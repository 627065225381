import axios from 'axios';
import { PremiumBreakdownId, ScenarioId } from '../types/api/PrimaryKeys';
import PremiumBreakdown from '../types/api/premiumBreakdown';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { envBaseApiUrl } from '../constants/envConstants';

export const getPremiumBreakdownsByScenarioIds = (scenarioIds: ScenarioId[]) => axios.post<PremiumBreakdown[]>(`${envBaseApiUrl}/premium-breakdowns/scenarios`, scenarioIds);

export const createPremiumBreakdown = (premiumBreakdown: PremiumBreakdown) => axios.post<CreatedItemResult<PremiumBreakdownId>>(`${envBaseApiUrl}/premium-breakdowns/`, premiumBreakdown);

export const updatePremiumBreakdown = (premiumBreakdown: PremiumBreakdown) => axios.put<void>(`${envBaseApiUrl}/premium-breakdowns`, premiumBreakdown);