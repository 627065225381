import { getVipMpMaxInputCostDeclineValues, roundToPlaces } from '@silveus/calculations';
import { Controller, useFormContext } from 'react-hook-form';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { FormInputProps } from '../../scenario/formInputProps';
import { formatPercentDigits } from '../../../../utils/formatNumbers';
import { Nullable } from '../../../../types/util/Nullable';

interface Props extends FormInputProps {
  maxInputCostDecline: Nullable<number>;
}

export interface MaxInputCostDeclineFields {
  maxInputCostDecline: Nullable<number>;
}

export const MaxInputCostDecline = ({ maxInputCostDecline, autoFocus = false, disabled = false }: Props) => {
  const { control } = useFormContext();
  const availableSelections = getVipMpMaxInputCostDeclineValues().map(x => roundToPlaces(x * 100, 0)).sort((a, b) => b - a);

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.MaxInputCostDecline}
      control={control}
      rules={{
        required: !disabled ? VALIDATION_RULES.required() : undefined,
        validate: !disabled ? VALIDATION_RULES.restrictNotOnListValue(availableSelections) : undefined,
      }}
      defaultValue={maxInputCostDecline}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label="Max Input Cost Decline"
          fullWidth
          select
          value={value}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {availableSelections.map((f: number) => <MenuItem key={f} value={f}>{formatPercentDigits(f * 0.01, 2)}</MenuItem>)}
        </TextField>
      )}
    />
  );
};
