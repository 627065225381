import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Authenticated from './components/authenticated/authenticated.component';
import Confirm from './components/confirm/confirm.component';
import Loader from './components/loader/loader.component';
import Navigation from './components/navigation/navigation.component';
import Toast from './components/toast/toast.component';
import { useRestorePersistedContext } from './hooks/routeDataHooks';
import { LicenseManager } from 'ag-grid-enterprise';
import { isNotNullOrUndefined } from './utils/nullHandling';
import { agGridLicenseKey } from './constants/envConstants';

import './App.css';
import { useEffect } from 'react';

function App() {
  const agGridLicense = agGridLicenseKey;
  useEffect(() => {
    if (isNotNullOrUndefined(agGridLicense)) {
      LicenseManager.setLicenseKey(agGridLicense);
    }
  }, [agGridLicense]);

  useRestorePersistedContext();

  return (
    <Authenticated>
      <Navigation />
      <Confirm />
      <Toast />
      <Loader />
    </Authenticated>
  );
}

export default App;
