import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { closeConfirm } from '../../app/confirmSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { MuiDialogCloseReason } from '../../types/mui/MuiDialogCloseReason';
import { useState } from 'react';

const Confirm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const confirm = useAppSelector(state => state.confirmation.content);
  const dispatch = useAppDispatch();

  const handleModalClose = async (closeReason: MuiDialogCloseReason) => {
    if (confirm?.shouldPreventBackdropClose === true && closeReason === 'backdropClick') {
      return;
    }

    await handleCancel();
  };

  const handleCancel = async () => {
    if (confirm && confirm.onCancel) {
      setIsLoading(true);
      try {
        await confirm.onCancel();
      } finally {
        setIsLoading(false);
      }
    }

    dispatch(closeConfirm());
  };

  const handleConfirm = async () => {
    if (confirm !== null) {
      setIsLoading(true);
      try {
        await confirm.onConfirm();
      } finally {
        setIsLoading(false);
      }
    }

    dispatch(closeConfirm());
  };

  if (confirm === null) {
    return null;
  }

  const hideCancel = Boolean(confirm.hideCancel);
  return (
    <Dialog
      open={true}
      onClose={(_, reason) => handleModalClose(reason)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {confirm.title && <DialogTitle id="alert-dialog-title">
        {confirm.title}
      </DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {confirm.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!hideCancel && (
          <Button disabled={isLoading} id="btn-cancel" onClick={handleCancel} variant="outlined" color="primary">
            {confirm.cancelText ?? 'Cancel'}
          </Button>
        )}
        <Button disabled={isLoading} id="btn-ok" onClick={handleConfirm} autoFocus>
          {confirm.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
