// Creating this intermediate object ensures that the #{tokens}# we rely on for environment injection don't get
// prematurely optimized away by TypeScript
export const env = {
  baseApiUrl: process.env.REACT_APP_BASE_API_URL,
  insightsKey: process.env.REACT_APP_INSIGHTS_KEY,
  insightsDisabled: process.env.REACT_APP_INSIGHTS_DISABLED,
  openWeather: process.env.REACT_APP_OPEN_WEATHER,
  authClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  authAuthority: process.env.REACT_APP_AUTH_AUTHORITY,
  authScope: process.env.REACT_APP_AUTH_SCOPE,
  d356PolicyUrl: process.env.REACT_APP_D365_POLICY_URL,
  baseD365Url: process.env.REACT_APP_BASE_D365_URL,
  baseDocumentGenerationUrl: process.env.REACT_APP_BASE_DOCUMENT_GENERATION_URL,
  eSignOverrideContacts: process.env.REACT_APP_ESIGN_OVERRIDE_CONTACTS,
  eSignAgentEmail: process.env.REACT_APP_ESIGN_AGENT_EMAIL,
  eSignAgentPhone: process.env.REACT_APP_ESIGN_AGENT_PHONE,
  eSignInsuredEmail: process.env.REACT_APP_ESIGN_INSURED_EMAIL,
  eSignInsuredPhone: process.env.REACT_APP_ESIGN_INSURED_PHONE,
  agGridLicenseKey: process.env.REACT_APP_AG_GRID_LICENSE,
  eSignStatusCallback: process.env.REACT_APP_ESIGN_REQUEST_CALLBACK_URL,
  d365AppTaskUrl: process.env.REACT_APP_D365_APPTASK_URL,
  applicationWizardEnabled: process.env.REACT_APP_ENABLE_APPLICATION_WIZARD,
  enabledFeatureFlags: process.env.REACT_APP_FEATURE_FLAGS,  // e.g., [\"enabled-flag-1\",\"enabled-flag-2\"]
};