import { useAppDispatch } from '../../hooks/reduxHooks';
import { QuoteId, ScenarioId } from '../../types/api/PrimaryKeys';
import { updateCalculationForScenario } from '../../app/calculationResultsSlice';
import { ReactElement } from 'react';
import './scenarioPieceCard.style.css';
import { CommandBarItem } from '../../components/command-bar/commandBar.component';
import { getFriendlyScenarioPieceName } from '../../utils/scenarioPieceUtils';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';
import { ScenarioPieceResponseDTO, ScenarioPieceType } from '@silveus/calculations';
import { Nullable } from '../../types/util/Nullable';
import ScenarioComponentCard from './scenarioComponentCard';
import ScenarioPieceTooltip from './scenarioPieceTooltip';
import { modifyUnitYearsForScenario } from '../../app/scenariosSlice';
import { getScenarioPieceDefinition } from '../../constants/productDefinitions/scenarioPieceDefinitionRecords';
import {
  buildScenarioPieceCardChips
} from '../../constants/productDefinitions/defaultDefinitions/scenarioPieceCardChipDefaults';
import BaseScenarioPieceCardChips from './scenarioPieceCardChips/baseScenarioPieceCardChips';
import { useOptions } from '../../hooks/useOptions';
import { OptionSelectionState } from '../../types/app/enums/optionSelectionState.enum';
import { useTheme } from '@mui/material';

interface ScenarioPieceCardProps<T extends ScenarioPiece> {
  scenarioPiece: T;
  quoteId: QuoteId;
  isScenarioActive: boolean;
  updateScenarioPiece: (scenarioPiece: T) => Promise<void>;
  removeScenarioPiece: (scenarioPiece: T) => Promise<void>;
  label: string;
  collapsedCardBody?: ReactElement;
  expandedCardBody?: ReactElement;
  extraCommandBarItems?: CommandBarItem[];
  netValue: number;
  calcResults: Nullable<ScenarioPieceResponseDTO>;
  scenarioId: ScenarioId;
  displayTooltip?: boolean;
  isReadonly: boolean;
  scenarioColor: string;
  errorTooltip?: ReactElement;
}

const ScenarioPieceCard = <T extends ScenarioPiece>({
  scenarioPiece,
  quoteId,
  isScenarioActive,
  updateScenarioPiece,
  removeScenarioPiece,
  label,
  collapsedCardBody,
  expandedCardBody,
  extraCommandBarItems = [],
  netValue,
  calcResults,
  scenarioId,
  isReadonly,
  displayTooltip = true,
  scenarioColor,
  errorTooltip,
}: ScenarioPieceCardProps<T>) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMp = [ScenarioPieceType.MP, ScenarioPieceType.MpHpo].includes(scenarioPiece.scenarioPieceType);

  const updatePieceCompletely = async (newScenarioPiece: T, isActive?: boolean) => {
    await updateScenarioPiece({ ...newScenarioPiece, isActive: isActive ?? newScenarioPiece.isActive });
    await dispatch(modifyUnitYearsForScenario({ scenarioId: scenarioId }));
    await dispatch(updateCalculationForScenario({ scenarioId: scenarioId }));
  };

  const getDisplayName = (scenarioPiece: ScenarioPiece) => getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType);
  const getScenarioPieceTooltip = (displayTooltip: boolean) =>
    displayTooltip
      ? () => <ScenarioPieceTooltip displayName={getDisplayName(scenarioPiece)} calcResults={calcResults} showDiscount={isMp} />
      : () => (<></>);

  const scenarioPieceDefinition = getScenarioPieceDefinition(scenarioPiece.scenarioPieceType);
  const { getOptionStatesForScenario } = useOptions(quoteId, scenarioId);
  const selectedOptions = getOptionStatesForScenario().filter(o => o.selectionState !== OptionSelectionState.Off);
  const scenarioPieceCardChipFunctions = scenarioPieceDefinition.getScenarioPieceCardChips;
  const chips = buildScenarioPieceCardChips(scenarioPieceCardChipFunctions, scenarioPiece, selectedOptions, theme);
  const scenarioPieceCardChips = chips.length > 0 ? <BaseScenarioPieceCardChips chips={chips} scenarioColor={scenarioColor} /> : undefined;

  const { shouldShowThreeStageSummaryExpander } = scenarioPieceDefinition;

  return (
    <ScenarioComponentCard
      scenarioComponent={scenarioPiece}
      quoteId={quoteId}
      isScenarioActive={isScenarioActive}
      updateScenarioComponent={updatePieceCompletely}
      removeScenarioComponent={removeScenarioPiece}
      label={label}
      netValue={netValue}
      scenarioId={scenarioId}
      calcResults={calcResults}
      getFormName={getDisplayName}
      collapsedCardBody={collapsedCardBody}
      expandedCardBody={expandedCardBody}
      extraCommandBarItems={extraCommandBarItems}
      getScenarioPieceTooltip={getScenarioPieceTooltip(displayTooltip)}
      isReadonly={isReadonly}
      isInvalid={scenarioPiece.isInvalid}
      errorTooltip={errorTooltip}
      scenarioPieceChips={scenarioPieceCardChips}
      shouldShowThreeStageExpander={shouldShowThreeStageSummaryExpander}
    />
  );
};

export default ScenarioPieceCard;
