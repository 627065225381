

export const extractActualPriceToolTip = (input: string | null | undefined, year: number) => {
  const regex = /([a-zA-Z]+\.?)[ ]*(\d+)[ ]*-?[ ]*([a-zA-Z]+\.?)[ ]*(\d+)/;

  if (input === undefined || input === null) {
    return undefined;
  }

  const matches = input.match(regex);
  if (matches) {
    const [, month1, day1, month2, day2] = matches;
    return `${month1} ${day1}, ${year} - ${month2} ${day2}, ${year + 1}`;
  }

  return undefined;
};