import { ScenarioId, ScenarioPieceId, UnitGroupId } from '../types/api/PrimaryKeys';
import { UnitGroup } from '../types/api/UnitGroup';
import axios from 'axios';
import { CreatedItemsResult } from '../types/api/results/CreatedItemsResult';
import { envBaseApiUrl } from '../constants/envConstants';

export const getUnitGroupsForScenarios = (scenarioIds: ScenarioId[]) => axios.post<UnitGroup[]>(`${envBaseApiUrl}/unit-groups/scenarios`, scenarioIds);

export const getUnitGroupsForScenarioPiece = (scenarioPieceId: ScenarioPieceId) => axios.get<UnitGroup[]>(`${envBaseApiUrl}/unit-groups/scenario-pieces/${scenarioPieceId}`);

export const createUnitGroups = (unitGroups: UnitGroup[]) => axios.post<CreatedItemsResult<UnitGroupId>>(`${envBaseApiUrl}/unit-groups`, unitGroups);

export const updateUnitGroups = (unitGroups: UnitGroup[]) => axios.put<void>(`${envBaseApiUrl}/unit-groups`, unitGroups);

export const deleteUnitGroups = (unitGroupIds: UnitGroupId[]) => axios.delete<void>(`${envBaseApiUrl}/unit-groups`, { data: unitGroupIds });