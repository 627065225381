import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import ApplicationWizardPage from './applicationWizardPage';
import { ApplicationWizardStep, ApplicationWizardStepProps } from './applicationWizardStep';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import { Grid } from '@mui/material';
import ContactInfoGrid from '../../../components/ag-grid/contactInfoGrid/contactInfoGrid';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { validatePersonOfInterest } from '../../../components/ag-grid/contactInfoGrid/validation/validateContactInfo';
import { selectInsuredsPersonsOfInterestByType } from '../../../app/personOfInterestSlice';
import { RootState } from '../../../app/store';
import { selectEntityTypes, selectTaxTypes } from '../../../app/insuredsSlice';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import { PersonOfInterestTypes } from '../../../types/api/insureds/PersonOfInterest';

export const POAAuthRepStep: ApplicationWizardStep = {
  isDisabled(app: ApplicationWizard, state: RootState) {
    return !app.anyChanges ||
      (!app.anyPOA) ||
      (!app.isGeneratingForms);
  },
  title: 'POA/Auth Rep',
  index: 5,
  formId: 'form-poa-info',
  icon: <GavelOutlinedIcon />,
  isValid(app: ApplicationWizard, state: RootState) {
    const isDisabled = this.isDisabled(app, state);
    // If step is disabled we do not care if all SBIs are valid here because the SBIs won't
    // be included in the application.
    if (isDisabled) {
      return {
        isValid: true,
        errors: [],
      };
    }
    const insuredId = app.isNewEntity && isNotNullOrUndefined(app.newInsuredId) ? app.newInsuredId : app.insuredId;
    const poas = selectInsuredsPersonsOfInterestByType(state, insuredId, PersonOfInterestType.PowerOfAttorney);
    const authorizedReps = selectInsuredsPersonsOfInterestByType(state, insuredId, PersonOfInterestType.AuthorizedRep);
    if (app.anyChanges && app.anyPOA && poas.length === 0 && authorizedReps.length === 0) {
      return {
        isValid: false,
        errors: ['No POAs found'],
      };
    }

    const entityTypes = selectEntityTypes(state);
    const taxIdTypes = selectTaxTypes(state);
    const poasAndAuthReps = poas.concat(authorizedReps);
    const poaValidationResults = poasAndAuthReps.map(x => validatePersonOfInterest(x, entityTypes, taxIdTypes, app.isGettingESignatures)).every(x => x === true);
    return {
      isValid: poaValidationResults,
      errors: poaValidationResults ? [] : ['Errors found on POAs'],
    };
  },
};

const POAInfoPage = ({ application, navigateToPreviousStep, navigateToNextStep, updateIsInEditMode, isInEditMode, isLoading, updateIsLoading }: ApplicationWizardStepProps) => {
  const handleNavigateToPrevious = () => {
    navigateToPreviousStep();
  };
  const personOfInterestTypes: PersonOfInterestTypes = [PersonOfInterestType.PowerOfAttorney, PersonOfInterestType.AuthorizedRep];

  return (
    <ApplicationWizardPage loading={isLoading} tabIndex={POAAuthRepStep.index} onPreviousClick={handleNavigateToPrevious} onNextClick={navigateToNextStep} disabled={isInEditMode || isLoading}>
      <Grid item container sx={{ flexDirection: 'row', flexWrap: 'nowrap', height: 'calc(100% - 55px)', pb: 1 }}>
        <ContactInfoGrid updateIsLoading={updateIsLoading} contactTypes={personOfInterestTypes} application={application} updateIsInEditMode={updateIsInEditMode} isLoading={isLoading} />
      </Grid>
    </ApplicationWizardPage>
  );
};

export default POAInfoPage;