import { ColDef, ColumnState, ICellRendererParams, IFilterPlaceholderFunctionParams, INumberFilterParams } from 'ag-grid-community';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import { HailPlanType } from '../../../types/api/enums/hail/hailPlanType';
import { CheckRenderer } from '../../../components/ag-grid/cellRenderers/checkRenderer';
import { Quote } from '../../../types/api/Quote';
import { isUnitQuote } from '../../../utils/quoteUtils';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import { HailProductId } from '../../../types/api/PrimaryKeys';

export const getColumnDefinitions = (onTogglePlan: (hailProduct: HailProduct) => void, quote: Quote, hailScenarioPieces: Map<HailProductId, HailScenarioPiece>) => {
  const columnDefinitions: ColDef<HailProduct>[] = [
    {
      colId: 'isSelected',
      headerName: '',
      editable: false,
      resizable: false,
      pinned: 'left',
      suppressMovable: true,
      filter: false,
      cellStyle: {
        paddingLeft: '4px',
        paddingRight: '4px',
        textAlign: 'center',
      },
      maxWidth: 50,
      cellRenderer: (params: ICellRendererParams<HailProduct>) => {
        const { data } = params;
        if (data === undefined) return null;

        return CheckRenderer(`hail-plan-check-${data.hailProductId}`, hailScenarioPieces.has(data.hailProductId), () => onTogglePlan(data));
      },
    },
    {
      colId: 'cropYear',
      headerName: 'Year',
      field: 'cropYear',
      type: 'rightAligned',
      filter: 'agNumberColumnFilter',
      maxWidth: 130,
      filterParams: {
        filterPlaceholder: (params: IFilterPlaceholderFunctionParams) => {
          const { filterOption } = params;
          return `${filterOption} year`;
        },
      } as INumberFilterParams,
    },
    {
      colId: 'aipAbbreviation',
      headerName: 'AIP',
      field: 'aipAbbreviation',
    },
    {
      colId: 'policy',
      headerName: 'Policy',
      field: 'policy.policyTypeName',
    },
    {
      colId: 'planType',
      headerName: 'Product Type',
      field: 'planType',
      valueFormatter: params => {
        return HailPlanType[params.value];
      },
    },
    {
      colId: 'productName',
      headerName: 'Plan Name',
      field: 'productName',
    },
    {
      colId: 'minRate',
      headerName: 'Min Rate',
      field: 'minRate',
      type: 'rightAligned',
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterPlaceholder: (params: IFilterPlaceholderFunctionParams) => {
          const { filterOption } = params;
          return `${filterOption} min rate`;
        },
      } as INumberFilterParams,
    },
    {
      colId: 'maxRate',
      headerName: 'Max Rate',
      field: 'maxRate',
      type: 'rightAligned',
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterPlaceholder: (params: IFilterPlaceholderFunctionParams) => {
          const { filterOption } = params;
          return `${filterOption} max rate`;
        },
      } as INumberFilterParams,
    },
    {
      colId: 'rate',
      headerName: 'Rate',
      field: 'rate',
      type: 'rightAligned',
      filter: 'agNumberColumnFilter',
      maxWidth: 100,
      filterParams: {
        filterPlaceholder: (params: IFilterPlaceholderFunctionParams) => {
          const { filterOption } = params;
          return `${filterOption} rate`;
        },
      } as INumberFilterParams,
    },
  ];

  const columnOrder = ['isSelected', 'cropYear', 'aipAbbreviation', 'policy', 'planType', 'productName', 'minRate', 'maxRate', 'rate'];
  const orderedColumnDefinitions = columnOrder.map(colId => columnDefinitions.find(col => col.colId === colId)).filter((x): x is ColDef<HailProduct> => !!x);

  const isUnitQuotingOn = isUnitQuote(quote);

  if (isUnitQuotingOn) {
    return orderedColumnDefinitions.filter(x => x.colId !== 'minRate' && x.colId !== 'maxRate');
  } else {
    return orderedColumnDefinitions.filter(x => x.colId !== 'rate');
  }
};

export const DefaultColDefinition: ColDef = {
  minWidth: 40,
  resizable: true,
  sortable: true,
  editable: false,
  filter: true,
  lockPinned: true,
  hide: false,
  lockVisible: true,
};


export const HailPlanExplorerDefaultColumnState: ColumnState[] = [
  {
    colId: 'isSelected',
    width: 50,
  },
  {
    colId: 'cropYear',
    width: 150,
    sort: 'desc',
  },
  {
    colId: 'aipAbbreviation',
    width: 150,
  },
  {
    colId: 'policy',
    width: 150,
  },
  {
    colId: 'planType',
    width: 170,
  },
  {
    colId: 'productName',
    width: 300,
  },
  {
    colId: 'minRate',
    width: 110,
  },
  {
    colId: 'maxRate',
    width: 130,
  },
  {
    colId: 'rate',
    width: 130,
  },
];