import { AdjustmentType, AdjustmentTypeAttributes } from '@silveus/calculations';
import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { MenuItem, TextField, styled } from '@mui/material';
import HarvestRevenueTransactionPropsBase from './harvestRevenueTransactionPropsBase';
import { HarvestRevenueFormFields } from '../harvestRevenueScenarioPieceForm.component';
import { useEffect } from 'react';

const StyledTextField = styled(TextField)({
  padding: '0px',
  width: '98px',
  '& div': {
    fontSize: '12px !important',
    paddingLeft: '4px',
  },
});

interface HarvestRevenueTransactionProductionTypeProps extends HarvestRevenueTransactionPropsBase {
  handleTransactionListChanged?: (childFunction: () => void) => void;
}

const HarvestRevenueTransactionProductionType = ({ index, disabled, onTransactionValueChanged, handleTransactionListChanged }: HarvestRevenueTransactionProductionTypeProps) => {
  const { control, getValues, trigger } = useFormContext<HarvestRevenueFormFields>();

  useEffect(() => {
    if (handleTransactionListChanged) {
      handleTransactionListChanged(() => triggerAllProductionTypesValidation());
    }
  }, [handleTransactionListChanged]);

  const databaseAvailableAdjustmentType = [
    AdjustmentType.Remaining,
    AdjustmentType.Fixed,
    AdjustmentType.Percent,
    AdjustmentType.PlusMinus,
  ];

  const triggerAllProductionTypesValidation = () => {
    const listOfProductions = getValues().harvestRevenueTransactions.map(x => x.productionAdjustmentTypeId);

    for (let i = 0; i < listOfProductions.length; i++) {
      trigger(`harvestRevenueTransactions.${i}.productionAdjustmentTypeId`);
    }
  };

  const availableAdjustmentTypes = databaseAvailableAdjustmentType.map(x => AdjustmentTypeAttributes[x]);

  const maxOneRemaining = () => {
    return (value: AdjustmentType) => {
      const listOfProductions = getValues().harvestRevenueTransactions.map(x => x.productionAdjustmentTypeId);
      const isOk = value !== AdjustmentType.Remaining || (listOfProductions.filter(x => x === AdjustmentType.Remaining).length <= 1);
      return isOk || `Can have at most only 1 ${AdjustmentTypeAttributes[value].description}`;
    };
  };

  return (
    <Controller
      name={`harvestRevenueTransactions.${index}.productionAdjustmentTypeId`}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.validateMultipleRules(
          [
            VALIDATION_RULES.restrictNotOnListValue(availableAdjustmentTypes.map(x => x.value)),
            maxOneRemaining(),
          ],
        ),
      }}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <StyledTextField
          {...field}
          type="text"
          disabled={disabled}
          select
          value={value}
          error={!!error}
          helperText={error?.message}
          onChange={e => {
            field.onChange(e.target.value);
            triggerAllProductionTypesValidation();
            onTransactionValueChanged();
          }}
          size="small"
        >
          {availableAdjustmentTypes.map(us => <MenuItem sx={{ fontSize: '12px' }} key={us.value} value={us.value}>{us.description}</MenuItem>)}
        </StyledTextField>
      )}
    />
  );
};

export default HarvestRevenueTransactionProductionType;