import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserMatrixColor, UserMatrixColorDictionary } from '../types/api/userSettings/UserMatrixColor';
import { ClientFileId, MatrixId, MatrixPresetId, QuoteId, ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import { PossibleLinkedScenarioField } from '../types/api/userSettings/PossibleLinkedScenarioField';
import { UserLinkedMatrices, UserLinkedScenarios } from '../types/api/userSettings/UserLinkedScenarios';
import { UserScenarioOrder } from '../types/api/userSettings/UserScenarioOrder';
import { Nullable } from '../types/util/Nullable';
import { AllPossibleScenarioLinkFields, DefaultPremiumBreakdownOptions, EmptyUserLinkedMatrixSettings, EmptyUserLinkedScenarioSettings, EmptyUserMatrixPresetsSetting, EmptyUserScenarioOrderSettings, EmptyUserScenarioPieceOrderSettings } from '../utils/userSettingUtils';
import { RootState } from './store';
import { ColumnState } from 'ag-grid-community';
import { createAppAsyncThunk } from './thunkHelpers';
import { UserScenarioPieceOrder } from '../types/api/userSettings/UserScenarioPieceOrder';
import {
  getHistoricalColumnsForUserRequest, getLinkedMatricesForUserRequest,
  getLinkedScenariosForUserRequest, getMatrixColorSettingsForUserRequest,
  getScenarioOrderForUserRequest, getScenarioPieceOrderForUserRequest,
  getUnitColumnsForUserRequest,
  updateHistoricalColumnsForUserRequest, updateLinkedMatricesForUserRequest,
  updateLinkedScenariosForUserRequest, updateMatrixColorSettingsForUserRequest,
  updateScenarioOrderForUserRequest, updateScenarioPieceOrderForUserRequest,
  updateUnitColumnsForUserRequest, getPremiumBreakdownSettingsForUserRequest,
  updatePremiumBreakdownSettingsForUserRequest,
  getUnitModalColumnsForUserRequest,
  updateUnitModalColumnsForUserRequest,
  getHailPlanExplorerColumnsForUserRequest,
  updateHailPlanExplorerColumnsForUserRequest,
  getPaletteModeSettingsForUserRequest,
  updatePaletteModeSettingsForUserRequest,
  getMatrixPresetsForUserRequest,
  updateMatrixPresetsForUserRequest
} from '../services/requestInterception/userSettingRequestInterceptor';
import { PremiumBreakdownOptions } from '../types/api/userSettings/PremiumBreakdownOptions';
import { selectScenarioPieceAcrossAllTypes } from './sharedSelectors';
import { PaletteModeSettings } from '../types/api/userSettings/PaletteModeSettings';
import { orderByProperty } from '../utils/arrayUtils';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import { MatrixPresetType } from '../types/api/enums/matrixPresetType.enum';
import { SystemMatrixPresets } from '../types/settings/SystemMatrixPresets';
import { UserMatrixPresets } from '../types/api/userSettings/UserMatrixPresets';
import { produce } from 'immer';
import { updateLinkedScenarios } from './scenariosSlice';

export interface UserSettingsState {
  linkedScenarios: UserLinkedScenarios;
  lastRemotelyPersistedLinkedScenarios: Nullable<UserLinkedScenarios>;

  scenarioOrder: UserScenarioOrder;

  scenarioPieceOrder: UserScenarioPieceOrder;

  matrixPresets: UserMatrixPresets;
  matrixColorSettings: UserMatrixColorDictionary;
  linkedMatrices: UserLinkedMatrices;

  unitColumns: ColumnState[];
  planExplorerColumns: ColumnState[];
  historicalColumns: ColumnState[];
  unitModalColumns: ColumnState[];
  unitAphModalColumns: ColumnState[];
  premiumBreakdownOptions: Nullable<PremiumBreakdownOptions>;
  paletteModeSettings: PaletteModeSettings;
}

const initialState: UserSettingsState = {
  linkedScenarios: EmptyUserLinkedScenarioSettings,
  lastRemotelyPersistedLinkedScenarios: null,

  scenarioOrder: EmptyUserScenarioOrderSettings,

  scenarioPieceOrder: EmptyUserScenarioPieceOrderSettings,

  matrixPresets: EmptyUserMatrixPresetsSetting,
  matrixColorSettings: {},
  linkedMatrices: EmptyUserLinkedMatrixSettings,
  unitColumns: [],
  historicalColumns: [],
  unitModalColumns: [],
  unitAphModalColumns: [],
  planExplorerColumns: [],
  premiumBreakdownOptions: null,
  paletteModeSettings: { paletteMode: 'light' },
};

const userSettingsSlice = createSlice({
  name: 'user-settings',
  initialState: initialState,
  reducers: {
    // Linked Scenarios ----------------------------
    addLinkedScenario(state, action: PayloadAction<{ quoteId: QuoteId, scenarioId: ScenarioId }>) {
      const existingLinkedScenariosForQuote = state.linkedScenarios.quotes[action.payload.quoteId];

      if (existingLinkedScenariosForQuote === undefined) {
        state.linkedScenarios.quotes[action.payload.quoteId] = {
          linkedScenarioIds: [action.payload.scenarioId],

          // When spinning up a brand new link, auto-assign all possible fields (this was a design requirement)
          linkedFields: [...AllPossibleScenarioLinkFields.map(s => s.fieldName)],
        };
      } else {
        existingLinkedScenariosForQuote.linkedScenarioIds.push(action.payload.scenarioId);
      }
    },
    removeLinkedScenario(state, action: PayloadAction<{ quoteId: QuoteId, scenarioId: ScenarioId }>) {
      const existingLinkedScenariosForQuote = state.linkedScenarios.quotes[action.payload.quoteId];

      // If there's no data for the quote, there's nothing to remove.
      if (existingLinkedScenariosForQuote === undefined) {
        return;
      }

      // Find & remove the supplied scenario.
      existingLinkedScenariosForQuote.linkedScenarioIds = existingLinkedScenariosForQuote.linkedScenarioIds.filter(s => s !== action.payload.scenarioId);
    },
    addLinkedFields(state, action: PayloadAction<{ quoteId: QuoteId, fieldsToLink: readonly PossibleLinkedScenarioField[] }>) {
      const existingLinkedScenariosForQuote = state.linkedScenarios.quotes[action.payload.quoteId];

      // I don't know if realistically it will be possible to add linked fields without first establishing a record
      // at the quote level...but it can't really hurt to handle it just in case, so this is more flexible.
      if (existingLinkedScenariosForQuote === undefined) {
        state.linkedScenarios.quotes[action.payload.quoteId] = {
          linkedScenarioIds: [],
          linkedFields: [...action.payload.fieldsToLink],
        };
      } else {
        existingLinkedScenariosForQuote.linkedFields.push(...action.payload.fieldsToLink);
      }
    },
    removeLinkedFields(state, action: PayloadAction<{ quoteId: QuoteId, fieldsToRemove: readonly PossibleLinkedScenarioField[] }>) {
      const existingLinkedScenariosForQuote = state.linkedScenarios.quotes[action.payload.quoteId];

      // No-Op. If there is no record for the quote, removing won't do anything anyway.
      if (existingLinkedScenariosForQuote === undefined) {
        return;
      }

      existingLinkedScenariosForQuote.linkedFields = existingLinkedScenariosForQuote.linkedFields.filter(
        existing => !action.payload.fieldsToRemove.includes(existing));
    },
    removeAllLinkedFields(state, action: PayloadAction<QuoteId>) {
      const existingLinkedScenariosForQuote = state.linkedScenarios.quotes[action.payload];

      // No-Op. If there is no record for the quote, removing won't do anything anyway.
      if (existingLinkedScenariosForQuote === undefined) {
        return;
      }

      existingLinkedScenariosForQuote.linkedFields = [];
    },
    setLastRemotelyPersistedLinkedScenarios(state, action: PayloadAction<UserLinkedScenarios>) {
      state.lastRemotelyPersistedLinkedScenarios = action.payload;
    },

    // Scenario Order ----------
    setScenarioOrder(state, action: PayloadAction<{ quoteId: QuoteId, scenarios: ScenarioId[] }>) {
      // I'm spreading the passed in array because I don't yet know where it might come from and there is risk
      // of the caller modifying it in the future.
      state.scenarioOrder.quotes[action.payload.quoteId] = [...action.payload.scenarios];
    },
    // Scenario Piece Order ----------
    setScenarioPieceOrder(state, action: PayloadAction<{ scenarioId: ScenarioId, scenarioPieces: ScenarioPieceId[] }>) {
      state.scenarioPieceOrder.scenarios[action.payload.scenarioId] = [...action.payload.scenarioPieces];
    },

    // Linked Matrix ----------------------------
    addLinkedMatrix(state, action: PayloadAction<{ quoteId: QuoteId, matrixId: MatrixId }>) {
      const existingLinkedMatricesForQuote = state.linkedMatrices.quotes[action.payload.quoteId];
      if (existingLinkedMatricesForQuote === undefined) {
        //New items
        state.linkedMatrices.quotes[action.payload.quoteId] = {
          linkedMatrixIds: [action.payload.matrixId],
        };
      } else {
        //Update the linked fields
        if (!existingLinkedMatricesForQuote.linkedMatrixIds.includes(action.payload.matrixId)) {
          existingLinkedMatricesForQuote.linkedMatrixIds.push(action.payload.matrixId);
        }
      }
    },
    removeLinkedMatrix(state, action: PayloadAction<{ quoteId: QuoteId, matrixId: MatrixId }>) {
      const existingLinkedMatricesForQuote = state.linkedMatrices.quotes[action.payload.quoteId];
      if (existingLinkedMatricesForQuote === undefined) {
        return;
      }
      // Find & remove the supplied matrix.
      existingLinkedMatricesForQuote.linkedMatrixIds = existingLinkedMatricesForQuote.linkedMatrixIds.filter(s => s !== action.payload.matrixId);
    },
  },
  extraReducers(builder) {
    builder
      // Unit Columns
      .addCase(fetchUnitColumnsForUser.fulfilled, (state, action: PayloadAction<ColumnState[]>) => {
        state.unitColumns = action.payload;
      })
      .addCase(modifyUnitColumnsForUser.fulfilled, (state, action: PayloadAction<ColumnState[]>) => {
        state.unitColumns = action.payload;
      })

      // hail plan explorer columns
      .addCase(fetchHailPlanExplorerColumnsForUser.fulfilled, (state, action: PayloadAction<ColumnState[]>) => {
        state.planExplorerColumns = action.payload;
      })
      .addCase(modifyHailPlanExplorerColumnsForUser.fulfilled, (state, action: PayloadAction<ColumnState[]>) => {
        state.planExplorerColumns = action.payload;
      })

      // Unit Modal Columns
      .addCase(fetchUnitModalColumnsForUser.fulfilled, (state, action: PayloadAction<ColumnState[]>) => {
        state.unitModalColumns = action.payload;
      })
      .addCase(modifyModalUnitColumnsForUser.fulfilled, (state, action: PayloadAction<ColumnState[]>) => {
        state.unitModalColumns = action.payload;
      })

      // Historical Columns
      .addCase(fetchHistoricalColumnsForUser.fulfilled, (state, action: PayloadAction<ColumnState[]>) => {
        state.historicalColumns = action.payload;
      })
      .addCase(modifyHistoricalColumnsForUser.fulfilled, (state, action: PayloadAction<ColumnState[]>) => {
        state.historicalColumns = action.payload;
      })

      // Linked Scenarios
      .addCase(fetchLinkedScenariosForUser.fulfilled, (state, action) => {
        state.linkedScenarios = action.payload;
      })
      .addCase(modifyLinkedScenariosForUser.fulfilled, (state, action: PayloadAction<UserLinkedScenarios>) => {
        state.linkedScenarios = action.payload;
      })

      // Scenario Order
      .addCase(fetchScenarioOrderForUser.fulfilled, (state, action) => {
        state.scenarioOrder = action.payload;
      })
      .addCase(modifyScenarioOrderForUser.fulfilled, (state, action: PayloadAction<UserScenarioOrder>) => {
        state.scenarioOrder = action.payload;
      })

      // Scenario Piece Order
      .addCase(fetchScenarioPieceOrderForUser.fulfilled, (state, action) => {
        state.scenarioPieceOrder = action.payload;
      })
      .addCase(modifyScenarioPieceOrderForUser.fulfilled, (state, action: PayloadAction<UserScenarioPieceOrder>) => {
        state.scenarioPieceOrder = action.payload;
      })

      // Matrix Presets
      .addCase(fetchMatrixPresetsForUser.fulfilled, (state, action) => {
        state.matrixPresets = action.payload;
      })
      .addCase(modifyMatrixPresetsForUser.fulfilled, (state, action) => {
        state.matrixPresets = action.payload;
      })

      // Matrix Colors
      .addCase(fetchMatrixColorSettingsForUser.fulfilled, (state, action) => {
        state.matrixColorSettings = action.payload;
      })
      .addCase(modifyMatrixColorSettingsForUser.fulfilled, (state, action) => {
        state.matrixColorSettings[state.paletteModeSettings.paletteMode] = action.payload;
      })

      // Premium Breakdown Options
      .addCase(modifyPremiumBreakdownOptionsForUser.fulfilled, (state, action) => {
        state.premiumBreakdownOptions = action.payload;
      })
      .addCase(fetchPremiumBreakdownOptionsForUser.fulfilled, (state, action) => {
        state.premiumBreakdownOptions = action.payload;
      })

      // Palette Mode
      .addCase(fetchPaletteModeSettingsForUser.fulfilled, (state, action) => {
        state.paletteModeSettings = action.payload;
      })
      .addCase(modifyPaletteModeSettingsForUser.fulfilled, (state, action) => {
        state.paletteModeSettings = action.payload;
      })

      // Linked Matrices
      .addCase(fetchLinkedMatricesForUser.fulfilled, (state, action) => {
        state.linkedMatrices = action.payload;
      })
      .addCase(modifyLinkedMatricesForUser.fulfilled, (state, action: PayloadAction<UserLinkedMatrices>) => {
        state.linkedMatrices = action.payload;
      });
  },
});

// Premium Breakdown ---------------------------------------
export const fetchPremiumBreakdownOptionsForUser = createAppAsyncThunk(
  'user-settings/fetchPremiumBreakdownOptionsForUser',
  async () => {
    const result = await getPremiumBreakdownSettingsForUserRequest();
    return result;
  },
);

export const modifyPremiumBreakdownOptionsForUser = createAppAsyncThunk(
  'user-settings/modifyPremiumBreakdownOptionsForUser',
  async ({ options }: { options: PremiumBreakdownOptions }) => {
    await updatePremiumBreakdownSettingsForUserRequest(options);
    return options;
  },
);

// Hail Plan Explorer Columns
export const fetchHailPlanExplorerColumnsForUser = createAppAsyncThunk(
  'user-settings/fetchHailPlanExplorerColumnsForUser',
  async () => {
    const result = await getHailPlanExplorerColumnsForUserRequest();
    return result;
  },
);

export const modifyHailPlanExplorerColumnsForUser = createAppAsyncThunk(
  'user-settings/modifyHailPlanExplorerColumnsForUser',
  async ({ columns }: { columns: ColumnState[] }) => {
    await updateHailPlanExplorerColumnsForUserRequest(columns);
    return columns;
  },
);


// Unit Modal Columns

export const fetchUnitModalColumnsForUser = createAppAsyncThunk(
  'user-settings/fetchUnitModalColumnsForUser',
  async () => {
    const result = await getUnitModalColumnsForUserRequest();
    return result;
  },
);

export const modifyModalUnitColumnsForUser = createAppAsyncThunk(
  'user-settings/modifyModalUnitColumnsForUser',
  async ({ columns }: { columns: ColumnState[] }) => {
    await updateUnitModalColumnsForUserRequest(columns);
    return columns;
  },
);

// Unit Columns --------------------------------------------
export const fetchUnitColumnsForUser = createAppAsyncThunk(
  'user-settings/fetchUnitColumnsForUser',
  async () => {
    const result = await getUnitColumnsForUserRequest();
    return result;
  },
);

export const modifyUnitColumnsForUser = createAppAsyncThunk(
  'user-settings/modifyUnitColumnsForUser',
  async ({ columns }: { columns: ColumnState[] }) => {
    await updateUnitColumnsForUserRequest(columns);
    return columns;
  },
);

// Historical Columns --------------------------------------
export const fetchHistoricalColumnsForUser = createAppAsyncThunk(
  'user-settings/fetchHistoricalColumnsForUser',
  async () => {
    const result = await getHistoricalColumnsForUserRequest();
    return result;
  },
);

export const modifyHistoricalColumnsForUser = createAppAsyncThunk(
  'user-settings/modifyHistoricalColumnsForUser',
  async ({ columns }: { columns: ColumnState[] }) => {
    await updateHistoricalColumnsForUserRequest(columns);
    return columns;
  },
);

// Linked Scenarios ----------------------------------------
export const updateLinkedScenariosSettingsAndScenarioValues = createAppAsyncThunk(
  'user-settings/updateLinkedScenariosSettingsAndScenarioValues',
  async ({ debouncedLinkedScenarios, clientFileId }: { debouncedLinkedScenarios: UserLinkedScenarios, clientFileId: ClientFileId }, thunkApi) => {
    const result = await thunkApi.dispatch(modifyLinkedScenariosForUser({ userLinkedScenarios: debouncedLinkedScenarios }));

    if (result.meta.requestStatus === 'fulfilled') {
      // Only if we have indication of success do we register that we have saved.
      thunkApi.dispatch(setLastRemotelyPersistedLinkedScenarios(debouncedLinkedScenarios));
      await thunkApi.dispatch(updateLinkedScenarios({ clientFileId: clientFileId }));
    }
  },
);

export const fetchLinkedScenariosForUser = createAppAsyncThunk(
  'user-settings/getLinkedScenariosForUser',
  async () => {
    const result = await getLinkedScenariosForUserRequest();
    return result;
  },
);

export const modifyLinkedScenariosForUser = createAppAsyncThunk(
  'user-settings/modifyLinkedScenariosForUser',
  async ({ userLinkedScenarios }: { userLinkedScenarios: UserLinkedScenarios }) => {
    await updateLinkedScenariosForUserRequest(userLinkedScenarios);
    return userLinkedScenarios;
  },
);

// Scenario Order ------------------------------------------
export const fetchScenarioOrderForUser = createAppAsyncThunk(
  'user-settings/getScenarioOrderForUser',
  async () => {
    const result = await getScenarioOrderForUserRequest();
    return result;
  },
);

export const modifyScenarioOrderForUser = createAppAsyncThunk(
  'user-settings/modifyScenarioOrderForUser',
  async ({ userScenarioOrder }: { userScenarioOrder: UserScenarioOrder }) => {
    await updateScenarioOrderForUserRequest(userScenarioOrder);
    return userScenarioOrder;
  },
);

// Scenario Piece Order ------------------------------------------
export const fetchScenarioPieceOrderForUser = createAppAsyncThunk(
  'user-settings/getScenarioPieceOrderForUser',
  async () => {
    const result = await getScenarioPieceOrderForUserRequest();
    return result;
  },
);

export const modifyScenarioPieceOrderForUser = createAppAsyncThunk(
  'user-settings/modifyScenarioPieceOrderForUser',
  async ({ userScenarioPieceOrder }: { userScenarioPieceOrder: UserScenarioPieceOrder }) => {
    await updateScenarioPieceOrderForUserRequest(userScenarioPieceOrder);
    return userScenarioPieceOrder;
  },
);

// Note: This implementation is temporary. There is currently no mechanism in the UI to reorder scenario pieces.
export const modifyScenarioPieceOrder = createAppAsyncThunk(
  'user-settings/modifyScenarioPieceOrder',
  async ({ scenarioId, scenarioPieceId }: { scenarioId: ScenarioId, scenarioPieceId: ScenarioPieceId }, thunkApi) => {
    // Rather than passing in an ordered list of scenario pieces, just pull the current list from state.
    // This does not make any assumptions about how ordering will actually work.
    const state = thunkApi.getState();
    const scenarioPieceOrderByScenario = selectUserScenarioPieceOrder(state);
    let scenarioPieceOrderForScenario = scenarioPieceOrderByScenario[scenarioId] ?? [];

    const scenarioPiece = selectScenarioPieceAcrossAllTypes(state, scenarioPieceId);
    const scenarioPieceExists = scenarioPiece !== undefined;

    if (!scenarioPieceExists && scenarioPieceOrderForScenario.includes(scenarioPieceId)) {
      //Scenario Piece has been deleted, so we need to remove it if it exists in our list.
      scenarioPieceOrderForScenario = scenarioPieceOrderForScenario.filter(id => id !== scenarioPieceId);
    }

    // If scenario piece exists and id does not exist in list, append to the end
    if (scenarioPieceExists && !scenarioPieceOrderForScenario.includes(scenarioPieceId)) {
      scenarioPieceOrderForScenario = [...scenarioPieceOrderForScenario, scenarioPieceId];
    }

    // This modifies the order in local state
    thunkApi.dispatch(setScenarioPieceOrder({ scenarioId, scenarioPieces: scenarioPieceOrderForScenario }));

    // Get the adjusted state after we just modified it above to go ahead and auto-persist it.
    const newScenarioPieceOrderState = thunkApi.getState().userSettings.scenarioPieceOrder;

    // Try to persist the state to the API.
    await thunkApi.dispatch(modifyScenarioPieceOrderForUser({ userScenarioPieceOrder: newScenarioPieceOrderState }));
  });

// Matrix Presets
export const fetchMatrixPresetsForUser = createAppAsyncThunk(
  'user-settings/get-matrix-presets',
  async () => {
    const result: UserMatrixPresets = await getMatrixPresetsForUserRequest();
    return result;
  },
);

export const modifyMatrixPresetsForUser = createAppAsyncThunk(
  'user-settings/modify-matrix-presets',
  async ({ matrixPresets }: { matrixPresets: UserMatrixPresets }) => {
    const userMatrixPresets = matrixPresets.filter(mp => mp.matrixPresetType !== MatrixPresetType.System);
    await updateMatrixPresetsForUserRequest(userMatrixPresets);
    return userMatrixPresets;
  },
);

// Matrix Colors
export const fetchMatrixColorSettingsForUser = createAppAsyncThunk(
  'user-settings/get-matrix-color-settings',
  async () => {
    const result = await getMatrixColorSettingsForUserRequest();
    return result;
  },
);

export const modifyMatrixColorSettingsForUser = createAppAsyncThunk(
  'user-settings/modify-matrix-color-settings',
  async ({ matrixColorSettings }: { matrixColorSettings: UserMatrixColor[] }, thunkApi) => {
    const state = thunkApi.getState();
    const userMatrixColor = selectUserMatrixColorSettings(state);
    const palette = selectPaletteMode(state);

    const updatedMatrixPalette = produce(userMatrixColor, draftUserMatrixColor => {
      draftUserMatrixColor[palette] = matrixColorSettings;
    });

    await updateMatrixColorSettingsForUserRequest(updatedMatrixPalette);
    return matrixColorSettings;
  },
);

//Linked Matrices
export const fetchLinkedMatricesForUser = createAppAsyncThunk(
  'user-settings/getLinkedMatricesForUser',
  async () => {
    const result = await getLinkedMatricesForUserRequest();
    return result;
  },
);

export const modifyLinkedMatricesForUser = createAppAsyncThunk(
  'user-settings/updateLinkedMatricesForUser', async (_undefined: undefined, thunkApi) => {
    const state = thunkApi.getState();
    const linkedMatrices = selectUserLinkedMatrices(state);
    await updateLinkedMatricesForUserRequest(linkedMatrices);
    return linkedMatrices;
  },
);

// Palette Mode
export const fetchPaletteModeSettingsForUser = createAppAsyncThunk(
  'user-settings/fetchPaletteModeSettingsForUser',
  async () => {
    const result = await getPaletteModeSettingsForUserRequest();
    return result;
  },
);

export const modifyPaletteModeSettingsForUser = createAppAsyncThunk(
  'user-settings/modifyPaletteModeSettingsForUser',
  async ({ paletteModeSettings }: { paletteModeSettings: PaletteModeSettings }) => {
    await updatePaletteModeSettingsForUserRequest(paletteModeSettings);
    return paletteModeSettings;
  },
);

export const selectPaletteMode = (state: RootState) => state.userSettings.paletteModeSettings.paletteMode;
export const selectIsLightMode = (state: RootState) => state.userSettings.paletteModeSettings.paletteMode === 'light';

// Premium Breakdown Options
export const selectPremiumBreakdownOptions = (state: RootState) => state.userSettings.premiumBreakdownOptions ?? DefaultPremiumBreakdownOptions;

// Unit Modal Columns
export const selectUnitModalColumns = (state: RootState) => state.userSettings.unitModalColumns;

// Hail Plan Explorer Columns
export const selectPlanExplorerColumns = (state: RootState) => state.userSettings.planExplorerColumns;

// Unit Modal Columns
export const selectUnitAphModalColumns = (state: RootState) => state.userSettings.unitAphModalColumns;

// Unit Columns
export const selectUserUnitColumns = (state: RootState) => state.userSettings.unitColumns;

// Historical Columns
export const selectHistoricalColumns = (state: RootState) => state.userSettings.historicalColumns;

// Linked Scenarios
export const selectUserLinkedScenarios = (state: RootState) => state.userSettings.linkedScenarios;
export const selectLastRemotelyPersistedLinkedScenarios = (state: RootState) => state.userSettings.lastRemotelyPersistedLinkedScenarios;

//Linked Matrices
export const selectUserLinkedMatrices = (state: RootState) => state.userSettings.linkedMatrices;

// Scenario Order
export const selectUserScenarioOrder = (state: RootState) => state.userSettings.scenarioOrder;

export const selectAnyScenariosFetching = (state: RootState) => state.scenarios.allScenarios.status.anyFetching;

// Scenario Piece Order
export const selectUserScenarioPieceOrder = (state: RootState) => state.userSettings.scenarioPieceOrder.scenarios;

//Matrix Presets
const selectUserMatrixPresets = (state: RootState) => state.userSettings.matrixPresets;
export const selectMatrixPresets = (state: RootState) => orderByProperty([...SystemMatrixPresets, ...selectUserMatrixPresets(state)], DefaultOrders.matrixPresets);
export const selectMatrixPresetById = (state: RootState, matrixPresetId: Nullable<MatrixPresetId>) => {
  if (matrixPresetId === null) {
    return null;
  }

  const matrixPresets = selectMatrixPresets(state);
  return matrixPresets.find(mp => mp.matrixPresetId === matrixPresetId);
};

// Matrix color settings
const selectUserMatrixColorSettings = (state: RootState) => state.userSettings.matrixColorSettings;

/** Returns the user's matrix color settings, or a set of default values if those don't exist or are empty. */
export const selectUserMatrixColorSettingsOrDefault = (state: RootState) => {
  const userColorSettings = selectUserMatrixColorSettings(state);
  const palette = selectPaletteMode(state);
  const userSettingsForPalette = userColorSettings[palette];

  // Empty array means either no setting exists, or the user somehow cleared out all their colors
  // (should be impossible given UI). Return a default in this case.
  if (userSettingsForPalette === undefined || userSettingsForPalette.length === 0) {
    return defaultMatrixColors[palette];
  }

  // Or if there's at least one color, just return that.
  return userSettingsForPalette;
};

const defaultMatrixColors = {
  'light': [
    { hexColor: '#009908', colorValue: 200 },
    { hexColor: '#F2DD00', colorValue: 100 },
    { hexColor: '#737373', colorValue: 0 },
    { hexColor: '#FF7A00', colorValue: -100 },
    { hexColor: '#D21500', colorValue: -200 },
  ],
  'dark': [
    { hexColor: '#009908', colorValue: 200 },
    { hexColor: '#F2DD00', colorValue: 100 },
    { hexColor: '#D9D9D9', colorValue: 0 },
    { hexColor: '#FF7A00', colorValue: -100 },
    { hexColor: '#D21500', colorValue: -200 },
  ],
} as const;

export const { addLinkedScenario, removeLinkedScenario, addLinkedFields, removeLinkedFields, removeAllLinkedFields, setLastRemotelyPersistedLinkedScenarios, setScenarioOrder, setScenarioPieceOrder, addLinkedMatrix, removeLinkedMatrix } = userSettingsSlice.actions;
export default userSettingsSlice.reducer;
