import { useEffect, useMemo, useState } from 'react';
import { AppTask } from '../../../../types/api/AppTask';
import { getAppTasksWithCoverages, getAppTasksWithoutCoverages } from '../../../../services/appTasks.service';
import { ApplicationWizard } from '../../../../types/api/applicationWizard/applicationWizard';
import { AppTaskFields } from './applicationDecisionPage';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../../hooks/reduxHooks';
import { selectAllScenarios } from '../../../../app/scenariosSlice';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../../../app/scenarioPiecesSlice';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../../utils/nullHandling';
import { selectAllQuotesByClientFileMap } from '../../../../app/quotesSlice';
import { selectScenarioOptionDictionary } from '../../../../app/optionsSlice';
import { getKeyedStateGroupedBy, getKeyedStateToMap } from '../../../../app/sliceHelpers';
import { AgencyId, AppTaskId } from '../../../../types/api/PrimaryKeys';
import { selectCalculationResults, selectCalculationsByScenarioPiece, updateCalculationForScenarios } from '../../../../app/calculationResultsSlice';
import { selectNetAcresForScenarios } from '../../../../app/unitsSlice';
import { Nullable } from '../../../../types/util/Nullable';
import { PersonOfInterest } from '../../../../types/api/insureds/PersonOfInterest';
import { cloneDeep } from 'lodash';
import { loadAllDataForCalculations } from '../../../../app/routeDataThunks';
import { filterNotNullOrUndefined } from '../../../../utils/arrayUtils';
import { ApplicationStatusType } from '../../../../types/api/enums/application/applicationStatusType.enum';
import { selectClientFileById } from '../../../../app/clientFilesSlice';
import { handleExistingApplicationWizardTasks, mapCoverageDecisionsToAppTasks, isAppTaskEditable, handleChangeTypeForCancelTransferTypes, handleCommitNoChangeTypeForCancelTransferTypes, handleRenewTypeForCancelTransferTypes } from './appTaskUtils';
import { AppType } from '../../../../types/api/enums/application/AppType.enum';
import { ADMYear } from '../../../../types/api/adm/ADMYear';
import { getAgenciesForAgentTeam, getAgentInformationByAgentTeamId } from '../../../../services/agent.service';
import { Insured } from '../../../../types/api/insureds/Insured';
import { AgencyInformation } from '../../../../types/api/agencyInformation';
import { PersonOfInterestType } from '../../../../types/api/enums/contactInfo/personOfInterestType';
import { selectSalesClosingDates } from '../../../../app/applicationsSlice';

export const useAppTasks = (application: ApplicationWizard, admData: ADMYear[], insured: Nullable<Insured>) => {
  const dispatch = useAppDispatch();
  const [appTasks, setAppTasks] = useState<AppTask[]>([]);
  const [d365AppTasks, setD365AppTasks] = useState<Nullable<AppTask[]>>(null);
  const clientFile = useAppSelector(state => selectClientFileById(state, application.clientFileId));
  // The primary agency id is what will be used when creating new app tasks for the agency id
  const [primaryAgencyId, setPrimaryAgencyId] = useState<Nullable<AgencyId>>(null);
  // Agencies are populated based off agent team id and will be shown in the drop down for agencies (not every agent team has more than 1 agency)
  const [agencies, setAgencies] = useState<AgencyInformation[]>([]);

  // Unlike other POI types where all POIs get attached to all app tasks, for landlords and tenants
  // we must select which LLTs should be attached to which app tasks. We use this record to keep
  // track of those selections. Note, these are only require when certain options have been selected
  // in the app wizard.
  const [appTaskLandlordAndTenants, setAppTaskLandlordsAndTenants] = useState<Partial<Record<AppTaskId, PersonOfInterest[]>>>({});

  const salesClosingDates = useAppSelector(selectSalesClosingDates);
  const quotes = useKeyMapSelector(selectAllQuotesByClientFileMap, application.clientFileId);
  const allScenarios = useAppSelector(selectAllScenarios);
  const allRowCropScenarioPiecesByScenario = useAppSelector(selectAllRowCropScenarioPiecesByScenarioMap);
  const includedScenarioIds = useMemo(() => {
    return application.applicationWizardScenarios.map(x => x.scenarioId);
  }, [application]);
  const includedScenarios = filterNotNullOrUndefined(includedScenarioIds.map(x => allScenarios[x]));
  const scenarioAcres = useAppSelector(state => selectNetAcresForScenarios(state, includedScenarioIds));
  const includedQuotes = quotes.filter(q => includedScenarios.some(s => s.quoteId === q.quoteId));
  const allScenarioOptions = useAppSelector(selectScenarioOptionDictionary);
  const scenarioOptionsByScenarioId = getKeyedStateGroupedBy(allScenarioOptions, s => s.scenarioId);
  const scenarioPieceCalcs = useAppSelector(selectCalculationsByScenarioPiece);
  const scenarioCalculationResults = useAppSelector(selectCalculationResults);

  const methods = useForm<AppTaskFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      appTasks: [],
    },
    shouldUnregister: false,
  });

  const { insert, remove, replace } = useFieldArray({
    control: methods.control,
    name: 'appTasks',
  });

  const watchedAppTasks = useWatch({ name: 'appTasks', control: methods.control, defaultValue: [] });

  useEffect(() => {
    const loadAgentData = async () => {
      if (isNotNullOrUndefined(insured)) {
        const { agentTeamId } = insured;
        const agentInfo = agentTeamId ? await getAgentInformationByAgentTeamId(agentTeamId) : null;
        if (isNotNullOrUndefined(agentInfo)) {
          const { primaryAgencyId } = agentInfo;
          setPrimaryAgencyId(primaryAgencyId);
          const agencies = agentTeamId ? await getAgenciesForAgentTeam(agentTeamId) : [];
          setAgencies(agencies);
        }
      }
    };
    loadAgentData();
  }, [insured]);

  useEffect(() => {
    // Any time app tasks are modified we need to check if one of the coverages qualifies for either creating new CT app tasks, or moving a CT
    // app task's coverages back to the original app task.
    if (watchedAppTasks.length === 0) return;
    const commitNoChangeResult = dispatch(handleCommitNoChangeTypeForCancelTransferTypes(watchedAppTasks));
    const changeResult = dispatch(handleChangeTypeForCancelTransferTypes(commitNoChangeResult.appTasks));
    const renewResult = dispatch(handleRenewTypeForCancelTransferTypes(changeResult.appTasks));
    if (commitNoChangeResult.anyChanges || changeResult.anyChanges || renewResult.anyChanges) {
      setAppTasks(renewResult.appTasks);
      replace(renewResult.appTasks);
    }
    methods.trigger();
  }, [watchedAppTasks]);

  const areAllScenariosDoneCalculating = useMemo(() => {
    const scenarioCalcResultsMap = getKeyedStateToMap(scenarioCalculationResults);
    return includedScenarioIds.every(scenarioId => scenarioCalcResultsMap.get(scenarioId));
  }, [scenarioCalculationResults, includedScenarioIds]);

  const loading = appTasks.length === 0 || !areAllScenariosDoneCalculating;

  useEffect(() => {
    const loadCalcData = async () => {
      if (!clientFile) return;
      await dispatch(loadAllDataForCalculations(includedScenarioIds));
      await dispatch(updateCalculationForScenarios({ scenarioIds: includedScenarioIds }));

      if (application.applicationWizardStatusType === ApplicationStatusType.AppTasksCreated) {
        // If this application wizard has already been submitted we want to fetch the existing app tasks WITH their coverages.
        const tasksWithCoverages = (await getAppTasksWithCoverages(application.insuredId, clientFile.year, application.applicationWizardId)).data;
        const tempLlts: Record<AppTaskId, PersonOfInterest[]> = {};
        for (const appTask of tasksWithCoverages) {
          tempLlts[appTask.appTaskId] = appTask.insuredOtherPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.LandLordOrTenant);
        }
        setAppTaskLandlordsAndTenants(tempLlts);
        setD365AppTasks(tasksWithCoverages);
      } else {
        // Otherwise, if this is a brand new app wizard that hasn't been submitted before its okay to get the app tasks without
        // coverages (which is must faster lookup and easier than trying to match) and recreate coverages here because on the
        // backend we can just kill any existing coverages and recreate them as new.
        const tasks = (await getAppTasksWithoutCoverages(application.insuredId, clientFile.year, application.applicationWizardId)).data;
        setD365AppTasks(tasks);
      }
    };
    loadCalcData();
  }, [clientFile]);

  useEffect(() => {
    const createAppTasks = async () => {
      if (isNullOrUndefined(d365AppTasks)) {
        return;
      }

      if (isNullOrUndefined(clientFile)) return;

      const tasks = cloneDeep(d365AppTasks);
      for (const appTask of tasks) {
        const canEditAppTask = isAppTaskEditable(appTask);
        // Here we set some defaults for app tasks.
        // If we are not generating forms then all these values can and should be null.
        // FWIW they will also show in the UI as empty AND be disabled when not generating forms.
        if (!application.isGeneratingForms && canEditAppTask) {
          appTask.isDesignatedCounty = null;
          appTask.designatedCountyElection = null;
          appTask.isInsuringLandlordShare = null;
          appTask.isInsuringTenantShare = null;
        }

        if (application.anyChanges && canEditAppTask && application.applicationWizardStatusType === ApplicationStatusType.InProgress) {
          appTask.appType = AppType.Change;
        } else if (!application.anyChanges && canEditAppTask && application.applicationWizardStatusType === ApplicationStatusType.InProgress) {
          appTask.appType = AppType.CommitNoChange;
        }
      }

      let modifiedAppTasks: AppTask[] = [];
      if (application.applicationWizardStatusType === ApplicationStatusType.AppTasksCreated) {
        const result = handleExistingApplicationWizardTasks(application, tasks, includedQuotes, includedScenarios, allRowCropScenarioPiecesByScenario, clientFile.year, scenarioOptionsByScenarioId, scenarioAcres, scenarioPieceCalcs, admData, primaryAgencyId, salesClosingDates);
        modifiedAppTasks = result.appTasks;
      } else {
        const result = mapCoverageDecisionsToAppTasks(application.insuredId, tasks, includedQuotes, includedScenarios, allRowCropScenarioPiecesByScenario, clientFile.year, scenarioOptionsByScenarioId, scenarioAcres, scenarioPieceCalcs, application.isNewEntity, admData, primaryAgencyId, salesClosingDates);
        modifiedAppTasks = result.appTasks;
      }

      setAppTasks(modifiedAppTasks);
      remove();
      insert(0, modifiedAppTasks);
    };
    if (appTasks.length === 0 && areAllScenariosDoneCalculating && isNotNullOrUndefined(primaryAgencyId)) {
      createAppTasks();
    }
  }, [d365AppTasks, scenarioPieceCalcs, areAllScenariosDoneCalculating, clientFile, primaryAgencyId]);

  const updateLandlordsAndTenants = (appTaskId: AppTaskId, landlordsAndTenants: PersonOfInterest[]) => {
    // set state for landlords and tenants and use in this assignment
    const tempAppTaskLandlordsAndTenants = { ...appTaskLandlordAndTenants };
    tempAppTaskLandlordsAndTenants[appTaskId] = landlordsAndTenants;
    setAppTaskLandlordsAndTenants(tempAppTaskLandlordsAndTenants);
  };

  return {
    appTasks,
    methods,
    loading,
    appTaskLandlordAndTenants,
    updateLandlordsAndTenants,
    d365AppTasks,
    agencies,
  };
};