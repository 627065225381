import { Nullable } from '../../../types/util/Nullable';
import { Controller, useWatch } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { TextField } from '@mui/material';
import { MatrixOffsetType } from '../../../types/api/enums/matrixOffsetType/MatrixOffsetType.enum';
import { ChangeEvent } from 'react';

interface BottomAxisIntegerOffsetProps {
  bottomAxisIntegerOffset: Nullable<number>;
  bottomAxisOffsetType: Nullable<MatrixOffsetType>;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
}

export type BottomAxisIntegerOffsetFields = {
  bottomAxisIntegerOffset: number;
}

export const BottomAxisIntegerOffsetInput = ({ bottomAxisIntegerOffset, bottomAxisOffsetType, className, autoFocus = false, disabled = false }: BottomAxisIntegerOffsetProps) => {
  const offsetType = useWatch({ name: 'bottomAxisOffsetType', defaultValue: bottomAxisOffsetType });
  const isDisabled = disabled || offsetType !== MatrixOffsetType.UpDown;

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: { onChange: (val: Nullable<number>) => void }) => {
    const val = parseFloat(e.target.value);
    field.onChange(isNaN(val) ? null : val);
  };

  return (
    <Controller
      name="bottomAxisIntegerOffset"
      rules={{ required: isDisabled ? false : VALIDATION_RULES.required() }}
      defaultValue={bottomAxisIntegerOffset}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          className={className}
          type="number"
          label="Up/Down"
          size="small"
          fullWidth
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onChange(e, field)}
          autoFocus={autoFocus}
          disabled={isDisabled}
          inputProps={{ step: 0.1 }}
        />
      )}
    />
  );
};
