import { Grid } from '@mui/material';
import {
  addUnitYearFromForm,
  modifyUnitYear,
  selectPolicyYear,
  selectUnitYearById
} from '../../../app/unitsSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { Nullable } from '../../../types/util/Nullable';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { closeDrawer, updateOpenDrawer } from '../../../app/appDrawerSlice';
import { InsuredId, UnitYearId } from '../../../types/api/PrimaryKeys';
import StateInput, { StateFields } from '../../../components/formInputs/stateInput.component';
import CommodityInput, { CommodityFields } from '../../../components/formInputs/commodityInput.component';
import PracticeInput, { PracticeFields } from '../../../components/formInputs/practiceInput.component';
import SharePercentInput, { SharePercentFields } from '../../../components/formInputs/sharePercentInput.component';
import CountyInput, { CountyFields } from '../../../components/formInputs/countyInput.component';
import AcresInput, { AcresFields } from '../../../components/formInputs/acresInput.component';
import CommodityTypeInput, { CommodityTypeFields } from '../../../components/formInputs/commodityTypeInput.component';
import { SharePersonFields } from '../../../components/formInputs/sharePersonInput.component';
import LocationInput, { LocationFields } from '../../../components/formInputs/unit/locationInput.component';
import FarmNameInput, { FarmNameFields } from '../../../components/formInputs/unit/farmNameInput.component';
import FarmNumberInput, { FarmNumberFields } from '../../../components/formInputs/unit/farmNumberInput.component';
import SectionInput, { SectionFields } from '../../../components/formInputs/unit/sectionInput.component';
import TownshipInput, { TownshipFields } from '../../../components/formInputs/unit/townshipInput.component';
import RangeInput, { RangeFields } from '../../../components/formInputs/unit/rangeInput.component';
import '../../../components/ag-grid/ag-grid.component.css';
import { useEffect } from 'react';
import SubCountyCodeInput, { SubCountyCodeFields } from '../../../components/formInputs/unit/subCountyCodeInput.component';
import OptionalUnitNumberInput, { OptionalUnitNumberFields } from '../../../components/formInputs/unit/optionalUnitNumberInput.component';
import BasicUnitNumberInput, { BasicUnitNumberFields } from '../../../components/formInputs/unit/basicUnitNumberInput.component';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';

export interface UnitFormProps extends FormWrapperProps {
  unitYearId: Nullable<UnitYearId>;
  insuredId: InsuredId;
}

export type UnitFormFields = StateFields &
  CountyFields & CommodityFields &
  AcresFields & SharePercentFields &
  PracticeFields & CommodityTypeFields &
  BasicUnitNumberFields & OptionalUnitNumberFields &
  SharePersonFields & LocationFields &
  FarmNameFields & FarmNumberFields &
  SectionFields & TownshipFields &
  RangeFields & SubCountyCodeFields;

const UnitForm = ({ unitYearId, insuredId, registerHeader, handleValidation, isCanceling = false }: UnitFormProps) => {
  const dispatch = useAppDispatch();
  const unit = useAppSelector(s => unitYearId === null ? null : selectUnitYearById(s, unitYearId));

  // This "0" default isn't great, but it affects too much code below to change.
  const policyYear = useAppSelector(selectPolicyYear) ?? 0;
  const formId = 'unitForm';

  useEffect(() => {
    //We have a form open for a saved item that no longer exists
    if (unitYearId !== null && unit === null) {
      dispatch(closeDrawer());
    }
  }, [unitYearId, unit]);

  const methods = useForm<UnitFormFields>();

  const onSubmit: SubmitHandler<UnitFormFields> = async data => {
    if (unitYearId === null) {
      const result = await dispatch(addUnitYearFromForm({ unitYear: data, policyYear: policyYear, insuredId: insuredId }));

      if (addUnitYearFromForm.fulfilled.match(result)) {
        const addedUnitYear = result.payload;
        unitYearId = addedUnitYear.unitYearId;
        dispatch(updateOpenDrawer({ formName: 'unitForm', unitYearId: unitYearId, insuredId: insuredId, width: 600 }));
      }
      //At the current time we are not going to save the APH on creation as there are technical impediments to it. Instead we are going to save the history on edit
    } else if (!isNullOrUndefined(unit)) {
      await dispatch(modifyUnitYear({
        unitFormFields: data,
        policyYear: policyYear,
        unitYearId: unit.unitYearId,
        insuredId: insuredId,
        unitId: unit.unitId,
        unitYear: unit,
      }));
    }
  };

  const handleReset = () => {
    methods.reset();
  };

  const shouldSubmit = methods.formState.isDirty || unitYearId === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit);
  const handleSubmit = useFormWrapper('Unit', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit} onReset={handleReset}>
        <Grid container spacing={2} p={2}>
          <Grid item xs={4}>
            <BasicUnitNumberInput basicUnitNumber={unit?.basicUnitNumber ?? null} autoFocus />
          </Grid>
          <Grid item xs={4}>
            <OptionalUnitNumberInput optionalUnitNumber={unit?.optionalUnitNumber ?? null} />
          </Grid>
          <Grid item xs={4}>
            <FarmNumberInput farmNumber={unit?.farmNumber ?? null} />
          </Grid>
          <Grid item xs={12}>
            <FarmNameInput farmName={unit?.farmName ?? null} />
          </Grid>
          <Grid item xs={4}>
            <StateInput countyId={unit?.countyId ?? null} />
          </Grid>
          <Grid item xs={4}>
            <CountyInput countyId={unit?.countyId ?? null} year={unit?.year ?? policyYear} />
          </Grid>
          <Grid item xs={4}>
            <CommodityInput commodityCode={unit?.commodityCode ?? null} countyId={unit?.countyId ?? null} year={unit?.year ?? policyYear} />
          </Grid>
          <Grid item xs={4}>
            <CommodityTypeInput typeId={unit?.typeId ?? null} year={unit?.year ?? policyYear} commodityCode={unit?.commodityCode ?? null} countyId={unit?.countyId ?? null} />
          </Grid>
          <Grid item xs={4}>
            <PracticeInput
              scenarioId={null}
              practiceId={unit?.practiceId ?? null}
              year={unit?.year ?? policyYear}
              commodityCode={unit?.commodityCode ?? null}
              countyId={unit?.countyId ?? null}
              typeId={unit?.typeId ?? null} />
          </Grid>
          <Grid item xs={4}>
            <SubCountyCodeInput
              subCountyCode={unit?.subCountyCode ?? null}
              year={unit?.year ?? policyYear}
              countyId={unit?.countyId ?? null}
              typeId={unit?.typeId ?? null}
              practiceId={unit?.practiceId ?? null}
            />
          </Grid>
          <Grid item xs={4}>
            <SectionInput section={unit?.section ?? null} />
          </Grid>
          <Grid item xs={4}>
            <TownshipInput township={unit?.township ?? null} />
          </Grid>
          <Grid item xs={4}>
            <RangeInput range={unit?.range ?? null} />
          </Grid>
          <Grid item xs={12}>
            <LocationInput location={unit?.location ?? null} />
          </Grid>
          <Grid item xs={4}>
            <AcresInput acres={unit?.acres ?? null} allowZero={true} />
          </Grid>
          <Grid item xs={4}>
            <SharePercentInput sharePercent={unit?.sharePercent ?? null} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default UnitForm;
