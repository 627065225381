import { ValueParserParams } from 'ag-grid-community';
import { Nullable } from '../../../types/util/Nullable';
import { isNilOrEmpty } from '../../../utils/nullHandling';
import { optionalRoundToPlaces } from '../../../utils/rounding';

export const yieldParser = <T>(params: ValueParserParams<T>, yieldRoundingPrecision: Nullable<number>) => {
  if (isNilOrEmpty(params.newValue)) return null;

  const value = parseFloat(params.newValue);
  if (isNaN(value)) return null;

  return optionalRoundToPlaces(value, yieldRoundingPrecision);
};