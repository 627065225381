import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Nullable } from '../types/util/Nullable';
import { RootState } from './store';
import {
  ScenarioId,
  ScenarioPieceId
} from '../types/api/PrimaryKeys';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { getKeyedStateGroupedBy, getStateIdsMatching } from './sliceHelpers';
import { updateCalculationForScenario } from './calculationResultsSlice';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import { updateUnitGroupsForScenarioPiece } from './unitGroupsSlice';
import ForwardSoldScenarioPiece from '../types/api/ForwardSoldScenarioPiece';
import {
  getForwardSoldScenarioPiecesForScenarioRequest,
  addForwardSoldScenarioPieceRequest,
  updateForwardSoldScenarioPieceRequest,
  deleteForwardSoldScenarioPieceRequest, getForwardSoldScenarioPiecesForScenariosRequest
} from '../services/requestInterception/scenarioPieces/forwardSoldScenarioPieceRequestInterceptor';
import { orderMap } from '../utils/mapHelpers';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';

export interface ScenarioPiecesState {
  allScenarioPieces: SliceDataState<ScenarioPieceId, ForwardSoldScenarioPiece>;
}

const initialState: ScenarioPiecesState = {
  allScenarioPieces: initialSliceDataState(),
};

export const forwardSoldScenarioPiecesSlice = createSlice({
  name: 'forwardSoldScenarioPieces',
  initialState: initialState,
  reducers: {
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allScenarioPieces, s => s.scenarioPieceId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addForwardSoldScenarioPiece,
      affectedIds: arg => arg.scenarioPiece.scenarioPieceId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchForwardSoldScenarioPieces,
      affectedIds: (arg, state) => getStateIdsMatching(state.allScenarioPieces.data, s => s.scenarioId === arg.scenarioId, s => s.scenarioPieceId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchForwardSoldScenarioPiecesByScenarioIds,
      affectedIds: (arg, state) => getStateIdsMatching(state.allScenarioPieces.data, s => arg.scenarioIds.some(as => as === s.scenarioId), s => s.scenarioPieceId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeForwardSoldScenarioPiecesCore,
      affectedIds: arg => arg.allPiecesToDelete.map(p => p.scenarioPieceId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyForwardSoldScenarioPieces,
      affectedIds: arg => arg.scenarioPieces.map(sp => sp.scenarioPieceId),
    });
  },
});


// Memoized Selectors
const selectScenarioPieceDictionary = (state: RootState) => state.forwardSoldScenarioPieces.allScenarioPieces.data;

export const selectAllForwardSoldScenarioPiecesByScenarioMap = createSelector([selectScenarioPieceDictionary], result => {
  const map = getKeyedStateGroupedBy(result, sp => sp.scenarioId);
  const ordered = orderMap(map, DefaultOrders.forwardSoldScenarioPieces);
  return ordered;
});

// Non-Memoized Selectors
export const selectForwardSoldScenarioPiece = (state: RootState, scenarioPieceId: ScenarioPieceId): Nullable<ForwardSoldScenarioPiece> => state.forwardSoldScenarioPieces.allScenarioPieces.data[scenarioPieceId] ?? null;

export const fetchForwardSoldScenarioPieces = createAppAsyncThunk('forwardSoldScenarioPieces/fetchScenarioPieces', async ({ scenarioId }: { scenarioId: ScenarioId }) => {
  return await getForwardSoldScenarioPiecesForScenarioRequest(scenarioId);
});

export const fetchForwardSoldScenarioPiecesByScenarioIds = createAppAsyncThunk('forwardSoldScenarioPieces/fetchForwardSoldScenarioPiecesByScenarioIds', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  return await getForwardSoldScenarioPiecesForScenariosRequest(scenarioIds);
});

export const addForwardSoldScenarioPiece = createAppAsyncThunk('forwardSoldScenarioPieces/addScenarioPiece', async ({ scenarioPiece }: { scenarioPiece: ForwardSoldScenarioPiece }) => {

  await addForwardSoldScenarioPieceRequest(scenarioPiece);
  return scenarioPiece;
});

export const createDuplicatedForwardSoldScenarioPiece = (sp: ForwardSoldScenarioPiece, scenarioId: ScenarioId) => {
  const scenarioPiece: ForwardSoldScenarioPiece = {
    ...sp,
    scenarioId: scenarioId,
    forwardSoldScenarioPieceId: generatePrimaryKey(),
    scenarioPieceId: generatePrimaryKey(),
  };

  scenarioPiece.forwardSoldTransactions = scenarioPiece.forwardSoldTransactions.map(forwardSoldTransaction => {
    return {
      ...forwardSoldTransaction,
      forwardSoldTransactionId: generatePrimaryKey(),
    };
  });

  return scenarioPiece;
};

export const duplicateForwardSoldScenarioPieces = createAppAsyncThunk('forwardSoldScenarioPieces/duplicateScenarioPieces', async ({ forwardSoldScenarioPieces, scenarioId }: { forwardSoldScenarioPieces: ForwardSoldScenarioPiece[], scenarioId: ScenarioId }, thunkApi) => {
  const newPieces = forwardSoldScenarioPieces.map(async sp => {
    const scenarioPiece = createDuplicatedForwardSoldScenarioPiece(sp, scenarioId);

    await thunkApi.dispatch(addForwardSoldScenarioPiece({ scenarioPiece: scenarioPiece }));
    await thunkApi.dispatch(updateUnitGroupsForScenarioPiece({ scenarioPieceId: scenarioPiece.scenarioPieceId }));
  });
  return Promise.all(newPieces);
});

export const modifyForwardSoldScenarioPiece = createAppAsyncThunk(
  'forwardSoldScenarioPieces/modifyScenarioPiece', async ({ scenarioPiece }: { scenarioPiece: ForwardSoldScenarioPiece }, thunkApi) => {
    await thunkApi.dispatch(modifyForwardSoldScenarioPieces({ scenarioPieces: [scenarioPiece] }));
  },
);

export const modifyForwardSoldScenarioPieces = createAppAsyncThunk(
  'forwardSoldScenarioPieces/modifyScenarioPieces', async ({ scenarioPieces }: { scenarioPieces: ForwardSoldScenarioPiece[] }) => {
    const updatePromises = scenarioPieces.map(sp => updateForwardSoldScenarioPieceRequest(sp));
    await Promise.all(updatePromises);
    return scenarioPieces;
  },
);

const removeForwardSoldScenarioPiece = createAppAsyncThunk(
  'scenarios/removeScenarioPiece',
  async ({ scenarioPiece }: { scenarioPiece: ForwardSoldScenarioPiece }, thunkApi) => {
    const allPiecesToDelete = [scenarioPiece];

    await thunkApi.dispatch(removeForwardSoldScenarioPiecesCore({ allPiecesToDelete }));
  },
);

const removeForwardSoldScenarioPiecesCore = createAppAsyncThunk(
  'scenarios/remove-scenario-pieces',
  async ({ allPiecesToDelete }: { allPiecesToDelete: ForwardSoldScenarioPiece[] }, thunkApi) => {
    // short circuit because logic below requires at least one piece.
    if (allPiecesToDelete.length === 0) { return []; }

    const allPieceIdsToDelete = allPiecesToDelete.map(sp => sp.scenarioPieceId);

    //Send requests to delete all of them
    //TODO: This should probably be done as a bulk operation instead of a bunch of individual operations
    const deletePromises = allPieceIdsToDelete.map(scenarioPieceId => deleteForwardSoldScenarioPieceRequest(scenarioPieceId));

    //Wait for requests to finish
    await Promise.all(deletePromises);

    //Return IDs of objects that got deleted
    return allPiecesToDelete;
  });

export const removeForwardSoldScenarioPieceAndRecalculate = createAppAsyncThunk(
  'forwardSoldScenarioPieces/removeScenarioPieceAndRecalculate', async ({ scenarioPiece }: { scenarioPiece: ForwardSoldScenarioPiece }, thunkApi) => {
    await thunkApi.dispatch(removeForwardSoldScenarioPiece({ scenarioPiece: scenarioPiece }));

    await thunkApi.dispatch(updateCalculationForScenario({ scenarioId: scenarioPiece.scenarioId }));
  },
);

export default forwardSoldScenarioPiecesSlice.reducer;
