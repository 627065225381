import { ColDef, DataTypeDefinition, ICellRendererParams, IRowNode, ITooltipParams, ValueSetterParams } from 'ag-grid-community';
import { State } from '../../../types/api/adm/State';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';
import { PersonOrBusiness } from '../../../types/api/enums/contactInfo/personOrBusiness';
import { TaxIdLength, validateAddress, validateCity, validateEmail, validateEntityType, validateFirstName, validateLastName, validatePhoneNumber, validateState, validateTaxId, validateTaxTypeId, validateZipCode } from './validation/validateContactInfo';
import { PersonOfInterest } from '../../../types/api/insureds/PersonOfInterest';
import { Grid, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { TaxType } from '../../../types/api/insureds/TaxType';
import { EntityType } from '../../../types/api/insureds/EntityType';
import { PersonOfInterestType, PersonOfInterestTypeAttributes } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { Edit } from '@mui/icons-material';
import { ContactId } from '../../../types/api/PrimaryKeys';
import { TaxIdInputTooltip } from '../../formInputs/applications/insuredTaxIdInput';
import { getMaskedTaxId } from '../../../utils/taxIdUtils';
import SaveIcon from '@mui/icons-material/Save';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { filterEntityTypes } from '../../formInputs/applications/insuredEntityTypeInput';
import { ICellEditorParams } from 'ag-grid-enterprise';

const ignoredColumns = new Set(['taxId', 'taxIdType', 'entityType']);
const personOrBusinessColumnId = 'personBusiness';

export const getColumnDefinitions = (
  contactTypes: PersonOfInterestType[],
  states: State[],
  deleteContact: (contact: PersonOfInterest) => void,
  taxTypes: TaxType[],
  entityTypes: EntityType[],
  isGettingESignatures: boolean,
  cancelEdit: (node: IRowNode<PersonOfInterest>) => void,
  saveContact: (node: IRowNode<PersonOfInterest>) => void,
  startEdit: (index: number) => void,
  openTaxIdModal: (id: ContactId | undefined) => void,
  hasExistingTaxId: (contactId: ContactId | undefined) => boolean,
) => {
  const columnDefinitions: ColDef<PersonOfInterest>[] = [
    {
      colId: 'action',
      headerName: '',
      width: 80,
      suppressNavigable: true,
      pinned: 'left',
      suppressMovable: true,
      suppressHeaderMenuButton: true,
      resizable: false,
      cellClass: 'icon-cell',
      headerClass: 'icon-header',
      lockPinned: true,
      cellRenderer: (params: ICellRendererParams<PersonOfInterest>) => {
        const { data, node, api } = params;
        const rowIndex = node.rowIndex;
        let editingCells = api.getEditingCells();
        // checks if the rowIndex matches in at least one of the editing cells
        let isCurrentRowEditing = editingCells.some(cell => {
          return cell.rowIndex === node.rowIndex;
        });
        const areAnyCellsBeingEdited = editingCells.length > 0;
        if (isCurrentRowEditing && isNotNullOrUndefined(data)) {
          return (
            <Grid container columnGap={1} alignItems="center">
              <IconButton color="primary" id="btn-save-contact" sx={{ pr: 0, pl: .5 }} type="button" onClick={() => saveContact(node)}>
                <SaveIcon />
              </IconButton>
              <IconButton color="error" id="btn-cancel-edit-contact" sx={{ pr: 0, pl: 0 }} type="button" onClick={() => cancelEdit(node)}>
                <DoDisturbIcon />
              </IconButton>
            </Grid>
          );
        } else {
          return (
            <Grid container columnGap={1} alignItems="center">
              <Grid item>
                <IconButton disabled={areAnyCellsBeingEdited} sx={{ pr: 0, pl: .5 }} type="button" onClick={() => isNotNullOrUndefined(rowIndex) && startEdit(rowIndex)}>
                  <Edit />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton disabled={areAnyCellsBeingEdited} sx={{ pr: 0, pl: 0 }} type="button" onClick={() => params.data !== undefined && deleteContact(params.data)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        }
      },
    },
    {
      colId: 'personOfInterestType',
      headerName: 'Type',
      field: 'personOfInterestType',
      pinned: 'left',
      suppressMovable: true,
      lockPinned: true,
      editable: false,
      hide: !contactTypes.includes(PersonOfInterestType.PowerOfAttorney) && !contactTypes.includes(PersonOfInterestType.AuthorizedRep),
      suppressNavigable: params => params.data?.personOrBusiness === null,
      width: 115,
      valueFormatter: params => {
        const { data } = params;
        if (isNullOrUndefined(data)) return '';

        return PersonOfInterestTypeAttributes[data.personOfInterestType].name;
      },
    },
    {
      colId: 'firstName',
      headerName: 'Name/First',
      field: 'firstName',
      pinned: 'left',
      suppressMovable: true,
      lockPinned: true,
      editable: true,
      width: 140,
      suppressNavigable: params => params.data?.personOrBusiness === null,
      cellClassRules: {
        'invalid-cell': params =>
          isNotNullOrUndefined(params.data)
          && checkValidationResult(validateFirstName(params.data)),
      },
    },
    {
      colId: 'middleName',
      headerName: 'Middle',
      field: 'middleName',
      pinned: 'left',
      suppressMovable: true,
      lockPinned: true,
      editable: params => {
        return params.data?.personOrBusiness === PersonOrBusiness.PERSON;
      },
      width: 120,
      suppressNavigable: params => params.data?.personOrBusiness === PersonOrBusiness.BUSINESS,
    },
    {
      colId: 'lastName',
      headerName: 'Last',
      field: 'lastName',
      pinned: 'left',
      suppressMovable: true,
      lockPinned: true,
      editable: params => params.data?.personOrBusiness === PersonOrBusiness.PERSON,
      width: 130,
      suppressNavigable: params => params.data?.personOrBusiness === PersonOrBusiness.BUSINESS,
      cellClassRules: {
        'invalid-cell': params =>
          isNotNullOrUndefined(params.data)
          && checkValidationResult(validateLastName(params.data)),
      },
    },
    {
      colId: 'suffix',
      headerName: 'Suffix',
      field: 'suffix',
      pinned: 'left',
      resizable: false,
      suppressMovable: true,
      lockPinned: true,
      suppressFillHandle: true,
      editable: params => params.data?.personOrBusiness === PersonOrBusiness.PERSON,
      width: 85,
      suppressNavigable: params => params.data?.personOrBusiness === PersonOrBusiness.BUSINESS,
    },
    {
      colId: 'address',
      headerName: 'Address',
      field: 'address.addressLine1',
      editable: true,
      lockPinned: true,
      flex: 1.5,
      minWidth: 205,
      cellClassRules: {
        'invalid-cell': params =>
          isNotNullOrUndefined(params.data)
          && checkValidationResult(validateAddress(params.data)),
      },
    },
    {
      colId: 'city',
      headerName: 'City',
      field: 'address.city',
      editable: true,
      lockPinned: true,
      minWidth: 115,
      cellClassRules: {
        'invalid-cell': params =>
          isNotNullOrUndefined(params.data)
          && checkValidationResult(validateCity(params.data)),
      },
    },
    {
      colId: 'state',
      headerName: 'State',
      field: 'address.state',
      editable: true,
      minWidth: 100,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: states.map(x => x.abbrev),
        valueListMaxHeight: 200,
      },
      cellClassRules: {
        'invalid-cell': params =>
          isNotNullOrUndefined(params.data)
          && checkValidationResult(validateState(params.data)),
      },
    },
    {
      colId: 'zip',
      headerName: 'Zip',
      field: 'address.postalCode',
      editable: true,
      minWidth: 115,
      cellClassRules: {
        'invalid-cell': params =>
          isNotNullOrUndefined(params.data)
          && checkValidationResult(validateZipCode(params.data)),
      },
    },
    {
      colId: 'phone',
      headerName: 'Phone',
      field: 'phone',
      editable: true,
      minWidth: 130,
      cellClassRules: {
        'invalid-cell': params =>
          isNotNullOrUndefined(params.data)
          && checkValidationResult(validatePhoneNumber(params.data, isGettingESignatures)),
      },
    },
    {
      colId: 'email',
      headerName: 'Email',
      field: 'email',
      editable: true,
      minWidth: 100,
      cellClassRules: {
        'invalid-cell': params =>
          isNotNullOrUndefined(params.data)
          && checkValidationResult(validateEmail(params.data, isGettingESignatures)),
      },
    },
    {
      colId: 'taxId',
      headerName: 'Tax ID',
      field: 'taxId',
      editable: params => !hasExistingTaxId(params.data?.id),
      minWidth: 130,
      maxWidth: 130,
      cellClassRules: {
        'invalid-cell': params =>
          isNotNullOrUndefined(params.data)
          && checkValidationResult(validateTaxId(params.data)),
      },
      cellDataType: 'taxId',
      type: 'taxId',
      tooltipValueGetter: (params: ITooltipParams<PersonOfInterest>) => {
        let editingCells = params.api.getEditingCells();
        // checks if the rowIndex matches in at least one of the editing cells
        let isCurrentRowEditing = editingCells.some(cell => {
          return cell.rowIndex === params.node?.rowIndex;
        });
        const existingTaxId = hasExistingTaxId(params.data?.id);
        if (existingTaxId) return !isCurrentRowEditing ? TaxIdInputTooltip : 'Cannot edit Tax ID while editing row';
        return '';
      },
      cellEditorParams: { maxLength: TaxIdLength },
      valueSetter: (params: ValueSetterParams<PersonOfInterest>) => {
        // When setting the tax id value we need to remove any non-digit chars. That parsed value is what will be validated.
        // User is allowed to enter invalid chars but those will get parsed out. Even if this was a numerical input the user can
        // still enter invalid values ('-', '.', 'e') in there we'd have to parse, and the tax Id is a string on the backend so we
        // treat this as a base data type of text.
        const { newValue } = params;
        params.data.taxId = newValue.replace(/\D/g, '');
        return true;
      },
      cellRenderer: (params: ICellRendererParams<PersonOfInterest>) => {
        let editingCells = params.api.getEditingCells();
        // checks if the rowIndex matches in at least one of the editing cells
        let isCurrentRowEditing = editingCells.some(cell => {
          return cell.rowIndex === params.node.rowIndex;
        });
        const existingTaxId = hasExistingTaxId(params.data?.id);
        return (
          <Grid pl={1} pr={0} container flexWrap="nowrap" columnGap={1} alignItems="center">
            <Grid item xs>
              {getMaskedTaxId(params.data?.taxId)}
            </Grid>
            {!isCurrentRowEditing && existingTaxId && <Grid item>
              <Tooltip title="Edit Tax ID">
                <IconButton type="button" onClick={() => openTaxIdModal(params.data?.id)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            </Grid>}
          </Grid>
        );
      },
    },
    {
      colId: 'taxIdType',
      headerName: 'Tax ID Type',
      field: 'taxTypeId',
      editable: params => params.data?.personOrBusiness === PersonOrBusiness.BUSINESS && !contactTypes.includes(PersonOfInterestType.LandLordOrTenant),
      resizable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorPopup: false,
      cellEditorParams: {
        values: taxTypes.map(x => x.name),
      },
      flex: 1,
      minWidth: 125,
      cellClassRules: {
        'invalid-cell': params =>
          isNotNullOrUndefined(params.data)
          && checkValidationResult(validateTaxTypeId(params.data.personOrBusiness, params.data.taxTypeId, params.data.entityTypeId, entityTypes, taxTypes)),
      },
      valueGetter: params => {
        const { data } = params;
        return taxTypes.find(x => x.taxTypeId === data?.taxTypeId)?.name ?? '';
      },
      valueSetter: params => {
        const { newValue } = params;
        params.data.taxTypeId = taxTypes.find(x => x.name === newValue)?.taxTypeId ?? null;
        return true;
      },
      valueFormatter: params => {
        const { value } = params;
        return taxTypes.find(x => x.taxTypeId === value)?.name ?? value;
      },
      valueParser: params => {
        const { newValue } = params;
        return taxTypes.find(x => x.name === newValue)?.taxTypeId ?? newValue;
      },
    },
    {
      colId: 'entityType',
      headerName: 'Entity Type',
      field: 'entityTypeId',
      editable: params => (params.data?.personOrBusiness === PersonOrBusiness.BUSINESS),
      cellEditor: 'agSelectCellEditor',
      cellEditorPopup: false,
      cellEditorParams: (params: ICellEditorParams<PersonOfInterest>) => {
        const filteredValues = filterEntityTypes(entityTypes, params.data.personOrBusiness).map(x => x.name);
        return {
          values: filteredValues,
        };
      },
      flex: 1,
      minWidth: 220,
      cellClassRules: {
        'invalid-cell': params =>
          isNotNullOrUndefined(params.data)
          && checkValidationResult(validateEntityType(params.data)),
      },
      valueGetter: params => {
        const { data } = params;
        return entityTypes.find(x => x.entityTypeId === data?.entityTypeId)?.name ?? '';
      },
      valueSetter: params => {
        const { newValue } = params;
        params.data.entityTypeId = entityTypes.find(x => x.name === newValue)?.entityTypeId ?? null;
        return true;
      },
      valueFormatter: params => {
        const { value } = params;
        return entityTypes.find(x => x.entityTypeId === value)?.name ?? value;
      },
      valueParser: params => {
        const { newValue } = params;
        return entityTypes.find(x => x.name === newValue)?.entityTypeId ?? newValue;
      },
    },
  ];

  if (contactTypes.includes(PersonOfInterestType.PowerOfAttorney) || contactTypes.includes(PersonOfInterestType.AuthorizedToSign)) {
    return columnDefinitions.filter(x => x.colId !== undefined && x.colId !== '' && !ignoredColumns.has(x.colId) && x.colId !== personOrBusinessColumnId);
  }

  if (contactTypes.includes(PersonOfInterestType.LandLordOrTenant)) {
    return columnDefinitions.filter(x => x.colId !== personOrBusinessColumnId);
  }

  return columnDefinitions;
};

// All the validation code returns true if valid, otherwise it returns a string with the error message.
const checkValidationResult = (result: boolean | string) => result === true ? false : true;

export const DefaultColDefinition: ColDef = {
  minWidth: 20,
  resizable: true,
  sortable: true,
  editable: false,
  filter: false,
  hide: false,
  lockVisible: true,
  suppressKeyboardEvent: params => {
    const key = params.event.key;
    params.event.stopPropagation();
    return params.editing && (key === 'Enter' || key === 'ArrowUp' || key === 'ArrowDown');
  },
};



export const customDataTypeDefinitions: { [cellDataType: string]: DataTypeDefinition<PersonOfInterest> } = {
  taxId: {
    columnTypes: 'taxId',
    extendsDataType: 'text',
    baseDataType: 'text',
    valueFormatter: params => {
      const { value } = params;
      if (value === undefined || value === null || value === '') return '';
      const taxId = value.replaceAll('-', '');
      return '*****' + taxId.substring(5, taxId.length);
    },
  },
};