import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

interface ApplicationZoomState {
  leftPanelZoomPercentage: number;
  rightPanelZoomPercentage: number;
  mainContentZoomPercentage: number;
  drawerZoomPercentage: number;
}
export const defaultZoomPercentage = 100;

export const minimumZoomPercentage = 50;
export const maximumZoomPercentage = 300;

const initialState: ApplicationZoomState = {
  leftPanelZoomPercentage: defaultZoomPercentage,
  rightPanelZoomPercentage: defaultZoomPercentage,
  mainContentZoomPercentage: defaultZoomPercentage,
  drawerZoomPercentage: defaultZoomPercentage,
};

const applicationZoomSlice = createSlice({
  name: 'applicationZoom',
  initialState: initialState,
  reducers: {
    setLeftPanelZoomPercentage(state, action: PayloadAction<number>) {
      state.leftPanelZoomPercentage = action.payload;
    },
    setRightPanelZoomPercentage(state, action: PayloadAction<number>) {
      state.rightPanelZoomPercentage = action.payload;
    },
    setMainContentZoomPercentage(state, action: PayloadAction<number>) {
      state.mainContentZoomPercentage = action.payload;
    },
    setDrawerZoomPercentage(state, action: PayloadAction<number>) {
      state.drawerZoomPercentage = action.payload;
    },
  },
});

export const selectLeftPanelZoomPercentage = (state: RootState) => state.applicationZoom.leftPanelZoomPercentage;
export const selectRightPanelZoomPercentage = (state: RootState) => state.applicationZoom.rightPanelZoomPercentage;
export const selectMainContentZoomPercentage = (state: RootState) => state.applicationZoom.mainContentZoomPercentage;
export const selectDrawerZoomPercentage = (state: RootState) => state.applicationZoom.drawerZoomPercentage;

export const { setLeftPanelZoomPercentage, setRightPanelZoomPercentage, setMainContentZoomPercentage, setDrawerZoomPercentage } = applicationZoomSlice.actions;
export default applicationZoomSlice.reducer;