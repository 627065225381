import { Grid, GridSize, GridDirection, Tooltip, GridSpacing } from '@mui/material';
import { Property } from 'csstype';
import { ReactNode } from 'react';
import CurrencyText from '../currencyText/currencyText.component';
import { ToggleableTypography } from '../toggleableTypography/toggleableTypography';
interface LabelValuePairProps {
  label: string;
  value: ReactNode;
  inverseBold?: boolean;
  direction?: GridDirection;
  justifyContent?: Property.JustifyContent;
  alignItems?: Property.AlignItems;
  xs?: boolean | GridSize;
  /**
   * Optionally provide an abbreviation to show instead of the full label.
   * When abbreviation is provided a tooltip is added that on hover over the
   * abbreviation will show the full label.
   */
  abbreviation?: string;
  disabled?: boolean;
  labelColumnSize?: boolean | GridSize;
  valueColumnSize?: boolean | GridSize;
  disableToolTip?: boolean;
  spacing?: GridSpacing;
  altTooltipText?: string;
}
interface LabelCurrencyPairProps extends LabelValuePairProps {
  colorize?: boolean;
  value: number;
  isDataLoading?: boolean;
  padRight?: boolean;
  disabled?: boolean;
}

export const LabelValuePair = ({ label, value, spacing = 0, labelColumnSize = 6, valueColumnSize = 5.5, justifyContent, alignItems, inverseBold = false, disabled = false, disableToolTip = false, altTooltipText = undefined }: LabelValuePairProps) => {
  return (
    <Grid container justifyContent={justifyContent} alignItems={alignItems} spacing={spacing}>
      <Tooltip title={!disableToolTip && label}>
        <Grid item xs={labelColumnSize}>
          <ToggleableTypography disabled={disabled} sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} fontWeight={inverseBold ? 'bold' : 'normal'} variant="label">{label}</ToggleableTypography>
        </Grid>
      </Tooltip>
      <Tooltip title={!disableToolTip && (altTooltipText ?? value)}>
        <Grid item xs={valueColumnSize}>
          <ToggleableTypography disabled={disabled} sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} fontWeight={inverseBold ? 'normal' : 'bold'} align="right" variant="value">{value}</ToggleableTypography></Grid>
      </Tooltip>
    </Grid>
  );
};

export const LabelCurrencyPair = ({ label, value, padRight = true, disabled = false }: LabelCurrencyPairProps) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={6}><ToggleableTypography disabled={disabled} variant="label">{label}</ToggleableTypography></Grid>
      <Grid item xs={padRight ? 5.5 : 6}><CurrencyText disabled={disabled} currencyAmount={value} colorize={false} align={'right'} /></Grid>
    </Grid>
  );
};
export default LabelValuePair;
