import { getMPowerDPricesCaps } from '@silveus/calculations';
import { Controller, useFormContext } from 'react-hook-form';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { FormInputProps } from '../../scenario/formInputProps';
import { formatCurrency } from '../../../../utils/formatNumbers';
import { Nullable } from '../../../../types/util/Nullable';

export interface MPowerDCapInputProps extends FormInputProps {
  commodityCode: string;
  priceCap: Nullable<number>;
}

export const MPowerDCapInput = ({ priceCap, commodityCode, autoFocus = false, disabled = false }: MPowerDCapInputProps) => {
  const { control } = useFormContext();

  const priceCaps = getMPowerDPricesCaps(commodityCode).sort((a, b) => b - a);

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.PriceChangeCap}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.restrictNotOnListValue(priceCaps),
      }}
      defaultValue={priceCap}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label="Cap"
          fullWidth
          select
          value={value}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {priceCaps.map(pc => <MenuItem key={pc} value={pc}>{formatCurrency(pc, true)}</MenuItem>)}
        </TextField>
      )}
    />
  );
};
