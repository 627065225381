import ScenarioPieceDefinition from '../../../types/app/ScenarioPieceDefinition';
import validationFunctionDefault from './validationFunctionDefault';
import createScenarioPieceDtoFunctionDefault from './createScenarioPieceDtoDefault';
import {
  getDefaultQuoteSummaryData
} from '../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getDefaultQuoteSummaryData';
import { scenarioPieceFormDefault } from './scenarioPieceFormDefault';
import { getFullyQualifiedScenarioPieceNameOnlyName } from './scenarioPieceNameDefaults';
import { scenarioPieceCardChipDefault } from './scenarioPieceCardChipDefaults';

export const defaultDefinition: ScenarioPieceDefinition = {
  validationFunction: validationFunctionDefault,
  d365ProductType: undefined,
  createScenarioPieceDto: createScenarioPieceDtoFunctionDefault,
  scenarioPieceForm: scenarioPieceFormDefault,
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getDefaultQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceNameOnlyName,
  getScenarioPieceCardChips: scenarioPieceCardChipDefault,
};

export const unsetDefinition: ScenarioPieceDefinition = {
  ...defaultDefinition,
  scenarioPieceForm: () => <></>,
};