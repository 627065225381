import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectEntityTypes } from '../../../app/insuredsSlice';
import { Nullable } from '../../../types/util/Nullable';
import { EntityTypeId } from '../../../types/api/PrimaryKeys';
import { PersonOrBusiness } from '../../../types/api/enums/contactInfo/personOrBusiness';
import { useEffect, useMemo } from 'react';
import { EntityTypeEnum } from '../../../types/api/enums/application/entityType.enum';
import { EntityType } from '../../../types/api/insureds/EntityType';
import { isNotNullOrUndefined, isNull } from '../../../utils/nullHandling';


export const filterEntityTypes = (entityTypes: EntityType[], personOrBusiness: Nullable<PersonOrBusiness>) => {
  if (isNull(personOrBusiness)) return entityTypes;

  const personEntityTypes = [
    EntityTypeEnum.Individuals,
    EntityTypeEnum.SpousalMarried,
  ] as const;

  const businessEntityTypes = [
    EntityTypeEnum.IndividualOperatingAsABusiness,
    EntityTypeEnum.Partnership,
    EntityTypeEnum.Corporation,
    EntityTypeEnum.Estates,
    EntityTypeEnum.PublicSchools,
    EntityTypeEnum.BureauOfIndianAffairsAndIndianTribeTreaties,
    EntityTypeEnum.NonProfitTaxExemptOrganizations,
    EntityTypeEnum.TransferOfRightToIndemnitySBIOnly,
    EntityTypeEnum.ReceiverOrLiquidator,
    EntityTypeEnum.StateLocalGovernment,
    EntityTypeEnum.JointOperationsVenturesAndCoOwnerships,
    EntityTypeEnum.TrustRevocable,
    EntityTypeEnum.TrustIrrevocable,
    EntityTypeEnum.UndividedInterest,
    EntityTypeEnum.PublicAgencyCounty,
    EntityTypeEnum.PublicAgencyCity,
    EntityTypeEnum.LimitedLiabilityCompany,
    EntityTypeEnum.PublicAgencyState,
  ] as const;

  const personOrBusinessEntityTypes = personOrBusiness === PersonOrBusiness.PERSON ? personEntityTypes : businessEntityTypes;
  return entityTypes.filter(entityType => personOrBusinessEntityTypes.some(e => e === entityType.name));
};


type InsuredEntityTypeInputProps = {
  value: Nullable<EntityTypeId>;
  disabled: boolean;
  required?: boolean;
  personOrBusiness: Nullable<PersonOrBusiness>;
}

export type InsuredEntityTypeFields = {
  entityTypeId: Nullable<EntityTypeId>;
}

const InsuredEntityTypeInput = ({ value, disabled, required = false, personOrBusiness }: InsuredEntityTypeInputProps) => {
  const { control, setValue, watch } = useFormContext();
  const entityTypes = useAppSelector(selectEntityTypes);
  const filteredEntityTypes = useMemo(() => filterEntityTypes(entityTypes, personOrBusiness), [entityTypes, personOrBusiness]);
  const selectedEntityType = watch('entityTypeId');

  useEffect(() => {
    if (filteredEntityTypes.length > 0 && isNotNullOrUndefined(selectedEntityType) && !filteredEntityTypes.some(e => e.entityTypeId === selectedEntityType)) {
      setValue('entityTypeId', null);
    }
  }, [personOrBusiness, selectedEntityType]);

  return (
    <Controller
      control={control}
      name="entityTypeId"
      rules={{ required: required ? VALIDATION_RULES.required() : false }}
      defaultValue={value ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          disabled={disabled}
          type="text"
          label="Entity Type"
          fullWidth
          select
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          size="small"
          onChange={e => field.onChange(e.target.value)}
        >
          {filteredEntityTypes.map(type => <MenuItem key={type.entityTypeId} value={type.entityTypeId}>{type.name}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default InsuredEntityTypeInput;