import BaseUnit from '../../../types/api/BaseUnit';
import {
  CoverageTypeCode,
  VipIndemnityParams,
  VipManualPremiumParams,
  Unit,
  VipUnit,
  VipMpRequestDTO,
  ScenarioPieceType
} from '@silveus/calculations';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import { getUnitsForScenarioPiece } from '../calculationUtils';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../../app/scenarioPiecesSlice';
import { MissingUnderlyingScenarioPieceInStateError } from '../../../errors/state/MissingStateErrors';
import { getItemsForId } from '../../../utils/mapHelpers';

export const createVipMpData = (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Get the underlying MP
  const underlyingMp = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), scenario.scenarioId).find(sp => [ScenarioPieceType.MP, ScenarioPieceType.MpHpo].includes(sp.scenarioPieceType));
  if (underlyingMp === undefined) throw new MissingUnderlyingScenarioPieceInStateError(scenario.scenarioId);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createVipMpPlanDto(scenarioPiece, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece, underlyingMp.upperCoverageLevel, underlyingMp.protectionFactor, underlyingMp.scenarioPieceType);
  return scenarioPieceSpecificData;
};

const transformVipData = (scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece, underlyingCoverageLevel: number, underlyingProtectionFactor: number, basePlan: ScenarioPieceType): VipManualPremiumParams & VipIndemnityParams => {
  const vipParams: VipManualPremiumParams & VipIndemnityParams = {
    // MP has a separate projected price from the scenario so we pull it from the extended data
    projectedPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.projectedPrice ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
    underlyingScenarioPieceType: basePlan,
    underlyingCoverageLevelPercent: underlyingCoverageLevel / 100,
    underlyingLiabilityAdjustmentFactor: underlyingProtectionFactor / 100,
    priceCap: scenarioPiece.rowCropScenarioPieceExtendedData?.priceChangeCap ?? 0,
    intervalPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.topPrice ?? 0,
    liabilityAdjustmentFactor: scenarioPiece.protectionFactor / 100,

    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    unitStructureCode: scenarioPiece.unitStructure,
    coverageTypeCode: CoverageTypeCode.A,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,

    premiumPerAcre: scenarioPiece.rowCropScenarioPieceExtendedData?.customPremium ?? 0,
  };

  return vipParams;
};

const createVipMpUnits = (units: BaseUnit[]): (VipUnit & Unit)[] => {
  return units.map(unit => {
    const unitDtos = createBaseUnit(unit);
    const nonAreaRiskUnit: VipUnit & Unit = {
      ...unitDtos,

      // VipUnit
      id: unit.id,

      // Unit (comes from unitDtos)
    };

    return nonAreaRiskUnit;
  });
};

const createVipMpPlanDto = (scenarioPiece: RowCropScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[], underlyingCoverageLevel: number, underlyingProtectionFactor: number, basePlan: ScenarioPieceType): VipMpRequestDTO => {
  const result: VipMpRequestDTO = {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createVipMpUnits(
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
      ),
    ),
    ...transformVipData(scenario, scenarioPiece, underlyingCoverageLevel, underlyingProtectionFactor, basePlan),
    expectedInputCosts: scenarioPiece.rowCropScenarioPieceExtendedData?.expectedInputCosts ?? 0,
    finalInputCosts: scenarioPiece.rowCropScenarioPieceExtendedData?.actualInputCosts ?? 0,
    deductible: (scenarioPiece.rowCropScenarioPieceExtendedData?.maxInputCostDecline ?? 0) / 100,
    isInputCostEndorsementSelected: scenarioPiece.rowCropScenarioPieceExtendedData?.isInputCostEndorsement ?? false,
    underlyingEstimatedLoss: 0,
  };
  return result;
};
