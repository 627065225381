import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, SxProps, TextField, Theme, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { Nullable } from '../../types/util/Nullable';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { isNilOrEmpty, isUndefined } from '../../utils/nullHandling';
import VALIDATION_RULES from '../../constants/formValidationRules';
import {
  fetchAvailableSubCountyCodes,
  selectAvailableSubCountyCodes
} from '../../app/admSlice';
import { HighRiskAttributes, HighRiskType, HighRiskMetaTags, getSubCountyCodeFromId } from '@silveus/calculations';
import { FormInputParameterNames } from './constants/inputConstants';


interface HighRiskProps {
  highRiskTypeId: Nullable<HighRiskType>;
  year: number;
  disabled?: boolean;
  commodityCode: Nullable<string>;
  practiceId: Nullable<string>;
  countyId: string;
  typeId: Nullable<string>;
  sx?: SxProps<Theme>;
  quickQuote: boolean;
}

export type HighRiskFields = {
  highRiskTypeId: HighRiskType;
};

const HighRiskInput = ({ quickQuote, highRiskTypeId, year, typeId, practiceId, countyId, sx, disabled = false }: HighRiskProps) => {
  const dispatch = useAppDispatch();
  const { control, setValue, getValues } = useFormContext();
  const subCounties = useAppSelector(selectAvailableSubCountyCodes);
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });
  const [subCountiesLoaded, setSubCountiesLoaded] = useState(false);

  const getDefaultHighRisks = () => {
    const highRiskOptionsAvailable = subCountiesLoaded && subCounties.filter(sc => sc !== getSubCountyCodeFromId(HighRiskType.URA)).length > 0;
    // URA should always be an option
    // AllLand and HighRiskOnly should only display if there are high risk subcounties available
    return Object.values(HighRiskAttributes)
      .filter(hr =>
        hr.elements !== undefined
        &&
        (
          hr.elements.includes(HighRiskMetaTags.HighRiskIsAlwaysAvailable)
          || (highRiskOptionsAvailable && !quickQuote && hr.elements.includes(HighRiskMetaTags.AvailableIfUnitQuotingWithHighRiskOptions))
        ),
      );
  };

  const availableHighRisksObjects = Object.values(HighRiskAttributes).filter(hr => subCounties.includes(hr.name) || getDefaultHighRisks().map(x => x.value).includes(hr.value));
  const availableHighRiskValues = availableHighRisksObjects.map(x => x.value);

  const currentSetVal = getValues(FormInputParameterNames.HighRiskTypeId);

  // set input if subcounties loaded
  if (subCountiesLoaded) {
    let tempHighRisk: HighRiskType | undefined = undefined;
    if (isNilOrEmpty(highRiskTypeId) && isNilOrEmpty(currentSetVal)) {
      if (availableHighRiskValues.length === 1) {
        // Only default to a value if it's the only one in the collection per #64824
        // input is null and only one option available
        tempHighRisk = availableHighRiskValues.at(0);
      }
    } else if (!availableHighRiskValues.includes(currentSetVal)) {
      // current set is no longer on list, set to input, else first in list
      if (highRiskTypeId !== null && availableHighRiskValues.includes(highRiskTypeId)) {
        tempHighRisk = highRiskTypeId;
      } else if (availableHighRiskValues.length > 0) {
        tempHighRisk = availableHighRiskValues.at(0);
      }
    }

    if (tempHighRisk !== undefined) {
      setValue(FormInputParameterNames.HighRiskTypeId, tempHighRisk);
    }
  }

  useEffect(() => {
    setSubCountiesLoaded(false);

    if (!isUndefined(practice)) {
      setValue(FormInputParameterNames.HighRiskTypeId, '');
    }

    if (isNilOrEmpty(practice) && isNilOrEmpty(practiceId)) return;

    const type = getValues('typeId');
    const county = getValues('countyId');

    dispatch(fetchAvailableSubCountyCodes({ year: year, typeId: type ?? typeId, practiceId: practice ?? practiceId, countyId: county ?? countyId }))
      .then(() => setSubCountiesLoaded(true));
  }, [practice]);

  return (
    <Controller
      name="highRiskTypeId"
      control={control}
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={highRiskTypeId}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <Tooltip title={Object.values(HighRiskAttributes).find(x => x.value === value)?.description ?? ''} enterDelay={500}>
          <TextField
            {...field}
            type="number"
            label="High Risk"
            fullWidth
            select
            disabled={disabled}
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            sx={sx}
            value={value ?? ''}
            onChange={e => {
              field.onChange(e.target.value);
            }}
            size="small"
          >
            {availableHighRisksObjects.map(x => <MenuItem key={x.value} value={x.value}>{x.description}</MenuItem>)}
          </TextField>
        </Tooltip>
      )}
    />
  );
};

export default HighRiskInput;