import { ActionReducerMapBuilder, AsyncThunk, Draft } from '@reduxjs/toolkit';
import { Guid } from '../types/api/PrimaryKeys';
import { KeyedState, initialKeyedState, removeObjectFromKeyedState, updateKeyedState } from './sliceHelpers';
import { asArray } from '../utils/arrayUtils';

export type SliceDataState<TKey extends Guid, TObj> = {
  /** Holds the core data for a slice.
   * Note that this was implemented as separate from the status
   * for largely backwards compatibility and the common case of wanting to easily be able
   * to know when data specifically has changed, independent of loading & error state.
   */
  data: KeyedState<TKey, TObj>;
  /** Holds loading and error data. */
  status: SliceStatusRoot<TKey>;
};

type SliceStatusRoot<TKey extends Guid> = {
  /** Returns whether there are ANY known objects represented by this slice being fetched.
   * This needs to be here because looking up loading state by key does NOT allow
   * for situations where we need to know if anything, even keys we don't know exist yet, are loading.
  */
  anyFetching: boolean;
  /** This is in here for a similar reason as "anyFetching". This will hold the current error
   * for the last fetch for this slice (if any). This should only be referenced if in a situation where we can't
   * look up by id.
   */
  currentFetchError: StatusError | null;
  /** Holds loading and error data by id. */
  keyStore: SliceStatusState<TKey>;
};

type SliceStatusState<TKey extends Guid> = KeyedState<TKey, SliceStatusStateValue>;

type SliceStatusStateValue = {
  loading: LoadingState | null;
  error: StatusError | null;
};

type LoadingState = 'adding' | 'fetching' | 'updating' | 'deleting' | 'duplicating';

// Future work: customize this type down the line as we see fit.
type StatusError = string;

export const initialSliceDataState = <TKey extends Guid, TObj>(): SliceDataState<TKey, TObj> => {
  return {
    data: initialKeyedState(),
    status: {
      anyFetching: false,
      currentFetchError: null,
      keyStore: initialKeyedState(),
    },
  };
};

const initialSliceStatusStateValue = (): SliceStatusStateValue => {
  return {
    loading: null,
    error: null,
  };
};

type AsyncHandlerInputs<TState, ThunkArg, TKey extends Guid, TObj> = {
  /** The builder as provided in "extraReducers" */
  builder: ActionReducerMapBuilder<TState>;
  /** Which action this thunk corresponds with. Note that this does more than simply change the loading state and must be correct. */
  action: LoadingState;
  /** The API-wrapping thunk in question. Note that this thunk MUST return the core state object, either in single or array form. */
  thunk: AsyncThunk<TObj[], ThunkArg, {}> | AsyncThunk<TObj, ThunkArg, {}>;
  /** The ids that constitute all data that is affected by this operation. */
  affectedIds: (thunkArg: ThunkArg, state: Draft<TState>) => (TKey | TKey[]);
  /** Use this function to select the main state for this slice. This should be the big "all" property for this slice. */
  sliceState: (state: Draft<TState>) => SliceDataState<TKey, TObj>;
  /** Provide a selector to identify the main key for this slice. This is needed because otherwise there is no association we can make. */
  idSelector: (obj: TObj) => TKey;
};

/** Generates a builder capable of creating async handlers for a certain slice context, removing the need for excessive repetition. */
export const getAsyncHandlerBuilder = <TState, TKey extends Guid, TObj>(
  builder: ActionReducerMapBuilder<TState>,
  sliceState: (state: Draft<TState>) => SliceDataState<TKey, TObj>,
  idSelector: (obj: TObj) => TKey,
) => {
  const builderFunction = <ThunkArgs>({ action, thunk, affectedIds }: Pick<AsyncHandlerInputs<TState, ThunkArgs, TKey, TObj>, 'action' | 'thunk' | 'affectedIds'>) => {
    return generateAsyncHandlers({ builder, action, thunk, sliceState, idSelector, affectedIds });
  };

  return {
    generateAsyncHandlers: builderFunction,
  };
};

/**
 * Generates all standard async handlers for a thunk that is responsible for performing some
 * action that affects normalized data.
 * For common typing issues, ensure that the thunk in question returns either an object or object array associated with your main slice state.
 */
export const generateAsyncHandlers = <TState, ThunkArg, TKey extends Guid, TObj>(
  { builder, action, thunk, sliceState, idSelector, affectedIds }: AsyncHandlerInputs<TState, ThunkArg, TKey, TObj>,
) => {
  builder
    .addCase(thunk.pending, (state, { meta }) => {
      const sliceData = sliceState(state);
      const idsInThunk = affectedIds(meta.arg, state);

      // For pending requests, clear out any existing errors and then set the loading status.
      const sliceStatus = {
        error: null,
        loading: action,
      };

      // Update the key store for any values we do know are affecting.
      updateSliceKeyStoreStatus(sliceData.status.keyStore, sliceStatus, asArray(idsInThunk));

      // Specifically for the "fetch" case, mark, in a global sense, that we are fetching
      // for this slice. This is to account for ids we are not yet aware of being fetched (potentially).
      // Consumers can look at this state if they care.
      // Likewise clear out the old error as we're doing in the keyStore path.
      if (action === 'fetching') {
        sliceData.status.anyFetching = true;
        sliceData.status.currentFetchError = null;
      }
    })
    .addCase(thunk.fulfilled, (state, { payload, meta } ) => {
      const sliceData = sliceState(state);
      const idsInThunk = affectedIds(meta.arg, state);

      // As of now, "deleting" is the only special action in that it deletes from state.
      // All other current actions are just variations of appending to or updating existing state.
      if (action === 'deleting') {
        removeObjectFromKeyedState(sliceData.data, payload, idSelector);
      } else {
        updateKeyedState(sliceData.data, payload, idSelector);
      }

      // When completed, set "loading" and error to null.
      updateSliceKeyStoreStatus(sliceData.status.keyStore, { loading: null, error: null }, asArray(idsInThunk));

      // Doing the same thing here, but on the global level (specific to fetch).
      if (action === 'fetching') {
        sliceData.status.anyFetching = false;
        sliceData.status.currentFetchError = null;
      }
    })
    .addCase(thunk.rejected, (state, { meta, error }) => {
      const sliceData = sliceState(state);
      const idsInThunk = affectedIds(meta.arg, state);

      // This may change over time, just carried over from previous work.
      const errorToAddToState = error.message ?? null;

      // On an error, we still need to set loading to null, and also store the error into state.
      const sliceStatus = {
        loading: null,
        error: errorToAddToState,
      };

      // Update the key store.
      updateSliceKeyStoreStatus(sliceData.status.keyStore, sliceStatus, asArray(idsInThunk));

      // Doing the same thing here, but on the global level (specific to fetch).
      if (action === 'fetching') {
        sliceData.status.anyFetching = false;
        sliceData.status.currentFetchError = errorToAddToState;
      }
    });
};

function updateSliceKeyStoreStatus<TKey extends Guid>(sliceStatusState: SliceStatusState<TKey>, updateTemplate: Partial<SliceStatusStateValue>, idsToUpdate: TKey[]) {
  for (const id of idsToUpdate) {
    let stateAtId = sliceStatusState[id];

    if (stateAtId === undefined) {
      // MARK: This type coercion is a product of having added a nominal key to the KeyedState type.
      // While that nominal key solves some issues, it also introduces new ones, such as this.
      const freshState = initialSliceStatusStateValue() as NonNullable<SliceStatusState<TKey>[TKey]>;
      sliceStatusState[id] = freshState;
      stateAtId = freshState;
    }

    // Apply the state update
    if (updateTemplate.error !== undefined) {
      stateAtId.error = updateTemplate.error;
    }
    if (updateTemplate.loading !== undefined) {
      stateAtId.loading = updateTemplate.loading;
    }
  }
}