import EntityDiff from '../entityDiffing/entityDiff';
import { getFriendlyScenarioPieceName } from '../../../utils/scenarioPieceUtils';
import DataLocationType from '../constants/dataLocationType';
import { NamedReconciliationStack } from '../../../types/app/ReconciliationTrackedEntity';

type StackType = NamedReconciliationStack<'inputCostScenarioPieces'>;

interface Props {
  inputCostScenarioPiece: StackType;
  reconciledInputCostScenarioPiece: StackType
  onReconciliationTypeChange: (entity: StackType) => void;
  dataLocation: DataLocationType;
}

export const InputCostScenarioPieceDiff = ({ inputCostScenarioPiece, reconciledInputCostScenarioPiece, onReconciliationTypeChange, dataLocation }: Props) => {
  const scenarioPiece = inputCostScenarioPiece.entity;
  const detailComponents = [
    getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType),
  ];

  return (
    <EntityDiff
      title="Input Cost Scenario Pieces"
      subEntitySelector={() => []}
      trackedEntity={reconciledInputCostScenarioPiece}
      subsetEntity={inputCostScenarioPiece}
      detailComponents={detailComponents}
      onReconciliationTypeChange={onReconciliationTypeChange}
      preventCollapse
      dataLocation={dataLocation}
      preventSelection
      hideHeader
    />
  );
};