import { FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Info } from '../../../info/info';
import { FormInputProps } from '../../scenario/formInputProps';
import React, { useEffect } from 'react';
import { selectOfferActualCountyYield, selectOfferActualInputCosts, selectOfferExpectedCountyYield, selectOfferExpectedInputCosts, selectOfferHarvestPrice, selectOfferProjectedPrice } from '../../../../app/admSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../../types/util/Nullable';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

interface MpLockFieldsToggleInputProps extends FormInputProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  defaultValue: boolean;
}

export type MpLockFieldsToggleInputFields = {
  actualInputCosts: number;
}

const MpLockFieldsToggleInput = ({ scenarioPiece, defaultValue, disabled }: MpLockFieldsToggleInputProps) => {
  const { setValue, formState } = useFormContext();
  const projectedPrice = useAppSelector(selectOfferProjectedPrice);
  const harvestPrice = useAppSelector(selectOfferHarvestPrice);
  const expectedYield = useAppSelector(selectOfferExpectedCountyYield);
  const actualYield = useAppSelector(selectOfferActualCountyYield);
  const expectedInputCosts = useAppSelector(selectOfferExpectedInputCosts);
  const actualInputCosts = useAppSelector(selectOfferActualInputCosts);

  useEffect(() => {
    const shouldExpectedFieldsLock = projectedPrice !== null && expectedYield !== null && expectedInputCosts !== null;

    if (scenarioPiece === null){
      setValue(ExtendedDataFormInputParameterNames.AreFieldsLocked, shouldExpectedFieldsLock);
    }

  }, [projectedPrice, expectedYield, expectedInputCosts]);

  const onToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked){
      setValue(ExtendedDataFormInputParameterNames.ProjectedPrice, 0, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue('harvestPrice', 0, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue('expectedCountyYield', 0, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue('actualCountyYield', 0, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue(ExtendedDataFormInputParameterNames.ExpectedInputCosts, 0, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue(ExtendedDataFormInputParameterNames.ActualInputCosts, 0, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue(ExtendedDataFormInputParameterNames.CustomPremium, 0, { shouldValidate: formState.isSubmitted, shouldDirty: true });
    } else {
      setValue(ExtendedDataFormInputParameterNames.ProjectedPrice, projectedPrice, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue('harvestPrice', harvestPrice, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue('expectedCountyYield', expectedYield, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue('actualCountyYield', actualYield, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue(ExtendedDataFormInputParameterNames.ExpectedInputCosts, expectedInputCosts, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue(ExtendedDataFormInputParameterNames.ActualInputCosts, actualInputCosts, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue(ExtendedDataFormInputParameterNames.CustomPremium, null, { shouldValidate: formState.isSubmitted, shouldDirty: true });
    }
  };

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.AreFieldsLocked}
      defaultValue={defaultValue}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  {...field}
                  onChange={e => {
                    field.onChange(e.target.checked);
                    onToggle(e);
                  }}
                  checked={value}
                  size="small"
                  disabled={disabled}
                />
              }
              label= {<Stack direction={'row'} spacing={1}><div>Lock Fields</div> <Info tooltipContent="Unlock for manual entry." /></Stack>}
            />
          </FormGroup>
        </>
      )}

    />
  );
};

export default MpLockFieldsToggleInput;