import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { selectSelectedPremiumBreakdownScenarioId, setSelectedPremiumBreakdownScenarioId } from '../../../app/scenarioAnalysisSlice';
import { Grid, Tooltip, Typography } from '@mui/material';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { selectAllPremiumBreakdowns } from '../../../app/premiumBreakdownSlice';
import PremiumBreakdownScenarioCard from './premiumBreakdownScenarioCard.component';
import './premiumBreakdownScenarioCard.styles.css';
import { useEffect, useState } from 'react';
import PremiumBreakdownCard from './premiumBreakdownCard.component';
import ScenarioAnalysisWrapper from '../ScenarioAnalysisWrapper';
import { ScenarioChip } from '../../../components/scenarioChip/scenarioChip';

type PremiumBreakdownProps = {
  allScenariosForView: RowCropScenario[];
}

const PremiumBreakdown = ({ allScenariosForView }: PremiumBreakdownProps) => {
  const dispatch = useAppDispatch();
  const [collapsedHeader, setCollapsedHeader] = useState(false);
  const selectedScenarioId = useAppSelector(selectSelectedPremiumBreakdownScenarioId);
  const breakdowns = useAppSelector(state => selectAllPremiumBreakdowns(state));
  const filteredScenarios = allScenariosForView.filter(x => x.isVisible && breakdowns.get(x.scenarioId)?.isActive);
  const selectedBreakdown = selectedScenarioId ? breakdowns.get(selectedScenarioId) : null;

  useEffect(() => {
    // Handle the cases where we either don't have a selected scenario or where our selected scenario does not
    // exist in the list of filtered scenarios. We want to pick the first scenario if one exists in those cases
    // so that something always displays on this page if something is available to display.
    if (filteredScenarios.findIndex(x => x.scenarioId === selectedScenarioId) < 0) {
      if (filteredScenarios.length > 0) {
        const firstScenario = filteredScenarios[0];
        dispatch(setSelectedPremiumBreakdownScenarioId(firstScenario.scenarioId));
      }
    }
  }, [filteredScenarios, selectedScenarioId]);

  if (filteredScenarios.length === 0) {
    return <Typography variant="subtitle1"><p>No premium breakdowns have been added</p></Typography>;
  }

  return (
    <Grid item height="100%" sx={{ flexWrap: 'nowrap' }} columnSpacing={2}>
      <ScenarioAnalysisWrapper
        collapsed={collapsedHeader}
        onToggleCollapse={() => setCollapsedHeader(prev => !prev)}
        headerItems={filteredScenarios.map(s => {
          const premiumBreakdownForScenario = breakdowns.get(s.scenarioId);
          if (premiumBreakdownForScenario === undefined) {
            return <></>;
          }

          return (
            collapsedHeader ? (
              <Grid item xs="auto" key={s.scenarioId}>
                <Tooltip title={s.name} placement="top">
                  <ScenarioChip
                    backgroundColor={s.scenarioColor}
                    label={s.name}
                    sx={{ color: theme => theme.palette.common.white }}
                    onClick={() => dispatch(setSelectedPremiumBreakdownScenarioId(s.scenarioId))}
                    maxWidth={400}
                  />
                </Tooltip>
              </Grid>
            ) : (
              <Grid item xs="auto" key={s.scenarioId}>
                <PremiumBreakdownScenarioCard
                  premiumBreakdown={premiumBreakdownForScenario}
                  key={s.scenarioId}
                  isSelected={s.scenarioId === selectedScenarioId}
                  scenario={s}
                  onClick={() => dispatch(setSelectedPremiumBreakdownScenarioId(s.scenarioId))}
                />
              </Grid>
            ));
        })}
      >
        <Grid item xs>
          {selectedBreakdown && selectedBreakdown.isActive && (
            <PremiumBreakdownCard premiumBreakdown={selectedBreakdown} key={selectedBreakdown.premiumBreakdownId} />
          )}
        </Grid>
      </ScenarioAnalysisWrapper>
    </Grid >
  );
};

export default PremiumBreakdown;