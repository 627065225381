import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  addClientFile,
  modifyClientFile, selectClientFileById
} from '../../../app/clientFilesSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { ClientFileId } from '../../../types/api/PrimaryKeys';
import { SubmitHandler, useForm, FormProvider, useWatch } from 'react-hook-form';
import { closeDrawer, updateOpenDrawer } from '../../../app/appDrawerSlice';
import { Nullable } from '../../../types/util/Nullable';
import NameInput, { NameFields } from '../../../components/formInputs/nameInput.component';
import { YearFields } from '../../../components/formInputs/yearInput.component';
import DescriptionInput, { DescriptionFields } from '../../../components/formInputs/descriptionInput.component';
import { changeCurrentClientFile } from '../../../app/routeDataThunks';
import { useEffect } from 'react';
import { selectCurrentInsured } from '../../../app/insuredsSlice';
import { selectYears } from '../../../app/admSlice';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import AdmYearInput from '../../../components/formInputs/admYearInput.component';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import { ClientFileOwnership } from '../../../types/clientFile/ClientFileOwnership';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';

export interface ClientFileFormProps extends FormWrapperProps {
  /** The client file being edited. Null means a new client file is being created. */
  clientFileId: Nullable<ClientFileId>;

  /** The owner of the client file. This is mandatory because we still need it in the case a new client file is being created.*/
  clientFileOwnership: ClientFileOwnership;
}

export type ClientFileFormFields = NameFields & DescriptionFields & YearFields;

const ClientFileForm = ({ clientFileId, clientFileOwnership, registerHeader, handleValidation, isCanceling = false }: ClientFileFormProps) => {
  const formId = 'clientFileForm';
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clientFile = useAppSelector(s => clientFileId === null ? null : selectClientFileById(s, clientFileId));
  const insured = useAppSelector(selectCurrentInsured);
  const years = useAppSelector(selectYears);
  const methods = useForm<ClientFileFormFields>();
  const year = useWatch({ name: 'year', control: methods.control, defaultValue: clientFile?.year ?? years[0] });

  useEffect(() => {
    if (methods.formState.isDirty && !(methods.formState.dirtyFields.name ?? false)) {
      methods.setValue('name', formatName());
    }
  }, [year]);

  useEffect(() => {
    //We have a form open for a saved item that no longer exists
    if (clientFileId !== null && clientFile === null) {
      dispatch(closeDrawer());
    }
  }, [clientFileId, clientFile]);

  const formatName = () => {
    return year && insured
      ? `${year} Client File for ${insured.name}`
      : '';
  };

  const onSubmit: SubmitHandler<ClientFileFormFields> = async data => {

    if (clientFileId === null || clientFile === null) {

      // If this is a brand new client file:
      const response = await dispatch(addClientFile({
        clientFile: {
          ...data,
          ...clientFileOwnership,
          clientFileId: generatePrimaryKey<ClientFileId>(),
          offlineCreatedOn: undefined,
          offlineDeletedOn: undefined,
          offlineLastUpdatedOn: undefined,
        },
      }));

      if (addClientFile.fulfilled.match(response)) {
        const rootPath = isNotNullOrUndefined(clientFileOwnership.insuredId)
          ? `/insureds/${clientFileOwnership.insuredId}`
          : `/agent-teams/${clientFileOwnership.agentTeamId}`;

        const addClientFileResult = response.payload;

        dispatch(changeCurrentClientFile({ clientFileId: addClientFileResult.clientFileId, clientFileOwnership }));
        navigate(`${rootPath}/clientFiles/${addClientFileResult.clientFileId}`, { state: { ignorePrompt: true } });
        // On add of new client file we want to automatically open the add quote drawer
        dispatch(updateOpenDrawer({ formName: 'quoteForm', clientFileId: addClientFileResult.clientFileId, quoteId: null }));
      }
    }
    else {
      // If this is a client file being edited:
      await dispatch(modifyClientFile({ clientFile: { ...clientFile, ...data }, clientFileId: clientFileId, ...clientFileOwnership }));
    }
  };

  const shouldSubmit = methods.formState.isDirty || clientFileId === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit);
  const handleSubmit = useFormWrapper('Client File', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const handleReset = () => {
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit} onReset={handleReset}>
        <Grid container spacing={2} p={2}>
          <Grid item xs={4}>
            <AdmYearInput year={clientFile?.year ?? null} disabled={clientFile !== null} autoFocus={clientFile === null} />
          </Grid>
          <Grid item xs={12}>
            <NameInput name={clientFile?.name ?? formatName()} autoFocus={clientFile !== null} />
          </Grid>
          <Grid item xs={12}>
            <DescriptionInput description={clientFile?.description ?? null} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>);
};

export default ClientFileForm;
