import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import { Button, Grid, IconButton, TableCell, TableRow } from '@mui/material';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { FormProvider, SubmitHandler, useFieldArray } from 'react-hook-form';
import { ScenarioPieceType } from '@silveus/calculations';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { Quote } from '../../../types/api/Quote';
import { ClientFile } from '../../../types/api/ClientFile';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { InputCostEntry, InputCostScenarioPiece } from '../../../types/api/InputCostScenarioPiece';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import { addInputCostScenarioPiece, modifyInputCostScenarioPiece, removeInputCostScenarioPieceAndRecalculate } from '../../../app/inputCostScenarioPiecesSlice';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import InputCostEntryPerAcreCost from './inputCostTableComponents/inputCostEntry.PerAcreCost.component';
import InputCostEntryDescription from './inputCostTableComponents/inputCostEntry.Description.component';
import { useTheme } from '@mui/system';
import InputCostTableWrapper from './inputCostTableComponents/inputCostTableWrapper.component';
import { getInputCostTableStyles } from './inputCostTableComponents/inputCostTable.style';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { useScenarioPieceForm } from '../useScenarioPieceForm';

interface InputCostScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<InputCostScenarioPiece>;
  scenarioId: ScenarioId;
  disabled: boolean;
  quote: Quote;
  clientFile: ClientFile;
  handleCancel: () => void;
}

interface InputCostFormFields {
  inputCosts: InputCostEntry[];
}
export type InputCostScenarioPieceFormFields = InputCostFormFields & ScenarioPieceFormFields;

export const InputCostScenarioPieceForm = ({ scenarioPiece, scenarioId, disabled, registerHeader, handleCancel, quote, clientFile, handleValidation, isCanceling = false }: InputCostScenarioPieceFormProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const inputCostTableStyle = getInputCostTableStyles(theme);

  const methods = useScenarioPieceForm<InputCostScenarioPieceFormFields>(scenarioPiece, {
    inputCosts: scenarioPiece?.inputCosts ?? [{ description: 'Total', perAcreCost: 0 }],
  });
  const { fields, append, remove } = useFieldArray({ control: methods.control, name: 'inputCosts' });
  const formId = 'inputCostScenarioPieceForm';

  const handleAddInputCost = (): void => {
    append({ description: '', perAcreCost: 0 });
  };

  const onSubmit: SubmitHandler<InputCostScenarioPieceFormFields> = async data => {
    const newScenarioPiece: InputCostScenarioPiece = {
      isActive: data.isActive,
      isInvalid: false,
      inputCostScenarioPieceId: scenarioPiece?.inputCostScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: ScenarioPieceType.InputCosts,
      inputCosts: data.inputCosts,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(addInputCostScenarioPiece({ scenarioPiece: newScenarioPiece }));
    } else {
      //Existing scenario piece
      await dispatch(modifyInputCostScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);
  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;
    await dispatch(removeInputCostScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid container item xs={12} justifyContent="end">
              <Button id="btn-add-year" variant="contained" onClick={handleAddInputCost}>Add Cost</Button>
            </Grid>
            <Grid item xs={12}>
              <InputCostTableWrapper includeActionColumn={true} tableBodyContents={
                fields.map((item, index) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell sx={inputCostTableStyle.bodyCell}>
                        <InputCostEntryDescription index={index} />
                      </TableCell>
                      <TableCell sx={inputCostTableStyle.bodyCell}>
                        <InputCostEntryPerAcreCost index={index} />
                      </TableCell>
                      <TableCell sx={inputCostTableStyle.actionCell}>
                        <IconButton size="small" onClick={() => remove(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              } />
            </Grid>
            <Grid item xs={12}>
              <IsActiveInput isActive={scenarioPiece?.isActive ?? null} disabled={false} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
    </>
  );
};
