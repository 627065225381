import * as React from 'react';
import { Grid, Tooltip } from '@mui/material';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import Matrix from '../../../types/api/Matrix';
import { modifyMatrix } from '../../../app/matricesSlice';
import { openDrawer } from '../../../app/appDrawerSlice';
import { setCurrentlySelectedScenarioId } from '../../../app/scenariosSlice';
import { ScenarioChip } from '../../../components/scenarioChip/scenarioChip';

type IncludedScenariosProps = {
  includedScenarios: RowCropScenario[];
  matrix: Matrix;
};

const IncludedScenarios = ({ includedScenarios, matrix }: IncludedScenariosProps) => {
  const dispatch = useAppDispatch();

  const handleRemoveScenario = (scenario: RowCropScenario) => {
    const filteredScenarios = includedScenarios.map(s => s.scenarioId).filter(s => s !== scenario.scenarioId);
    dispatch(modifyMatrix({ matrixData: matrix, matrix: matrix, includedScenarios: filteredScenarios }));
  };

  const openScenario = (scenario: RowCropScenario, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();  // We don't want the accordion to collapse or expand when the chip is clicked
    dispatch(setCurrentlySelectedScenarioId(scenario.scenarioId));
    dispatch(openDrawer({ formName: 'scenarioForm', scenarioId: scenario.scenarioId, quoteId: scenario.quoteId }));
  };

  return (
    <Grid container columnGap={1} rowGap={1} pb={1}>
      {includedScenarios
        //Sort the scenarios so that the primary scenario is always first
        .sort((a, b) => (a.scenarioId === matrix.primaryScenarioId ? -1 : b.scenarioId === matrix.primaryScenarioId ? 1 : 0))
        .map(s => (
          <Tooltip key={s.scenarioId} title={s.name} placement="top">
            <ScenarioChip
              backgroundColor={s.scenarioColor}
              label={s.name}
              onClick={e => openScenario(s, e)}
              sx={{ color: theme => theme.palette.common.white }}
              onDelete={matrix.primaryScenarioId !== s.scenarioId ? () => handleRemoveScenario(s) : undefined}
            />
          </Tooltip>
        ))}
    </Grid>
  );
};

export default IncludedScenarios;