import { ScenarioPieceGroupResponseDTO, ScenarioPieceResponseDTO } from '@silveus/calculations';
import { Nullable } from '../../types/util/Nullable';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectScenariosThatAreLoadingCalculations } from '../../app/calculationResultsSlice';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { Grid } from '@mui/material';
import { LabelCurrencyPair } from '../../components/labelValuePair/labelValuePair.component';
import * as React from 'react';
import CurrencyText from '../../components/currencyText/currencyText.component';

interface ScenarioComponentSubItemSummaryProps {
  calcResults: Nullable<ScenarioPieceResponseDTO | ScenarioPieceGroupResponseDTO>;
  label: string;
  scenarioId: ScenarioId;
  netValue: number;
  hideCoverage?: boolean;
}

const ScenarioComponentSubItemSummary = ({ calcResults, label, scenarioId, netValue, hideCoverage = false }: ScenarioComponentSubItemSummaryProps) => {
  const isScenarioCalculating = useAppSelector(selectScenariosThatAreLoadingCalculations)[scenarioId] ?? false;
  const shouldDisplayNetAsLoading = isScenarioCalculating && calcResults === null;

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} sx={{ borderBottom: '1px solid #666666' }} />
      <Grid item container xs={12} alignItems="center" sx={{ marginTop: '0.5rem' }}>
        <Grid item xs={6} sx={{ color: theme => theme.palette.primary.main, fontSize: '14px', fontWeight: 'bold' }}>
          {label}
        </Grid>
        <Grid item xs={6}>
          <CurrencyText align="right" sx={{ fontSize: 14, fontWeight: 'bold' }} currencyAmount={netValue} colorize isDataLoading={shouldDisplayNetAsLoading} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={1} />
          <Grid item container xs={11}>
            {!hideCoverage && <Grid item xs={12}><LabelCurrencyPair label="Coverage" value={calcResults?.protectionAmountPerAcre ?? 0} padRight={false} /></Grid>}
            <Grid item xs={12}><LabelCurrencyPair label="Indemnity" value={calcResults?.indemnityPerAcre ?? 0} padRight={false} /></Grid>
            <Grid item xs={12}><LabelCurrencyPair label="Producer Premium" value={calcResults?.producerPremiumPerAcre ?? 0} padRight={false} /></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScenarioComponentSubItemSummary;