import { roundToPlaces } from '@silveus/calculations';
import { selectUniqueCoverageLevelPairings } from '../../../app/admSlice';
import { CoverageLevelPair } from '../../../types/api/adm/CoverageLevelPair';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { LowerCoverageLevelInput, LowerCoverageLevelProps } from './lowerCoverageLevelInput.component';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { FormInputParameterNames } from '../constants/inputConstants';
import { isNotNilOrEmpty, isNotNullOrUndefined } from '../../../utils/nullHandling';

export interface AdmLowerCoverageLevelProps extends LowerCoverageLevelProps {
  lowestAvailableCoverageLevel?: number | null
}

//TODO #61244: this will need to get fixed up when validation from other product changes
// is implemented (if the mpci upper level changes, what uses lowestAvailableCoverageLevel could be invalid)

export const AdmLowerCoverageLevelInput = ({
  lowerCoverageLevel,
  lowestAvailableCoverageLevel = null,
  autoFocus = false,
  disabled = false }: AdmLowerCoverageLevelProps) => {

  const { control, getValues } = useFormContext();
  const coverageLevelPairs = useAppSelector(selectUniqueCoverageLevelPairings);

  const upperCoverage = getValues(FormInputParameterNames.UpperCoverageLevelName);
  const selectedUpperCoverageLevel = useWatch({ name: FormInputParameterNames.UpperCoverageLevelName, control: control, defaultValue: undefined });

  const [useableCoverageLevelPairings, setUseableCoverageLevelPairings] = useState<CoverageLevelPair[]>([]);

  useEffect(() => {
    let useablePairings: CoverageLevelPair[] = [];
    const normalizedPairings: CoverageLevelPair[] = coverageLevelPairs.map(x => {
      return {
        lowerCoverageLevel: roundToPlaces(x.lowerCoverageLevel * 100, 0),
        upperCoverageLevel: roundToPlaces(x.upperCoverageLevel * 100, 0),
      };
    });

    const validLowerLimit = isNotNullOrUndefined(lowestAvailableCoverageLevel);
    const validUpper =
      (isNotNullOrUndefined(upperCoverage) && isNotNilOrEmpty(upperCoverage))
      &&
      (
        !validLowerLimit
        ||
        (
          normalizedPairings.find(x => x.upperCoverageLevel === upperCoverage && x.lowerCoverageLevel >= lowestAvailableCoverageLevel) !== undefined
          && upperCoverage >= lowestAvailableCoverageLevel
        )
      );

    if (validUpper) {
      useablePairings = normalizedPairings.filter(x => x.upperCoverageLevel === upperCoverage && x.lowerCoverageLevel <= upperCoverage);
    } else {
      useablePairings = normalizedPairings;
    }

    if (validLowerLimit) {
      useablePairings = useablePairings.filter(x => x.lowerCoverageLevel >= lowestAvailableCoverageLevel && x.upperCoverageLevel >= lowestAvailableCoverageLevel);
    }

    useablePairings.sort((a, b) => {
      if (a.upperCoverageLevel === b.upperCoverageLevel) {
        return b.lowerCoverageLevel - a.lowerCoverageLevel;
      }
      return b.upperCoverageLevel - a.upperCoverageLevel;
    });

    setUseableCoverageLevelPairings(useablePairings);
  }, [lowestAvailableCoverageLevel, selectedUpperCoverageLevel, upperCoverage, lowerCoverageLevel, coverageLevelPairs]);

  return (<LowerCoverageLevelInput
    lowerCoverageLevel={lowerCoverageLevel}
    autoFocus={autoFocus}
    disabled={disabled}
    availableCoverageLevels={[]}
    coverageLevelPairings={useableCoverageLevelPairings} />);
};

