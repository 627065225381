

import { selectScenarioById } from '../../../../../app/scenariosSlice';
import { useAppSelector } from '../../../../../hooks/reduxHooks';
import HistoricalAnalysis from '../../../../../types/api/historicalAnalysis';
import { Chip, Grid, SxProps, Theme, Typography } from '@mui/material';
import { AcreageTypeAttributes } from '@silveus/calculations';
import HistoricalIncludeTypeAttributes from '../../../../../types/api/enums/historicalAnalysis/HistoricalIncludetypeAttributes';
import HistoricalShowTypeAttributes from '../../../../../types/api/enums/historicalAnalysis/historicalShowTypeAttributes';
import { isNullOrUndefined } from '../../../../../utils/nullHandling';

type HistoricalChartSettingsProps = {
  historicalAnalysis: HistoricalAnalysis;
  containerSx?: SxProps<Theme>;
};

interface SettingChip {
  label: string,
  shouldShow?: () => boolean,
}

const defaultShouldShow = () => true;

export const HistoricalChartSettings = ({ historicalAnalysis, containerSx }: HistoricalChartSettingsProps) => {
  const selectedScenario = useAppSelector(state => selectScenarioById(state, historicalAnalysis.primaryScenarioId));
  const selectedScenarioColor = selectedScenario?.scenarioColor ?? '';

  const chips: SettingChip[] = [
    { label: `${historicalAnalysis.startYear} - ${historicalAnalysis.endYear}` },
    { label: `${HistoricalIncludeTypeAttributes[historicalAnalysis.historicalIncludeType].description}` },
    { label: `${HistoricalShowTypeAttributes[historicalAnalysis.historicalShowType].description}` },
    { label: `${AcreageTypeAttributes[historicalAnalysis.acreageType].description}` },
    { label: 'Calc Missing Prod', shouldShow: () => historicalAnalysis.calculateMissingYears },
  ];

  return (
    <Grid sx={{ ...containerSx }} container spacing={0.75} direction="row">
      {chips.map(chip => (
        (isNullOrUndefined(chip.shouldShow) ? defaultShouldShow() : chip.shouldShow()) &&
        <Grid item>
          <Chip
            key={chip.label}
            label={<Typography color={theme => theme.palette.common.white} variant="subtitle2">{chip.label}</Typography>}
            size="small"
            sx={{
              backgroundColor: selectedScenarioColor,
            }}
          />
        </Grid>
      ))
      }
    </Grid >
  );
};
