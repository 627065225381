import { HailUnit, Unit } from '@silveus/calculations';
import { getHailRateForUnitFromHailRateMap, getHailRatesByTownshipRange } from '../../../pages/hailModal/utils/hailRateUtils';
import { HailEndorsementId, HailRateId, HailScenarioPieceId, UnitYearId } from '../../../types/api/PrimaryKeys';
import { HailCalculationParamsEndorsementRequest, HailCalculationParamsRateRequest } from '../../../types/api/calculationData/hailCalculationParamsRequest';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import HailEndorsement from '../../../types/api/hail/hailEndorsement';
import HailRate from '../../../types/api/hail/hailRate';
import HailScenarioPieceEndorsement from '../../../types/api/hail/HailScenarioPieceEndorsement';
import BaseUnit from '../../../types/api/BaseUnit';
import HailScenarioPieceRate from '../../../types/api/hail/HailScenarioPieceRate';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { Quote } from '../../../types/api/Quote';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { RootState } from '../../../app/store';
import { selectAdjustedYieldForScenario, selectApprovedYieldForScenario } from '../../../app/unitsSlice';
import { getItemsForId } from '../../../utils/mapHelpers';
import { selectHailScenarioPieceEndorsementsByHailScenarioPieceMap, selectHailScenarioPieceRatesByHailScenarioPieceMap } from '../../../app/hailSlice';
import {
  selectHailScenarioPieceModalEndorsementsByHailScenarioPieceMap,
  selectHailScenarioPieceModalRatesByHailScenarioPieceMap,
  selectHailScenarioPieceModalUnitsByHailScenarioPieceId
} from '../../../app/hailModalSlice';
import { UnitGroup } from '../../../types/api/UnitGroup';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import { getUnitsForScenarioPiece } from '../calculationUtils';
import { getHailEndorsementsRequest, getHailRatesRequest } from '../../requestInterception/scenarioPieces/hailScenarioPieceRequestInterceptor';
import { createBaseUnit } from '../baseDataTransformations';
export const createCalcRateParamRequest = (hailRateIds: HailRateId[]): HailCalculationParamsRateRequest => {
  const hailCalcParamRequest: HailCalculationParamsRateRequest = {
    hailRateIds: hailRateIds,
  };
  return hailCalcParamRequest;
};

export const createCalcEndorsementParamRequest = (hailEndorsementIds: HailEndorsementId[], countyId: string, commodityCode: string): HailCalculationParamsEndorsementRequest => {
  const hailCalcParamRequest: HailCalculationParamsEndorsementRequest = {
    hailEndorsementIds: hailEndorsementIds,
    countyId: countyId,
    commodityCode: commodityCode,
  };
  return hailCalcParamRequest;
};

export const createHailUnits = (
  hailRates: HailRate[],
  hailEndorsements: HailEndorsement[],
  hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[],
  units: BaseUnit[]): (HailUnit & Unit)[] => {
  const ratesByTownshipRange = getHailRatesByTownshipRange(hailRates);
  const hailEndorsementRatesByHailEndorsement = new Map<HailEndorsementId, Map<string, HailRate>>();
  hailEndorsements.forEach(endorsement => {
    hailEndorsementRatesByHailEndorsement.set(endorsement.hailPlanEndorsementId, getHailRatesByTownshipRange(endorsement.hailRates));
  });

  return (units.map(unit => {
    const baseRate = getHailRateForUnitFromHailRateMap(unit, ratesByTownshipRange);
    //TODO: Error Handling
    if (isNullOrUndefined(baseRate)) return null;

    let perCoverageEndorsementRate = 0;
    let perAcreEndorsementRate = 0;
    hailEndorsements.forEach(endorsement => {
      const endorsementRatesByTownshipRange = hailEndorsementRatesByHailEndorsement.get(endorsement.hailPlanEndorsementId);
      const endorsementRate = endorsementRatesByTownshipRange === undefined ? undefined : getHailRateForUnitFromHailRateMap(unit, endorsementRatesByTownshipRange);
      const hailScenarioPieceEndorsement = hailScenarioPieceEndorsements.find(hspe => hspe.endorsementId === endorsement.hailPlanEndorsementId);

      if (hailScenarioPieceEndorsement?.perAcre === true) {
        perAcreEndorsementRate += endorsementRate?.rate ?? 0;
      } else {
        perCoverageEndorsementRate += endorsementRate?.rate ?? 0;
      }
    });

    const unitDto = createBaseUnit(unit);
    const hailUnit: HailUnit & Unit = {
      ...unitDto,
      id: unit.id,
      ratePerCoverage: baseRate.rate + perCoverageEndorsementRate,
      ratePerAcre: perAcreEndorsementRate,
    };

    return hailUnit;
  }).filter((item): item is HailUnit & Unit => item !== null));
};

export const createFakeBaseUnitsForQuickQuoting = (state: RootState, hailScenarioPieceRates: HailScenarioPieceRate[], hailRates: HailRate[], scenario: RowCropScenario, quote: Quote, cropYear: number): BaseUnit[] => {
  return hailScenarioPieceRates.filter(r => r.acres !== null && r.acres > 0 && hailRates.some(hr => hr.rateId === r.rateId)).map(spr => {
    const hailRate = hailRates.find(hr => hr.rateId === spr.rateId);
    if (hailRate === undefined) {
      throw new Error('Hail Data Transform Utils: Unable to find hail rate from hail scenario piece rate when creating units for quick quoting');
    }
    const fakeBaseHailUnit: BaseUnit = {
      id: spr.hailScenarioPieceRateId,
      year: cropYear,
      commodityCode: quote.commodityCode,
      countyId: quote.countyId,
      typeId: scenario.typeId,
      practiceId: scenario.practiceId ?? '',
      subCountyCode: null,
      acres: spr.acres ?? 0,
      sharePercent: scenario.quickUnit?.sharePercent ?? 1,
      options: [],
      approvedYield: selectApprovedYieldForScenario(state, scenario.scenarioId, quote.countyId, quote.commodityCode) ?? scenario.quickUnit?.aphYield ?? 0,
      adjustedYield: selectAdjustedYieldForScenario(state, scenario.scenarioId, quote.countyId, quote.commodityCode) ?? 0,
      actualYield: scenario.actualProducerYield ?? 0,
      rateYield: scenario.quickUnit?.rateYield ?? 0,
      township: hailRate.township,
      range: hailRate.range,
    };

    return fakeBaseHailUnit;
  });
};

export const getHailScenarioPieceRates = async (state: RootState, hailScenarioPieceId: HailScenarioPieceId): Promise<{ hailScenarioPieceRates: HailScenarioPieceRate[], hailRates: HailRate[] }> => {
  //If we're in the modal, we need to use the modal data otherwise we use scenario data
  const hailScenarioPieceModalRates = getItemsForId(selectHailScenarioPieceModalRatesByHailScenarioPieceMap(state), hailScenarioPieceId);
  const hailScenarioPieceRates = hailScenarioPieceModalRates.length > 0 ? hailScenarioPieceModalRates :
    getItemsForId(selectHailScenarioPieceRatesByHailScenarioPieceMap(state), hailScenarioPieceId);
  const hailRateParamRequest = createCalcRateParamRequest(hailScenarioPieceRates.map(r => r.rateId));
  if (hailRateParamRequest.hailRateIds.length === 0) throw new Error('Zero Hail Rates Present while creating Hail Data');
  const hailRates = await getHailRatesRequest(hailRateParamRequest);
  return { hailScenarioPieceRates, hailRates };
};

export const getHailScenarioPieceEndorsements = async (state: RootState, hailScenarioPieceId: HailScenarioPieceId, quote: Quote)
  : Promise<{ hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[], hailEndorsements: HailEndorsement[] }> => {
  //If we're in the modal, we need to use the modal data otherwise we use scenario data
  const hailScenarioPieceModalEndorsements = getItemsForId(selectHailScenarioPieceModalEndorsementsByHailScenarioPieceMap(state), hailScenarioPieceId);
  const hailScenarioPieceEndorsements = hailScenarioPieceModalEndorsements.length > 0 ? hailScenarioPieceModalEndorsements :
    getItemsForId(selectHailScenarioPieceEndorsementsByHailScenarioPieceMap(state), hailScenarioPieceId);
  const hailEndorsementParamRequest = createCalcEndorsementParamRequest(hailScenarioPieceEndorsements.map(he => he.endorsementId), quote.countyId, quote.commodityCode);
  const hailEndorsements = hailEndorsementParamRequest.hailEndorsementIds.length === 0 ? [] : await getHailEndorsementsRequest(hailEndorsementParamRequest);

  return { hailScenarioPieceEndorsements, hailEndorsements };
};

export const getHailScenarioPieceUnits = (state: RootState,
  hailScenarioPiece: HailScenarioPiece,
  quote: Quote,
  scenario: RowCropScenario,
  baseUnits: BaseUnit[],
  hailScenarioPieceRates: HailScenarioPieceRate[],
  hailRates: HailRate[],
  cropYear: number,
  shouldSimulateUnitGroups: boolean)
  : { unitGroupsForScenarioPiece: UnitGroup[], unitsForScenarioPiece: BaseUnit[] } => {
  let unitGroupsForScenarioPiece: UnitGroup[];
  let unitsForScenarioPiece: BaseUnit[];
  if (quote.quickQuote) {
    //Create fake units since users can type in acres per different rates when quick quoting hail
    const fakeUnits = createFakeBaseUnitsForQuickQuoting(state, hailScenarioPieceRates, hailRates, scenario, quote, cropYear);
    //We always set shouldSimulateUnitGroups to true when quick quoting due to how we create fake units
    unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, hailScenarioPiece.scenarioPieceId, scenario.scenarioId, true, fakeUnits.map(u => toPrimaryKey<UnitYearId>(u.id)));
    unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, fakeUnits);
  } else {
    //We either use the units from modal slice while quoting from the modal otherwise use the actual unit groups.
    const hailScenarioPieceUnitYearIds = selectHailScenarioPieceModalUnitsByHailScenarioPieceId(state, hailScenarioPiece.hailScenarioPieceId).unitYearIds;
    unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, hailScenarioPiece.scenarioPieceId, scenario.scenarioId, shouldSimulateUnitGroups, hailScenarioPieceUnitYearIds);
    unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);
  }
  return { unitGroupsForScenarioPiece, unitsForScenarioPiece };
};
