import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { Controller } from 'react-hook-form';

interface HistoricalColumnToggleInputProps {
  name: string;
  defaultValue: boolean;
  label: string;
}

const HistoricalColumnToggleInput = ({ name, defaultValue, label }: HistoricalColumnToggleInputProps) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                {...field}
                onChange={e => field.onChange(e.target.checked)}
                checked={value}
                size="small"
              />
            } label={label} />
        </FormGroup>
      )}

    />
  );
};

export default HistoricalColumnToggleInput;