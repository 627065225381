
import { Grid } from '@mui/material';
import { FormInputProps } from '../../scenario/formInputProps';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import { Nullable } from '../../../../types/util/Nullable';
import PercentChangeSliderInput from '../../percentChangeSliderInput.component';
import FarmBillPriceInput from './farmBillPriceInput';
import { ScenarioPieceType } from '@silveus/calculations';
import { selectFarmBillDefaultActualPrice, selectFarmBillDefaultExpectedPrice } from '../../../../app/admSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { ReactNode, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Info } from '../../../info/info';

interface FarmBillPriceInputGroupProps extends FormInputProps {
  year: number;
  commodityCode: string;
  expectedPrice: Nullable<number>;
  actualPrice: Nullable<number>;
  scenarioPieceType: ScenarioPieceType;
  projectedPriceName?: string;
  actualPriceName?: string;
}

const FarmBillPriceInputGroup = (
  {
    year,
    disabled,
    commodityCode,
    expectedPrice,
    actualPrice,
    scenarioPieceType,
    projectedPriceName = ExtendedDataFormInputParameterNames.ProjectedPrice,
    actualPriceName = ExtendedDataFormInputParameterNames.ActualPrice,
  }: FarmBillPriceInputGroupProps,
) => {
  const methods = useFormContext();
  const lastScenarioPieceType = useRef(scenarioPieceType);

  const defaultExpectedPriceData = useAppSelector(state => selectFarmBillDefaultExpectedPrice(state, year, commodityCode, scenarioPieceType));
  const defaultActualPriceData = useAppSelector(state => selectFarmBillDefaultActualPrice(state, year, commodityCode));
  const actualPriceToShow = actualPrice ?? expectedPrice ?? (defaultActualPriceData.value === 0 ? defaultExpectedPriceData.value : defaultActualPriceData.value);

  useEffect(() => {
    if (scenarioPieceType === lastScenarioPieceType.current) {
      return;
    }

    methods.setValue(ExtendedDataFormInputParameterNames.ProjectedPrice, defaultExpectedPriceData.value);
    methods.setValue(ExtendedDataFormInputParameterNames.ActualPrice, defaultActualPriceData.value === 0 ? defaultExpectedPriceData.value : defaultActualPriceData.value);
    lastScenarioPieceType.current = scenarioPieceType;
  }, [defaultExpectedPriceData]);

  const createToolTip = (lines: string[], backup?: string, centerAllButFirst: boolean = false) => {
    let input = [...lines];
    if (input.length <= 0) {
      if (backup === undefined) {
        return undefined;
      }

      input = [backup];
    }

    let toolTipLine1: ReactNode = <div></div>;
    const toolTipLinesRest: ReactNode[] = [];

    for (const [index, item] of input.entries()) {
      if (index === 0) {
        toolTipLine1 = item;
      } else {
        toolTipLinesRest.push(item);
        toolTipLinesRest.push(<br />);
      }
    }

    const toolTipLines: ReactNode[] = [toolTipLine1];
    if (centerAllButFirst && toolTipLinesRest.length > 0) {
      toolTipLines.push(<>
        <div style={{ textAlign: 'center' }}>
          {toolTipLinesRest}
        </div >
      </>);
    } else {
      toolTipLines.push(toolTipLinesRest);
    }

    const text = <span>{toolTipLines}</span>;
    return <Info tooltipContent={text} />;
  };

  const toolTipExpected = createToolTip(defaultExpectedPriceData.hintLines, undefined, defaultExpectedPriceData.centerAllButFirst);
  const toolTipActual = createToolTip(defaultActualPriceData.hintLines, `No available prices for ${year} or ${year - 1}`, defaultActualPriceData.centerAllButFirst);

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={4}>
        <FarmBillPriceInput
          name={projectedPriceName}
          disabled={disabled}
          label="Benchmark Price"
          price={expectedPrice ?? defaultExpectedPriceData.value}
          projectedPriceName={projectedPriceName}
          actualPriceName={actualPriceName}
          toolTip={toolTipExpected}
        />
      </Grid>
      <Grid item xs={4}>
        <FarmBillPriceInput
          name={actualPriceName}
          disabled={disabled}
          label="Actual Price"
          price={actualPriceToShow}
          projectedPriceName={projectedPriceName}
          actualPriceName={actualPriceName}
          toolTip={toolTipActual}
        />
      </Grid>
      <Grid item xs={4}>
        <PercentChangeSliderInput
          estimatedValueFieldName={projectedPriceName}
          actualValueFieldName={actualPriceName}
          sliderName={'farmBillPriceSlider'}
          initialEstimatedValue={expectedPrice}
          initialActualValue={actualPrice}
        />
      </Grid>
    </Grid>
  );
};


export default FarmBillPriceInputGroup;
