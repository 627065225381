import { Grid, IconButton } from '@mui/material';
import ClientFileTemplatedScenarioList from '../clientFile/component/clientFileTemplatedScenarioList.component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getScalingTransformString } from '../../utils/css/scaling';
import { ReactNode } from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectMainContentZoomPercentage } from '../../app/applicationZoomSlice';

type ScenarioAnalysisWrapperProps = {
  headerItems: ReactNode;
  onToggleCollapse: () => void;
  collapsed: boolean;
  children: ReactNode;
};

const ScenarioAnalysisWrapper = ({ headerItems, onToggleCollapse, collapsed, children }: ScenarioAnalysisWrapperProps) => {
  const zoomPercentage = useAppSelector(selectMainContentZoomPercentage);
  const inverseZoomPercentage = zoomPercentage <= 0
    ? 100
    : 100 / zoomPercentage * 100;

  return (
    <Grid item height={`${inverseZoomPercentage}%`} width={`${inverseZoomPercentage}%`} rowSpacing={2} wrap="nowrap" sx={{ transform: getScalingTransformString(zoomPercentage), transformOrigin: 'top left' }}>
      <Grid container wrap="nowrap" height="100%" direction="column">
        <Grid item xs="auto">
          <Grid container wrap="nowrap">
            <Grid item xs="auto" pt={0.5}>
              <IconButton onClick={onToggleCollapse}>
                {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Grid>
            <Grid item xs sx={{ overflowX: 'auto' }} wrap="nowrap">
              <ClientFileTemplatedScenarioList>
                {headerItems}
              </ClientFileTemplatedScenarioList>
            </Grid>
          </Grid>
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
};

export default ScenarioAnalysisWrapper;