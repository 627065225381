import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { ChangeEvent, ReactNode } from 'react';
import { isNumber } from 'lodash';
import { selectCurrentClientFile } from '../../../app/clientFilesSlice';
import TrendlineAnalysis from '../../../types/api/TrendlineAnalysis';
import { modifyTrendline } from '../../../app/trendlineAnalysisSlice';
import { AcreageTypeAttributes } from '@silveus/calculations';

const minTrendlineYear = 1970;

type TrendlineChartControlsProps = {
  trendlineAnalysis: TrendlineAnalysis;
  maxWidth: string;
}

const TrendlineChartControls = ({ trendlineAnalysis, maxWidth }: TrendlineChartControlsProps) => {
  const dispatch = useAppDispatch();
  const clientFile = useAppSelector(selectCurrentClientFile);

  const generateYearsArray = () => {
    if (clientFile === null) return [];

    const possibleYears: number[] = [];

    let sy = minTrendlineYear;
    while (sy <= clientFile.year) {
      possibleYears.push(sy);
      sy++;
    }

    return possibleYears;
  };

  const yearsArray = generateYearsArray();

  const toggleShowChanged = async () => {
    await dispatch(modifyTrendline({ trendline: { ...trendlineAnalysis, showLabels: !trendlineAnalysis.showLabels } }));
  };

  const onEndYearChanged = async (event: SelectChangeEvent<number>, child: ReactNode) => {
    const val = isNumber(event.target.value) ? event.target.value : parseInt(event.target.value);
    await dispatch(modifyTrendline({ trendline: { ...trendlineAnalysis, endYear: val } }));
  };

  const onStartYearChanged = async (event: SelectChangeEvent<number>, child: ReactNode) => {
    const val = isNumber(event.target.value) ? event.target.value : parseInt(event.target.value);
    await dispatch(modifyTrendline({ trendline: { ...trendlineAnalysis, startYear: val } }));
  };

  const handleAcreTypeChanged = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = isNumber(event.target.value) ? event.target.value : parseInt(event.target.value);
    await dispatch(modifyTrendline({ trendline: { ...trendlineAnalysis, acreageType: val } }));
  };

  return (
    <Grid container spacing={1} rowGap={0.5} sx={{ maxWidth: maxWidth }}>
      <Grid item xs>
        <FormControl fullWidth>
          <InputLabel id="start-year-select-label">Start Year</InputLabel>
          <Select
            labelId="start-year-select-label"
            id="start-year-select"
            value={trendlineAnalysis.startYear}
            label="Start Year"
            onChange={onStartYearChanged}
            size="small"
          >
            {yearsArray.map(year => (
              <MenuItem key={year} value={year} disabled={year > trendlineAnalysis.endYear}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs>
        <FormControl fullWidth>
          <InputLabel id="end-year-select-label">End Year</InputLabel>
          <Select
            labelId="end-year-select-label"
            id="end-year-select"
            value={trendlineAnalysis.endYear}
            label="End Year"
            onChange={onEndYearChanged}
            size="small"
          >
            {yearsArray.map(year => (
              <MenuItem key={year} value={year} disabled={year < trendlineAnalysis.startYear}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={trendlineAnalysis.acreageType}
          type="number"
          label="Acre Type"
          fullWidth
          select
          onChange={handleAcreTypeChanged}
          size="small"
        >
          {Object.values(AcreageTypeAttributes).map(acreType => <MenuItem key={acreType.value} value={acreType.value}>{acreType.description}</MenuItem>)}
        </TextField>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
        <FormControlLabel control={<Checkbox onClick={toggleShowChanged} checked={trendlineAnalysis.showLabels} />} label="Show Labels" />
      </Grid>
    </Grid>
  );
};

export default TrendlineChartControls;