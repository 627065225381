import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { AppTaskCoverage } from '../../../types/api/appTaskCoverage';
import { handleValidationResult } from './validationHelpers';
import { validateEstimatedPremium } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import NumberTextField, { onNumericInputChange } from '../../numberTextField/numberTextField.component';

type EstimatedGrossPremiumInputProps = {
  value: Nullable<number>;
  appTaskIndex: number;
  coverageIndex: number;
}

const EstimatedGrossPremiumInput = ({ value, appTaskIndex, coverageIndex }: EstimatedGrossPremiumInputProps) => {
  const inputName = `appTasks.${appTaskIndex}.coverages.${coverageIndex}.estimatedGrossPremium`;
  const { control } = useFormContext();

  const currentCoverage: AppTaskCoverage = useWatch({ name: `appTasks.${appTaskIndex}.coverages.${coverageIndex}`, control });
  const isEditable = (currentCoverage.intendedNetAcres ?? 0) > 0 && (value ?? 0) === 0;

  const nonEditableStyle = {
    border: 'none',
    '& fieldset': { border: 'none' },
  };

  return (
    <Controller
      name={inputName}
      control={control}
      defaultValue={value}
      rules={{ validate: _ => handleValidationResult(validateEstimatedPremium(currentCoverage)) }}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          fullWidth
          error={!!error}
          sx={!isEditable ? nonEditableStyle : undefined}
          {...field}
          inputRef={ref}
          size="small"
          onChange={e => onNumericInputChange(e, field)}
          inputProps={{
            sx: { fontSize: '12px !important', textAlign: 'right', type: 'number' },
            readOnly: !isEditable,
          }}
        />
      )}
    />
  );
};

export default EstimatedGrossPremiumInput;