import { Nullable } from '../../../types/util/Nullable';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlLabel, FormHelperText, Checkbox } from '@mui/material';

interface IsActiveProps {
  isActive: Nullable<boolean>;
  autoFocus?: boolean;
  disabled?: boolean;
}

export type IsActiveFields = {
  isActive: boolean;
}

const IsActiveInput = ({ isActive, autoFocus = false, disabled = false }: IsActiveProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="isActive"
      control={control}
      defaultValue={isActive ?? true}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl
          error={!!error}>
          <FormControlLabel
            label="Active"
            control={
              <Checkbox
                {...field}
                disabled={disabled}
                checked={value}
                inputRef={ref}
                onChange={e => field.onChange(e.target.checked)}
                autoFocus={autoFocus}
                size="small"
              />
            }
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default IsActiveInput;
