import BaseUnit from '../../../types/api/BaseUnit';
import {
  sum,
  Commodity,
  CoverageTypeCode,
  GuaranteeAdjustmentTypeCode,
  RpIndemnityParams,
  RpPremiumParams,
  UnitOfMeasure,
  RpUnit,
  RpRequestDTO,
  UnitStructureCode,
  OptionCode,
  Unit
} from '@silveus/calculations';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import RpCalculationParams from '../../../types/api/calculationData/rpCalculationParams';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import { getRpCalcDataRequest } from '../../requestInterception/scenarioPieces/rpRequestInterceptor';
import { getCalcParamRequestUnits, getMatchingCalcParamDataForUnit, getUnitsForScenarioPiece } from '../calculationUtils';
import { RpCalculationParamsRequest } from '../../../types/api/calculationData/rpCalculationParamsRequest';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import { Nullable } from '../../../types/util/Nullable';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';

export const createRpData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  //Get the calc data back from the API
  const calcParamRequest = createCalcParamRequest(state, scenarioPiece, scenario, baseUnits);
  const returnedData = await getRpCalcDataRequest(calcParamRequest);

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createRpPlanDto(scenarioPiece, returnedData, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformRpData = (rpCalcData: Nullable<RpCalculationParams>, unit: Nullable<BaseUnit>, scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece): RpPremiumParams & RpIndemnityParams => {
  const rpParams: RpPremiumParams & RpIndemnityParams = {
    scenarioPieceType: scenarioPiece.scenarioPieceType,
    harvestPrice: scenario.harvestPrice ?? 0,
    projectedPrice: scenario.projectedPrice ?? 0,
    commodityCode: (unit?.commodityCode ?? '') as Commodity,
    commodityYear: unit?.year ?? 0,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    unitStructureCode: scenarioPiece.unitStructure,
    coverageTypeCode: CoverageTypeCode.A,
    comboRevenueFactors: rpCalcData?.comboRevenueFactors ?? [],
    multipleCommodityAdjustmentFactor: rpCalcData?.multipleCommodityAdjustmentFactor ?? 0,
    optionConversionFactor: rpCalcData?.optionConversionFactor ?? null,
    premiumSurchargeApplied: rpCalcData?.premiumSurchargeApplied ?? '',
    previousYearYieldLimitationCode: rpCalcData?.previousYearYieldLimitationCode ?? '',
    subsidyPercent: rpCalcData?.subsidyPercent ?? 0,
    unitOfMeasure: (rpCalcData?.unitOfMeasure ?? '') as UnitOfMeasure,
    wholeFarmAcreage: rpCalcData?.wholeFarmAcreage ?? 0,
    priceElectionPercent: scenarioPiece.protectionFactor / 100,
    conservComplSubsidyReductionPercent: 0,
    hasWrittenAgreement: false,
    isBeginningOrVeteranFarmerOrRancher: false,
    isConservationCompliance: false,
    isNativeSod: false,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
  };

  return rpParams;
};

const createRpUnits = (scenarioPiece: RowCropScenarioPiece, rpCalcData: RpCalculationParams[], units: BaseUnit[], scenario: RowCropScenario): (RpUnit & Unit)[] => {
  const unitGroupAcres = sum(units, unit => unit.acres);

  return units.map(unit => {

    const matchingData = getMatchingCalcParamDataForUnit(unit, rpCalcData);

    //TODO: Error Handling
    if (isNullOrUndefined(matchingData)) throw new Error('RP Data Transform State mismatch: Unable to find data returned from API associated with unit in state.');
    const unitDtos = createBaseUnit(unit);
    const nonAreaRiskUnit: RpUnit & Unit = {
      ...unitDtos,
      id: unit.id,
      optionRates: matchingData.optionRates,
      historicalRevenueCapping: matchingData.historicalRevenueCapping,
      guaranteeAdjustmentFactor: matchingData.guaranteeAdjustmentFactor,
      guaranteeAdjustmentTypeCode: matchingData.guaranteeAdjustmentTypeCode as GuaranteeAdjustmentTypeCode,
      revenueDrawQuantities: matchingData.betaSequences,
      unitDiscountLevels: matchingData.unitDiscountLevels.filter(udl => udl.areaHighQuantity !== null && udl.areaLowQuantity !== null && udl.areaHighQuantity >= unitGroupAcres && unitGroupAcres >= udl.areaLowQuantity),
      coverageLevelDifferentialValues: matchingData.coverageLevelDifferentials,
      subCountyRate: matchingData.subCountyRate,
      baseRate: matchingData.baseRate,
      priceVolatilityFactor: scenario.volatility ?? 0,
      //TODO #61367: This is a temporary workaround until we get a more permanent solution for handling options per scenario piece
      options: unit.options.filter(optionCode => optionCode !== OptionCode.TS),
    };

    return nonAreaRiskUnit;
  });
};

const createRpPlanDto = (scenarioPiece: RowCropScenarioPiece, apiData: RpCalculationParams[], unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): RpRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createRpUnits(
        scenarioPiece,
        apiData,
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformRpData(apiData.at(0) ?? null, unitData.at(0) ?? null, scenario, scenarioPiece),
  };
};

const createCalcParamRequest = (state: RootState, scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, baseUnits: BaseUnit[]): RpCalculationParamsRequest => {
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  return {
    year: clientFile.year,
    highRiskTypeId: scenario.highRiskTypeId,
    coverageTypeCode: (scenarioPiece.rowCropScenarioPieceExtendedData?.isCat ?? false) ? CoverageTypeCode[CoverageTypeCode.C] : CoverageTypeCode[CoverageTypeCode.A],
    unitStructureCode: UnitStructureCode[scenarioPiece.unitStructure],
    planCode: scenarioPiece.planCode,
    upperCoverageLevel: scenarioPiece.upperCoverageLevel,
    commodityCode: quote.commodityCode,
    countyId: quote.countyId,
    distinctUnits: getCalcParamRequestUnits(baseUnits),
  };
};
