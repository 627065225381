import { MenuItem, TextField } from '@mui/material';
import { HistoricalShowType } from '../../../types/api/enums/historicalAnalysis/historicalShowType.enum';
import HistoricalShowTypeAttributes from '../../../types/api/enums/historicalAnalysis/historicalShowTypeAttributes';
import { Controller } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';

interface HistoricalShowInputProps {
  value: HistoricalShowType;
}

export type HistoricalShowFields = {
  historicalShowType: HistoricalShowType;
}

const HistoricalShowInput = ({ value }: HistoricalShowInputProps) => {
  return (
    <Controller
      name="historicalShowType"
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label="Show"
          fullWidth
          select
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          size="small"
          onChange={e => field.onChange(e.target.value)}
        >
          {Object.values(HistoricalShowTypeAttributes).map(st => {
            return (
              <MenuItem key={st.value} value={st.value}>{st.name}</MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
};

export default HistoricalShowInput;