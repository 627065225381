import { useAppDispatch } from '../../../hooks/reduxHooks';
import HarvestRevenueScenarioPiece from '../../../types/api/HarvestRevenueScenarioPiece';
import { modifyHarvestRevenueScenarioPiece } from '../../../app/harvestRevenueScenarioPiecesSlice';
import InactiveScenarioPieceCard from '../inactiveScenarioPieceCard.component';

interface InactiveHarvestRevenueScenarioPieceCardProps {
  scenarioPiece: HarvestRevenueScenarioPiece;
  isScenarioActive: boolean;
}

const InactiveHarvestRevenueScenarioPieceCard = ({ scenarioPiece, isScenarioActive }: InactiveHarvestRevenueScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();

  const updateScenarioPiece = async (scenarioPiece: HarvestRevenueScenarioPiece) => {
    await dispatch(modifyHarvestRevenueScenarioPiece({ scenarioPiece: { ...scenarioPiece, isActive: true } }));
  };

  return (
    <InactiveScenarioPieceCard
      scenarioPiece={scenarioPiece}
      isScenarioActive={isScenarioActive}
      label="Harvest Revenue"
      updateScenarioPiece={updateScenarioPiece}
    />
  );
};

export default InactiveHarvestRevenueScenarioPieceCard;