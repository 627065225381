import axios from 'axios';
import { ClientFileId, ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import { RowCropScenarioPiece } from '../types/api/RowCropScenarioPiece';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { envBaseApiUrl } from '../constants/envConstants';

export const getScenarioPiecesForScenario = (scenarioId: ScenarioId) => axios.get<RowCropScenarioPiece[]>(`${envBaseApiUrl}/row-crop-scenario-pieces/scenarios/${scenarioId}`);

export const getScenarioPiecesForClientFile = (clientFileId: ClientFileId) => axios.get<RowCropScenarioPiece[]>(`${envBaseApiUrl}/row-crop-scenario-pieces/client-files/${clientFileId}`);

export const getScenarioPiecesForScenarios = (scenarioIds: ScenarioId[]) => axios.post<RowCropScenarioPiece[]>(`${envBaseApiUrl}/row-crop-scenario-pieces/scenarios`, scenarioIds);

export const getScenarioPiece = (scenarioPieceId: ScenarioPieceId) => axios.get<RowCropScenarioPiece>(`${envBaseApiUrl}/row-crop-scenario-pieces/${scenarioPieceId}`);

export const addScenarioPiece = (scenarioPiece: RowCropScenarioPiece) => axios.post<CreatedItemResult<ScenarioPieceId>>(`${envBaseApiUrl}/row-crop-scenario-pieces`, scenarioPiece);

export const updateScenarioPiece = (scenarioPiece: RowCropScenarioPiece) => axios.put<void>(`${envBaseApiUrl}/row-crop-scenario-pieces/`, scenarioPiece);

export const deleteRowCropScenarioPiece = (scenarioPieceId: ScenarioPieceId) => axios.delete<void>(`${envBaseApiUrl}/row-crop-scenario-pieces/${scenarioPieceId}/`);
