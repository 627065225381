import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';


import { createStaxData } from '../../../../services/calculations/stax/staxDataTransformations';
import StaxScenarioPieceForm from '../../../../pages/scenarioPiece/stax/staxScenarioPieceForm.component';
import { getStaxQuoteSummaryData } from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getStaxQuoteSummaryData';
import { validateStax } from '../../../../utils/validation/scenarioPieces/staxValidation';
import {
  getFullyQualifiedScenarioPieceNameOnlyCoverageLevel
} from '../../defaultDefinitions/scenarioPieceNameDefaults';
import {
  ProtectionFactorScenarioPieceChip
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';

import { ScenarioPiece } from '../../../../types/api/ScenarioPiece';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

export const staxDefinition: ScenarioPieceDefinition = {
  validationFunction: validateStax,
  d365ProductType: ProductType.MPCI,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, _aphYield, _shouldSimulateUnitGroups) => {
    return createStaxData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <StaxScenarioPieceForm {...props} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}/>;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getStaxQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
    return getFullyQualifiedScenarioPieceNameOnlyCoverageLevel(scenarioPiece, true);
  },
  getScenarioPieceCardChips: [ProtectionFactorScenarioPieceChip],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#DC143C',
};
