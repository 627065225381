import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import ScenarioPieceClassification from '@silveus/calculations/dist/lookups/scenarioPieceClassification/ScenarioPieceClassification';
import PremiumBreakdownData from '../../../types/app/PremiumBreakdownData';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { setSelectedPremiumBreakdownData } from '../../../app/premiumBreakdownSlice';
import { selectPremiumBreakdownOptions } from '../../../app/userSettingsSlice';
import { useTheme } from '@mui/material';

type PremiumBreakdownBarChartProps = {
  sortedData: PremiumBreakdownData[];
}

type PremiumBreakdownChartData = {
  name: string;
  values: Partial<Record<string, number>>;
}

const PremiumBreakdownBarChart = ({ sortedData }: PremiumBreakdownBarChartProps) => {
  const dispatch = useAppDispatch();
  const premiumBreakdownOptions = useAppSelector(selectPremiumBreakdownOptions);
  const theme = useTheme();
  const shouldShowPremium = premiumBreakdownOptions.details.premiumPerAcre;
  const shouldShowCoverage = premiumBreakdownOptions.details.coveragePerAcre;
  const formatYTick = (value: number): string => {
    return `${value} %`;
  };

  const buildChartData = () => {
    const data: PremiumBreakdownChartData[] = [
      {
        name: 'COUNTY',
        values: {},
      },
      {
        name: 'INDIVIDUAL',
        values: {},
      },
    ];

    sortedData.filter(x => x.classification === ScenarioPieceClassification.Individual).forEach(ind => {
      data[1].values[`${ind.id}`] = ind.height;
    });

    sortedData.filter(x => x.classification === ScenarioPieceClassification.County).forEach(cou => {
      data[0].values[`${cou.id}`] = cou.height;
    });

    return data;
  };

  const theData = buildChartData();

  const renderCustomBarLabel = (data: PremiumBreakdownData, props: { x: number, y: number, width: number, height: number, index: number }) => {
    const { x, y, width, height, index } = props;
    // If our height is less than 5 we don't have room for a label so we won't show it
    const isBarTooSmallForLabel = data.height < 5;
    // ReCharts will try and add labels for series that may not exist in the current data.
    // For example, if the county grouping has an SCO scenario piece ReCharts will try and add
    // the SCO label for the individual grouping as well.
    const doesBarExistInCollection = theData[index].values[data.id];
    // Since we often have to add spacers before/after bars to get the 'floating' to work
    // we need to know if the current data is just a spacer so we can ignore labels for it.
    const isSpacerBar = data.isSpacer;
    const areAllLabelsToggledOff = !shouldShowCoverage && !shouldShowPremium;

    if (doesBarExistInCollection === undefined || isBarTooSmallForLabel || (isSpacerBar ?? false) || areAllLabelsToggledOff) {
      return (
        <text x={x} y={y}>
          <tspan></tspan>
        </text>
      );
    }

    const coverage = data.coverage !== undefined ? Math.round(data.coverage) : 0;
    const premium = data.premium !== undefined ? Math.round(data.premium) : 0;

    // This is the compact mode for these labels. Premium and coverage will be shown in a single
    // line with no 'P' or 'C' prefix.
    if (data.height < 8) {
      if (shouldShowPremium && shouldShowCoverage) {
        return (
          <SingleLineLabel x={x} y={y} width={width} height={height} onClick={() => onBarClick(data)} label={`$${premium}/$${coverage}`} />
        );
      } else if (shouldShowPremium) {
        return (
          <SingleLineLabel x={x} y={y} width={width} height={height} onClick={() => onBarClick(data)} label={`$${premium}`} />
        );
      } else {
        return (
          <SingleLineLabel x={x} y={y} width={width} height={height} onClick={() => onBarClick(data)} label={`$${coverage}`} />
        );
      }
    }

    return (
      <text x={x} y={y} fill="Black" dx={width / 2} dy={height / 2 - 2} style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => onBarClick(data)}>
        {/* To hide premium we need to change the fill to transparent rather than just hiding the element in order to keep our placement of coverage centered properly */}
        <tspan textAnchor="middle" x={x} fill={shouldShowPremium ? 'Black' : 'transparent'} >P: ${premium}</tspan>
        <tspan textAnchor="middle" x={x} dx={width / 2} dy={shouldShowPremium ? 15 : 0}>{shouldShowCoverage ? `C: $${coverage}` : ' '}</tspan>
      </text>
    );
  };

  const onBarClick = (premiumBreakdownData: PremiumBreakdownData) => {
    if (!(premiumBreakdownData.isSpacer ?? false)) {
      dispatch(setSelectedPremiumBreakdownData(premiumBreakdownData));
    }
  };

  return (
    <ResponsiveContainer height="99%" width="99%" minWidth="475px">
      <BarChart margin={{ right: 22, top: 22, bottom: 22 }} data={theData} barCategoryGap={10}>
        <YAxis style={{ fill: `${theme.palette.premiumBreakdown.labelColor}` }} type="number" domain={[0, 100]} tickFormatter={formatYTick} tickLine={false} ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} axisLine={false} />
        <CartesianGrid vertical={false} />
        <XAxis style={{ fill: `${theme.palette.premiumBreakdown.labelColor}` }} dataKey="name" orientation="top" axisLine={false} tickLine={false} />
        <ReferenceLine stroke={theme.palette.premiumBreakdown.labelColor} x="COUNTY" position="end" strokeWidth={6} />
        {sortedData.map(cd => (
          <Bar
            style={{ cursor: cd.isSpacer ?? false ? 'default' : 'pointer' }}
            dataKey={`values.${cd.id}`}
            stackId={cd.stackId}
            fill={cd.fill}
            key={cd.id}
            label={props => renderCustomBarLabel(cd, props)}
            isAnimationActive={false}
            onClick={() => onBarClick(cd)}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

interface SingleLineLabelProps {
  x: number;
  y: number;
  height: number;
  width: number;
  label: string;
  onClick: () => void;
}

const SingleLineLabel = ({ x, y, height, width, label, onClick }: SingleLineLabelProps) => {
  return (
    <text x={x} y={y} fill="Black" dy={height / 2 + 4} style={{ cursor: 'pointer', userSelect: 'none' }} onClick={onClick}>
      <tspan textAnchor="middle" x={x} dx={width / 2}>{label}</tspan>
    </text>
  );
};

export default PremiumBreakdownBarChart;