import { Grid } from '@mui/material';
import { ReactNode } from 'react';

type ClientFileTemplatedScenarioListProps = {
  children: ReactNode;
}

const ClientFileTemplatedScenarioList = ({ children }: ClientFileTemplatedScenarioListProps) => {
  return (
    <Grid
      container
      padding={2}
      wrap="nowrap"
      columnGap={2}
      width="100%"
      sx={{ overflowX: 'auto' }}
    >
      {children}
    </Grid>
  );
};

export default ClientFileTemplatedScenarioList;