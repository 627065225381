import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';

type InfoCorrectYesNoInputProps = {
  value: Nullable<boolean>;
}

export type InfoCorrectYesNoInputFields = {
  isAboveCorrect: boolean;
}

const InfoCorrectYesNoInput = ({ value }: InfoCorrectYesNoInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="isAboveCorrect"
      defaultValue={value}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Grid container sx={{ alignItems: 'center', width: '100%', flexWrap: 'nowrap' }}>
          <Grid item xs={6}>
            <FormLabel id="demo-error-radios">
              <Typography variant="subtitle1">Is the above correct?</Typography>
            </FormLabel>
          </Grid>
          <Grid item xs={6}>
            <RadioGroup
              row
              value={value}
              name="isAboveCorrect"
              // using some regex here because we don't want the string version of the value, we want the boolean
              onChange={e => field.onChange(/true/i.test(e.target.value))}>
              <FormControlLabel value={true} control={<Radio value={true} name="yes"/>} label="Yes" labelPlacement="start" />
              <FormControlLabel value={false} control={<Radio value={false} name="no"/>} label="No" labelPlacement="start" />
            </RadioGroup>
          </Grid>
        </Grid>
      )}
    />
  );
};

export default InfoCorrectYesNoInput;