import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import App from './App';
import { reactPlugin } from './AppInsights';
import { msalInstance } from './authConfig';
import Theme from './components/theme/theme.component';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { injectStore } from './utils/isOnlineFromStore';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<App />} />));

injectStore(store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={msalInstance}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <Theme>
              <RouterProvider router={router} />
            </Theme>
          </AppInsightsContext.Provider>
        </MsalProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

serviceWorkerRegistration.register({
  onUpdate: async registration => {
    // See: https://github.com/facebook/create-react-app/issues/5316

    // The end effect this has - immediately updates the service worker (and thus all cached files) when we push out updates as opposed to the user having to
    // close out all browser tabs to get the update. This is not necessarily required by anything I know of, but it makes things much easier to manage on deploys, and makes
    // the browser's refresh button work as expected for the user.

    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration.waiting) {
      await registration.unregister();
      // Makes Workbox call skipWaiting()
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)
      window.location.reload();
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();