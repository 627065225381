import { LowerCoverageLevelInput } from './lowerCoverageLevelInput.component';

export const SingleOptionDisabledLowerCoverageLevelInput = ({ lowerCoverageLevel,
  autoFocus = false }: { lowerCoverageLevel: number, autoFocus?: boolean },
) => {
  const availableCoverageLevels = [lowerCoverageLevel];

  return <LowerCoverageLevelInput lowerCoverageLevel={lowerCoverageLevel}
    availableCoverageLevels={availableCoverageLevels} autoFocus={autoFocus}
    disabled={true} />;
};
