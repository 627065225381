import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';


import { useAppDispatch } from '../../hooks/reduxHooks';
import { modifyScenarioPiece } from '../../app/scenarioPiecesSlice';
import InactiveScenarioPieceCard from './inactiveScenarioPieceCard.component';
import { getScenarioPieceDefinition } from '../../constants/productDefinitions/scenarioPieceDefinitionRecords';

interface InactiveRowCropScenarioPieceCardProps {
  scenarioPiece: RowCropScenarioPiece;
  isScenarioActive: boolean;
}

const InactiveRowCropScenarioPieceCard = ({ scenarioPiece, isScenarioActive }: InactiveRowCropScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();

  const updateScenarioPiece = async (scenarioPiece: RowCropScenarioPiece) => {
    await dispatch(modifyScenarioPiece({ scenarioPiece: scenarioPiece }));
  };

  const scenarioPieceDefinition = getScenarioPieceDefinition(scenarioPiece.scenarioPieceType);
  const scenarioPieceNameLabel = scenarioPieceDefinition.getFullyQualifiedScenarioPieceName(scenarioPiece);

  return (
    <InactiveScenarioPieceCard
      scenarioPiece={scenarioPiece}
      isScenarioActive={isScenarioActive}
      label={scenarioPieceNameLabel}
      updateScenarioPiece={updateScenarioPiece}
    />
  );
};

export default InactiveRowCropScenarioPieceCard;
