import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { Nullable } from '../../util/Nullable';
import { StrictOmit } from '../../util/StrictOmit';
import { ApplicationWizardAppTask } from '../applicaitonWizardAppTask';
import { ApplicationStatusType } from '../enums/application/applicationStatusType.enum';
import { Insured } from '../insureds/Insured';
import { ApplicationWizardId, ApplicationWizardScenarioId, ClientFileId, InsuredId, ScenarioId } from '../PrimaryKeys';

export interface ApplicationWizard {
  applicationWizardId: ApplicationWizardId;
  insuredId: InsuredId;
  newInsuredId: Nullable<InsuredId>;
  clientFileId: ClientFileId;
  applicationWizardStatusType: ApplicationStatusType;
  // application questions
  anyChanges: boolean;
  isNewEntity: boolean;
  isGeneratingForms: boolean;
  isGettingESignatures: boolean;
  // Insured Questions
  isBFR: boolean;
  isLimitedResourceFarmer: boolean;
  isApplicantAtLeast18: boolean;
  isVFR: boolean;
  isVIP: boolean;
  insuringLLTs: boolean;
  anyPOA: boolean;
  additionAuthorityToSign: boolean;
  // Conditions of Acceptance
  fciaDelinquency: Nullable<boolean>;
  controlledSubstanceConviction: Nullable<boolean>;
  fciaInsuranceTermination: Nullable<boolean>;
  disqualifiedOrDebarred: Nullable<boolean>;
  fciaAgreementDisqualification: Nullable<boolean>;
  currentLikeInsurance: Nullable<boolean>;
  isNewApplication?: boolean;
  applicationWizardScenarios: ApplicationWizardScenarios[];
  applicationWizardAppTasks: ApplicationWizardAppTask[];
}

export interface ApplicationWizardSummary extends ApplicationWizard {
  year: number;
  crops: string[];
  states: string[];
  counties: string[];
  lastUpdatedTime: Nullable<string>;
}


export interface ApplicationWizardScenarios {
  applicationWizardScenarioId: ApplicationWizardScenarioId;
  applicationWizardId: ApplicationWizardId;
  scenarioId: ScenarioId;
}

export const DefaultApplication: StrictOmit<ApplicationWizard, 'applicationWizardId' | 'insuredId' | 'clientFileId' | 'newInsuredId'> = {
  applicationWizardScenarios: [],
  applicationWizardStatusType: ApplicationStatusType.InProgress,
  anyChanges: true,
  isNewEntity: false,
  isGeneratingForms: true,
  isGettingESignatures: true,
  isBFR: false,
  isLimitedResourceFarmer: false,
  isVFR: false,
  isVIP: false,
  insuringLLTs: false,
  isApplicantAtLeast18: true,
  anyPOA: false,
  additionAuthorityToSign: false,
  fciaDelinquency: null,
  controlledSubstanceConviction: null,
  fciaInsuranceTermination: null,
  disqualifiedOrDebarred: null,
  fciaAgreementDisqualification: null,
  currentLikeInsurance: null,
  applicationWizardAppTasks: [],
};

export const defaultInsuredInfo: Insured = {
  id: generatePrimaryKey(),
  corporationState: '',
  type: 'Insured',
  name: '',
  firstName: '',
  lastName: '',
  middleName: '',
  suffix: '',
  address: {
    addressLine1: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    composite: '',
  },
  phone: '',
  email: '',
  entityTypeId: null,
  taxId: '',
  taxTypeId: null,
  personOrBusiness: null,
  offlineCreatedOn: undefined,
  offlineLastUpdatedOn: undefined,
  offlineDeletedOn: undefined,
  agentTeamId: null,
};