/* eslint-disable no-redeclare */
import { Collection, Table, WhereClause } from 'dexie';
import { ValidObjectKey, ValidStringKey } from './validClauseKeys';

export function safeWhere<T, TKey>(table: Table<T, TKey>, key: ValidStringKey<T>): WhereClause<T, TKey>;
export function safeWhere<T, TKey>(table: Table<T, TKey>, obj: ValidObjectKey<T>): Collection<T, TKey>;
export function safeWhere<T, TKey>(table: Table<T, TKey>, keyOrObj: ValidStringKey<T> | ValidObjectKey<T>): WhereClause<T, TKey> | Collection<T, TKey> {
  if (typeof keyOrObj === 'object') {
    return table.where(keyOrObj);
  } else {
    return table.where(keyOrObj);
  }
}