import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';

type YesNoQuestionInputProps = {
  question: string;
  id: string;
  value: Nullable<boolean>;
  disabled?: boolean;
  onChange?: (val: boolean) => void;
}

const YesNoQuestionInput = ({ question, value, id, disabled, onChange }: YesNoQuestionInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={id}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <Grid container sx={{ alignItems: 'center', width: '100%' }} >
          <Grid item xs>
            <FormLabel id="demo-error-radios"><Typography variant="subtitle1">{question}</Typography></FormLabel>
          </Grid>
          <Grid item sx={{ width: '100px', ml: 2 }}>
            <RadioGroup
              row
              value={value}
              name={id}
              // using some regex here because we don't want the string version of the value, we want the boolean
              onChange={e => {
                const newValue = (/true/i).test(e.target.value);
                field.onChange(newValue);
                if (onChange) {
                  onChange(newValue);
                }
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label=""
                disabled={disabled}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label=""
                disabled={disabled}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      )}
    />
  );
};

export default YesNoQuestionInput;