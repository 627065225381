import { Box, Grid, Typography } from '@mui/material';
import DiffAction from './diffAction';
import EntityDiffIcon from './entityDiffIcon';
import EntityTypeTitle from './entityTypeTitle';
import EntityType from '../constants/entityType';
import ReconciliationType from '../constants/ReconciliationType';
import DataLocationType from '../constants/dataLocationType';

interface EntityDiffHeaderProps {
  title: string;
  subtitle?: string;
  entityType: EntityType;
  isReconciled: boolean;
  selectedReconciliationType: ReconciliationType;
  onSelectedReconciliationTypeChanged: (newReconciliationType: ReconciliationType) => void;
  areSomeSubEntitiesSelected: boolean;
  areAllSubEntitiesSelected: boolean;
  preventSelection: boolean;
  dataLocation: DataLocationType;
}

const EntityDiffHeader = ({
  entityType,
  isReconciled,
  selectedReconciliationType,
  onSelectedReconciliationTypeChanged,
  areSomeSubEntitiesSelected,
  areAllSubEntitiesSelected,
  title,
  subtitle,
  preventSelection,
  dataLocation,
}: EntityDiffHeaderProps) => {
  return (
    <Grid container columnSpacing={1} alignItems="center">
      <Grid item xs="auto">
        {!preventSelection && <DiffAction
          entityType={entityType}
          reconciliationType={selectedReconciliationType}
          onReconciliationTypeChange={onSelectedReconciliationTypeChanged}
          areSomeSubEntitiesSelected={areSomeSubEntitiesSelected}
          areAllSubEntitiesSelected={areAllSubEntitiesSelected}
          dataLocation={dataLocation}
        />}
      </Grid>
      <Grid item xs="auto">
        <Box alignItems="center" display="flex">
          <EntityDiffIcon entityType={entityType} isReconciled={isReconciled} />
        </Box>
      </Grid>
      <Grid item xs zeroMinWidth>
        <Typography noWrap>{title}</Typography>
      </Grid>
      <Grid item xs="auto">
        {subtitle !== undefined && subtitle !== '' && <Typography sx={{ color: theme => theme.palette.neutral.main }}>{subtitle}</Typography>}
      </Grid>
      <Grid item xs="auto">
        {['added', 'deleted'].includes(entityType) && <EntityTypeTitle entityType={entityType} />}
      </Grid>
    </Grid>
  );
};

export default EntityDiffHeader;