import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { CheckRenderer } from '../../../components/ag-grid/cellRenderers/checkRenderer';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';
import UnitYear from '../../../types/api/UnitYear';
import { isEmptyUnitGridRow } from '../../unitsModal/unitsGrid';
import AdjustedYieldCellRenderer from '../../unitsModal/cellRenderers/adjustedYieldCellRenderer';
import RateYieldCellRenderer from '../../unitsModal/cellRenderers/rateYieldCellRenderer';
import { Scenario } from '../../../types/api/Scenario';
import ApprovedYieldCellRenderer from '../../unitsModal/cellRenderers/approvedYieldCellRenderer';
import HailRateCellRenderer from './cellRenderers/hailRateCellRenderer';
import HailPremiumCellRenderer from './cellRenderers/hailPremiumCellRenderer';
import { UnitResponseDTO, roundToPlaces } from '@silveus/calculations';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import HailRate from '../../../types/api/hail/hailRate';
import { Quote } from '../../../types/api/Quote';

export const getColumnDefinitions = (onToggleHailUnit: (unitYearId: UnitYearId) => void, scenario: Scenario, quote: Quote,  selectedHailUnits: UnitYearId[], hailRatesByTownshipRange: Map<string, HailRate>, calculatedUnitValues: UnitResponseDTO[]) => {
  const columnDefinitions: ColDef<UnitYear>[] = [
    {
      colId: 'isSelected',
      headerName: '',
      width: 50,
      editable: false,
      resizable: false,
      pinned: 'left',
      cellStyle: {
        paddingLeft: '4px',
        paddingRight: '4px',
        textAlign: 'center',
      },
      cellRenderer: (params: ICellRendererParams<UnitYear>) => {
        const { data } = params;
        if (!data) return null;

        return CheckRenderer(`hail-unit-check-${data.unitYearId}`, isNotNullOrUndefined(selectedHailUnits.find(x => x === data.unitYearId)), () => onToggleHailUnit(data.unitYearId));
      },
    },
    {
      colId: 'unitNum',
      headerName: 'Unit #',
      field: 'basicUnitNumber',
      width: 110,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        // Because we combine the basic unit number and option unit number into
        // essentially a single column have a custom comparator that takes into
        // account for both fields while still putting newly added rows first
        if (isDescending) {
          if (nodeA.data === undefined || isEmptyUnitGridRow(nodeA.data)) return 1;
          if (isNullOrUndefined(nodeB.data) || isEmptyUnitGridRow(nodeB.data)) return -1;
        } else {
          if (nodeB.data === undefined || isEmptyUnitGridRow(nodeB.data)) return 1;
          if (isNullOrUndefined(nodeA.data) || isEmptyUnitGridRow(nodeA.data)) return -1;
        }

        const a = valueA?.toString().toLowerCase();
        const b = valueB?.toString().toLowerCase();

        if (a === b) {
          const aData = nodeA.data.optionalUnitNumber.toString().toLowerCase();
          const bData = nodeB.data.optionalUnitNumber.toString().toLowerCase();
          if (aData === '') return 1;
          if (bData === '') return -1;
          return aData.localeCompare(bData);
        }
        return a.localeCompare(b);
      },
    },
    {
      colId: 'farmName',
      headerName: 'Farm Name',
      field: 'farmName',
      width: 150,
    },
    {
      colId: 'farmNumber',
      headerName: 'Farm Number',
      field: 'farmNumber',
      width: 150,
    },
    {
      colId: 'section',
      headerName: 'Section',
      field: 'section',
      width: 150,
    },
    {
      colId: 'township',
      headerName: 'Township',
      field: 'township',
      width: 120,
    },
    {
      colId: 'range',
      headerName: 'Range',
      field: 'range',
      width: 100,
    },
    {
      colId: 'acres',
      headerName: 'Acres',
      field: 'acres',
      width: 100,
    },
    {
      colId: 'sharePercent',
      headerName: 'Share %',
      field: 'sharePercent',
      width: 110,
      valueFormatter: params => {
        return !isNullOrUndefined(params.value) ? `${roundToPlaces(params.value * 100, 2)}%` : '';
      },
    },
    {
      colId: 'rateYield',
      headerName: 'Rate Yield',
      headerTooltip: 'Rate Yield',
      wrapHeaderText: true,
      width: 85,
      editable: false,
      suppressNavigable: true,
      suppressKeyboardEvent: () => true,
      cellRenderer: (params: ICellRendererParams<UnitYear>) => {
        if (!params.data) return null;
        RateYieldCellRenderer({ params: params });
      },
    },
    {
      colId: 'adjYield',
      headerName: 'Adj. Yield',
      headerTooltip: 'Adjusted Yield',
      wrapHeaderText: true,
      width: 85,
      cellRenderer: (params: ICellRendererParams<UnitYear>) => {
        if (!params.data) return null;
        AdjustedYieldCellRenderer({ params: params, unitYear: params.data, scenarioId: scenario.scenarioId, quote: quote });
      },
    },
    {
      colId: 'appYield',
      headerName: 'Appr. Yield',
      headerTooltip: 'Approved Yield',
      wrapHeaderText: true,
      width: 86,
      cellRenderer: (params: ICellRendererParams<UnitYear>) => {
        if (!params.data) return null;
        return ApprovedYieldCellRenderer({ params, unitYear: params.data, scenarioId: scenario.scenarioId, quote: quote });
      },
    },
    {
      colId: 'hailUnitRate',
      headerName: 'Hail Rate',
      width: 110,
      cellRenderer: (params: ICellRendererParams<UnitYear>) => {
        if (!params.data) return null;
        return HailRateCellRenderer({ hailUnit: params.data, hailRatesByTownshipRange: hailRatesByTownshipRange });
      },
    },
    {
      colId: 'hailUnitPremium',
      headerName: 'Hail Premium',
      width: 150,
      cellRenderer: (params: ICellRendererParams<UnitYear>) => {
        if (!params.data) return null;
        return HailPremiumCellRenderer({ unitYearId: params.data.unitYearId, calculatedValues: calculatedUnitValues });
      },
    },
  ];

  const columnOrder = ['isSelected', 'unitNum', 'farmName', 'farmNumber', 'section', 'township', 'range', 'acres', 'sharePercent', 'rateYield',
    'adjYield', 'appYield', 'hailUnitRate', 'hailUnitPremium'];
  const orderedColumnDefinitions = columnOrder.map(colId => columnDefinitions.find(col => col.colId === colId)).filter((x): x is ColDef<UnitYear> => !!x);

  return orderedColumnDefinitions;
};

export const DefaultColDefinition: ColDef = {
  minWidth: 40,
  resizable: false,
  sortable: false,
  editable: false,
  filter: false,
  lockPinned: true,
  hide: false,
  lockVisible: true,
  suppressMovable: true,
};