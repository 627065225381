import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { addApplication, createApplication, currentlySelectedApplicationWizard, fetchSalesClosingDates, saveApplication, setCurrentlySelectedApplicationId, toggleApplicationsModal } from '../../app/applicationsSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectCurrentClientFile } from '../../app/clientFilesSlice';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../utils/nullHandling';
import { fetchEntityTypes, fetchTaxTypes } from '../../app/insuredsSlice';
import { ApplicationWizardSteps } from './wizardSteps/applicationWizardStep';
import { ApplicationDecisionStep } from './wizardSteps/appDecisionsPage/applicationDecisionPage';
import { generatePrimaryKey } from '../../utils/primaryKeyHelpers';
import { ApplicationWizardId } from '../../types/api/PrimaryKeys';
import { fetchPOIs } from '../../app/personOfInterestSlice';
import { fetchAIPs } from '../../app/aipSlice';
import { fetchUnitYears } from '../../app/unitsSlice';
import { openToast } from '../../app/toastSlice';
import { fetchAgentCodes } from '../../app/agentCodeSlice';
import { ConfirmStateContent, openConfirm } from '../../app/confirmSlice';
import { isEqual } from 'lodash';
import { MuiDialogCloseReason } from '../../types/mui/MuiDialogCloseReason';
import { useWarnOnUnloadAndNavigate } from '../../hooks/useWarnOnUnloadAndNavigate';
import { fetchAdmData } from '../../app/admSlice';

export const useApplicationsModal = () => {
  const dispatch = useAppDispatch();
  const clientFile = useAppSelector(selectCurrentClientFile);
  const globalApplication = useAppSelector(currentlySelectedApplicationWizard);
  const [application, updateApplication] = useState(globalApplication);
  // we need rootState accessible here to pass into the application modal steps to utilize for isDisabled
  const rootState = useAppSelector(s => s);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const insuredId = application?.insuredId;

  const clientFileId = application?.clientFileId;
  useWarnOnUnloadAndNavigate(isLoading);

  useEffect(() => {
    dispatch(fetchTaxTypes());
    dispatch(fetchEntityTypes());
    dispatch(fetchAdmData());
  }, []);

  useEffect(() => {
    if (clientFileId) {
      dispatch(fetchSalesClosingDates({ clientFileId: clientFileId }));
    }
  }, [clientFileId]);

  useEffect(() => {
    updateApplication(globalApplication);
  }, [globalApplication]);

  useEffect(() => {
    if (insuredId) {
      dispatch(fetchPOIs({ insuredId }));
      dispatch(fetchAIPs());
      dispatch(fetchUnitYears({ insuredId }));
      dispatch(fetchAgentCodes(insuredId));
    }
  }, [insuredId]);

  useEffect(() => {
    if (application && isNotNullOrUndefined(application.newInsuredId)) {
      dispatch(fetchPOIs({ insuredId: application.newInsuredId }));
    }
  }, [application?.newInsuredId]);

  useEffect(() => {
    if (!clientFile) {
      return;
    }
    const createApp = async () => {
      if (isNotNullOrUndefined(clientFile.insuredId)) {
        const newApplicationWizardId: ApplicationWizardId = generatePrimaryKey();
        const tempApplication = await dispatch(createApplication({ insuredId: clientFile.insuredId, clientFileId: clientFile.clientFileId, applicationWizardId: newApplicationWizardId })).unwrap();
        updateApplication(tempApplication);
        dispatch(setCurrentlySelectedApplicationId(newApplicationWizardId));
      }
    };
    if (isNullOrUndefined(globalApplication)) {
      // If user does not have a saved application we need to start by creating a new application with all default values
      // and putting that in redux, only once that application exists can we proceed
      createApp();
    }
  }, [globalApplication, clientFile]);

  const handleClose = (reason?: MuiDialogCloseReason) => {
    // to avoid loss of data by forgetting to save we don't want to close the modal if a user
    // clicks outside the modal boundary.
    if (reason === 'backdropClick') {
      return;
    }

    if (isDirty) {
      const confirmWindow: ConfirmStateContent = {
        title: 'Close Application Wizard Without Saving?',
        message: 'There are unsaved changes. Closing will discard all changes potentially resulting in a loss of work. What would you like to do?',
        confirmText: 'I WANT TO SAVE MY WORK',
        onConfirm: () => handleSaveAndClose(),
        cancelText: 'CLOSE WITHOUT SAVING',
        onCancel: () => toggleAppWizardModal(),
        shouldPreventBackdropClose: true,
      };
      dispatch(openConfirm(confirmWindow));
    } else {
      toggleAppWizardModal();
    }
  };

  const handleSaveAndClose = async () => {
    if (isNotNullOrUndefined(application)) {
      if (application.isNewApplication ?? false) {
        await dispatch(addApplication({ applicationWizard: application }));
      } else {
        await dispatch(saveApplication({ updatedApplication: application }));
      }
    }
    toggleAppWizardModal();
  };

  const toggleAppWizardModal = () => {
    dispatch(setCurrentlySelectedApplicationId(null));
    dispatch(toggleApplicationsModal());
  };

  const navigateToTabAtIndex = (index: number) => {
    if (!application) {
      return;
    }

    // If we are currently editing a form a user must exit edit mode otherwise we do NOT want to try
    // and change tabs, otherwise there could be loss of data.
    if (isInEditMode) {
      return;
    }

    if (isDirty && (application.isNewApplication ?? false)) {
      dispatch(addApplication({ applicationWizard: { ...application, isNewApplication: undefined } }));
    } else if (isDirty) {
      dispatch(saveApplication({ updatedApplication: { ...application, isNewApplication: undefined } }));
    }

    if (selectedTabIndex === ApplicationDecisionStep.index) {
      const confirmWindow: ConfirmStateContent = {
        title: 'Leaving App Decisions Step',
        message: 'Leaving this step prior to applying changes to D365 will result in a loss of work. What would you like to do?',
        confirmText: 'Continue',
        onConfirm: () => moveToStep(index),
        cancelText: 'Cancel',
      };
      dispatch(openConfirm(confirmWindow));
    } else {
      moveToStep(index);
    }
  };

  const moveToStep = (index: number) => {
    let nextStep = ApplicationWizardSteps[index];
    // If the next navigation forward would take us to the app decisions page we have to do some validation first.
    if (nextStep.index === ApplicationDecisionStep.index) {
      const isValid = areAllStepsValid();

      if (!isValid) {
        return;
      }
    }
    setSelectedTabIndex(index);
  };

  const areAllStepsValid = () => {
    if (isNullOrUndefined(application)) {
      return false;
    }

    const isValid = ApplicationWizardSteps.every(step => step.isValid(application, rootState).isValid);
    if (!isValid) {
      const errorMessage = 'Data validation errors were found.\nThe pages containing these issues have been marked. Please address these before continuing to Application Decisions.';
      dispatch(openToast({ type: 'error', message: errorMessage, shouldTimeout: true, allowClickToClose: true }));
    }

    return isValid;
  };

  const navigateToPreviousStep = () => {
    if (!application) {
      return;
    }

    setIsInEditMode(false);

    let previousStep = ApplicationWizardSteps[selectedTabIndex - 1];
    while (previousStep.isDisabled(application, rootState)) {
      // This condition exists to prevent an out of range error if the first page is disabled.
      if (previousStep.index === 0) {
        return;
      }
      previousStep = ApplicationWizardSteps[previousStep.index - 1];
    }
    setSelectedTabIndex(previousStep.index);
  };

  const navigateToNextStep = () => {
    if (!application) {
      return;
    }

    let nextStep = ApplicationWizardSteps[selectedTabIndex + 1];
    while (nextStep.isDisabled(application, rootState)) {
      // This condition exists to prevent an out of range error if last page is disabled.
      if (nextStep.index === ApplicationDecisionStep.index) {
        return;
      }
      nextStep = ApplicationWizardSteps[nextStep.index + 1];
    }

    // If the next navigation forward would take us to the app decisions page we have to do some validation first.
    if (nextStep.index === ApplicationDecisionStep.index) {
      const isValid = areAllStepsValid();
      if (!isValid) {
        return;
      }
    }
    setSelectedTabIndex(nextStep.index);
  };

  const updateIsInEditMode = (val: boolean) => {
    setIsInEditMode(val);
  };

  const updateIsLoading = useCallback((val: boolean) => {
    setIsLoading(val);
  }, []);

  const isDirty = useMemo(() => {
    return !isEqual(globalApplication, application);
  }, [globalApplication, application]);

  return {
    handleClose,
    selectedTabIndex,
    navigateToTabAtIndex,
    navigateToPreviousStep,
    navigateToNextStep,
    application,
    updateIsInEditMode,
    isInEditMode,
    updateApplication,
    isLoading,
    updateIsLoading,
  };
};