import { ScenarioPieceType } from '@silveus/calculations';
import { ScenarioId } from '../types/api/PrimaryKeys';
import { RowCropScenarioPiece } from '../types/api/RowCropScenarioPiece';
import { useAppSelector } from './reduxHooks';
import { selectScenarioPieceAvailability } from '../app/scenario-piece-availability/scenarioPieceAvailability';

export const useScenarioPieceAvailability = (
  scenarioId: ScenarioId,
  scenarioPieceTypeFilter: ScenarioPieceType[] = [],
  overrideRowCropScenarioPieces?: RowCropScenarioPiece[],
  useScenarioPieceGroups = true,
) => {
  const result = useAppSelector(s => selectScenarioPieceAvailability(s, {
    scenarioId,
    scenarioPieceTypeFilter,
    overrideRowCropScenarioPieces,
    useScenarioPieceGroups,
  }));

  return result;
};