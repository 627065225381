import axios from 'axios';
import { ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { InputCostScenarioPiece } from '../types/api/InputCostScenarioPiece';
import { envBaseApiUrl } from '../constants/envConstants';

export const getInputCostScenarioPiecesForScenario = (scenarioId: ScenarioId) => axios.get<InputCostScenarioPiece[]>(`${envBaseApiUrl}/input-cost-scenario-pieces/scenarios/${scenarioId}`);

export const getInputCostScenarioPiecesForScenarios = (scenarioIds: ScenarioId[]) => axios.post<InputCostScenarioPiece[]>(`${envBaseApiUrl}/input-cost-scenario-pieces/scenarios`, scenarioIds);

export const getInputCostScenarioPiece = (scenarioPieceId: ScenarioPieceId) => axios.get<InputCostScenarioPiece>(`${envBaseApiUrl}/input-cost-scenario-pieces/${scenarioPieceId}`);

export const createInputCostScenarioPiece = (scenarioPiece: InputCostScenarioPiece) => axios.post<CreatedItemResult<ScenarioPieceId>>(`${envBaseApiUrl}/input-cost-scenario-pieces`, scenarioPiece);

export const updateInputCostScenarioPiece = (scenarioPiece: InputCostScenarioPiece) => axios.put<void>(`${envBaseApiUrl}/input-cost-scenario-pieces/`, scenarioPiece);

export const deleteInputCostScenarioPiece = (scenarioPieceId: ScenarioPieceId) => axios.delete<void>(`${envBaseApiUrl}/input-cost-scenario-pieces/${scenarioPieceId}/`);
