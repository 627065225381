import { ReactElement, useState, MouseEvent } from 'react';
import { Nullable } from '../../types/util/Nullable';
import { ScenarioPieceGroupResponseDTO, ScenarioPieceResponseDTO } from '@silveus/calculations';
import { ScenarioPieceCoverageResponseDTO } from '@silveus/calculations/dist/calculationOrchestrator/response/scenarioPieceCoverageResponseDTO';
import { Grid, Popper, Tooltip } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CurrencyText from '../../components/currencyText/currencyText.component';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectScenariosThatAreLoadingCalculations } from '../../app/calculationResultsSlice';
import { ScenarioId } from '../../types/api/PrimaryKeys';


interface Props {
  label: string;
  isInvalid: boolean;
  isReadonly: boolean;
  errorTooltip: ReactElement | undefined;
  calcResults: Nullable<ScenarioPieceResponseDTO | ScenarioPieceGroupResponseDTO | ScenarioPieceCoverageResponseDTO>;
  getScenarioPieceTooltip: () => ReactElement;
  extraContent: ReactElement | undefined;
  extraContentWidth: number | undefined;
  textContentWidth: number | undefined;
  netValue: number;
  scenarioId: ScenarioId;
}

export const ScenarioComponentResultSummaryLabel = ({ label, isInvalid, isReadonly,
  errorTooltip, calcResults, getScenarioPieceTooltip,
  extraContent, extraContentWidth, textContentWidth, scenarioId, netValue }: Props) => {
  const isScenarioCalculating = useAppSelector(selectScenariosThatAreLoadingCalculations)[scenarioId] ?? false;
  const shouldDisplayNetAsLoading = isScenarioCalculating && calcResults === null;

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const isTooltipOpen = Boolean(anchorElement);

  const handleMouseLeave = () => {
    setAnchorElement(null);
  };

  const handleMouseEnter = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  return (
    <>
      <Grid item xs={4.5} sx={{ color: theme => isReadonly ? theme.palette.text.disabled : theme.palette.primary.main, fontSize: '14px', fontWeight: 'bold' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        { isInvalid &&
          <Tooltip title={errorTooltip}>
            <ErrorOutlineIcon color="warning" sx={{ verticalAlign: 'middle', mr: .5 }} />
          </Tooltip>
        }
        {label}
      </Grid>
      <Grid container item xs={7.5} alignItems="center">
        <Grid item xs={textContentWidth}>
          {!isInvalid && <CurrencyText align="right" sx={{ fontSize: 14, fontWeight: 'bold' }} currencyAmount={netValue} colorize isDataLoading={shouldDisplayNetAsLoading} />}
        </Grid>
        {extraContent && <Grid item container xs={extraContentWidth} alignItems="center" justifyContent="space-around">
          {extraContent}
        </Grid> }
      </Grid>

      {calcResults && (
        <Popper sx={{ backgroundColor: theme => theme.palette.background.paper }} placement="bottom-start" id={calcResults.id} open={isTooltipOpen} anchorEl={anchorElement}>
          {getScenarioPieceTooltip()}
        </Popper>
      )}
    </>
  );
};