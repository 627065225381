import { Na } from '../../../../types/util/Na';
import { formatNumber } from '../../../../utils/formatNumbers';
import { Typography } from '@mui/material';
import { getHailRateForUnitFromHailRateMap } from '../../utils/hailRateUtils';
import UnitYear from '../../../../types/api/UnitYear';
import HailRate from '../../../../types/api/hail/hailRate';

type HailRateCellRendererProps = {
  hailUnit: UnitYear;
  hailRatesByTownshipRange: Map<string, HailRate>;
};

const HailRateCellRenderer = ({ hailUnit, hailRatesByTownshipRange }: HailRateCellRendererProps) => {
  const hailRate = getHailRateForUnitFromHailRateMap(hailUnit, hailRatesByTownshipRange);
  return (
    <div><Typography variant="value" color={'red'}> {hailRate !== undefined ? formatNumber(hailRate.rate, true) : Na} </Typography> </div>
  );
};

export default HailRateCellRenderer;
