import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { Nullable } from '../../../types/util/Nullable';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { isNilOrEmpty, isUndefined } from '../../../utils/nullHandling';
import {
  fetchAvailableSubCountyCodes,
  selectAdmLoadingStatus,
  selectAvailableSubCountyCodes
} from '../../../app/admSlice';
import { useEffect, useState } from 'react';
import { LOADING_STATES } from '../../../constants/loadingStates';
import { validateSubCounty } from '../../../pages/units/validations/subCountyValidation';

interface SubCountyCodeProps {
  subCountyCode: Nullable<string>;
  year: number;
  typeId: Nullable<string>;
  practiceId: Nullable<string>;
  countyId: Nullable<string>;
}

export type SubCountyCodeFields = {
  subCountyCode: Nullable<string>;
}

const SubCountyCodeInput = ({ subCountyCode, year, typeId, practiceId, countyId }: SubCountyCodeProps) => {
  const dispatch = useAppDispatch();
  const { control, setValue, getValues } = useFormContext();
  const subCountyCodes = useAppSelector(selectAvailableSubCountyCodes);
  const [subCountiesLoaded, setSubCountiesLoaded] = useState(false);
  const admLoadingStatus = useAppSelector(selectAdmLoadingStatus);
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });
  const initialSubCountyCodeSelection = subCountiesLoaded && subCountyCodes.length >= 1 ? subCountyCodes.at(0) : null;
  const currentVal = subCountyCode ?? getValues('subCountyCode');
  if (isNilOrEmpty(currentVal) && !isNilOrEmpty(initialSubCountyCodeSelection)) setValue('subCountyCode', initialSubCountyCodeSelection);

  useEffect(() => {
    setSubCountiesLoaded(false);

    if (!isUndefined(practice))
      setValue('subCountyCode', null);

    if (isNilOrEmpty(practice) && isNilOrEmpty(practiceId)) return;

    const county = getValues('countyId');
    const type = getValues('typeId');
    dispatch(fetchAvailableSubCountyCodes({ year: year, typeId: type ?? typeId, practiceId: practice ?? practiceId, countyId: county ?? countyId }))
      .then(() => setSubCountiesLoaded(true));
  }, [practice]);

  const isSubCountyListLoading = admLoadingStatus === LOADING_STATES.Loading && subCountyCodes.length === 0;

  return (
    <Controller
      name="subCountyCode"
      control={control}
      rules={{ validate: validateSubCounty }}
      defaultValue={subCountyCode}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <Autocomplete
          {...field}
          renderInput={params =>
            <TextField
              {...params}
              label="Sub-County Code"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSubCountyListLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              error={!!error}
              helperText={error?.message}
              inputRef={ref}
              size="small"
            />
          }
          options={subCountyCodes}
          fullWidth
          autoHighlight
          autoComplete
          openOnFocus
          clearOnEscape
          loading={isSubCountyListLoading}
          onChange={(e, data) => field.onChange(data ?? null)}
        />
      )}
    />
  );
};

export default SubCountyCodeInput;
