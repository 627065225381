import { Grid } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import YearInput from '../yearInput.component';

export interface IntervalYearInputProps {
  initialYear: Nullable<number>,
  availableYears: number[],
}

export const IntervalYearInput = ({ initialYear, availableYears }: IntervalYearInputProps) =>
  <Grid item xs={12}>
    <YearInput
      year={initialYear}
      disabled={availableYears.length === 0}
      availableYears={availableYears}
      fieldLabel="Interval Year"
    />
  </Grid>;
