import { Card, CardContent, CardHeader, Grid, Tooltip, Typography } from '@mui/material';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import HailCardRow from '../hailCardView/hailCardRow.component';
import CoveragePerAcreInput from '../../../components/formInputs/hail/hailCoveragePerAcreInput.component';
import DiscountPercentageInput from '../../../components/formInputs/hail/discountPercentageInput.component';
import BudgetPercentageInput from '../../../components/formInputs/hail/budgetPercentageInput.component';
import BudgetInput from '../../../components/formInputs/hail/budgetInput.component';
import CoverageLevelInput from '../../../components/formInputs/hail/coverageLevelInput.component';
import PriceElectionPercentInput from '../../../components/formInputs/hail/priceElectionPercentInput.component';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import HailIncludeInBlendedInput from '../../../components/formInputs/hail/hailIncludeInBlended.component';

type HailTableCardProps = {
  hailScenarioPiece: HailScenarioPiece;
  hailProduct: HailProduct;
  index: number;
  width: number;
  height: number;
}

const HailTableInfoCard = ({ hailScenarioPiece, hailProduct, index, width, height }: HailTableCardProps) => {
  const updateValues = () => {
    // TODO #62895
  };

  return (
    <Card sx={{ width: width, height: height }} variant="elevation" elevation={3} raised>
      <CardContent sx={{ p: 1, pb: '0px !important' }}>
        <CardHeader
          title={
            <Tooltip title={hailProduct.productName}>
              <Typography variant="subtitle2" sx={{ textAlign: 'center', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>
                {hailProduct.productName}
              </Typography>
            </Tooltip>
          }
          titleTypographyProps={{ variant: 'subtitle2', sx: { textAlign: 'center' } }}
          sx={{ p: '8px !important', height: '50px' }}
        />
        <CardContent sx={{ p: '8px !important', pt: '0 !important', pr: '4px !important' }}>
          <Grid container>
            {!hailProduct.isProduction && (<HailCardRow
              title="Budget"
              value={<BudgetInput name={`hailScenarioPieces.${index}.budget`} value={hailScenarioPiece.budget} onBlur={updateValues} />} />)}
            {hailProduct.isProduction && (<HailCardRow
              title="Coverage Level"
              value={<CoverageLevelInput name={`hailScenarioPieces.${index}.upperCoverageLevel`} coverageLevel={hailScenarioPiece.upperCoverageLevel} onBlur={updateValues} />} />)}
            {!hailProduct.isProduction && (<HailCardRow
              title="Budget %"
              value={<BudgetPercentageInput name={`hailScenarioPieces.${index}.budgetPercent`} value={hailScenarioPiece.budgetPercent} onBlur={updateValues} />} />)}
            {hailProduct.isProduction && (<HailCardRow
              title="Price Election %"
              value={<PriceElectionPercentInput name={`hailScenarioPieces.${index}.priceElectionPercent`} value={hailScenarioPiece.priceElectionPercent} onBlur={updateValues} />} />)}
            <HailCardRow
              title="Rate"
              value={<CurrencyText currencyAmount={hailProduct.rate} colorize={false} align="right" />} />
            <HailCardRow
              title="Discount %"
              value={<DiscountPercentageInput name={`hailScenarioPieces.${index}.discountPercent`} value={hailScenarioPiece.discountPercent} onBlur={updateValues} />} />
            <HailCardRow
              title="Discounted Rate"
              value={<CurrencyText currencyAmount={hailScenarioPiece.discountedRate} colorize={false} align="right" />} />
            {!hailProduct.isProduction && (<HailCardRow
              title="$ Cov / Acre"
              value={<CoveragePerAcreInput name={`hailScenarioPieces.${index}.coveragePerAcre`} value={hailScenarioPiece.coveragePerAcre} onBlur={updateValues} />} />)}
            {hailProduct.isProduction && (<HailCardRow
              title="$ Cov / Acre"
              value={<CurrencyText currencyAmount={hailScenarioPiece.coveragePerAcre} colorize={false} align="right" />} />)}
            <HailCardRow
              title="Deductible %"
              value="TODO%" />
            <Grid item xs={12} textAlign="left">
              <HailIncludeInBlendedInput name={`hailScenarioPieces.${index}.includeInBlended`} includeInBlended={hailScenarioPiece.includeInBlended}></HailIncludeInBlendedInput>
            </Grid>
          </Grid>
        </CardContent>
      </CardContent>
    </Card>
  );
};

export default HailTableInfoCard;