import axios from 'axios';
import { AgentTeamOwnedClientFile, ClientFile, InsuredOwnedClientFile } from '../types/api/ClientFile';
import { AgentTeamId, ClientFileId, InsuredId } from '../types/api/PrimaryKeys';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { envBaseApiUrl } from '../constants/envConstants';

export const getClientFilesForInsured = (insuredId: InsuredId) => axios.get<InsuredOwnedClientFile[]>(`${envBaseApiUrl}/insureds/${insuredId}/clientfiles`);
export const getClientFilesForAgentTeams = (agentTeamId: AgentTeamId) => axios.get<AgentTeamOwnedClientFile[]>(`${envBaseApiUrl}/agentteam/${agentTeamId}/clientfiles`);

export const getClientFile = (clientFileId: ClientFileId) => axios.get<ClientFile>(`${envBaseApiUrl}/clientfiles/${clientFileId}`);

export const createClientFile = (file: ClientFile) => axios.post<CreatedItemResult<ClientFileId>>(`${envBaseApiUrl}/clientfiles/`, file);

export const updateClientFile = (clientFileId: ClientFileId, file: ClientFile) => axios.put<void>(`${envBaseApiUrl}/clientfiles/${clientFileId}`, file);

export const deleteClientFile = (clientFileId: ClientFileId) => axios.delete<void>(`${envBaseApiUrl}/clientfiles/${clientFileId}`);
