import { Dialog, DialogContent, Grid, Tab, Tabs } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { currentlySelectedScenario, setCurrentlySelectedScenarioId } from '../../app/scenariosSlice';
import { HailModalTabIndex, HailModalTabs } from '../../constants/hailModalTabs';
import { useState } from 'react';
import { TabPanel } from '../../utils/tabPanel';
import HailCardView from './hailCardView/hailCardView';
import HailPlanExplorerView from './hailPlanExplorerView/hailPlanExplorerView';
import HailTableView from './hailTableView/hailTableView';
import HailChartView from './hailChartView/hailChartView';
import { useHail } from './useHail';
import 'react-resizable/css/styles.css';
import { selectAppDrawerOpen } from '../../app/appDrawerSlice';
import { selectCurrentClientFile } from '../../app/clientFilesSlice';
import { isNullOrUndefined } from '../../utils/nullHandling';
import { toggleHailModal } from '../../app/hailModalSlice';
import { selectQuoteById } from '../../app/quotesSlice';
import { MuiDialogCloseReason } from '../../types/mui/MuiDialogCloseReason';
import HailModalHeaderControls from './hailModalHeaderControls';
import ScenarioModalHeader from '../../components/modal/scenarioModalHeader';

const HailModal = () => {
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState<HailModalTabIndex>(HailModalTabs.planExplorer.index);
  const scenario = useAppSelector(currentlySelectedScenario);
  const quote = useAppSelector(s => scenario === null ? null : selectQuoteById(s, scenario.quoteId));
  const clientFile = useAppSelector(selectCurrentClientFile);

  const isAppDrawerOpen = useAppSelector(selectAppDrawerOpen);
  const {
    hailScenarioPieceCompositionId,
    availableHailProducts,
    hailScenarioPieces,
    hailScenarioPiecesByProductId,
    toggleHailPlanSelected,
    updateHailScenarioPiece,
    updateHailScenarioPieceLossChanged,
    getHailProductByIdUseHail,
    getAvailableHailRatesByHailProductIdUseHail,
    getAvailableHailEndorsementsByHailProductIdUseHail,
    getSelectedHailScenarioPieceUnitsUseHail,
    getSelectedHailScenarioPieceSelectedRatesUseHail,
    updateHailScenarioPieceRates,
    updateHailScenarioPieceUnits,
    getSelectedHailScenarioPieceSelectedEndorsementsUseHail,
    updateHailScenarioPieceEndorsements,
  } = useHail(scenario, quote, clientFile);

  if (isNullOrUndefined(scenario) || isNullOrUndefined(quote) || isNullOrUndefined(clientFile)) return null;

  const handleTabChange = (newValue: HailModalTabIndex) => {
    setTabIndex(newValue);
  };

  const handleClose = (reason?: MuiDialogCloseReason) => {
    if (reason === 'backdropClick') {
      return;
    }

    if (!isAppDrawerOpen) {
      // Only deselect the scenario if it's not currently open
      dispatch(setCurrentlySelectedScenarioId(null));
    }
    dispatch(toggleHailModal());
  };

  const headerControls = <HailModalHeaderControls onCloseClicked={handleClose}/>;

  return (
    <Dialog
      open={true}
      onClose={(_, reason) => handleClose(reason)}
      maxWidth={false}
      scroll="body"
      disableEscapeKeyDown={true}
      key={scenario.scenarioId}
      fullScreen
      sx={{ m: 2 }}
    >
      <>
        <ScenarioModalHeader scenarioId={scenario.scenarioId} headerText="Hail Quoter" headerControls={headerControls} appendScenarioText={true} />
        <DialogContent sx={{ p: '8px !important', height: 'calc(100% - 96px)', overflow: 'hidden' }}>
          <Grid item sx={{ height: 'calc(100% - 70px)' }}>
            <Tabs value={tabIndex} onChange={(_, value) => handleTabChange(value)}>
              <Tab label={HailModalTabs.planExplorer.label} />
              <Tab label={HailModalTabs.card.label} />
              <Tab label={HailModalTabs.table.label} />
              <Tab label={HailModalTabs.chart.label} />
            </Tabs>
            <Grid item sx={{ height: 'calc(100% - 48px)', pt: 1, pb: 0, pr: '8px !important' }}>
              <TabPanel value={tabIndex} index={HailModalTabs.planExplorer.index}>
                <HailPlanExplorerView
                  scenario={scenario}
                  availableHailProducts={availableHailProducts}
                  onToggleProduct={toggleHailPlanSelected}
                  hailScenarioPiecesByHailProductId={hailScenarioPiecesByProductId}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={HailModalTabs.card.index}>
                <HailCardView
                  hailCompositionId={hailScenarioPieceCompositionId}
                  scenario={scenario}
                  hailScenarioPieces={hailScenarioPieces}
                  getHailProductById={getHailProductByIdUseHail}
                  getAvailableHailRatesByHailProductId={getAvailableHailRatesByHailProductIdUseHail}
                  getAvailableHailEndorsementsByHailProductId={getAvailableHailEndorsementsByHailProductIdUseHail}
                  getSelectedHailScenarioPieceSelectedRates={getSelectedHailScenarioPieceSelectedRatesUseHail}
                  getSelectedHailScenarioPieceUnits={getSelectedHailScenarioPieceUnitsUseHail}
                  getSelectedHailScenarioPieceSelectedEndorsements={getSelectedHailScenarioPieceSelectedEndorsementsUseHail}
                  onDeleteProductClick={toggleHailPlanSelected}
                  onHailScenarioPieceUpdated={updateHailScenarioPiece}
                  onHailScenarioPieceLossChanged={updateHailScenarioPieceLossChanged}
                  onHailScenarioPieceRatesUpdated={updateHailScenarioPieceRates}
                  onHailScenarioPieceUnitsUpdated={updateHailScenarioPieceUnits}
                  onHailScenarioPieceEndorsementsUpdated={updateHailScenarioPieceEndorsements}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={HailModalTabs.table.index}>
                <HailTableView
                  hailScenarioPieces={hailScenarioPieces}
                  getHailProductById={getHailProductByIdUseHail}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={HailModalTabs.chart.index}>
                <HailChartView
                  hailScenarioPieces={hailScenarioPieces}
                  getHailProductById={getHailProductByIdUseHail} />
              </TabPanel>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default HailModal;