import {
  HarvestRevenueRequestDTO,
  HarvestRevenueFixedParams,
  HarvestRevenueSimulatedParams,
  HarvestRevenueUnit,
  Unit
} from '@silveus/calculations';
import { RootState } from '../../../app/store';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import BaseUnit from '../../../types/api/BaseUnit';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { getUnitsForScenarioPiece } from '../calculationUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { UnitGroup } from '../../../types/api/UnitGroup';
import HarvestRevenueScenarioPiece from '../../../types/api/HarvestRevenueScenarioPiece';

export const createHarvestRevenueData = async (scenarioPiece: HarvestRevenueScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[], shouldSimulateUnitGroups: boolean) => {

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId, shouldSimulateUnitGroups);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createHarvestRevenueDto(scenarioPiece, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformHarvestRevenueData = (unit: BaseUnit, scenario: RowCropScenario, scenarioPiece: HarvestRevenueScenarioPiece): HarvestRevenueFixedParams & HarvestRevenueSimulatedParams => {
  const harvestRevenueParams: HarvestRevenueFixedParams & HarvestRevenueSimulatedParams = {
    projectedPrice: scenario.projectedPrice ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
    forwardSoldTotal: 0,
    projectedYield: scenario.actualProducerYield ?? 0,
    harvestRevenueTransactions: scenarioPiece.harvestRevenueTransactions,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
  };

  return harvestRevenueParams;
};

const createHarvestRevenueUnits = (units: BaseUnit[], scenario: RowCropScenario): (HarvestRevenueUnit & Unit)[] => {
  return units.map(unit => {
    const unitDto = createBaseUnit(unit);
    const harvestRevenueUnit: HarvestRevenueUnit & Unit = {
      ...unitDto,
      id: unit.id,
    };

    return harvestRevenueUnit;
  });
};

const createHarvestRevenueDto = (scenarioPiece: HarvestRevenueScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): HarvestRevenueRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createHarvestRevenueUnits(
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformHarvestRevenueData(unitData[0], scenario, scenarioPiece),
  };
};