import { Nullable } from '../../../../types/util/Nullable';
import IceRate from '../../../../types/api/adm/IceRate';
import { ScenarioPieceType } from '@silveus/calculations';
import { PlanSelectorInput } from '../planSelectorInput.component';

export interface IceProductSelectorProps {
  selectedIceProduct: Nullable<ScenarioPieceType>;
  disabled?: boolean;
}

export const IceProductSelectorInput = ({ selectedIceProduct, iceProducts, disabled = false }: IceProductSelectorProps & { iceProducts: IceRate[] }) => {
  const uniqueProducts = [...new Set(iceProducts.map(ip => ip.iceScenarioPieceType))];

  return (
    <PlanSelectorInput
      label="ICE Product"
      disabled={disabled}
      selectedPlan={selectedIceProduct}
      availablePlans={uniqueProducts}
    />
  );
};
