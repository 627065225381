import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { Quote } from '../../../types/api/Quote';
import {
  selectCommodity,
  selectCounty,
  selectStateFromCounty
} from '../../../app/admSlice';
import { modifyQuote, removeQuote } from '../../../app/quotesSlice';
import {
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { openDrawer } from '../../../app/appDrawerSlice';
import { ConfirmStateContent, openConfirm } from '../../../app/confirmSlice';
import {
  Delete,
  Edit,
  AddCircleOutline,
  Flag
} from '@mui/icons-material';
import { ClientFileQuoteScenarioList } from './clientFileQuoteScenarioList.component';
import { useState } from 'react';
import { updateAllScenarioCalculationsForQuote } from '../../../app/calculationResultsSlice';
import CommandBar from '../../../components/command-bar/commandBar.component';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { isNullOrUndefined } from '../../../utils/nullHandling';

interface Props {
  quote: Quote;
}

export const ClientFileSingleQuote = ({ quote }: Props) => {
  const dispatch = useAppDispatch();

  const state = useAppSelector(state => selectStateFromCounty(state, quote.countyId));
  const county = useAppSelector(state => selectCounty(state, quote.countyId));
  const commodity = useAppSelector(state => selectCommodity(state, quote.commodityCode));
  const clientFile = useAppSelector(state => selectClientFileById(state, quote.clientFileId));
  const theme = useTheme();

  const [scenarioListOpen, setScenarioListOpen] = useState<boolean>(false);

  // Render Logic
  const unitQuotingDisabled = isNullOrUndefined(clientFile?.insuredId);
  const stateCountyCommodityCombo = <Typography variant="clickable1">{county?.name.toUpperCase()} | {state?.abbrev?.toUpperCase()} | {commodity?.name.toUpperCase()}</Typography>;

  const onEditClick = () => {
    dispatch(openDrawer({ formName: 'quoteForm', quoteId: quote.quoteId, clientFileId: quote.clientFileId }));
  };

  const onDeleteQuote = async () => {
    await dispatch(removeQuote({ quote }));
  };

  const onDeleteQuoteClick = () => {
    const confirmWindow: ConfirmStateContent = {
      title: 'Delete Quote?',
      message: 'Are you sure you want to delete this quote?',
      confirmText: 'Delete',
      onConfirm: onDeleteQuote,
    };
    dispatch(openConfirm(confirmWindow));
  };

  const onQuoteNameClick = () => {
    setScenarioListOpen(isOpen => !isOpen);
  };

  const onChangeQuoteTypeClicked = async () => {
    const newQuote: Quote = { ...quote, quickQuote: !quote.quickQuote };
    await dispatch(modifyQuote({ quote: newQuote }));

    await dispatch(updateAllScenarioCalculationsForQuote({ quoteId: quote.quoteId }));
  };

  const onAddScenarioClicked = () => {
    dispatch(openDrawer({ formName: 'scenarioForm', scenarioId: null, quoteId: quote.quoteId }));
  };

  const flagSx = { color: (unitQuotingDisabled || quote.quickQuote) ? theme.palette.altText.disabled : 'red' };

  const flagHintText = unitQuotingDisabled
    ? 'Unit quoting disabled while quoting without insured'
    : quote.quickQuote ? 'Unit Quoting Off' : 'Unit Quoting On';

  return (
    <Grid container sx={{ }}>
      <Grid container item xs={12} sx={{ flexWrap: 'nowrap', height: '2.5rem', mb: -0.5 }}>
        <Grid item xs="auto" sx={{ alignSelf: 'center' }}>
          <Tooltip title={flagHintText} placement="top">
            <span>
              <IconButton disabled={unitQuotingDisabled} onClick={onChangeQuoteTypeClicked} sx={{ ...flagSx, padding: 0, marginRight: 1 }}>
                <Flag />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          alignSelf: 'center',
        }}>
          <Link variant="subtitle1" onClick={onQuoteNameClick} sx={{
            cursor: 'pointer',
          }}>{quote.name}</Link>
        </Grid>
        <Grid item xs="auto">
          <CommandBar
            items={[
              {
                id: 'add',
                label: 'Add Scenario',
                iconButton: <AddCircleOutline color="action" />,
                onclick: onAddScenarioClicked,
              },
              {
                id: 'edit',
                label: 'Edit Quote',
                iconButton: <Edit color="action" />,
                onclick: onEditClick,
              },
              {
                id: 'delete',
                label: 'Delete Quote',
                iconButton: <Delete color="action" />,
                onclick: onDeleteQuoteClick,
              },
            ]}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography>{stateCountyCommodityCombo}</Typography>
      </Grid>
      {
        scenarioListOpen &&
        <Grid container item xs={12}>
          <Grid item xs>
            <ClientFileQuoteScenarioList selectedQuoteId={quote.quoteId} />
          </Grid>
        </Grid>
      }
    </Grid >
  );
};
