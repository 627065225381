import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import BaseUnit from '../../../types/api/BaseUnit';
import {
  CoverageTypeCode,
  MyScoIndemnityParams,
  MyScoManualPremiumParams,
  MyScoRequestDto,
  MyScoUnit,
  ScenarioPieceType,
  Unit
} from '@silveus/calculations';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import {
  selectAllRowCropScenarioPiecesByScenarioMap
} from '../../../app/scenarioPiecesSlice';
import { getItemsForId } from '../../../utils/mapHelpers';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getUnitsForScenarioPiece } from '../calculationUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';

export const createMyScoData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Get the underling scenario piece
  const underlyingSco = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), scenario.scenarioId).find(sp => [ScenarioPieceType.ScoYp, ScenarioPieceType.ScoRp].includes(sp.scenarioPieceType));
  if (underlyingSco === undefined) throw new Error('Could not find underlying Sco for MySco calcs');

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createMyScoDto(scenarioPiece, unitsForScenarioPiece, scenario, underlyingSco.lowerCoverageLevel / 100, underlyingSco.scenarioPieceType, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformMyScoData = (scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece, scoCoverageLevel: number, scoBasePlan: ScenarioPieceType): MyScoManualPremiumParams & MyScoIndemnityParams => {
  const myScoParams: MyScoManualPremiumParams & MyScoIndemnityParams = {
    coverageTypeCode: CoverageTypeCode.A,
    harvestPrice: scenario.harvestPrice ?? 0,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    premiumPerAcre: scenarioPiece.rowCropScenarioPieceExtendedData?.customPremium ?? 0,
    projectedPrice: scenario.projectedPrice ?? 0,
    unitStructureCode: scenarioPiece.unitStructure,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    basePlanCoverageLevelPercent: scoCoverageLevel,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
    basePlan: scoBasePlan,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,
  };

  return myScoParams;
};

const createMyScoUnits = (units: BaseUnit[]): (MyScoUnit & Unit)[] => {
  const myScoUnits = units.map(u => {
    const unitDto = createBaseUnit(u);
    const myScoUnit: MyScoUnit & Unit = {
      ...unitDto,
      id: u.id,
    };

    return myScoUnit;
  });

  return myScoUnits;
};

const createMyScoDto = (scenarioPiece: RowCropScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, scoCoverageLevel: number, scoBasePlan: ScenarioPieceType, unitGroups: UnitGroup[]): MyScoRequestDto => {
  const myScoRequest: MyScoRequestDto = {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createMyScoUnits(
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
      ),
    ),
    ...transformMyScoData(scenario, scenarioPiece, scoCoverageLevel, scoBasePlan),
  };

  return myScoRequest;
};
