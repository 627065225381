import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import NumberTextField, { onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { FormInputProps } from './formInputProps';
import { validateYield } from '../../../pages/units/validations/yieldValidation';
import { getInsurancePlanCodeVariationForAreaYield } from '../../../utils/priceYieldVariationUtils';
import { getTypeCodeFromTypeId } from '../../../utils/adm';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectCommoditiesWithOffers, selectYieldInfoWithCounties } from '../../../app/admSlice';
import CountyYieldInputToolTip from './countyYieldInputToolTip';

export interface ExpectedCountyYieldProps extends FormInputProps {
  expectedCountyYield: Nullable<number>;
  commodityCode: string;
  typeId: Nullable<string>;
  practiceId: Nullable<string>;
  insurancePlanCodes: string[];
  label?: string;
  volatility: number;
  coverageLevel: Nullable<number>;
}

export type ExpectedCountyYieldFields = {
  expectedCountyYield: number;
}

const ExpectedCountyYieldInput = ({ expectedCountyYield, commodityCode, typeId, insurancePlanCodes, sx, label, autoFocus = false, disabled, coverageLevel, volatility }: ExpectedCountyYieldProps) => {
  const { control } = useFormContext();
  const typeCode = typeId !== null ? getTypeCodeFromTypeId(typeId) : undefined;
  const variation = getInsurancePlanCodeVariationForAreaYield(commodityCode, insurancePlanCodes, typeCode);

  const countyYieldInfo = useAppSelector(selectYieldInfoWithCounties);
  const commodities = useAppSelector(selectCommoditiesWithOffers);

  const commodityName = commodities.find(x => x.commodityCode === commodityCode)?.name;

  return (
    <Controller
      name="expectedCountyYield"
      control={control}
      rules={{ validate: (value: Nullable<number>) => validateYield(value, false) }}
      defaultValue={expectedCountyYield ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          type="number"
          label={label ?? 'Expected County Yield'}
          fullWidth
          inputProps={{ step: 0.01, min: 0 }}
          InputProps={{
            endAdornment: <CountyYieldInputToolTip
              countyYieldInfo={countyYieldInfo}
              coverageLevel={coverageLevel}
              commodityName={commodityName}
              volatility={volatility}
              variation={variation}
            />,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          sx={sx}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default ExpectedCountyYieldInput;
