import { distinct } from '../../../../utils/arrayUtils';
import { isNotNullOrUndefined } from '../../../../utils/nullHandling';

export type AxisKey<T> = keyof T & string;


const maxPercentageDistanceToMove = 0.01;

const defaultDyUp = -10;
const defaultDyDown = 20;

const computeDistance = (me: number, other: number): number => {
  return me - other;
};

export const calculateOffsetForPoint = (me: number, others: unknown[], axisRange: number) => {
  if (axisRange < 1) return defaultDyDown;
  const othersAsNumber = others.filter((o: unknown): o is number => typeof o === 'number');

  const offsetPercentages = distinct(othersAsNumber.map(other => computeDistance(me, other) / axisRange));

  // if I have points below or above me, find the ones within the threshold to determine how much I should be moved,
  // and in what direction
  const offsetPercentagesWithinThreshold = offsetPercentages.filter(p => p !== 0);

  // if everyone in range is below me, move me up
  if (offsetPercentagesWithinThreshold.every(p => p > 0)) return defaultDyUp;
  // if everyone in range is above me, move me down
  else if (offsetPercentagesWithinThreshold.every(p => p < 0)) return defaultDyDown;

  // calculate the percentage of the threshold each neighbor should cause me to move
  const movementPercentages = offsetPercentagesWithinThreshold
    .map(offset => Math.sign(offset) * (1 - Math.abs(offset)));

  // total all of the neighbor's effects on me
  const rawOffsetPercentageAverage = movementPercentages.reduce((a, b) => a + b, 0) / movementPercentages.length;

  const maxDyDown = defaultDyDown + (axisRange * maxPercentageDistanceToMove);
  const maxDyUp = defaultDyUp + -(axisRange * maxPercentageDistanceToMove);

  // if in general I am closer to things above me than below me
  return rawOffsetPercentageAverage < 0
    // move down by the weight
    ? maxDyDown * Math.abs(Math.max(rawOffsetPercentageAverage, -1))
    : rawOffsetPercentageAverage > 0
      ? maxDyUp * Math.min(rawOffsetPercentageAverage, 1)
      : defaultDyDown;
};

const shouldOnlyShowFirstAndLast = (data: unknown[]) => {
  if (data.length < 2) return true;

  return data.every(d => d === data[0]);
};

export const shouldShowLabel = <T,>(data: T[], currentData: T, xAxisKey: AxisKey<T>, currentYAxisKey: AxisKey<T>) => {
  const filteredData = data.filter(d => isNotNullOrUndefined(d[currentYAxisKey]));
  if (shouldOnlyShowFirstAndLast(filteredData.map(d => d[currentYAxisKey]))) {
    const index = filteredData.findIndex(d => d[currentYAxisKey] === currentData[currentYAxisKey] && d[xAxisKey] === currentData[xAxisKey]);
    return index === 0 || index === filteredData.length - 1;
  }

  return true;
};

export const defaultYAxisGenerator = (yAxisValues: number[], numberOfTicks: number = 5): number[] => {
  if (yAxisValues.length === 0) return [];

  const min = Math.min(...yAxisValues);
  const max = Math.max(...yAxisValues);

  const tenPercentOfRange = Math.round((max - min) * .1);
  const axisMin = Math.max(Math.floor(min - tenPercentOfRange), 0);
  const axisMax = Math.ceil(max + tenPercentOfRange);

  const axisStep = Math.floor((axisMax - axisMin) / (numberOfTicks - 1));

  return [...Array(numberOfTicks - 1).keys()].map(index => axisMin + index * axisStep).concat(axisMax);
};

export const getDefaultLabelStyle = (isLightMode: boolean) => ({
  fontSize: 14,
  textAnchor: 'middle',
  fontWeight: 100,
  style: {
    textShadow: isLightMode
      ? '0 0 2px lightgrey, 0 0 2px lightgrey, 0 0 2px lightgrey, 0 0 2px lightgrey'
      : '0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black',
  },
  stroke: isLightMode ? 'black' : 'lightgrey',
});