import { selectIsOnline } from '../app/onlineSlice';
import { Store } from 'redux';

/**
 * Gets the online status via direct access of the state store.
 * This exists to get the current online status in places where
 * we don't have conventional access to the state store.
 * This is UNSAFE in most use cases, so DO NOT use in any component.
 * If you need the online status use the selector to pull the value from state normally.
 */
let store: Store;

// This injectStore function is used to get a usable instance of the store we can use for knowing whether or not we are offline
// We can't just directly import the store from the main file because it creates circular references as various components are trying to instantiate
// This solution follows the guidelines laid out by Redux. See following link for more details:
// https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
export const injectStore = (_store: Store) => {
  store = _store;
};

export const isOnlineFromStore = () => {
  const state = store.getState();
  const isOnline = selectIsOnline(state);
  return isOnline;
};