import { Box, Grid, List, Paper, Typography } from '@mui/material';
import SummaryStateDropdown from './summaryStateDropdown.component';
import React from 'react';
import { State } from '../../types/api/adm/State';
import { County } from '../../types/api/adm/County';

interface SummaryStateListProps {
  statesToDisplay: string[];
  states: State[];
  countiesGroupedByState: Map<string, County[]>;
}

const SummaryStateList = ({ statesToDisplay, countiesGroupedByState, states }: SummaryStateListProps) => {
  return (
    <Grid container direction="column" sx={{ height: '100%' }}>
      <Grid item xs="auto">
        <Typography>State ADMs</Typography>
      </Grid>
      <Grid item xs>
        <Paper sx={{ height: '100%', overflowY: 'auto' }}>
          <List sx={{ height: '100%', overflowY: 'auto' }} disablePadding>
            {statesToDisplay.map(state =>
              <Box key={state}>
                <SummaryStateDropdown state={state} states={states} counties={countiesGroupedByState.get(state) ?? []} />
              </Box>,
            )}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SummaryStateList;