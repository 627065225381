import {
  ProductionHailRequestDTO,
  AvailabilityService,
  ProductionHailIndemnityParams,
  ProductionHailPremiumParams
} from '@silveus/calculations';
import { RootState } from '../../../app/store';
import BaseUnit from '../../../types/api/BaseUnit';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { createBaseScenarioPieceDto } from '../baseDataTransformations';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import HailRate from '../../../types/api/hail/hailRate';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../../app/scenarioPiecesSlice';
import { getItemsForId } from '../../../utils/mapHelpers';
import { createHailUnits, getHailScenarioPieceRates, getHailScenarioPieceEndorsements, getHailScenarioPieceUnits } from './sharedHailDataTransformUtils';
import HailEndorsement from '../../../types/api/hail/hailEndorsement';
import HailScenarioPieceEndorsement from '../../../types/api/hail/HailScenarioPieceEndorsement';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';

export const createProductionHailData = async (scenarioPiece: HailScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[], shouldSimulateUnitGroups: boolean) => {
  //Get the calc data back from the API
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  const hailRates = await getHailScenarioPieceRates(state, scenarioPiece.hailScenarioPieceId);
  const hailEndorsements = await getHailScenarioPieceEndorsements(state, scenarioPiece.hailScenarioPieceId, quote);
  const hailUnits = getHailScenarioPieceUnits(state, scenarioPiece, quote, scenario, baseUnits, hailRates.hailScenarioPieceRates, hailRates.hailRates, clientFile.year, shouldSimulateUnitGroups);

  //Get the underling scenario piece
  const underlyingPieceTypes = AvailabilityService.getScenarioPiecesThatThisScenarioPieceDependsOn(scenarioPiece.scenarioPieceType);
  const underlyingMpciScenarioPiece = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), scenario.scenarioId).find(sp => underlyingPieceTypes.includes(sp.scenarioPieceType));
  if (underlyingMpciScenarioPiece === undefined) throw new Error('Could not find underlying base mpci plan for Production Hail calcs');


  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createHailDto(scenarioPiece,
    hailRates.hailRates,
    hailEndorsements.hailEndorsements,
    hailEndorsements.hailScenarioPieceEndorsements,
    underlyingMpciScenarioPiece,
    hailUnits.unitsForScenarioPiece,
    scenario,
    hailUnits.unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformHailData = (underlyingMpciScenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, scenarioPiece: HailScenarioPiece): ProductionHailPremiumParams & ProductionHailIndemnityParams => {
  const hailParams: ProductionHailPremiumParams & ProductionHailIndemnityParams = {
    discountPercent: scenarioPiece.discountPercent / 100,
    projectedPrice: scenario.projectedPrice ?? 0,
    priceElectionPercent: scenarioPiece.priceElectionPercent / 100,
    coverageLevel: scenarioPiece.upperCoverageLevel / 100,
    mpciCoverageLevel: underlyingMpciScenarioPiece.upperCoverageLevel / 100,
    hailLossPercent: scenarioPiece.lossPercent / 100,
    increasingPaymentFactor: 1, // TODO: Agrisompo plans need a json file that has this data
  };

  return hailParams;
};

const createHailDto = (
  scenarioPiece: HailScenarioPiece,
  hailRates: HailRate[],
  hailEndorsements: HailEndorsement[],
  hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[],
  underlyingMpciScenarioPiece: RowCropScenarioPiece,
  unitData: BaseUnit[],
  scenario: RowCropScenario,
  unitGroups: UnitGroup[]): ProductionHailRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createHailUnits(
        hailRates,
        hailEndorsements,
        hailScenarioPieceEndorsements,
        unitData,
      ),
    ),
    ...transformHailData(underlyingMpciScenarioPiece, scenario, scenarioPiece),
  };
};