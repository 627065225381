import { ColumnState, GridApi } from 'ag-grid-community';
import { MutableRefObject, useCallback, useEffect } from 'react';
import { sum } from '@silveus/calculations';

export const useDynamicGridSize = <T,>(gridApi: GridApi<T> | undefined, gridWrapper: MutableRefObject<HTMLElement | null>, columnState: ColumnState[]) => {
  const setGridSizeOnWindowChange = useCallback(() => setDynamicDomLayout(gridApi, gridWrapper), [gridApi, gridWrapper]);

  useEffect(() => {
    //Listen for changes to the size of the window so we know to recalculate which dom layout mode
    // we need to use when the user resizes the browser
    window.addEventListener('resize', setGridSizeOnWindowChange);
    return () => window.removeEventListener('resize', setGridSizeOnWindowChange);
  }, [setGridSizeOnWindowChange]);

  useEffect(() => {
    if (gridApi === undefined) return;
    gridApi.applyColumnState({
      state: columnState,
      applyOrder: true,
    });
  }, [gridApi, columnState]);

  //This useEffect has no dependency array because the grid needs to be resized when any number of things change.
  // Ag-grid doesn't really have a good API that would easily allow us to do this as far as I can tell
  // and the existing functionality took several days to get working properly.
  //Seeing as how the lack of dependency array is not causing any infinite rendering loops or other issues as of the
  // time of implementation, we are choosing to not modify this for the sake of time and sanity.
  useEffect(setGridSizeOnWindowChange);
};

function setDynamicDomLayout<T>(gridApi: GridApi<T> | undefined, ref: MutableRefObject<HTMLElement | null>): void {
  //This uses the grid API to calculate the size of the grid and
  // compare it to the max height of the containing element
  //Ag-Grid does not support filling parent and providing scroll bars when content is bigger,
  // while making the grid just fit content when content is smaller than parent, so we're doing it ourselves.
  if (gridApi === undefined || ref.current === null) return;
  // get the rendered rows
  const renderedRowCount = gridApi.getDisplayedRowCount();
  const maxHeight = ref.current.clientHeight;

  const renderedColumnCount = gridApi.getAllDisplayedColumns();
  const columnWidths = renderedColumnCount.map(col => col.getActualWidth());
  const totalColumnWidth = sum(columnWidths, x => x);

  const size = gridApi.getSizesForCurrentTheme();

  const calculatedGridHeight = ((renderedRowCount * size.rowHeight)
    + (size.headerHeight) + 36);

  if (calculatedGridHeight > maxHeight
    && renderedRowCount > 0) {
    gridApi.setGridOption('domLayout', 'normal');
  }
  else {
    gridApi.setGridOption('domLayout', 'autoHeight');
  }

  ref.current.style.setProperty('max-width', `${totalColumnWidth}px`);
}

