import { useFormContext, useWatch } from 'react-hook-form';
import usePercentChange from '../../../hooks/usePercentChange';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferActualInputCosts, selectOfferExpectedInputCosts } from '../../../app/admSlice';
import { useEffect } from 'react';
import { isApiDataStale, isSavedDataBeingLoaded } from '../../../utils/formInputUtils';
import MpActualInputCostsInput, { MpActualInputCostsProps } from '../scenarioPiece/mp/mpActualInputCosts';
import { ExtendedDataFormInputParameterNames } from '../constants/inputConstants';

export type MpActualInputCostsFields = {
  actualInputCosts: number;
}

const ScenarioMpActualInputCostsInput = ({ actualInputCosts, sx, commodityCode, year, autoFocus = false, disabled = false }: MpActualInputCostsProps) => {
  const fieldName = ExtendedDataFormInputParameterNames.ActualInputCosts;
  const { control, trigger, getValues, setValue } = useFormContext();

  const offerActualInputCosts = useAppSelector(selectOfferActualInputCosts);
  const offerExpectedInputCosts = useAppSelector(selectOfferExpectedInputCosts);
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });

  usePercentChange(ExtendedDataFormInputParameterNames.ExpectedInputCosts, fieldName, 'inputCostsSlider');

  useEffect(() => {
    //When the API data is changed, load it into the field,
    // except for the initial load when there is saved data
    const currentPractice = getValues('practiceId');

    const isThereStaleData = isApiDataStale(currentPractice, offerActualInputCosts) || isApiDataStale(currentPractice, offerExpectedInputCosts);
    const isLoadingSavedData = isSavedDataBeingLoaded(actualInputCosts, practice);

    if (isThereStaleData || isLoadingSavedData || (offerActualInputCosts === null && offerExpectedInputCosts === null)) return;

    setValue(fieldName, offerActualInputCosts ?? offerExpectedInputCosts);
    trigger(fieldName);
  }, [offerActualInputCosts, offerExpectedInputCosts]);

  useEffect(() => {
    //When the practice is changed, clear out the current value
    if (practice === undefined) return;

    setValue(fieldName, 0);
  }, [practice]);

  return (
    <MpActualInputCostsInput
      commodityCode={commodityCode}
      year={year}
      actualInputCosts={actualInputCosts}
      sx={sx}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  );
};

export default ScenarioMpActualInputCostsInput;
