import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppTaskId, ApplicationWizardId, InsuredId } from '../types/api/PrimaryKeys';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { RootState } from './store';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAppTask, getAppTaskSummaries, getAppTasksForAppWizard, recallAppTask as recallAppTaskById, updateAppTaskStatus } from '../services/appTasks.service';
import { AppTaskExtended, AppTaskStatusUpdate } from '../types/api/AppTask';
import { getKeyedStateGroupedBy } from './sliceHelpers';

interface AppTaskSliceState {
  allAppTasks: SliceDataState<AppTaskId, AppTaskExtended>;
}

const initialState: AppTaskSliceState = {
  allAppTasks: initialSliceDataState(),
};

export const appTaskSummarySlice = createSlice({
  name: 'appTaskWizardSummaries',
  initialState: initialState,
  reducers: {
    updateAppTask(state, action: PayloadAction<AppTaskExtended>) {
      const appTask = action.payload;
      state.allAppTasks.data[appTask.appTaskId] = appTask;
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allAppTasks, s => s.appTaskId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchAppTasks,
      affectedIds: () => [],
    });
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchAppTasksForAppWizard,
      affectedIds: () => [],
    });
  },
});

export const { updateAppTask } = appTaskSummarySlice.actions;
export default appTaskSummarySlice.reducer;

export const selectAllAppTasks = (state: RootState) => state.appTasks.allAppTasks.data;
export const selectAppTaskById = (state: RootState, appTaskId: AppTaskId) => state.appTasks.allAppTasks.data[appTaskId] ?? null;
export const selectAllAppTasksByApplicationWizardId = createSelector([selectAllAppTasks], result => {
  return getKeyedStateGroupedBy(result, a => a.applicationWizardId);
});

export const fetchAppTasks = createAppAsyncThunk('appTasks/fetchAppTasks', async ({ insuredId, year }: { insuredId: InsuredId, year: number }) => {
  return (await getAppTaskSummaries(insuredId, year)).data;
});

export const fetchAppTasksForAppWizard = createAppAsyncThunk('appTasks/fetchAppTasksForAppWizard', async (appWizardId: ApplicationWizardId) => {
  return (await getAppTasksForAppWizard(appWizardId)).data;
});

export const recallAppTask = createAppAsyncThunk('appTasks/recallAppTask', async (appTaskId: AppTaskId, thunkApi) => {
  await recallAppTaskById(appTaskId);

  const updatedAppTask = await getAppTask(appTaskId);

  thunkApi.dispatch(updateAppTask(updatedAppTask.data));
});

export const updateAppTaskStatusOnly = createAppAsyncThunk('appTasks/updateAppTaskStatus', async ({ appTaskStatusUpdate }: {appTaskStatusUpdate: AppTaskStatusUpdate }, thunkApi) => {
  await updateAppTaskStatus(appTaskStatusUpdate);

  const updatedAppTask = await getAppTask(appTaskStatusUpdate.appTaskId);

  thunkApi.dispatch(updateAppTask(updatedAppTask.data));
});