import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import { ScenarioId, ScenarioPieceGroupId } from '../types/api/PrimaryKeys';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from './thunkHelpers';
import { getKeyedStateGroupedBy, getKeyedStateValues, getStateIdsMatching } from './sliceHelpers';
import {
  createScenarioPieceGroupRequest, deleteScenarioPieceGroupRequest,
  getScenarioPieceGroupsForScenarioRequest, getScenarioPieceGroupsForScenariosRequest, updateScenarioPieceGroupRequest
} from '../services/requestInterception/scenarioPieceGroupRequestInterceptor';
import { ScenarioPieceGroup } from '../types/api/ScenarioPieceGroup';
import { RootState } from './store';
import { selectAllScenarioPiecesByScenarioMap } from './sharedSelectors';
import { ScenarioPiece } from '../types/api/ScenarioPiece';
import { getItemsForId } from '../utils/mapHelpers';

export interface ScenarioPieceGroupsSlice {
  allScenarioPieceGroups: SliceDataState<ScenarioPieceGroupId, ScenarioPieceGroup>
}

const initialState: ScenarioPieceGroupsSlice = {
  allScenarioPieceGroups: initialSliceDataState(),
};

export const scenarioPieceGroupsSlice = createSlice({
  name: 'scenarioPieceGroups',
  initialState: initialState,
  reducers: {
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allScenarioPieceGroups, s => s.scenarioPieceGroupId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchScenarioPieceGroups,
      affectedIds: (arg, state) => getStateIdsMatching(state.allScenarioPieceGroups.data, s => s.scenarioId === arg.scenarioId, s => s.scenarioPieceGroupId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchScenarioPieceGroupsByScenarioIds,
      affectedIds: () => [],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addScenarioPieceGroup,
      affectedIds: arg => arg.scenarioPieceGroup.scenarioPieceGroupId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeScenarioPieceGroup,
      affectedIds: arg => arg.scenarioPieceGroup.scenarioPieceGroupId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyScenarioPieceGroup,
      affectedIds: arg => arg.scenarioPieceGroup.scenarioPieceGroupId,
    });
  },
});

//#region Non-Memoized Selectors

const selectScenarioPieceGroupDictionary = (state: RootState) => state.scenarioPieceGroups.allScenarioPieceGroups.data;

//#endregion

//#region Memoized Selectors

export const selectAllScenarioPieceGroupsByScenarioMap = createSelector([selectScenarioPieceGroupDictionary], result => {
  return getKeyedStateGroupedBy(result, spg => spg.scenarioId);
});

export const selectAllScenarioPiecesByScenarioPieceGroupMap = createSelector([selectAllScenarioPiecesByScenarioMap, selectScenarioPieceGroupDictionary], (allScenarioPieces, scenarioPieceGroups) => {
  const pieceGroups = getKeyedStateValues(scenarioPieceGroups);

  const scenarioPiecesByScenarioPieceGroupIdMap = new Map<ScenarioPieceGroupId, ScenarioPiece[]>();

  pieceGroups.forEach(scenarioPieceGroup => {
    const scenarioPieceIdsInGroup = scenarioPieceGroup.scenarioPieceGroupMembers.map(spgm => spgm.scenarioPieceId);

    const scenarioPiecesForScenario = getItemsForId(allScenarioPieces, scenarioPieceGroup.scenarioId);
    const scenarioPiecesForGroup = scenarioPiecesForScenario.filter(sp => scenarioPieceIdsInGroup.includes(sp.scenarioPieceId));

    scenarioPiecesByScenarioPieceGroupIdMap.set(scenarioPieceGroup.scenarioPieceGroupId, scenarioPiecesForGroup);
  });

  return scenarioPiecesByScenarioPieceGroupIdMap;
});

//#endregion

//#region Thunks

export const fetchScenarioPieceGroups = createAppAsyncThunk('scenarioPieceGroups/fetchScenarioPieceGroups', async ({ scenarioId }: { scenarioId: ScenarioId }) => {
  return await getScenarioPieceGroupsForScenarioRequest(scenarioId);
});

export const fetchScenarioPieceGroupsByScenarioIds = createAppAsyncThunk('scenarioPieceGroups/fetchScenarioPieceGroupsByScenarioIds', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  return await getScenarioPieceGroupsForScenariosRequest(scenarioIds);
});

export const addScenarioPieceGroup = createAppAsyncThunk('scenarioPieceGroups/addScenarioPieceGroup', async ({ scenarioPieceGroup }: { scenarioPieceGroup: ScenarioPieceGroup }) => {
  await createScenarioPieceGroupRequest(scenarioPieceGroup);
  return scenarioPieceGroup;
});

export const modifyScenarioPieceGroup = createAppAsyncThunk('scenarioPieceGroups/modifyScenarioPieceGroup', async ({ scenarioPieceGroup }: { scenarioPieceGroup: ScenarioPieceGroup }) => {
  await updateScenarioPieceGroupRequest(scenarioPieceGroup);
  return scenarioPieceGroup;
});

export const removeScenarioPieceGroup = createAppAsyncThunk('scenarioPieceGroups/removeScenarioPieceGroup', async ({ scenarioPieceGroup }: { scenarioPieceGroup: ScenarioPieceGroup }) => {
  await deleteScenarioPieceGroupRequest(scenarioPieceGroup.scenarioPieceGroupId);
  return scenarioPieceGroup;
});

//#endregion

export default scenarioPieceGroupsSlice.reducer;