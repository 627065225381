import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Stack } from '@mui/material';
import { Info } from '../../../info/info';
import { FormInputProps } from '../../scenario/formInputProps';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

interface MpGetDiscountRateProps extends FormInputProps {
  getDiscountRate: boolean;
}

export type MpGetDiscountRateFields = {
  getDiscountRate: boolean;
}

const MpGetDiscountRateInput = ({ getDiscountRate, autoFocus = false, disabled = false }: MpGetDiscountRateProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.GetDiscountRate}
      control={control}
      defaultValue={getDiscountRate}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl
          error={!!error}
          disabled={disabled}>
          <FormControlLabel
            label={<Stack direction={'row'} spacing={1}> <div>Get Discount Rate</div> <Info tooltipContent="Historical Yield Data is required to generate a discount rate." /> </Stack>}
            control={
              <Checkbox
                {...field}
                checked={value}
                inputRef={ref}
                onChange={e => field.onChange(e.target.checked)}
                autoFocus={autoFocus}
              />
            }
          />
          <FormHelperText>{error?.message}</FormHelperText>

        </FormControl >
      )}
    />
  );
};

export default MpGetDiscountRateInput;
