import { Grid, Typography } from '@mui/material';
import { selectAllScenariosByQuoteIdMap } from '../../../app/scenariosSlice';
import { useKeyMapSelector } from '../../../hooks/reduxHooks';
import { ClientFileQuoteScenarioListScenario } from './clientFileQuoteScenarioListScenario';
import { Nullable } from '../../../types/util/Nullable';
import { QuoteId } from '../../../types/api/PrimaryKeys';

interface Props {
  selectedQuoteId: Nullable<QuoteId>;
}

export const ClientFileQuoteScenarioList = ({ selectedQuoteId }: Props) => {
  const scenarios = useKeyMapSelector(selectAllScenariosByQuoteIdMap, selectedQuoteId);

  // Render logic: -------------------------------

  // No point in showing scenario widget at all if there is no selected quote.
  if (selectedQuoteId === null) {
    return null;
  }

  return <>
    <Grid container sx={{ width: '100%', pt: 0 }} rowSpacing={1}>
      {scenarios.length !== 0 ?
        scenarios.map(s => (
          <ClientFileQuoteScenarioListScenario
            scenario={s}
            key={s.scenarioId}
          />
        )) :
        <Grid item sx={{ whiteSpace: 'pre-wrap' }}><Typography variant="subtitle2">There are no scenarios for this quote.</Typography></Grid>
      }
    </Grid>
  </>;
};
