import { Typography } from '@mui/material';
import EntityType from '../constants/entityType';

interface EntityTypeTitleProps {
  entityType: EntityType;
}

const EntityTypeTitle = ({ entityType }: EntityTypeTitleProps) => {
  const isAdded = entityType === 'added';
  return (
    <Typography sx={{ color: theme => isAdded ? theme.palette.success.main : theme.palette.error.main }}>{isAdded ? 'ADDED' : 'DELETED'}</Typography>
  );
};

export default EntityTypeTitle;