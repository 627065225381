import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { AIPId, AgentCodeId, AgentTeamId } from '../../../types/api/PrimaryKeys';
import { MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { AppTask } from '../../../types/api/AppTask';
import { AppType } from '../../../types/api/enums/application/AppType.enum';
import { useKeyMapSelector } from '../../../hooks/reduxHooks';
import { selectAgentCodes } from '../../../app/agentCodeSlice';
import { AgentCode } from '../../../types/api/agentCode';
import { handleValidationResult } from './validationHelpers';
import { validateAgentCode } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';

type AgentCodeInputProps = {
  value: Nullable<AgentCodeId>;
  index: number;
  agentTeamId: Nullable<AgentTeamId>;
  originalAIP: Nullable<AIPId>;
  originalAgentCodeId: Nullable<AgentCodeId>;
  canEditAppTask: boolean;
}

const AgentCodeInput = ({ value, index, agentTeamId, originalAIP, originalAgentCodeId, canEditAppTask }: AgentCodeInputProps) => {
  const inputName = `appTasks.${index}.agentCodeId`;
  const { control, setValue } = useFormContext();

  const allAgentCodes = useKeyMapSelector(selectAgentCodes, agentTeamId);
  const [filteredAgentCodes, setFilteredAgentCodes] = useState<AgentCode[]>([]);
  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });
  const agentCodeId: AgentCodeId = useWatch({ name: inputName, control: control });

  const aipId = currentAppTask.aipId;
  const appType = currentAppTask.appType;

  useEffect(() => {
    const tempAgentCodes = allAgentCodes.filter(x => x.aipId === aipId);
    setFilteredAgentCodes(tempAgentCodes);
    if (isNotNullOrUndefined(originalAIP) && aipId === originalAIP) {
      setValue(inputName, originalAgentCodeId);
    } else if (!tempAgentCodes.find(x => x.agentCodeId === agentCodeId)) {
      setValue(inputName, null);
    }
  }, [aipId]);

  useEffect(() => {
    if ((appType === AppType.CommitNoChange || appType === AppType.Cancel || appType === AppType.HoldForCancelTransfer) && isNotNullOrUndefined(originalAIP) && aipId === originalAIP) {
      setValue(inputName, originalAgentCodeId);
    }
  }, [appType]);

  return (
    <Controller
      control={control}
      shouldUnregister={false}
      name={inputName}
      rules={{ validate: value => handleValidationResult(validateAgentCode(value)) }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          select
          disabled={(currentAppTask.appType !== AppType.CancelTransfer && currentAppTask.appType !== AppType.New && currentAppTask.appType !== AppType.Change) || !canEditAppTask}
          error={!!error}
          inputRef={ref}
          size="small"
          value={field.value}
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          {filteredAgentCodes.map(agentCode => (
            <MenuItem sx={{ fontSize: '12px' }} key={agentCode.agentCodeId} value={agentCode.agentCodeId}>{agentCode.agentCodeName}</MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default AgentCodeInput;