import { Accordion, Box, Collapse, Popper, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import { selectCalculationsForScenario, selectScenariosThatAreLoadingCalculations } from '../../../app/calculationResultsSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { selectPracticeById, selectTypeById } from '../../../app/admSlice';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import './scenarioCard.styles.css';
import { useState } from 'react';
import PayoutGrid from '../../../components/payoutGrid/payoutGrid.component';
import ScenarioSummaryTooltip from './scenarioSummaryTooltip';

interface ScenarioSummaryProps {
  scenarioId: ScenarioId;
  typeId: string;
  practiceId: Nullable<string>;
  isReadonly: boolean;
}

const ScenarioSummary = ({ scenarioId, typeId, practiceId, isReadonly }: ScenarioSummaryProps) => {
  const calcResults = useAppSelector(state => selectCalculationsForScenario(state, scenarioId));
  const isCalculating = useAppSelector(selectScenariosThatAreLoadingCalculations)[scenarioId] ?? false;
  const type = useAppSelector(state => selectTypeById(state, typeId));
  const practice = useAppSelector(state => selectPracticeById(state, practiceId ?? ''));

  const [isInnerExpanded, setIsInnerExpanded] = useState(false);
  const [tooltipAnchor, setTooltipAnchor] = useState<Nullable<HTMLElement>>(null);
  const isTooltipOpen = isNotNullOrUndefined(tooltipAnchor) && isNotNullOrUndefined(calcResults);

  const totalPremium = calcResults?.premiumAmount ?? 0;
  const totalIndemnity = calcResults?.indemnityAmount ?? 0;
  const premiumPerAcre = calcResults?.premiumPerAcreAmount ?? 0;
  const indemnityPerAcre = calcResults?.indemnityPerAcreAmount ?? 0;
  const totalFixed = calcResults?.fixedAmount ?? 0;
  const totalSimulated = calcResults?.simulatedAmount ?? 0;
  const fixedPerAcre = calcResults?.fixedPerAcreAmount ?? 0;
  const simulatedPerAcre = calcResults?.simulatedPerAcreAmount ?? 0;
  const protectionPerAcre = calcResults?.protectionAmountPerAcre ?? 0;

  const netScenarioPerAcre = (indemnityPerAcre + simulatedPerAcre) - (premiumPerAcre + fixedPerAcre);

  let summaryTitle = '';
  if (isNotNullOrUndefined(practice)) summaryTitle = practice.name.length > 12 && !/\s/g.test(practice.name) ? summaryTitle.concat(`${practice.abbrev?.toLowerCase().replace(/\b(\w)/g, k => k.toUpperCase())}`) : summaryTitle.concat(` ${practice.name}`);
  if (isNotNullOrUndefined(type)) summaryTitle = summaryTitle.concat(` ${type.abbrev}`);

  let summaryTooltip = '';
  if (isNotNullOrUndefined(practice)) summaryTooltip = summaryTooltip.concat(`${practice.name}`);
  if (isNotNullOrUndefined(type)) summaryTooltip = summaryTooltip.concat(` ${type.name}`);

  return (
    <>
      <Accordion disableGutters expanded variant="elevation" square elevation={0} sx={{ backgroundColor: 'transparent', '&:before': { display: 'none' } }}>
        <AccordionSummary
          className="scenario-accordion-summary"
          onClick={_ => setIsInnerExpanded(prevVal => !prevVal)}
          expandIcon={<ExpandMoreIcon sx={{ color: theme => theme.palette.action.selected, alignSelf: 'flex-start', transform: isInnerExpanded ? 'rotate(0deg)' : 'rotate(-180deg)', transition: 'transform .25s' }} />}
          onMouseEnter={e => setTooltipAnchor(e.currentTarget)}
          onMouseLeave={_ => setTooltipAnchor(null)}
          sx={{ pl: 1 }}>
          <Grid container item justifyContent="space-between">
            <Grid item>
              <Tooltip title={summaryTooltip.length > 0 ? summaryTooltip : undefined}>
                <Typography sx={{ color: theme => isReadonly ? theme.palette.text.disabled : theme.palette.text.primary }} variant="clickable2">{summaryTitle}</Typography>
              </Tooltip>
            </Grid>
            <Grid item display={'flex'} alignItems={'center'}><CurrencyText currencyAmount={netScenarioPerAcre} colorize isDataLoading={isCalculating} /></Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingBottom: 0, pt: 0 }}>
          <Grid container direction="column">
            <Grid container item direction="column" justifyContent="space-around">
              <Grid container item justifyContent="space-between" >
                <Grid item><Typography variant="label">Coverage</Typography></Grid>
                <Grid item><CurrencyText colorize={false} currencyAmount={protectionPerAcre} /></Grid>
              </Grid>
              <Grid container item justifyContent="space-between" sx={{ pt: '0 !important' }}>
                <Grid item><Typography variant="label">Producer Premium</Typography></Grid>
                <Grid item><CurrencyText colorize={false} currencyAmount={premiumPerAcre} /></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Collapse in={isInnerExpanded}>
            <Box pt={1}>
              <PayoutGrid
                isReadonly={isReadonly}
                totalPremium={totalPremium}
                totalIndemnity={totalIndemnity}
                premiumPerAcre={premiumPerAcre}
                indemnityPerAcre={indemnityPerAcre}
                totalFixed={totalFixed}
                totalSimulated={totalSimulated}
                fixedPerAcre={fixedPerAcre}
                simulatedPerAcre={simulatedPerAcre}
                totalDiscount={0}
                discountPerAcre={0} />
            </Box>
          </Collapse>
        </AccordionDetails>
      </Accordion >
      {isTooltipOpen && (
        <Popper sx={{ backgroundColor: theme => theme.palette.background.paper }} placement="bottom-start" id={calcResults.id} open={isTooltipOpen} anchorEl={tooltipAnchor}>
          <ScenarioSummaryTooltip calcResults={calcResults} />
        </Popper>
      )}
    </>

  );
};

export default ScenarioSummary;
