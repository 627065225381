import React from 'react';
import { Insured as InsuredApiType } from '../../types/api/insureds/Insured';
import { ClientFile as ClientFileAPI } from '../../types/api/ClientFile';
import ClientFile from '../../pages/clientFile/clientFile.page';
import { Nullable } from '../../types/util/Nullable';
import DataManagement from '../../pages/dataManagement/dataManagement.page';
import { AgentTeam } from '../../types/api/agentTeam';

const Dashboard = React.lazy(() => import('../../pages/dashboard/dashboard.page'));
const Insured = React.lazy(() => import('../../pages/insured/insured.page'));
const ClientFilesForAgentTeams = React.lazy(() => import('../../pages/clientFiles/clientFilesForAgentTeams.page'));
const Insureds = React.lazy(() => import('../../pages/insureds/insureds.page'));
const Units = React.lazy(() => import('../../pages/units/units.page'));

const ROUTES = (insured: Nullable<InsuredApiType>, clientFile: Nullable<ClientFileAPI>, agentTeam: Nullable<AgentTeam>) => [
  {
    path: '/',
    element: <Dashboard />,
    breadcrumbs: undefined,
  },
  {
    path: '/agent-teams',
    element: <ClientFilesForAgentTeams />,
    breadcrumbs: undefined,
  },
  {
    path: '/agent-teams/:agentTeamId',
    element: <ClientFilesForAgentTeams />,
    breadcrumbs: undefined,
  },
  {
    path: '/agent-teams/:agentTeamId/clientFiles/:clientFileId',
    element: <ClientFile />,
    breadcrumbs: [
      {
        name: agentTeam?.agentTeamName,
        path: `/agent-teams/${agentTeam?.agentTeamId}`,
      },
      {
        name: clientFile?.name,
        path: `/agent-teams/${agentTeam?.agentTeamId}/clientFiles/${clientFile?.clientFileId}`,
      },
    ],
  },
  {
    path: '/insureds',
    element: <Insureds />,
    breadcrumbs: undefined,
  },
  {
    path: '/data-management',
    element: <DataManagement />,
    breadcrumbs: undefined,
  },
  {
    path: '/insureds/:insuredId',
    element: <Insured />,
    breadcrumbs: [
      {
        name: insured?.name,
        path: `/insureds/${insured?.id}`,
      },
    ],
  },
  {
    path: '/insureds/:insuredId/units',
    element: <Units />,
    breadcrumbs: [
      {
        name: 'Units',
        path: `/insureds/${insured?.id}/units`,
      },
    ],
  },
  {
    path: '/insureds/:insuredId/clientFiles/:clientFileId',
    element: <ClientFile />,
    breadcrumbs: [
      {
        name: clientFile?.name,
        path: `/insureds/${insured?.id}/clientFiles/${clientFile?.clientFileId}`,
      },
    ],
  },
] as const;

export type ApplicationRoutes = ReturnType<typeof ROUTES>;

export default ROUTES;
