import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Nullable } from '../types/util/Nullable';
import { RootState } from './store';
import {
  ScenarioId,
  ScenarioPieceId
} from '../types/api/PrimaryKeys';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { getKeyedStateGroupedBy, getStateIdsMatching } from './sliceHelpers';
import { updateCalculationForScenario } from './calculationResultsSlice';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import { updateUnitGroupsForScenarioPiece } from './unitGroupsSlice';
import { InputCostScenarioPiece } from '../types/api/InputCostScenarioPiece';
import {
  createInputCostScenarioPieceRequest,
  deleteInputCostScenarioPieceRequest,
  getInputCostScenarioPiecesForScenarioRequest,
  getInputCostScenarioPiecesForScenariosRequest,
  updateInputCostScenarioPieceRequest
} from '../services/requestInterception/inputCostScenarioPieceRequestInterceptor';
import { orderMap } from '../utils/mapHelpers';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';

export interface ScenarioPiecesState {
  allScenarioPieces: SliceDataState<ScenarioPieceId, InputCostScenarioPiece>;
}

const initialState: ScenarioPiecesState = {
  allScenarioPieces: initialSliceDataState(),
};

export const inputCostScenarioPiecesSlice = createSlice({
  name: 'inputCostScenarioPieces',
  initialState: initialState,
  reducers: {
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allScenarioPieces, s => s.scenarioPieceId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addInputCostScenarioPiece,
      affectedIds: arg => arg.scenarioPiece.scenarioPieceId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchInputCostScenarioPieces,
      affectedIds: (arg, state) => getStateIdsMatching(state.allScenarioPieces.data, s => s.scenarioId === arg.scenarioId, s => s.scenarioPieceId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchInputCostScenarioPiecesByScenarioIds,
      affectedIds: () => [],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeInputCostScenarioPiecesCore,
      affectedIds: arg => arg.allPiecesToDelete.map(p => p.scenarioPieceId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyInputCostScenarioPiece,
      affectedIds: arg => arg.scenarioPiece.scenarioPieceId,
    });
  },
});


// Memoized Selectors
const selectScenarioPieceDictionary = (state: RootState) => state.inputCostScenarioPieces.allScenarioPieces.data;

export const selectAllInputCostScenarioPiecesByScenarioMap = createSelector([selectScenarioPieceDictionary], result => {
  const map = getKeyedStateGroupedBy(result, sp => sp.scenarioId);
  const ordered = orderMap(map, DefaultOrders.inputCostScenarioPieces);
  return ordered;
});

// Non-Memoized Selectors
export const selectInputCostScenarioPiece = (state: RootState, scenarioPieceId: ScenarioPieceId): Nullable<InputCostScenarioPiece> => state.inputCostScenarioPieces.allScenarioPieces.data[scenarioPieceId] ?? null;

export const fetchInputCostScenarioPieces = createAppAsyncThunk('inputCostScenarioPieces/fetchScenarioPieces', async ({ scenarioId }: { scenarioId: ScenarioId }) => {
  return await getInputCostScenarioPiecesForScenarioRequest(scenarioId);
});

export const fetchInputCostScenarioPiecesByScenarioIds = createAppAsyncThunk('inputCostScenarioPieces/fetchInputCostScenarioPiecesByScenarioIds', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  return await getInputCostScenarioPiecesForScenariosRequest(scenarioIds);
});

export const addInputCostScenarioPiece = createAppAsyncThunk('inputCostScenarioPieces/addScenarioPiece', async ({ scenarioPiece }: { scenarioPiece: InputCostScenarioPiece }) => {
  await createInputCostScenarioPieceRequest(scenarioPiece);
  return scenarioPiece;
});

export const createDuplicatedInputCostScenarioPiece = (sp: InputCostScenarioPiece, scenarioId: ScenarioId): InputCostScenarioPiece => {
  const scenarioPiece: InputCostScenarioPiece = {
    ...sp,
    scenarioId: scenarioId,
    inputCostScenarioPieceId: generatePrimaryKey(),
    scenarioPieceId: generatePrimaryKey(),
  };

  return scenarioPiece;
};

export const duplicateInputCostScenarioPieces = createAppAsyncThunk('inputCostScenarioPieces/duplicateScenarioPieces', async ({ inputCostScenarioPieces, scenarioId }: { inputCostScenarioPieces: InputCostScenarioPiece[], scenarioId: ScenarioId }, thunkApi) => {
  const newPieces = inputCostScenarioPieces.map(async sp => {
    const scenarioPiece = createDuplicatedInputCostScenarioPiece(sp, scenarioId);

    await thunkApi.dispatch(addInputCostScenarioPiece({ scenarioPiece: scenarioPiece }));
    await thunkApi.dispatch(updateUnitGroupsForScenarioPiece({ scenarioPieceId: scenarioPiece.scenarioPieceId }));
  });
  return Promise.all(newPieces);
});

export const modifyInputCostScenarioPiece = createAppAsyncThunk(
  'inputCostScenarioPieces/modifyScenarioPiece', async ({ scenarioPiece }: { scenarioPiece: InputCostScenarioPiece }, thunkApi) => {
    const piecesToUpdate: InputCostScenarioPiece[] = [];
    piecesToUpdate.push(scenarioPiece);

    const updatePromises = piecesToUpdate.map(sp => updateInputCostScenarioPieceRequest(sp));
    await Promise.all(updatePromises);
    return piecesToUpdate;
  },
);

const removeInputCostScenarioPiece = createAppAsyncThunk(
  'scenarios/removeScenarioPiece',
  async ({ scenarioPiece }: { scenarioPiece: InputCostScenarioPiece }, thunkApi) => {
    const allPiecesToDelete = [scenarioPiece];

    await thunkApi.dispatch(removeInputCostScenarioPiecesCore({ allPiecesToDelete }));
  },
);

const removeInputCostScenarioPiecesCore = createAppAsyncThunk(
  'scenarios/remove-scenario-pieces',
  async ({ allPiecesToDelete }: { allPiecesToDelete: InputCostScenarioPiece[] }, thunkApi) => {
    // short circuit because logic below requires at least one piece.
    if (allPiecesToDelete.length === 0) { return []; }

    const allPieceIdsToDelete = allPiecesToDelete.map(sp => sp.scenarioPieceId);

    //Send requests to delete all of them
    //TODO: This should probably be done as a bulk operation instead of a bunch of individual operations
    const deletePromises = allPieceIdsToDelete.map(scenarioPieceId => deleteInputCostScenarioPieceRequest(scenarioPieceId));

    //Wait for requests to finish
    await Promise.all(deletePromises);

    //Return IDs of objects that got deleted
    return allPiecesToDelete;
  });

export const removeInputCostScenarioPieceAndRecalculate = createAppAsyncThunk(
  'inputCostScenarioPieces/removeScenarioPieceAndRecalculate', async ({ scenarioPiece }: { scenarioPiece: InputCostScenarioPiece }, thunkApi) => {
    await thunkApi.dispatch(removeInputCostScenarioPiece({ scenarioPiece: scenarioPiece }));

    await thunkApi.dispatch(updateCalculationForScenario({ scenarioId: scenarioPiece.scenarioId }));
  },
);

export default inputCostScenarioPiecesSlice.reducer;
