import { Grid, Typography } from '@mui/material';
import { CellDataOverlay, TooltipProps } from '@silveus/react-matrix';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectScenarioById } from '../../app/scenariosSlice';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { toPrimaryKey } from '../../utils/primaryKeyHelpers';
import CurrencyText from '../../components/currencyText/currencyText.component';
import { CalculationsCellDataOverlayChild, ScenarioPieceTypeAttributes } from '@silveus/calculations';
import { useTheme } from '@mui/system';

type MatrixCellTooltipProps = TooltipProps & {
  overlays: CellDataOverlay<CalculationsCellDataOverlayChild>[];
};

const MatrixCellTooltip = ({ overlays }: MatrixCellTooltipProps) => {
  const theme = useTheme();

  const tooltipStyle = {
    maxWidth: '300px',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.altText.grey,
  };

  return (
    <Grid container sx={{ ...tooltipStyle }} rowGap={1}>
      {overlays.map(overlay => {
        return (
          <Grid xs={12} item key={overlay.overlayId}>
            <ScenarioTooltipSummary overlay={overlay} />
          </Grid>
        );
      })}
    </Grid>
  );
};

const ScenarioTooltipSummary = ({ overlay }: { overlay: CellDataOverlay<CalculationsCellDataOverlayChild> }) => {
  const scenarioName = useAppSelector(state => selectScenarioById(state, toPrimaryKey<ScenarioId>(overlay.overlayId))?.name);

  return (
    <Grid container rowGap={1}>
      <Grid item>
        <Typography variant="subtitle2" fontWeight="600">{scenarioName}</Typography>
      </Grid>
      <Grid container item>
        {overlay.children.map(overlayChild => (
          <ScenarioPieceTooltipSummary key={overlayChild.overlayChildId} overlayChild={overlayChild} />
        ))}
      </Grid>
    </Grid>
  );
};

const ScenarioPieceTooltipSummary = ({ overlayChild }: { overlayChild: CalculationsCellDataOverlayChild }) => {
  const scenarioPieceDescription = ScenarioPieceTypeAttributes[overlayChild.scenarioPieceType].description;

  return (
    <Grid container item pl={2}>
      <Grid item xs={12}>
        <Typography variant="body1">{scenarioPieceDescription}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="body1">Gross:</Typography>
      </Grid>
      <Grid item xs={5}>
        <CurrencyText colorize={true} currencyAmount={overlayChild.gross} />
      </Grid>
      <Grid item xs={7}>
        <Typography variant="body1">Net:</Typography>
      </Grid>
      <Grid item xs={5}>
        <CurrencyText colorize={true} currencyAmount={overlayChild.net} />
      </Grid>
    </Grid>
  );
};

export default MatrixCellTooltip;