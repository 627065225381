import { quoterDb } from '../../db';
import { InsuredId, QuoteId, ScenarioId, UnitYearAphId, UnitYearId } from '../../types/api/PrimaryKeys';
import UnitYearAph from '../../types/api/UnitYearAph';
import {
  createUnitYearAph,
  deleteAph, deleteAphForUnitYear,
  getUnitYearAph, getUnitYearAphForQuotes, getUnitYearAphForScenarios,
  updateUnitYearAph,
  updateUnitYearAphAcres
} from '../units.service';
import {
  addMultiple,
  getMultiple,
  update, getDefaultCachingStrategy
} from '../offlineDataAccess.service';
import { CreatedItemsResult } from '../../types/api/results/CreatedItemsResult';
import UnitYear from '../../types/api/UnitYear';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { nonGetCacheOnly, nonGetNetworkOnly } from '../../utils/cachingStrategies';
import { isOnlineFromStore } from '../../utils/isOnlineFromStore';
import { applySoftDelete } from '../../utils/dexieQueryHelpers/softDelete';
import { distinctBy } from '../../utils/arrayUtils';
import { PromiseExtended } from 'dexie';
import { isInsuredOwnedClientFile } from '../../utils/clientFileUtils';

const table = quoterDb.unitYearAph;

export const getUnitYearAphRequest = async (unitYearId: UnitYearId): Promise<UnitYearAph[]> => {
  const request = () => getUnitYearAph(unitYearId);
  return await getMultiple(table, { unitYearId: unitYearId }, request);
};

export const createUnitYearAphRequest = async (aph: UnitYearAph[]): Promise<CreatedItemsResult<UnitYearAphId>> => {
  const request = () => createUnitYearAph(aph);
  return await addMultiple(table, aph, request);
};

export const updateUnitYearAphRequest = async (aph: UnitYearAph[]): Promise<void> => {
  const request = () => updateUnitYearAph(aph);
  return await update(table, aph, request);
};

export const updateUnitYearAphAcresRequest = async (insuredId: InsuredId, unitYear: number, historicalYear: number): Promise<UnitYear[]> => {
  const request = () => updateUnitYearAphAcres(insuredId, unitYear, historicalYear);

  const transactionTables = [
    quoterDb.unitYears,
    quoterDb.unitYearAph,
  ];

  const updateTransaction = async () => {
    return quoterDb.transaction('rw', transactionTables, async () => {
      const unitYears = await safeWhere(quoterDb.unitYears, { insuredId: insuredId, year: unitYear }).toArray();
      const unitYearIds = unitYears.map(uy => uy.unitYearId);

      const unitAphs = await safeWhere(quoterDb.unitYearAph, { year: historicalYear }).and(uya => unitYearIds.includes(uya.unitYearId)).toArray();

      const modifiedUnitYears: UnitYear[] = [];

      unitYears.forEach(unitYear => {
        const matchingUnitYearAph = unitAphs.find(ua => ua.year === historicalYear && ua.unitYearId === unitYear.unitYearId);

        if (matchingUnitYearAph === undefined || matchingUnitYearAph.acres === null) return;

        const newUnitYear: UnitYear = { ...unitYear, acres: matchingUnitYearAph.acres };
        modifiedUnitYears.push(newUnitYear);
      });

      await quoterDb.unitYears.bulkPut(modifiedUnitYears);

      return safeWhere(quoterDb.unitYears, { insuredId: insuredId }).toArray();
    });
  };

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;

  return await strategy(request, updateTransaction);
};

export const deleteAphRequest = async (unitYearAphIds: UnitYearAphId[]): Promise<void> => {
  const request = () => deleteAph(unitYearAphIds);

  const transactionTables = [quoterDb.unitYearAph];

  const updateTransaction = () => quoterDb.transaction('rw', transactionTables, async () => {
    await deleteAphLocally(unitYearAphIds);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;
  return strategy(request, updateTransaction);
};

export const deleteAphForUnitYearRequest = async (unitYearId: UnitYearId): Promise<void> => {
  const request = () => deleteAphForUnitYear(unitYearId);

  const transactionTables = [quoterDb.unitYearAph];

  const updateTransaction = () => quoterDb.transaction('rw', transactionTables, async () => {
    const unitYearAph = safeWhere(quoterDb.unitYearAph, { unitYearId: unitYearId });
    const unitYearAphIds = (await unitYearAph.toArray()).map(uya => uya.unitYearAphId);
    await deleteAphLocally(unitYearAphIds);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;
  return strategy(request, updateTransaction);
};

export const getUnitYearAphForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<UnitYearAph[]> => {
  const request = () => getUnitYearAphForScenarios(scenarioIds);

  const transactionTables = [quoterDb.rowCropScenarios, quoterDb.quotes, quoterDb.clientFiles, quoterDb.unitYears, quoterDb.unitYearAph];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {

    const scenarios = await safeWhere(quoterDb.rowCropScenarios, 'scenarioId').anyOf(scenarioIds).toArray();
    const quoteIds = distinctBy(scenarios.map(scenario => scenario.quoteId), x => x);
    const quotes = await safeWhere(quoterDb.quotes, 'quoteId').anyOf(quoteIds).toArray();
    const clientFileIds = distinctBy(quotes.map(quote => quote.clientFileId), x => x);
    const clientFiles = (await safeWhere(quoterDb.clientFiles, 'clientFileId').anyOf(clientFileIds).toArray()).filter(isInsuredOwnedClientFile);

    const unitYearPromises: PromiseExtended<UnitYear[]>[] = [];
    for (const quote of quotes) {
      const clientFile = clientFiles.find(cf => cf.clientFileId === quote.clientFileId);
      if (clientFile === undefined) continue;

      const unitYears = safeWhere(quoterDb.unitYears, { insuredId: clientFile.insuredId, year: clientFile.year, commodityCode: quote.commodityCode, countyId: quote.countyId }).toArray();
      unitYearPromises.push(unitYears);
    }
    const unitYears = (await Promise.all(unitYearPromises)).flat();
    const unitYearIds = unitYears.map(uy => uy.unitYearId);
    const unitYearAph = safeWhere(quoterDb.unitYearAph, 'unitYearId').anyOf(unitYearIds).toArray();
    return unitYearAph;
  });

  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const getUnitYearAphForQuotesRequest = async (quoteIds: QuoteId[]): Promise<UnitYearAph[]> => {
  const request = () => getUnitYearAphForQuotes(quoteIds);

  const transactionTables = [quoterDb.quotes, quoterDb.clientFiles, quoterDb.unitYears, quoterDb.unitYearAph];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const quotes = await safeWhere(quoterDb.quotes, 'quoteId').anyOf(quoteIds).toArray();
    const clientFileIds = distinctBy(quotes.map(quote => quote.clientFileId), x => x);
    const clientFiles = (await safeWhere(quoterDb.clientFiles, 'clientFileId').anyOf(clientFileIds).toArray()).filter(isInsuredOwnedClientFile);

    const unitYearPromises: PromiseExtended<UnitYear[]>[] = [];
    for (const quote of quotes) {
      const clientFile = clientFiles.find(cf => cf.clientFileId === quote.clientFileId);
      if (clientFile === undefined) continue;

      const unitYears = safeWhere(quoterDb.unitYears, { insuredId: clientFile.insuredId, year: clientFile.year, commodityCode: quote.commodityCode, countyId: quote.countyId }).toArray();
      unitYearPromises.push(unitYears);
    }
    const unitYears = (await Promise.all(unitYearPromises)).flat();
    const unitYearIds = unitYears.map(uy => uy.unitYearId);
    const unitYearAph = safeWhere(quoterDb.unitYearAph, 'unitYearId').anyOf(unitYearIds).toArray();
    return unitYearAph;
  });

  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const deleteAphLocally = async (unitYearAphIds: UnitYearAphId[]): Promise<void> => {
  const unitYearAph = safeWhere(quoterDb.unitYearAph, 'unitYearAphId').anyOf(unitYearAphIds);

  await applySoftDelete(unitYearAph);
};
