import {
  selectPrivateProductUpperCoverageLevels
} from '../../../app/privateProductSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { UpperCoverageLevelInput, UpperCoverageLevelProps } from './upperCoverageLevelInput.component';

export const PrivateProductUpperCoverageLevelInput = ({ upperCoverageLevel, autoFocus = false, disabled = false }: UpperCoverageLevelProps) => {
  const coverageLevels = useAppSelector(selectPrivateProductUpperCoverageLevels)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => a > b ? -1 : 1);
  return (<UpperCoverageLevelInput upperCoverageLevel={upperCoverageLevel} autoFocus={autoFocus}
    disabled={disabled} availableCoverageLevels={coverageLevels} />);
};

