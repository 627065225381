import ScenarioQuickUnit from '../../types/api/ScenarioQuickUnit';
import { ScenarioId, ScenarioQuickUnitId } from '../../types/api/PrimaryKeys';
import {
  createScenarioQuickUnit,
  deleteScenarioQuickUnit,
  getScenarioQuickUnit,
  updateScenarioQuickUnit
} from '../units.service';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import { addSingle, getSingle, removeWhere, update } from '../offlineDataAccess.service';
import { quoterDb } from '../../db';

export const getScenarioQuickUnitRequest = async (scenarioId: ScenarioId): Promise<ScenarioQuickUnit> => {
  const request = () => getScenarioQuickUnit(scenarioId);

  return getSingle(quoterDb.scenarioQuickUnits, { scenarioId: scenarioId }, request);
};

export const createScenarioQuickUnitRequest = async (scenarioQuickUnit: ScenarioQuickUnit): Promise<CreatedItemResult<ScenarioQuickUnitId>> => {
  const request = () => createScenarioQuickUnit(scenarioQuickUnit);

  return addSingle(quoterDb.scenarioQuickUnits, scenarioQuickUnit, request);
};

export const updateScenarioQuickUnitRequest = async (scenarioQuickUnit: ScenarioQuickUnit): Promise<void> => {
  const request = () => updateScenarioQuickUnit(scenarioQuickUnit);

  return update(quoterDb.scenarioQuickUnits, scenarioQuickUnit, request);
};

export const deleteScenarioQuickUnitRequest = async (scenarioId: ScenarioId): Promise<void> => {
  const request = () => deleteScenarioQuickUnit(scenarioId);

  return removeWhere(quoterDb.scenarioQuickUnits, { scenarioId: scenarioId }, request);
};