import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../../types/util/Nullable';
import NumberTextField, { getRoundedValue, onNumericInputChange } from '../../../numberTextField/numberTextField.component';
import { validatePrice } from '../../../../pages/units/validations/priceValidation';
import { FormInputProps } from '../../scenario/formInputProps';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import { useCommodityOptionBasedPrecisionForScenario } from '../../../../hooks/useCommodityOptionBasedPrecision';
import { RowCropScenario } from '../../../../types/api/RowCropScenario';
import { Quote } from '../../../../types/api/Quote';
import { PriceInputTooltip } from '../../scenario/priceInputTooltip';
import { selectMpProjectedPriceToolTip } from '../../../../app/admSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectClientFileById } from '../../../../app/clientFilesSlice';
import { MissingClientFileInStateError } from '../../../../errors/state/MissingStateErrors';
import OptionState from '../../../../types/app/OptionState';

export interface MpProjectedPriceProps extends FormInputProps {
  quote: Quote;
  scenario: Nullable<RowCropScenario>;
  scenarioOptionState: OptionState[];
  projectedPrice: Nullable<number>;
}

export type MpProjectedPriceFields = {
  projectedPrice: number;
}

const MpProjectedPriceInput = ({ quote, scenario, scenarioOptionState, projectedPrice, sx, autoFocus = false, disabled = false }: MpProjectedPriceProps) => {
  const fieldName = ExtendedDataFormInputParameterNames.ProjectedPrice;
  const { control } = useFormContext();
  const { precision, step } = useCommodityOptionBasedPrecisionForScenario(quote.commodityCode, scenarioOptionState);

  const type = useWatch({ name: 'typeId', control: control, defaultValue: undefined });
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });
  const clientFile = useAppSelector(state => selectClientFileById(state, quote.clientFileId));
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  const mpProjectedPriceHintLines = useAppSelector(state => selectMpProjectedPriceToolTip(
    state,
    quote.countyId,
    quote.commodityCode,
    scenario?.typeId ?? type ?? '',
    scenario?.practiceId ?? practice ?? '',
    clientFile.year,
    scenario?.scenarioId ?? null,
  ));

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: (value: Nullable<number>) => validatePrice(value) }}
      defaultValue={getRoundedValue(projectedPrice, precision) ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          value={getRoundedValue(field.value, precision)}
          type="number"
          label="MP Proj Price"
          fullWidth
          inputProps={{ step: step, min: 0 }}
          InputProps={{
            endAdornment: <PriceInputTooltip priceHintLines={mpProjectedPriceHintLines} />,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          sx={sx}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default MpProjectedPriceInput;
