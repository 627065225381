import { useTrendLineYields } from '../../../hooks/useTrendlineYields';
import { Grid } from '@mui/material';
import { AnalysisLineChart } from './charts/AnalysisLineChart';
import HistoricalYield from '../../../types/api/adm/HistoricalYield';
import { AcreageType } from '@silveus/calculations';
import { ScenarioId, TrendlineAnalysisId } from '../../../types/api/PrimaryKeys';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { addLineData, modifyLineData, selectLineDataByType } from '../../../app/trendlineAnalysisSlice';
import { TrendlineType } from '../../../types/api/enums/trendlineAnalysis/TrendlineType.enum';
import { useLineData } from '../../../hooks/useLineData';
import { getFormattedLineDataName } from '../../../utils/lineDataHelpers';
import { pickColor } from './charts/chartColors';
import { defaultYAxisGenerator } from './charts/lineCharts.utils';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import { useMemo } from 'react';

interface YieldChartProps {
  scenarioId: ScenarioId;
  acreageType: AcreageType;
  trendlineAnalysisId: TrendlineAnalysisId;
  startYear: number;
  endYear: number;
  showLabels: boolean;
}

const defaultTrendlineDataKeys = new Set<keyof HistoricalYield>(['trendYield', 'actualYield', 'expectedCountyYield', 'actualCountyYield']);

const YieldChart = ({ scenarioId, showLabels, startYear, endYear, trendlineAnalysisId, acreageType }: YieldChartProps) => {
  const dispatch = useAppDispatch();
  const cachedLineData = useAppSelector(state => selectLineDataByType(state, trendlineAnalysisId, TrendlineType.Yield));
  const sortedChartData = useTrendLineYields(scenarioId, startYear, endYear, acreageType);

  // Flatten the data so rechart could use it
  const { flattenedChartData, lineData } = useMemo(() => {
    const flattenedChartData = sortedChartData.map(historicalYield => {
      const productRevenues = historicalYield.historicalYieldProducts ?? [];

      const newHistoricalYield: Omit<HistoricalYield, 'historicalYieldProducts'> & { [key: string]: number | undefined } = {
        year: historicalYield.year,
        trendYield: historicalYield.trendYield,
        actualYield: historicalYield.actualYield,
        expectedCountyYield: historicalYield.expectedCountyYield,
        actualCountyYield: historicalYield.actualCountyYield,
      };

      productRevenues.forEach(productRevenue => {
        newHistoricalYield[`${productRevenue.scenarioPieceName} Upper Cov. Lvl.`] = productRevenue.upperYieldCoverageLevel;
        if (productRevenue.lowerYieldCoverageLevel !== undefined && productRevenue.lowerYieldCoverageLevel !== 0) newHistoricalYield[`${productRevenue.scenarioPieceName} Lower Cov. Lvl.`] = productRevenue.lowerYieldCoverageLevel;
      });

      return newHistoricalYield;
    });

    const lineData = Object.keys(flattenedChartData[0])
      .filter(key => key !== 'year')
      .map((key, index) => ({
        dataKey: key as keyof HistoricalYield,
        color: pickColor(index),
        name: getFormattedLineDataName(key),
      }));

    return {
      flattenedChartData,
      lineData,
    };
  }, [sortedChartData]);

  const trendlineLineData = useLineData(lineData, trendlineAnalysisId, cachedLineData, TrendlineType.Yield, defaultTrendlineDataKeys);
  if (cachedLineData.length === 0) {
    dispatch(addLineData({ lineData: trendlineLineData }));
  } else if (lineData.some(ld => !cachedLineData.find(x => x.dataKey === ld.dataKey) || cachedLineData.some(x => !lineData.find(ld => ld.dataKey === x.dataKey)))) {
    dispatch(modifyLineData({ lineData: trendlineLineData, trendlineAnalysisId: trendlineAnalysisId }));
  }

  const filteredLineData = lineData.filter(ld => cachedLineData.filter(x => x.isVisible).find(x => ld.dataKey === x.dataKey));

  const customYAxisTicks = useMemo(() => defaultYAxisGenerator(
    filteredLineData
      .map(ld => ld.dataKey)
      .flatMap(key => flattenedChartData.map(d => d[key]))
      .filter((v): v is number => isNotNullOrUndefined(v)),
  ), [filteredLineData, flattenedChartData]);

  return (
    <Grid container sx={{ height: '100%', p: 2 }}>
      <AnalysisLineChart
        data={flattenedChartData}
        lineData={filteredLineData}
        customYAxisTicks={customYAxisTicks}
        xAxisKey={'year'}
        showTooltip
        showLabels={showLabels}
      />
    </Grid>
  );
};

export default YieldChart;
