import VALIDATION_RULES from '../../constants/formValidationRules';
import { TextField } from '@mui/material';
import { Nullable } from '../../types/util/Nullable';
import { Controller, useFormContext } from 'react-hook-form';

interface NameProps {
  name: Nullable<string>;
  autoFocus?: boolean;
  disabled?: boolean;
}

export type NameFields = {
  name: string;
}

const NameInput = ({ name, autoFocus = false, disabled = false }: NameProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="name"
      control={control}
      rules={{ required: VALIDATION_RULES.required(), maxLength: VALIDATION_RULES.maxLength(100) }}
      defaultValue={name ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Name"
          fullWidth
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default NameInput;

