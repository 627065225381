/* eslint-disable no-unused-vars */
export enum EntityTypeEnum {
    Corporation = 'Corporation',
    Estates = 'Estates',
    Individuals = 'Individuals',
    Partnership = 'Partnership',
    PublicSchools = 'Public Schools',
    BureauOfIndianAffairsAndIndianTribeTreaties = 'Bureau of Indian Affairs and Indian Tribe Treaties',
    NonProfitTaxExemptOrganizations = 'Non-Profit or Tax-Exempt Organizations',
    TransferOfRightToIndemnitySBIOnly = 'Transfer of Right to Indemnity (SBI only)',
    ReceiverOrLiquidator = 'Receiver or Liquidator',
    PublicAgencyState = 'Public Agency State',
    JointOperationsVenturesAndCoOwnerships = 'Joint operations/ventures and co-ownerships',
    TrustRevocable = 'Trust - Revocable',
    SpousalMarried = 'Spousal/Married',
    TrustIrrevocable = 'Trust - Irrevocable',
    UndividedInterest = 'Undivided Interest',
    PublicAgencyCounty = 'Public Agency County',
    PublicAgencyCity = 'Public Agency City',
    IndividualOperatingAsABusiness = 'Individual Operating as a Business',
    LimitedLiabilityCompany = 'Limited Liability Company',
    StateLocalGovernment = 'State/Local Government',
}