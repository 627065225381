/**
 * Convert a string to title case
 * Ex: foo string -> Foo String
 */
export function toTitleCase(str: string): string {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    },
  );
}

export const parseNumber = (value: string): number => {
  // Remove anything except 0-9 and a period for a decimal point
  const cleanedString = value.toString().replace(/[^0-9.-]/g, '');
  return parseFloat(cleanedString === '' ? '0' : cleanedString);
};

export const toBase64String = (arrayBuffer: ArrayBuffer): string => {
  let binaryString = '';
  const bytes = new Uint8Array(arrayBuffer);
  const length = bytes.byteLength;

  for (let i = 0; i < length; i++) {
    binaryString += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binaryString);
};