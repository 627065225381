import Matrix from '../../../types/api/Matrix';
import { Matrix as MatrixUI, SweetSpot as MatrixSweetSpot, useSweetSpots } from '@silveus/react-matrix';
import { CSSProperties, useRef } from 'react';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { styled, useTheme } from '@mui/material/styles';
import { IEnumAttributes } from '../../../types/api/enums/IEnumAttributes';
import { useGetSelectedScenarioRequestsForScenarios } from '../../../hooks/scenarioHooks';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { selectMatrixPresets } from '../../../app/userSettingsSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SweetSpot from '../../../types/api/SweetSpot';
import {
  addNewSweetSpotToSet,
  modifySweetSpots,
  selectMatrixPresetIdForMatrixId,
  setSelectedMatrixPresetIdForMatrixId
} from '../../../app/matricesSlice';
import { ScenarioPieceType } from '@silveus/calculations';
import IncludedScenarios from './includedScenarios.component';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import { isEqual } from 'lodash';
import { MatrixSweetSpots } from './matrixSweetSpots.component';
import { Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, TextField } from '@mui/material';
import { produce } from 'immer';
import { useMatrixHeatMap } from './hooks/useMatrixHeatMap';
import {
  createCellBackgroundColorProvider,
  getBottomXAxisDefinition,
  mainOverlayChildValueSelector
} from '../../../utils/matrixUtils';
import { useMatrixOverlays } from '../../../hooks/matrix/useMatrixOverlays';
import { useMatrixOverlayChildren } from '../../../hooks/matrix/useMatrixOverlayChildren';
import { useMatrixCellDataProvider } from '../../../hooks/matrix/useMatrixCellDataProvider';
import { MatrixPresetId } from '../../../types/api/PrimaryKeys';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { getDisplayNameForMatrixPreset } from '../../../utils/matrixPreset.utils';
import { useMatrixCellTextColorSettings } from '../../../hooks/matrix/useMatrixCellTextColorSettings';
import { matrixTopAxisLabel } from '../../matrix/matrixDefaults';

interface MatrixWrapperProps {
  matrix: Matrix;
  includedScenarios: RowCropScenario[];
  includedScenarioPieces: Set<IEnumAttributes<ScenarioPieceType>>
}

const mapToMatrixSweetSpots = (sweetSpots: readonly SweetSpot[]): MatrixSweetSpot[] => {
  return sweetSpots.map(ss => ({
    sweetSpotId: ss.sweetSpotId,
    cellRange: ss.cellRange,
    isHidden: ss.isForMatrixSummary || ss.isHidden, // Even though all matrix summaries should be hidden, explicitly accounting for that for BC.
    color: ss.color,
  }));
};

const MatrixWrapper = ({ matrix, includedScenarios, includedScenarioPieces }: MatrixWrapperProps) => {
  const dispatch = useAppDispatch();

  const { heatMapDataProvider } = useMatrixHeatMap(includedScenarios.map(s => s.scenarioId));
  const matrixPresets = useAppSelector(selectMatrixPresets);
  const selectedMatrixPresetId = useAppSelector(state => selectMatrixPresetIdForMatrixId(state, matrix.matrixId));
  const selectedMatrixPreset = matrixPresets.find(mp => mp.matrixPresetId === selectedMatrixPresetId) ?? matrixPresets.find(mp => mp.isDefault);

  const theme = useTheme();

  // Using ref here is a little bit of a hack, but it seems to be the only way to have a local reference of exactly what
  // the latest sweet spot state is when we're getting a ton of update events and need to maintain knowledge of the previous
  // state of sweet spots before trying to update them with new event information.
  const lastSweetSpotUpdateReceivedFromEvent = useRef<SweetSpot[]>(matrix.sweetSpots);

  const handleAddSweetSpot = async () => {
    const newSweetSpotList = addNewSweetSpotToSet(matrix.sweetSpots);

    // Why this is done: The event handler code in "onSweetSpotChanged" was originally written under the assumption the sweet spot list would never change.
    // This is a workaround to ensure that the sweet spot list is updated when a sweet spot is manually added for the handler to work properly.
    lastSweetSpotUpdateReceivedFromEvent.current = newSweetSpotList;

    // Persist the change to redux, since nothing else will trigger the sweet spot list to update.
    const newMatrixData = produce(matrix, draft => {
      draft.sweetSpots = newSweetSpotList;
    });

    await dispatch(modifySweetSpots({ matrixData: newMatrixData }));
  };

  const { buildSweetSpotInput, sweetSpotMatrixProps } = useSweetSpots({
    onSweetSpotsChanged(e) {
      const lastKnownSweetSpotEventState = [...lastSweetSpotUpdateReceivedFromEvent.current];
      const index = lastKnownSweetSpotEventState.findIndex(x => x.sweetSpotId === e.sweetSpotId);

      if (index === -1) { return; }

      const alteredSweetSpot = {
        ...lastKnownSweetSpotEventState[index],
      };

      // Here we would ignore all null average data because delete sweet spot should be
      // the only case where average is null, and we handle that later.
      if (isNotNullOrUndefined(e.sweetSpotChanges.averageData)) { alteredSweetSpot.averageData = e.sweetSpotChanges.averageData; }
      if (e.sweetSpotChanges.axisRange !== undefined) { alteredSweetSpot.axisRangeData = e.sweetSpotChanges.axisRange; }
      if (e.sweetSpotChanges.cellRange !== undefined) { alteredSweetSpot.cellRange = e.sweetSpotChanges.cellRange; }
      if (e.sweetSpotChanges.color !== undefined) { alteredSweetSpot.color = e.sweetSpotChanges.color; }
      if (e.sweetSpotChanges.label !== undefined) { alteredSweetSpot.label = e.sweetSpotChanges.label; }

      // If cell range is set to null that means we've deleted a sweet spot and by definition
      // axis range and average data can safely be set to null here.
      if (e.sweetSpotChanges.cellRange === null) {
        alteredSweetSpot.cellRange = null;
        alteredSweetSpot.axisRangeData = null;
        alteredSweetSpot.averageData = null;
      }

      if (isEqual(lastKnownSweetSpotEventState[index], alteredSweetSpot)) {
        return;
      }

      lastKnownSweetSpotEventState[index] = alteredSweetSpot;

      // Place the sweet spot at the beginning of the array so it gets drawn with highest priority.
      lastKnownSweetSpotEventState.unshift(lastKnownSweetSpotEventState.splice(index, 1)[0]);
      const data: Matrix = { ...matrix, sweetSpots: lastKnownSweetSpotEventState };
      lastSweetSpotUpdateReceivedFromEvent.current = lastKnownSweetSpotEventState;
      dispatch(modifySweetSpots({ matrixData: data }));
    },
    sweetSpotState: mapToMatrixSweetSpots(matrix.sweetSpots),
  });

  const StyledAccordionSummary = styled(AccordionSummary)(() => ({
    '&.MuiAccordionSummary-root': {
      paddingLeft: '0px !important',
    },
    '& .MuiAccordionSummary-content': {
      marginTop: '0',
    },
  }));

  const axisCellStyle: CSSProperties = {
    backgroundColor: theme.palette.matrix.background,
    fontFamily: 'roboto',
    fontWeight: 500,
    fontSize: '0.75rem',
    textAlign: 'center',
    border: 'none',
    padding: theme.spacing(1),
    color: theme.palette.matrix.axisCellTextColor,
  };

  const dataCellStyle: CSSProperties = {
    border: `1px solid ${theme.palette.matrix.border}`,
    backgroundColor: theme.palette.matrix.background,
    fontFamily: 'roboto',
    color: theme.palette.matrix.dataCellTextColor,
    fontSize: '0.875rem',
    textAlign: 'center',
    padding: theme.spacing(1),
    paddingTop: '6px',
    paddingBottom: '6px',
    fontWeight: 800,
    width: '10vw',
  };

  const labelCellStyle: CSSProperties = {
    backgroundColor: `${theme.palette.matrix.background}`,
    fontSize: '0.875rem',
    textAlign: 'center',
    paddingLeft: '4px',
    paddingRight: '14px',
  };

  const scenarioRequests = useGetSelectedScenarioRequestsForScenarios(includedScenarios);
  const matrixOverlays = useMatrixOverlays(includedScenarios);
  const matrixOverlayChildren = useMatrixOverlayChildren(includedScenarioPieces, selectedMatrixPreset);
  const cellDataProvider = useMatrixCellDataProvider(scenarioRequests);
  const cellBackgroundColorProvider = createCellBackgroundColorProvider(includedScenarios);
  const cellTextColorSettings = useMatrixCellTextColorSettings();

  const onSelectMatrixPreset = (newMatrixPresetId: MatrixPresetId) => {
    const matrixPreset = matrixPresets.find(mp => mp.matrixPresetId === newMatrixPresetId);
    if (isNotNullOrUndefined(matrixPreset)) {
      dispatch(setSelectedMatrixPresetIdForMatrixId({ matrixId: matrix.matrixId, matrixPresetId: matrixPreset.matrixPresetId }));
    }
  };

  // We need to invert the price scale here to mimic ST behavior of the price column. We do not want to persist
  // the inverted value however so we just invert it here and pass it to the matrix component.
  const priceScale = -1 * matrix.priceScale;

  return (
    <Grid style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Grid container>
        <Grid item xs={9}>
          <IncludedScenarios includedScenarios={includedScenarios} matrix={matrix} />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            size="small"
            select
            value={selectedMatrixPreset?.matrixPresetId ?? null}
            onChange={e => onSelectMatrixPreset(toPrimaryKey<MatrixPresetId>(e.target.value))}>{matrixPresets.map(mp => <MenuItem key={mp.matrixPresetId} value={mp.matrixPresetId}>{getDisplayNameForMatrixPreset(mp)}</MenuItem>)}</TextField>
        </Grid>
      </Grid>
      <Grid container style={{ height: '100%' }}>
        <MatrixUI
          xAxisCount={matrix.columnCount}
          topXAxisMidpoint={matrix.midYield}
          topXAxisScale={matrix.yieldScale}
          yAxisCount={matrix.rowCount}
          yAxisMidpoint={matrix.midPrice}
          yAxisScale={priceScale}
          yAxisLabel={matrixTopAxisLabel}
          bottomXAxisDefinition={getBottomXAxisDefinition(matrix.bottomAxisOffsetType, matrix.bottomAxisPercentChange, matrix.bottomAxisIntegerOffset)}
          cellDataProvider={cellDataProvider}
          mainOverlayChildValueSelector={c => mainOverlayChildValueSelector(matrix.includeFilter, matrix.showFilter, c)}
          matrixOverlays={matrixOverlays}
          matrixOverlayChildren={matrixOverlayChildren}
          cellDataTooltipDelayMs={300}
          cellBackgroundColorProvider={cellBackgroundColorProvider}
          axisCellStyle={axisCellStyle}
          cellTextColorSettings={cellTextColorSettings}
          dataCellStyle={dataCellStyle}
          yAxisLabelCellStyle={labelCellStyle}
          bottomXAxisLabelCellStyle={labelCellStyle}
          {...sweetSpotMatrixProps}
          shouldCellsUseSweetSpotBackgroundColor={false}
          heatMapDataProvider={heatMapDataProvider}
        />
      </Grid>

      <Accordion defaultExpanded={true} disableGutters={true} elevation={0}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme => theme.palette.action.selected }} />} />
        <AccordionDetails sx={{ paddingLeft: '0' }}>
          <MatrixSweetSpots
            matrix={matrix}
            buildSweetSpotInput={buildSweetSpotInput}
            handleAddSweetSpot={handleAddSweetSpot}
          />
        </AccordionDetails>
      </Accordion>

    </Grid>
  );
};

export default MatrixWrapper;