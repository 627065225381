import { isNotNilOrEmpty } from '@paravano/utils';
import { distinctBy } from './arrayUtils';
import PriceGroup from '../types/api/adm/PriceGroup';
import { IntervalPriceSymbolPrefix } from '../types/api/PrimaryKeys';

export const getDistinctSymbolsFromPriceGroups = (priceGroups: PriceGroup[]) => {
  const priceGroupSymbols = priceGroups.flatMap(pg => pg.projectedMarketSymbol.split(',')).filter(isNotNilOrEmpty);
  const priceGroupSymbolPrefixes = priceGroupSymbols.map(getPriceGroupSymbolPrefix);
  const distinctPriceGroupSymbols = distinctBy(priceGroupSymbolPrefixes, x => x);

  return distinctPriceGroupSymbols;
};

export const getPriceGroupSymbolPrefix = (symbol: string) => {
  const symbolPrefix = symbol.replace(/[^a-zA-Z]+/g, ''); // This removes all non-alphabetic characters from the symbol
  return symbolPrefix as IntervalPriceSymbolPrefix;
};