import NumberTextField from '../../../components/numberTextField/numberTextField.component';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectTYield } from '../../../app/admSlice';
import { Nullable } from '../../../types/util/Nullable';

interface TYieldDisplayProps {
  typeId: string;
  practiceId: string;
  subCountyCode: Nullable<string>;
}

const TYieldDisplay = ({ typeId, practiceId, subCountyCode }: TYieldDisplayProps) => {
  const admTYield = useAppSelector(state => selectTYield(state, typeId, practiceId, subCountyCode));
  return (<>
    {admTYield && <NumberTextField
      type="number"
      label="T-Yield"
      fullWidth
      defaultValue={admTYield.transitionalYield}
      size="small"
      disabled={true}
    />
    }
  </>
  );
};

export default TYieldDisplay;
