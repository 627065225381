import { BaseContactInfo } from '../../../types/api/reports/BaseContactInfo';
import { AgentInformation } from '../../../types/api/agentInformation';
import { AgencyInformation } from '../../../types/api/agencyInformation';
import { Nullable } from '../../../types/util/Nullable';

export const getContactInfoForAgent = (agentInformation: Nullable<AgentInformation>, agencyInformation: Nullable<AgencyInformation>): BaseContactInfo => {
  return {
    name: `${agentInformation?.firstName ?? ''} ${agentInformation?.lastName ?? ''}`.trim(),
    firstAddressLine: agencyInformation?.address?.replace(',', '').trimEnd() ?? null,
    secondAddressLine: null,
    city: agencyInformation?.city ?? null,
    state: agencyInformation?.state ?? null,
    zip: agencyInformation?.zipCode ?? null,
    phoneNumber: agentInformation?.phoneNumber ?? null,
  };
};