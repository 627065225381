import { Grid, IconButton, ListItem, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ValidColor } from '@silveus/react-matrix';
import { MatrixColorSample } from './MatrixColorSample';

interface Props {
  threshold: number;
  color: ValidColor;
  isSelected: boolean;
  isDeleteAllowed: boolean;
  cssClassNames: undefined | {
    inputCSSClassNames: string | undefined;
    iconCSSClassNames: string | undefined;
  };
  onCardSelected: () => void;
  onUpdateThreshold: (newThreshold: number) => void;
  onDeleteColor: () => void;

}

export const MatrixColorValueCard = ({ threshold, color, isSelected, isDeleteAllowed, cssClassNames, onCardSelected, onDeleteColor, onUpdateThreshold }: Props) => {
  const [thresholdDisplayValue, setThresholdDisplayValue] = useState<string>(threshold.toString());

  const onThresholdChange = (threshold: string) => {
    const valueAsNumber = threshold === '' ? null : Number(threshold);

    setThresholdDisplayValue(threshold);

    if (valueAsNumber !== null)
      onUpdateThreshold(valueAsNumber);
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item xs>
        <ListItem onClick={onCardSelected} selected={isSelected} disableGutters sx={{ borderRadius: 1, padding: 2 }}>
          <Stack alignItems="center">
            <MatrixColorSample color={color} />
            <TextField
              variant="standard"
              type="number"
              size="small"
              value={thresholdDisplayValue}
              className={cssClassNames?.inputCSSClassNames}
              onChange={e => onThresholdChange(e.target.value)}
              sx={{
                width: '50px',
                '& input[type=number]': {
                  'MozAppearance': 'textfield',
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                  'WebkitAppearance': 'none',
                  margin: 0,
                },
                '& input[type=number]::-webkit-inner-spin-button': {
                  'WebkitAppearance': 'none',
                  margin: 0,
                },
              }}
              inputProps={{
                style: {
                  textAlign: 'center',
                },
              }}
            />
          </Stack>
        </ListItem>
      </Grid>
      <Grid item xs="auto">
        <IconButton size="small" disabled={!isDeleteAllowed} onClick={onDeleteColor}>
          <CloseIcon className={cssClassNames?.iconCSSClassNames} fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>

  );
};