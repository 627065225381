import { ScenarioPieceType } from '@silveus/calculations';
import ScenarioPieceDefinition from '../../types/app/ScenarioPieceDefinition';
import { aphDefinition } from './rowCrop/federalProducts/aphDefinition';
import { arpDefinition, arpHpeDefinition } from './rowCrop/federalProducts/arpDefinition';
import { aypDefinition } from './rowCrop/federalProducts/aypDefinition';
import { ecoDefinition } from './rowCrop/federalProducts/ecoDefinition';
import { rpDefinition, rpHpeDefinition } from './rowCrop/federalProducts/rpDefinition';
import { mpDefinition } from './rowCrop/federalProducts/mpDefinition';
import { hipDefinition } from './rowCrop/federalProducts/hipDefinition';
import { scoDefinition } from './rowCrop/federalProducts/scoDefinition';
import { staxDefinition } from './rowCrop/federalProducts/staxDefinition';
import { ypDefinition } from './rowCrop/federalProducts/ypDefinition';
import { defaultDefinition, unsetDefinition } from './defaultDefinitions/defaultDefinition';
import { ecoPlusDefinition } from './rowCrop/privateProducts/ecoPlusDefinition';
import { scoPlusDefinition } from './rowCrop/privateProducts/scoPlusDefinition';
import { myScoDefinition } from './rowCrop/privateProducts/myScoDefinition';
import { myEcoDefinition } from './rowCrop/privateProducts/myEcoDefinition';
import { rampDefinition } from './rowCrop/privateProducts/rampDefinition';
import { revBoostDefinition } from './rowCrop/privateProducts/revBoostDefinition';
import { vipDefinition } from './rowCrop/privateProducts/vipDefinition';
import { vipMpDefinition } from './rowCrop/privateProducts/vipMpDefinition';
import { iceDefinition } from './rowCrop/privateProducts/iceDefinition';
import { productionHailDefinition, standardHailDefinition } from './rowCrop/privateProducts/hailDefinition';
import { forwardSoldDefinition } from './forwardSoldDefinition';
import { harvestRevenueDefinition } from './harvestRevenueDefinition';
import { inputCostDefinition } from './inputCostDefinition';
import { farmBillDefinition } from './rowCrop/federalProducts/farmBillDefinition';
import { mPowerDDefinition } from './rowCrop/privateProducts/mPowerDDefinition';
import { scenarioPieceDefinitionDefaultValues } from './defaultDefinitions/defaultValues';

const ScenarioPieceDefinitions: Readonly<Record<ScenarioPieceType, ScenarioPieceDefinition>> = {
  [ScenarioPieceType.Unset]: unsetDefinition,

  //Federal Area Plans
  [ScenarioPieceType.Arp]: arpDefinition,
  [ScenarioPieceType.ArpHpe]: arpHpeDefinition,
  [ScenarioPieceType.Ayp]: aypDefinition,

  [ScenarioPieceType.MP]: mpDefinition,
  [ScenarioPieceType.MpHpo]: mpDefinition,

  //Federal Individual Plans
  [ScenarioPieceType.Aph]: aphDefinition,
  [ScenarioPieceType.RP]: rpDefinition,
  [ScenarioPieceType.RpHpe]: rpHpeDefinition,

  [ScenarioPieceType.YP]: ypDefinition,

  [ScenarioPieceType.Arc]: farmBillDefinition,
  [ScenarioPieceType.Plc]: farmBillDefinition,

  //Federal Add-on Plans
  [ScenarioPieceType.EcoRp]: ecoDefinition,
  [ScenarioPieceType.EcoRpHpe]: ecoDefinition,
  [ScenarioPieceType.EcoYp]: ecoDefinition,

  [ScenarioPieceType.ScoRp]: scoDefinition,
  [ScenarioPieceType.ScoRpHpe]: scoDefinition,
  [ScenarioPieceType.ScoYp]: scoDefinition,

  [ScenarioPieceType.StaxRp]: staxDefinition,
  [ScenarioPieceType.StaxRpHpe]: staxDefinition,

  [ScenarioPieceType.Hip]: hipDefinition,

  //Private Products
  [ScenarioPieceType.EcoPlusRevenue]: ecoPlusDefinition,
  [ScenarioPieceType.EcoPlusYield]: ecoPlusDefinition,

  [ScenarioPieceType.ScoPlusRevenue]: scoPlusDefinition,
  [ScenarioPieceType.ScoPlusYield]: scoPlusDefinition,

  [ScenarioPieceType.MyEcoRevenue]: myEcoDefinition,
  [ScenarioPieceType.MyEcoYield]: myEcoDefinition,

  [ScenarioPieceType.MyScoRevenue]: myScoDefinition,
  [ScenarioPieceType.MyScoYield]: myScoDefinition,

  [ScenarioPieceType.RampRevenue]: rampDefinition,
  [ScenarioPieceType.RampYield]: rampDefinition,

  [ScenarioPieceType.RevBoostRp]: revBoostDefinition,
  [ScenarioPieceType.RevBoostRpHpe]: revBoostDefinition,
  [ScenarioPieceType.RevBoostRpHpo]: revBoostDefinition,
  [ScenarioPieceType.RevBoostYp]: revBoostDefinition,

  [ScenarioPieceType.Vip]: vipDefinition,
  [ScenarioPieceType.VipMp]: vipMpDefinition,
  [ScenarioPieceType.VipMpHpo]: vipMpDefinition,

  [ScenarioPieceType.MPowerD]: mPowerDDefinition,

  [ScenarioPieceType.IceStack590]: iceDefinition,
  [ScenarioPieceType.IceStack595]: iceDefinition,
  [ScenarioPieceType.IceStack1090]: iceDefinition,
  [ScenarioPieceType.IceStack1095]: iceDefinition,
  [ScenarioPieceType.IceStack1595]: iceDefinition,
  [ScenarioPieceType.IceShield1090]: iceDefinition,
  [ScenarioPieceType.IceShield1095]: iceDefinition,

  //Production Hail
  [ScenarioPieceType.ML0PROProductionHail]: productionHailDefinition,
  [ScenarioPieceType.ML5PROProductionHail]: productionHailDefinition,
  [ScenarioPieceType.ML10PROProductionHail]: productionHailDefinition,
  [ScenarioPieceType.PPMAXProductionHail]: productionHailDefinition,
  [ScenarioPieceType.CPPProductionHail]: productionHailDefinition,
  [ScenarioPieceType.CPP5ProductionHail]: productionHailDefinition,
  [ScenarioPieceType.HPPProductionHail]: productionHailDefinition,
  [ScenarioPieceType.PHIPProductionHail]: productionHailDefinition,
  [ScenarioPieceType.PProductionHail]: productionHailDefinition,
  [ScenarioPieceType.PEUProductionHail]: productionHailDefinition,
  [ScenarioPieceType.P5DEDProductionHail]: productionHailDefinition,
  [ScenarioPieceType.P10DEDProductionHail]: productionHailDefinition,
  [ScenarioPieceType.PF5ProductionHail]: productionHailDefinition,
  [ScenarioPieceType.CHPP0ProductionHail]: productionHailDefinition,
  [ScenarioPieceType.CHPP5ProductionHail]: productionHailDefinition,
  [ScenarioPieceType.CHPPD5ProductionHail]: productionHailDefinition,
  [ScenarioPieceType.CHPPD10ProductionHail]: productionHailDefinition,
  [ScenarioPieceType.CHPPD20ProductionHail]: productionHailDefinition,
  [ScenarioPieceType.CHPPF5ProductionHail]: productionHailDefinition,

  //Standard Hail
  [ScenarioPieceType.BASICStandardHail]: standardHailDefinition,
  [ScenarioPieceType.BASIC5MinStandardHail]: standardHailDefinition,
  [ScenarioPieceType.BASIC10MinStandardHail]: standardHailDefinition,
  [ScenarioPieceType.D20StandardHail]: standardHailDefinition,
  [ScenarioPieceType.DDAStandardHail]: standardHailDefinition,
  [ScenarioPieceType.DDBStandardHail]: standardHailDefinition,
  [ScenarioPieceType.DDCStandardHail]: standardHailDefinition,
  [ScenarioPieceType.DD2050StandardHail]: standardHailDefinition,
  [ScenarioPieceType.DD2070StandardHail]: standardHailDefinition,
  [ScenarioPieceType.DXS5StandardHail]: standardHailDefinition,
  [ScenarioPieceType.DXS10StandardHail]: standardHailDefinition,
  [ScenarioPieceType.DXS20StandardHail]: standardHailDefinition,
  [ScenarioPieceType.DXS25StandardHail]: standardHailDefinition,
  [ScenarioPieceType.DXS50StandardHail]: standardHailDefinition,
  [ScenarioPieceType.IP2StandardHail]: standardHailDefinition,
  [ScenarioPieceType.IP2FStandardHail]: standardHailDefinition,
  [ScenarioPieceType.IP3StandardHail]: standardHailDefinition,
  [ScenarioPieceType.IP3FStandardHail]: standardHailDefinition,
  [ScenarioPieceType.IP2D10StandardHail]: standardHailDefinition,
  [ScenarioPieceType.IP3D10StandardHail]: standardHailDefinition,
  [ScenarioPieceType.IP3D15StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP1POINT5StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP2StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP2POINT5StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP3StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP3POINT5StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP4StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP4POINT5StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP2D10StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP2D15StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP3D10StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP3D15StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP2PLUSStandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP2POINT5PLUSStandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP3PLUSStandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP3POINT5PLUSStandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP4PLUSStandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP4POINT5PLUSStandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP65StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP70StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP75StandardHail]: standardHailDefinition,
  [ScenarioPieceType.COMP80StandardHail]: standardHailDefinition,
  [ScenarioPieceType.XS5StandardHail]: standardHailDefinition,
  [ScenarioPieceType.XS10StandardHail]: standardHailDefinition,
  [ScenarioPieceType.XS15StandardHail]: standardHailDefinition,
  [ScenarioPieceType.XS20StandardHail]: standardHailDefinition,
  [ScenarioPieceType.XS25StandardHail]: standardHailDefinition,
  [ScenarioPieceType.XS5IP2StandardHail]: standardHailDefinition,
  [ScenarioPieceType.XS10IPStandardHail]: standardHailDefinition,
  [ScenarioPieceType.XS15IPStandardHail]: standardHailDefinition,
  [ScenarioPieceType.XS20IPStandardHail]: standardHailDefinition,
  [ScenarioPieceType.XS50IPStandardHail]: standardHailDefinition,
  [ScenarioPieceType.BC2StandardHail]: standardHailDefinition,
  [ScenarioPieceType.BC3StandardHail]: standardHailDefinition,

  //Non-Products
  [ScenarioPieceType.InputCosts]: inputCostDefinition,
  [ScenarioPieceType.ForwardSold]: forwardSoldDefinition,
  [ScenarioPieceType.HarvestRevenue]: harvestRevenueDefinition,

  //Not Implemented Currently
  [ScenarioPieceType.Band]: defaultDefinition,
  [ScenarioPieceType.BandRevenue]: defaultDefinition,
  [ScenarioPieceType.BandRevenueHpe]: defaultDefinition,
  [ScenarioPieceType.BandYield]: defaultDefinition,
  [ScenarioPieceType.BoostMax]: defaultDefinition,
  [ScenarioPieceType.PriceFlex]: defaultDefinition,
  [ScenarioPieceType.FuturesOptions]: defaultDefinition,
  [ScenarioPieceType.Ydo]: defaultDefinition,
  [ScenarioPieceType.Wfrp]: defaultDefinition,
  [ScenarioPieceType.Arh]: defaultDefinition,
  [ScenarioPieceType.Tdo]: defaultDefinition,
  [ScenarioPieceType.Prv]: defaultDefinition,
  [ScenarioPieceType.AqDol]: defaultDefinition,
  [ScenarioPieceType.DO]: defaultDefinition,
  [ScenarioPieceType.FD]: defaultDefinition,
  [ScenarioPieceType.HrAce]: defaultDefinition,
};

export const getScenarioPieceDefinition = (scenarioPieceType: ScenarioPieceType) => {
  // First get the requested definition, as it was defined.
  const definition = ScenarioPieceDefinitions[scenarioPieceType];

  const returnValue = {
    // Defaults go first so they may be overridden by the definition.
    ...scenarioPieceDefinitionDefaultValues,
    ...definition,
  };

  // Type inference is critical here so that filled defaults do not show as optional from the consumer's POV.
  return returnValue;
};
