import { ReportFormFields, ReportGenerationModal } from './reportGenerationModal';
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select, SelectChangeEvent
} from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { OptionalColumnInput } from '../formInputs/reports/optionalColumnInput';
import { QuoteSummaryReportData } from '../../types/api/reports/QuoteSummaryReportData';
import { selectCurrentClientFile } from '../../app/clientFilesSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../app/scenarioPiecesSlice';
import { selectAllScenariosByClientFileIdMap } from '../../app/scenariosSlice';
import { selectAllScenarioOptionsByScenarioIdMap } from '../../app/optionsSlice';
import {
  selectCalculationsByScenarioPiece, selectCalculationsForScenarios
} from '../../app/calculationResultsSlice';
import {
  selectAllCommodities, selectAllCounties,
  selectAllInsuranceCalendars, selectAllPractices, selectAllStates,
  selectPriceGroupMembers,
  selectPriceGroups
} from '../../app/admSlice';
import { selectQuotesByClientFileId } from '../../app/quotesSlice';
import { stableEmptyArrayAsMutable } from '../../utils/stableEmptyArray';
import { Quote } from '../../types/api/Quote';
import { getQuoteSummaryScenarios } from './utils/getQuoteSummaryScenarios';
import { getKeyedStateToMap } from '../../app/sliceHelpers';
import {
  selectAdjustedYieldForScenarios,
  selectApprovedYieldForScenarios,
  selectAverageSharePercentForScenarios,
  selectNetAcresForScenarios
} from '../../app/unitsSlice';
import { useInsuredContact } from './hooks/useInsuredContact';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { getItemsForId } from '../../utils/mapHelpers';
import { getContactInfoFromInsured } from './utils/getContactInfoFromInsured';
import { ReportAnalysisGenerationType } from '../../types/api/enums/report/reportAnalysisGenerationType.enum';
import {
  useGetSelectedScenarioRequestsForScenarios,
  useUniqueScenarioPieceTypesForScenarios
} from '../../hooks/scenarioHooks';
import { selectAllInputCostScenarioPiecesByScenarioMap } from '../../app/inputCostScenarioPiecesSlice';
import { selectAllForwardSoldScenarioPiecesByScenarioMap } from '../../app/forwardSoldScenarioPiecesSlice';
import { selectAllHarvestRevenueScenarioPiecesByScenarioMap } from '../../app/harvestRevenueScenarioPiecesSlice';
import { getQuoteSummaryMatrices } from './utils/getQuoteSummaryMatrices';
import { useMatrixCellTextColorSettings } from '../../hooks/matrix/useMatrixCellTextColorSettings';
import { generateQuoteSummaryReport } from '../../app/reportsSlice';
import { selectMatrixPresets, selectPremiumBreakdownOptions } from '../../app/userSettingsSlice';
import { getQuoteSummaryPremiumBreakdowns } from './utils/getQuoteSummaryPremiumBreakdowns';
import { selectCurrentAgencyInformation, selectCurrentAgentInformation } from '../../app/agentSlice';
import { selectAllAnalysisMatrices, selectMatrixPresetByMatrixIdDictionary } from '../../app/matricesSlice';
import { isNotNullOrUndefined } from '../../utils/nullHandling';

interface QuoteSummaryProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface QuoteSummaryOptionalColumnFields {
  options: boolean;
  projectedPrice: boolean;
  protectionFactorProjectedPrice: boolean;
  aphYieldTrendYield: boolean;
  triggerYield: boolean;
  liabilityPerAcre: boolean;
  liability: boolean;
  premiumPerAcre: boolean;
  producerPremium: boolean;
}

interface QuoteSummaryAdditionalOptionFields {
  includeKeyDates: boolean;
  includeDateGenerated: boolean;
}

type QuoteSummaryReportFormFields = ReportFormFields & QuoteSummaryOptionalColumnFields & QuoteSummaryAdditionalOptionFields;

export const QuoteSummaryReportGenerationModal = ({ open, setOpen }: QuoteSummaryProps) => {
  const dispatch = useAppDispatch();
  const methods = useForm<QuoteSummaryReportFormFields>();
  const clientFile = useAppSelector(selectCurrentClientFile);
  const insuredContact = useInsuredContact();
  const agent = useAppSelector(selectCurrentAgentInformation);
  const agencyInfo = useAppSelector(selectCurrentAgencyInformation);

  const scenariosByClientFile = useAppSelector(selectAllScenariosByClientFileIdMap);
  const scenariosForClientFile = clientFile === null ? stableEmptyArrayAsMutable<RowCropScenario>() : getItemsForId(scenariosByClientFile, clientFile.clientFileId);

  const premiumBreakdownOptions = useAppSelector(selectPremiumBreakdownOptions);
  const states = useAppSelector(selectAllStates);
  const counties = useAppSelector(selectAllCounties);
  const practices = useAppSelector(selectAllPractices);
  const commodities = useAppSelector(selectAllCommodities);

  const filteredScenarios = scenariosForClientFile.filter(s => s.isVisible);

  const [includedScenarios, setIncludedScenarios] = useState<ScenarioId[]>(filteredScenarios.map(s => s.scenarioId));
  const [analysisGenerationByScenarioId, setAnalysisGenerationByScenarioId] =
    useState<Map<ScenarioId, ReportAnalysisGenerationType[]>>(new Map<ScenarioId, ReportAnalysisGenerationType[]>());

  const selectedQuoteSummaryScenarios = filteredScenarios.filter(s => includedScenarios.includes(s.scenarioId));
  const scenarioOptions = useAppSelector(selectAllScenarioOptionsByScenarioIdMap);

  const calcsByScenarioPiece = useAppSelector(selectCalculationsByScenarioPiece);
  const calcsByScenario = useAppSelector(state => selectCalculationsForScenarios(state, includedScenarios));
  const uniqueScenarioPieceTypesForScenarios = useUniqueScenarioPieceTypesForScenarios(includedScenarios);
  const scenarioRequests = useGetSelectedScenarioRequestsForScenarios(filteredScenarios);

  const rowCropScenarioPiecesByScenario = useAppSelector(selectAllRowCropScenarioPiecesByScenarioMap);
  const inputCostScenarioPiecesByScenario = useAppSelector(selectAllInputCostScenarioPiecesByScenarioMap);
  const forwardSoldScenarioPiecesByScenario = useAppSelector(selectAllForwardSoldScenarioPiecesByScenarioMap);
  const harvestRevenueScenarioPiecesByScenario = useAppSelector(selectAllHarvestRevenueScenarioPiecesByScenarioMap);

  const filteredRowCropPiecesByScenarioByScenarioId = new Map(
    Array.from(rowCropScenarioPiecesByScenario.entries())
      .map(([scenarioId, pieces]) => [
        scenarioId,
        pieces.filter(piece => !piece.isInvalid),
      ]),
  );

  const cellTextColorSettings = useMatrixCellTextColorSettings();

  const insuranceCalendars = useAppSelector(selectAllInsuranceCalendars);
  const quotes = useAppSelector(state => clientFile === null ? stableEmptyArrayAsMutable<Quote>() : selectQuotesByClientFileId(state, clientFile.clientFileId));
  const priceGroupMembers = useAppSelector(selectPriceGroupMembers);
  const priceGroups = useAppSelector(selectPriceGroups);
  const sharePercentByScenario = useAppSelector(state => selectAverageSharePercentForScenarios(state, includedScenarios));
  const acresByScenario = useAppSelector(state => selectNetAcresForScenarios(state, includedScenarios));

  const approvedYieldByScenario = useAppSelector(state => selectApprovedYieldForScenarios(state, includedScenarios));
  const adjustedYieldByScenario = useAppSelector(state => selectAdjustedYieldForScenarios(state, includedScenarios));

  const matrices = useAppSelector(state => selectAllAnalysisMatrices(state));
  const selectedMatrixPresetsByMatrixId = useAppSelector(selectMatrixPresetByMatrixIdDictionary);
  const matrixPresets = useAppSelector(state => selectMatrixPresets(state));

  if (clientFile === null || insuredContact === null) {
    setOpen(false);
    return null;
  }

  const additionalOptions = [
    <OptionalColumnInput key={1} name="includeDateGenerated" label="Include Date Generated" />,
    <OptionalColumnInput key={2} name="includeKeyDates" label="Include Key Dates" />,
  ];

  const handleAnalysisDropdownChange = (event: SelectChangeEvent<ReportAnalysisGenerationType[]>, scenarioId: ScenarioId) => {
    const selectedValues = event.target.value as ReportAnalysisGenerationType[];
    setAnalysisGenerationByScenarioId(prevMap => {
      const updatedMap = new Map(prevMap);
      updatedMap.set(scenarioId, selectedValues);
      return updatedMap;
    });
  };

  const getAnalysisDropdownForScenario = (scenarioId: ScenarioId) => {
    const analysisGenerationSelections = analysisGenerationByScenarioId.get(scenarioId) ?? [];
    const generationTypesToDisplay = Object.values(ReportAnalysisGenerationType)
      .filter(generationType => {
        if (generationType === ReportAnalysisGenerationType.UseExistingMatrix) {
          const existingMatrix = matrices.get(scenarioId);
          return isNotNullOrUndefined(existingMatrix) && existingMatrix.isActive;
        }
        return true;
      });

    return (
      <FormControl key={scenarioId} sx={{ width: 200 }} size="small">
        <InputLabel id="report-analysis-multiple-checkbox-label" shrink>Generate Options</InputLabel>
        <Select
          labelId="report-analysis-multiple-checkbox-label"
          id="report-analysis-multiple-checkbox"
          multiple
          displayEmpty={true}
          value={analysisGenerationSelections}
          input={<OutlinedInput notched label="Generate Options" />}
          renderValue={selected => selected.length === 0 ? 'None Selected' : `${selected.length} Selected...`}
          onChange={event => handleAnalysisDropdownChange(event, scenarioId)}
        >
          {generationTypesToDisplay.map(generationType => {
            return (
              <MenuItem key={generationType} value={generationType}>
                <Checkbox checked={analysisGenerationSelections.includes(generationType)} />
                <ListItemText primary={generationType} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const optionalColumns = (
    <Grid container>
      <Grid item xs={12}>
        Select optional columns to include:
      </Grid>
      <Grid item xs={6}>
        <OptionalColumnInput name="options" label="Options" />
      </Grid>
      <Grid item xs={6}>
        <OptionalColumnInput name="liabilityPerAcre" label="Liability Per Acre" />
      </Grid>
      <Grid item xs={6}>
        <OptionalColumnInput name="projectedPrice" label="Projected Price" />
      </Grid>
      <Grid item xs={6}>
        <OptionalColumnInput name="liability" label="Liability" />
      </Grid>
      <Grid item xs={6}>
        <OptionalColumnInput name="protectionFactorProjectedPrice" label="Protection Factor/Projected Price" />
      </Grid>
      <Grid item xs={6}>
        <OptionalColumnInput name="premiumPerAcre" label="Premium Per Acre" />
      </Grid>
      <Grid item xs={6}>
        <OptionalColumnInput name="aphYieldTrendYield" label="APH Yield/Trend Yield" />
      </Grid>
      <Grid item xs={6}>
        <OptionalColumnInput name="producerPremium" label="Producer Premium" />
      </Grid>
      <Grid item xs={6}>
        <OptionalColumnInput name="triggerYield" label="Trigger Yield" />
      </Grid>
    </Grid>
  );

  const formId = 'quoteSummaryReportForm';

  const onSubmit: SubmitHandler<QuoteSummaryReportFormFields> = async data => {
    const insuranceCalendarsByQuoteInfo = getKeyedStateToMap(insuranceCalendars);
    const { quoteSummaryScenarios, quoteSummaryScenarioPiecesByScenario } = getQuoteSummaryScenarios(
      selectedQuoteSummaryScenarios,
      quotes,
      scenarioOptions,
      insuranceCalendarsByQuoteInfo,
      getKeyedStateToMap(priceGroupMembers),
      calcsByScenarioPiece,
      filteredRowCropPiecesByScenarioByScenarioId,
      priceGroups,
      acresByScenario,
      sharePercentByScenario,
      approvedYieldByScenario,
      adjustedYieldByScenario,
    );

    const matrixReportData = await getQuoteSummaryMatrices(
      analysisGenerationByScenarioId,
      filteredRowCropPiecesByScenarioByScenarioId,
      inputCostScenarioPiecesByScenario,
      forwardSoldScenarioPiecesByScenario,
      harvestRevenueScenarioPiecesByScenario,
      scenarioOptions,
      matrices,
      getKeyedStateToMap(selectedMatrixPresetsByMatrixId),
      matrixPresets,
      filteredScenarios,
      quotes,
      clientFile,
      scenarioRequests,
      calcsByScenarioPiece,
      uniqueScenarioPieceTypesForScenarios,
      approvedYieldByScenario,
      adjustedYieldByScenario,
      cellTextColorSettings,
      data.generatedDocumentType,
      data.includeDateGenerated,
      agent,
      agencyInfo,
    );

    const premiumBreakdownReportData = await getQuoteSummaryPremiumBreakdowns(
      premiumBreakdownOptions,
      analysisGenerationByScenarioId,
      filteredRowCropPiecesByScenarioByScenarioId,
      filteredScenarios,
      quotes,
      states,
      counties,
      commodities,
      practices,
      clientFile,
      insuranceCalendarsByQuoteInfo,
      scenarioRequests,
      data.generatedDocumentType,
      data.includeDateGenerated,
    );

    //Both discoveryPriceDate and MarketPriceDate are left null because in ST they represent when the corresponding set of date was last updated on the user's machine
    // Since HG only allows reports to be generated online, and we show a report generated date, Mitch determined that these are no longer needed here
    const quoteSummaryReportData: QuoteSummaryReportData = {
      cropYear: clientFile.year,
      discoveryPriceDate: null,
      hasGenerationDate: data.includeDateGenerated,
      hasPageNumbers: true,
      includeEstimateFooter: true,
      includeKeyDates: data.includeKeyDates,
      includePriceDates: false,
      insuredContactInformation: getContactInfoFromInsured(insuredContact.contact),
      agentContactInformation: null,
      isAPHYieldTrendYieldSelected: data.aphYieldTrendYield,
      isLiabilityPerAcreSelected: data.liabilityPerAcre,
      isLiabilitySelected: data.liability,
      isOptionSelected: data.options,
      isPremiumPerAcreSelected: data.premiumPerAcre,
      isProducerPremiumSelected: data.producerPremium,
      isProjectedPriceSelected: data.projectedPrice,
      isProtFacProjPriceSelected: data.protectionFactorProjectedPrice,
      isTriggerSelected: data.triggerYield,
      marketPriceDate: null,
      reportDocumentType: data.generatedDocumentType,
      scenarioPiecesByScenarioId: Object.fromEntries(quoteSummaryScenarioPiecesByScenario),
      scenarios: quoteSummaryScenarios,
      matrices: matrixReportData,
      premiumBreakdownReports: premiumBreakdownReportData,
    };

    await dispatch(generateQuoteSummaryReport({ quoteSummaryReportData }));

    setOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
        <ReportGenerationModal
          title="Quote Summary"
          handleClose={() => setOpen(false)}
          open={open}
          additionalOptions={additionalOptions}
          formId={formId}
          getScenarioSelectionContent={getAnalysisDropdownForScenario}
          contentAfterAdditionalOptions={optionalColumns}
          includedScenarios={includedScenarios}
          setIncludedScenarios={setIncludedScenarios}
        />
      </form>
    </FormProvider>
  );
};