import axios from 'axios';
import RampCalculationParams from '../types/api/calculationData/rampCalculationParams';
import ScoCalculationParams from '../types/api/calculationData/scoCalculationParams';
import EcoCalculationParams from '../types/api/calculationData/ecoCalculationParams';
import StaxCalculationParams from '../types/api/calculationData/staxCalculationParams';
import RpCalculationParams from '../types/api/calculationData/rpCalculationParams';
import { ScoEcoPlusCalculationParams } from '../types/api/calculationData/scoEcoPlusCalculationParams';
import AphCalculationParams from '../types/api/calculationData/aphCalculationParams';
import { IceCalculationParams } from '../types/api/calculationData/iceCalculationParams';
import ArpCalculationParams from '../types/api/calculationData/arpCalculationParams';
import ArpHpeCalculationParams from '../types/api/calculationData/arpHpeCalculationParams';
import AypCalculationParams from '../types/api/calculationData/aypCalculationParams';
import { AphCalculationParamsRequest } from '../types/api/calculationData/aphCalculationParamsRequest';
import { ArpCalculationParamsRequest } from '../types/api/calculationData/arpCalculationParamsRequest';
import { ArpHpeCalculationParamsRequest } from '../types/api/calculationData/arpHpeCalculationParamsRequest';
import { AypCalculationParamsRequest } from '../types/api/calculationData/aypCalculationParamsRequest';
import { EcoCalculationParamsRequest } from '../types/api/calculationData/ecoCalculationParamsRequest';
import { IceCalculationParamsRequest } from '../types/api/calculationData/iceCalculationParamsRequest';
import { RampCalculationParamsRequest } from '../types/api/calculationData/rampCalculationParamsRequest';
import { RpCalculationParamsRequest } from '../types/api/calculationData/rpCalculationParamsRequest';
import { ScoCalculationParamsRequest } from '../types/api/calculationData/scoCalculationParamsRequest';
import { ScoEcoPlusCalculationParamsRequest } from '../types/api/calculationData/scoEcoPlusCalculationParamsRequest';
import { StaxCalculationParamsRequest } from '../types/api/calculationData/staxCalculationParamsRequest';
import HipCalculationParamsRequest from '../types/api/calculationData/hipCalculationParamsRequest';
import HipCalculationParams from '../types/api/calculationData/hipCalculationParams';
import { envBaseApiUrl } from '../constants/envConstants';
import { MpCalculationParamsRequest } from '../types/api/calculationData/mpCalculationParamsRequest';
import MpCalculationParams from '../types/api/calculationData/mpCalculationParams';


export const getRpCalcData = (calcParamRequest: RpCalculationParamsRequest) => axios.post<RpCalculationParams[]>(`${envBaseApiUrl}/rp`, calcParamRequest);
export const getAphCalcData = (calcParamRequest: AphCalculationParamsRequest) => axios.post<AphCalculationParams[]>(`${envBaseApiUrl}/aph`, calcParamRequest);
export const getArpCalcData = (calcParamRequest: ArpCalculationParamsRequest) => axios.post<ArpCalculationParams[]>(`${envBaseApiUrl}/arp`, calcParamRequest);
export const getArpHpeCalcData = (calcParamRequest: ArpHpeCalculationParamsRequest) => axios.post<ArpHpeCalculationParams[]>(`${envBaseApiUrl}/arphpe`, calcParamRequest);
export const getAypCalcData = (calcParamRequest: AypCalculationParamsRequest) => axios.post<AypCalculationParams[]>(`${envBaseApiUrl}/ayp`, calcParamRequest);
export const getScoCalcData = (calcParamRequest: ScoCalculationParamsRequest) => axios.post<ScoCalculationParams[]>(`${envBaseApiUrl}/sco`, calcParamRequest);
export const getEcoCalcData = (calcParamRequest: EcoCalculationParamsRequest) => axios.post<EcoCalculationParams[]>(`${envBaseApiUrl}/eco`, calcParamRequest);
export const getStaxCalcData = (calcParamRequest: StaxCalculationParamsRequest) => axios.post<StaxCalculationParams[]>(`${envBaseApiUrl}/stax`, calcParamRequest);
export const getRampCalcData = (calcParamRequest: RampCalculationParamsRequest) => axios.post<RampCalculationParams[]>(`${envBaseApiUrl}/ramp`, calcParamRequest);
export const getScoEcoPlusCalcData = (calcParamRequest: ScoEcoPlusCalculationParamsRequest) => axios.post<ScoEcoPlusCalculationParams[]>(`${envBaseApiUrl}/scoEcoPlus`, calcParamRequest);
export const getIceCalcData = (calcParamRequest: IceCalculationParamsRequest) => axios.post<IceCalculationParams[]>(`${envBaseApiUrl}/ice`, calcParamRequest);
export const getHipCalcData = (calcParamRequest: HipCalculationParamsRequest) => axios.post<HipCalculationParams[]>(`${envBaseApiUrl}/hip`, calcParamRequest);
export const getMpCalcData = (calcParamRequest: MpCalculationParamsRequest) => axios.post<MpCalculationParams[]>(`${envBaseApiUrl}/mp`, calcParamRequest);
