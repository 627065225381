import { Grid, Tab, Tabs } from '@mui/material';
import { TabPanel } from '../../utils/tabPanel';
import MatrixAnalysis from './matrixAnalysis/matrixAnalysis.component';
import HistoricalAnalysis from './historicalAnalysis/historicalAnalysis.component';
import TrendLineAnalysis from './trendlineAnalysis/trendlinesAnalysis.component';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../hooks/reduxHooks';
import { selectScenarioAnalysisTabIndex, setSelectedScenarioAnalysisTabIndex } from '../../app/scenarioAnalysisSlice';
import { selectAllScenariosByClientFileIdMapWithUserOrder } from '../../app/scenariosSlice';
import { ScenarioAnalysisTabIndex, ScenarioAnalysisTabs } from '../../constants/analysisTabs';
import PremiumBreakdown from './premiumBreakdown/premiumBreakdown.component';
import { ScenarioCardColumn } from './shared/ScenarioCardColumn';
import { selectCurrentClientFileId } from '../../app/clientFilesSlice';

const ScenarioAnalysis = () => {
  const dispatch = useAppDispatch();
  const tabIndex = useAppSelector(selectScenarioAnalysisTabIndex);
  const clientFileId = useAppSelector(selectCurrentClientFileId);
  const scenarios = useKeyMapSelector(selectAllScenariosByClientFileIdMapWithUserOrder, clientFileId);

  const handleTabChange = (newValue: ScenarioAnalysisTabIndex) => {
    dispatch(setSelectedScenarioAnalysisTabIndex(newValue));
  };

  return (
    <Grid container item xs sx={{ height: '100%', flexWrap: 'nowrap', overflow: 'hidden' }} p={1}>
      <Grid container item xs sx={{ height: '100%', flexWrap: 'nowrap', overflow: 'auto' }} direction="column">
        <Grid item xs="auto">
          <Tabs value={tabIndex} onChange={(_, newValue) => handleTabChange(newValue)}>
            <Tab label={ScenarioAnalysisTabs.matrix.label} />
            <Tab label={ScenarioAnalysisTabs.historical.label} />
            <Tab label={ScenarioAnalysisTabs.trendlines.label} />
            <Tab label={ScenarioAnalysisTabs.premium.label} />
          </Tabs>
        </Grid>
        <Grid item xs sx={{ overflow: 'auto' }}>
          <TabPanel value={tabIndex} index={ScenarioAnalysisTabs.matrix.index}>
            <MatrixAnalysis allScenariosForView={scenarios} />
          </TabPanel>
          <TabPanel value={tabIndex} index={ScenarioAnalysisTabs.historical.index}>
            <HistoricalAnalysis allScenariosForView={scenarios} />
          </TabPanel>
          <TabPanel value={tabIndex} index={ScenarioAnalysisTabs.trendlines.index}>
            <TrendLineAnalysis allScenariosForView={scenarios} />
          </TabPanel>
          <TabPanel value={tabIndex} index={ScenarioAnalysisTabs.premium.index}>
            <PremiumBreakdown allScenariosForView={scenarios} />
          </TabPanel>
        </Grid>
      </Grid>
      <Grid container item xs="auto" sx={{ height: '100%', ml: 1 }}>
        <ScenarioCardColumn />
      </Grid>
    </Grid>
  );
};

export default ScenarioAnalysis;
