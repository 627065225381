import { admDb } from '../../db';
import { Commodity } from '../../types/api/adm/Commodity';
import { getCommodities, getCommoditiesForCounty, getCommodity } from '../commodities.service';
import { getAll, getDefaultCachingStrategy, getSingle } from '../offlineDataAccess.service';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';

const table = admDb.commodities;

export const getCommoditiesRequest = async (year: number): Promise<Commodity[]> => {
  const request = () => getCommodities(year);
  return await getAll(table, request, admDb);
};

export const getCommodityRequest = async (year: number, commodityCode: string): Promise<Commodity> => {
  const request = () => getCommodity(year, commodityCode);
  return await getSingle(table, { commodityCode: commodityCode }, request, admDb);
};

export const getCommoditiesForCountyRequest = async (year: number, countyId: string): Promise<Commodity[]> => {
  const request = () => getCommoditiesForCounty(year, countyId);

  const transactionTables = [admDb.insuranceOffers, admDb.commodities, admDb.commodityUses];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const insuranceOffers = await safeWhere(admDb.insuranceOffers, { countyId: countyId }).toArray();
    const commodityUseIds = insuranceOffers.map(cu => cu.commodityUseId);
    const commodityUses = await safeWhere(admDb.commodityUses, 'commodityUseId').anyOf(commodityUseIds).toArray();
    const commodityCodes = commodityUses.map(io => io.commodityCode);
    const commodities = safeWhere(admDb.commodities, 'commodityCode').anyOf(commodityCodes).toArray();
    return commodities;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};
