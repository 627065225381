import { useReducer } from 'react';
import { createUiColorRuleFromRule } from '../utils/createUiColorRuleFromRule';
import { buildComponentDefaultColorList } from '../utils/defaultColorList';
import { CellTextColorRule } from '@silveus/react-matrix';
import { UiCellTextColorRule } from '../types';


export const useOrderedColorList = (defaultColorList: CellTextColorRule[] | undefined) => {
  const initialColorList = createInitialColorList(defaultColorList);
  const useReducerResult = useReducer(orderColorList, initialColorList);
  return useReducerResult;
};

const createInitialColorList = (defaultColorList: CellTextColorRule[] | undefined) => {
  const initialColorList = (defaultColorList !== undefined && defaultColorList.length > 0) ? defaultColorList.map(createUiColorRuleFromRule) : buildComponentDefaultColorList();
  const sortedColorList = sortColorListInline(initialColorList);
  return sortedColorList;
};

const sortColorListInline = (colorList: UiCellTextColorRule[]) => {
  return colorList.sort((a, b) => b.threshold - a.threshold);
};

const orderColorList = (_: readonly UiCellTextColorRule[], unOrderedColorList: readonly UiCellTextColorRule[]): readonly UiCellTextColorRule[] => {
  // For every change to the color list, re-order the list so that higher thresholds appear on top.
  const orderedRuleList = sortColorListInline([...unOrderedColorList]);
  return orderedRuleList;
};