import { QuoteId } from '../types/api/PrimaryKeys';
import { toPrimaryKey } from '../utils/primaryKeyHelpers';

// Warning: The id(s) in this file correlate to static ids also defined on the back end.
// They can not be changed without also accounting for any back end implications.
// They are not arbitrary.


/** The hardcoded quote id that is used specifically to contain all scenario templates.
 * This should only ever be used with templates, and should never be changed.
 */
export const StaticTemplateScenarioQuoteId = toPrimaryKey<QuoteId>('be7e98e3-fb80-47a3-8f4e-b830fdc913ab');