import { AsyncThunkAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientFileId, QuoteId, ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import { Nullable } from '../types/util/Nullable';
import { RootState } from './store';
import { ScenarioFormFields } from '../pages/scenario/components/scenarioForm.component';
import { RowCropScenario } from '../types/api/RowCropScenario';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { Quote } from '../types/api/Quote';
import ScenarioQuickUnit from '../types/api/ScenarioQuickUnit';
import {
  duplicateRowCropScenarioPieces,
  selectAllRowCropScenarioPiecesByScenarioMap
} from './scenarioPiecesSlice';
import { isNullOrUndefined } from '../utils/nullHandling';
import { getKeyedStateGroupedBy, getStateIdsMatching, KeyedState } from './sliceHelpers';
import { modifyScenarioOrderForUser, selectUserLinkedScenarios, setScenarioOrder } from './userSettingsSlice';
import { getScenarioColor } from '../pages/clientFile/utils/getScenarioColor';
import { selectUnitYearsThatCanApplyToScenario } from './unitsSlice';
import { HighRiskType, ScenarioPieceType } from '@silveus/calculations';
import { optionalBaseScenarioPieces, scenarioPieceOrderingServiceInstance } from '../utils/scenarioOrderingServiceWrappers';
import { duplicateUnitYearOptionsForScenario } from './optionsSlice';
import { getItemsForId, orderMap } from '../utils/mapHelpers';
import { updateUnitGroupsForScenarioPiece, updateUnitGroupsForScenarioPieceWithSpecificUnits } from './unitGroupsSlice';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import { modifyMatrix, selectAllComparisonMatrices } from './matricesSlice';
import { selectAllQuotesByClientFileMap, selectQuoteById, selectQuotesByClientFileId, selectQuotesByIds } from './quotesSlice';
import UnitYear from '../types/api/UnitYear';
import { removeTrendline, selectAllTrendlines } from './trendlineAnalysisSlice';
import {
  createRowCropScenarioRequest,
  deleteRowCropScenarioRequest, getRowCropScenariosByClientFileRequest, getRowCropScenariosRequest, updateRowCropScenarioRequest, updateRowCropScenariosRequest
} from '../services/requestInterception/scenarioRequestInterceptor';
import {
  createScenarioQuickUnitRequest,
  updateScenarioQuickUnitRequest
} from '../services/requestInterception/scenarioQuickUnitRequestInterceptor';
import {
  duplicateInputCostScenarioPieces,
  selectAllInputCostScenarioPiecesByScenarioMap
} from './inputCostScenarioPiecesSlice';
import {
  duplicateForwardSoldScenarioPieces,
  selectAllForwardSoldScenarioPiecesByScenarioMap
} from './forwardSoldScenarioPiecesSlice';
import {
  duplicateHarvestRevenueScenarioPieces,
  selectAllHarvestRevenueScenarioPiecesByScenarioMap
} from './harvestRevenueScenarioPiecesSlice';
import {
  duplicateHailScenarioPieceComposition,
  selectAllHailScenarioPieceCompositionsByScenarioMap,
  selectAllHailScenarioPiecesByScenarioMap
} from './hailSlice';
import { selectAllScenarioPiecesByScenarioMap } from './sharedSelectors';
import { ScenarioPiece } from '../types/api/ScenarioPiece';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import { selectSelectedHistoricalScenarioId, setSelectedHistoricalScenarioId } from './scenarioAnalysisSlice';
import { selectAllApplicationWizardByInsuredMap, selectCurrentlySelectedApplicationWizardScenarios } from './applicationsSlice';
import { ApplicationWizardScenarios } from '../types/api/applicationWizard/applicationWizard';
import { selectCurrentInsured } from './insuredsSlice';
import { selectClientFileById } from './clientFilesSlice';
import AdmDataForQuoteParams from '../types/api/adm/AdmDataForQuoteParams';
import { MissingQuoteInStateError, MissingScenarioInStateError } from '../errors/state/MissingStateErrors';
import { validateAndUpdateScenario } from './validationsSlice';
import { produce } from 'immer';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import { getLinkedDataToUpdate } from './scenarioSliceHelpers';
import { closeDrawer } from './appDrawerSlice';
import {
  getOrderedDistinctUnitYearsThatCanApplyToScenario
} from '../utils/unitUtils';
import { addScenarioUnitYearAph, selectUnitYearAphByUnitYearId } from './unitYearAphSlice';
import { ScenarioUnitYearAph } from '../types/api/ScenarioUnitYearAph';
import { YeStatusType } from '../types/api/enums/optionStates/yeStatusType.enum';

export interface ScenariosState {
  allScenarios: SliceDataState<ScenarioId, RowCropScenario>;
  currentlySelectedScenarioId: Nullable<ScenarioId>;
}
const initialState: ScenariosState = {
  allScenarios: initialSliceDataState(),
  currentlySelectedScenarioId: null,
};

export const scenariosSlice = createSlice({
  name: 'scenarios',
  initialState: initialState,
  reducers: {
    setCurrentlySelectedScenarioId(state, action: PayloadAction<Nullable<ScenarioId>>) {
      state.currentlySelectedScenarioId = action.payload;
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allScenarios, s => s.scenarioId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addScenario,
      affectedIds: arg => arg.newScenario.scenarioId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchScenarios,
      affectedIds: (arg, state) => getStateIdsMatching(state.allScenarios.data, s => s.quoteId === arg.quoteId, s => s.scenarioId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchScenariosByClientFile,
      affectedIds: () => [],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'duplicating', thunk: internalDuplicateScenario,
      affectedIds: arg => [arg.existingScenario.scenarioId, arg.newScenarioId],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeScenario,
      affectedIds: arg => arg.scenario.scenarioId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyScenarios,
      affectedIds: arg => arg.scenarios.map(s => s.scenarioId),
    });

    builder
      .addCase(addScenarioQuickUnit.fulfilled, (state, action: PayloadAction<ScenarioQuickUnit>) => {
        const scenarioForQuickUnit = state.allScenarios.data[action.payload.scenarioId];

        if (scenarioForQuickUnit !== undefined) {
          scenarioForQuickUnit.quickUnit = action.payload;
        }
      })
      .addCase(modifyScenarioQuickUnit.fulfilled, (state, action: PayloadAction<ScenarioQuickUnit>) => {
        const scenarioForQuickUnit = state.allScenarios.data[action.payload.scenarioId];

        if (scenarioForQuickUnit !== undefined) {
          scenarioForQuickUnit.quickUnit = action.payload;
        }
      });
  },
});

export const { setCurrentlySelectedScenarioId } = scenariosSlice.actions;

//#region Non-Memoized Selectors
export const selectScenarioById = (state: RootState, scenarioId: ScenarioId) => state.scenarios.allScenarios.data[scenarioId] ?? null;
export const currentlySelectedScenarioId = (state: RootState) => state.scenarios.currentlySelectedScenarioId;
export const currentlySelectedScenario = (state: RootState) => state.scenarios.currentlySelectedScenarioId === null ? null : state.scenarios.allScenarios.data[state.scenarios.currentlySelectedScenarioId] ?? null;
export const selectAllScenarios = (state: RootState) => state.scenarios.allScenarios.data;
const selectScenarioDictionary = (state: RootState) => state.scenarios.allScenarios.data;

export const selectScenariosBelongingToInsuredApplications = createSelector([selectAllApplicationWizardByInsuredMap, selectCurrentInsured], (appWizardByInsuredMap, currentInsured) => {
  // pull in the map of all of the application wizards from the store for any insureds we have loaded
  // const allApplicationWizardsForInsureds = selectAllApplicationWizardByInsuredMap(state);
  // select the application wizards that exist for the currently selected insured
  if (!currentInsured) {
    return [];
  }
  const allApplicationWizardsForInsured = appWizardByInsuredMap.get(currentInsured.id) ?? [];
  // pull out all scenarios that belong to application wizards for the currently selected insured
  const allApplicationWizardScenarios = allApplicationWizardsForInsured.flatMap(x => x.applicationWizardScenarios);
  return allApplicationWizardScenarios;
});

export const selectEligibleScenariosForApplication = createSelector([selectAllScenarios, selectCurrentlySelectedApplicationWizardScenarios, selectScenariosBelongingToInsuredApplications], (allScenarios, currentApplicationWizardScenarios, insuredApplicationScenarios) => {
  // create a new map of eligible scenarios for the application wizard
  // by including any application that either
  // a. is already a part of the currently selected application wizard
  // b. is not a part of any other application wizards
  const eligibleScenarios = {} as KeyedState<ScenarioId, RowCropScenario>;
  Object.keys(allScenarios).forEach((key, index) => {
    const scenarioId = key as ScenarioId;
    if (!isNullOrUndefined(allScenarios[scenarioId]) &&
      !isNullOrUndefined(currentApplicationWizardScenarios) &&
      (
        currentApplicationWizardScenarios.some((scenario: ApplicationWizardScenarios) => scenario.scenarioId === scenarioId) ||
        !insuredApplicationScenarios.some(insuredAppScenario => insuredAppScenario.scenarioId === scenarioId)
      )
    ) {
      eligibleScenarios[scenarioId] = allScenarios[scenarioId];
    }
  });

  return eligibleScenarios;
});

export const selectUnitYearsForScenario = (state: RootState, scenarioId: ScenarioId): UnitYear[] => {
  const scenario = state.scenarios.allScenarios.data[scenarioId] ?? null;
  if (scenario === null) {
    return stableEmptyArrayAsMutable();
  }

  return selectUnitYears(state, scenario.quoteId, scenario.typeId, scenario.practiceId, scenario.highRiskTypeId);
};

export const selectUnitYearsForScenarios = (state: RootState, scenarioIds: ScenarioId[]): Map<ScenarioId, UnitYear[]> => {
  return new Map<ScenarioId, UnitYear[]>(scenarioIds.map(scenarioId => [scenarioId, selectUnitYearsForScenario(state, scenarioId)]));
};

export const selectUnitYears = (state: RootState, quoteId: QuoteId, typeId: Nullable<string>, practiceId: Nullable<string>, highRiskTypeId: HighRiskType) => {
  // Here we are determining what units are tied to the scenario dynamically.
  const unorderedUnitYears = selectUnitYearsThatCanApplyToScenario(state, quoteId, typeId, practiceId, highRiskTypeId);
  return getOrderedDistinctUnitYearsThatCanApplyToScenario(unorderedUnitYears);
};

//#endregion

//#region Memoized Selectors

export const selectScenariosByIds = createSelector([selectScenarioDictionary, (_state: RootState, scenarioIds: ScenarioId[]) => scenarioIds], (allScenarios, scenarioIds) => {
  const scenarios: RowCropScenario[] = [];

  for (const scenarioId of scenarioIds) {
    const scenario = allScenarios[scenarioId];
    if (scenario === undefined) { continue; }
    scenarios.push(scenario);
  }

  return scenarios;
});

/** Retrieves scenarios by quote, but with default ordering. */
export const selectAllScenariosByQuoteIdMap = createSelector([selectScenarioDictionary], result => {
  const map = getKeyedStateGroupedBy(result, s => s.quoteId);
  const ordered = orderMap(map, DefaultOrders.rowCropScenarios);
  return ordered;
});

export const selectScenariosByQuoteIds = createSelector([selectAllScenariosByQuoteIdMap, (_state: RootState, quoteIds: QuoteId[]) => quoteIds], (scenariosByQuoteId, quoteIds) => {
  const scenarios = quoteIds.flatMap(quoteId => getItemsForId(scenariosByQuoteId, quoteId));
  if (scenarios.length === 0) { return stableEmptyArrayAsMutable<RowCropScenario>(); }

  return scenarios;
});

export const selectAllScenariosByClientFileIdMap = createSelector(
  [
    (state: RootState) => selectAllScenariosByQuoteIdMap(state),
    (state: RootState) => selectAllQuotesByClientFileMap(state),
  ],
  (scenariosByQuote, quotesByClientFile) => {
    const result = new Map<ClientFileId, RowCropScenario[]>();

    for (const [clientFileId, quotes] of quotesByClientFile.entries()) {
      result.set(clientFileId, quotes.flatMap(x => getItemsForId(scenariosByQuote, x.quoteId)).sort((a, b) => a.sortIndex - b.sortIndex));
    }

    return result;
  });

export const selectAllScenariosByClientFileIdMapWithUserOrder = createSelector(
  [
    (state: RootState) => selectAllScenariosByQuoteIdMap(state),
    (state: RootState) => selectAllQuotesByClientFileMap(state),
  ],
  (scenariosByQuote, quotesByClientFile) => {
    const result = new Map<ClientFileId, RowCropScenario[]>();

    for (const [clientFileId, quotes] of quotesByClientFile.entries()) {
      result.set(clientFileId, quotes.flatMap(x => getItemsForId(scenariosByQuote, x.quoteId)));
    }

    return result;
  });

export const getNextSortIndex = (state: RootState, clientFileId: Nullable<ClientFileId>): number => {
  if (!clientFileId) {
    return -1;
  }

  const clientFileScenarios = getItemsForId(selectAllScenariosByClientFileIdMap(state), clientFileId);
  if (clientFileScenarios.length === 0) {
    return 0;
  }

  const maxSortIndex = clientFileScenarios.reduce((a, b) => Math.max(a, b.sortIndex), -1);
  return maxSortIndex + 1;
};
//#endregion

//#region Thunks
export const addScenarioFromForm = createAppAsyncThunk('scenarios/addScenarioFromForm', async ({ scenarioFormFields, quoteId }: { scenarioFormFields: ScenarioFormFields, quoteId: QuoteId }, thunkApi) => {
  const state = thunkApi.getState();
  const scenariosForQuote = getItemsForId(selectAllScenariosByQuoteIdMap(state), quoteId);

  const quote = selectQuoteById(state, quoteId);
  const sortIndex = getNextSortIndex(state, quote?.clientFileId ?? null);

  const newScenario: RowCropScenario = {
    quoteId: quoteId,
    scenarioId: generatePrimaryKey(),
    rowCropScenarioId: generatePrimaryKey(),
    name: scenarioFormFields.name,
    scenarioColor: scenarioFormFields.scenarioColor || getScenarioColor(scenariosForQuote),
    quickUnit: null,
    projectedPrice: scenarioFormFields.projectedPrice,
    harvestPrice: scenarioFormFields.harvestPrice,
    typeId: scenarioFormFields.typeId,
    practiceId: scenarioFormFields.practiceId,
    actualProducerYield: scenarioFormFields.producerYield,
    volatility: scenarioFormFields.volatility,
    expectedCountyYield: scenarioFormFields.expectedCountyYield,
    actualCountyYield: scenarioFormFields.actualCountyYield,
    scenarioOptions: [],
    isVisible: true,
    isFinalized: false,
    offlineCreatedOn: undefined,
    offlineLastUpdatedOn: undefined,
    offlineDeletedOn: undefined,
    highRiskTypeId: scenarioFormFields.highRiskTypeId,
    sortIndex,
  };
  await thunkApi.dispatch(addScenario({ newScenario }));

  const unitYears = selectUnitYearsThatCanApplyToScenario(state, newScenario.quoteId, newScenario.typeId, newScenario.practiceId, newScenario.highRiskTypeId);
  const newScenarioUnitYearAph: ScenarioUnitYearAph[] = unitYears.flatMap(unitYear => {
    const unitYearAph = selectUnitYearAphByUnitYearId(state, unitYear.unitYearId);
    return unitYearAph.map(unitYearAphRow => ({
      scenarioUnitYearAphId: generatePrimaryKey(),
      scenarioId: newScenario.scenarioId,
      unitYearAphId: unitYearAphRow.unitYearAphId,
      yeStatus: YeStatusType.NotOptedOut,
      offlineCreatedOn: undefined,
      offlineLastUpdatedOn: undefined,
      offlineDeletedOn: undefined,
    }));
  });
  await thunkApi.dispatch(addScenarioUnitYearAph({ scenarioUnitYearAph: newScenarioUnitYearAph }));

  return newScenario;
});

export const addScenario = createAppAsyncThunk(
  'scenarios/add-scenario', async ({ newScenario }: { newScenario: RowCropScenario }) => {
    await createRowCropScenarioRequest(newScenario);
    return newScenario;
  });

type DuplicateScenarioSettings = {
  newQuoteId?: QuoteId;
  color?: 'copy' | 'new';
  sortIndex?: 'copy' | 'new';
  shouldSkipValidation?: boolean;
};

const internalDuplicateScenario = createAppAsyncThunk(
  'scenarios/internalDuplicateScenario',
  async ({ newScenarioId, existingScenario, scenarioSettings }: { newScenarioId: ScenarioId, existingScenario: RowCropScenario, scenarioSettings?: DuplicateScenarioSettings }, thunkApi) => {
    const state = thunkApi.getState();

    // Use a QuoteId if provided, else assume the existing scenario's quoteId.
    const quoteIdToUse = scenarioSettings?.newQuoteId ?? existingScenario.quoteId;

    // Scenario Color
    const colorMode = scenarioSettings?.color ?? 'new';
    let scenarioColor;

    if (colorMode === 'copy') {
      scenarioColor = existingScenario.scenarioColor;
    } else {
      const quoteScenarios = getItemsForId(selectAllScenariosByQuoteIdMap(state), quoteIdToUse);
      scenarioColor = getScenarioColor(quoteScenarios);
    }

    // Sort Index
    const sortIndexMode = scenarioSettings?.sortIndex ?? 'new';
    let sortIndex;

    if (sortIndexMode === 'copy') {
      sortIndex = existingScenario.sortIndex;
    } else {
      const quote = selectQuoteById(state, quoteIdToUse);
      sortIndex = getNextSortIndex(state, quote?.clientFileId ?? null);
    }

    // ========================

    const newScenario: RowCropScenario = {
      ...existingScenario,
      scenarioId: newScenarioId,
      quoteId: quoteIdToUse,
      rowCropScenarioId: generatePrimaryKey(),
      scenarioColor: scenarioColor,
      sortIndex,
    };
    await thunkApi.dispatch(addScenario({ newScenario }));

    const unitYears = selectUnitYearsThatCanApplyToScenario(state, newScenario.quoteId, newScenario.typeId, newScenario.practiceId, newScenario.highRiskTypeId);
    const newScenarioUnitYearAph: ScenarioUnitYearAph[] = unitYears.flatMap(unitYear => {
      const unitYearAph = selectUnitYearAphByUnitYearId(state, unitYear.unitYearId);
      return unitYearAph.map(unitYearAphRow => ({
        scenarioUnitYearAphId: generatePrimaryKey(),
        scenarioId: newScenario.scenarioId,
        unitYearAphId: unitYearAphRow.unitYearAphId,
        yeStatus: YeStatusType.NotOptedOut,
        offlineCreatedOn: undefined,
        offlineLastUpdatedOn: undefined,
        offlineDeletedOn: undefined,
      }));
    });
    await thunkApi.dispatch(addScenarioUnitYearAph({ scenarioUnitYearAph: newScenarioUnitYearAph }));

    return newScenario;
  });

export const duplicateScenario = createAppAsyncThunk(
  'scenarios/duplicateScenario', async ({ existingScenario, scenarioSettings }: { existingScenario: RowCropScenario, scenarioSettings?: DuplicateScenarioSettings }, thunkApi) => {
    const state = thunkApi.getState();

    let newScenario: RowCropScenario;

    newScenario = await thunkApi.dispatch(internalDuplicateScenario({
      newScenarioId: generatePrimaryKey(),
      existingScenario: existingScenario,
      scenarioSettings,
    })).unwrap();

    await thunkApi.dispatch(modifyScenarioOrder({ quoteId: newScenario.quoteId }));

    if (!isNullOrUndefined(existingScenario.quickUnit)) {
      await thunkApi.dispatch(duplicateScenarioQuickUnit({ scenarioQuickUnit: existingScenario.quickUnit, scenarioId: newScenario.scenarioId }));
    }

    const requests: Promise<unknown>[] = [];

    // Duplicate ScenarioOptionUnitYears
    const unitYearOptionsRequest = thunkApi.dispatch(duplicateUnitYearOptionsForScenario({ copyFromScenarioId: existingScenario.scenarioId, copyToScenarioId: newScenario.scenarioId }));
    requests.push(unitYearOptionsRequest);

    // row crop pieces
    const existingRowCropScenarioPieces = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), existingScenario.scenarioId);
    const rowCropScenarioPiecesRequest = thunkApi.dispatch(duplicateRowCropScenarioPieces({ rowCropScenarioPieces: existingRowCropScenarioPieces, scenarioId: newScenario.scenarioId }));
    requests.push(rowCropScenarioPiecesRequest);

    // input costs
    const existingInputCostScenarioPieces = getItemsForId(selectAllInputCostScenarioPiecesByScenarioMap(state), existingScenario.scenarioId);
    const inputCostScenarioPiecesRequest = thunkApi.dispatch(duplicateInputCostScenarioPieces({ inputCostScenarioPieces: existingInputCostScenarioPieces, scenarioId: newScenario.scenarioId }));
    requests.push(inputCostScenarioPiecesRequest);

    // forward sold
    const existingForwardSoldScenarioPieces = getItemsForId(selectAllForwardSoldScenarioPiecesByScenarioMap(state), existingScenario.scenarioId);
    const forwardSoldScenarioPiecesRequest = thunkApi.dispatch(duplicateForwardSoldScenarioPieces({ forwardSoldScenarioPieces: existingForwardSoldScenarioPieces, scenarioId: newScenario.scenarioId }));
    requests.push(forwardSoldScenarioPiecesRequest);

    // harvest revenue pieces
    const existingHarvestRevenuePieces = getItemsForId(selectAllHarvestRevenueScenarioPiecesByScenarioMap(state), existingScenario.scenarioId);
    const harvestRevenueScenarioPiecesRequest = thunkApi.dispatch(duplicateHarvestRevenueScenarioPieces({ harvestRevenueScenarioPieces: existingHarvestRevenuePieces, scenarioId: newScenario.scenarioId }));
    requests.push(harvestRevenueScenarioPiecesRequest);

    // hail
    const compositionsMap = selectAllHailScenarioPieceCompositionsByScenarioMap(state);
    const existingFirstHailScenarioPieceCompositions = compositionsMap.get(existingScenario.scenarioId)?.at(0);
    if (existingFirstHailScenarioPieceCompositions) {
      const hailScenarioPieceCompositionRequest = thunkApi.dispatch(duplicateHailScenarioPieceComposition({ hailScenarioPieceComposition: existingFirstHailScenarioPieceCompositions, scenarioId: newScenario.scenarioId }));
      requests.push(hailScenarioPieceCompositionRequest);
    }

    await Promise.all(requests);

    // Default to not skipping validation. Only special cases should skip.
    const shouldSkipValidation = scenarioSettings?.shouldSkipValidation ?? false;

    if (!shouldSkipValidation) {
      // once all the pieces are in place, copy over units
      await thunkApi.dispatch(validateAndUpdateScenario({ scenarioId: newScenario.scenarioId }));
    }

    return newScenario;
  });

/** Duplicates all of the scenarios provided. */
export const duplicateScenarios = createAppAsyncThunk(
  'scenarios/duplicateScenarios', async ({ scenarios, scenarioSettings }: { scenarios: RowCropScenario[], scenarioSettings?: DuplicateScenarioSettings }, thunkApi) => {
    const promises = scenarios.map(scenario => thunkApi.dispatch(duplicateScenario({ existingScenario: scenario, scenarioSettings })));
    await Promise.all(promises);
  },
);

export const updateLinkedScenarios = createAppAsyncThunk(
  'scenarios/updateLinkedScenarios', async ({ clientFileId }: { clientFileId: ClientFileId }, thunkApi) => {
    const state = thunkApi.getState();
    const quoteIds = selectQuotesByClientFileId(state, clientFileId).map(x => x.quoteId);
    const userLinkedScenarios = selectUserLinkedScenarios(state);

    const currentSelectedScenario = currentlySelectedScenario(state);

    const promises: Promise<unknown>[] = [];
    for (const quoteId of quoteIds) {
      const linkedScenarioData = userLinkedScenarios.quotes[quoteId] ?? null;
      if (linkedScenarioData === null) {
        continue;
      }

      const scenarios = selectScenariosByIds(state, linkedScenarioData.linkedScenarioIds);

      // there is no need to update linked items if there's 0 or 1 item in the list
      // but if 2 or more, then save the first and it'll update the rest
      if (scenarios.length <= 1) {
        continue;
      }

      const firstScenario = scenarios.at(0);
      if (firstScenario === undefined) {
        continue;
      }

      // if the linked scenario is in the list and opened, the db is saved with correct values,
      // but the UI doesn't fully update, was easier to just close the drawer
      if (currentSelectedScenario !== null && scenarios.map(x => x.scenarioId).includes(currentSelectedScenario.scenarioId)) {
        thunkApi.dispatch(closeDrawer());
      }

      promises.push(thunkApi.dispatch(updateScenario({ updatedScenario: firstScenario })));
    }

    await Promise.all(promises);
  },
);

export const updateScenario = createAppAsyncThunk(
  'scenarios/updateScenario',
  async ({ updatedScenario }: {
    updatedScenario: RowCropScenario,
  }, thunkApi) => {
    const state = thunkApi.getState();
    const userLinkedScenarios = selectUserLinkedScenarios(state);
    const linkedScenarioData = userLinkedScenarios.quotes[updatedScenario.quoteId] ?? null;

    let scenariosToUpdate: RowCropScenario[];

    if (linkedScenarioData !== null) {
      // Pull the other scenarios to update out of current redux state. Note that this assumes the other linked scenarios must already be in memory.
      const allScenariosDictionary = selectScenarioDictionary(state);

      const linkedScenarios = getLinkedDataToUpdate(allScenariosDictionary, linkedScenarioData, updatedScenario);

      // Building the final request body - we need the actual main scenario being updated, as that was tracked separately,
      // then all of the other scenarios linked to it.
      scenariosToUpdate = [updatedScenario, ...linkedScenarios];
    } else {
      scenariosToUpdate = [updatedScenario];
    }

    await thunkApi.dispatch(modifyScenarios({ scenarios: scenariosToUpdate }));
  },
);

export const sortScenarios = createAppAsyncThunk('scenarios/sortScenarios', async ({ scenarios }: { scenarios: RowCropScenario[] }, thunkApi) => {
  const sortedScenarios = produce(scenarios, draftScenarios => {
    for (let i = 0; i < draftScenarios.length; i++) {
      draftScenarios[i].sortIndex = i;
    }
  });

  await thunkApi.dispatch(modifyScenarios({ scenarios: sortedScenarios }));
});

const modifyScenarios = createAppAsyncThunk(
  'scenarios/modify-scenarios',
  async ({ scenarios }: { scenarios: RowCropScenario[] }) => {
    // Note: this is kind of atypical in that most endpoints don't have batch updaters.
    // I'm sure technically we could just use the batch endpoint, but for the sake of backwards compatibility
    // I'm leaving in the two calls. As far as I know though we could just use the batch update method for everything.
    if (scenarios.length === 1) {
      await updateRowCropScenarioRequest(scenarios[0]);
    } else if (scenarios.length > 1) {
      await updateRowCropScenariosRequest(scenarios);
    }

    return scenarios;
  });

/** Note: This implementation is temporary. There is currently no mechanism in the UI to reorder scenarios.
 * There is no component of this function that cannot be changed to meet future requirements.
*/
export const modifyScenarioOrder = createAppAsyncThunk(
  'scenarios/modifyScenarioOrder',
  async ({ quoteId }: { quoteId: QuoteId }, thunkApi) => {
    // Rather than passing in an ordered list of scenarios, just pull the current list from state.
    // This is because I don't want to create assumptions about how ordering will actually work.
    const state = thunkApi.getState();
    const scenariosForQuote = getItemsForId(selectAllScenariosByQuoteIdMap(state), quoteId);
    const scenarioIdsForQuote = scenariosForQuote.map(s => s.scenarioId);

    // This modifies the order in local state
    thunkApi.dispatch(setScenarioOrder({ quoteId, scenarios: scenarioIdsForQuote }));

    // Get the adjusted state after we just modified it above to go ahead and auto-persist it.
    const newScenarioOrderState = thunkApi.getState().userSettings.scenarioOrder;

    // Try to persist the state to the API.
    await thunkApi.dispatch(modifyScenarioOrderForUser({ userScenarioOrder: newScenarioOrderState }));
  });

export const toggleIsScenarioFinalized = createAppAsyncThunk(
  'scenarios/modifyFinalizedScenarios',
  async ({ scenario }: { scenario: RowCropScenario }, thunkApi) => {
    const tempScenario: RowCropScenario = { ...scenario, isFinalized: !scenario.isFinalized };
    await thunkApi.dispatch(modifyScenarios({ scenarios: [tempScenario] }));
  });

export const toggleIsScenarioVisible = createAppAsyncThunk(
  'scenarios/modifyHiddenScenarios',
  async ({ scenario }: { scenario: RowCropScenario }, thunkApi) => {

    const tempScenario: RowCropScenario = { ...scenario, isVisible: !scenario.isVisible };
    await thunkApi.dispatch(modifyScenarios({ scenarios: [tempScenario] }));
  });

export const setScenarioHighRiskType = createAppAsyncThunk(
  'scenarios/setScenarioHighRiskType',
  async ({ scenario, highRiskType }: { scenario: RowCropScenario, highRiskType: HighRiskType }, thunkApi) => {

    const tempScenario = { ...scenario, highRiskTypeId: highRiskType };
    await thunkApi.dispatch(modifyScenarios({ scenarios: [tempScenario] }));
  });

export const removeScenario = createAppAsyncThunk(
  'scenarios/removeScenario',
  async ({ scenario }: { scenario: RowCropScenario }, thunkApi) => {
    await deleteRowCropScenarioRequest(scenario.scenarioId);
    const state = thunkApi.getState();

    const trendlineAnalysis = selectAllTrendlines(state).get(scenario.scenarioId);
    if (trendlineAnalysis) {
      thunkApi.dispatch(removeTrendline({ trendline: trendlineAnalysis }));
    }

    const possibleMatricesImpacted = selectAllComparisonMatrices(state);
    possibleMatricesImpacted.forEach((matrices, scenarioId) => {
      if (scenarioId === scenario.scenarioId) return;
      matrices.forEach(matrix => {
        //No need to update if the scenario being deleted isn't included in the matrix
        if (matrix.scenarioMatrices.every(sm => sm.scenarioId !== scenario.scenarioId)) return;

        const filteredScenarioMatrices = matrix.scenarioMatrices.filter(sm => sm.scenarioId !== scenario.scenarioId);
        thunkApi.dispatch(modifyMatrix({ matrixData: matrix, matrix: matrix, includedScenarios: filteredScenarioMatrices.map(x => x.scenarioId) }));
      });
    });

    const currentSelectedHistoricalScenarioId = selectSelectedHistoricalScenarioId(state);
    if (currentSelectedHistoricalScenarioId !== null && currentSelectedHistoricalScenarioId === scenario.scenarioId) {
      thunkApi.dispatch(setSelectedHistoricalScenarioId(null));
    }

    return scenario;
  });

export const fetchScenarios = createAppAsyncThunk('scenarios/fetchScenarios', async ({ quoteId }: { quoteId: QuoteId }) => {
  const scenarios = await getRowCropScenariosRequest(quoteId);
  return scenarios;
});

export const fetchScenariosByClientFile = createAppAsyncThunk('scenarios/fetchScenariosByClientFile', async ({ clientFileId }: { clientFileId: ClientFileId }) => {
  return await getRowCropScenariosByClientFileRequest(clientFileId);
});

//#region Scenario Quick Unit

export const addScenarioQuickUnit = createAppAsyncThunk('scenarios/addScenarioQuickUnit', async ({ scenarioFormFields, quote, scenarioId }: { scenarioFormFields: ScenarioFormFields, quote: Quote, scenarioId: ScenarioId }) => {
  const quickUnit: ScenarioQuickUnit = {
    scenarioId: scenarioId,
    countyId: quote.countyId,
    commodityCode: quote.commodityCode,
    typeId: scenarioFormFields.typeId,
    practiceId: scenarioFormFields.practiceId,
    acres: scenarioFormFields.acres,
    sharePercent: scenarioFormFields.sharePercent,
    aphYield: scenarioFormFields.aphYield,
    rateYield: scenarioFormFields.rateYield,
    approvedYield: scenarioFormFields.approvedYield,
    scenarioQuickUnitId: generatePrimaryKey(),
    offlineCreatedOn: undefined,
    offlineLastUpdatedOn: undefined,
    offlineDeletedOn: undefined,
  };
  await createScenarioQuickUnitRequest(quickUnit);
  return quickUnit;
});

export const duplicateScenarioQuickUnit = createAppAsyncThunk('scenarios/duplicateScenarioQuickUnit', async ({ scenarioQuickUnit, scenarioId }: { scenarioQuickUnit: ScenarioQuickUnit, scenarioId: ScenarioId }, thunkApi) => {
  const state = thunkApi.getState();

  const scenario = selectScenarioById(state, scenarioId);
  if (scenario === null) throw new MissingScenarioInStateError(scenarioId);

  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const scenarioFormFields: ScenarioFormFields = {
    name: scenario.name,
    typeId: scenario.typeId,
    practiceId: scenario.practiceId ?? '',
    harvestPrice: scenario.harvestPrice ?? 0,
    projectedPrice: scenario.projectedPrice ?? 0,
    producerYield: scenario.actualProducerYield ?? 0,
    volatility: scenario.volatility ?? 0,
    expectedCountyYield: scenario.expectedCountyYield ?? 0,
    actualCountyYield: scenario.actualCountyYield ?? 0,
    scenarioColor: scenario.scenarioColor,
    acres: scenarioQuickUnit.acres,
    sharePercent: scenarioQuickUnit.sharePercent,
    aphYield: scenarioQuickUnit.aphYield,
    rateYield: scenarioQuickUnit.rateYield,
    approvedYield: scenarioQuickUnit.approvedYield,
    highRiskTypeId: scenario.highRiskTypeId,
  };

  await thunkApi.dispatch(addScenarioQuickUnit({ scenarioFormFields: scenarioFormFields, quote: quote, scenarioId: scenario.scenarioId }));
});

export const modifyScenarioQuickUnit = createAppAsyncThunk('scenarios/modifyScenarioQuickUnit', async ({ scenarioFormFields, scenarioQuickUnit }: { scenarioFormFields: ScenarioFormFields, scenarioQuickUnit: ScenarioQuickUnit }) => {
  const quickUnit: ScenarioQuickUnit = {
    ...scenarioQuickUnit,
    ...scenarioFormFields,
  };

  await updateScenarioQuickUnitRequest(quickUnit);

  return quickUnit;
});

export const modifyUnitYearsForScenario = createAppAsyncThunk('scenarios/modifyUnitYearsForScenario', async ({ scenarioId }: { scenarioId: ScenarioId }, thunkApi) => {
  /*
    This code is slightly messy and quite possibly difficult to follow so here's what's going on:

    The quote has been updated and we need to make sure that we have units assigned to all of the scenario pieces before attempting to quotes
    We need to run each of the scenario pieces through the availability to make sure that we are only assigning units to scenario pieces that can actually apply.

    However, due to restrictions relating to which pieces rely on, or are incompatible with which pieces, we have to run these checks in a certain order.
    This is where the ordering service comes in. First we determine all of the "base" scenario pieces, then update them and run checks/updates on their dependencies.

    Because any scenario piece could have 0-N dependencies (whether direct or indirect), this means that we are running these checks in a recursive loop until all pieces are done updating.
    Also, because of the dependency restrictions, we need to feed information to the dependents as to which pieces have already been updated.
  */

  //More logic may need to be added here down the line to make sure we are only making these (expensive) changes when we need to.
  //As it stands, this should only trigger updates of the scenario pieces if they actually have units that need to either be added or removed.
  //However, all of the state operations that we are performing do get quite expensive and should be minimized when possible.
  //  Either that, or we don't need to worry about it if we can make the state operations sufficiently inexpensive.

  const state = thunkApi.getState();

  const updateUnitGroupsOnScenarioPiecesInOrder = async <T>(scenarioPiecesToEvaluate: ScenarioPiece[], pieceTypes: readonly ScenarioPieceType[], updateUnitGroups: (scenarioPieceId: ScenarioPieceId) => Promise<T>) => {
    const piecesToUpdate = scenarioPiecesToEvaluate.filter(sp => pieceTypes.includes(sp.scenarioPieceType));

    const promises = piecesToUpdate.map(async scenarioPiece => {
      await updateUnitGroups(scenarioPiece.scenarioPieceId);

      const dependents = scenarioPieceOrderingServiceInstance.getDependantScenarioPieces(scenarioPiece.scenarioPieceType);
      await updateUnitGroupsOnScenarioPiecesInOrder(scenarioPiecesToEvaluate, dependents, updateUnitGroups);
    });

    await Promise.all(promises);
  };

  const scenarioPiecesForScenario = getItemsForId(selectAllScenarioPiecesByScenarioMap(state), scenarioId);
  const hailScenarioPiecesForScenario = getItemsForId(selectAllHailScenarioPiecesByScenarioMap(state), scenarioId);

  const hailScenarioPieceIds = hailScenarioPiecesForScenario.map(hsp => hsp.scenarioPieceId);
  const hailScenarioPieceTypes = hailScenarioPiecesForScenario.map(hsp => hsp.scenarioPieceType);

  const nonHailScenarioPiecesForScenario = scenarioPiecesForScenario.filter(sp => !hailScenarioPieceIds.includes(sp.scenarioPieceId));

  const scenarioPieceTypes = nonHailScenarioPiecesForScenario.map(sp => sp.scenarioPieceType);
  const baseScenarioPieces = optionalBaseScenarioPieces(scenarioPieceTypes);

  await updateUnitGroupsOnScenarioPiecesInOrder(nonHailScenarioPiecesForScenario, baseScenarioPieces, (scenarioPieceId: ScenarioPieceId) => thunkApi.dispatch(updateUnitGroupsForScenarioPiece({ scenarioPieceId })));
  await updateUnitGroupsOnScenarioPiecesInOrder(hailScenarioPiecesForScenario, hailScenarioPieceTypes, (scenarioPieceId: ScenarioPieceId) => thunkApi.dispatch(updateUnitGroupsForScenarioPieceWithSpecificUnits({ scenarioPieceId })));
});

export const fetchAdmDataForScenarios = createAppAsyncThunk('scenarios/fetchAdmDataForScenarios', async ({ scenarioIds, functionToDispatch }: { scenarioIds: ScenarioId[], functionToDispatch: ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => AsyncThunkAction<unknown, unknown, {}> }, thunkApi) => {
  const state = thunkApi.getState();
  const scenarioQuoteIds = selectScenariosByIds(state, scenarioIds).map(scenario => scenario.quoteId);

  await thunkApi.dispatch(fetchAdmDataForQuotes({ quoteIds: scenarioQuoteIds, functionToDispatch }));
});

const buildYearCountyCommodityCodeKey = ({ year, countyId, commodityCode }: AdmDataForQuoteParams) => `${year}-${countyId}-${commodityCode}`;

export const fetchAdmDataForQuotes = createAppAsyncThunk('scenarios/fetchAdmDataForQuotes', async ({ quoteIds, functionToDispatch }: { quoteIds: QuoteId[], functionToDispatch: ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => AsyncThunkAction<unknown, unknown, {}> }, thunkApi) => {
  const state = thunkApi.getState();

  const quotes = selectQuotesByIds(state, quoteIds);

  const apiData: AdmDataForQuoteParams[] = [];
  const alreadySeenYearCountyCommodityCombos = new Set<string>();

  for (const quote of quotes) {
    const clientFile = selectClientFileById(state, quote.clientFileId);
    if (clientFile === null) { continue; }

    const admQuoteParams: AdmDataForQuoteParams = { year: clientFile.year, countyId: quote.countyId, commodityCode: quote.commodityCode };

    // To avoid firing the same requests N times since this could be called for any of quotes in the same client files, or client files with the same parameters.
    const key = buildYearCountyCommodityCodeKey(admQuoteParams);
    if (alreadySeenYearCountyCommodityCombos.has(key)) { continue; }

    apiData.push(admQuoteParams);
    alreadySeenYearCountyCommodityCombos.add(key);
  }

  await thunkApi.dispatch(functionToDispatch({ quoteData: apiData }));
});

//#endregion

export default scenariosSlice.reducer;
