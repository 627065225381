import { ScenarioPieceType } from '@silveus/calculations';
import { InputCostScenarioPiece } from '../../types/api/InputCostScenarioPiece';
import { AppDispatch } from '../store';
import { addInputCostScenarioPiece, createDuplicatedInputCostScenarioPiece } from '../inputCostScenarioPiecesSlice';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import ForwardSoldScenarioPiece from '../../types/api/ForwardSoldScenarioPiece';
import { addForwardSoldScenarioPiece, createDuplicatedForwardSoldScenarioPiece } from '../forwardSoldScenarioPiecesSlice';
import { addHarvestRevenueScenarioPiece, createDuplicatedHarvestRevenueScenarioPiece } from '../harvestRevenueScenarioPiecesSlice';
import HarvestRevenueScenarioPiece from '../../types/api/HarvestRevenueScenarioPiece';
import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import { createDuplicatedRowCropScenarioPiece, createScenarioPiece } from '../scenarioPiecesSlice';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';

type CreatePieceParams<SP extends ScenarioPiece> = {
  dispatch: AppDispatch;
  scenarioId: ScenarioId;
  templatePiece: SP;
};

const rowCropScenarioPieceDefault = {
  createPiece: async ({ dispatch, scenarioId, templatePiece }: CreatePieceParams<RowCropScenarioPiece>) => {
    const scenarioPiece = createDuplicatedRowCropScenarioPiece(templatePiece, scenarioId);

    await dispatch(createScenarioPiece({ scenarioPiece }));
  },
} as const;

/**
 * This is a hard record of which pieces will copy when applying a template, as
 * well as how those pieces will copy.
 */
const applicationRules = {
  [ScenarioPieceType.InputCosts]: {
    createPiece: async ({ dispatch, scenarioId, templatePiece }: CreatePieceParams<InputCostScenarioPiece>) => {
      // Everything gets copied over
      const scenarioPiece = createDuplicatedInputCostScenarioPiece(templatePiece, scenarioId);

      await dispatch(addInputCostScenarioPiece({ scenarioPiece }));
    },
  },

  [ScenarioPieceType.ForwardSold]: {
    createPiece: async ({ dispatch, scenarioId, templatePiece }: CreatePieceParams<ForwardSoldScenarioPiece>) => {
      const scenarioPiece = createDuplicatedForwardSoldScenarioPiece(templatePiece, scenarioId);

      // Do not copy over the marketing yield from the template.
      scenarioPiece.marketingYield = 0;

      await dispatch(addForwardSoldScenarioPiece({ scenarioPiece }));
    },
  },

  [ScenarioPieceType.HarvestRevenue]: {
    createPiece: async ({ dispatch, scenarioId, templatePiece }: CreatePieceParams<HarvestRevenueScenarioPiece>) => {
      // Everything gets copied over
      const scenarioPiece = createDuplicatedHarvestRevenueScenarioPiece(templatePiece, scenarioId);

      await dispatch(addHarvestRevenueScenarioPiece({ scenarioPiece }));
    },
  },

  // Individual
  [ScenarioPieceType.RP]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.RpHpe]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.YP]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.Aph]: rowCropScenarioPieceDefault,

  // Area
  [ScenarioPieceType.Arp]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.ArpHpe]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.Ayp]: rowCropScenarioPieceDefault,

  // MP
  [ScenarioPieceType.MP]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.MpHpo]: rowCropScenarioPieceDefault,

  // STAX
  [ScenarioPieceType.StaxRp]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.StaxRpHpe]: rowCropScenarioPieceDefault,

  // HIP
  [ScenarioPieceType.Hip]: rowCropScenarioPieceDefault,

  // ECO
  [ScenarioPieceType.EcoPlusRevenue]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.EcoPlusYield]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.EcoRp]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.EcoYp]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.EcoRpHpe]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.MyEcoRevenue]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.MyEcoYield]: rowCropScenarioPieceDefault,

  // SCO
  [ScenarioPieceType.ScoPlusRevenue]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.ScoPlusYield]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.ScoRp]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.ScoRpHpe]: rowCropScenarioPieceDefault,
  [ScenarioPieceType.ScoYp]: rowCropScenarioPieceDefault,

} as const;

type ValidApplicationKey = keyof typeof applicationRules;
type ApplicationValue = typeof applicationRules[ValidApplicationKey];

export const getTemplateApplicationRules = (scenarioPieceType: ScenarioPieceType) => {
  // This assertion is safe because of the next assertion below, which accounts for keys not present.
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  const pieceAsKey = scenarioPieceType as ValidApplicationKey;

  // This assertion is done to keep the above code legible. This is a tradeoff. As written,
  // the application rules type doesn't know that it's possible for keys to not exist on the type.
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  const objectSearchResult = applicationRules[pieceAsKey] as ApplicationValue | undefined;

  return objectSearchResult ?? null;
};