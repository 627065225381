import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { MenuItem, TextField } from '@mui/material';
import { AgencyId } from '../../../types/api/PrimaryKeys';
import { validateAgency } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import { handleValidationResult } from './validationHelpers';
import { AgencyInformation } from '../../../types/api/agencyInformation';
import { AppTask } from '../../../types/api/AppTask';
import { AppType } from '../../../types/api/enums/application/AppType.enum';
import { useEffect } from 'react';

type PreviousAipInputProps = {
  value: Nullable<AgencyId>;
  originalAgencyId: Nullable<AgencyId>;
  index: number;
  canEditAppTask: boolean;
  agencies: AgencyInformation[];
}

const AgencyInput = ({ value, index, canEditAppTask, agencies, originalAgencyId }: PreviousAipInputProps) => {
  const inputName = `appTasks.${index}.agencyId`;
  const { control, setValue } = useFormContext();

  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });

  const isDisabledBasedOnAppType = currentAppTask.appType === AppType.Cancel || currentAppTask.appType === AppType.HoldForCancelTransfer || currentAppTask.appType === AppType.CommitNoChange;

  useEffect(() => {
    if (isDisabledBasedOnAppType) {
      setValue(inputName, originalAgencyId);
    }
  }, [isDisabledBasedOnAppType]);
  return (
    <Controller
      control={control}
      shouldUnregister={false}
      name={inputName}
      rules={{ validate: val => handleValidationResult(validateAgency(val)) }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          select
          disabled={!canEditAppTask || isDisabledBasedOnAppType}
          error={!!error}
          inputRef={ref}
          size="small"
          value={field.value}
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          {agencies.map(agency => (
            <MenuItem sx={{ fontSize: '12px' }} key={agency.agencyId} value={agency.agencyId}>{agency.agencyName}</MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default AgencyInput;