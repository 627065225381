import { AdjustmentType } from '@silveus/calculations';
import { IEnumAttributes } from '@silveus/calculations/dist/utils/IEnumAttributes';
import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';

export interface PriceAdjustmentTypeSelectorProps {
  index: number;
  autoFocus?: boolean;
  disabled?: boolean;
  onValueChange: () => void;
}

export const PriceAdjustmentTypeSelectorInput = ({
  index, availableAdjustmentTypes, autoFocus = false, disabled = false, onValueChange,
}: PriceAdjustmentTypeSelectorProps & { availableAdjustmentTypes: IEnumAttributes<AdjustmentType>[] }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={`transactions.[${index}].priceAdjustmentTypeId`}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.restrictNotOnListValue(availableAdjustmentTypes.map(x => x.value)),
      }}
      defaultValue={AdjustmentType.Fixed}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          select
          value={value}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
          style={{ width: '100%' }}
          onChange={e => {
            field.onChange(e.target.value);
            onValueChange();
          }}
        >
          {availableAdjustmentTypes.map(x => <MenuItem sx={{ fontSize: '12px', padding: '2px 10px' }} key={x.value} value={x.value}>{x.description}</MenuItem>)}
        </TextField>
      )}
    />
  );
};