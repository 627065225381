import {
  AvailabilityService,
  Commodity,
  CoverageTypeCode,
  EcoIndemnityParams,
  EcoPremiumParams,
  EcoRequestDTO,
  EcoUnit, OptionCode, Unit,
  UnitOfMeasure,
  UnitStructureCode
} from '@silveus/calculations';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../../app/scenarioPiecesSlice';
import { RootState } from '../../../app/store';
import BaseUnit from '../../../types/api/BaseUnit';
import EcoCalculationParams from '../../../types/api/calculationData/ecoCalculationParams';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { getItemsForId } from '../../../utils/mapHelpers';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getCalcParamRequestUnits, getMatchingCalcParamDataForUnit, getUnitsForScenarioPiece } from '../calculationUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import { getEcoCalcDataRequest } from '../../requestInterception/scenarioPieces/ecoRequestInterceptor';
import { selectQuoteById } from '../../../app/quotesSlice';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { EcoCalculationParamsRequest } from '../../../types/api/calculationData/ecoCalculationParamsRequest';
import { Nullable } from '../../../types/util/Nullable';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';

export const createEcoData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  //Get the calc data back from the API
  const calcParamRequest = createCalcParamRequest(state, scenarioPiece, scenario, baseUnits);
  const returnedData = await getEcoCalcDataRequest(calcParamRequest);

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  const piecesForScenario = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), scenarioPiece.scenarioId);
  const piecesThatThisDependsOn = AvailabilityService.getScenarioPiecesThatThisScenarioPieceImmediatelyDependsOn(scenarioPiece.scenarioPieceType);
  const underlyingPiece = piecesForScenario.find(sp => piecesThatThisDependsOn.includes(sp.scenarioPieceType)) ?? null;

  if (underlyingPiece === null) throw new Error('An underlying RP, RPHPE, or YP scenario piece is required');

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createEcoPlanDto(scenarioPiece, returnedData, unitsForScenarioPiece, scenario, underlyingPiece.upperCoverageLevel / 100, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformEcoData = (calcData: Nullable<EcoCalculationParams>, unit: Nullable<BaseUnit>, scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece, underlyingCoverageLevelPercent: number): EcoPremiumParams & EcoIndemnityParams => {
  const ecoParams: EcoPremiumParams & EcoIndemnityParams = {
    commodityCode: (unit?.commodityCode ?? '') as Commodity,
    conservComplSubsidyReductionPercent: calcData?.conservComplSubsidyReductionPercent ?? 0,
    contractPrice: scenario.projectedPrice ?? 0,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
    isBeginningOrVeteranFarmerOrRancher: false,
    isConservationCompliance: false,
    isNativeSod: false,
    multipleCommodityAdjustmentFactor: calcData?.multipleCommodityAdjustmentFactor ?? 0,
    unitOfMeasure: (calcData?.unitOfMeasure ?? 0) as UnitOfMeasure,
    priceElectionPercent: scenarioPiece.protectionFactor / 100,
    projectedPrice: scenario.projectedPrice ?? 0,
    subsidyPercent: calcData?.subsidyPercent ?? 0,
    liabilityAdjustmentFactor: calcData?.multipleCommodityAdjustmentFactor ?? 0,
    underlyingLiabilityAmount: 0,
    totalPremiumMultiplicativeOptionalRateAdjustmentFactor: calcData?.totalPremiumMultiplicativeOptionalRateAdjustmentFactor ?? 0,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    unitStructureCode: scenarioPiece.unitStructure,
    coverageTypeCode: CoverageTypeCode.A,
    underlyingCoverageLevelPercent: underlyingCoverageLevelPercent,
    underlyingGuaranteeAmount: 0,
  };

  return ecoParams;
};

const createEcoUnits = (scenarioPiece: RowCropScenarioPiece, ecoCalcData: EcoCalculationParams[], units: BaseUnit[], scenario: RowCropScenario): (EcoUnit & Unit)[] => {
  return units.map(unit => {

    const matchingData = getMatchingCalcParamDataForUnit(unit, ecoCalcData);

    //TODO: Error Handling
    if (isNullOrUndefined(matchingData)) throw new Error('ECO Data Transform State mismatch: Unable to find data returned from API associated with unit in state.');
    const unitDto = createBaseUnit(unit);
    const ecoUnit: EcoUnit & Unit = {
      ...unitDto,
      id: unit.id,
      baseRate: matchingData.baseRate,
      //TODO #61367: This is a temporary workaround until we get a more permanent solution for handling options per scenario piece
      options: unit.options.filter(optionCode => optionCode === OptionCode.SE),
    };

    return ecoUnit;
  });
};

const createEcoPlanDto = (scenarioPiece: RowCropScenarioPiece, apiData: EcoCalculationParams[], unitData: BaseUnit[],
  scenario: RowCropScenario, underlyingCoverageLevelPercent: number, unitGroups: UnitGroup[]): EcoRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createEcoUnits(
        scenarioPiece,
        apiData,
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformEcoData(apiData.at(0) ?? null, unitData.at(0) ?? null, scenario, scenarioPiece, underlyingCoverageLevelPercent),
  };
};

const createCalcParamRequest = (state: RootState, scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, baseUnits: BaseUnit[]): EcoCalculationParamsRequest => {
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  return {
    year: clientFile.year,
    coverageTypeCode: (scenarioPiece.rowCropScenarioPieceExtendedData?.isCat ?? false) ? CoverageTypeCode[CoverageTypeCode.C] : CoverageTypeCode[CoverageTypeCode.A],
    unitStructureCode: UnitStructureCode[scenarioPiece.unitStructure],
    planCode: scenarioPiece.planCode,
    upperCoverageLevel: scenarioPiece.upperCoverageLevel,
    volatility: scenario.volatility,
    countyId: quote.countyId,
    commodityCode: quote.commodityCode,
    distinctUnits: getCalcParamRequestUnits(baseUnits),
  };
};
