import { ADMYear } from '../../types/api/adm/ADMYear';
import { AppTask } from '../../types/api/AppTask';
import { AppTaskCoverage } from '../../types/api/appTaskCoverage';
import { getCommodityName, getCountyName } from '../adm';
import { isNullOrUndefined } from '../nullHandling';

export function sortAppTasksAndCoverages(appTasks: AppTask[], admData: ADMYear[]): AppTask[] {
  // Helper function to get countyName safely
  const getCountyNameHelper = (coverage: AppTaskCoverage | undefined, cropYear: number): string => {
    const countyCode = coverage?.countyCode;
    if (isNullOrUndefined(countyCode)) {
      return '';
    }
    return getCountyName(admData, cropYear, countyCode);
  };

  // Helper function to get cropName safely
  const getCropNameHelper = (coverage: AppTaskCoverage | undefined, cropYear: number): string => {
    const cropCode = coverage?.cropCode;
    if (isNullOrUndefined(cropCode)) {
      return '';
    }
    return getCommodityName(admData, cropYear, cropCode);
  };

  // Sort coverages within each appTask first
  for (const appTask of appTasks) {
    appTask.coverages.sort((a, b) => {
      const countyNameA = getCountyNameHelper(a, appTask.cropYear).toLowerCase();
      const countyNameB = getCountyNameHelper(b, appTask.cropYear).toLowerCase();

      if (countyNameA !== countyNameB) {
        return countyNameA.localeCompare(countyNameB);
      }

      const cropNameA = getCropNameHelper(a, appTask.cropYear);
      const cropNameB = getCropNameHelper(b, appTask.cropYear);
      if (cropNameA !== cropNameB) {
        return cropNameA.localeCompare(cropNameB);
      }

      const planNameA = a.planCodeName;
      const planNameB = b.planCodeName;
      return planNameA.localeCompare(planNameB);
    });
  }

  // Then sort appTasks based on the countyName, cropName
  appTasks.sort((a, b) => {
    const firstCoverageA = a.coverages.find(coverage => coverage.countyCode !== null);
    const firstCoverageB = b.coverages.find(coverage => coverage.countyCode !== null);

    const countyNameA = getCountyNameHelper(firstCoverageA, a.cropYear).toLowerCase();
    const countyNameB = getCountyNameHelper(firstCoverageB, b.cropYear).toLowerCase();

    if (countyNameA !== countyNameB) {
      return countyNameA.localeCompare(countyNameB);
    }

    const cropNameA = getCropNameHelper(firstCoverageA, a.cropYear);
    const cropNameB = getCropNameHelper(firstCoverageB, b.cropYear);
    if (cropNameA !== cropNameB) {
      return cropNameA.localeCompare(cropNameB);
    }

    return 0;
  });

  return appTasks;
}