import { Button } from '@mui/material';

interface ReconciliationButtonsProps {
  pageNumber: number;
  firstPageNumber: number;
  lastPageNumber: number;
  handleNextClicked: () => void;
  handlePreviousClicked: () => void;
  handleCancelClicked: () => void;
  handleFinishClicked: () => void;
  isPageAbleToProgress: boolean;
}

const ReconciliationButtons = ({ pageNumber, firstPageNumber, lastPageNumber, handleNextClicked, handlePreviousClicked, handleCancelClicked, handleFinishClicked, isPageAbleToProgress }: ReconciliationButtonsProps) => {
  return (
    <>
      {pageNumber === firstPageNumber && <Button variant="outlined" onClick={handleCancelClicked}>Cancel</Button>}
      {pageNumber !== firstPageNumber && <Button variant="outlined" onClick={handlePreviousClicked}>Go Back</Button>}
      {pageNumber < lastPageNumber && <Button variant="contained" disabled={!isPageAbleToProgress} onClick={handleNextClicked}>Next</Button>}
      {pageNumber === lastPageNumber && <Button variant="contained" disabled={!isPageAbleToProgress} onClick={handleFinishClicked}>Finish Reconciliation</Button>}
    </>
  );
};

export default ReconciliationButtons;