import { SxProps, Theme, useTheme } from '@mui/material';
import { formatCurrency, getColor } from '../../utils/formatNumbers';
import { ToggleableTypography } from '../toggleableTypography/toggleableTypography';

const getNumberFormattedAsCurrencyOrLoadingDefault = (currencyValue: number, shouldShowAsLoadingState: boolean) => {
  let outputText: string;

  if (shouldShowAsLoadingState) {
    outputText = '---';
  } else {
    outputText = formatCurrency(currencyValue, true);
  }

  return outputText;
};

interface CurrencyTextProps {
  currencyAmount: number;
  colorize: boolean;
  sx?: SxProps<Theme>;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  isDataLoading?: boolean;
  disabled?: boolean;
}



const CurrencyText = ({ currencyAmount, colorize, sx, align, isDataLoading = false, disabled = false }: CurrencyTextProps) => {
  const theme = useTheme();
  const ColorDefault = theme.palette.altText.defaultCurrency;

  const color = colorize ? getColor(currencyAmount, theme) : ColorDefault;

  const shouldDisplayAmountAsLoading = isDataLoading && currencyAmount === 0;
  const displayText = getNumberFormattedAsCurrencyOrLoadingDefault(currencyAmount, shouldDisplayAmountAsLoading);

  return (
    <ToggleableTypography disabled={disabled} variant="value" color={color} align={align} sx={sx}>{ displayText }</ToggleableTypography>
  );
};

export default CurrencyText;