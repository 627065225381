import EntityDiff from '../entityDiffing/entityDiff';
import { selectPracticeById, selectTypeById } from '../../../app/admSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { formatCurrency } from '../../../utils/formatNumbers';
import RowCropScenarioPieceDiff from './rowCropScenarioPieceDiff';
import { isEmpty } from '../../../utils/nullHandling';
import { ReactElement } from 'react';
import DataLocationType from '../constants/dataLocationType';
import { NamedReconciliationStack } from '../../../types/app/ReconciliationTrackedEntity';
import EntityDiffHeader from '../entityDiffing/entityDiffHeader';
import { createJoinedString } from '../utils/createJoinedString';
import { ScenarioPieceType } from '@silveus/calculations';
import { InputCostScenarioPieceDiff } from './inputCostScenarioPieceDIff';
import { HarvestRevenueScenarioPieceDiff } from './harvestRevenueScenarioPieceDiff';
import { ForwardSoldScenarioPieceDiff } from './forwardSoldScenarioPieceDiff';

type StackType = NamedReconciliationStack<'rowCropScenarios'>;
type RowCropScenarioPieceSubEntityStackType = NamedReconciliationStack<'rowCropScenarioPieces'>;
type ForwardSoldScenarioPieceSubEntityStackType = NamedReconciliationStack<'forwardSoldScenarioPieces'>;
type InputCostScenarioPieceSubEntityStackType = NamedReconciliationStack<'inputCostScenarioPieces'>;
type HarvestRevenueScenarioPieceSubEntityStackType = NamedReconciliationStack<'harvestRevenueScenarioPieces'>;

interface RowCropScenarioDiffProps {
  rowCropScenario: StackType;
  reconciledRowCropScenario: StackType;
  onReconciliationTypeChange: (entity: StackType) => void;
  dataLocation: DataLocationType;
}

const RowCropScenarioDiff = ({ rowCropScenario, reconciledRowCropScenario, onReconciliationTypeChange, dataLocation }: RowCropScenarioDiffProps) => {
  const cropType = useAppSelector(state => selectTypeById(state, rowCropScenario.entity.typeId));
  const practice = useAppSelector(state => selectPracticeById(state, rowCropScenario.entity.practiceId ?? ''));

  const subtitle = createJoinedString([
    cropType?.name ?? null,
    practice?.name ?? null,
  ]);

  const scenarioOptionsString = rowCropScenario.subCollections.scenarioOptions
    .filter(so => so.entityType !== 'deleted')
    .map(so => so.entity.option.toString()).join('-');

  // If any scenario options show up as in a conflicting state, because they show up inline, make sure to show the details for the whole scenario.
  const shouldAlwaysShowDetails = rowCropScenario.subCollections.scenarioOptions.some(so => so.entityType !== 'unmodified');

  const detailComponents = [
    rowCropScenario.entity.name,
    cropType?.name ?? null,
    practice?.name ?? null,
    `${formatCurrency(rowCropScenario.entity.projectedPrice ?? 0)} Proj. Price`,
    `${formatCurrency(rowCropScenario.entity.harvestPrice ?? 0)} Harv. Price`,
    `${rowCropScenario.entity.volatility ?? 0} Vol.`,
    isEmpty(scenarioOptionsString) ? null : scenarioOptionsString,
  ];

  const getRowCropSubEntityDiffElements = (
    subEntities: RowCropScenarioPieceSubEntityStackType[],
    subEntitySubset: RowCropScenarioPieceSubEntityStackType[],
    onReconciliationTypeChange: (subEntity: RowCropScenarioPieceSubEntityStackType) => void,
  ) => {
    const elements: ReactElement[] = [];

    if (subEntitySubset.length > 0) {
      const subEntityIds = subEntitySubset.map(subEntity => subEntity.id);
      const applicableSubEntities = subEntities.filter(subEntity => subEntityIds.includes(subEntity.id));

      elements.push(
        <EntityDiffHeader
          title="Scenario Pieces"
          entityType="unmodified"
          isReconciled={applicableSubEntities.every(entity => entity.reconciliationType !== 'unset')}
          selectedReconciliationType={dataLocation}
          onSelectedReconciliationTypeChanged={() => {}}
          areSomeSubEntitiesSelected={false}
          areAllSubEntitiesSelected={false}
          preventSelection={true}
          dataLocation={dataLocation}
        />,
      );
    }

    subEntitySubset.forEach(subsetEntity => {
      const applicableEntity = subEntities.find(subEntity => subEntity.id === subsetEntity.id);

      if (applicableEntity === undefined) return;

      if (subsetEntity.entity.scenarioPieceType === ScenarioPieceType.ForwardSold) {
        // ToDo: I think a long term approach to avoiding this is to refactor EntityDiff
        // so that subEntitySelector and its two dependencies are passed as one object in an array structure.
        // This is very messy casting.
        const typedSubsetEntity = subsetEntity as unknown as ForwardSoldScenarioPieceSubEntityStackType;
        const typedApplicableEntity = applicableEntity as unknown as ForwardSoldScenarioPieceSubEntityStackType;
        const typedOnReconciliationTypeChange = onReconciliationTypeChange as unknown as (subEntity: ForwardSoldScenarioPieceSubEntityStackType) => void;

        elements.push(
          <ForwardSoldScenarioPieceDiff
            forwardSoldScenarioPiece={typedSubsetEntity}
            reconciledForwardSoldScenarioPiece={typedApplicableEntity}
            onReconciliationTypeChange={typedOnReconciliationTypeChange}
            dataLocation={dataLocation}
            key={subsetEntity.id}
          />,
        );
      } else if (subsetEntity.entity.scenarioPieceType === ScenarioPieceType.InputCosts) {
        const typedSubsetEntity = subsetEntity as unknown as InputCostScenarioPieceSubEntityStackType;
        const typedApplicableEntity = applicableEntity as unknown as InputCostScenarioPieceSubEntityStackType;
        const typedOnReconciliationTypeChange = onReconciliationTypeChange as unknown as (subEntity: InputCostScenarioPieceSubEntityStackType) => void;

        elements.push(
          <InputCostScenarioPieceDiff
            inputCostScenarioPiece={typedSubsetEntity}
            reconciledInputCostScenarioPiece={typedApplicableEntity}
            onReconciliationTypeChange={typedOnReconciliationTypeChange}
            dataLocation={dataLocation}
            key={subsetEntity.id}
          />,
        );
      } else if (subsetEntity.entity.scenarioPieceType === ScenarioPieceType.HarvestRevenue) {
        const typedSubsetEntity = subsetEntity as unknown as HarvestRevenueScenarioPieceSubEntityStackType;
        const typedApplicableEntity = applicableEntity as unknown as HarvestRevenueScenarioPieceSubEntityStackType;
        const typedOnReconciliationTypeChange = onReconciliationTypeChange as unknown as (subEntity: HarvestRevenueScenarioPieceSubEntityStackType) => void;

        elements.push(
          <HarvestRevenueScenarioPieceDiff
            harvestRevenueScenarioPiece={typedSubsetEntity}
            reconciledHarvestRevenueScenarioPiece={typedApplicableEntity}
            onReconciliationTypeChange={typedOnReconciliationTypeChange}
            dataLocation={dataLocation}
            key={subsetEntity.id}
          />,
        );
      } else {
        elements.push(
          <RowCropScenarioPieceDiff
            rowCropScenarioPiece={subsetEntity}
            reconciledRowCropScenarioPiece={applicableEntity}
            onReconciliationTypeChange={onReconciliationTypeChange}
            dataLocation={dataLocation}
            key={subsetEntity.id}
          />,
        );
      }
    });

    return elements;
  };

  return (
    <EntityDiff
      title={rowCropScenario.entity.name}
      subEntitySelector={rowCropScenario => [
        ...rowCropScenario.subCollections.rowCropScenarioPieces,
        ...rowCropScenario.subCollections.forwardSoldScenarioPieces,
        ...rowCropScenario.subCollections.inputCostScenarioPieces,
        ...rowCropScenario.subCollections.harvestRevenueScenarioPieces,
      ] as RowCropScenarioPieceSubEntityStackType[]}
      subtitle={subtitle}
      trackedEntity={reconciledRowCropScenario}
      subsetEntity={rowCropScenario}
      detailComponents={detailComponents}
      getSubEntityDiffElements={getRowCropSubEntityDiffElements}
      onReconciliationTypeChange={onReconciliationTypeChange}
      dataLocation={dataLocation}
      shouldAlwaysShowDetails={shouldAlwaysShowDetails}
    />
  );
};

export default RowCropScenarioDiff;