import axios from 'axios';
import { AgentTeamId, InsuredId } from '../types/api/PrimaryKeys';
import { Contact } from '../types/api/contact';
import { envBaseApiUrl } from '../constants/envConstants';

export const getContactsForAgentTeam = async (insuredId: InsuredId, agentTeamId: AgentTeamId) => {
  const res = await axios.get<Contact[]>(`${envBaseApiUrl}/insureds/${insuredId}/contacts/agent-team/${agentTeamId}`);
  return res.data;
};

export const getBusinessesForAgentTeam = async (insuredId: InsuredId, agentTeamId: AgentTeamId) => {
  const res = await axios.get<Contact[]>(`${envBaseApiUrl}/insureds/${insuredId}/businesses/agent-team/${agentTeamId}`);
  return res.data;
};