import { useState } from 'react';
import {
  setScenarioOptionsWithOptionState
} from '../../app/optionsSlice';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../hooks/reduxHooks';
import { UnitYearId } from '../../types/api/PrimaryKeys';
import { Nullable } from '../../types/util/Nullable';
import UnitYear from '../../types/api/UnitYear';
import { selectUnitYears } from '../../app/scenariosSlice';
import { validateBasicUnitNumber } from '../units/validations/basicUnitNumberValidation';
import { validateOptionalUnitNumber } from '../units/validations/optionalUnitNumberValidation';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import {
  ApprovedYieldProps,
  calculateAverageSharePercent,
  calculateRateYield,
  calculateTotalAcres,
  calculateWeightedAverageAdjustedYield,
  calculateWeightedAverageApprovedYield,
  calculateWeightedAverageRateYield,
  HistoricalTYield,
  OptionCode,
  roundToPlaces,
  ScenarioPieceType
} from '@silveus/calculations';
import { validateFarmName } from '../units/validations/farmNameValidation';
import { validateFarmNumber } from '../units/validations/farmNumberValidation';
import { validateSection } from '../units/validations/sectionValidation';
import { validateTownship } from '../units/validations/townshipValidation';
import { validateRange } from '../units/validations/rangeValidation';
import { validateLocation } from '../units/validations/locationValidation';
import { validateAcres } from '../units/validations/acresValidation';
import { validateInsuredSharePercent } from '../units/validations/insuredShareValidation';
import { closeUnitsModal, saveUnitYears } from '../../app/unitsSlice';
import { ConfirmStateContent, openConfirm } from '../../app/confirmSlice';
import {
  addOrUpdateScenarioUnitYearAph,
  addUpdateOrRemoveUnitYearAph,
  selectScenarioUnitYearAphForScenarioId,
  selectUnitYearAphByUnitYearMap
} from '../../app/unitYearAphSlice';
import UnitYearAph from '../../types/api/UnitYearAph';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import UnitsSummary from '../../types/app/UnitsSummary';
import {
  selectAvailableSubCountyCodes,
  selectAvailableTYields,
  selectHistoricalTYieldsForCountyCommodity
} from '../../app/admSlice';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../app/scenarioPiecesSlice';
import TrendAdjustmentFactor from '../../types/api/adm/TrendAdjustmentFactor';
import { getItemsForId } from '../../utils/mapHelpers';
import TYield from '../../types/api/adm/TYield';
import { YeStatusType } from '../../types/api/enums/optionStates/yeStatusType.enum';
import { MuiDialogCloseReason } from '../../types/mui/MuiDialogCloseReason';
import { Quote } from '../../types/api/Quote';
import { validateAndUpdateQuote } from '../../app/validationsSlice';
import { getFilteredTYield } from '../../utils/tYieldUtils';
import { useTaFactors } from '../../hooks/useTrendAdjustmentFactors';
import { useOptions } from '../../hooks/useOptions';
import { OptionLevelCodes } from '../../constants/optionLevelCodes';
import { useScenarioYieldCalculator } from '../unitsAphModal/useScenarioYieldCalculator';
import { OptionSelectionState } from '../../types/app/enums/optionSelectionState.enum';
import OptionState from '../../types/app/OptionState';

export type UnitYearGridItem = UnitYear & {
  approvedYield: Nullable<number>,
  adjustedYield: Nullable<number>,
  rateYield: Nullable<number>,
  priorYearProduction: Nullable<number>,
  priorYearAcres: Nullable<number>,
  priorYearYield: Nullable<number>
};

export const useUnitsModal = (scenario: RowCropScenario, quote: Quote) => {
  const dispatch = useAppDispatch();

  //Form Tracking
  const [unitFormDirty, setUnitFormDirty] = useState(false);
  const [unitAphFormDirty, setAphFormDirty] = useState(false);

  //Options
  const { getOptionStatesForScenario, getOptionStatesForUnits } = useOptions(quote.quoteId, scenario.scenarioId);
  const [localScenarioOptions, setLocalScenarioOptions] = useState(getOptionStatesForScenario());
  const [localScenarioOptionUnitYears, setLocalScenarioOptionUnitYears] = useState(getOptionStatesForUnits());

  //Needed for yield calculations
  const allHistoricalTYields = useAppSelector(state => selectHistoricalTYieldsForCountyCommodity(state, quote.countyId, quote.commodityCode));
  const tYields = useAppSelector(selectAvailableTYields);
  const subCountyCodes = useAppSelector(selectAvailableSubCountyCodes);
  const getTaFactors = useTaFactors();
  const primaryScenarioPiece = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenario.scenarioId).find(sp => [ScenarioPieceType.YP, ScenarioPieceType.RP, ScenarioPieceType.RpHpe].includes(sp.scenarioPieceType));
  const historicalTYields = allHistoricalTYields.filter(ty => ty.planCode === primaryScenarioPiece?.planCode);

  //UnitYear
  const globalUnitYears: UnitYear[] = useAppSelector(state => selectUnitYears(state, scenario.quoteId, scenario.typeId, scenario.practiceId, scenario.highRiskTypeId)).map(uy => ({ ...uy, sharePercent: roundToPlaces(uy.sharePercent * 100, 2) }));
  const [localUnitYears, setLocalUnitYears] = useState(globalUnitYears);
  //APH needed for prior year prod, acres, and yield
  const globalUnitYearAphMap = useAppSelector(selectUnitYearAphByUnitYearMap);
  const [localUnitYearAphMap, setLocalUnitYearAphMap] = useState(globalUnitYearAphMap);
  const setLocalUnitYearAphMapFilter = (unitYearsAphMap: Map<UnitYearId, UnitYearAph[]>) => {
    const filteredMap: Map<UnitYearId, UnitYearAph[]> = new Map();
    for (const [unitYear, unitYearAph] of unitYearsAphMap) {
      filteredMap.set(unitYear, unitYearAph.map(uyaph => {
        return {
          unitYearAphId: uyaph.unitYearAphId,
          unitYearId: uyaph.unitYearId,
          yieldType: uyaph.yieldType,
          year: uyaph.year,
          acres: uyaph.acres,
          aphYield: uyaph.aphYield,
          production: uyaph.production,
          preQaYield: uyaph.preQaYield,
          offlineCreatedOn: undefined,
          offlineLastUpdatedOn: undefined,
          offlineDeletedOn: undefined,
        };
      }));
    }
    setLocalUnitYearAphMap(filteredMap);
  };

  //Scenario specific APH properties needed for adding a new APH row when entering a prior year prod, acres, and yield
  const globalScenarioUnitYearAph = useAppSelector(state => selectScenarioUnitYearAphForScenarioId(state, scenario.scenarioId));
  const [localScenarioUnitYearAph, setLocalScenarioUnitYearAph] = useState(globalScenarioUnitYearAph);

  const yieldCalculator = useScenarioYieldCalculator(scenario.scenarioId);

  const [isSaving, setIsSaving] = useState(false);

  const taFactors = getTaFactors(quote.countyId, quote.commodityCode).allTaFactors;
  const localUnitsSummary = getUnitsSummary(localUnitYears, localUnitYearAphMap, localScenarioUnitYearAph, historicalTYields, tYields, subCountyCodes, localScenarioOptionUnitYears, taFactors, primaryScenarioPiece?.planCode ?? '');

  const isDirty = unitFormDirty || unitAphFormDirty;

  const updateLocalUnitYears = (unitYears: UnitYear[]) => {
    setLocalUnitYears(unitYears);
    setUnitFormDirty(true);
  };

  const generateUnitYearGridItems = (localUnitYears: UnitYear[], localUnitYearAphMap: Map<UnitYearId, UnitYearAph[]>) => {
    const newUnitYears: UnitYearGridItem[] = [];
    for (const unitYear of localUnitYears) {
      const unitYearAph = getItemsForId(localUnitYearAphMap, unitYear.unitYearId);
      const priorYearAphRow = unitYearAph.find(row => row.year === unitYear.year - 1);
      const selectedOptions = getItemsForId(localScenarioOptionUnitYears, unitYear.unitYearId)
        .filter(o => o.isAvailable && o.selectionState === OptionSelectionState.On).map(o => o.option.optionCode);
      const singleUnitCalculator = yieldCalculator.forSingleUnitYear(unitYear, unitYearAph, localScenarioUnitYearAph, selectedOptions);
      const adjustedYield = singleUnitCalculator.calculateAdjustedYield();
      const approvedYield = singleUnitCalculator.calculateApprovedYield();
      newUnitYears.push({
        ...unitYear,
        rateYield: calculateRateYield(unitYearAph),
        adjustedYield,
        approvedYield,
        priorYearProduction: priorYearAphRow?.production ?? null,
        priorYearAcres: priorYearAphRow?.acres ?? null,
        priorYearYield: priorYearAphRow?.aphYield ?? null,
      });
    }
    return newUnitYears;
  };

  const localUnitYearGridItems = generateUnitYearGridItems(localUnitYears, localUnitYearAphMap);

  const handleClose = (reason?: MuiDialogCloseReason) => {
    // to avoid loss of data by forgetting to save we don't want to close the modal if a user
    // clicks outside the modal boundary.
    if (reason === 'backdropClick') {
      return;
    }

    if (isDirty) {
      const confirmWindow: ConfirmStateContent = {
        title: 'Warning: Unsaved Changes',
        message: 'You have unsaved changes that will be discarded upon closing, potentially resulting in a loss of work.',
        confirmText: 'Save Changes',
        onConfirm: () => handleSave(true),
        cancelText: 'Discard Changes',
        onCancel: () => dispatch(closeUnitsModal()),
        shouldPreventBackdropClose: true,
      };
      dispatch(openConfirm(confirmWindow));
    } else {
      dispatch(closeUnitsModal());
    }
  };

  const areAllUnitsValid = localUnitYearGridItems.every(isUnitValid);

  const resetFormDirtyStates = () => {
    setAphFormDirty(false);
    setUnitFormDirty(false);
  };

  const handleSave = async (shouldCloseOnSave: boolean) => {
    if (!areAllUnitsValid) {
      return;
    }

    if (!isDirty) {
      // no sense going through the save path if nothing was changed.
      if (shouldCloseOnSave) {
        return dispatch(closeUnitsModal());
      } else {
        return;
      }
    }

    setIsSaving(true);

    const existingUnitYears = globalUnitYears.map(uy => ({ ...uy, sharePercent: roundToPlaces(uy.sharePercent / 100, 4) }));
    const newUnitYears = localUnitYears.map(uy => ({ ...uy, sharePercent: roundToPlaces(uy.sharePercent / 100, 4) }));

    await dispatch(saveUnitYears({ newUnitYears: newUnitYears, existingUnitYears: existingUnitYears }));

    // Persist Scenario Options
    await dispatch(setScenarioOptionsWithOptionState({ scenarioId: scenario.scenarioId, scenarioOptionState: localScenarioOptions, scenarioOptionUnitYearState: localScenarioOptionUnitYears }));
    // Save aph
    await dispatch(addUpdateOrRemoveUnitYearAph({ initialUnitYearAph: Array.from(globalUnitYearAphMap.values()).flat(), modifiedUnitYearAph: Array.from(localUnitYearAphMap.values()).flat() }));
    await dispatch(addOrUpdateScenarioUnitYearAph({ initialScenarioUnitYearAph: globalScenarioUnitYearAph, modifiedScenarioUnitYearAph: localScenarioUnitYearAph }));

    await dispatch(validateAndUpdateQuote({ quoteId: scenario.quoteId }));

    setIsSaving(false);
    resetFormDirtyStates();

    if (shouldCloseOnSave) {
      dispatch(closeUnitsModal());
    }
  };

  const updateUnitOptions = (unitOptionStates: OptionState[], unitYearId: UnitYearId) => {
    const existingOptionsForUnitYear = getItemsForId(localScenarioOptionUnitYears, unitYearId);
    const newScenarioOptions = [...localScenarioOptions];
    const newScenarioOptionsByUnitMap = new Map(localScenarioOptionUnitYears);

    if (unitOptionStates.length === 0){
      //Removing this unit
      newScenarioOptionsByUnitMap.delete(unitYearId);
    } else {
      let hasUnitOptionsChanged = false;
      const newUnitOptions: OptionState[] = existingOptionsForUnitYear.map(existingOption => {
        //We only want to update the state of Unit Level Options here
        if (existingOption.option.optionLevelCode !== OptionLevelCodes.Unit) return { ...existingOption };
        const existingUnitOptionState = unitOptionStates.find(uos => uos.option.optionCode === existingOption.option.optionCode);
        if (existingUnitOptionState === undefined) return { ...existingOption };

        const newSelectionState = existingUnitOptionState.selectionState;
        if (existingOption.selectionState !== newSelectionState) hasUnitOptionsChanged = true;
        return {
          ...existingOption,
          selectionState: newSelectionState,
        };

      });
      // We call update every time we update a row, if nothing has changed we can just short circuit here
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!hasUnitOptionsChanged) {
        return;
      }
      newScenarioOptionsByUnitMap.set(unitYearId, newUnitOptions);
    }

    //Because the option state has changed we need to update the corresponding ScenarioOption
    const allUnitOptionStates = Array.from(newScenarioOptionsByUnitMap.values()).flatMap(arr => arr);
    //We only loop through the unit level option codes because the user can't change the other option levels from editing a single unit
    const unitLevelScenarioOptions = localScenarioOptions.filter(o => o.option.optionLevelCode === OptionLevelCodes.Unit);
    for (const existingScenarioOption of unitLevelScenarioOptions) {
      let newSelectionState = existingScenarioOption.selectionState;
      const activeUnitOptionStatesForThisCode = allUnitOptionStates.filter(o => o.option.optionCode === existingScenarioOption.option.optionCode && o.selectionState === OptionSelectionState.On);

      switch (activeUnitOptionStatesForThisCode.length) {
        case 0:
          //No units have this option turned on meaning this should be off
          newSelectionState = OptionSelectionState.Off;
          break;
        case newScenarioOptionsByUnitMap.size:
          //Number of units with this option turned on equals the total number of units meaning this should be on
          newSelectionState = OptionSelectionState.On;
          break;
        default:
          //Number of units with this option turned on doesn't equal number of units meaning this should be in-determinate
          newSelectionState = OptionSelectionState.Partial;
      }

      //Update Scenario Option
      const indexToUpdate = newScenarioOptions.findIndex(so => so.option.optionCode === existingScenarioOption.option.optionCode);
      newScenarioOptions[indexToUpdate] = { ...newScenarioOptions[indexToUpdate], selectionState: newSelectionState };
    }

    setLocalScenarioOptions(newScenarioOptions);
    setLocalScenarioOptionUnitYears(newScenarioOptionsByUnitMap);
    setUnitFormDirty(true);
  };

  const addOptionToAllUnits = (unitOptionCode: OptionCode) => {
    updateOptionSelectionsOnAllUnits(unitOptionCode, OptionSelectionState.On);
  };

  const removeOptionFromAllUnits = (unitOptionCode: OptionCode) => {
    updateOptionSelectionsOnAllUnits(unitOptionCode, OptionSelectionState.Off);
  };

  const updateOptionSelectionsOnAllUnits = (optionCode: OptionCode, desiredState: OptionSelectionState) => {
    const tempScenarioOptionUnitYears = new Map(localScenarioOptionUnitYears);
    const tempScenarioOptions = [...localScenarioOptions];

    const scenarioOptionIndex = tempScenarioOptions.findIndex(so => so.option.optionCode === optionCode);
    if (scenarioOptionIndex === -1) return;
    tempScenarioOptions[scenarioOptionIndex] = { ...tempScenarioOptions[scenarioOptionIndex], selectionState: desiredState };


    for (const unitYear of localUnitYearGridItems) {
      const unitOptionStates = getItemsForId(localScenarioOptionUnitYears, unitYear.unitYearId);

      const newUnitOptionStates = [...unitOptionStates];
      const unitOptionIndex = newUnitOptionStates.findIndex(so => so.option.optionCode === optionCode);
      if (unitOptionIndex === -1) continue;
      newUnitOptionStates[unitOptionIndex] = { ...newUnitOptionStates[scenarioOptionIndex], selectionState: desiredState };
      tempScenarioOptionUnitYears.set(unitYear.unitYearId, newUnitOptionStates);
    }
    setLocalScenarioOptions(tempScenarioOptions);
    setLocalScenarioOptionUnitYears(tempScenarioOptionUnitYears);
    setUnitFormDirty(true);
  };

  return {
    handleClose,
    updateUnitOptions: updateUnitOptions,
    addOptionToAllUnits,
    removeOptionFromAllUnits,
    localScenarioOptions: localScenarioOptions,
    localScenarioOptionUnitYears: localScenarioOptionUnitYears,
    isUnitValid,
    handleSave,
    isGridValid: areAllUnitsValid,
    setLocalUnitYears: updateLocalUnitYears,
    localUnitYearAphMap: localUnitYearAphMap,
    setLocalUnitYearAphMap: setLocalUnitYearAphMapFilter,
    initialScenarioUnitYearAph: globalScenarioUnitYearAph,
    localUnitYearGridItems: localUnitYearGridItems,
    localScenarioUnitYearAph: localScenarioUnitYearAph,
    setLocalScenarioUnitYearAph: setLocalScenarioUnitYearAph,
    unitsSummary: localUnitsSummary,
    setLocalScenarioOptionUnitYears: setLocalScenarioOptionUnitYears,
    isSaving: isSaving,
    setAphFormDirty,
  };
};

const isUnitValid = (unitYear: UnitYear) => {
  const validationResults = [
    validateBasicUnitNumber(unitYear.basicUnitNumber),
    validateOptionalUnitNumber(unitYear.optionalUnitNumber),
    validateFarmName(unitYear.farmName),
    validateFarmNumber(unitYear.farmNumber),
    validateSection(unitYear.section),
    validateTownship(unitYear.township),
    validateRange(unitYear.range),
    validateLocation(unitYear.location),
    validateAcres(unitYear.acres, true),
    validateInsuredSharePercent(roundToPlaces(unitYear.sharePercent / 100, 4)),
  ];

  // compare all validation results explicitly to true because the validators return true | string
  return validationResults.every(x => x === true);
};

const getUnitsSummary = (localUnitYears: UnitYear[], localUnitYearAphMap: Map<UnitYearId, UnitYearAph[]>, localScenarioUnitYearAph: ScenarioUnitYearAph[],
  historicalTYields: HistoricalTYield[], tYields: TYield[], subCountyCodes: string[], localUnitScenarioOptionsMap: Map<UnitYearId, OptionState[]>,
  trendAdjustmentFactors: TrendAdjustmentFactor[], planCode: string): UnitsSummary => {

  const approvedYieldProps = new Map<UnitYear, ApprovedYieldProps>();
  for (const uy of localUnitYears) {
    const isYeApplicable = getItemsForId(localUnitScenarioOptionsMap, uy.unitId).some(o =>
      o.option.optionCode === OptionCode.YE && o.selectionState === OptionSelectionState.On && o.isAvailable === true);
    const unitYearAph = getItemsForId(localUnitYearAphMap, uy.unitYearId)
      .map(aphRow => ({
        ...aphRow, excluded: isYeApplicable && localScenarioUnitYearAph.find(scenAphRow =>
          aphRow.unitYearAphId === scenAphRow.unitYearAphId)?.yeStatus === YeStatusType.NotOptedOut,
      }));
    const applicableHistoricalTYields = historicalTYields.filter(ty => ty.practiceId === uy.practiceId &&
      ty.typeId === uy.typeId && ty.planCode === planCode);
    const filteredTYields = getFilteredTYield(tYields, subCountyCodes, uy.typeId, uy.practiceId, uy.subCountyCode);
    const currentYearTYield = filteredTYields?.transitionalYield ?? null;
    const selectedOptionCodes = localUnitScenarioOptionsMap.get(uy.unitYearId)?.filter(option =>
      option.isAvailable && option.selectionState === OptionSelectionState.On).map(so => so.option.optionCode) ?? [];
    const trendAdjustmentFactor = trendAdjustmentFactors.find(taf => taf.practiceId === uy.practiceId &&
      taf.typeId === uy.typeId && taf.planCode === planCode)?.factor ?? 0;
    approvedYieldProps.set(uy, {
      unitYearAph,
      historicalTYields: applicableHistoricalTYields,
      currentYearTYield,
      selectedOptionCodes,
      trendAdjustmentFactor,
    });
  }

  const rateYieldMap = new Map(localUnitYears.map(uy => [uy, getItemsForId(localUnitYearAphMap, uy.unitYearId)]));
  return {
    acres: calculateTotalAcres(localUnitYears.map(uy => ({ ...uy, sharePercent: roundToPlaces(uy.sharePercent / 100, 4) }))),
    sharePercent: roundToPlaces(calculateAverageSharePercent() * 100, 2),
    weightedAverageRateYield: calculateWeightedAverageRateYield(rateYieldMap),
    adjustedYield: calculateWeightedAverageAdjustedYield(approvedYieldProps),
    approvedYield: calculateWeightedAverageApprovedYield(approvedYieldProps),
  };
};

