import { quoterDb } from '../../db';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import {
  createScenarioUnitYearAphBatch, getScenarioUnitYearAphForScenarios,
  updateScenarioUnitYearAphBatch
} from '../scenarioUnitYearAph.service';
import { getDefaultCachingStrategy, update } from '../offlineDataAccess.service';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { nonGetCacheOnly, nonGetNetworkOnly } from '../../utils/cachingStrategies';
import { isOnlineFromStore } from '../../utils/isOnlineFromStore';

const table = quoterDb.scenarioUnitYearAph;

export const getScenarioUnitYearAphForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<ScenarioUnitYearAph[]> => {
  const request = () => getScenarioUnitYearAphForScenarios(scenarioIds);

  const readTransaction = () => quoterDb.transaction('r', quoterDb.scenarioUnitYearAph, async () => {
    return safeWhere(quoterDb.scenarioUnitYearAph, 'scenarioId').anyOf(scenarioIds).toArray();
  });

  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const createScenarioUnitYearAphBatchRequest = async (scenarioUnitYearAph: ScenarioUnitYearAph[]): Promise<void> => {
  const request = () => createScenarioUnitYearAphBatch(scenarioUnitYearAph);

  const updateTransaction = () => quoterDb.transaction('rw', quoterDb.scenarioUnitYearAph, () => {
    quoterDb.scenarioUnitYearAph.bulkAdd(scenarioUnitYearAph);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const updateScenarioUnitYearAphBatchRequest = async (scenarioUnitYearAph: ScenarioUnitYearAph[]): Promise<void> => {
  const request = () => updateScenarioUnitYearAphBatch(scenarioUnitYearAph);
  return await update(table, scenarioUnitYearAph, request);
};
