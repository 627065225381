import { useEffect, useState } from 'react';
import { Nullable } from '../../../types/util/Nullable';
import { isEqual } from 'lodash';
import DataLocationType from '../constants/dataLocationType';
import ReconciliationTrackedEntity, { SubEntityCollectionTypeBase } from '../../../types/app/ReconciliationTrackedEntity';
import ReconciliationType from '../constants/ReconciliationType';
import { createJoinedString } from '../utils/createJoinedString';
import { getAllSubCollectionArrays, getAllSubEntities } from '../utils/subCollectionHelpers';

const useReconciliationState = <Entity, EntityId, SubEntityCollectionType extends SubEntityCollectionTypeBase, ParentId, SubEntityType, SubEntityId>(
  detailComponents: Nullable<string>[],
  onReconciledStatusChange: (entity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionType, ParentId>) => void,
  trackedEntity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionType, ParentId>,
  subsetEntity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionType, ParentId>,
  dataLocation: DataLocationType,
) => {
  const [revisionTrackedEntity, setRevisionTrackedEntity] = useState(trackedEntity);

  const detailString = createJoinedString(detailComponents);
  const opposingDataLocation = dataLocation === 'client' ? 'server' : 'client';

  useEffect(() => {
    setRevisionTrackedEntity(trackedEntity);
  }, [trackedEntity]);

  useEffect(() => {
    onReconciledStatusChange(revisionTrackedEntity);
  }, [revisionTrackedEntity]);

  const subsetChildrenIds = getAllSubEntities(subsetEntity.subCollections).map(subEntity => subEntity.id);
  const applicableChildren = getAllSubEntities(revisionTrackedEntity.subCollections).filter(subEntity => subsetChildrenIds.includes(subEntity.id));

  const onSubEntityReconciledStatusChange = (subEntity: ReconciliationTrackedEntity<SubEntityType, SubEntityId, SubEntityCollectionTypeBase, EntityId>) => {
    // Top Level Copy
    const newRevisionTrackedEntity = { ...revisionTrackedEntity };
    // Coopy of the subcollections
    newRevisionTrackedEntity.subCollections = { ...revisionTrackedEntity.subCollections };

    // Copy of each array at each subcollection.
    for (const key of Object.keys(newRevisionTrackedEntity.subCollections)) {
      const assertedKey = key as keyof SubEntityCollectionTypeBase;
      // This type assertion to "any" hurts, but removing it I'm finding to be extremely challenging.
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      newRevisionTrackedEntity.subCollections[assertedKey] = [...newRevisionTrackedEntity.subCollections[assertedKey] as any];
    }

    let wasSubEntityFoundInAnyCollection = false;
    for (const newSubCollectionUnTyped of getAllSubCollectionArrays(newRevisionTrackedEntity.subCollections)) {
      // This "has" to be done. Anything else would be extremely challenging AFAIK
      const newSubCollection = newSubCollectionUnTyped as typeof subEntity[];
      const index = newSubCollection.findIndex(subItem => subItem.id === subEntity.id);

      if (index < 0) continue;

      newSubCollection.splice(index, 1, subEntity);
      wasSubEntityFoundInAnyCollection = true;
    }

    if (!wasSubEntityFoundInAnyCollection) return;

    const allSubCollectionEntities = getAllSubEntities(newRevisionTrackedEntity.subCollections);

    if (
      allSubCollectionEntities.every(subEntity => subEntity.reconciliationType !== 'unset') &&
      newRevisionTrackedEntity.entityType === 'unmodified' &&
      newRevisionTrackedEntity.reconciliationType === 'unset'
    ) {
      const opposingDataLocation = dataLocation === 'client' ? 'server' : 'client';
      newRevisionTrackedEntity.reconciliationType = ['keep', dataLocation].includes(subEntity.reconciliationType)
        ? dataLocation
        : opposingDataLocation;
    } else if (
      !allSubCollectionEntities.every(subEntity => subEntity.reconciliationType !== 'unset') &&
      newRevisionTrackedEntity.entityType === 'unmodified'
    ) {
      newRevisionTrackedEntity.reconciliationType = 'unset';
    }

    if (!isEqual(newRevisionTrackedEntity, revisionTrackedEntity)) {
      setRevisionTrackedEntity(newRevisionTrackedEntity);
    }
  };

  const onSelectedReconciliationTypeChanged = (newReconciliationType: ReconciliationType) => {
    const newRevisionTrackedEntity = { ...revisionTrackedEntity };

    //Set the reconciliation type to the new one as a baseline if no specific rules override it
    newRevisionTrackedEntity.reconciliationType = newReconciliationType;

    recursivelySetChildrenReconciliationType(
      newReconciliationType,
      newRevisionTrackedEntity,
      subsetEntity,
      dataLocation,
    );

    //If the element is unmodified and no children are unset, mark this as done being reconciled (no longer unset)
    //If not all children are reconciled and this element is unmodified, set it to unset
    if (
      applicableChildren.every(subEntity => subEntity.reconciliationType !== 'unset') &&
      revisionTrackedEntity.entityType === 'unmodified' &&
      revisionTrackedEntity.reconciliationType === 'unset'
    ) {
      const opposingDataLocation = dataLocation === 'client' ? 'server' : 'client';
      newRevisionTrackedEntity.reconciliationType = ['keep', dataLocation].includes(newReconciliationType)
        ? dataLocation
        : opposingDataLocation;
    }

    if (!isEqual(newRevisionTrackedEntity, revisionTrackedEntity)) {
      setRevisionTrackedEntity(newRevisionTrackedEntity);
    }
  };

  const areAllSubEntitiesSelected = applicableChildren.every(subEntity => !['unset', opposingDataLocation].includes(subEntity.reconciliationType));
  const areSomeSubEntitiesSelected = applicableChildren.some(subEntity => !['unset', opposingDataLocation].includes(subEntity.reconciliationType));

  const isReconciled = applicableChildren.every(subEntity => subEntity.reconciliationType !== 'unset') && revisionTrackedEntity.reconciliationType !== 'unset';

  return {
    detailString,
    onSelectedReconciliationTypeChanged,
    onSubEntityReconciledStatusChange,
    areSomeSubEntitiesSelected: areSomeSubEntitiesSelected,
    areAllSubEntitiesSelected: areAllSubEntitiesSelected,
    revisionTrackedEntity,
    isReconciled,
  };
};

const recursivelySetChildrenReconciliationType = (
  newReconciliationType: ReconciliationType,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  reconciledEntity: ReconciliationTrackedEntity<any, unknown, SubEntityCollectionTypeBase, unknown>,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  filteringEntity: Nullable<ReconciliationTrackedEntity<any, unknown, SubEntityCollectionTypeBase, unknown>>,
  dataLocation: DataLocationType,
) => {
  const groupEntityIds = new Set(getAllSubEntities(filteringEntity?.subCollections ?? {}).map(subEntity => subEntity.id));

  //If filtering entity is null, we should not filter the reconciled entity sub-collection
  const entitiesInGroup = filteringEntity === null
    ? getAllSubEntities(reconciledEntity.subCollections)
    : getAllSubEntities(reconciledEntity.subCollections).filter(subEntity => groupEntityIds.has(subEntity.id));

  const addedEntitiesInGroup = entitiesInGroup.filter(entity => entity.entityType === 'added');
  const deletedEntitiesInGroup = entitiesInGroup.filter(entity => entity.entityType === 'deleted');

  if (newReconciliationType === 'client' || newReconciliationType === 'server') {
    const allReconciledSubEntities = getAllSubEntities(reconciledEntity.subCollections);

    const entitiesOutsideGroup = allReconciledSubEntities.filter(subEntity => !groupEntityIds.has(subEntity.id));
    const conflictingAndUnmodifiedEntities = allReconciledSubEntities.filter(subEntity => ['conflicts', 'unmodified'].includes(subEntity.entityType));

    const addedEntitiesOutsideGroup = entitiesOutsideGroup.filter(entity => entity.entityType === 'added');
    const deletedEntitiesOutsideGroup = entitiesOutsideGroup.filter(entity => entity.entityType === 'deleted');

    //All entities with conflicts should be set to that side's type
    //All unmodified entities should be set to that side's type
    conflictingAndUnmodifiedEntities.forEach(subEntity => {
      filterAndSetEntityProperties(dataLocation, filteringEntity, subEntity, dataLocation);
    });

    //All added entities in the group should be set to "keep"
    addedEntitiesInGroup.forEach(subEntity => {
      filterAndSetEntityProperties('keep', filteringEntity, subEntity, dataLocation);
    });

    //All added entities outside the group should be set to "remove"
    addedEntitiesOutsideGroup.forEach(subEntity => {
      filterAndSetEntityProperties('remove', filteringEntity, subEntity, dataLocation);
    });

    //All removed entities in the group should be set to "remove"
    deletedEntitiesInGroup.forEach(subEntity => {
      filterAndSetEntityProperties('remove', filteringEntity, subEntity, dataLocation);
    });

    //All removed entities outside the group should be set to "keep"
    deletedEntitiesOutsideGroup.forEach(subEntity => {
      filterAndSetEntityProperties('keep', filteringEntity, subEntity, dataLocation);
    });

    //All child entities should recursively follow the same rules
  }

  //When the type of added or deleted is set, all children of the same type should be set to the same
  // while children of the opposing type should be set to the opposite
  // for example, if an added entity gets set to keep then all children that were added should get set to keep
  // while all deleted children get set to remove
  if (newReconciliationType === 'keep') {
    addedEntitiesInGroup.forEach(subEntity => {
      const reconciliationTypeToUse = reconciledEntity.entityType === subEntity.entityType ? 'keep' : 'remove';
      filterAndSetEntityProperties(reconciliationTypeToUse, filteringEntity, subEntity, dataLocation);
    });
    deletedEntitiesInGroup.forEach(subEntity => {
      const reconciliationTypeToUse = reconciledEntity.entityType === subEntity.entityType ? 'keep' : 'remove';
      filterAndSetEntityProperties(reconciliationTypeToUse, filteringEntity, subEntity, dataLocation);
    });
  }

  if (newReconciliationType === 'remove') {
    addedEntitiesInGroup.forEach(subEntity => {
      const reconciliationTypeToUse = reconciledEntity.entityType === subEntity.entityType ? 'remove' : 'keep';
      filterAndSetEntityProperties(reconciliationTypeToUse, filteringEntity, subEntity, dataLocation);
    });
    deletedEntitiesInGroup.forEach(subEntity => {
      const reconciliationTypeToUse = reconciledEntity.entityType === subEntity.entityType ? 'remove' : 'keep';
      filterAndSetEntityProperties(reconciliationTypeToUse, filteringEntity, subEntity, dataLocation);
    });
  }
};

const filterAndSetEntityProperties = (
  reconciliationType: ReconciliationType,
  filteringEntity: Nullable<ReconciliationTrackedEntity<unknown, unknown, SubEntityCollectionTypeBase, unknown>>,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  subEntity: ReconciliationTrackedEntity<any, unknown, SubEntityCollectionTypeBase, unknown>,
  dataLocation: DataLocationType,
) => {
  const filteringSubEntity = getAllSubEntities(filteringEntity?.subCollections ?? {}).find(entity => entity.id === subEntity.id) ?? null;
  if (filteringSubEntity !== null) {
    subEntity.entity = filteringSubEntity.entity;
  }

  subEntity.reconciliationType = reconciliationType;
  recursivelySetChildrenReconciliationType(reconciliationType, subEntity, filteringSubEntity, dataLocation);
};

export default useReconciliationState;