import { quoterDb } from '../../db';
import HistoricalAnalysis from '../../types/api/historicalAnalysis';
import { HistoricalAnalysisId, ScenarioId } from '../../types/api/PrimaryKeys';
import {
  createHistoricalAnalysis, getHistoricalAnalysesByScenarioIds,
  updateHistoricalAnalysis
} from '../historicalAnalysis.service';
import { addSingle, getDefaultCachingStrategy, update } from '../offlineDataAccess.service';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';

const table = quoterDb.historicalAnalyses;

export const getHistoricalAnalysesByScenarioIdsRequest = async (scenarioIds: ScenarioId[]): Promise<HistoricalAnalysis[]> => {
  const request = () => getHistoricalAnalysesByScenarioIds(scenarioIds);

  const transactionTables = [quoterDb.historicalAnalyses];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const historicalAnalyses = safeWhere(quoterDb.historicalAnalyses, 'primaryScenarioId').anyOf(scenarioIds).toArray();
    return historicalAnalyses;
  });

  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const createHistoricalAnalysisRequest = async (historicalAnalysis: HistoricalAnalysis): Promise<CreatedItemResult<HistoricalAnalysisId>> => {
  const request = () => createHistoricalAnalysis(historicalAnalysis);
  return await addSingle(table, historicalAnalysis, request);
};

export const updateHistoricalAnalysisRequest = async (historicalAnalysis: HistoricalAnalysis): Promise<void> => {
  const request = () => updateHistoricalAnalysis(historicalAnalysis);
  return await update(table, historicalAnalysis, request);
};
