import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';
import { CustomizedLineChartLabel } from './CustomizedLineChartLabel';
import { useTheme } from '@mui/material';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../../utils/nullHandling';
import { AxisKey } from './lineCharts.utils';

export type LineData<TData> = {
  dataKey: AxisKey<TData>;
  color: string;
  name: string;
};

type Props<TData> = {
  /** The actual chart data. This should include the x values as well as all possible y values. */
  data: TData[];
  /** Adjust this array to choose which lines to show. */
  lineData: LineData<TData>[];
  /** The key of the property that should power the x axis. */
  xAxisKey: AxisKey<TData>;
  /** Whether tooltips should be shown. */
  showTooltip?: boolean;
  /** Whether the labels on the chart for each value should show up. */
  showLabels?: boolean;
  /** Optional visual modifier to the x-axis values */
  xAxisTick?: string;
  /** Optional customization of x-axis intervals to display */
  customXAxisTicks?: number[];
  /** Optional customization of y-axis intervals to display */
  customYAxisTicks?: number[];
  /** Option to hide visual dot for data points */
  showDots?: boolean;
};

/** This is a wrapper around line charts to be shared amonst all analysis charts.
 * Any changes that apply to all analysis charts should be made here.
 */
export const AnalysisLineChart = <TData,>({
  data, lineData, xAxisKey, showTooltip = false, showLabels = false, xAxisTick = '', customXAxisTicks, customYAxisTicks, showDots = true,
}: Props<TData>) => {

  const theme = useTheme();

  const yAxisTicks = isNotNullOrUndefined(customYAxisTicks)
    ? customYAxisTicks
    : undefined;

  const formatXAxisTick = (value: unknown) => `${value}${xAxisTick}`;
  const xAxisProps = { dataKey: xAxisKey, tickFormatter: formatXAxisTick, ticks: customXAxisTicks };
  const yAxisProps = { padding: { top: 10, bottom: 10 }, ticks: yAxisTicks, domain: isNullOrUndefined(yAxisTicks) ? undefined : [yAxisTicks[0], yAxisTicks[yAxisTicks.length - 1]] };
  const yAxisRange = yAxisProps.domain ? yAxisProps.domain[1] - yAxisProps.domain[0] : 1;

  return (
    // Setting the width to 99% because at 100% the container does not resize when shrinking the window.
    // This seems to be a known issue with the ResponsiveContainer and the simplest solution to resolve
    // that issue is to just set width to 99%.
    //Encountered the same issue with the height as well, so setting the height to 99% as well.
    <ResponsiveContainer width="99%" height="99%" minWidth="400px" minHeight="300px">
      <LineChart data={data} margin={{ right: 22, top: 20 }}>
        {showTooltip && <Tooltip
          contentStyle={{
            backgroundColor: theme.palette.background.paper,
          }}
        />}
        <CartesianGrid />

        <XAxis {...xAxisProps} />
        <YAxis {...yAxisProps} />
        <ZAxis />
        {lineData.map(line =>
          <Line
            isAnimationActive={false}
            key={line.dataKey}
            strokeWidth={3}
            dataKey={line.dataKey}
            name={line.name}
            label={showLabels && <CustomizedLineChartLabel data={data} lineData={lineData} line={line} xAxisKey={xAxisKey} yAxisRange={yAxisRange} />}
            stroke={line.color}
            dot={showDots} />)}
      </LineChart>
    </ResponsiveContainer>
  );
};
