import { quoterDb } from '../db';
import Dexie, { Table } from 'dexie';
import {
  cacheOnly, networkOnly,
  nonGetCacheOnly,
  nonGetNetworkOnly
} from '../utils/cachingStrategies';
import { AxiosResponse } from 'axios';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { CreatedItemsResult } from '../types/api/results/CreatedItemsResult';
import { safeWhere } from '../utils/dexieQueryHelpers/whereClauses';
import { safeGet } from '../utils/dexieQueryHelpers/getClauses';
import { isOnlineFromStore } from '../utils/isOnlineFromStore';
import { applySoftDelete, softDelete } from '../utils/dexieQueryHelpers/softDelete';

type CachingStrategy<T> = (request: () => Promise<AxiosResponse<T, unknown>>, cacheResponse: () => Promise<T | undefined>, transaction: (newData: T) => Promise<void>) => Promise<T>

//#region GET

export const getMultiple = async <T>(
  table: Table<T>,
  equalityCriteria: Partial<T>,
  request: () => Promise<AxiosResponse<T[], unknown>>,
  database: Dexie = quoterDb,
  onlineCachingStrategy: CachingStrategy<T[]> = networkOnly,
  offlineCachingStrategy: CachingStrategy<T[]> = cacheOnly,
): Promise<T[]> => {
  const online = isOnlineFromStore();

  const readTransaction = () => database.transaction('r', table, async () => {
    return await safeWhere(table, equalityCriteria).toArray();
  });

  const updateTransaction = (newData: T[]) => database.transaction('rw', table, () => {
    table.bulkPut(newData);
  });

  const strategy = online ? onlineCachingStrategy : offlineCachingStrategy;

  return strategy(request, readTransaction, updateTransaction);
};

export const getSingle = async <T>(
  table: Table<T>,
  equalityCriteria: Partial<T>,
  request: () => Promise<AxiosResponse<T, unknown>>,
  database: Dexie = quoterDb,
  onlineCachingStrategy: CachingStrategy<T> = networkOnly,
  offlineCachingStrategy: CachingStrategy<T> = cacheOnly,
): Promise<T> => {
  const online = isOnlineFromStore();

  const readTransaction = () => database.transaction('r', table, async () => {
    return safeGet(table, equalityCriteria);
  });

  const updateTransaction = (newData: T) => database.transaction('rw', table, () => {
    table.put(newData);
  });

  const strategy = online ? onlineCachingStrategy : offlineCachingStrategy;

  return strategy(request, readTransaction, updateTransaction);
};

export const getAll = async <T>(
  table: Table<T>,
  request: () => Promise<AxiosResponse<T[], unknown>>,
  database: Dexie = quoterDb,
  onlineCachingStrategy: CachingStrategy<T[]> = networkOnly,
  offlineCachingStrategy: CachingStrategy<T[]> = cacheOnly,
): Promise<T[]> => {
  const online = isOnlineFromStore();

  const readTransaction = () => database.transaction('r', table, async () => {
    return table.toArray();
  });

  const updateTransaction = (newData: T[]) => database.transaction('rw', table, () => {
    table.bulkPut(newData);
  });

  const strategy = online ? onlineCachingStrategy : offlineCachingStrategy;

  return strategy(request, readTransaction, updateTransaction);
};

//#endregion

export const addSingle = async <T, U extends string>(
  table: Table<T>,
  entity: T,
  request: () => Promise<AxiosResponse<CreatedItemResult<U>, unknown>>,
): Promise<CreatedItemResult<U>> => {
  const updateTransaction = () => quoterDb.transaction('rw', table, async () => {
    const result = await table.add(entity);
    const createdItemResult: CreatedItemResult<U> = { createdId: result as U };
    return createdItemResult;
  });

  return nonGetSingle(updateTransaction, request);
};

export const addMultiple = async <T, U extends string>(
  table: Table<T>,
  entity: T[],
  request: () => Promise<AxiosResponse<CreatedItemsResult<U>, unknown>>,
): Promise<CreatedItemsResult<U>> => {
  const updateTransaction = () => quoterDb.transaction('rw', table, async () => {
    const result = await table.bulkAdd(entity, { allKeys: true });
    const createdItemsResult: CreatedItemsResult<U> = { createdIds: result as U[] };
    return createdItemsResult;
  });

  return nonGetSingle(updateTransaction, request);
};

export const update = async <T>(
  table: Table<T>,
  entity: T | T[],
  request: () => Promise<AxiosResponse<void, unknown>>,
): Promise<void> => {
  const updateTransaction = () => quoterDb.transaction('rw', table, () => {
    Array.isArray(entity) ? table.bulkPut(entity) : table.put(entity);
  });

  return nonGetSingle(updateTransaction, request);
};

export const remove = async <T, U extends string>(
  table: Table<T>,
  id: U | U[],
  request: () => Promise<AxiosResponse<void, unknown>>,
): Promise<void> => {
  const updateTransaction = () => quoterDb.transaction('rw', table, () => {
    softDelete(table, id);
  });

  return nonGetSingle(updateTransaction, request);
};

export const removeWhere = async <T>(
  table: Table<T>,
  equalityCriteria: Partial<T>,
  request: () => Promise<AxiosResponse<void, unknown>>,
): Promise<void> => {
  const updateTransaction = () => quoterDb.transaction('rw', table, async () => {
    const whereResults = safeWhere(table, equalityCriteria);
    await applySoftDelete(whereResults);
  });

  return nonGetSingle(updateTransaction, request);
};

const nonGetSingle = async <T>(transaction: () => Promise<T>, request: () => Promise<AxiosResponse<T, unknown>>): Promise<T> => {
  const online = isOnlineFromStore();

  const strategy = online ? nonGetNetworkOnly : nonGetCacheOnly;

  const result = strategy(request, transaction);

  return result;
};

export const getDefaultCachingStrategy = () => isOnlineFromStore() ? networkOnly : cacheOnly;
