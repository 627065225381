import {
  StandardHailRequestDTO,
  StandardHailIndemnityParams,
  StandardHailPremiumParams
} from '@silveus/calculations';
import { RootState } from '../../../app/store';
import BaseUnit from '../../../types/api/BaseUnit';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { createBaseScenarioPieceDto } from '../baseDataTransformations';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import HailRate from '../../../types/api/hail/hailRate';
import { createHailUnits, getHailScenarioPieceEndorsements, getHailScenarioPieceRates, getHailScenarioPieceUnits } from './sharedHailDataTransformUtils';
import HailEndorsement from '../../../types/api/hail/hailEndorsement';
import HailScenarioPieceEndorsement from '../../../types/api/hail/HailScenarioPieceEndorsement';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';

export const createStandardHailData = async (scenarioPiece: HailScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[], shouldSimulateUnitGroups: boolean) => {
  //Get the calc data back from the API
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  const hailRates = await getHailScenarioPieceRates(state, scenarioPiece.hailScenarioPieceId);
  const hailEndorsements = await getHailScenarioPieceEndorsements(state, scenarioPiece.hailScenarioPieceId, quote);
  const hailUnits = getHailScenarioPieceUnits(state, scenarioPiece, quote, scenario, baseUnits, hailRates.hailScenarioPieceRates, hailRates.hailRates, clientFile.year, shouldSimulateUnitGroups);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createHailDto(scenarioPiece,
    hailRates.hailRates,
    hailEndorsements.hailEndorsements,
    hailEndorsements.hailScenarioPieceEndorsements,
    hailUnits.unitsForScenarioPiece,
    scenario,
    hailUnits.unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformHailData = (scenario: RowCropScenario, scenarioPiece: HailScenarioPiece): StandardHailPremiumParams & StandardHailIndemnityParams => {
  const hailParams: StandardHailPremiumParams & StandardHailIndemnityParams = {
    discountPercent: scenarioPiece.discountPercent / 100,
    dollarsOfCoveragePerAcre: scenarioPiece.coveragePerAcre,
    hailLossPercent: scenarioPiece.lossPercent / 100,
  };

  return hailParams;
};

const createHailDto = (scenarioPiece: HailScenarioPiece, hailRates: HailRate[], hailEndorsements: HailEndorsement[], hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[], unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): StandardHailRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createHailUnits(
        hailRates,
        hailEndorsements,
        hailScenarioPieceEndorsements,
        unitData,
      ),
    ),
    ...transformHailData(scenario, scenarioPiece),
  };
};