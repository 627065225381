import axios from 'axios';
import Matrix from '../types/api/Matrix';
import { MatrixId, ScenarioId } from '../types/api/PrimaryKeys';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import SweetSpot from '../types/api/SweetSpot';
import { envBaseApiUrl } from '../constants/envConstants';

export const getMatricesByScenarioIds = (scenarioIds: ScenarioId[]) => axios.post<Matrix[]>(`${envBaseApiUrl}/matrices/scenarios`, scenarioIds);
export const deleteMatrix = (matrixId: MatrixId) => axios.delete(`${envBaseApiUrl}/matrices/${matrixId}/`);

export const createMatrix = (matrix: Matrix) => axios.post<CreatedItemResult<MatrixId>>(`${envBaseApiUrl}/matrices/`, matrix);

export const updateMatrix = (matrix: Matrix) => axios.put<void>(`${envBaseApiUrl}/matrices`, matrix);

export const updateSweetSpots = (matrixId: MatrixId, sweetSpots: SweetSpot[]) => axios.put<void>(`${envBaseApiUrl}/matrices/${matrixId}/sweetspots`, sweetSpots);

export const updateMatricesBatch = (matrices: readonly Matrix[]) => axios.put<void>(`${envBaseApiUrl}/matrices/batch`, matrices);
