export const formatPhoneNumber = (phoneNumberString: string | null) => {
  if (phoneNumberString === null || phoneNumberString === '') return null;

  //Remove all non-numeric characters from the phone # string
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  const areaCode = phoneNumberString.length >= 3 ? cleaned.substring(0, 3) : phoneNumberString;
  const firstSegment = phoneNumberString.length > 6 ? cleaned.substring(3, 6) : cleaned.substring(3, phoneNumberString.length);
  const lastSegment = phoneNumberString.length > 7 ? cleaned.substring(6) : '';

  return `(${areaCode}) ${firstSegment}-${lastSegment}`;
};