export interface UserSettingType {
  userSettingTypeId: number;
  name: string;
  description: string;
}

export const UserSettingTypes = {
  linkedScenarios: {
    userSettingTypeId: 1,
    name: 'Linked Scenarios',
    description: 'Holds information about which scenarios are linked at the user level, as well as what fields for those scenarios are linked. Linked fields will all update when any of the linked scenarios updates.',
  },
  scenarioOrder: {
    userSettingTypeId: 2,
    name: 'Scenario Order',
    description: 'Contains what order scenarios for a given quote should be displayed in, if possible. May not exhaustively contain every scenario for a given quote, or any data at all.',
  },
  // hidden scenarios is 3
  matrixColors: {
    userSettingTypeId: 4,
    name: 'Matrix Colors',
    description: 'Contains a list of matrix colors as an array. These are user default colors that will serve as a text color palette for any matrix the user creates.',
  },
  linkedMatrices: {
    userSettingTypeId: 5,
    name: 'Linked Matrices',
    description: 'Holds information about which matrices are linked at the user level.',
  },
  unitColumn: {
    userSettingTypeId: 6,
    name: 'Unit Column',
    description: 'Contains a list of unit columns as a list. These are the options for custom sorting, column order and size of columns in the units grid.',
  },
  scenarioPieceOrder: {
    userSettingTypeId: 7,
    name: 'Scenario Piece Order',
    description: 'Contains what order scenario pieces for a given scenario should be displayed in, if possible. May not exhaustively contain every scenario piece for a given scenario, or any data at all.',
  },
  historicalColumn: {
    userSettingTypeId: 8,
    name: 'Historical Column',
    description: 'Contains a list of historical quote columns as a list. These are the options for custom sorting, column order and size of columns in the historical grid.',
  },
  premiumBreakdown: {
    userSettingTypeId: 9,
    name: 'Premium Breakdown',
    description: 'Contains the toggle states for the various Premium Breakdown views',
  },
  unitModalColumn: {
    userSettingTypeId: 10,
    name: 'Unit Modal Column',
    description: 'Contains a list of unit modal columns as a list. These are the options for custom sorting, column order and size of columns in the units modal grid.',
  },
  hailPlanExplorerColumn: {
    userSettingTypeId: 11,
    name: 'Hail Plan Explorer Column',
    description: 'Contains a list of hail plan explorer columns as a list. These are the options for custom sorting, column order and size of columns in the hail plan explorer grid.',
  },
  paletteMode: {
    userSettingTypeId: 12,
    name: 'Palette Mode',
    description: 'Contains the toggle state for the user\'s dark/light mode selection.',
  },
  matrixPresets: {
    userSettingTypeId: 13,
    name: 'Matrix Presets',
    description: 'Contains the matrix presets that a user has created.',
  },
} as const;