import { Commodity } from '@silveus/calculations';
import RMAPriceDiscovery, { PriceDiscoveryStatus } from '../types/api/adm/RMAPriceDiscovery';
import PriceGroup from '../types/api/adm/PriceGroup';

export enum PriceDiscoveryPeriod {
  PreDiscovery = 0,
  InDiscovery = 1,
  Set = 2,
}

const convertPriceDiscoveryStatusToPriceDiscoveryPeriod = (status: PriceDiscoveryStatus): PriceDiscoveryPeriod => {
  switch (status) {
    case PriceDiscoveryStatus.YetToStart:
      return PriceDiscoveryPeriod.PreDiscovery;
    case PriceDiscoveryStatus.InDiscovery:
      return PriceDiscoveryPeriod.InDiscovery;
    case PriceDiscoveryStatus.Released:
      return PriceDiscoveryPeriod.Set;
  }
};

export const getDiscoveryPeriod = (
  dateToCompare: Date,
  isHarvestPrice: boolean,
  projectedPrice: number | null,
  harvestPrice: number | null,
  commodityCode: string,
  priceGroup: PriceGroup | undefined,
  rmaPriceDiscovery: RMAPriceDiscovery | null): PriceDiscoveryPeriod => {

  const justDateToCompare = new Date(dateToCompare);
  justDateToCompare.setHours(0, 0, 0, 0);

  if (priceGroup === undefined) {
    return caseOfNoDiscovery(projectedPrice, commodityCode);
  }

  let currentPeriod: PriceDiscoveryPeriod;

  const beginDate = isHarvestPrice ? new Date(priceGroup.harvestDiscoveryBeginDate) : new Date(priceGroup.projectedDiscoveryBeginDate);
  const endDate = isHarvestPrice ? new Date(priceGroup.harvestDiscoveryEndDate) : new Date(priceGroup.projectedDiscoveryEndDate);

  beginDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  if (justDateToCompare >= endDate) {
    currentPeriod = PriceDiscoveryPeriod.Set;
  } else if (justDateToCompare >= beginDate) {
    currentPeriod = PriceDiscoveryPeriod.InDiscovery;
  } else {
    currentPeriod = PriceDiscoveryPeriod.PreDiscovery;
  }

  if (isHarvestPrice) {
    switch (currentPeriod) {
      case PriceDiscoveryPeriod.PreDiscovery:
        return caseOfHarvestPreDiscovery(projectedPrice, rmaPriceDiscovery);
      case PriceDiscoveryPeriod.InDiscovery:
        return caseOfHarvestInDiscovery(projectedPrice, rmaPriceDiscovery);
      case PriceDiscoveryPeriod.Set:
        return caseOfHarvestPostDiscovery(projectedPrice, harvestPrice, rmaPriceDiscovery);
    }
  } else {
    switch (currentPeriod) {
      case PriceDiscoveryPeriod.PreDiscovery:
        return caseOfProjectedPreDiscovery();
      case PriceDiscoveryPeriod.InDiscovery:
        return caseOfProjectedInDiscovery(false, rmaPriceDiscovery);
      case PriceDiscoveryPeriod.Set:
        return caseOfProjectedPostDiscovery(projectedPrice, rmaPriceDiscovery);
    }
  }
};

const caseOfNoDiscovery = (projectedPrice: number | null, commodityCode: string): PriceDiscoveryPeriod => {
  if (commodityCode === Commodity.Pecans) {
    return PriceDiscoveryPeriod.Set;
  }

  if (projectedPrice !== null) {
    return PriceDiscoveryPeriod.Set;
  }

  return caseOfDefault();
};

const caseOfProjectedPreDiscovery = (): PriceDiscoveryPeriod => {
  return PriceDiscoveryPeriod.PreDiscovery;
};

const caseOfProjectedInDiscovery = (isHarvestPrice: boolean, rmaPriceDiscovery: RMAPriceDiscovery | null): PriceDiscoveryPeriod => {
  if (rmaPriceDiscovery !== null) {
    if (isHarvestPrice) {
      return convertPriceDiscoveryStatusToPriceDiscoveryPeriod(rmaPriceDiscovery.harvestPriceStatus);
    } else {
      return convertPriceDiscoveryStatusToPriceDiscoveryPeriod(rmaPriceDiscovery.projectedPriceStatus);
    }
  }
  return caseOfProjectedPreDiscovery();
};

const caseOfProjectedPostDiscovery = (projectedPrice: number | null, rmaPriceDiscovery: RMAPriceDiscovery | null): PriceDiscoveryPeriod => {
  if (projectedPrice !== null) {
    return PriceDiscoveryPeriod.Set;
  }

  return caseOfProjectedInDiscovery(false, rmaPriceDiscovery);
};

const caseOfHarvestPreDiscovery = (projectedPrice: number | null, rmaPriceDiscovery: RMAPriceDiscovery | null): PriceDiscoveryPeriod => {
  if (projectedPrice !== null) {
    return PriceDiscoveryPeriod.PreDiscovery;
  }

  return caseOfProjectedInDiscovery(true, rmaPriceDiscovery);
};


const caseOfHarvestInDiscovery = (projectedPrice: number | null, rmaPriceDiscovery: RMAPriceDiscovery | null): PriceDiscoveryPeriod => {
  if (rmaPriceDiscovery !== null) {
    if (projectedPrice !== null) {
      return rmaPriceDiscovery.harvestPriceStatus === PriceDiscoveryStatus.Released ? PriceDiscoveryPeriod.Set : PriceDiscoveryPeriod.InDiscovery;
    }
  }

  return caseOfDefault();
};

const caseOfDefault = (): PriceDiscoveryPeriod => {
  return PriceDiscoveryPeriod.PreDiscovery;
};

const caseOfHarvestPostDiscovery = (projectedPrice: number | null, harvestPrice: number | null, rmaPriceDiscovery: RMAPriceDiscovery | null): PriceDiscoveryPeriod => {
  if (
    harvestPrice !== null
    &&
    harvestPrice !== 0
    &&
    (
      (
        rmaPriceDiscovery !== null
        && rmaPriceDiscovery.harvestPriceStatus === PriceDiscoveryStatus.Released
        && projectedPrice !== null
        && projectedPrice !== harvestPrice
      )
      ||
      (
        rmaPriceDiscovery === null
        &&
        projectedPrice !== null
      )
    )
  ) {
    return PriceDiscoveryPeriod.Set;
  }

  return caseOfHarvestInDiscovery(projectedPrice, rmaPriceDiscovery);
};

export const getMpActualDiscoveryPeriod = (dateToCompare: Date, commodityCode: string, cropYear: number): PriceDiscoveryPeriod => {
  if (commodityCode !== Commodity.Corn && commodityCode !== Commodity.Soybeans) {
    return PriceDiscoveryPeriod.PreDiscovery;
  }

  const justDateToCompare = new Date(dateToCompare);

  // month for some odd reason is 0 based
  // start = april 1
  // end = may 1
  const mpActualDiscoveryStart = new Date();
  mpActualDiscoveryStart.setFullYear(cropYear, 3, 1);
  mpActualDiscoveryStart.setHours(0, 0, 0, 0);

  const mpActualDiscoveryEnd = new Date();
  mpActualDiscoveryEnd.setFullYear(cropYear, 4, 1);
  mpActualDiscoveryEnd.setHours(0, 0, 0, 0);

  if (justDateToCompare >= mpActualDiscoveryEnd) {
    return PriceDiscoveryPeriod.Set;
  }

  if (justDateToCompare >= mpActualDiscoveryStart) {
    return PriceDiscoveryPeriod.InDiscovery;
  }

  return PriceDiscoveryPeriod.PreDiscovery;
};


