import { useEffect, useState } from 'react';
import {
  syncOfflineChanges
} from '../../services/offline.service';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import {
  selectLastOfflineBulkDataDownloadIsoString,
  selectLastOfflineSynchronizationIsoString, setLastOfflineSynchronizationTime, shouldForceOfflineUpdated
} from '../../app/onlineSlice';
import { fetchPreDownloadedInsureds, selectPreDownloadedInsureds } from '../../app/insuredsSlice';
import { Nullable } from '../../types/util/Nullable';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import DataConflictList from './dataConflictList';
import {
  InsuredId,
  ScenarioId,
  ScenarioOptionId, ScenarioPieceId
} from '../../types/api/PrimaryKeys';
import InsuredReconciliationDialog from './insuredReconciliationDialog';
import {
  reconcileRelationalEntities,
  RelationalEntityReconciliationParams
} from '../../utils/reconciliation/reconcileRelationalEntities';
import ScenarioOptionUnitYear from '../../types/api/options/ScenarioOptionUnitYear';
import ScenarioOption from '../../types/api/options/ScenarioOption';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import { UnitGroup } from '../../types/api/UnitGroup';
import { groupBy } from '../../utils/arrayUtils';
import OfflineReconciliationData from '../../types/api/offline/OfflineReconciliationData';
import ScenarioQuickUnit from '../../types/api/ScenarioQuickUnit';
import useConflictDetectedEntities from './hooks/useConflictDetectedEntities';
import { clearOutExistingInsuredDataRequest } from '../../services/requestInterception/offlineRequestInterceptor';
import BulkInsuredReconciliationData from '../../types/api/offline/BulkInsuredReconciliationData';
import { sleep } from '../../utils/sleep';
import { useGetAllNeededReconciliationData } from './hooks/useGetAllNeededReconciliationData';
import ReconciliationTrackedEntity, { NamedReconciliationStack } from '../../types/app/ReconciliationTrackedEntity';
import { MuiDialogCloseReason } from '../../types/mui/MuiDialogCloseReason';
import { isInsuredOwnedClientFile } from '../../utils/clientFileUtils';

interface ReconciliationDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}

const ReconciliationDialog = ({ isOpen, handleClose }: ReconciliationDialogProps) => {
  const dispatch = useAppDispatch();
  const lastOfflineBulkDataDownloadTime = useAppSelector(selectLastOfflineBulkDataDownloadIsoString);
  const lastOfflineSynchronizationTime = useAppSelector(selectLastOfflineSynchronizationIsoString);
  const preDownloadedInsureds = useAppSelector(selectPreDownloadedInsureds);

  const [selectedInsured, setSelectedInsured] = useState<Nullable<InsuredId>>(null);

  const [reconciledInsuredClientFiles, setReconciledInsuredClientFiles] = useState<NamedReconciliationStack<'insureds'>[]>([]);
  const [reconciledInsuredUnits, setReconciledInsuredUnits] = useState<NamedReconciliationStack<'unitInsureds'>[]>([]);

  useEffect(() => {
    dispatch(fetchPreDownloadedInsureds());
  }, []);

  const {
    serverEntitiesModified,
    clientEntitiesModified,
    serverEntitiesDeleted,
    clientEntitiesDeleted,
    allClientEntities,
  } = useGetAllNeededReconciliationData(lastOfflineBulkDataDownloadTime, lastOfflineSynchronizationTime, preDownloadedInsureds);

  const modifiedEntitiesCompositeCollection = useConflictDetectedEntities(
    serverEntitiesModified,
    clientEntitiesModified,
    preDownloadedInsureds,
    allClientEntities,
    lastOfflineBulkDataDownloadTime,
    serverEntitiesDeleted,
    clientEntitiesDeleted,
  );

  const handleCancel = (reason?: MuiDialogCloseReason) => {
    if (reason === 'backdropClick') {
      return;
    }

    handleClose();
  };

  const handleInsuredReconciled = (
    clientFiles: Nullable<NamedReconciliationStack<'insureds'>>,
    units: Nullable<NamedReconciliationStack<'unitInsureds'>>,
  ) => {
    if (clientFiles !== null) {
      const newReconciledClientFiles = [...reconciledInsuredClientFiles];
      newReconciledClientFiles.push(clientFiles);
      setReconciledInsuredClientFiles(newReconciledClientFiles);
    }

    if (units !== null) {
      const newReconciledUnits = [...reconciledInsuredUnits];
      newReconciledUnits.push(units);
      setReconciledInsuredUnits(newReconciledUnits);
    }
  };

  const handleSubmitClicked = async () => {
    //Parse reconciled data into related entity reconciliation
    const allReconciledUnitYears = reconciledInsuredUnits.flatMap(u => u.subCollections.unitYears);
    const allReconciledUnitYearAph = allReconciledUnitYears.flatMap(unit => unit.subCollections.unitYearAphs);
    const allReconciledClientFiles = reconciledInsuredClientFiles.flatMap(insured => insured.subCollections.clientFiles);
    const allReconciledQuotes = allReconciledClientFiles.flatMap(clientFile => clientFile.subCollections.quotes);
    const allReconciledScenarios = allReconciledQuotes.flatMap(quote => quote.subCollections.rowCropScenarios);
    const allReconciledRowCropScenarioPieces = allReconciledScenarios.flatMap(scenario => scenario.subCollections.rowCropScenarioPieces);
    const allReconciledForwardSoldScenarioPieces = allReconciledScenarios.flatMap(scenario => scenario.subCollections.forwardSoldScenarioPieces);
    const allReconciledInputCostScenarioPieces = allReconciledScenarios.flatMap(scenario => scenario.subCollections.inputCostScenarioPieces);
    const allReconciledHarvestRevenueScenarioPieces = allReconciledScenarios.flatMap(scenario => scenario.subCollections.harvestRevenueScenarioPieces);

    const allUnitYears = allReconciledUnitYears.map(unit => unit.entity);
    const allUnitYearAph = allReconciledUnitYearAph.map(aph => aph.entity);
    const allQuickUnits = allClientEntities?.scenarioQuickUnits.map(quickUnit => quickUnit) ?? [];


    const unitsByInsured = groupBy(allUnitYears, unitYear => unitYear.insuredId);
    const unitYeahAphByUnit = groupBy(allUnitYearAph, aph => aph.unitYearId);
    const clientFilesByInsured = groupBy(allReconciledClientFiles.map(clientFile => clientFile.entity).filter(isInsuredOwnedClientFile), clientFile => clientFile.insuredId);
    const quotesByClientFile = groupBy(allReconciledQuotes.map(quote => quote.entity), quote => quote.clientFileId);
    const scenariosByQuote = groupBy(allReconciledScenarios.map(scenario => scenario.entity), scenario => scenario.quoteId);
    const rowCropScenarioPiecesByScenario = groupBy(allReconciledRowCropScenarioPieces.map(scenarioPiece => scenarioPiece.entity), scenarioPiece => scenarioPiece.scenarioId);

    const scenarioOptionUnitYearsByScenarioOption: Map<ScenarioOptionId, ScenarioOptionUnitYear[]> = new Map();
    const scenarioOptionsByScenario: Map<ScenarioId, ScenarioOption[]> = new Map();
    const scenarioUnitYearAphByScenario: Map<ScenarioId, ScenarioUnitYearAph[]> = new Map();
    const unitGroupsFromServerByScenarioPiece: Map<ScenarioPieceId, UnitGroup[]> = new Map();

    const scenarioQuickUnitsByScenario: Map<ScenarioId, ScenarioQuickUnit> = new Map();

    const getClientData = (scenario: NamedReconciliationStack<'rowCropScenarios'>) => {
      const clientScenarioOptions = clientEntitiesModified?.scenarioOptions ?? [];
      const clientScenarioUnitYearAph = clientEntitiesModified?.scenarioUnitYearAph ?? [];
      const clientScenarioOptionUnitYears = clientEntitiesModified?.scenarioOptionUnitYears ?? [];
      const clientScenarioQuickUnits = clientEntitiesModified?.scenarioQuickUnits ?? [];

      const scenarioOptions = clientScenarioOptions.filter(so => so.scenarioId === scenario.id);
      const scenarioUnitYearAph = clientScenarioUnitYearAph.filter(suya => suya.scenarioId === scenario.id);
      const scenarioQuickUnit = clientScenarioQuickUnits.find(squ => squ.scenarioId === scenario.id) ?? null;

      const scenarioOptionIds = scenarioOptions.map(so => so.scenarioOptionId);
      const scenarioOptionUnitYears = clientScenarioOptionUnitYears.filter(souy => scenarioOptionIds.includes(souy.scenarioOptionId));

      return {
        scenarioOptions,
        scenarioUnitYearAph,
        scenarioOptionUnitYears,
        scenarioQuickUnit,
      };
    };

    const getServerData = (scenario: NamedReconciliationStack<'rowCropScenarios'>) => {
      const scenarioOptions = serverEntitiesModified?.scenarioOptions.filter(so => so.scenarioId === scenario.id) ?? [];
      const scenarioUnitYearAph = serverEntitiesModified?.scenarioUnitYearAph.filter(suya => suya.scenarioId === scenario.id) ?? [];
      const scenarioQuickUnit = serverEntitiesModified?.scenarioQuickUnits.find(squ => squ.scenarioId === scenario.id) ?? null;

      const scenarioOptionIds = scenarioOptions.map(so => so.scenarioOptionId);
      const scenarioOptionUnitYears = serverEntitiesModified?.scenarioOptionUnitYears.filter(souy => scenarioOptionIds.includes(souy.scenarioOptionId)) ?? [];

      return {
        scenarioOptions,
        scenarioUnitYearAph,
        scenarioOptionUnitYears,
        scenarioQuickUnit,
      };
    };

    //Get certain pieces of data from the DB for scenarios since we don't have that reconciled data in the state tree
    // Namely scenario options, scenario option unit years, and scenario unit year aph records
    //TODO: We can't assume that because we have a reconciled scenario that it will have the accompanying scenario options
    // We need to update this section to ensure that we are able to get scenario options (we may have to pull this data from the server and from the client DB)
    // This could also be true for scenario option unit years and scenario unit year aph records
    allReconciledScenarios.forEach(scenario => {
      let result = undefined;

      if (['keep', 'remove', 'non-conflicting'].includes(scenario.reconciliationType)) {
        //Determine which side they came from
        const clientScenarios = clientEntitiesModified?.rowCropScenarios ?? [];
        const clientScenario = clientScenarios.find(s => s.scenarioId === scenario.id);
        const serverScenario = serverEntitiesModified?.rowCropScenarios.find(s => s.scenarioId === scenario.id);

        if (clientScenario !== undefined) {
          result = getClientData(scenario);
        } else if (serverScenario !== undefined) {
          result = getServerData(scenario);
        }
      } else if (scenario.reconciliationType === 'client') {
        //Get the scenario options from the client-side data
        result = getClientData(scenario);
      } else if (scenario.reconciliationType === 'server') {
        //Get the scenario options from the server-side data
        result = getServerData(scenario);
      }

      if (result !== undefined) {
        scenarioOptionsByScenario.set(scenario.id, result.scenarioOptions);
        scenarioUnitYearAphByScenario.set(scenario.id, result.scenarioUnitYearAph);

        const groupedScenarioOptionUnitYears = groupBy(result.scenarioOptionUnitYears, souy => souy.scenarioOptionId);
        groupedScenarioOptionUnitYears.forEach((value, key) => scenarioOptionUnitYearsByScenarioOption.set(key, value));

        const applicableQuickUnit = allQuickUnits.find(quickUnit => quickUnit.scenarioId === scenario.id);
        if (applicableQuickUnit !== undefined) {
          scenarioQuickUnitsByScenario.set(scenario.id, applicableQuickUnit);
        }
      }
    });

    //TODO: update this so we actually look at the scenario options from the server as opposed to never removing any options
    const scenarioOptionsFromServerByScenario = scenarioOptionsByScenario;
    //TODO: still need to set the unit groups from server

    const relationalEntityReconciliationParams: RelationalEntityReconciliationParams = {
      clientFiles: clientFilesByInsured,
      ledgerScenarioPieces: new Map(),
      matrices: new Map(),
      quotes: quotesByClientFile,
      rowCropScenarioPieces: rowCropScenarioPiecesByScenario,
      rowCropScenarios: scenariosByQuote,
      scenarioMatrices: new Map(),
      scenarioOptionUnitYears: scenarioOptionUnitYearsByScenarioOption,
      scenarioOptions: scenarioOptionsByScenario,
      scenarioOptionsFromServer: scenarioOptionsFromServerByScenario,
      scenarioUnitYearAph: scenarioUnitYearAphByScenario,
      unitGroupsFromServer: unitGroupsFromServerByScenarioPiece,
      unitYearAph: unitYeahAphByUnit,
      units: unitsByInsured,
      quickUnits: scenarioQuickUnitsByScenario,
    };

    const reconciledEntities = reconcileRelationalEntities(relationalEntityReconciliationParams);

    //Parse to format for API

    const clientCreatedClientFileIds = clientEntitiesModified?.clientFiles.filter(cf => cf.offlineCreatedOn !== undefined).map(cf => cf.clientFileId) ?? [];
    const clientCreatedQuoteIds = clientEntitiesModified?.quotes.filter(q => q.offlineCreatedOn !== undefined).map(q => q.quoteId) ?? [];
    const clientCreatedRowCropScenarioPieceIds = clientEntitiesModified?.rowCropScenarioPieces.filter(rcsp => rcsp.offlineCreatedOn !== undefined).map(rcsp => rcsp.scenarioPieceId) ?? [];
    const clientCreatedForwardSoldScenarioPieceIds = clientEntitiesModified?.forwardSoldScenarioPieces.filter(fssp => fssp.offlineCreatedOn !== undefined).map(fssp => fssp.scenarioPieceId) ?? [];
    const clientCreatedInputCostScenarioPieceIds = clientEntitiesModified?.inputCostScenarioPieces.filter(icsp => icsp.offlineCreatedOn !== undefined).map(icsp => icsp.scenarioPieceId) ?? [];
    const clientCreatedHarvestRevenueScenarioPieceIds = clientEntitiesModified?.harvestRevenueScenarioPieces.filter(hrsp => hrsp.offlineCreatedOn !== undefined).map(hrsp => hrsp.scenarioPieceId) ?? [];
    const clientCreatedRowCropScenarioIds = clientEntitiesModified?.rowCropScenarios.filter(rcs => rcs.offlineCreatedOn !== undefined).map(rcs => rcs.scenarioId) ?? [];
    const clientCreatedUnitYearAphIds = clientEntitiesModified?.unitYearAph.filter(uya => uya.offlineCreatedOn !== undefined).map(uya => uya.unitYearAphId) ?? [];
    const clientCreatedUnitYearIds = clientEntitiesModified?.unitYears.filter(uy => uy.offlineCreatedOn !== undefined).map(uy => uy.unitYearId) ?? [];
    const clientCreatedQuickUnitIds = clientEntitiesModified?.scenarioQuickUnits.filter(qu => qu.offlineCreatedOn !== undefined).map(qu => qu.scenarioQuickUnitId) ?? [];

    const clientDeletedClientFileIds = clientEntitiesDeleted?.clientFiles.map(cf => cf.clientFileId) ?? [];
    const clientDeletedQuoteIds = clientEntitiesDeleted?.quotes.map(q => q.quoteId) ?? [];
    const clientDeletedRowCropScenarioPieceIds = clientEntitiesDeleted?.rowCropScenarioPieces.map(rcsp => rcsp.scenarioPieceId) ?? [];
    const clientDeletedForwardSoldScenarioPieceIds = clientEntitiesDeleted?.forwardSoldScenarioPieces.map(fssp => fssp.scenarioPieceId) ?? [];
    const clientDeletedInputCostScenarioPieceIds = clientEntitiesDeleted?.inputCostScenarioPieces.map(icsp => icsp.scenarioPieceId) ?? [];
    const clientDeletedHarvestRevenueScenarioPieceIds = clientEntitiesDeleted?.harvestRevenueScenarioPieces.map(hrsp => hrsp.scenarioPieceId) ?? [];
    const clientDeletedRowCropScenarioIds = clientEntitiesDeleted?.rowCropScenarios.map(rcs => rcs.scenarioId) ?? [];
    const clientDeletedUnitYearAphIds = clientEntitiesDeleted?.unitYearAph.map(uya => uya.unitYearAphId) ?? [];
    const clientDeletedUnitYearIds = clientEntitiesDeleted?.unitYears.map(uy => uy.unitYearId) ?? [];


    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const isPreExistingAndDeletedOffline = <U,>(clientCreatedIds: U[], clientDeletedIds: U[], entity: ReconciliationTrackedEntity<any, U, any, unknown>) => {
      return (!clientCreatedIds.includes(entity.id) && clientDeletedIds.includes(entity.id));
    };

    // This general filter is done in a few places when determining new records to send to the API.
    // Start with all records (online and offline)
    // 1) Include anything that was created offline and that the user wants to keep
    // 2) Exclude records that the user wants to keep which were pre-existing but deleted in the user's offline session (nothing to add - already exists in DB)
    // 3) Exclude anything that was modified on the server since last sync/pull (nothing to add)
    const newRowCropScenarios = allReconciledScenarios.filter(rcs => rcs.reconciliationType === 'keep' && (clientCreatedRowCropScenarioIds.includes(rcs.id) || !isPreExistingAndDeletedOffline(clientCreatedRowCropScenarioIds, clientDeletedRowCropScenarioIds, rcs) || (serverEntitiesDeleted?.rowCropScenarioIds.includes(rcs.id) ?? false)) && !(serverEntitiesModified?.rowCropScenarios.map(rcs => rcs.scenarioId).includes(rcs.id) ?? false)).map(rcs => rcs.entity);

    const allScenarioQuickUnits = Array.from(scenarioQuickUnitsByScenario.values());

    // QuickUnits added while offline or QuickUnits that belong to a pre-existing scenario which was deleted online but was selected to keep when reconciling
    const quickUnitsToAdd = allScenarioQuickUnits.filter(quickUnit => (clientCreatedQuickUnitIds.includes(quickUnit.scenarioQuickUnitId) || (serverEntitiesDeleted?.rowCropScenarioIds.includes(quickUnit.scenarioId) ?? false)) && newRowCropScenarios.map(scenario => scenario.scenarioId).includes(quickUnit.scenarioId));
    const newScenarioQuickUnits = [...reconciledEntities.scenarioQuickUnits, ...quickUnitsToAdd];
    const updatedScenarioQuickUnits = allScenarioQuickUnits.filter(quickUnit => !newScenarioQuickUnits.some(qu => qu.scenarioQuickUnitId === quickUnit.scenarioQuickUnitId) && !clientDeletedRowCropScenarioIds.includes(quickUnit.scenarioId));

    // The data that should be added to the server is that which has a reconciliation type of "keep",
    // except for entities that were deleted on the client-side (if they were both created and deleted while offline, we should add them),
    // or entities that were added on the server-side
    const newData: BulkInsuredReconciliationData = {
      clientFiles: allReconciledClientFiles.filter(cf => cf.reconciliationType === 'keep' && (clientCreatedClientFileIds.includes(cf.id) || !isPreExistingAndDeletedOffline(clientCreatedClientFileIds, clientDeletedClientFileIds, cf) || (serverEntitiesDeleted?.clientFileIds.includes(cf.id) ?? false)) && !(serverEntitiesModified?.clientFiles.map(cf => cf.clientFileId).includes(cf.id) ?? false)).map(cf => cf.entity),
      historicalAnalyses: [], //Reconciliation of various scenario analyses is not supported right now
      matrices: [], //Reconciliation of various scenario analyses is not supported right now
      premiumBreakdowns: [], //Reconciliation of various scenario analyses is not supported right now
      quotes: allReconciledQuotes.filter(q => q.reconciliationType === 'keep' && (clientCreatedQuoteIds.includes(q.id) || !isPreExistingAndDeletedOffline(clientCreatedQuoteIds, clientDeletedQuoteIds, q) || (serverEntitiesDeleted?.quoteIds.includes(q.id) ?? false)) && !(serverEntitiesModified?.quotes.map(q => q.quoteId).includes(q.id) ?? false)).map(q => q.entity),
      rowCropScenarioPieces: allReconciledRowCropScenarioPieces.filter(rcsp => rcsp.reconciliationType === 'keep' && (clientCreatedRowCropScenarioPieceIds.includes(rcsp.id) || !isPreExistingAndDeletedOffline(clientCreatedRowCropScenarioPieceIds, clientDeletedRowCropScenarioPieceIds, rcsp) || (serverEntitiesDeleted?.rowCropScenarioPieceIds.includes(rcsp.id) ?? false)) && !(serverEntitiesModified?.rowCropScenarioPieces.map(rcsp => rcsp.scenarioPieceId).includes(rcsp.id) ?? false)).map(rcsp => rcsp.entity),
      forwardSoldScenarioPieces: allReconciledForwardSoldScenarioPieces.filter(fssp => fssp.reconciliationType === 'keep' && (clientCreatedForwardSoldScenarioPieceIds.includes(fssp.id) || !isPreExistingAndDeletedOffline(clientCreatedForwardSoldScenarioPieceIds, clientDeletedForwardSoldScenarioPieceIds, fssp) || (serverEntitiesDeleted?.forwardSoldScenarioPieceIds.includes(fssp.id) ?? false)) && !(serverEntitiesModified?.forwardSoldScenarioPieces.map(fssp => fssp.scenarioPieceId).includes(fssp.id) ?? false)).map(fssp => fssp.entity),
      inputCostScenarioPieces: allReconciledInputCostScenarioPieces.filter(icsp => icsp.reconciliationType === 'keep' && (clientCreatedInputCostScenarioPieceIds.includes(icsp.id) || !isPreExistingAndDeletedOffline(clientCreatedInputCostScenarioPieceIds, clientDeletedInputCostScenarioPieceIds, icsp) || (serverEntitiesDeleted?.inputCostScenarioPieceIds.includes(icsp.id) ?? false)) && !(serverEntitiesModified?.inputCostScenarioPieces.map(icsp => icsp.scenarioPieceId).includes(icsp.id) ?? false)).map(icsp => icsp.entity),
      harvestRevenueScenarioPieces: allReconciledHarvestRevenueScenarioPieces.filter(hrsp => hrsp.reconciliationType === 'keep' && (clientCreatedHarvestRevenueScenarioPieceIds.includes(hrsp.id) || !isPreExistingAndDeletedOffline(clientCreatedHarvestRevenueScenarioPieceIds, clientDeletedHarvestRevenueScenarioPieceIds, hrsp) || (serverEntitiesDeleted?.harvestRevenueScenarioPieceIds.includes(hrsp.id) ?? false)) && !(serverEntitiesModified?.harvestRevenueScenarioPieces.map(hrsp => hrsp.scenarioPieceId).includes(hrsp.id) ?? false)).map(hrsp => hrsp.entity),
      rowCropScenarios: newRowCropScenarios,
      scenarioOptionUnitYears: [],  //Scenario option unit years is only populated for update. Any records that need to get added go through the server's diffing process there
      scenarioOptions: [],  //Scenario options only need to be populated on delete since the scenario option unit years will create scenario options if they don't exist in the db
      scenarioQuickUnits: newScenarioQuickUnits,
      scenarioUnitYearAph: [],  //Any new records here are added via the update method
      trendlineAnalyses: [], //Reconciliation of various scenario analyses is not supported right now
      unitGroups: reconciledEntities.unitGroupsToAdd,
      unitYearAph: allReconciledUnitYearAph.filter(uya => uya.reconciliationType === 'keep' && (clientCreatedUnitYearAphIds.includes(uya.id) || !isPreExistingAndDeletedOffline(clientCreatedUnitYearAphIds, clientDeletedUnitYearAphIds, uya) || (serverEntitiesDeleted?.unitYearAphIds.includes(uya.id) ?? false)) && !(serverEntitiesModified?.unitYearAph.map(uya => uya.unitYearAphId).includes(uya.id) ?? false)).map(uya => uya.entity),
      unitYears: allReconciledUnitYears.filter(uy => uy.reconciliationType === 'keep' && (clientCreatedUnitYearIds.includes(uy.id) || !isPreExistingAndDeletedOffline(clientCreatedUnitYearIds, clientDeletedUnitYearIds, uy) || (serverEntitiesDeleted?.unitYearIds.includes(uy.id) ?? false)) && !(serverEntitiesModified?.unitYears.map(uy => uy.unitYearId).includes(uy.id) ?? false)).map(uy => uy.entity),
    };

    const updatedData: BulkInsuredReconciliationData = {
      clientFiles: allReconciledClientFiles.filter(cf => ['client', 'server', 'non-conflicting'].includes(cf.reconciliationType)).map(cf => cf.entity),
      historicalAnalyses: [], //Reconciliation of various scenario analyses is not supported right now
      matrices: [], //Reconciliation of various scenario analyses is not supported right now
      premiumBreakdowns: [], //Reconciliation of various scenario analyses is not supported right now
      quotes: allReconciledQuotes.filter(q => ['client', 'server', 'non-conflicting'].includes(q.reconciliationType)).map(q => q.entity),
      rowCropScenarioPieces: allReconciledRowCropScenarioPieces.filter(rcsp => ['client', 'server', 'non-conflicting'].includes(rcsp.reconciliationType)).map(rcsp => rcsp.entity),
      forwardSoldScenarioPieces: allReconciledForwardSoldScenarioPieces.filter(fssp => ['client', 'server', 'non-conflicting'].includes(fssp.reconciliationType)).map(fssp => fssp.entity),
      inputCostScenarioPieces: allReconciledInputCostScenarioPieces.filter(icsp => ['client', 'server', 'non-conflicting'].includes(icsp.reconciliationType)).map(icsp => icsp.entity),
      harvestRevenueScenarioPieces: allReconciledHarvestRevenueScenarioPieces.filter(hrsp => ['client', 'server', 'non-conflicting'].includes(hrsp.reconciliationType)).map(hrsp => hrsp.entity),
      rowCropScenarios: allReconciledScenarios.filter(s => ['client', 'server', 'non-conflicting'].includes(s.reconciliationType)).map(s => s.entity),
      scenarioOptionUnitYears: reconciledEntities.scenarioOptionUnitYearsToKeep,
      scenarioOptions: [],  //Scenario options only need to be populated on delete since the scenario option unit years will create scenario options if they don't exist in the db
      scenarioQuickUnits: updatedScenarioQuickUnits,
      scenarioUnitYearAph: Array.from(reconciledEntities.scenarioUnitYearAph.values()).flat(),
      trendlineAnalyses: [], //Reconciliation of various scenario analyses is not supported right now
      unitGroups: reconciledEntities.unitGroupsToUpdate,
      unitYearAph: allReconciledUnitYearAph.filter(uya => ['client', 'server', 'non-conflicting'].includes(uya.reconciliationType)).map(uya => uya.entity),
      unitYears: allReconciledUnitYears.filter(uy => ['client', 'server', 'non-conflicting'].includes(uy.reconciliationType)).map(uy => uy.entity),
    };

    //The data that should be removed from the server is that which has a reconciliation type of "remove", except for entities that were deleted on the server-side, or entities that were created on the client-side
    const deletedData: BulkInsuredReconciliationData = {
      clientFiles: allReconciledClientFiles.filter(cf => cf.reconciliationType === 'remove' && !(serverEntitiesDeleted?.clientFileIds.includes(cf.id) ?? false) && !clientCreatedClientFileIds.includes(cf.id)).map(cf => cf.entity),
      historicalAnalyses: [], //Reconciliation of various scenario analyses is not supported right now
      matrices: [], //Reconciliation of various scenario analyses is not supported right now
      premiumBreakdowns: [], //Reconciliation of various scenario analyses is not supported right now
      quotes: allReconciledQuotes.filter(q => q.reconciliationType === 'remove' && !(serverEntitiesDeleted?.quoteIds.includes(q.id) ?? false) && !clientCreatedQuoteIds.includes(q.id)).map(q => q.entity),
      rowCropScenarioPieces: allReconciledRowCropScenarioPieces.filter(rcsp => rcsp.reconciliationType === 'remove' && !(serverEntitiesDeleted?.rowCropScenarioPieceIds.includes(rcsp.id) ?? false) && !clientCreatedRowCropScenarioPieceIds.includes(rcsp.id)).map(rcsp => rcsp.entity),
      forwardSoldScenarioPieces: allReconciledForwardSoldScenarioPieces.filter(fssp => fssp.reconciliationType === 'remove' && !(serverEntitiesDeleted?.forwardSoldScenarioPieceIds.includes(fssp.id) ?? false) && !clientCreatedForwardSoldScenarioPieceIds.includes(fssp.id)).map(fssp => fssp.entity),
      inputCostScenarioPieces: allReconciledInputCostScenarioPieces.filter(icsp => icsp.reconciliationType === 'remove' && !(serverEntitiesDeleted?.inputCostScenarioPieceIds.includes(icsp.id) ?? false) && !clientCreatedInputCostScenarioPieceIds.includes(icsp.id)).map(icsp => icsp.entity),
      harvestRevenueScenarioPieces: allReconciledHarvestRevenueScenarioPieces.filter(hrsp => hrsp.reconciliationType === 'remove' && !(serverEntitiesDeleted?.harvestRevenueScenarioPieceIds.includes(hrsp.id) ?? false) && !clientCreatedHarvestRevenueScenarioPieceIds.includes(hrsp.id)).map(hrsp => hrsp.entity),
      rowCropScenarios: allReconciledScenarios.filter(rcs => rcs.reconciliationType === 'remove' && !(serverEntitiesDeleted?.rowCropScenarioIds.includes(rcs.id) ?? false) && !clientCreatedRowCropScenarioIds.includes(rcs.id)).map(rcs => rcs.entity),
      scenarioOptionUnitYears: [],  //Scenario option unit years is only populated for update. Any records that need to get added go through the server's diffing process there
      scenarioOptions: reconciledEntities.scenarioOptionsToRemove,
      scenarioQuickUnits: [], //Scenario quick units will be deleted as part of the cascade delete for scenarios and shouldn't be removed separately
      scenarioUnitYearAph: [],  //We don't delete scenario unit year APH records
      trendlineAnalyses: [], //Reconciliation of various scenario analyses is not supported right now
      unitGroups: reconciledEntities.unitGroupsToDelete,
      unitYearAph: allReconciledUnitYearAph.filter(uya => uya.reconciliationType === 'remove' && !(serverEntitiesDeleted?.unitYearAphIds.includes(uya.id) ?? false) && !clientCreatedUnitYearAphIds.includes(uya.id)).map(uya => uya.entity),
      unitYears: allReconciledUnitYears.filter(uy => uy.reconciliationType === 'remove' && !(serverEntitiesDeleted?.unitYearIds.includes(uy.id) ?? false) && !clientCreatedUnitYearIds.includes(uy.id)).map(uy => uy.entity),
    };

    const offlineReconciliationData: OfflineReconciliationData = {
      newData: newData,
      updatedData: updatedData,
      removedData: deletedData,
    };

    //Submit to API and wait
    await syncOfflineChanges(offlineReconciliationData);

    //Delete current offline data
    // Just deleting the insured info because when they go to pull insured info
    // again, it'll download new ADM data that will override the old
    await clearOutExistingInsuredDataRequest();

    await bringOnline();
  };

  const bringOnline = async () => {
    // Go back online and set the last date we synchronized
    dispatch(shouldForceOfflineUpdated(true));
    dispatch(setLastOfflineSynchronizationTime(new Date().toISOString()));

    // Redux persist updates local storage asynchronously but does not currently include notifications when it is done updating.
    // If local storage has not been updated to include userNetworkEnabled: true, the user will still be offline after the redirect below.
    // "Sleeping" for a second should give enough time for local storage to update, but there is no guarantee.
    // There are other options to solve this problem allowing us to know local storage was updated, but they add various degrees of complexity.
    // This was decided to be an acceptable solution for now
    await sleep(1000);

    // Redirect back to the dashboard
    // This forces the redux store to reset, which ensures users do no interact with stale data.
    // When they go back to where they were, it'll pull the new, updated data
    window.location.href = '/';
  };

  const entitiesThatNeedReconciled = modifiedEntitiesCompositeCollection === undefined
    ? null
    : [...modifiedEntitiesCompositeCollection.insureds.prunedCombinedEntityState, ...modifiedEntitiesCompositeCollection.unitInsureds.prunedCombinedEntityState];

  const idsOfEntitiesThatHaveBeenReconciled = new Set([...reconciledInsuredUnits, ...reconciledInsuredClientFiles].map(entity => entity.id));

  const entitiesToReconcile = entitiesThatNeedReconciled?.filter(entity => !idsOfEntitiesThatHaveBeenReconciled.has(entity.id)) ?? null;

  return (
    <Dialog open={isOpen} onClose={(_, reason) => handleCancel(reason)} maxWidth="sm" fullWidth disableEscapeKeyDown>
      <DialogTitle sx={{ backgroundColor: theme => theme.palette.primary.main }}>
        <Typography color="white">Data Conflicts</Typography>
      </DialogTitle>
      <DialogContent>
        <DataConflictList
          handleReconcileButtonClick={setSelectedInsured}
          handleCancel={handleCancel}
          conflictState={entitiesToReconcile}
          handleSubmitClicked={handleSubmitClicked}
        />
      </DialogContent>
      <InsuredReconciliationDialog
        isOpen={selectedInsured !== null}
        handleClose={() => setSelectedInsured(null)}
        selectedInsured={selectedInsured}
        clientFilesClientState={modifiedEntitiesCompositeCollection?.insureds.prunedClientEntityState ?? []}
        clientFilesServerState={modifiedEntitiesCompositeCollection?.insureds.prunedServerEntityState ?? []}
        clientFilesReconciledState={modifiedEntitiesCompositeCollection?.insureds.prunedCombinedEntityState ?? []}
        unitsClientState={modifiedEntitiesCompositeCollection?.unitInsureds.prunedClientEntityState ?? []}
        unitsServerState={modifiedEntitiesCompositeCollection?.unitInsureds.prunedServerEntityState ?? []}
        unitsReconciledState={modifiedEntitiesCompositeCollection?.unitInsureds.prunedCombinedEntityState ?? []}
        handleInsuredReconciled={handleInsuredReconciled}
      />
    </Dialog>
  );
};

export default ReconciliationDialog;