import { UiCellTextColorRule } from '../types';
import { nextUiColorId } from './uiColorRuleId';

export const buildComponentDefaultColorList = (): UiCellTextColorRule[] => [
  {
    threshold: 200,
    color: 'green',
    id: nextUiColorId(),
  },
  {
    threshold: 0,
    color: 'yellow',
    id: nextUiColorId(),
  },
  {
    threshold: -300,
    color: 'red',
    id: nextUiColorId(),
  },
];