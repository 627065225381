import EntityDiff from '../entityDiffing/entityDiff';
import { getFriendlyScenarioPieceName } from '../../../utils/scenarioPieceUtils';
import DataLocationType from '../constants/dataLocationType';
import { NamedReconciliationStack } from '../../../types/app/ReconciliationTrackedEntity';

type StackType = NamedReconciliationStack<'forwardSoldScenarioPieces'>;

interface Props {
  forwardSoldScenarioPiece: StackType;
  reconciledForwardSoldScenarioPiece: StackType
  onReconciliationTypeChange: (entity: StackType) => void;
  dataLocation: DataLocationType;
}

export const ForwardSoldScenarioPieceDiff = ({ forwardSoldScenarioPiece, reconciledForwardSoldScenarioPiece, onReconciliationTypeChange, dataLocation }: Props) => {
  const scenarioPiece = forwardSoldScenarioPiece.entity;

  const detailComponents = [
    getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType),
    scenarioPiece.marketingYield.toString(),
  ];

  return (
    <EntityDiff
      title="Forward Sold Scenario Pieces"
      subEntitySelector={() => []}
      trackedEntity={reconciledForwardSoldScenarioPiece}
      subsetEntity={forwardSoldScenarioPiece}
      detailComponents={detailComponents}
      onReconciliationTypeChange={onReconciliationTypeChange}
      preventCollapse
      dataLocation={dataLocation}
      preventSelection
      hideHeader
    />
  );
};