import { useState } from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { formatCurrency } from '../../../../utils/formatNumbers';
import { parseNumber } from '../../../../utils/stringUtils';

export interface InputCostEntryPerAcreCostProps {
  index: number;
}

const InputCostEntryPerAcreCost = ({ index }: InputCostEntryPerAcreCostProps) => {
  const { control } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);

  // This function is responsible for converting the actual value to the display format
  const formatDisplayValue = (value: string) => {
    const parsedNumber = parseNumber(value);
    const formattedNumber = formatCurrency(parsedNumber);
    return `${formattedNumber}`;
  };

  // Handle focus and blur events to switch between display and edit modes
  const handleFocus = () => setIsFocused(true);

  return (
    <>
      <Controller
        name={`inputCosts.${index}.perAcreCost`}
        control={control}
        rules={{
          required: VALIDATION_RULES.required(),
        }}
        render={({
          field: { ref, ...field }, fieldState: { error },
        }) => (
          <TextField
            {...field}
            // When the field is not focused, display the formatted value
            value={isFocused ? field.value : formatDisplayValue(field.value)}
            onFocus={handleFocus}
            onBlur={event => {
              const cleanedValue = parseNumber(event.target.value);
              field.onChange(cleanedValue);
              setIsFocused(false);
            }}
            error={!!error}
            helperText={error?.message}
            size="small"
            required
            type="text"
            sx={{
              '& .MuiFormHelperText-root': {
                marginLeft: 0,
                marginRight: 0,
              },
            }}
          />
        )}
      />
    </>
  );
};

export default InputCostEntryPerAcreCost;
