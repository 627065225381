import { DefaultFormSelection, FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import TabbedFormWrapper from '../../../components/formWrapper/tabbedFormWrapper.component';
import { QuoteId, ScenarioId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import HistoricalAnalysisColumnToggleForm from './historicalAnalysisColumnToggleForm';
import HistoricalQuoteForm from './historicalQuoteForm.component';

export type HistoricalFormWrapperProps = {
  primaryScenarioId: Nullable<ScenarioId>;
  quoteId: QuoteId;
  defaultFormSelection?: DefaultFormSelection;
}

const HistoricalFormWrapper = ({ primaryScenarioId, quoteId, defaultFormSelection }: HistoricalFormWrapperProps) => {
  const forms = [
    { formName: 'Settings', formComponent: (props: FormWrapperProps) => <HistoricalQuoteForm primaryScenarioId={primaryScenarioId} key={primaryScenarioId} {...props} /> },
    { formName: 'Show/Hide', formComponent: (props: FormWrapperProps) => <HistoricalAnalysisColumnToggleForm key={primaryScenarioId} {...props} /> },
  ];

  return (
    <TabbedFormWrapper defaultFormSelection={defaultFormSelection} forms={forms} key={quoteId} />
  );
};

export default HistoricalFormWrapper;