import { ScenarioPieceSelectedInterval } from '../../types/api/adm/ScenarioPieceSelectedInterval';
import { ScenarioId, ScenarioPieceId } from '../../types/api/PrimaryKeys';
import { networkOnly } from '../../utils/cachingStrategies';
import { setSelectedIntervals, getAvailableIntervals, getSelectedIntervals, getIntervalPrices } from '../intervals.service';
import { IntervalRange } from '../../types/api/adm/IntervalRange';
import { IntervalPrice } from '../../types/api/adm/IntervalPrice';

export const getSelectedIntervalsRequest = async (scenarioIds: ScenarioId[]): Promise<ScenarioPieceSelectedInterval[]> => {
  const request = async () => await getSelectedIntervals(scenarioIds);
  const strategy = networkOnly;
  return strategy(request);
};

export interface SetIntervalsProps {
  scenarioPieceSelectedIntervals: ScenarioPieceSelectedInterval[];
  scenarioPieceId: ScenarioPieceId
}
export const setSelectedIntervalsRequest = async (props: SetIntervalsProps): Promise<SetIntervalsProps> => {
  await setSelectedIntervals(props.scenarioPieceSelectedIntervals, props.scenarioPieceId);
  //TODO: Offline logic
  //return await addSingle(table, quote, request);
  return props;
};

export const getAvailableIntervalsRequest = async (onlyNew: boolean): Promise<IntervalRange[]> => {
  const request = async () => await getAvailableIntervals(onlyNew);
  const strategy = networkOnly;
  return strategy(request);
};

export const getIntervalPricesRequest = async (symbols: string[]): Promise<IntervalPrice[]> => {
  const request = async () => await getIntervalPrices(symbols);
  const strategy = networkOnly;
  return strategy(request);
};