import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import BaseUnit from '../../../types/api/BaseUnit';
import {
  CoverageTypeCode,
  RevBoostIndemnityParams,
  RevBoostManualPremiumParams,
  RevBoostRequestDTO,
  RevBoostUnit, Unit
} from '@silveus/calculations';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getUnitsForScenarioPiece } from '../calculationUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';

export const createRevBoostData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createRevBoostDto(scenarioPiece, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformRevBoostData = (scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece): RevBoostManualPremiumParams & RevBoostIndemnityParams => {
  const revBoostParams: RevBoostManualPremiumParams & RevBoostIndemnityParams = {
    coverageTypeCode: CoverageTypeCode.A,
    harvestPrice: scenario.harvestPrice ?? 0,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    premiumPerAcre: scenarioPiece.rowCropScenarioPieceExtendedData?.customPremium ?? 0,
    projectedPrice: scenario.projectedPrice ?? 0,
    unitStructureCode: scenarioPiece.unitStructure,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
  };

  return revBoostParams;
};

const createRevBoostUnits = (scenarioPiece: RowCropScenarioPiece, units: BaseUnit[], scenario: RowCropScenario): (RevBoostUnit & Unit)[] => {
  const revBoostUnits = units.map(u => {
    const unitDto = createBaseUnit(u);
    const revBoostUnit: RevBoostUnit & Unit = {
      ...unitDto,
      id: u.id,
    };

    return revBoostUnit;
  });

  return revBoostUnits;
};

const createRevBoostDto = (scenarioPiece: RowCropScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): RevBoostRequestDTO => {
  const revBoostRequest: RevBoostRequestDTO = {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createRevBoostUnits(
        scenarioPiece,
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformRevBoostData(scenario, scenarioPiece),
  };

  return revBoostRequest;
};
