import { FormProvider, SubmitErrorHandler, SubmitHandler } from 'react-hook-form';
import { ApplicationWizard } from '../../../../types/api/applicationWizard/applicationWizard';
import ApplicationWizardPage from '../applicationWizardPage';
import { ApplicationWizardStep, ApplicationWizardStepProps } from '../applicationWizardStep';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ApplicationDecisionGrid from './applicationDecisionGrid';
import { AppTask } from '../../../../types/api/AppTask';
import { useAppTasks } from './useAppTasks';
import { SBIInfoStep } from '../sbiInfoStep';
import { PersonOfInterest } from '../../../../types/api/insureds/PersonOfInterest';
import { SpouseInfoStep } from '../spouseInfoStep';
import { POAAuthRepStep } from '../poaAuthRepStep';
import { AuthToSignStep } from '../authToSignStep';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../../hooks/reduxHooks';
import { selectAllPOIsByInsuredIdMap } from '../../../../app/personOfInterestSlice';
import { PersonOfInterestType } from '../../../../types/api/enums/contactInfo/personOfInterestType';
import { validateAppTasks } from './validations/appTaskValidator';
import AppTaskErrorsModal from './appTaskErrorsModal';
import { useEffect, useState } from 'react';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../../utils/nullHandling';
import { ConfirmStateContent, openConfirm } from '../../../../app/confirmSlice';
import CommitNoChangeDifferencesModal, { CommitNoChangeDifferencesModalProps } from './commitNoChangeDifferencesModal';
import UnmatchedAppTasksModal from './unmatchedAppTasksModal';
import { AppType } from '../../../../types/api/enums/application/AppType.enum';
import { distinct } from '@silveus/calculations';
import { submitApplication } from '../../../../app/applicationsSlice';
import { validateCommitNoChangeAppTasks } from '../../../../services/appTasks.service';
import { Nullable } from '../../../../types/util/Nullable';
import { selectAdmData } from '../../../../app/admSlice';
import { selectInsuredById } from '../../../../app/insuredsSlice';

export const ApplicationDecisionStep: ApplicationWizardStep = {
  isDisabled(app: ApplicationWizard) {
    // ToDo: Last page will be disabled based on some upcoming validation rules for the entire app wizard
    // We will need to add those rules here once they are set in stone, but for now its okay for us to get
    // to this page so we can test saving app tasks.
    return false;
  },
  title: 'Application Decision',
  index: 10,
  formId: 'form-app-decision',
  icon: <CheckCircleOutlineOutlinedIcon />,
  isValid(app: ApplicationWizard) {
    return {
      isValid: true,
      errors: [],
    };
  },
};

export type AppTaskFields = { appTasks: AppTask[] };

const ApplicationDecisionPage = ({ navigateToPreviousStep, application }: ApplicationWizardStepProps) => {
  const rootState = useAppSelector(s => s);
  const admData = useAppSelector(selectAdmData);
  const [appTaskErrors, setAppTaskErrors] = useState<Record<string, string>>({});
  const [unmatchedAppTasks, setUnmatchedAppTasks] = useState<AppTask[]>([]);
  const insuredId = (application.isNewEntity && isNotNullOrUndefined(application.newInsuredId)) ? application.newInsuredId : application.insuredId;
  const insured = useAppSelector(state => selectInsuredById(state, insuredId));
  const allPersonsOfInterest = useKeyMapSelector(selectAllPOIsByInsuredIdMap, insuredId);
  const { appTasks, methods, loading, appTaskLandlordAndTenants, updateLandlordsAndTenants, d365AppTasks, agencies } = useAppTasks(application, admData, insured);
  const [applyingChanges, setApplyingChanges] = useState(false);
  const [cncProps, setCncProps] = useState<Nullable<CommitNoChangeDifferencesModalProps>>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isNotNullOrUndefined(d365AppTasks)) {
      const matchedAppTasks = appTasks.map(a => a.appTaskId);
      const filteredAppTasks = d365AppTasks.filter(a => !matchedAppTasks.includes(a.appTaskId));
      setUnmatchedAppTasks(filteredAppTasks);
    }
  }, [appTasks, d365AppTasks]);

  const applyChangesToD365: SubmitHandler<AppTaskFields> = async data => {
    const errors = validateAppTasks(data.appTasks, application, appTaskLandlordAndTenants);
    if (Object.keys(errors).length > 0) {
      setAppTaskErrors(errors);
      return;
    }

    const cncAppTasks = data.appTasks.filter(a => a.appType === AppType.CommitNoChange);
    if (cncAppTasks.length > 0) {
      setApplyingChanges(true);
      const differences = await validateCommitNoChangeAppTasks(cncAppTasks);
      setApplyingChanges(false);
      setCncProps({
        appTasks: cncAppTasks,
        differences: differences,
        onClose: () => setCncProps(null),
        onContinue: () => submitApplicationToD365(data.appTasks),
      });
    } else {
      submitApplicationToD365(data.appTasks);
    }
  };

  const submitApplicationToD365 = async (appTasksToSave: AppTask[]) => {
    const appTasksToSubmit = [...appTasksToSave];
    for (const appTask of appTasksToSubmit) {
      // We track scenario ids with app task coverages while we are making changes in the app decisions page
      // because it makes moving coverages from app tasks to other app tasks much easier. But before we submit
      // to D365 we want to roll those scenario ids up to the app task level from the coverages as they are
      // tracked on the back end at the app task level.
      const scenarioIds = appTask.coverages.flatMap(x => x.scenarioIds).flat();
      appTask.scenarioIds = distinct(scenarioIds);
      appTask.insuredOtherPersonsOfInterest = getAppTaskPOIs(appTask);
    }
    setApplyingChanges(true);
    await dispatch(submitApplication({ appWizardId: application.applicationWizardId, appTasks: appTasksToSubmit }));
    setApplyingChanges(false);
  };

  const handleSubmitError: SubmitErrorHandler<AppTaskFields> = async data => {
    if (isNullOrUndefined(data.appTasks)) {
      return;
    }
    const appTaskData = methods.getValues().appTasks;
    const errors = validateAppTasks(appTaskData, application, appTaskLandlordAndTenants);
    setAppTaskErrors(errors);
  };

  const getAppTaskPOIs = (appTask: AppTask) => {
    let poisAndAuthReps: PersonOfInterest[] = [];
    if (!SBIInfoStep.isDisabled(application, rootState)) {
      poisAndAuthReps = [...allPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.SBI)];
    }
    if (!SpouseInfoStep.isDisabled(application, rootState)) {
      poisAndAuthReps = [...poisAndAuthReps, ...allPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.Spouse)];
    }

    if (!POAAuthRepStep.isDisabled(application, rootState)) {
      poisAndAuthReps = [...poisAndAuthReps, ...allPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.PowerOfAttorney || x.personOfInterestType === PersonOfInterestType.AuthorizedRep)];
    }

    if (!AuthToSignStep.isDisabled(application, rootState)) {
      poisAndAuthReps = [...poisAndAuthReps, ...allPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.AuthorizedToSign)];
    }

    const { isInsuringLandlordShare, isInsuringTenantShare } = appTask;
    const isInsuringLandlord = Boolean(isInsuringLandlordShare);
    const isInsuringTenant = Boolean(isInsuringTenantShare);

    if (isInsuringLandlord || isInsuringTenant) {
      const landlordsAndTenants = appTaskLandlordAndTenants[appTask.appTaskId];
      if (isNotNullOrUndefined(landlordsAndTenants)) {
        poisAndAuthReps = [...poisAndAuthReps, ...landlordsAndTenants];
      }
    }

    return poisAndAuthReps;
  };

  const handleNavigateToPrevious = () => {
    // In this page, unlike the other pages we do not want to save here. If user navigates back
    // they will lose any changes made on this page. Here we will warn the user if they've made any
    // changes on this page that if they go back they will lose those changes.
    if (methods.formState.isDirty && Object.keys(methods.formState.dirtyFields).length > 0) {
      const confirmWindow: ConfirmStateContent = {
        title: 'Leaving App Decisions Step',
        message: 'Leaving this step prior to applying changes to D365 will result in a loss of work. What would you like to do?',
        confirmText: 'Continue',
        onConfirm: () => navigateToPreviousStep(),
        cancelText: 'Cancel',
      };
      dispatch(openConfirm(confirmWindow));
    } else {
      navigateToPreviousStep();
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form id="form-app-decision" onSubmit={methods.handleSubmit(applyChangesToD365, handleSubmitError)} style={{ height: '100%' }}>
          <ApplicationWizardPage tabIndex={ApplicationDecisionStep.index} onPreviousClick={handleNavigateToPrevious} loading={loading || applyingChanges}>
            {!loading && (
              <ApplicationDecisionGrid
                selectedLandlordsAndTenants={appTaskLandlordAndTenants}
                appTasks={appTasks}
                updateLandlordsAndTenants={updateLandlordsAndTenants}
                application={application}
                agencies={agencies}
              />
            )}
          </ApplicationWizardPage>
        </form>
      </FormProvider>
      {Object.keys(appTaskErrors).length > 0 && (
        <AppTaskErrorsModal onClose={() => setAppTaskErrors({})} errors={appTaskErrors} />
      )}
      {!loading && unmatchedAppTasks.length > 0 && (
        <UnmatchedAppTasksModal onClose={() => setUnmatchedAppTasks([])} appTasks={unmatchedAppTasks} />
      )}
      {isNotNullOrUndefined(cncProps) && (
        <CommitNoChangeDifferencesModal modalProps={cncProps} />
      )}
    </>
  );
};

export default ApplicationDecisionPage;
