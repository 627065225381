import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { modifyScenarioPiece } from '../../../app/scenarioPiecesSlice';
import InactiveScenarioPieceCard from '../inactiveScenarioPieceCard.component';
import { getFarmBillDisplayName } from '../../../services/calculations/farmBill/farmBillHelpers';
import { ScenarioPieceCardProps } from '../../../types/app/ScenarioPieceDefinition';

const FarmBillInactiveRowCropScenarioPieceCard = ({ scenarioPiece, isScenarioActive }: ScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();

  const label = getFarmBillDisplayName(scenarioPiece.scenarioPieceType, scenarioPiece.planCode, true);

  const updateScenarioPiece = async (scenarioPiece: RowCropScenarioPiece) => {
    await dispatch(modifyScenarioPiece({ scenarioPiece: scenarioPiece }));
  };

  return (
    <InactiveScenarioPieceCard
      scenarioPiece={scenarioPiece}
      isScenarioActive={isScenarioActive}
      label={label}
      updateScenarioPiece={updateScenarioPiece}
    />
  );
};

export default FarmBillInactiveRowCropScenarioPieceCard;
