import { ClientFileId, InsuredId, MatrixId, QuoteId, ScenarioId } from '../../types/api/PrimaryKeys';
import { Quote } from '../../types/api/Quote';
import { ClientFile } from '../../types/api/ClientFile';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import ScenarioMatrix from '../../types/api/ScenarioMatrix';
import Matrix from '../../types/api/Matrix';
import { getItemsForId } from '../mapHelpers';

export interface ScenarioMatrixReconciliationParams {
  quotes: Map<ClientFileId, Quote[]>;
  clientFilesMap: Map<InsuredId, ClientFile[]>;
  rowCropScenarios: Map<QuoteId, RowCropScenario[]>;
  scenarioMatrices: Map<MatrixId, ScenarioMatrix[]>;
  matrices: Map<ScenarioId, Matrix[]>;
}

/**
 * Reconciles the relationships between scenarios and matrices
 * @param clientFilesMap - The client files selected by the user, grouped by insured id
 * @param quotes - The quotes selected by the user, grouped by client file id
 * @param rowCropScenarios - The row crop scenarios selected by the user, grouped by quote id
 * @param matrices - The matrices selected by the user, grouped by primary scenario id
 * @param scenarioMatrices - The relationships between scenarios and matrices from the matrices selected by the user, grouped by scenario id
 * @return reconciledScenarioMatrices The relationships between scenarios and matrices that are still valid for input entities, grouped by matrix id
 */
export const getReconciledScenarioMatrices = ({
  clientFilesMap,
  quotes,
  rowCropScenarios,
  matrices,
  scenarioMatrices,
}: ScenarioMatrixReconciliationParams): Map<MatrixId, ScenarioMatrix[]> => {
  const reconciledScenarioMatrices = new Map<MatrixId, ScenarioMatrix[]>();

  clientFilesMap.forEach(clientFiles => {
    clientFiles.forEach(clientFile => {
      const applicableQuotes = getItemsForId(quotes, clientFile.clientFileId);

      applicableQuotes.forEach(quote => {
        const applicableScenarios = getItemsForId(rowCropScenarios, quote.quoteId);
        const scenarioIds = applicableScenarios.map(s => s.scenarioId);

        applicableScenarios.forEach(scenario => {
          const applicableMatrices = getItemsForId(matrices, scenario.scenarioId);

          applicableMatrices.forEach(matrix => {
            const applicableScenarioMatrices = getItemsForId(scenarioMatrices, matrix.matrixId);

            //Remove any scenario matrices for scenarios that no longer exist
            const scenarioMatricesThatShouldRemain = applicableScenarioMatrices.filter(sm => scenarioIds.includes(sm.scenarioId));

            reconciledScenarioMatrices.set(matrix.matrixId, scenarioMatricesThatShouldRemain);
          });
        });
      });
    });
  });

  return reconciledScenarioMatrices;
};