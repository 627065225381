import { ClientFileId, ScenarioId, ScenarioOptionId } from '../types/api/PrimaryKeys';
import axios from 'axios';
import ScenarioOption from '../types/api/options/ScenarioOption';
import { FlatScenarioOptionUnitYear } from '../types/api/options/FlatScenarioOptionUnitYear';
import ScenarioOptionUnitYear from '../types/api/options/ScenarioOptionUnitYear';
import { envBaseApiUrl } from '../constants/envConstants';

export const getScenarioOptions = (scenarioId: ScenarioId) => axios.get<ScenarioOption[]>(`${envBaseApiUrl}/row-crop-scenarios/${scenarioId}/options`);

export const getScenarioOptionsForScenarios = (scenarioIds: ScenarioId[]) => axios.post<ScenarioOption[]>(`${envBaseApiUrl}/row-crop-scenarios/options`, scenarioIds);

export const getScenarioOptionsForClientFile = (clientfileId: ClientFileId) => axios.get<ScenarioOption[]>(`${envBaseApiUrl}/client-files/${clientfileId}/options`);

export const getScenarioOptionUnitYearsForScenarios = (scenarioIds: ScenarioId[]) => axios.post<ScenarioOptionUnitYear[]>(`${envBaseApiUrl}/row-crop-scenarios/options/unit-years`, scenarioIds);

export const deleteScenarioOptions = (scenarioOptionIds: ScenarioOptionId[]) => axios.delete<void>(`${envBaseApiUrl}/row-crop-scenarios/options`, { data: scenarioOptionIds });

export const setAllScenarioOptionUnitYearsForScenario = (scenarioId: ScenarioId, scenarioOptionUnitYears: FlatScenarioOptionUnitYear[]) => axios.post<void>(`${envBaseApiUrl}/row-crop-scenarios/${scenarioId}/options/set-all`, scenarioOptionUnitYears);

export const setScenarioOptions = (scenarioId: ScenarioId, body: { scenarioOptions: ScenarioOption[], scenarioOptionUnitYears: ScenarioOptionUnitYear[] }) => axios.post<void>(`${envBaseApiUrl}/row-crop-scenarios/${scenarioId}/options`, body);

export const updateScenarioOptionUnitYearsBatch = (scenarioOptionUnitYears: ScenarioOptionUnitYear[]) => axios.put<void>(`${envBaseApiUrl}/row-crop-scenarios/options/unit-years/batch`, scenarioOptionUnitYears);
