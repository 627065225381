import { FormInputParameterNames } from '../../../components/formInputs/constants/inputConstants';
import { FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { getMonthAbbreviation } from '../../../utils/monthNamesUtil';
import { IntervalCheckbox } from './intervalCheckbox.component';
import { IntervalRange } from '../../../types/api/adm/IntervalRange';

interface Props {
  availableIntervals: IntervalRange[],
  initialSelectedIntervalIds: number[],
  selectedYear: number,
}

export interface SelectedIntervalsFields {
  selectedIntervalIds: number[];
}

export const IntervalCheckboxes = ({ availableIntervals, initialSelectedIntervalIds }: Props) => {
  const { control } = useFormContext();
  const sortedAvailableIntervals = availableIntervals.sort((a, b) => intervalSorter(a, b));

  return (
    <Controller
      name={FormInputParameterNames.SelectedIntervalsIds}
      defaultValue={initialSelectedIntervalIds}
      control={control}
      render={() => (
        <>
          {sortedAvailableIntervals.map(interval =>
            <FormControlLabel key={interval.id}
              label={`${getMonthAbbreviation(interval.intervalMonthStart)} ${interval.intervalDayStart} - ${getMonthAbbreviation(interval.intervalMonthEnd)} ${interval.intervalDayEnd}`}
              control={<IntervalCheckbox interval={interval} />
              } />)}
        </>
      )} />);
};

function intervalSorter(a: IntervalRange, b: IntervalRange) {
  if (a.intervalDeltaYearStart !== b.intervalDeltaYearStart) return a.intervalDeltaYearStart - b.intervalDeltaYearStart;
  if (a.intervalMonthStart !== b.intervalMonthStart) return a.intervalMonthStart - b.intervalMonthStart;
  return a.intervalDayStart - b.intervalDayStart;
}
