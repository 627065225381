import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { FormInputProps } from '../../scenario/formInputProps';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

interface IsPrimeProps extends FormInputProps {
  isPrime: boolean;
}

export type IsPrimeFields = {
  isPrime: boolean;
}

const IsPrime = ({ isPrime, autoFocus = false, disabled = false }: IsPrimeProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.IsPrime}
      control={control}
      defaultValue={isPrime}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl
          error={!!error}
          disabled={disabled}>
          <FormControlLabel
            label="Prime"
            control={
              <Checkbox
                {...field}
                checked={value}
                inputRef={ref}
                onChange={e => field.onChange(e.target.checked)}
                autoFocus={autoFocus}
              />
            }
          />
          <FormHelperText>{error?.message}</FormHelperText>

        </FormControl >
      )}
    />
  );
};

export default IsPrime;
