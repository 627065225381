import { TableCell, TableRow, Typography, styled } from '@mui/material';
import { roundToPlaces } from '@silveus/calculations';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import SweetSpot from '../../../types/api/SweetSpot';

type Props = {
  title: string;
  sweetSpot: SweetSpot;
};

const BorderlessCell = styled(TableCell)({
  borderBottom: 'none',
  padding: '4px',
});

export const SweetSpotMatrixAnalysisScenarioCardRow = ({ title, sweetSpot }: Props) => {
  return (
    <MatrixAnalysisScenarioCardRow
      title={title}
      average={sweetSpot.averageData?.average ?? 0}
      minYield={sweetSpot.axisRangeData?.x.from ?? 0}
      maxYield={sweetSpot.axisRangeData?.x.to ?? 0}
      minPrice={sweetSpot.axisRangeData?.y.from ?? 0}
      maxPrice={sweetSpot.axisRangeData?.y.to ?? 0}
    />
  );
};

type MatrixAnalysisScenarioCardRowProps = {
  title: string;
  average: number;
  minYield: number;
  maxYield: number;
  minPrice: number;
  maxPrice: number;
}

const MatrixAnalysisScenarioCardRow = ({ title, average, minYield, maxYield, minPrice, maxPrice }: MatrixAnalysisScenarioCardRowProps) => {
  return (
    <TableRow>
      <BorderlessCell sx={{ maxWidth: '100px' }}>
        <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="subtitle2">{title}</Typography>
      </BorderlessCell>
      <BorderlessCell>
        <Typography variant="label">Avg. $</Typography>
      </BorderlessCell>
      <BorderlessCell>
        <CurrencyText align="right" sx={{ textAlign: 'left' }} currencyAmount={roundToPlaces(average, 2)} colorize />
      </BorderlessCell>
      <BorderlessCell>
        <Typography variant="label">Yield</Typography>
      </BorderlessCell>
      <BorderlessCell>
        <Typography sx={{ textAlign: 'left' }} variant="value">{roundToPlaces(minYield, 1)}-{roundToPlaces(maxYield, 1)}</Typography>
      </BorderlessCell>
      <BorderlessCell>
        <Typography variant="label">Price</Typography>
      </BorderlessCell>
      <BorderlessCell>
        <Typography sx={{ textAlign: 'left' }} variant="value">{roundToPlaces(minPrice, 2)}-{roundToPlaces(maxPrice, 2)}</Typography>
      </BorderlessCell>
    </TableRow >
  );
};