export enum InsurancePlan {
  Arp,
  ArpHpe,
  Ayp,
  RP,
  RpHpe,
  YP,
  Aph,
  ScoRp,
  ScoRpHpe,
  ScoYp,
  EcoRp,
  EcoRpHpe,
  EcoYp,
  MP,
  MpHpo,
  StaxRp,
  StaxRpHpe,
  Hip,
  RampRevenue,
  RampYield,
  RevBoostRp,
  RevBoostRpHpe,
  RevBoostRpHpo,
  RevBoostYp,
  MyScoRevenue,
  MyScoYield,
  MyEcoRevenue,
  MyEcoYield,
  ScoPlusRevenue,
  ScoPlusYield,
  EcoPlusRevenue,
  EcoPlusYield,
  IceStack590,
  IceStack595,
  IceStack1090,
  IceStack1095,
  IceStack1595,
  IceShield1090,
  IceShield1095,
  Plc,
  Arc,
}

export default InsurancePlan;
