import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../types/util/Nullable';

interface ConfirmState {
  content: null | ConfirmStateContent;
}

export interface ConfirmStateContent {
  title: string;
  confirmText: string;
  message: string;
  onConfirm: (() => Promise<void>) | (() => void);
  onCancel?: (() => Promise<void>) | (() => void);
  cancelText?: Nullable<string>;
  shouldPreventBackdropClose?: boolean;
  hideCancel?: boolean;
}

const initialState: ConfirmState = {
  content: null,
};

export const confirmSlice = createSlice({
  name: 'confirmation',
  initialState: initialState,
  reducers: {
    openConfirm(state, action: PayloadAction<ConfirmStateContent>) {
      const { title, confirmText, message, onConfirm, cancelText, onCancel, shouldPreventBackdropClose, hideCancel } = action.payload;
      state.content = {
        title: title,
        confirmText: confirmText,
        message: message,
        cancelText: cancelText,
        onConfirm: onConfirm,
        onCancel: onCancel,
        shouldPreventBackdropClose: shouldPreventBackdropClose,
        hideCancel: hideCancel,
      };
    },
    closeConfirm(state) {
      state.content = null;
    },
  },
});

export const { openConfirm, closeConfirm } = confirmSlice.actions;

export default confirmSlice.reducer;
