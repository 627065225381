import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { ClientFile } from '../../../types/api/ClientFile';
import { HailEndorsementId, HailProductId, HailScenarioPieceCompositionId, ScenarioId } from '../../../types/api/PrimaryKeys';
import { Quote } from '../../../types/api/Quote';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import useFormWrapper from '../../../hooks/useFormWrapper';
import useDrawerForm from '../../../hooks/useDrawerForm';
import { Grid, Button, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { updateCalculationForScenario } from '../../../app/calculationResultsSlice';
import {
  addHailScenarioPieceComposition,
  removeHailScenarioPieceCompositionAndRecalculate,
  selectAllHailScenarioPieceCompositionsByScenarioMap,
  selectAllHailScenarioPiecesByScenarioMap,
  selectHailScenarioPieceEndorsementsByHailScenarioPieceMap,
  selectHailScenarioPieceRatesByHailScenarioPieceMap
} from '../../../app/hailSlice';
import HailScenarioPieceComposition from '../../../types/api/HailScenarioPieceComposition';
import { ConfirmStateContent, openConfirm } from '../../../app/confirmSlice';
import { toggleHailModal } from '../../../app/hailModalSlice';
import { getHailProductsRequest } from '../../../services/requestInterception/scenarioPieces/hailScenarioPieceRequestInterceptor';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import { HailProductParams } from '../../../types/api/adm/PrivateProductsParams';
import { HailPlanType } from '../../../types/api/enums/hail/hailPlanType';
import { parseProductNameToScenarioPieceType } from '../../../constants/hailPlanToScenarioPieceType';
import TabbedContent from '../../../components/tabs/tabbedContent.component';
import HailCard from '../../hailModal/hailCardView/hailCard';
import { selectScenarioById } from '../../../app/scenariosSlice';
import {
  getAvailableHailEndorsementsByHailProductId,
  getAvailableHailRatesByHailProductId,
  getHailProductById,
  getSelectedHailScenarioPieceSelectedEndorsements,
  getSelectedHailScenarioPieceSelectedRates,
  getSelectedHailScenarioPieceUnitsFromUnitGroup
} from '../../hailModal/utils/useHailUtils';
import { selectAllUnitGroupsByScenarioPieceMap } from '../../../app/unitGroupsSlice';
import { selectCalculationsByScenarioPiece } from '../../../app/calculationResultsSlice';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';

interface HailScenarioPieceFormProps extends FormWrapperProps {
  scenarioId: ScenarioId;
  quote: Quote;
  commodityCode: string;
  clientFile: ClientFile;
  handleCancel: () => void;
}

export type HailScenarioPieceCompositionFormFields = ScenarioPieceFormFields;
const onHailCardActionDoNothing = () => { };

const HailScenarioPieceForm = ({ scenarioId, registerHeader, handleCancel, quote, clientFile, handleValidation, isCanceling = false }: HailScenarioPieceFormProps) => {
  const formId = 'hailScenarioPieceForm';

  const scenario = useAppSelector(s => selectScenarioById(s, scenarioId));
  const hailScenarioPieceCompositions = useKeyMapSelector(selectAllHailScenarioPieceCompositionsByScenarioMap, scenarioId);
  const hailScenarioPieces = useKeyMapSelector(selectAllHailScenarioPiecesByScenarioMap, scenarioId);
  const hailScenarioPieceComposition = hailScenarioPieceCompositions.at(0);
  const dispatch = useAppDispatch();
  const methods = useForm<HailScenarioPieceCompositionFormFields>({});
  const [loadingHailProductData, setLoadingHailProductData] = useState<boolean>(false);
  const [hailScenarioPieceCompositionId] = useState<HailScenarioPieceCompositionId>(hailScenarioPieceComposition?.hailScenarioPieceCompositionId ?? generatePrimaryKey<HailScenarioPieceCompositionId>());
  const [hailProductsByProductId, setHailProductsByProductId] = useState<Map<HailProductId, HailProduct>>(new Map<HailProductId, HailProduct>());
  const hailScenarioPieceRates = useAppSelector(s => selectHailScenarioPieceRatesByHailScenarioPieceMap(s));
  const hailScenarioPieceEndorsements = useAppSelector(s => selectHailScenarioPieceEndorsementsByHailScenarioPieceMap(s));
  const hailScenarioPieceUnits = useAppSelector(s => selectAllUnitGroupsByScenarioPieceMap(s));
  const hailScenarioPieceResults = useAppSelector(s => selectCalculationsByScenarioPiece(s));

  useEffect(() => {
    const fetchData = async () => {
      if (hailScenarioPieces.length === 0) return;

      const hailPlansByProductId = new Map<HailProductId, HailProduct>();
      const hailScenarioPieceProductIds = hailScenarioPieces.map(hailScenarioPiece => hailScenarioPiece.hailProductId);
      const hailScenarioPieceEndorsementProductIds: HailEndorsementId[] = [];
      hailScenarioPieceEndorsements.forEach(hailScenarioPieceEndorsementArray => {
        hailScenarioPieceEndorsementArray.forEach(hailScenarioPieceEndorsement => {
          hailScenarioPieceEndorsementProductIds.push(hailScenarioPieceEndorsement.endorsementId);
        });
      });
      const hailProductParams: HailProductParams = {
        commodityCode: quote.commodityCode,
        countyId: quote.countyId,
        cropYears: [clientFile.year, clientFile.year - 1],
        hailProductIds: [...hailScenarioPieceProductIds, ...hailScenarioPieceEndorsementProductIds],
      };

      const loadProducts = async (hailProductParams: HailProductParams) => {
        const hailProducts = await getHailProductsRequest(hailProductParams);
        hailProducts.forEach(hailProduct => {
          hailProduct.maxRate = Math.max(...hailProduct.hailRates.map(hailRate => hailRate.rate));
          hailProduct.minRate = Math.min(...hailProduct.hailRates.map(hailRate => hailRate.rate));
          hailProduct.rate = Math.max(...hailProduct.hailRates.map(hailRate => hailRate.rate)); // TODO -> defaulting to max for now, but will need logic in the future
          hailProduct.policy = parseProductNameToScenarioPieceType(hailProduct);
          hailProduct.planType = hailProduct.isProduction ? HailPlanType.Production : HailPlanType.Standard;
          hailPlansByProductId.set(hailProduct.hailProductId, hailProduct);
        });
      };

      setLoadingHailProductData(true);
      await loadProducts(hailProductParams);
      setLoadingHailProductData(false);
      setHailProductsByProductId(hailPlansByProductId);
    };

    fetchData();
  }, [quote, clientFile, hailScenarioPieces, hailScenarioPieceEndorsements]);

  const onSubmit: SubmitHandler<HailScenarioPieceCompositionFormFields> = async data => {
    const newScenarioPieceComposition: HailScenarioPieceComposition = {
      hailScenarioPieceCompositionId: hailScenarioPieceCompositionId,
      scenarioId: scenarioId,
      isActive: true,
      offlineCreatedOn: hailScenarioPieceComposition?.offlineCreatedOn,
      offlineLastUpdatedOn: hailScenarioPieceComposition?.offlineLastUpdatedOn,
      offlineDeletedOn: hailScenarioPieceComposition?.offlineDeletedOn,
    };

    //New scenario piece
    await dispatch(addHailScenarioPieceComposition({
      hailScenarioPieceComposition: newScenarioPieceComposition,
      hailScenarioPieces: [],
      hailScenarioPieceRates: [],
      hailScenarioPieceEndorsements: [],
    }));

    await dispatch(updateCalculationForScenario({ scenarioId: scenarioId }));
  };
  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0;
  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const onDeleteScenarioPieceCompositionClick = () => {
    const confirmWindow: ConfirmStateContent = {
      title: 'Delete Hail?',
      message: 'Are you sure you want to delete hail? Any hail plans that are on this will also be deleted.',
      confirmText: 'Delete',
      onConfirm: onDeleteScenarioPieceComposition,
    };
    dispatch(openConfirm(confirmWindow));
  };

  const onDeleteScenarioPieceComposition = async () => {
    if (hailScenarioPieceComposition === undefined) return;
    await dispatch(removeHailScenarioPieceCompositionAndRecalculate({ hailScenarioPieceComposition: hailScenarioPieceComposition }));
  };

  const hailScenarioPieceTabNames = hailScenarioPieces.map(hailScenarioPiece => {
    const hailProduct = hailProductsByProductId.get(hailScenarioPiece.hailProductId);
    return hailProduct?.policy?.policyTypeName ?? '';
  });
  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid container spacing="15">
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button id="btn-add-hail" variant="contained" onClick={() => dispatch(toggleHailModal())}>
                OPEN HAIL QUOTER
              </Button>
            </Grid>
            <Grid item xs={12} >
              {hailScenarioPieces.length > 0 && scenario !== null && hailProductsByProductId.size > 0 ?
                <TabbedContent tabNames={hailScenarioPieceTabNames} shouldScroll={true}>
                  {hailScenarioPieces.map((hailScenarioPiece, index) => {
                    return (
                      <HailCard
                        key={hailScenarioPiece.hailScenarioPieceId}
                        scenario={scenario}
                        hailScenarioPiece={hailScenarioPiece}
                        hailScenarioPieceUnits={getSelectedHailScenarioPieceUnitsFromUnitGroup(hailScenarioPieceUnits, hailScenarioPiece.scenarioPieceId)}
                        hailScenarioPieceRates={getSelectedHailScenarioPieceSelectedRates(hailScenarioPieceRates, hailScenarioPiece.hailScenarioPieceId)}
                        hailScenarioPieceEndorsements={getSelectedHailScenarioPieceSelectedEndorsements(hailScenarioPieceEndorsements, hailScenarioPiece.hailScenarioPieceId)}
                        hailProduct={getHailProductById(hailProductsByProductId, hailScenarioPiece.hailProductId)}
                        availableHailRates={getAvailableHailRatesByHailProductId(hailProductsByProductId, hailScenarioPiece.hailProductId)}
                        availableHailEndorsements={getAvailableHailEndorsementsByHailProductId(hailProductsByProductId, hailScenarioPiece.hailProductId)}
                        onDeleteProductClick={onHailCardActionDoNothing}
                        onHailScenarioPieceUpdated={onHailCardActionDoNothing}
                        onHailScenarioPieceLossChanged={onHailCardActionDoNothing}
                        onHailScenarioPieceRatesUpdated={onHailCardActionDoNothing}
                        onHailScenarioPieceUnitsUpdated={onHailCardActionDoNothing}
                        onHailScenarioPieceEndorsementsUpdated={onHailCardActionDoNothing}
                        calculatedValue={hailScenarioPieceResults.get(hailScenarioPiece.scenarioPieceId)}
                        index={index}
                        disableButtonsAndFields={true}
                        shouldLimitHeight={true}
                      />
                    );
                  })}
                </TabbedContent>
                : loadingHailProductData && <CircularProgress />}
            </Grid>
          </Grid>

        </form>
      </FormProvider>
      <Grid item sx={{ position: 'absolute', right: 0, bottom: 0, paddingBottom: 3, paddingRight: 2 }}>
        <Button variant="contained" id="btn-delete" disabled={hailScenarioPieceComposition === undefined}
          onClick={() => onDeleteScenarioPieceCompositionClick()}>Delete</Button>
      </Grid>
    </>
  );
};

export default HailScenarioPieceForm;