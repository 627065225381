import { SalesClosingDate } from '../../types/api/applicationWizard/salesClosingDate';
import { Nullable } from '../../types/util/Nullable';
import { isNullOrUndefined } from '../nullHandling';

/**
 * Given a previous sales closing date, figure out the prior year's sales closing date if applicable and return
 * null if no sales closing date or a formatted date string we can use to match prior year coverages based on date.
 */
export const getPreviousSalesClosingDate = (scd: Nullable<SalesClosingDate>) => {

  if (isNullOrUndefined(scd)) return null;

  // Split the dateName string to extract month, day, and year
  const [month, day, year] = scd.dateName.split('/').map(Number);

  // Create a Date object using the extracted parts
  const currentDate = new Date(year, month - 1, day);

  // Subtract one year
  const previousYearDate = new Date(currentDate);
  previousYearDate.setFullYear(currentDate.getFullYear() - 1);

  // Format the date back to the required format to compare with policy dates
  const previousYearDateString = previousYearDate.toISOString().split('T')[0] + 'T00:00:00';

  return previousYearDateString;
};