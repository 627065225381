interface CustomizedBarChartLabelProps {
  x?: number;
  y?: number;
  stroke?: string;
  value?: number;
}

export const CustomizedBarChartLabel = ({ x, y, stroke, value }: CustomizedBarChartLabelProps) => {
  if (value === undefined) return <></>;

  const offset = value < 0 ? 20 : -10;

  return (
    <text x={x} y={y} dy={offset} stroke={stroke} fontSize={14} textAnchor="middle">
      { value.toFixed(2) }
    </text>
  );
};