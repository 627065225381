import { QuoteId, ScenarioId } from '../../types/api/PrimaryKeys';
import { ReactElement, useState } from 'react';
import CommandBar, { CommandBarItem } from '../../components/command-bar/commandBar.component';
import { Nullable } from '../../types/util/Nullable';
import { ScenarioPieceGroupResponseDTO, ScenarioPieceResponseDTO } from '@silveus/calculations';
import { Grid, IconButton, styled, Tooltip, useTheme } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { selectScenarioById, setCurrentlySelectedScenarioId } from '../../app/scenariosSlice';
import { openDrawer } from '../../app/appDrawerSlice';
import { ConfirmStateContent, openConfirm } from '../../app/confirmSlice';
import classNames from 'classnames';
import AccordionSummary from '@mui/material/AccordionSummary';
import EditIcon from '@mui/icons-material/Edit';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import AccordionDetails from '@mui/material/AccordionDetails';
import * as React from 'react';
import ScenarioComponentResultSummary from './scenarioComponentResultSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square={false} {...props} />
))(({ theme }) => {
  return ({
    borderRadius: '4px',
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },
  });
});

interface ScenarioComponentCardProps<T> {
  scenarioComponent: T;
  quoteId: QuoteId;
  isScenarioActive: boolean;
  updateScenarioComponent: (scenarioComponent: T, isActive?: boolean) => Promise<void>;
  removeScenarioComponent: (scenarioComponent: T) => Promise<void>;
  label: string;
  netValue: number;
  scenarioId: ScenarioId;
  calcResults: Nullable<ScenarioPieceResponseDTO | ScenarioPieceGroupResponseDTO>
  collapsedCardBody?: ReactElement;
  expandedCardBody?: ReactElement;
  extraCommandBarItems?: CommandBarItem[];
  getFormName: (scenarioComponent: T) => string;
  getScenarioPieceTooltip: () => ReactElement;
  isReadonly: boolean;
  isInvalid: boolean;
  errorTooltip?: ReactElement;
  scenarioPieceChips?: ReactElement;
  shouldShowThreeStageExpander?: boolean;
}

const ScenarioComponentCard = <T,>({
  scenarioComponent,
  quoteId,
  isScenarioActive,
  updateScenarioComponent,
  removeScenarioComponent,
  label,
  netValue,
  scenarioId,
  calcResults,
  collapsedCardBody,
  expandedCardBody,
  extraCommandBarItems = [],
  getFormName,
  getScenarioPieceTooltip,
  isReadonly,
  isInvalid,
  errorTooltip,
  scenarioPieceChips,
  shouldShowThreeStageExpander = false,
}: ScenarioComponentCardProps<T>) => {
  const dispatch = useAppDispatch();
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));

  const [isNestedAccordionExpanded, setIsNestedAccordionExpanded] = useState(false);
  const [isOuterAccordionExpanded, setIsOuterAccordionExpanded] = useState(false);

  const theme = useTheme();
  const editClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(setCurrentlySelectedScenarioId(scenarioId));
    const formName = getFormName(scenarioComponent);
    dispatch(openDrawer({ formName: 'scenarioPieceForm', scenarioId: scenarioId, quoteId: quoteId, defaultFormSelection: { formName: formName } }));
  };

  const activeInactiveSwitchClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation(); //prevent to expand the accordion
    await updateScenarioComponent(scenarioComponent, false);
  };

  const deleteScenarioPiece = async () => {
    await removeScenarioComponent(scenarioComponent);
  };

  const onDeleteScenarioPieceClick = () => {
    const confirmWindow: ConfirmStateContent = {
      title: 'Delete Scenario Piece?',
      message: 'Are you sure you want to delete this scenario piece? Any scenario pieces that rely on this will also be deleted.',
      confirmText: 'Delete',
      onConfirm: deleteScenarioPiece,
    };
    dispatch(openConfirm(confirmWindow));
  };

  const textWidth = Math.max(7.2 - (extraCommandBarItems.length * 1.2), 4);
  const buttonWidth = Math.min(4.8 + (extraCommandBarItems.length * 1.2), 8);

  const getCommandBarItems = () => {
    const items: CommandBarItem[] = [];
    if (!isReadonly) {
      items.push({
        id: 'edit-scenario-piece-menu',
        label: 'Edit',
        onclick: () => { },
        iconButton: <EditIcon fontSize="small" />,
        renderMenuItem: () => (
          <Tooltip title="Edit" color="primary">
            <div style={{ marginLeft: '0px', marginTop: '3px' }}>
              <IconButton size="small" sx={{ pr: 0, pl: 0, mr: 0, ml: 0 }} color={isInvalid ? 'secondary' : 'primary'} id="btn-edit-scenario-piece" onClick={editClick}>
                <EditIcon fontSize="small" />
              </IconButton>
            </div>
          </Tooltip>
        ),
      });
    }
    items.push({
      id: 'active-inactive-scenario-piece-menu',
      label: 'Inactive Scenario Piece',
      onclick: () => { },
      iconButton: <EditIcon fontSize="small" />,
      renderMenuItem: () => (
        <Tooltip title="Inactive Scenario Piece" color="primary">
          <div style={{ marginLeft: '0px' }}>
            <IconButton disabled={isReadonly} sx={{ pr: 0, pl: 0, mr: 0, ml: 0 }} onClick={activeInactiveSwitchClick} id="btn-active-inactive-scenario-piece" >
              <VisibilityIcon color={isReadonly ? 'disabled' : 'primary'} fontSize="small" />
            </IconButton>
          </div>

        </Tooltip>
      ),
    });
    if (!isReadonly) {
      items.push({
        id: 'edit',
        label: 'Edit',
        iconButton: <EditIcon fontSize="small" />,
        onclick: editClick,
      });
      items.push({
        id: 'delete',
        label: 'Delete',
        iconButton: <DeleteIcon fontSize="small" />,
        onclick: onDeleteScenarioPieceClick,
      });
    }

    return items;
  };

  const commandBar = (
    <CommandBar
      items={[
        ...extraCommandBarItems,
        ...getCommandBarItems(),
      ]}
    />
  );

  const handleOuterAccordionChange = (expanded: boolean) => {
    setIsOuterAccordionExpanded(expanded);

    // When the outer accordion is collapsed, reset / collapse the inner accordion.
    if (!expanded) {
      setIsNestedAccordionExpanded(false);
    }
  };

  const handleNestedAccordionChange = (expanded: boolean) => {
    // Default behavior here - this is only controlled so the parent (see above) can control collapse state.
    setIsNestedAccordionExpanded(expanded);
  };

  // Creates the colored border along the left side of the card
  const leftBorderElement = <div style={{ width: '9px', height: '100%', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', backgroundColor: isReadonly ? theme.palette.action.disabled : scenario?.scenarioColor ?? undefined }} />;

  // While the parent is passing down a setting about whether to show the three stage expander,
  // if the piece in question has not defined an expanded card body, it can't be displayed as three stage, regardless of
  // what the parent says. In this case, we need to fall back down to two stage.
  const useThreeStageExpander = shouldShowThreeStageExpander && expandedCardBody !== undefined;

  return (
    <>
      <Accordion
        className={classNames('scenario-piece-card-accordion', { 'scenario-piece-card-box-shadow': isScenarioActive })}
        onChange={(_, expanded) => handleOuterAccordionChange(expanded)}
      >
        <AccordionSummary
          className="scenario-piece-card-accordion-summary"
          sx={{ pl: 0, pr: 0, flexDirection: 'row-reverse', minHeight: '0', border: isInvalid ? theme => `3px solid ${theme.palette.secondary.main}` : undefined }}
        >
          <Grid container spacing={0.5}>

            <Grid item xs="auto">
              {leftBorderElement}
            </Grid>

            <Grid item xs sx={{ marginLeft: '5px' }}>
              <ScenarioComponentResultSummary
                scenarioPieceChips={scenarioPieceChips}
                extraContent={commandBar}
                extraContentWidth={buttonWidth}
                textContentWidth={textWidth}
                summaryContent={collapsedCardBody}
                label={label}
                calcResults={calcResults}
                scenarioId={scenarioId}
                netValue={netValue}
                getScenarioPieceTooltip={getScenarioPieceTooltip}
                isReadonly={isReadonly}
                isInvalid={isInvalid}
                errorTooltip={errorTooltip}
                mode={(!useThreeStageExpander && isOuterAccordionExpanded) ? 'full' : 'slim'}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className="scenario-piece-card-accordion-details">
          <Grid container spacing={0.5}>

            <Grid item xs="auto" sx={{ marginTop: '-10px' }}>
              {leftBorderElement}
            </Grid>

            {expandedCardBody && !useThreeStageExpander &&
              <Grid item xs pb={2} sx={{ marginLeft: '10px' }}>
                {expandedCardBody}
              </Grid>
            }

            {useThreeStageExpander &&
              <Grid item xs sx={{ marginLeft: '10px' }}>
                <Accordion
                  disableGutters
                  sx={{ border: 'none', margin: 0, padding: 0 }}
                  expanded={isNestedAccordionExpanded}
                  onChange={(_, expanded) => handleNestedAccordionChange(expanded)}
                >
                  <AccordionSummary sx={{ pl: 0, pr: 0, mb: 0, '& .MuiAccordionSummary-content': { marginBottom: 0 } }}>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <ScenarioComponentResultSummary
                          scenarioPieceChips={scenarioPieceChips}
                          extraContent={commandBar}
                          extraContentWidth={buttonWidth}
                          textContentWidth={textWidth}
                          summaryContent={collapsedCardBody}
                          label={label}
                          calcResults={calcResults}
                          scenarioId={scenarioId}
                          netValue={netValue}
                          getScenarioPieceTooltip={getScenarioPieceTooltip}
                          isReadonly={isReadonly}
                          isInvalid={isInvalid}
                          errorTooltip={errorTooltip}
                          mode={'additional-only'}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton >
                          {!isNestedAccordionExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </IconButton>

                      </Grid>
                    </Grid>
                  </AccordionSummary>

                  <AccordionDetails>
                    {expandedCardBody}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ScenarioComponentCard;