import {
  Commodity,
  CoverageTypeCode,
  ArpHpePremiumParams,
  ArpHpeIndemnityParams,
  ArpHpeUnit,
  ArpHpeRequestDTO,
  UnitStructureCode,
  OptionCode, Unit
} from '@silveus/calculations';
import { RootState } from '../../../app/store';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import BaseUnit from '../../../types/api/BaseUnit';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { getCalcParamRequestUnits, getMatchingCalcParamDataForUnit, getUnitsForScenarioPiece } from '../calculationUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getArpHpeCalcDataRequest } from '../../requestInterception/scenarioPieces/arpHpeRequestInterceptor';
import ArpHpeCalculationParams from '../../../types/api/calculationData/arpHpeCalculationParams';
import { ArpHpeCalculationParamsRequest } from '../../../types/api/calculationData/arpHpeCalculationParamsRequest';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import { Nullable } from '../../../types/util/Nullable';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';

export const createArpHpeData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  //Get the calc data back from the API
  const calcParamRequest = createCalcParamRequest(state, scenarioPiece, scenario, baseUnits);
  const returnedData = await getArpHpeCalcDataRequest(calcParamRequest);

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createArpHpePlanDto(scenarioPiece, returnedData, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformArpHpeData = (calcData: Nullable<ArpHpeCalculationParams>, unit: Nullable<BaseUnit>, scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece): ArpHpePremiumParams & ArpHpeIndemnityParams => {
  const arpParams: ArpHpePremiumParams & ArpHpeIndemnityParams = {
    commodityCode: (unit?.commodityCode ?? '') as Commodity,
    projectedPrice: scenario.projectedPrice ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
    premiumRate: calcData?.baseRate ?? 0,
    priceElectionPercent: scenarioPiece.protectionFactor / 100,
    multipleCommodityAdjustmentFactor: calcData?.multipleCommodityAdjustmentFactor ?? 0,
    liabilityAdjustmentFactor: 1,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    unitStructureCode: scenarioPiece.unitStructure,
    coverageTypeCode: CoverageTypeCode.A,
    isNativeSod: false,
    isBeginningOrVeteranFarmerOrRancher: false,
    isConservationCompliance: false,
    conservComplSubsidyReductionPercent: 0,
    subsidyPercent: calcData?.subsidyPercent ?? 0,
    underlyingGuaranteeAmount: 0,
    underlyingLiabilityAmount: 0,
  };

  return arpParams;
};

const createArpHpeUnits = (arpCalcData: ArpHpeCalculationParams[], units: BaseUnit[], scenario: RowCropScenario): (ArpHpeUnit & Unit)[] => {
  return units.map(unit => {

    const matchingData = getMatchingCalcParamDataForUnit(unit, arpCalcData);
    //TODO: Error Handling
    if (isNullOrUndefined(matchingData)) throw new Error('ARP Data Transform State mismatch: Unable to find calculation data returned from API associated with unit in state.');

    const unitDto = createBaseUnit(unit);
    const arpUnit: ArpHpeUnit & Unit = {
      ...unitDto,
      id: unit.id,
      //TODO #61367: This is a temporary workaround until we get a more permanent solution for handling options per scenario piece
      options: unit.options.filter(optionCode => optionCode !== OptionCode.TS),
    };

    return arpUnit;
  });
};

const createArpHpePlanDto = (scenarioPiece: RowCropScenarioPiece, apiData: ArpHpeCalculationParams[], unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): ArpHpeRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createArpHpeUnits(
        apiData,
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformArpHpeData(apiData.at(0) ?? null, unitData.at(0) ?? null, scenario, scenarioPiece),
  };
};

const createCalcParamRequest = (state: RootState, scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, baseUnits: BaseUnit[]): ArpHpeCalculationParamsRequest => {
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  return {
    year: clientFile.year,
    coverageTypeCode: (scenarioPiece.rowCropScenarioPieceExtendedData?.isCat ?? false) ? CoverageTypeCode[CoverageTypeCode.C] : CoverageTypeCode[CoverageTypeCode.A],
    unitStructureCode: UnitStructureCode[scenarioPiece.unitStructure],
    planCode: scenarioPiece.planCode,
    upperCoverageLevel: scenarioPiece.upperCoverageLevel,
    volatility: scenario.volatility,
    commodityCode: quote.commodityCode,
    countyId: quote.countyId,
    distinctUnits: getCalcParamRequestUnits(baseUnits),
  };
};
