import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import { validateVipMp } from '../../../../utils/validation/scenarioPieces/vipMpValidation';
import { createVipMpData } from '../../../../services/calculations/vipMp/vipMpDataTransformations';
import { VipMpScenarioPieceForm } from '../../../../pages/scenarioPiece/vipMp/vipMpScenarioPieceForm.component';
import {
  getCustomProductsQuoteSummaryData
} from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getCustomProductsQuoteSummaryData';
import { ScenarioPieceType } from '@silveus/calculations';
import { getFullyQualifiedScenarioPieceNameOnlyCoverageLevel } from '../../defaultDefinitions/scenarioPieceNameDefaults';
import {
  AddScenarioPieceCardChipFunction,
  CapScenarioPieceChip, ProtectionFactorScenarioPieceChip, TopIntervalScenarioPieceChip
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { ScenarioPiece } from '../../../../types/api/ScenarioPiece';
import { isRowCropScenarioPiece } from '../../../../utils/castingUtils';
import { getHistoricalQuoterColumnConfigs, getHistoricalQuoterIntervalColumnsForMarginProduct } from '../../../../utils/intervals/historicalQuoterIntervals';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';
import { ProductType } from '../../../../types/api/enums/application/productType.enum';

const InputCostEndorsementScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
  if (isRowCropScenarioPiece(scenarioPiece)) {
    if (scenarioPiece.rowCropScenarioPieceExtendedData?.isInputCostEndorsement === true && scenarioPiece.rowCropScenarioPieceExtendedData.maxInputCostDecline !== null){
      return `Input Cost End. ${scenarioPiece.rowCropScenarioPieceExtendedData.maxInputCostDecline}%`;
    }
  }
  return null;
};

export const vipMpDefinition: ScenarioPieceDefinition = {
  validationFunction: validateVipMp,
  d365ProductType: ProductType.VIP,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, _aphYield, _shouldSimulateUnitGroups) => {
    return createVipMpData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <VipMpScenarioPieceForm {...props} scenarioPieceType={ScenarioPieceType.MP} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}/>;
  },
  getHistoricalQuoterColumns: params => getHistoricalQuoterIntervalColumnsForMarginProduct(params),
  getHistoricalQuoterColumnConfigs: params => getHistoricalQuoterColumnConfigs(params),
  createInputForQuoteSummaryFunction: getCustomProductsQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceNameOnlyCoverageLevel,
  getScenarioPieceCardChips: [
    CapScenarioPieceChip,
    ProtectionFactorScenarioPieceChip,
    TopIntervalScenarioPieceChip,
    InputCostEndorsementScenarioPieceChip,
  ],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#6B8E23',
};
