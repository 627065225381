import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import validationFunctionDefault from '../../defaultDefinitions/validationFunctionDefault';
import { createVipData } from '../../../../services/calculations/vip/vipDataTransformations';
import { VipScenarioPieceForm } from '../../../../pages/scenarioPiece/vip/vipScenarioPieceForm.component';
import {
  getCoverageLevelTextForScenarioPieceName,
  getUnitStructureTextForScenarioPieceName
} from '../../defaultDefinitions/scenarioPieceNameDefaults';
import { ScenarioPiece } from '../../../../types/api/ScenarioPiece';
import { isRowCropScenarioPiece } from '../../../../utils/castingUtils';
import {
  AddScenarioPieceCardChipFunction,
  CapScenarioPieceChip,
  ProtectionFactorScenarioPieceChip,
  TopIntervalScenarioPieceChip
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { ScenarioPieceType } from '@silveus/calculations';
import { getFriendlyScenarioPieceName } from '../../../../utils/scenarioPieceUtils';
import { getVipQuoteSummaryData } from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getVipQuoteSummaryData';
import { getHistoricalQuoterColumnConfigs, getHistoricalQuoterIntervalColumnsForProduct } from '../../../../utils/intervals/historicalQuoterIntervals';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

const MPCIProtectionFactorScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
  if (isRowCropScenarioPiece(scenarioPiece)) {
    if (scenarioPiece.rowCropScenarioPieceExtendedData?.underlyingScenarioPieceType === ScenarioPieceType.Arp) {
      return `MPCI PF ${scenarioPiece.rowCropScenarioPieceExtendedData.underlyingProtectionFactor}%`;
    }
  }
  return null;
};

export const vipDefinition: ScenarioPieceDefinition = {
  validationFunction: validationFunctionDefault,
  d365ProductType: ProductType.VIP,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, _aphYield, _shouldSimulateUnitGroups) => {
    return createVipData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <VipScenarioPieceForm {...props} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>} />;
  },
  getHistoricalQuoterColumns: params => getHistoricalQuoterIntervalColumnsForProduct(params),
  getHistoricalQuoterColumnConfigs: params => getHistoricalQuoterColumnConfigs(params),
  createInputForQuoteSummaryFunction: getVipQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
    const scenarioPieceTypeName = getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType);
    if (isRowCropScenarioPiece(scenarioPiece)) {
      const underlyingPlan = scenarioPiece.rowCropScenarioPieceExtendedData?.underlyingScenarioPieceType ?? ScenarioPieceType.Unset;
      const underlyingPlanText = getFriendlyScenarioPieceName(underlyingPlan);
      const coverageLevel = getCoverageLevelTextForScenarioPieceName(scenarioPiece);
      const unitStructureText = getUnitStructureTextForScenarioPieceName(scenarioPiece);
      return `${scenarioPieceTypeName} ${underlyingPlanText} ${coverageLevel} ${unitStructureText}`;
    }
    return `${scenarioPieceTypeName}`;
  },
  getScenarioPieceCardChips: [
    CapScenarioPieceChip,
    ProtectionFactorScenarioPieceChip,
    TopIntervalScenarioPieceChip,
    MPCIProtectionFactorScenarioPieceChip,
  ],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#C71585',
};
