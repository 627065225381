import { OptionCode, ScenarioPieceType, ScenarioPieceResponseDTO } from '@silveus/calculations';
import { QuoteSummaryScenarioPiece } from '../../../../types/api/reports/QuoteSummaryScenarioPiece';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../../types/util/Nullable';
import { RowCropScenario } from '../../../../types/api/RowCropScenario';
import { getScenarioPieceDefinition } from '../../../../constants/productDefinitions/scenarioPieceDefinitionRecords';

export type CreateInputForQuoteSummaryForScenarioPieceFunction = (
  scenario: RowCropScenario,
  scenarioPiece: RowCropScenarioPiece,
  scenarioPieceCalcData: Nullable<ScenarioPieceResponseDTO>,
  scenarioOptions: OptionCode[],
  projectedPrice: number,
  isProjectedPriceSet: boolean,
  approvedYield: Nullable<number>,
  adjustedYield: Nullable<number>) => QuoteSummaryScenarioPiece[];

export const customizeQuoteSummaryScenarioPiece = (
  scenarioPieceType: ScenarioPieceType,
): CreateInputForQuoteSummaryForScenarioPieceFunction => {
  const scenarioPieceDefinition = getScenarioPieceDefinition(scenarioPieceType);
  return scenarioPieceDefinition.createInputForQuoteSummaryFunction;
};
