import { IEnumAttributes } from '../IEnumAttributes';

/* eslint-disable no-unused-vars */
export enum ApplicationStatusType {
  InProgress = 1,
  Submitted = 2,
  Cancelled = 3,
  AppTasksCreated = 4,
}

export const ApplicationStatusTypeAttributes: Readonly<Record<ApplicationStatusType, IEnumAttributes<ApplicationStatusType>>> = {
  [ApplicationStatusType.InProgress]: { name: 'In Progress', description: 'In Progress', value: ApplicationStatusType.InProgress },
  [ApplicationStatusType.Submitted]: { name: 'Submitted', description: 'Submitted', value: ApplicationStatusType.Submitted },
  [ApplicationStatusType.Cancelled]: { name: 'Cancelled', description: 'Cancelled', value: ApplicationStatusType.Cancelled },
  [ApplicationStatusType.AppTasksCreated]: { name: 'App Tasks Created', description: 'App Tasks Created', value: ApplicationStatusType.AppTasksCreated },
};
