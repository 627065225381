import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { validateInsuringLandlordTenantShare } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import { handleValidationResult } from './validationHelpers';

type YesNoNAInputProps = {
  value: Nullable<boolean>;
  index: number;
  name: string;
  application: ApplicationWizard;
  canEditAppTask: boolean;
}

const InsuringLandlordShareInput = ({ value, index, name, application, canEditAppTask }: YesNoNAInputProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={`appTasks.${index}.${name}`}
      defaultValue={value}
      rules={{ validate: val => handleValidationResult(validateInsuringLandlordTenantShare(val, application)) }}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          select
          disabled={!application.isGeneratingForms || !application.insuringLLTs || !canEditAppTask}
          error={!!error}
          inputRef={ref}
          size="small"
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <MenuItem value={true as any}>Yes</MenuItem>
          { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <MenuItem value={false as any}>No</MenuItem>
        </TextField>
      )}
    />
  );
};

export default InsuringLandlordShareInput;