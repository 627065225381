import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, ReferenceLine, ResponsiveContainer } from 'recharts';
import { CustomizedBarChartLabel } from './CustomizedBarChartLabel';
import { useTheme } from '@mui/material';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectIsLightMode } from '../../../../app/userSettingsSlice';

export type BarData<TData> = {
  dataKey: keyof TData & string;
  color: string;
  name: string;
};

type Props<TData> = {
  /** The actual chart data. This should include the x values as well as all possible y values. */
  data: TData[];
  /** Adjust this array to choose which bars to show. */
  barData: BarData<TData>[];
  /** The key of the property that should power the x axis. */
  xAxisKey: keyof TData & string;

  /** Whether tooltips should be shown. */
  showTooltip?: boolean;
  /** Whether the labels on the chart for each value should show up. */
  showLabels?: boolean;
};

// "extends unknown" is just to resolve a TS compiler issue specific to generics with components.

/** This is a wrapper around bar charts to be shared amonst all analysis charts.
 * Any changes that apply to all analysis charts should be made here.
 */
export const AnalysisBarChart = <TData extends unknown>({
  data, barData, xAxisKey, showTooltip = false, showLabels = false,
}: Props<TData>) => {
  const theme = useTheme();
  const isLightMode = useAppSelector(selectIsLightMode);
  const textStrokeColor = isLightMode ? theme.palette.common.black : theme.palette.common.white;

  return (
    // Setting the width to 99% because at 100% the container does not resize when shrinking the window.
    // This seems to be a known issue with the ResponsiveContainer and the simplest solution to resolve
    // that issue is to just set width to 99%.
    <ResponsiveContainer width="99%" minWidth="600px" minHeight="300px">
      <BarChart data={data} >
        {showTooltip && <Tooltip />}

        <CartesianGrid />
        <XAxis dataKey={xAxisKey} />
        <YAxis />
        <ReferenceLine y={0} stroke="#000" />

        {barData.map(bar => <Bar isAnimationActive={false} key={bar.dataKey} dataKey={bar.dataKey} name={bar.name} label={showLabels && <CustomizedBarChartLabel stroke={textStrokeColor} />} fill={bar.color} />)}
      </BarChart>
    </ResponsiveContainer>
  );
};