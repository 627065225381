import { GridApi } from 'ag-grid-community';
import { Nullable } from '../../types/util/Nullable';

export const generatePinnedBottomData = (aphYield: Nullable<number>, adjustedYield: Nullable<number>,
  approvedYield: Nullable<number>, gridApi: GridApi) => {
  const result: Record<string, unknown> = {};
  const allColumns = gridApi.getAllGridColumns();
  allColumns.forEach(col => {
    const colId = col.getColId();
    result[colId] = null;
  });

  result['aphYield'] = aphYield;
  result['approvedYield'] = approvedYield;
  result['adjustedYield'] = adjustedYield;

  return result;
};
