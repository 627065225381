import { AvailabilityService, ScenarioPieceGroupType, ScenarioPieceGroupTypeAttributes, ScenarioPieceIdentifier, ScenarioPieceType, ScenarioPieceTypeAttributes } from '@silveus/calculations';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import { RootState } from '../store';
import { getItemsForId } from '../../utils/mapHelpers';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../scenarioPiecesSlice';
import { selectAllInputCostScenarioPiecesByScenarioMap } from '../inputCostScenarioPiecesSlice';
import { selectAllForwardSoldScenarioPiecesByScenarioMap } from '../forwardSoldScenarioPiecesSlice';
import { selectAllHarvestRevenueScenarioPiecesByScenarioMap } from '../harvestRevenueScenarioPiecesSlice';
import { selectAllHailScenarioPiecesByScenarioMap } from '../hailSlice';
import { stableEmptyArrayAsMutable } from '../../utils/stableEmptyArray';
import { selectQuoteById } from '../quotesSlice';
import { selectScenarioById, selectUnitYears } from '../scenariosSlice';
import { selectOfferAvailabilitiesForCountyCommodity } from '../availabilitySlice';
import { getAvailabilityUnits } from '../../utils/unitAvailabilityUtils';
import { isNotNullOrUndefined } from '../../utils/nullHandling';

interface ScenarioPieceIdentifierAvailability {
  scenarioPieceType: ScenarioPieceType | ScenarioPieceGroupType,
  description: string,
  isDisabled: boolean,
  tooltipMessage: string
}

type Inputs = {
  scenarioId: ScenarioId,
  scenarioPieceTypeFilter?: ScenarioPieceType[],
  overrideRowCropScenarioPieces?: RowCropScenarioPiece[],
  useScenarioPieceGroups?: boolean,
};

export const selectScenarioPieceAvailability = (state: RootState, {
  scenarioId,
  scenarioPieceTypeFilter = [],
  overrideRowCropScenarioPieces,
  useScenarioPieceGroups = true,
}: Inputs) => {
  const persistedRowCropScenarioPieces = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), scenarioId);
  const rowCropScenarioPieces = overrideRowCropScenarioPieces !== undefined ? overrideRowCropScenarioPieces : persistedRowCropScenarioPieces;
  const inputCostScenarioPieces: ScenarioPiece[] = getItemsForId(selectAllInputCostScenarioPiecesByScenarioMap(state), scenarioId);
  const forwardSoldScenarioPieces: ScenarioPiece[] = getItemsForId(selectAllForwardSoldScenarioPiecesByScenarioMap(state), scenarioId);
  const harvestRevenueScenarioPieces: ScenarioPiece[] = getItemsForId(selectAllHarvestRevenueScenarioPiecesByScenarioMap(state), scenarioId);
  //const hailScenarioPieceCompositions: HailScenarioPieceComposition[] = getItemsForId(selectAllHailScenarioPieceCompositionsByScenarioMap(state), scenarioId);
  const hailScenarioPieces: ScenarioPiece[] = getItemsForId(selectAllHailScenarioPiecesByScenarioMap(state), scenarioId);
  const otherScenarioPieces = [...inputCostScenarioPieces, ...forwardSoldScenarioPieces, ...harvestRevenueScenarioPieces, ...hailScenarioPieces];

  const scenario = selectScenarioById(state, scenarioId);
  const quote = scenario === null ? null : selectQuoteById(state, scenario.quoteId);

  if (quote === null || scenario === null) return stableEmptyArrayAsMutable<ScenarioPieceIdentifierAvailability>();
  const scenarioUnitQuotingUnits = selectUnitYears(state, scenario.quoteId, scenario.typeId, scenario.practiceId, scenario.highRiskTypeId);
  const insuranceOfferAvailabilities = selectOfferAvailabilitiesForCountyCommodity(state, quote.countyId, quote.commodityCode);

  const availabilityUnits = getAvailabilityUnits(quote, scenario, rowCropScenarioPieces, otherScenarioPieces, scenarioUnitQuotingUnits, insuranceOfferAvailabilities);
  const pieceAvailabilities = AvailabilityService.getScenarioPieceAvailabilitiesForScenario(availabilityUnits);

  const scenarioAvailabilities = Array.from(pieceAvailabilities.scenarioPieceAvailabilities.values());
  const filteredScenarioAvailabilities = scenarioPieceTypeFilter.length === 0 ? scenarioAvailabilities : scenarioAvailabilities.filter(sa => scenarioPieceTypeFilter.includes(sa.id));

  const groupAvailability = new Map<ScenarioPieceGroupType, boolean>();
  const availabilityByIdentifier = new Map<ScenarioPieceIdentifier, ScenarioPieceIdentifierAvailability>();

  for (const spt of filteredScenarioAvailabilities) {
    const spAttributes = Object.values(ScenarioPieceTypeAttributes).find(sp => sp.value === spt.id);
    if (spAttributes === undefined) continue;

    const matchingSpGroup = useScenarioPieceGroups ? Object.values(ScenarioPieceGroupTypeAttributes).find(spg => spg.elements?.includes(spt.id)) : undefined;

    if (isNotNullOrUndefined(matchingSpGroup) && matchingSpGroup.value === ScenarioPieceGroupType.Hail) {
      // Temporarily commenting this out until we add hail back in
      // if (groupAvailability.get(ScenarioPieceGroupType.Hail) !== undefined) {
      //   continue;
      // }
      // if (hailScenarioPieceCompositions.length === 0) {
      //   groupAvailability.set(ScenarioPieceGroupType.Hail, true);
      //   availabilityByIdentifier.set(matchingSpGroup.value, {
      //     scenarioPieceType: matchingSpGroup.value,
      //     description: matchingSpGroup.description,
      //     isDisabled: false,
      //     tooltipMessage: '',
      //   });
      // } else {
      //   groupAvailability.set(ScenarioPieceGroupType.Hail, false);
      //   availabilityByIdentifier.set(matchingSpGroup.value, {
      //     scenarioPieceType: matchingSpGroup.value,
      //     description: matchingSpGroup.description,
      //     isDisabled: true,
      //     tooltipMessage: RestrictionMessageMap.get(AvailabilityRestrictionType.AlreadyElected) ?? 'Hail has already been elected',
      //   });
      // }
      continue;
    }

    const scenarioPieceIdentifier = matchingSpGroup?.value ?? spAttributes.value;
    const availabilityDescription = matchingSpGroup?.description ?? spAttributes.description;

    if (matchingSpGroup && matchingSpGroup.value !== ScenarioPieceGroupType.Hail) {
      const scenarioPieceGroupType = matchingSpGroup.value;
      const isGroupItemAvailable = groupAvailability.get(scenarioPieceGroupType);

      if (isGroupItemAvailable === undefined) {
        groupAvailability.set(scenarioPieceGroupType, spt.isAvailable);
      } else if (!isGroupItemAvailable && spt.isAvailable) {
        groupAvailability.set(scenarioPieceGroupType, spt.isAvailable);
      } else {
        continue;
      }
    }

    availabilityByIdentifier.set(scenarioPieceIdentifier, {
      scenarioPieceType: scenarioPieceIdentifier,
      description: availabilityDescription,
      isDisabled: !spt.isAvailable,
      tooltipMessage: spt.availabilityMessage,
    });
  }

  return Array.from(availabilityByIdentifier.values());
};