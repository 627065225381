import { Grid, List, ListItem, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import React from 'react';
import { Insured } from '../../types/api/insureds/Insured';

interface SummaryInsuredListProps {
  insuredsToShow: Insured[];
}

const SummaryInsuredList = ({ insuredsToShow }: SummaryInsuredListProps) => {
  return (
    <Grid container direction="column" sx={{ height: '100%' }}>
      <Grid item xs="auto">
        <Typography>Clients/Leads</Typography>
      </Grid>
      <Grid item xs>
        <Paper sx={{ height: '100%' }}>
          <List sx={{ height: '100%', overflowY: 'auto' }} disablePadding>
            {insuredsToShow.map(insured =>
              <ListItem key={insured.id} disablePadding>
                <ListItemButton>
                  <ListItemText>{insured.name}</ListItemText>
                </ListItemButton>
              </ListItem>,
            )}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SummaryInsuredList;