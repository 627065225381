import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { ComponentProps } from 'react';
import { ScenarioPieceTypeOption } from '../../../utils/scenarioPieceUtils';

interface ScenarioPieceTypeInputProps {
  index: number;
  scenarioPieceTypeOption: ScenarioPieceTypeOption;
  disabled?: boolean;
  sx?: ComponentProps<typeof FormControlLabel>['sx'];
}

const ScenarioPieceTypeInput = ({ index, scenarioPieceTypeOption, sx, disabled = false }: ScenarioPieceTypeInputProps) => {
  return (
    <Controller
      //selectedScenarioPieceTypes is defined in the MatrixPresetFormFields in matrixPresetForm.component.tsx
      name={`selectedScenarioPieceTypes.${index}.selected`}
      render={({
        field: { value, ...field },
      }) => (
        <FormControlLabel
          {...field}
          value={value}
          label={<Typography variant="body2">{scenarioPieceTypeOption.description}</Typography>}
          sx={sx}
          control={<Checkbox
            checked={value}
            size="small" />}
          disabled={disabled} />
      )} />
  );
};

export default ScenarioPieceTypeInput;
