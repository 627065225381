import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import {
  closeToast,
  selectToastMessage,
  selectToastOpenState,
  selectToastTimeout,
  selectToastClickToClose,
  selectToastType
} from '../../app/toastSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { SyntheticEvent } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/system';
import { IconButton } from '@mui/material';

const Toast = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectToastOpenState);
  const type = useAppSelector(selectToastType);
  const message = useAppSelector(selectToastMessage);
  const shouldTimeout = useAppSelector(selectToastTimeout);
  const allowClickToClose = useAppSelector(selectToastClickToClose);

  const handleClose = (event: SyntheticEvent | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(closeToast());
  };

  const handleCloseClick = () => {
    if (allowClickToClose ?? false) {
      dispatch(closeToast());
    }
  };

  return <Snackbar open={isOpen} autoHideDuration={(shouldTimeout ?? false) ? 5000 : null} onClose={handleClose}>
    <Alert severity={type} sx={{ width: '100%', alignItems: 'center', minHeight: 'auto' }}>
      <Stack direction="row" alignItems="center">
        <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: message }} />
        {(allowClickToClose ?? false) &&
          <IconButton onClick={handleCloseClick} sx={{ margin: '0px 0px 0px 5px', padding: '0px' }}>
            <CloseIcon />
          </IconButton>
        }
      </Stack>
    </Alert>
  </Snackbar>;
};

export default Toast;