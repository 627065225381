import { createScenarioPiece } from '../../../app/scenarioPiecesSlice';
import { AppDispatch } from '../../../app/store';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { ScenarioPiece } from '../../../types/api/ScenarioPiece';
import ScenarioPieceDefinition from '../../../types/app/ScenarioPieceDefinition';

type OptionalPropertiesOf<T> = {
  [K in keyof T as T[K] extends Required<T>[K] ? never : K]: T[K]
}

// Get the optional properties of ScenarioPieceDefinition - these are the only potential ones that will need defaults.
type OptionalScenarioPieceDefinitionProperties = OptionalPropertiesOf<ScenarioPieceDefinition>;


type SuppliedDefaults = Pick<Required<OptionalScenarioPieceDefinitionProperties>, 'shouldShowThreeStageSummaryExpander' | 'premiumBreakdownBarColor'>;


// How to use this file:
// To add a new default, add its property to the string union of the Pick<> type above, then add the default value to the object.
export const scenarioPieceDefinitionDefaultValues: SuppliedDefaults = {
  shouldShowThreeStageSummaryExpander: true,
  premiumBreakdownBarColor: '#808080',
};

export function baseRowScenarioPieceAdd(scenarioPiece: ScenarioPiece, dispatch: AppDispatch) {
  return dispatch(createScenarioPiece({ scenarioPiece: scenarioPiece as RowCropScenarioPiece }));
}
