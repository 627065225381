import { Nullable } from '../../util/Nullable';
import { ContactId, EntityTypeId, InsuredId, TaxTypeId } from '../PrimaryKeys';
import { PersonOfInterestType } from '../enums/contactInfo/personOfInterestType';
import { PersonOrBusiness } from '../enums/contactInfo/personOrBusiness';
import { InsuredAddress } from './Insured';
import { StrictOmit } from '../../util/StrictOmit';


export interface PersonOfInterest {
  id: ContactId;
  personOrBusiness: Nullable<PersonOrBusiness>;
  insuredId: InsuredId;
  name: Nullable<string>;
  firstName: Nullable<string>;
  middleName: Nullable<string>;
  lastName: Nullable<string>;
  suffix: Nullable<string>;
  email: Nullable<string>;
  phone: Nullable<string>;
  address: Nullable<InsuredAddress>;
  taxId: Nullable<string>;
  taxTypeId: Nullable<TaxTypeId>;
  corporationStateId: Nullable<string>;
  entityTypeId: Nullable<EntityTypeId>;
  personOfInterestType: PersonOfInterestType;
  personOfInterestId: Nullable<ContactId>;
}

export const defaultPOI: StrictOmit<PersonOfInterest, 'id' | 'personOrBusiness' | 'entityTypeId' | 'personOfInterestType' | 'insuredId'> = {
  name: '',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  email: '',
  phone: '',
  address: {
    addressLine1: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    composite: '',
  },
  taxTypeId: null,
  taxId: '',
  corporationStateId: '',
  personOfInterestId: null,
};

type AtLeastOne<T> = [T, ...T[]];
export type PersonOfInterestTypes = AtLeastOne<PersonOfInterestType>;