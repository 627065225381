import axios from 'axios';
import { ApplicationWizardId, InsuredId } from '../types/api/PrimaryKeys';
import { ApplicationWizard, ApplicationWizardSummary } from '../types/api/applicationWizard/applicationWizard';
import { AppTask } from '../types/api/AppTask';
import { envBaseApiUrl } from '../constants/envConstants';

export const getApplicationWizardSummaries = (insuredId: InsuredId, year: number) => axios.get<ApplicationWizardSummary[]>(`${envBaseApiUrl}/insureds/${insuredId}/application-wizard-summaries/${year}`);

export const createApplicationWizard = (applicationWizard: ApplicationWizard) => axios.post<void>(`${envBaseApiUrl}/application-wizards/`, applicationWizard);

export const updateApplicationWizard = (applicationWizard: ApplicationWizard) => axios.put<void>(`${envBaseApiUrl}/application-wizards/`, applicationWizard);

export const deleteApplicationWizard = (applicationWizardId: ApplicationWizardId) => axios.delete(`${envBaseApiUrl}/application-wizards/${applicationWizardId}/`);

export const fetchApplicationWizard = (applicationWizardId: ApplicationWizardId) => axios.get<ApplicationWizard>(`${envBaseApiUrl}/application-wizards/${applicationWizardId}`);

export const getAllApplicationWizardsForInsured = (insuredId: InsuredId) => axios.get<ApplicationWizard[]>(`${envBaseApiUrl}/insureds/${insuredId}/application-wizards`);

export const finalizeApplicationWizard = (applicationWizardId: ApplicationWizardId, appTasks: AppTask[]) => axios.post(`${envBaseApiUrl}/application-wizards/${applicationWizardId}/finalize-app-wizard`, appTasks);

export const updateRelatedAppTaskLastFormRelatedChange = (applicationWizardId: ApplicationWizardId) => axios.put(`${envBaseApiUrl}/application-wizards/${applicationWizardId}/last-generated-form-related-change`);