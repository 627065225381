import { Dialog, DialogContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@mui/material';
import { useFieldArray, useForm, FormProvider, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import HailEndorsement from '../../../types/api/hail/hailEndorsement';
import { HailEndorsementId, HailScenarioPieceId, UnitYearId } from '../../../types/api/PrimaryKeys';
import HailScenarioPieceEndorsement from '../../../types/api/hail/HailScenarioPieceEndorsement';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import { WeightedEndorsementRatesState, getEndorsementRates } from '../utils/hailEndorsementsUtils';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { currentlySelectedScenario, selectUnitYears } from '../../../app/scenariosSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import HailScenarioPieceRate from '../../../types/api/hail/HailScenarioPieceRate';
import { MuiDialogCloseReason } from '../../../types/mui/MuiDialogCloseReason';
import ScenarioModalHeader from '../../../components/modal/scenarioModalHeader';
import HailModalHeaderControls from '../hailModalHeaderControls';
import { stableEmptyArrayAsMutable } from '../../../utils/stableEmptyArray';
import UnitYear from '../../../types/api/UnitYear';

type HailEndorsementsModalProps = {
  hailScenarioPiece: HailScenarioPiece;
  availableHailEndorsements: HailEndorsement[];
  hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[];
  hailProduct: HailProduct;
  hailScenarioPieceRates: HailScenarioPieceRate[];
  hailScenarioPieceUnits: UnitYearId[];
  onCloseClicked: () => void;
  onHailScenarioPieceEndorsementsUpdated: (hailScenarioPieceId: HailScenarioPieceId, updatedHailScenarioPieceEndorsements: HailScenarioPieceEndorsement[]) => void;
  showModal: boolean;
}

type HailCardEndorsementFields = { hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[] };

const HailEndorsementsModal = ({ hailScenarioPiece, availableHailEndorsements, hailScenarioPieceEndorsements, hailProduct, hailScenarioPieceRates, hailScenarioPieceUnits, onCloseClicked, onHailScenarioPieceEndorsementsUpdated, showModal }: HailEndorsementsModalProps) => {
  const scenario = useAppSelector(currentlySelectedScenario);
  const quote = useAppSelector(state => scenario === null ? null : selectQuoteById(state, scenario.quoteId));
  const unitYears = useAppSelector(state => scenario === null ? stableEmptyArrayAsMutable<UnitYear>() : selectUnitYears(state, scenario.quoteId, scenario.typeId, scenario.practiceId, scenario.highRiskTypeId));
  const hailEndorsementIds = hailScenarioPieceEndorsements.map(hspe => hspe.endorsementId);
  const [selectedEndorsementIds, setSelectedEndorsementIds] = useState(hailEndorsementIds);

  const defaultValues = availableHailEndorsements.map(ahe => {
    let hailScenarioPieceEndorsement = hailScenarioPieceEndorsements.find(hspe => hspe.endorsementId === ahe.hailPlanEndorsementId);
    if (hailScenarioPieceEndorsement !== undefined) return hailScenarioPieceEndorsement;
    hailScenarioPieceEndorsement = {
      hailScenarioPieceEndorsementId: generatePrimaryKey(),
      hailScenarioPieceId: hailScenarioPiece.hailScenarioPieceId,
      endorsementId: ahe.hailPlanEndorsementId,
      perAcre: false,
      offlineCreatedOn: undefined,
      offlineLastUpdatedOn: undefined,
      offlineDeletedOn: undefined,
    };
    return hailScenarioPieceEndorsement;
  });

  const methods = useForm<HailCardEndorsementFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      hailScenarioPieceEndorsements: defaultValues,
    },
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'hailScenarioPieceEndorsements',
  });

  const [endorsementRates, setEndorsementRates] = useState<WeightedEndorsementRatesState>(new Map());

  useEffect(() => {
    methods.setValue('hailScenarioPieceEndorsements', defaultValues);
  }, [hailScenarioPieceEndorsements]);

  useEffect(() => {
    if (quote?.quickQuote === undefined) return;
    setEndorsementRates(getEndorsementRates(availableHailEndorsements, hailProduct.hailRates, hailScenarioPieceRates, quote.quickQuote, unitYears, hailScenarioPieceUnits));
  }, [availableHailEndorsements, hailProduct.hailRates, hailScenarioPieceRates, quote?.quickQuote, hailScenarioPieceUnits]);

  const handleClose = (reason?: MuiDialogCloseReason) => {
    if (reason === 'backdropClick') {
      return;
    }
    const formValues = methods.getValues().hailScenarioPieceEndorsements;
    const newHailScenarioPieceEndorsements: HailScenarioPieceEndorsement[] = [];
    selectedEndorsementIds.forEach(sei => {
      const hailScenarioPieceEndorsement = formValues.find(fv => fv.endorsementId === sei);
      if (hailScenarioPieceEndorsement !== undefined) {
        newHailScenarioPieceEndorsements.push(hailScenarioPieceEndorsement);
      }
    });

    onHailScenarioPieceEndorsementsUpdated(hailScenarioPiece.hailScenarioPieceId, newHailScenarioPieceEndorsements);
    onCloseClicked();
  };

  const onToggleEndorsementChecked = (hailEndorsementId: HailEndorsementId) => {
    const tempSelectedEndorsementIds = [...selectedEndorsementIds];
    const index = tempSelectedEndorsementIds.indexOf(hailEndorsementId);
    if (index >= 0) {
      tempSelectedEndorsementIds.splice(index, 1);
    } else {
      tempSelectedEndorsementIds.push(hailEndorsementId);
    }
    setSelectedEndorsementIds(tempSelectedEndorsementIds);
  };

  const tableCellStyleWide = {
    width: '85vw',
  } as const;

  const tableCellStyleNarrow = {
    width: '5vw',
  } as const;

  const getRateForThisEndorsement = (endorsementId: HailEndorsementId) => {
    const rate = endorsementRates.get(endorsementId);
    return rate?.toFixed(3) ?? '';
  };

  const headerControls = <HailModalHeaderControls onCloseClicked={handleClose}/>;

  return (
    <Dialog open={showModal} onClose={(_, reason) => handleClose(reason)} maxWidth={false} scroll="body" disableEscapeKeyDown={true}>
      <FormProvider {...methods}>
        { scenario !== null && <ScenarioModalHeader scenarioId={scenario.scenarioId} headerText="Endorsement Browser" headerControls={headerControls} /> }
        <DialogContent sx={{ p: 0 }}>
          <TableContainer sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <Table stickyHeader sx={{ minWidth: 750, maxWidth: '50vw' }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={tableCellStyleNarrow}></TableCell>
                  <TableCell sx={tableCellStyleWide}>Name</TableCell>
                  <TableCell sx={tableCellStyleNarrow}>Rate</TableCell>
                  <TableCell sx={{ tableCellStyleNarrow, display: 'flex', justifyContent: 'center', alignItems: 'center', whiteSpace: 'nowrap' }}  >Per Acre?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((hailScenarioPieceEndorsement, hailEndorsementIndex) => {
                  const hailEndorsement = availableHailEndorsements.find(ahe => ahe.hailPlanEndorsementId === hailScenarioPieceEndorsement.endorsementId);
                  if (hailEndorsement === undefined) throw new Error(`Cannot find endorsement for endorsement id ${hailScenarioPieceEndorsement.endorsementId}`);
                  const isSelected = selectedEndorsementIds.includes(hailScenarioPieceEndorsement.endorsementId);
                  return (
                    <TableRow key={hailEndorsementIndex}>
                      <TableCell sx={{ tableCellStyleNarrow, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Checkbox
                          sx={{ padding: '0 !important' }}
                          id={`select-endorsement-check-${hailEndorsementIndex}`}
                          checked={isSelected}
                          onClick={() => onToggleEndorsementChecked(hailEndorsement.hailPlanEndorsementId)}
                        />
                      </TableCell>
                      <TableCell sx={tableCellStyleWide}>{hailEndorsement.endorsementName}</TableCell>
                      <TableCell sx={tableCellStyleNarrow}>{getRateForThisEndorsement(hailEndorsement.hailPlanEndorsementId)}</TableCell>
                      <TableCell sx={{ tableCellStyleNarrow, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Controller
                          name={`hailScenarioPieceEndorsements.${hailEndorsementIndex}.perAcre`}
                          control={methods.control}
                          render={({
                            field: { ref, value, ...field }, fieldState: { error },
                          }) => (
                            <Checkbox
                              id={`per-acre-endorsement-check-${hailEndorsementIndex}`}
                              sx={{ padding: '0 !important' }}
                              inputRef={ref}
                              {...field}
                              checked={value}
                            />
                          )} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </FormProvider>
    </Dialog>
  );
};

export default HailEndorsementsModal;