import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { MenuItem, TextField } from '@mui/material';
import { AIPId } from '../../../types/api/PrimaryKeys';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectAllAIPs } from '../../../app/aipSlice';
import { validatePreviousAIP } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import { handleValidationResult } from './validationHelpers';
import { AppTask } from '../../../types/api/AppTask';
import { useEffect } from 'react';
import { AppType } from '../../../types/api/enums/application/AppType.enum';

type PreviousAipInputProps = {
  value: Nullable<AIPId>;
  index: number;
  canEditAppTask: boolean;
}

const PreviousAIPInput = ({ value, index, canEditAppTask }: PreviousAipInputProps) => {
  const inputName = `appTasks.${index}.previousAIPId`;
  const { control, resetField } = useFormContext();

  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });
  const aips = useAppSelector(selectAllAIPs);

  useEffect(() => {
    if (!(currentAppTask.isNewCT ?? false) && currentAppTask.appType !== AppType.CancelTransfer) {
      resetField(inputName, { defaultValue: null });
    }
  }, [currentAppTask.isNewCT]);

  const shouldShow = (currentAppTask.isNewCT ?? false) || currentAppTask.appType === AppType.CancelTransfer;
  if (!shouldShow) {
    return null;
  }

  return (
    <Controller
      control={control}
      shouldUnregister={false}
      name={inputName}
      rules={{ validate: val => handleValidationResult(validatePreviousAIP(val, currentAppTask)) }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          select
          disabled={!(currentAppTask.isNewCT ?? false) || !canEditAppTask}
          error={!!error}
          inputRef={ref}
          size="small"
          value={field.value}
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          {aips.map(aip => <MenuItem sx={{ fontSize: '12px' }} key={aip.aipId} value={aip.aipId}>{aip.aipName}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default PreviousAIPInput;