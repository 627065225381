import { AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { InsuredId } from '../../types/api/PrimaryKeys';
import { openToast } from '../../app/toastSlice';
import SummaryInsuredList from './summaryInsuredList.component';
import SummaryStateList from './summaryStateList.component';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { selectAllInsureds, selectPreDownloadedInsureds } from '../../app/insuredsSlice';
import { selectAllCounties, selectAllStates, selectPreDownloadedCounties, selectYears } from '../../app/admSlice';
import { groupBy } from '../../utils/arrayUtils';
import {
  clearOutExistingAdmDataRequest,
  getBulkAdmDataRequest, getBulkIceDataRequest,
  getBulkInsuredDataRequest, getBulkPrivateProductDataRequest
} from '../../services/requestInterception/offlineRequestInterceptor';
import { setLastOfflineBulkDataDownloadTime } from '../../app/onlineSlice';

interface DataSummaryDialogProps {
  selectedInsureds: InsuredId[];
  countiesToDownload: string[];
  open: boolean;
  onClose: () => void;
}

const DataSummaryDialog = ({ selectedInsureds, countiesToDownload, open, onClose }: DataSummaryDialogProps) => {
  const preDownloadedInsureds = useAppSelector(selectPreDownloadedInsureds);
  const preDownloadedCounties = useAppSelector(selectPreDownloadedCounties);
  const dispatch = useAppDispatch();
  const insureds = useAppSelector(selectAllInsureds);
  const counties = useAppSelector(selectAllCounties);
  const states = useAppSelector(selectAllStates);
  const years = useAppSelector(selectYears);
  const insuredsToShow = insureds.filter(insured => selectedInsureds.includes(insured.id));
  const countiesToShow = counties.filter(county => countiesToDownload.includes(county.countyId));

  const insuredsToRemove = preDownloadedInsureds.filter(insuredId => !selectedInsureds.includes(insuredId));
  const countiesToRemove = preDownloadedCounties.filter(countyId => !countiesToDownload.includes(countyId));

  const countiesGroupedByState = groupBy(countiesToShow, c => c.stateCode);
  const statesToDisplay = [...countiesGroupedByState.keys()].sort();

  const handleClose = () => {
    onClose();
  };

  const handleDownloadStart = async () => {
    dispatch(openToast({ type: 'info', message: 'Your offline data is downloading', shouldTimeout: false, allowClickToClose: false }));
    handleClose();
    const year = years[0];
    let alertType: AlertColor = 'success';
    let message = 'You are now offline-ready';
    try {
      //TODO #61493: Make function to delete locally stored insured data from IDB
      // This is going to be a big challenge to track down all of the records we need to get rid of from all DBs in a timely fashion
      // Also, delete operations are not quick in IDB, so once they are all located, they will still all need to be deleted.
      await clearOutExistingAdmDataRequest(countiesToRemove, insuredsToRemove);

      const admDataPromise = getBulkAdmDataRequest(year, countiesToDownload);
      const insuredDataPromise = getBulkInsuredDataRequest(selectedInsureds);
      const iceDataPromise = getBulkIceDataRequest(year, countiesToDownload);
      const privateProductDataPromise = getBulkPrivateProductDataRequest(year, countiesToDownload);
      await Promise.all([admDataPromise, insuredDataPromise, iceDataPromise, privateProductDataPromise]);

      const currentTime = new Date();

      // Record the timestamp of the last time we know a bulk download was completed.
      dispatch(setLastOfflineBulkDataDownloadTime(currentTime.toISOString()));
    } catch {
      alertType = 'error';
      message = 'An error occurred. Please try again.';
    }

    dispatch(openToast({ type: alertType, message: message, shouldTimeout: false, allowClickToClose: true }));
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Data Management Summary</DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2}>
          <Grid item xs>
            <SummaryInsuredList insuredsToShow={insuredsToShow} />
          </Grid>
          <Grid item xs>
            <SummaryStateList statesToDisplay={statesToDisplay} states={states} countiesGroupedByState={countiesGroupedByState} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end" rowSpacing={1}>
          <Grid item xs={12}>
            <Typography>*Any states, counties, or clients not listed above will be removed from this PC if they are currently installed.</Typography>
          </Grid>
          <Grid item xs="auto">
            <Button sx={{ marginLeft: 'auto' }} size="small" onClick={handleDownloadStart}>Start Download</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DataSummaryDialog;