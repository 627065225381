import { IEnumAttributes } from '../IEnumAttributes';

/* eslint-disable no-unused-vars */
export enum ESignStatus {
  NotSet,
  AuthoritativeCopy,
  Completed,
  Correct,
  Created,
  Declined,
  Deleted,
  Delivered,
  Sent,
  Signed,
  Template,
  TimedOut,
  TransferCompleted,
  Voided,
}

export const ESignStatusAttributes: Readonly<Record<ESignStatus, IEnumAttributes<ESignStatus>>> = {
  [ ESignStatus.NotSet ]: { name: '', description: '', value: ESignStatus.NotSet },
  [ ESignStatus.AuthoritativeCopy ]: { name: 'Authoritative Copy', description: 'Authoritative Copy', value: ESignStatus.AuthoritativeCopy },
  [ ESignStatus.Completed ]: { name: 'Completed', description: 'Completed', value: ESignStatus.Completed },
  [ ESignStatus.Correct ]: { name: 'Correct', description: 'Correct', value: ESignStatus.Correct },
  [ ESignStatus.Created ]: { name: 'Created', description: 'Created', value: ESignStatus.Created },
  [ ESignStatus.Declined ]: { name: 'Declined', description: 'Declined', value: ESignStatus.Declined },
  [ ESignStatus.Deleted ]: { name: 'Deleted', description: 'Deleted', value: ESignStatus.Deleted },
  [ ESignStatus.Delivered ]: { name: 'Delivered', description: 'Delivered', value: ESignStatus.Delivered },
  [ ESignStatus.Sent ]: { name: 'Sent', description: 'Sent', value: ESignStatus.Sent },
  [ ESignStatus.Signed ]: { name: 'Signed', description: 'Signed', value: ESignStatus.Signed },
  [ ESignStatus.Template ]: { name: 'Template', description: 'Template', value: ESignStatus.Template },
  [ ESignStatus.TimedOut ]: { name: 'Timed Out', description: 'Timed Out', value: ESignStatus.TimedOut },
  [ ESignStatus.TransferCompleted ]: { name: 'Transfer Completed', description: 'Transfer Completed', value: ESignStatus.TransferCompleted },
  [ ESignStatus.Voided ]: { name: 'Voided', description: 'Voided', value: ESignStatus.Voided },
};