import { InsuredId, ScenarioId, UnitYearId } from '../../../types/api/PrimaryKeys';
import UnitYear from '../../../types/api/UnitYear';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { Quote } from '../../../types/api/Quote';
import { ScenarioRequestDTO } from '@silveus/calculations';
import { ReportUnitData } from '../../../types/api/reports/ReportUnitData';
import UnitYearAph from '../../../types/api/UnitYearAph';
import { getReportUnitData } from './getReportUnitData';

export const getReportUnitDataByScenarioId = (
  unitsByScenarioMapData: Map<ScenarioId, UnitYear[]>,
  year: number,
  scenarios: RowCropScenario[],
  quotes: Quote[],
  scenarioRequestsByScenarioId: Map<ScenarioId, ScenarioRequestDTO>,
  insuredId: InsuredId,
  unitHistoryByUnitYear: Map<UnitYearId, UnitYearAph[]>,
): Map<ScenarioId, ReportUnitData[]> => {
  const unitReportDataByScenarioId = new Map<ScenarioId, ReportUnitData[]>();

  for (const [scenarioId, units] of unitsByScenarioMapData) {
    const scenarioRequest = scenarioRequestsByScenarioId.get(scenarioId) ?? null;
    const scenario = scenarios.find(scenario => scenario.scenarioId === scenarioId) ?? null;
    const quote = scenario === null ? null : quotes.find(quote => quote.quoteId === scenario.quoteId) ?? null;

    const reportUnits: ReportUnitData[] = quote === null || scenario === null
      ? []
      : getReportUnitData(
        quote.quickQuote,
        scenario.quickUnit,
        year,
        insuredId,
        scenarioRequest,
        units,
        unitHistoryByUnitYear,
      );

    unitReportDataByScenarioId.set(scenarioId, reportUnits);
  }

  return unitReportDataByScenarioId;
};