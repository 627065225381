import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../../types/util/Nullable';
import NumberTextField, { onNumericInputChange } from '../../../numberTextField/numberTextField.component';
import { Info } from '../../../info/info';
import { validateDollarAmount } from '../../../../pages/units/validations/genericDollarAmountValidation';
import { FormInputProps } from '../../scenario/formInputProps';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

export interface MpExpectedInputCostsProps extends FormInputProps {
  expectedInputCosts: Nullable<number>;
}

export type MpExpectedInputCostsFields = {
  expectedInputCosts: number;
}

const MpExpectedInputCostsInput = ({ expectedInputCosts, sx, autoFocus = false, disabled = false }: MpExpectedInputCostsProps) => {
  const fieldName = ExtendedDataFormInputParameterNames.ExpectedInputCosts;
  const { control } = useFormContext();

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: (value: Nullable<number>) => validateDollarAmount(value, false) }}
      defaultValue={expectedInputCosts ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          type="number"
          label="MP Expected Cost"
          fullWidth
          inputProps={{ step: 0.01, min: 0 }}
          InputProps={{
            endAdornment: <Info tooltipContent="MP Expected Input Costs" />,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          sx={sx}
          onChange={e => onNumericInputChange(e, field, 2)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default MpExpectedInputCostsInput;
