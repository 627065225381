const MESSAGES = {
  formError: 'Please correct the errors below before continuing.',
  required: 'Required',
  maxLength: (max: number) => `Length must not exceed ${max}`,
  minimum: (min: number)  => `Must be ${min} or greater.`,
  maximum: (max: number) => `Must be ${max} or less.`,
  selectAnOption: 'Please select an option',
  greaterThan: (min: number) => `Must be greater than ${min}`,
  lessThan: (max: number) => `Must be less than ${max}`,
  step: (step: number) => `Value must be an increment of ${step}`,
} as const;

export default MESSAGES;
