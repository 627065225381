import { Grid, Stack, Tab, Tabs } from '@mui/material';
import { TabPanel } from '../../utils/tabPanel';
import { cloneElement, CSSProperties, ReactElement, useEffect, useState } from 'react';
import { Nullable } from '../../types/util/Nullable';
import { selectAppDrawerWidth } from '../../app/appDrawerSlice';
import { useAppSelector } from '../../hooks/reduxHooks';

interface TabbedContentProps {
  tabNames: string[];
  handlePreChange?: () => boolean;
  handlePostChange?: () => void;
  children: ReactElement[];
  startingTabIndex?: number;
  shouldScroll?: boolean;
  stackStyling?: CSSProperties;
}

const TabbedContent = ({ tabNames, handlePreChange, handlePostChange, children, startingTabIndex, stackStyling, shouldScroll = false }: TabbedContentProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [buttonArea, setButtonArea] = useState<Nullable<ReactElement>>(null);
  const drawerWidth = useAppSelector(selectAppDrawerWidth);

  useEffect(() => {
    setTabIndex(startingTabIndex ?? 0);
  }, [startingTabIndex]);

  const renderButtonArea = (tabSpecificFunction: ReactElement) => {
    setButtonArea(tabSpecificFunction);
  };

  const handleTabChange = (value: number) => {
    const shouldChange = handlePreChange ? handlePreChange() : true;
    if (shouldChange) {
      setTabIndex(value);
      setButtonArea(null);
      if (handlePostChange) {
        handlePostChange();
      }
    }
  };

  return (
    <Stack spacing={2} style={{ ...stackStyling }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Tabs value={tabIndex} scrollButtons={shouldScroll} variant={shouldScroll ? 'scrollable' : 'standard'} onChange={(e, value) => handleTabChange(value)} sx={{
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0.3,
            },
            width: `calc(${drawerWidth}px - 29px)`,
          }}>
            {tabNames.map((name, index) => <Tab label={name} key={index} value={index} />)}
          </Tabs>
        </Grid>
        <Grid item>
          {buttonArea}
        </Grid>
      </Grid>
      {
        children.map((child, index) => {
          const newChild = cloneElement(child, { buttonArea: renderButtonArea });
          return <TabPanel value={tabIndex} key={index} index={index}>{newChild}</TabPanel>;
        })
      }
    </Stack>
  );
};

export default TabbedContent;