import { RootState } from '../../app/store';
import {
  HailScenarioPieceCompositionId,
  ScenarioId,
  ScenarioPieceGroupId,
  ScenarioPieceId
} from '../../types/api/PrimaryKeys';
import {
  CalculationOrchestrator,
  HistoricalQuoteRequestDTO,
  HistoricalQuoteResponseDTO,
  HistoricalQuoteYearRequestDTO,
  MatrixRequestDTO,
  ProducerYieldHistoryScenarioResult,
  ProductOfferAvailabilities,
  ScenarioPieceGroupRequestDTO,
  ScenarioPieceGroupType,
  ScenarioPieceRequestDTO,
  ScenarioPieceType,
  ScenarioRequestDTO,
  ScenarioResponseDTO
} from '@silveus/calculations';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { getApplicableUnits } from './calculationUnits';
import { CellDataProviderInput } from '@silveus/react-matrix';
import BaseUnit from '../../types/api/BaseUnit';
import { selectUnitYearsForScenario } from '../../app/scenariosSlice';
import { selectAdjustedYieldForScenario, selectApprovedYieldForScenario } from '../../app/unitsSlice';
import { getItemsForId } from '../../utils/mapHelpers';
import HistoricalPercentages from '../../types/api/adm/HistoricalPercentages';
import { Nullable } from '../../types/util/Nullable';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';
import ForwardSoldScenarioPiece from '../../types/api/ForwardSoldScenarioPiece';
import { selectAllScenarioPiecesByScenarioMap } from '../../app/sharedSelectors';
import HarvestRevenueScenarioPiece from '../../types/api/HarvestRevenueScenarioPiece';
import { generatePrimaryKey, toPrimaryKey } from '../../utils/primaryKeyHelpers';
import { ScenarioPieceGroup, ScenarioPieceGroupMember } from '../../types/api/ScenarioPieceGroup';
import { selectAllScenarioPieceGroupsByScenarioMap } from '../../app/scenarioPieceGroupsSlice';
import HailScenarioPiece from '../../types/api/HailScenarioPiece';
import {
  selectAllHailScenarioPieceCompositionsByScenarioMap,
  selectAllHailScenarioPiecesByScenarioMap
} from '../../app/hailSlice';
import HistoricalStormEvent from '../../types/api/adm/HistoricalStormEvent';
import { getScenarioPieceDefinition } from '../../constants/productDefinitions/scenarioPieceDefinitionRecords';
import { selectOfferAvailabilitiesForCountyCommodity } from '../../app/availabilitySlice';

export const getScenarioRequest = async (state: RootState, scenarioId: ScenarioId): Promise<ScenarioRequestDTO> => {
  const allScenarioPieceGroupsForScenario = getItemsForId(selectAllScenarioPieceGroupsByScenarioMap(state), scenarioId);
  const activeScenarioPieceGroups = allScenarioPieceGroupsForScenario.filter(spg => spg.isActive);
  const inactiveScenarioPieceGroups = allScenarioPieceGroupsForScenario.filter(spg => !spg.isActive);

  const inactiveScenarioPiecesInGroups = inactiveScenarioPieceGroups.flatMap(spg => spg.scenarioPieceGroupMembers.map(spgm => spgm.scenarioPieceId));

  const allScenarioPiecesForScenario = getItemsForId(selectAllScenarioPiecesByScenarioMap(state), scenarioId);

  const activeAndValidScenarioPieces = allScenarioPiecesForScenario.filter(sp =>
    sp.isActive &&
    !inactiveScenarioPiecesInGroups.includes(sp.scenarioPieceId) &&
    !sp.isInvalid,
  );

  const hailScenarioPieces = getItemsForId(selectAllHailScenarioPiecesByScenarioMap(state), scenarioId);
  const hailScenarioPieceComposition = selectAllHailScenarioPieceCompositionsByScenarioMap(state).get(scenarioId);

  const firstItem = hailScenarioPieceComposition?.at(0);
  if (firstItem !== undefined && hailScenarioPieces.length > 0) {
    const hailScenarioPieceGroup = getHailScenarioPieceGroup(scenarioId, firstItem.hailScenarioPieceCompositionId, hailScenarioPieces);
    activeScenarioPieceGroups.push(hailScenarioPieceGroup);
  }
  return await getScenarioRequestDTO(state, scenarioId, activeAndValidScenarioPieces, activeScenarioPieceGroups);
};

const getHailScenarioPieceGroup = (scenarioId: ScenarioId, hailScenarioPieceCompositionId: HailScenarioPieceCompositionId, hailScenarioPieces: HailScenarioPiece[]): ScenarioPieceGroup => {
  const scenarioPieceGroupId = toPrimaryKey<ScenarioPieceGroupId>(hailScenarioPieceCompositionId);

  const hailScenarioPieceGroupMembers: ScenarioPieceGroupMember[] = hailScenarioPieces.map(hailScenarioPiece => {
    const hailScenarioPieceGroupMember: ScenarioPieceGroupMember = {
      scenarioPieceGroupId: scenarioPieceGroupId,
      scenarioPieceGroupMemberId: generatePrimaryKey(),
      scenarioPieceId: hailScenarioPiece.scenarioPieceId,
    };

    return hailScenarioPieceGroupMember;
  });

  const hailScenarioPieceGroup: ScenarioPieceGroup = {
    isActive: true,
    offlineCreatedOn: undefined,
    offlineDeletedOn: undefined,
    offlineLastUpdatedOn: undefined,
    primaryScenarioPieceId: hailScenarioPieces[0].scenarioPieceId,
    scenarioId: scenarioId,
    scenarioPieceGroupId: scenarioPieceGroupId,
    scenarioPieceGroupMembers: hailScenarioPieceGroupMembers,
    scenarioPieceGroupType: ScenarioPieceGroupType.Hail,
  };

  return hailScenarioPieceGroup;
};

export const getForwardSoldScenarioRequest = async (state: RootState, scenarioId: ScenarioId, forwardSoldScenarioPiece: ForwardSoldScenarioPiece): Promise<ScenarioRequestDTO> => {
  return await getScenarioRequestDTO(state, scenarioId, [forwardSoldScenarioPiece], [], true);
};

export const getHarvestRevenueScenarioRequest = async (state: RootState, scenarioId: ScenarioId, harvestRevenueScenarioPiece: HarvestRevenueScenarioPiece, forwardSoldScenarioPiece: ForwardSoldScenarioPiece | undefined): Promise<ScenarioRequestDTO> => {
  const scenarioPieces: ScenarioPiece[] = [harvestRevenueScenarioPiece];
  if (forwardSoldScenarioPiece) {
    scenarioPieces.push(forwardSoldScenarioPiece);
  }
  return await getScenarioRequestDTO(state, scenarioId, scenarioPieces, [], true);
};

export const getHailScenarioRequest = async (state: RootState, scenarioId: ScenarioId, hailScenarioPieceCompositionId: HailScenarioPieceCompositionId, hailScenarioPieces: HailScenarioPiece[]): Promise<ScenarioRequestDTO> => {
  const hailScenarioPieceGroup = getHailScenarioPieceGroup(scenarioId, hailScenarioPieceCompositionId, hailScenarioPieces);
  return await getScenarioRequestDTO(state, scenarioId, hailScenarioPieces, [hailScenarioPieceGroup], true);
};

/** Runs calculations for an entire scenario and all of its pieces, based on the current supplied state.
 * The calculation is only computed and then returned - it is the caller's responsibility to take action on it.
 */
export const runCalculationForScenario = (scenario: ScenarioRequestDTO): ScenarioResponseDTO => {
  const scenarioResult = CalculationOrchestrator.calculateScenario(scenario);
  return scenarioResult;
};

/**
 * Runs calculations for an entire matrix and all scenarios therein, based on the current supplied state.
 * The calculation is only computed and then returned - it is the caller's responsibility to take action on it.
 **/
export const runCalculationForMatrix = (scenarios: ScenarioRequestDTO[], axisValues: readonly CellDataProviderInput[], selectedScenarioPieceTypes: readonly (ScenarioPieceType | ScenarioPieceGroupType)[]) => {
  const matrixRequestDto: MatrixRequestDTO = {
    axisValues,
    scenarios,
    selectedScenarioPieceTypes,
  };

  const matrixResult = CalculationOrchestrator.calculateMatrix(matrixRequestDto);
  const mappedResult = matrixResult
    .map(mr => ({
      ...mr, overlays: mr.overlays.map(o => ({
        ...o, children: o.children.map(c =>
          ({ ...c, overlayChildId: c.scenarioPieceType.toString() }),
        ),
      }),
      ),
    }),
    );

  return mappedResult;
};

export const runCalculationForHistoricalQuote = (producerYieldHistory: ProducerYieldHistoryScenarioResult[], percentChanges: HistoricalPercentages[], scenario: Nullable<ScenarioRequestDTO>, stormEvents: HistoricalStormEvent[], isQuickQuote: boolean, calculateProductsForMissingYears: boolean): HistoricalQuoteResponseDTO => {
  if (scenario === null || producerYieldHistory.length === 0) return {
    scenarioId: '',
    yearResults: [],
  };

  const historicalQuoteYears = producerYieldHistory.map(yieldHistory => {
    const percentChange = percentChanges.find(percentChange => percentChange.year === yieldHistory.year);

    //Get the storm events for the current year
    const stormEventsForYear = stormEvents.filter(stormEvent => stormEvent.year === yieldHistory.year);

    //Find the first hurricane of the year
    const firstHurricaneEventForYear = stormEventsForYear.find(stormEvent => stormEvent.insuranceOptionCode === null) ?? null;

    //Get all tropical storms for the year
    const tropicalStorms = stormEventsForYear.filter(stormEvent => stormEvent.insuranceOptionCode !== null);

    const hurricaneEventOccurred = firstHurricaneEventForYear !== null;

    //The # of tropical storms is the number of storms that occur before the first hurricane.
    // If there is no hurricane, it's just the number of tropical storm events
    const numberOfTropicalStorms = firstHurricaneEventForYear === null ? tropicalStorms.length : tropicalStorms.filter(stormEvent => stormEvent.date < firstHurricaneEventForYear.date).length;

    const historicalQuoteYear: HistoricalQuoteYearRequestDTO = {
      year: yieldHistory.year,
      pricePercentChange: percentChange?.percentChangePrice ?? 0,
      countyYieldPercentChange: percentChange?.percentChangeCountyYield ?? null,
      producerYieldPercentChanges: yieldHistory,
      hurricaneEventOccurred: hurricaneEventOccurred,
      numberOfTropicalStorms: numberOfTropicalStorms,
      inputCostPercentChange: percentChange?.percentChangeInputCosts ?? null,
      mpPricePercentChange: percentChange?.percentChangeMpPrice ?? null,
    };

    return historicalQuoteYear;
  });

  const historicalQuoteRequestDto: HistoricalQuoteRequestDTO = {
    scenario: scenario,
    historicalYearValues: historicalQuoteYears,
    isQuickQuote: isQuickQuote,
    calculateProductsForMissingYears: calculateProductsForMissingYears,
  };

  const historicalQuoteResult = CalculationOrchestrator.calculateHistoricalQuote(historicalQuoteRequestDto);

  return historicalQuoteResult;
};

const getScenarioRequestDTO = async (state: RootState, scenarioId: ScenarioId, scenarioPieces: readonly ScenarioPiece[], scenarioPieceGroups: readonly ScenarioPieceGroup[], shouldSimulateUnitGroups: boolean = false): Promise<ScenarioRequestDTO> => {
  const scenario = state.scenarios.allScenarios.data[scenarioId] ?? null;
  if (scenario === null) throw new Error('Attempting to calculate for scenario piece when the parent scenario does not exist in state');

  const quote = state.quotes.allQuotes.data[scenario.quoteId] ?? null;
  if (quote === null) throw new Error('Attempting to calculate for scenario piece when the scenario quote does not exist in state');

  const pieceAvailabilities = selectOfferAvailabilitiesForCountyCommodity(state, quote.countyId, quote.commodityCode);

  const scenarioUnits = selectUnitYearsForScenario(state, scenarioId);
  const baseUnits = getApplicableUnits(scenarioUnits, state, scenario);

  const approvedYield = selectApprovedYieldForScenario(state, scenario.scenarioId, quote.countyId, quote.commodityCode);
  const adjustedYield = selectAdjustedYieldForScenario(state, scenario.scenarioId, quote.countyId, quote.commodityCode) ?? 0;

  const scenarioPieceDtos = await Promise.all(scenarioPieces.map(scenarioPiece => {
    return createScenarioPieceDto(scenarioPiece, state, scenario, baseUnits, approvedYield ?? adjustedYield, shouldSimulateUnitGroups, pieceAvailabilities.find(x => x.scenarioPieceType === scenarioPiece.scenarioPieceType));
  },
  ));

  const scenarioPieceGroupDtos = createScenarioPieceGroupDtos(scenarioPieceDtos, scenarioPieceGroups);

  //Aggregate data and send to calculations
  const scenarioDto: ScenarioRequestDTO = {
    id: scenarioId,
    scenarioPieceGroups: [
      ...scenarioPieceGroupDtos,
    ],
  };

  return scenarioDto;
};

const createScenarioPieceGroupDtos = (scenarioPieces: ScenarioPieceRequestDTO[], scenarioPieceGroups: readonly ScenarioPieceGroup[]): ScenarioPieceGroupRequestDTO[] => {
  const scenarioPieceGroupDtos: ScenarioPieceGroupRequestDTO[] = [];
  let remainingScenarioPieces: ScenarioPieceRequestDTO[] = [...scenarioPieces];

  scenarioPieceGroups.forEach(scenarioPieceGroup => {
    const applicableScenarioPieceIds = scenarioPieceGroup.scenarioPieceGroupMembers.map(spgm => spgm.scenarioPieceId);
    const scenarioPiecesApplicableToGroup = remainingScenarioPieces.filter(sp => applicableScenarioPieceIds.includes(toPrimaryKey<ScenarioPieceId>(sp.id)));
    remainingScenarioPieces = remainingScenarioPieces.filter(sp => !applicableScenarioPieceIds.includes(toPrimaryKey<ScenarioPieceId>(sp.id)));

    const scenarioPieceGroupDto: ScenarioPieceGroupRequestDTO = {
      id: scenarioPieceGroup.scenarioPieceGroupId,
      primaryPieceId: scenarioPieceGroup.primaryScenarioPieceId,
      scenarioPieces: scenarioPiecesApplicableToGroup,
    };

    scenarioPieceGroupDtos.push(scenarioPieceGroupDto);
  });

  remainingScenarioPieces.forEach(scenarioPiece => {
    const scenarioPieceGroupDto: ScenarioPieceGroupRequestDTO = {
      id: scenarioPiece.id,
      primaryPieceId: scenarioPiece.id,
      scenarioPieces: [scenarioPiece],
    };

    scenarioPieceGroupDtos.push(scenarioPieceGroupDto);
  });

  return scenarioPieceGroupDtos;
};

const createScenarioPieceDto = async (scenarioPiece: ScenarioPiece, state: RootState, scenario: RowCropScenario, baseUnits: BaseUnit[], aphYield: number, shouldSimulateUnitGroups: boolean = false, productOfferAvailabilities: ProductOfferAvailabilities | undefined = undefined) => {
  const scenarioPieceDefinition = getScenarioPieceDefinition(scenarioPiece.scenarioPieceType);
  return await scenarioPieceDefinition.createScenarioPieceDto(scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups, productOfferAvailabilities);
};
