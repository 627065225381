import { InsurancePlanCode } from '@silveus/calculations';
import { ADMYear } from '../../../types/api/adm/ADMYear';
import { AgencyInformation } from '../../../types/api/agencyInformation';
import { AgentInformation } from '../../../types/api/agentInformation';
import { AIP } from '../../../types/api/aip';
import { FmhAgency, FmhAgent, FmhApplicant, FmhApplicationDto, FmhApplicationInformation, FmhConditionsOfAcceptance, FmhCropInformation, FmhPolicyCancellationInformation, FmhPolicyInformation, FmhPolicyTransferInformation, FmhSbi, FmhSbiFormDTO, FmhSignatureInfo, FmhStaxAndArc } from '../../../types/api/documents/fmhTypes';
import { AppType, AppTypeAttributes } from '../../../types/api/enums/application/AppType.enum';
import { CoverageType } from '../../../types/api/enums/application/coverageType.enum';
import { EntityTypeEnum } from '../../../types/api/enums/application/entityType.enum';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { EntityType } from '../../../types/api/insureds/EntityType';
import { TaxType } from '../../../types/api/insureds/TaxType';
import { getCommodityName, getCountyName, getPracticeAbbrevFromPracticeCode } from '../../../utils/adm';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';
import { getAppTaskStateName, getTaxTypeNameFromId } from './applicationMapper';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { Insured } from '../../../types/api/insureds/Insured';
import { AppTask } from '../../../types/api/AppTask';
import { ecoPlanCodesNameString, mapDelimitedAuthorizedSigners, mapDelimitedCancelTransferCrops, mapDelimitedPoasAndAuthorizedReps, mapDelimitedUnitAndOptionCodes, mapNewProducerCoverages, scoPlanCodesNameString, shouldShowTypeOrPracticeOnForm } from './applicationMapperUtils';

// Maximum SBIs for Fmh MPCI application before requiring an additional SBI form to be generated.
export const MaxFmhSbis = 3;

export const MapFMHApplication = (appTask: AppTask, applicationWizard: ApplicationWizard, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], aips: AIP[], agentInfo: AgentInformation, agencyInfo: AgencyInformation, insured: Insured) => {
  const sbis = mapSBIs(appTask, entityTypes, taxTypes);
  const mappedApplication: FmhApplicationDto = {
    agency: mapAgencyInformation(agencyInfo),
    agent: mapAgentInformation(appTask, agentInfo),
    applicant: mapApplicantInformation(appTask, entityTypes, taxTypes, insured),
    applicationInformation: mapApplicationInformation(appTask, applicationWizard, admData),
    policyInfo: mapPolicyInfo(appTask, admData),
    sbIs: sbis.slice(0, MaxFmhSbis),
    otherChanges: {
      addRemoveSBI: false,  // ignore - future/post 1.0 release - GETTY
      correctAuthorizedRepresentative: false,  // ignore - future/post 1.0 release - GETTY
      correctAddress: false,  // ignore - future/post 1.0 release - GETTY
      correctSbiIdNumber: false,  // ignore - future/post 1.0 release - GETTY
      correctInsuredIdNumber: false,  // ignore - future/post 1.0 release - GETTY
      correctInsuredSpelling: false,  // ignore - future/post 1.0 release - GETTY
      correctSbiSpelling: false,  // ignore - future/post 1.0 release - GETTY
      addRemoveCountyElection: false,  // ignore - future/post 1.0 release - GETTY
      other: false,  // ignore - future/post 1.0 release - GETTY
    },
    policyCancellationInfo: mapPolicyCancellationInfo(),
    remarks: null,  // ignore - future/post 1.0 release - GETTY
    cropInformation: mapCropInformation(appTask, admData),
    staxAndArc: mapStaxAndArc(),
    privateProducts: [], // ignore - future/post 1.0 release - GETTY
    conditionsOfAcceptance: mapConditionsOfAcceptance(applicationWizard),
    postClosingAddedLandCoveredBy: {
      sco: false,   // ignore - future/post 1.0 release - GETTY
      eco: false,   // ignore - future/post 1.0 release - GETTY
      stax: false,  // ignore - future/post 1.0 release - GETTY
    },
    policyTransferInfo: mapPolicyTransferInfo(appTask, aips, admData),
    signatureInfo: mapSignatureInfo(appTask),
  };
  return mappedApplication;
};

const mapAgencyInformation = (agency: AgencyInformation | null) => {

  if (isNullOrUndefined(agency)) {
    throw new Error('Agency information is required');
  }

  const agencyInfo: FmhAgency = {
    agencyName: agency.agencyName,
    phone: agency.phone,
    address: agency.address,
    city: agency.city,
    state: agency.state,
    zipCode: agency.zipCode,
  };

  return agencyInfo;
};

const mapApplicantInformation = (appTask: AppTask, entityTypes: EntityType[], taxTypes: TaxType[], insured: Insured): FmhApplicant => {
  const personType = entityTypes.find(x => x.entityTypeId === insured.entityTypeId);
  const spousalEntityTypeId = entityTypes.find(entityType => entityType.name === EntityTypeEnum.SpousalMarried)?.entityTypeId;
  const spouse = appTask.insuredOtherPersonsOfInterest.find(x => x.personOfInterestType === PersonOfInterestType.Spouse);

  const applicant: FmhApplicant = {
    fullName: insured.name,
    email: insured.email,
    phone: insured.phone,
    powerOfAttorney: mapDelimitedPoasAndAuthorizedReps(appTask),
    personType: personType?.name ?? null,
    taxIdType: getTaxTypeNameFromId(insured.taxTypeId, taxTypes),
    taxId: insured.taxId,
    // spouse is populated below only with certain conditions
    spouseFirstName: null,
    spouseLastName: null,
    spouseTaxIdType: null,
    spouseTaxId: null,
    address: insured.address.addressLine1,
    city: insured.address.city,
    state: insured.address.state,
    zipCode: insured.address.postalCode,
    stateOfIncorporation: insured.corporationState,
  };

  if (insured.entityTypeId === spousalEntityTypeId && isNotNullOrUndefined(spouse)) {
    applicant.spouseFirstName = spouse.firstName;
    applicant.spouseLastName = spouse.lastName;
    applicant.spouseTaxId = spouse.taxId;
    applicant.spouseTaxIdType = getTaxTypeNameFromId(spouse.taxTypeId, taxTypes);
  }

  return applicant;
};

const mapAgentInformation = (appTask: AppTask, agent: AgentInformation): FmhAgent => {
  const agentInfo: FmhAgent = {
    firstName: agent.firstName,
    lastName: agent.lastName,
    email: agent.email,
    agentCode: appTask.agentCodeName,
  };

  return agentInfo;
};

const mapApplicationInformation = (appTask: AppTask, applicationWizard: ApplicationWizard, admYears: ADMYear[]): FmhApplicationInformation => {
  const { isInsuringLandlordShare, isInsuringTenantShare } = appTask;
  const isInsuringLandlord = Boolean(isInsuringLandlordShare);
  const isInsuringTenant = Boolean(isInsuringTenantShare);

  const newProducerCoverages = mapNewProducerCoverages(appTask, admYears);

  const applicationInfo: FmhApplicationInformation = {
    type: AppTypeAttributes[appTask.appType].name,
    parentOrGuardian: null, // ignore - future/post 1.0 release - GETTY
    limitedResourceFarmer: applicationWizard.isLimitedResourceFarmer,
    atLeast18: applicationWizard.isApplicantAtLeast18,
    newProducer: newProducerCoverages.length > 0,
    newProducerCrops: newProducerCoverages.join(', '),
    verticallyIntegratedProducer: applicationWizard.isVIP, // ignore - form field does not exist
    insuringTenantOrLandlordShare: isInsuringLandlord || isInsuringTenant,
    categoryBNational: appTask.isDesignatedCounty,
    categoryBState: null, // ignore - form field does not exist
  };
  return applicationInfo;
};

const mapConditionsOfAcceptance = (applicationWizard: ApplicationWizard): FmhConditionsOfAcceptance => {
  return {
    a: applicationWizard.fciaDelinquency,
    b: applicationWizard.controlledSubstanceConviction,
    c: applicationWizard.fciaAgreementDisqualification,
    d: applicationWizard.disqualifiedOrDebarred,
    e: applicationWizard.fciaAgreementDisqualification,
    f: applicationWizard.currentLikeInsurance,
  };
};

const mapCropInformation = (appTask: AppTask, admData: ADMYear[]): FmhCropInformation[] => {
  const cropInfo = appTask.coverages.map(coverage => {
    const isCoverageCanceled = coverage.coverageType === CoverageType.Cancel;
    const hipCoverage = coverage.childCoverages.find(childCov => childCov.planCode === InsurancePlanCode.HIP_WI);
    const scoCoverage = coverage.childCoverages.find(childCov => childCov.planCodeName.includes(scoPlanCodesNameString));
    const ecoCoverage = coverage.childCoverages.find(childCov => childCov.planCodeName.includes(ecoPlanCodesNameString));
    const coveragePractice = (coverage.practiceCode !== null ? getPracticeAbbrevFromPracticeCode(admData, appTask.cropYear, coverage.practiceCode) : '');

    return {
      canCov: isCoverageCanceled,
      changeCoverageLevel: null,  // ignore - future/post 1.0 release - GETTY
      changePlan: null, // ignore - future/post 1.0 release - GETTY
      county: isNotNullOrUndefined(coverage.countyCode) ? getCountyName(admData, appTask.cropYear, coverage.countyCode) : '',
      crop: isNotNullOrUndefined(coverage.cropCode) ? getCommodityName(admData, appTask.cropYear, coverage.cropCode) : null,
      currentCoverageLevel: isNotNullOrUndefined(coverage.upperCoverageLevel) ? coverage.upperCoverageLevel.toString() : null,
      currentPlan: coverage.planCodeName,
      desCty: coverage.isDesignatedCounty,
      eco90: isNotNullOrUndefined(ecoCoverage) && ecoCoverage.upperCoverageLevel === 90,
      eco95: isNotNullOrUndefined(ecoCoverage) && ecoCoverage.upperCoverageLevel === 95,
      ecoPrice: isNotNullOrUndefined(ecoCoverage) && isNotNullOrUndefined(ecoCoverage.protectionFactor) ? ecoCoverage.protectionFactor.toString() : null,
      hipCov: isNotNullOrUndefined(hipCoverage),
      intendedAcres: isNotNullOrUndefined(coverage.intendedNetAcres) ? coverage.intendedNetAcres.toString() : null,
      newProducer: coverage.isNewProducer,
      options: mapDelimitedUnitAndOptionCodes(coverage),
      percent: coverage.protectionFactor !== null ? coverage.protectionFactor.toString() : null,
      practice: isCoverageCanceled ? 'Cancel' : shouldShowTypeOrPracticeOnForm(coverage) ? `${coveragePractice} ${coverage.typeAbbrev}` : null, // this field concatenates both PRACTICE and TYPE
      price: coverage.protectionFactor !== null ? coverage.protectionFactor.toString() : null, // this is a deprecated field, we are using PERCENT above instead
      scoCov: isNotNullOrUndefined(scoCoverage),
      scoPrice: isNotNullOrUndefined(scoCoverage) && isNotNullOrUndefined(scoCoverage.protectionFactor) ? scoCoverage.protectionFactor.toString() : null,
      type: isCoverageCanceled ? 'Cancel' : (shouldShowTypeOrPracticeOnForm(coverage) ? coverage.typeAbbrev : ''),
    };
  });

  return cropInfo;
};

const mapPolicyInfo = (appTask: AppTask, admData: ADMYear[]): FmhPolicyInformation => {
  const policyCounty = getCountyName(admData, appTask.cropYear, appTask.countyCode);
  const policyState = getAppTaskStateName(appTask, admData);
  const cropYear = appTask.cropYear.toString();

  return {
    counties: policyCounty,
    cropYear: cropYear,
    policyNumber: appTask.appType !== AppType.CancelTransfer ? appTask.policyNumber : null,
    state: policyState,
  };
};

const mapSBIs = (appTask: AppTask, entityTypes: EntityType[], taxTypes: TaxType[]) => {
  const sbiPOIs = appTask.insuredOtherPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.SBI || x.personOfInterestType === PersonOfInterestType.LandLordOrTenant || x.personOfInterestType === PersonOfInterestType.Spouse);
  return sbiPOIs.map(sbi => {
    const personType = entityTypes.find(x => x.entityTypeId === sbi.entityTypeId);
    const mappedSBI: FmhSbi = {
      firstName: sbi.firstName,
      lastName: sbi.lastName,
      phone: sbi.phone,
      personType: personType?.name ?? null,
      taxIdType: getTaxTypeNameFromId(sbi.taxTypeId, taxTypes),
      taxId: sbi.taxId,
      address: sbi.address?.addressLine1 ?? null,
      city: sbi.address?.city ?? null,
      state: sbi.address?.state ?? null,
      zipCode: sbi.address?.postalCode ?? null,
      landlordOrTenant: sbi.personOfInterestType === PersonOfInterestType.LandLordOrTenant,
    };
    return mappedSBI;
  });
};

export const MapFmhAdditionalSBIs = (appTask: AppTask, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], agentInfo: AgentInformation, agencyInfo: AgencyInformation, insured: Insured) => {
  const sbis = mapSBIs(appTask, entityTypes, taxTypes);
  const mappedSBIForm: FmhSbiFormDTO = {
    applicant: mapApplicantInformation(appTask, entityTypes, taxTypes, insured),
    agent: mapAgentInformation(appTask, agentInfo),
    agency: mapAgencyInformation(agencyInfo),
    sbIs: sbis.slice(MaxFmhSbis, sbis.length),
    policy: mapPolicyInfo(appTask, admData),
    signatureInfo: mapSignatureInfo(appTask),
  };

  return mappedSBIForm;
};

const mapPolicyCancellationInfo = (): FmhPolicyCancellationInformation => {
  return {
    aipRepresentativePrintedName: null, // ignore - future/post 1.0 release - GETTY
    deathImcompotenenceOrDissolition: false, // ignore - future/post 1.0 release - GETTY
    insuredRequest: false, // ignore - future/post 1.0 release - GETTY
    mutualConsent: false, // ignore - future/post 1.0 release - GETTY
    other: false, // ignore - future/post 1.0 release - GETTY
  };
};

const mapPolicyTransferInfo = (appTask: AppTask, aips: AIP[], admData:ADMYear[]): FmhPolicyTransferInformation => {
  const isAppCancelTransfer = appTask.appType === AppType.CancelTransfer;

  if (!isAppCancelTransfer) {
    return {
      aipName: null,
      cancelAndTransfer: null,
      policyNumber: null,
      cropsToBeCancelledOrTransferred: null,
    };
  }

  const previousAIP = aips.find(aip => aip.aipId === appTask.previousAIPId);
  return {
    aipName: previousAIP?.aipName ?? '',
    cancelAndTransfer: isAppCancelTransfer,
    policyNumber: appTask.previousPolicyNumber,
    cropsToBeCancelledOrTransferred: mapDelimitedCancelTransferCrops(appTask, admData),
  };
};

const mapSignatureInfo = (appTask: AppTask): FmhSignatureInfo => {
  const delimitedSignatureAuthorizations = mapDelimitedAuthorizedSigners(appTask);

  return {
    addSignatureAuthorizations: delimitedSignatureAuthorizations.length > 0,
    agentPrintedName: null, // Add logic as required
    applicantPrintedName: null, // Add logic as required
    authorizations: delimitedSignatureAuthorizations,
    sbisAreAuthorizedSigners: null,  // ignore - future/post 1.0 release - GETTY
  };
};

const mapStaxAndArc = (): FmhStaxAndArc => {
  return {
    arcCoverage: false, // per Ashley/Getty, default to false until spring season
    coverageRange: null, // ignore - future/post 1.0 release - GETTY
    protectionFactor: null, // ignore - future/post 1.0 release - GETTY
    scoOrEcoCoverage: null, // ignore - future/post 1.0 release - GETTY
    seOnStax: null, // ignore - future/post 1.0 release - GETTY
    trigger: null, // ignore - future/post 1.0 release - GETTY
  };
};
