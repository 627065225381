import BulkAdmData from '../../types/api/offline/BulkAdmData';
import { getBulkAdmData, getBulkIceData, getBulkInsuredData, getBulkPrivateProductData } from '../offline.service';
import { QuoterDb, admDb, iceDb, privateProductsDb, quoterDb } from '../../db';
import { networkOnlyAndUpdate, nonGetCacheOnly } from '../../utils/cachingStrategies';
import BulkInsuredData from '../../types/api/offline/BulkInsuredData';
import { InsuredId } from '../../types/api/PrimaryKeys';
import BulkIceData from '../../types/api/offline/BulkIceData';
import BulkPrivateProductData from '../../types/api/offline/BulkPrivateProductData';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { FilteredKeyOf } from '../../types/util/FilteredKeyOf';

export const getBulkAdmDataRequest = async (year: number, countyIds: string[]): Promise<void> => {
  const request = () => getBulkAdmData(year, countyIds);

  const updateTransaction = (admData: BulkAdmData) => admDb.transaction('rw', admDb.tables, async () => {
    admDb.insuranceCalendars.bulkPut(admData.insuranceCalendars);
    admDb.areaCoverageLevels.bulkPut(admData.areaCoverageLevels);
    admDb.areaRates.bulkPut(admData.areaRates);
    admDb.baseRates.bulkPut(admData.baseRates);
    admDb.betaSequences.bulkPut(admData.betaSequences);
    admDb.comboRevenueFactors.bulkPut(admData.comboRevenueFactors);
    admDb.coverageLevels.bulkPut(admData.coverageLevels);
    admDb.coverageLevelDifferentials.bulkPut(admData.coverageLevelDifferentials);
    admDb.hipEventHistoricals.bulkPut(admData.hipEventHistoricals);
    admDb.hipRates.bulkPut(admData.hipRates);
    admDb.historicalRevenueCappings.bulkPut(admData.historicalRevenueCappings);
    admDb.historicalYieldTrendYears.bulkPut(admData.historicalYieldTrendYears);
    admDb.insuranceOffers.bulkPut(admData.insuranceOffers);
    admDb.options.bulkPut(admData.options);
    admDb.optionRates.bulkPut(admData.optionRates);
    admDb.prices.bulkPut(admData.prices);
    admDb.priceGroupMembers.bulkPut(admData.priceGroupMembers);
    admDb.priceHistories.bulkPut(admData.priceHistories);
    admDb.subCountyRates.bulkPut(admData.subCountyRates);
    admDb.subsidies.bulkPut(admData.subsidies);
    admDb.supportedAdmYears.bulkPut(admData.supportedAdmYears);
    admDb.unitDiscountLevels.bulkPut(admData.unitDiscountLevels);
    admDb.yieldAndTYields.bulkPut(admData.yieldAndTYields);
    admDb.yieldExclusions.bulkPut(admData.yieldExclusions);
    admDb.myaPriceHistories.bulkPut(admData.myaPriceHistories);
  });

  await networkOnlyAndUpdate<BulkAdmData>(request, () => new Promise(() => undefined), updateTransaction);

  return Promise.resolve();
};

export const getBulkIceDataRequest = async (year: number, countyIds: string[]): Promise<void> => {
  const request = () => getBulkIceData(year, countyIds);

  const updateTransaction = (iceData: BulkIceData) => iceDb.transaction('rw', iceDb.tables, () => {
    iceDb.guaranteeAdjustments.bulkPut(iceData.guaranteeAdjustments);
    iceDb.priceElectionPercents.bulkPut(iceData.priceElectionPercents);
  });

  await networkOnlyAndUpdate(request, () => new Promise(() => undefined), updateTransaction);
};

export const getBulkInsuredDataRequest = async (insuredIds: InsuredId[]): Promise<void> => {
  const request = () => getBulkInsuredData(insuredIds);

  const updateTransaction = (insuredData: BulkInsuredData) => quoterDb.transaction('rw', quoterDb.tables, async () => {
    quoterDb.clientFiles.bulkPut(insuredData.clientFiles);
    quoterDb.forwardSoldScenarioPieces.bulkPut(insuredData.forwardSoldScenarioPieces);
    quoterDb.harvestRevenueScenarioPieces.bulkPut(insuredData.harvestRevenueScenarioPieces);
    quoterDb.historicalAnalyses.bulkPut(insuredData.historicalAnalyses);
    quoterDb.inputCostScenarioPieces.bulkPut(insuredData.inputCostScenarioPieces);
    quoterDb.matrices.bulkPut(insuredData.matrices);
    quoterDb.premiumBreakdowns.bulkPut(insuredData.premiumBreakdowns);
    quoterDb.quotes.bulkPut(insuredData.quotes);
    quoterDb.rowCropScenarioPieces.bulkPut(insuredData.rowCropScenarioPieces);
    quoterDb.rowCropScenarios.bulkPut(insuredData.rowCropScenarios);
    quoterDb.scenarioOptionUnitYears.bulkPut(insuredData.scenarioOptionUnitYears);
    quoterDb.scenarioOptions.bulkPut(insuredData.scenarioOptions);
    quoterDb.scenarioUnitYearAph.bulkPut(insuredData.scenarioUnitYearAph);
    quoterDb.scenarioQuickUnits.bulkPut(insuredData.scenarioQuickUnits);
    quoterDb.trendlineAnalyses.bulkPut(insuredData.trendlineAnalyses);
    quoterDb.unitGroups.bulkPut(insuredData.unitGroups);
    quoterDb.unitYearAph.bulkPut(insuredData.unitYearAph);
    quoterDb.unitYears.bulkPut(insuredData.unitYears);
  });

  await networkOnlyAndUpdate(request, () => new Promise(() => undefined), updateTransaction);
};

export const getBulkPrivateProductDataRequest = async (year: number, countyIds: string[]): Promise<void> => {
  const request = () => getBulkPrivateProductData(year, countyIds);

  const updateTransaction = (privateProductData: BulkPrivateProductData) => privateProductsDb.transaction('rw', privateProductsDb.tables, () => {
    privateProductsDb.ecoScoPlusRates.bulkPut(privateProductData.ecoScoPlusRates);
    privateProductsDb.rampRates.bulkPut(privateProductData.rampRates);
    privateProductsDb.revBoostCoverageLevels.bulkPut(privateProductData.revBoostCoverageLevels);
    privateProductsDb.iceProducts.bulkPut(privateProductData.iceProducts);
    privateProductsDb.iceRates.bulkPut(privateProductData.iceRates);
    privateProductsDb.iceAcresCodes.bulkPut(privateProductData.iceAcresCodes);
    privateProductsDb.iceDeductibleSelections.bulkPut(privateProductData.iceDeductibleSelections);
    privateProductsDb.arcYieldHistories.bulkPut(privateProductData.arcYieldHistories);

    // This is being temporarily commented out, because at runtime the hailRates will always currently be undefined,
    // despite what the type says.  This is because the hailRates are not being returned from the API.
    //privateProductsDb.hailRates.bulkPut(privateProductData.hailRates);
  });

  await networkOnlyAndUpdate(request, () => new Promise(() => undefined), updateTransaction);
};

export const clearOutExistingAdmDataRequest = async (countyIds: string[], insuredIds: InsuredId[]): Promise<void> => {
  const newTransaction = async (): Promise<void> => {
    const admTransaction = admDb.transaction('rw', admDb.insuranceOffers, () => {
      safeWhere(admDb.insuranceOffers, 'countyId').anyOf(countyIds).delete();
    });

    const insuredsTransaction = quoterDb.transaction('rw', quoterDb.clientFiles, () => {
      safeWhere(quoterDb.clientFiles, 'insuredId').anyOf(insuredIds).delete();
    });

    await Promise.all([admTransaction, insuredsTransaction]);
  };

  await nonGetCacheOnly(undefined, newTransaction);
};

export const clearOutExistingInsuredDataRequest = async (): Promise<void> => {
  const newTransaction = async (): Promise<void> => {
    await quoterDb.transaction('rw', quoterDb.tables, async () => {
      // The explicit type on "tableToExclude" is very intentional. This is the only protection against
      // the table name being a magic string.
      const tableToExclude: FilteredKeyOf<QuoterDb, 'insureds'> = 'insureds';

      const tablesToClear = quoterDb.tables.filter(table => table.name !== tableToExclude);
      const transactions = tablesToClear.map(table => table.clear());

      await Promise.all(transactions);
    });
  };

  await nonGetCacheOnly(undefined, newTransaction);
};
