import { Tooltip, Typography } from '@mui/material';
import { formatPriceValue } from './harvestRevenueUtils';
import { AdjustmentType, AdjustmentTypeAttributes } from '@silveus/calculations';

interface HarvestRevenueTransactionDisplayPriceProps {
  adjustmentType: AdjustmentType;
  value: number;
}

const HarvestRevenueTransactionDisplayPrice = ({ adjustmentType, value }: HarvestRevenueTransactionDisplayPriceProps) => {
  const text = `${AdjustmentTypeAttributes[adjustmentType].description} | ${formatPriceValue(adjustmentType, value)}`;

  return (
    <Tooltip title={text} placement="top-start">
      <Typography noWrap>{text}</Typography>
    </Tooltip>
  );
};

export default HarvestRevenueTransactionDisplayPrice;