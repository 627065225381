import { Typography, useTheme } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { formatCurrency } from '../../../../../../utils/formatNumbers';
import { getColorForNumberForHistoricalAnalysis } from '../getColorForNumberForHistoricalAnalysis';
import { useAppSelector } from '../../../../../../hooks/reduxHooks';
import { selectPaletteMode } from '../../../../../../app/userSettingsSlice';
import { Nullable } from '../../../../../../types/util/Nullable';
import { isNotNullOrUndefined } from '../../../../../../utils/nullHandling';

export const CurrencyCellRenderer = <TData extends unknown>({ value }: ICellRendererParams<TData, Nullable<number>>) => {
  const theme = useTheme();
  const mode = useAppSelector(selectPaletteMode);

  const numberAsCurrency = isNotNullOrUndefined(value) ? formatCurrency(value) : '';
  const textColor = getColorForNumberForHistoricalAnalysis(value ?? 0, theme, mode);

  return (
    <Typography variant="label" color={textColor} marginX={-1} paddingX={1}>{numberAsCurrency}</Typography>
  );
};
