import { getKeyedStateGroupedBy, getStateIdsMatching } from './sliceHelpers';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ScenarioId, ScenarioPieceId, UnitGroupId, UnitYearId } from '../types/api/PrimaryKeys';
import { selectClientFileById } from './clientFilesSlice';
import { UnitGroup } from '../types/api/UnitGroup';
import { selectAllRowCropScenarioPiecesByScenarioMap, selectRowCropScenarioPiece } from './scenarioPiecesSlice';
import { getUnitsAvailableForScenarioPiece } from '../utils/unitAvailabilityUtils';
import { selectUnitYearsThatCanApplyToScenario } from './unitsSlice';
import { getItemsForId, orderMap } from '../utils/mapHelpers';
import { generatePrimaryKey, toPrimaryKey } from '../utils/primaryKeyHelpers';
import { selectScenarioById, selectUnitYearsForScenario } from './scenariosSlice';
import { selectOfferAvailabilitiesForCountyCommodity } from './availabilitySlice';
import { getApplicableUnits } from '../services/calculations/calculationUnits';
import { createAppAsyncThunk } from './thunkHelpers';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { selectQuoteById } from './quotesSlice';
import * as uuid from 'uuid';
import {
  createUnitGroupsRequest,
  deleteUnitGroupsRequest,
  getUnitGroupsForScenarioPieceRequest, getUnitGroupsForScenariosRequest,
  updateUnitGroupsRequest
} from '../services/requestInterception/unitGroupRequestInterceptor';
import { getCorrectUnitGroups } from '../utils/unitGroupingUtils';
import { ScenarioPiece } from '../types/api/ScenarioPiece';
import { selectAllScenarioPiecesByScenarioMap, selectScenarioPieceAcrossAllTypes } from './sharedSelectors';
import { UnitStructureCode } from '@silveus/calculations';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import UnitYear from '../types/api/UnitYear';
import BaseUnit from '../types/api/BaseUnit';
import { RowCropScenario } from '../types/api/RowCropScenario';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import { ProductOfferAvailabilities } from '@silveus/calculations/dist/availability/productOfferAvailability';

export interface UnitGroupsState {
  allUnitGroups: SliceDataState<UnitGroupId, UnitGroup>;
}

const initialState: UnitGroupsState = {
  allUnitGroups: initialSliceDataState(),
};

export const unitGroupsSlice = createSlice({
  name: 'unitGroups',
  initialState: initialState,
  reducers: {
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allUnitGroups, s => s.unitGroupId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addUnitGroups,
      affectedIds: arg => arg.unitGroups.map(u => u.unitGroupId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchUnitGroups,
      affectedIds: (arg, state) => getStateIdsMatching(state.allUnitGroups.data, s => s.scenarioPieceId === arg.scenarioPieceId, s => s.unitGroupId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchUnitGroupsByScenarioIds,
      affectedIds: () => [],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeUnitGroups,
      affectedIds: arg => arg.unitGroups.map(u => u.unitGroupId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyUnitGroups,
      affectedIds: arg => arg.unitGroups.map(ug => ug.unitGroupId),
    });
  },
});

//#region Non-memoized selectors
const selectUnitGroupDictionary = (state: RootState) => state.unitGroups.allUnitGroups.data;
export const selectUnitGroupById = (state: RootState, unitGroupId: UnitGroupId) => state.unitGroups.allUnitGroups.data[unitGroupId] ?? null;
//#endregion

export const selectCalcUnitGroupsForScenarioPiece = (state: RootState, scenarioPieceId: ScenarioPieceId, scenarioId: ScenarioId, shouldSimulateUnitGroups: boolean = false, customSelectedUnits: UnitYearId[] = []) => {
  const rowCropScenarioPiece = selectRowCropScenarioPiece(state, scenarioPieceId);

  const scenario = selectScenarioById(state, scenarioId);
  if (scenario === null) return stableEmptyArrayAsMutable<UnitGroup>();

  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) return stableEmptyArrayAsMutable<UnitGroup>();

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) return stableEmptyArrayAsMutable<UnitGroup>();

  //Determine what unit groups apply to this scenario piece
  let unitGroupsForScenarioPiece: UnitGroup[] = [];

  if (quote.quickQuote) {
    const useCustomUnitYearIds = customSelectedUnits.length > 0 && shouldSimulateUnitGroups;
    const unitYearIds = useCustomUnitYearIds ? customSelectedUnits : [toPrimaryKey<UnitYearId>(scenario.quickUnit?.scenarioQuickUnitId ?? '')];
    const fakeUnitGroup: UnitGroup = {
      unitGroupId: toPrimaryKey<UnitGroupId>(uuid.v4()),
      scenarioPieceId: scenarioPieceId,
      year: clientFile.year,
      unitStructure: rowCropScenarioPiece?.unitStructure ?? UnitStructureCode.AU,
      unitYearIds: unitYearIds,
      offlineCreatedOn: undefined,
      offlineLastUpdatedOn: undefined,
      offlineDeletedOn: undefined,
    };

    unitGroupsForScenarioPiece.push(fakeUnitGroup);
  } else {
    // In the event that we are calculating before submitting, we will need to create simulated unit groups to use.
    // In its current unsubmitted state it won't be able to find the unit groups because the scenario piece doesn't exist yet for it to use the ID from.
    if (shouldSimulateUnitGroups) {
      const unitsForScenario = selectUnitYearsForScenario(state, scenario.scenarioId);
      const filteredUnitsForScenario = customSelectedUnits.length > 0 ? unitsForScenario.filter(u => customSelectedUnits.includes(u.unitYearId)) : unitsForScenario;
      unitGroupsForScenarioPiece = [{
        scenarioPieceId: scenarioPieceId,
        unitGroupId: generatePrimaryKey(),
        unitStructure: UnitStructureCode.AU,
        unitYearIds: filteredUnitsForScenario.map(u => u.unitYearId),
        year: filteredUnitsForScenario[0]?.year ?? clientFile.year,
        offlineCreatedOn: undefined,
        offlineLastUpdatedOn: undefined,
        offlineDeletedOn: undefined,
      }];
    } else {
      unitGroupsForScenarioPiece = getItemsForId(selectAllUnitGroupsByScenarioPieceMap(state), scenarioPieceId);
    }
  }

  return unitGroupsForScenarioPiece;
};

//#region Memoized selectors
export const selectAllUnitGroupsByScenarioPieceMap = createSelector([selectUnitGroupDictionary], result => {
  const map = getKeyedStateGroupedBy(result, ug => ug.scenarioPieceId);
  const ordered = orderMap(map, DefaultOrders.unitGroups);
  return ordered;
});
//#endregion

//#region Thunks
export const fetchUnitGroups = createAppAsyncThunk('unitGroups/fetchUnitGroups', async ({ scenarioPieceId }: { scenarioPieceId: ScenarioPieceId }) => {
  return await getUnitGroupsForScenarioPieceRequest(scenarioPieceId);
});

export const fetchUnitGroupsByScenarioIds = createAppAsyncThunk('unitGroups/fetchUnitGroupsByScenarioIds', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  return await getUnitGroupsForScenariosRequest(scenarioIds);
});

export const addUnitGroups = createAppAsyncThunk('unitGroups/addUnitGroups', async ({ unitGroups }: { unitGroups: UnitGroup[] }) => {
  await createUnitGroupsRequest(unitGroups);
  return unitGroups;
});

export const modifyUnitGroups = createAppAsyncThunk('unitGroups/modifyUnitGroups', async ({ unitGroups }: { unitGroups: UnitGroup[] }) => {
  await updateUnitGroupsRequest(unitGroups);
  return unitGroups;
});

export const removeUnitGroups = createAppAsyncThunk('unitGroups/removeUnitGroups', async ({ unitGroups }: { unitGroups: UnitGroup[] }) => {
  await deleteUnitGroupsRequest(unitGroups.map(u => u.unitGroupId));
  return unitGroups;
});

export const updateUnitGroupsForScenarioPiece = createAppAsyncThunk('unitGroups/updateUnitGroupsForScenarioPiece', async ({ scenarioPieceId }: { scenarioPieceId: ScenarioPieceId }, thunkApi) => {
  //We are assuming that the scenario piece has already been successfully saved and added to the state store
  const state = thunkApi.getState();

  const scenarioPiece = selectScenarioPieceAcrossAllTypes(state, scenarioPieceId);
  if (scenarioPiece === undefined) return;

  const scenario = selectScenarioById(state, scenarioPiece.scenarioId);
  if (scenario === null) return;

  const availableUnits = getAvailableUnitsForScenarioPiece(state, scenario, scenarioPiece);
  const currentUnitGroups = getItemsForId(selectAllUnitGroupsByScenarioPieceMap(state), scenarioPieceId);

  //Map the units that are available into the format that is needed for the unit grouping service
  const baseUnits = getApplicableUnits(availableUnits, state, scenario);
  await thunkApi.dispatch(detectAndDispatchUnitGroupChanges({ baseUnits, availableUnits, currentUnitGroups, scenarioPiece }));
});

export const updateUnitGroupsForScenarioPieceWithSpecificUnits = createAppAsyncThunk('unitGroups/updateUnitGroupsForScenarioPieceWithSpecificUnits', async ({ scenarioPieceId, selectedUnitYearIds }: { scenarioPieceId: ScenarioPieceId, selectedUnitYearIds?: UnitYearId[] }, thunkApi) => {
  //We are assuming that the scenario piece has already been successfully saved and added to the state store
  const state = thunkApi.getState();

  const scenarioPiece = selectScenarioPieceAcrossAllTypes(state, scenarioPieceId);
  if (scenarioPiece === undefined) return;

  const scenario = selectScenarioById(state, scenarioPiece.scenarioId);
  if (scenario === null) return;

  const availableUnits = getAvailableUnitsForScenarioPiece(state, scenario, scenarioPiece);
  const currentUnitGroups = getItemsForId(selectAllUnitGroupsByScenarioPieceMap(state), scenarioPieceId);

  //If there are no pre-existing selected units available, we need to use all available units
  const currentSelectedUnits = availableUnits.filter(u => currentUnitGroups.some(cug => cug.unitYearIds.includes(u.unitYearId)));
  const fallbackUnits = currentSelectedUnits.length > 0 ? currentSelectedUnits : availableUnits;

  //If units have been passed in then use those, otherwise use fallback units
  const availableUnitsFiltered = selectedUnitYearIds !== undefined ? availableUnits.filter(u => selectedUnitYearIds.includes(u.unitYearId)) : fallbackUnits;

  //Map the units that are available into the format that is needed for the unit grouping service
  const baseUnits = getApplicableUnits(availableUnitsFiltered, state, scenario);
  await thunkApi.dispatch(detectAndDispatchUnitGroupChanges({ baseUnits, availableUnits, currentUnitGroups, scenarioPiece }));
});

const getAvailableUnitsForScenarioPiece = (state: RootState, scenario: RowCropScenario, scenarioPiece: ScenarioPiece) => {
  const rowCropScenarioPiecesForScenario = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), scenarioPiece.scenarioId);
  const otherRowCropScenarioPieces = rowCropScenarioPiecesForScenario.filter(sp => sp.scenarioPieceId !== scenarioPiece.scenarioPieceId);

  const allScenarioPiecesForScenario: ScenarioPiece[] = getItemsForId(selectAllScenarioPiecesByScenarioMap(state), scenarioPiece.scenarioId);
  const nonRowCropScenarioPieces = allScenarioPiecesForScenario.filter(sp => sp.scenarioPieceId !== scenarioPiece.scenarioPieceId && !rowCropScenarioPiecesForScenario.some(rcsp => rcsp.scenarioPieceId === sp.scenarioPieceId));

  const unitsForScenario = selectUnitYearsThatCanApplyToScenario(state, scenario.quoteId, scenario.typeId, scenario.practiceId, scenario.highRiskTypeId);
  const quote = selectQuoteById(state, scenario.quoteId);
  const insuranceOfferAvailabilities = quote === null ? stableEmptyArrayAsMutable<ProductOfferAvailabilities>() : selectOfferAvailabilitiesForCountyCommodity(state, quote.countyId, quote.commodityCode);

  const availableUnitIds = getUnitsAvailableForScenarioPiece(scenarioPiece, unitsForScenario, otherRowCropScenarioPieces, nonRowCropScenarioPieces, insuranceOfferAvailabilities);
  return unitsForScenario.filter(u => availableUnitIds.includes(u.unitYearId));
};

const detectAndDispatchUnitGroupChanges = createAppAsyncThunk('unitGroups/detectAndDispatchUnitGroupChanges', async (
  { baseUnits, availableUnits, currentUnitGroups, scenarioPiece }:
    { baseUnits: BaseUnit[], availableUnits: UnitYear[], currentUnitGroups: UnitGroup[], scenarioPiece: ScenarioPiece }, thunkApi) => {
  //We are assuming that the scenario piece has already been successfully saved and added to the state store
  const { groupsThatHaveChanged, groupsToAdd, groupsToRemove } = getCorrectUnitGroups(baseUnits, availableUnits, currentUnitGroups, scenarioPiece);
  const promisesToWaitOn: Promise<unknown>[] = [];

  //After they are persisted, we need to update our local objects with the correct IDs
  // May be able to dispatch the add and remove before we even determine which groups need to be updated
  if (groupsToAdd.length !== 0) {
    //  Dispatch to thunk to add unit groups
    promisesToWaitOn.push(thunkApi.dispatch(addUnitGroups({ unitGroups: groupsToAdd })));
  }

  if (groupsToRemove.length !== 0) {
    //  Dispatch to thunk to remove unit groups
    promisesToWaitOn.push(thunkApi.dispatch(removeUnitGroups({ unitGroups: groupsToRemove })));
  }

  if (groupsThatHaveChanged.length !== 0) {
    //  Dispatch to thunk to update unit groups
    promisesToWaitOn.push(thunkApi.dispatch(modifyUnitGroups({ unitGroups: groupsThatHaveChanged })));
  }

  await Promise.all(promisesToWaitOn);
});
//#endregion

export default unitGroupsSlice.reducer;
