import { Grid } from '@mui/material';
import PercentChangeSliderInput from '../percentChangeSliderInput.component';
import { HarvestPriceFields } from './harvestPriceInput.component';
import { ProjectedPriceFields } from './projectedPriceInput.component';
import { Nullable } from '../../../types/util/Nullable';
import { Quote } from '../../../types/api/Quote';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import ScenarioPieceProjectedPriceInput from '../scenarioPiece/scenarioPieceProjectedPriceInput';
import ScenarioPieceHarvestPriceInput from '../scenarioPiece/scenarioPieceHarvestPriceInput';
import { LoadFromStateProps } from './loadFromStateProps';
import { useOptionsForSpForm } from '../../../hooks/useOptionsForSpForm';

interface Props extends LoadFromStateProps {
  quote: Quote;
  scenario: Nullable<RowCropScenario>;
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  projectedPrice: Nullable<number>;
  harvestPrice: Nullable<number>;
}

export type PriceInputGroupFields = ProjectedPriceFields & HarvestPriceFields;

const PriceInputGroup = ({ quote, scenario, scenarioPiece, projectedPrice, harvestPrice, loadFromState = false }: Props) => {
  const { scenarioOptionsForSpForm } = useOptionsForSpForm();
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={4}>
        <ScenarioPieceProjectedPriceInput quote={quote} scenario={scenario} scenarioOptionState={scenarioOptionsForSpForm} scenarioPiece={scenarioPiece} projectedPrice={projectedPrice} loadFromState={loadFromState} />
      </Grid>
      <Grid item xs={4}>
        <ScenarioPieceHarvestPriceInput quote={quote} scenario={scenario} scenarioOptionState={scenarioOptionsForSpForm} scenarioPiece={scenarioPiece} harvestPrice={harvestPrice} projectedPriceField="projectedPrice" loadFromState={loadFromState} />
      </Grid>
      <Grid item xs={4}>
        <PercentChangeSliderInput
          estimatedValueFieldName={'projectedPrice'}
          actualValueFieldName={'harvestPrice'}
          sliderName={'priceSlider'}
          initialEstimatedValue={projectedPrice}
          initialActualValue={harvestPrice}
        />
      </Grid>
    </Grid>
  );
};


export default PriceInputGroup;