import axios from 'axios';
import { ScenarioId, TrendlineAnalysisId } from '../types/api/PrimaryKeys';
import TrendlineAnalysis from '../types/api/TrendlineAnalysis';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { envBaseApiUrl } from '../constants/envConstants';

export const getTrendlineAnalysesByScenarioIds = (scenarioIds: ScenarioId[]) => axios.post<TrendlineAnalysis[]>(`${envBaseApiUrl}/trendline-analyses/scenarios`, scenarioIds);

export const createTrendlineAnalysis = (trendlineAnalysis: TrendlineAnalysis) => axios.post<CreatedItemResult<TrendlineAnalysisId>>(`${envBaseApiUrl}/trendline-analyses/`, trendlineAnalysis);

export const updateTrendlineAnalysis = (trendlineAnalysis: TrendlineAnalysis) => axios.put<void>(`${envBaseApiUrl}/trendline-analyses`, trendlineAnalysis);