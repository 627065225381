import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { applyScenarioAsTemplate, selectAllTemplateScenarios } from '../../app/templateScenariosSlice';
import { useState } from 'react';
import { ScenarioId, TemplateScenarioId } from '../../types/api/PrimaryKeys';
import { toPrimaryKey } from '../../utils/primaryKeyHelpers';

interface Props {
  scenarioId: ScenarioId;
}

export const TemplateSelection = ({ scenarioId }: Props) => {
  const dispatch = useAppDispatch();
  const allScenarioTemplates = useAppSelector(selectAllTemplateScenarios);

  const [selectedTemplateId, setSelectedTemplateId] = useState<TemplateScenarioId | null>(null);
  const [isTemplateApplying, setIsTemplateApplying] = useState(false);

  const onApplyTemplateClicked = async () => {
    if (selectedTemplateId === null) return;
    const matchedTemplate = allScenarioTemplates.find(template => template.templateScenarioId === selectedTemplateId);
    if (matchedTemplate === undefined) return;

    setIsTemplateApplying(true);

    try {
      // Note that when pieces have been successfully added via the below call,
      // the existence of pieces should make this UI disappear in the parent.
      await dispatch(applyScenarioAsTemplate({
        templateScenarioId: matchedTemplate.scenarioId,
        applyToScenarioId: scenarioId,
      })).unwrap();
    } finally {
      setIsTemplateApplying(false);
    }
  };

  const handleTemplateScenarioChange = (value: string) => {
    setSelectedTemplateId(toPrimaryKey<TemplateScenarioId>(value));
  };

  return (
    <>
      <Grid item xs={12}>
        <TextField
          type="number"
          label="Choose a Scenario Template"
          fullWidth
          select
          autoFocus
          value={selectedTemplateId ?? ''}
          onChange={e => handleTemplateScenarioChange(e.target.value)}
          size="small"
        >
          {allScenarioTemplates.length === 0
            ? <MenuItem disabled>No Scenario Templates</MenuItem>
            : allScenarioTemplates.map(template => (
              <MenuItem key={template.templateScenarioId} value={template.templateScenarioId}>
                {template.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

      <Grid item xs={12} display={'flex'} justifyContent={'center'}>
        <Button
          id="btn-apply-template"
          variant="contained"
          disabled={selectedTemplateId === null || isTemplateApplying}
          onClick={onApplyTemplateClicked}
        >
          {isTemplateApplying ? 'Applying...' : 'Apply Template To Scenario'}
        </Button>
      </Grid>
    </>
  );
};