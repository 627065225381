import GRID_COLUMN_TYPES from '../../constants/gridColumnTypes';
import {
  GetRowIdParams, GridApi, GridReadyEvent,
  IRowNode,
  IsExternalFilterPresentParams,
  RowSelectedEvent
} from 'ag-grid-community';
import { Insured } from '../../types/api/insureds/Insured';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { toPrimaryKey } from '../../utils/primaryKeyHelpers';
import { InsuredId } from '../../types/api/PrimaryKeys';
import { columnDefinitions } from './insuredSelectionColumnDefinitions';

interface InsuredSelectionGridProps {
  insureds: Insured[];
  insuredsToShow: Insured[];
  insuredsToDownload: InsuredId[];
  updateSelectedInsureds: (selectedInsureds: InsuredId[]) => void;
}

const InsuredSelectionGrid = ({ insureds, insuredsToShow, insuredsToDownload, updateSelectedInsureds }: InsuredSelectionGridProps) => {
  const [gridApi, setGridApi] = useState<GridApi<Insured>>();

  useEffect(() => {
    handleSelectingInsureds();
  }, [insuredsToDownload, gridApi]);

  useEffect(() => {
    gridApi?.onFilterChanged();
  }, [insuredsToShow, gridApi]);

  const onGridReady = (params: GridReadyEvent<Insured>): void => {
    setGridApi(params.api);
  };

  const handleSelectingInsureds = () => {
    if (gridApi === undefined) return;

    gridApi.forEachNode(node => {
      if (insuredsToDownload.includes(toPrimaryKey<InsuredId>(node.id ?? ''))) {
        node.setSelected(true);
      }
    });
  };

  const handleRowSelected = (event: RowSelectedEvent<Insured>) => {
    if (event.node.id === undefined) return;

    const insuredId = toPrimaryKey<InsuredId>(event.node.id);
    const insuredIndex = insuredsToDownload.indexOf(insuredId);
    const isSelectedToDownload = insuredIndex > -1;

    const isSelected = event.node.isSelected() ?? false;

    if (isSelected && !isSelectedToDownload) {
      //If the row is selected and the insured is not in the list of insureds to download, add it
      const newInsureds = [...insuredsToDownload];
      newInsureds.push(insuredId);
      updateSelectedInsureds(newInsureds);
    } else if (!isSelected && isSelectedToDownload) {
      //If the row is not selected and the insured is in the list of insureds to download, remove it
      const newInsureds = [...insuredsToDownload];
      newInsureds.splice(insuredIndex, 1);
      updateSelectedInsureds(newInsureds);
    }
  };

  return (
    <AgGridReact
      rowData={insureds.length === 0 ? null : insureds} /*doing this to indicate loading because the grid uses null to determine that but we don't want to initialize the state as null*/
      columnTypes={GRID_COLUMN_TYPES}
      pagination
      suppressRowClickSelection
      paginationAutoPageSize
      rowSelection={'multiple'}
      columnDefs={columnDefinitions}
      getRowId={(params: GetRowIdParams<Insured>) => params.data.id}
      onGridReady={onGridReady}
      isExternalFilterPresent={(params: IsExternalFilterPresentParams<Insured>) => {
        return insureds.length !== insuredsToShow.length;
      }}
      doesExternalFilterPass={(node: IRowNode<Insured>) => {
        if (node.data === undefined) return false;

        return insuredsToShow.includes(node.data);
      }}
      onRowSelected={handleRowSelected}
      autoSizeStrategy={{ type: 'fitGridWidth' }}
    />
  );
};

export default InsuredSelectionGrid;
