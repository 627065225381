import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { AnalysisLineChart, LineData } from './charts/AnalysisLineChart';
import HistoricalPrice from '../../../types/api/adm/HistoricalPrice';
import { selectSelectedTrendlineScenarioId } from '../../../app/scenarioAnalysisSlice';
import { addLineData, selectLineDataByType } from '../../../app/trendlineAnalysisSlice';
import { TrendlineAnalysisId } from '../../../types/api/PrimaryKeys';
import { TrendlineType } from '../../../types/api/enums/trendlineAnalysis/TrendlineType.enum';
import { useLineData } from '../../../hooks/useLineData';
import { possibleColors } from './charts/chartColors';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import { defaultYAxisGenerator } from './charts/lineCharts.utils';
import { useHistoricalPrices } from '../../../hooks/useHistoricalPrices';
import { useMemo } from 'react';

interface PriceChartProps {
  trendlineAnalysisId: TrendlineAnalysisId;
  startYear: number;
  endYear: number;
  showLabels: boolean;
}

const defaultTrendlineDataKeys = new Set<keyof HistoricalPrice>(['basePrice', 'harvestPrice']);

const PriceChart = ({ startYear, endYear, showLabels, trendlineAnalysisId }: PriceChartProps) => {
  const dispatch = useAppDispatch();
  const cachedLineData = useAppSelector(state => selectLineDataByType(state, trendlineAnalysisId, TrendlineType.Prices));
  const selectedScenarioId = useAppSelector(selectSelectedTrendlineScenarioId);
  const priceData = useHistoricalPrices(selectedScenarioId, startYear, endYear);

  const mpBasePriceKey: keyof HistoricalPrice = 'mpBasePrice';

  const priceLineData: LineData<HistoricalPrice>[] = [
    {
      dataKey: 'basePrice',
      color: possibleColors[0],
      name: 'Projected Price',
    },
    {
      dataKey: mpBasePriceKey,
      color: possibleColors[1],
      name: 'MP Projected Price',
    },
    {
      dataKey: 'harvestPrice',
      color: possibleColors[2],
      name: 'Harvest Price',
    },
  ];

  const trimmedPriceLineData = useMemo(() => priceLineData.filter(line => line.dataKey !== mpBasePriceKey || priceData.some(data => data[line.dataKey] !== null)), [priceLineData, priceData]);

  const trendlineLineData = useLineData(trimmedPriceLineData, trendlineAnalysisId, cachedLineData, TrendlineType.Prices, defaultTrendlineDataKeys);
  if (cachedLineData.length === 0) {
    dispatch(addLineData({ lineData: trendlineLineData }));
  }

  const filteredLineData = trimmedPriceLineData.filter(ld => cachedLineData.filter(x => x.isVisible).find(x => ld.dataKey === x.dataKey));

  const customYAxisTicks = useMemo(() => defaultYAxisGenerator(
    filteredLineData
      .map(pld => pld.dataKey)
      .flatMap(key => priceData.map(d => d[key]))
      .filter((v): v is number => isNotNullOrUndefined(v)),
  ), [filteredLineData, priceData]);

  return (
    <Grid container sx={{ height: '100%', p: 2 }}>
      <AnalysisLineChart
        data={priceData}
        lineData={filteredLineData}
        customYAxisTicks={customYAxisTicks}
        xAxisKey="priceYear"
        showTooltip
        showLabels={showLabels}
      />
    </Grid>
  );
};

export default PriceChart;