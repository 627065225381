import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { MuiDialogCloseReason } from '../../types/mui/MuiDialogCloseReason';
import { addTemplateScenario, closeCreateTemplateScenarioModal } from '../../app/templateScenariosSlice';
import { useState } from 'react';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { selectScenarioById } from '../../app/scenariosSlice';

interface Props {
  /** The scenario id that this template will be based on. */
  copyFromScenarioId: ScenarioId;
}

export const CreateTemplateScenarioModal = ({ copyFromScenarioId }: Props) => {
  const dispatch = useAppDispatch();
  const copyFromScenario = useAppSelector(s => selectScenarioById(s, copyFromScenarioId));

  const [templateName, setTemplateName] = useState(copyFromScenario?.name ?? '');
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);

  const handleClose = (reason?: MuiDialogCloseReason) => {
    // If the user clicks the backdrop, don't close the modal
    if (reason === 'backdropClick') {
      return;
    }

    dispatch(closeCreateTemplateScenarioModal());
  };

  const handleSave = async () => {
    setIsCreatingTemplate(true);

    await dispatch(addTemplateScenario({
      copyFromScenarioId,
      templateName,
    }));

    setIsCreatingTemplate(false);
    handleClose();
  };

  // Disable Saving if the template name is empty or saving is in flight.
  const isSaveDisabled = templateName === '' || isCreatingTemplate;
  const isCancelDisabled = isCreatingTemplate;

  return (
    <Dialog
      open={true}
      onClose={(_, reason) => handleClose(reason)}
      maxWidth={false}
      scroll="body"
      sx={{ m: 2 }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>
        <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Grid item xs>Create Scenario Template</Grid>
        </Grid>
      </DialogTitle>

      <DialogContent sx={{ width: '500px' }}>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <TextField
            value={templateName}
            label="Scenario Template Name"
            onChange={e => setTemplateName(e.target.value)}
          />
        </FormControl>

        <DialogActions style={{ marginTop: '15px' }}>
          <Button
            id="create-template-scenario-cancel-button"
            variant="outlined"
            onClick={() => handleClose()}
            disabled={isCancelDisabled}
          >
            Cancel
          </Button>
          <Button
            id="create-template-scenario-save-button"
            variant="contained"
            disabled={isSaveDisabled}
            onClick={() => handleSave()}
          >
            {isCreatingTemplate ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};