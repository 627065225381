import { ReportFormFields, ReportGenerationModal } from './reportGenerationModal';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { Grid } from '@mui/material';
import { OptionalColumnInput } from '../formInputs/reports/optionalColumnInput';
import { ActualProductionReportData } from '../../types/api/reports/ActualProductionReportData';
import { selectCurrentClientFile } from '../../app/clientFilesSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import {
  selectAllScenariosByClientFileIdMap,
  selectScenariosByIds,
  selectUnitYearsForScenarios
} from '../../app/scenariosSlice';
import {
  selectAllScenarioOptionsByScenarioIdMap,
  selectScenarioOptionsByUnitYearMapForScenarios
} from '../../app/optionsSlice';
import { generateActualProductionReport } from '../../app/reportsSlice';
import { stableEmptyArrayAsMutable } from '../../utils/stableEmptyArray';
import { Quote } from '../../types/api/Quote';
import { selectQuotesByClientFileId } from '../../app/quotesSlice';
import UnitYear from '../../types/api/UnitYear';
import { getItemsForId } from '../../utils/mapHelpers';
import { createFakeUnitYearForQuickUnit } from './utils/createFakeUnitYearForQuickUnit';
import { useInsuredContact } from './hooks/useInsuredContact';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { selectUnitYearAphByUnitYearMap } from '../../app/unitYearAphSlice';
import { getContactInfoFromInsured } from './utils/getContactInfoFromInsured';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../app/scenarioPiecesSlice';
import { scenarioPieceOrderingServiceInstance } from '../../utils/scenarioOrderingServiceWrappers';
import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';

interface ActualProductionProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface ActualProductionOptionalColumnFields {
  yieldDescriptor: boolean;
  yieldProductionType: boolean;
}

interface ActualProductionAdditionalOptionFields {
  includeDateGenerated: boolean;
  isPriorYearSelected: boolean;
  isInclude0IntendedAcresSelected: boolean;
}

type ActualProductionReportFormFields = ReportFormFields & ActualProductionOptionalColumnFields & ActualProductionAdditionalOptionFields;

export const ActualProductionReportGenerationModal = ({ open, setOpen }: ActualProductionProps) => {
  const dispatch = useAppDispatch();
  const methods = useForm<ActualProductionReportFormFields>();
  const clientFile = useAppSelector(selectCurrentClientFile);
  const insured = useInsuredContact();
  const quotes = useAppSelector(state => clientFile === null ? stableEmptyArrayAsMutable<Quote>() : selectQuotesByClientFileId(state, clientFile.clientFileId));
  const scenariosByClientFile = useAppSelector(selectAllScenariosByClientFileIdMap);
  const scenariosForClientFile = clientFile === null ? stableEmptyArrayAsMutable<RowCropScenario>() : getItemsForId(scenariosByClientFile, clientFile.clientFileId);
  const scenarioPiecesMap = useAppSelector(selectAllRowCropScenarioPiecesByScenarioMap);

  const filteredScenarios = scenariosForClientFile.filter(s => s.isVisible);

  const [includedScenarios, setIncludedScenarios] = useState<ScenarioId[]>(filteredScenarios.map(s => s.scenarioId));

  const scenarioUnits = useAppSelector(state => selectUnitYearsForScenarios(state, includedScenarios));
  const optionsByUnit = useAppSelector(state => selectScenarioOptionsByUnitYearMapForScenarios(state, includedScenarios));
  const scenarios = useAppSelector(state => selectScenariosByIds(state, includedScenarios));
  const scenarioOptions = useAppSelector(selectAllScenarioOptionsByScenarioIdMap);
  const unitYearAphByUnitYear = useAppSelector(selectUnitYearAphByUnitYearMap);

  if (clientFile === null || insured === null) {
    setOpen(false);
    return null;
  }

  const formId = 'actualProductionReportForm';

  const additionalOptions = [
    <OptionalColumnInput key={1} name="includeDateGenerated" label="Include Date Generated" />,
    <OptionalColumnInput key={2} name="isPriorYearSelected" label="Populate with prior year production info" defaultValue={false} />,
    <OptionalColumnInput key={3} name="isInclude0IntendedAcresSelected" label="Include units with 0 intended acres" defaultValue={false} />,
  ];

  const optionalColumns = (
    <Grid container>
      <Grid item xs={12}>
        Select optional columns to include:
      </Grid>
      <Grid item xs={6}>
        <OptionalColumnInput name="yieldDescriptor" label="Yield Descriptor" />
      </Grid>
      <Grid item xs={6}>
        <OptionalColumnInput name="yieldProductionType" label="Yield Production Type" />
      </Grid>
    </Grid>
  );

  const onSubmit: SubmitHandler<ActualProductionReportFormFields> = async data => {

    const scenarioUnitsWithQuickQuoteUnits = new Map<ScenarioId, UnitYear[]>();
    const baseScenarioPiecesByScenarioId = new Map<ScenarioId, RowCropScenarioPiece>();

    for (const scenario of scenarios) {
      const quote = quotes.find(quote => quote.quoteId === scenario.quoteId) ?? null;

      if (quote !== null && quote.quickQuote && scenario.quickUnit !== null) {
        const mockUnitYear = createFakeUnitYearForQuickUnit(scenario.quickUnit, insured.insuredId, clientFile.year);
        scenarioUnitsWithQuickQuoteUnits.set(scenario.scenarioId, [mockUnitYear]);

        const optionsForScenario = getItemsForId(scenarioOptions, scenario.scenarioId);
        optionsByUnit.set(mockUnitYear.unitYearId, optionsForScenario);
      } else {
        const unitsForScenario = getItemsForId(scenarioUnits, scenario.scenarioId);
        const unitsWithAph = unitsForScenario.map(unit => ({ ...unit, history: getItemsForId(unitYearAphByUnitYear, unit.unitYearId) }));

        scenarioUnitsWithQuickQuoteUnits.set(scenario.scenarioId, unitsWithAph);
      }

      const scenarioPieces = getItemsForId(scenarioPiecesMap, scenario.scenarioId);
      const basePieceTypes = scenarioPieceOrderingServiceInstance.getBaseScenarioPieces();
      const baseScenarioPiece = scenarioPieces.find(sp => basePieceTypes.includes(sp.scenarioPieceType));
      if (baseScenarioPiece !== undefined && !baseScenarioPiece.isInvalid) {
        baseScenarioPiecesByScenarioId.set(scenario.scenarioId, baseScenarioPiece);
      }
    }

    const actualProductionReportData: ActualProductionReportData = {
      cropYear: clientFile.year,
      hasGenerationDate: data.includeDateGenerated,
      hasPageNumbers: true,
      insuredContactInformation: getContactInfoFromInsured(insured.contact),
      agentContactInformation: null,
      isInclude0IntendedAcresSelected: data.isInclude0IntendedAcresSelected,
      isPriorYearSelected: data.isPriorYearSelected,
      isYieldDescriptorSelected: data.yieldDescriptor,
      isYieldProductionTypeSelected: data.yieldProductionType,
      reportDocumentType: data.generatedDocumentType,
      scenarios: scenarios,
      scenarioPieceByScenarioId: Object.fromEntries(baseScenarioPiecesByScenarioId),
      unitOptionsByUnitYearId: Object.fromEntries(optionsByUnit),
      unitsByScenarioId: Object.fromEntries(scenarioUnitsWithQuickQuoteUnits),
    };

    await dispatch(generateActualProductionReport({ actualProductionReportData }));

    setOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
        <ReportGenerationModal
          title="Production Reporting Unit Summary"
          handleClose={() => setOpen(false)}
          open={open}
          additionalOptions={additionalOptions}
          formId={formId}
          includedScenarios={includedScenarios}
          setIncludedScenarios={setIncludedScenarios}
          contentAfterAdditionalOptions={optionalColumns}
        />
      </form>
    </FormProvider>
  );
};