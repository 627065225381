import { Card, CardMedia, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from '@mui/material';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import classNames from 'classnames';
import PremiumBreakdown from '../../../types/api/premiumBreakdown';
import { PremiumBreakdownSummaryTypes, usePremiumBreakdownSummary } from '../../../hooks/usePremiumBreakdownSummary';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectPremiumBreakdownOptions } from '../../../app/userSettingsSlice';

type PremiumBreakdownScenarioCardProps = {
  premiumBreakdown: PremiumBreakdown;
  isSelected?: boolean;
  scenario: RowCropScenario;
  onClick: () => void;
}


const BorderlessCell = styled(TableCell)({
  borderBottom: 'none',
  padding: '0 8px',
});

const PremiumBreakdownScenarioCard = ({ isSelected, scenario, onClick, premiumBreakdown }: PremiumBreakdownScenarioCardProps) => {
  const premiumBreakdownOptions = useAppSelector(selectPremiumBreakdownOptions);
  const premiumBreakdownSummary = usePremiumBreakdownSummary(premiumBreakdown.primaryScenarioId);

  return (
    <Grid container direction="column" onClick={onClick} sx={{ cursor: 'pointer' }} rowSpacing={.5}>
      <Grid item xs="auto">
        <Typography noWrap sx={{ color: theme => theme.palette.primary.main, maxWidth: '400px' }} variant="clickable1">{scenario.name}</Typography>
      </Grid>
      <Grid item xs>
        <Card className={classNames({ 'premium-breakdown-card-box-shadow': isSelected })} sx={{ borderWidth: 0 }}>
          <CardMedia sx={{ height: '100%' }}>
            <Grid container height="100%" >
              <Grid item xs="auto">
                <div style={{ width: '9px', height: '100%', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', backgroundColor: scenario.scenarioColor }}>
                </div>
              </Grid>
              <Grid item xs p={1}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <BorderlessCell width="90px"></BorderlessCell>
                      {premiumBreakdownOptions.summary.premium && (
                        <BorderlessCell align="right"><Typography variant="label">Premium</Typography></BorderlessCell>
                      )}
                      {premiumBreakdownOptions.summary.coverage && (
                        <BorderlessCell align="right"><Typography variant="label">Cov $</Typography></BorderlessCell>
                      )}
                      {premiumBreakdownOptions.summary.subsidy && (
                        <BorderlessCell align="right"><Typography variant="label">Subsidy $</Typography></BorderlessCell>
                      )}
                      {premiumBreakdownOptions.summary.subsidyPercent && (
                        <BorderlessCell align="right"><Typography variant="label">Subsidy %</Typography></BorderlessCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(PremiumBreakdownSummaryTypes).map(key => {
                      const summaryType = PremiumBreakdownSummaryTypes[key as keyof typeof PremiumBreakdownSummaryTypes];
                      return (
                        <TableRow key={key}>
                          <BorderlessCell align="left"><Typography variant="label">{summaryType}</Typography></BorderlessCell>
                          {premiumBreakdownOptions.summary.premium && (
                            <BorderlessCell align="right"><CurrencyText currencyAmount={premiumBreakdownSummary.get(summaryType)?.producerPremiumPerAcre ?? 0} colorize={false} /></BorderlessCell>
                          )}
                          {premiumBreakdownOptions.summary.coverage && (
                            <BorderlessCell align="right"><CurrencyText currencyAmount={premiumBreakdownSummary.get(summaryType)?.coverage ?? 0} colorize={false} /></BorderlessCell>
                          )}
                          {premiumBreakdownOptions.summary.subsidy && (
                            <BorderlessCell align="right"><CurrencyText currencyAmount={premiumBreakdownSummary.get(summaryType)?.subsidy ?? 0} colorize={false} /></BorderlessCell>
                          )}
                          {premiumBreakdownOptions.summary.subsidyPercent && (
                            <BorderlessCell align="right"><Typography variant="value">{premiumBreakdownSummary.get(summaryType)?.subsidyPercent}%</Typography></BorderlessCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardMedia>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PremiumBreakdownScenarioCard;
