import { ScenarioId } from '../types/api/PrimaryKeys';
import PremiumBreakdownChartData from '../types/app/PremiumBreakdownData';
import { useAppSelector, useKeyMapSelector } from './reduxHooks';


import { selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder } from '../app/scenarioPiecesSlice';
import ScenarioPieceClassification
  from '@silveus/calculations/dist/lookups/scenarioPieceClassification/ScenarioPieceClassification';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { optionalBaseScenarioPieces } from '../utils/scenarioOrderingServiceWrappers';
import {
  CalculationOrchestrator,
  PremiumBreakdownRequestDTO,
  roundToPlaces,
  ScenarioPieceResponseDTO,
  ScenarioPieceType
} from '@silveus/calculations';
import { getFriendlyScenarioPieceName } from '../utils/scenarioPieceUtils';
import { useGetSelectedScenarioRequestsForScenarios } from './scenarioHooks';
import { selectScenarioById } from '../app/scenariosSlice';
import { useMemo } from 'react';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import { RowCropScenario } from '../types/api/RowCropScenario';
import { selectPremiumBreakdownOptions } from '../app/userSettingsSlice';
import { RowCropScenarioPiece } from '../types/api/RowCropScenarioPiece';

const defaultColors = ['#8DD3C7', '#FFFFB3', '#BEBADA', '#FFE49B', '#80B1DD', '#FDB462', '#B3DE69', '#FF6969', '#E9A7D6'] as const;

export const usePremiumBreakdown = (scenarioId: ScenarioId): PremiumBreakdownChartData[] => {
  const premiumBreakdownOptions = useAppSelector(selectPremiumBreakdownOptions);
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));

  //Memoize the array of scenarios so the reference doesn't change between renders
  const memoizedScenarioArray = useMemo(() => {
    return scenario !== null ? [scenario] : stableEmptyArrayAsMutable<RowCropScenario>();
  }, [scenario]);
  const scenarioRequests = useGetSelectedScenarioRequestsForScenarios(memoizedScenarioArray);

  //Memoize the premium breakdown calculation so it only happens when the scenario request, premium breakdown options, or scenario change
  const premiumBreakdownCalcs = useMemo(() => {
    const scenarioRequest = scenarioRequests.find(sr => sr.id === scenarioId);
    if (scenarioRequest === undefined || scenario === null) return null;

    const premiumBreakdownRequest: PremiumBreakdownRequestDTO = {
      scenario: scenarioRequest,
      harvestPrice: (premiumBreakdownOptions.includeHPO ? scenario.harvestPrice : scenario.projectedPrice) ?? 0,
    };

    return CalculationOrchestrator.calculatePremiumBreakdown(premiumBreakdownRequest);
  }, [scenarioRequests, premiumBreakdownOptions, scenario]);

  const scenarioPieceCalcResults = premiumBreakdownCalcs?.scenarioPieceGroups.flatMap(spg => spg.scenarioPieces) ?? stableEmptyArrayAsMutable<ScenarioPieceResponseDTO>();
  const scenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder, scenarioId);

  return getPremiumBreakdownData(scenarioPieces, scenarioPieceCalcResults);
};

export const getPremiumBreakdownData = (scenarioPieces: RowCropScenarioPiece[], scenarioPieceCalcResults: ScenarioPieceResponseDTO[]) => {
  if (scenarioPieceCalcResults.length === 0) {
    return [];
  }
  const nonApplicableScenarioPieceTypes = [ScenarioPieceType.Arc, ScenarioPieceType.Plc];
  const mappedPieces = scenarioPieces.filter(sp => scenarioPieceCalcResults.find(s => s.id === sp.scenarioPieceId) && !nonApplicableScenarioPieceTypes.includes(sp.scenarioPieceType)).map((sp, spIndex) => {
    const matchingCalc = scenarioPieceCalcResults.find(s => s.id === sp.scenarioPieceId);
    if (matchingCalc === undefined) throw new Error('Matching calculation not found for scenario piece');

    const upperCoverageLevel = sp.upperCoverageLevel;
    const lowerCoverageLevel = sp.lowerCoverageLevel;
    const scenarioPieceName = getFriendlyScenarioPieceName(sp.scenarioPieceType);
    const existingScenarioPieceTypes = scenarioPieces.map(scenarioPiece => scenarioPiece.scenarioPieceType);
    const baseScenarioPieces = optionalBaseScenarioPieces(existingScenarioPieceTypes);
    const chartData: PremiumBreakdownChartData = {
      liabilityFactor: sp.protectionFactor,
      coverage: matchingCalc.protectionAmountPerAcre,
      totalCoverage: matchingCalc.protectionAmount,
      premium: matchingCalc.producerPremiumPerAcre,
      totalPremium: matchingCalc.producerPremium,
      classification: matchingCalc.scenarioPieceClassification,
      upperCoverageLevel: upperCoverageLevel,
      lowerCoverageLevel: lowerCoverageLevel,
      id: sp.scenarioPieceId,
      height: upperCoverageLevel - lowerCoverageLevel,
      fill: defaultColors[spIndex % defaultColors.length],
      isBasePiece: baseScenarioPieces.includes(sp.scenarioPieceType),
      productName: scenarioPieceName,
      subsidy: matchingCalc.subsidyAmountPerAcre,
      totalSubsidy: matchingCalc.subsidyAmount,
      subsidyPercent: matchingCalc.totalPremium > 0 ? roundToPlaces(matchingCalc.subsidyAmount / matchingCalc.totalPremium * 100, 1) : 0,
    };

    return chartData;
  });

  const unSpacedCountyItems = getSortedItemsWithNoSpacers(mappedPieces, ScenarioPieceClassification.County);
  const unSpacedIndividualItems = getSortedItemsWithNoSpacers(mappedPieces, ScenarioPieceClassification.Individual);
  const maxNumberOfColumnsPerSide = Math.max(...unSpacedCountyItems.map(x => (x.stackId ?? 0)), ...unSpacedIndividualItems.map(x => (x.stackId ?? 0)));

  const countyItems = getSortedItemsWithSpacers(maxNumberOfColumnsPerSide, unSpacedCountyItems, ScenarioPieceClassification.County, false);
  const individualItems = getSortedItemsWithSpacers(maxNumberOfColumnsPerSide, unSpacedIndividualItems, ScenarioPieceClassification.Individual, true);

  return [...countyItems, ...individualItems];
};

const compareCoverageLevelToAllOfOtherItem = (value: number, otherProduct: PremiumBreakdownChartData, comparer: (currentNumber: number, otherNumber: number) => boolean) => {
  return comparer(value, otherProduct.lowerCoverageLevel) && comparer(value, otherProduct.upperCoverageLevel);
};

const isCurrentProductCompletelyLowerThanOtherProduct = (currentProduct: PremiumBreakdownChartData, otherProduct: PremiumBreakdownChartData): boolean => {
  const lessThanEqual = (current: number, other: number) => (current <= other);
  return compareCoverageLevelToAllOfOtherItem(currentProduct.lowerCoverageLevel, otherProduct, lessThanEqual)
    && compareCoverageLevelToAllOfOtherItem(currentProduct.upperCoverageLevel, otherProduct, lessThanEqual);
};

const isCurrentProductCompletelyHigherThanOtherProduct = (currentProduct: PremiumBreakdownChartData, otherProduct: PremiumBreakdownChartData): boolean => {
  const greaterThanEqual = (current: number, other: number) => (current >= other);
  return compareCoverageLevelToAllOfOtherItem(currentProduct.lowerCoverageLevel, otherProduct, greaterThanEqual)
    && compareCoverageLevelToAllOfOtherItem(currentProduct.upperCoverageLevel, otherProduct, greaterThanEqual);
};

const doesCurrentItemConflictWithOtherProduct = (currentStack: number, currentItem: PremiumBreakdownChartData, possibleConflictItem: PremiumBreakdownChartData): boolean => {
  if (possibleConflictItem.stackId !== currentStack) {
    return false;
  }

  if (isCurrentProductCompletelyLowerThanOtherProduct(currentItem, possibleConflictItem)) {
    return false;
  }

  if (isCurrentProductCompletelyHigherThanOtherProduct(currentItem, possibleConflictItem)) {
    return false;
  }

  return true;
};

const getSortedItemsWithNoSpacers = (mappedPieces: PremiumBreakdownChartData[], classification: ScenarioPieceClassification): PremiumBreakdownChartData[] => {

  // 1. sort first by if it's a base piece (with stax, can have multiples), then by lower coverage level
  const grouping = mappedPieces.filter(x => x.classification === classification).sort((a, b) => {
    if (a.isBasePiece !== b.isBasePiece) {
      return (a.isBasePiece ?? false) ? -1 : 1;
    }
    return a.lowerCoverageLevel - b.lowerCoverageLevel;
  });

  let currentStackId = 1;

  // quick exit if nothing to show
  if (grouping.length === 0) {
    return [createSpacer(0, currentStackId, classification)];
  }

  const allUnspacedItems: PremiumBreakdownChartData[] = [];

  // 2. find the first stack a product can go into, if none, start a new stack
  for (let i = 0; i < grouping.length; i++) {
    let stackToAddTo: number = 1;
    const currentItem = grouping[i];

    let spaceInStack: boolean = false;

    do {
      const currentStack = stackToAddTo;

      // new item must be completely outside all other items in the stack
      // but can have the levels line up: 40-50 and 50-60 is ok
      const conflictingItem = allUnspacedItems.find(possibleConflictItem => doesCurrentItemConflictWithOtherProduct(currentStack, currentItem, possibleConflictItem));

      if (conflictingItem) {
        stackToAddTo++;
      } else {
        spaceInStack = true;
      }
    } while (!spaceInStack);

    currentItem.stackId = stackToAddTo;
    allUnspacedItems.push(currentItem);
  }

  // 3. re-sort, because POSSIBLE a lower range got added after a higher range (higher might be base plan while other isn't)
  // e.g. stax (base 80-85) and another non-isbase county product that is lower
  allUnspacedItems.sort((a, b) => {
    if (a.stackId !== b.stackId) {
      return (a.stackId ?? 0) - (b.stackId ?? 0);
    }

    return (a.lowerCoverageLevel - b.lowerCoverageLevel);
  });
  return allUnspacedItems;
};

const getSortedItemsWithSpacers = (maxNumberOfColumns: number, allUnspacedItems: PremiumBreakdownChartData[], classification: ScenarioPieceClassification, addToTheRight: boolean): PremiumBreakdownChartData[] => {
  const allItems: PremiumBreakdownChartData[] = [];
  const maxStack = Math.max(...allUnspacedItems.map(x => (x.stackId ?? 0)));

  // 4. add spacers - now that each stack is sorted and prioritized
  for (let stackIndex = 1; stackIndex <= maxStack; stackIndex++) {
    const stackItems = allUnspacedItems.filter(x => x.stackId === stackIndex);

    let currentMaxCoverageLevel = 0;
    for (let index = 0; index < stackItems.length; index++) {
      const currentItem = stackItems[index];

      if (currentItem.lowerCoverageLevel > currentMaxCoverageLevel) {
        allItems.push(createSpacer(currentItem.lowerCoverageLevel - currentMaxCoverageLevel, stackIndex, classification));
      }

      allItems.push(currentItem);
      currentMaxCoverageLevel = currentItem.upperCoverageLevel;
    }
  }

  // 5. if county/adding to the left, need to fix the columns to push it to the middle
  if (!addToTheRight) {
    allItems.forEach(x => {
      // this math reverses the stacks so that stack 1 is on the far right
      // this puts the "base pieces" in the middle
      x.originalStackId = x.stackId;
      x.stackId = 1 + maxNumberOfColumns - (x.stackId ?? 1);
    });
  }

  return allItems;
};

const createSpacer = (height: number, stackId: number, classification: ScenarioPieceClassification): PremiumBreakdownChartData => {
  return {
    stackId: stackId,
    height: height,
    fill: 'transparent',
    classification: classification,
    id: generatePrimaryKey(),
    upperCoverageLevel: 0,
    lowerCoverageLevel: 0,
    isSpacer: true,
  };
};