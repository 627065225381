import { DraggableProvided, DraggableStateSnapshot, DraggableStyle } from '@hello-pangea/dnd';
import { Palette, useTheme } from '@mui/material';
import React, { CSSProperties } from 'react';

interface Props {
  provided: DraggableProvided,
  snapshot: DraggableStateSnapshot
  children: React.ReactNode;
  horizontalList?: boolean;
}

const grid = 8;

const getItemStyle = (palette: Palette, isDragging: boolean, draggableStyle: DraggableStyle | undefined, horizontalList: boolean): CSSProperties | undefined => {
  if (horizontalList) {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: grid,
      margin: `0 ${grid}px 0 0`,

      // change background colour if dragging
      background: isDragging ? palette.background.paper : palette.background.default,

      // styles we need to apply on draggables
      ...draggableStyle,
    };
  }
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? palette.background.paper : palette.background.default,
    width: '100%',

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

export const DragHandle = ({ provided, snapshot, children, horizontalList = false }: Props) => {
  const { palette } = useTheme();
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(
        palette,
        snapshot.isDragging,
        provided.draggableProps.style,
        horizontalList,
      )}
    >
      {children}
    </div>
  );
};