import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import ApplicationWizardPage from './applicationWizardPage';
import { ApplicationWizardStep, ApplicationWizardStepProps } from './applicationWizardStep';
import DiamondIcon from '@mui/icons-material/Diamond';
import AddressCityInput, { AddressCityFields } from '../../../components/formInputs/applications/addressCityInput';
import AddressLine1Input, { AddressLine1Fields } from '../../../components/formInputs/applications/addressLine1Input';
import AddressStateInput, { AddressStateFields } from '../../../components/formInputs/applications/addressStateInput';
import AddressZipInput, { AddressZipFields } from '../../../components/formInputs/applications/addressZipInput';
import InfoCorrectYesNoInput, { InfoCorrectYesNoInputFields } from '../../../components/formInputs/applications/infoCorrectYesNoInput';
import InsuredEmailInput, { InsuredEmailFields } from '../../../components/formInputs/applications/insuredEmailInput';
import InsuredEntityTypeInput, { InsuredEntityTypeFields } from '../../../components/formInputs/applications/insuredEntityTypeInput';
import InsuredPhoneNumberInput, { InsuredPhoneNumberFields } from '../../../components/formInputs/applications/insuredPhoneNumberInput';
import InsuredTaxIdInput, { InsuredTaxIdInputFields } from '../../../components/formInputs/applications/insuredTaxIdInput';
import NameExtendedInput, { NameExtendedInputFields } from '../../../components/formInputs/applications/nameInput';
import TaxIdTypeInput, { TaxIdTypeFields } from '../../../components/formInputs/applications/taxIdTypeInput';
import { Button, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import { createPersonOfInterest, removePersonOfInterest, selectAllPOIsByInsuredIdMap, selectSpouse, updatePersonOfInterest } from '../../../app/personOfInterestSlice';
import { selectEntityTypes, selectInsuredById, selectTaxTypes } from '../../../app/insuredsSlice';
import { defaultPOI, PersonOfInterest } from '../../../types/api/insureds/PersonOfInterest';
import { EntityTypeEnum } from '../../../types/api/enums/application/entityType.enum';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { PersonOrBusiness } from '../../../types/api/enums/contactInfo/personOrBusiness';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import TAX_ID_TYPES from '../../../constants/taxIdTypes';
import { RootState } from '../../../app/store';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';
import { validateMinimumRequirementsForSpouse, validatePersonOfInterest } from '../../../components/ag-grid/contactInfoGrid/validation/validateContactInfo';
import PersonOrBusinessTypeInput from '../../../components/formInputs/applications/personOrBusinessTypeInput';
import { Nullable } from '../../../types/util/Nullable';
import { useEffect, useMemo, useState } from 'react';
import { MuiDialogCloseReason } from '../../../types/mui/MuiDialogCloseReason';
import ImportContactsModal from '../../../components/ag-grid/contactInfoGrid/importContactsModal';
import { ConfirmStateContent, openConfirm } from '../../../app/confirmSlice';
import { openToast } from '../../../app/toastSlice';
import TaxIdUpdateModal from './taxIdUpdateModal';

export const SpouseInfoStep: ApplicationWizardStep = {
  isDisabled(app: ApplicationWizard, state: RootState) {
    const entityTypes = state.insureds.entityTypes;
    const insuredId = app.isNewEntity && isNotNullOrUndefined(app.newInsuredId) ? app.newInsuredId : app.insuredId;
    const insured = selectInsuredById(state, insuredId);

    const spousalEntityTypeId = entityTypes.find(entityType => entityType.name === EntityTypeEnum.SpousalMarried)?.entityTypeId;

    return !app.anyChanges ||
      (!app.isGeneratingForms) ||
      (insured?.entityTypeId !== spousalEntityTypeId);
  },
  title: 'Spouse Info',
  formId: 'form-spouse-info',
  index: 3,
  icon: <DiamondIcon />,
  isValid(app: ApplicationWizard, state: RootState) {
    const entityTypes = selectEntityTypes(state);
    const isDisabled = this.isDisabled(app, state);
    if (isDisabled) {
      return {
        isValid: true,
        errors: [],
      };
    }
    const insuredId = app.isNewEntity && isNotNullOrUndefined(app.newInsuredId) ? app.newInsuredId : app.insuredId;
    const insured = selectInsuredById(state, insuredId);
    const spousalEntityTypeId = entityTypes.find(entityType => entityType.name === EntityTypeEnum.SpousalMarried)?.entityTypeId;

    const isSpousalType = insured?.entityTypeId === spousalEntityTypeId;
    if (!isSpousalType) {
      return {
        isValid: true,
        errors: [],
      };
    }

    const spouse = selectSpouse(state, insuredId);

    if (isNullOrUndefined(spouse)) {
      return {
        isValid: false,
        errors: ['Insured entity type is spousal but no spouse found'],
      };
    }

    const taxIdTypes = selectTaxTypes(state);
    const personOfInterestValidationResult = validatePersonOfInterest(spouse, entityTypes, taxIdTypes, app.isGettingESignatures);
    return {
      isValid: personOfInterestValidationResult,
      errors: personOfInterestValidationResult ? [] : ['Invalid spouse'],
    };
  },
};

// these fields are currently not being shown but we are using this to pass them along
type AddressNoShowFields = {
  country: Nullable<string>;
  composite: Nullable<string>;
}

export type SpouseAddressTypeFields = {
  address: Nullable<AddressLine1Fields &
    AddressCityFields &
    AddressStateFields &
    AddressZipFields &
    AddressNoShowFields>
};

export type SpouseContactInfoFields =
  InsuredEmailFields &
  NameExtendedInputFields &
  InsuredEntityTypeFields &
  InsuredPhoneNumberFields &
  InsuredTaxIdInputFields &
  InsuredTaxIdInputFields &
  TaxIdTypeFields &
  SpouseAddressTypeFields &
  InfoCorrectYesNoInputFields;

const SpouseInfoPage = ({ application, navigateToPreviousStep, navigateToNextStep, updateIsInEditMode, isLoading, updateIsLoading }: ApplicationWizardStepProps) => {
  const entityTypes = useAppSelector(selectEntityTypes);
  const taxTypes = useAppSelector(selectTaxTypes);
  const spousalEntityTypeId = entityTypes.find(entityType => entityType.name === EntityTypeEnum.SpousalMarried)?.entityTypeId ?? null;
  const ssnTaxTypeId = taxTypes.find(taxType => taxType.name === TAX_ID_TYPES.SSN)?.taxTypeId;
  const [showImportModal, setShowImportModal] = useState(false);
  const insuredId = useMemo(() => application.isNewEntity && isNotNullOrUndefined(application.newInsuredId) ? application.newInsuredId : application.insuredId, [application]);
  const pois = useKeyMapSelector(selectAllPOIsByInsuredIdMap, insuredId);
  const dispatch = useAppDispatch();
  const methods = useForm<SpouseContactInfoFields>();
  const { formState, control, setValue } = methods;
  const currentSpouseInfo = useAppSelector(state => selectSpouse(state, insuredId));
  const isAboveCorrect = useWatch({ name: 'isAboveCorrect', control: control, defaultValue: isNotNullOrUndefined(currentSpouseInfo) });
  const [isAddingNewSpouse, setIsAddingNewSpouse] = useState(isNullOrUndefined(currentSpouseInfo));
  const areInputsDisabled = isAboveCorrect;
  const [isTaxIdModalOpen, setIsTaxIdModalOpen] = useState(false);
  const hasExistingTaxId = currentSpouseInfo ? isNotNullOrUndefined(currentSpouseInfo.taxId) && currentSpouseInfo.taxId.length > 0 : false;

  const defaultSpouseInfo: PersonOfInterest = {
    ...defaultPOI,
    id: generatePrimaryKey(),
    personOrBusiness: PersonOrBusiness.PERSON,
    personOfInterestType: PersonOfInterestType.Spouse,
    insuredId: insuredId,
    entityTypeId: spousalEntityTypeId ?? null,
    taxTypeId: ssnTaxTypeId ?? null,
    personOfInterestId: null,
  };
  const spouseInfo = currentSpouseInfo ?? defaultSpouseInfo;

  useEffect(() => {
    if (currentSpouseInfo && isAddingNewSpouse) {
      methods.reset({ ...currentSpouseInfo, entityTypeId: spousalEntityTypeId }, { keepValues: false });
      updateIsAboveCorrect(true);
      setIsAddingNewSpouse(false);
      if (currentSpouseInfo.entityTypeId !== spousalEntityTypeId) {
        dispatch(updatePersonOfInterest({ personOfInterest: { ...currentSpouseInfo, entityTypeId: spousalEntityTypeId }, updateTaxId: false }));
      }
    }
  }, [currentSpouseInfo]);

  const updateIsAboveCorrect = (val: boolean) => {
    setValue('isAboveCorrect', val);
    updateIsInEditMode(!val);
  };

  const isDirty = formState.isDirty;
  useEffect(() => {
    methods.trigger();

    if (isDirty) {
      if (formState.dirtyFields.isAboveCorrect ?? false) {
        updateIsAboveCorrect(methods.getValues().isAboveCorrect);
      }
      // Reset the form's isDirty state so we can tell when the next change comes in
      methods.reset({}, { keepValues: true, keepDefaultValues: true });
    }
  }, [isDirty, formState]);

  const handleInvalidSubmit: SubmitErrorHandler<SpouseContactInfoFields> = async data => {
    // firstName and lastName must always be error free in order to continue and save
    if (data.firstName || data.lastName) return;

    saveInProgressWork(methods.getValues());
  };

  const saveInProgressWork: SubmitHandler<SpouseContactInfoFields> = async poi => {
    updateIsLoading(true);
    try {
      if (!currentSpouseInfo) {
        await dispatch(createPersonOfInterest({ personOfInterest: { ...defaultSpouseInfo, ...poi } }));
      } else {
        await dispatch(updatePersonOfInterest({ personOfInterest: { ...spouseInfo, ...poi }, updateTaxId: false }));
      }
      updateIsAboveCorrect(true);
    } finally {
      updateIsLoading(false);
      setIsAddingNewSpouse(false);
    }
  };


  const handleImportDialogClose = (reason?: MuiDialogCloseReason) => {
    if (reason === 'backdropClick') return;
    setShowImportModal(false);
  };

  const handleNavigateToNextStep = () => {
    methods.trigger();
    if (!validateMinimumRequirementsForSpouse(spouseInfo)) {
      dispatch(openToast({ type: 'error', message: 'Please fill out First and Last Name before proceeding', shouldTimeout: true, allowClickToClose: true }));
      return;
    }
    navigateToNextStep();
  };

  const handleNavigateToPrevious = () => {
    navigateToPreviousStep();
  };

  const onCancelClick = () => {
    methods.reset({ ...spouseInfo }, { keepDefaultValues: true, keepDirty: false });
    updateIsAboveCorrect(true);
    methods.trigger();
  };

  const onRemoveSpouse = () => {
    if (!currentSpouseInfo) return;
    const confirmWindow: ConfirmStateContent = {
      title: 'Remove Spouse?',
      message: 'Are you sure you want to remove this spouse? This will not permanently delete the record, but rather mark it as inactive.',
      confirmText: 'Remove',
      onConfirm: async () => {
        setIsAddingNewSpouse(true);
        await dispatch(removePersonOfInterest({ personOfInterest: currentSpouseInfo }));
        methods.reset(defaultSpouseInfo);
        updateIsAboveCorrect(false);
      },
    };
    dispatch(openConfirm(confirmWindow));
  };

  const handleCloseTaxIdModal = () => {
    setIsTaxIdModalOpen(false);
    updateIsLoading(false);
  };

  return (
    <FormProvider {...methods}>
      <form style={{ height: '100%' }} id="form-spouse-info" onSubmit={methods.handleSubmit(saveInProgressWork, handleInvalidSubmit)}>
        <ApplicationWizardPage loading={isLoading} tabIndex={SpouseInfoStep.index} onPreviousClick={handleNavigateToPrevious} disabled={isLoading} onNextClick={handleNavigateToNextStep} hideNavigation={!isAboveCorrect}>
          <Grid container xs alignItems="center" rowSpacing={3} p={2} sx={{ maxWidth: '700px !important' }} flexDirection="column">
            <Grid container xs rowSpacing={3} columnSpacing={1} sx={{ maxWidth: '700px !important', display: 'flex', mt: 0 }}>
              <Grid item xs={12} width="100%" direction="row" display="flex">
                <PersonOrBusinessTypeInput value={defaultSpouseInfo.personOrBusiness ?? PersonOrBusiness.PERSON} disabled={true} />
                {currentSpouseInfo
                  ? <Button variant="outlined" onClick={onRemoveSpouse} id="btn-remove-spouse" sx={{ ml: 2, flexShrink: 0 }} disabled={areInputsDisabled}>Remove Spouse</Button>
                  : <Button id="btn-import-spouse" onClick={() => setShowImportModal(true)} sx={{ ml: 2 }} disabled={areInputsDisabled}>Import</Button>
                }
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <NameExtendedInput value={spouseInfo.firstName} name="firstName" label="First Name" disabled={areInputsDisabled} />
                </Grid>
                <Grid item xs={6}>
                  <NameExtendedInput value={spouseInfo.lastName} name="lastName" label="Last Name" disabled={areInputsDisabled} />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <NameExtendedInput value={spouseInfo.middleName} name="middleName" label="Middle Name" required={false} disabled={areInputsDisabled} />
                </Grid>
                <Grid item xs={6}>
                  <NameExtendedInput value={spouseInfo.suffix} name="suffix" label="Suffix" required={false} disabled={areInputsDisabled} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {/* ToDo: should these be made undefined within the input/type or can I assert this properly? */}
                <AddressLine1Input value={spouseInfo.address?.addressLine1 ?? null} disabled={areInputsDisabled} />
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={4}>
                  <AddressCityInput value={spouseInfo.address?.city ?? null} disabled={areInputsDisabled} />
                </Grid>
                <Grid item xs={4}>
                  <AddressStateInput value={spouseInfo.address?.state ?? null} disabled={areInputsDisabled} />
                </Grid>
                <Grid item xs={4}>
                  <AddressZipInput value={spouseInfo.address?.postalCode ?? null} disabled={areInputsDisabled} />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <InsuredPhoneNumberInput value={spouseInfo.phone} disabled={areInputsDisabled} />
                </Grid>
                <Grid item xs={6}>
                  <InsuredEmailInput value={spouseInfo.email} disabled={areInputsDisabled} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {/* for spouse info this is always going to be set to spouse and cannot be modified */}
                <InsuredEntityTypeInput value={defaultSpouseInfo.entityTypeId} disabled={true} personOrBusiness={PersonOrBusiness.PERSON}/>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <TaxIdTypeInput value={defaultSpouseInfo.taxTypeId} disabled={true} defaultEntityTypeId={spouseInfo.entityTypeId} defaultPersonOrBusinessType={spouseInfo.personOrBusiness} />
                </Grid>
                <Grid item xs={6}>
                  <InsuredTaxIdInput
                    value={spouseInfo.taxId}
                    disabled={areInputsDisabled || hasExistingTaxId}
                    onEditClick={hasExistingTaxId && !areInputsDisabled ? () => setIsTaxIdModalOpen(true) : undefined}
                    isFirstSsnInput={!hasExistingTaxId}
                  />
                </Grid>
              </Grid>
              {isAboveCorrect && (
                <Grid item xs={12} justifyContent="center">
                  <InfoCorrectYesNoInput value={isAboveCorrect} />
                </Grid>
              )}
            </Grid>
            {!isAboveCorrect && (
              <Grid container xs justifyContent="center" rowSpacing={3} columnSpacing={1} sx={{ maxWidth: '700px !important', m: 1 }}>
                <Grid item xs={6} display="flex" justifyContent="center">
                  <Button onClick={onCancelClick} id="btn-wizard-cancel" variant="outlined">Cancel</Button>
                  <Button type="submit" form="form-spouse-info" id="btn-wizard-save" variant="contained">Save</Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </ApplicationWizardPage>
      </form>
      {showImportModal && (
        <ImportContactsModal
          onClose={handleImportDialogClose}
          insuredId={insuredId}
          personOfInterestType={PersonOfInterestType.Spouse}
          existingPOIs={pois}
          closeOnImport
        />
      )}
      {isTaxIdModalOpen && <TaxIdUpdateModal
        onClose={handleCloseTaxIdModal}
        name={currentSpouseInfo ? `${currentSpouseInfo.firstName} ${currentSpouseInfo.lastName}` : null}
        updateTaxId={async (newTaxId: string) => {
          if (currentSpouseInfo) {
            updateIsLoading(true);
            await dispatch(updatePersonOfInterest({ personOfInterest: { ...currentSpouseInfo, taxId: newTaxId }, updateTaxId: true })).unwrap();
            updateIsLoading(false);
          }
          setValue('taxId', newTaxId);
        }}
        taxId={currentSpouseInfo ? currentSpouseInfo.taxId : null}
      />}
    </FormProvider>
  );
};

export default SpouseInfoPage;
