import { TextField } from '@mui/material';

import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { Nullable } from '../../../types/util/Nullable';

interface NameExtendedInputProps {
  value: Nullable<string>;
  name: Nullable<string>;
  label: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}

export type NameExtendedInputFields = {
  firstName: Nullable<string>; // individual first name
  lastName: Nullable<string>; // individual last name
  middleName: Nullable<string>; // individual middle name
  suffix: Nullable<string>; // individual suffix
}

const NameExtendedInput = ({ value, name, label, required = true, autoFocus = false, disabled = false }: NameExtendedInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name ?? 'name'}
      control={control}
      rules={{ required: required ? VALIDATION_RULES.required() : false, maxLength: VALIDATION_RULES.maxLength(100) }}
      defaultValue={value ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label={label}
          fullWidth
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default NameExtendedInput;

