import { Quote } from '../../types/api/Quote';
import { Nullable } from '../../types/util/Nullable';
import { useAppDispatch, useAppSelector } from '../reduxHooks';
import { selectClientFileById } from '../../app/clientFilesSlice';
import { getRoundingPrecision } from '../../utils/yieldRounding';
import { CoverageTypeCode, UnitOfMeasure } from '@silveus/calculations';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { fetchInsuranceOfferInformationIfOutOfSync, selectInsuranceOffer } from '../../app/admSlice';
import { GetInsuranceOfferParams } from '../../types/api/adm/GetInsuranceOfferParams';
import { useEffect } from 'react';
import { selectScenarioPieceForOfferData } from '../../app/scenarioPiecesSlice';
import { ScenarioId } from '../../types/api/PrimaryKeys';

export const useGetScenarioOfferPiecePlanCode = (scenarioId: Nullable<ScenarioId>) => {
  const offerPiecePlanCode = useAppSelector(s => scenarioId === null ? null : selectScenarioPieceForOfferData(s, scenarioId)?.planCode ?? null);
  return offerPiecePlanCode;
};


/** Assumes the scenario in question is already in state, and stable. */
export const useGetYieldRoundingPrecisionForExistingScenario = ({ scenario, quote }: { scenario: Nullable<RowCropScenario>, quote: Nullable<Quote> }) => {
  const offerPiecePlanCode = useGetScenarioOfferPiecePlanCode(scenario?.scenarioId ?? null);

  return useGetYieldRoundingPrecision({
    quote,
    typeId: scenario?.typeId ?? null,
    practiceId: scenario?.practiceId ?? null,
    insurancePlanCode: offerPiecePlanCode,
  });
};

interface CoreParams {
  quote: Nullable<Quote>;
  typeId: Nullable<string>;
  practiceId: Nullable<string>;
  insurancePlanCode: Nullable<string>;
}

/** This is the core call that all yield precision calls should flow into. */
export const useGetYieldRoundingPrecision = ({ quote, typeId, practiceId, insurancePlanCode }: CoreParams) => {
  const dispatch = useAppDispatch();
  const clientFileYear = useAppSelector(s => quote === null ? null : selectClientFileById(s, quote.clientFileId)?.year ?? null);

  const createOfferParams = (): Nullable<GetInsuranceOfferParams> => {
    if (clientFileYear === null) return null;
    if (quote === null) return null;
    if (practiceId === null) return null;
    if (typeId === null) return null;
    if (insurancePlanCode === null) return null;

    return {
      countyId: quote.countyId,
      year: clientFileYear,
      coverageTypeCode: CoverageTypeCode.A,
      insurancePlanCode: insurancePlanCode,
      practiceId,
      typeId,
    };
  };

  const insuranceOfferParams = createOfferParams();

  const roundingPrecision = useAppSelector(s => {
    const unitOfMeasure = insuranceOfferParams === null ? null : selectInsuranceOffer(s, insuranceOfferParams)?.unitOfMeasure ?? null;
    const roundingPrecision = unitOfMeasure === null ? null : getRoundingPrecision(unitOfMeasure as UnitOfMeasure);
    return roundingPrecision;
  });

  useEffect(() => {
    if (insuranceOfferParams !== null) {
      dispatch(fetchInsuranceOfferInformationIfOutOfSync(insuranceOfferParams));
    }
  }, [insuranceOfferParams, dispatch]);

  return roundingPrecision;
};

