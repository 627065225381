import { MenuItem, TextField } from '@mui/material';
import { HistoricalIncludeType } from '../../../types/api/enums/historicalAnalysis/historicalIncludeType.enum';
import HistoricalIncludeTypeAttributes from '../../../types/api/enums/historicalAnalysis/HistoricalIncludetypeAttributes';
import { Controller } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';

interface HistoricalIncludeTypeInputProps {
  value: HistoricalIncludeType;
}

export type HistoricalIncludeFields = {
  historicalIncludeType: HistoricalIncludeType;
}

const HistoricalIncludeInput = ({ value }: HistoricalIncludeTypeInputProps) => {
  return (
    <Controller
      name="historicalIncludeType"
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label="Include"
          fullWidth
          select
          size="small"
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => field.onChange(e.target.value)}
        >
          {Object.values(HistoricalIncludeTypeAttributes).map(it => {
            return (
              <MenuItem key={it.value} value={it.value}>{it.name}</MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
};

export default HistoricalIncludeInput;