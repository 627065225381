import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { TextField } from '@mui/material';
import { locationMaxLength, validateLocation } from '../../../pages/units/validations/locationValidation';

interface LocationProps {
  location: Nullable<string>;
  autoFocus?: boolean;
}

export type LocationFields = {
  location: Nullable<string>;
}

const LocationInput = ({ location, autoFocus = false }: LocationProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="location"
      control={control}
      rules={{ validate: validateLocation }}
      defaultValue={location}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Location"
          fullWidth
          inputProps={{ maxLength: locationMaxLength }}
          error={ !!error }
          helperText={ error?.message }
          inputRef={ ref }
          { ...field }
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default LocationInput;
