import { Grid, Tooltip, Typography } from '@mui/material';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { sum } from '@silveus/calculations';

type RateSummaryProps = {
  title: string;
  value: number;
  rateRecordsMap: Map<string, number>;
}

const RateSummary = ({ title, value, rateRecordsMap }: RateSummaryProps) => {
  const tooltipStyle = {
    fontSize: '11px',
  } as const;
  const rateRecords = Array.from(rateRecordsMap);
  const total = sum(rateRecords, rr => rr[1]);
  return (
    <Grid container direction="column" sx={{ width: '140px', pt: '0 !important', textAlign: 'center' }}>
      <Grid item>
        <Typography variant="subtitle1">{title}</Typography>
      </Grid>
      <Grid container item justifyContent="center" alignItems="center" columnGap={.5}>
        <Grid item>
          <Typography variant="subtitle2">{value}</Typography>
        </Grid>
        <Grid item sx={{ height: '24px' }}>
          <Tooltip
            title={
              <div>
                <Grid sx={{ minWidth: '220px' }}>
                  <Typography fontSize="14px" marginBottom={1}>Rate Distribution</Typography>
                  {rateRecords.map(rr => {
                    return (
                      <Grid container item xs={12} key={`${rr[1]}-${rr[0]}`} spacing={0} sx={{ margin: '0 !important', padding: '0 !important' }}>
                        <Grid item xs={10}>
                          <Typography sx={tooltipStyle}>{rr[0]}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography sx={{ ...tooltipStyle, textAlign: 'right' }}>{rr[1].toFixed(3)}</Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid container item xs={12} key={'total-row'} >
                    <Grid item xs={10}>
                      <Typography sx={tooltipStyle}>Total</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ ...tooltipStyle, textAlign: 'right' }}>{total.toFixed(3)}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            }>
            <HelpCenterIcon color="disabled" />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RateSummary;