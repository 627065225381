import { SubEntityCollectionTypeBase } from '../../../types/app/ReconciliationTrackedEntity';

/** Returns true if any of the subentities in a collection have any elements. */
export const subCollectionObjectHasAnyValues = (subCollections: SubEntityCollectionTypeBase) => {
  const allValues = getAllSubCollectionArrays(subCollections);
  return allValues.some(list => list.length > 0);
};

/** Returns an array of arrays. The outer array is each sub collection type.
 * The inner array is the list of sub entities of that type.
 */
export const getAllSubCollectionArrays = (subCollections: SubEntityCollectionTypeBase) => Object.values(subCollections);

/** Returns a single array holding all subentities, regardless of type bucket. */
export const getAllSubEntities = (subCollections: SubEntityCollectionTypeBase) => {
  const allValues = getAllSubCollectionArrays(subCollections);

  const result = [];
  for (const list of allValues) {
    result.push(...list);
  }
  return result;
};