import { AppTask } from '../../../../types/api/AppTask';
import { Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from '@mui/material';
import AppTaskRow from './appTaskRow';
import LandlordTenantAssignmentModal from './landlordTenantAssignmentModal';
import { useLandlordTenantAssignmentModal } from './useLandlordTenantAssignmentModal';
import { PersonOfInterest } from '../../../../types/api/insureds/PersonOfInterest';
import { AppTaskId } from '../../../../types/api/PrimaryKeys';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectIsLightMode } from '../../../../app/userSettingsSlice';
import { ApplicationWizard } from '../../../../types/api/applicationWizard/applicationWizard';
import { selectSalesClosingDates } from '../../../../app/applicationsSlice';
import { selectInsuredById } from '../../../../app/insuredsSlice';
import { isNullOrUndefined } from '../../../../utils/nullHandling';
import { selectClientFileById } from '../../../../app/clientFilesSlice';
import { AgencyInformation } from '../../../../types/api/agencyInformation';

type ApplicationDecisionGridProps = {
  appTasks: AppTask[];
  updateLandlordsAndTenants: (appTaskId: AppTaskId, landlordsAndTenants: PersonOfInterest[]) => void;
  selectedLandlordsAndTenants: Partial<Record<AppTaskId, PersonOfInterest[]>>;
  application: ApplicationWizard;
  agencies: AgencyInformation[];
}

export const AppTableCell = styled(TableCell)({
  fontSize: '12px',
  padding: '0 8px',
  borderBottom: 'none',
});

export const AppTableCellForInput = styled(TableCell)({
  fontSize: '12px',
  padding: 0,
  borderBottom: 'none',
});

export const AppTableHeader = styled(TableCell)({
  height: '47px',
  fontSize: '13px',
  padding: '0 8px',
  boxSizing: 'border-box',
  borderBottom: 'none',
  fontWeight: 500,
  backgroundColor: 'var(--ag-header-background-color)',
  '&:not(:last-child):after': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    width: 'var(--ag-header-column-resize-handle-width)',
    height: 'var(--ag-header-column-resize-handle-height)',
    top: 'calc(50% - var(--ag-header-column-resize-handle-height)* 0.5)',
    backgroundColor: 'var(--ag-header-column-resize-handle-color)',
    right: 0,
  },
});

const ApplicationDecisionGrid = ({ appTasks, updateLandlordsAndTenants, selectedLandlordsAndTenants, application, agencies }: ApplicationDecisionGridProps) => {
  const isLightMode = useAppSelector(selectIsLightMode);
  const { selectedAppTask, openModal, applyAndClose, cancel } = useLandlordTenantAssignmentModal();
  const salesClosingDates = useAppSelector(selectSalesClosingDates);
  const insured = useAppSelector(state => selectInsuredById(state, application.insuredId));
  const newInsured = useAppSelector(state => selectInsuredById(state, application.newInsuredId));
  const clientFile = useAppSelector(state => selectClientFileById(state, application.clientFileId));

  if (isNullOrUndefined(insured) || isNullOrUndefined(clientFile)) {
    return null;
  }

  const handleAssignClick = (appTask: AppTask) => {
    openModal(appTask);
  };

  const handleApplyAndSave = (landlordsAndTenants: PersonOfInterest[]) => {
    if (!selectedAppTask) {
      return;
    }
    updateLandlordsAndTenants(selectedAppTask.appTaskId, landlordsAndTenants);
    applyAndClose();
  };

  return (
    <Grid container className={isLightMode ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}>
      <Typography pb={1} variant="subtitle1">{newInsured ? newInsured.name : insured.name} - App Tasks</Typography>
      {
        appTasks.map((appTask, index) => (
          <Card key={appTask.appTaskId} elevation={4} sx={{ mb: 1.5, overflow: 'unset', width: '100%', mr: 1.25 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <AppTableCell sx={{ backgroundColor: 'var(--ag-header-background-color)', paddingRight: 0 }} width="20px" />
                  <AppTableHeader sx={{ minWidth: '45px' }}>State</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '110px' }}>Product Type</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '110px' }}>Status</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '110px' }}>Type</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '80px' }}>New C/T</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '80px' }}>SCD</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '100px' }}>AIP</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '90px' }}>Agent Code</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '130px', maxWidth: '140px' }}>Agency</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '90px' }}>Policy #</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '120px' }}>CT Previous AIP</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '120px' }}>CT Prev Policy#</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '70px' }}>Des. Cty.</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '80px' }}>Nation/State</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '90px' }}>Insuring LL&lsquo;s Share</AppTableHeader>
                  <AppTableHeader sx={{ minWidth: '90px' }}>Insuring Tenants&lsquo;s Share</AppTableHeader>
                  <AppTableHeader sx={{ p: 0 }} width="16px"></AppTableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <AppTaskRow
                  appTask={appTask}
                  index={index}
                  onAssignClick={handleAssignClick}
                  application={application}
                  salesClosingDates={salesClosingDates}
                  cropYear={clientFile.year}
                  agencies={agencies}
                />
              </TableBody>
            </Table>
          </Card>
        ))
      }
      {
        selectedAppTask && (
          <LandlordTenantAssignmentModal
            applyAndClose={handleApplyAndSave}
            cancel={cancel}
            selectedAppTask={selectedAppTask}
            selectedAppTaskLandlordsAndTenants={selectedLandlordsAndTenants[selectedAppTask.appTaskId] ?? []}
          />
        )
      }
    </Grid>
  );
};

export default ApplicationDecisionGrid;
