import { ScenarioPieceType } from '@silveus/calculations';
import { IEnumAttributes } from '../IEnumAttributes';
import InsurancePlan from './InsurancePlan.enum';

const InsurancePlanAttributes: Readonly<Record<InsurancePlan, IEnumAttributes<ScenarioPieceType>>> = {
  [InsurancePlan.Arp]: { name: '05', description: 'ARP', value: ScenarioPieceType.Arp },
  [InsurancePlan.ArpHpe]: { name: '06', description: 'ARP-HPE', value: ScenarioPieceType.ArpHpe },
  [InsurancePlan.Ayp]: { name: '04', description: 'AYP', value: ScenarioPieceType.Ayp },
  [InsurancePlan.RP]: { name: '02', description: 'RP', value: ScenarioPieceType.RP },
  [InsurancePlan.RpHpe]: { name: '03', description: 'RP-HPE', value: ScenarioPieceType.RpHpe },
  [InsurancePlan.YP]: { name: '01', description: 'YP', value: ScenarioPieceType.YP },
  [InsurancePlan.Aph]: { name: '90', description: 'APH', value: ScenarioPieceType.Aph },
  [InsurancePlan.ScoRp]: { name: '32', description: 'SCO-RP', value: ScenarioPieceType.ScoRp },
  [InsurancePlan.ScoRpHpe]: { name: '33', description: 'SCO-RP-HPE', value: ScenarioPieceType.ScoRpHpe },
  [InsurancePlan.ScoYp]: { name: '31', description: 'SCO-YP', value: ScenarioPieceType.ScoYp },
  [InsurancePlan.EcoRp]: { name: '88', description: 'ECO-RP', value: ScenarioPieceType.EcoRp },
  [InsurancePlan.EcoRpHpe]: { name: '89', description: 'ECO-RP-HPE', value: ScenarioPieceType.EcoRpHpe },
  [InsurancePlan.EcoYp]: { name: '87', description: 'ECO-YP', value: ScenarioPieceType.EcoYp },
  [InsurancePlan.MP]: { name: '16', description: 'MP', value: ScenarioPieceType.MP },
  [InsurancePlan.MpHpo]: { name: '17', description: 'MP-HPO', value: ScenarioPieceType.MpHpo },
  [InsurancePlan.StaxRp]: { name: '35', description: 'STAX-RP', value: ScenarioPieceType.StaxRp },
  [InsurancePlan.StaxRpHpe]: { name: '36', description: 'STAX-RP-HPE', value: ScenarioPieceType.StaxRpHpe },
  [InsurancePlan.Hip]: { name: '37', description: 'HIP-WI', value: ScenarioPieceType.Hip },
  [InsurancePlan.RampYield]: { name: '01', description: 'Yield', value: ScenarioPieceType.RampYield },
  [InsurancePlan.RampRevenue]: { name: '02', description: 'Revenue', value: ScenarioPieceType.RampRevenue },

  [InsurancePlan.RevBoostYp]: { name: '01', description: 'YP', value: ScenarioPieceType.RevBoostYp },
  [InsurancePlan.RevBoostRp]: { name: '02', description: 'RP', value: ScenarioPieceType.RevBoostRp },
  [InsurancePlan.RevBoostRpHpe]: { name: '03', description: 'RP-HPE', value: ScenarioPieceType.RevBoostRpHpe },
  [InsurancePlan.RevBoostRpHpo]: { name: '04', description: 'RP-HPO', value: ScenarioPieceType.RevBoostRpHpo },

  [InsurancePlan.MyScoYield]: { name: '01', description: 'Yield', value: ScenarioPieceType.MyScoYield },
  [InsurancePlan.MyScoRevenue]: { name: '02', description: 'Revenue', value: ScenarioPieceType.MyScoRevenue },
  [InsurancePlan.MyEcoYield]: { name: '03', description: 'Yield', value: ScenarioPieceType.MyEcoYield },
  [InsurancePlan.MyEcoRevenue]: { name: '04', description: 'Revenue', value: ScenarioPieceType.MyEcoRevenue },

  [InsurancePlan.ScoPlusYield]: { name: '01', description: 'Yield', value: ScenarioPieceType.ScoPlusYield },
  [InsurancePlan.ScoPlusRevenue]: { name: '02', description: 'Revenue', value: ScenarioPieceType.ScoPlusRevenue },
  [InsurancePlan.EcoPlusYield]: { name: '03', description: 'Yield', value: ScenarioPieceType.EcoPlusYield },
  [InsurancePlan.EcoPlusRevenue]: { name: '04', description: 'Revenue', value: ScenarioPieceType.EcoPlusRevenue },

  [InsurancePlan.IceStack590]: { name: '01', description: 'Stack 5-90', value: ScenarioPieceType.IceStack590 },
  [InsurancePlan.IceStack595]: { name: '02', description: 'Stack 5-95', value: ScenarioPieceType.IceStack595 },
  [InsurancePlan.IceStack1090]: { name: '03', description: 'Stack 10-90', value: ScenarioPieceType.IceStack1090 },
  [InsurancePlan.IceStack1095]: { name: '04', description: 'Stack 10-95', value: ScenarioPieceType.IceStack1095 },
  [InsurancePlan.IceStack1595]: { name: '05', description: 'Stack 15-95', value: ScenarioPieceType.IceStack1595 },
  [InsurancePlan.IceShield1090]: { name: '06', description: 'Shield 10-90', value: ScenarioPieceType.IceShield1090 },
  [InsurancePlan.IceShield1095]: { name: '07', description: 'Shield 10-95', value: ScenarioPieceType.IceShield1095 },

  [InsurancePlan.Plc]: { name: '15', description: 'PLC', value: ScenarioPieceType.Plc },
  [InsurancePlan.Arc]: { name: '16', description: 'ARC', value: ScenarioPieceType.Arc },
};

export default InsurancePlanAttributes;
