import { ScenarioPieceOrderingService, ScenarioPieceType } from '@silveus/calculations';

// This effectively is just a holder for what could be static methods.
export const scenarioPieceOrderingServiceInstance = new ScenarioPieceOrderingService();

// Call these and cache - these will never change at runtime.
export const baseScenarioPieces: readonly ScenarioPieceType[] = scenarioPieceOrderingServiceInstance.getBaseScenarioPieces();


// Call these and cache - these will never change at runtime.
// Using optional base here instead of base because base does not account for scenario piece types that may have optional prerequisites.
// The optional base will basically return what the baseScenarioPieces did, but will filter out any pieces with optional prerequisites that currently exist on the scenario
export const optionalBaseScenarioPieces = (existingScenarioPieceTypes: ScenarioPieceType[]): readonly ScenarioPieceType[] => {
  return scenarioPieceOrderingServiceInstance.getOptionalBaseScenarioPieces(existingScenarioPieceTypes);
};