import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { MenuItem, TextField } from '@mui/material';
import { CoverageType, CoverageTypeAttributes } from '../../../types/api/enums/application/coverageType.enum';
import { AppType } from '../../../types/api/enums/application/AppType.enum';
import { useEffect, useMemo } from 'react';
import { handleValidationResult } from './validationHelpers';
import { validateCoverageType } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import { AppTask } from '../../../types/api/AppTask';
import { IEnumAttributes } from '@silveus/calculations/dist/utils/IEnumAttributes';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';

type CoverageTypeInputProps = {
  value: Nullable<CoverageType>;
  index: number;
  coverageIndex: number;
  application: ApplicationWizard;
  canEditAppTask: boolean;
}

const CoverageTypeInput = ({ value, index, coverageIndex, application, canEditAppTask }: CoverageTypeInputProps) => {
  const inputName = `appTasks.${index}.coverages.${coverageIndex}.coverageType`;
  const { control, setValue } = useFormContext();

  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });
  const currentCoverage: CoverageType = useWatch({ name: inputName });
  const appType: Nullable<AppType> = useWatch({ name: `appTasks.${index}.appType`, control: control, defaultValue: null });

  const availableOptions = useMemo(() => {
    if (!appType) return [];

    const options: IEnumAttributes<CoverageType>[] = [];

    if (currentCoverage === CoverageType.New) {
      options.push(CoverageTypeAttributes[CoverageType.New]);
      return options;
    }
    // CT coverages have an additional option for renew in cases where the app task was split from an existing
    // app task. This allow the user to select the renew option to move the coverage back to the original change app task.
    if (appType === AppType.CancelTransfer && isNotNullOrUndefined(currentAppTask.ctOriginalAppTaskId)) {
      options.push(CoverageTypeAttributes[CoverageType.Renew]);
    }

    if (appType === AppType.New || (appType === AppType.Change && Boolean(currentAppTask.isMultiCounty))) {
      options.push(CoverageTypeAttributes[CoverageType.New]);
    }

    if ((appType === AppType.Change && currentAppTask.coverages.length > 1) || appType === AppType.CancelTransfer) {
      options.push(CoverageTypeAttributes[CoverageType.CancelTransfer]);
    }

    if (appType === AppType.HoldForCancelTransfer || appType === AppType.Change) {
      options.push(CoverageTypeAttributes[CoverageType.HoldForCancelTransfer]);
    }

    if (appType === AppType.CommitNoChange || appType === AppType.Change) {
      options.push(CoverageTypeAttributes[CoverageType.Renew]);
    }

    if (appType === AppType.Cancel || appType === AppType.Change) {
      options.push(CoverageTypeAttributes[CoverageType.Cancel]);
    }

    return options.sort((a, b) => a.name.localeCompare(b.name));
  }, [appType, currentAppTask.coverages]);


  useEffect(() => {
    const thereIsNewCoverageOnAnExistingAppType = currentCoverage === CoverageType.New && appType !== AppType.New;
    if (thereIsNewCoverageOnAnExistingAppType) {
      return;
    }
    let newValue: Nullable<CoverageType> = null;
    if (!appType) return;
    switch (appType) {
      case AppType.New:
        newValue = CoverageType.New;
        break;
      case AppType.CancelTransfer:
        newValue = CoverageType.CancelTransfer;
        break;
      case AppType.HoldForCancelTransfer:
        newValue = CoverageType.HoldForCancelTransfer;
        break;
      case AppType.CommitNoChange:
        newValue = CoverageType.Renew;
        break;
      case AppType.Cancel:
        newValue = CoverageType.Cancel;
        break;
      case AppType.Change:
        newValue = CoverageType.Renew;
        break;
    }

    setValue(inputName, newValue);
  }, [appType]);

  return (
    <Controller
      control={control}
      shouldUnregister={false}
      name={inputName}
      rules={{ validate: val => handleValidationResult(validateCoverageType(val, currentAppTask)) }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          select
          disabled={(!appType || !application.anyChanges || currentAppTask.appType !== AppType.Change || !canEditAppTask) && (isNullOrUndefined(currentAppTask.ctOriginalAppTaskId))}
          error={!!error}
          inputRef={ref}
          size="small"
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          {availableOptions.map(ct => <MenuItem sx={{ fontSize: '12px' }} key={ct.value} value={ct.value}>{ct.name}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default CoverageTypeInput;
