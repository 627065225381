import { isEmpty } from '../../../utils/nullHandling';
import { Grid } from '@mui/material';
import DiffDetails from './diffDetails';
import { ReactElement } from 'react';
import ReconciliationTrackedEntity from '../../../types/app/ReconciliationTrackedEntity';
import EntityType from '../constants/entityType';

interface EntityDiffBodyProps<EntityId, SubEntityType, SubEntityId> {
  detailString: string;
  getSubEntityDiffElements?: (
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    subEntities: ReconciliationTrackedEntity<SubEntityType, SubEntityId, any, EntityId>[],
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    subEntitySubset: ReconciliationTrackedEntity<SubEntityType, SubEntityId, any, EntityId>[],
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    onReconciliationTypeChange: (subEntity: ReconciliationTrackedEntity<SubEntityType, SubEntityId, any, EntityId>) => void,
  ) => ReactElement[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onSubEntityReconciledStatusChange: (subEntity: ReconciliationTrackedEntity<SubEntityType, SubEntityId, any, EntityId>) => void;
  entityType: EntityType;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  subEntities: ReconciliationTrackedEntity<SubEntityType, SubEntityId, any, EntityId>[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  subsetEntities: ReconciliationTrackedEntity<SubEntityType, SubEntityId, any, EntityId>[];
  shouldAlwaysShowDetails: boolean;
}

const EntityDiffBody = <EntityId, SubEntityType, SubEntityId>({
  detailString, getSubEntityDiffElements, onSubEntityReconciledStatusChange,
  entityType, subEntities, subsetEntities, shouldAlwaysShowDetails,
}: EntityDiffBodyProps<EntityId, SubEntityType, SubEntityId>) => {
  const shouldShowDetails = !isEmpty(detailString) && (shouldAlwaysShowDetails || entityType !== 'unmodified');

  const subEntityElements = getSubEntityDiffElements === undefined
    ? []
    : getSubEntityDiffElements(subEntities, subsetEntities, onSubEntityReconciledStatusChange);

  return (
    <Grid container rowSpacing={2}>
      {shouldShowDetails && <Grid item container rowSpacing={1} xs={12}>
        <Grid item key={detailString} xs={12}>
          <DiffDetails detail={detailString} detailType={entityType} />
        </Grid>
      </Grid>}
      {subEntityElements.length !== 0 && <Grid item xs={12} container rowSpacing={2}>
        {subEntityElements.map((element, index) => {
          return (
            <Grid item xs={12} key={index}>
              {element}
            </Grid>
          );
        })}
      </Grid>}
    </Grid>
  );
};

export default EntityDiffBody;