import { RevBoostCoverageLevelParams } from '../../../types/api/adm/PrivateProductsParams';
import CoverageLevelAvailability from '../../../types/api/adm/CoverageLevelAvailability';
import { getAvailableRevBoostCoverageLevels } from '../../privateProducts.service';
import { privateProductsDb } from '../../../db';
import { safeWhere } from '../../../utils/dexieQueryHelpers/whereClauses';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';

export const getAvailableRevBoostCoverageLevelsRequest = async (revBoostCoverageLevelParams: RevBoostCoverageLevelParams): Promise<CoverageLevelAvailability[]> => {
  const request = () => getAvailableRevBoostCoverageLevels(revBoostCoverageLevelParams);

  const readTransaction = () => privateProductsDb.transaction('r', privateProductsDb.revBoostCoverageLevels, async () => {
    const revBoostCoverageLevels = await safeWhere(privateProductsDb.revBoostCoverageLevels, { mpciUpperCoverageLevel: revBoostCoverageLevelParams.underlyingCoverageLevel }).toArray();
    const coverageLevelAvailabilities: CoverageLevelAvailability[] = revBoostCoverageLevels.map(revBoostCoverageLevel => {
      const coverageLevelAvailability: CoverageLevelAvailability = {
        upperCoverageLevel: revBoostCoverageLevel.upperCoverageLevel,
        lowerCoverageLevel: revBoostCoverageLevel.lowerCoverageLevel,
      };
      return coverageLevelAvailability;
    });
    return coverageLevelAvailabilities;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};
