import { ICellRendererParams } from 'ag-grid-community';
import { selectAdjustedYieldForUnitYear } from '../../../app/unitsSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import UnitYear from '../../../types/api/UnitYear';
import { formatNumber } from '../../../utils/formatNumbers';
import { Na } from '../../../types/util/Na';
import { Quote } from '../../../types/api/Quote';

type AdjustedYieldCellRendererProps = {
  unitYear: UnitYear;
  scenarioId: ScenarioId;
  params: ICellRendererParams;
  quote: Quote;
};

const AdjustedYieldCellRenderer = ({ params, unitYear, scenarioId, quote }: AdjustedYieldCellRendererProps) => {
  const isPinnedRow = params.node.rowPinned === 'bottom';
  const adjustedYield = useAppSelector(state => isPinnedRow ? params.data.adjYield : selectAdjustedYieldForUnitYear(state, unitYear.unitYearId, scenarioId, quote.countyId, quote.commodityCode));

  return (
    <div>{adjustedYield !== null ? formatNumber(adjustedYield, true) : Na}</div>
  );
};

export default AdjustedYieldCellRenderer;
