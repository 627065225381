import {
  ApprovedYieldProps, calculateWeightedAverageAdjustedYield, calculateWeightedAverageApprovedYield,
  HistoricalTYield,
  OptionCode, SubCountyCode
} from '@silveus/calculations';
import { YeStatusType } from '../../types/api/enums/optionStates/yeStatusType.enum';
import UnitYearAph from '../../types/api/UnitYearAph';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import { calculateApprovedYield, calculateAdjustedYield } from '@silveus/calculations';
import UnitYear from '../../types/api/UnitYear';
import TYield from '../../types/api/adm/TYield';
import TrendAdjustmentFactor from '../../types/api/adm/TrendAdjustmentFactor';
import { getFilteredTYield } from '../../utils/tYieldUtils';
import { Nullable } from '../../types/util/Nullable';
import { YeYear } from '../../types/api/adm/YeYears';

export function yieldCalculator(currentTYields: TYield[],
  planCode: Nullable<string>, allTrendAdjustmentFactors: TrendAdjustmentFactor[], allHistoricalTYields: HistoricalTYield[]) {

  function createFullProps(unitYear: UnitYear, unitYearAph: readonly UnitYearAph[],
    scenarioUnitYearAph: readonly ScenarioUnitYearAph[], selectedOptionCodes: OptionCode[],
    yeYears: readonly YeYear[]): ApprovedYieldProps {

    const calcUnitYearAph = [];
    for (const unitYearAphRow of unitYearAph) {
      const scenUnitYearAphRow = scenarioUnitYearAph.find(scenAphRow => scenAphRow.unitYearAphId === unitYearAphRow.unitYearAphId);
      if (scenUnitYearAphRow === undefined) continue;
      const filteredYeYears = yeYears.filter(yeYear => yeYear.typeId === unitYear.typeId && yeYear.practiceId === unitYear.practiceId &&
        (planCode === null || yeYear.planCode === planCode)).map(yeYear => yeYear.year);
      const isYeApplicable = selectedOptionCodes.some(o => o === OptionCode.YE) && filteredYeYears.includes(unitYearAphRow.year);
      calcUnitYearAph.push({ ...unitYearAphRow, excluded: isYeApplicable && scenUnitYearAphRow.yeStatus === YeStatusType.NotOptedOut });
    }

    const trendAdjustmentFactor = allTrendAdjustmentFactors.find(taf => taf.typeId === unitYear.typeId && taf.practiceId === unitYear.practiceId && (planCode === null || taf.planCode === planCode))?.factor ?? 0;
    const historicalTYields = allHistoricalTYields.filter(hty => hty.typeId === unitYear.typeId && hty.practiceId === unitYear.practiceId && (planCode === null || hty.planCode === planCode));
    const currentYearTYield = getFilteredTYield(currentTYields, [unitYear.subCountyCode ?? SubCountyCode.URA], unitYear.typeId, unitYear.practiceId, unitYear.subCountyCode)?.transitionalYield ?? null;

    return { unitYearAph: calcUnitYearAph, historicalTYields, selectedOptionCodes, trendAdjustmentFactor, currentYearTYield };
  }
  return {
    forSingleUnitYear: (unitYear: UnitYear, unitYearAph: readonly UnitYearAph[],
      scenarioUnitYearAphArray: readonly ScenarioUnitYearAph[], selectedOptionCodes: OptionCode[],
      yeYears: readonly YeYear[]) => {

      const props = createFullProps(unitYear, unitYearAph, scenarioUnitYearAphArray, selectedOptionCodes, yeYears);
      return {
        calculateApprovedYield: () => calculateApprovedYield(unitYear, props),
        calculateAdjustedYield: () => calculateAdjustedYield(props),
      };
    },

    forMultipleUnitYears: (unitYearMap: Map<UnitYear, {
      unitYearAph: UnitYearAph[], scenarioUnitYearAph: ScenarioUnitYearAph[], optionCodes: OptionCode[],
      yeYears: readonly YeYear[]
    }>) => {
      const propsMap = new Map<UnitYear, ApprovedYieldProps>();
      for (const [unitYear, { unitYearAph, scenarioUnitYearAph, optionCodes, yeYears }] of unitYearMap.entries()) {
        propsMap.set(unitYear, createFullProps(unitYear, unitYearAph, scenarioUnitYearAph, optionCodes, yeYears));
      }
      return {
        calculateWeightedAverageApprovedYield: () => calculateWeightedAverageApprovedYield(propsMap),
        calculateWeightedAverageAdjustedYield: () => calculateWeightedAverageAdjustedYield(propsMap),
      };
    },
  };
}
