import { Grid, InputAdornment, Slider, useMediaQuery, useTheme } from '@mui/material';
import NumberTextField from '../numberTextField/numberTextField.component';
import { useEffect, useState } from 'react';
import { cleanZoomPercentage } from './cleanZoomPercentage';
import { minimumZoomPercentage, maximumZoomPercentage, defaultZoomPercentage } from '../../app/applicationZoomSlice';
import { KeyboardEvent } from 'react';

interface Props {
  zoomPercentage: number;
  onZoomChange: (zoomPercentage: number) => void;
}

const marks = [
  {
    value: minimumZoomPercentage,
    label: `${minimumZoomPercentage}%`,
  },
  {
    value: defaultZoomPercentage,
    label: `${defaultZoomPercentage}%`,
  },
  {
    value: maximumZoomPercentage,
    label: `${maximumZoomPercentage}%`,
  },
];

export const ApplicationZoomInput = ({ zoomPercentage, onZoomChange }: Props) => {
  // Used to hide marks on smaller viewports - marks easily overlap with each other on smaller viewports.
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [textInputValue, setTextInputValue] = useState(zoomPercentage.toString());

  useEffect(() => {
    setTextInputValue(zoomPercentage.toString());
  }, [zoomPercentage]);

  const handleZoomChange = (zoomPercentage: number) => {
    const cleanedZoomPercentage = cleanZoomPercentage(zoomPercentage);
    setTextInputValue(cleanedZoomPercentage.toString());
    onZoomChange(cleanedZoomPercentage);
  };

  const handleSliderChange = (value: number | number[]) => {
    // This would only happen if the slider was a range slider, which is not how this is configured.
    if (Array.isArray(value)) {
      return;
    }

    handleZoomChange(value);
  };

  const handleInputChange = (newValueAsString: string) => {
    setTextInputValue(newValueAsString);
  };

  const lockInInputValue = () => {
    handleZoomChange(Number(textInputValue));
  };

  const handleInputBlur = () => {
    lockInInputValue();
  };

  const handleInputKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      lockInInputValue();
    }
  };

  return (
    <Grid container spacing={4} alignItems="center" sx={{ width: 300, ml: -2 }}>
      <Grid item xs={8}>
        <Slider
          value={zoomPercentage}
          onChange={(_, value) => handleSliderChange(value)}
          min={minimumZoomPercentage}
          max={maximumZoomPercentage}
          marks={isSmallScreen ? [] : marks}
          step={10}
          valueLabelDisplay="auto"
        />
      </Grid>
      <Grid item xs>
        <NumberTextField
          value={textInputValue}
          type="number"
          variant="standard"
          size="small"
          onChange={e => handleInputChange(e.target.value)}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );
};