import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferExpectedCountyYield } from '../../../app/admSlice';
import ExpectedCountyYieldInput, { ExpectedCountyYieldProps } from '../scenario/expectedCountyYieldInput';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { LoadFromStateProps } from '../scenario/loadFromStateProps';
import { Nullable } from '../../../types/util/Nullable';

interface ScenarioPieceExpectedCountyYieldProps extends ExpectedCountyYieldProps, LoadFromStateProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>,
}

const ScenarioPieceExpectedCountyYieldInput = ({ scenarioPiece, typeId, expectedCountyYield, coverageLevel, volatility,
  practiceId, commodityCode, insurancePlanCodes, sx, label, autoFocus = false, disabled, loadFromState = false }:
  ScenarioPieceExpectedCountyYieldProps) => {

  const { setValue, trigger } = useFormContext();
  const offerCountyYield = useAppSelector(selectOfferExpectedCountyYield);

  useEffect(() => {
    // This updates the yield if the scenario piece does not exist yet (saving will create a new scenarioPiece).
    // This should not be necessary in most cases. It handles cases where the insurance plan associated with the
    // scenario piece has a different yield than the yield used by default on the scenario.
    // Note: This also requires something (like the scenario piece form) to refetch the prices and yields based on the potentially new insurance plan code
    if (scenarioPiece !== null || offerCountyYield === null || !loadFromState) return;

    setValue('expectedCountyYield', offerCountyYield);
    trigger('expectedCountyYield');
  }, [offerCountyYield]);

  return (
    <ExpectedCountyYieldInput
      expectedCountyYield={expectedCountyYield}
      sx={sx}
      commodityCode={commodityCode}
      label={label}
      autoFocus={autoFocus}
      disabled={disabled}
      typeId={typeId}
      practiceId={practiceId}
      insurancePlanCodes={insurancePlanCodes}
      coverageLevel={coverageLevel}
      volatility={volatility}
    />
  );
};

export default ScenarioPieceExpectedCountyYieldInput;
