import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { farmNumberMaxLength, validateFarmNumber } from '../../../pages/units/validations/farmNumberValidation';

interface FarmNumberProps {
  farmNumber: Nullable<string>;
  autoFocus?: boolean;
}

export type FarmNumberFields = {
  farmNumber: Nullable<string>;
}

const FarmNumberInput = ({ farmNumber, autoFocus = false }: FarmNumberProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="farmNumber"
      control={control}
      rules={{ validate: validateFarmNumber }}
      defaultValue={farmNumber}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Farm #"
          fullWidth
          inputProps={{ maxLength: farmNumberMaxLength }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default FarmNumberInput;
