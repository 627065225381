import { quoterDb } from '../../db';
import {
  createRowCropScenario, deleteRowCropScenario,
  getRowCropScenario,
  getRowCropScenarios,
  getRowCropScenariosForClientFile,
  updateRowCropScenario, updateRowCropScenarios
} from '../scenarios.service';
import {
  addSingle,
  update, getDefaultCachingStrategy
} from '../offlineDataAccess.service';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { ClientFileId, QuoteId, ScenarioId } from '../../types/api/PrimaryKeys';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { safeGet } from '../../utils/dexieQueryHelpers/getClauses';
import { deleteRowCropScenarioPiecesLocally } from './scenarioPieceRequestInterceptor';
import { applySoftDelete } from '../../utils/dexieQueryHelpers/softDelete';
import { isOnlineFromStore } from '../../utils/isOnlineFromStore';
import { nonGetCacheOnly, nonGetNetworkOnly } from '../../utils/cachingStrategies';

const table = quoterDb.rowCropScenarios;

export const getRowCropScenariosRequest = async (quoteId: QuoteId): Promise<RowCropScenario[]> => {
  const request = () => getRowCropScenarios(quoteId);

  const transactionTables = [quoterDb.rowCropScenarios, quoterDb.scenarioQuickUnits];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const scenarios = await safeWhere(quoterDb.rowCropScenarios, { quoteId: quoteId }).toArray();
    const scenarioIds = scenarios.map(s => s.scenarioId);
    const scenarioQuickUnits = await safeWhere(quoterDb.scenarioQuickUnits, 'scenarioId').anyOf(scenarioIds).toArray();

    const updatedScenarios: RowCropScenario[] = [];

    scenarios.forEach(scenario => {
      const matchingQuickUnit = scenarioQuickUnits.find(squ => squ.scenarioId === scenario.scenarioId);

      if (matchingQuickUnit !== undefined) {
        scenario.quickUnit = matchingQuickUnit;
      }

      updatedScenarios.push(scenario);
    });

    return updatedScenarios;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getRowCropScenariosByClientFileRequest = async (clientFileId: ClientFileId): Promise<RowCropScenario[]> => {
  const request = () => getRowCropScenariosForClientFile(clientFileId);

  const transactionTables = [quoterDb.rowCropScenarios, quoterDb.scenarioQuickUnits];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const quotes = await safeWhere(quoterDb.quotes, { clientFileId: clientFileId }).toArray();
    const quoteIds = quotes.map(q => q.quoteId);

    const scenarios = await safeWhere(quoterDb.rowCropScenarios, 'quoteId').anyOf(quoteIds).toArray();
    const scenarioIds = scenarios.map(s => s.scenarioId);
    const scenarioQuickUnits = await safeWhere(quoterDb.scenarioQuickUnits, 'scenarioId').anyOf(scenarioIds).toArray();

    const updatedScenarios: RowCropScenario[] = [];

    scenarios.forEach(scenario => {
      const matchingQuickUnit = scenarioQuickUnits.find(squ => squ.scenarioId === scenario.scenarioId);

      if (matchingQuickUnit !== undefined) {
        scenario.quickUnit = matchingQuickUnit;
      }

      updatedScenarios.push(scenario);
    });

    return updatedScenarios;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getRowCropScenarioRequest = async (scenarioId: ScenarioId): Promise<RowCropScenario> => {
  const request = () => getRowCropScenario(scenarioId);

  const transactionTables = [quoterDb.rowCropScenarios, quoterDb.scenarioQuickUnits];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const scenario = await safeGet(quoterDb.rowCropScenarios, { scenarioId: scenarioId });

    if (scenario !== undefined) {
      const scenarioQuickUnit = await safeGet(quoterDb.scenarioQuickUnits, { scenarioId: scenario.scenarioId });

      if (scenarioQuickUnit !== undefined) {
        scenario.quickUnit = scenarioQuickUnit;
      }
    }

    return scenario;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const createRowCropScenarioRequest = async (scenario: RowCropScenario): Promise<CreatedItemResult<ScenarioId>> => {
  const request = () => createRowCropScenario(scenario);
  return await addSingle(table, scenario, request);
};

export const updateRowCropScenarioRequest = async (scenario: RowCropScenario): Promise<void> => {
  const request = () => updateRowCropScenario(scenario);
  return await update(table, scenario, request);
};

export const updateRowCropScenariosRequest = async (scenarios: RowCropScenario[]): Promise<void> => {
  const request = () => updateRowCropScenarios(scenarios);
  return await update(table, scenarios, request);
};

export const deleteRowCropScenarioRequest = async (scenarioId: ScenarioId): Promise<void> => {
  const request = () => deleteRowCropScenario(scenarioId);

  const transactionTables = [
    quoterDb.rowCropScenarios,
    quoterDb.rowCropScenarioPieces,
    quoterDb.matrices,
    quoterDb.unitGroups,
    quoterDb.scenarioOptions,
    quoterDb.historicalAnalyses,
    quoterDb.trendlineAnalyses,
    quoterDb.premiumBreakdowns,
    quoterDb.scenarioQuickUnits,
    quoterDb.scenarioUnitYearAph,
    quoterDb.scenarioOptionUnitYears,
  ];

  const updateTransaction = () => quoterDb.transaction('rw', transactionTables, async () => {
    await deleteRowCropScenariosLocally([scenarioId]);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;
  return await strategy(request, updateTransaction);
};

export const deleteRowCropScenariosLocally = async (scenarioIds: ScenarioId[]): Promise<void> => {
  const scenarios = safeWhere(quoterDb.rowCropScenarios, 'scenarioId').anyOf(scenarioIds);

  const scenarioPieces = safeWhere(quoterDb.rowCropScenarioPieces, 'scenarioId').anyOf(scenarioIds);
  const scenarioPieceIds = (await scenarioPieces.toArray()).map(sp => sp.scenarioPieceId);

  await deleteRowCropScenarioPiecesLocally(scenarioPieceIds);

  const scenarioOptions = safeWhere(quoterDb.scenarioOptions, 'scenarioId').anyOf(scenarioIds);
  const matrices = safeWhere(quoterDb.matrices, 'primaryScenarioId').anyOf(scenarioIds);
  const historicalQuotes = safeWhere(quoterDb.historicalAnalyses, 'primaryScenarioId').anyOf(scenarioIds);
  const trendlineAnalyses = safeWhere(quoterDb.trendlineAnalyses, 'primaryScenarioId').anyOf(scenarioIds);
  const premiumBreakdowns = safeWhere(quoterDb.premiumBreakdowns, 'primaryScenarioId').anyOf(scenarioIds);
  const scenarioQuickUnits = safeWhere(quoterDb.scenarioQuickUnits, 'scenarioId').anyOf(scenarioIds);
  const scenarioUnitYearAph = safeWhere(quoterDb.scenarioUnitYearAph, 'scenarioId').anyOf(scenarioIds);
  const scenarioOptionIds = (await scenarioOptions.toArray()).map(so => so.scenarioOptionId);
  const scenarioOptionUnitYears = safeWhere(quoterDb.scenarioOptionUnitYears, 'scenarioOptionId').anyOf(scenarioOptionIds);

  const softDeleteScenarioUnitYearAph = applySoftDelete(scenarioUnitYearAph);
  const softDeleteScenarioQuickUnits = applySoftDelete(scenarioQuickUnits);
  const softDeleteScenarioOptionUnitYears = applySoftDelete(scenarioOptionUnitYears);
  const softDeleteScenarioOptions = applySoftDelete(scenarioOptions);
  const softDeletePremiumBreakdowns = applySoftDelete(premiumBreakdowns);
  const softDeleteTrendlineAnalyses = applySoftDelete(trendlineAnalyses);
  const softDeleteHistoricalQuotes = applySoftDelete(historicalQuotes);
  const softDeleteMatrices = applySoftDelete(matrices);
  const softDeleteScenarios = applySoftDelete(scenarios);

  await Promise.all([
    softDeleteScenarioUnitYearAph,
    softDeleteScenarioQuickUnits,
    softDeleteScenarioOptionUnitYears,
    softDeleteScenarioOptions,
    softDeletePremiumBreakdowns,
    softDeleteTrendlineAnalyses,
    softDeleteHistoricalQuotes ,
    softDeleteMatrices,
    softDeleteScenarios,
  ]);
};
