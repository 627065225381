import { Card, CardMedia, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { useKeyMapSelector } from '../../../hooks/reduxHooks';
import { selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder } from '../../../app/scenarioPiecesSlice';
import { roundToPlaces } from '@silveus/calculations';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import './historicalAnalysisScenarioCard.styles.css';
import HistoricalAnalysis from '../../../types/api/historicalAnalysis';
import { useHistoricalAnalysisCalculations } from '../../../hooks/useHistoricalAnalysisCalculations';
import { average, groupBy } from '../../../utils/arrayUtils';
import { getFriendlyScenarioPieceName } from '../../../utils/scenarioPieceUtils';
import { getValueToUseForHistoricalQuoteResult } from './getValueToUseForHistoricalQuoteResult';
import { Nullable } from '../../../types/util/Nullable';

type HistoricalAnalysisScenarioCardProps = {
  isSelected?: boolean;
  scenario: RowCropScenario;
  onClick: () => void;
  historicalAnalysis: HistoricalAnalysis;
}

const HistoricalAnalysisScenarioCard = ({ scenario, historicalAnalysis, onClick, isSelected = false }: HistoricalAnalysisScenarioCardProps) => {
  const historicalAnalysisYearsToExclude = historicalAnalysis.historicalAnalysisYears.filter(hay => hay.excluded);
  const yearsToExclude = historicalAnalysisYearsToExclude.map(hay => hay.year);
  const scenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder, scenario.scenarioId);
  const historicalCalcResults = useHistoricalAnalysisCalculations(historicalAnalysis.startYear, historicalAnalysis.endYear, historicalAnalysis.acreageType, scenario.scenarioId, historicalAnalysis.calculateMissingYears);

  const applicableYearResults = historicalCalcResults.yearResults.filter(yr => !yearsToExclude.includes(yr.year));

  const flatCalcResults = applicableYearResults.flatMap(yr => yr.result.scenarioPieceGroups.flatMap(spg => spg.scenarioPieces));
  const scenarioPieceResults = groupBy(flatCalcResults, sp => sp.scenarioPieceType);

  const yearlyValues = applicableYearResults.map(yr =>
    getValueToUseForHistoricalQuoteResult(yr.result.indemnityPerAcreAmount, yr.result.indemnityAmount, yr.result.premiumPerAcreAmount,
      yr.result.premiumAmount, historicalAnalysis.historicalIncludeType, historicalAnalysis.historicalShowType));

  const averageNet = applicableYearResults.length === 0 ? null : roundToPlaces(average(yearlyValues), 2);
  const averagePaymentPerPremiumDollar = applicableYearResults.length === 0 ? null : roundToPlaces(average(applicableYearResults.map(yr => roundToPlaces(yr.result.indemnityPerAcreAmount / yr.result.premiumPerAcreAmount, 2))), 2);
  const numberOfLossesPaid = applicableYearResults.length === 0 ? null : applicableYearResults.filter(yr => yr.result.indemnityAmount > 0).length;
  const percentOfYearsPaid = applicableYearResults.length === 0 ? null : applicableYearResults.length === 0 ? 0 : roundToPlaces((numberOfLossesPaid ?? 0) / applicableYearResults.length, 2);

  return (
    <Grid container direction="column" onClick={onClick} sx={{ cursor: 'pointer' }} rowSpacing={0.5}>
      <Grid item xs zeroMinWidth>
        <Typography noWrap sx={{ color: theme => theme.palette.primary.main, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '400px' }} variant="clickable1">{scenario.name}</Typography>
      </Grid>
      <Grid item xs container>
        <Grid item xs="auto">
          <Card className={classNames({ 'historical-analysis-card-box-shadow': isSelected })} sx={{ borderWidth: 0 }}>
            <CardMedia sx={{ height: '100%' }}>
              <Grid container height="100%">
                <Grid item xs="auto">
                  <div style={{ width: '9px', height: '100%', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', backgroundColor: scenario.scenarioColor }}>
                  </div>
                </Grid>
                <Grid item container xs alignItems="center" pl={2} pr={2} pt={1} pb={1} columnGap={2}>
                  <Grid container item xs="auto" width="75px" direction="column" rowGap={0.25}>
                    <Grid item ><Typography variant="h6">&nbsp;</Typography></Grid>
                    <Grid item ><Typography variant="label">Avg $</Typography></Grid>
                    <Grid item ><Typography variant="label">Pay/Prem</Typography></Grid>
                    <Grid item ><Typography variant="label"># Losses Pd</Typography></Grid>
                    <Grid item ><Typography variant="label">% Years Paid</Typography></Grid>
                  </Grid>
                  {scenarioPieces.map(sp => {
                    const label = getFriendlyScenarioPieceName(sp.scenarioPieceType);
                    const spResults = scenarioPieceResults.get(sp.scenarioPieceType) ?? [];

                    const spValues = spResults.map(result =>
                      getValueToUseForHistoricalQuoteResult(result.indemnityPerAcre, result.indemnityAmount, result.producerPremiumPerAcre,
                        result.producerPremium, historicalAnalysis.historicalIncludeType, historicalAnalysis.historicalShowType));

                    const averageSpNet = spResults.length === 0 ? null : roundToPlaces(average(spValues), 2);
                    const averageSpPaymentPerPremiumDollar = spResults.length === 0 ? null : roundToPlaces(average(spResults.map(result => roundToPlaces(result.indemnityPerAcre / result.producerPremiumPerAcre, 2))), 2);
                    const spNumberOfLossesPaid = spResults.length === 0 ? null : spResults.filter(result => result.indemnityPerAcre > 0).length;
                    const spPercentOfYearsPaid = spResults.length === 0 ? null : (spNumberOfLossesPaid ?? 0) / spResults.length;

                    return (
                      <Grid item key={sp.scenarioPieceId}>
                        <HistoricalAnalysisMetricColumn
                          label={label}
                          average={averageSpNet}
                          payPrem={averageSpPaymentPerPremiumDollar}
                          lossesPd={spNumberOfLossesPaid}
                          percentYearsPaid={spPercentOfYearsPaid}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item>
                    <HistoricalAnalysisMetricColumn
                      label="Total"
                      average={averageNet}
                      payPrem={averagePaymentPerPremiumDollar}
                      lossesPd={numberOfLossesPaid}
                      percentYearsPaid={percentOfYearsPaid}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardMedia>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

type HistoricalAnalysisMetricColumnProps = {
  label: string;
  average: Nullable<number>;
  payPrem: Nullable<number>;
  lossesPd: Nullable<number>;
  percentYearsPaid: Nullable<number>;
}
const HistoricalAnalysisMetricColumn = ({ label, average, payPrem, lossesPd, percentYearsPaid }: HistoricalAnalysisMetricColumnProps) => {
  return (
    <Grid container direction="column" rowGap={0} sx={{ paddingTop: '0 !important', minWidth: '80px' }}>
      <Grid item xs textAlign="right"><Typography variant="h6">{label}</Typography></Grid>
      <Grid item xs textAlign="right">{average === null ? <Typography variant="value"> - </Typography> : <CurrencyText currencyAmount={average} colorize={true} align={'right'} />}</Grid>
      <Grid item xs textAlign="right">{payPrem === null ? <Typography variant="value"> - </Typography> : <CurrencyText currencyAmount={payPrem} colorize={true} align={'right'} />}</Grid>
      <Grid item xs textAlign="right"><Typography variant="value">{lossesPd ?? '-'}</Typography></Grid>
      <Grid item xs textAlign="right"><Typography variant="value">{percentYearsPaid === null ? '-' : `${roundToPlaces(percentYearsPaid * 100, 2)}%`}</Typography></Grid>
    </Grid>
  );
};

export default HistoricalAnalysisScenarioCard;