import {
  Commodity, CoverageTypeCode,
  ProductOfferAvailabilities,
  RampIndemnityParams,
  RampPremiumParams,
  RampRequestDTO,
  RampUnit, Unit
} from '@silveus/calculations';
import { RootState } from '../../../app/store';
import { RampExtendedDataFields } from '../../../pages/scenarioPiece/ramp/rampExtendedDataFields';
import BaseUnit from '../../../types/api/BaseUnit';
import RampCalculationParams from '../../../types/api/calculationData/rampCalculationParams';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getCalcParamRequestUnits, getMatchingCalcParamDataForUnit, getUnitsForScenarioPiece } from '../calculationUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import { getRampCalcDataRequest } from '../../requestInterception/scenarioPieces/rampRequestInterceptor';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import { RampCalculationParamsRequest } from '../../../types/api/calculationData/rampCalculationParamsRequest';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';
import { RampManualPremiumParams } from '@silveus/calculations/dist/privateProducts/ramp/params/rampManualPremiumParams';
import { ClientFile } from '../../../types/api/ClientFile';
import { Quote } from '../../../types/api/Quote';
import { selectOfferAvailabilitiesForCountyCommodity } from '../../../app/availabilitySlice';
import { isNullOrUndefined } from '../../../utils/nullHandling';

export const createRampData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[], aphYield: number, productOfferAvailabilities?: ProductOfferAvailabilities) => {
  // needed items
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  //Get the calc data back from the API
  const pieceAvailabilities = selectOfferAvailabilitiesForCountyCommodity(state, quote.countyId, quote.commodityCode);
  const rampAvailability = pieceAvailabilities.find(x => x.scenarioPieceType === scenarioPiece.scenarioPieceType);
  const useManualPremium: boolean = rampAvailability?.productOfferAvailabilities.at(0)?.extendedData?.rampUseManualPremium ?? false;

  const returnedData: RampCalculationParams[] = [];
  if (!useManualPremium) {
    const calcParamRequest = createCalcParamRequest(state, scenarioPiece, scenario, baseUnits, quote, clientFile, productOfferAvailabilities);
    returnedData.push(...(await getRampCalcDataRequest(calcParamRequest)));
  }

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createRampPlanDto(useManualPremium, scenarioPiece, returnedData, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece, aphYield, quote, clientFile, productOfferAvailabilities);
  return scenarioPieceSpecificData;
};

const transformRampData = (useManualPremium: boolean, unit: Nullable<BaseUnit>, scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece, aphYield: number, quote: Quote, clientFile: ClientFile, productOfferAvailabilities?: ProductOfferAvailabilities):
  RampPremiumParams & RampIndemnityParams | RampManualPremiumParams & RampIndemnityParams => {
  const extendedData = (scenarioPiece.rowCropScenarioPieceExtendedData as RampExtendedDataFields);

  const rampIndemnityParams: RampIndemnityParams = {
    commodityCode: (unit?.commodityCode ?? '') as Commodity,
    harvestPrice: scenario.harvestPrice ?? 0,
    priceElectionPercent: 100,
    projectedPrice: scenario.projectedPrice ?? 0,
    dollarLimitOfInsurance: extendedData.liabilityAmount,
    isPercentOfProjected: extendedData.isPercentOfProjected,
    percentOfProjected: extendedData.percentOfProjected / 100,
    maxLimitOfInsurance: maxLimitOfInsurance((unit?.commodityCode ?? '') as Commodity, aphYield, scenario.projectedPrice, scenarioPiece.upperCoverageLevel, scenarioPiece.lowerCoverageLevel),
    liabilityAdjustmentFactor: 1,
    multipleCommodityAdjustmentFactor: 1,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    unitStructureCode: scenarioPiece.unitStructure,
    coverageTypeCode: CoverageTypeCode.A,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
  };

  if (useManualPremium) {
    const premiumPerAcre = scenarioPiece.rowCropScenarioPieceExtendedData?.customPremium ?? 0;

    const rampManualPremiumAndIndemnityParams: RampManualPremiumParams & RampIndemnityParams =
    {
      ...rampIndemnityParams,
      premiumPerAcre,
    };

    return rampManualPremiumAndIndemnityParams;
  }

  const rampNormalPremiumParams: RampPremiumParams & RampIndemnityParams = {
    ...rampIndemnityParams,
  };

  return rampNormalPremiumParams;
};

const createRampUnits = (useManualPremium: boolean, scenarioPiece: RowCropScenarioPiece, rampCalcData: RampCalculationParams[], units: BaseUnit[], scenario: RowCropScenario): (RampUnit & Unit)[] => {
  return units.map(unit => {

    const matchingData = getMatchingCalcParamDataForUnit(unit, rampCalcData);

    //TODO: Error Handling
    // if using manual premium, the premiumRate below isn't used.
    if (!useManualPremium && isNullOrUndefined(matchingData)) throw new Error('RAMP Data Transform State mismatch: Unable to find data returned from API associated with unit in state.');

    const unitDto = createBaseUnit(unit);
    const rampUnit: RampUnit & Unit = {
      ...unitDto,
      id: unit.id,
      premiumRate: matchingData?.percentAmt ?? 0,
    };

    return rampUnit;
  });
};

const createRampPlanDto = (useManualPremium: boolean, scenarioPiece: RowCropScenarioPiece, apiData: RampCalculationParams[], unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[], aphYield: number, quote: Quote, clientFile: ClientFile, productOfferAvailabilities?: ProductOfferAvailabilities): RampRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createRampUnits(
        useManualPremium,
        scenarioPiece,
        apiData,
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformRampData(useManualPremium, unitData.at(0) ?? null, scenario, scenarioPiece, aphYield, quote, clientFile, productOfferAvailabilities),
  };
};

export const maxLimitOfInsurance = (commodityCode: Commodity, aphYield: number, projectedPrice: Nullable<number>, upperCoverageLevel?: number, lowerCoverageLevel?: number) => {
  let maxLiabilityAmount = 50;
  if (upperCoverageLevel !== undefined) {
    switch (commodityCode) {
      case Commodity.Corn:
        maxLiabilityAmount = upperCoverageLevel === 95 ? 50 : 120;
        break;
      case Commodity.Soybeans:
        maxLiabilityAmount = upperCoverageLevel === 95 ? 40 : 90;
        break;
      default:
        maxLiabilityAmount = 50;
        break;
    }

    if (lowerCoverageLevel !== undefined && projectedPrice !== null) {
      maxLiabilityAmount = Math.min(maxLiabilityAmount, ((upperCoverageLevel - lowerCoverageLevel) * aphYield * projectedPrice));
    }
  }
  return maxLiabilityAmount;
};

const createCalcParamRequest = (state: RootState, scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, baseUnits: BaseUnit[], quote: Quote, clientFile: ClientFile, productOfferAvailabilities?: ProductOfferAvailabilities): RampCalculationParamsRequest => {

  return {
    year: clientFile.year,
    planCode: scenarioPiece.planCode,
    upperCoverageLevel: scenarioPiece.upperCoverageLevel,
    lowerCoverageLevel: scenarioPiece.lowerCoverageLevel,
    commodityCode: quote.commodityCode,
    countyId: quote.countyId,
    distinctUnits: getCalcParamRequestUnits(baseUnits),
  };
};
