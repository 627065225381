
export const EmptyUserLinkedScenarioSettings = {
  quotes: {},
} as const;

export const EmptyUserLinkedMatrixSettings = {
  quotes: {},
} as const;

export const EmptyUserScenarioOrderSettings = {
  quotes: {},
} as const;

export const EmptyUserHiddenScenarioSettings = {
  quotes: {},
} as const;

export const EmptyUserScenarioPieceOrderSettings = {
  scenarios: {},
} as const;

export const EmptyUserMatrixPresetsSetting = [];

export const DefaultPremiumBreakdownOptions = {
  includeHPO: true,
  summary: {
    premium: true,
    coverage: true,
    subsidy: true,
    subsidyPercent: true,
  },
  details: {
    coveragePerAcre: true,
    premiumPerAcre: true,
    totalPremium: true,
    subsidyPercent: true,
    subsidyPerAcre: true,
    totalSubsidy: true,
  },
} as const;

// ToDo: Add a "satisfies" constraint to this once it's available in TS 4.9.
// These should "satisfy" a new type where fieldName is a PossibleLinkedScenarioField.
// That will give us type checking & error feedback in this file instead of way somewhere else.
export const AllPossibleScenarioLinkFields = [
  {
    label: 'Projected Price',
    fieldName: 'projectedPrice',
  },
  {
    label: 'Harvest Price',
    fieldName: 'harvestPrice',
  },
  {
    label: 'Producer Yield',
    fieldName: 'actualProducerYield',
  },
  {
    label: 'Volatility',
    fieldName: 'volatility',
  },
  {
    label: 'Adjusted Yield',
    fieldName: 'quickUnit.aphYield',
  },
  {
    label: 'Approved Yield',
    fieldName: 'quickUnit.approvedYield',
  },
  {
    label: 'Rate Yield',
    fieldName: 'quickUnit.rateYield',
  },
  {
    label: 'Acres',
    fieldName: 'quickUnit.acres',
  },
  {
    label: 'Share Percent',
    fieldName: 'quickUnit.sharePercent',
  },
  {
    label: 'Actual County Yield',
    fieldName: 'actualCountyYield',
  },
  {
    label: 'Expected County Yield',
    fieldName: 'expectedCountyYield',
  },
  {
    label: 'MP Projected Price',
    fieldName: 'rowCropScenarioPieceExtendedData.projectedPrice',
  },
  {
    label: 'MP Expected Cost',
    fieldName: 'rowCropScenarioPieceExtendedData.expectedInputCosts',
  },
  {
    label: 'MP Actual Cost',
    fieldName: 'rowCropScenarioPieceExtendedData.actualInputCosts',
  },
] as const;
