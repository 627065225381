import Dexie, { Table } from 'dexie';
import { Insured } from './types/api/insureds/Insured';
import { ClientFile } from './types/api/ClientFile';
import { Quote } from './types/api/Quote';
import UnitYear from './types/api/UnitYear';
import UnitYearAph from './types/api/UnitYearAph';
import HistoricalAnalysis from './types/api/historicalAnalysis';
import Matrix from './types/api/Matrix';
import { ScenarioUnitYearAph } from './types/api/ScenarioUnitYearAph';
import TrendlineAnalysis from './types/api/TrendlineAnalysis';
import { UnitGroup } from './types/api/UnitGroup';
import ScenarioOption from './types/api/options/ScenarioOption';
import ScenarioOptionUnitYear from './types/api/options/ScenarioOptionUnitYear';
import { RowCropScenario } from './types/api/RowCropScenario';
import { RowCropScenarioPiece } from './types/api/RowCropScenarioPiece';
import { State } from './types/api/adm/State';
import { County } from './types/api/adm/County';
import AreaCoverageLevel from './types/api/adm/AreaCoverageLevel';
import AreaRate from './types/api/adm/AreaRate';
import BaseRate from './types/api/adm/BaseRate';
import BetaSequence from './types/api/adm/BetaSequence';
import ComboRevenueFactor from './types/api/adm/ComboRevenueFactor';
import CoverageLevel from './types/api/adm/CoverageLevel';
import CoverageLevelDifferential from './types/api/adm/CoverageLevelDifferential';
import HistoricalRevenueCapping from './types/api/adm/HistoricalRevenueCapping';
import InsuranceOffer from './types/api/adm/InsuranceOffer';
import OptionRate from './types/api/adm/OptionRate';
import Price from './types/api/adm/Price';
import SubCountyRate from './types/api/adm/SubCountyRate';
import Subsidy from './types/api/adm/Subsidy';
import UnitDiscountLevel from './types/api/adm/UnitDiscountLevel';
import YieldAndTYield from './types/api/adm/YieldAndTYield';
import YieldExclusion from './types/api/adm/YieldExclusion';
import { Commodity } from './types/api/adm/Commodity';
import CommodityUse from './types/api/adm/CommodityUse';
import { CropType } from './types/api/adm/CropType';
import { Practice } from './types/api/adm/Practice';
import { Option } from './types/api/adm/Option';
import GuaranteeAdjustment from './types/api/adm/GuaranteeAdjustment';
import PriceElectionPercent from './types/api/adm/PriceElectionPercent';
import EcoScoPlusRate from './types/api/adm/EcoScoPlusRate';
import RampRate from './types/api/adm/RampRate';
import RevBoostCoverageLevel from './types/api/adm/RevBoostCoverageLevel';
import PremiumBreakdown from './types/api/premiumBreakdown';
import ScenarioQuickUnit from './types/api/ScenarioQuickUnit';
import RawHistoricalYieldTrendYear from './types/api/adm/RawHistoricalYieldTrendYear';
import { IceAcresCode } from './types/api/adm/IceAcreCode';
import IceProduct from './types/api/adm/IceProduct';
import IceRateData from './types/api/adm/IceRateData';
import { IceDeductibleSelection } from './types/api/adm/iceDeductibleSelection';
import UserSetting from './types/api/userSettings/UserSetting';
import PriceGroupMember from './types/api/adm/PriceGroupMember';
import HistoricalPrice from './types/api/adm/HistoricalPrice';
import { InputCostScenarioPiece } from './types/api/InputCostScenarioPiece';
import { OfflineChangeTrackedEntity } from './types/app/OfflineChangeTrackedEntity';
import { offlineChangeTrackingMiddleware } from './utils/dexieMiddleware/offlineChangeTrackingMiddleware';
import ForwardSoldScenarioPiece from './types/api/ForwardSoldScenarioPiece';
import HarvestRevenueScenarioPiece from './types/api/HarvestRevenueScenarioPiece';
import { FilteredKeyOf } from './types/util/FilteredKeyOf';
import { excludeSoftDeletedMiddleware } from './utils/dexieMiddleware/excludeSoftDeletedMiddleware';
import SupportedAdmYear from './types/api/adm/SupportedAdmYear';
import HailScenarioPiece from './types/api/HailScenarioPiece';
import HailScenarioPieceComposition from './types/api/HailScenarioPieceComposition';
import { HailProduct } from './types/api/hail/hailProduct';
import HailRate from './types/api/hail/hailRate';
import HailEndorsement from './types/api/hail/hailEndorsement';
import HipRate from './types/api/adm/HipRate';
import HipEventHistorical from './types/api/adm/HipEventHistorical';
import HailScenarioPieceRate from './types/api/hail/HailScenarioPieceRate';
import HailScenarioPieceEndorsement from './types/api/hail/HailScenarioPieceEndorsement';
import InsuranceCalendar from './types/api/adm/InsuranceCalendar';
import ArcYieldHistory from './types/api/adm/ArcYieldHistory';
import { MYAPriceHistory } from './types/api/adm/MYAPriceHistory';

export type QuoterDbTable<T extends OfflineChangeTrackedEntity> = Table<T>;

// Note: these types may seem redundant, but they keep these otherwise magic strings tied to their expected key name.
const offlineLastUpdatedOn: FilteredKeyOf<OfflineChangeTrackedEntity, 'offlineLastUpdatedOn'> = 'offlineLastUpdatedOn';
const offlineDeletedOn: FilteredKeyOf<OfflineChangeTrackedEntity, 'offlineDeletedOn'> = 'offlineDeletedOn';

export class QuoterDb extends Dexie {
  insureds!: QuoterDbTable<Insured>;
  clientFiles!: QuoterDbTable<ClientFile>;
  premiumBreakdowns!: QuoterDbTable<PremiumBreakdown>;
  quotes!: QuoterDbTable<Quote>;
  rowCropScenarios!: QuoterDbTable<RowCropScenario>;
  rowCropScenarioPieces!: QuoterDbTable<RowCropScenarioPiece>;
  inputCostScenarioPieces!: QuoterDbTable<InputCostScenarioPiece>;
  unitGroups!: QuoterDbTable<UnitGroup>;
  unitYears!: QuoterDbTable<UnitYear>;
  unitYearAph!: QuoterDbTable<UnitYearAph>;
  scenarioUnitYearAph!: QuoterDbTable<ScenarioUnitYearAph>;
  scenarioOptions!: QuoterDbTable<ScenarioOption>;
  scenarioOptionUnitYears!: QuoterDbTable<ScenarioOptionUnitYear>;
  scenarioQuickUnits!: QuoterDbTable<ScenarioQuickUnit>;
  historicalAnalyses!: QuoterDbTable<HistoricalAnalysis>;
  matrices!: QuoterDbTable<Matrix>;
  trendlineAnalyses!: QuoterDbTable<TrendlineAnalysis>;
  userSettings!: QuoterDbTable<UserSetting>;
  forwardSoldScenarioPieces!: QuoterDbTable<ForwardSoldScenarioPiece>;
  harvestRevenueScenarioPieces!: QuoterDbTable<HarvestRevenueScenarioPiece>;
  hailScenarioPieceCompositions!: QuoterDbTable<HailScenarioPieceComposition>;
  hailScenarioPieces!: QuoterDbTable<HailScenarioPiece>;
  hailScenarioPieceRates!: QuoterDbTable<HailScenarioPieceRate>;
  hailScenarioPieceEndorsements!: QuoterDbTable<HailScenarioPieceEndorsement>;

  constructor() {
    super('quoterDb');

    this.version(10).stores({
      // Note: All tables need to have indices for "offlineLastUpdatedOn" and "offlineDeletedOn"
      // for the change tracking function to work. Please do not omit it, as all tables
      // in this DB will be programatically queried.
      insureds: `id, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      clientFiles: `clientFileId, insuredId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      premiumBreakdowns: `premiumBreakdownId, primaryScenarioId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      quotes: `quoteId, clientFileId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      rowCropScenarios: `scenarioId, quoteId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      rowCropScenarioPieces: `scenarioPieceId, scenarioId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      unitGroups: `unitGroupId, scenarioPieceId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      unitYears: `unitYearId, unitId, insuredId, [insuredId+year+commodityCode+countyId], ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      unitYearAph: `unitYearAphId, unitYearId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      scenarioUnitYearAph: `scenarioUnitYearAphId, scenarioId, unitYearAphId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      scenarioOptions: `scenarioOptionId, scenarioId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      scenarioOptionUnitYears: `scenarioOptionUnitYearId, scenarioOptionId, unitYearId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      scenarioQuickUnits: `scenarioQuickUnitId, scenarioId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      historicalAnalyses: `historicalAnalysisId, primaryScenarioId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      matrices: `matrixId, primaryScenarioId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      trendlineAnalyses: `trendlineAnalysisId, primaryScenarioId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      userSettings: `userSettingsId, userId, userSettingTypeId, [userId+userSettingTypeId], ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      inputCostScenarioPieces: `scenarioPieceId, scenarioId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      forwardSoldScenarioPieces: `scenarioPieceId, scenarioId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      harvestRevenueScenarioPieces: `scenarioPieceId, scenarioId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      hailScenarioPieceCompositions: `hailScenarioPieceCompositionId, scenarioId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      hailScenarioPieces: `scenarioPieceId, scenarioId, hailScenarioPieceCompositionId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      hailScenarioPieceRates: `hailScenarioPieceRateId, hailScenarioPieceId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
      hailScenarioPieceEndorsements: `hailScenarioPieceEndorsementId, hailScenarioPieceId, ${offlineLastUpdatedOn}, ${offlineDeletedOn}`,
    });
  }
}

export class AdmDb extends Dexie {
  areaCoverageLevels!: Table<AreaCoverageLevel>;
  areaRates!: Table<AreaRate>;
  baseRates!: Table<BaseRate>;
  betaSequences!: Table<BetaSequence>;
  comboRevenueFactors!: Table<ComboRevenueFactor>;
  commodities!: Table<Commodity>;
  commodityUses!: Table<CommodityUse>;
  counties!: Table<County>;
  coverageLevels!: Table<CoverageLevel>;
  coverageLevelDifferentials!: Table<CoverageLevelDifferential>;
  cropTypes!: Table<CropType>;
  hipEventHistoricals!: Table<HipEventHistorical>;
  hipRates!: Table<HipRate>;
  historicalRevenueCappings!: Table<HistoricalRevenueCapping>;
  historicalYieldTrendYears!: Table<RawHistoricalYieldTrendYear>;
  insuranceOffers!: Table<InsuranceOffer>;
  options!: Table<Option>;
  optionRates!: Table<OptionRate>;
  practices!: Table<Practice>;
  prices!: Table<Price>;
  priceGroupMembers!: Table<PriceGroupMember>;
  priceHistories!: Table<HistoricalPrice>;
  states!: Table<State>;
  subCountyRates!: Table<SubCountyRate>;
  subsidies!: Table<Subsidy>;
  supportedAdmYears!: Table<SupportedAdmYear>;
  unitDiscountLevels!: Table<UnitDiscountLevel>;
  yieldAndTYields!: Table<YieldAndTYield>;
  yieldExclusions!: Table<YieldExclusion>;
  insuranceCalendars!: Table<InsuranceCalendar>;
  myaPriceHistories!: Table<MYAPriceHistory>;

  constructor() {
    super('admDb');

    this.version(12).stores({
      areaCoverageLevels: 'areaCoverageLevelId, admInsuranceOfferId, coverageLevelId, [admInsuranceOfferId+coverageLevelId]',
      areaRates: '++, areaRateId, priceVolatilityFactor, [areaRateId+priceVolatilityFactor]',
      baseRates: 'baseRateId, insuranceOfferId',
      betaSequences: 'betaSequenceId, betaId',
      comboRevenueFactors: 'comboRevenueFactorId, commodityCode, stateCode, [commodityCode+stateCode]',
      commodities: 'commodityCode',
      commodityUses: 'commodityUseId, commodityCode, commodityYear',
      counties: 'countyId, countyCode, stateCode',
      coverageLevels: 'coverageLevelId, admInsuranceOfferId',
      coverageLevelDifferentials: 'coverageLevelDifferentialId, insuranceOfferId, coverageTypeCode, [insuranceOfferId+coverageTypeCode]',
      cropTypes: 'typeId, commodityCode, typeCode',
      hipEventHistoricals: 'hipEventHistoricalId, [stateCode+countyCode]',
      hipRates: 'hipRateId',
      historicalRevenueCappings: 'historicalRevenueCappingId, insuranceOfferId',
      historicalYieldTrendYears: 'historicalYieldTrendYearId, historicalYieldTrendId',
      insuranceOffers: 'insuranceOfferId, insurancePlanCode, practiceId, typeId, countyId, [insurancePlanCode+countyId+practiceId+typeId], [countyId+typeId]',
      options: 'optionCode',
      optionRates: 'optionRateId, insuranceOfferId, [insuranceOfferId+optionCode]',
      practices: 'practiceId, commodityCode, practiceCode',
      priceGroupMembers: 'priceGroupMemberID, priceGroupID, countyID, typeID, practiceID',
      priceHistories: 'priceHistoryID, priceGroupID, priceYear',
      prices: 'priceId, insuranceOfferId, optionCode',
      states: 'stateCode',
      subCountyRates: 'subCountyRateId, insuranceOfferId, subCountyCode',
      subsidies: 'subsidyId, coverageLevelId, insurancePlanCode, coverageTypeCode, commodityCode, unitStructureCode, [commodityCode+unitStructureCode], [coverageLevelId+insurancePlanCode+coverageTypeCode]',
      supportedAdmYears: 'year',
      unitDiscountLevels: 'unitDiscountLevelId, unitDiscountId',
      yieldAndTYields: 'yieldAndTYieldId, insuranceOfferId',
      yieldExclusions: 'yieldExclusionId',
      insuranceCalendars: 'insuranceCalendarId, insuranceOfferId',
      myaPriceHistories: 'compositeKey, year',
    });
  }
}

export class IceDb extends Dexie {
  guaranteeAdjustments!: Table<GuaranteeAdjustment>;
  priceElectionPercents!: Table<PriceElectionPercent>;

  constructor() {
    super('iceDb');

    this.version(1).stores({
      guaranteeAdjustments: '++, commodityCode, insurancePlanCode, stateCode, countyCode, typeCode, practiceCode, subCountyCode, [commodityCode+insurancePlanCode+stateCode+countyCode+typeCode+practiceCode]',
      priceElectionPercents: '++, commodityYear, commodityCode, insurancePlanCode, coverageTypeCode, stateCode, [commodityCode+insurancePlanCode+coverageTypeCode+stateCode]',
    });
  }
}

export class PrivateProductsDb extends Dexie {
  ecoScoPlusRates!: Table<EcoScoPlusRate>;
  rampRates!: Table<RampRate>;
  revBoostCoverageLevels!: Table<RevBoostCoverageLevel>;
  iceAcresCodes!: Table<IceAcresCode>;
  iceProducts!: Table<IceProduct>;
  iceRates!: Table<IceRateData>;
  iceDeductibleSelections!: Table<IceDeductibleSelection>;
  hailRates!: Table<HailRate>;
  hailProducts!: Table<HailProduct>;
  hailEndorsements!: Table<HailEndorsement>;
  arcYieldHistories!: Table<ArcYieldHistory>;

  constructor() {
    super('privateProductsDb');

    this.version(7).stores({
      ecoScoPlusRates: 'ecoScoPlusRatesId, countyId, commodityCode, practiceId, coverageLevelId, productName, cropYear, [cropYear+countyId+commodityCode+practiceId+coverageLevelId+productName], [cropYear+countyId+commodityCode+productName]',
      rampRates: 'rampRatesId, countyId, commodityCode, insurancePlanCode, percentRangeStart, percentRangeEnd, cropYear, [countyId+commodityCode+insurancePlanCode+percentRangeStart+percentRangeEnd]',
      revBoostCoverageLevels: 'revBoostCoverageLevelsId, mpciUpperCoverageLevel, lowerCoverageLevel, upperCoverageLevel',
      iceAcresCodes: 'acresCodeID',
      iceProducts: 'iceProductId, harvestGuardScenarioPieceTypeId, upperCoverageLevel, lowerCoverageLevel',
      iceRates: 'iceRatesId, countyId, commodityCode, cropYear, iceDeductibleSelectionId, [cropYear+countyId+commodityCode]',
      iceDeductibleSelections: 'iceDeductibleSelectionID',
      hailRates: 'ratesId, cropYear, countyId, productId, commodityCode, [cropYear+countyId+commodityCode]',
      hailProducts: 'hailProductId',
      hailEndorsements: 'hailPlanEndorsementId',
      arcYieldHistories: 'arcYieldHistoryID, cropYear, countyID, commodityCode, [cropYear+countyID+commodityCode]',
    });
  }
}

export const quoterDb = new QuoterDb()
  // Order is important here.
  .use(offlineChangeTrackingMiddleware)
  .use(excludeSoftDeletedMiddleware);

export const admDb = new AdmDb();
export const iceDb = new IceDb();
export const privateProductsDb = new PrivateProductsDb();

/** Returns all quoter db tables, with a little more static type information provided. */
export const getAllQuoterDbTables = (): QuoterDbTable<OfflineChangeTrackedEntity>[] => {
  return quoterDb.tables;
};
