import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import { validateIsNewCT } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import { handleValidationResult } from './validationHelpers';
import { AppTask } from '../../../types/api/AppTask';
import { AppType } from '../../../types/api/enums/application/AppType.enum';
import { ProductType } from '../../../types/api/enums/application/productType.enum';

type IsNewCTInputProps = {
  value: Nullable<boolean>;
  index: number;
  canEditAppTask: boolean;
}

const IsNewCTInput = ({ value, index, canEditAppTask }: IsNewCTInputProps) => {
  const inputName = `appTasks.${index}.isNewCT`;
  const { control } = useFormContext();
  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });

  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={value}
      rules={{ validate: val => handleValidationResult(validateIsNewCT(val, currentAppTask)) }}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          select
          disabled={currentAppTask.appType !== AppType.New || currentAppTask.productType !== ProductType.MPCI || !canEditAppTask}
          error={!!error}
          inputRef={ref}
          size="small"
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <MenuItem value={true as any}>Yes</MenuItem>
          { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <MenuItem value={false as any}>No</MenuItem>
        </TextField>
      )}
    />
  );
};

export default IsNewCTInput;