import { Box, Grid } from '@mui/material';
import { formatCurrency, formatNumberDigits } from '../../../../utils/formatNumbers';


interface HarvestRevenueOutputSummaryProps {
  bushelsProduced: number;
  totalForwardSold: number;
  totalYieldSold: number;
  averagePrice: number;
}

const HarvestRevenueOutputSummary = ({ bushelsProduced, totalForwardSold, totalYieldSold, averagePrice }: HarvestRevenueOutputSummaryProps) => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <div>Bushels Produced</div>
      </Grid>
      <Grid item xs={6} textAlign={'right'}>
        <Box marginRight="28px">{formatNumberDigits(bushelsProduced)}</Box>
      </Grid>
      <Grid item xs={6}>
        <div>Total Forward Sold</div>
      </Grid>
      <Grid item xs={6} textAlign={'right'}>
        <Box marginRight="28px">{formatNumberDigits(totalForwardSold)}</Box>
      </Grid>
      <Grid item xs={6}>
        <div>Total Harvest Sold</div>
      </Grid>
      <Grid item xs={6} textAlign={'right'}>
        <Box marginRight="28px">{formatNumberDigits(totalYieldSold)}</Box>
      </Grid>
      <Grid item xs={6}>
        <div>Average Price</div>
      </Grid>
      <Grid item xs={6} textAlign={'right'}>
        <Box marginRight="28px">{formatCurrency(averagePrice)}</Box>
      </Grid>
    </Grid>
  );
};


export default HarvestRevenueOutputSummary;
