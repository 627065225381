import { Card, CardContent, Grid, IconButton } from '@mui/material';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import HailCardRow from './hailCardRow.component';
import DeleteIcon from '@mui/icons-material/Delete';
import DiscountPercentageInput from '../../../components/formInputs/hail/discountPercentageInput.component';
import { HailPlanType } from '../../../types/api/enums/hail/hailPlanType';
import { useFormContext } from 'react-hook-form';
import HailLossPercentageInput from '../../../components/formInputs/hail/hailLossPercentageInput.component';
import CoveragePerAcreInput from '../../../components/formInputs/hail/hailCoveragePerAcreInput.component';
import CoverageLevelInput from '../../../components/formInputs/hail/coverageLevelInput.component';
import PriceElectionPercentInput from '../../../components/formInputs/hail/priceElectionPercentInput.component';
import { HailCardFields } from './hailCardView';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import { ScenarioPieceResponseDTO } from '@silveus/calculations';
import { RowCropScenario } from '../../../types/api/RowCropScenario';

type HailDetailsCardProps = {
  scenario: RowCropScenario;
  hailScenarioPiece: HailScenarioPiece;
  onDeleteHailScenarioPieceClick: () => void;
  onHailScenarioPieceUpdated: (hailScenarioPiece: HailScenarioPiece) => void;
  onHailScenarioPieceLossChanged: (updatedScenarioPiece: HailScenarioPiece) => void;
  calculatedValues: ScenarioPieceResponseDTO | undefined
  cardWidth: number;
  index: number;
  disableButtonsAndFields: boolean;
}

const HailDetailsCard = ({ scenario, hailScenarioPiece, onDeleteHailScenarioPieceClick, onHailScenarioPieceUpdated, onHailScenarioPieceLossChanged, calculatedValues, cardWidth, index, disableButtonsAndFields }: HailDetailsCardProps) => {

  const methods = useFormContext<HailCardFields>();

  const updateValues = () => {
    const formHailScenarioPiece = methods.getValues().hailScenarioPieces[index];
    const updatedProduct = { ...hailScenarioPiece, ...formHailScenarioPiece };
    onHailScenarioPieceUpdated(updatedProduct);
  };

  const updateValuesWhenLossPercentChanged = () => {
    //Not currently working as expected so not using it for now.
    //Currently have an issue where it updates the loss percent for all pieces but then doesn't "render" the new number in the Input.
    const formHailScenarioPiece = methods.getValues().hailScenarioPieces[index];
    const updatedProduct = { ...hailScenarioPiece, ...formHailScenarioPiece };
    onHailScenarioPieceLossChanged(updatedProduct);
  };
  const priceElectionPriceForElectedPrice = disableButtonsAndFields ? hailScenarioPiece.priceElectionPercent : methods.getValues().hailScenarioPieces[index].priceElectionPercent;
  return (
    <Card sx={{ width: cardWidth }} raised>
      <CardContent sx={{ p: '8px !important', pr: '4px !important', pb: '0 !important' }}>
        <Grid container>
          {hailScenarioPiece.hailPlanType === HailPlanType.Standard ? (
            <HailCardRow
              title="$ Coverage/Acre"
              value={<CoveragePerAcreInput name={`hailScenarioPieces.${index}.coveragePerAcre`} disabled={disableButtonsAndFields} value={hailScenarioPiece.coveragePerAcre} onBlur={updateValues} />}
            />
          ) : (
            <>
              <HailCardRow
                title="Coverage Level"
                value={<CoverageLevelInput name={`hailScenarioPieces.${index}.upperCoverageLevel`} disabled={disableButtonsAndFields} coverageLevel={hailScenarioPiece.upperCoverageLevel} onBlur={updateValues} />}
              />
              <HailCardRow
                title="Price Election %"
                value={<PriceElectionPercentInput name={`hailScenarioPieces.${index}.priceElectionPercent`} disabled={disableButtonsAndFields} value={hailScenarioPiece.priceElectionPercent} onBlur={updateValues} />}
              />
              <HailCardRow
                title="Elected Price"
                value={<CurrencyText currencyAmount={(scenario.projectedPrice ?? 0) * (priceElectionPriceForElectedPrice / 100)} colorize={false} align="right" />}
              />
            </>
          )}
          <HailCardRow
            title="Hail Loss %"
            value={<HailLossPercentageInput name={`hailScenarioPieces.${index}.lossPercent`} disabled={disableButtonsAndFields} value={hailScenarioPiece.lossPercent} onBlur={updateValues} />}
          />
          {/* TODO uncomment this once we are able properly show Deductible
           <HailCardRow
            title="Deductible"
            value={'TODO%'}
          /> */}
          <HailCardRow
            title="Liability"
            value={<CurrencyText currencyAmount={calculatedValues?.liabilityAmount ?? 0} colorize={false} align="right" />}
          />
          {hailScenarioPiece.hailPlanType === HailPlanType.Production && (
            <HailCardRow
              title="Liability/Acre"
              value={<CurrencyText currencyAmount={calculatedValues?.liabilityPerAcre ?? 0} colorize={false} align="right" />}
            />
          )}
          <HailCardRow
            title="Discount %"
            value={<DiscountPercentageInput name={`hailScenarioPieces.${index}.discountPercent`} disabled={disableButtonsAndFields} value={hailScenarioPiece.discountPercent} onBlur={updateValues} />}
          />
          <HailCardRow
            title="Total Premium"
            value={<CurrencyText currencyAmount={(calculatedValues?.totalPremium ?? 0) * -1} colorize={true} align="right" />}
          />
          <HailCardRow
            title="Premium/Acre"
            value={<CurrencyText currencyAmount={(calculatedValues?.totalPremiumPerAcre ?? 0) * -1} colorize={true} align="right" />}
          />
          <HailCardRow
            title="Loss Payment"
            value={<CurrencyText currencyAmount={calculatedValues?.indemnityAmount ?? 0} colorize={true} align="right" />}
          />
          <HailCardRow
            title="Loss Payment/Acre"
            value={<CurrencyText currencyAmount={calculatedValues?.indemnityPerAcre ?? 0} colorize={true} align="right" />}
          />
          {!disableButtonsAndFields &&
          <Grid item xs={12} textAlign="center">
            <IconButton tabIndex={-1} onClick={() => onDeleteHailScenarioPieceClick()}>
              <DeleteIcon />
            </IconButton>
          </Grid>}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HailDetailsCard;