import axios from 'axios';
import { ScenarioUnitYearAph } from '../types/api/ScenarioUnitYearAph';
import { ScenarioId } from '../types/api/PrimaryKeys';
import { envBaseApiUrl } from '../constants/envConstants';

export const getScenarioUnitYearAphForScenarios = (scenarioIds: ScenarioId[]) => axios.post<ScenarioUnitYearAph[]>(`${envBaseApiUrl}/units/scenario-unit-years/scenarios/aph`, scenarioIds);

export const createScenarioUnitYearAphBatch = (scenarioUnitYearAph: ScenarioUnitYearAph[]) => axios.post<void>(`${envBaseApiUrl}/units/scenario-unit-years/aph/batch`, scenarioUnitYearAph);

export const updateScenarioUnitYearAphBatch = (scenarioUnitYearAph: ScenarioUnitYearAph[]) => axios.put<void>(`${envBaseApiUrl}/units/scenario-unit-years/aph/batch`, scenarioUnitYearAph);
