import { AnalysisLineChart, LineData } from '../../scenarioAnalysis/trendlineAnalysis/charts/AnalysisLineChart';
import { pickColor } from '../../scenarioAnalysis/trendlineAnalysis/charts/chartColors';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import { Grid } from '@mui/material';
import HailChartData from '../../../types/api/hail/hailChartData';
import HailChartLineData from '../../../types/api/hail/hailChartLineData';
import { getLossPercentages, getScenarioPieceIndemnityPairs } from '../utils/hailTableUtils';
import { HailProductId, HailScenarioPieceId } from '../../../types/api/PrimaryKeys';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import { useFieldArray, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { getHailChartData } from '../utils/hailChartUtils';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { addHailChartLineData, modifyHailChartLineData, selectAllHailChartLineData, toggleHailLineDataVisibility } from '../../../app/hailModalSlice';
import { StrictOmit } from '../../../types/util/StrictOmit';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import ChartLegend from '../../scenarioAnalysis/trendlineAnalysis/chartLegend';

type HailChartProps = {
  hailScenarioPieces: Map<HailScenarioPieceId, HailScenarioPiece>;
  getHailProductById: (hailProductId: HailProductId) => HailProduct;
}

export type HailChartFields =
  { hailScenarioPieces: HailScenarioPiece[] };
const HailChartView = ({ hailScenarioPieces, getHailProductById }: HailChartProps) => {
  const dispatch = useAppDispatch();
  const cachedLineData = useAppSelector(selectAllHailChartLineData);
  const methods = useForm<HailChartFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      hailScenarioPieces: Array.from(hailScenarioPieces.values()),
    },
  });
  const { setValue } = methods;

  useEffect(() => {
    setValue('hailScenarioPieces', Array.from(hailScenarioPieces.values()));
  }, [hailScenarioPieces]);

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'hailScenarioPieces',
  });

  const onVisibleToggled = (lineData: HailChartLineData) => {
    dispatch(toggleHailLineDataVisibility({ lineData }));
  };

  const lossPercentIncrement = 1;
  const lossPercentMin = 0;
  const lossPercentMax = 100;
  const lossPercentages = getLossPercentages(lossPercentIncrement, lossPercentMin, lossPercentMax);
  const scenarioPieceIndemnityPairs = getScenarioPieceIndemnityPairs(lossPercentages, fields, getHailProductById);
  const chartData = getHailChartData(lossPercentages, scenarioPieceIndemnityPairs, getHailProductById);

  const flattenedChartData = chartData.map(point => {
    const productIndemnnities = point.productIndemnities ?? [];
    const newChartPoint: StrictOmit<HailChartData, 'productIndemnities'> & Record<string, number | undefined> = {
      percent: point.percent,
    };
    productIndemnnities.forEach(pi => {
      newChartPoint[`${pi.name}`] = pi.indemnity;
    });
    return newChartPoint;
  });

  const lineData: LineData<HailChartData>[] = (Object.keys(flattenedChartData.at(0) ?? []) as Array<keyof HailChartData>)
    .filter(key => key !== 'percent')
    .map((key, index) => ({
      dataKey: key,
      color: pickColor(index),
      name: key,
    }));

  const hailChartLineData: HailChartLineData[] = lineData
    .map(ld => ({
      hailChartLineDataId: toPrimaryKey(ld.dataKey),
      dataKey: ld.dataKey,
      color: ld.color,
      name: ld.name,
      isVisible: true,
    }));

  if (cachedLineData.length === 0) {
    dispatch(addHailChartLineData({ hailChartLineData: hailChartLineData }));
  } else if (lineData.some(ld => !cachedLineData.find(x => x.dataKey === ld.dataKey) || cachedLineData.some(x => !lineData.find(ld => ld.dataKey === x.dataKey)))) {
    dispatch(modifyHailChartLineData({ hailChartLineData: hailChartLineData }));
  }

  const filteredHailChartLineData = lineData.filter(ld => cachedLineData.filter(x => x.isVisible).find(x => ld.dataKey === x.dataKey));

  return (
    <Grid container direction="row" height="70%">
      <Grid item container xs={9} sx={{ height: '100%', p: 2 }}>
        <AnalysisLineChart
          data={flattenedChartData}
          lineData={filteredHailChartLineData}
          xAxisKey={'percent'}
          showTooltip
          showLabels={false}
          xAxisTick={'%'}
          customXAxisTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} // This could potentially be made customizable in the future
          showDots={false}
        />
      </Grid>
      <Grid item container xs={3} >
        <ChartLegend lineData={cachedLineData} onVisibleToggled={onVisibleToggled} displayTooltip={true}></ChartLegend>
      </Grid>
    </Grid>

  );
};

export default HailChartView;
