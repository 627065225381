import { AppTask } from '../../../../../types/api/AppTask';
import { AgencyId, AIPId, AppTaskId, SalesClosingDateId } from '../../../../../types/api/PrimaryKeys';
import { AppTaskCoverage } from '../../../../../types/api/appTaskCoverage';
import { ApplicationWizard } from '../../../../../types/api/applicationWizard/applicationWizard';
import { AppType } from '../../../../../types/api/enums/application/AppType.enum';
import { CoverageType } from '../../../../../types/api/enums/application/coverageType.enum';
import { DesignatedCountyElection } from '../../../../../types/api/enums/application/designatedCountyElection';
import { ProductType } from '../../../../../types/api/enums/application/productType.enum';
import { PersonOfInterest } from '../../../../../types/api/insureds/PersonOfInterest';
import { AppTaskValidationResult } from '../../../../../types/app/AppTaskValidationResult';
import { Nullable } from '../../../../../types/util/Nullable';
import { isNotNullOrUndefined, isNull, isNullOrUndefined } from '../../../../../utils/nullHandling';

export function validateAIP(aipId: Nullable<AIPId>) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (isNull(aipId)) {
    validationResult.title = 'AIP Required';
    validationResult.message = 'AIP must be selected';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validateSalesClosingDate(salesClosingDateId: Nullable<SalesClosingDateId>, productType: ProductType) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (productType === ProductType.MPCI && isNull(salesClosingDateId)) {
    validationResult.title = 'Sales Closing Date Required';
    validationResult.message = 'When Product Type is MPCI, "Sales Closing Date" must be selected';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validatePreviousAIP(aipId: Nullable<AIPId>, appTask: AppTask) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (isNull(aipId) && (appTask.isNewCT ?? false)) {
    validationResult.title = 'Previous AIP Required';
    validationResult.message = 'If "Is New C/T" is yes, then previous AIP must be selected';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validateProductType(productType: Nullable<ProductType>) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (isNull(productType)) {
    validationResult.title = 'Product Required';
    validationResult.message = 'Product type must be selected';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validateAgentCode(agentCode: Nullable<string>) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (isNull(agentCode)) {
    validationResult.title = 'Agent Code Required';
    validationResult.message = 'AgentCode must be selected';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validateAgency(agencyId: Nullable<AgencyId>) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (isNull(agencyId)) {
    validationResult.title = 'Agency Required';
    validationResult.message = 'Agency must be selected';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validateAppType(appTask: AppTask) {
  const appType = appTask.appType;
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };

  if (isNull(appType)) {
    validationResult.title = 'App Type Required';
    validationResult.message = 'App Type must be selected';
    validationResult.isValid = false;
  } else if (!isNull(appTask.previousPolicyId) && appType === AppType.New) {
    validationResult.title = 'App Type Selection Error';
    validationResult.message = 'App Type must not be marked as "New" for applications that are for existing policies';
    validationResult.isValid = false;
  }

  return validationResult;
}

export function validateIsNewCT(isNewCT: Nullable<boolean>, appTask: AppTask) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (isNullOrUndefined(isNewCT) && appTask.appType === AppType.New && appTask.productType === ProductType.MPCI) {
    validationResult.title = 'Is New C/T Selection Error';
    validationResult.message = 'When the AppType is new and Product Type is MPCI the "Is New C/T" must be selected';
    validationResult.isValid = false;
  }

  return validationResult;
}

export function validateDesignatedCounty(isDesignatedCounty: Nullable<boolean>, application: ApplicationWizard, appTask: AppTask) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (appTask.appType === AppType.HoldForCancelTransfer || appTask.appType === AppType.Cancel || appTask.productType !== ProductType.MPCI) return validationResult;

  if (application.isGeneratingForms && isNull(isDesignatedCounty)) {
    validationResult.title = 'Designated County Required';
    validationResult.message = 'Designated County must be selected if you are intending to generate forms';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validateNationState(designatedCountyElection: Nullable<DesignatedCountyElection>, isDesignatedCounty: Nullable<boolean>) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if ((isDesignatedCounty ?? false) && isNull(designatedCountyElection)) {
    validationResult.title = 'Designated County - Nation/State Required';
    validationResult.message = 'When the designated county is marked as "yes" then you must select either Nation or State';
    validationResult.isValid = false;
  } else if (!(isDesignatedCounty ?? false) && !isNull(designatedCountyElection)) {
    validationResult.title = 'Designated County Nation/State Selection Error';
    validationResult.message = 'When the designated county is marked as "no" for the application, then Nation/State should not be selected';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validatePreviousPolicy(appTask: AppTask) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if ((appTask.isNewCT ?? false) && (isNullOrUndefined(appTask.previousPolicyNumber) || appTask.previousPolicyNumber.length === 0)) {
    validationResult.title = 'Previous Policy Information Missing';
    validationResult.message = 'If "Is New C/T" is yes then previous policy number is required';
    validationResult.isValid = false;
  } else if ((appTask.isNewCT ?? false) && isNotNullOrUndefined(appTask.previousPolicyNumber) && appTask.previousPolicyNumber.length > 7) {
    validationResult.title = 'Previous Policy Selection Error';
    validationResult.message = 'Previous Policy has a max length of 7 characters';
    validationResult.isValid = false;
  } else if (appTask.coverages.some(x => x.coverageType === CoverageType.CancelTransfer) && appTask.previousPolicyNumber?.length === 0) {
    validationResult.title = 'Previous Policy Information Missing';
    validationResult.message = 'If coverage type is Cancel/Transfer then previous policy number is required';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validateInsuringLandlordTenantShare(insuringShare: Nullable<boolean>, application: ApplicationWizard) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (application.isGeneratingForms && application.insuringLLTs && isNull(insuringShare)) {
    validationResult.title = 'Insuring Landlords/Tenants Required';
    validationResult.message = 'If insuring LLTs was selected then you must select answers for both Insuring Landlord\'s Share and Insuring Tenant\'s share';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validateAttachedPersonsOfInterest(appTask: AppTask, attachedPersonsOfInterest: PersonOfInterest[]) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if ((appTask.isInsuringLandlordShare === true || appTask.isInsuringTenantShare === true) && attachedPersonsOfInterest.length === 0) {
    validationResult.title = 'Insuring Landlords/Tenants Required';
    validationResult.message = 'If "Insuring LL\'s Share" or "Insuring Tenants\'s Share" is yes, then you must include at least one LL\\T';
    validationResult.isValid = false;
  }

  return validationResult;
}

export function validateAppTasks(appTasks: AppTask[], application: ApplicationWizard, appTaskLandlordAndTenants: Partial<Record<AppTaskId, PersonOfInterest[]>>) {
  const appTaskErrorResults = appTasks.map(x => validateAppTask(x, application, appTaskLandlordAndTenants[x.appTaskId] ?? [], appTasks));
  const errors: Record<string, string> = {};

  // Ensure we only return a unique set of error messages
  appTaskErrorResults.flat().filter(x => !x.isValid).forEach(error => {
    errors[error.title] = error.message;
  });
  return errors;
}

function validateAppTask(appTask: AppTask, application: ApplicationWizard, attachedPersonsOfInterest: PersonOfInterest[], allAppTasks: AppTask[]) {
  const appTaskValidationResults = [
    validateAppType(appTask),
    validateSalesClosingDate(appTask.salesClosingDateId, appTask.productType),
    validateAIP(appTask.aipId),
    validatePreviousAIP(appTask.previousAIPId, appTask),
    validateProductType(appTask.productType),
    validateAgentCode(appTask.agentCodeId),
    validateAgency(appTask.agencyId),
    validateDesignatedCounty(appTask.isDesignatedCounty, application, appTask),
    validateNationState(appTask.designatedCountyElection, appTask.isDesignatedCounty),
    validatePreviousPolicy(appTask),
    validateIsNewCT(appTask.isNewCT, appTask),
    validateInsuringLandlordTenantShare(appTask.isInsuringLandlordShare, application),
    validateInsuringLandlordTenantShare(appTask.isInsuringTenantShare, application),
    validateAttachedPersonsOfInterest(appTask, attachedPersonsOfInterest),
  ];

  const appTaskCoverageValidationResults = validateCoverages(appTask, application, allAppTasks);
  return [...appTaskValidationResults, ...appTaskCoverageValidationResults];
}

function validateCoverages(appTask: AppTask, application: ApplicationWizard, allAppTasks: AppTask[]) {
  const results = appTask.coverages.map(cov => validateCoverage(cov, appTask, application, allAppTasks)).flat();
  results.push(validateCoveragesRequired(appTask));
  return results;
}

function validateCoveragesRequired(appTask: AppTask) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (appTask.coverages.length === 0) {
    validationResult.title = 'Coverages Required';
    validationResult.message = 'At least one coverage must exist for each application';
    validationResult.isValid = false;
  }

  return validationResult;
}

function validateCoverage(coverage: AppTaskCoverage, appTask: AppTask, application: ApplicationWizard, allAppTasks: AppTask[]) {
  const results = [
    validateCoverageType(coverage.coverageType, appTask),
    ...validateCoverageDesignatedCounty(application, appTask, coverage, allAppTasks),
    validateIsNewProducer(coverage.isNewProducer, application, appTask),
    validateEstimatedPremium(coverage),
  ];

  return results;
}

export function validateCoverageDesignatedCounty(application: ApplicationWizard, appTask: AppTask, coverage: AppTaskCoverage, allAppTasks: AppTask[]) {
  const isDesignatedCounty = coverage.isDesignatedCounty ?? false;
  // When validating coverage designated county selection here we can return multiple errors.
  const results: AppTaskValidationResult[] = [];

  if (appTask.productType !== ProductType.MPCI) {
    return results;
  }

  if (application.isGeneratingForms && isNull(isDesignatedCounty) && (appTask.isDesignatedCounty ?? false)) {
    results.push({
      title: 'Designated County Required',
      message: 'Designated County must be selected if you are intending to generate forms',
      isValid: false,
    });
  }
  const designatedCountyCoverages = appTask.coverages.map(x => x).filter(x => (x.isDesignatedCounty ?? false));
  const otherAppTasks = allAppTasks.filter(x => x.productType === ProductType.MPCI);
  if ((appTask.isDesignatedCounty ?? false) && designatedCountyCoverages.length === 0) {
    results.push({
      title: 'Coverage - Is Designated County Yes',
      message: 'When the designated county is marked as "yes" for the application, then at least one coverage must have the designated county marked as "yes"',
      isValid: false,
    });
  } else if (!(appTask.isDesignatedCounty ?? false) && designatedCountyCoverages.length !== 0) {
    results.push({
      title: 'Coverage - Is Designated County No',
      message: 'If app task designated county is no, then no coverages for the app task can have designated county selected',
      isValid: false,
    });
  }

  // Need to now check all app task coverages keeping in mind nation/state rules
  const nationState = appTask.designatedCountyElection;
  const coverageCrop = coverage.cropCode;
  const otherCoveragesForCrop = otherAppTasks
    .filter(x => x.productType === ProductType.MPCI)
    .flatMap(x => x.coverages)
    .filter(x => (x.isDesignatedCounty ?? false) && x.coverageId !== coverage.coverageId && x.cropCode === coverageCrop);
  const otherCoveragesForSameCountyAndCrop = otherCoveragesForCrop.filter(x => x.countyCode === coverage.countyCode);
  const areOtherCoveragesForSameCountyCropNation = otherCoveragesForCrop.filter(x => isNotNullOrUndefined(otherAppTasks.find(a => a.appTaskId === x.appTaskId))).length > 0;
  if (isDesignatedCounty && nationState === DesignatedCountyElection.Nation && otherCoveragesForCrop.length > 0) {
    results.push({
      title: 'Coverage - Is Designated County',
      message: 'If app task is designated county and Nation/State is set to \'Nation\', then no other coverages for the same crop can be marked as designated county',
      isValid: false,
    });
  } else if (areOtherCoveragesForSameCountyCropNation && isDesignatedCounty && nationState === DesignatedCountyElection.Nation ) {
    results.push({
      title: 'Coverage - Is Designated County',
      message: 'If app task is designated county and Nation/State is set to \'Nation\', then no other coverages for the same crop can be marked as designated county',
      isValid: false,
    });
  } else if (isDesignatedCounty && nationState === DesignatedCountyElection.State && otherCoveragesForSameCountyAndCrop.length > 0) {
    results.push({
      title: 'Coverage - Is Designated County',
      message: 'If app task is designated county and Nation/State is set to \'State\', then no other coverages for the same crop/state combination can be marked as designated county',
      isValid: false,
    });
  }
  return results;
}

export function validateIsNewProducer(isNewProducer: Nullable<boolean>, application: ApplicationWizard, appTask: AppTask) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (appTask.appType === AppType.HoldForCancelTransfer || appTask.appType === AppType.Cancel || appTask.productType !== ProductType.MPCI) return validationResult;

  if (application.isGeneratingForms && isNull(isNewProducer)) {
    validationResult.title = 'New Producer Selection Error';
    validationResult.message = 'If generating forms is selected new producer must be selected';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validateEstimatedPremium(appTaskCoverage: AppTaskCoverage) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  if (((appTaskCoverage.intendedNetAcres ?? 0) > 0) && (Number(appTaskCoverage.estimatedGrossPremium ?? 0) === 0)) {
    validationResult.title = 'Coverage Estimated Premium';
    validationResult.message = 'If intended acres is greater than 0, estimated premium must be greater than 0.';
    validationResult.isValid = false;
  }
  return validationResult;
}

export function validateCoverageType(coverageType: Nullable<CoverageType>, appTask: AppTask) {
  const validationResult: AppTaskValidationResult = { message: '', title: '', isValid: true };
  const allCoverages = [CoverageType.CancelTransfer, CoverageType.Renew, CoverageType.HoldForCancelTransfer, CoverageType.Cancel, CoverageType.New];
  const coverageIsNew = coverageType === CoverageType.New;
  const coverageIsAllInclusive = isNotNullOrUndefined(coverageType) && allCoverages.includes(coverageType);
  const coverageIsCanceled = coverageType === CoverageType.Cancel;
  const coverageIsCancelTransfer = coverageType === CoverageType.CancelTransfer;
  const coverageIsHoldForCancelTransfer = coverageType === CoverageType.HoldForCancelTransfer;

  if (appTask.appType === AppType.New && !coverageIsNew) {
    validationResult.title = 'Coverage Type Selection Error';
    validationResult.message = 'When the App Type is "New" then all coverage types should be set to "N - New"';
    validationResult.isValid = false;
  } else if (!(appTask.isMultiCounty ?? false) && appTask.appType === AppType.Change && !coverageIsAllInclusive) {
    validationResult.title = 'Coverage Type Selection Error';
    validationResult.message = 'When the App Type is "Change" then all coverage types should be set either "R - Renewal", "CT – Cancel/transfer", "H - Hold for cancel/transfer", "C - Cancel, or N - New"';
    validationResult.isValid = false;
  } else if (appTask.appType === AppType.CancelTransfer && !coverageIsCancelTransfer) {
    validationResult.title = 'Coverage Type Selection Error';
    validationResult.message = 'When the App Type is "Cancel/Transfer" then all coverage types should be set to "CT - Cancel/Transfer"';
    validationResult.isValid = false;
  } else if (appTask.appType === AppType.HoldForCancelTransfer && !coverageIsHoldForCancelTransfer) {
    validationResult.title = 'Coverage Type Selection Error';
    validationResult.message = 'When the App Type is "Hold for Cancel/Transfer" then all coverage types should be set to "H - Hold for Cancel/Transfer"';
    validationResult.isValid = false;
  } else if (appTask.appType === AppType.Cancel && !coverageIsCanceled) {
    validationResult.title = 'Coverage Type Selection Error';
    validationResult.message = 'When the App Type is "Cancel" then all coverage types should be set to "C - Cancel"';
    validationResult.isValid = false;
  }
  return validationResult;
}
