import { Card, Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import {
  CheckCircleOutline,
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityOnIcon,
  LinkOff as LinkOffIcon,
  Link as LinkOnIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { currentlySelectedScenarioId, removeScenario, setCurrentlySelectedScenarioId, toggleIsScenarioFinalized, toggleIsScenarioVisible } from '../../../app/scenariosSlice';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { ConfirmStateContent, openConfirm } from '../../../app/confirmSlice';
import { selectCurrentQuoteTabIndex } from '../../../app/quotesSlice';
import { selectScenarioIdsAssociatedWithAppTasks } from '../../../app/applicationsSlice';
import CommandBar, { CommandBarItem } from '../../../components/command-bar/commandBar.component';
import { addLinkedScenario, removeLinkedScenario, selectUserLinkedScenarios } from '../../../app/userSettingsSlice';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { selectPracticeById, selectTypeById } from '../../../app/admSlice';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import ScenarioAnalysisMenu from '../../scenario/components/scenarioCard.component.scenarioAnalysisMenu';
import { closeDrawer } from '../../../app/appDrawerSlice';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';

interface Props {
  scenario: RowCropScenario;
}

export const ClientFileQuoteScenarioListScenario = ({ scenario: s }: Props) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const doesScenarioHaveAppTasks = useAppSelector(state => selectScenarioIdsAssociatedWithAppTasks(state).has(s.scenarioId));

  const currentScenarioId = useAppSelector(currentlySelectedScenarioId);
  const linkedScenarios = useAppSelector(selectUserLinkedScenarios);
  const isNotOnScenariosTab = useAppSelector(s => selectCurrentQuoteTabIndex(s) !== 0);
  const type = useAppSelector(state => isNullOrUndefined(s) ? undefined : selectTypeById(state, s.typeId));
  const practice = useAppSelector(state => isNullOrUndefined(s) || isNullOrUndefined(s.practiceId) ? undefined : selectPracticeById(state, s.practiceId));

  const isScenarioVisible = s.isVisible;

  const onVisibleToggled = async (scenario: RowCropScenario) => {
    // If we are hiding the currently selected scenario we should clear the currently selected scenario
    // and close the drawer.
    const wasVisible = scenario.isVisible;
    if (scenario.isVisible && currentScenarioId === scenario.scenarioId) {
      dispatch(setCurrentlySelectedScenarioId(null));
      dispatch(closeDrawer());
    }

    await dispatch(toggleIsScenarioVisible({ scenario: scenario }));
    if (!wasVisible) {
      await dispatch(validateAndUpdateScenario({ scenarioId: scenario.scenarioId }));
    }
  };

  const onDeleteScenario = async () => {
    // Delete, then re-fetch scenarios for the current quote.
    await dispatch(removeScenario({ scenario: s }));
  };

  const onDeleteScenarioClick = () => {
    const confirmWindow: ConfirmStateContent = {
      title: 'Delete Scenario?',
      message: 'Are you sure you want to delete this scenario?',
      confirmText: 'Delete',
      onConfirm: onDeleteScenario,
    };
    dispatch(openConfirm(confirmWindow));
  };

  const scenarioLinkageData = linkedScenarios.quotes[s.quoteId];

  const isScenarioLinked = (scenarioId: ScenarioId): boolean => {
    return scenarioLinkageData?.linkedScenarioIds.includes(scenarioId) ?? false;
  };

  const onScenarioLinkClicked = () => {
    if (!isScenarioLinked(s.scenarioId)) {
      dispatch(addLinkedScenario({ quoteId: s.quoteId, scenarioId: s.scenarioId }));
    } else {
      dispatch(removeLinkedScenario({ quoteId: s.quoteId, scenarioId: s.scenarioId }));
    }
  };

  const toggleIsFinalized = (scenario: RowCropScenario) => {
    dispatch(toggleIsScenarioFinalized({ scenario }));
  };

  const getButtonSx = (isButtonActive: boolean) => {
    return { fontSize: '16px', color: isButtonActive ? 'secondary' : theme.palette.altText.disabled };
  };

  const commandBarItemsTop = () => {
    const items: CommandBarItem[] = [
      {
        id: `mark-as-final-${s.scenarioId}`,
        label: 'Mark as final',
        onclick: () => toggleIsFinalized(s),
        iconButton: <CheckCircleOutline />,
        renderMenuItem: () => (
          <Tooltip title="Mark as final" placement="top" disableInteractive>
            <div id={`mark-as-final-${s.scenarioId}`} style={{ paddingLeft: '5px' }} >
              <IconButton
                sx={{ pr: 0, pl: 0, mr: 0, ml: 0 }}
                onClick={() => toggleIsFinalized(s)}
              >
                <CheckCircleOutline sx={{ fontSize: '16px', color: s.isFinalized ? theme.palette.other.positive : theme.palette.altText.disabled }} />
              </IconButton>
            </div>
          </Tooltip>
        ),
      },
      {
        id: `link-${s.scenarioId}`,
        label: 'Scenario link',
        onclick: () => onScenarioLinkClicked(),
        iconButton: isScenarioLinked(s.scenarioId) ? <LinkOnIcon sx={getButtonSx(true)} /> : <LinkOffIcon sx={getButtonSx(false)} />,
        disabled: !s.isVisible,
      },
      {
        id: `active-inactive-scenario-piece-menu-${s.scenarioId}`,
        label: 'Visibility (show/hide)',
        onclick: () => onVisibleToggled(s),
        iconButton: s.isVisible ? <VisibilityOnIcon sx={getButtonSx(s.isVisible)} /> : <VisibilityOffIcon sx={getButtonSx(s.isVisible)} />,
      },
      {
        id: `delete-${s.scenarioId}`,
        label: 'Delete',
        iconButton: <DeleteIcon sx={getButtonSx(false)} />,
        onclick: () => onDeleteScenarioClick(),
        disabled: isNotOnScenariosTab || doesScenarioHaveAppTasks,
      },
    ];

    return items;
  };

  const commandBarTop = (
    <CommandBar items={commandBarItemsTop()} />
  );

  const commandBarBottom = (
    <ScenarioAnalysisMenu scenarioId={s.scenarioId} />
  );

  return (
    <Grid item xs={12}>
      <Card>
        <Grid container spacing={0.5} sx={{ backgroundColor: theme.palette.background.paper }}>
          <Grid item xs="auto">
            <div style={{ width: '9px', height: '100%', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', backgroundColor: isScenarioVisible ? s.scenarioColor : theme.palette.action.disabled }} />
          </Grid>
          <Grid item container xs sx={{ marginLeft: '5px', marginRight: '5px' }}>
            <Grid item xs={12}>
              <Grid container xs={12} alignItems="center" sx={{ mt: '0 !important' }}>
                <Grid item xs>
                  <Typography variant="body2" >
                    {`${type?.name ?? ''}, ${practice?.name ?? ''}`}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  {commandBarTop}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container xs={12} alignItems="center">
                <Grid item xs>
                  <Typography variant="body2">
                    {s.name}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  {commandBarBottom}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};