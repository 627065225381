import { Card, Grid, Tab, Tabs } from '@mui/material';
import { TrendlineAnalysisTabIndex, TrendlineAnalysisTabs } from '../../../constants/trendlineAnalysisTabs';
import { SyntheticEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { selectLineDataByTabIndex, selectTrendlineTabIndex, setSelectedTrendlineTabIndex, toggleLineDataVisibility } from '../../../app/trendlineAnalysisSlice';
import { TabPanel } from '../../../utils/tabPanel';
import YieldChart from './yieldChart.component';
import RevenueChart from './revenueChart';
import PriceChart from './priceChart';
import PercentChangeChart from './percentChangeChart';
import TrendlineChartControls from './trendlineChartControls';
import ChartLegend from './chartLegend';
import TrendlineAnalysis from '../../../types/api/TrendlineAnalysis';
import TrendlineAnalysisLineData from '../../../types/api/TrendlineAnalysisLineData';

type TrendlineAnalysisCardProps = {
  trendlineAnalysis: TrendlineAnalysis;
}

const TrendlineAnalysisCard = ({ trendlineAnalysis }: TrendlineAnalysisCardProps) => {
  const dispatch = useAppDispatch();
  const tabIndex = useAppSelector(selectTrendlineTabIndex);
  const lineData = useAppSelector(state => selectLineDataByTabIndex(state, trendlineAnalysis.trendlineAnalysisId, tabIndex));
  const defaultKeys = new Set(['trendYield', 'actualYield', 'expectedCountyYield', 'actualCountyYield', 'expectedRevenue', 'actualRevenue']);
  const maxWidth = '275px';

  const handleTabChange = (event: SyntheticEvent, newValue: TrendlineAnalysisTabIndex) => {
    dispatch(setSelectedTrendlineTabIndex(newValue));
  };

  const onVisibleToggled = (lineData: TrendlineAnalysisLineData) => {
    dispatch(toggleLineDataVisibility({ lineData }));
  };

  return (
    <Card sx={{ height: '100%', overflow: 'hidden', minWidth: '925px', minHeight: '400px' }}>
      <Grid container sx={{ flexWrap: 'nowrap', height: '100%', overflowX: 'auto' }}>
        <Grid item xs sx={{ height: '100%', overflow: 'hidden' }}>
          <Grid container direction="column" sx={{ height: '100%', flexWrap: 'nowrap' }}>
            <Grid item xs="auto">
              <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label={TrendlineAnalysisTabs.yields.label} />
                <Tab label={TrendlineAnalysisTabs.revenue.label} />
                <Tab label={TrendlineAnalysisTabs.prices.label} />
                <Tab label={TrendlineAnalysisTabs.percentChange.label} />
              </Tabs>
            </Grid>
            <Grid item xs>
              <TabPanel value={tabIndex} index={TrendlineAnalysisTabs.yields.index}>
                <YieldChart
                  trendlineAnalysisId={trendlineAnalysis.trendlineAnalysisId}
                  scenarioId={trendlineAnalysis.primaryScenarioId}
                  acreageType={trendlineAnalysis.acreageType}
                  startYear={trendlineAnalysis.startYear}
                  endYear={trendlineAnalysis.endYear}
                  showLabels={trendlineAnalysis.showLabels}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={TrendlineAnalysisTabs.revenue.index}>
                <RevenueChart
                  trendlineAnalysisId={trendlineAnalysis.trendlineAnalysisId}
                  scenarioId={trendlineAnalysis.primaryScenarioId}
                  startYear={trendlineAnalysis.startYear}
                  endYear={trendlineAnalysis.endYear}
                  showLabels={trendlineAnalysis.showLabels}
                  acreageType={trendlineAnalysis.acreageType}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={TrendlineAnalysisTabs.prices.index}>
                <PriceChart
                  trendlineAnalysisId={trendlineAnalysis.trendlineAnalysisId}
                  startYear={trendlineAnalysis.startYear}
                  endYear={trendlineAnalysis.endYear}
                  showLabels={trendlineAnalysis.showLabels}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={TrendlineAnalysisTabs.percentChange.index}>
                <PercentChangeChart
                  trendlineAnalysisId={trendlineAnalysis.trendlineAnalysisId}
                  scenarioId={trendlineAnalysis.primaryScenarioId}
                  startYear={trendlineAnalysis.startYear}
                  endYear={trendlineAnalysis.endYear}
                  showLabels={trendlineAnalysis.showLabels}
                  acreageType={trendlineAnalysis.acreageType}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto" p={2}>
          <Grid container direction="column">
            <Grid item xs="auto">
              <TrendlineChartControls trendlineAnalysis={trendlineAnalysis} maxWidth={maxWidth} />
            </Grid>
            <Grid item xs>
              <ChartLegend lineData={lineData} onVisibleToggled={onVisibleToggled} defaultKeys={defaultKeys} maxWidth={maxWidth} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TrendlineAnalysisCard;
