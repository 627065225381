import { scenarioPieceOrderingServiceInstance } from '../scenarioOrderingServiceWrappers';
import { ScenarioPieceGroupTypeAttributes, ScenarioPieceType } from '@silveus/calculations';
import { Nullable } from '../../types/util/Nullable';
import { isEqual } from 'lodash';
import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import { getScenarioPieceDefinition } from '../../constants/productDefinitions/scenarioPieceDefinitionRecords';
import { Scenario } from '../../types/api/Scenario';
import { Quote } from '../../types/api/Quote';

/*
 * While this is fairly heavy business logic we decided to keep it out of the calculations due to the number of web-specific objects that would need to be created
 * or the amount of crazy hacky data transformations that would need to happen to house that in the calculations.
 *
 * Also, due to some of the details of how the web project consumes this and the fact that how this is implemented, and some of the validations/changes
 * that need to occur depend upon the constraints of the web project it made more sense to just isolate it as best as we could within the web project.
 */
export const validateRowCropScenario = (scenarioPieces: RowCropScenarioPiece[], scenario: Scenario, quote: Quote, updatedScenarioPiece?: RowCropScenarioPiece): ScenarioPieceValidationResult[] => {

  const modifiedScenarioPieces = updatedScenarioPiece !== undefined ? updateScenarioPieces(scenarioPieces, updatedScenarioPiece) : [...scenarioPieces];
  const scenarioPieceTypes = modifiedScenarioPieces.map(sp => sp.scenarioPieceType);
  const basePieces = scenarioPieceOrderingServiceInstance.getOptionalBaseScenarioPieces(scenarioPieceTypes);

  //Get the scenario pieces that are classified as base pieces, but aren't currently optional.
  // This prevents us from accidentally validating MP before RP if RP is elected, but will still validate MP if RP isn't elected
  const startingPieces = modifiedScenarioPieces.filter(scenarioPiece => basePieces.includes(scenarioPiece.scenarioPieceType));
  const updatedScenarioPieces = validatePieceAndDependents(startingPieces, modifiedScenarioPieces, null, null, scenario, quote);

  const originallyUpdatedPiece = updatedScenarioPieces.find(sp => sp.scenarioPiece.scenarioPieceId === updatedScenarioPiece?.scenarioPieceId);
  if (originallyUpdatedPiece !== undefined) {
    originallyUpdatedPiece.shouldUpdate = true;
  }

  return updatedScenarioPieces;
};

function validatePieceAndDependents(piecesToValidate: RowCropScenarioPiece[], allScenarioPieces: RowCropScenarioPiece[], underlyingScenarioPiece: Nullable<RowCropScenarioPiece>, doubleUnderlyingScenarioPiece: Nullable<RowCropScenarioPiece>, scenario: Scenario, quote: Quote): ScenarioPieceValidationResult[] {
  const combinedResults: ScenarioPieceValidationResult[] = [];

  for (const scenarioPiece of piecesToValidate) {
    const updatedPiece = validateScenarioPiece(scenarioPiece, underlyingScenarioPiece, allScenarioPieces, doubleUnderlyingScenarioPiece, scenario, quote);
    const updatedScenarioPieces = updateScenarioPieces(allScenarioPieces, updatedPiece.scenarioPiece);
    const dependentScenarioPieceTypes = scenarioPieceOrderingServiceInstance.getDependantScenarioPieces(updatedPiece.scenarioPiece.scenarioPieceType);
    //Get dependent scenario piece types, get their group type, get all scenario piece types for those groups, filter down the pieces to validate next with any of those types
    const allApplicableScenarioPieceTypes = new Set<ScenarioPieceType>();
    for (const dependentScenarioPieceType of dependentScenarioPieceTypes) {
      const scenarioPieceGroup = Object.values(ScenarioPieceGroupTypeAttributes).find(spg => spg.elements?.includes(dependentScenarioPieceType));
      if (scenarioPieceGroup !== undefined && scenarioPieceGroup.elements !== undefined) {
        for (const scenarioPieceGroupMemberType of scenarioPieceGroup.elements) {
          allApplicableScenarioPieceTypes.add(scenarioPieceGroupMemberType);
        }
      } else {
        allApplicableScenarioPieceTypes.add(dependentScenarioPieceType);
      }
    }
    const piecesToValidateNext = updatedScenarioPieces.filter(sp => allApplicableScenarioPieceTypes.has(sp.scenarioPieceType));
    const results = validatePieceAndDependents(piecesToValidateNext, updatedScenarioPieces, updatedPiece.scenarioPiece, underlyingScenarioPiece, scenario, quote);

    combinedResults.push(updatedPiece, ...results);
  }

  return combinedResults;
}

function validateScenarioPiece(scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: Nullable<RowCropScenarioPiece>, allScenarioPieces: RowCropScenarioPiece[], doubleUnderlyingScenarioPiece: Nullable<RowCropScenarioPiece>, scenario: Scenario, quote: Quote): ScenarioPieceValidationResult {
  //Gets the proper validation function out of the collection of functions
  //Calls the function and uses the result to provide an updated object back

  const validationFunction = getValidationFunction(scenarioPiece);
  const validationResults = validationFunction(scenarioPiece, underlyingScenarioPiece, allScenarioPieces, doubleUnderlyingScenarioPiece, scenario, quote);

  if (validationResults.propertiesToUpdate !== null && validationResults.propertiesToUpdate.isInvalid === undefined) {
    validationResults.propertiesToUpdate.isInvalid = false;
  }

  const updatedScenarioPiece: RowCropScenarioPiece = {
    ...scenarioPiece,
    ...validationResults.propertiesToUpdate,
  };

  //If the original scenario piece and the updated scenario piece are not equal, updates were made.
  // This is done instead of checking the length of properties to update because one of the properties to update could be to set isInvalid to true.
  // However, if the scenario piece is already marked as invalid, then nothing actually changed.
  const wereUpdatesMade = !isEqual(scenarioPiece, updatedScenarioPiece);

  return {
    scenarioPiece: updatedScenarioPiece,
    shouldUpdate: wereUpdatesMade,
    validationErrors: validationResults.validationErrors,
  };
}

export type ScenarioPieceValidationFunction = (scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: Nullable<RowCropScenarioPiece>, allScenarioPieces: RowCropScenarioPiece[], doubleUnderlyingScenarioPiece: Nullable<RowCropScenarioPiece>, scenario: Scenario, quote: Quote) => CombinedValidationResult<RowCropScenarioPiece>;

function getValidationFunction(scenarioPiece: RowCropScenarioPiece): ScenarioPieceValidationFunction {
  const scenarioPieceDefinition = getScenarioPieceDefinition(scenarioPiece.scenarioPieceType);
  return scenarioPieceDefinition.validationFunction;
}

function updateScenarioPieces(scenarioPiecesToUpdate: RowCropScenarioPiece[], updatedScenarioPiece: RowCropScenarioPiece): RowCropScenarioPiece[] {
  let modifiedScenarioPieces = [...scenarioPiecesToUpdate];
  const index = modifiedScenarioPieces.findIndex(sp => sp.scenarioPieceId === updatedScenarioPiece.scenarioPieceId);
  modifiedScenarioPieces.splice(index, 1, updatedScenarioPiece);
  return modifiedScenarioPieces;
}

export interface ScenarioPieceValidationResult {
  scenarioPiece: RowCropScenarioPiece;
  shouldUpdate: boolean;
  validationErrors: string[];
}

export interface CombinedValidationResult<T> {
  propertiesToUpdate: Nullable<Partial<T>>;
  validationErrors: string[];
}

export interface ValidationResult<T> {
  propertiesToUpdate: Nullable<Partial<T>>;
  validationError: Nullable<string>;
}
