import ScenarioQuickUnit from '../../../types/api/ScenarioQuickUnit';
import { InsuredId, UnitYearId } from '../../../types/api/PrimaryKeys';
import UnitYear from '../../../types/api/UnitYear';

export const createFakeUnitYearForQuickUnit = (
  quickUnit: ScenarioQuickUnit,
  insuredId: InsuredId,
  year: number,
): UnitYear => {
  const mockUnitYear: UnitYear = {
    acres: quickUnit.acres,
    basicUnitNumber: '0001',
    commodityCode: quickUnit.commodityCode,
    countyId: quickUnit.countyId,
    farmName: null,
    farmNumber: null,
    history: [],
    insuredId: insuredId,
    location: null,
    offlineCreatedOn: undefined,
    offlineDeletedOn: undefined,
    offlineLastUpdatedOn: undefined,
    optionalUnitNumber: '0001',
    practiceId: quickUnit.practiceId,
    priorYearApprYield: null,
    range: null,
    section: null,
    sharePercent: quickUnit.sharePercent,
    subCountyCode: null,
    township: null,
    typeId: quickUnit.typeId,
    unitId: quickUnit.scenarioQuickUnitId,
    unitYearId: quickUnit.scenarioQuickUnitId as unknown as UnitYearId,
    year: year,
  };

  return mockUnitYear;
};