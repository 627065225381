import MESSAGES from '../../../constants/messages';
import { Nullable } from '../../../types/util/Nullable';

export function validatePrice(priceValue: Nullable<number>, allowZero: boolean = false): true | string {
  if (priceValue === null) return MESSAGES.required;
  if (priceValue < 0) return MESSAGES.minimum(0);
  if (priceValue > 99999.9999) return MESSAGES.maximum(99999.9999);
  if (priceValue.toString().length > 10) return MESSAGES.maxLength(10);
  if (priceValue === 0 && !allowZero) return MESSAGES.greaterThan(0);

  return true;
}
