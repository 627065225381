import { AgGridReact } from 'ag-grid-react';
import { ApplicationWizard } from '../../../../types/api/applicationWizard/applicationWizard';
import { useGetScenariosFromQuoteIds } from '../../../../hooks/scenarioHooks';
import { useEffect, useState } from 'react';
import { useAppSelector, useKeyMapSelector } from '../../../../hooks/reduxHooks';
import { QuoteId, ScenarioId } from '../../../../types/api/PrimaryKeys';
import { selectAllQuotesByClientFileMap } from '../../../../app/quotesSlice';
import { selectAdmData } from '../../../../app/admSlice';
import { CoverageDecisionRowData, DefaultColDefinition, getColumnDefinitions } from './coverageDecisionColumnDefinitions';
import { selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder } from '../../../../app/scenarioPiecesSlice';
import { getItemsForId } from '../../../../utils/mapHelpers';
import { ClientFile } from '../../../../types/api/ClientFile';
import { isNotNullOrUndefined } from '../../../../utils/nullHandling';
import { Typography } from '@mui/material';
import { selectScenarioOptionDictionary } from '../../../../app/optionsSlice';
import { getKeyedStateGroupedBy } from '../../../../app/sliceHelpers';
import { generatePrimaryKey } from '../../../../utils/primaryKeyHelpers';

import './coverageDecisionStep.styles.css';

type CoverageDecisionScenarioGridProps = {
  application: ApplicationWizard;
  clientFile: ClientFile;
  includedQuoteIds: QuoteId[];
  updateApplication: (application: ApplicationWizard) => void;
};

const CoverageDecisionScenarioGrid = ({ application, clientFile, includedQuoteIds, updateApplication }: CoverageDecisionScenarioGridProps) => {
  const admData = useAppSelector(selectAdmData);
  const quotes = useKeyMapSelector(selectAllQuotesByClientFileMap, clientFile.clientFileId);

  const scenarios = useGetScenariosFromQuoteIds(includedQuoteIds);
  const [rowData, setRowData] = useState<CoverageDecisionRowData[]>([]);
  const allRowCropScenarioPiecesByScenario = useAppSelector(selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder);
  const allScenarioOptions = useAppSelector(selectScenarioOptionDictionary);

  useEffect(() => {
    const tempRowData: CoverageDecisionRowData[] = [];
    const scenarioOptionsByScenarioId = getKeyedStateGroupedBy(allScenarioOptions, s => s.scenarioId);
    for (const quoteId of includedQuoteIds) {
      // First thing we need to add the header for each section, in our case its a dummy row
      // with the quote name since thats the only thing we need to display in that row.
      const quote = quotes.find(x => x.quoteId === quoteId);
      // Then we need to add all the scenarios for that quote
      const scenariosForQuote = scenarios.get(quoteId) ?? [];
      for (const scenario of scenariosForQuote) {
        const scenarioPiecesForScenario = getItemsForId(allRowCropScenarioPiecesByScenario, scenario.scenarioId);
        const isChecked = isNotNullOrUndefined(application.applicationWizardScenarios.find(x => x.scenarioId === scenario.scenarioId));
        if (scenarioPiecesForScenario.length > 0) {
          tempRowData.push({
            stateId: scenario.name,
            section: 'big-title',
            scenarioId: scenarioPiecesForScenario.length > 0 ? scenario.scenarioId : undefined,
            quoteId: quoteId,
            isSelected: isChecked,
          });
        }

        const options = scenarioOptionsByScenarioId.get(scenario.scenarioId) ?? [];
        const scenarioPieceEntries = scenarioPiecesForScenario.entries();
        for (const [index, sp] of scenarioPieceEntries) {
          tempRowData.push({
            stateId: index === 0 ? quote?.countyId : undefined,
            countyId: index === 0 ? quote?.countyId : undefined,
            commodityCode: index === 0 ? quote?.commodityCode : undefined,
            scenarioPieceType: sp.scenarioPieceType,
            practiceId: index === 0 && (scenario.practiceId !== null && scenario.practiceId !== '') ? scenario.practiceId : undefined,
            typeId: index === 0 ? scenario.typeId : undefined,
            upperCoverage: sp.upperCoverageLevel,
            lowerCoverage: sp.lowerCoverageLevel,
            protectionFactor: sp.protectionFactor,
            isSelected: false,
            options: index === 0 ? options : [],
            highRiskTypeId: scenario.highRiskTypeId,
            unitStructure: sp.unitStructure,
          });
        }
      }
    }

    setRowData(tempRowData);

  }, [application.applicationWizardScenarios, allScenarioOptions]);

  const onScenarioToggled = (quoteId: QuoteId, scenarioId: ScenarioId) => {
    let tempIncludedItems = [...application.applicationWizardScenarios];
    const index = tempIncludedItems.findIndex(x => x.scenarioId === scenarioId);
    if (index >= 0) {
      tempIncludedItems.splice(index, 1);
    } else {
      tempIncludedItems.push({
        scenarioId: scenarioId,
        applicationWizardId: application.applicationWizardId,
        applicationWizardScenarioId: generatePrimaryKey(),
      });
    }

    let updatedApplication: ApplicationWizard = { ...application, applicationWizardScenarios: tempIncludedItems };
    updateApplication(updatedApplication);
  };

  const columnDefinitions = getColumnDefinitions(application, clientFile.year, admData, onScenarioToggled);

  return (
    <AgGridReact<CoverageDecisionRowData>
      className="application-scenario-grid"
      defaultColDef={DefaultColDefinition}
      rowData={rowData}
      columnDefs={columnDefinitions}
      noRowsOverlayComponent={() => (
        <Typography variant="body2">No Quotes selected</Typography>
      )}
      gridOptions={{
        suppressCellFocus: true,
      }}
      autoSizeStrategy={{ type: 'fitGridWidth' }}
    />
  );
};

export default CoverageDecisionScenarioGrid;