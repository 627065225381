import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { ComponentProps, ReactElement } from 'react';

interface Props {
  isSelected: boolean;
  click: () => void;
  icon: ReactElement;
  text: ComponentProps<typeof ListItemText>['primary'];
  disabled?: boolean;
}

const NavigationLink = ({ isSelected, click, icon, text, disabled = false }: Props) => {
  return (
    <ListItemButton selected={isSelected} onClick={click} disabled={disabled} alignItems="center" sx={{ flexGrow: 0 }}>
      <ListItemIcon sx={{ marginTop: 0 }}>
        <Tooltip placement="right" title={text}>{icon}</Tooltip>
      </ListItemIcon>
      <ListItemText primary={text} primaryTypographyProps={{ variant: 'subtitle1' }} sx={{ marginBotom: 0 }} />
    </ListItemButton>
  );
};

export default NavigationLink;