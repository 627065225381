import { InsurancePlanCode } from '@silveus/calculations';
import { ADMYear } from '../../../types/api/adm/ADMYear';
import { AgencyInformation } from '../../../types/api/agencyInformation';
import { AgentInformation } from '../../../types/api/agentInformation';
import { AIP } from '../../../types/api/aip';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { AppTask } from '../../../types/api/AppTask';
import { NAUAgency, NAUAgent, NAUApplicant, NAUApplicationInformation, NAUApplicationOtherChanges, NAUAuthorizedSigner, NAUConditionsOfAcceptance, NAUCoverage, NAUPolicyInfo, NAUPolicyTransferInformation, NAUPostClosingAddedLandCoveredBy, NAUSBI, NAUSBIFormDTO, NAUSignatureInfo, NauApplicationDto } from '../../../types/api/documents/nauTypes';
import { AppType, AppTypeAttributes } from '../../../types/api/enums/application/AppType.enum';
import { CoverageType } from '../../../types/api/enums/application/coverageType.enum';
import { DesignatedCountyElection } from '../../../types/api/enums/application/designatedCountyElection';
import { EntityTypeEnum } from '../../../types/api/enums/application/entityType.enum';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { EntityType } from '../../../types/api/insureds/EntityType';
import { Insured } from '../../../types/api/insureds/Insured';
import { TaxType } from '../../../types/api/insureds/TaxType';
import { getCommodityName, getCountyName, getPracticeAbbrevFromPracticeCode } from '../../../utils/adm';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import { getAppTaskStateName, getTaxTypeNameFromId } from './applicationMapper';
import { ecoPlanCodesNameString, mapDelimitedCancelTransferCrops, mapDelimitedPoasAndAuthorizedReps, mapDelimitedUnitAndOptionCodes, mapNewProducerCoverages, scoPlanCodesNameString, shouldShowTypeOrPracticeOnForm, staxPlanCodesNameString } from './applicationMapperUtils';

// Maximum SBIs for NAU MPCI application before requiring an additional SBI form to be generated.
export const MaxNAUSBIs = 7;

export const MapNAUAdditionSBIs = (appTask: AppTask, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], agentInfo: AgentInformation, agencyInfo: AgencyInformation, insured: Insured) => {
  const sbis = mapSBIs(appTask, entityTypes, taxTypes);
  const mappedSBIForm: NAUSBIFormDTO = {
    applicant: mapApplicantInfo(appTask, entityTypes, taxTypes, insured),
    agent: mapAgentInformation(appTask, agentInfo),
    agency: mapAgencyInformation(agencyInfo),
    sbIs: sbis.slice(MaxNAUSBIs, sbis.length),
    policy: mapPolicyInfo(appTask, admData),
    signatureInfo: mapSignatureInfo(appTask, insured, agentInfo),
  };

  return mappedSBIForm;
};

export const MapNAUApplication = (appTask: AppTask, applicationWizard: ApplicationWizard, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], aips: AIP[], agentInfo: AgentInformation, agencyInfo: AgencyInformation, insured: Insured) => {
  const sbis = mapSBIs(appTask, entityTypes, taxTypes);
  const mappedApplication: NauApplicationDto = {
    applicationInfo: mapApplicationInfo(appTask, applicationWizard, admData),
    applicant: mapApplicantInfo(appTask, entityTypes, taxTypes, insured),
    agent: mapAgentInformation(appTask, agentInfo),
    agency: mapAgencyInformation(agencyInfo),
    otherChanges: mapOtherChanges(),
    coverages: mapCoverages(appTask, admData),
    postClosingAddedLandCoveredBy: mapPostClosingAddedLandCoveredBy(appTask),
    ezHailCoverage: null, // ignore - future/post 1.0 release - GETTY
    ezHailChange: null, // ignore - future/post 1.0 release - GETTY
    nonMPCICrops: null, // Ignore - future
    cropHailUnderwritingConditions: null, // ignore - future/post 1.0 release - GETTY
    authorizedSigners: mapAuthorizedSigners(appTask),
    sbIs: sbis.slice(0, MaxNAUSBIs),
    conditionsOfAcceptance: mapConditionsOfAcceptance(applicationWizard),
    policy: mapPolicyInfo(appTask, admData),
    policyTransferInformation: mapPolicyTransferInformation(appTask, admData, aips, agentInfo),
    signatureInfo: mapSignatureInfo(appTask, insured, agentInfo),
    remarks: null,
  };

  return mappedApplication;
};

const mapApplicationInfo = (appTask: AppTask, applicationWizard: ApplicationWizard, admYears: ADMYear[]) => {
  const { isInsuringLandlordShare, isInsuringTenantShare } = appTask;
  const newProducerCoverages = mapNewProducerCoverages(appTask, admYears);

  const applicationInfo: NAUApplicationInformation = {
    type: AppTypeAttributes[appTask.appType].name,
    parentOrGuardian: null, // Not part of app wizard at this time but an edge case that would need to be handled in the future if not 18
    limitedResourceFarmer: applicationWizard.isLimitedResourceFarmer,
    atLeast18: applicationWizard.isApplicantAtLeast18,
    newProducer: newProducerCoverages.length > 0,
    verticallyIntegratedProducer: applicationWizard.isVIP,
    isInsuringLandlordShare: isInsuringLandlordShare,
    isInsuringTenantShare: isInsuringTenantShare,
    categoryBNational: appTask.designatedCountyElection ? appTask.designatedCountyElection === DesignatedCountyElection.Nation : null,
    categoryBState: appTask.designatedCountyElection ? appTask.designatedCountyElection === DesignatedCountyElection.State : null,
  };
  return applicationInfo;
};

const mapApplicantInfo = (appTask: AppTask, entityTypes: EntityType[], taxTypes: TaxType[], insured: Insured) => {
  const personType = entityTypes.find(x => x.entityTypeId === insured.entityTypeId);
  const applicant: NAUApplicant = {
    fullName: insured.name,
    email: insured.email,
    phone: insured.phone,
    personType: personType?.name ?? null,
    taxIdType: getTaxTypeNameFromId(insured.taxTypeId, taxTypes),
    taxId: insured.taxId,
    spouseFirstName: null,
    spouseLastName: null,
    spouseTaxIdType: null,
    spouseTaxId: null,
    address1: insured.address.addressLine1,
    address2: '',
    city: insured.address.city,
    state: insured.address.state,
    zipCode: insured.address.postalCode,
    stateOfIncorporation: insured.corporationState,
    powerOfAttorney: mapDelimitedPoasAndAuthorizedReps(appTask),
  };

  const spousalEntityTypeId = entityTypes.find(entityType => entityType.name === EntityTypeEnum.SpousalMarried)?.entityTypeId;
  const spouse = appTask.insuredOtherPersonsOfInterest.find(x => x.personOfInterestType === PersonOfInterestType.Spouse);

  if (insured.entityTypeId === spousalEntityTypeId && isNotNullOrUndefined(spouse)) {
    applicant.spouseFirstName = spouse.firstName;
    applicant.spouseLastName = spouse.lastName;
    applicant.spouseTaxId = spouse.taxId;
    applicant.spouseTaxIdType = getTaxTypeNameFromId(spouse.taxTypeId, taxTypes);
  }

  return applicant;
};

const mapAgentInformation = (appTask: AppTask, agent: AgentInformation): NAUAgent => {
  const agentInfo: NAUAgent = {
    firstName: agent.firstName,
    lastName: agent.lastName,
    email: agent.email,
    agentCode: appTask.agentCodeName,
  };

  return agentInfo;
};


const mapAgencyInformation = (agency: AgencyInformation) => {
  const agencyInfo: NAUAgency = {
    agencyName: agency.agencyName,
    phone: agency.phone,
    address: agency.address,
    city: agency.city,
    state: agency.state,
    zipCode: agency.zipCode,
  };

  return agencyInfo;
};


const mapOtherChanges = () => {
  const otherChanges: NAUApplicationOtherChanges = {
    addRemoveSBI: null, // ignore - future/post 1.0 release - GETTY
    correctAuthorizedRepresentative: null,  // ignore - future/post 1.0 release - GETTY
    correctAddress: null,  // ignore - future/post 1.0 release - GETTY
    correctSbiIdNumber: null,  // ignore - future/post 1.0 release - GETTY
    correctInsuredIdNumber: null,  // ignore - future/post 1.0 release - GETTY
    correctInsuredSpelling: null,  // ignore - future/post 1.0 release - GETTY
    correctSbiSpelling: null,  // ignore - future/post 1.0 release - GETTY
    addRemoveCountyElection: null,  // ignore - future/post 1.0 release - GETTY
    other: null,  // ignore - future/post 1.0 release - GETTY
  };

  return otherChanges;
};

const mapPostClosingAddedLandCoveredBy = (appTask: AppTask): NAUPostClosingAddedLandCoveredBy => {
  const { coverages } = appTask;

  const childCoverages = coverages.filter(x => x.isDesignatedCounty).flatMap(x => x.childCoverages);
  const hasSCOChildren = childCoverages.some(x => x.planCodeName.includes(scoPlanCodesNameString));
  const hasECOChildren = childCoverages.some(x => x.planCodeName.includes(ecoPlanCodesNameString));
  const hasStaxChildren = childCoverages.some(x => x.planCodeName.includes(staxPlanCodesNameString));

  return {
    sco: hasSCOChildren,
    eco: hasECOChildren,
    stax: hasStaxChildren,
  };
};

const mapCoverages = (appTask: AppTask, admData: ADMYear[]) => {
  const coverages: NAUCoverage[] = [];
  for (const coverage of appTask.coverages) {
    const isCoverageCanceled = coverage.coverageType === CoverageType.Cancel;
    const yesFormValue = 'YES';
    const noFormValue = 'NO';
    const hipCoverage = coverage.childCoverages.find(childCov => childCov.planCode === InsurancePlanCode.HIP_WI);
    const scoCoverage = coverage.childCoverages.find(childCov => childCov.planCodeName.includes(scoPlanCodesNameString));
    const ecoCoverage = coverage.childCoverages.find(childCov => childCov.planCodeName.includes(ecoPlanCodesNameString));
    const coverageIsStax = coverage.planCodeName.includes(staxPlanCodesNameString);
    const childCoverageIsStax = coverage.childCoverages.find(childCov => childCov.planCodeName.includes(staxPlanCodesNameString));
    const coveragePractice = (coverage.practiceCode !== null ? getPracticeAbbrevFromPracticeCode(admData, appTask.cropYear, coverage.practiceCode) : '');

    coverages.push({
      addCoverage: null, // ignore - future/post 1.0 release - GETTY
      transferCoverage: null, // ignore - future/post 1.0 release - GETTY
      county: isNotNullOrUndefined(coverage.countyCode) ? getCountyName(admData, appTask.cropYear, coverage.countyCode) : '',
      effectiveCropYear: appTask.cropYear.toString(),
      desCty: isNotNullOrUndefined(coverage.isDesignatedCounty) ? coverage.isDesignatedCounty ? yesFormValue : noFormValue : null,
      cropCode: isNotNullOrUndefined(coverage.cropCode) ? getCommodityName(admData, appTask.cropYear, coverage.cropCode) : null,
      practice: isCoverageCanceled ? 'Cancel' : shouldShowTypeOrPracticeOnForm(coverage) ? `${coveragePractice} ${coverage.typeAbbrev}` : null,
      type: isCoverageCanceled ? 'Cancel' : shouldShowTypeOrPracticeOnForm(coverage) ? coverage.typeAbbrev : null,
      plan: coverage.planCodeName,
      coverageLevel: isNotNullOrUndefined(coverage.upperCoverageLevel) ? coverage.upperCoverageLevel.toString() : null,
      price: coverage.protectionFactor !== null ? coverage.protectionFactor.toString() : null, // this is a deprecated field, we are using PERCENT above instead
      options: mapDelimitedUnitAndOptionCodes(coverage),
      sco: isNotNullOrUndefined(scoCoverage),
      eco: isNotNullOrUndefined(ecoCoverage),
      hip: isNotNullOrUndefined(hipCoverage), // Ignore - Getty to investigate
      scoSE: null, // ignore - future/post 1.0 release - GETTY
      ecoSE: null, // ignore - future/post 1.0 release - GETTY
      stax: isNotNullOrUndefined(coverageIsStax) || isNotNullOrUndefined(childCoverageIsStax),
      arc: false, // per Ashley/Getty, default to false until spring season
      scoDetails: isNotNullOrUndefined(scoCoverage) ? `SCO ${scoCoverage.upperCoverageLevel}/${scoCoverage.protectionFactor}` : null,
      ecoDetails: isNotNullOrUndefined(ecoCoverage) ? `ECO ${ecoCoverage.upperCoverageLevel}/${ecoCoverage.protectionFactor}` : null,
      hipDetails: isNotNullOrUndefined(hipCoverage) ? `HIP ${hipCoverage.upperCoverageLevel}/${hipCoverage.protectionFactor}` : null,
    });
  }

  return coverages;
};


const mapPolicyInfo = (appTask: AppTask, admData: ADMYear[]) => {
  const counties = new Set<string>();
  for (const coverage of appTask.coverages) {
    if (isNotNullOrUndefined(coverage.countyCode)) {
      const county = getCountyName(admData, appTask.cropYear, coverage.countyCode);
      counties.add(county);
    }
  }

  const policyInfo: NAUPolicyInfo = {
    cropYear: appTask.cropYear.toString(),
    policyNumber: appTask.appType !== AppType.CancelTransfer ? appTask.policyNumber : null,
    state: getAppTaskStateName(appTask, admData),
    counties: Array.from(counties).join(', '),
  };

  return policyInfo;
};

const mapPolicyTransferInformation = (appTask: AppTask, admData: ADMYear[], aips: AIP[], agentInfo: AgentInformation): NAUPolicyTransferInformation => {
  const isAppCancelTransfer = appTask.appType === AppType.CancelTransfer;

  if (!isAppCancelTransfer) {
    return {
      aipName: '',
      policyNumber: null,
      cropsToBeCancelledOrTransferred: null,
      cropsYear: null,
      assumingAgent: null,
      aipRepresentativePrintedName: null, // Not applicable
      aipCode: null,
    };
  }

  const previousAIP = aips.find(x => x.aipId === appTask.previousAIPId);
  return {
    aipName: previousAIP?.aipName ?? '',
    policyNumber: appTask.previousPolicyNumber,
    cropsToBeCancelledOrTransferred: mapDelimitedCancelTransferCrops(appTask, admData),
    cropsYear: appTask.cropYear.toString(),
    assumingAgent: mapAgentInformation(appTask, agentInfo),
    aipRepresentativePrintedName: null, // ignore - filled out by AIP - GETTY
    aipCode: previousAIP?.aipId ?? '', // we need to add aipCode to the endpoint
  };
};

const mapAuthorizedSigners = (appTask: AppTask) => {
  const authorizedSigners = appTask.insuredOtherPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.AuthorizedToSign);
  return authorizedSigners.map(sbi => {
    const mappedSBI: NAUAuthorizedSigner = {
      firstName: sbi.firstName,
      lastName: sbi.lastName,
      phone: sbi.phone,
      address: sbi.address?.addressLine1 ?? null,
      city: sbi.address?.city ?? null,
      state: sbi.address?.state ?? null,
      zipCode: sbi.address?.postalCode ?? null,
      grant: true, // always set to true
      remove: null, // ignore - future/post 1.0 release - GETTY
    };
    return mappedSBI;
  });
};

const mapSBIs = (appTask: AppTask, entityTypes: EntityType[], taxTypes: TaxType[]) => {
  const sbiPOIs = appTask.insuredOtherPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.SBI || x.personOfInterestType === PersonOfInterestType.LandLordOrTenant || x.personOfInterestType === PersonOfInterestType.Spouse);
  return sbiPOIs.map(sbi => {
    const personType = entityTypes.find(x => x.entityTypeId === sbi.entityTypeId);
    const mappedSBI: NAUSBI = {
      firstName: sbi.firstName,
      lastName: sbi.lastName,
      phone: sbi.phone,
      personType: personType?.name ?? null,
      taxIdType: getTaxTypeNameFromId(sbi.taxTypeId, taxTypes),
      taxId: sbi.taxId,
      address: sbi.address?.addressLine1 ?? null,
      city: sbi.address?.city ?? null,
      state: sbi.address?.state ?? null,
      zipCode: sbi.address?.postalCode ?? null,
      landlordOrTenant: sbi.personOfInterestType === PersonOfInterestType.LandLordOrTenant,
    };
    return mappedSBI;
  });
};


const mapConditionsOfAcceptance = (applicationWizard: ApplicationWizard) => {
  const conditionsOfAcceptance: NAUConditionsOfAcceptance = {
    a: applicationWizard.fciaDelinquency,
    b: applicationWizard.controlledSubstanceConviction,
    c: applicationWizard.fciaAgreementDisqualification,
    d: applicationWizard.disqualifiedOrDebarred,
    e: applicationWizard.fciaAgreementDisqualification,
    f: applicationWizard.currentLikeInsurance,
  };
  return conditionsOfAcceptance;
};

const mapSignatureInfo = (appTask: AppTask, insured: Insured, agentInformation: AgentInformation) => {
  const signatureInfo: NAUSignatureInfo = {
    applicantPrintedName: insured.name,  // ignore - GETTY to confirm
    agentPrintedName: `${agentInformation.firstName} ${agentInformation.lastName}`, // ignore - form field does not exist
    signatureAuthorizations: null,  // ignore - GETTY to confirm
    authorizedForApplications: null,  // ignore - GETTY to confirm
    authorizedForTransfer: null,  // ignore - GETTY to confirm
    authorizedForChange: null,  // ignore - GETTY to confirm
    authorizedForCancel: null,  // ignore - GETTY to confirm
  };
  return signatureInfo;
};

