import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import { Grid } from '@mui/material';
import HailTableInfoCard from './hailTableInfoCard';
import { HailProductId, HailScenarioPieceId } from '../../../types/api/PrimaryKeys';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import HailTableIndemnityColumns from './hailTableIndemnityColumns';
import HailBlendedNetColumn from './hailBlendedNetColumn';
import HailLossPercentColumn from './hailLossPercentColumn';
import { useEffect } from 'react';
import { getBlendedNetValues, getIndemnities, getLossPercentages, getNetIndemnities } from '../utils/hailTableUtils';

type HailTableViewProps = {
  hailScenarioPieces: Map<HailScenarioPieceId, HailScenarioPiece>;
  getHailProductById: (hailProductId: HailProductId) => HailProduct;
}

export type HailTableFields =
  { hailScenarioPieces: HailScenarioPiece[] };

const HailTableView = ({ hailScenarioPieces, getHailProductById }: HailTableViewProps) => {
  const methods = useForm<HailTableFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      hailScenarioPieces: Array.from(hailScenarioPieces.values()),
    },
  });

  const { setValue } = methods;

  useEffect(() => {
    setValue('hailScenarioPieces', Array.from(hailScenarioPieces.values()));
  }, [hailScenarioPieces]);

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'hailScenarioPieces',
  });

  const topSectionHeight = 314;
  const dataColumnWidth = 250;

  // TODO -> these will use real data eventually
  const lossPercentIncrement = 10;
  const lossPercentMin = 0;
  const lossPercentMax = 100;
  const lossPercentages = getLossPercentages(lossPercentIncrement, lossPercentMin, lossPercentMax);

  const blendedNetValues = getBlendedNetValues(lossPercentages, fields, getHailProductById); // TODO -> recalc these when unchecking include in blended

  return (
    <FormProvider {...methods}>
      <form id="hail-table-form" style={{ height: '100%' }}>
        <Grid container direction="row" sx={{ height: '100%', overflowY: 'auto', overflowX: 'auto' }}>
          <Grid container columnGap={1} item sx={{ width: 'fit-content', flexWrap: 'nowrap' }}>
            <Grid container item direction="column" width={'fit-content'} xs="auto" padding="0px !important">
              <Grid height={topSectionHeight}>
              </Grid>
              {fields.length > 0 && (<HailLossPercentColumn
                lossPercentages={lossPercentages} />)}
            </Grid>
            <Grid container columnGap={1} item sx={{ width: 'fit-content', flexWrap: 'nowrap' }} padding="0px !important">
              {fields.map((hailScenarioPiece, index) => {
                const indemnities = getIndemnities(lossPercentages, hailScenarioPiece);
                const netIndemnities = getNetIndemnities(indemnities, getHailProductById(hailScenarioPiece.hailProductId).rate);
                const areIndemnityArraysSameLength = indemnities.length === netIndemnities.length;
                return (
                  <Grid key={hailScenarioPiece.hailScenarioPieceId}>
                    <HailTableInfoCard
                      hailScenarioPiece={hailScenarioPiece}
                      hailProduct={getHailProductById(hailScenarioPiece.hailProductId)}
                      index={index}
                      width={dataColumnWidth}
                      height={topSectionHeight} />
                    {areIndemnityArraysSameLength && <HailTableIndemnityColumns
                      width={dataColumnWidth}
                      indemnities={indemnities}
                      netIndemnities={netIndemnities} />}
                  </Grid>
                );
              })}
            </Grid>
            <Grid container item direction="column" width={'fit-content'} xs="auto" padding="0px !important">
              <Grid height={topSectionHeight}>
              </Grid>
              {fields.length > 0 && blendedNetValues !== undefined && (<HailBlendedNetColumn
                blendedValues={blendedNetValues} />)}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default HailTableView;