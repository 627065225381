import axios from 'axios';

import { FmhApplicationDto, FmhSbiFormDTO } from '../types/api/documents/fmhTypes';
import { GenerateFormsResponse } from '../types/api/documents/generateFormResponse';
import { HudsonApplicationDto, HudsonSbiFormDTO } from '../types/api/documents/hudsonTypes';
import { NAUSBIFormDTO, NauApplicationDto } from '../types/api/documents/nauTypes';
import { BulkESignatureRequest, ESignResult, ESignatureRequest } from '../types/api/documents/esign';
import { envBaseDocumentGenerationUrl } from '../constants/envConstants';
import { ProAgApplicationDto, ProAgSbiFormDTO } from '../types/api/documents/proAgTypes';
import { AgriSompoApplicationDto } from '../types/api/documents/agriSompoTypes';

export const generateHudsonApplicationDocument = async (hudsonApplication: HudsonApplicationDto, includePDFBytes: boolean) =>
  (await axios.post<GenerateFormsResponse>(
    `${envBaseDocumentGenerationUrl}/aip-documents/hudson/application/generate${includePDFBytes ? '?includePdf=true' : ''}`,
    hudsonApplication,
  )).data;

export const generateHudsonSBIDocument = async (hudsonSbiForm: HudsonSbiFormDTO, includePDFBytes: boolean) =>
  (await axios.post<GenerateFormsResponse>(
    `${envBaseDocumentGenerationUrl}/aip-documents/hudson/sbi-form/generate${includePDFBytes ? '?includePdf=true' : ''}`,
    hudsonSbiForm,
  )).data;

export const generateFmhApplicationDocument = async (fmhApplication: FmhApplicationDto, includePDFBytes: boolean) =>
  (await axios.post<GenerateFormsResponse>(
    `${envBaseDocumentGenerationUrl}/aip-documents/fmh/application/generate${includePDFBytes ? '?includePdf=true' : ''}`,
    fmhApplication,
  )).data;

export const generateFmhSBIDocument = async (fmhSbiForm: FmhSbiFormDTO, includePDFBytes: boolean) =>
  (await axios.post<GenerateFormsResponse>(
    `${envBaseDocumentGenerationUrl}/aip-documents/fmh/sbi-form/generate${includePDFBytes ? '?includePdf=true' : ''}`,
    fmhSbiForm,
  )).data;

export const generateNAUApplicationDocument = async (nauApplication: NauApplicationDto, includePDFBytes: boolean) =>
  (await axios.post<GenerateFormsResponse>(
    `${envBaseDocumentGenerationUrl}/aip-documents/nau/application/generate${includePDFBytes ? '?includePdf=true' : ''}`,
    nauApplication,
  )).data;

export const generateNAUSBIDocument = async (nauSbiForm: NAUSBIFormDTO, includePDFBytes: boolean) =>
  (await axios.post<GenerateFormsResponse>(
    `${envBaseDocumentGenerationUrl}/aip-documents/nau/sbi-form/generate${includePDFBytes ? '?includePdf=true' : ''}`,
    nauSbiForm,
  )).data;

export const generateProAgApplicationDocument = async (proAgApplication: ProAgApplicationDto, includePDFBytes: boolean) =>
  (await axios.post<GenerateFormsResponse>(
    `${envBaseDocumentGenerationUrl}/aip-documents/proag/application/generate${includePDFBytes ? '?includePdf=true' : ''}`,
    proAgApplication,
  )).data;

export const generateProAgSbiDocument = async (proAgSbiForm: ProAgSbiFormDTO, includePDFBytes: boolean) =>
  (await axios.post<GenerateFormsResponse>(
    `${envBaseDocumentGenerationUrl}/aip-documents/proag/sbi-form/generate${includePDFBytes ? '?includePdf=true' : ''}`,
    proAgSbiForm,
  )).data;

export const generateAgriSompoApplicationDocument = async (agriSompoApplication: AgriSompoApplicationDto, includePDFBytes: boolean) =>
  (await axios.post<GenerateFormsResponse>(
    `${envBaseDocumentGenerationUrl}/aip-documents/agrisompo/application/generate${includePDFBytes ? '?includePdf=true' : ''}`,
    agriSompoApplication,
  )).data;

/**
  DEPRECATED - waiting on removing all of the pieces that work on individual until we're sure multi meets all of our needs.
**/
export const eSignNAUApplicationDocument = async (esignatureRequest: ESignatureRequest) =>
  (await axios.post<ESignResult>(
    `${envBaseDocumentGenerationUrl}/aip-documents/nau/application/esignature`,
    esignatureRequest,
  )).data;

/**
  DEPRECATED - waiting on removing all of the pieces that work on individual until we're sure multi meets all of our needs.
**/
export const eSignNAUSBIDocument = async (esignatureRequest: ESignatureRequest) =>
  (await axios.post<ESignResult>(
    `${envBaseDocumentGenerationUrl}/aip-documents/nau/sbi-form/esignature`,
    esignatureRequest,
  )).data;

export const eSignHudsonApplicationDocument = async (esignatureRequest: ESignatureRequest) =>
  (await axios.post<ESignResult>(
    `${envBaseDocumentGenerationUrl}/aip-documents/hudson/application/esignature`,
    esignatureRequest,
  )).data;

/**
  DEPRECATED - waiting on removing all of the pieces that work on individual until we're sure multi meets all of our needs.
**/
export const eSignHudsonSBIDocument = async (esignatureRequest: ESignatureRequest) =>
  (await axios.post<ESignResult>(
    `${envBaseDocumentGenerationUrl}/aip-documents/hudson/sbi-form/esignature`,
    esignatureRequest,
  )).data;

/**
  DEPRECATED - waiting on removing all of the pieces that work on individual until we're sure multi meets all of our needs.
**/
export const eSignFMHApplicationDocument = async (esignatureRequest: ESignatureRequest) =>
  (await axios.post<ESignResult>(
    `${envBaseDocumentGenerationUrl}/aip-documents/fmh/application/esignature`,
    esignatureRequest,
  )).data;

/**
  DEPRECATED - waiting on removing all of the pieces that work on individual until we're sure multi meets all of our needs.
**/
export const eSignFMHSBIDocument = async (esignatureRequest: ESignatureRequest) =>
  (await axios.post<ESignResult>(
    `${envBaseDocumentGenerationUrl}/aip-documents/fmh/sbi-form/esignature`,
    esignatureRequest,
  )).data;

export const cancelESignRequest = async (envelopeId: string) =>
  (await axios.delete<ESignResult>(
    `${envBaseDocumentGenerationUrl}/esignature/${envelopeId}`,
  )).data;

export const bulkESignDocuments = async (bulkESignatureRequest: BulkESignatureRequest) =>
  (await axios.post<ESignResult>(
    `${envBaseDocumentGenerationUrl}/aip-documents/esign`,
    bulkESignatureRequest,
  )).data;