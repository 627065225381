import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import {
  RowCropScenarioPieceUnderlyingValidationRule,
  RowCropScenarioPieceValidationRule,
  liveQuoteShouldNotBeStale,
  underlyingPieceShouldNotBeCAT
} from '../validationRules/rowCropScenarioPieceValidationRules';
import { composeValidationResults } from '../validationHandling';
import { CombinedValidationResult } from '../validateScenario';
import { Nullable } from '../../../types/util/Nullable';

export function validateMp(scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: Nullable<RowCropScenarioPiece>, allScenarioPieces: RowCropScenarioPiece[]): CombinedValidationResult<RowCropScenarioPiece> {
  if (underlyingScenarioPiece === null) {
    return validateMpStandalonePlan(scenarioPiece, allScenarioPieces);
  }

  return validateMpCompanionPlan(scenarioPiece, underlyingScenarioPiece, allScenarioPieces);
}

const standalonePlanValidationRules: RowCropScenarioPieceValidationRule[] = [
  liveQuoteShouldNotBeStale,
];

const companionPlanValidationRules: (RowCropScenarioPieceValidationRule | RowCropScenarioPieceUnderlyingValidationRule)[] = [
  ...standalonePlanValidationRules,
  underlyingPieceShouldNotBeCAT,
];

const validateMpStandalonePlan = (scenarioPiece: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[]) => {
  const validationResults = standalonePlanValidationRules.map(validationRule => validationRule(scenarioPiece, allScenarioPieces));
  return composeValidationResults(validationResults);
};

const validateMpCompanionPlan = (scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[]) => {
  const validationResults = companionPlanValidationRules.map(validationRule => validationRule(scenarioPiece, allScenarioPieces, underlyingScenarioPiece));
  return composeValidationResults(validationResults);
};