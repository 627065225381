import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import validationFunctionDefault from '../../defaultDefinitions/validationFunctionDefault';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import { createProductionHailData } from '../../../../services/calculations/hail/productionHailDataTransformation';
import HailScenarioPiece from '../../../../types/api/HailScenarioPiece';
import HailScenarioPieceForm from '../../../../pages/scenarioPiece/hail/hailScenarioPieceForm.component';
import { getDefaultQuoteSummaryData } from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getDefaultQuoteSummaryData';
import { createStandardHailData } from '../../../../services/calculations/hail/standardHailDataTransformation';
import { getFullyQualifiedScenarioPieceNameOnlyName } from '../../defaultDefinitions/scenarioPieceNameDefaults';
import { scenarioPieceCardChipDefault } from '../../defaultDefinitions/scenarioPieceCardChipDefaults';

const baseDefinition: ScenarioPieceDefinition = {
  validationFunction: validationFunctionDefault,
  d365ProductType: ProductType.Hail,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    return createProductionHailData(scenarioPiece as HailScenarioPiece, scenario, state, baseUnits, shouldSimulateUnitGroups ?? false);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <HailScenarioPieceForm {...props}/>;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getDefaultQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceNameOnlyName,
  getScenarioPieceCardChips: scenarioPieceCardChipDefault,
  premiumBreakdownBarColor: '#00CED1',
};

export const productionHailDefinition: ScenarioPieceDefinition = {
  ...baseDefinition,
};

export const standardHailDefinition: ScenarioPieceDefinition = {
  ...baseDefinition,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    return createStandardHailData(scenarioPiece as HailScenarioPiece, scenario, state, baseUnits, shouldSimulateUnitGroups ?? false);
  },
};