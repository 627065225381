import { admDb } from '../../db';
import { CropType } from '../../types/api/adm/CropType';
import { getCropTypesForCommodity } from '../cropTypes.service';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { getDefaultCachingStrategy } from '../offlineDataAccess.service';

export const getCropTypesForCommodityRequest = async (year: number, commodityCode: string, countyId: string): Promise<CropType[]> => {
  const request = () => getCropTypesForCommodity(year, commodityCode, countyId);

  const transactionTables = [admDb.insuranceOffers, admDb.cropTypes, admDb.commodityUses];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const commodityUses = await safeWhere(admDb.commodityUses, { commodityCode: commodityCode }).toArray();
    const commodityUseIds = commodityUses.map(cu => cu.commodityUseId);
    const insuranceOffers = await safeWhere(admDb.insuranceOffers, { countyId: countyId }).and(io => commodityUseIds.includes(io.commodityUseId)).toArray();
    const cropTypeIds = insuranceOffers.map(io => io.typeId);
    const cropTypes = await safeWhere(admDb.cropTypes, 'typeId').anyOf(cropTypeIds).toArray();
    return cropTypes;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};
