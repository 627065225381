import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { TrendlineAnalysisTabIndex, TrendlineAnalysisTabs } from '../constants/trendlineAnalysisTabs';
import { RootState } from './store';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { ScenarioId, TrendlineAnalysisId } from '../types/api/PrimaryKeys';
import TrendlineAnalysis from '../types/api/TrendlineAnalysis';
import { getKeyedStateGroupedBy, getKeyedStateValues } from './sliceHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import { TrendlineType } from '../types/api/enums/trendlineAnalysis/TrendlineType.enum';
import { getItemsForId } from '../utils/mapHelpers';
import TrendlineAnalysisLineData from '../types/api/TrendlineAnalysisLineData';
import {
  createTrendlineAnalysisRequest, getTrendlineAnalysesByScenarioIdsRequest,
  updateTrendlineAnalysisRequest
} from '../services/requestInterception/trendlineRequestInterceptor';

interface TrendlineAnalysisState {
  allTrendlineAnalyses: SliceDataState<TrendlineAnalysisId, TrendlineAnalysis>;
  allLineData: SliceDataState<string, TrendlineAnalysisLineData>;
  selectedTabIndex: TrendlineAnalysisTabIndex;
}

const initialState: TrendlineAnalysisState = {
  allTrendlineAnalyses: initialSliceDataState(),
  allLineData: initialSliceDataState(),
  selectedTabIndex: TrendlineAnalysisTabs.yields.index,
};

export const trendlineAnalysisSlice = createSlice({
  name: 'TrendlineAnalysis',
  initialState: initialState,
  reducers: {
    setSelectedTrendlineTabIndex(state, action: PayloadAction<TrendlineAnalysisTabIndex>) {
      state.selectedTabIndex = action.payload;
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allTrendlineAnalyses, s => s.trendlineAnalysisId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchTrendlinesByScenarioIds,
      affectedIds: (_, state) => getKeyedStateValues(state.allTrendlineAnalyses.data).map(i => i.trendlineAnalysisId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addTrendlineAnalysis,
      affectedIds: arg => arg.trendline.trendlineAnalysisId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyTrendline,
      affectedIds: arg => arg.trendline.trendlineAnalysisId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeTrendline,
      affectedIds: arg => arg.trendline.trendlineAnalysisId,
    });

    const lineDataAsyncBuilder = getAsyncHandlerBuilder(builder, s => s.allLineData, s => s.trendlineLineDataId);

    lineDataAsyncBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: addLineData,
      affectedIds: () => [],
    });

    lineDataAsyncBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyLineData,
      affectedIds: arg => arg.lineData.map(x => x.trendlineLineDataId),
    });

    lineDataAsyncBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeLineData,
      affectedIds: arg => arg.lineData.map(x => x.trendlineLineDataId),
    });

    lineDataAsyncBuilder.generateAsyncHandlers({
      action: 'updating', thunk: toggleLineDataVisibility,
      affectedIds: arg => arg.lineData.trendlineLineDataId,
    });
  },
});

export const { setSelectedTrendlineTabIndex } = trendlineAnalysisSlice.actions;

export default trendlineAnalysisSlice.reducer;

const selectTrendlineDictionary = (state: RootState) => state.trendlineAnalysis.allTrendlineAnalyses.data;
const selectTrendlineLineData = (state: RootState) => state.trendlineAnalysis.allLineData.data;

export const selectTrendlineTabIndex = (state: RootState) => state.trendlineAnalysis.selectedTabIndex;

const selectAllLineDataByTrendlineId = createSelector([selectTrendlineLineData], result => {
  return getKeyedStateGroupedBy(result, sp => sp.trendlineAnalysisId);
});

export const selectLineDataByType = (state: RootState, trendlineAnalysisId: TrendlineAnalysisId, trendlineType: TrendlineType) => {
  const lineDataForTrendline = getItemsForId(selectAllLineDataByTrendlineId(state), trendlineAnalysisId);
  return lineDataForTrendline.filter(x => x.type === trendlineType);
};

export const selectLineDataByTabIndex = (state: RootState, trendlineAnalysisId: TrendlineAnalysisId, tabIndex: TrendlineAnalysisTabIndex) => {
  let trendlineType: TrendlineType;
  switch (tabIndex) {
    case TrendlineAnalysisTabs.yields.index:
      trendlineType = TrendlineType.Yield;
      break;
    case TrendlineAnalysisTabs.percentChange.index:
      trendlineType = TrendlineType.PercentChange;
      break;
    case TrendlineAnalysisTabs.prices.index:
      trendlineType = TrendlineType.Prices;
      break;
    case TrendlineAnalysisTabs.revenue.index:
      trendlineType = TrendlineType.Revenue;
      break;
  }

  return selectLineDataByType(state, trendlineAnalysisId, trendlineType);
};

export const selectAllTrendlines = createSelector([selectTrendlineDictionary], result => {
  const trendlines = getKeyedStateValues(result);
  const map = new Map<ScenarioId, TrendlineAnalysis>();
  trendlines.forEach(tl => {
    map.set(tl.primaryScenarioId, tl);
  });

  return map;
});

export const fetchTrendlinesByScenarioIds = createAppAsyncThunk('trendlines/fetchTrendlinesByScenarioIds', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  const trendlines = await getTrendlineAnalysesByScenarioIdsRequest(scenarioIds);
  return trendlines;
});

export const removeTrendline = createAppAsyncThunk('trendlines/removeTrendline', async ({ trendline }: { trendline: TrendlineAnalysis }) => {
  return trendline;
});

export const addTrendlineAnalysis = createAppAsyncThunk('trendlines/addTrendline', async ({ trendline }: { trendline: TrendlineAnalysis }) => {
  await createTrendlineAnalysisRequest(trendline);
  return trendline;
});

export const modifyTrendline = createAppAsyncThunk('trendlines/modifyTrendline', async ({ trendline }: { trendline: TrendlineAnalysis }) => {
  await updateTrendlineAnalysisRequest(trendline);
  return trendline;
});

export const addLineData = createAppAsyncThunk('trendlines/addLineData', async ({ lineData }: { lineData: TrendlineAnalysisLineData[] }) => {
  return lineData;
});

export const modifyLineData = createAppAsyncThunk('trendlines/modifyLineData', async ({ lineData, trendlineAnalysisId }: { lineData: TrendlineAnalysisLineData[], trendlineAnalysisId: TrendlineAnalysisId }, thunkApi) => {
  const state = thunkApi.getState();
  const type = lineData[0].type;
  const existingItems = getItemsForId(selectAllLineDataByTrendlineId(state), trendlineAnalysisId).filter(x => x.type === type);

  const itemsToRemove = existingItems.filter(x => !lineData.find(ld => ld.dataKey === x.dataKey));
  if (itemsToRemove.length > 0) {
    thunkApi.dispatch(removeLineData({ lineData: itemsToRemove }));
  }

  return lineData;
});

export const removeLineData = createAppAsyncThunk('trendlines/deleteLineData', async ({ lineData }: { lineData: TrendlineAnalysisLineData[] }) => {
  return lineData;
});

export const toggleLineDataVisibility = createAppAsyncThunk('trendlines/toggleVisibility', async ({ lineData }: { lineData: TrendlineAnalysisLineData }) => {
  const toggleLineData = { ...lineData, isVisible: !lineData.isVisible };
  return toggleLineData;
});