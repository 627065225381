import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../types/util/Nullable';
import { CombinedValidationResult } from '../validateScenario';
import { composeValidationResults } from '../validationHandling';
import { missingUnderlyingScenarioPiece, RowCropScenarioPieceUnderlyingValidationRule, upperCoverageShouldMatchUnderlyingUpperCoverage } from '../validationRules/rowCropScenarioPieceValidationRules';

export function validateMPowerD(scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: Nullable<RowCropScenarioPiece>, allScenarioPieces: RowCropScenarioPiece[]): CombinedValidationResult<RowCropScenarioPiece> {
  if (underlyingScenarioPiece === null) { return missingUnderlyingScenarioPiece; }

  const validationRules: RowCropScenarioPieceUnderlyingValidationRule[] = [
    upperCoverageShouldMatchUnderlyingUpperCoverage,
  ];

  const validationResults = validationRules.map(validationRule => validationRule(scenarioPiece, allScenarioPieces, underlyingScenarioPiece));
  return composeValidationResults(validationResults);
}