import { Box, Popper } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useState, MouseEvent } from 'react';
import { ScenarioPiece } from '../../../types/api/ScenarioPiece';
import { getScenarioPieceDefinition } from '../../../constants/productDefinitions/scenarioPieceDefinitionRecords';

interface Props {
  scenarioName: string;
  invalidScenarioPieces: readonly ScenarioPiece[];
}

export const InvalidScenarioIcon = ({ scenarioName, invalidScenarioPieces }: Props) => {
  const [anchorElement, setAnchorElement] = useState<SVGSVGElement | null>(null);

  const isTooltipOpen = Boolean(anchorElement);

  const handleIconHover = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleIconLeave = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <ErrorOutlineIcon onMouseOut={handleIconLeave} onMouseOver={e => handleIconHover(e)} color="warning" sx={{ verticalAlign: 'middle', ml: .5 }} />
      <Popper
        sx={{ backgroundColor: theme => theme.palette.secondary.main, color: theme => theme.palette.common.white }}
        placement="bottom-start" open={isTooltipOpen} anchorEl={anchorElement}
      >
        <TooltipTitle scenarioName={scenarioName} invalidScenarioPieces={invalidScenarioPieces} />
      </Popper>
    </>
  );
};

const TooltipTitle = ({ scenarioName, invalidScenarioPieces }: Props) => {
  const subjectString = invalidScenarioPieces.length === 1 ? 'piece' : 'pieces';
  const headingText = `Scenario ${scenarioName} has ${invalidScenarioPieces.length} invalid scenario ${subjectString}`;
  const getScenarioPieceName = (scenarioPiece: ScenarioPiece) => {
    const scenarioPieceDefinition = getScenarioPieceDefinition(scenarioPiece.scenarioPieceType);
    return scenarioPieceDefinition.getFullyQualifiedScenarioPieceName(scenarioPiece);
  };

  return (
    <Box sx={{ fontSize: '12px', padding: 1 }}>
      <p style={{ margin: 0 }}>{headingText}</p>
      <ul style={{ margin: 0 }}>
        {invalidScenarioPieces.map(piece => (
          <li key={piece.scenarioPieceId}>{getScenarioPieceName(piece)}</li>
        ))}
      </ul>
    </Box>
  );
};