import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface LoaderState {
  loading: boolean;
  namedTrackedLoading: string[];
}

const initialState: LoaderState = {
  loading: false,
  namedTrackedLoading: [],
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: initialState,
  reducers: {
    beginLoading(state) {
      state.loading = true;
    },
    endLoading(state) {
      state.loading = false;
    },
    beginNamedTrackedLoading(state: LoaderState, action: PayloadAction<string>) {
      if (state.namedTrackedLoading.includes(action.payload)) {
        return;
      }
      state.namedTrackedLoading.push(action.payload);
    },
    endNamedTrackedLoading(state: LoaderState, action: PayloadAction<string>) {
      if (!state.namedTrackedLoading.includes(action.payload)) {
        return;
      }
      state.namedTrackedLoading = state.namedTrackedLoading.filter(x => x !== action.payload);
    },
  },
});

export const { beginLoading, endLoading, beginNamedTrackedLoading, endNamedTrackedLoading } = loaderSlice.actions;

const selectDefaultLoading = (state: RootState) => state.loader.loading;
const selectNamedTrackedLoading = (state: RootState) => state.loader.namedTrackedLoading;

export const selectIsLoading = (state: RootState) => selectDefaultLoading(state) || (selectNamedTrackedLoading(state).length > 0);

export default loaderSlice.reducer;