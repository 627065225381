export const ClientFileTabs = {
  scenarios: {
    label: 'SCENARIO',
    index: 0,
  },
  scenarioAnalysis: {
    label: 'ANALYSIS',
    index: 1,
  },
  comparisonView: {
    label: 'COMPARE',
    index: 2,
  },
} as const;

export type ClientFileTabIndex = typeof ClientFileTabs[keyof typeof ClientFileTabs]['index'];
