import { closeSupportModal } from '../../app/supportSlice';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { MuiDialogCloseReason } from '../../types/mui/MuiDialogCloseReason';

export const useSupportModal = () => {
  const dispatch = useAppDispatch();


  const handleClose = (reason?: MuiDialogCloseReason) => {
    if (reason === 'backdropClick') {
      return;
    }

    dispatch(closeSupportModal());
  };

  return {
    handleClose,
  };
};