import { Nullable } from '../../../types/util/Nullable';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlLabel, FormHelperText, Checkbox } from '@mui/material';

interface HailIncludeInBlendedProps {
  includeInBlended: Nullable<boolean>;
  name: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

export type HailIncludeInBlendedFields = {
  includeInBlended: boolean;
}

const HailIncludeInBlendedInput = ({ includeInBlended, name, autoFocus = false, disabled = false }: HailIncludeInBlendedProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={includeInBlended ?? true}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl
          error={!!error}>
          <FormControlLabel
            label="Include in Blended?"
            control={
              <Checkbox
                {...field}
                disabled={disabled}
                checked={value}
                inputRef={ref}
                onChange={e => field.onChange(e.target.checked)}
                autoFocus={autoFocus}
                size="small"
              />
            }
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default HailIncludeInBlendedInput;
