import MESSAGES from '../../../constants/messages';
import { Nullable } from '../../../types/util/Nullable';

export function validateInsuredSharePercent(insuredSharePercent: Nullable<number>, allowZero: boolean = false): true | string {
  if (insuredSharePercent === null) return MESSAGES.required;
  if (insuredSharePercent > 1) return MESSAGES.maximum(1);
  if (insuredSharePercent < 0) return MESSAGES.minimum(0);
  if (!allowZero && insuredSharePercent === 0) return MESSAGES.greaterThan(0);

  return true;
}
