import { Nullable } from '../../../../types/util/Nullable';
import { LowerCoverageLevelInput, LowerCoverageLevelProps } from '../lowerCoverageLevelInput.component';
import { useFormContext, useWatch } from 'react-hook-form';
import { selectPrivateProductLowerCoverageLevels } from '../../../../app/privateProductSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { FormInputParameterNames } from '../../constants/inputConstants';

export const RevBoostLowerCoverageLevelInput = ({ lowerCoverageLevel, upperCoverageLevel, autoFocus = false, disabled = false }: LowerCoverageLevelProps & { upperCoverageLevel: Nullable<number> }) => {
  const { control } = useFormContext();
  const currentUpperCoverageLevel = useWatch({ name: FormInputParameterNames.UpperCoverageLevelName, control: control, defaultValue: upperCoverageLevel });

  const availableCoverageLevels = useAppSelector(state => selectPrivateProductLowerCoverageLevels(state, currentUpperCoverageLevel ?? 0));

  return <LowerCoverageLevelInput lowerCoverageLevel={lowerCoverageLevel ?? null} availableCoverageLevels={availableCoverageLevels} />;
};
