import { selectAllYeYears } from '../app/admSlice';
import { getKeyedStateToMap } from '../app/sliceHelpers';
import { buildCountyAndCommodityKey } from '../types/app/CompositeKeys';
import { getItemsForId } from '../utils/mapHelpers';
import { getYeYearForTypePracticePlan } from '../utils/yeUtils';
import { useAppSelector } from './reduxHooks';

export function useYeYears() {
  const allYeYears = useAppSelector(state => selectAllYeYears(state));
  return {
    getYeYearForCountyCommodity: (countyId: string, commodityCode: string) => {
      const allYeYearsByCountyAndCommodityMap = getKeyedStateToMap(allYeYears);
      const yeYears = getItemsForId(allYeYearsByCountyAndCommodityMap, buildCountyAndCommodityKey(countyId, commodityCode));
      return {
        yeYears,
        filterByTypePracticePlan: (typeId: string, practiceId: string, planCode: string | null) =>
          getYeYearForTypePracticePlan(yeYears, typeId, practiceId, planCode),
      };
    },
  };
}
