import { AIP } from '../types/api/aip';
import { AIPId } from '../types/api/PrimaryKeys';
import { Nullable } from '../types/util/Nullable';
import { isNotNullOrUndefined } from '../utils/nullHandling';

export const ActiveAIPs = {
  AgriSompo: {
    label: 'AgriSompo',
    aipId: 'ec254b17-411e-487d-83e4-622fc5eb6c64',
  },
  ArmTech: {
    label: 'ArmTech',
    aipId: '3b24786a-3211-ee11-8f6d-000d3a9bc9ed',
  },
  CRS: {
    label: 'CRS',
    aipId: '9b412ccd-23df-4b35-b405-a98e210dac42',
  },
  FMH: {
    label: 'FMH',
    aipId: '7c31b434-4ae7-4aa1-b1b5-fb14e86def8e',
  },
  Hudson: {
    label: 'Hudson',
    aipId: '0d91fb40-ebbc-4614-b3f4-6711021e8d2c',
  },
  NAU: {
    label: 'NAU',
    aipId: '8f58608c-8efc-4cb2-9de7-7ff9991c7173',
  },
  ProAg: {
    label: 'ProAg',
    aipId: 'ae0bef19-bde1-49eb-a754-b703b894d63b',
  },
} as const;

export const SupportedDocumentGenerationAIPIds = new Set<string>(
  [
    ActiveAIPs.FMH.aipId,
    ActiveAIPs.Hudson.aipId,
    ActiveAIPs.NAU.aipId,
    ActiveAIPs.ProAg.aipId,
    ActiveAIPs.AgriSompo.aipId,
  ]);

export const getAIPLabelById = (aipId: AIPId): Nullable<AIP> => {
  const entries = Object.values(ActiveAIPs);
  const matchingAIP = entries.find(aipEntry => aipEntry.aipId === aipId);
  return isNotNullOrUndefined(matchingAIP)
    ? {
      aipName: matchingAIP.label,
      aipId: matchingAIP.aipId as AIPId,
    }
    : null;
};
