import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { farmNameMaxLength, validateFarmName } from '../../../pages/units/validations/farmNameValidation';

interface FarmNameProps {
  farmName: Nullable<string>;
  autoFocus?: boolean;
}

export type FarmNameFields = {
  farmName: Nullable<string>;
}

const FarmNameInput = ({ farmName, autoFocus = false }: FarmNameProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="farmName"
      control={control}
      rules={{ validate: validateFarmName }}
      defaultValue={farmName}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Farm Name"
          fullWidth
          inputProps={{ maxLength: farmNameMaxLength }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default FarmNameInput;
