import { Grid } from '@mui/material';
import { ReactElement } from 'react';
import { Nullable } from '../../types/util/Nullable';
import { ScenarioPieceGroupResponseDTO, ScenarioPieceResponseDTO } from '@silveus/calculations';
import { ScenarioPieceCoverageResponseDTO } from '@silveus/calculations/dist/calculationOrchestrator/response/scenarioPieceCoverageResponseDTO';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { ScenarioComponentResultSummaryLabel } from './scenarioComponentResultSummaryLabel';
import { ScenarioComponentResultSummaryAdditional } from './scenarioComponentResultSummaryAdditional';

interface ScenarioComponentResultSummaryProps {
  scenarioPieceChips?: ReactElement;
  extraContent?: ReactElement;
  extraContentWidth?: number;
  textContentWidth?: number;
  label: string;
  summaryContent?: ReactElement;
  netValue: number;
  calcResults: Nullable<ScenarioPieceResponseDTO | ScenarioPieceGroupResponseDTO | ScenarioPieceCoverageResponseDTO>;
  scenarioId: ScenarioId;
  getScenarioPieceTooltip: () => ReactElement;
  isReadonly: boolean;
  isInvalid: boolean;
  errorTooltip?: ReactElement;
  mode?: 'slim' | 'additional-only' | 'full';
}

const ScenarioComponentResultSummary = ({
  scenarioPieceChips, extraContent, extraContentWidth = 0, textContentWidth = 12 - extraContentWidth, label,
  summaryContent, netValue, calcResults, scenarioId,
  getScenarioPieceTooltip, isReadonly, isInvalid, errorTooltip,
  mode = 'full',
}: ScenarioComponentResultSummaryProps) => {

  const renderLabel = mode !== 'additional-only';
  const renderAdditional = mode !== 'slim';

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" rowSpacing={1}>
        { renderLabel &&
          <ScenarioComponentResultSummaryLabel
            label={label}
            isInvalid={isInvalid}
            isReadonly={isReadonly}
            errorTooltip={errorTooltip}
            calcResults={calcResults}
            getScenarioPieceTooltip={getScenarioPieceTooltip}
            extraContent={extraContent}
            extraContentWidth={extraContentWidth}
            textContentWidth={textContentWidth}
            netValue={netValue}
            scenarioId={scenarioId}
          />
        }
        { renderAdditional &&
          <ScenarioComponentResultSummaryAdditional
            scenarioPieceChips={scenarioPieceChips}
            summaryContent={summaryContent}
          />
        }
      </Grid>
    </>
  );
};

export default ScenarioComponentResultSummary;