import { Grid, Radio, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ConditionsOfAcceptanceFormFields } from '../../../pages/applicationsModal/wizardSteps/conditionsOfAcceptanceStep';

const NoToAllQuestionsInput = () => {
  const { setValue, getValues } = useFormContext<ConditionsOfAcceptanceFormFields>();
  const allValues = getValues();
  const handleChange = (val: boolean) => {
    // In case user checks "No To All" button then we need to go and set all other form fields to false
    if (val) {
      setValue('fciaDelinquency', false, { shouldDirty: true });
      setValue('controlledSubstanceConviction', false, { shouldDirty: true });
      setValue('fciaInsuranceTermination', false, { shouldDirty: true });
      setValue('disqualifiedOrDebarred', false, { shouldDirty: true });
      setValue('fciaAgreementDisqualification', false, { shouldDirty: true });
      setValue('currentLikeInsurance', false, { shouldDirty: true });
    }
  };

  const value = [
    allValues.fciaDelinquency,
    allValues.controlledSubstanceConviction,
    allValues.fciaInsuranceTermination,
    allValues.disqualifiedOrDebarred,
    allValues.fciaAgreementDisqualification,
    allValues.currentLikeInsurance].every(x => x === false);

  return (
    <Grid container sx={{ alignItems: 'center', width: '100%', justifyContent: 'flex-end' }} >
      <Grid item mr={2}><Typography variant="body1">NO TO ALL</Typography></Grid>
      <Grid item sx={{ mr: 2.75 }}>
        <Radio
          checked={value === true}
          onChange={(_, checked) => handleChange(checked)}
          value="b"
          name="noToAll"
        />
      </Grid>
    </Grid>
  );
};

export default NoToAllQuestionsInput;