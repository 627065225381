import { Box, Button, Grid, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { selectCurrentlySelectedMatrixId, openMatrixColorPickerDialog, selectAllComparisonMatrices } from '../../app/matricesSlice';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../hooks/reduxHooks';
import { MatrixColorPicker } from '../matrix/matrixColorPicker.component';
import { CreateMatrixFromScenariosMenu } from '../scenario/components/scenarioCard.component.createMatrixFromScenariosMenu';
import MatrixCard from './components/matrixCard.component';
import { selectAllScenariosByClientFileIdMap } from '../../app/scenariosSlice';
import { getItemsForId } from '../../utils/mapHelpers';
import { MainContentZoomableArea } from '../clientFile/component/mainContentZoomableArea.component';
import { selectCurrentClientFileId } from '../../app/clientFilesSlice';


const MatrixComparisonView = () => {
  const dispatch = useAppDispatch();
  const [matrixMenuAnchor, setMatrixMenuAnchor] = useState<null | HTMLElement>(null);
  const clientFileId = useAppSelector(selectCurrentClientFileId);
  const scenarios = useKeyMapSelector(selectAllScenariosByClientFileIdMap, clientFileId);
  const globalMatricesByScenario = useAppSelector(state => selectAllComparisonMatrices(state));
  const selectedMatrixId = useAppSelector(selectCurrentlySelectedMatrixId);

  const filteredMatrices = useMemo(() => {
    return scenarios.flatMap(scenario => getItemsForId(globalMatricesByScenario, scenario.scenarioId));
  }, [scenarios, globalMatricesByScenario]);

  const onEditColorsClicked = () => {
    dispatch(openMatrixColorPickerDialog(true));
  };

  return (
    <Grid container item sx={{ height: '100%', display: 'flex', flexFlow: 'column', rowGap: '8px', overflow: 'hidden' }} p={1}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          id="btn-edit-colors"
          color="primary"
          variant="outlined"
          onClick={onEditColorsClicked}
        >
          Edit Colors
        </Button>

        {matrixMenuAnchor && (
          <CreateMatrixFromScenariosMenu anchor={matrixMenuAnchor} closeMenu={() => setMatrixMenuAnchor(null)} />
        )}
      </Box>
      <Box sx={{ padding: '8px', overflow: 'auto', display: 'flex', flexFlow: 'column', marginBottom: '8px', height: '100%' }}>
        {filteredMatrices.length === 0 ? (
          <Grid item><Typography>There are no matrices for this quote.</Typography></Grid>
        ) : (
          <MainContentZoomableArea>
            <Grid container columnGap={2} rowGap={2} sx={{ flexWrap: 'nowrap', width: 'fit-content', height: '100%' }}>
              {filteredMatrices.map(matrix => (
                <MatrixCard
                  key={matrix.matrixId}
                  isSelectedItem={selectedMatrixId === matrix.matrixId}
                  matrix={matrix} />
              ))}
            </Grid>
          </MainContentZoomableArea>
        )}
      </Box>
      <MatrixColorPicker />
    </Grid>
  );
};

export default MatrixComparisonView;