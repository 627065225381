import { ScenarioId } from '../types/api/PrimaryKeys';

import { useTrendLineYields } from './useTrendlineYields';

import { AcreageType, roundToPlaces, calculatePercentDifference } from '@silveus/calculations';

import HistoricalPercentages from '../types/api/adm/HistoricalPercentages';
import { useHistoricalPrices } from './useHistoricalPrices';
import { useHistoricalInputCosts } from './useHistoricalInputCosts';

export const useTrendLinePercentages = (scenarioId: ScenarioId, startYear: number, endYear: number, acreageType: AcreageType): HistoricalPercentages[] => {
  const priceData = useHistoricalPrices(scenarioId, startYear, endYear);
  const yieldData = useTrendLineYields(scenarioId, startYear, endYear, acreageType);
  const inputCostsData = useHistoricalInputCosts(scenarioId, startYear, endYear);

  let trendlinePercentages: HistoricalPercentages[] = [];

  for (let year = startYear; year <= endYear; year++) {
    const historicalYield = yieldData.find(x => x.year === year);
    const historicalPrice = priceData.find(x => x.priceYear === year);
    const historicalInputCost = inputCostsData.find(x => x.reinsuranceYear === year);

    const pricePercentDifference = historicalPrice !== undefined && historicalPrice.basePrice !== null && historicalPrice.harvestPrice !== null
      ? calculatePercentDifference(historicalPrice.basePrice, historicalPrice.harvestPrice)
      : undefined;

    const mpPricePercentDifference = historicalPrice !== undefined && historicalPrice.mpBasePrice !== null && historicalPrice.harvestPrice !== null
      ? calculatePercentDifference(historicalPrice.mpBasePrice, historicalPrice.harvestPrice)
      : undefined;

    const producerYieldPercentDifference = (historicalYield?.trendYield !== undefined && historicalYield.actualYield !== undefined)
      ? calculatePercentDifference(historicalYield.trendYield, historicalYield.actualYield)
      : undefined;

    const countyYieldPercentDifference = (historicalYield?.expectedCountyYield !== undefined && historicalYield.actualCountyYield !== undefined)
      ? calculatePercentDifference(historicalYield.expectedCountyYield, historicalYield.actualCountyYield)
      : undefined;

    const inputCostPercentDifference = (historicalInputCost !== undefined)
      ? calculatePercentDifference(historicalInputCost.expected, historicalInputCost.actual)
      : undefined;

    trendlinePercentages.push(
      {
        year: year,
        percentChangePrice: pricePercentDifference !== undefined ? roundToPlaces(pricePercentDifference, 2) : undefined,
        percentChangeMpPrice: mpPricePercentDifference !== undefined ? roundToPlaces(mpPricePercentDifference, 2) : undefined,
        percentChangeProducerYield: producerYieldPercentDifference !== undefined ? roundToPlaces(producerYieldPercentDifference, 2) : undefined,
        percentChangeCountyYield: countyYieldPercentDifference !== undefined ? roundToPlaces(countyYieldPercentDifference, 2) : undefined,
        percentChangeInputCosts: inputCostPercentDifference !== undefined ? roundToPlaces(inputCostPercentDifference, 2) : undefined,
      },
    );
  }

  return trendlinePercentages;
};