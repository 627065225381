import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { AppTask } from '../../../types/api/AppTask';
import { AppType } from '../../../types/api/enums/application/AppType.enum';

type PolicyInputProps = {
  value: Nullable<string>;
  originalPolicyNumber: Nullable<string>;
  index: number;
}

const PolicyInput = ({ value, index, originalPolicyNumber }: PolicyInputProps) => {
  const inputName = `appTasks.${index}.policyNumber`;
  const { control, setValue } = useFormContext();

  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}`, control });

  useEffect(() => {
    if (currentAppTask.appType === AppType.CancelTransfer) {
      setValue(inputName, '');
    } else {
      setValue(inputName, originalPolicyNumber);
    }
  }, [currentAppTask.appType, setValue, inputName, originalPolicyNumber]);

  return (
    <Controller
      name={inputName}
      control={control}
      render={({ field }) => (
        <TextField
          type="text"
          fullWidth
          sx={{ border: 'none', '& fieldset': { border: 'none' } }}
          {...field}
          size="small"
          inputProps={{
            sx: { fontSize: '14px !important' },
            readOnly: true,
          }}
        />
      )}
    />
  );
};

export default PolicyInput;