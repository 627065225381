import { IEnumAttributes } from '../IEnumAttributes';

export enum AppDocumentType {
  Application = 907550000,
  _578s = 907550009,
  AssignmentOfIndemnity = 907550011,
  BFRApplication = 907550012,
  Checklist = 907550040,
  Contract = 907550013,
  CorporationLLCStatement = 907550014,
  EmailCorrespondence = 907550015,
  EUDisclaimer = 907550016,
  HighRiskAlternativeCoverageEndorsement = 907550017,
  HighRiskLandExclusion = 907550018,
  HIPWIApplication = 907550019,
  IntendedAcreageReport = 907550020,
  LandlordTenantAuthorization = 907550021,
  NewProducersForm = 907550022,
  OrganicCertificate = 907550023,
  Other = 907550024,
  PartnershipStatement = 907550025,
  PolicyConfirmation = 907550027,
  PowerofAttorney = 907550029,
  ProductionRecords = 907550030,
  ProductionReport = 907550031,
  SBIForm = 907550032,
  ScheduleofInsurance = 907550033,
  SCOApplication = 907550034,
  STAXApplication = 907550035,
  TransferHistory = 907550036,
  TransferofCoverage = 907550037,
  VFRApplication = 907550038,
  WrittenAgreement = 907550039
}

export const AppDocumentTypeAttributes: Readonly<Record<AppDocumentType, IEnumAttributes<AppDocumentType>>> = {
  [AppDocumentType.Application]: { name: 'Application', description: 'Application', value: AppDocumentType.Application },
  [AppDocumentType._578s]: { name: '578s', description: '578s', value: AppDocumentType._578s },
  [AppDocumentType.AssignmentOfIndemnity]: { name: 'Assignment of Indemnity', description: 'Assignment of Indemnity', value: AppDocumentType.AssignmentOfIndemnity },
  [AppDocumentType.BFRApplication]: { name: 'BFR Application', description: 'BFR Application', value: AppDocumentType.BFRApplication },
  [AppDocumentType.Checklist]: { name: 'Checklist', description: 'Checklist', value: AppDocumentType.Checklist },
  [AppDocumentType.Contract]: { name: 'Contract', description: 'Contract', value: AppDocumentType.Contract },
  [AppDocumentType.CorporationLLCStatement]: { name: 'Corporation LLC Statement', description: 'Corporation LLC Statement', value: AppDocumentType.CorporationLLCStatement },
  [AppDocumentType.EmailCorrespondence]: { name: 'Email Correspondence', description: 'Email Correspondence', value: AppDocumentType.EmailCorrespondence },
  [AppDocumentType.EUDisclaimer]: { name: 'EU Disclaimer', description: 'EU Disclaimer', value: AppDocumentType.EUDisclaimer },
  [AppDocumentType.HighRiskAlternativeCoverageEndorsement]: { name: 'High Risk Alternative Coverage Endorsement', description: 'High Risk Alternative Coverage Endorsement', value: AppDocumentType.HighRiskAlternativeCoverageEndorsement },
  [AppDocumentType.HighRiskLandExclusion]: { name: 'High Risk Land Exclusion', description: 'High Risk Land Exclusion', value: AppDocumentType.HighRiskLandExclusion },
  [AppDocumentType.HIPWIApplication]: { name: 'HIPWI Application', description: 'HIPWI Application', value: AppDocumentType.HIPWIApplication },
  [AppDocumentType.IntendedAcreageReport]: { name: 'Intended Acreage Report', description: 'Intended Acreage Report', value: AppDocumentType.IntendedAcreageReport },
  [AppDocumentType.LandlordTenantAuthorization]: { name: 'Landlord Tenant Authorization', description: 'Landlord Tenant Authorization', value: AppDocumentType.LandlordTenantAuthorization },
  [AppDocumentType.NewProducersForm]: { name: 'New Producers Form', description: 'New Producers Form', value: AppDocumentType.NewProducersForm },
  [AppDocumentType.OrganicCertificate]: { name: 'Organic Certificate', description: 'Organic Certificate', value: AppDocumentType.OrganicCertificate },
  [AppDocumentType.Other]: { name: 'Other', description: 'Other', value: AppDocumentType.Other },
  [AppDocumentType.PartnershipStatement]: { name: 'Partnership Statement', description: 'Partnership Statement', value: AppDocumentType.PartnershipStatement },
  [AppDocumentType.PolicyConfirmation]: { name: 'Policy Confirmation', description: 'Policy Confirmation', value: AppDocumentType.PolicyConfirmation },
  [AppDocumentType.PowerofAttorney]: { name: 'Power of Attorney', description: 'Power of Attorney', value: AppDocumentType.PowerofAttorney },
  [AppDocumentType.ProductionRecords]: { name: 'Production Records', description: 'Production Records', value: AppDocumentType.ProductionRecords },
  [AppDocumentType.ProductionReport]: { name: 'Production Report', description: 'Production Report', value: AppDocumentType.ProductionReport },
  [AppDocumentType.SBIForm]: { name: 'SBI Form', description: 'SBI Form', value: AppDocumentType.SBIForm },
  [AppDocumentType.ScheduleofInsurance]: { name: 'Schedule of Insurance', description: 'Schedule of Insurance', value: AppDocumentType.ScheduleofInsurance },
  [AppDocumentType.SCOApplication]: { name: 'SCO Application', description: 'SCO Application', value: AppDocumentType.SCOApplication },
  [AppDocumentType.STAXApplication]: { name: 'STAX Application', description: 'STAX Application', value: AppDocumentType.STAXApplication },
  [AppDocumentType.TransferHistory]: { name: 'Transfer History', description: 'Transfer History', value: AppDocumentType.TransferHistory },
  [AppDocumentType.TransferofCoverage]: { name: 'Transfer of Coverage', description: 'Transfer of Coverage', value: AppDocumentType.TransferofCoverage },
  [AppDocumentType.VFRApplication]: { name: 'VFR Application', description: 'VFR Application', value: AppDocumentType.VFRApplication },
  [AppDocumentType.WrittenAgreement]: { name: 'Written Agreement', description: 'Written Agreement', value: AppDocumentType.WrittenAgreement },
};

export const SupportedAppDocumentGenerationTypes = new Set<AppDocumentType>([AppDocumentType.Application, AppDocumentType.SBIForm]);