import { ClientFileId, QuoteId, ScenarioId, UnitYearAphId, UnitYearId } from '../../types/api/PrimaryKeys';

export class MissingCurrentlySelectedScenarioInStateError extends Error {
  constructor() {
    super('Current scenario is missing in state');
  }
}

export class MissingScenarioInStateError extends Error {
  constructor(scenarioId: ScenarioId) {
    super(`Scenario ${scenarioId} is missing in state`);
  }
}

export class MissingUnderlyingScenarioPieceInStateError extends Error {
  constructor(scenarioId: ScenarioId) {
    super(`The underlying scenario piece for ${scenarioId} is missing in state`);
  }
}

export class MissingQuoteInStateError extends Error {
  constructor(quoteId: QuoteId) {
    super(`Quote ${quoteId} is missing in state`);
  }
}

export class MissingPracticeInScenarioError extends Error {
  constructor(scenarioId: ScenarioId) {
    super(`Scenario ${scenarioId} is missing practice`);
  }
}

export class MissingClientFileInStateError extends Error {
  constructor(clientFileId: ClientFileId) {
    super(`Client File ${clientFileId} is missing in state`);
  }
}

export class MissingUnitYearInStateError extends Error {
  constructor(unitYearId: UnitYearId) {
    super(`Unit Year ${unitYearId} is missing in state`);
  }
}

export class MissingUnitYearAphInStateError extends Error {
  constructor(unitYearAphId: UnitYearAphId) {
    super(`Unit Year Aph ${unitYearAphId} is missing in state`);
  }
}

export class MissingScenarioUnitYearAphInStateError extends Error {
  constructor(unitYearAphId: UnitYearAphId) {
    super(`Scenario Unit Year Aph for ${unitYearAphId} is missing in state`);
  }
}
