import { Nullable } from '../../util/Nullable';
import { BaseReportScenarioPiece } from './BaseReportScenarioPiece';

export enum PlanBaseType {
  Basic = 0,
  Hail = 1
}

export interface QuoteSummaryScenarioPiece extends BaseReportScenarioPiece {
  unitStructure: string;
  projectedPrice: Nullable<number>;
  isProjectedPriceSet: boolean;
  protectionFactor: Nullable<number>;
  hailEndorsements: string[];
  hailRate: Nullable<number>;
  planBaseType: PlanBaseType;
  options: string[];
}