import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import NumberTextField, { onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { FormInputProps } from './formInputProps';
import { validateYield } from '../../../pages/units/validations/yieldValidation';
import { ReactNode } from 'react';

export interface YieldProps extends FormInputProps {
  name: string;
  theYield: Nullable<number>; // "yield" is reserved
  toolTip?: ReactNode;
  label: string;
}

const YieldInput = ({ name, toolTip = undefined, theYield, sx, label, autoFocus = false, disabled = false }: YieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value: Nullable<number>) => validateYield(value, false) }}
      defaultValue={theYield ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          type="number"
          label={label}
          fullWidth
          inputProps={{ step: 0.01, min: 0 }}
          InputProps={{
            endAdornment: toolTip,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          sx={sx}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default YieldInput;
