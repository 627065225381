import { Checkbox } from '@mui/material';
import { useFormValues } from '../../../hooks/useFormValues';
import { IntervalInputFields } from './intervalInput.component';
import { FormInputParameterNames } from '../constants/inputConstants';
import { IntervalRange } from '../../../types/api/adm/IntervalRange';

interface Props {
  interval: IntervalRange,
}

export const IntervalCheckbox = ({ interval }: Props) => {
  const { watchFormValue, setValue } = useFormValues<IntervalInputFields>();
  const selectedIntervalIds = watchFormValue.selectedIntervalIds ?? [];

  function handleChange(checked: boolean) {
    if (selectedIntervalIds.includes(interval.id) === checked) return;
    const updatedSelectedIntervals = checked ?
      [...selectedIntervalIds, interval.id] :
      selectedIntervalIds.filter((intervalId: number) => intervalId !== interval.id);
    setValue(FormInputParameterNames.SelectedIntervalsIds, updatedSelectedIntervals, { shouldDirty: true });
  }

  return <Checkbox
    key={interval.id}
    value={interval.id}
    checked={selectedIntervalIds.includes(interval.id)}
    onChange={e => handleChange(e.target.checked)}
  />;
};
