import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceType } from '@silveus/calculations';
import { PlanSelectorInput } from '../planSelectorInput.component';

export interface StaxPlanSelectorProps {
  selectedStaxPlan: Nullable<ScenarioPieceType>;
  disabled?: boolean;
}

export const StaxPlanSelectorInput = ({ selectedStaxPlan, disabled = false }: StaxPlanSelectorProps) => {
  const uniquePlans = [ScenarioPieceType.StaxRp, ScenarioPieceType.StaxRpHpe];

  return (
    <PlanSelectorInput
      label="STAX Plan"
      disabled={disabled}
      selectedPlan={selectedStaxPlan}
      availablePlans={uniquePlans}
    />
  );
};
