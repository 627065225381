import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ScenarioId } from '../types/api/PrimaryKeys';
import { Nullable } from '../types/util/Nullable';
import { RootState } from './store';
import { ScenarioAnalysisTabIndex, ScenarioAnalysisTabs } from '../constants/analysisTabs';

interface ScenarioAnalysisState {
  selectedTabIndex: ScenarioAnalysisTabIndex;
  selectedMatrixScenarioId: Nullable<ScenarioId>;
  selectedTrendlineScenarioId: Nullable<ScenarioId>;
  selectedHistoricalScenarioId: Nullable<ScenarioId>;
  selectedPremiumBreakdownScenarioId: Nullable<ScenarioId>;
}

const initialState: ScenarioAnalysisState = {
  selectedTabIndex: ScenarioAnalysisTabs.matrix.index,
  selectedMatrixScenarioId: null,
  selectedTrendlineScenarioId: null,
  selectedHistoricalScenarioId: null,
  selectedPremiumBreakdownScenarioId: null,
};


export const scenarioAnalysisSlice = createSlice({
  name: 'scenarioAnalysis',
  initialState: initialState,
  reducers: {
    setSelectedScenarioAnalysisTabIndex(state, action: PayloadAction<ScenarioAnalysisTabIndex>) {
      state.selectedTabIndex = action.payload;
    },
    setSelectedMatrixScenarioId(state, action: PayloadAction<Nullable<ScenarioId>>) {
      state.selectedMatrixScenarioId = action.payload;
    },
    setSelectedTrendlineScenarioId(state, action: PayloadAction<Nullable<ScenarioId>>) {
      state.selectedTrendlineScenarioId = action.payload;
    },
    setSelectedHistoricalScenarioId(state, action: PayloadAction<Nullable<ScenarioId>>) {
      state.selectedHistoricalScenarioId = action.payload;
    },
    setSelectedPremiumBreakdownScenarioId(state, action: PayloadAction<Nullable<ScenarioId>>) {
      state.selectedPremiumBreakdownScenarioId = action.payload;
    },
    resetSelectedScenarioId(state) {
      state.selectedMatrixScenarioId = null;
      state.selectedHistoricalScenarioId = null;
      state.selectedTrendlineScenarioId = null;
      state.selectedPremiumBreakdownScenarioId = null;
    },
  },
});

export const {
  setSelectedScenarioAnalysisTabIndex,
  setSelectedTrendlineScenarioId,
  setSelectedMatrixScenarioId,
  resetSelectedScenarioId,
  setSelectedHistoricalScenarioId,
  setSelectedPremiumBreakdownScenarioId } = scenarioAnalysisSlice.actions;

export default scenarioAnalysisSlice.reducer;

export const selectScenarioAnalysisTabIndex = (state: RootState) => state.scenarioAnalysis.selectedTabIndex;
export const selectSelectedMatrixScenarioId = (state: RootState) => state.scenarioAnalysis.selectedMatrixScenarioId;
export const selectSelectedTrendlineScenarioId = (state: RootState) => state.scenarioAnalysis.selectedTrendlineScenarioId;
export const selectSelectedHistoricalScenarioId = (state: RootState) => state.scenarioAnalysis.selectedHistoricalScenarioId;
export const selectSelectedPremiumBreakdownScenarioId = (state: RootState) => state.scenarioAnalysis.selectedPremiumBreakdownScenarioId;

export const selectSelectedScenarioAnalysisScenarioId = (state: RootState) => {
  const index = state.scenarioAnalysis.selectedTabIndex;

  switch (index) {
    case ScenarioAnalysisTabs.matrix.index:
      return state.scenarioAnalysis.selectedMatrixScenarioId;
    case ScenarioAnalysisTabs.historical.index:
      return state.scenarioAnalysis.selectedHistoricalScenarioId;
    case ScenarioAnalysisTabs.trendlines.index:
      return state.scenarioAnalysis.selectedTrendlineScenarioId;
    case ScenarioAnalysisTabs.premium.index:
      return state.scenarioAnalysis.selectedPremiumBreakdownScenarioId;
    default:
      return null;
  }
};