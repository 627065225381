import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import {
  CurrencyCellRenderer
} from '../../../../pages/scenarioAnalysis/historicalAnalysis/charts/HistoricalChart/cellRenderers/currencyCellRenderer';
import {
  PercentageCellRenderer
} from '../../../../pages/scenarioAnalysis/historicalAnalysis/charts/HistoricalChart/cellRenderers/percentageCellRenderer';
import {
  getMpQuoteSummaryData
} from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getMpQuoteSummaryData';
import { MpScenarioPieceForm } from '../../../../pages/scenarioPiece/mp/mpScenarioPieceForm.component';
import { createMpData } from '../../../../services/calculations/mp/mpDataTransformations';
import { validateMp } from '../../../../utils/validation/scenarioPieces/mpValidation';
import { getFullyQualifiedScenarioPieceNameOnlyUpperCoverageLevel
} from '../../defaultDefinitions/scenarioPieceNameDefaults';
import {
  LiveQuotingScenarioPieceChip,
  ProtectionFactorScenarioPieceChip
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { ScenarioPiece } from '../../../../types/api/ScenarioPiece';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

export const mpDefinition: ScenarioPieceDefinition = {
  validationFunction: validateMp,
  d365ProductType: ProductType.MPCI,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    return createMpData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <MpScenarioPieceForm {...props} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>} />;
  },
  getHistoricalQuoterColumns: params => {
    const mpPrice = params.prices.find(price => price.priceYear === params.year);
    const mpInputCost = params.mpInputCosts.find(inputCost => inputCost.reinsuranceYear === params.year);
    const yearPercentChanges = params.percentChanges.find(p => p.year === params.year);
    return [
      {
        groupingKey: 'MP Price',
        dataKey: 'mpExpectedPrice',
        value: mpPrice?.mpBasePrice,
      },
      {
        groupingKey: 'MP Price',
        dataKey: 'mpHarvestPrice',
        value: mpPrice?.harvestPrice,
      },
      {
        groupingKey: 'MP Price',
        dataKey: 'mpPricePercentChange',
        value: yearPercentChanges?.percentChangeMpPrice,
      },
      {
        groupingKey: 'MP Input Costs',
        dataKey: 'mpExpectedInputCost',
        value: mpInputCost?.expected,
      },
      {
        groupingKey: 'MP Input Costs',
        dataKey: 'mpActualInputCost',
        value: mpInputCost?.actual,
      },
      {
        groupingKey: 'MP Input Costs',
        dataKey: 'mpInputCostPercentChange',
        value: yearPercentChanges?.percentChangeInputCosts,
      },
    ];
  },
  getHistoricalQuoterColumnConfigs: params => [
    {
      groupingKey: 'MP Price',
      dataKey: 'mpExpectedPrice',
      columnHeader: 'Plant Price',
      cellRenderer: CurrencyCellRenderer,
      maxWidth: 100,
    },
    {
      groupingKey: 'MP Price',
      dataKey: 'mpHarvestPrice',
      columnHeader: 'Harvest Price',
      cellRenderer: CurrencyCellRenderer,
      maxWidth: 100,
    },
    {
      groupingKey: 'MP Price',
      dataKey: 'mpPricePercentChange',
      columnHeader: '% Chg.',
      cellRenderer: PercentageCellRenderer,
      maxWidth: 100,
    },
    {
      groupingKey: 'MP Input Costs',
      dataKey: 'mpExpectedInputCost',
      columnHeader: 'Exp. Cost',
      cellRenderer: CurrencyCellRenderer,
      maxWidth: 100,
    },
    {
      groupingKey: 'MP Input Costs',
      dataKey: 'mpActualInputCost',
      columnHeader: 'Actual Cost',
      cellRenderer: CurrencyCellRenderer,
      maxWidth: 100,
    },
    {
      groupingKey: 'MP Input Costs',
      dataKey: 'mpInputCostPercentChange',
      columnHeader: '% Chg.',
      cellRenderer: PercentageCellRenderer,
      maxWidth: 100,
    },
  ],
  createInputForQuoteSummaryFunction: getMpQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
    return getFullyQualifiedScenarioPieceNameOnlyUpperCoverageLevel(scenarioPiece, true);
  },
  getScenarioPieceCardChips: [LiveQuotingScenarioPieceChip, ProtectionFactorScenarioPieceChip],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#BA55D3',
};