import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { ReactNode } from 'react';

interface SelectedScenarioCheckboxInputProps {
  scenarioId: ScenarioId;
  scenarioName: string;
  selected: boolean;
  handleSelectionChanged: (selected: boolean, scenarioId: ScenarioId) => void;
  extraContent?: ReactNode;
}

export const SelectedScenarioCheckboxInput = ({ scenarioId, scenarioName, selected, handleSelectionChanged, extraContent }: SelectedScenarioCheckboxInputProps) => {
  return (
    <ListItem key={scenarioId} disablePadding>
      <ListItemButton onClick={() => handleSelectionChanged(!selected, scenarioId)}>
        <ListItemIcon>
          <Checkbox edge="start" checked={selected} />
        </ListItemIcon>
        <ListItemText primary={scenarioName} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
      </ListItemButton>
      {extraContent}
    </ListItem>
  );
};