import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import NumberTextField, { getRoundedValue, onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferProducerYield } from '../../../app/admSlice';
import { isNullOrUndefined, isUndefined } from '../../../utils/nullHandling';
import { useEffect } from 'react';
import { isApiDataStale, isSavedDataBeingLoaded } from '../../../utils/formInputUtils';
import { validateYield } from '../../../pages/units/validations/yieldValidation';
import { Quote } from '../../../types/api/Quote';
import { Info } from '../../info/info';
import { useGetYieldRoundingPrecisionInScenarioForm } from './hooks/useGetYieldRoundingPrecisionInScenarioForm';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { FormInputProps } from './formInputProps';

interface RateYieldProps extends FormInputProps {
  quote: Quote;
  scenarioId: Nullable<ScenarioId>;
  rateYield: Nullable<number>;
  allowZero?: boolean;
}

export type RateYieldFields = {
  rateYield: number;
}

const RateYieldInput = ({ quote, scenarioId, rateYield, allowZero = false, sx, autoFocus = false, disabled = false }: RateYieldProps) => {
  const { control, setValue, getValues, trigger } = useFormContext();

  const rate = useAppSelector(selectOfferProducerYield);
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });

  const roundingPrecision = useGetYieldRoundingPrecisionInScenarioForm({ quote, scenarioId });

  useEffect(() => {
    const currentPractice = getValues('practiceId');

    const isThereStaleData = isApiDataStale(currentPractice, rate);
    const isLoadingSavedData = isSavedDataBeingLoaded(rateYield, practice);

    if (isThereStaleData || isLoadingSavedData || isNullOrUndefined(rate) || !quote.quickQuote) return;

    setValue('rateYield', rate);
    trigger('rateYield');
  }, [rate]);

  useEffect(() => {
    //When the practice is changed, if it is not undefined, blank out the approved yield's value
    if (isUndefined(practice) || practice === '') return;

    setValue('rateYield', 0);
  }, [practice]);

  return (
    <Controller
      name="rateYield"
      control={control}
      rules={{ validate: value => validateYield(value, allowZero) }}
      defaultValue={getRoundedValue(rateYield, roundingPrecision) ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          type="number"
          label="Rate Yield"
          fullWidth
          inputProps={{ step: 0.01, min: 0 }}
          InputProps={{
            endAdornment: <Info tooltipContent="Rate Yield" />,
          }}
          sx={sx}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          onBlur={e => onNumericInputChange(e, field, roundingPrecision)}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default RateYieldInput;
