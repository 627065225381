import { selectAllTrendAdjustmentFactors } from '../app/admSlice';
import { getKeyedStateToMap } from '../app/sliceHelpers';
import QuoteTrendAdjustmentFactors from '../types/api/adm/QuoteTrendAdjustmentFactors';
import TrendAdjustmentFactor from '../types/api/adm/TrendAdjustmentFactor';
import { buildCountyAndCommodityKey } from '../types/app/CompositeKeys';
import { getItemsForId } from '../utils/mapHelpers';
import { useAppSelector } from './reduxHooks';

export function useTaFactors() {
  const allTrendAdjustmentFactors = useAppSelector(selectAllTrendAdjustmentFactors);
  function forCountyCommodity(countyId: string, commodityCode: string) {
    const allTrendAdjustmentFactorsByCountyAndCommodityMap = getKeyedStateToMap(allTrendAdjustmentFactors);
    return getCountyCommodityTaFactors(allTrendAdjustmentFactorsByCountyAndCommodityMap, countyId, commodityCode);
  }
  return forCountyCommodity;
}

export function convertToTaFactorsMap(allTrendAdjustmentFactors: QuoteTrendAdjustmentFactors[]) {
  return new Map(allTrendAdjustmentFactors.map(item => [item.countyCommodityKey, item.trendAdjustmentFactors]));
}

export function getCountyCommodityTaFactors(allTrendAdjustmentFactors: Map<`${string}-${string}`, TrendAdjustmentFactor[]>, countyId: string, commodityCode: string) {
  const allTaFactors = getItemsForId(allTrendAdjustmentFactors, buildCountyAndCommodityKey(countyId, commodityCode));
  const getTaFactorForPracticeTypePlan = (practiceId: string, typeId: string, planCode: string) => {
    return allTaFactors.find(ta => ta.practiceId === practiceId && ta.typeId === typeId && ta.planCode === planCode);
  };
  return { allTaFactors, getTaFactorForPracticeTypePlan };
}

