import { combineReducers, configureStore } from '@reduxjs/toolkit';
import insuredsReducer from './insuredsSlice';
import unitsReducer from './unitsSlice';
import clientFilesReducer from './clientFilesSlice';
import quotesReducer from './quotesSlice';
import scenariosReducer from './scenariosSlice';
import confirmReducer from './confirmSlice';
import loaderReducer from './loaderSlice';
import toastReducer from './toastSlice';
import admReducer from './admSlice';
import privateProductsReducer from './privateProductSlice';
import intervalsReducer from './intervalsSlice';
import appDrawerReducer from './appDrawerSlice';
import calculationResultsReducer from './calculationResultsSlice';
import scenarioPiecesReducer from './scenarioPiecesSlice';
import inputCostScenarioPiecesReducer from './inputCostScenarioPiecesSlice';
import userSettingsReducer from './userSettingsSlice';
import unitYearAphReducer from './unitYearAphSlice';
import matricesReducer from './matricesSlice';
import matrixHeatMapDataReducer from './matrixHeatMapDataSlice';
import optionsReducer from './optionsSlice';
import unitGroupsReducer from './unitGroupsSlice';
import availabilityReducer from './availabilitySlice';
import scenarioAnalysisReducer from './scenarioAnalysisSlice';
import trendlineAnalysisReducer from './trendlineAnalysisSlice';
import historicalAnalysisReducer from './historicalAnalysisSlice';
import premiumBreakdownReducer from './premiumBreakdownSlice';
import applicationsReducers from './applicationsSlice';
import applicationSummaryReducer from './applicationWizardSummariesSlice';
import appTaskDocumentsReducer from './appTaskDocumentsSlice';
import appTaskReducer from './appTaskSlice';
import onlineReducer from './onlineSlice';
import hailReducer from './hailSlice';
import hailModalReducer from './hailModalSlice';
import scenarioPieceGroupsReducer from './scenarioPieceGroupsSlice';
import aipReducer from './aipSlice';
import agentCodeReducer from './agentCodeSlice';
import agentTeamsReducer from './agentTeamsSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import { getPersistConfig } from 'redux-deep-persist';
import forwardSoldScenarioPieceReducer from './forwardSoldScenarioPiecesSlice';
import harvestRevenueScenarioPiecesReducer from './harvestRevenueScenarioPiecesSlice';
import personsOfInterestReducer from './personOfInterestSlice';
import applicationZoomReducer from './applicationZoomSlice';
import validationsReducer from './validationsSlice';
import reportsReducer from './reportsSlice';
import appTaskStatusReducer from './appTaskStatusSlice';
import supportReducer from './supportSlice';
import agentReducer from './agentSlice';
import templateScenariosReducer from './templateScenariosSlice';

const rootReducer = combineReducers({
  online: onlineReducer,
  insureds: insuredsReducer,
  clientFiles: clientFilesReducer,
  quotes: quotesReducer,
  adm: admReducer,
  confirmation: confirmReducer,
  loader: loaderReducer,
  toast: toastReducer,
  appDrawer: appDrawerReducer,
  scenarios: scenariosReducer,
  units: unitsReducer,
  privateProducts: privateProductsReducer,
  intervals: intervalsReducer,
  harvestRevenueScenarioPieces: harvestRevenueScenarioPiecesReducer,
  scenarioPieces: scenarioPiecesReducer,
  inputCostScenarioPieces: inputCostScenarioPiecesReducer,
  userSettings: userSettingsReducer,
  calculationResults: calculationResultsReducer,
  unitYearAph: unitYearAphReducer,
  matrices: matricesReducer,
  matrixHeatMapData: matrixHeatMapDataReducer,
  options: optionsReducer,
  unitGroups: unitGroupsReducer,
  availability: availabilityReducer,
  scenarioAnalysis: scenarioAnalysisReducer,
  trendlineAnalysis: trendlineAnalysisReducer,
  historicalAnalysis: historicalAnalysisReducer,
  premiumBreakdown: premiumBreakdownReducer,
  applications: applicationsReducers,
  applicationSummaries: applicationSummaryReducer,
  appTasks: appTaskReducer,
  forwardSoldScenarioPieces: forwardSoldScenarioPieceReducer,
  hail: hailReducer,
  hailModal: hailModalReducer,
  personsOfInterest: personsOfInterestReducer,
  scenarioPieceGroups: scenarioPieceGroupsReducer,
  aips: aipReducer,
  agentCodes: agentCodeReducer,
  agentTeams: agentTeamsReducer,
  applicationZoom: applicationZoomReducer,
  appTaskDocuments: appTaskDocumentsReducer,
  validations: validationsReducer,
  reports: reportsReducer,
  appTaskStatuses: appTaskStatusReducer,
  support: supportReducer,
  agentInformation: agentReducer,
  templateScenarios: templateScenariosReducer,
});

const rootPersistConfig = getPersistConfig({
  key: 'primary',
  storage,
  whitelist: [
    'insureds.currentInsuredId',
    'clientFiles.currentClientFileId',
    'adm.data',
    'matrices.selectedMatrixPresetIdByMatrixId',
    'online.userNetworkEnabled',
    'online.lastOfflineSynchronizationIsoString',
    'online.lastOfflineBulkDataDownloadIsoString',
    'userSettings.paletteMode',
    'applicationZoom', // So that zoom settings are preserved on reload.
  ],
  rootReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType> = (dispatch: AppDispatch, getState: () => RootState) => ReturnType;
