import {
  ScenarioPieceType, ScenarioPieceGroupType
} from '@silveus/calculations';
import { MenuItem, Tooltip } from '@mui/material';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { ReactElement, ReactNode } from 'react';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { useScenarioPieceAvailability } from '../../../hooks/useScenarioPieceAvailability';

interface WrappedMenuItemProps {
  children: ReactNode;
  value: ScenarioPieceGroupType | ScenarioPieceType;
  disabled: boolean;
  tooltipProps: {
    title: string,
    placement: 'left' | 'top' | 'bottom' | 'right',
  };
}

const WrappedMenuItem = (props: WrappedMenuItemProps) => {
  const { tooltipProps, children, ...menuItemProps } = props;

  return (
    <Tooltip {...tooltipProps}>
      <span>
        <MenuItem {...menuItemProps}>{children}</MenuItem>
      </span>
    </Tooltip>
  );
};

export const useAvailableScenarioPieces = (scenarioId: ScenarioId, scenarioPieceTypeFilter: ScenarioPieceType[] = [], overrideRowCropScenarioPieces?: RowCropScenarioPiece[], useScenarioPieceGroups = true): ReactElement[] => {
  const scenarioPieces = useScenarioPieceAvailability(
    scenarioId,
    scenarioPieceTypeFilter,
    overrideRowCropScenarioPieces,
    useScenarioPieceGroups,
  );

  return scenarioPieces.map(availability => (
    <MenuItem
      component={WrappedMenuItem}
      key={availability.scenarioPieceType}
      value={availability.scenarioPieceType}
      disabled={availability.isDisabled}
      tooltipProps={{
        title: availability.tooltipMessage,
        placement: 'left',
      }}
    >
      {availability.description}
    </MenuItem>
  ));
};