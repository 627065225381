import { CoverageTypeCode } from '@silveus/calculations';
import { getCommodityCodeFromTypeId, getTypeCodeFromTypeId } from './adm';
import { isNotNullOrUndefined } from './nullHandling';
import { getInsurancePlanCodeVariationForAreaYield, getInsurancePlanCodeVariationForPrice } from './priceYieldVariationUtils';
import { ScenarioPricesAndYieldsParams } from '../types/api/adm/ScenarioPricesAndYieldsParams';
import { getScenarioPricesAndYieldsRequest } from '../services/requestInterception/admRequestInterceptor';

export const getAllApplicablePlanCodes = (typeId: string, planCodes: string[]): string[] => {
  const commodityCode = getCommodityCodeFromTypeId(typeId);
  const typeCode = getTypeCodeFromTypeId(typeId);
  const additionalPlanCodes = [getInsurancePlanCodeVariationForAreaYield(commodityCode, planCodes, typeCode)?.insurancePlanCode, getInsurancePlanCodeVariationForPrice(commodityCode, planCodes)?.insurancePlanCode]
    .filter((possibleVariation): possibleVariation is string => isNotNullOrUndefined(possibleVariation) && !planCodes.includes(possibleVariation));
  return [...planCodes, ...additionalPlanCodes];
};

export const getScenarioPricesAndYieldsForAllApplicablePlanCodes = async ({ year, countyId, typeId, practiceId, planCodes }:
  { year: number, countyId: string, typeId: string, practiceId: string, planCodes: string[] }) => {
  const insurancePlanCodes = getAllApplicablePlanCodes(typeId, planCodes);
  const scenarioPricesAndYieldsParams: ScenarioPricesAndYieldsParams = { year, countyId, typeId, practiceId, insurancePlanCodes: insurancePlanCodes, coverageTypeCode: CoverageTypeCode.A };
  const scenarioPricesAndYields = await getScenarioPricesAndYieldsRequest(scenarioPricesAndYieldsParams);
  return scenarioPricesAndYields;
};