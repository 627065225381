import { ScenarioPieceType } from '@silveus/calculations';
import { IntervalRange, IntervalType } from '../types/api/adm/IntervalRange';

const pieceTypeIntervalTypeRestrictions = new Map<ScenarioPieceType, IntervalType[]>([
  [ScenarioPieceType.Vip, [IntervalType.Monthly, IntervalType.BiWeekly, IntervalType.MidMonth]],
  [ScenarioPieceType.VipMp, [IntervalType.Monthly, IntervalType.BiWeekly, IntervalType.MidMonth]],
  [ScenarioPieceType.VipMpHpo, [IntervalType.Monthly, IntervalType.BiWeekly, IntervalType.MidMonth]],
  [ScenarioPieceType.MPowerD, [IntervalType.Monthly, IntervalType.BiWeekly]],
]);

export function filterIntervalsByScenarioPieceType(availableIntervals: IntervalRange[], scenarioPieceType: ScenarioPieceType) {
  const intervalTypeRestrictions = pieceTypeIntervalTypeRestrictions.get(scenarioPieceType);
  if (intervalTypeRestrictions === undefined) return [];
  return availableIntervals.filter(intervals => intervalTypeRestrictions.includes(intervals.intervalType));
}
