import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import { getCustomProductsQuoteSummaryData } from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getCustomProductsQuoteSummaryData';
import { ScenarioPieceType } from '@silveus/calculations';
import { validateMyEco } from '../../../../utils/validation/scenarioPieces/myEcoValidation';
import { createMyEcoData } from '../../../../services/calculations/myEco/myEcoDataTransformations';
import { MyEcoScenarioPieceForm } from '../../../../pages/scenarioPiece/myEco/myEcoScenarioPieceForm';
import getFullyQualifiedScenarioPieceName from '../../defaultDefinitions/scenarioPieceNameDefaults';
import { scenarioPieceCardChipDefault } from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { ScenarioPiece } from '../../../../types/api/ScenarioPiece';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

export const myEcoDefinition: ScenarioPieceDefinition = {
  validationFunction: validateMyEco,
  d365ProductType: ProductType.MyECO,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    return createMyEcoData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <MyEcoScenarioPieceForm {...props} selectedScenarioPieceType={props.selectedScenarioPieceType as ScenarioPieceType} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>} />;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getCustomProductsQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
    return getFullyQualifiedScenarioPieceName(scenarioPiece, true);
  },
  getScenarioPieceCardChips: scenarioPieceCardChipDefault,
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#CD853F',
};