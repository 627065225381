import { roundToPlaces } from '@silveus/calculations';
import { Nullable } from '../types/util/Nullable';

/** Performs rounding on a number only if the provided precision is not null.
 * If the precision is null, returns the original number.
 */
export const optionalRoundToPlaces = (numberToRound: number, placesToRoundTo: Nullable<number>): number => {
  if (placesToRoundTo === null) {
    return numberToRound;
  }

  return roundToPlaces(numberToRound, placesToRoundTo);
};