import { ScenarioId } from '../types/api/PrimaryKeys';
import HistoricalRevenue from '../types/api/adm/HistoricalRevenue';
import { useTrendLineYields } from './useTrendlineYields';
import { useAppSelector, useKeyMapSelector } from './reduxHooks';
import { selectScenarioById } from '../app/scenariosSlice';
import { orderByProperty } from '../utils/arrayUtils';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../app/scenarioPiecesSlice';
import { roundToPlaces, AcreageType } from '@silveus/calculations';
import { selectScenarioPieceCalculationsForScenario } from '../app/calculationResultsSlice';
import HistoricalProductRevenue from '../types/api/adm/HistoricalProductRevenue';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import { getFriendlyScenarioPieceName } from '../utils/scenarioPieceUtils';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import { useHistoricalPrices } from './useHistoricalPrices';

export const useHistoricalRevenue = (scenarioId: ScenarioId, startYear: number, endYear: number, acreageType: AcreageType): HistoricalRevenue[] => {
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  const historicalPriceData = useHistoricalPrices(scenarioId, startYear, endYear);
  const historicalYields = useTrendLineYields(scenarioId, startYear, endYear, acreageType);
  const allScenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioId);
  const scenarioPieceCalcResults = useAppSelector(state => selectScenarioPieceCalculationsForScenario(state, scenarioId));

  if (scenario === null) return stableEmptyArrayAsMutable<HistoricalRevenue>();

  const filteredCalcScenarioPieces = scenarioPieceCalcResults.filter(x => allScenarioPieces.find(sp => x.scenarioPieceType === sp.scenarioPieceType));

  const historicalRevenueProducts = filteredCalcScenarioPieces.map(scenarioPiece => {
    const scenarioPieceName = getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType);
    const associatedScenarioPiece = allScenarioPieces.find(sp => sp.scenarioPieceType === scenarioPiece.scenarioPieceType);

    if (associatedScenarioPiece === undefined) throw new Error();

    //Only add lower coverage level if it is not 0
    const lowerCoverageRevenue = scenarioPiece.lowerRevenueCoverageLevel === 0
      ? undefined
      : scenarioPiece.lowerRevenueCoverageLevel;

    const productYield: HistoricalProductRevenue = {
      productTitle: scenarioPieceName,
      lowerRevenueCoverageLevel: lowerCoverageRevenue,
      upperRevenueCoverageLevel: scenarioPiece.upperRevenueCoverageLevel,
    };

    return productYield;
  });

  const calculatedHistoricalRevenues: HistoricalRevenue[] = [];
  for (let year = startYear; year <= endYear; year++) {
    //Prices
    const historicalPrice = historicalPriceData.find(price => price.priceYear === year);
    const projectedPrice = historicalPrice?.basePrice ?? undefined;
    const harvestPrice = historicalPrice?.harvestPrice ?? undefined;
    const mpProjectedPrice = historicalPrice?.mpBasePrice ?? undefined;

    //Yields
    const historicalYield = historicalYields.find(areaYield => areaYield.year === year);
    const producerExpectedYield = historicalYield?.trendYield;
    const producerActualYield = historicalYield?.actualYield;
    const countyExpectedYield = historicalYield?.expectedCountyYield;
    const countyActualYield = historicalYield?.actualCountyYield;

    //Revenue
    const expectedRevenue = producerExpectedYield === undefined || projectedPrice === undefined ? undefined : roundToPlaces(producerExpectedYield * projectedPrice, 2);
    const actualRevenue = producerActualYield === undefined || harvestPrice === undefined ? undefined : roundToPlaces(producerActualYield * harvestPrice, 2);
    const expectedCountyRevenue = countyExpectedYield === undefined || projectedPrice === undefined ? undefined : roundToPlaces(countyExpectedYield * projectedPrice, 2);
    const actualCountyRevenue = countyActualYield === undefined || harvestPrice === undefined ? undefined : roundToPlaces(countyActualYield * harvestPrice, 2);
    const expectedMpRevenue = countyExpectedYield === undefined || mpProjectedPrice === undefined ? undefined : roundToPlaces(countyExpectedYield * mpProjectedPrice, 2);
    const actualMpRevenue = countyActualYield === undefined || mpProjectedPrice === undefined || harvestPrice === undefined ? undefined : actualCountyRevenue;

    calculatedHistoricalRevenues.push({
      year: year,
      expectedRevenue: expectedRevenue,
      actualRevenue: actualRevenue,
      expectedCountyRevenue: expectedCountyRevenue,
      actualCountyRevenue: actualCountyRevenue,
      expectedMpRevenue: expectedMpRevenue,
      actualMpRevenue: actualMpRevenue,
      productRevenues: historicalRevenueProducts,
    });

  }

  const sortedChartData = orderByProperty(calculatedHistoricalRevenues, DefaultOrders.historicalRevenue);
  return sortedChartData;
};
