import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import ApplicationWizardPage from './applicationWizardPage';
import { ApplicationWizardStep, ApplicationWizardStepProps } from './applicationWizardStep';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { Grid, Typography } from '@mui/material';
import YesNoQuestionInput from '../../../components/formInputs/applications/yesNoQuestionInput';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { Nullable } from '../../../types/util/Nullable';
import { useEffect } from 'react';
import { saveApplication } from '../../../app/applicationsSlice';
import { RootState } from '../../../app/store';
import NoToAllQuestionsInput from '../../../components/formInputs/applications/noToAllQuestionsInput';


export const ConditionsOfAcceptanceStep: ApplicationWizardStep = {
  isDisabled(app: ApplicationWizard) {
    return !app.anyChanges ||
      (!app.isGeneratingForms);
  },
  title: 'Conditions Of Acceptance',
  index: 8,
  formId: 'form-conditions-of-acceptance',
  icon: <FactCheckIcon />,
  isValid(app: ApplicationWizard, state: RootState) {
    if (this.isDisabled(app, state)) {
      return {
        isValid: true,
        errors: [],
      };
    }
    const areConditionsFilledOut =
      app.fciaDelinquency !== null &&
      app.controlledSubstanceConviction !== null &&
      app.fciaInsuranceTermination !== null &&
      app.disqualifiedOrDebarred !== null &&
      app.fciaAgreementDisqualification !== null &&
      app.currentLikeInsurance !== null;
    return {
      isValid: areConditionsFilledOut,
      errors: areConditionsFilledOut ? [] : ['Missing answers'],
    };
  },
};

export type ConditionsOfAcceptanceFormFields = {
  fciaDelinquency: Nullable<boolean>;
  controlledSubstanceConviction: Nullable<boolean>;
  fciaInsuranceTermination: Nullable<boolean>;
  disqualifiedOrDebarred: Nullable<boolean>;
  fciaAgreementDisqualification: Nullable<boolean>;
  currentLikeInsurance: Nullable<boolean>;
}

const ConditionsOfAcceptancePage = ({ application, navigateToPreviousStep, navigateToNextStep, updateApplication }: ApplicationWizardStepProps) => {

  const dispatch = useAppDispatch();
  const methods = useForm<ConditionsOfAcceptanceFormFields>({
    values: {
      ...application,
    },
  });
  const { formState } = methods;
  const isDirty = formState.isDirty;

  useEffect(() => {
    if (isDirty) {
      const updatedApplication: ApplicationWizard = { ...application, ...methods.getValues() };
      updateApplication(updatedApplication);
      // Reset the form's isDirty state so we can tell when the next change comes in
      methods.reset({}, { keepValues: true, keepDefaultValues: true });
    }
  }, [isDirty]);

  const saveInProgressWork: SubmitHandler<ConditionsOfAcceptanceFormFields> = async data => {
    const updatedApplication: ApplicationWizard = { ...application, ...data };
    await dispatch(saveApplication({ updatedApplication }));
    navigateToNextStep();
  };

  const handleNavigateToPrevious = async () => {
    const updatedApplication: ApplicationWizard = { ...application, ...methods.getValues() };
    await dispatch(saveApplication({ updatedApplication }));
    navigateToPreviousStep();
  };

  return (
    <FormProvider {...methods}>
      <form style={{ height: '100%' }} id="form-conditions-of-acceptance" onSubmit={methods.handleSubmit(saveInProgressWork)}>
        <ApplicationWizardPage tabIndex={ConditionsOfAcceptanceStep.index} onPreviousClick={handleNavigateToPrevious}>
          <Grid container alignItems="center" rowSpacing={3} columnSpacing={1} p={2} sx={{ maxWidth: '900px !important' }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">You must answer the following questions before proceeding</Typography>
            </Grid>
            <Grid item container justifyContent="flex-end">
              <Grid item width="50px">YES</Grid>
              <Grid item width="50px">NO</Grid>
            </Grid>
            <Grid item container>
              <NoToAllQuestionsInput />
              <YesNoQuestionInput
                question="Are you now indebted and the debt is delinquent for insurance coverage under the Federal Crop Insurance Act?"
                value={application.fciaDelinquency}
                id="fciaDelinquency"
              />
              <YesNoQuestionInput
                question="Have you in the last five years been convicted under federal or state law of planting, cultivating, crowing, producing, harvesting, or storing a controlled substance?"
                value={application.controlledSubstanceConviction}
                id="controlledSubstanceConviction"
              />
              <YesNoQuestionInput
                question="Have you ever had insurance coverage under the authority of the Federal Crop Insurance Act terminated for violations of the terms of the contract or regulations, or for failure to pay your delinquent debt?"
                value={application.fciaInsuranceTermination}
                id="fciaInsuranceTermination"
              />
              <YesNoQuestionInput
                question="Are you disqualified or debarred under the Federal Crop Insurance Act, the regulations of the Federal Crop Insurance Corporation, or the United States Department of Agriculture?"
                value={application.disqualifiedOrDebarred}
                id="disqualifiedOrDebarred"
              />
              <YesNoQuestionInput
                question="Have you ever entered into an agreement with the Federal Crop Insurance Corporation or with the Department of Justice that you would refrain from participating
                  in programs under the authority of the Federal Crop Insurance Act and that agreement is still effective?"
                value={application.fciaAgreementDisqualification}
                id="fciaAgreementDisqualification"
              />
              <YesNoQuestionInput
                question="Do you have like insurance on any of the above crop(s)?"
                value={application.currentLikeInsurance}
                id="currentLikeInsurance"
              />
            </Grid>
          </Grid>
        </ApplicationWizardPage>
      </form>
    </FormProvider>
  );
};

export default ConditionsOfAcceptancePage;