import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/system';
import { ReactElement } from 'react';
import { getInputCostTableStyles } from './inputCostTable.style';

export interface InputCostEntryDescriptionProps {
    tableBodyContents: ReactElement[];
    includeActionColumn: boolean;
}

const InputCostTableWrapper = ({ tableBodyContents, includeActionColumn }: InputCostEntryDescriptionProps) => {
  const theme = useTheme();
  const styles = getInputCostTableStyles(theme);
  return (
    <>
      <TableContainer sx={styles.tableContainer}>
        <Table size="small" stickyHeader sx={styles.table}>
          <TableHead sx={styles.header}>
            <TableRow>
              <TableCell sx={styles.headerCell}>Description</TableCell>
              <TableCell sx={styles.headerCell}>Cost</TableCell>
              {includeActionColumn && <TableCell sx={styles.actionHeaderCell}></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBodyContents}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InputCostTableWrapper;
