import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { selectAllStates } from '../../../app/admSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { Nullable } from '../../../types/util/Nullable';
import { selectEntityTypes } from '../../../app/insuredsSlice';
import { Insured } from '../../../types/api/insureds/Insured';
import { useEffect } from 'react';
import { EntityTypeEnum } from '../../../types/api/enums/application/entityType.enum';

interface CorporationStateInputProps {
  value: Nullable<string>;
  existingInsured: Nullable<Insured>;
  disabled?: boolean;
}

export type CorporationStateFields = {
  corporationState: Nullable<string>;
};

const CorporationStateInput = ({ value, existingInsured, disabled = false }: CorporationStateInputProps) => {
  const inputName = 'corporationState';
  const { control, setValue } = useFormContext();
  const states = useAppSelector(selectAllStates);
  const entityTypes = useAppSelector(selectEntityTypes);
  const corporationEntityType = entityTypes.find(x => x.name === EntityTypeEnum.Corporation);
  const llcEntityType = entityTypes.find(x => x.name === EntityTypeEnum.LimitedLiabilityCompany);

  const insuredEntityTypeId = useWatch({ name: 'entityTypeId', control: control, defaultValue: existingInsured?.entityTypeId });
  const isCorporationEntityType = insuredEntityTypeId === corporationEntityType?.entityTypeId || insuredEntityTypeId === llcEntityType?.entityTypeId;

  useEffect(() => {
    if (!isCorporationEntityType) {
      setValue(inputName, null);
    } else {
      setValue(inputName, value);
    }
  }, [isCorporationEntityType]);

  return (
    <Controller
      name={inputName}
      control={control}
      rules={{ required: isCorporationEntityType ? VALIDATION_RULES.required() : false }}
      defaultValue={value ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Corporation State"
          fullWidth
          select
          error={isCorporationEntityType && !!error}
          helperText={isCorporationEntityType && error?.message}
          inputRef={ref}
          {...field}
          disabled={disabled || !isCorporationEntityType}
          size="small"
        >
          {states.map(s => <MenuItem key={s.abbrev} value={s.abbrev ?? ''}>{s.name}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default CorporationStateInput;
