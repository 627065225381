import UnitAphForm from './unitAphForm.component';
import UnitForm from './unitForm.component';
import { UnitYearId, InsuredId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import TabbedFormWrapper from '../../../components/formWrapper/tabbedFormWrapper.component';
import { DefaultFormSelection, FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';

export type UnitFormWrapperProps = {
  unitYearId: Nullable<UnitYearId>;
  insuredId: InsuredId;
  defaultFormSelection?: DefaultFormSelection;
}

const UnitFormWrapper = ({ unitYearId, insuredId, defaultFormSelection }: UnitFormWrapperProps) => {
  const forms = [
    { formName: 'Unit Data', formComponent: (props: FormWrapperProps) => <UnitForm key={unitYearId} unitYearId={unitYearId} insuredId={insuredId} {...props} /> },
    { formName: 'APH', formComponent: (props: FormWrapperProps) => unitYearId !== null ? <UnitAphForm key={unitYearId} unitYearId={unitYearId} {...props} /> : <></> },
  ];

  return (
    <TabbedFormWrapper forms={forms} key={insuredId} defaultFormSelection={defaultFormSelection} />
  );
};

export default UnitFormWrapper;
