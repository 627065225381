import { Box, Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityOnIcon } from '@mui/icons-material';
import BaseLineData from '../../../types/api/baseLineData';

type ChartLegendProps<T extends BaseLineData> = {
  lineData: T[];
  onVisibleToggled: (lineData: T) => void;
  defaultKeys?: Set<string>;
  maxWidth?: string | undefined;
  displayTooltip?: boolean;
}

const ChartLegend = <T extends BaseLineData>({ lineData, onVisibleToggled, defaultKeys = new Set(), maxWidth, displayTooltip = false }: ChartLegendProps<T>) => {
  const theme = useTheme();
  // Always show default trendlines before the dynamic trendlines
  const sortedLineData = [
    ...lineData.filter(x => defaultKeys.has(x.dataKey)),
    ...lineData.filter(x => !defaultKeys.has(x.dataKey)),
  ];

  const getIconColor = (lineData: BaseLineData) => {
    return lineData.isVisible
      ? theme.palette.primary.dark
      : theme.palette.altText.disabled;
  };

  return (
    <Grid container pt={1} sx={{ minHeight: '120px', height: 'fit-content', overflowY: 'auto', maxWidth: maxWidth ?? 'auto' }}>
      {sortedLineData.map(x => (
        <Grid item container key={x.dataKey} alignItems="center" sx={{ paddingTop: '4px !important' }}>
          <Grid item xs={2}>
            <Box sx={{ backgroundColor: x.color, height: '20px', width: '20px' }} />
          </Grid>
          <Grid item xs={8}>
            <Tooltip title={displayTooltip ? x.name : undefined}>
              <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} variant="body1">{x.name}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              size="small"
              onClick={() => onVisibleToggled(x)}
              sx={{ color: getIconColor(x), paddingLeft: '8px' }}
            >
              {x.isVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ChartLegend;
