//FormInputParameterNames should probably be updated with "as const" after the curly braces.
//It will require some refactoring to make typsecript happy.
export const FormInputParameterNames = {
  LowerCoverageLevelName: 'lowerCoverageLevel',
  UpperCoverageLevelName: 'upperCoverageLevel',
  ProtectionFactor: 'protectionFactor',
  UnitStructure: 'unitStructure',
  SelectedIntervalsIds: 'selectedIntervalIds' as const,
  HighRiskTypeId: 'highRiskTypeId',
};

export const ExtendedDataFormInputParameterNames = {
  ProjectedPrice: 'rowCropScenarioPieceExtendedData.projectedPrice',
  ActualPrice: 'rowCropScenarioPieceExtendedData.actualPrice',
  CustomPremium: 'rowCropScenarioPieceExtendedData.customPremium',
  UnsubsidizedPremium: 'rowCropScenarioPieceExtendedData.unsubsidizedPremium',
  GetDiscountRate: 'rowCropScenarioPieceExtendedData.getDiscountRate',
  IsLiveQuote: 'rowCropScenarioPieceExtendedData.isLiveQuote',
  AreFieldsLocked: 'rowCropScenarioPieceExtendedData.areFieldsLocked',
  ExpectedInputCosts: 'rowCropScenarioPieceExtendedData.expectedInputCosts',
  ActualInputCosts: 'rowCropScenarioPieceExtendedData.actualInputCosts',
  DiscountAmount: 'rowCropScenarioPieceExtendedData.discountAmount',
  PriceChangeCap: 'rowCropScenarioPieceExtendedData.priceChangeCap',
  UnderlyingUpperCoverageLevel: 'rowCropScenarioPieceExtendedData.underlyingUpperCoverageLevel',
  UnderlyingScenarioPieceType: 'rowCropScenarioPieceExtendedData.underlyingScenarioPieceType',
  UnderlyingProtectionFactor: 'rowCropScenarioPieceExtendedData.underlyingProtectionFactor',
  TopPrice: 'rowCropScenarioPieceExtendedData.topPrice',
  IsInputCostEndorsement: 'rowCropScenarioPieceExtendedData.isInputCostEndorsement',
  MaxInputCostDecline: 'rowCropScenarioPieceExtendedData.maxInputCostDecline',
  Acres: 'rowCropScenarioPieceExtendedData.acres',
  PayableEntitiesAmount: 'rowCropScenarioPieceExtendedData.payableEntitiesAmount',
  ActualYield: 'rowCropScenarioPieceExtendedData.actualYield',
  ExpectedYield: 'rowCropScenarioPieceExtendedData.expectedYield',
  IsPrime: 'rowCropScenarioPieceExtendedData.isPrime',
  PrimeFloor: 'rowCropScenarioPieceExtendedData.primeDollarFloor',
  Aip: 'rowCropScenarioPieceExtendedData.aip',
} as const;
