import HailScenarioPiece from '../types/api/HailScenarioPiece';
import HailScenarioPieceComposition from '../types/api/HailScenarioPieceComposition';
import HailScenarioPieceEndorsement from '../types/api/hail/HailScenarioPieceEndorsement';
import HailScenarioPieceRate from '../types/api/hail/HailScenarioPieceRate';
import { HailScenarioPieceCompositionDTO } from '../types/api/hail/hailScenarioPieceCompositionDTO';
import { HailScenarioPieceDTO } from '../types/api/hail/hailScenarioPieceDTO';

export const getHailScenarioPieceCompositionFromDTO = (hailScenarioPieceCompositionDTO: HailScenarioPieceCompositionDTO): HailScenarioPieceComposition => {
  const hailScenarioPieceComposition: HailScenarioPieceComposition = {
    hailScenarioPieceCompositionId: hailScenarioPieceCompositionDTO.hailScenarioPieceCompositionId,
    scenarioId: hailScenarioPieceCompositionDTO.scenarioId,
    isActive: hailScenarioPieceCompositionDTO.isActive,
    offlineCreatedOn: hailScenarioPieceCompositionDTO.offlineCreatedOn,
    offlineLastUpdatedOn: hailScenarioPieceCompositionDTO.offlineLastUpdatedOn,
    offlineDeletedOn: hailScenarioPieceCompositionDTO.offlineDeletedOn,
  };
  return hailScenarioPieceComposition;
};

export const getHailScenarioPieceFromDTO = (hailScenarioPieceDTO: HailScenarioPieceDTO): HailScenarioPiece => {
  const hailScenarioPiece: HailScenarioPiece = {
    hailScenarioPieceId: hailScenarioPieceDTO.hailScenarioPieceId,
    hailScenarioPieceCompositionId: hailScenarioPieceDTO.hailScenarioPieceCompositionId,
    hailProductId: hailScenarioPieceDTO.hailProductId,
    hailPlanType: hailScenarioPieceDTO.hailPlanType,
    upperCoverageLevel: hailScenarioPieceDTO.upperCoverageLevel,
    lowerCoverageLevel: hailScenarioPieceDTO.lowerCoverageLevel,
    lossPercent: hailScenarioPieceDTO.lossPercent,
    discountPercent: hailScenarioPieceDTO.discountPercent,
    discountedRate: hailScenarioPieceDTO.discountedRate,
    coveragePerAcre: hailScenarioPieceDTO.coveragePerAcre,
    priceElectionPercent: hailScenarioPieceDTO.priceElectionPercent,
    budget: hailScenarioPieceDTO.budget,
    budgetPercent: hailScenarioPieceDTO.budgetPercent,
    includeInBlended: false,
    customRatePerAcre: hailScenarioPieceDTO.customRatePerAcre,
    customRatePerHundred: hailScenarioPieceDTO.customRatePerHundred,
    scenarioPieceId: hailScenarioPieceDTO.scenarioPieceId,
    scenarioId: hailScenarioPieceDTO.scenarioId,
    scenarioPieceType: hailScenarioPieceDTO.scenarioPieceType,
    isActive: hailScenarioPieceDTO.isActive,
    isInvalid: hailScenarioPieceDTO.isInvalid,
    offlineCreatedOn: hailScenarioPieceDTO.offlineCreatedOn,
    offlineLastUpdatedOn: hailScenarioPieceDTO.offlineLastUpdatedOn,
    offlineDeletedOn: hailScenarioPieceDTO.offlineDeletedOn,
  };
  return hailScenarioPiece;
};

export const getHailCompositionDTOSplitOut = (hailCompositionDTO: HailScenarioPieceCompositionDTO) => {
  const hailScenarioPieces: HailScenarioPiece[] = [];
  const hailScenarioPieceRates: HailScenarioPieceRate[] = [];
  const hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[] = [];

  hailCompositionDTO.hailScenarioPieces.forEach(hailScenarioPieceDTO => {
    const hailScenarioPiece = getHailScenarioPieceFromDTO(hailScenarioPieceDTO);
    hailScenarioPieces.push(hailScenarioPiece);

    hailScenarioPieceDTO.hailScenarioPieceRates.forEach(hailScenarioPieceRate => {
      hailScenarioPieceRates.push(hailScenarioPieceRate);
    });
    hailScenarioPieceDTO.hailScenarioPieceEndorsements.forEach(hailScenarioPieceEndorsement => {
      hailScenarioPieceEndorsements.push(hailScenarioPieceEndorsement);
    });
  });

  return {
    hailScenarioPieces,
    hailScenarioPieceRates,
    hailScenarioPieceEndorsements,
  };
};

export const getHailCompositionDTO = (
  hailScenarioPieceComposition: HailScenarioPieceComposition,
  hailScenarioPieces: HailScenarioPiece[],
  hailScenarioPieceRates: HailScenarioPieceRate[],
  hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[],
): HailScenarioPieceCompositionDTO => {
  const hailScenarioPieceDTOs: HailScenarioPieceDTO[] = hailScenarioPieces.map(hailScenarioPiece => {
    const applicableRates = hailScenarioPieceRates.filter(hspr => hspr.hailScenarioPieceId === hailScenarioPiece.hailScenarioPieceId);
    const applicableEndorsements = hailScenarioPieceEndorsements.filter(hspe => hspe.hailScenarioPieceId === hailScenarioPiece.hailScenarioPieceId);
    const hailScenarioPieceDTO: HailScenarioPieceDTO = {
      ...hailScenarioPiece,
      hailScenarioPieceRates: applicableRates,
      hailScenarioPieceEndorsements: applicableEndorsements,
    };
    return hailScenarioPieceDTO;
  });
  const hailScenarioPieceCompositionDTO: HailScenarioPieceCompositionDTO = {
    ...hailScenarioPieceComposition,
    hailScenarioPieces: hailScenarioPieceDTOs,
  };
  return hailScenarioPieceCompositionDTO;
};