import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { ColorResult } from 'react-color';
import Sketch from 'react-color/lib/components/sketch/Sketch';
import { useState } from 'react';

interface ColorPickerDialogProps {
  handleClose: () => void;
  handleApply: (color: string) => void;
  open: boolean;
  defaultColor?: string;
}

export const ColorPickerDialog = ({ defaultColor, handleClose, open, handleApply }: ColorPickerDialogProps) => {
  const [selectedColor, setSelectedColor] = useState(defaultColor);

  const handleChange = (color: ColorResult) => {
    setSelectedColor(color.hex);
  };

  const handleChangeCompleted = (color: ColorResult) => {
    handleApply(color.hex);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent sx={{ p: '16px' }}>
        <Sketch
          disableAlpha={true}
          width="350px"
          color={selectedColor}
          onChange={handleChange}
          onChangeComplete={handleChangeCompleted}
        />
      </DialogContent>
      <DialogActions>
        <Button id="btn-color-dialog-close" variant="contained" onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};