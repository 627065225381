import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../../types/util/Nullable';
import NumberTextField, { onNumericInputChange } from '../../../numberTextField/numberTextField.component';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

export interface FarmBillPayableEntitiesProps {
  payableEntities: Nullable<number>;
  disabled?: boolean;
  autoFocus?: boolean;
}

export const FarmBillPayableEntitiesInput = ({ payableEntities, disabled = false, autoFocus = false }: FarmBillPayableEntitiesProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.PayableEntitiesAmount}
      control={control}
      rules={{ required: VALIDATION_RULES.required(), min: VALIDATION_RULES.minimum(1), max: VALIDATION_RULES.maximum(255), maxLength: VALIDATION_RULES.maxLength(3) }}
      defaultValue={payableEntities ?? 1}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          type="number"
          label="Payable Entities"
          fullWidth
          inputProps={{ step: 1, min: 1 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          onChange={e => {
            onNumericInputChange(e, field, 0);
          }}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};
