import { Grid } from '@mui/material';
import ScenarioPieceTypeInput from './scenarioPieceTypeInput.component';
import { ScenarioPieceTypeSelected, SelectedScenarioPieceTypeFields } from '../../../pages/matrix/presets/matrixPresetForm.component';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { ScenarioPieceTypeOption } from '../../../utils/scenarioPieceUtils';


interface ScenarioPieceTypeSelectionProps {
  availableScenarioPieceTypeOptions: ScenarioPieceTypeOption[];
  disabled?: boolean;
}

const isScenarioPieceTypeSelectionValid = (selectedValues: ScenarioPieceTypeSelected[]) => selectedValues.some(sv => sv.selected);

const ScenarioPieceTypeSelection = ({ availableScenarioPieceTypeOptions, disabled }: ScenarioPieceTypeSelectionProps) => {
  const controlName = 'selectedScenarioPieceTypes';
  const numberPerColumn = Math.ceil(availableScenarioPieceTypeOptions.length / 2);
  const { control, formState } = useFormContext<SelectedScenarioPieceTypeFields>();
  const { fields } = useFieldArray<SelectedScenarioPieceTypeFields>({
    control: control,
    name: controlName,
    rules: {
      required: true,
      validate: value => isScenarioPieceTypeSelectionValid(value),
    },
  });

  const watchSelectedScenarioPieceTypes = useWatch({ name: controlName, control: control, defaultValue: fields });

  const createScenarioPieceTypeInputColumn = (
    start: number,
    end: number,
  ) => (
    fields.slice(start, end).map((field, index) => {
      return (
        <Grid item key={field.id}>
          <ScenarioPieceTypeInput
            index={start + index}
            scenarioPieceTypeOption={field}
            disabled={disabled}
            sx={{ whiteSpace: 'pre-wrap' }} />
        </Grid>
      );
    })
  );

  return (<Grid container sx={{
    border: formState.isSubmitted && !isScenarioPieceTypeSelectionValid(watchSelectedScenarioPieceTypes) ? 'solid red' : 'solid transparent',
  }}>
    <Grid item xs={6}>
      {createScenarioPieceTypeInputColumn(0, numberPerColumn)}
    </Grid>
    <Grid item xs={6}>
      {createScenarioPieceTypeInputColumn(numberPerColumn, availableScenarioPieceTypeOptions.length)}
    </Grid>
  </Grid>);
};

export default ScenarioPieceTypeSelection;