import {
  CalculationsCellDataOverlayChild,
  getMatrixHeatMappingPriceProbabilityPercentValue
} from '@silveus/calculations';
import { MatrixIncludeFilterType } from '../types/api/enums/matrixIncludeFilterType/MatrixIncludeFilterType.enum';
import {
  defaultMatrixIncludeFilter,
  defaultMatrixShowFilter,
  matrixBottomAxisLabel
} from '../pages/matrix/matrixDefaults';
import { MatrixShowFilterType } from '../types/api/enums/matrixShowFilterType/MatrixShowFilterType.enum';
import { BottomXAxisDefinition, CellBackgroundColorProvider, ValidColor } from '@silveus/react-matrix';
import { RowCropScenario } from '../types/api/RowCropScenario';
import Color from 'color';
import { MatrixOffsetType } from '../types/api/enums/matrixOffsetType/MatrixOffsetType.enum';

export const getMatrixHeatValue = (matrixPrice: number, currentPrice: number, volatility: number, daysUntilHarvestDiscoveryEnd: number): number => {
  return convertProbabilityToHeatValue(getMatrixHeatMappingPriceProbabilityPercentValue(matrixPrice, currentPrice, volatility, daysUntilHarvestDiscoveryEnd));
};

export const convertProbabilityToHeatValue = (probability: number) => {
  if (probability >= 0 && probability < 0.5) {
    return probability * 2;
  }
  if (probability >= 0.5 && probability <= 1.0) {
    return (1.0 - probability) * 2;
  }
  return 0.0;
};

export const mainOverlayChildValueSelector = (includeFilterParam: MatrixIncludeFilterType, showFilterParam: MatrixShowFilterType, child: CalculationsCellDataOverlayChild) => {
  // It's possible these values will be unset - assume defaults so that matrices before these settings existed will continue to function.
  const includeFilter = includeFilterParam === MatrixIncludeFilterType.Unset ? defaultMatrixIncludeFilter : includeFilterParam;
  const showFilter = showFilterParam === MatrixShowFilterType.Unset ? defaultMatrixShowFilter : showFilterParam;

  if (includeFilter === MatrixIncludeFilterType.Net) {
    if (showFilter === MatrixShowFilterType.Total) {
      return child.net;
    } else if (showFilter === MatrixShowFilterType.PerAcre) {
      return child.netPerAcre;
    }
  } else if (includeFilter === MatrixIncludeFilterType.Gross) {
    if (showFilter === MatrixShowFilterType.Total) {
      return child.gross;
    } else if (showFilter === MatrixShowFilterType.PerAcre) {
      return child.grossPerAcre;
    }
  }

  // This would only happen if the above logic was not properly exhaustive.
  return 0;
};

export function createCellBackgroundColorProvider(includedScenarios: RowCropScenario[]): CellBackgroundColorProvider {
  return (context): ValidColor | undefined => {
    // If we have only attached one scenario (or zero) to the matrix then there is no reason to
    // specify background cell color and we can return undefined here for no background color
    if (includedScenarios.length <= 1) {
      return undefined;
    }

    const allOverlayValues = context.overlayData.map(d => d.mainValue);
    const min = Math.min(...allOverlayValues);
    const max = Math.max(...allOverlayValues);
    const spread = max - min;

    // ToDo: We need a user input control for `if values are within X $ maintain neutral` so we don't need to show a background color`
    // Below is an example of how we can use that input value.
    if (spread <= 5) {
      return undefined;
    }

    const matchingOverlayData = context.overlayData.find(o => o.mainValue === context.cellValueAsNumber);

    if (matchingOverlayData !== undefined) {
      const scenario = includedScenarios.find(sc => sc.scenarioId === matchingOverlayData.overlay.overlayId);

      if (scenario !== undefined) {
        const backgroundColor = Color(scenario.scenarioColor);
        const withAlpha = backgroundColor.alpha(0.35);
        return withAlpha.rgb().string();
      }
    }

    return undefined;
  };
}

export function getBottomXAxisDefinition(
  bottomAxisOffsetType: MatrixOffsetType,
  bottomAxisPercentChange: number,
  bottomAxisIntegerOffset: number): BottomXAxisDefinition | undefined {
  const bottomAxisLabel = matrixBottomAxisLabel;
  const isBottomAxisEnabled = bottomAxisOffsetType !== MatrixOffsetType.Unset;
  return isBottomAxisEnabled ? {
    axisLabel: bottomAxisLabel,
    valueRelationToTopAxis: topXAxisValue => bottomAxisOffsetType === MatrixOffsetType.PercentChange
      ? topXAxisValue - (topXAxisValue * bottomAxisPercentChange / 100)
      : topXAxisValue - bottomAxisIntegerOffset,
  } : undefined;
}