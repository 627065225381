import { UnitOfMeasure } from '@silveus/calculations';

/** Given the unit of measure, returns the rounding precision expected for yield fields. */
export const getRoundingPrecision = (unitOfMeasure: UnitOfMeasure) => {
  switch (unitOfMeasure) {
    case UnitOfMeasure.Barrel:
    case UnitOfMeasure.Ton:
      return 1;
    default:
      return 0;
  }
};