import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import validationFunctionDefault from '../../defaultDefinitions/validationFunctionDefault';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import { createArpData } from '../../../../services/calculations/arp/arpDataTransformations';
import { createArpHpeData } from '../../../../services/calculations/arpHpe/arpHpeDataTransformations';
import { ArpScenarioPieceForm } from '../../../../pages/scenarioPiece/arp/arpScenarioPieceForm.componment';
import { ArpHpeScenarioPieceForm } from '../../../../pages/scenarioPiece/arphpe/arphpeScenarioPieceForm.componment';
import {
  getAreaQuoteSummaryData
} from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getAreaQuoteSummaryData';
import { ScenarioPieceType } from '@silveus/calculations';
import { getFullyQualifiedScenarioPieceNameOnlyUpperCoverageLevel
} from '../../defaultDefinitions/scenarioPieceNameDefaults';
import {
  ProtectionFactorScenarioPieceChip
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

const baseDefinition: ScenarioPieceDefinition = {
  validationFunction: validationFunctionDefault,
  d365ProductType: ProductType.MPCI,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, _aphYield, _shouldSimulateUnitGroups) => {
    return createArpData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <ArpScenarioPieceForm {...props} selectedScenarioPieceType={props.selectedScenarioPieceType as ScenarioPieceType} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}/>;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getAreaQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceNameOnlyUpperCoverageLevel,
  getScenarioPieceCardChips: [ProtectionFactorScenarioPieceChip],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#6495ED',
};

export const arpDefinition: ScenarioPieceDefinition = {
  ...baseDefinition,
};

export const arpHpeDefinition: ScenarioPieceDefinition = {
  ...baseDefinition,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, _aphYield, _shouldSimulateUnitGroups) => {
    return createArpHpeData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <ArpHpeScenarioPieceForm {...props} selectedScenarioPieceType={props.selectedScenarioPieceType as ScenarioPieceType} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}/>;
  },
};
