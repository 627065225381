import { useState } from 'react';
import { AppTask } from '../../../../types/api/AppTask';
import { Collapse, IconButton, Table, TableBody, TableHead, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectAdmData } from '../../../../app/admSlice';
import { getStateAbbrev } from '../../../../utils/adm';
import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import AppTypeInput from '../../../../components/formInputs/applications/appTypeInput';
import AIPInput from '../../../../components/formInputs/applications/aipInput';
import DesignatedCountyInput from '../../../../components/formInputs/applications/designatedCountyInput';
import DesignatedCountyElectionInput from '../../../../components/formInputs/applications/designatedCountyElectionInput';
import { AppTableCell, AppTableCellForInput, AppTableHeader } from './applicationDecisionGrid';
import { useWatch } from 'react-hook-form';
import AppTaskCoverageRow from './appTaskCoverageRow';
import { selectInsuredById } from '../../../../app/insuredsSlice';
import AgentCodeInput from '../../../../components/formInputs/applications/agentCodeInput';
import PreviousPolicyInput from '../../../../components/formInputs/applications/previousPolicyInput';
import { ApplicationWizard } from '../../../../types/api/applicationWizard/applicationWizard';
import InsuringLandlordShareInput from '../../../../components/formInputs/applications/insuringLandlordShareInput';
import PreviousAIPInput from '../../../../components/formInputs/applications/previousAipInput';
import { SalesClosingDate } from '../../../../types/api/applicationWizard/salesClosingDate';
import SalesClosingDateInput from '../../../../components/formInputs/applications/salesClosingDateInput';
import IsNewCTInput from '../../../../components/formInputs/applications/isNewCTInput';
import { AppTaskStatusAttributes } from '../../../../types/api/enums/application/appTaskStatus.enum';
import { isAppTaskEditable } from './appTaskUtils';
import { selectIsLightMode } from '../../../../app/userSettingsSlice';
import AppTaskOptionsButton from './appTaskOptionsButton';
import PolicyInput from '../../../../components/formInputs/applications/PolicyInput';
import { isNotNullOrUndefined } from '../../../../utils/nullHandling';
import AgencyInput from '../../../../components/formInputs/applications/agencyInput';
import { AgencyInformation } from '../../../../types/api/agencyInformation';

type AppTaskRowProps = {
  appTask: AppTask;
  index: number;
  onAssignClick: (appTask: AppTask) => void;
  application: ApplicationWizard;
  salesClosingDates: SalesClosingDate[];
  cropYear: number;
  agencies: AgencyInformation[];
}


const AppTaskRow = ({ appTask, index, onAssignClick, application, salesClosingDates, cropYear, agencies }: AppTaskRowProps) => {
  const [open, setOpen] = useState(true);
  const admData = useAppSelector(selectAdmData);
  const isLightMode = useAppSelector(selectIsLightMode);

  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });
  const canEditAppTask = isAppTaskEditable(currentAppTask);
  const insured = useAppSelector(state => selectInsuredById(state, appTask.insuredId));

  const getStateInfo = () => {
    if (isNotNullOrUndefined(appTask.countyCode)) {
      return getStateAbbrev(admData, cropYear, appTask.countyCode);
    } else if (appTask.coverages.length > 0 && isNotNullOrUndefined(appTask.coverages[0].countyCode)) {
      return getStateAbbrev(admData, cropYear, appTask.coverages[0].countyCode);
    } else {
      return '';
    }
  };
  return (
    <React.Fragment>
      {insured && (
        <>
          <TableRow className={isLightMode ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'} sx={{ '&:hover': { backgroundColor: 'var(--ag-row-hover-color)' } }}>
            <AppTableCell style={{ width: '20px', paddingRight: 0, paddingLeft: 0 }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </AppTableCell>
            <AppTableCell width={45}>{getStateInfo()}</AppTableCell>
            <AppTableCell>{ProductType[appTask.productType]}</AppTableCell>
            <AppTableCell>{AppTaskStatusAttributes[appTask.appTaskStatus].name}</AppTableCell>
            <AppTableCellForInput><AppTypeInput value={appTask.appType} index={index} application={application} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCellForInput><IsNewCTInput value={appTask.isNewCT} index={index} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCellForInput><SalesClosingDateInput index={index} salesClosingDates={salesClosingDates} value={appTask.salesClosingDateId} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCellForInput><AIPInput value={appTask.aipId} index={index} originalAIP={appTask.aipId} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCellForInput><AgentCodeInput value={appTask.agentCodeId} index={index} agentTeamId={insured.agentTeamId} originalAIP={appTask.aipId} originalAgentCodeId={appTask.agentCodeId} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCellForInput><AgencyInput agencies={agencies} value={appTask.agencyId} originalAgencyId={appTask.agencyId} index={index} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCellForInput><PolicyInput value={appTask.policyNumber} originalPolicyNumber={appTask.policyNumber} index={index} /></AppTableCellForInput>
            <AppTableCellForInput><PreviousAIPInput value={appTask.previousAIPId} index={index} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCellForInput><PreviousPolicyInput value={appTask.previousPolicyNumber} index={index} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCellForInput><DesignatedCountyInput value={appTask.isDesignatedCounty} index={index} application={application} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCellForInput><DesignatedCountyElectionInput value={appTask.designatedCountyElection} index={index} application={application} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCellForInput><InsuringLandlordShareInput value={appTask.isInsuringLandlordShare} name="isInsuringLandlordShare" index={index} application={application} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCellForInput><InsuringLandlordShareInput value={appTask.isInsuringTenantShare} name="isInsuringTenantShare" index={index} application={application} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
            <AppTableCell sx={{ p: 0 }} width="16px"><AppTaskOptionsButton index={index} canEditAppTask={canEditAppTask} onAssignClick={onAssignClick} /></AppTableCell>
          </TableRow>
          <TableRow>
            <AppTableCell colSpan={19} sx={{ pl: 4, pt: 1.5, pb: 0, pr: 4 }}>
              <Collapse in={open} timeout="auto" unmountOnExit sx={{ p: 0 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <AppTableCell sx={{ backgroundColor: 'var(--ag-header-background-color)' }}  width="30px" />
                      <AppTableHeader sx={{ minWidth: '120px' }}>County</AppTableHeader>
                      <AppTableHeader sx={{ minWidth: '80px' }}>Coverage Type</AppTableHeader>
                      <AppTableHeader sx={{ minWidth: '70px' }}>Des. County</AppTableHeader>
                      <AppTableHeader sx={{ minWidth: '70px' }}>New Prod</AppTableHeader>
                      <AppTableHeader sx={{ minWidth: '80px' }}>Crop</AppTableHeader>
                      <AppTableHeader sx={{ minWidth: '80px' }}>Plan</AppTableHeader>
                      <AppTableHeader sx={{ minWidth: '80px' }}>Level</AppTableHeader>
                      <AppTableHeader sx={{ minWidth: '80px' }}>Practice</AppTableHeader>
                      <AppTableHeader sx={{ minWidth: '70' }}>Type</AppTableHeader>
                      <AppTableHeader sx={{ minWidth: '70' }}>Unit Structure</AppTableHeader>
                      <AppTableHeader sx={{ minWidth: '80px' }}>Options</AppTableHeader>
                      <AppTableHeader align="right">Int Net Acres</AppTableHeader>
                      <AppTableHeader align="right">Est Gross Premium</AppTableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appTask.coverages.map((coverage, coverageIndex) => (
                      <AppTaskCoverageRow appTask={appTask} coverage={coverage} key={coverage.coverageId} appTaskIndex={index} coverageIndex={coverageIndex} application={application} canEditAppTask={canEditAppTask} cropYear={cropYear} />
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </AppTableCell>
          </TableRow>
        </>
      )}
    </React.Fragment>
  );
};

export default AppTaskRow;