import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppTask } from '../../../../types/api/AppTask';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectAllAIPs } from '../../../../app/aipSlice';
import { getCountyName, getStateName } from '../../../../utils/adm';
import { selectAdmData } from '../../../../app/admSlice';
import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { isNullOrUndefined } from '../../../../utils/nullHandling';


type UnmatchedAppTasksModalProps = {
  appTasks: AppTask[];
  onClose: () => void;
}

const UnmatchedAppTasksModal = ({ appTasks, onClose }: UnmatchedAppTasksModalProps) => {
  const aips = useAppSelector(selectAllAIPs);
  const admData = useAppSelector(selectAdmData);

  return (
    <Dialog open onClose={onClose} maxWidth="sm">
      <DialogTitle>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs><Typography variant="h6">Unmatched App Tasks</Typography></Grid>
          <Grid item>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ flexDirection: 'column', overflow: 'auto' }}>
        <DialogContentText>Some app tasks did not match the selected coverage decisions. You must either include those coverage decisions or address them in another app wizard after completing this one.  </DialogContentText>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size="small" sx={{ fontWeight: 600 }}>State</TableCell>
                <TableCell size="small" sx={{ fontWeight: 600 }}>County</TableCell>
                <TableCell size="small" sx={{ fontWeight: 600 }}>Product</TableCell>
                <TableCell size="small" sx={{ fontWeight: 600 }}>AIP</TableCell>
                <TableCell size="small" sx={{ fontWeight: 600 }}>Policy #</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appTasks.map(appTask =>
                <TableRow key={appTask.appTaskId}>
                  <TableCell size="small">{!isNullOrUndefined(appTask.countyCode) ? getStateName(admData, appTask.cropYear, appTask.countyCode) : ''}</TableCell>
                  <TableCell size="small">{!isNullOrUndefined(appTask.countyCode) ? getCountyName(admData, appTask.cropYear, appTask.countyCode) : ''}</TableCell>
                  <TableCell size="small">{ProductType[appTask.productType]}</TableCell>
                  <TableCell size="small">{aips.find(x => x.aipId === appTask.aipId)?.aipName}</TableCell>
                  <TableCell size="small">{appTask.previousPolicyNumber}</TableCell>
                </TableRow>,
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog >
  );
};

export default UnmatchedAppTasksModal;