import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { changeCurrentClientFile, changeCurrentClientFileOwner, synchronizeStateWithRouteContext } from '../app/routeDataThunks';
import { AgentTeamId, ClientFileId, InsuredId } from '../types/api/PrimaryKeys';
import { useAppDispatch } from './reduxHooks';
import { Nullable } from '../types/util/Nullable';
import { ClientFileOwnership } from '../types/clientFile/ClientFileOwnership';

export function useRestorePersistedContext() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  // Note on why this is being pulled in: based on current architecture, if these calls fire before auth is ready, they will all fail.
  // For now, wait until the user is authenticated before trying to restore a context.
  const isAuthenticated = useIsAuthenticated();

  // This truly only should fire ONCE per app run, specifically at startup.
  useEffect(() => {
    if (isAuthenticated) {
      const pathMatchParams = getPathMatchParams(pathname);
      const { clientFileId, ...clientFileOwnership } = { ...pathMatchParams };

      dispatch(synchronizeStateWithRouteContext({
        clientFileOwnership,
        clientFileId: clientFileId ?? null,
      }));
    }
  }, [isAuthenticated]);
}

/**
 * Since we cannot use useParams we need a way to pull parameters from the current location's pathname.
 * This function will try and get the insured id and client file id from the pathname and return them if they
 * exist, otherwise they will be null.
 */
function getPathMatchParams(pathname: string) {
  const insuredClientFileMatch = matchPath('/insureds/:insuredId/clientFiles/:clientFileId/*', pathname);
  if (insuredClientFileMatch?.params) {
    const { clientFileId, insuredId } = insuredClientFileMatch.params;
    return { insuredId: insuredId as InsuredId, agentTeamId: undefined, clientFileId: clientFileId as ClientFileId };
  }

  const agentTeamClientFileMatch = matchPath('/agent-teams/:agentTeamId/clientFiles/:clientFileId/*', pathname);
  if (agentTeamClientFileMatch?.params) {
    const { agentTeamId, clientFileId } = agentTeamClientFileMatch.params;
    return { insuredId: undefined, agentTeamId: agentTeamId as AgentTeamId, clientFileId: clientFileId as ClientFileId };
  }

  const insuredsMatch = matchPath('/insureds/:insuredId/*', pathname);
  if (insuredsMatch) {
    const { insuredId } = insuredsMatch.params;
    return { insuredId: insuredId as InsuredId, agentTeamId: undefined, clientFileId: undefined };
  }

  const agentTeamMatch = matchPath('/agent-teams/:agentTeamId/*', pathname);
  if (agentTeamMatch?.params) {
    const { agentTeamId } = agentTeamMatch.params;
    return { insuredId: undefined, agentTeamId: agentTeamId as AgentTeamId, clientFileId: undefined };
  }
  return { insuredId: undefined, agentTeamId: undefined, clientFileId: undefined };
}

export function useSetInsuredContext(insuredId: InsuredId) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeCurrentClientFileOwner({ clientFileOwnership: { insuredId } }));
  }, [insuredId]);
}

export function useSetClientFileContext(clientFileId: Nullable<ClientFileId>, clientFileOwnership: ClientFileOwnership) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeCurrentClientFile({ clientFileId, clientFileOwnership }));
  }, [clientFileId, clientFileOwnership]);
}