import HipCalculationParamsRequest from '../../../types/api/calculationData/hipCalculationParamsRequest';
import HipCalculationParams from '../../../types/api/calculationData/hipCalculationParams';
import { admDb } from '../../../db';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import { ProductOfferAvailabilityParams } from './OfferAvailability';
import {
  CoverageLevelDifferential, CoverageTypeCode, OptionRate,
  ProductOfferAvailabilities,
  ScenarioPieceType
} from '@silveus/calculations';
import {
  getCoverageLevelDifferentials, getHipBaseRate,
  getInsuranceOfferAvailability,
  getInsuranceOfferInformation, getMultipleCommodityAdjustmentFactor, getOptionRates,
  getSubsidyPercent
} from '../../localCalcDataQueries.service';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { getHipCalcData } from '../../calculationData.service';

export const getHipCalcDataRequest = async (calcParamRequest: HipCalculationParamsRequest): Promise<HipCalculationParams[]> => {
  const request = () => getHipCalcData(calcParamRequest);

  const admTransactionTables = [
    admDb.subsidies,
    admDb.insuranceOffers,
    admDb.coverageLevelDifferentials,
    admDb.hipRates,
    admDb.optionRates,
  ];

  const readTransaction = async (): Promise<HipCalculationParams[]> => {
    const hipResponse: HipCalculationParams[] = [];

    const multipleCroppingCode = 'NS';
    const conservationComplianceSubsidyReductionPercent = 0;

    const unitTransactions = calcParamRequest.distinctUnits.map(async distinctUnit => {
      const admData = await admDb.transaction('r', admTransactionTables, async () => {
        const subsidyPercent = getSubsidyPercent(calcParamRequest.commodityCode, calcParamRequest.unitStructureCode, calcParamRequest.planCode, calcParamRequest.upperCoverageLevel, calcParamRequest.coverageTypeCode);

        const insuranceOffer = await getInsuranceOfferInformation(calcParamRequest.planCode, calcParamRequest.countyId, distinctUnit.typeId, distinctUnit.practiceId, calcParamRequest.coverageTypeCode);

        if (insuranceOffer === null || insuranceOffer.hipRateId === null) return null;

        const baseRate = getHipBaseRate(calcParamRequest.year, insuranceOffer.hipRateId);
        const coverageLevelDifferentials = getCoverageLevelDifferentials(insuranceOffer.insuranceOfferId, calcParamRequest.coverageTypeCode, distinctUnit.subCountyCode);
        const optionRates = getOptionRates(insuranceOffer.insuranceOfferId);

        await Promise.all([coverageLevelDifferentials, subsidyPercent, optionRates, baseRate]);

        return {
          baseRate: await baseRate,
          coverageLevelDifferentials: await coverageLevelDifferentials,
          subsidyPercent: await subsidyPercent,
          optionRates: await optionRates,
        };
      });

      if (admData === null) return;

      const multipleCommodityAdjustmentFactor = await getMultipleCommodityAdjustmentFactor(multipleCroppingCode);

      const hipCalculationParams: HipCalculationParams = {
        baseRate: admData.baseRate,
        conservComplSubsidyReductionPercent: conservationComplianceSubsidyReductionPercent,
        coverageLevelDifferentials: admData.coverageLevelDifferentials as CoverageLevelDifferential[],
        coverageTypeCode: calcParamRequest.coverageTypeCode as CoverageTypeCode,
        multipleCommodityAdjustmentFactor: multipleCommodityAdjustmentFactor,
        optionRates: admData.optionRates as OptionRate[],
        subsidyPercent: admData.subsidyPercent,
        unit: distinctUnit,
      };

      hipResponse.push(hipCalculationParams);
    });

    await Promise.all(unitTransactions);

    return hipResponse;
  };

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getOfferAvailability = async (productOfferAvailabilityParams: ProductOfferAvailabilityParams): Promise<ProductOfferAvailabilities[]> => {
  const scenarioPieceTypes: ScenarioPieceType[] = [ScenarioPieceType.Hip];

  const offerAvailabilityPromises = scenarioPieceTypes.map(scenarioPieceType => {
    return getInsuranceOfferAvailability(
      productOfferAvailabilityParams.year,
      productOfferAvailabilityParams.countyId,
      productOfferAvailabilityParams.commodityCode,
      getInsurancePlanCodeForScenarioPiece(scenarioPieceType),
      scenarioPieceType);
  });

  return Promise.all(offerAvailabilityPromises);
};
