import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { HipScenarioPieceFormFields } from '../../../pages/scenarioPiece/hip/hipScenarioPieceForm.component';
import { Nullable } from '../../../types/util/Nullable';

export interface HurricaneEventProps {
  hurricaneEventOccurred: Nullable<boolean>;
  autoFocus?: boolean;
  disabled?: boolean;
}

export const HurricaneEventInput = ({ hurricaneEventOccurred, autoFocus = false, disabled = false }: HurricaneEventProps) => {
  const { control } = useFormContext<HipScenarioPieceFormFields>();

  return (
    <Controller
      name="rowCropScenarioPieceExtendedData.hurricaneEventOccurred"
      control={control}
      defaultValue={hurricaneEventOccurred ?? false}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControlLabel
          label={<Typography variant="body2">Hurricane Event</Typography>}
          control={
            <Checkbox
              {...field}
              size="small"
              disabled={disabled}
              checked={value}
              inputRef={ref}
              onChange={e => field.onChange(e.target.checked)}
              autoFocus={autoFocus}
            />
          }
        />
      )}
    />
  );
};