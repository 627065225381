import { OptionCode, ScenarioPieceResponseDTO, ScenarioPieceType } from '@silveus/calculations';
import { PlanBaseType, QuoteSummaryScenarioPiece } from '../../../../../types/api/reports/QuoteSummaryScenarioPiece';
import { RowCropScenarioPiece } from '../../../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../../../types/util/Nullable';
import { RowCropScenario } from '../../../../../types/api/RowCropScenario';
import { getBaseReportScenarioPiece } from '../../getBaseReportScenarioPiece';
import { getFriendlyScenarioPieceName } from '../../../../../utils/scenarioPieceUtils';

export const getVipQuoteSummaryData = (
  scenario: RowCropScenario,
  scenarioPiece: RowCropScenarioPiece,
  scenarioPieceCalcData: Nullable<ScenarioPieceResponseDTO>,
  scenarioOptions: OptionCode[],
  projectedPrice: number,
  isProjectedPriceSet: boolean,
  approvedYield: Nullable<number>,
  adjustedYield: Nullable<number>): QuoteSummaryScenarioPiece[] => {

  const baseReportScenarioPiece = getBaseReportScenarioPiece(scenario, scenarioPiece, scenarioPieceCalcData, scenario.expectedCountyYield, approvedYield, adjustedYield);

  return [{
    ...baseReportScenarioPiece,
    displayPlanName: `${getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType)} - ${getFriendlyScenarioPieceName(scenarioPiece.rowCropScenarioPieceExtendedData?.underlyingScenarioPieceType ?? ScenarioPieceType.Unset)}`,
    hailEndorsements: [],
    hailRate: null,
    isProjectedPriceSet: false,
    options: [],
    planBaseType: PlanBaseType.Basic,
    projectedPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.topPrice ?? null,
    protectionFactor: scenarioPiece.protectionFactor / 100,
    taYield: null,
    unitStructure: '',
    yield: null,
    calculationData: {
      ...baseReportScenarioPiece.calculationData,
      triggerYield: null,
    },
  }];
};
