import { Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { currentlySelectedScenario } from '../../../app/scenariosSlice';
import 'react-resizable/css/styles.css';
import { selectQuoteById } from '../../../app/quotesSlice';
import { AgGridReact } from 'ag-grid-react';
import { DefaultColDefinition, getColumnDefinitions } from './hailUnitGridColumns';
import { Grid } from '@mui/material';
import { selectUnitYears } from '../../../app/scenariosSlice';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import UnitYear from '../../../types/api/UnitYear';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import HailScenarioPieceRate from '../../../types/api/hail/HailScenarioPieceRate';
import HailRate from '../../../types/api/hail/hailRate';
import { ScenarioPieceResponseDTO, UnitResponseDTO, sum } from '@silveus/calculations';
import { Quote } from '../../../types/api/Quote';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { calculateSummaryHailRate, getHailRatesByTownshipRange } from '../utils/hailRateUtils';
import { stableEmptyArrayAsMutable } from '../../../utils/stableEmptyArray';
import { getScenarioPieceRateCalculatedValuesWhenUnitQuoting } from '../utils/hailAcresUtils';
import { selectIsLightMode } from '../../../app/userSettingsSlice';
import { MuiDialogCloseReason } from '../../../types/mui/MuiDialogCloseReason';
import ScenarioModalHeader from '../../../components/modal/scenarioModalHeader';
import HailModalHeaderControls from '../hailModalHeaderControls';

type HailUnitsModalProps = {
  hailScenarioPiece: HailScenarioPiece;
  hailScenarioPieceUnits: UnitYearId[];
  hailScenarioPieceRates: HailScenarioPieceRate[];
  availableHailRates: HailRate[];
  onCloseClicked: () => void;
  onHailScenarioPieceUnitsUpdated: (quote: Quote, scenario: RowCropScenario, hailScenarioPiece: HailScenarioPiece, updatedUnitSelection: UnitYear[]) => void;
  showModal: boolean;
  hailProductIndex: number;
  calculatedValue: ScenarioPieceResponseDTO | undefined;
  calculatedUnitValues: UnitResponseDTO[];
}

const HailUnitsModal = ({ hailScenarioPiece, hailScenarioPieceUnits, hailScenarioPieceRates, availableHailRates, onCloseClicked, onHailScenarioPieceUnitsUpdated, showModal, hailProductIndex, calculatedValue, calculatedUnitValues }: HailUnitsModalProps)  => {
  const isLightMode = useAppSelector(selectIsLightMode);
  const scenario = useAppSelector(currentlySelectedScenario);
  const unitYears = useAppSelector(state => scenario !== null ? selectUnitYears(state, scenario.quoteId, scenario.typeId, scenario.practiceId, scenario.highRiskTypeId) : stableEmptyArrayAsMutable<UnitYear>());
  if (!scenario) throw new Error('Trying to quote hail without a selected scenario');
  const quote = useAppSelector(state => selectQuoteById(state, scenario.quoteId));
  if (quote === null) throw new Error('Hail Township Range Modal cannot find quote for scenario');


  const handleClose = (reason?: MuiDialogCloseReason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onCloseClicked();
  };

  const toggleHailUnitSelected = (unitYearId: UnitYearId) => {
    const newSelectedUnitIds = [...hailScenarioPieceUnits];
    const index = newSelectedUnitIds.findIndex(x => x === unitYearId);
    const unitNotFoundInSelected = index < 0;

    if (unitNotFoundInSelected) {
      newSelectedUnitIds.push(unitYearId);
    } else {
      newSelectedUnitIds.splice(index, 1);
    }
    const newSelectedUnits = unitYears.filter(x => newSelectedUnitIds.includes(x.unitYearId));
    onHailScenarioPieceUnitsUpdated(quote, scenario, hailScenarioPiece, newSelectedUnits);
  };

  const hailRatesByTownshipRange = getHailRatesByTownshipRange(availableHailRates);
  const columnDefinitions = getColumnDefinitions(toggleHailUnitSelected, scenario, quote, hailScenarioPieceUnits, hailRatesByTownshipRange, calculatedUnitValues);
  const currentlySelectedUnits = unitYears.filter(x => hailScenarioPieceUnits.includes(x.unitYearId));
  const hailScenarioPieceRateAmounts = getScenarioPieceRateCalculatedValuesWhenUnitQuoting(hailScenarioPieceRates, availableHailRates, currentlySelectedUnits, calculatedUnitValues);
  const totalAcres = sum(Array.from(hailScenarioPieceRateAmounts.values()), x => x.acres);
  const summaryHailRate = calculateSummaryHailRate(totalAcres, hailScenarioPiece.coveragePerAcre, calculatedValue?.totalPremium ?? 0);

  const headerControls = <HailModalHeaderControls onCloseClicked={handleClose}/>;

  return (
    <Dialog
      open={showModal}
      onClose={(_, reason) => handleClose(reason)}
      maxWidth={false}
      scroll="body"
      disableEscapeKeyDown={true}
      key={hailProductIndex}
      fullScreen
      sx={{ m: 2 }}
    >
      <>
        <ScenarioModalHeader scenarioId={scenario.scenarioId} headerText="Units Include/Exclude" headerControls={headerControls} />
        <DialogContent sx={{ p: 0, height: 'calc(100% - 69px)', padding: 1 }}>
          <Grid container direction="column" sx={{ height: '100%' }}>
            <Grid item xs sx={{ pt: 1 }} className={isLightMode ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}>
              <AgGridReact<UnitYear>
                className="hail-unit-grid"
                defaultColDef={DefaultColDefinition}
                rowData={unitYears.length === 0 ? null : unitYears}
                columnDefs={columnDefinitions}
                enterNavigatesVerticallyAfterEdit={false}
                enterNavigatesVertically={false}
                maintainColumnOrder={true}
                gridOptions={{
                  sortingOrder: ['desc', 'asc', null],
                }}
              />
            </Grid>
            <Grid container item sx={{ pt: 1, height: '70px', justifyContent: 'space-between' }}>
              <Grid item xs={1}>
                <Typography variant="value">Summary:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Stack direction={'row'} sx={{ justifyContent: 'flex-end' }} spacing={2}>
                  <Stack direction={'row'} spacing={1}>
                    <Typography variant="value">Rate:</Typography>
                    <Typography variant="value" color="red">{summaryHailRate}</Typography>
                  </Stack>
                  <Stack direction={'row'} spacing={1}>
                    <Typography variant="value">Hail Premium:</Typography>
                    <CurrencyText currencyAmount={(calculatedValue?.totalPremium ?? 0) * -1} colorize={true} />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default HailUnitsModal;
