import { modifyMpScenarioPiecePriceAndInputCosts, selectRowCropScenarioPiecesByScenarioIdAndTypes } from '../app/scenarioPiecesSlice';
import { RowCropScenarioPiece } from '../types/api/RowCropScenarioPiece';
import { FullExtendedData } from '../types/api/scenarioPieceExtendedData/fullExtendedData';
import { Nullable } from '../types/util/Nullable';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { AvailabilityService, ScenarioPieceType } from '@silveus/calculations';
import { RowCropScenario } from '../types/api/RowCropScenario';
import { useCallback } from 'react';

export const useUnderlyingMpScenarioPiece = (scenario: RowCropScenario, scenarioPieceType: ScenarioPieceType) => {
  const dispatch = useAppDispatch();
  const dependentPieces = AvailabilityService.getScenarioPiecesThatThisScenarioPieceImmediatelyDependsOn(scenarioPieceType);
  const underlyingScenarioPiece = useAppSelector(state => selectRowCropScenarioPiecesByScenarioIdAndTypes(state, scenario.scenarioId, dependentPieces));
  let underlyingMpScenarioPiece: Nullable<RowCropScenarioPiece> = null;

  if (underlyingScenarioPiece?.scenarioPieceType !== undefined && [ScenarioPieceType.MP, ScenarioPieceType.MpHpo].includes(underlyingScenarioPiece.scenarioPieceType)){
    underlyingMpScenarioPiece = underlyingScenarioPiece;
  }

  const updateUnderlyingMpScenarioPiece = useCallback(async (rowCropScenarioPieceExtendedData: Nullable<FullExtendedData>) => {
    await dispatch(modifyMpScenarioPiecePriceAndInputCosts({ mpScenarioPiece: underlyingMpScenarioPiece, rowCropScenarioPieceExtendedData }));
  }, [underlyingMpScenarioPiece]);

  return { underlyingMpScenarioPiece, updateUnderlyingMpScenarioPiece };
};