import MESSAGES from '../../../constants/messages';
import { Nullable } from '../../../types/util/Nullable';

export function validateAcres(acres: Nullable<number>, allowZero: boolean = false): true | string {
  if (acres === null) return MESSAGES.required;
  if (acres > 99999999.99) return MESSAGES.maximum(99999999.99);
  if (acres < 0) return MESSAGES.minimum(0);
  if (acres === 0 && !allowZero) return MESSAGES.greaterThan(0);
  if (acres.toString().length > 11) return MESSAGES.maxLength(11);
  // Check for more than two numbers after the decimal point
  if ((acres > 0) && (acres.toString().split('.')[1]?.length > 2)) {
    return MESSAGES.step(.01);
  }
  return true;
}
