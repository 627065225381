import { calculateApplicableTYield, calculateCustomTYield } from '@silveus/calculations';
import { getKeyedStateGroupedBy, getKeyedStateValues, getStateIdsMatching } from './sliceHelpers';
import { Nullable } from '../types/util/Nullable';
import UnitYearAph from '../types/api/UnitYearAph';
import { QuoteId, ScenarioId, ScenarioUnitYearAphId, UnitYearAphId, UnitYearId } from '../types/api/PrimaryKeys';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { createAppAsyncThunk } from './thunkHelpers';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { ScenarioUnitYearAph } from '../types/api/ScenarioUnitYearAph';
import {
  createScenarioUnitYearAphBatchRequest,
  getScenarioUnitYearAphForScenariosRequest,
  updateScenarioUnitYearAphBatchRequest
} from '../services/requestInterception/scenarioUnitYearAphRequestInterceptor';
import {
  createUnitYearAphRequest,
  deleteAphForUnitYearRequest,
  deleteAphRequest, getUnitYearAphForQuotesRequest, getUnitYearAphForScenariosRequest,
  getUnitYearAphRequest,
  updateUnitYearAphRequest
} from '../services/requestInterception/unitYearAphRequestInterceptor';
import { selectTYield } from './admSlice';
import { selectUnitYearById } from './unitsSlice';
import { getItemsForId, orderMap } from '../utils/mapHelpers';
import { orderByProperty } from '../utils/arrayUtils';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';

export interface UnitYearAphState {
  allUnitYearAph: SliceDataState<UnitYearAphId, UnitYearAph>;
  allScenarioUnitYearAph: SliceDataState<ScenarioUnitYearAphId, ScenarioUnitYearAph>;
  currentlySelectedHistoricalAcresYear: Nullable<number>;
}

const initialState: UnitYearAphState = {
  allUnitYearAph: initialSliceDataState(),
  allScenarioUnitYearAph: initialSliceDataState(),
  currentlySelectedHistoricalAcresYear: null,
};

export const unitYearAphSlice = createSlice({
  name: 'unitYearAph',
  initialState: initialState,
  reducers: {
    setCurrentlySelectedHistoricalAcresYear(state, action: PayloadAction<Nullable<number>>) {
      state.currentlySelectedHistoricalAcresYear = action.payload;
    },
  },
  extraReducers(builder) {
    const unitYearAphBuilder = getAsyncHandlerBuilder(builder, s => s.allUnitYearAph, s => s.unitYearAphId);

    unitYearAphBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addUnitYearAph,
      affectedIds: arg => arg.unitYearAph.map(u => u.unitYearAphId),
    });

    unitYearAphBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchUnitYearAph,
      affectedIds: (arg, state) => getStateIdsMatching(state.allUnitYearAph.data, s => s.unitYearId === arg.unitYearId, s => s.unitYearAphId),
    });

    unitYearAphBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchUnitYearAphForScenarios,
      affectedIds: () => [],
    });

    unitYearAphBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchUnitYearAphForQuotes,
      affectedIds: () => [],
    });

    unitYearAphBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyUnitYearAph,
      affectedIds: arg => arg.unitYearAph.map(u => u.unitYearAphId),
    });

    unitYearAphBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeUnitYearAph,
      affectedIds: arg => arg.unitYearAph.map(u => u.unitYearAphId),
    });

    const scenarioUnitYearAphBuilder = getAsyncHandlerBuilder(builder, s => s.allScenarioUnitYearAph, s => s.scenarioUnitYearAphId);

    scenarioUnitYearAphBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchScenarioUnitYearAphForScenarios,
      affectedIds: () => [],
    });

    scenarioUnitYearAphBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addScenarioUnitYearAph,
      affectedIds: arg => arg.scenarioUnitYearAph.map(u => u.scenarioUnitYearAphId),
    });

    scenarioUnitYearAphBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyScenarioUnitYearAph,
      affectedIds: arg => arg.scenarioUnitYearAph.map(u => u.scenarioUnitYearAphId),
    });

    builder
      .addCase(removeAllAphForUnitYear.fulfilled, (state, action: PayloadAction<UnitYearId>) => {
        for (const unitYearAph of getKeyedStateValues(state.allUnitYearAph.data)) {
          if (unitYearAph.unitYearId === action.payload) {
            delete state.allUnitYearAph.data[unitYearAph.unitYearAphId];
          }
        }
      });
  },
});

export const { setCurrentlySelectedHistoricalAcresYear } = unitYearAphSlice.actions;

// Memoized Selectors
export const selectUnitYearAphDictionary = (state: RootState) => state.unitYearAph.allUnitYearAph.data;
export const selectUnitYearAphByUnitYearMap = createSelector([selectUnitYearAphDictionary], result => {
  const map = getKeyedStateGroupedBy(result, uy => uy.unitYearId);
  const ordered = orderMap(map, DefaultOrders.unitYearAphs);
  return ordered;
});

export const selectUnitYearAphByUnitYearIds = (state: RootState, unitYearIds: UnitYearId[]) => {
  return unitYearIds.map(id => selectUnitYearAphByUnitYearId(state, id)).flat();
};

const selectScenarioUnitYearAphDictionary = (state: RootState) => state.unitYearAph.allScenarioUnitYearAph.data;
export const selectScenarioUnitYearAphByScenarioIdMap = createSelector([selectScenarioUnitYearAphDictionary], result => {
  const map = getKeyedStateGroupedBy(result, uy => uy.scenarioId);
  const ordered = orderMap(map, DefaultOrders.scenarioUnitYearAphs);
  return ordered;
});

export const selectScenarioUnitYearAphForScenarioId = createSelector([
  selectScenarioUnitYearAphByScenarioIdMap,
  (_state: RootState, scenarioId?: ScenarioId) => scenarioId,
  (state: RootState, _scenarioId?: ScenarioId) => state,
], (result, scenarioId) => {
  const items = getItemsForId(result, scenarioId);
  return orderByProperty(items, DefaultOrders.scenarioUnitYearAphs);
});

export const selectScenarioUnitYearAphByScenarioIds = (state: RootState, scenarioIds: ScenarioId[]) => {
  return scenarioIds.map(id => selectScenarioUnitYearAphForScenarioId(state, id)).flat();
};

// Non-Memoized Selectors
export const currentlySelectedHistoricalAcresYear = (state: RootState) => state.unitYearAph.currentlySelectedHistoricalAcresYear;
export const selectUnitYearAphById = (state: RootState, unitYearAphId: UnitYearAphId) => state.unitYearAph.allUnitYearAph.data[unitYearAphId] ?? null;
export const selectUnitYearAphByUnitYearId = (state: RootState, unitYearId: UnitYearId) => Object.values(state.unitYearAph.allUnitYearAph.data).filter(aph => aph !== undefined).map(aph => aph as UnitYearAph).filter(aph => aph.unitYearId === unitYearId);

// Thunks
export const fetchUnitYearAph = createAppAsyncThunk('units/fetchUnitYearAph', async ({ unitYearId }: { unitYearId: UnitYearId }) => {
  return await getUnitYearAphRequest(unitYearId);
});

export const fetchUnitYearAphForScenarios = createAppAsyncThunk('units/fetchUnitYearAphForScenarios', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  return await getUnitYearAphForScenariosRequest(scenarioIds);
});

export const fetchUnitYearAphForQuotes = createAppAsyncThunk('units/fetchUnitYearAphForQuotes', async ({ quoteIds }: { quoteIds: QuoteId[] }) => {
  return await getUnitYearAphForQuotesRequest(quoteIds);
});

export const addUnitYearAph = createAppAsyncThunk('units/addUnitYearAph', async ({ unitYearAph }: { unitYearAph: UnitYearAph[] }) => {
  await createUnitYearAphRequest(unitYearAph);
  return unitYearAph;
});

export const modifyUnitYearAph = createAppAsyncThunk('units/modifyUnitYearAph', async ({ unitYearAph }: { unitYearAph: UnitYearAph[] }) => {
  await updateUnitYearAphRequest(unitYearAph);
  return unitYearAph;
});


export const removeUnitYearAph = createAppAsyncThunk('units/removeAph', async ({ unitYearAph }: { unitYearAph: UnitYearAph[] }) => {
  await deleteAphRequest(unitYearAph.map(u => u.unitYearAphId));
  return unitYearAph;
});

export const removeAllAphForUnitYear = createAppAsyncThunk('units/removeAphForUnitYear', async ({ unitYearId }: { unitYearId: UnitYearId }) => {
  await deleteAphForUnitYearRequest(unitYearId);
  return unitYearId;
});

export const selectCustomTYieldByUnitYearId = createSelector([
  selectUnitYearAphByUnitYearId,
], unitYearAph => {
  return calculateCustomTYield(unitYearAph);
});

export const selectApplicableTYieldByUnitYearId = createSelector([
  selectUnitYearAphByUnitYearId,
  (state: RootState, _unitYearId: UnitYearId) => state,
  (_state: RootState, unitYearId: UnitYearId) => unitYearId,
], (unitYearAph, state, unitYearId) => {
  const unit = selectUnitYearById(state, unitYearId);
  const admTYield = unit ? selectTYield(state, unit.typeId, unit.practiceId, unit.subCountyCode)?.transitionalYield ?? 0 : 0;
  return calculateApplicableTYield(unitYearAph, admTYield);
});

// Scenario Unit Year Aph -------------------

export const fetchScenarioUnitYearAphForScenarios = createAppAsyncThunk('units/fetchScenarioUnitYearAphForScenarios', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  return await getScenarioUnitYearAphForScenariosRequest(scenarioIds);
});

export const addUpdateOrRemoveUnitYearAph = createAppAsyncThunk('units/addOrUpdateScenarioUnitYearAph', async ({ initialUnitYearAph, modifiedUnitYearAph }: { initialUnitYearAph: UnitYearAph[], modifiedUnitYearAph: UnitYearAph[] }, thunkApi) => {
  let aphEntriesToAdd: UnitYearAph[] = [];
  let aphEntriesToRemove: UnitYearAph[] = [];
  let aphEntriesToUpdate: UnitYearAph[] = [];

  if (initialUnitYearAph.length <= 0) {
    aphEntriesToAdd = modifiedUnitYearAph;
  } else {
    //Get the entries that exist in local, but not in the original. These need to be added
    aphEntriesToAdd = modifiedUnitYearAph.filter(year => !initialUnitYearAph.map(u => u.unitYearAphId).includes(year.unitYearAphId));
    //Get the entries that exist only in the original, but not in local. They need to be removed.
    aphEntriesToRemove = initialUnitYearAph.filter(year => !modifiedUnitYearAph.map(u => u.unitYearAphId).includes(year.unitYearAphId));
    //Get the entries that exist both in local and the original. They need to be updated.
    aphEntriesToUpdate = modifiedUnitYearAph.filter(year => initialUnitYearAph.map(u => u.unitYearAphId).includes(year.unitYearAphId));
  }

  const promisesToAwait: Promise<unknown>[] = [];
  //Get the entries that only exist in local APH, add them
  if (aphEntriesToAdd.length > 0) {
    promisesToAwait.push(thunkApi.dispatch(addUnitYearAph({ unitYearAph: aphEntriesToAdd })));
  }

  if (aphEntriesToRemove.length > 0) {
    promisesToAwait.push(thunkApi.dispatch(removeUnitYearAph({ unitYearAph: aphEntriesToRemove })));
  }

  if (aphEntriesToUpdate.length > 0) {
    promisesToAwait.push(thunkApi.dispatch(modifyUnitYearAph({ unitYearAph: aphEntriesToUpdate })));
  }

  await Promise.all(promisesToAwait);
});

export const addOrUpdateScenarioUnitYearAph = createAppAsyncThunk('units/addOrUpdateScenarioUnitYearAph', async ({ initialScenarioUnitYearAph, modifiedScenarioUnitYearAph }: { initialScenarioUnitYearAph: ScenarioUnitYearAph[], modifiedScenarioUnitYearAph: ScenarioUnitYearAph[] }, thunkApi) => {
  // This thunk is going to be "smart" and decide for itself when to trigger adds or updates based on factors other than primary key.
  const toUpdate: ScenarioUnitYearAph[] = [];
  const toAdd: ScenarioUnitYearAph[] = [];
  // the remove isn't needed because of the cascade delete from removeUnitYearAph

  for (const aph of modifiedScenarioUnitYearAph) {
    const existing = initialScenarioUnitYearAph.find(s => s.unitYearAphId === aph.unitYearAphId);

    if (existing === undefined) {
      toAdd.push({ ...aph });
    } else {
      toUpdate.push({
        ...existing,
        yeStatus: aph.yeStatus,
      });
    }
  }

  const promisesToAwait: Promise<unknown>[] = [];

  if (toAdd.length > 0) {
    promisesToAwait.push(thunkApi.dispatch(addScenarioUnitYearAph({ scenarioUnitYearAph: toAdd })));
  }

  if (toUpdate.length > 0) {
    promisesToAwait.push(thunkApi.dispatch(modifyScenarioUnitYearAph({ scenarioUnitYearAph: toUpdate })));
  }

  await Promise.all(promisesToAwait);
});

export const addScenarioUnitYearAph = createAppAsyncThunk('units/addScenarioUnitYearAph', async ({ scenarioUnitYearAph }: { scenarioUnitYearAph: ScenarioUnitYearAph[] }) => {
  await createScenarioUnitYearAphBatchRequest(scenarioUnitYearAph);
  return scenarioUnitYearAph;
});

export const modifyScenarioUnitYearAph = createAppAsyncThunk('units/modifyScenarioUnitYearAph', async ({ scenarioUnitYearAph }: { scenarioUnitYearAph: ScenarioUnitYearAph[] }) => {
  await updateScenarioUnitYearAphBatchRequest(scenarioUnitYearAph);
  return scenarioUnitYearAph;
});

export default unitYearAphSlice.reducer;
