import { Box, Grid } from '@mui/material';
import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common';
import { ColorResult, CustomPicker } from 'react-color';
import { EditableInputStyles } from 'react-color/lib/components/common/EditableInput';
import { ValidColor } from '@silveus/react-matrix';

interface Props {
  color: ValidColor | undefined;
  customColorPickerStyles: EditableInputStyles | undefined;
  onChange: (colorResult: ColorResult) => void;
  onInputChange: (colorResult: ColorResult) => void;
}

const inputStyle = {
  input: {
    fontSize: '11px',
    color: '#333',
    width: '100%',
    borderRadius: '2px',
    border: 'none',
    height: '21px',
    textAlign: 'center',
  },
  label: {
    textTransform: 'uppercase',
    fontSize: '11px',
    lineHeight: '11px',
    color: '#969696',
    textAlign: 'center',
    display: 'block',
    marginTop: '12px',
  },
} as const;

const CustomPointer = () => {
  const styles = {
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    transform: 'translate(-6px, -1px)',
    backgroundColor: 'rgb(248, 248, 248)',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
  };

  return (
    <div style={styles} />
  );
};

const CustomPointerCircle = () => {
  const styles = {
    width: '12px',
    height: '12px',
    borderRadius: '12px',
    boxShadow: 'inset 0 0 0 1px #fff',
    transform: 'translate(-6px, -6px)',
  };

  return (
    <div style={styles} />
  );
};

interface rest {}

const MatrixCustomColorPicker = ({ color, customColorPickerStyles, onChange, onInputChange, ...rest }: Props) => {
  // I guess this component somehow injects in some additional props.
  // Because those are difficult to type, for now we're specifically casting those as "any."
  // This is also why there's a split - the real props are useful for our code. The other props,
  // while they could definitely use types, won't at least affect the "public" api.
  // eslint-disable-next-line
  const injectedProps = rest as any;
  const mergedInputStyles = {
    input: {
      ...inputStyle.input,
      ...customColorPickerStyles?.input,
    },
    label: {
      ...inputStyle.label,
      ...customColorPickerStyles?.label,
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ position: 'relative', paddingBottom: '100%' }}>
          <Saturation
            {...injectedProps}
            pointer={CustomPointerCircle}
            onChange={onChange}
            onChangeComplete={injectedProps.onChangeComplete}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ position: 'relative', height: '20px' }}>
          <Hue
            {...injectedProps}
            pointer={CustomPointer}
            onChange={onChange}
            onChangeComplete={injectedProps.onChangeComplete}
            style={{
              height: '10px',
              width: '100%',
            }}
          />
        </Box>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs />
        <Grid item xs={2}>
          <EditableInput
            style={mergedInputStyles}
            label="R"
            value={injectedProps.rgb.r}
            onChange={onInputChange}
          />
        </Grid>
        <Grid item xs={2}>
          <EditableInput
            style={mergedInputStyles}
            label="G"
            value={injectedProps.rgb.g}
            onChange={onInputChange}
          />
        </Grid>
        <Grid item xs={2}>
          <EditableInput
            style={mergedInputStyles}
            label="B"
            value={injectedProps.rgb.b}
            onChange={onInputChange}
          />
        </Grid>
        <Grid item xs={2}>
          <EditableInput
            style={mergedInputStyles}
            label="HEX"
            value={injectedProps.hex}
            onChange={onInputChange}
          />
        </Grid>
        <Grid item xs />
      </Grid>
    </Grid>
  );
};

export default CustomPicker(MatrixCustomColorPicker);