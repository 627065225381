import { useEffect, useState } from 'react';

const useTime = (delay = 60000) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => setTime(new Date()), delay);

    return () => clearInterval(intervalId);
  }, []);

  return {
    weekday: new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(time),
    dateTime: time,
    year: time.getFullYear(),
    month: time.getMonth() + 1,
    monthLong: new Intl.DateTimeFormat('en-US', { month: 'long' }).format(time),
    day: time.getDate(),
    hours: time.getHours(),
    minutes: time.getMinutes(),
    seconds: time.getSeconds(),
    daytime: getDaytime(time.getHours()),
    time: getTime(time),
  };
};

const getDaytime = (hours: number) => {
  if (hours < 12) {
    return 'Morning';
  } else if (hours >= 12 && hours < 18) {
    return 'Afternoon';
  } else if (hours >= 18) {
    return 'Evening';
  }
};

const getTime = (date: Date) => {
  const hours = date.getHours();

  return `${hours > 12 ? hours - 12 : hours}:${date.getMinutes().toString().padStart(2, '0')} ${hours > 12 ? 'PM' : 'AM'}`;
};

export default useTime;