import { useMemo } from 'react';
import { useAppSelector } from '../reduxHooks';
import { selectUserMatrixColorSettingsOrDefault } from '../../app/userSettingsSlice';
import { UserMatrixColor } from '../../types/api/userSettings/UserMatrixColor';
import { CellTextColorSettings } from '@silveus/react-matrix';

export function useMatrixCellTextColorSettings() {
  const userMatrixColorSettings = useAppSelector(selectUserMatrixColorSettingsOrDefault);
  return useMemo(() => createMatrixCellTextColorSettings(userMatrixColorSettings), [userMatrixColorSettings]);
}

export function createMatrixCellTextColorSettings(userMatrixColorSettings: readonly UserMatrixColor[]): CellTextColorSettings {
  return {
    textColors: userMatrixColorSettings.map(mc => ({
      color: mc.hexColor,
      threshold: mc.colorValue,
    })),
  };
}