import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import validationFunctionDefault from '../../defaultDefinitions/validationFunctionDefault';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import { createRpHpeData } from '../../../../services/calculations/rp/rpHpeDataTransformations';
import { RpScenarioPieceForm } from '../../../../pages/scenarioPiece/rp/rpScenarioPieceForm.component';
import { createRpData } from '../../../../services/calculations/rp/rpDataTransformations';
import { ScenarioPieceType } from '@silveus/calculations';
import {
  getNonAreaQuoteSummaryData
} from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getNonAreaQuoteSummaryData';
import getFullyQualifiedScenarioPieceName from '../../defaultDefinitions/scenarioPieceNameDefaults';
import { EuByScenarioPieceChip, OptionsScenarioPieceChip } from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

const baseDefinition: ScenarioPieceDefinition = {
  validationFunction: validationFunctionDefault,
  d365ProductType: ProductType.MPCI,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, _aphYield, _shouldSimulateUnitGroups) => {
    return createRpData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <RpScenarioPieceForm {...props} selectedScenarioPieceType={props.selectedScenarioPieceType as ScenarioPieceType} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>} />;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getNonAreaQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceName,
  getScenarioPieceCardChips: [EuByScenarioPieceChip, OptionsScenarioPieceChip],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#6495ED',
};

export const rpDefinition: ScenarioPieceDefinition = {
  ...baseDefinition,
};

export const rpHpeDefinition: ScenarioPieceDefinition = {
  ...baseDefinition,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, _aphYield, _shouldSimulateUnitGroups) => {
    return createRpHpeData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
};
