import { quoterDb } from '../../db';
import { Quote } from '../../types/api/Quote';
import { createQuote, deleteQuote, getQuote, getQuotes, updateQuote, updateQuotes } from '../quotes.service';
import { ClientFileId, QuoteId } from '../../types/api/PrimaryKeys';
import {
  addSingle,
  getMultiple,
  getSingle,
  update
} from '../offlineDataAccess.service';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { deleteRowCropScenariosLocally } from './scenarioRequestInterceptor';
import { applySoftDelete } from '../../utils/dexieQueryHelpers/softDelete';
import { isOnlineFromStore } from '../../utils/isOnlineFromStore';
import { nonGetCacheOnly, nonGetNetworkOnly } from '../../utils/cachingStrategies';

const table = quoterDb.quotes;

export const getQuoteRequest = async (quoteId: QuoteId): Promise<Quote> => {
  const request = () => getQuote(quoteId);
  return await getSingle(table, { quoteId: quoteId }, request);
};

export const getQuotesRequest = async (clientFileId: ClientFileId): Promise<Quote[]> => {
  const request = () => getQuotes(clientFileId);
  return await getMultiple(table, { clientFileId: clientFileId }, request);
};

export const createQuoteRequest = async (quote: Quote): Promise<CreatedItemResult<QuoteId>> => {
  const request = () => createQuote(quote);
  return await addSingle(table, quote, request);
};

export const updateQuoteRequest = async (quote: Quote): Promise<void> => {
  const request = () => updateQuote(quote);
  return await update(table, quote, request);
};

export const updateQuotesRequest = async (quotes: Quote[]): Promise<void> => {
  const request = () => updateQuotes(quotes);
  return await update(table, quotes, request);
};

export const deleteQuoteRequest = async (quoteId: QuoteId): Promise<void> => {
  const request = () => deleteQuote(quoteId);

  const transactionTables = [
    quoterDb.rowCropScenarios,
    quoterDb.rowCropScenarioPieces,
    quoterDb.matrices,
    quoterDb.unitGroups,
    quoterDb.scenarioOptions,
    quoterDb.historicalAnalyses,
    quoterDb.trendlineAnalyses,
    quoterDb.premiumBreakdowns,
    quoterDb.scenarioQuickUnits,
    quoterDb.scenarioUnitYearAph,
    quoterDb.scenarioOptionUnitYears,
    quoterDb.quotes,
  ];

  const updateTransaction = () => quoterDb.transaction('rw', transactionTables, async () => {
    await deleteQuotesLocally([quoteId]);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;
  return await strategy(request, updateTransaction);
};

export const deleteQuotesLocally = async (quoteIds: QuoteId[]): Promise<void> => {
  const quotes = safeWhere(quoterDb.quotes, 'quoteId').anyOf(quoteIds);
  const scenarios = safeWhere(quoterDb.rowCropScenarios, 'quoteId').anyOf(quoteIds);
  const scenarioIds = (await scenarios.toArray()).map(s => s.scenarioId);

  await deleteRowCropScenariosLocally(scenarioIds);

  await applySoftDelete(quotes);
};