import { Tooltip, Typography } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { isNullOrUndefined } from '../../../../../../utils/nullHandling';
import HistoricalStormEvent from '../../../../../../types/api/adm/HistoricalStormEvent';

export const StormEventCellRenderer = <TData extends unknown>({ value }: ICellRendererParams<TData, HistoricalStormEvent[]>) => {
  if (isNullOrUndefined(value) || value.length === 0) return <></>;

  const textToUse = `${value.length > 1 ? value.length : ''}X`;

  const formattedStormDates = value.map(stormEvent => {
    const stormDate = new Date(stormEvent.date);
    const formattedDate = `${stormDate.getMonth()}/${stormDate.getDate()}/${stormDate.getFullYear()}`;
    return <Typography variant="body2" key={stormEvent.name}>{`${stormEvent.name}: ${formattedDate}`}</Typography>;
  });

  return (
    <Tooltip title={<>{formattedStormDates}</>}>
      <Typography variant="label" align="center">{textToUse}</Typography>
    </Tooltip>
  );
};