import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  style?: React.CSSProperties;
}

const ScrollableArea = ({ children, style }: Props) => {
  return (
    <div style={style} className={'scrollable-area'}>
      {children}
    </div >
  );
};

export default ScrollableArea;