import { Grid, Tooltip, Typography } from '@mui/material';
import TrendlineAnalysisScenarioCard from './trendlineAnalysisScenarioCard.component';
import TrendlineAnalysisCard from './trendlineAnalysisCard.component';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  selectSelectedTrendlineScenarioId,
  setSelectedTrendlineScenarioId
} from '../../../app/scenarioAnalysisSlice';
import { selectAllTrendlines } from '../../../app/trendlineAnalysisSlice';
import { useEffect, useState } from 'react';
import ScenarioAnalysisWrapper from '../ScenarioAnalysisWrapper';
import { ScenarioChip } from '../../../components/scenarioChip/scenarioChip';

type TrendLineAnalysisProps = {
  allScenariosForView: RowCropScenario[];
}

const TrendLineAnalysis = ({ allScenariosForView }: TrendLineAnalysisProps) => {
  const dispatch = useAppDispatch();
  const [collapsedHeader, setCollapsedHeader] = useState(false);
  const selectedScenarioId = useAppSelector(selectSelectedTrendlineScenarioId);
  const trendlines = useAppSelector(state => selectAllTrendlines(state));
  const filteredScenarios = allScenariosForView.filter(x => x.isVisible && trendlines.get(x.scenarioId)?.isActive);
  const selectedTrendline = selectedScenarioId ? trendlines.get(selectedScenarioId) : null;

  useEffect(() => {
    // Handle the cases where we either don't have a selected scenario or where our selected scenario does not
    // exist in the list of filtered scenarios. We want to pick the first scenario if one exists in those cases
    // so that something always displays on this page if something is available to display.
    if (filteredScenarios.findIndex(x => x.scenarioId === selectedScenarioId) < 0) {
      if (filteredScenarios.length > 0) {
        const firstScenario = filteredScenarios[0];
        dispatch(setSelectedTrendlineScenarioId(firstScenario.scenarioId));
      }
    }
  }, [filteredScenarios, selectedScenarioId]);

  if (filteredScenarios.length === 0) {
    return <Typography variant="subtitle1"><p>No trendlines have been added</p></Typography>;
  }

  return (
    <Grid item height="100%" sx={{ flexWrap: 'nowrap' }} columnSpacing={2}>
      <ScenarioAnalysisWrapper
        collapsed={collapsedHeader}
        onToggleCollapse={() => setCollapsedHeader(prev => !prev)}
        headerItems={filteredScenarios.map(s => {
          return (
            collapsedHeader ? (
              <Grid item xs="auto" key={s.scenarioId}>
                <Tooltip title={s.name} placement="top">
                  <ScenarioChip
                    backgroundColor={s.scenarioColor}
                    label={s.name}
                    sx={{ color: theme => theme.palette.common.white }}
                    onClick={() => dispatch(setSelectedTrendlineScenarioId(s.scenarioId))}
                    maxWidth={400}
                  />
                </Tooltip>
              </Grid>
            ) : (
              <Grid item xs="auto" key={s.scenarioId}>
                <TrendlineAnalysisScenarioCard
                  key={s.scenarioId}
                  isSelected={s.scenarioId === selectedScenarioId}
                  scenario={s}
                  onClick={() => dispatch(setSelectedTrendlineScenarioId(s.scenarioId))}
                />
              </Grid>
            ));
        })}
      >
        <Grid item xs>
          {selectedTrendline && selectedTrendline.isActive && (
            <TrendlineAnalysisCard trendlineAnalysis={selectedTrendline} key={selectedTrendline.trendlineAnalysisId} />
          )}
        </Grid>
      </ScenarioAnalysisWrapper>
    </Grid >
  );
};

export default TrendLineAnalysis;