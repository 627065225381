import MESSAGES from '../../../constants/messages';
import { Nullable } from '../../../types/util/Nullable';

export function validateOptionalUnitNumber(optionalUnitNumber: Nullable<string>): true | string {
  if (optionalUnitNumber === null || optionalUnitNumber === '') return MESSAGES.required;
  if (optionalUnitNumber.length > optionalUnitMaxLength) return MESSAGES.maxLength(optionalUnitMaxLength);
  return true;
}

export const optionalUnitMaxLength = 4;
