import { createSlice } from '@reduxjs/toolkit';
import { KeyedState, initialKeyedState, updateKeyedStateVerbatim } from './sliceHelpers';
import { QuotePricingDto } from '../types/api/pricing/QuotePricingDto';
import { createAppAsyncThunk } from './thunkHelpers';
import { QuotePriceParams, getQuotePrice } from '../services/prices.service';
import { Key, ScenarioId } from '../types/api/PrimaryKeys';
import { RootState } from './store';
import { selectScenarioById } from './scenariosSlice';
import { selectQuoteById } from './quotesSlice';
import { selectClientFileById } from './clientFilesSlice';
import { toPrimaryKey } from '../utils/primaryKeyHelpers';
import { selectRmaPriceDiscoveryForScenario, selectSalesClosingDateStringForScenario } from './rmaPriceDiscoverySelectors';
import { getStateCodeFromCountyId } from '../utils/adm';
import { getDaysUntilHarvestDiscoveryEnd } from '../utils/rma/getDaysUntilHarvestDiscoveryEnd';
import { distinctBy } from '../utils/arrayUtils';

type DataKey = Key<'MatrixHeatMapDataKey'>;

interface MatrixHeatMapState {
  data: KeyedState<DataKey, QuotePricingDto>;
}

const initialState: MatrixHeatMapState = {
  data: initialKeyedState(),
};


export const matrixHeatMapDataSlice = createSlice({
  name: 'matrixHeatMapData',
  initialState: initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(fetchMatrixHeatMapData.fulfilled, (state, action) => {
      const key = generateDataKey(action.meta.arg);
      updateKeyedStateVerbatim(state.data, action.payload, key);
    });
  },
});

/** Generates a key capable of accessing state in this slice. This is the only function that should be called to generate a key. */
const generateDataKey = (params: QuotePriceParams): DataKey => {
  // This data is manually copied field by field so that order of properties is guaranteed to be consistent.
  const standardizedData: QuotePriceParams = {
    year: params.year,
    commodityCode: params.commodityCode,
    typeId: params.typeId,
    practiceId: params.practiceId,
    stateCode: params.stateCode,
    salesClosingDate: params.salesClosingDate,
  };

  const serialized = JSON.stringify(standardizedData);
  return toPrimaryKey(serialized);
};

/** This is the "bare metal" call, but likely publicly only the "scenario" wrapper will be called. */
const fetchMatrixHeatMapData = createAppAsyncThunk('matrixHeatMapData/fetchMatrixHeatMapData', async (params: QuotePriceParams) => {
  const result = await getQuotePrice(params);
  return result;
});

/** Given scenario ids, fetches all matrix heat map pricing data for those scenarios.
 * Note that this needs essentially all scenario data to already exist in state, including even some ADM data.
 */
export const fetchMatrixHeatMapDataByScenarioIds = createAppAsyncThunk('matrixHeatMapData/fetchMatrixHeatMapDataByScenarioIds', async ({ scenarioIds }: { scenarioIds: readonly ScenarioId[] }, thunkApi) => {
  const state = thunkApi.getState();

  const distinctParamCombos = new Set<DataKey>();
  const requestParamsToFire: QuotePriceParams[] = [];

  for (const scenarioId of scenarioIds) {
    const dataParams = selectQuotePriceParamsForScenario(state, scenarioId);
    if (dataParams === null) { continue; }

    const dataKey = generateDataKey(dataParams);

    // Multiple scenarios may share the exact same pricing params. This is to reduce the total number of API requests made,
    // as this API call is heavy.
    if (!distinctParamCombos.has(dataKey)) {
      distinctParamCombos.add(dataKey);
      requestParamsToFire.push(dataParams);
    }
  }

  const promises = requestParamsToFire.map(params => thunkApi.dispatch(fetchMatrixHeatMapData(params)));
  await Promise.all(promises);
});


const selectRequiredRmaParamsForScenario = (state: RootState, scenarioId: ScenarioId) => {
  const scenario = selectScenarioById(state, scenarioId);
  if (scenario === null) { return null; }

  if (scenario.practiceId === null) { return null; }

  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) { return null; }

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) { return null; }

  return {
    year: clientFile.year,
    countyId: quote.countyId,
    commodityCode: quote.commodityCode,
    typeId: scenario.typeId,
    practiceId: scenario.practiceId,
  };
};



/** Given a scenario id pulls from state all of the data needed to populate quote pricing params.
 * Returns null if any required data is missing.
 */
const selectQuotePriceParamsForScenario = (state: RootState, scenarioId: ScenarioId): QuotePriceParams | null => {
  const rmaParams = selectRequiredRmaParamsForScenario(state, scenarioId);
  if (rmaParams === null) { return null; }

  const { year, countyId, commodityCode, typeId, practiceId } = rmaParams;

  const salesClosingDate = selectSalesClosingDateStringForScenario(state, scenarioId, countyId, commodityCode, typeId, practiceId);
  if (salesClosingDate === null) { return null; }

  const stateCode = getStateCodeFromCountyId(countyId);

  return {
    year: year,
    commodityCode: commodityCode,
    typeId: typeId,
    practiceId: practiceId,
    stateCode: stateCode,
    salesClosingDate: salesClosingDate,
  };
};

type MatrixHeatMapData = {
  quotePriceData: QuotePricingDto;
  daysUntilHarvestDiscoveryEnd: number;
};

const selectHarvestPriceEndDate = (state: RootState, scenarioId: ScenarioId) => {
  const rmaParams = selectRequiredRmaParamsForScenario(state, scenarioId);
  if (rmaParams === null) { return null; }

  const rmaPriceDiscovery = selectRmaPriceDiscoveryForScenario(state, rmaParams.year, rmaParams.countyId, scenarioId, rmaParams.commodityCode, rmaParams.typeId, rmaParams.practiceId);
  if (rmaPriceDiscovery === null) { return null; }

  const { harvestPriceEndDate } = rmaPriceDiscovery;

  return new Date(harvestPriceEndDate);
};


/** Selects out the matrix heat map pricing data for a given scenario.
 * Returns null if no data is available.
 */
const selectMatrixHeatMapDataForScenario = (state: RootState, scenarioId: ScenarioId): MatrixHeatMapData | null => {
  const params = selectQuotePriceParamsForScenario(state, scenarioId);
  if (params === null) { return null; }

  // Pull out the heat map-specific data. This is the core data needed.
  const quotePriceParamsKey = generateDataKey(params);
  const quotePriceData = state.matrixHeatMapData.data[quotePriceParamsKey] ?? null;
  if (quotePriceData === null) { return null; }

  // As an extra piece of data callers will need, pull out the days until harvest discovery end.
  const harvestPriceEndDate = selectHarvestPriceEndDate(state, scenarioId);
  if (harvestPriceEndDate === null) { return null; }

  const daysUntilHarvestDiscoveryEnd = getDaysUntilHarvestDiscoveryEnd(harvestPriceEndDate);

  // If the discovery end date is in the past, return null. This will result in no heat map being shown.
  if (daysUntilHarvestDiscoveryEnd === null) { return null; }

  return {
    quotePriceData: quotePriceData,
    daysUntilHarvestDiscoveryEnd: daysUntilHarvestDiscoveryEnd,
  };
};

/**
 * Given a list of scenario ids, returns the matrix heat map data for those scenarios if they all have the exact same data.
 * Otherwise, returns null.
 */
export const selectMatrixHeatMapDataForScenarios = (state: RootState, scenarioIds: readonly ScenarioId[]) => {
  // Explanation of this logic: This selector is tailored for a specific use case.
  // In that use case, the caller can provide N scenarios, but we only want to return heat map data if all of the scenarios have the exact same data.
  // If ANY of the scenarios have different data, we return null. Again, this is tailored to a specific use case.
  // The end result, way down the chain, is that the matrix heat map will only display if ALL scenarios share the same relevant heat map data.
  // This is so that a matrix does not show a heat map that only applies to some of the scenarios, but not others.

  const allHeatMapData: MatrixHeatMapData[] = [];

  for (const scenarioId of scenarioIds) {
    const heatMapData = selectMatrixHeatMapDataForScenario(state, scenarioId);

    // Yes, this is intentionally returning. This entire selector returns null if any data is missing for any of the scenarios.
    if (heatMapData === null) { return null; }

    allHeatMapData.push(heatMapData);
  }

  // This is essentially doing a deep distinct comparison of all the data. If any of the data is different, it will return null below.
  const distinctHeatMapData = distinctBy(allHeatMapData, data => JSON.stringify(data));

  if (distinctHeatMapData.length !== 1) { return null; }

  // There will always be exactly one element in this array. The null check is just to satisfy TypeScript.
  return allHeatMapData.at(0) ?? null;
};

export default matrixHeatMapDataSlice.reducer;