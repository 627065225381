import { Grid } from '@mui/material';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../types/util/Nullable';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import {
  AdmUpperCoverageLevelInput
} from '../../../components/formInputs/scenarioPiece/admUpperCoverageLevelInput.component';
import {
  AdmLowerCoverageLevelInput
} from '../../../components/formInputs/scenarioPiece/admLowerCoverageLevelInput.component';
import { AdmProtectionFactorInput } from '../../../components/formInputs/scenarioPiece/admProtectionFactor.component';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useFederalOfferData } from '../../../hooks/useFederalOfferData';
import { HighRiskType, ScenarioPieceType, UnitStructureCode } from '@silveus/calculations';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import {
  createScenarioPiece,
  removeScenarioPieceAndRecalculate,
  selectAllRowCropScenarioPiecesByScenarioMap
} from '../../../app/scenarioPiecesSlice';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { Quote } from '../../../types/api/Quote';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import useFormWrapper from '../../../hooks/useFormWrapper';
import useDrawerForm from '../../../hooks/useDrawerForm';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import { LowerCoverageLevelFields } from '../../../components/formInputs/scenarioPiece/lowerCoverageLevelInput.component';
import CountyYieldInputGroup, { CountyYieldInputGroupFields } from '../../../components/formInputs/scenario/countyYieldInputGroup';
import PriceInputGroup, { PriceInputGroupFields } from '../../../components/formInputs/scenario/priceInputGroup.component';
import { ProtectionFactorFields } from '../../../components/formInputs/scenarioPiece/protectionFactorInput.component';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import {
  UpperCoverageLevelFields
} from '../../../components/formInputs/scenarioPiece/upperCoverageLevelInput.component';
import { selectScenarioById, updateScenario } from '../../../app/scenariosSlice';
import { mergeScenarioWithYieldAndPriceUpdates } from '../../../utils/scenarioUpdateUtils';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { useScenarioPieceForm } from '../useScenarioPieceForm';
import { useStableInsurancePlanCodeArray } from '../../../hooks/useStableInsurancePlanCodeArray';

interface EcoScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  scenarioId: ScenarioId;
  year: number;
  countyId: string;
  typeId: string;
  highRiskTypeId: HighRiskType;
  practiceId: string;
  disabled: boolean;
  quote: Quote;
  handleCancel: () => void;
}

export type EcoScenarioPieceFormFields = ScenarioPieceFormFields & UpperCoverageLevelFields & LowerCoverageLevelFields & ProtectionFactorFields & PriceInputGroupFields & CountyYieldInputGroupFields;

const EcoScenarioPieceForm = ({ scenarioPiece, scenarioId, year, countyId, typeId, practiceId, highRiskTypeId, disabled, registerHeader, handleCancel, quote, handleValidation, isCanceling = false }: EcoScenarioPieceFormProps) => {
  const underlying = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioId).find(sp => [ScenarioPieceType.RP, ScenarioPieceType.RpHpe, ScenarioPieceType.YP, ScenarioPieceType.Aph].includes(sp.scenarioPieceType));

  let applicableScenarioPieceType = ScenarioPieceType.Unset;
  if (underlying?.scenarioPieceType === ScenarioPieceType.YP) applicableScenarioPieceType = ScenarioPieceType.EcoYp;
  else if (underlying?.scenarioPieceType === ScenarioPieceType.RP) applicableScenarioPieceType = ScenarioPieceType.EcoRp;
  else if (underlying?.scenarioPieceType === ScenarioPieceType.RpHpe) applicableScenarioPieceType = ScenarioPieceType.EcoRpHpe;
  else if (underlying?.scenarioPieceType === ScenarioPieceType.Aph) applicableScenarioPieceType = ScenarioPieceType.EcoYp;

  const scenarioPieceType = scenarioPiece?.scenarioPieceType ?? applicableScenarioPieceType;

  const dispatch = useAppDispatch();
  const methods = useScenarioPieceForm<EcoScenarioPieceFormFields>(scenarioPiece);
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  const formId = 'ecoScenarioPieceForm';

  useFederalOfferData(scenarioPiece, scenarioId, scenarioPieceType, year, countyId, typeId, practiceId, highRiskTypeId);

  const onSubmit: SubmitHandler<EcoScenarioPieceFormFields> = async data => {
    const planCode = getInsurancePlanCodeForScenarioPiece(scenarioPieceType);

    const newScenarioPiece: RowCropScenarioPiece = {
      isActive: data.isActive,
      isInvalid: false,
      lowerCoverageLevel: data.lowerCoverageLevel,
      planCode: scenarioPiece?.planCode ?? planCode,
      protectionFactor: data.protectionFactor,
      rowCropScenarioPieceExtendedData: null,
      rowCropScenarioPieceId: scenarioPiece?.rowCropScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: scenarioPieceType,
      unitStructure: UnitStructureCode.AU,
      upperCoverageLevel: data.upperCoverageLevel,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    if (scenario) {
      await dispatch(updateScenario({
        updatedScenario: mergeScenarioWithYieldAndPriceUpdates(scenario, data),
      }));
    }

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(createScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId, updatedScenarioPiece: newScenarioPiece }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };
  const planCode = getInsurancePlanCodeForScenarioPiece(scenarioPieceType);

  const insurancePlanCodes = useStableInsurancePlanCodeArray(planCode);

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AdmUpperCoverageLevelInput upperCoverageLevel={scenarioPiece?.upperCoverageLevel ?? null} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <AdmLowerCoverageLevelInput lowerCoverageLevel={86} disabled />
            </Grid>
            <Grid item xs={6}>
              <AdmProtectionFactorInput protectionFactor={scenarioPiece?.protectionFactor ?? null} disabled={disabled} />
            </Grid>
            <PriceInputGroup quote={quote} scenario={scenario} scenarioPiece={scenarioPiece} projectedPrice={scenario?.projectedPrice ?? null} harvestPrice={scenario?.harvestPrice ?? null} />
            <CountyYieldInputGroup
              year={year}
              countyId={countyId}
              volatility={scenario?.volatility ?? 0}
              coverageLevel={scenarioPiece?.upperCoverageLevel ?? null}
              typeId={scenario?.typeId ?? null}
              practiceId={scenario?.practiceId ?? null}
              commodityCode={quote.commodityCode}
              insurancePlanCodes={insurancePlanCodes}
              scenarioPiece={scenarioPiece}
              scenarioId={scenario?.scenarioId ?? null}
              expectedCountyYield={scenario?.expectedCountyYield ?? null}
              actualCountyYield={scenario?.actualCountyYield ?? null} />
            <Grid item xs={12}>
              <IsActiveInput isActive={scenarioPiece?.isActive ?? null} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
    </>
  );
};

export default EcoScenarioPieceForm;
