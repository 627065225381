import { Nullable } from '../types/util/Nullable';
import { QuoteId } from '../types/api/PrimaryKeys';
import { useAppSelector } from './reduxHooks';
import { selectQuoteById } from '../app/quotesSlice';
import { selectUnitYearDictionary } from '../app/unitsSlice';
import { getKeyedStateValues } from '../app/sliceHelpers';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../errors/state/MissingStateErrors';
import { selectClientFileById } from '../app/clientFilesSlice';
import {
  filterUnitYearsThatCanApplyToScenario,
  getOrderedDistinctUnitYearsThatCanApplyToScenario
} from '../utils/unitUtils';
import { HighRiskType } from '@silveus/calculations';

export const useUnitsForUnsavedScenario = (quoteId: QuoteId) => {
  const quote = useAppSelector(state => selectQuoteById(state, quoteId));
  if (quote === null) throw new MissingQuoteInStateError(quoteId);

  const clientFile = useAppSelector(state => selectClientFileById(state, quote.clientFileId));
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  const allUnitYears = getKeyedStateValues(useAppSelector(state => selectUnitYearDictionary(state)));
  return {
    getUnitYearsForUnsavedScenario: (typeId: Nullable<string>, practiceId: Nullable<string>, highRiskTypeId: HighRiskType) => {
      const filteredUnitYears = filterUnitYearsThatCanApplyToScenario(allUnitYears, clientFile, quote, typeId, practiceId, highRiskTypeId);
      return getOrderedDistinctUnitYearsThatCanApplyToScenario(filteredUnitYears);
    },
  };
};