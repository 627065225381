import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AppType, AppTypeAttributes } from '../../../types/api/enums/application/AppType.enum';
import { Nullable } from '../../../types/util/Nullable';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { AppTask } from '../../../types/api/AppTask';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';

type AppTypeInputProps = {
  value: Nullable<AppType>;
  index: number;
  application: ApplicationWizard;
  canEditAppTask: boolean;
}


const AppTypeInput = ({ value, index, application, canEditAppTask }: AppTypeInputProps) => {
  const inputName = `appTasks.${index}.appType`;
  const { control } = useFormContext();
  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });

  const options = isNullOrUndefined(currentAppTask.previousPolicyId)
    ? [AppTypeAttributes[AppType.New]]
    : [
      AppTypeAttributes[AppType.Cancel],
      AppTypeAttributes[AppType.CancelTransfer],
      AppTypeAttributes[AppType.Change],
      AppTypeAttributes[AppType.CommitNoChange],
      AppTypeAttributes[AppType.HoldForCancelTransfer],
    ];

  return (
    <Controller
      control={control}
      name={inputName}
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          disabled={!application.anyChanges || !canEditAppTask || isNotNullOrUndefined(currentAppTask.ctOriginalAppTaskId)}
          select
          error={!!error}
          inputRef={ref}
          size="small"
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          {options.map(appType => <MenuItem sx={{ fontSize: '12px' }} key={appType.value} value={appType.value}>{appType.name}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default AppTypeInput;