import { DBCore, Middleware } from 'dexie';
import { OfflineChangeTrackedEntity } from '../../types/app/OfflineChangeTrackedEntity';
import { isNotNilOrEmpty } from '../nullHandling';

export let __disableExcludeSoftDelete = false;
const shouldDisableSoftDeleteFilter = () => __disableExcludeSoftDelete;

export const ignoreSoftDeleteQueryFilter = async <T>(operation: () => Promise<T>) => {
  __disableExcludeSoftDelete = true;

  try {
    const result = await operation();
    return result;
  } finally {
    __disableExcludeSoftDelete = false;
  }
};

// This link has a lot of information about middleware implementations for Dexie
// https://github.com/dexie/Dexie.js/issues/1180

// Note: Filtering records is happening in memory because there does not appear to be an easy way
// to tie the filter into a where clause in all cases

export const excludeSoftDeletedMiddleware: Middleware<DBCore> = {
  stack: 'dbcore',
  create: down => ({
    ...down,
    table: tableName => {
      const downTable = down.table(tableName);
      return {
        ...downTable,
        get: async req => {
          const res = await downTable.get(req);

          if (shouldDisableSoftDeleteFilter()) {
            return res;
          }

          const dataResult = res as OfflineChangeTrackedEntity;

          return isNotNilOrEmpty(dataResult.offlineDeletedOn) ? undefined : dataResult;
        },

        getMany: async req => {
          const res = await downTable.getMany(req);

          if (shouldDisableSoftDeleteFilter()) {
            return res;
          }

          const dataResult = res as OfflineChangeTrackedEntity[];

          return dataResult.filter(entity => !isNotNilOrEmpty(entity.offlineDeletedOn));
        },

        query: async req => {
          const res = await downTable.query(req);

          if (shouldDisableSoftDeleteFilter()) {
            return res;
          }

          const dataResult = res.result as OfflineChangeTrackedEntity[];

          const alteredResult = dataResult.filter(entity => !isNotNilOrEmpty(entity.offlineDeletedOn));

          res.result = alteredResult;

          return res;
        },

        openCursor: async req => {
          const cursor = await downTable.openCursor(req);

          if (!cursor || shouldDisableSoftDeleteFilter()) {
            return cursor;
          }

          if (!(req.values ?? false)) {
            return cursor;
          }

          let currentValue = offlineDeletedOnMapper(cursor.value);

          return Object.create(cursor, {
            key: { get: () => cursor.key },
            primaryKey: { get: () => cursor.primaryKey },
            value: { get: () => currentValue },
            continue: {
              value: () => {
                cursor.continue();
                currentValue = (cursor.done ?? false) ? null : offlineDeletedOnMapper(cursor.value);
              },
            },
            continuePrimaryKey: {
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              value: (key: any, primaryKey: any) => {
                cursor.continuePrimaryKey(key, primaryKey);
                currentValue = (cursor.done ?? false) ? null : offlineDeletedOnMapper(cursor.value);
              },
            },
            start: {
              value: (onNext: () => void) => cursor.start(() => {
                onNext();
              }).catch(error => {
                cursor.fail(error);
              }),
            },
          });
        },

      };
    },
  }),
};

function offlineDeletedOnMapper(value: OfflineChangeTrackedEntity | undefined): OfflineChangeTrackedEntity | null | undefined {
  if (value && isNotNilOrEmpty(value.offlineDeletedOn)) {
    return null;
  }
  return value;
}