import { RowCropScenario } from '../../types/api/RowCropScenario';
import { Quote } from '../../types/api/Quote';
import { MatrixIncludeFilterType } from '../../types/api/enums/matrixIncludeFilterType/MatrixIncludeFilterType.enum';
import { MatrixShowFilterType } from '../../types/api/enums/matrixShowFilterType/MatrixShowFilterType.enum';
import { Nullable } from '../../types/util/Nullable';
import { roundToPlaces, roundUpToPlaces } from '@silveus/calculations';
import { isNullOrUndefined } from '../../utils/nullHandling';

export const defaultMatrixIncludeFilter: MatrixIncludeFilterType = MatrixIncludeFilterType.Net;
export const defaultMatrixShowFilter: MatrixShowFilterType = MatrixShowFilterType.PerAcre;
export function createMatrixDefaults(
  firstScenario: Nullable<RowCropScenario>,
  quote: Nullable<Quote>,
  scenarioApprovedYield: Nullable<number>,
  scenarioAdjustedYield: number,
  columnCount: number = 12,
  rowCount: number = 12) {

  let aph = 0;
  const applicableYield = scenarioApprovedYield ?? scenarioAdjustedYield;

  const blankDefaults = {
    midPrice: 0,
    priceScale: 0,
    topYield: 0,
    yieldScale: 0,
    columnCount: columnCount,
    rowCount: rowCount,
    includeFilter: defaultMatrixIncludeFilter,
    showFilter: defaultMatrixShowFilter,
  };

  if (!firstScenario) return blankDefaults;
  if (firstScenario.projectedPrice === null) throw new Error('Projected price is required');

  if (quote?.quickQuote === true) {
    if (isNullOrUndefined(firstScenario.quickUnit)) return blankDefaults;
    aph = firstScenario.quickUnit.aphYield;
  } else {
    aph = applicableYield;
  }


  //Default yield scale is half of the aph divided by the number of rows desired
  const yieldScale = roundToPlaces(aph / 2 / columnCount, 0);
  const startingPercentage = .6; //we want APH to be right of center - even further than the midpoint
  const topYield = calculateYieldValue(aph, columnCount, yieldScale, startingPercentage,
    topYieldPercentage);
  const midPrice = firstScenario.projectedPrice;
  const priceScale = roundToPlaces(midPrice * 0.02, 2);

  return {
    midPrice: midPrice,
    priceScale: priceScale,
    topYield: topYield,
    yieldScale: yieldScale,
    columnCount: columnCount,
    rowCount: rowCount,
    includeFilter: defaultMatrixIncludeFilter,
    showFilter: defaultMatrixShowFilter,
  };
}

//Calculates the yield at desired percentage using a starting percentage and yield
//For example, if you know the top yield and want determine the mid yield, use topYield = midYield
//as the startingYield, the startingPercentage will be 1 (since it is the highest value)
//and the desiredPercentage .51 (because we want the mid value but want it to default
//to the higher column if there is an even number) and of course columnCount (the total
//number of columns) and yieldScale (increment each column increases by) are needed to
//calculate it.
export function calculateYieldValue(startingYield: number, columnCount: number,
  yieldScale: number, startingPercentage: number, desiredPercentage: number) {
  const baseIndex = roundUpToPlaces(columnCount * startingPercentage, 0);
  const desiredIndex = roundUpToPlaces(columnCount * desiredPercentage, 0);
  return startingYield + (desiredIndex - baseIndex) * yieldScale;
}

export const midYieldPercentage = .51;
export const topYieldPercentage = 1;

export const matrixReportDataColumnCount = 14;
export const matrixReportDataRowCount = 18;

export const matrixReportTotalColumnCount = 15;
export const matrixReportTotalRowCount = 20;

export const matrixTopAxisLabel = 'Price';
export const matrixBottomAxisLabel = 'C. Yield';