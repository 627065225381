import { Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { ReactElement, MouseEvent, useState, useRef, useEffect, ComponentProps } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface CommandBarItem {
  iconButton: ReactElement;
  label: string;
  id: string;
  hide?: boolean;
  onclick: (e: MouseEvent<HTMLElement>) => void;
  /**
   * Optional render function for when you want to render a menu item that isn't just the standard
   * icon with label.
   */
  renderMenuItem?: () => ReactElement;
  disabled?: boolean;
  placement?: ComponentProps<typeof Tooltip>['placement'];
}

type CommandBarProps = {
  items: CommandBarItem[];
  hideOverflow?: boolean;
}

const CommandBar = ({ items, hideOverflow = false }: CommandBarProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [visibilityMap, setVisibilityMap] = useState(new Set<string>());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const visibleItems = items.filter(x => !(x.hide ?? false));

  const openMenu = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const updatedEntries = new Set<string>();
    for (const entry of entries) {
      if (entry.target instanceof HTMLElement) {
        const targetid = entry.target.id;
        if (entry.isIntersecting && targetid.length > 0) {
          updatedEntries.add(targetid);
        } else {
          updatedEntries.delete(targetid);
        }
      }
    }

    setVisibilityMap(updatedEntries);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: parentRef.current,
      threshold: 1,
    });

    if (parentRef.current?.children) {
      for (const item of Array.from(parentRef.current.children)) {
        if (item instanceof HTMLElement && item.id) {
          observer.observe(item);
        }
      }
    }
    return () => observer.disconnect();
  }, [parentRef, items]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: MouseEvent<HTMLElement>, item: CommandBarItem) => {
    item.onclick(e);
    handleClose();
  };

  return (
    <Grid container style={{ justifyContent: 'flex-end', flexWrap: 'nowrap' }}>
      <Grid container item style={{ overflow: 'hidden', flexWrap: 'nowrap' }} ref={parentRef}>
        <>
          {visibleItems.map((item, index) => {
            return (
              item.renderMenuItem ? (
                <div id={item.id} key={`commandbar-${item.label}-${index}`}>
                  {item.renderMenuItem()}
                </div>
              ) : (
                <Tooltip placement={item.placement ?? 'top'} title={item.label} key={`commandbar-${item.label}-${index}`} disableInteractive>
                  <div id={item.id} style={{ paddingLeft: '5px', visibility: `${visibilityMap.has(item.id) ? 'visible' : 'hidden'}` }} >
                    <IconButton color="primary" sx={{ pr: 0, pl: 0, mr: 0, ml: 0 }} disabled={item.disabled} onClick={item.onclick}>
                      {item.iconButton}
                    </IconButton>
                  </div>
                </Tooltip>
              ));
          })}
        </>
      </Grid>
      {!hideOverflow && visibleItems.length > visibilityMap.size && (
        <>
          <IconButton
            onClick={openMenu}
            color="primary"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={closeMenu}
          >
            {visibleItems.filter(item => !visibilityMap.has(item.id)).map((item, index) => {
              return (
                item.renderMenuItem ? (
                  <div id={item.id} key={`menu-${item.label}-${index}`}>
                    {item.renderMenuItem()}
                  </div>) : (
                  <MenuItem key={`menu-${item.label}-${index}`} onClick={e => handleClick(e, item)} disabled={item.disabled}>
                    <Typography variant="body2" noWrap>
                      {item.label}
                    </Typography>
                  </MenuItem>
                )
              );
            })}
          </Menu>
        </>
      )}
    </Grid>
  );
};

export default CommandBar;