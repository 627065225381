import { useAppSelector } from './reduxHooks';
import { ScenarioId } from '../types/api/PrimaryKeys';
import { selectAdjustedYieldForScenario, selectApprovedYieldForScenario, selectRateYieldForScenario } from '../app/unitsSlice';

const useYieldsForScenario = (scenarioId: ScenarioId, countyId: string, commodityCode: string) => {
  const approvedYield = useAppSelector(state => selectApprovedYieldForScenario(state, scenarioId, countyId, commodityCode));
  const rateYield = useAppSelector(state => selectRateYieldForScenario(state, scenarioId));
  const aphYield = useAppSelector(state => selectAdjustedYieldForScenario(state, scenarioId, countyId, commodityCode));
  const adjustedYield = useAppSelector(state => selectAdjustedYieldForScenario(state, scenarioId, countyId, commodityCode));

  return { approvedYield, rateYield, aphYield, adjustedYield };
};

export default useYieldsForScenario;
