import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppTaskDocumentId, AppTaskId } from '../types/api/PrimaryKeys';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { RootState } from './store';
import { AppTaskDocument } from '../types/api/appTaskDocument';
import { createAppAsyncThunk } from './thunkHelpers';
import { createAppTaskApplicationDocument, createAppTaskSupportingDocument, deleteAppTaskApplicationDocument, deleteAppTaskSupportingDocument, getAppTaskSubmissionDocuments, getAppTaskSupportingDocuments, registerApplicationDocumentForESign, registerSupportingDocumentForESign, updatedAppTaskLastGeneratedFormDate } from '../services/appTasks.service';
import { getKeyedStateGroupedBy } from './sliceHelpers';
import { AppTaskDocumentUpload } from '../pages/applications/appTaskDocumentUpload';
import { isNotNullOrUndefined, isNullOrUndefined } from '../utils/nullHandling';
import { AppTaskDocumentSource } from '../types/api/enums/application/appTaskDocumentSource.enum';
import { envBaseD365Url } from '../constants/envConstants';
import { toPrimaryKey } from '../utils/primaryKeyHelpers';
import { getItemsForId } from '../utils/mapHelpers';
import { ESignStatus } from '../types/api/enums/application/eSignStatus.enum';
import { beginLoading, endLoading } from './loaderSlice';
import { bulkESignDocuments, cancelESignRequest } from '../services/applicationDocuments.service';
import { openToast } from './toastSlice';
import { ESignData } from '../pages/applications/eSignModal';
import { AppDocumentType } from '../types/api/enums/application/appDocumentType.enum';
import { generateBulkESignRequest } from '../pages/applications/applicationMappers/applicationMapper';
import { AppTaskExtended } from '../types/api/AppTask';
import { ADMYear } from '../types/api/adm/ADMYear';

interface AppTaskDocumentState {
  allAppTaskDocuments: SliceDataState<AppTaskDocumentId, AppTaskDocument>;
}

const initialState: AppTaskDocumentState = {
  allAppTaskDocuments: initialSliceDataState(),
};

export const appTaskDocumentSlice = createSlice({
  name: 'appTaskDocuments',
  initialState: initialState,
  reducers: {
    // This is used to facilitate methods that pass in a different type (AppTaskDocumentUpload) and WITHOUT a document ID
    // so the store cannot track directly without transformation in between
    addAppTaskDocument(state, action: PayloadAction<AppTaskDocument>) {
      state.allAppTaskDocuments.data[action.payload.documentId] = action.payload;
    },
    clearAppTaskSupportingDocuments(state, action: PayloadAction<AppTaskDocument[]>) {
      for (const doc of action.payload) {
        delete state.allAppTaskDocuments.data[doc.documentId];
      }
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, d => d.allAppTaskDocuments, d => d.documentId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchAppTaskSupportingDocuments,
      affectedIds: () => [],
    });
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchAppTaskSubmissionDocuments,
      affectedIds: () => [],
    });
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeAppTaskSupportingDocument,
      affectedIds: arg => arg.documentId,
    });
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeAppTaskApplicationDocument,
      affectedIds: arg => arg.documentId,
    });
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: registerAppTaskApplicationDocumentForESign,
      affectedIds: args => args.documentId,
    });
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: registerAppTaskSupportingDocumentForESign,
      affectedIds: args => args.documentId,
    });
  },
});

const { addAppTaskDocument, clearAppTaskSupportingDocuments } = appTaskDocumentSlice.actions;

export default appTaskDocumentSlice.reducer;
export const selectAllAppTaskDocuments = (state: RootState) => state.appTaskDocuments.allAppTaskDocuments.data;
// Memoized Selectors
export const selectAllDocumentsByAppTask = createSelector([selectAllAppTaskDocuments], result => {
  return getKeyedStateGroupedBy(result, a => a.appTaskId);
});
export const selectDocumentsForAppTask = createSelector([
  selectAllDocumentsByAppTask,
  (_, appTaskId: AppTaskId) => appTaskId,
  (_1, _2, documentSource: AppTaskDocumentSource) => documentSource,
], (records, appTaskId, documentSource) => {
  if (isNullOrUndefined(appTaskId) || !records.has(appTaskId)) {
    return null;
  }
  const documents = records.get(appTaskId) || [];
  return documents.filter(d => d.appTaskDocumentSource === documentSource);
});

export const fetchAppTaskSupportingDocuments = createAppAsyncThunk('appTaskDocuments/fetchAppTaskSupportingDocuments', async (appTaskId: AppTaskId) => {
  const res = await getAppTaskSupportingDocuments(appTaskId);
  return res.data;
});

export const fetchAppTaskSubmissionDocuments = createAppAsyncThunk('appTaskDocuments/fetchAppTaskSubmissionDocuments', async (appTaskId: AppTaskId) => {
  const res = await getAppTaskSubmissionDocuments(appTaskId);
  return res.data;
});

export const fetchAndReplaceAppTaskDocuments = createAppAsyncThunk('appTaskDocuments/fetchAndReplaceAppTaskDocuments', async (appTaskId: AppTaskId, thunkApi) => {
  const state = thunkApi.getState();
  const docsByAppTaskId = selectAllDocumentsByAppTask(state);
  const allDocsForAppTask = getItemsForId(docsByAppTaskId, appTaskId);
  thunkApi.dispatch(clearAppTaskSupportingDocuments(allDocsForAppTask));

  const allPromises = [thunkApi.dispatch(fetchAppTaskSupportingDocuments(appTaskId)), thunkApi.dispatch(fetchAppTaskSubmissionDocuments(appTaskId))];
  await Promise.all(allPromises);

});

export const removeAppTaskSupportingDocument = createAppAsyncThunk('appTaskDocuments/removeAppTaskSupportingDocument', async (document: AppTaskDocument) => {
  await deleteAppTaskSupportingDocument(document.documentId);
  return document;
});

export const removeAppTaskApplicationDocument = createAppAsyncThunk('appTaskDocuments/removeAppTaskApplicationDocument', async (document: AppTaskDocument) => {
  await deleteAppTaskApplicationDocument(document.appTaskId);
  return document;
});

export const addAppTaskSupportingDocument = createAppAsyncThunk('appTaskDocuments/addAppTaskSupportingDocument', async (document: AppTaskDocumentUpload, thunkApi) => {
  const res = await createAppTaskSupportingDocument(document);

  const newDocument: AppTaskDocument = {
    appTaskId: document.appTaskId,
    documentId: res.data.createdId,
    documentName: document.documentName,
    appDocumentType: document.appDocumentType,
    eSignStatus: null,
    createdOn: new Date().toUTCString(),
    appTaskDocumentSource: AppTaskDocumentSource.SupportingDocument,
    wasGenerated: document.wasGenerated,
    envelopeId: null,
    fileURL: `${envBaseD365Url}/new_supportingdocuments(${res.data.createdId})/new_file/$value`,
    eSignAutoSubmitActionType: null,
  };
  thunkApi.dispatch(addAppTaskDocument(newDocument));
});

export const addAppTaskApplicationDocument = createAppAsyncThunk('appTaskDocuments/addAppTaskApplicationDocument', async (document: AppTaskDocumentUpload, thunkApi) => {
  await createAppTaskApplicationDocument(document);

  const newDocument: AppTaskDocument = {
    appTaskId: document.appTaskId,
    documentId: toPrimaryKey(document.appTaskId), // for the application, the documentId is being set to the appTaskId
    documentName: document.documentName,
    appDocumentType: document.appDocumentType,
    eSignStatus: null,
    createdOn: new Date().toUTCString(),
    appTaskDocumentSource: AppTaskDocumentSource.SupportingDocument,
    wasGenerated: document.wasGenerated,
    envelopeId: null,
    fileURL: `${envBaseD365Url}/new_apptasks(${document.appTaskId})/new_appdocument/$value`,
    eSignAutoSubmitActionType: null,
  };
  thunkApi.dispatch(addAppTaskDocument(newDocument));
  await updatedAppTaskLastGeneratedFormDate(document.appTaskId);
});

export const registerAppTaskApplicationDocumentForESign = createAsyncThunk('appTaskDocuments/registerAppTaskApplicationDocumentForESign', async (document: AppTaskDocument) => {
  await registerApplicationDocumentForESign(document);
  return document;
});

export const registerAppTaskSupportingDocumentForESign = createAsyncThunk('appTaskDocuments/registerAppTaskSupportingDocumentForESign', async (document: AppTaskDocument) => {
  await registerSupportingDocumentForESign(document);
  return document;
});

export const cancelESignDocumentRequest = createAppAsyncThunk('appTaskDocuments/cancelESignDocumentRequest', async (document: AppTaskDocument, thunkApi) => {
  if (isNullOrUndefined(document.envelopeId)) {
    // do what
    return;
  }

  thunkApi.dispatch(beginLoading());
  try {
    const cancelESignResult = await cancelESignRequest(document.envelopeId);

    if (isNotNullOrUndefined(cancelESignResult.errorMessage) && cancelESignResult.errorMessage.length > 0) {
      thunkApi.dispatch(openToast({ type: 'error', message: 'Error while cancelling E-Signature request', shouldTimeout: true, allowClickToClose: true }));
    } else {
      // Because of the known latency here between when this happens in DocuSign and when
      // we receive the status event in D365, we choose to preemptively set this state to voided
      const newDocument: AppTaskDocument = {
        appTaskId: document.appTaskId,
        documentId: document.documentId,
        documentName: document.documentName,
        appDocumentType: document.appDocumentType,
        eSignStatus: ESignStatus.Voided,
        createdOn: document.createdOn,
        appTaskDocumentSource: document.appTaskDocumentSource,
        wasGenerated: document.wasGenerated,
        envelopeId: document.envelopeId,
        fileURL: document.fileURL,
        eSignAutoSubmitActionType: null,
      };
      // update state here
      thunkApi.dispatch(addAppTaskDocument(newDocument));
      thunkApi.dispatch(openToast({ type: 'success', message: 'You have successfully cancelled your E-Signature request', shouldTimeout: true, allowClickToClose: true }));
    }
  } catch (e) {
    thunkApi.dispatch(openToast({ type: 'error', message: 'Error while cancelling E-Signature request', shouldTimeout: true, allowClickToClose: true }));
  } finally {
    thunkApi.dispatch(endLoading());
  }
});

export const handleESignRequest = createAppAsyncThunk('appTaskDocuments/handleESignRequest', async ({ appTask, eSignData, admData, documentsForAppTask }: {appTask: AppTaskExtended, eSignData: ESignData, admData: ADMYear[], documentsForAppTask: AppTaskDocument[]}, thunkApi) => {
  try {
    thunkApi.dispatch(beginLoading());

    const bulkESignRequest = await generateBulkESignRequest(appTask, eSignData, admData, documentsForAppTask);
    const bulkESignResult = await bulkESignDocuments(bulkESignRequest);

    if (isNotNullOrUndefined(bulkESignResult) && bulkESignResult.errorMessage?.length === 0) {
      for (const documentId of eSignData.eSignIncludedDocuments) {
        const appTaskDocument = documentsForAppTask.find(d => d.documentId === documentId);
        if (isNullOrUndefined(appTaskDocument)) throw Error('Cannot find document in app task documents');

        const eSignAppTaskDocument: AppTaskDocument = {
          ...appTaskDocument,
          envelopeId: bulkESignResult.envelopeId,
          eSignStatus: ESignStatus.Sent,
          eSignAutoSubmitActionType: eSignData.eSignSubmissionType,
        };

        if (appTaskDocument.appDocumentType === AppDocumentType.Application) {
          thunkApi.dispatch(registerAppTaskApplicationDocumentForESign(eSignAppTaskDocument));
        } else {
          thunkApi.dispatch(registerAppTaskSupportingDocumentForESign(eSignAppTaskDocument));
        }
      }
      thunkApi.dispatch(openToast({ type: 'success', message: 'You have successfully submitted your documents for E-Signature', shouldTimeout: true, allowClickToClose: true }));

    } else {
      thunkApi.dispatch(openToast({ type: 'error', message: 'Error while sending PDF for E-Signature', shouldTimeout: true, allowClickToClose: true }));
    }} catch (e) {
    thunkApi.dispatch(openToast({ type: 'error', message: 'Error while sending PDF for E-Signature', shouldTimeout: true, allowClickToClose: true }));
  } finally {
    thunkApi.dispatch(endLoading());
  }

});
