import { useEffect } from 'react';
import { isNullOrUndefined } from '../utils/nullHandling';
import { useFormContext, useWatch } from 'react-hook-form';
import { cup, getValueWithPercentChangeApplied, roundToPlaces } from '@silveus/calculations';

const usePercentChange = (expectedField: string, actualField: string, percentChangeField: string) => {
  const { control, setValue, getValues } = useFormContext();
  const percentChange = useWatch({ name: percentChangeField, control: control, defaultValue: undefined });

  useEffect(() => {
    if (isNullOrUndefined(percentChange)) return;

    const expectedValue = getValues(expectedField);
    const actualValue = cup(roundToPlaces(getValueWithPercentChangeApplied(expectedValue, percentChange), 2), 0);
    setValue(actualField, actualValue);
  }, [percentChange]);

};

export default usePercentChange;