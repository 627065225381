import { Tooltip } from '@mui/material';
import { IHeaderParams } from 'ag-grid-community';
import { ReactNode } from 'react';

export interface CustomValueHeaderParams<T> extends IHeaderParams<T> {
  headerValue: ReactNode;
  tooltip?: string;
}

export const CustomValueColumnHeader = <T,>(props: CustomValueHeaderParams<T>) => {
  return (
    <div className="ag-header-cell-label">
      {props.tooltip !== undefined ?
        <Tooltip title={props.tooltip} placement="top">
          <div>
            {props.headerValue}
          </div>
        </Tooltip>
        :
        <div>
          {props.headerValue}
        </div>
      }
    </div>
  );
};