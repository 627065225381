import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { cup, roundToPlaces } from '@silveus/calculations';

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const FreeDiskSpace = () => {
  const [remainingDiskSpace, setRemainingDiskSpace] = useState(0);

  useEffect(() => {
    navigator.storage.estimate().then(result => {
      const quote = result.quota ?? 0;
      const usage = result.usage ?? 0;
      setRemainingDiskSpace(roundToPlaces(cup(quote - usage, 0), 0));
    });
  }, []);

  return (
    <Typography>Current free disk space: {formatBytes(remainingDiskSpace)}</Typography>
  );
};

export default FreeDiskSpace;