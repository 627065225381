import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppTask } from '../../../../types/api/AppTask';
import { Change } from '../../../../types/api/applicationWizard/change';
import { AppTaskId } from '../../../../types/api/PrimaryKeys';
import CommitNoChangeDifferencesRow from './commitNoChangeDifferencesRow';


export type CommitNoChangeDifferencesModalProps = {
  appTasks: AppTask[];
  differences: Partial<Record<AppTaskId, Change[]>>;
  onClose: () => void;
  onContinue: () => void;
}


type Props = {
  modalProps: CommitNoChangeDifferencesModalProps;
}

const CommitNoChangeDifferencesModal = ({ modalProps }: Props) => {
  const { appTasks, onClose, differences, onContinue } = modalProps;

  const handleContinue = () => {
    onClose();
    onContinue();
  };

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs><Typography variant="h6">Unmatched App Tasks</Typography></Grid>
          <Grid item>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ flexDirection: 'column', overflow: 'auto' }}>
        <DialogContentText>The following Commit No Change App Tasks do not match the prior year coverage:</DialogContentText>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell size="small" sx={{ fontWeight: 600 }}>State</TableCell>
                <TableCell size="small" sx={{ fontWeight: 600 }}>County</TableCell>
                <TableCell size="small" sx={{ fontWeight: 600 }}>Product</TableCell>
                <TableCell size="small" sx={{ fontWeight: 600 }}>AIP</TableCell>
                <TableCell size="small" sx={{ fontWeight: 600 }}>Policy #</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appTasks.map(appTask => <CommitNoChangeDifferencesRow key={appTask.appTaskId} appTask={appTask} changes={differences[appTask.appTaskId] ?? []} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button id="btn-close" variant="outlined" onClick={onClose}>Cancel</Button>
        <Button id="btn-proceed" onClick={handleContinue}>Proceed Without Changes</Button>
      </DialogActions>
    </Dialog >
  );
};

export default CommitNoChangeDifferencesModal;