import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import ApplicationWizardPage from './applicationWizardPage';
import { ApplicationWizardStep, ApplicationWizardStepProps } from './applicationWizardStep';
import GroupIcon from '@mui/icons-material/Group';
import { Grid } from '@mui/material';
import ContactInfoGrid from '../../../components/ag-grid/contactInfoGrid/contactInfoGrid';
import { validatePersonOfInterest } from '../../../components/ag-grid/contactInfoGrid/validation/validateContactInfo';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { EntityTypeEnum } from '../../../types/api/enums/application/entityType.enum';
import { selectInsuredsPersonsOfInterestByType } from '../../../app/personOfInterestSlice';
import { RootState } from '../../../app/store';
import { selectEntityTypes, selectInsuredById, selectTaxTypes } from '../../../app/insuredsSlice';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import { PersonOfInterestTypes } from '../../../types/api/insureds/PersonOfInterest';

export const SBIInfoStep: ApplicationWizardStep = {
  isDisabled(app: ApplicationWizard, state: RootState) {
    // search within the entity types for the entity type name that matches the ENUM string
    // then take it's id and use it to compare if the contact info entity type is set to either one
    const entityTypes = state.insureds.entityTypes;
    const insuredId = app.isNewEntity && isNotNullOrUndefined(app.newInsuredId) ? app.newInsuredId : app.insuredId;
    const insured = selectInsuredById(state, insuredId);
    const individualEntityTypeId = entityTypes.find(entityType => entityType.name === EntityTypeEnum.Individuals)?.entityTypeId;
    const spousalEntityTypeId = entityTypes.find(entityType => entityType.name === EntityTypeEnum.SpousalMarried)?.entityTypeId;

    return !app.anyChanges
      || (!app.isGeneratingForms)
      || insured?.entityTypeId === individualEntityTypeId
      || insured?.entityTypeId === spousalEntityTypeId;

  },
  title: 'SBI Info',
  index: 4,
  formId: 'form-sbi-info',
  icon: <GroupIcon />,
  isValid(app: ApplicationWizard, state: RootState) {
    const isDisabled = this.isDisabled(app, state);
    // If step is disabled we do not care if all SBIs are valid here because the SBIs won't
    // be included in the application.
    if (isDisabled) {
      return {
        isValid: true,
        errors: [],
      };
    }

    const insuredId = app.isNewEntity && isNotNullOrUndefined(app.newInsuredId) ? app.newInsuredId : app.insuredId;
    const sbis = selectInsuredsPersonsOfInterestByType(state, insuredId, PersonOfInterestType.SBI);
    const taxIdTypes = selectTaxTypes(state);
    const entityTypes = selectEntityTypes(state);

    const entityTypesNotRequiringSBI = entityTypes.filter(entityType =>
      entityType.name.toLowerCase().indexOf('individual') >= 0 ||
      entityType.name.toLowerCase().indexOf('spousal') >= 0 ||
      entityType.name.toLowerCase().indexOf('bureau') >= 0 ||
      entityType.name.toLowerCase().indexOf('clubs') >= 0 ||
      entityType.name.toLowerCase().indexOf('public school') >= 0 ||
      entityType.name.toLowerCase().indexOf('public agency') >= 0 ||
      entityType.name.toLowerCase().indexOf('revocable trust') >= 0 ||
      entityType.name.toLowerCase().indexOf('irrevocable trust') >= 0 ||
      entityType.name.toLowerCase().indexOf('estates') >= 0);
    const insured = selectInsuredById(state, insuredId);
    if (!entityTypesNotRequiringSBI.find(x => x.entityTypeId === insured?.entityTypeId) && sbis.length === 0) {
      return {
        isValid: false,
        errors: ['No SBIs found'],
      };
    }

    const sbiValidationResults = sbis.map(x => validatePersonOfInterest(x, entityTypes, taxIdTypes, app.isGettingESignatures)).every(x => x === true);
    return {
      isValid: sbiValidationResults,
      errors: sbiValidationResults ? [] : ['Errors found on SBIs'],
    };
  },
};

const SBIInfoPage = ({ application, navigateToPreviousStep, navigateToNextStep, updateIsInEditMode, isInEditMode, isLoading, updateIsLoading }: ApplicationWizardStepProps) => {
  const handleNavigateToPrevious = () => {
    navigateToPreviousStep();
  };

  const personOfInterestTypes: PersonOfInterestTypes = [PersonOfInterestType.SBI];


  return (
    <ApplicationWizardPage loading={isLoading} tabIndex={SBIInfoStep.index} onNextClick={navigateToNextStep} onPreviousClick={handleNavigateToPrevious} disabled={isInEditMode || isLoading}>
      <Grid item container sx={{ flexDirection: 'row', flexWrap: 'nowrap', height: 'calc(100% - 55px)', pb: 1 }}>
        <ContactInfoGrid contactTypes={personOfInterestTypes} application={application} updateIsInEditMode={updateIsInEditMode} updateIsLoading={updateIsLoading} isLoading={isLoading} />
      </Grid>
    </ApplicationWizardPage>
  );
};

export default SBIInfoPage;
