import { getCommodityName, getCountyName, getCropTypeName, getPracticeName, getStateName } from './adm';

export function numberFormatter(params) {
  return params.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const numberSetter = (data, field, newValue, min, max) => {
  if (!newValue) {
    data[field] = newValue;
    return true;
  }
  const numValue = parseFloat(newValue.toString().replace(/,/g, ''));
  if (isNaN(numValue)) {
    return false;
  } else if (min !== undefined && numValue < min) {
    return false;
  } else if (max !== undefined && numValue > max) {
    return false;
  }
  data[field] = numValue;
  return true;
};

export const gridStatePersistence = id => {
  const setColumnState = e => {
    const model = e.columnApi.getColumnState();
    localStorage.setItem(`${id}-columnState`, JSON.stringify(model));
  };

  return {
    onDragStopped: setColumnState,
    onColumnVisible: setColumnState,
    onSortChanged: setColumnState,
    onFirstDataRendered: e => {
      if (localStorage.getItem(`${id}-columnState`)) {
        e.columnApi.applyColumnState({ state: JSON.parse(localStorage.getItem(`${id}-columnState`)), applyOrder: true });
      }
    },
  };
};

export const stateGetter = (admData, year) => params => getStateName(admData, year, params.data.countyId);
export const countyGetter = (admData, year) => params => getCountyName(admData, year, params.data.countyId);
export const commodityGetter = (admData, year) => params => getCommodityName(admData, year, params.data.commodityCode);
export const practiceGetter = (admData, year) => params => getPracticeName(admData, year, params.data.practiceId);
export const typeGetter = (admData, year) => params => getCropTypeName(admData, year, params.data.typeId);
