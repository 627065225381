import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { Quote } from '../types/api/Quote';
import { Nullable } from '../types/util/Nullable';
import { ClientFileId, QuoteId } from '../types/api/PrimaryKeys';
import { QuoteFormFields } from '../pages/quote/components/quoteForm.component';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { getKeyedStateGroupedBy, getStateIdsMatching } from './sliceHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import { ClientFileTabIndex, ClientFileTabs } from '../constants/clientFileTabs';
import {
  addScenarioQuickUnit,
  duplicateScenarios,
  selectAllScenariosByQuoteIdMap,
  selectScenarioById,
  setScenarioHighRiskType
} from './scenariosSlice';
import { ScenarioFormFields } from '../pages/scenario/components/scenarioForm.component';
import { getItemsForId, orderMap } from '../utils/mapHelpers';
import {
  selectAcresForScenario,
  selectAdjustedYieldForScenario,
  selectApprovedYieldForScenario,
  selectAverageSharePercentForScenario,
  selectRateYieldForScenario
} from './unitsSlice';
import {
  createQuoteRequest,
  deleteQuoteRequest,
  getQuoteRequest,
  getQuotesRequest,
  updateQuoteRequest,
  updateQuotesRequest
} from '../services/requestInterception/quoteRequestInterceptor';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import { HighRiskAttributes, HighRiskMetaTags, HighRiskType } from '@silveus/calculations';
import { produce } from 'immer';
import QuoteType from '../types/api/enums/quoteTypes/QuoteType.enum';

export interface QuotesState {
  allQuotes: SliceDataState<QuoteId, Quote>;
  quotesTabIndex: ClientFileTabIndex;
}

const initialState: QuotesState = {
  allQuotes: initialSliceDataState(),
  quotesTabIndex: ClientFileTabs.scenarios.index,
};

export const quotesSlice = createSlice({
  name: 'quotes',
  initialState: initialState,
  reducers: {
    setCurrentQuoteTabIndex(state, action: PayloadAction<ClientFileTabIndex>) {
      state.quotesTabIndex = action.payload;
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allQuotes, s => s.quoteId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addQuote,
      affectedIds: arg => arg.newQuote.quoteId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchQuote,
      affectedIds: arg => arg.quoteId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchQuotes,
      affectedIds: (arg, state) => getStateIdsMatching(state.allQuotes.data, s => s.clientFileId === arg.clientFileId, s => s.quoteId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'duplicating', thunk: copyQuote,
      affectedIds: arg => arg.quote.quoteId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeQuote,
      affectedIds: arg => arg.quote.quoteId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyQuote,
      affectedIds: arg => arg.quote.quoteId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyQuotes,
      affectedIds: arg => arg.quotes.map(q => q.quoteId),
    });
  },
});

// Memoized Selectors
const selectQuoteDictionary = (state: RootState) => state.quotes.allQuotes.data;
export const selectAllQuotesByClientFileMap = createSelector([selectQuoteDictionary], result => {
  const map = getKeyedStateGroupedBy(result, q => q.clientFileId);
  const ordered = orderMap(map, DefaultOrders.quotes);
  return ordered;
});

export const selectQuotesByIds = createSelector([selectQuoteDictionary, (_state: RootState, quoteIds: QuoteId[]) => quoteIds], (allQuotes, quoteIds) => {
  const quotes: Quote[] = [];

  for (const quoteId of quoteIds) {
    const quote = allQuotes[quoteId];
    if (quote === undefined) { continue; }
    quotes.push(quote);
  }

  return quotes;
});

export const selectQuotesByClientFileId = createSelector([selectAllQuotesByClientFileMap, (_state: RootState, clientFileId: ClientFileId) => clientFileId], (quotesByClientFile, clientFileId) => {
  return getItemsForId(quotesByClientFile, clientFileId);
});

export const selectQuoteById = (state: RootState, quoteId: QuoteId): Nullable<Quote> => state.quotes.allQuotes.data[quoteId] ?? null;
export const selectCurrentQuoteTabIndex = (state: RootState) => state.quotes.quotesTabIndex;
export const { setCurrentQuoteTabIndex } = quotesSlice.actions;

export const fetchQuotes = createAppAsyncThunk('quotes/fetchQuotes', async ({ clientFileId }: { clientFileId: ClientFileId }) => {
  return await getQuotesRequest(clientFileId);
});

export const removeQuote = createAppAsyncThunk('quotes/removeQuote', async ({ quote }: { quote: Quote }, thunkApi) => {
  await deleteQuoteRequest(quote.quoteId);

  return quote;
});

export const fetchQuote = createAppAsyncThunk('quotes/fetchQuote', async ({ quoteId }: { quoteId: QuoteId }) => {
  return await getQuoteRequest(quoteId);
});

export const addQuoteFromForm = createAppAsyncThunk('quotes/addQuoteFromForm', async ({ quoteFormFields, clientFileId }: { quoteFormFields: QuoteFormFields, clientFileId: ClientFileId }, thunkApi) => {
  const state = thunkApi.getState();
  const clientFileQuotes = selectQuotesByClientFileId(state, clientFileId);
  const sortIndex = clientFileQuotes.length;

  const newQuote: Quote = {
    ...quoteFormFields,
    quoteType: QuoteType.RowCrop,
    quoteId: generatePrimaryKey(),
    clientFileId: clientFileId,
    offlineCreatedOn: undefined,
    offlineLastUpdatedOn: undefined,
    offlineDeletedOn: undefined,
    sortIndex,
  };

  await thunkApi.dispatch(addQuote({ newQuote }));
  return newQuote;
});

export const addQuote = createAppAsyncThunk('quotes/addQuote', async ({ newQuote }: { newQuote: Quote }) => {
  await createQuoteRequest(newQuote);
  return newQuote;
});

export const copyQuote = createAppAsyncThunk('quotes/copyQuote', async ({ quote }: { quote: Quote }) => {
  const newQuote = { ...quote, quickQuote: true };
  await createQuoteRequest(quote);
  return newQuote;
});

export const modifyQuote = createAppAsyncThunk('quotes/modifyQuote', async ({ quote, quoteFormFields }: { quote: Quote, quoteFormFields?: QuoteFormFields }, thunkApi) => {
  const newQuote: Quote = { ...quote, ...quoteFormFields };

  await updateQuoteRequest(newQuote);

  const state = thunkApi.getState();
  // Getting Scenarios
  const scenarios = getItemsForId(selectAllScenariosByQuoteIdMap(state), newQuote.quoteId);
  const promises = scenarios.map(async scenario => {
    if (newQuote.quickQuote) {
      //Create new ScenarioQuickUnit If there is no quickUnit for a scenario.
      if (scenario.quickUnit === null) {
        const acres = selectAcresForScenario(state, scenario.scenarioId);
        const aphYield = selectAdjustedYieldForScenario(state, scenario.scenarioId, newQuote.countyId, newQuote.commodityCode);
        const rateYield = selectRateYieldForScenario(state, scenario.scenarioId);
        const approvedYield = selectApprovedYieldForScenario(state, scenario.scenarioId, newQuote.countyId, newQuote.commodityCode);
        const sharePercent = selectAverageSharePercentForScenario(state, scenario.scenarioId);

        const scenarioFormFields: ScenarioFormFields = {
          name: scenario.name,
          typeId: scenario.typeId,
          practiceId: scenario.practiceId ?? '',
          harvestPrice: scenario.harvestPrice ?? 0,
          projectedPrice: scenario.projectedPrice ?? 0,
          producerYield: scenario.actualProducerYield ?? 0,
          volatility: scenario.volatility ?? 0,
          expectedCountyYield: scenario.expectedCountyYield ?? 0,
          actualCountyYield: scenario.actualCountyYield ?? 0,
          scenarioColor: scenario.scenarioColor,
          acres: acres ?? 1000,
          sharePercent: sharePercent ?? 1,
          aphYield: aphYield ?? 0,
          rateYield: rateYield ?? 0,
          approvedYield: approvedYield ?? 0,
          highRiskTypeId: scenario.highRiskTypeId,
        };
        await thunkApi.dispatch(addScenarioQuickUnit({ scenarioFormFields: scenarioFormFields, quote: newQuote, scenarioId: scenario.scenarioId }));
      }

      //We need to get an updated version of the scenario here because addScenarioQuickUnit adds the created quick unit to the corresponding scenario
      // If setScenarioHighRiskType is called without an updated scenario then it will override the new quick unit that got added and cause local state to be incorrect
      const newState = thunkApi.getState();
      const updatedScenario = selectScenarioById(newState, scenario.scenarioId) ?? scenario;
      if (Object.values(HighRiskAttributes).find(x => x.value === updatedScenario.highRiskTypeId)?.elements?.includes(HighRiskMetaTags.AvailableIfUnitQuotingWithHighRiskOptions) ?? false) {
        await thunkApi.dispatch(setScenarioHighRiskType({ scenario: updatedScenario, highRiskType: HighRiskType.URA }));
      }
    }
  });
  await Promise.all(promises); //Resolved all promises

  return newQuote;
});

export const modifyQuotes = createAppAsyncThunk('quotes/modifyQuotes', async ({ quotes }: { quotes: Quote[] }) => {
  await updateQuotesRequest(quotes);

  return quotes;
});

export const sortQuotes = createAppAsyncThunk('quotes/sortQuotes', async ({ quotes }: { quotes: Quote[] }, thunkApi) => {
  const sortedQuotes = produce(quotes, draftQuotes => {
    for (let i = 0; i < draftQuotes.length; i++) {
      draftQuotes[i].sortIndex = i;
    }
  });

  return await thunkApi.dispatch(modifyQuotes({ quotes: sortedQuotes }));
});

const duplicateQuote = createAppAsyncThunk('quotes/duplicateQuote', async ({ quote, newClientFileId }: { quote: Quote, newClientFileId?: ClientFileId }, thunkApi) => {
  // Duplicate the Quote Level
  const newQuote: Quote = {
    ...quote,
    quoteId: generatePrimaryKey(),
    clientFileId: newClientFileId ?? quote.clientFileId,
  };

  await thunkApi.dispatch(addQuote({ newQuote }));

  const state = thunkApi.getState();
  const allScenariosByQuoteIdMap = selectAllScenariosByQuoteIdMap(state);

  // Duplicate the Scenarios for the source Quote, sending down the new Quote Id
  const scenariosForOriginalQuote = getItemsForId(allScenariosByQuoteIdMap, quote.quoteId);
  await thunkApi.dispatch(duplicateScenarios({
    scenarios: scenariosForOriginalQuote,
    scenarioSettings: {
      newQuoteId: newQuote.quoteId,
      color: 'copy',
      sortIndex: 'copy',
    },
  }));
});

export const duplicateQuotes = createAppAsyncThunk('quotes/duplicateQuotes', async ({ quotes, newClientFileId }: { quotes: Quote[], newClientFileId?: ClientFileId }, thunkApi) => {
  const promises = quotes.map(quote => thunkApi.dispatch(duplicateQuote({ quote, newClientFileId })));
  await Promise.all(promises);
});

export default quotesSlice.reducer;