import BaseUnit from '../../../types/api/BaseUnit';
import {
  CoverageTypeCode,
  VipIndemnityParams,
  VipManualPremiumParams,
  Unit,
  VipUnit,
  VipRequestDTO
} from '@silveus/calculations';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import { getUnitsForScenarioPiece } from '../calculationUtils';

export const createVipData = (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createVipPlanDto(scenarioPiece, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformVipData = (scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece): VipManualPremiumParams & VipIndemnityParams => {
  const vipParams: VipManualPremiumParams & VipIndemnityParams = {
    projectedPrice: scenario.projectedPrice ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
    underlyingScenarioPieceType: scenarioPiece.rowCropScenarioPieceExtendedData?.underlyingScenarioPieceType ?? 0,
    underlyingCoverageLevelPercent: (scenarioPiece.rowCropScenarioPieceExtendedData?.underlyingUpperCoverageLevel ?? 0) / 100,
    underlyingLiabilityAdjustmentFactor: (scenarioPiece.rowCropScenarioPieceExtendedData?.underlyingProtectionFactor ?? 0) / 100,
    priceCap: scenarioPiece.rowCropScenarioPieceExtendedData?.priceChangeCap ?? 0,
    intervalPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.topPrice ?? 0,
    liabilityAdjustmentFactor: scenarioPiece.protectionFactor / 100,

    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    unitStructureCode: scenarioPiece.unitStructure,
    coverageTypeCode: CoverageTypeCode.A,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,

    premiumPerAcre: scenarioPiece.rowCropScenarioPieceExtendedData?.customPremium ?? 0,
  };

  return vipParams;
};

const createVipUnits = (units: BaseUnit[]): (VipUnit & Unit)[] => {
  return units.map(unit => {
    const unitDtos = createBaseUnit(unit);
    const nonAreaRiskUnit: VipUnit & Unit = {
      ...unitDtos,

      // VipUnit
      id: unit.id,

      // Unit (comes from unitDtos)
    };

    return nonAreaRiskUnit;
  });
};

const createVipPlanDto = (scenarioPiece: RowCropScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): VipRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createVipUnits(
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
      ),
    ),
    ...transformVipData(scenario, scenarioPiece),
  };
};
