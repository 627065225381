import React, { ReactElement, SyntheticEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid, Stack, styled } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DrawerHeader from '../drawerHeader/drawerHeader.component';
import { TabPanel } from '../../utils/tabPanel';
import { isEqual } from 'lodash';
import { selectAppDrawerState } from '../../app/appDrawerSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectDrawerZoomPercentage } from '../../app/applicationZoomSlice';
import { Nullable } from '../../types/util/Nullable';
import { getScalingTransformString } from '../../utils/css/scaling';

export type DefaultFormSelection = {
  formName: Nullable<string>;
  id?: Nullable<string>;
  defaultFormSelection?: DefaultFormSelection;
}

export interface FormObject {
  formName: string;
  formComponent: FormRenderer;
}

export interface SharedFormWrapperProps extends FormWrapperProps {
  forms: FormObject[];
  newItemForm?: FormRenderer;
  defaultFormSelection?: DefaultFormSelection;
}

interface GenericFormWrapperProps extends SharedFormWrapperProps {
  formId: string;
  setFormId: (formId: string) => void;
  setSelectedFormName: (newFormName: string) => void;
  selectorComponent: ReactElement;
}
const AddNewItemButton = styled(Button)(({ theme }) => ({
  height: 45,
  width: 45,
  borderRadius: 0,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  margin: 0,
}));

export type FormWrapperHandler = (formHeaderTitle: string, formId: string) => void;

export type FormRenderer = (props: FormWrapperProps & { defaultFormSelection?: DefaultFormSelection }) => React.ReactElement;

export interface FormWrapperProps {
  registerHeader?: FormWrapperHandler;
  handleValidation?: (isSuccessful: boolean) => void;
  newItemForm?: FormRenderer;
  isCanceling?: boolean;
}

export interface FormWrapperForwardProps {
  handleChange: (newFormName: string) => void;
}

type UpdateSelectedFormInput = {
  current?: number;
  last?: number;
  lastClick?: number;
  prevDefault?: DefaultFormSelection;
  setPrevDefault?: boolean;
  shouldUseChildDef?: boolean;
  handleChange?: boolean;
  resetNewItem?: boolean;
}

enum PreSubmitClickedStateEnum {
  None,
  Plus,
  Form,
}

const newItemId = 'New';

// using a forward ref here encourages usage through a concrete wrapper that manages the ref.
// it couples this component to the concrete wrapper and allows the concrete wrapper to drive state changes here
const FormWrapper = forwardRef<FormWrapperForwardProps, GenericFormWrapperProps>(({ forms, formId, setFormId, setSelectedFormName, selectorComponent, defaultFormSelection, newItemForm, registerHeader, handleValidation, isCanceling = false }: GenericFormWrapperProps, ref) => {
  const zoomPercentage = useAppSelector(selectDrawerZoomPercentage);
  const isNewItemForm = defaultFormSelection?.formName === null || forms.length === 0;
  const newItemIndex = -1;
  const drawerState = useAppSelector(selectAppDrawerState);

  const canAddNewItems = newItemForm !== undefined;
  const isTopmostFormWrapper = registerHeader === undefined || handleValidation === undefined;

  //Try to find the index of the form with the name specified. If it can't be found, we should default the form index to 0
  const formIndexWithMatchingName = forms.findIndex(form => form.formName === defaultFormSelection?.formName);
  const defaultMatchingFormIndex = Math.max(formIndexWithMatchingName, 0);
  //If this is meant to be a new form return newItemIndex, otherwise return the index of the matching form
  const defaultFormIndex = isNewItemForm ? newItemIndex : defaultMatchingFormIndex;
  //If there are no forms, we don't want the user to be able to cancel out of the new item form
  const defaultLastFormIndex = forms.length === 0 ? newItemIndex : defaultMatchingFormIndex;
  const shouldShowFormSelector = forms.length > 1 || canAddNewItems;

  // references to keep state between renders and up to date with handlevalidation callback
  const lastForm = useRef(defaultLastFormIndex);
  const lastClickedForm = useRef(defaultFormIndex);
  const currentForm = useRef(defaultFormIndex);
  const currentForms = useRef(forms);
  const preSubmitButtonClicked = useRef(PreSubmitClickedStateEnum.None);
  const prevDefaultForm = useRef<DefaultFormSelection | undefined>();
  const shouldUseChildFormDefault = useRef(true);
  const requestId = useRef(drawerState.openDrawerRequestId);

  // use state for render tracking
  const [, setFormChanged] = useState(0);
  const [headerTitle, setHeaderTitle] = useState('Forms');
  const [shouldCancel, setShouldCancel] = useState(isCanceling);
  const [newItemPieceUi, setNewItemPieceUi] = useState<ReactElement>(<></>);

  const getNameForFormAtIndex = (index: number) => index === newItemIndex
    ? newItemId
    : currentForms.current.at(index)?.formName ?? currentForms.current[currentForms.current.length - 1].formName;

  // use effects
  useEffect(() => {
    setSelectedFormName(getNameForFormAtIndex(defaultFormIndex));
  }, []);

  useEffect(() => {
    resetNewItemPieceUi();
  }, [isCanceling, shouldCancel]);

  useEffect(() => {
    currentForms.current = forms;
    setSelectedFormName(getNameForFormAtIndex(currentForm.current));
  }, [forms]);

  // get new form
  function resetNewItemPieceUi() {
    if (canAddNewItems) {
      setNewItemPieceUi(newItemForm({ registerHeader: childSetSubmit, handleValidation: handleValidationResult, isCanceling: isCanceling || shouldCancel }));
    }
  }

  // form change handling
  function updateSelectedForm(
    {
      current,
      last,
      lastClick,
      prevDefault,
      setPrevDefault,
      shouldUseChildDef,
      handleChange,
      resetNewItem,
    }: UpdateSelectedFormInput) {

    let anyChanged = false;

    if ((handleChange ?? false) && current !== undefined) {
      last = currentForm.current;
    }

    if ((resetNewItem ?? false)) {
      setFormId('');
      resetNewItemPieceUi();
    }

    if (current !== undefined) {
      setSelectedFormName(getNameForFormAtIndex(current));
      currentForm.current = current;
      anyChanged = true;
    }

    if (last !== undefined) {
      lastForm.current = last;
      anyChanged = true;
    }

    if (lastClick !== undefined) {
      lastClickedForm.current = lastClick;
      anyChanged = true;
    }

    if ((setPrevDefault ?? false)) {
      prevDefaultForm.current = prevDefault;
      anyChanged = true;
    }

    if (shouldUseChildDef !== undefined) {
      shouldUseChildFormDefault.current = shouldUseChildDef;
      anyChanged = true;
    }

    // force render
    if (anyChanged) {
      setFormChanged(x => {
        // arbitrary number just to avoid the unlikely overflow
        if (x > 999999) {
          return 1;
        }

        return x + 1;
      });
    }
  }

  if (!isEqual(defaultFormSelection, prevDefaultForm.current)) {
    //If the default form changes, then we need to set the form to the new default form
    //This generally occurs when a button is pressed that "opens" one of the other forms in the set of forms
    updateSelectedForm({ current: defaultFormIndex, prevDefault: defaultFormSelection, setPrevDefault: true, shouldUseChildDef: true });
  }

  if (requestId.current !== drawerState.openDrawerRequestId) {
    requestId.current = drawerState.openDrawerRequestId;
    updateSelectedForm({ current: defaultFormIndex, lastClick: defaultFormIndex, shouldUseChildDef: true });
  }

  // Handling if the current form is outside the range of known forms.
  if (currentForm.current >= forms.length) {
    if (formIndexWithMatchingName === newItemIndex) {
      //Something happened such that we are now selected on a form that doesn't exist
      //If there are no forms, we should default to the new item page, otherwise go to the last form
      const indexToMoveTo = forms.length === 0 ? newItemIndex : forms.length - 1;

      updateSelectedForm({ current: indexToMoveTo, last: indexToMoveTo, lastClick: indexToMoveTo });
    } else if (forms.length > 0) {
      // This is a more general case - if there is a form available to switch to, switch to it.
      const indexToMoveTo = forms.length - 1;
      updateSelectedForm({ current: indexToMoveTo, last: indexToMoveTo, lastClick: indexToMoveTo });
    }
  }

  const childSetSubmit = (formHeaderTitle: string, formId: string) => {
    setHeaderTitle(formHeaderTitle);
    setFormId(formId);

    //Handle save will not be undefined when this component is nested within another formwrapper
    //When that's the case, we want to pass this data up to make sure they are in sync
    registerHeader && registerHeader(formHeaderTitle, formId);
  };

  // post submit handling
  const handleValidationResult = (isSuccessful: boolean) => {
    //This gets called by the onSubmit method of child forms once validation has been run
    //This is used to indicate whether validation succeeded or failed
    if (isSuccessful) {
      if (currentForm.current === newItemIndex) {
        // submitting new form
        switch (preSubmitButtonClicked.current) {
          case PreSubmitClickedStateEnum.None:
            // new item -> normal add
            updateSelectedForm({ current: currentForms.current.length - 1, last: currentForms.current.length - 1, lastClick: currentForms.current.length - 1, shouldUseChildDef: true, resetNewItem: true });
            break;
          case PreSubmitClickedStateEnum.Plus:
            // new item -> plus button
            updateSelectedForm({ current: newItemIndex, last: currentForms.current.length - 1, lastClick: currentForms.current.length - 1, shouldUseChildDef: true, resetNewItem: true });
            break;
          case PreSubmitClickedStateEnum.Form:
            // new item -> clicking on on another item
            updateSelectedForm({ current: lastClickedForm.current, lastClick: currentForms.current.length - 1, shouldUseChildDef: true, handleChange: true });
            break;
        }
      } else if (preSubmitButtonClicked.current === PreSubmitClickedStateEnum.Plus) {
        // + button while on existing form
        updateSelectedForm({ current: newItemIndex, shouldUseChildDef: false, handleChange: true });
      }
      else if (currentForm.current !== lastClickedForm.current) {
        // going from existing form to another
        updateSelectedForm({ current: lastClickedForm.current, shouldUseChildDef: false, handleChange: true });
      }

      preSubmitButtonClicked.current = PreSubmitClickedStateEnum.None;
      handleValidation && handleValidation(isSuccessful);
    } else {
      //If validation fails, then we don't want to change forms and
      //we want to reset the last form that was clicked to this form
      updateSelectedForm({ lastClick: currentForm.current });
      preSubmitButtonClicked.current = PreSubmitClickedStateEnum.None;
    }
  };

  //
  // items that can possibly trigger a submit/validation
  //

  // plus button
  const handleAddNewItemClick = (_: SyntheticEvent) => {
    //Handles the event for clicking the + button that moves to the new item form
    if (formId !== '') {
      preSubmitButtonClicked.current = PreSubmitClickedStateEnum.Plus;
    }
  };

  // cancel current item
  const handleNewItemFormCancelClick = (_: SyntheticEvent) => {
    //Handle clicking the "cancel" button on the new item form
    //Change back to whichever form we were on before we came here
    updateSelectedForm({ current: lastForm.current, resetNewItem: true });
  };

  // this is the parent close, not the local form cancel (in the case of like scenario piece)
  const handleCancel = () => {
    //Notifies children listening for this property that they need to run their cancellation methods
    setShouldCancel(true);
  };

  useImperativeHandle(ref, () => ({
    handleChange: (newFormName: string) => {
      //This is useful for cases where we may want to switch forms,
      // even if something that isn't actually a form is focused (such as with a new item form)
      const index = forms.findIndex(f => f.formName === newFormName);
      if (formId === '') {
        updateSelectedForm({ current: index, lastClick: index, handleChange: true });
        handleValidation && handleValidation(true);
      } else {
        preSubmitButtonClicked.current = PreSubmitClickedStateEnum.Form;
        updateSelectedForm({ lastClick: index });
      }
    },
  }));

  return (
    <>
      {isTopmostFormWrapper && <DrawerHeader title={headerTitle} onCancel={handleCancel} formId={formId} />}
      <Grid container alignItems="center" flexWrap="nowrap">
        <Grid item minWidth={0} xs sx={{ overflow: 'clip' }}>
          <>
            {shouldShowFormSelector &&
              <>{selectorComponent}</>
            }
          </>
        </Grid>
        <Grid item xs="auto">
          <>
            {canAddNewItems && <AddNewItemButton variant="contained" size="large" onClick={handleAddNewItemClick} startIcon={<ControlPointIcon />} type="submit" form={formId} />}
          </>
        </Grid>
      </Grid>
      <>
        {canAddNewItems &&
          <TabPanel key={newItemIndex} value={currentForm.current} index={newItemIndex}>
            {newItemPieceUi}
            <Stack direction="row" spacing={3} justifyContent="center" alignItems="center" sx={{ p: 0 }}>
              <Button id="btn-cancel-addpiece" variant="text" onClick={handleNewItemFormCancelClick}>Cancel</Button>
              <Button id="btn-save-addpiece" variant="contained" type="submit" form={formId}>Add</Button>
            </Stack>
          </TabPanel>
        }
        {forms.map((form, index) => {

          return (
            <TabPanel key={index} value={currentForm.current} index={index}>
              <Box
                overflow="auto"
                className="scroll-container"
                flexDirection="column"
                sx={{ p: 0, transform: getScalingTransformString(zoomPercentage), transformOrigin: 'top left' }}
              >
                {form.formComponent({
                  registerHeader: childSetSubmit,
                  handleValidation: handleValidationResult,
                  isCanceling: isCanceling || shouldCancel,
                  defaultFormSelection: shouldUseChildFormDefault.current ? defaultFormSelection?.defaultFormSelection : undefined,
                })}
              </Box>
            </TabPanel>
          );
        })}
      </>
    </>
  );
});

FormWrapper.displayName = 'FormWrapper';
export default FormWrapper;