//TODO: this is jsx, not tsx, makes difficult to figure out and not scope of lint task to fix
/* eslint-disable */
import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { Avatar, Badge, ClickAwayListener, Popper } from '@mui/material';
import { stringAvatar } from '../../utils/avatar';
import UserMenu from './userMenu.component';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectIsOnline } from '../../app/onlineSlice';

const UserIcon = () => {
  const { accounts } = useMsal();
  const online = useAppSelector(selectIsOnline);

  const [accountMenuActive, setAccountMenuActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleAccountMenu = event => {
    setAnchorEl(event.currentTarget);
    setAccountMenuActive(!accountMenuActive);
  };

  const handleClose = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }

    setAccountMenuActive(false);
  };

  if (accounts.length > 0) {
    return <>
      <Popper open={accountMenuActive} placement={'bottom-end'} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <UserMenu sx={{ mt: 3 }} close={() => setAccountMenuActive(false)} />
          </div>
        </ClickAwayListener>
      </Popper>
      <Badge variant="dot" color={online ? 'success' : 'error'} overlap="circular" anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }} sx={{ mb: 0.5, ml: 3 }} onClick={toggleAccountMenu}>
        <Avatar {...stringAvatar(accounts[0].name)} onClick={toggleAccountMenu} />
      </Badge>
    </>;
  }

  return null;
};

export default UserIcon;