import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { FormProvider, SubmitHandler, useWatch } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { createScenarioPiece, removeScenarioPieceAndRecalculate } from '../../../app/scenarioPiecesSlice';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import { ScenarioPieceFormFields, ScenarioPiecePlanCodeFields } from '../scenarioPieceForm.component';
import { useScenarioPieceForm } from '../useScenarioPieceForm';
import { Nullable } from '../../../types/util/Nullable';
import { ScenarioPieceType, UnitStructureCode, FarmBillCodeType } from '@silveus/calculations';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { Grid } from '@mui/material';
import { FarmBillCoverageTypeInput } from '../../../components/formInputs/scenarioPiece/farmBill/farmBillCoverageTypeInput.component';
import AcresInput from '../../../components/formInputs/acresInput.component';
import NumberTextField from '../../../components/numberTextField/numberTextField.component';
import { ExtendedDataFormInputParameterNames } from '../../../components/formInputs/constants/inputConstants';
import { RowCropScenarioPieceExtendedFormFields } from '../rowCropScenarioPieceExtendedFormFields.component';
import { FarmBillPayableEntitiesInput } from '../../../components/formInputs/scenarioPiece/farmBill/farmBillPayableEntitiesInput.component';
import { Quote } from '../../../types/api/Quote';
import FarmBillYieldInput from '../../../components/formInputs/scenarioPiece/farmBill/farmBillYieldInput';
import FarmBillBenchMarkYieldInputGroup from '../../../components/formInputs/scenarioPiece/farmBill/farmBillBenchMarkYieldInputGroup';
import FarmBillPriceInputGroup from '../../../components/formInputs/scenarioPiece/farmBill/farmBillPriceInputGroup';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { ScenarioPiecePlanSelection, ScenarioPiecePlanSelectionFormValues } from '../scenarioPiecePlanSelection.component';
import { ScenarioPieceFormSnapshot } from '../../../types/app/ScenarioPieceFormSnapshot';
import { useEffect } from 'react';
import { getFarmBillNetAcres } from '../../../services/calculations/farmBill/farmBillHelpers';
import { isNilOrEmpty } from '../../../utils/nullHandling';
import { selectScenarioById } from '../../../app/scenariosSlice';
import { MissingScenarioInStateError, MissingPracticeInScenarioError } from '../../../errors/state/MissingStateErrors';

export interface FarmBillScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  scenarioId: ScenarioId;
  year: number;
  disabled: boolean;
  quote: Quote;
  selectedScenarioPieceType: ScenarioPieceType;
  scenarioPieceTypePlanSelectionList: ScenarioPieceType[];
  scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null;
  updateScenarioPieceFormSnapshot: (updatePlanSelection: boolean, newPlanSelection: ScenarioPieceType, scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null) => void;
  handleCancel: () => void;
}

export type FarmBillScenarioPieceFormFields =
  ScenarioPieceFormFields
  & ScenarioPiecePlanCodeFields
  & RowCropScenarioPieceExtendedFormFields;

export const FarmBillScenarioPieceForm = (
  {
    scenarioPiece,
    scenarioId,
    year,
    disabled,
    quote,
    updateScenarioPieceFormSnapshot,
    scenarioPieceFormSnapshot,
    scenarioPieceTypePlanSelectionList,
    selectedScenarioPieceType,
    registerHeader,
    handleValidation,
    handleCancel,
    isCanceling = false,
  }: FarmBillScenarioPieceFormProps,
) => {
  const dispatch = useAppDispatch();
  const methods = useScenarioPieceForm<FarmBillScenarioPieceFormFields>(scenarioPiece);

  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  if (scenario === null) throw new MissingScenarioInStateError(scenarioId);
  if (scenario.practiceId === null) {
    throw new MissingPracticeInScenarioError(scenarioId);
  }

  const formId = 'farmBillScenarioPieceForm';

  const watchPlanCode = useWatch({
    control: methods.control,
    name: 'planCode',
    defaultValue: scenarioPiece?.planCode ?? FarmBillCodeType.County,
  });
  const valuePlanCode = watchPlanCode;

  const watchAcres = useWatch({
    control: methods.control,
    name: ExtendedDataFormInputParameterNames.Acres,
    defaultValue: scenarioPiece?.rowCropScenarioPieceExtendedData?.acres ?? 0,
  });
  const valueAcres = watchAcres ?? 0;

  const isPlc = ScenarioPieceType.Plc === selectedScenarioPieceType;
  const isArc = !isPlc;
  const netBaseAcres = getFarmBillNetAcres(selectedScenarioPieceType, valuePlanCode, valueAcres);
  const onSubmit: SubmitHandler<FarmBillScenarioPieceFormFields> = async data => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const pc = isNilOrEmpty(data.planCode) ? FarmBillCodeType.County : data.planCode;
    updateScenarioPieceFormSnapshot(false, ScenarioPieceType.Unset, null);

    const extendedData = data.rowCropScenarioPieceExtendedData;
    if (extendedData === null) throw new Error('Farm Bill extended data is unexpectedly null');

    const newScenarioPiece: RowCropScenarioPiece = {
      isActive: data.isActive,
      isInvalid: false,
      lowerCoverageLevel: 0,
      planCode: pc,
      protectionFactor: 0,
      rowCropScenarioPieceExtendedData: data.rowCropScenarioPieceExtendedData,
      rowCropScenarioPieceId: scenarioPiece?.rowCropScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: selectedScenarioPieceType,
      unitStructure: UnitStructureCode.AU,
      upperCoverageLevel: 0,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(createScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId, updatedScenarioPiece: newScenarioPiece }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  const getLatestFormValues = () => {
    const formValues = methods.getValues();
    const scenarioPieceFormValues: ScenarioPiecePlanSelectionFormValues = {
      ...formValues,
      unitStructure: UnitStructureCode.AU,
      upperCoverageLevel: 0,
      expectedCountyYield: null,
      actualCountyYield: null,
      protectionFactor: 0,
      projectedPrice: 0,
      producerYield: null,
      harvestPrice: 0,
      approvedYield: null,
    };
    return scenarioPieceFormValues;
  };

  useEffect(() => {
    let changeIt = false;
    if (scenarioPieceFormSnapshot?.scenarioPiece?.scenarioPieceType !== undefined &&
      scenarioPieceFormSnapshot.scenarioPiece.scenarioPieceType !== selectedScenarioPieceType) {
      changeIt = true;
    } else if (scenarioPiece !== null && scenarioPiece.scenarioPieceType !== selectedScenarioPieceType) {
      changeIt = true;
    }

    if (changeIt) {
      methods.setValue('scenarioPieceType', selectedScenarioPieceType, { shouldDirty: true });
    }
  }, [scenarioPieceFormSnapshot, selectedScenarioPieceType, scenarioPiece]);

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}>
            <Grid item xs={12}>
              <ScenarioPiecePlanSelection
                scenarioPieceFormSnapshot={scenarioPieceFormSnapshot}
                scenarioPiece={scenarioPiece}
                scenarioId={scenarioId}
                selectedScenarioPieceType={selectedScenarioPieceType}
                updateScenarioPieceFormSnapshot={updateScenarioPieceFormSnapshot}
                scenarioPieceTypePlanSelectionList={scenarioPieceTypePlanSelectionList}
                getScenarioPieceFormValues={getLatestFormValues}
              />
            </Grid>
            {isArc &&
              <Grid item xs={12}>
                <FarmBillCoverageTypeInput
                  disabled={disabled}
                  selectedCoverage={scenarioPiece?.planCode ?? null}
                />
              </Grid>
            }
            <Grid item xs={6}>
              <AcresInput
                label="Base Acres"
                disabled={disabled}
                name={ExtendedDataFormInputParameterNames.Acres}
                acres={scenarioPiece?.rowCropScenarioPieceExtendedData?.acres ?? 0} />
            </Grid>
            <Grid item xs={6}>
              <NumberTextField
                type="number"
                label="Net Base Acres"
                fullWidth
                size="small"
                value={netBaseAcres}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <FarmBillPayableEntitiesInput
                disabled={disabled}
                payableEntities={scenarioPiece?.rowCropScenarioPieceExtendedData?.payableEntitiesAmount ?? 1}
              />
            </Grid>
            {isPlc &&
              <Grid item xs={6}>
                <FarmBillYieldInput
                  isActual={true}
                  isArc={false}
                  name={ExtendedDataFormInputParameterNames.ActualYield}
                  disabled={disabled}
                  label="Payment Yield"
                  year={year}
                  commodityCode={quote.commodityCode}
                  countyId={quote.countyId}
                  theYield={scenarioPiece?.rowCropScenarioPieceExtendedData?.actualYield ?? null}
                  practiceId={scenario.practiceId}
                />
              </Grid>
            }
            <Grid item xs={12}>
              <FarmBillPriceInputGroup
                scenarioPieceType={selectedScenarioPieceType}
                disabled={disabled}
                year={year}
                commodityCode={quote.commodityCode}
                expectedPrice={scenarioPiece?.rowCropScenarioPieceExtendedData?.projectedPrice ?? null}
                actualPrice={scenarioPiece?.rowCropScenarioPieceExtendedData?.actualPrice ?? null}
              />
            </Grid>
            {isArc &&
              <Grid item xs={12}>
                <FarmBillBenchMarkYieldInputGroup
                  disabled={disabled}
                  year={year}
                  countyId={quote.countyId}
                  commodityCode={quote.commodityCode}
                  expectedYield={scenarioPiece?.rowCropScenarioPieceExtendedData?.expectedYield ?? null}
                  actualYield={scenarioPiece?.rowCropScenarioPieceExtendedData?.actualYield ?? null}
                  practiceId={scenario.practiceId}
                />
              </Grid>
            }
            <Grid item xs={12}>
              <IsActiveInput isActive={scenarioPiece?.isActive ?? null} />
            </Grid>
          </Grid>
        </form>
      </FormProvider >
      <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
    </>
  );
};
