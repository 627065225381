import axios from 'axios';
import { envBaseApiUrl } from '../constants/envConstants';
import { ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import { ScenarioPieceSelectedInterval } from '../types/api/adm/ScenarioPieceSelectedInterval';
import { IntervalRange } from '../types/api/adm/IntervalRange';
import { IntervalPrice } from '../types/api/adm/IntervalPrice';

export const getSelectedIntervals = (scenarioIds: ScenarioId[]) => axios.post<ScenarioPieceSelectedInterval[]>(`${envBaseApiUrl}/intervals/scenarios/`, scenarioIds);
export const setSelectedIntervals = (scenarioPieceSelectedIntervals: ScenarioPieceSelectedInterval[], scenarioPieceId: ScenarioPieceId) => axios.post<void>(`${envBaseApiUrl}/intervals/${scenarioPieceId}`, scenarioPieceSelectedIntervals);
export const getAvailableIntervals = (newOnly: boolean) => axios.get<IntervalRange[]>(`${envBaseApiUrl}/prices/get-intervals/`, { params: { newOnly } });
export const getIntervalPrices = (symbols: string[]) => axios.post<IntervalPrice[]>(`${envBaseApiUrl}/prices/get-interval-prices/`, symbols);
