import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../../types/util/Nullable';
import NumberTextField, { onNumericInputChange } from '../../../numberTextField/numberTextField.component';
import { Info } from '../../../info/info';
import { validateDollarAmount } from '../../../../pages/units/validations/genericDollarAmountValidation';
import usePercentChange from '../../../../hooks/usePercentChange';
import { FormInputProps } from '../../scenario/formInputProps';
import { getDiscoveryAwareStyling } from '../../../numberTextField/numberTextField.styles';
import { selectIsLightMode } from '../../../../app/userSettingsSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { getMpActualDiscoveryPeriod } from '../../../../utils/discoveryPeriodUtils';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

export interface MpActualInputCostsProps extends FormInputProps {
  commodityCode: string;
  year: number;
  actualInputCosts: Nullable<number>;
}

export type MpActualInputCostsFields = {
  actualInputCosts: number;
}

const MpActualInputCostsInput = ({ actualInputCosts, sx, commodityCode, year, autoFocus = false, disabled = false }: MpActualInputCostsProps) => {
  const fieldName = ExtendedDataFormInputParameterNames.ActualInputCosts;
  const { control } = useFormContext();

  const isLightMode = useAppSelector(selectIsLightMode);
  const mpDiscoveryPeriod = getMpActualDiscoveryPeriod(new Date(), commodityCode, year);

  usePercentChange(ExtendedDataFormInputParameterNames.ExpectedInputCosts, fieldName, 'inputCostsSlider');

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: (value: Nullable<number>) => validateDollarAmount(value, false) }}
      defaultValue={actualInputCosts ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          type="number"
          label="MP Actual Cost"
          fullWidth
          inputProps={{ step: 0.01, min: 0 }}
          InputProps={{
            endAdornment: <Info tooltipContent="MP Actual Input Costs" />,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          sx={{
            ...sx,
            ...getDiscoveryAwareStyling(isLightMode, mpDiscoveryPeriod),
          }}
          onChange={e => onNumericInputChange(e, field, 2)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default MpActualInputCostsInput;
