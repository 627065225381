import InsuranceCalendar from '../../../types/api/adm/InsuranceCalendar';
import InsurancePlanAttributes from '../../../types/api/enums/insurancePlan/InsurancePlanAttributes';
import InsurancePlan from '../../../types/api/enums/insurancePlan/InsurancePlan.enum';
import { Nullable } from '../../../types/util/Nullable';
import { QuoteSummaryImportantDates } from '../../../types/api/reports/QuoteSummaryImportantDates';
import { format } from 'date-fns';
import { BaseImportantDates } from '../../../types/api/reports/BaseImportantDates';
import { PremiumBreakdownImportantDates } from '../../../types/api/reports/PremiumBreakdownImportantDates';

const mpInsurancePlanCode = InsurancePlanAttributes[InsurancePlan.MP].name;
const mpHpoInsurancePlanCode = InsurancePlanAttributes[InsurancePlan.MpHpo].name;

export const getQuoteSummaryImportantDates = (insuranceCalendars: InsuranceCalendar[]): QuoteSummaryImportantDates[] => {

  return insuranceCalendars.map(insuranceCalendar => {
    const importantDate: QuoteSummaryImportantDates = {
      ...getImportantDates(insuranceCalendar),
      isMp: [mpInsurancePlanCode, mpHpoInsurancePlanCode].includes(insuranceCalendar.insurancePlanCode),
    };
    return importantDate;
  });
};

export const getPremiumBreakdownImportantDates = (scenarioCommodity: string, insuranceCalendars: InsuranceCalendar[]): PremiumBreakdownImportantDates[] => {
  return insuranceCalendars.map(insuranceCalendar => {
    const importantDateName = [mpInsurancePlanCode, mpHpoInsurancePlanCode].includes(insuranceCalendar.insurancePlanCode) ?
      `MP ${scenarioCommodity}` :
      scenarioCommodity;
    const importantDate: PremiumBreakdownImportantDates = {
      ...getImportantDates(insuranceCalendar),
      commodityName: importantDateName,
    };
    return importantDate;
  });
};


const getImportantDates = (insuranceCalendar: InsuranceCalendar): BaseImportantDates => {
  const getFormattedDate = (str: Nullable<string>) => {
    if (str === null) return '';

    const date = new Date(str);
    return format(date, 'MM/dd/yyyy');
  };

  return {
    acreageReporting: getFormattedDate(insuranceCalendar.acreageReportingDate),
    earlyPlant: getFormattedDate(insuranceCalendar.earliestPlantingDate),
    endOfLatePlanting: getFormattedDate(insuranceCalendar.endofLatePlantingPeriodDate),
    finalPlant: getFormattedDate(insuranceCalendar.extendedFinalPlantingDate),
    premiumReporting: getFormattedDate(insuranceCalendar.premiumBillingDate),
    productionReporting: getFormattedDate(insuranceCalendar.productionReportingDate),
    salesClosing: getFormattedDate(insuranceCalendar.salesClosingDate),
  };
};