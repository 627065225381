export const HailModalTabs = {
  planExplorer: {
    label: 'Plan Explorer',
    index: 0,
  },
  card: {
    label: 'Card',
    index: 1,
  },
  table: {
    label: 'Table',
    index: 2,
  },
  chart: {
    label: 'Chart',
    index: 3,
  },
} as const;

export type HailModalTabIndex = typeof HailModalTabs[keyof typeof HailModalTabs]['index'];