import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, Tooltip
} from '@mui/material';
import { MuiDialogCloseReason } from '../../types/mui/MuiDialogCloseReason';
import { ReactNode } from 'react';
import { DocumentTypeInput, GeneratedDocumentTypeFields } from '../formInputs/reports/documentTypeInput';
import { SelectedScenariosInput } from './selectedScenariosInput';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { selectIsReportLoading } from '../../app/reportsSlice';
import { useAppSelector } from '../../hooks/reduxHooks';

interface ReportGenerationModalProps {
  title: string;
  handleClose: () => void;
  open: boolean;
  additionalOptions: ReactNode[];
  formId: string;
  getScenarioSelectionContent?: (scenarioId: ScenarioId) => ReactNode;
  contentBeforeAdditionalOptions?: ReactNode;
  contentAfterAdditionalOptions?: ReactNode;
  includedScenarios: ScenarioId[];
  setIncludedScenarios: (scenarioIds: ScenarioId[]) => void;
}

export interface ReportFormFields extends GeneratedDocumentTypeFields {}

export const ReportGenerationModal = ({ title, handleClose, open, additionalOptions, formId, getScenarioSelectionContent, contentBeforeAdditionalOptions, contentAfterAdditionalOptions, includedScenarios, setIncludedScenarios }: ReportGenerationModalProps) => {
  const isReportLoading = useAppSelector(selectIsReportLoading);

  const handleCancel = async (closeReason?: MuiDialogCloseReason) => {
    if (closeReason === 'backdropClick') {
      return;
    }

    handleClose();
  };

  return (
    <Dialog onClose={(e, reason) => handleCancel(reason)} open={open} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item container direction="column" xs="auto">
            <Grid item xs="auto">
            Select scenarios to include:
            </Grid>
            <Grid item xs>
              <SelectedScenariosInput getScenarioSelectionContent={getScenarioSelectionContent} includedScenarios={includedScenarios} setIncludedScenarios={setIncludedScenarios} />
            </Grid>
          </Grid>
          <Grid item container xs="auto">
            {contentBeforeAdditionalOptions}
          </Grid>
          <Grid item container xs>
            <Grid item xs={12}>
            Additional Options:
            </Grid>
            {additionalOptions.map((option, index) => {
              return (
                <Grid key={index} item xs={6}>
                  {option}
                </Grid>
              );
            })}
          </Grid>
          <Grid item container xs="auto">
            {contentAfterAdditionalOptions}
          </Grid>
          <Grid item container xs>
            <Grid item xs={12}>
            Document Type
            </Grid>
            <Grid item xs={12}>
              <DocumentTypeInput />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} variant="outlined">Cancel</Button>
        <Tooltip title={includedScenarios.length < 1 ? 'Please select at least one scenario to generate a report' : undefined}>
          <span>
            <Button type="submit" form={formId} disabled={isReportLoading || includedScenarios.length < 1} sx={{ ml: 1 }}>
              { isReportLoading ? <CircularProgress color="inherit" size={25} /> : 'Generate' }
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};