import { Commodity, InsurancePlanCode, TypeCode } from '@silveus/calculations';
import { Nullable } from '../types/util/Nullable';
import { isNullOrUndefined } from './nullHandling';

export type PriceYieldVariation = {
  commodity: string;
  insurancePlanCode?: string;
  description: string;
  typeCode?: string;
}

type PriceYieldVariationRule = {
  commodity: string;
  insurancePlanCodes?: string[];
  description: string;
  typeCode?: string;
  defaultCalculator?: (includedInsurancePlanCodes: string[]) => Nullable<string>;
}

const priceDescription = 'Dry Beans and Dry Peas prices can differ between Revenue Plans and Yield Plans.';
const PriceVariations: PriceYieldVariationRule[] = [
  {
    commodity: Commodity.Dry_Beans,
    insurancePlanCodes: [InsurancePlanCode.YP],
    description: priceDescription,
    defaultCalculator: () => { return InsurancePlanCode.RP; },
  },
  {
    commodity: Commodity.Dry_Peas,
    insurancePlanCodes: [InsurancePlanCode.YP],
    description: priceDescription,
    defaultCalculator: () => { return InsurancePlanCode.RP; },
  },
];

export const getInsurancePlanCodeVariationForPrice = (commodityCode: string, includedScenarioPiecePlanCodes: string[]): Nullable<PriceYieldVariation> => {
  const possibleVariationRule = PriceVariations
    .find(pe => pe.commodity === commodityCode);

  if (isNullOrUndefined(possibleVariationRule)) return null;

  return getSingleVariationFromRule(possibleVariationRule, includedScenarioPiecePlanCodes);
};

const getSingleVariationFromRule = (priceYieldVariationRule: PriceYieldVariationRule, includedScenarioPiecePlanCodes: string[]): Nullable<PriceYieldVariation> => {
  // If the plan codes for the variation rule are undefined, then we should apply the rule regardless of currently selected plan codes
  if (isNullOrUndefined(priceYieldVariationRule.insurancePlanCodes)) {
    return {
      ...priceYieldVariationRule,
    };
  }

  const insurancePlanCode = priceYieldVariationRule.insurancePlanCodes.find(pc => includedScenarioPiecePlanCodes.includes(pc))
    ?? priceYieldVariationRule.defaultCalculator?.(includedScenarioPiecePlanCodes);

  if (isNullOrUndefined(insurancePlanCode)) return null;

  return {
    ...priceYieldVariationRule,
    insurancePlanCode: insurancePlanCode,
  };
};

const AreaYieldVariations: PriceYieldVariationRule[] = [
  {
    commodity: Commodity.Popcorn,
    typeCode: undefined,
    insurancePlanCodes: undefined,
    description: 'Popcorn County Yields can differ between SCO/ECO and ARP/ARP-HPE/AYP',
  },
  {
    commodity: Commodity.Corn,
    typeCode: TypeCode.Silage,
    insurancePlanCodes: [InsurancePlanCode.MP],
    description: 'MP County Yields can differ from standard county yields when the type is Silage.',
  },
];

export const getInsurancePlanCodeVariationForAreaYield = (commodityCode: string, includedScenarioPiecePlanCodes: string[], typeCode?: string): Nullable<PriceYieldVariation> => {
  const possibleVariationRule = AreaYieldVariations
    .find(aye => aye.commodity === commodityCode
      && (aye.typeCode === undefined || aye.typeCode === typeCode));

  if (isNullOrUndefined(possibleVariationRule)) return null;

  return getSingleVariationFromRule(possibleVariationRule, includedScenarioPiecePlanCodes);
};

export const doesPriceVariationRuleExistForPlanCode = (commodityCode: string, typeCode: string, planCode: string) => {
  return doesVariationRuleExistForPlanCode(commodityCode, typeCode, planCode, PriceVariations);
};

export const doesAreaYieldVariationRuleExistForPlanCode = (commodityCode: string, typeCode: string, planCode: string) => {
  return doesVariationRuleExistForPlanCode(commodityCode, typeCode, planCode, AreaYieldVariations);
};

const doesVariationRuleExistForPlanCode = (commodityCode: string, typeCode: string, planCode: string, variationRules: PriceYieldVariationRule[]) => {
  const variationRule = variationRules
    .find(rule => rule.commodity === commodityCode
      && (rule.typeCode === undefined || rule.typeCode === typeCode)
      && (rule.insurancePlanCodes === undefined || rule.insurancePlanCodes.includes(planCode)));

  return variationRule ? true : false;
};
