import NumberTextField, { onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';

interface BudgetInputProps {
  onBlur: () => void;
  value: number;
  name: string;
}

export type BudgetFields = {
  budget: number;
};

const BudgetInput = ({ onBlur, value, name }: BudgetInputProps) => {

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        min: VALIDATION_RULES.minimum(0),
      }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          type="number"
          fullWidth
          required
          size="small"
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          inputProps={{ sx: { padding: '4px !important', paddingRight: '8px !important', textAlign: 'right' } }}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          onBlur={() => onBlur()}
        />
      )}
    />
  );
};

export default BudgetInput;