import { Checkbox, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ChangeEvent, SyntheticEvent } from 'react';
import React from 'react';
import DataLocationType from '../constants/dataLocationType';
import ReconciliationType from '../constants/ReconciliationType';
import EntityType from '../constants/entityType';

interface DiffActionProps {
  entityType: EntityType;
  reconciliationType: ReconciliationType;
  onReconciliationTypeChange: (newReconciliationType: ReconciliationType) => void;
  areSomeSubEntitiesSelected: boolean;
  areAllSubEntitiesSelected: boolean;
  dataLocation: DataLocationType;
}

const DiffAction = ({ entityType, reconciliationType, onReconciliationTypeChange, areSomeSubEntitiesSelected, areAllSubEntitiesSelected, dataLocation }: DiffActionProps) => {
  const handleCheckboxValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onReconciliationTypeChange(e.target.checked ? dataLocation : 'unset');
  };

  const handleSelectValueChanged = (e: SelectChangeEvent) => {
    e.stopPropagation();
    onReconciliationTypeChange(e.target.value as ReconciliationType);
  };


  function handleClose(e: SyntheticEvent) {
    e.stopPropagation();
  }

  const handleCheckboxClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  switch (entityType) {
    case 'added':
    case 'deleted':
      return (
        <>
          <Select
            value={reconciliationType}
            onChange={handleSelectValueChanged}
            onClose={handleClose}
            sx={{ minWidth: 110 }}
            size="small"
          >
            <MenuItem value="keep">Keep</MenuItem>
            <MenuItem value="remove">Remove</MenuItem>
          </Select>
        </>
      );

    case 'conflicts':
    case 'unmodified':
    case 'non-conflicting':
      return (
        <>
          <Checkbox
            checked={reconciliationType === dataLocation}
            onChange={handleCheckboxValueChanged}
            onClick={handleCheckboxClicked}
            indeterminate={areSomeSubEntitiesSelected && !areAllSubEntitiesSelected}
          />
        </>
      );

    default:
      return <></>;
  }
};

export default DiffAction;