import EntityDiff from '../entityDiffing/entityDiff';
import {
  selectCommodity,
  selectCounty,
  selectPracticeById,
  selectStateFromCounty,
  selectTypeById
} from '../../../app/admSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import UnitAphDiff from './unitAphDiff';
import { ReactElement } from 'react';
import DataLocationType from '../constants/dataLocationType';
import { NamedReconciliationStack } from '../../../types/app/ReconciliationTrackedEntity';
import EntityDiffHeader from '../entityDiffing/entityDiffHeader';
import { roundToPlaces } from '@silveus/calculations';
import { createJoinedString } from '../utils/createJoinedString';

type StackType = NamedReconciliationStack<'unitYears'>;
type SubEntityStackType = NamedReconciliationStack<'unitYearAphs'>;

interface UnitDiffProps {
  unit: StackType;
  reconciledUnit: StackType;
  onReconciliationTypeChange: (entity: StackType) => void;
  dataLocation: DataLocationType;
}

const UnitDiff = ({ unit, reconciledUnit, onReconciliationTypeChange, dataLocation }: UnitDiffProps) => {
  const title = `Unit ${unit.entity.basicUnitNumber}-${unit.entity.optionalUnitNumber}`;
  const county = useAppSelector(state => selectCounty(state, unit.entity.countyId));
  const state = useAppSelector(state => selectStateFromCounty(state, unit.entity.countyId));
  const commodity = useAppSelector(state => selectCommodity(state, unit.entity.commodityCode));
  const cropType = useAppSelector(state => selectTypeById(state, unit.entity.typeId));
  const practice = useAppSelector(state => selectPracticeById(state, unit.entity.practiceId));

  const subtitle = createJoinedString([
    state?.name ?? null,
    county?.name ?? null,
    commodity?.name ?? null,
    unit.entity.farmName,
  ]);

  const detailComponents = [
    state?.name ?? null,
    county?.name ?? null,
    commodity?.name ?? null,
    practice?.abbrev ?? null,
    cropType?.abbrev ?? null,
    `${unit.entity.basicUnitNumber}-${unit.entity.optionalUnitNumber}`,
    unit.entity.farmNumber,
    unit.entity.farmNumber,
    unit.entity.section,
    unit.entity.township,
    unit.entity.range,
    unit.entity.location,
    `${unit.entity.acres} Acres`,
    `${roundToPlaces(unit.entity.sharePercent * 100, 0)}% Share`,
    unit.entity.subCountyCode,
  ];

  const getSubEntityDiffElements = (
    subEntities: SubEntityStackType[],
    subEntitySubset: SubEntityStackType[],
    onReconciliationTypeChange: (subEntity: SubEntityStackType) => void,
  ) => {
    const elements: ReactElement[] = [];

    if (subEntitySubset.length > 0) {
      const years = subEntitySubset.map(subsetEntity => subsetEntity.entity.year);
      const minYear = Math.min(...years);
      const maxYear = Math.max(...years);
      const unitHistorySubtitle = `${minYear} - ${maxYear}`;

      elements.push(
        <EntityDiffHeader
          title="Unit History"
          subtitle={unitHistorySubtitle}
          entityType="unmodified"
          isReconciled={subEntities.every(entity => entity.reconciliationType !== 'unset')}
          selectedReconciliationType={'unset'}
          onSelectedReconciliationTypeChanged={() => {}}
          areSomeSubEntitiesSelected={false}
          areAllSubEntitiesSelected={false}
          preventSelection={true}
          dataLocation={dataLocation}
        />,
      );
    }

    const orderedEntities = subEntitySubset.sort((a, b) => a.entity.year - b.entity.year);

    orderedEntities.forEach(subsetEntity => {
      const applicableEntity = subEntities.find(subEntity => subEntity.id === subsetEntity.id);

      if (applicableEntity === undefined) return;

      elements.push(
        <UnitAphDiff
          unitYearAph={subsetEntity}
          reconciledUnitYearAph={applicableEntity}
          onReconciliationTypeChange={onReconciliationTypeChange}
          dataLocation={dataLocation}
          shouldShowHeader={false}
          preventSelection
          key={subsetEntity.id}
        />,
      );
    });

    return elements;
  };

  return (
    <EntityDiff
      title={title}
      subEntitySelector={unit => unit.subCollections.unitYearAphs}
      subtitle={subtitle}
      trackedEntity={reconciledUnit}
      subsetEntity={unit}
      detailComponents={detailComponents}
      getSubEntityDiffElements={getSubEntityDiffElements}
      onReconciliationTypeChange={onReconciliationTypeChange}
      dataLocation={dataLocation}
    />
  );
};

export default UnitDiff;