import {
  AvailabilityService,
  ScenarioPieceGroupType,
  ScenarioPieceGroupTypeAttributes,
  ScenarioPieceType,
  ScenarioPieceTypeAttributes
} from '@silveus/calculations';
import InsurancePlanAttributes from '../types/api/enums/insurancePlan/InsurancePlanAttributes';
import { isNotNullOrUndefined } from './nullHandling';
import { IScenarioPieceGroupTypeEnumAttributes } from '@silveus/calculations';

export type ScenarioPieceTypeOption = {
  description: string,
  scenarioPieceType: ScenarioPieceType | ScenarioPieceGroupType,
};

export const getFriendlyScenarioPieceName = (scenarioPieceType: ScenarioPieceType) => {
  const spAttributes = Object.values(ScenarioPieceTypeAttributes).find(spt => spt.value === scenarioPieceType);
  let useIndividualPieceName = false;
  const matchingSpGroup = Object.values(ScenarioPieceGroupTypeAttributes).find(spg => spg.elements?.includes(scenarioPieceType) ?? false);
  if (matchingSpGroup !== undefined) {
    const matchingSpAttGroup = matchingSpGroup as IScenarioPieceGroupTypeEnumAttributes;
    useIndividualPieceName = matchingSpAttGroup.useIndividualPieceName;
  }

  if (useIndividualPieceName) {
    return spAttributes?.description ?? '';
  }

  return matchingSpGroup?.description ?? spAttributes?.description ?? '';
};

export const getInsurancePlanCodeForScenarioPiece = (scenarioPieceType: ScenarioPieceType): string => {
  return Object.values(InsurancePlanAttributes).find(plan => plan.value === scenarioPieceType)?.name ?? '';
};

// filter out unimplemented or old options
const unneededScenarioPieceTypes: ScenarioPieceType[] = [
  ScenarioPieceType.AqDol,
  ScenarioPieceType.Arh,
  ScenarioPieceType.Band,
  ScenarioPieceType.BandRevenue,
  ScenarioPieceType.BandRevenueHpe,
  ScenarioPieceType.BandYield,
  ScenarioPieceType.BoostMax,
  ScenarioPieceType.DO,
  ScenarioPieceType.FD,
  ScenarioPieceType.FuturesOptions,
  ScenarioPieceType.HrAce,
  ScenarioPieceType.PriceFlex,
  ScenarioPieceType.Prv,
  ScenarioPieceType.Tdo,
  ScenarioPieceType.Wfrp,
  ScenarioPieceType.Ydo,
];

const unneededScenarioPieceGroupTypes: ScenarioPieceGroupType[] = [
  ScenarioPieceGroupType.Hail,
];

const baselineSpAttributes = Object.values(ScenarioPieceTypeAttributes).filter(spa => !unneededScenarioPieceTypes.includes(spa.value)
  && !unneededScenarioPieceGroupTypes.some(spgt => ScenarioPieceGroupTypeAttributes[spgt].elements?.includes(spa.value)));

const baselineSpGroupAttributes = Object.values(ScenarioPieceGroupTypeAttributes).filter(spga => !unneededScenarioPieceGroupTypes.includes(spga.value));

export const getDistinctOrderedScenarioPieceTypeOptions = (spAttributes = baselineSpAttributes, spGroupAttributes = baselineSpGroupAttributes) => {
  const filteredSpAttributes = baselineSpAttributes.filter(bspa => spAttributes.find(spa => spa.value === bspa.value));
  const filteredSpGroupAttributes = baselineSpGroupAttributes.filter(bspga => isNotNullOrUndefined(spGroupAttributes.find(spga => spga.value === bspga.value)?.elements));
  const scenarioPieceTypeToOptionMap = new Map<ScenarioPieceType | ScenarioPieceGroupType, ScenarioPieceTypeOption>();
  const availableScenarioPieceTypeOptions: ScenarioPieceTypeOption[] = filteredSpAttributes
    .filter(spa => spa.value !== ScenarioPieceType.Unset)
    .map(spa => {
      const matchingSpGroup = filteredSpGroupAttributes.find(spga => spga.elements?.includes(spa.value));
      return {
        scenarioPieceType: matchingSpGroup?.value ?? spa.value,
        description: matchingSpGroup?.description ?? spa.description,
      };
    });

  for (const option of availableScenarioPieceTypeOptions) {
    scenarioPieceTypeToOptionMap.set(option.scenarioPieceType, option);
  }

  return AvailabilityService.sortScenarioPieces(scenarioPieceTypeToOptionMap);
};

export const getDistinctOrderedScenarioPieceTypes = (spAttributes = baselineSpAttributes, spGroupAttributes = baselineSpGroupAttributes) => Array.from(getDistinctOrderedScenarioPieceTypeOptions(spAttributes, spGroupAttributes).keys());

export const getFlattenedScenarioPieceTypes = (typesAndGroupTypes: (ScenarioPieceType | ScenarioPieceGroupType)[]): ScenarioPieceType[] =>
  typesAndGroupTypes.flatMap(spt => {
    const potentialGroupType = baselineSpGroupAttributes.find(spga => spga.value === spt);
    if (isNotNullOrUndefined(potentialGroupType)) return potentialGroupType.elements ?? [];
    else {
      const potentialScenarioPieceType = baselineSpAttributes.find(spa => spa.value === spt);
      if (isNotNullOrUndefined(potentialScenarioPieceType)) return potentialScenarioPieceType.value;
      return [];
    }
  });
