import { Button, Grid } from '@mui/material';
import HailTotalCard from './hailTotalCard';
import HailCard from './hailCard';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { HailProductId, HailScenarioPieceCompositionId, HailScenarioPieceId, UnitYearId } from '../../../types/api/PrimaryKeys';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import HailEndorsement from '../../../types/api/hail/hailEndorsement';
import HailRate from '../../../types/api/hail/hailRate';
import HailScenarioPieceRate from '../../../types/api/hail/HailScenarioPieceRate';
import { Quote } from '../../../types/api/Quote';
import UnitYear from '../../../types/api/UnitYear';
import HailScenarioPieceEndorsement from '../../../types/api/hail/HailScenarioPieceEndorsement';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { addHailScenarioPieceComposition } from '../../../app/hailSlice';
import HailScenarioPieceComposition from '../../../types/api/HailScenarioPieceComposition';
import { updateUnitGroupsForScenarioPieceWithSpecificUnits } from '../../../app/unitGroupsSlice';
import { updateCalculationForScenario } from '../../../app/calculationResultsSlice';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { closeDrawer, openDrawer, selectAppDrawerState } from '../../../app/appDrawerSlice';
import { selectHailScenarioPieceModalResultsByScenarioPieceId, selectHailTotals } from '../../../app/hailModalSlice';

type HailCardViewProps = {
  hailCompositionId: HailScenarioPieceCompositionId;
  scenario: RowCropScenario;
  hailScenarioPieces: Map<HailScenarioPieceId, HailScenarioPiece>;
  getHailProductById: (hailProductId: HailProductId) => HailProduct;
  getAvailableHailRatesByHailProductId: (hailProductId: HailProductId) => HailRate[];
  getAvailableHailEndorsementsByHailProductId: (hailProductId: HailProductId) => HailEndorsement[];
  getSelectedHailScenarioPieceUnits: (hailSenarioPieceId: HailScenarioPieceId) => UnitYearId[];
  getSelectedHailScenarioPieceSelectedRates: (hailSenarioPieceId: HailScenarioPieceId) => HailScenarioPieceRate[];
  getSelectedHailScenarioPieceSelectedEndorsements: (hailSenarioPieceId: HailScenarioPieceId) => HailScenarioPieceEndorsement[];
  onDeleteProductClick: (hailProduct: HailProduct) => void;
  onHailScenarioPieceUpdated: (hailScenarioPiece: HailScenarioPiece) => void;
  onHailScenarioPieceLossChanged: (updatedScenarioPiece: HailScenarioPiece) => void;
  onHailScenarioPieceRatesUpdated: (updatedScenarioPieceRates: HailScenarioPieceRate[]) => void;
  onHailScenarioPieceUnitsUpdated: (quote: Quote, scenario: RowCropScenario, hailScenarioPiece: HailScenarioPiece, updatedUnitSelection: UnitYear[]) => void;
  onHailScenarioPieceEndorsementsUpdated: (hailScenarioPieceId: HailScenarioPieceId, updatedHailScenarioPieceEndorsements: HailScenarioPieceEndorsement[]) => void;
}

export type HailCardFields =
  { hailScenarioPieces: HailScenarioPiece[] };

const HailCardView = ({ scenario,
  hailCompositionId,
  hailScenarioPieces,
  getHailProductById,
  getAvailableHailRatesByHailProductId,
  getAvailableHailEndorsementsByHailProductId,
  getSelectedHailScenarioPieceUnits,
  getSelectedHailScenarioPieceSelectedRates,
  getSelectedHailScenarioPieceSelectedEndorsements,
  onDeleteProductClick,
  onHailScenarioPieceUpdated,
  onHailScenarioPieceLossChanged,
  onHailScenarioPieceRatesUpdated,
  onHailScenarioPieceUnitsUpdated,
  onHailScenarioPieceEndorsementsUpdated }: HailCardViewProps) => {

  const hailCalculatedValues = useAppSelector(s => selectHailScenarioPieceModalResultsByScenarioPieceId(s));
  const hailTotals = useAppSelector(selectHailTotals);

  const dispatch = useAppDispatch();
  const methods = useForm<HailCardFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      hailScenarioPieces: Array.from(hailScenarioPieces.values()),
    },
  });

  const { trigger, setValue } = methods;
  const appDrawerState = useAppSelector(selectAppDrawerState);

  useEffect(() => {
    setValue('hailScenarioPieces', Array.from(hailScenarioPieces.values()));
  }, [hailScenarioPieces]);

  useEffect(() => {
    // Trigger validation on first
    trigger();
  }, [trigger]);

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'hailScenarioPieces',
  });

  const onSubmit: SubmitHandler<HailCardFields> = async data => {
    const hailScenarioPieceComposition: HailScenarioPieceComposition = {
      hailScenarioPieceCompositionId: generatePrimaryKey(),
      scenarioId: scenario.scenarioId,
      isActive: true,
      offlineCreatedOn: undefined,
      offlineLastUpdatedOn: undefined,
      offlineDeletedOn: undefined,
    };
    const hailScenarioPiecesForSubmit: HailScenarioPiece[] = [];
    const hailScenarioPieceRatesForSubmit: HailScenarioPieceRate[] = [];
    const hailScenarioPieceEndorsementsForSubmit: HailScenarioPieceEndorsement[] = [];

    hailScenarioPieces.forEach(hailScenarioPiece => {
      const updatedHailScenarioPiece: HailScenarioPiece = {
        ...hailScenarioPiece,
        hailScenarioPieceId: generatePrimaryKey(),
        hailScenarioPieceCompositionId: hailScenarioPieceComposition.hailScenarioPieceCompositionId,
      };
      hailScenarioPiecesForSubmit.push(updatedHailScenarioPiece);

      const rates = getSelectedHailScenarioPieceSelectedRates(hailScenarioPiece.hailScenarioPieceId);
      const updatedRates: HailScenarioPieceRate[] = rates.map(rate => {
        return {
          ...rate,
          hailScenarioPieceRateId: generatePrimaryKey(),
          hailScenarioPieceId: updatedHailScenarioPiece.hailScenarioPieceId,
        };
      });
      hailScenarioPieceRatesForSubmit.push(...updatedRates);

      const endorsements = getSelectedHailScenarioPieceSelectedEndorsements(hailScenarioPiece.hailScenarioPieceId);
      const updatedEndorsements: HailScenarioPieceEndorsement[] = endorsements.map(endorsement => {
        return {
          ...endorsement,
          hailScenarioPieceEndorsementId: generatePrimaryKey(),
          hailScenarioPieceId: updatedHailScenarioPiece.hailScenarioPieceId,
        };
      });
      hailScenarioPieceEndorsementsForSubmit.push(...updatedEndorsements);
    });

    await dispatch(addHailScenarioPieceComposition({
      hailScenarioPieceComposition,
      hailScenarioPieces: hailScenarioPiecesForSubmit,
      hailScenarioPieceRates: hailScenarioPieceRatesForSubmit,
      hailScenarioPieceEndorsements: hailScenarioPieceEndorsementsForSubmit,
    }));

    const updateUnitGroupPromises = Array.from(hailScenarioPieces.values()).map(hailScenarioPiece => {
      const hailScenarioPieceUnits = getSelectedHailScenarioPieceUnits(hailScenarioPiece.hailScenarioPieceId);
      return dispatch(updateUnitGroupsForScenarioPieceWithSpecificUnits({ scenarioPieceId: hailScenarioPiece.scenarioPieceId, selectedUnitYearIds: hailScenarioPieceUnits }));
    });
    await Promise.all(updateUnitGroupPromises);

    await dispatch(updateCalculationForScenario({ scenarioId: scenario.scenarioId }));

    //Because a user can open the hail quoter without already having added hail to the scenario, we need to re-open the drawer if it was already open to properly select the newly created hail tab.
    if (appDrawerState.isOpen && appDrawerState.openDrawer?.formName === 'scenarioPieceForm' && appDrawerState.openDrawer.scenarioId === scenario.scenarioId) {
      await dispatch(closeDrawer());
      await dispatch(openDrawer({ formName: 'scenarioPieceForm', scenarioId: scenario.scenarioId, quoteId: scenario.quoteId, defaultFormSelection: { formName: 'Hail' } }));
    }

  };
  return (
    <FormProvider {...methods}>
      <form id="hail-card-form" style={{ height: '100%' }} onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container direction="column" sx={{ height: '100%' }}>
          <Grid container item pt={1} pb={1} xs sx={{ overflowX: 'auto' }}>
            {/* Scrollable area should to only scroll cards */}
            <Grid container columnGap={1} item sx={{ width: 'fit-content', flexWrap: 'nowrap' }}>
              {fields.map((hailScenarioPiece, index) => {
                const calculatedValue = hailCalculatedValues.get(hailScenarioPiece.scenarioPieceId);
                return (
                  <HailCard
                    key={hailScenarioPiece.hailScenarioPieceId}
                    scenario={scenario}
                    hailScenarioPiece={hailScenarioPiece}
                    hailScenarioPieceUnits={getSelectedHailScenarioPieceUnits(hailScenarioPiece.hailScenarioPieceId)}
                    hailScenarioPieceRates={getSelectedHailScenarioPieceSelectedRates(hailScenarioPiece.hailScenarioPieceId)}
                    hailScenarioPieceEndorsements={getSelectedHailScenarioPieceSelectedEndorsements(hailScenarioPiece.hailScenarioPieceId)}
                    hailProduct={getHailProductById(hailScenarioPiece.hailProductId)}
                    availableHailRates={getAvailableHailRatesByHailProductId(hailScenarioPiece.hailProductId)}
                    availableHailEndorsements={getAvailableHailEndorsementsByHailProductId(hailScenarioPiece.hailProductId)}
                    onDeleteProductClick={onDeleteProductClick}
                    onHailScenarioPieceUpdated={onHailScenarioPieceUpdated}
                    onHailScenarioPieceLossChanged={onHailScenarioPieceLossChanged}
                    onHailScenarioPieceRatesUpdated={onHailScenarioPieceRatesUpdated}
                    onHailScenarioPieceUnitsUpdated={onHailScenarioPieceUnitsUpdated}
                    onHailScenarioPieceEndorsementsUpdated={onHailScenarioPieceEndorsementsUpdated}
                    calculatedValue={calculatedValue}
                    index={index}
                    disableButtonsAndFields={false}
                    shouldLimitHeight={false}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Grid container item sx={{ justifyContent: 'space-between', alignItems: 'center', height: '95px', pt: '0 !important' }}>
            <Grid item>
              <HailTotalCard hailTotals={hailTotals} />
            </Grid>
            <Grid item>
              <Button disabled={false} type="submit" id="btn-update-hail" variant="contained">Update Hail</Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default HailCardView;