import { InputBaseComponentProps } from '@mui/material';
import React from 'react';
import { IMaskInput } from 'react-imask';

const TaxIdMask = React.forwardRef<HTMLInputElement, InputBaseComponentProps >(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask="XXXXX0000"
        definitions={{
          X: {
            mask: '0',
            displayChar: '*',
            placeholderChar: '#',
          },
        }}
        inputRef={ref}
        // eslint-disable-next-line no-type-assertion/no-type-assertion
        onAccept={value =>  onChange ? onChange({ target: { value } } as unknown as React.ChangeEvent<HTMLInputElement>) : undefined}
        overwrite
      />
    );
  },
);

export default TaxIdMask;