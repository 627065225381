import { Grid, IconButton, Paper, Typography } from '@mui/material';
import classNames from 'classnames';
import CurrencyText from '../../components/currencyText/currencyText.component';
import { VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';

interface InactiveScenarioComponentCardProps<T> {
  scenarioComponent: T;
  isScenarioActive: boolean;
  label: string;
  updateScenarioComponent: (scenarioComponent: T, isActive: boolean) => Promise<void>;
}

const InactiveScenarioPieceCard = <T,>({ scenarioComponent, isScenarioActive, label, updateScenarioComponent }: InactiveScenarioComponentCardProps<T>) => {
  const onDisabledClick = async () => {
    await updateScenarioComponent(scenarioComponent, true);
  };

  return (
    <Paper variant="outlined" sx={{ pl: 0, pr: 0 }} className={classNames({ 'scenario-piece-card-box-shadow': isScenarioActive })}>
      <Grid className="inactive-scenario-piece-card1" container alignItems="center" padding={0} margin={0} sx={{ height: '50px' }}>
        <Grid item xs={0.25} sx={{
          height: '100%',
          backgroundColor: theme => theme.palette.scenarioPiece.inactivePiece,
          borderStyle: 'solid',
          borderColor: theme => theme.palette.scenarioPiece.inactivePiece,
          borderWidth: '4.5px', borderRadius: '3px 0 0 3px',
        }}>
        </Grid>
        <Grid item xs={0.25}>
        </Grid>
        <Grid item xs={5.5}>
          <Typography variant="body2" sx={{ color: theme => theme.palette.neutral.main }}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CurrencyText align="right" sx={{ fontSize: 14, fontWeight: 'bold', color: theme => theme.palette.scenarioPiece.inactivePiece }} currencyAmount={0} colorize={false} />
        </Grid>
        <Grid item xs={1}>
          <IconButton size="small" onClick={onDisabledClick}><VisibilityOffIcon color="disabled" fontSize="small" /></IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default InactiveScenarioPieceCard;