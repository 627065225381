import HistoricalYieldTrendYear from '../types/api/adm/HistoricalYieldTrendYear';
import { Nullable } from '../types/util/Nullable';
import { isNullOrUndefined } from './nullHandling';

export function getTrendBasedUponPriorTwoYears(historicalYields: HistoricalYieldTrendYear[], year: number): Nullable<number> {
  const oneYearPrior = historicalYields.find(areaYield => areaYield.year === year - 1);
  const twoYearsPrior = historicalYields.find(areaYield => areaYield.year === year - 2);
  if (isNullOrUndefined(oneYearPrior) || isNullOrUndefined(twoYearsPrior)) {
    return null;
  }
  const diff = twoYearsPrior.expectedYield - oneYearPrior.expectedYield;
  return oneYearPrior.expectedYield - diff;
}