import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { MenuItem, TextField } from '@mui/material';
import { SalesClosingDateId } from '../../../types/api/PrimaryKeys';
import { AppTask } from '../../../types/api/AppTask';
import { SalesClosingDate } from '../../../types/api/applicationWizard/salesClosingDate';
import { ProductType } from '../../../types/api/enums/application/productType.enum';
import { handleValidationResult } from './validationHelpers';
import { validateSalesClosingDate } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';

type SalesClosingDateInputProps = {
  value: Nullable<SalesClosingDateId>;
  index: number;
  salesClosingDates: SalesClosingDate[];
  canEditAppTask: boolean;
};

const SalesClosingDateInput = ({ value, index, salesClosingDates, canEditAppTask }: SalesClosingDateInputProps) => {
  const inputName = `appTasks.${index}.salesClosingDateId`;
  const { control } = useFormContext();

  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });

  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={value}
      rules={{ validate: value =>  handleValidationResult(validateSalesClosingDate(value, currentAppTask.productType)) }}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          select
          disabled={currentAppTask.productType !== ProductType.MPCI || !canEditAppTask}
          error={!!error}
          inputRef={ref}
          size="small"
          value={field.value}
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          {salesClosingDates.map(scd => <MenuItem sx={{ fontSize: '12px' }} key={scd.salesClosingDateId} value={scd.salesClosingDateId}>{scd.dateName}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default SalesClosingDateInput;