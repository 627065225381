import { OfflineChangeTrackedEntity } from '../../../types/app/OfflineChangeTrackedEntity';
import { Nullable } from '../../../types/util/Nullable';
import { compareEntities } from '../../../utils/entityComparison/compareEntities';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';
import { Guid, InsuredId } from '../../../types/api/PrimaryKeys';
import ReconciliationTrackedEntity, { NamedReconciliationStack, SubEntityCollectionTypeBase } from '../../../types/app/ReconciliationTrackedEntity';
import { groupBy } from '../../../utils/arrayUtils';
import EntityType from '../constants/entityType';
import ReconciliationType from '../constants/ReconciliationType';
import BulkInsuredData from '../../../types/api/offline/BulkInsuredData';
import { OfflineChangedEntitiesResult } from '../../../utils/dexieQueryHelpers/OfflineChangedEntitiesResult';
import { BulkServerDeletedStatusModel } from '../../../types/api/offline/BulkServerDeletedStatusModel';
import { getLatestDate } from '../utils/getLatestDate';
import { subCollectionObjectHasAnyValues } from '../utils/subCollectionHelpers';

const useConflictDetectedEntities = (
  serverModifiedEntities: Nullable<BulkInsuredData>,
  clientModifiedEntities: Nullable<OfflineChangedEntitiesResult>,
  insuredIds: InsuredId[],
  allClientEntities: Nullable<OfflineChangedEntitiesResult>,
  lastReconciledDate: Nullable<string>,
  serverDeletedEntities: Nullable<BulkServerDeletedStatusModel>,
  clientDeletedEntities: Nullable<OfflineChangedEntitiesResult>,
) => {
  const isDataInvalid = serverModifiedEntities === null || clientModifiedEntities === null ||
    allClientEntities === null || insuredIds.length === 0 ||
    lastReconciledDate === null || serverDeletedEntities === null ||
    clientDeletedEntities === null;

  if (isDataInvalid) return;

  const allClientEntitiesIncludingDeleted: OfflineChangedEntitiesResult = {
    clientFiles: allClientEntities.clientFiles.concat(clientDeletedEntities.clientFiles),
    historicalAnalyses: allClientEntities.historicalAnalyses.concat(clientDeletedEntities.historicalAnalyses),
    insureds: allClientEntities.insureds.concat(clientDeletedEntities.insureds),
    matrices: allClientEntities.matrices.concat(clientDeletedEntities.matrices),
    premiumBreakdowns: allClientEntities.premiumBreakdowns.concat(clientDeletedEntities.premiumBreakdowns),
    quotes: allClientEntities.quotes.concat(clientDeletedEntities.quotes),
    rowCropScenarioPieces: allClientEntities.rowCropScenarioPieces.concat(clientDeletedEntities.rowCropScenarioPieces),
    forwardSoldScenarioPieces: allClientEntities.forwardSoldScenarioPieces.concat(clientDeletedEntities.forwardSoldScenarioPieces),
    inputCostScenarioPieces: allClientEntities.inputCostScenarioPieces.concat(clientDeletedEntities.inputCostScenarioPieces),
    harvestRevenueScenarioPieces: allClientEntities.harvestRevenueScenarioPieces.concat(clientDeletedEntities.harvestRevenueScenarioPieces),
    rowCropScenarios: allClientEntities.rowCropScenarios.concat(clientDeletedEntities.rowCropScenarios),
    scenarioOptionUnitYears: allClientEntities.scenarioOptionUnitYears.concat(clientDeletedEntities.scenarioOptionUnitYears),
    scenarioOptions: allClientEntities.scenarioOptions.concat(clientDeletedEntities.scenarioOptions),
    scenarioQuickUnits: allClientEntities.scenarioQuickUnits.concat(clientDeletedEntities.scenarioQuickUnits),
    scenarioUnitYearAph: allClientEntities.scenarioUnitYearAph.concat(clientDeletedEntities.scenarioUnitYearAph),
    trendlineAnalyses: allClientEntities.trendlineAnalyses.concat(clientDeletedEntities.trendlineAnalyses),
    unitGroups: allClientEntities.unitGroups.concat(clientDeletedEntities.unitGroups),
    unitYearAph: allClientEntities.unitYearAph.concat(clientDeletedEntities.unitYearAph),
    unitYears: allClientEntities.unitYears.concat(clientDeletedEntities.unitYears),
    userSettings: allClientEntities.userSettings.concat(clientDeletedEntities.userSettings),
  };

  const unitAph = iterateLayer({
    serverModifiedEntities: serverModifiedEntities.unitYearAph,
    serverDeletedEntities: serverDeletedEntities.unitYearAphIds,
    clientModifiedEntities: clientModifiedEntities.unitYearAph,
    clientDeletedEntities: clientDeletedEntities.unitYearAph,
    allClientEntities: allClientEntitiesIncludingDeleted.unitYearAph,
    getId: unitYearAph => unitYearAph.unitYearAphId,
    getParentId: unitYearAph => unitYearAph.unitYearId,
    subEntityCollection: [],
    lastReconciledDate,
    shouldEntitiesBeCohesive: true,
  });

  const units = iterateLayer({
    serverModifiedEntities: serverModifiedEntities.unitYears,
    serverDeletedEntities: serverDeletedEntities.unitYearIds,
    clientModifiedEntities: clientModifiedEntities.unitYears,
    clientDeletedEntities: clientDeletedEntities.unitYears,
    allClientEntities: allClientEntitiesIncludingDeleted.unitYears,
    getId: unitYear => unitYear.unitYearId,
    getParentId: unitYear => unitYear.insuredId,
    subEntityCollection: [{
      entityKey: 'unitYearAphs',
      combinedSubEntities: unitAph.prunedCombinedEntityState,
      serverSubEntities: unitAph.prunedServerEntityState,
      clientSubEntities: unitAph.prunedClientEntityState,
    }],
    lastReconciledDate,
  });

  const rowCropScenarioPieces = iterateLayer({
    serverModifiedEntities: serverModifiedEntities.rowCropScenarioPieces,
    serverDeletedEntities: serverDeletedEntities.rowCropScenarioPieceIds,
    clientModifiedEntities: clientModifiedEntities.rowCropScenarioPieces,
    clientDeletedEntities: clientDeletedEntities.rowCropScenarioPieces,
    allClientEntities: allClientEntitiesIncludingDeleted.rowCropScenarioPieces,
    getId: rowCropScenarioPiece => rowCropScenarioPiece.scenarioPieceId,
    getParentId: rowCropScenarioPiece => rowCropScenarioPiece.scenarioId,
    subEntityCollection: [],
    lastReconciledDate,
    shouldEntitiesBeCohesive: true,
  });

  const forwardSoldScenarioPieces = iterateLayer({
    serverModifiedEntities: serverModifiedEntities.forwardSoldScenarioPieces,
    serverDeletedEntities: serverDeletedEntities.forwardSoldScenarioPieceIds,
    clientModifiedEntities: clientModifiedEntities.forwardSoldScenarioPieces,
    clientDeletedEntities: clientDeletedEntities.forwardSoldScenarioPieces,
    allClientEntities: allClientEntitiesIncludingDeleted.forwardSoldScenarioPieces,
    getId: rowCropScenarioPiece => rowCropScenarioPiece.scenarioPieceId,
    getParentId: rowCropScenarioPiece => rowCropScenarioPiece.scenarioId,
    subEntityCollection: [],
    lastReconciledDate,
    shouldEntitiesBeCohesive: true,
  });

  const inputCostScenarioPieces = iterateLayer({
    serverModifiedEntities: serverModifiedEntities.inputCostScenarioPieces,
    serverDeletedEntities: serverDeletedEntities.inputCostScenarioPieceIds,
    clientModifiedEntities: clientModifiedEntities.inputCostScenarioPieces,
    clientDeletedEntities: clientDeletedEntities.inputCostScenarioPieces,
    allClientEntities: allClientEntitiesIncludingDeleted.inputCostScenarioPieces,
    getId: rowCropScenarioPiece => rowCropScenarioPiece.scenarioPieceId,
    getParentId: rowCropScenarioPiece => rowCropScenarioPiece.scenarioId,
    subEntityCollection: [],
    lastReconciledDate,
    shouldEntitiesBeCohesive: true,
  });

  const harvestRevenueScenarioPieces = iterateLayer({
    serverModifiedEntities: serverModifiedEntities.harvestRevenueScenarioPieces,
    serverDeletedEntities: serverDeletedEntities.harvestRevenueScenarioPieceIds,
    clientModifiedEntities: clientModifiedEntities.harvestRevenueScenarioPieces,
    clientDeletedEntities: clientDeletedEntities.harvestRevenueScenarioPieces,
    allClientEntities: allClientEntitiesIncludingDeleted.harvestRevenueScenarioPieces,
    getId: rowCropScenarioPiece => rowCropScenarioPiece.scenarioPieceId,
    getParentId: rowCropScenarioPiece => rowCropScenarioPiece.scenarioId,
    subEntityCollection: [],
    lastReconciledDate,
    shouldEntitiesBeCohesive: true,
  });

  const scenarioOptions = iterateLayer({
    serverModifiedEntities: serverModifiedEntities.scenarioOptions,
    serverDeletedEntities: serverDeletedEntities.scenarioOptionIds,
    clientModifiedEntities: clientModifiedEntities.scenarioOptions,
    clientDeletedEntities: clientDeletedEntities.scenarioOptions,
    allClientEntities: allClientEntitiesIncludingDeleted.scenarioOptions,
    getId: scenarioOption => scenarioOption.scenarioOptionId,
    getParentId: scenarioOption => scenarioOption.scenarioId,
    subEntityCollection: [],
    lastReconciledDate,
    shouldEntitiesBeCohesive: true,
  });

  // Note -------- This block is all broken out into separate variables due to type challenges only.
  const rowCropScenarioPieceSubData = {
    entityKey: 'rowCropScenarioPieces',
    combinedSubEntities: rowCropScenarioPieces.prunedCombinedEntityState,
    serverSubEntities: rowCropScenarioPieces.prunedServerEntityState,
    clientSubEntities: rowCropScenarioPieces.prunedClientEntityState,
  } as const;

  const forwardSoldScenarioPieceSubData = {
    entityKey: 'forwardSoldScenarioPieces',
    combinedSubEntities: forwardSoldScenarioPieces.prunedCombinedEntityState,
    serverSubEntities: forwardSoldScenarioPieces.prunedServerEntityState,
    clientSubEntities: forwardSoldScenarioPieces.prunedClientEntityState,
  } as const;

  const inputCostScenarioPieceSubData = {
    entityKey: 'inputCostScenarioPieces',
    combinedSubEntities: inputCostScenarioPieces.prunedCombinedEntityState,
    serverSubEntities: inputCostScenarioPieces.prunedServerEntityState,
    clientSubEntities: inputCostScenarioPieces.prunedClientEntityState,
  } as const;

  const harvestRevenueScenarioPieceSubData = {
    entityKey: 'harvestRevenueScenarioPieces',
    combinedSubEntities: harvestRevenueScenarioPieces.prunedCombinedEntityState,
    serverSubEntities: harvestRevenueScenarioPieces.prunedServerEntityState,
    clientSubEntities: harvestRevenueScenarioPieces.prunedClientEntityState,
  } as const;

  const scenarioOptionSubData = {
    entityKey: 'scenarioOptions',
    combinedSubEntities: scenarioOptions.prunedCombinedEntityState,
    serverSubEntities: scenarioOptions.prunedServerEntityState,
    clientSubEntities: scenarioOptions.prunedClientEntityState,
  } as const;

  // This unknown => assert is where the type challenge actually manifests
  const scenarioSubEntityCollection = [
    rowCropScenarioPieceSubData,
    forwardSoldScenarioPieceSubData,
    inputCostScenarioPieceSubData,
    harvestRevenueScenarioPieceSubData,
    scenarioOptionSubData,
  ];

  // ----------------------------------

  const scenarios = iterateLayer({
    serverModifiedEntities: serverModifiedEntities.rowCropScenarios,
    serverDeletedEntities: serverDeletedEntities.rowCropScenarioIds,
    clientModifiedEntities: clientModifiedEntities.rowCropScenarios,
    clientDeletedEntities: clientDeletedEntities.rowCropScenarios,
    allClientEntities: allClientEntitiesIncludingDeleted.rowCropScenarios,
    getId: scenario => scenario.scenarioId,
    getParentId: scenario => scenario.quoteId,
    subEntityCollection: scenarioSubEntityCollection,
    lastReconciledDate,
  });

  const quotes = iterateLayer({
    serverModifiedEntities: serverModifiedEntities.quotes,
    serverDeletedEntities: serverDeletedEntities.quoteIds,
    clientModifiedEntities: clientModifiedEntities.quotes,
    clientDeletedEntities: clientDeletedEntities.quotes,
    allClientEntities: allClientEntitiesIncludingDeleted.quotes,
    getId: quote => quote.quoteId,
    getParentId: quote => quote.clientFileId,
    subEntityCollection: [{
      entityKey: 'rowCropScenarios',
      combinedSubEntities: scenarios.prunedCombinedEntityState,
      serverSubEntities: scenarios.prunedServerEntityState,
      clientSubEntities: scenarios.prunedClientEntityState,
    }],
    lastReconciledDate,
  });

  const clientFiles = iterateLayer({
    serverModifiedEntities: serverModifiedEntities.clientFiles,
    serverDeletedEntities: serverDeletedEntities.clientFileIds,
    clientModifiedEntities: clientModifiedEntities.clientFiles,
    clientDeletedEntities: clientDeletedEntities.clientFiles,
    allClientEntities: allClientEntitiesIncludingDeleted.clientFiles,
    getId: clientFile => clientFile.clientFileId,
    getParentId: clientFile => clientFile.insuredId ?? clientFile.agentTeamId,
    subEntityCollection: [{
      entityKey: 'quotes',
      combinedSubEntities: quotes.prunedCombinedEntityState,
      serverSubEntities: quotes.prunedServerEntityState,
      clientSubEntities: quotes.prunedClientEntityState,
    }],
    lastReconciledDate,
  });

  const insureds = iterateLayer({
    serverModifiedEntities: insuredIds,
    serverDeletedEntities: [],
    clientModifiedEntities: insuredIds,
    clientDeletedEntities: [],
    allClientEntities: insuredIds,
    getId: (insuredId: InsuredId) => insuredId,
    getParentId: (insuredId: InsuredId) => insuredId,
    subEntityCollection: [{
      entityKey: 'clientFiles',
      combinedSubEntities: clientFiles.prunedCombinedEntityState,
      serverSubEntities: clientFiles.prunedServerEntityState,
      clientSubEntities: clientFiles.prunedClientEntityState,
    }],
    lastReconciledDate,
  });

  const unitInsureds = iterateLayer({
    serverModifiedEntities: insuredIds,
    serverDeletedEntities: [],
    clientModifiedEntities: insuredIds,
    clientDeletedEntities: [],
    allClientEntities: insuredIds,
    getId: (insuredId: InsuredId) => insuredId,
    getParentId: (insuredId: InsuredId) => insuredId,
    subEntityCollection: [{
      entityKey: 'unitYears',
      combinedSubEntities: units.prunedCombinedEntityState,
      serverSubEntities: units.prunedServerEntityState,
      clientSubEntities: units.prunedClientEntityState,
    }],
    lastReconciledDate,
  });

  return {
    insureds: insureds as PrunedResponse<NamedReconciliationStack<'insureds'>>,
    unitInsureds: unitInsureds as PrunedResponse<NamedReconciliationStack<'unitInsureds'>>,
  };
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type PrunedResponse<T extends ReconciliationTrackedEntity<any, any, any, any>> = {
  prunedCombinedEntityState: T[];
  prunedServerEntityState: T[];
  prunedClientEntityState: T[];
};

const iterateLayerCollectionToSubEntityCollection = (
  collection: SingleSubEntityData[],
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  subCollection: (c: SingleSubEntityData) => ReconciliationTrackedEntity<any, any, any, any>[],
  entityId: string,
) => {
  const result: SubEntityCollectionTypeBase = {};
  for (const subEntityData of collection) {
    result[subEntityData.entityKey] = subCollection(subEntityData).filter(se => se.parentId === entityId);
  }

  return result;
};

type SingleSubEntityData = {
  entityKey: keyof SubEntityCollectionTypeBase;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  combinedSubEntities: ReconciliationTrackedEntity<any, any, any, any>[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  serverSubEntities: ReconciliationTrackedEntity<any, any, any, any>[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  clientSubEntities: ReconciliationTrackedEntity<any, any, any, any>[];
};

/**
 * @param serverModifiedEntities The entities that were modified on the server
 * @param clientModifiedEntities The entities that were modified on the client
 * @param allClientEntities All entities that exist on the client
 * @param getId A function that accepts an entity and returns its PK
 * @param getParentId A function that accepts an entity and returns the PK of its parent
 * @param subEntities A collection of child reconciliationTrackedEntities
 */
const iterateLayer = <
  Entity extends {},
  EntityId extends Guid,
  ParentId extends Guid>(
    {
      serverModifiedEntities, serverDeletedEntities, clientModifiedEntities,
      clientDeletedEntities, allClientEntities, getId, getParentId, subEntityCollection,
      lastReconciledDate, shouldEntitiesBeCohesive = false,
    }:
      {
        serverModifiedEntities: Entity[]; serverDeletedEntities: EntityId[]; clientModifiedEntities: Entity[];
        clientDeletedEntities: Entity[]; allClientEntities: Entity[]; getId: (entity: Entity) => EntityId;
        getParentId: (entity: Entity) => ParentId; subEntityCollection: SingleSubEntityData[];
        lastReconciledDate: string; shouldEntitiesBeCohesive?: boolean;
      },
  ) => {
  const serverModifiedIds = serverModifiedEntities.map(getId);
  const clientModifiedIds = clientModifiedEntities.map(getId);
  const clientEntityIds = allClientEntities.map(getId);
  const clientDeletedEntityIds = clientDeletedEntities.map(getId);

  const allEntityIds = Array.from(new Set([...serverModifiedIds, ...clientModifiedIds, ...clientEntityIds]));

  const clientEntityState: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId>[] = [];
  const serverEntityState: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId>[] = [];
  const combinedEntityState: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId>[] = [];

  allEntityIds.forEach(entityId => {
    const serverModifiedEntity = serverModifiedEntities.find(entity => getId(entity) === entityId);
    const clientModifiedEntity = clientModifiedEntities.find(entity => getId(entity) === entityId);
    const clientEntity = allClientEntities.find(entity => getId(entity) === entityId);


    const clientSubCollection = iterateLayerCollectionToSubEntityCollection(subEntityCollection, se => se.clientSubEntities, entityId);
    const serverSubCollection = iterateLayerCollectionToSubEntityCollection(subEntityCollection, se => se.serverSubEntities, entityId);
    const combinedSubCollection = iterateLayerCollectionToSubEntityCollection(subEntityCollection, se => se.combinedSubEntities, entityId);

    let clientEntityAdded = false;
    let serverEntityAdded = false;

    if (clientDeletedEntityIds.includes(entityId) && clientEntity !== undefined) {
      const newEntity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId> = {
        id: entityId,
        parentId: getParentId(clientEntity),
        entity: clientEntity,
        entityType: 'deleted',
        reconciliationType: 'remove',
        propertiesWithConflicts: [],
        subCollections: clientSubCollection,
      };
      clientEntityState.push(newEntity);
      combinedEntityState.push(newEntity);
      clientEntityAdded = true;
    }
    if (serverDeletedEntities.includes(entityId) && clientEntity !== undefined) {
      const newEntity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId> = {
        id: entityId,
        parentId: getParentId(clientEntity),
        entity: clientEntity,
        entityType: 'deleted',
        reconciliationType: 'remove',
        propertiesWithConflicts: [],
        subCollections: serverSubCollection,
      };
      serverEntityState.push(newEntity);
      if (!combinedEntityState.some(entityState => entityState.id === entityId))
        combinedEntityState.push(newEntity);
      serverEntityAdded = true;
    }

    //If the entities on both the client and server were modified, it either has conflicting changes, or is unmodified
    if (isNotNullOrUndefined(serverModifiedEntity) && isNotNullOrUndefined(clientModifiedEntity)) {
      //The entity was modified on both the client and the server, run a comparison on it
      const comparisonResult = compareEntities(clientModifiedEntity, serverModifiedEntity);

      if (comparisonResult.areSame) {
        const newEntity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId> = {
          id: entityId,
          parentId: getParentId(clientModifiedEntity),
          entity: clientModifiedEntity,
          entityType: 'unmodified',
          reconciliationType: 'unset',
          propertiesWithConflicts: comparisonResult.propsThatDiffer,
          subCollections: combinedSubCollection,
        };

        if (!clientEntityAdded) {
          clientEntityState.push({ ...newEntity, subCollections: clientSubCollection });
        }
        if (!serverEntityAdded) {
          serverEntityState.push({ ...newEntity, entity: serverModifiedEntity, subCollections: serverSubCollection });
        }
        if (!clientEntityAdded && !serverEntityAdded) {
          combinedEntityState.push(newEntity);
        }
      } else {
        const newEntity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId> = {
          id: entityId,
          parentId: getParentId(clientModifiedEntity),
          entity: clientModifiedEntity,
          entityType: 'conflicts',
          reconciliationType: 'unset',
          propertiesWithConflicts: comparisonResult.propsThatDiffer,
          subCollections: combinedSubCollection,
        };

        if (!clientEntityAdded) {
          clientEntityState.push({ ...newEntity, subCollections: clientSubCollection });
        }
        if (!serverEntityAdded) {
          serverEntityState.push({ ...newEntity, entity: serverModifiedEntity, subCollections: serverSubCollection });
        }
        if (!clientEntityAdded && !serverEntityAdded) {
          combinedEntityState.push(newEntity);
        }
      }
    }
    //The entity was changed on the client, but not on the server
    else if (isNullOrUndefined(serverModifiedEntity) && isNotNullOrUndefined(clientModifiedEntity)) {
      //If the created date of the client modified entity is earlier than the last reconciliation date,
      // this is added, otherwise it has non-conflicting changes
      let entityTypeToUse: EntityType;
      let reconciliationTypeToUse: ReconciliationType;

      const changedEntity = clientModifiedEntity as (Entity & OfflineChangeTrackedEntity);

      const createdDate = getLatestDate(changedEntity.offlineCreatedOn ?? null, lastReconciledDate);
      const wasCreatedOffline = createdDate !== lastReconciledDate;

      if (subCollectionObjectHasAnyValues(serverSubCollection)) {
        //The matching entity wasn't changed, but one of its children was
        // This entity should be non-conflicting, but not provide a default selection
        entityTypeToUse = 'non-conflicting';
        reconciliationTypeToUse = 'unset';
      } else if (wasCreatedOffline) {
        entityTypeToUse = 'added';
        reconciliationTypeToUse = 'keep';
      } else {
        //Modifications were made to the client entity and no changes were made to the server entity
        // Non-conflicting changes were made, mark it as such
        entityTypeToUse = 'non-conflicting';
        reconciliationTypeToUse = 'client';
      }

      const newEntity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId> = {
        id: entityId,
        parentId: getParentId(clientModifiedEntity),
        entity: clientModifiedEntity,
        entityType: entityTypeToUse,
        reconciliationType: reconciliationTypeToUse,
        propertiesWithConflicts: [],
        subCollections: combinedSubCollection,
      };

      if (!clientEntityAdded) {
        clientEntityState.push({ ...newEntity, subCollections: clientSubCollection });
      }
      if (!serverEntityAdded && subCollectionObjectHasAnyValues(serverSubCollection)) {
        serverEntityState.push({ ...newEntity, subCollections: serverSubCollection });
      }
      if (!clientEntityAdded && !serverEntityAdded) {
        combinedEntityState.push(newEntity);
      }
    }
    //The entity was modified on the server, but not on the client
    else if (isNullOrUndefined(clientModifiedEntity) && isNotNullOrUndefined(serverModifiedEntity)) {
      //If an entity exists at all on the client, this entity was added to the server
      // Otherwise, there were non-conflicting changes
      let entityTypeToUse: EntityType;
      let reconciliationTypeToUse: ReconciliationType;

      if (clientEntity === undefined) {
        //This entity was added to the server
        entityTypeToUse = 'added';
        reconciliationTypeToUse = 'keep';
      } else if (subCollectionObjectHasAnyValues(clientSubCollection)) {
        //This entity on the client-side that matches this wasn't modified, but one of its children was
        // This should be non-conflicting and set to unset, so a selection has to be made
        entityTypeToUse = 'non-conflicting';
        reconciliationTypeToUse = 'unset';
      } else {
        //This entity was modified with non-conflicting changes
        // We can safely set this to be selected
        entityTypeToUse = 'non-conflicting';
        reconciliationTypeToUse = 'server';
      }

      const newEntity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId> = {
        id: entityId,
        parentId: getParentId(serverModifiedEntity),
        entity: serverModifiedEntity,
        entityType: entityTypeToUse,
        reconciliationType: reconciliationTypeToUse,
        propertiesWithConflicts: [],
        subCollections: combinedSubCollection,
      };

      if (!clientEntityAdded && subCollectionObjectHasAnyValues(serverSubCollection) && clientEntity !== undefined) {
        clientEntityState.push({ ...newEntity, entity: clientEntity, subCollections: clientSubCollection });
      }
      if (!serverEntityAdded) {
        serverEntityState.push({ ...newEntity, subCollections: serverSubCollection });
      }
      if (!clientEntityAdded && !serverEntityAdded) {
        combinedEntityState.push(newEntity);
      }
    }
    //The entity was not modified on the client, nor on the server
    // Add this to the tree as unmodified so we can still keep track of its children if needed
    else {
      //We shouldn't be able to have a case where it doesn't exist on the
      // client and wasn't modified on the server, but we are still trying to reconcile it
      // Just entirely skip this case
      if (clientEntity === undefined) return;

      let entityTypeToUse: EntityType;
      let reconciliationTypeToUse: ReconciliationType;

      entityTypeToUse = 'unmodified';
      reconciliationTypeToUse = 'client';

      const newEntity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId> = {
        id: entityId,
        parentId: getParentId(clientEntity),
        entity: clientEntity,
        entityType: entityTypeToUse,
        //This reconciliation type should probably be unset, but due to needing to set unmodified to non-conflicting in some cases,
        // set this to client so we can allow it to properly count toward things being reconciled
        reconciliationType: reconciliationTypeToUse,
        propertiesWithConflicts: [],
        subCollections: combinedSubCollection,
      };

      if (!clientEntityAdded) {
        clientEntityState.push({ ...newEntity, subCollections: clientSubCollection });
      }
      if (!serverEntityAdded) {
        serverEntityState.push({ ...newEntity, subCollections: serverSubCollection });
      }
      if (!clientEntityAdded && !serverEntityAdded) {
        combinedEntityState.push(newEntity);
      }
    }
  });

  if (shouldEntitiesBeCohesive) {
    const combinedEntitiesGroupedByParentId = groupBy(combinedEntityState, e => e.parentId);
    const serverEntitiesGroupedByParentId = groupBy(serverEntityState, e => e.parentId);
    const clientEntitiesGroupedByParentId = groupBy(clientEntityState, e => e.parentId);

    const makeEntityGroupCohesive = (entityGroup: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId>[]) => {
      const areSomeEntitiesModified = entityGroup.some(entity => entity.entityType !== 'unmodified');
      if (areSomeEntitiesModified) {
        entityGroup.filter(entity => entity.entityType === 'unmodified').forEach(entity => entity.entityType = 'non-conflicting');
      }
    };

    combinedEntitiesGroupedByParentId.forEach(makeEntityGroupCohesive);
    serverEntitiesGroupedByParentId.forEach(makeEntityGroupCohesive);
    clientEntitiesGroupedByParentId.forEach(makeEntityGroupCohesive);
  }

  //The entity is unmodified, and it has no modified children, so it is entirely unchanged.
  const isUnchanged = (entity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionTypeBase, ParentId>) =>
    entity.entityType === 'unmodified' && !subCollectionObjectHasAnyValues(entity.subCollections);

  //Prune out all unchanged entities from the state tree
  const prunedCombinedEntityState = combinedEntityState.filter(es => !isUnchanged(es));
  const prunedServerEntityState = serverEntityState.filter(es => !isUnchanged(es));
  const prunedClientEntityState = clientEntityState.filter(es => !isUnchanged(es));

  const response = {
    prunedCombinedEntityState,
    prunedServerEntityState,
    prunedClientEntityState,
  };

  return response;
};

export default useConflictDetectedEntities;