import BaseUnit from '../../../types/api/BaseUnit';
import {
  FarmBillPlcPremiumParams,
  FarmBillPlcIndemnityParams,
  FarmBillPlcUnit,
  FarmBillPlcRequestDTO,
  UnitStructureCode,
  Commodity,
  CoverageTypeCode,
  Unit
} from '@silveus/calculations';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getUnitsForScenarioPiece } from '../calculationUtils';
import { farmBillMakeBaseUnitGroup, farmBillMakeBaseUnit, getFarmBillPercentAcresCoveredFromScenarioPiece } from './farmBillHelpers';
import { selectQuoteById } from '../../../app/quotesSlice';
import { MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';

export const createFarmBillPlcData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  const farmBillUnit = farmBillMakeBaseUnit(scenarioPiece);
  const unitGroupsForScenarioPiece = [farmBillMakeBaseUnitGroup(scenarioPiece, farmBillUnit)];
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, [farmBillUnit]);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createFarmBillPlcPlanDto(state, scenarioPiece, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformFarmBillPlcData = (state: RootState, scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece): FarmBillPlcPremiumParams & FarmBillPlcIndemnityParams => {
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const farmBillPlcParams: FarmBillPlcPremiumParams & FarmBillPlcIndemnityParams = {
    // farm bill specific
    baseAcres: scenarioPiece.rowCropScenarioPieceExtendedData?.acres ?? 0,
    percentAcresCovered: getFarmBillPercentAcresCoveredFromScenarioPiece(scenarioPiece.scenarioPieceType, scenarioPiece.planCode),
    payableEntities: scenarioPiece.rowCropScenarioPieceExtendedData?.payableEntitiesAmount ?? 0,
    finalAreaYield: scenarioPiece.rowCropScenarioPieceExtendedData?.actualYield ?? 0,
    projectedPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.projectedPrice ?? 0,
    harvestPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.actualPrice ?? 0,
    commodityCode: quote.commodityCode as Commodity,

    // needed only because of base classes
    expectedAreaYield: 0,
    upperCoverageLevelPercent: 0,
    lowerCoverageLevelPercent: 0,
    unitStructureCode: UnitStructureCode.AU,
    coverageTypeCode: CoverageTypeCode.A,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,
  };

  return farmBillPlcParams;
};

const createFarmBillPlcUnits = (units: BaseUnit[]): (FarmBillPlcUnit & Unit)[] => {
  return units.map(unit => {
    const unitDtos = createBaseUnit(unit);
    const nonAreaRiskUnit: FarmBillPlcUnit & Unit = {
      ...unitDtos,
      id: unit.id,
    };

    return nonAreaRiskUnit;
  });
};

const createFarmBillPlcPlanDto = (state: RootState, scenarioPiece: RowCropScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): FarmBillPlcRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createFarmBillPlcUnits(
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
      ),
    ),
    ...transformFarmBillPlcData(state, scenario, scenarioPiece),
  };
};