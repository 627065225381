import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, SxProps, TextField, Theme, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { Nullable } from '../../types/util/Nullable';
import { isNilOrEmpty, isNullOrUndefined } from '../../utils/nullHandling';
import { fetchCommodityTypesWithOffers, selectCommodityTypesWithOffers } from '../../app/admSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { validateType } from '../../pages/units/validations/typeValidation';

interface CommodityTypeProps {
  typeId: Nullable<string>;
  year: number;
  commodityCode: Nullable<string>;
  countyId: Nullable<string>;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

export type CommodityTypeFields = {
  typeId: string;
};

const CommodityTypeInput = ({ typeId, year, commodityCode, countyId, sx, autoFocus = false, disabled = false }: CommodityTypeProps) => {
  const { control, setValue } = useFormContext();
  const dispatch = useAppDispatch();
  const commodityTypes = useAppSelector(selectCommodityTypesWithOffers);

  const commodity = useWatch({ name: 'commodityCode', control: control, defaultValue: undefined });
  const county = useWatch({ name: 'countyId', control: control, defaultValue: undefined });

  useEffect(() => {
    if (isNullOrUndefined(commodityCode) || isNullOrUndefined(countyId)) return;

    dispatch(fetchCommodityTypesWithOffers({ year, commodityCode, countyId }));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(commodity)) return;

    setValue('typeId', '');

    if (!isNilOrEmpty(commodity))
      dispatch(fetchCommodityTypesWithOffers({ year, commodityCode: commodity, countyId: county }));
  }, [commodity]);

  return (
    <Controller
      name="typeId"
      control={control}
      rules={{ validate: validateType }}
      defaultValue={typeId}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <Tooltip title={commodityTypes.find(x => x.typeId === value)?.name ?? ''} enterDelay={500}>
          <TextField
            type="text"
            label="Type"
            fullWidth
            select
            error={!!error}
            value={value}
            helperText={error?.message}
            inputRef={ref}
            {...field}
            autoFocus={autoFocus}
            sx={sx}
            disabled={disabled}
            size="small"
          >
            {commodityTypes.map(t => <MenuItem key={t.typeId} value={t.typeId}>{t.name}</MenuItem>)}
          </TextField>
        </Tooltip>
      )}
    />
  );
};

export default CommodityTypeInput;
