import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { MatrixIncludeFilterTypeAttributes } from '../../../types/api/enums/matrixIncludeFilterType/MatrixIncludeFilterTypeAttributes';
import { MatrixIncludeFilterType } from '../../../types/api/enums/matrixIncludeFilterType/MatrixIncludeFilterType.enum';

interface Props {
  includeFilter: MatrixIncludeFilterType;
  className?: string;
}

export type MatrixIncludeFilterFields = {
  includeFilter: MatrixIncludeFilterType;
};

export const MatrixIncludeFilterInput = ({ includeFilter, className }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="includeFilter"
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={includeFilter}
      control={control}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          className={className}
          type="text"
          label="Include"
          fullWidth
          select
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => field.onChange(e.target.value)}
        >
          <MenuItem
            value={MatrixIncludeFilterTypeAttributes[MatrixIncludeFilterType.Net].value}
          >
            {MatrixIncludeFilterTypeAttributes[MatrixIncludeFilterType.Net].description}
          </MenuItem>
          <MenuItem
            value={MatrixIncludeFilterTypeAttributes[MatrixIncludeFilterType.Gross].value}
          >
            {MatrixIncludeFilterTypeAttributes[MatrixIncludeFilterType.Gross].description}
          </MenuItem>
        </TextField>
      )}
    />
  );
};