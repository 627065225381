import { quoterDb } from '../../db';
import { ScenarioId, ScenarioPieceId, UnitGroupId } from '../../types/api/PrimaryKeys';
import {
  createUnitGroups, deleteUnitGroups,
  getUnitGroupsForScenarioPiece, getUnitGroupsForScenarios,
  updateUnitGroups
} from '../unitGroups.service';
import { addMultiple, remove, getMultiple, update, getDefaultCachingStrategy } from '../offlineDataAccess.service';
import { UnitGroup } from '../../types/api/UnitGroup';
import { CreatedItemsResult } from '../../types/api/results/CreatedItemsResult';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';

const table = quoterDb.unitGroups;

export const getUnitGroupsForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<UnitGroup[]> => {
  const request = () => getUnitGroupsForScenarios(scenarioIds);

  const transactionTables = [quoterDb.rowCropScenarios, quoterDb.rowCropScenarioPieces, quoterDb.unitGroups];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const scenarioPieces = await safeWhere(quoterDb.rowCropScenarioPieces, 'scenarioId').anyOf(scenarioIds).toArray();
    const scenarioPieceIds = scenarioPieces.map(sp => sp.scenarioPieceId);
    const unitGroups = safeWhere(quoterDb.unitGroups, 'scenarioPieceId').anyOf(scenarioPieceIds).toArray();
    return unitGroups;
  });

  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const getUnitGroupsForScenarioPieceRequest = async (scenarioPieceId: ScenarioPieceId): Promise<UnitGroup[]> => {
  const request = () => getUnitGroupsForScenarioPiece(scenarioPieceId);
  return await getMultiple(table, { scenarioPieceId: scenarioPieceId }, request);
};

export const createUnitGroupsRequest = async (unitGroups: UnitGroup[]): Promise<CreatedItemsResult<UnitGroupId>> => {
  const request = () => createUnitGroups(unitGroups);
  return await addMultiple(table, unitGroups, request);
};

export const updateUnitGroupsRequest = async (unitGroups: UnitGroup[]): Promise<void> => {
  const request = () => updateUnitGroups(unitGroups);
  return await update(table, unitGroups, request);
};

export const deleteUnitGroupsRequest = async (unitGroupIds: UnitGroupId[]): Promise<void> => {
  const request = () => deleteUnitGroups(unitGroupIds);
  return await remove(table, unitGroupIds, request);
};
