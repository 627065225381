import { Grid } from '@mui/material';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectCommodity, selectCounty, selectPracticeById, selectStateFromCounty, selectTypeById } from '../../app/admSlice';
import { selectQuoteById } from '../../app/quotesSlice';
import ModalHeader from './modalHeader';
import { ReactNode } from 'react';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { selectScenarioById } from '../../app/scenariosSlice';
import { isNullOrUndefined } from '../../utils/nullHandling';
import { HighRiskAttributes } from '@silveus/calculations';

type ScenarioModalHeaderProps = {
  scenarioId: ScenarioId;
  headerText: string;
  headerControls: ReactNode;
  appendScenarioText?: boolean;
}

const ScenarioModalHeader = ({ scenarioId, headerText, headerControls, appendScenarioText = false }: ScenarioModalHeaderProps) => {
  const scenario = useAppSelector(s => selectScenarioById(s, scenarioId));
  const quote = useAppSelector(s => scenario === null ? undefined : selectQuoteById(s, scenario.quoteId));
  const state = useAppSelector(state => isNullOrUndefined(quote) ? undefined : selectStateFromCounty(state, quote.countyId));
  const county = useAppSelector(state => isNullOrUndefined(quote) ? undefined : selectCounty(state, quote.countyId));
  const commodity = useAppSelector(state => isNullOrUndefined(quote) ? undefined : selectCommodity(state, quote.commodityCode));
  const type = useAppSelector(state => isNullOrUndefined(scenario) ? undefined : selectTypeById(state, scenario.typeId));
  const practice = useAppSelector(state => isNullOrUndefined(scenario) || isNullOrUndefined(scenario.practiceId) ? undefined : selectPracticeById(state, scenario.practiceId));
  const highRisk = isNullOrUndefined(scenario) ? undefined : Object.values(HighRiskAttributes).find(hr => hr.value === scenario.highRiskTypeId);

  if (!scenario || !quote) {
    return null;
  }

  const stateCountyCommodityCombo = state && county && commodity && type && practice && highRisk ? `${state.name}/${county.name}/${commodity.name}/${type.name}/${practice.name}/${highRisk.description}` : undefined;

  const scenarioHeaderContent =
    <Grid container>
      {appendScenarioText ?
        <>
          <Grid item xs={12}>{`${headerText} > ${scenario.name}`}</Grid>
          <Grid item xs={12}>{`${stateCountyCommodityCombo}`}</Grid>
        </>
        :
        <Grid item xs={12}>{`${headerText}`}</Grid>
      }
    </Grid>
  ;

  return (
    <ModalHeader headerContent={scenarioHeaderContent} headerControls={headerControls} headerBackgroundColor={scenario.scenarioColor}/>
  );
};

export default ScenarioModalHeader;