import { defaultZoomPercentage, maximumZoomPercentage, minimumZoomPercentage } from '../../app/applicationZoomSlice';

export const cleanZoomPercentage = (zoomPercentage: number) => {
  let cleanedZoomPercentage;

  if (zoomPercentage < minimumZoomPercentage) {
    cleanedZoomPercentage = minimumZoomPercentage;
  } else if (zoomPercentage > maximumZoomPercentage) {
    cleanedZoomPercentage = maximumZoomPercentage;
  } else if (isNaN(zoomPercentage)) {
    cleanedZoomPercentage = defaultZoomPercentage;
  } else {
    cleanedZoomPercentage = zoomPercentage;
  }

  return cleanedZoomPercentage;
};