import { Info } from '../../info/info';
import { ReactNode, useMemo } from 'react';
import { InsurancePlanCode } from '@silveus/calculations';
import { Nullable } from '../../../types/util/Nullable';
import YieldInfoWithCounties from '../../../types/api/adm/YieldInfoWithCounties';
import { PriceYieldVariation } from '../../../utils/priceYieldVariationUtils';
import { stableEmptyArrayAsMutable } from '../../../utils/stableEmptyArray';

export interface CountyYieldInputToolTipProps {
  countyYieldInfo: Nullable<YieldInfoWithCounties>;
  commodityName: string | undefined;
  coverageLevel: Nullable<number>;
  volatility: number;
  variation: Nullable<PriceYieldVariation>
}

const makeTooltip = (tooltip: ReactNode) => {
  return (
    <Info tooltipContent={tooltip} />
  );
};

const CountyYieldInputToolTip = ({
  countyYieldInfo,
  commodityName,
  coverageLevel,
  volatility,
  variation,
}: CountyYieldInputToolTipProps) => {
  const countiesTipContent = useMemo(() => {
    if (countyYieldInfo === null) return stableEmptyArrayAsMutable<ReactNode>();

    const countyTooltipLines: ReactNode[] = [];

    for (const [index, item] of countyYieldInfo.availableCounties.entries()) {
      countyTooltipLines.push(item);
      if (index < countyYieldInfo.availableCounties.length - 1) {
        countyTooltipLines.push(<br />);
      }
    }

    return countyTooltipLines;
  }, [countyYieldInfo]);

  const tooltipContent = useMemo(() => {
    if (countyYieldInfo === null) return stableEmptyArrayAsMutable<ReactNode>();

    const hintLines: string[] = [];

    hintLines.push('Data Source: ADM');
    hintLines.push(`Yield Type: ${countyYieldInfo.indexValueCode === '0' ? 'Planted Yield' : 'Harvested Yield'}`);
    hintLines.push(`Crop Type: ${commodityName}`);
    hintLines.push(`Irrigation Practice: ${countyYieldInfo.irrigationPractice.irrigationPracticeName}`);
    hintLines.push(`Production Area: ${countyYieldInfo.areaBasisCode === '1' ? 'County' : 'Production Area'}`);

    const decimalCoverage = (coverageLevel ?? 0) / 100;
    const isYieldSet = countyYieldInfo.coverageAndVolatility.some(x => {
      return (
        volatility === 0
        ||
        x.priceVolatilityFactor === volatility
        ||
        countyYieldInfo.insurancePlanCode === InsurancePlanCode.SCO_YP
      )
        &&
        (
          coverageLevel === null
          ||
          x.areaLossEndPercent === decimalCoverage
          ||
          x.areaLossStartPercent === decimalCoverage
        );
    });

    hintLines.push(isYieldSet ? 'Yield Set' : 'Yield Not Set');

    if (variation !== null) {
      hintLines.push(variation.description);
    }

    hintLines.push('----------------------------------------');
    hintLines.push('This offer\'s production area');
    hintLines.push('includes this list of counties:');

    const tooltipLineItems: ReactNode[] = [];
    for (const item of hintLines) {
      tooltipLineItems.push(item);
      tooltipLineItems.push(<br />);
    }

    return tooltipLineItems;
  }, [countyYieldInfo, commodityName, coverageLevel, volatility, variation]);

  const toolTipElement = countyYieldInfo === null
    ? 'Yield information for this policy is not yet available.'
    : (
      <div>
        <span>{tooltipContent}</span>
        <span style={{ display: 'inline-block', maxHeight: '100px', overflow: 'auto', border: '1px solid #ccc', padding: '5px' }}>{countiesTipContent}</span>
      </div>
    );

  return makeTooltip(toolTipElement);
};

export default CountyYieldInputToolTip;
