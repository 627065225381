/** Returns the number of days if the discovery end date is in the future relative to today.
 * OTHERWISE (if today is in the future relative to the discovery end date), returns NULL.
 */
export const getDaysUntilHarvestDiscoveryEnd = (harvestPriceEndDate: Date): number | null => {
  const currentDate = new Date();

  const days = getDaysBetweenDates(harvestPriceEndDate, currentDate);

  // If the discovery end date is in the past, return null.
  // This is to help the caller decide what to do in this situation.
  if (days < 0) { return null; }

  return days;
};



const msPerDay = 1000 * 60 * 60 * 24;

/** Subtracts the second date from the first date.
 * May return negative values.
 * Returns the difference in days in whole integers.
 */
const getDaysBetweenDates = (date1: Date, date2: Date): number => {
  const diffInMs = date1.getTime() - date2.getTime();
  const daysAsFloat = diffInMs / msPerDay;
  return Math.floor(daysAsFloat);
};