import { Controller, useFormContext } from 'react-hook-form';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { FormInputProps } from '../../scenario/formInputProps';
import { Nullable } from '../../../../types/util/Nullable';

export interface AipSelectorInputProps extends FormInputProps {
  selectedAip: Nullable<string>;
}

export const AipSelectorInput = ({ selectedAip, autoFocus = false, disabled = false }: AipSelectorInputProps) => {
  const { control } = useFormContext();

  // TODO: Product Backlog Item 66407: Get from Watts Endpoint
  const availableAips: string[] = [];

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.UnderlyingScenarioPieceType}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.restrictNotOnListValue(availableAips),
      }}
      defaultValue={selectedAip}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label="Carrier"
          fullWidth
          select
          value={value}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {availableAips.map(aip => <MenuItem key={aip} value={aip}>
            aip
          </MenuItem>)}
        </TextField>
      )}
    />
  );
};
