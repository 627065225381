import { Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { ValidColor } from '@silveus/react-matrix';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityOnIcon } from '@mui/icons-material';
import { SweetSpotId } from '../../../types/api/PrimaryKeys';
import { MatrixSweetSpotColorAndValueInput } from './matrixSweetSpotColorAndValueInput';

type MatrixSweetSpotInputProps = {
  /** A unique, human-readable id for this sweet spot */
  sweetSpotId: SweetSpotId;
  /** The color associated with this sweet spot. In most scenarios this should probably be unique, but there's no technical requirement for this. */
  color: ValidColor;
  /** A human-readable label associated with the sweet spot. This is different from the spot id because there's no guarantee of uniqueness. */
  label: string;
  /** The value to display associated with the sweet spot. Value will be displayed verbatim. */
  value: string | number | null | undefined;
  /** Determines whether this sweet spot should show as "active" this will manifest as a check mark in its upper right box. Note that this value is controlled. */
  isActive: boolean;
  /** Whether this sweet spot should be "hidden" (not drawn) to the final matrix. */
  isHidden: boolean;
  /** An event handler for managing what happens when the user clicks the "active" checkbox. */
  onSweetSpotIsActiveClick: (newIsActiveValue: boolean) => void;
  /** An event handler for managing what happens when the user clicks the "delete" button on the sweet spot. */
  onSweetSpotDeleteClick: () => void;
  /** A callback that's expected to fire every time the user adjusts the sweet spot label input. */
  onSweetSpotLabelChange: (newLabel: string) => void;
  /** A callback for managing what happens when the user changes the sweetspot color */
  onSweetSpotColorChange: (color: ValidColor) => void;

  customOnSweetSpotDelete: (sweetSpotId: SweetSpotId) => void;
  customOnSweetSpotVisibilityChange: (sweetSpotId: SweetSpotId, isHidden: boolean) => void;
}

export const MatrixSweetSpotInput = ({
  sweetSpotId, color, label, isActive, isHidden, value,
  onSweetSpotIsActiveClick, customOnSweetSpotDelete, customOnSweetSpotVisibilityChange,
  onSweetSpotLabelChange, onSweetSpotColorChange }: MatrixSweetSpotInputProps) =>
{

  const handleEditClicked = () => {
    onSweetSpotIsActiveClick(!isActive);
  };

  const handleVisibilityClicked = () => {
    customOnSweetSpotVisibilityChange(sweetSpotId, !isHidden);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs="auto">
        <IconButton onClick={handleEditClicked} color="primary" sx={{ backgroundColor: theme => isActive ? theme.palette.info.main : undefined }}>
          <EditIcon />
        </IconButton>
      </Grid>

      <Grid item xs>
        <TextField
          value={label}
          onChange={e => onSweetSpotLabelChange(e.target.value)}
          size="small"
          type="text"
          variant="outlined"
          label="Label"
          inputProps={{ sx: { fontSize: '14px' } }}
          fullWidth
        />
      </Grid>

      <Grid item xs>
        <MatrixSweetSpotColorAndValueInput color={color} value={value} onSweetSpotColorChange={onSweetSpotColorChange} />
      </Grid>

      <Grid item xs="auto">
        <Tooltip title={isHidden ? 'Show' : 'Hide'}>
          <IconButton onClick={handleVisibilityClicked} size="small" color="primary">
            { isHidden ? <VisibilityOffIcon /> : <VisibilityOnIcon /> }
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item xs="auto">
        <Tooltip title="Delete">
          <IconButton onClick={() => customOnSweetSpotDelete(sweetSpotId)} size="small" color="primary">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};