import { Backdrop, CircularProgress } from '@mui/material';
import { selectIsLoading } from '../../app/loaderSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useWarnOnUnloadAndNavigate } from '../../hooks/useWarnOnUnloadAndNavigate';


const Loader = () => {
  const loading = useAppSelector(selectIsLoading);

  useWarnOnUnloadAndNavigate(loading);

  return <>
    <Backdrop
      sx={{ color: theme => theme.palette.other.backdrop, zIndex: theme => theme.zIndex.modal + 10 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </>;
};

export default Loader;