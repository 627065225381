import { Dialog, DialogContent, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { currentlySelectedScenario } from '../../../app/scenariosSlice';
import { Warning as WarningIcon } from '@mui/icons-material';
import 'react-resizable/css/styles.css';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import { selectQuoteById } from '../../../app/quotesSlice';
import { Nullable } from '../../../types/util/Nullable';
import AcresInput from '../../../components/formInputs/acresInput.component';
import { ScenarioPieceResponseDTO, sum, UnitResponseDTO } from '@silveus/calculations';
import HailRate from '../../../types/api/hail/hailRate';
import HailScenarioPieceRate from '../../../types/api/hail/HailScenarioPieceRate';
import { HailScenarioPieceRateId } from '../../../types/api/PrimaryKeys';
import { useEffect } from 'react';
import { HailScenarioPieceRateCalculatedValues } from '../utils/hailAcresUtils';
import { calculateSummaryHailRate } from '../utils/hailRateUtils';
import HailModalHeaderControls from '../hailModalHeaderControls';
import ScenarioModalHeader from '../../../components/modal/scenarioModalHeader';

type HailTownshipRangeModalProps = {
  hailScenarioPieceRates: HailScenarioPieceRate[];
  availableHailRates: HailRate[];
  onCloseClicked: () => void;
  showModal: boolean;
  hailProductIndex: number;
  totalCalculatedValues: ScenarioPieceResponseDTO | undefined;
  unitQuoteCalculatedValues: Map<HailScenarioPieceRateId, HailScenarioPieceRateCalculatedValues>;
  quickQuoteCalculatedValues: Map<HailScenarioPieceRateId, UnitResponseDTO>;
  onHailScenarioPieceRatesUpdated: (updatedScenarioPieceRates: HailScenarioPieceRate[]) => void;
}
type HailCardTownshipRangeModalFields = { rates: HailScenarioPieceRate[] };
const HailTownshipRangeModal = ({ hailScenarioPieceRates, availableHailRates, onCloseClicked, showModal, hailProductIndex, totalCalculatedValues, unitQuoteCalculatedValues, quickQuoteCalculatedValues, onHailScenarioPieceRatesUpdated }: HailTownshipRangeModalProps) => {
  const theme = useTheme();
  const scenario = useAppSelector(currentlySelectedScenario);
  const quote = useAppSelector(state => scenario === null ? null : selectQuoteById(state, scenario.quoteId));
  const methods = useForm<HailCardTownshipRangeModalFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      rates: [...hailScenarioPieceRates],
    },
  });

  useEffect(() => {
    methods.setValue('rates', [...hailScenarioPieceRates]);
  }, [hailScenarioPieceRates]);

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'rates',
  });

  const getHailRateByRateId = (rateId: number) => {
    const hailRate = availableHailRates.find(x => x.rateId === rateId);
    if (hailRate === undefined) throw new Error(`Cannot find hail rate for rateId: ${rateId}`);
    return hailRate;
  };

  function getValidNumber(value: Nullable<number>) {
    // Convert null or undefined to 0
    if (value === null) {
      return 0;
    }
    // Ensure the value is a number
    const valueAsNumber = Number(value);
    return isNaN(valueAsNumber) ? 0 : valueAsNumber;
  }

  const tableCellStyle = {
    width: '156.67px',
  } as const;
  const tablePremiumCellStyle = {
    width: '180px',
  } as const;

  const onSubmit: SubmitHandler<HailCardTownshipRangeModalFields> = data => {
    onCloseClicked();
  };

  const onBlurAcresInput = () => {
    if (quote?.quickQuote === true) {
      const updatedScenarioPieceRates = methods.getValues().rates;
      onHailScenarioPieceRatesUpdated(updatedScenarioPieceRates);
    }
  };

  const isQuickQuote = quote?.quickQuote === true;
  const totalAcres = isQuickQuote ? sum(hailScenarioPieceRates, x => getValidNumber(x.acres)) : sum(Array.from(unitQuoteCalculatedValues.values()), x => x.acres);
  const summaryHailRate = calculateSummaryHailRate(totalAcres, totalCalculatedValues?.liabilityPerAcre ?? 0, totalCalculatedValues?.totalPremium ?? 0);

  const headerControls = <HailModalHeaderControls onCloseClicked={() => { }}/>;

  return (
    <Dialog open={showModal} maxWidth={false} scroll="body" disableEscapeKeyDown={true} key={hailProductIndex}>
      <FormProvider {...methods}>
        <form id="hail-township-range-modal-form" onSubmit={e => {
          //This stops the parent form for the hail modal from submitting
          e.stopPropagation();
          return methods.handleSubmit(onSubmit)(e);
        }}>
          { scenario !== null && <ScenarioModalHeader scenarioId={scenario.scenarioId} headerText="Townships/Ranges" headerControls={headerControls}/> }
          <DialogContent sx={{ p: 0 }}>
            <TableContainer sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
              <Table stickyHeader sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableCellStyle}>Township/Range</TableCell>
                    <TableCell sx={tableCellStyle}>Acres</TableCell>
                    <TableCell sx={tableCellStyle}>Rate</TableCell>
                    <TableCell sx={tablePremiumCellStyle}>Premium</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((hailRate, hailRateIndex) => {
                    const { rateId, hailScenarioPieceRateId, acres, id } = hailRate;
                    const hailRateData = getHailRateByRateId(rateId);

                    const unitQuoteValues = unitQuoteCalculatedValues.get(hailScenarioPieceRateId);
                    const quickQuoteValues = quickQuoteCalculatedValues.get(hailScenarioPieceRateId);

                    if (isQuickQuote === false && unitQuoteValues === undefined) return null;

                    const premiumValue = isQuickQuote
                      ? quickQuoteValues?.totalPremium ?? 0
                      : (unitQuoteValues?.totalPremium ?? 0);

                    const acresCell = isQuickQuote ? (
                      <AcresInput
                        name={`rates.${hailRateIndex}.acres`}
                        acres={acres}
                        allowZero={true}
                        label={''}
                        sx={{ textAlign: 'left' }}
                        onBlur={onBlurAcresInput}
                      />
                    ) : unitQuoteValues?.acres;

                    return (
                      <TableRow key={id}>
                        <TableCell sx={tableCellStyle}>{hailRateData.township}-{hailRateData.range}</TableCell>
                        <TableCell sx={tableCellStyle}>{acresCell}</TableCell>
                        <TableCell sx={tableCellStyle}>{hailRateData.rate}</TableCell>
                        <TableCell sx={tablePremiumCellStyle}>
                          <CurrencyText currencyAmount={premiumValue * -1} colorize={true} align="left" />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter sx={{ position: 'sticky', bottom: 0, backgroundColor: theme.palette.background.paper }}>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: theme.typography.value, ...tableCellStyle }}>
                      <Stack direction="row" spacing={1}>
                        <>Total</>
                        {isQuickQuote === false &&
                          <Tooltip
                            title={
                              <Typography variant="body2">Units with missing or incorrect Township/Range data may cause rate and premium calculations to be incorrect.</Typography>
                            }>
                            <WarningIcon color="warning" fontSize="small" />
                          </Tooltip>
                        }
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: theme.typography.value, ...tableCellStyle }}>
                      Acres: {totalAcres}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: theme.typography.value, ...tableCellStyle }}>
                      Rate: {summaryHailRate}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: theme.typography.value, ...tablePremiumCellStyle }}>
                      <Stack direction="row" spacing={1}>
                        <>Premium: </>
                        <CurrencyText currencyAmount={(totalCalculatedValues?.totalPremium ?? 0) * -1} colorize={true} align="right" />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </DialogContent>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default HailTownshipRangeModal;