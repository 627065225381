import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { Insured } from '../../types/api/insureds/Insured';
import { Chip } from '@mui/material';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';

export const columnDefinitions: ColDef[] = [
  { field: 'download', type: 'checkbox', headerName: '', showDisabledCheckboxes: true, checkboxSelection: true, maxWidth: 70 },
  { field: 'name', type: 'text' },
  { field: 'type', type: 'text', headerName: 'Client Type', maxWidth: 150, cellRenderer: (params: ICellRendererParams<Insured>) => <Chip sx={{ color: theme => theme.palette.common.white }} size="small" label={params.value} color={params.value === 'Insured' ? 'primary' : 'secondary'} /> },
  { field: 'address.composite', headerName: 'Address', type: 'text' },
  { field: 'email', type: 'text' },
  { field: 'phone', type: 'text', valueFormatter: (params: ValueFormatterParams<Insured>) => formatPhoneNumber(params.value) ?? '' },
];