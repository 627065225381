import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferMpProjectedPrice } from '../../../app/admSlice';
import { isApiDataStale, isSavedDataBeingLoaded } from '../../../utils/formInputUtils';
import MpProjectedPriceInput, { MpProjectedPriceProps } from '../scenarioPiece/mp/mpProjectedPriceInput';
import { ExtendedDataFormInputParameterNames } from '../constants/inputConstants';
import { Quote } from '../../../types/api/Quote';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { Nullable } from '../../../types/util/Nullable';
import { getRoundedValue } from '../../numberTextField/numberTextField.component';
import { useCommodityOptionBasedPrecisionForScenario } from '../../../hooks/useCommodityOptionBasedPrecision';
import useLinkedScenarioFields from '../../../hooks/useLinkedScenarioFields';

export type MpProjectedPriceFields = {
  quote: Quote;
  scenario: Nullable<RowCropScenario>;
  projectedPrice: number;
}

const ScenarioMpProjectedPriceInput = ({ quote, scenario, scenarioOptionState, projectedPrice, sx, autoFocus = false, disabled = false }: MpProjectedPriceProps) => {
  const fieldName = ExtendedDataFormInputParameterNames.ProjectedPrice;
  const { control, setValue, getValues, trigger } = useFormContext();
  const offerMpProjectedPrice = useAppSelector(selectOfferMpProjectedPrice);
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });
  const { precision } = useCommodityOptionBasedPrecisionForScenario(quote.commodityCode, scenarioOptionState);
  const { getInputStyle } = useLinkedScenarioFields(scenario?.scenarioId ?? null);

  useEffect(() => {
    //When the API data is changed, load it into the field,
    // except for the initial load when there is saved data
    const currentPractice = getValues('practiceId');

    const isThereStaleData = isApiDataStale(currentPractice, offerMpProjectedPrice);
    const isLoadingSavedData = isSavedDataBeingLoaded(projectedPrice, practice);

    if (isThereStaleData || isLoadingSavedData || offerMpProjectedPrice === null) return;

    setValue(fieldName, getRoundedValue(offerMpProjectedPrice, precision));
    trigger(fieldName);
  }, [offerMpProjectedPrice]);

  useEffect(() => {
    //When the practice is changed, clear out the current value
    if (practice === undefined) return;

    setValue(fieldName, 0);
  }, [practice]);

  return (
    <MpProjectedPriceInput
      quote={quote}
      scenario={scenario}
      scenarioOptionState={scenarioOptionState}
      projectedPrice={projectedPrice}
      sx={{
        ...sx,
        ...getInputStyle(fieldName),
      }}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  );
};

export default ScenarioMpProjectedPriceInput;
