import React, { useCallback, useMemo, useState } from 'react';
import { Button, Grid, ListItemButton, ListItemIcon, ListItemText, Popover, SvgIcon, Tooltip, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../hooks/reduxHooks';
import { fetchAllRequiredDataForApplication, fetchApplication, selectApplicationsModalOpen, toggleApplicationsModal } from '../../app/applicationsSlice';
import { ReactComponent as StartApplicationIcon } from '../../images/startApplication.svg';
import { selectIsOnline } from '../../app/onlineSlice';
import { envApplicationWizardEnabled } from '../../constants/envConstants';
import { fetchApplicationWizardSummaries, selectAllSummariesByInsuredId } from '../../app/applicationWizardSummariesSlice';
import { AgGridReact } from 'ag-grid-react';
import { ApplicationWizardSummary } from '../../types/api/applicationWizard/applicationWizard';
import { selectAdmData } from '../../app/admSlice';
import { fetchAppTasks, selectAllAppTasksByApplicationWizardId } from '../../app/appTaskSlice';
import { DefaultAppWizardColDefinition, getAppWizardColumnDefinitions } from './columnDefinitions';
import { selectIsLightMode } from '../../app/userSettingsSlice';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../utils/nullHandling';
import { ClientFile } from '../../types/api/ClientFile';
import { Nullable } from '../../types/util/Nullable';
import { IRowNode, RowClassParams, SelectionChangedEvent } from 'ag-grid-community';
import { beginLoading, endLoading } from '../../app/loaderSlice';
import { isResumeAppWizardEnabled } from '../../utils/applications/appWizardUtils';
import { Insured } from '../../types/api/insureds/Insured';

import './startApplicationMenu.styles.css';

type StartApplicationMenuProps = {
  clientFile: ClientFile;
  insured: Insured;
}

const StartApplicationMenu = ({ clientFile, insured }: StartApplicationMenuProps) => {
  const dispatch = useAppDispatch();
  const [popperOpen, setPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<Nullable<ApplicationWizardSummary>>(null);

  const isLightMode = useAppSelector(selectIsLightMode);
  const offline = !useAppSelector(selectIsOnline);
  const applicationsModalOpen = useAppSelector(selectApplicationsModalOpen);
  const admData = useAppSelector(selectAdmData);
  const allAppWizards = useKeyMapSelector(selectAllSummariesByInsuredId, insured.id);
  const appWizardsForSelectedYear = allAppWizards.filter(x => x.year === clientFile.year && x.clientFileId === clientFile.clientFileId);
  const appTasksByAppWizardId = useAppSelector(selectAllAppTasksByApplicationWizardId);

  const handleApplicationButtonClick = async (event: React.MouseEvent<HTMLElement>) => {
    try {
      dispatch(beginLoading());
      setAnchorEl(event.currentTarget);
      const apps = await dispatch(fetchApplicationWizardSummaries({ insuredId: insured.id, year: clientFile.year })).unwrap();

      // If user has no existing application wizards we will skip showing the popper and jump directly
      // to starting a new app wizard since that would be the only option available in the popper anyway.
      if (apps.length === 0) {
        startNew();
      } else {
        // Otherwise if the user has some existing applications we'll set up the popper to display that has the list of available
        // application wizards and the option to resume or start a new one from scratch. We'll load app task summaries here as well
        // because the status of app tasks is important to whether or not an app wizard can be continued.
        await dispatch(fetchAppTasks({ insuredId: insured.id, year: clientFile.year }));
        setPopperOpen(true);
      }
    } finally {
      dispatch(endLoading());
    }
  };

  const appWizardsColumnDefinitions = useMemo(() => {
    return getAppWizardColumnDefinitions({ admData, appTasksByAppWizardId });
  }, [admData, appTasksByAppWizardId]);

  const resumeApp = useCallback(async () => {
    try {
      if (isNotNullOrUndefined(selectedRow)) {
        dispatch(beginLoading());
        await dispatch(fetchApplication({ appSummary: selectedRow }));
      }
    } finally {
      handleClose();
      dispatch(endLoading());
    }
  }, [dispatch, selectedRow]);

  const startNew = useCallback(async () => {
    if (isNotNullOrUndefined(clientFile)) {
      dispatch(fetchAllRequiredDataForApplication({ clientFileId: clientFile.clientFileId }));
      dispatch(toggleApplicationsModal());
      handleClose();
    }
  }, [clientFile, dispatch]);

  const handleRowSelectionChanged = useCallback((event: SelectionChangedEvent<ApplicationWizardSummary>) => {
    const selectedRows = event.api.getSelectedRows();
    if (selectedRows.length === 0) {
      setSelectedRow(null);
    } else {
      setSelectedRow(selectedRows[0]);
    }
  }, [appTasksByAppWizardId, selectedRow]);

  const isRowSelectable = useCallback((params: IRowNode<ApplicationWizardSummary>) => {
    const { data } = params;
    if (isNullOrUndefined(data)) {
      return false;
    }
    return calculateWizardIsResumable(data);
  }, [appTasksByAppWizardId]);

  const getRowClass = (params: RowClassParams) => {
    const { data } = params;
    if (isNullOrUndefined(data)) {
      return '';
    }

    const canResume = calculateWizardIsResumable(data);
    if (!canResume) {
      return 'disabled-row';
    }
  };

  const calculateWizardIsResumable = (appWizard: ApplicationWizardSummary) => {
    const appTasksForRow = appTasksByAppWizardId.get(appWizard.applicationWizardId) ?? [];
    return isResumeAppWizardEnabled(appWizard, appTasksForRow);
  };

  const handleClose = () => {
    setSelectedRow(null);
    setPopperOpen(false);
  };

  const title = 'Applications';
  const disabled = offline || !envApplicationWizardEnabled;

  return (
    <>
      <ListItemButton selected={applicationsModalOpen} onClick={handleApplicationButtonClick} disabled={disabled} alignItems="center" sx={{ flexGrow: 0 }}>
        <ListItemIcon sx={{ marginTop: 0 }}>
          <Tooltip placement="right" title={title}>
            <SvgIcon component={StartApplicationIcon} />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={title} primaryTypographyProps={{ variant: 'subtitle1' }} sx={{ marginBotom: 0 }} />
      </ListItemButton>
      {popperOpen && (
        <Popover
          open={true}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleClose}
        >
          <Grid p={2} direction="column" container sx={{ minHeight: '400px', minWidth: '900px', flexWrap: 'nowrap' }} className={isLightMode ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}>
            <Grid item><Typography variant="subtitle1">Applications</Typography></Grid>
            <Grid item><Typography variant="body2">Select the application you wish to continue with</Typography></Grid>
            <Grid item xs mt={1.5} mb={1.5}>
              <AgGridReact<ApplicationWizardSummary>
                className="in-progress-apps-grid"
                defaultColDef={DefaultAppWizardColDefinition}
                rowData={appWizardsForSelectedYear}
                columnDefs={appWizardsColumnDefinitions}
                suppressCellFocus
                suppressRowClickSelection
                gridOptions={{
                  sortingOrder: ['desc', 'asc', null],
                  embedFullWidthRows: true,
                  keepDetailRows: true,
                }}
                onSelectionChanged={handleRowSelectionChanged}
                isRowSelectable={isRowSelectable}
                autoSizeStrategy={{ type: 'fitGridWidth' }}
                getRowClass={getRowClass}
                getRowId={params => params.data.applicationWizardId}
              />
            </Grid>
            <Grid container item sx={{ height: '45px', justifyContent: 'flex-end' }}>
              <Button id="btn-start-app" onClick={startNew}>Start New Application</Button>
              <Button id="btn-resume-app" onClick={resumeApp} disabled={!selectedRow}>Resume</Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};

export default StartApplicationMenu;