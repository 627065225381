import { Controller, useFormContext } from 'react-hook-form';
import { SxProps, TextField, Theme } from '@mui/material';
import { Nullable } from '../../types/util/Nullable';
import { ChangeEvent, useEffect, useState } from 'react';
import { roundToPlaces } from '@silveus/calculations';
import { validateInsuredSharePercent } from '../../pages/units/validations/insuredShareValidation';

interface SharePercentProps {
  sharePercent: Nullable<number>;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
  allowZero?: boolean;
  disabled?: boolean;
  showSharePercentValue?: Nullable<number>;
}

export type SharePercentFields = {
  sharePercent: number;
}

const SharePercentInput = ({ sharePercent, sx, autoFocus = false, disabled = false, allowZero = false, showSharePercentValue = null }: SharePercentProps) => {
  const { control } = useFormContext();

  const defaultSharePercent = showSharePercentValue ?? (sharePercent ?? 1);

  useEffect(() => {
    if (showSharePercentValue === null) {
      setSharePercentAsWholeNumber(roundToPlaces(defaultSharePercent * 100, 2));
    } else {
      setSharePercentAsWholeNumber(roundToPlaces(showSharePercentValue * 100, 2));
    }
  }, [sharePercent, showSharePercentValue]);

  //In DB like we have 0.0001~1.0000, but in UI we need to show 0.01~100.00%
  const [sharePercentAsWholeNumber, setSharePercentAsWholeNumber] = useState<number | null>(roundToPlaces(defaultSharePercent * 100, 2));

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: { onChange: (val: Nullable<number>) => void }) => {

    const val = parseFloat(e.target.value);

    if (!isNaN(val)) {
      //In UI, the values are 0.01~100.00.
      //in DB, we need to convert the value to 0.0001~1.0000
      const decimalVal = roundToPlaces(val / 100, 4);
      field.onChange(decimalVal);
      setSharePercentAsWholeNumber(val);
    } else {
      field.onChange(null);
      setSharePercentAsWholeNumber(null);
    }
  };

  //We need this because validation rules are based on 0.0001~1 but in UI based on 0.0~100
  //TODO: We may need to update/override the VALIDATION_RULES, where we can define the custom values to show the user custom message.
  const overrideMessage = (message: string | undefined): string | undefined => {
    if (message !== undefined && message !== '') {
      if (message.includes('0.0001')) {
        return message.replace('0.0001', '0.01');
      }
      if (message.includes('1')) {
        return message.replace('1', '100');
      }
      return message;
    }
  };

  return (
    <Controller
      name="sharePercent"
      control={control}
      rules={{ validate: value => validateInsuredSharePercent(value, allowZero) }}
      defaultValue={defaultSharePercent}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="number"
          label="Share %"
          fullWidth
          value={sharePercentAsWholeNumber}
          inputProps={{ min: 0.01, max: 100, step: 0.01 }}
          error={!!error}
          helperText={overrideMessage(error?.message)}
          inputRef={ref}
          {...field}
          onChange={e => onChange(e, field)}
          autoFocus={autoFocus}
          sx={sx}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default SharePercentInput;
