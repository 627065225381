import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { TextField } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';

type AddressLine1InputProps = {
  value?: Nullable<string>;
  disabled: boolean;
  required?: boolean;
}

export type AddressLine1Fields = {
  addressLine1: Nullable<string>;
}

const AddressLine1Input = ({ value, disabled, required = true }: AddressLine1InputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="address.addressLine1"
      control={control}
      rules={{ required: required ? VALIDATION_RULES.required() : false, maxLength: VALIDATION_RULES.maxLength(100) }}
      defaultValue={value ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="address1"
          label="Address"
          fullWidth
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          size="small"
        />
      )}
    />
  );
};

export default AddressLine1Input;

