import { DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Insured } from '../../types/api/insureds/Insured';
import InsuredUnitsDiff from './entityDiffWrappers/insuredUnitsDiff';
import { NamedReconciliationStack } from '../../types/app/ReconciliationTrackedEntity';

type StackType = NamedReconciliationStack<'unitInsureds'>;

interface UnitReconciliationDialogProps {
  insured: Insured;
  handleReconciliationStatusChanged: (entity: StackType) => void;
  reconciledState: StackType | undefined;
  clientState: StackType | undefined;
  serverState: StackType | undefined;
}

const UnitReconciliationDialog = ({ insured, handleReconciliationStatusChanged, reconciledState, clientState, serverState }: UnitReconciliationDialogProps) => {
  return (
    <>
      <DialogTitle sx={{ backgroundColor: theme => theme.palette.primary.main }}>
        <Typography color="white">Data Reconciliation - {insured.name} - Units</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={6}>
            {clientState && reconciledState && <InsuredUnitsDiff insured={clientState} reconciledInsured={reconciledState} onReconciliationTypeChange={handleReconciliationStatusChanged} dataLocation="client" /> }
          </Grid>
          <Grid item xs={6}>
            {serverState && reconciledState && <InsuredUnitsDiff insured={serverState} reconciledInsured={reconciledState} onReconciliationTypeChange={handleReconciliationStatusChanged} dataLocation="server" /> }
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default UnitReconciliationDialog;