import { Button, Grid, Stack, Typography } from '@mui/material';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { openDrawer } from '../../../app/appDrawerSlice';
import { ClientFileId } from '../../../types/api/PrimaryKeys';
import farmIconSrc from '../../../images/farmImage.png';

interface Props {
  clientFileId: ClientFileId;
}

export const NoQuotesForClientFile = ({ clientFileId }: Props) => {
  const dispatch = useAppDispatch();

  const onAddQuoteClicked = () => {
    dispatch(openDrawer({ formName: 'quoteForm', quoteId: null, clientFileId: clientFileId }));
  };

  return (
    <Grid container alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <Grid item>
        <Stack alignContent="center" display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
          <img alt="No Quotes Available" src={farmIconSrc} />

          <Typography sx={{ mt: -5 }} variant="h6" textAlign="center">
            <p>You Have No Quotes Yet.</p>
            <p>Add a Quote To Get Started.</p>
          </Typography>

          <Button onClick={onAddQuoteClicked} id="no-quotes-view-add-quote-btn" variant="outlined">
            Add Quote
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};