import { getMpCalcData } from '../../calculationData.service';
import { networkOnly } from '../../../utils/cachingStrategies';
import { MpCalculationParamsRequest } from '../../../types/api/calculationData/mpCalculationParamsRequest';
import MpCalculationParams from '../../../types/api/calculationData/mpCalculationParams';
import { MpPremiumResponseDto } from '../../../types/api/liveQuote/mpPremiumResponseDTO';
import { MpPremiumRequestDto } from '../../../types/api/liveQuote/mpPremiumRequestDTO';
import { liveQuoteMp } from '../liveQuote.service';

export const getMpCalcDataRequest = async (calcParamRequest: MpCalculationParamsRequest): Promise<MpCalculationParams[]> => {
  const request = () => getMpCalcData(calcParamRequest);

  //TODO: do offline (after may deadline)
  const strategy = networkOnly;
  return strategy(request);
};

export const liveQuoteMpRequest = async (requestDto: MpPremiumRequestDto): Promise<MpPremiumResponseDto> => {
  const request = () => liveQuoteMp(requestDto);

  //This will never be offline because it relies on 3rd party data.
  const strategy = networkOnly;
  return strategy(request);
};