import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import { Nullable } from '../../../../types/util/Nullable';
import {
  getValueToUseForHistoricalQuoteResult
} from '../../../../pages/scenarioAnalysis/historicalAnalysis/getValueToUseForHistoricalQuoteResult';
import {
  HistoricalDynamicColumn,
  HistoricalDynamicColumnConfig
} from '../../../../pages/scenarioAnalysis/historicalAnalysis/charts/HistoricalChart/types/HistoricalData';
import {
  CurrencyCellRenderer
} from '../../../../pages/scenarioAnalysis/historicalAnalysis/charts/HistoricalChart/cellRenderers/currencyCellRenderer';
import {
  StormEventCellRenderer
} from '../../../../pages/scenarioAnalysis/historicalAnalysis/charts/HistoricalChart/cellRenderers/stormEventCellRenderer';
import StormIcon from '@mui/icons-material/Storm';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import { validateHip } from '../../../../utils/validation/scenarioPieces/hipValidation';
import { HipScenarioPieceForm } from '../../../../pages/scenarioPiece/hip/hipScenarioPieceForm.component';
import { OptionCode, ScenarioPieceType } from '@silveus/calculations';
import {
  getHipQuoteSummaryData
} from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getHipQuoteSummaryData';
import { createHipData } from '../../../../services/calculations/hip/hipDataTransformations';
import { getFullyQualifiedScenarioPieceNameOnlyCoverageLevel } from '../../defaultDefinitions/scenarioPieceNameDefaults';
import {
  ProtectionFactorScenarioPieceChip,
  TopicalStormScenarioPieceChip
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';
import { OptionSelectionState } from '../../../../types/app/enums/optionSelectionState.enum';

export const hipDefinition: ScenarioPieceDefinition = {
  validationFunction: validateHip,
  d365ProductType: ProductType.MPCI,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, _aphYield, _shouldSimulateUnitGroups) => {
    return createHipData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <HipScenarioPieceForm {...props} scenarioPieceType={props.selectedScenarioPieceType as ScenarioPieceType} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}/>;
  },
  getHistoricalQuoterColumns: params => {
    const dynamicColumns: HistoricalDynamicColumn[] = [];
    const applicableHurricanes = params.historicalStormEvents.filter(hse => hse.year === params.year && hse.insuranceOptionCode === null);

    dynamicColumns.push(
      {
        groupingKey: params.groupingKey,
        dataKey: 'hurricaneEvents',
        value: applicableHurricanes,
      },
      {
        groupingKey: params.groupingKey,
        dataKey: 'hurricanePayment',
        value: params.scenarioPiece === null ? null : getValueToUseForHistoricalQuoteResult(params.scenarioPiece.baseResult.indemnityPerAcre, params.scenarioPiece.baseResult.indemnityAmount,
          params.scenarioPiece.baseResult.producerPremiumPerAcre, params.scenarioPiece.baseResult.producerPremium, params.includeType, params.showType),
      },
    );

    const tropicalStormResult = params.scenarioPiece?.additionalCoverageResults.find(acr => acr.id === OptionCode.TS) ?? null;

    if (tropicalStormResult !== null) {
      const applicableTropicalStorms = params.historicalStormEvents.filter(hse => hse.year === params.year && hse.insuranceOptionCode !== null);

      dynamicColumns.push(
        {
          groupingKey: params.groupingKey,
          dataKey: 'tropicalStormCount',
          value: applicableTropicalStorms,
        },
        {
          groupingKey: params.groupingKey,
          dataKey: 'tropicalStormPayment',
          value: getValueToUseForHistoricalQuoteResult(tropicalStormResult.indemnityPerAcre, tropicalStormResult.indemnityAmount,
            tropicalStormResult.producerPremiumPerAcre, tropicalStormResult.producerPremium, params.includeType, params.showType),
        },
      );
    }
    return dynamicColumns;
  },
  getHistoricalQuoterColumnConfigs: params => {
    const dynamicColumnConfigs: HistoricalDynamicColumnConfig[] = [];
    dynamicColumnConfigs.push(
      {
        groupingKey: params.groupName,
        dataKey: 'hurricaneEvents',
        columnHeader: <StormIcon/>,
        cellRenderer: StormEventCellRenderer,
        maxWidth: 50,
      },
    );

    if (params.scenarioOptions.some(scenarioOption => scenarioOption.option.optionCode === OptionCode.TS && scenarioOption.selectionState === OptionSelectionState.On)) {
      dynamicColumnConfigs.push(
        {
          groupingKey: params.groupName,
          dataKey: 'hurricanePayment',
          columnHeader: 'HIP',
          cellRenderer: CurrencyCellRenderer,
          maxWidth: 100,
        },
        {
          groupingKey: params.groupName,
          dataKey: 'tropicalStormCount',
          columnHeader: <ThunderstormIcon/>,
          cellRenderer: StormEventCellRenderer,
          maxWidth: 50,
        },
        {
          groupingKey: params.groupName,
          dataKey: 'tropicalStormPayment',
          columnHeader: 'TS',
          cellRenderer: CurrencyCellRenderer,
          maxWidth: 100,
        },
      );
    }
    return dynamicColumnConfigs;
  },
  createInputForQuoteSummaryFunction: getHipQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceNameOnlyCoverageLevel,
  getScenarioPieceCardChips: [
    TopicalStormScenarioPieceChip,
    ProtectionFactorScenarioPieceChip,
  ],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#9400D3',
};
