import EntityDiff from '../entityDiffing/entityDiff';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectCommodity, selectCounty, selectStateFromCounty } from '../../../app/admSlice';
import { isEmpty } from '../../../utils/nullHandling';
import RowCropScenarioDiff from './rowCropScenarioDiff';
import { ReactElement } from 'react';
import DataLocationType from '../constants/dataLocationType';
import { NamedReconciliationStack } from '../../../types/app/ReconciliationTrackedEntity';
import { createJoinedString } from '../utils/createJoinedString';

type StackType = NamedReconciliationStack<'quotes'>;
type SubEntityStackType = NamedReconciliationStack<'rowCropScenarios'>;

interface QuoteDiffProps {
  quote: StackType;
  reconciledQuote: StackType;
  onReconciliationTypeChange: (entity: StackType) => void;
  dataLocation: DataLocationType;
}

const QuoteDiff = ({ quote, reconciledQuote, onReconciliationTypeChange, dataLocation }: QuoteDiffProps) => {
  const county = useAppSelector(state => selectCounty(state, quote.entity.countyId));
  const state = useAppSelector(state => selectStateFromCounty(state, quote.entity.countyId));
  const commodity = useAppSelector(state => selectCommodity(state, quote.entity.commodityCode));

  const subtitle = createJoinedString([
    state?.name ?? null,
    county?.name ?? null,
    commodity?.name ?? null,
  ]);

  const detailComponents = [
    quote.entity.name,
    isEmpty(quote.entity.description) ? null : quote.entity.description,
    `${quote.entity.quickQuote ? 'Quick' : 'Unit'} Quote`,
  ];

  const getSubEntityDiffElements = (
    subEntities: SubEntityStackType[],
    subEntitySubset: SubEntityStackType[],
    onReconciliationTypeChange: (subEntity: SubEntityStackType) => void,
  ) => {
    const elements: ReactElement[] = [];

    subEntitySubset.forEach(subsetEntity => {
      const applicableEntity = subEntities.find(subEntity => subEntity.id === subsetEntity.id);

      if (applicableEntity === undefined) return;

      elements.push(
        <RowCropScenarioDiff
          rowCropScenario={subsetEntity}
          reconciledRowCropScenario={applicableEntity}
          onReconciliationTypeChange={onReconciliationTypeChange}
          dataLocation={dataLocation}
          key={subsetEntity.id}
        />,
      );
    });

    return elements;
  };

  return (
    <EntityDiff
      title={quote.entity.name}
      subEntitySelector={quote => quote.subCollections.rowCropScenarios}
      subtitle={subtitle}
      trackedEntity={reconciledQuote}
      subsetEntity={quote}
      detailComponents={detailComponents}
      getSubEntityDiffElements={getSubEntityDiffElements}
      onReconciliationTypeChange={onReconciliationTypeChange}
      dataLocation={dataLocation}
    />
  );
};

export default QuoteDiff;