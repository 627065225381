import { FormProvider, useForm } from 'react-hook-form';
import { ApplicationWizard } from '../../../../types/api/applicationWizard/applicationWizard';
import ApplicationWizardPage from '../applicationWizardPage';
import { ApplicationWizardStep, ApplicationWizardStepProps } from '../applicationWizardStep';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import QuoteSelectInput from '../../../../components/formInputs/applications/quoteSelectInput';
import { Button, Grid } from '@mui/material';
import { QuoteId } from '../../../../types/api/PrimaryKeys';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { saveApplication } from '../../../../app/applicationsSlice';
import CoverageDecisionScenarioGrid from './coverageDecisionScenarioGrid';
import { selectClientFileById } from '../../../../app/clientFilesSlice';
import { selectAllScenarios, selectEligibleScenariosForApplication } from '../../../../app/scenariosSlice';
import { getKeyedStateGroupedBy } from '../../../../app/sliceHelpers';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../../../app/scenarioPiecesSlice';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../../utils/nullHandling';
import { generatePrimaryKey } from '../../../../utils/primaryKeyHelpers';
import { RootState } from '../../../../app/store';
import { selectIsLightMode } from '../../../../app/userSettingsSlice';
import { filterNotNullOrUndefined } from '../../../../utils/arrayUtils';
import { validateCoverageDecisions } from './coverageDecisionValidation';
import { useMemo, useState } from 'react';
import CoverageDecisionValidationDialog from './coverageDecisionValidationDialog';
import ErrorIcon from '@mui/icons-material/Error';

export const CoverageDecisionStep: ApplicationWizardStep = {
  isDisabled(app: ApplicationWizard) {
    return false; // First page should never be disabled
  },
  title: 'Coverage Decisions',
  index: 9,
  formId: 'form-coverage-decisions',
  icon: <AssignmentOutlinedIcon />,
  isValid (app: ApplicationWizard, state: RootState) {
    const validationResult = validateCoverageDecisions(app, state);
    const valid = validationResult.every(x => x.isValid);
    return {
      isValid: valid,
      errors: valid ? [] : ['There are errors on this page, navigate here for more details'],
    };
  },
};

const CoverageDecisionPage = ({ navigateToNextStep, navigateToPreviousStep, application, clientFileId, updateApplication }: ApplicationWizardStepProps) => {
  const isLightMode = useAppSelector(selectIsLightMode);
  const dispatch = useAppDispatch();
  const rootState = useAppSelector(s => s);
  const [showCoverageValidationErrors, setShowCoverageValidationErrors] = useState(false);
  const allScenarios = useAppSelector(selectAllScenarios);
  const eligibleScenarios = useAppSelector(selectEligibleScenariosForApplication);
  const scenariosByQuoteId = getKeyedStateGroupedBy(allScenarios, s => s.quoteId);
  const allRowCropScenarioPiecesByScenario = useAppSelector(selectAllRowCropScenarioPiecesByScenarioMap);
  const clientFile = useAppSelector(state => selectClientFileById(state, application.clientFileId));
  const methods = useForm();
  const { handleSubmit } = methods;

  const includedScenarios = filterNotNullOrUndefined(application.applicationWizardScenarios.map(x => eligibleScenarios[x.scenarioId]));
  const includedQuoteIds = [...new Set(includedScenarios.map(x => x.quoteId))];

  const coverageDecisionValidationResults = useMemo(() => {
    return validateCoverageDecisions(application, rootState);
  }, [application, rootState]);

  const coverageDecisionErrors = useMemo(() => {
    return coverageDecisionValidationResults.filter(x => !x.isValid);
  }, [coverageDecisionValidationResults]);

  if (!clientFile) {
    return null;
  }

  const handleNavigateToPrevious = () => {
    dispatch(saveApplication({ updatedApplication: { ...application } }));
    navigateToPreviousStep();
  };

  const saveInProgressWork = () => {
    if (coverageDecisionErrors.length > 0) {
      setShowCoverageValidationErrors(true);
      return;
    }
    dispatch(saveApplication({ updatedApplication: { ...application, isNewApplication: undefined } }));
    navigateToNextStep();
  };

  const onSelectedQuotesChanged = (quoteIds: QuoteId[]) => {
    let includedScenarios = [...application.applicationWizardScenarios];

    for (const quoteId of quoteIds) {
      const scenarios = scenariosByQuoteId.get(quoteId) ?? [];
      for (const s of scenarios) {
        const scenarioPieces = allRowCropScenarioPiecesByScenario.get(s.scenarioId) ?? [];
        const isScenarioUnchecked = isNullOrUndefined(includedScenarios.find(x => x.scenarioId === s.scenarioId));
        const isScenarioEligible = isNotNullOrUndefined(eligibleScenarios[s.scenarioId]);

        if (scenarioPieces.length > 0 && isScenarioUnchecked && isScenarioEligible) {
          includedScenarios.push({
            scenarioId: s.scenarioId,
            applicationWizardId: application.applicationWizardId,
            applicationWizardScenarioId: generatePrimaryKey(),
          });
        }
      }
    }

    for (let i = includedScenarios.length - 1; i >= 0; i--) {
      const scenario = allScenarios[includedScenarios[i].scenarioId];
      if (scenario) {
        const index = quoteIds.indexOf(scenario.quoteId);
        if (index < 0) {
          includedScenarios.splice(i, 1);
        }
      }
    }

    const updatedApplication: ApplicationWizard = { ...application, applicationWizardScenarios: includedScenarios };
    updateApplication(updatedApplication);
  };

  return (
    <FormProvider {...methods}>
      <form id="form-coverage-decisions" onSubmit={handleSubmit(saveInProgressWork)} style={{ height: '100%', width: '100%' }}>
        <ApplicationWizardPage tabIndex={CoverageDecisionStep.index} formId="form-coverage-decisions" onPreviousClick={handleNavigateToPrevious}>
          <Grid container item xs={12} columnGap={1.5} p={0} pb={1} pt={1} sx={{ height: '73px', alignItems: 'flex-end' }}>
            <Grid item>
              <QuoteSelectInput clientFileId={clientFileId} selectedQuotes={includedQuoteIds} onChange={onSelectedQuotesChanged} />
            </Grid>
            {coverageDecisionErrors.length > 0 && (
              <Button sx={{ height: '35px' }} size="small" startIcon={<ErrorIcon />} color="error" id="btn-show-coverage-errors" onClick={() => setShowCoverageValidationErrors(true)}>Show Errors</Button>
            )}
          </Grid>
          <Grid item container sx={{ flexDirection: 'row', flexWrap: 'nowrap', height: 'calc(100% - 73px)', pb: 1 }}>
            <Grid item container xs={12}>
              <Grid item xs={12} sx={{ height: '100%' }} className={isLightMode ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}>
                <CoverageDecisionScenarioGrid application={application} clientFile={clientFile} includedQuoteIds={includedQuoteIds} updateApplication={updateApplication} />
              </Grid>
            </Grid>
          </Grid>
        </ApplicationWizardPage>
      </form>
      {showCoverageValidationErrors && (
        <CoverageDecisionValidationDialog errors={coverageDecisionErrors} onClose={() => setShowCoverageValidationErrors(false)} />
      )}
    </FormProvider>
  );
};

export default CoverageDecisionPage;