import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';
import {
  fetchHistoricalColumnsForUser,
  fetchLinkedMatricesForUser,
  fetchLinkedScenariosForUser,
  fetchMatrixColorSettingsForUser,
  fetchScenarioOrderForUser,
  fetchScenarioPieceOrderForUser,
  fetchUnitColumnsForUser,
  fetchPremiumBreakdownOptionsForUser,
  fetchUnitModalColumnsForUser,
  fetchHailPlanExplorerColumnsForUser,
  fetchPaletteModeSettingsForUser,
  fetchMatrixPresetsForUser
} from '../../app/userSettingsSlice';
import { useAppDispatch } from '../../hooks/reduxHooks';

/** Fetches certain critical user information as soon as the user is authenticated. */
export const useFetchUserSettings = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    async function fetchUserSettings() {
      await Promise.all([
        dispatch(fetchLinkedScenariosForUser()),
        dispatch(fetchScenarioOrderForUser()),
        dispatch(fetchScenarioPieceOrderForUser()),
        dispatch(fetchMatrixPresetsForUser()),
        dispatch(fetchMatrixColorSettingsForUser()),
        dispatch(fetchLinkedMatricesForUser()),
        dispatch(fetchUnitColumnsForUser()),
        dispatch(fetchUnitModalColumnsForUser()),
        dispatch(fetchHistoricalColumnsForUser()),
        dispatch(fetchPremiumBreakdownOptionsForUser()),
        dispatch(fetchHailPlanExplorerColumnsForUser()),
        dispatch(fetchPaletteModeSettingsForUser()),
      ]);
    }

    if (isAuthenticated) {
      fetchUserSettings();
    }
  }, [isAuthenticated]);
};