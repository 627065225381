import { useFormContext, Controller } from 'react-hook-form';
import VALIDATION_RULES from '../../constants/formValidationRules';
import { Nullable } from '../../types/util/Nullable';
import NumberTextField, { onNumericInputChange } from '../numberTextField/numberTextField.component';
import { FormInputProps } from './scenario/formInputProps';
import { ExtendedDataFormInputParameterNames } from './constants/inputConstants';

interface CustomPremiumProps extends FormInputProps {
  customPremium: Nullable<number>;
  label?: string;
}

export type CustomPremiumFields = {
  customPremium: number;
};

export const CustomPremiumInput = ({ customPremium, sx, autoFocus = false, disabled = false, label }: CustomPremiumProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.CustomPremium}
      control={control}
      defaultValue={customPremium ?? 0}
      rules={{
        required: VALIDATION_RULES.required(),
        min: VALIDATION_RULES.minimum(0),
        maxLength: VALIDATION_RULES.maxLength(8),
      }}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          type="number"
          label={label ?? 'Custom Premium'}
          fullWidth
          value={value}
          inputProps={{ min: 0, maxLength: 8, step: .01 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          sx={sx}
          disabled={disabled}
          size="small"
        />
      )}
    />
  );
};
