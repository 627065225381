import {
  getHistoricalColumnsForUser, getLinkedMatricesForUser,
  getLinkedScenariosForUser, getMatrixColorSettingsForUser,
  getScenarioOrderForUser,
  getScenarioPieceOrderForUser,
  getUnitColumnsForUser,
  updateHistoricalColumnsForUser, updateLinkedMatricesForUser,
  updateLinkedScenariosForUser, updateMatrixColorSettingsForUser,
  updateScenarioOrderForUser,
  updateScenarioPieceOrderForUser,
  updateUnitColumnsForUser,
  getPremiumBreakdownSettingsForUser,
  updatePremiumBreakdownSettingsForUser,
  getUnitModalColumnsForUser,
  updateUnitModalColumnsForUser,
  getHailPlanExplorerColumns,
  updateHailPlanExplorerColumnsForUser,
  getPaletteModeSettingsForUser,
  updatePaletteModeSettingsForUser,
  getMatrixPresetsForUser,
  updateMatrixPresetsForUser
} from '../userSettings.service';
import { ColumnState } from 'ag-grid-community';
import { UserLinkedMatrices, UserLinkedScenarios } from '../../types/api/userSettings/UserLinkedScenarios';
import { UserScenarioOrder } from '../../types/api/userSettings/UserScenarioOrder';
import { UserScenarioPieceOrder } from '../../types/api/userSettings/UserScenarioPieceOrder';
import { UserMatrixColorDictionary } from '../../types/api/userSettings/UserMatrixColor';
import { PremiumBreakdownOptions } from '../../types/api/userSettings/PremiumBreakdownOptions';
import {
  cacheOnly,
  networkOnlyAndUpdate,
  nonGetCacheOnly,
  nonGetNetworkOnlyAndUpdate
} from '../../utils/cachingStrategies';
import { quoterDb } from '../../db';
import { safeGet } from '../../utils/dexieQueryHelpers/getClauses';
import { UserSettingType, UserSettingTypes } from '../../constants/userSettingType';
import UserSetting from '../../types/api/userSettings/UserSetting';
import { Nullable } from '../../types/util/Nullable';
import { toPrimaryKey } from '../../utils/primaryKeyHelpers';
import { UserSettingsId } from '../../types/api/PrimaryKeys';
import * as uuid from 'uuid';
import { isOnlineFromStore } from '../../utils/isOnlineFromStore';
import { msalInstance } from '../../authConfig';
import { PaletteModeSettings } from '../../types/api/userSettings/PaletteModeSettings';
import { EmptyUserMatrixPresetsSetting } from '../../utils/userSettingUtils';
import { UserMatrixPresets } from '../../types/api/userSettings/UserMatrixPresets';

export const getPremiumBreakdownSettingsForUserRequest = async (): Promise<PremiumBreakdownOptions> => {
  const userId = getUserId();

  const request = () => getPremiumBreakdownSettingsForUser();

  const defaultPremiumBreakdownOptions: PremiumBreakdownOptions = {
    summary: {
      premium: true,
      subsidy: true,
      coverage: true,
      subsidyPercent: true,
    },
    details: {
      coveragePerAcre: true,
      premiumPerAcre: true,
      totalPremium: true,
      subsidyPercent: true,
      subsidyPerAcre: true,
      totalSubsidy: true,
    },
    includeHPO: true,
  };
  const userSetting = () => getUserSetting<PremiumBreakdownOptions>(userId, UserSettingTypes.premiumBreakdown, defaultPremiumBreakdownOptions);

  const updateTransaction = async (retrievedSetting: PremiumBreakdownOptions) => (await updateUserSetting<PremiumBreakdownOptions>(userId, UserSettingTypes.premiumBreakdown.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updatePremiumBreakdownSettingsForUserRequest = async (premiumBreakdownOptions: PremiumBreakdownOptions): Promise<void> => {
  const userId = getUserId();

  const request = () => updatePremiumBreakdownSettingsForUser(premiumBreakdownOptions);

  const updateTransaction = await updateUserSetting<PremiumBreakdownOptions>(userId, UserSettingTypes.premiumBreakdown.userSettingTypeId, premiumBreakdownOptions);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const getHailPlanExplorerColumnsForUserRequest = async (): Promise<ColumnState[]> => {
  const userId = getUserId();

  const request = () => getHailPlanExplorerColumns();

  const userSetting = () => getUserSetting<ColumnState[]>(userId, UserSettingTypes.unitModalColumn, []);

  const updateTransaction = async (retrievedSetting: ColumnState[]) => (await updateUserSetting<ColumnState[]>(userId, UserSettingTypes.hailPlanExplorerColumn.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updateHailPlanExplorerColumnsForUserRequest = async (planExplorerColumns: ColumnState[]): Promise<void> => {
  const userId = getUserId();

  const request = () => updateHailPlanExplorerColumnsForUser(planExplorerColumns);

  const updateTransaction = await updateUserSetting<ColumnState[]>(userId, UserSettingTypes.hailPlanExplorerColumn.userSettingTypeId, planExplorerColumns);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const getUnitModalColumnsForUserRequest = async (): Promise<ColumnState[]> => {
  const userId = getUserId();

  const request = () => getUnitModalColumnsForUser();

  const userSetting = () => getUserSetting<ColumnState[]>(userId, UserSettingTypes.unitModalColumn, []);

  const updateTransaction = async (retrievedSetting: ColumnState[]) => (await updateUserSetting<ColumnState[]>(userId, UserSettingTypes.unitModalColumn.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updateUnitModalColumnsForUserRequest = async (unitColumns: ColumnState[]): Promise<void> => {
  const userId = getUserId();

  const request = () => updateUnitModalColumnsForUser(unitColumns);

  const updateTransaction = await updateUserSetting<ColumnState[]>(userId, UserSettingTypes.unitModalColumn.userSettingTypeId, unitColumns);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const getUnitColumnsForUserRequest = async (): Promise<ColumnState[]> => {
  const userId = getUserId();

  const request = () => getUnitColumnsForUser();

  const userSetting = () => getUserSetting<ColumnState[]>(userId, UserSettingTypes.unitColumn, []);

  const updateTransaction = async (retrievedSetting: ColumnState[]) => (await updateUserSetting<ColumnState[]>(userId, UserSettingTypes.unitColumn.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updateUnitColumnsForUserRequest = async (unitColumns: ColumnState[]): Promise<void> => {
  const userId = getUserId();

  const request = () => updateUnitColumnsForUser(unitColumns);

  const updateTransaction = await updateUserSetting<ColumnState[]>(userId, UserSettingTypes.unitColumn.userSettingTypeId, unitColumns);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const getHistoricalColumnsForUserRequest = async (): Promise<ColumnState[]> => {
  const userId = getUserId();

  const request = () => getHistoricalColumnsForUser();

  const userSetting = () => getUserSetting<ColumnState[]>(userId, UserSettingTypes.historicalColumn, []);

  const updateTransaction = async (retrievedSetting: ColumnState[]) => (await updateUserSetting<ColumnState[]>(userId, UserSettingTypes.historicalColumn.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updateHistoricalColumnsForUserRequest = async (historicalColumns: ColumnState[]): Promise<void> => {
  const userId = getUserId();

  const request = () => updateHistoricalColumnsForUser(historicalColumns);

  const updateTransaction = await updateUserSetting<ColumnState[]>(userId, UserSettingTypes.historicalColumn.userSettingTypeId, historicalColumns);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const getLinkedScenariosForUserRequest = async (): Promise<UserLinkedScenarios> => {
  const userId = getUserId();

  const request = () => getLinkedScenariosForUser();

  const defaultUserLinkedScenarios: UserLinkedScenarios = {
    quotes: {},
  };

  const userSetting = () => getUserSetting<UserLinkedScenarios>(userId, UserSettingTypes.linkedScenarios, defaultUserLinkedScenarios);

  const updateTransaction = async (retrievedSetting: UserLinkedScenarios) => (await updateUserSetting<UserLinkedScenarios>(userId, UserSettingTypes.linkedScenarios.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updateLinkedScenariosForUserRequest = async (userLinkedScenarios: UserLinkedScenarios): Promise<void> => {
  const userId = getUserId();

  const request = () => updateLinkedScenariosForUser(userLinkedScenarios);

  const updateTransaction = await updateUserSetting<UserLinkedScenarios>(userId, UserSettingTypes.linkedScenarios.userSettingTypeId, userLinkedScenarios);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const getScenarioOrderForUserRequest = async (): Promise<UserScenarioOrder> => {
  const userId = getUserId();

  const request = () => getScenarioOrderForUser();

  const defaultUserScenarioOrder: UserScenarioOrder = {
    quotes: {},
  };

  const userSetting = () => getUserSetting<UserScenarioOrder>(userId, UserSettingTypes.scenarioOrder, defaultUserScenarioOrder);

  const updateTransaction = async (retrievedSetting: UserScenarioOrder) => (await updateUserSetting<UserScenarioOrder>(userId, UserSettingTypes.scenarioOrder.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updateScenarioOrderForUserRequest = async (userScenarioOrder: UserScenarioOrder): Promise<void> => {
  const userId = getUserId();

  const request = () => updateScenarioOrderForUser(userScenarioOrder);

  const updateTransaction = await updateUserSetting<UserScenarioOrder>(userId, UserSettingTypes.scenarioOrder.userSettingTypeId, userScenarioOrder);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const getScenarioPieceOrderForUserRequest = async (): Promise<UserScenarioPieceOrder> => {
  const userId = getUserId();

  const request = () => getScenarioPieceOrderForUser();

  const defaultUserScenarioPieceOrder: UserScenarioPieceOrder = {
    scenarios: {},
  };

  const userSetting = () => getUserSetting<UserScenarioPieceOrder>(userId, UserSettingTypes.scenarioPieceOrder, defaultUserScenarioPieceOrder);

  const updateTransaction = async (retrievedSetting: UserScenarioPieceOrder) => (await updateUserSetting<UserScenarioPieceOrder>(userId, UserSettingTypes.scenarioPieceOrder.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updateScenarioPieceOrderForUserRequest = async (userScenarioPieceOrder: UserScenarioPieceOrder): Promise<void> => {
  const userId = getUserId();

  const request = () => updateScenarioPieceOrderForUser(userScenarioPieceOrder);

  const updateTransaction = await updateUserSetting<UserScenarioPieceOrder>(userId, UserSettingTypes.scenarioPieceOrder.userSettingTypeId, userScenarioPieceOrder);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const getPaletteModeSettingsForUserRequest = async (): Promise<PaletteModeSettings> => {
  const userId = getUserId();

  const request = () => getPaletteModeSettingsForUser();

  const defaultPaletteModeSettings: PaletteModeSettings = { paletteMode: 'light' };

  const userSetting = () => getUserSetting<PaletteModeSettings>(userId, UserSettingTypes.paletteMode, defaultPaletteModeSettings);

  const updateTransaction = async (retrievedSetting: PaletteModeSettings) => (await updateUserSetting<PaletteModeSettings>(userId, UserSettingTypes.paletteMode.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updatePaletteModeSettingsForUserRequest = async (paletteModeSettings: PaletteModeSettings): Promise<void> => {
  const userId = getUserId();

  const request = () => updatePaletteModeSettingsForUser(paletteModeSettings);

  const updateTransaction = await updateUserSetting<PaletteModeSettings>(userId, UserSettingTypes.paletteMode.userSettingTypeId, paletteModeSettings);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const getMatrixPresetsForUserRequest = async (): Promise<UserMatrixPresets> => {
  const userId = getUserId();

  const request = () => getMatrixPresetsForUser();

  const userSetting = () => getUserSetting<UserMatrixPresets>(userId, UserSettingTypes.matrixPresets, EmptyUserMatrixPresetsSetting);

  const updateTransaction = async (retrievedSetting: UserMatrixPresets) => (await updateUserSetting<UserMatrixPresets>(userId, UserSettingTypes.matrixPresets.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updateMatrixPresetsForUserRequest = async (matrixPresets: UserMatrixPresets): Promise<void> => {
  const userId = getUserId();

  const request = () => updateMatrixPresetsForUser(matrixPresets);

  const updateTransaction = await updateUserSetting<UserMatrixPresets>(userId, UserSettingTypes.matrixPresets.userSettingTypeId, matrixPresets);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const getMatrixColorSettingsForUserRequest = async (): Promise<UserMatrixColorDictionary> => {
  const userId = getUserId();

  const request = () => getMatrixColorSettingsForUser();

  const userSetting = () => getUserSetting<UserMatrixColorDictionary>(userId, UserSettingTypes.matrixColors, {});

  const updateTransaction = async (retrievedSetting: UserMatrixColorDictionary) => (await updateUserSetting<UserMatrixColorDictionary>(userId, UserSettingTypes.matrixColors.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updateMatrixColorSettingsForUserRequest = async (matrixColorSettings: UserMatrixColorDictionary): Promise<void> => {
  const userId = getUserId();

  const request = () => updateMatrixColorSettingsForUser(matrixColorSettings);

  const updateTransaction = await updateUserSetting<UserMatrixColorDictionary>(userId, UserSettingTypes.matrixColors.userSettingTypeId, matrixColorSettings);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const getLinkedMatricesForUserRequest = async (): Promise<UserLinkedMatrices> => {
  const userId = getUserId();

  const request = () => getLinkedMatricesForUser();

  const defaultUserLinkedMatrices: UserLinkedMatrices = {
    quotes: {},
  };

  const userSetting = () => getUserSetting<UserLinkedMatrices>(userId, UserSettingTypes.linkedMatrices, defaultUserLinkedMatrices);

  const updateTransaction = async (retrievedSetting: UserLinkedMatrices) => (await updateUserSetting<UserLinkedMatrices>(userId, UserSettingTypes.linkedMatrices.userSettingTypeId, retrievedSetting))();

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return strategy(request, userSetting, updateTransaction);
};

export const updateLinkedMatricesForUserRequest = async (userLinkedMatrices: UserLinkedMatrices): Promise<void> => {
  const userId = getUserId();

  const request = () => updateLinkedMatricesForUser(userLinkedMatrices);

  const updateTransaction = await updateUserSetting<UserLinkedMatrices>(userId, UserSettingTypes.linkedMatrices.userSettingTypeId, userLinkedMatrices);

  const strategy = isOnlineFromStore() ? nonGetNetworkOnlyAndUpdate : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

const getUserSetting = async <T>(userId: string, userSettingType: UserSettingType, defaultValue: T): Promise<T> => {
  const userSetting = await getUserSettingData(userId, userSettingType.userSettingTypeId);

  const userSettingJson = userSetting?.settingValue;

  if (userSettingJson === undefined) return defaultValue;

  try {
    const jsonObject = JSON.parse(userSettingJson);
    return jsonObject as T;
  } catch {
    throw new Error(`Failed to deserialize user setting of type ${userSettingType.name}. Stored JSON was likely malformed`);
  }
};

const getUserSettingData = async (userId: string, userSettingType: number): Promise<Nullable<UserSetting>> => {
  return quoterDb.transaction('r', quoterDb.userSettings, async () => {
    return (await safeGet(quoterDb.userSettings, { userId: userId, userSettingTypeId: userSettingType })) ?? null;
  });
};

const updateUserSettingData = async (userSetting: UserSetting): Promise<void> => {
  return quoterDb.transaction('rw', quoterDb.userSettings, () => {
    quoterDb.userSettings.put(userSetting);
  });
};

const updateUserSetting = async <T>(userId: string, userSettingType: number, settingValue: T): Promise<() => Promise<void>> => {
  const serializedJson = JSON.stringify(settingValue);

  let userSettingToUpdate = await getUserSettingData(userId, userSettingType);

  if (userSettingToUpdate === null) {
    userSettingToUpdate = {
      userSettingsId: toPrimaryKey<UserSettingsId>(uuid.v4()),
      userId: userId,
      userSettingTypeId: userSettingType,
      settingValue: '',
      offlineCreatedOn: undefined,
      offlineLastUpdatedOn: undefined,
      offlineDeletedOn: undefined,
    };
  }

  userSettingToUpdate.settingValue = serializedJson;

  return () => updateUserSettingData(userSettingToUpdate as UserSetting);
};

const getUserId = (): string => {
  const accounts = msalInstance.getAllAccounts();
  const userId = accounts[0].username;

  return userId;
};
