import { admDb } from '../../db';
import { Practice } from '../../types/api/adm/Practice';
import { getPracticesForCommodity } from '../practices.service';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { getDefaultCachingStrategy } from '../offlineDataAccess.service';

export const getPracticesForCommodityRequest = async (year: number, commodityCode: string, countyId: string, typeId: string): Promise<Practice[]> => {
  const request = () => getPracticesForCommodity(year, commodityCode, countyId, typeId);

  const transactionTables = [admDb.insuranceOffers, admDb.practices, admDb.commodityUses, admDb.commodities];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const commodityUses = await safeWhere(admDb.commodityUses, { commodityCode: commodityCode }).toArray();
    const commodityUseIds = commodityUses.map(cu => cu.commodityUseId);
    const insuranceOffers = await safeWhere(admDb.insuranceOffers, { countyId: countyId, typeId: typeId }).and(io => commodityUseIds.includes(io.commodityUseId)).toArray();
    const practiceIds = insuranceOffers.map(io => io.practiceId);
    const practices = await safeWhere(admDb.practices, 'practiceId').anyOf(practiceIds).toArray();
    return practices;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};
