import { IEnumAttributes } from '../IEnumAttributes';

/* eslint-disable no-unused-vars */
export enum CoverageType {
  Cancel = 907550004,
  CancelTransfer = 907550002,
  HoldForCancelTransfer = 907550003,
  New = 907550001,
  Renew = 907550000
}

export const CoverageTypeAttributes: Readonly<Record<CoverageType, IEnumAttributes<CoverageType>>> = {
  [ CoverageType.Cancel ]: { name: 'Cancel', description: 'C', value: CoverageType.Cancel },
  [ CoverageType.CancelTransfer ]: { name: 'Cancel/Transfer', description: 'CT', value: CoverageType.CancelTransfer },
  [ CoverageType.HoldForCancelTransfer ]: { name: 'Hold for Cancel/Transfer', description: 'H', value: CoverageType.HoldForCancelTransfer },
  [ CoverageType.New ]: { name: 'New', description: 'N', value: CoverageType.New },
  [ CoverageType.Renew ]: { name: 'Renew', description: 'R', value: CoverageType.Renew },
};