import { Grid } from '@mui/material';
import { AnalysisBarChart } from './charts/AnalysisBarChart';
import { LineData } from './charts/AnalysisLineChart';
import { useTrendLinePercentages } from '../../../hooks/useTrendlinePercentages';
import { ScenarioId, TrendlineAnalysisId } from '../../../types/api/PrimaryKeys';
import { AcreageType } from '@silveus/calculations';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { addLineData, selectLineDataByType } from '../../../app/trendlineAnalysisSlice';
import { TrendlineType } from '../../../types/api/enums/trendlineAnalysis/TrendlineType.enum';
import { useLineData } from '../../../hooks/useLineData';
import HistoricalPercentages from '../../../types/api/adm/HistoricalPercentages';
import { possibleColors } from './charts/chartColors';
import { useMemo } from 'react';

interface PercentChangeChartProps {
  trendlineAnalysisId: TrendlineAnalysisId;
  scenarioId: ScenarioId;
  startYear: number;
  endYear: number;
  showLabels: boolean;
  acreageType: AcreageType;
}

const defaultTrendlineDataKeys = new Set<keyof HistoricalPercentages>(['percentChangePrice', 'percentChangeProducerYield', 'percentChangeCountyYield']);

const PercentChangeChart = ({ scenarioId, startYear, endYear, showLabels, acreageType, trendlineAnalysisId }: PercentChangeChartProps) => {
  const dispatch = useAppDispatch();
  const cachedLineData = useAppSelector(state => selectLineDataByType(state, trendlineAnalysisId, TrendlineType.PercentChange));
  const historicalPercentages = useTrendLinePercentages(scenarioId, startYear, endYear, acreageType);

  const mpPricePercentChangeKey: keyof HistoricalPercentages = 'percentChangeMpPrice';

  const barChartLineData: LineData<HistoricalPercentages>[] = [
    {
      dataKey: 'percentChangePrice',
      color: possibleColors[0],
      name: 'Price',
    },
    {
      dataKey: mpPricePercentChangeKey,
      color: possibleColors[1],
      name: 'MP Price',
    },
    {
      dataKey: 'percentChangeProducerYield',
      color: possibleColors[2],
      name: 'Producer Yield',
    },
    {
      dataKey: 'percentChangeCountyYield',
      color: possibleColors[3],
      name: 'County Yield',
    },
  ];

  const trimmedBarChartLineData = useMemo(() => barChartLineData.filter(line => line.dataKey !== mpPricePercentChangeKey || historicalPercentages.some(data => data[line.dataKey] !== undefined && data[line.dataKey] !== 0)), [barChartLineData, historicalPercentages]);

  const trendlineLineData = useLineData(trimmedBarChartLineData, trendlineAnalysisId, cachedLineData, TrendlineType.PercentChange, defaultTrendlineDataKeys);
  if (cachedLineData.length === 0) {
    dispatch(addLineData({ lineData: trendlineLineData }));
  }

  const filteredLineData = trimmedBarChartLineData.filter(ld => cachedLineData.filter(x => x.isVisible).find(x => ld.dataKey === x.dataKey));

  return (
    <Grid container sx={{ height: '100%', p: 2 }}>
      <AnalysisBarChart
        data={historicalPercentages}
        barData={filteredLineData}
        xAxisKey={'year'}
        showTooltip
        showLabels={showLabels}
      />
    </Grid>
  );
};

export default PercentChangeChart;