import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useSupportModal } from './useSupportModal';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { closeSupportModal, sendSupportTicket } from '../../app/supportSlice';
import { msalInstance } from '../../authConfig';
import { SupportPayload } from '../../types/api/support/support';
import { ConfirmStateContent, closeConfirm, openConfirm } from '../../app/confirmSlice';
import { Nullable } from '../../types/util/Nullable';
import * as htmlToImage from 'html-to-image';

const ErrorReasons = [
  'Miscellaneous',
];

export const SupportModal = () => {
  const { handleClose } = useSupportModal();
  const dispatch = useAppDispatch();
  const [errorReason, setErrorReason] = useState('');
  const [description, setDescription] = useState('');
  const [screenshotBlob, setScreenshotBlob] = useState<Nullable<Blob>>(null);
  const [validationError, setValidationError] = useState(false);

  const submitTicket = async () => {
    if (screenshotBlob === null) {
      return;
    }
    if (!errorReason || !description) {
      setValidationError(true);
      return;
    }
    setValidationError(false);
    const accounts = msalInstance.getAllAccounts();
    const userId = accounts[0].username;
    const data: SupportPayload = {
      url: window.location.href,
      reason: errorReason,
      description,
      blob: screenshotBlob,
      email: userId,
    };

    const response = await dispatch(sendSupportTicket({ supportPayload: data }));
    if (response.meta.requestStatus !== 'fulfilled') {
      showErrorConfirmDialog();
      return;
    }
    dispatch(closeSupportModal());
  };

  const showErrorConfirmDialog = () => {
    const confirmWindow: ConfirmStateContent = {
      title: ' Couldn\'t Connect',
      message: 'We were unable to send your support ticket. Please check your network connection and try again.',
      confirmText: 'Close',
      hideCancel: true,
      onConfirm: () => {
        dispatch(closeConfirm());
      },
    };
    dispatch(openConfirm(confirmWindow));
  };

  useEffect(() => {
    const takeScreenshot = async () => {
      const body = document.querySelector('body');
      if (body) {
        const blob = await htmlToImage.toBlob(body);
        setScreenshotBlob(blob);
      }
    };

    if (screenshotBlob === null) {
      takeScreenshot();
    }
  }, [screenshotBlob]);

  // A screenshot without the modal in the way is required before we can get more data
  if (screenshotBlob === null) {
    return null;
  }

  return (
    <Dialog
      open={true}
      onClose={(_, reason) => handleClose(reason)}
      maxWidth={false}
      scroll="body"
      sx={{ m: 2 }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>
        <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', height: '64px' }}>
          <Grid item xs>Support Helpdesk Ticket</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ width: '700px' }}>
        <Grid item xs={12} sx={{ alignItems: 'center' }}>
          <Typography variant="body1" textAlign="center">
            We have captured a screenshot of your desktop to submit as support ticket.
            Please provide any supporting details you&apos;d like us to know.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ alignItems: 'center', marginTop: '15px' }}>
          <FormControl sx={{ width: '100%' }} error={validationError && !errorReason}>
            <InputLabel id="error-reason-label">Error Type</InputLabel>
            <Select
              labelId="error-reason-select-label"
              id="error-reason-select"
              value={errorReason}
              label="Error Type"
              style={{ width: '100%' }}
              onChange={(event: SelectChangeEvent) => {
                setErrorReason(event.target.value);
              }}
            >
              <MenuItem value=""></MenuItem>
              {
                ErrorReasons.map(reason => (
                  <MenuItem key={`reason-${reason}`} value={reason}>{reason}</MenuItem>
                ))
              }
            </Select>
            {validationError && !errorReason && (
              <FormHelperText>A reason is required</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '15px' }}>
          <FormControl sx={{ width: '100%' }} error={validationError && !description}>
            <TextField
              id="support-description"
              label="Description of Problem"
              multiline={true}
              maxRows={4}
              variant="standard"
              value={description}
              style={{ width: '100%' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(event.target.value);
              }}
              placeholder="Enter a brief description of your problem here..."
              error={validationError && !description}
            />
            {validationError && !description && (
              <FormHelperText>A description is required</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <DialogActions style={{ marginTop: '15px' }}>
          <Button
            id="support-form-close-button"
            variant="outlined"
            onClick={() => handleClose()}
          >
            Close
          </Button>
          <Button
            id="support-form-submit-button"
            variant="contained"
            onClick={() => submitTicket()}
          >
            Submit
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};