import { OptionCode, UnitStructureAttributes, UnitStructureCode } from '@silveus/calculations';
import { ADMYear } from '../../../types/api/adm/ADMYear';
import { AppTask } from '../../../types/api/AppTask';
import { AppTaskCoverage } from '../../../types/api/appTaskCoverage';
import { AppType } from '../../../types/api/enums/application/AppType.enum';
import { CoverageType } from '../../../types/api/enums/application/coverageType.enum';
import { PersonOfInterestType, PersonOfInterestTypeAttributes } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { PersonOrBusiness } from '../../../types/api/enums/contactInfo/personOrBusiness';
import { getCommodityName } from '../../../utils/adm';
import { isAreaPlan } from '../../applicationsModal/wizardSteps/appDecisionsPage/appTaskUtils';
import { PersonOfInterest } from '../../../types/api/insureds/PersonOfInterest';
import { isNilOrEmpty, isNotNilOrEmpty } from '../../../utils/nullHandling';

export const mapDelimitedCancelTransferCrops = (appTask: AppTask, admYears: ADMYear[]) => {
  if (appTask.appType !== AppType.CancelTransfer) return null;

  const cancelTransferCoverages = appTask.coverages.filter(coverage => coverage.coverageType === CoverageType.CancelTransfer);
  const crops = new Set(cancelTransferCoverages.map(coverage => getCommodityName(admYears, appTask.cropYear, coverage.cropCode)));
  return Array.from(crops).join(', ');
};

export const mapNewProducerCoverages = (appTask: AppTask, admYears: ADMYear[]) => {
  const newProducerCoverages: string[] = [];

  for (const coverage of appTask.coverages) {
    if (coverage.isNewProducer === true) {
      newProducerCoverages.push(getCommodityName(admYears, appTask.cropYear, coverage.cropCode));
    }
  }
  return newProducerCoverages;
};

export const mapDelimitedPoasAndAuthorizedReps = (appTask: AppTask): string => {
  const poaAndAuthRepPersons = appTask.insuredOtherPersonsOfInterest.filter(person => person.personOfInterestType === PersonOfInterestType.PowerOfAttorney || person.personOfInterestType === PersonOfInterestType.AuthorizedRep);
  const poaAndAuthRepStringArray: string[] = [];

  for (const poaOrAuthRep of poaAndAuthRepPersons) {
    const name = formatPersonOfInterestName(poaOrAuthRep);
    if (isNotNilOrEmpty(name)) {
      poaAndAuthRepStringArray.push(name);
    }
  }

  return poaAndAuthRepStringArray.join(', ');
};

export const mapDelimitedUnitAndOptionCodes = (coverage: AppTaskCoverage) => {
  const excludedUnitStructureCodes = [UnitStructureCode.Unset, UnitStructureCode.AU];
  const sanitizedUnitStructureCode = isAreaPlan(coverage.planCode) || excludedUnitStructureCodes.includes(coverage.unitStructure)
    ? ''
    : UnitStructureAttributes[coverage.unitStructure].name;
  // Filtering out EU unitStructureCode when an EI option is selected on an EU coverage
  if (coverage.unitStructure === UnitStructureCode.EU && coverage.optionCodes.includes(OptionCode.EI)) {
    return [...coverage.optionCodes].join(',');
  }

  return [sanitizedUnitStructureCode, ...coverage.optionCodes].join(',');
};
export const mapDelimitedAuthorizedSigners = (appTask: AppTask): string => {
  const { insuredOtherPersonsOfInterest } = appTask;
  const authorizedToSign = insuredOtherPersonsOfInterest.filter(person => person.personOfInterestType === PersonOfInterestType.AuthorizedToSign);
  const authSignerArray: string[] = [];

  for (const authSigner of authorizedToSign) {
    const name = formatPersonOfInterestName(authSigner);
    if (isNotNilOrEmpty(name)) {
      authSignerArray.push(name);
    }
  }
  return authSignerArray.join(', ');
};

export const formatPersonOfInterestName = (personOfInterest: PersonOfInterest): string => {
  const formattedNameArray = [];

  if (personOfInterest.personOrBusiness === PersonOrBusiness.BUSINESS) {
    formattedNameArray.push(personOfInterest.firstName ?? '');
  } else {
    formattedNameArray.push(personOfInterest.firstName, personOfInterest.lastName);
    if (!isNilOrEmpty(personOfInterest.suffix)) {
      formattedNameArray.push(personOfInterest.suffix);
    }
    formattedNameArray.push(`(${PersonOfInterestTypeAttributes[personOfInterest.personOfInterestType].name})`);
  }

  return formattedNameArray.join(', ');
};

export const shouldShowTypeOrPracticeOnForm = (coverage: AppTaskCoverage): boolean => {
  // Getty is re-verifying this list with the CSR team
  // We may have only 1 item at the end, so leaving the set local for now
  const eligibleOptionStrings = [OptionCode.LP.toString(), OptionCode.EI.toString(), OptionCode.ET.toString()];

  return coverage.optionCodes.some(o => eligibleOptionStrings.includes(o));
};

export const ecoPlanCodesNameString = 'ECO';
export const hipPlanCodesNameString = 'HIP';
export const scoPlanCodesNameString = 'SCO';
export const staxPlanCodesNameString = 'STAX';

export const filterCoveragesByPlanCodeName = (coverages: AppTaskCoverage[], planCodeName: string): AppTaskCoverage[] => {
  return coverages.filter(coverage => coverage.childCoverages.some(childCoverage => childCoverage.planCodeName.includes(planCodeName)));
};
