import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';

interface OptionalColumnInputProps {
  name: string;
  label: string;
  defaultValue?: boolean;
}

export const OptionalColumnInput = ({ name, label, defaultValue = true }: OptionalColumnInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { ref, value, ...field },
      }) => (
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              {...field}
              checked={value}
              inputRef={ref}
              onChange={e => field.onChange(e.target.checked)}
            />
          }
        />
      )}
    />
  );
};