import { SxProps } from '@mui/system';
import { Theme } from '@mui/system';

export const getInputCostTableStyles = (theme: Theme): Record<string, SxProps<Theme>> => {
  const cellWidth = '46%';
  const actionCellWidth = '8%';
  const tableBorderStyle = `1px solid ${theme.palette.divider}`;

  return {
    tableContainer: {
      maxHeight: 'calc(100vh - 400px)',
      minHeight: '0px',
      overflowX: 'hidden',
    },
    table: {
      tableLayout: 'fixed',
      width: '100%',
      borderLeft: tableBorderStyle,
      borderRight: tableBorderStyle,
    },
    headerCell: {
      width: cellWidth,
      backgroundColor: theme.palette.background.paper,
    },
    actionHeaderCell: {
      width: actionCellWidth,
      padding: '0',
      backgroundColor: theme.palette.background.paper,
    },
    bodyCell: {
      width: cellWidth,
      borderRight: tableBorderStyle,
    },
    actionCell: {
      width: actionCellWidth,
      padding: '0',
    },
  };
};

