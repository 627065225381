import { quoterDb } from '../../db';
import { ScenarioId, ScenarioPieceId } from '../../types/api/PrimaryKeys';
import {
  createHarvestRevenueScenarioPiece,
  getHarvestRevenueScenarioPiece,
  getHarvestRevenueScenarioPiecesForScenario,
  updateHarvestRevenueScenarioPiece,
  deleteHarvestRevenueScenarioPiece, getHarvestRevenueScenarioPiecesForScenarios
} from '../harvestRevenueScenarioPieces.service';
import {
  addSingle,
  remove,
  getMultiple,
  getSingle,
  update, getDefaultCachingStrategy
} from '../offlineDataAccess.service';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import HarvestRevenueScenarioPiece from '../../types/api/HarvestRevenueScenarioPiece';

const table = quoterDb.harvestRevenueScenarioPieces;

export const getHarvestRevenueScenarioPiecesForScenarioRequest = async (scenarioId: ScenarioId): Promise<HarvestRevenueScenarioPiece[]> => {
  const request = () => getHarvestRevenueScenarioPiecesForScenario(scenarioId);
  return await getMultiple(table, { scenarioId: scenarioId }, request);
};

export const getHarvestRevenueScenarioPiecesForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<HarvestRevenueScenarioPiece[]> => {
  const request = () => getHarvestRevenueScenarioPiecesForScenarios(scenarioIds);

  const readTransaction = () => quoterDb.transaction('r', quoterDb.harvestRevenueScenarioPieces, async () => {
    return safeWhere(quoterDb.harvestRevenueScenarioPieces, 'scenarioId').anyOf(scenarioIds).toArray();
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getHarvestRevenueScenarioPieceRequest = async (scenarioPieceId: ScenarioPieceId): Promise<HarvestRevenueScenarioPiece> => {
  const request = () => getHarvestRevenueScenarioPiece(scenarioPieceId);
  return await getSingle(table, { scenarioPieceId: scenarioPieceId }, request);
};

export const createHarvestRevenueScenarioPieceRequest = async (scenarioPiece: HarvestRevenueScenarioPiece): Promise<CreatedItemResult<ScenarioPieceId>> => {
  const request = () => createHarvestRevenueScenarioPiece(scenarioPiece);
  return await addSingle(table, scenarioPiece, request);
};

export const updateHarvestRevenueScenarioPieceRequest = async (scenarioPiece: HarvestRevenueScenarioPiece): Promise<void> => {
  const request = () => updateHarvestRevenueScenarioPiece(scenarioPiece);
  return await update(table, scenarioPiece, request);
};

export const deleteHarvestRevenueScenarioPieceRequest = async (scenarioPieceId: ScenarioPieceId): Promise<void> => {
  const request = () => deleteHarvestRevenueScenarioPiece(scenarioPieceId);
  return await remove(table, scenarioPieceId, request);
};
