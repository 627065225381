import { Card, CardContent, Grid, Typography } from '@mui/material';
import CurrencyText from '../../../components/currencyText/currencyText.component';

type HailBlendedNetColumnProps = {
  blendedValues: number[];
}

const HailBlendedNetColumn = ({ blendedValues }: HailBlendedNetColumnProps) => {
  return (
    <Card sx={{ height: 'fit-content', mt: 1, borderColor: 'transparent' }} raised>
      <CardContent sx={{ pb: '5px !important' }}>
        <Grid item justifyContent="center" alignItems="center" marginBottom={2}>
          <Typography fontWeight="medium" textAlign="center" >Blended Net</Typography>
        </Grid>
        <Grid container direction="column" rowGap={1} >
          {blendedValues.map((value, index) => (
            <Grid item key={index} sx={{ p: '0px !important' }} justifyContent="center" alignItems="center">
              <Typography textAlign="center">{<CurrencyText currencyAmount={value} colorize={value < 0} align="center" />}</Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HailBlendedNetColumn;