import BaseUnit from '../../../types/api/BaseUnit';
import {
  CoverageTypeCode,
  Unit,
  VipUnit,
  MPowerDRequestDTO,
  MPowerDManualPremiumParams,
  MPowerDIndemnityParams,
  MPowerDUnit,
  ScenarioPieceType
} from '@silveus/calculations';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import { getUnitsForScenarioPiece } from '../calculationUtils';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../../app/scenarioPiecesSlice';
import { MissingUnderlyingScenarioPieceInStateError } from '../../../errors/state/MissingStateErrors';
import { getItemsForId } from '../../../utils/mapHelpers';

export const createMPowerDData = (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Get the underlying MP
  const underlyingMp = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), scenario.scenarioId).find(sp => [ScenarioPieceType.MP, ScenarioPieceType.MpHpo].includes(sp.scenarioPieceType));
  if (underlyingMp === undefined) throw new MissingUnderlyingScenarioPieceInStateError(scenario.scenarioId);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createMPowerDPlanDto(scenarioPiece, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece, underlyingMp.upperCoverageLevel);
  return scenarioPieceSpecificData;
};

const transformMPowerDData = (scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece, underlyingCoverageLevel: number): MPowerDManualPremiumParams & MPowerDIndemnityParams => {
  const mPowerDParams: MPowerDManualPremiumParams & MPowerDIndemnityParams = {
    // MP has a separate projected price from the scenario so we pull it from the extended data
    projectedPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.projectedPrice ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
    priceCap: scenarioPiece.rowCropScenarioPieceExtendedData?.priceChangeCap ?? 0,
    topIntervalPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.topPrice ?? 0,
    mPowerDLiabilityFactor: scenarioPiece.protectionFactor / 100,

    expectedCosts: scenarioPiece.rowCropScenarioPieceExtendedData?.expectedInputCosts ?? 0,
    finalCosts: scenarioPiece.rowCropScenarioPieceExtendedData?.actualInputCosts ?? 0,
    protectionLevel: scenarioPiece.rowCropScenarioPieceExtendedData?.underlyingProtectionFactor ?? 0,

    upperCoverageLevelPercent: underlyingCoverageLevel / 100,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    unitStructureCode: scenarioPiece.unitStructure,
    coverageTypeCode: CoverageTypeCode.A,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,

    premiumPerAcre: scenarioPiece.rowCropScenarioPieceExtendedData?.customPremium ?? 0,
  };

  return mPowerDParams;
};

const createMPowerDUnits = (units: BaseUnit[]): (VipUnit & Unit)[] => {
  return units.map(unit => {
    const unitDtos = createBaseUnit(unit);
    const nonAreaRiskUnit: MPowerDUnit & Unit = {
      ...unitDtos,
      id: unit.id,
    };

    return nonAreaRiskUnit;
  });
};

const createMPowerDPlanDto = (scenarioPiece: RowCropScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[], underlyingCoverageLevel: number): MPowerDRequestDTO => {
  const result: MPowerDRequestDTO = {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createMPowerDUnits(
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
      ),
    ),
    ...transformMPowerDData(scenario, scenarioPiece, underlyingCoverageLevel),
  };
  return result;
};
