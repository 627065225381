import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack } from '@mui/material';
import { LoadFromStateProps } from './loadFromStateProps';
import { FormInputProps } from './formInputProps';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectIntervalsForScenarioPiece, getAvailableIntervalsForScenarioPieceType } from '../../../app/intervalsSlice';
import { Nullable } from '../../../types/util/Nullable';
import { ScenarioPieceType } from '@silveus/calculations';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IntervalYearInput } from './intervalYearInput.component';
import IntervalTabs from './intervalTabs.component';
import { useState } from 'react';
import { YearColumns } from './yearColumns.component';
import { ScenarioPieceId } from '../../../types/api/PrimaryKeys';
import { SelectedIntervalsFields } from './intervalCheckboxes.component';
import { YearFields } from '../yearInput.component';
import { IntervalType } from '../../../types/api/adm/IntervalRange';

interface Props extends LoadFromStateProps, FormInputProps {
  scenarioPieceType: ScenarioPieceType,
  clientFileYear: number,
  scenarioPieceId: Nullable<ScenarioPieceId>,
}

export type IntervalInputFields = SelectedIntervalsFields & YearFields;

const IntervalInput = ({ scenarioPieceType, clientFileYear, scenarioPieceId }: Props) => {
  const availableIntervals = useAppSelector(state => getAvailableIntervalsForScenarioPieceType(state, scenarioPieceType));
  const initialSelectedIntervals = useAppSelector(state => scenarioPieceId === null ? [] : selectIntervalsForScenarioPiece(state, scenarioPieceId));

  //since we don't save the originally selected interval type or year then we have to
  //derive it from the selected intervals. Here we grab the first selected one and use it to
  //set default tab and year selections
  const firstSelectedInverval = initialSelectedIntervals.at(0);
  //the interval range type that availableIntervals uses has the type and year info
  //that we need so we find the one that matches to the selected interval
  const matchingIntervalRangeForSelectedIntervals = availableIntervals.find(interval => interval.id === firstSelectedInverval?.intervalRangeId);

  //default to the type on the firstSelectedIntervals type
  const initialIntervalType = matchingIntervalRangeForSelectedIntervals?.intervalType ??
    //otherwise try to use monthly if it is an option
    availableIntervals.find(interval => interval.intervalType === IntervalType.Monthly)?.intervalType ??
    //otherwise use the first available option
    availableIntervals.at(0)?.intervalType ??
    //last resort none
    IntervalType.None;

  const availableYears = [clientFileYear, clientFileYear + 1];
  const availableYearsSortedDesc = [...availableYears].sort((a, b) => b - a);

  //default to the most year that corresponds to the first selected interval
  const initialYear = matchingIntervalRangeForSelectedIntervals !== undefined && firstSelectedInverval !== undefined ?
    firstSelectedInverval.year - matchingIntervalRangeForSelectedIntervals.intervalDeltaYearStart :
    availableYears.find(year => year === Math.max(...initialSelectedIntervals.map(interval => interval.year))) ??
    //otherwise to the client file year if it's not available -- shouldn't really happen
    clientFileYear;

  const [selectedIntervalType, setSelectedIntervalType] = useState<IntervalType>(initialIntervalType);
  const typeFilteredAvailableIntervals = availableIntervals.filter(interval => interval.intervalType === selectedIntervalType);

  return (
    <Stack alignItems="stretch" justifyContent="start">
      <Stack direction="row" justifyContent="center">
        <IntervalTabs
          selectedIntervalType={selectedIntervalType}
          setSelectedIntervalType={setSelectedIntervalType}
          scenarioPieceType={scenarioPieceType} />
      </Stack>
      <Accordion disableGutters elevation={1} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          Intervals
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={2}>
            <IntervalYearInput initialYear={initialYear} availableYears={availableYearsSortedDesc} />
            <YearColumns
              availableIntervals={typeFilteredAvailableIntervals}
              initialSelectedIntervals={initialSelectedIntervals}
              initialSelectedYear={initialYear} />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Stack >
  );
};

export default IntervalInput;
