import { Backdrop, Button, CircularProgress, Grid } from '@mui/material';
import { ReactNode } from 'react';
import { ApplicationDecisionStep } from './appDecisionsPage/applicationDecisionPage';
import { AppQuestionsStep } from './appQuestionsStep';

type ApplicationWizardPageProps = {
  children: ReactNode;
  tabIndex: number;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
  formId?: string;
  disabled?: boolean;
  hideNavigation?: boolean;
  loading?: boolean;
}

const ApplicationWizardPage = ({ children, tabIndex, onPreviousClick, onNextClick, formId, disabled, hideNavigation = false, loading = false }: ApplicationWizardPageProps) => {
  return (
    <Grid container direction="column" p={1} sx={{ width: '100%', height: '100%', flexWrap: 'nowrap' }}>
      {loading && (
        <Grid container sx={{ position: 'absolute', bottom: 0, top: 0, left: 0, right: 0, margin: 'auto', justifyContent: 'center', alignItems: 'center' }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress size={50} />
          </Backdrop>
        </Grid>
      )}
      <Grid item xs="auto" sx={{ width: '100%', height: 'calc(100% - 45px)', overflow: 'auto' }}>
        {children}
      </Grid>
      {!hideNavigation && (
        <Grid container item sx={{ height: '45px', mt: 1, justifyContent: 'center' }}>
          <Button onClick={onPreviousClick} disabled={tabIndex === AppQuestionsStep.index || (disabled ?? false) || loading} id="btn-wizard-previous" variant="outlined">Previous</Button>
          <Button type="submit" form={formId} onClick={onNextClick} disabled={(disabled ?? false) || loading} id="btn-wizard-save" variant="contained">{tabIndex === ApplicationDecisionStep.index ? 'Apply Changes to D365' : 'Continue'}</Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ApplicationWizardPage;