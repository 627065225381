import { Quote } from '../../../../types/api/Quote';
import { ScenarioFormFields } from '../../../../pages/scenario/components/scenarioForm.component';
import { useFormValues } from '../../../../hooks/useFormValues';
import { useGetScenarioOfferPiecePlanCode, useGetYieldRoundingPrecision } from '../../../../hooks/roundingPrecision/useGetYieldRoundingPrecision';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioId } from '../../../../types/api/PrimaryKeys';

/** Should be called only inside the scenario form, as this uses the scenario form context to do its work. */
export const useGetYieldRoundingPrecisionInScenarioForm = ({ quote, scenarioId }: { quote: Quote, scenarioId: Nullable<ScenarioId> }) => {
  const { watchFormValue } = useFormValues<ScenarioFormFields>();
  const insurancePlanCode = useGetScenarioOfferPiecePlanCode(scenarioId);

  const { practiceId, typeId } = watchFormValue;

  return useGetYieldRoundingPrecision({
    quote,
    typeId: typeId ?? null,
    practiceId: practiceId ?? null,
    insurancePlanCode,
  });
};