import { Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import HailScenarioPieceEndorsement from '../../../types/api/hail/HailScenarioPieceEndorsement';
import HailEndorsement from '../../../types/api/hail/hailEndorsement';
import { useState } from 'react';
import HailEndorsementsModal from '../hailEndorsements/hailEndorsementsModal';
import { HailScenarioPieceId, UnitYearId } from '../../../types/api/PrimaryKeys';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import HailScenarioPieceRate from '../../../types/api/hail/HailScenarioPieceRate';

type EndorsementsProps = {
  hailScenarioPiece: HailScenarioPiece;
  availableHailEndorsements: HailEndorsement[];
  hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[];
  hailProduct: HailProduct;
  hailScenarioPieceRates: HailScenarioPieceRate[];
  hailScenarioPieceUnits: UnitYearId[];
  onHailScenarioPieceEndorsementsUpdated: (hailScenarioPieceId: HailScenarioPieceId, updatedHailScenarioPieceEndorsements: HailScenarioPieceEndorsement[]) => void;
  disableButtonsAndFields: boolean;
}

const EndorsementsCard = ({ hailScenarioPiece, availableHailEndorsements, hailScenarioPieceEndorsements, hailProduct, hailScenarioPieceRates, hailScenarioPieceUnits, onHailScenarioPieceEndorsementsUpdated, disableButtonsAndFields }: EndorsementsProps) => {

  const [endorsementsModalOpen, setEndorsementsModalOpen] = useState(false);

  const handleEndorsementsClickOpen = () => {
    setEndorsementsModalOpen(true);
  };

  const handleEndorsementsClose = () => {
    setEndorsementsModalOpen(false);
  };

  return (
    <Card sx={{ width: 325 }} raised>
      <CardContent sx={{ p: 1, pb: '8px !important', height: '45px' }}>
        <Grid container alignItems="center" columnGap={1}>
          <Grid item>
            <Typography variant="subtitle2">Endorsements</Typography>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <Typography variant="body2">Certain endorsements may not be stacked together. Review hail manuals for exact policy language.</Typography>
              }>
              <WarningIcon color="warning" />
            </Tooltip>
          </Grid>
          {!disableButtonsAndFields &&
            <Grid item xs textAlign="right">
              <IconButton onClick={handleEndorsementsClickOpen} tabIndex={-1} size="large" color="primary" sx={{ padding: '0 !important' }}>
                <OpenInBrowserIcon />
              </IconButton>
            </Grid>
          }
          <HailEndorsementsModal
            hailScenarioPiece={hailScenarioPiece}
            availableHailEndorsements={availableHailEndorsements}
            hailScenarioPieceEndorsements={hailScenarioPieceEndorsements}
            hailProduct={hailProduct}
            hailScenarioPieceRates={hailScenarioPieceRates}
            hailScenarioPieceUnits={hailScenarioPieceUnits}
            onCloseClicked={handleEndorsementsClose}
            onHailScenarioPieceEndorsementsUpdated={onHailScenarioPieceEndorsementsUpdated}
            showModal={endorsementsModalOpen} />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EndorsementsCard;