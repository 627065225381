import {
  ClientFileId,
  InsuredId,
  QuoteId,
  ScenarioId,
  ScenarioOptionId
} from '../../types/api/PrimaryKeys';
import UnitYear from '../../types/api/UnitYear';
import { Quote } from '../../types/api/Quote';
import { ClientFile } from '../../types/api/ClientFile';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import ScenarioOption from '../../types/api/options/ScenarioOption';
import ScenarioOptionUnitYear from '../../types/api/options/ScenarioOptionUnitYear';
import { getItemsForId } from '../mapHelpers';
import { isNotNullOrUndefined } from '../nullHandling';
import ReconciliationScenarioOptionUnitYear from '../../types/api/ReconciliationScenarioOptionUnitYear';

export interface ScenarioOptionReconciliationParams {
  units: Map<InsuredId, UnitYear[]>
  quotes: Map<ClientFileId, Quote[]>;
  clientFilesMap: Map<InsuredId, ClientFile[]>;
  rowCropScenarios: Map<QuoteId, RowCropScenario[]>;
  rowCropScenarioPieces: Map<ScenarioId, RowCropScenarioPiece[]>;
  scenarioOptions: Map<ScenarioId, ScenarioOption[]>;
  scenarioOptionUnitYears: Map<ScenarioOptionId, ScenarioOptionUnitYear[]>;
  scenarioOptionsFromServer: Map<ScenarioId, ScenarioOption[]>;
}

/**
 * Reconciles scenario options and scenario option unit years given a set of data representing the user's selected source of truth
 * @param clientFilesMap - The client files selected by the user, grouped by insured id
 * @param quotes - The quotes selected by the user, grouped by client file id
 * @param rowCropScenarios - The row crop scenarios selected by the user, grouped by quote id
 * @param scenarioOptions - The scenario options on the scenarios selected by the user, grouped by scenario id
 * @param scenarioOptionUnitYears - The relationships between scenario options and unit years from the scenarios selected by the user, grouped by scenario option id
 * @param units - The unit years selected by the user, grouped by insured id
 * @param scenarioOptionsFromServer - The last known state of scenario options according to the server, grouped by scenario id
 * @return reconciledScenarioOptions The scenario options that should be removed, along with the scenario option unit years that are the new source of truth, grouped by scenario id
 */
export const getReconciledScenarioOptions = ({
  clientFilesMap,
  quotes,
  rowCropScenarios,
  scenarioOptions,
  scenarioOptionUnitYears,
  units,
  scenarioOptionsFromServer,
}: ScenarioOptionReconciliationParams): { scenarioId: ScenarioId, scenarioOptionUnitYearsToKeep: ReconciliationScenarioOptionUnitYear[], scenarioOptionsToRemove: ScenarioOption[] }[] => {
  const reconciledScenarioOptions: { scenarioId: ScenarioId, scenarioOptionUnitYearsToKeep: ReconciliationScenarioOptionUnitYear[], scenarioOptionsToRemove: ScenarioOption[] }[] = [];

  clientFilesMap.forEach(clientFiles => {
    clientFiles.forEach(clientFile => {
      const applicableQuotes = getItemsForId(quotes, clientFile.clientFileId);

      applicableQuotes.forEach(quote => {
        const applicableScenarios = getItemsForId(rowCropScenarios, quote.quoteId);

        applicableScenarios.forEach(scenario => {
          const unitsForScenario = getItemsForId(units, clientFile.insuredId).filter(u =>
            u.year === clientFile.year &&
            u.countyId === quote.countyId &&
            u.commodityCode === quote.commodityCode &&
            u.insuredId === clientFile.insuredId &&
            u.typeId === scenario.typeId &&
            u.practiceId === scenario.practiceId);

          const scenarioOptionsForScenario = getItemsForId(scenarioOptions, scenario.scenarioId);
          const scenarioOptionUnitYearsForScenarioOption = scenarioOptionsForScenario.flatMap(opt => scenarioOptionUnitYears.get(opt.scenarioOptionId)).filter(isNotNullOrUndefined);
          const unitYearIds = unitsForScenario.map(u => u.unitYearId);
          const filteredScenarioOptionUnitYears = scenarioOptionUnitYearsForScenarioOption.filter(ouy => unitYearIds.includes(ouy.unitYearId));

          const flatScenarioOptionUnitYears: ReconciliationScenarioOptionUnitYear[] = [];

          filteredScenarioOptionUnitYears.forEach(souy => {
            const matchingOption = scenarioOptionsForScenario.find(so => so.scenarioOptionId === souy.scenarioOptionId);
            const matchingUnitYear = unitsForScenario.find(u => u.unitYearId === souy.unitYearId);

            if (matchingOption === undefined || matchingUnitYear === undefined) return;

            const flatScenarioOptionUnitYear: ReconciliationScenarioOptionUnitYear = {
              scenarioOptionCode: matchingOption.option,
              scenarioOptionId: souy.scenarioOptionId,
              scenarioOptionUnitYearId: souy.scenarioOptionUnitYearId,
              unitYearId: souy.unitYearId,
              scenarioId: scenario.scenarioId,
            };

            flatScenarioOptionUnitYears.push(flatScenarioOptionUnitYear);
          });

          const scenarioOptionIds = scenarioOptionsForScenario.map(so => so.scenarioOptionId);
          const serverOptions = getItemsForId(scenarioOptionsFromServer, scenario.scenarioId);

          const optionsToRemove = serverOptions.filter(so => !scenarioOptionIds.includes(so.scenarioOptionId));

          const obj = {
            scenarioId: scenario.scenarioId,
            scenarioOptionUnitYearsToKeep: flatScenarioOptionUnitYears,
            scenarioOptionsToRemove: optionsToRemove,
          };

          reconciledScenarioOptions.push(obj);
        });
      });
    });
  });

  return reconciledScenarioOptions;
};