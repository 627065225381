import ScenarioPieceClassification from '@silveus/calculations/dist/lookups/scenarioPieceClassification/ScenarioPieceClassification';
import { ScenarioPieceResponseDTO } from '@silveus/calculations';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { ReportProductData } from '../../../types/api/reports/ReportProductData';
import { ScenarioPieceId, UnitYearId } from '../../../types/api/PrimaryKeys';
import ScenarioQuickUnit from '../../../types/api/ScenarioQuickUnit';
import { getFriendlyScenarioPieceName } from '../../../utils/scenarioPieceUtils';

export const getReportProductDataForQuickQuote = (
  scenarioPiecesByUnitMap: Map<UnitYearId, ReportProductData[]>,
  quickUnit: ScenarioQuickUnit,
  scenarioPiece: RowCropScenarioPiece,
  calculations: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
): { unitYearId: UnitYearId, reportProductData: ReportProductData[] }[] => {
  //Using a manual get with a fallback array instead of getItemsForId here since we need to be able to push items to the array.
  //The array returned by getItemsForId is immutable, so won't work in this case
  const existingScenarioPieces = scenarioPiecesByUnitMap.get(quickUnit.scenarioQuickUnitId as unknown as UnitYearId) ?? [];
  const scenarioPieceCalculation = calculations.get(scenarioPiece.scenarioPieceId);

  const reportProductData: ReportProductData = {
    isCountyProduct: scenarioPieceCalculation?.scenarioPieceClassification === ScenarioPieceClassification.County,
    productName: getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType),
    topIntervalPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.topPrice ?? 0,
    triggerYield: scenarioPieceCalculation?.triggerYield ?? 0,
    unitStructure: scenarioPiece.unitStructure,
    upperCoverageLevel: scenarioPiece.upperCoverageLevel,
    scenarioId: scenarioPiece.scenarioId,
  };
  existingScenarioPieces.push(reportProductData);

  return [
    {
      unitYearId: quickUnit.scenarioQuickUnitId as unknown as UnitYearId,
      reportProductData: existingScenarioPieces,
    },
  ];
};