import { quoterDb } from '../../db';
import PremiumBreakdown from '../../types/api/premiumBreakdown';
import {
  createPremiumBreakdown,
  getPremiumBreakdownsByScenarioIds,
  updatePremiumBreakdown
} from '../premiumBreakdowns.service';
import { PremiumBreakdownId, ScenarioId } from '../../types/api/PrimaryKeys';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import { addSingle, getDefaultCachingStrategy, update } from '../offlineDataAccess.service';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';

const table = quoterDb.premiumBreakdowns;

export const getPremiumBreakdownsByScenarioIdsRequest = async (scenarioIds: ScenarioId[]): Promise<PremiumBreakdown[]> => {
  const request = () => getPremiumBreakdownsByScenarioIds(scenarioIds);

  const transactionTables = [quoterDb.premiumBreakdowns];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const premiumBreakdowns = safeWhere(quoterDb.premiumBreakdowns, 'primaryScenarioId').anyOf(scenarioIds).toArray();
    return premiumBreakdowns;
  });

  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const createPremiumBreakdownRequest = async (premiumBreakdown: PremiumBreakdown): Promise<CreatedItemResult<PremiumBreakdownId>> => {
  const request = () => createPremiumBreakdown(premiumBreakdown);
  return addSingle(table, premiumBreakdown, request);
};

export const updatePremiumBreakdownRequest = async (premiumBreakdown: PremiumBreakdown): Promise<void> => {
  const request = () => updatePremiumBreakdown(premiumBreakdown);
  return update(table, premiumBreakdown, request);
};
