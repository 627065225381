import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../../types/util/Nullable';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

interface Props extends InputProps {
  isInputCostEndorsement: Nullable<boolean>;
}
export interface IsInputCostEndorsementFields {
  isInputCostEndorsement: boolean;
}
export const VipMpIsInputCostEndorsement = ({ isInputCostEndorsement, disabled }: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.IsInputCostEndorsement}
      control={control}
      defaultValue={isInputCostEndorsement}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl
          error={!!error}
          disabled={disabled}>
          <FormControlLabel
            label="Input Cost End."
            control={
              <Checkbox
                {...field}
                checked={value}
                inputRef={ref}
              />
            } />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl >
      )}
    />
  );

};
