import { AdjustmentType, roundToPlaces } from '@silveus/calculations';
import { HarvestRevenueScenarioPieceFormFields } from '../harvestRevenueScenarioPieceForm.component';
import { formatCurrency, formatNumberDigits, formatPercentDigits } from '../../../../utils/formatNumbers';
import { SimulatedAdjustmentRevenues, SimulatedResults } from '@silveus/calculations/dist/shared/results/simulatedResults';
import { Nullable } from '../../../../types/util/Nullable';

export const getProductionAdjustmentType = (index: number, getValues: () => HarvestRevenueScenarioPieceFormFields): AdjustmentType => {
  const listOfAdjustments = getValues().harvestRevenueTransactions.map(x => x.productionAdjustmentTypeId);
  return listOfAdjustments[index];
};

export const getPriceAdjustmentType = (index: number, getValues: () => HarvestRevenueScenarioPieceFormFields): AdjustmentType => {
  const listOfAdjustments = getValues().harvestRevenueTransactions.map(x => x.priceAdjustmentTypeId);
  return listOfAdjustments[index];
};

export const parseHarvestRevenuePriceNumber = (adjType: AdjustmentType, value: string): number => {
  //Remove anything except 0-9 and a period for a decimal point
  const cleanedString = value.toString().replace(/[^0-9.-]/g, '');
  const parsed = parseFloat(cleanedString === '' ? '0' : cleanedString);

  return roundHarvestRevenuePriceNumber(adjType, parsed);
};

export const roundHarvestRevenuePriceNumber = (adjType: AdjustmentType, value: number): number => {
  switch (adjType) {
    case AdjustmentType.Fixed:
    case AdjustmentType.PlusMinus:
      return roundToPlaces(value, 2);
    case AdjustmentType.Percent:
      return roundToPlaces(value, 0);
    default:
      throw new Error('invalid adjustment type');
  }
};

const getSafeTransaction = (calcResults: Nullable<SimulatedResults>, index: number): SimulatedAdjustmentRevenues | undefined => {
  let result = undefined;
  if (calcResults?.adjustmentRevenues) {
    if (calcResults.adjustmentRevenues.length > index) {
      result = calcResults.adjustmentRevenues[index];
    }
  }
  return result;
};

export const getSafeProduction = (calcResults: Nullable<SimulatedResults>, index: number): number | undefined => {
  return getSafeTransaction(calcResults, index)?.derivedProduction;
};

export const getSafePrice = (calcResults: Nullable<SimulatedResults>, index: number): number | undefined => {
  return getSafeTransaction(calcResults, index)?.derivedPrice;
};

export const getSafeRevenue = (calcResults: Nullable<SimulatedResults>, index: number): number | undefined => {
  return getSafeTransaction(calcResults, index)?.calculatedRevenue;
};

export const formatProductionValue = (adjustmentType: AdjustmentType, value: number, productionCalculated: number) => {
  switch (adjustmentType) {
    case AdjustmentType.Remaining:
      return formatNumberDigits(productionCalculated);
    case AdjustmentType.Fixed:
    case AdjustmentType.PlusMinus:
      return formatNumberDigits(value);
    case AdjustmentType.Percent:
      return formatPercentDigits(value / 100);
    default:
      throw new Error('invalid adjustment');
  }
};

export const formatPriceValue = (adjustmentType: AdjustmentType, value: number) => {
  switch (adjustmentType) {
    case AdjustmentType.Fixed:
    case AdjustmentType.PlusMinus:
      return formatCurrency(value, true);
    case AdjustmentType.Percent:
      return formatPercentDigits(value / 100);
    default:
      throw new Error('invalid adjustment');
  }
};

