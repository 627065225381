import { quoterDb } from '../../db';
import { Insured } from '../../types/api/insureds/Insured';
import {
  getAgentTeams,
  getEntityTypes,
  getInsured,
  getInsuredContacts, getInsuredCorporationStates, getInsuredEntityTypes,
  getInsuredNotes,
  getInsuredPolicies,
  getInsureds,
  getInsuredSMS,
  getTaxTypes
} from '../insureds.service';
import { getAll, getSingle } from '../offlineDataAccess.service';
import { InsuredId } from '../../types/api/PrimaryKeys';
import { cacheOnly, networkOnly, staleWhileRevalidate } from '../../utils/cachingStrategies';
import { InsuredNote } from '../../types/api/insureds/InsuredNote';
import { InsuredSMS } from '../../types/api/insureds/InsuredSMS';
import { InsuredContact } from '../../types/api/insureds/InsuredContact';
import { InsuredPolicy } from '../../types/api/insureds/InsuredPolicy';
import { AutoCompleteOption } from '../../types/api/insureds/AutoCompleteOption';
import { getCountiesForInsureds } from '../insureds.service';
import { distinctBy } from '../../utils/arrayUtils';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { TaxType } from '../../types/api/insureds/TaxType';
import { EntityType } from '../../types/api/insureds/EntityType';
import { isNotNullOrUndefined } from '../../utils/nullHandling';
import { AgentTeam } from '../../types/api/insureds/agentTeam';

const table = quoterDb.insureds;

export const getInsuredsRequest = async (): Promise<Insured[]> => {
  const request = () => getInsureds();
  return await getAll(table, request, undefined, staleWhileRevalidate);
};

export const getInsuredRequest = async (insuredId: InsuredId): Promise<Insured> => {
  const request = () => getInsured(insuredId);
  return await getSingle(table, { id: insuredId }, request);
};

export const getInsuredNotesRequest = async (insuredId: InsuredId): Promise<InsuredNote[]> => {
  const request = () => getInsuredNotes(insuredId);
  return networkOnly(request);
};

export const getInsuredSmsRequest = async (insuredId: InsuredId): Promise<InsuredSMS[]> => {
  const request = () => getInsuredSMS(insuredId);
  return networkOnly(request);
};

export const getInsuredContactsRequest = async (insuredId: InsuredId): Promise<InsuredContact[]> => {
  const request = () => getInsuredContacts(insuredId);
  return networkOnly(request);
};

export const getAgentTeamsRequest = async (): Promise<AgentTeam[]> => {
  const request = () => getAgentTeams();
  return networkOnly(request);
};

export const getInsuredPoliciesRequest = async (insuredId: InsuredId): Promise<InsuredPolicy[]> => {
  const request = () => getInsuredPolicies(insuredId);
  return networkOnly(request);
};

export const getInsuredEntityTypesRequest = async (): Promise<AutoCompleteOption[]> => {
  const request = () => getInsuredEntityTypes();
  return networkOnly(request);
};

export const getEntityTypesRequest = async (): Promise<EntityType[]> => {
  const request = () => getEntityTypes();
  return networkOnly(request);
};

export const getTaxTypesRequest = async (): Promise<TaxType[]> => {
  const request = () => getTaxTypes();
  return networkOnly(request);
};

export const getInsuredCorporationStatesRequest = async (): Promise<AutoCompleteOption[]> => {
  const request = () => getInsuredCorporationStates();

  return networkOnly(request);
};

export const getCountiesForInsuredsRequest = async (insuredIds: InsuredId[]): Promise<string[]> => {
  const request = () => getCountiesForInsureds(insuredIds);
  return networkOnly(request);
};

export const getPreDownloadedInsuredsRequest = async (): Promise<InsuredId[]> => {
  const transactionTables = [quoterDb.insureds, quoterDb.clientFiles];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const clientFiles = await quoterDb.clientFiles.toArray();

    const insuredIds = distinctBy(clientFiles.map(cf => cf.insuredId).filter((id): id is InsuredId => isNotNullOrUndefined(id)), x => x);

    const insureds = await safeWhere(quoterDb.insureds, 'id').anyOf(insuredIds).toArray();
    return insureds.map(i => i.id);
  });

  return cacheOnly(undefined, readTransaction);
};
