import { ScenarioRequestDTO, ScenarioPieceTypeAttributes, ScenarioPieceType } from '@silveus/calculations';
import { useMemo } from 'react';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../app/scenarioPiecesSlice';
import { selectScenarioRequests } from '../app/calculationResultsSlice';
import { IEnumAttributes } from '../types/api/enums/IEnumAttributes';
import { QuoteId, ScenarioId } from '../types/api/PrimaryKeys';
import { isNullOrUndefined } from '../utils/nullHandling';
import { useAppSelector } from './reduxHooks';
import { RowCropScenario } from '../types/api/RowCropScenario';
import { selectAllScenarios, selectEligibleScenariosForApplication } from '../app/scenariosSlice';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import { selectAllInputCostScenarioPiecesByScenarioMap } from '../app/inputCostScenarioPiecesSlice';
import { selectAllForwardSoldScenarioPiecesByScenarioMap } from '../app/forwardSoldScenarioPiecesSlice';
import { selectAllHarvestRevenueScenarioPiecesByScenarioMap } from '../app/harvestRevenueScenarioPiecesSlice';
import { ScenarioPiece } from '../types/api/ScenarioPiece';
import { getKeyedStateGroupedBy } from '../app/sliceHelpers';

export const useUniqueScenarioPieceTypesForScenarios = (scenarioIds: ScenarioId[]) => {
  const allRowCropScenarioPiecesByScenario = useAppSelector(selectAllRowCropScenarioPiecesByScenarioMap);
  const allInputCostScenarioPiecesByScenario = useAppSelector(selectAllInputCostScenarioPiecesByScenarioMap);
  const allForwardSoldScenarioPiecesByScenario = useAppSelector(selectAllForwardSoldScenarioPiecesByScenarioMap);
  const allHarvestRevenueScenarioPiecesByScenario = useAppSelector(selectAllHarvestRevenueScenarioPiecesByScenarioMap);

  const result = useMemo(() => {
    const allUniqueScenarioPieceTypes = new Set<IEnumAttributes<ScenarioPieceType>>();
    scenarioIds.forEach(scenarioId => {
      const rowCropPiecesForScenario: ScenarioPiece[] = allRowCropScenarioPiecesByScenario.get(scenarioId) ?? [];
      const inputCostPiecesForScenario: ScenarioPiece[] = allInputCostScenarioPiecesByScenario.get(scenarioId) ?? [];
      const forwardSoldPiecesForScenario: ScenarioPiece[] = allForwardSoldScenarioPiecesByScenario.get(scenarioId) ?? [];
      const harvestRevenuePiecesForScenario: ScenarioPiece[] = allHarvestRevenueScenarioPiecesByScenario.get(scenarioId) ?? [];
      const allPiecesForScenario = [...rowCropPiecesForScenario, ...inputCostPiecesForScenario, ...forwardSoldPiecesForScenario, ...harvestRevenuePiecesForScenario];

      allPiecesForScenario.forEach(piece => {
        const pieceTypeEnum = ScenarioPieceTypeAttributes[piece.scenarioPieceType];
        allUniqueScenarioPieceTypes.add(pieceTypeEnum);
      });
    });

    return allUniqueScenarioPieceTypes;

  }, [allRowCropScenarioPiecesByScenario, allInputCostScenarioPiecesByScenario, allForwardSoldScenarioPiecesByScenario, allHarvestRevenueScenarioPiecesByScenario, scenarioIds]);

  return result;
};

export const useGetSelectedScenarioRequestsForScenarios = (scenarios: RowCropScenario[]) => {
  const allScenarioRequests = useAppSelector(selectScenarioRequests);
  // This will update any time any time allScenarioRequests is changed. Ideally we could have a selector
  // that takes a list of scenario ids and only updates when that list is changed, but that is not possible yet.
  const result = useMemo(() => {
    const requests: ScenarioRequestDTO[] = [];
    scenarios.forEach(scenario => {
      const s = allScenarioRequests[scenario.scenarioId];
      if (!isNullOrUndefined(s)) {
        requests.push(s);
      }
    });

    return requests;
  }, [allScenarioRequests, scenarios]);

  return result;
};

export const useGetScenariosFromScenarioIds = (scenarioIds: ScenarioId[]) => {
  const allScenarios = useAppSelector(selectAllScenarios);
  const result = useMemo(() => {
    const scenarios: RowCropScenario[] = [];
    scenarioIds.forEach(id => {
      const s = allScenarios[id];
      if (!isNullOrUndefined(s)) {
        scenarios.push(s);
      }
    });

    return scenarios.length === 0 ? stableEmptyArrayAsMutable<RowCropScenario>() : scenarios;
  }, [allScenarios, scenarioIds]);

  return result;
};

export const useGetScenariosFromQuoteIds = (quoteIds: QuoteId[]) => {
  const allScenarios = useAppSelector(selectEligibleScenariosForApplication);
  const result = useMemo(() => {
    const scenarios = new Map<QuoteId, RowCropScenario[]>();
    quoteIds.forEach(id => {
      const s = getKeyedStateGroupedBy(allScenarios, s => s.quoteId);
      const quoteScenarios = s.get(id);
      if (!isNullOrUndefined(quoteScenarios)) {
        scenarios.set(id, quoteScenarios);
      }
    });

    return scenarios;
  }, [allScenarios, quoteIds]);

  return result;
};