import { Button, Grid } from '@mui/material';
import { AphModalProps, selectTrendAdjustmentFactorForScenario } from '../../app/unitsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import LabelValuePair from '../../components/labelValuePair/labelValuePair.component';
import { selectScenarioById } from '../../app/scenariosSlice';
import { selectQuoteById } from '../../app/quotesSlice';
import { selectClientFileById } from '../../app/clientFilesSlice';
import { calculateCustomTYield, calculateRateYield, YIELD_DESCRIPTORS } from '@silveus/calculations';
import { fetchTYields, selectTYield } from '../../app/admSlice';
import { selectApplicableTYieldByUnitYearId } from '../../app/unitYearAphSlice';
import { useEffect } from 'react';
import { addYear } from '../units/utils/aph';
import { CombinedAphScenarioAph } from './unitAphModal';
import { generatePrimaryKey } from '../../utils/primaryKeyHelpers';
import { YeStatusType } from '../../types/api/enums/optionStates/yeStatusType.enum';
import { Na } from '../../types/util/Na';
import { formatNumber } from '../../utils/formatNumbers';
import { useScenarioYieldCalculator } from './useScenarioYieldCalculator';
import { useOptionCodesWithAvailability } from '../../hooks/useOptionCodesWithAvailability';
import { OptionSelectionState } from '../../types/app/enums/optionSelectionState.enum';
import { OptionAvailabilityWarning } from '../../constants/optionAvailability';

interface UnitAphYieldSummaryProps {
  aph: CombinedAphScenarioAph[];
  setAph: (value: CombinedAphScenarioAph[]) => void;
  isInEditMode: boolean;
  modalProps: AphModalProps;
}

export const UnitSummary = ({ aph, setAph, isInEditMode, modalProps }: UnitAphYieldSummaryProps) => {
  const dispatch = useAppDispatch();
  const getFormattedOptionCodesWithAvailability = useOptionCodesWithAvailability();

  const { unitYear, scenarioId, unitYearOptions: options } = modalProps;
  const unitYearId = unitYear.unitYearId;

  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  const quote = useAppSelector(state => scenario === null ? null : selectQuoteById(state, scenario.quoteId));
  const clientFile = useAppSelector(state => quote === null ? null : selectClientFileById(state, quote.clientFileId));
  const taFactor = useAppSelector(state => selectTrendAdjustmentFactorForScenario(state, scenarioId, quote?.countyId ?? '', quote?.commodityCode ?? ''));
  const admTYield = useAppSelector(state => selectTYield(state, unitYear.typeId, unitYear.practiceId, unitYear.subCountyCode));
  const applicableTYield = useAppSelector(state => selectApplicableTYieldByUnitYearId(state, unitYearId));

  const policyYear = clientFile?.year ?? null;

  const yieldCalculator = useScenarioYieldCalculator(scenarioId);
  const applicableOptions = options.filter(o => o.isAvailable && o.selectionState === OptionSelectionState.On)
    .map(o => o.option.optionCode);
  const singleUnitCalculator = yieldCalculator.forSingleUnitYear(unitYear, aph, aph, applicableOptions);
  const adjustedYield = singleUnitCalculator.calculateAdjustedYield();
  const approvedYield = singleUnitCalculator.calculateApprovedYield();
  const customTYield = calculateCustomTYield(aph);
  const rateYield = calculateRateYield(aph);

  useEffect(() => {
    if (policyYear === null) return;
    dispatch(fetchTYields({ year: policyYear, countyId: unitYear.countyId, commodityCode: unitYear.commodityCode }));
  }, [unitYear]);

  const addRow = () => {
    if (policyYear === null) return;

    const defaultAcres = unitYear.acres;

    const { newAph } = addYear(aph, unitYearId, defaultAcres, policyYear, unitYear.commodityCode, YIELD_DESCRIPTORS.A, applicableTYield);
    //after getting the new UnitYearAph results we need to combine them with the ScenarioUnitYearProperties
    const combinedScenarioAph: CombinedAphScenarioAph[] = newAph.map(row => {
      const scenarioUnitYearAphRow = aph.find(aphRow => row.unitYearAphId === aphRow.unitYearAphId);
      return ({
        ...row,
        scenarioUnitYearAphId: scenarioUnitYearAphRow?.scenarioUnitYearAphId ?? generatePrimaryKey(),
        scenarioId: scenarioId,
        yeStatus: scenarioUnitYearAphRow?.yeStatus ?? YeStatusType.NotOptedOut,
        production: row.production ?? null,
      });
    });
    setAph(combinedScenarioAph);
  };
  const disableOptionsTooltip = options.filter(o => o.selectionState !== OptionSelectionState.Off && !o.isAvailable).length === 0;
  const optionsSummary = getFormattedOptionCodesWithAvailability(options.filter(o => o.selectionState !== OptionSelectionState.Off));

  return (
    <Grid container>
      <Grid item container>
        <Grid item container xs>
          <Grid item xs={12}>
            <LabelValuePair spacing={5} labelColumnSize={false} valueColumnSize={false} inverseBold label="Farm Name" value={unitYear.farmName} />
          </Grid>
          <Grid item xs={12}>
            <LabelValuePair spacing={5} labelColumnSize={false} valueColumnSize={false} inverseBold label="Farm Number" value={unitYear.farmNumber} />
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Button id="btn-add-year" variant="contained" onClick={addRow} style={{ width: '100%' }} disabled={isInEditMode}>Add Year</Button>
        </Grid>
      </Grid>
      <Grid item container columns={15}>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="Section" value={unitYear.section} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="Location" value={unitYear.location} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="Custom T-Yield:" value={customTYield ?? Na} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="Adj. Yield:" value={adjustedYield !== null ? formatNumber(adjustedYield, true) : Na} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="Township" value={unitYear.township} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="Intended Acres" value={unitYear.acres} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="Options" value={optionsSummary} disableToolTip={disableOptionsTooltip} altTooltipText={OptionAvailabilityWarning} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="TA Factor:" value={taFactor.toFixed(2)} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="Appr. Yield:" value={approvedYield !== null ? formatNumber(approvedYield, true) : Na} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="Range" value={unitYear.range} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="Share %" value={unitYear.sharePercent} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="T-Yield:" value={admTYield?.transitionalYield.toFixed(0)} />
        </Grid>
        <Grid item xs={3}>
          <LabelValuePair inverseBold label="Rate Yield:" value={rateYield !== null ? formatNumber(rateYield, true) : Na} />
        </Grid>
      </Grid>
    </Grid>
  );
};
