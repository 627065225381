import { HighRiskType, ScenarioPieceType, UnitStructureCode } from '@silveus/calculations';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import { Quote } from '../../../types/api/Quote';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useScenarioPieceForm } from '../useScenarioPieceForm';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useWatch } from 'react-hook-form';
import { ExtendedDataFormInputParameterNames } from '../../../components/formInputs/constants/inputConstants';
import { useFederalOfferData } from '../../../hooks/useFederalOfferData';
import { selectScenarioById, updateScenario } from '../../../app/scenariosSlice';
import { mergeScenarioWithYieldAndPriceUpdates } from '../../../utils/scenarioUpdateUtils';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import { createScenarioPiece, removeScenarioPieceAndRecalculate } from '../../../app/scenarioPiecesSlice';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
//import { Button, CircularProgress, Grid } from '@mui/material';
//Connor Julian 07/25/2024: Removing live quote UI temporarily, revert by deleting Grid import below, and removing double slash above.
import { Grid } from '@mui/material';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import { CustomPremiumFields, CustomPremiumInput } from '../../../components/formInputs/customPremiumInput.component';
import IntervalInput, { IntervalInputFields } from '../../../components/formInputs/scenario/intervalInput.component';
import { TopIntervalInput } from '../../../components/formInputs/scenarioPiece/topIntervalInput.component';
import { UpperCoverageLevelFields } from '../../../components/formInputs/scenarioPiece/upperCoverageLevelInput.component';
import { ProtectionFactorFields, ProtectionFactorInput } from '../../../components/formInputs/scenarioPiece/protectionFactorInput.component';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { MpScenarioPiecePricesAndYields, MpScenarioPiecePricesAndYieldsFields } from '../mp/mpScenarioPiecePricesAndYields.component';
import IsPrime from '../../../components/formInputs/scenarioPiece/mpowerd/isPrime.component';
import PrimeFloorInput, { PrimeFloorFields } from '../../../components/formInputs/scenarioPiece/mpowerd/primeFloor.component';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
//import IsLiveQuotingInput, { IsLiveQuotingFields } from '../../../components/formInputs/scenarioPiece/mpowerd/isLiveQuotingInput.component';
//Connor Julian 07/25/2024: Removing live quote UI temporarily, revert by deleting Grid import below, and removing double slash above.
import { IsLiveQuotingFields } from '../../../components/formInputs/scenarioPiece/mpowerd/isLiveQuotingInput.component';
import { MPowerDCapInput } from '../../../components/formInputs/scenarioPiece/mpowerd/mPowerDCapInput.component';
import { MPowerDFactorInput } from '../../../components/formInputs/scenarioPiece/mpowerd/mPowerDFactorInput.component';
import { ScenarioPieceSelectedInterval } from '../../../types/api/adm/ScenarioPieceSelectedInterval';
import { getAvailableIntervalsForScenarioPieceType, setSelectedIntervals } from '../../../app/intervalsSlice';
import { AipSelectorInput } from '../../../components/formInputs/scenarioPiece/mpowerd/aipSelectorInput.component';
import { useUnderlyingMpScenarioPiece } from '../../../hooks/useUnderlyingMpScenarioPiece';
import { MissingScenarioInStateError, MissingUnderlyingScenarioPieceInStateError } from '../../../errors/state/MissingStateErrors';
import CustomUnderlyingProductData from '../../../types/api/scenarioPieceExtendedData/customUnderlyingProductData';


export interface MPowerDScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  scenarioId: ScenarioId;
  year: number;
  commodityCode: string;
  countyId: string;
  typeId: string;
  practiceId: string;
  disabled: boolean;
  highRiskTypeId: HighRiskType;
  quote: Quote;
  handleCancel: () => void;
}

export type MPowerDScenarioPieceFormFields =
  ScenarioPieceFormFields & UpperCoverageLevelFields &
  ProtectionFactorFields & IsLiveQuotingFields &
  PrimeFloorFields & IntervalInputFields &
  MpScenarioPiecePricesAndYieldsFields & CustomPremiumFields &
  CustomUnderlyingProductData;


export const MPowerDScenarioPieceForm = ({ scenarioPiece, scenarioId, year, commodityCode, countyId, typeId, practiceId, disabled, highRiskTypeId, registerHeader, handleCancel, quote, handleValidation, isCanceling = false }: MPowerDScenarioPieceFormProps) => {
  const dispatch = useAppDispatch();
  const methods = useScenarioPieceForm<MPowerDScenarioPieceFormFields>(scenarioPiece);
  const formId = 'MPowerDScenarioPieceForm';
  const scenarioPieceType = ScenarioPieceType.MPowerD;
  //const [isLiveQuoteLoading, setIsLiveQuoteLoading] = useState(false);
  const [isSavingLiveQuote, setIsSavingLiveQuote] = useState(false);

  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  if (scenario === null) {
    throw new MissingScenarioInStateError(scenarioId);
  }

  const { underlyingMpScenarioPiece, updateUnderlyingMpScenarioPiece } = useUnderlyingMpScenarioPiece(scenario, scenarioPieceType);
  if (underlyingMpScenarioPiece === null) {
    throw new MissingUnderlyingScenarioPieceInStateError(scenarioId);
  }

  const planCode = getInsurancePlanCodeForScenarioPiece(underlyingMpScenarioPiece.scenarioPieceType);

  const isLiveQuote = useWatch({ name: ExtendedDataFormInputParameterNames.IsLiveQuote, control: methods.control, defaultValue: scenarioPiece?.rowCropScenarioPieceExtendedData?.isLiveQuote ?? false });
  const isPrime = useWatch({ name: ExtendedDataFormInputParameterNames.IsPrime, control: methods.control, defaultValue: scenarioPiece?.rowCropScenarioPieceExtendedData?.isPrime ?? false });

  // TODO: Product Backlog Item 66407: update with the logic to determine if the live quote is stale
  const isLiveQuoteStale = scenarioPiece?.rowCropScenarioPieceExtendedData?.isLiveQuoteStale ?? false;

  useFederalOfferData(scenarioPiece, scenarioId, underlyingMpScenarioPiece.scenarioPieceType, year, countyId, typeId, practiceId, highRiskTypeId);

  const availableIntervals = useAppSelector(state => getAvailableIntervalsForScenarioPieceType(state, scenarioPieceType));

  const onSubmit: SubmitHandler<MPowerDScenarioPieceFormFields> = async data => {
    const extendedData = data.rowCropScenarioPieceExtendedData !== null
      ? { ...data.rowCropScenarioPieceExtendedData, isLiveQuoteStale: isLiveQuoteStale && !isSavingLiveQuote }
      : null;

    const newScenarioPiece: RowCropScenarioPiece = {
      isActive: data.isActive,
      isInvalid: isLiveQuoteStale && !isSavingLiveQuote,
      lowerCoverageLevel: 0,
      planCode: planCode,
      protectionFactor: data.protectionFactor,
      rowCropScenarioPieceExtendedData: extendedData,
      rowCropScenarioPieceId: scenarioPiece?.rowCropScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: scenarioPieceType,
      unitStructure: UnitStructureCode.AU,
      upperCoverageLevel: underlyingMpScenarioPiece.upperCoverageLevel,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    await dispatch(updateScenario({
      updatedScenario: mergeScenarioWithYieldAndPriceUpdates(scenario, data),
    }));

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(createScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await updateUnderlyingMpScenarioPiece(data.rowCropScenarioPieceExtendedData);

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId, updatedScenarioPiece: newScenarioPiece }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));

    setIsSavingLiveQuote(false);

    const scenarioPieceSelectedIntervals: ScenarioPieceSelectedInterval[] = data.selectedIntervalIds.map(id => {
      const correspondingInterval = availableIntervals.find(interval => interval.id === id);
      if (correspondingInterval === undefined) throw new Error('Something was wrong with the selected interval chosen');
      return {
        scenarioPieceSelectedIntervalId: generatePrimaryKey(),
        scenarioPieceId: newScenarioPiece.scenarioPieceId,
        intervalRangeId: id,
        year: data.year + correspondingInterval.intervalDeltaYearStart,
      };
    });
    await dispatch(setSelectedIntervals({ scenarioPieceId: newScenarioPiece.scenarioPieceId, scenarioPieceSelectedIntervals }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;
  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <IntervalInput
                scenarioPieceType={scenarioPieceType}
                clientFileYear={year}
                scenarioPieceId={scenarioPiece?.scenarioPieceId ?? null} />
            </Grid>

            {/* <Grid item xs={6}>
              <IsLiveQuotingInput isLiveQuote={scenarioPiece?.rowCropScenarioPieceExtendedData?.isLiveQuote ?? false} disabled={disabled} />
            </Grid>
            <Grid item container xs={6} justifyContent="center">
              <Grid item>
                <Button
                  id="btn-refresh-live-quote"
                  color={isLiveQuoteStale ? 'secondary' : 'primary'}
                  size="small"
                  variant="contained"
                  onClick={() => { }}
                  disabled={!isLiveQuote || isLiveQuoteLoading}
                >
                  {isLiveQuoteLoading ? <CircularProgress color="inherit" size={20} /> : 'REFRESH LIVE QUOTE'}
                </Button>
              </Grid>
            </Grid> */}

            {isLiveQuote &&
              <>
                <Grid container item xs={6}>
                  <AipSelectorInput selectedAip={scenarioPiece?.rowCropScenarioPieceExtendedData?.aip ?? null} />
                </Grid>
                <Grid item xs={6} />
              </>
            }
            <Grid item xs={6}>
              <MPowerDFactorInput
                protectionFactor={scenarioPiece?.protectionFactor ?? null}
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={6}>
              <ProtectionFactorInput
                label="MP Protection Factor"
                fieldName={ExtendedDataFormInputParameterNames.UnderlyingProtectionFactor}
                upperProtectionFactor={120}
                lowerProtectionFactor={80}
                protectionFactor={scenarioPiece?.rowCropScenarioPieceExtendedData?.underlyingProtectionFactor ?? null}
                disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <CustomPremiumInput customPremium={scenarioPiece?.rowCropScenarioPieceExtendedData?.customPremium ?? null} disabled={isLiveQuote} label="Premium" />
            </Grid>
            <Grid item xs={6}>
              <MPowerDCapInput priceCap={scenarioPiece?.rowCropScenarioPieceExtendedData?.priceChangeCap ?? null} commodityCode={quote.commodityCode} disabled={disabled} />
            </Grid>

            <Grid item xs={6}>
              <IsPrime isPrime={isPrime} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <PrimeFloorInput primeDollarFloor={scenarioPiece?.rowCropScenarioPieceExtendedData?.primeDollarFloor ?? 0} disabled={!isPrime} />
            </Grid>

            <Grid item xs={6}>
              <TopIntervalInput topPrice={scenarioPiece?.rowCropScenarioPieceExtendedData?.topPrice ?? null} disabled={disabled} />
            </Grid>

            <Grid container item xs={12} spacing={2} sx={{ marginTop: '6px' }}>
              <MpScenarioPiecePricesAndYields
                quote={quote}
                scenario={scenario}
                scenarioPiece={underlyingMpScenarioPiece}
                scenarioPieceType={underlyingMpScenarioPiece.scenarioPieceType}
                year={year}
                isLiveQuote={underlyingMpScenarioPiece.rowCropScenarioPieceExtendedData?.isLiveQuote ?? false}
                areFieldsLocked={underlyingMpScenarioPiece.rowCropScenarioPieceExtendedData?.areFieldsLocked ?? false}
                commodityCode={commodityCode}
              />
            </Grid>

            <Grid item xs={12}>
              <IsActiveInput isActive={scenarioPiece?.isActive ?? null} />
            </Grid>

          </Grid>
        </form>
      </FormProvider>
      <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
    </>
  );
};
