import MESSAGES from '../../../constants/messages';
import { Nullable } from '../../../types/util/Nullable';

export function validateDollarAmount(dollarAmount: Nullable<number>, allowZero: boolean = true): true | string {
  if (dollarAmount === null) return MESSAGES.required;
  if (dollarAmount < 0) return MESSAGES.minimum(0);
  if (dollarAmount > 99999999.99) return MESSAGES.maximum(99999999.99);
  if (dollarAmount.toString().length > 11) return MESSAGES.maxLength(11);
  if (dollarAmount === 0 && !allowZero) return MESSAGES.greaterThan(0);

  return true;
}
