import { defaultNewColor } from './utils/defaultNewColor';
import { Grid, IconButton, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { MatrixColorValueCard } from './MatrixColorValueCard';
import { CellTextColorRule } from '@silveus/react-matrix';
import { UiCellTextColorRule } from './types';

interface Props {
  colorList: readonly UiCellTextColorRule[];
  selectedColorRule: UiCellTextColorRule | null;
  cssClasses: undefined | {
    labelClasses:  string | undefined,
    iconClasses:  string | undefined,
    colorValueCardCSSClasses:  string | undefined,
  };
  onAddColor: (newColorRule: CellTextColorRule) => void;
  onSetSelectedColorId: (id: number) => void;
  onUpdateColor: (id: number, newValue: CellTextColorRule) => void;
  onDeleteColor: (id: number) => void;
}

const minimumNumberOfColorsAllowed = 3;

export const MatrixCurrentPaletteSidebar = ({ colorList, selectedColorRule, cssClasses, onAddColor, onSetSelectedColorId, onUpdateColor, onDeleteColor }: Props) => {

  const onAddColorClicked = () => {
    onAddColor(defaultNewColor);
  };

  const updateThreshold = (colorRule: UiCellTextColorRule, newThreshold: number) => {
    const newColor: CellTextColorRule = {
      ...colorRule,
      threshold: newThreshold,
    };

    onUpdateColor(colorRule.id, newColor);
  };

  const getLabelClass = () => {
    if (cssClasses === undefined) {
      return 'side-bar-heading';
    }

    return 'side-bar-heading ' + cssClasses.labelClasses;
  };

  const shouldAllowDelete = colorList.length > minimumNumberOfColorsAllowed;
  return (
    <Grid container direction="column" justifyContent="flex-start" className="palette-sidebar" spacing={2}>
      <Grid item container alignItems="center">
        <Grid item xs>
          <Typography className={getLabelClass()} variant="body2">Current Palette</Typography>
        </Grid>
        <Grid item xs="auto">
          <IconButton className="add-color-button" aria-label="delete" onClick={onAddColorClicked} sx={{ p: 0 }} size="small">
            <AddCircleOutlineIcon className={cssClasses?.iconClasses} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center" className="vertical-list-wrapper">
        {colorList.map(colorRule => {
          return (
            <Grid item key={colorRule.id}>
              <MatrixColorValueCard
                key={colorRule.id}
                threshold={colorRule.threshold}
                color={colorRule.color}
                isSelected={colorRule.id === selectedColorRule?.id}
                isDeleteAllowed={shouldAllowDelete}
                cssClassNames={{
                  inputCSSClassNames: cssClasses?.colorValueCardCSSClasses,
                  iconCSSClassNames: cssClasses?.iconClasses,
                }}
                onCardSelected={() => onSetSelectedColorId(colorRule.id)}
                onUpdateThreshold={threshold => updateThreshold(colorRule, threshold)}
                onDeleteColor={() => onDeleteColor(colorRule.id)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};