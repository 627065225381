import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import {
  createScenarioPiece,
  removeScenarioPieceAndRecalculate,
  selectAllRowCropScenarioPiecesByScenarioMap
} from '../../../app/scenarioPiecesSlice';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { AvailabilityService, HighRiskType, ScenarioPieceType, UnitStructureCode } from '@silveus/calculations';
import { RowCropScenarioPieceExtendedFormFields } from '../rowCropScenarioPieceExtendedFormFields.component';
import InsurancePlanAttributes from '../../../types/api/enums/insurancePlan/InsurancePlanAttributes';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { Quote } from '../../../types/api/Quote';
import { ClientFile } from '../../../types/api/ClientFile';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import {
  AdmUpperCoverageLevelInput
} from '../../../components/formInputs/scenarioPiece/admUpperCoverageLevelInput.component';
import { AdmProtectionFactorInput } from '../../../components/formInputs/scenarioPiece/admProtectionFactor.component';
import { useFederalOfferData } from '../../../hooks/useFederalOfferData';
import {
  selectAllScenarioOptionsByScenarioIdMap
} from '../../../app/optionsSlice';
import {
  fetchHipHurricaneEvent,
  selectHurricaneEventOccurred
} from '../../../app/admSlice';
import { TropicalStormInput } from '../../../components/formInputs/scenarioPiece/tropicalStormInput.component';
import { HurricaneEventInput } from '../../../components/formInputs/scenarioPiece/hurricaneEventInput.component';
import { useEffect } from 'react';
import InsurancePlan from '../../../types/api/enums/insurancePlan/InsurancePlan.enum';
import { getHipLowerCoverageLevel } from './utils/getHipLowerCoverageLevel';
import PriceInputGroup, { PriceInputGroupFields } from '../../../components/formInputs/scenario/priceInputGroup.component';
import { ProtectionFactorFields } from '../../../components/formInputs/scenarioPiece/protectionFactorInput.component';
import {
  UpperCoverageLevelFields
} from '../../../components/formInputs/scenarioPiece/upperCoverageLevelInput.component';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import { selectScenarioById, updateScenario } from '../../../app/scenariosSlice';
import IndividualYieldInputGroup from '../../../components/formInputs/scenario/individualYieldInputGroup.component';
import useYieldsForScenario from '../../../hooks/useYieldsForScenario';
import { mergeScenarioWithYieldAndPriceUpdates } from '../../../utils/scenarioUpdateUtils';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { useScenarioPieceForm } from '../useScenarioPieceForm';

interface HipScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  scenarioPieceType: ScenarioPieceType;
  scenarioId: ScenarioId;
  year: number;
  countyId: string;
  typeId: string;
  practiceId: string;
  highRiskTypeId: HighRiskType;
  disabled: boolean;
  quote: Quote;
  clientFile: ClientFile;
  handleCancel: () => void;
}

export type HipScenarioPieceFormFields = ScenarioPieceFormFields & UpperCoverageLevelFields & ProtectionFactorFields & RowCropScenarioPieceExtendedFormFields & PriceInputGroupFields;

export const HipScenarioPieceForm = ({ scenarioPiece, scenarioPieceType, scenarioId, year, countyId, highRiskTypeId, typeId, practiceId, disabled, quote, clientFile, handleCancel, registerHeader, handleValidation, isCanceling = false }: HipScenarioPieceFormProps) => {
  const dispatch = useAppDispatch();
  const methods = useScenarioPieceForm<HipScenarioPieceFormFields>(scenarioPiece);
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  const { approvedYield, rateYield, aphYield } = useYieldsForScenario(scenarioId, quote.countyId, quote.commodityCode);
  const formId = 'hipScenarioPieceForm';
  const selectedOptions = useKeyMapSelector(selectAllScenarioOptionsByScenarioIdMap, scenarioId);
  const hurricaneEventOccurred = useAppSelector(selectHurricaneEventOccurred);
  const piecesForScenario = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioId);
  const piecesThatThisDependsOn = AvailabilityService.getScenarioPiecesThatThisScenarioPieceImmediatelyDependsOn(ScenarioPieceType.Hip);
  const underlyingPiece = piecesForScenario.find(sp => piecesThatThisDependsOn.includes(sp.scenarioPieceType)) ?? null;

  useFederalOfferData(scenarioPiece, scenarioId, scenarioPieceType, year, countyId, typeId, practiceId, highRiskTypeId);

  useEffect(() => {
    const insurancePlanCode = InsurancePlanAttributes[InsurancePlan.Hip].name;
    dispatch(fetchHipHurricaneEvent({ year, countyId, typeId, practiceId, insurancePlanCode }));
  }, []);

  useEffect(() => {
    if (scenarioPiece?.rowCropScenarioPieceExtendedData?.hurricaneEventOccurred === undefined) {
      methods.setValue('rowCropScenarioPieceExtendedData.hurricaneEventOccurred', hurricaneEventOccurred);
    }
  }, [hurricaneEventOccurred]);

  const onSubmit: SubmitHandler<HipScenarioPieceFormFields> = async data => {
    const planCode = Object.values(InsurancePlanAttributes).find(plan => plan.value === ScenarioPieceType.Hip)?.name ?? '';

    const lowerCoverageLevel = getHipLowerCoverageLevel(piecesForScenario, underlyingPiece);

    const newScenarioPiece: RowCropScenarioPiece = {
      isActive: data.isActive,
      isInvalid: false,
      lowerCoverageLevel: lowerCoverageLevel,
      planCode: scenarioPiece?.planCode ?? planCode,
      protectionFactor: data.protectionFactor,
      rowCropScenarioPieceExtendedData: data.rowCropScenarioPieceExtendedData,
      rowCropScenarioPieceId: scenarioPiece?.rowCropScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: ScenarioPieceType.Hip,
      unitStructure: UnitStructureCode.AU,
      upperCoverageLevel: data.upperCoverageLevel,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    if (scenario) {
      await dispatch(updateScenario({
        updatedScenario: mergeScenarioWithYieldAndPriceUpdates(scenario, data),
      }));
    }

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(createScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId, updatedScenarioPiece: newScenarioPiece }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };
  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AdmUpperCoverageLevelInput upperCoverageLevel={scenarioPiece?.upperCoverageLevel ?? null} disabled={true} />
            </Grid>
            <Grid item xs={6}>
              <AdmProtectionFactorInput protectionFactor={scenarioPiece?.protectionFactor ?? null} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <HurricaneEventInput hurricaneEventOccurred={scenarioPiece?.rowCropScenarioPieceExtendedData?.hurricaneEventOccurred ?? hurricaneEventOccurred} disabled={disabled} />
            </Grid>
            <Grid item xs={12}>
              {/* TODO #67490 Get Tropical Storm working for HIP again */}
            </Grid>
            <Grid item xs={12}>
              <TropicalStormInput numberOfTropicalStorms={scenarioPiece?.rowCropScenarioPieceExtendedData?.numberOfTropicalStorms ?? null} initialOptions={selectedOptions.map(o => o.option)} disabled={disabled} />
            </Grid>
            <PriceInputGroup quote={quote} scenario={scenario} scenarioPiece={scenarioPiece} projectedPrice={scenario?.projectedPrice ?? null} harvestPrice={scenario?.harvestPrice ?? null} />
            <IndividualYieldInputGroup
              quote={quote}
              scenarioId={scenarioId}
              approvedYield={approvedYield}
              producerYield={scenario?.actualProducerYield ?? null}
              rateYield={rateYield}
              aphYield={aphYield}
            />
            <Grid item xs={12}>
              <IsActiveInput isActive={scenarioPiece?.isActive ?? null} disabled={disabled} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
    </>
  );
};
