import { Controller, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { Nullable } from '../../../types/util/Nullable';
import { CoverageLevelPair } from '../../../types/api/adm/CoverageLevelPair';
import { onNumericInputChange } from '../../numberTextField/numberTextField.component';
import React, { useEffect, useState } from 'react';
import { FormInputParameterNames } from '../constants/inputConstants';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';

export interface UpperCoverageLevelProps {
  fieldName?: string;
  label?: string;
  upperCoverageLevel: Nullable<number>;
  autoFocus?: boolean;
  disabled?: boolean;
  coverageLevelPairings?: CoverageLevelPair[] | null;
  setAreUpperCoverageLevelsLoaded?: (isLoaded: boolean) => void;
}

export interface UpperCoverageLevelFields extends FieldValues {
  upperCoverageLevel: number;
}

export const UpperCoverageLevelInput = ({
  upperCoverageLevel,
  availableCoverageLevels,
  label = 'Upper Coverage Level',
  fieldName = FormInputParameterNames.UpperCoverageLevelName,
  autoFocus = false,
  coverageLevelPairings = null,
  disabled = false,
  setAreUpperCoverageLevelsLoaded }: UpperCoverageLevelProps & { availableCoverageLevels: number[] }) => {

  const { control, setValue, getValues } = useFormContext();
  const lowerCoverage = getValues(FormInputParameterNames.LowerCoverageLevelName);
  const selectedLowerCoverageLevel = useWatch({ name: FormInputParameterNames.LowerCoverageLevelName, control: control, defaultValue: undefined });
  const [useableCoverageLevels, setUseableCoverageLevels] = useState<number[]>([]);

  useEffect(() => {
    let useableValues: number[];
    const validOtherPairing = isNotNullOrUndefined(lowerCoverage) && coverageLevelPairings !== null && coverageLevelPairings.map(x => x.lowerCoverageLevel).includes(lowerCoverage);
    const validOtherAvailableLevels = isNotNullOrUndefined(lowerCoverage) && availableCoverageLevels.includes(lowerCoverage);
    if (coverageLevelPairings && validOtherPairing) {
      // using pairing and the other number is valid and in that list
      useableValues = coverageLevelPairings.filter(x => x.lowerCoverageLevel === lowerCoverage).map(x => x.upperCoverageLevel);
    } else if (coverageLevelPairings) {
      // using pairing but the other number is invalid or not in the list
      useableValues = coverageLevelPairings.map(x => x.upperCoverageLevel);
    } else if (validOtherAvailableLevels) {
      // (original functionality) other number is valid, use the availble list grab all greater than the other
      useableValues = availableCoverageLevels.filter(x => x > lowerCoverage);
    } else {
      // fall back, just present all options
      useableValues = availableCoverageLevels;
    }

    setUseableCoverageLevels([...new Set(useableValues)].sort((a, b) => b - a));
  }, [coverageLevelPairings, availableCoverageLevels, selectedLowerCoverageLevel]);

  useEffect(() => {
    if (useableCoverageLevels.length === 1 && upperCoverageLevel !== useableCoverageLevels[0]) {
      setValue(FormInputParameterNames.UpperCoverageLevelName, useableCoverageLevels[0], { shouldDirty: true });
    }
    if (setAreUpperCoverageLevelsLoaded) setAreUpperCoverageLevelsLoaded(useableCoverageLevels.length > 0);
  }, [useableCoverageLevels]);

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.validateMultipleRules(
          [
            VALIDATION_RULES.restrictDefaultValue(0),
            VALIDATION_RULES.restrictNotOnListValue(useableCoverageLevels),
          ],
        ),
      }}
      defaultValue={upperCoverageLevel ?? ''}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="number"
          label={label}
          fullWidth
          select
          value={value}
          inputProps={{ max: 0, min: 100 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {useableCoverageLevels.map(cl => <MenuItem key={cl} value={cl.toFixed(0)}>{cl.toFixed(0)}</MenuItem>)}
        </TextField>
      )}
    />
  );
};
