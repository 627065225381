import StaxCalculationParams from '../../../types/api/calculationData/staxCalculationParams';
import { getStaxCalcData } from '../../calculationData.service';
import { admDb } from '../../../db';
import {
  getAreaBaseRate,
  getInsuranceOfferAvailability,
  getInsuranceOfferInformation,
  getSubsidyPercent
} from '../../localCalcDataQueries.service';
import {
  ProductOfferAvailabilities,
  ScenarioPieceType } from '@silveus/calculations';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import { ProductOfferAvailabilityParams } from './OfferAvailability';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { StaxCalculationParamsRequest } from '../../../types/api/calculationData/staxCalculationParamsRequest';

export const getStaxCalcDataRequest = async (calcParamRequest: StaxCalculationParamsRequest): Promise<StaxCalculationParams[]> => {
  const request = () => getStaxCalcData(calcParamRequest);

  const admTransactionTables = [
    admDb.insuranceOffers,
    admDb.subsidies,
    admDb.areaCoverageLevels,
    admDb.areaRates,
    admDb.coverageLevelDifferentials,
  ];

  const readTransaction = async (): Promise<StaxCalculationParams[]> => {
    const staxResponse: StaxCalculationParams[] = [];

    const unitTransactions = calcParamRequest.distinctUnits.map(async distinctUnit => {
      const admData = await admDb.transaction('r', admTransactionTables, async () => {
        const subsidyPercent = await getSubsidyPercent(calcParamRequest.commodityCode, calcParamRequest.unitStructureCode, calcParamRequest.planCode, calcParamRequest.upperCoverageLevel, calcParamRequest.coverageTypeCode);

        const insuranceOffer = await getInsuranceOfferInformation(calcParamRequest.planCode, calcParamRequest.countyId, distinctUnit.typeId, distinctUnit.practiceId, calcParamRequest.coverageTypeCode);

        if (insuranceOffer === null) return null;

        const baseRate = await getAreaBaseRate(calcParamRequest.year, insuranceOffer.insuranceOfferId, calcParamRequest.upperCoverageLevel, calcParamRequest.volatility, calcParamRequest.upperCoverageLevel, calcParamRequest.lowerCoverageLevel);

        return {
          baseRate: baseRate,
          subsidyPercent: subsidyPercent,
        };
      });

      if (admData === null) return;

      const staxCalculationParams: StaxCalculationParams = {
        unit: distinctUnit,
        baseRate: admData.baseRate,
        subsidyPercent: admData.subsidyPercent,
      };

      staxResponse.push(staxCalculationParams);
    });

    await Promise.all(unitTransactions);

    return staxResponse;
  };

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getOfferAvailability = async (productOfferAvailabilityParams: ProductOfferAvailabilityParams): Promise<ProductOfferAvailabilities[]> => {
  const scenarioPieceTypes: ScenarioPieceType[] = [ScenarioPieceType.StaxRp, ScenarioPieceType.StaxRpHpe];

  const offerAvailabilityPromises = scenarioPieceTypes.map(scenarioPieceType => {
    return getInsuranceOfferAvailability(
      productOfferAvailabilityParams.year,
      productOfferAvailabilityParams.countyId,
      productOfferAvailabilityParams.commodityCode,
      getInsurancePlanCodeForScenarioPiece(scenarioPieceType),
      scenarioPieceType);
  });

  return Promise.all(offerAvailabilityPromises);
};
