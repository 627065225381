import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../../app/scenarioPiecesSlice';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import { openDrawer } from '../../../app/appDrawerSlice';
import {
  duplicateScenario,
  removeScenario,
  setCurrentlySelectedScenarioId
} from '../../../app/scenariosSlice';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import ScenarioSummary from './scenarioSummary.component';
import PriceYieldDetails from './priceYieldDetails.component';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import CommandBar from '../../../components/command-bar/commandBar.component';
import DeleteIcon from '@mui/icons-material/Delete';
import { Compare, ViewModule as ViewModuleIcon } from '@mui/icons-material';
import { LinkFieldsMenuItem } from './scenarioCard.component.linkFieldsMenu';
import { ConfirmStateContent, openConfirm } from '../../../app/confirmSlice';
import classNames from 'classnames';
import './scenarioCard.styles.css';
import { CreateMatrixFromScenariosMenu } from './scenarioCard.component.createMatrixFromScenariosMenu';
import { ReactElement, ReactNode, useState, MouseEvent } from 'react';
import CollectionsIcon from '@mui/icons-material/Collections';
import { selectQuoteById } from '../../../app/quotesSlice';
import {
  openUnitsModal,
  selectAcresForScenario, selectAdjustedYieldForScenario,
  selectApprovedYieldForScenario, selectRateYieldForScenario,
  selectAverageSharePercentForScenario
} from '../../../app/unitsSlice';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import { selectCommodity, selectCounty, selectInsuranceCalendarsByTypePractice, selectStateFromCounty } from '../../../app/admSlice';
import { selectAllInputCostScenarioPiecesByScenarioMap } from '../../../app/inputCostScenarioPiecesSlice';
import InputCostScenarioPieceCard from '../../../pages/scenarioPiece/inputCost/inputCostScenarioPieceCard.component';
import InactiveInputCostScenarioPieceCard from '../../scenarioPiece/inputCost/inactiveInputCostScenarioPieceCard.component';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { selectAllForwardSoldScenarioPiecesByScenarioMap } from '../../../app/forwardSoldScenarioPiecesSlice';
import ForwardSoldScenarioPieceCard from '../../scenarioPiece/forwardSold/forwardSoldScenarioPieceCard.component';
import InactiveForwardSoldScenarioPieceCard from '../../scenarioPiece/forwardSold/inactiveForwardSoldScenarioPieceCard.component';
import { selectAllHarvestRevenueScenarioPiecesByScenarioMap } from '../../../app/harvestRevenueScenarioPiecesSlice';
import HarvestRevenueScenarioPieceCard from '../../../pages/scenarioPiece/harvestRevenue/harvestRevenueScenarioPieceCard.component';
import InactiveHarvestRevenueScenarioPieceCard from '../../scenarioPiece/harvestRevenue/inactiveHarvestRevenueScenarioPieceCard.component';
import { selectAllHailScenarioPieceCompositionsByScenarioMap, selectAllHailScenarioPiecesByScenarioMap } from '../../../app/hailSlice';
import HailScenarioPieceCard from '../../scenarioPiece/hail/hailScenarioPieceCard.component';
import InactiveHailScenarioPieceCard from '../../scenarioPiece/hail/inactiveHailScenarioPieceCard.component';
import InactiveRowCropScenarioPieceCard from '../../scenarioPiece/inactiveRowCropScenarioPieceCard';
import RowCropScenarioPieceCard from '../../scenarioPiece/rowCropScenarioPieceCard';
import ScenarioPieceGroupCard from '../../scenarioPieceGroup/scenarioPieceGroupCard.component';
import { selectAllScenarioPieceGroupsByScenarioMap } from '../../../app/scenarioPieceGroupsSlice';
import InactiveScenarioPieceGroupCard from '../../scenarioPieceGroup/inactiveScenarioPieceGroupCard.component';
import { ScenarioPieceIdentifier, AvailabilityService, ScenarioPieceGroupType } from '@silveus/calculations';
import ImportantDatesDetails from './importantDates.component';
import { stableEmptyArrayAsMutable } from '../../../utils/stableEmptyArray';
import InsuranceCalendar from '../../../types/api/adm/InsuranceCalendar';
import { InvalidScenarioIcon } from './invalidScenarioIcon.component';
import { selectUserLinkedScenarios } from '../../../app/userSettingsSlice';
import { beginLoading, endLoading } from '../../../app/loaderSlice';
import { getScenarioPieceDefinition } from '../../../constants/productDefinitions/scenarioPieceDefinitionRecords';
import { openCreateTemplateScenarioModal } from '../../../app/templateScenariosSlice';
import { Nullable } from '../../../types/util/Nullable';

interface ScenarioCardProps {
  scenario: RowCropScenario;
  isSelectedItem: boolean,
  isScenarioAnalysisCard?: boolean;
  isReadonly?: boolean;
}

const ScenarioCard = ({ scenario, isSelectedItem, isScenarioAnalysisCard = false, isReadonly = false }: ScenarioCardProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [matrixMenuAnchor, setMatrixMenuAnchor] = useState<Nullable<HTMLElement>>(null);

  const linkedScenarios = useAppSelector(selectUserLinkedScenarios);
  const scenarioPieceReactElements = new Map<ScenarioPieceIdentifier, ReactElement>();

  const rowCropScenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenario.scenarioId);
  const inputCostScenarioPieces = useKeyMapSelector(selectAllInputCostScenarioPiecesByScenarioMap, scenario.scenarioId);
  const forwardSoldScenarioPieces = useKeyMapSelector(selectAllForwardSoldScenarioPiecesByScenarioMap, scenario.scenarioId);
  const harvestRevenueScenarioPieces = useKeyMapSelector(selectAllHarvestRevenueScenarioPiecesByScenarioMap, scenario.scenarioId);
  const hailScenarioPieceCompositions = useKeyMapSelector(selectAllHailScenarioPieceCompositionsByScenarioMap, scenario.scenarioId);
  const hailScenarioPieces = useKeyMapSelector(selectAllHailScenarioPiecesByScenarioMap, scenario.scenarioId);


  // Warning: if additional pieces are added here via selectors, make sure to add them to the array below.
  const allScenarioPieces = [
    ...rowCropScenarioPieces,
    ...inputCostScenarioPieces,
    ...forwardSoldScenarioPieces,
    ...harvestRevenueScenarioPieces,
    ...hailScenarioPieces,
  ];

  const invalidScenarioPieces = allScenarioPieces.filter(sp => sp.isInvalid);

  const scenarioPieceGroups = useKeyMapSelector(selectAllScenarioPieceGroupsByScenarioMap, scenario.scenarioId);
  const scenarioPieceIdsInGroups = new Set(scenarioPieceGroups.flatMap(spg => spg.scenarioPieceGroupMembers.map(spgm => spgm.scenarioPieceId)));

  const scenarioLinkageData = linkedScenarios.quotes[scenario.quoteId];

  const isScenarioLinked = scenarioLinkageData?.linkedScenarioIds.includes(scenario.scenarioId) ?? false;

  const linkedScenarioFields = scenarioLinkageData?.linkedFields ?? [];

  for (const rowCropScenarioPiece of rowCropScenarioPieces) {
    if (scenarioPieceIdsInGroups.has(rowCropScenarioPiece.scenarioPieceId)) {
      break;
    }

    let card: ReactNode;
    const def = getScenarioPieceDefinition(rowCropScenarioPiece.scenarioPieceType);
    if (rowCropScenarioPiece.isActive) {
      if (def.customScenarioPieceCard !== undefined) {
        card = def.customScenarioPieceCard({ isReadonly: isReadonly, scenarioPiece: rowCropScenarioPiece, quoteId: scenario.quoteId, isScenarioActive: isSelectedItem, scenarioColor: scenario.scenarioColor });
      } else {
        card = <RowCropScenarioPieceCard scenario={scenario} isReadonly={isReadonly} scenarioPiece={rowCropScenarioPiece} quoteId={scenario.quoteId} isScenarioActive={isSelectedItem} />;
      }
    } else {
      if (def.customScenarioInactivePieceCard !== undefined) {
        card = def.customScenarioInactivePieceCard({ isReadonly: isReadonly, scenarioPiece: rowCropScenarioPiece, quoteId: scenario.quoteId, isScenarioActive: isSelectedItem, scenarioColor: scenario.scenarioColor });
      } else {
        card = <InactiveRowCropScenarioPieceCard scenarioPiece={rowCropScenarioPiece} isScenarioActive={isSelectedItem} />;
      }
    }

    const applicableScenarioPieceCard =
      <Grid item xs={12} key={rowCropScenarioPiece.scenarioPieceId}>
        {card}
      </Grid>;
    scenarioPieceReactElements.set(rowCropScenarioPiece.scenarioPieceType, applicableScenarioPieceCard);
  }

  for (const inputCostScenarioPiece of inputCostScenarioPieces) {
    if (scenarioPieceIdsInGroups.has(inputCostScenarioPiece.scenarioPieceId)) {
      break;
    }

    const applicableScenarioPieceCard =
      <Grid item xs={12} key={inputCostScenarioPiece.scenarioPieceId}>
        {inputCostScenarioPiece.isActive
          ? <InputCostScenarioPieceCard isReadonly={isReadonly} scenarioPiece={inputCostScenarioPiece} quoteId={scenario.quoteId} isScenarioActive={isSelectedItem} />
          : <InactiveInputCostScenarioPieceCard scenarioPiece={inputCostScenarioPiece} isScenarioActive={isSelectedItem} />
        }
      </Grid>;
    scenarioPieceReactElements.set(inputCostScenarioPiece.scenarioPieceType, applicableScenarioPieceCard);
  }

  for (const forwardSoldScenarioPiece of forwardSoldScenarioPieces) {
    if (scenarioPieceIdsInGroups.has(forwardSoldScenarioPiece.scenarioPieceId)) {
      break;
    }

    const applicableScenarioPieceCard =
      <Grid item xs={12} key={forwardSoldScenarioPiece.scenarioPieceId}>
        {forwardSoldScenarioPiece.isActive
          ? <ForwardSoldScenarioPieceCard isReadonly={isReadonly} scenarioPiece={forwardSoldScenarioPiece} quoteId={scenario.quoteId} isScenarioActive={isSelectedItem} />
          : <InactiveForwardSoldScenarioPieceCard scenarioPiece={forwardSoldScenarioPiece} isScenarioActive={isSelectedItem} />
        }
      </Grid>;
    scenarioPieceReactElements.set(forwardSoldScenarioPiece.scenarioPieceType, applicableScenarioPieceCard);
  }

  for (const harvestRevenueScenarioPiece of harvestRevenueScenarioPieces) {
    if (scenarioPieceIdsInGroups.has(harvestRevenueScenarioPiece.scenarioPieceId)) {
      break;
    }

    const applicableScenarioPieceCard =
      <Grid item xs={12} key={harvestRevenueScenarioPiece.scenarioPieceId}>
        {harvestRevenueScenarioPiece.isActive
          ? <HarvestRevenueScenarioPieceCard isReadonly={isReadonly} scenarioPiece={harvestRevenueScenarioPiece} quoteId={scenario.quoteId} isScenarioActive={isSelectedItem} scenarioId={scenario.scenarioId} />
          : <InactiveHarvestRevenueScenarioPieceCard scenarioPiece={harvestRevenueScenarioPiece} isScenarioActive={isSelectedItem} />
        }
      </Grid>;
    scenarioPieceReactElements.set(harvestRevenueScenarioPiece.scenarioPieceType, applicableScenarioPieceCard);
  }

  for (const hailScenarioPieceComposition of hailScenarioPieceCompositions) {
    const applicableScenaioPieceCard =
      <Grid item xs={12} key={hailScenarioPieceComposition.hailScenarioPieceCompositionId}>
        {hailScenarioPieceComposition.isActive
          ? <HailScenarioPieceCard isReadonly={isReadonly} hailScenarioPieceComposition={hailScenarioPieceComposition} quoteId={scenario.quoteId} isScenarioActive={isSelectedItem} />
          : <InactiveHailScenarioPieceCard hailScenarioPieceComposition={hailScenarioPieceComposition} hailScenarioPieces={hailScenarioPieces} isScenarioActive={isSelectedItem} />
        }
      </Grid>;
    scenarioPieceReactElements.set(ScenarioPieceGroupType.Hail, applicableScenaioPieceCard);
  }

  for (const scenarioPieceGroup of scenarioPieceGroups) {
    const applicableCard = (
      <Grid item xs={12} key={scenarioPieceGroup.scenarioPieceGroupId}>
        {scenarioPieceGroup.isActive
          ? <ScenarioPieceGroupCard scenarioPieceGroup={scenarioPieceGroup} quoteId={scenario.quoteId} isScenarioActive={isSelectedItem} />
          : <InactiveScenarioPieceGroupCard scenarioPieceGroup={scenarioPieceGroup} isScenarioActive={isSelectedItem} />
        }
      </Grid>
    );
    scenarioPieceReactElements.set(scenarioPieceGroup.scenarioPieceGroupType, applicableCard);
  }

  const orderedScenarioPieces = [...AvailabilityService.sortScenarioPieces(scenarioPieceReactElements).values()];

  const quote = useAppSelector(s => selectQuoteById(s, scenario.quoteId));
  const unitsDisabled = quote?.quickQuote === true;

  const acres = useAppSelector(state => selectAcresForScenario(state, scenario.scenarioId)) ?? 0;
  const sharePercent = useAppSelector(state => selectAverageSharePercentForScenario(state, scenario.scenarioId)) ?? 1;
  const approvedYield = useAppSelector(state => selectApprovedYieldForScenario(state, scenario.scenarioId, quote?.countyId ?? '', quote?.commodityCode ?? ''));
  const aphYield = useAppSelector(state => selectAdjustedYieldForScenario(state, scenario.scenarioId, quote?.countyId ?? '', quote?.commodityCode ?? ''));
  const rateYield = useAppSelector(state => selectRateYieldForScenario(state, scenario.scenarioId));

  const allInsuranceCalendars = useAppSelector(state => quote === null ? stableEmptyArrayAsMutable<InsuranceCalendar>() :
    selectInsuranceCalendarsByTypePractice(state, quote.countyId, quote.commodityCode, scenario.typeId, scenario.practiceId));

  const planCodes = rowCropScenarioPieces.map(x => x.planCode);

  const importantDates = allInsuranceCalendars.filter(x => planCodes.includes(x.insurancePlanCode)).at(0);

  const state = useAppSelector(state => quote === null ? undefined : selectStateFromCounty(state, quote.countyId));
  const county = useAppSelector(state => quote === null ? undefined : selectCounty(state, quote.countyId));
  const commodity = useAppSelector(state => quote === null ? undefined : selectCommodity(state, quote.commodityCode));
  const stateCountyCommodityCombo = state && county && commodity ? `${state.name}/${county.name}/${commodity.name}` : undefined;

  const openScenarioPieceDrawer = (scenarioId: ScenarioId) => {
    dispatch(setCurrentlySelectedScenarioId(scenarioId));
    dispatch(openDrawer({ formName: 'scenarioPieceForm', scenarioId: scenarioId, quoteId: scenario.quoteId, defaultFormSelection: { formName: null } }));
  };

  const deleteScenario = async () => {
    await dispatch(removeScenario({ scenario: scenario }));
  };

  const onDeleteScenarioClick = () => {
    const confirmWindow: ConfirmStateContent = {
      title: 'Delete Scenario?',
      message: 'Are you sure you want to delete this scenario?',
      confirmText: 'Delete',
      onConfirm: deleteScenario,
    };
    dispatch(openConfirm(confirmWindow));
  };

  const editScenario = () => {
    dispatch(setCurrentlySelectedScenarioId(scenario.scenarioId));
    dispatch(openDrawer({ formName: 'scenarioForm', scenarioId: scenario.scenarioId, quoteId: scenario.quoteId }));
  };

  const onDuplicateScenarioClick = async () => {
    dispatch(beginLoading());

    try {
      await dispatch(duplicateScenario({ existingScenario: scenario }));
    } finally {
      dispatch(endLoading());
    }
  };

  const openMatrixMenu = (e: MouseEvent<HTMLElement>) => {
    setMatrixMenuAnchor(e.currentTarget);
  };
  const closeMenu = () => {
    setMatrixMenuAnchor(null);
  };

  const onViewUnitsClick = () => {
    dispatch(setCurrentlySelectedScenarioId(scenario.scenarioId));
    dispatch(openUnitsModal(scenario.scenarioId));
  };

  const onCreateScenarioTemplateClick = () => {
    dispatch(openCreateTemplateScenarioModal(scenario.scenarioId));
  };

  return (
    <Grid container sx={{ width: '340px', overflowY: 'auto', pb: 1 }} rowSpacing={1}>
      <Grid item xs={12}>
        <Card className={classNames({ 'scenario-card-box-shadow': isSelectedItem })}>
          <CardHeader
            sx={{ mr: 0, p: 0 }}
            title={
              <>
                {isReadonly ? (
                  <Grid container p={1} sx={{ justifyContent: 'flex-end' }}>
                    <Grid item>
                      <Tooltip title="This scenario has been chosen for an application. You may no longer edit it. VIEW ONLY" placement="top">
                        <TaskAltIcon sx={{ color: theme => theme.palette.primary.main, verticalAlign: 'middle', ml: .5 }} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container p={{ padding: '8px 2px' }}>
                    <Grid item xs>
                      {invalidScenarioPieces.length > 0 && (
                        <InvalidScenarioIcon scenarioName={scenario.name} invalidScenarioPieces={invalidScenarioPieces} />
                      )}
                      {!unitsDisabled && (
                        <Tooltip title="Unit Quoting On" placement="top">
                          <EmojiFlagsIcon color="secondary" sx={{ verticalAlign: 'middle', ml: .5 }} />
                        </Tooltip>
                      )}
                      {scenario.isFinalized && (
                        <Tooltip title="Scenario is final" placement="top">
                          <TaskAltIcon sx={{ color: theme => theme.palette.other.positive, verticalAlign: 'middle', ml: .5 }} />
                        </Tooltip>
                      )}
                    </Grid>

                    <Grid item xs={isScenarioAnalysisCard ? 3.5 : 6.75}>
                      <CommandBar
                        hideOverflow={isScenarioAnalysisCard}
                        items={[
                          {
                            id: 'units',
                            label: 'View Units',
                            iconButton: <CollectionsIcon />,
                            onclick: onViewUnitsClick,
                          },
                          {
                            id: 'matrix-menu',
                            label: 'Matrix',
                            onclick: () => { },
                            iconButton: <Compare />,
                            hide: isScenarioAnalysisCard,
                            renderMenuItem: () => (
                              <div key={`${scenario.scenarioId}-matrix-icon`}>
                                <Tooltip placement="top" title="Matrix">
                                  <div id={scenario.scenarioId} style={{ paddingLeft: '5px' }} onClick={openMatrixMenu}>
                                    <IconButton color="primary" sx={{ pr: 0, pl: 0, mr: 0, ml: 0 }}>
                                      <Compare />
                                    </IconButton>
                                  </div>
                                </Tooltip>
                                {matrixMenuAnchor && (
                                  <CreateMatrixFromScenariosMenu
                                    scenarioId={scenario.scenarioId}
                                    anchor={matrixMenuAnchor}
                                    closeMenu={closeMenu}
                                  />
                                )}
                              </div>
                            ),
                          },
                          {
                            id: 'duplicate',
                            label: 'Duplicate Scenario',
                            hide: isScenarioAnalysisCard,
                            iconButton: <ContentCopyIcon />,
                            onclick: onDuplicateScenarioClick,
                          },
                          {
                            id: 'add',
                            label: 'Add Scenario Piece',
                            iconButton: <AddCircleOutlineIcon />,
                            onclick: () => openScenarioPieceDrawer(scenario.scenarioId),
                          },
                          {
                            id: 'edit',
                            label: 'Edit',
                            iconButton: <EditIcon />,
                            onclick: editScenario,
                          },
                          {
                            id: 'link-fields',
                            label: 'Link Fields',
                            hide: isScenarioAnalysisCard,
                            onclick: () => { },
                            iconButton: <ViewModuleIcon />,
                            renderMenuItem: () => (
                              <LinkFieldsMenuItem
                                linkedScenarioFields={linkedScenarioFields}
                                quoteId={scenario.quoteId}
                                disabled={!isScenarioLinked}
                              />
                            ),
                          },
                          {
                            id: 'create-scenario-template',
                            label: 'Create Scenario Template',
                            onclick: onCreateScenarioTemplateClick,
                            iconButton: <ViewModuleIcon />,
                            disabled: allScenarioPieces.length === 0,
                          },
                          {
                            id: 'unit-level-loss-simulation',
                            label: 'Unit-Level Loss Simulation',
                            onclick: () => { },
                            hide: isScenarioAnalysisCard,
                            iconButton: <RequestQuoteIcon />,
                            disabled: true,
                          },
                          {
                            id: 'delete',
                            label: 'Delete',
                            hide: isScenarioAnalysisCard,
                            iconButton: <DeleteIcon />,
                            onclick: onDeleteScenarioClick,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            }
          />
          <CardContent sx={{ p: 0 }}>
            <Tooltip title={isReadonly ? '' : 'Double click to edit scenario'}>
              <Grid container onDoubleClick={isReadonly ? undefined : editScenario} bgcolor={isReadonly ? theme.palette.action.disabled : scenario.scenarioColor} sx={{ pl: 2, pr: 2, pb: 1, pt: 1 }}>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: theme => theme.palette.altText.main }} >
                    {scenario.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: theme => theme.palette.altText.main }} variant="subtitle2" component="h6">{stateCountyCommodityCombo}</Typography>
                </Grid>
              </Grid>
            </Tooltip>
            <Grid sx={{ pl: 1, pr: 1 }}>
              <ScenarioSummary scenarioId={scenario.scenarioId} typeId={scenario.typeId} practiceId={scenario.practiceId} isReadonly={isReadonly} />
              <PriceYieldDetails
                plantPrice={scenario.projectedPrice ?? 0}
                harvestPrice={scenario.harvestPrice ?? 0}
                aph={aphYield}
                productionYield={scenario.actualProducerYield ?? 0}
                volatility={scenario.volatility ?? 0}
                rateYield={rateYield}
                approvedYield={approvedYield}
                trendYield={scenario.expectedCountyYield ?? 0}
                countyYield={scenario.actualCountyYield ?? 0}
                acres={acres}
                sharePercent={sharePercent}
                isReadonly={isReadonly}
              />
              <ImportantDatesDetails
                insuranceCalendar={importantDates}
                isReadOnly={isReadonly}
              />
            </Grid>
          </CardContent>
          <CardActionArea />
        </Card >
      </Grid >
      {orderedScenarioPieces}
    </Grid >
  );
};


export default ScenarioCard;
