import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppTask } from '../../../../types/api/AppTask';
import { PersonOfInterest } from '../../../../types/api/insureds/PersonOfInterest';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectInsuredsPersonsOfInterestByType } from '../../../../app/personOfInterestSlice';
import { PersonOfInterestType } from '../../../../types/api/enums/contactInfo/personOfInterestType';
import { AppTableCell } from './applicationDecisionGrid';
import { selectTaxTypes } from '../../../../app/insuredsSlice';
import { TaxTypeId } from '../../../../types/api/PrimaryKeys';
import { Nullable } from '../../../../types/util/Nullable';
import { selectCounty, selectStateFromCounty } from '../../../../app/admSlice';
import { isNotNilOrEmpty, isNotNullOrUndefined, isNullOrUndefined } from '../../../../utils/nullHandling';
import { formatCityStateZip } from '../../../../utils/formatAddress';
import { getMaskedTaxId } from '../../../../utils/taxIdUtils';

export type LandLordTenantAssignmentModalProps = {
  applyAndClose: (landlordsAndTenants: PersonOfInterest[]) => void;
  cancel: () => void;
  selectedAppTask: AppTask;
  selectedAppTaskLandlordsAndTenants: PersonOfInterest[];
}

const LandlordTenantAssignmentModal = ({ applyAndClose, cancel, selectedAppTask, selectedAppTaskLandlordsAndTenants }: LandLordTenantAssignmentModalProps) => {
  const [landlordsAndTenants, setLandlordsAndTenants] = useState(selectedAppTaskLandlordsAndTenants);
  // use all landlords and tenants to populate the table
  const allLandlordsAndTenants = useAppSelector(state => selectInsuredsPersonsOfInterestByType(state, selectedAppTask.insuredId, PersonOfInterestType.LandLordOrTenant));
  const rootState = useAppSelector(s => s);
  const county = selectCounty(rootState, selectedAppTask.countyCode);
  const state = selectStateFromCounty(rootState, selectedAppTask.countyCode);
  const taxIdTypes = useAppSelector(selectTaxTypes);

  const handleSelectAllClicked = (checked: boolean) => {
    if (checked) {
      setLandlordsAndTenants(allLandlordsAndTenants);
    } else {
      setLandlordsAndTenants([]);
    }
  };

  const handleCheck = (llt: PersonOfInterest, isChecked: boolean) => {
    if (isChecked) {
      setLandlordsAndTenants([...landlordsAndTenants, llt]);
    } else {
      const filteredLlts = landlordsAndTenants.filter(taskLLT => taskLLT.personOfInterestId !== llt.personOfInterestId);
      setLandlordsAndTenants(filteredLlts);
    }
  };

  const handleApplyAndClose = () => {
    applyAndClose(landlordsAndTenants);
  };

  const handleCancel = () => {
    cancel();
  };

  const getName = (landlordTenant: PersonOfInterest) => {
    if (isNotNilOrEmpty(landlordTenant.middleName)) {
      return `${landlordTenant.firstName} ${landlordTenant.middleName} ${landlordTenant.lastName}`;
    } else {
      return `${landlordTenant.firstName} ${landlordTenant.lastName}`;
    }
  };

  const getAddress = (landlordTenant: PersonOfInterest) => {
    const { address } = landlordTenant;
    if (isNullOrUndefined(address)) {
      return '';
    }

    if (isNotNullOrUndefined(address.composite) && address.composite.length > 0) {
      return address.composite;
    }

    return `${isNotNullOrUndefined(address.addressLine1) ? address.addressLine1 : ''} ${formatCityStateZip(address)}`;
  };

  const getTaxIdTypeName = (taxIdTypeId: Nullable<TaxTypeId>) => {
    return taxIdTypes.find(taxIdType => taxIdType.taxTypeId === taxIdTypeId)?.name;
  };

  const areAllLltsChecked = () => {
    const allLltsIds = allLandlordsAndTenants.map(llt => llt.id);
    const checkedLltsIds = landlordsAndTenants.map(llt => llt.id);
    const allLltsAreChecked = allLltsIds.every(lltId => checkedLltsIds.includes(lltId));
    return allLltsAreChecked;
  };

  return (
    <Dialog
      open={true}
      onClose={(_, reason) => cancel()}
      maxWidth={false}
      disableEscapeKeyDown={true}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1100px',  // Set your width here
            height: '600px',
          },
        },
      }}>
      <DialogTitle>
        <Grid container>
          <Grid item xs>{`Link Landlord or Tenant to ${state?.abbrev} - ${county?.name} County`}</Grid>
          <Grid item>
            <IconButton
              onClick={handleCancel}
              sx={{ p: 0, color: theme => theme.palette.common.white }}
              size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 1, flexDirection: 'column', height: 'calc(100% - 120px)', overflow: 'hidden' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ height: '35px' }}>
                <AppTableCell width="35px">
                  <Checkbox checked={areAllLltsChecked()}
                    onChange={event => handleSelectAllClicked(event.target.checked)}
                  />
                </AppTableCell>
                <AppTableCell>Name</AppTableCell>
                <AppTableCell>Address</AppTableCell>
                <AppTableCell>Tax ID</AppTableCell>
                <AppTableCell>Tax Type</AppTableCell>
                <AppTableCell>Phone</AppTableCell>
                <AppTableCell>Email</AppTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allLandlordsAndTenants.map((landlordTenant, landlordTenantIndex) => {
                const doIdsMatch = landlordsAndTenants.some(taskLLT => taskLLT.personOfInterestId === landlordTenant.personOfInterestId);
                return (
                  <TableRow key={landlordTenant.id}>
                    <AppTableCell style={{ width: '35px' }}>
                      <Checkbox
                        checked={doIdsMatch}
                        onChange={event => {
                          handleCheck(landlordTenant, event.target.checked);
                        }}
                      />
                    </AppTableCell>
                    <AppTableCell>{getName(landlordTenant)}</AppTableCell>
                    <AppTableCell>{getAddress(landlordTenant)}</AppTableCell>
                    <AppTableCell>{getMaskedTaxId(landlordTenant.taxId)}</AppTableCell>
                    <AppTableCell>{getTaxIdTypeName(landlordTenant.taxTypeId)}</AppTableCell>
                    <AppTableCell>{landlordTenant.phone}</AppTableCell>
                    <AppTableCell>{landlordTenant.email}</AppTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Grid container xs justifyContent="center" rowSpacing={3} columnSpacing={1}>
          <Grid item xs={6} display="flex" justifyContent="center">
            <Button onClick={handleCancel} id="btn-wizard-cancel" variant="outlined">CLOSE</Button>
            <Button onClick={handleApplyAndClose} id="btn-wizard-save" variant="contained">APPLY AND CLOSE</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default LandlordTenantAssignmentModal;
