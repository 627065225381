import React, { KeyboardEvent, MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Fade, MenuList, Paper, Popper } from '@mui/material';

export interface PopoutActionChildProps {
  onClick: (e: MouseEvent) => void;
  className: string;
}

export interface PopoutActionProps {
  /**
   * This is only required when one of the menu items is itself opening another flyout
   * view on top of the one that this opens.
   */
  keepOpen?: boolean;
  id: string;
  children: ReactElement<PopoutActionChildProps> | ReactElement<PopoutActionChildProps>[];
}

export const usePopoutActions = ({ children, id, keepOpen = false }: PopoutActionProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: globalThis.MouseEvent | globalThis.TouchEvent) => {
    if (event.target instanceof Node && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    prevOpen.current = open;
  }, [open]);

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const popoutActions = <Popper
    open={open || keepOpen}
    anchorEl={anchorRef.current}
    placement="auto"
    transition
    style={{ zIndex: 1300 }}
  >
    {({ TransitionProps }) => (
      <Fade
        {...TransitionProps}
      >
        <Paper>
          <ClickAwayListener onClickAway={e => handleClose(e)}>
            <MenuList
              autoFocusItem={open}
              id={`action-menu-${id}`}
              aria-labelledby={`action-menu-${id}`}
              onKeyDown={handleListKeyDown}
            >
              {React.Children.map(
                children,
                child => React.cloneElement(child, {
                  onClick: (e: MouseEvent) => { setOpen(false); child.props.onClick(e); },
                  className: 'action-item',
                }))
              }
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Fade>
    )}
  </Popper>;

  return {
    onClick: handleToggle,
    anchorRef: anchorRef,
    popoutActions: popoutActions,
    open: open,
  };
};