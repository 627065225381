import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, styled } from '@mui/material';
import { ReactElement } from 'react';
import HelpIcon from '@mui/icons-material/Help';

export interface HarvestRevenueEntryDescriptionProps {
  tableBodyContents: ReactElement[];
  includeActionColumn: boolean;
  includeHints: boolean;
  disabled: boolean;
  useHeaderColSpan?: boolean;
}

const HarvestRevenueTableWrapper = ({ tableBodyContents, includeActionColumn, includeHints, useHeaderColSpan }: HarvestRevenueEntryDescriptionProps) => {

  const StyledTableHeader = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    paddingLeft: '6px',
    '& div': {
      fontSize: '12px',
      fontWeight: 400,
    },
  }));

  return (
    <>
      <TableContainer sx={{ overflowX: 'hidden' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableHeader colSpan={(useHeaderColSpan ?? false) ? 2 : 1}>
                <Grid container justifyContent="left">
                  Production
                  {includeHints &&
                    <Tooltip
                      sx={{ marginLeft: '8px' }}
                      title={
                        <div>
                          Remaining: An auto calculated production amount (Harvest Production - Forward Sold Bushels).<br /><br />
                          Fixed: A fixed production amount manually entered.<br /><br />
                          %: A % of the Production Yield multiplied by net acres. Enter as a whole number, IE 50 = 50%<br /><br />
                          +/-: Use 100% of the Production Yield multiplied by net acres and then add or subtract the amount entered.
                        </div>
                      }
                      arrow
                      disableFocusListener
                      placement="left">
                      <HelpIcon />
                    </Tooltip>
                  }
                </Grid>
              </StyledTableHeader>
              <StyledTableHeader colSpan={(useHeaderColSpan ?? false) ? 2 : 1}>
                <Grid container justifyContent="left">
                  Price
                  {includeHints &&
                    <Tooltip
                      sx={{ marginLeft: '8px' }}
                      title={
                        <div>
                          Fixed: A fixed price manually entered.<br /><br />
                          %: A % of the Harvest Price. Enter as a whole number, IE 50 = 50<br /><br />
                          %+/-: Use the Harvest price and then add or subtract the amount entered.
                        </div>
                      }
                      arrow
                      disableFocusListener
                      placement="left">
                      <HelpIcon />
                    </Tooltip>
                  }
                </Grid>
              </StyledTableHeader>
              <StyledTableHeader>
                <Grid container justifyContent="left">
                  Revenue
                </Grid>
              </StyledTableHeader>
              {includeActionColumn && <StyledTableHeader />}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBodyContents}
          </TableBody>
        </Table>
      </TableContainer >
    </>
  );
};

export default HarvestRevenueTableWrapper;
