import { useAppSelector } from '../../hooks/reduxHooks';
import { selectYears } from '../../app/admSlice';
import YearInput from './yearInput.component';
import { Nullable } from '../../types/util/Nullable';

export interface AdmYearProps {
  year: Nullable<number>;
  disabled: boolean;
  autoFocus?: boolean;
}

const AdmYearInput = ({ year, disabled, autoFocus = false }: AdmYearProps) => {
  const years = useAppSelector(selectYears);

  return (
    <YearInput
      year={year}
      disabled={disabled}
      autoFocus={autoFocus}
      availableYears={years}
      fieldLabel="Year" />
  );
};

export default AdmYearInput;
