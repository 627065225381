import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { loginRequest } from '../../authConfig';
import { Loading } from './loading.component';

export const LoggedOut = () => {
  const { instance } = useMsal();

  useEffect(() => {
    instance.loginRedirect(loginRequest);
  }, []);

  return <Loading message="Signing you in..." />;
};