import { OfflineChangedEntitiesResult } from './OfflineChangedEntitiesResult';
import { QuoterDbTable, quoterDb } from '../../db';
import { ignoreSoftDeleteQueryFilter } from '../dexieMiddleware/excludeSoftDeletedMiddleware';
import { safeWhere } from './whereClauses';
import { OfflineChangeTrackedEntity } from '../../types/app/OfflineChangeTrackedEntity';

export const getAllOfflineDeletedEntities = async (lastSynchronizationDate: Date) => {

  const getDataForTable = async <T extends OfflineChangeTrackedEntity>(table: QuoterDbTable<T>) => {
    const entities = ignoreSoftDeleteQueryFilter(async () => {
      const query = safeWhere(table, 'offlineDeletedOn').above(lastSynchronizationDate.toISOString());
      return await query.toArray();
    });

    return entities;
  };

  const resolvedPromises = await Promise.all([
    getDataForTable(quoterDb.insureds),
    getDataForTable(quoterDb.clientFiles),
    getDataForTable(quoterDb.premiumBreakdowns),
    getDataForTable(quoterDb.quotes),
    getDataForTable(quoterDb.rowCropScenarios),
    getDataForTable(quoterDb.rowCropScenarioPieces),
    getDataForTable(quoterDb.unitGroups),
    getDataForTable(quoterDb.unitYears),
    getDataForTable(quoterDb.unitYearAph),
    getDataForTable(quoterDb.scenarioUnitYearAph),
    getDataForTable(quoterDb.scenarioOptions),
    getDataForTable(quoterDb.scenarioOptionUnitYears),
    getDataForTable(quoterDb.scenarioQuickUnits),
    getDataForTable(quoterDb.historicalAnalyses),
    getDataForTable(quoterDb.matrices),
    getDataForTable(quoterDb.trendlineAnalyses),
    getDataForTable(quoterDb.userSettings),
    getDataForTable(quoterDb.forwardSoldScenarioPieces),
    getDataForTable(quoterDb.inputCostScenarioPieces),
    getDataForTable(quoterDb.harvestRevenueScenarioPieces),
  ]);

  const response: OfflineChangedEntitiesResult = {
    insureds: resolvedPromises[0],
    clientFiles: resolvedPromises[1],
    premiumBreakdowns: resolvedPromises[2],
    quotes: resolvedPromises[3],
    rowCropScenarios: resolvedPromises[4],
    rowCropScenarioPieces: resolvedPromises[5],
    unitGroups: resolvedPromises[6],
    unitYears: resolvedPromises[7],
    unitYearAph: resolvedPromises[8],
    scenarioUnitYearAph: resolvedPromises[9],
    scenarioOptions: resolvedPromises[10],
    scenarioOptionUnitYears: resolvedPromises[11],
    scenarioQuickUnits: resolvedPromises[12],
    historicalAnalyses: resolvedPromises[13],
    matrices: resolvedPromises[14],
    trendlineAnalyses: resolvedPromises[15],
    userSettings: resolvedPromises[16],
    forwardSoldScenarioPieces: resolvedPromises[17],
    inputCostScenarioPieces: resolvedPromises[18],
    harvestRevenueScenarioPieces: resolvedPromises[19],
  };

  return response;
};