import { UnitStructureCode } from '@silveus/calculations';
import { ADMYear } from '../../../types/api/adm/ADMYear';
import { AgencyInformation } from '../../../types/api/agencyInformation';
import { AgentInformation } from '../../../types/api/agentInformation';
import { AIP } from '../../../types/api/aip';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { AppTask } from '../../../types/api/AppTask';
import { ProAgAgency, ProAgAgent, ProAgApplicant, ProAgApplicationDto, ProAgApplicationInfo, ProAgConditionsOfAcceptance, ProAgCropInfo, ProAgEcoCropInfo, ProAgHipCropInfo, ProAgOtherChanges, ProAgPolicyCancellationInfo, ProAgPolicyInfo, ProAgPolicyTransferInfo, ProAgPostClosingAddedLandCoveredBy, ProAgSBI, ProAgSbiFormDTO, ProAgScoCropInfo, ProAgSignatureAuthorization, ProAgSignatureInfo, ProAgStaxCropInfo } from '../../../types/api/documents/proAgTypes';
import { AppType, AppTypeAttributes } from '../../../types/api/enums/application/AppType.enum';
import { CoverageType } from '../../../types/api/enums/application/coverageType.enum';
import { DesignatedCountyElection } from '../../../types/api/enums/application/designatedCountyElection';
import { EntityTypeEnum } from '../../../types/api/enums/application/entityType.enum';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { EntityType } from '../../../types/api/insureds/EntityType';
import { Insured } from '../../../types/api/insureds/Insured';
import { TaxType } from '../../../types/api/insureds/TaxType';
import { getCommodityName, getCountyName, getPracticeAbbrevFromPracticeCode } from '../../../utils/adm';
import { isNotNilOrEmpty, isNotNullOrUndefined } from '../../../utils/nullHandling';
import { getAppTaskStateName, getTaxTypeNameFromId } from './applicationMapper';
import { ecoPlanCodesNameString, filterCoveragesByPlanCodeName, formatPersonOfInterestName, hipPlanCodesNameString, mapDelimitedCancelTransferCrops, mapDelimitedPoasAndAuthorizedReps, mapDelimitedUnitAndOptionCodes, mapNewProducerCoverages, scoPlanCodesNameString, shouldShowTypeOrPracticeOnForm, staxPlanCodesNameString } from './applicationMapperUtils';
import { getCoverageLevelString } from '../../applicationsModal/wizardSteps/appDecisionsPage/appTaskCoverageRow';

// Maximum SBIs for ProAg MPCI application before requiring an additional SBI form to be generated.
export const MaxProAgSbi = 3;

export const MapProAgApplication = (appTask: AppTask, applicationWizard: ApplicationWizard, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], aips: AIP[], agentInfo: AgentInformation, agencyInfo: AgencyInformation, insured: Insured) => {
  const sbis = mapSBIs(appTask, entityTypes, taxTypes);
  const mappedApplication: ProAgApplicationDto = {
    agency: mapAgency(agencyInfo),
    agent: mapAgent(appTask, agentInfo),
    applicant: mapApplicant(appTask, entityTypes, taxTypes, insured),
    applicationInfo: mapApplicationInfo(appTask, applicationWizard, admData),
    conditionsOfAcceptance: mapConditionsOfAcceptance(applicationWizard),
    cropInfo: mapCropInfo(appTask, applicationWizard, admData),
    ecoCropInfo: mapEcoCropsInfo(appTask, admData),
    hipCropInfo: mapHipCropInfo(appTask, admData),
    otherChanges: mapOtherChanges(appTask),
    policyCancellationInfo: mapPolicyCancellationInfo(appTask),
    policyInfo: mapPolicyInfo(appTask, admData),
    policyTransferInfo: mapPolicyTransferInfo(appTask, agentInfo, admData, aips),
    postClosingAddedLandCoveredBy: mapPostClosingAddedLandCoveredBy(appTask),
    remarks: null,
    sbis: sbis.slice(0, MaxProAgSbi),
    scoCropInfo: mapScoCropsInfo(appTask, admData),
    signatureInfo: mapSignatureInfo(appTask, agentInfo),
    staxCropInfo: mapStaxCropsInfo(appTask),
  };

  return mappedApplication;
};

const mapAgency = (agency: AgencyInformation) => {
  const agencyInfo: ProAgAgency = {
    address: agency.address,
    agencyName: agency.agencyName,
    city: agency.city,
    phone: agency.phone,
    state: agency.state,
    zipCode: agency.zipCode,
  };

  return agencyInfo;
};

const mapAgent = (appTask: AppTask, agent: AgentInformation): ProAgAgent => {
  const agentInfo: ProAgAgent = {
    agentCode: appTask.agentCodeName,
    email: agent.email,
    firstName: agent.firstName,
    lastName: agent.lastName,
  };

  return agentInfo;
};

const mapApplicant = (appTask: AppTask, entityTypes: EntityType[], taxTypes: TaxType[], insured: Insured): ProAgApplicant => {
  const personType = entityTypes.find(x => x.entityTypeId === insured.entityTypeId);
  const spousalEntityTypeId = entityTypes.find(entityType => entityType.name === EntityTypeEnum.SpousalMarried)?.entityTypeId;
  const spouse = appTask.insuredOtherPersonsOfInterest.find(x => x.personOfInterestType === PersonOfInterestType.Spouse);
  const applicant: ProAgApplicant = {
    fullName: insured.name,
    email: insured.email,
    phone: insured.phone,
    personType: personType?.name ?? null,
    taxIdType: getTaxTypeNameFromId(insured.taxTypeId, taxTypes),
    taxId: insured.taxId,
    spouseFirstName: null,
    spouseLastName: null,
    spouseTaxIdType: null,
    spouseTaxId: null,
    address: insured.address.addressLine1,
    city: insured.address.city,
    state: insured.address.state,
    zipCode: insured.address.postalCode,
    stateOfIncorporation: insured.corporationState,
    powerOfAttorney: mapDelimitedPoasAndAuthorizedReps(appTask),
  };

  if (insured.entityTypeId === spousalEntityTypeId && isNotNullOrUndefined(spouse)) {
    applicant.spouseFirstName = spouse.firstName;
    applicant.spouseLastName = spouse.lastName;
    applicant.spouseTaxId = spouse.taxId;
    applicant.spouseTaxIdType = getTaxTypeNameFromId(spouse.taxTypeId, taxTypes);
  }

  return applicant;
};

const mapApplicationInfo = (appTask: AppTask, applicationWizard: ApplicationWizard, admYears: ADMYear[]): ProAgApplicationInfo => {
  const { isInsuringLandlordShare, isInsuringTenantShare } = appTask;
  const newProducerCoverages = mapNewProducerCoverages(appTask, admYears);

  const applicationInfo: ProAgApplicationInfo = {
    type: AppTypeAttributes[appTask.appType].name,
    limitedResourceFarmer: applicationWizard.isLimitedResourceFarmer,
    atLeast18: applicationWizard.isApplicantAtLeast18,
    newProducer: newProducerCoverages.length > 0,
    verticallyIntegratedProducer: applicationWizard.isVIP,
    insuringTenantShare: isInsuringTenantShare ?? false,
    insuringLandlordShare: isInsuringLandlordShare ?? false,
    categoryBNational: appTask.designatedCountyElection === DesignatedCountyElection.Nation,
    categoryBState: appTask.designatedCountyElection === DesignatedCountyElection.State,
  };
  return applicationInfo;
};

const mapConditionsOfAcceptance = (applicationWizard: ApplicationWizard): ProAgConditionsOfAcceptance => {
  return {
    a: applicationWizard.fciaDelinquency ?? false,
    b: applicationWizard.controlledSubstanceConviction ?? false,
    c: applicationWizard.fciaAgreementDisqualification ?? false,
    d: applicationWizard.disqualifiedOrDebarred ?? false,
    e: applicationWizard.fciaAgreementDisqualification ?? false,
    f: applicationWizard.currentLikeInsurance ?? false,
  };
};

const mapCropInfo = (appTask: AppTask, applicationWizard: ApplicationWizard, admData: ADMYear[]): ProAgCropInfo[] => {
  return appTask.coverages.map(coverage => {
    const isCoverageCanceled = coverage.coverageType === CoverageType.Cancel;
    const coveragePractice = coverage.practiceCode !== null ? getPracticeAbbrevFromPracticeCode(admData, appTask.cropYear, coverage.practiceCode) : '';
    const yesFormValue = 'YES';
    const noFormValue = 'NO';

    return {
      addedPriceElection: null,  // ignore - future/post 1.0 release - GETTY
      countyCode: isNotNullOrUndefined(coverage.countyCode) ? getCountyName(admData, appTask.cropYear, appTask.countyCode) : '',
      coverageLevel: coverage.upperCoverageLevel?.toString() ?? '',
      cropCode: getCommodityName(admData, appTask.cropYear, coverage.cropCode),
      designatedCounty: isNotNullOrUndefined(coverage.isDesignatedCounty) && coverage.isDesignatedCounty ? yesFormValue : noFormValue,
      effectiveCropYear: appTask.cropYear.toString(),
      ezHailAcres: null, // TODO - Spring
      ezHailPlan: null, // TODO - Spring
      ezHailPrice: null, // TODO - Spring
      intendedAcres: isNotNullOrUndefined(coverage.intendedNetAcres) ? coverage.intendedNetAcres.toString() : '',
      newProduct: isNotNullOrUndefined(coverage.isNewProducer) && coverage.isNewProducer ? yesFormValue : noFormValue,
      options: mapDelimitedUnitAndOptionCodes(coverage),
      plan: coverage.planCodeName,
      practice: isCoverageCanceled ? 'Cancel' : shouldShowTypeOrPracticeOnForm(coverage) ? coveragePractice : '',
      price: coverage.protectionFactor !== null ? coverage.protectionFactor.toString() : null,
      type: isCoverageCanceled ? 'Cancel' : shouldShowTypeOrPracticeOnForm(coverage) ? coverage.typeAbbrev : '',
      unitStructureCode: UnitStructureCode[coverage.unitStructure],
      verticallyIntegratedProducer: applicationWizard.isVIP ? yesFormValue : noFormValue,
    };
  });
};

const mapEcoCropsInfo = (appTask: AppTask, admData: ADMYear[]): ProAgEcoCropInfo[] => {
  const ecoCrops = filterCoveragesByPlanCodeName(appTask.coverages, ecoPlanCodesNameString);

  return ecoCrops.map(coverage => {
    // we already know that this coverage has an ECO child coverage because this array ONLY contains those coverages
    const ecoChild = coverage.childCoverages.find(childCoverage => childCoverage.planCodeName.includes(ecoPlanCodesNameString));
    return {
      cropCode: isNotNullOrUndefined(coverage.cropCode) ? getCommodityName(admData, appTask.cropYear, coverage.cropCode) : '',
      countyCode: appTask.countyCode ? getCountyName(admData, appTask.cropYear, appTask.countyCode) : '',
      ecoPlan: ecoChild?.planCodeName ?? '',
      ecoCoverageLevel: coverage.upperCoverageLevel?.toString() ?? '',
      ecoProtectionFactor: ecoChild?.protectionFactor?.toString() ?? '',
      parentCoverageLevel: coverage.upperCoverageLevel?.toString() ?? '',
      underlyingPlan: coverage.planCodeName,
    };
  });
};

const mapHipCropInfo = (appTask: AppTask, admData: ADMYear[]): ProAgHipCropInfo[] => {
  const hipCrops = filterCoveragesByPlanCodeName(appTask.coverages, hipPlanCodesNameString);

  return hipCrops.map(coverage => {
    // we already know that this coverage has a HIP child coverage because this array ONLY contains those coverages
    const hipChild = coverage.childCoverages.find(childCoverage => childCoverage.planCodeName.includes(hipPlanCodesNameString));
    return {
      cropCode: isNotNullOrUndefined(coverage.cropCode) ? getCommodityName(admData, appTask.cropYear, coverage.cropCode) : '',
      countyCode: appTask.countyCode ? getCountyName(admData, appTask.cropYear, appTask.countyCode) : '',
      hipProtectionFactor: hipChild?.protectionFactor?.toString() ?? '',
      intendedAcres: coverage.intendedNetAcres?.toString() ?? '',
      optionCodes: mapDelimitedUnitAndOptionCodes(coverage),
      practice: null, // ignore - GETTY
      type: null, // ignore - GETTY
      underlyingPlan: null, // ignore - GETTY
    };
  });
};

const mapOtherChanges = (appTask: AppTask): ProAgOtherChanges => {
  return {
    addRemoveSBI: false,  // ignore - future/post 1.0 release - GETTY
    correctAuthorizedRepresentative: false,  // ignore - future/post 1.0 release - GETTY
    correctAddress: false,  // ignore - future/post 1.0 release - GETTY
    correctSbiIdNumber: false,  // ignore - future/post 1.0 release - GETTY
    correctInsuredIdNumber: false,  // ignore - future/post 1.0 release - GETTY
    correctInsuredSpelling: false,  // ignore - future/post 1.0 release - GETTY
    correctSbiSpelling: false,  // ignore - future/post 1.0 release - GETTY
    addRemoveCountyElection: false,  // ignore - future/post 1.0 release - GETTY
    other: false,  // ignore - future/post 1.0 release - GETTY
  };
};

const mapPolicyCancellationInfo = (appTask: AppTask): ProAgPolicyCancellationInfo => {
  // ignore - future/post 1.0 release - GETTY
  return {
    aipRepresentativePrintedName: '', // ignore - future/post 1.0 release - GETTY
    deathIncompetenceOrDissolution: false, // ignore - future/post 1.0 release - GETTY
    insuredRequest: false, // ignore - future/post 1.0 release - GETTY
    mutualConsent: false, // ignore - future/post 1.0 release - GETTY
    other: false, // ignore - future/post 1.0 release - GETTY
  };
};

const mapPolicyInfo = (appTask: AppTask, admData: ADMYear[]): ProAgPolicyInfo => {
  return {
    cropYear: appTask.cropYear.toString(),
    policyNumber: appTask.appType !== AppType.CancelTransfer ? appTask.policyNumber : '',
    state: getAppTaskStateName(appTask, admData),
  };
};

const mapPolicyTransferInfo = (appTask: AppTask, agent: AgentInformation, admYears: ADMYear[], aips: AIP[]): ProAgPolicyTransferInfo => {
  const isAppCancelTransfer = appTask.appType === AppType.CancelTransfer;

  if (!isAppCancelTransfer) {
    return {
      aipName: null,
      aipCode: null,
      aipRepresentativePrintedName: null,
      assumingAgent: null,
      cropsToBeCancelTransferred: null,
      cropYear: null,
      policyNumber: null,
    };
  }

  const previousAIP = aips.find(x => x.aipId === appTask.previousAIPId);
  return {
    aipName: previousAIP?.aipName ?? '',
    aipCode: '',
    aipRepresentativePrintedName: '',
    assumingAgent: mapAgent(appTask, agent),
    cropsToBeCancelTransferred: mapDelimitedCancelTransferCrops(appTask, admYears),
    cropYear: appTask.cropYear.toString(),
    policyNumber: appTask.previousPolicyNumber,
  };
};

const mapPostClosingAddedLandCoveredBy = (appTask: AppTask): ProAgPostClosingAddedLandCoveredBy => {
  return {
    sco: false, // ignore - future/post 1.0 release - GETTY
    eco: false, // ignore - future/post 1.0 release - GETTY
    stax: false, // ignore - future/post 1.0 release - GETTY
  };
};

const mapScoCropsInfo = (appTask: AppTask, admData: ADMYear[]): ProAgScoCropInfo[] => {
  const scoCrops = filterCoveragesByPlanCodeName(appTask.coverages, scoPlanCodesNameString);
  return scoCrops.map(coverage => {
    // we already know that this coverage has an SCO child coverage because this array ONLY contains those coverages
    const scoChild = coverage.childCoverages.find(childCoverage => childCoverage.planCodeName.includes(scoPlanCodesNameString));
    return {
      arcEnrolled: false, // per Ashley/Getty, default to false until spring season
      countyCode: appTask.countyCode ? getCountyName(admData, appTask.cropYear, appTask.countyCode) : '',
      cropCode: isNotNullOrUndefined(coverage.cropCode) ? getCommodityName(admData, appTask.cropYear, coverage.cropCode) : null,
      coverageLevel: coverage.upperCoverageLevel?.toString() ?? '',
      scoPlan: scoChild?.planCodeName ?? '',
      scoProtectionFactor: scoChild?.protectionFactor?.toString() ?? '',
      underlyingPlan: coverage.planCodeName,
    };
  });
};

const mapSBIs = (appTask: AppTask, entityTypes: EntityType[], taxTypes: TaxType[]) => {
  const sbiPOIs = appTask.insuredOtherPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.SBI || x.personOfInterestType === PersonOfInterestType.LandLordOrTenant || x.personOfInterestType === PersonOfInterestType.Spouse);
  return sbiPOIs.map(sbi => {
    const personType = entityTypes.find(x => x.entityTypeId === sbi.entityTypeId);
    const mappedSBI: ProAgSBI = {
      firstName: sbi.firstName,
      lastName: sbi.lastName,
      phone: sbi.phone,
      personType: personType?.name ?? null,
      taxIdType: getTaxTypeNameFromId(sbi.taxTypeId, taxTypes),
      taxId: sbi.taxId,
      address: sbi.address?.addressLine1 ?? null,
      city: sbi.address?.city ?? null,
      state: sbi.address?.state ?? null,
      zipCode: sbi.address?.postalCode ?? null,
      landlordOrTenant: sbi.personOfInterestType === PersonOfInterestType.LandLordOrTenant,
    };
    return mappedSBI;
  });
};

const mapSignatureAuthorizations = (appTask: AppTask): ProAgSignatureAuthorization[] => {
  const authSignerArray: ProAgSignatureAuthorization[] = [];
  const { insuredOtherPersonsOfInterest } = appTask;
  const authorizedToSign = insuredOtherPersonsOfInterest.filter(person => person.personOfInterestType === PersonOfInterestType.AuthorizedToSign);

  for (const authSigner of authorizedToSign) {
    const name = formatPersonOfInterestName(authSigner);
    if (isNotNilOrEmpty(name)) {
      const authorizedToSign: ProAgSignatureAuthorization = {
        legalName: name,
        last4Ssn: null, // TODO we will eventually want this populated from taxId
      };
      authSignerArray.push(authorizedToSign);
    }
  }
  return authSignerArray;
};

const mapSignatureInfo = (appTask: AppTask, agent: AgentInformation): ProAgSignatureInfo => {
  return {
    agentPrintedName: `${agent.firstName} ${agent.lastName}`, // Add logic as required
    applicantPrintedName: appTask.insuredName, // Add logic as required
    signatureAuthorizations: mapSignatureAuthorizations(appTask),
  };
};

const mapStaxCropsInfo = (appTask: AppTask): ProAgStaxCropInfo[] => {
  // Post 1.0 Release Logic below, keeping for later mapping.
  const staxCrops = filterCoveragesByPlanCodeName(appTask.coverages, staxPlanCodesNameString);
  const staxMapped = staxCrops.map(coverage => {
    return {
      countyCode: appTask.countyCode,
      coverageRange: getCoverageLevelString(coverage),
      cropCode: coverage.cropCode,
      ecoCoverage: false,   // TODO ask Getty
      lossTrigger: null,
      practice: null,
      scoCoverage: false,
      staxPlan: null,
      staxProtectionFactor: null,
      type: null,
      underlyingPlan: null,
    };
  });

  return staxMapped;
};

export const MapProAgAdditionalSBIs = (appTask: AppTask, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], agent: AgentInformation, agency: AgencyInformation, insured: Insured) => {
  const sbis = mapSBIs(appTask, entityTypes, taxTypes);
  const mappedSBIForm: ProAgSbiFormDTO = {
    agency: mapAgency(agency),
    agent: mapAgent(appTask, agent),
    applicant: mapApplicant(appTask, entityTypes, taxTypes, insured),
    policyInfo: mapPolicyInfo(appTask, admData),
    sbis: sbis.slice(MaxProAgSbi, sbis.length),
    signatureInfo: mapSignatureInfo(appTask, agent),
  };

  return mappedSBIForm;
};