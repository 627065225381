import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../types/util/Nullable';
import NumberTextField, { onNumericInputChange } from '../numberTextField/numberTextField.component';
import { SxProps, Theme } from '@mui/material';
import { validateAcres } from '../../pages/units/validations/acresValidation';

interface AcresProps {
  acres: Nullable<number>;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
  allowZero?: boolean;
  disabled?: boolean;
  name?: string;
  label?: string;
  onBlur?: () => void;
}

export type AcresFields = {
  acres: number;
};

const AcresInput = ({ acres, sx, autoFocus = false, allowZero = false, disabled = false, name = 'acres', label = 'Acres', onBlur }: AcresProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value: Nullable<number>) => validateAcres(value, allowZero) }}
      defaultValue={acres ?? 1000}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          type="number"
          label={label}
          fullWidth
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          sx={sx}
          size="small"
          disabled={disabled}
          onBlur={onBlur}
        />
      )}
    />
  );
};

export default AcresInput;
