import { CellTextColorRule } from '@silveus/react-matrix';
import { nextUiColorId } from './uiColorRuleId';
import { UiCellTextColorRule } from '../types';

export const createUiColorRuleFromRule = (colorRule: CellTextColorRule) => {
  const newColorRule: UiCellTextColorRule = {
    ...colorRule,
    id: nextUiColorId(),
  };

  return newColorRule;
};