import { envBaseApiUrl } from '../constants/envConstants';
import { ApiDate } from '../types/api/apiDate';
import { QuotePricingDto } from '../types/api/pricing/QuotePricingDto';
import axios from 'axios';

export interface QuotePriceParams {
  year: number;
  commodityCode: string;
  typeId: string;
  practiceId: string;
  stateCode: string;
  salesClosingDate: ApiDate;
}

export const getQuotePrice = async (params: QuotePriceParams) => {
  const result = await axios.get<QuotePricingDto>(`${envBaseApiUrl}/prices/quote-price`, { params });
  return result.data;
};