import { RootState } from './store';
import { ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import { ScenarioPiece } from '../types/api/ScenarioPiece';
import { createSelector } from '@reduxjs/toolkit';
import { getKeyedStateGroupedBy } from './sliceHelpers';
import { orderMap } from '../utils/mapHelpers';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';


//This is not in a slice because multiple slices need to use this selector
export const selectScenarioPieceAcrossAllTypes = (state: RootState, scenarioPieceId: ScenarioPieceId): ScenarioPiece | undefined => {
  let scenarioPiece: (ScenarioPiece | undefined) = state.scenarioPieces.allScenarioPieces.data[scenarioPieceId];
  if (scenarioPiece) {
    return scenarioPiece;
  }

  scenarioPiece = state.inputCostScenarioPieces.allScenarioPieces.data[scenarioPieceId];
  if (scenarioPiece) {
    return scenarioPiece;
  }

  scenarioPiece = state.forwardSoldScenarioPieces.allScenarioPieces.data[scenarioPieceId];
  if (scenarioPiece) {
    return scenarioPiece;
  }

  scenarioPiece = state.harvestRevenueScenarioPieces.allScenarioPieces.data[scenarioPieceId];
  if (scenarioPiece) {
    return scenarioPiece;
  }

  scenarioPiece = state.hail.hailScenarioPieces.data[scenarioPieceId];
  if (scenarioPiece) {
    return scenarioPiece;
  }
  return undefined;
};

const selectRowCropScenarioPieces = (state: RootState) => state.scenarioPieces.allScenarioPieces.data;
const selectInputCostScenarioPieces = (state: RootState) => state.inputCostScenarioPieces.allScenarioPieces.data;
const selectForwardSoldScenarioPieces = (state: RootState) => state.forwardSoldScenarioPieces.allScenarioPieces.data;
const selectHarvestRevenueScenarioPieces = (state: RootState) => state.harvestRevenueScenarioPieces.allScenarioPieces.data;
const selectHailScenarioPieces = (state: RootState) => state.hail.hailScenarioPieces.data;

// Memoized selector to get combined list of all scenario pieces
export const selectAllScenarioPiecesByScenarioMap = createSelector(
  [selectRowCropScenarioPieces, selectInputCostScenarioPieces, selectForwardSoldScenarioPieces, selectHarvestRevenueScenarioPieces, selectHailScenarioPieces],
  (rowCropScenarioPieces, inputCostScenarioPieces, forwardSoldScenarioPieces, harvestRevenueScenarioPieces, hailScenarioPieces): Map<ScenarioId, ScenarioPiece[]> => {
    const map = getKeyedStateGroupedBy({ ...rowCropScenarioPieces, ...inputCostScenarioPieces, ...forwardSoldScenarioPieces, ...harvestRevenueScenarioPieces, ...hailScenarioPieces }, sp => sp.scenarioId);
    const ordered = orderMap(map, DefaultOrders.scenarioPieces);
    return ordered;
  },
);