import { Button, Card, CardContent, CardHeader, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import { HailPlanType } from '../../../types/api/enums/hail/hailPlanType';
import EndorsementsCard from './endorsementsCard';
import HailDetailsCard from './hailDetailsCard';
import HailCardRow from './hailCardRow.component';
import RateSummary from './rateSummary.component';
import { useState } from 'react';
import HailTownshipRangeModal from '../hailTownshipRange/hailTownshipRangeModal';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { ScenarioPieceResponseDTO, sum, UnitResponseDTO } from '@silveus/calculations';
import { Nullable } from '../../../types/util/Nullable';
import HailUnitsModal from '../hailAcres/hailUnitsModal';
import { useAppSelector } from '../../../hooks/reduxHooks';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import { HailScenarioPieceId, HailScenarioPieceRateId, UnitYearId } from '../../../types/api/PrimaryKeys';
import HailRate from '../../../types/api/hail/hailRate';
import HailEndorsement from '../../../types/api/hail/hailEndorsement';
import HailScenarioPieceRate from '../../../types/api/hail/HailScenarioPieceRate';
import { selectUnitYears } from '../../../app/scenariosSlice';
import { getScenarioPieceRateCalculatedValuesWhenUnitQuoting } from '../utils/hailAcresUtils';
import { Quote } from '../../../types/api/Quote';
import UnitYear from '../../../types/api/UnitYear';
import HailScenarioPieceEndorsement from '../../../types/api/hail/HailScenarioPieceEndorsement';
import { getWeightedAverageRate, getWeightedEndorsementRateMap } from '../utils/hailEndorsementsUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { selectQuoteById } from '../../../app/quotesSlice';

type HailCardProps = {
  scenario: RowCropScenario;
  hailScenarioPiece: HailScenarioPiece;
  hailScenarioPieceUnits: UnitYearId[];
  hailScenarioPieceRates: HailScenarioPieceRate[];
  hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[];
  hailProduct: HailProduct;
  availableHailRates: HailRate[];
  availableHailEndorsements: HailEndorsement[];
  onHailScenarioPieceUpdated: (hailScenarioPiece: HailScenarioPiece) => void;
  onHailScenarioPieceLossChanged: (updatedScenarioPiece: HailScenarioPiece) => void;
  onHailScenarioPieceRatesUpdated: (updatedScenarioPieceRates: HailScenarioPieceRate[]) => void;
  onHailScenarioPieceUnitsUpdated: (quote: Quote, scenario: RowCropScenario, hailScenarioPiece: HailScenarioPiece, updatedUnitSelection: UnitYear[]) => void;
  onHailScenarioPieceEndorsementsUpdated: (hailScenarioPieceId: HailScenarioPieceId, updatedHailScenarioPieceEndorsements: HailScenarioPieceEndorsement[]) => void;
  onDeleteProductClick: (hailProduct: HailProduct) => void;
  calculatedValue: ScenarioPieceResponseDTO | undefined;
  index: number;
  disableButtonsAndFields: boolean;
  shouldLimitHeight: boolean;
}

const cardWidth = 325;

const HailCard = ({ scenario,
  hailScenarioPiece,
  hailScenarioPieceUnits,
  hailScenarioPieceRates,
  hailScenarioPieceEndorsements,
  hailProduct,
  availableHailRates,
  availableHailEndorsements,
  onDeleteProductClick,
  onHailScenarioPieceUpdated,
  onHailScenarioPieceLossChanged,
  onHailScenarioPieceRatesUpdated,
  onHailScenarioPieceUnitsUpdated,
  onHailScenarioPieceEndorsementsUpdated,
  calculatedValue,
  index,
  disableButtonsAndFields,
  shouldLimitHeight }: HailCardProps) => {
  const [townshipRangeModalOpen, setTownshipRangeModalOpen] = useState(false);
  const [acresModalOpen, setAcresModalOpen] = useState(false);
  const quote = useAppSelector(s => selectQuoteById(s, scenario.quoteId));
  const unitYears = useAppSelector(state => selectUnitYears(state, scenario.quoteId, scenario.typeId, scenario.practiceId, scenario.highRiskTypeId));
  if (quote === null) return null;

  const weightedAverageBaseRate = getWeightedAverageRate(
    availableHailRates, // pass in the same base collection for the available rates as the base rates
    availableHailRates,
    hailScenarioPieceRates,
    quote.quickQuote,
    unitYears,
    hailScenarioPieceUnits);

  const deleteHailScenarioPiece = () => {
    onDeleteProductClick(hailProduct);
  };

  function getValidNumber(value: Nullable<number>): number {
    return value !== null && !isNaN(value) ? value : 0;
  }

  const handleTownshipRangeClickOpen = () => {
    setTownshipRangeModalOpen(true);
  };

  const handleTownshipRangeClose = () => {
    setTownshipRangeModalOpen(false);
  };

  const handleAcresClickOpen = () => {
    setAcresModalOpen(true);
  };

  const handleAcresClose = () => {
    setAcresModalOpen(false);
  };

  const getNameRateMap = (isPerAcre: boolean) => {
    const endorsementsMap = getWeightedEndorsementRateMap(hailScenarioPieceEndorsements, availableHailEndorsements, availableHailRates, hailScenarioPieceRates, quote.quickQuote, unitYears, hailScenarioPieceUnits, isPerAcre);
    const genericMap: Map<string, number> = new Map();
    if (!isPerAcre) {
      genericMap.set(hailProduct.productName, weightedAverageBaseRate);
    }
    endorsementsMap.forEach((rate, endorsementId) => {
      const endorsementName = availableHailEndorsements.find(he => he.hailPlanEndorsementId === endorsementId)?.endorsementName ?? '';
      genericMap.set(endorsementName, rate);
    });
    return genericMap;
  };

  const per100Endorsements = getNameRateMap(false);
  const perAcreEndorsements = getNameRateMap(true);

  const getRateValue = (endorsementsMap: Map<string, number>) => {
    const endorsementsSum = sum(Array.from(endorsementsMap.values()), x => x);
    return Number(endorsementsSum.toFixed(3));
  };

  const ratePer100 = getRateValue(per100Endorsements);
  const ratePerAcre = getRateValue(perAcreEndorsements);

  const isUnitAcresButtonDisabled = quote.quickQuote || hailScenarioPiece.hailPlanType === HailPlanType.Production;
  const hailScenarioUnits = unitYears.filter(x => hailScenarioPieceUnits.includes(x.unitYearId));
  //We grab the first unit group only because we only support one unit group for hail
  const quickQuotingValuesMap = new Map<HailScenarioPieceRateId, UnitResponseDTO>();
  const calculatedUnitValues = calculatedValue?.unitGroupResponses[0]?.units.flatMap(u => { quickQuotingValuesMap.set(toPrimaryKey(u.id), u); return u; }) ?? [];
  const hailScenarioPieceRateAmounts = getScenarioPieceRateCalculatedValuesWhenUnitQuoting(hailScenarioPieceRates, availableHailRates, hailScenarioUnits, calculatedUnitValues);
  const totalAcres = quote.quickQuote ? sum(hailScenarioPieceRates, x => getValidNumber(x.acres)) : sum(Array.from(hailScenarioPieceRateAmounts.values()), x => x.acres);
  return (
    <>
      <div style={{ maxHeight: shouldLimitHeight ? 'calc(100vh - 435px)' : 'none', overflowY: 'auto' }}>
        <Grid container direction="column" rowGap={1} sx={{ p: '0 !important' }}>
          <Card sx={{ width: cardWidth }} raised>
            <CardHeader
              title={hailProduct.productName}
              titleTypographyProps={{ variant: 'subtitle2', sx: { textAlign: 'center', textWrap: 'wrap' } }}
              sx={{ p: '8px !important' }}
            />
            <CardContent sx={{ p: '8px !important', pt: '0 !important', pr: '4px !important' }}>
              <Grid container>
                <HailCardRow title="Standard/Production" value={hailScenarioPiece.hailPlanType === HailPlanType.Standard ? 'S' : 'P'} />
                <HailCardRow title="Year" value={hailProduct.cropYear} />
                <HailCardRow title="AIP" value={hailProduct.aipAbbreviation} />
                <HailCardRow title="Policy Type" value={hailProduct.policy?.policyTypeName ?? ''} />
                {!disableButtonsAndFields &&
                  <HailCardRow
                    title="Township/Range"
                    value={
                      <Button id="btn-twp-range" tabIndex={-1} size="small" sx={{ height: '25px' }} onClick={handleTownshipRangeClickOpen}>Details</Button>
                    } />
                }

                <Grid container alignItems="center" sx={{ pt: '0 !important' }}>
                  <Grid item xs={2}>
                    <Typography variant="subtitle1">Acres</Typography>
                  </Grid>
                  <Grid item xs={1} textAlign="right">
                    {!disableButtonsAndFields &&
                      <Tooltip title={isUnitAcresButtonDisabled ? 'Acres is only applicable when unit quoting and the plan is a standard plan' : ''}>
                        <div>
                          <IconButton onClick={handleAcresClickOpen} disabled={isUnitAcresButtonDisabled} tabIndex={-1} size="large" color="primary" sx={{ padding: '0 !important' }}>
                            <OpenInBrowserIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    }
                  </Grid>
                  <Grid item xs={9} textAlign="right">
                    <Typography variant="subtitle2">{totalAcres}</Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} columnGap={1} justifyContent="center">
                  <RateSummary value={ratePer100} title="Rate Per $100 Cov" rateRecordsMap={per100Endorsements} />
                  {ratePerAcre > 0 && (
                    <RateSummary value={ratePerAcre} title="Rate Per Acre" rateRecordsMap={perAcreEndorsements} />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <EndorsementsCard
            hailScenarioPiece={hailScenarioPiece}
            availableHailEndorsements={availableHailEndorsements}
            hailScenarioPieceEndorsements={hailScenarioPieceEndorsements}
            hailProduct={hailProduct}
            hailScenarioPieceRates={hailScenarioPieceRates}
            hailScenarioPieceUnits={hailScenarioPieceUnits}
            onHailScenarioPieceEndorsementsUpdated={onHailScenarioPieceEndorsementsUpdated}
            disableButtonsAndFields={disableButtonsAndFields} />
          <HailDetailsCard
            scenario={scenario}
            hailScenarioPiece={hailScenarioPiece}
            onDeleteHailScenarioPieceClick={deleteHailScenarioPiece}
            onHailScenarioPieceUpdated={onHailScenarioPieceUpdated}
            onHailScenarioPieceLossChanged={onHailScenarioPieceLossChanged}
            calculatedValues={calculatedValue}
            cardWidth={cardWidth}
            index={index}
            disableButtonsAndFields={disableButtonsAndFields}
          />
        </Grid>
      </div>
      <HailTownshipRangeModal
        hailScenarioPieceRates={hailScenarioPieceRates}
        availableHailRates={availableHailRates}
        onCloseClicked={handleTownshipRangeClose}
        showModal={townshipRangeModalOpen}
        hailProductIndex={index}
        totalCalculatedValues={calculatedValue}
        unitQuoteCalculatedValues={hailScenarioPieceRateAmounts}
        quickQuoteCalculatedValues={quickQuotingValuesMap}
        onHailScenarioPieceRatesUpdated={onHailScenarioPieceRatesUpdated} />

      <HailUnitsModal
        hailScenarioPiece={hailScenarioPiece}
        hailScenarioPieceUnits={hailScenarioPieceUnits}
        hailScenarioPieceRates={hailScenarioPieceRates}
        availableHailRates={availableHailRates}
        onCloseClicked={handleAcresClose}
        onHailScenarioPieceUnitsUpdated={onHailScenarioPieceUnitsUpdated}
        showModal={acresModalOpen}
        hailProductIndex={index}
        calculatedValue={calculatedValue}
        calculatedUnitValues={calculatedUnitValues} />
    </>
  );
};

export default HailCard;
