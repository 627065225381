import { Card, CardMedia, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import classNames from 'classnames';
import { selectAllTrendlines } from '../../../app/trendlineAnalysisSlice';
import './trendlineAnalysisScenarioCard.styles.css';
import { getColor } from '../../../utils/formatNumbers';

import { useTrendLineYields } from '../../../hooks/useTrendlineYields';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { average } from '../../../utils/arrayUtils';
import { selectQuoteById } from '../../../app/quotesSlice';
import { isUnitQuote } from '../../../utils/quoteUtils';
import { selectIsLightMode } from '../../../app/userSettingsSlice';
import { AcreageType } from '@silveus/calculations';

type TrendlineAnalysisScenarioCardProps = {
  isSelected?: boolean;
  scenario: RowCropScenario;
  onClick: () => void;
};

const TrendlineAnalysisScenarioCard = ({ scenario, onClick, isSelected = false }: TrendlineAnalysisScenarioCardProps) => {
  const trendlineAnalysis = useAppSelector(state => selectAllTrendlines(state).get(scenario.scenarioId));

  // These defaults are not ideal, but doing anything else would be very challenging.
  const startYear = trendlineAnalysis?.startYear ?? 0;
  const endYear = trendlineAnalysis?.endYear ?? 0;
  const acreageType = trendlineAnalysis?.acreageType ?? AcreageType.Historical;

  const sortedChartData = useTrendLineYields(scenario.scenarioId, startYear, endYear, acreageType);
  const quote = useAppSelector(state => selectQuoteById(state, scenario.quoteId));
  const isUnitQuotingOn = quote ? isUnitQuote(quote) : false;

  const allCountyYields = sortedChartData.filter(x => x.actualCountyYield);
  const allProducerYields = sortedChartData.filter(x => x.actualYield);
  const actualCountyYieldAverage = allCountyYields.length === 0 ? null : average(allCountyYields.map(x => x.actualCountyYield ?? 0));
  const producerYieldAverage = allProducerYields.length === 0 || !isUnitQuotingOn ? null : average(allProducerYields.map(x => x.actualYield ?? 0));

  const getDifference = () => {
    if (!isUnitQuotingOn || actualCountyYieldAverage === null || producerYieldAverage === null) {
      return null;
    }

    return producerYieldAverage - actualCountyYieldAverage;
  };

  return (
    <Grid container direction="column" onClick={onClick} sx={{ cursor: 'pointer' }} rowSpacing={0.5}>
      <Grid item xs="auto">
        <Tooltip title={scenario.name}>
          <Typography noWrap sx={{ color: theme => theme.palette.primary.main, maxWidth: '400px' }} variant="clickable1">{scenario.name}</Typography>
        </Tooltip>
      </Grid>
      <Grid item xs>
        <Card className={classNames({ 'trendline-analysis-card-box-shadow': isSelected })} sx={{ borderWidth: 0 }}>
          <CardMedia sx={{ height: '100%' }}>
            <Grid container height="100%">
              <Grid item xs="auto">
                <div style={{ width: '9px', height: '100%', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', backgroundColor: scenario.scenarioColor }}>
                </div>
              </Grid>
              <Grid item xs pt={1} pb={1} pl={4} pr={4}>
                <Grid container alignItems="center" justifyContent="space-around" columnSpacing={4}>
                  <Grid item xs="auto">
                    <TrendlineScenarioCardMetric heading="Avg Producer Yield" value={producerYieldAverage} />
                  </Grid>
                  <Grid item xs="auto">
                    <TrendlineScenarioCardMetric heading="Avg County Yield" value={actualCountyYieldAverage} />
                  </Grid>
                  <Grid item xs="auto">
                    <TrendlineScenarioCardMetric heading="Difference" value={getDifference()} colorize={true} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardMedia>
        </Card>
      </Grid>
    </Grid>
  );
};

type CardMetricProps = {
  heading: string;
  value: number | null;
  colorize?: boolean;
}

const TrendlineScenarioCardMetric = ({ heading, value, colorize }: CardMetricProps) => {
  const theme = useTheme();
  const isLightMode = useAppSelector(selectIsLightMode);
  const defaultColor = isLightMode ? theme.palette.common.black : theme.palette.common.white;
  const color = value === null || colorize === undefined || value === 0 ? defaultColor : getColor(value, theme);

  return (
    <Grid container spacing={0} direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <Typography variant="caption">{heading}</Typography>
      </Grid>
      <Grid item>
        <Typography color={color} variant="h4" >{value === null ? 'N/A' : value.toFixed(2)}</Typography>
      </Grid>
    </Grid>
  );
};

export default TrendlineAnalysisScenarioCard;