import { DefaultValues, FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';
import { Nullable } from '../../types/util/Nullable';

export function useScenarioPieceForm<TFieldValues extends FieldValues = FieldValues>(scenarioPiece: Nullable<ScenarioPiece>, defaultValues?: DefaultValues<TFieldValues>): UseFormReturn<TFieldValues> {
  const isScenarioPieceInvalid = scenarioPiece?.isInvalid ?? false;

  return useForm<TFieldValues>({
    defaultValues: defaultValues,
    mode: isScenarioPieceInvalid ? 'onChange' : 'onSubmit',
  });
}