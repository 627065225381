import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import { PersonOrBusiness } from '../../../types/api/enums/contactInfo/personOrBusiness';

type PersonOrBusinessTypeInputProps = {
  value: Nullable<PersonOrBusiness>;
  disabled: boolean;
};

export type PersonOrBusinessTypeFields = {
  personOrBusiness: Nullable<PersonOrBusiness>;
};

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => !Number.isNaN(k)) as K[];
}

const PersonOrBusinessTypeInput = ({ value, disabled }: PersonOrBusinessTypeInputProps) => {
  const { control } = useFormContext();

  const personOrBusinessTypes: string[] = enumKeys(PersonOrBusiness).filter(key => {
    return typeof PersonOrBusiness[key] !== 'string';
  });

  return (
    <Controller
      control={control}
      name="personOrBusiness"
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={value ?? ''}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <TextField
          {...field}
          disabled={disabled}
          type="text"
          label="Person or Business"
          fullWidth
          select
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          size="small"
          onChange={e => field.onChange(e.target.value)}>
          {personOrBusinessTypes.map((key: string, index) => {
            return (
              <MenuItem key={key} value={PersonOrBusiness[key as keyof typeof PersonOrBusiness]}>
                {key}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
};

export default PersonOrBusinessTypeInput;
