import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { ScenarioId, UnitYearId } from '../types/api/PrimaryKeys';
import { RowCropScenarioPiece } from '../types/api/RowCropScenarioPiece';
import { OptionCode } from '@silveus/calculations';
import { OptionByEuType } from '../types/app/OptionByEuType';
import {
  MissingClientFileInStateError,
  MissingPracticeInScenarioError,
  MissingQuoteInStateError,
  MissingScenarioInStateError
} from '../errors/state/MissingStateErrors';
import { OptionLevelCodes } from '../constants/optionLevelCodes';
import { setScenarioOptionsWithOptionState } from '../app/optionsSlice';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { selectScenarioById, selectUnitYears } from '../app/scenariosSlice';
import { selectQuoteById } from '../app/quotesSlice';
import { selectClientFileById } from '../app/clientFilesSlice';
import { useOptions } from '../hooks/useOptions';
import OptionState from '../types/app/OptionState';
import { OptionSelectionState } from '../types/app/enums/optionSelectionState.enum';

interface OptionsForSpFormContextProviderProps {
  scenarioId: ScenarioId;
  children: ReactNode;
}

interface OptionsForSpFormContextState {
  scenarioOptionsForSpForm: OptionState[];
  updateAvailableOptionsBasedOnSpChanges: (updatedScenarioPiece: RowCropScenarioPiece) => void;
  updateOptionSelection: (optionCode: OptionCode) => void;
  updateEuByOptionSelection: (byEuSelection: OptionByEuType) => void;
  persistNewOptionSelections: () => Promise<void>;
}

export const OptionsForSpFormContext = createContext<OptionsForSpFormContextState | null>(null);

export const OptionsForSpFormContextProvider = ({ children, scenarioId }: OptionsForSpFormContextProviderProps) => {
  const dispatch = useAppDispatch();
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  if (scenario === null) throw new MissingScenarioInStateError(scenarioId);

  const quote = useAppSelector(state => selectQuoteById(state, scenario.quoteId));
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = useAppSelector(state => selectClientFileById(state, quote.clientFileId));
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  const { getOptionStatesForScenario, getOptionStatesForUnits, getOptionStatesForScenarioProps } = useOptions(quote.quoteId, scenario.scenarioId);
  const [scenarioOptionsForSpForm, setScenarioOptionsForSpForm] = useState(getOptionStatesForScenario());
  const scenarioUnitYearsFromState = useAppSelector(state => selectUnitYears(state, scenario.quoteId, scenario.typeId, scenario.practiceId, scenario.highRiskTypeId));

  const updateAvailableOptionsBasedOnSpChanges = useCallback((updatedScenarioPiece: RowCropScenarioPiece) => {
    if (scenario.practiceId === null) throw new MissingPracticeInScenarioError(scenario.scenarioId);
    const scenarioPieceMap = new Map<string, RowCropScenarioPiece>([
      [updatedScenarioPiece.planCode, updatedScenarioPiece],
    ]);
    const newOptionState = getOptionStatesForScenarioProps(
      scenario.typeId,
      scenario.practiceId,
      scenarioPieceMap,
      scenarioUnitYearsFromState,
    );

    const newOptionStateWithExistingChanges = newOptionState.map(newOption => {
      const existingOptionState = scenarioOptionsForSpForm
        .find(existingOption => newOption.option.optionCode === existingOption.option.optionCode);
      return {
        ...newOption,
        selectionState: existingOptionState !== undefined ? existingOptionState.selectionState : newOption.selectionState,
      };
    });
    setScenarioOptionsForSpForm(newOptionStateWithExistingChanges);
  }, [scenario, scenarioOptionsForSpForm, scenarioUnitYearsFromState, getOptionStatesForScenarioProps]);

  const updateOptionSelection = useCallback((optionCode: OptionCode) => {
    setScenarioOptionsForSpForm(prevOptions => {
      const newOptionSelections = [...prevOptions];
      const currentOptionState = newOptionSelections.find(o => o.option.optionCode === optionCode);
      if (currentOptionState === undefined) return prevOptions;

      const currentIndex = newOptionSelections.indexOf(currentOptionState);
      newOptionSelections[currentIndex] = {
        ...newOptionSelections[currentIndex],
        selectionState: currentOptionState.selectionState !== OptionSelectionState.On ? OptionSelectionState.On : OptionSelectionState.Off,
      };
      return newOptionSelections;
    });
  }, []);

  const updateEuByOptionSelection = useCallback((byEuSelection: OptionByEuType) => {
    setScenarioOptionsForSpForm(prevOptions => {
      const newOptionSelections = [...prevOptions];
      const euOptions = newOptionSelections.filter(o => o.option.optionLevelCode === OptionLevelCodes.EnterpriseUnit);
      for (const euOption of euOptions) {
        const currentIndex = newOptionSelections.findIndex(o => o.option.optionCode === euOption.option.optionCode);
        if (currentIndex === -1) continue;
        newOptionSelections[currentIndex] = {
          ...newOptionSelections[currentIndex],
          selectionState: euOption.option.optionCode.toString() === byEuSelection.toString() ? OptionSelectionState.On : OptionSelectionState.Off,
        };
      }
      return newOptionSelections;
    });
  }, []);

  const persistNewOptionSelections = useCallback(async () => {
    const oldUnitOptionMap = getOptionStatesForUnits();
    const newUnitOptionStateMap = new Map<UnitYearId, OptionState[]>();
    for (const [unitYearId, unitOptionStateArray] of oldUnitOptionMap) {
      const newUnitOptionStateArray = scenarioOptionsForSpForm.map(scenarioOption => {
        const existingOptionState = unitOptionStateArray
          .find(existingOption => scenarioOption.option.optionCode === existingOption.option.optionCode);
        return {
          ...scenarioOption,
          selectionState: existingOptionState !== undefined && scenarioOption.selectionState === OptionSelectionState.Partial ? existingOptionState.selectionState : scenarioOption.selectionState,
        };
      });
      newUnitOptionStateMap.set(unitYearId, newUnitOptionStateArray);
    }

    await dispatch(setScenarioOptionsWithOptionState({ scenarioId: scenario.scenarioId, scenarioOptionState: scenarioOptionsForSpForm, scenarioOptionUnitYearState: newUnitOptionStateMap }));
  }, [dispatch, scenario.scenarioId, scenarioOptionsForSpForm, getOptionStatesForUnits]);

  const value = useMemo(() => ({
    scenarioOptionsForSpForm,
    updateAvailableOptionsBasedOnSpChanges,
    updateOptionSelection,
    updateEuByOptionSelection,
    persistNewOptionSelections,
  }), [scenarioOptionsForSpForm, updateAvailableOptionsBasedOnSpChanges, updateOptionSelection, updateEuByOptionSelection, persistNewOptionSelections]);

  return (
    <OptionsForSpFormContext.Provider value={value}>
      {children}
    </OptionsForSpFormContext.Provider>
  );
};