import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { validatePhoneNumberString } from '../../ag-grid/contactInfoGrid/validation/validateContactInfo';

type InsuredPhoneNumberInputProps = {
  value: Nullable<string>;
  disabled: boolean;
  required?: boolean;
  name?: string;
}

export type InsuredPhoneNumberFields = {
  phone: Nullable<string>;
}

const InsuredPhoneNumberInput = ({ value, disabled, required = true, name = 'phone' }: InsuredPhoneNumberInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (phone: Nullable<string>) => validatePhoneNumberString(phone, required) }}
      defaultValue={value ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="phone"
          label="Phone"
          fullWidth
          disabled={disabled}
          inputProps={{ maxLength: 15 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          size="small"
        />
      )}
    />
  );
};

export default InsuredPhoneNumberInput;

