import { Autocomplete, CircularProgress, FilterOptionsState, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import { Insured, InsuredAddress } from '../../types/api/insureds/Insured';
import { fuzzySearchMultipleWords } from '../../utils/searchUtils';
import { matchSorter } from 'match-sorter';
import { Nullable } from '../../types/util/Nullable';
import { formatCityStateZip } from '../../utils/formatAddress';
import { isNilOrEmpty } from '../../utils/nullHandling';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';

interface InsuredFilterProps {
  loading: boolean;
  insureds: Insured[];
  filterInsureds: (insureds: Insured[]) => void;
}

const filterOptions = (options: Insured[], { inputValue }: FilterOptionsState<Insured>) => fuzzySearchMultipleWords(options, inputValue, [
  { key: 'name', threshold: matchSorter.rankings.ACRONYM },
  { key: 'address.state', threshold: matchSorter.rankings.EQUAL },
  { key: 'address.city', threshold: matchSorter.rankings.CONTAINS, maxRanking: matchSorter.rankings.WORD_STARTS_WITH },
  { key: 'address.postalCode', threshold: matchSorter.rankings.CONTAINS },
  { key: 'phone', threshold: matchSorter.rankings.CONTAINS },
]);

const formatAddress = (address: Nullable<InsuredAddress>) => {
  const formattedAddress = formatCityStateZip(address);
  return isNilOrEmpty(formattedAddress) ? '' : `- ${formattedAddress}`;
};

const formatPhone = (phone: Nullable<string>) => {
  return isNilOrEmpty(phone) ? '' : `- ${formatPhoneNumber(phone)}`;
};

const InsuredFilter = ({ loading, insureds, filterInsureds }: InsuredFilterProps) => {
  const handleInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
    const getOptionLabel = (insured: Insured) => `${insured.name} ${formatAddress(insured.address)} ${formatPhone(insured.phone)}`.trim().toUpperCase();
    const filteredInsureds = filterOptions(insureds, { inputValue: value, getOptionLabel });
    filterInsureds(filteredInsureds);
  };

  return (
    <Autocomplete
      open={false}
      clearOnBlur={false}
      handleHomeEndKeys={false}
      forcePopupIcon={false}
      autoComplete={true}
      clearOnEscape={true}
      disabled={loading}
      loading={loading}
      filterOptions={filterOptions}
      onInputChange={handleInputChange}
      noOptionsText={'No match found'}
      options={insureds}
      renderInput={params =>
        <TextField
          {...params}
          variant="outlined"
          label="Search"
          sx={{ background: theme => theme.palette.other.inputBackground }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading
                  ? <CircularProgress color="inherit" size={20} />
                  :
                  <Stack direction="row" alignItems="center" gap={1}>
                    <SearchIcon />
                  </Stack>
                }
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      }
    />
  );
};

export default InsuredFilter;