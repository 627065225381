import DifferenceIcon from '@mui/icons-material/Difference';
import { Box, MenuItem } from '@mui/material';
import NavigationLink from '../navigation/navigationLink.component';
import { usePopoutActions } from '../actions/usePopoutActions';
import { InsuredId } from '../../types/api/PrimaryKeys';
import { QuoteSummaryReportGenerationModal } from './quoteSummaryReportGenerationModal';
import { useState } from 'react';
import { TriggerYieldReportGenerationModal } from './triggerYieldReportGenerationModal';
import { ActualProductionReportGenerationModal } from './actualProductionReportGenerationModal';

interface ReportModalButtonProps {
  insuredId: InsuredId;
}

export const ReportModalButton = ({ insuredId }: ReportModalButtonProps) => {
  const [quoteSummaryOpen, setQuoteSummaryOpen] = useState(false);
  const [triggerYieldOpen, setTriggerYieldOpen] = useState(false);
  const [actualProductionOpen, setActualProductionOpen] = useState(false);

  const menuActions = [
    <MenuItem key={1} id="quote-summary-modal-button" onClick={() => setQuoteSummaryOpen(true)}>Quote Summary</MenuItem>,
    <MenuItem key={2} id="trigger-yield-modal-button" onClick={() => setTriggerYieldOpen(true)}>Trigger Yield</MenuItem>,
    <MenuItem key={3} id="actual-production-modal-button" onClick={() => setActualProductionOpen(true)}>Actual Production</MenuItem>,
  ];

  const { onClick, anchorRef, popoutActions, open } = usePopoutActions({ children: menuActions, id: insuredId });

  return (
    <Box ref={anchorRef}>
      <NavigationLink
        isSelected={false}
        click={onClick}
        icon={<DifferenceIcon />}
        text="Reports"
        aria-controls={open ? `action-menu-${insuredId}` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
      />
      {popoutActions}
      {quoteSummaryOpen && <QuoteSummaryReportGenerationModal open={quoteSummaryOpen} setOpen={setQuoteSummaryOpen} />}
      {triggerYieldOpen && <TriggerYieldReportGenerationModal open={triggerYieldOpen} setOpen={setTriggerYieldOpen} />}
      {actualProductionOpen && <ActualProductionReportGenerationModal open={actualProductionOpen} setOpen={setActualProductionOpen} />}
    </Box>
  );
};