import { Button } from '@mui/material';

interface SummaryButtonProps {
  onClick: () => void;
}

const SummaryButton = ({ onClick }: SummaryButtonProps) => {
  return (
    <Button size="small" onClick={onClick}>Summary</Button>
  );
};

export default SummaryButton;