import {
  selectAllInsuranceCalendars,
  selectCeppPractice,
  selectCeppType,
  selectCounty,
  selectAllRmaPriceDiscoveries,
  selectPriceGroupMember, selectPriceGroups, selectOfferProjectedPrice, selectOfferHarvestPrice
} from './admSlice';
import { selectRowCropScenarioPiecesByScenarioId } from './scenarioPiecesSlice';
import { RootState } from './store';
import { ScenarioPieceType, InsurancePlanCode } from '@silveus/calculations';
import { getInsurancePlanCodeForScenarioPiece } from '../utils/scenarioPieceUtils';
import { getKeyedStateToMap } from './sliceHelpers';
import { getItemsForId } from '../utils/mapHelpers';
import { buildCountyAndCommodityKey } from '../types/app/CompositeKeys';
import { scenarioPieceOrderingServiceInstance } from '../utils/scenarioOrderingServiceWrappers';
import { ScenarioId } from '../types/api/PrimaryKeys';
import { getDiscoveryPeriod, getMpActualDiscoveryPeriod, PriceDiscoveryPeriod } from '../utils/discoveryPeriodUtils';
import { Nullable } from '../types/util/Nullable';

export const selectRmaPriceDiscoveryForScenario = (
  state: RootState,
  year: number,
  countyId: string,
  scenarioId: Nullable<ScenarioId>,
  commodityCode: string,
  typeId: string,
  practiceId: string) => {

  if (scenarioId === null) {
    return null;
  }

  // get necessary inputs
  const priceDiscoveries = selectAllRmaPriceDiscoveries(state);
  const cropType = selectCeppType(state, typeId);
  if (cropType === undefined) {
    return null;
  }
  const cropPractice = selectCeppPractice(state, practiceId);
  if (cropPractice === undefined) {
    return null;
  }
  const county = selectCounty(state, countyId);
  if (county === undefined) {
    return null;
  }

  const salesClosingDateString = selectSalesClosingDateStringForScenario(state, scenarioId, countyId, commodityCode, typeId, practiceId);
  if (salesClosingDateString === null) {
    return null;
  }

  const salesClosingDate = new Date(salesClosingDateString);

  // prep get price discovery
  const priceDiscoveriesMap = getKeyedStateToMap(priceDiscoveries);
  const priceDiscoveriesForCommodity = getItemsForId(priceDiscoveriesMap, commodityCode);

  // get price discovery
  const result =
    priceDiscoveriesForCommodity.find(x =>
      x.commodityCode === commodityCode
      && x.ceppTypeCode === cropType.ceppTypeCode
      && x.ceppPracticeCode === cropPractice.ceppPracticeCode
      && x.stateCode === county.stateCode
      && x.commodityYear === year
      && (new Date(x.salesClosingDateDisplay)).getTime() === salesClosingDate.getTime());

  return result ?? null;
};

export const selectSalesClosingDateStringForScenario = (
  state: RootState,
  scenarioId: ScenarioId,
  countyId: string,
  commodityCode: string,
  typeId: string,
  practiceId: string) =>
{
  const cropType = selectCeppType(state, typeId);
  if (cropType === undefined) { return null; }

  const cropPractice = selectCeppPractice(state, practiceId);
  if (cropPractice === undefined) { return null; }

  const insuranceCalendarsMap = getKeyedStateToMap(selectAllInsuranceCalendars(state));
  const insuranceCalendars = getItemsForId(insuranceCalendarsMap, buildCountyAndCommodityKey(countyId, commodityCode));

  // find appropriate plan code
  const planCodeToUse = selectPrimaryPlanCodeOfScenario(state, scenarioId);

  // find appropriate insurance calendar
  const possibleInsuranceCalendars = insuranceCalendars.filter(x =>
    x.countyId === countyId
    && x.typeId === cropType.typeID
    && x.practiceId === cropPractice.practiceID).sort((a, b) => a.insurancePlanCode.localeCompare(b.insurancePlanCode));

  // pick the plan code if one found/provided, else pick first
  let insuranceCalendar = possibleInsuranceCalendars.find(x => planCodeToUse === undefined || x.insurancePlanCode === planCodeToUse);
  if (insuranceCalendar === undefined) {
    insuranceCalendar = possibleInsuranceCalendars.at(0);
  }

  if (insuranceCalendar === undefined || insuranceCalendar.salesClosingDate === null) {
    return null;
  }

  return insuranceCalendar.salesClosingDate;
};

export const selectPrimaryPlanCodeOfScenario = (
  state: RootState,
  scenarioId: Nullable<ScenarioId>) => {

  const rowCropScenarioPieces = selectRowCropScenarioPiecesByScenarioId(state, scenarioId);
  const scenarioPieceTypes: ScenarioPieceType[] = scenarioPieceOrderingServiceInstance.getOptionalBaseScenarioPieces([]);
  const orderedPlanCodesFallback = scenarioPieceTypes.map(x => getInsurancePlanCodeForScenarioPiece(x)).filter(x => x !== '').sort();
  const inScenarioPlanCodes = rowCropScenarioPieces.map(x => x.planCode).filter(x => x !== '');
  return orderedPlanCodesFallback.find(x => inScenarioPlanCodes.includes(x));
};

export const selectDiscoveryPeriod = (
  state: RootState,
  dateToCompare: Date,
  isHarvestPrice: boolean,
  year: number,
  countyId: string,
  scenarioId: Nullable<ScenarioId>,
  commodityCode: string,
  typeId: string,
  practiceId: string): PriceDiscoveryPeriod => {

  const planCodeToUse = selectPrimaryPlanCodeOfScenario(state, scenarioId);

  if (planCodeToUse === InsurancePlanCode.MP) {
    return getMpActualDiscoveryPeriod(dateToCompare, commodityCode, year);
  }

  const priceGroupMember = selectPriceGroupMember(state, countyId, commodityCode, typeId, practiceId);
  const priceGroups = selectPriceGroups(state);
  const priceGroup = priceGroups.find(x => x.priceGroupID === priceGroupMember?.priceGroupID);
  const rmaPriceDiscovery = selectRmaPriceDiscoveryForScenario(state, year, countyId, scenarioId, commodityCode, typeId, practiceId);
  const projectedPrice = selectOfferProjectedPrice(state);
  const harvestPrice = selectOfferHarvestPrice(state);

  return getDiscoveryPeriod(dateToCompare, isHarvestPrice, projectedPrice, harvestPrice, commodityCode, priceGroup, rmaPriceDiscovery);
};