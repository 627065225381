import { Box, Container, CssBaseline, Typography } from '@mui/material';
import { loginBox } from './authenticated.styles';

interface Props {
  message: string;
}

export const Loading = ({ message }: Props) => (
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box sx={loginBox}>
      <Typography component="h1" variant="h5">
        {message}
      </Typography>
    </Box>
  </Container>
);