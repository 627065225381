import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { Nullable } from '../../../types/util/Nullable';
import { onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { FormInputParameterNames } from '../constants/inputConstants';

export interface ProtectionFactorProps {
  protectionFactor: Nullable<number>;
  autoFocus?: boolean;
  disabled?: boolean;
  defaultValue?: Nullable<number>;
  fieldName?: string;
  label?: string;
}

export interface ProtectionFactorFields {
  protectionFactor: number;
}

export const ProtectionFactorInput = ({
  protectionFactor,
  upperProtectionFactor,
  lowerProtectionFactor,
  label = 'Protection Factor',
  fieldName = FormInputParameterNames.ProtectionFactor,
  defaultValue = null,
  autoFocus = false,
  disabled = false }: ProtectionFactorProps & {
    upperProtectionFactor: number,
    lowerProtectionFactor: number
  }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ required: VALIDATION_RULES.required(), min: VALIDATION_RULES.minimum(lowerProtectionFactor), max: VALIDATION_RULES.maximum(upperProtectionFactor) }}
      defaultValue={(protectionFactor ?? defaultValue) ?? upperProtectionFactor}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="number"
          label={label}
          fullWidth
          value={value}
          inputProps={{ step: 1, min: lowerProtectionFactor, max: upperProtectionFactor }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        />
      )}
    />
  );
};
