import { Controller, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { Nullable } from '../../../types/util/Nullable';
import { CoverageLevelPair } from '../../../types/api/adm/CoverageLevelPair';
import { onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { useEffect, useState } from 'react';
import { FormInputParameterNames } from '../constants/inputConstants';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';

export interface LowerCoverageLevelProps {
  lowerCoverageLevel: Nullable<number>;
  autoFocus?: boolean;
  disabled?: boolean;
  coverageLevelPairings?: CoverageLevelPair[] | null;
}

export interface LowerCoverageLevelFields extends FieldValues {
  lowerCoverageLevel: number;
}

export const LowerCoverageLevelInput = ({
  lowerCoverageLevel,
  availableCoverageLevels,
  autoFocus = false,
  coverageLevelPairings = null,
  disabled = false,
}: LowerCoverageLevelProps & { availableCoverageLevels: number[] }) => {

  const { control, setValue, getValues } = useFormContext();
  const upperCoverage = getValues(FormInputParameterNames.UpperCoverageLevelName);
  const selectedUpperCoverageLevel = useWatch({ name: FormInputParameterNames.UpperCoverageLevelName, control: control, defaultValue: undefined });
  const [useableCoverageLevels, setUseableCoverageLevels] = useState<number[]>([]);

  useEffect(() => {
    let useableValues: number[];
    const validOtherPairing = isNotNullOrUndefined(upperCoverage) && coverageLevelPairings !== null && coverageLevelPairings.map(x => x.upperCoverageLevel).includes(upperCoverage);
    const validOtherAvailableLevels = isNotNullOrUndefined(upperCoverage) && availableCoverageLevels.includes(upperCoverage);
    if (coverageLevelPairings && validOtherPairing) {
      // using pairing and the other number is valid and in that list
      useableValues = coverageLevelPairings.filter(x => x.upperCoverageLevel === upperCoverage).map(x => x.lowerCoverageLevel);
    } else if (coverageLevelPairings) {
      // using pairing but the other number is invalid or not in the list
      useableValues = coverageLevelPairings.map(x => x.lowerCoverageLevel);
    } else if (validOtherAvailableLevels) {
      // ("not quite" original functionality) other number is valid, use the availble list grab all less than the other
      // previously, lower could have any selected, and upper was restricted based on this
      useableValues = availableCoverageLevels.filter(x => x < upperCoverage);
    } else {
      // fall back, just present all options
      useableValues = availableCoverageLevels;
    }

    setUseableCoverageLevels([...new Set(useableValues)].sort((a, b) => b - a));
  }, [coverageLevelPairings, availableCoverageLevels, selectedUpperCoverageLevel]);

  useEffect(() => {
    if (useableCoverageLevels.length === 1 && lowerCoverageLevel !== useableCoverageLevels[0]) {
      setValue(FormInputParameterNames.LowerCoverageLevelName, useableCoverageLevels[0], { shouldDirty: true });
    }
  }, [useableCoverageLevels]);

  return (
    <Controller
      name={FormInputParameterNames.LowerCoverageLevelName}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.restrictNotOnListValue(useableCoverageLevels),
      }}
      defaultValue={lowerCoverageLevel ?? ''}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="number"
          label="Lower Coverage Level"
          fullWidth
          select
          value={value}
          inputProps={{ min: 0, max: 100 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {useableCoverageLevels.map(cl => <MenuItem key={cl} value={cl.toFixed(0)}>{cl.toFixed(0)}</MenuItem>)}
        </TextField>
      )}
    />
  );
};
