
import { MatrixId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import { MatrixPresetForm } from './matrixPresetForm.component';
import { DefaultFormSelection, FormObject, FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import DropdownSelectFormWrapper from '../../../components/formWrapper/dropdownSelectFormWrapper.component';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { getDisplayNameForMatrixPreset } from '../../../utils/matrixPreset.utils';
import { selectMatrixPresets } from '../../../app/userSettingsSlice';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';

export type MatrixPresetsFormWrapperProps = FormWrapperProps & {
  matrixId: Nullable<MatrixId>;
  defaultFormSelection?: DefaultFormSelection;
}


const MatrixPresetsFormWrapper = ({ matrixId, defaultFormSelection, ...formWrapperProps }: MatrixPresetsFormWrapperProps) => {
  const allPresets = useAppSelector(selectMatrixPresets);

  const forms: FormObject[] = allPresets.map(preset => ({ formName: getDisplayNameForMatrixPreset(preset), formComponent: (props: FormWrapperProps) => <MatrixPresetForm key={preset.matrixPresetId} matrixPresetId={preset.matrixPresetId} {...props} /> }));
  const newPresetForm = (props: FormWrapperProps) => <MatrixPresetForm key={generatePrimaryKey()} matrixPresetId={null} {...props} />;
  return (
    <DropdownSelectFormWrapper
      forms={forms}
      defaultFormSelection={defaultFormSelection}
      newItemForm={newPresetForm}
      noOptionsText="No Matrix Presets available"
      label="Presets"
      {...formWrapperProps} />
  );
};

export default MatrixPresetsFormWrapper;