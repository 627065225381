import { ScenarioId, ScenarioPieceGroupId, ScenarioPieceGroupMemberId } from '../types/api/PrimaryKeys';
import { ScenarioPieceGroup, ScenarioPieceGroupMember } from '../types/api/ScenarioPieceGroup';
import axios from 'axios';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { envBaseApiUrl } from '../constants/envConstants';

export const getScenarioPieceGroupsForScenario = (scenarioId: ScenarioId) => axios.get<ScenarioPieceGroup[]>(`${envBaseApiUrl}/scenario-piece-groups/scenarios/${scenarioId}`);

export const getScenarioPieceGroupsForScenarios = (scenarioIds: ScenarioId[]) => axios.post<ScenarioPieceGroup[]>(`${envBaseApiUrl}/scenario-piece-groups/scenarios`, scenarioIds);

export const createScenarioPieceGroup = (scenarioPieceGroup: ScenarioPieceGroup) => axios.post<CreatedItemResult<ScenarioPieceGroupId>>(`${envBaseApiUrl}/scenario-piece-groups`, scenarioPieceGroup);

export const updateScenarioPieceGroup = (scenarioPieceGroup: ScenarioPieceGroup) => axios.put<void>(`${envBaseApiUrl}/scenario-piece-groups`, scenarioPieceGroup);

export const deleteScenarioPieceGroup = (scenarioPieceGroupId: ScenarioPieceGroupId) => axios.delete<void>(`${envBaseApiUrl}/scenario-piece-groups/members/${scenarioPieceGroupId}`);

export const createScenarioPieceGroupMember = (scenarioPieceGroupMember: ScenarioPieceGroupMember) => axios.post<CreatedItemResult<ScenarioPieceGroupMemberId>>(`${envBaseApiUrl}/scenario-piece-groups/members`, scenarioPieceGroupMember);

export const deleteScenarioPieceGroupMember = (scenarioPieceGroupMemberId: ScenarioPieceGroupMemberId) => axios.delete<void>(`${envBaseApiUrl}/scenario-piece-groups/members/${scenarioPieceGroupMemberId}`);