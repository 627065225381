import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { useApplicationsModal } from './useApplicationsModal';
import ApplicationsWizard from './applicationsWizard';
import { ApplicationWizardSteps } from './wizardSteps/applicationWizardStep';
import { selectClientFileById } from '../../app/clientFilesSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import CloseIcon from '@mui/icons-material/Close';
import './applicationModal.styles.css';

const ApplicationsModal = () => {
  const {
    handleClose,
    selectedTabIndex,
    navigateToPreviousStep,
    navigateToNextStep,
    navigateToTabAtIndex,
    application,
    updateApplication,
    updateIsInEditMode,
    isInEditMode,
    isLoading,
    updateIsLoading,
  } = useApplicationsModal();

  const selectedTab = ApplicationWizardSteps[selectedTabIndex];
  const clientFile = useAppSelector(state => application ? selectClientFileById(state, application.clientFileId) : null);

  return (
    <Dialog
      id="application-wizard-modal"
      open
      onClose={(_, reason) => handleClose(reason)}
      maxWidth={false}
      scroll="body"
      fullScreen
      sx={{ m: 2 }}
      disableEscapeKeyDown={true}
    >
      {application && (
        <>
          <DialogTitle>
            <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              {`App Wizard > ${clientFile?.name} > ${selectedTab.title}`}
              <IconButton disabled={isInEditMode || isLoading} onClick={() => handleClose()} size="small" sx={{ position: 'absolute', right: 16, top: 16 }}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ p: 1, pr: 0, flexDirection: 'column', height: 'calc(100% - 64px)', overflow: 'hidden' }}>
            <ApplicationsWizard
              selectedTabIndex={selectedTabIndex}
              navigateToPreviousStep={navigateToPreviousStep}
              navigateToNextStep={navigateToNextStep}
              app={application}
              updateIsInEditMode={updateIsInEditMode}
              updateApplication={updateApplication}
              isInEditMode={isInEditMode}
              isLoading={isLoading}
              updateIsLoading={updateIsLoading}
              navigateToTabAtIndex={navigateToTabAtIndex}
            />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default ApplicationsModal;
