import { Grid } from '@mui/material';
import { ReactElement } from 'react';

interface Props {
  scenarioPieceChips: ReactElement | undefined;
  summaryContent: ReactElement | undefined;
}

export const ScenarioComponentResultSummaryAdditional = ({ scenarioPieceChips, summaryContent }: Props) => {
  return (
    <>
      {scenarioPieceChips &&
        <Grid container item xs={12} alignItems="left" sx={{ paddingTop: '0 !important' }}>
          <Grid item>
            {scenarioPieceChips}
          </Grid>
        </Grid>
      }
      {summaryContent &&
        <Grid item xs={12}>
          {summaryContent}
        </Grid>
      }
    </>
  );
};