import EntityDiff from '../entityDiffing/entityDiff';
import { getFriendlyScenarioPieceName } from '../../../utils/scenarioPieceUtils';
import { UnitStructureAttributes, UnitStructureCode } from '@silveus/calculations';
import DataLocationType from '../constants/dataLocationType';
import { NamedReconciliationStack } from '../../../types/app/ReconciliationTrackedEntity';

type StackType = NamedReconciliationStack<'rowCropScenarioPieces'>;

interface RowCropScenarioPieceDiffProps {
  rowCropScenarioPiece: StackType;
  reconciledRowCropScenarioPiece: StackType;
  onReconciliationTypeChange: (entity: StackType) => void;
  dataLocation: DataLocationType;
}

const RowCropScenarioPieceDiff = ({ rowCropScenarioPiece, reconciledRowCropScenarioPiece, onReconciliationTypeChange, dataLocation }: RowCropScenarioPieceDiffProps) => {
  const scenarioPiece = rowCropScenarioPiece.entity;

  const coverageLevel = scenarioPiece.lowerCoverageLevel === 0
    ? `${scenarioPiece.upperCoverageLevel}%`
    : `${scenarioPiece.upperCoverageLevel} - ${scenarioPiece.lowerCoverageLevel}%`;
  const unitStructureText = ![UnitStructureCode.Unset, UnitStructureCode.AU].includes(scenarioPiece.unitStructure)
    ? UnitStructureAttributes[scenarioPiece.unitStructure].name
    : null;

  const detailComponents = [
    getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType),
    unitStructureText,
    coverageLevel,
  ];

  return (
    <EntityDiff
      title="Row Crop Scenario Pieces"
      subEntitySelector={() => []}
      trackedEntity={reconciledRowCropScenarioPiece}
      subsetEntity={rowCropScenarioPiece}
      detailComponents={detailComponents}
      onReconciliationTypeChange={onReconciliationTypeChange}
      preventCollapse
      dataLocation={dataLocation}
      preventSelection
      hideHeader
    />
  );
};

export default RowCropScenarioPieceDiff;
