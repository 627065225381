import EditIcon from '@mui/icons-material/Edit';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import { openDrawer } from '../../../../../../app/appDrawerSlice';
import { selectSelectedHistoricalScenarioId } from '../../../../../../app/scenarioAnalysisSlice';
import { selectScenarioById } from '../../../../../../app/scenariosSlice';

export const FirstGroupingHeaderGroupComponent = () => {
  const dispatch = useAppDispatch();
  const selectedScenarioId = useAppSelector(selectSelectedHistoricalScenarioId);
  const scenario = useAppSelector(s => selectedScenarioId === null ? null : selectScenarioById(s, selectedScenarioId));

  if (scenario === null) {
    return null;
  }

  const handleClick = () => {
    dispatch(openDrawer({ formName: 'historicalQuoteForm', primaryScenarioId: selectedScenarioId, quoteId: scenario.quoteId }));
  };

  return (
    <EditIcon onClick={handleClick} />
  );
};