import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { envInsightsDisabled, envInsightsKey } from './constants/envConstants';
import { msalInstance } from './authConfig';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true,
  },
};
const appInsights = new ApplicationInsights({
  config: {
    disableTelemetry: envInsightsDisabled === 'true' ? true : false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    disableFetchTracking: false,
    autoTrackPageVisitTime: true,
    enableAutoRouteTracking: true,
    enableAjaxPerfTracking: true,
    disableAjaxTracking: false,
    enableUnhandledPromiseRejectionTracking: true,
    disableCorrelationHeaders: false,
    enableCorsCorrelation: true,
    distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
    instrumentationKey: envInsightsKey,
    excludeRequestFromAutoTrackingPatterns: ['api.openweathermap.org'],
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
});

appInsights.loadAppInsights();

const addUserMetadata = (envelope: ITelemetryItem) => {
  const accounts = msalInstance.getAllAccounts();
  const email = accounts[0]?.username;

  envelope.data ??= {};
  envelope.data.userEmail = email;
};
appInsights.addTelemetryInitializer(addUserMetadata);

export { reactPlugin, appInsights };

