import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../../types/util/Nullable';
import NumberTextField, { onNumericInputChange } from '../../../numberTextField/numberTextField.component';
import { FormInputProps } from '../../scenario/formInputProps';
import { validateDollarAmount } from '../../../../pages/units/validations/genericDollarAmountValidation';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

interface PrimeFloorProps extends FormInputProps {
  primeDollarFloor: Nullable<number>;
}

export type PrimeFloorFields = {
  primeDollarFloor: number;
}

const PrimeFloorInput = ({ primeDollarFloor, sx, autoFocus = false, disabled = false }: PrimeFloorProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.PrimeFloor}
      control={control}
      rules={{ validate: (value: Nullable<number>) => validateDollarAmount(value) }}
      defaultValue={primeDollarFloor ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (

        <NumberTextField
          {...field}
          type="number"
          label="Prime $ Floor"
          fullWidth
          inputProps={{ step: 0.01, min: 0 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          sx={sx}
          onChange={e => onNumericInputChange(e, field, 2)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default PrimeFloorInput;
