export const ScenarioAnalysisTabs = {
  matrix: {
    label: 'Matrix',
    index: 0,
  },
  historical: {
    label: 'Historical',
    index: 1,
  },
  trendlines: {
    label: 'Trendlines',
    index: 2,
  },
  premium: {
    label: 'Premium Breakdown',
    index: 3,
  },
} as const;

export type ScenarioAnalysisTabIndex = typeof ScenarioAnalysisTabs[keyof typeof ScenarioAnalysisTabs]['index'];