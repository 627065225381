import { ScenarioPieceType, UnitStructureAttributes } from '@silveus/calculations';
import { UnitStructureInput, UnitStructureProps } from './unitStructureInput.component';
import IceRate from '../../../types/api/adm/IceRate';

export interface IceUnitStructureInputProps {
  iceProductsAvailable: IceRate[],
  selectedIceProduct: ScenarioPieceType,
}

export const IceUnitStructureInput = ({ selectedIceProduct, iceProductsAvailable, unitStructure, disabled = false, autoFocus = false }: UnitStructureProps & IceUnitStructureInputProps) => {
  const iceAllowedUnitStructures = [...new Set(iceProductsAvailable
    .filter(iceRate => iceRate.iceScenarioPieceType === selectedIceProduct)
    .map(iceRate => iceRate.unitStructure))];

  const availableUnitStructures = Object.values(UnitStructureAttributes)
    .filter(us => iceAllowedUnitStructures.includes(us.value));
  return (<UnitStructureInput unitStructure={unitStructure}
    disabled={disabled}
    availableUnitStructures={availableUnitStructures} autoFocus={autoFocus} />);
};
