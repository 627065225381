import { Guid } from '../types/api/PrimaryKeys';
import * as uuid from 'uuid';


export function toPrimaryKey<T extends Guid>(input: Guid) {
  return input as T;
}

export function generatePrimaryKey<T extends Guid>() {
  const guid = uuid.v4();
  return toPrimaryKey<T>(guid);
}