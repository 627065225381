import { styled, TextField, TextFieldProps } from '@mui/material';
import { cup, roundToPlaces } from '@silveus/calculations';
import { ChangeEvent } from 'react';
import { Nullable } from '../../types/util/Nullable';
import { isNullOrUndefined } from '../../utils/nullHandling';

export const onNumericStringInputChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: { onChange: (val: Nullable<number>) => void }, placesToRoundTo?: number) => {
  if (e.target.value.endsWith('.') && e.target.value.split('.').length <= 2) {
    field.onChange(null);
    return;
  }
  onNumericInputChange(e, field, placesToRoundTo);
};

export const onNumericInputChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: { onChange: (val: Nullable<number>) => void }, placesToRoundTo?: Nullable<number>) => {
  const val = getRoundedValue(parseFloat(e.target.value), placesToRoundTo);

  field.onChange(val);
};

export const getRoundedValue = (val: Nullable<number>, placesToRoundTo: number | null | undefined, defaultVal: Nullable<number> = null) => {
  if (isNullOrUndefined(val) || isNaN(val)) return defaultVal;
  if (isNullOrUndefined(placesToRoundTo)) return val;

  return cup(roundToPlaces(val, placesToRoundTo), 0);
};

//Custom styled component to remove the up and down arrows from number text boxes
const NumberTextField = styled(TextField)<TextFieldProps>(
  ({ theme }) => ({
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  }),
);

export default NumberTextField;
