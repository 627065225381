import { Button, Grid, Typography } from '@mui/material';
import { InsuredId } from '../../types/api/PrimaryKeys';
import { Insured } from '../../types/api/insureds/Insured';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectInsuredsForIds, selectPreDownloadedInsureds } from '../../app/insuredsSlice';
import ReconciliationTrackedEntity, { SubEntityCollectionTypeBase } from '../../types/app/ReconciliationTrackedEntity';
import { Nullable } from '../../types/util/Nullable';

interface DataConflictListProps<T extends ReconciliationTrackedEntity<InsuredId, InsuredId, SubEntityCollectionTypeBase, InsuredId>> {
  handleReconcileButtonClick: (insuredId: InsuredId) => void;
  handleCancel: () => void;
  conflictState:  Nullable<T[]>;
  handleSubmitClicked: () => void;
}

const DataConflictList = <T extends ReconciliationTrackedEntity<InsuredId, InsuredId, SubEntityCollectionTypeBase, InsuredId>>({ handleReconcileButtonClick, handleCancel, conflictState, handleSubmitClicked }: DataConflictListProps<T>) => {
  const preDownloadedInsureds = useAppSelector(selectPreDownloadedInsureds);

  const insuredsWithConflicts = useMemo(() => {
    return conflictState?.map(cs => cs.id) ?? null;
  }, [conflictState]);

  const insureds = useAppSelector(state => insuredsWithConflicts === null ? null : selectInsuredsForIds(state, insuredsWithConflicts));

  const [insuredsToReconcile, setInsuredsToReconcile] = useState<Insured[]>([]);
  const [isAllDataValid, setIsAllDataValid] = useState(false);

  useEffect(() => {
    if (insureds === null) return;
    setInsuredsToReconcile(insureds);
  }, [insureds]);

  useEffect(() => {
    //Using a useEffect instead of just using a locally set value to prevent it from setting to true
    // temporarily before all of the data gets returned and it's determined it should not be valid
    const areConflicts = (insuredsWithConflicts?.length ?? 0) !== 0 && insuredsToReconcile.length !== 0;
    const isDataValid = (insuredsWithConflicts !== null || preDownloadedInsureds.length === 0) && !areConflicts;
    setIsAllDataValid(isDataValid);
  }, [preDownloadedInsureds, insuredsToReconcile, insuredsWithConflicts]);

  return (
    <Grid container sx={{ pt: 3 }} rowSpacing={2}>
      <Grid item xs={12}>
        {!isAllDataValid && <>
          <Typography>A mismatch has been found with data from the following insureds.</Typography>
          <Typography>You must reconcile these changes before going online.</Typography>
        </>}
        {isAllDataValid && <Typography>All insureds have been reconciled.</Typography>}
      </Grid>
      <Grid item container xs={12}>
        {insuredsToReconcile.map(insured => {
          return <Grid container key={insured.id} alignItems="center" columnSpacing={3}>
            <Grid item xs="auto">
              <Button variant="contained" onClick={() => handleReconcileButtonClick(insured.id)}>Reconcile</Button>
            </Grid>
            <Grid item xs>
              <Typography>{insured.name ?? ''}</Typography>
            </Grid>
          </Grid>;
        })}
      </Grid>
      <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" disabled={!isAllDataValid} onClick={handleSubmitClicked}>Go online</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataConflictList;