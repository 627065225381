import { OptionCode } from '@silveus/calculations';
import { Checkbox, FormControlLabel, FormGroup, Grid, Tooltip, Typography } from '@mui/material';
import { OptionLevelCode, OptionLevelCodes } from '../../../constants/optionLevelCodes';
import OptionState from '../../../types/app/OptionState';
import { OptionSelectionState } from '../../../types/app/enums/optionSelectionState.enum';
import { OptionSelectionAvailabilityWarning } from '../../../constants/optionAvailability';

interface ScenarioPieceOptionsNewProps {
  scenarioOptionsForSpForm: OptionState[];
  updateOptionSelection: (optionCode: OptionCode) => void;
}

interface ScenarioPieceOptionsListProps {
  optionsByText: string;
  scenarioOptionsState: OptionState[];
  optionLevelCode: OptionLevelCode;
  onOptionChanged: (optionCode: OptionCode) => void;
}

export const ScenarioPieceOptions = ({ scenarioOptionsForSpForm, updateOptionSelection }: ScenarioPieceOptionsNewProps) => {
  const onOptionChanged = (optionCode: OptionCode) => {
    updateOptionSelection(optionCode);
  };

  return (
    <>
      <Grid container spacing={2}>
        <ScenarioPieceOptionList
          optionsByText={'Commodity'}
          scenarioOptionsState={scenarioOptionsForSpForm}
          optionLevelCode={OptionLevelCodes.Commodity}
          onOptionChanged={onOptionChanged}
        />
        <ScenarioPieceOptionList
          optionsByText={'Units'}
          scenarioOptionsState={scenarioOptionsForSpForm}
          optionLevelCode={OptionLevelCodes.Unit}
          onOptionChanged={onOptionChanged}
        />
      </Grid>
    </>
  );
};

const ScenarioPieceOptionList = ({ optionsByText, scenarioOptionsState, optionLevelCode, onOptionChanged }: ScenarioPieceOptionsListProps) => {
  const filteredScenarioOptions = scenarioOptionsState.filter(o => o.option.optionLevelCode === optionLevelCode);
  return (
    <Grid item xs={6}>
      <Grid item>
        <Typography>{`Options by ${optionsByText}`}</Typography>
        {filteredScenarioOptions.length === 0 && <Typography variant="caption">{`No Options by ${optionsByText} Available`}</Typography> }
      </Grid>
      <FormGroup>
        {
          filteredScenarioOptions.map(option => {
            return (
              <Grid key={option.option.optionCode} item xs={12}>
                <Tooltip title={!option.isAvailable && OptionSelectionAvailabilityWarning} enterDelay={2000}>
                  <FormControlLabel control={
                    <Checkbox
                      checked={option.selectionState === OptionSelectionState.On}
                      indeterminate={option.selectionState === OptionSelectionState.Partial}
                      size="small" />
                  }
                  onChange={() => onOptionChanged(option.option.optionCode)} label={option.option.optionName}
                  sx={theme => ({
                    '& .MuiFormControlLabel-label': {
                      color: option.isAvailable ? 'inherit' : theme.palette.warning.main,
                      fontSize: '0.875rem',
                    },
                  })}/>
                </Tooltip>
              </Grid>
            );
          })
        }
      </FormGroup>
    </Grid>
  );
};
