import { Controller, useFormContext } from 'react-hook-form';
import NumberTextField, { onNumericInputChange } from '../../../numberTextField/numberTextField.component';
import VALIDATION_RULES from '../../../../constants/formValidationRules';

interface MarketingYieldProps {
  autoFocus?: boolean;
  disabled?: boolean;
  marketingYield: number;
  onValueChange: () => void;
}

export type MarketingYieldFields = {
  marketingYield: number;
}

const MarketingYieldInput = ({ autoFocus = false, disabled = false, marketingYield, onValueChange }: MarketingYieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="marketingYield"
      control={control}
      rules={{ required: VALIDATION_RULES.required(), min: VALIDATION_RULES.minimum(0), max: VALIDATION_RULES.maximum(99999999.99), maxLength: VALIDATION_RULES.maxLength(11), validate: VALIDATION_RULES.restrictMinDefaultValue(0) }}
      defaultValue={marketingYield}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          type="number"
          label="Marketing Yield"
          fullWidth
          inputProps={{ step: 0.01, min: 0 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          onChange={e => {
            onNumericInputChange(e, field, 2);
            onValueChange();
          }}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default MarketingYieldInput;