import {
  Autocomplete,
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText, TextField
} from '@mui/material';
import { selectCounties } from '../../app/admSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CountyListItem from './countyListItem.component';
import { County } from '../../types/api/adm/County';
import SearchIcon from '@mui/icons-material/Search';
import { State } from '../../types/api/adm/State';
import { fuzzySearchMultipleWords } from '../../utils/searchUtils';

interface StateListItemProps {
  selectedState: State;
  lockedCounties: string[];
  activeCounties: County[];
  handleStateSelected: (stateCode: string, active: boolean) => void;
  handleCountySelected: (county: County, active: boolean) => void;
}

const StateListItem = ({ selectedState, lockedCounties, activeCounties, handleStateSelected, handleCountySelected }: StateListItemProps) => {
  const counties = useAppSelector(state => selectCounties(state, selectedState.stateCode));
  const [open, setOpen] = useState(false);
  const [countiesToShow, setCountiesToShow] = useState(counties);

  const areAllCountiesSelected = counties.every(county => activeCounties.includes(county));
  const isAnyCountySelected = counties.some(county => activeCounties.includes(county));
  const isIndeterminate = !areAllCountiesSelected && isAnyCountySelected;

  const handleExpandClick = (e: React.SyntheticEvent) => {
    setOpen(!open);

    if (open) {
      //Reset the filter as we're closing. If this isn't reset, then when we re-open the list,
      // the filter on list of items remains, but the text in the text box clears out
      handleCountySearchInputChange(e, '', '');
    }
  };

  const handleStateCheckboxClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    handleStateSelected(selectedState.stateCode, isIndeterminate || !areAllCountiesSelected);
  };

  const handleCountySearchInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
    const filteredCounties = fuzzySearchMultipleWords(counties, value, ['name']);
    setCountiesToShow(filteredCounties);
  };

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={handleExpandClick}>
          <ListItemIcon>
            <Checkbox
              checked={areAllCountiesSelected}
              disableRipple
              indeterminate={isIndeterminate}
              onClick={handleStateCheckboxClicked}
              sx={{ p: 0 }}
            />
          </ListItemIcon>
          <ListItemText>{selectedState.name}</ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ marginLeft: 2, marginRight: 2 }}>
        <Autocomplete
          open={false}
          freeSolo
          options={counties}
          clearOnEscape
          clearOnBlur={false}
          onInputChange={handleCountySearchInputChange}
          renderInput={(params =>
            <TextField
              {...params}
              variant="standard"
              label="Counties"
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon />,
              }}
            />
          )}
        />
        <List component="div" disablePadding dense>
          {countiesToShow.map(county =>
            <CountyListItem key={county.countyId} county={county} handleCountyClick={handleCountySelected} selectedCounties={activeCounties} disabled={lockedCounties.includes(county.countyId)} />,
          )}
        </List>
      </Collapse>
    </>
  );
};

export default StateListItem;
