import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import { onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { ChangeEvent } from 'react';

interface CoverageLevelInputProps {
  onBlur: () => void;
  coverageLevel: Nullable<number>;
  name: string;
  disabled?: boolean;
}

export type CoverageLevelInputFields = {
  coverageLevel: number;
};

const CoverageLevelInput = ({ onBlur, coverageLevel, name }: CoverageLevelInputProps) => {

  const { control } = useFormContext();

  // ToDo: Replace this dummy data for drop down with actual valid coverage levels.
  const coverageLevels = [120, 115, 110, 105, 100];

  const onValueChanged = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: { onChange: (val: Nullable<number>) => void }) => {
    onNumericInputChange(e, field);
    onBlur();
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.validateMultipleRules(
          [
            VALIDATION_RULES.restrictDefaultValue(0),
            VALIDATION_RULES.restrictNotOnListValue(coverageLevels),
          ],
        ),
      }}
      defaultValue={coverageLevel ?? ''}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          { ...field }
          type="text"
          select
          fullWidth
          inputRef={ref}
          error={!!error}
          disabled={true} //TODO: Remove this when we have the actual coverage levels.
          value={value}
          inputProps={{ sx: { pt: '4px !important', pb: '4px !important' } }}
          onChange={e => onValueChanged(e, field)}
        >
          {coverageLevels.map(level => <MenuItem key={level} value={level}>{level}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default CoverageLevelInput;
