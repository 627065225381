import EntityDiff from '../entityDiffing/entityDiff';
import UnitDiff from './unitDiff';
import { ReactElement } from 'react';
import DataLocationType from '../constants/dataLocationType';
import { NamedReconciliationStack } from '../../../types/app/ReconciliationTrackedEntity';

type StackType = NamedReconciliationStack<'unitInsureds'>;
type SubEntityStackType = NamedReconciliationStack<'unitYears'>;

interface InsuredUnitsDiffProps {
  insured: StackType;
  reconciledInsured: StackType;
  onReconciliationTypeChange: (entity: StackType) => void;
  dataLocation: DataLocationType;
}

const InsuredUnitsDiff = ({ onReconciliationTypeChange, insured, reconciledInsured, dataLocation }: InsuredUnitsDiffProps) => {
  const title = `${dataLocation === 'client' ? 'Offline' : 'Online'} Units`;

  const getSubEntityDiffElements = (
    subEntities: SubEntityStackType[],
    subEntitySubset: SubEntityStackType[],
    onReconciliationTypeChange: (subEntity: SubEntityStackType) => void,
  ) => {
    const elements: ReactElement[] = [];

    subEntitySubset.forEach(subsetEntity => {
      const applicableEntity = subEntities.find(subEntity => subEntity.id === subsetEntity.id);

      if (applicableEntity === undefined) return;

      elements.push(
        <UnitDiff
          unit={subsetEntity}
          reconciledUnit={applicableEntity}
          onReconciliationTypeChange={onReconciliationTypeChange}
          dataLocation={dataLocation}
          key={subsetEntity.id}
        />,
      );
    });

    return elements;
  };

  return (
    <EntityDiff
      title={title}
      subEntitySelector={unit => unit.subCollections.unitYears}
      detailComponents={[]}
      getSubEntityDiffElements={getSubEntityDiffElements}
      onReconciliationTypeChange={onReconciliationTypeChange}
      trackedEntity={reconciledInsured}
      subsetEntity={insured}
      preventCollapse
      dataLocation={dataLocation}
    />
  );
};

export default InsuredUnitsDiff;