import Dexie, { Table } from 'dexie';
import { OfflineChangeTrackedEntity } from '../../types/app/OfflineChangeTrackedEntity';

export const softDelete = async (table: Table<unknown>, id: string | string[]) => {
  const updateDiff = getSoftDeleteDiff();

  if (Array.isArray(id)) {
    await table.where(':id').anyOf(id).modify(updateDiff);
  } else {
    await table.update(id, updateDiff);
  }
};

export const applySoftDelete = async (collection: Dexie.Collection<unknown, unknown>) => {
  const updateDiff = getSoftDeleteDiff();
  await collection.modify(updateDiff);
};

const getSoftDeleteDiff = () => {
  const deletedOn = new Date().toISOString();
  const updateDiff: Partial<OfflineChangeTrackedEntity> = {
    offlineDeletedOn: deletedOn,
  };

  return updateDiff;
};