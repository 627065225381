import { useFormContext } from 'react-hook-form';
import { Nullable } from '../../../../types/util/Nullable';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectOfferExpectedInputCosts } from '../../../../app/admSlice';
import { useEffect } from 'react';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import MpExpectedInputCostsInput, { MpExpectedInputCostsProps } from './mpExpectedInputCosts';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

interface ScenarioPieceMpExpectedInputCostsProps extends MpExpectedInputCostsProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>
}

export const ScenarioPieceMpExpectedInputCostsInput = ({ scenarioPiece, expectedInputCosts, sx, autoFocus = false, disabled = false }: ScenarioPieceMpExpectedInputCostsProps) => {
  const fieldName = ExtendedDataFormInputParameterNames.ExpectedInputCosts;
  const { setValue, trigger } = useFormContext();
  const offerExpectedInputCosts = useAppSelector(selectOfferExpectedInputCosts);

  useEffect(() => {
    //We only want to change the value if it is a new scenario and offer expected
    // input costs are loaded for the first time
    if (scenarioPiece !== null || offerExpectedInputCosts === null) return;

    setValue(fieldName, offerExpectedInputCosts);
    trigger(fieldName);
  }, [offerExpectedInputCosts]);

  return (
    <MpExpectedInputCostsInput
      expectedInputCosts={expectedInputCosts}
      sx={sx}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  );
};
