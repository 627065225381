import { orderBy } from 'lodash';

/** Returns the provided argument as an array.
 * If the argument is already an array, it will be returned as is.
 * Otherwise, it will be wrapped in an array where it is the single element.
 */
export const asArray = <T>(arr: T[] | T): T[] => {
  if (Array.isArray(arr)) { return arr; }
  return [arr];
};

export const remove = <T>(arr: T[], items: T[] | T): T[] => {
  const clonedArray = [...arr];
  const itemsToRemove = items instanceof Array ? items : [items];

  itemsToRemove.forEach(item => {
    const removalIndex = clonedArray.indexOf(item);
    if (removalIndex < 0) return clonedArray;
    clonedArray.splice(removalIndex, 1);
  });
  return clonedArray;
};

export function groupBy<TObj, TKey>(list: readonly TObj[], keySelector: (i: TObj) => TKey) {
  return groupByWithValueSelector(list, keySelector, x => x);
}

export function groupByWithValueSelector<TObj, TKey, TValue>(list: readonly TObj[], keySelector: (i: TObj) => TKey, valueSelector: (i: TObj) => TValue) {
  const map = new Map<TKey, TValue[]>();

  for (const i of list) {
    const key = keySelector(i);
    const collection = map.get(key);
    if (collection === undefined) {
      map.set(key, [valueSelector(i)]);
    } else {
      collection.push(valueSelector(i));
    }
  }

  return map;
}

export function compare<T>(a: T, b: T): (0 | -1 | 1) {
  //This allows an array sort to easily order by any number of properties, without having to re-implement the logic every time
  //For full explanation, see https://stackoverflow.com/questions/9175268/javascript-sort-function-sort-by-first-then-by-second
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
}

/** Given an array, returns a new array with only the distinct elements included. */
export function distinct<T>(array: readonly T[]): T[] {
  return [...new Set(array)];
}

export function distinctBy<TObj, TKey>(list: readonly TObj[], keySelector: (i: TObj) => TKey): TObj[] {
  const distinctItems = new Set<TKey>();
  const filteredItems = list.filter(item => {
    const key = keySelector(item);
    if (distinctItems.has(key)) {
      return false;
    }
    distinctItems.add(key);
    return true;
  });

  return filteredItems;
}

export function average(list: readonly number[]) {
  if (list.length === 0) return 0;
  return list.reduce((a, b) => a + b) / list.length;
}


// ----------------------------


type OrderByParameter<T> = {
  property: keyof T,
  order?: 'asc' | 'desc',
};

export type OrderByParameters<T> = OrderByParameter<T> | OrderByParameter<T>[];

const globalDefaultSortOrder = 'asc';

/** A type safe passthrough for the simple case of ordering an array by one field.
 * Returns a new sorted array.
*/
export function orderByProperty<T>(arr: readonly T[], parameters: OrderByParameters<T>) {
  if (Array.isArray(parameters)) {
    const keys = parameters.map(p => p.property);
    const orders = parameters.map(p => p.order ?? globalDefaultSortOrder);
    return orderBy(arr, keys, orders);
  } else {
    const key = parameters.property;
    const order = parameters.order ?? globalDefaultSortOrder;
    return orderBy(arr, key, order);
  }
}

type OrderByMappedValue<T, U> = {
  map: (t: T) => U,
  order?: 'asc' | 'desc',
};

/** A type safe passthrough for the case of ordering an array by mapping function(s).
 * Returns a new sorted array.
*/
export function orderByMappedValues<T, U>(arr: readonly T[], mappings: OrderByMappedValue<T, U>[]) {
  const orderedMappingFunctions = mappings.map(mapping => mapping.map);
  const orders = mappings.map(mapping => mapping.order ?? globalDefaultSortOrder);
  return orderBy(arr, orderedMappingFunctions, orders);
}

/* Accesses an element from an array, accounting for the possibility that no item exists at that index. */
export const safeArrayAccess = <T>(arr: readonly T[], index: number) => {
  return arr[index] as T | undefined;
};

/** Removes null and undefined from an array, returning a new array. */
export const filterNotNullOrUndefined = <T>(arr: readonly (T | null | undefined)[]): T[] => {
  return arr.filter((i): i is T => i !== null && i !== undefined);
};