import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import validationFunctionDefault from '../../defaultDefinitions/validationFunctionDefault';
import { createAphData } from '../../../../services/calculations/aph/aphDataTransformations';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import { AphScenarioPieceForm } from '../../../../pages/scenarioPiece/aph/aphScenarioPieceForm.component';
import { getNonAreaQuoteSummaryData } from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getNonAreaQuoteSummaryData';
import { ScenarioPieceType } from '@silveus/calculations';
import getFullyQualifiedScenarioPieceName from '../../defaultDefinitions/scenarioPieceNameDefaults';

import {
  CatScenarioPieceChip, EuByScenarioPieceChip, OptionsScenarioPieceChip, ProtectionFactorScenarioPieceChip
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

export const aphDefinition: ScenarioPieceDefinition = {
  validationFunction: validationFunctionDefault,
  d365ProductType: ProductType.MPCI,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    return createAphData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <AphScenarioPieceForm {...props} selectedScenarioPieceType={props.selectedScenarioPieceType as ScenarioPieceType} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}/>;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getNonAreaQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceName,
  getScenarioPieceCardChips: [
    CatScenarioPieceChip,
    ProtectionFactorScenarioPieceChip,
    EuByScenarioPieceChip,
    OptionsScenarioPieceChip,
  ],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#6495ED',
};
