import { SigReportDocumentType } from '../../../constants/sigReportDocumentType';
import { PremiumBreakdownReportData } from '../../../types/api/reports/PremiumBreakdownReportData';
import PremiumBreakdownData from '../../../types/app/PremiumBreakdownData';
import { PremiumBreakdownReportProduct } from '../../../types/api/reports/PremiumBreakdownReportProduct';
import {
  getUnitStructureTextForScenarioPieceName
} from '../../../constants/productDefinitions/defaultDefinitions/scenarioPieceNameDefaults';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import ScenarioPieceClassification
  from '@silveus/calculations/dist/lookups/scenarioPieceClassification/ScenarioPieceClassification';
import { getPremiumBreakdownImportantDates } from './getImportantDates';
import { Commodity } from '../../../types/api/adm/Commodity';
import InsuranceCalendar from '../../../types/api/adm/InsuranceCalendar';
import { PremiumBreakdownReportSummaryProduct } from '../../../types/api/reports/PremiumBreakdownReportSummaryProduct';
import {
  PremiumBreakdownSummary,
  PremiumBreakdownSummaryTypes
} from '../../../hooks/usePremiumBreakdownSummary';
import { getScenarioPieceDefinition } from '../../../constants/productDefinitions/scenarioPieceDefinitionRecords';
import { PremiumBreakdownOptions } from '../../../types/api/userSettings/PremiumBreakdownOptions';
import { Quote } from '../../../types/api/Quote';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { State } from '../../../types/api/adm/State';
import { County } from '../../../types/api/adm/County';
import { Practice } from '../../../types/api/adm/Practice';
import { ClientFile } from '../../../types/api/ClientFile';
import { scenarioPieceOrderingServiceInstance } from '../../../utils/scenarioOrderingServiceWrappers';

export const getPremiumBreakdownReportData = (
  clientFile: ClientFile,
  quote: Quote,
  scenario: RowCropScenario,
  states: State[],
  counties: County[],
  commodities: Commodity[],
  practices: Practice[],
  insuranceCalendars: InsuranceCalendar[],
  rowCropScenarioPieces: RowCropScenarioPiece[],
  premiumBreakdownOptions: PremiumBreakdownOptions,
  premiumBreakdownProductData: PremiumBreakdownData[],
  premiumBreakdownSummaryResults: Map<PremiumBreakdownSummaryTypes, PremiumBreakdownSummary>,
  reportDocumentType: SigReportDocumentType,
  includeGenerationDate: boolean,
): PremiumBreakdownReportData => {

  const county = counties.find(c => c.countyId === quote.countyId);
  const state = states.find(s => s.stateCode === county?.stateCode);
  const commodity = commodities.find(c => c.commodityCode === quote.commodityCode);
  const practice = practices.find(p => p.practiceId === scenario.practiceId);

  const premiumBreakdownTitle = `${clientFile.year} - ${state?.name ?? ''} - ${county?.name ?? ''} - ${commodity?.name ?? ''} - ${practice?.abbrev ?? ''}`;

  const basePieceTypes = scenarioPieceOrderingServiceInstance.getBaseScenarioPieces();
  const insurancePlanCodes = rowCropScenarioPieces.filter(sp => basePieceTypes.includes(sp.scenarioPieceType)).map(sp => sp.planCode);
  const filteredInsuranceCalendars = insuranceCalendars.filter(ic => ic.typeId === scenario.typeId && ic.practiceId === scenario.practiceId && insurancePlanCodes.includes(ic.insurancePlanCode));

  const filteredProductData = premiumBreakdownProductData
    .filter(pd => pd.productName !== undefined)
    .sort((a, b) => {
      if (a.originalStackId !== b.originalStackId){
        return (a.originalStackId ?? 0) - (b.originalStackId ?? 0);
      }

      return b.lowerCoverageLevel - a.lowerCoverageLevel;
    });

  const reportProducts: PremiumBreakdownReportProduct[] = [];
  let currentIndividualLabelRowStart = 0;
  let currentIndividualLabelRowEnd = 0;
  let currentCountyLabelRowStart = 0;
  let currentCountyLabelRowEnd = 0;

  for (const product of filteredProductData) {
    if (product.classification === ScenarioPieceClassification.County){
      currentCountyLabelRowStart = currentCountyLabelRowEnd + 1;
      currentCountyLabelRowEnd = currentCountyLabelRowStart + printoutDetailsHeight;
      reportProducts.push(mapPremiumBreakdownProductData(rowCropScenarioPieces, product, currentCountyLabelRowStart, currentCountyLabelRowEnd));
    } else {
      currentIndividualLabelRowStart = currentIndividualLabelRowEnd + 1;
      currentIndividualLabelRowEnd = currentIndividualLabelRowStart + printoutDetailsHeight;
      reportProducts.push(mapPremiumBreakdownProductData(rowCropScenarioPieces, product, currentIndividualLabelRowStart, currentIndividualLabelRowEnd));
    }
  }

  const premiumBreakdownReportData: PremiumBreakdownReportData = {
    //BaseReportData
    agentContactInformation: null,
    insuredContactInformation: null,
    reportDocumentType: reportDocumentType,
    hasGenerationDate: includeGenerationDate,
    hasPageNumbers: true,

    //PremiumBreakdown Specific Data
    title: premiumBreakdownTitle,
    showDisclaimer: true,

    //Product Preferences
    showPremiumPerAcre: premiumBreakdownOptions.details.premiumPerAcre,
    showTotalPremium: premiumBreakdownOptions.details.totalPremium,
    showCoverage: premiumBreakdownOptions.details.coveragePerAcre,
    showTotalCoverage: true,
    showSubsidyPercent: premiumBreakdownOptions.details.subsidyPercent,
    showSubsidyPerAcre: premiumBreakdownOptions.details.subsidyPerAcre,
    showSubsidyTotal: premiumBreakdownOptions.details.totalSubsidy,
    showLiabilityPercent: true,

    //Summary Section Preferences
    showSummaryCoverage: premiumBreakdownOptions.summary.coverage,
    showSummarySubsidyPercent: premiumBreakdownOptions.summary.subsidyPercent,
    showSummarySubsidyPerAcre: premiumBreakdownOptions.summary.subsidy,
    showSummaryTotalPremium: false,
    showSummaryPremiumPerAcre: premiumBreakdownOptions.summary.premium,
    showSummaryTotalCoverage: false,
    showSummaryTotalSubsidy: false,

    products: reportProducts,
    importantDates: getPremiumBreakdownImportantDates(commodity?.name ?? '', filteredInsuranceCalendars),
    summaryProducts: filteredProductData.map(pd => mapPremiumBreakdownProductSummary(pd)),
    summaryProductTotals: mapPremiumBreakdownSummaryTotals(premiumBreakdownSummaryResults),
  };
  return premiumBreakdownReportData;
};

const countyLabelColumnNumber = 2;
const individualLabelColumnNumber = 12;
const printoutDetailsHeight = 14;
const dataRowStart = 101;
const mapPremiumBreakdownProductData = (scenarioPieces: RowCropScenarioPiece[], productData: PremiumBreakdownData, currentLabelRowStart: number, currentLabelRowEnd: number) : PremiumBreakdownReportProduct => {
  const rowCropScenarioPiece = scenarioPieces.find(sp => sp.scenarioPieceId === productData.id);
  const productColor = rowCropScenarioPiece !== undefined ? getScenarioPieceDefinition(rowCropScenarioPiece.scenarioPieceType).premiumBreakdownBarColor : '#808080';
  const countyReportColumnNumbers = [7,6,5,4];
  const individualReportColumnNumbers = [8,9,10,11];
  return {
    name: productData.productName ?? '',
    color: productColor,

    labelColumn: productData.classification === ScenarioPieceClassification.County ? countyLabelColumnNumber : individualLabelColumnNumber,
    labelRowStart: currentLabelRowStart,
    labelRowEnd: currentLabelRowEnd,

    //County stack ids are the inverse so we need to use the originalStackId instead of the stackId
    dataColumn: productData.classification === ScenarioPieceClassification.County ? countyReportColumnNumbers[(productData.originalStackId ?? 1) - 1] : individualReportColumnNumbers[(productData.stackId ?? 1) - 1],
    dataRowStart: dataRowStart - productData.lowerCoverageLevel,
    dataRowEnd: dataRowStart - productData.upperCoverageLevel,

    upperCoverageLevel: productData.upperCoverageLevel,
    lowerCoverageLevel: productData.lowerCoverageLevel,
    unitStructure: rowCropScenarioPiece !== undefined ? getUnitStructureTextForScenarioPieceName(rowCropScenarioPiece) : '',

    liabilityPerAcre: productData.coverage ?? 0,
    liabilityAmount: productData.totalCoverage ?? 0,
    liabilityPercent: productData.liabilityFactor ?? 0,

    totalPremiumPerAcre: productData.premium ?? 0,
    totalPremium: productData.totalPremium ?? 0,

    subsidyPercent: productData.subsidyPercent ?? 0,
    subsidyPerAcre: productData.subsidy ?? 0,
    totalSubsidy: productData.totalSubsidy ?? 0,

    //Unused
    triggerYield: null,
    producerPremium: 0,
    producerPremiumPerAcre: 0,
    premiumLiability: 0,
    premiumLiabilityPerAcre: 0,
  };
};

const mapPremiumBreakdownProductSummary = (productData: PremiumBreakdownData) : PremiumBreakdownReportSummaryProduct => {

  return {
    productName: productData.productName ?? '',
    liabilityAmount: productData.totalCoverage ?? 0,
    liabilityPerAcre: productData.coverage ?? 0,
    totalPremium: productData.totalPremium ?? 0,
    totalPremiumPerAcre: productData.premium ?? 0,
    totalSubsidy: productData.totalSubsidy ?? 0,
    subsidyPercent: productData.subsidyPercent ?? 0,
    subsidyPerAcre: productData.subsidy ?? 0,
    //Unused Vars
    premiumLiability: 0,
    premiumLiabilityPerAcre: 0,
    producerPremium: 0,
    producerPremiumPerAcre: 0,
    triggerYield: null,
  };
};

const mapPremiumBreakdownSummaryTotals = (premiumBreakdownSummaryResults: Map<PremiumBreakdownSummaryTypes, PremiumBreakdownSummary>) : PremiumBreakdownReportSummaryProduct[] => {
  const premiumBreakdownIndividualSummary = mapPremiumBreakdownReportSummary('INDIVIDUAL', PremiumBreakdownSummaryTypes.individual, premiumBreakdownSummaryResults);
  const premiumBreakdownCountySummary = mapPremiumBreakdownReportSummary('COUNTY', PremiumBreakdownSummaryTypes.county, premiumBreakdownSummaryResults);
  const premiumBreakdownTotalSummary = mapPremiumBreakdownReportSummary('TOTAL', PremiumBreakdownSummaryTypes.combined, premiumBreakdownSummaryResults);
  return [premiumBreakdownIndividualSummary, premiumBreakdownCountySummary, premiumBreakdownTotalSummary];
};

const mapPremiumBreakdownReportSummary = (
  summaryName: string,
  summaryType: PremiumBreakdownSummaryTypes,
  premiumBreakdownSummary: Map<PremiumBreakdownSummaryTypes, PremiumBreakdownSummary>): PremiumBreakdownReportSummaryProduct => {
  const combinedPremiumBreakdownSummaryResults = premiumBreakdownSummary.get(summaryType);
  return {
    productName: summaryName,
    liabilityAmount: combinedPremiumBreakdownSummaryResults?.totalCoverage ?? 0,
    liabilityPerAcre: combinedPremiumBreakdownSummaryResults?.coverage ?? 0,
    producerPremium: combinedPremiumBreakdownSummaryResults?.producerPremium ?? 0,
    producerPremiumPerAcre: combinedPremiumBreakdownSummaryResults?.producerPremiumPerAcre ?? 0,
    totalPremium: combinedPremiumBreakdownSummaryResults?.totalPremium ?? 0,
    totalPremiumPerAcre: combinedPremiumBreakdownSummaryResults?.totalPremiumPerAcre ?? 0,
    totalSubsidy: combinedPremiumBreakdownSummaryResults?.totalSubsidy ?? 0,
    subsidyPercent: combinedPremiumBreakdownSummaryResults?.subsidyPercent ?? 0,
    subsidyPerAcre: combinedPremiumBreakdownSummaryResults?.subsidy ?? 0,
    //Unused Vars
    premiumLiability: 0,
    premiumLiabilityPerAcre: 0,
    triggerYield: null,
  };
};