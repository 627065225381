import { IEnumAttributes } from '../IEnumAttributes';

/* eslint-disable no-unused-vars */
export enum DesignatedCountyElection {
  Nation = 907550000,
  State = 907550001,
}

export const DesignatedCountyElectionAttributes: Readonly<Record<DesignatedCountyElection, IEnumAttributes<DesignatedCountyElection>>> = {
  [ DesignatedCountyElection.Nation ]: { name: 'Nation', description: 'Nation', value: DesignatedCountyElection.Nation },
  [ DesignatedCountyElection.State ]: { name: 'State', description: 'State', value: DesignatedCountyElection.State },
};