import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactId, InsuredId } from '../types/api/PrimaryKeys';
import { PersonOfInterest } from '../types/api/insureds/PersonOfInterest';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import * as insuredsService from '../services/insureds.service';
import { getKeyedStateGroupedBy, getStateIdsMatching, updateKeyedState } from './sliceHelpers';
import { RootState } from './store';
import { PersonOfInterestType } from '../types/api/enums/contactInfo/personOfInterestType';
import { getItemsForId } from '../utils/mapHelpers';
import { Nullable } from '../types/util/Nullable';
import { isNotNullOrUndefined } from '../utils/nullHandling';
import { openToast } from './toastSlice';
interface PersonsOfInterestState {
  allPersonsOfInterest: SliceDataState<ContactId, PersonOfInterest>;
}
const initialState: PersonsOfInterestState = {
  allPersonsOfInterest: initialSliceDataState(),
};
export const personsOfInterestSlice = createSlice({
  name: 'applications',
  initialState: initialState,
  reducers: {
    addTemporaryPOI(state, action: PayloadAction<PersonOfInterest>) {
      updateKeyedState(state.allPersonsOfInterest.data, action.payload, app => app.id);
    },
    updateTemporaryPOI(state, action: PayloadAction<PersonOfInterest>) {
      updateKeyedState(state.allPersonsOfInterest.data, action.payload, app => app.id);
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allPersonsOfInterest, s => s.id);
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchPOIs,
      affectedIds: (arg, state) => getStateIdsMatching(state.allPersonsOfInterest.data, s => s.insuredId === arg.insuredId, s => s.id),
    });
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: updatePersonOfInterest,
      affectedIds: arg => arg.personOfInterest.id,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: createPersonOfInterest,
      affectedIds: arg => arg.personOfInterest.id,
    });
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removePersonOfInterest,
      affectedIds: arg => arg.personOfInterest.id,
    });
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: importPersonOfInterest,
      affectedIds: arg => arg.personOfInterest.id,
    });
  },
});

export const { addTemporaryPOI, updateTemporaryPOI } = personsOfInterestSlice.actions;
export default personsOfInterestSlice.reducer;

export const fetchPOIs = createAppAsyncThunk('personsOfInterest/fetchPOIs', async ({ insuredId }: { insuredId: InsuredId }) => {
  const contacts = (await insuredsService.getInsuredPersonsOfInterest(insuredId)).data;
  return contacts;
});

export const updatePersonOfInterest = createAppAsyncThunk('personsOfInterest/updatePersonOfInterest', async ({ personOfInterest, updateTaxId }: { personOfInterest: PersonOfInterest, updateTaxId: boolean }, thunkApi) => {
  const updateResult = (await insuredsService.updatePersonOfInterest(personOfInterest, updateTaxId)).data;
  if (!updateResult.success) {
    thunkApi.dispatch(openToast({ type: 'error', message: updateResult.message, shouldTimeout: true, allowClickToClose: true }));
    throw new Error(updateResult.message);
  }
  if (!updateTaxId) {
    const existingPoi = selectPoiById(thunkApi.getState(), personOfInterest.id);
    if (existingPoi) {
      personOfInterest.taxId = existingPoi.taxId;
    }
  } return personOfInterest;
});

export const createPersonOfInterest = createAppAsyncThunk('personsOfInterest/createPersonOfInterest', async ({ personOfInterest }: { personOfInterest: PersonOfInterest }, thunkApi) => {
  const createResult =  await insuredsService.createPersonOfInterest(personOfInterest);
  if (!createResult.success) {
    thunkApi.dispatch(openToast({ type: 'error', message: createResult.message, shouldTimeout: true, allowClickToClose: true }));
    throw new Error(createResult.message);
  }
  return createResult.item;
});

export const removePersonOfInterest = createAppAsyncThunk('personsOfInterest/removePersonOfInterest', async ({ personOfInterest }: { personOfInterest: PersonOfInterest }) => {
  if (isNotNullOrUndefined(personOfInterest.personOfInterestId)) {
    await insuredsService.removePersonOfInterest(personOfInterest);
  }

  return personOfInterest;
});

export const importPersonOfInterest = createAppAsyncThunk('personsOfInterest/importPersonOfInterest', async ({ personOfInterest }: { personOfInterest: PersonOfInterest }) => {
  return await insuredsService.importPersonOfInterest(personOfInterest);
});


export const selectPoiById = (state: RootState, poiId: Nullable<ContactId>): Nullable<PersonOfInterest> => poiId === null ? null : state.personsOfInterest.allPersonsOfInterest.data[poiId] ?? null;
// Memoized Selectors
const selectPOIDictionary = (state: RootState) => state.personsOfInterest.allPersonsOfInterest.data;
export const selectAllPOIsByInsuredIdMap = createSelector([selectPOIDictionary], result => {
  return getKeyedStateGroupedBy(result, cf => cf.insuredId);
});

export const selectInsuredsPersonsOfInterestByType = (state: RootState, insuredId: InsuredId, type: PersonOfInterestType) => {
  const allPOIs = selectAllPOIsByInsuredIdMap(state);
  const insuredPOIs = getItemsForId(allPOIs, insuredId);

  return insuredPOIs.filter(x => x.personOfInterestType === type);
};

export const selectSpouse = (state: RootState, insuredId: Nullable<InsuredId>) => {
  if (insuredId === null) return null;

  const allPOIs = selectAllPOIsByInsuredIdMap(state);
  const insuredPOIs = getItemsForId(allPOIs, insuredId);
  const spouse = insuredPOIs.find(x => x.personOfInterestType === PersonOfInterestType.Spouse);

  return spouse ?? null;
};


