import { useEffect } from 'react';
import { useAppDispatch } from './reduxHooks';
import { networkStatusUpdated } from '../app/onlineSlice';

const useOnlineStatus = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleEvent = () => dispatch(networkStatusUpdated(navigator.onLine));
    window.addEventListener('online', handleEvent);
    window.addEventListener('offline', handleEvent);
    return () => {
      window.removeEventListener('online', handleEvent);
      window.removeEventListener('offline', handleEvent);
    };
  }, []);
};

export default useOnlineStatus;