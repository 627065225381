import { UnitYearId } from '../../../../types/api/PrimaryKeys';
import CurrencyText from '../../../../components/currencyText/currencyText.component';
import { UnitResponseDTO } from '@silveus/calculations';

type HailPremiumCellRendererProps = {
  unitYearId: UnitYearId;
  calculatedValues: UnitResponseDTO[];
};

const HailPremiumCellRenderer = ({ unitYearId, calculatedValues }: HailPremiumCellRendererProps) => {
  const hailPremium = (calculatedValues.find(x => x.id === unitYearId)?.totalPremium ?? 0) * -1;
  return (
    <div><CurrencyText currencyAmount={hailPremium} colorize={true}/></div>
  );
};

export default HailPremiumCellRenderer;