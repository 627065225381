import { privateProductsDb, quoterDb } from '../../../db';
import HailScenarioPieceComposition from '../../../types/api/HailScenarioPieceComposition';
import { HailScenarioPieceCompositionId, ScenarioId } from '../../../types/api/PrimaryKeys';
import { HailEndorsementParams, HailProductParams, HailRateParams } from '../../../types/api/adm/PrivateProductsParams';
import HailEndorsement from '../../../types/api/hail/hailEndorsement';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import HailRate from '../../../types/api/hail/hailRate';
import { HailScenarioPieceCompositionDTO } from '../../../types/api/hail/hailScenarioPieceCompositionDTO';
import { networkOnly } from '../../../utils/cachingStrategies';
import { safeWhere } from '../../../utils/dexieQueryHelpers/whereClauses';
import { getHailCompositionDTO } from '../../../utils/hailMapperUtils';
import {
  addHailScenarioPieceComposition,
  deleteHailScenarioPieceComposition,
  getHailScenarioPieceCompositionsByScenarioIds,
  updateHailScenarioPieceComposition
} from '../../hailScenarioPieces.service';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import { getHailEndorsements, getHailProducts, getHailRates } from '../../privateProducts.service';

export const addHailScenarioPieceCompositionRequest = async (hailScenarioPieceCompositionDTO: HailScenarioPieceCompositionDTO): Promise<HailScenarioPieceCompositionDTO> => {
  const request = () => addHailScenarioPieceComposition(hailScenarioPieceCompositionDTO);
  // const transactionTables = [quoterDb.hailScenarioPieceCompositions, quoterDb.hailScenarioPieces, quoterDb.hailScenarioPieceRates, quoterDb.hailScenarioPieceEndorsements];
  // const insertTransaction = quoterDb.transaction('rw', transactionTables, async () => {
  //   const hailScenarioPieces = hailScenarioPieceCompositionDTO.hailScenarioPieces.map(hailScenarioPieceDTO => {
  //     return getHailScenarioPieceFromDTO(hailScenarioPieceDTO);
  //   });
  //   const hailScenarioPieceRates = hailScenarioPieceCompositionDTO.hailScenarioPieces.flatMap(hailScenarioPiece => hailScenarioPiece.hailScenarioPieceRates);
  //   const hailScenarioPieceEndorsements = hailScenarioPieceCompositionDTO.hailScenarioPieces.flatMap(hailScenarioPiece => hailScenarioPiece.hailScenarioPieceEndorsements);

  //   await quoterDb.hailScenarioPieceCompositions.add(getHailScenarioPieceCompositionFromDTO(hailScenarioPieceCompositionDTO));
  //   await quoterDb.hailScenarioPieces.bulkAdd(hailScenarioPieces);
  //   await quoterDb.hailScenarioPieceRates.bulkAdd(hailScenarioPieceRates);
  //   await quoterDb.hailScenarioPieceEndorsements.bulkAdd(hailScenarioPieceEndorsements);
  //   return hailScenarioPieceCompositionDTO;
  // });

  const strategy = networkOnly;
  return strategy(request);
};

export const getHailScenarioPieceCompositionsForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<HailScenarioPieceCompositionDTO[]> => {
  const request = () => getHailScenarioPieceCompositionsByScenarioIds(scenarioIds);
  // const transactionTables = [quoterDb.rowCropScenarios, quoterDb.hailScenarioPieceCompositions, quoterDb.hailScenarioPieces, quoterDb.hailScenarioPieceRates, quoterDb.hailScenarioPieceEndorsements];
  // const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
  //   const scenarios = await safeWhere(quoterDb.rowCropScenarios, { quoteId: quoteId }).toArray();
  //   const scenarioIds = scenarios.map(scen => scen.scenarioId);

  //   const scenarioPieceCompositions = await safeWhere(quoterDb.hailScenarioPieceCompositions, 'scenarioId').anyOf(scenarioIds).toArray();
  //   const hailCompositionIds = scenarioPieceCompositions.map(scen => scen.hailScenarioPieceCompositionId);

  //   const hailScenarioPieces = await safeWhere(quoterDb.hailScenarioPieces, 'hailScenarioPieceCompositionId').anyOf(hailCompositionIds).toArray();
  //   const hailScenarioPieceIds = hailScenarioPieces.map(piece => piece.hailScenarioPieceId);

  //   const hailScenarioRates = await safeWhere(quoterDb.hailScenarioPieceRates, 'hailScenarioPieceId').anyOf(hailScenarioPieceIds).toArray();
  //   const hailScenarioEndorsements = await safeWhere(quoterDb.hailScenarioPieceEndorsements, 'hailScenarioPieceId').anyOf(hailScenarioPieceIds).toArray();

  //   const scenarioPieceCompositionDTOs = scenarioPieceCompositions.map(composition => {
  //     return getHailCompositionDTO(composition, hailScenarioPieces, hailScenarioRates, hailScenarioEndorsements);
  //   });
  //   return scenarioPieceCompositionDTOs;
  // });
  const strategy = networkOnly;
  return strategy(request);
};

export const updateHailScenarioPieceCompositionRequest = async (hailScenarioPieceComposition: HailScenarioPieceComposition): Promise<void> => {
  const request = () => updateHailScenarioPieceComposition(getHailCompositionDTO(hailScenarioPieceComposition, [], [], []));
  // const transactionTables = [quoterDb.hailScenarioPieceCompositions, quoterDb.hailScenarioPieces];
  // const updateTransaction = quoterDb.transaction('rw', transactionTables, async () => {

  //   await quoterDb.hailScenarioPieceCompositions.update(hailScenarioPieceComposition.hailScenarioPieceCompositionId, hailScenarioPieceComposition);
  //   const hailScenarioPieces = await safeWhere(quoterDb.hailScenarioPieces, 'hailScenarioPieceCompositionId').anyOf([hailScenarioPieceComposition.hailScenarioPieceCompositionId]).toArray();
  //   const updateHailPieces = hailScenarioPieces.map(hailScenarioPiece => {
  //     return quoterDb.hailScenarioPieces.update(hailScenarioPiece.scenarioPieceId, { ...hailScenarioPiece, isActive: hailScenarioPieceComposition.isActive });
  //   });
  //   await Promise.all(updateHailPieces);
  //   return;
  // });

  const strategy = networkOnly;
  return strategy(request);
};

export const deleteHailScenarioPieceCompositionRequest = async (scenarioId: ScenarioId): Promise<HailScenarioPieceCompositionId> => {
  const request = () => deleteHailScenarioPieceComposition(scenarioId);
  // const transactionTables = [quoterDb.hailScenarioPieceCompositions, quoterDb.hailScenarioPieces, quoterDb.hailScenarioPieceRates, quoterDb.hailScenarioPieceEndorsements, quoterDb.unitGroups];
  // const removeTransaction = quoterDb.transaction('rw', transactionTables, async () => {

  //   const hailCompositions = safeWhere(quoterDb.hailScenarioPieceCompositions, { scenarioId: scenarioId });
  //   const hailScenarioPieces = safeWhere(quoterDb.hailScenarioPieces, { scenarioId: scenarioId });
  //   const hailScenarioPiecesArray = await hailScenarioPieces.toArray();
  //   const hailScenarioPieceIds = hailScenarioPiecesArray.map(q => q.hailScenarioPieceId);
  //   const scenarioPieceIds = hailScenarioPiecesArray.map(q => q.scenarioPieceId);

  //   const hailScenarioPieceRates = safeWhere(quoterDb.hailScenarioPieceRates, 'hailScenarioPieceId').anyOf(hailScenarioPieceIds);
  //   const hailScenarioPieceEndorsements = safeWhere(quoterDb.hailScenarioPieceEndorsements, 'hailScenarioPieceId').anyOf(hailScenarioPieceIds);
  //   const unitGroups = safeWhere(quoterDb.unitGroups, 'scenarioPieceId').anyOf(scenarioPieceIds);


  //   const softDeleteHailCompositions = applySoftDelete(hailCompositions);
  //   const softDeleteHailScenarioPieces = applySoftDelete(hailScenarioPieces);
  //   const softDeleteHailScenarioPieceRates = applySoftDelete(hailScenarioPieceRates);
  //   const softDeleteHailScenarioPieceEndorsements = applySoftDelete(hailScenarioPieceEndorsements);
  //   const softDeleteUnitGroups = applySoftDelete(unitGroups);
  //   await Promise.all([softDeleteHailCompositions, softDeleteHailScenarioPieces, softDeleteHailScenarioPieceRates, softDeleteHailScenarioPieceEndorsements, softDeleteUnitGroups]);
  //   return;
  // });
  const strategy = networkOnly;
  return strategy(request);
};


export const getHailProductsRequest = async (hailRateParams: HailProductParams): Promise<HailProduct[]> => {
  const request = () => getHailProducts(hailRateParams);

  const transactionTables = [privateProductsDb.hailProducts, privateProductsDb.hailRates];
  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const hailRateQueries = hailRateParams.cropYears.map(cropYear =>
      safeWhere(privateProductsDb.hailRates, {
        cropYear: cropYear,
        countyId: hailRateParams.countyId,
        commodityCode: hailRateParams.commodityCode,
      }).toArray(),
    );

    const hailRates = (await Promise.all(hailRateQueries)).flat();

    const hailRateProductIds = hailRates.map(hailRate => hailRate.productId);
    const hailProducts = await safeWhere(privateProductsDb.hailProducts, 'hailProductId')
      .anyOf(hailRateProductIds).toArray();
    return hailProducts;
  });

  const strategy = getDefaultCachingStrategy();
  return strategy(request, readTransaction);
};

export const getHailRatesRequest = async (hailRateParams: HailRateParams): Promise<HailRate[]> => {
  const request = () => getHailRates(hailRateParams);

  const transactionTables = [privateProductsDb.hailRates];
  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const hailRateQueries = hailRateParams.hailRateIds.map(hailRateId =>
      safeWhere(privateProductsDb.hailRates, {
        rateId: hailRateId,
      }).toArray(),
    );

    const hailRates = (await Promise.all(hailRateQueries)).flat();
    return hailRates;
  });

  const strategy = getDefaultCachingStrategy();
  return strategy(request, readTransaction);
};

export const getHailEndorsementsRequest = async (hailEndorsementParams: HailEndorsementParams): Promise<HailEndorsement[]> => {
  const request = () => getHailEndorsements(hailEndorsementParams);

  const transactionTables = [privateProductsDb.hailEndorsements];
  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const hailEndorsementQueries = hailEndorsementParams.hailEndorsementIds.map(hailEndorsementId =>
      safeWhere(privateProductsDb.hailEndorsements, {
        hailPlanEndorsementId: hailEndorsementId,
      }).toArray(),
    );

    const hailEndorsements = (await Promise.all(hailEndorsementQueries)).flat();
    return hailEndorsements;
  });

  const strategy = getDefaultCachingStrategy();
  return strategy(request, readTransaction);
};
