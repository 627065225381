import { getAvailableIntervalsForScenarioPieceType } from '../../../app/intervalsSlice';
import { Grid } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { HighRiskType, ScenarioPieceType, UnitStructureCode } from '@silveus/calculations';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { createScenarioPiece, removeScenarioPieceAndRecalculate } from '../../../app/scenarioPiecesSlice';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import { Quote } from '../../../types/api/Quote';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import { VipCapInput } from '../../../components/formInputs/scenarioPiece/vip/vipCapInput.component';
import { RowCropScenarioPieceExtendedFormFields } from '../rowCropScenarioPieceExtendedFormFields.component';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { CustomPremiumInput } from '../../../components/formInputs/customPremiumInput.component';
import { VipUnderlyingPlanInput } from '../../../components/formInputs/scenarioPiece/vip/vipUnderlyingPlanInput.component';
import { VipFactorInput } from '../../../components/formInputs/scenarioPiece/vip/vipFactorInput.component';
import { TopIntervalInput } from '../../../components/formInputs/scenarioPiece/topIntervalInput.component';
import { ProtectionFactorFields, ProtectionFactorInput } from '../../../components/formInputs/scenarioPiece/protectionFactorInput.component';
import { AdmUpperCoverageLevelInput } from '../../../components/formInputs/scenarioPiece/admUpperCoverageLevelInput.component';
import { useFederalOfferData } from '../../../hooks/useFederalOfferData';
import { ExtendedDataFormInputParameterNames } from '../../../components/formInputs/constants/inputConstants';
import PriceInputGroup, { PriceInputGroupFields } from '../../../components/formInputs/scenario/priceInputGroup.component';
import CountyYieldInputGroup, { CountyYieldInputGroupFields } from '../../../components/formInputs/scenario/countyYieldInputGroup';
import { selectScenarioById, updateScenario } from '../../../app/scenariosSlice';
import IndividualYieldInputGroup from '../../../components/formInputs/scenario/individualYieldInputGroup.component';
import useYieldsForScenario from '../../../hooks/useYieldsForScenario';
import { mergeScenarioWithYieldAndPriceUpdates } from '../../../utils/scenarioUpdateUtils';
import IntervalInput, { IntervalInputFields } from '../../../components/formInputs/scenario/intervalInput.component';
import { ScenarioPieceSelectedInterval } from '../../../types/api/adm/ScenarioPieceSelectedInterval';
import { setSelectedIntervals } from '../../../app/intervalsSlice';
import { useStableInsurancePlanCodeArray } from '../../../hooks/useStableInsurancePlanCodeArray';

export interface VipScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  scenarioId: ScenarioId;
  quote: Quote;
  year: number;
  highRiskTypeId: HighRiskType;
  countyId: string;
  typeId: string;
  practiceId: string;
  disabled: boolean;
  handleCancel: () => void;
}

export type VipScenarioPieceFormFields = IntervalInputFields & ScenarioPieceFormFields & RowCropScenarioPieceExtendedFormFields & ProtectionFactorFields & PriceInputGroupFields & CountyYieldInputGroupFields;

export const VipScenarioPieceForm = ({ quote, scenarioPiece, scenarioId, year, countyId, typeId, practiceId, highRiskTypeId, disabled, registerHeader, handleValidation, handleCancel, isCanceling = false }: VipScenarioPieceFormProps) => {
  const formId = 'vipScenarioPieceForm';
  const dispatch = useAppDispatch();
  const methods = useForm<VipScenarioPieceFormFields>();
  const scenarioPieceType = ScenarioPieceType.Vip;
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  const { approvedYield, rateYield, aphYield } = useYieldsForScenario(scenarioId, quote.countyId, quote.commodityCode);

  const underlyingScenarioPieceType = useWatch({ name: ExtendedDataFormInputParameterNames.UnderlyingScenarioPieceType, control: methods.control, defaultValue: scenarioPiece?.rowCropScenarioPieceExtendedData?.underlyingScenarioPieceType ?? ScenarioPieceType.RP });
  const isArp = underlyingScenarioPieceType === ScenarioPieceType.Arp;

  const planCode = getInsurancePlanCodeForScenarioPiece(underlyingScenarioPieceType);

  useFederalOfferData(null, scenarioId, underlyingScenarioPieceType, year, countyId, typeId, practiceId, highRiskTypeId);

  const availableIntervals = useAppSelector(state => getAvailableIntervalsForScenarioPieceType(state, scenarioPieceType));

  const onSubmit: SubmitHandler<VipScenarioPieceFormFields> = async data => {
    if (!isArp && data.rowCropScenarioPieceExtendedData !== null) {
      data.rowCropScenarioPieceExtendedData.underlyingProtectionFactor = 100;
    }

    const newScenarioPiece: RowCropScenarioPiece = {
      isActive: data.isActive,
      isInvalid: false,
      lowerCoverageLevel: 0,
      planCode: planCode,
      protectionFactor: data.protectionFactor,
      rowCropScenarioPieceExtendedData: data.rowCropScenarioPieceExtendedData,
      rowCropScenarioPieceId: scenarioPiece?.rowCropScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: scenarioPieceType,
      unitStructure: UnitStructureCode.AU,
      upperCoverageLevel: data.rowCropScenarioPieceExtendedData?.underlyingUpperCoverageLevel ?? 0,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    if (scenario) {
      await dispatch(updateScenario({
        updatedScenario: mergeScenarioWithYieldAndPriceUpdates(scenario, data),
      }));
    }

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(createScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId, updatedScenarioPiece: newScenarioPiece }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));

    const scenarioPieceSelectedIntervals: ScenarioPieceSelectedInterval[] = data.selectedIntervalIds.map(id => {
      const correspondingInterval = availableIntervals.find(interval => interval.id === id);
      if (correspondingInterval === undefined) throw new Error('Something was wrong with the selected interval chosen');
      return {
        scenarioPieceSelectedIntervalId: generatePrimaryKey(),
        scenarioPieceId: newScenarioPiece.scenarioPieceId,
        intervalRangeId: id,
        year: data.year + correspondingInterval.intervalDeltaYearStart,
      };
    });
    await dispatch(setSelectedIntervals({ scenarioPieceId: newScenarioPiece.scenarioPieceId, scenarioPieceSelectedIntervals }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  const insurancePlanCodes = useStableInsurancePlanCodeArray(planCode);

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IntervalInput
                scenarioPieceType={scenarioPieceType}
                clientFileYear={year}
                scenarioPieceId={scenarioPiece?.scenarioPieceId ?? null} />
            </Grid>
            <Grid item xs={6}>
              <VipCapInput priceCap={scenarioPiece?.rowCropScenarioPieceExtendedData?.priceChangeCap ?? null} commodityCode={quote.commodityCode} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <VipFactorInput factor={scenarioPiece?.protectionFactor ?? null} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <TopIntervalInput topPrice={scenarioPiece?.rowCropScenarioPieceExtendedData?.topPrice ?? null} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <CustomPremiumInput customPremium={scenarioPiece?.rowCropScenarioPieceExtendedData?.customPremium ?? null} label="Premium" disabled={disabled} />
            </Grid>
            <Grid item xs={12}>
              <VipUnderlyingPlanInput
                underlyingScenarioPiece={scenarioPiece?.rowCropScenarioPieceExtendedData?.underlyingScenarioPieceType ?? null}
                disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <AdmUpperCoverageLevelInput
                label="MPCI Cov Level"
                fieldName={ExtendedDataFormInputParameterNames.UnderlyingUpperCoverageLevel}
                upperCoverageLevel={scenarioPiece?.rowCropScenarioPieceExtendedData?.underlyingUpperCoverageLevel ?? null} autoFocus disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              {isArp &&
                <ProtectionFactorInput
                  label="MPCI Factor"
                  fieldName={ExtendedDataFormInputParameterNames.UnderlyingProtectionFactor}
                  upperProtectionFactor={120}
                  lowerProtectionFactor={80}
                  protectionFactor={scenarioPiece?.rowCropScenarioPieceExtendedData?.underlyingProtectionFactor ?? null}
                  disabled={disabled} />
              }
            </Grid>

            <PriceInputGroup
              quote={quote}
              scenario={scenario}
              scenarioPiece={scenarioPiece}
              projectedPrice={scenario?.projectedPrice ?? null}
              harvestPrice={scenario?.harvestPrice ?? null} />

            {isArp &&
              <CountyYieldInputGroup
                year={year}
                countyId={countyId}
                typeId={scenario?.typeId ?? null}
                volatility={scenario?.volatility ?? 0}
                coverageLevel={scenarioPiece?.rowCropScenarioPieceExtendedData?.underlyingUpperCoverageLevel ?? null}
                practiceId={scenario?.practiceId ?? null}
                commodityCode={quote.commodityCode}
                insurancePlanCodes={insurancePlanCodes}
                scenarioPiece={scenarioPiece}
                scenarioId={scenario?.scenarioId ?? null}
                expectedCountyYield={scenario?.expectedCountyYield ?? null}
                actualCountyYield={scenario?.actualCountyYield ?? null}
                loadFromState={true} />
            }

            {!isArp &&
              <IndividualYieldInputGroup
                quote={quote}
                scenarioId={scenarioId}
                approvedYield={approvedYield}
                producerYield={scenario?.actualProducerYield ?? null}
                rateYield={rateYield}
                aphYield={aphYield} />
            }

            <Grid item xs={12}>
              <IsActiveInput isActive={scenarioPiece?.isActive ?? null} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
    </>
  );
};
