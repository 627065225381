import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Nullable } from '../../types/util/Nullable';
import { getStateCodeFromCountyId } from '../../utils/adm';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { fetchCountiesWithOffers, selectCountiesWithOffers } from '../../app/admSlice';
import { isNilOrEmpty, isNullOrUndefined } from '../../utils/nullHandling';
import { validateCounty } from '../../pages/units/validations/countyValidation';

interface CountyProps {
  countyId: Nullable<string>;
  year: number;
  disabled?: boolean;
  autoFocus?: boolean;
}

export type CountyFields = {
  countyId: string;
};

const CountyInput = ({ countyId, year, disabled = false, autoFocus = false }: CountyProps) => {
  const { control, setValue } = useFormContext();
  const dispatch = useAppDispatch();
  const counties = useAppSelector(selectCountiesWithOffers);
  const state = useWatch({ name: 'stateCode', control: control, defaultValue: undefined });

  useEffect(() => {
    if (isNullOrUndefined(countyId)) return;

    const stateCode = getStateCodeFromCountyId(countyId);
    dispatch(fetchCountiesWithOffers({ year, stateCode }));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(state)) return;

    setValue('countyId', '');

    if (!isNilOrEmpty(state))
      dispatch(fetchCountiesWithOffers({ year, stateCode: state }));
  }, [state]);

  return (
    <Controller
      name="countyId"
      control={control}
      rules={{ validate: validateCounty }}
      defaultValue={countyId}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="County"
          fullWidth
          select
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {counties.map(c => <MenuItem key={c.countyId} value={c.countyId}>{c.name}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default CountyInput;
