import { roundToPlaces, sum } from '@silveus/calculations';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import { HailProductId } from '../../../types/api/PrimaryKeys';
import { HailProduct } from '../../../types/api/hail/hailProduct';

export interface HailScenarioPieceWithIndemnities {
  hailScenarioPiece: HailScenarioPiece;
  indemnities: number[];
  netIndemnities: number[];
}

export function getLossPercentages(lossPercentIncrement: number, lossPercentMinValue: number, lossPercentMaxValue: number) {
  const lossPercentages: number[] = [];
  for (let i = lossPercentMinValue; i <= lossPercentMaxValue; i += lossPercentIncrement) {
    lossPercentages.push(i);
  }
  return lossPercentages;
}

export function getScenarioPieceIndemnityPairs(lossPercentages: number[], hailScenarioPieces: HailScenarioPiece[], getHailProductById: (hailProductId: HailProductId) => HailProduct) {
  return hailScenarioPieces.map(scenarioPiece => {
    const indemnities = getIndemnities(lossPercentages, scenarioPiece);
    const netIndemnities = getNetIndemnities(indemnities, getHailProductById(scenarioPiece.hailProductId).rate);
    const scenarioPieceIndemnityPair: HailScenarioPieceWithIndemnities = { hailScenarioPiece: scenarioPiece, indemnities: indemnities, netIndemnities: netIndemnities };
    return scenarioPieceIndemnityPair;
  });
}

export function getIndemnities(lossPercentages: number[], hailScenarioPiece: HailScenarioPiece) {
  // TODO #62895 -> we will use the scenarioPieceType from hailScenarioPiece to call the calculations to get the indemnities here
  return lossPercentages.map(lossPercent => roundToPlaces((lossPercent - 10), 2));
}

export function getNetIndemnities(indemnities: number[], premium: number) {
  return indemnities.map(indemnity => roundToPlaces((indemnity - premium), 2)); // TODO #62895 -> unsure if this needs more; this is temporary to populate net indemnity rows with dummy data
}

export function getBlendedNetValues(lossPercentages: number[], hailScenarioPieces: HailScenarioPiece[], getHailProductById: (hailProductId: HailProductId) => HailProduct) {
  const hailScenarioPiecesWithIndemnities = getScenarioPieceIndemnityPairs(lossPercentages, hailScenarioPieces, getHailProductById);
  const includedHailScenarioPiecesWithIndemnities = hailScenarioPiecesWithIndemnities.filter(hspwi => hspwi.hailScenarioPiece.includeInBlended);
  const hailScenarioPieceWithIndemnities = includedHailScenarioPiecesWithIndemnities.at(0);
  if (hailScenarioPieceWithIndemnities === undefined || hailScenarioPieceWithIndemnities.netIndemnities.length <= 0) return;
  const flatIndemnities = includedHailScenarioPiecesWithIndemnities.map(hspwi => hspwi.netIndemnities);
  if (!areAllArraysSameLength(flatIndemnities)) return;
  const blendedNetValues = hailScenarioPieceWithIndemnities.netIndemnities.map((indemnity, index) => sum(includedHailScenarioPiecesWithIndemnities, hspwi => hspwi.netIndemnities[index]));
  return blendedNetValues;
}

function areAllArraysSameLength(arrayOfArrays: number[][]) {
  const firstArrayLength = arrayOfArrays.at(0)?.length ?? 0;
  return arrayOfArrays.every(item => item.length === firstArrayLength);
}
