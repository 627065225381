import { getFriendlyScenarioPieceName } from '../../../utils/scenarioPieceUtils';
import { ScenarioPieceTypeAttributes, UnitStructureAttributes, UnitStructureCode } from '@silveus/calculations';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { ScenarioPiece } from '../../../types/api/ScenarioPiece';
import { isRowCropScenarioPiece } from '../../../utils/castingUtils';

/** Returns a piece's fully qualified name, in the format of:
 * [Type] [Coverage] [UnitStructure]
 */
export const getFullyQualifiedScenarioPieceName = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece, useNonGroupScenarioPieceName: boolean = false) => {
  const scenarioPieceTypeName = useNonGroupScenarioPieceName ? getScenarioPieceNameWithoutGroup(scenarioPiece) : getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType);
  if (isRowCropScenarioPiece(scenarioPiece)) {
    const coverageLevel = getCoverageLevelTextForScenarioPieceName(scenarioPiece);
    const unitStructureText = getUnitStructureTextForScenarioPieceName(scenarioPiece);
    return `${scenarioPieceTypeName} ${coverageLevel} ${unitStructureText}`;
  }
  return `${scenarioPieceTypeName}`;
};

export const getFullyQualifiedScenarioPieceNameOnlyName = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece, useNonGroupScenarioPieceName: boolean = false) => {
  const scenarioPieceTypeName = useNonGroupScenarioPieceName ? getScenarioPieceNameWithoutGroup(scenarioPiece) : getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType);
  return `${scenarioPieceTypeName}`;
};

export const getFullyQualifiedScenarioPieceNameOnlyCoverageLevel = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece, useNonGroupScenarioPieceName: boolean = false) => {
  const scenarioPieceTypeName = useNonGroupScenarioPieceName ? getScenarioPieceNameWithoutGroup(scenarioPiece) : getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType);
  return `${scenarioPieceTypeName} ${isRowCropScenarioPiece(scenarioPiece) ? getCoverageLevelTextForScenarioPieceName(scenarioPiece) : ''}`;
};

export const getFullyQualifiedScenarioPieceNameOnlyUpperCoverageLevel = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece, useNonGroupScenarioPieceName: boolean = false) => {
  const scenarioPieceTypeName = useNonGroupScenarioPieceName ? getScenarioPieceNameWithoutGroup(scenarioPiece) : getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType);
  return `${scenarioPieceTypeName} ${isRowCropScenarioPiece(scenarioPiece) ? `${scenarioPiece.upperCoverageLevel}%` : ''}`;
};

export const getCoverageLevelTextForScenarioPieceName = (scenarioPiece: RowCropScenarioPiece) => {
  return scenarioPiece.lowerCoverageLevel === 0 ? `${scenarioPiece.upperCoverageLevel}%` : `${scenarioPiece.upperCoverageLevel}-${scenarioPiece.lowerCoverageLevel}%`;
};

export const getUnitStructureTextForScenarioPieceName = (scenarioPiece: RowCropScenarioPiece) => {
  return ![UnitStructureCode.Unset, UnitStructureCode.AU].includes(scenarioPiece.unitStructure) ? UnitStructureAttributes[scenarioPiece.unitStructure].name : '';
};

export const getScenarioPieceNameWithoutGroup = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece): string => {
  if (isRowCropScenarioPiece(scenarioPiece)){
    const spAttributes = Object.values(ScenarioPieceTypeAttributes).find(spt => spt.value === scenarioPiece.scenarioPieceType);
    return `${spAttributes?.description}`;
  }
  return getFullyQualifiedScenarioPieceNameOnlyName(scenarioPiece);
};

export default getFullyQualifiedScenarioPieceName;