import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuList,
  Paper,
  Popper,
  useTheme
} from '@mui/material';
import { useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectIsLightMode } from '../../app/userSettingsSlice';

type UnitsModalHeaderControlsProps = {
  onCancel: () => void;
  onSave: (shouldCloseOnSave: boolean) => void;
  isSaving: boolean;
  isSaveDisabled: boolean;
}

const UnitsModalHeaderControls = ({ onCancel, onSave, isSaving, isSaveDisabled }: UnitsModalHeaderControlsProps) => {
  const theme = useTheme();
  const isLightMode = useAppSelector(selectIsLightMode);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [splitButtonExpanded, setSplitButtonExpanded] = useState(false);

  const handleSplitButtonClose = () => {
    setSplitButtonExpanded(false);
  };

  const handleToggle = () => {
    setSplitButtonExpanded(prevOpen => !prevOpen);
  };

  return (
    <Grid container columnGap={1}>
      <Grid item>
        <Button id="btn-units-close" sx={{ color: theme => theme.palette.common.white, backgroundColor: 'transparent', borderColor: theme => theme.palette.common.white }} variant="outlined" onClick={onCancel} disabled={isSaving}>Close</Button>
      </Grid>
      <Grid item>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="outlined primary button group">
          <Button sx={{ backgroundColor: isLightMode ? theme.palette.common.white : theme.palette.common.black, color: theme.palette.primary.main }} onClick={() => onSave(false)} id="btn-save" variant="contained" disabled={isSaveDisabled}>Save</Button>
          <Button id="btn-split" sx={{ backgroundColor: isLightMode ? theme.palette.common.white : theme.palette.common.black, color: theme.palette.primary.main, marginLeft: '0 !important' }} size="small" onClick={handleToggle} disabled={isSaveDisabled}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={splitButtonExpanded}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleSplitButtonClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    <Button id="btn-save-close" type="submit" onClick={() => onSave(true)} variant="text">Save and Close</Button>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default UnitsModalHeaderControls;
