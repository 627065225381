import { ScenarioId, ScenarioPieceGroupId } from '../../types/api/PrimaryKeys';
import { networkOnly } from '../../utils/cachingStrategies';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import {
  createScenarioPieceGroup,
  deleteScenarioPieceGroup,
  getScenarioPieceGroupsForScenario, getScenarioPieceGroupsForScenarios, updateScenarioPieceGroup
} from '../scenarioPieceGroups.service';
import { ScenarioPieceGroup } from '../../types/api/ScenarioPieceGroup';

export const getScenarioPieceGroupsForScenarioRequest = async (scenarioId: ScenarioId): Promise<ScenarioPieceGroup[]> => {
  const request = () => getScenarioPieceGroupsForScenario(scenarioId);
  return networkOnly(request);
};

export const getScenarioPieceGroupsForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<ScenarioPieceGroup[]> => {
  const request = () => getScenarioPieceGroupsForScenarios(scenarioIds);
  return networkOnly(request);
};

export const createScenarioPieceGroupRequest = async (scenarioPieceGroup: ScenarioPieceGroup): Promise<CreatedItemResult<ScenarioPieceGroupId>> => {
  const request = () => createScenarioPieceGroup(scenarioPieceGroup);
  return networkOnly(request);
};

export const updateScenarioPieceGroupRequest = async (scenarioPieceGroup: ScenarioPieceGroup): Promise<void> => {
  const request = () => updateScenarioPieceGroup(scenarioPieceGroup);
  return networkOnly(request);
};

export const deleteScenarioPieceGroupRequest = async (scenarioPieceGroupId: ScenarioPieceGroupId): Promise<void> => {
  const request = () => deleteScenarioPieceGroup(scenarioPieceGroupId);
  return networkOnly(request);
};

