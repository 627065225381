import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { FormInputProps } from '../../scenario/formInputProps';

interface MpIsHarvestPriceOptionProps extends FormInputProps {
  isHarvestPriceOption: boolean;
}

export type MpIsHarvestPriceOptionFields = {
  isHarvestPriceOption: boolean;
}

const MpIsHarvestPriceOptionInput = ({ isHarvestPriceOption, autoFocus = false, disabled = false }: MpIsHarvestPriceOptionProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="isHarvestPriceOption"
      control={control}
      defaultValue={isHarvestPriceOption}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl
          error={!!error}
          disabled={disabled}>
          <FormControlLabel
            label="Harvest Price Option"
            control={
              <Checkbox
                {...field}
                checked={value}
                inputRef={ref}
                onChange={e => field.onChange(e.target.checked)}
                autoFocus={autoFocus}
              />
            } />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl >
      )}
    />
  );
};

export default MpIsHarvestPriceOptionInput;
