const GRID_COLUMN_TYPES = {
  text: {
    filter: 'agTextColumnFilter',
    sortable: true,
    flex: 1,
    resizable: true,
  },
  date: {
    filter: 'agDateColumnFilter',
    sortable: true,
    flex: 1,
    resizable: true,
  },
  number: {
    filter: 'agNumberColumnFilter',
    sortable: true,
    flex: 1,
    resizable: true,
  },
  actions: {
    maxWidth: 80,
    cellClass: 'ag-cell-value-clip',
  },
} as const;

export default GRID_COLUMN_TYPES;