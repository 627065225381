import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import ApplicationWizardPage from './applicationWizardPage';
import { ApplicationWizardStep, ApplicationWizardStepProps } from './applicationWizardStep';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import { Grid } from '@mui/material';
import ContactInfoGrid from '../../../components/ag-grid/contactInfoGrid/contactInfoGrid';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { validatePersonOfInterest } from '../../../components/ag-grid/contactInfoGrid/validation/validateContactInfo';
import { RootState } from '../../../app/store';
import { selectInsuredsPersonsOfInterestByType } from '../../../app/personOfInterestSlice';
import { selectEntityTypes, selectTaxTypes } from '../../../app/insuredsSlice';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import { PersonOfInterestTypes } from '../../../types/api/insureds/PersonOfInterest';

export const LandlordTenantStep: ApplicationWizardStep = {
  isDisabled(app: ApplicationWizard, state: RootState) {
    return !app.anyChanges ||
      (!app.insuringLLTs) ||
      (!app.isGeneratingForms);
  },
  title: 'LL/T Info',
  index: 6,
  formId: 'form-landlord-tenant',
  icon: <OtherHousesOutlinedIcon />,
  isValid(app: ApplicationWizard, state: RootState) {
    const isDisabled = this.isDisabled(app, state);
    // If step is disabled we do not care if all SBIs are valid here because the SBIs won't
    // be included in the application.
    if (isDisabled) {
      return {
        isValid: true,
        errors: [],
      };
    }

    const insuredId = app.isNewEntity && isNotNullOrUndefined(app.newInsuredId) ? app.newInsuredId : app.insuredId;
    const llts = selectInsuredsPersonsOfInterestByType(state, insuredId, PersonOfInterestType.LandLordOrTenant);

    if (app.anyChanges && app.insuringLLTs && llts.length === 0) {
      return {
        isValid: false,
        errors: ['No Landlords or Tenants found'],
      };
    }

    const entityTypes = selectEntityTypes(state);
    const taxIdTypes = selectTaxTypes(state);
    const lltValidationResult = llts.map(x => validatePersonOfInterest(x, entityTypes, taxIdTypes, app.isGettingESignatures)).every(x => x === true);
    return {
      isValid: lltValidationResult,
      errors: lltValidationResult ? [] : ['Errors found on LLTs'],
    };
  },
};

const LandlordTenantPage = ({ application, navigateToPreviousStep, navigateToNextStep, updateIsInEditMode, isInEditMode, isLoading, updateIsLoading }: ApplicationWizardStepProps) => {
  const handleNavigateToPrevious = () => {
    navigateToPreviousStep();
  };

  const personOfInterestTypes: PersonOfInterestTypes = [PersonOfInterestType.LandLordOrTenant];


  return (
    <ApplicationWizardPage loading={isLoading} tabIndex={LandlordTenantStep.index} onPreviousClick={handleNavigateToPrevious} onNextClick={navigateToNextStep} disabled={isInEditMode || isLoading}>
      <Grid item container sx={{ flexDirection: 'row', flexWrap: 'nowrap', height: 'calc(100% - 55px)', pb: 1 }}>
        <ContactInfoGrid updateIsLoading={updateIsLoading} contactTypes={personOfInterestTypes} application={application} updateIsInEditMode={updateIsInEditMode} isLoading={isLoading} />
      </Grid>
    </ApplicationWizardPage>
  );
};

export default LandlordTenantPage;