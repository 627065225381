import { getMPowerDLiabilityFactors, roundToPlaces } from '@silveus/calculations';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputParameterNames } from '../../constants/inputConstants';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { FormInputProps } from '../../scenario/formInputProps';
import { formatPercentDigits } from '../../../../utils/formatNumbers';
import { Nullable } from '../../../../types/util/Nullable';

export interface MPowerDFactorInputProps extends FormInputProps {
  protectionFactor: Nullable<number>;
}

export const MPowerDFactorInput = ({ protectionFactor, autoFocus = false, disabled = false }: MPowerDFactorInputProps) => {
  const { control } = useFormContext();

  const availableFactors = getMPowerDLiabilityFactors().map(x => roundToPlaces(x * 100, 0)).sort((a, b) => b - a);

  return (
    <Controller
      name={FormInputParameterNames.ProtectionFactor}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.restrictNotOnListValue(availableFactors),
      }}
      defaultValue={protectionFactor}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label="Protection Factor"
          fullWidth
          select
          value={value}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {availableFactors.map(f => <MenuItem key={f} value={f}>{formatPercentDigits(f * 0.01, 2)}</MenuItem>)}
        </TextField>
      )}
    />
  );
};
