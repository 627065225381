import { useFormContext, useWatch } from 'react-hook-form';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferExpectedInputCosts } from '../../../app/admSlice';
import { useEffect } from 'react';
import { isApiDataStale, isSavedDataBeingLoaded } from '../../../utils/formInputUtils';
import MpExpectedInputCostsInput, { MpExpectedInputCostsProps } from '../scenarioPiece/mp/mpExpectedInputCosts';
import { ExtendedDataFormInputParameterNames } from '../constants/inputConstants';

export type MpExpectedInputCostsFields = {
  expectedInputCosts: number;
}

const ScenarioMpExpectedInputCostsInput = ({ expectedInputCosts, sx, autoFocus = false, disabled = false }: MpExpectedInputCostsProps) => {
  const fieldName = ExtendedDataFormInputParameterNames.ExpectedInputCosts;
  const { control, setValue, getValues, trigger } = useFormContext();
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });
  const offerExpectedInputCosts = useAppSelector(selectOfferExpectedInputCosts);

  useEffect(() => {
    //When the API data is changed, load it into the field,
    // except for the initial load when there is saved data
    const currentPractice = getValues('practiceId');

    const isThereStaleData = isApiDataStale(currentPractice, offerExpectedInputCosts);
    const isLoadingSavedData = isSavedDataBeingLoaded(expectedInputCosts, practice);

    if (isThereStaleData || isLoadingSavedData || offerExpectedInputCosts === null) return;

    setValue(fieldName, offerExpectedInputCosts);
    trigger(fieldName);
  }, [offerExpectedInputCosts]);

  useEffect(() => {
    //When the practice is changed, clear out the current value
    if (practice === undefined) return;

    setValue(fieldName, 0);
  }, [practice]);

  return (
    <MpExpectedInputCostsInput
      expectedInputCosts = {expectedInputCosts}
      sx = {sx}
      autoFocus = {autoFocus}
      disabled = {disabled}
    />
  );
};

export default ScenarioMpExpectedInputCostsInput;
