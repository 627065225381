import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from '@mui/material';
import PremiumBreakdownChartData from '../../../types/app/PremiumBreakdownData';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import { setSelectedPremiumBreakdownData } from '../../../app/premiumBreakdownSlice';
import PremiumBreakdown from '../../../types/api/premiumBreakdown';
import { selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder } from '../../../app/scenarioPiecesSlice';
import { selectPremiumBreakdownOptions } from '../../../app/userSettingsSlice';

type PremiumBreakdownProductSummaryProps = {
  premiumBreakdownData: PremiumBreakdownChartData[];
  premiumBreakdown: PremiumBreakdown;
}

const BorderlessCell = styled(TableCell)({
  borderBottom: 'none',
  padding: '4px',
});

const PremiumBreakdownProductSummary = ({ premiumBreakdownData, premiumBreakdown }: PremiumBreakdownProductSummaryProps) => {
  const dispatch = useAppDispatch();
  const premiumBreakdownOptions = useAppSelector(selectPremiumBreakdownOptions);
  const scenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder, premiumBreakdown.primaryScenarioId);

  const sortedData = premiumBreakdownData.sort((a, b) => scenarioPieces.findIndex(x => x.scenarioPieceId === a.id) - scenarioPieces.findIndex(x => x.scenarioPieceId === b.id));

  const onRowClick = (premiumBreakdownData: PremiumBreakdownChartData) => {
    dispatch(setSelectedPremiumBreakdownData(premiumBreakdownData));
  };

  return (
    <Grid container sx={{ p: 1, pt: 0, alignItems: 'flex-start', userSelect: 'none' }}>
      <Grid item xs={12} p={1} textAlign="center">
        <Typography variant="subtitle2">All Products Summary</Typography>
      </Grid>
      <Grid item xs={12} display={'flex'}>
        <Table>
          <TableHead>
            <TableRow>
              <BorderlessCell sx={{ p: 0 }}></BorderlessCell>
              {premiumBreakdownOptions.summary.premium && (
                <BorderlessCell align="right" sx={{ minWidth: '75px', p: 0 }}><Typography variant="label">Premium</Typography></BorderlessCell>
              )}
              {premiumBreakdownOptions.summary.coverage && (
                <BorderlessCell align="right" sx={{ minWidth: '75px', p: 0 }}><Typography variant="label">Cov $</Typography></BorderlessCell>
              )}
              {premiumBreakdownOptions.summary.subsidy && (
                <BorderlessCell align="right" sx={{ minWidth: '90px', p: 0 }}><Typography variant="label">Subsidy $</Typography></BorderlessCell>
              )}
              {premiumBreakdownOptions.summary.subsidyPercent && (
                <BorderlessCell align="right" sx={{ minWidth: '90px', p: 0 }}><Typography variant="label">Subsidy %</Typography></BorderlessCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.filter(x => !(x.isSpacer ?? false)).map(x => (
              <TableRow key={x.id} style={{ cursor: 'pointer' }} onClick={() => onRowClick(x)}>
                <BorderlessCell>
                  <Box sx={{ height: '40px', minWidth: '90px', backgroundColor: x.fill, borderRadius: 1 }}>
                    <Grid container direction="column" sx={{ textAlign: 'center' }}>
                      <Grid item xs>
                        <Typography sx={{ whiteSpace: 'nowrap', color: theme => theme.palette.common.black }} variant="value">{x.productName}</Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="value" sx={{ color: theme => theme.palette.common.black }}>{x.lowerCoverageLevel}% - {x.upperCoverageLevel}%</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </BorderlessCell>
                {premiumBreakdownOptions.summary.premium && (
                  <BorderlessCell align="right">
                    <CurrencyText currencyAmount={x.premium ?? 0} colorize={false} />
                  </BorderlessCell>
                )}
                {premiumBreakdownOptions.summary.coverage && (
                  <BorderlessCell align="right">
                    <CurrencyText currencyAmount={x.coverage ?? 0} colorize={false} />
                  </BorderlessCell>
                )}
                {premiumBreakdownOptions.summary.subsidy && (
                  <BorderlessCell align="right">
                    <CurrencyText currencyAmount={x.subsidy ?? 0} colorize={false} />
                  </BorderlessCell>
                )}
                {premiumBreakdownOptions.summary.subsidyPercent && (
                  <BorderlessCell align="right">
                    <Typography variant="value">{x.subsidyPercent}%</Typography>
                  </BorderlessCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default PremiumBreakdownProductSummary;