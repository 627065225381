import { Grid } from '@mui/material';
import { ColorResult, RGBColor } from 'react-color';
import MatrixCustomColorPicker from './MatrixCustomColorPicker';
import { useState } from 'react';
import { EditableInputStyles } from 'react-color/lib/components/common/EditableInput';
import { CellTextColorRule } from '@silveus/react-matrix';
import { UiCellTextColorRule } from './types';

interface Props {
  selectedColorRule: UiCellTextColorRule | null;
  customColorPickerStyles: EditableInputStyles | undefined;
  onUpdateColor: (id: number, newValue: CellTextColorRule) => void;
}

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

function rgbToHex(r: number, g: number, b: number) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const MatrixColorSelectionUi = ({ selectedColorRule, customColorPickerStyles, onUpdateColor }: Props) => {
  const [currentColor, setCurrentColor] = useState<ColorResult>();

  const handleInputChange = (colorResult: ColorResult) => {
    if (currentColor === undefined) return;

    //react-color has to make this really difficult because when changing an input, only the value that
    //was changed is a property on the return object, instead of all of them like when you click the picker.
    //This means we can't just set objects like we do elsewhere, but we have to update the values on our stored object
    //with the values of the new object, then if the changed value was one of the RGB values, we need to recompute
    //the hex value and use that to then be able to update the current colors

    //Clone properties over from the new colorResult to our "currentColor" to accomodate for missing ones
    const current: ColorResult = { ...currentColor };
    let newHex = '';

    Object.entries(colorResult).forEach(([key, value]) => {
      //Key is uppercase, but the key value on colorResult is lowercase
      const lowercaseKey = key.toLowerCase();

      //They don't even pass a whole RGB object, so we have to update the values in that explicitly
      if (['r', 'g', 'b'].includes(lowercaseKey)) {
        //Parse the string value to number
        const numberValue = parseInt(value);

        //If the parsed value is not a number, we don't want to update, because if we do, the rest of the values will zero
        if (isNaN(numberValue)) return;

        current.rgb[lowercaseKey as keyof RGBColor] = numberValue;
        newHex = rgbToHex(current.rgb.r, current.rgb.g, current.rgb.b);
      } else {
        //Handle the case that somebody changes the hex value
        newHex = value;
      }
    });

    current.hex = newHex;

    handleColorChange(current);
  };

  const handleColorChange = (colorResult: ColorResult) => {
    if (selectedColorRule === null) { return; }

    setCurrentColor(colorResult);
    onUpdateColor(selectedColorRule.id, {
      ...selectedColorRule,
      color: colorResult.hex,
    });
  };

  return (
    <Grid container className="color-selection-ui">
      <MatrixCustomColorPicker
        color={selectedColorRule?.color}
        customColorPickerStyles={customColorPickerStyles}
        onChange={handleColorChange}
        onInputChange={handleInputChange}
      />
    </Grid>
  );
};