import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { openMatrixColorPickerDialog, selectIsMatrixColorPickerOpen } from '../../app/matricesSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { UserMatrixColor } from '../../types/api/userSettings/UserMatrixColor';
import { beginLoading, endLoading } from '../../app/loaderSlice';
import { CellTextColorRule } from '@silveus/react-matrix';
import { modifyMatrixColorSettingsForUser, selectIsLightMode, selectUserMatrixColorSettingsOrDefault } from '../../app/userSettingsSlice';
import { MatrixColorPicker as ColorPicker } from '../../components/matrix-color-picker/MatrixColorPicker';

import './matrixColorPicker.scss';

const userSettingToColorRules = (matrixColors: readonly UserMatrixColor[]): CellTextColorRule[] => {
  return matrixColors.map(color => ({ threshold: color.colorValue, color: color.hexColor }));
};

const colorRulesToUserSetting = (matrixColors: CellTextColorRule[]): UserMatrixColor[] => {
  return matrixColors.map(color => ({ colorValue: color.threshold, hexColor: color.color }));
};

export const MatrixColorPicker = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsMatrixColorPickerOpen);
  const matrixColors = useAppSelector(selectUserMatrixColorSettingsOrDefault);
  const theme = useTheme();
  const isLightMode = useAppSelector(selectIsLightMode);

  const formId = 'matrixColorPickerForm';

  const [colorRules, setColorRules] = useState<CellTextColorRule[]>(userSettingToColorRules(matrixColors));

  const handleClose = () => {
    dispatch(openMatrixColorPickerDialog(false));
  };

  const handleSubmit = async () => {
    const mappedColorRules = colorRulesToUserSetting(colorRules);

    dispatch(beginLoading());

    await dispatch(modifyMatrixColorSettingsForUser({ matrixColorSettings: mappedColorRules }));

    dispatch(endLoading());

    handleClose();
  };

  const handleColorListChanged = (colorRules: CellTextColorRule[]) => {
    setColorRules(colorRules);
  };

  useEffect(() => {
    setColorRules(userSettingToColorRules(matrixColors));
  }, [matrixColors]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Grid container rowSpacing={2} columnSpacing={3}>
          {/*Title section w/ close button*/}
          <Grid container item xs={12} justifyContent="space-between" alignItems="center">
            <Grid item xs>
              <Typography>Color Selection for Numeric Range</Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton onClick={handleClose}><CloseIcon /></IconButton>
            </Grid>
            <Grid item xs={12}>
              <hr/>
            </Grid>
          </Grid>
          <Grid className={isLightMode ? '' : 'color-picker-container dark'} item xs={12}>
            <ColorPicker
              onColorListChanged={handleColorListChanged}
              defaultColorList={colorRules}
              customColorPickerStyles={{
                label: {
                  color: `${theme.palette.text.primary}`,
                },
              }}
              cssClasses={{
                colorValueCardCSSClasses: 'themed-color-value',
                iconClasses: 'themed-icon',
                labelClasses: 'themed-label',
              }}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Button variant="contained" type="submit" id="btn-submit-color-picker" form={formId} onClick={handleSubmit}>Save</Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};