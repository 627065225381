import InactiveScenarioComponentCard from '../scenarioPiece/inactiveScenarioComponentCard';
import { ScenarioPieceGroup } from '../../types/api/ScenarioPieceGroup';
import { ScenarioPieceGroupTypeAttributes } from '@silveus/calculations';
import { modifyScenarioPieceGroup } from '../../app/scenarioPieceGroupsSlice';
import { updateUnitGroupsForScenarioPiece } from '../../app/unitGroupsSlice';
import { updateCalculationForScenario } from '../../app/calculationResultsSlice';
import { useAppDispatch } from '../../hooks/reduxHooks';

interface InactiveScenarioPieceGroupCardProps {
  scenarioPieceGroup: ScenarioPieceGroup;
  isScenarioActive: boolean;
}

const InactiveScenarioPieceGroupCard = ({ scenarioPieceGroup, isScenarioActive }: InactiveScenarioPieceGroupCardProps) => {
  const dispatch = useAppDispatch();
  const label = Object.values(ScenarioPieceGroupTypeAttributes).find(spt => spt.value === scenarioPieceGroup.scenarioPieceGroupType)?.description ?? '';

  const updateScenarioPieceGroup = async (newScenarioPieceGroup: ScenarioPieceGroup, isActive: boolean) => {
    await dispatch(modifyScenarioPieceGroup({ scenarioPieceGroup: { ...newScenarioPieceGroup, isActive: isActive } }));
    const unitGroupPromises = newScenarioPieceGroup.scenarioPieceGroupMembers.map(scenarioPieceGroupMember => {
      return dispatch(updateUnitGroupsForScenarioPiece({ scenarioPieceId: scenarioPieceGroupMember.scenarioPieceId }));
    });
    await Promise.all(unitGroupPromises);
    await dispatch(updateCalculationForScenario({ scenarioId: newScenarioPieceGroup.scenarioId }));
  };

  return (
    <InactiveScenarioComponentCard
      scenarioComponent={scenarioPieceGroup}
      updateScenarioComponent={updateScenarioPieceGroup}
      isScenarioActive={isScenarioActive}
      label={label}
    />
  );
};

export default InactiveScenarioPieceGroupCard;