import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Matrix from '../types/api/Matrix';
import { MatrixId, MatrixPresetId, QuoteId, ScenarioId, SweetSpotId } from '../types/api/PrimaryKeys';
import { Nullable } from '../types/util/Nullable';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { getKeyedStateValues, getKeyedStateArrayGroupedBy, KeyedState, initialKeyedState } from './sliceHelpers';
import { AppDispatch, RootState } from './store';
import ScenarioMatrix from '../types/api/ScenarioMatrix';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import { selectMatrixPresetById, selectMatrixPresets, selectUserLinkedMatrices } from './userSettingsSlice';
import { UserLinkedMatrices } from '../types/api/userSettings/UserLinkedScenarios';
import { StrictOmit } from '../types/util/StrictOmit';
import { MatrixType } from '../types/api/enums/matrixType/matrixType.enum';
import SweetSpot from '../types/api/SweetSpot';
import { selectScenarioById } from './scenariosSlice';
import { selectQuoteById } from './quotesSlice';
import {
  createMatrixRequest,
  deleteMatrixRequest, getMatricesByScenarioIdsRequest,
  updateMatricesBatchRequest,
  updateMatrixRequest
} from '../services/requestInterception/matrixRequestInterceptor';
import { debounce, maxBy } from 'lodash';
import { orderMap } from '../utils/mapHelpers';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import { selectSelectedMatrixScenarioId } from './scenarioAnalysisSlice';

export interface MatricesState {
  allMatrices: SliceDataState<MatrixId, Matrix>;
  selectedMatrixPresetIdByMatrixId: KeyedState<MatrixId, MatrixPresetId>;
  currentlySelectedMatrixId: Nullable<MatrixId>;
  isColorPickerOpen: boolean;
}

const initialState: MatricesState = {
  allMatrices: initialSliceDataState(),
  selectedMatrixPresetIdByMatrixId: initialKeyedState(),
  currentlySelectedMatrixId: null,
  isColorPickerOpen: false,
};

const DelayUntilSweetSpotsShouldPersistMs = 1000;

export const matricesSlice = createSlice({
  name: 'matrices',
  initialState: initialState,
  reducers: {
    setUpdatedSweetSpotState(state, action: PayloadAction<{ matrixId: MatrixId, sweetSpots: SweetSpot[] }>) {
      const matrixId = action.payload.matrixId;
      const matrix = state.allMatrices.data[matrixId];
      if (matrix) {
        matrix.sweetSpots = action.payload.sweetSpots;
      }
    },
    setCurrentlySelectedMatrixId(state, action: PayloadAction<Nullable<MatrixId>>) {
      state.currentlySelectedMatrixId = action.payload;
    },
    openMatrixColorPickerDialog(state, action: PayloadAction<boolean>) {
      state.isColorPickerOpen = action.payload;
    },
    setSelectedMatrixPresetIdForMatrixId(state, action: PayloadAction<{ matrixId: MatrixId, matrixPresetId: MatrixPresetId }>) {
      state.selectedMatrixPresetIdByMatrixId[action.payload.matrixId] = action.payload.matrixPresetId;
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allMatrices, s => s.matrixId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addMatrix,
      affectedIds: arg => arg.matrix.matrixId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchMatricesByScenarioIds,
      affectedIds: () => [],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeMatrix,
      affectedIds: arg => arg.matrix.matrixId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyMatrix,
      affectedIds: arg => arg.matrix.matrixId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: updateMatrices,
      affectedIds: arg => arg.matrices.map(m => m.matrixId),
    });
  },
});

export const { setCurrentlySelectedMatrixId, openMatrixColorPickerDialog, setUpdatedSweetSpotState, setSelectedMatrixPresetIdForMatrixId } = matricesSlice.actions;

export const selectCurrentlySelectedMatrixId = (state: RootState) => state.matrices.currentlySelectedMatrixId;
const selectMatrixDictionary = (state: RootState) => state.matrices.allMatrices.data;
export const selectMatrixById = (state: RootState, matrixId: MatrixId) => state.matrices.allMatrices.data[matrixId] ?? null;
export const selectIsMatrixColorPickerOpen = (state: RootState) => state.matrices.isColorPickerOpen;
export const selectMatrixPresetByMatrixIdDictionary = (state: RootState) => state.matrices.selectedMatrixPresetIdByMatrixId;
export const selectMatrixPresetIdForMatrixId = (state: RootState, matrixId: Nullable<MatrixId>) => {
  if (matrixId === null) {
    return null;
  }

  const matrixIdDictionary = selectMatrixPresetByMatrixIdDictionary(state);
  return matrixIdDictionary[matrixId] ?? null;
};

export const selectAllComparisonMatrices = createSelector([selectMatrixDictionary], result => {
  const filteredMatrices = getKeyedStateValues(result).filter(x => x.matrixType === MatrixType.Comparison);
  const map = getKeyedStateArrayGroupedBy(filteredMatrices, m => m.primaryScenarioId);
  const ordered = orderMap(map, DefaultOrders.matrices);
  return ordered;
});

export const selectAllAnalysisMatrices = createSelector([selectMatrixDictionary], result => {
  const filteredMatrices = getKeyedStateValues(result).filter(x => x.matrixType === MatrixType.Analysis);
  const map = new Map<ScenarioId, Matrix>();

  for (const matrix of filteredMatrices) {
    map.set(matrix.primaryScenarioId, matrix);
  }

  return map;
});

const selectCurrentlySelectedMatrix = createSelector([
  selectSelectedMatrixScenarioId,
  selectAllAnalysisMatrices,
], (scenarioId, analysisMatrices) => {
  if (scenarioId === null) {
    return null;
  }

  return analysisMatrices.get(scenarioId) ?? null;
});

export const selectCurrentlySelectedMatrixPreset = (state: RootState) => {
  const selectedMatrix = selectCurrentlySelectedMatrix(state);
  const selectedMatrixId = selectedMatrix?.matrixId ?? null;

  const selectedMatrixPresetId = selectMatrixPresetIdForMatrixId(state, selectedMatrixId);
  const selectedMatrixPreset = selectMatrixPresetById(state, selectedMatrixPresetId);

  return selectedMatrixPreset;
};

export const addMatrixFromForm = createAppAsyncThunk('matrices/addMatrixFromForm', async ({ matrixData, includedScenarios }: { matrixData: StrictOmit<Matrix, 'matrixId' | 'scenarioMatrices' | 'sweetSpots'>, includedScenarios: ScenarioId[] }, thunkApi) => {
  const matrixId: MatrixId = generatePrimaryKey();
  const scenarioMatricesToAdd: ScenarioMatrix[] = includedScenarios.map(scenarioId => {
    return {
      scenarioId: scenarioId,
      matrixId: matrixId,
      scenarioMatrixId: generatePrimaryKey(),
    };
  });

  const matrix: Matrix = {
    ...matrixData,
    matrixId: matrixId,
    scenarioMatrices: scenarioMatricesToAdd,
    sweetSpots: buildDefaultSweetSpotSet(matrixData.matrixType),
  };

  await thunkApi.dispatch(addMatrix({ matrix }));
  return matrix;
});

export const addMatrix = createAppAsyncThunk('matrices/add-matrix', async ({ matrix }: { matrix: Matrix }, thunkApi) => {
  await createMatrixRequest(matrix);
  thunkApi.dispatch(setCurrentlySelectedMatrixId(matrix.matrixId));
  const state = thunkApi.getState();
  const matrixPresets = selectMatrixPresets(state);
  const defaultPreset = matrixPresets.find(mp => mp.isDefault);
  if (defaultPreset !== undefined) {
    thunkApi.dispatch(setSelectedMatrixPresetIdForMatrixId({ matrixId: matrix.matrixId, matrixPresetId: defaultPreset.matrixPresetId }));
  }
  return matrix;
});

export const fetchMatricesByScenarioIds = createAppAsyncThunk('matrices/fetchMatricesByScenarioIds', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }, thunkApi) => {
  const matrices = await getMatricesByScenarioIdsRequest(scenarioIds);
  return matrices;
});

/** This method will mutate the destination matrix inline. */
const copyPropertiesSharedByLinkedMatrices = (source: Matrix, destination: Matrix) => {
  destination.bottomAxisIntegerOffset = source.bottomAxisIntegerOffset;
  destination.bottomAxisOffsetType = source.bottomAxisOffsetType;
  destination.bottomAxisPercentChange = source.bottomAxisPercentChange;
  destination.columnCount = source.columnCount;
  destination.includeFilter = source.includeFilter;
  destination.midPrice = source.midPrice;
  destination.midYield = source.midYield;
  destination.priceScale = source.priceScale;
  destination.rowCount = source.rowCount;
  destination.showFilter = source.showFilter;
  destination.yieldScale = source.yieldScale;

  // Temporarily undoing sweet spot linking
  //destination.sweetSpots = copyAndReturnSweetSpotPropertiesForLinkedMatrices(source.sweetSpots, destination.sweetSpots);
};

const copyAndReturnSweetSpotPropertiesForLinkedMatrices = (source: readonly SweetSpot[], destination: readonly SweetSpot[]) => {
  const modifiedSweetSpots: SweetSpot[] = [];

  for (const sourceSweetSpot of source) {
    // Why I'm using the order to run a match: I see no other option. It's the only property that should never change.
    // A previous implementation used the sweet spot id (as in, that was being copied to the linked matrix), but this is not ideal, I feel.
    // Even though nothing currently depends on sweet spot ids being globally unique, that may change down the line.
    const destinationSweetSpot = destination.find(s => s.sweetSpotOrder === sourceSweetSpot.sweetSpotOrder);

    if (destinationSweetSpot === undefined) {
      const sweetSpotToAdd = {
        ...sourceSweetSpot,
        sweetSpotId: generatePrimaryKey<SweetSpotId>(),
      };
      modifiedSweetSpots.push(sweetSpotToAdd);
    } else {
      const editedSweetSpot: SweetSpot = {
        // Note on omissions: AverageData is not copied because two matrices could be the same size but have different data.
        // I think everything else makes sense given the context that this piece happens as a subset of cloning an entire matrix.
        // sweetSpotOrder is also omitted due to it being the property we matched on to associate linked sweet spots anyway.
        ...destinationSweetSpot,
        axisRangeData: sourceSweetSpot.axisRangeData,
        cellRange: sourceSweetSpot.cellRange,
        color: sourceSweetSpot.color,
        isForMatrixSummary: sourceSweetSpot.isForMatrixSummary,
        label: sourceSweetSpot.label,
      };

      modifiedSweetSpots.push(editedSweetSpot);
    }
  }

  return modifiedSweetSpots;
};

export const firstTimeLinkedMatrix = createAppAsyncThunk('matrices/firstTimeLinkedMatrix', async ({ quoteId, matrixId }: {
  quoteId: QuoteId, matrixId: MatrixId
}, thunkApi) => {
  const state = thunkApi.getState();
  const linkedMatrices = selectUserLinkedMatrices(state);
  const linkedMatricesData = linkedMatrices.quotes[quoteId] ?? null;
  const isLinkedMatrixFound = linkedMatricesData !== null && linkedMatricesData.linkedMatrixIds.length > 0;
  if (isLinkedMatrixFound) {
    const allMatrices = getKeyedStateValues(state.matrices.allMatrices.data);
    const destinationMatrix = selectMatrixById(state, matrixId);
    const otherLinkedMatrixIds = new Set(linkedMatricesData.linkedMatrixIds.filter(s => s !== matrixId));
    const linkedMatrix = allMatrices.filter(s => otherLinkedMatrixIds.has(s.matrixId));

    const sourceLinkedMatrix = linkedMatrix.at(0);
    if (sourceLinkedMatrix !== undefined && destinationMatrix !== null) {
      const clonedMatrix = {
        ...destinationMatrix,
      };

      copyPropertiesSharedByLinkedMatrices(sourceLinkedMatrix, clonedMatrix);
      await thunkApi.dispatch(updateMatrices({ matrices: [clonedMatrix] }));
    }
  }
});


const prepareLinkedMatrices = (linkedMatrices: UserLinkedMatrices, quoteId: QuoteId, matrixThatWasUpdated: Matrix, allMatrices: Matrix[]): Matrix[] => {
  // See if there is any existing linked matrix data for the quote. Null would imply the user has never interacted with matrix linking for this quote.
  const linkedMatricesDatas = linkedMatrices.quotes[quoteId] ?? null;

  // Capture the matrix ids that are part of the link group, if any. No linked matrices is fine, and just means this will end up being a single matrix update.
  const linkedMatrixIds = linkedMatricesDatas?.linkedMatrixIds ?? [];

  let matricesToUpdate: Matrix[];

  // If the matrix being updated is part of a link group, then we need to update all the linked matrices.
  const isMatrixBeingUpdatedLinked = linkedMatrixIds.includes(matrixThatWasUpdated.matrixId);

  // Get the ids of matrices other than the current one in the linked group (if any), that need updating.
  // This is needed because we need to copy settings to each of these matrices below.
  const otherMatrixIdsToUpdate = isMatrixBeingUpdatedLinked ? new Set(linkedMatrixIds.filter(s => s !== matrixThatWasUpdated.matrixId)) : new Set();

  if (otherMatrixIdsToUpdate.size > 0) {
    // We know we have at least one other matrix to update, so get the entities from the state, and clone them.

    const otherMatrixEntitiesToUpdateFromState = allMatrices.filter(s => otherMatrixIdsToUpdate.has(s.matrixId));
    const otherMatricesToUpdate: Matrix[] = [];

    otherMatrixEntitiesToUpdateFromState.forEach(matrix => {
      // Copy the original matrix with no changes
      const clonedMatrix = {
        ...matrix,
      };

      copyPropertiesSharedByLinkedMatrices(matrixThatWasUpdated, clonedMatrix);
      otherMatricesToUpdate.push(clonedMatrix);
    });

    // The original matrix will be updated, but so will all of the linked matrices that were cloned above.
    matricesToUpdate = [matrixThatWasUpdated, ...otherMatricesToUpdate];
  } else {
    // There are no other matrices to update, so just update the current one.
    matricesToUpdate = [matrixThatWasUpdated];
  }

  return matricesToUpdate;
};

export const modifyMatrix = createAppAsyncThunk('matrices/modifyMatrix', async ({ matrixData, matrix, includedScenarios }: {
  matrixData: StrictOmit<Matrix, 'matrixId' | 'scenarioMatrices' | 'sweetSpots'>,
  matrix: Matrix,
  includedScenarios: ScenarioId[]
}, thunkApi) => {
  const scenarioMatrices: ScenarioMatrix[] = includedScenarios.map(scenarioId => {
    const matchingScenarioMatrix = matrix.scenarioMatrices.find(sm => sm.scenarioId === scenarioId);
    if (matchingScenarioMatrix !== undefined) return matchingScenarioMatrix;

    const newScenarioMatrix: ScenarioMatrix = {
      scenarioId: scenarioId,
      matrixId: matrix.matrixId,
      scenarioMatrixId: generatePrimaryKey(),
    };

    return newScenarioMatrix;
  });

  const newMatrix: Matrix = { ...matrixData, matrixId: matrix.matrixId, primaryScenarioId: matrix.primaryScenarioId, scenarioMatrices: scenarioMatrices, sweetSpots: matrix.sweetSpots };

  const state = thunkApi.getState();
  const linkedMatrices = selectUserLinkedMatrices(state);
  const allMatrices = getKeyedStateValues(state.matrices.allMatrices.data);
  const primaryScenario = selectScenarioById(state, matrix.primaryScenarioId);
  if (!primaryScenario) {
    throw new Error('Failed to modify matrix. The primary scenario does not exist in state on the matrix.');
  }

  const quote = selectQuoteById(state, primaryScenario.quoteId);
  if (!quote) {
    throw new Error('Failed to modify matrix. The quote does not exist in state from the matrix primary scenario.');
  }
  const matricesToUpdate = prepareLinkedMatrices(linkedMatrices, quote.quoteId, newMatrix, allMatrices);
  await thunkApi.dispatch(updateMatrices({ matrices: matricesToUpdate }));

  return newMatrix;
});

const updateMatrices = createAppAsyncThunk('matrices/updateMatrices', async ({ matrices }: { matrices: Matrix[] }, thunkApi) => {
  if (matrices.length === 1) {
    await updateMatrixRequest(matrices[0]);
  } else if (matrices.length > 1) {
    await updateMatricesBatchRequest(matrices);
  }

  return matrices;
},
);

// Here we need to make sure we have a different debounce function for every matrix id. We need this because when multiple matrices are updating their
// sweet spots we need to ensure that the previous matrix changes are not getting lost to the debounce. Without this only the last matrix changed would
// call the API to update.
const debounceMapping = new Map<MatrixId, (linkedMatrices: UserLinkedMatrices, allMatrices: Matrix[], quoteId: QuoteId, newMatrix: Matrix, dispatch: AppDispatch) => void>();

export const modifySweetSpots = createAppAsyncThunk('matrices/modifySweetSpots', async ({ matrixData }: { matrixData: Matrix }, thunkApi) => {
  const sweetSpots = [...matrixData.sweetSpots];
  const newMatrix: Matrix = { ...matrixData, sweetSpots: sweetSpots };

  const state = thunkApi.getState();
  const linkedMatrices = selectUserLinkedMatrices(state);
  const allMatrices = getKeyedStateValues(state.matrices.allMatrices.data);
  const primaryScenario = selectScenarioById(state, matrixData.primaryScenarioId);
  if (!primaryScenario) {
    throw new Error('Failed to modify Sweet Spots for matrix. The primary scenario does not exist in state on the matrix.');
  }

  const quote = selectQuoteById(state, primaryScenario.quoteId);
  if (!quote) {
    throw new Error('Failed to modify Sweet Spots for matrix. The quote does not exist in state from the matrix primary scenario.');
  }
  thunkApi.dispatch(setUpdatedSweetSpotState({ matrixId: matrixData.matrixId, sweetSpots }));
  const debounceFunctionToCall = getDebounceForMatrix(newMatrix.matrixId);
  debounceFunctionToCall(linkedMatrices, allMatrices, quote.quoteId, newMatrix, thunkApi.dispatch);
});

const getDebounceForMatrix = (matrixId: MatrixId): (linkedMatrices: UserLinkedMatrices, allMatrices: Matrix[], quoteId: QuoteId, newMatrix: Matrix, dispatch: AppDispatch) => void => {
  const debounceFunction = debounceMapping.get(matrixId);
  if (debounceFunction) {
    return debounceFunction;
  } else {
    const newDebounceFunc = debounce(persistSweetSpots, DelayUntilSweetSpotsShouldPersistMs);
    debounceMapping.set(matrixId, newDebounceFunc);
    return newDebounceFunc;
  }
};

const persistSweetSpots = async (linkedMatrices: UserLinkedMatrices, allMatrices: Matrix[], quoteId: QuoteId, newMatrix: Matrix, dispatch: AppDispatch) => {
  const matricesToUpdate = prepareLinkedMatrices(linkedMatrices, quoteId, newMatrix, allMatrices);
  await dispatch(updateMatrices({ matrices: matricesToUpdate }));
};

export const removeMatrix = createAppAsyncThunk('matrices/removeMatrix', async ({ matrix }: { matrix: Matrix }, thunkApi) => {
  await deleteMatrixRequest(matrix.matrixId);
  return matrix;
});

const defaultSweetSpotColors = ['#E0DF7A', '#ABD7B3', '#F8F4D7', '#ABD9E9'] as const;

const buildDefaultSweetSpotSet = (matrixType: MatrixType): SweetSpot[] => {
  const sweetSpotTemplates: MakeSweetSpotInputs[] = [];

  // Analysis matrices get a special summary sweet spot that will be first in the list.
  const shouldThereBeSummarySweetSpot = matrixType === MatrixType.Analysis;

  if (shouldThereBeSummarySweetSpot) {
    sweetSpotTemplates.push({
      isForMatrixSummary: true, color: '#FFFFFF',
    });
  }

  // Note that if non-summary sweet spots are initialized as defaults again, they need to happen here, AFTER the summary sweet spot
  // gets added. This is because we want the "summary" to assume order ZERO, so that the "real" sweet spots get 1, 2, 3, etc.

  // If there is a summary sweet spot, we want it to have order "0" so the "real" sweet spots get 1, 2, 3, etc.
  // Note that right now this value doesn't actually need to be "dynamic". It's this way in case the default list of sweet spots is
  // modified again (previously 4 "static" sweet spots were also added to new matrices).
  const defaultFirstOrder = shouldThereBeSummarySweetSpot ? 0 : 1;

  const builtList = appendSweetSpots([], sweetSpotTemplates, defaultFirstOrder);

  return builtList;
};

type MakeSweetSpotInputs = {
  isForMatrixSummary?: boolean;
  color?: string;
  label?: string;
};

/** NON-MUTATING
 * Simplifies the process of adding sweet spots to an existing set of them.
 * This exists to avoid the "complexity" of setting specific properties, especially "order."
 * Always returns a new array. */
const appendSweetSpots = (existingSweetSpots: readonly SweetSpot[], sweetSpotsToAdd: readonly MakeSweetSpotInputs[], defaultFirstOrder: number = 1): SweetSpot[] => {
  if (sweetSpotsToAdd.length === 0) return [];

  // Because this method will be non-mutating.
  const response = [...existingSweetSpots];

  // Find out the highest existing order so we can append the new sweet spots after that.
  // If there is no existing order, set to the default order minus 1, so that the first one will be given the default order.
  const highestExistingOrder = maxBy(response, s => s.sweetSpotOrder)?.sweetSpotOrder ?? (defaultFirstOrder - 1);

  // Initialize the next order
  let order = highestExistingOrder + 1;

  for (const sweetSpotToAdd of sweetSpotsToAdd) {
    response.push(makeSweetSpot({ ...sweetSpotToAdd, order }));
    order++;
  }

  return response;
};

/** NON-MUTATING
 * Adds a new default sweet spot to the set of existing sweet spots.
 * Always returns a new array. */
export const addNewSweetSpotToSet = (existingSweetSpots: readonly SweetSpot[]): SweetSpot[] => {
  return appendSweetSpots(existingSweetSpots, [{}]);
};

const makeSweetSpot = ({ isForMatrixSummary = false, label, color, order }: MakeSweetSpotInputs & { order: number }): SweetSpot => {
  const getDefaultColor = () => {
    const arrayIndex = order % defaultSweetSpotColors.length;
    // Black should not be possible unless the code is changed and a bug is introduced.
    return defaultSweetSpotColors.at(arrayIndex) ?? '#000000';
  };

  return {
    sweetSpotId: generatePrimaryKey(),
    isForMatrixSummary: isForMatrixSummary,
    isHidden: isForMatrixSummary, // Sweet spot is only defaulted as hidden if it's for the matrix summary.
    sweetSpotOrder: order,
    label: label ?? `Sweet Spot ${order}`,
    cellRange: isForMatrixSummary ? 'fill-grid' : null,
    color: color ?? getDefaultColor(),
    averageData: null,
    axisRangeData: null,
  };
};

export default matricesSlice.reducer;
