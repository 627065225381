import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { addMatrixFromForm, modifyMatrix, selectAllAnalysisMatrices } from '../../../app/matricesSlice';
import { selectScenarioById } from '../../../app/scenariosSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import {
  calculateYieldValue,
  midYieldPercentage,
  topYieldPercentage
} from '../../matrix/matrixDefaults';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import { StrictOmit } from '../../../types/util/StrictOmit';
import { addTrendlineAnalysis, modifyTrendline, selectAllTrendlines } from '../../../app/trendlineAnalysisSlice';
import TrendlineAnalysis from '../../../types/api/TrendlineAnalysis';
import { selectCurrentClientFile } from '../../../app/clientFilesSlice';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import {
  addHistoricalAnalysis,
  modifyHistoricalAnalysis,
  selectAllHistoricalAnalyses
} from '../../../app/historicalAnalysisSlice';
import {
  addPremiumBreakdown,
  modifyPremiumBreakdown,
  selectAllPremiumBreakdowns
} from '../../../app/premiumBreakdownSlice';
import HistoricalAnalysis from '../../../types/api/historicalAnalysis';
import { HistoricalIncludeType } from '../../../types/api/enums/historicalAnalysis/historicalIncludeType.enum';
import { HistoricalShowType } from '../../../types/api/enums/historicalAnalysis/historicalShowType.enum';
import { HistoricalIntervalType } from '../../../types/api/enums/historicalAnalysis/historicalIntervalType.enum';
import {
  HistoricalSmartCriteriaType
} from '../../../types/api/enums/historicalAnalysis/historicalSmartCriteriaType.enum';
import { AcreageType } from '@silveus/calculations';
import PremiumBreakdown from '../../../types/api/premiumBreakdown';
import CommandBar, { CommandBarItem } from '../../../components/command-bar/commandBar.component';
import { BarChart, GridOn, History, QueryStats } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { MatrixOffsetType } from '../../../types/api/enums/matrixOffsetType/MatrixOffsetType.enum';
import { MatrixType } from '../../../types/api/enums/matrixType/matrixType.enum';
import Matrix from '../../../types/api/Matrix';
import { ClientFile } from '../../../types/api/ClientFile';
import { useMatrixDefaults } from '../../../hooks/matrix/useMatrixDefaults';

type ScenarioAnalysisMenuProps = {
  scenarioId: ScenarioId;
}

const getDefaultStartYear = (clientFile: ClientFile) => clientFile.year - 10;

const ScenarioAnalysisMenu = ({ scenarioId }: ScenarioAnalysisMenuProps) => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const clientFile = useAppSelector(selectCurrentClientFile);
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  const quote = useAppSelector(state => scenario === null ? null : selectQuoteById(state, scenario.quoteId));

  const matrices = useAppSelector(state => selectAllAnalysisMatrices(state));
  const existingMatrix = matrices.get(scenarioId);
  const isMatrixActive = existingMatrix?.isActive ?? false;
  const doesAnAnalysisMatrixExistForScenario = isNotNullOrUndefined(existingMatrix);

  const existingTrendlineAnalysis = useAppSelector(state => selectAllTrendlines(state).get(scenarioId));
  const isTrendlineActive = existingTrendlineAnalysis?.isActive ?? false;
  const doesATrendlineAnalysisExistForScenario = isNotNullOrUndefined(existingTrendlineAnalysis);
  const matrixDefaults = useMatrixDefaults(scenario, quote);

  const existingHistoricalAnalysis = useAppSelector(state => selectAllHistoricalAnalyses(state)).get(scenarioId);
  const isHistoricalAnalysisActive = existingHistoricalAnalysis?.isActive ?? false;
  const doesAHistoricalAnalysisExistForScenario = isNotNullOrUndefined(existingHistoricalAnalysis);

  const existingPremiumBreakdown = useAppSelector(state => selectAllPremiumBreakdowns(state)).get(scenarioId);
  const isPremiumBreakdownActive = existingPremiumBreakdown?.isActive ?? false;
  const doesAPremiumBreakdownExistForScenario = isNotNullOrUndefined(existingPremiumBreakdown);

  const onPremiumBreakdownClicked = async () => {
    if (scenario !== null && !isPremiumBreakdownActive && !doesAPremiumBreakdownExistForScenario) {
      // if we have checked the premium breakdown option and there is no inactive premium breakdown already for this scenario, create
      // a new premium breakdown
      const premiumBreakdown: PremiumBreakdown = {
        premiumBreakdownId: generatePrimaryKey(),
        isActive: true,
        primaryScenarioId: scenario.scenarioId,
        offlineCreatedOn: undefined,
        offlineLastUpdatedOn: undefined,
        offlineDeletedOn: undefined,
      };

      dispatch(addPremiumBreakdown({ premiumBreakdown: { ...premiumBreakdown } }));
    } else if (!isPremiumBreakdownActive && existingPremiumBreakdown) {
      // If we have the premium breakdown option checked but there already exists a premium breakdown in the inactive state
      // for this scenario, reenable the existing premium breakdown
      await dispatch(modifyPremiumBreakdown({ premiumBreakdown: { ...existingPremiumBreakdown, isActive: true } }));
    } else if (isPremiumBreakdownActive && doesAPremiumBreakdownExistForScenario) {
      // If we have a premium breakdown created for this scenario but have unchecked the premium breakdown option, mark the
      // existing premium breakdown
      await dispatch(modifyPremiumBreakdown({ premiumBreakdown: { ...existingPremiumBreakdown, isActive: false } }));
    }
  };

  const onHistoricalAnalysisClicked = async () => {
    if (scenario !== null && clientFile !== null && !isHistoricalAnalysisActive && !doesAHistoricalAnalysisExistForScenario) {
      // if we have checked the historical option and there is no inactive historical analysis already for this scenario, create
      // a new historical analysis
      const historical: StrictOmit<HistoricalAnalysis, 'historicalAnalysisYears'> = {
        historicalAnalysisId: generatePrimaryKey(),
        calculateMissingYears: false,
        hidePastIntervals: false,
        intervalCount: 0,
        isActive: true,
        primaryScenarioId: scenario.scenarioId,
        startYear: getDefaultStartYear(clientFile),
        endYear: clientFile.year,
        historicalIncludeType: HistoricalIncludeType.Net,
        historicalShowType: HistoricalShowType.PerAcre,
        acreageType: AcreageType.IntendedPlant,
        historicalIntervalType: HistoricalIntervalType.SelectedIntervals,
        historicalSmartCriteriaType: HistoricalSmartCriteriaType.PercentYearsPaid,
        offlineCreatedOn: undefined,
        offlineLastUpdatedOn: undefined,
        offlineDeletedOn: undefined,
      };

      dispatch(addHistoricalAnalysis({ historicalAnalysis: { ...historical } }));
    } else if (!isHistoricalAnalysisActive && existingHistoricalAnalysis) {
      // If we have the historical option checked but there already exists a historical analysis in the inactive state
      // for this scenario, reenable the existing historical analysis
      await dispatch(modifyHistoricalAnalysis({ historicalAnalysis: { ...existingHistoricalAnalysis, isActive: true } }));
    } else if (isHistoricalAnalysisActive && doesAHistoricalAnalysisExistForScenario) {
      // If we have a historical analysis created for this scenario but have unchecked the historical option, mark the
      // existing historical analysis as inactive
      await dispatch(modifyHistoricalAnalysis({ historicalAnalysis: { ...existingHistoricalAnalysis, isActive: false } }));
    }
  };

  const onTrendlineClicked = async () => {
    if (scenario !== null && clientFile !== null && !isTrendlineActive && !doesATrendlineAnalysisExistForScenario) {
      // if we have checked the trendline option and there is no inactive trendline already for this scenario, create
      // a new trendline analysis
      const theTrendline: TrendlineAnalysis = {
        primaryScenarioId: scenario.scenarioId,
        startYear: getDefaultStartYear(clientFile),
        endYear: clientFile.year,
        showLabels: false,
        isActive: true,
        trendlineAnalysisId: generatePrimaryKey(),
        acreageType: AcreageType.IntendedPlant,
        offlineCreatedOn: undefined,
        offlineLastUpdatedOn: undefined,
        offlineDeletedOn: undefined,
      };

      dispatch(addTrendlineAnalysis({ trendline: { ...theTrendline } }));
    } else if (!isTrendlineActive && existingTrendlineAnalysis) {
      // If we have the trendline option checked but there already exists a trendline analysis in the inactive state
      // for this scenario, reenable the existing trendline analysis
      await dispatch(modifyTrendline({ trendline: { ...existingTrendlineAnalysis, isActive: true } }));
    } else if (isTrendlineActive && doesATrendlineAnalysisExistForScenario) {
      // If we have a trendline analysis created for this scenario but have unchecked the trendline option, mark the
      // existing trendline analysis as inactive
      await dispatch(modifyTrendline({ trendline: { ...existingTrendlineAnalysis, isActive: false } }));
    }
  };

  const onMatrixClicked = async () => {
    if (scenario !== null && !isMatrixActive && !doesAnAnalysisMatrixExistForScenario) {
      // If we have checked the matrix option and there is no inactive analysis matrix for this scenario, create
      // a new analysis matrix with default values
      const midYield = calculateYieldValue(matrixDefaults.topYield, matrixDefaults.columnCount, matrixDefaults.yieldScale, topYieldPercentage, midYieldPercentage);
      const theMatrix: StrictOmit<Matrix, 'sweetSpots' | 'matrixId' | 'scenarioMatrices'> = {
        name: `Matrix - ${scenario.name}`,
        ...matrixDefaults,
        bottomAxisOffsetType: MatrixOffsetType.Unset,
        bottomAxisPercentChange: 0,
        bottomAxisIntegerOffset: 0,
        matrixType: MatrixType.Analysis,
        primaryScenarioId: scenarioId,
        midYield: midYield,
        isActive: true,
        offlineCreatedOn: undefined,
        offlineLastUpdatedOn: undefined,
        offlineDeletedOn: undefined,
      };

      dispatch(addMatrixFromForm({ matrixData: { ...theMatrix }, includedScenarios: [scenarioId] }));
    } else if (!isMatrixActive && existingMatrix) {
      // If we have the matrix option checked but there already exists an analysis matrix in the inactive state
      // for this scenario, reenable the existing matrix
      await dispatch(modifyMatrix({ matrixData: { ...existingMatrix, isActive: true }, matrix: existingMatrix, includedScenarios: existingMatrix.scenarioMatrices.map(x => x.scenarioId) }));
    } else if (isMatrixActive && doesAnAnalysisMatrixExistForScenario) {
      // If we have an analysis matrix created for this scenario but have unchecked the matrix option, mark the
      // existing matrix as inactive
      await dispatch(modifyMatrix({ matrixData: { ...existingMatrix, isActive: false }, matrix: existingMatrix, includedScenarios: existingMatrix.scenarioMatrices.map(x => x.scenarioId) }));
    }
  };

  const getButtonSx = (isButtonActive: boolean) => {
    return { fontSize: '16px', color: isButtonActive ? 'secondary' : theme.palette.altText.disabled };
  };

  const getScenarioAnalysisItems = () => {
    const items: CommandBarItem[] = [
      {
        id: `matrix-${scenarioId}`,
        label: 'Matrix',
        onclick: () => { onMatrixClicked(); },
        iconButton: <GridOn sx={getButtonSx(isMatrixActive)} />,
        placement: 'bottom',
      },
      {
        id: `historical-analysis-${scenarioId}`,
        label: 'Historical analysis',
        onclick: () => { onHistoricalAnalysisClicked(); },
        iconButton: <History sx={getButtonSx(isHistoricalAnalysisActive)} />,
        placement: 'bottom',
      },
      {
        id: `trendlines-${scenarioId}`,
        label: 'Trendlines',
        onclick: () => { onTrendlineClicked(); },
        iconButton: <QueryStats sx={getButtonSx(isTrendlineActive)} />,
        placement: 'bottom',
      },
      {
        id: `premium-breakdown-${scenarioId}`,
        label: 'Premium breakdown',
        onclick: () => { onPremiumBreakdownClicked(); },
        iconButton: <BarChart sx={getButtonSx(isPremiumBreakdownActive)} />,
        placement: 'bottom',
      },
    ];

    return items;
  };

  return (
    <CommandBar
      items={getScenarioAnalysisItems()}
    />
  );
};

export default ScenarioAnalysisMenu;