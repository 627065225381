import { Button, Grid } from '@mui/material';
import { selectEntityTypes, selectInsuredById, selectTaxTypes, modifyInsured, addInsured, fetchInsured, selectCurrentInsured } from '../../../app/insuredsSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { ApplicationWizard, defaultInsuredInfo } from '../../../types/api/applicationWizard/applicationWizard';
import ApplicationWizardPage from './applicationWizardPage';
import { ApplicationWizardStep, ApplicationWizardStepProps } from './applicationWizardStep';
import InsuredEntityTypeInput, { InsuredEntityTypeFields } from '../../../components/formInputs/applications/insuredEntityTypeInput';
import { FieldError, FieldErrors, FormProvider, SubmitErrorHandler, useForm, useWatch } from 'react-hook-form';
import TaxIdTypeInput, { TaxIdTypeFields } from '../../../components/formInputs/applications/taxIdTypeInput';
import InsuredPhoneNumberInput, { InsuredPhoneNumberFields } from '../../../components/formInputs/applications/insuredPhoneNumberInput';
import InsuredEmailInput, { InsuredEmailFields } from '../../../components/formInputs/applications/insuredEmailInput';
import InsuredTaxIdInput, { InsuredTaxIdInputFields } from '../../../components/formInputs/applications/insuredTaxIdInput';
import PersonIcon from '@mui/icons-material/Person';
import PersonOrBusinessTypeInput, { PersonOrBusinessTypeFields } from '../../../components/formInputs/applications/personOrBusinessTypeInput';
import AddressLine1Input, { AddressLine1Fields } from '../../../components/formInputs/applications/addressLine1Input';
import AddressStateInput, { AddressStateFields } from '../../../components/formInputs/applications/addressStateInput';
import AddressCityInput, { AddressCityFields } from '../../../components/formInputs/applications/addressCityInput';
import AddressZipInput, { AddressZipFields } from '../../../components/formInputs/applications/addressZipInput';
import NameExtendedInput, { NameExtendedInputFields } from '../../../components/formInputs/applications/nameInput';
import { useEffect, useState } from 'react';
import InfoCorrectYesNoInput, { InfoCorrectYesNoInputFields } from '../../../components/formInputs/applications/infoCorrectYesNoInput';
import { PersonOrBusiness } from '../../../types/api/enums/contactInfo/personOrBusiness';
import { Insured } from '../../../types/api/insureds/Insured';
import { RootState } from '../../../app/store';
import { isNotNullOrUndefined, isNull, isNullOrUndefined } from '../../../utils/nullHandling';
import { validateInsured, validateMinimumRequirementsForInsured } from '../../../components/ag-grid/contactInfoGrid/validation/validateContactInfo';
import CorporationStateInput, { CorporationStateFields } from '../../../components/formInputs/applications/corporationStateInput';
import { Nullable } from '../../../types/util/Nullable';
import { ConfirmStateContent, openConfirm } from '../../../app/confirmSlice';
import ImportInsuredModal from './importInsuredModal';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { MuiDialogCloseReason } from '../../../types/mui/MuiDialogCloseReason';
import { saveApplication } from '../../../app/applicationsSlice';
import TaxIdUpdateModal from './taxIdUpdateModal';

export const InsuredInfoStep: ApplicationWizardStep = {
  isDisabled(app: ApplicationWizard, state: RootState) {
    return !app.anyChanges;
  },
  title: 'Insured Info',
  formId: 'form-insured-info',
  index: 1,
  icon: <PersonIcon />,
  isValid(app: ApplicationWizard, state: RootState) {
    const isDisabled = this.isDisabled(app, state);

    if (isDisabled) {
      return {
        isValid: true,
        errors: [],
      };
    }

    const insuredId = app.isNewEntity ? app.newInsuredId : app.insuredId;
    const insured = selectInsuredById(state, insuredId);
    if (isNullOrUndefined(insured)) {
      return {
        isValid: false,
        errors: ['Cannot find Insured by ID'],
      };
    }

    const entityTypes = selectEntityTypes(state);
    const taxIdTypes = selectTaxTypes(state);
    const insuredValidationResult = validateInsured(insured, entityTypes, taxIdTypes, app.isGettingESignatures);
    return {
      isValid: insuredValidationResult,
      errors: insuredValidationResult ? [] : ['Errors found on Insured'],
    };
  },
};

const formatName = (values: InsuredInfoFormFields) => {
  // format person's name in the case that middleName has no value
  const personName = values.middleName !== '' ? `${values.firstName} ${values.middleName} ${values.lastName}` : `${values.firstName} ${values.lastName}`;
  return values.personOrBusiness === PersonOrBusiness.BUSINESS ? values.firstName : personName;
};

type AddressCountry = {
  country: Nullable<string>;
}

export type InsuredAddressTypeFields = {
  address: AddressLine1Fields &
  AddressCityFields &
  AddressStateFields &
  AddressZipFields &
  AddressCountry;
};

export type ContactInfoFields = PersonOrBusinessTypeFields &
  InsuredTaxIdInputFields &
  InsuredEmailFields &
  NameExtendedInputFields &
  InsuredEntityTypeFields &
  InsuredPhoneNumberFields &
  InsuredAddressTypeFields &
  CorporationStateFields &
  PersonOrBusinessTypeFields;

export type InsuredInfoFormFields =
  ContactInfoFields &
  InfoCorrectYesNoInputFields &
  TaxIdTypeFields;

const InsuredInfoPage = ({ application, navigateToPreviousStep, navigateToNextStep, updateIsInEditMode, isLoading, updateIsLoading }: ApplicationWizardStepProps) => {
  const dispatch = useAppDispatch();
  const { isNewEntity } = application;
  const originalInsured = useAppSelector(selectCurrentInsured);
  const currentInsuredId = isNewEntity ? application.newInsuredId : application.insuredId;
  const currentInsured = useAppSelector(state => selectInsuredById(state, currentInsuredId));
  const clientFile = useAppSelector(state => selectClientFileById(state, application.clientFileId));
  const [showImportModal, setShowImportModal] = useState(false);
  const methods = useForm<InsuredInfoFormFields>({ mode: 'onChange' });
  const { formState, control, setValue } = methods;
  const isDirty = formState.isDirty;
  const isAboveCorrect = useWatch({ name: 'isAboveCorrect', control: control, defaultValue: true });
  const personOrBusiness = useWatch({ name: 'personOrBusiness', control: control, defaultValue: currentInsured?.personOrBusiness ?? null });
  const [loading, setLoading] = useState(true);
  const areInputsDisabled = isAboveCorrect;
  const defaultFormValues = currentInsured ?? defaultInsuredInfo;
  const [isTaxIdModalOpen, setIsTaxIdModalOpen] = useState(false);
  const hasExistingTaxId = currentInsured ? isNotNullOrUndefined(currentInsured.taxId) && currentInsured.taxId.length > 0 : false;

  useEffect(() => {
    if (isNewEntity && isNull(application.newInsuredId)) {
      updateIsAboveCorrect(false);
    }
  }, []);

  useEffect(() => {
    if (isNewEntity && isNotNullOrUndefined(application.newInsuredId) && !currentInsured) {
      setLoading(true);
      dispatch(fetchInsured({ insuredId: application.newInsuredId })).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [application]);

  useEffect(() => {
    methods.trigger();
    if (isDirty) {
      if (formState.dirtyFields.isAboveCorrect ?? false) {
        updateIsAboveCorrect(methods.getValues().isAboveCorrect);
      }

      if (formState.dirtyFields.personOrBusiness ?? false) {
        // if user just selected BUSINESS we want to reset the other name fields - is this supposed to happen?
        if (personOrBusiness === PersonOrBusiness.BUSINESS) {
          setValue('lastName', '');
          setValue('middleName', '');
          setValue('suffix', '');
        }
      }
    }
  }, [isDirty, personOrBusiness, setValue]);

  if (!originalInsured) return null;

  const updateIsAboveCorrect = (val: boolean) => {
    setValue('isAboveCorrect', val);
    updateIsInEditMode(!val);
  };

  const importInsured = (insured: Insured) => {
    dispatch(saveApplication({ updatedApplication: { ...application, newInsuredId: insured.id } }));
    methods.reset(insured);
    updateIsAboveCorrect(true);
  };

  const saveInProgressWork = async (data: InsuredInfoFormFields) => {
    updateIsLoading(true);
    try {
      if (!currentInsured) {
        const newInsured = await dispatch(addInsured(populateInsuredChanges(data))).unwrap();
        dispatch(saveApplication({ updatedApplication: { ...application, newInsuredId: newInsured.id } }));
      } else {
        await dispatch(modifyInsured({ insured: populateInsuredChanges(data), updateTaxId: false }));
      }
      updateIsAboveCorrect(true);
    } finally {
      updateIsLoading(false);
    }
  };

  const handleInvalidSubmit: SubmitErrorHandler<InsuredInfoFormFields> = async data => {
    const formValues = methods.getValues();
    // Sometimes its okay to ignore form errors, however firstName and lastName must always be error free
    // in order to continue and save.
    if (data.firstName || data.lastName) return;

    // If we have validation errors that means that the user filled something out incorrectly
    let anyValidationErrors = false;

    for (const field of Object.keys(data)) {
      if (field === 'address' || field === 'corporationState') {
        const error = data[field];
        if (!error) continue;

        const addressErrors = Object.values(error) as FieldError[];
        if (!anyValidationErrors) {
          anyValidationErrors = addressErrors.some(x => x.type !== 'required');
        }
      } else {
        const key = field as keyof FieldErrors<InsuredInfoFormFields>;
        const error = data[key];
        if (!error) continue;
        if (error.type !== 'required') {
          anyValidationErrors = true;
        }
      }
    }

    if (anyValidationErrors) return;

    const tempInsured = populateInsuredChanges(formValues);
    if (!validateMinimumRequirementsForInsured(tempInsured)) return;

    await dispatch(modifyInsured({ insured: tempInsured, updateTaxId: false }));
    updateIsAboveCorrect(true);
  };

  const handleNavigateToNextStep = () => {
    navigateToNextStep();
  };

  const handleNavigateToPrevious = () => {
    navigateToPreviousStep();
  };

  const onCancelClick = () => {
    methods.trigger();
    methods.reset({ ...currentInsured }, { keepDefaultValues: false, keepDirty: false });
    updateIsAboveCorrect(true);
  };

  const populateInsuredChanges = (formValues: InsuredInfoFormFields) => {
    if (currentInsured) {
      const updatedInsured: Insured = {
        ...currentInsured,
        ...formValues,
        name: formatName(formValues),
        address: { ...currentInsured.address, ...formValues.address, composite: currentInsured.address.composite, country: '' },
      };
      return updatedInsured;
    } else {
      const newInsured: Insured = {
        ...defaultInsuredInfo,
        ...formValues,
        name: formatName(formValues),
        address: { ...defaultInsuredInfo.address, ...formValues.address, composite: defaultInsuredInfo.address.composite, country: '' },
        agentTeamId: originalInsured.agentTeamId,
        type: 'Insured',
      };
      return newInsured;
    }
  };

  const handleImportDialogClose = (reason?: MuiDialogCloseReason) => {
    if (reason === 'backdropClick') return;
    setShowImportModal(false);
  };

  const clearInsured = () => {
    if (!currentInsured) return;
    const confirmWindow: ConfirmStateContent = {
      title: 'Remove Insured?',
      message: 'Are you sure you want to remove the Insured? This is a permament action.',
      confirmText: 'Remove',
      onConfirm: async () => {
        dispatch(saveApplication({ updatedApplication: { ...application, newInsuredId: null } }));
        methods.reset({ ...defaultInsuredInfo, isAboveCorrect: false });
      },
    };
    dispatch(openConfirm(confirmWindow));
  };

  const handleCloseTaxIdModal = () => {
    setIsTaxIdModalOpen(false);
    updateIsLoading(false);
  };

  return (
    <FormProvider {...methods}>
      <form style={{ height: '100%' }} noValidate id="form-insured-info" onSubmit={methods.handleSubmit(saveInProgressWork, handleInvalidSubmit)}>
        <ApplicationWizardPage loading={isLoading} formId="form-insured-info" tabIndex={InsuredInfoStep.index} onPreviousClick={handleNavigateToPrevious} disabled={!isAboveCorrect || isLoading} onNextClick={handleNavigateToNextStep} hideNavigation={!isAboveCorrect}>
          {!loading && <Grid container xs alignItems="center" rowSpacing={3} p={2} sx={{ maxWidth: '700px !important' }} flexDirection="column">
            <Grid item xs={12} width="100%" direction="row" display="flex">
              <PersonOrBusinessTypeInput value={defaultFormValues.personOrBusiness} disabled={areInputsDisabled || defaultFormValues.personOrBusiness !== null} />
              {isNewEntity && currentInsured && <Button variant="outlined" onClick={clearInsured} id="btn-remove-insured" sx={{ ml: 2, maxHeight: '40px', flexShrink: 0 }} disabled={areInputsDisabled}>Remove Insured</Button>}
              {isNewEntity && !currentInsured && <Button id="btn-import-insured" onClick={() => setShowImportModal(true)} sx={{ ml: 2, maxHeight: '40px' }} disabled={areInputsDisabled}>Import</Button>}
            </Grid>
            <Grid container xs rowSpacing={3} columnSpacing={1} sx={{ maxWidth: '700px !important', mt: 0 }}>
              {personOrBusiness !== PersonOrBusiness.PERSON && (
                <Grid item xs={12}>
                  <NameExtendedInput value={defaultFormValues.firstName} name="firstName" label="Name" disabled={areInputsDisabled} />
                </Grid>
              )}
              {personOrBusiness === PersonOrBusiness.PERSON && (
                <>
                  <Grid container item spacing={2}>
                    <Grid item xs={6}>
                      <NameExtendedInput value={defaultFormValues.firstName} name="firstName" label="First Name" disabled={areInputsDisabled} />
                    </Grid>
                    <Grid item xs={6}>
                      <NameExtendedInput value={defaultFormValues.lastName} name="lastName" label="Last Name" disabled={areInputsDisabled} />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={6}>
                      <NameExtendedInput value={defaultFormValues.middleName} name="middleName" label="Middle Name" required={false} disabled={areInputsDisabled} />
                    </Grid>
                    <Grid item xs={6}>
                      <NameExtendedInput value={defaultFormValues.suffix} name="suffix" label="Suffix" required={false} disabled={areInputsDisabled} />
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <AddressLine1Input value={defaultFormValues.address.addressLine1} disabled={areInputsDisabled} />
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={4}>
                  <AddressCityInput value={defaultFormValues.address.city} disabled={areInputsDisabled} />
                </Grid>
                <Grid item xs={4}>
                  <AddressStateInput value={defaultFormValues.address.state} disabled={areInputsDisabled} />
                </Grid>
                <Grid item xs={4}>
                  <AddressZipInput value={defaultFormValues.address.postalCode} disabled={areInputsDisabled} />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <InsuredPhoneNumberInput value={defaultFormValues.phone} disabled={areInputsDisabled} />
                </Grid>
                <Grid item xs={6}>
                  <InsuredEmailInput value={defaultFormValues.email} disabled={areInputsDisabled} required={application.isGettingESignatures} />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <InsuredEntityTypeInput value={defaultFormValues.entityTypeId} disabled={areInputsDisabled || defaultFormValues.personOrBusiness !== null} required={true} personOrBusiness={personOrBusiness} />
                </Grid>
                <Grid item xs={6}>
                  <CorporationStateInput disabled={areInputsDisabled} existingInsured={defaultFormValues} value={defaultFormValues.corporationState} />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <TaxIdTypeInput value={defaultFormValues.taxTypeId} disabled={areInputsDisabled} required={true} defaultEntityTypeId={defaultFormValues.entityTypeId} defaultPersonOrBusinessType={defaultFormValues.personOrBusiness} />
                </Grid>
                <Grid item xs={6}>
                  <InsuredTaxIdInput
                    value={defaultFormValues.taxId}
                    disabled={areInputsDisabled || hasExistingTaxId}
                    onEditClick={hasExistingTaxId && !areInputsDisabled ? () => setIsTaxIdModalOpen(true) : undefined}
                    isFirstSsnInput={!hasExistingTaxId}
                  />
                </Grid>
              </Grid>
              {isAboveCorrect && (
                <Grid item xs={12}>
                  <InfoCorrectYesNoInput value={isAboveCorrect} />
                </Grid>
              )}
            </Grid>
            {!isAboveCorrect && (
              <Grid container xs justifyContent="center" rowSpacing={3} columnSpacing={1} sx={{ maxWidth: '700px !important', m: 1 }}>
                <Grid item xs={6} display="flex" justifyContent="center">
                  <Button onClick={onCancelClick} id="btn-wizard-cancel" variant="outlined">Cancel</Button>
                  <Button type="submit" form="form-insured-info" id="btn-wizard-save" variant="contained">Save</Button>
                </Grid>
              </Grid>
            )}
          </Grid>}
        </ApplicationWizardPage>
      </form>
      {showImportModal && clientFile?.insuredId && (
        <ImportInsuredModal
          onClose={handleImportDialogClose}
          existingInsuredId={clientFile.insuredId}
          importInsured={importInsured}
        />
      )}
      {isTaxIdModalOpen && <TaxIdUpdateModal
        onClose={handleCloseTaxIdModal}
        name={currentInsured ? currentInsured.name : null}
        updateTaxId={async (newTaxId: string) => {
          if (currentInsured) {
            updateIsLoading(true);
            await dispatch(modifyInsured({ insured: { ...currentInsured, taxId: newTaxId }, updateTaxId: true })).unwrap();
            updateIsLoading(false);
          }
          setValue('taxId', newTaxId);
        }}
        taxId={currentInsured ? currentInsured.taxId : null}
      />}
    </FormProvider>
  );
};

export default InsuredInfoPage;


