import {
  calculateWeightedAverageRateYield,
  AdjustedYieldProps, HistoricalTYield, calculateRateYield,
  OptionCode, calculateApprovedYield, ApprovedYieldProps,
  calculateWeightedAverageApprovedYield, calculateAdjustedYield, calculateWeightedAverageAdjustedYield,
  calculateTotalAcres, calculateAverageSharePercent, HighRiskType
} from '@silveus/calculations';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from './store';
import UnitYear from '../types/api/UnitYear';
import { Nullable } from '../types/util/Nullable';
import { UnitFormFields } from '../pages/units/components/unitForm.component';
import { ClientFileId, InsuredId, QuoteId, ScenarioId, UnitId, UnitYearAphId, UnitYearId } from '../types/api/PrimaryKeys';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import {
  getKeyedStateGroupedBy,
  getKeyedStateValues,
  getStateIdsMatching
} from './sliceHelpers';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import { selectQuoteById } from './quotesSlice';
import { selectClientFileById } from './clientFilesSlice';
import { addUnitYearAph, modifyScenarioUnitYearAph, selectScenarioUnitYearAphByScenarioIds, selectScenarioUnitYearAphForScenarioId, selectUnitYearAphById, selectUnitYearAphByUnitYearId, selectUnitYearAphByUnitYearIds, selectUnitYearAphByUnitYearMap } from './unitYearAphSlice';
import UnitYearAph from '../types/api/UnitYearAph';
import {
  YIELD_DESCRIPTORS
} from '@silveus/calculations/';
import {
  selectAvailableOptionsForQuote,
  selectAvailableSubCountyCodes,
  selectAvailableTYields,
  selectHistoricalTYieldsForCountyCommodity,
  selectTrendAdjustmentFactorsForCountyCommodity,
  selectYeYearsForCountyCommodity
} from './admSlice';
import { getItemsForId, orderMap } from '../utils/mapHelpers';
import { selectAllScenariosByClientFileIdMap, selectScenarioById, selectUnitYears, selectUnitYearsForScenario } from './scenariosSlice';
import { selectScenarioPieceForOfferData, selectAllRowCropScenarioPiecesByScenarioMap } from './scenarioPiecesSlice';
import { scenarioPieceOrderingServiceInstance } from '../utils/scenarioOrderingServiceWrappers';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import {
  batchCreateUnitYearRequest,
  batchDeleteUnitYearsRequest,
  batchUpdateUnitYearsRequest,
  createUnitYearRequest,
  deleteUnitRequest,
  deleteUnitYearRequest, getUnitYearsForInsuredRequest, getUnitYearsForQuotesRequest, getUnitYearsForScenariosRequest,
  updateUnitYearRequest
} from '../services/requestInterception/unitRequestInterceptor';
import { updateUnitYearAphAcresRequest } from '../services/requestInterception/unitYearAphRequestInterceptor';
import { getInsurancePlanCodeForScenarioPiece } from '../utils/scenarioPieceUtils';
import { ScenarioUnitYearAph } from '../types/api/ScenarioUnitYearAph';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import { selectScenarioOptionCodesByUnitYearMap, selectScenarioOptionsForUnitYearId, selectScenarioOptionUnitYearsByScenarioIds, updateScenarioOptionUnitYears } from './optionsSlice';
import TrendAdjustmentFactor from '../types/api/adm/TrendAdjustmentFactor';
import ScenarioQuickUnit from '../types/api/ScenarioQuickUnit';
import TYield from '../types/api/adm/TYield';
import { YeStatusType } from '../types/api/enums/optionStates/yeStatusType.enum';
import { isNotNullOrUndefined } from '../utils/nullHandling';
import { closeDrawer } from './appDrawerSlice';
import { getFilteredTYield } from '../utils/tYieldUtils';
import { MissingClientFileInStateError, MissingScenarioUnitYearAphInStateError, MissingUnitYearAphInStateError, MissingUnitYearInStateError } from '../errors/state/MissingStateErrors';
import { ClientFile } from '../types/api/ClientFile';
import ScenarioOptionUnitYear from '../types/api/options/ScenarioOptionUnitYear';
import { filterUnitYearsThatCanApplyToScenario } from '../utils/unitUtils';
import OptionState from '../types/app/OptionState';
import { getYeYearForTypePracticePlan } from '../utils/yeUtils';
import AvailableOptionSelections from '../types/api/adm/AvailableOptionSelections';
import { selectPrimaryPlanCodeOfScenario } from './rmaPriceDiscoverySelectors';
import { filterScenarioOptionsByAvailability } from '../utils/options';

export interface UnitState {
  allUnitYears: SliceDataState<UnitYearId, UnitYear>;
  policyYear: Nullable<number>;
  aphModalProps: Nullable<AphModalProps>;

  /** If not null, modal is assumed to be open. If null, assumed to be closed. */
  unitsModalScenarioId: Nullable<ScenarioId>;

  showUnitAphModal: boolean;
}

export interface AphModalProps {
  unitYear: UnitYear;
  scenarioId: ScenarioId;
  initialUnitYearAph: UnitYearAph[];
  unitYearOptions: OptionState[];
  initialScenarioUnitYearAph: ScenarioUnitYearAph[];
  setUnitYearAph: (unitYearAph: UnitYearAph[]) => void;
  setScenarioUnitYearAph: (scenarioUnitYearAph: ScenarioUnitYearAph[]) => void;
}

const initialState: UnitState = {
  allUnitYears: initialSliceDataState(),
  policyYear: null,
  aphModalProps: null,
  unitsModalScenarioId: null,
  showUnitAphModal: false,
};

export const unitsSlice = createSlice({
  name: 'units',
  initialState: initialState,
  reducers: {
    switchUnitYear(state, action: PayloadAction<number>) {
      state.policyYear = action.payload;
    },
    openUnitsModal(state, action: PayloadAction<ScenarioId>) {
      state.unitsModalScenarioId = action.payload;
    },
    closeUnitsModal(state) {
      state.unitsModalScenarioId = null;
    },
    toggleUnitAphModal(state, action: PayloadAction<Nullable<AphModalProps>>) {
      state.aphModalProps = action.payload ?? null;
      state.showUnitAphModal = !state.showUnitAphModal;
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allUnitYears, s => s.unitYearId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addUnitYear,
      affectedIds: arg => arg.newUnitYear.unitYearId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addUnitYears,
      affectedIds: arg => arg.unitYearsToAdd.map(x => x.unitYearId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchUnitYears,
      affectedIds: (arg, state) => getStateIdsMatching(state.allUnitYears.data, s => s.insuredId === arg.insuredId, s => s.unitYearId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchUnitYearsForQuotes,
      affectedIds: () => [],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchUnitYearsForScenarios,
      affectedIds: () => [],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyUnitYear,
      affectedIds: arg => arg.unitYearId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyUnitYears,
      affectedIds: arg => arg.unitYearsToUpdate.map(x => x.unitYearId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyUnitYearAphYearAcres,
      affectedIds: (arg, state) => getStateIdsMatching(state.allUnitYears.data, s => s.insuredId === arg.insuredId && s.year === arg.unitYear, s => s.unitYearId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeUnitYear,
      affectedIds: arg => arg.unitYear.unitYearId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeUnitYears,
      affectedIds: arg => arg.unitYearsToDelete.map(x => x.unitYearId),
    });

    builder
      //removeUnit
      .addCase(removeUnit.fulfilled, (state, action: PayloadAction<UnitId>) => {
        for (const unitYear of getKeyedStateValues(state.allUnitYears.data)) {
          if (unitYear.unitId === action.payload) {
            delete state.allUnitYears.data[unitYear.unitYearId];
          }
        }
      });
  },
});

/*
 this hides the normal openUnitsModal reducer, because we need to close the drawer
 when this modal shows, because of interference of useFederalOfferData, causing
 drawer data to get stale
*/
export const openUnitsModal = (scenarioId: ScenarioId) => (dispatch: AppDispatch) => {
  // Dispatch the action to close the drawer first
  dispatch(closeDrawer());

  // Dispatch the action to open the units modal
  dispatch(unitsSlice.actions.openUnitsModal(scenarioId));
};

// Memoized Selectors
export const selectUnitYearDictionary = (state: RootState) => state.units.allUnitYears.data;
export const selectUnitYearsByInsuredMap = createSelector([selectUnitYearDictionary], result => {
  const map = getKeyedStateGroupedBy(result, uy => uy.insuredId);
  const ordered = orderMap(map, DefaultOrders.unitYears);
  return ordered;
});

export const selectUnitYearsByInsuredAndYearMap = createSelector([selectUnitYearDictionary], result => {
  const grouped = getKeyedStateGroupedBy<InsuredAndYearFormat, UnitYear>(result, uy => buildInsuredAndYearKey(uy.insuredId, uy.year));
  const ordered = orderMap(grouped, DefaultOrders.unitYears);
  return ordered;
});

type InsuredAndYearFormat = `insuredId: ${InsuredId}|year: ${number}`;
export function buildInsuredAndYearKey(insuredId: InsuredId, year: number): InsuredAndYearFormat {
  return `insuredId: ${insuredId}|year: ${year}`;
}

//TODO #56450: might need to memoize this like we do for the matrix ones to prevent almost always having cache misses
export const selectUnitYearsByIds = createSelector([selectUnitYearDictionary, (_state: RootState, unitYearIds: UnitYearId[]) => unitYearIds], (allUnitYears, unitYearIds) => {
  const applicableUnitYears: UnitYear[] = [];
  unitYearIds.forEach(uyId => {
    const unitYear = allUnitYears[uyId] ?? null;
    if (unitYear !== null) applicableUnitYears.push(unitYear);
  });
  return applicableUnitYears.length === 0 ? stableEmptyArrayAsMutable<UnitYear>() : applicableUnitYears;
});

// Non-Memoized Selectors
export const selectPolicyYear = (state: RootState): Nullable<number> => state.units.policyYear;
export const selectUnitYearById = (state: RootState, unitYearId: UnitYearId) => state.units.allUnitYears.data[unitYearId] ?? null;

/** If this returns null, modal is not open. */
export const selectOpenUnitsModalScenarioId = (state: RootState) => state.units.unitsModalScenarioId;

export const selectIsUnitAphModalOpen = (state: RootState) => state.units.showUnitAphModal;
export const selectAphModalProps = (state: RootState) => state.units.aphModalProps;

export const selectUnitYearsThatCanApplyToScenario = (state: RootState, quoteId: QuoteId, typeId: Nullable<string>, practiceId: Nullable<string>, highRiskTypeId: HighRiskType) => {
  const quote = selectQuoteById(state, quoteId);

  if (quote === null) return stableEmptyArrayAsMutable<UnitYear>();

  const clientFile = selectClientFileById(state, quote.clientFileId);

  if (clientFile === null) return stableEmptyArrayAsMutable<UnitYear>();

  const allUnitYears = getKeyedStateValues(state.units.allUnitYears.data);
  return filterUnitYearsThatCanApplyToScenario(allUnitYears, clientFile, quote, typeId, practiceId, highRiskTypeId);
};

export const selectHistoricalTYieldsForUnitYear = createSelector([
  (state: RootState, _unitYearId: UnitYearId, _scenarioId: Nullable<ScenarioId>, countyId: string, commodityCode: string) => selectHistoricalTYieldsForCountyCommodity(state, countyId, commodityCode),
  (state: RootState, unitYearId: UnitYearId, _scenarioId: Nullable<ScenarioId>) => selectUnitYearById(state, unitYearId),
  (state: RootState) => selectAllRowCropScenarioPiecesByScenarioMap(state),
  (_state: RootState, _unitYearId: UnitYearId, scenarioId: Nullable<ScenarioId>) => scenarioId,
], (allHistoricalTYields, unitYear, scenarioPiecesByScenarioId, scenarioId) => {
  if (scenarioId === null) return stableEmptyArrayAsMutable<HistoricalTYield>();

  const scenarioPieces = getItemsForId(scenarioPiecesByScenarioId, scenarioId);

  const basePieceTypes = scenarioPieceOrderingServiceInstance.getBaseScenarioPieces();
  const baseScenarioPiece = scenarioPieces.find(sp => basePieceTypes.includes(sp.scenarioPieceType));

  if (baseScenarioPiece === undefined || unitYear === null) return stableEmptyArrayAsMutable<HistoricalTYield>();

  const planCode = getInsurancePlanCodeForScenarioPiece(baseScenarioPiece.scenarioPieceType);
  return allHistoricalTYields.filter(tYield => tYield.practiceId === unitYear.practiceId && tYield.typeId === unitYear.typeId && tYield.planCode === planCode);
});

export const selectTYieldForUnitYear = createSelector([
  (state: RootState) => selectAvailableTYields(state),
  (state: RootState) => selectAvailableSubCountyCodes(state),
  (state: RootState, unitYearId: UnitYearId) => selectUnitYearById(state, unitYearId),
], (tYields, subCountyCodes, unitYear) => {
  if (!unitYear) return null;
  return getFilteredTYield(tYields, subCountyCodes, unitYear.typeId, unitYear.practiceId, unitYear.subCountyCode)?.transitionalYield ?? null;
});

export const selectTrendAdjustmentFactorForUnitYear = createSelector([
  (state: RootState, _unitYearId: UnitYearId, _scenarioId: Nullable<ScenarioId>, countyId: string, commodityCode: string) => selectTrendAdjustmentFactorsForCountyCommodity(state, countyId, commodityCode),
  (state: RootState, unitYearId: UnitYearId, _scenarioId: Nullable<ScenarioId>) => selectUnitYearById(state, unitYearId),
  (state: RootState) => selectAllRowCropScenarioPiecesByScenarioMap(state),
  (_state: RootState, _unitYearId: UnitYearId, scenarioId: Nullable<ScenarioId>) => scenarioId,
], (allTrendAdjustmentFactors, unitYear, scenarioPiecesByScenarioId, scenarioId) => {
  if (scenarioId === null) return 0;

  const scenarioPieces = getItemsForId(scenarioPiecesByScenarioId, scenarioId);

  const basePieceTypes = scenarioPieceOrderingServiceInstance.getBaseScenarioPieces();
  const baseScenarioPiece = scenarioPieces.find(sp => basePieceTypes.includes(sp.scenarioPieceType));

  if (baseScenarioPiece === undefined || unitYear === null) return 0;

  const planCode = getInsurancePlanCodeForScenarioPiece(baseScenarioPiece.scenarioPieceType);
  const applicableTrendAdjustmentFactor = allTrendAdjustmentFactors.find(trendAdjustmentFactor => trendAdjustmentFactor.practiceId === unitYear.practiceId && trendAdjustmentFactor.typeId === unitYear.typeId && trendAdjustmentFactor.planCode === planCode) ?? null;

  return applicableTrendAdjustmentFactor !== null ? applicableTrendAdjustmentFactor.factor : 0;
});

export const selectTrendAdjustmentFactorForScenario = createSelector([
  (state: RootState, _scenarioId: Nullable<ScenarioId>, countyId: string, commodityCode: string) => selectTrendAdjustmentFactorsForCountyCommodity(state, countyId, commodityCode),
  (state: RootState) => selectAllRowCropScenarioPiecesByScenarioMap(state),
  (state: RootState, scenarioId: Nullable<ScenarioId>) => scenarioId === null ? null : selectScenarioById(state, scenarioId),
], (allTrendAdjustmentFactors, scenarioPiecesByScenarioId, scenario) => {
  if (scenario === null) return 0;

  const scenarioPieces = getItemsForId(scenarioPiecesByScenarioId, scenario.scenarioId);

  const basePieceTypes = scenarioPieceOrderingServiceInstance.getBaseScenarioPieces();
  const baseScenarioPiece = scenarioPieces.find(sp => basePieceTypes.includes(sp.scenarioPieceType));

  if (baseScenarioPiece === undefined) return 0;

  const planCode = getInsurancePlanCodeForScenarioPiece(baseScenarioPiece.scenarioPieceType);
  const applicableTrendAdjustmentFactor = allTrendAdjustmentFactors.find(trendAdjustmentFactor => trendAdjustmentFactor.practiceId === scenario.practiceId && trendAdjustmentFactor.typeId === scenario.typeId && trendAdjustmentFactor.planCode === planCode) ?? null;

  return applicableTrendAdjustmentFactor !== null ? applicableTrendAdjustmentFactor.factor : 0;
});

export const getApplicableUnitAphForUnitYear = (aphByUnitYearId: Map<UnitYearId, UnitYearAph[]>, unitYearId: Nullable<UnitYearId>) => {
  return getItemsForId(aphByUnitYearId, unitYearId).filter(aph => aph.aphYield !== null && aph.yieldType !== YIELD_DESCRIPTORS.Z).sort((a, b) => b.year - a.year).slice(0, 10);
};

const selectIsQuickQuoteForScenarioId = createSelector([
  (state: RootState) => state,
  (_state: RootState, scenarioId: Nullable<ScenarioId>) => scenarioId,
], (state, scenarioId) => {
  if (scenarioId === null) return false;

  const scenario = selectScenarioById(state, scenarioId);
  if (scenario === null) return false;

  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) return false;
  return quote.quickQuote;
});

const selectIsQuickQuoteForQuoteId = createSelector([
  (state: RootState) => state,
  (_state: RootState, quoteId: QuoteId) => quoteId,
], (state, quoteId) => {
  const quote = selectQuoteById(state, quoteId);
  if (quote === null) return false;
  return quote.quickQuote;
});

const selectQuickUnitForScenarioId = createSelector([
  (state: RootState) => state,
  (_state: RootState, scenarioId: Nullable<ScenarioId>) => scenarioId,
  selectIsQuickQuoteForScenarioId,
], (state, scenarioId, isQuickQuote) => {
  if (!isQuickQuote || scenarioId === null) return null;

  const scenario = selectScenarioById(state, scenarioId);
  if (scenario === null) return null;

  return scenario.quickUnit ?? null;
});

//--------Rate Yield Selectors--------//
function selectRateYield(isQuickQuote: boolean, quickUnit: Nullable<ScenarioQuickUnit>, unitYears: UnitYear[],
  unitYearAphMap: Map<UnitYearId, UnitYearAph[]>, scenarioId: Nullable<ScenarioId>): Nullable<number> {
  if (isQuickQuote) return scenarioId === null ? null : quickUnit?.rateYield ?? null;

  const unitYearMap = new Map(unitYears.map(uy => [uy, getItemsForId(unitYearAphMap, uy.unitYearId)]));
  return calculateWeightedAverageRateYield(unitYearMap);
}

export const selectRateYieldForScenario = createSelector([
  selectIsQuickQuoteForScenarioId,
  selectQuickUnitForScenarioId,
  (state: RootState, scenarioId: ScenarioId) => selectUnitYearsForScenario(state, scenarioId),
  selectUnitYearAphByUnitYearMap,
  (_state: RootState, scenarioId: ScenarioId) => scenarioId,
], selectRateYield, { memoizeOptions: { maxSize: 100 } });

export const selectRateYieldForTypePractice = createSelector([
  (state: RootState, _scenarioId: Nullable<ScenarioId>, quoteId: QuoteId) => selectIsQuickQuoteForQuoteId(state, quoteId),
  selectQuickUnitForScenarioId,
  (state: RootState, _scenarioId: Nullable<ScenarioId>, quoteId: QuoteId, typeId: Nullable<string>, practiceId: Nullable<string>, highRiskTypeId: HighRiskType) => selectUnitYears(state, quoteId, typeId, practiceId, highRiskTypeId),
  selectUnitYearAphByUnitYearMap,
  (_state: RootState, scenarioId: Nullable<ScenarioId>) => scenarioId,
], selectRateYield, { memoizeOptions: { maxSize: 100 } });

export const selectRateYieldForUnit = createSelector([
  selectUnitYearAphByUnitYearMap,
  (_state: RootState, unitYearId: Nullable<UnitYearId>) => unitYearId,
], (aphByUnitYearId, unitYearId): Nullable<number> => {
  const unitYearAph = getApplicableUnitAphForUnitYear(aphByUnitYearId, unitYearId);
  return calculateRateYield(unitYearAph);

}, { memoizeOptions: { maxSize: 100 } });
//-------- --------//

export function selectAdjustedYieldForScenario(state: RootState, scenarioId: ScenarioId, countyId: string, commodityCode: string) {
  const availableOptions = selectAvailableOptionsForQuote(state, countyId, commodityCode);

  const unitYears = selectUnitYearsForScenario(state, scenarioId);
  const unitYearAphMap = selectUnitYearAphByUnitYearMap(state);
  const scenarioUnitYearAph = selectScenarioUnitYearAphForScenarioId(state, scenarioId);
  const allHistoricalTYields = selectHistoricalTYieldsForCountyCommodity(state, countyId, commodityCode);
  const selectedOptionCodesMap = selectScenarioOptionCodesByUnitYearMap(state, scenarioId);
  const scenarioPiece = selectScenarioPieceForOfferData(state, scenarioId);
  const planCode = scenarioPiece?.planCode ?? null;
  const isQuickQuote = selectIsQuickQuoteForScenarioId(state, scenarioId);
  const quickUnit = selectQuickUnitForScenarioId(state, scenarioId);
  if (isQuickQuote) return quickUnit?.aphYield ?? null;
  const isCat = scenarioPiece?.rowCropScenarioPieceExtendedData?.isCat ?? false;

  const adjustedYieldProps = new Map<UnitYear, AdjustedYieldProps>();
  for (const uy of unitYears) {
    const unitYearAph = getItemsForId(unitYearAphMap, uy.unitYearId);
    const calcUnitYearAph = [];
    for (const unitYearAphRow of unitYearAph) {
      const scenUnitYearAphRow = scenarioUnitYearAph.find(row => row.unitYearAphId === unitYearAphRow.unitYearAphId);
      if (scenUnitYearAphRow === undefined) return null;
      const isYeApplicable = selectIsYeApplicable(state, uy.unitYearId, scenUnitYearAphRow.scenarioId, scenUnitYearAphRow.unitYearAphId);

      calcUnitYearAph.push({ ...unitYearAphRow, excluded: isYeApplicable && scenUnitYearAphRow.yeStatus === YeStatusType.NotOptedOut });
    }
    const historicalTYields = allHistoricalTYields.filter(ty => ty.practiceId === uy.practiceId &&
      ty.typeId === uy.typeId && (planCode === null || ty.planCode === planCode));
    const selectedOptionCodes = filterScenarioOptionsByAvailability(availableOptions, getItemsForId(selectedOptionCodesMap, uy.unitYearId), uy, planCode, isCat);

    adjustedYieldProps.set(uy, { unitYearAph: calcUnitYearAph, historicalTYields, selectedOptionCodes });
  }
  return calculateWeightedAverageAdjustedYield(adjustedYieldProps);
}

export function selectAdjustedYieldForTypePractice(state: RootState, scenarioId: Nullable<ScenarioId>, quoteId: QuoteId,
  typeId: string, practiceId: string, highRiskTypeId: HighRiskType, countyId: string, commodityCode: string) {
  const availableOptions = selectAvailableOptionsForQuote(state, countyId, commodityCode);
  const unitYears = selectUnitYears(state, quoteId, typeId, practiceId, highRiskTypeId);
  const unitYearAphMap = selectUnitYearAphByUnitYearMap(state);
  const scenarioUnitYearAph = selectScenarioUnitYearAphForScenarioId(state, scenarioId ?? undefined);
  const allHistoricalTYields = selectHistoricalTYieldsForCountyCommodity(state, countyId, commodityCode);
  const selectedOptionCodesMap = selectScenarioOptionCodesByUnitYearMap(state, scenarioId);
  const scenarioPiece = scenarioId ? selectScenarioPieceForOfferData(state, scenarioId) : null;
  const planCode = scenarioPiece?.planCode ?? null;
  const isQuickQuote = selectIsQuickQuoteForQuoteId(state, quoteId);
  const quickUnit = selectQuickUnitForScenarioId(state, scenarioId);
  if (isQuickQuote) return scenarioId === null ? null : quickUnit?.aphYield ?? null;
  const isCat = scenarioPiece?.rowCropScenarioPieceExtendedData?.isCat ?? false;

  const adjustedYieldProps = new Map<UnitYear, AdjustedYieldProps>();
  for (const uy of unitYears) {
    const unitYearAph = getItemsForId(unitYearAphMap, uy.unitYearId);
    const calcUnitYearAph = [];
    for (const unitYearAphRow of unitYearAph) {
      const scenUnitYearAphRow = scenarioUnitYearAph.find(row => row.unitYearAphId === unitYearAphRow.unitYearAphId);
      if (scenUnitYearAphRow === undefined) throw new MissingScenarioUnitYearAphInStateError(unitYearAphRow.unitYearAphId);
      const isYeApplicable = selectIsYeApplicable(state, uy.unitYearId, scenUnitYearAphRow.scenarioId, scenUnitYearAphRow.unitYearAphId);

      calcUnitYearAph.push({ ...unitYearAphRow, excluded: isYeApplicable && scenUnitYearAphRow.yeStatus === YeStatusType.NotOptedOut });
    }
    const historicalTYields = allHistoricalTYields.filter(ty => ty.practiceId === uy.practiceId &&
      ty.typeId === uy.typeId && (planCode === null || ty.planCode === planCode));
    const selectedOptionCodes = filterScenarioOptionsByAvailability(availableOptions, getItemsForId(selectedOptionCodesMap, uy.unitYearId), uy, planCode, isCat);

    adjustedYieldProps.set(uy, { unitYearAph: calcUnitYearAph, historicalTYields, selectedOptionCodes });
  }
  return calculateWeightedAverageAdjustedYield(adjustedYieldProps);
}

export function selectAdjustedYieldForScenarios(state: RootState, scenarioIds: ScenarioId[]) {
  const adjustedYieldByScenario = new Map<ScenarioId, Nullable<number>>();

  for (const scenarioId of scenarioIds) {
    const scenario = selectScenarioById(state, scenarioId);
    const quote = scenario === null ? null : selectQuoteById(state, scenario.quoteId);

    if (quote === null) continue;

    const adjustedYield = selectAdjustedYieldForScenario(state, scenarioId, quote.countyId, quote.commodityCode);

    adjustedYieldByScenario.set(scenarioId, adjustedYield);
  }

  return adjustedYieldByScenario;
}

export function selectAdjustedYieldForUnitYear(state: RootState, unitYearId: UnitYearId, scenarioId: ScenarioId, countyId: string, commodityCode: string) {
  const availableOptions = selectAvailableOptionsForQuote(state, countyId, commodityCode);
  const planCode = selectPrimaryPlanCodeOfScenario(state, scenarioId);

  const unitYearAph = selectUnitYearAphByUnitYearId(state, unitYearId);
  const unitYear = selectUnitYearById(state, unitYearId);
  const historicalTYields = selectHistoricalTYieldsForUnitYear(state, unitYearId, scenarioId, countyId, commodityCode);
  const selectedOptionCodes = selectScenarioOptionsForUnitYearId(state, unitYearId, scenarioId);
  const scenarioUnitYearAph = selectScenarioUnitYearAphForScenarioId(state, scenarioId);
  const isCat = selectScenarioPieceForOfferData(state, scenarioId)?.rowCropScenarioPieceExtendedData?.isCat ?? false;

  if (!unitYear) return null;
  const calcUnitYearAph = [];
  for (const unitYearAphRow of unitYearAph) {
    const scenUnitYearAphRow = scenarioUnitYearAph.find(scenAph => unitYearAphRow.unitYearAphId === scenAph.unitYearAphId);
    if (scenUnitYearAphRow === undefined) return null;
    const isYeApplicable = selectIsYeApplicable(state, unitYearAphRow.unitYearId, scenUnitYearAphRow.scenarioId, scenUnitYearAphRow.unitYearAphId);
    calcUnitYearAph.push({ ...unitYearAphRow, excluded: isYeApplicable && scenUnitYearAphRow.yeStatus === YeStatusType.NotOptedOut });
  }
  return calculateAdjustedYield({ unitYearAph: calcUnitYearAph, historicalTYields, selectedOptionCodes: filterScenarioOptionsByAvailability(availableOptions, selectedOptionCodes, unitYear, planCode ?? null, isCat) });
}

//-------- --------//
//--------Approved Yields Selectors--------//
function getApprovedYieldProps(
  state: RootState, unitYears: UnitYear[], unitYearAphMap: Map<UnitYearId, UnitYearAph[]>,
  scenarioUnitYearAph: ScenarioUnitYearAph[], allHistoricalTYields: HistoricalTYield[], tYields: TYield[],
  subCountyCodes: string[], selectedOptionCodesMap: Map<UnitYearId, OptionCode[]>,
  allTrendAdjustmentFactors: TrendAdjustmentFactor[], planCode: Nullable<string>, availableOptions: AvailableOptionSelections[], isCat: boolean,
): Map<UnitYear, ApprovedYieldProps> {
  const approvedYieldProps = new Map<UnitYear, ApprovedYieldProps>();

  for (const uy of unitYears) {
    const calcUnitYearAph = [];
    const unitYearAph = getItemsForId(unitYearAphMap, uy.unitYearId);
    for (const unitYearAphRow of unitYearAph) {
      const scenUnitYearRow = scenarioUnitYearAph.find(scenAph => unitYearAphRow.unitYearAphId === scenAph.unitYearAphId);
      if (scenUnitYearRow === undefined) continue;
      const isYeApplicable = selectIsYeApplicable(state, uy.unitYearId, scenUnitYearRow.scenarioId, scenUnitYearRow.unitYearAphId);
      calcUnitYearAph.push({ ...unitYearAphRow, excluded: isYeApplicable && scenUnitYearRow.yeStatus === YeStatusType.NotOptedOut });
    }
    const historicalTYields = allHistoricalTYields.filter(ty => ty.practiceId === uy.practiceId && ty.typeId === uy.typeId && (planCode === null || ty.planCode === planCode));
    const selectedOptionCodes = filterScenarioOptionsByAvailability(availableOptions, getItemsForId(selectedOptionCodesMap, uy.unitYearId), uy, planCode, isCat);
    const trendAdjustmentFactor = allTrendAdjustmentFactors.find(taf => taf.practiceId === uy.practiceId && taf.typeId === uy.typeId && (planCode === null || taf.planCode === planCode))?.factor ?? 0;
    const currentYearTYield = getFilteredTYield(tYields, subCountyCodes, uy.typeId, uy.practiceId, uy.subCountyCode)?.transitionalYield ?? null;
    approvedYieldProps.set(uy, {
      unitYearAph: calcUnitYearAph,
      historicalTYields,
      selectedOptionCodes,
      trendAdjustmentFactor,
      currentYearTYield,
    });
  }
  return approvedYieldProps;
}

export function selectApprovedYieldForScenario(state: RootState, scenarioId: ScenarioId, countyId: string, commodityCode: string) {
  const availableOptions = selectAvailableOptionsForQuote(state, countyId, commodityCode);

  const unitYears = selectUnitYearsForScenario(state, scenarioId);
  const unitYearAphMap = selectUnitYearAphByUnitYearMap(state);
  const scenarioUnitYearAph = selectScenarioUnitYearAphForScenarioId(state, scenarioId);
  const allHistoricalTYields = selectHistoricalTYieldsForCountyCommodity(state, countyId, commodityCode);
  const tYields = selectAvailableTYields(state);
  const subCountyCodes = selectAvailableSubCountyCodes(state);
  const selectedOptionCodesMap = selectScenarioOptionCodesByUnitYearMap(state, scenarioId);
  const allTrendAdjustmentFactors = selectTrendAdjustmentFactorsForCountyCommodity(state, countyId, commodityCode);
  const scenarioPieceForOfferData = selectScenarioPieceForOfferData(state, scenarioId);
  const isQuickQuote = selectIsQuickQuoteForScenarioId(state, scenarioId);
  const quickUnit = selectQuickUnitForScenarioId(state, scenarioId);
  const planCode = scenarioPieceForOfferData?.planCode ?? null;
  if (isQuickQuote) return quickUnit?.approvedYield ?? null;
  const isCat = scenarioPieceForOfferData?.rowCropScenarioPieceExtendedData?.isCat ?? false;
  const approvedYieldProps = getApprovedYieldProps(state, unitYears, unitYearAphMap, scenarioUnitYearAph,
    allHistoricalTYields, tYields, subCountyCodes, selectedOptionCodesMap, allTrendAdjustmentFactors, planCode, availableOptions, isCat);

  return calculateWeightedAverageApprovedYield(approvedYieldProps);
}

export function selectApprovedYieldForTypePractice(state: RootState, scenarioId: Nullable<ScenarioId>, quoteId: QuoteId,
  typeId: Nullable<string>, practiceId: Nullable<string>, highRiskTypeId: HighRiskType, countyId: string, commodityCode: string) {
  const availableOptions = selectAvailableOptionsForQuote(state, countyId, commodityCode);

  const unitYears = selectUnitYears(state, quoteId, typeId, practiceId, highRiskTypeId);
  const unitYearAphMap = selectUnitYearAphByUnitYearMap(state);
  const scenarioUnitYearAph = selectScenarioUnitYearAphForScenarioId(state, scenarioId ?? undefined);
  const allHistoricalTYields = selectHistoricalTYieldsForCountyCommodity(state, countyId, commodityCode);
  const tYields = selectAvailableTYields(state);
  const subCountyCodes = selectAvailableSubCountyCodes(state);
  const selectedOptionCodesMap = selectScenarioOptionCodesByUnitYearMap(state, scenarioId);
  const allTrendAdjustmentFactors = selectTrendAdjustmentFactorsForCountyCommodity(state, countyId, commodityCode);
  const isQuickQuote = selectIsQuickQuoteForQuoteId(state, quoteId);
  const quickUnit = selectQuickUnitForScenarioId(state, scenarioId);
  const scenarioPiece = scenarioId ? selectScenarioPieceForOfferData(state, scenarioId) : null;
  const planCode = scenarioPiece?.planCode ?? null;
  const isCat = scenarioPiece?.rowCropScenarioPieceExtendedData?.isCat ?? false;
  if (isQuickQuote) return scenarioId === null ? null : quickUnit?.approvedYield ?? null;

  const approvedYieldProps = getApprovedYieldProps(state, unitYears, unitYearAphMap, scenarioUnitYearAph,
    allHistoricalTYields, tYields, subCountyCodes, selectedOptionCodesMap, allTrendAdjustmentFactors, planCode, availableOptions, isCat);

  return calculateWeightedAverageApprovedYield(approvedYieldProps);
}

export function selectApprovedYieldForScenarios(state: RootState, scenarioIds: ScenarioId[]) {
  const approvedYieldByScenario = new Map<ScenarioId, Nullable<number>>();

  for (const scenarioId of scenarioIds) {
    const scenario = selectScenarioById(state, scenarioId);
    const quote = scenario === null ? null : selectQuoteById(state, scenario.quoteId);

    if (quote === null) continue;

    const approvedYield = selectApprovedYieldForScenario(state, scenarioId, quote.countyId, quote.commodityCode);

    approvedYieldByScenario.set(scenarioId, approvedYield);
  }

  return approvedYieldByScenario;
}

export function selectApprovedYieldForUnitYear(state: RootState, unitYearId: UnitYearId, scenarioId: ScenarioId, countyId: string, commodityCode: string) {
  const availableOptions = selectAvailableOptionsForQuote(state, countyId, commodityCode);

  const unitYear = selectUnitYearById(state, unitYearId);
  const unitYearAph = selectUnitYearAphByUnitYearId(state, unitYearId);
  const historicalTYields = selectHistoricalTYieldsForUnitYear(state, unitYearId, scenarioId, countyId, commodityCode);
  const currentYearTYield = selectTYieldForUnitYear(state, unitYearId);
  const selectedOptionCodes = selectScenarioOptionsForUnitYearId(state, unitYearId, scenarioId);
  const trendAdjustmentFactor = selectTrendAdjustmentFactorForUnitYear(state, unitYearId, scenarioId, countyId, commodityCode);
  const scenarioUnitYearAph = selectScenarioUnitYearAphForScenarioId(state, scenarioId);
  const primaryPlanCode = selectPrimaryPlanCodeOfScenario(state, scenarioId);
  const scenarioPieceForOfferData = selectScenarioPieceForOfferData(state, scenarioId);
  const isCat = scenarioPieceForOfferData?.rowCropScenarioPieceExtendedData?.isCat ?? false;

  if (!unitYear) return null;

  const calcUnitYearAph = [];
  for (const unitYearAphRow of unitYearAph) {
    const scenUnitYearRow = scenarioUnitYearAph.find(scenAph => unitYearAphRow.unitYearAphId === scenAph.unitYearAphId);
    if (scenUnitYearRow === undefined) return null;
    const isYeApplicable = selectIsYeApplicable(state, unitYear.unitYearId, scenUnitYearRow.scenarioId, scenUnitYearRow.unitYearAphId);
    calcUnitYearAph.push({ ...unitYearAphRow, excluded: isYeApplicable && scenUnitYearRow.yeStatus === YeStatusType.NotOptedOut });
  }

  return calculateApprovedYield(unitYear, { unitYearAph: calcUnitYearAph, historicalTYields, selectedOptionCodes: filterScenarioOptionsByAvailability(availableOptions, selectedOptionCodes, unitYear, primaryPlanCode ?? null, isCat), trendAdjustmentFactor, currentYearTYield });
}

export function selectIsYeApplicable(state: RootState, unitYearId: UnitYearId, scenarioId: ScenarioId, unitYearAphId: UnitYearAphId) {
  const scenario = selectScenarioById(state, scenarioId);
  const unitYear = selectUnitYearById(state, unitYearId);
  const unitYearAphRow = selectUnitYearAphById(state, unitYearAphId);
  const selectedOptionCodes = selectScenarioOptionsForUnitYearId(state, unitYearId, scenarioId);
  if (unitYearAphRow === null || scenario === null || unitYear === null) return false;
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) return false;
  const yeYears = selectYeYears(state, scenario.scenarioId, quote.countyId, quote.commodityCode, unitYear.typeId, unitYear.practiceId);
  return selectedOptionCodes.some(o => o === OptionCode.YE) && yeYears.includes(unitYearAphRow.year);
}

//--------Acres Selectors--------//
function selectAcres(isQuickQuote: boolean, quickUnit: Nullable<ScenarioQuickUnit>, unitYears: UnitYear[]) {
  if (isQuickQuote) return quickUnit?.acres ?? null;
  return calculateTotalAcres(unitYears);
}

export const selectNetAcresForScenarios = (state: RootState, scenarioIds: ScenarioId[]): Record<ScenarioId, Nullable<number>> => {
  const defaultAcres = null;
  const scenarioIdToAcresMapping: Record<ScenarioId, Nullable<number>> = {};

  for (const scenarioId of scenarioIds) {
    const scenario = selectScenarioById(state, scenarioId);
    const quote = !scenario ? null : selectQuoteById(state, scenario.quoteId);
    if (!scenario || !quote) {
      scenarioIdToAcresMapping[scenarioId] = null;
    } else {
      if (quote.quickQuote) {
        if (isNotNullOrUndefined(scenario.quickUnit) && isNotNullOrUndefined(scenario.quickUnit.acres) && isNotNullOrUndefined(scenario.quickUnit.sharePercent)) {
          scenarioIdToAcresMapping[scenarioId] = scenario.quickUnit.acres * scenario.quickUnit.sharePercent;
        } else {
          scenarioIdToAcresMapping[scenarioId] = defaultAcres;
        }
      } else {
        const unitsForScenario = selectUnitYears(state, quote.quoteId, scenario.typeId, scenario.practiceId, scenario.highRiskTypeId);
        scenarioIdToAcresMapping[scenarioId] = calculateTotalAcres(unitsForScenario);
      }
    }
  }

  return scenarioIdToAcresMapping;
};

export const selectAcresForScenario = createSelector([
  selectIsQuickQuoteForScenarioId,
  selectQuickUnitForScenarioId,
  (state: RootState, scenarioId: ScenarioId) => selectUnitYearsForScenario(state, scenarioId),
], selectAcres);

export const selectAcresForTypePractice = createSelector([
  (state: RootState, _scenarioId: Nullable<ScenarioId>, quoteId: QuoteId) => selectIsQuickQuoteForQuoteId(state, quoteId),
  selectQuickUnitForScenarioId,
  (state: RootState, _scenarioId: Nullable<ScenarioId>, quoteId: QuoteId, typeId: Nullable<string>, practiceId: Nullable<string>, highRiskTypeId: HighRiskType) => selectUnitYears(state, quoteId, typeId, practiceId, highRiskTypeId),
], selectAcres);
//-------- -------//

//--------Share Percent Selectors--------//
function selectAverageSharePercent(isQuickQuote: boolean, quickUnit: Nullable<ScenarioQuickUnit>) {
  if (isQuickQuote) return quickUnit?.sharePercent ?? null;
  return calculateAverageSharePercent();
}

export const selectAverageSharePercentForScenario = createSelector([
  selectIsQuickQuoteForScenarioId,
  selectQuickUnitForScenarioId,
], selectAverageSharePercent);

export const selectAverageSharePercentForTypePractice = createSelector([
  (state: RootState, _scenarioId: Nullable<ScenarioId>, quoteId: QuoteId) => selectIsQuickQuoteForQuoteId(state, quoteId),
  selectQuickUnitForScenarioId,
], selectAverageSharePercent);

export const selectAverageSharePercentForScenarios = createSelector([
  (state: RootState, _scenarioIds: ScenarioId[]) => state,
  (_state: RootState, scenarioIds: ScenarioId[]) => scenarioIds,
], (state, scenarioIds) => {
  const sharePercentByScenario = new Map<ScenarioId, number>();

  for (const scenarioId of scenarioIds) {
    const sharePercent = selectAverageSharePercentForScenario(state, scenarioId);
    sharePercentByScenario.set(scenarioId, sharePercent ?? 100);
  }

  return sharePercentByScenario;
});
//-------- -------//

export const selectYeYears = createSelector([
  (state: RootState, scenarioId: ScenarioId) => selectScenarioPieceForOfferData(state, scenarioId),
  (state: RootState, _scenarioId: ScenarioId, countyId: string, commodityCode: string) => selectYeYearsForCountyCommodity(state, countyId, commodityCode),
  (_state: RootState, _scenarioId: ScenarioId, _countyId: string, _commodityCode: string, typeId: string) => typeId,
  (_state: RootState, _scenarioId: ScenarioId, _countyId: string, _commodityCode: string, _typeId: string, practiceId: string) => practiceId,
], (scenarioPiece, yeYears, typeId, practiceId) => {
  return getYeYearForTypePracticePlan(yeYears, typeId, practiceId, scenarioPiece?.planCode ?? null);
});

export const { switchUnitYear, closeUnitsModal, toggleUnitAphModal } = unitsSlice.actions;

export const fetchUnitYears = createAppAsyncThunk('units/fetchUnitYears', async ({ insuredId }: { insuredId: InsuredId }) => {
  return await getUnitYearsForInsuredRequest(insuredId);
});

export const fetchUnitYearsForQuotes = createAppAsyncThunk('units/fetchUnitYearsForQuotes', async ({ quoteIds }: { quoteIds: QuoteId[] }) => {
  return await getUnitYearsForQuotesRequest(quoteIds);
});

export const fetchUnitYearsForScenarios = createAppAsyncThunk('units/fetchUnitYearsForScenarios', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  return await getUnitYearsForScenariosRequest(scenarioIds);
});

export const addUnitYearFromForm = createAppAsyncThunk('units/addUnitYearFromForm', async ({ unitYear, policyYear, insuredId }: { unitYear: UnitFormFields, policyYear: number, insuredId: InsuredId }, thunkApi) => {
  //Since we aren't actually saving the history in this call I'm just setting the history to null so we have all of the fields we need but aren't providing data that won't be used
  const newUnitYear: UnitYear = {
    ...unitYear,
    year: policyYear,
    insuredId: insuredId,
    unitYearId: generatePrimaryKey(),
    unitId: generatePrimaryKey(),
    history: [],
    offlineCreatedOn: undefined,
    offlineLastUpdatedOn: undefined,
    offlineDeletedOn: undefined,
    priorYearApprYield: null,
  };

  await thunkApi.dispatch(addUnitYear({ newUnitYear }));
  return newUnitYear;
});

export const addUnitYear = createAppAsyncThunk('units/addUnitYear', async ({ newUnitYear }: { newUnitYear: UnitYear }) => {
  await createUnitYearRequest(newUnitYear);
  return newUnitYear;
});

/**
 * This thunk is the orchestrator for everything that goes into updating, deleting and saving units from the units modal. It figures out which units need to be added, removed
 * or updated and dispatches those thunks as required.
 */
export const saveUnitYears = createAppAsyncThunk('units/saveUnitYears', async ({ newUnitYears, existingUnitYears }: { newUnitYears: UnitYear[], existingUnitYears: UnitYear[] }, thunkApi) => {
  const promisesToAwait: Promise<unknown>[] = [];

  // This thunk currently submits unitYear updates one at a time because we don't have a bulk end point for units/unitYears yet on the back end.
  const toDelete = existingUnitYears.filter(x => !newUnitYears.find(y => y.unitYearId === x.unitYearId));
  if (toDelete.length > 0) {
    promisesToAwait.push(thunkApi.dispatch(removeUnitYears({ unitYearsToDelete: toDelete })));
  }

  const toAdd = newUnitYears.filter(x => !existingUnitYears.find(y => y.unitYearId === x.unitYearId));
  if (toAdd.length > 0) {
    promisesToAwait.push(thunkApi.dispatch(addUnitYears({ unitYearsToAdd: toAdd })));
  }

  const toUpdate = newUnitYears.filter(x => existingUnitYears.find(y => y.unitYearId === x.unitYearId));
  if (toUpdate.length > 0) {
    promisesToAwait.push(thunkApi.dispatch(modifyUnitYears({ unitYearsToUpdate: toUpdate })));
  }

  await Promise.all(promisesToAwait);
});

const addUnitYears = createAppAsyncThunk('units/addUnitYears', async ({ unitYearsToAdd }: { unitYearsToAdd: UnitYear[] }, _thunkApi) => {
  await batchCreateUnitYearRequest(unitYearsToAdd);
  return unitYearsToAdd;
});

const modifyUnitYears = createAppAsyncThunk('units/modifyUnitYears', async ({ unitYearsToUpdate }: { unitYearsToUpdate: UnitYear[] }, _thunkApi) => {
  await batchUpdateUnitYearsRequest(unitYearsToUpdate);
  return unitYearsToUpdate;
});

const removeUnitYears = createAppAsyncThunk('units/removeUnitYears', async ({ unitYearsToDelete }: { unitYearsToDelete: UnitYear[] }, _thunkApi) => {
  await batchDeleteUnitYearsRequest(unitYearsToDelete);
  return unitYearsToDelete;
});

export const modifyUnitYearAphYearAcres = createAppAsyncThunk('units/modifyUnitYearAphYearAcres', async ({ insuredId, unitYear, historicalYear }: { insuredId: InsuredId, unitYear: number, historicalYear: number }) => {
  return await updateUnitYearAphAcresRequest(insuredId, unitYear, historicalYear);
});

export const modifyUnitYear = createAppAsyncThunk('units/modifyUnitYear', async ({ unitFormFields, policyYear, insuredId, unitId, unitYearId, unitYear }: { unitFormFields: UnitFormFields, policyYear: number, insuredId: InsuredId, unitId: string, unitYearId: UnitYearId, unitYear: UnitYear }) => {
  //Since we aren't actually saving the history in this call I'm just setting the history to an empty array so we have all of the fields we need but aren't providing data that won't be used
  const newUnitYear: UnitYear = {
    ...unitYear,
    ...unitFormFields,
    year: policyYear,
    insuredId: insuredId,
    unitYearId: unitYearId,
    unitId: unitId,
  };

  await updateUnitYearRequest(newUnitYear);
  return newUnitYear;
});

export const removeUnit = createAppAsyncThunk('units/removeUnit', async ({ unitId }: { unitId: UnitId }) => {
  await deleteUnitRequest(unitId);
  return unitId;
});

export const removeUnitYear = createAppAsyncThunk('units/removeUnitYear', async ({ unitYear }: { unitYear: UnitYear }) => {
  await deleteUnitYearRequest(unitYear.unitYearId);
  return unitYear;
});


export const cloneClientFileUnitsIntoNewInsured = createAppAsyncThunk('units/cloneClientFileUnitsIntoNewInsured', async ({ sourceClientFileId, destinationInsuredId }: { sourceClientFileId: ClientFileId, destinationInsuredId: InsuredId }, thunkApi) => {
  const state = thunkApi.getState();

  // Given a client file, we need its year and its insured id to know which units to clone.
  const clientFile = selectClientFileById(state, sourceClientFileId);

  // The source client file needs to be in memory or we can't proceed.
  if (clientFile === null) { throw new MissingClientFileInStateError(sourceClientFileId); }

  const { insuredId: sourceInsuredId, year: unitYear } = clientFile;

  if (sourceInsuredId === null) { throw new Error('Cannot clone units from a client file that is not attached to an insured.'); }

  const unitYearsByInsuredAndYearMap = selectUnitYearsByInsuredAndYearMap(state);

  const insuredAndYearKey = buildInsuredAndYearKey(sourceInsuredId, unitYear);
  const sourceUnitsToClone = getItemsForId(unitYearsByInsuredAndYearMap, insuredAndYearKey);

  // Not an error, but if there are no units to clone, our work is done.
  if (sourceUnitsToClone.length === 0) { return; }

  // Now we have the list of units to potentially clone to the insured.
  // First though, we need to know which ones potentially already exist on the insured.
  // To do this, we're going to need a custom comparison

  // ==============================================

  // Get the existing units for the destination insured.
  const existingDestinationUnitYears = unitYearsByInsuredAndYearMap.get(buildInsuredAndYearKey(destinationInsuredId, unitYear)) ?? [];

  const sourceUnitToDestinationUnitMap = new Map<UnitYearId, UnitYearId>();

  for (const sourceUnit of sourceUnitsToClone) {
    const existingDestinationUnit = existingDestinationUnitYears.find(destinationUnit => isUnitYearDataEquivalent(sourceUnit, destinationUnit));

    if (existingDestinationUnit) {
      sourceUnitToDestinationUnitMap.set(sourceUnit.unitYearId, existingDestinationUnit.unitYearId);
    }
  }

  // Unit Years to add are those where there isn't some equivalent unit year already on the destination insured.
  const unitYearsToAddToDestination = sourceUnitsToClone.filter(sourceUnit => sourceUnitToDestinationUnitMap.get(sourceUnit.unitYearId) === undefined);

  const reassignedUnitYearsToAddToDestination = unitYearsToAddToDestination.map(unitYear => {

    const newUnitYear: UnitYear = {
      ...unitYear,

      // Reset the key fields - we are adding.
      insuredId: destinationInsuredId,
      unitYearId: generatePrimaryKey(),
      unitId: generatePrimaryKey(),
    };

    // Update the relationship map as we go.
    sourceUnitToDestinationUnitMap.set(unitYear.unitYearId, newUnitYear.unitYearId);

    return newUnitYear;
  });

  await thunkApi.dispatch(addUnitYears({ unitYearsToAdd: reassignedUnitYearsToAddToDestination }));

  // ==============================================

  // Any unit years needed to be added have been. Next is aph.

  // It's ok that this state hasn't been refreshed since adding unit years above. We know aph was not added for any new units.
  const unitYearAphMap = selectUnitYearAphByUnitYearMap(state);
  const aphBatchToAdd: UnitYearAph[] = [];

  for (const sourceUnit of sourceUnitsToClone) {
    const aphForSourceUnit = getItemsForId(unitYearAphMap, sourceUnit.unitYearId);

    const destinationUnitYearId = sourceUnitToDestinationUnitMap.get(sourceUnit.unitYearId);

    if (destinationUnitYearId === undefined) { continue; } // This should be impossible given the above logic.

    const aphForDestinationUnit = getItemsForId(unitYearAphMap, destinationUnitYearId);

    // For each aph in the source unit, try to find a matching aph in the destination unit.
    const aphToAddToDestination = aphForSourceUnit.filter(sourceAph => !aphForDestinationUnit.some(destinationAph => isUnitYearAphEquivalent(sourceAph, destinationAph)));

    const reassignedAphToAddToDestination: UnitYearAph[] = aphToAddToDestination.map(aph => {
      return {
        ...aph,

        // Reset the key fields - we are adding.
        unitYearAphId: generatePrimaryKey(),
        unitYearId: destinationUnitYearId,
      };
    });

    aphBatchToAdd.push(...reassignedAphToAddToDestination);
  }

  await thunkApi.dispatch(addUnitYearAph({ unitYearAph: aphBatchToAdd }));

  // ==============================================
});

export const changeUnitOwnership = createAppAsyncThunk('units/changeUnitOwnership', async ({ clientFile }: { clientFile: ClientFile }, thunkApi) => {
  // Guards
  if (clientFile.insuredId === null) {
    throw new Error('Cannot change unit ownership for a client file that is not attached to an insured.');
  }

  // Pull out state
  const state = thunkApi.getState();

  // We need to know about all of the scenarios for the client file, since all unit data we need to reassign is in some way tied to scenarios.
  const allScenariosForClientFile = getItemsForId(selectAllScenariosByClientFileIdMap(state), clientFile.clientFileId);
  const allScenarioIdsForClientFile = allScenariosForClientFile.map(scenario => scenario.scenarioId);

  // ==============================================

  // ScenarioOptionUnitYear - Remap all "UnitYearId" to the new "UnitYearId" for the destination insured.
  // 1) Get all ScenarioOptionUnitYears for the source insured.
  const scenarioOptionUnitYearsForClientFile = selectScenarioOptionUnitYearsByScenarioIds(state, allScenarioIdsForClientFile);

  // Pull out the unit years for the client file.
  const unitYearsByInsuredAndYearMap = selectUnitYearsByInsuredAndYearMap(state);
  const insuredAndYearKey = buildInsuredAndYearKey(clientFile.insuredId, clientFile.year);
  const relevantUnitYearsForSelf = getItemsForId(unitYearsByInsuredAndYearMap, insuredAndYearKey);

  const scenarioOptionUnitYearAndNewUnitYearMap = new Map<ScenarioOptionUnitYear, UnitYear>();

  for (const scenarioOptionUnitYear of scenarioOptionUnitYearsForClientFile) {
    // Pull out the unit year for each option, because this is the thing we're going to have to potentially change the ownership of.
    const unitYear = selectUnitYearById(state, scenarioOptionUnitYear.unitYearId);
    if (unitYear === null) {
      throw new MissingUnitYearInStateError(scenarioOptionUnitYear.unitYearId);
    }

    // This means that everything is already fine. We don't need to remap anything.
    if (unitYear.insuredId === clientFile.insuredId) {
      continue;
    }

    // We know we need to change the unit year on this record now - it is associated with the wrong insured.
    // So we need to find some expected unit year that exists on this insured.

    // Performing a rename now for legibility
    const wronglyAssignedUnitYear = unitYear;

    const correctUnitYear = relevantUnitYearsForSelf.find(unitYear => isUnitYearDataEquivalent(unitYear, wronglyAssignedUnitYear));

    if (correctUnitYear === undefined) {
      throw new Error('Could not find a matching unit year for the scenario option unit year.');
    }

    scenarioOptionUnitYearAndNewUnitYearMap.set(scenarioOptionUnitYear, correctUnitYear);
  }

  // From the information derived above, create a raw array of updates to be made.
  const scenarioOptionUnitYearUpdateArray: ScenarioOptionUnitYear[] = [...scenarioOptionUnitYearAndNewUnitYearMap.entries()].map(([existing, newUnit]) => ({
    ...existing,
    unitYearId: newUnit.unitYearId,
  }));

  // Actually persist the changes, if any
  if (scenarioOptionUnitYearUpdateArray.length > 0) {
    await thunkApi.dispatch(updateScenarioOptionUnitYears(scenarioOptionUnitYearUpdateArray));
  }

  // ==============================================

  // ScenarioUnitYearAph - Remap all "UnitYearAphId" to the new "UnitYearAphId" for the destination insured.

  // 1) Get all ScenarioUnitYearAph for the scenarios for the client file.
  const scenarioUnitYearAphForClientFile = selectScenarioUnitYearAphByScenarioIds(state, allScenarioIdsForClientFile);

  // Pull out the unit year aphs for the client file.
  const relevantUnitYearAphForSelf = selectUnitYearAphByUnitYearIds(state, relevantUnitYearsForSelf.map(unitYear => unitYear.unitYearId));

  const scenarioUnitYearAphAndNewUnitYearAphMap = new Map<ScenarioUnitYearAph, UnitYearAph>();

  for (const scenarioUnitYearAph of scenarioUnitYearAphForClientFile) {
    // Pull out the unit year aph for each option, because this is the thing we're going to have to potentially change the ownership of.
    const unitYearAph = selectUnitYearAphById(state, scenarioUnitYearAph.unitYearAphId);
    if (unitYearAph === null) {
      throw new MissingUnitYearAphInStateError(scenarioUnitYearAph.unitYearAphId);
    }

    // Need to join out to the unit year to check if the relationship is correct.
    const unitYearForAph = selectUnitYearById(state, unitYearAph.unitYearId);
    if (unitYearForAph === null) {
      throw new MissingUnitYearInStateError(unitYearAph.unitYearId);
    }

    // This means that everything is already fine. We don't need to remap anything.
    if (unitYearForAph.insuredId === clientFile.insuredId) {
      continue;
    }

    // We know we need to change the unit year aph on this record now - it is associated with the wrong insured.
    // So we need to find some expected unit year ah that exists on this insured.

    // Performing a rename now for legibility
    const wronglyAssignedUnitYearAph = unitYearAph;

    const correctUnitYearAph = relevantUnitYearAphForSelf.find(unitYearAph => isUnitYearAphEquivalent(unitYearAph, wronglyAssignedUnitYearAph));

    if (correctUnitYearAph === undefined) {
      throw new Error('Could not find a matching unit year aph for the scenario unit year aph.');
    }

    scenarioUnitYearAphAndNewUnitYearAphMap.set(scenarioUnitYearAph, correctUnitYearAph);
  }

  const scenarioUnitYearAphUpdateArray: ScenarioUnitYearAph[] = [...scenarioUnitYearAphAndNewUnitYearAphMap.entries()].map(([existing, newUnitAph]) => ({
    ...existing,
    unitYearAphId: newUnitAph.unitYearAphId,
  }));

  // Actually persist the changes, if any
  if (scenarioUnitYearAphUpdateArray.length > 0) {
    await thunkApi.dispatch(modifyScenarioUnitYearAph({ scenarioUnitYearAph: scenarioUnitYearAphUpdateArray }));
  }
});

/** Compares if two unit years are equivalent, ignoring any key relationships.
 */
const isUnitYearDataEquivalent = (unit1: UnitYear, unit2: UnitYear): boolean => {
  const areEquivalent =
    unit1.countyId === unit2.countyId &&
    unit1.commodityCode === unit2.commodityCode &&
    unit1.typeId === unit2.typeId &&

    unit1.practiceId === unit2.practiceId &&
    unit1.acres === unit2.acres &&
    unit1.sharePercent === unit2.sharePercent &&

    unit1.basicUnitNumber === unit2.basicUnitNumber &&
    unit1.optionalUnitNumber === unit2.optionalUnitNumber &&
    unit1.year === unit2.year &&

    unit1.farmName === unit2.farmName &&
    unit1.farmNumber === unit2.farmNumber &&
    unit1.section === unit2.section &&

    unit1.township === unit2.township &&
    unit1.range === unit2.range &&
    unit1.location === unit2.location &&

    unit1.subCountyCode === unit2.subCountyCode &&
    unit1.priorYearApprYield === unit2.priorYearApprYield;

  return areEquivalent;
};

/** Compares if two unit year aphs are equivalent, ignoring any key relationships.
 */
const isUnitYearAphEquivalent = (aph1: UnitYearAph, aph2: UnitYearAph): boolean => {
  const areEquivalent =
    aph1.yieldType === aph2.yieldType &&
    aph1.year === aph2.year &&
    aph1.acres === aph2.acres &&
    aph1.aphYield === aph2.aphYield &&
    aph1.production === aph2.production &&
    aph1.preQaYield === aph2.preQaYield;

  return areEquivalent;
};

export default unitsSlice.reducer;

