import { useMsal } from '@azure/msal-react';
import { Avatar, Stack, Typography } from '@mui/material';
import { stringAvatar } from '../../utils/avatar';

const UserInfo = () => {
  const { accounts } = useMsal();

  const firstAccount = accounts.at(0);
  const icon = stringAvatar(firstAccount?.name ?? '');

  if (accounts.length > 0) {
    return <Stack direction="row" sx={{ m: 1 }} alignItems={'center'}>
      <Avatar {...icon} sx={{ mr: 1.25, ...icon.sx }}  />
      <div>
        {firstAccount?.name}
        <Typography variant="caption" component="div">
          {firstAccount?.username}
        </Typography>
      </div>
    </Stack>;
  }

  return null;
};

export default UserInfo;