import BulkAdmData from '../types/api/offline/BulkAdmData';
import axios from 'axios';
import { InsuredId } from '../types/api/PrimaryKeys';
import BulkInsuredData from '../types/api/offline/BulkInsuredData';
import BulkIceData from '../types/api/offline/BulkIceData';
import BulkPrivateProductData from '../types/api/offline/BulkPrivateProductData';
import { BulkInsuredsWithTimestampRequest } from '../types/api/offline/BulkInsuredsWithTimestampRequest';
import { BulkServerDeletedStatusModel } from '../types/api/offline/BulkServerDeletedStatusModel';
import OfflineReconciliationData from '../types/api/offline/OfflineReconciliationData';
import { envBaseApiUrl } from '../constants/envConstants';

export const getBulkAdmData = (year: number, countyIds: string[]) => axios.post<BulkAdmData>(`${envBaseApiUrl}/offline/adm`, countyIds, { params: { year } });

export const getBulkInsuredData = (insuredIds: InsuredId[]) => axios.post<BulkInsuredData>(`${envBaseApiUrl}/offline/insureds`, insuredIds);

/** Returns all bulk data that was modified on the server after the supplied date. */
export const getBulkInsuredDataModifiedAfter = (request: BulkInsuredsWithTimestampRequest) => axios.post<BulkInsuredData>(`${envBaseApiUrl}/offline/insureds-modified-after`, request);

/** For the provided ids for each entity type, returns the ids of the entities that were deleted on the server. */
export const getServerDeletedEntities = (request: BulkServerDeletedStatusModel) => axios.post<BulkServerDeletedStatusModel>(`${envBaseApiUrl}/offline/deleted-entities`, request);

export const getBulkIceData = (year: number, countyIds: string[]) => axios.post<BulkIceData>(`${envBaseApiUrl}/offline/ice`, countyIds, { params: { year } });

export const getBulkPrivateProductData = (year: number, countyIds: string[]) => axios.post<BulkPrivateProductData>(`${envBaseApiUrl}/offline/private-products`, countyIds, { params: { year } });

export const syncOfflineChanges = (offlineReconciliationData: OfflineReconciliationData) => axios.post<void>(`${envBaseApiUrl}/offline/sync`, offlineReconciliationData);