import { Checkbox, Menu, MenuItem, Typography } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { AllPossibleScenarioLinkFields } from '../../../utils/userSettingUtils';
import { PossibleLinkedScenarioField } from '../../../types/api/userSettings/PossibleLinkedScenarioField';
import { QuoteId } from '../../../types/api/PrimaryKeys';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { addLinkedFields, removeAllLinkedFields, removeLinkedFields } from '../../../app/userSettingsSlice';

interface Props {
  linkedScenarioFields: PossibleLinkedScenarioField[];
  quoteId: QuoteId;
  disabled?: boolean;
}


export const LinkFieldsMenuItem = ({ linkedScenarioFields, quoteId, disabled = false }: Props) => {
  const dispatch = useAppDispatch();

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);


  const openMenu = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onSelectAllClicked = (allShouldBeChecked: boolean) => {
    if (allShouldBeChecked) {
      dispatch(addLinkedFields({ quoteId: quoteId, fieldsToLink: AllPossibleScenarioLinkFields.map(f => f.fieldName) }));
    } else {
      dispatch(removeAllLinkedFields(quoteId));
    }

    setIsSelectAllChecked(allShouldBeChecked);
  };

  const onFieldCheckboxClicked = (fieldName: PossibleLinkedScenarioField, shouldBeLinked: boolean) => {
    if (shouldBeLinked) {
      dispatch(addLinkedFields({ quoteId: quoteId, fieldsToLink: [fieldName] }));
    } else {
      dispatch(removeLinkedFields({ quoteId: quoteId, fieldsToRemove: [fieldName] }));
    }
  };

  const isFieldChecked = (fieldName: PossibleLinkedScenarioField): boolean => {
    return linkedScenarioFields.includes(fieldName);
  };

  return <>
    <MenuItem onClick={openMenu} disabled={disabled}>
      <Typography variant="body2" noWrap>Link Fields</Typography>
    </MenuItem>

    <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={closeMenu}
    >
      <MenuItem onClick={() => onSelectAllClicked(!isSelectAllChecked)}>
        <Checkbox checked={isSelectAllChecked} />
        <strong>Select All</strong>
      </MenuItem>

      {AllPossibleScenarioLinkFields.map(field => {
        const fieldIsChecked = isFieldChecked(field.fieldName);

        return (
          <MenuItem key={field.fieldName} onClick={() => onFieldCheckboxClicked(field.fieldName, !fieldIsChecked)}>
            <Checkbox
              checked={fieldIsChecked}
            />
            {field.label}
          </MenuItem>
        );
      })}
    </Menu>
  </>;
};