import useTime from '../../hooks/useTime';
import { Typography } from '@mui/material';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectIsLightMode } from '../../app/userSettingsSlice';

const Weather = () => {

  const { monthLong, weekday, daytime, time, day } = useTime();
  const isLightMode = useAppSelector(selectIsLightMode);

  return (
    <Typography sx={{ color: theme => isLightMode ? theme.palette.common.black : theme.palette.common.white }} border={'0pt'} variant="body1">
      Good {daytime} &nbsp;| &nbsp;{weekday}, {monthLong} {day} &nbsp;|&nbsp; {time}
    </Typography>
  );
};

export default Weather;