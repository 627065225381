import { HistoricalTYield } from '@silveus/calculations';
import { selectAllHistoricalTYields } from '../app/admSlice';
import { getKeyedStateToMap } from '../app/sliceHelpers';
import QuoteHistoricalTYields from '../types/api/adm/QuoteHistoricalTYields';
import { buildCountyAndCommodityKey } from '../types/app/CompositeKeys';
import { getItemsForId } from '../utils/mapHelpers';
import { useAppSelector } from './reduxHooks';

export function useHistoricalTYields() {
  const allHistoricalTYields = useAppSelector(selectAllHistoricalTYields);
  function forCountyCommodity(countyId: string, commodityCode: string) {
    const allHistoricalTYieldsByCountyAndCommodityMap = getKeyedStateToMap(allHistoricalTYields);
    return getCountyCommodityHistoricalTYields(allHistoricalTYieldsByCountyAndCommodityMap, countyId, commodityCode);
  }
  return forCountyCommodity;
}

export function convertToHistoricalTYieldMap(allHistoricalTYields: QuoteHistoricalTYields[]) {
  return new Map(allHistoricalTYields.map(item => [item.countyCommodityKey, item.historicalTYields]));
}

export function getCountyCommodityHistoricalTYields(historicalQuoteTYields: Map<`${string}-${string}`, HistoricalTYield[]>, countyId: string, commodityCode: string) {
  const allTYields = getItemsForId(historicalQuoteTYields, buildCountyAndCommodityKey(countyId, commodityCode));
  if (allTYields.length === 0) throw new Error(`Could not find matching historicalTYields for ${countyId} countyID and ${commodityCode} commodityCode`);

  const getTYieldsForPracticeTypePlan = (practiceId: string, typeId: string, planCode: string) => {
    return allTYields.filter(ta => ta.practiceId === practiceId && ta.typeId === typeId && ta.planCode === planCode);
  };
  return { allTYields, getTYieldsForPracticeTypePlan };
}
