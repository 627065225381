import ClientFileDiff from './clientFileDiff';
import EntityDiff from '../entityDiffing/entityDiff';
import { ReactElement } from 'react';
import { NamedReconciliationStack } from '../../../types/app/ReconciliationTrackedEntity';
import DataLocationType from '../constants/dataLocationType';

type StackType = NamedReconciliationStack<'insureds'>;
type SubEntityStackType = NamedReconciliationStack<'clientFiles'>;

interface InsuredDiffProps {
  insured: StackType;
  reconciledInsured: StackType;
  onReconciliationTypeChange: (entity: StackType) => void;
  dataLocation: DataLocationType;
}

const InsuredDiff = ({ insured, reconciledInsured, onReconciliationTypeChange, dataLocation }: InsuredDiffProps) => {
  const title = `${dataLocation === 'client' ? 'Offline' : 'Online'} Client Files`;

  const getSubEntityDiffElements = (
    subEntities: SubEntityStackType[],
    subEntitySubset: SubEntityStackType[],
    onReconciliationTypeChange: (subEntity: SubEntityStackType) => void,
  ) => {
    const elements: ReactElement[] = [];

    subEntitySubset.forEach(subsetEntity => {
      const applicableEntity = subEntities.find(subEntity => subEntity.id === subsetEntity.id);

      if (applicableEntity === undefined) return;

      elements.push(
        <ClientFileDiff
          clientFile={subsetEntity}
          reconciledClientFile={applicableEntity}
          onReconciliationTypeChange={onReconciliationTypeChange}
          dataLocation={dataLocation}
          key={subsetEntity.id}
        />,
      );
    });

    return elements;
  };

  return (
    <EntityDiff
      title={title}
      subEntitySelector={clientFile => clientFile.subCollections.clientFiles}
      detailComponents={[]}
      getSubEntityDiffElements={getSubEntityDiffElements}
      onReconciliationTypeChange={onReconciliationTypeChange}
      trackedEntity={reconciledInsured}
      subsetEntity={insured}
      preventCollapse
      dataLocation={dataLocation}
    />
  );
};

export default InsuredDiff;