import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HistoricalAnalysisId, ScenarioId } from '../types/api/PrimaryKeys';
import HistoricalAnalysis from '../types/api/historicalAnalysis';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import { RootState } from './store';
import { getKeyedStateValues, initialKeyedState, KeyedState, updateKeyedState } from './sliceHelpers';
import { StrictOmit } from '../types/util/StrictOmit';
import { HistoricalAnalysisYear } from '../types/api/historicalAnalysisYear';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import {
  createHistoricalAnalysisRequest, getHistoricalAnalysesByScenarioIdsRequest,
  updateHistoricalAnalysisRequest
} from '../services/requestInterception/historicalAnalysisRequestInterceptor';

interface HistoricalAnalysisState {
  allHistoricalAnalyses: SliceDataState<HistoricalAnalysisId, HistoricalAnalysis>;
  lastRemotelyPersistedExcludedYears: KeyedState<HistoricalAnalysisId, number[]>;
}

const initialState: HistoricalAnalysisState = {
  allHistoricalAnalyses: initialSliceDataState(),
  lastRemotelyPersistedExcludedYears: initialKeyedState(),
};

const historicalAnalysisSlice = createSlice({
  name: 'HistoricalAnalysis',
  initialState: initialState,
  reducers: {
    setLastRemotelyPersistedExcludedYears(state, action: PayloadAction<{ historicalAnalysisId: HistoricalAnalysisId, excludedYears: number[] }>) {
      updateKeyedState(state.lastRemotelyPersistedExcludedYears, action.payload.excludedYears, x => action.payload.historicalAnalysisId);
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allHistoricalAnalyses, s => s.historicalAnalysisId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchHistoricalAnalysesByScenarioIds,
      affectedIds: (_, state) => getKeyedStateValues(state.allHistoricalAnalyses.data).map(i => i.historicalAnalysisId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addHistoricalAnalysis,
      affectedIds: arg => arg.historicalAnalysis.historicalAnalysisId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyHistoricalAnalysis,
      affectedIds: arg => arg.historicalAnalysis.historicalAnalysisId,
    });
  },
});

export const { setLastRemotelyPersistedExcludedYears } = historicalAnalysisSlice.actions;

const selectHistoricalDictionary = (state: RootState) => state.historicalAnalysis.allHistoricalAnalyses.data;

export const selectAllHistoricalAnalyses = createSelector([selectHistoricalDictionary], result => {
  const historicalAnalyses = getKeyedStateValues(result);
  const map = new Map<ScenarioId, HistoricalAnalysis>();
  for (const h of historicalAnalyses) {
    map.set(h.primaryScenarioId, h);
  }

  return map;
});

export const selectLastRemotelyPersistedExcludedYearsForHistoricalAnalysis = (state: RootState, historicalAnalysisId: HistoricalAnalysisId) => state.historicalAnalysis.lastRemotelyPersistedExcludedYears[historicalAnalysisId] ?? stableEmptyArrayAsMutable<number>();

export const fetchHistoricalAnalysesByScenarioIds = createAppAsyncThunk('historical/fetchHistoricalByScenarioIds', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  const historicalAnalysis = await getHistoricalAnalysesByScenarioIdsRequest(scenarioIds);
  return historicalAnalysis;
});

export const addHistoricalAnalysis = createAppAsyncThunk('historical/addHistorical', async ({ historicalAnalysis }: { historicalAnalysis: StrictOmit<HistoricalAnalysis, 'historicalAnalysisYears'> }) => {
  const historicalAnalysisYears: HistoricalAnalysisYear[] = [];

  let startYear = historicalAnalysis.startYear;
  while (startYear <= historicalAnalysis.endYear) {
    historicalAnalysisYears.push(createHistoricalAnalysisYear(startYear, historicalAnalysis.historicalAnalysisId));
    startYear++;
  }

  const historicalAnalysisToAdd: HistoricalAnalysis = { ...historicalAnalysis, historicalAnalysisYears: historicalAnalysisYears };
  await createHistoricalAnalysisRequest(historicalAnalysisToAdd);
  return historicalAnalysisToAdd;
});

// dummy data
const createHistoricalAnalysisYear = (year: number, historicalAnalysisId: HistoricalAnalysisId) => {
  const historicalAnalysisYear: HistoricalAnalysisYear = {
    historicalAnalysisYearId: generatePrimaryKey(),
    historicalAnalysisId: historicalAnalysisId,
    actualYield: 0,
    excluded: false,
    year: year,
  };

  return historicalAnalysisYear;
};

export const modifyHistoricalAnalysis = createAppAsyncThunk('historical/modifyHistoricalAnalysis', async ({ historicalAnalysis }: { historicalAnalysis: HistoricalAnalysis }, thunkApi) => {
  const state = thunkApi.getState();
  const historicalAnalysisToUpdate = { ...historicalAnalysis };
  const historicalAnalysisYears = [...historicalAnalysisToUpdate.historicalAnalysisYears];
  const existingHistoricalAnalysis = state.historicalAnalysis.allHistoricalAnalyses.data[historicalAnalysis.historicalAnalysisId];

  if (!existingHistoricalAnalysis) throw Error('Cannot find existing historical analysis');

  // Below we need to handle changes to years by adding, if necessary, any historical analysis years that are
  // outside the existing range of years. We do not care about removing years if they already exist.
  const addOrCreateHistoricalAnalysisYear = (year: number) => {
    const existingHistoricalAnalysisYear = existingHistoricalAnalysis.historicalAnalysisYears.find(hay => hay.year === year) ?? null;
    if (existingHistoricalAnalysisYear === null) {
      historicalAnalysisYears.push(createHistoricalAnalysisYear(year, historicalAnalysis.historicalAnalysisId));
    } else {
      historicalAnalysisYears.push(existingHistoricalAnalysisYear);
    }
  };

  for (let year = historicalAnalysis.startYear; year < existingHistoricalAnalysis.startYear; year++) {
    addOrCreateHistoricalAnalysisYear(year);
  }

  for (let year = historicalAnalysis.endYear; year > existingHistoricalAnalysis.endYear; year--) {
    addOrCreateHistoricalAnalysisYear(year);
  }

  historicalAnalysisToUpdate.historicalAnalysisYears = historicalAnalysisYears;

  await updateHistoricalAnalysisRequest(historicalAnalysisToUpdate);
  return historicalAnalysisToUpdate;
});

export default historicalAnalysisSlice.reducer;
