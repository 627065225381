import { useFormContext } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectAdmLoadingStatusFetchScenarioPricesAndYields, selectOfferActualCountyYield } from '../../../app/admSlice';
import ActualCountyYieldInput, { ActualCountyYieldProps } from './actualCountyYieldInput';

import { LOADING_STATES } from '../../../constants/loadingStates';

const ScenarioActualCountyYieldInput = ({ actualCountyYield, sx, label, commodityCode, coverageTypeCode, year, countyId, typeId, practiceId, coverageLevel, volatility, insurancePlanCodes, autoFocus = false, disabled }: ActualCountyYieldProps) => {
  const { setValue, trigger } = useFormContext();
  const offerActualCountyYield = useAppSelector(selectOfferActualCountyYield);

  const pricesAndYieldsFetchStatus = useAppSelector(selectAdmLoadingStatusFetchScenarioPricesAndYields);
  const lastPractice = useRef(practiceId);

  useEffect(() => {
    if (practiceId === lastPractice.current && actualCountyYield !== null) {
      return;
    }

    if (pricesAndYieldsFetchStatus === LOADING_STATES.Loading) {
      setValue('actualCountyYield', 0);
    } else if (pricesAndYieldsFetchStatus === LOADING_STATES.Succeeded || actualCountyYield === null) {
      lastPractice.current = practiceId;
      setValue('actualCountyYield', offerActualCountyYield);
      trigger('actualCountyYield');
    }
  }, [pricesAndYieldsFetchStatus, practiceId, offerActualCountyYield, actualCountyYield]);

  return (
    <ActualCountyYieldInput
      actualCountyYield={actualCountyYield}
      year={year}
      countyId={countyId}
      typeId={typeId}
      practiceId={practiceId}
      volatility={volatility}
      coverageLevel={coverageLevel}
      sx={sx}
      commodityCode={commodityCode}
      label={label}
      autoFocus={autoFocus}
      disabled={disabled}
      coverageTypeCode={coverageTypeCode}
      insurancePlanCodes={insurancePlanCodes} />
  );
};

export default ScenarioActualCountyYieldInput;
