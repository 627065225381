import { createSelector, createSlice } from '@reduxjs/toolkit';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { AgentCodeId, InsuredId } from '../types/api/PrimaryKeys';
import { AgentCode } from '../types/api/agentCode';
import { RootState } from './store';
import { getKeyedStateGroupedBy } from './sliceHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAgentCodes } from '../services/appTasks.service';
import { isNotNullOrUndefined } from '../utils/nullHandling';

interface AgentCodeState {
  allAgentCodes: SliceDataState<AgentCodeId, AgentCode>;
}

const initialState: AgentCodeState = {
  allAgentCodes: initialSliceDataState(),
};

export const AIPSlice = createSlice({
  name: 'agentCodes',
  initialState: initialState,
  reducers: {
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allAgentCodes, s => s.agentCodeId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchAgentCodes,
      affectedIds: () => [],
    });
  },
});

export default AIPSlice.reducer;

const selectAllAgentCodesDictionary = (state: RootState) => state.agentCodes.allAgentCodes.data;

export const selectAgentCodes = createSelector([selectAllAgentCodesDictionary], result => {
  const map = getKeyedStateGroupedBy(result, ac => ac.agentTeamId);
  return map;
});

export const fetchAgentCodes = createAppAsyncThunk('agentCodes/fetchAgentCodes', async (insuredId: InsuredId, thunkApi) => {
  const state = thunkApi.getState();
  const insured = state.insureds.allInsureds.data[insuredId];
  if (isNotNullOrUndefined(insured) && isNotNullOrUndefined(insured.agentTeamId)) {
    const agentCodes = (await getAgentCodes(insured.agentTeamId)).data;
    return agentCodes;
  }
  return [];
});

