import { useAppSelector } from './reduxHooks';
import { isNullOrUndefined } from '../utils/nullHandling';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import HistoricalPrice from '../types/api/adm/HistoricalPrice';
import { selectHistoricalPrices, selectMpHistoricalPrices } from '../app/admSlice';
import { useMemo } from 'react';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { selectScenarioById } from '../app/scenariosSlice';
import { selectQuoteById } from '../app/quotesSlice';
import { ScenarioId } from '../types/api/PrimaryKeys';
import { Nullable } from '../types/util/Nullable';

export const useHistoricalPrices = (scenarioId: Nullable<ScenarioId>, startYear: number, endYear: number) => {
  const scenario = useAppSelector(state => scenarioId === null ? null : selectScenarioById(state, scenarioId));
  const quote = useAppSelector(state => scenario === null ? null : selectQuoteById(state, scenario.quoteId));
  const isScenarioOrQuoteInvalid = isNullOrUndefined(scenario) || isNullOrUndefined(quote);

  const nonMpPriceData = useAppSelector(state => isScenarioOrQuoteInvalid || isNullOrUndefined(scenario.practiceId) ?
    stableEmptyArrayAsMutable<HistoricalPrice>() :
    selectHistoricalPrices(state, quote.countyId, quote.commodityCode, scenario.typeId, scenario.practiceId, startYear, endYear));
  const mpPriceData = useAppSelector(state => isScenarioOrQuoteInvalid || isNullOrUndefined(scenario.practiceId)
    ? stableEmptyArrayAsMutable<HistoricalPrice>()
    : selectMpHistoricalPrices(state, quote.countyId, quote.commodityCode, scenario.typeId, scenario.practiceId, startYear, endYear));

  const priceData = useMemo(() => {
    const years: number[] = [];

    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }

    const combinedPriceData: HistoricalPrice[] = [];

    for (const year of years) {
      const nonMpPrice = nonMpPriceData.find(price => price.priceYear === year);
      const mpPrice = mpPriceData.find(price => price.priceYear === year);

      if (nonMpPrice === undefined && mpPrice === undefined) continue;

      const newHistoricalPrice: HistoricalPrice = {
        basePrice: nonMpPrice?.basePrice ?? null,
        harvestPrice: nonMpPrice?.harvestPrice ?? mpPrice?.harvestPrice ?? null,
        mpBasePrice: mpPrice?.basePrice ?? null,
        priceGroupID: nonMpPrice?.priceGroupID ?? mpPrice?.priceGroupID ?? generatePrimaryKey(),
        priceHistoryID: nonMpPrice?.priceHistoryID ?? mpPrice?.priceHistoryID ?? 0,
        priceYear: year,
      };

      combinedPriceData.push(newHistoricalPrice);
    }

    return combinedPriceData.length > 0 ? combinedPriceData : stableEmptyArrayAsMutable<HistoricalPrice>();
  }, [nonMpPriceData, mpPriceData]);

  return priceData;
};