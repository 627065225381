import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import {
  AvailabilityRestrictionType,
  AvailabilityService,
  cap,
  RestrictionMessageMap,
  ScenarioPieceGroupTypeAttributes,
  ScenarioPieceType
} from '@silveus/calculations';
import { getHipLowerCoverageLevel } from '../../../pages/scenarioPiece/hip/utils/getHipLowerCoverageLevel';
import { CombinedValidationResult, ValidationResult } from '../validateScenario';
import { getInsurancePlanCodeForScenarioPiece } from '../../scenarioPieceUtils';

export type RowCropScenarioPieceValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[]) => ValidationResult<RowCropScenarioPiece>;
export type RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece, doubleUnderlyingPiece?: RowCropScenarioPiece) => ValidationResult<RowCropScenarioPiece>;

export const missingUnderlyingScenarioPiece: CombinedValidationResult<RowCropScenarioPiece> = { propertiesToUpdate: { isInvalid: true }, validationErrors: ['Required underlying scenario piece is missing'] };

//Create validation function to take in base type and find the other grouped scenario pieces, look up their dependencies, compare with the underlying to know what we need to convert it to
//Update the broken scenario piece to be the new scenario piece type
export const dependentScenarioPieceShouldMatchUnderlyingScenarioPieceType: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece, doubleUnderlyingPiece?: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  return makeUnderlyingScenarioPieceAndPlanCodeFunction(false)(pieceToValidate, allScenarioPieces, underlyingPiece, doubleUnderlyingPiece);
};

export const dependentScenarioPieceShouldMatchUnderlyingScenarioPieceTypeAndPlanCode: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece, doubleUnderlyingPiece?: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  return makeUnderlyingScenarioPieceAndPlanCodeFunction(true)(pieceToValidate, allScenarioPieces, underlyingPiece, doubleUnderlyingPiece);
};

const makeUnderlyingScenarioPieceAndPlanCodeFunction = (updatePlanCode: boolean): RowCropScenarioPieceUnderlyingValidationRule => {
  const validationFunc: RowCropScenarioPieceUnderlyingValidationRule =
    (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
      const dependentScenarioPieceTypes = AvailabilityService.getScenarioPiecesThatThisScenarioPieceImmediatelyDependsOn(pieceToValidate.scenarioPieceType);
      const isUnderlyingADependentOfPieceToValidate = dependentScenarioPieceTypes.some(spt => spt === underlyingPiece.scenarioPieceType);

      const scenarioPieceGroup = Object.values(ScenarioPieceGroupTypeAttributes).find(spg => spg.elements?.includes(pieceToValidate.scenarioPieceType));

      if (scenarioPieceGroup !== undefined && scenarioPieceGroup.elements !== undefined) {

        if (!scenarioPieceGroup.automaticSwitchBetweenGroupMembers) {
          //If we don't need to automatically switch and the underlying scenario piece type is a valid one, don't do anything else.
          if (isUnderlyingADependentOfPieceToValidate){
            return {
              propertiesToUpdate: null,
              validationError: null,
            };
          }

          //If we don't automatically switch and the underlying is not a dependent then we have issues.
          return {
            propertiesToUpdate: { isInvalid: true },
            validationError: RestrictionMessageMap.get(AvailabilityRestrictionType.Incompatible) ?? '',
          };
        }

        for (const scenarioPieceGroupMemberType of scenarioPieceGroup.elements) {
          //Loop over the members of the group and figure out which one depends on the underlying piece that we can use to switch to
          const dependentScenarioPieceTypes = AvailabilityService.getScenarioPiecesThatThisScenarioPieceImmediatelyDependsOn(scenarioPieceGroupMemberType);
          if (dependentScenarioPieceTypes.includes(underlyingPiece.scenarioPieceType)) {
            if (updatePlanCode) {
              const planCode = getInsurancePlanCodeForScenarioPiece(scenarioPieceGroupMemberType);
              return {
                propertiesToUpdate: { scenarioPieceType: scenarioPieceGroupMemberType, planCode: planCode },
                validationError: null,
              };
            } else {
              return {
                propertiesToUpdate: { scenarioPieceType: scenarioPieceGroupMemberType },
                validationError: null,
              };
            }
          }
        }
      } else {
        return {
          propertiesToUpdate: null,
          validationError: null,
        };
      }

      return {
        propertiesToUpdate: null,
        validationError: null,
      };
    };
  return validationFunc;
};

export const lowerCoverageShouldBeEqualToOrGreaterThanUnderlyingUpperCoverage: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  const propertiesToUpdate = underlyingPiece.upperCoverageLevel > pieceToValidate.lowerCoverageLevel
    ? { lowerCoverageLevel: underlyingPiece.upperCoverageLevel }
    : null;
  return {
    propertiesToUpdate,
    validationError: null,
  };
};

export const lowerCoverageShouldMatchUnderlyingUpperCoverage: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  const propertiesToUpdate = pieceToValidate.lowerCoverageLevel !== underlyingPiece.upperCoverageLevel ? { lowerCoverageLevel: underlyingPiece.upperCoverageLevel } : null;
  return {
    propertiesToUpdate,
    validationError: null,
  };
};

export const lowerCoverageShouldMatchUnderlyingLowerCoverage: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  const propertiesToUpdate = pieceToValidate.lowerCoverageLevel !== underlyingPiece.lowerCoverageLevel ? { lowerCoverageLevel: underlyingPiece.lowerCoverageLevel } : null;
  return {
    propertiesToUpdate,
    validationError: null,
  };
};

export const upperCoverageShouldMatchUnderlyingUpperCoverage: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  const propertiesToUpdate = pieceToValidate.upperCoverageLevel !== underlyingPiece.upperCoverageLevel ? { upperCoverageLevel: underlyingPiece.upperCoverageLevel } : null;
  return {
    propertiesToUpdate,
    validationError: null,
  };
};

export const harvestPriceOptionShouldMatchUnderlying: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  let propertiesToUpdate;
  if (underlyingPiece.scenarioPieceType === ScenarioPieceType.MpHpo)
    propertiesToUpdate = pieceToValidate.scenarioPieceType !== ScenarioPieceType.VipMpHpo ? { scenarioPieceType: ScenarioPieceType.VipMpHpo } : null;
  else
    propertiesToUpdate = pieceToValidate.scenarioPieceType !== ScenarioPieceType.VipMp ? { scenarioPieceType: ScenarioPieceType.VipMp } : null;
  return {
    propertiesToUpdate,
    validationError: null,
  };
};

export const plusProductProtectionFactorShouldNotBeGreaterThanCap: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  const protectionFactorCap = cap(underlyingPiece.protectionFactor, 90);
  return protectionFactorShouldNotBeGreaterThanCap(pieceToValidate, underlyingPiece, protectionFactorCap);
};

export const plusProductsShouldBeExclusivePerScenario: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  const plusPieces = [ScenarioPieceType.EcoPlusRevenue, ScenarioPieceType.EcoPlusYield, ScenarioPieceType.ScoPlusRevenue, ScenarioPieceType.ScoPlusYield];
  const scenarioPlusPieces = allScenarioPieces.filter(piece => plusPieces.includes(piece.scenarioPieceType));
  const isInvalid = scenarioPlusPieces.length > 1;
  const propertiesToUpdate = isInvalid ? { isInvalid: true } : null;
  const validationError = isInvalid ? 'SCO+ and ECO+ can\'t be selected simultaneously, please delete one of them.' : null;

  return {
    propertiesToUpdate,
    validationError,
  };
};

export const protectionFactorShouldNotBeGreaterThanCap = (pieceToValidate: RowCropScenarioPiece, underlyingPiece: RowCropScenarioPiece, protectionFactorUpperBound: number): ValidationResult<RowCropScenarioPiece> => {
  const isInvalid = pieceToValidate.protectionFactor > protectionFactorUpperBound;
  const propertiesToUpdate = isInvalid ? { isInvalid: true } : null;
  const validationError = isInvalid ? `Protection factor can't be greater than the cap of ${protectionFactorUpperBound}` : null;

  return {
    propertiesToUpdate,
    validationError,
  };
};

export const productCannotBeRevenueWithUnderlyingYieldBasedCoverage: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  const incompatibleRevenueTypes = [ScenarioPieceType.ScoPlusRevenue, ScenarioPieceType.EcoPlusRevenue];
  const incompatibleUnderlyingTypes = [ScenarioPieceType.ScoYp, ScenarioPieceType.EcoYp];
  const isInvalid = incompatibleUnderlyingTypes.includes(underlyingPiece.scenarioPieceType) && incompatibleRevenueTypes.includes(pieceToValidate.scenarioPieceType);
  const propertiesToUpdate = isInvalid ? { isInvalid: true } : null;
  const validationError = isInvalid ? 'Revenue plan is not compatible with underlying yield plan' : null;
  return {
    propertiesToUpdate,
    validationError,
  };
};

export const coverageLevelShouldBeOneOf = (validCoverageLevels: number[]): RowCropScenarioPieceUnderlyingValidationRule => {
  return (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece, doubleUnderlyingPiece?: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
    if (doubleUnderlyingPiece === undefined) {
      return {
        propertiesToUpdate: { isInvalid: true },
        validationError: 'Base underlying scenario piece not found',
      };
    }
    const isInvalid = !validCoverageLevels.includes(doubleUnderlyingPiece.upperCoverageLevel);
    const propertiesToUpdate = isInvalid ? { isInvalid: true } : null;
    const validationError = isInvalid ? 'Underlying coverage level is invalid' : null;
    return {
      propertiesToUpdate,
      validationError,
    };
  };
};

export const hipLowerCoverageShouldMatchSpecifiedCoverage: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  const applicableLowerCoverage = getHipLowerCoverageLevel(allScenarioPieces, underlyingPiece);
  return lowerCoverageShouldMatchSpecifiedCoverage(pieceToValidate, applicableLowerCoverage);
};

export const lowerCoverageShouldMatchSpecifiedCoverage = (pieceToValidate: RowCropScenarioPiece, applicableLowerCoverage: number): ValidationResult<RowCropScenarioPiece> => {
  const propertiesToUpdate = pieceToValidate.lowerCoverageLevel !== applicableLowerCoverage ? { lowerCoverageLevel: applicableLowerCoverage } : null;
  return {
    propertiesToUpdate,
    validationError: null,
  };
};

export const liveQuoteShouldNotBeStale = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[]): ValidationResult<RowCropScenarioPiece> => {
  const isLiveQuoteStale = pieceToValidate.rowCropScenarioPieceExtendedData?.isLiveQuoteStale ?? false;
  const propertiesToUpdate = isLiveQuoteStale ? { isInvalid: true } : null;

  const validationError = isLiveQuoteStale ? 'Your live quote is out of date. Please refresh your live quote.' : null;

  return {
    propertiesToUpdate,
    validationError,
  };
};

export const underlyingPieceShouldNotBeCAT: RowCropScenarioPieceUnderlyingValidationRule = (pieceToValidate: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[], underlyingPiece: RowCropScenarioPiece): ValidationResult<RowCropScenarioPiece> => {
  const isInvalid = underlyingPiece.rowCropScenarioPieceExtendedData?.isCat ?? false;
  const propertiesToUpdate = isInvalid ? { isInvalid: true } : null;
  const validationError = isInvalid ? 'Product cannot be elected with CAT' : null;
  return {
    propertiesToUpdate,
    validationError,
  };
};
