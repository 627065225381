import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { MatrixColorSelectionUi } from './MatrixColorSelectionUi';
import { useCallChangeEventHandler } from './hooks/useCallChangeEventHandler';
import { useOrderedColorList } from './hooks/useOrderedColorList';
import { MatrixCurrentPaletteSidebar } from './MatrixCurrentPaletteSidebar';
import { CellTextColorRule } from '@silveus/react-matrix';
import { EditableInputStyles } from 'react-color/lib/components/common/EditableInput';
import { createUiColorRuleFromRule } from './utils/createUiColorRuleFromRule';
import { OnColorListChangeHandler } from './types';
import { MatrixColorSample } from './MatrixColorSample';

interface Props {
  /* This event will fire every single time any color or threshold changes. */
  onColorListChanged: OnColorListChangeHandler;

  /* If provided, this value will be used as the starting point for the color list on component mount only. */
  defaultColorList?: CellTextColorRule[];
  cssClasses?: undefined | {
    colorValueCardCSSClasses: string;
    iconClasses: string;
    labelClasses: string;
  };
  customColorPickerStyles?: EditableInputStyles;
}

export const MatrixColorPicker = ({ onColorListChanged, defaultColorList, cssClasses, customColorPickerStyles }: Props) => {

  // This is the master color list used by this entire component tree.
  const [colorList, setColorList] = useOrderedColorList(defaultColorList);
  const [selectedColorId, setSelectedColorId] = useState<number | null>(null);

  useCallChangeEventHandler(colorList, onColorListChanged);

  const getColorRuleIndexById = (id: number) => {
    const foundIndex = colorList.findIndex(c => c.id === id);
    return foundIndex === -1 ? null : foundIndex;
  };

  const addNewColor = (colorRule: CellTextColorRule) => {
    const newColorRule = createUiColorRuleFromRule(colorRule);
    setColorList([...colorList, newColorRule]);
  };

  const updateColor = (id: number, newValue: CellTextColorRule) => {
    const index = getColorRuleIndexById(id);
    if (index === null) { return; }

    const newList = [...colorList];
    newList[index] = {
      ...newValue,
      id: id,
    };

    setColorList(newList);
  };

  const deleteColor = (id: number) => {
    const index = getColorRuleIndexById(id);
    if (index === null) { return; }

    const newList = [...colorList];
    newList.splice(index, 1);
    setColorList(newList);
  };

  const selectedColorIndex = selectedColorId === null ? null : getColorRuleIndexById(selectedColorId);
  const selectedColorRule = selectedColorIndex === null ? null : colorList[selectedColorIndex];

  return (
    <Grid container sx={{ flexWrap: 'nowrap' }} rowSpacing={2} columnSpacing={3} className="color-picker-body">
      <Grid item xs={3.5}>
        <MatrixCurrentPaletteSidebar
          colorList={colorList}
          selectedColorRule={selectedColorRule}
          cssClasses={cssClasses}
          onAddColor={addNewColor}
          onSetSelectedColorId={id => setSelectedColorId(id)}
          onUpdateColor={updateColor}
          onDeleteColor={deleteColor}
        />
      </Grid>
      <Grid item container xs={8.5} spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="body2" className={cssClasses?.labelClasses}>Select Custom Color</Typography>
        </Grid>
        <Grid item xs="auto">
          <Typography variant="body2" className={cssClasses?.labelClasses}>Selected Color</Typography>
        </Grid>
        <Grid item xs="auto">
          <MatrixColorSample color={selectedColorRule?.color ?? ''} />
        </Grid>
        <Grid item xs={12}>
          <MatrixColorSelectionUi
            selectedColorRule={selectedColorRule}
            customColorPickerStyles={customColorPickerStyles}
            onUpdateColor={updateColor}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};