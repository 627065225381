import axios from 'axios';
import { ClientFileId, QuoteId, ScenarioId } from '../types/api/PrimaryKeys';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { RowCropScenario } from '../types/api/RowCropScenario';
import { envBaseApiUrl } from '../constants/envConstants';

export const getRowCropScenarios = (quoteId: QuoteId) => axios.get<RowCropScenario[]>(`${envBaseApiUrl}/row-crop-scenarios/quotes/${quoteId}/`);

export const getRowCropScenariosForClientFile = (clientFileId: ClientFileId) => axios.get<RowCropScenario[]>(`${envBaseApiUrl}/row-crop-scenarios/client-files/${clientFileId}/`);

export const getRowCropScenario = (scenarioId: ScenarioId) => axios.get<RowCropScenario>(`${envBaseApiUrl}/row-crop-scenarios/${scenarioId}/`);

export const createRowCropScenario = (scenario: RowCropScenario) => axios.post<CreatedItemResult<ScenarioId>>(`${envBaseApiUrl}/row-crop-scenarios/`, scenario);

export const updateRowCropScenario = (scenario: RowCropScenario) => axios.put<void>(`${envBaseApiUrl}/row-crop-scenarios`, scenario);

export const updateRowCropScenarios = (scenarios: readonly RowCropScenario[]) => axios.put<void>(`${envBaseApiUrl}/row-crop-scenarios/batch`, scenarios);

export const deleteRowCropScenario = (scenarioId: ScenarioId) => axios.delete<void>(`${envBaseApiUrl}/row-crop-scenarios/${scenarioId}/`);