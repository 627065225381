import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';

export enum FarmDescriptor {
  FarmName = 0,
  TractNumber = 1,
}

export const FarmNameTractNumberInput = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name="farmNameTractNumber"
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
      }}
      defaultValue={FarmDescriptor.FarmName}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl fullWidth error={!!error}>
          <RadioGroup
            {...field}
            row
            value={value}
            onChange={e => field.onChange(JSON.parse(e.target.value))}
          >
            <FormControlLabel value={FarmDescriptor.FarmName} checked={value === FarmDescriptor.FarmName} control={<Radio />} label="Farm Name" />
            <FormControlLabel value={FarmDescriptor.TractNumber} checked={value === FarmDescriptor.TractNumber} control={<Radio />} label="Tract #" />
          </RadioGroup>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};