import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import {
  coverageLevelShouldBeOneOf,
  dependentScenarioPieceShouldMatchUnderlyingScenarioPieceType,
  lowerCoverageShouldMatchUnderlyingLowerCoverage,
  missingUnderlyingScenarioPiece,
  plusProductProtectionFactorShouldNotBeGreaterThanCap,
  plusProductsShouldBeExclusivePerScenario,
  productCannotBeRevenueWithUnderlyingYieldBasedCoverage,
  RowCropScenarioPieceUnderlyingValidationRule,
  upperCoverageShouldMatchUnderlyingUpperCoverage
} from '../validationRules/rowCropScenarioPieceValidationRules';
import { composeValidationResults } from '../validationHandling';
import { CombinedValidationResult } from '../validateScenario';
import { Nullable } from '../../../types/util/Nullable';
import { Scenario } from '../../../types/api/Scenario';
import { Quote } from '../../../types/api/Quote';
import { Commodity } from '@silveus/calculations';

export function validateScoPlus(scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: Nullable<RowCropScenarioPiece>, allScenarioPieces: RowCropScenarioPiece[], doubleUnderlyingScenarioPiece: Nullable<RowCropScenarioPiece>, scenario: Scenario, quote: Quote): CombinedValidationResult<RowCropScenarioPiece> {
  if (underlyingScenarioPiece === null || doubleUnderlyingScenarioPiece === null) { return missingUnderlyingScenarioPiece; }
  const validCoverageLevels = quote.commodityCode === Commodity.Wheat ? [70, 75, 80] : [75, 80];

  const validationRules: RowCropScenarioPieceUnderlyingValidationRule[] = [
    dependentScenarioPieceShouldMatchUnderlyingScenarioPieceType,
    upperCoverageShouldMatchUnderlyingUpperCoverage,
    lowerCoverageShouldMatchUnderlyingLowerCoverage,
    plusProductProtectionFactorShouldNotBeGreaterThanCap,
    plusProductsShouldBeExclusivePerScenario,
    productCannotBeRevenueWithUnderlyingYieldBasedCoverage,
    coverageLevelShouldBeOneOf(validCoverageLevels),
  ];

  const validationResults = validationRules.map(validationRule => validationRule(scenarioPiece, allScenarioPieces, underlyingScenarioPiece, doubleUnderlyingScenarioPiece));
  return composeValidationResults(validationResults);
}