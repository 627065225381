import { selectYeYearsForCountyCommodity } from '../../app/admSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { selectScenarioPieceForOfferData } from '../../app/scenarioPiecesSlice';
import { selectScenarioById } from '../../app/scenariosSlice';
import { selectQuoteById } from '../../app/quotesSlice';
import UnitYearAph from '../../types/api/UnitYearAph';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import { useYieldCalculator } from './useYieldCalculator';
import UnitYear from '../../types/api/UnitYear';
import { OptionCode } from '@silveus/calculations';
import {
  MissingQuoteInStateError, MissingScenarioInStateError, MissingUnderlyingScenarioPieceInStateError
} from '../../errors/state/MissingStateErrors';
import { YeYear } from '../../types/api/adm/YeYears';

export const useScenarioYieldCalculator = (scenarioId: ScenarioId) => {
  const scenarioPiece = useAppSelector(state => selectScenarioPieceForOfferData(state, scenarioId));
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  if (scenario === null) throw new MissingScenarioInStateError(scenarioId);
  const quote = useAppSelector(state => selectQuoteById(state, scenario.quoteId));
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);
  const yieldCalculator = useYieldCalculator();
  const yeYears = useAppSelector(state => selectYeYearsForCountyCommodity(state, quote.countyId, quote.commodityCode));

  return {
    forSingleUnitYear: (unitYear: UnitYear, unitYearAph: readonly UnitYearAph[],
      scenarioUnitYearAph: readonly ScenarioUnitYearAph[], selectedOptionCodes: OptionCode[]) => {
      return yieldCalculator.forSingleUnitYear(quote, unitYear, scenarioPiece?.planCode ?? null, unitYearAph,
        scenarioUnitYearAph, selectedOptionCodes, yeYears);
    },

    forMultipleUnitYears: (unitYearMap: Map<UnitYear, {
      unitYearAph: UnitYearAph[], scenarioUnitYearAph: ScenarioUnitYearAph[], optionCodes: OptionCode[],
      yeYears: YeYear[]
    }>) => {
      if (scenarioPiece === null) throw new MissingUnderlyingScenarioPieceInStateError(scenario.scenarioId);
      return yieldCalculator.forMultipleUnitYears(quote, scenarioPiece.planCode, unitYearMap);
    },
  };
};

