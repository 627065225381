import { Info } from '../../info/info';
import { ReactNode, useMemo } from 'react';

interface PriceInputTooltipProps {
  priceHintLines: string[]
}

export const PriceInputTooltip = ({ priceHintLines }: PriceInputTooltipProps) => {
  const priceHintItem = useMemo(() => {
    const priceHintItems: (ReactNode)[] = [];
    for (const [index, item] of priceHintLines.entries()) {
      priceHintItems.push(item);
      if (index < priceHintLines.length - 1) {
        priceHintItems.push(<br key={index} />);
      }
    }
    return priceHintItems;
  }, [priceHintLines]);

  return (
    <Info tooltipContent={<span>{priceHintItem}</span>} />
  );
};