import { ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material';

interface Props {
  index: number;
  value: number;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

export function TabPanel(props: Props) {
  const { children, value, index } = props;

  return (
    <>
      {value === index &&
        children
      }
    </>
  );
}