import QuoteDiff from './quoteDiff';
import EntityDiff from '../entityDiffing/entityDiff';
import { ReactElement } from 'react';
import DataLocationType from '../constants/dataLocationType';
import { NamedReconciliationStack } from '../../../types/app/ReconciliationTrackedEntity';

type StackType = NamedReconciliationStack<'clientFiles'>;
type SubEntityStackType = NamedReconciliationStack<'quotes'>;

interface ClientFileDiffProps {
  clientFile: StackType;
  reconciledClientFile: StackType;
  onReconciliationTypeChange: (entity: StackType) => void;
  dataLocation: DataLocationType;
}

const ClientFileDiff = ({ clientFile, reconciledClientFile, onReconciliationTypeChange, dataLocation }: ClientFileDiffProps) => {
  const detailComponents = [
    clientFile.entity.name,
    clientFile.entity.description,
  ];

  const getSubEntityDiffElements = (
    subEntities: SubEntityStackType[],
    subEntitySubset: SubEntityStackType[],
    onReconciliationTypeChange: (subEntity: SubEntityStackType) => void,
  ) => {
    const elements: ReactElement[] = [];

    subEntitySubset.forEach(subsetEntity => {
      const applicableEntity = subEntities.find(subEntity => subEntity.id === subsetEntity.id);

      if (applicableEntity === undefined) return;

      elements.push(
        <QuoteDiff
          quote={subsetEntity}
          reconciledQuote={applicableEntity}
          onReconciliationTypeChange={onReconciliationTypeChange}
          dataLocation={dataLocation}
          key={subsetEntity.id}
        />,
      );
    });

    return elements;
  };

  return (
    <EntityDiff
      title={clientFile.entity.name}
      subtitle={clientFile.entity.year.toString()}
      trackedEntity={reconciledClientFile}
      subsetEntity={clientFile}
      detailComponents={detailComponents}
      getSubEntityDiffElements={getSubEntityDiffElements}
      onReconciliationTypeChange={onReconciliationTypeChange}
      subEntitySelector={clientFile => clientFile.subCollections.quotes}
      dataLocation={dataLocation}
    />
  );
};

export default ClientFileDiff;