import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './scenarioCard.styles.css';
import { Nullable } from '../../../types/util/Nullable';
import { format } from 'date-fns';
import InsuranceCalendar from '../../../types/api/adm/InsuranceCalendar';
import React from 'react';
import LabelValuePair from '../../../components/labelValuePair/labelValuePair.component';
import { ToggleableTypography } from '../../../components/toggleableTypography/toggleableTypography';

interface ImportantDatesDetailsProps {
  insuranceCalendar: InsuranceCalendar | undefined;
  isReadOnly: boolean;
}

const ImportantDatesDetails = ({ insuranceCalendar, isReadOnly }: ImportantDatesDetailsProps) => {

  const wrapGridElement = (child: React.ReactElement) => {
    return (
      <Grid item xs={12}>
        {child}
      </Grid>
    );
  };

  const lineItems: React.ReactElement[] = [];

  const addDate = (label: string, date: Nullable<string>) => {
    if (date === null) {
      return;
    }

    lineItems.push(wrapGridElement(<LabelValuePair
      disabled={isReadOnly}
      disableToolTip={true}
      labelColumnSize={8}
      valueColumnSize={4}
      label={label}
      value={format(new Date(date), 'MM/dd/yyyy')} />));
  };


  if (insuranceCalendar === undefined) {
    lineItems.push(wrapGridElement(<Typography fontWeight="bold" variant="body2">Please select an insurance plan to view important dates.</Typography>));
  } else {

    addDate('Sales Closing Date:', insuranceCalendar.salesClosingDate);
    addDate('Earliest Planting Date:', insuranceCalendar.earliestPlantingDate);
    addDate('Final Planting Date:', insuranceCalendar.finalPlantingDate);
    addDate('End of Late Planting Period Date:', insuranceCalendar.endofLatePlantingPeriodDate);
    addDate('Acreage Reporting Date:', insuranceCalendar.acreageReportingDate);
    addDate('Production Reporting Date:', insuranceCalendar.productionReportingDate);
    addDate('Premium Billing Date:', insuranceCalendar.premiumBillingDate);
  }

  return (
    <Accordion disableGutters variant="elevation" square elevation={0} sx={{ backgroundColor: 'transparent', '&:before': { display: 'none' } }}>
      <AccordionSummary className="price-yield-details-accordion-summary" expandIcon={<ExpandMoreIcon sx={{ color: theme => theme.palette.action.selected }} />} sx={{ pl: 1 }}>
        <ToggleableTypography disabled={isReadOnly} variant="clickable2">Important Dates</ToggleableTypography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {lineItems}
        </Grid>
      </AccordionDetails>
    </Accordion>);
};
export default ImportantDatesDetails;
