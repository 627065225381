import { Grid } from '@mui/material';
import PercentChangeSliderInput from '../percentChangeSliderInput.component';
import { Nullable } from '../../../types/util/Nullable';
import { ApprovedYieldFields, ApprovedYieldInput } from './approvedYieldInput.component';
import ProducerYieldInput, { ProducerYieldFields } from './producerYieldInput.component';
import { Quote } from '../../../types/api/Quote';
import RateYieldInput, { RateYieldFields } from './rateYieldInput.component';
import AphInput, { AphFields } from './aphInput.component';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import useLinkedScenarioFields from '../../../hooks/useLinkedScenarioFields';

interface Props {
  quote: Quote;
  scenarioId: Nullable<ScenarioId>;
  approvedYield: Nullable<number>;
  producerYield: Nullable<number>;
  rateYield: Nullable<number>;
  aphYield: Nullable<number>;
}

export type IndividualYieldInputGroupFields = ApprovedYieldFields & ProducerYieldFields & RateYieldFields & AphFields;

const IndividualYieldInputGroup = ({ quote, scenarioId, approvedYield, producerYield, rateYield, aphYield }: Props) => {
  const { getInputStyle } = useLinkedScenarioFields(scenarioId);
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={4}>
        <ApprovedYieldInput
          quote={quote}
          scenarioId={scenarioId}
          approvedYield={approvedYield}
          disabled={!quote.quickQuote}
          sx={getInputStyle('quickUnit.approvedYield')} />
      </Grid>
      <Grid item xs={4}>
        <ProducerYieldInput producerYield={producerYield} approvedYield={approvedYield} sx={getInputStyle('actualProducerYield')} />
      </Grid>
      <Grid item xs={4}>
        <PercentChangeSliderInput
          estimatedValueFieldName={'approvedYield'}
          actualValueFieldName={'producerYield'}
          sliderName={'approvedYieldSlider'}
          initialEstimatedValue={approvedYield}
          initialActualValue={producerYield}
        />
      </Grid>
      <Grid item xs={4}>
        <RateYieldInput
          quote={quote}
          scenarioId={scenarioId}
          rateYield={rateYield}
          disabled={!quote.quickQuote}
          allowZero={!quote.quickQuote}
          sx={getInputStyle('quickUnit.rateYield')} />
      </Grid>
      <Grid item xs={4}>
        <AphInput
          quote={quote}
          scenarioId={scenarioId}
          aphYield={aphYield}
          disabled={!quote.quickQuote}
          allowZero={!quote.quickQuote}
          sx={getInputStyle('quickUnit.aphYield')} />
      </Grid>
    </Grid>
  );
};


export default IndividualYieldInputGroup;