import { ScenarioPieceType, ScenarioPieceTypeAttributes, ProductType, ScenarioPieceGroupType } from '@silveus/calculations';
import { toPrimaryKey } from '../../utils/primaryKeyHelpers';
import { getDistinctOrderedScenarioPieceTypes } from '../../utils/scenarioPieceUtils';
import { MatrixPresetId } from '../api/PrimaryKeys';
import { MatrixPresetType } from '../api/enums/matrixPresetType.enum';
import MatrixPreset from '../api/MatrixPreset';

// these guids are arbitrary; they are only hard coded to ensure that the IDs that are used to reference these
// system presets are consistent, but currently are not referenced anywhere else.
const profitPresetId = toPrimaryKey<MatrixPresetId>('36adad5e-154c-43ec-80e5-747bdc1609a4');
const physicalPresetId = toPrimaryKey<MatrixPresetId>('c566eacb-e875-4628-bbc3-6f5bb68c3a7e');
const insurancePresetId = toPrimaryKey<MatrixPresetId>('68e83f0f-09d1-4e40-b41b-425d40b6b621');
const privateProductsPresetId = toPrimaryKey<MatrixPresetId>('e7e9e00f-fdaa-4e3a-b0b4-8423bf2e6f8a');
const scoPresetId = toPrimaryKey<MatrixPresetId>('579cee29-0f75-4e57-8eda-18d00bfcb1fe');
const ecoPresetId = toPrimaryKey<MatrixPresetId>('8366cf98-0a17-4c85-b02f-e39a06dc4f63');
const farmPresetId = toPrimaryKey<MatrixPresetId>('36a20d28-1fe9-4acd-9d7a-5c6571261d2a');

export const profitPreset: MatrixPreset = {
  matrixPresetId: profitPresetId,
  name: 'Profit',
  matrixPresetType: MatrixPresetType.System,
  selectedScenarioPieceTypes: getDistinctOrderedScenarioPieceTypes(),
  isDefault: true,
};

const physicalPreset: MatrixPreset = {
  matrixPresetId: physicalPresetId,
  name: 'Physical Only',
  matrixPresetType: MatrixPresetType.System,
  selectedScenarioPieceTypes: [ScenarioPieceType.InputCosts, ScenarioPieceType.ForwardSold, ScenarioPieceType.HarvestRevenue],
};

export const insurancePreset: MatrixPreset = {
  matrixPresetId: insurancePresetId,
  name: 'Insurance',
  matrixPresetType: MatrixPresetType.System,
  selectedScenarioPieceTypes: getDistinctOrderedScenarioPieceTypes(Object.values(ScenarioPieceTypeAttributes).filter(spaAttr => spaAttr.productType === ProductType.Federal || spaAttr.productType === ProductType.Private)),
};

const privateProductPreset: MatrixPreset = {
  matrixPresetId: privateProductsPresetId,
  name: 'Private Products',
  matrixPresetType: MatrixPresetType.System,
  selectedScenarioPieceTypes: getDistinctOrderedScenarioPieceTypes(Object.values(ScenarioPieceTypeAttributes).filter(spaAttr => spaAttr.productType === ProductType.Private)),
};

const farmBillPreset: MatrixPreset = {
  matrixPresetId: farmPresetId,
  name: 'FarmBill',
  matrixPresetType: MatrixPresetType.System,
  selectedScenarioPieceTypes: [ScenarioPieceGroupType.FarmBill],
};

const scoPreset: MatrixPreset = {
  matrixPresetId: scoPresetId,
  name: 'SCO',
  matrixPresetType: MatrixPresetType.System,
  selectedScenarioPieceTypes: [ScenarioPieceGroupType.Sco],
};

const ecoPreset: MatrixPreset = {
  matrixPresetId: ecoPresetId,
  name: 'ECO',
  matrixPresetType: MatrixPresetType.System,
  selectedScenarioPieceTypes: [ScenarioPieceGroupType.Eco],
};

export const getDefaultMatrixPreset = (): MatrixPreset => SystemMatrixPresets.find(smp => smp.isDefault) ?? profitPreset;

export const SystemMatrixPresets: MatrixPreset[] = [
  profitPreset,
  physicalPreset,
  insurancePreset,
  privateProductPreset,
  farmBillPreset,
  scoPreset,
  ecoPreset,
];