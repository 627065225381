import { HistoricalIncludeType } from '../../../types/api/enums/historicalAnalysis/historicalIncludeType.enum';
import { roundToPlaces } from '@silveus/calculations';
import { HistoricalShowType } from '../../../types/api/enums/historicalAnalysis/historicalShowType.enum';

export const getValueToUseForHistoricalQuoteResult = (indemnityPerAcre: number, indemnityTotal: number, premiumPerAcre: number,
  premiumTotal: number, includeType: HistoricalIncludeType, showType: HistoricalShowType) => {

  const indemnityToShow = getShowTypeValueForHistoricalQuote(indemnityPerAcre, indemnityTotal, showType);
  const premiumToShow = getShowTypeValueForHistoricalQuote(premiumPerAcre, premiumTotal, showType);

  return includeType === HistoricalIncludeType.Net
    ? roundToPlaces(indemnityToShow - premiumToShow, 2)
    : indemnityToShow;
};

const getShowTypeValueForHistoricalQuote = (perAcreValue: number, totalValue: number, showType: HistoricalShowType) : number => {
  return showType === HistoricalShowType.PerAcre
    ? perAcreValue
    : totalValue;
};
