import { ScenarioPiece } from '../../../types/api/ScenarioPiece';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { isRowCropScenarioPiece } from '../../../utils/castingUtils';
import { OptionCode } from '@silveus/calculations';
import { formatCurrency } from '../../../utils/formatNumbers';
import OptionState from '../../../types/app/OptionState';
import { ReactElement, ReactNode } from 'react';
import { Theme, Tooltip } from '@mui/material';
import { OptionAvailabilityWarning } from '../../optionAvailability';

export type AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece, selectedOptions: OptionState[], theme: Theme) => string | null | ReactElement;
export type ScenarioPieceCardChipContent = ReactNode;
export const scenarioPieceCardChipDefault = [];
export const buildScenarioPieceCardChips = (scenarioPieceChipFunctions: AddScenarioPieceCardChipFunction[], scenarioPiece: ScenarioPiece | RowCropScenarioPiece, selectedOptions: OptionState[], theme: Theme): ScenarioPieceCardChipContent[] => {
  const scenarioPieceChips: ScenarioPieceCardChipContent[] = [];
  for (const scenarioPieceChipFunction of scenarioPieceChipFunctions) {
    const scenarioPieceChip = scenarioPieceChipFunction(scenarioPiece, selectedOptions, theme);
    if (scenarioPieceChip !== null) {
      scenarioPieceChips.push(scenarioPieceChip);
    }
  }
  return scenarioPieceChips;
};

export const CatScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
  if (isRowCropScenarioPiece(scenarioPiece)) {
    const isCat = scenarioPiece.rowCropScenarioPieceExtendedData?.isCat ?? false;
    return isCat ? 'CAT' : null;
  }
  return null;
};

export const ProtectionFactorScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
  if (isRowCropScenarioPiece(scenarioPiece)) {
    return `PF ${scenarioPiece.protectionFactor}`;
  }
  return null;
};

export const LiveQuotingScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
  if (isRowCropScenarioPiece(scenarioPiece)) {
    if (scenarioPiece.rowCropScenarioPieceExtendedData?.isLiveQuote === true) {
      if (scenarioPiece.rowCropScenarioPieceExtendedData.getDiscountRate) {
        return 'Live with discount';
      }
      return 'Live';
    }
  }
  return null;
};

const availableOptionsByEu = [OptionCode.EC, OptionCode.EI, OptionCode.ET];

export const OptionsScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece, selectedOptions: OptionState[], theme) => {
  const selectedOptionsNotEu = selectedOptions.filter(so => so.option.optionCode !== OptionCode.TS && !availableOptionsByEu.some(ao => ao === so.option.optionCode));
  if (selectedOptionsNotEu.length > 0) {
    return (
      <Tooltip title={selectedOptionsNotEu.some(o => !o.isAvailable) && OptionAvailabilityWarning}>
        <div>
          {selectedOptionsNotEu.map(o => {
            const style = !o.isAvailable ? { color: theme.palette.warning.main } : {};
            return (
              <span key={o.option.optionCode} style={style} className="option-chip">
                {`${o.option.optionCode} `}
              </span>
            );
          })}
        </div>
      </Tooltip>
    );
  }
  return null;
};

export const EuByScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece, selectedOptions: OptionState[]) => {
  //We aren't able to use the available options/data driven approach to getting the option names because we don't store availableOptions across scenarios/quotes currently
  const selectedOptionsByEU = selectedOptions.filter(so => availableOptionsByEu.includes(so.option.optionCode));
  if (selectedOptionsByEU.length > 0) {
    let optionName = '';
    switch (selectedOptionsByEU[0].option.optionCode){
      case OptionCode.EC:
        optionName = 'Cropping Practice';
        break;
      case OptionCode.EI:
        optionName = 'Irrigation Practice';
        break;
      case OptionCode.ET:
        optionName = 'Type';
        break;
      default:
        return null;
    }
    return `EU BY ${optionName}`;
  }
  return null;
};

export const TopicalStormScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece, selectedOptions: OptionState[]) => {
  const isTropicalStormEnabled = selectedOptions.some(o => o.option.optionCode === OptionCode.TS);
  if (isTropicalStormEnabled) {
    return OptionCode.TS;
  }
  return null;
};

export const CapScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
  if (isRowCropScenarioPiece(scenarioPiece)) {
    if (scenarioPiece.rowCropScenarioPieceExtendedData?.priceChangeCap !== undefined){
      return `Cap ${formatCurrency(scenarioPiece.rowCropScenarioPieceExtendedData.priceChangeCap)}`;
    }
  }
  return null;
};

export const TopIntervalScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
  if (isRowCropScenarioPiece(scenarioPiece)) {
    if (scenarioPiece.rowCropScenarioPieceExtendedData?.topPrice !== undefined){
      return `Top Interval ${formatCurrency(scenarioPiece.rowCropScenarioPieceExtendedData.topPrice)}`;
    }
  }
  return null;
};
