import { Dialog, DialogContent, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { AphModalProps, selectIsUnitAphModalOpen, toggleUnitAphModal } from '../../app/unitsSlice';
import 'react-resizable/css/styles.css';
import { UnitAphGrid } from './unitAphGrid';
import { UnitSummary } from './unitSummary';
import { useState } from 'react';
import UnitYearAph from '../../types/api/UnitYearAph';
import { validateAph } from '../units/utils/aph';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import { ScenarioId, UnitYearAphId } from '../../types/api/PrimaryKeys';
import { generatePrimaryKey } from '../../utils/primaryKeyHelpers';
import { YeStatusType } from '../../types/api/enums/optionStates/yeStatusType.enum';
import { MuiDialogCloseReason } from '../../types/mui/MuiDialogCloseReason';
import ScenarioModalHeader from '../../components/modal/scenarioModalHeader';
import UnitsAphModalHeaderControls from './unitsAphModalHeaderControls';

interface Props {
  modalProps: AphModalProps;
  setFormDirty: (isDirty: boolean) => void;
}

const UnitAphModal = ({ modalProps, setFormDirty }: Props) => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectIsUnitAphModalOpen);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const unitYear = modalProps.unitYear;
  const combinedInitialAph = combineAph(modalProps.initialUnitYearAph, modalProps.initialScenarioUnitYearAph, modalProps.scenarioId);

  const [aph, setAphDirectUseState] = useState(combinedInitialAph);

  const setAph = (newValue: CombinedAphScenarioAph[]) => {
    setFormDirty(true);
    setAphDirectUseState(newValue);
  };

  const handleClose = (reason?: MuiDialogCloseReason) => {
    if (reason === 'backdropClick') {
      return;
    }

    handleSave();
  };

  const handleSave = () => {
    const isValid = validateAph(aph);
    if (!isValid) return;

    modalProps.setUnitYearAph(aph);
    modalProps.setScenarioUnitYearAph(aph);

    dispatch(toggleUnitAphModal(null));
  };

  const disabled = !validateAph(aph);
  const headerControls = <UnitsAphModalHeaderControls onClose={handleClose} disabled={disabled} />;

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => handleClose(reason)}
      maxWidth={false}
      scroll="body"
      disableEscapeKeyDown={true}
      fullScreen
      sx={{ m: 2 }}
    >
      <form id="unitAph-modal-form" style={{ height: '100%', width: '100%' }}>
        <ScenarioModalHeader scenarioId={modalProps.scenarioId} headerText={`Unit ${unitYear.basicUnitNumber}-${unitYear.optionalUnitNumber} - History`} headerControls={headerControls} />
        <DialogContent sx={{ height: 'calc(100% - 77.5px)', mt: 1 }}>
          <Grid container direction="column" sx={{ height: '100%' }} rowGap={2}>
            <Grid item xs="auto">
              <UnitSummary aph={aph} setAph={setAph} isInEditMode={isInEditMode} modalProps={modalProps} />
            </Grid>
            <Grid item xs>
              <UnitAphGrid setIsInEditMode={setIsInEditMode} aph={aph} setAph={setAph} modalProps={modalProps} />
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export type CombinedAphScenarioAph = UnitYearAph & ScenarioUnitYearAph

function createNewScenarioAphRow(scenarioId: ScenarioId, unitYearAphId: UnitYearAphId): ScenarioUnitYearAph {
  return {
    scenarioUnitYearAphId: generatePrimaryKey(),
    scenarioId: scenarioId,
    unitYearAphId: unitYearAphId,
    yeStatus: YeStatusType.NotOptedOut,
    offlineCreatedOn: undefined,
    offlineLastUpdatedOn: undefined,
    offlineDeletedOn: undefined,
  };
}

function combineAph(unitYearAph: UnitYearAph[], scenarioUnitYearAph: ScenarioUnitYearAph[], scenarioId: ScenarioId) {
  const combinedAph: CombinedAphScenarioAph[] = unitYearAph.map(row => {
    const scenarioAphRow = scenarioUnitYearAph.find(sRow => sRow.unitYearAphId === row.unitYearAphId) ?? createNewScenarioAphRow(scenarioId, row.unitYearAphId);
    return {
      ...scenarioAphRow,
      ...row,
    };
  });
  return combinedAph;
}

export default UnitAphModal;
