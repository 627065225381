import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  addScenarioFromForm,
  addScenarioQuickUnit,
  fetchAdmDataForScenarios,
  modifyScenarioOrder,
  modifyUnitYearsForScenario,
  selectScenarioById,
  setCurrentlySelectedScenarioId,
  updateScenario
} from '../../../app/scenariosSlice';
import { SubmitHandler, FormProvider, useForm, useWatch } from 'react-hook-form';
import { Button, Box, Grid, Typography, Tooltip } from '@mui/material';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import NameInput, { NameFields } from '../../../components/formInputs/nameInput.component';
import PracticeInput, { PracticeFields } from '../../../components/formInputs/practiceInput.component';
import AcresInput, { AcresFields } from '../../../components/formInputs/acresInput.component';
import CommodityTypeInput, { CommodityTypeFields } from '../../../components/formInputs/commodityTypeInput.component';
import SharePercentInput, { SharePercentFields } from '../../../components/formInputs/sharePercentInput.component';
import AphInput, { AphFields } from '../../../components/formInputs/scenario/aphInput.component';
import { HarvestPriceFields } from '../../../components/formInputs/scenario/harvestPriceInput.component';
import ProducerYieldInput, { ProducerYieldFields } from '../../../components/formInputs/scenario/producerYieldInput.component';
import RateYieldInput, { RateYieldFields } from '../../../components/formInputs/scenario/rateYieldInput.component';
import VolatilityInput, { VolatilityFields } from '../../../components/formInputs/scenario/volatilityInput.component';
import { ProjectedPriceFields } from '../../../components/formInputs/scenario/projectedPriceInput.component';
import { QuoteId, ScenarioId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import { selectIsLightMode } from '../../../app/userSettingsSlice';


import PercentChangeSliderInput from '../../../components/formInputs/percentChangeSliderInput.component';
import { selectQuoteById } from '../../../app/quotesSlice';
import { ActualCountyYieldFields } from '../../../components/formInputs/scenario/actualCountyYieldInput';
import { ExpectedCountyYieldFields } from '../../../components/formInputs/scenario/expectedCountyYieldInput';
import { updateOpenDrawer } from '../../../app/appDrawerSlice';
import { useEffect, useMemo, useState } from 'react';
import { updateCalculationForScenario, updateCalculationForScenarios } from '../../../app/calculationResultsSlice';
import { fetchAllScenarioPricesAndYieldsPriorYear, fetchCountyYieldInfo, fetchInsuranceCalendars, selectAdmLoadingStatusFetchScenarioPricesAndYields, selectCommodityTypesWithOffers, selectOffer, selectOfferActualCountyYield, selectOfferExpectedCountyYield, selectPracticesWithOffers } from '../../../app/admSlice';
import { isEmpty, isNullOrUndefined } from '../../../utils/nullHandling';
import {
  ApprovedYieldInput,
  ApprovedYieldFields
} from '../../../components/formInputs/scenario/approvedYieldInput.component';
import {
  selectAdjustedYieldForTypePractice,
  selectRateYieldForTypePractice, selectAverageSharePercentForTypePractice,
  selectApprovedYieldForTypePractice,
  selectAcresForTypePractice
} from '../../../app/unitsSlice';
import { ColorPickerDialog } from '../../../components/color-picker/colorPicker.component';
import useFormWrapper from '../../../hooks/useFormWrapper';
import useDrawerForm from '../../../hooks/useDrawerForm';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { fetchScenarioPricesAndYields } from '../../../app/admSlice';
import {
  modifyMpScenarioPiecePriceAndInputCosts,
  modifyScenarioPiece,
  selectRowCropScenarioPiecePlanCodes,
  selectRowCropScenarioPiecesByScenarioIdAndTypes
} from '../../../app/scenarioPiecesSlice';
import HighRiskInput, { HighRiskFields } from '../../../components/formInputs/highRiskInput.component';
import { HighRiskType, ScenarioPieceType } from '@silveus/calculations';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import ScenarioProjectedPriceInput from '../../../components/formInputs/scenario/scenarioProjectedPriceInput';
import ScenarioHarvestPriceInput from '../../../components/formInputs/scenario/scenarioHarvestPriceInput';
import ScenarioExpectedCountyYieldInput from '../../../components/formInputs/scenario/scenarioExpectedCountyYieldInput';
import ScenarioActualCountyYieldInput from '../../../components/formInputs/scenario/scenarioActualCountyYieldInput';
import ScenarioMpProjectedPriceInput from '../../../components/formInputs/scenario/scenarioMpProjectedPriceInput.component';
import { ScenarioMpInputCostsInputGroup } from '../../../components/formInputs/scenario/scenarioMpInputCostsInputGroup.component';
import ScenarioQuickUnit from '../../../types/api/ScenarioQuickUnit';
import { RowCropScenarioPieceExtendedFormFields } from '../../scenarioPiece/rowCropScenarioPieceExtendedFormFields.component';
import { getDiscoveryAwareStyling } from '../../../components/numberTextField/numberTextField.styles';
import { PriceDiscoveryPeriod } from '../../../utils/discoveryPeriodUtils';
import { selectDiscoveryPeriod } from '../../../app/rmaPriceDiscoverySelectors';
import { getItemsForId } from '../../../utils/mapHelpers';
import { selectAllScenarioPiecesByScenarioMap } from '../../../app/sharedSelectors';
import { LOADING_STATES } from '../../../constants/loadingStates';
import FarmBillPriceInputGroup from '../../../components/formInputs/scenarioPiece/farmBill/farmBillPriceInputGroup';
import FarmBillBenchMarkYieldInputGroup from '../../../components/formInputs/scenarioPiece/farmBill/farmBillBenchMarkYieldInputGroup';
import FarmBillYieldInput from '../../../components/formInputs/scenarioPiece/farmBill/farmBillYieldInput';
import useLinkedScenarioFields from '../../../hooks/useLinkedScenarioFields';
import { useOptions } from '../../../hooks/useOptions';
import { useUnitsForUnsavedScenario } from '../../../hooks/useUnitsForUnsavedScenario';
import { setScenarioOptionsWithOptionState } from '../../../app/optionsSlice';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';

export interface ScenarioFormProps extends FormWrapperProps {
  scenarioId: Nullable<ScenarioId>;
  quoteId: QuoteId;
}

type ScenarioColorFields = {
  scenarioColor: string;
}

type FarmBillOverrideFormFields = {
  farmBillProjectedPrice: Nullable<number>;
  farmBillActualPrice: Nullable<number>;
  farmBillProjectedYield: Nullable<number>;
  farmBillActualYield: Nullable<number>;
}

export type ScenarioFormFields = NameFields &
  PracticeFields & AcresFields &
  HighRiskFields &
  SharePercentFields & CommodityTypeFields &
  AphFields & HarvestPriceFields &
  ProjectedPriceFields & ProducerYieldFields &
  RateYieldFields & VolatilityFields &
  ExpectedCountyYieldFields & ActualCountyYieldFields &
  ApprovedYieldFields & ScenarioColorFields;

type CombinedScenarioFormFields = ScenarioFormFields &
  RowCropScenarioPieceExtendedFormFields &
  FarmBillOverrideFormFields;

const ScenarioForm = ({ scenarioId, quoteId, registerHeader, handleValidation, isCanceling = false }: ScenarioFormProps) => {
  const dispatch = useAppDispatch();
  const scenario = useAppSelector(state => scenarioId === null ? null : selectScenarioById(state, scenarioId));
  const scenarioHasScenarioPieces = useAppSelector(state => {
    const scenarioPiecesByScenario = selectAllScenarioPiecesByScenarioMap(state);
    const scenarioPieces = scenarioId === null ? [] : getItemsForId(scenarioPiecesByScenario, scenarioId);
    return scenarioPieces.length > 0;
  });

  const mpScenarioPiece = useAppSelector(state => scenarioId === null ? null : selectRowCropScenarioPiecesByScenarioIdAndTypes(state, scenarioId, [ScenarioPieceType.MpHpo, ScenarioPieceType.MP]));
  const farmBillScenarioPiece = useAppSelector(state => scenarioId === null ? null : selectRowCropScenarioPiecesByScenarioIdAndTypes(state, scenarioId, [ScenarioPieceType.Arc, ScenarioPieceType.Plc]));
  const areMpFieldsDisabled = (mpScenarioPiece?.rowCropScenarioPieceExtendedData?.areFieldsLocked ?? true) || (mpScenarioPiece?.rowCropScenarioPieceExtendedData?.isLiveQuote ?? true);

  const quote = useAppSelector(s => isNullOrUndefined(quoteId) ? null : selectQuoteById(s, quoteId));
  const clientFile = useAppSelector(s => isNullOrUndefined(quote) ? null : selectClientFileById(s, quote.clientFileId));
  const methods = useForm<CombinedScenarioFormFields>();
  const typeIdWatch = useWatch({ name: 'typeId', control: methods.control, defaultValue: scenario?.typeId });
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const typeId = typeIdWatch ?? null;
  const practiceId = useWatch({ name: 'practiceId', control: methods.control, defaultValue: scenario?.practiceId ?? '' });
  const currentOffer = useAppSelector(selectOffer);
  const offerExpectedCountyYield = useAppSelector(selectOfferExpectedCountyYield);
  const offerActualCountyYield = useAppSelector(selectOfferActualCountyYield);

  const pricesAndYieldsFetchStatus = useAppSelector(selectAdmLoadingStatusFetchScenarioPricesAndYields);
  const hasCountyProducts = (offerExpectedCountyYield !== null && offerActualCountyYield !== null) || pricesAndYieldsFetchStatus === LOADING_STATES.Loading;
  const { getOptionStatesForScenarioProps, carryOverOptionStateForScenarioChange } = useOptions(quoteId, scenarioId);
  const { getUnitYearsForUnsavedScenario } = useUnitsForUnsavedScenario(quoteId);
  const currentDate = new Date();

  const { projectedDiscoveryPeriod, harvestDiscoveryPeriod } = useAppSelector(state =>
    clientFile !== null && quote !== null && typeId !== '' && practiceId !== ''
      ? {
        projectedDiscoveryPeriod: selectDiscoveryPeriod(state, currentDate, false, clientFile.year, quote.countyId, scenario?.scenarioId ?? null, quote.commodityCode, typeIdWatch, practiceId),
        harvestDiscoveryPeriod: selectDiscoveryPeriod(state, currentDate, true, clientFile.year, quote.countyId, scenario?.scenarioId ?? null, quote.commodityCode, typeId, practiceId),
      }
      :
      {
        projectedDiscoveryPeriod: PriceDiscoveryPeriod.PreDiscovery,
        harvestDiscoveryPeriod: PriceDiscoveryPeriod.PreDiscovery,
      },
  );

  const commodityTypes = useAppSelector(selectCommodityTypesWithOffers);
  const practices = useAppSelector(selectPracticesWithOffers);

  const highRiskTypeId = useWatch({ name: 'highRiskTypeId', control: methods.control, defaultValue: scenario?.highRiskTypeId ?? HighRiskType.AllLand });

  const scenarioOptionState = useMemo(() => {
    const newUnitYearsForScenario = getUnitYearsForUnsavedScenario(typeIdWatch, practiceId, highRiskTypeId);
    //Because we don't need availability, we can just pass in an empty Map
    return getOptionStatesForScenarioProps(typeIdWatch, practiceId, new Map<string, RowCropScenarioPiece>(), newUnitYearsForScenario);
  }, [typeIdWatch, practiceId, highRiskTypeId]);

  const acres = useAppSelector(state => selectAcresForTypePractice(state, scenarioId, quoteId, typeId, practiceId, highRiskTypeId));
  const sharePercent = useAppSelector(state => selectAverageSharePercentForTypePractice(state, scenarioId, quoteId));
  const rateYield = useAppSelector(state => selectRateYieldForTypePractice(state, scenarioId, quoteId, typeId, practiceId, highRiskTypeId));
  const aphYield = useAppSelector(state => selectAdjustedYieldForTypePractice(state, scenarioId, quoteId, typeId, practiceId, highRiskTypeId, quote?.countyId ?? '', quote?.commodityCode ?? ''));
  const approvedYield = useAppSelector(state => selectApprovedYieldForTypePractice(state, scenarioId, quoteId, typeId, practiceId, highRiskTypeId, quote?.countyId ?? '', quote?.commodityCode ?? ''));

  const isLightMode = useAppSelector(selectIsLightMode);

  const { linkedScenarioData, getInputStyle } = useLinkedScenarioFields(scenarioId);

  const [scenarioColorPickerOpen, setScenarioColorPickerOpen] = useState(false);
  const [unitShare, setUnitShare] = useState<Nullable<number>>(null);
  const initialScenarioColor = scenario?.scenarioColor;
  const [selectedScenarioColor, setSelectedScenarioColor] = useState(initialScenarioColor);
  const scenarioPlanCodes = useAppSelector(state => selectRowCropScenarioPiecePlanCodes(state, scenarioId));

  const formId = 'scenarioForm';

  useEffect(() => {
    if (quote?.quickQuote ?? false) {
      setUnitShare(null);
    } else {
      setUnitShare(sharePercent);
    }
  }, [sharePercent, quote, quote?.quickQuote]);

  const isFarmBillDataDirty = (formData: CombinedScenarioFormFields) => {
    if (farmBillScenarioPiece === null) {
      return false;
    }

    const farmBillExtendedData = farmBillScenarioPiece.rowCropScenarioPieceExtendedData;

    if (farmBillExtendedData === null) {
      return false;
    }

    if (
      farmBillExtendedData.projectedPrice !== formData.farmBillProjectedPrice ||
      farmBillExtendedData.actualPrice !== formData.farmBillActualPrice ||
      farmBillExtendedData.actualYield !== formData.farmBillProjectedYield) {
      return true;
    }

    if (farmBillScenarioPiece.scenarioPieceType === ScenarioPieceType.Arc) {
      if (farmBillExtendedData.expectedYield !== formData.farmBillActualYield) {
        return true;
      }
    }

    return false;
  };

  const onSubmit: SubmitHandler<CombinedScenarioFormFields> = async data => {
    if (quote === null) return;

    // Null would be if something went wrong.
    let operationScenarioId: ScenarioId | null;

    const { rowCropScenarioPieceExtendedData, ...scenarioData } = data;

    if (scenario === null) {
      //New scenario
      const result = await dispatch(addScenarioFromForm({ scenarioFormFields: scenarioData, quoteId: quoteId }));

      if (addScenarioFromForm.fulfilled.match(result)) {
        const addedScenario = result.payload;

        operationScenarioId = addedScenario.scenarioId;

        if (quote.quickQuote) {
          await dispatch(addScenarioQuickUnit({ scenarioFormFields: scenarioData, quote, scenarioId: addedScenario.scenarioId }));
        }

        await dispatch(fetchAdmDataForScenarios({ scenarioIds: [addedScenario.scenarioId], functionToDispatch: fetchInsuranceCalendars }));

        dispatch(updateOpenDrawer({ formName: 'scenarioForm', scenarioId: addedScenario.scenarioId, quoteId: addedScenario.quoteId }));
      } else {
        operationScenarioId = null;
      }

    } else {
      //Existing scenario
      operationScenarioId = scenario.scenarioId;

      const quickUnit: Nullable<ScenarioQuickUnit> = scenario.quickUnit !== null
        ? {
          ...scenario.quickUnit,
          acres: data.acres,
          sharePercent: data.sharePercent,
          aphYield: data.aphYield,
          rateYield: data.rateYield,
          approvedYield: data.approvedYield,
          typeId: data.typeId,
          practiceId: data.practiceId,
        }
        : null;

      const newScenario: RowCropScenario = {
        ...scenarioData,
        scenarioId: scenario.scenarioId,
        rowCropScenarioId: scenario.rowCropScenarioId,
        quoteId: scenario.quoteId,
        quickUnit: quickUnit,
        actualProducerYield: data.producerYield,
        scenarioOptions: scenario.scenarioOptions,
        isVisible: scenario.isVisible,
        isFinalized: scenario.isFinalized,
        offlineCreatedOn: scenario.offlineCreatedOn,
        offlineLastUpdatedOn: scenario.offlineLastUpdatedOn,
        offlineDeletedOn: scenario.offlineDeletedOn,
        sortIndex: scenario.sortIndex,
      };

      await dispatch(updateScenario({
        updatedScenario: newScenario,
      }));

      if (scenario.typeId !== data.typeId || scenario.practiceId !== data.practiceId || scenario.highRiskTypeId !== data.highRiskTypeId) {
        //If we are doing unit quoting, we want to update the unit groups only if the type or practice of the scenario changed or high risk type
        if (!quote.quickQuote){
          await dispatch(modifyUnitYearsForScenario({ scenarioId: scenario.scenarioId }));
        }
        //If the type, practice or high risk type changes we need to update option selections regardless if unit quoting or not
        const newUnitYearsForScenario = getUnitYearsForUnsavedScenario(data.typeId, data.practiceId, data.highRiskTypeId);
        //Passing in a null scenario piece because currently you can't have scenario pieces while changing one of these values.
        const { newOptionStateForScenario, newOptionStateForScenarioUnits } = carryOverOptionStateForScenarioChange(data.typeId, data.practiceId, newUnitYearsForScenario);
        await dispatch(setScenarioOptionsWithOptionState({ scenarioId: scenario.scenarioId, scenarioOptionState: newOptionStateForScenario, scenarioOptionUnitYearState: newOptionStateForScenarioUnits }));
      }

      await dispatch(modifyMpScenarioPiecePriceAndInputCosts({ mpScenarioPiece, rowCropScenarioPieceExtendedData }));

      if (farmBillScenarioPiece !== null && isFarmBillDataDirty(data)) {

        const farmBillExtendedData = farmBillScenarioPiece.rowCropScenarioPieceExtendedData;

        const newFarmBillScenarioPiece = {
          ...farmBillScenarioPiece,
          rowCropScenarioPieceExtendedData:
            farmBillExtendedData !== null ?
              {
                ...farmBillExtendedData,
                projectedPrice: data.farmBillProjectedPrice ?? farmBillExtendedData.projectedPrice,
                actualPrice: data.farmBillActualPrice ?? farmBillExtendedData.projectedPrice,
                expectedYield: data.farmBillProjectedYield ?? farmBillExtendedData.projectedPrice,
                actualYield: data.farmBillActualYield ?? farmBillExtendedData.projectedPrice,
              }
              : null,
        };

        await dispatch(modifyScenarioPiece({ scenarioPiece: newFarmBillScenarioPiece }));
      }
    }

    const promisesToWaitFor: Promise<unknown>[] = [];

    if (operationScenarioId !== null) {
      // After a Scenario creation or edit, spawn off a task to update calculations for the whole scenario.
      promisesToWaitFor.push(dispatch(updateCalculationForScenario({ scenarioId: operationScenarioId })));
      dispatch(setCurrentlySelectedScenarioId(operationScenarioId));
      if (linkedScenarioData?.linkedScenarioIds) {
        promisesToWaitFor.push(dispatch(updateCalculationForScenarios({ scenarioIds: linkedScenarioData.linkedScenarioIds.filter(s => s !== operationScenarioId) })));
      }
      // Update/get any potentially missing county yield data for the prior year (for historical data)
      promisesToWaitFor.push(dispatch(fetchAllScenarioPricesAndYieldsPriorYear({ scenarioIds: [operationScenarioId] })));
      promisesToWaitFor.push(dispatch(fetchCountyYieldInfo({ scenarioIds: [operationScenarioId] })));
    }

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario, trigger an auto scenario order change, so that we can verify that system works.
    promisesToWaitFor.push(dispatch(modifyScenarioOrder({ quoteId })));

    await Promise.all(promisesToWaitFor);
  };
  const handleCancel = () => {

    dispatch(setCurrentlySelectedScenarioId(null));
  };

  const shouldSubmit = methods.formState.isDirty;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  useEffect(() => {
    if (methods.formState.isDirty && !(methods.formState.dirtyFields.name ?? false)) {
      methods.setValue('name', formatName());
    }
  }, [typeIdWatch, practiceId]);

  useEffect(() => {
    //If the type/practice changes, the units used in this scenario will change
    //we then need to update the info such as acres, share %, and yields

    if (acres !== null)
      methods.setValue('acres', acres);

    if (sharePercent !== null)
      methods.setValue('sharePercent', sharePercent);

    if (aphYield !== null)
      methods.setValue('aphYield', aphYield);

    if (rateYield !== null)
      methods.setValue('rateYield', rateYield);

    if (approvedYield !== null)
      methods.setValue('approvedYield', approvedYield);

    if (selectedScenarioColor !== undefined)
      //We set the value here because we need to manually mark the field as dirty upon change
      //This is because we don't have a registered input control that is tied to this field
      methods.setValue('scenarioColor', selectedScenarioColor, { shouldDirty: initialScenarioColor !== selectedScenarioColor });
  }, [acres, sharePercent, aphYield, rateYield, approvedYield, selectedScenarioColor]);

  const formatName = () => {
    let commodity = '', practice = '';
    if (typeId) {
      const cType = commodityTypes.find(cr => cr.typeId === typeId);
      if (cType) {
        commodity = (cType.name.length > 12 ? cType.abbrev : cType.name) ?? '';
      }
    }
    if (practiceId) {
      const prac = practices.find(p => p.practiceId === practiceId);
      if (prac) {
        practice = (prac.name.length > 12 ? prac.abbrev : prac.name) ?? '';
      }
    }

    return typeId && practiceId ? `Scenario: ${commodity} - ${practice}` : '';
  };

  const handleReset = () => {
    methods.reset();
  };

  const openColorPicker = () => {
    setScenarioColorPickerOpen(true);
  };

  const closeColorPicker = () => {
    setScenarioColorPickerOpen(false);
  };

  const handleScenarioColorChange = (color: string) => {
    setSelectedScenarioColor(color);
  };

  useEffect(() => {
    fetchPricesAndYields(typeId, practiceId);
  }, [typeId, practiceId]);

  const fetchPricesAndYields = async (typeId: string | undefined, practiceId: string | undefined) => {
    if (clientFile === null
      || quote === null
      || isNullOrUndefined(typeId)
      || isNullOrUndefined(practiceId)
      || isEmpty(typeId)
      || isEmpty(practiceId)
    ) {
      return undefined;
    }

    const year = clientFile.year;
    const countyId = quote.countyId;

    return await dispatch(fetchScenarioPricesAndYields({ year: year, countyId: countyId, typeId: typeId, practiceId: practiceId, planCodes: scenarioPlanCodes }));
  };

  const handleRefreshPrices = async () => {
    if (isNullOrUndefined(currentOffer)) {
      return;
    }

    methods.setValue('projectedPrice', currentOffer.projectedPrice ?? 0, { shouldDirty: true });
    methods.setValue('harvestPrice', currentOffer.harvestPrice ?? currentOffer.projectedPrice ?? 0, { shouldDirty: true });
    methods.setValue('volatility', currentOffer.priceVolatilityFactor ?? 0, { shouldDirty: true });
  };

  const handleRefreshYields = async () => {
    if (isNullOrUndefined(currentOffer)) {
      return;
    }

    if (quote?.quickQuote === true) {
      const resetYield = currentOffer.transitionalYield ?? 0;

      methods.setValue('aphYield', resetYield, { shouldDirty: true });
      methods.setValue('rateYield', resetYield, { shouldDirty: true });
      methods.setValue('approvedYield', resetYield, { shouldDirty: true });
      methods.setValue('producerYield', resetYield, { shouldDirty: true });
    } else {
      methods.setValue('producerYield', approvedYield ?? 0, { shouldDirty: true });
    }

    methods.setValue('expectedCountyYield', currentOffer.expectedIndexValue ?? 0, { shouldDirty: true });
    methods.setValue('actualCountyYield', currentOffer.finalIndexValue ?? 0, { shouldDirty: true });
  };

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit} onReset={handleReset}>
        {clientFile !== null && quote !== null &&
          <Grid container rowSpacing={4} p={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={.5}>
                  <Box borderRadius="4px" sx={{ cursor: 'pointer', backgroundColor: selectedScenarioColor, border: theme => `1px solid ${theme.palette.primary.main}`, height: '20px', width: '20px', marginLeft: '0px', marginTop: '0px' }} onClick={openColorPicker}>
                    {/* This button ensures the Full Box area is clickable. */}
                    <Button id="btn-scenario-color" disableRipple sx={{ height: '20px', width: '20px', minWidth: '20px', lineHeight: '0' }}></Button>
                  </Box>
                </Grid>
                <Grid item xs={11.5} zeroMinWidth>
                  <Tooltip title={scenario?.name} placement="top">
                    <Typography noWrap paddingLeft={'5px'} paddingTop={'0px'} marginTop={'0px'} sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: theme => isLightMode ? theme.palette.common.black : theme.palette.common.white }} fontWeight="bold" fontSize={'14px'}>{scenario?.name}</Typography>
                  </Tooltip>
                </Grid>
                <ColorPickerDialog defaultColor={selectedScenarioColor} handleClose={closeColorPicker} open={scenarioColorPickerOpen} handleApply={handleScenarioColorChange} />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <CommodityTypeInput
                    typeId={scenario?.typeId ?? null}
                    year={clientFile.year}
                    commodityCode={quote.commodityCode}
                    countyId={quote.countyId}
                    autoFocus
                    disabled={scenarioHasScenarioPieces}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PracticeInput
                    scenarioId={scenario?.scenarioId ?? null}
                    practiceId={scenario?.practiceId ?? null}
                    year={clientFile.year}
                    commodityCode={quote.commodityCode}
                    countyId={quote.countyId}
                    typeId={scenario?.typeId ?? null}
                    disabled={scenarioHasScenarioPieces}
                  />
                </Grid>
                <Grid item xs={4}>
                  <HighRiskInput
                    quickQuote={quote.quickQuote}
                    highRiskTypeId={scenario?.highRiskTypeId ?? null}
                    practiceId={scenario?.practiceId ?? null}
                    year={clientFile.year}
                    commodityCode={quote.commodityCode}
                    countyId={quote.countyId}
                    typeId={scenario?.typeId ?? null}
                    disabled={scenarioHasScenarioPieces}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NameInput name={scenario?.name ?? ''} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={4} >
                  <ScenarioProjectedPriceInput
                    projectedPrice={scenario?.projectedPrice ?? null}
                    sx={{
                      ...getInputStyle('projectedPrice'),
                      ...getDiscoveryAwareStyling(isLightMode, projectedDiscoveryPeriod),
                    }}
                    quote={quote}
                    scenario={scenario}
                    scenarioOptionState={scenarioOptionState}
                  />
                </Grid>
                <Grid item xs={4} >
                  <ScenarioHarvestPriceInput
                    harvestPrice={scenario?.harvestPrice ?? null}
                    projectedPriceField="projectedPrice"
                    sx={{
                      ...getInputStyle('harvestPrice'),
                      ...getDiscoveryAwareStyling(isLightMode, harvestDiscoveryPeriod),
                    }}
                    quote={quote}
                    scenario={scenario}
                    scenarioOptionState={scenarioOptionState}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PercentChangeSliderInput
                    estimatedValueFieldName={'projectedPrice'}
                    actualValueFieldName={'harvestPrice'}
                    sliderName={'priceSlider'}
                    initialEstimatedValue={scenario?.projectedPrice ?? null}
                    initialActualValue={scenario?.harvestPrice ?? null}
                  />
                </Grid>
                {mpScenarioPiece &&
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ScenarioMpProjectedPriceInput
                          scenario={scenario}
                          scenarioOptionState={scenarioOptionState}
                          quote={quote}
                          projectedPrice={mpScenarioPiece.rowCropScenarioPieceExtendedData?.projectedPrice ?? null}
                          disabled={areMpFieldsDisabled} />
                      </Grid>
                    </Grid>
                  </Grid>}
                <Grid item xs={8}>
                  <VolatilityInput volatility={scenario?.volatility ?? null} sx={getInputStyle('volatility')} />
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <Button id="btn-refresh-prices" size="small" variant="contained" onClick={handleRefreshPrices} disabled={false}>Refresh Prices</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <AcresInput acres={acres} sx={getInputStyle('quickUnit.acres')} disabled={!quote.quickQuote} allowZero={!quote.quickQuote} />
                </Grid>
                <Grid item xs={6}>
                  {quote.quickQuote && <SharePercentInput sharePercent={sharePercent} showSharePercentValue={unitShare} sx={getInputStyle('quickUnit.sharePercent')} allowZero={!quote.quickQuote} />}
                </Grid>
                <Grid item xs={4}>
                  <ApprovedYieldInput
                    quote={quote}
                    scenarioId={scenarioId}
                    approvedYield={approvedYield}
                    disabled={!quote.quickQuote}
                    sx={getInputStyle('quickUnit.approvedYield')} />
                </Grid>
                <Grid item xs={4}>
                  <ProducerYieldInput producerYield={scenario?.actualProducerYield ?? null} approvedYield={approvedYield} sx={getInputStyle('actualProducerYield')} />
                </Grid>
                <Grid item xs={4}>
                  <PercentChangeSliderInput
                    estimatedValueFieldName={'approvedYield'}
                    actualValueFieldName={'producerYield'}
                    sliderName={'approvedYieldSlider'}
                    initialEstimatedValue={approvedYield}
                    initialActualValue={scenario?.actualProducerYield ?? null}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RateYieldInput
                    quote={quote}
                    scenarioId={scenarioId}
                    rateYield={rateYield}
                    disabled={!quote.quickQuote}
                    allowZero={!quote.quickQuote}
                    sx={getInputStyle('quickUnit.rateYield')} />
                </Grid>
                <Grid item xs={4}>
                  <AphInput
                    aphYield={aphYield}
                    sx={getInputStyle('quickUnit.aphYield')}
                    disabled={!quote.quickQuote}
                    allowZero={!quote.quickQuote}
                    quote={quote}
                    scenarioId={scenarioId}
                  />
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                  <Button id="btn-refresh-yields" size="small" variant="contained" onClick={handleRefreshYields} disabled={false}>Refresh Yields</Button>
                </Grid>
              </Grid>
            </Grid>
            {hasCountyProducts &&
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>County Yields</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <ScenarioExpectedCountyYieldInput
                      label="Expected Cty Yld"
                      expectedCountyYield={scenario?.expectedCountyYield ?? null}
                      sx={getInputStyle('expectedCountyYield')}
                      practiceId={practiceId}
                      commodityCode={quote.commodityCode}
                      insurancePlanCodes={scenarioPlanCodes}
                      disabled={mpScenarioPiece !== null && areMpFieldsDisabled}
                      coverageLevel={null}
                      volatility={scenario?.volatility ?? 0}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ScenarioActualCountyYieldInput
                      year={clientFile.year}
                      countyId={quote.countyId}
                      practiceId={practiceId}
                      volatility={scenario?.volatility ?? 0}
                      coverageLevel={null}
                      typeId={typeId}
                      label="Actual Cty Yld"
                      actualCountyYield={scenario?.actualCountyYield ?? null}
                      sx={getInputStyle('actualCountyYield')}
                      commodityCode={quote.commodityCode}
                      insurancePlanCodes={scenarioPlanCodes} />
                  </Grid>
                  <Grid item xs={4}>
                    <PercentChangeSliderInput
                      estimatedValueFieldName={'expectedCountyYield'}
                      actualValueFieldName={'actualCountyYield'}
                      sliderName={'countyYieldSlider'}
                      initialEstimatedValue={scenario?.expectedCountyYield ?? null}
                      initialActualValue={scenario?.actualCountyYield ?? null}
                    />
                  </Grid>
                </Grid>
              </Grid>}
            {mpScenarioPiece &&
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>MP Input Costs</Typography>
                  </Grid>
                  <ScenarioMpInputCostsInputGroup
                    scenarioId={scenario?.scenarioId ?? null}
                    year={clientFile.year}
                    commodityCode={quote.commodityCode}
                    expectedInputCosts={mpScenarioPiece.rowCropScenarioPieceExtendedData?.expectedInputCosts ?? null}
                    actualInputCosts={mpScenarioPiece.rowCropScenarioPieceExtendedData?.actualInputCosts ?? null}
                    disabled={areMpFieldsDisabled} />
                </Grid>
              </Grid>}
            {farmBillScenarioPiece &&
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>Farm Bill</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FarmBillPriceInputGroup
                      projectedPriceName="farmBillProjectedPrice"
                      actualPriceName="farmBillActualPrice"
                      scenarioPieceType={farmBillScenarioPiece.scenarioPieceType}
                      disabled={false}
                      year={clientFile.year}
                      commodityCode={quote.commodityCode}
                      expectedPrice={farmBillScenarioPiece.rowCropScenarioPieceExtendedData?.projectedPrice ?? null}
                      actualPrice={farmBillScenarioPiece.rowCropScenarioPieceExtendedData?.actualPrice ?? null}
                    />
                  </Grid>
                  {farmBillScenarioPiece.scenarioPieceType === ScenarioPieceType.Arc &&
                    <Grid item xs={12}>
                      <FarmBillBenchMarkYieldInputGroup
                        projectedYieldName="farmBillProjectedYield"
                        actualYieldName="farmBillActualYield"
                        disabled={false}
                        year={clientFile.year}
                        countyId={quote.countyId}
                        commodityCode={quote.commodityCode}
                        expectedYield={farmBillScenarioPiece.rowCropScenarioPieceExtendedData?.expectedYield ?? null}
                        actualYield={farmBillScenarioPiece.rowCropScenarioPieceExtendedData?.actualYield ?? null}
                        practiceId={scenario?.practiceId ?? ''}
                      />
                    </Grid>
                  }
                  {farmBillScenarioPiece.scenarioPieceType === ScenarioPieceType.Plc &&
                    <Grid item xs={4}>
                      <FarmBillYieldInput
                        isActual={true}
                        isArc={false}
                        name={'farmBillActualYield'}
                        disabled={false}
                        label="Payment Yield"
                        year={clientFile.year}
                        commodityCode={quote.commodityCode}
                        countyId={quote.countyId}
                        theYield={farmBillScenarioPiece.rowCropScenarioPieceExtendedData?.actualYield ?? null}
                        practiceId={scenario?.practiceId ?? ''}
                      />
                    </Grid>
                  }
                </Grid>
              </Grid>}
          </Grid>}
      </form>
    </FormProvider>
  );
};
export default ScenarioForm;
