import { createSelector, createSlice } from '@reduxjs/toolkit';
import { ApplicationWizardId, InsuredId } from '../types/api/PrimaryKeys';
import { ApplicationWizardSummary } from '../types/api/applicationWizard/applicationWizard';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import { getApplicationWizardSummaries } from '../services/applicationWizards.service';
import { RootState } from './store';
import { getKeyedStateGroupedBy } from './sliceHelpers';
import { removeApplicationWizard, selectApplicationWizardById } from './applicationsSlice';
import { isNotNullOrUndefined } from '../utils/nullHandling';

interface ApplicationsWizardSummariesState {
  allApplicationWizardSummaries: SliceDataState<ApplicationWizardId, ApplicationWizardSummary>;
}

const initialState: ApplicationsWizardSummariesState = {
  allApplicationWizardSummaries: initialSliceDataState(),
};

export const applicationsWizardSummarySlice = createSlice({
  name: 'applicationWizardSummaries',
  initialState: initialState,
  reducers: {
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allApplicationWizardSummaries, s => s.applicationWizardId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchApplicationWizardSummaries,
      affectedIds: () => [],
    });
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeApplicationSummary,
      affectedIds: arg => arg.applicationSummary.applicationWizardId,
    });
  },
});

export default applicationsWizardSummarySlice.reducer;
export const selectAllAppWizards = (state: RootState) => state.applicationSummaries.allApplicationWizardSummaries.data;
export const selectAppWizardSummaryById = (state: RootState, applicationWizardId: ApplicationWizardId) => state.applicationSummaries.allApplicationWizardSummaries.data[applicationWizardId];
const selectAllSummaries = (state: RootState) => state.applicationSummaries.allApplicationWizardSummaries.data;
// Memoized Selectors
export const selectAllSummariesByInsuredId = createSelector([selectAllSummaries], result => {
  const map = getKeyedStateGroupedBy(result, a => a.insuredId);
  return map;
});

export const fetchApplicationWizardSummaries = createAppAsyncThunk('applicationSummaries/fetchApplicationWizardSummaries', async ({ insuredId, year }: { insuredId: InsuredId, year: number }) => {
  const applicationWizardSummaries = (await getApplicationWizardSummaries(insuredId, year)).data;
  return applicationWizardSummaries;
});

export const removeApplicationSummary = createAppAsyncThunk('applicationSummaries/removeApplicationSummary', async ({ applicationSummary }: { applicationSummary: ApplicationWizardSummary }, thunkApi) => {
  const state = thunkApi.getState();
  const applicationWizard = selectApplicationWizardById(state, applicationSummary.applicationWizardId);

  if (isNotNullOrUndefined(applicationWizard)) {
    await thunkApi.dispatch(removeApplicationWizard({ applicationWizard }));
  }

  return applicationSummary;
});