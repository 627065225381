import { Divider, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided, DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd';
import { useAppDispatch, useKeyMapSelector } from '../../../hooks/reduxHooks';
import { selectAllQuotesByClientFileMap, sortQuotes } from '../../../app/quotesSlice';
import { ClientFileId } from '../../../types/api/PrimaryKeys';
import { Quote } from '../../../types/api/Quote';
import { useDragAndDrop } from '../hooks/useDragAndDrop';
import { ClientFileSingleQuote } from './clientFileSingleQuote.component';
import { DragHandle } from '../../../components/drag-handle/dragHandle.component';

interface Props {
  clientFileId: ClientFileId;
}

interface RenderItemProps {
  isLastElement: (quote: Quote) => boolean;
}

const renderClientFileSingleQuote = (provided: DraggableProvided, snapshot: DraggableStateSnapshot, quote: Quote, funcProps: RenderItemProps) => (
  <DragHandle provided={provided} snapshot={snapshot}>
    <ClientFileSingleQuote quote={quote} />
    {!funcProps.isLastElement(quote) && <Divider sx={{ mt: 2, mb: 1 }} />}
  </DragHandle>
);

export const ClientFileQuoteList = ({ clientFileId }: Props) => {
  const quotes = useKeyMapSelector(selectAllQuotesByClientFileMap, clientFileId);
  const dispatch = useAppDispatch();

  const { list: quoteList, reorder, getRenderItem, getListStyle } = useDragAndDrop({ items: quotes, horizontal: false });

  const onDragEnd = (result: DropResult, _provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    const orderedQuotes = reorder(result.source.index, result.destination.index);
    dispatch(sortQuotes({ quotes: orderedQuotes }));
  };

  const isLastElement = (quote: Quote) => {
    return quoteList.indexOf(quote) === (quoteList.length - 1);
  };

  if (quotes.length === 0) {
    return <Typography variant="subtitle1" ><p>There are no quotes available. <br /> Add a quote to get started.</p></Typography>;
  }

  const renderQuote = getRenderItem<RenderItemProps>({ isLastElement }, renderClientFileSingleQuote);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="quotes-droppable"
        // renderClone required because we use css transform on a parent and that will set the wrong position of the draggable
        // https://github.com/hello-pangea/dnd/blob/main/docs/api/draggable.md#warning-position-fixed
        renderClone={renderQuote}
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {quoteList.map(quote => (
              <Draggable key={quote.quoteId} draggableId={quote.quoteId} index={quote.sortIndex}>
                {renderQuote}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
