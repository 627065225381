import { ScenarioPieceType } from '@silveus/calculations';
import { isNotNullOrUndefined } from '../../../../utils/nullHandling';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../../types/util/Nullable';

export const getHipLowerCoverageLevel = (piecesForScenario: RowCropScenarioPiece[], underlyingPiece: Nullable<RowCropScenarioPiece>): number => {
  const sco = piecesForScenario.find(pieceInScenario => [ScenarioPieceType.ScoYp, ScenarioPieceType.ScoRp, ScenarioPieceType.ScoRpHpe].includes(pieceInScenario.scenarioPieceType));
  const stax = piecesForScenario.find(pieceInScenario => [ScenarioPieceType.StaxRp, ScenarioPieceType.StaxRpHpe].includes(pieceInScenario.scenarioPieceType));

  const availableCoverageLevels = [sco?.upperCoverageLevel, stax?.upperCoverageLevel, underlyingPiece?.upperCoverageLevel].filter(isNotNullOrUndefined);

  if (availableCoverageLevels.length === 0) return 0;

  const highestUnderlyingUpperCoverageLevel = Math.max(...availableCoverageLevels);

  return highestUnderlyingUpperCoverageLevel;
};