import axios, { ParamsSerializerOptions } from 'axios';
import { RampAvailabilityParams, RevBoostCoverageLevelParams, ScoEcoPlusCoverageLevelParams, IceRateParams, HailRateParams, HailProductParams, HailEndorsementParams } from '../types/api/adm/PrivateProductsParams';
import CoverageLevelAvailability from '../types/api/adm/CoverageLevelAvailability';
import IceRate from '../types/api/adm/IceRate';
import { IceAcresCode } from '../types/api/adm/IceAcreCode';
import { IceDeductibleSelection } from '../types/api/adm/iceDeductibleSelection';
import { HailProduct } from '../types/api/hail/hailProduct';
import qs from 'qs';
import HailRate from '../types/api/hail/hailRate';
import HailEndorsement from '../types/api/hail/hailEndorsement';
import { envBaseApiUrl } from '../constants/envConstants';
import AdmDataForQuoteParams from '../types/api/adm/AdmDataForQuoteParams';
import QuoteArcYieldHistories from '../types/api/adm/QuoteArcYieldHistories';

// RAMP
export const getAvailableRampCoverageLevels = (privateProductsParams: RampAvailabilityParams) =>
  axios.get<CoverageLevelAvailability[]>(`${envBaseApiUrl}/ramp/rampCoverageLevels`,
    { params: privateProductsParams });

// RevBoost
export const getAvailableRevBoostCoverageLevels = (revBoostCoverageLevelParams: RevBoostCoverageLevelParams) =>
  axios.get<CoverageLevelAvailability[]>(`${envBaseApiUrl}/revBoost/revBoostCoverageLevels`,
    { params: revBoostCoverageLevelParams });

// SCO+/ECO+
export const getAvailableScoEcoPlusCoverageLevels = (scoEcoPlusCoverageLevelParams: ScoEcoPlusCoverageLevelParams) =>
  axios.get<CoverageLevelAvailability[]>(`${envBaseApiUrl}/scoEcoPlus/scoEcoPlusCoverageLevels`,
    { params: scoEcoPlusCoverageLevelParams });

// ICE
export const getIceRates = (privateProductsParams: IceRateParams) =>
  axios.get<IceRate[]>(`${envBaseApiUrl}/ice/iceRates`,
    { params: privateProductsParams });

export const getIceAcreCodes = () =>
  axios.get<IceAcresCode[]>(`${envBaseApiUrl}/ice/iceAcreCodes`,
    {});

export const getIceDeductibleSelections = () =>
  axios.get<IceDeductibleSelection[]>(`${envBaseApiUrl}/ice/iceDeductibleSelections`,
    {});

// Hail
export const getHailProducts = (hailProductParams: HailProductParams) => {
  return axios.get<HailProduct[]>(`${envBaseApiUrl}/hail/hailProducts`, {
    params: hailProductParams,
    paramsSerializer: {
      serialize: (params: ParamsSerializerOptions) => {
        // Serialize the parameters using qs, ensuring arrays are handled correctly
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      indexes: false,
    },
  });
};

export const getHailRates = (hailRateParams: HailRateParams) => {
  return axios.get<HailRate[]>(`${envBaseApiUrl}/hail/hailRates`, {
    params: hailRateParams,
    paramsSerializer: {
      serialize: (params: ParamsSerializerOptions) => {
        // Serialize the parameters using qs, ensuring arrays are handled correctly
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      indexes: false,
    },
  });
};

export const getHailEndorsements = (hailEndorsementParams: HailEndorsementParams) => {
  return axios.get<HailEndorsement[]>(`${envBaseApiUrl}/hail/hailEndorsements`, {
    params: hailEndorsementParams,
    paramsSerializer: {
      serialize: (params: ParamsSerializerOptions) => {
        // Serialize the parameters using qs, ensuring arrays are handled correctly
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      indexes: false,
    },
  });
};

// FarmBill
export const getFarmBillArcYieldHistories = (admDataParams: AdmDataForQuoteParams[]) => axios.post<QuoteArcYieldHistories[]>(`${envBaseApiUrl}/farmBill/arc-yield-history`, admDataParams);
