import { selectIsLightMode } from '../../../../app/userSettingsSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../../utils/nullHandling';
import { LineData } from './AnalysisLineChart';
import { AxisKey, calculateOffsetForPoint, getDefaultLabelStyle, shouldShowLabel } from './lineCharts.utils';

interface CustomizedChartLabelProps<T> {
  x?: number;
  y?: number;
  value?: number;
  index?: number;
  lineData: LineData<T>[];
  line: LineData<T>;
  data: T[];
  xAxisKey: AxisKey<T>;
  yAxisRange: number;
}

export const CustomizedLineChartLabel = <T,>({ x, y, value, index, data, lineData, line, xAxisKey, yAxisRange }: CustomizedChartLabelProps<T>) => {
  const isLightMode = useAppSelector(selectIsLightMode);
  const applicableDataPoint = isNotNullOrUndefined(index) && data[index][line.dataKey] === value
    ? data[index]
    : data.find(data => data[line.dataKey] === value && data[xAxisKey] === index);

  if (applicableDataPoint === undefined) return <></>;
  if (applicableDataPoint === null) return <></>;
  if (isNullOrUndefined(value)) return <></>;
  if (!shouldShowLabel(data, applicableDataPoint, xAxisKey, line.dataKey)) return <></>;

  const valuesToCompare: unknown[] = [];

  Object.entries(applicableDataPoint).forEach(([key, value]) => {
    if (lineData.some((point: LineData<T>) => point.dataKey === key)) valuesToCompare.push(value);
  });

  valuesToCompare.sort();

  if (valuesToCompare[0] === undefined) return <></>;

  const offset = calculateOffsetForPoint(value, valuesToCompare, yAxisRange);

  return (
    <text
      {...getDefaultLabelStyle(isLightMode)}
      className="chart-label"
      x={x}
      y={y}
      dy={offset}>
      {value.toFixed(2)}
    </text >
  );
};