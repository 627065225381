import { Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select, Tooltip, useTheme } from '@mui/material';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import UnitYear from '../../../types/api/UnitYear';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { Na } from '../../../types/util/Na';
import { getItemsForId } from '../../../utils/mapHelpers';
import { OptionCode } from '@silveus/calculations';
import { OptionLevelCodes } from '../../../constants/optionLevelCodes';
import { useOptionCodesWithAvailability } from '../../../hooks/useOptionCodesWithAvailability';
import OptionState from '../../../types/app/OptionState';
import { OptionSelectionState } from '../../../types/app/enums/optionSelectionState.enum';
import { OptionSelectionAvailabilityWarning } from '../../../constants/optionAvailability';

export type UnitOptionInputParams = {
  unitYear: UnitYear;
  unitOptionsMap: Map<UnitYearId, OptionState[]>;
}

const UnitOptionsInput = forwardRef((props: UnitOptionInputParams, ref) => {
  const theme = useTheme();
  const getFormattedOptionCodesWithAvailability = useOptionCodesWithAvailability();
  const { unitYear, unitOptionsMap } = props;
  const unitYearOptions = getItemsForId(unitOptionsMap, unitYear.unitYearId);
  const refInput = useRef<HTMLInputElement>(null);
  const [options, setOptions] = useState<OptionState[]>(unitYearOptions);

  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return options;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        return false;
      },
    };
  });

  const addRemoveOption = (optionCode: OptionCode) => {
    const tempOptions = [...options];
    const index = tempOptions.findIndex(o => o.option.optionCode === optionCode);
    if (index >= 0) {
      const newSelectionState = tempOptions[index].selectionState === OptionSelectionState.On ? OptionSelectionState.Off : OptionSelectionState.On;
      tempOptions[index] = { ...tempOptions[index], selectionState: newSelectionState };
      setOptions(tempOptions);
    }
  };
  const filteredUnitOptions: OptionState[] = options.filter(o => o.option.optionLevelCode === OptionLevelCodes.Unit);
  return (
    <FormControl sx={{ width: '100%', m: 0, height: '100%' }} ref={refInput}>
      <Select
        id="options-multiple-checkbox"
        multiple
        size="small"
        disabled={unitYearOptions.length === 0}
        value={options}
        input={<OutlinedInput />}
        renderValue={
          options => unitYearOptions.length === 0 ? Na : getFormattedOptionCodesWithAvailability(options
            .filter(o => o.selectionState === OptionSelectionState.On && o.option.optionLevelCode === OptionLevelCodes.Unit))
        }
        sx={{ fontSize: '14px', height: '100%' }}
        MenuProps={{
          className: 'ag-custom-component-popup', // required by ag-grid so when user clicks in the dropdown that click doesn't register as a click outside ag-grid causing grid to stop edit
        }}
      >
        <div>
          {filteredUnitOptions.length === 0 &&
            <MenuItem value="">No available options</MenuItem>
          }
          {filteredUnitOptions.map(o => (
            <MenuItem key={o.option.optionCode} value={o.option.optionCode} onClick={() => addRemoveOption(o.option.optionCode)}>
              <Checkbox checked={o.selectionState === OptionSelectionState.On} />
              <Tooltip title={!o.isAvailable && OptionSelectionAvailabilityWarning} enterDelay={2000}>
                <ListItemText
                  primary={o.option.optionName}
                  primaryTypographyProps={{
                    style: {
                      color: o.isAvailable ? 'inherit' : theme.palette.warning.main,
                    },
                  }}
                />
              </Tooltip>
            </MenuItem>
          ))}
        </div>
      </Select>
    </FormControl>
  );
});

UnitOptionsInput.displayName = 'UnitOptionsInput';
export default UnitOptionsInput;