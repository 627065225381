import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow, MenuList,
  Paper,
  Popper,
  Typography,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import { drawerHeaderContainer } from './drawerHeader.styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { updateShouldCloseOnSave } from '../../app/appDrawerSlice';
import { selectIsLightMode } from '../../app/userSettingsSlice';

interface Props {
  title: string;
  onCancel: () => void;
  formId: string;
}

const DrawerHeader = ({ title, onCancel, formId }: Props) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isLightMode = useAppSelector(selectIsLightMode);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [splitButtonExpanded, setSplitButtonExpanded] = useState(false);

  const handleToggle = () => {
    setSplitButtonExpanded(prevOpen => !prevOpen);
  };

  const handleSplitButtonClose = () => {
    setSplitButtonExpanded(false);
  };

  const updateDrawerCloseState = (shouldClose: boolean) => {
    dispatch(updateShouldCloseOnSave(shouldClose));
    setSplitButtonExpanded(false);
  };

  return (
    <Box sx={{ backgroundColor: isLightMode ? theme.palette.primary.main : theme.palette.neutral.dark, ...drawerHeaderContainer }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <Button id="btn-cancel" variant="outlined" sx={{ mr: '8px', borderColor: theme.palette.common.white, color: theme.palette.common.white }} onClick={onCancel}>Close</Button>
          <ButtonGroup variant="contained" ref={anchorRef} aria-label="outlined primary button group">
            <Button sx={{ backgroundColor: isLightMode ? theme.palette.common.white : theme.palette.common.black, color: theme.palette.primary.main }} onClick={() => updateDrawerCloseState(false)} id="btn-save" type="submit" variant="contained" form={formId}>Save</Button>
            <Button sx={{ backgroundColor: isLightMode ? theme.palette.common.white : theme.palette.common.black, color: theme.palette.primary.main, marginLeft: '0 !important' }} id="btn-split" size="small" onClick={handleToggle}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={splitButtonExpanded}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleSplitButtonClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      <Button id="btn-save-close" type="submit" onClick={() => updateDrawerCloseState(true)} variant="text" form={formId}>Save and Close</Button>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DrawerHeader;