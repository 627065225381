import { Grid, IconButton, Tooltip } from '@mui/material';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { QuoteId, ScenarioPieceGroupId } from '../../../types/api/PrimaryKeys';
import { selectCalculationsByScenarioPieceGroup, updateCalculationForScenario } from '../../../app/calculationResultsSlice';
import { modifyHailScenarioPieceComposition } from '../../../app/hailSlice';
import { setCurrentlySelectedScenarioId } from '../../../app/scenariosSlice';
import '../scenarioPieceCard.style.css';
import HailScenarioPieceComposition from '../../../types/api/HailScenarioPieceComposition';
import { removeHailScenarioPieceCompositionAndRecalculate } from '../../../app/hailSlice';
import { OpenInBrowser } from '@mui/icons-material';
import { LabelCurrencyPair } from '../../../components/labelValuePair/labelValuePair.component';
import ScenarioComponentCard from '../scenarioComponentCard';
import { toggleHailModal } from '../../../app/hailModalSlice';
import { useKeyMapSelectorSingle } from '../../../hooks/reduxHooks';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import PayoutGrid from '../../../components/payoutGrid/payoutGrid.component';
import ScenarioPieceTooltip from '../scenarioPieceTooltip';

interface HailScenarioPieceCardProps {
  hailScenarioPieceComposition: HailScenarioPieceComposition;
  quoteId: QuoteId;
  isScenarioActive: boolean;
  isReadonly: boolean;
}

const HailScenarioPieceCard = ({ hailScenarioPieceComposition, quoteId, isScenarioActive, isReadonly }: HailScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();

  const calcResults = useKeyMapSelectorSingle(selectCalculationsByScenarioPieceGroup, toPrimaryKey<ScenarioPieceGroupId>(hailScenarioPieceComposition.hailScenarioPieceCompositionId));
  const netScenarioPieceTotal = (calcResults?.indemnityPerAcre ?? 0) - (calcResults?.producerPremiumPerAcre ?? 0);
  const updatePieceCompletely = async (newScenarioPieceComposition: HailScenarioPieceComposition, isActive?: boolean) => {
    await dispatch(modifyHailScenarioPieceComposition({ scenarioPieceComposition: { ...newScenarioPieceComposition, isActive: isActive ?? newScenarioPieceComposition.isActive } }));
    await dispatch(updateCalculationForScenario({ scenarioId: newScenarioPieceComposition.scenarioId }));
  };

  const deleteScenarioPiece = async () => {
    await dispatch(removeHailScenarioPieceCompositionAndRecalculate({ hailScenarioPieceComposition: hailScenarioPieceComposition }));
  };

  const openHailModal = async () => {
    dispatch(setCurrentlySelectedScenarioId(hailScenarioPieceComposition.scenarioId));
    dispatch(toggleHailModal());
  };

  const collapsedCardBody = (
    <Grid container>
      <Grid item xs={1} />
      <Grid item container xs={11}>
        <Grid item xs={12}><LabelCurrencyPair label="Coverage" value={calcResults?.liabilityPerAcre ?? 0} /></Grid>
        <Grid item xs={12}><LabelCurrencyPair label="Producer Premium" value={calcResults?.producerPremiumPerAcre ?? 0} /></Grid>
      </Grid>
    </Grid>
  );
  const expandedCardBody = (
    <PayoutGrid
      totalPremium={calcResults?.producerPremium ?? 0}
      totalIndemnity={calcResults?.indemnityAmount ?? 0}
      premiumPerAcre={calcResults?.producerPremiumPerAcre ?? 0}
      indemnityPerAcre={calcResults?.indemnityPerAcre ?? 0}
      totalFixed={calcResults?.totalFixed ?? 0}
      totalSimulated={calcResults?.totalSimulated ?? 0}
      fixedPerAcre={calcResults?.fixedPerAcre ?? 0}
      simulatedPerAcre={calcResults?.simulatedPerAcre ?? 0}
      totalDiscount={0}
      discountPerAcre={0}
      isReadonly={isReadonly}
    />
  );

  const extraCommandBarItems = [
    {
      id: 'open-hail-quoter',
      label: 'Open Hail Quoter',
      onclick: () => { },
      iconButton: <OpenInBrowser fontSize="small" />,
      renderMenuItem: () => (
        <Tooltip title="Open Hail Quoter" color="primary">
          <div style={{ marginLeft: '0px', marginTop: '3px' }}>
            <IconButton size="small" sx={{ pr: 0, pl: 0, mr: 0, ml: 0 }} id="btn-open-hail" onClick={openHailModal}>
              <OpenInBrowser color="primary" fontSize="small" />
            </IconButton>
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <ScenarioComponentCard
      scenarioComponent={hailScenarioPieceComposition}
      quoteId={quoteId}
      isScenarioActive={isScenarioActive}
      updateScenarioComponent={updatePieceCompletely}
      removeScenarioComponent={deleteScenarioPiece}
      label="Hail"
      netValue={netScenarioPieceTotal}
      scenarioId={hailScenarioPieceComposition.scenarioId}
      calcResults={calcResults}
      getFormName={_ => 'Hail'}
      collapsedCardBody={collapsedCardBody}
      expandedCardBody={expandedCardBody}
      extraCommandBarItems={extraCommandBarItems}
      getScenarioPieceTooltip={() => <ScenarioPieceTooltip displayName={'Hail'} calcResults={calcResults} showTriggerYield={false} />}
      isReadonly={isReadonly}
      isInvalid={false}
    />
  );
};

export default HailScenarioPieceCard;
