import { Nullable } from '../../types/util/Nullable';
import { Controller, useFormContext } from 'react-hook-form';
import { getStateCodeFromCountyId } from '../../utils/adm';
import { MenuItem, TextField } from '@mui/material';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectAllStates } from '../../app/admSlice';
import { validateState } from '../../pages/units/validations/stateValidation';

interface StateProps {
  countyId: Nullable<string>;
  disabled?: boolean;
  autoFocus?: boolean;
}

export type StateFields = {
  stateCode: string;
};

const StateInput = ({ countyId, disabled = false, autoFocus = false }: StateProps) => {
  const { control } = useFormContext();
  const states = useAppSelector(selectAllStates);

  return (
    <Controller
      name="stateCode"
      control={control}
      rules={{ validate: validateState }}
      defaultValue={countyId === null ? null : getStateCodeFromCountyId(countyId)}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="State"
          fullWidth
          select
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {states.map(s => <MenuItem key={s.stateCode} value={s.stateCode}>{s.name}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default StateInput;
