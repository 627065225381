import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { MatrixShowFilterType } from '../../../types/api/enums/matrixShowFilterType/MatrixShowFilterType.enum';
import { MatrixShowFilterTypeAttributes } from '../../../types/api/enums/matrixShowFilterType/MatrixShowFilterTypeAttributes';

interface Props {
  showFilter: MatrixShowFilterType;
  className?: string;
}

export type MatrixShowFilterFields = {
  showFilter: MatrixShowFilterType;
};

export const MatrixShowFilterInput = ({ showFilter, className }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="showFilter"
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={showFilter}
      control={control}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          className={className}
          type="text"
          label="Show"
          fullWidth
          select
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => field.onChange(e.target.value)}
        >
          <MenuItem
            value={MatrixShowFilterTypeAttributes[MatrixShowFilterType.PerAcre].value}
          >
            {MatrixShowFilterTypeAttributes[MatrixShowFilterType.PerAcre].description}
          </MenuItem>

          <MenuItem
            value={MatrixShowFilterTypeAttributes[MatrixShowFilterType.Total].value}
          >
            {MatrixShowFilterTypeAttributes[MatrixShowFilterType.Total].description}
          </MenuItem>
        </TextField>
      )}
    />
  );
};