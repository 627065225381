import {
  Commodity,
  createPracticeCode,
  createTypeCode, roundToPlaces,
  ScenarioPieceRequestDTO,
  ScenarioPieceUnit,
  SubCountyCode,
  Unit,
  UnitGroupDto, UnitStructureCode
} from '@silveus/calculations';
import BaseUnit from '../../types/api/BaseUnit';
import { getPracticeCodeFromPracticeId, getTypeCodeFromTypeId } from '../../utils/adm';
import { UnitGroup } from '../../types/api/UnitGroup';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';

export const createBaseScenarioPieceDto = (scenarioPiece: ScenarioPiece, unitGroups: UnitGroup[], createUnits: (unitGroup: UnitGroup) => (ScenarioPieceUnit & Unit)[]): ScenarioPieceRequestDTO => {
  return {
    id: scenarioPiece.scenarioPieceId,
    scenarioPieceType: scenarioPiece.scenarioPieceType,
    unitGroups: unitGroups.map(unitGroup => createBaseUnitGroupDto(unitGroup, createUnits)),
  };
};

export const createBaseUnitGroupDto = (unitGroup: UnitGroup, createUnits: (unitGroup: UnitGroup) => (ScenarioPieceUnit & Unit)[]): UnitGroupDto => {
  const unitStructure = unitGroup.unitStructure;

  const unitGroupDto: UnitGroupDto = {
    unitGroupId: unitGroup.unitGroupId,
    unitStructure: unitStructure as UnitStructureCode,
    units: createUnits(unitGroup),
  };

  return unitGroupDto;
};

export const createBaseUnit = (baseUnit: BaseUnit): Unit => {
  const unit: Unit = {
    id: baseUnit.id,
    acres: baseUnit.acres,
    actualYield: roundToPlaces(baseUnit.actualYield, 0),
    adjustedYield: roundToPlaces(baseUnit.adjustedYield, 0),
    approvedYield: roundToPlaces(baseUnit.approvedYield, 0),
    rateYield: roundToPlaces(baseUnit.rateYield, 0),
    insuredSharePercent: baseUnit.sharePercent,
    isLossEvent: false,
    isPP: false,
    practiceCode: createPracticeCode(baseUnit.commodityCode as Commodity, getPracticeCodeFromPracticeId(baseUnit.practiceId)),
    subCountyCode: baseUnit.subCountyCode as SubCountyCode | null,
    typeCode: createTypeCode(baseUnit.commodityCode as Commodity, getTypeCodeFromTypeId(baseUnit.typeId)),
  };

  return unit;
};
