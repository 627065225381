import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { Nullable } from '../../../../types/util/Nullable';
import { FarmBillCodeType } from '@silveus/calculations';

export interface FarmBillCoverageTypeInputProps {
  selectedCoverage: Nullable<string>;
  disabled?: boolean;
}

export const FarmBillCoverageTypeInput = ({ selectedCoverage, disabled = false }: FarmBillCoverageTypeInputProps) => {
  const { control } = useFormContext();

  const codeMapping = [[FarmBillCodeType.County, 'County'], [FarmBillCodeType.Producer, 'Producer']];
  const codeMappingKeys = codeMapping.map(x => x[0]);

  return (
    <Controller
      name="planCode"
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.validateMultipleRules(
          [
            VALIDATION_RULES.restrictNull(),
            VALIDATION_RULES.restrictNotOnListValueNullable(codeMappingKeys),
          ],
        ),
      }}
      defaultValue={selectedCoverage ?? ''}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label="Coverage Type"
          fullWidth
          select
          value={value}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => field.onChange(e.target.value)}
          disabled={disabled}
          size="small"
        >
          {codeMapping.map(cov =>
            <MenuItem key={cov[0]} value={cov[0]}>
              {cov[1]}
            </MenuItem>)}
        </TextField>
      )}
    />
  );
};
