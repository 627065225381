import { Alert, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { beginLoading, endLoading } from '../../../app/loaderSlice';
import { selectAllQuotesByClientFileMap } from '../../../app/quotesSlice';
import { selectLastRemotelyPersistedLinkedScenarios, selectUserLinkedScenarios, updateLinkedScenariosSettingsAndScenarioValues } from '../../../app/userSettingsSlice';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import { useDebounce } from '../../../hooks/useDebounce';
import { ClientFileId, ScenarioId } from '../../../types/api/PrimaryKeys';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import ScenarioCard from '../../scenario/components/scenarioCard.component';
import { NoQuotesForClientFile } from './noQuotesForClientFile.component';
import { currentlySelectedScenarioId, selectAllScenariosByClientFileIdMap, sortScenarios } from '../../../app/scenariosSlice';
import { selectQuoteIdsAssociatedWithAppTasks } from '../../../app/applicationsSlice';
import { useDragAndDrop } from '../hooks/useDragAndDrop';
import { DragHandle } from '../../../components/drag-handle/dragHandle.component';
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, DropResult, Droppable, ResponderProvided } from '@hello-pangea/dnd';
import { Nullable } from '../../../types/util/Nullable';
import { selectAppTaskStatusesByClientFileId } from '../../../app/appTaskStatusSlice';
import { AppTaskStatusResult } from '../../../types/api/appTaskStatusResult';
import { isAppTaskStatusEditable } from '../../applicationsModal/wizardSteps/appDecisionsPage/appTaskUtils';

interface Props {
  areScenariosLoading: boolean;
  clientFileId: ClientFileId;
}

const DelayUntilUserSettingsShouldPersistMs = 2000;

interface RenderItemProps {
  scenariosWithAppTasks: AppTaskStatusResult[];
  selectedScenarioId: Nullable<ScenarioId>
}

const renderClientFileQuoteScenarioListScenario = (provided: DraggableProvided, snapshot: DraggableStateSnapshot, scenario: RowCropScenario, { selectedScenarioId, scenariosWithAppTasks }: RenderItemProps) => {
  const scenarioAppTaskResult = scenariosWithAppTasks.find(x => x.scenarioId === scenario.scenarioId);
  const isScenarioEditable = isAppTaskStatusEditable(scenarioAppTaskResult?.status ?? null);

  const isSelectedItem = selectedScenarioId === scenario.scenarioId;
  return (
    <DragHandle
      provided={provided}
      snapshot={snapshot}
      key={scenario.scenarioId}
      horizontalList={true}
    >
      <ScenarioCard
        scenario={scenario}
        isReadonly={!isScenarioEditable}
        isSelectedItem={isSelectedItem} />
    </DragHandle>
  );
};

export const ClientFileScenarioModules = ({ areScenariosLoading, clientFileId }: Props) => {
  const dispatch = useAppDispatch();
  const scenarios = useKeyMapSelector(selectAllScenariosByClientFileIdMap, clientFileId);
  const hiddenScenarios = scenarios.filter(s => !s.isVisible);
  const visibleScenarios = scenarios.filter(s => s.isVisible);

  const { list: scenarioList, reorder, getRenderItem, getListStyle } = useDragAndDrop({ items: scenarios, horizontal: true });

  const quotesForClientFile = useKeyMapSelector(selectAllQuotesByClientFileMap, clientFileId);
  const quoteIdsWithTasks = useAppSelector(selectQuoteIdsAssociatedWithAppTasks);
  const doesQuoteContainAppTasks = scenarios.some(item => quoteIdsWithTasks.has(item.quoteId));
  const lastSavedScenarioState = useAppSelector(selectLastRemotelyPersistedLinkedScenarios);

  const linkedScenarios = useAppSelector(selectUserLinkedScenarios);
  const debouncedLinkedScenarios = useDebounce(linkedScenarios, DelayUntilUserSettingsShouldPersistMs);
  const selectedScenarioId = useAppSelector(currentlySelectedScenarioId);

  const scenariosWithAppTasks = useKeyMapSelector(selectAppTaskStatusesByClientFileId, clientFileId);
  const onDragEnd = (result: DropResult, _provided: ResponderProvided) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderedScenarios = reorder(result.source.index, result.destination.index);
    dispatch(sortScenarios({ scenarios: orderedScenarios }));
  };

  useEffect(() => {
    if (debouncedLinkedScenarios !== lastSavedScenarioState) {
      dispatch(updateLinkedScenariosSettingsAndScenarioValues({ debouncedLinkedScenarios: debouncedLinkedScenarios, clientFileId: clientFileId }));
    }
  }, [debouncedLinkedScenarios, lastSavedScenarioState, dispatch]);

  // Render Logic ------------------------------

  // Loading indicator
  useEffect(() => {
    if (areScenariosLoading) {
      dispatch(beginLoading());
    } else {
      dispatch(endLoading());
    }
  }, [areScenariosLoading]);

  // Sad Paths -----------------------------------------------

  // No Quotes For the Client File View
  if (quotesForClientFile.length === 0) {
    return <NoQuotesForClientFile clientFileId={clientFileId} />;
  }

  // No Scenarios For Quote View
  if (scenarios.length === 0 && hiddenScenarios.length === 0) {
    return <Typography variant="subtitle1" sx={{ pl: 2 }}>There are no available scenarios for this client file.</Typography>;
  }

  // No Scenarios for the quote year because user has unselected all of them
  if (visibleScenarios.length === 0 && hiddenScenarios.length > 0) {
    return <Typography variant="subtitle1" sx={{ pl: 2 }}>There are no selected scenarios for this client file.</Typography>;
  }


  // Happy Path -----------------------------------------------------------------------

  const renderScenario = getRenderItem({ scenariosWithAppTasks, selectedScenarioId }, renderClientFileQuoteScenarioListScenario);

  return (
    <Grid container p={1} direction="column" flexWrap="nowrap" sx={{ height: '100%', m: 0 }}>
      {doesQuoteContainAppTasks && (
        <Grid item pt={2}>
          <Alert sx={{ maxWidth: '800px' }} variant="filled" severity="info">You have one or more scenarios that are part of an application. You will not be allowed to delete this quote</Alert>
        </Grid>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="scenarios-droppable"
          direction="horizontal"
          // renderClone required because we use css transform on a parent and that will set the wrong position of the draggable
          // https://github.com/hello-pangea/dnd/blob/main/docs/api/draggable.md#warning-position-fixed
          renderClone={renderScenario}
        >
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {scenarioList.filter(s => s.isVisible).map(scenario => (
                <Draggable
                  key={scenario.scenarioId}
                  draggableId={scenario.scenarioId}
                  index={scenario.sortIndex}
                >
                  {renderScenario}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};
