import { Box, InputAdornment, Popover, TextField } from '@mui/material';
import { useState } from 'react';
import { ChromePicker } from 'react-color';

interface Props {
  color: string;
  value: string | number | null | undefined;
  onSweetSpotColorChange: (color: string) => void;
}

export const MatrixSweetSpotColorAndValueInput = ({ color, value, onSweetSpotColorChange }: Props) => {
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState<HTMLElement | null>(null);

  const isColorPickerOpen = colorPickerAnchorEl !== null;

  const openColorPicker = (target: HTMLElement) => {
    setColorPickerAnchorEl(target);
  };

  const closeColorPicker = () => {
    setColorPickerAnchorEl(null);
  };

  const handleSweetSpotColorChange = (color: string) => {
    onSweetSpotColorChange(color);
  };

  return (
    <>
      <TextField
        type="text"
        size="small"
        fullWidth
        value={value ?? ''}
        InputProps={{
          sx: { fontSize: '13px', fontWeight: 400 },
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start" sx={{ marginLeft: '-8px' }}>
              <Box borderRadius="4px" sx={{ cursor: 'pointer', backgroundColor: color, height: '18px', width: '20px' }} onClick={e => openColorPicker(e.currentTarget)} />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        open={isColorPickerOpen}
        anchorEl={colorPickerAnchorEl}
        onClose={closeColorPicker}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <ChromePicker
          color={color}
          onChangeComplete={color => handleSweetSpotColorChange(color.hex)}

        />
      </Popover>
    </>
  );
};