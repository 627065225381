import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { ApplicationWizardStep, ApplicationWizardStepProps } from './applicationWizardStep';
import ApplicationWizardPage from './applicationWizardPage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, Typography } from '@mui/material';
import YesNoQuestionInput from '../../../components/formInputs/applications/yesNoQuestionInput';
import { saveApplication } from '../../../app/applicationsSlice';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { useEffect } from 'react';

export const InsuredQuestionsStep: ApplicationWizardStep = {
  isDisabled(app: ApplicationWizard) {
    return !app.anyChanges ||
      (!app.isGeneratingForms);
  },
  title: 'Insured Questions',
  index: 2,
  formId: 'form-insured-questions',
  icon: <InfoOutlinedIcon />,
  isValid(app: ApplicationWizard) {
    return {
      isValid: true,
      errors: [],
    };
  },
};

type InsuredQuestionsFormFields = {
  isLimitedResourceFarmer: boolean;
  isBFR: boolean;
  isVFR: boolean;
  isVIP: boolean;
  insuringLLTs: boolean;
  anyPOA: boolean;
  additionAuthorityToSign: boolean;
}

const InsuredQuestionsPage = ({ application, navigateToPreviousStep, navigateToNextStep, updateApplication }: ApplicationWizardStepProps) => {
  const dispatch = useAppDispatch();
  const methods = useForm<InsuredQuestionsFormFields>({
    defaultValues: application,
  });
  const { formState } = methods;
  const isDirty = formState.isDirty;

  // Here we want to know when something in the form has changed so we can persist that change to redux as changes
  // on this page impact what steps show as enabled in the wizard. We can't wait until user hits continue/prev to save
  // like we will for almost all the other pages.
  useEffect(() => {
    if (isDirty) {
      const updatedApplication: ApplicationWizard = { ...application, ...methods.getValues() };
      updateApplication(updatedApplication);
      // Reset the form's isDirty state so we can tell when the next change comes in
      methods.reset({}, { keepValues: true });
    }
  }, [isDirty]);

  const saveInProgressWork: SubmitHandler<InsuredQuestionsFormFields> = async data => {
    const updatedApplication: ApplicationWizard = { ...application, ...data };
    dispatch(saveApplication({ updatedApplication }));
    navigateToNextStep();
  };

  const handleNavigateToPrevious = () => {
    const updatedApplication: ApplicationWizard = { ...application, ...methods.getValues() };
    dispatch(saveApplication({ updatedApplication }));
    navigateToPreviousStep();
  };

  return (
    <FormProvider {...methods}>
      <form id="form-insured-questions" onSubmit={methods.handleSubmit(saveInProgressWork)} style={{ height: '100%' }}>
        <ApplicationWizardPage tabIndex={InsuredQuestionsStep.index} onPreviousClick={handleNavigateToPrevious}>
          <Grid container alignItems="center" rowSpacing={3} columnSpacing={1} p={2} sx={{ maxWidth: '600px !important' }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">You must answer the following questions before proceeding</Typography>
            </Grid>
            <Grid item container justifyContent="flex-end">
              <Grid item width="52px">YES</Grid>
              <Grid item width="52px">NO</Grid>
            </Grid>
            <Grid item container>
              <YesNoQuestionInput
                question="1. Limited Resource Farmer?"
                value={application.isLimitedResourceFarmer}
                id="isLimitedResourceFarmer"
              />
              <YesNoQuestionInput
                question="2. Applicant is at least 18 years old?"
                value={application.isApplicantAtLeast18}
                id="isApplicantAtLeast18"
              />
              <YesNoQuestionInput
                question="3. Beginning Farmer/Rancher BFR?"
                value={application.isBFR}
                id="isBFR"
              />
              <YesNoQuestionInput
                question="4. Veteran Farmer/Rancher?"
                value={application.isVFR}
                id="isVFR"
              />
              <YesNoQuestionInput
                question="5. Vertically Integrated Producer?"
                value={application.isVIP}
                id="isVIP"
              />
              <YesNoQuestionInput
                question="6. Insuring any Landlord's or Tenant's share?"
                value={application.insuringLLTs}
                id="insuringLLTs"
              />
              <YesNoQuestionInput
                question="7. Has a Power of Attorney or Authorized Rep?"
                value={application.anyPOA}
                id="anyPOA"
              />
              <YesNoQuestionInput
                question="8. Granting people authority to sign insurance documents?"
                value={application.additionAuthorityToSign}
                id="additionAuthorityToSign"
              />
            </Grid>
          </Grid>
        </ApplicationWizardPage>
      </form>
    </FormProvider>
  );
};

export default InsuredQuestionsPage;