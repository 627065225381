import { useEffect, useState } from 'react';
import { getBulkInsuredDataModifiedAfter, getServerDeletedEntities } from '../../../services/offline.service';
import { getAllOfflineChangedEntities, getAllOfflineEntities } from '../../../utils/dexieQueryHelpers/getAllOfflineChangedEntities';
import { OfflineChangedEntitiesResult } from '../../../utils/dexieQueryHelpers/OfflineChangedEntitiesResult';
import { BulkServerDeletedStatusModel } from '../../../types/api/offline/BulkServerDeletedStatusModel';
import { getAllOfflineDeletedEntities } from '../../../utils/dexieQueryHelpers/getAllOfflineDeletedEntities';
import { Nullable } from '../../../types/util/Nullable';
import { InsuredId } from '../../../types/api/PrimaryKeys';
import BulkInsuredData from '../../../types/api/offline/BulkInsuredData';
import { getLatestDate } from '../utils/getLatestDate';

const minDate = new Date(1900, 1, 1);

const buildServerDeletedRequestModelFromChangedEntities = (changedEntities: OfflineChangedEntitiesResult) => {

  const result: BulkServerDeletedStatusModel = {
    clientFileIds: changedEntities.clientFiles.map(clientFile => clientFile.clientFileId),
    historicalAnalysisIds: changedEntities.historicalAnalyses.map(item => item.historicalAnalysisId),
    matrixIds: changedEntities.matrices.map(item => item.matrixId),
    premiumBreakdownIds: changedEntities.premiumBreakdowns.map(item => item.premiumBreakdownId),
    quoteIds: changedEntities.quotes.map(item => item.quoteId),
    rowCropScenarioIds: changedEntities.rowCropScenarios.map(item => item.scenarioId),
    rowCropScenarioPieceIds: changedEntities.rowCropScenarioPieces.map(item => item.scenarioPieceId),
    forwardSoldScenarioPieceIds: changedEntities.forwardSoldScenarioPieces.map(item => item.scenarioPieceId),
    inputCostScenarioPieceIds: changedEntities.inputCostScenarioPieces.map(item => item.scenarioPieceId),
    harvestRevenueScenarioPieceIds: changedEntities.harvestRevenueScenarioPieces.map(item => item.scenarioPieceId),
    scenarioOptionIds: changedEntities.scenarioOptions.map(item => item.scenarioOptionId),
    scenarioOptionUnitYearIds: changedEntities.scenarioOptionUnitYears.map(item => item.scenarioOptionUnitYearId),
    scenarioQuickUnitIds: changedEntities.scenarioQuickUnits.map(item => item.scenarioQuickUnitId),
    scenarioUnitYearAphIds: changedEntities.scenarioUnitYearAph.map(item => item.scenarioUnitYearAphId),
    trendlineAnalysisIds: changedEntities.trendlineAnalyses.map(item => item.trendlineAnalysisId),
    unitGroupIds: changedEntities.unitGroups.map(item => item.unitGroupId),
    unitYearAphIds: changedEntities.unitYearAph.map(item => item.unitYearAphId),
    unitYearIds: changedEntities.unitYears.map(item => item.unitYearId),
  };

  return result;
};

export const useGetAllNeededReconciliationData = (lastOfflineBulkDataDownloadTime: Nullable<string>, lastOfflineSynchronizationTime: Nullable<string>, preDownloadedInsureds: InsuredId[]) => {
  const [serverEntitiesDeleted, setServerEntitiesDeleted] = useState<Nullable<BulkServerDeletedStatusModel>>(null);
  const [clientEntitiesDeleted, setClientEntitiesDeleted] = useState<Nullable<OfflineChangedEntitiesResult>>(null);

  const [serverEntitiesModified, setServerEntitiesModified] = useState<Nullable<BulkInsuredData>>(null);
  const [clientEntitiesModified, setClientEntitiesModified] = useState<Nullable<OfflineChangedEntitiesResult>>(null);

  const [allClientEntities, setAllClientEntities] = useState<Nullable<OfflineChangedEntitiesResult>>(null);

  useEffect(() => {
    const getServerEntitiesModified = async () => {
      const mostRecentSyncOrPullTimestamp = getLatestDate(lastOfflineBulkDataDownloadTime, lastOfflineSynchronizationTime);

      if (mostRecentSyncOrPullTimestamp === null) return;

      const result = await getBulkInsuredDataModifiedAfter({ modifiedAfter: new Date(mostRecentSyncOrPullTimestamp), insuredIds: preDownloadedInsureds });

      setServerEntitiesModified(result.data);
    };

    const handleGetEntitiesInLocalChangedStatus = async () => {
      const dateToUse = lastOfflineBulkDataDownloadTime === null ? null : new Date(lastOfflineBulkDataDownloadTime);
      const changedEntities = await getAllOfflineChangedEntities(dateToUse ?? minDate);

      setClientEntitiesModified(changedEntities);
    };

    const handleGetAllEntitiesInLocal = async () => {
      const allEntities = await getAllOfflineEntities();

      setAllClientEntities(allEntities);
    };

    const handleGetServerDeletedEntities = async () => {
      const changedEntitiesWithoutCreatedEntities = await getAllOfflineChangedEntities(null, true);

      const request = buildServerDeletedRequestModelFromChangedEntities(changedEntitiesWithoutCreatedEntities);
      const serverResult = await getServerDeletedEntities(request);

      setServerEntitiesDeleted(serverResult.data);
    };

    const handleGetLocallyDeletedEntities = async () => {
      const dateToUse = lastOfflineBulkDataDownloadTime === null ? null : new Date(lastOfflineBulkDataDownloadTime);

      const locallyDeletedEntities = await getAllOfflineDeletedEntities(dateToUse ?? minDate);
      setClientEntitiesDeleted(locallyDeletedEntities);
    };

    const getAllNeededData = async () => {
      const serverEntitiesPromise = getServerEntitiesModified();
      const localChangedEntitiesPromise = handleGetEntitiesInLocalChangedStatus();
      const localEntitiesPromise = handleGetAllEntitiesInLocal();
      const serverDeletedEntitiesPromise = handleGetServerDeletedEntities();
      const locallyDeletedEntitiesPromise = handleGetLocallyDeletedEntities();

      await Promise.all([serverEntitiesPromise, localChangedEntitiesPromise, localEntitiesPromise, serverDeletedEntitiesPromise, locallyDeletedEntitiesPromise]);
    };

    getAllNeededData();
  }, [lastOfflineBulkDataDownloadTime, lastOfflineSynchronizationTime, preDownloadedInsureds]);

  return { serverEntitiesDeleted, clientEntitiesDeleted, serverEntitiesModified, clientEntitiesModified, allClientEntities };
};

