import { Grid, Typography } from '@mui/material';
import { selectCommodity, selectCounty, selectStateFromCounty } from '../../../app/admSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Quote } from '../../../types/api/Quote';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { selectApprovedYieldForScenario } from '../../../app/unitsSlice';

type MatrixCardHeaderProps = {
  quote: Quote;
  scenario: RowCropScenario;
}

const MatrixCardHeader = ({ quote, scenario }: MatrixCardHeaderProps) => {
  const aph = useAppSelector(state => selectApprovedYieldForScenario(state, scenario.scenarioId, quote.countyId, quote.commodityCode)) ?? 0;
  const plantPrice = scenario.projectedPrice ?? 0;
  const expectedCountyYield = scenario.expectedCountyYield ?? 0;
  const county = useAppSelector(state => selectCounty(state, quote.countyId));
  const state = useAppSelector(state => selectStateFromCounty(state, quote.countyId));
  const commodity = useAppSelector(state => selectCommodity(state, quote.commodityCode));

  return (
    <Grid container pl={1} pt={1} pb={1} spacing={2} >
      <Grid item>
        <Typography sx={{ color: theme => theme.palette.text.primary }} variant="body2">State: {state?.name}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ color: theme => theme.palette.text.primary }} variant="body2">County: {county?.name}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ color: theme => theme.palette.text.primary }} variant="body2">Crop: {commodity?.name}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ color: theme => theme.palette.text.primary }} variant="body2">Plant Price: {plantPrice}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ color: theme => theme.palette.text.primary }} variant="body2">APH: {aph}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ color: theme => theme.palette.text.primary }} variant="body2">Exp Ct Yield: {expectedCountyYield}</Typography>
      </Grid>
    </Grid>
  );
};

export default MatrixCardHeader;
