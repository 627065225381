import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { Nullable } from '../../types/util/Nullable';
import VALIDATION_RULES from '../../constants/formValidationRules';

export interface YearProps {
  year: Nullable<number>;
  fieldLabel: string;
  disabled: boolean;
  autoFocus?: boolean;
  availableYears: number[];
}

export type YearFields = {
  year: number;
}

const YearInput = ({ year, fieldLabel, availableYears, disabled, autoFocus = false }: YearProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="year"
      control={control}
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={year ?? availableYears[0]}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="number"
          label={fieldLabel}
          fullWidth
          disabled={disabled}
          select
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          size="small"
        >
          {availableYears.map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default YearInput;
