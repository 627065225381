import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { HarvestRevenueScenarioPieceId, ScenarioId, ScenarioPieceId } from '../../../types/api/PrimaryKeys';
import { Quote } from '../../../types/api/Quote';
import { Nullable } from '../../../types/util/Nullable';
import { ClientFile } from '../../../types/api/ClientFile';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { selectScenarioById } from '../../../app/scenariosSlice';
import { FormProvider, SubmitHandler, useFieldArray } from 'react-hook-form';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import { Button, Grid, IconButton, TableCell, TableRow, Tooltip, Typography, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdjustmentType, ScenarioPieceType } from '@silveus/calculations';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import HarvestRevenueScenarioPiece, { HarvestRevenueScenarioPieceTransaction } from '../../../types/api/HarvestRevenueScenarioPiece';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import { calculateHarvestRevenue } from '../../../app/calculationResultsSlice';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import { addHarvestRevenueScenarioPiece, modifyHarvestRevenueScenarioPiece, removeHarvestRevenueScenarioPieceAndRecalculate } from '../../../app/harvestRevenueScenarioPiecesSlice';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import HarvestRevenueTableWrapper from './harvestRevenueTableComponents/harvestRevenueTableWrapper.component';
import HarvestRevenueTransactionProductionType from './harvestRevenueTableComponents/harvestRevenueTransaction.ProductionType.component';
import HarvestRevenueTransactionProduction from './harvestRevenueTableComponents/harvestRevenueTransaction.Production.component';
import HarvestRevenueTransactionPriceType from './harvestRevenueTableComponents/harvestRevenueTransaction.PriceType.component';
import HarvestRevenueTransactionPrice from './harvestRevenueTableComponents/harvestRevenueTransaction.Price.component';
import HarvestRevenueOutputSummary from './harvestRevenueTableComponents/harvestRevenueOutputSummary.component';
import { useEffect, useState } from 'react';
import { SimulatedResults } from '@silveus/calculations/dist/shared/results/simulatedResults';
import { formatCurrency } from '../../../utils/formatNumbers';
import { selectAllForwardSoldScenarioPiecesByScenarioMap } from '../../../app/forwardSoldScenarioPiecesSlice';
import ForwardSoldScenarioPiece from '../../../types/api/ForwardSoldScenarioPiece';
import { getSafeRevenue, roundHarvestRevenuePriceNumber } from './harvestRevenueTableComponents/harvestRevenueUtils';
import { Box } from '@mui/system';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { useScenarioPieceForm } from '../useScenarioPieceForm';

interface HarvestRevenueScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<HarvestRevenueScenarioPiece>;
  scenarioId: ScenarioId;
  year: number;
  countyId: string;
  commodityCode: string;
  typeId: string;
  practiceId: string;
  disabled: boolean;
  quote: Quote;
  clientFile: ClientFile;
  handleCancel: () => void;
}

const StyledTableCell = styled(TableCell)({
  padding: '0px',
  '& p': {
    fontSize: '12px',
  },
});

export interface HarvestRevenueFormFields {
  harvestRevenueTransactions: HarvestRevenueScenarioPieceTransaction[];
}

export type HarvestRevenueScenarioPieceFormFields = HarvestRevenueFormFields & ScenarioPieceFormFields;

const HarvestRevenueScenarioPieceForm = ({ scenarioPiece, scenarioId, disabled, registerHeader, handleCancel, quote, clientFile, handleValidation, isCanceling = false }: HarvestRevenueScenarioPieceFormProps) => {
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  if (scenario === null) {
    throw new Error('Attempting to calculate for scenario piece when the parent scenario does not exist in state');
  }

  const forwardSoldScenarioPieceOfScenariosList = useAppSelector(selectAllForwardSoldScenarioPiecesByScenarioMap);
  let forwardSoldScenarioPiece: ForwardSoldScenarioPiece | undefined = undefined;
  if ((forwardSoldScenarioPieceOfScenariosList.get(scenarioId)?.length ?? 0) > 0) {
    const forwardSoldList = forwardSoldScenarioPieceOfScenariosList.get(scenarioId);
    if (forwardSoldList) {
      const temp: ForwardSoldScenarioPiece = forwardSoldList[0];
      if (temp.isActive) {
        forwardSoldScenarioPiece = temp;
      }
    }
  }

  const formId = 'harvestRevenueScenarioPieceForm';
  const dispatch = useAppDispatch();
  const methods = useScenarioPieceForm<HarvestRevenueScenarioPieceFormFields>(scenarioPiece, {
    harvestRevenueTransactions: scenarioPiece?.harvestRevenueTransactions ?? [{
      productionAdjustmentTypeId: AdjustmentType.Remaining,
      production: 0,
      priceAdjustmentTypeId: AdjustmentType.PlusMinus,
      price: 0,
    }],
  });

  const { fields, append, remove } = useFieldArray({ control: methods.control, name: 'harvestRevenueTransactions' });
  const [harvestRevenueScenarioPieceId] = useState<HarvestRevenueScenarioPieceId>(scenarioPiece?.harvestRevenueScenarioPieceId ?? generatePrimaryKey<HarvestRevenueScenarioPieceId>());
  const [scenarioPieceId] = useState<ScenarioPieceId>(scenarioPiece?.scenarioPieceId ?? generatePrimaryKey<ScenarioPieceId>());

  const onSubmit: SubmitHandler<HarvestRevenueScenarioPieceFormFields> = async data => {
    data.harvestRevenueTransactions.forEach(x => {
      x.price = roundHarvestRevenuePriceNumber(x.priceAdjustmentTypeId, x.price);
    });

    const newScenarioPiece: HarvestRevenueScenarioPiece = {
      isActive: data.isActive,
      isInvalid: false,
      scenarioId: scenarioId,
      scenarioPieceType: ScenarioPieceType.HarvestRevenue,
      harvestRevenueScenarioPieceId: scenarioPiece?.harvestRevenueScenarioPieceId ?? generatePrimaryKey(),
      harvestRevenueTransactions: data.harvestRevenueTransactions,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    if (scenarioPiece === null) {
      await dispatch(addHarvestRevenueScenarioPiece({ scenarioPiece: newScenarioPiece }));
    } else {
      await dispatch(modifyHarvestRevenueScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;
  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const handleAddHarvestRevenue = () => {
    append(
      {
        harvestRevenueTransactionId: generatePrimaryKey(),
        productionAdjustmentTypeId: AdjustmentType.Fixed,
        production: 0,
        priceAdjustmentTypeId: AdjustmentType.Fixed,
        price: 0,
      });
    handleTransactionValueChanged();
  };

  const handleRemoveHarvestRevenue = (index: number) => {
    remove(index);
    handleTransactionValueChanged();
  };

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeHarvestRevenueScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  const [calcResults, setCalcResults] = useState<Nullable<SimulatedResults>>(null);

  useEffect(() => {
    handleTransactionValueChanged();
  }, []);

  const handleTransactionValueChanged = async () => {
    const values = methods.getValues();
    const simulatedTransactions = [...values.harvestRevenueTransactions];

    const harvestRevenueScenarioPiece: HarvestRevenueScenarioPiece = {
      harvestRevenueScenarioPieceId: harvestRevenueScenarioPieceId,
      harvestRevenueTransactions: simulatedTransactions,
      isActive: true,
      isInvalid: false,
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPieceId,
      scenarioPieceType: ScenarioPieceType.HarvestRevenue,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    const response = await dispatch(calculateHarvestRevenue({ scenarioId, harvestRevenueScenarioPiece: harvestRevenueScenarioPiece, forwardSoldScenarioPiece: forwardSoldScenarioPiece }));

    if (calculateHarvestRevenue.fulfilled.match(response)) {
      const calculatedScenarioResult = response.payload;
      const calculatedResult = calculatedScenarioResult.scenarioPieceGroups.flatMap(spg => spg.scenarioPieces).find(sp => sp.id === scenarioPieceId);

      if (calculatedResult !== undefined) {
        setCalcResults(calculatedResult);
      }
    }
  };

  const triggerTransactionListChanged = (childFunction: () => void) => {
    childFunction();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid container item justifyContent="end" pb={1} xs={12} >
              <Button id="btn-add-year" variant="contained" onClick={handleAddHarvestRevenue} disabled={disabled}>Add Harvest Revenue</Button>
            </Grid>
            <Grid item xs={12} sx={{ p: '0 !important' }}>
              <Box sx={{ overflowY: 'auto', overflowX: 'hidden' }} maxHeight={'350px'} maxWidth={'440px'}>
                <HarvestRevenueTableWrapper
                  disabled={disabled}
                  includeActionColumn={true}
                  includeHints={true}
                  useHeaderColSpan={true}
                  tableBodyContents={
                    fields.map((item, index) => {
                      const rev = formatCurrency(getSafeRevenue(calcResults, index) ?? 0, false);

                      return (
                        <TableRow key={item.id}>
                          <StyledTableCell>
                            <HarvestRevenueTransactionProductionType onTransactionValueChanged={handleTransactionValueChanged} index={index} disabled={disabled} handleTransactionListChanged={triggerTransactionListChanged} />
                          </StyledTableCell>
                          <StyledTableCell>
                            <HarvestRevenueTransactionProduction onTransactionValueChanged={handleTransactionValueChanged} index={index} disabled={disabled} calcResults={calcResults} />
                          </StyledTableCell>
                          <StyledTableCell>
                            <HarvestRevenueTransactionPriceType onTransactionValueChanged={handleTransactionValueChanged} index={index} disabled={disabled} />
                          </StyledTableCell>
                          <StyledTableCell>
                            <HarvestRevenueTransactionPrice onTransactionValueChanged={handleTransactionValueChanged} index={index} disabled={disabled} />
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Tooltip title={rev} placement="top-start" enterDelay={1000}>
                              <Typography align="left" sx={{ color: 'lightgray', fontSize: '12px', maxWidth: '60px' }}>{rev}</Typography>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton size="small" onClick={() => handleRemoveHarvestRevenue(index)} disabled={disabled}>
                              <DeleteIcon />
                            </IconButton>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })
                  } />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <HarvestRevenueOutputSummary
                bushelsProduced={calcResults?.bushelsProduced ?? 0}
                totalForwardSold={calcResults?.totalForwardSold ?? 0}
                totalYieldSold={calcResults?.totalYieldSold ?? 0}
                averagePrice={calcResults?.averagePrice ?? 0}
              />
            </Grid>
            <Grid item xs={12}>
              <IsActiveInput isActive={scenarioPiece?.isActive ?? null} disabled={false} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
    </>
  );
};

export default HarvestRevenueScenarioPieceForm;
