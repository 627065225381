import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import NumberTextField, { getRoundedValue, onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferProducerYield } from '../../../app/admSlice';
import { isNullOrUndefined, isUndefined } from '../../../utils/nullHandling';
import { useEffect } from 'react';
import { isApiDataStale, isSavedDataBeingLoaded } from '../../../utils/formInputUtils';
import { validateYield } from '../../../pages/units/validations/yieldValidation';
import { Quote } from '../../../types/api/Quote';
import { Info } from '../../info/info';
import { FormInputProps } from './formInputProps';
import { useGetYieldRoundingPrecisionInScenarioForm } from './hooks/useGetYieldRoundingPrecisionInScenarioForm';
import { ScenarioId } from '../../../types/api/PrimaryKeys';

interface AphProps extends FormInputProps {
  quote: Quote;
  scenarioId: Nullable<ScenarioId>;
  aphYield: Nullable<number>;
  allowZero?: boolean;
}

export type AphFields = {
  aphYield: number;
}

const AphInput = ({ quote, scenarioId, aphYield, sx, allowZero = false, autoFocus = false, disabled = false }: AphProps) => {
  const { control, setValue, getValues, trigger } = useFormContext();

  const aph = useAppSelector(selectOfferProducerYield);
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });

  const roundingPrecision = useGetYieldRoundingPrecisionInScenarioForm({ quote, scenarioId });

  useEffect(() => {
    const currentPractice = getValues('practiceId');

    const isThereStaleData = isApiDataStale(currentPractice, aph);
    const isLoadingSavedData = isSavedDataBeingLoaded(aphYield, practice);

    if (isThereStaleData || isLoadingSavedData || isNullOrUndefined(aph) || !quote.quickQuote) return;

    setValue('aphYield', aph);
    trigger('aphYield');
  }, [aph]);

  useEffect(() => {
    if (isUndefined(practice)) return;
    setValue('aphYield', 0);
  }, [practice]);

  return (
    <Controller
      name="aphYield"
      control={control}
      rules={{ validate: value => validateYield(value, allowZero) }}
      defaultValue={getRoundedValue(aphYield, roundingPrecision) ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          type="number"
          label="Adjusted Yield"
          fullWidth
          inputProps={{ step: 0.01, min: 0 }}
          InputProps={{
            endAdornment: <Info tooltipContent="Adjusted Yield" />,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          onChange={e => onNumericInputChange(e, field)}
          onBlur={e => onNumericInputChange(e, field, roundingPrecision)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          sx={sx}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default AphInput;
