import RpCalculationParams from '../../../types/api/calculationData/rpCalculationParams';
import { getRpCalcData } from '../../calculationData.service';
import { admDb, iceDb } from '../../../db';
import {
  getBaseRate,
  getBetaSequences,
  getComboRevenueFactors,
  getCoverageLevelDifferentials,
  getGuaranteeAdjustment,
  getHistoricalRevenueCapping, getInsuranceOfferAvailability,
  getInsuranceOfferInformation,
  getMultipleCommodityAdjustmentFactor,
  getOptionConversionFactor,
  getOptionRates, getPreviousYearYieldLimitationCode,
  getSubCountyRate,
  getSubsidyPercent,
  getUnitDiscountLevels
} from '../../localCalcDataQueries.service';
import {
  BaseRate,
  ComboRevenueFactor,
  CoverageLevelDifferential,
  OptionRate,
  ProductOfferAvailabilities, ScenarioPieceType,
  SubCountyRate,
  UnitDiscountLevel
} from '@silveus/calculations';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import { ProductOfferAvailabilityParams } from './OfferAvailability';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { RpCalculationParamsRequest } from '../../../types/api/calculationData/rpCalculationParamsRequest';

export const getRpCalcDataRequest = async (calcParamRequest: RpCalculationParamsRequest): Promise<RpCalculationParams[]> => {
  const request = () => getRpCalcData(calcParamRequest);

  const admTransactionTables = [
    admDb.comboRevenueFactors,
    admDb.subsidies,
    admDb.insuranceOffers,
    admDb.coverageLevelDifferentials,
    admDb.baseRates,
    admDb.optionRates,
    admDb.betaSequences,
    admDb.unitDiscountLevels,
    admDb.historicalRevenueCappings,
    admDb.subCountyRates,
  ];

  const iceTransactionTables = [
    iceDb.guaranteeAdjustments,
  ];

  const readTransaction = async (): Promise<RpCalculationParams[]> => {
    const wholeFarmAcreage = 0;
    const multipleCroppingCode = 'NS';
    const conservationComplianceSubsidyReductionPercent = 0;
    const premiumSurchargeApplied = 'N';

    const stateCode = calcParamRequest.countyId.substring(0, 2);

    const rpResponse: RpCalculationParams[] = [];

    const unitTransactions = calcParamRequest.distinctUnits.map(async distinctUnit => {
      const admData = await admDb.transaction('r', admTransactionTables, async () => {

        const comboRevenueFactors = getComboRevenueFactors(calcParamRequest.commodityCode, stateCode);
        const subsidyPercent = getSubsidyPercent(calcParamRequest.commodityCode, calcParamRequest.unitStructureCode, calcParamRequest.planCode, calcParamRequest.upperCoverageLevel, calcParamRequest.coverageTypeCode);

        const insuranceOffer = await getInsuranceOfferInformation(calcParamRequest.planCode, calcParamRequest.countyId, distinctUnit.typeId, distinctUnit.practiceId, calcParamRequest.coverageTypeCode);

        if (insuranceOffer === null) return null;

        const baseRate = await getBaseRate(insuranceOffer.insuranceOfferId);
        const coverageLevelDifferentials = getCoverageLevelDifferentials(insuranceOffer.insuranceOfferId, calcParamRequest.coverageTypeCode, distinctUnit.subCountyCode);
        const optionRates = getOptionRates(insuranceOffer.insuranceOfferId);
        const optionConversionFactor = getOptionConversionFactor(insuranceOffer.insuranceOfferId);
        const betaSequences = insuranceOffer.betaId === null ? Promise.resolve([]) : getBetaSequences(insuranceOffer.betaId);
        const unitDiscountLevels = insuranceOffer.unitDiscountId === null ? Promise.resolve([]) : getUnitDiscountLevels(insuranceOffer.unitDiscountId);

        const historicalRevenueCapping = getHistoricalRevenueCapping(insuranceOffer.insuranceOfferId);
        const subCountyRate = getSubCountyRate(insuranceOffer.insuranceOfferId, calcParamRequest.highRiskTypeId);

        await Promise.all([comboRevenueFactors, optionRates, optionConversionFactor, betaSequences, unitDiscountLevels, historicalRevenueCapping, subCountyRate, coverageLevelDifferentials, subsidyPercent]);

        return {
          comboRevenueFactors: await comboRevenueFactors,
          optionRates: await optionRates,
          optionConversionFactor: await optionConversionFactor,
          betaSequences: await betaSequences,
          unitDiscountLevels: await unitDiscountLevels,
          historicalRevenueCapping: await historicalRevenueCapping,
          subCountyRate: await subCountyRate,
          baseRate: baseRate,
          coverageLevelDifferentials: await coverageLevelDifferentials,
          subsidyPercent: await subsidyPercent,
          unitOfMeasure: insuranceOffer.unitOfMeasure,
        };
      });

      if (admData === null) return;

      const iceData = await iceDb.transaction('r', iceTransactionTables, async () => {
        const countyCode = calcParamRequest.countyId.substring(2, 5);
        const practiceCode = distinctUnit.practiceId.substring(4, 7);
        const typeCode = distinctUnit.typeId.substring(4, 7);

        const guaranteeAdjustment = getGuaranteeAdjustment(calcParamRequest.commodityCode, calcParamRequest.planCode, stateCode, countyCode, typeCode, practiceCode, calcParamRequest.highRiskTypeId);
        const multipleCommodityAdjustmentFactor = getMultipleCommodityAdjustmentFactor(multipleCroppingCode);
        const previousYearYieldLimitationCode = getPreviousYearYieldLimitationCode();

        await Promise.all([guaranteeAdjustment, multipleCommodityAdjustmentFactor, previousYearYieldLimitationCode]);

        return {
          guaranteeAdjustment: await guaranteeAdjustment,
          multipleCommodityAdjustmentFactor: await multipleCommodityAdjustmentFactor,
          previousYearYieldLimitationCode: await previousYearYieldLimitationCode,
        };
      });

      const rpCalculationParams: RpCalculationParams = {
        unit: distinctUnit,
        baseRate: admData.baseRate as BaseRate,
        betaSequences: admData.betaSequences,
        comboRevenueFactors: admData.comboRevenueFactors as ComboRevenueFactor[],
        ccSubsidyReductionPercent: conservationComplianceSubsidyReductionPercent,
        coverageLevelDifferentials: admData.coverageLevelDifferentials as CoverageLevelDifferential[],
        guaranteeAdjustmentFactor: iceData.guaranteeAdjustment?.fixedGuaranteeAdjustmentFactor ?? null,
        guaranteeAdjustmentTypeCode: iceData.guaranteeAdjustment?.guaranteeAdjustmentTypeCode ?? 'L',
        historicalRevenueCapping: admData.historicalRevenueCapping,
        multipleCommodityAdjustmentFactor: iceData.multipleCommodityAdjustmentFactor,
        optionConversionFactor: admData.optionConversionFactor,
        optionRates: admData.optionRates as OptionRate[],
        premiumSurchargeApplied: premiumSurchargeApplied,
        previousYearYieldLimitationCode: iceData.previousYearYieldLimitationCode,
        subCountyRate: admData.subCountyRate as SubCountyRate,
        subsidyPercent: admData.subsidyPercent,
        unitDiscountLevels: admData.unitDiscountLevels as UnitDiscountLevel[],
        unitOfMeasure: admData.unitOfMeasure,
        wholeFarmAcreage: wholeFarmAcreage,
      };

      rpResponse.push(rpCalculationParams);
    });

    await Promise.all(unitTransactions);

    return rpResponse;
  };

  const strategy = getDefaultCachingStrategy();
  return strategy(request, readTransaction);
};

export const getOfferAvailability = async (productOfferAvailabilityParams: ProductOfferAvailabilityParams): Promise<ProductOfferAvailabilities[]> => {
  const scenarioPieceTypes: ScenarioPieceType[] = [ScenarioPieceType.RP, ScenarioPieceType.RpHpe, ScenarioPieceType.YP];

  const offerAvailabilityPromises = scenarioPieceTypes.map(scenarioPieceType => {
    return getInsuranceOfferAvailability(
      productOfferAvailabilityParams.year,
      productOfferAvailabilityParams.countyId,
      productOfferAvailabilityParams.commodityCode,
      getInsurancePlanCodeForScenarioPiece(scenarioPieceType),
      scenarioPieceType);
  });

  return Promise.all(offerAvailabilityPromises);
};
