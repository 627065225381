import { ReactNode } from 'react';
import { selectFarmBillDefaultPaymentYield } from '../../../../app/privateProductSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import usePercentChange from '../../../../hooks/usePercentChange';
import { Nullable } from '../../../../types/util/Nullable';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import { FormInputProps } from '../../scenario/formInputProps';
import YieldInput from '../../scenario/yieldInput';
import { Info, InfoType } from '../../../info/info';

export interface FarmBillYieldInputProps extends FormInputProps {
  isActual: boolean;
  name: string;
  label: string;
  theYield: Nullable<number>;
  year: number;
  isArc: boolean;
  countyId: string;
  commodityCode: string;
  projectedYieldName?: string;
  actualYieldName?: string;
  practiceId: string;
}

const FarmBillYieldInput = ({
  isActual,
  label,
  name,
  theYield,
  year,
  countyId,
  commodityCode,
  sx,
  isArc,
  practiceId,
  autoFocus = false,
  disabled,
  projectedYieldName = ExtendedDataFormInputParameterNames.ExpectedYield,
  actualYieldName = ExtendedDataFormInputParameterNames.ActualYield,
}: FarmBillYieldInputProps) => {

  usePercentChange(
    projectedYieldName,
    actualYieldName,
    'farmBillYieldSlider');

  const createToolTip = (warningTextLines: string[], hintTextLines: string[], centerAllButFirst: boolean = false, usingPreviousYear: boolean = false) => {
    let addBr = false;

    const warningUILines: ReactNode[] = [];
    const allUILines: ReactNode[] = [];

    // warning
    for (const text of warningTextLines) {
      if (addBr) {
        warningUILines.push(<br />);
      }
      warningUILines.push(text);
      addBr = true;
    }

    if (warningUILines.length > 0) {
      allUILines.push(<>
        <div style={{ color: 'orange', textAlign: 'center' }}>
          {warningUILines}
        </div >
      </>);
    }

    // first line of hints
    const firstHintLine = hintTextLines.at(0);

    if (firstHintLine !== undefined) {
      if (addBr) {
        allUILines.push(<br />);
      }

      allUILines.push(<>{firstHintLine}</>);
      addBr = true;
    }

    // rest of hint lines
    const hintUILines: ReactNode[] = [];

    for (let index = 1; index < hintTextLines.length; index++) {
      if (addBr && index >= 2) {
        hintUILines.push(<br />);
      }

      hintUILines.push(<>{hintTextLines[index]}</>);
      addBr = true;
    }

    if (hintUILines.length > 0) {
      allUILines.push(<>
        <div style={{ textAlign: 'center' }}>
          {hintUILines}
        </div >
      </>);
    }

    const text = <span>{allUILines}</span>;
    return <Info tooltipContent={text} iconType={warningHintLines.length > 0 ? InfoType.Warning : InfoType.Info} />;
  };

  const { value: yieldHistory, hintLines, warningHintLines } = useAppSelector(state => selectFarmBillDefaultPaymentYield(state, year, countyId, commodityCode, practiceId, isActual, isArc));

  const toolTip = createToolTip(warningHintLines, hintLines);

  return (
    <YieldInput
      name={name}
      sx={sx}
      toolTip={toolTip}
      theYield={theYield ?? yieldHistory}
      label={label}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  );
};

export default FarmBillYieldInput;
