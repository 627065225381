import { QuoteId, ScenarioId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import ScenarioForm from './scenarioForm.component';
import ScenarioPieceFormWrapper from '../../scenarioPiece/scenarioPieceFormWrapper.component';
import TabbedFormWrapper from '../../../components/formWrapper/tabbedFormWrapper.component';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { selectScenarioById } from '../../../app/scenariosSlice';
import { closeDrawer } from '../../../app/appDrawerSlice';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import { DefaultFormSelection, FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';

export type ScenarioFormWrapperProps = {
  scenarioId: Nullable<ScenarioId>;
  quoteId: QuoteId;
  defaultFormSelection?: DefaultFormSelection;
}

const ScenarioFormWrapper = ({ scenarioId, quoteId, defaultFormSelection }: ScenarioFormWrapperProps) => {
  const forms = [
    { formName: 'Summary', formComponent: (props: FormWrapperProps) => <ScenarioForm scenarioId={scenarioId} quoteId={quoteId} key={scenarioId} {...props} /> },
    { formName: 'Scenario Pieces', formComponent: (props: FormWrapperProps) => <ScenarioPieceFormWrapper scenarioId={scenarioId} key={scenarioId} {...props} /> },
  ];

  const dispatch = useAppDispatch();

  const quote = useAppSelector(s => isNullOrUndefined(quoteId) ? null : selectQuoteById(s, quoteId));
  const scenario = useAppSelector(state => scenarioId === null ? null : selectScenarioById(state, scenarioId));
  const clientFile = useAppSelector(s => isNullOrUndefined(quote) ? null : selectClientFileById(s, quote.clientFileId));

  useEffect(() => {
    //We have a form open for a saved item that no longer exists
    if (scenarioId !== null && (scenario === null || clientFile === null || quote === null)) {
      dispatch(closeDrawer());
    }
  }, [scenarioId, scenario, clientFile, quote]);

  //The keys on each of these components are crucial. The quoteId key on the FormWrapper allows it to retain information about tab changes between renders.
  // Meanwhile, the scenarioId keys on the forms themselves allow the form instances to change when a different scenario is selected.
  // The reason a scenarioId key can't be set on the FormWrapper is because if you do that, then when you create a new scenario and use the tabs to change
  // to the scenario piece form, the whole wrapper component re-renders and it loses the fact that it was supposed to change to the scenario pieces tab.
  // This means it will keep the summary tab selected instead of switching.

  //The scenarioId keys also shouldn't be removed. Otherwise, if you have a scenario/SP form open in the drawer and click on an icon to edit a different one,
  // the form instance will remain the same, instead of changing to the form of the newly selected component.

  return (
    <TabbedFormWrapper forms={forms} defaultFormSelection={defaultFormSelection} key={quoteId} />
  );
};

export default ScenarioFormWrapper;