import { Card, CardContent, Grid, Typography } from '@mui/material';

type HailLossPercentColumnProps = {
  lossPercentages: number[];
}

const HailLossPercentColumn = ({ lossPercentages }: HailLossPercentColumnProps) => {
  return (
    <Card sx={{ height: 'fit-content', mt: 1, borderColor: 'transparent' }} raised>
      <CardContent sx={{ pb: '5px !important' }}>
        <Grid item justifyContent="center" alignItems="center" >
          <Typography fontWeight="medium" textAlign="center" marginBottom={2}>Loss %</Typography>
        </Grid>
        <Grid container direction="column" rowGap={1} >
          {lossPercentages.map((value, index) => (
            <Grid item key={index} sx={{ p: '0px !important' }} justifyContent="center" alignItems="center">
              <Typography variant="value" align="center">{value}%</Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HailLossPercentColumn;