import { buildAxis, buildDataCellResult, buildInputForGridAsync, CellTextColorSettings } from '@silveus/react-matrix';
import {
  AdjustmentType, roundToPlaces,
  ScenarioPieceResponseDTO,
  ScenarioPieceType,
  ScenarioRequestDTO
} from '@silveus/calculations';
import {
  createCellBackgroundColorProvider,
  getBottomXAxisDefinition,
  mainOverlayChildValueSelector
} from '../../../utils/matrixUtils';
import { MatrixIncludeFilterType } from '../../../types/api/enums/matrixIncludeFilterType/MatrixIncludeFilterType.enum';
import { MatrixShowFilterType } from '../../../types/api/enums/matrixShowFilterType/MatrixShowFilterType.enum';
import { createCellDataProvider } from '../../../hooks/matrix/useMatrixCellDataProvider';
import { createMatrixOverlayChildren } from '../../../hooks/matrix/useMatrixOverlayChildren';
import { IEnumAttributes } from '../../../types/api/enums/IEnumAttributes';
import { createMatrixOverlays } from '../../../hooks/matrix/useMatrixOverlays';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { MatrixOffsetType } from '../../../types/api/enums/matrixOffsetType/MatrixOffsetType.enum';
import { MatrixData } from '../../../types/api/reports/MatrixData';
import SweetSpot from '../../../types/api/SweetSpot';
import { MatrixReportSweetSpot } from '../../../types/api/reports/MatrixReportSweetSpot';
import {
  MatrixShowFilterTypeAttributes
} from '../../../types/api/enums/matrixShowFilterType/MatrixShowFilterTypeAttributes';
import MatrixPreset from '../../../types/api/MatrixPreset';
import { getBaseReportScenarioPiece } from './getBaseReportScenarioPiece';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../types/util/Nullable';
import { scenarioPieceOrderingServiceInstance } from '../../../utils/scenarioOrderingServiceWrappers';
import { ScenarioPieceId } from '../../../types/api/PrimaryKeys';
import HarvestRevenueScenarioPiece from '../../../types/api/HarvestRevenueScenarioPiece';
import { InputCostScenarioPiece } from '../../../types/api/InputCostScenarioPiece';
import ForwardSoldScenarioPiece from '../../../types/api/ForwardSoldScenarioPiece';
import ScenarioPieceClassification
  from '@silveus/calculations/dist/lookups/scenarioPieceClassification/ScenarioPieceClassification';
import {
  calculateYieldValue,
  matrixBottomAxisLabel,
  matrixReportDataColumnCount, matrixReportDataRowCount,
  matrixReportTotalColumnCount,
  matrixTopAxisLabel, midYieldPercentage, topYieldPercentage
} from '../../../pages/matrix/matrixDefaults';
import { MatrixCellData } from '../../../types/api/reports/MatrixCellData';
import { formatCurrency } from '../../../utils/formatNumbers';
import { getFriendlyScenarioPieceName } from '../../../utils/scenarioPieceUtils';

const matrixTableHeaderTextColor = '#44546A';

export const getMatrixTableData = (
  scenario: RowCropScenario | undefined,
  matrixPreset: MatrixPreset,
  matrixShowFilterType: MatrixShowFilterType,
  matrixIncludeType: MatrixIncludeFilterType,
  sweetSpots: SweetSpot[],
  rowCropScenarioPieces: RowCropScenarioPiece[],
  inputCostScenarioPiece: InputCostScenarioPiece | undefined,
  forwardSoldScenarioPiece: ForwardSoldScenarioPiece | undefined,
  harvestRevenueScenarioPiece: HarvestRevenueScenarioPiece | undefined,
  calcsByScenarioPiece: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  scenarioExpectedCountyYield: Nullable<number>,
  scenarioApprovedYield: Nullable<number>,
  scenarioAdjustedYield: Nullable<number>,
  cellData: MatrixCellData[][],
  projectedPricePrecision: number,
): MatrixData => {
  const first3SweetSpots = sweetSpots.splice(0, 3).sort((a, b) => a.sweetSpotOrder - b.sweetSpotOrder);
  const convertedSweetSpots = first3SweetSpots.map(sweetSpot => getMatrixReportSweetSpot(sweetSpot));

  //Primary Scenario Piece
  const basePieceTypes = scenarioPieceOrderingServiceInstance.getBaseScenarioPieces();
  const mpciScenarioPiece = rowCropScenarioPieces.find(sp => basePieceTypes.includes(sp.scenarioPieceType));

  if (mpciScenarioPiece === undefined) throw new Error('Missing MPCI Scenario Piece for Matrix Generation');

  const mpciScenarioPieceCalcData = calcsByScenarioPiece.get(mpciScenarioPiece.scenarioPieceId) ?? null;
  const isMpciScenarioPieceCounty = mpciScenarioPieceCalcData?.scenarioPieceClassification === ScenarioPieceClassification.County;
  const primaryScenarioPiece = getBaseReportScenarioPiece(scenario, mpciScenarioPiece, mpciScenarioPieceCalcData, scenarioExpectedCountyYield, scenarioApprovedYield, scenarioAdjustedYield);
  //Only the matrix sets this property
  primaryScenarioPiece.planAbbrev = getFriendlyScenarioPieceName(mpciScenarioPiece.scenarioPieceType);

  //Forward Sold & Input Cost Total Amount Retrieval
  const forwardSoldScenarioPieceCalcData = forwardSoldScenarioPiece !== undefined ?
    (calcsByScenarioPiece.get(forwardSoldScenarioPiece.scenarioPieceId) ?? null) : null;

  const inputCostScenarioPieceCalcData = inputCostScenarioPiece !== undefined ?
    (calcsByScenarioPiece.get(inputCostScenarioPiece.scenarioPieceId) ?? null) : null;

  //Harvest Revenue
  const harvestRevenueAveragePriceSoldTransaction = harvestRevenueScenarioPiece?.harvestRevenueTransactions
    .find(
      hrt => hrt.productionAdjustmentTypeId === AdjustmentType.Remaining &&
        hrt.priceAdjustmentTypeId === AdjustmentType.PlusMinus);
  const harvestRevenueAveragePriceSoldString = harvestRevenueAveragePriceSoldTransaction?.price.toString() ?? '';

  const matrixData: MatrixData = {
    preset: `${matrixPreset.name} - ${MatrixIncludeFilterType[matrixIncludeType]}`,
    valueType: MatrixShowFilterTypeAttributes[matrixShowFilterType].description,
    includeMatrixFooter: true, // always true
    primaryScenarioBasePlan: primaryScenarioPiece,
    projPriceFormat: `$#,##0.${'0'.repeat(projectedPricePrecision)}`,
    sweetSpots: convertedSweetSpots,
    totalForwardSold: forwardSoldScenarioPieceCalcData?.totalFixed !== undefined ? -forwardSoldScenarioPieceCalcData.totalFixed : null,
    totalInputCosts: inputCostScenarioPieceCalcData?.totalFixed !== undefined ? -inputCostScenarioPieceCalcData.totalFixed : null,
    harvestRevenueAveragePriceSold: harvestRevenueAveragePriceSoldString,
    yieldLabel: isMpciScenarioPieceCounty ? 'Trend Yield:' : 'APH Yield:',
    rowData: cellData,
  };
  return matrixData;
};

const getMatrixReportSweetSpot = (sweetSpot: SweetSpot): MatrixReportSweetSpot => {
  return {
    sweetSpotName: sweetSpot.label,
    sweetSpotColor: sweetSpot.color,
    sweetSpotText: sweetSpot.averageData?.average.toString() ?? '',
  };
};

export const buildMatrixCellDataForReports = async (
  usingExistingMatrix: boolean,
  matrixPreset: MatrixPreset,
  includedScenarios: RowCropScenario[],
  scenarioRequests: ScenarioRequestDTO[],
  includedScenarioPieces: Set<IEnumAttributes<ScenarioPieceType>>,
  xAxisMidpointOrTopYield: number,
  xAxisScale: number,
  yAxisMidpoint: number,
  yAxisScale: number,
  bottomAxisOffsetType: MatrixOffsetType,
  bottomAxisPercentChange: number,
  bottomAxisIntegerOffset: number,
  includeFilterParam: MatrixIncludeFilterType,
  showFilterParam: MatrixShowFilterType,
  cellTextColorSettings: CellTextColorSettings | null | undefined,
  sweetSpots: SweetSpot[]) => {

  const xAxisCount = matrixReportDataColumnCount;
  const yAxisCount = matrixReportDataRowCount;
  //For auto-generating the matrix defaults only returns the top-yield and doesn't give us the midpoint
  const xMidPoint = usingExistingMatrix ? xAxisMidpointOrTopYield : calculateYieldValue(xAxisMidpointOrTopYield, xAxisCount, xAxisScale, topYieldPercentage, midYieldPercentage);
  const xAxis = buildAxis({ count: xAxisCount, midpoint: xMidPoint, scale: xAxisScale });
  //We add the y axis scale once to the midpoint because there is a weird difference in the existing matrix displayed and the one generated in the report.
  const yMidpoint = usingExistingMatrix ? yAxisMidpoint + yAxisScale : yAxisMidpoint;
  const yAxis = buildAxis({ count: yAxisCount, midpoint: yMidpoint, scale: yAxisScale });

  //Automatic report generation doesn't let users change the bottom x-axis county yield offset
  const bottomXAxisDefinition = getBottomXAxisDefinition(bottomAxisOffsetType, bottomAxisPercentChange, bottomAxisIntegerOffset);
  const bottomXAxis = bottomXAxisDefinition !== undefined ? xAxis.map((topValue, index) => bottomXAxisDefinition.valueRelationToTopAxis(topValue, index, xAxisCount)) : null;

  const manualGridInput = await buildInputForGridAsync({
    topXAxis: xAxis,
    yAxis: yAxis.reverse(),
    bottomXAxis: bottomXAxis,
    cellDataProvider: createCellDataProvider(scenarioRequests, matrixPreset.selectedScenarioPieceTypes),
  });

  const calculatedCellValues = manualGridInput.map((row, rowIndex) => row.map((cell, cellIndex) => {
    const dataCellResult = buildDataCellResult({
      cellData: cell,
      matrixOverlays: createMatrixOverlays(includedScenarios),
      matrixOverlayChildren: createMatrixOverlayChildren(includedScenarioPieces, matrixPreset),
      mainOverlayChildValueSelector: c => mainOverlayChildValueSelector(includeFilterParam, showFilterParam, c),
      cellDataFormatter: value => `${roundToPlaces(value, 0)}`,
      cellTextColorSettings,
      cellBackgroundColorProvider: createCellBackgroundColorProvider(includedScenarios),
    });

    let sweetSpotColor = '';
    let isSweetSpot = false;
    for (const sweetSpot of sweetSpots) {
      if (isCellInSweetSpot(sweetSpot, cellIndex, rowIndex)) {
        sweetSpotColor = sweetSpot.color;
        isSweetSpot = true;
      }
    }

    //Calculated Cell Data
    const reportMatrixCellData: MatrixCellData = {
      cellText: dataCellResult.cellValueAsString,
      cellTextColor: dataCellResult.style.color,
      isSweetSpot: isSweetSpot,
      scenarioColor: '',
      sweetSpotColor: sweetSpotColor,
      useScenarioColor: false,
    };
    return reportMatrixCellData;
  }));

  //Price Column
  for (const row of calculatedCellValues) {
    //Add prices to the first column of every data row
    const matrixCellData: MatrixCellData = {
      cellText: formatCurrency(yAxis.shift() ?? 0),
      cellTextColor: matrixTableHeaderTextColor,
      isSweetSpot: false,
      scenarioColor: '',
      sweetSpotColor: '',
      useScenarioColor: false,
    };
    row.unshift(matrixCellData);
  }

  //Top xAxis Labels
  const xAxisTopLabelRow = buildXAxisLabelRow(matrixTopAxisLabel, xAxis);
  calculatedCellValues.unshift(xAxisTopLabelRow);

  //Bottom xAxis Labels
  const xAxisBottomLabelRow = buildXAxisLabelRow(matrixBottomAxisLabel, bottomXAxis ?? xAxis);
  calculatedCellValues.push(xAxisBottomLabelRow);

  return calculatedCellValues;
};

const buildXAxisLabelRow = (label: string, topXAxisNumbers: number[]): MatrixCellData[] => {
  const matrixHeaderRowCellData: MatrixCellData[] = [];
  for (let columnIndex = 0; columnIndex < matrixReportTotalColumnCount; columnIndex++) {
    const matrixCellData: MatrixCellData = {
      cellText: columnIndex === 0 ? label : topXAxisNumbers[columnIndex - 1].toString(),
      cellTextColor: matrixTableHeaderTextColor,
      isSweetSpot: false,
      scenarioColor: '',
      sweetSpotColor: '',
      useScenarioColor: false,
    };

    matrixHeaderRowCellData.push(matrixCellData);
  }
  return matrixHeaderRowCellData;
};

const isCellInSweetSpot = (sweetSpot: SweetSpot, cellX: number, cellY: number) => {
  // Handles sweet spots that haven't been drawn, invisible sweet spots, as well as the "summary' sweetspot, because the summary is flagged as hidden.
  if (sweetSpot.cellRange === null || sweetSpot.isHidden) return false;

  // Handles a currently-impossible future case where the user could create a visible sweet spot that covers the entire grid.
  if (sweetSpot.cellRange === 'fill-grid') return true;

  const sweetSpotStartX = sweetSpot.cellRange.start.x;
  const sweetSpotStartY = sweetSpot.cellRange.start.y;
  const sweetSpotEndX = sweetSpot.cellRange.end.x;
  const sweetSpotEndY = sweetSpot.cellRange.end.y;
  const [minX, maxX] = [Math.min(sweetSpotStartX, sweetSpotEndX), Math.max(sweetSpotStartX, sweetSpotEndX)];
  const [minY, maxY] = [Math.min(sweetSpotStartY, sweetSpotEndY), Math.max(sweetSpotStartY, sweetSpotEndY)];
  return cellX >= minX && cellX <= maxX && cellY >= minY && cellY <= maxY;
};