import { useEffect, useState } from 'react';
import { Button, Checkbox, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import { modifyMatrix } from '../../../app/matricesSlice';
import Matrix from '../../../types/api/Matrix';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { openDrawer } from '../../../app/appDrawerSlice';
import { beginLoading, endLoading } from '../../../app/loaderSlice';
import { selectCurrentClientFileId } from '../../../app/clientFilesSlice';
import { selectAllScenariosByClientFileIdMapWithUserOrder } from '../../../app/scenariosSlice';

type MatrixMenuItemProps = {
  scenarioId?: ScenarioId;
  anchor?: null | HTMLElement;
  closeMenu: () => void;
  matrix?: Matrix;
}

export const CreateMatrixFromScenariosMenu = ({ scenarioId, anchor, closeMenu, matrix }: MatrixMenuItemProps) => {
  const dispatch = useAppDispatch();
  const [selectedScenarios, setSelectedScenarios] = useState(new Set<ScenarioId>());
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const clientFileId = useAppSelector(selectCurrentClientFileId);

  const scenarios = useKeyMapSelector(selectAllScenariosByClientFileIdMapWithUserOrder, clientFileId);
  const isMenuOpen = Boolean(anchor);

  useEffect(() => {
    if (scenarioId) {
      setSelectedScenarios(new Set([scenarioId]));
      if (scenarios.length === 1) {
        setIsSelectAllChecked(true);
      }
    }
    if (!isNullOrUndefined(matrix)) {
      setSelectedScenarios(new Set(matrix.scenarioMatrices.map(x => x.scenarioId)));
      if (matrix.scenarioMatrices.length === scenarios.length) {
        setIsSelectAllChecked(true);
      }
    }
  }, [matrix, scenarioId, scenarios]);

  const onSelectAllClicked = (allShouldBeChecked: boolean) => {
    if (allShouldBeChecked) {
      setSelectedScenarios(new Set(scenarios.map(x => x.scenarioId)));
    } else {
      const primaryScenarioId = matrix?.primaryScenarioId ?? scenarioId;
      if (primaryScenarioId !== undefined) {
        //Prevent the primary scenario from being unchecked
        setSelectedScenarios(new Set([primaryScenarioId]));
      } else {
        setSelectedScenarios(new Set<ScenarioId>());
      }
    }

    setIsSelectAllChecked(allShouldBeChecked);
  };

  const isChecked = (id: ScenarioId) => {
    return selectedScenarios.has(id);
  };

  const isPrimaryScenario = (id: ScenarioId) => {
    return matrix?.primaryScenarioId === id || scenarioId === id;
  };

  const onApplyClicked = async () => {

    if (matrix === undefined) {
      if (scenarioId !== undefined) {
        dispatch(openDrawer({ formName: 'matrixForm', matrixId: null, primaryScenarioId: scenarioId, scenarioIds: Array.from(selectedScenarios), defaultFormSelection: { formName: 'Matrix' } }));
      }
    } else {
      dispatch(beginLoading());
      await dispatch(modifyMatrix({ matrixData: matrix, matrix: matrix, includedScenarios: Array.from(selectedScenarios) }));
      dispatch(endLoading());
    }

    closeMenu();
  };

  const rowClicked = (id: ScenarioId) => {
    const check = !selectedScenarios.has(id);
    const selected = new Set(selectedScenarios);
    if (check) {
      selected.add(id);
    } else {
      selected.delete(id);
    }

    setSelectedScenarios(selected);
    setIsSelectAllChecked(selected.size === scenarios.length);
  };

  return <>
    <Menu
      anchorEl={anchor}
      open={isMenuOpen}
      onClose={closeMenu}
    >
      <MenuItem onClick={() => onSelectAllClicked(!isSelectAllChecked)} sx={{ pt: 0, pb: 0 }}>
        <Checkbox checked={isSelectAllChecked} />
        <Typography variant="body1">Select All</Typography>
      </MenuItem>

      {scenarios.map(scenario => {
        const isScenarioSelected = isChecked(scenario.scenarioId);

        return (
          <MenuItem disabled={isPrimaryScenario(scenario.scenarioId)} sx={{ pt: '2px', pb: '2px', maxWidth: '350px' }} key={`${scenario.scenarioId}-menu-item`} onClick={() => rowClicked(scenario.scenarioId)}>
            <Checkbox checked={isScenarioSelected} />
            <Typography noWrap variant="body1">{scenario.name}</Typography>
          </MenuItem>
        );
      })}
      <Grid container sx={{ justifyContent: 'flex-end' }} p={1}>
        <Button id="btn-matrix-menu-cancel" variant="text" onClick={closeMenu}>Cancel</Button>
        <Button id="btn-matrix-menu-apply" variant="text" onClick={onApplyClicked}>Apply</Button>
      </Grid>
    </Menu>
  </>;
};
