import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { MenuItem, TextField } from '@mui/material';
import { AIPId } from '../../../types/api/PrimaryKeys';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectAllAIPs } from '../../../app/aipSlice';
import { validateAIP } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import { handleValidationResult } from './validationHelpers';
import { AppType } from '../../../types/api/enums/application/AppType.enum';
import { AppTask } from '../../../types/api/AppTask';
import { useEffect } from 'react';

type AIPInputProps = {
  value: Nullable<AIPId>;
  index: number;
  originalAIP: Nullable<AIPId>;
  canEditAppTask: boolean;
}

const AIPInput = ({ value, index, originalAIP, canEditAppTask }: AIPInputProps) => {
  const inputName = `appTasks.${index}.aipId`;
  const { control, setValue } = useFormContext();

  const aips = useAppSelector(selectAllAIPs);
  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });

  useEffect(() => {
    if (currentAppTask.appType === AppType.CancelTransfer && currentAppTask.aipId === currentAppTask.previousAIPId) {
      setValue(inputName, null);
    } else if (currentAppTask.appType !== AppType.CancelTransfer) {
      // If user switches from C/T app type, then we should set the AIP drop down back to
      // whatever was set on the existing app task
      setValue(inputName, currentAppTask.previousAIPId ?? originalAIP);
    }
  }, [currentAppTask.appType]);

  return (
    <Controller
      control={control}
      shouldUnregister={false}
      name={inputName}
      rules={{ validate: value => handleValidationResult(validateAIP(value)) }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          select
          disabled={(currentAppTask.appType !== AppType.CancelTransfer && currentAppTask.appType !== AppType.New) || !canEditAppTask}
          error={!!error}
          inputRef={ref}
          size="small"
          value={field.value}
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          {aips.map(aip => {
            const isAIPPreviousAIP = aip.aipId === currentAppTask.previousAIPId;
            return (
              <MenuItem disabled={isAIPPreviousAIP} sx={{ fontSize: '12px' }} key={aip.aipId} value={aip.aipId}>
                {aip.aipName}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
};

export default AIPInput;