import { average } from '../arrayUtils';

export const calculateOlympicAverage = (items: number[]): number => {
  let toAverage = [...items];
  if (toAverage.length > 2) {
    toAverage = toAverage.sort();
    toAverage = toAverage.slice(1, toAverage.length - 1);
  }

  return average(toAverage);
};