import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { beginLoading, endLoading } from '../../../app/loaderSlice';
import InsuredTaxIdInput, { InsuredTaxIdInputFields } from '../../../components/formInputs/applications/insuredTaxIdInput';
import { isNull } from '../../../utils/nullHandling';
import { Nullable } from '../../../types/util/Nullable';

export type TaxIdUpdateModalProps = {
  onClose: () => void;
  name: Nullable<string>;
  updateTaxId: (newTaxId: string) => Promise<void>;
  taxId: Nullable<string>;
}

const TaxIdUpdateModal = ({ onClose, name, updateTaxId, taxId }: TaxIdUpdateModalProps) => {
  const dispatch = useAppDispatch();
  const methods = useForm<InsuredTaxIdInputFields>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<InsuredTaxIdInputFields> = async data => {
    try {
      dispatch(beginLoading());
      if (isNull(data.taxId)) throw new Error('Tax ID cannot be null');
      if (data.taxId !== taxId) {
        await updateTaxId(data.taxId);
      }
      onClose();
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <DialogTitle>Update Tax ID for {name}</DialogTitle>
      <DialogContent>
        <DialogContentText mb={1.5}>
          This update should only be used to correct the Tax ID, not change the Tax ID to another Entity.
        </DialogContentText>
        <FormProvider {...methods}>
          <form id="form-update-tax-id" onSubmit={methods.handleSubmit(onSubmit)}>
            <InsuredTaxIdInput value={taxId} disabled={false} masked={false} />
            <Grid container columnGap={1} mt={1.5} justifyContent="flex-end">
              <Grid item><Button onClick={onClose} id="btn-cancel" variant="outlined">Cancel</Button></Grid>
              <Grid item><Button type="submit" id="btn-save" variant="contained">Save</Button></Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default TaxIdUpdateModal;