import {
  IceCalculationParams,
  IceCalculationRateParams
} from '../../../types/api/calculationData/iceCalculationParams';
import { getIceCalcData } from '../../calculationData.service';
import { privateProductsDb } from '../../../db';
import { safeWhere } from '../../../utils/dexieQueryHelpers/whereClauses';
import { Nullable } from '../../../types/util/Nullable';
import {
  ProductOfferAvailabilities,
  roundToPlaces,
  ScenarioPieceType,
  UnitStructureAttributes,
  UnitStructureCode
} from '@silveus/calculations';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import { IceRateParams } from '../../../types/api/adm/PrivateProductsParams';
import IceRate from '../../../types/api/adm/IceRate';
import { getIceAcreCodes, getIceDeductibleSelections, getIceRates } from '../../privateProducts.service';
import { IceDeductibleSelection } from '../../../types/api/adm/iceDeductibleSelection';
import { IceAcresCode } from '../../../types/api/adm/IceAcreCode';
import { ProductOfferAvailabilityParams } from './OfferAvailability';
import { safeGet } from '../../../utils/dexieQueryHelpers/getClauses';
import { IceCalculationParamsRequest } from '../../../types/api/calculationData/iceCalculationParamsRequest';

export const getIceCalcDataRequest = async (calcParamRequest: IceCalculationParamsRequest): Promise<IceCalculationParams[]> => {
  const request = () => getIceCalcData(calcParamRequest);

  const transactionTables = [
    privateProductsDb.iceRates,
    privateProductsDb.iceProducts,
    privateProductsDb.iceAcresCodes,
  ];

  const readTransaction = async (): Promise<IceCalculationParams[]> => {
    const iceDeductibleNoneValue = 1;
    const iceDeductibleSelection = calcParamRequest.iceDeductibleSelection ?? iceDeductibleNoneValue;

    const iceResults = await privateProductsDb.transaction('r', transactionTables, async () => {
      const iceProducts = await safeWhere(privateProductsDb.iceProducts, { harvestGuardScenarioPieceTypeId: calcParamRequest.scenarioPieceTypeId }).toArray();
      const iceProductIds = iceProducts.map(ip => ip.iceProductId);

      const iceRates = await safeWhere(privateProductsDb.iceRates, {
        cropYear: calcParamRequest.year,
        countyId: calcParamRequest.countyId,
        commodityCode: calcParamRequest.commodityCode,
        iceDeductibleSelectionId: iceDeductibleSelection,
      })
        .and(ir => iceProductIds.includes(ir.iceProductId))
        .toArray();

      const acresCodeIds = iceRates.map(ir => ir.acresCodeId);

      const iceAcresCodes = await safeWhere(privateProductsDb.iceAcresCodes, 'acresCodeID')
        .anyOf(acresCodeIds)
        .toArray();

      const iceParamData: { rate: number, minAcres: Nullable<number>, maxAcresExclusive: Nullable<number>, unitStructure: string }[] = [];

      iceRates.forEach(iceRate => {
        const matchingAcresCode = iceAcresCodes.find(acresCode => acresCode.acresCodeID === iceRate.acresCodeId);

        if (matchingAcresCode === undefined) return;

        iceParamData.push({
          rate: iceRate.rate,
          minAcres: matchingAcresCode.minAcres,
          maxAcresExclusive: matchingAcresCode.maxAcresExclusive,
          unitStructure: iceRate.unitStructure,
        });
      });

      return iceParamData;
    });

    const iceRateResults: IceCalculationParams[] = [];

    calcParamRequest.distinctUnits.forEach(distinctUnit => {
      const iceCalcParams: IceCalculationParams = {
        unit: distinctUnit,
        rates: iceResults.map(iceResult => {
          const unitStructure = Object.values(UnitStructureAttributes).find(usa => usa.name === iceResult.unitStructure)?.value ?? UnitStructureCode.Unset;

          const iceCalculationRateParams: IceCalculationRateParams = {
            rate: roundToPlaces(iceResult.rate / 100, 4),
            unitStructure: unitStructure,
            minAcres: iceResult.minAcres,
            maxAcresExclusive: iceResult.maxAcresExclusive,
          };

          return iceCalculationRateParams;
        }),
      };

      iceRateResults.push(iceCalcParams);
    });

    return iceRateResults;
  };

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getIceRatesRequest = async (privateProductsParams: IceRateParams): Promise<IceRate[]> => {
  const request = () => getIceRates(privateProductsParams);

  const transactionTables = [
    privateProductsDb.iceProducts,
    privateProductsDb.iceRates,
  ];

  const readTransaction = () => privateProductsDb.transaction('r', transactionTables, async () => {
    const rateData = await safeWhere(privateProductsDb.iceRates, {
      cropYear: privateProductsParams.year,
      countyId: privateProductsParams.countyId,
      commodityCode: privateProductsParams.commodityCode,
    }).toArray();

    const iceProductIds = rateData.map(rd => rd.iceProductId);

    const iceProducts = await safeWhere(privateProductsDb.iceProducts, 'iceProductId').anyOf(iceProductIds).toArray();

    const iceRates: IceRate[] = [];

    rateData.forEach(rate => {
      const applicableIceProduct = iceProducts.find(ip => ip.iceProductId === rate.iceProductId);

      if (applicableIceProduct === undefined) return;

      const iceRate: IceRate = {
        iceRatesID: rate.iceRatesId,
        acresCodeID: rate.acresCodeId,
        lowerCoverageLevel: applicableIceProduct.lowerCoverageLevel,
        upperCoverageLevel: applicableIceProduct.upperCoverageLevel,
        maximumAllowableGap: applicableIceProduct.maximumAllowableGap,
        iceDeductibleSelectionID: rate.iceDeductibleSelectionId,
        iceScenarioPieceType: applicableIceProduct.harvestGuardScenarioPieceTypeId as ScenarioPieceType,
        unitStructure: Object.values(UnitStructureAttributes).find(usa => usa.name === rate.unitStructure)?.value ?? UnitStructureCode.Unset,
      };

      iceRates.push(iceRate);
    });

    return iceRates;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getIceDeductibleSelectionsRequest = async (): Promise<IceDeductibleSelection[]> => {
  const request = () => getIceDeductibleSelections();

  const readTransaction = () => privateProductsDb.transaction('r', privateProductsDb.iceDeductibleSelections, async () => {
    // excluding 2 because proportional is currently not supported
    return safeWhere(privateProductsDb.iceDeductibleSelections, 'iceDeductibleSelectionID').notEqual(2).toArray();
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getIceAcreCodesRequest = async (): Promise<IceAcresCode[]> => {
  const request = () => getIceAcreCodes();

  const readTransaction = () => privateProductsDb.transaction('r', privateProductsDb.iceAcresCodes, async () => {
    return privateProductsDb.iceAcresCodes.toArray();
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getOfferAvailability = async (productOfferAvailabilityParams: ProductOfferAvailabilityParams): Promise<ProductOfferAvailabilities[]> => {
  const scenarioPieceTypes: ScenarioPieceType[] = [
    ScenarioPieceType.IceStack590,
    ScenarioPieceType.IceStack595,
    ScenarioPieceType.IceStack1090,
    ScenarioPieceType.IceStack1095,
    ScenarioPieceType.IceStack1595,
    ScenarioPieceType.IceShield1090,
    ScenarioPieceType.IceShield1095,
  ];

  const iceRate = await privateProductsDb.transaction('r', privateProductsDb.iceRates, async () => {
    return safeGet(privateProductsDb.iceRates, {
      cropYear: productOfferAvailabilityParams.year,
      countyId: productOfferAvailabilityParams.countyId,
      commodityCode: productOfferAvailabilityParams.commodityCode,
    });
  });

  const offerAvailabilities: { practiceId: Nullable<string>, typeId: Nullable<string>, cropYear: Nullable<number> }[] = [];
  if (iceRate !== undefined) offerAvailabilities.push({ practiceId: null, typeId: null, cropYear: null });

  const offerAvailabilityPromises = scenarioPieceTypes.map(scenarioPieceType => {
    const productOfferAvailability: ProductOfferAvailabilities = {
      scenarioPieceType: scenarioPieceType,
      productOfferAvailabilities: offerAvailabilities.map(io => ({ typeId: io.typeId, practiceId: io.practiceId, cropYear: io.cropYear, extendedData: null })),
    };

    return productOfferAvailability;
  });

  return Promise.all(offerAvailabilityPromises);
};
