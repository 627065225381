import { roundToPlaces } from '@silveus/calculations';
import { selectUniqueCoverageLevelPairings } from '../../../app/admSlice';
import { CoverageLevelPair } from '../../../types/api/adm/CoverageLevelPair';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { UpperCoverageLevelInput, UpperCoverageLevelProps } from './upperCoverageLevelInput.component';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { FormInputParameterNames } from '../constants/inputConstants';
import { isNotNilOrEmpty, isNotNullOrUndefined } from '../../../utils/nullHandling';
import { stableEmptyArrayAsMutable } from '../../../utils/stableEmptyArray';

export interface AdmUpperCoverageLevelProps extends UpperCoverageLevelProps {
  label?: string;
  fieldName?: string;
  lowestAvailableCoverageLevel?: number | null;
}
export const AdmUpperCoverageLevelInput = ({
  upperCoverageLevel,
  fieldName = FormInputParameterNames.UpperCoverageLevelName,
  label = 'Upper Coverage Level',
  lowestAvailableCoverageLevel = null,
  autoFocus = false,
  disabled = false,
  setAreUpperCoverageLevelsLoaded }: AdmUpperCoverageLevelProps) => {

  const { control, getValues } = useFormContext();
  const coverageLevelPairs = useAppSelector(selectUniqueCoverageLevelPairings);

  const lowerCoverage = getValues(FormInputParameterNames.LowerCoverageLevelName);
  const selectedLowerCoverageLevel = useWatch({ name: FormInputParameterNames.LowerCoverageLevelName, control: control, defaultValue: undefined });

  const [useableCoverageLevelPairings, setUseableCoverageLevelPairings] = useState<CoverageLevelPair[]>([]);

  useEffect(() => {
    let useablePairings: CoverageLevelPair[];
    const normalizedPairings: CoverageLevelPair[] = coverageLevelPairs.map(x => {
      return {
        lowerCoverageLevel: roundToPlaces(x.lowerCoverageLevel * 100, 0),
        upperCoverageLevel: roundToPlaces(x.upperCoverageLevel * 100, 0),
      };
    });

    const validLowerLimit = isNotNullOrUndefined(lowestAvailableCoverageLevel);
    const validLower =
      (isNotNullOrUndefined(lowerCoverage) && isNotNilOrEmpty(lowerCoverage))
      &&
      (
        !validLowerLimit
        ||
        (
          normalizedPairings.find(x => x.lowerCoverageLevel === lowerCoverage && x.upperCoverageLevel >= lowestAvailableCoverageLevel) !== undefined
          && lowerCoverage >= lowestAvailableCoverageLevel
        )
      );

    if (validLower) {
      useablePairings = normalizedPairings.filter(x => x.lowerCoverageLevel === lowerCoverage && x.upperCoverageLevel >= lowerCoverage);
    } else {
      useablePairings = normalizedPairings;
    }

    if (validLowerLimit) {
      useablePairings = useablePairings.filter(x => x.lowerCoverageLevel >= lowestAvailableCoverageLevel && x.upperCoverageLevel >= lowestAvailableCoverageLevel);
    }

    useablePairings.sort((a, b) => {
      if (a.upperCoverageLevel === b.upperCoverageLevel) {
        return b.lowerCoverageLevel - a.lowerCoverageLevel;
      }
      return b.upperCoverageLevel - a.upperCoverageLevel;
    });

    setUseableCoverageLevelPairings(useablePairings);
  }, [lowestAvailableCoverageLevel, selectedLowerCoverageLevel, lowerCoverage, upperCoverageLevel, coverageLevelPairs]);

  return (<UpperCoverageLevelInput
    fieldName={fieldName}
    label={label}
    upperCoverageLevel={upperCoverageLevel}
    autoFocus={autoFocus}
    disabled={disabled}
    availableCoverageLevels={stableEmptyArrayAsMutable()}
    coverageLevelPairings={useableCoverageLevelPairings}
    setAreUpperCoverageLevelsLoaded={setAreUpperCoverageLevelsLoaded} />);
};

