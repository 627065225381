import { Box, ThemeProvider, createTheme, useTheme } from '@mui/material';
import { selectAppDrawerState, PossibleDrawer, selectAppDrawerWidth } from '../../app/appDrawerSlice';
import SlidingDrawer from '../slidingDrawer/slidingDrawer.component';
import ScenarioFormWrapper from '../../pages/scenario/components/scenarioFormWrapper.component';
import UnitFormWrapper from '../../pages/units/components/unitFormWrapper.component';
import { useAppSelector } from '../../hooks/reduxHooks';
import QuoteForm from '../../pages/quote/components/quoteForm.component';
import ClientFileForm from '../../pages/clientFile/component/clientFileForm.component';
import TabbedFormWrapper from '../formWrapper/tabbedFormWrapper.component';
import HistoricalFormWrapper from '../../pages/scenarioAnalysis/historicalAnalysis/historicalFormWrapper';
import PremiumBreakdownForm from '../../pages/scenarioAnalysis/premiumBreakdown/premiumBreakdownForm.component';
import { selectIsLightMode } from '../../app/userSettingsSlice';
import { DrawerZoomInput } from '../applicationZoomInput/DrawerZoomInput';
import { FormWrapperProps } from '../formWrapper/formWrapper.component';
import MatrixFormWrapper from '../../pages/matrix/matrixFormWrapper.component';

const AppDrawer = () => {
  const isLightMode = useAppSelector(selectIsLightMode);
  const drawerState = useAppSelector(selectAppDrawerState);
  const drawerWidth = useAppSelector(selectAppDrawerWidth);
  const theme = useTheme();

  const renderDrawerForState = (drawerSpecificState: PossibleDrawer) => {
    switch (drawerSpecificState.formName) {
      case 'clientFileForm': return <TabbedFormWrapper key="clientFormFileWrapper" forms={[{ formName: 'Client File', formComponent: (props: FormWrapperProps) => <ClientFileForm clientFileId={drawerSpecificState.clientFileId} clientFileOwnership={drawerSpecificState.clientFileOwnership} key={drawerSpecificState.clientFileId} {...props} /> }]} />;
      case 'quoteForm': return <TabbedFormWrapper key="quoteFormWrapper" forms={[{ formName: 'Quote', formComponent: (props: FormWrapperProps) => <QuoteForm quoteId={drawerSpecificState.quoteId} clientFileId={drawerSpecificState.clientFileId} key={drawerSpecificState.quoteId} {...props} /> }]} />;
      case 'unitForm': return <UnitFormWrapper unitYearId={drawerSpecificState.unitYearId} insuredId={drawerSpecificState.insuredId} defaultFormSelection={drawerSpecificState.defaultFormSelection} />;

      case 'scenarioForm': return <ScenarioFormWrapper scenarioId={drawerSpecificState.scenarioId} quoteId={drawerSpecificState.quoteId} defaultFormSelection={{ formName: 'Summary', id: drawerSpecificState.scenarioId }} />;
      case 'scenarioPieceForm': return <ScenarioFormWrapper scenarioId={drawerSpecificState.scenarioId} quoteId={drawerSpecificState.quoteId} defaultFormSelection={{ formName: 'Scenario Pieces', id: drawerSpecificState.scenarioId, defaultFormSelection: drawerSpecificState.defaultFormSelection }} />;

      case 'matrixForm': return <MatrixFormWrapper matrixId={drawerSpecificState.matrixId} primaryScenarioId={drawerSpecificState.primaryScenarioId} scenarioIds={drawerSpecificState.scenarioIds} defaultFormSelection={{ formName: 'Matrix', id: drawerSpecificState.matrixId }} />;
      case 'matrixPresetsForm': return <MatrixFormWrapper matrixId={drawerSpecificState.matrixId} primaryScenarioId={drawerSpecificState.primaryScenarioId} scenarioIds={drawerSpecificState.scenarioIds} defaultFormSelection={{ formName: 'Matrix Presets', id: drawerSpecificState.matrixId }} />;
      case 'historicalQuoteForm': return <HistoricalFormWrapper defaultFormSelection={{ formName: 'Settings', id: drawerSpecificState.primaryScenarioId, defaultFormSelection: drawerSpecificState.defaultFormSelection }} primaryScenarioId={drawerSpecificState.primaryScenarioId} quoteId={drawerSpecificState.quoteId} />;
      case 'premiumBreakdownForm': return <TabbedFormWrapper key="premiumBreakdownFormWrapper" forms={[{ formName: 'PremiumBreakdown', formComponent: (props: FormWrapperProps) => <PremiumBreakdownForm {...props} /> }]} />;
    }
  };

  const appDrawerTheme = createTheme({
    ...theme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: 'transparent',
            fontSize: '0.875rem',
            color: theme.palette.other.grey,
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }),
        },
      },
      MuiTab: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.Mui-selected:after': {
              content: '\'\'',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              background: theme.palette.primary.main,
              height: '2px',
              borderRadius: '10px',
            },
          }),
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            '& .MuiPaper-root': {
              zIndex: theme.zIndex.drawer + 1,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: () => ({
            fontSize: '0.8125rem',
            fontWeight: 400,
          }),
          containedPrimary: ({ theme }) => ({
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.dark,
            '&:hover': {
              backgroundColor: isLightMode ? '#CCCCCC' : '#111111',
            },
          }),
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: () => ({
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: isLightMode ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255,255,255,0.90)',
            },
          }),
        },
      },
    },
  });

  return (
    <ThemeProvider theme={appDrawerTheme}>
      <SlidingDrawer
        open={drawerState.isOpen}
        anchor="right"
        variant="permanent"
        minWidth={0}
        maxWidth={drawerWidth}>
        {drawerState.openDrawer !== null &&
          <>
            <Box className="scroll-container" sx={{ flexDirection: 'column', p: 0 }}>
              {renderDrawerForState(drawerState.openDrawer)}
            </Box>
            <Box p={1} pl={2} >
              <DrawerZoomInput />
            </Box>
          </>
        }
      </SlidingDrawer>
    </ThemeProvider >
  );
};

export default AppDrawer;
