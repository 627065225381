import { Theme } from '@mui/material';

export const formatCurrency = (value: number, showDigits = true) => {
  if (!value) {
    value = 0;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: showDigits ? 2 : 0, // (causes 2500.99 to be printed as $2,501)
  });

  const formattedNumber = formatter.format(Math.abs(value));

  return value < 0 ? `(${formattedNumber})` : formattedNumber;
};

export const formatPercentDigits = (value: number, numberOfDigits: number = 0) => {
  if (!value) {
    value = 0;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: numberOfDigits,
  });

  return formatter.format(value);
};

export const formatNumber = (value: number, showDigits = false) => {
  return formatNumberDigits(value, showDigits ? 2 : 0, true);
};

export const formatNumberDigits = (value: number, numberOfDigits: number = 0, parenthesisIfNegative: boolean = false) => {
  if (!value) {
    value = 0;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: numberOfDigits,
  });

  if (parenthesisIfNegative && value < 0) {
    const formattedNumber = formatter.format(Math.abs(value));
    return `(${formattedNumber})`;
  }

  return formatter.format(value);
};

export const getColor = (value: number, theme: Theme) => {
  return value < 0 ? theme.palette.other.negative : theme.palette.other.positive;
};