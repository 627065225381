import axios from 'axios';
import { ClientFileId, QuoteId } from '../types/api/PrimaryKeys';
import { Quote } from '../types/api/Quote';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { envBaseApiUrl } from '../constants/envConstants';

export const getQuotes = (clientFileId: ClientFileId) => axios.get<Quote[]>(`${envBaseApiUrl}/quotes/clientfiles/${clientFileId}/`);

export const getQuote = (quoteId: string) => axios.get<Quote>(`${envBaseApiUrl}/quotes/${quoteId}`);

export const createQuote = (quote: Quote) => axios.post<CreatedItemResult<QuoteId>>(`${envBaseApiUrl}/quotes/`, quote);

export const updateQuote = (quote: Quote) => axios.put<void>(`${envBaseApiUrl}/quotes/`, quote);

export const updateQuotes = (quotes: Quote[]) => axios.put<void>(`${envBaseApiUrl}/quotes/batch`, quotes);

export const deleteQuote = (quoteId: string) => axios.delete<void>(`${envBaseApiUrl}/quotes/${quoteId}`);
