import { InsuredAddress } from '../types/api/insureds/Insured';
import { isNilOrEmpty, isNullOrUndefined } from './nullHandling';
import { Nullable } from '../types/util/Nullable';

export const formatCityStateZip = (address: Nullable<InsuredAddress>): string => {
  if (isNullOrUndefined(address)) {
    return '';
  }

  let formattedAddress = '';

  if (!isNilOrEmpty(address.city)) formattedAddress = formattedAddress.concat(`${address.city}, `);
  if (!isNilOrEmpty(address.state)) formattedAddress = formattedAddress.concat(`${address.state}`);
  if (!isNilOrEmpty(address.postalCode)) formattedAddress = formattedAddress.concat(` ${address.postalCode}`);

  return formattedAddress.trim();
};