import { ScenarioId } from '../types/api/PrimaryKeys';
import { useAppSelector } from './reduxHooks';
import {
  selectScenarioPieceCalculationsForScenario
} from '../app/calculationResultsSlice';
import ScenarioPieceClassification from '@silveus/calculations/dist/lookups/scenarioPieceClassification/ScenarioPieceClassification';
import { ScenarioPieceResponseDTO, roundToPlaces, sum } from '@silveus/calculations';

export interface PremiumBreakdownSummary {
  producerPremium: number;
  producerPremiumPerAcre: number;
  totalPremium: number;
  totalPremiumPerAcre: number;
  coverage: number;
  totalCoverage: number;
  subsidy: number;
  totalSubsidy: number;
  subsidyPercent: number;
}

export enum PremiumBreakdownSummaryTypes {
  individual = 'Individual',
  county = 'County',
  combined = 'Combined',
}


export const usePremiumBreakdownSummary = (scenarioId: ScenarioId): Map<PremiumBreakdownSummaryTypes, PremiumBreakdownSummary> => {
  const scenarioPieceCalcResults = useAppSelector(state => selectScenarioPieceCalculationsForScenario(state, scenarioId));
  return calculatePremiumBreakdownSummary(scenarioPieceCalcResults);
};

export const calculatePremiumBreakdownSummary = (scenarioPieceCalcResults: ScenarioPieceResponseDTO[]) => {
  const individualGrouping = scenarioPieceCalcResults.filter(x => x.scenarioPieceClassification === ScenarioPieceClassification.Individual);
  const countyGrouping = scenarioPieceCalcResults.filter(x => x.scenarioPieceClassification === ScenarioPieceClassification.County);
  const combinedGrouping = scenarioPieceCalcResults;
  const results = new Map<PremiumBreakdownSummaryTypes, PremiumBreakdownSummary>();

  results.set(PremiumBreakdownSummaryTypes.individual, calculatePremiumBreakdownSummaryForGroup(individualGrouping));
  results.set(PremiumBreakdownSummaryTypes.county, calculatePremiumBreakdownSummaryForGroup(countyGrouping));
  results.set(PremiumBreakdownSummaryTypes.combined, calculateCombinedPremiumBreakdownSummaryForGroup(combinedGrouping));

  return results;
};

const calculatePremiumBreakdownSummaryForGroup = (grouping: ScenarioPieceResponseDTO[]): PremiumBreakdownSummary => {
  const producerPremium = sum(grouping, x => x.producerPremium);
  const producerPremiumPerAcre = sum(grouping, x => x.producerPremiumPerAcre);
  const totalPremium = sum(grouping, x => x.totalPremium);
  const totalPremiumPerAcre = sum(grouping, x => x.totalPremiumPerAcre);

  const subsidy = sum(grouping, x => x.subsidyAmountPerAcre);
  const totalSubsidy = sum(grouping, x => x.subsidyAmount);
  const subsidyPercent = totalPremiumPerAcre > 0 ? roundToPlaces(subsidy / totalPremiumPerAcre * 100, 0) : 0;

  const coverage = sum(grouping, x => x.liabilityPerAcre);
  const totalCoverage = sum(grouping, x => x.liabilityAmount);

  return {
    producerPremium: producerPremium,
    producerPremiumPerAcre: producerPremiumPerAcre,
    totalPremium: totalPremium,
    totalPremiumPerAcre: totalPremiumPerAcre,
    coverage: coverage,
    totalCoverage: totalCoverage,
    subsidy: subsidy,
    totalSubsidy: totalSubsidy,
    subsidyPercent: subsidyPercent,
  };
};

const calculateCombinedPremiumBreakdownSummaryForGroup = (grouping: ScenarioPieceResponseDTO[]): PremiumBreakdownSummary => {
  const producerPremium = sum(grouping, x => x.producerPremium);
  const producerPremiumPerAcre = sum(grouping, x => x.producerPremiumPerAcre);
  const totalPremium = sum(grouping, x => x.totalPremium);
  const totalPremiumPerAcre = sum(grouping, x => x.totalPremiumPerAcre);

  const subsidy = sum(grouping, x => x.subsidyAmountPerAcre);
  const totalSubsidy = sum(grouping, x => x.subsidyAmount);
  const subsidyPercent = totalPremiumPerAcre > 0 ? roundToPlaces(subsidy / totalPremiumPerAcre * 100, 0) : 0;

  const coverage = sum(grouping, x => x.combinedLiabilityPerAcre);
  const totalCoverage = sum(grouping, x => x.totalCombinedLiability);

  return {
    producerPremium: producerPremium,
    producerPremiumPerAcre: producerPremiumPerAcre,
    totalPremium: totalPremium,
    totalPremiumPerAcre: totalPremiumPerAcre,
    coverage: coverage,
    totalCoverage: totalCoverage,
    subsidy: subsidy,
    totalSubsidy: totalSubsidy,
    subsidyPercent: subsidyPercent,
  };
};