import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { TextField } from '@mui/material';
import { townshipMaxLength, validateTownship } from '../../../pages/units/validations/townshipValidation';

interface TownshipProps {
  township: Nullable<string>;
  autoFocus?: boolean;
}

export type TownshipFields = {
  township: Nullable<string>;
}

const TownshipInput = ({ township, autoFocus = false }: TownshipProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="township"
      control={control}
      rules={{ validate: validateTownship }}
      defaultValue={township}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Township"
          fullWidth
          inputProps={{ maxLength: townshipMaxLength }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default TownshipInput;
