import axios from 'axios';
import { ADMYear } from '../types/api/adm/ADMYear';
import { InsuranceOfferParams } from '../types/api/adm/InsuranceOfferParams';
import AvailableInsuranceOfferSelections from '../types/api/adm/AvailableInsuranceOfferSelections';
import HistoricalPrice from '../types/api/adm/HistoricalPrice';
import HistoricalYieldTrendYear from '../types/api/adm/HistoricalYieldTrendYear';
import TYield from '../types/api/adm/TYield';
import { Nullable } from '../types/util/Nullable';
import { ScenarioPricesAndYieldsParams } from '../types/api/adm/ScenarioPricesAndYieldsParams';
import ScenarioPricesAndYields from '../types/api/adm/ScenarioPricesAndYields';
import AdmDataForQuoteParams from '../types/api/adm/AdmDataForQuoteParams';
import QuoteProductOfferAvailabilities from '../types/api/adm/QuoteProductOfferAvailabilities';
import QuoteHistoricalTYields from '../types/api/adm/QuoteHistoricalTYields';
import QuoteYeYears from '../types/api/adm/QuoteYeYears';
import QuoteTrendAdjustmentFactors from '../types/api/adm/QuoteTrendAdjustmentFactors';
import QuotePriceGroupMembers from '../types/api/adm/QuotePriceGroupMembers';
import QuoteHistoricalYieldTrends from '../types/api/adm/QuoteHistoricalYieldTrends';
import QuoteHistoricalStormEvents from '../types/api/adm/QuoteHistoricalStormEvents';
import QuoteInsuranceCalendars from '../types/api/adm/QuoteInsuranceCalendars';
import { envBaseApiUrl } from '../constants/envConstants';
import PriceGroup from '../types/api/adm/PriceGroup';
import { PriceGroupId } from '../types/api/PrimaryKeys';
import RMAPriceDiscovery from '../types/api/adm/RMAPriceDiscovery';
import { CeppMappings } from '../types/api/adm/CeppMappings';
import YieldInfoWithCounties from '../types/api/adm/YieldInfoWithCounties';
import YieldInfoWithCountiesParams from '../types/api/adm/YieldInfoWithCountiesParams';
import QuoteHistoricalInputCosts from '../types/api/adm/QuoteHistoricalInputCosts';
import InsuranceOffer from '../types/api/adm/InsuranceOffer';
import { GetInsuranceOfferParams } from '../types/api/adm/GetInsuranceOfferParams';
import { MYAPriceHistory } from '../types/api/adm/MYAPriceHistory';
import QuoteAvailableOptionSelections from '../types/api/adm/QuoteAvailableOptionSelections';

export const getYears = () => axios.get<ADMYear[]>(`${envBaseApiUrl}/years`, { headers: { 'Cache-Control': 'no-cache' } });

export const getAvailableInsuranceOfferSelections = (insuranceOfferParams: InsuranceOfferParams) => axios.get<Nullable<AvailableInsuranceOfferSelections>>(`${envBaseApiUrl}/offers`, { params: insuranceOfferParams });

export const getScenarioPricesAndYields = (scenarioPricesAndYieldsParams: ScenarioPricesAndYieldsParams) => axios.post<Nullable<ScenarioPricesAndYields>>(`${envBaseApiUrl}/offers/prices-and-yields`, scenarioPricesAndYieldsParams);

export const getAvailableSubCountyCodes = (year: number, countyId: string, typeId: string, practiceId: string) => axios.get<string[]>(`${envBaseApiUrl}/offers/sub-counties`, { params: { year: year, countyId: countyId, typeId: typeId, practiceId: practiceId } });

export const getAvailableInsurancePlanCodes = (year: number, countyId: string, typeId: string, practiceId: string) => axios.get<string[]>(`${envBaseApiUrl}/offers/plan-codes`, { params: { year, countyId, typeId, practiceId } });

export const getTYields = (year: number, countyId: string, commodityCode: string) => axios.get<TYield[]>(`${envBaseApiUrl}/offers/t-yields`, { params: { year: year, countyId: countyId, commodityCode: commodityCode } });

export const getAvailableHistoricalTYields = (admDataParams: AdmDataForQuoteParams[]) => axios.post<QuoteHistoricalTYields[]>(`${envBaseApiUrl}/offers/historical-t-yields`, admDataParams);

export const getInsuranceCalendars = (insuranceCalendarsParams: AdmDataForQuoteParams[]) => axios.post<QuoteInsuranceCalendars[]>(`${envBaseApiUrl}/offers/insurance-calendars`, insuranceCalendarsParams);

export const getInsuranceOfferInformation = (insuranceOfferParams: GetInsuranceOfferParams) => axios.post<InsuranceOffer>(`${envBaseApiUrl}/offers/insurance-offer-information`, insuranceOfferParams);

export const getAvailableYeYears = (admDataParams: AdmDataForQuoteParams[]) => axios.post<QuoteYeYears[]>(`${envBaseApiUrl}/offers/yeYears`, admDataParams);

export const getTrendAdjustmentFactors = (admDataParams: AdmDataForQuoteParams[]) => axios.post<QuoteTrendAdjustmentFactors[]>(`${envBaseApiUrl}/offers/trend-adjustment-factors`, admDataParams);

export const getOfferAvailabilitiesForQuote = (admDataParams: AdmDataForQuoteParams[]) => axios.post<QuoteProductOfferAvailabilities[]>(`${envBaseApiUrl}/offers/product-availability`, admDataParams);

export const getHistoricalPrices = (year: number, priceGroupIds: number[]) => axios.post<HistoricalPrice[]>(`${envBaseApiUrl}/prices/price-history`, { priceGroupIds, year });

export const getHistoricalInputCosts = (admDataParams: AdmDataForQuoteParams[]) => axios.post<QuoteHistoricalInputCosts[]>(`${envBaseApiUrl}/prices/input-cost-history`, admDataParams);

export const getPriceGroupMembers = (admDataParams: AdmDataForQuoteParams[]) => axios.post<QuotePriceGroupMembers[]>(`${envBaseApiUrl}/prices/price-group-members`, admDataParams);

export const getPriceGroups = (year: number, priceGroupIds: PriceGroupId[]) => axios.post<PriceGroup[]>(`${envBaseApiUrl}/prices/price-groups`, { year, priceGroupIds });

export const getPriceDiscoveries = (year: number, commodityCodes: string[]) => axios.post<RMAPriceDiscovery[]>(`${envBaseApiUrl}/prices/prices-by-year-commodities`, commodityCodes, { params: { year } });

export const getCeppMappings = () => axios.get<CeppMappings>(`${envBaseApiUrl}/prices/cepp-mappings`);

export const getHistoricalYieldTrends = (admDataParams: AdmDataForQuoteParams[]) => axios.post<QuoteHistoricalYieldTrends[]>(`${envBaseApiUrl}/offers/historical-yield-trends`, admDataParams);

export const getHistoricalYieldTrendYears = (year: number, historicalYieldTrendIds: number[]) => axios.post<HistoricalYieldTrendYear[]>(`${envBaseApiUrl}/offers/historical-yield-trend-years`, { year, historicalYieldTrendIds });

export const getHipHurricaneEvent = (year: number, countyId: string, typeId: string, practiceId: string, insurancePlanCode: string) => axios.get<boolean>(`${envBaseApiUrl}/offers/hip-hurricane-event`, { params: { year, countyId, typeId, practiceId, insurancePlanCode } });

export const getHistoricalStormEvents = (admDataParams: AdmDataForQuoteParams[]) => axios.post<QuoteHistoricalStormEvents[]>(`${envBaseApiUrl}/offers/historical-storm-events`, admDataParams);
export const getAvailableOptionSelections = (admDataParams: AdmDataForQuoteParams[]) => axios.post<QuoteAvailableOptionSelections[]>(`${envBaseApiUrl}/offers/available-option-selections`, admDataParams);

export const getCountyYieldInfo = (admDataParams: YieldInfoWithCountiesParams) => axios.post<Nullable<YieldInfoWithCounties>>(`${envBaseApiUrl}/offers/get-county-yield-info`, admDataParams);

export const getMyaPriceHistories = (year: number) => axios.get<MYAPriceHistory[]>(`${envBaseApiUrl}/prices/mya-price-histories`, { params: { year } });
