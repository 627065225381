import { ScenarioPieceResponseDTO, roundToPlaces, weightedAverage } from '@silveus/calculations';
import ScenarioPieceClassification from '@silveus/calculations/dist/lookups/scenarioPieceClassification/ScenarioPieceClassification';
import { ScenarioPieceId, UnitYearId } from '../../../types/api/PrimaryKeys';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { ReportProductData } from '../../../types/api/reports/ReportProductData';
import { getItemsForId } from '../../../utils/mapHelpers';
import { getFriendlyScenarioPieceName } from '../../../utils/scenarioPieceUtils';
import UnitYear from '../../../types/api/UnitYear';

export const getReportProductDataForUnitQuote = (
  unitGroups: Map<ScenarioPieceId, UnitGroup[]>,
  scenarioPiece: RowCropScenarioPiece,
  scenarioPiecesByUnitMap: Map<UnitYearId, ReportProductData[]>,
  calculations: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  unitsForScenario: UnitYear[],
): { unitYearId: UnitYearId, reportProductData: ReportProductData[] }[] => {
  const reportScenarioPieceData: { unitYearId: UnitYearId, reportProductData: ReportProductData[] }[] = [];

  const unitGroupsForScenarioPiece = getItemsForId(unitGroups, scenarioPiece.scenarioPieceId);

  for (const unitGroup of unitGroupsForScenarioPiece) {
    const scenarioPieceCalculation = calculations.get(scenarioPiece.scenarioPieceId);
    const scenarioPieceUnitCalculations = scenarioPieceCalculation === undefined ? [] : scenarioPieceCalculation.unitGroupResponses.flatMap(ugr => ugr.units);
    const scenarioPieceUnitCalculationsForThisUnitGroup = scenarioPieceUnitCalculations.filter(spuc => unitGroup.unitYearIds.some(uy => uy === spuc.id));
    const unitsForUnitGroup = unitsForScenario.filter(unit => unitGroup.unitYearIds.includes(unit.unitYearId));
    const weightedAverageTriggerYield = weightedAverage(
      scenarioPieceUnitCalculationsForThisUnitGroup,
      ur => ur.triggerYield,
      ur => {
        const unit = unitsForUnitGroup.find(uy => uy.unitYearId === ur.id);
        return unit === undefined
          ? 0
          : unit.acres * unit.sharePercent;
      },
    );

    for (const unitYearId of unitGroup.unitYearIds) {
      //Using a manual get with a fallback array instead of getItemsForId here since we need to be able to push items to the array.
      //The array returned by getItemsForId is immutable, so won't work in this case
      const existingScenarioPieces = scenarioPiecesByUnitMap.get(unitYearId) ?? [];

      const reportProductData: ReportProductData = {
        isCountyProduct: scenarioPieceCalculation?.scenarioPieceClassification === ScenarioPieceClassification.County,
        productName: getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType),
        topIntervalPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.topPrice ?? 0,
        triggerYield: roundToPlaces(weightedAverageTriggerYield, 2),
        unitStructure: scenarioPiece.unitStructure,
        upperCoverageLevel: scenarioPiece.upperCoverageLevel,
        scenarioId: scenarioPiece.scenarioId,
      };
      existingScenarioPieces.push(reportProductData);

      reportScenarioPieceData.push({
        unitYearId,
        reportProductData: existingScenarioPieces,
      });
    }
  }

  return reportScenarioPieceData;
};