import { Grid, List, Paper } from '@mui/material';
import { selectAllCounties, selectAllStates } from '../../app/admSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import StateListItem from './stateListItem.component';
import SummaryButton from './summaryButton.component';
import React, { useEffect, useState } from 'react';
import FreeDiskSpace from './freeDiskSpace.component';
import AmChartMap from './amChartMap.component';
import { County } from '../../types/api/adm/County';
import { distinctBy } from '../../utils/arrayUtils';

interface AdmManagementProps {
  countiesForInsureds: string[];
  electedCounties: string[];
  updateSelectedCounties: (counties: string[]) => void;
  navigateToSummaryPage: () => void;
}

const AdmManagement = ({ countiesForInsureds, electedCounties, updateSelectedCounties, navigateToSummaryPage }: AdmManagementProps) => {
  const states = useAppSelector(selectAllStates);
  const counties = useAppSelector(selectAllCounties);

  const [selectedCounties, setSelectedCounties] = useState(counties.filter(c => electedCounties.includes(c.countyId)));

  const selectedStateCodes = distinctBy(selectedCounties.map(c => c.stateCode), stateCode => stateCode);

  const selectedStates = states.filter(state => selectedStateCodes.includes(state.stateCode));

  useEffect(() => {
    updateSelectedCounties(selectedCounties.map(c => c.countyId));
  }, [selectedCounties]);

  const handleStateSelected = (stateCode: string, active: boolean) => {
    const countiesForState = counties.filter(c => c.stateCode === stateCode);

    const areAllCountiesSelected = countiesForState.every(county => selectedCounties.includes(county));
    const isAnyCountySelected = countiesForState.some(county => selectedCounties.includes(county));
    const isIndeterminate = !areAllCountiesSelected && isAnyCountySelected;

    if (isIndeterminate || active) {
      //Mark all counties in the given state as active
      const selectedCountiesWithoutThoseInCurrentState = selectedCounties.filter(c => c.stateCode !== stateCode);
      const countiesForState = counties.filter(c => c.stateCode === stateCode);

      const newCounties = [...selectedCountiesWithoutThoseInCurrentState, ...countiesForState];
      setSelectedCounties(newCounties);
    } else {
      //Leave counties needed for insured selections selected
      const countiesRemaining = selectedCounties.filter(c => c.stateCode !== stateCode || countiesForInsureds.includes(c.countyId));
      setSelectedCounties(countiesRemaining);
    }
  };

  const handleCountySelected = (county: County, active: boolean) => {
    if (active) {
      if (!selectedCounties.includes(county)) {
        const newCounties = [...selectedCounties];
        newCounties.push(county);
        setSelectedCounties(newCounties);
      }
    } else {
      //Leave locked counties selected
      const countyIndex = selectedCounties.indexOf(county);
      if (countyIndex > -1 && !countiesForInsureds.includes(county.countyId)) {
        const newCounties = [...selectedCounties];
        newCounties.splice(countyIndex, 1);
        setSelectedCounties(newCounties);
      }
    }
  };

  return (
    <Grid container p={6} sx={{ height: '100%' }} columnGap={3}>
      <Grid item xs>
        <AmChartMap updateSelectedState={handleStateSelected} selectedStates={selectedStates} />
      </Grid>
      <Grid item container xs="auto" sx={{ height: '100%', backgroundColor: theme => theme.palette.neutral.light }} direction="column">
        <Grid item xs sx={{ overflow: 'auto' }} mb={2}>
          <Paper>
            <List sx={{ overflowY: 'auto' }} dense disablePadding>
              {states.map(state =>
                <StateListItem
                  key={state.stateCode}
                  selectedState={state}
                  activeCounties={selectedCounties}
                  lockedCounties={countiesForInsureds}
                  handleStateSelected={handleStateSelected}
                  handleCountySelected={handleCountySelected}
                />,
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item container justifyContent="flex-end" xs="auto">
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <FreeDiskSpace />
          </Grid>
          <Grid item xs />
          <Grid item xs="auto">
            <SummaryButton onClick={navigateToSummaryPage} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdmManagement;