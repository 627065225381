import {
  Button
} from '@mui/material';

type HailModalHeadeControlsProps = {
  onCloseClicked: () => void;
}

const HailModalHeaderControls = ({ onCloseClicked }: HailModalHeadeControlsProps) => {

  return (
    <Button
      id="btn-units-cancel"
      sx={{ mr: '8px', color: theme => theme.palette.common.white, backgroundColor: 'transparent', borderColor: theme => theme.palette.common.white }}
      type="submit"
      variant="outlined"
      onClick={onCloseClicked}>
        CLOSE
    </Button>
  );
};

export default HailModalHeaderControls;
