import { Nullable } from '../../types/util/Nullable';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import {
  HighRiskType,
  ScenarioPieceGroupType,
  ScenarioPieceIdentifier,
  ScenarioPieceType
} from '@silveus/calculations';
import { ClientFile } from '../../types/api/ClientFile';
import { Quote } from '../../types/api/Quote';
import { FormWrapperProps } from '../../components/formWrapper/formWrapper.component';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';
import ScoScenarioPieceForm from './sco/scoScenarioPieceForm';
import { MpScenarioPieceForm } from './mp/mpScenarioPieceForm.component';
import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import EcoScenarioPieceForm from './eco/ecoScenarioPieceForm';
import StaxScenarioPieceForm from './stax/staxScenarioPieceForm.component';
import HailScenarioPieceForm from './hail/hailScenarioPieceForm.component';
import { VipMpScenarioPieceForm } from './vipMp/vipMpScenarioPieceForm.component';
import IceScenarioPieceForm from './ice/iceScenarioPieceForm';
import { ScenarioPieceFormSnapshot } from '../../types/app/ScenarioPieceFormSnapshot';
import { ScenarioPieceMultiPlanWrapper } from './scenarioPieceMultiPlanWrapper.component';
import { getScenarioPieceDefinition } from '../../constants/productDefinitions/scenarioPieceDefinitionRecords';

export interface ScenarioPieceFormPickerProps extends FormWrapperProps {
  scenarioPiece: Nullable<ScenarioPiece>;
  scenarioId: ScenarioId;
  year: number;
  countyId: string;
  highRiskTypeId: HighRiskType;
  typeId: string;
  practiceId: string;
  commodityCode: string;
  disabled: boolean;
  selectedScenarioPieceType: ScenarioPieceIdentifier;
  quote: Quote;
  clientFile: ClientFile;
  handleCancel: () => void;
  updateScenarioPieceFormSnapshot: (updatePlanSelection: boolean, newPlanSelection: ScenarioPieceType, scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null) => void;
  scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null;
  scenarioPieceTypePlanSelectionList: ScenarioPieceType[];
}

export const ScenarioPieceFormPicker = (props: ScenarioPieceFormPickerProps) => {
  const selectedScenarioPieceIdentifier = props.selectedScenarioPieceType;
  switch (selectedScenarioPieceIdentifier) {
    case ScenarioPieceGroupType.MpciIndividual:
      return <ScenarioPieceMultiPlanWrapper
        {...props}
        key={ScenarioPieceGroupType.MpciIndividual}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
        scenarioPieceTypePlanSelectionList={[ScenarioPieceType.RP, ScenarioPieceType.RpHpe, ScenarioPieceType.YP, ScenarioPieceType.Aph]}
      />;
    case ScenarioPieceGroupType.MpciArea:
      return <ScenarioPieceMultiPlanWrapper
        {...props}
        key={ScenarioPieceGroupType.MpciArea}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
        scenarioPieceTypePlanSelectionList={[ScenarioPieceType.Arp, ScenarioPieceType.ArpHpe, ScenarioPieceType.Ayp]}
      />;
    case ScenarioPieceGroupType.Mp:
      return <MpScenarioPieceForm
        {...props}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
      />;
    case ScenarioPieceGroupType.Sco:
      return <ScoScenarioPieceForm
        {...props}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
      />;
    case ScenarioPieceGroupType.Eco:
      return <EcoScenarioPieceForm
        {...props}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
      />;
    case ScenarioPieceGroupType.Ice:
      return <IceScenarioPieceForm
        {...props}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
      />;
    case ScenarioPieceGroupType.Stax:
      return <StaxScenarioPieceForm
        {...props}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
      />;
    case ScenarioPieceGroupType.ScoPlus:
      return <ScenarioPieceMultiPlanWrapper
        {...props}
        key={ScenarioPieceGroupType.ScoPlus}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
        scenarioPieceTypePlanSelectionList={[ScenarioPieceType.ScoPlusRevenue, ScenarioPieceType.ScoPlusYield]}
      />;
    case ScenarioPieceGroupType.EcoPlus:
      return <ScenarioPieceMultiPlanWrapper
        {...props}
        key={ScenarioPieceGroupType.EcoPlus}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
        scenarioPieceTypePlanSelectionList={[ScenarioPieceType.EcoPlusRevenue, ScenarioPieceType.EcoPlusYield]}
      />;
    case ScenarioPieceGroupType.MySco:
      return <ScenarioPieceMultiPlanWrapper
        {...props}
        key={ScenarioPieceGroupType.MySco}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
        scenarioPieceTypePlanSelectionList={[ScenarioPieceType.MyScoRevenue, ScenarioPieceType.MyScoYield]}
      />;
    case ScenarioPieceGroupType.MyEco:
      return <ScenarioPieceMultiPlanWrapper
        {...props}
        key={ScenarioPieceGroupType.MyEco}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
        scenarioPieceTypePlanSelectionList={[ScenarioPieceType.MyEcoRevenue, ScenarioPieceType.MyEcoYield]}
      />;
    case ScenarioPieceGroupType.Ramp:
      return <ScenarioPieceMultiPlanWrapper
        {...props}
        key={ScenarioPieceGroupType.Ramp}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
        scenarioPieceTypePlanSelectionList={[ScenarioPieceType.RampRevenue, ScenarioPieceType.RampYield]}
      />;
    case ScenarioPieceGroupType.RevBoost:
      return <ScenarioPieceMultiPlanWrapper
        {...props}
        key={ScenarioPieceGroupType.RevBoost}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
        scenarioPieceTypePlanSelectionList={[ScenarioPieceType.RevBoostRp, ScenarioPieceType.RevBoostRpHpe, ScenarioPieceType.RevBoostRpHpo, ScenarioPieceType.RevBoostYp]}
      />;
    case ScenarioPieceGroupType.Hail:
      return <HailScenarioPieceForm
        {...props}
      />;
    case ScenarioPieceGroupType.VipMp:
      return <VipMpScenarioPieceForm
        {...props}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
        scenarioPieceType={ScenarioPieceType.MP}
      />;
    case ScenarioPieceGroupType.FarmBill:
      return <ScenarioPieceMultiPlanWrapper
        {...props}
        key={ScenarioPieceGroupType.FarmBill}
        scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
        scenarioPieceTypePlanSelectionList={[ScenarioPieceType.Arc, ScenarioPieceType.Plc]}
      />;
  }

  //Then use the scenario piece definition if a specific scenario piece type (not group) is selected
  if (Object.values(ScenarioPieceType).includes(selectedScenarioPieceIdentifier as ScenarioPieceType)) {
    const scenarioPieceDefinition = getScenarioPieceDefinition(selectedScenarioPieceIdentifier as ScenarioPieceType);
    return scenarioPieceDefinition.scenarioPieceForm(props);
  }

  return <></>;
};
