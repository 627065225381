import axios from 'axios';
import { QuoteId, ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import HailScenarioPiece from '../types/api/HailScenarioPiece';
import { HailScenarioPieceCompositionDTO } from '../types/api/hail/hailScenarioPieceCompositionDTO';
import { envBaseApiUrl } from '../constants/envConstants';

export const addHailScenarioPieceComposition = (hailScenarioPieceComposition: HailScenarioPieceCompositionDTO) => axios.post(`${envBaseApiUrl}/hail`, hailScenarioPieceComposition);
export const getHailScenarioPiece = (scenarioPieceId: ScenarioPieceId) => axios.get<HailScenarioPiece>(`${envBaseApiUrl}/hail/${scenarioPieceId}`);
export const getHailScenarioPieceCompositionsByScenarioIds = (scenarioIds: ScenarioId[]) => axios.post<HailScenarioPieceCompositionDTO[]>(`${envBaseApiUrl}/hail/scenarios`, scenarioIds);
export const getHailScenarioPiecesByQuoteId = (quoteId: QuoteId) => axios.get<HailScenarioPiece[]>(`${envBaseApiUrl}/hail/quotes/${quoteId}/scenarioPieces`);
export const updateHailScenarioPieceComposition = (hailScenarioPieceComposition: HailScenarioPieceCompositionDTO) => axios.put(`${envBaseApiUrl}/hail`, hailScenarioPieceComposition);
export const deleteHailScenarioPiece = (scenarioPieceId: ScenarioPieceId) => axios.delete(`${envBaseApiUrl}/hail/${scenarioPieceId}`);
export const deleteHailScenarioPieceComposition = (scenarioId: ScenarioId) => axios.delete(`${envBaseApiUrl}/hail/scenarios/${scenarioId}`);
