export const TrendlineAnalysisTabs = {
  yields: {
    label: 'Yields',
    index: 0,
  },
  revenue: {
    label: 'Revenue',
    index: 1,
  },
  prices: {
    label: 'Prices',
    index: 2,
  },
  percentChange: {
    label: 'Percent Change',
    index: 3,
  },
} as const;

export type TrendlineAnalysisTabIndex = typeof TrendlineAnalysisTabs[keyof typeof TrendlineAnalysisTabs]['index'];