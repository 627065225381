import { ICellRendererParams } from 'ag-grid-community';
import { selectApprovedYieldForUnitYear } from '../../../app/unitsSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import UnitYear from '../../../types/api/UnitYear';
import { formatNumber } from '../../../utils/formatNumbers';
import { Na } from '../../../types/util/Na';
import { Quote } from '../../../types/api/Quote';

type ApprovedYieldCellRendererProps = {
  unitYear: UnitYear;
  scenarioId: ScenarioId;
  params: ICellRendererParams;
  quote: Quote;
};

const ApprovedYieldCellRenderer = ({ params, unitYear, scenarioId, quote }: ApprovedYieldCellRendererProps) => {
  const isPinnedRow = params.node.rowPinned === 'bottom';
  const approvedYield = useAppSelector(state => isPinnedRow ? params.data.appYield : selectApprovedYieldForUnitYear(state, unitYear.unitYearId, scenarioId, quote.countyId, quote.commodityCode));
  return (
    <div>{approvedYield !== null ? formatNumber(approvedYield, true) : Na}</div>
  );
};

export default ApprovedYieldCellRenderer;
