import { ScenarioId } from '../types/api/PrimaryKeys';
import { LinkedScenarioData } from '../types/api/userSettings/UserLinkedScenarios';
import { asArray } from '../utils/arrayUtils';
import { isNullOrUndefined } from '../utils/nullHandling';

export const getLinkedDataToUpdate = <T extends { scenarioId: ScenarioId }>(scenarioIdToEntityDictionary: Partial<Record<ScenarioId, T | T[]>>, linkedScenarioData: LinkedScenarioData, mainUpdateTemplate: T) => {
  let willThereBeALinkedUpdate: boolean;
  let otherScenarioIdsToUpdate: Set<ScenarioId>;

  // This is important - the linked data is at the quote level. If the user updates a certain scenario, it might not be part of a link group.
  // If it's not, there's no linked update that's going to happen.
  const isScenarioBeingUpdatedLinked = linkedScenarioData.linkedScenarioIds.includes(mainUpdateTemplate.scenarioId);

  otherScenarioIdsToUpdate = isScenarioBeingUpdatedLinked ?
    new Set(linkedScenarioData.linkedScenarioIds.filter(s => s !== mainUpdateTemplate.scenarioId)) : new Set();

  // We know to run a linked update if:
  willThereBeALinkedUpdate =
    // The "main" scenario being updated belongs to the link group
    isScenarioBeingUpdatedLinked &&
    // and there's at least 1 linked scenario
    otherScenarioIdsToUpdate.size > 0 &&
    // And there's at least one linked field. With 0 linked fields, there'd be nothing to update on the linked scenarios.
    linkedScenarioData.linkedFields.length > 0;

  const linkedDataToUpdate: T[] = [];

  if (willThereBeALinkedUpdate) {
    // Go through each scenario and update its fields for only the linked fields to match the template.
    for (const scenarioId of otherScenarioIdsToUpdate) {
      const dataForScenarioId = scenarioIdToEntityDictionary[scenarioId];

      if (dataForScenarioId === undefined) { continue; }

      const dataArray = asArray(dataForScenarioId);
      for (const data of dataArray) {
        const clonedData = {
          ...data,
        };

        for (const value of linkedScenarioData.linkedFields) {
          const innerMethod = cloneFields(value, clonedData, mainUpdateTemplate);
          innerMethod();
        }

        linkedDataToUpdate.push(clonedData);
      }
    }
  }
  return linkedDataToUpdate;
};


const cloneFields = <T>(fieldName: string, clone: T, mainTemplate: T) => <K extends keyof T>() => {
  //This applies changes from the fields on one scenario to the associated linked scenarios
  //It works with property names (including dot notation to a depth of 1) and should be type safe
  const propNames = fieldName.split('.');
  if (propNames.length > 1) {
    const childPropName = propNames[0] as K;
    const nestedPropName = propNames[1] as keyof K;

    if (isNullOrUndefined((clone[childPropName] as K))) return;

    let clonedProperty = { ...clone[childPropName] };
    (clonedProperty as K)[nestedPropName] = ((mainTemplate)[childPropName] as K)[nestedPropName];
    clone[childPropName] = clonedProperty;

  } else {
    clone[fieldName as K] = (mainTemplate)[fieldName as K];
  }
};


