import { Grid, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import AdmManagement from './admManagement.component';
import InsuredManagement from './insuredManagement.component';
import { InsuredId } from '../../types/api/PrimaryKeys';
import { useEffect, useState } from 'react';
import { beginLoading, endLoading } from '../../app/loaderSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import {
  fetchCountiesForInsureds,
  fetchPreDownloadedInsureds,
  selectCountiesForInsureds,
  selectPreDownloadedInsureds
} from '../../app/insuredsSlice';
import DataSummaryDialog from './dataSummaryDialog.component';
import { fetchPreDownloadedCounties, selectPreDownloadedCounties } from '../../app/admSlice';

type ValidPageNumber = 0 | 1 | 2;

const DataManagement = () => {
  const preDownloadedInsureds = useAppSelector(selectPreDownloadedInsureds);
  const preDownloadedCounties = useAppSelector(selectPreDownloadedCounties);
  const [selectedInsureds, setSelectedInsureds] = useState<InsuredId[]>([]);
  const countiesForInsureds = useAppSelector(selectCountiesForInsureds);
  const [insuredCounties, setInsuredCounties] = useState<string[]>([]);
  const [priorCountiesForInsureds, setPriorCountiesForInsureds] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState<ValidPageNumber>(0);
  const dispatch = useAppDispatch();
  const [isSummaryOpen, setIsSummaryOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchPreDownloadedInsureds());
    dispatch(fetchPreDownloadedCounties());
  }, []);

  useEffect(() => {
    setSelectedInsureds(preDownloadedInsureds);
  }, [preDownloadedInsureds]);

  useEffect(() => {
    const counties = [...new Set([...insuredCounties, ...preDownloadedCounties])];
    setInsuredCounties(counties);
  }, [preDownloadedCounties]);

  useEffect(() => {
    const newCounties = [...insuredCounties];
    //Find all of the counties that are no longer part of the counties for insureds and remove them
    const countiesToRemove = priorCountiesForInsureds.filter(countyId => !countiesForInsureds.includes(countyId));
    countiesToRemove.forEach(countyId => {
      newCounties.splice(newCounties.indexOf(countyId), 1);
    });

    //Find all of the counties that are now part of the counties for insureds and add them
    const countiesToAdd = countiesForInsureds.filter(countyId => !priorCountiesForInsureds.includes(countyId));
    newCounties.push(...countiesToAdd);

    setPriorCountiesForInsureds(countiesForInsureds);
    setInsuredCounties(newCounties);
  }, [countiesForInsureds]);

  useEffect(() => {
    const updateSelectedCounties = async () => {
      dispatch(beginLoading());
      try {
        await dispatch(fetchCountiesForInsureds({ insuredIds: selectedInsureds }));
      } finally {
        dispatch(endLoading());
      }
    };
    updateSelectedCounties();
  }, [selectedInsureds]);

  const handleInsuredsClick = () => {
    setPageNumber(0);
  };

  const handleAdmDataClick = () => {
    setPageNumber(1);
  };

  const handleSummaryClick = () => {
    setIsSummaryOpen(true);
  };

  // const handleReconciliationTestClick = () => {
  //   setPageNumber(2);
  // };

  const handleInsuredSelectionChanged = (insureds: InsuredId[]) => {
    setSelectedInsureds(insureds);
  };

  const handleSummaryDialogClose = () => {
    setIsSummaryOpen(false);
  };

  return (
    <Grid container m={-1} height={'calc(100vh - 48px)'}>
      <Grid item xs="auto" sx={{ height: '100%', color: theme => theme.palette.altText.main }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton selected={pageNumber === 0} onClick={handleInsuredsClick}>
              <ListItemText primary="Clients/Leads" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton selected={pageNumber === 1} onClick={handleAdmDataClick}>
              <ListItemText primary="State ADMs" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton selected={isSummaryOpen} onClick={handleSummaryClick}>
              <ListItemText primary="Summary" />
            </ListItemButton>
          </ListItem>
          {/*Leaving this commented out for now for convenience of dev testing now and in the future. Just don't want to show this to users*/}
          {/*<ListItem disablePadding>*/}
          {/*  <ListItemButton selected={isSummaryOpen} onClick={handleReconciliationTestClick}>*/}
          {/*    <ListItemText primary="Reconciliation Tests" />*/}
          {/*  </ListItemButton>*/}
          {/*</ListItem>*/}
        </List>
      </Grid>
      <Grid item xs sx={{ height: '100%' }}>
        {pageNumber === 0 && <InsuredManagement selectedInsureds={selectedInsureds} updateSelectedInsureds={handleInsuredSelectionChanged} navigateToSummaryPage={handleSummaryClick} />}
        {pageNumber === 1 && <AdmManagement countiesForInsureds={countiesForInsureds} electedCounties={insuredCounties} navigateToSummaryPage={handleSummaryClick} updateSelectedCounties={setInsuredCounties} />}
        {/*{pageNumber === 2 && <ReconciliationTestScreen />}*/}
      </Grid>
      <DataSummaryDialog selectedInsureds={selectedInsureds} countiesToDownload={insuredCounties} open={isSummaryOpen} onClose={handleSummaryDialogClose} />
    </Grid>
  );
};

export default DataManagement;