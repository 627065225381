import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

interface IsPercentOfProjectedProps {
  isPercentOfProjected: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}

export type IsPercentOfProjectedFields = {
  isPercentOfProjected: boolean;
}

const IsPercentOfProjectedInput = ({ isPercentOfProjected, autoFocus = false, disabled = false }: IsPercentOfProjectedProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="rowCropScenarioPieceExtendedData.isPercentOfProjected"
      control={control}
      defaultValue={isPercentOfProjected}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl
          error={!!error}
          disabled={disabled}>
          <FormControlLabel
            label="Is Percent Of Projected"
            control={
              <Checkbox
                {...field}
                checked={value}
                inputRef={ref}
                onChange={e => field.onChange(e.target.checked)}
                autoFocus={autoFocus}
                size="small"
              />
            } />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl >
      )}
    />
  );
};

export default IsPercentOfProjectedInput;
