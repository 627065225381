import { MenuItem, TextField } from '@mui/material';
import { AcreageType, AcreageTypeAttributes } from '@silveus/calculations';
import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';

interface HistoricalAcreTypeInputProps {
  value: AcreageType;
}

export type HistoricalAcreageTypeFields = {
  acreageType: AcreageType;
}

const HistoricalAcreTypeInput = ({ value }: HistoricalAcreTypeInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="acreageType"
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label="Acre Type"
          fullWidth
          select
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          size="small"
          onChange={e => field.onChange(e.target.value)}
        >
          {Object.values(AcreageTypeAttributes).map(at => {
            return (
              <MenuItem key={at.value} value={at.value}>{at.description}</MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
};

export default HistoricalAcreTypeInput;