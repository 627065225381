import { Button, Grid, MenuItem } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  selectPolicyYear,
  selectUnitYearById
} from '../../../app/unitsSlice';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import UnitYearAph from '../../../types/api/UnitYearAph';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import {
  CellClassParams,
  CellStyle,
  ColDef,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  RowClassParams,
  ValueSetterParams
} from 'ag-grid-community';
import {
  addYear,
  processAcreageChange,
  processProductionChange,
  processTypeSelection,
  processYieldChange,
  removeYear,
  validateAph,
  processAphRow,
  isAphYieldInvalid,
  isProductionInvalid,
  isAcreageInvalid,
  getUnitYearAphForAddedLand
} from '../utils/aph';
import { numberFormatter, numberSetter } from '../../../utils/grid';
import { YIELD_DESCRIPTORS, calculateApplicableTYield } from '@silveus/calculations/';
import { AgGridReact } from 'ag-grid-react';
import GRID_COLUMN_TYPES from '../../../constants/gridColumnTypes';
import Actions from '../../../components/actions/actions.component';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import '../../../components/ag-grid/ag-grid.component.css';
import {
  addUpdateOrRemoveUnitYearAph,
  currentlySelectedHistoricalAcresYear,
  selectUnitYearAphByUnitYearMap
} from '../../../app/unitYearAphSlice';
import { ConfirmStateContent, openConfirm } from '../../../app/confirmSlice';
import './unitAphForm.css';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import TYieldDisplay from './tyieldUnitAPH.component';
import CustomTYieldDisplay from './customTYieldUnitAPH.component';
import { fetchTYields, selectTYield } from '../../../app/admSlice';
import { Nullable } from '../../../types/util/Nullable';
import { isEqual } from 'lodash';
import { selectIsLightMode } from '../../../app/userSettingsSlice';
import { isAcreageEditable, isAphYieldEditable, isProductionEditable } from '../utils/aphGridUtils';

export interface UnitAphFormProps extends FormWrapperProps {
  unitYearId: UnitYearId;
}

export type UnitAphFormFields = {
  customTYield: Nullable<number>;
  aphYears: UnitYearAph[];
};

const UnitAphForm = ({ unitYearId, registerHeader, handleValidation, isCanceling = false }: UnitAphFormProps) => {
  const dispatch = useAppDispatch();
  const unitAph = useKeyMapSelector(selectUnitYearAphByUnitYearMap, unitYearId);
  const unit = useAppSelector(s => selectUnitYearById(s, unitYearId));
  const policyYear = useAppSelector(selectPolicyYear) ?? 0;
  const admTYield = useAppSelector(state => unit === null ? undefined : selectTYield(state, unit.typeId, unit.practiceId, unit.subCountyCode));
  const isLightMode = useAppSelector(selectIsLightMode);
  const selectedHistoricalAcresYear = useAppSelector(currentlySelectedHistoricalAcresYear);
  const [aph, setAph] = useState<UnitYearAph[]>([]);
  const [gridApi, setGridApi] = useState<GridApi<UnitYearAph>>();
  const methods = useForm<UnitAphFormFields>();
  const formId = 'unitAphForm';
  const [applicableTYield, setApplicableTYield] = useState<number>(0);

  useEffect(() => {
    updateAphData(aph);
    const newApplicableTYield = calculateApplicableTYield(aph, admTYield?.transitionalYield ?? 0);
    if (newApplicableTYield === applicableTYield) return;
    setApplicableTYield(newApplicableTYield);
    aph.forEach(aphRow => processAphRow(aphRow, newApplicableTYield));
  }, [aph, admTYield]);

  function updateAphData(aphData: UnitYearAph[]) {
    methods.clearErrors();
    methods.setValue('aphYears', aphData);

    aphData.forEach((row, index) => {
      if (isAcreageInvalid(row.yieldType, row.acres)) {
        methods.setError(`aphYears.${index}.yieldType`, { type: 'custom', message: 'invalid yield type' });
        methods.setError(`aphYears.${index}.acres`, { type: 'custom', message: 'invalid acres' });
      }

      if (isAphYieldInvalid(row.yieldType, row.aphYield)) {
        methods.setError(`aphYears.${index}.aphYield`, { type: 'custom', message: 'invalid APH Yield' });
      }

      if (isProductionInvalid(row.yieldType, row.production)) {
        methods.setError(`aphYears.${index}.acres`, { type: 'custom', message: 'invalid production' });
      }

      if (!isYearValid(row.year)) {
        methods.setError(`aphYears.${index}.year`, { type: 'custom', message: 'invalid year' });
      }
    });
  }

  useEffect(() => {
    if (unit !== null) {
      dispatch(fetchTYields({ year: policyYear, countyId: unit.countyId, commodityCode: unit.commodityCode }));
    }
  }, [unit]);

  const onAddYear = (): void => {
    const years = aph.map(aphRow => aphRow.year);
    const latestYear = Math.max(...years);
    const earliestYear = Math.min(...years);

    //if no rows default to the policy year - 1,
    //otherwise increment from the latest year but no more that the policy year.
    //Then you will increment down from the earliest year given
    let defaultYear = earliestYear - 1;
    if (years.length === 0) {
      defaultYear = policyYear - 1;
    } else if (latestYear < policyYear - 1) {
      defaultYear = latestYear + 1;
    }

    const { newAph } = addYear(aph, unitYearId, unit?.acres ?? 0, defaultYear, unit?.commodityCode ?? '', undefined, undefined, defaultYear);
    setAph(newAph);
  };

  const onAddYearsNewUnit = (yearsToAdd: number): void => {
    let newAph = aph;
    for (let i = 0; i < yearsToAdd; i++) {
      newAph = addYear(newAph, unitYearId, unit?.acres ?? 0, policyYear, unit?.commodityCode ?? '').newAph;
    }

    setAph(newAph);
  };

  const onAddYearsAddedLand = (): void => {
    const newAph = getUnitYearAphForAddedLand(policyYear, unitYearId, unit?.acres ?? 0, applicableTYield);
    setAph(newAph);
  };

  const onDeleteYear = (index: number) => {
    if (isNullOrUndefined(gridApi) || index < 0) return;
    const rowAtIndex = gridApi.getDisplayedRowAtIndex(index)?.data;
    if (isNullOrUndefined(rowAtIndex)) return;
    const itemIndex = aph.indexOf(rowAtIndex);
    const newAph = removeYear(aph, itemIndex);
    setAph(newAph);
  };

  const onDeleteYearClick = (index: number) => {
    const confirmWindow: ConfirmStateContent = {
      title: 'Delete Unit Year?',
      message: 'Are you sure you want to delete this unit year?',
      confirmText: 'Delete',
      onConfirm: () => onDeleteYear(index),
    };
    dispatch(openConfirm(confirmWindow));
  };

  const onGridReady = (params: GridReadyEvent<UnitYearAph>): void => {
    setGridApi(params.api);
  };

  const setType = (newAph: UnitYearAph[]) => (params: ValueSetterParams): boolean => {
    if (isNullOrUndefined(params.newValue)) return false;

    params.data.yieldType = params.newValue;
    newAph = processTypeSelection(params.data.unitYearAphId, newAph, params.newValue, unit?.commodityCode ?? '', applicableTYield, unit?.acres ?? 0).changedAph;
    setAph(newAph);
    return true;
  };

  const setYear = () => (params: ValueSetterParams): boolean => {
    if (isNullOrUndefined(gridApi)) return false;

    const newAph = params.data;
    const isValueSetSuccessful = numberSetter(newAph, 'year', params.newValue, 1900, policyYear - 1);

    if (isValueSetSuccessful) {
      updateAphYear(newAph);
      gridApi.onSortChanged();
    }

    return isValueSetSuccessful;
  };

  const setAcres = (params: ValueSetterParams): boolean => {
    const newAph = processAcreageChange(params.data, params.newValue);

    updateAphYear(newAph);
    return true;
  };

  const setProduction = (params: ValueSetterParams): boolean => {
    const newAph = processProductionChange(params.data, params.newValue);

    updateAphYear(newAph);
    return true;
  };

  const setYield = (params: ValueSetterParams): boolean => {
    const { changedAph } = processYieldChange(params.data.unitYearAphId, aph, params.newValue, applicableTYield, unit?.commodityCode);

    setAph(changedAph);

    return true;
  };

  const onSubmit: SubmitHandler<UnitAphFormFields> = async data => {
    const isValid = validateAph(aph);
    if (!isValid) {
      return;
    }

    dispatch(addUpdateOrRemoveUnitYearAph({ initialUnitYearAph: unitAph, modifiedUnitYearAph: aph }));
  };

  const handleReset = () => {
    methods.reset();
  };

  useEffect(() => {
    if (unitAph.length !== 0 && aph.length === 0) {
      const newAph = [
        ...unitAph,
      ].map(i => ({ ...i }));
      setAph(newAph);
    }
  }, [unitAph]);

  const updateAphYear = (newAph: UnitYearAph) => {
    const newAphHistory = [...aph];
    const yearToRemove = newAphHistory.findIndex(history => history.unitYearAphId === newAph.unitYearAphId);

    if (yearToRemove < 0) {
      newAphHistory.push(newAph);
    }

    newAphHistory.splice(yearToRemove, 1, newAph);

    setAph(newAphHistory);
  };

  const cellStyleRules = (params: CellClassParams): CellStyle | undefined => {
    const { acres, yieldType, production, aphYield } = params.data;
    const columnId = params.column.getColId();

    const errorStyle = {
      borderColor: 'lightCoral',
      borderStyle: 'solid',
      borderWidth: 'medium',
    };

    if ((columnId === 'yieldType' || columnId === 'acres') && isAcreageInvalid(yieldType, acres)) {
      return errorStyle;
    }

    if (columnId === 'aphYield' && isAphYieldInvalid(yieldType, aphYield)) {
      return errorStyle;
    }

    if (columnId === 'production' && isProductionInvalid(yieldType, production)) {
      return errorStyle;
    }

    if ((columnId === 'year') && !isYearValid(params.value)) {
      return errorStyle;
    }
  };

  function isYearValid(year: number) {
    return !!year;
  }

  const getRowClass = (params: RowClassParams<UnitYearAph>) => {
    if (params.data?.year === selectedHistoricalAcresYear) {
      return 'unit-aph-row-select';
    }
  };

  const shouldSubmit = validateAph(aph) && !isEqual(unitAph, aph);

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit);
  const handleSubmit = useFormWrapper('Unit', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const columnDefs = useMemo<ColDef[]>(() => [
    { field: 'year', maxWidth: 85, sort: 'asc', valueSetter: setYear(), cellStyle: cellStyleRules },
    { field: 'production', flex: 1, valueSetter: setProduction, editable: isProductionEditable, valueFormatter: numberFormatter, cellStyle: cellStyleRules },
    { field: 'acres', maxWidth: 85, valueSetter: setAcres, editable: isAcreageEditable, valueFormatter: numberFormatter, cellStyle: cellStyleRules },
    { field: 'yieldType', maxWidth: 100, valueSetter: setType(aph), cellEditor: 'agRichSelectCellEditor', cellEditorParams: { values: Object.keys(YIELD_DESCRIPTORS).sort() }, refData: YIELD_DESCRIPTORS, cellStyle: cellStyleRules },
    { field: 'aphYield', flex: 1, valueSetter: setYield, editable: isAphYieldEditable, valueFormatter: numberFormatter, cellStyle: cellStyleRules },
    {
      type: 'actions', resizable: false, editable: false, maxWidth: 60, cellRenderer: (params: ICellRendererParams) =>
        <Actions id={policyYear.toString()}>
          <MenuItem onClick={() => onDeleteYearClick(params.node.rowIndex ?? -1)}>Delete</MenuItem>
        </Actions>,
    },
  ], [aph, policyYear, applicableTYield, unit]);

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit} onReset={handleReset}>
        <Grid container spacing={2} p={2}>
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={2} sx={{ marginLeft: '0', marginTop: '5px' }}>
              <Grid item xs={2}>
                {unit !== null && <TYieldDisplay typeId={unit.typeId} practiceId={unit.practiceId} subCountyCode={unit.subCountyCode} />}
              </Grid>
              <Grid item xs={3}>
                <CustomTYieldDisplay unitYearAph={aph} />
              </Grid>
              <Grid item xs={7} sx={{ textAlign: 'end' }}>
                <Button id="btn-add-year" variant="contained" onClick={onAddYear}>Add Year</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} className={isLightMode ? 'ag-theme-quartz unitAphForm' : 'ag-theme-quartz-dark unitAphForm'} sx={{ height: `${aph.length > 0 ? 'calc(100vh - 220px)' : '150px'}` }}>
              <AgGridReact
                rowData={aph}
                getRowClass={getRowClass}
                columnTypes={GRID_COLUMN_TYPES}
                columnDefs={columnDefs}
                onCellValueChanged={() => {
                  updateAphData(aph);
                }}
                gridOptions={{
                  singleClickEdit: true,
                  defaultColDef: {
                    sortable: true,
                    resizable: true,
                    editable: true,
                  },
                  sortingOrder: ['desc', 'asc', null],
                  enterNavigatesVertically: true,
                  enterNavigatesVerticallyAfterEdit: true,
                  stopEditingWhenCellsLoseFocus: true,
                }}
                onGridReady={onGridReady}
                autoSizeStrategy={{ type: 'fitGridWidth' }}
              />
            </Grid>
            {aph.length === 0 &&
              <Grid container item xs={12} spacing={2} justifyContent={'center'}>
                <Grid item xs>
                </Grid>
                <Grid item xs>
                  <Button id="btn-add-ten-years" variant="contained" onClick={() => onAddYearsNewUnit(10)}>Add 10 Years</Button>
                </Grid>
                <Grid item xs>
                  <Button id="btn-add-four-years" variant="contained" onClick={() => onAddYearsNewUnit(4)}>Add 4 Years</Button>
                </Grid>
                <Grid item xs>
                  <Button id="btn-added-land" variant="contained" onClick={onAddYearsAddedLand}>Added Land</Button>
                </Grid>
                <Grid item xs>
                </Grid>
              </Grid>
            }

          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default UnitAphForm;
