import { Nullable } from '../../../types/util/Nullable';
import { FormInputProps } from './formInputProps';
import usePercentChange from '../../../hooks/usePercentChange';
import { getInsurancePlanCodeVariationForAreaYield } from '../../../utils/priceYieldVariationUtils';
import { getTypeCodeFromTypeId } from '../../../utils/adm';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { fetchCommoditiesWithOffers, fetchYieldInfoWithCounties, selectYieldInfoWithCounties, selectCommoditiesWithOffers } from '../../../app/admSlice';
import { CoverageTypeCode } from '@silveus/calculations';
import YieldInput from './yieldInput';
import CountyYieldInputToolTip from './countyYieldInputToolTip';

export interface ActualCountyYieldProps extends FormInputProps {
  actualCountyYield: Nullable<number>;
  commodityCode: string;
  year: number;
  countyId: string;
  practiceId: Nullable<string>;
  typeId: Nullable<string>;
  insurancePlanCodes: string[];
  label?: string;
  volatility: number;
  coverageLevel: Nullable<number>;
  coverageTypeCode?: CoverageTypeCode;
}

export type ActualCountyYieldFields = {
  actualCountyYield: number;
}

const ActualCountyYieldInput = ({ actualCountyYield, commodityCode, year, countyId, practiceId, typeId, insurancePlanCodes, sx, label, autoFocus = false, disabled, coverageTypeCode = CoverageTypeCode.A, volatility, coverageLevel }: ActualCountyYieldProps) => {
  const dispatch = useAppDispatch();
  const variation = getInsurancePlanCodeVariationForAreaYield(commodityCode, insurancePlanCodes, typeId !== null ? getTypeCodeFromTypeId(typeId) : undefined);
  usePercentChange('expectedCountyYield', 'actualCountyYield', 'countyYieldSlider');
  const countyYieldInfo = useAppSelector(selectYieldInfoWithCounties);
  const commodities = useAppSelector(selectCommoditiesWithOffers);

  const commodityName = commodities.find(x => x.commodityCode === commodityCode)?.name;

  useEffect(() => {
    dispatch(fetchCommoditiesWithOffers({ countyId, year }));
  }, []);

  useEffect(() => {
    if (typeId === null || practiceId === null) {
      return;
    }

    dispatch(fetchYieldInfoWithCounties({ year, countyId, typeId, practiceId, planCodes: insurancePlanCodes, coverageTypeCode }));
  }, [typeId, practiceId, insurancePlanCodes, coverageLevel, coverageTypeCode]);

  const toolTip = <CountyYieldInputToolTip
    countyYieldInfo={countyYieldInfo}
    coverageLevel={coverageLevel}
    commodityName={commodityName}
    volatility={volatility}
    variation={variation}
  />;

  return (
    <YieldInput
      name="actualCountyYield"
      sx={sx}
      theYield={actualCountyYield ?? 0}
      toolTip={toolTip}
      label={label ?? 'Actual County Yield'}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  );
};

export default ActualCountyYieldInput;
