import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScenarioPieceType } from '@silveus/calculations';
import { WritableDraft } from 'immer/dist/internal';
import { setSelectedIntervalsRequest, getAvailableIntervalsRequest, getSelectedIntervalsRequest, SetIntervalsProps, getIntervalPricesRequest } from '../services/requestInterception/intervalsRequestInterceptor';
import { ScenarioPieceSelectedInterval } from '../types/api/adm/ScenarioPieceSelectedInterval';
import { IntervalPriceSymbolPrefix, ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import { filterIntervalsByScenarioPieceType } from '../utils/intervalFilteringByScenarioPieceType';
import { updateKeyedStateVerbatim } from './sliceHelpers';
import { initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import { RootState } from './store';
import { createAppAsyncThunk } from './thunkHelpers';
import { groupByWithValueSelector } from '../utils/arrayUtils';
import { getPriceGroupSymbolPrefix } from '../utils/priceGroupUtils';
import { IntervalRange } from '../types/api/adm/IntervalRange';
import { IntervalPrice } from '../types/api/adm/IntervalPrice';

export interface IntervalsState {
  /** The currently selected intervals for a given selection of scenarios grouped by ScenarioPieceId */
  selectedIntervals: SliceDataState<ScenarioPieceId, ScenarioPieceSelectedInterval[]>;
  /** All available intervals */
  availableIntervals: IntervalRange[];
  intervalPrices: SliceDataState<IntervalPriceSymbolPrefix, IntervalPrice[]>;
}

const initialState: IntervalsState = {
  selectedIntervals: initialSliceDataState(),
  availableIntervals: [],
  intervalPrices: initialSliceDataState(),
};

/** Handles selected and available intervals state management concerns */
export const intervalsSlice = createSlice({
  name: 'intervals',
  initialState: initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAvailableIntervals.fulfilled, (state, action: PayloadAction<IntervalRange[]>) => {
        state.availableIntervals = action.payload;
      })
      .addCase(fetchSelectedIntervals.fulfilled, (state, action: PayloadAction<ScenarioPieceSelectedInterval[]>) => {
        const scenarioPieceIds = [...new Set(action.payload.map(interval => interval.scenarioPieceId))];
        updateSelectedIntervals(state, action.payload, scenarioPieceIds);
      })
      .addCase(setSelectedIntervals.fulfilled, (state, action: PayloadAction<SetIntervalsProps>) => {
        updateSelectedIntervals(state, action.payload.scenarioPieceSelectedIntervals, [action.payload.scenarioPieceId]);
      })
      .addCase(fetchIntervalPrices.fulfilled, (state, action: PayloadAction<IntervalPrice[]>) => {
        const intervalPricesWithKeys = action.payload.map(intervalPrice => ({ intervalPrice, key: getPriceGroupSymbolPrefix(intervalPrice.symbol) }));
        const groupedIntervalPrices = groupByWithValueSelector(intervalPricesWithKeys, ipwk => ipwk.key, ipwk => ipwk.intervalPrice);
        for (const [key, value] of groupedIntervalPrices.entries()) {
          updateKeyedStateVerbatim(state.intervalPrices.data, value, key);
        }
      });
  },
});

/** Local helper function to update state.selectedInvervals **/
function updateSelectedIntervals(state: WritableDraft<IntervalsState>, intervals: ScenarioPieceSelectedInterval[], scenarioPieceIds: ScenarioPieceId[]) {
  scenarioPieceIds.forEach(scenarioPieceId => {
    updateKeyedStateVerbatim(state.selectedIntervals.data, intervals.filter(interval => interval.scenarioPieceId === scenarioPieceId), scenarioPieceId);
  });
}

/** Returns the currently selected intervals for a given scenario piece */
export const selectIntervalsForScenarioPiece = (state: RootState, scenarioPieceId: ScenarioPieceId) => state.intervals.selectedIntervals.data[scenarioPieceId] ?? [];

export const selectSelectedIntervals = (state: RootState) => state.intervals.selectedIntervals.data;

/** Returns the currently available intervals for a given scenario piece type*/
export const getAvailableIntervalsForScenarioPieceType = (state: RootState, scenarioPieceType: ScenarioPieceType) => filterIntervalsByScenarioPieceType(state.intervals.availableIntervals, scenarioPieceType);

export const selectAvailableIntervals = (state: RootState) => state.intervals.availableIntervals;

export const selectIntervalPrices = (state: RootState) => state.intervals.intervalPrices.data;

/**
* Fetches the currently selected intervals for a given selection of scenarios
* @param scenarioIds The list of scenarios that will be queried to aggregate state.intervals.selectedIntervals
* */
export const fetchSelectedIntervals = createAppAsyncThunk('intervals/fetchSelectedIntervals',
  async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
    return getSelectedIntervalsRequest(scenarioIds);
  });

/**
* Sets the given selected intervals for a scenario piece to db. If that scenario piece already has intervals selected it will override them.
* @param scenarioPieceSelectedIntervals The list of intervals that be inserted/updated
* @param scenarioPieceId The id of the target scenario piece to inserted/updated
* */
export const setSelectedIntervals = createAppAsyncThunk('intervals/setSelectedIntervals',
  async (setProps: SetIntervalsProps) => {
    return await setSelectedIntervalsRequest(setProps);
  });

/**
* Gets available intervals
* @param onlyNew Flag to receive only future intervals
* */
export const fetchAvailableIntervals = createAppAsyncThunk('intervals/getAvailableIntervals',
  async ({ onlyNew }: { onlyNew: boolean }) => {
    return await getAvailableIntervalsRequest(onlyNew);
  });

/**
* Gets interval prices for a crop & year
* @param symbol Concatenation of interval crop code + contract month code + year (example: ZCZ14)
* */
export const fetchIntervalPrices = createAppAsyncThunk('intervals/getIntervalPrices',
  async ({ symbols }: { symbols: string[] }) => {
    return await getIntervalPricesRequest(symbols);
  });

// export reducer
export default intervalsSlice.reducer;
