import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import ScenarioPieceDefinition, { ScenarioPieceCardProps } from '../../../../types/app/ScenarioPieceDefinition';
import validationFunctionDefault from '../../defaultDefinitions/validationFunctionDefault';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import { getDefaultQuoteSummaryData } from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getDefaultQuoteSummaryData';
import { FarmBillScenarioPieceForm } from '../../../../pages/scenarioPiece/farmBill/farmBillScenarioPieceForm.component';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPiece } from '../../../../types/api/ScenarioPiece';
import {
  AddScenarioPieceCardChipFunction
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { isRowCropScenarioPiece } from '../../../../utils/castingUtils';
import FarmBillScenarioPieceCard from '../../../../pages/scenarioPiece/farmBill/farmBillScenarioPieceCard.component';
import FarmBillInactiveRowCropScenarioPieceCard from '../../../../pages/scenarioPiece/farmBill/farmBillInactiveRowCropScenarioPieceCard.component';
import { createFarmBillArcData } from '../../../../services/calculations/farmBill/farmBillArcDataTransformations';
import { createFarmBillPlcData } from '../../../../services/calculations/farmBill/farmBillPlcDataTransformations';
import { ScenarioPieceType, FarmBillCodeType } from '@silveus/calculations';
import { getFarmBillDisplayName } from '../../../../services/calculations/farmBill/farmBillHelpers';

const ProducerCountyScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
  if (isRowCropScenarioPiece(scenarioPiece) && scenarioPiece.scenarioPieceType === ScenarioPieceType.Arc) {
    return scenarioPiece.planCode === FarmBillCodeType.County ? 'County' : 'Producer';
  }
  return null;
};

const PayableEntitiesScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
  if (isRowCropScenarioPiece(scenarioPiece)) {
    if (scenarioPiece.rowCropScenarioPieceExtendedData?.payableEntitiesAmount !== undefined && scenarioPiece.rowCropScenarioPieceExtendedData.payableEntitiesAmount !== null) {
      return `${scenarioPiece.rowCropScenarioPieceExtendedData.payableEntitiesAmount} Payable Entities`;
    }
  }
  return null;
};

export const farmBillDefinition: ScenarioPieceDefinition = {
  validationFunction: validationFunctionDefault,
  d365ProductType: ProductType.MPCI,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    if (scenarioPiece.scenarioPieceType === ScenarioPieceType.Arc) {
      return createFarmBillArcData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
    }
    return createFarmBillPlcData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <FarmBillScenarioPieceForm
      {...props}
      selectedScenarioPieceType={props.selectedScenarioPieceType as ScenarioPieceType}
      scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}
    />;
  },
  customScenarioPieceCard: (props: ScenarioPieceCardProps) => {
    return <FarmBillScenarioPieceCard
      {...props}
    />;
  },
  customScenarioInactivePieceCard: (props: ScenarioPieceCardProps) => {
    return <FarmBillInactiveRowCropScenarioPieceCard
      {...props}
    />;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getDefaultQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
    if (isRowCropScenarioPiece(scenarioPiece)) {
      return getFarmBillDisplayName(scenarioPiece.scenarioPieceType, scenarioPiece.planCode, false);
    }
    return 'FarmBill';
  },
  getScenarioPieceCardChips: [ProducerCountyScenarioPieceChip, PayableEntitiesScenarioPieceChip],
  shouldShowThreeStageSummaryExpander: false,
};
