import { ReportFormFields, ReportGenerationModal } from './reportGenerationModal';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ScenarioPieceType } from '@silveus/calculations';
import { useState } from 'react';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { TriggerYieldReportData } from '../../types/api/reports/TriggerYieldReportData';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { selectCurrentClientFile } from '../../app/clientFilesSlice';
import {
  selectAllScenariosByClientFileIdMap,
  selectScenariosByIds,
  selectUnitYearsForScenarios
} from '../../app/scenariosSlice';
import { selectCalculationsByScenarioPiece, selectScenarioRequests } from '../../app/calculationResultsSlice';
import { selectAllUnitGroupsByScenarioPieceMap } from '../../app/unitGroupsSlice';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../app/scenarioPiecesSlice';
import { selectUnitYearAphByUnitYearMap } from '../../app/unitYearAphSlice';
import { generateTriggerYieldReport } from '../../app/reportsSlice';
import { OptionalColumnInput } from '../formInputs/reports/optionalColumnInput';
import { FarmDescriptor, FarmNameTractNumberInput } from '../formInputs/reports/triggerYield/farmNameTractNumberInput';
import { OptionalProductsInput } from '../formInputs/reports/triggerYield/optionalProductsInput';
import { Grid } from '@mui/material';
import { selectQuotesByClientFileId } from '../../app/quotesSlice';
import { stableEmptyArrayAsMutable } from '../../utils/stableEmptyArray';
import { Quote } from '../../types/api/Quote';
import { getReportUnitDataByScenarioId } from './utils/getReportUnitDataByScenarioId';
import { getReportProductDataByUnitYear } from './utils/getReportProductDataByUnitYear';
import { getKeyedStateToMap } from '../../app/sliceHelpers';
import { useInsuredContact } from './hooks/useInsuredContact';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { getItemsForId } from '../../utils/mapHelpers';
import { getContactInfoFromInsured } from './utils/getContactInfoFromInsured';

interface TriggerYieldProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface TriggerYieldFameNameTractNumberFields {
  farmNameTractNumber: FarmDescriptor;
}

interface TriggerYieldAdditionalOptionFields {
  onlyShowEarliestTrigger: boolean;
  includeDateGenerated: boolean;
}

interface TriggerYieldOptionalProductFields {
  excludedScenarioPieces: ScenarioPieceType[];
}

type TriggerYieldReportFormFields = ReportFormFields & TriggerYieldAdditionalOptionFields & TriggerYieldFameNameTractNumberFields & TriggerYieldOptionalProductFields;

export const TriggerYieldReportGenerationModal = ({ open, setOpen }: TriggerYieldProps) => {
  const dispatch = useAppDispatch();
  const methods = useForm<TriggerYieldReportFormFields>();
  const clientFile = useAppSelector(selectCurrentClientFile);
  const insured = useInsuredContact();
  const quotes = useAppSelector(state => clientFile === null ? stableEmptyArrayAsMutable<Quote>() : selectQuotesByClientFileId(state, clientFile.clientFileId));

  const scenarioPieces = useAppSelector(selectAllRowCropScenarioPiecesByScenarioMap);

  const scenariosByClientFile = useAppSelector(selectAllScenariosByClientFileIdMap);
  const scenariosForClientFile = clientFile === null ? stableEmptyArrayAsMutable<RowCropScenario>() : getItemsForId(scenariosByClientFile, clientFile.clientFileId);

  const filteredScenarios = scenariosForClientFile.filter(s => s.isVisible);

  const filteredScenarioPieces = new Map(
    Array.from(scenarioPieces.entries())
      .map(([scenarioId, pieces]) => [
        scenarioId,
        pieces.filter(piece => !piece.isInvalid && piece.isActive),
      ]),
  );

  const [includedScenarios, setIncludedScenarios] = useState<ScenarioId[]>(filteredScenarios.map(s => s.scenarioId));

  const unitsByScenario = useAppSelector(state => selectUnitYearsForScenarios(state, includedScenarios));
  const unitGroups = useAppSelector(selectAllUnitGroupsByScenarioPieceMap);
  const calculations = useAppSelector(selectCalculationsByScenarioPiece);
  const scenarios = useAppSelector(state => selectScenariosByIds(state, includedScenarios));
  const unitHistory = useAppSelector(selectUnitYearAphByUnitYearMap);
  const scenarioRequests = useAppSelector(selectScenarioRequests);

  if (clientFile === null || insured === null) {
    setOpen(false);
    return null;
  }

  const additionalOptions = [
    <OptionalColumnInput key={1} name="includeDateGenerated" label="Include Date Generated" />,
    <OptionalColumnInput key={2} name="onlyShowEarliestTrigger" label="Only Show Earliest Trigger" defaultValue={false} />,
  ];

  const formId = 'triggerYieldReportForm';

  const onSubmit: SubmitHandler<TriggerYieldReportFormFields> = async data => {

    const unitReportDataByScenarioId = getReportUnitDataByScenarioId(
      unitsByScenario,
      clientFile.year,
      scenarios,
      quotes,
      getKeyedStateToMap(scenarioRequests),
      insured.insuredId,
      unitHistory,
    );

    const scenarioPiecesByUnitMap = getReportProductDataByUnitYear(
      includedScenarios,
      data.excludedScenarioPieces,
      filteredScenarioPieces,
      scenarios,
      quotes,
      calculations,
      unitGroups,
      unitsByScenario,
    );

    const triggerYieldReportData: TriggerYieldReportData = {
      cropYear: clientFile.year,
      hasGenerationDate: data.includeDateGenerated,
      hasPageNumbers: true,
      insuredContactInformation: getContactInfoFromInsured(insured.contact),
      agentContactInformation: null,
      onlyShowEarliestTriggers: data.onlyShowEarliestTrigger,
      productsByUnitYearId: Object.fromEntries(scenarioPiecesByUnitMap),
      reportDocumentType: data.generatedDocumentType,
      scenarios: scenarios,
      unitsByScenarioId: Object.fromEntries(unitReportDataByScenarioId),
      useTractNumber: data.farmNameTractNumber === FarmDescriptor.TractNumber,
    };

    await dispatch(generateTriggerYieldReport({ triggerYieldReportData }));

    setOpen(false);
  };

  const optionalProducts = (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        Select Optional Products:
      </Grid>
      <Grid item xs={12}>
        <OptionalProductsInput includedScenarios={includedScenarios} scenarioPieces={filteredScenarioPieces} />
      </Grid>
    </Grid>
  );

  const farmNameTractNumber = (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        Farm Name or Tract #:
      </Grid>
      <Grid item xs={12}>
        <FarmNameTractNumberInput />
      </Grid>
    </Grid>
  );

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
        <ReportGenerationModal
          title="Trigger Yield Summary"
          handleClose={() => setOpen(false)}
          open={open}
          additionalOptions={additionalOptions}
          formId={formId}
          contentBeforeAdditionalOptions={farmNameTractNumber}
          contentAfterAdditionalOptions={optionalProducts}
          includedScenarios={includedScenarios}
          setIncludedScenarios={setIncludedScenarios}
        />
      </form>
    </FormProvider>
  );
};