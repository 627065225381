import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { PremiumBreakdownId, ScenarioId } from '../types/api/PrimaryKeys';
import PremiumBreakdown from '../types/api/premiumBreakdown';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { RootState } from './store';
import { getKeyedStateValues } from './sliceHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import {
  createPremiumBreakdownRequest, getPremiumBreakdownsByScenarioIdsRequest,
  updatePremiumBreakdownRequest
} from '../services/requestInterception/premiumBreakdownRequestInterceptor';
import { Nullable } from '../types/util/Nullable';
import PremiumBreakdownData from '../types/app/PremiumBreakdownData';

interface PremiumBreakdownState {
  allPremiumBreakdowns: SliceDataState<PremiumBreakdownId, PremiumBreakdown>;
  selectedPremiumBreakdownData: Nullable<PremiumBreakdownData>;
}

const initialState: PremiumBreakdownState = {
  allPremiumBreakdowns: initialSliceDataState(),
  selectedPremiumBreakdownData: null,
};

export const premiumBreakdownSlice = createSlice({
  name: 'PremiumBreakdown',
  initialState: initialState,
  reducers: {
    setSelectedPremiumBreakdownData(state, action: PayloadAction<Nullable<PremiumBreakdownData>>) {
      if (state.selectedPremiumBreakdownData?.id === action.payload?.id) {
        state.selectedPremiumBreakdownData = null;
      } else {
        state.selectedPremiumBreakdownData = action.payload;
      }
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allPremiumBreakdowns, s => s.premiumBreakdownId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchPremiumBreakdownsByScenarioIds,
      affectedIds: (_, state) => getKeyedStateValues(state.allPremiumBreakdowns.data).map(i => i.premiumBreakdownId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addPremiumBreakdown,
      affectedIds: arg => arg.premiumBreakdown.premiumBreakdownId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyPremiumBreakdown,
      affectedIds: arg => arg.premiumBreakdown.premiumBreakdownId,
    });
  },
});

export const {
  setSelectedPremiumBreakdownData,
} = premiumBreakdownSlice.actions;

export default premiumBreakdownSlice.reducer;

const selectPremiumBreakdownsDictionary = (state: RootState) => state.premiumBreakdown.allPremiumBreakdowns.data;
export const selectSelectedPremiumBreakdownData = (state: RootState) => state.premiumBreakdown.selectedPremiumBreakdownData;

export const selectAllPremiumBreakdowns = createSelector([selectPremiumBreakdownsDictionary], result => {
  const premiumBreakdowns = getKeyedStateValues(result);
  const map = new Map<ScenarioId, PremiumBreakdown>();
  premiumBreakdowns.forEach(p => {
    map.set(p.primaryScenarioId, p);
  });

  return map;
});

export const fetchPremiumBreakdownsByScenarioIds = createAppAsyncThunk('premiumBreakdown/fetchPremiumBreakdownsByScenarioIds', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  const premiumBreakdowns = await getPremiumBreakdownsByScenarioIdsRequest(scenarioIds);
  return premiumBreakdowns;
});

export const addPremiumBreakdown = createAppAsyncThunk('premiumBreakdown/addPremiumBreakdown', async ({ premiumBreakdown }: { premiumBreakdown: PremiumBreakdown }) => {
  await createPremiumBreakdownRequest(premiumBreakdown);
  return premiumBreakdown;
});

export const modifyPremiumBreakdown = createAppAsyncThunk('premiumBreakdown/modifyPremiumBreakdown', async ({ premiumBreakdown }: { premiumBreakdown: PremiumBreakdown }) => {
  await updatePremiumBreakdownRequest(premiumBreakdown);
  return premiumBreakdown;
});