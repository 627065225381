import { useEffect, useState } from 'react';
import { HailProduct } from '../../types/api/hail/hailProduct';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { HailPlanType } from '../../types/api/enums/hail/hailPlanType';
import { HailProductParams } from '../../types/api/adm/PrivateProductsParams';
import { Quote } from '../../types/api/Quote';
import { ClientFile } from '../../types/api/ClientFile';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../hooks/reduxHooks';
import { getHailProductsRequest } from '../../services/requestInterception/scenarioPieces/hailScenarioPieceRequestInterceptor';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../app/scenarioPiecesSlice';
import { AvailabilityService, roundToPlaces } from '@silveus/calculations';
import { Nullable } from '../../types/util/Nullable';
import { selectUnitYears } from '../../app/scenariosSlice';
import { parseProductNameToScenarioPieceType } from '../../constants/hailPlanToScenarioPieceType';
import HailScenarioPiece from '../../types/api/HailScenarioPiece';
import { HailProductId, HailScenarioPieceId } from '../../types/api/PrimaryKeys';
import { generatePrimaryKey } from '../../utils/primaryKeyHelpers';
import HailScenarioPieceRate from '../../types/api/hail/HailScenarioPieceRate';
import { getHailScenarioPieceRates, sortHailRates } from './utils/hailRateUtils';
import UnitYear from '../../types/api/UnitYear';
import { stableEmptyArrayAsMutable } from '../../utils/stableEmptyArray';
import {
  addHailScenarioPiece,
  modifyHailScenarioPiece,
  modifyHailScenarioPieceRates,
  removeHailScenarioPiece,
  selectHailScenarioPieceCompositionId,
  selectHailScenarioPieceModalEndorsementsByHailScenarioPieceMap,
  selectHailScenarioPieceModalRatesByHailScenarioPieceMap,
  selectHailScenarioPiecesByHailProductId,
  selectHailScenarioPieceByHailScenarioPieceId,
  removeHailScenarioPieceRates,
  addHailScenarioPieceRates,
  removeHailScenarioPieceEndorsements,
  addHailScenarioPieceEndorsements,
  selectHailScenarioPieceModalUnitsByHailScenarioPiece,
  modifyHailScenarioPieceUnits
} from '../../app/hailModalSlice';
import HailScenarioPieceEndorsement from '../../types/api/hail/HailScenarioPieceEndorsement';
import { getAvailableHailEndorsementsByHailProductId, getAvailableHailRatesByHailProductId, getHailProductById, getSelectedHailScenarioPieceSelectedEndorsements, getSelectedHailScenarioPieceSelectedRates, getSelectedHailScenarioPieceUnits, getSelectedHailScenarioPieceUnitsFromUnitGroup } from './utils/useHailUtils';
import { selectAllHailScenarioPiecesByScenarioMap, selectHailScenarioPieceEndorsementsByHailScenarioPieceMap, selectHailScenarioPieceRatesByHailScenarioPieceMap } from '../../app/hailSlice';
import { selectAllUnitGroupsByScenarioPieceMap } from '../../app/unitGroupsSlice';

export const useHail = (scenario: Nullable<RowCropScenario>, quote: Nullable<Quote>, clientFile: Nullable<ClientFile>) => {
  const dispatch = useAppDispatch();
  const [availableHailProducts, setAvailableProducts] = useState<HailProduct[]>([]);
  const [availableHailProductsByProductId, setAvailableHailProductsByProductId] = useState<Map<HailProductId, HailProduct>>(new Map<HailProductId, HailProduct>());
  // ToDo: this set is empty to start for now but we'll eventually need to pull in previous saved hail scenario pieces and make sure
  // when the modal loads anything we've saved as selected product also shows saved here.
  const hailScenarioPieceCompositionId = useAppSelector(s => selectHailScenarioPieceCompositionId(s));
  const hailScenarioPieces = useAppSelector(s => selectHailScenarioPieceByHailScenarioPieceId(s));
  const hailScenarioPieceRates = useAppSelector(s => selectHailScenarioPieceModalRatesByHailScenarioPieceMap(s));
  const hailScenarioPieceEndorsements = useAppSelector(s => selectHailScenarioPieceModalEndorsementsByHailScenarioPieceMap(s));
  const hailScenarioPiecesByProductId = useAppSelector(s => selectHailScenarioPiecesByHailProductId(s));
  const hailScenarioPiecesUnits = useAppSelector(s => selectHailScenarioPieceModalUnitsByHailScenarioPiece(s));

  const selectedHailScenarioPiecesFromScenario = useKeyMapSelector(selectAllHailScenarioPiecesByScenarioMap, scenario?.scenarioId);
  const selectedHailScenarioPieceRates = useAppSelector(s => selectHailScenarioPieceRatesByHailScenarioPieceMap(s));
  const selectedHailScenarioPieceEndorsements = useAppSelector(s => selectHailScenarioPieceEndorsementsByHailScenarioPieceMap(s));
  const selectedHailScenarioPieceUnits = useAppSelector(s => selectAllUnitGroupsByScenarioPieceMap(s));

  const rowCropScenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenario?.scenarioId);
  const unitYears = useAppSelector(state => scenario !== null ? selectUnitYears(state, scenario.quoteId, scenario.typeId, scenario.practiceId, scenario.highRiskTypeId) : stableEmptyArrayAsMutable<UnitYear>());

  useEffect(() => {
    const fetchData = async () => {
      if (scenario !== null && quote !== null && clientFile !== null) {
        const plans: HailProduct[] = [];
        const hailPlansByProductId = new Map<HailProductId, HailProduct>();
        const fileYear = clientFile.year;
        const cropYears = [fileYear - 1, fileYear];
        const hailProductParams: HailProductParams = { commodityCode: quote.commodityCode, countyId: quote.countyId, cropYears: cropYears, hailProductIds: [] };

        const loadProducts = async (hailProductParams: HailProductParams) => {
          const hailProducts = await getHailProductsRequest(hailProductParams);
          hailProducts.forEach(hailProduct => {
            hailProduct.maxRate = Math.max(...hailProduct.hailRates.map(hailRate => hailRate.rate));
            hailProduct.minRate = Math.min(...hailProduct.hailRates.map(hailRate => hailRate.rate));
            hailProduct.rate = Math.max(...hailProduct.hailRates.map(hailRate => hailRate.rate)); // TODO -> defaulting to max for now, but will need logic in the future
            hailProduct.policy = parseProductNameToScenarioPieceType(hailProduct);
            hailProduct.planType = hailProduct.isProduction ? HailPlanType.Production : HailPlanType.Standard;
            // if standard - add
            if (!hailProduct.isProduction) {
              plans.push(hailProduct);
              hailPlansByProductId.set(hailProduct.hailProductId, hailProduct);
            }
            // if production - add if underlying exists and coverage level matches
            else if (hailProduct.mpciCoverageLevel !== undefined) {
              if (hailProduct.policy === null) return;
              const underlyingPieces = AvailabilityService.getScenarioPiecesThatThisScenarioPieceDependsOn(hailProduct.policy.policyType);
              const underlying = rowCropScenarioPieces.find(rowCropScenarioPiece => underlyingPieces.includes(rowCropScenarioPiece.scenarioPieceType));
              if (underlying === undefined) return;

              const hailCoverageLevelToCompare = hailProduct.mpciCoverageLevel * 100;
              if (hailCoverageLevelToCompare === underlying.upperCoverageLevel) {
                plans.push(hailProduct);
                hailPlansByProductId.set(hailProduct.hailProductId, hailProduct);
              }
            }
          });
        };

        await loadProducts(hailProductParams);
        setAvailableProducts(plans);
        setAvailableHailProductsByProductId(hailPlansByProductId);

        const loadExistingHailScenarioPieces = async () => {
          const addHailScenarioPiecePromises = selectedHailScenarioPiecesFromScenario.map(selectedHailScenarioPiece => {
            const updatedHailScenarioPiece: HailScenarioPiece = {
              ...selectedHailScenarioPiece,
              hailScenarioPieceCompositionId: hailScenarioPieceCompositionId,
            };
            const availableRates = getAvailableHailRatesByHailProductId(hailPlansByProductId, selectedHailScenarioPiece.hailProductId);
            const hailScenarioPieceRates = getSelectedHailScenarioPieceSelectedRates(selectedHailScenarioPieceRates, selectedHailScenarioPiece.hailScenarioPieceId);
            const sortedRates = sortHailRates(hailScenarioPieceRates, availableRates);
            const hailScenarioPieceEndorsements = getSelectedHailScenarioPieceSelectedEndorsements(selectedHailScenarioPieceEndorsements, selectedHailScenarioPiece.hailScenarioPieceId);
            const hailScenarioPieceUnits = quote.quickQuote === false ? getSelectedHailScenarioPieceUnitsFromUnitGroup(selectedHailScenarioPieceUnits, selectedHailScenarioPiece.scenarioPieceId) : [];
            return dispatch(addHailScenarioPiece({ hailScenarioPiece: updatedHailScenarioPiece, hailScenarioPieceRates: sortedRates, hailScenarioPieceEndorsements: hailScenarioPieceEndorsements, hailScenarioPieceUnits: hailScenarioPieceUnits }));
          });
          await Promise.all(addHailScenarioPiecePromises);
        };
        await loadExistingHailScenarioPieces();
      }
    };

    fetchData();
  }, []);

  const getHailProductByIdUseHail = (hailProductId: HailProductId) => {
    return getHailProductById(availableHailProductsByProductId, hailProductId);
  };

  const getAvailableHailRatesByHailProductIdUseHail = (hailProductId: HailProductId) => {
    return getAvailableHailRatesByHailProductId(availableHailProductsByProductId, hailProductId);
  };

  const getAvailableHailEndorsementsByHailProductIdUseHail = (hailProductId: HailProductId) => {
    return getAvailableHailEndorsementsByHailProductId(availableHailProductsByProductId, hailProductId);
  };

  const getSelectedHailScenarioPieceSelectedRatesUseHail = (hailSenarioPieceId: HailScenarioPieceId) => {
    return getSelectedHailScenarioPieceSelectedRates(hailScenarioPieceRates, hailSenarioPieceId);
  };

  const getSelectedHailScenarioPieceSelectedEndorsementsUseHail = (hailSenarioPieceId: HailScenarioPieceId) => {
    return getSelectedHailScenarioPieceSelectedEndorsements(hailScenarioPieceEndorsements, hailSenarioPieceId);
  };

  const getSelectedHailScenarioPieceUnitsUseHail = (hailSenarioPieceId: HailScenarioPieceId) => {
    return getSelectedHailScenarioPieceUnits(hailScenarioPiecesUnits, hailSenarioPieceId);
  };

  const toggleHailPlanSelected = async (hailProduct: HailProduct) => {
    if (hailProduct.policy === null) return;
    const isHailProductSelected = hailScenarioPiecesByProductId.has(hailProduct.hailProductId);
    if (!isHailProductSelected && quote !== null && scenario !== null) {
      // Create a hail scenario piece and add it to the list
      const newSelectedHailScenarioPiece: HailScenarioPiece = {
        hailScenarioPieceId: generatePrimaryKey(),
        hailScenarioPieceCompositionId: hailScenarioPieceCompositionId,
        hailProductId: hailProduct.hailProductId,
        hailPlanType: hailProduct.isProduction ? HailPlanType.Production : HailPlanType.Standard,
        upperCoverageLevel: hailProduct.isProduction ? roundToPlaces((hailProduct.productionPlanCoverageLevel ?? 0) * 100, 0) : 0,
        lowerCoverageLevel: 0,
        lossPercent: 0,
        discountPercent: 0,
        discountedRate: 0,
        coveragePerAcre: 100,
        priceElectionPercent: 100,
        budget: 0,
        budgetPercent: 100,
        includeInBlended: !hailProduct.isProduction,
        customRatePerAcre: null,
        customRatePerHundred: null,

        //Scenario Piece Fields
        scenarioPieceId: generatePrimaryKey(),
        scenarioId: scenario.scenarioId,
        scenarioPieceType: hailProduct.policy.policyType,
        isActive: true,
        isInvalid: false,

        //OfflineChangeTrackedEntity Fields
        offlineCreatedOn: undefined,
        offlineLastUpdatedOn: undefined,
        offlineDeletedOn: undefined,
      };

      //Figure out what rates to pre-select for this hail scenario piece
      //TODO maybe figure out how to use hook here instead of function? Also how to handle units that have been selected/de-selected previously?
      const availableRates = getAvailableHailRatesByHailProductId(availableHailProductsByProductId, newSelectedHailScenarioPiece.hailProductId);
      const newHailScenarioPieceRates = getHailScenarioPieceRates(quote, scenario, newSelectedHailScenarioPiece.hailScenarioPieceId, unitYears, availableRates);

      //Auto-select all units when unit quoting
      const newHailScenarioPieceUnits = quote.quickQuote === false ? unitYears.map(unit => unit.unitYearId) : [];

      await dispatch(addHailScenarioPiece({ hailScenarioPiece: newSelectedHailScenarioPiece, hailScenarioPieceRates: newHailScenarioPieceRates, hailScenarioPieceEndorsements: [], hailScenarioPieceUnits: newHailScenarioPieceUnits }));
    } else {
      // Remove the hail scenario piece from the list
      const existingHailScenarioPiece = hailScenarioPiecesByProductId.get(hailProduct.hailProductId);
      if (existingHailScenarioPiece === undefined) return;

      await dispatch(removeHailScenarioPiece({ hailScenarioPiece: existingHailScenarioPiece }));
      // TODO : figure out if we can just remove this
      // const tempCalculatedValues: Record<number, HailCalculatedValues> = { ...hailCalculatedValues };
      // delete tempCalculatedValues[hailProduct.hailProductId];
      // setHailCalculatedValues(tempCalculatedValues);
    }
  };

  const updateHailScenarioPiece = async (updatedScenarioPiece: HailScenarioPiece) => {
    await dispatch(modifyHailScenarioPiece({ hailScenarioPiece: updatedScenarioPiece }));
  };

  const updateHailScenarioPieceLossChanged = async (updatedScenarioPiece: HailScenarioPiece) => {
    //This updates all other hail scenario pieces to match the new loss percent passed in
    const oldHailScenarioPieces = Array.from(hailScenarioPieces.values());
    const dispatchPromises = oldHailScenarioPieces.map(hsp => {
      const updatedHSP = {
        ...hsp,
        lossPercent: updatedScenarioPiece.lossPercent,
      };
      return dispatch(modifyHailScenarioPiece({ hailScenarioPiece: updatedHSP }));
    });

    // Wait for all dispatch actions to complete
    await Promise.all(dispatchPromises);
  };

  const updateHailScenarioPieceRates = async (updatedScenarioPieceRates: HailScenarioPieceRate[]) => {
    await dispatch(modifyHailScenarioPieceRates({ hailScenarioPieceRates: updatedScenarioPieceRates }));
  };

  const updateHailScenarioPieceEndorsements = async (hailScenarioPieceId: HailScenarioPieceId, updatedHailScenarioPieceEndorsements: HailScenarioPieceEndorsement[]) => {
    const oldEndorsements = getSelectedHailScenarioPieceSelectedEndorsements(hailScenarioPieceEndorsements, hailScenarioPieceId);
    await dispatch(removeHailScenarioPieceEndorsements({ hailScenarioPieceEndorsements: oldEndorsements }));
    await dispatch(addHailScenarioPieceEndorsements({ hailScenarioPieceEndorsements: updatedHailScenarioPieceEndorsements }));
  };

  const updateHailScenarioPieceUnits = async (quote: Quote, scenario: RowCropScenario, hailScenarioPiece: HailScenarioPiece, updatedUnitSelection: UnitYear[]) => {
    const hailScenarioPieceId = hailScenarioPiece.hailScenarioPieceId;
    const unitYearIds = updatedUnitSelection.map(unit => unit.unitYearId);
    //We need to update the selected rates whenever a unit is selected/de-selected as this controls what rates show up in township/range modal
    //Also we don't want to hold on to rates that are no longer relevant if a unit was removed that used it
    const availableRates = getAvailableHailRatesByHailProductId(availableHailProductsByProductId, hailScenarioPiece.hailProductId);
    const newRatesBasedOnNewUnitSelections = getHailScenarioPieceRates(quote, scenario, hailScenarioPieceId, updatedUnitSelection, availableRates);

    const oldRates = getSelectedHailScenarioPieceSelectedRates(hailScenarioPieceRates, hailScenarioPieceId);
    await dispatch(removeHailScenarioPieceRates({ hailScenarioPieceRates: oldRates }));
    await dispatch(addHailScenarioPieceRates({ hailScenarioPieceRates: newRatesBasedOnNewUnitSelections }));
    await dispatch(modifyHailScenarioPieceUnits({ hailScenarioPieceId: hailScenarioPieceId, unitYearIds }));
  };



  return {
    hailScenarioPieceCompositionId,
    hailScenarioPieces,
    hailScenarioPiecesByProductId,
    availableHailProducts,
    toggleHailPlanSelected,
    updateHailScenarioPiece,
    updateHailScenarioPieceLossChanged,
    updateHailScenarioPieceRates,
    updateHailScenarioPieceUnits,
    updateHailScenarioPieceEndorsements,
    getHailProductByIdUseHail,
    getAvailableHailRatesByHailProductIdUseHail,
    getAvailableHailEndorsementsByHailProductIdUseHail,
    getSelectedHailScenarioPieceSelectedRatesUseHail,
    getSelectedHailScenarioPieceSelectedEndorsementsUseHail,
    getSelectedHailScenarioPieceUnitsUseHail,
  };
};