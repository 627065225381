import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import BaseUnit from '../../../types/api/BaseUnit';
import {
  CoverageTypeCode,
  MyEcoIndemnityParams,
  MyEcoManualPremiumParams,
  MyEcoRequestDto,
  MyEcoUnit,
  ScenarioPieceType,
  Unit
} from '@silveus/calculations';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../../app/scenarioPiecesSlice';
import { getItemsForId } from '../../../utils/mapHelpers';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getUnitsForScenarioPiece } from '../calculationUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';

export const createMyEcoData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Get the underling scenario piece
  const underlyingEco = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), scenario.scenarioId).find(sp => [ScenarioPieceType.EcoYp, ScenarioPieceType.EcoRp].includes(sp.scenarioPieceType));
  if (underlyingEco === undefined) throw new Error('Could not find underlying Eco for MyEco calcs');

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createMyEcoDto(scenarioPiece, unitsForScenarioPiece, scenario, underlyingEco.lowerCoverageLevel / 100, underlyingEco.scenarioPieceType, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformMyEcoData = (scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece, ecoCoverageLevel: number, ecoBasePlan: ScenarioPieceType): MyEcoManualPremiumParams & MyEcoIndemnityParams => {
  const myEcoParams: MyEcoManualPremiumParams & MyEcoIndemnityParams = {
    coverageTypeCode: CoverageTypeCode.A,
    harvestPrice: scenario.harvestPrice ?? 0,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    premiumPerAcre: scenarioPiece.rowCropScenarioPieceExtendedData?.customPremium ?? 0,
    projectedPrice: scenario.projectedPrice ?? 0,
    unitStructureCode: scenarioPiece.unitStructure,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    basePlanCoverageLevelPercent: ecoCoverageLevel,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
    basePlan: ecoBasePlan,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,
  };

  return myEcoParams;
};

const createMyEcoUnits = (units: BaseUnit[]): (MyEcoUnit & Unit)[] => {
  const myEcoUnits = units.map(u => {
    const unitDto = createBaseUnit(u);
    const myEcoUnit: MyEcoUnit & Unit = {
      ...unitDto,
      id: u.id,
    };

    return myEcoUnit;
  });

  return myEcoUnits;
};

const createMyEcoDto = (scenarioPiece: RowCropScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, ecoCoverageLevel: number, ecoBasePlan: ScenarioPieceType, unitGroups: UnitGroup[]): MyEcoRequestDto => {
  const myEcoRequest: MyEcoRequestDto = {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createMyEcoUnits(
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
      ),
    ),
    ...transformMyEcoData(scenario, scenarioPiece, ecoCoverageLevel, ecoBasePlan),
  };

  return myEcoRequest;
};
