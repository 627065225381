import { useEffect, useState } from 'react';
import { TextField, Tooltip, styled } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import HarvestRevenueTransactionPropsBase from './harvestRevenueTransactionPropsBase';
import { HarvestRevenueScenarioPieceFormFields } from '../harvestRevenueScenarioPieceForm.component';
import { AdjustmentType, roundToPlaces } from '@silveus/calculations';
import { Nullable } from '../../../../types/util/Nullable';
import { formatProductionValue, getProductionAdjustmentType, getSafeProduction } from './harvestRevenueUtils';
import { SimulatedResults } from '@silveus/calculations/dist/shared/results/simulatedResults';
import { onNumericStringInputChange } from '../../../../components/numberTextField/numberTextField.component';
import { parseNumber } from '../../../../utils/stringUtils';

const StyledTextField = styled(TextField)({
  padding: '0px',
  width: '70px',
  '& div': {
    fontSize: '12px !important',
    '& input': {
      paddingLeft: '4px',
    },
  },
});

const HarvestRevenueTransactionProduction = (
  { index,
    disabled,
    calcResults,
    onTransactionValueChanged }:
    HarvestRevenueTransactionPropsBase
    &
    {
      calcResults: Nullable<SimulatedResults>,
    },
) => {
  const { control, getValues } = useFormContext<HarvestRevenueScenarioPieceFormFields>();
  const [isFocused, setIsFocused] = useState(false);
  const [typedText, setTypedText] = useState<string>('');
  const listOfProductions = getValues().harvestRevenueTransactions.map(x => x.productionAdjustmentTypeId);
  const correspondingProductionType = useWatch({ name: `harvestRevenueTransactions.${index}.productionAdjustmentTypeId`, control: control, defaultValue: listOfProductions[index] });

  const [localDisable, setLocalDisable] = useState(false);
  useEffect(() => {
    setLocalDisable(getProductionAdjustmentType(index, getValues) === AdjustmentType.Remaining);
  }, [correspondingProductionType]);

  const handleFocus = () => setIsFocused(true);

  return (
    <>
      <Controller
        name={`harvestRevenueTransactions.${index}.production`}
        control={control}
        rules={{
          required: VALIDATION_RULES.required(),
        }}
        render={({
          field: { ref, ...field }, fieldState: { error },
        }) => {
          const prodValue = getSafeProduction(calcResults, index) ?? 0;
          const displayValue = isFocused ? typedText : formatProductionValue(getProductionAdjustmentType(index, getValues), field.value, prodValue);
          return (
            <Tooltip title={displayValue} placement="top-start" enterDelay={1000}>
              <StyledTextField
                {...field}
                // When the field is not focused, display the formatted value
                value={displayValue}
                disabled={disabled || localDisable}
                onFocus={() => {
                  setTypedText(field.value.toString());
                  handleFocus();
                }}
                onBlur={event => {
                  const cleanedValue = roundToPlaces(parseNumber(event.target.value), 0);
                  field.onChange(cleanedValue);
                  setIsFocused(false);
                }}
                onChange={e => {
                  setTypedText(e.target.value);
                  onNumericStringInputChange(e, field);
                  onTransactionValueChanged();
                }}
                error={!!error}
                helperText={error?.message}
                size="small"
                required
                type="text"
              />
            </Tooltip>
          );
        }}
      />
    </>
  );
};

export default HarvestRevenueTransactionProduction;