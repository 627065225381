import axios from 'axios';
import { AgentTeamId, ApplicationWizardId, ClientFileId, InsuredId } from '../types/api/PrimaryKeys';
import { AppTask, AppTaskExtended, AppTaskStatusUpdate } from '../types/api/AppTask';
import { AIP } from '../types/api/aip';
import { AgentCode } from '../types/api/agentCode';
import { SalesClosingDate } from '../types/api/applicationWizard/salesClosingDate';
import { envBaseApiUrl } from '../constants/envConstants';
import { AppTaskDocumentId, AppTaskId } from '../types/api/PrimaryKeys';
import { AppTaskDocument } from '../types/api/appTaskDocument';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { Change } from '../types/api/applicationWizard/change';
import { AppTaskStatusResult } from '../types/api/appTaskStatusResult';
import { AppTaskDocumentUpload } from '../pages/applications/appTaskDocumentUpload';

export const getAppTasksWithoutCoverages = (insuredId: InsuredId, cropYear: number, applicationWizardId: ApplicationWizardId) => axios.get<AppTask[]>(`${envBaseApiUrl}/insureds/${insuredId}/app-tasks/${cropYear}/without-coverages/${applicationWizardId}`);
export const getAppTasksWithCoverages = (insuredId: InsuredId, cropYear: number, applicationWizardId: ApplicationWizardId) => axios.get<AppTask[]>(`${envBaseApiUrl}/insureds/${insuredId}/app-tasks/${cropYear}/with-coverages/${applicationWizardId}`);
export const getAppTask = (appTaskId: AppTaskId) => axios.get<AppTaskExtended>(`${envBaseApiUrl}/app-tasks/${appTaskId}`);
export const getAppTasksForAppWizard = (appWizardId: ApplicationWizardId) => axios.get<AppTaskExtended[]>(`${envBaseApiUrl}/app-tasks/app-wizard-app-tasks/${appWizardId}`);
export const getAIPs = () => axios.get<AIP[]>(`${envBaseApiUrl}/app-tasks/aips`);
export const getAppTaskSummaries = (insuredId: InsuredId, year: number) => axios.get<AppTaskExtended[]>(`${envBaseApiUrl}/insureds/${insuredId}/app-task-summaries/${year}`);
export const getAgentCodes = (agentTeamId: AgentTeamId) => axios.get<AgentCode[]>(`${envBaseApiUrl}/app-tasks/agentCodes/${agentTeamId}`);
export const getSalesClosingDates = (cropYear: number) => axios.get<SalesClosingDate[]>(`${envBaseApiUrl}/app-tasks/sales-closing-dates/${cropYear}`);
export const updateAppTaskStatus = (appTaskStatusUpdate: AppTaskStatusUpdate) => axios.put(`${envBaseApiUrl}/app-tasks/`, appTaskStatusUpdate);
export const recallAppTask = (appTaskId: AppTaskId) => axios.put(`${envBaseApiUrl}/app-tasks/recall/${appTaskId}`);
export const validateCommitNoChangeAppTasks = async (appTasks: AppTask[]) => (await axios.post<Partial<Record<AppTaskId, Change[]>>>(`${envBaseApiUrl}/app-tasks/validate-commit-no-change`, appTasks)).data;
export const getAppTaskStatusesForClientFile = async (clientFileId: ClientFileId) => axios.get<AppTaskStatusResult[]>(`${envBaseApiUrl}/app-tasks/client-files/${clientFileId}/status`);

export const getAppTaskSupportingDocuments = (appTaskId: AppTaskId) => axios.get<AppTaskDocument[]>(`${envBaseApiUrl}/app-tasks/documents/supporting/${appTaskId}`);
export const getAppTaskSubmissionDocuments = (appTaskId: AppTaskId) => axios.get<AppTaskDocument[]>(`${envBaseApiUrl}/app-tasks/documents/submission/${appTaskId}`);
export const createAppTaskApplicationDocument = (appTaskDocument: AppTaskDocumentUpload) => axios.post(`${envBaseApiUrl}/app-tasks/documents/application/`, appTaskDocument);
export const createAppTaskSupportingDocument = (appTaskDocument: AppTaskDocumentUpload) => axios.post<CreatedItemResult<AppTaskDocumentId>>(`${envBaseApiUrl}/app-tasks/documents/supporting/`, appTaskDocument);
export const deleteAppTaskApplicationDocument = (appTaskId: AppTaskId) => axios.delete(`${envBaseApiUrl}/app-tasks/documents/application/${appTaskId}`);
export const deleteAppTaskSupportingDocument = (documentId: AppTaskDocumentId) => axios.delete(`${envBaseApiUrl}/app-tasks/documents/supporting/${documentId}`);
export const registerApplicationDocumentForESign = (appTaskDocument: AppTaskDocument) => axios.post(`${envBaseApiUrl}/app-tasks/documents/application/register-esign/`, appTaskDocument);
export const registerSupportingDocumentForESign = (appTaskDocument: AppTaskDocument) => axios.post(`${envBaseApiUrl}/app-tasks/documents/supporting/register-esign/`, appTaskDocument);
export const getApplicationDocumentBase64Content = (appTaskId: AppTaskId) => axios.get<string>(`${envBaseApiUrl}/app-tasks/documents/application/${appTaskId}/get-base64-content/`);
export const getAppTaskSupportingDocumentBase64Content = (documentId: AppTaskDocumentId) => axios.get<string>(`${envBaseApiUrl}/app-tasks/documents/supporting/${documentId}/get-base64-content/`);

/**
 * Call this method to update the app task form generated time.
 */
export const updatedAppTaskLastGeneratedFormDate = (appTaskId: AppTaskId) => axios.put(`${envBaseApiUrl}/app-tasks/${appTaskId}/last-generated-form`);

/**
 * Call this method any time some related to an app task changes to update the last form related change time on the app task
 */
export const updateAppTaskLastFormRelatedChange = (appTaskId: AppTaskId) => axios.put(`${envBaseApiUrl}/app-tasks/${appTaskId}/last-form-related-change`);