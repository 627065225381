import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { ReportAnalysisGenerationType } from '../../../types/api/enums/report/reportAnalysisGenerationType.enum';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import {
  CalculationOrchestrator,
  PremiumBreakdownRequestDTO,
  ScenarioRequestDTO
} from '@silveus/calculations';
import { Quote } from '../../../types/api/Quote';


import { SigReportDocumentType } from '../../../constants/sigReportDocumentType';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { ClientFile } from '../../../types/api/ClientFile';
import { getItemsForId } from '../../../utils/mapHelpers';
import { PremiumBreakdownReportData } from '../../../types/api/reports/PremiumBreakdownReportData';
import { PremiumBreakdownOptions } from '../../../types/api/userSettings/PremiumBreakdownOptions';
import { getPremiumBreakdownData } from '../../../hooks/usePremiumBreakdown';
import { Commodity } from '../../../types/api/adm/Commodity';
import { buildCountyAndCommodityKey } from '../../../types/app/CompositeKeys';
import InsuranceCalendar from '../../../types/api/adm/InsuranceCalendar';
import { State } from '../../../types/api/adm/State';
import { County } from '../../../types/api/adm/County';
import { Practice } from '../../../types/api/adm/Practice';
import { getPremiumBreakdownReportData } from './getPremiumBreakdownReportData';
import { calculatePremiumBreakdownSummary } from '../../../hooks/usePremiumBreakdownSummary';

export const getQuoteSummaryPremiumBreakdowns = async (
  premiumBreakdownOptions: PremiumBreakdownOptions,
  analysisGenerationByScenarioId: Map<ScenarioId, ReportAnalysisGenerationType[]>,
  rowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>,
  scenarios: RowCropScenario[],
  quotes: Quote[],
  states: State[],
  counties: County[],
  commodities: Commodity[],
  practices: Practice[],
  clientFile: ClientFile,
  insuranceCalendarsByQuoteInfo: Map<`${string}-${string}`, InsuranceCalendar[]>,
  scenarioRequests: ScenarioRequestDTO[],
  reportDocumentType: SigReportDocumentType,
  includeGenerationDate: boolean,
): Promise<PremiumBreakdownReportData[]> => {

  const allPremiumBreakdownReportData: PremiumBreakdownReportData[] = [];
  for (const [scenarioId, electedAnalysisReportsToGenerate] of analysisGenerationByScenarioId) {
    //We loop per scenario so if Premium Breakdown isn't one of the selected reports, don't generate one.
    if (electedAnalysisReportsToGenerate.length === 0 ||
      electedAnalysisReportsToGenerate.every(r => r !== ReportAnalysisGenerationType.PremiumBreakdown)) continue;

    const scenario = scenarios.find(scenario => scenario.scenarioId === scenarioId);
    if (scenario === undefined) continue;

    const quote = quotes.find(quote => quote.quoteId === scenario.quoteId);
    if (quote === undefined) continue;

    const scenarioRequest = scenarioRequests.find(scenario => scenario.id === scenarioId);
    if (scenarioRequest === undefined) continue;

    const rowCropScenarioPieces = getItemsForId(rowCropScenarioPiecesByScenario, scenario.scenarioId);

    const premiumBreakdownRequest: PremiumBreakdownRequestDTO = {
      scenario: scenarioRequest,
      harvestPrice: (premiumBreakdownOptions.includeHPO ? scenario.harvestPrice : scenario.projectedPrice) ?? 0,
    };
    const premiumBreakdownCalcResults = CalculationOrchestrator.calculatePremiumBreakdown(premiumBreakdownRequest);
    const scenarioPieceCalcResults = premiumBreakdownCalcResults.scenarioPieceGroups.flatMap(spg => spg.scenarioPieces);
    const premiumBreakdownProductData = getPremiumBreakdownData(rowCropScenarioPieces, scenarioPieceCalcResults);
    const premiumBreakdownSummaryResults = calculatePremiumBreakdownSummary(scenarioPieceCalcResults);

    const countyCommodityKey = buildCountyAndCommodityKey(quote.countyId, quote.commodityCode);
    const insuranceCalendarsForQuote = getItemsForId(insuranceCalendarsByQuoteInfo, countyCommodityKey);

    const premiumBreakdownReportData = getPremiumBreakdownReportData(
      clientFile,
      quote,
      scenario,
      states,
      counties,
      commodities,
      practices,
      insuranceCalendarsForQuote,
      rowCropScenarioPieces,
      premiumBreakdownOptions,
      premiumBreakdownProductData,
      premiumBreakdownSummaryResults,
      reportDocumentType,
      includeGenerationDate,
    );

    allPremiumBreakdownReportData.push(premiumBreakdownReportData);

  }
  return allPremiumBreakdownReportData;
};