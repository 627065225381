import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getKeyedStateGroupedBy, getKeyedStateValues, removeFromKeyedState, updateKeyedState } from './sliceHelpers';
import { RootState } from './store';
import {
  ClientFileId,
  ScenarioId,
  ScenarioOptionId,
  ScenarioOptionUnitYearId,
  UnitYearId
} from '../types/api/PrimaryKeys';
import ScenarioOption from '../types/api/options/ScenarioOption';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import ScenarioOptionUnitYear from '../types/api/options/ScenarioOptionUnitYear';
import { filterNotNullOrUndefined, groupBy, orderByProperty } from '../utils/arrayUtils';
import { FlatScenarioOptionUnitYear } from '../types/api/options/FlatScenarioOptionUnitYear';
import { getItemsForId, orderMap } from '../utils/mapHelpers';
import { OptionCode } from '@silveus/calculations';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import {
  deleteScenarioOptionsRequest,
  getScenarioOptionsForClientFileRequest,
  getScenarioOptionsForScenariosRequest,
  getScenarioOptionUnitYearsForScenariosRequest,
  setAllScenarioOptionUnitYearsForScenarioRequest,
  setScenarioOptionsRequest
} from '../services/requestInterception/optionRequestInterceptor';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import { updateScenarioOptionUnitYearsBatch } from '../services/options.service';
import OptionState from '../types/app/OptionState';
import { OptionSelectionState } from '../types/app/enums/optionSelectionState.enum';

export interface OptionsState {
  allScenarioOptions: SliceDataState<ScenarioOptionId, ScenarioOption>;
  allScenarioOptionUnitYears: SliceDataState<ScenarioOptionUnitYearId, ScenarioOptionUnitYear>;
}

const initialState: OptionsState = {
  allScenarioOptions: initialSliceDataState(),
  allScenarioOptionUnitYears: initialSliceDataState(),
};

export type ScenarioUnitYearOptionBatchUpdate = { unitYearId: UnitYearId, optionCodes: OptionCode[] }[];

export const optionsSlice = createSlice({
  name: 'options',
  initialState: initialState,
  reducers: {
    updateAllUnitYearOptionsForScenario: (state, action: PayloadAction<{ scenarioId: ScenarioId, unitYears: ScenarioUnitYearOptionBatchUpdate }>) => {
      // The overall goal of this reducer: Take in an object that should represent the ENTIRETY of ScenarioOptionUnitYears for one particular scenario.
      // This is why this reducer has cleanup at the tail to delete records that were not "observed" in the main logic - the input to this reducer is expected to be representative
      // of the ENTIRE state for this scenario context.

      const scenarioOptionUnitYearsForScenario = getAllScenarioOptionUnitYearsForScenario(state, action.payload.scenarioId);
      const scenarioOptionsForScenario = getItemsForId(getKeyedStateGroupedBy(state.allScenarioOptions.data, o => o.scenarioId), action.payload.scenarioId);
      const scenarioOptionsByCode = groupBy(scenarioOptionsForScenario, s => s.option);

      const scenarioOptionUnitYearIdsThatShouldBeKeptForThisScenario = new Set<ScenarioOptionUnitYearId>();

      for (const unitYear of action.payload.unitYears) {
        for (const optionCode of unitYear.optionCodes) {
          // There should only be one match, which is why we're just taking the first one.
          let possibleOptionMatches = scenarioOptionsByCode.get(optionCode);
          let stateScenarioOption = possibleOptionMatches === undefined ? undefined : possibleOptionMatches[0];

          if (stateScenarioOption === undefined) {
            stateScenarioOption = {
              scenarioOptionId: generatePrimaryKey<ScenarioOptionId>(),
              option: optionCode,
              scenarioId: action.payload.scenarioId,
              offlineCreatedOn: undefined,
              offlineLastUpdatedOn: undefined,
              offlineDeletedOn: undefined,
            };

            // Add this option to state since it's been created in this branch.
            scenarioOptionsByCode.set(stateScenarioOption.option, [stateScenarioOption]);
            updateKeyedState(state.allScenarioOptions.data, stateScenarioOption, so => so.scenarioOptionId);
          }

          // This is only here to make TS happy. Because this is getting captured in a lambda below, undefined won't get removed from its union
          // in time unless we explicitly map it to a correctly-typed variable. This is better to me than an assertion.
          const scenarioOption = stateScenarioOption;

          let scenarioOptionUnitYear = scenarioOptionUnitYearsForScenario.find(u => u.scenarioOptionId === scenarioOption.scenarioOptionId && u.unitYearId === unitYear.unitYearId);

          if (scenarioOptionUnitYear === undefined) {
            scenarioOptionUnitYear = {
              scenarioOptionUnitYearId: generatePrimaryKey<ScenarioOptionUnitYearId>(),
              unitYearId: unitYear.unitYearId,
              scenarioOptionId: scenarioOption.scenarioOptionId,
              offlineCreatedOn: undefined,
              offlineLastUpdatedOn: undefined,
              offlineDeletedOn: undefined,
            };

            // Add this value to state since it's been created in this branch.
            updateKeyedState(state.allScenarioOptionUnitYears.data, scenarioOptionUnitYear, souy => souy.scenarioOptionUnitYearId);
          }

          scenarioOptionUnitYearIdsThatShouldBeKeptForThisScenario.add(scenarioOptionUnitYear.scenarioOptionUnitYearId);
        }
      }

      // Delete ALL ScenarioOptionUnitYear for the scenario that we didn't just touch directly.
      const scenarioOptionUnitYearsForScenarioToDelete = scenarioOptionUnitYearsForScenario.filter(o => !scenarioOptionUnitYearIdsThatShouldBeKeptForThisScenario.has(o.scenarioOptionUnitYearId));
      removeFromKeyedState(state.allScenarioOptionUnitYears.data, scenarioOptionUnitYearsForScenarioToDelete.map(u => u.scenarioOptionUnitYearId));
    },
  },
  extraReducers(builder) {
    const scenarioOptionsAsyncBuilder = getAsyncHandlerBuilder(builder, s => s.allScenarioOptions, s => s.scenarioOptionId);

    scenarioOptionsAsyncBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchScenarioOptionsForScenarios,
      affectedIds: () => [],
    });

    scenarioOptionsAsyncBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchScenarioOptionsForClientFile,
      affectedIds: () => [],
    });

    scenarioOptionsAsyncBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeScenarioOptions,
      affectedIds: arg => arg.scenarioOptions.map(s => s.scenarioOptionId),
    });

    const scenarioOptionsUnitYearAsyncBuilder = getAsyncHandlerBuilder(builder, s => s.allScenarioOptionUnitYears, s => s.scenarioOptionUnitYearId);

    scenarioOptionsUnitYearAsyncBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchScenarioOptionUnitYearsForScenarios,
      affectedIds: () => [],
    });

    scenarioOptionsUnitYearAsyncBuilder.generateAsyncHandlers({
      action: 'updating', thunk: updateScenarioOptionUnitYears,
      affectedIds: arg => arg.map(s => s.scenarioOptionUnitYearId),
    });

    builder
      .addCase(setScenarioOptions.fulfilled, (state, action: PayloadAction<{ scenarioId: ScenarioId, scenarioOptions: ScenarioOption[], scenarioOptionUnitYears: ScenarioOptionUnitYear[] }>) => {
        const options = action.payload;

        const scenarioOptionUnitYearsForScenario = getAllScenarioOptionUnitYearsForScenario(state, action.payload.scenarioId);
        removeFromKeyedState(state.allScenarioOptionUnitYears.data, scenarioOptionUnitYearsForScenario.map(souy => souy.scenarioOptionUnitYearId));

        const scenarioOptionsForScenario = getItemsForId(getKeyedStateGroupedBy(state.allScenarioOptions.data, o => o.scenarioId), action.payload.scenarioId);
        removeFromKeyedState(state.allScenarioOptions.data, scenarioOptionsForScenario.map(so => so.scenarioOptionId));

        updateKeyedState(state.allScenarioOptions.data, options.scenarioOptions, sp => sp.scenarioOptionId);
        updateKeyedState(state.allScenarioOptionUnitYears.data, options.scenarioOptionUnitYears, sp => sp.scenarioOptionUnitYearId);
      });
  },
});

//Memoized Selectors
export const selectScenarioOptionDictionary = (state: RootState) => state.options.allScenarioOptions.data;

export const selectAllScenarioOptionsByScenarioIdMap = createSelector([selectScenarioOptionDictionary], dictionary => {
  const map = getKeyedStateGroupedBy(dictionary, so => so.scenarioId);
  return orderMap(map, DefaultOrders.scenarioOptions);
});

export const selectAllScenarioOptionsForScenarioId = createSelector([
  selectAllScenarioOptionsByScenarioIdMap,
  (_state, scenarioId) => scenarioId,
], (map, scenarioId) => {
  return getItemsForId(map, scenarioId);
});

const selectScenarioOptionUnitYearsDictionary = (state: RootState) => state.options.allScenarioOptionUnitYears.data;
export const selectScenarioOptionUnitYearsByScenarioIdMap = createSelector([
  selectScenarioOptionUnitYearsDictionary,
  selectScenarioOptionDictionary,
], (scenarioOptionUnitYears, scenarioOptions) => {
  const result = new Map<ScenarioId, ScenarioOptionUnitYear[]>();
  const unorderedList = getKeyedStateValues(scenarioOptionUnitYears);
  const list = orderByProperty(unorderedList, DefaultOrders.scenarioOptionUnitYears);

  for (const scenarioOptionUnitYear of list) {
    const scenarioId = scenarioOptions[scenarioOptionUnitYear.scenarioOptionId]?.scenarioId;
    if (scenarioId === undefined) { continue; }

    const existingCollection = result.get(scenarioId);

    if (existingCollection === undefined) {
      result.set(scenarioId, [scenarioOptionUnitYear]);
    } else {
      existingCollection.push(scenarioOptionUnitYear);
    }
  }

  return result;
});

export const selectScenarioOptionUnitYearsByScenarioIds = (state: RootState, scenarioIds: ScenarioId[]) => {
  const dataMap = selectScenarioOptionUnitYearsByScenarioIdMap(state);

  const result: ScenarioOptionUnitYear[] = [];

  for (const scenarioId of scenarioIds) {
    const data = dataMap.get(scenarioId);
    if (data !== undefined) {
      result.push(...data);
    }
  }

  return result;
};

export const selectScenarioOptionUnitYearsByUnitYearIdMap = createSelector([
  selectScenarioOptionUnitYearsDictionary,
  selectScenarioOptionDictionary,
], scenarioOptionUnitYears => {
  const map = getKeyedStateGroupedBy(scenarioOptionUnitYears, scenarioOptionUnitYear => scenarioOptionUnitYear.unitYearId);
  return orderMap(map, DefaultOrders.scenarioOptionUnitYears);
});

export const selectScenarioOptionsByUnitYearMap = createSelector([
  selectScenarioOptionUnitYearsByUnitYearIdMap,
  selectAllScenarioOptionsForScenarioId,
], (optionsByUnitYearId, allScenarioOptions) => {
  const scenarioOptionsByUnitYear = new Map<UnitYearId, ScenarioOption[]>();

  for (const [unitYearId, scenarioOptionUnitYears] of optionsByUnitYearId) {
    const applicableScenarioOptionUnitYears = filterNotNullOrUndefined(scenarioOptionUnitYears.map(souy => allScenarioOptions.find(so => so.scenarioOptionId === souy.scenarioOptionId)));
    scenarioOptionsByUnitYear.set(unitYearId, applicableScenarioOptionUnitYears);
  }

  return scenarioOptionsByUnitYear;
});

export const selectScenarioOptionsByUnitYearMapForScenarios = createSelector([
  selectScenarioOptionUnitYearsByUnitYearIdMap,
  selectAllScenarioOptionsByScenarioIdMap,
  (_state: RootState, scenarioIds: ScenarioId[]) => scenarioIds,
], (optionsByUnitYearId, allScenarioOptions, scenarioIds) => {
  const scenarioOptionsForScenarios = scenarioIds.flatMap(scenarioId => getItemsForId(allScenarioOptions, scenarioId));
  const scenarioOptionsByUnitYear = new Map<UnitYearId, ScenarioOption[]>();

  for (const [unitYearId, scenarioOptionUnitYears] of optionsByUnitYearId) {
    const applicableScenarioOptionUnitYears = filterNotNullOrUndefined(scenarioOptionUnitYears.map(souy => scenarioOptionsForScenarios.find(so => so.scenarioOptionId === souy.scenarioOptionId)));
    scenarioOptionsByUnitYear.set(unitYearId, applicableScenarioOptionUnitYears);
  }

  return scenarioOptionsByUnitYear;
});

export const selectScenarioOptionCodesByUnitYearMap = createSelector([
  selectScenarioOptionsByUnitYearMap,
], scenarioOptionsByUnitYear => {
  const scenarioOptionCodesByUnitYear = new Map<UnitYearId, OptionCode[]>();

  for (const [unitYearId, scenarioOptions] of scenarioOptionsByUnitYear) {
    scenarioOptionCodesByUnitYear.set(unitYearId, scenarioOptions.map(so => so.option));
  }

  return scenarioOptionCodesByUnitYear;
});

/** Warning: Will return a new array with every call. */
export const selectScenarioOptionsForUnitYearId = createSelector([
  (state: RootState, _unitYearId: UnitYearId, scenarioId: ScenarioId) => selectScenarioOptionCodesByUnitYearMap(state, scenarioId),
  (_state: RootState, unitYearId: UnitYearId, _scenarioId: ScenarioId) => unitYearId,
], (optionsByUnitYearId, unitYearId) => {
  return getItemsForId(optionsByUnitYearId, unitYearId).sort();
});

/** Warning: Will return a new array with every call. */
const getAllScenarioOptionUnitYearsForScenario = (state: OptionsState, scenarioId: ScenarioId) => {
  const unorderedAllStateScenarioOptionUnitYears = getKeyedStateValues(state.allScenarioOptionUnitYears.data);
  const allStateScenarioOptionUnitYears = orderByProperty(unorderedAllStateScenarioOptionUnitYears, DefaultOrders.scenarioOptionUnitYears);
  const matchingForScenario = allStateScenarioOptionUnitYears.filter(scenarioOptionUnitYear => {
    return state.allScenarioOptions.data[scenarioOptionUnitYear.scenarioOptionId]?.scenarioId === scenarioId;
  });

  return matchingForScenario;
};

export const fetchScenarioOptionsForScenarios = createAppAsyncThunk('options/fetchScenarioOptionsForScenarios', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  return await getScenarioOptionsForScenariosRequest(scenarioIds);
});

export const fetchScenarioOptionsForClientFile = createAppAsyncThunk('options/fetchOptions-for-client-file', async ({ clientFileId }: { clientFileId: ClientFileId }) => {
  return await getScenarioOptionsForClientFileRequest(clientFileId);
});

export const fetchScenarioOptionUnitYearsForScenarios = createAppAsyncThunk('options/fetchScenarioOptionUnitYearsForScenarios', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  return await getScenarioOptionUnitYearsForScenariosRequest(scenarioIds);
});

export const updateScenarioOptionUnitYears = createAppAsyncThunk('options/updateScenarioOptionUnitYears', async (unitYears: ScenarioOptionUnitYear[]) => {
  await updateScenarioOptionUnitYearsBatch(unitYears);
  return unitYears;
});

export const updateUnitYearOptionsForScenario = createAppAsyncThunk('options/update-unityear-options-for-scenario', async ({ scenarioId, unitYears }: { scenarioId: ScenarioId, unitYears: ScenarioUnitYearOptionBatchUpdate }, thunkApi) => {
  // Adjust local state first.
  thunkApi.dispatch(updateAllUnitYearOptionsForScenario({ scenarioId, unitYears }));

  // Get a fresh copy of state after we just made changes to it.
  const adjustedState = thunkApi.getState();

  // Get the data from state we're going to need to iterate / reference to build the API call.
  const allScenarioOptionUnitYearsForScenario = getAllScenarioOptionUnitYearsForScenario(adjustedState.options, scenarioId);
  const scenarioOptions = selectScenarioOptionDictionary(adjustedState);

  const scenarioOptionUnitYearsForApiCall: FlatScenarioOptionUnitYear[] = [];

  for (const scenarioOptionUnitYear of allScenarioOptionUnitYearsForScenario) {
    // We need to pull out this record because the API call also has the power to create scenario options that are missing on the back end.
    // If that ends up happening, the back end will need to know the "code" associated with that record.
    const scenarioOptionFromState = scenarioOptions[scenarioOptionUnitYear.scenarioOptionId];

    // This should be "impossible" unless there is a bug.
    if (scenarioOptionFromState === undefined) {
      continue;
    }

    scenarioOptionUnitYearsForApiCall.push({
      scenarioOptionUnitYearId: scenarioOptionUnitYear.scenarioOptionUnitYearId,
      scenarioOptionId: scenarioOptionUnitYear.scenarioOptionId,
      unitYearId: scenarioOptionUnitYear.unitYearId,
      scenarioOptionCode: scenarioOptionFromState.option,
    });
  }

  // Make the API call to persist.
  await setAllScenarioOptionUnitYearsForScenarioRequest(scenarioId, scenarioOptionUnitYearsForApiCall);
});

export const duplicateUnitYearOptionsForScenario = createAppAsyncThunk('options/duplicate-unityear-options-for-scenario', async ({ copyFromScenarioId, copyToScenarioId }: { copyFromScenarioId: ScenarioId, copyToScenarioId: ScenarioId }, thunkApi) => {
  const state = thunkApi.getState();

  // Get all of the scenario option unit years for the source scenario.
  const sourceScenarioUnitYearOptions = getItemsForId(selectScenarioOptionUnitYearsByScenarioIdMap(state), copyFromScenarioId);

  // Get all of the scenario options - we'll need to be able to look them up by id later.
  const sourceScenarioScenarioOptions = selectScenarioOptionDictionary(state);

  // We need to group a flat structure by unit id to get it into the shape expected by the method we need to call.
  const sourceScenarioScenarioOptionsByUnitId = groupBy(sourceScenarioUnitYearOptions, o => o.unitYearId);

  const updateTemplate: ScenarioUnitYearOptionBatchUpdate = [];

  for (const unitYear of sourceScenarioScenarioOptionsByUnitId) {
    const optionCodes: OptionCode[] = [];

    // Go get all of the actual literal option codes for the current year, add them to our structure.
    for (const scenarioOptionUnitYear of unitYear[1]) {
      const scenarioOption = sourceScenarioScenarioOptions[scenarioOptionUnitYear.scenarioOptionId];
      if (scenarioOption !== undefined) {
        optionCodes.push(scenarioOption.option);
      }
    }

    updateTemplate.push({
      unitYearId: unitYear[0],
      optionCodes: optionCodes,
    });
  }

  if (updateTemplate.length === 0) {
    // may have options selected, but no units, tell back end to just add scenario options
    const selectedCodes = filterNotNullOrUndefined(Object.values(sourceScenarioScenarioOptions)).filter(x => x.scenarioId === copyFromScenarioId).map(x => x.option);
    await thunkApi.dispatch(updateOptionSelections({ scenarioId: copyToScenarioId, optionsToAdd: selectedCodes, optionsToRemove: [] }));
  } else {
    // there are units, go by that
    await thunkApi.dispatch(updateUnitYearOptionsForScenario({ scenarioId: copyToScenarioId, unitYears: updateTemplate }));
  }
});
export interface OptionsByScenarioUnitYear { options: OptionCode[], scenarioId: ScenarioId, unitYearId: UnitYearId }

export const removeScenarioOptions = createAppAsyncThunk('options/removeScenarioOptions', async ({ scenarioOptions }: { scenarioOptions: ScenarioOption[] }) => {
  await deleteScenarioOptionsRequest(scenarioOptions.map(s => s.scenarioOptionId));
  return scenarioOptions;
});

// it looks like this is used in the STQ import process and may have been broken without being updated; I'm not sure I know what to do here.
export const updateOptionSelections = createAppAsyncThunk(
  'options/updateOptionSelections', async ({ scenarioId, optionsToAdd, optionsToRemove }: { scenarioId: ScenarioId, optionsToAdd: OptionCode[], optionsToRemove: ScenarioOption[] }, thunkApi) => {
    let addOptionsPromise;
    let removeOptionsPromise;

    if (optionsToAdd.length > 0) {
      // addOptionsPromise = thunkApi.dispatch(addOptionsAsOptionCodeForScenario({ scenarioId: scenarioId, options: optionsToAdd }));
    }
    if (optionsToRemove.length > 0) {
      removeOptionsPromise = thunkApi.dispatch(removeScenarioOptions({ scenarioOptions: optionsToRemove }));
    }

    await Promise.all([addOptionsPromise, removeOptionsPromise]);
  },
);

export const setScenarioOptionsWithOptionState = createAppAsyncThunk('options/setScenarioOptionsByOptionState',
  async ({ scenarioId, scenarioOptionState, scenarioOptionUnitYearState }: { scenarioId: ScenarioId, scenarioOptionState: OptionState[], scenarioOptionUnitYearState: Map<UnitYearId, OptionState[]> }, thunkApi) => {
    const scenarioOptions = scenarioOptionState.filter(so => so.selectionState !== OptionSelectionState.Off).map<ScenarioOption>(so => ({
      scenarioOptionId: generatePrimaryKey(),
      scenarioId: scenarioId,
      option: so.option.optionCode,
      offlineCreatedOn: undefined,
      offlineDeletedOn: undefined,
      offlineLastUpdatedOn: undefined,
    }));

    const scenarioOptionUnitYears: ScenarioOptionUnitYear[] = [];
    for (const [unitYearId, optionStates] of Array.from(scenarioOptionUnitYearState)) {
      const filteredOptionStates = optionStates.filter(os => os.selectionState === OptionSelectionState.On);
      for (const option of filteredOptionStates) {
        const scenarioOption = scenarioOptions.find(so => so.option === option.option.optionCode);
        if (scenarioOption === undefined) continue;
        const scenarioOptionUnitYear: ScenarioOptionUnitYear = {
          scenarioOptionUnitYearId: generatePrimaryKey(),
          scenarioOptionId: scenarioOption.scenarioOptionId,
          unitYearId: unitYearId,
          offlineCreatedOn: undefined,
          offlineDeletedOn: undefined,
          offlineLastUpdatedOn: undefined,
        };
        scenarioOptionUnitYears.push(scenarioOptionUnitYear);
      }
    }

    await thunkApi.dispatch(setScenarioOptions({ scenarioId, scenarioOptions, scenarioOptionUnitYears }));
  });

export const setScenarioOptions = createAppAsyncThunk('options/setScenarioOptions',
  async ({ scenarioId, scenarioOptions, scenarioOptionUnitYears }: { scenarioId: ScenarioId, scenarioOptions: ScenarioOption[], scenarioOptionUnitYears: ScenarioOptionUnitYear[] }) => {
    await setScenarioOptionsRequest(scenarioId, scenarioOptions, scenarioOptionUnitYears);
    return { scenarioId, scenarioOptions, scenarioOptionUnitYears };
  });

export const { updateAllUnitYearOptionsForScenario } = optionsSlice.actions;

export default optionsSlice.reducer;
