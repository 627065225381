import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, styled } from '@mui/material';
import { useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { PersonOrBusiness } from '../../../types/api/enums/contactInfo/personOrBusiness';
import { Nullable } from '../../../types/util/Nullable';


const StyledMenuItem = styled(MenuItem)(() => ({
  width: 220,
  pl: .5,
}));

type ContactInfoGridSplitButtonProps = {
  disabled: boolean;
  onAddClick: (personOrBusinessType: PersonOrBusiness, personOfInterestType: PersonOfInterestType) => void;
  onImportClick: (personOfInterestType: Nullable<PersonOfInterestType>) => void;
  contactType: PersonOfInterestType;
};

const ContactInfoGridSplitButton = ({ disabled, onAddClick, onImportClick, contactType }: ContactInfoGridSplitButtonProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const showAdditionalPOASpecificMenuItems = contactType === PersonOfInterestType.AuthorizedRep || contactType === PersonOfInterestType.PowerOfAttorney;

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleImportClicked = () => {
    setOpen(false);
    onImportClick(null);
  };

  const handleAddPersonOrBusinessClicked = (personOrBusiness: PersonOrBusiness, personOfInterest: PersonOfInterestType) => {
    onAddClick(personOrBusiness, personOfInterest);
    setOpen(false);
  };

  const renderMenuItems = () => {
    if (showAdditionalPOASpecificMenuItems) {
      return (
        <>
          <StyledMenuItem onClick={() => handleAddPersonOrBusinessClicked(PersonOrBusiness.PERSON, PersonOfInterestType.PowerOfAttorney)}>
            <ListItemIcon >
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ textAlign: 'left' }}>Add POA</ListItemText>
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleAddPersonOrBusinessClicked(PersonOrBusiness.PERSON, PersonOfInterestType.AuthorizedRep)}>
            <ListItemIcon >
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ textAlign: 'left' }}>Add Auth Rep</ListItemText>
          </StyledMenuItem>
        </>
      );
    }
    return (
      <>
        <StyledMenuItem onClick={() => handleAddPersonOrBusinessClicked(PersonOrBusiness.PERSON, contactType)}>
          <ListItemIcon >
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ textAlign: 'left' }}>Add Person</ListItemText>
        </StyledMenuItem>
        <StyledMenuItem disabled={contactType !== PersonOfInterestType.SBI} onClick={() => handleAddPersonOrBusinessClicked(PersonOrBusiness.BUSINESS, contactType)}>
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ textAlign: 'left' }}>Add Business</ListItemText>
        </StyledMenuItem>
      </>
    );
  };

  return (
    <>
      <div ref={anchorRef} >
        <Button id="btn-contact-info-add" disabled={disabled} onClick={handleToggle} endIcon={<ArrowDropDownIcon />}>Add</Button>
      </div>
      <Menu
        disablePortal
        open={open}
        sx={{ mt: .5 }}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {renderMenuItems()}
        <Divider />
        <StyledMenuItem onClick={handleImportClicked}>
          <ListItemIcon>
            <ImportContactsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ textAlign: 'left' }}>Import</ListItemText>
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export default ContactInfoGridSplitButton;