import { Breadcrumbs, Link } from '@mui/material';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { ApplicationRoutes } from './routes';

interface Props {
  clientFileOwnerClick: (route: string) => void;
  routes: ApplicationRoutes;
}

const NavigationBreadcrumbs = ({ clientFileOwnerClick, routes }: Props) => {
  const location = useLocation();

  return (
    <Breadcrumbs separator=">" sx={{ color: theme => theme.palette.primary.main }}>
      {matchPath('/insureds/*', location.pathname) && (
        <Link variant="clickable1" sx={{ color: theme => theme.palette.primary.main }} key="insureds" href="#" onClick={() => clientFileOwnerClick('/insureds')}>
          Insureds
        </Link>
      )}
      {renderBreadcrumbs(location.pathname, routes)}
    </Breadcrumbs>
  );
};

export default NavigationBreadcrumbs;

const renderBreadcrumbs = (pathName: string, routes: ApplicationRoutes) => {
  const items = [];

  for (const route of routes) {
    if (matchPath(`${route.path}/*`, pathName) && route.breadcrumbs !== undefined && route.breadcrumbs.length > 0) {
      for (const breadcrumb of route.breadcrumbs) {
        items.push(<Link variant="clickable1" key={route.path} sx={{ color: theme => theme.palette.primary.main }} component={NavLink} to={breadcrumb.path}>
          {breadcrumb.name}
        </Link>);
      }
    }
  }

  return items;
};