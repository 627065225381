import {
  ForwardSoldRequestDTO,
  ForwardSoldFixedParams,
  ForwardSoldSimulatedParams,
  ForwardSoldUnit,
  Unit
} from '@silveus/calculations';
import { RootState } from '../../../app/store';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import BaseUnit from '../../../types/api/BaseUnit';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { getUnitsForScenarioPiece } from '../calculationUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { UnitGroup } from '../../../types/api/UnitGroup';
import ForwardSoldScenarioPiece from '../../../types/api/ForwardSoldScenarioPiece';

export const createForwardSoldData = async (scenarioPiece: ForwardSoldScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[], shouldSimulateUnitGroups: boolean = false) => {

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId, shouldSimulateUnitGroups);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createForwardSoldDto(scenarioPiece, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformForwardSoldData = (unit: BaseUnit, scenario: RowCropScenario, scenarioPiece: ForwardSoldScenarioPiece): ForwardSoldFixedParams & ForwardSoldSimulatedParams => {
  const forwardSoldParams: ForwardSoldFixedParams & ForwardSoldSimulatedParams = {
    projectedPrice: scenario.projectedPrice ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
    marketingYield: scenarioPiece.marketingYield,
    forwardSoldTransactions: scenarioPiece.forwardSoldTransactions,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
  };

  return forwardSoldParams;
};

const createForwardSoldUnits = (units: BaseUnit[], scenario: RowCropScenario): (ForwardSoldUnit & Unit)[] => {
  return units.map(unit => {
    const unitDto = createBaseUnit(unit);
    const forwardSoldUnit: ForwardSoldUnit & Unit = {
      ...unitDto,
      id: unit.id,
    };

    return forwardSoldUnit;
  });
};

const createForwardSoldDto = (scenarioPiece: ForwardSoldScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): ForwardSoldRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createForwardSoldUnits(
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformForwardSoldData(unitData[0], scenario, scenarioPiece),
  };
};