import { weightedAverage } from '@silveus/calculations';
import { HailEndorsementId, UnitYearId } from '../../../types/api/PrimaryKeys';
import HailEndorsement from '../../../types/api/hail/hailEndorsement';
import HailRate from '../../../types/api/hail/hailRate';
import HailScenarioPieceRate from '../../../types/api/hail/HailScenarioPieceRate';
import UnitYear from '../../../types/api/UnitYear';
import HailScenarioPieceEndorsement from '../../../types/api/hail/HailScenarioPieceEndorsement';
import { getHailRateForUnitFromHailRateMap, getHailRatesByTownshipRange } from './hailRateUtils';

export type WeightedEndorsementRatesState = Map<HailEndorsementId, number>;

export function getEndorsementRates(
  hailEndorsements: HailEndorsement[],
  baseHailRates: HailRate[],
  hailScenarioPieceRates: HailScenarioPieceRate[],
  isQuickQuote: boolean,
  unitYears: UnitYear[],
  unitYearIds: UnitYearId[],
): Map<HailEndorsementId, number> {
  const endorsementRatesState: Map<HailEndorsementId, number> = new Map();
  hailEndorsements.forEach(he => {
    const weightedRate = getWeightedAverageRate(he.hailRates, baseHailRates, hailScenarioPieceRates, isQuickQuote, unitYears, unitYearIds);
    endorsementRatesState.set(he.hailPlanEndorsementId, weightedRate);
  });
  return endorsementRatesState;
}

export function getWeightedAverageRate(
  availableHailRates: HailRate[],
  baseHailRates: HailRate[],
  hailScenarioPieceRates: HailScenarioPieceRate[],
  isQuickQuote: boolean,
  unitYears: UnitYear[],
  unitYearIds: UnitYearId[]) {
  const hailRateMap = getHailRatesByTownshipRange(availableHailRates);
  if (isQuickQuote) {
    // get acres from hail scenario piece rate
    const filteredHailScenarioPieceRates = hailScenarioPieceRates.filter(hspr => hspr.acres !== null && hspr.acres > 0);
    const filteredBaseHailRates = baseHailRates.filter(bhr => filteredHailScenarioPieceRates.some(fhspr => fhspr.rateId === bhr.rateId));
    const weightedAverageRate = weightedAverage(
      filteredBaseHailRates,
      fbhr => getHailRateForUnitFromHailRateMap(fbhr, hailRateMap)?.rate ?? 0,
      fbhr => filteredHailScenarioPieceRates.find(fhspr => fhspr.rateId === fbhr.rateId)?.acres ?? 0,
    );
    return weightedAverageRate;
  } else {
    // get acres from units
    const filteredUnitYears = unitYears.filter(uy => unitYearIds.some(uyi => uyi === uy.unitYearId));
    const weightedAverageRate = weightedAverage(
      filteredUnitYears,
      uy => getHailRateForUnitFromHailRateMap(uy, hailRateMap)?.rate ?? 0,
      uy => uy.acres * uy.sharePercent,
    );
    return weightedAverageRate;
  }
}

export function getWeightedEndorsementRateMap(
  hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[],
  availableHailEndorsements: HailEndorsement[],
  availableHailRates: HailRate[],
  hailScenarioPieceRates: HailScenarioPieceRate[],
  isQuickQuote: boolean,
  unitYears: UnitYear[],
  unitYearIds: UnitYearId[],
  getPerAcre: boolean): WeightedEndorsementRatesState {
  const endorsementRateRecordsMap: WeightedEndorsementRatesState = new Map();
  const selectedEndorsements = hailScenarioPieceEndorsements.filter(hspe => hspe.perAcre === getPerAcre);
  selectedEndorsements.forEach(endorsement => {
    const hailEndorsementThatCorrelatesToSelectedScenarioPieceEndorsement = availableHailEndorsements.find(ahe => ahe.hailPlanEndorsementId === endorsement.endorsementId);
    if (hailEndorsementThatCorrelatesToSelectedScenarioPieceEndorsement === undefined) return;
    const weightedRate = getWeightedAverageRate(
      hailEndorsementThatCorrelatesToSelectedScenarioPieceEndorsement.hailRates,
      availableHailRates,
      hailScenarioPieceRates,
      isQuickQuote,
      unitYears,
      unitYearIds);
    endorsementRateRecordsMap.set(endorsement.endorsementId, weightedRate);
  });
  return endorsementRateRecordsMap;
}