import { Chip, styled } from '@mui/material';

// 250px max width is roughly 30 characters. The rest of the text will be visible via a tooltip.
export const ScenarioChip = styled(Chip)(({ backgroundColor, maxWidth = 250 }: { backgroundColor: string, maxWidth?: number }) => ({
  '& .MuiChip-deleteIcon': {
    color: '#FFF',
    opacity: 0.5,
  },
  backgroundColor: backgroundColor,
  textOverflow: 'ellipsis',
  maxWidth: `${maxWidth}px`,
}));