import { Dialog, DialogActions } from '@mui/material';
import { selectInsuredById } from '../../app/insuredsSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import { InsuredId } from '../../types/api/PrimaryKeys';
import { Nullable } from '../../types/util/Nullable';
import { ReactElement, useEffect, useState } from 'react';
import UnitReconciliationDialog from './unitReconciliationDialog';
import ClientFileReconciliationDialog from './clientFileReconciliationDialog';
import { isNotNullOrUndefined } from '../../utils/nullHandling';
import ReconciliationButtons from './reconciliationButtons';
import { NamedReconciliationStack } from '../../types/app/ReconciliationTrackedEntity';
import { MuiDialogCloseReason } from '../../types/mui/MuiDialogCloseReason';

type ClientFileInsuredStack = NamedReconciliationStack<'insureds'>;
type UnitYearInsuredStack = NamedReconciliationStack<'unitInsureds'>;

interface InsuredReconciliationDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedInsured: Nullable<InsuredId>;
  clientFilesClientState: ClientFileInsuredStack[];
  clientFilesServerState: ClientFileInsuredStack[];
  clientFilesReconciledState: ClientFileInsuredStack[];
  unitsClientState: UnitYearInsuredStack[];
  unitsServerState: UnitYearInsuredStack[];
  unitsReconciledState: UnitYearInsuredStack[];
  handleInsuredReconciled: (clientFiles: Nullable<ClientFileInsuredStack>, units: Nullable<UnitYearInsuredStack>) => void;
}

const InsuredReconciliationDialog = ({ isOpen, handleClose, selectedInsured, clientFilesClientState, clientFilesServerState, clientFilesReconciledState, unitsClientState, unitsServerState, unitsReconciledState, handleInsuredReconciled }: InsuredReconciliationDialogProps) => {
  const [selectedPage, setSelectedPage] = useState<0 | 1>(0);
  const insured = useAppSelector(state => selectInsuredById(state, selectedInsured));
  const [isReconciled, setIsReconciled] = useState<boolean>(false);
  const [elementMap, setElementMap] = useState<Map<number, ReactElement>>(new Map());

  const initialInsuredData = clientFilesReconciledState.find(stateTree => stateTree.id === selectedInsured);
  const initialUnitData = unitsReconciledState.find(stateTree => stateTree.id === selectedInsured);

  const clientUnitInsured = unitsClientState.find(stateTree => stateTree.id === selectedInsured);
  const serverUnitInsured = unitsServerState.find(stateTree => stateTree.id === selectedInsured);

  const clientInsured = clientFilesClientState.find(stateTree => stateTree.id === selectedInsured);
  const serverInsured = clientFilesServerState.find(stateTree => stateTree.id === selectedInsured);

  const [reconciledUnitsData, setReconciledUnitsData] = useState(initialUnitData);
  const [reconciledInsuredData, setReconciledInsuredData] = useState(initialInsuredData);

  const [serverUnitsData, setServerUnitsData] = useState(serverUnitInsured);
  const [clientUnitsData, setClientUnitsData] = useState(clientUnitInsured);

  const [serverClientFilesData, setServerClientFilesData] = useState(serverInsured);
  const [clientClientFilesData, setClientClientFilesData] = useState(clientInsured);

  useEffect(() => {
    setReconciledInsuredData(initialInsuredData);
  }, [initialInsuredData]);

  useEffect(() => {
    setReconciledUnitsData(initialUnitData);
  }, [initialUnitData]);

  useEffect(() => {
    setClientUnitsData(clientUnitInsured);
  }, [clientUnitInsured]);

  useEffect(() => {
    setServerUnitsData(serverUnitInsured);
  }, [serverUnitInsured]);

  useEffect(() => {
    setClientClientFilesData(clientInsured);
  }, [clientInsured]);

  useEffect(() => {
    setServerClientFilesData(serverInsured);
  }, [serverInsured]);

  useEffect(() => {
    if (insured === null)
      handleClose();
  }, [insured]);

  const handleCancel = (reason?: MuiDialogCloseReason) => {
    if (reason === 'backdropClick') {
      return;
    }

    handleClose();
  };

  //If there is no units data, the buttons on the client files page should be cancel and finish
  //If there is no client files data, the buttons on the units page should be cancel and finish
  //A page should only show if all 3 pieces of data are not undefined

  useEffect(() => {
    if (insured === null) return;

    const newElementMap = new Map(elementMap);
    let elementNumber = 0;

    if ([reconciledUnitsData, serverUnitsData, clientUnitsData].some(x => isNotNullOrUndefined(x))) {
      newElementMap.set(elementNumber, <UnitReconciliationDialog
        insured={insured}
        handleReconciliationStatusChanged={handleUnitsReconciliationStatusChanged}
        reconciledState={reconciledUnitsData}
        clientState={clientUnitsData}
        serverState={serverUnitsData}
      />);
      elementNumber++;
    }
    if ([reconciledInsuredData, serverClientFilesData, clientClientFilesData].some(x => isNotNullOrUndefined(x))) {
      newElementMap.set(elementNumber, <ClientFileReconciliationDialog
        insured={insured}
        handleReconciliationStatusChanged={handleInsuredReconciliationStatusChanged}
        reconciledState={reconciledInsuredData}
        clientState={clientClientFilesData}
        serverState={serverClientFilesData}
      />);
      elementNumber++;
    }

    setElementMap(newElementMap);
  }, [reconciledUnitsData, serverUnitsData, clientUnitsData, reconciledInsuredData, serverClientFilesData, clientClientFilesData]);

  const handleNextButtonClicked = () => {
    setSelectedPage(1);
    setIsReconciled(false);
  };

  const handlePreviousClicked = () => {
    setSelectedPage(0);
    setIsReconciled(false);
  };

  const handleFinishClicked = () => {
    handleInsuredReconciled(reconciledInsuredData ?? null, reconciledUnitsData ?? null);
    setSelectedPage(0);
    handleCancel();
  };

  const handleUnitsReconciliationStatusChanged = (entity: UnitYearInsuredStack) => {
    setReconciledUnitsData(entity);
    setIsReconciled(entity.reconciliationType !== 'unset');
  };

  const handleInsuredReconciliationStatusChanged = (entity: ClientFileInsuredStack) => {
    setReconciledInsuredData(entity);
    setIsReconciled(entity.reconciliationType !== 'unset');
  };

  //Need some way to map pages to UI elements


  return (
    <Dialog
      open={isOpen}
      onClose={(e, reason) => handleCancel(reason)}
      fullScreen
      sx={{ m: 2 }}
    >
      {elementMap.get(selectedPage)}
      <DialogActions>
        <ReconciliationButtons
          pageNumber={selectedPage}
          firstPageNumber={0}
          lastPageNumber={Math.max(elementMap.size - 1, 0)}
          handleNextClicked={handleNextButtonClicked}
          handlePreviousClicked={handlePreviousClicked}
          handleCancelClicked={handleCancel}
          handleFinishClicked={handleFinishClicked}
          isPageAbleToProgress={isReconciled}
        />
      </DialogActions>
    </Dialog>
  );
};

export default InsuredReconciliationDialog;