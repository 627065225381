import { useAppDispatch } from '../../hooks/reduxHooks';
import { updateCalculationForScenario } from '../../app/calculationResultsSlice';
import { updateUnitGroupsForScenarioPiece } from '../../app/unitGroupsSlice';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';
import InactiveScenarioComponentCard from './inactiveScenarioComponentCard';

interface InactiveScenarioPieceCardProps<T extends ScenarioPiece> {
  scenarioPiece: T;
  isScenarioActive: boolean;
  label: string;
  updateScenarioPiece: (scenarioPiece: T) => Promise<void>;
}

const InactiveScenarioPieceCard = <T extends ScenarioPiece>({ scenarioPiece, isScenarioActive, label, updateScenarioPiece }: InactiveScenarioPieceCardProps<T>) => {
  const dispatch = useAppDispatch();

  const updateScenarioPieceCompletely = async (newScenarioPiece: T, isActive: boolean) => {
    await updateScenarioPiece({ ...newScenarioPiece, isActive: isActive });
    await dispatch(updateUnitGroupsForScenarioPiece({ scenarioPieceId: newScenarioPiece.scenarioPieceId }));
    await dispatch(updateCalculationForScenario({ scenarioId: newScenarioPiece.scenarioId }));
  };

  return (
    <InactiveScenarioComponentCard
      scenarioComponent={scenarioPiece}
      isScenarioActive={isScenarioActive}
      label={label}
      updateScenarioComponent={updateScenarioPieceCompletely}
    />
  );
};

export default InactiveScenarioPieceCard;