import { useEffect } from 'react';
import { HistoricalAnalysisYear } from '../../../../../../types/api/historicalAnalysisYear';
import HistoricalAnalysis from '../../../../../../types/api/historicalAnalysis';
import {
  modifyHistoricalAnalysis, selectLastRemotelyPersistedExcludedYearsForHistoricalAnalysis,
  setLastRemotelyPersistedExcludedYears
} from '../../../../../../app/historicalAnalysisSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import { useDebounce } from '../../../../../../hooks/useDebounce';

const delayUntilHistoricalQuoteUpdatesArePersisted = 500;


export const useExcludedYearPersistence = (historicalAnalysis: HistoricalAnalysis, yearsToExclude: number[]) => {
  const dispatch = useAppDispatch();

  const debouncedYearsToExclude = useDebounce(yearsToExclude, delayUntilHistoricalQuoteUpdatesArePersisted);
  const lastSavedExcludedYears = useAppSelector(state => selectLastRemotelyPersistedExcludedYearsForHistoricalAnalysis(state, historicalAnalysis.historicalAnalysisId));

  useEffect(() => {
    async function save() {
      const newHistoricalAnalysisYears: HistoricalAnalysisYear[] = historicalAnalysis.historicalAnalysisYears.map(historicalAnalysisYear => {
        const newHistoricalAnalysisYear: HistoricalAnalysisYear = {
          ...historicalAnalysisYear,
          excluded: debouncedYearsToExclude.includes(historicalAnalysisYear.year),
        };

        return newHistoricalAnalysisYear;
      });

      const newHistoricalAnalysis: HistoricalAnalysis = {
        ...historicalAnalysis,
        historicalAnalysisYears: newHistoricalAnalysisYears,
      };
      const result = await dispatch(modifyHistoricalAnalysis({ historicalAnalysis: newHistoricalAnalysis }));

      if (result.meta.requestStatus === 'fulfilled') {
        // Only if we have indication of success do we register that we have saved.
        dispatch(setLastRemotelyPersistedExcludedYears({ historicalAnalysisId: newHistoricalAnalysis.historicalAnalysisId, excludedYears: debouncedYearsToExclude }));
      }
    }

    if (debouncedYearsToExclude !== lastSavedExcludedYears) {
      save();
    }
  }, [debouncedYearsToExclude]);
};