import { ProductOfferAvailabilityParams } from './OfferAvailability';
import { ProductOfferAvailabilities, ScenarioPieceType } from '@silveus/calculations';
import {
  getAreaBaseRate,
  getInsuranceOfferAvailability, getInsuranceOfferInformation,
  getMultipleCommodityAdjustmentFactor, getSubsidyPercent
} from '../../localCalcDataQueries.service';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import ArpHpeCalculationParams from '../../../types/api/calculationData/arpHpeCalculationParams';
import { getArpHpeCalcData } from '../../calculationData.service';
import { admDb } from '../../../db';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import { ArpHpeCalculationParamsRequest } from '../../../types/api/calculationData/arpHpeCalculationParamsRequest';

export const getArpHpeCalcDataRequest = async (calcParamRequest: ArpHpeCalculationParamsRequest): Promise<ArpHpeCalculationParams[]> => {
  const request = () => getArpHpeCalcData(calcParamRequest);
  const admTransactionTables = [admDb.subsidies, admDb.insuranceOffers, admDb.areaCoverageLevels, admDb.areaRates, admDb.coverageLevelDifferentials];

  const readTransaction = async (): Promise<ArpHpeCalculationParams[]> => {
    const multipleCroppingCode = 'NS';

    const volatility = calcParamRequest.volatility;

    const arpHpeResponse: ArpHpeCalculationParams[] = [];

    const unitTransactions = calcParamRequest.distinctUnits.map(async distinctUnit => {
      const admData = await admDb.transaction('r', admTransactionTables, async () => {
        const subsidyPercent = await getSubsidyPercent(calcParamRequest.commodityCode, calcParamRequest.unitStructureCode, calcParamRequest.planCode, calcParamRequest.upperCoverageLevel, calcParamRequest.coverageTypeCode);

        const insuranceOffer = await getInsuranceOfferInformation(calcParamRequest.planCode, calcParamRequest.countyId, distinctUnit.typeId, distinctUnit.practiceId, calcParamRequest.coverageTypeCode);

        if (insuranceOffer === null) return null;

        const baseRate = await getAreaBaseRate(calcParamRequest.year, insuranceOffer.insuranceOfferId, calcParamRequest.upperCoverageLevel, volatility);

        return {
          unitOfMeasure: insuranceOffer.unitOfMeasure,
          baseRate: baseRate,
          subsidyPercent: subsidyPercent,
        };
      });

      if (admData === null) return;

      const multipleCommodityAdjustmentFactor = await getMultipleCommodityAdjustmentFactor(multipleCroppingCode);

      const arpHpeCalculationParams: ArpHpeCalculationParams = {
        unit: distinctUnit,
        baseRate: admData.baseRate,
        multipleCommodityAdjustmentFactor: multipleCommodityAdjustmentFactor,
        subsidyPercent: admData.subsidyPercent,
      };

      arpHpeResponse.push(arpHpeCalculationParams);
    });

    await Promise.all(unitTransactions);

    return arpHpeResponse;
  };

  const strategy = getDefaultCachingStrategy();
  return strategy(request, readTransaction);
};

export const getOfferAvailability = async (productOfferAvailabilityParams: ProductOfferAvailabilityParams): Promise<ProductOfferAvailabilities[]> => {
  const scenarioPieceTypes: ScenarioPieceType[] = [ScenarioPieceType.ArpHpe];

  const offerAvailabilityPromises = scenarioPieceTypes.map(scenarioPieceType => {
    return getInsuranceOfferAvailability(
      productOfferAvailabilityParams.year,
      productOfferAvailabilityParams.countyId,
      productOfferAvailabilityParams.commodityCode,
      getInsurancePlanCodeForScenarioPiece(scenarioPieceType),
      scenarioPieceType);
  });

  return Promise.all(offerAvailabilityPromises);
};
