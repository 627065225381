import { QuoteId, ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import { initialKeyedState, KeyedState, updateKeyedStateVerbatim } from './sliceHelpers';
import { RootState } from './store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import { createAppAsyncThunk } from './thunkHelpers';
import { RowCropScenarioPiece } from '../types/api/RowCropScenarioPiece';
import { ScenarioPieceValidationResult, validateRowCropScenario } from '../utils/validation/validateScenario';
import { modifyScenarioPiece, selectRowCropScenarioPiecesByScenarioId } from './scenarioPiecesSlice';
import { modifyUnitYearsForScenario, selectAllScenariosByQuoteIdMap, selectScenarioById, selectScenariosByIds } from './scenariosSlice';
import { updateCalculationForScenario, updateCalculationForScenarios } from './calculationResultsSlice';
import { getItemsForId } from '../utils/mapHelpers';
import { MissingQuoteInStateError, MissingScenarioInStateError } from '../errors/state/MissingStateErrors';
import { selectQuoteById, selectQuotesByIds } from './quotesSlice';

export interface ValidationsState {
  allValidationErrors: KeyedState<ScenarioPieceId, string[]>;
}

const initialState: ValidationsState = {
  allValidationErrors: initialKeyedState(),
};

export const validationsSlice = createSlice({
  name: 'validations',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(validateAndUpdateScenario.fulfilled, (state, action: PayloadAction<ScenarioPieceValidationResult[]>) => {
        const validationResults = action.payload;
        for (const validationResult of validationResults) {
          updateKeyedStateVerbatim(state.allValidationErrors, validationResult.validationErrors, validationResult.scenarioPiece.scenarioPieceId);
        }
      })
      .addCase(validateAndUpdateScenarios.fulfilled, (state, action: PayloadAction<ScenarioPieceValidationResult[]>) => {
        const validationResults = action.payload;
        for (const validationResult of validationResults) {
          updateKeyedStateVerbatim(state.allValidationErrors, validationResult.validationErrors, validationResult.scenarioPiece.scenarioPieceId);
        }
      });
  },
});

//Non-Memoized Selectors
const selectValidationErrorDictionary = (state: RootState) => state.validations.allValidationErrors;

//Memoized Selectors
export const selectValidationErrorsForScenarioPiece = createSelector([
  selectValidationErrorDictionary,
  (_state, scenarioPieceId) => scenarioPieceId,
], (validationErrorsMap, scenarioPieceId) => {
  return validationErrorsMap[scenarioPieceId] ?? stableEmptyArrayAsMutable<string>();
});

//Thunks
export const validateAndUpdateScenario = createAppAsyncThunk('validations/validateAndUpdateScenario', async ({ scenarioId, updatedScenarioPiece, additionalOperations }: { scenarioId: ScenarioId, updatedScenarioPiece?: RowCropScenarioPiece, additionalOperations?: () => Promise<void> }, thunkApi) => {
  const state = thunkApi.getState();
  const scenario = selectScenarioById(state, scenarioId);
  if (scenario === null) throw new MissingScenarioInStateError(scenarioId);
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);
  const scenarioPieces = selectRowCropScenarioPiecesByScenarioId(state, scenarioId);
  const scenarioPieceValidations = validateRowCropScenario(scenarioPieces, scenario, quote, updatedScenarioPiece);

  const scenarioPiecesToUpdate = scenarioPieceValidations.filter(validation => validation.shouldUpdate);
  const piecesToUpdate = scenarioPiecesToUpdate.map(validation => thunkApi.dispatch(modifyScenarioPiece({ scenarioPiece: validation.scenarioPiece })));
  await Promise.all(piecesToUpdate);

  if (additionalOperations !== undefined) {
    await additionalOperations();
  }

  await thunkApi.dispatch(modifyUnitYearsForScenario({ scenarioId: scenarioId }));
  await thunkApi.dispatch(updateCalculationForScenario({ scenarioId: scenarioId }));

  return scenarioPieceValidations;
});

export const validateAndUpdateQuote = createAppAsyncThunk('validations/validateAndUpdateQuote', async ({ quoteId }: { quoteId: QuoteId }, thunkApi) => {
  const state = thunkApi.getState();
  const scenarios = getItemsForId(selectAllScenariosByQuoteIdMap(state), quoteId);
  const scenarioIds = scenarios.map(s => s.scenarioId);
  await thunkApi.dispatch(validateAndUpdateScenarios({ scenarioIds }));
});

export const validateAndUpdateScenarios = createAppAsyncThunk('validations/validateAndUpdateScenarios', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }, thunkApi) => {
  const state = thunkApi.getState();
  const scenarioPieceUpdates: unknown[] = [];
  const allValidationResults: ScenarioPieceValidationResult[] = [];
  const scenarios = selectScenariosByIds(state, scenarioIds);
  const quotes = selectQuotesByIds(state, scenarios.map(x => x.quoteId));

  for (const scenarioId of scenarioIds) {
    const scenarioPieces = selectRowCropScenarioPiecesByScenarioId(state, scenarioId);
    const scenario = scenarios.find(s => s.scenarioId === scenarioId);
    if (scenario === undefined) continue;
    const quote = quotes.find(q => q.quoteId === scenario.quoteId);
    if (quote === undefined) continue;
    const scenarioPieceValidations = validateRowCropScenario(scenarioPieces, scenario, quote);

    allValidationResults.push(...scenarioPieceValidations);

    const scenarioPiecesToUpdate = scenarioPieceValidations.filter(validation => validation.shouldUpdate);
    const piecesToUpdate = scenarioPiecesToUpdate.map(validation => thunkApi.dispatch(modifyScenarioPiece({ scenarioPiece: validation.scenarioPiece })));
    scenarioPieceUpdates.push(...piecesToUpdate);
  }

  await Promise.all(scenarioPieceUpdates);

  const unitYearUpdates = scenarioIds.map(scenarioId => thunkApi.dispatch(modifyUnitYearsForScenario({ scenarioId: scenarioId })));
  await Promise.all(unitYearUpdates);

  await thunkApi.dispatch(updateCalculationForScenarios({ scenarioIds: scenarioIds }));

  return allValidationResults;
});

export default validationsSlice.reducer;