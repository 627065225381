import { InputProps } from '@mui/material';
import { Nullable } from '../../../../types/util/Nullable';
import { FormInputParameterNames } from '../../constants/inputConstants';
import { ProtectionFactorInput } from '../protectionFactorInput.component';

interface Props extends InputProps {
  protectionFactor: Nullable<number>;
}
export const VipMpFactorInput = ({ protectionFactor, disabled }: Props) => {
  const maxProtectionFactor = 120;
  const minProtectionFactor = 1;
  return (
    <ProtectionFactorInput
      fieldName={FormInputParameterNames.ProtectionFactor}
      label="Factor"
      protectionFactor={protectionFactor}
      defaultValue={maxProtectionFactor}
      disabled={disabled}
      upperProtectionFactor={maxProtectionFactor}
      lowerProtectionFactor={minProtectionFactor} />
  );

};
