import { Nullable } from '../../types/util/Nullable';
import { MatrixId, ScenarioId } from '../../types/api/PrimaryKeys';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { Grid, Typography } from '@mui/material';
import { selectShouldCloseOnSave, updateOpenDrawer } from '../../app/appDrawerSlice';
import { MidPriceFields, MidPriceInput } from '../../components/formInputs/matrix/midPriceInput.component';
import { PriceScaleFields, PriceScaleInput } from '../../components/formInputs/matrix/priceScaleInput.component';
import { YieldScaleFields, YieldScaleInput } from '../../components/formInputs/matrix/yieldScaleInput.component';
import {
  BottomAxisOffsetTypeFields,
  BottomAxisOffsetTypeInput
} from '../../components/formInputs/matrix/bottomAxisOffsetTypeInput.component';
import {
  BottomAxisPercentChangeFields,
  BottomAxisPercentChangeInput
} from '../../components/formInputs/matrix/bottomAxisPercentChangeInput.component';
import {
  BottomAxisIntegerOffsetFields,
  BottomAxisIntegerOffsetInput
} from '../../components/formInputs/matrix/bottomAxisIntegerOffsetInput.component';
import { ColumnCountFields, ColumnCountInput } from '../../components/formInputs/matrix/columnCountInput.component';
import { RowCountFields, RowCountInput } from '../../components/formInputs/matrix/rowCountInput.component';
import { addMatrixFromForm, modifyMatrix, selectMatrixById } from '../../app/matricesSlice';
import { selectQuoteById, setCurrentQuoteTabIndex } from '../../app/quotesSlice';
import NameInput, { NameFields } from '../../components/formInputs/nameInput.component';
import { isNullOrUndefined } from '../../utils/nullHandling';
import { selectScenarioById } from '../../app/scenariosSlice';
import {
  calculateYieldValue,
  midYieldPercentage,
  topYieldPercentage
} from './matrixDefaults';
import { TopYieldFields, TopYieldInput } from '../../components/formInputs/matrix/topYieldInput.component';
import { MatrixIncludeFilterFields, MatrixIncludeFilterInput } from '../../components/formInputs/matrix/matrixIncludeFilterInput.component';
import { MatrixShowFilterInput, MatrixShowFilterFields } from '../../components/formInputs/matrix/matrixShowFilterInput.component';
import { MatrixIncludeFilterType } from '../../types/api/enums/matrixIncludeFilterType/MatrixIncludeFilterType.enum';
import { MatrixShowFilterType } from '../../types/api/enums/matrixShowFilterType/MatrixShowFilterType.enum';
import './matrix.form.css';
import classNames from 'classnames';
import { selectUserLinkedMatrices } from '../../app/userSettingsSlice';
import { MatrixType } from '../../types/api/enums/matrixType/matrixType.enum';
import { ClientFileTabs } from '../../constants/clientFileTabs';
import { FormWrapperProps } from '../../components/formWrapper/formWrapper.component';
import useDrawerForm from '../../hooks/useDrawerForm';
import useFormWrapper from '../../hooks/useFormWrapper';
import { useMatrixDefaults } from '../../hooks/matrix/useMatrixDefaults';

export interface MatrixFormProps extends FormWrapperProps {
  matrixId: Nullable<MatrixId>;
  primaryScenarioId: ScenarioId;
  scenarioIds: ScenarioId[];
}

export type MatrixFormFields = MidPriceFields & PriceScaleFields &
  TopYieldFields & YieldScaleFields &
  BottomAxisOffsetTypeFields & BottomAxisPercentChangeFields &
  BottomAxisIntegerOffsetFields & ColumnCountFields &
  RowCountFields & NameFields & MatrixIncludeFilterFields & MatrixShowFilterFields;

export const MatrixForm = ({ matrixId, primaryScenarioId, scenarioIds, handleValidation, registerHeader, isCanceling = false }: MatrixFormProps) => {
  const formId = 'matrixForm';
  const methods = useForm<MatrixFormFields>();
  const dispatch = useAppDispatch();

  const shouldCloseOnSave = useAppSelector(selectShouldCloseOnSave);
  const matrix = useAppSelector(state => matrixId === null ? null : selectMatrixById(state, matrixId));
  const firstScenarioId = scenarioIds.at(0) ?? null;
  const firstScenario = useAppSelector(state => firstScenarioId === null ? null : selectScenarioById(state, firstScenarioId));
  const quote = useAppSelector(s => firstScenario === null ? null : selectQuoteById(s, firstScenario.quoteId));

  if (quote === null) { throw new Error('A quote must be selected'); }

  const matrixDefaults = useMatrixDefaults(firstScenario, quote);
  const linkedMatrices = useAppSelector(selectUserLinkedMatrices);
  const matricesLinkageData = linkedMatrices.quotes[quote.quoteId];
  const isLinkMatrices = matrixId === null ? false : matricesLinkageData?.linkedMatrixIds.includes(matrixId) ?? false;
  const topYield = matrix !== null ? calculateYieldValue(matrix.midYield, matrix.columnCount, matrix.yieldScale, midYieldPercentage, topYieldPercentage) : null;

  const defaultMatrixName = () => {
    let formattedName = 'Matrix';
    if (scenarioIds.length > 1) {
      formattedName += ` - ${scenarioIds.length} scenarios`;
    } else if (firstScenario !== null) {
      formattedName += ` - ${firstScenario.name}`;
    }
    return formattedName;
  };

  const onSubmit: SubmitHandler<MatrixFormFields> = async data => {
    const midYield = calculateYieldValue(data.topYield, data.columnCount, data.yieldScale, topYieldPercentage, midYieldPercentage);

    if (!isNullOrUndefined(matrix)) {
      await dispatch(modifyMatrix({
        matrixData: {
          ...data,
          matrixType: matrix.matrixType,
          isActive: matrix.isActive,
          primaryScenarioId: matrix.primaryScenarioId,
          midYield: midYield,
          offlineCreatedOn: matrix.offlineCreatedOn,
          offlineLastUpdatedOn: matrix.offlineLastUpdatedOn,
          offlineDeletedOn: matrix.offlineDeletedOn,
        },
        matrix: matrix,
        includedScenarios: scenarioIds,
      }));
    } else {
      const response = await dispatch(addMatrixFromForm({
        matrixData: {
          ...data,
          matrixType: MatrixType.Comparison,
          isActive: true,
          primaryScenarioId: primaryScenarioId,
          midYield: midYield,
          offlineCreatedOn: undefined,
          offlineLastUpdatedOn: undefined,
          offlineDeletedOn: undefined,
        },
        includedScenarios: scenarioIds,
      }));
      if (!shouldCloseOnSave && addMatrixFromForm.fulfilled.match(response)) {
        // If we are keeping the drawer open after adding a new matrix we need to update the current drawer state with the proper ids so that on next save we modify
        // the existing matrix rather than add a new one.
        const addedMatrix = response.payload;
        dispatch(updateOpenDrawer({ formName: 'matrixForm', matrixId: addedMatrix.matrixId, primaryScenarioId: addedMatrix.primaryScenarioId, scenarioIds: addedMatrix.scenarioMatrices.map(sm => sm.scenarioId) }));
      }

      dispatch(setCurrentQuoteTabIndex(ClientFileTabs.comparisonView.index));
    }
  };

  const handleReset = () => {
    methods.reset();
  };

  const shouldSubmit = methods.formState.isDirty || matrixId === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit);
  const handleSubmit = useFormWrapper('Matrix', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit} onReset={handleReset}>
        <Grid container spacing={4} p={2}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">Matrix Edits</Typography>
            </Grid>
            <Grid item xs={12}>
              <NameInput name={matrix?.name ?? defaultMatrixName()} />
            </Grid>
            <Grid item xs={6}>
              <MidPriceInput className={classNames({ 'linked-item': isLinkMatrices })} midPrice={matrix?.midPrice ?? matrixDefaults.midPrice} />
            </Grid>
            <Grid item xs={6}>
              <PriceScaleInput className={classNames({ 'linked-item': isLinkMatrices })} priceScale={matrix?.priceScale ?? matrixDefaults.priceScale} />
            </Grid>
            <Grid item xs={6}>
              <TopYieldInput className={classNames({ 'linked-item': isLinkMatrices })} topYield={topYield ?? matrixDefaults.topYield} />
            </Grid>
            <Grid item xs={6}>
              <YieldScaleInput className={classNames({ 'linked-item': isLinkMatrices })} yieldScale={matrix?.yieldScale ?? matrixDefaults.yieldScale} />
            </Grid>
            <Grid item xs={12}>
              <BottomAxisOffsetTypeInput bottomAxisOffsetType={matrix?.bottomAxisOffsetType ?? null} />
            </Grid>
            <Grid item xs={6}>
              <BottomAxisPercentChangeInput className={classNames({ 'linked-item': isLinkMatrices })} bottomAxisPercentChange={matrix?.bottomAxisPercentChange ?? null} bottomAxisOffsetType={matrix?.bottomAxisOffsetType ?? null} />
            </Grid>
            <Grid item xs={6}>
              <BottomAxisIntegerOffsetInput className={classNames({ 'linked-item': isLinkMatrices })} bottomAxisIntegerOffset={matrix?.bottomAxisIntegerOffset ?? null} bottomAxisOffsetType={matrix?.bottomAxisOffsetType ?? null} />
            </Grid>
            <Grid item xs={6}>
              <ColumnCountInput className={classNames({ 'linked-item': isLinkMatrices })} columnCount={matrix?.columnCount ?? matrixDefaults.columnCount} />
            </Grid>
            <Grid item xs={6}>
              <RowCountInput className={classNames({ 'linked-item': isLinkMatrices })} rowCount={matrix?.rowCount ?? matrixDefaults.rowCount} />
            </Grid>
            <Grid item xs={6}>
              <MatrixIncludeFilterInput className={classNames({ 'linked-item': isLinkMatrices })} includeFilter={matrix === null || matrix.includeFilter === MatrixIncludeFilterType.Unset ? matrixDefaults.includeFilter : matrix.includeFilter} />
            </Grid>
            <Grid item xs={6}>
              <MatrixShowFilterInput className={classNames({ 'linked-item': isLinkMatrices })} showFilter={matrix === null || matrix.showFilter === MatrixShowFilterType.Unset ? matrixDefaults.showFilter : matrix.showFilter} />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
