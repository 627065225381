import { ReactNode, useEffect, useState } from 'react';
import { Palette, useTheme } from '@mui/material';
import { DraggableProvided, DraggableRubric, DraggableStateSnapshot } from '@hello-pangea/dnd';

interface Props<T> {
  items: T[];
  horizontal: boolean;
}

export interface Sortable {
  sortIndex: number;
}

interface ItemRenderProps {

}

const getListStyle = (palette: Palette, isDraggingOver: boolean, horizontalList: boolean) => {
  if (horizontalList) {
    return {
      background: isDraggingOver ? palette.action.disabled : palette.background.default,
      display: 'flex',
      overflow: 'auto',
      flexGrow: 1,
      width: '100%',
    };
  }
  return {
    background: isDraggingOver ? palette.action.disabled : palette.background.default,
    height: '100%',
    width: '100%',
  };
};

const validateSortIndexes = <T extends Sortable>(items: T[]): T[] => {
  if (items.length === 0) {
    return items;
  }
  // if the sort index isn't unique, only one item will display so we need to apply some sorting
  return items.map((item, index) => ({ ...item, sortIndex: index }));
};

const reorderSortItems = <T extends Sortable>(items: T[], startSortIndex: number, endSortIndex: number): T[] => {
  // in the scenario where a quote could've been deleted, our sort index might not match the index of our items array
  const startIndex = items.findIndex(q => q.sortIndex === startSortIndex);
  const endIndex = items.findIndex(q => q.sortIndex === endSortIndex);

  if (startIndex === -1 || endIndex === -1) {
    return items;
  }

  const [removed] = items.splice(startIndex, 1);
  items.splice(endIndex, 0, removed);
  return items;
};

export const useDragAndDrop = <T extends Sortable>({ items, horizontal }: Props<T>) => {
  const { palette } = useTheme();
  const [list, setList] = useState<T[]>(validateSortIndexes(items));

  const reorder = (startSortIndex: number, endSortIndex: number) => {
    const reorderedItems = reorderSortItems(list, startSortIndex, endSortIndex);
    // https://github.com/hello-pangea/dnd/blob/main/docs/guides/responders.md#persisting-a-reorder
    // our list will have a visual issue if we do not keep a local state up to date
    setList(reorderedItems);
    return reorderedItems;
  };

  const getRenderItem = <H extends ItemRenderProps>(funcProps: H, child: (provided: DraggableProvided, snapshot: DraggableStateSnapshot, item: T, funcProps: H) => ReactNode) => {
    const renderItem = (provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => {
      const listItem = list.find(q => q.sortIndex === rubric.source.index);

      if (!listItem) {
        return null;
      }

      return child(provided, snapshot, listItem, funcProps);
    };

    return renderItem;
  };

  useEffect(() => {
    setList(validateSortIndexes(items));
  }, [items]);

  return {
    list,
    reorder,
    getRenderItem,
    getListStyle: (isDragging: boolean) => getListStyle(palette, isDragging, horizontal),
  };
};