import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import {
  dependentScenarioPieceShouldMatchUnderlyingScenarioPieceType,
  lowerCoverageShouldMatchUnderlyingLowerCoverage,
  missingUnderlyingScenarioPiece,
  RowCropScenarioPieceUnderlyingValidationRule,
  upperCoverageShouldMatchUnderlyingUpperCoverage
} from '../validationRules/rowCropScenarioPieceValidationRules';
import { composeValidationResults } from '../validationHandling';
import { CombinedValidationResult } from '../validateScenario';
import { Nullable } from '../../../types/util/Nullable';

export function validateMySco (scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: Nullable<RowCropScenarioPiece>, allScenarioPieces: RowCropScenarioPiece[]): CombinedValidationResult<RowCropScenarioPiece> {
  if (underlyingScenarioPiece === null) {return missingUnderlyingScenarioPiece;}

  const validationRules: RowCropScenarioPieceUnderlyingValidationRule[] = [
    dependentScenarioPieceShouldMatchUnderlyingScenarioPieceType,
    upperCoverageShouldMatchUnderlyingUpperCoverage,
    lowerCoverageShouldMatchUnderlyingLowerCoverage,
  ];

  const validationResults = validationRules.map(validationRule => validationRule(scenarioPiece, allScenarioPieces, underlyingScenarioPiece));
  return composeValidationResults(validationResults);
}