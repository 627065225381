import { Box } from '@mui/material';
import { getScalingTransformString } from '../../../utils/css/scaling';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectMainContentZoomPercentage } from '../../../app/applicationZoomSlice';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const MainContentZoomableArea = ({ children }: Props) => {
  const zoomPercentage = useAppSelector(selectMainContentZoomPercentage);
  const inverseZoomPercentage = zoomPercentage <= 0
    ? 100
    : 100 / zoomPercentage * 100;

  return (
    <Box width={`${inverseZoomPercentage}%`} height={`${inverseZoomPercentage}%`} sx={{ transform: getScalingTransformString(zoomPercentage), transformOrigin: 'top left' }}>
      {children}
    </Box>
  );
};