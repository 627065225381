import axios from 'axios';
import { HistoricalAnalysisId, ScenarioId } from '../types/api/PrimaryKeys';
import HistoricalAnalysis from '../types/api/historicalAnalysis';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { envBaseApiUrl } from '../constants/envConstants';

export const getHistoricalAnalysesByScenarioIds = (scenarioIds: ScenarioId[]) => axios.post<HistoricalAnalysis[]>(`${envBaseApiUrl}/historical-analyses/scenarios`, scenarioIds);

export const createHistoricalAnalysis = (historicalAnalysis: HistoricalAnalysis) => axios.post<CreatedItemResult<HistoricalAnalysisId>>(`${envBaseApiUrl}/historical-analyses/`, historicalAnalysis);

export const updateHistoricalAnalysis = (historicalAnalysis: HistoricalAnalysis) => axios.put<void>(`${envBaseApiUrl}/historical-analyses`, historicalAnalysis);