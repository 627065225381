import { admDb } from '../../db';
import { getAll, getMultiple, getSingle } from '../offlineDataAccess.service';
import { getCounties, getCountiesForState, getCounty } from '../counties.service';
import { County } from '../../types/api/adm/County';

const table = admDb.counties;

export const getCountiesRequest = async (year: number): Promise<County[]> => {
  const request = () => getCounties(year);
  return await getAll(table, request, admDb);
};

export const getCountyRequest = async (year: number, countyId: string): Promise<County> => {
  const request = () => getCounty(year, countyId);
  return await getSingle(table, { countyId: countyId }, request, admDb);
};

export const getCountiesForStateRequest = async (year: number, stateCode: string): Promise<County[]> => {
  const request = () => getCountiesForState(year, stateCode);
  return await getMultiple(table, { stateCode: stateCode }, request, admDb);
};