import { Grid } from '@mui/material';
import { useState } from 'react';
import ReconciliationDialog from '../../pages/reconciliationDialog/reconciliationDialog';

//Connor Julian 07/25/2024
//Removed Wifi Toggle icon as part of initial release, to revert, uncomment code (Ctrl+/) and delete above import statements.


// import { Grid, Switch } from '@mui/material';
// import { Wifi as WifiIcon, WifiOff as WifiOffIcon } from '@mui/icons-material';
// import { ChangeEvent, useState } from 'react';
// import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
// import { selectIsOnline, selectUserNetworkEnabled, shouldForceOfflineUpdated } from '../../app/onlineSlice';
// import { openConfirm } from '../../app/confirmSlice';
// import ReconciliationDialog from '../../pages/reconciliationDialog/reconciliationDialog';
// import { selectIsLightMode } from '../../app/userSettingsSlice';

const NetworkToggle = () => {
  //const dispatch = useAppDispatch();
  //const isUserNetworkEnabled = useAppSelector(selectUserNetworkEnabled);
  //const isOnline = useAppSelector(selectIsOnline);
  const [isReconciliationDialogOpen, setIsReconciliationDialogOpen] = useState(false);
  //const isLightMode = useAppSelector(selectIsLightMode);

  // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const goingOnline = event.target.checked && !isUserNetworkEnabled;

  //   if (goingOnline) {
  //     setIsReconciliationDialogOpen(true);
  //   } else {
  //     dispatch(openConfirm({
  //       title: 'Switching to Offline Mode',
  //       confirmText: 'Go Offline',
  //       message: 'Any in progress downloads will be canceled, and you will no longer have access to online features. Please ensure your work is complete before disconnecting.',
  //       cancelText: 'Cancel',
  //       onConfirm: () => dispatch(shouldForceOfflineUpdated(goingOnline)),
  //     }));
  //   }
  // };

  const handleReconciliationDialogClose = () => {
    setIsReconciliationDialogOpen(false);
  };

  return (
    <Grid container alignItems="flex-end">
      {/* <Grid item>
        <Switch
          color="default"
          onChange={handleChange}
          checked={isUserNetworkEnabled}
        />
      </Grid>
      <Grid item sx={{ color: theme => isLightMode ? theme.palette.common.black : theme.palette.common.white }}>
        {isOnline ? <WifiIcon /> : <WifiOffIcon />}
      </Grid> */}
      {isReconciliationDialogOpen && <ReconciliationDialog isOpen={isReconciliationDialogOpen} handleClose={handleReconciliationDialogClose} />}
    </Grid>
  );
};

export default NetworkToggle;
