import { PriceDiscoveryPeriod } from '../../utils/discoveryPeriodUtils';

export const getDiscoveryAwareStyling = (isLightMode: boolean, discoveryPeriod: PriceDiscoveryPeriod) => {
  let rootStyle = {};
  let notchedOutline = {};
  let outlinedStyle = {};

  if (discoveryPeriod === PriceDiscoveryPeriod.InDiscovery) {
    // discovery
    if (isLightMode) {
      rootStyle = {
        background: '#bbd97c',
      };
      outlinedStyle = {
        color: '#182d16',
      };
    } else {
      rootStyle = {
        background: '#182d16',
      };
      outlinedStyle = {
        color: '#bbd97c',
      };
    }
  } else if (discoveryPeriod === PriceDiscoveryPeriod.Set) {
    // set
    notchedOutline = {
      borderWidth: '0px',
    };
    outlinedStyle = {
      fontWeight: 'bold',
    };
  }

  return {
    '& .MuiOutlinedInput-root': {
      ...rootStyle,
      '& .MuiOutlinedInput-notchedOutline': {
        ...notchedOutline,
      },
    },
    '& .MuiOutlinedInput-input': outlinedStyle,
  };
};
