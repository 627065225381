import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { createAppAsyncThunk } from './thunkHelpers';
import { SupportPayload } from '../types/api/support/support';
import { submitSupportTicket } from '../services/support.service';
import { openToast } from './toastSlice';

interface SupportState {
  isSupportModalOpen: boolean;
}

const initialState: SupportState = {
  isSupportModalOpen: false,
};

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    openSupportModal(state) {
      state.isSupportModalOpen = true;
    },
    closeSupportModal(state) {
      state.isSupportModalOpen = false;
    },
  },
});

export const { openSupportModal, closeSupportModal } = supportSlice.actions;

export default supportSlice.reducer;


export const selectSupportModalOpen = (state: RootState) => state.support.isSupportModalOpen;

export const sendSupportTicket = createAppAsyncThunk('support/sendSupportTicket', async ({ supportPayload }: { supportPayload : SupportPayload }, thunkApi) => {
  // I dont know if you can send a file via json payload? prototype I use form field. Can maybe change.
  const formData = new FormData();
  formData.append('File', supportPayload.blob, 'screenshot.png');
  formData.append('Email', supportPayload.email);
  formData.append('Description', supportPayload.description);
  formData.append('Reason', supportPayload.reason);
  formData.append('Url', supportPayload.url);

  await submitSupportTicket(formData);

  thunkApi.dispatch(openToast({ type: 'success', message: 'Ticket submitted successfully.', shouldTimeout: true, allowClickToClose: false }));
});