import { Grid } from '@mui/material';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useFederalOfferData } from '../../../hooks/useFederalOfferData';
import { HighRiskType, InsurancePlanCode, ScenarioPieceType, UnitStructureCode } from '@silveus/calculations';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import {
  createScenarioPiece,
  removeScenarioPieceAndRecalculate,
  selectRowCropScenarioPiecePlanCodes
} from '../../../app/scenarioPiecesSlice';
import { Nullable } from '../../../types/util/Nullable';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { Quote } from '../../../types/api/Quote';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import useFormWrapper from '../../../hooks/useFormWrapper';
import useDrawerForm from '../../../hooks/useDrawerForm';
import { AdmUpperCoverageLevelInput } from '../../../components/formInputs/scenarioPiece/admUpperCoverageLevelInput.component';
import { AdmLowerCoverageLevelInput } from '../../../components/formInputs/scenarioPiece/admLowerCoverageLevelInput.component';
import { AdmProtectionFactorInput } from '../../../components/formInputs/scenarioPiece/admProtectionFactor.component';
import { useEffect } from 'react';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import { FormInputParameterNames } from '../../../components/formInputs/constants/inputConstants';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import { LowerCoverageLevelFields } from '../../../components/formInputs/scenarioPiece/lowerCoverageLevelInput.component';
import CountyYieldInputGroup, { CountyYieldInputGroupFields } from '../../../components/formInputs/scenario/countyYieldInputGroup';
import PriceInputGroup, { PriceInputGroupFields } from '../../../components/formInputs/scenario/priceInputGroup.component';
import { ProtectionFactorFields } from '../../../components/formInputs/scenarioPiece/protectionFactorInput.component';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import {
  UpperCoverageLevelFields
} from '../../../components/formInputs/scenarioPiece/upperCoverageLevelInput.component';
import { selectScenarioById, updateScenario } from '../../../app/scenariosSlice';
import { mergeScenarioWithYieldAndPriceUpdates } from '../../../utils/scenarioUpdateUtils';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { fetchScenarioPricesAndYields } from '../../../app/admSlice';
import { distinct } from '../../../utils/arrayUtils';
import { useScenarioPieceForm } from '../useScenarioPieceForm';
import { useStableInsurancePlanCodeArray } from '../../../hooks/useStableInsurancePlanCodeArray';
import {
  ScenarioPiecePlanSelection,
  ScenarioPiecePlanSelectionFormValues
} from '../scenarioPiecePlanSelection.component';
import { ScenarioPieceFormSnapshot } from '../../../types/app/ScenarioPieceFormSnapshot';

export interface ArpScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  selectedScenarioPieceType: ScenarioPieceType;
  scenarioId: ScenarioId;
  year: number;
  countyId: string;
  highRiskTypeId: HighRiskType;
  typeId: string;
  practiceId: string;
  disabled: boolean;
  quote: Quote;
  handleCancel: () => void;
  updateScenarioPieceFormSnapshot: (updatePlanSelection: boolean, newPlanSelection: ScenarioPieceType, scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null) => void;
  scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null;
  scenarioPieceTypePlanSelectionList: ScenarioPieceType[];
}

export type ArpScenarioPieceFormFields = ScenarioPieceFormFields & UpperCoverageLevelFields & LowerCoverageLevelFields & ProtectionFactorFields & PriceInputGroupFields & CountyYieldInputGroupFields;

export const ArpScenarioPieceForm = ({ scenarioPiece, selectedScenarioPieceType, scenarioId, year, countyId, typeId, highRiskTypeId, practiceId, disabled, registerHeader, handleCancel, quote, handleValidation, updateScenarioPieceFormSnapshot, scenarioPieceFormSnapshot, scenarioPieceTypePlanSelectionList, isCanceling = false }: ArpScenarioPieceFormProps) => {
  const dispatch = useAppDispatch();
  const methods = useScenarioPieceForm<ArpScenarioPieceFormFields>(scenarioPieceFormSnapshot?.scenarioPiece ?? scenarioPiece);
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  const planCodes = useAppSelector(state => selectRowCropScenarioPiecePlanCodes(state, scenarioId));
  const formId = 'ArpScenarioPieceForm';

  useEffect(() => {
    //Fetch prices and yields including ARP in the request because ARP may have different county yields than the county yields
    //associated with the plan used to create the scenario.
    const distinctPlanCodes = distinct([...planCodes, InsurancePlanCode.ARP]);
    dispatch(fetchScenarioPricesAndYields({ year, countyId, typeId, practiceId, planCodes: distinctPlanCodes }));

    //We call setValue because the default value for lowerCoverageLevel doesn't set before AdmProtectionFactorInput is rendered
    methods.setValue(FormInputParameterNames.LowerCoverageLevelName, 18);
  }, []);

  useFederalOfferData(scenarioPiece, scenarioId, selectedScenarioPieceType, year, countyId, typeId, practiceId, highRiskTypeId);

  const onSubmit: SubmitHandler<ArpScenarioPieceFormFields> = async data => {
    data.scenarioPieceType = selectedScenarioPieceType;

    const planCode = getInsurancePlanCodeForScenarioPiece(selectedScenarioPieceType);
    updateScenarioPieceFormSnapshot(false, ScenarioPieceType.Unset, null);

    const newScenarioPiece: RowCropScenarioPiece = {
      isActive: data.isActive,
      isInvalid: false,
      lowerCoverageLevel: data.lowerCoverageLevel,
      planCode: planCode,
      protectionFactor: data.protectionFactor,
      rowCropScenarioPieceExtendedData: null,
      rowCropScenarioPieceId: scenarioPiece?.rowCropScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: data.scenarioPieceType,
      unitStructure: UnitStructureCode.AU,
      upperCoverageLevel: data.upperCoverageLevel,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    if (scenario) {
      await dispatch(updateScenario({
        updatedScenario: mergeScenarioWithYieldAndPriceUpdates(scenario, data),
      }));
    }

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(createScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId, updatedScenarioPiece: newScenarioPiece }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  const insurancePlanCodes = useStableInsurancePlanCodeArray(InsurancePlanCode.ARP);

  const getLatestFormValues = () => {
    const formValues = methods.getValues();
    const scenarioPieceFormValues: ScenarioPiecePlanSelectionFormValues = {
      ...formValues,
      unitStructure: UnitStructureCode.AU,
      producerYield: null,
      approvedYield: null,
    };
    return scenarioPieceFormValues;
  };

  useEffect(() => {
    let changeIt = false;
    if (scenarioPieceFormSnapshot?.scenarioPiece?.scenarioPieceType !== undefined &&
      scenarioPieceFormSnapshot.scenarioPiece.scenarioPieceType !== selectedScenarioPieceType) {
      changeIt = true;
    } else if (scenarioPiece !== null && scenarioPiece.scenarioPieceType !== selectedScenarioPieceType) {
      changeIt = true;
    }

    if (changeIt) {
      methods.setValue('scenarioPieceType', selectedScenarioPieceType, { shouldDirty: true });
    }
  }, [scenarioPieceFormSnapshot, selectedScenarioPieceType, scenarioPiece]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ScenarioPiecePlanSelection
          scenarioPieceFormSnapshot={scenarioPieceFormSnapshot}
          scenarioPiece={scenarioPiece}
          scenarioId={scenarioId}
          selectedScenarioPieceType={selectedScenarioPieceType}
          updateScenarioPieceFormSnapshot={updateScenarioPieceFormSnapshot}
          scenarioPieceTypePlanSelectionList={scenarioPieceTypePlanSelectionList}
          getScenarioPieceFormValues={getLatestFormValues}
        />
      </Grid>
      <Grid item xs={12}>
        <FormProvider {...methods}>
          <form id={formId} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AdmUpperCoverageLevelInput upperCoverageLevel={scenarioPieceFormSnapshot?.scenarioPiece?.upperCoverageLevel ?? scenarioPiece?.upperCoverageLevel ?? null} disabled={disabled} />
              </Grid>
              <Grid item xs={6} sx={{ display: 'none' }}>
                <AdmLowerCoverageLevelInput lowerCoverageLevel={18} disabled />
              </Grid>
              <Grid item xs={6}>
                <AdmProtectionFactorInput protectionFactor={scenarioPieceFormSnapshot?.scenarioPiece?.protectionFactor ?? scenarioPiece?.protectionFactor ?? null} disabled={disabled} defaultValue={scenarioPieceFormSnapshot?.scenarioPiece?.protectionFactor ?? 120} />
              </Grid>
              <PriceInputGroup quote={quote} scenario={scenario} scenarioPiece={scenarioPiece} projectedPrice={scenarioPieceFormSnapshot?.projectedPrice ?? scenario?.projectedPrice ?? null} harvestPrice={scenarioPieceFormSnapshot?.harvestPrice ?? scenario?.harvestPrice ?? null} />
              <CountyYieldInputGroup
                year={year}
                countyId={countyId}
                volatility={scenario?.volatility ?? 0}
                coverageLevel={scenarioPieceFormSnapshot?.scenarioPiece?.upperCoverageLevel ?? scenarioPiece?.upperCoverageLevel ?? null}
                typeId={scenario?.typeId ?? null}
                practiceId={scenario?.practiceId ?? null}
                commodityCode={quote.commodityCode}
                insurancePlanCodes={insurancePlanCodes}
                scenarioPiece={scenarioPiece}
                scenarioId={scenario?.scenarioId ?? null}
                expectedCountyYield={scenarioPieceFormSnapshot?.expectedYield ?? scenario?.expectedCountyYield ?? null}
                actualCountyYield={scenarioPieceFormSnapshot?.actualYield ?? scenario?.actualCountyYield ?? null} />
              <Grid item xs={12}>
                <IsActiveInput isActive={scenarioPieceFormSnapshot?.scenarioPiece?.isActive ?? scenarioPiece?.isActive ?? null} />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
      </Grid>
    </Grid >
  );
};
