import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { Quote } from '../../../types/api/Quote';
import { ScenarioId, ScenarioPieceId } from '../../../types/api/PrimaryKeys';
import ScenarioOption from '../../../types/api/options/ScenarioOption';
import PriceGroup from '../../../types/api/adm/PriceGroup';
import { getItemsForId } from '../../../utils/mapHelpers';
import { getQuoteSummaryImportantDates } from './getImportantDates';
import { QuoteSummaryScenarioPiece } from '../../../types/api/reports/QuoteSummaryScenarioPiece';
import InsuranceCalendar from '../../../types/api/adm/InsuranceCalendar';
import { ScenarioPieceResponseDTO } from '@silveus/calculations';
import PriceGroupMember from '../../../types/api/adm/PriceGroupMember';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../types/util/Nullable';
import { QuoteSummaryRowCropScenario } from '../../../types/api/reports/QuoteSummaryRowCropScenario';
import { buildCountyAndCommodityKey } from '../../../types/app/CompositeKeys';
import { getPriceGroupMemberFromPriceGroups } from '../../../app/admSlice';
import { getQuoteSummaryScenarioPieces } from './getQuoteSummaryScenarioPieces';
import { scenarioPieceOrderingServiceInstance } from '../../../utils/scenarioOrderingServiceWrappers';
import { getPrecisionForCommodityCodeAndOptions } from '../../../utils/priceUtils';
import { getQuoteSummaryLiabilityPerAcreSummary } from './quoteSummary/getQuoteSummaryLiabilityPerAcreSummary';

export const getQuoteSummaryScenarios = (
  scenarios: RowCropScenario[],
  quotes: Quote[],
  scenarioOptionsByScenarioId: Map<ScenarioId, ScenarioOption[]>,
  insuranceCalendarsByQuoteInfo: Map<`${string}-${string}`, InsuranceCalendar[]>,
  priceGroupMembers: Map<`${string}-${string}`, PriceGroupMember[]>,
  calcsByScenarioPiece: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  scenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>,
  priceGroups: PriceGroup[],
  acresByScenario: Record<ScenarioId, Nullable<number>>,
  sharePercentByScenario: Map<ScenarioId, number>,
  approvedYieldByScenario: Map<ScenarioId, Nullable<number>>,
  adjustedYieldByScenario: Map<ScenarioId, Nullable<number>>,
): {
  quoteSummaryScenarios: QuoteSummaryRowCropScenario[],
  quoteSummaryScenarioPiecesByScenario: Map<ScenarioId, QuoteSummaryScenarioPiece[]>
} => {
  const quoteSummaryScenarios: QuoteSummaryRowCropScenario[] = [];
  const quoteSummaryScenarioPiecesByScenario = new Map<ScenarioId, QuoteSummaryScenarioPiece[]>();

  for (const scenario of scenarios) {
    const quote = quotes.find(quote => quote.quoteId === scenario.quoteId);
    if (quote === undefined) continue;

    //Get the appropriate insurance calendars to be mapped into the important dates
    const countyCommodityKey = buildCountyAndCommodityKey(quote.countyId, quote.commodityCode);
    const insuranceCalendarsForQuote = getItemsForId(insuranceCalendarsByQuoteInfo, countyCommodityKey);
    const basePieceTypes = scenarioPieceOrderingServiceInstance.getBaseScenarioPieces();
    const insurancePlanCodes = getItemsForId(scenarioPiecesByScenario, scenario.scenarioId).filter(sp => basePieceTypes.includes(sp.scenarioPieceType)).map(sp => sp.planCode);
    const insuranceCalendars = insuranceCalendarsForQuote.filter(ic => ic.typeId === scenario.typeId && ic.practiceId === scenario.practiceId && insurancePlanCodes.includes(ic.insurancePlanCode));

    const importantDates = getQuoteSummaryImportantDates(insuranceCalendars);

    const scenarioPieces = getItemsForId(scenarioPiecesByScenario, scenario.scenarioId);

    //Get the price group member and corresponding price group to determine if the projected price is set
    const priceGroupMember = getPriceGroupMemberFromPriceGroups(priceGroupMembers, quote.countyId, quote.commodityCode, scenario.typeId, scenario.practiceId ?? '');
    const priceGroup = priceGroups.find(x => priceGroupMember !== undefined && x.priceGroupID === priceGroupMember.priceGroupID);

    let isProjectedPriceBeyondEndDate = false;
    let isProjectedPriceSetTrue = false;
    if (priceGroup !== undefined) {
      const endDate = new Date(priceGroup.projectedDiscoveryEndDate);
      const currentDate = new Date();
      isProjectedPriceBeyondEndDate = currentDate >= endDate;
      isProjectedPriceSetTrue = priceGroup.projectedPriceSet;
    }

    const isProjectedPriceSet = isProjectedPriceBeyondEndDate || isProjectedPriceSetTrue;

    const approvedYield = approvedYieldByScenario.get(scenario.scenarioId) ?? null;
    const adjustedYield = adjustedYieldByScenario.get(scenario.scenarioId) ?? null;

    const scenarioOptionsForScenario = getItemsForId(scenarioOptionsByScenarioId, scenario.scenarioId);
    const optionsForScenario = scenarioOptionsForScenario.map(so => so.option);

    const quoteSummaryScenarioPieces = getQuoteSummaryScenarioPieces(
      scenarioPieces,
      calcsByScenarioPiece,
      optionsForScenario,
      scenario.projectedPrice ?? 0,
      isProjectedPriceSet,
      scenario,
      approvedYield,
      adjustedYield,
    );

    const liabilityPerAcre = getQuoteSummaryLiabilityPerAcreSummary(quoteSummaryScenarioPieces);

    quoteSummaryScenarioPiecesByScenario.set(scenario.scenarioId, quoteSummaryScenarioPieces);

    const precision = getPrecisionForCommodityCodeAndOptions(quote.commodityCode, optionsForScenario);

    const quoteSummaryScenario: QuoteSummaryRowCropScenario = {
      acres: acresByScenario[scenario.scenarioId] ?? 0,
      actualCountyYield: scenario.actualCountyYield,
      actualProducerYield: scenario.actualProducerYield,
      expectedCountyYield: scenario.expectedCountyYield,
      hailAcres: 0,     //TODO #65409: Figure out how to get this value
      harvestPrice: scenario.harvestPrice,
      highRiskTypeId: scenario.highRiskTypeId,
      importantDates: importantDates,
      isFinalized: scenario.isFinalized,
      isVisible: scenario.isVisible,
      name: scenario.name,
      offlineCreatedOn: scenario.offlineCreatedOn,
      offlineDeletedOn: scenario.offlineDeletedOn,
      offlineLastUpdatedOn: scenario.offlineLastUpdatedOn,
      practiceId: scenario.practiceId ?? '',
      projPriceFormat: `$#,##0.${'0'.repeat(precision)}`,
      projectedPrice: scenario.projectedPrice,
      quickUnit: scenario.quickUnit,
      quoteId: scenario.quoteId,
      rowCropScenarioId: scenario.rowCropScenarioId,
      scenarioColor: scenario.scenarioColor,
      scenarioId: scenario.scenarioId,
      scenarioOptions: scenarioOptionsForScenario,
      sharePercent: sharePercentByScenario.get(scenario.scenarioId) ?? 1,
      totalLiabilityPerAcre: liabilityPerAcre,
      typeId: scenario.typeId,
      volatility: scenario.volatility,
      sortIndex: scenario.sortIndex,
    };

    quoteSummaryScenarios.push(quoteSummaryScenario);
  }

  return {
    quoteSummaryScenarios,
    quoteSummaryScenarioPiecesByScenario,
  };
};