import { InputAdornment } from '@mui/material';
import NumberTextField, { onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';

interface BudgetPercentageInputProps {
  onBlur: () => void;
  value: number;
  name: string;
}

export type BudgetPercentageFields = {
  budgetPercentage: number;
};

const BudgetPercentageInput = ({ onBlur, value, name }: BudgetPercentageInputProps) => {

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        min: VALIDATION_RULES.minimum(0),
        max: VALIDATION_RULES.maximum(100),
      }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          type="number"
          fullWidth
          required
          size="small"
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
          inputProps={{ sx: { padding: '4px !important', textAlign: 'right' } }}
          onBlur={() => onBlur()}
        />
      )}
    />
  );
};

export default BudgetPercentageInput;