import { Nullable } from '../../../types/util/Nullable';
import { Controller } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { TextField } from '@mui/material';

interface MidPriceProps {
  midPrice: Nullable<number>;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
}

export type MidPriceFields = {
  midPrice: number;
}

export const MidPriceInput = ({ midPrice, className, autoFocus = false, disabled = false }: MidPriceProps) => {
  return (
    <Controller
      name="midPrice"
      rules={{ required: VALIDATION_RULES.required(), min: VALIDATION_RULES.minimum(0) }}
      defaultValue={midPrice}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          className={className}
          type="number"
          label="Mid Price"
          size="small"
          fullWidth
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => field.onChange(parseFloat(e.target.value))}
          autoFocus={autoFocus}
          disabled={disabled}
          inputProps={{ step: 0.1 }}
        />
      )}
    />
  );
};
