import React, { useEffect, useState } from 'react';

// This hook can be used when a form needs to be submitted on the render after some state is updated.
// This should only be used in situations where it is not easy to pass the updated values directly to the submit handler.
// Example: clicking a button that updates state and submits a form with values based on the updated state.
const useSubmitOnNextRender = (handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>) => {
  const [submitOnNextRender, setSubmitOnNextRender] = useState(false);

  useEffect(() => {
    if (submitOnNextRender) {
      handleSubmit();
      setSubmitOnNextRender(false);
    }
  }, [submitOnNextRender]);

  return setSubmitOnNextRender;
};

export default useSubmitOnNextRender;
