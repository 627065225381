import { MatrixReportData } from '../../../types/api/reports/MatrixReportData';
import { MatrixId, MatrixPresetId, ScenarioId, ScenarioPieceId } from '../../../types/api/PrimaryKeys';
import { ReportAnalysisGenerationType } from '../../../types/api/enums/report/reportAnalysisGenerationType.enum';
import { MatrixIncludeFilterType } from '../../../types/api/enums/matrixIncludeFilterType/MatrixIncludeFilterType.enum';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { ScenarioPieceResponseDTO, ScenarioPieceType, ScenarioRequestDTO } from '@silveus/calculations';
import { IEnumAttributes } from '../../../types/api/enums/IEnumAttributes';
import { MatrixShowFilterType } from '../../../types/api/enums/matrixShowFilterType/MatrixShowFilterType.enum';
import { CellTextColorSettings } from '@silveus/react-matrix';
import { Nullable } from '../../../types/util/Nullable';
import { Quote } from '../../../types/api/Quote';
import {
  createMatrixDefaults,
  matrixReportDataColumnCount,
  matrixReportDataRowCount
} from '../../../pages/matrix/matrixDefaults';
import { getMatrixReportData } from './getMatrixReportData';
import { SigReportDocumentType } from '../../../constants/sigReportDocumentType';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { InputCostScenarioPiece } from '../../../types/api/InputCostScenarioPiece';
import ForwardSoldScenarioPiece from '../../../types/api/ForwardSoldScenarioPiece';
import HarvestRevenueScenarioPiece from '../../../types/api/HarvestRevenueScenarioPiece';
import { ClientFile } from '../../../types/api/ClientFile';
import { getItemsForId } from '../../../utils/mapHelpers';
import { buildMatrixCellDataForReports } from './getMatrixTableData';
import { getDefaultMatrixPreset, insurancePreset } from '../../../types/settings/SystemMatrixPresets';
import ScenarioOption from '../../../types/api/options/ScenarioOption';
import { getPrecisionForCommodityCodeAndOptions } from '../../../utils/priceUtils';
import { AgentInformation } from '../../../types/api/agentInformation';
import { AgencyInformation } from '../../../types/api/agencyInformation';
import Matrix from '../../../types/api/Matrix';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import MatrixPreset from '../../../types/api/MatrixPreset';
import { MatrixOffsetType } from '../../../types/api/enums/matrixOffsetType/MatrixOffsetType.enum';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';

export const getQuoteSummaryMatrices = async (
  analysisGenerationByScenarioId: Map<ScenarioId, ReportAnalysisGenerationType[]>,
  rowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>,
  inputCostScenarioPiecesByScenario: Map<ScenarioId, InputCostScenarioPiece[]>,
  forwardSoldScenarioPiecesByScenario: Map<ScenarioId, ForwardSoldScenarioPiece[]>,
  harvestRevenueScenarioPiecesByScenario: Map<ScenarioId, HarvestRevenueScenarioPiece[]>,
  scenarioOptionsByScenarioId: Map<ScenarioId, ScenarioOption[]>,
  matricesByScenarioId: Map<ScenarioId, Matrix>,
  selectedMatrixPresetsByMatrixId: Map<MatrixId, MatrixPresetId>,
  matrixPresets: MatrixPreset[],
  scenarios: RowCropScenario[],
  quotes: Quote[],
  clientFile: ClientFile,
  scenarioRequests: ScenarioRequestDTO[],
  calcsByScenarioPiece: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  uniqueScenarioPieceTypesForScenarios: Set<IEnumAttributes<ScenarioPieceType>>,
  approvedYieldByScenario: Map<ScenarioId, Nullable<number>>,
  adjustedYieldByScenario: Map<ScenarioId, Nullable<number>>,
  cellTextColorSettings: CellTextColorSettings | undefined,
  reportDocumentType: SigReportDocumentType,
  includeGenerationDate: boolean,
  agentInformation: Nullable<AgentInformation>,
  agencyInformation: Nullable<AgencyInformation>,
): Promise<MatrixReportData[]> => {
  const defaultMatrixPreset = getDefaultMatrixPreset();
  const allMatrixReportData: MatrixReportData[] = [];
  for (const [scenarioId, electedAnalysisReportsToGenerate] of analysisGenerationByScenarioId) {
    //We need at least one selection and one matrix type selected
    if (electedAnalysisReportsToGenerate.length === 0 ||
      electedAnalysisReportsToGenerate.every(r => r === ReportAnalysisGenerationType.PremiumBreakdown)) continue;

    const scenario = scenarios.find(scenario => scenario.scenarioId === scenarioId);
    if (scenario === undefined) continue;

    const quote = quotes.find(quote => quote.quoteId === scenario.quoteId);
    if (quote === undefined) continue;

    const scenarioRequest = scenarioRequests.find(scenario => scenario.id === scenarioId);
    if (scenarioRequest === undefined) continue;

    const approvedYield = approvedYieldByScenario.get(scenario.scenarioId) ?? null;
    const adjustedYield = adjustedYieldByScenario.get(scenario.scenarioId) ?? 0;

    const scenarioOptionsForScenario = getItemsForId(scenarioOptionsByScenarioId, scenario.scenarioId);
    const optionsForScenario = scenarioOptionsForScenario.map(so => so.option);

    const projectedPricePrecision = getPrecisionForCommodityCodeAndOptions(quote.commodityCode, optionsForScenario);

    const matrixDefaults =
      createMatrixDefaults(scenario, quote, approvedYield, adjustedYield, matrixReportDataColumnCount, matrixReportDataRowCount);

    const existingMatrix = matricesByScenarioId.get(scenario.scenarioId);
    const scenarioHasExistingMatrix = isNotNullOrUndefined(existingMatrix) && existingMatrix.isActive;

    for (const matrixTypeToGenerate of electedAnalysisReportsToGenerate.filter(report => report !== ReportAnalysisGenerationType.PremiumBreakdown)) {
      if (matrixTypeToGenerate === ReportAnalysisGenerationType.UseExistingMatrix) {
        //Use Existing Matrix Logic
        if (!scenarioHasExistingMatrix) continue;
        const existingMatrixPresetId = selectedMatrixPresetsByMatrixId.get(existingMatrix.matrixId) ?? defaultMatrixPreset.matrixPresetId;
        const existingMatrixPreset = matrixPresets.find(mp => mp.matrixPresetId === existingMatrixPresetId) ?? defaultMatrixPreset;
        const sweetSpots = existingMatrix.sweetSpots.filter(ss => !ss.isHidden && ss.cellRange !== null && !ss.isForMatrixSummary);
        const includedScenarioIds = existingMatrix.scenarioMatrices.map(sm => sm.scenarioId);
        const includedScenarios = scenarios.filter(s => includedScenarioIds.includes(s.scenarioId));
        const matrixData = await buildMatrixCellDataForReports(
          true,
          existingMatrixPreset,
          includedScenarios,
          scenarioRequests.filter(sr => includedScenarioIds.includes(toPrimaryKey<ScenarioId>(sr.id))),
          uniqueScenarioPieceTypesForScenarios,
          existingMatrix.midYield,
          existingMatrix.yieldScale,
          existingMatrix.midPrice,
          existingMatrix.priceScale,
          existingMatrix.bottomAxisOffsetType,
          existingMatrix.bottomAxisPercentChange,
          existingMatrix.bottomAxisIntegerOffset,
          existingMatrix.includeFilter,
          existingMatrix.showFilter,
          cellTextColorSettings,
          sweetSpots,
        );

        const matrixReportData = getMatrixReportData(
          clientFile.year,
          includedScenarios,
          scenario.scenarioId,
          reportDocumentType,
          includeGenerationDate,
          existingMatrixPreset,
          existingMatrix.showFilter,
          existingMatrix.includeFilter,
          sweetSpots,
          getItemsForId(rowCropScenarioPiecesByScenario, scenario.scenarioId),
          getItemsForId(inputCostScenarioPiecesByScenario, scenario.scenarioId)[0], // There should ever only be one per scenario
          getItemsForId(forwardSoldScenarioPiecesByScenario, scenario.scenarioId)[0], // There should ever only be one per scenario
          getItemsForId(harvestRevenueScenarioPiecesByScenario, scenario.scenarioId)[0], // There should ever only be one per scenario
          calcsByScenarioPiece,
          scenario.expectedCountyYield,
          approvedYield,
          adjustedYield,
          matrixData,
          projectedPricePrecision,
          agentInformation,
          agencyInformation,
        );
        allMatrixReportData.push(matrixReportData);
        continue;
      }

      //Autogenerated Matrix Logic
      const matrixIncludeType = matrixTypeToGenerate === ReportAnalysisGenerationType.NetMatrix ? MatrixIncludeFilterType.Net : MatrixIncludeFilterType.Gross;
      const matrixData = await buildMatrixCellDataForReports(
        false,
        insurancePreset,
        [scenario],
        [scenarioRequest],
        uniqueScenarioPieceTypesForScenarios,
        matrixDefaults.topYield,
        matrixDefaults.yieldScale,
        matrixDefaults.midPrice,
        matrixDefaults.priceScale,
        MatrixOffsetType.Unset,
        0,
        0,
        matrixIncludeType,
        MatrixShowFilterType.PerAcre,
        cellTextColorSettings,
        [],
      );

      const matrixReportData = getMatrixReportData(
        clientFile.year,
        [scenario],
        scenario.scenarioId,
        reportDocumentType,
        includeGenerationDate,
        insurancePreset,
        MatrixShowFilterType.PerAcre,
        matrixIncludeType,
        [],
        getItemsForId(rowCropScenarioPiecesByScenario, scenario.scenarioId),
        getItemsForId(inputCostScenarioPiecesByScenario, scenario.scenarioId)[0], // There should ever only be one per scenario
        getItemsForId(forwardSoldScenarioPiecesByScenario, scenario.scenarioId)[0], // There should ever only be one per scenario
        getItemsForId(harvestRevenueScenarioPiecesByScenario, scenario.scenarioId)[0], // There should ever only be one per scenario
        calcsByScenarioPiece,
        scenario.expectedCountyYield,
        approvedYield,
        adjustedYield,
        matrixData,
        projectedPricePrecision,
        agentInformation,
        agencyInformation,
      );
      allMatrixReportData.push(matrixReportData);
    }

  }
  return allMatrixReportData;
};