import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../types/util/Nullable';
import VALIDATION_RULES from '../../constants/formValidationRules';
import NumberTextField, { onNumericInputChange } from '../numberTextField/numberTextField.component';
import { SxProps, Theme } from '@mui/material';
import { isNullOrUndefined } from '../../utils/nullHandling';

interface LiabilityAmountProps {
  liabilityAmount: Nullable<number>;
  max?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export type LiabilityAmountFields = {
  liabilityAmount: number;
};

export const LiabilityAmountInput = ({ liabilityAmount, sx, autoFocus = false, disabled = false, max = 100 }: LiabilityAmountProps) => {
  const { control } = useFormContext();
  const isDisabled: boolean = useWatch({ name: 'rowCropScenarioPieceExtendedData.isPercentOfProjected', control: control, defaultValue: disabled }) ?? false;
  const required = !isDisabled;

  return (
    <Controller
      name="rowCropScenarioPieceExtendedData.liabilityAmount"
      control={control}
      defaultValue={liabilityAmount ?? 1}
      rules={{
        required: required ? VALIDATION_RULES.required() : false,
        min: required ? VALIDATION_RULES.minimum(1) : undefined,
        max: required ? VALIDATION_RULES.maximum(max) : undefined,
        maxLength: required ? VALIDATION_RULES.maxLength(8) : undefined,
        validate: (value: number) => (isDisabled || (!Number.isNaN(value) && !isNullOrUndefined(value))) || required,
      }}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          type="number"
          label="Limit of Liability Amount"
          fullWidth
          value={value}
          inputProps={{ step: 0.1, min: 1, max: max, maxLength: 8 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          sx={sx}
          disabled={isDisabled}
          size="small"
        />
      )}
    />
  );
};
