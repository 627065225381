import { EditableCallbackParams, RowPinnedType } from 'ag-grid-community';
import { acreageChangeNotAllowedYD, productionChangeNotAllowYD, yieldChangeNotAllowedYD } from './aph';

export const isSummaryRow = (rowPinned: RowPinnedType) => {
  return rowPinned === 'bottom';
};

export const isAphYieldEditable = (params: EditableCallbackParams): boolean => {
  const { yieldType } = params.data;
  return !yieldChangeNotAllowedYD.some(y => y === yieldType) && !isSummaryRow(params.node.rowPinned);
};

export const isProductionEditable = (params: EditableCallbackParams): boolean => {
  const { yieldType } = params.data;
  return !productionChangeNotAllowYD.some(y => y === yieldType) && !isSummaryRow(params.node.rowPinned);
};

export const isAcreageEditable = (params: EditableCallbackParams): boolean => {
  const { yieldType } = params.data;
  return !acreageChangeNotAllowedYD.some(y => y === yieldType) && !isSummaryRow(params.node.rowPinned);
};