import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferHarvestPrice } from '../../../app/admSlice';
import { Nullable } from '../../../types/util/Nullable';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import HarvestPriceInput, { HarvestPriceProps } from '../scenario/harvestPriceInput.component';
import { LoadFromStateProps } from '../scenario/loadFromStateProps';
import { useCommodityOptionBasedPrecisionForScenario } from '../../../hooks/useCommodityOptionBasedPrecision';
import { getRoundedValue } from '../../numberTextField/numberTextField.component';
import useLinkedScenarioFields from '../../../hooks/useLinkedScenarioFields';
import { useOptionsForSpForm } from '../../../hooks/useOptionsForSpForm';

interface ScenarioPieceHarvestPriceProps extends HarvestPriceProps, LoadFromStateProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>
}

const ScenarioPieceHarvestPriceInput = ({ quote, scenario, scenarioPiece, harvestPrice, autoFocus = false, sx, projectedPriceField, label, disabled, loadFromState = false }: ScenarioPieceHarvestPriceProps) => {
  const { setValue } = useFormContext();
  const harvPrice = useAppSelector(selectOfferHarvestPrice);
  const { scenarioOptionsForSpForm } = useOptionsForSpForm();
  const { precision } = useCommodityOptionBasedPrecisionForScenario(quote.commodityCode, scenarioOptionsForSpForm);

  const { getInputStyle } = useLinkedScenarioFields(scenario?.scenarioId ?? null);

  useEffect(() => {
    // This updates the price if the scenario piece does not exist yet (the scenario piece will be new if saved).
    // This should not be necessary in most cases. It handles cases where the insurance plan associated with the
    // scenario piece has a different price than the price used by default on the scenario.
    // Note: This also requires something (like the scenario piece form) to refetch the prices and yields based on the potentially new insurance plan code
    if (scenarioPiece !== null || harvPrice === null || !loadFromState) return;

    setValue('harvestPrice', getRoundedValue(harvPrice, precision));
  }, [scenarioPiece, harvPrice, loadFromState, precision]);

  return (
    <HarvestPriceInput
      quote={quote}
      scenario={scenario}
      scenarioOptionState={scenarioOptionsForSpForm}
      harvestPrice={harvestPrice}
      autoFocus={autoFocus}
      sx={{
        ...sx,
        ...getInputStyle('harvestPrice'),
      }}
      projectedPriceField={projectedPriceField}
      label={label}
      disabled={disabled}
    />
  );
};

export default ScenarioPieceHarvestPriceInput;
