import { AdjustmentType, AdjustmentTypeAttributes } from '@silveus/calculations';
import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { MenuItem, TextField, styled } from '@mui/material';
import HarvestRevenueTransactionPropsBase from './harvestRevenueTransactionPropsBase';

const StyledTextField = styled(TextField)({
  padding: '0px',
  width: '70px',
  '& div': {
    fontSize: '12px !important',
    paddingLeft: '4px',
  },
});

const HarvestRevenueTransactionPriceType = ({ index, disabled, onTransactionValueChanged }: HarvestRevenueTransactionPropsBase) => {
  const { control } = useFormContext();

  const databaseAvailableAdjustmentType = [
    AdjustmentType.Fixed,
    AdjustmentType.Percent,
    AdjustmentType.PlusMinus,
  ];

  const availableAdjustmentTypes = databaseAvailableAdjustmentType.map(x => AdjustmentTypeAttributes[x]);

  return (
    <Controller
      name={`harvestRevenueTransactions.${index}.priceAdjustmentTypeId`}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.validateMultipleRules(
          [
            VALIDATION_RULES.restrictNotOnListValue(availableAdjustmentTypes.map(x => x.value)),
          ],
        ),
      }}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <StyledTextField
          {...field}
          type="text"
          disabled={disabled}
          select
          value={value}
          onChange={e => {
            field.onChange(e.target.value);
            onTransactionValueChanged();
          }}
          error={!!error}
          helperText={error?.message}
          size="small"
        >
          {availableAdjustmentTypes.map(us => <MenuItem key={us.value} value={us.value}>{us.description}</MenuItem>)}
        </StyledTextField>
      )}
    />
  );
};

export default HarvestRevenueTransactionPriceType;