import { OptionCode, ScenarioPieceResponseDTO, ScenarioPieceType } from '@silveus/calculations';
import { PlanBaseType, QuoteSummaryScenarioPiece } from '../../../../../types/api/reports/QuoteSummaryScenarioPiece';
import { RowCropScenarioPiece } from '../../../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../../../types/util/Nullable';
import { RowCropScenario } from '../../../../../types/api/RowCropScenario';
import { getBaseReportScenarioPiece } from '../../getBaseReportScenarioPiece';

export const getHipQuoteSummaryData = (
  scenario: RowCropScenario,
  scenarioPiece: RowCropScenarioPiece,
  scenarioPieceCalcData: Nullable<ScenarioPieceResponseDTO>,
  scenarioOptions: OptionCode[],
  projectedPrice: number,
  isProjectedPriceSet: boolean,
  approvedYield: Nullable<number>,
  adjustedYield: Nullable<number>): QuoteSummaryScenarioPiece[] => {

  const baseReportScenarioPiece = getBaseReportScenarioPiece(scenario, scenarioPiece, scenarioPieceCalcData, scenario.expectedCountyYield, approvedYield, adjustedYield);
  const baseHipResults = scenarioPieceCalcData?.baseResult;
  const hipQuoteSummaryScenarioPieces: QuoteSummaryScenarioPiece[] = [];

  const hipQuoteSummaryScenarioPiece: QuoteSummaryScenarioPiece = {
    ...baseReportScenarioPiece,
    hailEndorsements: [],
    hailRate: null,
    isProjectedPriceSet: false,
    options: [],
    planBaseType: PlanBaseType.Basic,
    projectedPrice: null,
    protectionFactor: scenarioPiece.protectionFactor / 100,
    taYield: null,
    unitStructure: '',
    yield: null,
    lowerCoverageLevel: `${scenarioPiece.lowerCoverageLevel}%`,

    calculationData: {
      ...baseReportScenarioPiece.calculationData,
      triggerYield: null,
      producerPremium: baseHipResults?.producerPremium ?? 0,
      producerPremiumPerAcre: baseHipResults?.producerPremiumPerAcre ?? 0,
      totalPremium: baseHipResults?.totalPremium ?? 0,
      totalPremiumPerAcre: baseHipResults?.totalPremiumPerAcre ?? 0,
    },
  };
  hipQuoteSummaryScenarioPieces.push(hipQuoteSummaryScenarioPiece);

  // For HIP with TS, we add a second line with the TS info broken out
  if (scenarioPiece.scenarioPieceType === ScenarioPieceType.Hip && scenarioOptions.includes(OptionCode.TS)) {
    const tropicalStormResults = scenarioPieceCalcData?.additionalCoverageResults.find(acr => acr.id === OptionCode.TS) ?? null;

    const getHipTsQuoteSummaryScenarioPiece: QuoteSummaryScenarioPiece = {
      ...baseReportScenarioPiece,
      hailEndorsements: [],
      hailRate: null,
      isProjectedPriceSet: false,
      options: [],
      planBaseType: PlanBaseType.Basic,
      projectedPrice: null,
      protectionFactor: null,
      taYield: null,
      unitStructure: '',
      yield: null,
      displayPlanName: 'HIP Tropical Storm',
      upperCoverageLevel: '',

      calculationData: {
        ...baseReportScenarioPiece.calculationData,
        triggerYield: null,
        liabilityAmount: null,
        liabilityPerAcre: null,
        premiumLiability: null,
        premiumLiabilityPerAcre: null,
        producerPremium: tropicalStormResults?.producerPremium ?? 0,
        producerPremiumPerAcre: tropicalStormResults?.producerPremiumPerAcre ?? 0,
        totalPremium: tropicalStormResults?.totalPremium ?? 0,
        totalPremiumPerAcre: tropicalStormResults?.totalPremiumPerAcre ?? 0,
      },
    };

    hipQuoteSummaryScenarioPieces.push(getHipTsQuoteSummaryScenarioPiece);
  }

  return hipQuoteSummaryScenarioPieces;
};
