import { Box, Chip } from '@mui/material';
import {
  ScenarioPieceCardChipContent
} from '../../../constants/productDefinitions/defaultDefinitions/scenarioPieceCardChipDefaults';

interface BaseScenarioPieceCardChipProps {
  chips: ScenarioPieceCardChipContent[];
  scenarioColor: string | undefined;
}

const BaseScenarioPieceCardChips = ({ chips, scenarioColor }: BaseScenarioPieceCardChipProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 0.5, // This replaces the Stack spacing
      }}
    >
      {chips.map((chip, index) => (
        <Chip
          key={index}
          label={chip}
          size="small"
          sx={{
            backgroundColor: scenarioColor,
            color: theme => theme.palette.common.white,
            '&:hover': {
              backgroundColor: scenarioColor,
            },
          }}
        />
      ))}
    </Box>
  );
};

export default BaseScenarioPieceCardChips;