import {
  Commodity,
  UnitOfMeasure,
  UnitStructureCode,
  CoverageTypeCode,
  ScoPremiumParams,
  ScoIndemnityParams,
  ScoRequestDTO,
  ScoUnit, OptionCode, Unit
} from '@silveus/calculations';
import { RootState } from '../../../app/store';
import BaseUnit from '../../../types/api/BaseUnit';
import ScoCalculationParams from '../../../types/api/calculationData/scoCalculationParams';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getCalcParamRequestUnits, getMatchingCalcParamDataForUnit, getUnitsForScenarioPiece } from '../calculationUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import { getScoCalcDataRequest } from '../../requestInterception/scenarioPieces/scoRequestInterceptor';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import { ScoCalculationParamsRequest } from '../../../types/api/calculationData/scoCalculationParamsRequest';
import { Nullable } from '../../../types/util/Nullable';

export const createScoData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  //Get the calc data back from the API
  const calcParamRequest = createCalcParamRequest(state, scenarioPiece, scenario, baseUnits);
  const returnedData = await getScoCalcDataRequest(calcParamRequest);

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createScoPlanDto(scenarioPiece, returnedData, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformScoData = (calcData: Nullable<ScoCalculationParams>, unit: Nullable<BaseUnit>, scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece): ScoPremiumParams & ScoIndemnityParams => {
  const scoParams: ScoPremiumParams & ScoIndemnityParams = {
    commodityCode: (unit?.commodityCode ?? '') as Commodity,
    conservComplSubsidyReductionPercent: calcData?.conservComplSubsidyReductionPercent ?? 0,
    contractPrice: scenario.projectedPrice ?? 0,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
    isBeginningOrVeteranFarmerOrRancher: false,
    isConservationCompliance: false,
    isNativeSod: false,
    multipleCommodityAdjustmentFactor: calcData?.multipleCommodityAdjustmentFactor ?? 0,
    unitOfMeasure: (calcData?.unitOfMeasure ?? '') as UnitOfMeasure,
    priceElectionPercent: scenarioPiece.protectionFactor / 100,
    projectedPrice: scenario.projectedPrice ?? 0,
    subsidyPercent: calcData?.subsidyPercent ?? 0,
    liabilityAdjustmentFactor: calcData?.multipleCommodityAdjustmentFactor ?? 0,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,
    totalPremiumMultiplicativeOptionalRateAdjustmentFactor: calcData?.totalPremiumMultiplicativeOptionalRateAdjustmentFactor ?? 0,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    unitStructureCode: scenarioPiece.unitStructure,
    coverageTypeCode: CoverageTypeCode.A,
  };

  return scoParams;
};

const createScoUnits = (scenarioPiece: RowCropScenarioPiece, scoCalcData: ScoCalculationParams[], units: BaseUnit[], scenario: RowCropScenario): (ScoUnit & Unit)[] => {
  return units.map(unit => {

    const matchingData = getMatchingCalcParamDataForUnit(unit, scoCalcData);

    //TODO: Error Handling
    if (isNullOrUndefined(matchingData)) throw new Error('SCO Data Transform State mismatch: Unable to find data returned from API associated with unit in state.');
    const unitDto = createBaseUnit(unit);
    const ecoUnit: ScoUnit & Unit = {
      ...unitDto,
      id: unit.id,
      baseRate: matchingData.baseRate,
      //TODO #61367: This is a temporary workaround until we get a more permanent solution for handling options per scenario piece
      options: unit.options.filter(optionCode => optionCode === OptionCode.SE),
    };

    return ecoUnit;
  });
};

const createScoPlanDto = (scenarioPiece: RowCropScenarioPiece, apiData: ScoCalculationParams[], unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): ScoRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createScoUnits(
        scenarioPiece,
        apiData,
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformScoData(apiData.at(0) ?? null, unitData.at(0) ?? null, scenario, scenarioPiece),
  };
};

const createCalcParamRequest = (state: RootState, scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, baseUnits: BaseUnit[]): ScoCalculationParamsRequest => {
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new Error(`Quote id ${scenario.quoteId} not found`);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new Error(`Client file id ${quote.clientFileId} not found`);

  return {
    year: clientFile.year,
    coverageTypeCode: (scenarioPiece.rowCropScenarioPieceExtendedData?.isCat ?? false) ? CoverageTypeCode[CoverageTypeCode.C] : CoverageTypeCode[CoverageTypeCode.A],
    unitStructureCode: UnitStructureCode[scenarioPiece.unitStructure],
    planCode: scenarioPiece.planCode,
    lowerCoverageLevel: scenarioPiece.lowerCoverageLevel,
    volatility: scenario.volatility,
    commodityCode: quote.commodityCode,
    countyId: quote.countyId,
    distinctUnits: getCalcParamRequestUnits(baseUnits),
  };
};
