import { useFormContext, useWatch } from 'react-hook-form';
import { selectOfferLowerProtectionFactor, selectOfferUpperProtectionFactor } from '../../../app/admSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { isNullOrUndefined, isNotNullOrUndefined } from '../../../utils/nullHandling';
import { ProtectionFactorInput, ProtectionFactorProps } from './protectionFactorInput.component';
import { roundToPlaces } from '@silveus/calculations';
import { FormInputParameterNames } from '../constants/inputConstants';

export const AdmProtectionFactorInput = ({
  protectionFactor,
  fieldName = FormInputParameterNames.ProtectionFactor,
  defaultValue = 100,
  autoFocus = false,
  disabled = false }: ProtectionFactorProps) => {

  const [validCoverages, setValidCoverages] = useState<boolean>(false);

  const { control, getValues, setValue } = useFormContext();

  const upperCoverageLevelRaw = getValues(FormInputParameterNames.UpperCoverageLevelName);
  const lowerCoverageLevelRaw = getValues(FormInputParameterNames.LowerCoverageLevelName);
  const upperCoverageLevel = (upperCoverageLevelRaw ?? 0) / 100;
  const lowerCoverageLevel = (lowerCoverageLevelRaw ?? 0) / 100;
  const upperCoverageLevelWatch = useWatch({ name: FormInputParameterNames.UpperCoverageLevelName, control: control, defaultValue: upperCoverageLevelRaw });
  const lowerCoverageLevelWatch = useWatch({ name: FormInputParameterNames.LowerCoverageLevelName, control: control, defaultValue: lowerCoverageLevelRaw });

  const upperProtectionFactorRaw = useAppSelector(state => selectOfferUpperProtectionFactor(state, upperCoverageLevel, lowerCoverageLevel));
  const lowerProtectionFactorRaw = useAppSelector(state => selectOfferLowerProtectionFactor(state, upperCoverageLevel, lowerCoverageLevel));

  const upperProtectionFactor = roundToPlaces((upperProtectionFactorRaw ?? 1.5) * 100, 0);
  const lowerProtectionFactor = roundToPlaces((lowerProtectionFactorRaw ?? 0) * 100, 0);

  useEffect(() => {
    const validProtectionRanges: boolean =
      (isNotNullOrUndefined(upperProtectionFactorRaw))
      && (isNotNullOrUndefined(lowerProtectionFactorRaw))
      && (upperProtectionFactorRaw >= 0)
      && (lowerProtectionFactorRaw >= 0);
    setValidCoverages(validProtectionRanges);
    if (isNullOrUndefined(upperProtectionFactor) || isNullOrUndefined(lowerProtectionFactor)) return;

    const currentProtectionFactor = getValues(FormInputParameterNames.ProtectionFactor);

    if (currentProtectionFactor > upperProtectionFactor) {
      setValue(FormInputParameterNames.ProtectionFactor, upperProtectionFactor);
    } else if (currentProtectionFactor < lowerProtectionFactor) {
      setValue(FormInputParameterNames.ProtectionFactor, lowerProtectionFactor);
    }
  }, [upperCoverageLevelWatch, lowerCoverageLevelWatch, upperProtectionFactorRaw, lowerProtectionFactorRaw]);

  return (
    <ProtectionFactorInput
      fieldName={fieldName}
      protectionFactor={protectionFactor}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      disabled={disabled || !validCoverages}
      upperProtectionFactor={upperProtectionFactor}
      lowerProtectionFactor={lowerProtectionFactor} />
  );
};
