import { AppBar, styled } from '@mui/material';

const SlidingAppBar = styled(
  AppBar,
)(({ theme }) => ({
  overflow: 'hidden',
  elevation: 0,
  position: 'fixed',
  width: '100%',
  zIndex: theme.zIndex.drawer + 1,
}));

export default SlidingAppBar;