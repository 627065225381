import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { ForwardSoldScenarioPieceId, ScenarioId, ScenarioPieceId } from '../../../types/api/PrimaryKeys';
import { Quote } from '../../../types/api/Quote';
import { Nullable } from '../../../types/util/Nullable';
import { ClientFile } from '../../../types/api/ClientFile';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { selectScenarioById } from '../../../app/scenariosSlice';
import { FormProvider, SubmitHandler, useFieldArray } from 'react-hook-form';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { selectAdjustedYieldForScenario, selectApprovedYieldForScenario } from '../../../app/unitsSlice';
import MarketingYieldInput, { MarketingYieldFields } from '../../../components/formInputs/scenarioPiece/forwardSold/marketingYieldInput.component';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import ForwardSoldScenarioPiece, { ForwardSoldTransaction } from '../../../types/api/ForwardSoldScenarioPiece';
import { AdjustmentType, AdjustmentTypeAttributes, ScenarioPieceType } from '@silveus/calculations';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import { addForwardSoldScenarioPiece, modifyForwardSoldScenarioPiece, removeForwardSoldScenarioPieceAndRecalculate } from '../../../app/forwardSoldScenarioPiecesSlice';
import { calculateForwardSold } from '../../../app/calculationResultsSlice';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { ProductionAdjustmentTypeSelectorInput } from '../../../components/formInputs/scenarioPiece/forwardSold/productionAdjustmentTypeInput.component';
import { PriceAdjustmentTypeSelectorInput } from '../../../components/formInputs/scenarioPiece/forwardSold/priceAdjustmentTypeInput.component';
import ProductionInput from '../../../components/formInputs/scenarioPiece/forwardSold/productionInput.component';
import ForwardSoldPriceInput from '../../../components/formInputs/scenarioPiece/forwardSold/forwardSoldPriceInput.component';
import { useEffect, useState } from 'react';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import { formatCurrency } from '../../../utils/formatNumbers';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import ForwardSoldOutputSummary from './forwardSoldOutputSummary.component';
import { FixedResults } from '@silveus/calculations/dist/shared/results/fixedResults';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { useScenarioPieceForm } from '../useScenarioPieceForm';

interface ForwardSoldScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<ForwardSoldScenarioPiece>;
  scenarioId: ScenarioId;
  year: number;
  countyId: string;
  commodityCode: string;
  typeId: string;
  practiceId: string;
  disabled: boolean;
  quote: Quote;
  clientFile: ClientFile;
  handleCancel: () => void;
}

export type ForwardSoldScenarioPieceFormFields = ScenarioPieceFormFields & MarketingYieldFields
  &
{ transactions: ForwardSoldTransaction[] };

const ForwardSoldScenarioPieceForm = ({ scenarioPiece, scenarioId, registerHeader, handleCancel, quote, clientFile, handleValidation, isCanceling = false }: ForwardSoldScenarioPieceFormProps) => {
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  if (scenario === null) {
    throw new Error('Attempting to calculate for scenario piece when the parent scenario does not exist in state');
  }

  const formId = 'forwardSoldScenarioPieceForm';
  const dispatch = useAppDispatch();
  const aphYield = useAppSelector(state => selectAdjustedYieldForScenario(state, scenarioId, quote.countyId, quote.commodityCode));
  const approvedYield = useAppSelector(state => selectApprovedYieldForScenario(state, scenarioId, quote.countyId, quote.commodityCode));
  const scenarioDefaultMarketingYield = (approvedYield !== null ? approvedYield : aphYield) ?? 0;
  const methods = useScenarioPieceForm<ForwardSoldScenarioPieceFormFields>(scenarioPiece, {
    transactions: scenarioPiece?.forwardSoldTransactions ?? [],
  });
  const { fields, append, remove } = useFieldArray({ control: methods.control, name: 'transactions' });
  const [forwardSoldScenarioPieceId] = useState<ForwardSoldScenarioPieceId>(scenarioPiece?.forwardSoldScenarioPieceId ?? generatePrimaryKey<ForwardSoldScenarioPieceId>());
  const [scenarioPieceId] = useState<ScenarioPieceId>(scenarioPiece?.scenarioPieceId ?? generatePrimaryKey<ScenarioPieceId>());

  const handleAddForwardSoldTransaction = (): void => {
    const newTransaction: ForwardSoldTransaction = {
      forwardSoldTransactionId: generatePrimaryKey(),
      productionAdjustmentTypeId: AdjustmentType.Fixed,
      production: 0,
      priceAdjustmentTypeId: AdjustmentType.Fixed,
      price: 0,
    };
    append(newTransaction);
    handleTransactionValueChanged();
  };

  const handleDeleteForwardSoldTransaction = (index: number) => {
    remove(index);
    handleTransactionValueChanged();
  };

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;
    await dispatch(removeForwardSoldScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  const onSubmit: SubmitHandler<ForwardSoldScenarioPieceFormFields> = async data => {
    const newScenarioPiece: ForwardSoldScenarioPiece = {
      forwardSoldScenarioPieceId: forwardSoldScenarioPieceId,
      scenarioId: scenarioId,
      scenarioPieceType: ScenarioPieceType.ForwardSold,
      forwardSoldTransactions: data.transactions,
      marketingYield: data.marketingYield,
      scenarioPieceId: scenarioPieceId,
      isActive: data.isActive,
      isInvalid: false,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(addForwardSoldScenarioPiece({ scenarioPiece: newScenarioPiece }));
    } else {
      //Existing scenario piece
      await dispatch(modifyForwardSoldScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;
  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const availableAdjustmentTypes = Object.values(AdjustmentTypeAttributes).filter(adjType => adjType.value !== AdjustmentType.Remaining);

  const [calcResults, setCalcResults] = useState<Nullable<FixedResults>>(null);

  useEffect(() => {
    handleTransactionValueChanged();
  }, []);


  const handleTransactionValueChanged = async () => {
    const values = methods.getValues();

    const simulatedTransactions = [...values.transactions];

    const forwardSoldScenarioPiece: ForwardSoldScenarioPiece = {
      forwardSoldScenarioPieceId: forwardSoldScenarioPieceId,
      forwardSoldTransactions: simulatedTransactions,
      isActive: true,
      isInvalid: false,
      marketingYield: values.marketingYield,
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPieceId,
      scenarioPieceType: ScenarioPieceType.ForwardSold,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    const response = await dispatch(calculateForwardSold({ scenarioId, forwardSoldScenarioPiece: forwardSoldScenarioPiece }));

    if (calculateForwardSold.fulfilled.match(response)) {
      const calculatedScenarioResult = response.payload;
      const calculatedResult = calculatedScenarioResult.scenarioPieceGroups.flatMap(spg => spg.scenarioPieces).find(sp => sp.id === scenarioPieceId);

      if (calculatedResult !== undefined) {
        setCalcResults(calculatedResult);
      }
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid container item spacing={2} justifyContent="end" alignItems="center" >
              <Grid item xs={4}>
                <MarketingYieldInput marketingYield={scenarioPiece?.marketingYield ?? scenarioDefaultMarketingYield} onValueChange={handleTransactionValueChanged} />
              </Grid>
              <Grid item>
                <Button id="btn-add-fwd-sold" variant="contained" onClick={handleAddForwardSoldTransaction}>
                  ADD FORWARD SOLD
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Table size="small" sx={{ tableLayout: 'fixed', width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '36%', borderRight: theme => `1px solid ${theme.palette.background.paper}` }}>Production
                      <Tooltip title={<div>
                        Fixed: A fixed production manually entered.<br /> <br />
                        %: A % of the marketing yield multiplied by net acres. Enter as a whole number, IE 50 = 50%<br /> <br />
                        +/-: Use 100% of the marketing yield multiplied by net acres and then add or subtract the amount entered.
                      </div>}>
                        <HelpIcon style={{ marginLeft: '5px', marginBottom: '-7px' }} />
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ width: '36%', borderRight: theme => `1px solid ${theme.palette.background.paper}` }}>Price
                      <Tooltip title={<div>
                        Fixed: A fixed price manually entered.<br /> <br />
                        %: A % of the projected price. Enter as a whole number, IE 50 = 50%<br /> <br />
                        +/-: Use the projected price and then add or subtract the amount entered.
                      </div>}>
                        <HelpIcon style={{ marginLeft: '5px', marginBottom: '-7px' }} />
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ width: '21%', borderRight: theme => `1px solid ${theme.palette.background.paper}` }}>Revenue</TableCell>
                    <TableCell sx={{ width: '7%' }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((transactionItem, index) => {
                    return (
                      <TableRow key={transactionItem.id}>
                        <TableCell sx={{ border: theme => `1px solid ${theme.palette.divider}`, padding: '1px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <div style={{ width: '55%' }}>
                              <ProductionAdjustmentTypeSelectorInput
                                index={index}
                                availableAdjustmentTypes={availableAdjustmentTypes}
                                onValueChange={handleTransactionValueChanged}
                              />
                            </div>
                            <div style={{ width: '45%', marginLeft: '2px' }}>
                              <ProductionInput
                                index={index}
                                disabled={false}
                                onValueChange={handleTransactionValueChanged}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell sx={{ border: theme => `1px solid ${theme.palette.divider}`, padding: '1px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <div style={{ width: '55%' }}>
                              <PriceAdjustmentTypeSelectorInput
                                index={index}
                                availableAdjustmentTypes={availableAdjustmentTypes}
                                onValueChange={handleTransactionValueChanged} />
                            </div>
                            <div style={{ width: '45%', marginLeft: '2px' }}>
                              <ForwardSoldPriceInput
                                index={index}
                                disabled={false}
                                onValueChange={handleTransactionValueChanged} />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell sx={{ border: theme => `1px solid ${theme.palette.divider}`, textAlign: 'right', padding: '5px' }}>
                          <Typography color="gray" fontSize="12px" >
                            {formatCurrency((calcResults?.adjustmentRevenuesFixed ?? []).at(index) ?? 0, false)}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: theme => `1px solid ${theme.palette.divider}`, padding: '0px' }}>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <IconButton onClick={() => handleDeleteForwardSoldTransaction(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            <ForwardSoldOutputSummary
              totalYieldSold={calcResults?.totalYieldSoldFixed ?? 0}
              percentBushelsSold={calcResults?.percentBushelsSoldFixed ?? 0}
              averagePrice={calcResults?.averagePriceFixed ?? 0} />
            <Grid item xs={12}>
              <IsActiveInput isActive={scenarioPiece?.isActive ?? null} disabled={false} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
    </>
  );
};

export default ForwardSoldScenarioPieceForm;
