import { getReconciledScenarioOptions } from './getReconciledScenarioOptions';
import {
  ClientFileId,
  InsuredId, MatrixId,
  QuoteId,
  ScenarioId,
  ScenarioOptionId,
  ScenarioPieceId,
  UnitYearId
} from '../../types/api/PrimaryKeys';
import UnitYear from '../../types/api/UnitYear';
import { Quote } from '../../types/api/Quote';
import { ClientFile } from '../../types/api/ClientFile';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import ScenarioOption from '../../types/api/options/ScenarioOption';
import ScenarioOptionUnitYear from '../../types/api/options/ScenarioOptionUnitYear';
import { UnitGroup } from '../../types/api/UnitGroup';
import { getReconciledScenarioUnitYearAph } from './getReconciledScenarioUnitYearAph';
import UnitYearAph from '../../types/api/UnitYearAph';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import { getReconciledScenarioMatrices } from './getReconciledScenarioMatrices';
import Matrix from '../../types/api/Matrix';
import ScenarioMatrix from '../../types/api/ScenarioMatrix';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';
import { getReconciledScenarioQuickUnits } from './getReconciledScenarioQuickUnits';
import ScenarioQuickUnit from '../../types/api/ScenarioQuickUnit';

export interface RelationalEntityReconciliationParams {
  units: Map<InsuredId, UnitYear[]>
  quotes: Map<ClientFileId, Quote[]>;
  clientFiles: Map<InsuredId, ClientFile[]>;
  rowCropScenarios: Map<QuoteId, RowCropScenario[]>;
  rowCropScenarioPieces: Map<ScenarioId, RowCropScenarioPiece[]>;
  ledgerScenarioPieces: Map<ScenarioId, ScenarioPiece[]>;
  unitYearAph: Map<UnitYearId, UnitYearAph[]>;
  scenarioUnitYearAph: Map<ScenarioId, ScenarioUnitYearAph[]>;
  matrices: Map<ScenarioId, Matrix[]>;
  scenarioMatrices: Map<MatrixId, ScenarioMatrix[]>;
  scenarioOptions: Map<ScenarioId, ScenarioOption[]>;
  scenarioOptionUnitYears: Map<ScenarioOptionId, ScenarioOptionUnitYear[]>;
  scenarioOptionsFromServer: Map<ScenarioId, ScenarioOption[]>;
  unitGroupsFromServer: Map<ScenarioPieceId, UnitGroup[]>;
  quickUnits: Map<ScenarioId, ScenarioQuickUnit>;
}

/**
 * Reconciles all of the domain entities that are indirectly impacted by other changes during reconciliation
 * @param units - The unit years selected by the user, grouped by insured id
 * @param quotes - The quotes selected by the user, grouped by client file id
 * @param clientFiles - The client files selected by the user, grouped by insured id
 * @param rowCropScenarios - The row crop scenarios selected by the user, grouped by quote id
 * @param rowCropScenarioPieces - The row crop scenario pieces selected by the user, grouped by scenario id
 * @param ledgerScenarioPieces - The ledger scenario pieces selected by the user, grouped by scenario id
 * @param scenarioOptions - The scenario options on the scenarios selected by the user, grouped by scenario id
 * @param scenarioOptionUnitYears - The relationships between scenario options and unit years from the scenarios selected by the user, grouped by scenario option id
 * @param scenarioOptionsFromServer - The last known state of scenario options according to the server, grouped by scenario id
 * @param unitGroupsFromServer - The last known state of unit groups according to the server, grouped by scenario piece id
 * @param unitYearAph - The unit year APHs selected by the user, grouped by unit year id
 * @param scenarioUnitYearAph - The scenario unit year APHs selected by the user, grouped by scenario id
 * @param matrices - The matrices selected by the user, grouped by primary scenario id
 * @param scenarioMatrices - The relationships between scenarios and matrices from the matrices selected by the user, grouped by scenario id
 * @param quickUnits - The quick units for the scenarios selected by the user
 * @return reconciledRelationalEntities A composite object of all changes that need to be submitted to the server to properly align db state
 */
export const reconcileRelationalEntities = ({
  units,
  quotes,
  clientFiles,
  rowCropScenarios,
  rowCropScenarioPieces,
  ledgerScenarioPieces,
  scenarioOptions,
  scenarioOptionUnitYears,
  scenarioOptionsFromServer,
  unitGroupsFromServer,
  unitYearAph,
  scenarioUnitYearAph,
  matrices,
  scenarioMatrices,
  quickUnits,
}: RelationalEntityReconciliationParams) => {
  const reconciledScenarioQuickUnits = getReconciledScenarioQuickUnits({
    clientFilesMap: clientFiles,
    quotes,
    rowCropScenarios,
    quickUnits,
  });

  const reconciledScenarioUnitYearAph = getReconciledScenarioUnitYearAph({
    units,
    quotes,
    clientFilesMap: clientFiles,
    rowCropScenarios,
    scenarioUnitYearAph,
    unitYearAph,
  });

  const reconciledScenarioMatrices = getReconciledScenarioMatrices({
    quotes,
    clientFilesMap: clientFiles,
    rowCropScenarios,
    matrices,
    scenarioMatrices,
  });

  const reconciledScenarioOptions = getReconciledScenarioOptions({
    units,
    quotes,
    clientFilesMap: clientFiles,
    rowCropScenarios,
    rowCropScenarioPieces,
    scenarioOptions,
    scenarioOptionUnitYears,
    scenarioOptionsFromServer,
  });

  const allScenarioOptionsToRemove = reconciledScenarioOptions.flatMap(so => so.scenarioOptionsToRemove);
  const allScenarioOptionUnitYearsToKeep = reconciledScenarioOptions.flatMap(so => so.scenarioOptionUnitYearsToKeep);

  // TODO: unit group reconciliation has been commented out due to the need to have info for ALL units,
  //  not just those that changed. In addition, the reconciliation of unit groups happens any time a quote
  //  is opened, so when they go to view that quote, it will update these anyway
  // const scenarioOptionUnitYearsToKeepByScenarioOptionId = getKeyedStateArrayGroupedBy(Object.values(allScenarioOptionUnitYearsToKeep), souy => souy.scenarioOptionId);

  // const { unitGroupsToAdd, unitGroupsToDelete, unitGroupsToUpdate } = getReconciledUnitGroups({
  //   units,
  //   quotes,
  //   clientFilesMap: clientFiles,
  //   rowCropScenarios,
  //   rowCropScenarioPieces,
  //   ledgerScenarioPieces,
  //   //Any scenario options that get removed will be from the server, not from the state passed in,
  //   // so we can use the scenario options passed in.
  //   scenarioOptions: scenarioOptions,
  //   //For scenario option unit years, units could have gotten removed and had the associated links removed.
  //   // We need to use the reconciled result for these because of that.
  //   scenarioOptionUnitYears: scenarioOptionUnitYearsToKeepByScenarioOptionId,
  //   unitGroupsFromServer,
  // });

  return {
    scenarioOptionsToRemove: allScenarioOptionsToRemove,
    scenarioOptionUnitYearsToKeep: allScenarioOptionUnitYearsToKeep,
    unitGroupsToAdd: [],
    unitGroupsToDelete: [],
    unitGroupsToUpdate: [],
    scenarioUnitYearAph: reconciledScenarioUnitYearAph,
    scenarioMatrices: reconciledScenarioMatrices,
    scenarioQuickUnits: reconciledScenarioQuickUnits,
  };
};