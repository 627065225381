import { OptionCode, ScenarioPieceResponseDTO, UnitStructureCode, UnitStructureAttributes } from '@silveus/calculations';
import { PlanBaseType, QuoteSummaryScenarioPiece } from '../../../../../types/api/reports/QuoteSummaryScenarioPiece';
import { RowCropScenario } from '../../../../../types/api/RowCropScenario';
import { Nullable } from '../../../../../types/util/Nullable';
import { RowCropScenarioPiece } from '../../../../../types/api/RowCropScenarioPiece';
import { getBaseReportScenarioPiece } from '../../getBaseReportScenarioPiece';

export const getNonAreaQuoteSummaryData = (
  scenario: RowCropScenario,
  scenarioPiece: RowCropScenarioPiece,
  scenarioPieceCalcData: Nullable<ScenarioPieceResponseDTO>,
  scenarioOptions: OptionCode[],
  projectedPrice: number,
  isProjectedPriceSet: boolean,
  approvedYield: Nullable<number>,
  adjustedYield: Nullable<number>): QuoteSummaryScenarioPiece[] => {

  const baseReportScenarioPiece = getBaseReportScenarioPiece(scenario, scenarioPiece, scenarioPieceCalcData, scenario.expectedCountyYield, approvedYield, adjustedYield);
  const quoteSummaryScenarioPiece: QuoteSummaryScenarioPiece[] = [{
    ...baseReportScenarioPiece,
    hailEndorsements: [],
    hailRate: null,
    isProjectedPriceSet: isProjectedPriceSet,
    options: scenarioOptions.filter(so => so !== OptionCode.TS),
    planBaseType: PlanBaseType.Basic,
    projectedPrice: projectedPrice,
    protectionFactor: null,
    unitStructure: scenarioPiece.unitStructure === UnitStructureCode.AU ? '' : UnitStructureAttributes[scenarioPiece.unitStructure].name,
  }];

  return quoteSummaryScenarioPiece;
};
