import { TextField, Tooltip } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { useState } from 'react';

export interface InputCostEntryDescriptionProps {
    index: number;
}

const InputCostEntryDescription = ({ index }: InputCostEntryDescriptionProps) => {
  const { control } = useFormContext();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const isToolTipApplicable = (valueLength: number) => {
    //Only show tool-tip if value is cut off
    valueLength > 14 ? setTooltipOpen(true) : setTooltipOpen(false);
  };

  return (
    <>
      <Controller
        name={`inputCosts.${index}.description`}
        control={control}
        rules={{
          required: VALIDATION_RULES.required(),
          maxLength: VALIDATION_RULES.maxLength(50),
        }}
        render={({
          field: { ref, ...field }, fieldState: { error },
        }) => (
          <Tooltip title={field.value} arrow disableFocusListener placement="left" open={tooltipOpen} onClose={handleTooltipClose} onOpen={() => isToolTipApplicable(field.value.length)}>
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              size="small"
              type="text"
              onChange={event => {
                isToolTipApplicable(event.target.value.length);
                field.onChange(event.target.value);
              }}
              sx={{
                '& .MuiFormHelperText-root': {
                  marginLeft: 0,
                  marginRight: 0,
                },
              }}
            />
          </Tooltip>
        )}
      />
    </>
  );
};

export default InputCostEntryDescription;
