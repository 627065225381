import { Autocomplete, Button, CircularProgress, Dialog, DialogContent, FilterOptionsState, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Nullable } from '../../../types/util/Nullable';
import { isNilOrEmpty, isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';
import { Insured, InsuredAddress } from '../../../types/api/insureds/Insured';
import { formatCityStateZip } from '../../../utils/formatAddress';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { InsuredId, TaxTypeId } from '../../../types/api/PrimaryKeys';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { fetchInsured, fetchInsureds, selectAllInsureds, selectInsuredById, selectTaxTypes } from '../../../app/insuredsSlice';
import { matchSorter } from 'match-sorter';
import { fuzzySearchMultipleWords } from '../../../utils/searchUtils';
import { MuiDialogCloseReason } from '../../../types/mui/MuiDialogCloseReason';
import { TaxType } from '../../../types/api/insureds/TaxType';
import TaxIdMask from '../../../components/formInputs/applications/taxIdMask';


const filterOptions = (options: Insured[], { inputValue }: FilterOptionsState<Insured>) => fuzzySearchMultipleWords(options, inputValue, [
  { key: 'name', threshold: matchSorter.rankings.ACRONYM },
  { key: 'address.state', threshold: matchSorter.rankings.EQUAL },
  { key: 'address.city', threshold: matchSorter.rankings.CONTAINS, maxRanking: matchSorter.rankings.WORD_STARTS_WITH },
  { key: 'address.postalCode', threshold: matchSorter.rankings.CONTAINS },
  { key: 'phone', threshold: matchSorter.rankings.CONTAINS },
]).slice(0, 5);

const formatAddress = (address: Nullable<InsuredAddress>) => {
  const formattedAddress = formatCityStateZip(address);
  return isNilOrEmpty(formattedAddress) ? '' : `- ${formattedAddress}`;
};

const formatPhone = (phone: Nullable<string>) => {
  return isNilOrEmpty(phone) ? '' : `- ${formatPhoneNumber(phone)}`;
};

const getSelectedTaxIdType = (taxTypes: TaxType[], taxTypeId: Nullable<TaxTypeId>) => {
  if (isNullOrUndefined(taxTypes) || isNullOrUndefined(taxTypeId)) {
    return '';
  }

  const selectedTaxIdType = taxTypes.find(x => x.taxTypeId === taxTypeId);
  return selectedTaxIdType?.name ?? '';
};

type ImportInsuredModalProps = {
  onClose: (reason?: MuiDialogCloseReason) => void;
  existingInsuredId: InsuredId;
  importInsured: (insured: Insured) => void;
};

const ImportInsuredModal = ({ onClose, existingInsuredId, importInsured }: ImportInsuredModalProps) => {
  const dispatch = useAppDispatch();
  const taxTypes = useAppSelector(selectTaxTypes);
  const insureds = useAppSelector(selectAllInsureds);
  const filteredInsureds = useMemo(() => insureds.filter(x => x.id !== existingInsuredId), [insureds, existingInsuredId]);
  const [loading, setLoading] = useState(false);
  const [selectedInsuredId, setSelectedInsuredId] = useState<Nullable<InsuredId>>(null);
  const selectedInsured = useAppSelector(state => isNullOrUndefined(selectedInsuredId) ? null : selectInsuredById(state, selectedInsuredId));

  useEffect(() => {
    setLoading(true);
    dispatch(fetchInsureds()).then(() => setLoading(false));
  }, []);

  const handleImport = () => {
    if (!selectedInsured) return;

    const address: InsuredAddress = {
      city: selectedInsured.address.city ?? '',
      state: selectedInsured.address.state ?? '',
      addressLine1: selectedInsured.address.addressLine1 ?? '',
      postalCode: selectedInsured.address.postalCode ?? '',
      country: selectedInsured.address.country ?? '',
      composite: selectedInsured.address.composite ?? '',
    };

    const insured: Insured = {
      ...selectedInsured,
      middleName: selectedInsured.middleName ?? '',
      lastName: selectedInsured.lastName ?? '',
      suffix: selectedInsured.suffix ?? '',
      phone: selectedInsured.phone ?? '',
      corporationState: selectedInsured.corporationState ?? '',
      email: selectedInsured.email ?? '',
      name: selectedInsured.name ?? '',
      firstName: selectedInsured.firstName ?? '',
      address: address,
    };

    importInsured(insured);
    onClose();
    setSelectedInsuredId(null);
  };

  const handleInsuredSelect = async (insured: Nullable<Insured>) => {
    if (isNullOrUndefined(insured)) return;

    await dispatch(fetchInsured({ insuredId: insured.id }));
    setSelectedInsuredId(insured.id);
  };

  return (
    <Dialog
      className="import-modal"
      open={true}
      onClose={(_, reason) => onClose(reason)}
      scroll="body"
      maxWidth="md"
      sx={{ m: 2 }}
      fullWidth
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container direction="column" rowGap={1} sx={{ height: '100%' }}>
          <Grid item><Typography variant="h6">Search D365</Typography></Grid>
          <Grid item>
            <Autocomplete
              key={selectedInsured?.id}
              value={selectedInsured}
              selectOnFocus={false}
              clearOnBlur={false}
              handleHomeEndKeys={false}
              forcePopupIcon={false}
              autoComplete={true}
              clearOnEscape={true}
              includeInputInList={true}
              disabled={loading}
              loading={loading}
              filterOptions={filterOptions}
              onChange={(_, insured) => handleInsuredSelect(insured)}
              noOptionsText={'No insureds found'}
              getOptionLabel={insured => `${insured.name} ${formatAddress(insured.address)} ${formatPhone(insured.phone)}`.trim().toUpperCase()}
              options={filteredInsureds}
              renderInput={params =>
                <TextField
                  {...params}
                  disabled={loading}
                  variant="outlined"
                  value={selectedInsured?.name ?? ''}
                  label="SEARCH BY NAME, ADDRESS OR PHONE #"
                  sx={{ background: theme => theme.palette.other.inputBackground, color: theme => theme.palette.text.primary }}
                />
              }
            />
          </Grid>
          {loading && (
            <Grid container sx={{ position: 'absolute', bottom: 0, top: 0, left: 0, right: 0, margin: 'auto', justifyContent: 'center', alignItems: 'center' }}>
              <Grid item>
                <CircularProgress size={50} />
              </Grid>
            </Grid>
          )}
          {isNotNullOrUndefined(selectedInsured) ? (
            <Grid container pt={2} spacing={1} rowGap={2}>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Email"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={selectedInsured.email}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Phone"
                  disabled
                  variant="outlined"
                  fullWidth
                  value={formatPhone(selectedInsured.phone)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Tax ID"
                  disabled
                  fullWidth
                  value={selectedInsured.taxId}
                  InputProps={{
                    inputComponent: TaxIdMask,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Tax ID Type"
                  disabled
                  fullWidth
                  value={getSelectedTaxIdType(taxTypes, selectedInsured.taxTypeId)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  disabled
                  label="Address"
                  fullWidth
                  value={selectedInsured.address.addressLine1}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="City"
                  disabled
                  fullWidth
                  value={selectedInsured.address.city}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="State"
                  disabled
                  fullWidth
                  value={selectedInsured.address.state}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  disabled
                  label="Zip"
                  fullWidth
                  value={selectedInsured.address.postalCode}
                />
              </Grid>
            </Grid>
          ) : <Grid container height="140px" />}
          <Grid item xs container minHeight="75px" columnGap={1} justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <Button id="btn-insured-close" variant="outlined" onClick={() => onClose()}>Close</Button>
            </Grid>
            <Grid item>
              <Button id="btn-insured-import" variant="contained" disabled={isNullOrUndefined(selectedInsured)} onClick={handleImport}>Import</Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ImportInsuredModal;