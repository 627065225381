import { Badge, BadgeProps, Grid, Step, StepLabel, Stepper, Tooltip, styled } from '@mui/material';
import SpouseInfoPage, { SpouseInfoStep } from './wizardSteps/spouseInfoStep';
import SBIInfoPage, { SBIInfoStep } from './wizardSteps/sbiInfoStep';
import POAInfoPage, { POAAuthRepStep } from './wizardSteps/poaAuthRepStep';
import ConditionsOfAcceptancePage, { ConditionsOfAcceptanceStep } from './wizardSteps/conditionsOfAcceptanceStep';
import LandlordTenantPage, { LandlordTenantStep } from './wizardSteps/landlordTenantStep';
import AuthToSignPage, { AuthToSignStep } from './wizardSteps/authToSignStep';
import CoverageDecisionPage, { CoverageDecisionStep } from './wizardSteps/coverageDecisionPage/coverageDecisionStep';
import ApplicationQuestionsPage, { AppQuestionsStep } from './wizardSteps/appQuestionsStep';
import InsuredInfoPage, { InsuredInfoStep } from './wizardSteps/insuredInfoStep';
import { TabPanel } from '../../utils/tabPanel';
import InsuredQuestionsPage, { InsuredQuestionsStep } from './wizardSteps/insuredQuestionsStep';
import ApplicationDecisionPage, { ApplicationDecisionStep } from './wizardSteps/appDecisionsPage/applicationDecisionPage';
import { ApplicationWizard } from '../../types/api/applicationWizard/applicationWizard';
import { ApplicationWizardStepProps, ApplicationWizardStepValidationResult, ApplicationWizardSteps } from './wizardSteps/applicationWizardStep';
import { ReactElement } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectClientFileById } from '../../app/clientFilesSlice';

type ApplicationWizardProps = {
  selectedTabIndex: number;
  navigateToPreviousStep: () => void;
  navigateToNextStep: () => void;
  navigateToTabAtIndex: (index: number) => void;
  app: ApplicationWizard;
  updateIsInEditMode: (val: boolean) => void;
  updateApplication: (application: ApplicationWizard) => void;
  isInEditMode: boolean;
  isLoading: boolean;
  updateIsLoading: (val: boolean) => void;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 2,
    padding: '0 4px',
  },
}));

const CustomStepIconRoot = styled('div')<{
  ownerState: { isActive: boolean; isDisabled: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.primary.main,
  width: 35,
  height: 35,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '1.75rem',
  },
  ...(ownerState.isActive && {
    color: theme.palette.secondary.main,
  }),
  ...(ownerState.isDisabled && {
    color: theme.palette.action.disabled,
  }),
}));

const CustomStepIcon = (icon: ReactElement, isDisabled: boolean, isActive: boolean, validationResult: ApplicationWizardStepValidationResult) => {
  if (validationResult.isValid) {
    return (
      <CustomStepIconRoot ownerState={{ isDisabled, isActive }}>
        {icon}
      </CustomStepIconRoot>
    );
  }
  return (
    <CustomStepIconRoot ownerState={{ isDisabled, isActive }}>
      <Tooltip title={validationResult.errors.join(';\n')}>
        <StyledBadge badgeContent={<ErrorIcon sx={{ color: theme => theme.palette.error.main, fontSize: '20px !important' }} />}>
          {icon}
        </StyledBadge>
      </Tooltip>
    </CustomStepIconRoot>
  );
};

const ApplicationsWizard = ({ selectedTabIndex, navigateToPreviousStep, navigateToNextStep, navigateToTabAtIndex, app, updateIsInEditMode, updateApplication, isInEditMode, isLoading, updateIsLoading }: ApplicationWizardProps) => {
  const clientFile = useAppSelector(state => selectClientFileById(state, app.clientFileId));
  // Need access to root state in our validation. Could potentially narrow down this scope
  // a bit if performance becomes an issue but we are in the context of the application modal here
  // so we shouldn't have state outside the needs of the application modal changing here.
  const rootState = useAppSelector(s => s);

  if (!clientFile) {
    return null;
  }

  const wizardPageProps: ApplicationWizardStepProps = {
    application: app,
    navigateToNextStep: navigateToNextStep,
    navigateToPreviousStep: navigateToPreviousStep,
    clientFileId: clientFile.clientFileId,
    updateIsInEditMode: updateIsInEditMode,
    updateApplication: updateApplication,
    isInEditMode: isInEditMode,
    isLoading: isLoading,
    updateIsLoading: updateIsLoading,
  };

  return (
    <Grid container direction="column" sx={{ width: '100%', height: '100%', p: '0 !important', overflowX: 'auto', overflowY: 'hidden', flexWrap: 'nowrap' }}>
      <Stepper activeStep={selectedTabIndex} alternativeLabel sx={{ overflow: 'hidden', pt: .5 }}>
        {ApplicationWizardSteps.map(step => {
          const isDisabled = step.isDisabled(app, rootState);
          const isActiveStep = selectedTabIndex === step.index;
          const stepValidationResult = step.isValid(app, rootState);
          return (
            <Step sx={{ cursor: isDisabled || isInEditMode ? 'default' : 'pointer' }} key={step.title} active={!isDisabled} disabled={isDisabled} onClick={isDisabled ? undefined : () => navigateToTabAtIndex(step.index)}>
              <StepLabel
                className={isActiveStep ? 'active-step' : ''}
                StepIconProps={{ sx: { textSize: '30px' } }}
                StepIconComponent={() => CustomStepIcon(step.icon, isDisabled, isActiveStep, stepValidationResult)}
              >
                {step.title}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Grid item xs sx={{ overflow: 'hidden' }}>
        <TabPanel value={selectedTabIndex} index={CoverageDecisionStep.index}>
          <CoverageDecisionPage {...wizardPageProps} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={AppQuestionsStep.index}>
          <ApplicationQuestionsPage {...wizardPageProps} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={InsuredInfoStep.index}>
          <InsuredInfoPage {...wizardPageProps} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={InsuredQuestionsStep.index}>
          <InsuredQuestionsPage {...wizardPageProps} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={SpouseInfoStep.index}>
          <SpouseInfoPage {...wizardPageProps} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={SBIInfoStep.index}>
          <SBIInfoPage {...wizardPageProps} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={POAAuthRepStep.index}>
          <POAInfoPage {...wizardPageProps} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={LandlordTenantStep.index}>
          <LandlordTenantPage {...wizardPageProps} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={AuthToSignStep.index}>
          <AuthToSignPage {...wizardPageProps} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={ConditionsOfAcceptanceStep.index}>
          <ConditionsOfAcceptancePage {...wizardPageProps} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={ApplicationDecisionStep.index}>
          <ApplicationDecisionPage {...wizardPageProps} />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default ApplicationsWizard;