import EntityDiff from '../entityDiffing/entityDiff';
import { formatNumber } from '../../../utils/formatNumbers';
import DataLocationType from '../constants/dataLocationType';
import { NamedReconciliationStack } from '../../../types/app/ReconciliationTrackedEntity';

type StackType = NamedReconciliationStack<'unitYearAphs'>;

interface UnitAphDiffProps {
  unitYearAph: StackType;
  reconciledUnitYearAph: StackType;
  onReconciliationTypeChange: (entity: StackType) => void;
  dataLocation: DataLocationType;
  shouldShowHeader: boolean;
  preventSelection: boolean;
}

const UnitAphDiff = ({ unitYearAph, reconciledUnitYearAph, onReconciliationTypeChange, dataLocation, shouldShowHeader, preventSelection }: UnitAphDiffProps) => {
  const detailComponents = [
    unitYearAph.entity.year.toString(),
    `${formatNumber(unitYearAph.entity.production ?? 0, true)} Production`,
    `${formatNumber(unitYearAph.entity.acres ?? 0, true)} Acres`,
    `${unitYearAph.entity.yieldType} Type`,
    `${formatNumber(unitYearAph.entity.aphYield ?? 0, true)} Yield`,
  ];

  return (
    <EntityDiff
      title="Unit History"
      subEntitySelector={() => []}
      subtitle={unitYearAph.entity.year.toString()}
      detailComponents={detailComponents}
      onReconciliationTypeChange={onReconciliationTypeChange}
      preventCollapse
      trackedEntity={reconciledUnitYearAph}
      subsetEntity={unitYearAph}
      dataLocation={dataLocation}
      preventSelection={preventSelection}
      hideHeader={!shouldShowHeader}
    />
  );
};

export default UnitAphDiff;