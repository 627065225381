import { ADMYear } from '../types/api/adm/ADMYear';

export const getStateCodeFromCountyId = (countyId: string) => countyId.substring(0, 2);
export const getCountyCodeFromCountyId = (countyId: string) => countyId.substring(2);

export const getPracticeCodeFromPracticeId = (practiceId: string) => {
  const practiceCode = practiceId.substring(4);
  return practiceCode;
};

export const getTypeCodeFromTypeId = (typeId: string) => {
  const typeCode = typeId.substring(4);
  return typeCode;
};

export const getCommodityCodeFromTypeId = (typeId: string) => typeId.substring(0, 4);
export const getCommodityCodeFromPracticeId = (practiceId: string) => practiceId.substring(0, 4);

const getState = (years: ADMYear[], year: number, countyId: string) => years.find(y => y.year === year)?.states.find(s => s.stateCode === getStateCodeFromCountyId(countyId));

export const getStateName = (years: ADMYear[], year: number, countyId: string): string => getState(years, year, countyId)?.name ?? '';

export const getStateAbbrev = (years: ADMYear[], year: number, countyId: string):string => getState(years, year, countyId)?.abbrev ?? '';

export const getCountyName = (years: ADMYear[], year: number, countyId: string): string => years.find(y => y.year === year)?.counties.find(c => c.countyId === countyId)?.name ?? '';

export const getCommodityName = (years: ADMYear[], year: number, commodityCode: string): string => years.find(y => y.year === year)?.commodities.find(c => c.commodityCode === commodityCode)?.name ?? '';

export const getCommodityAbbrevFromCommodityCode = (years: ADMYear[], year: number, commodityCode: string): string => years.find(y => y.year === year)?.commodities.find(c => c.commodityCode === commodityCode)?.abbrev ?? '';

export const getPracticeAbbrevFromPracticeCode = (years: ADMYear[], year: number, practiceCode: string): string => years.find(y => y.year === year)?.practices.find(p => p.practiceCode === practiceCode)?.abbrev ?? '';

export const getPracticeName = (years: ADMYear[], year: number, practiceId: string): string => years.find(y => y.year === year)?.practices.find(p => p.practiceId === practiceId)?.name ?? '';

export const getCropTypeName = (years: ADMYear[], year: number, typeId: string): string => years.find(y => y.year === year)?.cropTypes.find(c => c.typeId === typeId)?.name ?? '';

export const getCropTypeAbbrev = (years: ADMYear[], year: number, typeId: string): string => years.find(y => y.year === year)?.cropTypes.find(c => c.typeId === typeId)?.abbrev ?? '';
