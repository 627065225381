import { AccountInfo, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { ToastContent } from '../app/toastSlice';
import { loginRequest } from '../authConfig';
import { isOnlineFromStore } from './isOnlineFromStore';

export const requestInterceptor = (instance: IPublicClientApplication, accounts: readonly AccountInfo[]) => async (config: AxiosRequestConfig) => {
  let tokenResponse;

  try {
    tokenResponse = await instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] });
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      tokenResponse = await instance.acquireTokenPopup({ ...loginRequest, account: accounts[0] });
    } else {
      throw new Error('Unable to authenticate. Please refresh to try again.');
    }
  }

  if (config.headers !== undefined) {
    config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
  }

  return config;
};

export const responseInterceptor = <T>(response: T) => response;

interface DefaultApiError {
  title: string;
  errors: string[];
}

export const responseErrorInterceptor = (openToast: (content: ToastContent) => void) => async (error: AxiosError<DefaultApiError>) => {
  const isOnline = isOnlineFromStore();
  if (!isOnline) throw error;

  let message = error.message;

  try {
    if (error.response?.data && error.response.data.title) {
      message = error.response.data.title;
      for (const key in error.response.data.errors) {
        message += `<br />${error.response.data.errors[key][0]}`;
      }
    }
  } catch {
    message = error.message;
  }

  openToast({ type: 'error', message: message });
  throw error;
};