import BaseUnit from '../../../types/api/BaseUnit';
import {
  FarmBillArcPremiumParams,
  FarmBillArcIndemnityParams,
  FarmBillArcUnit,
  FarmBillArcRequestDTO,
  UnitStructureCode,
  CoverageTypeCode,
  Unit
} from '@silveus/calculations';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getUnitsForScenarioPiece } from '../calculationUtils';
import { Nullable } from '../../../types/util/Nullable';
import { farmBillMakeBaseUnitGroup, farmBillMakeBaseUnit, getFarmBillPercentAcresCoveredFromScenarioPiece } from './farmBillHelpers';

export const createFarmBillArcData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  const farmBillUnit = farmBillMakeBaseUnit(scenarioPiece);
  const unitGroupsForScenarioPiece = [farmBillMakeBaseUnitGroup(scenarioPiece, farmBillUnit)];
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, [farmBillUnit]);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createFarmBillArcPlanDto(state, scenarioPiece, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformFarmBillArcData = (unit: Nullable<BaseUnit>, scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece): FarmBillArcPremiumParams & FarmBillArcIndemnityParams => {

  const farmBillArcParams: FarmBillArcPremiumParams & FarmBillArcIndemnityParams = {
    // farm bill specific
    baseAcres: scenarioPiece.rowCropScenarioPieceExtendedData?.acres ?? 0,
    percentAcresCovered: getFarmBillPercentAcresCoveredFromScenarioPiece(scenarioPiece.scenarioPieceType, scenarioPiece.planCode),
    payableEntities: scenarioPiece.rowCropScenarioPieceExtendedData?.payableEntitiesAmount ?? 0,
    expectedAreaYield: scenarioPiece.rowCropScenarioPieceExtendedData?.expectedYield ?? 0,
    finalAreaYield: scenarioPiece.rowCropScenarioPieceExtendedData?.actualYield ?? 0,
    projectedPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.projectedPrice ?? 0,
    harvestPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.actualPrice ?? 0,

    // needed only because of base classes
    upperCoverageLevelPercent: 0,
    lowerCoverageLevelPercent: 0,
    unitStructureCode: UnitStructureCode.AU,
    coverageTypeCode: CoverageTypeCode.A,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,
  };

  return farmBillArcParams;
};

const createFarmBillArcUnits = (units: BaseUnit[]): (FarmBillArcUnit & Unit)[] => {
  return units.map(unit => {
    const unitDtos = createBaseUnit(unit);
    const nonAreaRiskUnit: FarmBillArcUnit & Unit = {
      ...unitDtos,
      id: unit.id,
    };

    return nonAreaRiskUnit;
  });
};

const createFarmBillArcPlanDto = (state: RootState, scenarioPiece: RowCropScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): FarmBillArcRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createFarmBillArcUnits(
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
      ),
    ),
    ...transformFarmBillArcData(unitData.at(0) ?? null, scenario, scenarioPiece),
  };
};