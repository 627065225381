import { YpRequestDTO } from '@silveus/calculations';
import { RootState } from '../../../app/store';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { createRpData } from './rpDataTransformations';
import BaseUnit from '../../../types/api/BaseUnit';

export const createYpData = async (scenarioPiece: RowCropScenarioPiece,
  scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  const extendedData = scenarioPiece.rowCropScenarioPieceExtendedData;
  const data = await createRpData(scenarioPiece, scenario, state, baseUnits);
  //This cast was added due to the fact that properties come back from the rp data as possibly being undefined due to allowing one of 2 premium params
  //TS is not able to tell which one it is at compile time, so we have to tell it that this is okay because we know that method isn't creating the manual version
  const ypData: YpRequestDTO = {
    ...data,
    liabilityAdjustmentFactor: 1,
    experienceFactor: (extendedData?.experienceFactor ?? 100) / 100,
    isCat: scenarioPiece.rowCropScenarioPieceExtendedData?.isCat ?? false,
  } as YpRequestDTO;
  return ypData;
};
