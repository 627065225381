import { UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import axios from 'axios';
import { ReactNode, useEffect, useState } from 'react';
import useOnlineStatus from '../../hooks/useOnlineStatus';
import { requestInterceptor, responseErrorInterceptor, responseInterceptor } from '../../utils/api';
import { openToast, ToastContent } from '../../app/toastSlice';
import { fetchAdmData } from '../../app/admSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { Loading } from './loading.component';
import { LoggedOut } from './loggedOut.component';
import { useFetchUserSettings } from './useFetchUserSettings';
import { selectIsOnline } from '../../app/onlineSlice';

interface Props {
  children: ReactNode;
}

const Authenticated = ({ children }: Props) => {
  useOnlineStatus();
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [initialized, setInitialized] = useState(false);
  const online = useAppSelector(selectIsOnline);
  const offline = !online;

  useEffect(() => {
    if (isAuthenticated) {
      axios.interceptors.request.use(requestInterceptor(instance, accounts));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    axios.interceptors.response.use(responseInterceptor, responseErrorInterceptor((content: ToastContent) => dispatch(openToast(content))));
  }, []);

  //State Initialization
  useEffect(() => {
    if ((isAuthenticated || offline) && !initialized) {
      dispatch(fetchAdmData()).then(() => setInitialized(true));
    }
  }, [isAuthenticated, offline]);

  // Fetch certain critical user information as soon as the user is authenticated.
  useFetchUserSettings();

  return <>
    {(isAuthenticated || offline) && initialized && children}
    {(isAuthenticated || offline) && !initialized && <Loading message="Loading application..." />}
    {online && <UnauthenticatedTemplate>
      <LoggedOut />
    </UnauthenticatedTemplate>}
  </>;
};

export default Authenticated;