import ScenarioPieceDefinition from '../../types/app/ScenarioPieceDefinition';
import validationFunctionDefault from './defaultDefinitions/validationFunctionDefault';
import { createForwardSoldData } from '../../services/calculations/forwardSold/forwardSoldDataTransformation';
import ForwardSoldScenarioPiece from '../../types/api/ForwardSoldScenarioPiece';
import { ScenarioPieceFormPickerProps } from '../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import ForwardSoldScenarioPieceForm from '../../pages/scenarioPiece/forwardSold/forwardSoldScenarioPieceForm.component';
import {
  getDefaultQuoteSummaryData
} from '../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getDefaultQuoteSummaryData';
import { Nullable } from '../../types/util/Nullable';
import { addForwardSoldScenarioPiece } from '../../app/forwardSoldScenarioPiecesSlice';
import { getFullyQualifiedScenarioPieceNameOnlyName } from './defaultDefinitions/scenarioPieceNameDefaults';
import { scenarioPieceCardChipDefault } from './defaultDefinitions/scenarioPieceCardChipDefaults';

export const forwardSoldDefinition: ScenarioPieceDefinition = {
  validationFunction: validationFunctionDefault,
  d365ProductType: undefined,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    return createForwardSoldData(scenarioPiece as ForwardSoldScenarioPiece, scenario, state, baseUnits, shouldSimulateUnitGroups);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <ForwardSoldScenarioPieceForm {...props} scenarioPiece={props.scenarioPiece as Nullable<ForwardSoldScenarioPiece>} />;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getDefaultQuoteSummaryData,
  addScenarioPiece: (scenarioPiece, dispatch) => dispatch(addForwardSoldScenarioPiece({ scenarioPiece: scenarioPiece as ForwardSoldScenarioPiece })),
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceNameOnlyName,
  getScenarioPieceCardChips: scenarioPieceCardChipDefault,
  shouldShowThreeStageSummaryExpander: false,
};
