import { useAppDispatch } from '../../../hooks/reduxHooks';
import ForwardSoldScenarioPiece from '../../../types/api/ForwardSoldScenarioPiece';
import { modifyForwardSoldScenarioPiece } from '../../../app/forwardSoldScenarioPiecesSlice';
import InactiveScenarioPieceCard from '../inactiveScenarioPieceCard.component';

interface InactiveForwardSoldScenarioPieceCardProps {
  scenarioPiece: ForwardSoldScenarioPiece;
  isScenarioActive: boolean;
}

const InactiveForwardSoldScenarioPieceCard = ({ scenarioPiece, isScenarioActive }: InactiveForwardSoldScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();

  const updateScenarioPiece = async (scenarioPiece: ForwardSoldScenarioPiece) => {
    await dispatch(modifyForwardSoldScenarioPiece({ scenarioPiece: { ...scenarioPiece, isActive: true } }));
  };

  return (
    <InactiveScenarioPieceCard
      scenarioPiece={scenarioPiece}
      isScenarioActive={isScenarioActive}
      label="Forward Sold"
      updateScenarioPiece={updateScenarioPiece}
    />
  );
};

export default InactiveForwardSoldScenarioPieceCard;