
export const possibleColors = [
  '#1F78B4',
  '#F44336',
  '#55C92C',
  '#00E5FF',
  '#BFB16C',
  '#DA77D0',
  '#F4F099',
  '#FF0266',
  '#ba68c8',
  '#6200EE',
  '#03DAC5',
  '#0336FF',
  '#B2DFDB',
  '#81C784',
  '#A1887F',
  '#FF8A65',
  '#00BFA5',
  '#7986CB',
  '#BA68C8',
  '#BB86FC',
] as const;

export function pickColor(currentPosition: number): string {
  const colorIndex = currentPosition % possibleColors.length;
  return possibleColors[colorIndex];
}
