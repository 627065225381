import { isEqual } from 'lodash';

/** Limits inputs to only be objects with properties, as opposed to strings, functions, etc. */
type ValidObjectToCompare = { };

type ComparisonOutput<T> = {
  /** Whether the objects are the same. If true, the accompanying array should be empty. */
  areSame: boolean;
  /** The properties that differ between the two objects. If empty, the objects are the same. */
  propsThatDiffer: readonly (keyof T)[];
};

/**
 * Determines if two objects are identical.
 * If they are not, returns the properties that differ between them.
 * Only compares those properties that are shared between the two objects.
 */
export const compareEntities = <T extends ValidObjectToCompare>(a: T, b: T): ComparisonOutput<T> => {

  const propsToCompare = getSharedProperties(a, b);

  type ValidKey = keyof T;

  const propsThatDiffer: ValidKey[] = [];

  for (const propName of propsToCompare) {
    // This assertion can technically lead to false positives (properties the compiler isn't aware of),
    // but we can ignore that safely and assume types are accurate.
    const assertedPropName = propName as keyof T;

    const propA = a[assertedPropName];
    const propB = b[assertedPropName];

    if (!areObjectsTheSame(propA, propB)) {
      propsThatDiffer.push(assertedPropName);
    }
  }

  return {
    areSame: propsThatDiffer.length === 0,
    propsThatDiffer: propsThatDiffer,
  };
};

/** Returns the properties of an object that we will use for comparison.
 * This is abstracted because it's not a given how we choose which properties to compare,
 * and we want consistent logic.
 */
const procurePropertiesForComparison = (obj: ValidObjectToCompare) => Object.keys(obj);

const areObjectsTheSame = (a: unknown, b: unknown) => isEqual(a, b);

/**
 * Returns the set of properties the two objects share.
 */
const getSharedProperties = (a: ValidObjectToCompare, b: ValidObjectToCompare) => {
  const aProps = procurePropertiesForComparison(a);
  const bProps = new Set(procurePropertiesForComparison(b));

  return aProps.filter(prop => bProps.has(prop));
};