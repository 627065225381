import { ScenarioPieceType, HighRiskType, OptionCode, UnitStructureCode } from '@silveus/calculations';
import { selectScenarioOptionDictionary } from '../../../../app/optionsSlice';
import { selectAllQuotesByClientFileMap } from '../../../../app/quotesSlice';
import { selectAllScenarios } from '../../../../app/scenariosSlice';
import { getKeyedStateGroupedBy } from '../../../../app/sliceHelpers';
import { RootState } from '../../../../app/store';
import { ScenarioId } from '../../../../types/api/PrimaryKeys';
import { RowCropScenario } from '../../../../types/api/RowCropScenario';
import { ApplicationWizard } from '../../../../types/api/applicationWizard/applicationWizard';
import ScenarioOption from '../../../../types/api/options/ScenarioOption';
import { distinct, filterNotNullOrUndefined, groupBy } from '../../../../utils/arrayUtils';
import { isNullOrUndefined } from '../../../../utils/nullHandling';
import { scenarioPieceOrderingServiceInstance } from '../../../../utils/scenarioOrderingServiceWrappers';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../../../app/scenarioPiecesSlice';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import { getAllDependantScenarioPieces } from '../appDecisionsPage/appTaskUtils';
import { setsAreEqual } from '../../../../utils/setUtils';

export interface CoverageValidationResult {
  title: string;
  isValid: boolean;
  message: string;
}

export const validateCoverageDecisions = (app: ApplicationWizard, state: RootState) => {
  // First we need to ensure user has even selected any scenarios before we go any further.
  const minimumRequiredScenariosIncluded = validateMinimumCoveragesSelected(app);
  if (!minimumRequiredScenariosIncluded.isValid) {
    return [minimumRequiredScenariosIncluded];
  }

  const quotes = selectAllQuotesByClientFileMap(state);
  const quotesForClientFile = quotes.get(app.clientFileId);
  if (isNullOrUndefined(quotesForClientFile)) {
    throw new Error('Failed to find quotes for client file');
  }

  const allScenarioOptions = selectScenarioOptionDictionary(state);
  const optionsByScenarioId = getKeyedStateGroupedBy(allScenarioOptions, s => s.scenarioId);
  const allRowCropScenarioPiecesByScenario = selectAllRowCropScenarioPiecesByScenarioMap(state);

  const allScenarios = selectAllScenarios(state);
  const validationResults: CoverageValidationResult[] = [];
  const includedScenarios = filterNotNullOrUndefined(app.applicationWizardScenarios.map(x => allScenarios[x.scenarioId]));
  const includedQuotes = filterNotNullOrUndefined(includedScenarios.map(x => quotesForClientFile.find(q => q.quoteId === x.quoteId)));
  const groupedQuotes = groupBy(includedQuotes, x => x.countyId);
  const groupedQuoteKeys = groupedQuotes.keys();
  // For coverage decision validation we need to group all included scenarios by state/county/crop and then do our comparisons of those
  // scenarios to see if the decisions are valid. i.e. if a user includes scenarios across many quotes for the same state/county/crop
  // we need to get all those like scenarios first to see if the elections are valid.
  for (const countyId of groupedQuoteKeys) {
    const quotesForCounty = groupedQuotes.get(countyId);
    if (isNullOrUndefined(quotesForCounty)) {
      continue;
    }

    const quotesByCommodity = groupBy(quotesForCounty, x => x.commodityCode);
    const commodityCodes = quotesByCommodity.keys();
    for (const commodityCode of commodityCodes) {
      const quotesForCommodity = quotesByCommodity.get(commodityCode);
      if (isNullOrUndefined(quotesForCommodity)) continue;
      const scenariosForQuotes = includedScenarios.filter(x => quotesForCommodity.find(q => q.quoteId === x.quoteId));
      const scenariosForCommodity = filterNotNullOrUndefined(scenariosForQuotes);
      const scenarioIdsForCommodity = new Set(scenariosForCommodity.map(x => x.scenarioId));
      // Run the validations using the scenarios for the state/county/crop here
      const basePlanValidation = validateBasePlans(scenariosForCommodity, allRowCropScenarioPiecesByScenario);
      const duplicateValidationResults = validateDuplicates(scenariosForCommodity);
      const countyCommodityValidationResults = [
        validateOptionCodes(scenariosForCommodity, optionsByScenarioId),
        duplicateValidationResults,
        basePlanValidation,
        validateAdditionalEndorsements(scenariosForCommodity, allRowCropScenarioPiecesByScenario),
        validateEIOptions(scenariosForCommodity, optionsByScenarioId),
        validateUnitStructures(scenariosForCommodity, allRowCropScenarioPiecesByScenario, optionsByScenarioId),
        validateVipMp(scenarioIdsForCommodity, allRowCropScenarioPiecesByScenario),
        validateMPowerD(scenarioIdsForCommodity, allRowCropScenarioPiecesByScenario),
      ];

      // We only care about running the practice validations if the duplicate and base plan validations are okay otherwise its possible
      // for us to get additional validation errors that don't really apply.
      if (duplicateValidationResults.isValid && basePlanValidation.isValid) {
        countyCommodityValidationResults.push(validatePractices(scenariosForCommodity, allRowCropScenarioPiecesByScenario, optionsByScenarioId));
      }
      validationResults.push(...countyCommodityValidationResults);
    }
  }
  return validationResults;
};

const validateVipMp = (scenarios: Set<ScenarioId>, allRowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>) => {
  const validationResult: CoverageValidationResult = { title: '', message: '', isValid: true };

  const filteredScenarioPieces = Array.from(allRowCropScenarioPiecesByScenario.entries())
    .filter(([scenarioId]) => scenarios.has(scenarioId))
    .flatMap(([, pieces]) => pieces);

  const allVIPScenarioPieces = filteredScenarioPieces.filter(x => x.scenarioPieceType === ScenarioPieceType.VipMp);

  if (allVIPScenarioPieces.length > 1) {
    validationResult.message = 'VIP cannot be added to more than 1 scenario for the same state, county, and crop';
    validationResult.isValid = false;
    validationResult.title = 'VIP with MP';
  }
  return validationResult;
};

const validateMPowerD = (scenarios: Set<ScenarioId>, allRowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>) => {
  const validationResult: CoverageValidationResult = { title: '', message: '', isValid: true };

  const filteredScenarioPieces = Array.from(allRowCropScenarioPiecesByScenario.entries())
    .filter(([scenarioId]) => scenarios.has(scenarioId))
    .flatMap(([, pieces]) => pieces);

  const allMPowerDScenarioPieces = filteredScenarioPieces.filter(x => x.scenarioPieceType === ScenarioPieceType.MPowerD);

  if (allMPowerDScenarioPieces.length > 1) {
    validationResult.message = 'MPowerD cannot be added to more than 1 scenario for the same state, county, and crop';
    validationResult.isValid = false;
    validationResult.title = 'MPowerD';
  }
  return validationResult;
};

/**
 * User must have included at a minimum at least one scenario that has at least 1 scenario piece.
 */
const validateMinimumCoveragesSelected = (app: ApplicationWizard) => {
  const validationResult: CoverageValidationResult = { title: '', message: '', isValid: true };
  if (app.applicationWizardScenarios.length === 0) {
    validationResult.message = 'There must be at least 1 coverage decision selected or it is invalid';
    validationResult.isValid = false;
    validationResult.title = 'No Coverages Selected';
  }
  return validationResult;
};

/**
 * Only certain option codes should be selected depending on certain situations
 */
const validateOptionCodes = (scenarios: RowCropScenario[], optionsByScenarioId: Map<ScenarioId, ScenarioOption[]>) => {
  const validationResult: CoverageValidationResult = { title: '', message: '', isValid: true };

  if (scenarios.length === 1) {
    const invalidSingleScenarioOptions = new Set([OptionCode.LP, OptionCode.HB, OptionCode.ET, OptionCode.EC]);
    const options = optionsByScenarioId.get(scenarios[0].scenarioId) ?? [];
    const invalidPieces = options.filter(x => invalidSingleScenarioOptions.has(x.option));
    if (invalidPieces.length > 0) {
      validationResult.message = 'When only 1 coverage decision for a given quote (state/county/crop) is included, then the options values should not contain LP, HB, ET, or EC';
      validationResult.isValid = false;
      validationResult.title = 'Invalid Option Codes';
    }
  }
  // Other options are validated as part of other validators based on things such as practice codes
  return validationResult;
};

/**
 * If there are duplicate scenarios for type/practice/high risk then this is not valid
 */
const validateDuplicates = (scenarios: RowCropScenario[]) => {
  const validationResult: CoverageValidationResult = { title: '', message: '', isValid: true };
  for (const scenario of scenarios) {
    const matchingScenarios = scenarios.filter(x => x.scenarioId !== scenario.scenarioId && x.typeId === scenario.typeId && x.practiceId === scenario.practiceId && x.highRiskTypeId === scenario.highRiskTypeId);
    if (matchingScenarios.length > 0) {
      validationResult.message = 'There are duplicate scenarios for the same type/practice/high risk.';
      validationResult.isValid = false;
      validationResult.title = 'Duplicate Coverages Found';
    }
  }
  return validationResult;
};

const validateEIOptions = (scenarios: RowCropScenario[], optionsByScenarioId: Map<ScenarioId, ScenarioOption[]>) => {
  const validationResult: CoverageValidationResult = { title: '', message: '', isValid: true };
  const grouped = groupBy(scenarios, x => `${x.highRiskTypeId}${x.typeId}`);
  for (const [_, groupScenarios] of grouped) {
    const doSomeContainEI = doSomeScenariosContainOption(groupScenarios, OptionCode.EI, optionsByScenarioId);
    const doAllContainEI = doAllScenariosContainOption(groupScenarios, OptionCode.EI, optionsByScenarioId);
    if (doSomeContainEI && !doAllContainEI) {
      validationResult.message = 'You have selected the EI option on at least one scenario, while another scenario with the same State/County/Crop/Type/High Risk is missing the EI option.';
      validationResult.isValid = false;
      validationResult.title = 'EI Missing';
    }
  }
  return validationResult;
};

const validateUnitStructures = (scenarios: RowCropScenario[], allRowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>, optionsByScenarioId: Map<ScenarioId, ScenarioOption[]>) => {
  const validationResult: CoverageValidationResult = { title: '', message: '', isValid: true };

  const uniqueUnitStructures = new Set<UnitStructureCode>();
  for (const scenario of scenarios) {
    const scenarioPieces = allRowCropScenarioPiecesByScenario.get(scenario.scenarioId) ?? [];
    const scenarioPieceTypes = scenarioPieces.map(x => x.scenarioPieceType);
    const basePieceTypes = scenarioPieceOrderingServiceInstance.getOptionalBaseScenarioPieces(scenarioPieceTypes);
    const baseScenarioPiece = scenarioPieces.find(sp => basePieceTypes.includes(sp.scenarioPieceType));
    if (baseScenarioPiece) {
      uniqueUnitStructures.add(baseScenarioPiece.unitStructure);
    }
  }

  // We can only have different unit structures for the same state/county/crop IF the user has included the EI option on all.
  const doAllContainEI = doAllScenariosContainOption(scenarios, OptionCode.EI, optionsByScenarioId);
  if (uniqueUnitStructures.size > 1 && !doAllContainEI) {
    validationResult.message = 'If coverages for the same State/County/Crop contain different Unit Structures, then the EI option must be included ';
    validationResult.isValid = false;
    validationResult.title = 'Invalid Unit Structures';
  }

  return validationResult;
};

const validateAdditionalEndorsements = (scenarios: RowCropScenario[], allRowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>) => {
  // Unlike other scenario piece types, the following set of scenario piece types are considered endoresements and have their own set of rules.
  const additionalEndorsementScenarioPieceTypes = new Set([
    ScenarioPieceType.ScoRp,
    ScenarioPieceType.ScoRpHpe,
    ScenarioPieceType.ScoYp,
    ScenarioPieceType.EcoRp,
    ScenarioPieceType.EcoRpHpe,
    ScenarioPieceType.EcoYp,
    ScenarioPieceType.Hip,
    ScenarioPieceType.StaxRp,
    ScenarioPieceType.StaxRpHpe,
  ]);
  const validationResult: CoverageValidationResult = { title: '', message: '', isValid: true };
  const uniquePractices = distinct(scenarios.map(x => x.practiceId));
  if (uniquePractices.length === 0) return validationResult;

  const uniquePlans = new Set<ScenarioPieceType>();
  const uniqueUpperCoverageLevels = new Set<number>();

  const additionalEndorsementsByScenario = new Map<ScenarioId, RowCropScenarioPiece[]>();
  for (const scenario of scenarios) {
    const scenarioPieces = allRowCropScenarioPiecesByScenario.get(scenario.scenarioId) ?? [];
    const scenarioPieceTypes = scenarioPieces.map(x => x.scenarioPieceType);
    const basePieceTypes = scenarioPieceOrderingServiceInstance.getOptionalBaseScenarioPieces(scenarioPieceTypes);
    const baseScenarioPiece = scenarioPieces.find(sp => basePieceTypes.includes(sp.scenarioPieceType));
    if (isNullOrUndefined(baseScenarioPiece)) continue;
    uniquePlans.add(baseScenarioPiece.scenarioPieceType);
    uniqueUpperCoverageLevels.add(baseScenarioPiece.upperCoverageLevel);
    const endorsementPieces = scenarioPieces.filter(x => additionalEndorsementScenarioPieceTypes.has(x.scenarioPieceType));
    const dependantPieces = getAllDependantScenarioPieces(endorsementPieces, baseScenarioPiece.scenarioPieceType, new Set());
    additionalEndorsementsByScenario.set(scenario.scenarioId, dependantPieces);
  }

  if (!areAllAdditionalEndorsementsTheSameLength(additionalEndorsementsByScenario)) {
    validationResult.message = 'Included scenarios do not contain the same number of additional endorsements.';
    validationResult.isValid = false;
    validationResult.title = 'Invalid Additional Endorsements';
  } else if (!areAllAdditionalEndorsementsOfSameType(additionalEndorsementsByScenario)) {
    validationResult.message = 'Included scenarios do not contain the same endorsements.';
    validationResult.isValid = false;
    validationResult.title = 'Invalid Additional Endorsements';
  } else if (!areAdditionalPropertiesMatching(additionalEndorsementsByScenario)) {
    validationResult.message = 'Additional endorsements do not have the same upper coverage levels.';
    validationResult.isValid = false;
    validationResult.title = 'Invalid Additional Endorsements';
  } else if (!areProtectionFactorsMatching(additionalEndorsementsByScenario)) {
    validationResult.message = 'Additional endorsements do not have the same protection factors.';
    validationResult.isValid = false;
    validationResult.title = 'Invalid Additional Endorsements';
  }

  return validationResult;
};

const areAllAdditionalEndorsementsOfSameType = (map: Map<ScenarioId, RowCropScenarioPiece[]>) => {
  const scenarioTypeSets: Set<ScenarioPieceType>[] = [];
  const values = map.values();
  for (const pieces of values) {
    const typeSet = new Set<ScenarioPieceType>(pieces.map(p => p.scenarioPieceType));
    scenarioTypeSets.push(typeSet);
  }

  // Ensure all scenario type sets are identical
  const firstTypeSet = scenarioTypeSets[0];
  return scenarioTypeSets.every(typeSet => setsAreEqual(firstTypeSet, typeSet));
};

const areAllAdditionalEndorsementsTheSameLength = (map: Map<ScenarioId, RowCropScenarioPiece[]>) => {
  const lengths = [...map.values()].map(arr => arr.length);
  return lengths.every((val, i, arr) => val === arr[0]);
};

const areAdditionalPropertiesMatching = (map: Map<ScenarioId, RowCropScenarioPiece[]>): boolean => {
  // Get a set of all unique types of RowCropScenarioPieces across all scenarios
  const allTypes = new Set(Array.from(map.values()).flatMap(pieces => pieces.map(piece => piece.scenarioPieceType)));

  // Loop through each unique type and compare properties across scenarios
  for (const type of allTypes) {
    if (!areMatchingPiecesUpperCoveragesEqual(map, type)) {
      return false;
    }
  }

  return true; // All additional property checks passed
};

const areProtectionFactorsMatching = (map: Map<ScenarioId, RowCropScenarioPiece[]>): boolean => {
  // Get a set of all unique types of RowCropScenarioPieces across all scenarios
  const allTypes = new Set(Array.from(map.values()).flatMap(pieces => pieces.map(piece => piece.scenarioPieceType)));

  for (const type of allTypes) {
    if (!areMatchingPiecesProtectionFactorsEqual(map, type)) {
      return false;
    }
  }

  return true; // All additional property checks passed
};

// Helper function to check if properties (e.g., upperCoverageLevel) of matching RowCropScenarioPieces are equal across scenarios
const areMatchingPiecesUpperCoveragesEqual = (map: Map<ScenarioId, RowCropScenarioPiece[]>, type: ScenarioPieceType): boolean => {
  // Find the first scenario that contains a RowCropScenarioPiece of the given type
  let firstScenarioId: ScenarioId | undefined;
  let firstPiece: RowCropScenarioPiece | undefined;

  const entries = map.entries();
  for (const [scenarioId, pieces] of entries) {
    const matchingPiece = pieces.find(piece => piece.scenarioPieceType === type);
    if (matchingPiece) {
      firstScenarioId = scenarioId;
      firstPiece = matchingPiece;
      break;
    }
  }

  if (!firstScenarioId || !firstPiece) {
    // No scenario contains the specified type
    // Consider it as a valid scenario since the type isn't present anywhere as this is already accounted for in other validation steps
    return true;
  }

  // Compare properties of the matching piece across all scenarios
  for (const [scenarioId, pieces] of entries) {
    if (scenarioId !== firstScenarioId) {
      const matchingPiece = pieces.find(piece => piece.scenarioPieceType === type);
      if (!matchingPiece || matchingPiece.upperCoverageLevel !== firstPiece.upperCoverageLevel) {
        return false; // Found a mismatch in properties
      }
    }
  }

  return true; // All matching pieces have matching properties
};

const areMatchingPiecesProtectionFactorsEqual = (map: Map<ScenarioId, RowCropScenarioPiece[]>, type: ScenarioPieceType): boolean => {
  // Find the first scenario that contains a RowCropScenarioPiece of the given type
  let firstScenarioId: ScenarioId | undefined;
  let firstPiece: RowCropScenarioPiece | undefined;
  const entries = map.entries();
  for (const [scenarioId, pieces] of entries) {
    const matchingPiece = pieces.find(piece => piece.scenarioPieceType === type);
    if (matchingPiece) {
      firstScenarioId = scenarioId;
      firstPiece = matchingPiece;
      break;
    }
  }

  if (!firstScenarioId || !firstPiece) {
    // No scenario contains the specified type
    // Consider it as a valid scenario since the type isn't present anywhere as this is already accounted for in other validation steps
    return true;
  }

  // Compare properties of the matching piece across all scenarios
  for (const [scenarioId, pieces] of entries) {
    if (scenarioId !== firstScenarioId) {
      const matchingPiece = pieces.find(piece => piece.scenarioPieceType === type);
      if (!matchingPiece || matchingPiece.protectionFactor !== firstPiece.protectionFactor) {
        return false;
      }
    }
  }

  return true; // All matching pieces have matching properties
};

const validatePractices = (scenarios: RowCropScenario[], allRowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>, optionsByScenarioId: Map<ScenarioId, ScenarioOption[]>) => {
  const validationResult: CoverageValidationResult = { title: '', message: '', isValid: true };
  const uniquePractices = distinct(scenarios.map(x => x.practiceId));
  if (uniquePractices.length === 0 || scenarios.length === 1) return validationResult;

  const uniquePlans = new Set<ScenarioPieceType>();
  const uniqueUpperCoverageLevels = new Set<number>();
  for (const scenario of scenarios) {
    const scenarioPieces = allRowCropScenarioPiecesByScenario.get(scenario.scenarioId) ?? [];
    const scenarioPieceTypes = scenarioPieces.map(x => x.scenarioPieceType);
    const basePieceTypes = scenarioPieceOrderingServiceInstance.getOptionalBaseScenarioPieces(scenarioPieceTypes);
    const baseScenarioPiece = scenarioPieces.find(sp => basePieceTypes.includes(sp.scenarioPieceType));
    if (isNullOrUndefined(baseScenarioPiece)) continue;
    uniquePlans.add(baseScenarioPiece.scenarioPieceType);
    uniqueUpperCoverageLevels.add(baseScenarioPiece.upperCoverageLevel);
  }

  if (uniquePlans.size === 1 && uniqueUpperCoverageLevels.size === 1) {
    const areOptionsValid = scenarios.every(s => isNullOrUndefined(optionsByScenarioId.get(s.scenarioId)?.find(x => x.option === OptionCode.LP || x.option === OptionCode.HB)));
    if (!areOptionsValid) {
      validationResult.message = 'If all plans and upper coverage levels match then LP or HB cannot be added as options.';
      validationResult.isValid = false;
      validationResult.title = 'Invalid Options Selected';
    }
  } else if (uniquePlans.size === 1 || uniqueUpperCoverageLevels.size > 1) {
    // When the plans match, and the upper coverage levels do not match either LP or HB options must be listed on each scenario.

    const doSomeContainHB = doSomeScenariosContainOption(scenarios, OptionCode.HB, optionsByScenarioId);
    const doAllContainHB = doAllScenariosContainOption(scenarios, OptionCode.HB, optionsByScenarioId);
    const doSomeContainLP = doSomeScenariosContainOption(scenarios, OptionCode.LP, optionsByScenarioId);
    const doAllContainLP = doAllScenariosContainOption(scenarios, OptionCode.LP, optionsByScenarioId);

    if (!doSomeContainLP && !doSomeContainHB) {
      validationResult.message = 'If plans match but upper coverage levels do not match then LP or HB option needs to be on all scenarios.';
      validationResult.isValid = false;
      validationResult.title = 'LP Required';
    } else {
      const isLPRequirementNotMet = doSomeContainLP && !doAllContainLP;
      const isHBRequirementNotMet = doSomeContainHB && !doAllContainHB;
      if (isLPRequirementNotMet || isHBRequirementNotMet) {
        validationResult.message = 'You have selected the LP or HB option on one scenario, while another scenario with a different practice and coverage level is missing the LP or HB option.';
        validationResult.isValid = false;
        validationResult.title = 'LP Missing';
      }
    }
  }

  return validationResult;
};

const doesOptionExistInScenario = (scenario: RowCropScenario, option: string, optionsByScenarioId: Map<ScenarioId, ScenarioOption[]>) => {
  const options = optionsByScenarioId.get(scenario.scenarioId) ?? [];
  return options.some(x => x.option === option);
};

const doAllScenariosContainOption = (scenarios: RowCropScenario[], option: string, optionsByScenarioId: Map<ScenarioId, ScenarioOption[]>) => {
  return scenarios.every(scenario => doesOptionExistInScenario(scenario, option, optionsByScenarioId));
};

const doSomeScenariosContainOption = (scenarios: RowCropScenario[], option: string, optionsByScenarioId: Map<ScenarioId, ScenarioOption[]>) => {
  return scenarios.some(scenario => doesOptionExistInScenario(scenario, option, optionsByScenarioId));
};

/**
 * The scenario parent plan must be the same e.g. you cannot have IN - Allen County - Corn - RP and IN - Allen County – Corn - ARP plans as it would be double
 * covering the same crop. The term parent here is meant to exclude add-ons such as ECO, SCO, HIP, and STAX.
 */
const validateBasePlans = (scenarios: RowCropScenario[], allRowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>) => {
  const validationResult: CoverageValidationResult = { title: '', message: '', isValid: true };
  const uniqueScenarioPieceTypes = new Map<ScenarioPieceType, RowCropScenarioPiece>();
  for (const scenario of scenarios) {
    const scenarioPieces = allRowCropScenarioPiecesByScenario.get(scenario.scenarioId) ?? [];
    const scenarioPieceTypes = scenarioPieces.map(x => x.scenarioPieceType);
    const basePieceTypes = scenarioPieceOrderingServiceInstance.getOptionalBaseScenarioPieces(scenarioPieceTypes);
    const baseScenarioPiece = scenarioPieces.find(sp => basePieceTypes.includes(sp.scenarioPieceType));
    if (isNullOrUndefined(baseScenarioPiece)) continue;
    const scenarioPieceType = baseScenarioPiece.scenarioPieceType;
    if (uniqueScenarioPieceTypes.size > 0 && !uniqueScenarioPieceTypes.has(scenarioPieceType)) {
      if (scenarioPieceType !== ScenarioPieceType.RP && scenarioPieceType !== ScenarioPieceType.RpHpe && scenarioPieceType !== ScenarioPieceType.YP) {
        validationResult.message = 'You cannot have different plan types for scenarios for the same state/county/crops.';
        validationResult.isValid = false;
        validationResult.title = 'Invalid Plan Types';
      }

      const ypPiece = scenarioPieceType === ScenarioPieceType.YP ? baseScenarioPiece : uniqueScenarioPieceTypes.get(ScenarioPieceType.YP);
      const rpPiece = scenarioPieceType === ScenarioPieceType.RP ? baseScenarioPiece : uniqueScenarioPieceTypes.get(ScenarioPieceType.RP) ?? uniqueScenarioPieceTypes.get(ScenarioPieceType.RpHpe);
      if (isNullOrUndefined(ypPiece) || isNullOrUndefined(rpPiece)) {
        validationResult.message = 'You cannot have different plan types for scenarios for the same state/county/crops.';
        validationResult.isValid = false;
        validationResult.title = 'Invalid Plan Types';
      } else {
        const rpScenario = scenarios.find(x => x.scenarioId === rpPiece.scenarioId);
        const ypScenario = scenarios.find(x => x.scenarioId === ypPiece.scenarioId);
        // Exception to the rule here -  If the insured wants to cover their high-risk ground separately, 2 different plans are allowed.
        // The insured can have RP or RP-HPE as well as YP on a crop if the YP is the same or lower UpperCoverageLevel than the RP/RP-HPE.
        if (rpScenario?.highRiskTypeId === HighRiskType.URA && ypScenario?.highRiskTypeId !== HighRiskType.URA && ypPiece.upperCoverageLevel <= rpPiece.upperCoverageLevel) {
          continue;
        }

        if (rpScenario?.highRiskTypeId === HighRiskType.URA && ypScenario?.highRiskTypeId !== HighRiskType.URA && ypPiece.upperCoverageLevel >= rpPiece.upperCoverageLevel) {
          validationResult.message = 'YP Coverage level must be less than or equal to the RP/RPHPE plan.';
          validationResult.isValid = false;
          validationResult.title = 'Invalid YP Coverage Level';
        } else {
          validationResult.message = 'Cannot insure the same high risk ground with different plans.';
          validationResult.isValid = false;
          validationResult.title = 'Invalid Plan Types';
        }
      }
    }

    uniqueScenarioPieceTypes.set(scenarioPieceType, baseScenarioPiece);
  }
  return validationResult;
};