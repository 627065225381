import MESSAGES from './messages';
import { Nullable } from '../types/util/Nullable';

type validationCallback<T> = (value: T) => boolean | string;

const VALIDATION_RULES = {
  required: () => MESSAGES.required,
  maxLength: (maxLength: number) => {
    return {
      value: maxLength,
      message: MESSAGES.maxLength(maxLength),
    };
  },
  minimum: (minimum: number) => {
    return {
      value: minimum,
      message: MESSAGES.minimum(minimum),
    };
  },
  maximum: (maximum: number) => {
    return {
      value: maximum,
      message: MESSAGES.maximum(maximum),
    };
  },
  restrictDefaultValue: <T>(defaultValue: T) => {
    return (value: T) => value !== defaultValue || MESSAGES.selectAnOption;
  },
  restrictNull: <T>() => {
    return (value: T) => value !== null || MESSAGES.selectAnOption;
  },
  restrictNotOnListValue: <T>(listOfValues: T[]) => {
    return (value: T) => listOfValues.includes(value) || MESSAGES.selectAnOption;
  },
  restrictNotOnListValueNullable: <T>(listOfValues: T[]) => {
    return (value: Nullable<T>) => (value !== null && listOfValues.includes(value)) || MESSAGES.selectAnOption;
  },
  restrictMinDefaultValue: (defaultValue: number, isFieldDisabled: boolean = false) => {
    return (value: number) => value !== defaultValue || isFieldDisabled ||  MESSAGES.greaterThan(defaultValue);
  },
  validateMultipleRules: <T>(validators: validationCallback<T>[]) => {
    return (value: T) => {
      for (const validator of validators) {
        const result = validator(value);
        if (result !== true) {
          return result;
        }
      }
      return true;
    };
  },
} as const;

export default VALIDATION_RULES;
