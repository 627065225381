import { Button, ButtonGroup, Card, Divider, List, ListItemButton, ListItemText, PaletteMode, TypographyProps } from '@mui/material';
import UserInfo from './userInfo.component';
import { useMsal } from '@azure/msal-react';
import { logoutRequest } from '../../authConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { modifyPaletteModeSettingsForUser, selectIsLightMode } from '../../app/userSettingsSlice';
import { PaletteModeSettings } from '../../types/api/userSettings/PaletteModeSettings';
import ManageTemplateScenariosModal from '../../pages/templateScenarios/manageTemplateScenarios/manageTemplateScenariosModal.component';
import { useState } from 'react';

type UserMenuProps = {
  close: () => void;
}

const UserMenu = ({ close }: UserMenuProps) => {
  const dispatch = useAppDispatch();
  const isLightMode = useAppSelector(selectIsLightMode);
  const { instance, accounts } = useMsal();
  const [isManageTemplateScenarioModalOpen, setIsManageTemplateScenarioModalOpen] = useState(false);

  const handleSignOutClick = () => {
    instance.logoutPopup(logoutRequest(accounts[0].homeAccountId));
  };

  const setPaletteMode = async (mode: PaletteMode) => {
    const paletteModeSettings: PaletteModeSettings = { paletteMode: mode };
    await dispatch(modifyPaletteModeSettingsForUser({ paletteModeSettings: paletteModeSettings }));
    close();
  };

  return (
    <>
      <Card sx={{ mt: 0.65, zIndex: theme => theme.zIndex.drawer + 2 }}>
        <UserInfo />
        <Divider />
        <ButtonGroup sx={{ p: .5 }} aria-label="outlined primary button group">
          <Button onClick={() => setPaletteMode('light')} color="secondary" variant={isLightMode ? 'contained' : 'outlined'} id="btn-light-mode" disableElevation>Light Mode</Button>
          <Button onClick={() => setPaletteMode('dark')} color="secondary" variant={!isLightMode ? 'contained' : 'outlined'} id="btn-dark-mode" disableElevation>Dark Mode</Button>
        </ButtonGroup>
        <Divider />
        <List disablePadding>
          <ListItemButton onClick={() => setIsManageTemplateScenarioModalOpen(true)}>
            <ListItemText primaryTypographyProps={buttonTextTypography} primary="Manage Scenario Templates" />
          </ListItemButton>
          <ListItemButton onClick={handleSignOutClick}>
            <ListItemText primaryTypographyProps={buttonTextTypography} primary="Sign Out" />
          </ListItemButton>
        </List>
      </Card>

      {isManageTemplateScenarioModalOpen &&
        <ManageTemplateScenariosModal
          onClose={() => setIsManageTemplateScenarioModalOpen(false)}
        />
      }
    </>
  );
};

export default UserMenu;

const buttonTextTypography: TypographyProps = { variant: 'body2' };
