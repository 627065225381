import { Na } from '../../../types/util/Na';
import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import LabelValuePair, { LabelCurrencyPair } from '../../../components/labelValuePair/labelValuePair.component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatNumber } from '../../../utils/formatNumbers';
import './scenarioCard.styles.css';
import { Nullable } from '../../../types/util/Nullable';
import { calculatePercentDifference } from '@silveus/calculations';
import { ToggleableTypography } from '../../../components/toggleableTypography/toggleableTypography';

interface PriceYieldProps {
  plantPrice: number;
  harvestPrice: number;
  aph: Nullable<number>;
  productionYield: number;
  volatility: number;
  rateYield: Nullable<number>;
  approvedYield: Nullable<number>;
  trendYield: number;
  countyYield: number;
  acres: number;
  sharePercent: number;
  isReadonly: boolean;
}
const PriceYieldDetails = (priceYieldProps: PriceYieldProps) => {
  return (
    <Accordion disableGutters variant="elevation" square elevation={0} sx={{ backgroundColor: 'transparent', '&:before': { display: 'none' }  }}>
      <AccordionSummary className="price-yield-details-accordion-summary" expandIcon={<ExpandMoreIcon sx={{ color: theme => theme.palette.action.selected }} />} sx={{ pl: 1 }}>
        <ToggleableTypography disabled={priceYieldProps.isReadonly} variant="clickable2">Price, Producer, County Details</ToggleableTypography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item container spacing={1}>
            <Grid item container xs={12} alignItems="center" spacing={1}>
              <Grid item xs="auto"><ToggleableTypography color={theme => theme.palette.neutral.main} variant="body2">Prices</ToggleableTypography></Grid>
              <Grid item xs><Divider /></Grid>
            </Grid>
            <Grid item xs={1} />
            <Grid item container spacing={1} xs={11}>
              <Grid item xs={12}><LabelCurrencyPair disabled={priceYieldProps.isReadonly} label="Plant" value={priceYieldProps.plantPrice} /></Grid>
              <Grid item xs={12}><LabelCurrencyPair disabled={priceYieldProps.isReadonly} label="Harvest" value={priceYieldProps.harvestPrice} /></Grid>
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="Percent Change" abbreviation="% Chg" value={`${calculatePercentDifference(priceYieldProps.plantPrice, priceYieldProps.harvestPrice).toFixed(2)}%`} /></Grid>
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="Vol" value={priceYieldProps.volatility.toFixed(2)} /></Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item container xs={12} alignItems="center" spacing={1}>
              <Grid item xs="auto"><ToggleableTypography disabled={priceYieldProps.isReadonly} color={theme => theme.palette.neutral.main} variant="body2">Acreage</ToggleableTypography></Grid>
              <Grid item xs><Divider /></Grid>
            </Grid>
            <Grid item xs={1} />
            <Grid item container spacing={1} xs={11}>
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="Acres" value={formatNumber(priceYieldProps.acres, true)} /></Grid>
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="Share %" value={(priceYieldProps.sharePercent * 100).toFixed(2) + '%'} /></Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item container xs={12} alignItems="center" spacing={1}>
              <Grid item xs="auto"><ToggleableTypography color={theme => theme.palette.neutral.main} disabled={priceYieldProps.isReadonly} variant="body2">Individual & County Yields</ToggleableTypography></Grid>
              <Grid item xs><Divider /></Grid>
            </Grid>
            <Grid item xs={1} />
            <Grid item container spacing={1} xs={11}>
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="Actual Production History" abbreviation="APH" value={priceYieldProps.aph !== null ? formatNumber(priceYieldProps.aph, true) : Na} /></Grid>
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="Production Yield" abbreviation="Prod. Yield" value={formatNumber(priceYieldProps.productionYield, true)} /></Grid>
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="Percent Change" abbreviation="% Chg" value={`${calculatePercentDifference(priceYieldProps.aph, priceYieldProps.productionYield).toFixed(2)}%`} /></Grid>
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="Approved Yield" abbreviation="Appr. Yield" value={priceYieldProps.approvedYield !== null ? formatNumber(priceYieldProps.approvedYield, true) : Na} /></Grid>
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="Rate Yield" value={priceYieldProps.rateYield !== null ? formatNumber(priceYieldProps.rateYield, true) : Na} /></Grid>
              <Grid item xs={4} />
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="Trend Yield" value={formatNumber(priceYieldProps.trendYield, true)} /></Grid>
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="County Yield" abbreviation="Cnty. Yield" value={formatNumber(priceYieldProps.countyYield, true)} /></Grid>
              <Grid item xs={12}><LabelValuePair disabled={priceYieldProps.isReadonly} label="Percent Change" abbreviation="% Chg" value={`${calculatePercentDifference(priceYieldProps.trendYield, priceYieldProps.countyYield).toFixed(2)}%`} /></Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>);
};
export default PriceYieldDetails;
