import { Controller, useFormContext } from 'react-hook-form';
import { ExtendedDataFormInputParameterNames } from '../constants/inputConstants';
import { FormInputProps } from '../scenario/formInputProps';
import { Nullable } from '../../../types/util/Nullable';
import NumberTextField, { onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { Info } from '../../info/info';
import { validatePrice } from '../../../pages/units/validations/priceValidation';

export interface TopIntervalInputProps extends FormInputProps {
  topPrice: Nullable<number>;
}

export const TopIntervalInput = ({ sx, topPrice, autoFocus = false, disabled = false }: TopIntervalInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.TopPrice}
      control={control}
      rules={{ validate: (value: Nullable<number>) => validatePrice(value) }}
      defaultValue={topPrice}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          type="number"
          label="Top Interval"
          fullWidth
          value={value}
          inputProps={{ min: 0, maxLength: 8, step: .01 }}
          InputProps={{
            endAdornment: <Info tooltipContent="Top Interval - The highest price gain from selected intervals." />,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          sx={sx}
          disabled={disabled}
          size="small"
        />
      )}
    />
  );
};
