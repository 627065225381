import { quoterDb } from '../../db';
import {
  getMultiple,
  getDefaultCachingStrategy
} from '../offlineDataAccess.service';
import {
  deleteScenarioOptions,
  getScenarioOptions,
  getScenarioOptionsForClientFile, getScenarioOptionsForScenarios, getScenarioOptionUnitYearsForScenarios, setAllScenarioOptionUnitYearsForScenario, setScenarioOptions
} from '../options.service';
import ScenarioOption from '../../types/api/options/ScenarioOption';
import { ClientFileId, ScenarioId, ScenarioOptionId } from '../../types/api/PrimaryKeys';
import ScenarioOptionUnitYear from '../../types/api/options/ScenarioOptionUnitYear';
import { FlatScenarioOptionUnitYear } from '../../types/api/options/FlatScenarioOptionUnitYear';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { networkOnly, nonGetCacheOnly, nonGetNetworkOnly } from '../../utils/cachingStrategies';
import { setAllScenarioOptionUnitYearsForScenarioData } from '../localCalcDataQueries.service';
import { isOnlineFromStore } from '../../utils/isOnlineFromStore';
import { applySoftDelete } from '../../utils/dexieQueryHelpers/softDelete';

const table = quoterDb.scenarioOptions;

export const getScenarioOptionsRequest = async (scenarioId: ScenarioId): Promise<ScenarioOption[]> => {
  const request = () => getScenarioOptions(scenarioId);
  return await getMultiple(table, { scenarioId: scenarioId }, request);
};

export const getScenarioOptionsForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<ScenarioOption[]> => {
  const request = () => getScenarioOptionsForScenarios(scenarioIds);

  const readTransaction = () => quoterDb.transaction('r', quoterDb.scenarioOptions, async () => {
    const scenarioOptions = safeWhere(quoterDb.scenarioOptions, 'scenarioId').anyOf(scenarioIds).toArray();
    return scenarioOptions;
  });

  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const getScenarioOptionsForClientFileRequest = async (clientFileId: ClientFileId): Promise<ScenarioOption[]> => {
  const request = () => getScenarioOptionsForClientFile(clientFileId);

  const transactionTables = [quoterDb.rowCropScenarios, quoterDb.scenarioOptions];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const quotes = await safeWhere(quoterDb.quotes, { clientFileId: clientFileId }).toArray();
    const quoteIds = quotes.map(q => q.quoteId);
    const scenarios = await safeWhere(quoterDb.rowCropScenarios, 'quoteId').anyOf(quoteIds).toArray();
    const scenarioIds = scenarios.map(scen => scen.scenarioId);
    const scenarioOptions = safeWhere(quoterDb.scenarioOptions, 'scenarioId').anyOf(scenarioIds).toArray();
    return scenarioOptions;
  });

  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const getScenarioOptionUnitYearsForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<ScenarioOptionUnitYear[]> => {
  const request = () => getScenarioOptionUnitYearsForScenarios(scenarioIds);

  const transactionTables = [quoterDb.scenarioOptions, quoterDb.scenarioOptionUnitYears];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const scenarioOptions = await safeWhere(quoterDb.scenarioOptions, 'scenarioId').anyOf(scenarioIds).toArray();
    const scenarioOptionIds = scenarioOptions.map(so => so.scenarioOptionId);
    const scenarioOptionUnitYears = safeWhere(quoterDb.scenarioOptionUnitYears, 'scenarioOptionId').anyOf(scenarioOptionIds).toArray();
    return scenarioOptionUnitYears;
  });
  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const deleteScenarioOptionsRequest = async (scenarioOptionIds: ScenarioOptionId[]): Promise<void> => {
  const request = () => deleteScenarioOptions(scenarioOptionIds);

  const transactionTables = [
    quoterDb.scenarioOptions,
    quoterDb.scenarioOptionUnitYears,
  ];

  const updateTransaction = () => quoterDb.transaction('rw', transactionTables, async () => {
    const scenarioOptionsWhereClause = safeWhere(quoterDb.scenarioOptions, 'scenarioOptionId').anyOf(scenarioOptionIds);
    const scenarioOptionRemovalPromise = applySoftDelete(scenarioOptionsWhereClause);

    const scenarioOptionUnitYearsWhereClause = safeWhere(quoterDb.scenarioOptionUnitYears, 'scenarioOptionId').anyOf(scenarioOptionIds);
    const scenarioOptionUnitYearRemovalPromise = applySoftDelete(scenarioOptionUnitYearsWhereClause);

    await Promise.all([scenarioOptionRemovalPromise, scenarioOptionUnitYearRemovalPromise]);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;

  return await strategy(request, updateTransaction);
};

export const setAllScenarioOptionUnitYearsForScenarioRequest = async (scenarioId: ScenarioId, scenarioOptionUnitYears: FlatScenarioOptionUnitYear[]): Promise<void> => {
  const request = () => setAllScenarioOptionUnitYearsForScenario(scenarioId, scenarioOptionUnitYears);

  const transactionTables = [
    quoterDb.scenarioOptionUnitYears,
    quoterDb.scenarioOptions,
  ];

  const updateTransaction = () => quoterDb.transaction('rw', transactionTables, async () => {
    await setAllScenarioOptionUnitYearsForScenarioData(scenarioId, scenarioOptionUnitYears);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;

  return await strategy(request, updateTransaction);
};

export const setScenarioOptionsRequest = async (scenarioId: ScenarioId, scenarioOptions: ScenarioOption[], scenarioOptionUnitYears: ScenarioOptionUnitYear[]): Promise<void> => {
  const request = () => setScenarioOptions(scenarioId, { scenarioOptions, scenarioOptionUnitYears });

  return networkOnly(request);
};
