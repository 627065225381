import { selectAvailableTYields } from '../../app/admSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useTaFactors } from '../../hooks/useTrendAdjustmentFactors';
import { useHistoricalTYields } from '../../hooks/useHistoricalTYields';
import { yieldCalculator } from './yieldCalculator';
import { Quote } from '../../types/api/Quote';
import UnitYear from '../../types/api/UnitYear';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import { OptionCode } from '@silveus/calculations';
import UnitYearAph from '../../types/api/UnitYearAph';
import { Nullable } from '../../types/util/Nullable';
import { YeYear } from '../../types/api/adm/YeYears';

export const useYieldCalculator = () => {
  const tYields = useAppSelector(selectAvailableTYields);
  const historicalTYields = useHistoricalTYields();
  const taFactors = useTaFactors();

  return {
    forSingleUnitYear: (quote: Quote, unitYear: UnitYear, planCode: Nullable<string>,
      unitYearAph: readonly UnitYearAph[], scenarioUnitYearAph: readonly ScenarioUnitYearAph[],
      selectedOptionCodes: OptionCode[], yeYears: YeYear[]) => {
      const applicableTaFactors = taFactors(quote.countyId, quote.commodityCode).allTaFactors;
      const applicableTYields = historicalTYields(quote.countyId, quote.commodityCode).allTYields;
      const calculator = yieldCalculator(tYields, planCode, applicableTaFactors, applicableTYields);
      return calculator.forSingleUnitYear(unitYear, unitYearAph, scenarioUnitYearAph, selectedOptionCodes, yeYears);
    },

    forMultipleUnitYears: (quote: Quote, planCode: Nullable<string>, unitYearMap: Map<UnitYear, {
      unitYearAph: UnitYearAph[], scenarioUnitYearAph: ScenarioUnitYearAph[], optionCodes: OptionCode[],
      yeYears: YeYear[]
    }>) => {
      const applicableTaFactors = taFactors(quote.countyId, quote.commodityCode).allTaFactors;
      const applicableTYields = historicalTYields(quote.countyId, quote.commodityCode).allTYields;
      const calculator = yieldCalculator(tYields, planCode, applicableTaFactors, applicableTYields);

      return calculator.forMultipleUnitYears(unitYearMap);
    },
  };
};

