import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import { validateEmailString } from '../../ag-grid/contactInfoGrid/validation/validateContactInfo';

type InsuredPhoneNumberInputProps = {
  value: Nullable<string>;
  disabled: boolean;
  required?: boolean;
  name?: string;
}

export type InsuredEmailFields = {
  email: Nullable<string>;
}

const InsuredEmailInput = ({ value, disabled, required = false, name = 'email' }: InsuredPhoneNumberInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: val => validateEmailString(val, required) }}
      defaultValue={value ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="email"
          label="Email"
          fullWidth
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          size="small"
        />
      )}
    />
  );
};

export default InsuredEmailInput;

