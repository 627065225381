import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import NumberTextField, { getRoundedValue, onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectHarvestPriceToolTip } from '../../../app/admSlice';
import { Quote } from '../../../types/api/Quote';
import { RowCropScenario } from '../../../types/api/RowCropScenario';

import { FormInputProps } from './formInputProps';
import { validatePrice } from '../../../pages/units/validations/priceValidation';
import usePercentChange from '../../../hooks/usePercentChange';
import { useCommodityOptionBasedPrecisionForScenario } from '../../../hooks/useCommodityOptionBasedPrecision';
import { PriceInputTooltip } from './priceInputTooltip';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { MissingClientFileInStateError } from '../../../errors/state/MissingStateErrors';
import OptionState from '../../../types/app/OptionState';

export interface HarvestPriceProps extends FormInputProps {
  harvestPrice: Nullable<number>;
  projectedPriceField: string;
  label?: string;
  quote: Quote;
  scenario: Nullable<RowCropScenario>;
  scenarioOptionState: OptionState[];
}

export type HarvestPriceFields = {
  harvestPrice: number;
}

const HarvestPriceInput = ({ quote, scenario, scenarioOptionState, harvestPrice, autoFocus = false, sx, projectedPriceField, label, disabled }: HarvestPriceProps) => {
  const { control } = useFormContext();
  usePercentChange(projectedPriceField, 'harvestPrice', 'priceSlider');

  const type = useWatch({ name: 'typeId', control: control, defaultValue: undefined });
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });
  const clientFile = useAppSelector(state => selectClientFileById(state, quote.clientFileId));
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  const harvestPriceHintLines = useAppSelector(state => selectHarvestPriceToolTip(
    state,
    quote.countyId,
    quote.commodityCode,
    scenario?.typeId ?? type ?? '',
    scenario?.practiceId ?? practice ?? '',
    clientFile.year,
    scenario?.scenarioId ?? null,
  ));


  const { precision, step } = useCommodityOptionBasedPrecisionForScenario(quote.commodityCode, scenarioOptionState);

  return (
    <Controller
      name="harvestPrice"
      control={control}
      rules={{ validate: (value: Nullable<number>) => validatePrice(value) }}
      defaultValue={getRoundedValue(harvestPrice, precision) ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          value={getRoundedValue(field.value, precision)}
          type="number"
          label={label ?? 'Harvest Price'}
          fullWidth
          inputProps={{ step: step, min: 0, shrink: !isNullOrUndefined(field.value) }}
          InputProps={{
            endAdornment: <PriceInputTooltip priceHintLines={harvestPriceHintLines} />,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          autoFocus={autoFocus}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          sx={sx}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default HarvestPriceInput;
