import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';

interface IncludeMissingYearsInputProps {
  calculateMissingYears: boolean;
}

export type IncludeMissingYearsFields = {
  calculateMissingYears: boolean;
};

const IncludeMissingYearsInput = ({ calculateMissingYears }: IncludeMissingYearsInputProps) => {

  return (
    <Controller
      name="calculateMissingYears"
      defaultValue={calculateMissingYears}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl
          error={!!error}>
          <FormControlLabel
            label="Calculate Products for missing historical years"
            control={
              <Checkbox
                {...field}
                checked={value}
                inputRef={ref}
                onChange={e => field.onChange(e.target.checked)}
                size="small"
              />
            }
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default IncludeMissingYearsInput;