import { useEffect, useRef, useState } from 'react';
import { Grid, InputAdornment, Slider } from '@mui/material';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { Nullable } from '../../types/util/Nullable';
import VALIDATION_RULES from '../../constants/formValidationRules';
import NumberTextField from '../numberTextField/numberTextField.component';
import { roundToPlaces, calculatePercentDifference } from '@silveus/calculations';
import { FormInputProps } from './scenario/formInputProps';
import { isNullOrUndefined } from '../../utils/nullHandling';

interface SliderWithTextBoxProps extends FormInputProps {
  estimatedValueFieldName: string;
  actualValueFieldName: string;
  sliderName: string;
  initialEstimatedValue: Nullable<number>;
  initialActualValue: Nullable<number>
}

const PercentChangeSliderInput = ({ estimatedValueFieldName, actualValueFieldName, sliderName, initialEstimatedValue, initialActualValue, autoFocus = false, disabled }: SliderWithTextBoxProps) => {
  const { control, getValues, setValue } = useFormContext();
  const maxValue = 100;
  const minValue = -100;

  //In order to present a clean number to the user, and to prevent tons of update events going to the connected fields,
  //we are keeping track of a rounded version of the actual % change value here.
  //This is only used internally for display in the text box and easier stepping in the slider.
  //The raw % change is bound to a field controlled by the react-hook-form Controller and is sent to the connected fields on update to
  //allow for accurate % change calculations.
  const initialPercentDifference = roundToPlaces(calculatePercentDifference(initialEstimatedValue, initialActualValue), 2);
  const [roundedValue, setRoundedValue] = useState(initialPercentDifference.toString());
  const valueOnFocus = useRef('');

  const roundNewValue = (newValue: number): number => {
    const rounded = roundToPlaces(newValue, 2);
    setRoundedValue(rounded.toString());
    return rounded;
  };

  const areValuesInvalid = (valueOne: Nullable<number>, valueTwo: Nullable<number>): boolean => {
    return isNullOrUndefined(valueOne) || Number.isNaN(valueOne) || isNullOrUndefined(valueTwo) || Number.isNaN(valueTwo);
  };

  const estimatedValue = useWatch({ name: estimatedValueFieldName, control: control, defaultValue: initialEstimatedValue });
  const actualValue = useWatch({ name: actualValueFieldName, control: control, defaultValue: initialActualValue });

  useEffect(() => {
    const tempEstimatedValue = getValues(estimatedValueFieldName);
    const tempActualValue = getValues(actualValueFieldName);
    if (areValuesInvalid(tempEstimatedValue, estimatedValue) || areValuesInvalid(tempActualValue, actualValue)) {
      return;
    }

    const calculatedValue = calculatePercentDifference(tempEstimatedValue, tempActualValue);
    roundNewValue(calculatedValue);
  }, [estimatedValue, actualValue]);

  /*
    Using a placeholder name of a GUID for the Controller because we are manually managing
    the component's state and don't want it to be saved automatically. This allows us to
    handle the value changes and saving behavior ourselves.
  */
  return (
    <Controller
      name={'7F5B9ADA1B4343DD94B318D7A825362E'}
      control={control}
      rules={{ min: VALIDATION_RULES.minimum(minValue) }}
      defaultValue={calculatePercentDifference(initialEstimatedValue, initialActualValue)}
      render={({
        field, fieldState: { error },
      }) => (
        <Grid container columnSpacing={2} alignItems="center">
          <Grid item xs={7}>
            <Slider
              {...field}
              step={1}
              min={minValue}
              max={maxValue}
              value={parseInt(roundedValue)}
              onChange={(_, value) => {
                if (typeof (value) === 'number') {
                  roundNewValue(value);
                }
              }}
              onChangeCommitted={(_, value) => {
                field.onChange(value);
                setValue(sliderName, value);
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={5}>
            <NumberTextField
              {...field}
              value={roundedValue}
              type="number"
              variant="standard"
              size="small"
              inputProps={{ step: 0.01 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
              onChange={e => {
                setRoundedValue(e.target.value);
              }}
              onFocus={e => {
                valueOnFocus.current = e.target.value;
              }}
              onBlur={e => {
                const parsedValue = parseFloat(e.target.value);
                if (isNaN(parsedValue)) {
                  setRoundedValue('0');
                  setValue(sliderName, 0);
                  field.onChange(0);
                } else {
                  const localRoundedValue = roundNewValue(parsedValue);
                  if (valueOnFocus.current !== e.target.value) {
                    // prevents changing values on tab in and tab out
                    // if value doesn't change
                    setValue(sliderName, localRoundedValue);
                    field.onChange(localRoundedValue);
                  }
                }
              }}
              autoFocus={autoFocus}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default PercentChangeSliderInput;
