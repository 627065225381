import { ScenarioId } from '../../types/api/PrimaryKeys';
import { Nullable } from '../../types/util/Nullable';
import ScenarioPieceForm from './scenarioPieceForm.component';
import * as uuid from 'uuid';
import { DefaultFormSelection, FormWrapperProps } from '../../components/formWrapper/formWrapper.component';

import TabbedFormWrapper from '../../components/formWrapper/tabbedFormWrapper.component';
import { useGetScenarioPieceForms } from './useGetScenarioPieceForms';

type ScenarioPieceFormWrapperProps = FormWrapperProps & {
  scenarioId: Nullable<ScenarioId>;
  defaultFormSelection?: DefaultFormSelection;
}

const ScenarioPieceFormWrapper = ({ scenarioId, registerHeader, defaultFormSelection, handleValidation, isCanceling = false }: ScenarioPieceFormWrapperProps) => {
  const { orderedScenarioPieceForms } = useGetScenarioPieceForms(scenarioId);

  //TODO: 62522: come back to this when formWrapper is fixed/refactored for its tabs to work better

  const newScenarioPieceForm = (props: FormWrapperProps) => {
    return scenarioId === null ? <></> : <ScenarioPieceForm scenarioId={scenarioId} scenarioPiece={null} key={uuid.v4()} {...props} />;
  };

  return (
    <TabbedFormWrapper
      forms={orderedScenarioPieceForms}
      newItemForm={newScenarioPieceForm}
      registerHeader={registerHeader}
      shouldScroll
      defaultFormSelection={defaultFormSelection}
      key={scenarioId ?? 'scenarioPieceFormWrapper'}
      handleValidation={handleValidation}
      isCanceling={isCanceling}
    />
  );
};

export default ScenarioPieceFormWrapper;
