import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { selectAllAnalysisMatrices, selectMatrixPresetByMatrixIdDictionary } from '../../../app/matricesSlice';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { selectSelectedMatrixScenarioId, setSelectedMatrixScenarioId } from '../../../app/scenarioAnalysisSlice';
import { useEffect, useState } from 'react';
import Matrix from '../../../types/api/Matrix';
import ScenarioAnalysisWrapper from '../ScenarioAnalysisWrapper';
import MatrixAnalysisScenarioCard from './matrixAnalysisScenarioCard.component';
import MatrixCard from '../../analysis/components/matrixCard.component';
import { ScenarioChip } from '../../../components/scenarioChip/scenarioChip';
import { selectMatrixPresets } from '../../../app/userSettingsSlice';
import { SigReportDocumentType } from '../../../constants/sigReportDocumentType';
import { generateMatrixReport } from '../../../app/reportsSlice';
import { getDefaultMatrixPreset } from '../../../types/settings/SystemMatrixPresets';
import { buildMatrixCellDataForReports } from '../../../components/reports/utils/getMatrixTableData';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { getMatrixReportData } from '../../../components/reports/utils/getMatrixReportData';
import { getItemsForId } from '../../../utils/mapHelpers';
import { selectCurrentClientFile } from '../../../app/clientFilesSlice';
import { selectCurrentAgencyInformation, selectCurrentAgentInformation } from '../../../app/agentSlice';
import { selectAllScenariosByClientFileIdMap } from '../../../app/scenariosSlice';
import { stableEmptyArrayAsMutable } from '../../../utils/stableEmptyArray';
import {
  useGetSelectedScenarioRequestsForScenarios,
  useUniqueScenarioPieceTypesForScenarios
} from '../../../hooks/scenarioHooks';
import { useMatrixCellTextColorSettings } from '../../../hooks/matrix/useMatrixCellTextColorSettings';
import { getKeyedStateToMap } from '../../../app/sliceHelpers';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../../app/scenarioPiecesSlice';
import { selectAllInputCostScenarioPiecesByScenarioMap } from '../../../app/inputCostScenarioPiecesSlice';
import { selectAllForwardSoldScenarioPiecesByScenarioMap } from '../../../app/forwardSoldScenarioPiecesSlice';
import { selectAllHarvestRevenueScenarioPiecesByScenarioMap } from '../../../app/harvestRevenueScenarioPiecesSlice';
import { selectAdjustedYieldForScenarios, selectApprovedYieldForScenarios } from '../../../app/unitsSlice';
import { selectCalculationsByScenarioPiece } from '../../../app/calculationResultsSlice';
import { getPrecisionForCommodityCodeAndOptions } from '../../../utils/priceUtils';
import { selectAllScenarioOptionsByScenarioIdMap } from '../../../app/optionsSlice';
import { Quote } from '../../../types/api/Quote';
import { selectQuotesByClientFileId } from '../../../app/quotesSlice';

type MatrixAnalysisProps = {
  allScenariosForView: RowCropScenario[];
}

const MatrixAnalysis = ({ allScenariosForView }: MatrixAnalysisProps) => {
  const dispatch = useAppDispatch();
  const [collapsedHeader, setCollapsedHeader] = useState(false);
  const selectedScenarioId = useAppSelector(selectSelectedMatrixScenarioId);
  const globalMatricesByScenario = useAppSelector(selectAllAnalysisMatrices);
  const selectedMatrix = selectedScenarioId ? globalMatricesByScenario.get(selectedScenarioId) ?? null : null;
  const scenariosForCurrentView = allScenariosForView.filter(x => x.isVisible && globalMatricesByScenario.get(x.scenarioId)?.isActive);
  const selectedMatrixPresetsByMatrixIdState = useAppSelector(selectMatrixPresetByMatrixIdDictionary);
  const matrixPresets = useAppSelector(selectMatrixPresets);

  const scenarioOptionsByScenarioId = useAppSelector(selectAllScenarioOptionsByScenarioIdMap);

  const clientFile = useAppSelector(selectCurrentClientFile);
  const agent = useAppSelector(selectCurrentAgentInformation);
  const agencyInfo = useAppSelector(selectCurrentAgencyInformation);
  const cellTextColorSettings = useMatrixCellTextColorSettings();

  const scenariosByClientFile = useAppSelector(selectAllScenariosByClientFileIdMap);
  const scenariosForClientFile = clientFile === null ? stableEmptyArrayAsMutable<RowCropScenario>() : getItemsForId(scenariosByClientFile, clientFile.clientFileId);
  const scenarioRequests = useGetSelectedScenarioRequestsForScenarios(scenariosForClientFile);
  const calcsByScenarioPiece = useAppSelector(selectCalculationsByScenarioPiece);

  const rowCropScenarioPiecesByScenario = useAppSelector(selectAllRowCropScenarioPiecesByScenarioMap);
  const inputCostScenarioPiecesByScenario = useAppSelector(selectAllInputCostScenarioPiecesByScenarioMap);
  const forwardSoldScenarioPiecesByScenario = useAppSelector(selectAllForwardSoldScenarioPiecesByScenarioMap);
  const harvestRevenueScenarioPiecesByScenario = useAppSelector(selectAllHarvestRevenueScenarioPiecesByScenarioMap);

  const scenarioIdsForClientFile = scenariosForClientFile.map(s => s.scenarioId);
  const uniqueScenarioPieceTypesForScenarios = useUniqueScenarioPieceTypesForScenarios(scenarioIdsForClientFile);

  const approvedYieldByScenario = useAppSelector(state => selectApprovedYieldForScenarios(state, scenarioIdsForClientFile));
  const adjustedYieldByScenario = useAppSelector(state => selectAdjustedYieldForScenarios(state, scenarioIdsForClientFile));

  const quotes = useAppSelector(state => clientFile === null ? stableEmptyArrayAsMutable<Quote>() : selectQuotesByClientFileId(state, clientFile.clientFileId));

  const allMatricesForAllScenariosForView: Matrix[] = [];
  for (const scenario of scenariosForCurrentView) {
    const matrix = globalMatricesByScenario.get(scenario.scenarioId);
    if (matrix !== undefined && matrix.isActive) {
      allMatricesForAllScenariosForView.push(matrix);
    }
  }

  useEffect(() => {
    // Handle the cases where we either don't have a selected scenario or where our selected scenario does not
    // exist in the list of filtered scenarios. We want to pick the first scenario if one exists in those cases
    // so that something always displays on this page if something is available to display.
    if (scenariosForCurrentView.findIndex(x => x.scenarioId === selectedScenarioId) < 0) {
      if (scenariosForCurrentView.length > 0) {
        const firstScenario = scenariosForCurrentView[0];
        dispatch(setSelectedMatrixScenarioId(firstScenario.scenarioId));
      }
    }
  }, [scenariosForCurrentView, selectedScenarioId]);

  if (scenariosForCurrentView.length === 0) {
    return <Typography variant="subtitle1"><p>No matrices have been added</p></Typography>;
  }

  const printMatrix = async (existingMatrix: Matrix) => {
    if (clientFile === null) return;
    const scenario = scenariosForClientFile.find(scenario => scenario.scenarioId === existingMatrix.primaryScenarioId);
    if (scenario === undefined) return;

    const quote = quotes.find(quote => quote.quoteId === scenario.quoteId);
    if (quote === undefined) return;

    const scenarioOptionsForScenario = getItemsForId(scenarioOptionsByScenarioId, existingMatrix.primaryScenarioId);
    const optionsForScenario = scenarioOptionsForScenario.map(so => so.option);

    const projectedPricePrecision = getPrecisionForCommodityCodeAndOptions(quote.commodityCode, optionsForScenario);

    const approvedYield = approvedYieldByScenario.get(existingMatrix.primaryScenarioId) ?? null;
    const adjustedYield = adjustedYieldByScenario.get(existingMatrix.primaryScenarioId) ?? 0;

    const selectedMatrixPresetsByMatrixId = getKeyedStateToMap(selectedMatrixPresetsByMatrixIdState);
    const defaultMatrixPreset = matrixPresets.find(mp => mp.isDefault) ?? getDefaultMatrixPreset();
    const existingMatrixPresetId = selectedMatrixPresetsByMatrixId.get(existingMatrix.matrixId) ?? defaultMatrixPreset.matrixPresetId;
    const existingMatrixPreset = matrixPresets.find(mp => mp.matrixPresetId === existingMatrixPresetId) ?? defaultMatrixPreset;
    const sweetSpots = existingMatrix.sweetSpots.filter(ss => !ss.isHidden && ss.cellRange !== null && !ss.isForMatrixSummary);
    const includedScenarioIds = existingMatrix.scenarioMatrices.map(sm => sm.scenarioId);
    const includedScenarios = scenariosForClientFile.filter(s => includedScenarioIds.includes(s.scenarioId));
    const matrixData = await buildMatrixCellDataForReports(
      true,
      existingMatrixPreset,
      includedScenarios,
      scenarioRequests.filter(sr => includedScenarioIds.includes(toPrimaryKey<ScenarioId>(sr.id))),
      uniqueScenarioPieceTypesForScenarios,
      existingMatrix.midYield,
      existingMatrix.yieldScale,
      existingMatrix.midPrice,
      existingMatrix.priceScale,
      existingMatrix.bottomAxisOffsetType,
      existingMatrix.bottomAxisPercentChange,
      existingMatrix.bottomAxisIntegerOffset,
      existingMatrix.includeFilter,
      existingMatrix.showFilter,
      cellTextColorSettings,
      sweetSpots,
    );

    const matrixReportData = getMatrixReportData(
      clientFile.year,
      includedScenarios,
      existingMatrix.primaryScenarioId,
      SigReportDocumentType.Pdf,
      true,
      existingMatrixPreset,
      existingMatrix.showFilter,
      existingMatrix.includeFilter,
      sweetSpots,
      getItemsForId(rowCropScenarioPiecesByScenario, existingMatrix.primaryScenarioId),
      getItemsForId(inputCostScenarioPiecesByScenario, existingMatrix.primaryScenarioId)[0], // There should ever only be one per scenario
      getItemsForId(forwardSoldScenarioPiecesByScenario, existingMatrix.primaryScenarioId)[0], // There should ever only be one per scenario
      getItemsForId(harvestRevenueScenarioPiecesByScenario, existingMatrix.primaryScenarioId)[0], // There should ever only be one per scenario
      calcsByScenarioPiece,
      scenario.expectedCountyYield,
      approvedYield,
      adjustedYield,
      matrixData,
      projectedPricePrecision,
      agent,
      agencyInfo,
    );

    await dispatch(generateMatrixReport({ matrixReportData }));
  };

  return (
    <Grid item height="100%" wrap="nowrap" columnSpacing={2}>
      <ScenarioAnalysisWrapper
        collapsed={collapsedHeader}
        onToggleCollapse={() => setCollapsedHeader(prev => !prev)}
        headerItems={scenariosForCurrentView.map(s => {
          return (
            collapsedHeader ? (
              <Grid item xs="auto" key={s.scenarioId}>
                <Tooltip title={s.name} placement="top">
                  <ScenarioChip
                    backgroundColor={s.scenarioColor}
                    label={s.name}
                    sx={{ color: theme => theme.palette.common.white }}
                    onClick={() => dispatch(setSelectedMatrixScenarioId(s.scenarioId))}
                    maxWidth={400}
                  />
                </Tooltip>
              </Grid>
            ) : (
              <Grid item xs="auto" key={s.scenarioId}>
                <MatrixAnalysisScenarioCard
                  scenario={s}
                  isSelected={selectedScenarioId === s.scenarioId}
                  onCardClick={() => dispatch(setSelectedMatrixScenarioId(s.scenarioId))}
                />
              </Grid>
            ));
        })}
      >
        <Grid item xs sx={{ display: 'flex' }}>
          {allMatricesForAllScenariosForView.map(matrix => {
            // Note on what is going on here:
            // Previously, and ideally, we were only rendering a single matrix, the selected one.
            // However, through https://dev.azure.com/silveus/VNext/_workitems/edit/60038 we determined an issue where either scenarios or matrices
            // could be edited while another matrix was on screen. This would result in stale data being shown in the non-active scenario cards.
            // Because all of this state is currently tied to internal systems of the matrix, it must be rendered in order to send updates to the scenario card.
            // As what I'll call a hack, we are now rendering all matrices, but only displaying the selected one. This is not ideal, but it is the only solution that won't involve many days of work.
            // (Note that is there is no currently known alternative solution either).

            // Only the selected matrix will be displayed to the user. All others will still be rendered so we can get their functionality, but hidden.
            const shouldDisplayMatrix = selectedMatrix !== null && selectedMatrix.isActive && matrix.matrixId === selectedMatrix.matrixId;

            return (
              <Box key={matrix.matrixId} sx={{ height: '100%', display: shouldDisplayMatrix ? undefined : 'none' }}>
                <MatrixCard
                  isSelectedItem={false}
                  matrix={matrix}
                  hideLinkButton={true}
                  printMatrix={printMatrix}
                />
              </Box>
            );
          })
          }
        </Grid>
      </ScenarioAnalysisWrapper>
    </Grid>
  );
};

export default MatrixAnalysis;