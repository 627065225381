import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import NumberTextField, { getRoundedValue, onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { useAppSelector } from '../../../hooks/reduxHooks';
import {
  selectProjectedPriceToolTip
} from '../../../app/admSlice';
import { Quote } from '../../../types/api/Quote';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { validatePrice } from '../../../pages/units/validations/priceValidation';
import { FormInputProps } from './formInputProps';
import { useCommodityOptionBasedPrecisionForScenario } from '../../../hooks/useCommodityOptionBasedPrecision';
import { PriceInputTooltip } from './priceInputTooltip';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { MissingClientFileInStateError } from '../../../errors/state/MissingStateErrors';
import OptionState from '../../../types/app/OptionState';

export interface ProjectedPriceProps extends FormInputProps {
  quote: Quote;
  scenario: Nullable<RowCropScenario>;
  scenarioOptionState: OptionState[];
  projectedPrice: Nullable<number>;
}

export type ProjectedPriceFields = {
  projectedPrice: number;
}

const ProjectedPriceInput = ({ quote, scenario, scenarioOptionState, projectedPrice, sx, autoFocus = false }: ProjectedPriceProps) => {
  const { control } = useFormContext();
  const type = useWatch({ name: 'typeId', control: control, defaultValue: undefined });
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });
  const clientFile = useAppSelector(state => selectClientFileById(state, quote.clientFileId));
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  const { precision, step } = useCommodityOptionBasedPrecisionForScenario(quote.commodityCode, scenarioOptionState);

  const projectedPriceHintLines = useAppSelector(state => selectProjectedPriceToolTip(
    state,
    quote.countyId,
    quote.commodityCode,
    scenario?.typeId ?? type ?? '',
    scenario?.practiceId ?? practice ?? '',
    clientFile.year,
    scenario?.scenarioId ?? null,
  ));

  return (
    <Controller
      name="projectedPrice"
      control={control}
      rules={{ validate: (value: Nullable<number>) => validatePrice(value) }}
      defaultValue={getRoundedValue(projectedPrice, precision) ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          type="number"
          label="Projected Price"
          fullWidth
          inputProps={{ step: step, min: 0 }}
          InputProps={{
            endAdornment: <PriceInputTooltip priceHintLines={projectedPriceHintLines} />,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          sx={sx}
          {...field}
          value={getRoundedValue(field.value, precision)}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default ProjectedPriceInput;
