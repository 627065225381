import { Nullable } from '../../../types/util/Nullable';
import ScenarioQuickUnit from '../../../types/api/ScenarioQuickUnit';
import { InsuredId, UnitYearId } from '../../../types/api/PrimaryKeys';
import { ScenarioRequestDTO } from '@silveus/calculations';
import UnitYear from '../../../types/api/UnitYear';
import UnitYearAph from '../../../types/api/UnitYearAph';
import { ReportUnitData } from '../../../types/api/reports/ReportUnitData';
import { getItemsForId } from '../../../utils/mapHelpers';

export const getReportUnitData = (
  quickQuote: boolean,
  quickUnit: Nullable<ScenarioQuickUnit>,
  year: number,
  insuredId: InsuredId,
  scenarioRequest: Nullable<ScenarioRequestDTO>,
  units: UnitYear[],
  unitHistoryByUnitYear: Map<UnitYearId, UnitYearAph[]>,
): ReportUnitData[] => {
  let reportUnits: ReportUnitData[];

  if (quickQuote && quickUnit !== null) {
    const reportUnitData: ReportUnitData = {
      acres: quickUnit.acres,
      aph: quickUnit.aphYield,
      basicUnitNumber: '0001',
      commodityCode: quickUnit.commodityCode,
      countyId: quickUnit.countyId,
      farmName: null,
      farmNumber: null,
      history: [],
      insuredId: insuredId,
      location: null,
      offlineCreatedOn: undefined,
      offlineDeletedOn: undefined,
      offlineLastUpdatedOn: undefined,
      optionalUnitNumber: '0001',
      practiceId: quickUnit.practiceId,
      priorYearApprYield: null,
      range: null,
      section: null,
      sharePercent: quickUnit.sharePercent,
      subCountyCode: null,
      taYeYield: quickUnit.approvedYield,
      township: null,
      typeId: quickUnit.typeId,
      unitId: quickUnit.scenarioQuickUnitId,
      unitYearId: quickUnit.scenarioQuickUnitId as unknown as UnitYearId,
      year: year,

    };

    reportUnits = [reportUnitData];
  } else {
    //Flat map all the unit request DTOs for all the scenario pieces into a single array
    const unitRequests = scenarioRequest?.scenarioPieceGroups.flatMap(spg => spg.scenarioPieces).flatMap(sp => sp.unitGroups).flatMap(ug => ug.units) ?? [];

    reportUnits = units.map(unit => {
      const matchingUnitRequest = unitRequests.find(ur => ur.id === unit.unitYearId);

      const reportUnitData: ReportUnitData = {
        acres: unit.acres,
        aph: matchingUnitRequest?.adjustedYield ?? 0,
        basicUnitNumber: unit.basicUnitNumber,
        commodityCode: unit.commodityCode,
        countyId: unit.countyId,
        farmName: unit.farmName,
        farmNumber: unit.farmNumber,
        history: getItemsForId(unitHistoryByUnitYear, unit.unitYearId),
        insuredId: unit.insuredId,
        location: unit.location,
        offlineCreatedOn: unit.offlineCreatedOn,
        offlineDeletedOn: unit.offlineDeletedOn,
        offlineLastUpdatedOn: unit.offlineLastUpdatedOn,
        optionalUnitNumber: unit.optionalUnitNumber,
        practiceId: unit.practiceId,
        priorYearApprYield: unit.priorYearApprYield,
        range: unit.range,
        section: unit.section,
        sharePercent: unit.sharePercent,
        subCountyCode: unit.subCountyCode,
        taYeYield: matchingUnitRequest?.approvedYield ?? 0,
        township: unit.township,
        typeId: unit.typeId,
        unitId: unit.unitId,
        unitYearId: unit.unitYearId,
        year: unit.year,
      };

      return reportUnitData;
    });
  }

  return reportUnits;
};