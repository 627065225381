import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { RootState } from '../../app/store';
import { selectQuoteById } from '../../app/quotesSlice';
import { selectClientFileById } from '../../app/clientFilesSlice';
import { UnitGroup } from '../../types/api/UnitGroup';
import { generatePrimaryKey, toPrimaryKey } from '../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../types/api/PrimaryKeys';
import { getItemsForId } from '../../utils/mapHelpers';
import { selectAllUnitGroupsByScenarioPieceMap } from '../../app/unitGroupsSlice';
import BaseUnit from '../../types/api/BaseUnit';
import { CalcParamRequestUnit } from '../../types/api/calculationData/calcUnit';
import { distinctBy } from '../../utils/arrayUtils';
import { CalculationParamsBase } from '../../types/api/calculationData/calculationParamsBase';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../errors/state/MissingStateErrors';

export const getCalcUnitGroupsForScenarioPiece = (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState) => {
  //Get the unit groups that are applicable for the scenario piece
  //If this is a quick quote, we need to create a fake unit group using the unit structure of the scenario piece
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  //Determine what unit groups apply to this scenario piece
  let unitGroupsForScenarioPiece: UnitGroup[] = [];

  if (quote.quickQuote) {
    const fakeUnitGroup: UnitGroup = {
      unitGroupId: generatePrimaryKey(),
      scenarioPieceId: scenarioPiece.scenarioPieceId,
      year: clientFile.year,
      unitStructure: scenarioPiece.unitStructure,
      unitYearIds: [toPrimaryKey<UnitYearId>(scenario.quickUnit?.scenarioQuickUnitId ?? '')],
      offlineCreatedOn: undefined,
      offlineLastUpdatedOn: undefined,
      offlineDeletedOn: undefined,
    };

    unitGroupsForScenarioPiece.push(fakeUnitGroup);
  } else {
    unitGroupsForScenarioPiece = getItemsForId(selectAllUnitGroupsByScenarioPieceMap(state), scenarioPiece.scenarioPieceId);
  }

  return unitGroupsForScenarioPiece;
};

export const getUnitsForScenarioPiece = (unitGroupsForScenarioPiece: UnitGroup[], baseUnits: BaseUnit[]) => {
  //Filter all of the base units down to just the applicable ones
  const unitIdsForScenarioPiece = unitGroupsForScenarioPiece.flatMap(ug => ug.unitYearIds);
  const unitsForScenarioPiece = baseUnits.filter(bu => unitIdsForScenarioPiece.includes(toPrimaryKey<UnitYearId>(bu.id)));

  return unitsForScenarioPiece;
};

export const getCalcParamRequestUnits = (baseUnits: BaseUnit[]): CalcParamRequestUnit[] => {
  const distinctBaseUnits = distinctBy(baseUnits, u => `${u.countyId}${u.typeId}${u.practiceId}${u.commodityCode}${u.subCountyCode}`);
  const calcParamRequestUnits = distinctBaseUnits.flatMap(baseUnit => convertUnit(baseUnit));

  return calcParamRequestUnits;
};

const convertUnit = (baseUnit: BaseUnit): CalcParamRequestUnit => {
  return {
    practiceId: baseUnit.practiceId,
    typeId: baseUnit.typeId,
    subCountyCode: baseUnit.subCountyCode,
  };
};

export const getMatchingCalcParamDataForUnit = <T extends CalculationParamsBase>(unit: BaseUnit, paramsData: T[]): T | undefined => {
  const matchedData = paramsData.find(data => unit.practiceId === data.unit.practiceId && unit.typeId === data.unit.typeId && unit.subCountyCode === data.unit.subCountyCode );
  return matchedData;
};