import { useState } from 'react';
import { ColumnState, GridApi, GridReadyEvent } from 'ag-grid-community';

type useGridStatePersistenceProps = {
  defaultColumnState: ColumnState[];
  columns: ColumnState[];
  saveColumnState: (columns: ColumnState[]) => void;
}

export const useGridStatePersistence = ({ defaultColumnState, columns, saveColumnState }: useGridStatePersistenceProps) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const onChange = () => {
    if (gridApi) {
      const model = gridApi.getColumnState();
      saveColumnState(model);
    }
  };

  function onGridReady(event: GridReadyEvent): void {
    setGridApi(event.api);
    const cols = columns.length > 0 ? columns : defaultColumnState;
    event.api.applyColumnState({
      state: cols,
      applyOrder: true,
    });
  }

  return {
    onChange,
    onGridReady,
    gridApi,
  };
};
