import { useMemo } from 'react';
import { RowCropScenario } from '../../types/api/RowCropScenario';

export function useMatrixOverlays(includedScenarios: RowCropScenario[]) {
  return useMemo(() => createMatrixOverlays(includedScenarios), [includedScenarios]);
}

export function createMatrixOverlays(includedScenarios: RowCropScenario[]) {
  return includedScenarios.map(scenario => ({
    overlayId: scenario.scenarioId,
    type: scenario.typeId,
    practice: scenario.practiceId ?? '',
  }));
}