import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { TextField } from '@mui/material';

interface ColumnCountProps {
  columnCount: number;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
}

export type ColumnCountFields = {
  columnCount: number;
}

export const ColumnCountInput = ({ columnCount, className, autoFocus = false, disabled = false }: ColumnCountProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name="columnCount"
      rules={{ required: VALIDATION_RULES.required(), min: VALIDATION_RULES.minimum(1) }}
      defaultValue={columnCount}
      control={control}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          className={className}
          type="number"
          label="Columns"
          size="small"
          fullWidth
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => field.onChange(parseFloat(e.target.value))}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      )}
    />
  );
};
