import axios from 'axios';
import { ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import HarvestRevenueScenarioPiece from '../types/api/HarvestRevenueScenarioPiece';
import { envBaseApiUrl } from '../constants/envConstants';

export const getHarvestRevenueScenarioPiecesForScenario = (scenarioId: ScenarioId) => axios.get<HarvestRevenueScenarioPiece[]>(`${envBaseApiUrl}/harvest-revenue-scenario-pieces/scenarios/${scenarioId}`);
export const getHarvestRevenueScenarioPiecesForScenarios = (scenarioIds: ScenarioId[]) => axios.post<HarvestRevenueScenarioPiece[]>(`${envBaseApiUrl}/harvest-revenue-scenario-pieces/scenarios`, scenarioIds);

export const getHarvestRevenueScenarioPiece = (scenarioPieceId: ScenarioPieceId) => axios.get<HarvestRevenueScenarioPiece>(`${envBaseApiUrl}/harvest-revenue-scenario-pieces/${scenarioPieceId}`);

export const createHarvestRevenueScenarioPiece = (scenarioPiece: HarvestRevenueScenarioPiece) => axios.post<CreatedItemResult<ScenarioPieceId>>(`${envBaseApiUrl}/harvest-revenue-scenario-pieces`, scenarioPiece);

export const updateHarvestRevenueScenarioPiece = (scenarioPiece: HarvestRevenueScenarioPiece) => axios.put<void>(`${envBaseApiUrl}/harvest-revenue-scenario-pieces/`, scenarioPiece);

export const deleteHarvestRevenueScenarioPiece = (scenarioPieceId: ScenarioPieceId) => axios.delete<void>(`${envBaseApiUrl}/harvest-revenue-scenario-pieces/${scenarioPieceId}/`);
