import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactElement } from 'react';
import useReconciliationState from '../hooks/useReconciliationState';
import { Nullable } from '../../../types/util/Nullable';
import EntityDiffHeader from './entityDiffHeader';
import EntityDiffBody from './entityDiffBody';
import ReconciliationTrackedEntity, { SubEntityCollectionTypeBase } from '../../../types/app/ReconciliationTrackedEntity';
import DataLocationType from '../constants/dataLocationType';

interface EntityDiffProps<Entity, EntityId, SubEntityCollectionType extends SubEntityCollectionTypeBase, ParentId, SubEntityType, SubEntityId, SubEntitySubCollectionType extends SubEntityCollectionTypeBase> {
  title: string;
  subEntitySelector: (entity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionType, ParentId>) => ReconciliationTrackedEntity<SubEntityType, SubEntityId, SubEntitySubCollectionType, EntityId>[];
  subtitle?: string;
  trackedEntity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionType, ParentId>;
  subsetEntity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionType, ParentId>;
  getSubEntityDiffElements?: (
    subEntities: ReconciliationTrackedEntity<SubEntityType, SubEntityId, SubEntitySubCollectionType, EntityId>[],
    subEntitySubset: ReconciliationTrackedEntity<SubEntityType, SubEntityId, SubEntitySubCollectionType, EntityId>[],
    onReconciliationTypeChange: (subEntity: ReconciliationTrackedEntity<SubEntityType, SubEntityId, SubEntitySubCollectionType, EntityId>) => void,
  ) => ReactElement[];
  onReconciliationTypeChange: (entity: ReconciliationTrackedEntity<Entity, EntityId, SubEntityCollectionType, ParentId>) => void;
  detailComponents: Nullable<string>[];
  preventCollapse?: boolean;
  hideHeader?: boolean;
  preventSelection?: boolean;
  dataLocation: DataLocationType;
  shouldAlwaysShowDetails?: boolean;
}

const EntityDiff = <Entity, EntityId, SubEntityCollectionType extends SubEntityCollectionTypeBase, ParentId, SubEntityType, SubEntityId, SubEntitySubCollectionType extends SubEntityCollectionTypeBase>({
  title, subtitle, getSubEntityDiffElements, onReconciliationTypeChange,
  detailComponents, trackedEntity, subsetEntity, dataLocation,
  preventCollapse = false, hideHeader = false, preventSelection = false,
  subEntitySelector, shouldAlwaysShowDetails = false,
}: EntityDiffProps<Entity, EntityId, SubEntityCollectionType, ParentId, SubEntityType, SubEntityId, SubEntitySubCollectionType>) => {
  const {
    onSelectedReconciliationTypeChanged,
    detailString,
    onSubEntityReconciledStatusChange,
    areAllSubEntitiesSelected,
    areSomeSubEntitiesSelected,
    revisionTrackedEntity,
    isReconciled,
    // Note: these generics are explicitly passed in because without it, for an unknown reason, one of the returned objects loses its type information.
  } = useReconciliationState<Entity, EntityId, SubEntityCollectionType, ParentId, SubEntityType, SubEntityId>(
    detailComponents, onReconciliationTypeChange, trackedEntity, subsetEntity, dataLocation);

  if (!preventCollapse) {
    return (
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme => theme.palette.action.selected }} />}>
          <EntityDiffHeader
            title={title}
            subtitle={subtitle}
            entityType={revisionTrackedEntity.entityType}
            isReconciled={isReconciled}
            selectedReconciliationType={revisionTrackedEntity.reconciliationType}
            onSelectedReconciliationTypeChanged={onSelectedReconciliationTypeChanged}
            areSomeSubEntitiesSelected={areSomeSubEntitiesSelected}
            areAllSubEntitiesSelected={areAllSubEntitiesSelected}
            preventSelection={preventSelection}
            dataLocation={dataLocation}
          />
        </AccordionSummary>
        <AccordionDetails>
          <EntityDiffBody
            detailString={detailString}
            onSubEntityReconciledStatusChange={onSubEntityReconciledStatusChange}
            getSubEntityDiffElements={getSubEntityDiffElements}
            subEntities={subEntitySelector(revisionTrackedEntity)}
            subsetEntities={subEntitySelector(subsetEntity)}
            entityType={revisionTrackedEntity.entityType}
            shouldAlwaysShowDetails={shouldAlwaysShowDetails}
          />
        </AccordionDetails>
      </Accordion>
    );
  } else {
    return (
      <Grid container rowSpacing={1}>
        {!hideHeader && <Grid item xs={12}>
          <EntityDiffHeader
            title={title}
            subtitle={subtitle}
            entityType={revisionTrackedEntity.entityType}
            isReconciled={isReconciled}
            selectedReconciliationType={revisionTrackedEntity.reconciliationType}
            onSelectedReconciliationTypeChanged={onSelectedReconciliationTypeChanged}
            areSomeSubEntitiesSelected={areSomeSubEntitiesSelected}
            areAllSubEntitiesSelected={areAllSubEntitiesSelected}
            preventSelection={preventSelection}
            dataLocation={dataLocation}
          />
        </Grid> }
        <Grid item xs={12}>
          <EntityDiffBody
            detailString={detailString}
            onSubEntityReconciledStatusChange={onSubEntityReconciledStatusChange}
            getSubEntityDiffElements={getSubEntityDiffElements}
            entityType={revisionTrackedEntity.entityType}
            subEntities={subEntitySelector(revisionTrackedEntity)}
            subsetEntities={subEntitySelector(subsetEntity)}
            shouldAlwaysShowDetails={shouldAlwaysShowDetails}
          />
        </Grid>
      </Grid>
    );
  }
};



export default EntityDiff;