import { IEnumAttributes } from '@silveus/calculations/dist/utils/IEnumAttributes';

export enum PersonOfInterestType {
  Spouse = 907550000,
  SBI = 907550001,
  LandLordOrTenant = 907550002,
  PowerOfAttorney = 907550003,
  AuthorizedRep = 907550004,
  AuthorizedToSign = 907550005,
}

export const PersonOfInterestTypeAttributes: Readonly<Record<PersonOfInterestType, IEnumAttributes<PersonOfInterestType>>> = {
  [PersonOfInterestType.Spouse]: { name: 'Spouse', description: 'S', value: PersonOfInterestType.Spouse },
  [PersonOfInterestType.SBI]: { name: 'SBI', description: 'SB', value: PersonOfInterestType.SBI },
  [PersonOfInterestType.LandLordOrTenant]: { name: 'LL/T', description: 'L', value: PersonOfInterestType.LandLordOrTenant },
  [PersonOfInterestType.PowerOfAttorney]: { name: 'POA', description: 'P', value: PersonOfInterestType.PowerOfAttorney },
  [PersonOfInterestType.AuthorizedRep]: { name: 'Auth Rep', description: 'AR', value: PersonOfInterestType.AuthorizedRep },
  [PersonOfInterestType.AuthorizedToSign]: { name: 'Auth to Sign', description: 'AS', value: PersonOfInterestType.AuthorizedToSign },
};