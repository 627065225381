import { ScenarioPieceGroupType, ScenarioPieceGroupTypeAttributes, ScenarioPieceType, ScenarioPieceTypeAttributes } from '@silveus/calculations';
import { IEnumAttributes } from '@silveus/calculations/dist/utils/IEnumAttributes';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';

const getCompareValue = (groupAtts: IEnumAttributes<ScenarioPieceGroupType, ScenarioPieceType>[], rcsp: RowCropScenarioPiece): ScenarioPieceType => {
  const groupAtt = groupAtts.find(spg => spg.elements?.includes(rcsp.scenarioPieceType));
  if (groupAtt === undefined || groupAtt.elements === undefined || groupAtt.elements.length <= 0) {
    return rcsp.scenarioPieceType;
  }

  return Math.min(...groupAtt.elements);
};

const groupAttributes = Object.values(ScenarioPieceGroupTypeAttributes);

export const sortScenarioPiecesForQuoteSummary = ((a: RowCropScenarioPiece, b: RowCropScenarioPiece) => {
  const aAtt = ScenarioPieceTypeAttributes[a.scenarioPieceType];
  const bAtt = ScenarioPieceTypeAttributes[b.scenarioPieceType];

  // sort product types first
  if (aAtt.productType !== bAtt.productType) {
    return aAtt.productType - bAtt.productType;
  }

  const sptA = getCompareValue(groupAttributes, a);
  const sptB = getCompareValue(groupAttributes, b);

  return sptA - sptB;
});