import { HailScenarioPieceId } from '../../../types/api/PrimaryKeys';
import { Quote } from '../../../types/api/Quote';
import UnitYear from '../../../types/api/UnitYear';
import HailRate from '../../../types/api/hail/hailRate';
import HailScenarioPieceRate from '../../../types/api/hail/HailScenarioPieceRate';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { roundToPlaces } from '@silveus/calculations';
import { maxBy } from 'lodash';
import { Nullable } from '../../../types/util/Nullable';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';

const DEFAULT_TOWNSHIP_RANGE = '0000-0000';
const DEFAULT_TOWNSHIP_RANGE_FALLBACK = 'DEFAULT-DEFAULT';

export function getHailScenarioPieceRates(quote: Quote, scenario: RowCropScenario, hailScenarioPieceId: HailScenarioPieceId, unitYears: UnitYear[], availableHailRates: HailRate[]): HailScenarioPieceRate[] {
  const hailRatesByTownshipRange = getHailRatesByTownshipRange(availableHailRates);
  const defaultHailRate = getFallbackHailRate(hailRatesByTownshipRange);
  const hailScenarioPieceRates: HailScenarioPieceRate[] = [];
  if (quote.quickQuote) {
    //take scenario acres put it on county rate or put it on highest rate
    const netScenarioAcres = roundToPlaces((scenario.quickUnit?.acres ?? 0) * (scenario.quickUnit?.sharePercent ?? 0), 2);
    if (defaultHailRate !== undefined) {
      const hailScenarioPieceRate = createHailScenarioPieceRate(hailScenarioPieceId, defaultHailRate, netScenarioAcres);
      hailScenarioPieceRates.push(hailScenarioPieceRate);
    } else {
      const highestHailRate = maxBy(availableHailRates, hr => hr.rate);
      if (highestHailRate !== undefined) {
        const hailScenarioPieceRate = createHailScenarioPieceRate(hailScenarioPieceId, highestHailRate, netScenarioAcres);
        hailScenarioPieceRates.push(hailScenarioPieceRate);
      }
    }
    const existingHailRate = hailScenarioPieceRates[0];
    availableHailRates.forEach(hailRate => {
      //Don't re-add the existing rate
      if (hailRate.rateId === existingHailRate.rateId) return;
      const hailScenarioPieceRate = createHailScenarioPieceRate(hailScenarioPieceId, hailRate, 0);
      hailScenarioPieceRates.push(hailScenarioPieceRate);
    });
  } else {
    //Determine if we should create HailScenarioPieceRate based on if there is a rate that matches the unit
    unitYears.forEach(unit => {
      const hailRate = getHailRateForUnitFromHailRateMap(unit, hailRatesByTownshipRange);
      if (hailRate !== undefined && hailScenarioPieceRates.findIndex(hr => hr.rateId === hailRate.rateId) < 0) {
        const hailScenarioPieceRate = createHailScenarioPieceRate(hailScenarioPieceId, hailRate, null);
        hailScenarioPieceRates.push(hailScenarioPieceRate);
      }
    });
  }
  sortHailRates(hailScenarioPieceRates, availableHailRates);
  return hailScenarioPieceRates;
}

// Function to extract numeric and alphabetic parts from a string
const extractParts = (str: string): [number, string] => {
  if (str.toUpperCase() === DEFAULT_TOWNSHIP_RANGE ||
    str.toUpperCase() === DEFAULT_TOWNSHIP_RANGE_FALLBACK) {
    return [-1, '']; // Default sorts first
  }

  const numericPart = parseInt(str.match(/\d+/)?.[0] ?? '0', 10);
  const alphabeticPart = str.match(/[A-Za-z]+/)?.[0] ?? '';
  return [numericPart, alphabeticPart];
};

export const sortHailRates = (scenarioPieceRates: HailScenarioPieceRate[], hailRates: HailRate[]): HailScenarioPieceRate[] => {
  const hailRateMap = new Map(hailRates.map(hailRate => [hailRate.rateId, hailRate]));
  return scenarioPieceRates.sort((a, b) => {
    const hailRateA = hailRateMap.get(a.rateId);
    const hailRateB = hailRateMap.get(b.rateId);
    if (hailRateA === undefined || hailRateB === undefined) return 0;

    const [townshipNumberA, townshipLettersA] = extractParts(hailRateA.township);
    const [townshipNumberB, townshipLettersB] = extractParts(hailRateB.township);

    //Sort by township number first, then by township letters
    if (townshipNumberA !== townshipNumberB) {
      return townshipNumberA - townshipNumberB;
    }
    if (townshipLettersA !== townshipLettersB) {
      return townshipLettersA.localeCompare(townshipLettersB);
    }

    //Sort by range number first, then by range letters
    const [rangeNumA, rangeLetterA] = extractParts(hailRateA.range);
    const [rangeNumB, rangeLetterB] = extractParts(hailRateB.range);

    if (rangeNumA !== rangeNumB) {
      return rangeNumA - rangeNumB;
    }
    return rangeLetterA.localeCompare(rangeLetterB);
  });
};

function createHailScenarioPieceRate(hailScenarioPieceId: HailScenarioPieceId, hailRate: HailRate, acres: Nullable<number>): HailScenarioPieceRate {
  const newHailScenarioPieceRate: HailScenarioPieceRate = {
    hailScenarioPieceRateId: generatePrimaryKey(),
    hailScenarioPieceId: hailScenarioPieceId,
    rateId: hailRate.rateId,
    acres: acres,
    offlineCreatedOn: undefined,
    offlineLastUpdatedOn: undefined,
    offlineDeletedOn: undefined,
  };
  return newHailScenarioPieceRate;
}

export function getHailRateForUnitFromHailRateMap(unit: { township: Nullable<string>, range: Nullable<string> }, hailRatesByTownshipRange: Map<string, HailRate>): HailRate | undefined {
  //Get hail rate based on township and range if not found use default rate if present
  return hailRatesByTownshipRange.get(getTownshipRange(unit)) ?? getFallbackHailRate(hailRatesByTownshipRange);
}

export function getHailRateForUnitFromHailRates(unit: UnitYear, hailRates: HailRate[]): HailRate | undefined {
  const hailRatesByTownshipRange = getHailRatesByTownshipRange(hailRates);
  return hailRatesByTownshipRange.get(getTownshipRange(unit)) ?? getFallbackHailRate(hailRatesByTownshipRange);
}

const getFallbackHailRate = (hailRatesByTownshipRange: Map<string, HailRate>): HailRate | undefined => {
  return hailRatesByTownshipRange.get(DEFAULT_TOWNSHIP_RANGE) ?? hailRatesByTownshipRange.get(DEFAULT_TOWNSHIP_RANGE_FALLBACK);
};

export const getHailRatesByTownshipRange = (hailRates: HailRate[]): Map<string, HailRate> => {
  const hailRatesByTownshipRange = new Map<string, HailRate>();
  hailRates.forEach(hr => {
    hailRatesByTownshipRange.set(getTownshipRange(hr), hr);
  });
  return hailRatesByTownshipRange;
};

function getTownshipRange(entity: { township: Nullable<string>, range: Nullable<string> }) {
  const stringComponents = [entity.township?.toUpperCase(), entity.range?.toUpperCase()];
  return stringComponents.filter(sc => isNotNullOrUndefined(sc)).join('-');
}

export const calculateSummaryHailRate = (
  hailPlanNetAcres: number,
  hailPlanCoveragePerAcre: number,
  totalPremium: number,
): number => {
  return hailPlanNetAcres > 0 && hailPlanCoveragePerAcre > 0 ? roundToPlaces(totalPremium / hailPlanNetAcres / (hailPlanCoveragePerAcre / 100), 2) : 0;
};