import { CurrencyCellRenderer } from './cellRenderers/currencyCellRenderer';
import { FirstGroupingHeaderGroupComponent } from './cellRenderers/firstGroupingHeaderGroupComponent';
import { IncludeColumnCellRenderer } from './cellRenderers/includeColumnCellRenderer';
import { PercentageCellRenderer } from './cellRenderers/percentageCellRenderer';
import {
  HistoricalData,
  HistoricalDynamicColumnConfig,
  HistoricalDynamicColumnGroupConfig,
  HistoricalDynamicConfigs
} from './types/HistoricalData';
import { StaticallyTypedGroupedColumnDef } from './types/agGridUtilityTypes';
import './historicalChart.scss';
import { ColDef, HeaderClassParams, ICellRendererParams } from 'ag-grid-community';
import { getFriendlyScenarioPieceName } from '../../../../../utils/scenarioPieceUtils';
import { ScenarioPiece } from '../../../../../types/api/ScenarioPiece';
import { useMemo } from 'react';
import { useJoinStaticAndDynamicColumns } from './hooks/useJoinStaticAndDynamicColumns';
import { useAppSelector } from '../../../../../hooks/reduxHooks';
import { selectHistoricalColumns } from '../../../../../app/userSettingsSlice';
import { distinctBy } from '../../../../../utils/arrayUtils';
import { selectAvailableIntervals, selectSelectedIntervals } from '../../../../../app/intervalsSlice';
import { KeyedState } from '../../../../../app/sliceHelpers';
import { ScenarioPieceId } from '../../../../../types/api/PrimaryKeys';
import { ScenarioPieceSelectedInterval } from '../../../../../types/api/adm/ScenarioPieceSelectedInterval';
import { IntervalRange } from '../../../../../types/api/adm/IntervalRange';
import { getScenarioPieceDefinition } from '../../../../../constants/productDefinitions/scenarioPieceDefinitionRecords';
import OptionState from '../../../../../types/app/OptionState';

const defaultColumnDefinition = {
  suppressMovable: true,
  maxWidth: 80,
};

const getDefaultColumnGroupDefinition = (selectedScenarioColor: string) => {
  return {
    marryChildren: true,
    headerClass: (params: HeaderClassParams) => getColumnGroupHeaderStyle(selectedScenarioColor),
  };
};

export const useHistoricalAnalysisColumnDefinitions = (
  selectedScenarioColor: string,
  updateYearsToExclude: (year: number, include: boolean) => void,
  scenarioPieces: ScenarioPiece[],
  scenarioOptions: OptionState[],
) => {
  const historicalColumns = useAppSelector(selectHistoricalColumns);
  const staticColumnDefs = useHistoricalChartColumnDefinitions(updateYearsToExclude);
  const defaultColumnGroupDefinition = useMemo(() => getDefaultColumnGroupDefinition(selectedScenarioColor), [selectedScenarioColor]);
  const selectedIntervals = useAppSelector(selectSelectedIntervals);
  const availableIntervals = useAppSelector(selectAvailableIntervals);

  const columnDefWithUserState = [...staticColumnDefs];
  columnDefWithUserState.forEach(cd => {
    cd.children.forEach((child: ColDef<HistoricalData>) => {
      const savedColumnState = historicalColumns.find(x => x.colId === child.field);
      if (savedColumnState) {
        child.hide = savedColumnState.hide ?? false;
      }
    });
  });

  const dynamicColumnConfigs = useDynamicConfigs(scenarioPieces, scenarioOptions, selectedIntervals, availableIntervals);
  const finalColumnDefinitions = useJoinStaticAndDynamicColumns(staticColumnDefs, dynamicColumnConfigs);


  return {
    defaultColumnDefinition,
    defaultColumnGroupDefinition,
    columnDefinitions: finalColumnDefinitions,
  };
};

function getColumnGroupHeaderStyle(scenarioColor: string): string | string[] | undefined {
  //This sets a css variable to the color of the scenario, so that we can have dynamically determined stylings on the ag-grid
  // This is required since ag-grid requires class names of pre-defined classes, which otherwise don't have a way to use dynamic styles.
  //More info about css variables: https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
  document.documentElement.style.setProperty('--scenario-color', scenarioColor);

  return 'historical-column-group-header';
}

const firstColumnCellStyle = { padding: 0, textAlign: 'center' };

const useHistoricalChartColumnDefinitions = (onIncludeChange: (year: number, include: boolean) => void): StaticallyTypedGroupedColumnDef<HistoricalData>[] => {
  const historicalChartColumnDefinitions: StaticallyTypedGroupedColumnDef<HistoricalData>[] = useMemo(() => {
    return [
      {
        headerName: '[Pencil]',
        headerGroupComponent: FirstGroupingHeaderGroupComponent,
        children: [
          { field: 'include', headerName: 'Include?', cellRenderer: (params: ICellRendererParams<HistoricalData, boolean>) => IncludeColumnCellRenderer(params, onIncludeChange), pinned: 'left', maxWidth: 70, cellStyle: firstColumnCellStyle },
          { field: 'year', pinned: 'left', maxWidth: 60, cellStyle: firstColumnCellStyle, cellClass: 'cellCenter' },
        ],
      },

      {
        headerName: 'Prices',
        children: [
          { field: 'plant', cellRenderer: CurrencyCellRenderer },
          { field: 'harvest', cellRenderer: CurrencyCellRenderer },
          { field: 'percentChange', headerName: '% Chg.', cellRenderer: PercentageCellRenderer },
        ],
      },

      {
        headerName: 'Producer Yields',
        children: [
          { field: 'expectedProducerYield', headerName: 'Exp. Yld.' },
          { field: 'actualProducerYield', headerName: 'Actual Yld' },
          { field: 'producerYieldPercentChange', headerName: '% Chg.', cellRenderer: PercentageCellRenderer },
        ],
      },

      {
        headerName: 'County Yields',
        children: [
          { field: 'expectedCountyYield', headerName: 'Exp. Yld.' },
          { field: 'actualCountyYield', headerName: 'Actual Yld' },
          { field: 'countyYieldPercentChange', headerName: '% Chg.', cellRenderer: PercentageCellRenderer },
        ],
      },

      {
        headerName: '',
        children: [
          { field: 'total', pinned: 'right', headerName: 'Total', cellRenderer: CurrencyCellRenderer, maxWidth: 100 },
        ],
      },
    ];
  }, [onIncludeChange]);

  return historicalChartColumnDefinitions;
};

const useDynamicConfigs = (
  scenarioPieces: ScenarioPiece[],
  scenarioOptions: OptionState[],
  selectedIntervals: KeyedState<ScenarioPieceId, ScenarioPieceSelectedInterval[]>,
  availableIntervals: IntervalRange[]): HistoricalDynamicConfigs => {
  return useMemo(() => {
    const columnConfigs = getDynamicColumnConfigs(scenarioPieces, scenarioOptions, selectedIntervals, availableIntervals);
    const disctinctColumnConfigs = distinctBy(columnConfigs, columnConfig => columnConfig.groupingKey);
    const columnGroupConfigs = disctinctColumnConfigs.map(distinctColumnGroup => {

      const dynamicColumnGroupConfig: HistoricalDynamicColumnGroupConfig = {
        groupingKey: distinctColumnGroup.groupingKey,
        groupingLabel: distinctColumnGroup.groupingKey,
      };

      return dynamicColumnGroupConfig;
    });

    return {
      columnConfigs,
      columnGroupConfigs,
    };
  }, [scenarioPieces, scenarioOptions]);
};

const getDynamicColumnConfigs = (
  scenarioPieces: ScenarioPiece[],
  scenarioOptions: OptionState[],
  selectedIntervals: KeyedState<ScenarioPieceId, ScenarioPieceSelectedInterval[]>,
  availableIntervals: IntervalRange[]) => {

  const dynamicColumnConfigs: HistoricalDynamicColumnConfig[] = [];

  for (const scenarioPiece of scenarioPieces) {
    const groupName = getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType);
    const scenarioPieceDefinition = getScenarioPieceDefinition(scenarioPiece.scenarioPieceType);
    dynamicColumnConfigs.push(...scenarioPieceDefinition.getHistoricalQuoterColumnConfigs({
      groupName,
      scenarioOptions,
      selectedIntervals,
      availableIntervals,
      scenarioPiece,
    }));

    const insColumnConfig: HistoricalDynamicColumnConfig = {
      groupingKey: groupName,
      dataKey: 'ins',
      columnHeader: 'Ins.',
      cellRenderer: CurrencyCellRenderer,
      maxWidth: 100,
    };
    dynamicColumnConfigs.push(insColumnConfig);
  }

  return dynamicColumnConfigs;
};