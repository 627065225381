import axios from 'axios';
import { CreatedItemsResult } from '../types/api/results/CreatedItemsResult';
import {
  InsuredId,
  QuoteId,
  ScenarioId,
  ScenarioQuickUnitId,
  UnitId,
  UnitYearAphId,
  UnitYearId
} from '../types/api/PrimaryKeys';
import UnitYear from '../types/api/UnitYear';
import UnitYearAph from '../types/api/UnitYearAph';
import { CreatedInheritanceItemResult } from '../types/api/results/CreatedInheritanceItemResult';
import ScenarioQuickUnit from '../types/api/ScenarioQuickUnit';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import { envBaseApiUrl } from '../constants/envConstants';

export const getUnitYearsForInsured = (insuredId: InsuredId) => axios.get<UnitYear[]>(`${envBaseApiUrl}/units/Insureds/${insuredId}`);

export const getUnitYear = (unitYearId: UnitYearId) => axios.get<UnitYear>(`${envBaseApiUrl}/units/unityears/${unitYearId}`);

export const createUnitYear = (unitYear: UnitYear) => axios.post<CreatedInheritanceItemResult<UnitId, UnitYearId>>(`${envBaseApiUrl}/units/unityears`, unitYear);

export const batchCreateUnitYear = (unitYears: readonly UnitYear[]) =>  axios.post<void>(`${envBaseApiUrl}/units/unityears/batch`, unitYears);

export const updateUnitYear = (unit: UnitYear) => axios.put<void>(`${envBaseApiUrl}/units/unityears`, unit);

export const batchUpdateUnitYears = (unitYears: readonly UnitYear[]) => axios.put<void>(`${envBaseApiUrl}/units/unityears/batch`, unitYears);

export const deleteUnit = (unitId: UnitId) => axios.delete<void>(`${envBaseApiUrl}/units/${unitId}`);

export const deleteUnitYear = (unitYearId: UnitYearId) => axios.delete<void>(`${envBaseApiUrl}/units/unityears/${unitYearId}`);

export const batchDeleteUnitYears = (data: readonly UnitYearId[]) => axios.delete<void>(`${envBaseApiUrl}/units/unityears/batch`, { data });

export const getUnitYearAph = (unitYearId: UnitYearId) => axios.get<UnitYearAph[]>(`${envBaseApiUrl}/units/unityears/${unitYearId}/aph`);

export const createUnitYearAph = (aph: UnitYearAph[]) => axios.post<CreatedItemsResult<UnitYearAphId>>(`${envBaseApiUrl}/units/unityears/aph`, aph);

export const updateUnitYearAph = (aph: UnitYearAph[]) => axios.put<void>(`${envBaseApiUrl}/units/unityears/aph`, aph);

export const updateUnitYearAphAcres = (insuredId: InsuredId, unitYear: number, historicalYear: number) => axios.put<UnitYear[]>(`${envBaseApiUrl}/units/insureds/${insuredId}/unityears`, { unitYear, historicalYear });

export const deleteAph = (unitYearAphIds: UnitYearAphId[]) => axios.delete<void>(`${envBaseApiUrl}/units/unityears/aph`, { data: unitYearAphIds });

export const deleteAphForUnitYear = (unitYearId: UnitYearId) => axios.delete<void>(`${envBaseApiUrl}/units/unityears/${unitYearId}/aph`);

export const getScenarioQuickUnit = (scenarioId: ScenarioId) => axios.get<ScenarioQuickUnit>(`${envBaseApiUrl}/units/scenarios/${scenarioId}/`);

export const createScenarioQuickUnit = (ScenarioQuickUnit: ScenarioQuickUnit) => axios.post<CreatedItemResult<ScenarioQuickUnitId>>(`${envBaseApiUrl}/units/`, ScenarioQuickUnit);

export const updateScenarioQuickUnit = (ScenarioQuickUnit: ScenarioQuickUnit) => axios.put<void>(`${envBaseApiUrl}/units/`, ScenarioQuickUnit);

export const deleteScenarioQuickUnit = (scenarioId: ScenarioId) => axios.delete<void>(`${envBaseApiUrl}/units/scenarios/${scenarioId}`);

export const getUnitYearsForScenarios = (scenarioIds: ScenarioId[]) => axios.post<UnitYear[]>(`${envBaseApiUrl}/units/scenarios`, scenarioIds);

export const getUnitYearAphForScenarios = (scenarioIds: ScenarioId[]) => axios.post<UnitYearAph[]>(`${envBaseApiUrl}/units/scenarios/aph`, scenarioIds);

export const getUnitYearsForQuotes = (quoteIds: QuoteId[]) => axios.post<UnitYear[]>(`${envBaseApiUrl}/units/quotes`, quoteIds);

export const getUnitYearAphForQuotes = (quoteIds: QuoteId[]) => axios.post<UnitYearAph[]>(`${envBaseApiUrl}/units/quotes/aph`, quoteIds);
