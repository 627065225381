import { useFormContext } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectAdmLoadingStatusFetchScenarioPricesAndYields, selectOfferExpectedCountyYield } from '../../../app/admSlice';
import ExpectedCountyYieldInput, { ExpectedCountyYieldProps } from './expectedCountyYieldInput';
import { Nullable } from '../../../types/util/Nullable';
import { LOADING_STATES } from '../../../constants/loadingStates';

const ScenarioExpectedCountyYieldInput = ({ expectedCountyYield, sx, label, commodityCode, practiceId, insurancePlanCodes, autoFocus = false, disabled, coverageLevel, volatility }: Omit<ExpectedCountyYieldProps, 'typeId'>) => {
  const { setValue, getValues, trigger } = useFormContext();
  const offerExpectedCountyYield = useAppSelector(selectOfferExpectedCountyYield);
  const currentType: Nullable<string> = getValues('typeId') ?? null; //this is because it was setting it to undefined some of the time
  const pricesAndYieldsFetchStatus = useAppSelector(selectAdmLoadingStatusFetchScenarioPricesAndYields);
  const lastPractice = useRef(practiceId);

  useEffect(() => {
    if (practiceId === lastPractice.current && expectedCountyYield !== null) {
      return;
    }

    if (pricesAndYieldsFetchStatus === LOADING_STATES.Loading) {
      setValue('expectedCountyYield', 0);
    } else if (pricesAndYieldsFetchStatus === LOADING_STATES.Succeeded || expectedCountyYield === null) {
      lastPractice.current = practiceId;
      setValue('expectedCountyYield', offerExpectedCountyYield);
      trigger('expectedCountyYield');
    }
  }, [pricesAndYieldsFetchStatus, practiceId, offerExpectedCountyYield, expectedCountyYield]);

  return (
    <ExpectedCountyYieldInput
      expectedCountyYield={expectedCountyYield}
      sx={sx}
      practiceId={practiceId}
      commodityCode={commodityCode}
      label={label}
      autoFocus={autoFocus}
      disabled={disabled}
      typeId={currentType}
      insurancePlanCodes={insurancePlanCodes}
      coverageLevel={coverageLevel}
      volatility={volatility}
    />
  );
};

export default ScenarioExpectedCountyYieldInput;
