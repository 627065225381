import am5geodata_usaLow from '@amcharts/amcharts5-geodata/usaLow';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useLayoutEffect, useRef } from 'react';
import { selectAllStates } from '../../app/admSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import { MapChart, MapPolygon, MapPolygonSeries } from '@amcharts/amcharts5/map';
import { State } from '../../types/api/adm/State';
import { DataItem } from '@amcharts/amcharts5';
import { IComponentDataItem } from '@amcharts/amcharts5/.internal/core/render/Component';

interface StateIdentifier {
  id: string;
  name: string;
}

interface AmChartMapProps {
  updateSelectedState: (stateCode: string, active: boolean) => void;
  selectedStates: State[];
}

const AmChartMap = ({ updateSelectedState, selectedStates }: AmChartMapProps) => {
  const outerChart = useRef<MapChart | null>(null);
  const allStates = useAppSelector(selectAllStates);

  //Parse the map's state ID to the appropriate state object
  const getStateById = (polygonDataItem: DataItem<IComponentDataItem> | undefined): State | null => {
    let selectedState = null;

    if (polygonDataItem !== undefined) {
      const polygonDataContext = polygonDataItem.dataContext as StateIdentifier;
      const stateAbbrev = polygonDataContext.id.substring(3);

      selectedState = allStates.find(state => state.abbrev === stateAbbrev) ?? null;
    }

    return selectedState;
  };

  useLayoutEffect(() => {
    let root = am5.Root.new('chartdiv');

    // Set themes
    root.setThemes([
      am5themes_Animated.new(root),
    ]);

    //Create the chart and set its initial settings
    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: 'none',
        panY: 'none',
        wheelX: 'none',
        wheelY: 'none',
        projection: am5map.geoAlbersUsa(),
      }),
    );

    // Create polygon series
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_usaLow,
      }),
    );

    let polygonTemplate = polygonSeries.mapPolygons.template;

    polygonTemplate.setAll({
      // tooltipText: '{name}',
      toggleKey: 'active',
      interactive: true,
      fill: am5.color('#458bcf'),
    });

    //Change the color when a polygon is hovered
    polygonTemplate.states.create('hover', {
      fill: am5.color('#265e95'),
    });

    //change the color when a polygon is set to active
    polygonTemplate.states.create('active', {
      fill: am5.color('#21456C'),
    });

    //Set the initial click handler for the states to allow initial selections to be made
    // Without this, in order for clicking on a state to reflect in the list, you first have to click
    // on an item in the list (so the click handler assigned there gets assigned to the states in the map)
    polygonSeries.mapPolygons.template.events.on('click', function (event) {
      const selectedState = getStateById(event.target.dataItem);
      if (selectedState !== null) {
        const isActive = event.target.get('active') ?? false;

        updateSelectedState(selectedState.stateCode, isActive);
      }
    });

    outerChart.current = chart;

    return () => {
      root.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    const chart = outerChart.current;

    if (chart !== null) {
      const polygonSeries = chart.series.values[0] as MapPolygonSeries;

      polygonSeries.mapPolygons.each((value: MapPolygon, index: number) => {
        const state = getStateById(value.dataItem);
        if (state !== null) {
          //Set the active state of the state according to which states have been selected
          // This allows selection of item in list to mark state on map as active
          value.set('active', selectedStates.map(state => state.stateCode).includes(state.stateCode));

          //Set the click event of the state to update the selected states
          // This is needed, despite initial click event handler on the template, so that the
          // collection of selected states referenced by this instance of the updateSelectedState
          // function gets updated
          value.events.on('click', function (event) {
            const selectedState = getStateById(value.dataItem);
            if (selectedState !== null) {
              const isActive = value.get('active') ?? false;

              updateSelectedState(selectedState.stateCode, isActive);
            }
          });
        }
      });
    }
  }, [selectedStates, updateSelectedState]);

  return (
    <div id="chartdiv" style={{ width: '100%', height: '100%' }}></div>
  );
};

export default AmChartMap;