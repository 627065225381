import { TextField } from '@mui/material';
import { ScenarioPieceType, UnitStructureCode } from '@silveus/calculations';
import { ChangeEvent, useMemo } from 'react';
import { useAvailableScenarioPieces } from '../../components/formInputs/scenarioPiece/scenarioPieceTypeInput.util';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../types/util/Nullable';
import { ScenarioPieceFormSnapshot } from '../../types/app/ScenarioPieceFormSnapshot';
import { getInsurancePlanCodeForScenarioPiece } from '../../utils/scenarioPieceUtils';
import { generatePrimaryKey } from '../../utils/primaryKeyHelpers';


import { useKeyMapSelector } from '../../hooks/reduxHooks';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../app/scenarioPiecesSlice';
import { FieldValues } from 'react-hook-form';

export interface ScenarioPiecePlanSelectionProps<T extends FieldValues & { scenarioPieceType: ScenarioPieceType }> {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  scenarioId: ScenarioId;
  selectedScenarioPieceType: ScenarioPieceType;
  updateScenarioPieceFormSnapshot: (updatePlanSelection: boolean, newPlanSelection: ScenarioPieceType, scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null) => void;
  scenarioPieceTypePlanSelectionList: ScenarioPieceType[];
  getScenarioPieceFormValues: () => ScenarioPiecePlanSelectionFormValues;
  scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null;
}

export interface ScenarioPiecePlanSelectionFormValues {
  scenarioPieceType: ScenarioPieceType;
  unitStructure: UnitStructureCode;
  upperCoverageLevel: number;
  protectionFactor: number;
  isActive: boolean;
  projectedPrice: number;
  harvestPrice: number;
  approvedYield: Nullable<number>;
  producerYield: Nullable<number>;
  expectedCountyYield: Nullable<number>;
  actualCountyYield: Nullable<number>;
}

export const ScenarioPiecePlanSelection =
  <T extends FieldValues & { scenarioPieceType: ScenarioPieceType }>({
    scenarioPieceFormSnapshot,
    scenarioPiece,
    scenarioId,
    selectedScenarioPieceType,
    updateScenarioPieceFormSnapshot,
    scenarioPieceTypePlanSelectionList,
    getScenarioPieceFormValues }: ScenarioPiecePlanSelectionProps<T>) => {
    const persistedRowCropScenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioId);
    const overrideRowCropScenarioPieces = useMemo(() => {
      return scenarioPiece === null ? undefined : persistedRowCropScenarioPieces.filter(sp => sp.scenarioPieceId !== scenarioPiece.scenarioPieceId);
    }, [scenarioPiece]);

    const handleScenarioPieceSelectionChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

      const scenarioPieceTypeValue = parseInt(e.target.value);
      const newScenarioPieceType = scenarioPieceTypeValue as ScenarioPieceType;

      const scenarioPieceFormValues = getScenarioPieceFormValues();
      const planCode = getInsurancePlanCodeForScenarioPiece(scenarioPieceFormValues.scenarioPieceType);
      const currentFormStateScenarioPiece: RowCropScenarioPiece = {
        rowCropScenarioPieceId: generatePrimaryKey(),
        scenarioPieceId: generatePrimaryKey(),
        scenarioId: scenarioId,
        unitStructure: scenarioPieceFormValues.unitStructure,
        planCode: planCode,
        upperCoverageLevel: scenarioPieceFormValues.upperCoverageLevel,
        lowerCoverageLevel: 0,
        protectionFactor: scenarioPieceFormValues.protectionFactor,
        rowCropScenarioPieceExtendedData: null,

        scenarioPieceType: scenarioPieceFormValues.scenarioPieceType,
        isActive: scenarioPieceFormValues.isActive,
        isInvalid: false,
        offlineCreatedOn: undefined,
        offlineLastUpdatedOn: undefined,
        offlineDeletedOn: undefined,
      };

      updateScenarioPieceFormSnapshot(true, newScenarioPieceType,
        {
          scenarioPiece: currentFormStateScenarioPiece,
          projectedPrice: scenarioPieceFormValues.projectedPrice,
          harvestPrice: scenarioPieceFormValues.harvestPrice,
          expectedYield: scenarioPieceFormValues.approvedYield ?? scenarioPieceFormValues.expectedCountyYield,
          actualYield: scenarioPieceFormValues.producerYield ?? scenarioPieceFormValues.actualCountyYield,
        },
      );
    };

    const availableScenarioPieces = useAvailableScenarioPieces(scenarioId, scenarioPieceTypePlanSelectionList, overrideRowCropScenarioPieces, false);
    return (
      <>
        <TextField
          type="number"
          label="Plan Selection"
          fullWidth
          select
          autoFocus
          value={selectedScenarioPieceType}
          onChange={handleScenarioPieceSelectionChange}
          size="small"
        >
          {availableScenarioPieces}
        </TextField>
      </>
    );
  };
