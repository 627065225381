import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { TextField } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';

type AddressCityInputProps = {
  value?: Nullable<string>;
  disabled: boolean;
  required?: boolean;
}

export type AddressCityFields = {
  city: Nullable<string>;
}

const AddressCityInput = ({ value, disabled, required = true }: AddressCityInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="address.city"
      control={control}
      rules={{ required: required ? VALIDATION_RULES.required() : false, maxLength: VALIDATION_RULES.maxLength(100) }}
      defaultValue={value ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="city"
          label="City"
          fullWidth
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          size="small"
        />
      )}
    />
  );
};

export default AddressCityInput;

