
import { Grid } from '@mui/material';
import { FormInputProps } from '../../scenario/formInputProps';
import FarmBillYieldInput from './farmBillYieldInput';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import { Nullable } from '../../../../types/util/Nullable';
import PercentChangeSliderInput from '../../percentChangeSliderInput.component';

interface FarmBillBenchMarkYieldInputGroupProps extends FormInputProps {
  year: number;
  countyId: string;
  commodityCode: string;
  practiceId: string;
  expectedYield: Nullable<number>;
  actualYield: Nullable<number>;
  projectedYieldName?: string;
  actualYieldName?: string;
}

const FarmBillBenchMarkYieldInputGroup = (
  {
    year,
    disabled,
    commodityCode,
    countyId,
    expectedYield,
    actualYield,
    practiceId,
    projectedYieldName = ExtendedDataFormInputParameterNames.ExpectedYield,
    actualYieldName = ExtendedDataFormInputParameterNames.ActualYield,
  }: FarmBillBenchMarkYieldInputGroupProps,
) => {

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={4}>
        <FarmBillYieldInput
          isActual={false}
          isArc={true}
          name={projectedYieldName}
          disabled={disabled}
          label="Benchmark Yield"
          year={year}
          commodityCode={commodityCode}
          countyId={countyId}
          theYield={expectedYield ?? null}
          projectedYieldName={projectedYieldName}
          actualYieldName={actualYieldName}
          practiceId={practiceId}
        />
      </Grid>
      <Grid item xs={4}>
        <FarmBillYieldInput
          isActual={true}
          isArc={true}
          name={actualYieldName}
          disabled={disabled}
          label="Actual Yield"
          year={year}
          commodityCode={commodityCode}
          countyId={countyId}
          theYield={actualYield ?? null}
          projectedYieldName={projectedYieldName}
          actualYieldName={actualYieldName}
          practiceId={practiceId}
        />
      </Grid>
      <Grid item xs={4}>
        <PercentChangeSliderInput
          estimatedValueFieldName={projectedYieldName}
          actualValueFieldName={actualYieldName}
          sliderName={'farmBillYieldSlider'}
          initialEstimatedValue={expectedYield}
          initialActualValue={actualYield}
        />
      </Grid>
    </Grid>
  );
};


export default FarmBillBenchMarkYieldInputGroup;
