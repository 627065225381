import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { Nullable } from '../types/util/Nullable';
import { valueof } from '../types/util/valueof';
import { LOADING_STATES } from '../constants/loadingStates';
import { RootState } from './store';
import { createAppAsyncThunk } from './thunkHelpers';
import { getOfferAvailabilitiesForQuotesRequest } from '../services/requestInterception/admRequestInterceptor';
import { initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import { getKeyedStateToMap, updateKeyedStateVerbatim } from './sliceHelpers';
import AdmDataForQuoteParams from '../types/api/adm/AdmDataForQuoteParams';
import QuoteProductOfferAvailabilities from '../types/api/adm/QuoteProductOfferAvailabilities';
import { getItemsForId } from '../utils/mapHelpers';
import { ProductOfferAvailabilities } from '@silveus/calculations/dist/availability/productOfferAvailability';
import { buildCountyAndCommodityKey, CountyCommodityKey } from '../types/app/CompositeKeys';

interface AvailabilityState {
  productOfferAvailability: SliceDataState<CountyCommodityKey, ProductOfferAvailabilities[]>;
  status: valueof<typeof LOADING_STATES>;
  error: Nullable<string>;
}

const initialState: AvailabilityState = {
  productOfferAvailability: initialSliceDataState(),
  status: LOADING_STATES.Idle,
  error: null,
};

export const availabilitySlice = createSlice({
  name: 'availability',
  initialState: initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOfferAvailabilities.pending, state => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchOfferAvailabilities.fulfilled, (state, action: PayloadAction<QuoteProductOfferAvailabilities[]>) => {
        for (const offerAvailability of action.payload) {
          updateKeyedStateVerbatim(state.productOfferAvailability.data, offerAvailability.productOfferAvailabilities, offerAvailability.countyCommodityKey);
        }
      })
      .addCase(fetchOfferAvailabilities.rejected, (state, action) => {
        state.status = LOADING_STATES.Failed;
        state.error = action.error.message ?? null;
      });
  },
});

export const fetchOfferAvailabilities = createAppAsyncThunk('availability/fetchOfferAvailabilities', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => {
  return await getOfferAvailabilitiesForQuotesRequest(quoteData);
});

export const selectOfferAvailabilities = (state: RootState) => state.availability.productOfferAvailability.data;

export const selectOfferAvailabilitiesForCountyCommodity = createSelector([
  selectOfferAvailabilities,
  (state: RootState, countyId: string, commodityCode: string) => countyId,
  (state: RootState, countyId: string, commodityCode: string) => commodityCode], (allOfferAvailabilities, countyId, commodityCode) => {
  const allOfferAvailabilitiesMap = getKeyedStateToMap(allOfferAvailabilities);
  return getItemsForId(allOfferAvailabilitiesMap, buildCountyAndCommodityKey(countyId, commodityCode));
});

export default availabilitySlice.reducer;
