import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { TextField } from '@mui/material';
import { sectionMaxLength, validateSection } from '../../../pages/units/validations/sectionValidation';

interface SectionProps {
  section: Nullable<string>;
  autoFocus?: boolean;
}

export type SectionFields = {
  section: Nullable<string>;
}

const SectionInput = ({ section, autoFocus = false }: SectionProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="section"
      control={control}
      rules={{ validate: validateSection }}
      defaultValue={section}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Section"
          fullWidth
          inputProps={{ maxLength: sectionMaxLength }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default SectionInput;
