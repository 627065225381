import { Grid } from '@mui/material';
import { formatCurrency, formatNumber } from '../../../utils/formatNumbers';

interface ForwardSoldOutputSummaryProps {
  totalYieldSold: number;
  percentBushelsSold: number;
  averagePrice: number;
  useSmallFont?: boolean;
  marketingYield?: number;
}

const ForwardSoldOutputSummary = ({ totalYieldSold, percentBushelsSold, averagePrice, useSmallFont = false, marketingYield = undefined }: ForwardSoldOutputSummaryProps) => {
  return (
    <Grid item xs={12} container spacing={1} fontSize={useSmallFont ? '14px' : '16px'}>
      {marketingYield !== undefined &&
        <>
          <Grid item xs={8}>
            Marketing Yield
          </Grid>
          <Grid item xs={4} paddingRight="15px" style={{ textAlign: 'right' }}>
            {formatNumber(marketingYield)}
          </Grid>
        </>}
      <Grid item xs={8}>
        Total Yield Sold
      </Grid>
      <Grid item xs={4} paddingRight="15px" style={{ textAlign: 'right' }}>
        {formatNumber(totalYieldSold)}
      </Grid>
      <Grid item xs={8}>
        % Bushels Sold
      </Grid>
      <Grid item xs={4} paddingRight="15px" style={{ textAlign: 'right' }}>
        {`${formatNumber(percentBushelsSold * 100, true)}%`}
      </Grid>
      <Grid item xs={8}>
        Average Price
      </Grid>
      <Grid item xs={4} paddingRight="15px" style={{ textAlign: 'right' }}>
        {formatCurrency(averagePrice)}
      </Grid>
    </Grid>
  );
};


export default ForwardSoldOutputSummary;
