import { getPrecisionForCommodityCodeAndOptions } from '../utils/priceUtils';
import { useMemo } from 'react';
import OptionState from '../types/app/OptionState';
import { OptionSelectionState } from '../types/app/enums/optionSelectionState.enum';


export const useCommodityOptionBasedPrecisionForScenario = (commodityCode: string, scenarioOptionState: OptionState[]) => {
  const selectedOptionCodes = useMemo(() => {
    return scenarioOptionState.filter(o => o.selectionState !== OptionSelectionState.Off).map(o => o.option.optionCode);
  }, [scenarioOptionState]);

  const precision = getPrecisionForCommodityCodeAndOptions(commodityCode, selectedOptionCodes);
  const step = 1 / (10 ** precision);

  return { precision, step };
};
