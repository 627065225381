import { ScenarioPieceGroupType, ScenarioPieceGroupTypeAttributes } from '@silveus/calculations';
import { selectAllHailScenarioPieceCompositionsByScenarioMap, selectAllHailScenarioPiecesByScenarioMap } from '../../app/hailSlice';
import { selectAllScenarioPieceGroupsByScenarioMap } from '../../app/scenarioPieceGroupsSlice';
import { selectAllScenarioPiecesByScenarioMap } from '../../app/sharedSelectors';
import { selectUserScenarioPieceOrder } from '../../app/userSettingsSlice';
import { FormObject, FormWrapperProps } from '../../components/formWrapper/formWrapper.component';
import { useAppSelector, useKeyMapSelector } from '../../hooks/reduxHooks';
import { ScenarioId, ScenarioPieceId } from '../../types/api/PrimaryKeys';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';
import { Nullable } from '../../types/util/Nullable';
import { getFriendlyScenarioPieceName } from '../../utils/scenarioPieceUtils';
import { stableEmptyArrayAsMutable } from '../../utils/stableEmptyArray';
import ScenarioPieceForm from './scenarioPieceForm.component';

export const useGetScenarioPieceForms = (scenarioId: Nullable<ScenarioId>) => {
  const allScenarioPieces = useKeyMapSelector(selectAllScenarioPiecesByScenarioMap, scenarioId);
  const hailScenarioPieceCompositions = useKeyMapSelector(selectAllHailScenarioPieceCompositionsByScenarioMap, scenarioId);
  const hailScenarioPieces = useKeyMapSelector(selectAllHailScenarioPiecesByScenarioMap, scenarioId);
  const orderedScenarioPieceIds = useAppSelector(s => scenarioId === null ? stableEmptyArrayAsMutable<ScenarioPieceId>() : selectUserScenarioPieceOrder(s)[scenarioId] ?? stableEmptyArrayAsMutable<ScenarioPieceId>());
  const scenarioPieceGroups = useKeyMapSelector(selectAllScenarioPieceGroupsByScenarioMap, scenarioId);

  const getScenarioPieceForm = (scenarioPiece: ScenarioPiece) => {
    return {
      formName: getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType),
      formComponent: (props: FormWrapperProps) => {
        if (scenarioId === null) {
          return <></>;
        }

        return (
          <ScenarioPieceForm
            scenarioPiece={scenarioPiece}
            scenarioId={scenarioId}
            key={scenarioPiece.scenarioPieceId}
            {...props}
          />
        );
      },
    };
  };

  const piecesInGroups = scenarioPieceGroups.flatMap(spg => spg.scenarioPieceGroupMembers.map(spgm => spgm.scenarioPieceId));

  const allScenarioPieceForms = new Map<ScenarioPieceId, FormObject>();
  for (const scenarioPiece of allScenarioPieces) {
    //If this is a piece in a group, we want to skip it
    if (piecesInGroups.includes(scenarioPiece.scenarioPieceId)) continue;
    //Hail is special because it does not have a scenario piece group but has a composition further below
    if (hailScenarioPieces.some(hsp => hsp.scenarioPieceId === scenarioPiece.scenarioPieceId)) continue;
    //We only want to include the primary scenario pieces and those not in groups
    allScenarioPieceForms.set(scenarioPiece.scenarioPieceId, getScenarioPieceForm(scenarioPiece));
  }

  const orderedScenarioPieceForms: FormObject[] = [];

  for (const orderedScenarioPieceId of orderedScenarioPieceIds) {
    const orderedScenarioPieceForm = allScenarioPieceForms.get(orderedScenarioPieceId);

    if (orderedScenarioPieceForm !== undefined) {
      orderedScenarioPieceForms.push(orderedScenarioPieceForm);
      allScenarioPieceForms.delete(orderedScenarioPieceId);
    }
  }

  const remainingScenarioPieces = Array.from(allScenarioPieceForms.values());
  orderedScenarioPieceForms.push(...remainingScenarioPieces);

  for (const hailScenarioPieceComposition of hailScenarioPieceCompositions) {
    const hailScenarioPieceCompositionForm: FormObject = {
      formName: 'Hail',
      formComponent: (props: FormWrapperProps) => {
        if (scenarioId === null) return <></>;

        return <ScenarioPieceForm
          scenarioPiece={null}
          scenarioId={scenarioId}
          key={hailScenarioPieceComposition.hailScenarioPieceCompositionId}
          scenarioPieceType={ScenarioPieceGroupType.Hail}
          {...props}
        />;
      },
    };
    orderedScenarioPieceForms.push(hailScenarioPieceCompositionForm);
  }

  allScenarioPieceForms.clear();

  for (const scenarioPieceGroup of scenarioPieceGroups) {
    const scenarioPieceGroupName = Object.values(ScenarioPieceGroupTypeAttributes).find(spt => spt.value === scenarioPieceGroup.scenarioPieceGroupType)?.description ?? '';

    const scenarioPieceGroupForm: FormObject = {
      formName: scenarioPieceGroupName,
      formComponent: (props: FormWrapperProps) => <ScenarioPieceForm scenarioPiece={null} scenarioId={scenarioPieceGroup.scenarioId} scenarioPieceType={scenarioPieceGroup.scenarioPieceGroupType} />,
    };

    orderedScenarioPieceForms.push(scenarioPieceGroupForm);
  }

  return { orderedScenarioPieceForms };
};