import { Box, Collapse, IconButton, List, ListItem, ListItemAvatar, ListItemText, TableCell, TableRow, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppTask } from '../../../../types/api/AppTask';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectAllAIPs } from '../../../../app/aipSlice';
import { getCountyName, getStateName } from '../../../../utils/adm';
import { selectAdmData } from '../../../../app/admSlice';
import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { useState } from 'react';
import { Change } from '../../../../types/api/applicationWizard/change';


type CommitNoChangeDifferencesRowProps = {
  appTask: AppTask;
  changes: Change[];
}


const CommitNoChangeDifferencesRow = ({ appTask, changes }: CommitNoChangeDifferencesRowProps) => {
  const aips = useAppSelector(selectAllAIPs);
  const admData = useAppSelector(selectAdmData);
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow key={appTask.appTaskId}>
        <TableCell sx={{ p: 0, pl: .5, width: '35px' }}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell size="small">{getStateName(admData, appTask.cropYear, appTask.countyCode)}</TableCell>
        <TableCell size="small">{getCountyName(admData, appTask.cropYear, appTask.countyCode)}</TableCell>
        <TableCell size="small">{ProductType[appTask.productType]}</TableCell>
        <TableCell size="small">{aips.find(x => x.aipId === appTask.aipId)?.aipName}</TableCell>
        <TableCell size="small">{appTask.previousPolicyNumber}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open}>
            <Box sx={{ margin: 1 }}>
              <List>
                {changes.map((change, index) =>
                  <ListItem key={change.summary} sx={{ pt: .5, pb: .5 }}>
                    <ListItemAvatar>
                      <Typography variant="subtitle1">{index + 1}.</Typography>
                    </ListItemAvatar>
                    <ListItemText
                      primary={change.summary}
                      secondary={
                        <Typography variant="body2" color="text.primary">
                          {change.description}
                        </Typography>
                      }
                    />
                  </ListItem>,
                )}
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CommitNoChangeDifferencesRow;