import { Grid } from '@mui/material';
import { FormProvider, SubmitHandler, useWatch } from 'react-hook-form';
import { useFederalOfferData } from '../../../hooks/useFederalOfferData';


import { HighRiskType, ScenarioPieceType } from '@silveus/calculations';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { createScenarioPiece, removeScenarioPieceAndRecalculate } from '../../../app/scenarioPiecesSlice';
import { Nullable } from '../../../types/util/Nullable';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { Quote } from '../../../types/api/Quote';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import useFormWrapper from '../../../hooks/useFormWrapper';
import useDrawerForm from '../../../hooks/useDrawerForm';
import { AdmUpperCoverageLevelInput } from '../../../components/formInputs/scenarioPiece/admUpperCoverageLevelInput.component';
import { AdmUnitStructureInput } from '../../../components/formInputs/scenarioPiece/admUnitStructureInput.component';
import { AdmProtectionFactorInput } from '../../../components/formInputs/scenarioPiece/admProtectionFactor.component';
import IsCatInput from '../../../components/formInputs/scenarioPiece/isCatInput.component';
import { RowCropScenarioPieceExtendedFormFields } from '../rowCropScenarioPieceExtendedFormFields.component';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import PriceInputGroup, { PriceInputGroupFields } from '../../../components/formInputs/scenario/priceInputGroup.component';
import IndividualYieldInputGroup, { IndividualYieldInputGroupFields } from '../../../components/formInputs/scenario/individualYieldInputGroup.component';
import { ProtectionFactorFields } from '../../../components/formInputs/scenarioPiece/protectionFactorInput.component';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import {
  UpperCoverageLevelFields
} from '../../../components/formInputs/scenarioPiece/upperCoverageLevelInput.component';
import { UnitStructureFields } from '../../../components/formInputs/scenarioPiece/unitStructureInput.component';
import { selectScenarioById, updateScenario } from '../../../app/scenariosSlice';
import useYieldsForScenario from '../../../hooks/useYieldsForScenario';
import { mergeScenarioWithYieldAndPriceUpdates } from '../../../utils/scenarioUpdateUtils';
import { deepCompareOptionState, shouldShowOptionsByEu } from '../../../utils/options';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { useScenarioPieceForm } from '../useScenarioPieceForm';
import { OptionsByEnterpriseUnit } from '../../../components/formInputs/scenarioPiece/optionsByEnterpriseUnit.component';
import {
  ScenarioPiecePlanSelection,
  ScenarioPiecePlanSelectionFormValues
} from '../scenarioPiecePlanSelection.component';
import { ScenarioPieceFormSnapshot } from '../../../types/app/ScenarioPieceFormSnapshot';
import { useEffect } from 'react';
import { ScenarioPieceOptions } from '../../../components/formInputs/scenarioPiece/scenarioPieceOptions.component';
import { useOptionsForSpForm } from '../../../hooks/useOptionsForSpForm';
import { useOptions } from '../../../hooks/useOptions';

export interface AphScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  selectedScenarioPieceType: ScenarioPieceType;
  scenarioId: ScenarioId;
  year: number;
  countyId: string;
  highRiskTypeId: HighRiskType;
  typeId: string;
  practiceId: string;
  disabled: boolean;
  quote: Quote;
  handleCancel: () => void;
  updateScenarioPieceFormSnapshot: (updatePlanSelection: boolean, newPlanSelection: ScenarioPieceType, scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null) => void;
  scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null;
  scenarioPieceTypePlanSelectionList: ScenarioPieceType[];
}

export type AphScenarioPieceFormFields = ScenarioPieceFormFields & UpperCoverageLevelFields & UnitStructureFields & ProtectionFactorFields & RowCropScenarioPieceExtendedFormFields & PriceInputGroupFields & IndividualYieldInputGroupFields;

export const AphScenarioPieceForm = ({ scenarioPiece, selectedScenarioPieceType, scenarioId, year, countyId, typeId, practiceId, highRiskTypeId, disabled, registerHeader, handleCancel, quote, handleValidation, updateScenarioPieceFormSnapshot, scenarioPieceFormSnapshot, scenarioPieceTypePlanSelectionList, isCanceling = false }: AphScenarioPieceFormProps) => {
  const dispatch = useAppDispatch();
  const methods = useScenarioPieceForm<AphScenarioPieceFormFields>(scenarioPieceFormSnapshot?.scenarioPiece ?? scenarioPiece);
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  const { approvedYield, rateYield, aphYield } = useYieldsForScenario(scenarioId, quote.countyId, quote.commodityCode);
  const formId = 'aphScenarioPieceForm';

  const isCat = useWatch({ name: 'rowCropScenarioPieceExtendedData.isCat', control: methods.control, defaultValue: scenarioPiece?.rowCropScenarioPieceExtendedData?.isCat ?? false });
  const { scenarioOptionsForSpForm, updateAvailableOptionsBasedOnSpChanges, updateOptionSelection, updateEuByOptionSelection, persistNewOptionSelections } = useOptionsForSpForm();
  const { getOptionStatesForScenario } = useOptions(quote.quoteId, scenarioId);

  useFederalOfferData(scenarioPiece, scenarioId, selectedScenarioPieceType, year, countyId, typeId, practiceId, highRiskTypeId, isCat);

  useEffect(() => {
    //Fetch updated available options based on scenario piece changes
    const formValues = methods.getValues();
    const updatedScenarioPiece = getUpdatedRowCropScenarioPiece(formValues);
    updateAvailableOptionsBasedOnSpChanges(updatedScenarioPiece);
  }, [selectedScenarioPieceType, isCat]);

  const onSubmit: SubmitHandler<AphScenarioPieceFormFields> = async data => {
    data.scenarioPieceType = selectedScenarioPieceType;
    updateScenarioPieceFormSnapshot(false, ScenarioPieceType.Unset, null);

    const newScenarioPiece = getUpdatedRowCropScenarioPiece(data);

    if (scenario) {
      await dispatch(updateScenario({
        updatedScenario: mergeScenarioWithYieldAndPriceUpdates(scenario, data),
      }));
    }

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(createScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId, updatedScenarioPiece: newScenarioPiece, additionalOperations: persistNewOptionSelections }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null || deepCompareOptionState(getOptionStatesForScenario(), scenarioOptionsForSpForm);

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  const getUpdatedRowCropScenarioPiece = (data: AphScenarioPieceFormFields) => {
    const planCode = getInsurancePlanCodeForScenarioPiece(selectedScenarioPieceType);
    const newScenarioPiece: RowCropScenarioPiece = {
      isActive: data.isActive,
      isInvalid: false,
      lowerCoverageLevel: 0,
      planCode: planCode,
      protectionFactor: data.protectionFactor,
      rowCropScenarioPieceExtendedData: data.rowCropScenarioPieceExtendedData,
      rowCropScenarioPieceId: scenarioPiece?.rowCropScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: data.scenarioPieceType,
      unitStructure: data.unitStructure,
      upperCoverageLevel: data.upperCoverageLevel,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };
    return newScenarioPiece;
  };

  const getLatestFormValues = () => {
    const formValues = methods.getValues();
    const scenarioPieceFormValues: ScenarioPiecePlanSelectionFormValues = {
      ...formValues,
      expectedCountyYield: null,
      actualCountyYield: null,
    };
    return scenarioPieceFormValues;
  };

  useEffect(() => {
    let changeIt = false;
    if (scenarioPieceFormSnapshot?.scenarioPiece?.scenarioPieceType !== undefined &&
      scenarioPieceFormSnapshot.scenarioPiece.scenarioPieceType !== selectedScenarioPieceType) {
      changeIt = true;
    } else if (scenarioPiece !== null && scenarioPiece.scenarioPieceType !== selectedScenarioPieceType) {
      changeIt = true;
    }

    if (changeIt) {
      methods.setValue('scenarioPieceType', selectedScenarioPieceType, { shouldDirty: true });
    }
  }, [scenarioPieceFormSnapshot, selectedScenarioPieceType, scenarioPiece]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ScenarioPiecePlanSelection
          scenarioPieceFormSnapshot={scenarioPieceFormSnapshot}
          scenarioPiece={scenarioPiece}
          scenarioId={scenarioId}
          selectedScenarioPieceType={selectedScenarioPieceType}
          updateScenarioPieceFormSnapshot={updateScenarioPieceFormSnapshot}
          scenarioPieceTypePlanSelectionList={scenarioPieceTypePlanSelectionList}
          getScenarioPieceFormValues={getLatestFormValues}
        />
      </Grid>
      <Grid item xs={12}>
        <FormProvider {...methods}>
          <form id={formId} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <IsCatInput isCat={scenarioPiece?.rowCropScenarioPieceExtendedData?.isCat ?? false} disabled={disabled} />
              </Grid>
              <Grid item xs={6}>
                <AdmUpperCoverageLevelInput upperCoverageLevel={scenarioPieceFormSnapshot?.scenarioPiece?.upperCoverageLevel ?? scenarioPiece?.upperCoverageLevel ?? null} autoFocus disabled={disabled || isCat} />
              </Grid>
              <Grid item xs={6}>
                <AdmUnitStructureInput unitStructure={scenarioPieceFormSnapshot?.scenarioPiece?.unitStructure ?? scenarioPiece?.unitStructure ?? null} disabled={disabled} />
              </Grid>
              <Grid item xs={6}>
                <AdmProtectionFactorInput protectionFactor={scenarioPieceFormSnapshot?.scenarioPiece?.protectionFactor ?? scenarioPiece?.protectionFactor ?? null} disabled={disabled || isCat} />
              </Grid>
              {shouldShowOptionsByEu(scenarioOptionsForSpForm) &&
                <Grid item xs={6}>
                  <OptionsByEnterpriseUnit scenarioOptionsForSpForm={scenarioOptionsForSpForm} updateEuByOptionSelection={updateEuByOptionSelection} />
                </Grid>
              }
              <Grid item xs={12}>
                <ScenarioPieceOptions
                  scenarioOptionsForSpForm={scenarioOptionsForSpForm}
                  updateOptionSelection={updateOptionSelection}
                />
              </Grid>
              <PriceInputGroup quote={quote} scenario={scenario} scenarioPiece={scenarioPiece} projectedPrice={scenarioPieceFormSnapshot?.projectedPrice ?? scenario?.projectedPrice ?? null} harvestPrice={scenarioPieceFormSnapshot?.harvestPrice ?? scenario?.harvestPrice ?? null} />
              <IndividualYieldInputGroup
                quote={quote}
                scenarioId={scenarioId}
                approvedYield={scenarioPieceFormSnapshot?.expectedYield ?? approvedYield}
                producerYield={scenarioPieceFormSnapshot?.actualYield ?? scenario?.actualProducerYield ?? null}
                rateYield={rateYield}
                aphYield={aphYield} />
              <Grid item xs={12}>
                <IsActiveInput isActive={scenarioPieceFormSnapshot?.scenarioPiece?.isActive ?? scenarioPiece?.isActive ?? null} />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
      </Grid>
    </Grid >
  );
};
