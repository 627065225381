import { useAppSelector, useKeyMapSelector } from './reduxHooks';
import { selectAvailableOptionsForQuote } from '../app/admSlice';
import { selectQuoteById } from '../app/quotesSlice';
import { selectClientFileById } from '../app/clientFilesSlice';
import { QuoteId, ScenarioId, UnitYearId } from '../types/api/PrimaryKeys';
import { selectScenarioById, selectUnitYears } from '../app/scenariosSlice';
import {
  MissingClientFileInStateError,
  MissingPracticeInScenarioError,
  MissingQuoteInStateError, MissingScenarioInStateError
} from '../errors/state/MissingStateErrors';
import {
  selectAllScenarioOptionsByScenarioIdMap,
  selectScenarioOptionUnitYearsByScenarioIdMap
} from '../app/optionsSlice';
import {
  selectAllRowCropScenarioPiecesByScenarioMap
} from '../app/scenarioPiecesSlice';
import { CoverageTypeCode, InsurancePlanCode, ScenarioPieceType } from '@silveus/calculations';
import { OptionLevelCodes } from '../constants/optionLevelCodes';
import { RowCropScenarioPiece } from '../types/api/RowCropScenarioPiece';
import AvailableOptionSelections from '../types/api/adm/AvailableOptionSelections';
import { groupBy } from '../utils/arrayUtils';
import UnitYear from '../types/api/UnitYear';
import { getItemsForId } from '../utils/mapHelpers';
import { Nullable } from '../types/util/Nullable';
import { toPrimaryKey } from '../utils/primaryKeyHelpers';
import { useCallback, useMemo } from 'react';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import OptionState from '../types/app/OptionState';
import { OptionSelectionState } from '../types/app/enums/optionSelectionState.enum';

export const useOptions = (quoteId: QuoteId, scenarioId: Nullable<ScenarioId>) => {
  const scenario = useAppSelector(state => scenarioId === null ? null : selectScenarioById(state, scenarioId));

  const quote = useAppSelector(state => selectQuoteById(state, quoteId));
  if (quote === null) throw new MissingQuoteInStateError(quoteId);

  const clientFile = useAppSelector(state => selectClientFileById(state, quote.clientFileId));
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  const availableOptionsSelectionsForQuote = useAppSelector(state => selectAvailableOptionsForQuote(state, quote.countyId, quote.commodityCode));
  const selectedOptionsForScenarioFromState = useAppSelector(state => getItemsForId(selectAllScenarioOptionsByScenarioIdMap(state), scenarioId) );
  const selectedUnitYearOptionsForScenarioFromState = useAppSelector(state => getItemsForId(selectScenarioOptionUnitYearsByScenarioIdMap(state), scenarioId));

  const rowCropScenarioPiecesFromState = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioId);
  const rowCropScenarioPiecesByPlanCodeMapFromState = useMemo(() => {
    //We filter these out because we don't want them to show the options as unavailable if only they are present
    const rowCropScenarioPieceTypesToIgnore = [ScenarioPieceType.Arc, ScenarioPieceType.MP, ScenarioPieceType.MpHpo, ScenarioPieceType.Plc, ScenarioPieceType.StaxRp, ScenarioPieceType.StaxRpHpe];
    return new Map(rowCropScenarioPiecesFromState.filter(sp => !rowCropScenarioPieceTypesToIgnore.includes(sp.scenarioPieceType)).map(sp => [sp.planCode, sp]));
  }, [rowCropScenarioPiecesFromState]);

  const scenarioUnitYearsFromState = useAppSelector(state => scenario === null ? stableEmptyArrayAsMutable<UnitYear>() : selectUnitYears(state, scenario.quoteId, scenario.typeId, scenario.practiceId, scenario.highRiskTypeId));
  const getOptionStatesForScenario = useCallback(() => {
    if (scenario === null) throw new MissingScenarioInStateError(scenarioId ?? toPrimaryKey<ScenarioId>(''));
    if (scenario.practiceId === null) throw new MissingPracticeInScenarioError(scenario.scenarioId);
    return getOptionStatesForScenarioProps(
      scenario.typeId,
      scenario.practiceId,
      rowCropScenarioPiecesByPlanCodeMapFromState,
      scenarioUnitYearsFromState,
    );
  }, [scenario, rowCropScenarioPiecesByPlanCodeMapFromState, scenarioUnitYearsFromState]);

  const getOptionStatesForScenarioProps = useCallback((
    typeId: string,
    practiceId: string,
    rowCropScenarioPiecesByPlanCodeMap: Map<string, RowCropScenarioPiece>,
    scenarioUnitYears: UnitYear[],
  ) => {
    const availableOptionsSelectionsForScenario = filterAvailableOptionSelectionsForScenario(availableOptionsSelectionsForQuote, typeId, practiceId);
    const distinctOptionCodesAndCorrespondingPlans = groupBy(availableOptionsSelectionsForScenario, ao => ao.option.optionCode);
    const optionStates: OptionState[] = [];
    for (const insurancePlans of distinctOptionCodesAndCorrespondingPlans.values()) {
      const option = insurancePlans[0].option;
      let optionSelectionState = OptionSelectionState.Off;

      const applicableScenarioOption = selectedOptionsForScenarioFromState.find(so => so.option === option.optionCode);
      if (applicableScenarioOption !== undefined) {
        if (option.optionLevelCode === OptionLevelCodes.Unit) {
          const applicableUnitYearOptions = selectedUnitYearOptionsForScenarioFromState.filter(suyo => suyo.scenarioOptionId === applicableScenarioOption.scenarioOptionId &&
            scenarioUnitYears.some(uy => uy.unitYearId === suyo.unitYearId));
          optionSelectionState = applicableUnitYearOptions.length === scenarioUnitYears.length ? OptionSelectionState.On : OptionSelectionState.Partial;
        } else {
          optionSelectionState = OptionSelectionState.On;
        }
      }

      const optionState: OptionState = {
        isAvailable: insurancePlans.some(ip => isOptionAvailable(ip, rowCropScenarioPiecesByPlanCodeMap)),
        selectionState: optionSelectionState,
        option: option,
      };
      optionStates.push(optionState);
    }
    return optionStates;
  }, [availableOptionsSelectionsForQuote, selectedOptionsForScenarioFromState, selectedUnitYearOptionsForScenarioFromState]);

  const getOptionStatesForUnits = useCallback(() => {
    if (scenario === null) throw new Error('getOptionStatesForUnits: Only use this get when Scenario exists in state');
    if (scenario.practiceId === null) throw new MissingPracticeInScenarioError(scenario.scenarioId);
    return getOptionStatesForUnitsProps(
      scenario.typeId,
      scenario.practiceId,
      rowCropScenarioPiecesByPlanCodeMapFromState,
      scenarioUnitYearsFromState,
    );
  }, [scenario, rowCropScenarioPiecesByPlanCodeMapFromState, scenarioUnitYearsFromState]);

  const getOptionStatesForUnitsProps = useCallback((
    typeId: string,
    practiceId: string,
    rowCropScenarioPiecesByPlanCodeMap: Map<string, RowCropScenarioPiece>,
    scenarioUnitYears: UnitYear[],
  ) => {
    const availableOptionsSelectionsForScenario = filterAvailableOptionSelectionsForScenario(availableOptionsSelectionsForQuote, typeId, practiceId);
    const distinctOptionCodesAndCorrespondingPlans = groupBy(availableOptionsSelectionsForScenario, ao => ao.option.optionCode);

    const allUnitOptionStates = new Map<UnitYearId, OptionState[]>();
    for (const scenarioUnitYear of scenarioUnitYears) {
      const unitOptionStates: OptionState[] = [];
      for (const insurancePlans of distinctOptionCodesAndCorrespondingPlans.values()) {
        const option = insurancePlans[0].option;
        let optionSelectionState = OptionSelectionState.Off;

        const applicableScenarioOption = selectedOptionsForScenarioFromState.find(so => so.option === option.optionCode);
        if (applicableScenarioOption !== undefined) {
          if (option.optionLevelCode === OptionLevelCodes.Unit) {
            const selectedUnitYearOption = selectedUnitYearOptionsForScenarioFromState
              .find(suyo => suyo.scenarioOptionId === applicableScenarioOption.scenarioOptionId &&
                suyo.unitYearId === scenarioUnitYear.unitYearId);

            optionSelectionState = selectedUnitYearOption !== undefined ? OptionSelectionState.On : OptionSelectionState.Off;
          } else {
            optionSelectionState = OptionSelectionState.On;
          }
        }

        const optionState: OptionState = {
          isAvailable: insurancePlans.some(ip => isOptionAvailable(ip, rowCropScenarioPiecesByPlanCodeMap)),
          selectionState: optionSelectionState,
          option: option,
        };
        unitOptionStates.push(optionState);
      }

      allUnitOptionStates.set(scenarioUnitYear.unitYearId, unitOptionStates);
    }
    return allUnitOptionStates;
  }, [availableOptionsSelectionsForQuote, selectedOptionsForScenarioFromState, selectedUnitYearOptionsForScenarioFromState]);

  const carryOverOptionStateForScenarioChange = (
    typeId: string,
    practiceId: string,
    newScenarioUnitYears: UnitYear[],
  ) => {
    const availableOptionsSelectionsForScenario = filterAvailableOptionSelectionsForScenario(availableOptionsSelectionsForQuote, typeId, practiceId);
    const distinctOptionCodesAndCorrespondingPlans = groupBy(availableOptionsSelectionsForScenario, ao => ao.option.optionCode);
    //We pass in the unit years from state because we want to use the OLD unit year option selections to determine which unit level options to carry-over
    const existingScenarioOptionState = getOptionStatesForScenarioProps(typeId, practiceId, new Map<string, RowCropScenarioPiece>(), scenarioUnitYearsFromState);
    const newOptionStateForScenarioUnits = new Map<UnitYearId, OptionState[]>();
    for (const scenarioUnitYear of newScenarioUnitYears) {

      const unitOptionStates: OptionState[] = [];
      for (const insurancePlans of distinctOptionCodesAndCorrespondingPlans.values()) {
        const option = insurancePlans[0].option; // Option will be the same in all insurance plan objects
        let optionSelectionState = OptionSelectionState.Off;

        const applicableScenarioOption = selectedOptionsForScenarioFromState.find(so => so.option === option.optionCode);
        if (applicableScenarioOption !== undefined) {
          //Scenario Option Found
          if (option.optionLevelCode === OptionLevelCodes.Unit){
            //Unit Level Option so we need to see if this unit should have it
            const previousScenarioOptionState = existingScenarioOptionState.find(o => o.option.optionCode === option.optionCode);
            const selectedUnitYearOption = selectedUnitYearOptionsForScenarioFromState
              .find(suyo => suyo.scenarioOptionId === applicableScenarioOption.scenarioOptionId &&
                suyo.unitYearId === scenarioUnitYear.unitYearId);

            //If the unit had options selected, turn the option on for the unit, otherwise if the previous Scenario Option State was ON and not Partial, then set all units to have this unit option turned on
            optionSelectionState = selectedUnitYearOption !== undefined || (previousScenarioOptionState !== undefined &&
            previousScenarioOptionState.selectionState === OptionSelectionState.On) ? OptionSelectionState.On : OptionSelectionState.Off;
          } else {
            //Option is not unit level so as long as Scenario Options has it, we mark it as on for all units.
            optionSelectionState = OptionSelectionState.On;
          }
        }

        const optionState: OptionState = {
          isAvailable: insurancePlans.some(ip => isOptionAvailable(ip, new Map<string, RowCropScenarioPiece>())),
          selectionState: optionSelectionState,
          option: option,
        };
        unitOptionStates.push(optionState);
      }

      newOptionStateForScenarioUnits.set(scenarioUnitYear.unitYearId, unitOptionStates);
    }

    const allExistingUnitOptionStates = Array.from(getOptionStatesForUnits().values()).flat();
    const allNewUnitOptionStates = Array.from(newOptionStateForScenarioUnits.values()).flat();
    const newOptionStateForScenario = existingScenarioOptionState.map(os => {
      //Use the new unit option state to help determine the new scenario option state
      const applicableOldUnitOptionStates = allExistingUnitOptionStates.filter(o => o.option.optionCode === os.option.optionCode);
      const applicableNewUnitOptionStates = allNewUnitOptionStates.filter(o => o.option.optionCode === os.option.optionCode);
      let newSelectionState = OptionSelectionState.Off;

      //Set scenario option to whatever the previous state was if we have zero old units and zero new units
      if (applicableNewUnitOptionStates.length === 0 && applicableOldUnitOptionStates.length === 0) newSelectionState = os.selectionState;

      //Set scenario option to on when we don't have units we're porting to but every OLD unit has this option on
      if (applicableNewUnitOptionStates.length === 0 && applicableOldUnitOptionStates.length !== 0 && applicableOldUnitOptionStates.every(uo => uo.selectionState !== OptionSelectionState.Off)) newSelectionState = OptionSelectionState.On;

      //Set scenario option to on when we have units we're porting to and every NEW unit has this option on
      if (applicableNewUnitOptionStates.length > 0 && applicableNewUnitOptionStates.every(uo => uo.selectionState !== OptionSelectionState.Off)) newSelectionState = OptionSelectionState.On;

      //Set scenario option to partial when we have units we're porting to and only some units have this option
      if (applicableNewUnitOptionStates.some(uo => uo.selectionState !== OptionSelectionState.Off)) newSelectionState = OptionSelectionState.Partial;
      return {
        ...os,
        selectionState: newSelectionState,
      };
    });

    return { newOptionStateForScenario: newOptionStateForScenario, newOptionStateForScenarioUnits };
  };

  const isOptionAvailable = (availableOption: AvailableOptionSelections, rowCropScenarioPiecesByPlanCodeMap: Map<string, RowCropScenarioPiece>) => {
    //If we don't have any row-crop scenario pieces, show all options as available
    if (rowCropScenarioPiecesByPlanCodeMap.size === 0) return true;

    const rowCropScenarioPiece = rowCropScenarioPiecesByPlanCodeMap.get(availableOption.insurancePlanCode);
    const coverageTypeCode = (rowCropScenarioPiece?.rowCropScenarioPieceExtendedData?.isCat ?? false) ? CoverageTypeCode.C : CoverageTypeCode.A;

    //If a scenario has a MPCI scenario piece, we need to mark options that aren't for that plan as not-available
    return rowCropScenarioPiece !== undefined && availableOption.coverageTypeCode === coverageTypeCode;
  };

  const filterAvailableOptionSelectionsForScenario = (countyCommodityAvailableOptions: AvailableOptionSelections[], typeId: string, practiceId: string) => {
    //We don't want to display options such as Tropical Storm in our regular option dropdowns so we filter the options down to these few insurance plans.
    const mpciIndividualPlanCodes: string[] = [InsurancePlanCode.APH, InsurancePlanCode.RP, InsurancePlanCode.RPHPE, InsurancePlanCode.YP];
    return countyCommodityAvailableOptions.filter(ao => ao.typeId === typeId && ao.practiceId === practiceId && mpciIndividualPlanCodes.includes(ao.insurancePlanCode));//
  };

  return {
    getOptionStatesForScenario,
    getOptionStatesForUnits,
    getOptionStatesForScenarioProps,
    getOptionStatesForUnitsProps,
    carryOverOptionStateForScenarioChange,
  };
};
