import { GridApi } from 'ag-grid-community';
import UnitsSummary from '../../types/app/UnitsSummary';

export const generatePinnedBottomData = (unitsSummary: UnitsSummary, gridApi: GridApi) => {
  const result: Record<string, unknown> = {};
  const allColumns = gridApi.getAllGridColumns();
  allColumns.forEach(col => {
    const colId = col.getColId();
    result[colId] = null;
  });

  result['acres'] = unitsSummary.acres;
  result['sharePercent'] = unitsSummary.sharePercent;
  result['rateYield'] = unitsSummary.weightedAverageRateYield;
  result['approvedYield'] = unitsSummary.approvedYield;
  result['adjustedYield'] = unitsSummary.adjustedYield;

  return result;
};
