import { quoterDb } from '../../db';
import Matrix from '../../types/api/Matrix';
import { MatrixId, ScenarioId } from '../../types/api/PrimaryKeys';
import {
  createMatrix,
  deleteMatrix,
  getMatricesByScenarioIds,
  updateMatricesBatch,
  updateMatrix
} from '../matrixService';
import { addSingle, getDefaultCachingStrategy, remove, update } from '../offlineDataAccess.service';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';

const table = quoterDb.matrices;

export const getMatricesByScenarioIdsRequest = async (scenarioIds: ScenarioId[]): Promise<Matrix[]> => {
  const request = () => getMatricesByScenarioIds(scenarioIds);

  const transactionTables = [quoterDb.matrices];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const matrices = safeWhere(quoterDb.matrices, 'primaryScenarioId').anyOf(scenarioIds).toArray();
    return matrices;
  });

  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const deleteMatrixRequest = async (matrixId: MatrixId): Promise<void> => {
  const request = () => deleteMatrix(matrixId);
  return await remove(table, matrixId, request);
};

export const createMatrixRequest = async (matrix: Matrix): Promise<CreatedItemResult<MatrixId>> => {
  const request = () => createMatrix(matrix);
  return await addSingle(table, matrix, request);
};

export const updateMatrixRequest = async (matrix: Matrix): Promise<void> => {
  const request = () => updateMatrix(matrix);
  return await update(table, matrix, request);
};

export const updateMatricesBatchRequest = async (matrices: Matrix[]): Promise<void> => {
  const request = () => updateMatricesBatch(matrices);
  return await update(table, matrices, request);
};
