
import { DefaultFormSelection, FormObject } from '../../components/formWrapper/formWrapper.component';
import TabbedFormWrapper from '../../components/formWrapper/tabbedFormWrapper.component';
import { MatrixId, ScenarioId } from '../../types/api/PrimaryKeys';
import { Nullable } from '../../types/util/Nullable';
import { MatrixForm } from './matrixForm.component';
import MatrixPresetsFormWrapper from './presets/matrixPresetsFormWrapper.component';

export type MatrixFormWrapperProps = {
  matrixId: Nullable<MatrixId>;
  primaryScenarioId: ScenarioId;
  scenarioIds: ScenarioId[];
  defaultFormSelection?: DefaultFormSelection;
}

const MatrixFormWrapper = ({ matrixId, primaryScenarioId, scenarioIds, defaultFormSelection }: MatrixFormWrapperProps) => {
  const forms: FormObject[] = [
    { formName: 'Matrix', formComponent: props => <MatrixForm matrixId={matrixId} primaryScenarioId={primaryScenarioId} scenarioIds={scenarioIds} key={matrixId} {...props} /> },
    { formName: 'Matrix Presets', formComponent: props => <MatrixPresetsFormWrapper matrixId={matrixId} key={matrixId} {...props} /> },
  ];

  return (
    <TabbedFormWrapper key={primaryScenarioId} forms={forms} defaultFormSelection={defaultFormSelection} />
  );
};

export default MatrixFormWrapper;