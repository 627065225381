import {
  getAvailableHistoricalTYields,
  getAvailableInsuranceOfferSelections,
  getAvailableSubCountyCodes,
  getAvailableYeYears, getHipHurricaneEvent,
  getHistoricalPrices, getHistoricalYieldTrends,
  getHistoricalYieldTrendYears, getHistoricalStormEvents,
  getOfferAvailabilitiesForQuote,
  getPriceGroupMembers,
  getScenarioPricesAndYields,
  getTrendAdjustmentFactors, getTYields,
  getYears,
  getInsuranceCalendars,
  getPriceGroups,
  getPriceDiscoveries,
  getCeppMappings,
  getCountyYieldInfo,
  getHistoricalInputCosts,
  getMyaPriceHistories, getAvailableInsurancePlanCodes, getAvailableOptionSelections
} from '../adm.service';
import { InsuranceOfferParams } from '../../types/api/adm/InsuranceOfferParams';
import AvailableInsuranceOfferSelections from '../../types/api/adm/AvailableInsuranceOfferSelections';
import { ADMYear } from '../../types/api/adm/ADMYear';
import HistoricalPrice from '../../types/api/adm/HistoricalPrice';
import HistoricalYieldTrendYear from '../../types/api/adm/HistoricalYieldTrendYear';
import { cacheOnly, networkOnly, networkOnlyAndUpdate } from '../../utils/cachingStrategies';
import { admDb, iceDb } from '../../db';
import {
  getAvailableInsuranceOffers,
  getBulkPricesAndYields,
  getBulkTYield,
  getHistoricalStormEventsData,
  getCoverageLevels, getHistoricalTYields, getHistoricalYieldTrendsData, getHistoricalYieldTrendYearsData,
  getInsuranceOfferInformation, getInsuranceOffersForCommodityAndCounty, getOptions,
  getPriceElections, getPriceGroupMembersData, getPriceHistoriesData,
  getPricesAndYields, getSubCountyCodes, getTrendAdjustmentFactorsData, getTYield, getYeYears
} from '../localCalcDataQueries.service';
import { InsurancePlanCode, UnitStructureCode } from '@silveus/calculations';
import CoverageLevelBasedSelections from '../../types/api/adm/CoverageLevelBasedSelections';
import TYield from '../../types/api/adm/TYield';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { isOnlineFromStore } from '../../utils/isOnlineFromStore';
import { getDefaultCachingStrategy } from '../offlineDataAccess.service';
import { Option } from '../../types/api/adm/Option';
import { distinctBy, safeArrayAccess } from '../../utils/arrayUtils';
import { getOfferAvailability as getRpOfferAvailability } from './scenarioPieces/rpRequestInterceptor';
import { getOfferAvailability as getAphOfferAvailability } from './scenarioPieces/aphRequestInterceptor';
import { getOfferAvailability as getScoOfferAvailability } from './scenarioPieces/scoRequestInterceptor';
import { getOfferAvailability as getEcoOfferAvailability } from './scenarioPieces/ecoRequestInterceptor';
import { getOfferAvailability as getStaxOfferAvailability } from './scenarioPieces/staxRequestInterceptor';
import { getOfferAvailability as getIceOfferAvailability } from './scenarioPieces/iceRequestInterceptor';
import { getOfferAvailability as getEcoScoPlusOfferAvailability } from './scenarioPieces/ecoScoPlusRequestInterceptor';
import { getOfferAvailability as getArpOfferAvailability } from './scenarioPieces/arpRequestInterceptor';
import { getOfferAvailability as getArpHpeOfferAvailability } from './scenarioPieces/arpHpeRequestInterceptor';
import { getOfferAvailability as getAypOfferAvailability } from './scenarioPieces/aypRequestInterceptor';
import { Nullable } from '../../types/util/Nullable';
import { safeGet } from '../../utils/dexieQueryHelpers/getClauses';
import { ScenarioPricesAndYieldsParams } from '../../types/api/adm/ScenarioPricesAndYieldsParams';
import ScenarioPricesAndYields from '../../types/api/adm/ScenarioPricesAndYields';
import { isNullOrUndefined } from '../../utils/nullHandling';
import AdmDataForQuoteParams from '../../types/api/adm/AdmDataForQuoteParams';
import { buildCountyAndCommodityKey } from '../../types/app/CompositeKeys';
import QuoteProductOfferAvailabilities from '../../types/api/adm/QuoteProductOfferAvailabilities';
import QuoteTrendAdjustmentFactors from '../../types/api/adm/QuoteTrendAdjustmentFactors';
import QuoteYeYears from '../../types/api/adm/QuoteYeYears';
import QuoteHistoricalTYields from '../../types/api/adm/QuoteHistoricalTYields';
import QuotePriceGroupMembers from '../../types/api/adm/QuotePriceGroupMembers';
import QuoteHistoricalYieldTrends from '../../types/api/adm/QuoteHistoricalYieldTrends';
import QuoteHistoricalStormEvents from '../../types/api/adm/QuoteHistoricalStormEvents';
import QuoteInsuranceCalendars from '../../types/api/adm/QuoteInsuranceCalendars';
import PriceGroup from '../../types/api/adm/PriceGroup';
import { PriceGroupId } from '../../types/api/PrimaryKeys';
import RMAPriceDiscovery from '../../types/api/adm/RMAPriceDiscovery';
import { CeppMappings } from '../../types/api/adm/CeppMappings';
import YieldInfoWithCountiesParams from '../../types/api/adm/YieldInfoWithCountiesParams';
import YieldInfoWithCounties from '../../types/api/adm/YieldInfoWithCounties';
import { MYAPriceHistory } from '../../types/api/adm/MYAPriceHistory';
import QuoteAvailableOptionSelections from '../../types/api/adm/QuoteAvailableOptionSelections';

export const getYearsRequest = async (): Promise<ADMYear[]> => {
  const request = () => getYears();

  const transactionTables = [
    admDb.supportedAdmYears,
    admDb.states,
    admDb.counties,
    admDb.commodities,
    admDb.practices,
    admDb.cropTypes,
    admDb.commodityUses,
  ];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const supportedAdmYears = admDb.supportedAdmYears.toArray();
    const states = admDb.states.toArray();
    const counties = admDb.counties.toArray();
    const commodities = admDb.commodities.toArray();
    const practices = admDb.practices.toArray();
    const cropTypes = admDb.cropTypes.toArray();
    const commodityUses = admDb.commodityUses.toArray();

    const results = await Promise.all([supportedAdmYears, states, counties, commodities, practices, cropTypes, commodityUses]);

    if (results.some(r => r.length === 0)) return [];

    const admYears: ADMYear[] = results[0].map(supportedAdmYear => {
      const admYear = {
        year: supportedAdmYear.year,
        states: results[1],
        counties: results[2],
        commodities: results[3],
        practices: results[4],
        cropTypes: results[5],
        commodityUses: results[6],
      };

      return admYear;
    });

    return admYears;
  });

  const updateTransaction = (newData: ADMYear[]) => {
    return admDb.transaction('rw', transactionTables, () => {
      admDb.states.bulkPut(newData.flatMap(d => d.states));
      admDb.counties.bulkPut(newData.flatMap(d => d.counties));
      admDb.commodities.bulkPut(newData.flatMap(d => d.commodities));
      admDb.practices.bulkPut(newData.flatMap(d => d.practices));
      admDb.cropTypes.bulkPut(newData.flatMap(d => d.cropTypes));
      admDb.commodityUses.bulkPut(newData.flatMap(d => d.commodityUses));
    });
  };

  const strategy = isOnlineFromStore() ? networkOnlyAndUpdate : cacheOnly;

  return await strategy(request, readTransaction, updateTransaction);
};

export const getAvailableInsuranceOfferSelectionsRequest = async (insuranceOfferParams: InsuranceOfferParams): Promise<Nullable<AvailableInsuranceOfferSelections>> => {
  const request = () => getAvailableInsuranceOfferSelections(insuranceOfferParams);

  const readTransaction = async (): Promise<Nullable<AvailableInsuranceOfferSelections>> => {
    const commodityCode = insuranceOfferParams.typeId.substring(0, 4);
    const stateCode = insuranceOfferParams.countyId.substring(0, 2);

    const admTransactionTables = [
      admDb.insuranceOffers,
      admDb.coverageLevels,
      admDb.areaCoverageLevels,
      admDb.prices,
      admDb.yieldAndTYields,
      admDb.coverageLevelDifferentials,
      admDb.optionRates,
      admDb.options,
    ];

    const priceElections = await iceDb.transaction('r', iceDb.priceElectionPercents, async () => {
      return await getPriceElections(insuranceOfferParams.insurancePlanCode, commodityCode, insuranceOfferParams.coverageTypeCode, stateCode);
    });

    const admData = admDb.transaction('r', admTransactionTables, async () => {
      const insuranceOffer = await getInsuranceOfferInformation(insuranceOfferParams.insurancePlanCode, insuranceOfferParams.countyId, insuranceOfferParams.typeId, insuranceOfferParams.practiceId, insuranceOfferParams.coverageTypeCode);

      if (insuranceOffer === null) return null;

      const coverageLevelsTask = getCoverageLevels(insuranceOffer.insuranceOfferId, insuranceOffer.admInsuranceOfferId, insuranceOfferParams.coverageTypeCode, insuranceOfferParams.highRiskTypeId);
      const pricesAndYieldsTask = getPricesAndYields(insuranceOffer.insuranceOfferId, insuranceOffer.insurancePlanCode);
      const tYieldTask = getTYield(insuranceOffer.insuranceOfferId);
      const optionsTask = getOptions(insuranceOffer.insuranceOfferId);

      const [coverageLevels, options] = await Promise.all([coverageLevelsTask, optionsTask]);

      const coverageLevelDependentInfo: CoverageLevelBasedSelections[] = coverageLevels.flatMap(cov => {
        const applicablePriceElection = priceElections.find(pe => pe.coverageLevelPercent === cov.coverageLevelPercent);

        if (applicablePriceElection === undefined) throw Error('Unable to find applicable price election');

        const lowerCoverageLevels = coverageLevels.filter(cl => cl.upperCoverageLevel === cov.upperCoverageLevel).map(cl => cl.lowerCoverageLevel);

        const applicableOptions = distinctBy(options.filter(opt => opt.coverageLevel === cov.coverageLevelId || opt.coverageLevel === null).map(opt => {
          const option: Option = {
            optionCode: opt.optionCode,
            optionName: opt.optionName,
            optionLevelCode: opt.optionLevelCode,
          };
          return option;
        }), option => option.optionCode);

        return lowerCoverageLevels.map(lowerCoverage => {
          return {
            coverageLevelId: cov.coverageLevelId,
            upperCoverageLevel: cov.upperCoverageLevel,
            lowerCoverageLevel: lowerCoverage,
            upperProtectionFactor: applicablePriceElection.upperPriceElection,
            lowerProtectionFactor: applicablePriceElection.lowerPriceElection,
            options: applicableOptions,
          };
        });
      });

      let unitStructures = [];

      if (insuranceOffer.basicUnitAllowedFlag === 'Y') unitStructures.push(UnitStructureCode.BU);
      if (insuranceOffer.enterpriseUnitAllowedFlag === 'Y') unitStructures.push(UnitStructureCode.EU);
      if (insuranceOffer.optionalUnitAllowedFlag === 'Y') unitStructures.push(UnitStructureCode.OU);

      const pricesAndYields = await pricesAndYieldsTask;
      const tYield = await tYieldTask;

      const availableInsuranceOfferSelections: AvailableInsuranceOfferSelections = {
        unitStructures: unitStructures,
        coverageLevelBasedSelections: coverageLevelDependentInfo,
        projectedPrice: pricesAndYields?.projectedPrice ?? null,
        harvestPrice: pricesAndYields?.harvestPrice ?? null,
        priceVolatilityFactor: pricesAndYields?.priceVolatilityFactor ?? null,
        expectedIndexValue: pricesAndYields?.expectedIndexValue ?? null,
        finalIndexValue: pricesAndYields?.finalIndexValue ?? null,
        transitionalYield: tYield,
      };

      return availableInsuranceOfferSelections;
    });

    return admData;
  };

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getScenarioPricesAndYieldsRequest = async (scenarioPricesAndYieldsParams: ScenarioPricesAndYieldsParams): Promise<Nullable<ScenarioPricesAndYields>> => {
  const request = () => getScenarioPricesAndYields(scenarioPricesAndYieldsParams);

  const readTransaction = async (): Promise<Nullable<ScenarioPricesAndYields>> => {

    const admTransactionTables = [
      admDb.insuranceOffers,
      admDb.coverageLevelDifferentials,
      admDb.prices,
      admDb.yieldAndTYields,
    ];

    const admData = admDb.transaction('r', admTransactionTables, async () => {

      const availableInsuranceOffers = await getAvailableInsuranceOffers(scenarioPricesAndYieldsParams.countyId, scenarioPricesAndYieldsParams.typeId,
        scenarioPricesAndYieldsParams.practiceId, scenarioPricesAndYieldsParams.coverageTypeCode);

      const insuranceOfferIds = availableInsuranceOffers.filter(offer => offer.insurancePlanCode !== InsurancePlanCode.MP && offer.insurancePlanCode !== InsurancePlanCode.MP_HPO).map(offer => offer.insuranceOfferId);
      if (isNullOrUndefined(insuranceOfferIds) || insuranceOfferIds.length < 1) return null;

      const pricesAndYieldsTask = getBulkPricesAndYields(insuranceOfferIds, availableInsuranceOffers);
      const tYieldTask = getBulkTYield(insuranceOfferIds, availableInsuranceOffers, scenarioPricesAndYieldsParams.year);

      const pricesAndYields = await pricesAndYieldsTask;
      const tYields = await tYieldTask;

      const sortedPricesAndYields = pricesAndYields.sort((a, b) => parseInt(a.insurancePlanCode) < parseInt(b.insurancePlanCode) ? 1 : -1);

      let underlyingPlanCode: Nullable<string> = null;
      for (const planCode of scenarioPricesAndYieldsParams.insurancePlanCodes) {
        if (underlyingPlanCode === null || parseInt(planCode) < parseInt(underlyingPlanCode)) {
          underlyingPlanCode = planCode;
        }
      }

      const underlyingPlanPricesAndYields = safeArrayAccess(sortedPricesAndYields.filter(price => price.insurancePlanCode === underlyingPlanCode), 0);

      let mainPrices = underlyingPlanPricesAndYields;
      if (isNullOrUndefined(mainPrices?.projectedPrice)) {
        const filteredPricesAndYields = sortedPricesAndYields.filter(price => {
          return price.projectedPrice !== null && price.priceVolatilityFactor !== null;
        });
        mainPrices = safeArrayAccess(filteredPricesAndYields, 0);
      }

      //If still undefined/null, relax the filter and try again
      if (isNullOrUndefined(mainPrices?.projectedPrice)) {
        const filteredPricesAndYields = sortedPricesAndYields.filter(price => price.projectedPrice !== null);
        mainPrices = safeArrayAccess(filteredPricesAndYields, 0);
      }

      let countyYields = underlyingPlanPricesAndYields;
      if (isNullOrUndefined(countyYields?.expectedIndexValue)) {
        const filteredPricesAndYields = sortedPricesAndYields.filter(price => price.expectedIndexValue !== null && scenarioPricesAndYieldsParams.insurancePlanCodes.indexOf(price.insurancePlanCode));
        countyYields = safeArrayAccess(filteredPricesAndYields, 0);
      }

      if (isNullOrUndefined(countyYields?.expectedIndexValue)) {
        const ecoScoPlanCodes = [InsurancePlanCode.ECO_RP, InsurancePlanCode.ECO_RPHPE, InsurancePlanCode.ECO_YP, InsurancePlanCode.SCO_RP, InsurancePlanCode.SCO_RPHPE, InsurancePlanCode.SCO_YP];
        const filteredPricesAndYields = sortedPricesAndYields.filter(price => price.expectedIndexValue !== null
          && price.insurancePlanCode in ecoScoPlanCodes);
        countyYields = safeArrayAccess(filteredPricesAndYields, 0);
      }

      if (isNullOrUndefined(countyYields?.expectedIndexValue)) {
        const filteredPricesAndYields = sortedPricesAndYields.filter(price => price.expectedIndexValue !== null);
        countyYields = safeArrayAccess(filteredPricesAndYields, 0);
      }

      let mpCosts = underlyingPlanPricesAndYields;
      if (isNullOrUndefined(mpCosts?.expectedInputCosts)) {
        const filteredPricesAndYields = sortedPricesAndYields.filter(price => price.expectedInputCosts !== null && scenarioPricesAndYieldsParams.insurancePlanCodes.indexOf(price.insurancePlanCode));
        mpCosts = safeArrayAccess(filteredPricesAndYields, 0);
      }

      if (isNullOrUndefined(mpCosts?.expectedInputCosts)) {
        const filteredPricesAndYields = sortedPricesAndYields.filter(price => price.expectedInputCosts !== null);
        mpCosts = safeArrayAccess(filteredPricesAndYields, 0);
      }

      const sortedTYields = tYields.sort((a, b) => parseInt(a.insurancePlanCode) < parseInt(b.insurancePlanCode) ? 1 : -1);
      let tYield = safeArrayAccess(sortedTYields.filter(t => t.insurancePlanCode === underlyingPlanCode), 0);
      if (isNullOrUndefined(tYield?.transitionalYield)) {
        const filteredTYields = sortedTYields.filter(t => t.transitionalYield !== null);
        tYield = safeArrayAccess(filteredTYields, 0);
      }

      const scenarioPricesAndYields: ScenarioPricesAndYields = {
        insuranceOfferId: mainPrices?.insuranceOfferId ?? null,
        insurancePlanCode: mainPrices?.insurancePlanCode ?? null,
        projectedPrice: mainPrices?.projectedPrice ?? null,
        harvestPrice: mainPrices?.harvestPrice ?? null,
        priceVolatilityFactor: mainPrices?.priceVolatilityFactor ?? null,
        expectedIndexValue: countyYields?.expectedIndexValue ?? null,
        finalIndexValue: countyYields?.finalIndexValue ?? null,
        transitionalYield: tYield?.transitionalYield ?? null,
        expectedInputCosts: mpCosts?.expectedInputCosts ?? null,
        actualInputCosts: mpCosts?.actualInputCosts ?? null,
        mpProjectedPrice: mpCosts?.projectedPrice ?? null,
      };

      return scenarioPricesAndYields;
    });

    return admData;
  };

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getAvailableSubCountyCodesRequest = async (year: number, countyId: string, typeId: string, practiceId: string): Promise<string[]> => {
  if (practiceId === '') {
    return [];
  }

  const request = () => getAvailableSubCountyCodes(year, countyId, typeId, practiceId);

  const transactionTables = [admDb.subCountyRates, admDb.insuranceOffers];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    return await getSubCountyCodes(year, countyId, practiceId, typeId);
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getAvailableInsurancePlanCodesRequest = async (year: number, countyId: string, typeId: string, practiceId: string): Promise<string[]> => {
  if ([countyId, typeId, practiceId].includes('')) {
    return [];
  }

  const request = () => getAvailableInsurancePlanCodes(year, countyId, typeId, practiceId);
  return networkOnly(request);
};

export const getAvailableHistoricalTYieldsRequest = async (params: AdmDataForQuoteParams[]): Promise<QuoteHistoricalTYields[]> => {
  const request = () => getAvailableHistoricalTYields(params);

  const transactionTables = [admDb.insuranceOffers, admDb.yieldAndTYields];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const allHistoricalTYields: QuoteHistoricalTYields[] = [];
    for (const tYieldParams of params) {
      const tYields = await getHistoricalTYields(tYieldParams);
      const historicalTYields: QuoteHistoricalTYields = {
        countyCommodityKey: buildCountyAndCommodityKey(tYieldParams.countyId, tYieldParams.commodityCode),
        historicalTYields: tYields,
      };
      allHistoricalTYields.push(historicalTYields);
    }

    return allHistoricalTYields;

  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getInsuranceCalendarsRequest = async (params: AdmDataForQuoteParams[]): Promise<QuoteInsuranceCalendars[]> => {
  const request = () => getInsuranceCalendars(params);

  const transactionTables = [admDb.insuranceOffers, admDb.insuranceCalendars, admDb.commodities, admDb.cropTypes];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const allInsuranceCalendars: QuoteInsuranceCalendars[] = [];

    for (const insuranceCalendarsParams of params) {
      const commodityCode = insuranceCalendarsParams.commodityCode;
      const countyId = insuranceCalendarsParams.countyId;
      const cropTypeIds = (await safeWhere(admDb.cropTypes, { commodityCode }).toArray())
        .map(x => x.typeId);
      const insuranceOfferIds = (await safeWhere(admDb.insuranceOffers, { countyId })
        .and(x => cropTypeIds.includes(x.typeId)).toArray()).map(x => x.insuranceOfferId);
      const insuranceCalendarsAll = safeWhere(admDb.insuranceCalendars, 'insuranceOfferId').anyOf(insuranceOfferIds);
      const insuranceCalendars = (await insuranceCalendarsAll.toArray());

      const quoteInsuranceCalendars: QuoteInsuranceCalendars = {
        countyCommodityKey:
          buildCountyAndCommodityKey(insuranceCalendarsParams.countyId, insuranceCalendarsParams.commodityCode),
        insuranceCalendars: insuranceCalendars,
      };

      allInsuranceCalendars.push(quoteInsuranceCalendars);
    }

    return allInsuranceCalendars;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getAvailableYeYearsRequest = async (params: AdmDataForQuoteParams[]): Promise<QuoteYeYears[]> => {
  const request = () => getAvailableYeYears(params);

  const transactionTables = [admDb.insuranceOffers, admDb.yieldExclusions];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const allYeYears: QuoteYeYears[] = [];
    for (const yeYearParams of params) {
      const yeYearsForParams = await getYeYears(yeYearParams);
      const yeYears: QuoteYeYears = {
        countyCommodityKey: buildCountyAndCommodityKey(yeYearParams.countyId, yeYearParams.commodityCode),
        yeYears: yeYearsForParams,
      };
      allYeYears.push(yeYears);
    }

    return allYeYears;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getTrendAdjustmentFactorsRequest = async (params: AdmDataForQuoteParams[]): Promise<QuoteTrendAdjustmentFactors[]> => {
  const request = () => getTrendAdjustmentFactors(params);

  const transactionTables = [admDb.insuranceOffers, admDb.optionRates];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const allTrendAdjustmentFactors: QuoteTrendAdjustmentFactors[] = [];
    for (const trendAdjustmentFactorParams of params) {
      const trendAdjustmentFactors = await getTrendAdjustmentFactorsData(trendAdjustmentFactorParams);
      const quoteTrendAdjustmentFactor: QuoteTrendAdjustmentFactors = {
        countyCommodityKey: buildCountyAndCommodityKey(trendAdjustmentFactorParams.countyId, trendAdjustmentFactorParams.commodityCode),
        trendAdjustmentFactors: trendAdjustmentFactors,
      };
      allTrendAdjustmentFactors.push(quoteTrendAdjustmentFactor);
    }

    return allTrendAdjustmentFactors;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getOfferAvailabilitiesForQuotesRequest = async (getOfferParams: AdmDataForQuoteParams[]): Promise<QuoteProductOfferAvailabilities[]> => {
  const request = () => getOfferAvailabilitiesForQuote(getOfferParams);

  const insuranceOfferServices = [
    getRpOfferAvailability,
    getAphOfferAvailability,
    getEcoOfferAvailability,
    getScoOfferAvailability,
    getIceOfferAvailability,
    getStaxOfferAvailability,
    getEcoScoPlusOfferAvailability,
    getArpOfferAvailability,
    getArpHpeOfferAvailability,
    getAypOfferAvailability,
  ];

  const readTransaction = async (): Promise<QuoteProductOfferAvailabilities[]> => {
    const allOfferAvailabilities: QuoteProductOfferAvailabilities[] = [];
    for (const offerParams of getOfferParams) {
      const productOfferAvailabilityPromises = insuranceOfferServices.map(offerAvailability => {
        return offerAvailability(offerParams);
      });

      const productOfferAvailabilities = (await Promise.all(productOfferAvailabilityPromises)).flatMap(productOfferAvailabilityYcc => {
        //Array of product offer availabilities for one year, county, crop
        const quoteProductOfferAvailability: QuoteProductOfferAvailabilities = {
          countyCommodityKey: buildCountyAndCommodityKey(offerParams.countyId, offerParams.commodityCode),
          productOfferAvailabilities: productOfferAvailabilityYcc,
        };
        return quoteProductOfferAvailability;
      });

      allOfferAvailabilities.push(...productOfferAvailabilities);
    }

    return allOfferAvailabilities;
  };

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getHistoricalPricesRequest = async (year: number, priceGroupIds: number[]): Promise<HistoricalPrice[]> => {
  const request = () => getHistoricalPrices(year, priceGroupIds);

  const readTransaction = () => admDb.transaction('r', admDb.priceHistories, async () => {
    return getPriceHistoriesData(year, priceGroupIds);
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getHistoricalInputCostsRequest = async (quoteData: AdmDataForQuoteParams[]) => {
  const request = () => getHistoricalInputCosts(quoteData);

  //TODO: add offline
  return (await request()).data;
};

export const getPriceGroupMembersRequest = async (params: AdmDataForQuoteParams[]): Promise<QuotePriceGroupMembers[]> => {
  const request = () => getPriceGroupMembers(params);

  const readTransaction = () => admDb.transaction('r', admDb.priceGroupMembers, async () => {
    const allPriceGroupMembers: QuotePriceGroupMembers[] = [];
    for (const priceGroupMemberParams of params) {
      const priceGroupMembers = await getPriceGroupMembersData(priceGroupMemberParams);
      const quoteTrendAdjustmentFactor: QuotePriceGroupMembers = {
        countyCommodityKey: buildCountyAndCommodityKey(priceGroupMemberParams.countyId, priceGroupMemberParams.commodityCode),
        priceGroupMembers: priceGroupMembers,
      };
      allPriceGroupMembers.push(quoteTrendAdjustmentFactor);
    }

    return allPriceGroupMembers;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getPriceGroupsRequest = async (year: number, priceGroupIds: PriceGroupId[]): Promise<PriceGroup[]> => {
  const request = () => getPriceGroups(year, priceGroupIds);
  //TODO: add offline
  return (await request()).data;
};

export const getPriceDiscoveriesRequest = async (year: number, commodityCodes: string[]): Promise<RMAPriceDiscovery[]> => {
  const request = () => getPriceDiscoveries(year, commodityCodes);
  //TODO: add offline
  return (await request()).data;
};

export const getCeppMappingsRequest = async (): Promise<CeppMappings> => {
  const request = () => getCeppMappings();
  //TODO: add offline
  return (await request()).data;
};

export const getMyaPriceHistoriesRequest = async (year: number): Promise<MYAPriceHistory[]> => {
  const request = () => getMyaPriceHistories(year);

  const transactionTables = [admDb.myaPriceHistories];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    return await safeWhere(admDb.myaPriceHistories, 'year').between(year - 6, year - 1, true, false).toArray();
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getHistoricalYieldTrendsRequest = async (params: AdmDataForQuoteParams[]): Promise<QuoteHistoricalYieldTrends[]> => {
  const request = () => getHistoricalYieldTrends(params);

  const transactionTables = [admDb.insuranceOffers, admDb.historicalYieldTrendYears];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const allHistoricalYieldTrendYears: QuoteHistoricalYieldTrends[] = [];
    for (const historicalYieldTrendParams of params) {
      const historicalYieldTrend = await getHistoricalYieldTrendsData(historicalYieldTrendParams);
      const historicalYieldTrendYears: QuoteHistoricalYieldTrends = {
        countyCommodityKey: buildCountyAndCommodityKey(historicalYieldTrendParams.countyId, historicalYieldTrendParams.commodityCode),
        historicalYieldTrends: historicalYieldTrend,
      };
      allHistoricalYieldTrendYears.push(historicalYieldTrendYears);
    }

    return allHistoricalYieldTrendYears;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getHistoricalYieldTrendYearsRequest = async (year: number, historicalYieldTrendIds: number[]): Promise<HistoricalYieldTrendYear[]> => {
  const request = () => getHistoricalYieldTrendYears(year, historicalYieldTrendIds);

  const readTransaction = () => admDb.transaction('r', admDb.historicalYieldTrendYears, async () => {
    return await getHistoricalYieldTrendYearsData(year, historicalYieldTrendIds);
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getHistoricalStormEventsRequest = async (params: AdmDataForQuoteParams[]): Promise<QuoteHistoricalStormEvents[]> => {
  const request = () => getHistoricalStormEvents(params);

  const transactionTables = [admDb.hipEventHistoricals];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const allHistoricalStormEvents: QuoteHistoricalStormEvents[] = [];
    for (const historicalStormEventsParams of params) {
      const historicalStormEventsData = await getHistoricalStormEventsData(historicalStormEventsParams);
      const historicalStormEvents: QuoteHistoricalStormEvents = {
        countyCommodityKey: buildCountyAndCommodityKey(historicalStormEventsParams.countyId, historicalStormEventsParams.commodityCode),
        historicalStormEvents: historicalStormEventsData,
      };
      allHistoricalStormEvents.push(historicalStormEvents);
    }

    return allHistoricalStormEvents;
  });

  const strategy = getDefaultCachingStrategy();
  return strategy(request, readTransaction);
};

export const getAvailableOptionSelectionsRequest = async (params: AdmDataForQuoteParams[]): Promise<QuoteAvailableOptionSelections[]> => {
  const request = () => getAvailableOptionSelections(params);
  //TODO: add offline
  return (await request()).data;
};

export const getCountyYieldInfoRequest = async (params: YieldInfoWithCountiesParams): Promise<Nullable<YieldInfoWithCounties>> => {
  const request = () => getCountyYieldInfo(params);
  //TODO: add offline
  return (await request()).data;
};

export const getTYieldsRequest = async (year: number, countyId: string, commodityCode: string): Promise<TYield[]> => {
  const request = () => getTYields(year, countyId, commodityCode);

  const transactionTables = [admDb.insuranceOffers, admDb.yieldAndTYields];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const insuranceOffers = await getInsuranceOffersForCommodityAndCounty(countyId, commodityCode).toArray();
    const insuranceOfferIds = insuranceOffers.map(io => io.insuranceOfferId);

    const yields = await safeWhere(admDb.yieldAndTYields, 'insuranceOfferId').anyOf(insuranceOfferIds)
      .and(tYield => tYield.priorCommodityYear === year - 1 && tYield.priorTransitionalAmount !== null).toArray();

    const trendYields: TYield[] = [];

    yields.forEach(ty => {
      const insuranceOffer = insuranceOffers.find(io => io.insuranceOfferId === ty.insuranceOfferId);
      if (insuranceOffer === undefined) return;
      if (ty.transitionalAmount === null) return;

      const tYield: TYield = {
        typeId: insuranceOffer.typeId,
        practiceId: insuranceOffer.practiceId,
        subCountyCode: ty.subCountyCode,
        transitionalYield: ty.transitionalAmount,
      };

      trendYields.push(tYield);
    });

    return trendYields;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getPreDownloadedCountiesRequest = async (): Promise<string[]> => {
  const readTransaction = () => admDb.transaction('r', admDb.insuranceOffers, async () => {
    const insuranceOffers = await admDb.insuranceOffers.toArray();
    const countyIds = distinctBy(insuranceOffers.map(io => io.countyId), x => x);
    return countyIds;
  });

  return cacheOnly(undefined, readTransaction);
};

export const getHipHurricaneEventRequest = async (year: number, countyId: string, typeId: string, practiceId: string, insurancePlanCode: string): Promise<boolean> => {
  const request = () => getHipHurricaneEvent(year, countyId, typeId, practiceId, insurancePlanCode);

  const transactionTables = [
    admDb.insuranceOffers,
    admDb.hipRates,
  ];

  const readTransaction = () => admDb.transaction('r', transactionTables, async () => {
    const insuranceOffer = await safeGet(admDb.insuranceOffers, {
      insurancePlanCode: insurancePlanCode,
      countyId: countyId,
      practiceId: practiceId,
      typeId: typeId,
    });

    if (insuranceOffer === undefined || insuranceOffer.hipRateId === null) return false;

    const hipRate = await safeGet(admDb.hipRates, { hipRateId: insuranceOffer.hipRateId });

    if (hipRate === undefined) return false;

    return hipRate.hipPaymentFlag === 'Y';
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};
