import { Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';

type HailCardRowProps = {
  title: string | ReactNode;
  value: string | ReactNode;
}

const HailCardRow = ({ title, value }: HailCardRowProps) => {
  return (
    <Grid container alignItems="center" sx={{ pt: '0 !important' }}>
      <Grid item xs={7}>
        <Typography variant="subtitle1">{title}</Typography>
      </Grid>
      <Grid item xs={5} textAlign="right">
        <Typography variant="subtitle2">{value}</Typography>
      </Grid>
    </Grid>
  );
};

export default HailCardRow;