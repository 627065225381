import { Grid, Typography } from '@mui/material';
import { IntervalRange } from '../../../types/api/adm/IntervalRange';
import { useEffect, useState } from 'react';
import { useFormValues } from '../../../hooks/useFormValues';
import { ScenarioPieceSelectedInterval } from '../../../types/api/adm/ScenarioPieceSelectedInterval';
import { FormInputParameterNames } from '../constants/inputConstants';
import { IntervalCheckboxes } from './intervalCheckboxes.component';
import { IntervalInputFields } from './intervalInput.component';

interface Props {
  availableIntervals: IntervalRange[],
  initialSelectedIntervals: ScenarioPieceSelectedInterval[],
  initialSelectedYear: number,
}

export const YearColumns = ({ availableIntervals, initialSelectedIntervals, initialSelectedYear }: Props) => {
  const { watchFormValue, setValue } = useFormValues<IntervalInputFields>();
  const initialSelectedIntervalIds = initialSelectedIntervals.map(interval => interval.intervalRangeId);
  const selectedYear = watchFormValue.year ?? initialSelectedYear;
  const [previousYear, setPreviousYear] = useState(selectedYear);
  const [intervalYears, setIntervalYears] = useState<number[]>([]);

  useEffect(() => {
    setIntervalYears([...new Set(availableIntervals.map(interval => selectedYear + interval.intervalDeltaYearStart))]);
    if (previousYear !== selectedYear) setValue(FormInputParameterNames.SelectedIntervalsIds, []);
    setPreviousYear(selectedYear);
  }, [selectedYear]);

  return (
    <>
      {intervalYears.map(intervalYear =>
        <Grid key={intervalYear - selectedYear} item xs={6}>
          <Grid direction="column" container>
            <Typography textAlign="center">{intervalYear}</Typography>
            <IntervalCheckboxes
              availableIntervals={availableIntervals.filter(interval => interval.intervalDeltaYearStart === (intervalYear - selectedYear))}
              initialSelectedIntervalIds={initialSelectedIntervalIds}
              selectedYear={selectedYear} />
          </Grid>
        </Grid>)}
    </>);
};
