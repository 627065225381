import { useRef, useState } from 'react';
import { Button, Tab, Tabs } from '@mui/material';
import { Nullable } from '../../types/util/Nullable';
import FormWrapper, { FormWrapperForwardProps, SharedFormWrapperProps } from './formWrapper.component';

export interface TabbedFormProps extends SharedFormWrapperProps {
  shouldScroll?: boolean;
}

const TabbedFormWrapper = ({ forms, newItemForm, registerHeader, defaultFormSelection, shouldScroll = false, handleValidation, isCanceling = false }: TabbedFormProps) => {
  const [formId, setFormId] = useState(defaultFormSelection?.id ?? '');
  const [selectedFormName, setSelectedFormName] = useState(defaultFormSelection?.formName ?? '');
  const childRef = useRef<Nullable<FormWrapperForwardProps>>(null);

  const selectorComponent = (
    <Tabs value={selectedFormName} scrollButtons={shouldScroll} variant={shouldScroll ? 'scrollable' : 'standard'} sx={{
      '.MuiTabs-scrollButtons.Mui-disabled': {
        opacity: 0.3,
      },
    }}>
      {forms.map((form, index) => {
        const formSubmitTabButton =
          <Button
            id="btn-change-form"
            type="submit"
            form={formId}
            disableRipple
            onClick={() => {
              childRef.current?.handleChange(form.formName);
            }}
            sx={{
              color: theme => form.formName === selectedFormName ? theme.palette.primary.main : theme.palette.neutral.main,
              margin: 0,
              pl: 2,
              pr: 2,
              height: '100%',
              width: '100%',
              '&:hover, &.MuiButton-textPrimary': {
                background: 'rgba(0,0,0,0)',
              },
            }}>
            {form.formName}
          </Button>;
        return <Tab label={formSubmitTabButton} key={index} value={form.formName} sx={{ p: 0 }} />;
      })}
    </Tabs>
  );

  return (
    <FormWrapper
      ref={childRef}
      formId={formId}
      setFormId={setFormId}
      setSelectedFormName={setSelectedFormName}
      selectorComponent={selectorComponent}
      forms={forms}
      defaultFormSelection={defaultFormSelection}
      newItemForm={newItemForm}
      registerHeader={registerHeader}
      handleValidation={handleValidation}
      isCanceling={isCanceling}
    />
  );
};

export default TabbedFormWrapper;