import { RowCropScenarioPiece } from '../types/api/RowCropScenarioPiece';
import { ScenarioPiece } from '../types/api/ScenarioPiece';

const doesBaseObjectSupportProperty = <T extends object, U extends T>(obj: T, propertyName: keyof U): obj is U => {
  return propertyName in obj;
};

export const isRowCropScenarioPiece = (scenarioPiece: ScenarioPiece): scenarioPiece is RowCropScenarioPiece => {
  return doesBaseObjectSupportProperty<ScenarioPiece, RowCropScenarioPiece>(scenarioPiece, 'rowCropScenarioPieceId');
};
