import { Box, IconButton } from '@mui/material';
import { getScalingTransformString } from '../../../utils/css/scaling';
import ScenarioCard from '../../scenario/components/scenarioCard.component';
import { RightPanelZoomInput } from '../../../components/applicationZoomInput/RightPanelZoomInput';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectRightPanelZoomPercentage } from '../../../app/applicationZoomSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useState } from 'react';
import { selectSelectedScenarioAnalysisScenarioId } from '../../../app/scenarioAnalysisSlice';
import { selectScenarioById } from '../../../app/scenariosSlice';

export const ScenarioCardColumn = () => {
  const selectedScenarioId = useAppSelector(selectSelectedScenarioAnalysisScenarioId);
  const selectedScenario = useAppSelector(state => selectedScenarioId ? selectScenarioById(state, selectedScenarioId) : null);
  const zoomPercentage = useAppSelector(selectRightPanelZoomPercentage);
  const [collapsed, setCollapsed] = useState(false);

  if (selectedScenario === null) {
    return null;
  }

  return (
    <Box height="100%" sx={{ pl: 0, borderLeft: theme => `1px solid ${theme.palette.grey[400]}`, overflow: 'auto' }}>
      <IconButton sx={{ pl: .5 }} onClick={() => setCollapsed(prev => !prev)}>
        {collapsed ? <ExpandMoreIcon sx={{ rotate: '90deg' }} /> : <ExpandLessIcon sx={{ rotate: '90deg' }} />}
      </IconButton>
      {!collapsed && (
        <>
          <Box overflow="auto" sx={{ pl: 1, pr: 0, transformOrigin: 'top left', transform: getScalingTransformString(zoomPercentage) }}>
            <ScenarioCard
              scenario={selectedScenario}
              isSelectedItem={false}
              isScenarioAnalysisCard={true}
            />
          </Box>
          <Box width="12%" position="absolute" bottom={0}>
            <RightPanelZoomInput />
          </Box>
        </>
      )}
    </Box>
  );
};