import { AdjustmentType, roundToPlaces } from '@silveus/calculations';
import { ForwardSoldScenarioPieceFormFields } from './forwardSoldScenarioPieceForm.component';
import { formatCurrency, formatNumber, formatPercentDigits } from '../../../utils/formatNumbers';
import { parseNumber } from '../../../utils/stringUtils';

export const getProductionAdjustmentType = (index: number, getValues: () => ForwardSoldScenarioPieceFormFields): AdjustmentType => {
  const listOfAdjustments = getValues().transactions.map(x => x.productionAdjustmentTypeId);
  return listOfAdjustments[index];
};

export const getPriceAdjustmentType = (index: number, getValues: () => ForwardSoldScenarioPieceFormFields): AdjustmentType => {
  const listOfAdjustments = getValues().transactions.map(x => x.priceAdjustmentTypeId);
  return listOfAdjustments[index];
};

const trimNumber = (value: number, adjustmentType: AdjustmentType): number => {
  switch (adjustmentType) {
    case AdjustmentType.Fixed:
    case AdjustmentType.PlusMinus:
      return roundToPlaces(value, 2);
    case AdjustmentType.Percent:
      return roundToPlaces(value, 0);
    default:
      throw new Error('invalid adjustment type');
  }
};

export const forwardSoldParseNumber = (value: string, adjustmentType: AdjustmentType): number => {
  const result = trimNumber(parseNumber(value), adjustmentType);

  return result;
};

export const formatProductionDisplayValue = (value: number, adjustmentType: AdjustmentType) => {
  const parsedNumber = trimNumber(value, adjustmentType);

  switch (adjustmentType) {
    case AdjustmentType.Fixed:
    case AdjustmentType.PlusMinus:
      return formatNumber(parsedNumber);
    case AdjustmentType.Percent:
      return formatPercentDigits(parsedNumber / 100);
    default:
      throw new Error('invalid adjustment type');
  }
};

export const formatPriceDisplayValue = (value: number, adjustmentType: AdjustmentType) => {
  const parsedNumber = trimNumber(value, adjustmentType);

  switch (adjustmentType) {
    case AdjustmentType.Fixed:
    case AdjustmentType.PlusMinus:
      return formatCurrency(parsedNumber);
    case AdjustmentType.Percent:
      return formatPercentDigits(parsedNumber / 100);
    default:
      throw new Error('invalid adjustment type');
  }
};
