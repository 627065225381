import axios from 'axios';
import { ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import { CreatedItemResult } from '../types/api/results/CreatedItemResult';
import ForwardSoldScenarioPiece from '../types/api/ForwardSoldScenarioPiece';
import { envBaseApiUrl } from '../constants/envConstants';

export const getForwardSoldScenarioPiece = (scenarioPieceId: ScenarioPieceId) => axios.get<ForwardSoldScenarioPiece>(`${envBaseApiUrl}/forwardSold/${scenarioPieceId}`);
export const getForwardSoldScenarioPiecesByScenarioId = (scenarioId: ScenarioId) => axios.get<ForwardSoldScenarioPiece[]>(`${envBaseApiUrl}/forwardSold/scenarios/${scenarioId}`);
export const getForwardSoldScenarioPiecesByScenarioIds = (scenarioIds: ScenarioId[]) => axios.post<ForwardSoldScenarioPiece[]>(`${envBaseApiUrl}/forwardSold/scenarios`, scenarioIds);
export const addForwardSoldScenarioPiece = (forwardSoldScenarioPiece: ForwardSoldScenarioPiece) => axios.post<CreatedItemResult<ScenarioPieceId>>(`${envBaseApiUrl}/forwardSold`, forwardSoldScenarioPiece);
export const updateForwardSoldScenarioPiece = (forwardSoldScenarioPiece: ForwardSoldScenarioPiece) => axios.put(`${envBaseApiUrl}/forwardSold`, forwardSoldScenarioPiece);
export const deleteForwardSoldScenarioPiece = (scenarioPieceId: ScenarioPieceId) => axios.delete(`${envBaseApiUrl}/forwardSold/${scenarioPieceId}`);