import { RootState } from '../../../app/store';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { createRpData } from './rpDataTransformations';
import BaseUnit from '../../../types/api/BaseUnit';

export const createRpHpeData = async (scenarioPiece: RowCropScenarioPiece,
  scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  return createRpData(scenarioPiece, scenario, state, baseUnits);
};
