import { Guid, NominalKey } from '../types/api/PrimaryKeys';
import { groupBy } from '../utils/arrayUtils';
import { toPrimaryKey } from '../utils/primaryKeyHelpers';

export type KeyedState<TKey extends Guid, TObj> = Partial<Record<TKey, TObj>> & NominalKey<'keyed-state-identifier'>;

export const initialKeyedState = <TKey extends Guid, TObj>(): KeyedState<TKey, TObj> => {
  return {} as KeyedState<TKey, TObj>;
};

/** Warning: Returns a new array every time! Almost always this should be placed behind memoization */
export function getKeyedStateValues<TKey extends Guid, TObj>(state: KeyedState<TKey, TObj>): TObj[] {
  return Object.values(state);
}

/** Convenience method for a somewhat common case of wanting to find pieces of data in normalized state that match some condition.
 * Was originally built to be used with "generateAsyncHandlers"
 * Returns a new array every time!
 */
export function getStateIdsMatching<TKey extends Guid, TObj>(state: KeyedState<TKey, TObj>, predicate: (obj: TObj) => boolean, idSelector: (obj: TObj) => TKey): TKey[] {
  const stateValues = Object.values(state);

  const result: TKey[] = [];

  stateValues.forEach(value => {
    if (predicate(value)) {
      result.push(idSelector(value));
    }
  });

  return result;
}

/**
 * Updates individual items or multiple items within a keyed state collection, identified by unique keys.
 *
 * Note: This function is designed for updating individual objects or for bulk-updating using an array of objects.
 * It does NOT support setting an array as the value for a single key directly. To assign an array as a value
 * to a specific key (e.g., `<Key, ArrayOfObjects[]>`), please use `updateKeyedStateVerbatim`.
 *
 * @param keyedState The state map where keys are of type `TKey` (extending `Guid`) and values are of type `TObj`.
 * Each key-value pair represents an item's current state in the collection.
 *
 * @param itemsToUpdate The item or items to be updated. Can be a single object (`TObj`) or an array of objects
 * (`TObj[]`). The function updates the `keyedState` by replacing the corresponding items based on their keys.
 *
 * @param idSelector A selector function that extracts the key (`TKey`) from an item (`TObj`). This key is used
 * to identify and update the correct item in the `keyedState`.
 *
 */
export function updateKeyedState<TKey extends Guid, TObj>(keyedState: KeyedState<TKey, TObj>, itemsToUpdate: TObj[] | TObj, idSelector: (obj: TObj) => TKey) {
  if (Array.isArray(itemsToUpdate)) {
    itemsToUpdate.forEach(i => {
      updateKeyedStateVerbatim(keyedState, i, idSelector(i));
    });
  } else {
    updateKeyedStateVerbatim(keyedState, itemsToUpdate, idSelector(itemsToUpdate));
  }
}

/**
 * Updates a single item in a keyed state collection.
 *
 * It directly replaces the item associated with the provided `id` in the `keyedState` map.
 *
 * @param keyedState - The state object containing key-value pairs, where keys are of type `TKey` and values of type `TObj`.
 * @param itemToUpdate - The new value to be updated in the `keyedState` for the specified `id`.
 * @param id - The key corresponding to the item in `keyedState` that should be updated.
 */
export function updateKeyedStateVerbatim<TKey extends Guid, TObj>(keyedState: KeyedState<TKey, TObj>, itemToUpdate: TObj, id: TKey) {
  // MARK: This type coercion is a product of having added a nominal key to the KeyedState type.
  // While that nominal key solves some issues, it also introduces new ones, such as this.
  keyedState[id] = itemToUpdate as KeyedState<TKey, TObj>[TKey];
}

export function removeObjectFromKeyedState<TKey extends Guid, TObj>(keyedState: KeyedState<TKey, TObj>, itemsToDelete: TObj[] | TObj, idSelector: (obj: TObj) => TKey) {
  if (Array.isArray(itemsToDelete)) {
    itemsToDelete.forEach(item => removeFromKeyedStateSingle(keyedState, idSelector(item)));
  } else {
    removeFromKeyedStateSingle(keyedState, idSelector(itemsToDelete));
  }
}

export function removeFromKeyedState<TKey extends Guid, TObj>(keyedState: KeyedState<TKey, TObj>, toDelete: TKey | TKey[]) {
  if (Array.isArray(toDelete)) {
    toDelete.forEach(i => removeFromKeyedStateSingle(keyedState, i));
  } else {
    removeFromKeyedStateSingle(keyedState, toDelete);
  }
}

function removeFromKeyedStateSingle<TKey extends Guid, TObj>(keyedState: KeyedState<TKey, TObj>, id: TKey) {
  delete keyedState[id];
}

/** Warning: Returns a new array every time! Almost always this should be placed behind memoization */
export function getKeyedStateGroupedBy<TKey, TObj>(state: KeyedState<Guid, TObj>, keySelector: (i: TObj) => TKey) {
  const allStateValues = getKeyedStateValues(state);
  const result = getKeyedStateArrayGroupedBy(allStateValues, keySelector);
  return result;
}

/** Warning: Returns a new array every time! Almost always this should be placed behind memoization */
export function getTypeGuardedKeyedStateGroupedBy<TKey, TObj, TResult extends TObj>(state: KeyedState<Guid, TObj>, keySelector: (i: TResult) => TKey, typeGuard: (i: TObj) => i is TResult) {
  const filteredStateValues = getKeyedStateValues(state).filter(typeGuard);
  const result = getKeyedStateArrayGroupedBy(filteredStateValues, keySelector);
  return result;
}

/** Warning: Returns a new array every time! Almost always this should be placed behind memoization */
export function getKeyedStateArrayGroupedBy<TKey, TObj>(allStateValues: TObj[], keySelector: (i: TObj) => TKey) {
  const result = groupBy(allStateValues, keySelector);
  return result;
}

/** Warning: Returns a new map every time! Almost always this should be placed behind memoization */
export function getKeyedStateToMap<TKey extends Guid, TObj>(keyedState: KeyedState<TKey, TObj>): Map<TKey, TObj> {
  const map = new Map<TKey, TObj>();
  Object.entries(keyedState).forEach(([key, value]) => {
    map.set(toPrimaryKey<TKey>(key), value);
  });
  return map;
}
