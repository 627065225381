import axios from 'axios';
import { envBaseApiUrl } from '../constants/envConstants';
import { TemplateScenario } from '../types/api/template-scenarios/TemplateScenario';
import { TemplateScenarioId } from '../types/api/PrimaryKeys';

/** Returns all template scenarios for the current user. */
export const getTemplateScenariosForUser = () => axios.get<TemplateScenario[]>(`${envBaseApiUrl}/template-scenarios`);

/** Adds a new template scenario for the current user. */
export const createTemplateScenario = (templateScenario: TemplateScenario) => axios.post<void>(`${envBaseApiUrl}/template-scenarios`, templateScenario);

/** Updates an existing template scenario. */
export const updateTemplateScenario = (templateScenario: TemplateScenario) => axios.put<void>(`${envBaseApiUrl}/template-scenarios/batch`, [templateScenario]);

/** Updates multiple existing template scenarios. */
export const updateTemplateScenarios = (templateScenarios: TemplateScenario[]) => axios.put<void>(`${envBaseApiUrl}/template-scenarios/batch`, templateScenarios);

/** Deletes a template scenario. */
export const deleteTemplateScenario = (templateScenarioId: TemplateScenarioId) => axios.delete<void>(`${envBaseApiUrl}/template-scenarios/${templateScenarioId}`);

/** Deletes multiple template scenarios. */
export const deleteTemplateScenarios = (templateScenarioIds: TemplateScenarioId[]) => axios.post<void>(`${envBaseApiUrl}/template-scenarios/batch-delete`, templateScenarioIds);
