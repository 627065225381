import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import {
  coverageLevelShouldBeOneOf,
  dependentScenarioPieceShouldMatchUnderlyingScenarioPieceType,
  missingUnderlyingScenarioPiece,
  plusProductProtectionFactorShouldNotBeGreaterThanCap,
  plusProductsShouldBeExclusivePerScenario,
  productCannotBeRevenueWithUnderlyingYieldBasedCoverage,
  RowCropScenarioPieceUnderlyingValidationRule,
  upperCoverageShouldMatchUnderlyingUpperCoverage
} from '../validationRules/rowCropScenarioPieceValidationRules';
import { composeValidationResults } from '../validationHandling';
import { CombinedValidationResult } from '../validateScenario';
import { Nullable } from '../../../types/util/Nullable';

export function validateEcoPlus(scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: Nullable<RowCropScenarioPiece>, allScenarioPieces: RowCropScenarioPiece[], doubleUnderlyingScenarioPiece: Nullable<RowCropScenarioPiece>): CombinedValidationResult<RowCropScenarioPiece> {
  if (underlyingScenarioPiece === null || doubleUnderlyingScenarioPiece === null) { return missingUnderlyingScenarioPiece; }

  const validationRules: RowCropScenarioPieceUnderlyingValidationRule[] = [
    dependentScenarioPieceShouldMatchUnderlyingScenarioPieceType,
    upperCoverageShouldMatchUnderlyingUpperCoverage,
    plusProductProtectionFactorShouldNotBeGreaterThanCap,
    plusProductsShouldBeExclusivePerScenario,
    productCannotBeRevenueWithUnderlyingYieldBasedCoverage,
    coverageLevelShouldBeOneOf([75, 80, 85]),
  ];

  const validationResults = validationRules.map(validationRule => validationRule(scenarioPiece, allScenarioPieces, underlyingScenarioPiece, doubleUnderlyingScenarioPiece));
  return composeValidationResults(validationResults);
}