import { Button, Grid } from '@mui/material';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { Nullable } from '../../types/util/Nullable';
import { ConfirmStateContent, openConfirm } from '../../app/confirmSlice';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';

export interface ScenarioPieceFormFooterProps {
  scenarioPiece: Nullable<ScenarioPiece>;
  onDeleteScenarioPieceCallback: () => void;
}

const ScenarioPieceFormFooter = ({ scenarioPiece, onDeleteScenarioPieceCallback }: ScenarioPieceFormFooterProps) => {
  const dispatch = useAppDispatch();

  const onDeleteScenarioPieceClick = () => {
    const confirmWindow: ConfirmStateContent = {
      title: 'Delete Scenario Piece?',
      message: 'Are you sure you want to delete this scenario piece? Any scenario pieces that rely on this will also be deleted.',
      confirmText: 'Delete',
      onConfirm: onDeleteScenarioPieceCallback,
    };
    dispatch(openConfirm(confirmWindow));
  };

  return (
    <>
      {scenarioPiece !== null &&
        <Grid container justifyContent="end">
          <Grid item sx={{ paddingBottom: 3, paddingRight: 2 }}>
            <Button variant="contained" id="btn-delete"
              onClick={() => onDeleteScenarioPieceClick()}>Delete</Button>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default ScenarioPieceFormFooter;
