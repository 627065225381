import { useEffect } from 'react';
import { FormWrapperHandler } from '../components/formWrapper/formWrapper.component';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';

function useFormWrapper<T extends FieldValues>(title: string, methods: UseFormReturn<T>, formId: string, onSubmit: SubmitHandler<T>, handleCancel: () => void, isCanceling: boolean = false, registerHeader?: FormWrapperHandler, handleValidation?: (isSuccessful: boolean) => void) {
  useEffect(() => {
    registerHeader && registerHeader(title, formId);
  }, []);

  useEffect(() => {
    isCanceling && handleCancel();
  }, [isCanceling]);

  const handleSubmit = methods.handleSubmit(async data => {
    await onSubmit(data);
    handleValidation && handleValidation(true);
  }, errors => {
    handleValidation && handleValidation(false);
  });

  return handleSubmit;
}

export default useFormWrapper;