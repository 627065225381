import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import {
  dependentScenarioPieceShouldMatchUnderlyingScenarioPieceTypeAndPlanCode,
  missingUnderlyingScenarioPiece,
  RowCropScenarioPieceUnderlyingValidationRule
} from '../validationRules/rowCropScenarioPieceValidationRules';
import { composeValidationResults } from '../validationHandling';
import { CombinedValidationResult } from '../validateScenario';
import { Nullable } from '../../../types/util/Nullable';

export function validateEco(scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: Nullable<RowCropScenarioPiece>, allScenarioPieces: RowCropScenarioPiece[]): CombinedValidationResult<RowCropScenarioPiece> {
  if (underlyingScenarioPiece === null) { return missingUnderlyingScenarioPiece; }

  const validationRules: RowCropScenarioPieceUnderlyingValidationRule[] = [
    dependentScenarioPieceShouldMatchUnderlyingScenarioPieceTypeAndPlanCode,
  ];

  const validationResults = validationRules.map(validationRule => validationRule(scenarioPiece, allScenarioPieces, underlyingScenarioPiece));
  return composeValidationResults(validationResults);
}