import { ProductOfferAvailabilityParams } from './OfferAvailability';
import { ProductOfferAvailabilities, ScenarioPieceType } from '@silveus/calculations';
import {
  getAreaBaseRate,
  getInsuranceOfferAvailability, getInsuranceOfferInformation,
  getMultipleCommodityAdjustmentFactor, getSubsidyPercent
} from '../../localCalcDataQueries.service';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import ArpCalculationParams from '../../../types/api/calculationData/arpCalculationParams';
import { getArpCalcData } from '../../calculationData.service';
import { admDb } from '../../../db';
import { ArpCalculationParamsRequest } from '../../../types/api/calculationData/arpCalculationParamsRequest';

export const getArpCalcDataRequest = async (calcParamRequest: ArpCalculationParamsRequest): Promise<ArpCalculationParams[]> => {
  const request = () => getArpCalcData(calcParamRequest);
  const admTransactionTables = [admDb.subsidies, admDb.insuranceOffers, admDb.areaCoverageLevels, admDb.areaRates, admDb.coverageLevelDifferentials];

  const readTransaction = async (): Promise<ArpCalculationParams[]> => {
    const multipleCroppingCode = 'NS';

    const volatility = calcParamRequest.volatility;

    const arpResponse: ArpCalculationParams[] = [];

    const unitTransactions = calcParamRequest.distinctUnits.map(async distinctUnit => {
      const admData = await admDb.transaction('r', admTransactionTables, async () => {
        const subsidyPercent = await getSubsidyPercent(calcParamRequest.commodityCode, calcParamRequest.unitStructureCode, calcParamRequest.planCode, calcParamRequest.upperCoverageLevel, calcParamRequest.coverageTypeCode);

        const insuranceOffer = await getInsuranceOfferInformation(calcParamRequest.planCode, calcParamRequest.countyId, distinctUnit.typeId, distinctUnit.practiceId, calcParamRequest.coverageTypeCode);

        if (insuranceOffer === null) return null;

        const baseRate = await getAreaBaseRate(calcParamRequest.year, insuranceOffer.insuranceOfferId, calcParamRequest.upperCoverageLevel, volatility);

        return {
          unitOfMeasure: insuranceOffer.unitOfMeasure,
          baseRate: baseRate,
          subsidyPercent: subsidyPercent,
        };
      });

      if (admData === null) return;

      const multipleCommodityAdjustmentFactor = await getMultipleCommodityAdjustmentFactor(multipleCroppingCode);

      const arpCalculationParams: ArpCalculationParams = {
        unit: distinctUnit,
        baseRate: admData.baseRate,
        multipleCommodityAdjustmentFactor: multipleCommodityAdjustmentFactor,
        subsidyPercent: admData.subsidyPercent,
      };

      arpResponse.push(arpCalculationParams);
    });

    await Promise.all(unitTransactions);

    return arpResponse;
  };

  const strategy = getDefaultCachingStrategy();
  return strategy(request, readTransaction);
};

export const getOfferAvailability = async (productOfferAvailabilityParams: ProductOfferAvailabilityParams): Promise<ProductOfferAvailabilities[]> => {
  const scenarioPieceTypes: ScenarioPieceType[] = [ScenarioPieceType.Arp];

  const offerAvailabilityPromises = scenarioPieceTypes.map(scenarioPieceType => {
    return getInsuranceOfferAvailability(
      productOfferAvailabilityParams.year,
      productOfferAvailabilityParams.countyId,
      productOfferAvailabilityParams.commodityCode,
      getInsurancePlanCodeForScenarioPiece(scenarioPieceType),
      scenarioPieceType);
  });

  return Promise.all(offerAvailabilityPromises);
};
