import { ColumnState } from 'ag-grid-community';

export const UnitGridDefaultState: ColumnState[] = [
  {
    colId: 'actions',
    width: 70,
  },
  {
    colId: 'unitNum',
    width: 75,
    sort: 'asc',
  },
  {
    colId: 'optionUnitNumber',
    width: 75,
  },
  {
    colId: 'farmName',
    width: 110,
  },
  {
    colId: 'farmNum',
    width: 85,
  },
  {
    colId: 'section',
    width: 85,
  },
  {
    colId: 'twp',
    width: 75,
  },
  {
    colId: 'range',
    width: 75,
  },
  {
    colId: 'location',
    width: 85,
  },
  {
    colId: 'options',
    width: 150,
  },
  {
    colId: 'subCounty',
    width: 100,
  },
  {
    colId: 'acres',
    width: 90,
  },
  {
    colId: 'sharePercent',
    width: 85,
  },
  {
    colId: 'rateYield',
    width: 85,
  },
  {
    colId: 'adjustedYield',
    width: 75,
  },
  {
    colId: 'approvedYield',
    width: 75,
  },
  {
    colId: 'priorYearProduction',
    width: 120,
  },
  {
    colId: 'priorYearAcres',
    width: 80,
  },
  {
    colId: 'priorYearYield',
    width: 80,
  },
  {
    colId: 'priorYearApprYield',
    width: 100,
  },
];
