import { styled, Drawer, Theme } from '@mui/material';

const openedMixin = (theme: Theme, maxWidth: number) => ({
  width: `${maxWidth}px`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, minWidth: number) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `${minWidth}px`,
});

const mixinStyles = (theme: Theme, open: boolean | undefined, maxWidth: number, minWidth: number) => {
  return (open ?? false) ? openedMixin(theme, maxWidth) : closedMixin(theme, minWidth);
};

interface SlidingDrawerProps {
  minWidth: number;
  maxWidth: number;
}

const SlidingDrawer = styled(
  Drawer,
  { shouldForwardProp: prop => prop !== 'open' && prop !== 'minWidth' && prop !== 'maxWidth' },
)<SlidingDrawerProps>(({ theme, open, minWidth, maxWidth }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  borderWidth: 0,
  '& .MuiDrawer-paper': { ...mixinStyles(theme, open, maxWidth, minWidth) },
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: (open ?? false) ? theme.transitions.easing.sharp : theme.transitions.easing.sharp,
    duration: (open ?? false) ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
  }),
  width: `${(open ?? false) ? maxWidth : minWidth}px`,
}));

export default SlidingDrawer;