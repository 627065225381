import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { optionalUnitMaxLength, validateOptionalUnitNumber } from '../../../pages/units/validations/optionalUnitNumberValidation';

interface OptionalUnitNumberProps {
  optionalUnitNumber: Nullable<string>;
  autoFocus?: boolean;
}

export type OptionalUnitNumberFields = {
  optionalUnitNumber: string;
};

const OptionalUnitNumberInput = ({ optionalUnitNumber, autoFocus = false }: OptionalUnitNumberProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="optionalUnitNumber"
      control={control}
      rules={{ validate: validateOptionalUnitNumber }}
      defaultValue={optionalUnitNumber}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Optional Unit #"
          fullWidth
          inputProps={{ maxLength: optionalUnitMaxLength }}
          error={ !!error }
          helperText={ error?.message }
          inputRef={ ref }
          { ...field }
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default OptionalUnitNumberInput;
