// This is safe reference that can be used anywhere in the application that will always point to an empty array.
const stableEmptyArrayInstance = Object.freeze([]);

/** Returns an application-wide reference to a truly immutable empty array of type T. Runtime modifications are blocked. */
export function stableEmptyArray<T>(): readonly T[] {
  return stableEmptyArrayInstance as unknown as readonly T[];
}

/**
 * Returns an application-wide reference to a truly immutable empty array of type T.
 * The response "pretends" to not be readonly, but beware that the reference returned from this is actually frozen
 * and can NEVER be modified. Use it with caution and purpose.
 * */
export function stableEmptyArrayAsMutable<T>(): T[] {
  const immutableStableArray = stableEmptyArray<T>();
  return immutableStableArray as T[];
}