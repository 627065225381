import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  useTheme
} from '@mui/material';
import { Option } from '../../types/api/adm/Option';
import { OptionCode } from '@silveus/calculations';
import { Na } from '../../types/util/Na';
import { useOptionCodesWithAvailability } from '../../hooks/useOptionCodesWithAvailability';
import { OptionLevelCode, OptionLevelCodes } from '../../constants/optionLevelCodes';
import OptionState from '../../types/app/OptionState';
import { OptionSelectionState } from '../../types/app/enums/optionSelectionState.enum';
import { OptionSelectionAvailabilityWarning } from '../../constants/optionAvailability';

type UnitsModalOptionsSelectorProps = {
  scenarioOptions: OptionState[];
  addOptionToAllUnits: (unitOptionCode: OptionCode) => void;
  removeOptionFromAllUnits: (unitOptionCode: OptionCode) => void;
  optionLevel: OptionLevelCode;
  disabled?: boolean;
}

const UnitsModalOptionsSelector = ({ scenarioOptions, addOptionToAllUnits, removeOptionFromAllUnits, optionLevel, disabled = false }: UnitsModalOptionsSelectorProps) => {
  const theme = useTheme();
  const getFormattedOptionCodesWithAvailability = useOptionCodesWithAvailability();
  const addRemoveOption = (option: Option) => {
    const scenarioOption = scenarioOptions.find(to => to.option.optionCode === option.optionCode);
    if (scenarioOption === undefined) return;
    // We only want to remove this option if it already exists AND if all units have this option,
    // otherwise we want to add the option to all the units that don't have it already.
    if (scenarioOption.selectionState === OptionSelectionState.On) {
      removeOptionFromAllUnits(option.optionCode);
    } else {
      addOptionToAllUnits(option.optionCode);
    }
  };
  const placeholderOptionText = optionLevel === OptionLevelCodes.Commodity ? 'Options By Commodity' : 'Options By Unit';
  const filteredScenarioOptions: OptionState[] = scenarioOptions.filter(o => optionLevel === o.option.optionLevelCode);
  return (
    <FormControl sx={{ width: 215 }}>
      <InputLabel id={`options-select-label-${optionLevel}`}>{placeholderOptionText}</InputLabel>
      <Select
        id={`options-multiple-checkbox-${optionLevel}`}
        labelId={`options-select-label-${optionLevel}`}
        multiple
        size="small"
        displayEmpty
        disabled={disabled}
        value={scenarioOptions}
        label={placeholderOptionText}
        renderValue={options => {
          const selectedOptions = options.filter(o => o.selectionState !== OptionSelectionState.Off && optionLevel === o.option.optionLevelCode);
          if (options.length === 0) return Na;
          // MUI has a placeholder for Select but it doesn't work in this use case, have to provide our own as per MUI docs
          return selectedOptions.length === 0 ? <span>{ placeholderOptionText }</span> : getFormattedOptionCodesWithAvailability(selectedOptions);
        }}
      >
        <div>
          {filteredScenarioOptions.length === 0 &&
            <MenuItem value="">No available options</MenuItem>
          }
          {filteredScenarioOptions.map(o => (
            <MenuItem key={o.option.optionCode} value={o.option.optionCode} onClick={() => addRemoveOption(o.option)}>
              <Checkbox checked={o.selectionState !== OptionSelectionState.Off} indeterminate={o.selectionState === OptionSelectionState.Partial}/>
              <Tooltip title={!o.isAvailable && OptionSelectionAvailabilityWarning} enterDelay={2000}>
                <ListItemText
                  primary={o.option.optionName}
                  primaryTypographyProps={{
                    style: {
                      color: o.isAvailable ? 'inherit' : theme.palette.warning.main,
                    },
                  }} />
              </Tooltip>
            </MenuItem>
          ))}
        </div>
      </Select>
    </FormControl>
  );
};

export default UnitsModalOptionsSelector;
