import * as React from 'react';
import { styled, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector, useKeyMapSelectorSingle } from '../../../hooks/reduxHooks';
import { QuoteId, ScenarioId } from '../../../types/api/PrimaryKeys';
import { selectCalculationsByScenarioPiece } from '../../../app/calculationResultsSlice';
import { selectScenarioById } from '../../../app/scenariosSlice';
import '../scenarioPieceCard.style.css';
import HarvestRevenueScenarioPiece from '../../../types/api/HarvestRevenueScenarioPiece';
import { modifyHarvestRevenueScenarioPiece, removeHarvestRevenueScenarioPieceAndRecalculate } from '../../../app/harvestRevenueScenarioPiecesSlice';
import HarvestRevenueTableWrapper from './harvestRevenueTableComponents/harvestRevenueTableWrapper.component';
import HarvestRevenueOutputSummary from './harvestRevenueTableComponents/harvestRevenueOutputSummary.component';
import { formatCurrency } from '../../../utils/formatNumbers';
import HarvestRevenueTransactionDisplayProduction from './harvestRevenueTableComponents/harvestRevenueDisplayProduction.component';
import HarvestRevenueTransactionDisplayPrice from './harvestRevenueTableComponents/harvestRevenueDisplayPrice.component';
import { getSafeProduction, getSafeRevenue } from './harvestRevenueTableComponents/harvestRevenueUtils';
import ScenarioPieceCard from '../scenarioPieceCard.component';

const StyledTableCell = styled(TableCell)({
  padding: '6px',
  '& p': {
    fontSize: '12px',
  },
});

interface HarvestRevenueScenarioPieceCardProps {
  scenarioPiece: HarvestRevenueScenarioPiece;
  quoteId: QuoteId;
  scenarioId: ScenarioId;
  isScenarioActive: boolean;
  isReadonly: boolean;
}

const HarvestRevenueScenarioPieceCard = ({ scenarioPiece, quoteId, isScenarioActive, scenarioId, isReadonly }: HarvestRevenueScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();

  const calcResults = useKeyMapSelectorSingle(selectCalculationsByScenarioPiece, scenarioPiece.scenarioPieceId);
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));

  if (!scenario) {
    return <></>;
  }


  const netScenarioTotal = calcResults?.simulatedPerAcre ?? 0;

  const expandedCardBody = (
    <>
      <HarvestRevenueTableWrapper
        disabled={false}
        includeActionColumn={false}
        includeHints={false}
        tableBodyContents={
          scenarioPiece.harvestRevenueTransactions.map((harvestRevenueTransaction, index) => {
            const rev = formatCurrency(getSafeRevenue(calcResults, index) ?? 0, false);
            const prod = getSafeProduction(calcResults, index) ?? 0;

            return (
              <TableRow key={index}>
                <StyledTableCell>
                  <HarvestRevenueTransactionDisplayProduction
                    adjustmentType={harvestRevenueTransaction.productionAdjustmentTypeId}
                    value={harvestRevenueTransaction.production}
                    productionCalculated={prod} />
                </StyledTableCell>
                <StyledTableCell>
                  <HarvestRevenueTransactionDisplayPrice
                    adjustmentType={harvestRevenueTransaction.priceAdjustmentTypeId}
                    value={harvestRevenueTransaction.price} />
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip title={rev} placement="top-start">
                    <Typography noWrap>{rev}</Typography>
                  </Tooltip>
                </StyledTableCell>
              </TableRow>
            );
          })
        }
      />
      <HarvestRevenueOutputSummary
        bushelsProduced={calcResults?.bushelsProduced ?? 0}
        totalForwardSold={calcResults?.totalForwardSold ?? 0}
        totalYieldSold={calcResults?.totalYieldSold ?? 0}
        averagePrice={calcResults?.averagePrice ?? 0}
      />
    </>
  );

  const updateScenarioPiece = async (scenarioPiece: HarvestRevenueScenarioPiece) => {
    await dispatch(modifyHarvestRevenueScenarioPiece({ scenarioPiece: scenarioPiece }));
  };

  const removeScenarioPiece = async (scenarioPiece: HarvestRevenueScenarioPiece) => {
    await dispatch(removeHarvestRevenueScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
  };

  return (
    <ScenarioPieceCard
      scenarioPiece={scenarioPiece}
      quoteId={quoteId}
      isScenarioActive={isScenarioActive}
      updateScenarioPiece={updateScenarioPiece}
      removeScenarioPiece={removeScenarioPiece}
      label="Harvest Revenue"
      expandedCardBody={expandedCardBody}
      netValue={netScenarioTotal}
      calcResults={calcResults}
      scenarioId={scenarioPiece.scenarioId}
      displayTooltip={false}
      isReadonly={isReadonly}
      scenarioColor={scenario.scenarioColor}
    />
  );
};

export default HarvestRevenueScenarioPieceCard;
