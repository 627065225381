import { Controller, useFormContext, useWatch } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import NumberTextField, { getRoundedValue, onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferProducerYield } from '../../../app/admSlice';
import { useEffect } from 'react';
import { isNullOrUndefined, isUndefined } from '../../../utils/nullHandling';
import { isApiDataStale, isSavedDataBeingLoaded } from '../../../utils/formInputUtils';
import { Nullable } from '../../../types/util/Nullable';
import { Quote } from '../../../types/api/Quote';
import { Info } from '../../info/info';
import { useGetYieldRoundingPrecisionInScenarioForm } from './hooks/useGetYieldRoundingPrecisionInScenarioForm';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { FormInputProps } from './formInputProps';

interface ApprovedYieldProps extends FormInputProps {
  quote: Quote;
  scenarioId: Nullable<ScenarioId>;
  approvedYield: Nullable<number>;
}

export type ApprovedYieldFields = {
  approvedYield: number;
};

export const ApprovedYieldInput = ({ quote, scenarioId, approvedYield, sx, autoFocus = false, disabled = false }: ApprovedYieldProps) => {
  const { control, setValue, getValues, trigger } = useFormContext();
  const apprYield = useAppSelector(selectOfferProducerYield);
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });

  const roundingPrecision = useGetYieldRoundingPrecisionInScenarioForm({ quote, scenarioId });

  useEffect(() => {
    //When the API data is changed, load it into the field,
    // except for the initial load when there is saved data
    const currentPractice = getValues('practiceId');

    const isThereStaleData = isApiDataStale(currentPractice, apprYield);
    const isLoadingSavedData = isSavedDataBeingLoaded(approvedYield, practice);

    if (isThereStaleData || isLoadingSavedData || isNullOrUndefined(apprYield) || !quote.quickQuote) return;

    setValue('approvedYield', apprYield);
    trigger('approvedYield');
  }, [apprYield]);

  useEffect(() => {
    //When the practice is changed, clear out the current value
    if (isUndefined(practice)) return;

    setValue('approvedYield', 0);
  }, [practice]);

  const defaultValue = getRoundedValue(approvedYield ?? apprYield, roundingPrecision) ?? 0;

  return (<Controller
    name="approvedYield"
    control={control}
    rules={{ required: VALIDATION_RULES.required(), min: VALIDATION_RULES.minimum(0), max: VALIDATION_RULES.maximum(99999999.99), maxLength: VALIDATION_RULES.maxLength(11), validate: VALIDATION_RULES.restrictMinDefaultValue(0, disabled) }}
    defaultValue={defaultValue}
    render={({
      field: { ref, value, ...field }, fieldState: { error },
    }) => (
      <NumberTextField
        {...field}
        type="number"
        label="Approved Yield"
        fullWidth
        inputProps={{ step: 0.01, min: 0 }}
        InputProps={{
          endAdornment: <Info tooltipContent="Approved Yield" />,
        }}
        value={quote.quickQuote ? value : defaultValue}
        onChange={e => onNumericInputChange(e, field)}
        error={!!error}
        helperText={error?.message}
        inputRef={ref}
        sx={sx}
        onBlur={e => onNumericInputChange(e, field, roundingPrecision)}
        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
        autoFocus={autoFocus}
        size="small"
        disabled={disabled}
      />
    )}
  />);
};
