import { Nullable } from '../../types/util/Nullable';
import VALIDATION_RULES from '../../constants/formValidationRules';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

interface DescriptionProps {
  description: Nullable<string>;
  autoFocus?: boolean;
}

export type DescriptionFields = {
  description: string;
};

const DescriptionInput = ({ description, autoFocus = false }: DescriptionProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="description"
      control={control}
      rules={{ maxLength: VALIDATION_RULES.maxLength(500) }}
      defaultValue={description ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Description"
          fullWidth
          multiline
          minRows={4}
          maxRows={8}
          inputProps={{ maxLength: 500 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default DescriptionInput;

