import { ScenarioPieceIdentifier, ScenarioPieceType } from '@silveus/calculations';
import { useMemo, useState } from 'react';
import { selectClientFileById } from '../../app/clientFilesSlice';
import { selectScenarioById } from '../../app/scenariosSlice';
import { FormWrapperProps } from '../../components/formWrapper/formWrapper.component';
import { useAppSelector, useKeyMapSelector } from '../../hooks/reduxHooks';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { Quote } from '../../types/api/Quote';
import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import { ScenarioPieceFormSnapshot } from '../../types/app/ScenarioPieceFormSnapshot';
import { Nullable } from '../../types/util/Nullable';
import { ScenarioPieceFormPicker } from './scenarioPieceFormPicker.component';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../app/scenarioPiecesSlice';
import { useScenarioPieceAvailability } from '../../hooks/useScenarioPieceAvailability';

export interface ScenarioPieceMultiPlanWrapperProps extends FormWrapperProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  scenarioId: ScenarioId;
  quote: Quote;
  handleCancel: () => void;
  scenarioPieceTypePlanSelectionList: ScenarioPieceType[];
}

export const ScenarioPieceMultiPlanWrapper = ({ scenarioPiece, scenarioId, quote, registerHeader, handleValidation, handleCancel, scenarioPieceTypePlanSelectionList, isCanceling = false }: ScenarioPieceMultiPlanWrapperProps) => {
  const scenario = useAppSelector(s => selectScenarioById(s, scenarioId));
  const clientFile = useAppSelector(s => selectClientFileById(s, quote.clientFileId));

  const persistedRowCropScenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioId);
  const overrideRowCropScenarioPieces = useMemo(() => {
    return scenarioPiece === null ? undefined : persistedRowCropScenarioPieces.filter(sp => sp.scenarioPieceId !== scenarioPiece.scenarioPieceId);
  }, [scenarioPiece]);
  const availableScenarioPieces = useScenarioPieceAvailability(scenarioId, scenarioPieceTypePlanSelectionList, overrideRowCropScenarioPieces, false);
  const defaultScenarioPieceType: ScenarioPieceIdentifier =
    scenarioPiece?.scenarioPieceType ??
    availableScenarioPieces.find(availability => !availability.isDisabled && scenarioPieceTypePlanSelectionList.includes(availability.scenarioPieceType as ScenarioPieceType))?.scenarioPieceType ??
    ScenarioPieceType.Unset;

  const [selectedScenarioPieceType, setSelectedScenarioPieceType] = useState(defaultScenarioPieceType);
  const [scenarioPieceFormSnapshot, setScenarioPieceFormSnapshot] = useState<ScenarioPieceFormSnapshot | null>(null);

  const updateScenarioPieceFormSnapshot = (updatePlanSelection: boolean, newPlanSelection: ScenarioPieceType, scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null) => {
    setScenarioPieceFormSnapshot(scenarioPieceFormSnapshot);
    if (updatePlanSelection){
      setSelectedScenarioPieceType(newPlanSelection);
    }
  };

  return (
    <>
      {clientFile !== null && scenario !== null &&
        <ScenarioPieceFormPicker
          scenarioPiece={scenarioPiece}
          scenarioId={scenario.scenarioId}
          year={clientFile.year}
          countyId={quote.countyId}
          typeId={scenario.typeId}
          practiceId={scenario.practiceId ?? ''}
          commodityCode={quote.commodityCode}
          highRiskTypeId={scenario.highRiskTypeId}
          disabled={!(scenarioPiece?.isActive ?? true)}
          selectedScenarioPieceType={selectedScenarioPieceType}
          registerHeader={registerHeader}
          quote={quote}
          clientFile={clientFile}
          handleValidation={handleValidation}
          handleCancel={handleCancel}
          isCanceling={isCanceling}
          updateScenarioPieceFormSnapshot={updateScenarioPieceFormSnapshot}
          scenarioPieceFormSnapshot={scenarioPieceFormSnapshot}
          scenarioPieceTypePlanSelectionList={scenarioPieceTypePlanSelectionList}
        />
      }
    </>
  );
};
