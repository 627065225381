import { useState } from 'react';
import { TextField, Tooltip, styled } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import HarvestRevenueTransactionPropsBase from './harvestRevenueTransactionPropsBase';
import { getPriceAdjustmentType, parseHarvestRevenuePriceNumber } from './harvestRevenueUtils';
import { HarvestRevenueScenarioPieceFormFields } from '../harvestRevenueScenarioPieceForm.component';
import { formatPriceValue } from './harvestRevenueUtils';
import { onNumericStringInputChange } from '../../../../components/numberTextField/numberTextField.component';

const StyledTextField = styled(TextField)({
  padding: '0px',
  width: '80px',
  '& div': {
    fontSize: '12px !important',
    '& input': {
      paddingLeft: '6px',
    },
  },
});

const HarvestRevenueTransactionPrice = ({ index, disabled, onTransactionValueChanged }: HarvestRevenueTransactionPropsBase) => {
  const { control, getValues } = useFormContext<HarvestRevenueScenarioPieceFormFields>();
  const [isFocused, setIsFocused] = useState(false);
  const [typedText, setTypedText] = useState<string>('');

  // Handle focus and blur events to switch between display and edit modes
  const handleFocus = () => setIsFocused(true);

  return (
    <>
      <Controller
        name={`harvestRevenueTransactions.${index}.price`}
        control={control}
        rules={{
          required: VALIDATION_RULES.required(),
        }}
        render={({
          field: { ref, ...field }, fieldState: { error },
        }) => {
          const displayValue = isFocused ? typedText : formatPriceValue(getPriceAdjustmentType(index, getValues), field.value);

          return (
            <Tooltip title={displayValue} placement="top-start" enterDelay={1000}>
              <StyledTextField
                {...field}
                // When the field is not focused, display the formatted value
                value={displayValue}
                onFocus={() => {
                  setTypedText(field.value.toString());
                  handleFocus();
                }}
                onBlur={event => {
                  const cleanedValue = parseHarvestRevenuePriceNumber(getPriceAdjustmentType(index, getValues), event.target.value);
                  field.onChange(cleanedValue);
                  setIsFocused(false);
                }}
                error={!!error}
                disabled={disabled}
                onChange={e => {
                  setTypedText(e.target.value);
                  onNumericStringInputChange(e, field);
                  onTransactionValueChanged();
                }}
                helperText={error?.message}
                size="small"
                required
                type="text"
              />
            </Tooltip>
          );
        }}
      />
    </>
  );
};

export default HarvestRevenueTransactionPrice;
