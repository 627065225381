import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { basicUnitNumberMaxLength, validateBasicUnitNumber } from '../../../pages/units/validations/basicUnitNumberValidation';

interface BasicUnitNumberProps {
  basicUnitNumber: Nullable<string>;
  autoFocus?: boolean;
}

export type BasicUnitNumberFields = {
  basicUnitNumber: string;
};

const BasicUnitNumberInput = ({ basicUnitNumber, autoFocus = false }: BasicUnitNumberProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="basicUnitNumber"
      control={control}
      rules={{ validate: validateBasicUnitNumber }}
      defaultValue={basicUnitNumber}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Basic Unit #"
          fullWidth
          inputProps={{ maxLength: basicUnitNumberMaxLength }}
          error={ !!error }
          helperText={ error?.message }
          inputRef={ ref }
          { ...field }
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default BasicUnitNumberInput;
