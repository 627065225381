import { AgGridReact } from 'ag-grid-react';
import { DefaultColDefinition, customDataTypeDefinitions, getColumnDefinitions } from './ContactGridColumns';
import { selectAllStates } from '../../../app/admSlice';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import { PersonOfInterest } from '../../../types/api/insureds/PersonOfInterest';
import { selectEntityTypes, selectTaxTypes } from '../../../app/insuredsSlice';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ConfirmStateContent, openConfirm } from '../../../app/confirmSlice';
import { addTemporaryPOI, createPersonOfInterest, removePersonOfInterest, selectAllPOIsByInsuredIdMap, updatePersonOfInterest } from '../../../app/personOfInterestSlice';
import { GridApi, GridReadyEvent, IRowNode, RowEditingStoppedEvent } from 'ag-grid-community';
import { cloneDeep, isEqual } from 'lodash';
import { Grid } from '@mui/material';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { Nullable } from '../../../types/util/Nullable';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { getMinimumValidationResultsForContact, validateMinimumRequirementsForContact } from './validation/validateContactInfo';
import { PersonOrBusiness } from '../../../types/api/enums/contactInfo/personOrBusiness';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';
import { selectIsLightMode } from '../../../app/userSettingsSlice';
import ContactInfoGridSplitButton from './contactInfoGridSplitButton';
import ImportContactsModal from './importContactsModal';
import { MuiDialogCloseReason } from '../../../types/mui/MuiDialogCloseReason';
import { ContactId } from '../../../types/api/PrimaryKeys';
import TaxIdUpdateModal, { TaxIdUpdateModalProps } from '../../../pages/applicationsModal/wizardSteps/taxIdUpdateModal';

import './ContactInfoGrid.styles.css';
import { openToast } from '../../../app/toastSlice';

type ContactInfoGridProps = {
  contactTypes: PersonOfInterestType[];
  application: ApplicationWizard;
  updateIsInEditMode: (val: boolean) => void;
  updateIsLoading: (val: boolean) => void;
  isLoading: boolean;
}

const ContactInfoGrid = ({ contactTypes, application, updateIsInEditMode, updateIsLoading, isLoading }: ContactInfoGridProps) => {
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [editingRowIndex, setEditingRowIndex] = useState<Nullable<number>>(null);
  const [showImportModal, setShowImportModal] = useState(false);

  const dispatch = useAppDispatch();
  const insuredId = useMemo(() => application.isNewEntity && isNotNullOrUndefined(application.newInsuredId) ? application.newInsuredId : application.insuredId, [application]);
  const firstColKey = 'firstName';
  const pois = useKeyMapSelector(selectAllPOIsByInsuredIdMap, insuredId).filter(x => contactTypes.includes(x.personOfInterestType));
  const isLightMode = useAppSelector(selectIsLightMode);
  const [prevContacts, setPrevContacts] = useState<PersonOfInterest[]>([]);
  const [rowData, setRowData] = useState<PersonOfInterest[]>([]);
  const states = useAppSelector(selectAllStates);
  const taxTypes = useAppSelector(selectTaxTypes);
  const entityTypes = useAppSelector(selectEntityTypes);
  const [taxIdModalData, setTaxIdModalData] = useState<Nullable<TaxIdUpdateModalProps>>(null);

  const isEditingRowMode = isNotNullOrUndefined(editingRowIndex);

  useEffect(() => {
    updateIsInEditMode(isEditingRowMode);
  }, [isEditingRowMode]);

  useEffect(() => {
    if (!isEqual(pois, prevContacts)) {
      setPrevContacts(cloneDeep(pois));
      setRowData(cloneDeep(pois));
    }
  }, [pois, prevContacts]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridAPI(event.api);
  };

  const inactivatePOI = useCallback((poi: PersonOfInterest) => {
    const confirmWindow: ConfirmStateContent = {
      title: 'Remove Person of Interest?',
      message: 'Are you sure you want to remove this person of interest? This will not permanently delete the record, but rather mark it as inactive.',
      confirmText: 'Remove',
      onConfirm: async () => {
        updateIsLoading(true);
        try {
          await dispatch(removePersonOfInterest({ personOfInterest: poi }));
        } finally {
          updateIsLoading(false);
        }
      },
    };
    dispatch(openConfirm(confirmWindow));

  }, [dispatch, updateIsLoading]);

  const onContactFinishedEditing = useCallback(async (poi: PersonOfInterest, updateTaxId: boolean = false, index?: Nullable<number>) => {
    updateIsLoading(true);
    try {
      if (poi.personOfInterestId !== null) {
        await dispatch(updatePersonOfInterest({ personOfInterest: poi, updateTaxId })).unwrap();
      } else {
        await dispatch(createPersonOfInterest({ personOfInterest: poi })).unwrap();
      }
    } catch {
      if (isNotNullOrUndefined(index)) {
        gridAPI?.startEditingCell({
          rowIndex: index,
          colKey: firstColKey,
        });
      }
    } finally {
      updateIsLoading(false);
    }
  }, [dispatch, updateIsLoading, gridAPI]);

  const handleStopEdit = useCallback(async (node: IRowNode<PersonOfInterest>, cancel?: boolean) => {
    if (isNullOrUndefined(gridAPI)) return;
    const { data } = node;
    if ((cancel ?? false) && isNotNullOrUndefined(data) && isNullOrUndefined(data.personOfInterestId)) {
      await dispatch(removePersonOfInterest({ personOfInterest: data }));
    }

    gridAPI.stopEditing(cancel);
  }, [gridAPI, pois]);

  const rowEditStarted = useCallback((index: number) => {
    if (!gridAPI) return;
    gridAPI.startEditingCell({
      rowIndex: index,
      colKey: firstColKey,
    });
    setEditingRowIndex(index);
  }, [gridAPI]);

  const openTaxIdModal = useCallback((contactId: ContactId | undefined) => {
    if (isNullOrUndefined(contactId)) return;
    const contact = pois.find(poi => poi.id === contactId);
    if (!contact) return;

    setTaxIdModalData({
      onClose: () => setTaxIdModalData(null),
      name: `${contact.firstName} ${contact.lastName}`,
      updateTaxId: async (newTaxId: string) => await onContactFinishedEditing({ ...contact, taxId: newTaxId }, true),
      taxId: contact.taxId,
    });
  }, [pois]);

  const hasExistingTaxId = useCallback((contactId: ContactId | undefined) => {
    if (isNullOrUndefined(contactId)) return false;
    const contact = pois.find(poi => poi.id === contactId);
    if (!contact) return false;

    return isNotNullOrUndefined(contact.taxId) && contact.taxId.length > 0;
  }, [pois]);

  const columnDefinitions = useMemo(() => {
    return getColumnDefinitions(contactTypes, states, inactivatePOI, taxTypes, entityTypes, application.isGettingESignatures, poi => handleStopEdit(poi, true), poi => handleStopEdit(poi, false), rowEditStarted, openTaxIdModal, hasExistingTaxId);
  }, [rowEditStarted, contactTypes, states, taxTypes, entityTypes, application.isGettingESignatures, inactivatePOI, handleStopEdit, openTaxIdModal, hasExistingTaxId]);

  const rowEditStopped = (event: RowEditingStoppedEvent) => {
    setEditingRowIndex(null);
    const { data, node } = event;
    const index = node.rowIndex;
    const existingPOI = pois.find(x => x.id === data.id);
    const valid = validateMinimumRequirementsForContact(data);
    if (!valid && index !== null && data.personOfInterestId !== null) {
      event.api.startEditingCell({
        rowIndex: index,
        colKey: firstColKey,
      });
      const validationErrors = getMinimumValidationResultsForContact(data);
      const messages = validationErrors.filter(x => typeof(x) === 'string' );
      dispatch(openToast({ type: 'error', message: `Failed to update contact, invalid cell data.<br />${messages.join('<br />')}`, shouldTimeout: true, allowClickToClose: false }));
    }

    if (valid && !isEqual(data, existingPOI)) {
      const shouldUpdateTaxId = !hasExistingTaxId(data.id);
      onContactFinishedEditing(data, shouldUpdateTaxId, index);
    }

    refreshActionCell();
  };

  const addContact = (personOrBusinessType: PersonOrBusiness, personOfInterestType: PersonOfInterestType) => {
    if (isNullOrUndefined(gridAPI)) return;
    const ssnType = taxTypes.find(x => x.name === 'SSN');
    const individualEntityType = entityTypes.find(x => x.name === 'Individuals');
    const poi: PersonOfInterest = {
      id: generatePrimaryKey(),
      personOrBusiness: personOrBusinessType,
      insuredId: insuredId,
      name: '',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      email: '',
      phone: '',
      address: {
        city: '',
        state: '',
        addressLine1: '',
        postalCode: '',
        country: '',
        composite: '',
      },
      taxId: '',
      taxTypeId: personOrBusinessType === PersonOrBusiness.PERSON ? (ssnType?.taxTypeId ?? null) : null,
      corporationStateId: '',
      entityTypeId: personOrBusinessType === PersonOrBusiness.PERSON ? (individualEntityType?.entityTypeId ?? null) : null,
      personOfInterestType: personOfInterestType,
      personOfInterestId: null,
    };
    dispatch(addTemporaryPOI(poi));
    // After dispatching, find the new row and start editing
    setTimeout(() => {
      const allNodes: IRowNode<PersonOfInterest>[] = [];

      gridAPI.forEachNode(node => {
        allNodes.push(node);
      });

      const newRowNode = allNodes.find(node => node.data?.id === poi.id);

      const rowIndex = newRowNode?.rowIndex;
      if (isNotNullOrUndefined(rowIndex)) {
        gridAPI.startEditingCell({
          rowIndex: rowIndex,
          colKey: firstColKey,
        });
        setEditingRowIndex(rowIndex);
      }
    }, 150);
  };

  const handleImportDialogClose = (reason?: MuiDialogCloseReason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setShowImportModal(false);
  };

  const refreshActionCell = () => {
    if (isNullOrUndefined(gridAPI)) return;

    gridAPI.refreshCells({
      columns: ['action'],
      force: true,
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} p={0} pt={1} sx={{ height: '55px', textAlign: 'right' }}>
        <ContactInfoGridSplitButton contactType={contactTypes[0]} disabled={isLoading || isEditingRowMode} onAddClick={addContact} onImportClick={() => {setShowImportModal(true);}} />
      </Grid>
      <Grid item xs={12} sx={{ height: '100%' }} className={isLightMode ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}>
        <AgGridReact<PersonOfInterest>
          className="contact-info-grid"
          rowData={rowData}
          defaultColDef={DefaultColDefinition}
          columnDefs={columnDefinitions}
          enterNavigatesVerticallyAfterEdit={false}
          enterNavigatesVertically={false}
          getRowId={params => params.data.id}
          onGridReady={e => onGridReady(e)}
          maintainColumnOrder={true}
          onRowEditingStarted={params => {
            params.api.refreshCells({
              columns: ['action'],
              rowNodes: [params.node],
              force: true,
            });
          }}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          tooltipShowDelay={350}
          onRowEditingStopped={rowEditStopped}
          gridOptions={{
            editType: 'fullRow',
            stopEditingWhenCellsLoseFocus: false,
            sortingOrder: ['desc', 'asc', null],
            suppressClickEdit: true,
            dataTypeDefinitions: customDataTypeDefinitions,
          }}
          autoSizeStrategy={{ type: 'fitGridWidth' }}
        />
      </Grid>
      {showImportModal && (
        <ImportContactsModal
          onClose={handleImportDialogClose}
          insuredId={insuredId}
          personOfInterestType={contactTypes[0]}
          existingPOIs={pois}
        />
      )}
      {taxIdModalData && <TaxIdUpdateModal
        onClose={taxIdModalData.onClose}
        name={taxIdModalData.name}
        updateTaxId={taxIdModalData.updateTaxId}
        taxId={taxIdModalData.taxId}
      />}
    </Grid>
  );
};

export default ContactInfoGrid;