import { Card, List, ListSubheader } from '@mui/material';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectCurrentClientFileId } from '../../app/clientFilesSlice';
import { stableEmptyArrayAsMutable } from '../../utils/stableEmptyArray';
import { Quote } from '../../types/api/Quote';
import { selectQuotesByClientFileId } from '../../app/quotesSlice';
import { ReactNode, useMemo } from 'react';
import { selectScenariosByQuoteIds } from '../../app/scenariosSlice';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { SelectedScenarioCheckboxInput } from './selectedScenarioCheckboxInput';

interface SelectedScenariosInputProps {
  getScenarioSelectionContent?: (scenarioId: ScenarioId) => ReactNode;
  includedScenarios: ScenarioId[];
  setIncludedScenarios: (scenarioIds: ScenarioId[]) => void;
}

export const SelectedScenariosInput = ({ getScenarioSelectionContent, includedScenarios, setIncludedScenarios }: SelectedScenariosInputProps) => {
  const clientFileId = useAppSelector(selectCurrentClientFileId);
  const quotes = useAppSelector(state => clientFileId === null ? stableEmptyArrayAsMutable<Quote>() : selectQuotesByClientFileId(state, clientFileId));
  const quoteIds = useMemo(() => quotes.map(quote => quote.quoteId), [quotes]);
  const scenarios = useAppSelector(state => selectScenariosByQuoteIds(state, quoteIds));

  const handleCheckboxToggled = (selected: boolean, scenarioId: ScenarioId) => {
    let newIncludedScenarios: ScenarioId[];

    if (selected && !includedScenarios.includes(scenarioId)) {
      //If the checkbox is set to checked and it's not already included, add it
      newIncludedScenarios = [...includedScenarios, scenarioId];
      setIncludedScenarios(newIncludedScenarios);
    } else if (!selected && includedScenarios.includes(scenarioId)) {
      //If the checkbox is set to unchecked and it's included, remove it
      newIncludedScenarios = includedScenarios.filter(id => id !== scenarioId);
    } else {
      return;
    }

    setIncludedScenarios(newIncludedScenarios);
  };

  return (
    <Card sx={{ maxHeight: '100%' }}>
      {/*Need to use li html element as the subheader here otherwise there are weird gaps around the subheader that let you see elements under it*/}
      <List sx={{ overflow: 'auto', maxHeight: '500px' }} subheader={<li />}>
        {quotes.map(quote => {
          const scenariosForQuote = scenarios.filter(scenario => scenario.quoteId === quote.quoteId && scenario.isVisible);
          if (scenariosForQuote.length < 1) return <></>;
          return (
            <>
              {/*Z-Index set to 2 here because if you have an input in the list item then the text of it would pop through the subheader when it should be covered*/}
              <ListSubheader sx={{ zIndex: 2 }}>{quote.name}</ListSubheader>
              {scenariosForQuote.map(scenario => (
                <SelectedScenarioCheckboxInput
                  key={scenario.scenarioId}
                  scenarioId={scenario.scenarioId}
                  scenarioName={scenario.name}
                  selected={includedScenarios.includes(scenario.scenarioId)}
                  handleSelectionChanged={handleCheckboxToggled}
                  extraContent={getScenarioSelectionContent !== undefined ? getScenarioSelectionContent(scenario.scenarioId) : undefined}
                />
              ))}
            </>
          );
        })

        }

      </List>
    </Card>
  );
};