import { LineData } from '../pages/scenarioAnalysis/trendlineAnalysis/charts/AnalysisLineChart';
import { TrendlineAnalysisId } from '../types/api/PrimaryKeys';
import TrendlineAnalysisLineData from '../types/api/TrendlineAnalysisLineData';
import { TrendlineType } from '../types/api/enums/trendlineAnalysis/TrendlineType.enum';

export const useLineData = <TData>(
  lineData: LineData<TData>[],
  trendlineAnalysisId: TrendlineAnalysisId,
  cachedLineData: TrendlineAnalysisLineData[],
  trendlineType: TrendlineType,
  defaultKeys: Set<keyof TData>) => {
  const trendlineLineData: TrendlineAnalysisLineData[] = lineData.map(ld => {
    return {
      trendlineLineDataId: `${trendlineAnalysisId}-${ld.dataKey}-${trendlineType}`,
      trendlineAnalysisId: trendlineAnalysisId,
      dataKey: ld.dataKey,
      name: ld.name,
      color: ld.color,
      isVisible: cachedLineData.find(x => x.dataKey === ld.dataKey)?.isVisible ?? defaultKeys.has(ld.dataKey),
      type: trendlineType,
    };
  });

  return trendlineLineData;
};