import { useState } from 'react';
import { currentlySelectedApplicationWizard } from '../../../../app/applicationsSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { AppTask } from '../../../../types/api/AppTask';
import { Nullable } from '../../../../types/util/Nullable';


export const useLandlordTenantAssignmentModal = () => {
  const application = useAppSelector(currentlySelectedApplicationWizard);
  const [selectedAppTask, setSelectedAppTask] = useState<Nullable<AppTask>>(null);

  const openModal = (appTask: AppTask) => {
    setSelectedAppTask(appTask);
  };

  const applyAndClose = () => {
    cancel();
  };

  const cancel = () => {
    setSelectedAppTask(null);
  };

  return {
    application,
    selectedAppTask,
    openModal,
    applyAndClose,
    cancel,
  };
};