import { Controller, useFormContext, useWatch } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectEntityTypes, selectTaxTypes } from '../../../app/insuredsSlice';
import { EntityTypeId, TaxTypeId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import { validateTaxTypeId } from '../../ag-grid/contactInfoGrid/validation/validateContactInfo';
import { PersonOrBusiness } from '../../../types/api/enums/contactInfo/personOrBusiness';
import { useEffect } from 'react';

type InsuredEntityTypeInputProps = {
  value: Nullable<TaxTypeId>;
  disabled: boolean;
  required?: boolean;
  defaultEntityTypeId: Nullable<EntityTypeId>;
  defaultPersonOrBusinessType: Nullable<PersonOrBusiness>;
}

export type TaxIdTypeFields = {
  taxTypeId: Nullable<TaxTypeId>;
}

const TaxIdTypeInput = ({ value, disabled, defaultEntityTypeId, defaultPersonOrBusinessType, required = true }: InsuredEntityTypeInputProps) => {
  const { control, trigger } = useFormContext();
  const taxTypes = useAppSelector(selectTaxTypes);
  const entityTypes = useAppSelector(state => selectEntityTypes(state));

  const entityTypeId = useWatch({ name: 'entityTypeId', control: control, defaultValue: defaultEntityTypeId });
  const personOrBusiness = useWatch({ name: 'personOrBusiness', control: control, defaultValue: defaultPersonOrBusinessType });
  const insuredType = useWatch({ name: 'type', control: control, defaultValue: 'Insured' });

  useEffect(() => {
    trigger('taxTypeId');
  }, [entityTypeId, personOrBusiness, insuredType, entityTypes, taxTypes]);

  return (
    <Controller
      control={control}
      name="taxTypeId"
      rules={{ required: required ? VALIDATION_RULES.required() : false, validate: (taxIdType: Nullable<TaxTypeId>) => validateTaxTypeId(personOrBusiness, taxIdType, entityTypeId, entityTypes, taxTypes, undefined, insuredType) }}
      defaultValue={value ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => {
        return (
          <TextField
            {...field}
            disabled={disabled}
            type="text"
            label="Tax ID Type"
            fullWidth
            select
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            size="small"
          >
            {taxTypes.filter(x => x.name !== 'Unspecified').map(type => <MenuItem key={type.taxTypeId} value={type.taxTypeId}>{type.name}</MenuItem>)}
          </TextField>
        );
      }}
    />
  );
};

export default TaxIdTypeInput;