import { ScenarioPieceGroup } from '../../types/api/ScenarioPieceGroup';
import { QuoteId } from '../../types/api/PrimaryKeys';
import { useAppDispatch, useKeyMapSelector, useKeyMapSelectorSingle } from '../../hooks/reduxHooks';
import {
  selectCalculationsByScenarioPieceGroup,
  updateCalculationForScenario
} from '../../app/calculationResultsSlice';
import { Grid } from '@mui/material';
import PayoutGrid from '../../components/payoutGrid/payoutGrid.component';
import { getFriendlyScenarioPieceName } from '../../utils/scenarioPieceUtils';
import { ScenarioPieceGroupTypeAttributes } from '@silveus/calculations';
import InsuranceResultSummary from '../scenarioPiece/insuranceResultSummary.component';
import ScenarioComponentCard from '../scenarioPiece/scenarioComponentCard';
import { updateUnitGroupsForScenarioPiece } from '../../app/unitGroupsSlice';
import {
  modifyScenarioPieceGroup,
  removeScenarioPieceGroup,
  selectAllScenarioPiecesByScenarioPieceGroupMap
} from '../../app/scenarioPieceGroupsSlice';
import ScenarioComponentSubItemSummary from '../scenarioPiece/scenarioComponentSubItemSummary';
import ScenarioPieceTooltip from '../scenarioPiece/scenarioPieceTooltip';

interface ScenarioPieceGroupCardProps {
  scenarioPieceGroup: ScenarioPieceGroup;
  quoteId: QuoteId;
  isScenarioActive: boolean;
  isReadonly?: boolean;
}

const ScenarioPieceGroupCard = ({ scenarioPieceGroup, quoteId, isScenarioActive, isReadonly = false }: ScenarioPieceGroupCardProps) => {
  const dispatch = useAppDispatch();
  const scenarioPieces = useKeyMapSelector(selectAllScenarioPiecesByScenarioPieceGroupMap, scenarioPieceGroup.scenarioPieceGroupId);
  const calcResults = useKeyMapSelectorSingle(selectCalculationsByScenarioPieceGroup, scenarioPieceGroup.scenarioPieceGroupId);
  const netScenarioPieceGroupTotal = calcResults === null ? 0 : calcResults.indemnityPerAcre - calcResults.producerPremiumPerAcre;

  const primaryScenarioPiece = scenarioPieces.find(sp => sp.scenarioPieceId === scenarioPieceGroup.primaryScenarioPieceId) ?? null;
  const primaryPieceCalcResult = calcResults?.scenarioPieces.find(sp => sp.id === primaryScenarioPiece?.scenarioPieceId) ?? null;

  const nonPrimaryScenarioPieces = scenarioPieces.filter(sp => sp.scenarioPieceId !== scenarioPieceGroup.primaryScenarioPieceId);

  const areAnyPiecesInGroupInvalid = scenarioPieces.some(sp => sp.isInvalid);

  const expandedCardBody = (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <PayoutGrid
          totalPremium={calcResults?.producerPremium ?? 0}
          totalIndemnity={calcResults?.indemnityAmount ?? 0}
          premiumPerAcre={calcResults?.producerPremiumPerAcre ?? 1}
          indemnityPerAcre={calcResults?.indemnityPerAcre ?? 0}
          totalFixed={calcResults?.totalFixed ?? 0}
          totalSimulated={calcResults?.totalSimulated ?? 0}
          fixedPerAcre={calcResults?.fixedPerAcre ?? 0}
          simulatedPerAcre={calcResults?.simulatedPerAcre ?? 0}
          totalDiscount={0}
          discountPerAcre={0}
          isReadonly={isReadonly}
        />
      </Grid>
      {primaryScenarioPiece !== null && <Grid item xs={12}>
        <ScenarioComponentSubItemSummary
          calcResults={primaryPieceCalcResult}
          label={getFriendlyScenarioPieceName(primaryScenarioPiece.scenarioPieceType)}
          scenarioId={primaryScenarioPiece.scenarioId}
          netValue={primaryPieceCalcResult === null ? 0 : primaryPieceCalcResult.indemnityPerAcre - primaryPieceCalcResult.producerPremiumPerAcre}
        />
      </Grid>}
      {nonPrimaryScenarioPieces.map(scenarioPiece => {
        const scenarioPieceCalcResult = calcResults?.scenarioPieces.find(sp => sp.id === scenarioPiece.scenarioPieceId) ?? null;
        const netValue = scenarioPieceCalcResult === null ? 0 : scenarioPieceCalcResult.indemnityPerAcre - scenarioPieceCalcResult.producerPremiumPerAcre;

        return (
          <Grid item xs={12} key={scenarioPiece.scenarioPieceId}>
            <ScenarioComponentSubItemSummary
              calcResults={scenarioPieceCalcResult}
              label={getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType)}
              scenarioId={scenarioPiece.scenarioId}
              netValue={netValue}
            />
          </Grid>
        );
      })}
    </Grid>
  );

  const updateScenarioPieceGroup = async (newScenarioPieceGroup: ScenarioPieceGroup, isActive?: boolean) => {
    await dispatch(modifyScenarioPieceGroup({ scenarioPieceGroup: { ...newScenarioPieceGroup, isActive: isActive ?? newScenarioPieceGroup.isActive } }));
    const unitGroupPromises = newScenarioPieceGroup.scenarioPieceGroupMembers.map(scenarioPieceGroupMember => {
      return dispatch(updateUnitGroupsForScenarioPiece({ scenarioPieceId: scenarioPieceGroupMember.scenarioPieceId }));
    });
    await Promise.all(unitGroupPromises);
    await dispatch(updateCalculationForScenario({ scenarioId: newScenarioPieceGroup.scenarioId }));
  };

  const deleteScenarioPieceGroup = async (scenarioPieceGroup: ScenarioPieceGroup) => {
    await dispatch(removeScenarioPieceGroup({ scenarioPieceGroup: scenarioPieceGroup }));
  };

  const getDisplayName = (scenarioPieceGroup: ScenarioPieceGroup) => Object.values(ScenarioPieceGroupTypeAttributes).find(spt => spt.value === scenarioPieceGroup.scenarioPieceGroupType)?.description ?? '';

  return (
    <ScenarioComponentCard
      scenarioComponent={scenarioPieceGroup}
      quoteId={quoteId}
      isScenarioActive={isScenarioActive}
      updateScenarioComponent={updateScenarioPieceGroup}
      removeScenarioComponent={deleteScenarioPieceGroup}
      label={getDisplayName(scenarioPieceGroup)}
      netValue={netScenarioPieceGroupTotal}
      scenarioId={scenarioPieceGroup.scenarioId}
      calcResults={calcResults}
      getFormName={getDisplayName}
      collapsedCardBody={<InsuranceResultSummary calcResults={calcResults} hideTrigger isReadonly={isReadonly} />}
      expandedCardBody={expandedCardBody}
      getScenarioPieceTooltip={() => <ScenarioPieceTooltip displayName={getDisplayName(scenarioPieceGroup)} calcResults={calcResults} />}
      isReadonly={isReadonly}
      isInvalid={areAnyPiecesInGroupInvalid}
    />
  );
};

export default ScenarioPieceGroupCard;