import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { Insured } from '../types/api/insureds/Insured';
import { AgentTeamId, InsuredId } from '../types/api/PrimaryKeys';
import { Nullable } from '../types/util/Nullable';
import { getKeyedStateValues } from './sliceHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import {
  getEntityTypesRequest,
  getInsuredEntityTypesRequest,
  getInsuredRequest,
  getInsuredsRequest,
  getPreDownloadedInsuredsRequest,
  getTaxTypesRequest
} from '../services/requestInterception/insuredRequestInterceptor';
import { orderByMappedValues } from '../utils/arrayUtils';
import { getCountiesForInsuredsRequest } from '../services/requestInterception/insuredRequestInterceptor';
import { AutoCompleteOption } from '../types/api/insureds/AutoCompleteOption';
import { TaxType } from '../types/api/insureds/TaxType';
import { EntityType } from '../types/api/insureds/EntityType';
import { createInsured, updateInsured } from '../services/insureds.service';
import { openToast } from './toastSlice';

export interface InsuredState {
  allInsureds: SliceDataState<InsuredId, Insured>;
  currentInsuredId: Nullable<InsuredId>;
  countiesForInsureds: string[];
  preDownloadedInsureds: InsuredId[];
  /**
   * Deprecated, use entityTypes instead. D365 has deprecated the enums for entity types and is using look up tables for these values now.
   * We can safely delete this once insured info application page has been refactored.
   */
  insuredEntityTypes: AutoCompleteOption[];
  taxTypes: TaxType[];
  entityTypes: EntityType[];
  showCreateInsuredModal: boolean;
  showEditInsuredModal: boolean;
}

const initialState: InsuredState = {
  allInsureds: initialSliceDataState(),
  currentInsuredId: null,
  countiesForInsureds: [],
  preDownloadedInsureds: [],
  insuredEntityTypes: [],
  taxTypes: [],
  entityTypes: [],
  showCreateInsuredModal: false,
  showEditInsuredModal: false,
};

export const insuredsSlice = createSlice({
  name: 'insureds',
  initialState: initialState,
  reducers: {
    setCurrentInsuredId(state: InsuredState, action: PayloadAction<Nullable<InsuredId>>) {
      state.currentInsuredId = action.payload;
    },
    toggleCreateInsuredsModal(state: InsuredState) {
      state.showCreateInsuredModal = !state.showCreateInsuredModal;
    },
    toggleEditInsuredsModal(state: InsuredState) {
      state.showEditInsuredModal = !state.showEditInsuredModal;
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allInsureds, i => i.id);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchInsured,
      affectedIds: arg => arg.insuredId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchInsureds,
      affectedIds: (_, state) => getKeyedStateValues(state.allInsureds.data).map(i => i.id),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyInsured,
      affectedIds: arg => arg.insured.id,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addInsured,
      affectedIds: arg => arg.id,
    });

    builder
      // fetchInsuredEntityTypes
      .addCase(fetchInsuredEntityTypes.pending, (state: InsuredState) => {
        state.insuredEntityTypes = [];
      })
      .addCase(fetchInsuredEntityTypes.fulfilled, (state: InsuredState, action: PayloadAction<AutoCompleteOption[]>) => {
        state.insuredEntityTypes = action.payload.sort((a, b) => a.label.localeCompare(b.label));
      })
      .addCase(fetchTaxTypes.fulfilled, (state: InsuredState, action: PayloadAction<TaxType[]>) => {
        state.taxTypes = action.payload.sort((a, b) => a.name.localeCompare(b.name));
      })
      .addCase(fetchEntityTypes.fulfilled, (state: InsuredState, action: PayloadAction<EntityType[]>) => {
        state.entityTypes = action.payload;
      })
      .addCase(fetchCountiesForInsureds.pending, (state: InsuredState) => {
        state.countiesForInsureds = [];
      })
      .addCase(fetchCountiesForInsureds.fulfilled, (state: InsuredState, action: PayloadAction<string[]>) => {
        state.countiesForInsureds = action.payload;
      })
      .addCase(fetchPreDownloadedInsureds.pending, (state: InsuredState) => {
        state.preDownloadedInsureds = [];
      })
      .addCase(fetchPreDownloadedInsureds.fulfilled, (state: InsuredState, action: PayloadAction<InsuredId[]>) => {
        state.preDownloadedInsureds = action.payload;
      });
  },
});

// Memoized Selectors
const selectInsuredDictionary = (state: RootState) => state.insureds.allInsureds.data;

// This memoized both because of the required cost of iterating a potentially large array, and also returning a new array every call.
export const selectAllInsureds = createSelector([selectInsuredDictionary], result => {
  const allInsureds = getKeyedStateValues(result);
  const ordered = orderByMappedValues(allInsureds, [{ map: i => i.name?.toLocaleLowerCase() ?? '' }]);
  return ordered;
});

export const selectAllInsuredsForAgentTeam = createSelector([selectAllInsureds, (_, agentTeamId: AgentTeamId) => agentTeamId], (allInsureds, agentTeamId) => {
  return allInsureds.filter(insured => insured.agentTeamId === agentTeamId);
});

export const selectInsuredsForIds = createSelector([selectAllInsureds, (state: RootState, insuredIds: InsuredId[]) => insuredIds], (allInsureds, insuredIds) => {
  return allInsureds.filter(insured => insuredIds.includes(insured.id));
});


// Non-Memoized Selectors
export const selectCurrentInsuredId = (state: RootState) => state.insureds.currentInsuredId;
export const selectCurrentInsured = (state: RootState): Nullable<Insured> => state.insureds.currentInsuredId === null ? null : state.insureds.allInsureds.data[state.insureds.currentInsuredId] ?? null;
export const selectInsuredById = (state: RootState, insuredId: Nullable<InsuredId>): Nullable<Insured> => insuredId === null ? null : state.insureds.allInsureds.data[insuredId] ?? null;
export const selectCountiesForInsureds = (state: RootState) => state.insureds.countiesForInsureds;
export const selectPreDownloadedInsureds = (state: RootState) => state.insureds.preDownloadedInsureds;
export const selectInsuredEntityTypes = (state: RootState) => state.insureds.insuredEntityTypes;
export const selectTaxTypes = (state: RootState) => state.insureds.taxTypes;
export const selectEntityTypes = (state: RootState) => state.insureds.entityTypes;
export const selectShowCreateInsuredsModal = (state: RootState) => state.insureds.showCreateInsuredModal;
export const selectShowEditInsuredsModal = (state: RootState) => state.insureds.showEditInsuredModal;

export const { setCurrentInsuredId, toggleCreateInsuredsModal, toggleEditInsuredsModal } = insuredsSlice.actions;

export const fetchInsureds = createAppAsyncThunk('insureds/fetchInsureds', async () => {
  return await getInsuredsRequest();
});

export const fetchInsured = createAppAsyncThunk('insureds/fetchInsured', async ({ insuredId }: { insuredId: InsuredId }) => {
  return await getInsuredRequest(insuredId);
});

export const fetchInsuredEntityTypes = createAppAsyncThunk('insureds/fetchInsuredEntityTypes', async () => {
  return await getInsuredEntityTypesRequest();
});

export const fetchEntityTypes = createAppAsyncThunk('insureds/fetchEntityTypes', async () => {
  return await getEntityTypesRequest();
});

export const fetchTaxTypes = createAppAsyncThunk('insureds/fetchTaxTypes', async () => {
  return await getTaxTypesRequest();
});

export const fetchCountiesForInsureds = createAppAsyncThunk('insureds/fetchCountiesForInsureds', async ({ insuredIds }: { insuredIds: InsuredId[] }) => {
  return insuredIds.length === 0 ? [] : await getCountiesForInsuredsRequest(insuredIds);
});

export const fetchPreDownloadedInsureds = createAppAsyncThunk('insureds/fetchPreDownloadedInsureds', async () => {
  return await getPreDownloadedInsuredsRequest();
});

export const modifyInsured = createAppAsyncThunk('insureds/updateInsured', async ({ insured, updateTaxId }: { insured: Insured, updateTaxId: boolean }, thunkApi) => {
  const updateInsuredResult = (await updateInsured(insured, updateTaxId)).data;
  if (!updateInsuredResult.success) {
    thunkApi.dispatch(openToast({ type: 'error', message: updateInsuredResult.message, shouldTimeout: true, allowClickToClose: true }));
    throw new Error(updateInsuredResult.message);
  }
  if (!updateTaxId) {
    const existingInsured = selectInsuredById(thunkApi.getState(), insured.id);
    if (existingInsured) {
      insured.taxId = existingInsured.taxId;
    }
  }
  return insured;
});

export const addInsured = createAppAsyncThunk('insureds/addInsured', async (insured: Insured, thunkApi) => {
  const addInsuredResult = (await createInsured(insured)).data;
  if (!addInsuredResult.success) {
    thunkApi.dispatch(openToast({ type: 'error', message: addInsuredResult.message, shouldTimeout: true, allowClickToClose: true }));
    throw new Error(addInsuredResult.message);
  }
  return insured;
});

export default insuredsSlice.reducer;