import { ClientFileId, QuoteId, ScenarioId } from '../types/api/PrimaryKeys';
import { Nullable } from '../types/util/Nullable';
import { fetchClientFile, selectClientFileById, setCurrentClientFile } from './clientFilesSlice';
import { fetchInsured, setCurrentInsuredId } from './insuredsSlice';
import { setCurrentAgentTeamId } from './agentTeamsSlice';
import { fetchMatricesByScenarioIds } from './matricesSlice';
import { fetchQuotes, selectQuotesByClientFileId } from './quotesSlice';
import { fetchScenarioPiecesByScenarioIds } from './scenarioPiecesSlice';
import { fetchAdmDataForQuotes, fetchScenariosByClientFile, selectAllScenariosByQuoteIdMap, selectScenariosByIds } from './scenariosSlice';
import { fetchUnitYearsForQuotes } from './unitsSlice';
import { fetchScenarioUnitYearAphForScenarios, fetchUnitYearAphForQuotes } from './unitYearAphSlice';
import { fetchUnitGroupsByScenarioIds } from './unitGroupsSlice';
import { fetchScenarioOptionsForScenarios, fetchScenarioOptionUnitYearsForScenarios } from './optionsSlice';
import { createAppAsyncThunk } from './thunkHelpers';
import { fetchOfferAvailabilities } from './availabilitySlice';
import { fetchTrendlinesByScenarioIds } from './trendlineAnalysisSlice';
import { fetchHistoricalAnalysesByScenarioIds } from './historicalAnalysisSlice';
import {
  fetchAvailableOptionSelections,
  fetchCeppMappings,
  fetchCountyYieldInfo,
  fetchHistoricalStormEvents,
  fetchHistoricalTYields,
  fetchHistoricalYieldTrendsAndHistoricalYieldTrendYears,
  fetchInsuranceCalendars,
  fetchMyaPriceHistoriesRequest,
  fetchPriceGroupMembersAndPriceHistory,
  fetchAllScenarioPricesAndYieldsPriorYear,
  fetchTrendAdjustmentFactors,
  fetchYeYears,
  selectAllPriceGroups
} from './admSlice';
import { fetchPremiumBreakdownsByScenarioIds } from './premiumBreakdownSlice';
import {
  fetchInputCostScenarioPiecesByScenarioIds
} from './inputCostScenarioPiecesSlice';
import {
  fetchForwardSoldScenarioPiecesByScenarioIds
} from './forwardSoldScenarioPiecesSlice';
import {
  fetchHarvestRevenueScenarioPiecesByScenarioIds
} from './harvestRevenueScenarioPiecesSlice';
import {
  fetchHailScenarioPieceCompositionsByScenarioIds
} from './hailSlice';
import { fetchScenarioPieceGroupsByScenarioIds } from './scenarioPieceGroupsSlice';
import { AppDispatch, RootState } from './store';
import { fetchAllApplicationWizardsForInsured } from './applicationsSlice';
import { getItemsForId } from '../utils/mapHelpers';
import { Quote } from '../types/api/Quote';
import { RowCropScenario } from '../types/api/RowCropScenario';
import { distinct } from '../utils/arrayUtils';
import { validateAndUpdateScenarios } from './validationsSlice';
import { fetchMatrixHeatMapDataByScenarioIds } from './matrixHeatMapDataSlice';
import { fetchAvailableIntervals, fetchIntervalPrices, fetchSelectedIntervals } from './intervalsSlice';
import { fetchAgentTeamsForCurrentUser } from './agentTeamsSlice';
import { ClientFileOwnership } from '../types/clientFile/ClientFileOwnership';
import { isNotNullOrUndefined } from '../utils/nullHandling';
import { fetchAppTaskStatusesForClientFile } from './appTaskStatusSlice';
import { MissingClientFileInStateError } from '../errors/state/MissingStateErrors';
import { fetchFarmBillArcYieldHistories } from './privateProductSlice';
import { getDistinctSymbolsFromPriceGroups } from '../utils/priceGroupUtils';
import { fetchAgentAndAgency } from './agentSlice';
import { fetchTemplateScenariosForUser } from './templateScenariosSlice';
import { beginNamedTrackedLoading, endNamedTrackedLoading } from './loaderSlice';

export const synchronizeStateWithRouteContext = createAppAsyncThunk(
  'routingThunks/synchronize-with-route-context',
  async ({ clientFileOwnership, clientFileId }: {
    clientFileOwnership: Partial<ClientFileOwnership>,
    clientFileId: Nullable<ClientFileId>
  }, thunkApi) => {
    // Note on why the "forceRefresh" are necessary:
    // At present time, the "current" state is actually persisted across browser sessions, and
    // the methods below are coded to only update if the current value has changed.
    // With these things combined, on a browser refresh (which is the only case this is trying to handle currently),
    // none of the updates would trigger due to no perceived change.

    // We want to update the current insured and the client file here even if they are null. This method will do both of these things
    thunkApi.dispatch(changeCurrentClientFile({ clientFileOwnership, clientFileId, forceRefresh: true }));

    // Global data fetches (until we have a better spot to put these).
    thunkApi.dispatch(fetchGlobalApplicationData());
  });

/** Initiate any global data fetches.
 * These are not dependent on any specific client file or quote, but are necessary for the application to function correctly.
 */
const fetchGlobalApplicationData = createAppAsyncThunk(
  'routingThunks/fetchGlobalApplicationData',
  async (_, thunkApi) => {
    await Promise.all([
      thunkApi.dispatch(fetchCeppMappings()),
      thunkApi.dispatch(fetchAgentTeamsForCurrentUser()),
      thunkApi.dispatch(fetchAvailableIntervals({ onlyNew: false })),
      thunkApi.dispatch(fetchTemplateScenariosForUser()),
    ]);
  },
);

export const changeCurrentClientFileOwner = createAppAsyncThunk(
  'routingThunks/changeCurrentClientFileOwner',
  async ({ clientFileOwnership, forceRefresh = false }: {
    clientFileOwnership: Partial<ClientFileOwnership>,
    forceRefresh?: boolean
  }, thunkApi) => {
    const state = thunkApi.getState();

    const { insuredId, agentTeamId } = {
      insuredId: clientFileOwnership.insuredId ?? null,
      agentTeamId: clientFileOwnership.agentTeamId ?? null,
    };
    // No-Op
    if (!forceRefresh && (state.insureds.currentInsuredId === insuredId && state.agentTeams.currentAgentTeamId === agentTeamId)) {
      return;
    }

    // Invalidate Children
    thunkApi.dispatch(setCurrentClientFile(null));

    // Set principle
    thunkApi.dispatch(setCurrentInsuredId(insuredId ?? null));
    thunkApi.dispatch(setCurrentAgentTeamId(agentTeamId ?? null));

    if (isNotNullOrUndefined(insuredId)) {
      // Principle data fetch
      thunkApi.dispatch(fetchInsured({ insuredId }));

      // Additional information fetch
      thunkApi.dispatch(fetchAllApplicationWizardsForInsured({ insuredId }));
    }
  });

export const changeCurrentClientFile = createAppAsyncThunk(
  'routingThunks/changeCurrentClientFile',
  async ({ clientFileId, clientFileOwnership, forceRefresh = false }: {
    clientFileId: Nullable<ClientFileId>,
    clientFileOwnership: Partial<ClientFileOwnership>,
    forceRefresh?: boolean
  }, thunkApi) => {

    const state = thunkApi.getState();

    // No Op. Do nothing.
    if (!forceRefresh && state.clientFiles.currentClientFileId === clientFileId) {
      return;
    }

    // Trigger Potential Parent Change
    thunkApi.dispatch(changeCurrentClientFileOwner({ clientFileOwnership, forceRefresh }));

    // Set Principle
    thunkApi.dispatch(setCurrentClientFile(clientFileId));

    // Everything below this point requires a valid client file id.
    if (clientFileId === null) { return; }

    const trackedName = 'changeCurrentClientFile_loadAllDataForClientFile';
    thunkApi.dispatch(beginNamedTrackedLoading(trackedName));
    await thunkApi.dispatch(loadAllDataForClientFile(clientFileId));
    thunkApi.dispatch(endNamedTrackedLoading(trackedName));
  });

export const loadAllDataForClientFile = createAppAsyncThunk(
  'routingThunks/loadAllDataForClientFile',
  async (clientFileId: ClientFileId, thunkApi) => {
    // Principle Data Fetch
    const clientFileFetchPromise = thunkApi.dispatch(fetchClientFile({ clientFileId }));

    // Required Children
    const quotesFetchPromise = thunkApi.dispatch(fetchQuotes({ clientFileId }));

    // Wait for all of the above to complete.
    await Promise.all([clientFileFetchPromise, quotesFetchPromise]);

    // Refresh state after making the above calls, because we need the newly-loaded data.
    const revisedState = thunkApi.getState();

    const clientFile = selectClientFileById(revisedState, clientFileId);
    if (clientFile === null) {
      throw new MissingClientFileInStateError(clientFileId);
    }

    //Get the agent information for the client file.
    const agentInformation = thunkApi.dispatch(fetchAgentAndAgency(clientFile.insuredId));

    // Get all of the quotes for the client file.
    const quotesForClientFile = selectQuotesByClientFileId(revisedState, clientFileId);

    // Load all of the child data for the quotes.
    const childDataPromise = thunkApi.dispatch(loadAllChildDataForQuotes(quotesForClientFile));

    // Get all app task statuses that may be tied to the client file
    const appTaskPromise = thunkApi.dispatch(fetchAppTaskStatusesForClientFile(clientFileId));

    // get all mya price histories
    const myaHistoriesPromise = thunkApi.dispatch(fetchMyaPriceHistoriesRequest({ year: clientFile.year }));

    // Wait for all of the above to complete.
    await Promise.all([agentInformation, childDataPromise, appTaskPromise, myaHistoriesPromise]);
  });


/** Given a list of quotes, loads ALL possible children needed for general quoting.
 * Assumes that the quotes themselves have already been loaded into state.
*/
export const loadAllChildDataForQuotes = createAppAsyncThunk(
  'routingThunks/loadAllChildDataForQuotes',
  async (quotes: readonly Quote[], thunkApi) => {
    const distinctClientFileIdsForQuotes = distinct(quotes.map(q => q.clientFileId));

    // Get all scenarios for the quotes by grouping requests by client file.
    // Currently this is the best we can do with existing endpoints; a possible reduction in calls could be made with an endpoint that returns all scenarios for a list of quotes.
    const scenarioPromises = distinctClientFileIdsForQuotes.map(clientFileId => thunkApi.dispatch(fetchScenariosByClientFile({ clientFileId: clientFileId })));

    await Promise.all(scenarioPromises);

    await thunkApi.dispatch(loadAllSubScenarioDataForQuotes(quotes));
  });

/** Given a list of quotes, loads all SUB scenario data required for those quotes.
 * This assumes that the quotes, and their child scenarios, have already been loaded into memory.
 */
const loadAllSubScenarioDataForQuotes = createAppAsyncThunk(
  'routingThunks/loadAllSubScenarioDataForQuotes',
  async (quotes: readonly Quote[], thunkApi) => {
    const quoteIds = quotes.map(q => q.quoteId);

    if (quoteIds.length === 0) { return; }

    // All promises in this array need to complete before calculations are allowed to run. (See end of this function).
    const calculationsPredecessorPromises: Promise<unknown>[] = [];

    // === Begin Quote-Level Fetches. =======================================================
    // All of this data needs to be loaded whether scenarios exist for the quote in question or not.
    // That's why these fetches are being done prior to us pulling out scenarios for the quotes.

    calculationsPredecessorPromises.push(thunkApi.dispatch(loadQuoteLevelDataForCalculations(quoteIds)));

    // These need to happen at the quote level (so even if there are no scenarios), but do not need to block calculations.
    const priceGroupsPromise = thunkApi.dispatch(fetchAdmDataForQuotes({ quoteIds, functionToDispatch: fetchPriceGroupMembersAndPriceHistory }));
    thunkApi.dispatch(fetchAdmDataForQuotes({ quoteIds, functionToDispatch: fetchHistoricalYieldTrendsAndHistoricalYieldTrendYears }));
    thunkApi.dispatch(fetchAdmDataForQuotes({ quoteIds, functionToDispatch: fetchHistoricalStormEvents }));
    thunkApi.dispatch(fetchAdmDataForQuotes({ quoteIds, functionToDispatch: fetchAvailableOptionSelections }));

    // === End Quote-Level Fetches ===========================================================

    const state = thunkApi.getState();
    const scenarios = selectAllScenariosForQuotes(state, quotes);
    const scenarioIds = scenarios.map(s => s.scenarioId);

    await priceGroupsPromise;
    const newState = thunkApi.getState();
    const priceGroups = selectAllPriceGroups(newState);
    const priceGroupSymbols = getDistinctSymbolsFromPriceGroups(priceGroups);

    thunkApi.dispatch(fetchIntervalPrices({ symbols: priceGroupSymbols }));

    if (scenarioIds.length === 0) { return; }

    // Required Children for calculations
    calculationsPredecessorPromises.push(thunkApi.dispatch(loadAllScenarioPiecesForScenarios(scenarioIds)));

    //Dependent upon quote and client file fetches
    calculationsPredecessorPromises.push(thunkApi.dispatch(loadScenarioLevelDataForCalculations(scenarioIds)));

    // Children not required for calculations
    thunkApi.dispatch(fetchMatricesByScenarioIds({ scenarioIds }));
    thunkApi.dispatch(fetchTrendlinesByScenarioIds({ scenarioIds }));
    thunkApi.dispatch(fetchHistoricalAnalysesByScenarioIds({ scenarioIds }));
    thunkApi.dispatch(fetchPremiumBreakdownsByScenarioIds({ scenarioIds }));
    thunkApi.dispatch(fetchSelectedIntervals({ scenarioIds }));

    // Update/get any potentially missing county yield data for the prior year (for historical data)
    thunkApi.dispatch(fetchAllScenarioPricesAndYieldsPriorYear({ scenarioIds }));
    thunkApi.dispatch(fetchCountyYieldInfo({ scenarioIds }));

    // Make sure all critical promises are done before followup.
    await Promise.all(calculationsPredecessorPromises);

    // This needs to happen after the calculation predecessor calls because part of it is based on ADM data, BUT it does
    // not need to block calculations itself, which is why this is not awaited.
    thunkApi.dispatch(fetchMatrixHeatMapDataByScenarioIds({ scenarioIds }));

    // Children Update Followup -----
    // -- Important: Calculations are dependent on having an accurate lens into state. That's why there is an await
    // on the "predecessor" promises above. The EXTREMELY important bits at time of writing is the scenarios and scenario pieces.
    thunkApi.dispatch(validateAndUpdateScenarios({ scenarioIds }));
  });

export const loadAllScenarioPiecesForScenarios = (scenarioIds: ScenarioId[]) => async (dispatch: AppDispatch) => {
  const scenarioPiecePromises = [
    dispatch(fetchScenarioPiecesByScenarioIds({ scenarioIds })),
    dispatch(fetchInputCostScenarioPiecesByScenarioIds({ scenarioIds })),
    dispatch(fetchForwardSoldScenarioPiecesByScenarioIds({ scenarioIds })),
    dispatch(fetchHarvestRevenueScenarioPiecesByScenarioIds({ scenarioIds })),
    dispatch(fetchHailScenarioPieceCompositionsByScenarioIds({ scenarioIds })),
  ];

  await Promise.all(scenarioPiecePromises);
};

/** Loads all required calculations data for the provided scenario ids.
 * Note that this assumes the quotes for the scenarios must already be in state. Certain calls will not be made if the quotes for all scenarios are not in state.
 */
export const loadAllDataForCalculations = (scenarioIds: ScenarioId[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const quoteIds = selectScenariosByIds(state, scenarioIds).map(s => s.quoteId);

  // If changes or additional calls need to be made, they need to be appended to the "scenario" and "quote" specific calls, not here.
  // Appending extra calls here will break anything calling the other two functions independently, expecting them to exhaustively compose this function.
  // (Currently, the only current and expected spot that is calling the independent pieces is in this file).
  type DoNotChangeThisTypeWithoutUnderstandingWhy = [Promise<unknown>, Promise<unknown>];
  const promiseArray: DoNotChangeThisTypeWithoutUnderstandingWhy = [
    dispatch(loadQuoteLevelDataForCalculations(quoteIds)),
    dispatch(loadScenarioLevelDataForCalculations(scenarioIds)),
  ];

  await Promise.all(promiseArray);
};

/** Any calculation-related data that can be fetched with merely quote information should go here.
 * Things that need to load data even without scenarios existing yet will be calling this.
*/
const loadQuoteLevelDataForCalculations = (quoteIds: QuoteId[]) => async (dispatch: AppDispatch) => {
  if (quoteIds.length === 0) { return; }

  const promiseArray = [
    dispatch(fetchUnitYearsForQuotes({ quoteIds })),
    dispatch(fetchUnitYearAphForQuotes({ quoteIds })),
    dispatch(fetchAdmDataForQuotes({ quoteIds, functionToDispatch: fetchOfferAvailabilities })),
    dispatch(fetchAdmDataForQuotes({ quoteIds, functionToDispatch: fetchHistoricalTYields })),
    dispatch(fetchAdmDataForQuotes({ quoteIds, functionToDispatch: fetchYeYears })),
    dispatch(fetchAdmDataForQuotes({ quoteIds, functionToDispatch: fetchInsuranceCalendars })),
    dispatch(fetchAdmDataForQuotes({ quoteIds, functionToDispatch: fetchTrendAdjustmentFactors })),
    dispatch(fetchAdmDataForQuotes({ quoteIds, functionToDispatch: fetchFarmBillArcYieldHistories })),
  ];

  await Promise.all(promiseArray);
};

/** Any "normal" calculation data that doesn't need to able to be run with 0 scenarios existing yet can go here. */
const loadScenarioLevelDataForCalculations = (scenarioIds: ScenarioId[]) => async (dispatch: AppDispatch) => {
  if (scenarioIds.length === 0) { return; }

  const promiseArray = [
    dispatch(fetchScenarioPieceGroupsByScenarioIds({ scenarioIds })),
    dispatch(fetchUnitGroupsByScenarioIds({ scenarioIds })),
    dispatch(fetchScenarioOptionsForScenarios({ scenarioIds })),
    dispatch(fetchScenarioOptionUnitYearsForScenarios({ scenarioIds })),
    dispatch(fetchScenarioUnitYearAphForScenarios({ scenarioIds })),
  ];

  await Promise.all(promiseArray);
};

const selectAllScenariosForQuotes = (state: RootState, quotes: readonly Quote[]) => {
  // Pull out the scenarios from state. We're about to pull out the ones for the quotes in our context.
  const quoteScenarioMap = selectAllScenariosByQuoteIdMap(state);

  const allScenarios: RowCropScenario[] = [];

  for (const quote of quotes) {
    const scenariosForQuote = getItemsForId(quoteScenarioMap, quote.quoteId);
    allScenarios.push(...scenariosForQuote);
  }

  return allScenarios;
};
