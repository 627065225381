import { IconButton, Link, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState, MouseEvent } from 'react';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import PolicyIcon from '@mui/icons-material/Policy';
import { useWatch } from 'react-hook-form';
import { AppTask } from '../../../../types/api/AppTask';
import { isNullOrUndefined } from '../../../../utils/nullHandling';
import { envD365PolicyUrl } from '../../../../constants/envConstants';

type AppTaskOptionsButtonProps = {
  index: number;
  canEditAppTask: boolean;
  onAssignClick: (appTask: AppTask) => void;
};

const AppTaskOptionsButton = ({ index, canEditAppTask, onAssignClick }: AppTaskOptionsButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const appTask: AppTask = useWatch({ name: `appTasks.${index}` });

  if (isNullOrUndefined(appTask)) {
    return null;
  }

  const isInsuringLandlordShare = appTask.isInsuringLandlordShare ?? false;
  const isInsuringTenantShare = appTask.isInsuringTenantShare ?? false;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddLLTsClick = () => {
    onAssignClick(appTask);
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleAddLLTsClick} disabled={(!isInsuringLandlordShare && !isInsuringTenantShare) || !canEditAppTask}>
          <InsertLinkIcon sx={{ mr: 1 }} />
          Manage LLTs
        </MenuItem>
        <MenuItem onClick={handleClose} disabled={isNullOrUndefined(appTask.previousPolicyId)}>
          <PolicyIcon sx={{ mr: 1 }} />
          <Link variant="body2" target="_blank" href={`${envD365PolicyUrl}${appTask.previousPolicyId}`}>View Previous Policy</Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AppTaskOptionsButton;