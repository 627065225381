import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectCurrentInsured } from '../../../app/insuredsSlice';
import { InsuredContact } from '../../../types/api/insureds/InsuredContact';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import TaxIdTypes from '../../../constants/taxIdTypes';
import { Nullable } from '../../../types/util/Nullable';
import { InsuredId } from '../../../types/api/PrimaryKeys';

export const useInsuredContact = (): Nullable<{ contact: InsuredContact, insuredId: InsuredId }> => {
  const insured = useAppSelector(selectCurrentInsured);

  if (insured === null) return null;

  const insuredContact: InsuredContact = {
    address: insured.address,
    corporationStateId: insured.corporationState,
    email: insured.email,
    entityName: insured.name,
    firstName: insured.firstName,
    id: generatePrimaryKey(),
    lastName: insured.lastName,
    middleName: insured.middleName,
    phone: insured.phone,
    suffix: insured.suffix,
    taxId: insured.taxId,
    taxIdIsEIN: insured.taxTypeId === TaxIdTypes.EIN,
    taxIdIsRIN: insured.taxTypeId === TaxIdTypes.RIN,
    taxIdIsSSN: insured.taxTypeId === TaxIdTypes.SSN,
  };

  return { contact: insuredContact, insuredId: insured.id };
};