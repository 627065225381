import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { IEnumAttributes } from '../../../types/api/enums/IEnumAttributes';
import { UnitStructureCode } from '@silveus/calculations';
import { FormInputParameterNames } from '../constants/inputConstants';

export interface UnitStructureProps {
  unitStructure: Nullable<UnitStructureCode>;
  autoFocus?: boolean;
  disabled?: boolean;
}

export interface UnitStructureFields {
  unitStructure: UnitStructureCode;
}

export const UnitStructureInput = ({
  unitStructure, availableUnitStructures, autoFocus = false, disabled = false,
}: UnitStructureProps & { availableUnitStructures: IEnumAttributes<UnitStructureCode>[] }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={FormInputParameterNames.UnitStructure}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.validateMultipleRules(
          [
            VALIDATION_RULES.restrictDefaultValue(UnitStructureCode.Unset),
            VALIDATION_RULES.restrictNotOnListValue(availableUnitStructures.map(x => x.value)),
          ],
        ),
      }}
      defaultValue={unitStructure ?? UnitStructureCode.Unset}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label="Unit Structure"
          fullWidth
          select
          value={value}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {availableUnitStructures.map(us => <MenuItem key={us.value} value={us.value}>{us.name}</MenuItem>)}
        </TextField>
      )}
    />
  );
};
