import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Tooltip } from '@mui/material';
import { useEffect, useRef } from 'react';

interface QuickQuoteProps {
  quickQuote: boolean;
  autoFocus?: boolean;
  disabledInformation?: {
    disabled: boolean,
    reason: string,
  };
}

export type QuickQuoteFields = {
  quickQuote: boolean;
}

const QuickQuoteInput = ({ quickQuote, disabledInformation = undefined, autoFocus = false }: QuickQuoteProps) => {
  const { control } = useFormContext();
  const formControlQQLabelRef = useRef<HTMLFormElement>(null);
  useEffect(() => {
    if (autoFocus) {
      formControlQQLabelRef.current?.focus();
    }
  }, []);
  return (
    <Controller
      name="quickQuote"
      control={control}
      defaultValue={quickQuote}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl
          error={!!error}>
          <Tooltip title={disabledInformation?.reason}>
            <RadioGroup
              row
              {...field}
              value={value}
              onChange={e => field.onChange(JSON.parse(e.target.value))}
            >
              <FormControlLabel ref={formControlQQLabelRef} value="true" disabled={disabledInformation?.disabled} checked={value === true} control={<Radio />} label="Quick Quote" />
              <FormControlLabel value="false" checked={value === false} disabled={disabledInformation?.disabled} control={<Radio />} label="Unit Quote" />
            </RadioGroup>
          </Tooltip>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl >
      )}
    />
  );
};

export default QuickQuoteInput;
