import { CellDataProvider } from '@silveus/react-matrix';
import { CalculationsCellDataOverlayChild, ScenarioPieceGroupType, ScenarioPieceType, ScenarioRequestDTO } from '@silveus/calculations';
import { useCallback } from 'react';
import { runCalculationForMatrix } from '../../services/calculations/calculationDelegate.service';
import MatrixCellTooltip from '../../pages/matrix/matrixCellTooltip.component';
import { useAppSelector } from '../reduxHooks';
import { stableEmptyArray } from '../../utils/stableEmptyArray';
import { RootState } from '../../app/store';
import { selectCurrentlySelectedMatrixPreset } from '../../app/matricesSlice';

export function useMatrixCellDataProvider(scenarioRequests: ScenarioRequestDTO[]) {
  const selectedScenarioPieceTypes = useAppSelector(selectSelectedScenarioPieceTypes);

  const cellDataProvider = useCallback(createCellDataProvider(scenarioRequests, selectedScenarioPieceTypes), [scenarioRequests, selectedScenarioPieceTypes]);

  return cellDataProvider;
}

const selectSelectedScenarioPieceTypes = (state: RootState) =>
  selectCurrentlySelectedMatrixPreset(state)?.selectedScenarioPieceTypes ?? stableEmptyArray();

export function createCellDataProvider(scenarioRequests: ScenarioRequestDTO[], selectedScenarioPieceTypes: readonly (ScenarioPieceType | ScenarioPieceGroupType)[]): CellDataProvider<CalculationsCellDataOverlayChild> {
  return axisValues => {
    const cellData = runCalculationForMatrix(scenarioRequests, axisValues, selectedScenarioPieceTypes);

    for (const cell of cellData) {
      cell.renderTooltip = props => <MatrixCellTooltip overlays={cell.overlays} {...props} />;
    }

    return cellData;
  };
}