import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AgentInformation } from '../types/api/agentInformation';
import { InsuredId } from '../types/api/PrimaryKeys';
import { AgencyInformation } from '../types/api/agencyInformation';
import { createAppAsyncThunk } from './thunkHelpers';
import { isNotNullOrUndefined } from '../utils/nullHandling';
import { getAgencyInformation, getAgentInformationByAgentTeamId } from '../services/agent.service';
import { RootState } from './store';
import { Nullable } from '../types/util/Nullable';

interface AgentSliceState {
  currentAgentInformation: Nullable<AgentInformation>;
  currentAgencyInformation: Nullable<AgencyInformation>;
}

const initialState: AgentSliceState = {
  currentAgentInformation: null,
  currentAgencyInformation: null,
};

export const agentSlice = createSlice({
  name: 'agentInformation',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAgentAndAgency.fulfilled, (state, action: PayloadAction<AgentSliceState>) => {
        const updatedAgentAgencyInfo = action.payload;
        state.currentAgentInformation = updatedAgentAgencyInfo.currentAgentInformation;
        state.currentAgencyInformation = updatedAgentAgencyInfo.currentAgencyInformation;
      });
  },
});

export const selectCurrentAgentInformation = (state: RootState): Nullable<AgentInformation> => state.agentInformation.currentAgentInformation;
export const selectCurrentAgencyInformation = (state: RootState): Nullable<AgencyInformation> => state.agentInformation.currentAgencyInformation;

export const fetchAgentAndAgency = createAppAsyncThunk('agentSlice/fetchAgency', async (insuredId: Nullable<InsuredId>, thunkApi) => {
  const state = thunkApi.getState();
  if (!isNotNullOrUndefined(insuredId)) return { currentAgentInformation: null, currentAgencyInformation: null };

  const insured = state.insureds.allInsureds.data[insuredId];

  if (isNotNullOrUndefined(insured) && isNotNullOrUndefined(insured.agentTeamId)) {
    const currentAgentInformation = await getAgentInformationByAgentTeamId(insured.agentTeamId);
    const currentAgencyInformation = await getAgencyInformation(currentAgentInformation.primaryAgencyId);
    return { currentAgentInformation, currentAgencyInformation };
  }
  return { currentAgentInformation: null, currentAgencyInformation: null };
});

export default agentSlice.reducer;