import { AlertColor } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface ToastState extends ToastContent {
  isOpen: boolean;
}

export interface ToastContent {
  type: AlertColor;
  message: string;
  shouldTimeout?: boolean;
  allowClickToClose?: boolean;
}

const initialState: ToastState = {
  type: 'info',
  message: '',
  isOpen: false,
  shouldTimeout: true,
  allowClickToClose: false,
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState: initialState,
  reducers: {
    openToast(state, action: PayloadAction<ToastContent>) {
      const { type, message, shouldTimeout, allowClickToClose } = action.payload;
      state.type = type;
      state.message = message;
      state.isOpen = true;
      state.shouldTimeout = shouldTimeout ?? true;
      state.allowClickToClose = allowClickToClose ?? false;
    },
    closeToast(state) {
      state.isOpen = false;
    },
  },
});

export const selectToastOpenState = (state: RootState) => state.toast.isOpen;
export const selectToastType = (state: RootState) => state.toast.type;
export const selectToastMessage = (state: RootState) => state.toast.message;
export const selectToastTimeout = (state: RootState) => state.toast.shouldTimeout;
export const selectToastClickToClose = (state: RootState) => state.toast.allowClickToClose;

export const { openToast, closeToast } = toastSlice.actions;

export default toastSlice.reducer;