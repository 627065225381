import { useEffect } from 'react';
import { OnColorListChangeHandler, UiCellTextColorRule } from '../types';

export const useCallChangeEventHandler = (colorList: readonly UiCellTextColorRule[], onColorListChanged: OnColorListChangeHandler) => {
  useEffect(() => {
    // Make a copy of state in the exact shape the caller requires.
    // Make sure state is "brand new" so the caller can't accidentally modify component state...
    const colorListCopy = colorList.map(c => ({
      color: c.color,
      threshold: c.threshold,
    }));

    onColorListChanged(colorListCopy);

    // onColorListChanged is intentionally omitted from the dep array, because adding it places unrealistic expectations on the caller and probably undesired behavior.
    // We want to use the handler as it stands when this effect runs, not re-run this effect every time the handler changes.
  }, [colorList]);
};