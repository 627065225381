import { Button, Grid } from '@mui/material';
import { CellValueChangedEvent, ColDef, RowEditingStoppedEvent, RowValueChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import UnitYear from '../../types/api/UnitYear';
import { ConfirmStateContent, openConfirm } from '../../app/confirmSlice';
import { selectCurrentClientFile } from '../../app/clientFilesSlice';
import { modifyModalUnitColumnsForUser, selectIsLightMode, selectUnitModalColumns } from '../../app/userSettingsSlice';
import { useGridStatePersistence } from '../../hooks/useGridStatePersistence';
import GridColumnsButton from '../../components/gridColumnsButton/gridColumnsButton.component';
import { UnitGridDefaultState } from './unitGridDefaultColumns';
import { generatePrimaryKey } from '../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../types/api/PrimaryKeys';
import { Quote } from '../../types/api/Quote';
import { getColumnDefinitions } from './columnDefinitions';
import { AphModalProps, toggleUnitAphModal } from '../../app/unitsSlice';
import UnitOptionsInput from './cellEditors/unitOptionsInput';
import {
  calculateApplicableTYield,
  getSubCountyCodeFromId,
  HighRiskType,
  OptionCode,
  YIELD_DESCRIPTORS
} from '@silveus/calculations';
import './unitsGrid.css';
import { UnitYearGridItem } from './useUnitsModal';
import UnitYearAph from '../../types/api/UnitYearAph';
import { addYear, processAcreageChange, processProductionChange, processYieldChange } from '../units/utils/aph';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import UnitsSummary from '../../types/app/UnitsSummary';
import { useEffect, useRef } from 'react';
import { YeStatusType } from '../../types/api/enums/optionStates/yeStatusType.enum';
import { cloneDeep } from 'lodash';
import { getItemsForId } from '../../utils/mapHelpers';
import { fetchAvailableSubCountyCodes, selectAvailableSubCountyCodes, selectAvailableTYields } from '../../app/admSlice';
import { generatePinnedBottomData } from './unitModalSummaryHelper';
import { getFilteredTYield } from '../../utils/tYieldUtils';
import { isNullOrUndefined } from '../../utils/nullHandling';
import {
  useGetYieldRoundingPrecisionForExistingScenario
} from '../../hooks/roundingPrecision/useGetYieldRoundingPrecision';
import UnitsModalOptionsSelector from './unitsModalOptionsSelector';
import { OptionLevelCodes } from '../../constants/optionLevelCodes';
import OptionState from '../../types/app/OptionState';
import { OptionSelectionState } from '../../types/app/enums/optionSelectionState.enum';

type UnitsGridProps = {
  quote: Quote;
  scenario: RowCropScenario;
  unitsSummary: UnitsSummary;
  unitYearGridItems: UnitYearGridItem[];
  setLocalUnitYears: (unitYears: UnitYear[]) => void;
  updateUnitOptions: (unitOptionStates: OptionState[], unitYearId: UnitYearId) => void;
  addOptionToAllUnits: (unitOptionCode: OptionCode) => void;
  removeOptionFromAllUnits: (unitOptionCode: OptionCode) => void;
  localScenarioOptions: OptionState[];
  localScenarioOptionUnitYears: Map<UnitYearId, OptionState[]>;
  isGridValid: boolean;
  setIsInEditMode: (val: boolean) => void;
  localUnitYearAphMap: Map<UnitYearId, UnitYearAph[]>;
  setLocalUnitYearAphMap: (unitYearAphMap: Map<UnitYearId, UnitYearAph[]>) => void;
  initialScenarioUnitYearAph: ScenarioUnitYearAph[],
  localScenarioUnitYearAph: ScenarioUnitYearAph[],
  setLocalScenarioUnitYearAph: (scenarioUnitYearAph: ScenarioUnitYearAph[]) => void,
  setLocalScenarioOptionUnitYears: (scenarioOptionUnitYear: Map<UnitYearId, OptionState[]>) => void,
}

const defaultColDef: ColDef = {
  minWidth: 45,
  resizable: true,
  sortable: true,
  editable: true,
  filter: true,
  filterParams: 'agTextColumnFilter',
  comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
    if (isDescending) {
      if (isEmptyUnitGridRow(nodeA.data)) return 1;
      if (isEmptyUnitGridRow(nodeB.data)) return -1;
    } else {
      if (isEmptyUnitGridRow(nodeB.data)) return 1;
      if (isEmptyUnitGridRow(nodeA.data)) return -1;
    }

    const a = valueA?.toString() ?? '';
    const b = valueB?.toString() ?? '';
    return a.toLowerCase().localeCompare(b.toLowerCase());
  },
};

// We need to treat new rows differently for sorting where they always show at the top. So here we just compare
// to what an empty row is
export const isEmptyUnitGridRow = (unitYear: UnitYear) => {

  return unitYear.acres === 0 && unitYear.basicUnitNumber === '' && unitYear.optionalUnitNumber === ''
    && unitYear.farmName === null && unitYear.farmNumber === null && unitYear.sharePercent === 100
    && unitYear.section === null && unitYear.township === null && unitYear.location === null;
};

const UnitsGrid = ({
  scenario,
  unitYearGridItems,
  quote,
  unitsSummary,
  updateUnitOptions,
  addOptionToAllUnits,
  removeOptionFromAllUnits,
  localScenarioOptions,
  localScenarioOptionUnitYears,
  isGridValid,
  setIsInEditMode,
  localUnitYearAphMap,
  setLocalUnitYears,
  setLocalUnitYearAphMap,
  localScenarioUnitYearAph,
  setLocalScenarioUnitYearAph,
  setLocalScenarioOptionUnitYears,
}: UnitsGridProps) => {
  //#region Hooks

  const agGridComponent = useRef<AgGridReact>(null);
  const clientFile = useAppSelector(selectCurrentClientFile);
  const unitColumns = useAppSelector(selectUnitModalColumns);
  const isLightMode = useAppSelector(selectIsLightMode);
  const admTYields = useAppSelector(selectAvailableTYields);
  const subCountyCodes = useAppSelector(selectAvailableSubCountyCodes);
  const uraCode = getSubCountyCodeFromId(HighRiskType.URA) ?? '-';
  const subCountyCode = getSubCountyCodeFromId(scenario.highRiskTypeId);
  const yieldRoundingPrecision = useGetYieldRoundingPrecisionForExistingScenario({ quote, scenario });

  const setSelectableSubCountyCodes = (): string[] => {
    switch (scenario.highRiskTypeId) {
      case HighRiskType.URA: {
        return [uraCode];
      }
      case HighRiskType.AllLand: {
        return [...subCountyCodes];
      }
      case HighRiskType.HighRiskOnly: {
        return [...subCountyCodes.filter(code => code !== uraCode)];
      }
      default: {
        if (subCountyCode !== null) {
          return [...subCountyCodes.filter(code => code === subCountyCode)];
        } else {
          return [];
        }
      }
    }
  };
  const selectableSubCountyCodes = setSelectableSubCountyCodes();
  const defaultSubCountyCode = selectableSubCountyCodes.at(0) ?? null;

  const dispatch = useAppDispatch();

  const { onChange, onGridReady, gridApi } = useGridStatePersistence({
    defaultColumnState: UnitGridDefaultState,
    columns: unitColumns,
    saveColumnState: cols => dispatch(modifyModalUnitColumnsForUser({ columns: cols })),
  });

  useEffect(() => {
    if (gridApi) {
      const bottomRow = generatePinnedBottomData(unitsSummary, gridApi);
      gridApi.setGridOption('pinnedBottomRowData', [bottomRow]);
    }
  }, [unitsSummary, gridApi]);

  useEffect(() => {
    if (scenario.practiceId !== null && clientFile !== null) {
      dispatch(fetchAvailableSubCountyCodes({ year: clientFile.year, typeId: scenario.typeId, practiceId: scenario.practiceId, countyId: quote.countyId }));
    }
  }, [clientFile?.year, scenario.typeId, scenario.practiceId, quote.countyId]);

  //#endregion

  if (!clientFile || isNullOrUndefined(clientFile.insuredId)) {
    return null;
  }

  const locallyEditedUnits = cloneDeep(unitYearGridItems);

  //#region Local functions

  const openUnitHistory = (unitYear: UnitYear) => {
    if (agGridComponent.current) {
      agGridComponent.current.api.stopEditing();
    }

    const modalProps: AphModalProps = {
      unitYear: unitYear,
      scenarioId: scenario.scenarioId,
      initialUnitYearAph: getItemsForId(localUnitYearAphMap, unitYear.unitYearId),
      initialScenarioUnitYearAph: localScenarioUnitYearAph,
      setUnitYearAph: (unitYearAph: UnitYearAph[]) => setUnitYearAph(unitYear.unitYearId, unitYearAph),
      setScenarioUnitYearAph: setLocalScenarioUnitYearAph,
      unitYearOptions: getItemsForId(localScenarioOptionUnitYears, unitYear.unitYearId),
    };
    dispatch(toggleUnitAphModal(modalProps));
  };

  const setUnitYearAph = (unitYearId: UnitYearId, unitYearAph: UnitYearAph[]) => {
    const tempMap = new Map(localUnitYearAphMap);
    tempMap.set(unitYearId, unitYearAph);
    setLocalUnitYearAphMap(tempMap);
  };

  const deleteUnit = (unitYear: UnitYear) => {
    const confirmWindow: ConfirmStateContent = {
      title: 'Delete Unit?',
      message: 'Are you sure you want to delete this Unit? Doing so will delete the unit from all scenarios.',
      confirmText: 'Delete',
      onConfirm: () => {
        // Remove options from local state
        updateUnitOptions([], unitYear.unitYearId);
        const unitYearAph = localUnitYearAphMap.get(unitYear.unitYearId);

        const tempMap = new Map(localUnitYearAphMap);
        tempMap.delete(unitYear.unitYearId);
        setLocalScenarioUnitYearAph(localScenarioUnitYearAph.filter(scenarioUnitAph => unitYearAph === undefined || !unitYearAph.some(aph => scenarioUnitAph.unitYearAphId === aph.unitYearAphId)));
        setLocalUnitYearAphMap(tempMap);
        setLocalUnitYears(unitYearGridItems.filter(uy => uy.unitYearId !== unitYear.unitYearId));
      },
    };
    dispatch(openConfirm(confirmWindow));
  };

  const onCellValueChanged = (event: CellValueChangedEvent<UnitYearGridItem>) => {
    switch (event.column.getId()) {
      case 'priorYearProduction': {
        const asNumber = parseInt(event.newValue);

        if (isNaN(asNumber) || event.oldValue === asNumber) return false;

        const unitYear = event.data;
        const unitYearAph = getItemsForId(localUnitYearAphMap, unitYear.unitYearId);
        const updatedMap = new Map(localUnitYearAphMap);

        const priorYearAphRow = unitYearAph.find(row => row.year === unitYear.year - 1);

        //If there is no history add new aph row
        if (priorYearAphRow === undefined) {
          const { newAph, newYear } = addYear(unitYearAph, unitYear.unitYearId, unitYear.acres, unitYear.year, unitYear.commodityCode, YIELD_DESCRIPTORS.A, null, unitYear.year - 1);

          if (newYear === null) throw new Error('Unexpectedly could add aph row');

          const tempAphRow = processProductionChange(newYear, asNumber);
          const scenarioUnitYearAphRow: ScenarioUnitYearAph = {
            scenarioUnitYearAphId: generatePrimaryKey(),
            scenarioId: scenario.scenarioId,
            unitYearAphId: tempAphRow.unitYearAphId,
            yeStatus: YeStatusType.NotOptedOut,
            offlineCreatedOn: undefined,
            offlineLastUpdatedOn: undefined,
            offlineDeletedOn: undefined,
          };

          const scenarioUnitYearAph = localScenarioUnitYearAph.filter(aph => newAph.find(na => na.unitYearAphId === aph.unitYearAphId) !== undefined);
          scenarioUnitYearAph.push(scenarioUnitYearAphRow);
          setLocalScenarioUnitYearAph(scenarioUnitYearAph);
          const tempUnitYearAph = [...newAph.filter(row => row.unitYearAphId !== tempAphRow.unitYearAphId), tempAphRow];
          updatedMap.set(unitYear.unitYearId, tempUnitYearAph);
          setLocalUnitYearAphMap(updatedMap);

          break;
        }

        const tempAphRow = processProductionChange(priorYearAphRow, asNumber);
        const tempUnitYearAph = [...unitYearAph.filter(row => row.unitYearAphId !== tempAphRow.unitYearAphId), tempAphRow];
        updatedMap.set(unitYear.unitYearId, tempUnitYearAph);
        setLocalUnitYearAphMap(updatedMap);

        break;
      }
      case 'priorYearAcres': {
        const asNumber = parseInt(event.newValue);

        if (isNaN(asNumber) || event.oldValue === asNumber) return false;

        const unitYear = event.data;
        const unitYearAph = getItemsForId(localUnitYearAphMap, unitYear.unitYearId);
        const priorYearAphRow = unitYearAph.find(row => row.year === unitYear.year - 1);

        if (priorYearAphRow === undefined) break;

        const tempAphRow = processAcreageChange(priorYearAphRow, asNumber);
        const updatedMap = new Map(localUnitYearAphMap);
        const tempUnitYearAph = [...unitYearAph.filter(row => row.unitYearAphId !== tempAphRow.unitYearAphId), tempAphRow];
        updatedMap.set(unitYear.unitYearId, tempUnitYearAph);
        setLocalUnitYearAphMap(updatedMap);

        break;
      }

      case 'priorYearYield': {
        const asNumber = parseInt(event.newValue);

        if (isNaN(asNumber) || event.oldValue === asNumber) return false;

        const unitYear = event.data;
        const unitYearAph = getItemsForId(localUnitYearAphMap, unitYear.unitYearId);
        const updatedMap = new Map(localUnitYearAphMap);

        const admTYield = getFilteredTYield(admTYields, subCountyCodes, unitYear.typeId, unitYear.practiceId, unitYear.subCountyCode);
        const applicableTYield = calculateApplicableTYield(unitYearAph, admTYield?.transitionalYield ?? 0);

        const priorYearAphRow = unitYearAph.find(row => row.year === unitYear.year - 1);

        //If there is no history add new aph row
        if (priorYearAphRow === undefined) {
          const { newAph, newYear } = addYear(unitYearAph, unitYear.unitYearId, unitYear.acres, unitYear.year, unitYear.commodityCode, YIELD_DESCRIPTORS.A, null, unitYear.year - 1);

          if (newYear === null) throw new Error('Unexpectedly could add aph row');

          const tempAphRow = processYieldChange(newYear.unitYearAphId, newAph, asNumber, applicableTYield, unitYear.commodityCode).changedRow;

          if (tempAphRow === null) throw new Error('Unexpectedly could process yield change');

          const scenarioUnitYearAph = localScenarioUnitYearAph.filter(aph => unitYearAph.find(uy => uy.unitYearAphId === aph.unitYearAphId) !== undefined);
          const scenarioUnitYearAphRow: ScenarioUnitYearAph = {
            scenarioUnitYearAphId: generatePrimaryKey(),
            scenarioId: scenario.scenarioId,
            unitYearAphId: tempAphRow.unitYearAphId,
            yeStatus: YeStatusType.NotOptedOut,
            offlineCreatedOn: undefined,
            offlineLastUpdatedOn: undefined,
            offlineDeletedOn: undefined,
          };

          scenarioUnitYearAph.push(scenarioUnitYearAphRow);
          setLocalScenarioUnitYearAph(scenarioUnitYearAph);

          const tempUnitYearAph = [...newAph.filter(row => row.unitYearAphId !== tempAphRow.unitYearAphId), tempAphRow];
          updatedMap.set(unitYear.unitYearId, tempUnitYearAph);
          setLocalUnitYearAphMap(updatedMap);

          break;
        }

        const { changedAph } = processYieldChange(priorYearAphRow.unitYearAphId, unitYearAph, asNumber, applicableTYield, unitYear.commodityCode);
        updatedMap.set(unitYear.unitYearId, changedAph);
        setLocalUnitYearAphMap(updatedMap);

        break;
      }
    }
  };

  const onRowEditingStopped = (event: RowEditingStoppedEvent<UnitYearGridItem>) => {
    setIsInEditMode(false);
  };

  const onRowValueChanged = (event: RowValueChangedEvent<UnitYearGridItem>) => {
    const { data } = event;

    if (!gridApi || !data) {
      return;
    }

    const newUnitYears = [...unitYearGridItems];

    //Find out where in the array the edited item exists
    const initialIndexPosition = newUnitYears.findIndex(gridItem => gridItem.unitYearId === data.unitYearId);

    //If it doesn't exist in the array, set the position that it should be added as the end of the array
    const finalIndexPosition = initialIndexPosition < 0 ? newUnitYears.length : initialIndexPosition;

    //Replace the current item with the new copy in the same position
    newUnitYears.splice(finalIndexPosition, 1, data);

    setLocalUnitYears(newUnitYears);
  };

  const addRow = async () => {
    if (!gridApi || isNullOrUndefined(clientFile.insuredId)) return;

    const newUnitYear: UnitYearGridItem = {
      unitId: generatePrimaryKey(),
      unitYearId: generatePrimaryKey(),
      insuredId: clientFile.insuredId,
      countyId: quote.countyId,
      commodityCode: quote.commodityCode,
      typeId: scenario.typeId,
      practiceId: scenario.practiceId ?? '',
      acres: 0,
      sharePercent: 100,
      basicUnitNumber: '',
      optionalUnitNumber: '',
      year: clientFile.year,
      farmName: null,
      farmNumber: null,
      section: null,
      township: null,
      range: null,
      location: null,
      subCountyCode: defaultSubCountyCode,
      priorYearProduction: null,
      priorYearAcres: null,
      priorYearYield: null,
      offlineCreatedOn: undefined,
      offlineLastUpdatedOn: undefined,
      offlineDeletedOn: undefined,
      rateYield: null,
      approvedYield: null,
      adjustedYield: null,
      priorYearApprYield: null,
      history: [],
    };
    const tempUnitYears = [...locallyEditedUnits, newUnitYear];
    setLocalUnitYears(tempUnitYears);

    //We should only add options that are currently assigned to ALL existing units.
    //If the scenario option is on, then it's on every unit.
    const scenarioUnitOptions = localScenarioOptions.map(so => ({
      ...so, selectionState: so.selectionState === OptionSelectionState.On ? OptionSelectionState.On : OptionSelectionState.Off,
    }));
    if (scenarioUnitOptions.length === 0) return;

    const tempScenOptionUnitYears = new Map(localScenarioOptionUnitYears);
    tempScenOptionUnitYears.set(newUnitYear.unitYearId, scenarioUnitOptions);

    setLocalScenarioOptionUnitYears(tempScenOptionUnitYears);
  };

  //#endregion

  const columnDefinitions = getColumnDefinitions({
    previousYear: clientFile.year - 1,
    openUnitHistory: openUnitHistory,
    deleteUnit: deleteUnit,
    unitOptionsMap: localScenarioOptionUnitYears,
    updateUnitOptions: updateUnitOptions,
    unitYearAphMap: localUnitYearAphMap,
    setLocalUnitYears: setLocalUnitYears,
    setLocalUnitYearAphMap: setLocalUnitYearAphMap,
    setLocalScenarioUnitYearAph: setLocalScenarioUnitYearAph,
    localScenarioUnitYearAph: localScenarioUnitYearAph,
    subCountyCodes: selectableSubCountyCodes,
    yieldRoundingPrecision: yieldRoundingPrecision,
  });

  return (
    <Grid container direction="column" style={{ width: '100%', height: '100%' }} className={isLightMode ? 'ag-theme-compact units-grid' : 'ag-theme-compact-dark units-grid'} rowSpacing={1}>
      <Grid item container justifyContent="flex-end" alignItems="center" xs="auto" columnSpacing={1} style={{ paddingTop: '13px' }}>
        {gridApi && (
          <Grid item>
            <GridColumnsButton
              gridApi={gridApi}
              onChangeCallback={onChange}
              useFullButton={true}
              buttonText="Show/Hide Columns"
              disabled={!isGridValid || (gridApi.getEditingCells().length > 0)}
              excluded={['actions', 'unitNum', 'optionUnitNumber', 'sharePercent', 'options']}
            />
          </Grid>
        )}
        <Grid item>
          <UnitsModalOptionsSelector
            scenarioOptions={localScenarioOptions}
            addOptionToAllUnits={addOptionToAllUnits}
            removeOptionFromAllUnits={removeOptionFromAllUnits}
            optionLevel={OptionLevelCodes.Commodity}
            disabled={(gridApi && gridApi.getEditingCells().length > 0)}
          />
        </Grid>
        <Grid item>
          <UnitsModalOptionsSelector
            scenarioOptions={localScenarioOptions}
            addOptionToAllUnits={addOptionToAllUnits}
            removeOptionFromAllUnits={removeOptionFromAllUnits}
            optionLevel={OptionLevelCodes.Unit}
            disabled={(gridApi && gridApi.getEditingCells().length > 0)}
          />
        </Grid>
        <Grid item>
          <Button onClick={addRow} id="btn-add-unit" disabled={gridApi && gridApi.getEditingCells().length > 0}>Add Unit</Button>
        </Grid>
      </Grid>
      <Grid item xs>
        <AgGridReact<UnitYearGridItem>
          ref={agGridComponent}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          rowData={locallyEditedUnits}
          columnDefs={columnDefinitions}
          onRowEditingStarted={() => setIsInEditMode(true)}
          onCellValueChanged={onCellValueChanged}
          onRowEditingStopped={onRowEditingStopped}
          onSortChanged={onChange}
          onDragStopped={onChange}
          maintainColumnOrder={true}
          enterNavigatesVerticallyAfterEdit={false}
          enterNavigatesVertically={false}
          onRowValueChanged={onRowValueChanged}
          getRowId={item => item.data.unitYearId}
          gridOptions={{
            editType: 'fullRow',
            stopEditingWhenCellsLoseFocus: true,
            sortingOrder: ['desc', 'asc'],
            components: {
              unitOptionsEditor: UnitOptionsInput,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UnitsGrid;
