import { ScenarioId } from '../types/api/PrimaryKeys';
import { selectUserLinkedScenarios } from '../app/userSettingsSlice';
import { useAppSelector } from './reduxHooks';
import { selectScenarioById } from '../app/scenariosSlice';
import { linkedScenarioFieldStyle } from '../pages/scenario/components/scenarioStyles';
import { PossibleLinkedScenarioField } from '../types/api/userSettings/PossibleLinkedScenarioField';
import { Nullable } from '../types/util/Nullable';

const useLinkedScenarioFields = (scenarioId: Nullable<ScenarioId>) => {
  const userLinkedScenarios = useAppSelector(selectUserLinkedScenarios);
  const scenario = useAppSelector(state => scenarioId ? selectScenarioById(state, scenarioId) : null);
  if (!scenario) return { linkedScenarioData: null, getInputStyle: (_: PossibleLinkedScenarioField) => undefined };

  const linkedScenarioData = userLinkedScenarios.quotes[scenario.quoteId] ?? null;

  const isThisScenarioLinked =
    // And there is link data
    linkedScenarioData !== null &&
    // And the current scenario is a member of the link group.
    linkedScenarioData.linkedScenarioIds.includes(scenario.scenarioId);

  const getInputStyle = (fieldName: PossibleLinkedScenarioField) => {
    // Scenario isn't linked - nothing special happens.
    if (!isThisScenarioLinked) return undefined;
    // The field isn't in the link group - nothing special happens.
    if (!linkedScenarioData.linkedFields.includes(fieldName)) return undefined;

    // The field is linked! Provide special styling.
    return linkedScenarioFieldStyle;
  };

  return { linkedScenarioData, getInputStyle };
};

export default useLinkedScenarioFields;