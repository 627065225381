import { useEffect } from 'react';
import { Nullable } from '../../types/util/Nullable';
import { isNilOrEmpty, isNullOrUndefined } from '../../utils/nullHandling';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useFormContext, useWatch, Controller } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { fetchCommoditiesWithOffers, selectCommoditiesWithOffers } from '../../app/admSlice';
import { validateCommodity } from '../../pages/units/validations/commodityValidation';

interface CommodityProps {
  commodityCode: Nullable<string>;
  countyId: Nullable<string>;
  year: number;
  disabled?: boolean;
  autoFocus?: boolean;
}

export type CommodityFields = {
  commodityCode: string;
};

const CommodityInput = ({ commodityCode, countyId, year, disabled = false, autoFocus = false }: CommodityProps) => {
  const dispatch = useAppDispatch();
  const commodities = useAppSelector(selectCommoditiesWithOffers);
  const { control, setValue } = useFormContext();
  const county: string = useWatch({ name: 'countyId', control: control, defaultValue: undefined });

  useEffect(() => {
    if (isNullOrUndefined(countyId)) return;
    dispatch(fetchCommoditiesWithOffers({ countyId, year }));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(county)) return;

    setValue('commodityCode', '');

    if (!isNilOrEmpty(county))
      dispatch(fetchCommoditiesWithOffers({ countyId: county, year }));
  }, [county]);

  return (
    <Controller
      name="commodityCode"
      control={control}
      rules={{ validate: validateCommodity }}
      defaultValue={commodityCode}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Commodity"
          fullWidth
          select
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {commodities.map(c => <MenuItem key={c.commodityCode} value={c.commodityCode}>{c.name}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default CommodityInput;
