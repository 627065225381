import { OptionCode } from '@silveus/calculations';
import { OptionLevelCodes } from '../constants/optionLevelCodes';
import AvailableOptionSelections from '../types/api/adm/AvailableOptionSelections';
import UnitYear from '../types/api/UnitYear';
import OptionState from '../types/app/OptionState';
import { Nullable } from '../types/util/Nullable';
export const shouldShowOptionsByEu = (scenarioOptionsForSpForm: OptionState[]) => {
  return scenarioOptionsForSpForm.filter(o => o.option.optionLevelCode === OptionLevelCodes.EnterpriseUnit).length > 0;
};

export const deepCompareOptionState = (initialOptionState: OptionState[], currentOptionState: OptionState[]) => {
  for (const initialOption of initialOptionState) {
    const isThisOptionDifferent = currentOptionState
      .some(cos => cos.option.optionCode === initialOption.option.optionCode && cos.selectionState !== initialOption.selectionState);
    if (isThisOptionDifferent) return true;
  }
  return false;
};

export const filterScenarioOptionsByAvailability = (availableOptions: AvailableOptionSelections[], selectedOptionCodes: OptionCode[], unitYear: UnitYear, planCode: Nullable<string>, isCat: boolean): OptionCode[] => {
  const filteredAvailableOptions = availableOptions
    .filter(ao => ao.practiceId === unitYear.practiceId
      && ao.typeId === unitYear.typeId
      && (planCode === null || ao.insurancePlanCode === planCode)
      && ao.coverageTypeCode === (isCat ? 'C' : 'A'),
    ).map(ao => ao.option.optionCode);

  return selectedOptionCodes.filter(soc => filteredAvailableOptions.includes(soc));
};
