import { AcreageType, ScenarioPieceResponseDTO, ScenarioPieceType } from '@silveus/calculations';
import { ScenarioId, ScenarioPieceId } from '../../../../../../types/api/PrimaryKeys';
import { HistoricalData, HistoricalDynamicColumn } from '../types/HistoricalData';
import { useAppSelector } from '../../../../../../hooks/reduxHooks';
import { selectScenarioById } from '../../../../../../app/scenariosSlice';
import { useTrendLineYields } from '../../../../../../hooks/useTrendlineYields';
import { useTrendLinePercentages } from '../../../../../../hooks/useTrendlinePercentages';
import { selectHistoricalInputCostsYears, selectHistoricalStormEvents, selectPriceGroups } from '../../../../../../app/admSlice';
import { useHistoricalAnalysisCalculations } from '../../../../../../hooks/useHistoricalAnalysisCalculations';
import { getFriendlyScenarioPieceName } from '../../../../../../utils/scenarioPieceUtils';
import { HistoricalIncludeType } from '../../../../../../types/api/enums/historicalAnalysis/historicalIncludeType.enum';
import { getValueToUseForHistoricalQuoteResult } from '../../../getValueToUseForHistoricalQuoteResult';
import { HistoricalShowType } from '../../../../../../types/api/enums/historicalAnalysis/historicalShowType.enum';
import HistoricalStormEvent from '../../../../../../types/api/adm/HistoricalStormEvent';
import { selectQuoteById } from '../../../../../../app/quotesSlice';
import { stableEmptyArrayAsMutable } from '../../../../../../utils/stableEmptyArray';
import { isNullOrUndefined } from '../../../../../../utils/nullHandling';
import HistoricalPrice from '../../../../../../types/api/adm/HistoricalPrice';
import { selectRowCropScenarioPiecesByScenarioId } from '../../../../../../app/scenarioPiecesSlice';
import { Nullable } from '../../../../../../types/util/Nullable';
import HistoricalPercentages from '../../../../../../types/api/adm/HistoricalPercentages';
import HistoricalInputCost from '../../../../../../types/api/adm/HistoricalInputCost';
import { useHistoricalPrices } from '../../../../../../hooks/useHistoricalPrices';
import { selectAvailableIntervals, selectIntervalPrices, selectSelectedIntervals } from '../../../../../../app/intervalsSlice';
import PriceGroup from '../../../../../../types/api/adm/PriceGroup';
import { KeyedState } from '../../../../../../app/sliceHelpers';
import { IntervalPrice } from '../../../../../../types/api/adm/IntervalPrice';
import {
  getScenarioPieceDefinition
} from '../../../../../../constants/productDefinitions/scenarioPieceDefinitionRecords';
import { IntervalRange } from '../../../../../../types/api/adm/IntervalRange';
import { ScenarioPieceSelectedInterval } from '../../../../../../types/api/adm/ScenarioPieceSelectedInterval';
import { selectClientFileById } from '../../../../../../app/clientFilesSlice';

export const useHistoricalAnalysisData = (startYear: number, endYear: number, acreageType: AcreageType, scenarioId: ScenarioId, excludedYears: number[],
  includeType: HistoricalIncludeType, showType: HistoricalShowType, calculateProductsForMissingYears: boolean)
  : HistoricalData[] => {

  const selectedScenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  const rowCropScenarioPieces = useAppSelector(state => selectRowCropScenarioPiecesByScenarioId(state, scenarioId));
  const quote = useAppSelector(state => selectedScenario === null ? null : selectQuoteById(state, selectedScenario.quoteId));
  const isScenarioOrQuoteInvalid = isNullOrUndefined(selectedScenario) || isNullOrUndefined(quote);
  const yields = useTrendLineYields(scenarioId, startYear, endYear, acreageType);
  const percentChanges = useTrendLinePercentages(scenarioId, startYear, endYear, acreageType);
  const prices = useHistoricalPrices(scenarioId, startYear, endYear);
  const intervalPrices = useAppSelector(selectIntervalPrices);
  const priceGroups = useAppSelector(selectPriceGroups);
  const selectedIntervals = useAppSelector(selectSelectedIntervals);
  const availableIntervals = useAppSelector(selectAvailableIntervals);
  const clientFileYear = useAppSelector(state => isNullOrUndefined(quote) ? null : selectClientFileById(state, quote.clientFileId)?.year);

  const mpInputCosts = useAppSelector(state => isScenarioOrQuoteInvalid || isNullOrUndefined(selectedScenario.practiceId)
    ? stableEmptyArrayAsMutable<HistoricalInputCost>() :
    selectHistoricalInputCostsYears(state, quote.countyId, quote.commodityCode, selectedScenario.typeId, selectedScenario.practiceId, startYear, endYear));

  const historicalStormEvents = useAppSelector(state => isScenarioOrQuoteInvalid ?
    stableEmptyArrayAsMutable<HistoricalStormEvent>() :
    selectHistoricalStormEvents(state, quote.countyId, quote.commodityCode, startYear, endYear));

  const historicalCalcResults = useHistoricalAnalysisCalculations(startYear, endYear, acreageType, scenarioId, calculateProductsForMissingYears);

  const historicalData: HistoricalData[] = [];

  for (let year = startYear; year <= endYear; year++) {
    // we don't want to display the current client file's year in historical data
    if (year === clientFileYear) continue;
    const applicablePrices = prices.find(price => price.priceYear === year);
    const applicableYields = yields.find(historicalYield => historicalYield.year === year);
    const applicablePercentChanges = percentChanges.find(percentChange => percentChange.year === year);

    const applicableCalcResult = historicalCalcResults.yearResults.find(calcResult => calcResult.year === year)?.result ?? null;
    const flatScenarioPieces = applicableCalcResult?.scenarioPieceGroups.flatMap(spg => spg.scenarioPieces) ?? stableEmptyArrayAsMutable<ScenarioPieceResponseDTO>();

    const dynamicColumns = rowCropScenarioPieces.flatMap(scenarioPiece => {
      const scenarioPieceCalcResult = flatScenarioPieces.find(sp => sp.scenarioPieceType === scenarioPiece.scenarioPieceType) ?? null;

      return getDynamicColumnsForScenarioPiece(scenarioPiece.scenarioPieceType, scenarioPieceCalcResult, year, includeType, showType, historicalStormEvents, mpInputCosts, percentChanges,
        prices, priceGroups, intervalPrices, selectedIntervals, availableIntervals, scenarioPiece.scenarioPieceId);
    });

    historicalData.push({
      include: !excludedYears.includes(year),
      year: year,
      plant: applicablePrices?.basePrice ?? null,
      harvest: applicablePrices?.harvestPrice ?? null,
      percentChange: applicablePercentChanges?.percentChangePrice ?? null,
      expectedProducerYield: applicableYields?.trendYield ?? null,
      actualProducerYield: applicableYields?.actualYield ?? null,
      producerYieldPercentChange: applicablePercentChanges?.percentChangeProducerYield ?? null,
      expectedCountyYield: applicableYields?.expectedCountyYield ?? null,
      actualCountyYield: applicableYields?.actualCountyYield ?? null,
      countyYieldPercentChange: applicablePercentChanges?.percentChangeCountyYield ?? null,
      dynamicColumns: dynamicColumns,
      total: applicableCalcResult === null || excludedYears.includes(year) ? null : getValueToUseForHistoricalQuoteResult(applicableCalcResult.indemnityPerAcreAmount, applicableCalcResult.indemnityAmount,
        applicableCalcResult.premiumPerAcreAmount, applicableCalcResult.premiumAmount, includeType, showType),
    });
  }

  return historicalData;
};

const getDynamicColumnsForScenarioPiece = (
  scenarioPieceType: ScenarioPieceType,
  scenarioPiece: Nullable<ScenarioPieceResponseDTO>,
  year: number, includeType: HistoricalIncludeType,
  showType: HistoricalShowType,
  historicalStormEvents: HistoricalStormEvent[],
  mpInputCosts: HistoricalInputCost[],
  percentChanges: HistoricalPercentages[],
  prices: HistoricalPrice[],
  priceGroups: PriceGroup[],
  intervalPrices: KeyedState<string, IntervalPrice[]>,
  selectedIntervals: KeyedState<ScenarioPieceId, ScenarioPieceSelectedInterval[]>,
  availableIntervals: IntervalRange[],
  scenarioPieceId: ScenarioPieceId): HistoricalDynamicColumn[] => {
  const groupingKey = getFriendlyScenarioPieceName(scenarioPieceType);

  const dynamicColumns: HistoricalDynamicColumn[] = [];
  const scenarioPieceDefinition = getScenarioPieceDefinition(scenarioPieceType);
  const scenarioPieceColumns = scenarioPieceDefinition.getHistoricalQuoterColumns({
    includeType,
    showType,
    year,
    groupingKey,
    percentChanges,
    historicalStormEvents,
    scenarioPiece,
    mpInputCosts,
    prices,
    priceGroups,
    intervalPrices,
    selectedIntervals,
    availableIntervals,
    scenarioPieceId,
  },
  );
  dynamicColumns.push(...scenarioPieceColumns);

  const insColumn: HistoricalDynamicColumn = {
    groupingKey: getFriendlyScenarioPieceName(scenarioPieceType),
    value: scenarioPiece === null ? null : getValueToUseForHistoricalQuoteResult(scenarioPiece.indemnityPerAcre, scenarioPiece.indemnityAmount,
      scenarioPiece.producerPremiumPerAcre, scenarioPiece.producerPremium, includeType, showType),
    dataKey: 'ins',
  };

  dynamicColumns.push(insColumn);

  return dynamicColumns;
};