import { Quote } from '../types/api/Quote';
import { RowCropScenario } from '../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../types/api/RowCropScenarioPiece';
import UnitYear from '../types/api/UnitYear';
import ScenarioQuickUnit from '../types/api/ScenarioQuickUnit';
import {
  AvailabilityRowCropScenarioPiece,
  AvailabilityScenarioPiece,
  AvailabilityService,
  AvailabilityUnit,
  Commodity,
  createPracticeCode,
  createTypeCode,
  OrganicPractice,
  PracticeCode,
  SubCountyCode,
  TypeCode
} from '@silveus/calculations';
import { UnitYearId } from '../types/api/PrimaryKeys';
import { toPrimaryKey } from './primaryKeyHelpers';
import { ProductOfferAvailabilities } from '@silveus/calculations/dist/availability/productOfferAvailability';
import { getPracticeCodeFromPracticeId, getTypeCodeFromTypeId } from './adm';
import { ScenarioPiece } from '../types/api/ScenarioPiece';

const mapQuickUnitToAvailabilityUnit = (unit: ScenarioQuickUnit, rowCropScenarioPieceAvailability: AvailabilityRowCropScenarioPiece[], allOtherScenarioPieceAvailability: AvailabilityScenarioPiece[], insuranceOfferAvailabilities: ProductOfferAvailabilities[]) => {
  const typeCode = createTypeCode(unit.commodityCode as Commodity, getTypeCodeFromTypeId(unit.typeId));
  const practiceCode = createPracticeCode(unit.commodityCode as Commodity, getPracticeCodeFromPracticeId(unit.practiceId));

  const availabilityUnit: AvailabilityUnit = {
    id: unit.scenarioQuickUnitId,
    acres: unit.acres,
    typeCode: typeCode as TypeCode,
    practiceCode: practiceCode as PracticeCode,
    commodityCode: unit.commodityCode as Commodity,
    insuredSharePercent: unit.sharePercent,
    actualYield: unit.aphYield,
    adjustedYield: unit.aphYield,
    approvedYield: unit.aphYield,
    rateYield: unit.aphYield,
    scenarioPieces: [...rowCropScenarioPieceAvailability, ...allOtherScenarioPieceAvailability],
    organicPracticeCode: OrganicPractice.NOPS,
    isReplant: false,
    isCat: false,
    isSecondCrop: false,
    isDoubleCrop: false,
    croppingPracticeCode: '',
    isLossEvent: false,
    isPP: false,
    subCountyCode: null,
    insuranceOfferAvailabilities: insuranceOfferAvailabilities,
  };

  return [availabilityUnit];
};

const mapUnitYearsToAvailabilityUnits = (unitYears: UnitYear[], rowCropScenarioPieceAvailability: AvailabilityRowCropScenarioPiece[], allOtherScenarioPieceAvailability: AvailabilityScenarioPiece[], insuranceOfferAvailabilities: ProductOfferAvailabilities[]) => {
  const availabilityUnits = unitYears.map(unitYear => {
    //These yields are meaningless within the availability. These are just here because the availability unit extends Unit.
    //This should probably be changed in the future to just ask for exactly what it needs.
    const actualYield = 100;
    const adjustedYield = 100;
    const rateYield = 100;
    const approvedYield = 100;

    const typeCode = createTypeCode(unitYear.commodityCode as Commodity, getTypeCodeFromTypeId(unitYear.typeId));
    const practiceCode = createPracticeCode(unitYear.commodityCode as Commodity, getPracticeCodeFromPracticeId(unitYear.practiceId));


    const availabilityUnit: AvailabilityUnit = {
      id: unitYear.unitYearId,
      acres: unitYear.acres,
      actualYield: actualYield,
      adjustedYield: adjustedYield,
      approvedYield: approvedYield,
      commodityCode: unitYear.commodityCode as Commodity,
      croppingPracticeCode: '',
      insuredSharePercent: unitYear.sharePercent,
      isCat: false,
      isDoubleCrop: false,
      isLossEvent: false,
      isPP: false,
      isReplant: false,
      isSecondCrop: false,
      organicPracticeCode: OrganicPractice.NOPS,
      practiceCode: practiceCode as PracticeCode,
      rateYield: rateYield,
      scenarioPieces: [...rowCropScenarioPieceAvailability, ...allOtherScenarioPieceAvailability],
      subCountyCode: unitYear.subCountyCode as SubCountyCode | null,
      typeCode: typeCode as TypeCode,
      insuranceOfferAvailabilities: insuranceOfferAvailabilities,
    };

    return availabilityUnit;
  });

  return availabilityUnits;
};

function mapScenarioToAvailabilityUnit(quote: Quote, scenario: RowCropScenario, rowCropScenarioPieceAvailability: AvailabilityRowCropScenarioPiece[], allOtherScenarioPieceAvailability: AvailabilityScenarioPiece[], insuranceOfferAvailabilities: ProductOfferAvailabilities[]) {
  const availabilityUnit: AvailabilityUnit = {
    id: '',
    acres: 0,
    actualYield: scenario.actualProducerYield ?? 0,
    adjustedYield: 0,
    approvedYield: 0,
    commodityCode: quote.commodityCode as Commodity,
    croppingPracticeCode: '',
    insuredSharePercent: 0,
    isCat: false,
    isDoubleCrop: false,
    isLossEvent: false,
    isPP: false,
    isReplant: false,
    isSecondCrop: false,
    organicPracticeCode: OrganicPractice.NOPS,
    practiceCode: getPracticeCodeFromPracticeId(scenario.practiceId ?? '') as PracticeCode,
    rateYield: 0,
    scenarioPieces: [...rowCropScenarioPieceAvailability, ...allOtherScenarioPieceAvailability],
    subCountyCode: null,
    typeCode: getTypeCodeFromTypeId(scenario.typeId) as TypeCode,
    insuranceOfferAvailabilities: insuranceOfferAvailabilities,
  };
  return [availabilityUnit] as const;
}

export const getAvailabilityUnits = (quote: Quote, scenario: RowCropScenario, currentRowCropScenarioPieces: RowCropScenarioPiece[], currentOtherScenarioPieces: ScenarioPiece[], scenarioUnitYears: UnitYear[], insuranceOfferAvailabilities: ProductOfferAvailabilities[]) => {
  const rowCropScenarioPieceAvailability = formatRowCropScenarioPiecesForAvailabilityChecking(currentRowCropScenarioPieces);
  const allOtherScenarioPieceAvailability = formatScenarioPiecesForAvailabilityChecking(currentOtherScenarioPieces);

  if (quote.quickQuote && scenario.quickUnit !== null) return mapQuickUnitToAvailabilityUnit(scenario.quickUnit, rowCropScenarioPieceAvailability, allOtherScenarioPieceAvailability, insuranceOfferAvailabilities);
  if (scenarioUnitYears.length > 0) return mapUnitYearsToAvailabilityUnits(scenarioUnitYears, rowCropScenarioPieceAvailability, allOtherScenarioPieceAvailability, insuranceOfferAvailabilities);
  return mapScenarioToAvailabilityUnit(quote, scenario, rowCropScenarioPieceAvailability, allOtherScenarioPieceAvailability, insuranceOfferAvailabilities);
};

export const getUnitsAvailableForScenarioPiece = (scenarioPiece: ScenarioPiece, unitYears: UnitYear[], currentRowCropScenarioPieces: RowCropScenarioPiece[], currentOtherScenarioPieces: ScenarioPiece[], insuranceOfferAvailabilities: ProductOfferAvailabilities[]) => {
  const rowCropScenarioPieceAvailability = formatRowCropScenarioPiecesForAvailabilityChecking(currentRowCropScenarioPieces);
  const allOtherScenarioPieceAvailability = formatScenarioPiecesForAvailabilityChecking(currentOtherScenarioPieces);

  const availabilityUnits = mapUnitYearsToAvailabilityUnits(unitYears, rowCropScenarioPieceAvailability, allOtherScenarioPieceAvailability, insuranceOfferAvailabilities);

  const unitAvailabilities = AvailabilityService.getUnitAvailabilitiesForScenarioPiece(availabilityUnits, scenarioPiece.scenarioPieceType);

  const unitsAvailableForScenarioPiece: UnitYearId[] = [];

  for (const [unitId, unitAvailability] of unitAvailabilities.entries()) {
    if (unitAvailability.isAvailable) unitsAvailableForScenarioPiece.push(toPrimaryKey<UnitYearId>(unitId));
  }

  return unitsAvailableForScenarioPiece;
};

const formatRowCropScenarioPiecesForAvailabilityChecking = (currentRowCropScenarioPieces: RowCropScenarioPiece[]) => {
  return currentRowCropScenarioPieces.map(sp => {
    const rowCropScenarioPiece: AvailabilityRowCropScenarioPiece = {
      scenarioPieceType: sp.scenarioPieceType,
      isActive: sp.isActive,
      upperCoverageLevel: sp.upperCoverageLevel / 100,
      lowerCoverageLevel: sp.lowerCoverageLevel / 100,
      isCat: sp.rowCropScenarioPieceExtendedData?.isCat ?? false,
    };
    return rowCropScenarioPiece;
  });
};

const formatScenarioPiecesForAvailabilityChecking = (currentScenarioPieces: ScenarioPiece[]) => {
  return currentScenarioPieces.map(sp => {
    const scenarioPiece: AvailabilityScenarioPiece = {
      scenarioPieceType: sp.scenarioPieceType,
      isActive: sp.isActive,
    };
    return scenarioPiece;
  });
};