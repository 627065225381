import {
  InputCostRequestDTO,
  InputCostFixedParams,
  InputCostSimulatedParams,
  InputCostUnit,
  Unit
} from '@silveus/calculations';
import { RootState } from '../../../app/store';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import BaseUnit from '../../../types/api/BaseUnit';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { getUnitsForScenarioPiece } from '../calculationUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { InputCostScenarioPiece } from '../../../types/api/InputCostScenarioPiece';
export const createInputCostData = async (scenarioPiece: InputCostScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createInputCostDto(scenarioPiece, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformInputCostData = (unit: BaseUnit, scenario: RowCropScenario, scenarioPiece: InputCostScenarioPiece): InputCostFixedParams & InputCostSimulatedParams => {
  const inputCostParams: InputCostFixedParams & InputCostSimulatedParams = {
    projectedPrice: scenario.projectedPrice ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
    inputCosts: scenarioPiece.inputCosts,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
  };

  return inputCostParams;
};

const createInputCostUnits = (units: BaseUnit[], scenario: RowCropScenario): (InputCostUnit & Unit)[] => {
  return units.map(unit => {
    const unitDto = createBaseUnit(unit);
    const inputCostUnit: InputCostUnit & Unit = {
      ...unitDto,
      id: unit.id,
    };

    return inputCostUnit;
  });
};

const createInputCostDto = (scenarioPiece: InputCostScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): InputCostRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createInputCostUnits(
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformInputCostData(unitData[0], scenario, scenarioPiece),
  };
};