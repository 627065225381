import NavigationLink from './navigationLink.component';
import { matchPath, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import CollectionsIcon from '@mui/icons-material/Collections';
import { Grid, List, SvgIcon, Tooltip } from '@mui/material';
import { selectCurrentInsured } from '../../app/insuredsSlice';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
//Connor Julian 07/25/2024: Hid data management icon for initial release.
//import { ReactComponent as DataManagementIcon } from '../../images/dataManagementIcon.svg';
import { selectIsOnline } from '../../app/onlineSlice';
import { ReportModalButton } from '../reports/reportModalButton';
import BugReportIcon from '@mui/icons-material/BugReport';
import { openSupportModal } from '../../app/supportSlice';
import StartApplicationMenu from '../startApplicationMenu/startApplicationMenu';
import { selectCurrentClientFile } from '../../app/clientFilesSlice';

interface Props {
  clientFileOwnerClick: (route: string) => void;
  navigationClick: (route: string) => void;
}

const NavigationMenu = ({ clientFileOwnerClick, navigationClick }: Props) => {
  const dispatch = useAppDispatch();
  const insured = useAppSelector(selectCurrentInsured);
  const location = useLocation();
  const selected = (pathToMatch: string) => !!matchPath(pathToMatch, location.pathname);
  const isInInsured = useMemo(() => !!matchPath('/insureds/:insuredId/*', location.pathname), [location.pathname]);
  const offline = !useAppSelector(selectIsOnline);
  const isInClientFile = useMemo(() => !!matchPath('/insureds/:insuredId/clientFiles/:clientFileId/*', location.pathname), [location.pathname]);
  const currentClientFile = useAppSelector(selectCurrentClientFile);

  return (
    <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
      <Grid sx={{ pt: 0 }} direction="column" width="100%">
        <List>
          <NavigationLink
            isSelected={selected('/')}
            click={() => clientFileOwnerClick('/')}
            icon={<DashboardIcon />}
            text={'Dashboard'} />
          <NavigationLink
            isSelected={selected('/insureds')}
            click={() => clientFileOwnerClick('/insureds')}
            icon={<GroupsIcon />}
            text={'Insureds'} />
          {/* <NavigationLink
            isSelected={selected('/data-management')}
            click={() => clientFileOwnerClick('/data-management')}
            icon={
              //Due to this being a custom svg icon file, the way that the svg file gets imported as a react component means
              // that the color of the svg is hard-coded. To change the color of the icon, we will either need to copy/paste
              // the code of the svg here and use the color for the fill (meaning if the svg image source changes, the image
              // here won't update), or we will have to switch to using something like SVGR to import this as a react
              // component. The downside to that is it requires modifying the webpack config, which is something that
              // create-react-app locks up.
              <SvgIcon component={DataManagementIcon} />
            }
            text={'Data Management'}
            disabled={offline}
          /> */}
          {insured && isInInsured && <>
            <NavigationLink
              isSelected={selected('/insureds/:insuredId')}
              click={() => clientFileOwnerClick(`/insureds/${insured.id}`)}
              icon={<PersonIcon />}
              text={insured.name} />
            <NavigationLink
              isSelected={selected('/insureds/:insuredId/units')}
              click={() => clientFileOwnerClick(`/insureds/${insured.id}/units`)}
              icon={<CollectionsIcon />}
              text={'Units'} />
          </>}
          {insured && isInClientFile && <>
            <ReportModalButton insuredId={insured.id} />
            {currentClientFile && <StartApplicationMenu insured={insured} clientFile={currentClientFile} /> }
          </>}
        </List>
      </Grid>
      <Grid direction="column">
        <NavigationLink
          isSelected={false}
          click={() => {
            dispatch(openSupportModal());
          }}
          icon={
            <Tooltip title="Click or Press Ctrl + Shift + Alt + H to trigger">
              <SvgIcon component={BugReportIcon} />
            </Tooltip>
          }
          text={'Support'}
          disabled={offline}
        />
      </Grid>
    </Grid>
  );
};

export default NavigationMenu;