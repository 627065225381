import { ADMYear } from '../../types/api/adm/ADMYear';
import { ApplicationWizard, ApplicationWizardSummary } from '../../types/api/applicationWizard/applicationWizard';
import { AppTask } from '../../types/api/AppTask';
import { ApplicationStatusType } from '../../types/api/enums/application/applicationStatusType.enum';
import { AppTaskStatus } from '../../types/api/enums/application/appTaskStatus.enum';
import { getCommodityName, getCountyName, getStateName } from '../adm';

/**
 * Given an application wizard and a list of app task, figure out if resuming the applicaiton wizard should be allowed.
 * An application wizard can be resumed if its status is `in progress` or if its been submitted by some of the app tasks
 * have status of not `submitted`, `revise` or `return to submitter`.
 */
export const isResumeAppWizardEnabled = (appWizard: ApplicationWizard, appTasks: AppTask[]) => {
  return appWizard.applicationWizardStatusType === ApplicationStatusType.InProgress || appTasks.some(x => x.appTaskStatus === AppTaskStatus.NotSubmitted || x.appTaskStatus === AppTaskStatus.ReturnToSubmitter || x.appTaskStatus === AppTaskStatus.Revise);
};

/**
 * Get a unique, comma separated list of crops as a formatted string from an application wizard summary
 */
export const getCropList = (appWizard: ApplicationWizardSummary, admData: ADMYear[]) => {
  return appWizard.crops.map(crop => getCommodityName(admData, appWizard.year, crop));
};

/**
 * Get a unique, comma separated list of counties as a formatted string from an application wizard summary
 */
export const getCountiesList = (appWizard: ApplicationWizardSummary, admData: ADMYear[]) => {
  return appWizard.counties.map(county => getCountyName(admData, appWizard.year, county));
};

/**
 * Get a unique, comma separated list of states as a formatted string from an application wizard summary
 */
export const getStatesList = (appWizard: ApplicationWizardSummary, admData: ADMYear[]) => {
  return appWizard.counties.map(county => getStateName(admData, appWizard.year, county));
};