import { Card, CardMedia, Grid, Table, TableBody, Typography, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import './matrixAnalysisScenarioCard.styles.css';
import { SweetSpotMatrixAnalysisScenarioCardRow } from './matrixAnalysisScenarioCardRow.component';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectAllAnalysisMatrices } from '../../../app/matricesSlice';

type MatrixAnalysisScenarioCardProps = {
  scenario: RowCropScenario;
  isSelected?: boolean;
  onCardClick: () => void;
}

const MatrixAnalysisScenarioCard = ({ scenario, onCardClick, isSelected = false }: MatrixAnalysisScenarioCardProps) => {
  const analysisMatrixForScenario = useAppSelector(state => selectAllAnalysisMatrices(state).get(scenario.scenarioId) ?? null);
  // This should not be possible if everything is done correctly by this component's parent.
  // However, it needs to be handled for the sake of the compiler.
  if (analysisMatrixForScenario === null) {
    return null;
  }

  // Happy Path ---------------------------------------------------------------

  const summarySweetSpot = analysisMatrixForScenario.sweetSpots.find(x => x.isForMatrixSummary) ?? null;

  const sweetSpotsDrawnOnMatrix = analysisMatrixForScenario.sweetSpots.filter(x => !x.isForMatrixSummary).sort((a, b) => a.sweetSpotOrder - b.sweetSpotOrder);

  return (
    <Grid container direction="column" onClick={onCardClick} sx={{ cursor: 'pointer' }}>
      <Grid item xs="auto" sx={{ height: '16px', marginBottom: '8px' }}>
        <Tooltip title={scenario.name}>
          <Typography noWrap sx={{ color: theme => theme.palette.primary.main, maxWidth: '400px' }} variant="clickable1" >{scenario.name}</Typography>
        </Tooltip>
      </Grid>
      <Grid item xs="auto">
        <Card className={classNames('trendline-analysis-card', { 'trendline-analysis-card-box-shadow': isSelected })} sx={{  borderWidth: 0 }}>
          <CardMedia sx={{ height: '100%' }}>
            <Grid container height="100%">
              <Grid item xs="auto">
                <div style={{ width: '9px', height: '100%', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', backgroundColor: scenario.scenarioColor }}>
                </div>
              </Grid>
              <Grid item >
                <Table>
                  <TableBody>
                    <>
                      {summarySweetSpot !== null && <SweetSpotMatrixAnalysisScenarioCardRow title="Summary" sweetSpot={summarySweetSpot} />}
                      {sweetSpotsDrawnOnMatrix.map(sweetSpot => (
                        <SweetSpotMatrixAnalysisScenarioCardRow
                          title={sweetSpot.label}
                          sweetSpot={sweetSpot}
                          key={sweetSpot.sweetSpotId}
                        />
                      ))}
                    </>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardMedia>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MatrixAnalysisScenarioCard;