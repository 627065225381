import * as React from 'react';
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector, useKeyMapSelectorSingle } from '../../../hooks/reduxHooks';
import { QuoteId } from '../../../types/api/PrimaryKeys';
import '../scenarioPieceCard.style.css';
import { InputCostScenarioPiece } from '../../../types/api/InputCostScenarioPiece';
import { modifyInputCostScenarioPiece, removeInputCostScenarioPieceAndRecalculate } from '../../../app/inputCostScenarioPiecesSlice';
import InputCostTableWrapper from './inputCostTableComponents/inputCostTableWrapper.component';
import { useTheme } from '@mui/system';
import { getInputCostTableStyles } from './inputCostTableComponents/inputCostTable.style';
import { formatCurrency } from '../../../utils/formatNumbers';
import ScenarioPieceCard from '../scenarioPieceCard.component';
import { selectCalculationsByScenarioPiece } from '../../../app/calculationResultsSlice';
import { selectScenarioById } from '../../../app/scenariosSlice';

interface InputCostScenarioPieceCardProps {
  scenarioPiece: InputCostScenarioPiece;
  quoteId: QuoteId;
  isScenarioActive: boolean;
  isReadonly?: boolean;
}


const InputCostScenarioPieceCard = ({ scenarioPiece, quoteId, isScenarioActive, isReadonly = false }: InputCostScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const inputCostTableStyle = getInputCostTableStyles(theme);

  const calcResults = useKeyMapSelectorSingle(selectCalculationsByScenarioPiece, scenarioPiece.scenarioPieceId);
  const netScenarioTotal = -(calcResults?.fixedPerAcre ?? 0);

  const scenario = useAppSelector(state => selectScenarioById(state, scenarioPiece.scenarioId));

  if (!scenario) {
    return <></>;
  }

  const expandedCardBody = (
    <InputCostTableWrapper
      includeActionColumn={false}
      tableBodyContents={
        scenarioPiece.inputCosts.map((inputCostEntry, index) => {
          return (
            <TableRow key={index}>
              <TableCell sx={inputCostTableStyle.bodyCell}>
                <Tooltip title={inputCostEntry.description}>
                  <Typography noWrap>{inputCostEntry.description}</Typography>
                </Tooltip>
              </TableCell>
              <TableCell sx={inputCostTableStyle.bodyCell}>
                <Typography>{formatCurrency(inputCostEntry.perAcreCost)}</Typography>
              </TableCell>
            </TableRow>
          );
        })
      }
    />
  );

  const updateScenarioPiece = async (scenarioPiece: InputCostScenarioPiece) => {
    await dispatch(modifyInputCostScenarioPiece({ scenarioPiece: scenarioPiece }));
  };

  const removeScenarioPiece = async (scenarioPiece: InputCostScenarioPiece) => {
    await dispatch(removeInputCostScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
  };

  return (
    <ScenarioPieceCard
      scenarioPiece={scenarioPiece}
      quoteId={quoteId}
      isScenarioActive={isScenarioActive}
      updateScenarioPiece={updateScenarioPiece}
      removeScenarioPiece={removeScenarioPiece}
      label="Input Costs"
      expandedCardBody={expandedCardBody}
      netValue={netScenarioTotal}
      calcResults={calcResults}
      scenarioId={scenarioPiece.scenarioId}
      displayTooltip={false}
      isReadonly={isReadonly}
      scenarioColor={scenario.scenarioColor}
    />
  );
};

export default InputCostScenarioPieceCard;
