import { Grid } from '@mui/material';
import PercentChangeSliderInput from '../../percentChangeSliderInput.component';
import { ScenarioPieceMpActualInputCostsInput } from './scenarioPieceMpActualInputCostsInput.component';
import { ScenarioPieceMpExpectedInputCostsInput } from './scenarioPieceMpExpectedInputCostInput.component';
import { Nullable } from '../../../../types/util/Nullable';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import { FormInputProps } from '../../scenario/formInputProps';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import useLinkedScenarioFields from '../../../../hooks/useLinkedScenarioFields';
import { ScenarioId } from '../../../../types/api/PrimaryKeys';

interface ScenarioPieceMpInputCostsInputGroupProps extends FormInputProps {
  commodityCode: string;
  year: number;
  scenarioId: ScenarioId;
  scenarioPiece: Nullable<RowCropScenarioPiece>
}

export const ScenarioPieceMpInputCostsInputGroup = ({ scenarioId, scenarioPiece, commodityCode, year, disabled = false }: ScenarioPieceMpInputCostsInputGroupProps) => {
  const { getInputStyle } = useLinkedScenarioFields(scenarioId);
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={4}>
        <ScenarioPieceMpExpectedInputCostsInput
          scenarioPiece={scenarioPiece}
          expectedInputCosts={scenarioPiece?.rowCropScenarioPieceExtendedData?.expectedInputCosts ?? null}
          sx={getInputStyle(ExtendedDataFormInputParameterNames.ExpectedInputCosts)}
          disabled={disabled} />
      </Grid>
      <Grid item xs={4}>
        <ScenarioPieceMpActualInputCostsInput
          commodityCode={commodityCode}
          year={year}
          scenarioPiece={scenarioPiece}
          sx={getInputStyle(ExtendedDataFormInputParameterNames.ActualInputCosts)}
          actualInputCosts={scenarioPiece?.rowCropScenarioPieceExtendedData?.actualInputCosts ?? null} />
      </Grid>
      <Grid item xs={4}>
        <PercentChangeSliderInput
          estimatedValueFieldName={ExtendedDataFormInputParameterNames.ExpectedInputCosts}
          actualValueFieldName={ExtendedDataFormInputParameterNames.ActualInputCosts}
          sliderName={'inputCostsSlider'}
          initialEstimatedValue={scenarioPiece?.rowCropScenarioPieceExtendedData?.expectedInputCosts ?? null}
          initialActualValue={scenarioPiece?.rowCropScenarioPieceExtendedData?.actualInputCosts ?? null}
        />
      </Grid>
    </Grid>
  );
};
