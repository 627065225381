import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import {
  lowerCoverageShouldBeEqualToOrGreaterThanUnderlyingUpperCoverage,
  RowCropScenarioPieceUnderlyingValidationRule,
  RowCropScenarioPieceValidationRule
} from '../validationRules/rowCropScenarioPieceValidationRules';
import { composeValidationResults } from '../validationHandling';
import { CombinedValidationResult } from '../validateScenario';
import { Nullable } from '../../../types/util/Nullable';

export function validateStax(scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: Nullable<RowCropScenarioPiece>, allScenarioPieces: RowCropScenarioPiece[]): CombinedValidationResult<RowCropScenarioPiece> {
  if (underlyingScenarioPiece === null) {
    return validateStaxStandalonePlan(scenarioPiece, allScenarioPieces);
  }
  return validateStaxCompanionPlan(scenarioPiece, underlyingScenarioPiece, allScenarioPieces);
}

const standalonePlanValidationRules: RowCropScenarioPieceValidationRule[] = [];

const companionPlanValidationRules: (RowCropScenarioPieceValidationRule | RowCropScenarioPieceUnderlyingValidationRule)[] = [
  lowerCoverageShouldBeEqualToOrGreaterThanUnderlyingUpperCoverage,
];

const validateStaxStandalonePlan = (scenarioPiece: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[]) => {
  const validationResults = standalonePlanValidationRules.map(validationRule => validationRule(scenarioPiece, allScenarioPieces));
  return composeValidationResults(validationResults);
};

const validateStaxCompanionPlan = (scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: RowCropScenarioPiece, allScenarioPieces: RowCropScenarioPiece[]) => {
  const validationResults = companionPlanValidationRules.map(validationRule => validationRule(scenarioPiece, allScenarioPieces, underlyingScenarioPiece));
  return composeValidationResults(validationResults);
};