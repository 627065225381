import { Nullable } from '../types/util/Nullable';
import { isNullOrUndefined } from './nullHandling';

export function getMaskedTaxId(taxId: Nullable<string> | undefined) {
  if (isNullOrUndefined(taxId)) {
    return '';
  }

  const visibleCharsLength = 4;
  const formattedTaxId = taxId.replace('-', '');

  const length = formattedTaxId.length;
  if (length <= visibleCharsLength) {
    return taxId;
  }

  const maskedPart = '*'.repeat(length - visibleCharsLength);
  const lastFourDigits = formattedTaxId.slice(-visibleCharsLength);
  return `${maskedPart}${lastFourDigits}`;
}