import { Button, CircularProgress, Grid } from '@mui/material';
import { FormProvider, SubmitHandler, useWatch } from 'react-hook-form';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../types/util/Nullable';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { AdmUpperCoverageLevelInput } from '../../../components/formInputs/scenarioPiece/admUpperCoverageLevelInput.component';
import { AdmProtectionFactorInput } from '../../../components/formInputs/scenarioPiece/admProtectionFactor.component';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import { MpProjectedPriceFields } from '../../../components/formInputs/scenario/scenarioMpProjectedPriceInput.component';
import MpDiscountInput, { MpDiscountFields } from '../../../components/formInputs/scenarioPiece/mp/mpDiscountInput.component';
import MpIsLiveQuotingInput, { MpIsLiveQuotingFields } from '../../../components/formInputs/scenarioPiece/mp/mpIsLiveQuotingInput.component';
import MpIsHarvestPriceOptionInput, { MpIsHarvestPriceOptionFields } from '../../../components/formInputs/scenarioPiece/mp/mpIsHarvestPriceOptionInput.component';
import MpGetDiscountRateInput, { MpGetDiscountRateFields } from '../../../components/formInputs/scenarioPiece/mp/mpGetDiscountRatesInput.component';
import MpLockFieldsToggleInput from '../../../components/formInputs/scenarioPiece/mp/mpLockFieldsToggleInput.component';
import { CustomPremiumFields, CustomPremiumInput } from '../../../components/formInputs/customPremiumInput.component';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import {
  HighRiskType,
  InsurancePlanCode, roundToPlaces,
  ScenarioPieceType,
  UnitStructureCode, weightedAverage, YIELD_DESCRIPTORS
} from '@silveus/calculations';
import { Quote } from '../../../types/api/Quote';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { createScenarioPiece, removeScenarioPieceAndRecalculate, selectAllRowCropScenarioPiecesByScenarioMap, selectRowCropScenarioPiecePlanCodes } from '../../../app/scenarioPiecesSlice';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import { useAppDispatch, useAppSelector, useKeyMapSelector, useKeyMapSelectorSingle } from '../../../hooks/reduxHooks';
import useFormWrapper from '../../../hooks/useFormWrapper';
import useDrawerForm from '../../../hooks/useDrawerForm';
import { useFederalOfferData } from '../../../hooks/useFederalOfferData';
import { selectScenarioById, selectUnitYearsForScenario, updateScenario } from '../../../app/scenariosSlice';
import { mergeScenarioWithYieldAndPriceUpdates } from '../../../utils/scenarioUpdateUtils';
import { UpperCoverageLevelFields } from '../../../components/formInputs/scenarioPiece/upperCoverageLevelInput.component';
import { ProtectionFactorFields } from '../../../components/formInputs/scenarioPiece/protectionFactorInput.component';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { useEffect, useState } from 'react';
import { distinct } from '../../../utils/arrayUtils';
import { fetchScenarioPricesAndYields, selectOfferExpectedCountyYield, selectOfferExpectedInputCosts, selectOfferProjectedPrice } from '../../../app/admSlice';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { ExtendedDataFormInputParameterNames, FormInputParameterNames } from '../../../components/formInputs/constants/inputConstants';
import { useScenarioPieceForm } from '../useScenarioPieceForm';
import { liveQuoteMpRequest } from '../../../services/requestInterception/scenarioPieces/mpRequestInterceptor';
import { MpPremiumRequestDto } from '../../../types/api/liveQuote/mpPremiumRequestDTO';
import {
  getCountyCodeFromCountyId,
  getPracticeCodeFromPracticeId,
  getStateCodeFromCountyId,
  getTypeCodeFromTypeId
} from '../../../utils/adm';
import { MpUnitPremiumRequestDTO } from '../../../types/api/liveQuote/mpUnitPremiumRequestDTO';
import { selectUnitYearAphByUnitYearMap } from '../../../app/unitYearAphSlice';
import { ClientFile } from '../../../types/api/ClientFile';
import { MpYieldHistory } from '../../../types/api/liveQuote/mpYieldHistory';
import { selectApprovedYieldForScenario } from '../../../app/unitsSlice';
import { selectCalculationsByScenarioPiece } from '../../../app/calculationResultsSlice';
import useSubmitOnNextRender from '../../../hooks/useSubmitOnNextRender';
import { MpScenarioPiecePricesAndYields, MpScenarioPiecePricesAndYieldsFields } from './mpScenarioPiecePricesAndYields.component';

export interface MpScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  scenarioId: ScenarioId;
  clientFile: ClientFile;
  year: number;
  commodityCode: string;
  countyId: string;
  typeId: string;
  practiceId: string;
  disabled: boolean;
  highRiskTypeId: HighRiskType;
  quote: Quote;
  handleCancel: () => void;
}

export type MpScenarioPieceFormFields =
  ScenarioPieceFormFields & UpperCoverageLevelFields &
  ProtectionFactorFields & MpScenarioPiecePricesAndYieldsFields &
  MpIsLiveQuotingFields & MpIsHarvestPriceOptionFields &
  MpGetDiscountRateFields & MpProjectedPriceFields &
  MpDiscountFields & CustomPremiumFields;

export const MpScenarioPieceForm = ({ scenarioPiece, scenarioId, clientFile, year, commodityCode, countyId, typeId, practiceId, disabled, highRiskTypeId, registerHeader, handleCancel, quote, handleValidation, isCanceling = false }: MpScenarioPieceFormProps) => {
  const dispatch = useAppDispatch();
  const methods = useScenarioPieceForm<MpScenarioPieceFormFields>(scenarioPiece, {
    rowCropScenarioPieceExtendedData: {
      customPremium: scenarioPiece?.rowCropScenarioPieceExtendedData?.customPremium,
      unsubsidizedPremium: scenarioPiece?.rowCropScenarioPieceExtendedData?.unsubsidizedPremium,
    },
  });
  const formId = 'MpScenarioPieceForm';
  const [isLiveQuoteLoading, setIsLiveQuoteLoading] = useState(false);
  const [areUpperCoverageLevelsLoaded, setAreUpperCoverageLevelsLoaded] = useState(false);
  const [isSavingLiveQuote, setIsSavingLiveQuote] = useState(false);

  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  if (scenario === null) {
    throw new Error('Parent scenario does not exist in state for MP scenario piece');
  }
  const units = useAppSelector(state => selectUnitYearsForScenario(state, scenarioId));
  const unitYearAphMap = useAppSelector(selectUnitYearAphByUnitYearMap);
  const scenarioApprovedYield = useAppSelector(state => selectApprovedYieldForScenario(state, scenario.scenarioId, quote.countyId, quote.commodityCode)) ?? 0;
  const underlyingPiece = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioId).find(sp => [ScenarioPieceType.RP, ScenarioPieceType.RpHpe, ScenarioPieceType.YP].includes(sp.scenarioPieceType));
  const underlyingPieceCalcResults = useKeyMapSelectorSingle(selectCalculationsByScenarioPiece, underlyingPiece?.scenarioPieceId);

  const mpProjectedPrice = useAppSelector(selectOfferProjectedPrice);
  const mpExpectedCountyYield = useAppSelector(selectOfferExpectedCountyYield);
  const mpExpectedInputCosts = useAppSelector(selectOfferExpectedInputCosts);
  const doesAdmMpDataExist = mpProjectedPrice !== null && mpExpectedCountyYield !== null && mpExpectedInputCosts !== null;

  const planCodes = useAppSelector(state => selectRowCropScenarioPiecePlanCodes(state, scenarioId));
  const isMpHpo = useWatch({ name: 'isHarvestPriceOption', control: methods.control, defaultValue: scenarioPiece?.scenarioPieceType === ScenarioPieceType.MpHpo ? true : false });
  const scenarioPieceType = isMpHpo ? ScenarioPieceType.MpHpo : ScenarioPieceType.MP;
  const insurancePlanCode = isMpHpo ? InsurancePlanCode.MP_HPO : InsurancePlanCode.MP;

  const protectionFactor = useWatch({ name: FormInputParameterNames.ProtectionFactor, control: methods.control, defaultValue: undefined });
  const coverageLevel = useWatch({ name: FormInputParameterNames.UpperCoverageLevelName, control: methods.control, defaultValue: undefined });
  const getDiscountRate = useWatch({ name: ExtendedDataFormInputParameterNames.GetDiscountRate, control: methods.control, defaultValue: scenarioPiece?.rowCropScenarioPieceExtendedData?.getDiscountRate ?? false });
  const isLiveQuote = useWatch({ name: ExtendedDataFormInputParameterNames.IsLiveQuote, control: methods.control, defaultValue: scenarioPiece?.rowCropScenarioPieceExtendedData?.isLiveQuote ?? false });
  const areFieldsLocked = useWatch({ name: ExtendedDataFormInputParameterNames.AreFieldsLocked, control: methods.control, defaultValue: scenarioPiece?.rowCropScenarioPieceExtendedData?.areFieldsLocked ?? doesAdmMpDataExist });

  const protectionFactorFieldState = methods.getFieldState(FormInputParameterNames.ProtectionFactor, methods.formState).invalid;
  const upperCoverageFieldState = methods.getFieldState(FormInputParameterNames.UpperCoverageLevelName, methods.formState).invalid;
  const areLiveQuoteFieldsInvalid = protectionFactorFieldState || upperCoverageFieldState;
  //Check if the user has made changes to the coverage level or protection factor and that there are saved values for the scenario piece
  const isLoadedFromValidState = coverageLevel === undefined && protectionFactor === undefined && scenarioPiece !== null;
  const isFormInvalidForLiveQuote = areLiveQuoteFieldsInvalid && !isLoadedFromValidState;

  const isLiveQuoteStale = (methods.getFieldState(FormInputParameterNames.ProtectionFactor, methods.formState).isDirty
    || methods.getFieldState(FormInputParameterNames.UpperCoverageLevelName, methods.formState).isDirty
    || (methods.getValues(ExtendedDataFormInputParameterNames.GetDiscountRate)
      && (methods.getFieldState(ExtendedDataFormInputParameterNames.GetDiscountRate, methods.formState).isDirty
        || methods.getFieldState(ExtendedDataFormInputParameterNames.DiscountAmount, methods.formState).isDirty))
    || methods.getFieldState('isHarvestPriceOption', methods.formState).isDirty
    || methods.getFieldState(ExtendedDataFormInputParameterNames.ProjectedPrice, methods.formState).isDirty
    || methods.getFieldState('expectedCountyYield', methods.formState).isDirty
    || methods.getFieldState(ExtendedDataFormInputParameterNames.ExpectedInputCosts, methods.formState).isDirty
    || methods.getFieldState(ExtendedDataFormInputParameterNames.IsLiveQuote, methods.formState).isDirty
    || (scenarioPiece?.rowCropScenarioPieceExtendedData?.isLiveQuoteStale ?? false))
    && methods.getValues(ExtendedDataFormInputParameterNames.IsLiveQuote);

  useEffect(() => {
    if (!isLiveQuote) {
      return;
    }
    methods.trigger(FormInputParameterNames.ProtectionFactor);
    methods.trigger(FormInputParameterNames.UpperCoverageLevelName);
  }, [isMpHpo, protectionFactor, coverageLevel, isLiveQuote, areUpperCoverageLevelsLoaded]);

  useFederalOfferData(scenarioPiece, scenarioId, scenarioPieceType, year, countyId, typeId, practiceId, highRiskTypeId);

  useEffect(() => {
    const distinctPlanCodes = distinct([...planCodes, insurancePlanCode]);
    dispatch(fetchScenarioPricesAndYields({ year, countyId, typeId, practiceId, planCodes: distinctPlanCodes }));
  }, []);

  const onSubmit: SubmitHandler<MpScenarioPieceFormFields> = async data => {
    const planCode = getInsurancePlanCodeForScenarioPiece(scenarioPieceType);

    const extendedData = data.rowCropScenarioPieceExtendedData !== null
      ? { ...data.rowCropScenarioPieceExtendedData, isLiveQuoteStale: isLiveQuoteStale && !isSavingLiveQuote }
      : null;

    const newScenarioPiece: RowCropScenarioPiece = {
      isActive: data.isActive,
      isInvalid: isLiveQuoteStale && !isSavingLiveQuote,
      lowerCoverageLevel: 0,
      planCode: planCode,
      protectionFactor: data.protectionFactor,
      rowCropScenarioPieceExtendedData: extendedData,
      rowCropScenarioPieceId: scenarioPiece?.rowCropScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: scenarioPieceType,
      unitStructure: underlyingPiece?.unitStructure ?? UnitStructureCode.EU,
      upperCoverageLevel: data.upperCoverageLevel,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    await dispatch(updateScenario({
      updatedScenario: mergeScenarioWithYieldAndPriceUpdates(scenario, data),
    }));

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(createScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId, updatedScenarioPiece: newScenarioPiece }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));

    setIsSavingLiveQuote(false);
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;
  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);
  const setSubmitOnNextRender = useSubmitOnNextRender(handleSubmit);

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  const refreshLiveQuote = async () => {
    try {
      setIsLiveQuoteLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const shouldGetDiscountRate = (methods.getValues(ExtendedDataFormInputParameterNames.GetDiscountRate) ?? false) &&
        underlyingPiece !== undefined &&
        !quote.quickQuote;

      const liveQuotePremiumParams: MpPremiumRequestDto = {
        commodityCode: Number(commodityCode),
        countyCode: Number(getCountyCodeFromCountyId(countyId)),
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        coverageLevel: (methods.getValues().upperCoverageLevel ?? scenarioPiece?.upperCoverageLevel ?? 0) / 100,
        cropYear: year,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        isHarvestPriceOption: methods.getValues().isHarvestPriceOption ?? false,
        practiceCode: Number(getPracticeCodeFromPracticeId(practiceId)),
        protectionFactor: (methods.getValues(FormInputParameterNames.ProtectionFactor) ?? 0) / 100,
        shouldGetDiscountRate: shouldGetDiscountRate,
        stateId: Number(getStateCodeFromCountyId(countyId)),
        typeCode: Number(getTypeCodeFromTypeId(typeId)),
        underlyingApprovedYield: scenarioApprovedYield,
        underlyingCoverageLevel: (underlyingPiece?.upperCoverageLevel ?? 0) / 100,
        underlyingPlanCode: underlyingPiece?.planCode ?? '',
        underlyingProducerPremiumPerAcre: underlyingPieceCalcResults?.producerPremiumPerAcre ?? 0,
        unitYieldHistories: [],
      };

      const invalidDiscountYieldTypes = [YIELD_DESCRIPTORS.I, YIELD_DESCRIPTORS.T, YIELD_DESCRIPTORS.C];

      if (shouldGetDiscountRate) {
        for (const unit of units) {
          //Grab the latest 10 years, filter out any invalid years, add to the histories for liveQuoting
          const unitHistory = unitYearAphMap.get(unit.unitYearId) ?? [];
          const last10YearsOfUnitHistory = unitHistory
            .slice(Math.max(0, unitHistory.length - 10))
            .filter(uhy => uhy.acres !== null &&
              uhy.acres > 0 &&
              uhy.year <= (clientFile.year - 2) &&
              invalidDiscountYieldTypes.every(yt => yt !== uhy.yieldType));

          const mpYieldHistory: MpYieldHistory[] = last10YearsOfUnitHistory.map(unitAphYear => {
            return {
              year: unitAphYear.year,
              yield: unitAphYear.aphYield ?? unitAphYear.production ?? 0 / (unitAphYear.acres ?? 1),
            };
          });

          const unitYieldHistory: MpUnitPremiumRequestDTO = {
            unitYearId: unit.unitYearId,
            yieldHistory: mpYieldHistory,
          };
          liveQuotePremiumParams.unitYieldHistories.push(unitYieldHistory);
        }
      }

      const values = methods.getValues();

      const mpLiveQuoteData = await liveQuoteMpRequest(liveQuotePremiumParams);
      //This clears any validation messages
      const shouldValidate = true;

      if (shouldGetDiscountRate) {
        const discountAmountPerAcre = weightedAverage(
          mpLiveQuoteData.premiumCreditByUnits,
          ur => ur.premiumCreditPerAcre,
          unitPremiumCredit => {
            const unit = units.find(u => u.unitYearId === unitPremiumCredit.unitYearId);
            if (unit === undefined) return 0;
            return unit.acres * unit.sharePercent;
          });
        const newValue = roundToPlaces(discountAmountPerAcre, 2);
        methods.setValue(ExtendedDataFormInputParameterNames.DiscountAmount, newValue, { shouldValidate: shouldValidate, shouldDirty: values.rowCropScenarioPieceExtendedData?.discountAmount !== roundToPlaces(discountAmountPerAcre, 2) });
      }

      methods.setValue(ExtendedDataFormInputParameterNames.ProjectedPrice, mpLiveQuoteData.projectedCropPrice, { shouldValidate: shouldValidate, shouldDirty: values.rowCropScenarioPieceExtendedData?.projectedPrice !== mpLiveQuoteData.projectedCropPrice });
      methods.setValue('harvestPrice', mpLiveQuoteData.projectedCropPrice, { shouldValidate: shouldValidate, shouldDirty: values.harvestPrice !== mpLiveQuoteData.projectedCropPrice });
      methods.setValue('expectedCountyYield', mpLiveQuoteData.expectedCountyYield, { shouldValidate: shouldValidate, shouldDirty: values.expectedCountyYield !== mpLiveQuoteData.expectedCountyYield });
      methods.setValue('actualCountyYield', mpLiveQuoteData.expectedCountyYield, { shouldValidate: shouldValidate, shouldDirty: values.actualCountyYield !== mpLiveQuoteData.expectedCountyYield });
      methods.setValue(ExtendedDataFormInputParameterNames.ExpectedInputCosts, mpLiveQuoteData.expectedCostPerAcre, { shouldValidate: shouldValidate, shouldDirty: values.rowCropScenarioPieceExtendedData?.expectedInputCosts !== mpLiveQuoteData.expectedCostPerAcre });
      methods.setValue(ExtendedDataFormInputParameterNames.ActualInputCosts, mpLiveQuoteData.expectedCostPerAcre, { shouldValidate: shouldValidate, shouldDirty: values.rowCropScenarioPieceExtendedData?.actualInputCosts !== mpLiveQuoteData.expectedCostPerAcre });
      methods.setValue(ExtendedDataFormInputParameterNames.CustomPremium, mpLiveQuoteData.producerPremiumPerAcre, { shouldValidate: shouldValidate, shouldDirty: values.rowCropScenarioPieceExtendedData?.customPremium !== mpLiveQuoteData.producerPremiumPerAcre });
      methods.setValue(ExtendedDataFormInputParameterNames.UnsubsidizedPremium, mpLiveQuoteData.totalPremiumPerAcre, { shouldValidate: shouldValidate, shouldDirty: values.rowCropScenarioPieceExtendedData?.unsubsidizedPremium !== mpLiveQuoteData.totalPremiumPerAcre });
    } finally {
      setIsLiveQuoteLoading(false);
    }
  };

  const onLiveQuoteRefresh = async () => {
    await refreshLiveQuote();
    setIsSavingLiveQuote(true);
    setSubmitOnNextRender(true);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid container spacing={2}>

            <Grid item xs={6}>
              <AdmProtectionFactorInput protectionFactor={scenarioPiece?.protectionFactor ?? null} disabled={disabled} defaultValue={120} />
            </Grid>
            <Grid item xs={6}>
              <AdmUpperCoverageLevelInput upperCoverageLevel={scenarioPiece?.upperCoverageLevel ?? null} disabled={disabled} setAreUpperCoverageLevelsLoaded={isLoaded => setAreUpperCoverageLevelsLoaded(isLoaded)} />
            </Grid>

            <Grid item xs={6}>
              <MpIsLiveQuotingInput isLiveQuote={scenarioPiece?.rowCropScenarioPieceExtendedData?.isLiveQuote ?? false} doesAdmMpDataExist={doesAdmMpDataExist} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <MpIsHarvestPriceOptionInput isHarvestPriceOption={isMpHpo} disabled={disabled} />
            </Grid>

            {isLiveQuote &&
              <>
                <Grid item xs={6}>
                  <MpGetDiscountRateInput getDiscountRate={scenarioPiece?.rowCropScenarioPieceExtendedData?.getDiscountRate ?? false} disabled={disabled} />
                </Grid>
                <Grid item container xs={6} justifyContent="center">
                  <Grid item>
                    <Button id="btn-refresh-live-quote" color={isLiveQuoteStale ? 'secondary' : 'primary'} size="small" variant="contained" onClick={() => onLiveQuoteRefresh()} disabled={isFormInvalidForLiveQuote || isLiveQuoteLoading}>{isLiveQuoteLoading ? <CircularProgress color="inherit" size={20} /> : 'REFRESH LIVE QUOTE'}</Button>
                  </Grid>
                </Grid>
              </>
            }

            {!isLiveQuote && doesAdmMpDataExist &&
              <Grid container item xs={12} ml={'8px'}>
                <Grid item xs={'auto'}>
                  <MpLockFieldsToggleInput scenarioPiece={scenarioPiece} defaultValue={scenarioPiece?.rowCropScenarioPieceExtendedData?.areFieldsLocked !== undefined ? scenarioPiece.rowCropScenarioPieceExtendedData.areFieldsLocked : doesAdmMpDataExist} disabled={disabled} />
                </Grid>
              </Grid>
            }

            <MpScenarioPiecePricesAndYields quote={quote} scenario={scenario} scenarioPiece={scenarioPiece} scenarioPieceType={scenarioPieceType} year={year} isLiveQuote={isLiveQuote} areFieldsLocked={areFieldsLocked} commodityCode={commodityCode} />

            <Grid item xs={6}>
              <MpDiscountInput discount={scenarioPiece?.rowCropScenarioPieceExtendedData?.discountAmount ?? null} disabled={isLiveQuote && getDiscountRate} />
            </Grid>
            <Grid item xs={6}>
              {(!areFieldsLocked && !isLiveQuote) && <CustomPremiumInput customPremium={scenarioPiece?.rowCropScenarioPieceExtendedData?.customPremium ?? null} disabled={disabled} />}
            </Grid>

            <Grid item xs={12}>
              <IsActiveInput isActive={scenarioPiece?.isActive ?? null} />
            </Grid>

          </Grid>
        </form>
      </FormProvider>
      <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
    </>
  );
};
