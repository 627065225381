import EntityDiff from '../entityDiffing/entityDiff';
import { getFriendlyScenarioPieceName } from '../../../utils/scenarioPieceUtils';
import DataLocationType from '../constants/dataLocationType';
import { NamedReconciliationStack } from '../../../types/app/ReconciliationTrackedEntity';

type StackType = NamedReconciliationStack<'harvestRevenueScenarioPieces'>;

interface Props {
  harvestRevenueScenarioPiece: StackType;
  reconciledHarvestRevenueScenarioPiece: StackType
  onReconciliationTypeChange: (entity: StackType) => void;
  dataLocation: DataLocationType;
}

export const HarvestRevenueScenarioPieceDiff = ({ harvestRevenueScenarioPiece, reconciledHarvestRevenueScenarioPiece, onReconciliationTypeChange, dataLocation }: Props) => {
  const scenarioPiece = harvestRevenueScenarioPiece.entity;
  const detailComponents = [
    getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType),
  ];

  return (
    <EntityDiff
      title="Harvest Revenue Scenario Pieces"
      subEntitySelector={() => []}
      trackedEntity={reconciledHarvestRevenueScenarioPiece}
      subsetEntity={harvestRevenueScenarioPiece}
      detailComponents={detailComponents}
      onReconciliationTypeChange={onReconciliationTypeChange}
      preventCollapse
      dataLocation={dataLocation}
      preventSelection
      hideHeader
    />
  );
};