import { Insured } from '../../types/api/insureds/Insured';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { fetchInsureds, selectAllInsureds } from '../../app/insuredsSlice';
import React, { useEffect, useState } from 'react';
import { InsuredId } from '../../types/api/PrimaryKeys';
import SummaryButton from './summaryButton.component';
import './insuredsGrid.css';
import InsuredFilter from './insuredFilter.component';
import InsuredSelectionGrid from './insuredSelectionGrid.component';
import { selectIsLightMode } from '../../app/userSettingsSlice';

interface InsuredManagementProps {
  selectedInsureds: InsuredId[];
  updateSelectedInsureds: (insuredIds: InsuredId[]) => void;
  navigateToSummaryPage: () => void;
}

const InsuredManagement = ({ selectedInsureds, updateSelectedInsureds, navigateToSummaryPage }: InsuredManagementProps) => {
  const isLightMode = useAppSelector(selectIsLightMode);
  const insureds = useAppSelector(selectAllInsureds);
  const [insuredsToShow, setInsuredsToShow] = useState<Insured[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    //When the user is on the page for an insured and refreshes, then comes here
    // only a single insured will be loaded, so we want to pull all of them
    if (insureds.length < 2)
      dispatch(fetchInsureds());
  }, []);

  useEffect(() => {
    setInsuredsToShow(insureds);
  }, [insureds]);

  const loading = insureds.length === 0;

  return (
    <Grid container p={6} rowSpacing={2}>
      <Grid item xs={3}>
        <InsuredFilter loading={loading} insureds={insureds} filterInsureds={setInsuredsToShow} />
      </Grid>
      <Grid item xs={12} sx={{ height: 'calc(100vh - 265px)' }} className={isLightMode ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}>
        <InsuredSelectionGrid insureds={insureds} insuredsToShow={insuredsToShow} insuredsToDownload={selectedInsureds} updateSelectedInsureds={updateSelectedInsureds} />
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Grid item xs />
        <Grid item xs="auto">
          <SummaryButton onClick={navigateToSummaryPage} />
        </Grid>
      </Grid>

    </Grid>
  );
};

export default InsuredManagement;