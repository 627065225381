import { quoterDb } from '../../db';
import { ClientFile } from '../../types/api/ClientFile';
import { AgentTeamId, ClientFileId, InsuredId } from '../../types/api/PrimaryKeys';
import {
  createClientFile,
  deleteClientFile,
  getClientFile,
  getClientFilesForInsured,
  getClientFilesForAgentTeams as getClientFilesForAgentTeam,
  updateClientFile
} from '../clientFiles.service';
import {
  addSingle,
  getMultiple,
  getSingle, update
} from '../offlineDataAccess.service';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { deleteQuotesLocally } from './quoteRequestInterceptor';
import { applySoftDelete } from '../../utils/dexieQueryHelpers/softDelete';
import { isOnlineFromStore } from '../../utils/isOnlineFromStore';
import { networkOnly, nonGetCacheOnly, nonGetNetworkOnly } from '../../utils/cachingStrategies';

const table = quoterDb.clientFiles;

export const getClientFileRequest = async (clientFileId: ClientFileId): Promise<ClientFile> => {
  const request = () => getClientFile(clientFileId);
  return await getSingle(table, { clientFileId: clientFileId }, request);
};

export const getClientFilesForInsuredRequest = async (insuredId: InsuredId): Promise<ClientFile[]> => {
  const request = () => getClientFilesForInsured(insuredId);
  return await getMultiple(table, { insuredId: insuredId }, request);
};

export const getClientFilesForAgentTeamRequest = async (agentTeamId: AgentTeamId): Promise<ClientFile[]> => {
  const request = () => getClientFilesForAgentTeam(agentTeamId);
  // TODO: Offline support
  return networkOnly(request);
};

export const createClientFileRequest = async (clientFile: ClientFile): Promise<CreatedItemResult<ClientFileId>> => {
  const request = () => createClientFile(clientFile);
  return await addSingle(table, clientFile, request);
};

export const updateClientFileRequest = async (clientFileId: ClientFileId, clientFile: ClientFile): Promise<void> => {
  const request = () => updateClientFile(clientFileId, clientFile);
  return await update(table, clientFile, request);
};

export const deleteClientFileRequest = async (clientFileId: ClientFileId): Promise<void> => {
  const request = () => deleteClientFile(clientFileId);

  const transactionTables = [
    quoterDb.rowCropScenarios,
    quoterDb.rowCropScenarioPieces,
    quoterDb.matrices,
    quoterDb.unitGroups,
    quoterDb.scenarioOptions,
    quoterDb.historicalAnalyses,
    quoterDb.trendlineAnalyses,
    quoterDb.premiumBreakdowns,
    quoterDb.scenarioQuickUnits,
    quoterDb.scenarioUnitYearAph,
    quoterDb.scenarioOptionUnitYears,
    quoterDb.quotes,
    quoterDb.clientFiles,
  ];

  const updateTransaction = () => quoterDb.transaction('rw', transactionTables, async () => {
    await deleteClientFilesLocally([clientFileId]);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;
  return await strategy(request, updateTransaction);
};

export const deleteClientFilesLocally = async (clientFileIds: ClientFileId[]): Promise<void> => {
  const clientFiles = safeWhere(quoterDb.clientFiles, 'clientFileId').anyOf(clientFileIds);
  const quotes = safeWhere(quoterDb.quotes, 'clientFileId').anyOf(clientFileIds);
  const quoteIds = (await quotes.toArray()).map(q => q.quoteId);

  await deleteQuotesLocally(quoteIds);

  await applySoftDelete(clientFiles);
};