import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import ScoScenarioPieceForm from '../../../../pages/scenarioPiece/sco/scoScenarioPieceForm';
import { createScoData } from '../../../../services/calculations/sco/scoDataTransformations';
import { validateSco } from '../../../../utils/validation/scenarioPieces/scoValidation';
import { getEcoScoQuoteSummaryData } from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getEcoScoQuoteSummaryData';
import {
  getFullyQualifiedScenarioPieceNameOnlyCoverageLevel
} from '../../defaultDefinitions/scenarioPieceNameDefaults';
import {
  ProtectionFactorScenarioPieceChip
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

export const scoDefinition: ScenarioPieceDefinition = {
  validationFunction: validateSco,
  d365ProductType: ProductType.MPCI,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    return createScoData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <ScoScenarioPieceForm {...props} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>}/>;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getEcoScoQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceNameOnlyCoverageLevel,
  getScenarioPieceCardChips: [ProtectionFactorScenarioPieceChip],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#006400',
};