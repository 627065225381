import { Paper, Typography } from '@mui/material';
import EntityType from '../constants/entityType';

interface DiffDetailsProps {
  detail: string;
  detailType: EntityType;
}

const DiffDetails = ({ detail, detailType }: DiffDetailsProps) => {
  let backgroundColor = '';
  if (detailType === 'added')
    backgroundColor = 'rgba(76, 175, 80, 0.08)';
  else if (detailType === 'deleted' || detailType === 'conflicts')
    backgroundColor = 'rgba(244, 67, 54, 0.08)';
  else
    backgroundColor = 'rgba(38, 94, 149, 0.04)';

  return (
    <Paper sx={{ backgroundColor: backgroundColor, p: 2 }} elevation={0}>
      <Typography variant="body2">{detail}</Typography>
    </Paper>
  );
};

export default DiffDetails;