import { quoterDb } from '../../db';
import {
  batchCreateUnitYear,
  batchDeleteUnitYears,
  batchUpdateUnitYears,
  createUnitYear,
  deleteUnit,
  deleteUnitYear,
  getUnitYear,
  getUnitYearsForInsured, getUnitYearsForQuotes, getUnitYearsForScenarios,
  updateUnitYear
} from '../units.service';
import UnitYear from '../../types/api/UnitYear';
import { InsuredId, QuoteId, ScenarioId, UnitId, UnitYearId } from '../../types/api/PrimaryKeys';
import {
  getMultiple,
  getSingle,
  update, getDefaultCachingStrategy
} from '../offlineDataAccess.service';
import { CreatedInheritanceItemResult } from '../../types/api/results/CreatedInheritanceItemResult';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { nonGetCacheOnly, nonGetNetworkOnly } from '../../utils/cachingStrategies';
import { isOnlineFromStore } from '../../utils/isOnlineFromStore';
import { toPrimaryKey } from '../../utils/primaryKeyHelpers';
import { applySoftDelete } from '../../utils/dexieQueryHelpers/softDelete';
import { deleteAphLocally } from './unitYearAphRequestInterceptor';
import { distinctBy } from '../../utils/arrayUtils';
import { PromiseExtended } from 'dexie';
import { isInsuredOwnedClientFile } from '../../utils/clientFileUtils';

const table = quoterDb.unitYears;

export const getUnitYearsForInsuredRequest = async (insuredId: InsuredId): Promise<UnitYear[]> => {
  const request = () => getUnitYearsForInsured(insuredId);
  return await getMultiple(table, { insuredId: insuredId }, request);
};

export const getUnitYearRequest = async (unitYearId: UnitYearId): Promise<UnitYear> => {
  const request = () => getUnitYear(unitYearId);
  return await getSingle(table, { unitYearId: unitYearId }, request);
};

export const createUnitYearRequest = async (unitYear: UnitYear): Promise<CreatedInheritanceItemResult<UnitId, UnitYearId>> => {
  const request = () => createUnitYear(unitYear);

  const updateTransaction = () => {
    quoterDb.transaction('rw', quoterDb.unitYears, () => {
      quoterDb.unitYears.add(unitYear);
    });

    const createdInheritanceItemResult: CreatedInheritanceItemResult<UnitId, UnitYearId> = {
      superClassGuid: toPrimaryKey<UnitId>(unitYear.unitId),
      subClassGuid: toPrimaryKey<UnitYearId>(unitYear.unitYearId),
    };

    return Promise.resolve(createdInheritanceItemResult);
  };

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const batchCreateUnitYearRequest = (unitYears: UnitYear[]): Promise<void> => {
  const request = () => batchCreateUnitYear(unitYears);

  const updateTransaction = () => {
    return quoterDb.transaction('rw', quoterDb.unitYears, async () => {
      await quoterDb.unitYears.bulkAdd(unitYears);
    });
  };

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;

  return strategy(request, updateTransaction);
};

export const batchUpdateUnitYearsRequest = async (unitYears: UnitYear[]): Promise<void> => {
  const request = () => batchUpdateUnitYears(unitYears);
  return await update(table, unitYears, request);
};

export const updateUnitYearRequest = async (unitYear: UnitYear): Promise<void> => {
  const request = () => updateUnitYear(unitYear);
  return await update(table, unitYear, request);
};

export const deleteUnitRequest = async (unitId: UnitId): Promise<void> => {
  const request = () => deleteUnit(unitId);

  const transactionTables = [
    quoterDb.unitYears,
    quoterDb.unitYearAph,
  ];

  const updateTransaction = () => quoterDb.transaction('rw', transactionTables, async () => {
    const unitYears = safeWhere(quoterDb.unitYears, { unitId: unitId });
    const unitYearIds = (await unitYears.toArray()).map(uy => uy.unitYearId);
    await deleteUnitYearsLocally(unitYearIds);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;
  return strategy(request, updateTransaction);
};

export const batchDeleteUnitYearsRequest = async (unitYears: UnitYear[]): Promise<void> => {
  const unitYearIds = unitYears.map(x => x.unitYearId);

  const request = () => batchDeleteUnitYears(unitYearIds);

  const transactionTables = [
    quoterDb.unitYears,
    quoterDb.unitYearAph,
  ];

  const updateTransaction = () => quoterDb.transaction('rw', transactionTables, async () => {
    await deleteUnitYearsLocally(unitYearIds);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;
  return strategy(request, updateTransaction);
};

export const deleteUnitYearRequest = async (unitYearId: UnitYearId): Promise<void> => {
  const request = () => deleteUnitYear(unitYearId);

  const transactionTables = [
    quoterDb.unitYears,
    quoterDb.unitYearAph,
  ];

  const updateTransaction = () => quoterDb.transaction('rw', transactionTables, async () => {
    await deleteUnitYearsLocally([unitYearId]);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;
  return strategy(request, updateTransaction);
};

export const getUnitYearsForQuotesRequest = async (quoteIds: QuoteId[]): Promise<UnitYear[]> => {
  const request = () => getUnitYearsForQuotes(quoteIds);

  const transactionTables = [quoterDb.quotes, quoterDb.clientFiles, quoterDb.unitYears];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const quotes = await safeWhere(quoterDb.quotes, 'quoteId').anyOf(quoteIds).toArray();
    const clientFileIds = distinctBy(quotes.map(quote => quote.clientFileId), x => x);
    const clientFiles = (await safeWhere(quoterDb.clientFiles, 'clientFileId').anyOf(clientFileIds).toArray()).filter(isInsuredOwnedClientFile);
    const unitYearPromises: PromiseExtended<UnitYear[]>[] = [];
    for (const quote of quotes) {
      const clientFile = clientFiles.find(cf => cf.clientFileId === quote.clientFileId);
      if (clientFile === undefined) continue;

      const unitYears = safeWhere(quoterDb.unitYears, { insuredId: clientFile.insuredId, year: clientFile.year, commodityCode: quote.commodityCode, countyId: quote.countyId }).toArray();
      unitYearPromises.push(unitYears);
    }
    return (await Promise.all(unitYearPromises)).flat();
  });
  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};


export const getUnitYearsForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<UnitYear[]> => {
  const request = () => getUnitYearsForScenarios(scenarioIds);

  const transactionTables = [quoterDb.rowCropScenarios, quoterDb.quotes, quoterDb.clientFiles, quoterDb.unitYears];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const scenarios = await safeWhere(quoterDb.rowCropScenarios, 'scenarioId').anyOf(scenarioIds).toArray();
    const quoteIds = distinctBy(scenarios.map(scenario => scenario.quoteId), x => x);
    const quotes = await safeWhere(quoterDb.quotes, 'quoteId').anyOf(quoteIds).toArray();
    const clientFileIds = distinctBy(quotes.map(quote => quote.clientFileId), x => x);
    const clientFiles = (await safeWhere(quoterDb.clientFiles, 'clientFileId').anyOf(clientFileIds).toArray()).filter(isInsuredOwnedClientFile);
    const unitYearPromises: PromiseExtended<UnitYear[]>[] = [];
    for (const quote of quotes) {
      const clientFile = clientFiles.find(cf => cf.clientFileId === quote.clientFileId);
      if (clientFile === undefined) continue;

      const unitYears = safeWhere(quoterDb.unitYears, { insuredId: clientFile.insuredId, year: clientFile.year, commodityCode: quote.commodityCode, countyId: quote.countyId }).toArray();
      unitYearPromises.push(unitYears);
    }
    return (await Promise.all(unitYearPromises)).flat();
  });
  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const deleteUnitYearsLocally = async (unitYearIds: UnitYearId[]): Promise<void> => {
  const unitYears = safeWhere(quoterDb.unitYears, 'unitYearId').anyOf(unitYearIds);
  const unitYearAph = safeWhere(quoterDb.unitYearAph, 'unitYearId').anyOf(unitYearIds);
  const unitYearAphIds = (await unitYearAph.toArray()).map(uya => uya.unitYearAphId);

  await deleteAphLocally(unitYearAphIds);

  await applySoftDelete(unitYears);
};
