import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { SigReportDocumentType } from '../../../constants/sigReportDocumentType';
import { onNumericInputChange } from '../../numberTextField/numberTextField.component';

export type GeneratedDocumentTypeFields = {
  generatedDocumentType: SigReportDocumentType;
}

export const DocumentTypeInput = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name="generatedDocumentType"
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
      }}
      defaultValue={SigReportDocumentType.Pdf}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl fullWidth error={!!error}>
          <RadioGroup
            {...field}
            row
            value={value}
            onChange={e => onNumericInputChange(e, field)}
          >
            <FormControlLabel value={SigReportDocumentType.Excel} control={<Radio />} label="Excel Spreadsheet (XLSX)" />
            <FormControlLabel value={SigReportDocumentType.Pdf} control={<Radio />} label="PDF" />
          </RadioGroup>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};