import { ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface InfoProps {
  tooltipContent: ReactNode;
  iconType?: InfoType;
}

export enum InfoType {
  Info = 1,
  Warning = 2,
}

export const Info = ({ tooltipContent, iconType = InfoType.Info }: InfoProps) => {
  return (
    <>
      {iconType === InfoType.Warning &&
        <>
          <Tooltip title={tooltipContent} arrow placement="top">
            <WarningAmberIcon color="warning" cursor="pointer" />
          </Tooltip>
        </>
      }
      {
        iconType === InfoType.Info &&
        <>
          <Tooltip title={tooltipContent} arrow placement="top">
            <InfoOutlinedIcon color="action" cursor="pointer" />
          </Tooltip>
        </>
      }
    </>
  );
};