import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { selectAllStates } from '../../../app/admSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { Nullable } from '../../../types/util/Nullable';

interface AddressStateInputProps {
  value?: Nullable<string>;
  disabled?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  inputName?: string;
}

export type AddressStateFields = {
  state: Nullable<string>;  // we are using the state abbreviation here
};

const AddressStateInput = ({ value, inputName, disabled = false, autoFocus = false, required = true }: AddressStateInputProps) => {
  const { control } = useFormContext();
  const states = useAppSelector(selectAllStates);

  return (
    <Controller
      name={inputName ?? 'address.state'}
      control={control}
      rules={{ required: required ? VALIDATION_RULES.required() : false }}
      defaultValue={value}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="State"
          fullWidth
          select
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {states.map(s => <MenuItem key={s.abbrev} value={s.abbrev ?? ''}>{s.name}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default AddressStateInput;
