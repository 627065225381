import { IconButton, TableCell, TableRow, styled } from '@mui/material';
import { AppTaskCoverage } from '../../../../types/api/appTaskCoverage';
import CoverageTypeInput from '../../../../components/formInputs/applications/coverageTypeInput';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectAdmData, selectAllTypes } from '../../../../app/admSlice';
import { getCommodityName, getCountyName, getPracticeAbbrevFromPracticeCode } from '../../../../utils/adm';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CurrencyText from '../../../../components/currencyText/currencyText.component';
import { useState } from 'react';
import { UnitStructureAttributes } from '@silveus/calculations';
import { ApplicationWizard } from '../../../../types/api/applicationWizard/applicationWizard';
import IsNewProducerInput from '../../../../components/formInputs/applications/isNewProducerInput';
import CoverageDesignatedCountyInput from '../../../../components/formInputs/applications/coverageDesignatedCountyInput';
import { isNotNullOrUndefined } from '../../../../utils/nullHandling';
import { AppTableCellForInput } from './applicationDecisionGrid';
import { AppTask } from '../../../../types/api/AppTask';
import EstimatedGrossPremiumInput from '../../../../components/formInputs/applications/estimatedGrossPremiumInput';

type AppTaskCoverageRowProps = {
  coverage: AppTaskCoverage;
  appTaskIndex: number;
  coverageIndex: number;
  application: ApplicationWizard;
  canEditAppTask: boolean;
  appTask: AppTask;
  cropYear: number;
}

export const getCoverageLevelString = (coverage: AppTaskCoverage) => {
  let cl = '';
  if (coverage.upperCoverageLevel !== null) {
    cl += coverage.upperCoverageLevel;
  }
  if (coverage.lowerCoverageLevel !== null) {
    cl += coverage.upperCoverageLevel !== null ? `/${coverage.lowerCoverageLevel}` : coverage.lowerCoverageLevel;
  }
  if (coverage.protectionFactor !== null) {
    cl += ` (${coverage.protectionFactor})`;
  }
  return cl;
};

export const AppTableCell = styled(TableCell)({
  fontSize: '12px',
  padding: '0 8px',
  borderBottom: 'var(--ag-row-border-style) var(--ag-row-border-color) 1px',
});


export const CoverageRow = styled(TableRow)({
  '&:last-child > td': {
    borderBottom: 'none',
  },
  '&:hover': {
    backgroundColor: 'var(--ag-row-hover-color)',
  },
});



const AppTaskCoverageRow = ({ coverage, appTaskIndex, coverageIndex, application, canEditAppTask, appTask, cropYear }: AppTaskCoverageRowProps) => {
  const [open, setOpen] = useState(true);
  const admData = useAppSelector(selectAdmData);
  const typeCodes = useAppSelector(selectAllTypes);

  const unitStructure = isNotNullOrUndefined(coverage.unitStructure) ? UnitStructureAttributes[coverage.unitStructure].name : '';
  const typeCode = typeCodes.find(x => x.typeCode === coverage.typeCode && x.commodityCode === coverage.cropCode)?.abbrev ?? '';
  const practice = coverage.practiceCode !== null ? getPracticeAbbrevFromPracticeCode(admData, cropYear, coverage.practiceCode) : '';

  return (
    <>
      <CoverageRow>
        <AppTableCell width="30px">
          {isNotNullOrUndefined(coverage.childCoverages) && coverage.childCoverages.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </AppTableCell>
        <AppTableCell sx= {{ paddingLeft: 1 }}>{isNotNullOrUndefined(coverage.countyCode) ? getCountyName(admData, appTask.cropYear, coverage.countyCode) : ''}</AppTableCell>
        <AppTableCellForInput>{<CoverageTypeInput value={coverage.coverageType} index={appTaskIndex} coverageIndex={coverageIndex} application={application} canEditAppTask={canEditAppTask} />}</AppTableCellForInput>
        <AppTableCellForInput><CoverageDesignatedCountyInput value={coverage.isDesignatedCounty} coverageIndex={coverageIndex} index={appTaskIndex} application={application} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
        <AppTableCellForInput><IsNewProducerInput value={coverage.isNewProducer} name={`.coverages.${coverageIndex}.isNewProducer`} index={appTaskIndex} application={application} canEditAppTask={canEditAppTask} /></AppTableCellForInput>
        <AppTableCell sx={{ paddingLeft: 1 }}>{getCommodityName(admData, cropYear, coverage.cropCode)}</AppTableCell>
        <AppTableCell sx={{ paddingLeft: 1 }}>{coverage.planCodeName}</AppTableCell>
        <AppTableCell sx={{ paddingLeft: 1 }}>{getCoverageLevelString(coverage)}</AppTableCell>
        <AppTableCell sx={{ paddingLeft: 1 }}>{practice}</AppTableCell>
        <AppTableCell sx={{ paddingLeft: 1 }}>{typeCode}</AppTableCell>
        <AppTableCell sx={{ paddingLeft: 1 }}>{unitStructure}</AppTableCell>
        <AppTableCell sx={{ paddingLeft: 1 }}>{isNotNullOrUndefined(coverage.optionCodes) ? coverage.optionCodes.sort((a, b) => a.localeCompare(b)).join(', ') : ''}</AppTableCell>
        <AppTableCell align="right">{coverage.intendedNetAcres}</AppTableCell>
        <AppTableCell sx={{ width: '150px'  }}><EstimatedGrossPremiumInput value={coverage.estimatedGrossPremium} coverageIndex={coverageIndex} appTaskIndex={appTaskIndex} /></AppTableCell>
      </CoverageRow>
      {/* Not using collapse here because we want our columns to line up with the table above,
      collapse would make that really hard without static width columns */}
      {open && coverage.childCoverages.map(cc => {
        return (
          <CoverageRow sx={{ height: '40px' }} key={cc.coverageId}>
            <AppTableCell />
            <AppTableCell></AppTableCell>
            <AppTableCell></AppTableCell>
            <AppTableCell></AppTableCell>
            <AppTableCell></AppTableCell>
            <AppTableCell>{getCommodityName(admData, cropYear, cc.cropCode)}</AppTableCell>
            <AppTableCell>{cc.planCodeName}</AppTableCell>
            <AppTableCell>{getCoverageLevelString(cc)}</AppTableCell>
            <AppTableCell></AppTableCell>
            <AppTableCell></AppTableCell>
            <AppTableCell></AppTableCell>
            <AppTableCell></AppTableCell>
            <AppTableCell align="right"></AppTableCell>
            <AppTableCell align="right">{cc.estimatedGrossPremium !== null ? <CurrencyText sx={{ fontWeight: 'normal', fontSize: '12px' }} currencyAmount={cc.estimatedGrossPremium} colorize={false} align={'right'} /> : null}</AppTableCell>
          </CoverageRow>
        );
      })}
    </>
  );
};

export default AppTaskCoverageRow;
