import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import BaseUnit from '../../../types/api/BaseUnit';
import {
  Commodity,
  CoverageTypeCode,
  ScenarioPieceType,
  ScoPlusIndemnityParams,
  ScoPlusPremiumParams,
  ScoPlusRequestDTO,
  ScoPlusUnit,
  Unit
} from '@silveus/calculations';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { ScoEcoPlusCalculationParams } from '../../../types/api/calculationData/scoEcoPlusCalculationParams';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getCalcParamRequestUnits, getMatchingCalcParamDataForUnit, getUnitsForScenarioPiece } from '../calculationUtils';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import { getScoEcoPlusCalcDataRequest } from '../../requestInterception/scenarioPieces/ecoScoPlusRequestInterceptor';
import { ScoEcoPlusCalculationParamsRequest } from '../../../types/api/calculationData/scoEcoPlusCalculationParamsRequest';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import { Nullable } from '../../../types/util/Nullable';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';

export const createScoPlusData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  //Get the calc data back from the scenario piece
  const calcParamRequest = createCalcParamRequest(state, scenarioPiece, scenario, baseUnits);
  const returnedData = await getScoEcoPlusCalcDataRequest(calcParamRequest);

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createScoPlusDto(scenarioPiece, unitsForScenarioPiece, scenario, returnedData, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformScoPlusData = (scenario: RowCropScenario, unit: Nullable<BaseUnit>, scenarioPiece: RowCropScenarioPiece): ScoPlusPremiumParams & ScoPlusIndemnityParams => {
  //Create the data that is not specific to any unit
  const scoPlusParams: ScoPlusPremiumParams & ScoPlusIndemnityParams = {
    finalAreaYield: scenario.actualCountyYield ?? 0,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    liabilityAdjustmentFactor: 1,
    multipleCommodityAdjustmentFactor: 1,
    priceElectionPercent: 100,
    harvestPrice: scenario.harvestPrice ?? 0,
    projectedPrice: scenario.projectedPrice ?? 0,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    percentOfPrice: scenarioPiece.protectionFactor / 100,
    coverageTypeCode: CoverageTypeCode.A,
    commodityCode: (unit?.commodityCode ?? '') as Commodity,
    unitStructureCode: scenarioPiece.unitStructure,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,
  };
  return scoPlusParams;
};

const createScoPlusUnits = (units: BaseUnit[], scoPlusData: ScoEcoPlusCalculationParams[], scenarioPieceType: ScenarioPieceType): (ScoPlusUnit & Unit)[] => {
  //Create the data for a given unit
  const scoPlusUnits = units.map(unit => {
    const matchingData = getMatchingCalcParamDataForUnit(unit, scoPlusData);
    const unitDto = createBaseUnit(unit);
    const scoPlusUnit: ScoPlusUnit & Unit = {
      ...unitDto,
      id: unit.id,
      premiumRate: scenarioPieceType === ScenarioPieceType.ScoPlusRevenue ? matchingData?.revenueRate ?? 0 : matchingData?.yieldRate ?? 0,
    };

    return scoPlusUnit;
  });

  return scoPlusUnits;
};

const createScoPlusDto = (scenarioPiece: RowCropScenarioPiece, unitData: BaseUnit[], scenario: RowCropScenario, scoPlusData: ScoEcoPlusCalculationParams[], unitGroups: UnitGroup[]): ScoPlusRequestDTO => {
  const myScoRequest: ScoPlusRequestDTO = {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createScoPlusUnits(
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scoPlusData,
        scenarioPiece.scenarioPieceType,
      ),
    ),
    ...transformScoPlusData(scenario, unitData.at(0) ?? null, scenarioPiece),
  };

  return myScoRequest;
};

const createCalcParamRequest = (state: RootState, scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, baseUnits: BaseUnit[]): ScoEcoPlusCalculationParamsRequest => {
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  return {
    year: clientFile.year,
    scenarioPieceTypeId: scenarioPiece.scenarioPieceType,
    upperCoverageLevel: scenarioPiece.upperCoverageLevel,
    lowerCoverageLevel: scenarioPiece.lowerCoverageLevel,
    commodityCode: quote.commodityCode,
    countyId: quote.countyId,
    distinctUnits: getCalcParamRequestUnits(baseUnits),
  };
};
