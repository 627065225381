import UnitYearAph from '../../../types/api/UnitYearAph';
import NumberTextField from '../../../components/numberTextField/numberTextField.component';
import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { calculateCustomTYield } from '@silveus/calculations';

interface CustomTYieldProps {
  unitYearAph: UnitYearAph[];
}

const CustomTYieldDisplay = ({ unitYearAph }: CustomTYieldProps) => {
  const customTYield = calculateCustomTYield(unitYearAph);
  const { control } = useFormContext();
  return (
    <Controller
      name="customTYield"
      control={control}
      rules={{ maxLength: VALIDATION_RULES.maxLength(100) }}
      defaultValue={customTYield ?? ''}
      render={({
        field: { ref }, fieldState: { error },
      }) => (
        <NumberTextField
          type="text"
          label="Custom T-Yield"
          fullWidth
          inputProps={{ maxLength: 100 }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          value={customTYield ?? ''}
          size="small"
          disabled
        />
      )}
    />
  );
};

export default CustomTYieldDisplay;
