import { KeyOption, matchSorter } from 'match-sorter';

export const fuzzySearchMultipleWords = <T>(rows: T[], filterValue: string, keys: KeyOption<T>[]) => {
  if (!filterValue || !filterValue.length) return rows;

  //remove all characters that are neither alphanumeric nor spaces
  const cleaned = filterValue.replace(/[^\w\s]/gi, '');

  const terms = cleaned.split(' ');

  return terms.reduceRight(
    (results, term) => matchSorter(results, term, { keys: keys }),
    rows,
  );
};