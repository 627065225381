import { AxiosResponse } from 'axios';
import { isOnlineFromStore } from './isOnlineFromStore';

const onlyAvailableOnlineMessage = 'This action is only available online';

/**
 * Makes a request to the cache, and one to the network. The result from the cache is returned if there is one.
 * When the network request returns, the cache is updated with the result, which will be returned to the user the next time the same request is made
 */
export const staleWhileRevalidate = async <T>(request: () => Promise<AxiosResponse<T, unknown>>, cacheResponse: () => Promise<T | undefined>, transaction: (newData: T) => Promise<void>): Promise<T> => {
  try {
    const cacheResult = await cacheResponse();
    const networkFetch = fetchAndUpdate(request, transaction);
    return isCachedDataValid(cacheResult) ? cacheResult : await networkFetch;
  } catch {
    return await fetchAndUpdate(request, transaction);
  }
};

/** Queries the cache first, and if no results are found in the cache, will go to the network  */
export const cacheFirst = async <T>(request: () => Promise<AxiosResponse<T, unknown>>, cacheResponse: () => Promise<T | undefined>): Promise<T> => {
  try {
    const cacheResult = await cacheResponse();
    return isCachedDataValid(cacheResult) ? cacheResult : (await request()).data;
  } catch {
    throw new Error('Unable to get cached data while offline');
  }
};

/** Issues a network request and updates the cache with the result. If the network request fails, it will attempt to retrieve the data from cache */
export const networkFirstAndUpdate = async <T>(request: () => Promise<AxiosResponse<T, unknown>>, cacheResponse: () => Promise<T | undefined>, transaction: (newData: T) => Promise<void>): Promise<T> => {
  try {
    return await fetchAndUpdate(request, transaction);
  } catch {
    const cacheResult = await cacheResponse();
    return isCachedDataValid(cacheResult) ? cacheResult : (await request()).data;
  }
};

/** Issues and returns a network request like normal */
export const networkOnly = async <T>(request: () => Promise<AxiosResponse<T, unknown>>): Promise<T> => {
  return isOnlineFromStore() ? (await request()).data : Promise.reject(new Error(onlyAvailableOnlineMessage));
};

/** Issues a network request and updates the cache with the result */
export const networkOnlyAndUpdate = async <T>(request: () => Promise<AxiosResponse<T, unknown>>, cacheResponse: () => Promise<T | undefined>, transaction: (newData: T) => Promise<void>): Promise<T> => {
  return isOnlineFromStore() ? await fetchAndUpdate(request, transaction) : Promise.reject(new Error(onlyAvailableOnlineMessage));
};

/** Queries the cache and returns the result. No network request is ever issued */
export const cacheOnly = async <T>(request: (() => Promise<AxiosResponse<T, unknown>>) | undefined, cacheResponse: () => Promise<T | undefined>): Promise<T> => {
  const result = await cacheResponse();
  if (result === undefined) throw new Error('Unable to get cached data while offline');

  return result;
};

/**
 * Issues requests of types other than GET to the network. No cache hit is ever attempted.
 * This functions much the same as normal network only, with a few changes to the parameters specific to non-get requests
 */
export const nonGetNetworkOnly = async <T>(request: () => Promise<AxiosResponse<T, unknown>>, transaction: () => Promise<T>): Promise<T> => {
  return isOnlineFromStore() ? (await request()).data : Promise.reject(new Error(onlyAvailableOnlineMessage));
};

export const nonGetNetworkOnlyAndUpdate = async <T>(request: () => Promise<AxiosResponse<T, unknown>>, transaction: (newData: T) => Promise<void>): Promise<T> => {
  return isOnlineFromStore() ? await fetchAndUpdate(request, transaction) : Promise.reject(new Error(onlyAvailableOnlineMessage));
};

/**
 * Issues requests to the cache with no network request being made.
 * This functions much the same as normal cache only, with a few changes to the parameters specific to non-get requests
 */
export const nonGetCacheOnly = async <T>(request: (() => Promise<AxiosResponse<T, unknown>>) | undefined, transaction: () => Promise<T>): Promise<T> => {
  return transaction();
};

const isCachedDataValid = <T>(cacheResult: T | T[] | undefined): cacheResult is T | T[] => Array.isArray(cacheResult) ? cacheResult.length > 0 : cacheResult !== undefined;

const fetchAndUpdate = async <T>(request: () => Promise<AxiosResponse<T, unknown>>, transaction: (newData: T) => Promise<void>): Promise<T> => {
  const response = await request();

  const data = response.data;

  await transaction(data);

  return data;
};
