import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientFileFormProps } from '../pages/clientFile/component/clientFileForm.component';
import { QuoteFormProps } from '../pages/quote/components/quoteForm.component';
import { ScenarioFormWrapperProps } from '../pages/scenario/components/scenarioFormWrapper.component';
import { UnitFormWrapperProps } from '../pages/units/components/unitFormWrapper.component';
import { Nullable } from '../types/util/Nullable';
import { RootState } from './store';
import { HistoricalFormWrapperProps } from '../pages/scenarioAnalysis/historicalAnalysis/historicalFormWrapper';
import { PremiumBreakdownFormProps } from '../pages/scenarioAnalysis/premiumBreakdown/premiumBreakdownForm.component';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { MatrixFormWrapperProps } from '../pages/matrix/matrixFormWrapper.component';


interface AppDrawerState {
  isOpen: boolean;
  openDrawer: Nullable<PossibleDrawer>;
  shouldCloseOnSave: boolean;
  openDrawerRequestId: string;
}

export type DrawerFormType =
  | 'clientFileForm'
  | 'quoteForm'
  | 'unitForm'
  | 'scenarioForm'
  | 'scenarioPieceForm'
  | 'matrixForm'
  | 'matrixPresetsForm'
  | 'historicalQuoteForm'
  | 'historicalQuoteColumnForm'
  | 'premiumBreakdownForm';

type DrawerDiscriminator<TFormName extends DrawerFormType, TOwnProps> = {
  formName: TFormName;
  width?: number;
} & TOwnProps;

export type PossibleDrawer =
  | DrawerDiscriminator<'clientFileForm', ClientFileFormProps>
  | DrawerDiscriminator<'quoteForm', QuoteFormProps>
  | DrawerDiscriminator<'unitForm', UnitFormWrapperProps>
  | DrawerDiscriminator<'scenarioForm', ScenarioFormWrapperProps>
  | DrawerDiscriminator<'scenarioPieceForm', ScenarioFormWrapperProps>
  | DrawerDiscriminator<'matrixForm', MatrixFormWrapperProps>
  | DrawerDiscriminator<'matrixPresetsForm', MatrixFormWrapperProps>
  | DrawerDiscriminator<'historicalQuoteForm', HistoricalFormWrapperProps>
  | DrawerDiscriminator<'historicalQuoteColumnForm', HistoricalFormWrapperProps>
  | DrawerDiscriminator<'premiumBreakdownForm', PremiumBreakdownFormProps>;

const initialState: AppDrawerState = {
  isOpen: false,
  openDrawer: null,
  shouldCloseOnSave: false,
  openDrawerRequestId: '',
};

export const appDrawerSlice = createSlice({
  name: 'appDrawer',
  initialState: initialState,
  reducers: {
    openDrawer(state: AppDrawerState, action: PayloadAction<PossibleDrawer>) {
      state.isOpen = true;
      state.openDrawer = action.payload;
      //Whenever an open drawer command is issued, we want to generate a new key
      //to be able to distinguish just a tab switch.
      state.openDrawerRequestId = generatePrimaryKey();
    },
    closeDrawer() {
      return initialState;
    },
    updateOpenDrawer(state: AppDrawerState, action: PayloadAction<PossibleDrawer>) {
      if (state.isOpen) {
        state.openDrawer = action.payload;
      }
    },
    updateShouldCloseOnSave(state: AppDrawerState, action: PayloadAction<boolean>) {
      state.shouldCloseOnSave = action.payload;
    },
  },
});

export const { openDrawer, closeDrawer, updateOpenDrawer, updateShouldCloseOnSave } = appDrawerSlice.actions;

export const selectAppDrawerState = (state: RootState) => state.appDrawer;
export const selectAppDrawerOpen = (state: RootState) => state.appDrawer.isOpen;
export const selectAppDrawerWidth = (state: RootState) => state.appDrawer.openDrawer?.width ?? 450;
export const selectShouldCloseOnSave = (state: RootState) => state.appDrawer.shouldCloseOnSave;

export default appDrawerSlice.reducer;
