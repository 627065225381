import { IEnumAttributes } from '../IEnumAttributes';

export enum AppTaskDocumentSource {
  SupportingDocument = 0,
  AppTaskDocument = 1,
}

export const AppTaskDocumentSourceAttributes: Readonly<Record<AppTaskDocumentSource, IEnumAttributes<AppTaskDocumentSource>>> = {
  [AppTaskDocumentSource.SupportingDocument]: { name: 'Supporting Documents', description: 'Supporting', value: AppTaskDocumentSource.SupportingDocument },
  [AppTaskDocumentSource.AppTaskDocument]: { name: 'App Task Documents', description: 'App Task', value: AppTaskDocumentSource.AppTaskDocument },
};