import { UpperCoverageLevelInput } from './upperCoverageLevelInput.component';

export const SingleOptionDisabledUpperCoverageLevelInput = ({ upperCoverageLevel,
  autoFocus = false }: { upperCoverageLevel: number, autoFocus?: boolean },
) => {
  const availableCoverageLevels = [upperCoverageLevel];

  return <UpperCoverageLevelInput upperCoverageLevel={upperCoverageLevel}
    availableCoverageLevels={availableCoverageLevels} autoFocus={autoFocus}
    disabled={true} />;
};
