import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { FormInputProps } from '../../scenario/formInputProps';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectOfferExpectedCountyYield, selectOfferExpectedInputCosts, selectOfferProjectedPrice } from '../../../../app/admSlice';
import React from 'react';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

interface MpIsLiveQuotingProps extends FormInputProps {
  isLiveQuote: boolean;
  doesAdmMpDataExist: boolean
}

export type MpIsLiveQuotingFields = {
  isLiveQuote: boolean;
}

const MpIsLiveQuotingInput = ({ isLiveQuote, doesAdmMpDataExist, autoFocus = false, disabled = false }: MpIsLiveQuotingProps) => {
  const { control, setValue, formState } = useFormContext();
  const projectedPrice = useAppSelector(selectOfferProjectedPrice);
  const expectedYield = useAppSelector(selectOfferExpectedCountyYield);
  const expectedInputCosts = useAppSelector(selectOfferExpectedInputCosts);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked && doesAdmMpDataExist) {
      setValue(ExtendedDataFormInputParameterNames.ProjectedPrice, projectedPrice, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue('expectedCountyYield', expectedYield, { shouldValidate: formState.isSubmitted, shouldDirty: true });
      setValue(ExtendedDataFormInputParameterNames.ExpectedInputCosts, expectedInputCosts, { shouldValidate: formState.isSubmitted, shouldDirty: true });
    }
    setValue(ExtendedDataFormInputParameterNames.UnsubsidizedPremium, null, { shouldValidate: formState.isSubmitted, shouldDirty: true });
  };

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.IsLiveQuote}
      control={control}
      defaultValue={isLiveQuote}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl
          error={!!error}
          disabled={disabled}>
          <FormControlLabel
            label="Live Quoting"
            control={
              <Checkbox
                {...field}
                checked={value}
                inputRef={ref}
                onChange={e => {
                  field.onChange(e.target.checked);
                  onChange(e);
                }}
                autoFocus={autoFocus}
              />
            } />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl >
      )}
    />
  );
};

export default MpIsLiveQuotingInput;
