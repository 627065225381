import { envBaseApiUrl } from '../constants/envConstants';
import axios from 'axios';
import { ActualProductionReportData } from '../types/api/reports/ActualProductionReportData';
import { TriggerYieldReportData } from '../types/api/reports/TriggerYieldReportData';
import { QuoteSummaryReportData } from '../types/api/reports/QuoteSummaryReportData';
import { PremiumBreakdownReportData } from '../types/api/reports/PremiumBreakdownReportData';
import { MatrixReportData } from '../types/api/reports/MatrixReportData';

export const createActualProductionReport = (reportData: ActualProductionReportData) => axios.post<string>(`${envBaseApiUrl}/reports/actual-production`, reportData, { responseType: 'arraybuffer' });
export const createTriggerYieldReport = (reportData: TriggerYieldReportData) => axios.post<string>(`${envBaseApiUrl}/reports/trigger-yield`, reportData, { responseType: 'arraybuffer' });
export const createQuoteSummaryReport = (reportData: QuoteSummaryReportData) => axios.post<string>(`${envBaseApiUrl}/reports/quote-summary`, reportData, { responseType: 'arraybuffer' });
export const createMatrixReport = (reportData: MatrixReportData) => axios.post<string>(`${envBaseApiUrl}/reports/matrix`, reportData, { responseType: 'arraybuffer' });
export const createPremiumBreakdownReport = (reportData: PremiumBreakdownReportData) => axios.post<string>(`${envBaseApiUrl}/reports/premium-breakdown`, reportData, { responseType: 'arraybuffer' });