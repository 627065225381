import { modifyMpScenarioPiecePriceAndInputCosts, selectAllRowCropScenarioPiecesByScenarioMap } from '../../../app/scenarioPiecesSlice';
import { getAvailableIntervalsForScenarioPieceType } from '../../../app/intervalsSlice';
import { Grid } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { HighRiskType, ScenarioPieceType, UnitStructureCode } from '@silveus/calculations';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { createScenarioPiece, removeScenarioPieceAndRecalculate } from '../../../app/scenarioPiecesSlice';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import { RowCropScenarioPieceExtendedFormFields } from '../rowCropScenarioPieceExtendedFormFields.component';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { ProtectionFactorFields } from '../../../components/formInputs/scenarioPiece/protectionFactorInput.component';
import { useFederalOfferData } from '../../../hooks/useFederalOfferData';
import { ExtendedDataFormInputParameterNames } from '../../../components/formInputs/constants/inputConstants';
import { PriceInputGroupFields } from '../../../components/formInputs/scenario/priceInputGroup.component';
import { CountyYieldInputGroupFields } from '../../../components/formInputs/scenario/countyYieldInputGroup';
import { updateScenario } from '../../../app/scenariosSlice';
import { mergeScenarioWithYieldAndPriceUpdates } from '../../../utils/scenarioUpdateUtils';
import IntervalInput, { IntervalInputFields } from '../../../components/formInputs/scenario/intervalInput.component';
import { ScenarioPieceSelectedInterval } from '../../../types/api/adm/ScenarioPieceSelectedInterval';
import { setSelectedIntervals } from '../../../app/intervalsSlice';
import { VipMpCapInput } from '../../../components/formInputs/scenarioPiece/vipMp/vipMpCapInput.component';
import { VipMpFactorInput } from '../../../components/formInputs/scenarioPiece/vipMp/vipMpFactorInput.component';
import { TopIntervalInput } from '../../../components/formInputs/scenarioPiece/topIntervalInput.component';
import { CustomPremiumInput } from '../../../components/formInputs/customPremiumInput.component';
import { VipMpIsInputCostEndorsement } from '../../../components/formInputs/scenarioPiece/vipMp/vipMpIsInputCostEndorsementInput.component';
import { MaxInputCostDecline } from '../../../components/formInputs/scenarioPiece/vipMp/vipMpMaxInputCostDeclineInput.component';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { UpperCoverageLevelFields } from '../../../components/formInputs/scenarioPiece/upperCoverageLevelInput.component';
import { MpScenarioPiecePricesAndYields, MpScenarioPiecePricesAndYieldsFields } from '../mp/mpScenarioPiecePricesAndYields.component';
import { Quote } from '../../../types/api/Quote';
import { selectScenarioById } from '../../../app/scenariosSlice';
import { MissingScenarioInStateError, MissingUnderlyingScenarioPieceInStateError } from '../../../errors/state/MissingStateErrors';

export interface VipMpScenarioPieceFormProps extends FormWrapperProps {
  quote: Quote;
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  scenarioPieceType: ScenarioPieceType.MP | ScenarioPieceType.MpHpo;
  year: number;
  scenarioId: ScenarioId;
  highRiskTypeId: HighRiskType;
  countyId: string;
  typeId: string;
  practiceId: string;
  disabled: boolean;
  handleCancel: () => void;
}

export type VipMpScenarioPieceFormFields = IntervalInputFields & ScenarioPieceFormFields &
  RowCropScenarioPieceExtendedFormFields & ProtectionFactorFields & PriceInputGroupFields &
  CountyYieldInputGroupFields & UpperCoverageLevelFields & MpScenarioPiecePricesAndYieldsFields;

export const VipMpScenarioPieceForm = ({ quote, scenarioPiece, scenarioId, year, countyId, typeId, practiceId, highRiskTypeId, disabled, registerHeader, handleValidation, handleCancel, isCanceling = false }: VipMpScenarioPieceFormProps) => {
  const formId = 'vipScenarioPieceForm';
  const dispatch = useAppDispatch();
  const methods = useForm<VipMpScenarioPieceFormFields>();
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  if (scenario === null) throw new MissingScenarioInStateError(scenarioId);


  const underlying = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioId).find(sp => [ScenarioPieceType.MpHpo, ScenarioPieceType.MP].includes(sp.scenarioPieceType));
  if (underlying === undefined) throw new MissingUnderlyingScenarioPieceInStateError(scenarioId);
  const underlyingScenarioPieceType = underlying.scenarioPieceType;

  const scenarioPieceType = underlyingScenarioPieceType === ScenarioPieceType.MpHpo ? ScenarioPieceType.VipMpHpo : ScenarioPieceType.VipMp;

  const maxInputCostDisabled = !useWatch({ name: ExtendedDataFormInputParameterNames.IsInputCostEndorsement, control: methods.control, defaultValue: scenarioPiece?.rowCropScenarioPieceExtendedData?.isInputCostEndorsement ?? false });

  const planCode = getInsurancePlanCodeForScenarioPiece(underlyingScenarioPieceType);

  useFederalOfferData(null, scenarioId, underlyingScenarioPieceType, year, countyId, typeId, practiceId, highRiskTypeId);

  const availableIntervals = useAppSelector(state => getAvailableIntervalsForScenarioPieceType(state, scenarioPieceType));

  const onSubmit: SubmitHandler<VipMpScenarioPieceFormFields> = async data => {
    const extendedData = data.rowCropScenarioPieceExtendedData;
    if (extendedData === null) throw new Error('Vip Mp extended data is unexpectedly null');

    const newScenarioPiece: RowCropScenarioPiece = {
      isActive: data.isActive,
      isInvalid: false,
      lowerCoverageLevel: 0,
      planCode: planCode,
      protectionFactor: data.protectionFactor,
      rowCropScenarioPieceExtendedData: data.rowCropScenarioPieceExtendedData,
      rowCropScenarioPieceId: scenarioPiece?.rowCropScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: scenarioPieceType,
      unitStructure: UnitStructureCode.AU,
      upperCoverageLevel: underlying.upperCoverageLevel,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    await dispatch(updateScenario({
      updatedScenario: mergeScenarioWithYieldAndPriceUpdates(scenario, data),
    }));

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(createScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(modifyMpScenarioPiecePriceAndInputCosts({ mpScenarioPiece: underlying, rowCropScenarioPieceExtendedData: data.rowCropScenarioPieceExtendedData }));

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId, updatedScenarioPiece: newScenarioPiece }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));

    const scenarioPieceSelectedIntervals: ScenarioPieceSelectedInterval[] = data.selectedIntervalIds.map(id => {
      const correspondingInterval = availableIntervals.find(interval => interval.id === id);
      if (correspondingInterval === undefined) throw new Error('Something was wrong with the selected interval chosen');
      return {
        scenarioPieceSelectedIntervalId: generatePrimaryKey(),
        scenarioPieceId: newScenarioPiece.scenarioPieceId,
        intervalRangeId: id,
        year: data.year + correspondingInterval.intervalDeltaYearStart,
      };
    });
    await dispatch(setSelectedIntervals({ scenarioPieceId: newScenarioPiece.scenarioPieceId, scenarioPieceSelectedIntervals }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IntervalInput
                scenarioPieceType={scenarioPieceType}
                clientFileYear={year}
                scenarioPieceId={scenarioPiece?.scenarioPieceId ?? null} />
            </Grid>
            <Grid item xs={6}>
              <VipMpCapInput priceCap={scenarioPiece?.rowCropScenarioPieceExtendedData?.priceChangeCap ?? null} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <VipMpFactorInput protectionFactor={scenarioPiece?.protectionFactor ?? null} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <TopIntervalInput topPrice={scenarioPiece?.rowCropScenarioPieceExtendedData?.topPrice ?? null} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <CustomPremiumInput customPremium={scenarioPiece?.rowCropScenarioPieceExtendedData?.customPremium ?? null} label="Premium" disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <VipMpIsInputCostEndorsement isInputCostEndorsement={scenarioPiece?.rowCropScenarioPieceExtendedData?.isInputCostEndorsement ?? false} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <MaxInputCostDecline maxInputCostDecline={scenarioPiece?.rowCropScenarioPieceExtendedData?.maxInputCostDecline ?? null} disabled={disabled || maxInputCostDisabled} />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '6px' }} />
            <MpScenarioPiecePricesAndYields quote={quote} scenario={scenario} scenarioPiece={underlying} scenarioPieceType={scenarioPieceType} year={year} isLiveQuote={underlying.rowCropScenarioPieceExtendedData?.isLiveQuote ?? false} areFieldsLocked={underlying.rowCropScenarioPieceExtendedData?.areFieldsLocked ?? false} commodityCode={quote.commodityCode} />
            <Grid item xs={12}>
              <IsActiveInput isActive={scenarioPiece?.isActive ?? null} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
    </>
  );
};
