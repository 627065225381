import { Guid, ScenarioId, UnitYearId } from '../types/api/PrimaryKeys';
import { RowCropScenario } from '../types/api/RowCropScenario';
import ScenarioOption from '../types/api/options/ScenarioOption';
import ScenarioOptionUnitYear from '../types/api/options/ScenarioOptionUnitYear';
import UnitYear from '../types/api/UnitYear';
import BaseUnit from '../types/api/BaseUnit';
import { toPrimaryKey } from './primaryKeyHelpers';
import { getSubCountyCodeFromId, HighRiskType, OptionCode, Options } from '@silveus/calculations';
import { OptionLevelCodes } from '../constants/optionLevelCodes';
import { Nullable } from '../types/util/Nullable';
import { compare, distinctBy, orderByProperty } from './arrayUtils';
import { DefaultOrders } from './entityOrdering/defaultOrdering';
import { ClientFile } from '../types/api/ClientFile';
import { Quote } from '../types/api/Quote';

export const getApplicableUnitsWithSuppliedData = (
  apiData: readonly { unitYearId: Guid }[],
  scenario: RowCropScenario,
  optionsForScenario: ScenarioOption[],
  isQuickQuote: boolean,
  scenarioOptionUnitYearsForScenario: ScenarioOptionUnitYear[],
  unitsForScenario: UnitYear[],
  year: number,
  getApprovedYieldForUnit: (unitYearId: UnitYearId, scenarioId: ScenarioId) => Nullable<number>,
  getAdjustedYieldForUnit: (unitYearId: UnitYearId, scenarioId: ScenarioId) => Nullable<number>,
  getRateYieldForUnit: (unitYearId: UnitYearId) => Nullable<number>,
  getApprovedYieldForScenario: (scenarioId: ScenarioId) => Nullable<number>,
  getAdjustedYieldForScenario: (scenarioId: ScenarioId) => Nullable<number>,
): BaseUnit[] => {
  let applicableCombinedUnits: BaseUnit[] = [];

  if (!isQuickQuote) {
    //If we are doing unit quoting then we need to get all of the unitYears that apply to the given thing (scenario or scenario piece) and transform them
    const unitYearIds = apiData.map(data => toPrimaryKey<UnitYearId>(data.unitYearId));
    const applicableUnitYears = unitsForScenario.filter(u => unitYearIds.includes(u.unitYearId));

    applicableCombinedUnits = applicableUnitYears.map(uy => {
      const scenarioOptionsForUnit = scenarioOptionUnitYearsForScenario.filter(souy => souy.unitYearId === uy.unitYearId).map(souy => souy.scenarioOptionId);
      const unitOptionsCodes = optionsForScenario.filter(option => scenarioOptionsForUnit.includes(option.scenarioOptionId)).map(scenarioOption => scenarioOption.option) as OptionCode[];
      const unitOptions = unitOptionsCodes.filter(optionCode => Options[optionCode].optionLevelCode === OptionLevelCodes.Unit);

      const scenarioOptions = optionsForScenario.filter(scenarioOption => Options[scenarioOption.option].optionLevelCode !== OptionLevelCodes.Unit).map(scenarioOption => scenarioOption.option);

      const combinedOptions = unitOptions.concat(scenarioOptions);

      const approvedYield = getApprovedYieldForUnit(uy.unitYearId, scenario.scenarioId);
      const adjustedYield = getAdjustedYieldForUnit(uy.unitYearId, scenario.scenarioId);
      const rateYield = getRateYieldForUnit(uy.unitYearId);

      const baseUnit: BaseUnit = {
        ...uy,
        id: uy.unitYearId,
        options: combinedOptions,
        approvedYield: approvedYield ?? adjustedYield ?? 0,
        adjustedYield: adjustedYield ?? 0,
        actualYield: scenario.actualProducerYield ?? 0,
        rateYield: rateYield ?? 0,
        subCountyCode: uy.subCountyCode,
      };
      return baseUnit;
    });
  } else {
    //If we are doing quick quoting, then we need to get the quick unit and transform it
    const quickUnitOptions = optionsForScenario.map(so => so.option) as OptionCode[];

    const approvedYield = getApprovedYieldForScenario(scenario.scenarioId);
    const adjustedYield = getAdjustedYieldForScenario(scenario.scenarioId) ?? 0;

    const scenarioQuickUnit = scenario.quickUnit;
    if (scenarioQuickUnit !== null) {
      const baseUnit: BaseUnit = {
        ...scenarioQuickUnit,
        id: scenarioQuickUnit.scenarioQuickUnitId,
        year: year,
        options: quickUnitOptions,
        adjustedYield: adjustedYield,
        approvedYield: approvedYield ?? adjustedYield,
        actualYield: scenario.actualProducerYield ?? 0,
        rateYield: scenarioQuickUnit.rateYield,
        subCountyCode: getSubCountyCodeFromId(scenario.highRiskTypeId),
        township: null,
        range: null,
      };
      applicableCombinedUnits = [baseUnit];
    }
  }

  return applicableCombinedUnits;
};

export const filterUnitYearsThatCanApplyToScenario = (allUnitYears: UnitYear[], clientFile: ClientFile, quote: Quote, typeId: Nullable<string>, practiceId: Nullable<string>, highRiskTypeId: HighRiskType) => {
  const unitYearsForClientFileAndQuote = allUnitYears.filter(uy => uy.year === clientFile.year &&
    uy.countyId === quote.countyId && uy.commodityCode === quote.commodityCode && uy.insuredId === clientFile.insuredId);

  const unitYearsFilteredByType = typeId === null ? unitYearsForClientFileAndQuote : unitYearsForClientFileAndQuote.filter(uy => uy.typeId === typeId);

  let unitYearsFilteredByHighRisk = practiceId === null ? unitYearsFilteredByType : unitYearsFilteredByType.filter(uy => uy.practiceId === practiceId);

  if (!quote.quickQuote) {
    const uraCode = getSubCountyCodeFromId(HighRiskType.URA);
    const code = getSubCountyCodeFromId(highRiskTypeId);
    switch (highRiskTypeId) {
      case HighRiskType.AllLand:
        // all units, so leave unitYearsFilteredByHighRisk as is
        break;
      case HighRiskType.HighRiskOnly:
        unitYearsFilteredByHighRisk = unitYearsFilteredByHighRisk.filter(x => x.subCountyCode !== null && x.subCountyCode !== uraCode);
        break;
      case HighRiskType.URA:
        unitYearsFilteredByHighRisk = unitYearsFilteredByHighRisk.filter(x => x.subCountyCode === null || x.subCountyCode === uraCode);
        break;
      default:
        // these are the AAA and 003 like ones
        unitYearsFilteredByHighRisk = unitYearsFilteredByHighRisk.filter(x => x.subCountyCode === code);
        break;
    }
  }

  return unitYearsFilteredByHighRisk;
};

export const getOrderedDistinctUnitYearsThatCanApplyToScenario = (unorderedUnitYears: UnitYear[]) => {
  const unitYears = orderByProperty(unorderedUnitYears, DefaultOrders.unitYears);

  //Make sure that we are aren't providing duplicates, and that they are properly sorted
  const filteredUnitYears = distinctBy(unitYears, uy => uy.unitYearId);

  return filteredUnitYears.sort((unitYear1, unitYear2) => {
    //This sorts the list of units, first by basic unit number, then by optional unit number
    return compare(unitYear1.basicUnitNumber, unitYear2.basicUnitNumber) ||
      compare(unitYear1.optionalUnitNumber, unitYear2.optionalUnitNumber);
  });
};