import { Controller } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { TextField } from '@mui/material';

interface RowCountProps {
  rowCount: number;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
}

export type RowCountFields = {
  rowCount: number;
}

export const RowCountInput = ({ rowCount, className, autoFocus = false, disabled = false }: RowCountProps) => {
  return (
    <Controller
      name="rowCount"
      rules={{ required: VALIDATION_RULES.required(), min: VALIDATION_RULES.minimum(1) }}
      defaultValue={rowCount}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          className={className}
          type="number"
          label="Rows"
          size="small"
          fullWidth
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => field.onChange(parseFloat(e.target.value))}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      )}
    />
  );
};
