import { Nullable } from '../../../types/util/Nullable';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlLabel, FormHelperText, Checkbox } from '@mui/material';

interface IsCatProps {
  isCat: Nullable<boolean>;
  autoFocus?: boolean;
  disabled?: boolean;
}

export type IsCatFields = {
  isCat: boolean;
}

const IsCatInput = ({ isCat, autoFocus = false, disabled = false }: IsCatProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="rowCropScenarioPieceExtendedData.isCat"
      control={control}
      defaultValue={isCat ?? false}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl
          error={!!error}>
          <FormControlLabel
            label="CAT"
            control={
              <Checkbox
                {...field}
                checked={value}
                disabled={disabled}
                inputRef={ref}
                onChange={e => field.onChange(e.target.checked)}
                autoFocus={autoFocus}
                size="small"
              />
            }
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default IsCatInput;
