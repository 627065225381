import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import { QuoteId } from '../../types/api/PrimaryKeys';
import { useAppDispatch, useAppSelector, useKeyMapSelector, useKeyMapSelectorSingle } from '../../hooks/reduxHooks';
import {
  selectCalculationsByScenarioPiece
} from '../../app/calculationResultsSlice';
import {
  modifyScenarioPiece,
  removeScenarioPieceAndRecalculate,
  selectAllRowCropScenarioPiecesByScenarioMap
} from '../../app/scenarioPiecesSlice';
import { ReactElement } from 'react';
import PayoutGrid from '../../components/payoutGrid/payoutGrid.component';
import ScenarioPieceCard from './scenarioPieceCard.component';
import { getFriendlyScenarioPieceName } from '../../utils/scenarioPieceUtils';
import InsuranceResultSummary from './insuranceResultSummary.component';
import { Box } from '@mui/material';
import ScenarioComponentResultSummary from './scenarioComponentResultSummary';
import ScenarioPieceTooltip from './scenarioPieceTooltip';
import { validateRowCropScenario } from '../../utils/validation/validateScenario';
import { selectValidationErrorsForScenarioPiece } from '../../app/validationsSlice';
import { ScenarioPieceType } from '@silveus/calculations';
import { getScenarioPieceDefinition } from '../../constants/productDefinitions/scenarioPieceDefinitionRecords';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { selectQuoteById } from '../../app/quotesSlice';
import { MissingQuoteInStateError } from '../../errors/state/MissingStateErrors';

interface RowCropScenarioPieceCardProps {
  scenario: RowCropScenario;
  scenarioPiece: RowCropScenarioPiece;
  quoteId: QuoteId;
  isScenarioActive: boolean;
  isReadonly?: boolean;
}

const RowCropScenarioPieceCard = ({ scenario, scenarioPiece, quoteId, isScenarioActive, isReadonly = false }: RowCropScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();
  const calcResults = useKeyMapSelectorSingle(selectCalculationsByScenarioPiece, scenarioPiece.scenarioPieceId);
  const netScenarioPieceTotal = (calcResults?.indemnityPerAcre ?? 0) - (calcResults?.producerPremiumPerAcre ?? 0);
  const validationErrors = useAppSelector(state => selectValidationErrorsForScenarioPiece(state, scenarioPiece.scenarioPieceId));
  const quote = useAppSelector(state => selectQuoteById(state, quoteId));

  const piecesForScenario = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioPiece.scenarioId);

  const isMp = [ScenarioPieceType.MP, ScenarioPieceType.MpHpo].includes(scenarioPiece.scenarioPieceType);

  const collapsedCardBody = <InsuranceResultSummary calcResults={calcResults} hideCoverage={isMp} isReadonly={isReadonly} />;

  const scenarioPieceDefinition = getScenarioPieceDefinition(scenarioPiece.scenarioPieceType);

  const breakdownResultCards: ReactElement[] = [];

  if (calcResults !== null && calcResults.additionalCoverageResults.length > 0) {
    const netBaseValue = calcResults.baseResult.indemnityPerAcre - calcResults.baseResult.producerPremiumPerAcre;
    const scenarioPieceTypeName = getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType);

    const baseSummaryContent = (
      <PayoutGrid
        totalPremium={calcResults.baseResult.producerPremium}
        totalIndemnity={calcResults.baseResult.indemnityAmount}
        premiumPerAcre={calcResults.baseResult.producerPremiumPerAcre}
        indemnityPerAcre={calcResults.baseResult.indemnityPerAcre}
        totalFixed={0}
        totalSimulated={0}
        fixedPerAcre={0}
        simulatedPerAcre={0}
        totalDiscount={0}
        discountPerAcre={0}
        isReadonly={isReadonly}
      />
    );

    const baseCard = (
      <Box key={calcResults.baseResult.id}>
        <hr />
        <ScenarioComponentResultSummary
          label={`${scenarioPieceTypeName} - Base`}
          netValue={netBaseValue}
          calcResults={calcResults.baseResult}
          scenarioId={scenarioPiece.scenarioId}
          summaryContent={baseSummaryContent}
          getScenarioPieceTooltip={() => <ScenarioPieceTooltip displayName={`${scenarioPieceTypeName} - Base`} calcResults={calcResults.baseResult} />}
          isReadonly={isReadonly}
          isInvalid={scenarioPiece.isInvalid}
        />
      </Box>
    );

    breakdownResultCards.push(baseCard);

    calcResults.additionalCoverageResults.forEach(additionalCoverageCalcResult => {
      const netValue = additionalCoverageCalcResult.indemnityPerAcre - additionalCoverageCalcResult.producerPremiumPerAcre;

      const summaryContent = (
        <PayoutGrid
          totalPremium={additionalCoverageCalcResult.producerPremium}
          totalIndemnity={additionalCoverageCalcResult.indemnityAmount}
          premiumPerAcre={additionalCoverageCalcResult.producerPremiumPerAcre}
          indemnityPerAcre={additionalCoverageCalcResult.indemnityPerAcre}
          totalFixed={0}
          totalSimulated={0}
          fixedPerAcre={0}
          simulatedPerAcre={0}
          totalDiscount={0}
          discountPerAcre={0}
          isReadonly={isReadonly}
        />
      );

      const additionalCoverageSummaryCard = (
        <Box key={additionalCoverageCalcResult.id}>
          <hr />
          <ScenarioComponentResultSummary
            label={additionalCoverageCalcResult.id}
            netValue={netValue}
            calcResults={additionalCoverageCalcResult}
            scenarioId={scenarioPiece.scenarioId}
            summaryContent={summaryContent}
            getScenarioPieceTooltip={() => <ScenarioPieceTooltip displayName={additionalCoverageCalcResult.id} calcResults={additionalCoverageCalcResult} />}
            isReadonly={isReadonly}
            isInvalid={scenarioPiece.isInvalid}
          />
        </Box>
      );

      breakdownResultCards.push(additionalCoverageSummaryCard);
    });
  }

  const expandedCardBody = (
    <>
      <PayoutGrid
        totalPremium={calcResults?.producerPremium ?? 0}
        totalIndemnity={calcResults?.indemnityAmount ?? 0}
        premiumPerAcre={calcResults?.producerPremiumPerAcre ?? 1}
        indemnityPerAcre={calcResults?.indemnityPerAcre ?? 0}
        totalFixed={calcResults?.totalFixed ?? 0}
        totalSimulated={calcResults?.totalSimulated ?? 0}
        fixedPerAcre={calcResults?.fixedPerAcre ?? 0}
        simulatedPerAcre={calcResults?.simulatedPerAcre ?? 0}
        totalDiscount={calcResults?.discount ?? 0}
        discountPerAcre={calcResults?.discountPerAcre ?? 0}
        isReadonly={isReadonly}
        showDiscount={isMp}
      />
      {breakdownResultCards.length > 0 && breakdownResultCards}
    </>
  );

  const updateScenarioPiece = async (scenarioPiece: RowCropScenarioPiece) => {
    if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);
    const scenarioPieceValidations = validateRowCropScenario(piecesForScenario, scenario, quote, scenarioPiece);

    const scenarioPiecesToUpdate = scenarioPieceValidations.filter(validation => validation.shouldUpdate);
    const piecesToUpdate = scenarioPiecesToUpdate.map(validation => dispatch(modifyScenarioPiece({ scenarioPiece: validation.scenarioPiece })));
    await Promise.all(piecesToUpdate);
  };

  const removeScenarioPiece = async (scenarioPiece: RowCropScenarioPiece) => {
    dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
  };

  const errorTooltip = (
    <>
      {validationErrors.map((error, index) => (
        <div key={index}>
          {error}
          {index < validationErrors.length - 1 && <br />}
        </div>
      ))}
    </>
  );

  const scenarioPieceNameLabel = scenarioPieceDefinition.getFullyQualifiedScenarioPieceName(scenarioPiece);

  return (
    <ScenarioPieceCard
      scenarioPiece={scenarioPiece}
      quoteId={quoteId}
      isScenarioActive={isScenarioActive}
      updateScenarioPiece={updateScenarioPiece}
      removeScenarioPiece={removeScenarioPiece}
      label={scenarioPieceNameLabel}
      collapsedCardBody={collapsedCardBody}
      expandedCardBody={expandedCardBody}
      netValue={netScenarioPieceTotal}
      calcResults={calcResults}
      scenarioId={scenarioPiece.scenarioId}
      isReadonly={isReadonly}
      scenarioColor={scenario.scenarioColor}
      errorTooltip={errorTooltip}
    />
  );
};

export default RowCropScenarioPieceCard;