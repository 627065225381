import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import BaseUnit from '../../../types/api/BaseUnit';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import {
  getCalcParamRequestUnits,
  getMatchingCalcParamDataForUnit,
  getUnitsForScenarioPiece
} from '../calculationUtils';
import {
  Commodity, CoverageTypeCode,
  HipIndemnityParams,
  HipPremiumParams,
  HipRequestDTO,
  HipUnit,
  OptionCode, ScenarioPieceType, Unit,
  UnitStructureCode
} from '@silveus/calculations';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { selectQuoteById } from '../../../app/quotesSlice';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import HipCalculationParams from '../../../types/api/calculationData/hipCalculationParams';
import HipCalculationParamsRequest from '../../../types/api/calculationData/hipCalculationParamsRequest';
import { getHipCalcDataRequest } from '../../requestInterception/scenarioPieces/hipRequestInterceptor';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../../../app/scenarioPiecesSlice';
import { getItemsForId } from '../../../utils/mapHelpers';
import { scenarioPieceOrderingServiceInstance } from '../../../utils/scenarioOrderingServiceWrappers';
import { Nullable } from '../../../types/util/Nullable';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';

export const createHipData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  const calcParamRequest = createCalcParamRequest(state, scenarioPiece, scenario, baseUnits);
  const returnedData = await getHipCalcDataRequest(calcParamRequest);

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  const rowCropScenarioPieces = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), scenario.scenarioId);

  const baseScenarioPieceTypes = scenarioPieceOrderingServiceInstance.getBaseScenarioPieces();
  const basePiecesThatHaveHipDependency: ScenarioPieceType[] = [];
  baseScenarioPieceTypes.forEach(baseScenarioPieceType => {
    const dependentPieces = scenarioPieceOrderingServiceInstance.getDependantScenarioPieces(baseScenarioPieceType);
    if (dependentPieces.includes(ScenarioPieceType.Hip)) {
      basePiecesThatHaveHipDependency.push(baseScenarioPieceType);
    }
  });

  const underlyingScenarioPiece = rowCropScenarioPieces.find(scenarioPiece => basePiecesThatHaveHipDependency.includes(scenarioPiece.scenarioPieceType));

  if (underlyingScenarioPiece === undefined) throw new Error('Attempting to quote HIP with no underlying scenario piece');

  const scenarioPieceSpecificData = createHipPlanDto(scenarioPiece, returnedData, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece, underlyingScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformHipData = (calcData: Nullable<HipCalculationParams>, unit: Nullable<BaseUnit>, scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: RowCropScenarioPiece): HipPremiumParams & HipIndemnityParams => {
  const hipParams: HipPremiumParams & HipIndemnityParams = {
    commodityCode: (unit?.commodityCode ?? '') as Commodity,
    conservComplSubsidyReductionPercent: calcData?.conservComplSubsidyReductionPercent ?? 0,
    coverageTypeCode: (calcData?.coverageTypeCode ?? '') as CoverageTypeCode,
    hurricaneEventOccurred: scenarioPiece.rowCropScenarioPieceExtendedData?.hurricaneEventOccurred ?? false,
    isBeginningOrVeteranFarmerOrRancher: false,
    isConservationCompliance: false,
    isNativeSod: false,
    liabilityAdjustmentFactor: 1,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    multipleCommodityAdjustmentFactor: calcData?.multipleCommodityAdjustmentFactor ?? 0,
    numberOfTropicalStorms: scenarioPiece.rowCropScenarioPieceExtendedData?.numberOfTropicalStorms ?? 0,
    priceElectionPercent: scenarioPiece.protectionFactor / 100,
    underlyingPriceElectionPercent: underlyingScenarioPiece.protectionFactor / 100,
    projectedPrice: scenario.projectedPrice ?? 0,
    //TODO: Not sure exactly what this is or where it comes from. It's only for a few commodities
    // (orange trees, grapefruit trees, lemon trees, lime trees, avocados, avocado trees, carambola trees, and mango trees)
    // None of these are currently supported.
    //"Proration percent applies only to florida fruit trees and is determined by the signature date plus 45 days"
    // No mention of an ICE or ADM table that this data may come from
    prorationPercent: 1,
    subsidyPercent: calcData?.subsidyPercent ?? 0,
    //This is the upper coverage level percent of an underlying plan (something like RP, YP, etc.)
    underlyingCoverageLevelPercent: underlyingScenarioPiece.upperCoverageLevel / 100,
    underlyingGuaranteeAmount: 0,
    underlyingLiabilityAmount: 0,
    //This is the upper coverage level of STAX (if it's on the scenario)
    unitStructureCode: scenarioPiece.unitStructure,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,

    //TODO: These only applies to acre limitation, which is something we currently aren't handling anywhere
    acreReductionRequired: false,
    hipwiAcreLimitationAmount: 0,
    summedReportedPlantedAcreage: 0,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
  };

  return hipParams;
};

const createHipUnits = (scenarioPiece: RowCropScenarioPiece, hipCalcData: HipCalculationParams[], units: BaseUnit[], scenario: RowCropScenario): (HipUnit & Unit)[] => {
  return units.map(unit => {
    const matchingData = getMatchingCalcParamDataForUnit(unit, hipCalcData);

    //TODO: Error Handling
    if (isNullOrUndefined(matchingData)) throw new Error('HIP Data Transform State mismatch: Unable to find data returned from API associated with unit in state.');
    const unitDto = createBaseUnit(unit);
    const hipUnit: HipUnit & Unit = {
      ...unitDto,
      id: unit.id,
      baseRate: matchingData.baseRate,
      coverageLevelDifferentialValues: matchingData.coverageLevelDifferentials,
      optionRates: matchingData.optionRates,
      options: unit.options.filter(optionCode => [OptionCode.TS, OptionCode.SE].includes(optionCode)),
    };

    return hipUnit;
  });
};

const createHipPlanDto = (scenarioPiece: RowCropScenarioPiece, apiData: HipCalculationParams[], unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[], underlyingScenarioPiece: RowCropScenarioPiece): HipRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createHipUnits(
        scenarioPiece,
        apiData,
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformHipData(apiData.at(0) ?? null, unitData.at(0) ?? null, scenario, scenarioPiece, underlyingScenarioPiece),
  };
};

const createCalcParamRequest = (state: RootState, scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, baseUnits: BaseUnit[]): HipCalculationParamsRequest => {
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  return {
    year: clientFile.year,
    coverageTypeCode: (scenarioPiece.rowCropScenarioPieceExtendedData?.isCat ?? false) ? CoverageTypeCode[CoverageTypeCode.C] : CoverageTypeCode[CoverageTypeCode.A],
    unitStructureCode: UnitStructureCode[scenarioPiece.unitStructure],
    planCode: scenarioPiece.planCode,
    highRiskTypeId: scenario.highRiskTypeId,
    upperCoverageLevel: scenarioPiece.upperCoverageLevel,
    volatility: scenario.volatility,
    commodityCode: quote.commodityCode,
    countyId: quote.countyId,
    distinctUnits: getCalcParamRequestUnits(baseUnits),
  };
};
