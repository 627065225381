import { ColDef, ICellEditorParams, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import UnitYear from '../../types/api/UnitYear';
import { ActionCellRenderer } from './cellRenderers/actionCellRenderer';
import { validateBasicUnitNumber } from '../units/validations/basicUnitNumberValidation';
import { validateOptionalUnitNumber } from '../units/validations/optionalUnitNumberValidation';
import { validateFarmName } from '../units/validations/farmNameValidation';
import { validateFarmNumber } from '../units/validations/farmNumberValidation';
import { validateSection } from '../units/validations/sectionValidation';
import { validateTownship } from '../units/validations/townshipValidation';
import { validateRange } from '../units/validations/rangeValidation';
import { validateLocation } from '../units/validations/locationValidation';
import UnitOptionsCellRenderer from './cellRenderers/unitOptionsCellRenderer';
import { validateAcres } from '../units/validations/acresValidation';
import { validateInsuredSharePercent } from '../units/validations/insuredShareValidation';
import { UnitOptionInputParams } from './cellEditors/unitOptionsInput';
import { UnitYearId } from '../../types/api/PrimaryKeys';
import { roundToPlaces } from '@silveus/calculations';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../utils/nullHandling';
import { isEmptyUnitGridRow } from './unitsGrid';
import { UnitYearGridItem } from './useUnitsModal';
import UnitYearAph from '../../types/api/UnitYearAph';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import { numberFormatter } from '../../utils/grid';
import { Nullable } from '../../types/util/Nullable';
import { sharedYieldFormatter } from './valueFormatters/sharedYieldFormatter';
import { yieldParser } from './valueParser/yieldParser';
import OptionState from '../../types/app/OptionState';

type GetColumnDefinitionsParams = {
  previousYear: number;
  openUnitHistory: (unitYear: UnitYear) => void;
  deleteUnit: (unitYear: UnitYear) => void;
  unitOptionsMap: Map<UnitYearId, OptionState[]>;
  updateUnitOptions: (unitOptionStates: OptionState[], unitYearId: UnitYearId) => void;
  unitYearAphMap: Map<UnitYearId, UnitYearAph[]>;
  setLocalUnitYears: (unitYearGrid: UnitYearGridItem[]) => void;
  setLocalUnitYearAphMap: (unitYearAphMap: Map<UnitYearId, UnitYearAph[]>) => void;
  localScenarioUnitYearAph: ScenarioUnitYearAph[];
  setLocalScenarioUnitYearAph: (scenarioUnitYearAph: ScenarioUnitYearAph[]) => void;
  subCountyCodes: string[];
  yieldRoundingPrecision: Nullable<number>;
}

export const getColumnDefinitions = ({ previousYear, openUnitHistory, deleteUnit, unitOptionsMap, updateUnitOptions, subCountyCodes, yieldRoundingPrecision }: GetColumnDefinitionsParams) => {
  const getOptionEditorParams = (params: ICellEditorParams) => {
    const props: UnitOptionInputParams = {
      unitYear: params.data,
      unitOptionsMap: unitOptionsMap,
    };

    return props;
  };

  const columnDefinitions: ColDef<UnitYearGridItem>[] = [
    {
      colId: 'actions',
      headerName: '',
      pinned: 'right',
      sortable: false,
      suppressMovable: true,
      suppressNavigable: true,
      editable: false,
      resizable: false,
      cellClass: 'icon-cell',
      headerClass: 'icon-header',
      cellRenderer: (params: ICellRendererParams<UnitYearGridItem>) => ActionCellRenderer(params, openUnitHistory, deleteUnit),
      cellClassRules: {
        'borderless-cell': params => params.node.rowPinned === 'bottom',
      },
    },
    {
      colId: 'unitNum',
      headerName: 'Unit #',
      pinned: 'left',
      lockVisible: true,
      headerTooltip: 'Unit #',
      field: 'basicUnitNumber',
      suppressMovable: true,
      suppressAutoSize: true,
      resizable: false,
      editable: params => params.node.rowPinned !== 'bottom',
      cellStyle: { paddingRight: 0 },
      cellClassRules: {
        'invalid-cell': params => params.node.rowPinned !== 'bottom' && isNotNullOrUndefined(params.data) && checkValidationResult(validateBasicUnitNumber(params.data.basicUnitNumber)),
      },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        // Because we combine the basic unit number and option unit number into
        // essentially a single column have a custom comparator that takes into
        // account for both fields while still putting newly added rows first
        if (isDescending) {
          if (nodeA.data === undefined || isEmptyUnitGridRow(nodeA.data)) return 1;
          if (isNullOrUndefined(nodeB.data) || isEmptyUnitGridRow(nodeB.data)) return -1;
        } else {
          if (nodeB.data === undefined || isEmptyUnitGridRow(nodeB.data)) return 1;
          if (isNullOrUndefined(nodeA.data) || isEmptyUnitGridRow(nodeA.data)) return -1;
        }

        const a = valueA?.toString().toLowerCase();
        const b = valueB?.toString().toLowerCase();

        if (a === b) {
          const aData = nodeA.data.optionalUnitNumber.toString().toLowerCase();
          const bData = nodeB.data.optionalUnitNumber.toString().toLowerCase();
          if (aData === '') return 1;
          if (bData === '') return -1;
          return aData.localeCompare(bData);
        }
        return a.localeCompare(b);
      },
      valueFormatter: (params: ValueFormatterParams<UnitYearGridItem>) => {
        return params.node?.rowPinned === 'bottom' ? 'Summary' : params.value;
      },
    },
    {
      colId: 'optionUnitNumber',
      headerName: '',
      pinned: 'left',
      lockVisible: true,
      sortable: false,
      editable: params => params.node.rowPinned !== 'bottom',
      field: 'optionalUnitNumber',
      suppressMovable: true,
      suppressAutoSize: true,
      resizable: false,
      cellStyle: { paddingRight: 0, paddingLeft: 0 },
      cellClassRules: {
        'invalid-cell': params => params.node.rowPinned !== 'bottom' && isNotNullOrUndefined(params.data) && checkValidationResult(validateOptionalUnitNumber(params.data.optionalUnitNumber)),
        'borderless-cell': params => params.node.rowPinned === 'bottom',
      },
    },
    {
      colId: 'farmName',
      headerName: 'Farm Name',
      field: 'farmName',
      headerTooltip: 'Farm Name',
      editable: params => params.node.rowPinned !== 'bottom',
      cellClassRules: {
        'invalid-cell': params => params.node.rowPinned !== 'bottom' && isNotNullOrUndefined(params.data) && checkValidationResult(validateFarmName(params.data.farmName ?? '')),
      },
    },
    {
      colId: 'farmNum',
      headerName: 'Farm #',
      headerTooltip: 'Farm #',
      field: 'farmNumber',
      editable: params => params.node.rowPinned !== 'bottom',
      cellClassRules: {
        'invalid-cell': params => params.node.rowPinned !== 'bottom' && isNotNullOrUndefined(params.data) && checkValidationResult(validateFarmNumber(params.data.farmNumber ?? '')),
      },
    },
    {
      colId: 'section',
      headerName: 'Section',
      headerTooltip: 'Section',
      field: 'section',
      editable: params => params.node.rowPinned !== 'bottom',
      cellClassRules: {
        'invalid-cell': params => params.node.rowPinned !== 'bottom' && isNotNullOrUndefined(params.data) && checkValidationResult(validateSection(params.data.section ?? '')),
      },
    },
    {
      colId: 'twp',
      headerName: 'TWP',
      headerTooltip: 'Township',
      field: 'township',
      editable: params => params.node.rowPinned !== 'bottom',
      cellClassRules: {
        'invalid-cell': params => params.node.rowPinned !== 'bottom' && isNotNullOrUndefined(params.data) && checkValidationResult(validateTownship(params.data.township ?? '')),
      },
    },
    {
      colId: 'range',
      headerName: 'Range',
      headerTooltip: 'Range',
      field: 'range',
      editable: params => params.node.rowPinned !== 'bottom',
      cellClassRules: {
        'invalid-cell': params => params.node.rowPinned !== 'bottom' && isNotNullOrUndefined(params.data) && checkValidationResult(validateRange(params.data.range ?? '')),
      },
    },
    {
      colId: 'location',
      headerName: 'Location',
      headerTooltip: 'Location',
      field: 'location',
      editable: params => params.node.rowPinned !== 'bottom',
      cellClassRules: {
        'invalid-cell': params => params.node.rowPinned !== 'bottom' && isNotNullOrUndefined(params.data) && checkValidationResult(validateLocation(params.data.location ?? '')),
      },
    },
    {
      colId: 'options',
      headerName: 'Options',
      headerTooltip: 'Options',
      sortable: false,
      suppressNavigable: true,
      editable: params => params.node.rowPinned !== 'bottom',
      filter: false,
      cellEditorPopupPosition: 'over',
      cellRenderer: (params: ICellRendererParams<UnitYear>) => params.data === undefined ? null : UnitOptionsCellRenderer({
        params: params,
        unitYear: params.data,
        unitOptionsMap: unitOptionsMap,
      }),
      cellEditor: 'unitOptionsEditor',
      cellEditorParams: getOptionEditorParams,
      valueSetter: params => {
        updateUnitOptions(params.newValue, params.data.unitYearId);
        return false;
      },
    },
    {
      colId: 'subCounty',
      headerName: 'Sub County',
      headerTooltip: 'Sub County Code',
      field: 'subCountyCode',
      editable: params => params.node.rowPinned !== 'bottom',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: subCountyCodes,
      },
    },
    {
      colId: 'acres',
      headerName: 'Acres',
      headerTooltip: 'Acres',
      editable: params => params.node.rowPinned !== 'bottom',
      type: 'number',
      field: 'acres',
      valueFormatter: numberFormatter,
      cellClassRules: {
        'invalid-cell': params =>
          params.node.rowPinned !== 'bottom'
          && isNotNullOrUndefined(params.data)
          && checkValidationResult(validateAcres(params.data.acres, true)),
        'outlined-cell': params => params.node.rowPinned === 'bottom',
      },
    },
    {
      colId: 'sharePercent',
      headerName: 'Share %',
      headerTooltip: 'Share %',
      type: 'number',
      editable: params => params.node.rowPinned !== 'bottom',
      field: 'sharePercent',
      cellClassRules: {
        'invalid-cell': params => params.node.rowPinned !== 'bottom' && isNotNullOrUndefined(params.data) && checkValidationResult(validateInsuredSharePercent(roundToPlaces(params.data.sharePercent / 100, 4))),
      },
    },
    {
      colId: 'rateYield',
      headerName: 'Rate Yield',
      headerTooltip: 'Rate Yield',
      wrapHeaderText: true,
      field: 'rateYield',
      type: 'number',
      valueFormatter: params => sharedYieldFormatter(params, yieldRoundingPrecision),
      editable: false,
      suppressNavigable: true,
      suppressKeyboardEvent: () => true,
      cellClassRules: {
        'outlined-cell': params => params.node.rowPinned === 'bottom',
      },
    },
    {
      colId: 'adjustedYield',
      headerName: 'Adj. Yield',
      headerTooltip: 'Adjusted Yield',
      wrapHeaderText: true,
      type: 'number',
      valueFormatter: params => sharedYieldFormatter(params, yieldRoundingPrecision),
      field: 'adjustedYield',
      editable: false,
      suppressNavigable: true,
      cellClassRules: {
        'outlined-cell': params => params.node.rowPinned === 'bottom',
      },
    },
    {
      colId: 'approvedYield',
      headerName: 'Appr. Yield',
      headerTooltip: 'Approved Yield',
      wrapHeaderText: true,
      type: 'number',
      valueFormatter: params => sharedYieldFormatter(params, yieldRoundingPrecision),
      field: 'approvedYield',
      editable: false,
      suppressNavigable: true,
      cellClassRules: {
        'outlined-cell': params => params.node.rowPinned === 'bottom',
      },
    },
    {
      colId: 'priorYearProduction',
      editable: params => params.node.rowPinned !== 'bottom',
      headerName: `${previousYear} Production`,
      headerTooltip: `${previousYear} Production`,
      wrapHeaderText: true,
      type: 'number',
      valueFormatter: numberFormatter,
      field: 'priorYearProduction',
    },
    {
      colId: 'priorYearAcres',
      editable: params => params.node.rowPinned !== 'bottom',
      headerName: `${previousYear} Acres`,
      headerTooltip: `${previousYear} Acres`,
      wrapHeaderText: true,
      type: 'number',
      valueFormatter: numberFormatter,
      field: 'priorYearAcres',
    },
    {
      colId: 'priorYearYield',
      editable: params => params.node.rowPinned !== 'bottom',
      headerName: `${previousYear} Yield`,
      headerTooltip: `${previousYear} Yield`,
      wrapHeaderText: true,
      type: 'number',
      valueFormatter: params => sharedYieldFormatter(params, yieldRoundingPrecision),
      field: 'priorYearYield',
    },
    {
      colId: 'priorYearApprYield',
      headerName: 'Prior Year Appr. Yield',
      headerTooltip: 'Prior Year Approved Yield',
      editable: true,
      wrapHeaderText: true,
      minWidth: 70,
      type: 'number',
      valueParser: params => yieldParser(params, yieldRoundingPrecision),
      field: 'priorYearApprYield',
    },
  ];

  // All the validation code returns true if valid, otherwise it returns a string with the error message.
  const checkValidationResult = (result: boolean | string) => result !== true;


  return columnDefinitions;
};