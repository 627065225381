import { AcreageType } from '@silveus/calculations';
import { useAppSelector, useKeyMapSelector } from './reduxHooks';
import { selectScenarioById } from '../app/scenariosSlice';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../app/scenarioPiecesSlice';
import { selectCountyYieldInfoPriorAndCurrent, selectHistoricalYieldTrendYears, selectScenarioPricesAndYieldsPriorYearFinalCountyYield } from '../app/admSlice';
import { orderByProperty } from '../utils/arrayUtils';
import HistoricalYield from '../types/api/adm/HistoricalYield';
import { ScenarioId } from '../types/api/PrimaryKeys';
import { selectScenarioPieceCalculationsForScenario } from '../app/calculationResultsSlice';
import HistoricalYieldProduct from '../types/api/adm/HistoricalYieldProduct';
import { useProducerHistory } from './useProducerHistory';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import HistoricalYieldTrendYear from '../types/api/adm/HistoricalYieldTrendYear';
import { getFriendlyScenarioPieceName } from '../utils/scenarioPieceUtils';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import { selectQuoteById } from '../app/quotesSlice';
import { isNullOrUndefined } from '../utils/nullHandling';
import { MissingClientFileInStateError, MissingQuoteInStateError, MissingScenarioInStateError } from '../errors/state/MissingStateErrors';
import { selectClientFileById } from '../app/clientFilesSlice';
import { getTrendBasedUponPriorTwoYears } from '../utils/trendlineYieldUtils';

export const useTrendLineYields = (scenarioId: ScenarioId, startYear: number, endYear: number, acreageType: AcreageType): HistoricalYield[] => {
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  if (scenario === null) throw new MissingScenarioInStateError(scenarioId);
  const scenarioPieceCalcResults = useAppSelector(state => selectScenarioPieceCalculationsForScenario(state, scenarioId));
  const quote = useAppSelector(state => selectQuoteById(state, scenario.quoteId));
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);
  const clientFile = useAppSelector(s => selectClientFileById(s, quote.clientFileId));
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);
  const priorYear = clientFile.year - 1;

  const isScenarioOrQuoteInvalid = isNullOrUndefined(scenario) || isNullOrUndefined(quote);
  const historicalYields = useAppSelector(state => isScenarioOrQuoteInvalid || isNullOrUndefined(scenario.practiceId) ?
    stableEmptyArrayAsMutable<HistoricalYieldTrendYear>() :
    selectHistoricalYieldTrendYears(state, quote.countyId, quote.commodityCode, scenario.typeId, scenario.practiceId, startYear, endYear));
  const priorYearFinalCountyYield = useAppSelector(state => selectScenarioPricesAndYieldsPriorYearFinalCountyYield(state, scenarioId));
  const countyYieldInfo = useAppSelector(state => selectCountyYieldInfoPriorAndCurrent(state, scenarioId));
  const countyYieldInfoThisYear = countyYieldInfo?.yieldInfoWithCountiesCurrentYear ?? null;
  const countyYieldInfoLastYear = countyYieldInfo?.yieldInfoWithCountiesPriorYear ?? null;

  //Determine the average upper coverage level for use in the getting the trigger yield
  const allScenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioId);

  const calculatedProducerYieldHistories = useProducerHistory(startYear, endYear, scenario, quote, acreageType, true);
  const filteredCalcScenarioPieces = scenarioPieceCalcResults.filter(x => allScenarioPieces.find(sp => x.scenarioPieceType === sp.scenarioPieceType));

  const historicalYieldProducts = filteredCalcScenarioPieces.map(scenarioPiece => {
    const scenarioPieceName = getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType);
    const associatedScenarioPiece = allScenarioPieces.find(sp => sp.scenarioPieceType === scenarioPiece.scenarioPieceType);

    if (associatedScenarioPiece === undefined) throw new Error();

    //Only add lower coverage level if it is not 0
    const lowerCoverageYield = scenarioPiece.lowerYieldCoverageLevel === 0
      ? undefined
      : scenarioPiece.lowerYieldCoverageLevel;

    const productYield: HistoricalYieldProduct = {
      scenarioPieceName: scenarioPieceName,
      upperYieldCoverageLevel: scenarioPiece.upperYieldCoverageLevel,
      lowerYieldCoverageLevel: lowerCoverageYield,
    };

    return productYield;
  });

  const mergedHistoricalYields: HistoricalYield[] = [];

  for (let year = startYear; year <= endYear; year++) {
    const producerResult = calculatedProducerYieldHistories.find(prodYieldHist => prodYieldHist.year === year);
    const areaResult = historicalYields.find(areaYield => areaYield.year === year);

    let expectedCountyYield = areaResult?.expectedYield;
    let actualCountyYield = areaResult?.actualYield;

    if (year === priorYear && areaResult === undefined) {
      const trendedExpectedYield = getTrendBasedUponPriorTwoYears(historicalYields, priorYear);
      if (trendedExpectedYield !== null) {
        expectedCountyYield = trendedExpectedYield;
      }
      const areEqual = countyYieldInfoLastYear !== null && countyYieldInfoThisYear !== null
        && countyYieldInfoLastYear.areaBasisCode === countyYieldInfoThisYear.areaBasisCode
        && countyYieldInfoLastYear.indexValueCode === countyYieldInfoThisYear.indexValueCode
        && countyYieldInfoLastYear.irrigationPractice.irrigationPracticeCode === countyYieldInfoThisYear.irrigationPractice.irrigationPracticeCode
        && countyYieldInfoLastYear.availableCounties.join('') === countyYieldInfoThisYear.availableCounties.join('');
      if (areEqual) {
        actualCountyYield = priorYearFinalCountyYield;
      }
    }

    const historicalYieldResult: HistoricalYield = {
      year: year,
      trendYield: producerResult?.trendYield ?? undefined,
      actualYield: producerResult?.actualYield ?? producerResult?.trendYield ?? undefined,
      expectedCountyYield: expectedCountyYield,
      actualCountyYield: actualCountyYield,
      historicalYieldProducts: historicalYieldProducts,
    };

    mergedHistoricalYields.push(historicalYieldResult);
  }

  const sortedChartData = orderByProperty(mergedHistoricalYields, DefaultOrders.historicalYields);

  return sortedChartData;
};