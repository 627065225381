import { Grid, Typography } from '@mui/material';
import { ApplicationWizard, DefaultApplication } from '../../../types/api/applicationWizard/applicationWizard';
import ApplicationWizardPage from './applicationWizardPage';
import { ApplicationWizardStep, ApplicationWizardStepProps } from './applicationWizardStep';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { addApplication, saveApplication } from '../../../app/applicationsSlice';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import QuizIcon from '@mui/icons-material/Quiz';
import YesNoQuestionInput from '../../../components/formInputs/applications/yesNoQuestionInput';
import { useEffect, useRef } from 'react';
import { fetchInsured, selectCurrentInsured } from '../../../app/insuredsSlice';

export const AppQuestionsStep: ApplicationWizardStep = {
  isDisabled(app: ApplicationWizard) {
    return false;
  },
  title: 'App Questions',
  index: 0,
  formId: 'form-app-questions',
  icon: <QuizIcon />,
  isValid(app: ApplicationWizard) {
    return {
      isValid: true,
      errors: [],
    };
  },
};

export interface ApplicationQuestionsFields {
  anyChanges: boolean;
  isNewEntity: boolean;
  isGeneratingForms: boolean;
  isGettingESignatures: boolean;
}

const ApplicationQuestionsPage = ({ application, navigateToNextStep, updateApplication }: ApplicationWizardStepProps) => {
  const insured = useAppSelector(selectCurrentInsured);
  const dispatch = useAppDispatch();
  const methods = useForm<ApplicationQuestionsFields>({
    defaultValues: application,
    values: application,
  });
  const { formState, control, setValue } = methods;
  const isDirty = formState.isDirty;
  const anyChanges = useWatch({ name: 'anyChanges', control: control, defaultValue: application.anyChanges });
  const isNewEntity = useWatch({ name: 'isNewEntity', control: control, defaultValue: application.isNewEntity });
  const newInsuredId = useRef(application.newInsuredId);

  useEffect(() => {
    // If isNewEntity changes we want to make sure we pull down the full insured information here for the original insured
    if (!isNewEntity && isNewEntity !== application.isNewEntity) {
      dispatch(fetchInsured({ insuredId: application.insuredId }));
    }
  }, [isNewEntity]);

  // Here we want to know when something in the form has changed so we can persist that change to redux as changes
  // on this page impact what steps show as enabled in the wizard. We can't wait until user hits continue/prev to save
  // like we will for almost all the other pages.
  useEffect(() => {
    if (isDirty) {
      updateApplication(populateApplicationChanges());
      // Reset the form's isDirty state so we can tell when the next change comes in
      methods.reset({}, { keepValues: true, keepDefaultValues: true });
    }
  }, [isDirty]);

  const saveInProgressWork = () => {
    const updatedApplication = populateApplicationChanges();
    if (application.isNewApplication ?? false) {
      dispatch(addApplication({ applicationWizard: { ...updatedApplication, isNewApplication: undefined } }));
    } else {
      dispatch(saveApplication({ updatedApplication: { ...updatedApplication, isNewApplication: undefined } }));
    }
    navigateToNextStep();
  };

  const populateApplicationChanges = () => {
    const formValues: ApplicationQuestionsFields = methods.getValues();
    const updatedApplicationQuestions: ApplicationQuestionsFields = {
      ...formValues,
      isNewEntity: formValues.anyChanges ? formValues.isNewEntity : false,
      isGeneratingForms: formValues.anyChanges ? formValues.isGeneratingForms : false,
      isGettingESignatures: formValues.anyChanges ? formValues.isGettingESignatures : false,
    };

    const updatedApplication: ApplicationWizard = {
      ...application,
      ...updatedApplicationQuestions,
      newInsuredId: updatedApplicationQuestions.isNewEntity ? newInsuredId.current : null,
    };

    return updatedApplication;
  };

  const handleAnyChanges = (makingChanges: boolean) => {
    if (makingChanges) {
      setValue('isNewEntity', DefaultApplication.isNewEntity);
      setValue('isGeneratingForms', DefaultApplication.isGeneratingForms);
      setValue('isGettingESignatures', DefaultApplication.isGettingESignatures);
    }
  };

  return (
    <FormProvider {...methods}>
      <form style={{ height: '100%' }} id="form-app-questions" onSubmit={methods.handleSubmit(saveInProgressWork)}>
        <ApplicationWizardPage tabIndex={AppQuestionsStep.index} formId={AppQuestionsStep.formId}>
          <Grid container alignItems="center" rowSpacing={2} p={2} sx={{ maxWidth: '800px !important' }}>
            <Grid item container justifyContent="flex-end">
              <Grid item width="52px" fontWeight={500}>Yes</Grid>
              <Grid item width="52px" fontWeight={500}>No</Grid>
            </Grid>
            <Grid item container ml={2}>
              <YesNoQuestionInput
                question="Are you making any changes to the application this year (including Address/Contact Info changes, Entity changes, Policy changes, or other changes)?"
                value={application.anyChanges}
                id="anyChanges"
                onChange={handleAnyChanges}
              />
              <YesNoQuestionInput
                question={`Is ${insured?.name} farming under a new entity?`}
                value={application.isNewEntity}
                id="isNewEntity"
                disabled={!anyChanges}
              />
              {!!newInsuredId.current && !isNewEntity && <Typography variant="subtitle1" sx={{ color: theme => theme.palette.secondary.main, mt: -1.5 }}>(If saved as No, the current new Insured will be removed)</Typography>}
              <YesNoQuestionInput
                question="Are you generating forms?"
                value={application.isGeneratingForms}
                id="isGeneratingForms"
                disabled={!anyChanges}
              />
              <YesNoQuestionInput
                question="Are you getting e-signatures?"
                value={application.isGettingESignatures}
                id="isGettingESignatures"
                disabled={!anyChanges}
              />
            </Grid>
          </Grid>
        </ApplicationWizardPage>
      </form>
    </FormProvider>
  );
};

export default ApplicationQuestionsPage;