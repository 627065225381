import { useAppDispatch } from '../../../hooks/reduxHooks';
import { updateCalculationForScenario } from '../../../app/calculationResultsSlice';
import { updateUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import HailScenarioPieceComposition from '../../../types/api/HailScenarioPieceComposition';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import { modifyHailScenarioPieceComposition } from '../../../app/hailSlice';
import InactiveScenarioComponentCard from '../inactiveScenarioComponentCard';

interface InactiveHailScenarioPieceCardProps {
  hailScenarioPieceComposition: HailScenarioPieceComposition;
  hailScenarioPieces: HailScenarioPiece[];
  isScenarioActive: boolean;
}

const InactiveHailScenarioPieceCard = ({ hailScenarioPieceComposition, hailScenarioPieces, isScenarioActive }: InactiveHailScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();

  const updateHailScenarioPieceComposition = async (newHailScenarioPieceComposition: HailScenarioPieceComposition, isActive?: boolean) => {
    await dispatch(modifyHailScenarioPieceComposition({ scenarioPieceComposition: { ...newHailScenarioPieceComposition, isActive: isActive ?? newHailScenarioPieceComposition.isActive } }));
    const unitGroupPromises = hailScenarioPieces.map(hailScenarioPiece => {
      return dispatch(updateUnitGroupsForScenarioPiece({ scenarioPieceId: hailScenarioPiece.scenarioPieceId }));
    });
    await Promise.all(unitGroupPromises);
    await dispatch(updateCalculationForScenario({ scenarioId: hailScenarioPieceComposition.scenarioId }));
  };

  return (
    <InactiveScenarioComponentCard
      scenarioComponent={hailScenarioPieceComposition}
      isScenarioActive={isScenarioActive}
      label="Hail"
      updateScenarioComponent={updateHailScenarioPieceComposition}
    />
  );
};

export default InactiveHailScenarioPieceCard;