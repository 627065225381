import { useSweetSpots } from '@silveus/react-matrix';
import { MatrixSweetSpotInput } from './matrixSweetSpotInput';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { modifySweetSpots } from '../../../app/matricesSlice';
import Matrix from '../../../types/api/Matrix';
import { SweetSpotId } from '../../../types/api/PrimaryKeys';
import { produce } from 'immer';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface Props {
  matrix: Matrix;
  buildSweetSpotInput: ReturnType<typeof useSweetSpots>['buildSweetSpotInput'];
  handleAddSweetSpot: () => void;
}

export const MatrixSweetSpots = ({ matrix, buildSweetSpotInput, handleAddSweetSpot }: Props) => {
  const dispatch = useAppDispatch();

  const produceMatrixChange = async (fn: (draft: Matrix) => void) => {
    const matrixData = produce(matrix, fn);
    await dispatch(modifySweetSpots({ matrixData: matrixData }));
  };

  const handleDeleteSweetSpot = (sweetSpotId: SweetSpotId) => {
    produceMatrixChange(draft => {
      draft.sweetSpots = draft.sweetSpots.filter(ss => ss.sweetSpotId !== sweetSpotId);
    });
  };

  const handleSweetSpotVisibilityChange = (sweetSpotId: SweetSpotId, isHidden: boolean) => {
    produceMatrixChange(draft => {
      const sweetSpot = draft.sweetSpots.find(ss => ss.sweetSpotId === sweetSpotId);
      if (sweetSpot !== undefined) {
        sweetSpot.isHidden = isHidden;
      }
    });
  };

  // The summary sweet spot is special, and doesn't get to be configured or removed by the user.
  const sweetSpotsToRenderInputsFor = matrix.sweetSpots.filter(ss => !ss.isForMatrixSummary).sort((a, b) => a.sweetSpotOrder - b.sweetSpotOrder);

  return (
    <Grid container>
      <Grid item xs={11}>
        {sweetSpotsToRenderInputsFor.map(ss => (
          <MatrixSweetSpotInput
            key={ss.sweetSpotId}
            {...buildSweetSpotInput({ sweetSpotId: ss.sweetSpotId, color: ss.color, label: ss.label, value: ss.averageData?.average ?? null })}
            sweetSpotId={ss.sweetSpotId}
            customOnSweetSpotDelete={handleDeleteSweetSpot}
            customOnSweetSpotVisibilityChange={handleSweetSpotVisibilityChange}
            isHidden={ss.isHidden}
          />
        ))}
      </Grid>

      <Grid item xs>
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={handleAddSweetSpot} color="primary">
            { sweetSpotsToRenderInputsFor.length === 0 && <Typography mr={1}>Add Sweet Spots</Typography> }
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};