import { quoterDb } from '../../../db';
import ForwardSoldScenarioPiece from '../../../types/api/ForwardSoldScenarioPiece';
import { ScenarioId, ScenarioPieceId } from '../../../types/api/PrimaryKeys';
import { CreatedItemResult } from '../../../types/api/results/CreatedItemResult';
import { safeWhere } from '../../../utils/dexieQueryHelpers/whereClauses';
import {
  addForwardSoldScenarioPiece,
  deleteForwardSoldScenarioPiece,
  getForwardSoldScenarioPiece,
  getForwardSoldScenarioPiecesByScenarioId,
  getForwardSoldScenarioPiecesByScenarioIds,
  updateForwardSoldScenarioPiece
} from '../../forwardSold.service';
import { addSingle, getDefaultCachingStrategy, getMultiple, getSingle, remove, update } from '../../offlineDataAccess.service';

const table = quoterDb.forwardSoldScenarioPieces;

export const getForwardSoldScenarioPieceRequest = async (scenarioPieceId: ScenarioPieceId): Promise<ForwardSoldScenarioPiece> => {
  const request = () => getForwardSoldScenarioPiece(scenarioPieceId);
  return await getSingle(table, { scenarioPieceId: scenarioPieceId }, request);
};

export const getForwardSoldScenarioPiecesForScenarioRequest = async (scenarioId: ScenarioId): Promise<ForwardSoldScenarioPiece[]> => {
  const request = () => getForwardSoldScenarioPiecesByScenarioId(scenarioId);
  return await getMultiple(table, { scenarioId: scenarioId }, request);
};

export const getForwardSoldScenarioPiecesForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<ForwardSoldScenarioPiece[]> => {
  const request = () => getForwardSoldScenarioPiecesByScenarioIds(scenarioIds);

  const readTransaction = () => quoterDb.transaction('r', quoterDb.forwardSoldScenarioPieces, async () => {
    return safeWhere(quoterDb.forwardSoldScenarioPieces, 'scenarioId').anyOf(scenarioIds).toArray();
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const addForwardSoldScenarioPieceRequest = async (forwardSold: ForwardSoldScenarioPiece): Promise<CreatedItemResult<ScenarioPieceId>> => {
  const request = () => addForwardSoldScenarioPiece(forwardSold);
  return await addSingle(table, forwardSold, request);
};

export const updateForwardSoldScenarioPieceRequest = async (forwardSold: ForwardSoldScenarioPiece): Promise<void> => {
  const request = () => updateForwardSoldScenarioPiece(forwardSold);
  return await update(table, forwardSold, request);
};

export const deleteForwardSoldScenarioPieceRequest = async (scenarioPieceId: ScenarioPieceId): Promise<void> => {
  const request = () => deleteForwardSoldScenarioPiece(scenarioPieceId);
  return await remove(table, scenarioPieceId, request);
};
