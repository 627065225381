import { Nullable } from '../../../types/util/Nullable';
import { Controller } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { TextField } from '@mui/material';
import { ChangeEvent } from 'react';

interface PriceScaleProps {
  priceScale: Nullable<number>;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
}

export type PriceScaleFields = {
  priceScale: number;
}

export const PriceScaleInput = ({ priceScale, className,  autoFocus = false, disabled = false }: PriceScaleProps) => {
  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: { onChange: (val: Nullable<number>) => void }) => {
    const val = parseFloat(e.target.value);
    field.onChange(isNaN(val) ? null : val);
  };

  return (
    <Controller
      name="priceScale"
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={priceScale}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          className={className}
          {...field}
          type="number"
          label="Price Scale"
          size="small"
          fullWidth
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onChange(e, field)}
          autoFocus={autoFocus}
          disabled={disabled}
          inputProps={{ step: 0.1 }}
        />
      )}
    />
  );
};
