import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import { validateEcoPlus } from '../../../../utils/validation/scenarioPieces/ecoPlusValidation';
import { EcoPlusScenarioPieceForm } from '../../../../pages/scenarioPiece/ecoPlus/ecoPlusScenarioPieceForm.component';
import { getCustomProductsQuoteSummaryData } from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getCustomProductsQuoteSummaryData';
import { ScenarioPieceType } from '@silveus/calculations';
import { createEcoPlusData } from '../../../../services/calculations/ecoPlus/ecoPlusDataTransformations';
import getFullyQualifiedScenarioPieceName from '../../defaultDefinitions/scenarioPieceNameDefaults';
import {
  ProtectionFactorScenarioPieceChip
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { ScenarioPiece } from '../../../../types/api/ScenarioPiece';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

export const ecoPlusDefinition: ScenarioPieceDefinition = {
  validationFunction: validateEcoPlus,
  d365ProductType: ProductType.ECO,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    return createEcoPlusData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <EcoPlusScenarioPieceForm {...props} selectedScenarioPieceType={props.selectedScenarioPieceType as ScenarioPieceType} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>} />;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getCustomProductsQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
    return getFullyQualifiedScenarioPieceName(scenarioPiece, true);
  },
  getScenarioPieceCardChips: [ProtectionFactorScenarioPieceChip],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#B22222',
};