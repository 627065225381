import { Card, CardContent, Grid, Typography } from '@mui/material';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import { ScenarioPieceGroupResponseDTO } from '@silveus/calculations';

type HailTotalCardProps = {
  hailTotals: ScenarioPieceGroupResponseDTO | undefined;
}

const HailTotalCard = ({ hailTotals }: HailTotalCardProps) => {
  return (
    <Card className="hail-card-box-shadow" sx={{ width: 600, height: '95px' }} raised>
      <CardContent>
        <Grid container direction="column">
          <Grid item xs={12} sx={{ height: '25px' }}>
            <Typography textAlign="center" variant="subtitle2" gutterBottom>Hail Totals</Typography>
          </Grid>
          <Grid container item xs alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Premium Per Acre</Typography>
            </Grid>
            <Grid item xs>
              <CurrencyText currencyAmount={(hailTotals?.totalPremiumPerAcre ?? 0) * -1} colorize={true} align="right" />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Indemnity Per Acre</Typography>
            </Grid>
            <Grid item xs>
              <CurrencyText currencyAmount={hailTotals?.indemnityPerAcre ?? 0} colorize={true} align="right" />
            </Grid>
          </Grid>

          <Grid container item xs alignItems="center" spacing={1} sx={{ pt: '0 !important' }}>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Total Premium</Typography>
            </Grid>
            <Grid item xs>
              <CurrencyText currencyAmount={(hailTotals?.totalPremium ?? 0) * -1} colorize={true} align="right" />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Total Indemnity</Typography>
            </Grid>
            <Grid item xs>
              <CurrencyText currencyAmount={hailTotals?.indemnityAmount ?? 0} colorize={true} align="right" />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  );
};

export default HailTotalCard;