import { Grid } from '@mui/material';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../types/util/Nullable';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import IsPercentOfProjectedInput from '../../../components/formInputs/scenarioPiece/isPercentOfProjectedInput.component';
import PercentOfProjectedInput from '../../../components/formInputs/scenarioPiece/percentOfProjectedInput.component';
import { LiabilityAmountInput } from '../../../components/formInputs/liabilityAmountInput.component';
import { RampUnitStructureInput } from '../../../components/formInputs/scenarioPiece/rampUnitStructureInput.component';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { fetchAvailableRampCoverageLevels } from '../../../app/privateProductSlice';
import { useEffect } from 'react';
import { PrivateProductUpperCoverageLevelInput } from '../../../components/formInputs/scenarioPiece/privateProductUpperCoverageLevelInput.component';
import { PrivateProductLowerCoverageLevelInput } from '../../../components/formInputs/scenarioPiece/privateProductLowerCoverageLevelInput.component';
import { Commodity } from '@silveus/calculations/dist/lookups';
import { FormProvider, SubmitHandler, useWatch } from 'react-hook-form';
import { selectScenarioById, updateScenario } from '../../../app/scenariosSlice';
import { maxLimitOfInsurance } from '../../../services/calculations/ramp/rampDataTransformations';
import { RowCropScenarioPieceExtendedFormFields } from '../rowCropScenarioPieceExtendedFormFields.component';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { createScenarioPiece, removeScenarioPieceAndRecalculate } from '../../../app/scenarioPiecesSlice';
import { ScenarioPieceType } from '@silveus/calculations';
import { Quote } from '../../../types/api/Quote';
import { ClientFile } from '../../../types/api/ClientFile';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { FormInputParameterNames } from '../../../components/formInputs/constants/inputConstants';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import { LowerCoverageLevelFields } from '../../../components/formInputs/scenarioPiece/lowerCoverageLevelInput.component';
import IndividualYieldInputGroup, { IndividualYieldInputGroupFields } from '../../../components/formInputs/scenario/individualYieldInputGroup.component';
import PriceInputGroup, { PriceInputGroupFields } from '../../../components/formInputs/scenario/priceInputGroup.component';
import { UnitStructureFields } from '../../../components/formInputs/scenarioPiece/unitStructureInput.component';
import {
  UpperCoverageLevelFields
} from '../../../components/formInputs/scenarioPiece/upperCoverageLevelInput.component';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import useYieldsForScenario from '../../../hooks/useYieldsForScenario';
import { mergeScenarioWithYieldAndPriceUpdates } from '../../../utils/scenarioUpdateUtils';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { useScenarioPieceForm } from '../useScenarioPieceForm';
import { ScenarioPieceFormSnapshot } from '../../../types/app/ScenarioPieceFormSnapshot';
import { ScenarioPiecePlanSelection, ScenarioPiecePlanSelectionFormValues } from '../scenarioPiecePlanSelection.component';
import { selectOfferAvailabilitiesForCountyCommodity } from '../../../app/availabilitySlice';
import { CustomPremiumInput } from '../../../components/formInputs/customPremiumInput.component';

interface RampScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  selectedScenarioPieceType: ScenarioPieceType;
  scenarioId: ScenarioId;
  year: number;
  countyId: string;
  commodityCode: string;
  typeId: string;
  practiceId: string;
  disabled: boolean;
  quote: Quote;
  clientFile: ClientFile;
  handleCancel: () => void;
  updateScenarioPieceFormSnapshot: (updatePlanSelection: boolean, newPlanSelection: ScenarioPieceType, scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null) => void;
  scenarioPieceFormSnapshot: ScenarioPieceFormSnapshot | null;
  scenarioPieceTypePlanSelectionList: ScenarioPieceType[];
}

export type RampScenarioPieceFormFields =
  & ScenarioPieceFormFields
  & UpperCoverageLevelFields
  & LowerCoverageLevelFields
  & UnitStructureFields
  & RowCropScenarioPieceExtendedFormFields
  & PriceInputGroupFields
  & IndividualYieldInputGroupFields;

const RampScenarioPieceForm = ({
  scenarioPiece,
  selectedScenarioPieceType,
  scenarioId,
  year: clientYear,
  countyId,
  commodityCode,
  typeId,
  practiceId,
  disabled,
  registerHeader,
  handleCancel,
  quote,
  handleValidation,
  updateScenarioPieceFormSnapshot,
  scenarioPieceFormSnapshot,
  scenarioPieceTypePlanSelectionList,
  isCanceling = false }: RampScenarioPieceFormProps) => {

  const dispatch = useAppDispatch();
  const methods = useScenarioPieceForm<RampScenarioPieceFormFields>(scenarioPiece);

  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  const pieceAvailabilities = useAppSelector(state => selectOfferAvailabilitiesForCountyCommodity(state, countyId, commodityCode));

  const rampAvailability = pieceAvailabilities.find(x => x.scenarioPieceType === selectedScenarioPieceType);
  const year = rampAvailability?.productOfferAvailabilities.at(0)?.cropYear ?? clientYear;

  const upperCoverageLevel: number | undefined = useWatch({ name: FormInputParameterNames.UpperCoverageLevelName, control: methods.control, defaultValue: undefined });
  const lowerCoverageLevel: number | undefined = useWatch({ name: FormInputParameterNames.LowerCoverageLevelName, control: methods.control, defaultValue: undefined });

  if (scenario === null) throw new Error('Attempting to calculate for scenario piece when the parent scenario does not exist in state');

  const useManualPremium: boolean = rampAvailability?.productOfferAvailabilities.at(0)?.extendedData?.rampUseManualPremium ?? false;

  const { approvedYield, rateYield, aphYield, adjustedYield } = useYieldsForScenario(scenarioId, quote.countyId, quote.commodityCode);
  const maxLimitOfLiability = maxLimitOfInsurance(commodityCode as Commodity, approvedYield ?? adjustedYield ?? 0, scenario.projectedPrice, upperCoverageLevel, lowerCoverageLevel);

  const formId = 'rampScenarioPieceForm';

  useEffect(() => {
    dispatch(fetchAvailableRampCoverageLevels({ year: year, countyId: countyId, commodityCode: commodityCode }));
  }, [scenarioPiece, scenarioId]);

  const onSubmit: SubmitHandler<RampScenarioPieceFormFields> = async data => {
    const planCode = getInsurancePlanCodeForScenarioPiece(selectedScenarioPieceType);
    updateScenarioPieceFormSnapshot(false, ScenarioPieceType.Unset, null);

    const newScenarioPiece: RowCropScenarioPiece = {
      isActive: data.isActive,
      isInvalid: false,
      lowerCoverageLevel: data.lowerCoverageLevel,
      planCode: scenarioPiece?.planCode ?? planCode,
      protectionFactor: 100,
      rowCropScenarioPieceExtendedData: data.rowCropScenarioPieceExtendedData,
      rowCropScenarioPieceId: scenarioPiece?.rowCropScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: selectedScenarioPieceType,
      unitStructure: data.unitStructure,
      upperCoverageLevel: data.upperCoverageLevel,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    await dispatch(updateScenario({
      updatedScenario: mergeScenarioWithYieldAndPriceUpdates(scenario, data),
    }));

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(createScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId, updatedScenarioPiece: newScenarioPiece }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  const getLatestFormValues = () => {
    const formValues = methods.getValues();
    const scenarioPieceFormValues: ScenarioPiecePlanSelectionFormValues = {
      ...formValues,
      protectionFactor: 100,
      expectedCountyYield: null,
      actualCountyYield: null,
    };
    return scenarioPieceFormValues;
  };

  useEffect(() => {
    let changeIt = false;
    if (scenarioPieceFormSnapshot?.scenarioPiece?.scenarioPieceType !== undefined &&
      scenarioPieceFormSnapshot.scenarioPiece.scenarioPieceType !== selectedScenarioPieceType) {
      changeIt = true;
    } else if (scenarioPiece !== null && scenarioPiece.scenarioPieceType !== selectedScenarioPieceType) {
      changeIt = true;
    }

    if (changeIt) {
      methods.setValue('scenarioPieceType', selectedScenarioPieceType, { shouldDirty: true });
    }
  }, [scenarioPieceFormSnapshot, selectedScenarioPieceType, scenarioPiece]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ScenarioPiecePlanSelection
          scenarioPieceFormSnapshot={scenarioPieceFormSnapshot}
          scenarioPiece={scenarioPiece}
          scenarioId={scenarioId}
          selectedScenarioPieceType={selectedScenarioPieceType}
          updateScenarioPieceFormSnapshot={updateScenarioPieceFormSnapshot}
          scenarioPieceTypePlanSelectionList={scenarioPieceTypePlanSelectionList}
          getScenarioPieceFormValues={getLatestFormValues}
        />
      </Grid>
      <Grid item xs={12}>
        <FormProvider {...methods}>
          <form id={formId} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <PrivateProductUpperCoverageLevelInput upperCoverageLevel={scenarioPiece?.upperCoverageLevel ?? null} disabled={disabled} />
              </Grid>
              <Grid item xs={6}>
                <PrivateProductLowerCoverageLevelInput lowerCoverageLevel={scenarioPiece?.lowerCoverageLevel ?? null} upperCoverageLevel={scenarioPiece?.upperCoverageLevel ?? null} disabled={disabled} />
              </Grid>
              <Grid item xs={6}>
                <IsPercentOfProjectedInput isPercentOfProjected={scenarioPiece?.rowCropScenarioPieceExtendedData?.isPercentOfProjected ?? false} disabled={disabled} />
              </Grid>
              <Grid item xs={6}>
                <PercentOfProjectedInput percentOfProjected={scenarioPiece?.rowCropScenarioPieceExtendedData?.percentOfProjected ?? 100} minValue={5} maxValue={100}
                  step={5} disabled={disabled || !(scenarioPiece?.rowCropScenarioPieceExtendedData?.isPercentOfProjected ?? false)} />
              </Grid>
              <Grid item xs={6}>
                <LiabilityAmountInput liabilityAmount={scenarioPiece?.rowCropScenarioPieceExtendedData?.liabilityAmount ?? null}
                  disabled={(scenarioPiece?.rowCropScenarioPieceExtendedData?.isPercentOfProjected ?? false) || disabled} max={maxLimitOfLiability} />
              </Grid>
              <Grid item xs={6}>
                <RampUnitStructureInput unitStructure={scenarioPiece?.unitStructure ?? null} disabled={disabled} />
              </Grid>
              {useManualPremium &&
                <Grid item xs={6}>
                  <CustomPremiumInput customPremium={scenarioPiece?.rowCropScenarioPieceExtendedData?.customPremium ?? null} label="Premium Per Acre" disabled={disabled} />
                </Grid>
              }
              <PriceInputGroup quote={quote} scenario={scenario} scenarioPiece={scenarioPiece} projectedPrice={scenario.projectedPrice} harvestPrice={scenario.harvestPrice} />
              <IndividualYieldInputGroup
                quote={quote}
                scenarioId={scenarioId}
                approvedYield={approvedYield}
                producerYield={scenario.actualProducerYield}
                rateYield={rateYield}
                aphYield={aphYield} />
              <Grid item xs={12}>
                <IsActiveInput isActive={scenarioPiece?.isActive ?? null} />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
      </Grid>
    </Grid>
  );
};

export default RampScenarioPieceForm;
