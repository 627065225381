import { Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CoverageValidationResult } from './coverageDecisionValidation';

type CoverageDecisionValidationDialogProps = {
  errors: CoverageValidationResult[];
  onClose: () => void;
}

const CoverageDecisionValidationDialog = ({ onClose, errors }: CoverageDecisionValidationDialogProps) => {

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth={false}
      disableEscapeKeyDown={false}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
            height: '600px',
          },
        },
      }}
    >
      <DialogTitle sx={{ p: '0 !important' }}>
        <Grid container p={1} sx={{ alignItems: 'center', backgroundColor: theme => theme.palette.warning.light }}>
          <Grid item xs><Typography sx={{ color: theme => theme.palette.common.white }} variant="h6">Errors</Typography></Grid>
          <Grid item>
            <IconButton
              onClick={onClose}
              sx={{ p: 0, color: theme => theme.palette.common.white }}
              size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 1.5, flexDirection: 'column', overflow: 'auto' }}>
        <Grid container p={1.5} pt={2} rowGap={1.5}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Validation Error: Multiple Issues Stopped Process</Typography>
            <Typography variant="body2">Please review the entered information and address the following issues to proceed with the validation process. Contact support for further assistance if needed.</Typography>
          </Grid>
          <Grid item xs={12}>
            <List>
              {errors.map((error, index) => {
                return (
                  <ListItem key={index} alignItems="flex-start">
                    <ListItemAvatar>
                      <Typography variant="subtitle1">{index + 1}.</Typography>
                    </ListItemAvatar>
                    <ListItemText
                      primary={error.title}
                      secondary={
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {error.message}
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog >
  );
};

export default CoverageDecisionValidationDialog;