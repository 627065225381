import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferActualCountyYield } from '../../../app/admSlice';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import ActualCountyYieldInput, { ActualCountyYieldProps } from '../scenario/actualCountyYieldInput';
import { LoadFromStateProps } from '../scenario/loadFromStateProps';
import { Nullable } from '../../../types/util/Nullable';

interface ScenarioPieceActualCountyYieldProps extends ActualCountyYieldProps, LoadFromStateProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>,
}

const ScenarioPieceActualCountyYieldInput = ({ scenarioPiece, year, countyId, typeId, practiceId, actualCountyYield, coverageTypeCode,
  coverageLevel, volatility,
  commodityCode, insurancePlanCodes, sx, label, autoFocus = false, disabled, loadFromState = false }:
  ScenarioPieceActualCountyYieldProps) => {
  const { setValue, trigger } = useFormContext();
  const offerActualCountyYield = useAppSelector(selectOfferActualCountyYield);

  useEffect(() => {
    // This updates the yield if the scenario piece does not exist yet (the scenario piece will be new if saved).
    // This should not be necessary in most cases. It handles cases where the insurance plan associated with the
    // scenario piece has a different yield than the yield used by default on the scenario.
    // Note: This also requires something (like the scenario piece form) to refetch the prices and yields based on the potentially new insurance plan code
    if (scenarioPiece !== null || offerActualCountyYield === null || !loadFromState) return;

    setValue('actualCountyYield', offerActualCountyYield);
    trigger('actualCountyYield');
  }, [offerActualCountyYield]);

  return (
    <ActualCountyYieldInput
      actualCountyYield={actualCountyYield}
      year={year}
      countyId={countyId}
      practiceId={practiceId}
      volatility={volatility}
      coverageLevel={coverageLevel}
      sx={sx}
      commodityCode={commodityCode}
      coverageTypeCode={coverageTypeCode}
      label={label}
      autoFocus={autoFocus}
      disabled={disabled}
      typeId={typeId}
      insurancePlanCodes={insurancePlanCodes} />
  );
};

export default ScenarioPieceActualCountyYieldInput;
