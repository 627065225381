import { ColDef } from 'ag-grid-community';
import { ADMYear } from '../../types/api/adm/ADMYear';
import { ApplicationWizardId } from '../../types/api/PrimaryKeys';
import { AppTaskExtended } from '../../types/api/AppTask';
import { ApplicationWizardSummary } from '../../types/api/applicationWizard/applicationWizard';
import { isNullOrUndefined } from '../../utils/nullHandling';
import { ApplicationStatusTypeAttributes } from '../../types/api/enums/application/applicationStatusType.enum';
import { Na } from '../../types/util/Na';
import { getCountiesList, getCropList, getStatesList } from '../../utils/applications/appWizardUtils';

type GetAppWizardColumnDefinitionsProps = {
  admData: ADMYear[];
  appTasksByAppWizardId: Map<ApplicationWizardId, AppTaskExtended[]>;
}

export const getAppWizardColumnDefinitions = ({ admData, appTasksByAppWizardId }: GetAppWizardColumnDefinitionsProps) => {
  const columnDefinitions: ColDef<ApplicationWizardSummary>[] = [
    {
      colId: 'states',
      headerName: 'States',
      checkboxSelection: true,
      field: 'states',
      valueGetter: params => {
        const { data } = params;
        if (isNullOrUndefined(data)) return '';
        const states = getStatesList(data, admData);
        if (states.length === 0) {
          return Na;
        }
        return [...new Set(states)].sort((a, b) => a.localeCompare(b)).join(' , ');
      },
      tooltipValueGetter: params => {
        const { data } = params;
        if (isNullOrUndefined(data)) return null;

        const states = getStatesList(data, admData);
        if (states.length === 0) {
          return 'No coverages selected on coverage decisions step yet.';
        }
        return [...new Set(states)].sort((a, b) => a.localeCompare(b)).join(' , ');
      },
    },
    {
      colId: 'counties',
      headerName: 'Counties',
      field: 'counties',
      valueGetter: params => {
        const { data } = params;
        if (isNullOrUndefined(data)) return '';

        const counties = getCountiesList(data, admData);
        if (counties.length === 0) {
          return Na;
        }
        return [...new Set(counties)].sort((a, b) => a.localeCompare(b)).join(' , ');
      },
      tooltipValueGetter: params => {
        const { data } = params;
        if (isNullOrUndefined(data)) return '';

        const counties = getCountiesList(data, admData);
        if (counties.length === 0) {
          return 'No coverages selected on coverage decisions step yet.';
        }
        return [...new Set(counties)].sort((a, b) => a.localeCompare(b)).join(' , ');
      },
    },
    {
      colId: 'crops',
      headerName: 'Crops',
      field: 'crops',
      valueGetter: params => {
        const { data } = params;
        if (isNullOrUndefined(data)) return '';

        const crops = getCropList(data, admData);
        if (crops.length === 0) {
          return Na;
        }
        return [...new Set(crops)].sort((a, b) => a.localeCompare(b)).join(' , ');
      },
      tooltipValueGetter: params => {
        const { data } = params;
        if (isNullOrUndefined(data)) return '';

        const crops = getCropList(data, admData);
        if (crops.length === 0) {
          return 'No coverages selected on coverage decisions step yet.';
        }
        return [...new Set(crops)].sort((a, b) => a.localeCompare(b)).join(' , ');
      },
    },
    {
      colId: 'status',
      headerName: 'Status',
      width: 195,
      field: 'applicationWizardStatusType',
      valueFormatter: params => {
        const { data } = params;
        const type = data?.applicationWizardStatusType;
        if (isNullOrUndefined(type)) return '';
        return ApplicationStatusTypeAttributes[type].name;
      },
    },
    {
      colId: 'modifiedOn',
      headerName: 'Modified Date',
      field: 'lastUpdatedTime',
      width: 215,
      filter: 'agDateColumnFilter',
      flex: 0,
      sort: 'desc',
      initialSortIndex: 1,
      valueFormatter: params => {
        const { value } = params;
        return isNullOrUndefined(value)
          ? ''
          : `${new Date(value).toLocaleDateString()} ${new Date(value).toLocaleTimeString()}`;
      },
    },
  ];

  return columnDefinitions;
};

export const DefaultAppWizardColDefinition: ColDef = {
  resizable: false,
  sortable: false,
  editable: false,
  filter: false,
  hide: false,
  lockVisible: true,
  suppressNavigable: true,
  minWidth: 50,
  flex: 1,
};
