import { HeatMapData, MatrixHeatMapDataProvider } from '@silveus/react-matrix';
import { selectMatrixHeatMapDataForScenarios } from '../../../../app/matrixHeatMapDataSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { convertProbabilityToHeatValue } from '../../../../utils/matrixUtils';
import { useMemo } from 'react';
import { MatrixHeatMapTooltip } from '../matrixHeatMapTooltip.component';
import { getMatrixHeatMappingPriceProbabilityPercentValue } from '@silveus/calculations';
import { ScenarioId } from '../../../../types/api/PrimaryKeys';

export const useMatrixHeatMap = (includedScenarioIds: readonly ScenarioId[]) => {
  const heatMapData = useAppSelector(s => selectMatrixHeatMapDataForScenarios(s, includedScenarioIds));

  const heatMapDataProvider = useMemo<MatrixHeatMapDataProvider | undefined>(() => {
    if (heatMapData === null) { return undefined; }

    const { lastPrice, impliedVolatility } = heatMapData.quotePriceData;

    if (lastPrice === null || impliedVolatility === null) { return undefined; }

    return yAxisValues => {
      const response: HeatMapData[] = yAxisValues.map(matrixPrice => {

        // This is the "SalesTools" probability, which is used to power the "percent above" / "percent below" text in the tooltips.
        const rawProbability = getMatrixHeatMappingPriceProbabilityPercentValue(matrixPrice, lastPrice, impliedVolatility, heatMapData.daysUntilHarvestDiscoveryEnd);

        // This does a simple conversion of the probability into the format the heat map API expects in order to color its cells correctly.
        const heatMapProbability = convertProbabilityToHeatValue(rawProbability);

        // probability given is at most 50%; when the matrixPrice is LESS than the last price we are told the odds that the
        // price will be at or BELOW that. subtract that probability from 1 to get the 'above' percentage.
        const percentChanceAbove = matrixPrice < lastPrice ? 1 - rawProbability : rawProbability;

        return {
          probability: heatMapProbability,
          renderTooltip: () =>
            <MatrixHeatMapTooltip
              matrixPrice={matrixPrice}
              percentChanceAbove={percentChanceAbove}
              currentPrice={lastPrice}
              volatility={impliedVolatility}
              daysUntilHarvestDiscoveryEnd={heatMapData.daysUntilHarvestDiscoveryEnd}
            />,
        };
      });

      return response;
    };
  }, [heatMapData]);

  return {
    heatMapDataProvider: heatMapDataProvider,
  };
};