import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import { MPowerDScenarioPieceForm } from '../../../../pages/scenarioPiece/mPowerD/mPowerDScenarioPieceForm.component';
import { getCustomProductsQuoteSummaryData } from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getCustomProductsQuoteSummaryData';
import { createMPowerDData } from '../../../../services/calculations/mPowerD/mPowerDDataTransformation';
import { getFullyQualifiedScenarioPieceNameOnlyCoverageLevel } from '../../defaultDefinitions/scenarioPieceNameDefaults';
import { scenarioPieceCardChipDefault } from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { getHistoricalQuoterColumnConfigs, getHistoricalQuoterIntervalColumnsForMarginProduct } from '../../../../utils/intervals/historicalQuoterIntervals';
import { validateMPowerD } from '../../../../utils/validation/scenarioPieces/mPowerDValidation';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

export const mPowerDDefinition: ScenarioPieceDefinition = {
  validationFunction: validateMPowerD,
  d365ProductType: ProductType.MPowerD,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    return createMPowerDData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <MPowerDScenarioPieceForm {...props} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>} />;
  },
  getHistoricalQuoterColumns: params => getHistoricalQuoterIntervalColumnsForMarginProduct(params),
  getHistoricalQuoterColumnConfigs: params => getHistoricalQuoterColumnConfigs(params),
  createInputForQuoteSummaryFunction: getCustomProductsQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceNameOnlyCoverageLevel,
  getScenarioPieceCardChips: scenarioPieceCardChipDefault,
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#9370DB',
};