import { QuoteSummaryReportData } from '../../types/api/reports/QuoteSummaryReportData';
import {
  createActualProductionReport, createMatrixReport,
  createPremiumBreakdownReport,
  createQuoteSummaryReport,
  createTriggerYieldReport
} from '../reports.service';
import { networkOnly } from '../../utils/cachingStrategies';
import { ActualProductionReportData } from '../../types/api/reports/ActualProductionReportData';
import { TriggerYieldReportData } from '../../types/api/reports/TriggerYieldReportData';
import { PremiumBreakdownReportData } from '../../types/api/reports/PremiumBreakdownReportData';
import { MatrixReportData } from '../../types/api/reports/MatrixReportData';

export const generateQuoteSummaryReportRequest = async (quoteSummaryReportData: QuoteSummaryReportData): Promise<string> => {
  const request = () => createQuoteSummaryReport(quoteSummaryReportData);
  return await networkOnly(request);
};

export const generateActualProductionReportRequest = async (actualProductionReportData: ActualProductionReportData): Promise<string> => {
  const request = () => createActualProductionReport(actualProductionReportData);
  return await networkOnly(request);
};

export const generateTriggerYieldReportRequest = async (triggerYieldReportData: TriggerYieldReportData): Promise<string> => {
  const request = () => createTriggerYieldReport(triggerYieldReportData);
  return await networkOnly(request);
};

export const generatePremiumBreakdownReportRequest = async (premiumBreakdownReportData: PremiumBreakdownReportData): Promise<string> => {
  const request = () => createPremiumBreakdownReport(premiumBreakdownReportData);
  return await networkOnly(request);
};

export const generateMatrixReportRequest = async (matrixReportData: MatrixReportData): Promise<string> => {
  const request = () => createMatrixReport(matrixReportData);
  return await networkOnly(request);
};