import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { validateInsuredTaxId } from '../../ag-grid/contactInfoGrid/validation/validateContactInfo';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import TaxIdMask from './taxIdMask';

type InsuredTaxIdInputProps = {
  value: Nullable<string>;
  disabled: boolean;
  onEditClick?: () => void;
  masked?: boolean;
  isFirstSsnInput?: boolean;
}

export const TaxIdInputTooltip = 'Please use the edit button to edit the Tax ID';
export type InsuredTaxIdInputFields = {
  taxId: Nullable<string>;
};

// Allow only numeric keys and control keys
const allowedKeys = new Set(['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter', ...Array.from({ length: 10 }, (_, i) => i.toString())]);

const InsuredTaxIdInput = ({ value, disabled, onEditClick, masked = true, isFirstSsnInput = false }: InsuredTaxIdInputProps) => {
  const { control } = useFormContext();
  // Numeric input won't work with masking so input has to be a mui text field. Because of that
  // we need to handle our own keydown event here and make sure user is only able to enter numbers.
  // This is actually better than using the numeric input because in numeric input you can enter things
  // like - or . or even 'e'.
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!allowedKeys.has(event.key)) {
      event.preventDefault();
    }
  };

  const shouldSsnBeMasked = isFirstSsnInput === true ? false : masked;

  return (
    <Controller
      name="taxId"
      control={control}
      rules={{ validate: val => validateInsuredTaxId(val) }}
      defaultValue={value ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <Tooltip title={disabled && onEditClick ? TaxIdInputTooltip : ''}>
          <TextField
            onKeyDown={handleKeyDown}
            label="Tax ID"
            fullWidth
            disabled={disabled}
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            {...field}
            size="small"
            InputProps={{
              inputComponent: shouldSsnBeMasked ? TaxIdMask : undefined,
              endAdornment: (onEditClick && disabled &&
                <InputAdornment position="end">
                  <IconButton onClick={onEditClick} edge="end"><Edit /></IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      )}
    />
  );
};

export default InsuredTaxIdInput;

