import { CssBaseline, Grid, IconButton, Toolbar, useTheme } from '@mui/material';
import { Suspense, useEffect, useState } from 'react';
import { matchPath, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ROUTES from './routes';
import NavigationMenu from './navigationMenu.component';
import NavigationBreadcrumbs from './navigationBreadcrumbs.component';
import Weather from '../weather/weather.component';
import UserIcon from '../user/userIcon.component';
import { selectCurrentInsured } from '../../app/insuredsSlice';
import { openConfirm } from '../../app/confirmSlice';
import { selectCurrentClientFile } from '../../app/clientFilesSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../utils/nullHandling';
import AppDrawer from '../appDrawer/appDrawer.component';
import SlidingDrawer from '../slidingDrawer/slidingDrawer.component';
import SlidingAppBar from '../slidingAppBar/slidingAppBar.component';
import { selectAppDrawerOpen, selectAppDrawerWidth } from '../../app/appDrawerSlice';
import NetworkToggle from '../../components/networkToggle/networkToggle.component';
import { selectApplicationsModalOpen } from '../../app/applicationsSlice';
import ApplicationsModal from '../../pages/applicationsModal/applicationsModal';
import { selectIsLightMode } from '../../app/userSettingsSlice';
import { openSupportModal, selectSupportModalOpen } from '../../app/supportSlice';
import { SupportModal } from '../../pages/supportModal/supportModal';
import { selectCurrentAgentTeam } from '../../app/agentTeamsSlice';
import { ReactComponent as SilveusInsuranceIcon } from '../../assets/sig-s.svg';

const maxBarHeight = 48;
const maxNavBarWidth = 240;
const minNavBarWidth = 60;
const appBarHeight = 48;

const Navigation = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [navOpen, setNavOpen] = useState(false);
  const navigate = useNavigate();
  const insured = useAppSelector(selectCurrentInsured);
  const clientFile = useAppSelector(selectCurrentClientFile);
  const agentTeam = useAppSelector(selectCurrentAgentTeam);
  const isAppDrawerOpen = useAppSelector(selectAppDrawerOpen);
  const appDrawerWidth = useAppSelector(selectAppDrawerWidth);
  const routes = ROUTES(insured, clientFile, agentTeam);
  const isInInsuredOrAgentTeam = !!matchPath('/insureds/:insuredId/*', location.pathname) || !!matchPath('/agent-teams/:agentTeamId/*', location.pathname);
  const theme = useTheme();
  const isLightMode = useAppSelector(selectIsLightMode);

  const applicationsModalOpen = useAppSelector(selectApplicationsModalOpen);
  const supportModalOpen = useAppSelector(selectSupportModalOpen);

  const clientFileOwnerClick = (navigationPath: string) => {
    const isInsuredsBasePath = navigationPath.includes(`insureds/${insured?.id}`);
    isInInsuredOrAgentTeam && !isInsuredsBasePath ? confirmLeave(navigationPath) : navigate(navigationPath);
  };

  const navigationClick = (path: string) => {
    navigate(path);
  };

  const confirmLeave = (path: string) => {
    if (isNullOrUndefined(insured) && isNullOrUndefined(agentTeam)) return;

    const ownerType = isNotNullOrUndefined(insured)
      ? 'insured'
      : 'agent team';

    const name = insured?.name ?? agentTeam?.agentTeamName ?? '';

    const confirmWindow = {
      title: `Exit the ${ownerType}?`,
      cancelText: 'No',
      confirmText: 'Yes',
      message: `Are you sure you want to exit the ${ownerType} (${name})?`,
      onConfirm: () => navigate(path),
    };

    dispatch(openConfirm(confirmWindow));
  };

  // Listen for keydown events on mount
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'KeyH' && event.altKey && event.ctrlKey && event.shiftKey) {
        dispatch(openSupportModal());
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return <>
    <CssBaseline />
    <Grid container>
      <Grid item xs={12} sx={{ height: `${appBarHeight}px` }}>
        <SlidingAppBar sx={{ maxHeight: `${maxBarHeight}px` }}>
          <Toolbar variant="dense">
            <Grid container alignItems={'center'} sx={{ maxHeight: `${maxBarHeight}px` }}>
              <Grid item xs={'auto'}>
                <IconButton
                  onClick={() => setNavOpen(prevNavOpen => !prevNavOpen)}
                  size="large"
                  edge="start"
                  aria-label="menu"
                  sx={{ ml: -2.25, fill: theme => theme.palette.primary.main }}
                >
                  <SilveusInsuranceIcon fill="inherit" width={24} height={24} />
                </IconButton>
              </Grid>
              <Grid item xs>
                <NavigationBreadcrumbs clientFileOwnerClick={clientFileOwnerClick} routes={routes} />
              </Grid>
              <Grid item xs={'auto'} whiteSpace={'nowrap'}>
                <Weather />
              </Grid>
              <Grid item xs="auto">
                <NetworkToggle />
              </Grid>
              <Grid item xs={'auto'}>
                <UserIcon />
              </Grid>
            </Grid>
          </Toolbar>
        </SlidingAppBar>
      </Grid>
      <Grid container sx={{ height: `calc(100vh - ${appBarHeight}px)` }}>
        <Grid item sx={{ height: '100%' }}>
          <SlidingDrawer variant="permanent" open={navOpen} minWidth={minNavBarWidth} maxWidth={maxNavBarWidth} >
            <Toolbar variant="dense" />
            <Grid item xs sx={{ backgroundColor: isLightMode ? theme.palette.neutral.light : theme.palette.neutral.dark }} >
              <NavigationMenu clientFileOwnerClick={clientFileOwnerClick}
                navigationClick={navigationClick} />
            </Grid>
          </SlidingDrawer>
        </Grid>
        <Grid item xs component="main" padding={1} paddingTop={1} style={{ height: '100%', width: `calc(100% - ${navOpen ? maxNavBarWidth : minNavBarWidth}px - ${isAppDrawerOpen ? `${appDrawerWidth}px` : '0px'})` }} >
          <Suspense fallback="">
            <Routes>
              {routes.map(r => <Route key={r.path} path={r.path} element={r.element} />)}
            </Routes>
          </Suspense>
        </Grid>
        <Grid item xs="auto">
          <AppDrawer />
        </Grid>
        {applicationsModalOpen && (
          <ApplicationsModal />
        )}
        {supportModalOpen && (
          <SupportModal />
        )}
      </Grid>
    </Grid>
  </>;
};

export default Navigation;

