import { quoterDb } from '../../db';
import { ClientFileId, ScenarioId, ScenarioPieceId } from '../../types/api/PrimaryKeys';
import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import {
  addScenarioPiece, deleteRowCropScenarioPiece,
  getScenarioPiece,
  getScenarioPiecesForClientFile,
  getScenarioPiecesForScenario, getScenarioPiecesForScenarios, updateScenarioPiece
} from '../scenarioPieces.service';
import {
  addSingle,
  getMultiple,
  getSingle,
  update, getDefaultCachingStrategy
} from '../offlineDataAccess.service';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { applySoftDelete } from '../../utils/dexieQueryHelpers/softDelete';
import { isOnlineFromStore } from '../../utils/isOnlineFromStore';
import { nonGetCacheOnly, nonGetNetworkOnly } from '../../utils/cachingStrategies';

const table = quoterDb.rowCropScenarioPieces;

export const getScenarioPiecesForScenarioRequest = async (scenarioId: ScenarioId): Promise<RowCropScenarioPiece[]> => {
  const request = () => getScenarioPiecesForScenario(scenarioId);
  return await getMultiple(table, { scenarioId: scenarioId }, request);
};

export const getScenarioPiecesForClientFileRequest = async (clientFileId: ClientFileId): Promise<RowCropScenarioPiece[]> => {
  const request = () => getScenarioPiecesForClientFile(clientFileId);

  const transactionTables = [quoterDb.quotes, quoterDb.rowCropScenarios, quoterDb.rowCropScenarioPieces];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const quotes = await safeWhere(quoterDb.quotes, { clientFileId: clientFileId }).toArray();
    const quoteIds = quotes.map(q => q.quoteId);

    const scenarios = await safeWhere(quoterDb.rowCropScenarios, 'quoteId').anyOf(quoteIds).toArray();
    const scenarioIds = scenarios.map(scen => scen.scenarioId);

    const scenarioPieces = safeWhere(quoterDb.rowCropScenarioPieces, 'scenarioId').anyOf(scenarioIds).toArray();
    return scenarioPieces;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getScenarioPiecesForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<RowCropScenarioPiece[]> => {
  const request = () => getScenarioPiecesForScenarios(scenarioIds);

  const readTransaction = () => quoterDb.transaction('r', quoterDb.rowCropScenarioPieces, async () => {
    return safeWhere(quoterDb.rowCropScenarioPieces, 'scenarioId').anyOf(scenarioIds).toArray();
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getScenarioPieceRequest = async (scenarioPieceId: ScenarioPieceId): Promise<RowCropScenarioPiece> => {
  const request = () => getScenarioPiece(scenarioPieceId);
  return await getSingle(table, { scenarioPieceId: scenarioPieceId }, request);
};

export const createScenarioPieceRequest = async (scenarioPiece: RowCropScenarioPiece): Promise<CreatedItemResult<ScenarioPieceId>> => {
  const request = () => addScenarioPiece(scenarioPiece);
  return await addSingle(table, scenarioPiece, request);
};

export const updateScenarioPieceRequest = async (scenarioPiece: RowCropScenarioPiece): Promise<void> => {
  const request = () => updateScenarioPiece(scenarioPiece);
  return await update(table, scenarioPiece, request);
};

export const deleteRowCropScenarioPieceRequest = async (scenarioPieceId: ScenarioPieceId): Promise<void> => {
  const request = () => deleteRowCropScenarioPiece(scenarioPieceId);

  const transactionTables = [
    quoterDb.rowCropScenarioPieces,
    quoterDb.unitGroups,
  ];

  const updateTransaction = () => quoterDb.transaction('rw', transactionTables, async () => {
    await deleteRowCropScenarioPiecesLocally([scenarioPieceId]);
  });

  const strategy = isOnlineFromStore() ? nonGetNetworkOnly : nonGetCacheOnly;
  return await strategy(request, updateTransaction);
};

export const deleteRowCropScenarioPiecesLocally = async (scenarioPieceIds: ScenarioPieceId[]): Promise<void> => {
  const scenarioPieces = safeWhere(quoterDb.rowCropScenarioPieces, 'scenarioPieceId').anyOf(scenarioPieceIds);

  const unitGroups = safeWhere(quoterDb.unitGroups, 'scenarioPieceId').anyOf(scenarioPieceIds);

  const softDeleteUnitGroups = applySoftDelete(unitGroups);
  const softDeleteScenarioPieces = applySoftDelete(scenarioPieces);

  await Promise.all([softDeleteUnitGroups, softDeleteScenarioPieces]);
};
