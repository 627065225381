import { useEffect } from 'react';
import { fetchAvailableInsuranceOfferSelectionsForPlan } from '../app/admSlice';
import { useAppDispatch } from './reduxHooks';
import { ScenarioPiece } from '../types/api/ScenarioPiece';
import { ScenarioId } from '../types/api/PrimaryKeys';
import { Nullable } from '../types/util/Nullable';
import { CoverageTypeCode, HighRiskType, ScenarioPieceType } from '@silveus/calculations';

export function useFederalOfferData(scenarioPiece: Nullable<ScenarioPiece>, scenarioId: ScenarioId, scenarioPieceType: ScenarioPieceType, year: number, countyId: string, typeId: string, practiceId: string, highRiskTypeId: HighRiskType, isCat: boolean = false) {
  const dispatch = useAppDispatch();

  const coverageTypeCode = isCat ? CoverageTypeCode.C : CoverageTypeCode.A;

  useEffect(() => {
    dispatch(fetchAvailableInsuranceOfferSelectionsForPlan({ year: year, countyId: countyId, typeId: typeId, practiceId: practiceId, scenarioPieceType: scenarioPieceType, coverageTypeCode: coverageTypeCode, highRiskTypeId }));
  }, [scenarioPiece, scenarioId, scenarioPieceType, isCat]);
}
