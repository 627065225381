import Grid from '@mui/material/Grid';
import CurrencyText from '../currencyText/currencyText.component';
import { ToggleableTypography } from '../toggleableTypography/toggleableTypography';

interface PayoutGridProps {
  totalPremium: number;
  totalIndemnity: number;
  totalFixed: number;
  totalSimulated: number;
  premiumPerAcre: number;
  indemnityPerAcre: number;
  totalDiscount: number;
  discountPerAcre: number;
  fixedPerAcre: number;
  simulatedPerAcre: number;
  isReadonly: boolean;
  showDiscount?: boolean;
}

const PayoutGrid = ({ totalPremium, totalIndemnity, premiumPerAcre, indemnityPerAcre, totalFixed, totalSimulated, fixedPerAcre, simulatedPerAcre, totalDiscount, discountPerAcre, isReadonly = false, showDiscount = false }: PayoutGridProps) => {
  const modifiedTotalPremium = -totalPremium - totalDiscount;
  const modifiedPremiumPerAcre = -premiumPerAcre - discountPerAcre;
  const totalNetRevenue = totalIndemnity + totalSimulated;
  const totalNetRevenuePerAcre = indemnityPerAcre + simulatedPerAcre;
  const totalNetCost = -(totalPremium + totalFixed);
  const totalNetCostPerAcre = -(premiumPerAcre + fixedPerAcre);

  return (
    <Grid container columnSpacing={2} rowSpacing={0.5}>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}><ToggleableTypography disabled={isReadonly} variant="label" align="right">Per Acre</ToggleableTypography></Grid>
      <Grid item xs={4}><ToggleableTypography disabled={isReadonly} variant="label" align="right">Total</ToggleableTypography></Grid>
      <Grid item xs={4} sx={{ pl: 0 }}><ToggleableTypography disabled={isReadonly} variant="label">Indemnity</ToggleableTypography></Grid>
      <Grid item xs={4}><CurrencyText disabled={isReadonly} currencyAmount={indemnityPerAcre} colorize align={'right'} /></Grid>
      <Grid item xs={4}><CurrencyText disabled={isReadonly} currencyAmount={totalIndemnity} colorize align={'right'} /></Grid>
      <Grid item xs={4} sx={{ pl: 0 }}><ToggleableTypography disabled={isReadonly} variant="label">Premium</ToggleableTypography></Grid>
      <Grid item xs={4}><CurrencyText disabled={isReadonly} currencyAmount={modifiedPremiumPerAcre} colorize align={'right'} /></Grid>
      <Grid item xs={4}><CurrencyText disabled={isReadonly} currencyAmount={modifiedTotalPremium} colorize align={'right'} /></Grid>
      {showDiscount &&
      <>
        <Grid item xs={4}><ToggleableTypography disabled={isReadonly} variant="label">MP Discount</ToggleableTypography></Grid>
        <Grid item xs={4}><CurrencyText disabled={isReadonly} currencyAmount={discountPerAcre} colorize align={'right'} /></Grid>
        <Grid item xs={4}><CurrencyText disabled={isReadonly} currencyAmount={totalDiscount} colorize align={'right'} /></Grid>
      </>
      }
      <Grid item xs={4}><ToggleableTypography disabled={isReadonly} variant="label">Net</ToggleableTypography></Grid>
      <Grid item xs={4}><CurrencyText disabled={isReadonly} currencyAmount={totalNetRevenuePerAcre + totalNetCostPerAcre} colorize align={'right'} /></Grid>
      <Grid item xs={4}><CurrencyText disabled={isReadonly} currencyAmount={totalNetRevenue + totalNetCost} colorize align={'right'} /></Grid>
    </Grid>
  );
};

export default PayoutGrid;