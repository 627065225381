import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { validatePreviousPolicy } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import { Nullable } from '../../../types/util/Nullable';
import { TextField } from '@mui/material';
import { AppTask } from '../../../types/api/AppTask';
import { handleValidationResult } from './validationHelpers';
import { AppType } from '../../../types/api/enums/application/AppType.enum';
import { useEffect } from 'react';

type PreviousPolicyInputProps = {
  value: Nullable<string>;
  index: number;
  canEditAppTask: boolean;
}

const PreviousPolicyInput = ({ value, index, canEditAppTask }: PreviousPolicyInputProps) => {
  const inputName = `appTasks.${index}.previousPolicyNumber`;
  const { control, resetField } = useFormContext();
  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });

  useEffect(() => {
    if (!(currentAppTask.isNewCT ?? false) && currentAppTask.appType !== AppType.CancelTransfer) {
      resetField(inputName, { defaultValue: '' });
    }
  }, [currentAppTask.isNewCT]);

  const shouldShow = (currentAppTask.isNewCT ?? false) || currentAppTask.appType === AppType.CancelTransfer;
  if (!shouldShow) {
    return null;
  }

  return (
    <Controller
      name={inputName}
      control={control}
      rules={{ validate: _ => handleValidationResult(validatePreviousPolicy(currentAppTask)) }}
      defaultValue={value ?? ''}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          fullWidth
          error={!!error}
          disabled={!(currentAppTask.isNewCT ?? false) || !canEditAppTask}
          inputRef={ref}
          {...field}
          size="small"
          inputProps={{
            sx: { fontSize: '14px !important' },
          }}
        />
      )}
    />
  );
};

export default PreviousPolicyInput;