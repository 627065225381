import { IEnumAttributes } from '../IEnumAttributes';

export enum AppTaskStatus {
  Approved = 100000005,
  AwaitingSignature = 100000013,
  ClaimedByCSR = 100000003,
  Generated = 100000012,
  KeyingCorrection = 100000007,
  NotGenerated = 100000011,
  NotSubmitted = 100000001,
  ReturnToAgent = 100000010,
  ReturnToCSR = 100000008,
  ReturnToSubmitter = 100000004,
  Revise = 100000016,
  RevisionNeeded = 100000006,
  SignatureDeclined = 100000014,
  Signed = 100000015,
  SubmitToASR = 100000009,
  SubmitToProcessing = 100000002,
  Verified = 100000000
}

export const AppTaskStatusAttributes: Readonly<Record<AppTaskStatus, IEnumAttributes<AppTaskStatus>>> = {
  [AppTaskStatus.Approved]: { name: 'Approved', description: 'Approved', value: AppTaskStatus.Approved },
  [AppTaskStatus.AwaitingSignature]: { name: 'Awaiting Signature', description: 'Awaiting Signature', value: AppTaskStatus.AwaitingSignature },
  [AppTaskStatus.ClaimedByCSR]: { name: 'Claimed By CSR', description: 'Claimed By CSR', value: AppTaskStatus.ClaimedByCSR },
  [AppTaskStatus.Generated]: { name: 'Generated', description: 'Generated', value: AppTaskStatus.Generated },
  [AppTaskStatus.KeyingCorrection]: { name: 'Keying Correction', description: 'Keying Correction', value: AppTaskStatus.KeyingCorrection },
  [AppTaskStatus.NotGenerated]: { name: 'Not Generated', description: 'Not Generated', value: AppTaskStatus.NotGenerated },
  [AppTaskStatus.NotSubmitted]: { name: 'Not Submitted', description: 'Not Submitted', value: AppTaskStatus.NotSubmitted },
  [AppTaskStatus.ReturnToAgent]: { name: 'Return To Agent', description: 'Return To Agent', value: AppTaskStatus.ReturnToAgent },
  [AppTaskStatus.ReturnToCSR]: { name: 'Return To CSR', description: 'Return To CSR', value: AppTaskStatus.ReturnToCSR },
  [AppTaskStatus.ReturnToSubmitter]: { name: 'Return To Submitter', description: 'Return To Submitter', value: AppTaskStatus.ReturnToSubmitter },
  [AppTaskStatus.Revise]: { name: 'Revise', description: 'Revise', value: AppTaskStatus.Revise },
  [AppTaskStatus.RevisionNeeded]: { name: 'Revision Needed', description: 'Revision Needed', value: AppTaskStatus.RevisionNeeded },
  [AppTaskStatus.SignatureDeclined]: { name: 'Signature Declined', description: 'Signature Declined', value: AppTaskStatus.SignatureDeclined },
  [AppTaskStatus.Signed]: { name: 'Signed', description: 'Signed', value: AppTaskStatus.Signed },
  [AppTaskStatus.SubmitToASR]: { name: 'Submit To ASR', description: 'Submit To ASR', value: AppTaskStatus.SubmitToASR },
  [AppTaskStatus.SubmitToProcessing]: { name: 'Submit To Processing', description: 'Submit To Processing', value: AppTaskStatus.SubmitToProcessing },
  [AppTaskStatus.Verified]: { name: 'Verified', description: 'Verified', value: AppTaskStatus.Verified },
};

export const AppTaskStatusColors: Readonly<Record<AppTaskStatus, string>> = {
  [AppTaskStatus.Approved]: '#4472c4', // blue
  [AppTaskStatus.AwaitingSignature]: '#ffffff', // white - no color set
  [AppTaskStatus.ClaimedByCSR]: '#ffffff', // white - no color set
  [AppTaskStatus.Generated]: '#ffffff', // white - no color set
  [AppTaskStatus.KeyingCorrection]: '#7030a0', // purple
  [AppTaskStatus.NotGenerated]: '#ffffff', // white - no color set
  [AppTaskStatus.NotSubmitted]: '#ffff00', // yellow
  [AppTaskStatus.ReturnToAgent]: '#ffffff', // white - no color set
  [AppTaskStatus.ReturnToCSR]: '#ffcccc', // pink
  [AppTaskStatus.ReturnToSubmitter]: '#ffc000', // orange
  [AppTaskStatus.Revise]: '#ffffff', // white - no color set
  [AppTaskStatus.RevisionNeeded]: '#ff0000', // red
  [AppTaskStatus.SignatureDeclined]: '#ffffff', // white - no color set
  [AppTaskStatus.Signed]: '#ffffff', // white - no color set
  [AppTaskStatus.SubmitToASR]: '#ffffff', // white - no color set
  [AppTaskStatus.SubmitToProcessing]: '#ffffff', // white - no color set
  [AppTaskStatus.Verified]: '#92d050', // green
};