import { quoterDb } from '../../db';
import { ScenarioId, TrendlineAnalysisId } from '../../types/api/PrimaryKeys';
import TrendlineAnalysis from '../../types/api/TrendlineAnalysis';
import {
  createTrendlineAnalysis,
  getTrendlineAnalysesByScenarioIds,
  updateTrendlineAnalysis
} from '../trendlineService';
import { addSingle, getDefaultCachingStrategy, update } from '../offlineDataAccess.service';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';

const table = quoterDb.trendlineAnalyses;

export const getTrendlineAnalysesByScenarioIdsRequest = async (scenarioIds: ScenarioId[]): Promise<TrendlineAnalysis[]> => {
  const request = () => getTrendlineAnalysesByScenarioIds(scenarioIds);

  const transactionTables = [quoterDb.trendlineAnalyses];

  const readTransaction = () => quoterDb.transaction('r', transactionTables, async () => {
    const trendlineAnalyses = safeWhere(quoterDb.trendlineAnalyses, 'primaryScenarioId').anyOf(scenarioIds).toArray();
    return trendlineAnalyses;
  });

  const strategy = getDefaultCachingStrategy();

  return await strategy(request, readTransaction);
};

export const createTrendlineAnalysisRequest = async (trendlineAnalysis: TrendlineAnalysis): Promise<CreatedItemResult<TrendlineAnalysisId>> => {
  const request = () => createTrendlineAnalysis(trendlineAnalysis);
  return await addSingle(table, trendlineAnalysis, request);
};

export const updateTrendlineAnalysisRequest = async (trendlineAnalysis: TrendlineAnalysis): Promise<void> => {
  const request = () => updateTrendlineAnalysis(trendlineAnalysis);
  return await update(table, trendlineAnalysis, request);
};
