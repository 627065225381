import { Controller } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';


interface HistoricalYearOption {
  year: number;
  disabled: boolean;
}

interface HistoricalYearInputProps {
  year: Nullable<number>;
  autoFocus?: boolean;
  name: string;
  years: HistoricalYearOption[];
  label: string;
}

export type StartYearFields = {
  startYear: number;
}

export type EndYearFields = {
  endYear: number;
}

const HistoricalYearInput = ({ year, name, years, label }: HistoricalYearInputProps) => {
  return (
    <Controller
      name={name}
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={year}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label={label}
          fullWidth
          select
          size="small"
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => field.onChange(e.target.value)}
          SelectProps={{
            MenuProps: {
              style: {
                height: '350px',
              },
            },
          }}
        >
          {years.map(year => {
            return (
              <MenuItem key={year.year} value={year.year} disabled={year.disabled}>{year.year}</MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );

};

export default HistoricalYearInput;