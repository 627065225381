import { ScenarioPieceType, ScenarioPieceTypeAttributes } from '@silveus/calculations';
import { Controller, useFormContext } from 'react-hook-form';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { MenuItem, TextField } from '@mui/material';
import { FormInputProps } from '../../scenario/formInputProps';
import { Nullable } from '../../../../types/util/Nullable';

export interface VipUnderlyingPlanInputProps extends FormInputProps {
  underlyingScenarioPiece: Nullable<ScenarioPieceType>;
}

export const VipUnderlyingPlanInput = ({ underlyingScenarioPiece, autoFocus = false, disabled = false }: VipUnderlyingPlanInputProps) => {
  const { control } = useFormContext();

  const availableUnderlyingScenarioPieces = [ScenarioPieceType.RP, ScenarioPieceType.Arp];

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.UnderlyingScenarioPieceType}
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.restrictNotOnListValue(availableUnderlyingScenarioPieces),
      }}
      defaultValue={underlyingScenarioPiece}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label="MPCI Underlying Plan"
          fullWidth
          select
          value={value}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          autoFocus={autoFocus}
          disabled={disabled}
          size="small"
        >
          {availableUnderlyingScenarioPieces.map(spt => <MenuItem key={spt} value={spt}>
            {Object.values(ScenarioPieceTypeAttributes).find(x => x.value === spt)?.description ?? ''}
          </MenuItem>)}
        </TextField>
      )}
    />
  );
};
