import { Grid } from '@mui/material';
import { ScenarioPieceType } from '@silveus/calculations';
import { ExtendedDataFormInputParameterNames } from '../../../components/formInputs/constants/inputConstants';
import PercentChangeSliderInput from '../../../components/formInputs/percentChangeSliderInput.component';
import CountyYieldInputGroup, { CountyYieldInputGroupFields } from '../../../components/formInputs/scenario/countyYieldInputGroup';
import { HarvestPriceFields } from '../../../components/formInputs/scenario/harvestPriceInput.component';
import { MpInputCostsInputGroupFields } from '../../../components/formInputs/scenario/scenarioMpInputCostsInputGroup.component';
import { MpProjectedPriceFields } from '../../../components/formInputs/scenarioPiece/mp/mpProjectedPriceInput';
import { ScenarioPieceMpInputCostsInputGroup } from '../../../components/formInputs/scenarioPiece/mp/scenarioPieceMpInputCostsInputGroup.component';
import { ScenarioPieceMpProjectedPriceInput } from '../../../components/formInputs/scenarioPiece/mp/scenarioPieceMpProjectedPriceInput.component';
import ScenarioPieceHarvestPriceInput from '../../../components/formInputs/scenarioPiece/scenarioPieceHarvestPriceInput';
import { Quote } from '../../../types/api/Quote';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../types/util/Nullable';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { RowCropScenarioPieceExtendedFormFields } from '../rowCropScenarioPieceExtendedFormFields.component';
import { useStableInsurancePlanCodeArray } from '../../../hooks/useStableInsurancePlanCodeArray';
import useLinkedScenarioFields from '../../../hooks/useLinkedScenarioFields';
import { useOptionsForSpForm } from '../../../hooks/useOptionsForSpForm';

interface Props {
  quote: Quote;
  scenario: RowCropScenario;
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  scenarioPieceType: ScenarioPieceType;
  year: number;
  isLiveQuote: boolean;
  areFieldsLocked: boolean;
  commodityCode: string;
  isLinked?: boolean;
}

export type MpScenarioPiecePricesAndYieldsFields =
  RowCropScenarioPieceExtendedFormFields &
  MpProjectedPriceFields &
  HarvestPriceFields & CountyYieldInputGroupFields &
  MpInputCostsInputGroupFields;

export const MpScenarioPiecePricesAndYields = ({ quote, scenario, scenarioPiece, scenarioPieceType, year, isLiveQuote, areFieldsLocked, commodityCode, isLinked = true }: Props) => {
  const planCode = getInsurancePlanCodeForScenarioPiece(scenarioPieceType);
  const insurancePlanCodes = useStableInsurancePlanCodeArray(planCode);
  const { scenarioOptionsForSpForm } = useOptionsForSpForm();
  const { getInputStyle } = useLinkedScenarioFields(scenario.scenarioId);

  return (<>
    <Grid item xs={4}>
      <ScenarioPieceMpProjectedPriceInput
        quote={quote}
        scenario={scenario}
        scenarioPiece={scenarioPiece}
        sx={getInputStyle(ExtendedDataFormInputParameterNames.ProjectedPrice)}
        disabled={isLiveQuote || areFieldsLocked} />
    </Grid>
    <Grid item xs={4}>
      <ScenarioPieceHarvestPriceInput
        quote={quote}
        scenario={scenario}
        scenarioOptionState={scenarioOptionsForSpForm}
        scenarioPiece={scenarioPiece}
        harvestPrice={scenario.harvestPrice}
        sx={getInputStyle('harvestPrice')}
        projectedPriceField={ExtendedDataFormInputParameterNames.ProjectedPrice}
        label="MP Harvest Price"
        loadFromState={true}
      />
    </Grid>
    <Grid item xs={4}>
      <PercentChangeSliderInput
        estimatedValueFieldName={ExtendedDataFormInputParameterNames.ProjectedPrice}
        actualValueFieldName={'harvestPrice'}
        sliderName={'priceSlider'}
        initialEstimatedValue={scenarioPiece?.rowCropScenarioPieceExtendedData?.projectedPrice ?? null}
        initialActualValue={scenario.harvestPrice}
      />
    </Grid>

    <CountyYieldInputGroup
      year={year}
      countyId={quote.countyId}
      practiceId={scenario.practiceId ?? null}
      volatility={scenario.volatility ?? 0}
      coverageLevel={scenarioPiece?.upperCoverageLevel ?? null}
      commodityCode={commodityCode}
      typeId={scenario.typeId}
      scenarioPiece={scenarioPiece}
      scenarioId={scenario.scenarioId}
      expectedCountyYield={scenario.expectedCountyYield}
      actualCountyYield={scenario.actualCountyYield}
      expectedCountyYieldLabel="MP Expected Yield"
      actualCountyYieldLabel="MP Actual Yield" loadFromState={true}
      insurancePlanCodes={insurancePlanCodes}
      disabled={isLiveQuote || areFieldsLocked} />

    <ScenarioPieceMpInputCostsInputGroup
      scenarioId={scenario.scenarioId}
      scenarioPiece={scenarioPiece}
      commodityCode={commodityCode}
      year={year}
      disabled={isLiveQuote || areFieldsLocked} />
  </>);
};
