import { useAppSelector } from '../../../hooks/reduxHooks';
import { LowerCoverageLevelInput, LowerCoverageLevelProps } from './lowerCoverageLevelInput.component';
import { selectPrivateProductLowerCoverageLevels } from '../../../app/privateProductSlice';
import { useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { FormInputParameterNames } from '../constants/inputConstants';

export const PrivateProductLowerCoverageLevelInput = ({ lowerCoverageLevel, upperCoverageLevel, autoFocus = false, disabled = false }: LowerCoverageLevelProps & { upperCoverageLevel: Nullable<number> }) => {
  const { control } = useFormContext();
  const selectedUpperCoverageLevel = useWatch({ name: FormInputParameterNames.UpperCoverageLevelName, control: control, defaultValue: upperCoverageLevel });

  const availableCoverageLevels = useAppSelector(state => selectPrivateProductLowerCoverageLevels(state, selectedUpperCoverageLevel)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => a > b ? -1 : 1));

  const coverageLevels = availableCoverageLevels.filter(cl => cl < (selectedUpperCoverageLevel ?? 100));

  return (<LowerCoverageLevelInput lowerCoverageLevel={lowerCoverageLevel} autoFocus={autoFocus}
    disabled={disabled} availableCoverageLevels={coverageLevels} />);
};

