import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import { validateEco } from '../../../../utils/validation/scenarioPieces/ecoValidation';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import EcoScenarioPieceForm from '../../../../pages/scenarioPiece/eco/ecoScenarioPieceForm';
import { getEcoScoQuoteSummaryData } from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getEcoScoQuoteSummaryData';
import { createEcoData } from '../../../../services/calculations/eco/ecoDataTransformations';
import {
  getFullyQualifiedScenarioPieceNameOnlyCoverageLevel
} from '../../defaultDefinitions/scenarioPieceNameDefaults';
import {
  ProtectionFactorScenarioPieceChip
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

export const ecoDefinition: ScenarioPieceDefinition = {
  validationFunction: validateEco,
  d365ProductType: ProductType.MPCI,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    return createEcoData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <EcoScenarioPieceForm {...props} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>} />;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getEcoScoQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceNameOnlyCoverageLevel,
  getScenarioPieceCardChips: [ProtectionFactorScenarioPieceChip],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#9932CC',
};