import { useAppSelector } from '../../../hooks/reduxHooks';
import { ClientFileId } from '../../../types/api/PrimaryKeys';
import { ClientFileScenarioModules } from './clientFileScenarioModules.component';
import { selectAnyScenariosFetching } from '../../../app/userSettingsSlice';

interface Props {
  clientFileId: ClientFileId;
}

export const ClientFileScenarioModulesCard = ({ clientFileId }: Props) => {
  const areAnyScenariosFetching = useAppSelector(selectAnyScenariosFetching);

  return (
    <ClientFileScenarioModules
      areScenariosLoading={areAnyScenariosFetching}
      clientFileId={clientFileId}
    />
  );
};