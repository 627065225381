import { Grid } from '@mui/material';
import { TabPanel } from '../../../utils/tabPanel';
import { ClientFileScenarioModulesCard } from './clientFileScenarioModulesCard.component';
import MatrixComparisonView from '../../analysis/matrixComparisonView.page';
import ScenarioAnalysis from '../../scenarioAnalysis/scenarioAnalysis.component';
import { ClientFileTabs } from '../../../constants/clientFileTabs';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectCurrentQuoteTabIndex } from '../../../app/quotesSlice';
import { ClientFileId } from '../../../types/api/PrimaryKeys';
import { MainContentZoomInput } from '../../../components/applicationZoomInput/MainContentZoomInput';
import { MainContentZoomableArea } from './mainContentZoomableArea.component';

interface Props {
  clientFileId: ClientFileId;
}

export const ClientFileContentBody = ({ clientFileId }: Props) => {
  const tabIndex = useAppSelector(selectCurrentQuoteTabIndex);
  return (
    <Grid container direction="column" sx={{ height: '100%', m: 0 }}>
      <Grid item xs sx={{ width: '100%', overflow: 'hidden' }}>
        <TabPanel value={tabIndex} index={ClientFileTabs.scenarios.index}>
          <MainContentZoomableArea>
            <Grid container item sx={{ flexFlow: 'column', rowGap: '8px', height: '100%', flexWrap: 'nowrap' }}>
              <ClientFileScenarioModulesCard clientFileId={clientFileId} />
            </Grid>
          </MainContentZoomableArea>
        </TabPanel>
        <TabPanel value={tabIndex} index={ClientFileTabs.scenarioAnalysis.index}>
          <ScenarioAnalysis />
        </TabPanel>
        <TabPanel value={tabIndex} index={ClientFileTabs.comparisonView.index}>
          <MatrixComparisonView />
        </TabPanel>
      </Grid>

      <Grid container item xs="auto">
        <Grid item xs={12}>
          <MainContentZoomInput />
        </Grid>
      </Grid>
    </Grid>
  );
};
