import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { County } from '../../types/api/adm/County';
import LockIcon from '@mui/icons-material/Lock';

interface CountyListItemProps {
  county: County;
  handleCountyClick: (county: County, active: boolean) => void;
  selectedCounties: County[];
  disabled?: boolean;
}

const CountyListItem = ({ county, handleCountyClick, selectedCounties, disabled = false }: CountyListItemProps) => {
  const isActive = selectedCounties.includes(county);

  return (
    <ListItem
      secondaryAction={(disabled
        ? <Tooltip title="This county is required by a selected client" arrow={false}><LockIcon color="disabled" /></Tooltip>
        : undefined
      )}
      disablePadding
    >
      <ListItemButton sx={{ pl: 4 }} disableRipple={disabled}>
        <ListItemIcon>
          <Checkbox
            checked={isActive}
            disableRipple
            onClick={() => handleCountyClick(county, !isActive)}
            disabled={disabled}
            sx={{ p: 0 }}
          />
        </ListItemIcon>
        <ListItemText>{county.name}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default CountyListItem;