import { runCalculationForHistoricalQuote } from '../services/calculations/calculationDelegate.service';
import { useProducerHistory } from './useProducerHistory';
import { useTrendLinePercentages } from './useTrendlinePercentages';
import { AcreageType, HistoricalQuoteResponseDTO } from '@silveus/calculations';
import { useAppSelector } from './reduxHooks';
import { selectScenarioRequestForScenario } from '../app/calculationResultsSlice';
import { selectHistoricalStormEvents } from '../app/admSlice';
import { selectScenarioById } from '../app/scenariosSlice';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import HistoricalStormEvent from '../types/api/adm/HistoricalStormEvent';
import { isNullOrUndefined } from '../utils/nullHandling';
import { selectQuoteById } from '../app/quotesSlice';
import { ScenarioId } from '../types/api/PrimaryKeys';
import { MissingQuoteInStateError, MissingScenarioInStateError } from '../errors/state/MissingStateErrors';

export const useHistoricalAnalysisCalculations = (startYear: number, endYear: number, acreageType: AcreageType, scenarioId: ScenarioId, calculateProductsForMissingYears: boolean): HistoricalQuoteResponseDTO => {
  const selectedScenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  if (selectedScenario === null) throw new MissingScenarioInStateError(scenarioId);

  const quote = useAppSelector(state => selectQuoteById(state, selectedScenario.quoteId));
  if (quote === null) throw new MissingQuoteInStateError(selectedScenario.quoteId);

  const producerYields = useProducerHistory(startYear, endYear, selectedScenario, quote, acreageType, calculateProductsForMissingYears);
  const percentChanges = useTrendLinePercentages(scenarioId, startYear, endYear, acreageType);

  const isScenarioOrQuoteInvalid = isNullOrUndefined(selectedScenario) || isNullOrUndefined(quote) || isNullOrUndefined(selectedScenario.practiceId);

  const historicalStormEvents = useAppSelector(state => isScenarioOrQuoteInvalid ?
    stableEmptyArrayAsMutable<HistoricalStormEvent>() :
    selectHistoricalStormEvents(state, quote.countyId, quote.commodityCode, startYear, endYear));

  const scenarioRequest = useAppSelector(state => selectScenarioRequestForScenario(state, scenarioId));

  const producerYieldsForHistoricalCalcs = producerYields.filter(py => py.year >= startYear && py.year <= endYear);

  const historicalCalcResults = runCalculationForHistoricalQuote(producerYieldsForHistoricalCalcs, percentChanges, scenarioRequest, historicalStormEvents, quote.quickQuote, calculateProductsForMissingYears);

  return historicalCalcResults;
};