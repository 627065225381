import { selectRightPanelZoomPercentage, setRightPanelZoomPercentage } from '../../app/applicationZoomSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { ApplicationZoomInput } from './ApplicationZoomInput';

export const RightPanelZoomInput = () => {
  const dispatch = useAppDispatch();
  const zoomPercentage = useAppSelector(selectRightPanelZoomPercentage);

  const onZoomChange = (zoomPercentage: number) => dispatch(setRightPanelZoomPercentage(zoomPercentage));

  return (
    <ApplicationZoomInput zoomPercentage={zoomPercentage} onZoomChange={onZoomChange} />
  );
};