import axios from 'axios';
import { AgencyId, AgentTeamId } from '../types/api/PrimaryKeys';
import { AgentInformation } from '../types/api/agentInformation';
import { AgencyInformation } from '../types/api/agencyInformation';
import { envBaseApiUrl } from '../constants/envConstants';
import { AgentTeam } from '../types/api/agentTeam';

export const getAgentInformationByAgentTeamId = async (agentTeamId: AgentTeamId) => {
  const res = await axios.get<AgentInformation>(`${envBaseApiUrl}/agent/${agentTeamId}`);
  return res.data;
};

export const getAgenciesForAgentTeam = async (agentTeamId: AgentTeamId) => {
  const res = await axios.get<AgencyInformation[]>(`${envBaseApiUrl}/agencies/${agentTeamId}`);
  return res.data;
};

export const getAgencyInformation = async (agencyId: AgencyId) => {
  const res = await axios.get<AgencyInformation>(`${envBaseApiUrl}/agency/${agencyId}`);
  return res.data;
};

export const getAgentTeamsForCurrentUser = async () => {
  const res = await axios.get<AgentTeam[]>(`${envBaseApiUrl}/agentteams`);
  return res.data;
};
