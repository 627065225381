import { sum } from '@silveus/calculations';
import { HailProductId } from '../../../types/api/PrimaryKeys';
import HailChartData, { HailProductIndemnity } from '../../../types/api/hail/hailChartData';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import { HailScenarioPieceWithIndemnities } from './hailTableUtils';

export function getHailChartData(lossPercentages: number[], scenarioPieceIndemnityPairs: HailScenarioPieceWithIndemnities[], getHailProductById: (hailProductId: HailProductId) => HailProduct) {
  const hailChartData: HailChartData[] = [];
  lossPercentages.forEach((lossPercent, index) => {
    const productIndemnities: HailProductIndemnity[] = [];
    const blendedLineProductIndemnity: HailProductIndemnity = { name: 'Blended', indemnity: sum(scenarioPieceIndemnityPairs, spip => spip.netIndemnities[index]) }; // TODO #62899 -> handle net vs gross
    productIndemnities.push(blendedLineProductIndemnity);
    scenarioPieceIndemnityPairs.forEach(pair => {
      const hailProduct = getHailProductById(pair.hailScenarioPiece.hailProductId);
      const productIndemnity: HailProductIndemnity = { name: hailProduct.productName, indemnity: pair.netIndemnities[index] }; // TODO #62899 -> handle net vs gross
      productIndemnities.push(productIndemnity);
    });
    const newHailChartData: HailChartData = { percent: lossPercent, productIndemnities: productIndemnities };
    hailChartData.push(newHailChartData);
  });
  return hailChartData;
}