import { Grid } from '@mui/material';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../types/util/Nullable';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { IceUnitStructureInput } from '../../../components/formInputs/scenarioPiece/iceUnitStructureInput.component';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  fetchAvailableIceDeductibles,
  fetchAvailableIceProducts,
  selectIceDeductibles,
  selectIceProduct
} from '../../../app/privateProductSlice';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useWatch } from 'react-hook-form';
import { selectScenarioById, updateScenario } from '../../../app/scenariosSlice';
import { RowCropScenarioPieceExtendedFormFields } from '../rowCropScenarioPieceExtendedFormFields.component';
import IsActiveInput from '../../../components/formInputs/scenarioPiece/isActiveInput.component';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import { createScenarioPiece, removeScenarioPieceAndRecalculate, selectAllRowCropScenarioPiecesByScenarioMap } from '../../../app/scenarioPiecesSlice';
import { ScenarioPieceGroupType, ScenarioPieceGroupTypeAttributes, ScenarioPieceType, UnitStructureCode } from '@silveus/calculations';
import { Quote } from '../../../types/api/Quote';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { IceProductSelectorInput } from '../../../components/formInputs/scenarioPiece/ice/iceProductSelectorInput.component';
import {
  ProtectionFactorFields,
  ProtectionFactorInput
} from '../../../components/formInputs/scenarioPiece/protectionFactorInput.component';
import IceDeductibleSelectionInput from '../../../components/formInputs/scenarioPiece/ice/iceDeductibleSelectionInput.component';
import IceRate from '../../../types/api/adm/IceRate';
import { useKeyMapSelector } from '../../../hooks/reduxHooks';
import { PlanSelectorInputFields } from '../../../components/formInputs/scenarioPiece/planSelectorInput.component';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { modifyScenarioPieceOrder } from '../../../app/userSettingsSlice';
import ScenarioPieceFormFooter from '../scenarioPieceFormFooter.component';
import IndividualYieldInputGroup, { IndividualYieldInputGroupFields } from '../../../components/formInputs/scenario/individualYieldInputGroup.component';
import PriceInputGroup, { PriceInputGroupFields } from '../../../components/formInputs/scenario/priceInputGroup.component';
import { UnitStructureFields } from '../../../components/formInputs/scenarioPiece/unitStructureInput.component';
import {
  UpperCoverageLevelFields
} from '../../../components/formInputs/scenarioPiece/upperCoverageLevelInput.component';
import { ScenarioPieceFormFields } from '../scenarioPieceForm.component';
import useYieldsForScenario from '../../../hooks/useYieldsForScenario';
import { mergeScenarioWithYieldAndPriceUpdates } from '../../../utils/scenarioUpdateUtils';
import { validateAndUpdateScenario } from '../../../app/validationsSlice';
import { useScenarioPieceForm } from '../useScenarioPieceForm';
import { selectOfferAvailabilitiesForCountyCommodity } from '../../../app/availabilitySlice';

interface IceScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  scenarioId: ScenarioId;
  year: number;
  countyId: string;
  commodityCode: string;
  typeId: string;
  practiceId: string;
  disabled: boolean;
  quote: Quote;
  handleCancel: () => void;
}

export type IceScenarioPieceFormFields = ScenarioPieceFormFields & PlanSelectorInputFields & UpperCoverageLevelFields & UnitStructureFields & ProtectionFactorFields & RowCropScenarioPieceExtendedFormFields & PriceInputGroupFields & IndividualYieldInputGroupFields;

const IceScenarioPieceForm = ({ scenarioPiece, scenarioId, year: clientYear, countyId, commodityCode, typeId, practiceId, disabled, registerHeader, handleCancel, quote, handleValidation, isCanceling = false }: IceScenarioPieceFormProps) => {
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));

  const iceProductTypes = ScenarioPieceGroupTypeAttributes[ScenarioPieceGroupType.Ice].elements ?? [];

  if (scenario === null) {
    throw new Error('Attempting to calculate for scenario piece when the parent scenario does not exist in state');
  }

  // SCO's upper coverage level is 86 and not a nice divisible-by-5 number, allow for fudge factor of +1
  const allowableCoverageLevelOverlap = 1;

  //TODO: 60955, later pbi to handle when mpci upper coverage changes or if SCO gets removed
  //TODO: 60955, handle SCO as "can fill in gap, later, since unknown if it forces upper or just extends range" (see question asked in PBI)
  const underlyingMpci = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioId).find(sp => [ScenarioPieceType.RP, ScenarioPieceType.RpHpe, ScenarioPieceType.YP].includes(sp.scenarioPieceType));

  const underlyingUpperCoverageLevel = underlyingMpci?.upperCoverageLevel ?? 0;

  const { approvedYield, rateYield, aphYield } = useYieldsForScenario(scenarioId, quote.countyId, quote.commodityCode);

  // form id
  const formId = 'iceScenarioPieceForm';

  const dispatch = useAppDispatch();
  const methods = useScenarioPieceForm<IceScenarioPieceFormFields>(scenarioPiece);

  const selectedIceProduct = useWatch<IceScenarioPieceFormFields>({
    control: methods.control,
    name: 'scenarioPieceType',
    defaultValue: scenarioPiece?.scenarioPieceType ?? ScenarioPieceType.Unset,
  });

  const selectedUnitStructure = useWatch<IceScenarioPieceFormFields>({
    control: methods.control,
    name: 'unitStructure',
    defaultValue: scenarioPiece?.unitStructure ?? UnitStructureCode.Unset,
  });

  const selectedIceDeductible = useWatch<IceScenarioPieceFormFields>({
    control: methods.control,
    name: 'rowCropScenarioPieceExtendedData.iceDeductibleSelection',
    defaultValue: scenarioPiece?.rowCropScenarioPieceExtendedData?.iceDeductibleSelection ?? null,
  });

  const [iceProductsAvailable, setIceProductsAvailable] = useState<IceRate[]>([]);
  const [areIceProductsSet, setAreIceProductsSet] = useState<boolean>(false);

  const iceDeductibleSelectionsAvailable = useAppSelector(state => selectIceDeductibles(state));

  const pieceAvailabilities = useAppSelector(state => selectOfferAvailabilitiesForCountyCommodity(state, countyId, commodityCode));
  const iceAvailabilities = pieceAvailabilities.filter(x => iceProductTypes.includes(x.scenarioPieceType));
  const iceAvailability = iceAvailabilities.find(x => x === selectedIceProduct);
  const yearToUse =
    iceAvailability?.productOfferAvailabilities.at(0)?.cropYear
    ?? iceAvailabilities.at(0)?.productOfferAvailabilities.at(0)?.cropYear
    ?? clientYear;

  // needed to have UI elements show red when a product is invalidated by mpci/sco and selections are no longer available
  useEffect(() => {
    if (iceDeductibleSelectionsAvailable.length <= 0 || !areIceProductsSet) {
      return;
    }
    if (scenarioPiece?.scenarioPieceType !== undefined) {
      methods.trigger('scenarioPieceType');
    }
    if (scenarioPiece?.unitStructure !== undefined) {
      methods.trigger('unitStructure');
    }
    if (scenarioPiece?.rowCropScenarioPieceExtendedData?.iceDeductibleSelection !== undefined) {
      methods.trigger('rowCropScenarioPieceExtendedData.iceDeductibleSelection');
    }
  }, [selectedIceProduct, selectedUnitStructure, areIceProductsSet, iceDeductibleSelectionsAvailable, selectedIceDeductible]);

  // reduced from year/commodity/county
  const iceProductsAvailableAll = useAppSelector(state => selectIceProduct(state));

  useEffect(() => {
    const temp = iceProductsAvailableAll.filter(iceRate =>
      underlyingUpperCoverageLevel >= (iceRate.lowerCoverageLevel - iceRate.maximumAllowableGap)
      &&
      underlyingUpperCoverageLevel <= (iceRate.lowerCoverageLevel - iceRate.maximumAllowableGap) + allowableCoverageLevelOverlap);
    setIceProductsAvailable(temp);
    setAreIceProductsSet(true);
  }, [iceProductsAvailableAll]);

  useEffect(() => {
    dispatch(fetchAvailableIceProducts({ year: yearToUse, countyId: countyId, commodityCode: commodityCode }));
    dispatch(fetchAvailableIceDeductibles());
  }, [dispatch, yearToUse, countyId, commodityCode]);

  const onSubmit: SubmitHandler<IceScenarioPieceFormFields> = async data => {
    let planCode = getInsurancePlanCodeForScenarioPiece(data.scenarioPieceType);

    const matchingIceProduct = iceProductsAvailable.find(x => x.iceScenarioPieceType === selectedIceProduct);

    const newScenarioPiece: RowCropScenarioPiece = {
      isActive: data.isActive,
      isInvalid: false,
      lowerCoverageLevel: matchingIceProduct?.lowerCoverageLevel ?? 0,
      upperCoverageLevel: matchingIceProduct?.upperCoverageLevel ?? 0,
      planCode: scenarioPiece?.planCode ?? planCode,
      protectionFactor: data.protectionFactor,
      rowCropScenarioPieceExtendedData: data.rowCropScenarioPieceExtendedData,
      rowCropScenarioPieceId: scenarioPiece?.rowCropScenarioPieceId ?? generatePrimaryKey(),
      scenarioId: scenarioId,
      scenarioPieceId: scenarioPiece?.scenarioPieceId ?? generatePrimaryKey(),
      scenarioPieceType: data.scenarioPieceType,
      unitStructure: data.unitStructure,
      offlineCreatedOn: scenarioPiece?.offlineCreatedOn,
      offlineLastUpdatedOn: scenarioPiece?.offlineLastUpdatedOn,
      offlineDeletedOn: scenarioPiece?.offlineDeletedOn,
    };

    await dispatch(updateScenario({
      updatedScenario: mergeScenarioWithYieldAndPriceUpdates(scenario, data),
    }));

    if (scenarioPiece === null) {
      //New scenario piece
      await dispatch(createScenarioPiece({ scenarioPiece: newScenarioPiece }));
    }

    await dispatch(validateAndUpdateScenario({ scenarioId: scenarioId, updatedScenarioPiece: newScenarioPiece }));

    // Note: This is a temporary placement. In the future, there will be some kind of explicit scenario piece reorder mechanism this will tie into.
    // Regardless: After we have updated or added a scenario piece, trigger an auto scenario piece order change, so that we can verify that system works.
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: newScenarioPiece.scenarioPieceId }));
  };

  const shouldSubmit = Object.keys(methods.formState.dirtyFields).length > 0 || scenarioPiece === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit, handleCancel);
  const handleSubmit = useFormWrapper('Scenario', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const onDeleteScenarioPiece = async () => {
    if (scenarioPiece === null) return;

    await dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
    await dispatch(modifyScenarioPieceOrder({ scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));
  };

  return (
    <>
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <IceProductSelectorInput
                iceProducts={iceProductsAvailable}
                disabled={disabled}
                selectedIceProduct={scenarioPiece?.scenarioPieceType ?? null} />
            </Grid>
            <Grid item xs={6}>
              <ProtectionFactorInput protectionFactor={Math.min(scenarioPiece?.protectionFactor ?? 100, 100)}
                upperProtectionFactor={100} lowerProtectionFactor={50} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <IceUnitStructureInput
                unitStructure={scenarioPiece?.unitStructure ?? null}
                disabled={disabled}
                selectedIceProduct={selectedIceProduct}
                iceProductsAvailable={iceProductsAvailable} />
            </Grid>
            <Grid item xs={6}>
              <IceDeductibleSelectionInput
                iceProductSelected={selectedIceProduct}
                iceProductsAvailable={iceProductsAvailable}
                iceDeductibleSelections={iceDeductibleSelectionsAvailable}
                unitStructureSelected={selectedUnitStructure}
                selectedIceDeductibleSelection={scenarioPiece?.rowCropScenarioPieceExtendedData?.iceDeductibleSelection ?? null}
                disabled={disabled} />
            </Grid>
            <PriceInputGroup quote={quote} scenario={scenario} scenarioPiece={scenarioPiece} projectedPrice={scenario.projectedPrice} harvestPrice={scenario.harvestPrice} />
            <IndividualYieldInputGroup
              quote={quote}
              scenarioId={scenarioId}
              approvedYield={approvedYield}
              producerYield={scenario.actualProducerYield ?? null}
              rateYield={rateYield}
              aphYield={aphYield} />
            <Grid item xs={12}>
              <IsActiveInput isActive={scenarioPiece?.isActive ?? null} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ScenarioPieceFormFooter scenarioPiece={scenarioPiece} onDeleteScenarioPieceCallback={() => onDeleteScenarioPiece()} />
    </>

  );
};

export default IceScenarioPieceForm;
