import { useRef, useState } from 'react';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { Nullable } from '../../types/util/Nullable';
import FormWrapper, { FormWrapperForwardProps, SharedFormWrapperProps } from './formWrapper.component';

export interface DropdownSelectFormProps extends SharedFormWrapperProps {
  label?: string;
  noOptionsText?: string;
}
const noOptionsTextDefault = 'No options available';

const DropdownSelectFormWrapper = ({ forms, label, defaultFormSelection, newItemForm, registerHeader, handleValidation, isCanceling = false, noOptionsText = noOptionsTextDefault }: DropdownSelectFormProps) => {
  const [selectedFormId, setSelectedFormId] = useState<string>(defaultFormSelection?.id ?? '');
  const [selectedFormName, setSelectedFormName] = useState<string>(defaultFormSelection?.formName ?? '');
  const childRef = useRef<Nullable<FormWrapperForwardProps>>(null);
  const submitButtonRefs = useRef<Partial<Record<string, HTMLButtonElement>>>({});

  const selectorComponent = (
    <Box sx={{ p: 1, pl: 2, pr: 2 }}>
      <TextField
        label={label}
        value={selectedFormName}
        select
        onChange={e => {
          const newName = e.target.value;
          submitButtonRefs.current[newName]?.click();
          childRef.current?.handleChange(newName);
        }}
        fullWidth
        size="small"
      >
        {forms.length ? forms.map(f =>
          <MenuItem
            key={f.formName}
            value={f.formName}
          >
            {f.formName}
            <Button
              // invisible button is created to submit the currently selected form
              // when a dropdown item is selected
              ref={ref => {
                if (ref) submitButtonRefs.current[f.formName] = ref;
              }}
              id={'btn-submit-form'}
              sx={{ visibility: 'hidden' }}
              type="submit"
              form={selectedFormId} />
          </MenuItem>,
        )
          : <MenuItem disabled value="no-items">{noOptionsText}</MenuItem>}
      </TextField>
    </Box>
  );

  return (
    <FormWrapper
      ref={childRef}
      formId={selectedFormId}
      setFormId={setSelectedFormId}
      setSelectedFormName={setSelectedFormName}
      selectorComponent={selectorComponent}
      forms={forms}
      defaultFormSelection={defaultFormSelection}
      newItemForm={newItemForm}
      registerHeader={registerHeader}
      handleValidation={handleValidation}
      isCanceling={isCanceling} />
  );
};

export default DropdownSelectFormWrapper;