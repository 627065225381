import { DBCore, DBCoreMutateRequest, Middleware } from 'dexie';
import { OfflineChangeTrackedEntity } from '../../types/app/OfflineChangeTrackedEntity';
import { isOnlineFromStore } from '../isOnlineFromStore';

export const offlineChangeTrackingMiddleware: Middleware<DBCore> = {
  stack: 'dbcore',
  create: down => ({
    ...down,
    table: tableName => {
      const downTable = down.table(tableName);

      return {
        ...downTable,
        mutate: req => {

          applyTimeStampsToEntity(req);

          return downTable.mutate(req);
        },
      };
    },
  }),
};

const applyTimeStampsToEntity = (req: DBCoreMutateRequest) => {
  // This logic should ONLY run with the user is offline. We do not want offline-specific stamps updating while in the online mode.
  // The app should be enforcing this in general, but I want to be explicit with this check regardless.
  if (isOnlineFromStore()) return;

  // We are only handling adds and updates for now.
  if (req.type !== 'add' && req.type !== 'put') return;

  // Get the timestamp to write soon.
  const timestamp = new Date().toISOString();

  for (const entity of req.values as OfflineChangeTrackedEntity[]) {
    if (req.type === 'add') {
      entity.offlineCreatedOn = timestamp;
      entity.offlineLastUpdatedOn = timestamp;
    }

    if (req.type === 'put') {
      entity.offlineLastUpdatedOn = timestamp;
    }
  }
};