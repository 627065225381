import { cloneDeep, groupBy } from 'lodash';
import { AppTask } from '../../../../types/api/AppTask';
import { AppTaskStatus } from '../../../../types/api/enums/application/appTaskStatus.enum';
import { ApplicationWizard } from '../../../../types/api/applicationWizard/applicationWizard';
import { AppType } from '../../../../types/api/enums/application/AppType.enum';
import { ScenarioPieceTypeAttributes, ProductType as ScenarioPieceProductType, ScenarioPieceResponseDTO, ScenarioPieceType, AvailabilityService, UnitStructureCode, InsurancePlanCode, OptionCode } from '@silveus/calculations';
import { AgencyId, AppTaskCoverageId, AppTaskId, InsuredId, ScenarioId, ScenarioPieceId } from '../../../../types/api/PrimaryKeys';
import { ProductType, getProductTypeFromScenarioPiece } from '../../../../types/api/enums/application/productType.enum';
import { scenarioPieceOrderingServiceInstance } from '../../../../utils/scenarioOrderingServiceWrappers';
import { Quote } from '../../../../types/api/Quote';
import { generatePrimaryKey } from '../../../../utils/primaryKeyHelpers';
import { RowCropScenario } from '../../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../../types/util/Nullable';
import { AppTaskCoverage } from '../../../../types/api/appTaskCoverage';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../../utils/nullHandling';
import ScenarioOption from '../../../../types/api/options/ScenarioOption';
import { getInsurancePlanCodeForScenarioPiece } from '../../../../utils/scenarioPieceUtils';
import { getPracticeCodeFromPracticeId, getTypeCodeFromTypeId } from '../../../../utils/adm';
import { CoverageType } from '../../../../types/api/enums/application/coverageType.enum';
import { AppDispatch } from '../../../../app/store';
import { openToast } from '../../../../app/toastSlice';
import { distinct } from '../../../../utils/arrayUtils';
import { ADMYear } from '../../../../types/api/adm/ADMYear';
import { SalesClosingDate } from '../../../../types/api/applicationWizard/salesClosingDate';
import { getPreviousSalesClosingDate } from '../../../../utils/applications/applicationDateUtils';
import { sortAppTasksAndCoverages } from '../../../../utils/applications/appTaskSorting';

const MPCIScenarioPieceTypes = Object.values(ScenarioPieceTypeAttributes).filter(spt => spt.productType === ScenarioPieceProductType.Federal).map(x => x.value);
const PrivateProductPieceTypes = Object.values(ScenarioPieceTypeAttributes).filter(spt => spt.productType === ScenarioPieceProductType.Private).map(x => x.value);
const AreaPlanCodes = new Set([
  InsurancePlanCode.ARP,
  InsurancePlanCode.ARP_HPE,
  InsurancePlanCode.AYP,
  InsurancePlanCode.ECO_RP,
  InsurancePlanCode.ECO_RPHPE,
  InsurancePlanCode.ECO_YP,
  InsurancePlanCode.HIP_WI,
  InsurancePlanCode.MP,
  InsurancePlanCode.MP_HPO,
  InsurancePlanCode.SCO_RP,
  InsurancePlanCode.SCO_RPHPE,
  InsurancePlanCode.SCO_YP,
  InsurancePlanCode.STAX_RP,
  InsurancePlanCode.STAX_RPHPE,
]);

const MPPlanCodes = new Set([InsurancePlanCode.MP, InsurancePlanCode.MP_HPO]);
const MPScenarioPieceTypes = new Set([ScenarioPieceType.MP, ScenarioPieceType.MpHpo]);

interface MappedAppTaskResult {
  appTasks: AppTask[];
  unhandledCoverages: AppTaskCoverage[];
}

export function handleExistingApplicationWizardTasks(
  application: ApplicationWizard,
  tasks: AppTask[],
  includedQuotes: Quote[],
  includedScenarios: RowCropScenario[],
  allRowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>,
  cropYear: number,
  scenarioOptionsByScenarioId: Map<ScenarioId, ScenarioOption[]>,
  scenarioAcres: Record<ScenarioId, Nullable<number>>,
  scenarioPieceCalcs: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  admData: ADMYear[],
  primaryAgencyId: Nullable<AgencyId>,
  salesClosingDates: SalesClosingDate[],
): MappedAppTaskResult {
  let modifiedAppTasks: AppTask[] = [];

  const newAppTasks: AppTask[] = [];

  // 1. First create all new app tasks just like we would when submitting app wizard for the first time

  // Handle any existing cancel transfer app tasks as special cases because we want to make sure that any coverages that did exist on the CT app task still get mapped to
  // the correct app task and not another one that may have the same county.
  const ctAppTasks = tasks.filter(x => x.appType === AppType.CancelTransfer);
  const ctScenarioList = new Set<ScenarioId>(); // Track this here so its easier to filter them out later scenarios that were tied to CT
  for (const ctAppTask of ctAppTasks) {
    const appWizardAppTasks = application.applicationWizardAppTasks.filter(x => x.appTaskId === ctAppTask.appTaskId);
    // Get any scenarios that were originally tied to the CT app task
    const scenariosForCTTask = includedScenarios.filter(x => appWizardAppTasks.findIndex(s => s.scenarioId === x.scenarioId) >= 0);
    if (scenariosForCTTask.length > 0) {
      // and if we have any we can map coverage decisions like we normally do EXCEPT here we'll only include the CT App Task and the exact scenarios
      // so that they get mapped properly for coverages for the app task.
      for (const scenario of scenariosForCTTask) ctScenarioList.add(scenario.scenarioId);
      const temp = mapCoverageDecisionsToAppTasks(application.insuredId, [ctAppTask], includedQuotes, scenariosForCTTask, allRowCropScenarioPiecesByScenario, cropYear, scenarioOptionsByScenarioId, scenarioAcres, scenarioPieceCalcs, application.isNewEntity, admData, primaryAgencyId, salesClosingDates);
      newAppTasks.push(...temp.appTasks);
    }
  }

  // Now handle any non CT app tasks by mapping coverages like normal EXCEPT this time we only use the non CT app tasks and scenarios that
  // weren't already tied to a CT app task.
  const appTasksWithoutCT = tasks.filter(x => x.appType !== AppType.CancelTransfer);
  const filteredScenariosWithoutCTScenarios = includedScenarios.filter(s => !ctScenarioList.has(s.scenarioId));
  newAppTasks.push(...mapCoverageDecisionsToAppTasks(application.insuredId, cloneDeep(appTasksWithoutCT), includedQuotes, filteredScenariosWithoutCTScenarios, allRowCropScenarioPiecesByScenario, cropYear, scenarioOptionsByScenarioId, scenarioAcres, scenarioPieceCalcs, application.isNewEntity, admData, primaryAgencyId, salesClosingDates).appTasks);

  for (let appTask of newAppTasks) {
    // 2. now we try and match existing app tasks with the app tasks returned from step 1.
    const matchingAppTask = tasks.find(x => x.appTaskId === appTask.appTaskId);

    // 3. If none of the existing app tasks match this app task id then that means we have a brand new app task + coverages
    // and we don't have to worry about trying to match existing coverages at all and can use it as is.
    if (!matchingAppTask) {
      modifiedAppTasks.push(appTask);
      continue;
    }

    // 4. If the existing app task is not editable then we can ignore the created app task in step 1 and use the matching app task instead.
    // This ensures that if an app task is in the non-editable state that we don't mess with any properties on the app task OR on its coverages.
    if (!isAppTaskEditable(matchingAppTask)) {
      appTask = {
        ...matchingAppTask,
      };
      continue;
    }

    // 5. Otherwise we need to handle matching app task coverages possible. Here we want to match with the existing coverages when possible because
    // when we send these to the backend we don't want to overwrite them like we do the first time we submit.
    for (let cov of appTask.coverages) {
      // ToDo: Might need to match by more fields here such as by irrigation practice, coverage level, etc. Upcoming work to handle split practice
      const matchingCoverage = matchingAppTask.coverages.find(x => x.cropCode === cov.cropCode && x.planCode === cov.planCode);
      if (!matchingCoverage) {
        continue;
      }

      cov.isNewProducer = matchingCoverage.isNewProducer;
      cov.coverageType = matchingCoverage.coverageType;
      cov.isAPHRequired = matchingCoverage.isAPHRequired;
    }

    modifiedAppTasks.push(appTask);
  }
  return {
    appTasks: modifiedAppTasks.filter(x => x.coverages.length > 0),
    unhandledCoverages: [], // ToDo: Get these
  };
}

/**
 * Given a list of included scenarios from the coverage decision step, this function maps those scenarios to existing app tasks
 * if it can, otherwise creates new app tasks for those. In addition to mapping to the app tasks it will also create coverages
 * for the scenarios/scenario pieces.
 */
export function mapCoverageDecisionsToAppTasks(
  insuredId: InsuredId,
  tasks: AppTask[],
  includedQuotes: Quote[],
  includedScenarios: RowCropScenario[],
  allRowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>,
  cropYear: number,
  scenarioOptionsByScenarioId: Map<ScenarioId, ScenarioOption[]>,
  scenarioAcres: Record<ScenarioId, Nullable<number>>,
  scenarioPieceCalcs: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  isNewEntity: boolean,
  admData: ADMYear[],
  primaryAgencyId: Nullable<AgencyId>,
  salesClosingDates: SalesClosingDate[],
): MappedAppTaskResult {

  const modifiedAppTasks: AppTask[] = [];
  const groupedQuotes = groupBy(includedQuotes, x => x.countyId);
  const groupedQuoteKeys = Object.keys(groupedQuotes);
  const handledScenarios = new Set<ScenarioId>();
  const handledAppTasks = new Set<AppTaskId>();
  const handledCoverages = new Set<AppTaskCoverageId>();
  const handledScenarioPieces = new Set<ScenarioPieceId>();
  const multiCountyAppTasks = tasks.filter(x => x.isMultiCounty);

  // If no mc app tasks this gets skipped and its business as usual, otherwise we need to handle mc
  // app tasks first and keep track of which app tasks and coverages have been handled and which haven't.
  for (const appTask of multiCountyAppTasks) {
    const mcaCounties = new Set(appTask.multiCountyCodes);
    const mcaCrops = new Set(appTask.multiCountyCropCodes);
    for (const countyId of mcaCounties) {
      appTask.isNewEntity = isNewEntity;
      const matchingQuotes = groupedQuotes[countyId];
      const quoteScenarios = includedScenarios.filter(x => matchingQuotes.some(q => mcaCrops.has(q.commodityCode) && q.quoteId === x.quoteId));
      const scenarioPiecesForScenarios = quoteScenarios.flatMap(x => allRowCropScenarioPiecesByScenario.get(x.scenarioId) ?? []);
      handleQuoteScenarios(quoteScenarios, handledScenarios, handledCoverages, includedQuotes, scenarioPiecesForScenarios, appTask, scenarioOptionsByScenarioId, scenarioAcres, scenarioPieceCalcs, handledScenarioPieces);
    }

    modifiedAppTasks.push(appTask);
    handledAppTasks.add(appTask.appTaskId);
  }

  for (const countyId of groupedQuoteKeys) {
    const quotesForCounty = groupedQuotes[countyId];
    const scenariosForCounty = includedScenarios.filter(x => quotesForCounty.some(q => q.quoteId === x.quoteId));
    // Get all MPCI app tasks for the given county
    const potentialAppTaskMatches = getMatchingExistingMPCIAppTasks(tasks, countyId);
    for (const task of potentialAppTaskMatches) {
      task.isNewEntity = isNewEntity;
      const { scenariosForAppTask, scenarioPiecesForAppTask } = getScenariosAndScenarioPiecesForAppTask(task, scenariosForCounty, allRowCropScenarioPiecesByScenario, salesClosingDates, handledScenarioPieces);
      if (scenarioPiecesForAppTask.length > 0) {
        handleQuoteScenarios(scenariosForAppTask, handledScenarios, handledCoverages, includedQuotes, scenarioPiecesForAppTask, task, scenarioOptionsByScenarioId, scenarioAcres, scenarioPieceCalcs, handledScenarioPieces);
        modifiedAppTasks.push(task);
        handledAppTasks.add(task.appTaskId);
      }
    }

    const remainingScenarios = scenariosForCounty.filter(x => !(allRowCropScenarioPiecesByScenario.get(x.scenarioId) ?? []).every(sp => handledScenarioPieces.has(sp.scenarioPieceId)));
    const remainingScenarioPieces = remainingScenarios
      .flatMap(x => (allRowCropScenarioPiecesByScenario.get(x.scenarioId) ?? []))
      .filter(x => !handledScenarioPieces.has(x.scenarioPieceId));
    if (remainingScenarioPieces.length > 0) {
      // Remaining scenario pieces could contain a mix of MP and Non-MP types. We need to handle those differently.
      const mpScenarioPieces = remainingScenarioPieces.filter(x => MPScenarioPieceTypes.has(x.scenarioPieceType));
      if (mpScenarioPieces.length > 0) {
        const appTask = potentialAppTaskMatches.find(x => !handledAppTasks.has(x.appTaskId)) ?? createNewAppTask(countyId, ProductType.MPCI, insuredId, cropYear, primaryAgencyId, isNewEntity);
        if (!modifiedAppTasks.find(x => x.appTaskId === appTask.appTaskId)) {
          modifiedAppTasks.push(appTask);
        }
        handleQuoteScenarios(remainingScenarios, handledScenarios, handledCoverages, includedQuotes, mpScenarioPieces, appTask, scenarioOptionsByScenarioId, scenarioAcres, scenarioPieceCalcs, handledScenarioPieces);
      }

      const nonMPScenarioPieces = remainingScenarioPieces.filter(x => !MPScenarioPieceTypes.has(x.scenarioPieceType));
      if (nonMPScenarioPieces.length > 0) {
        const appTask = potentialAppTaskMatches.find(x => !handledAppTasks.has(x.appTaskId)) ?? createNewAppTask(countyId, ProductType.MPCI, insuredId, cropYear, primaryAgencyId, isNewEntity);
        if (!modifiedAppTasks.find(x => x.appTaskId === appTask.appTaskId)) {
          modifiedAppTasks.push(appTask);
        }
        handleQuoteScenarios(remainingScenarios, handledScenarios, handledCoverages, includedQuotes, nonMPScenarioPieces, appTask, scenarioOptionsByScenarioId, scenarioAcres, scenarioPieceCalcs, handledScenarioPieces);
      }

    }

    // get unique private product scenario pieces
    const privateProductScenarioPieces = getUniquePrivateProductScenarioPieces(scenariosForCounty, allRowCropScenarioPiecesByScenario);
    const privateProductKeys = Object.keys(privateProductScenarioPieces);
    for (const scenarioPieceType of privateProductKeys) {
      const scenarioPieces = privateProductScenarioPieces[scenarioPieceType];
      if (scenarioPieces.length > 0) {
        const privateProductAppTask = handlePrivateProductAppTask(countyId, scenarioPieces, tasks, insuredId, cropYear, includedScenarios, includedQuotes, scenarioOptionsByScenarioId, scenarioPieceCalcs, primaryAgencyId, handledCoverages, isNewEntity, scenarioAcres);
        if (!privateProductAppTask) continue;
        const matchingPrivateProductAppTask = modifiedAppTasks.find(x => x.appTaskId === privateProductAppTask.appTaskId);
        if (!matchingPrivateProductAppTask) {
          modifiedAppTasks.push(privateProductAppTask);
        } else {
          matchingPrivateProductAppTask.coverages = privateProductAppTask.coverages;
        }
      }
    }
  }

  const unhandledCoverages: AppTaskCoverage[] = [];
  for (const appTask of modifiedAppTasks) {
    for (let i = appTask.coverages.length - 1; i >= 0; i--) {
      const coverage = appTask.coverages[i];
      if (!handledCoverages.has(coverage.coverageId)) {
        unhandledCoverages.push(coverage);
        // Remove coverage from app task so its not displayed, if app task ends up with zero coverages it gets filtered out later on
        appTask.coverages.splice(i, 1);
      }
    }
  }

  return {
    // Here we are only concerned with app tasks that have any coverages, others can be ignored
    appTasks: sortAppTasksAndCoverages(modifiedAppTasks.filter(x => x.coverages.length > 0), admData),
    unhandledCoverages: unhandledCoverages,
  };
}

/**
 * Given the app task and a list of scenarios and their pieces try and find which scenarios/pieces should be used to create/update app task coverages.
 * If an app task had prior year policy coverages that only contained MP then only MP coverages would be attached.
 * If an app task had prior year policy coverages that only contained NON-MP then only NON-MP coverages should be attached.
 * For the rare case that an app task had prior year policy coverages that contained both MP and NON-MP then all plans can be attached as coverages.
 */
const getScenariosAndScenarioPiecesForAppTask = (task: AppTask, scenariosForCounty: RowCropScenario[], allRowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>, salesClosingDates: SalesClosingDate[], handledScenarioPieces: Set<ScenarioPieceId>): { scenariosForAppTask: RowCropScenario[], scenarioPiecesForAppTask: RowCropScenarioPiece[] } => {
  const priorYearCoverages = task.priorYearPolicyCoverages;
  if (isNullOrUndefined(priorYearCoverages)) {
    return { scenariosForAppTask: [], scenarioPiecesForAppTask: [] };
  }

  const appTaskSCD = isNotNullOrUndefined(task.salesClosingDateId) ? salesClosingDates.find(x => x.salesClosingDateId === task.salesClosingDateId) ?? null : null;
  const previousYearSalesClosingDate = getPreviousSalesClosingDate(appTaskSCD);
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  const coveragesContainsOnlyMP = priorYearCoverages.length > 0 && priorYearCoverages.every(x => MPPlanCodes.has(x.planCode as InsurancePlanCode) && x.salesClosingDate === previousYearSalesClosingDate);
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  const coveragesContainOnlyNonMP = priorYearCoverages.length > 0 && priorYearCoverages.every(x => !MPPlanCodes.has(x.planCode as InsurancePlanCode) && x.salesClosingDate === previousYearSalesClosingDate);
  if (coveragesContainsOnlyMP) {
    const scenariosWithMP = scenariosForCounty.filter(x => allRowCropScenarioPiecesByScenario.get(x.scenarioId)?.some(s => MPScenarioPieceTypes.has(s.scenarioPieceType)));
    const mpScenarioPieces: RowCropScenarioPiece[] = scenariosWithMP
      .flatMap(x => allRowCropScenarioPiecesByScenario.get(x.scenarioId) ?? [])
      .filter(x => MPScenarioPieceTypes.has(x.scenarioPieceType) && !handledScenarioPieces.has(x.scenarioPieceId));
    return { scenariosForAppTask: scenariosWithMP, scenarioPiecesForAppTask: mpScenarioPieces };
  } else if (coveragesContainOnlyNonMP) {
    const scenariosWithoutMP: RowCropScenario[] = scenariosForCounty
      .filter(x => allRowCropScenarioPiecesByScenario.get(x.scenarioId)?.some(s => !MPScenarioPieceTypes.has(s.scenarioPieceType)));
    const nonMPScenarioPieces = scenariosWithoutMP
      .flatMap(x => allRowCropScenarioPiecesByScenario.get(x.scenarioId) ?? [])
      .filter(x => !MPScenarioPieceTypes.has(x.scenarioPieceType) && !handledScenarioPieces.has(x.scenarioPieceId));
    return { scenariosForAppTask: scenariosWithoutMP, scenarioPiecesForAppTask: nonMPScenarioPieces };
  } else {
    const scenarioPieces = scenariosForCounty
      .flatMap(x => allRowCropScenarioPiecesByScenario.get(x.scenarioId) ?? [])
      .filter(x => !handledScenarioPieces.has(x.scenarioPieceId));
    return { scenariosForAppTask: scenariosForCounty, scenarioPiecesForAppTask: scenarioPieces };
  }
};

/**
 * Try and find app tasks for the given county. For non multi-county app tasks we can just compare the county id (code) on the app tasks.
 * If app task is multi-county then we must check if its coverage's county codes contains the given code.
 * @returns A list of app tasks that match the given county
 */
const getMatchingExistingMPCIAppTasks = (appTasks: AppTask[], countyId: string) => {
  const matchingExistingAppTasks = appTasks.filter(x =>
    ((x.countyCode === countyId) ||
      (Boolean(x.isMultiCounty) && (x.multiCountyCodes ?? []).includes(countyId))) &&
    x.productType === ProductType.MPCI);

  return matchingExistingAppTasks;
};

const handleQuoteScenarios = (
  quoteScenarios: RowCropScenario[],
  handledScenarios: Set<ScenarioId>,
  handledCoverages: Set<AppTaskCoverageId>,
  includedQuotes: Quote[],
  allRowCropScenarioPiecesByScenario: RowCropScenarioPiece[],
  appTask: AppTask,
  scenarioOptionsByScenarioId: Map<ScenarioId, ScenarioOption[]>,
  scenarioAcres: Record<ScenarioId, Nullable<number>>,
  scenarioPieceCalcs: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  handledScenarioPieces: Set<ScenarioPieceId>,
) => {
  const baseScenarioPieceTypes = new Set(scenarioPieceOrderingServiceInstance.getBaseScenarioPieces());
  for (const scenario of quoteScenarios) {
    // Its possible that a scenario was handled already as part of the matching by county/crop/level/type logic, if so we can skip this iteration.
    if (handledScenarios.has(scenario.scenarioId)) continue;
    const scenarioQuote = includedQuotes.find(x => x.quoteId === scenario.quoteId);
    if (scenarioQuote === undefined) continue;

    const scenarioPieces = allRowCropScenarioPiecesByScenario.filter(x => x.scenarioId === scenario.scenarioId);

    let mpciScenarioPieces = scenarioPieces.filter(sp => MPCIScenarioPieceTypes.indexOf(sp.scenarioPieceType) >= 0);

    // Typically we don't have more than one base piece here but sometimes you can have something like RP and MP and we need to handle those cases
    const basePieces = mpciScenarioPieces.filter(sp => baseScenarioPieceTypes.has(sp.scenarioPieceType));
    for (const basePiece of basePieces) {
      // Need to handle the case where there may be other scenarios included from coverage decisions step that have the same
      // state/county/crop/plan/level (but not practice). In this case we combine those pieces into one parent coverage and combine
      // acres, est gross premium, options and any dependant either piece may have
      const matchingPieces = findMatchingPieceByCountyCropTypeAndLevel(basePiece, quoteScenarios.filter(x => x.scenarioId !== scenario.scenarioId), scenarioQuote, scenario, allRowCropScenarioPiecesByScenario, includedQuotes);
      if (matchingPieces.length > 0) {
        const matchingPiecesScenariosScenarioPieces = matchingPieces.map(matchingPiece => allRowCropScenarioPiecesByScenario.filter(x => x.scenarioId === matchingPiece.scenarioId)).flat();
        mpciScenarioPieces = [...mpciScenarioPieces, ...matchingPiecesScenariosScenarioPieces.filter(sp => MPCIScenarioPieceTypes.indexOf(sp.scenarioPieceType) >= 0)];
      }
      const baseCoverage = createMPCIAppTaskCoverage(basePiece, scenarioQuote, scenario, appTask, matchingPieces, scenarioOptionsByScenarioId, scenarioAcres, scenarioPieceCalcs, undefined, handledCoverages, quoteScenarios);

      if (!handledCoverages.has(baseCoverage.coverageId)) {
        appTask.coverages.push(baseCoverage);
        handledCoverages.add(baseCoverage.coverageId);
      } else {
        // If the coverage already exists we need to replace the existing one on appTask.coverages.
        // createMPCIAppTaskCoverage returns a new app task coverage with a lot more data than the rollover coverage has.
        // Example - a rollover coverage does not have type and practice set. createMPCIAppTaskCoverage will find that existing
        // coverage and fill in the blanks from the scenarios/scenario pieces.
        const index = appTask.coverages.findIndex(x => x.coverageId === baseCoverage.coverageId);
        if (index >= 0) {
          appTask.coverages[index] = baseCoverage;
        }
      }

      handledScenarioPieces.add(basePiece.scenarioPieceId);
      const dependantScenarioPieces = getAllDependantScenarioPieces(mpciScenarioPieces, basePiece.scenarioPieceType, handledScenarioPieces);
      for (const dsp of dependantScenarioPieces) {
        // Its possible that a scenario piece was already handled here as part of the combining with other like scenario pieces
        // e.g. if 2 ECO scenario pieces existed those would be combined into a single child coverage and in that case we
        // don't want to do anything with the scenario piece that was already combined.
        if (handledScenarioPieces.has(dsp.scenarioPieceId)) continue;
        const matchingOtherDependantScenarioPieces = getOtherMatchingDependantPieces(dsp, dependantScenarioPieces);
        const dependantCoverage = createMPCIAppTaskCoverage(dsp, scenarioQuote, scenario, appTask, [], scenarioOptionsByScenarioId, scenarioAcres, scenarioPieceCalcs, matchingOtherDependantScenarioPieces, handledCoverages, quoteScenarios);
        baseCoverage.childCoverages.push(dependantCoverage);
        baseCoverage.scenarioIds.push(dsp.scenarioId);
        for (const otherMatchingDependantPiece of matchingOtherDependantScenarioPieces) {
          handledScenarioPieces.add(otherMatchingDependantPiece.scenarioPieceId);
        }
        handledScenarioPieces.add(dsp.scenarioPieceId);
      }

      baseCoverage.scenarioIds.push(scenario.scenarioId);
      for (const matchingPiece of matchingPieces) {
        // Mark both the piece and scenario as handled so we know we can bypass handling it
        // in future iterations or when handling misc. extra SPs below.
        handledScenarios.add(matchingPiece.scenarioId);
        handledScenarioPieces.add(matchingPiece.scenarioPieceId);
        baseCoverage.scenarioIds.push(matchingPiece.scenarioId);
      }
    }

    // Handle the remaining, if any, scenario pieces that haven't been handled as part of the
    // base piece or its children pieces.
    const unhandledPieces = mpciScenarioPieces.filter(x => !handledScenarioPieces.has(x.scenarioPieceId));
    for (const sp of unhandledPieces) {
      const coverage = createMPCIAppTaskCoverage(sp, scenarioQuote, scenario, appTask, [], scenarioOptionsByScenarioId, scenarioAcres, scenarioPieceCalcs, undefined, handledCoverages, quoteScenarios);
      coverage.scenarioIds.push(sp.scenarioId);
      appTask.coverages.push(coverage);
      handledScenarioPieces.add(sp.scenarioPieceId);
    }
  }
};

/**
 * Creates a new app task object with default parameters for the given county id.
 * @param countyId County ID for the new app task
 * @param productType
 */
function createNewAppTask(countyId: string, productType: ProductType, insuredId: InsuredId, cropYear: number, primaryAgencyId: Nullable<AgencyId>, isNewEntity: boolean): AppTask {
  return {
    appTaskId: generatePrimaryKey(),
    aipId: null,
    agencyId: primaryAgencyId,
    agentCodeId: null,
    agentCodeName: null,
    previousAIPId: null,
    insuredName: '',
    insuredId: insuredId,
    cropYear: cropYear,
    previousPolicyNumber: null,
    previousPolicyId: null,
    productType: productType,
    coverages: [],
    countyCode: countyId,
    appType: AppType.New,
    isDesignatedCounty: null,
    designatedCountyElection: null,
    isInsuringLandlordShare: null,
    isInsuringTenantShare: null,
    insuredOtherPersonsOfInterest: [],
    appTaskStatus: AppTaskStatus.NotSubmitted,
    isNew: true,
    salesClosingDateId: null,
    isNewCT: null,
    isNewEntity: isNewEntity,
    ctOriginalAppTaskId: null,
    existingPolicy: false,
    scenarioIds: [],
    policyNumber: null,
    isMultiCounty: false,
    multiCountyCodes: [],
    multiCountyCropCodes: [],
    appTaskNumber: null,
    priorYearPolicyCoverages: null,
    lastGeneratedFormRelatedChangeTime: null,
    lastGeneratedFormTime: null,
  };
}

/**
 * App Tasks in the Application Wizard can only be edited if they are in certain states.
 * @param appTask
 * @returns true if the app task can be edited, else false
 */
export function isAppTaskEditable(appTask: AppTask): boolean {
  return appTask.appTaskStatus === AppTaskStatus.NotSubmitted
    || appTask.appTaskStatus === AppTaskStatus.ReturnToSubmitter
    || appTask.appTaskStatus === AppTaskStatus.Revise
    || appTask.appTaskStatus === AppTaskStatus.SubmitToASR;
}

/**
 * Handle the case where the user had moved some coverages to a new app task, but then decided to move those coverages back to the original app task by
 *  marking their coverage type as 'Renew'. In this case we move the Renewed coverage back to the original app task coverages. Then we check if that
 * CT app task has any remaining coverages. If it does NOT have any more coverages then its safe to remove that app task from the app task list.
 * @param appTask
 * @param dispatch
 */
export const handleRenewTypeForCancelTransferTypes = (appTasks: AppTask[]) => (dispatch: AppDispatch) => {
  let tempAppTasks = [...appTasks];
  let anyChanges = false;
  const ctTypeAppTasks = tempAppTasks.filter(x => x.appType === AppType.CancelTransfer);
  for (const appTask of ctTypeAppTasks) {
    const renewCoverage = appTask.coverages.find(x => x.coverageType === CoverageType.Renew);
    if (isNullOrUndefined(renewCoverage)) continue;

    const matchingAppTask = tempAppTasks.find(x => x.appTaskId === appTask.ctOriginalAppTaskId);
    if (matchingAppTask) {
      const updatedCoverage: AppTaskCoverage = { ...renewCoverage, appTaskId: matchingAppTask.appTaskId };
      matchingAppTask.coverages.push(updatedCoverage);
      appTask.coverages = appTask.coverages.filter(x => x.coverageId !== updatedCoverage.coverageId);
      dispatch(openToast({ type: 'success', message: 'Cancel Transfer Coverages\nCoverages from CT AppTask have been moved to original AppTask.', shouldTimeout: true, allowClickToClose: false }));
      tempAppTasks = tempAppTasks.filter(x => x.coverages.length > 0);
      anyChanges = true;
    }
  }

  return {
    anyChanges: anyChanges,
    appTasks: tempAppTasks,
  };
};

/**
 * Handle the case where the user had moved 1 or more coverages to a new app task by marking them as CT but then changes the original
 * app task to a commit no change app type. In this case we will find the app task whose ctOriginalAppTaskId matches the commit no
 * change app task. If we find one then we move all coverages from the other app task to the one that was marked commit no change and
 * show the user a message indicating as such.
 */
export const handleCommitNoChangeTypeForCancelTransferTypes = (appTasks: AppTask[]) => (dispatch: AppDispatch) => {
  let tempAppTasks = [...appTasks];
  let anyChanges = false;
  const commitNoChangeAppTasks = tempAppTasks.filter(x => x.appType === AppType.CommitNoChange);
  for (const appTask of commitNoChangeAppTasks) {
    const cancelTransferTask = tempAppTasks.find(x => x.ctOriginalAppTaskId === appTask.appTaskId);
    if (isNullOrUndefined(cancelTransferTask)) continue;

    for (const ctCoverage of cancelTransferTask.coverages) {
      const newCTCoverage = { ...ctCoverage, appTaskId: appTask.appTaskId, CoverageType: CoverageType.Renew };
      appTask.coverages.push(newCTCoverage);
    }
    cancelTransferTask.coverages = [];
    dispatch(openToast({ type: 'success', message: 'The related CT AppTask has been removed and the CT Coverages have been moved back to original AppTask.', shouldTimeout: true, allowClickToClose: false }));
    anyChanges = true;
  }
  tempAppTasks = tempAppTasks.filter(x => x.coverages.length > 0);
  return {
    anyChanges: anyChanges,
    appTasks: tempAppTasks,
  };
};

/**
 *  Handle the case where we have an app task of type 'Change' with at least 1 coverage (but cannot be all coverages) is marked as 'Cancel Transfer' type.
 * For this case we want to first check if there is an existing app task that was already created for this situation. If there was we use that app task and
 * move the coverage over to that app task, otherwise we 'clone' an original app task and change some values on it, namely appType, id, ctOriginalAppTaskId
 * and move the coverage to that app task.
 */
export const handleChangeTypeForCancelTransferTypes = (appTasks: AppTask[]) => (dispatch: AppDispatch) => {
  const tempAppTasks = [...appTasks];
  const changeTypeAppTasks = tempAppTasks.filter(x => x.appType === AppType.Change);
  let anyChanges = false;
  for (const appTask of changeTypeAppTasks) {
    if (appTask.coverages.some(x => x.coverageType === CoverageType.CancelTransfer) && !appTask.coverages.every(x => x.coverageType === CoverageType.CancelTransfer)) {
      const ctCoverage = appTask.coverages.find(x => x.coverageType === CoverageType.CancelTransfer);
      if (isNullOrUndefined(ctCoverage)) continue;
      const existingCTAppTaskForCounty = tempAppTasks.find(x => x.countyCode === appTask.countyCode && x.appType === AppType.CancelTransfer);
      const newCTAppTask: AppTask = existingCTAppTaskForCounty ?? {
        ...appTask,
        appTaskId: generatePrimaryKey(),
        coverages: [],
        appType: AppType.CancelTransfer,
        ctOriginalAppTaskId: appTask.appTaskId,
        previousAIPId: appTask.aipId,
        aipId: null,
        agentCodeId: null,
        isNew: true,
        existingPolicy: true,
      };
      const newCTCoverage = {
        ...ctCoverage,
        appTaskId: newCTAppTask.appTaskId,
        CoverageType: CoverageType.CancelTransfer,
      };
      newCTAppTask.coverages.push(newCTCoverage);
      if (isNullOrUndefined(existingCTAppTaskForCounty)) {
        tempAppTasks.splice(0, 0, newCTAppTask);
      }
      appTask.coverages = appTask.coverages.filter(x => x.coverageId !== newCTCoverage.coverageId);
      if (isNotNullOrUndefined(existingCTAppTaskForCounty)) {
        dispatch(openToast({ type: 'success', message: 'Cancel Transfer Coverages\nCancel/Transfer Coverage moved to the Cancel/Transfer AppTask.', shouldTimeout: true, allowClickToClose: false }));
      } else {
        dispatch(openToast({ type: 'success', message: 'Cancel Transfer Coverages\nA new AppTask has been created for your Cancel/Transfer Coverages.', shouldTimeout: true, allowClickToClose: false }));
      }
      anyChanges = true;
    }
  }
  return {
    anyChanges: anyChanges,
    appTasks: tempAppTasks,
  };
};

function handlePrivateProductAppTask(
  countyId: string,
  scenarioPieces: RowCropScenarioPiece[],
  existingAppTasks: AppTask[],
  insuredId: InsuredId,
  cropYear: number,
  includedScenarios: RowCropScenario[],
  includedQuotes: Quote[],
  scenarioOptionsByScenarioId: Map<ScenarioId, ScenarioOption[]>,
  scenarioPieceCalcs: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  primaryAgencyId: Nullable<AgencyId>,
  handledCoverages: Set<AppTaskCoverageId>,
  isNewEntity: boolean,
  scenarioAcres: Record<ScenarioId, Nullable<number>>,
) {
  const representativePieceType = scenarioPieces[0].scenarioPieceType;
  const productType = getProductTypeFromScenarioPiece(representativePieceType);
  if (isNullOrUndefined(productType)) return null;

  const appTask = existingAppTasks.find(x => x.countyCode === countyId && x.productType === productType) ?? createNewAppTask(countyId, productType, insuredId, cropYear, primaryAgencyId, isNewEntity);
  appTask.coverages = createPrivateProductCoverage(appTask.appTaskId, scenarioPieces, appTask, includedScenarios, includedQuotes, scenarioOptionsByScenarioId, scenarioPieceCalcs, handledCoverages, scenarioAcres);

  return appTask;
}

/**
 * This method finds a matching scenario piece that is included in application where the county, crop, type, level all match but practice does not.
 */
function findMatchingPieceByCountyCropTypeAndLevel(basePiece: RowCropScenarioPiece, scenarios: RowCropScenario[], basePieceQuote: Quote, basePieceScenario: RowCropScenario, allScenarioPieces: RowCropScenarioPiece[], includedQuotes: Quote[]) {

  const matchingPieces: RowCropScenarioPiece[] = [];
  // Iterate through all scenario pieces
  for (const scenarioPiece of allScenarioPieces) {
    // Check if the scenario piece type matches the base piece type
    if (basePiece.scenarioPieceType !== scenarioPiece.scenarioPieceType) continue;

    // Find the scenario and quote associated with the scenario piece
    const scenarioPieceScenario = scenarios.find(scenario => scenario.scenarioId === scenarioPiece.scenarioId);
    if (!scenarioPieceScenario) continue;

    const scenarioPieceQuote = includedQuotes.find(quote => scenarioPieceScenario.quoteId === quote.quoteId);

    if (!scenarioPieceQuote) continue;

    // Check if commodity codes and scenario types match
    if (basePieceQuote.commodityCode !== scenarioPieceQuote.commodityCode) continue;
    if (basePieceScenario.typeId !== scenarioPieceScenario.typeId) continue;

    // Check if coverage levels match
    if (basePiece.lowerCoverageLevel !== scenarioPiece.lowerCoverageLevel) continue;
    if (basePiece.upperCoverageLevel !== scenarioPiece.upperCoverageLevel) continue;

    if (basePiece.unitStructure !== scenarioPiece.unitStructure) continue;

    // We've found a match of county, type, level, crop, make sure practices aren't equal and combine
    if (basePieceScenario.practiceId !== scenarioPieceScenario.practiceId) {
      matchingPieces.push(scenarioPiece);
    }
  }

  return matchingPieces;
}

function getOtherMatchingDependantPieces(scenarioPiece: RowCropScenarioPiece, otherScenarioPieces: RowCropScenarioPiece[]) {
  return otherScenarioPieces.filter(otherPiece => otherPiece.planCode === scenarioPiece.planCode
    && otherPiece.scenarioPieceId !== scenarioPiece.scenarioPieceId);
}

function createMPCIAppTaskCoverage(
  sp: RowCropScenarioPiece,
  quote: Quote,
  scenario: RowCropScenario,
  appTask: AppTask,
  matchingPieces: RowCropScenarioPiece[],
  scenarioOptionsByScenarioId: Map<ScenarioId, ScenarioOption[]>,
  scenarioAcres: Record<ScenarioId, Nullable<number>>,
  scenarioPieceCalcs: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  otherMatchingPieces: RowCropScenarioPiece[] = [],
  handledCoverages: Set<AppTaskCoverageId>,
  quoteScenarios: RowCropScenario[],
): AppTaskCoverage {
  let options = scenarioOptionsByScenarioId.get(scenario.scenarioId) ?? [];
  const basePieceCalc = scenarioPieceCalcs.get(sp.scenarioPieceId);
  const basePieceAcres = scenarioAcres[scenario.scenarioId];

  let totalPremium = basePieceCalc?.totalPremium ?? 0;
  let acres = basePieceAcres ?? 0;

  for (const matchingPiece of matchingPieces) {
    const matchingPieceOptions = scenarioOptionsByScenarioId.get(matchingPiece.scenarioId) ?? [];
    options = [...options, ...matchingPieceOptions];

    const matchingPieceCalc = scenarioPieceCalcs.get(matchingPiece.scenarioPieceId);
    if (matchingPieceCalc) {
      totalPremium += matchingPieceCalc.totalPremium;
    }

    const matchingAcres = scenarioAcres[matchingPiece.scenarioId];
    acres += matchingAcres ?? 0;

    for (const otherMatchingPiece of otherMatchingPieces) {
      if (otherMatchingPiece.scenarioId !== matchingPiece.scenarioId) {
        const matchingPieceOptions = scenarioOptionsByScenarioId.get(otherMatchingPiece.scenarioId) ?? [];
        options = [...options, ...matchingPieceOptions];
      }

      const otherMatchingPieceCalc = scenarioPieceCalcs.get(otherMatchingPiece.scenarioPieceId);
      if (otherMatchingPieceCalc) {
        totalPremium += otherMatchingPieceCalc.totalPremium;
      }

      const otherMatchingAcres = scenarioAcres[otherMatchingPiece.scenarioId];
      acres += otherMatchingAcres ?? 0;
    }
  }

  // Try and find an existing coverage on the app task that matches for State/County/Crop/Unit Structure. If Unit Structure isn't set on the existing coverage find a match by State/County/Crop.
  // This is important because when D365 team does the rollover every year Unit Structure on the coverage will not get set. So without the extra check if We'd never find the existing coverage
  // first time through the app wizard for a new policy year.
  let matchingCoverage = appTask.coverages.find(c => c.cropCode === quote.commodityCode && c.countyCode === quote.countyId && (c.unitStructure === sp.unitStructure || c.unitStructure === UnitStructureCode.Unset));
  const practiceCodes = quoteScenarios.filter(x => matchingPieces.find(sp => sp.scenarioId === x.scenarioId)).map(x => x.practiceId);
  practiceCodes.push(scenario.practiceId);

  const distinctPracticeCodes = distinct(practiceCodes);
  // If combining matching coverages together but they have different practices we want to make sure we set the
  // practice code on the coverage to undefined.
  const shouldSetPracticeToNull = distinctPracticeCodes.length > 1;

  let shouldBeRenewable = false;
  // In cases where we have already handled the matched coverages we don't want to combine if the coverage levels are different. We would want to create a brand new coverage.

  if (isNotNullOrUndefined(matchingCoverage) && handledCoverages.has(matchingCoverage.coverageId) && matchingCoverage.upperCoverageLevel !== sp.upperCoverageLevel) {
    // In D365 if a prior year policy had LP, EI, or HB only a single coverage is created for the current year's app task. So here we must figure out if we've already matched
    // on that single coverage but we have another scenario also matches that existing coverage AND if both coverages have either LP, EI, or HB. If this is the case then we'll
    // want to create a new coverage but instead of using CoverageType.New for that newly created coverage it will be set to CoverageType.Renew.
    // ToDo: 67427 There is some upcoming work in the near future where we will actually be able to look at the prior year coverages and determine this a lot more reliably. Once those
    // changes are in Dataverse Service we'll want to switch this matching logic up and reference the prior year policy's coverage to determine a coverage's type.
    const validOptionTypes = new Set([OptionCode.EI, OptionCode.LP, OptionCode.HB]);
    const spOptions = (scenarioOptionsByScenarioId.get(sp.scenarioId) ?? []).flatMap(x => x.option).filter(x => validOptionTypes.has(x));
    const handledSpOptions = isNotNullOrUndefined(matchingCoverage.scenarioIds) ? [...matchingCoverage.scenarioIds.map(s => scenarioOptionsByScenarioId.get(s) ?? [])].flat().map(x => x.option).filter(x => validOptionTypes.has(x)) : [];

    if (spOptions.length > 0 && handledSpOptions.length > 0) {
      shouldBeRenewable = true;
    }
    matchingCoverage = undefined;
  }

  if (isNotNullOrUndefined(matchingCoverage)) {
    handledCoverages.add(matchingCoverage.coverageId);
    matchingCoverage = {
      ...matchingCoverage,
      planCode: getInsurancePlanCodeForScenarioPiece(sp.scenarioPieceType),
      planCodeName: ScenarioPieceTypeAttributes[sp.scenarioPieceType].name,
      upperCoverageLevel: sp.upperCoverageLevel,
      lowerCoverageLevel: sp.lowerCoverageLevel,
      protectionFactor: sp.protectionFactor,
      unitStructure: sp.unitStructure,
      practiceCode: scenario.practiceId === null || shouldSetPracticeToNull ? null : getPracticeCodeFromPracticeId(scenario.practiceId),
      typeCode: getTypeCodeFromTypeId(scenario.typeId),
      typeAbbrev: null,
      optionCodes: distinct(options.map(x => x.option).sort((a, b) => a.localeCompare(b))),
      intendedNetAcres: acres,
      estimatedGrossPremium: acres > 0 && totalPremium === 0 ? matchingCoverage.estimatedGrossPremium : totalPremium,
      newAIPId: null,
      childCoverages: [],
      countyCode: matchingCoverage.countyCode,
      isAPHRequired: null,
      scenarioIds: [sp.scenarioId, ...matchingPieces.map(x => x.scenarioId)],
    };
    return matchingCoverage;
  }

  const newCoverageId: AppTaskCoverageId = generatePrimaryKey();
  return {
    coverageId: newCoverageId,
    appTaskId: appTask.appTaskId,
    parentCoverageId: null,
    coverageType: shouldBeRenewable ? CoverageType.Renew : CoverageType.New,
    isDesignatedCounty: null,
    isNewProducer: null,
    cropCode: quote.commodityCode,
    planCode: getInsurancePlanCodeForScenarioPiece(sp.scenarioPieceType),
    planCodeName: ScenarioPieceTypeAttributes[sp.scenarioPieceType].name,
    upperCoverageLevel: sp.upperCoverageLevel,
    lowerCoverageLevel: sp.lowerCoverageLevel,
    protectionFactor: sp.protectionFactor,
    unitStructure: sp.unitStructure,
    practiceCode: scenario.practiceId === null || shouldSetPracticeToNull ? null : getPracticeCodeFromPracticeId(scenario.practiceId),
    typeCode: getTypeCodeFromTypeId(scenario.typeId),
    typeAbbrev: null,
    optionCodes: distinct(options.map(x => x.option).sort((a, b) => a.localeCompare(b))),
    intendedNetAcres: acres,
    estimatedGrossPremium: totalPremium,
    newAIPId: null,
    childCoverages: [],
    countyCode: quote.countyId,
    isAPHRequired: null,
    scenarioIds: [],
  };
}

export function getAllDependantScenarioPieces(scenarioPieces: RowCropScenarioPiece[], basePieceType: ScenarioPieceType, handledScenarioPieces: Set<ScenarioPieceId>) {
  const baseScenarioDependantPieceTypes = [...AvailabilityService.getScenarioPiecesThatDependOnScenarioPiece(basePieceType)];
  let allPossibleDependantScenarioPieceTypes: ScenarioPieceType[] = [...baseScenarioDependantPieceTypes];
  return scenarioPieces.filter(sp => allPossibleDependantScenarioPieceTypes.findIndex(x => x === sp.scenarioPieceType) >= 0 && !handledScenarioPieces.has(sp.scenarioPieceId));
}

function createPrivateProductCoverage(
  appTaskId: AppTaskId,
  scenarioPieces: RowCropScenarioPiece[],
  appTask: AppTask,
  includedScenarios: RowCropScenario[],
  includedQuotes: Quote[],
  scenarioOptionsByScenarioId: Map<ScenarioId, ScenarioOption[]>,
  scenarioPieceCalcs: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  handledCoverages: Set<AppTaskCoverageId>,
  scenarioAcres: Record<ScenarioId, Nullable<number>>,
) {
  const appTaskCoverages: AppTaskCoverage[] = [];

  for (const sp of scenarioPieces) {
    const scenario = includedScenarios.find(x => x.scenarioId === sp.scenarioId);

    if (!scenario) continue;

    const quote = includedQuotes.find(x => x.quoteId === scenario.quoteId);

    if (!quote) continue;

    let options = scenarioOptionsByScenarioId.get(scenario.scenarioId) ?? [];

    // Because this coverage is a private product - type, practice do not need to get set.
    const calc = scenarioPieceCalcs.get(sp.scenarioPieceId);
    const appTaskCoverage: AppTaskCoverage = {
      coverageId: generatePrimaryKey(),
      countyCode: appTask.countyCode,
      appTaskId: appTaskId,
      parentCoverageId: null,
      coverageType: null,
      isDesignatedCounty: null,
      isNewProducer: null,
      cropCode: quote.commodityCode,
      planCode: sp.planCode,
      planCodeName: ScenarioPieceTypeAttributes[sp.scenarioPieceType].name,
      upperCoverageLevel: sp.upperCoverageLevel,
      lowerCoverageLevel: sp.lowerCoverageLevel,
      protectionFactor: sp.protectionFactor,
      unitStructure: sp.unitStructure,
      practiceCode: null,
      typeCode: null,
      typeAbbrev: null,
      optionCodes: options.map(x => x.option).sort((a, b) => a.localeCompare(b)),
      estimatedGrossPremium: calc?.totalPremium ?? 0,
      newAIPId: null,
      childCoverages: [],
      isAPHRequired: null,
      scenarioIds: [scenario.scenarioId],
      intendedNetAcres: scenarioAcres[sp.scenarioId],
    };

    handledCoverages.add(appTaskCoverage.coverageId);
    appTaskCoverages.push(appTaskCoverage);
  }

  return appTaskCoverages;
}

function getUniquePrivateProductScenarioPieces(scenarios: RowCropScenario[], allRowCropScenarioPiecesByScenario: Map<ScenarioId, RowCropScenarioPiece[]>) {
  return groupBy(scenarios.flatMap(s =>
    allRowCropScenarioPiecesByScenario.get(s.scenarioId) ?? []).filter(sp => PrivateProductPieceTypes.find(x => sp.scenarioPieceType === x)), x => x.scenarioPieceType);
}

export function isAppTaskStatusEditable(status: Nullable<AppTaskStatus>) {
  if (isNullOrUndefined(status)) return true;

  return status === AppTaskStatus.NotSubmitted || status === AppTaskStatus.ReturnToSubmitter || status === AppTaskStatus.Revise;
}

export const isAreaPlan = (planCode: string) => {
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  return AreaPlanCodes.has(planCode as InsurancePlanCode);
};