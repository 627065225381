import {
  ClientFileId,
  InsuredId,
  QuoteId,
  ScenarioId,
  UnitYearId
} from '../../types/api/PrimaryKeys';
import UnitYear from '../../types/api/UnitYear';
import { Quote } from '../../types/api/Quote';
import { ClientFile } from '../../types/api/ClientFile';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { getItemsForId } from '../mapHelpers';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import UnitYearAph from '../../types/api/UnitYearAph';

export interface ScenarioUnitYearAphReconciliationParams {
  units: Map<InsuredId, UnitYear[]>;
  quotes: Map<ClientFileId, Quote[]>;
  clientFilesMap: Map<InsuredId, ClientFile[]>;
  rowCropScenarios: Map<QuoteId, RowCropScenario[]>;
  scenarioUnitYearAph: Map<ScenarioId, ScenarioUnitYearAph[]>;
  unitYearAph: Map<UnitYearId, UnitYearAph[]>;
}

/**
 * Reconciles scenario unit year APH records given a set of data representing the user's selected source of truth
 * @param clientFilesMap - The client files selected by the user, grouped by insured id
 * @param units - The unit years selected by the user, grouped by insured id
 * @param quotes - The quotes selected by the user, grouped by client file id
 * @param rowCropScenarios - The row crop scenarios selected by the user, grouped by quote id
 * @param scenarioUnitYearAph - The scenario unit year APHs selected by the user, grouped by scenario id
 * @param unitYearAph - The unit year APHs selected by the user, grouped by unit year id
 * @return reconciledScenarioUnitYearAph The final state of all scenario unit year APH records, grouped by scenario id
 */
export const getReconciledScenarioUnitYearAph = ({
  clientFilesMap,
  units,
  quotes,
  rowCropScenarios,
  scenarioUnitYearAph,
  unitYearAph,
}: ScenarioUnitYearAphReconciliationParams): Map<ScenarioId, ScenarioUnitYearAph[]> => {
  const reconciledScenarioUnitYearAph = new Map<ScenarioId, ScenarioUnitYearAph[]>();

  clientFilesMap.forEach(clientFiles => {
    clientFiles.forEach(clientFile => {
      const applicableQuotes = getItemsForId(quotes, clientFile.clientFileId);

      applicableQuotes.forEach(quote => {
        const applicableScenarios = getItemsForId(rowCropScenarios, quote.quoteId);

        applicableScenarios.forEach(scenario => {
          const unitsForScenario = getItemsForId(units, clientFile.insuredId).filter(u =>
            u.year === clientFile.year &&
            u.countyId === quote.countyId &&
            u.commodityCode === quote.commodityCode &&
            u.insuredId === clientFile.insuredId &&
            u.typeId === scenario.typeId &&
            u.practiceId === scenario.practiceId);

          //Keep just the entries for the scenario that still have a corresponding unit
          const allUnitYearAphForScenario = unitsForScenario.flatMap(unit => {
            const aphForUnitYear = getItemsForId(unitYearAph, unit.unitYearId);
            const unitYearAphIds = aphForUnitYear.map(uya => uya.unitYearAphId);
            return getItemsForId(scenarioUnitYearAph, scenario.scenarioId).filter(uya => unitYearAphIds.includes(uya.unitYearAphId));
          });

          reconciledScenarioUnitYearAph.set(scenario.scenarioId, allUnitYearAphForScenario);
        });
      });
    });
  });

  return reconciledScenarioUnitYearAph;
};