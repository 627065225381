import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { addQuoteFromForm, modifyQuote, selectQuoteById } from '../../../app/quotesSlice';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { SubmitHandler, useForm, FormProvider, useWatch } from 'react-hook-form';
import StateInput, { StateFields } from '../../../components/formInputs/stateInput.component';
import CommodityInput, { CommodityFields } from '../../../components/formInputs/commodityInput.component';
import DescriptionInput, { DescriptionFields } from '../../../components/formInputs/descriptionInput.component';
import NameInput, { NameFields } from '../../../components/formInputs/nameInput.component';
import CountyInput, { CountyFields } from '../../../components/formInputs/countyInput.component';
import QuickQuoteInput, { QuickQuoteFields } from '../../../components/formInputs/quote/quickQuoteInput.component';
import { ClientFileId, QuoteId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import { closeDrawer, openDrawer } from '../../../app/appDrawerSlice';
import { endLoading } from '../../../app/loaderSlice';
import { selectCommoditiesWithOffers, selectCountiesWithOffers, selectAllStates } from '../../../app/admSlice';
import useFormWrapper from '../../../hooks/useFormWrapper';
import useDrawerForm from '../../../hooks/useDrawerForm';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { getRowCropScenariosRequest } from '../../../services/requestInterception/scenarioRequestInterceptor';
import { loadAllChildDataForQuotes } from '../../../app/routeDataThunks';
import { validateAndUpdateQuote } from '../../../app/validationsSlice';
import { isNullOrUndefined } from '../../../utils/nullHandling';

export interface QuoteFormProps extends FormWrapperProps {
  quoteId: Nullable<QuoteId>;
  clientFileId: ClientFileId;
}

export type QuoteFormFields = StateFields & CountyFields &
  CommodityFields & NameFields &
  DescriptionFields & QuickQuoteFields;

const QuoteForm = ({ quoteId, clientFileId, isCanceling = false, registerHeader, handleValidation }: QuoteFormProps) => {
  const dispatch = useAppDispatch();
  const [areScenarioDependentFieldsDisabled, setAreScenarioDependentFieldsDisabled] = useState(false);
  const clientFile = useAppSelector(s => selectClientFileById(s, clientFileId));
  const quote = useAppSelector(state => quoteId === null ? null : selectQuoteById(state, quoteId));
  const methods = useForm<QuoteFormFields>();
  const stateCode = useWatch({ name: 'stateCode', control: methods.control });
  const countyCode = useWatch({ name: 'countyId', control: methods.control });
  const commodityCode = useWatch({ name: 'commodityCode', control: methods.control });

  const states = useAppSelector(selectAllStates);
  const counties = useAppSelector(selectCountiesWithOffers);
  const commodities = useAppSelector(selectCommoditiesWithOffers);

  const forceQuickQuote = isNullOrUndefined(clientFile?.insuredId);
  const disabledInformation = forceQuickQuote ?
    {
      disabled: true,
      reason: 'You cannot create a Unit Quote without an Insured',
    }
    : undefined;

  const formId = 'quoteForm';

  useEffect(() => {
    if (methods.formState.isDirty && !(methods.formState.dirtyFields.name ?? false)) {
      methods.setValue('name', formatName());
    }
  }, [stateCode, countyCode, commodityCode]);

  const formatName = () => {
    let state = '', county = '', crop = '';
    if (stateCode) {
      state = states.find(st => st.stateCode === stateCode)?.name ?? '';
    }
    if (countyCode) {
      county = counties.find(ct => ct.countyId === countyCode)?.name ?? '';
    }
    if (commodityCode) {
      crop = commodities.find(cr => cr.commodityCode === commodityCode)?.name ?? '';
    }

    return stateCode && countyCode && commodityCode ? `${state} - ${county} - ${crop}` : '';
  };

  useEffect(() => {
    const loadScenarios = async (id: QuoteId) => {
      const scenarios = await getRowCropScenariosRequest(id);
      setAreScenarioDependentFieldsDisabled(scenarios.length > 0);
    };
    if (quoteId) {
      loadScenarios(quoteId);
    } else {
      setAreScenarioDependentFieldsDisabled(false);
    }
  }, [quoteId]);

  useEffect(() => {
    //We have a form open for a saved item that no longer exists
    if (quoteId !== null && (quote === null || clientFile === null)) {
      dispatch(closeDrawer());
    }
  }, [quoteId, quote, clientFile]);

  const onSubmit: SubmitHandler<QuoteFormFields> = async data => {
    if (clientFile === null) return;

    if (quote === null) {
      //New quote
      const result = await dispatch(addQuoteFromForm({ quoteFormFields: data, clientFileId: clientFileId }));

      if (!addQuoteFromForm.fulfilled.match(result)) {
        dispatch(endLoading());
        return;
      }

      const newQuote = result.payload;

      dispatch(endLoading());

      // Loads any critical data for the new quote to get the application state "in sync," including but not limited to ADM data.
      dispatch(loadAllChildDataForQuotes([newQuote]));

      // Open the "add scenario" form, after setting the current quote to the newly-created quote.
      dispatch(openDrawer({ formName: 'scenarioForm', scenarioId: null, quoteId: newQuote.quoteId }));
    } else {
      //Existing quote
      await dispatch(modifyQuote({ quote: quote, quoteFormFields: data }));
      await dispatch(validateAndUpdateQuote({ quoteId: quote.quoteId }));
    }
  };

  const shouldSubmit = methods.formState.isDirty || quoteId === null;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit);
  const handleSubmit = useFormWrapper('Quote', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const handleReset = () => {
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit} onReset={handleReset}>
        {clientFile !== null &&
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
              <QuickQuoteInput disabledInformation={disabledInformation} quickQuote={(forceQuickQuote || quote?.quickQuote) ?? true} autoFocus />
            </Grid>
            <Grid item xs={4}>
              <StateInput countyId={quote?.countyId ?? null} disabled={areScenarioDependentFieldsDisabled} />
            </Grid>
            <Grid item xs={4}>
              <CountyInput countyId={quote?.countyId ?? null} year={clientFile.year} disabled={areScenarioDependentFieldsDisabled} />
            </Grid>
            <Grid item xs={4}>
              <CommodityInput commodityCode={quote?.commodityCode ?? null} countyId={quote?.countyId ?? null} year={clientFile.year} disabled={areScenarioDependentFieldsDisabled} />
            </Grid>
            <Grid item xs={12}>
              <NameInput name={quote?.name ?? null} />
            </Grid>
            <Grid item xs={12}>
              <DescriptionInput description={quote?.description ?? null} />
            </Grid>
          </Grid>}
      </form>
    </FormProvider>
  );
};

export default QuoteForm;
