import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogContent, FilterOptionsState, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Contact } from '../../../types/api/contact';
import { Nullable } from '../../../types/util/Nullable';
import { isNilOrEmpty, isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';
import { InsuredAddress } from '../../../types/api/insureds/Insured';
import { formatCityStateZip } from '../../../utils/formatAddress';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { getBusinessesForAgentTeam, getContactsForAgentTeam } from '../../../services/contacts.service';
import { InsuredId } from '../../../types/api/PrimaryKeys';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { selectEntityTypes, selectInsuredById, selectTaxTypes } from '../../../app/insuredsSlice';
import { matchSorter } from 'match-sorter';
import { fuzzySearchMultipleWords } from '../../../utils/searchUtils';
import { PersonOfInterest } from '../../../types/api/insureds/PersonOfInterest';
import { PersonOfInterestType, PersonOfInterestTypeAttributes } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { importPersonOfInterest } from '../../../app/personOfInterestSlice';
import { MuiDialogCloseReason } from '../../../types/mui/MuiDialogCloseReason';
import { EntityTypeEnum } from '../../../types/api/enums/application/entityType.enum';
import { openToast } from '../../../app/toastSlice';
import { PersonOrBusiness } from '../../../types/api/enums/contactInfo/personOrBusiness';
import TaxIdMask from '../../formInputs/applications/taxIdMask';
import { getDecryptedId } from '../../../services/insureds.service';


type ImportContactsModalProps = {
  onClose: (reason?: MuiDialogCloseReason) => void;
  insuredId: InsuredId;
  personOfInterestType: PersonOfInterestType;
  existingPOIs: PersonOfInterest[];
  closeOnImport?: boolean;
};

const ImportContactsModal = ({ onClose, insuredId, personOfInterestType, existingPOIs, closeOnImport = false }: ImportContactsModalProps) => {
  const dispatch = useAppDispatch();
  const taxTypes = useAppSelector(selectTaxTypes);
  const entityTypes = useAppSelector(selectEntityTypes);
  const insured = useAppSelector(state => selectInsuredById(state, insuredId));
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [selectedContact, setSelectedContact] = useState<Nullable<Contact>>(null);
  const [selectedPoiType, setSelectedPoiType] = useState(personOfInterestType);

  useEffect(() => {
    const loadData = async () => {
      if (isNotNullOrUndefined(insured) && isNotNullOrUndefined(insured.agentTeamId)) {
        let allContacts = await getContactsForAgentTeam(insuredId, insured.agentTeamId);
        if (personOfInterestType === PersonOfInterestType.SBI) {
          const businesses = await getBusinessesForAgentTeam(insuredId, insured.agentTeamId);
          allContacts = [...allContacts, ...businesses];
        }
        setContacts(allContacts);
      }
      setLoading(false);
    };

    loadData();
  }, [insured]);

  useEffect(() => {
    // Any existing POIs need to be filtered from our available list of contacts or import.
    setFilteredContacts(contacts.filter(x => !existingPOIs.find(p => p.id === x.id)));
  }, [contacts, existingPOIs]);

  const formatAddress = (address: Nullable<InsuredAddress>) => {
    const formattedAddress = formatCityStateZip(address);
    return isNilOrEmpty(formattedAddress) ? '' : `- ${formattedAddress}`;
  };

  const formatPhone = (phone: Nullable<string>) => {
    return isNilOrEmpty(phone) ? '' : `- ${formatPhoneNumber(phone)}`;
  };

  const getSelectedTaxIdType = () => {
    if (isNullOrUndefined(taxTypes) || isNullOrUndefined(selectedContact) || isNullOrUndefined(selectedContact.taxTypeId)) {
      return '';
    }

    const selectedTaxIdType = taxTypes.find(x => x.taxTypeId === selectedContact.taxTypeId);
    return selectedTaxIdType?.name ?? '';
  };

  const handleImport = async () => {
    if (!selectedContact) {
      return;
    }

    // In cases of importing a person aka NOT a business we will try and use the entity type of the contact.
    // However in most cases persons do not have an entity type so we'll default to use the individual entity type.
    const individualEntityTypeId = entityTypes.find(x => x.name === EntityTypeEnum.Individuals)?.entityTypeId ?? null;
    // In cases of importing a person aka NOT a business we will try and use the tax type id of the actual contact.
    // However in liu of that if the tax type id is missing for some reason we'll just default it to the SSN type.
    const ssnTypeId = taxTypes.find(x => x.name === 'SSN')?.taxTypeId ?? null;
    const address: InsuredAddress = {
      city: selectedContact.address?.city ?? '',
      state: selectedContact.address?.state ?? '',
      addressLine1: selectedContact.address?.addressLine1 ?? '',
      postalCode: selectedContact.address?.postalCode ?? '',
      country: selectedContact.address?.country ?? '',
      composite: selectedContact.address?.composite ?? '',
    };

    const poi: PersonOfInterest = {
      ...selectedContact,
      middleName: selectedContact.middleName ?? '',
      lastName: selectedContact.lastName ?? '',
      suffix: selectedContact.suffix ?? '',
      phone: selectedContact.phone ?? '',
      address: address,
      corporationStateId: selectedContact.corporationStateId ?? '',
      email: selectedContact.email ?? '',
      insuredId: insuredId,
      personOfInterestType: selectedPoiType,
      personOfInterestId: null,
      taxTypeId: selectedContact.taxTypeId ?? (selectedContact.personOrBusiness === PersonOrBusiness.PERSON ? ssnTypeId : null),
      entityTypeId: selectedContact.entityTypeId ?? (selectedContact.personOrBusiness === PersonOrBusiness.PERSON ? individualEntityTypeId : null),
      taxId: selectedContact.taxId ?? null,
    };

    try {
      setLoading(true);
      await dispatch(importPersonOfInterest({ personOfInterest: poi }));
      if (closeOnImport) {
        onClose();
      }
    } catch {
      dispatch(openToast({ type: 'error', message: `Failed to import ${selectedContact.name}`, shouldTimeout: true, allowClickToClose: true }));
    } finally {
      setSelectedContact(null);
      setLoading(false);
    }
  };

  const filterOptions = (options: Contact[], { inputValue }: FilterOptionsState<Contact>) => fuzzySearchMultipleWords(options, inputValue, [
    { key: 'name', threshold: matchSorter.rankings.ACRONYM },
    { key: 'address.state', threshold: matchSorter.rankings.EQUAL },
    { key: 'address.city', threshold: matchSorter.rankings.CONTAINS, maxRanking: matchSorter.rankings.WORD_STARTS_WITH },
    { key: 'address.postalCode', threshold: matchSorter.rankings.CONTAINS },
    { key: 'phone', threshold: matchSorter.rankings.CONTAINS },
  ]).slice(0, 5);

  const formatContact = (contact: Contact) => {
    return `${contact.name} ${formatAddress(contact.address)} ${formatPhone(contact.phone)}`.trim().toUpperCase();
  };

  const handleSelectedContactChanged = async (contact: Nullable<Contact>) => {
    if (isNullOrUndefined(contact)) return;

    const tempContact = { ...contact };
    if (isNotNullOrUndefined(tempContact.taxId)) {
      const decryptedId = await getDecryptedId({ encryptedId: tempContact.taxId });
      tempContact.taxId = decryptedId.toString();
    }
    setSelectedContact(tempContact);
  };

  const handleChangePOIType = (event: SelectChangeEvent) => {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const selectedPoaOrAuthRep = event.target.value as unknown as PersonOfInterestType;
    setSelectedPoiType(selectedPoaOrAuthRep);
  };

  const poaOrAuthRep = personOfInterestType === PersonOfInterestType.PowerOfAttorney || personOfInterestType === PersonOfInterestType.AuthorizedRep;

  return (
    <Dialog
      className="import-modal"
      open={true}
      onClose={(_, reason) => onClose(reason)}
      scroll="body"
      maxWidth="md"
      sx={{ m: 2 }}
      fullWidth
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container direction="column" rowGap={1} sx={{ height: '100%' }}>
          <Grid item><Typography variant="h6">Search D365</Typography></Grid>
          <Grid item>
            <Autocomplete
              key={selectedContact?.id}
              value={selectedContact}
              selectOnFocus={false}
              clearOnBlur={false}
              handleHomeEndKeys={false}
              forcePopupIcon={false}
              autoComplete={true}
              clearOnEscape={true}
              includeInputInList={true}
              disabled={loading}
              loading={loading}
              filterOptions={filterOptions}
              onChange={(_, contact) => handleSelectedContactChanged(contact)}
              noOptionsText={'No contacts found'}
              getOptionLabel={contact => formatContact(contact)}
              renderOption={(props, contact) => (
                <li {...props} key={contact.id}>
                  <span>{formatContact(contact)}</span>
                </li>
              )}
              options={filteredContacts}
              renderInput={params =>
                <TextField
                  {...params}
                  disabled={loading}
                  variant="outlined"
                  value={selectedContact?.name ?? ''}
                  label="SEARCH BY NAME, ADDRESS OR PHONE #"
                  sx={{ background: theme => theme.palette.other.inputBackground, color: theme => theme.palette.text.primary }}
                />
              }
            />
          </Grid>
          {loading && (
            <Grid container sx={{ position: 'absolute', bottom: 0, top: 0, left: 0, right: 0, margin: 'auto', justifyContent: 'center', alignItems: 'center' }}>
              <Grid item>
                <CircularProgress size={50} />
              </Grid>
            </Grid>
          )}
          {isNotNullOrUndefined(selectedContact) ? (
            <Grid container pt={2} spacing={1} rowGap={2}>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Email"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={selectedContact.email}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Phone"
                  disabled
                  variant="outlined"
                  fullWidth
                  value={formatPhone(selectedContact.phone)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Tax ID"
                  disabled
                  fullWidth
                  value={selectedContact.taxId}
                  InputProps={{
                    inputComponent: TaxIdMask,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="Tax ID Type"
                  disabled
                  fullWidth
                  value={getSelectedTaxIdType()}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  disabled
                  label="Address"
                  fullWidth
                  value={selectedContact.address?.addressLine1}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="City"
                  disabled
                  fullWidth
                  value={selectedContact.address?.city}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label="State"
                  disabled
                  fullWidth
                  value={selectedContact.address?.state}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  disabled
                  label="Zip"
                  fullWidth
                  value={selectedContact.address?.postalCode}
                />
              </Grid>
              {
                poaOrAuthRep && (
                  <Grid item xs={6}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="person-of-interest-type-selector-label">Person Type</InputLabel>
                        <Select
                          labelId="person-of-interest-type-selector-label"
                          label={'Person Type'}
                          value={`${selectedPoiType}`}
                          onChange={handleChangePOIType}
                        >
                          <MenuItem value={PersonOfInterestType.PowerOfAttorney}>{PersonOfInterestTypeAttributes[PersonOfInterestType.PowerOfAttorney].name}</MenuItem>
                          <MenuItem value={PersonOfInterestType.AuthorizedRep}>{PersonOfInterestTypeAttributes[PersonOfInterestType.AuthorizedRep].name}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                )
              }
            </Grid>
          ) : <Grid container height="240px" />}
          <Grid item xs container minHeight="65px" columnGap={1} justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <Button id="btn-contact-close" variant="outlined" onClick={() => onClose()}>Close</Button>
            </Grid>
            <Grid item>
              <Button id="btn-contact-import" variant="contained" disabled={isNullOrUndefined(selectedContact)} onClick={handleImport}>Import</Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ImportContactsModal;