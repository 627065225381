import { env } from './env';

export const envBaseApiUrl = env.baseApiUrl;
export const envInsightsKey = env.insightsKey;
export const envInsightsDisabled = env.insightsDisabled;
export const envOpenWeather = env.openWeather;
export const envAuthClientId = env.authClientId;
export const envAuthAuthority = env.authAuthority;
export const envAuthScope = env.authScope;
export const envD365PolicyUrl = env.d356PolicyUrl;
export const envBaseD365Url = env.baseD365Url;
export const envBaseDocumentGenerationUrl = env.baseDocumentGenerationUrl;
export const envESignOverrideContacts = env.eSignOverrideContacts === 'true';
export const envESignAgentEmail = env.eSignAgentEmail;
export const envESignAgentPhone = env.eSignAgentPhone;
export const envESignInsuredEmail = env.eSignInsuredEmail;
export const envESignInsuredPhone = env.eSignInsuredPhone;
export const agGridLicenseKey = env.agGridLicenseKey;
export const eSignStatusCallback = env.eSignStatusCallback;
export const envD365AppTaskUrl = env.d365AppTaskUrl;
export const envApplicationWizardEnabled = env.applicationWizardEnabled === 'true';
export const envEnabledFeatureFlags = env.enabledFeatureFlags;
