import { RootState } from '../../app/store';
import BaseUnit from '../../types/api/BaseUnit';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { getKeyedStateGroupedBy } from '../../app/sliceHelpers';
import { Guid, UnitYearId } from '../../types/api/PrimaryKeys';
import { selectQuoteById } from '../../app/quotesSlice';
import {
  selectAdjustedYieldForUnitYear,
  selectApprovedYieldForUnitYear, selectAdjustedYieldForScenario, selectApprovedYieldForScenario,
  selectRateYieldForUnit,
  selectUnitYearsByIds
} from '../../app/unitsSlice';
import { selectClientFileById } from '../../app/clientFilesSlice';
import { toPrimaryKey } from '../../utils/primaryKeyHelpers';
import { selectScenarioOptionUnitYearsByScenarioIdMap } from '../../app/optionsSlice';
import { getApplicableUnitsWithSuppliedData } from '../../utils/unitUtils';

export const getApplicableUnits = (apiData: readonly { unitYearId: Guid }[], state: RootState, scenario: RowCropScenario): BaseUnit[] => {
  const scenarioOptionsGroupedByScenario = getKeyedStateGroupedBy(state.options.allScenarioOptions.data, so => so.scenarioId);
  const optionsForScenario = scenarioOptionsGroupedByScenario.get(scenario.scenarioId) ?? [];

  const quote = selectQuoteById(state, scenario.quoteId);

  if (quote === null) throw new Error('Null current quote when attempting to determine units for calculation');

  const scenarioOptionUnitYears = selectScenarioOptionUnitYearsByScenarioIdMap(state).get(scenario.scenarioId) ?? [];

  const unitYearIds = apiData.map(data => toPrimaryKey<UnitYearId>(data.unitYearId));
  const unitsForScenario = selectUnitYearsByIds(state, unitYearIds);

  const clientFile = selectClientFileById(state, quote.clientFileId);

  //TODO: Error Handling
  if (clientFile === null) throw new Error('Attempting to calculate for scenario piece when the parent client file does not exist in state');
  return getApplicableUnitsWithSuppliedData(
    apiData,
    scenario,
    optionsForScenario,
    quote.quickQuote,
    scenarioOptionUnitYears,
    unitsForScenario,
    clientFile.year,
    (unitYearId, scenarioId) => selectApprovedYieldForUnitYear(state, unitYearId, scenarioId, quote.countyId, quote.commodityCode),
    (unitYearId, scenarioId) => selectAdjustedYieldForUnitYear(state, unitYearId, scenarioId, quote.countyId, quote.commodityCode),
    unitYearId => selectRateYieldForUnit(state, unitYearId),
    scenarioId => selectApprovedYieldForScenario(state, scenarioId, quote.countyId, quote.commodityCode),
    scenarioId => selectAdjustedYieldForScenario(state, scenarioId, quote.countyId, quote.commodityCode),
  );

};
