import { Grid, Tooltip, Typography } from '@mui/material';
import HistoricalAnalysisScenarioCard from './historicalAnalysisScenarioCard.component';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { selectSelectedHistoricalScenarioId, setSelectedHistoricalScenarioId } from '../../../app/scenarioAnalysisSlice';
import { selectAllHistoricalAnalyses } from '../../../app/historicalAnalysisSlice';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { useEffect, useState } from 'react';
import { HistoricalChart } from './charts/HistoricalChart/HistoricalChart';
import ScenarioAnalysisWrapper from '../ScenarioAnalysisWrapper';
import { ScenarioChip } from '../../../components/scenarioChip/scenarioChip';
import { HistoricalChartSettings } from './charts/HistoricalChart/HistoricalChartSettings';

type HistoricalAnalysisProps = {
  allScenariosForView: RowCropScenario[];
}

const HistoricalAnalysis = ({ allScenariosForView }: HistoricalAnalysisProps) => {
  const dispatch = useAppDispatch();
  const [collapsedHeader, setCollapsedHeader] = useState(false);
  const selectedScenarioId = useAppSelector(selectSelectedHistoricalScenarioId);
  const historicalAnalyses = useAppSelector(state => selectAllHistoricalAnalyses(state));
  const filteredScenarios = allScenariosForView.filter(x => x.isVisible && historicalAnalyses.get(x.scenarioId)?.isActive);
  const selectedHistorical = selectedScenarioId ? historicalAnalyses.get(selectedScenarioId) ?? null : null;

  useEffect(() => {
    // Handle the cases where we either don't have a selected scenario or where our selected scenario does not
    // exist in the list of filtered scenarios. We want to pick the first scenario if one exists in those cases
    // so that something always displays on this page if something is available to display.
    if (filteredScenarios.findIndex(x => x.scenarioId === selectedScenarioId) < 0) {
      const firstScenario = filteredScenarios.at(0);
      if (firstScenario) {
        dispatch(setSelectedHistoricalScenarioId(firstScenario.scenarioId));
      }
    }
  }, [filteredScenarios, selectedScenarioId]);

  if (filteredScenarios.length === 0) {
    return <Typography variant="subtitle1"><p>No historicals have been added</p></Typography>;
  }

  return (
    <Grid item height="100%" flexWrap="nowrap" columnSpacing={2}>
      <ScenarioAnalysisWrapper
        collapsed={collapsedHeader}
        onToggleCollapse={() => setCollapsedHeader(prev => !prev)}
        headerItems={filteredScenarios.map(s => {
          const historicalAnalysisForScenario = historicalAnalyses.get(s.scenarioId);

          if (historicalAnalysisForScenario === undefined) {
            return <></>;
          }

          return (
            collapsedHeader ? (
              <Grid item xs="auto" key={s.scenarioId}>
                <Tooltip title={s.name} placement="top">
                  <ScenarioChip
                    backgroundColor={s.scenarioColor}
                    label={s.name}
                    sx={{ color: theme => theme.palette.common.white }}
                    onClick={() => dispatch(setSelectedHistoricalScenarioId(s.scenarioId))}
                    maxWidth={400}
                  />
                </Tooltip>
              </Grid>
            ) : (
              <Grid item xs="auto" key={s.scenarioId}>
                <HistoricalAnalysisScenarioCard
                  isSelected={s.scenarioId === selectedScenarioId}
                  scenario={s}
                  onClick={() => dispatch(setSelectedHistoricalScenarioId(s.scenarioId))}
                  historicalAnalysis={historicalAnalysisForScenario}
                />
              </Grid>
            ));
        })}
      >
        <Grid item ml={0} spacing={1} xs>
          {selectedHistorical && selectedHistorical.isActive &&
            <>
              <HistoricalChartSettings historicalAnalysis={selectedHistorical} containerSx={{ pt: 1, pb: 1 }} />
              <HistoricalChart historicalAnalysis={selectedHistorical} key={selectedHistorical.historicalAnalysisId} />
            </>
          }
        </Grid>
      </ScenarioAnalysisWrapper>
    </Grid >
  );
};

export default HistoricalAnalysis;