import { Scenario } from '../../../types/api/Scenario';
import { groupBy } from '../../../utils/arrayUtils';

export const scenarioColors = [
  '#760F05', //Red
  '#053E76', //Blue
  '#05765A',  //Green
  '#754305',  //Brown
  '#450576',  //Purple
] as const;

export const getScenarioColor = (scenarios: Scenario[]): string => {
  //Group the scenarios by the scenario color
  const colorsByScenariosUsing = groupBy(scenarios, s => s.scenarioColor);

  //If one color has fewer scenarios, move it to the front, if it has more, move it to the back, otherwise retain the original order.
  //The ?.length ?? 0 is to account for colors that are missing from the grouping colors object because is has no scenarios using it.
  const colorsOrderedByUse = [...scenarioColors].sort((a: string, b: string) => {
    return (colorsByScenariosUsing.get(a)?.length ?? 0) > (colorsByScenariosUsing.get(b)?.length ?? 0)
      ? 1
      : (colorsByScenariosUsing.get(a)?.length ?? 0) < (colorsByScenariosUsing.get(b)?.length ?? 0)
        ? -1
        : 0;
  });

  return colorsOrderedByUse[0];
};
