import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { Nullable } from '../../../types/util/Nullable';
import { ScenarioPieceType } from '@silveus/calculations';
import InsurancePlanAttributes from '../../../types/api/enums/insurancePlan/InsurancePlanAttributes';

export interface PlanSelectorProps {
  label: string,
  availablePlans: ScenarioPieceType[],
  selectedPlan: Nullable<ScenarioPieceType>,
  disabled?: boolean;
}

export interface PlanSelectorInputFields {
  scenarioPieceType: Nullable<ScenarioPieceType>,
}

export const PlanSelectorInput = ({ label, availablePlans, selectedPlan, disabled = false }: PlanSelectorProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="scenarioPieceType"
      control={control}
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.validateMultipleRules(
          [
            VALIDATION_RULES.restrictNull(),
            VALIDATION_RULES.restrictNotOnListValueNullable(availablePlans),
          ],
        ),
      }}
      defaultValue={selectedPlan ?? ''}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label={label}
          fullWidth
          select
          value={value}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => field.onChange(parseInt(e.target.value))}
          disabled={disabled}
          size="small"
        >
          {availablePlans.map(scenarioPieceType =>
            <MenuItem key={scenarioPieceType} value={scenarioPieceType}>
              {Object.values(InsurancePlanAttributes).find(plan => plan.value === scenarioPieceType)?.description}
            </MenuItem>)}
        </TextField>
      )}
    />
  );
};
