import { Typography, useTheme } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { getColorForNumberForHistoricalAnalysis } from '../getColorForNumberForHistoricalAnalysis';
import { useAppSelector } from '../../../../../../hooks/reduxHooks';
import { selectPaletteMode } from '../../../../../../app/userSettingsSlice';
import { isNotNullOrUndefined } from '../../../../../../utils/nullHandling';

export const PercentageCellRenderer = <TData,>({ value }: ICellRendererParams<TData, number>) => {
  const mode = useAppSelector(selectPaletteMode);
  const theme = useTheme();
  const textColor = getColorForNumberForHistoricalAnalysis(value ?? 0, theme, mode);

  const displayText = isNotNullOrUndefined(value) ? `${value.toFixed(2)}%` : '';

  return (
    <>
      <Typography variant="label" color={textColor} align="right">{displayText}</Typography>
    </>
  );
};