import { UpperCoverageLevelInput, UpperCoverageLevelProps } from '../upperCoverageLevelInput.component';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectPrivateProductUpperCoverageLevels } from '../../../../app/privateProductSlice';

export const RevBoostUpperCoverageLevelInput = ({ upperCoverageLevel, autoFocus = false, disabled = false }: UpperCoverageLevelProps) => {
  const availableCoverageLevels = useAppSelector(selectPrivateProductUpperCoverageLevels)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => a > b ? -1 : 1);

  return <UpperCoverageLevelInput upperCoverageLevel={upperCoverageLevel} availableCoverageLevels={availableCoverageLevels} autoFocus={autoFocus} disabled={disabled}/>;
};
