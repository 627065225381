import { Collapse, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useState } from 'react';
import { State } from '../../types/api/adm/State';
import { County } from '../../types/api/adm/County';

interface SummaryStateDropdownProps {
  state: string;
  states: State[];
  counties: County[];
}

const SummaryStateDropdown = ({ state, states, counties }: SummaryStateDropdownProps) => {
  const [open, setOpen] = useState(false);

  const handleExpandClick = (e: React.SyntheticEvent) => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={handleExpandClick}>
          <ListItemText>{states.find(s => s.stateCode === state)?.name ?? ''}</ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ marginLeft: 2, marginRight: 2 }}>
        <List component="div" disablePadding dense>
          {counties.map(county =>
            <ListItem key={county.countyId} disablePadding>
              <ListItemText sx={{ ml: 3 }}>{county.name}</ListItemText>
            </ListItem>,
          )}
        </List>
      </Collapse>
    </>
  );
};

export default SummaryStateDropdown;