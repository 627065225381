import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useRef, useState } from 'react';
import { HistoricalData } from './types/HistoricalData';
import { selectScenarioById } from '../../../../../app/scenariosSlice';
import {
  selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder
} from '../../../../../app/scenarioPiecesSlice';
import './historicalChart.scss';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import HistoricalAnalysis from '../../../../../types/api/historicalAnalysis';
import { useHistoricalAnalysisData } from './hooks/useHistoricalAnalysisData';
import { useExcludedYearPersistence } from './hooks/useExcludedYearPersistence';
import { useHistoricalAnalysisColumnDefinitions } from './columnDefinitions';
import { useDynamicGridSize } from './hooks/useDynamicGridSize';
import { useAppSelector, useKeyMapSelector } from '../../../../../hooks/reduxHooks';
import { selectHistoricalColumns, selectIsLightMode } from '../../../../../app/userSettingsSlice';
import { useOptions } from '../../../../../hooks/useOptions';
import { toPrimaryKey } from '../../../../../utils/primaryKeyHelpers';

type HistoricalChartProps = {
  historicalAnalysis: HistoricalAnalysis;
};

export const HistoricalChart = ({ historicalAnalysis }: HistoricalChartProps) => {
  const historicalAnalysisYearsToExclude = historicalAnalysis.historicalAnalysisYears.filter(hay => hay.excluded);
  const [yearsToExclude, setYearsToExclude] = useState(historicalAnalysisYearsToExclude.map(hay => hay.year));
  useExcludedYearPersistence(historicalAnalysis, yearsToExclude);
  const historicalData = useHistoricalAnalysisData(
    historicalAnalysis.startYear,
    historicalAnalysis.endYear,
    historicalAnalysis.acreageType,
    historicalAnalysis.primaryScenarioId,
    yearsToExclude,
    historicalAnalysis.historicalIncludeType,
    historicalAnalysis.historicalShowType,
    historicalAnalysis.calculateMissingYears,
  );

  const historicalColumns = useAppSelector(selectHistoricalColumns);
  const [gridApi, setGridApi] = useState<GridApi<HistoricalData>>();
  const elementWrappingHistoricalQuoteGrid = useRef<HTMLElement>(null);
  useDynamicGridSize(gridApi, elementWrappingHistoricalQuoteGrid, historicalColumns);

  const isLightMode = useAppSelector(selectIsLightMode);
  const selectedScenario = useAppSelector(state => selectScenarioById(state, historicalAnalysis.primaryScenarioId));
  const selectedScenarioColor = selectedScenario?.scenarioColor ?? '';
  const scenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder, historicalAnalysis.primaryScenarioId);
  const { getOptionStatesForScenario } = useOptions(selectedScenario?.quoteId ?? toPrimaryKey(''), historicalAnalysis.primaryScenarioId);

  const updateYearsToExclude = useCallback((year: number, include: boolean) => {
    if (include) {
      //Remove the existing item
      const newYearsToExclude = [...yearsToExclude];
      const indexOfYear = yearsToExclude.indexOf(year);
      if (indexOfYear >= 0) {
        newYearsToExclude.splice(indexOfYear, 1);
        setYearsToExclude(newYearsToExclude);
      }
    } else {
      //Add to the array if it doesn't exist
      const newYearsToExclude = [...new Set([...yearsToExclude, year])];
      setYearsToExclude(newYearsToExclude);
    }
  }, [yearsToExclude]);

  const { columnDefinitions, defaultColumnDefinition, defaultColumnGroupDefinition } =
    useHistoricalAnalysisColumnDefinitions(
      selectedScenarioColor,
      updateYearsToExclude,
      scenarioPieces.filter(sp => sp.isActive),
      getOptionStatesForScenario());

  const onGridReady = (params: GridReadyEvent<HistoricalData>): void => {
    setGridApi(params.api);
  };

  return (
    <Box height="100%" width="100%" className={isLightMode ? 'ag-theme-compact historicalQuote' : 'ag-theme-compact-dark historicalQuote'} ref={elementWrappingHistoricalQuoteGrid}>
      <AgGridReact
        onGridReady={onGridReady}
        rowData={historicalData}
        columnDefs={columnDefinitions}
        defaultColDef={defaultColumnDefinition}
        defaultColGroupDef={defaultColumnGroupDefinition}
        groupHeaderHeight={50}
      />
    </Box>
  );
};
