import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from './thunkHelpers';
import { createTemplateScenario, deleteTemplateScenario, deleteTemplateScenarios, getTemplateScenariosForUser, updateTemplateScenario, updateTemplateScenarios } from '../services/templateScenarios.service';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import { TemplateScenario } from '../types/api/template-scenarios/TemplateScenario';
import { ScenarioId, TemplateScenarioId } from '../types/api/PrimaryKeys';
import { getKeyedStateValues, getStateIdsMatching } from './sliceHelpers';
import { AppDispatch, RootState } from './store';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { duplicateScenario, selectScenarioById } from './scenariosSlice';
import { MissingScenarioInStateError } from '../errors/state/MissingStateErrors';
import { StaticTemplateScenarioQuoteId } from '../constants/templateScenarios';
import { selectAllScenarioPiecesByScenarioMap } from './sharedSelectors';
import { getItemsForId } from '../utils/mapHelpers';
import { orderByProperty } from '../utils/arrayUtils';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import { getTemplateApplicationRules } from './template-scenarios/templateScenarioApplication';
import { loadAllScenarioPiecesForScenarios } from './routeDataThunks';
import { validateAndUpdateScenario } from './validationsSlice';
import { selectScenarioPieceAvailability } from './scenario-piece-availability/scenarioPieceAvailability';
import { ScenarioPieceType } from '@silveus/calculations';
import { modifyForwardSoldScenarioPieces, selectAllForwardSoldScenarioPiecesByScenarioMap } from './forwardSoldScenarioPiecesSlice';
import { isNotNullOrUndefined, isNullOrUndefined } from '../utils/nullHandling';
import { selectApprovedYieldForScenario } from './unitsSlice';
import { selectQuoteById } from './quotesSlice';

interface TemplateScenariosState {
  /** Note that all data in this bucket is expected to only be for the current user. */
  allTemplateScenarios: SliceDataState<TemplateScenarioId, TemplateScenario>;

  /** The scenario id to use as the baseline for the new template scenario.
   * If non-null, the modal is open.
  */
  createTemplateScenarioModalScenarioId: ScenarioId | null;
}

const initialState: TemplateScenariosState = {
  allTemplateScenarios: initialSliceDataState(),

  // Modal
  createTemplateScenarioModalScenarioId: null,
};


export const templateScenariosSlice = createSlice({
  name: 'templateScenarios',
  initialState: initialState,
  reducers: {
    /** Opens the create template scenario modal, with the provided scenario id used as the baseline. */
    openCreateTemplateScenarioModal(state, action: PayloadAction<ScenarioId>) {
      state.createTemplateScenarioModalScenarioId = action.payload;
    },
    closeCreateTemplateScenarioModal(state) {
      state.createTemplateScenarioModalScenarioId = null;
    },
  },
  extraReducers: builder => {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allTemplateScenarios, s => s.templateScenarioId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchTemplateScenariosForUser,
      affectedIds: (_, state) => getStateIdsMatching(state.allTemplateScenarios.data, _ => true, x => x.templateScenarioId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addTemplateScenario_private,
      affectedIds: arg => arg.templateScenarioId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyTemplateScenario,
      affectedIds: arg => arg.templateScenarioId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyTemplateScenarios,
      affectedIds: arg => arg.map(t => t.templateScenarioId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeTemplateScenario,
      affectedIds: arg => arg.templateScenarioId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeTemplateScenarios,
      affectedIds: arg => arg.map(t => t.templateScenarioId),
    });
  },
});

/** Fetches all template scenarios for a user. */
export const fetchTemplateScenariosForUser = createAppAsyncThunk('templateScenarios/fetchTemplateScenariosForUser', async () => {
  const apiResults = await getTemplateScenariosForUser();
  return apiResults.data;
});

/** Adds a new template scenario for the current user.
 * This is marked as private because likely all public calls will need to go through a wrapper containing more logic.
*/
const addTemplateScenario_private = createAppAsyncThunk('templateScenarios/addTemplateScenario_private', async (templateScenario: TemplateScenario) => {
  await createTemplateScenario(templateScenario);
  return templateScenario;
});

/** Modifies an existing template scenario. */
export const modifyTemplateScenario = createAppAsyncThunk('templateScenarios/modifyTemplateScenario', async (templateScenario: TemplateScenario) => {
  await updateTemplateScenario(templateScenario);
  return templateScenario;
});

/** Modifies multiple existing template scenarios. */
export const modifyTemplateScenarios = createAppAsyncThunk('templateScenarios/modifyTemplateScenarios', async (templateScenarios: TemplateScenario[]) => {
  await updateTemplateScenarios(templateScenarios);
  return templateScenarios;
});

/** Removes a template scenario. */
export const removeTemplateScenario = createAppAsyncThunk('templateScenarios/removeTemplateScenario', async (templateScenario: TemplateScenario) => {
  await deleteTemplateScenario(templateScenario.templateScenarioId);
  return templateScenario;
});

/** Removes multiple template scenarios. */
export const removeTemplateScenarios = createAppAsyncThunk('templateScenarios/removeTemplateScenarios', async (templateScenarios: TemplateScenario[]) => {
  await deleteTemplateScenarios(templateScenarios.map(t => t.templateScenarioId));
  return templateScenarios;
});

/** Creates a template scenario, copied from the supplied scenario. */
export const addTemplateScenario = createAppAsyncThunk('templateScenarios/addTemplateScenario',
  async ({ copyFromScenarioId, templateName }: { copyFromScenarioId: ScenarioId, templateName: string }, thunkApi) => {
    const state = thunkApi.getState();

    // First pull out the existing scenario the user referenced. This will be the basis of the template.
    const copyFromScenario = selectScenarioById(state, copyFromScenarioId);
    if (copyFromScenario === null) { throw new MissingScenarioInStateError(copyFromScenarioId); }

    // Duplicate the scenario. This is so the template is fully disconnected and independent from the source scenario.
    const duplicatedScenario = await thunkApi.dispatch(duplicateScenario({
      existingScenario: copyFromScenario,
      scenarioSettings: {

        // Critical: Associate the template with a special quote id. This quote id is how we keep templates
        // isolated from all "real" scenarios, while maintaining key relationships.
        newQuoteId: StaticTemplateScenarioQuoteId,
        shouldSkipValidation: true,
      },
    })).unwrap();

    const templateScenario: TemplateScenario = {
      templateScenarioId: generatePrimaryKey(),
      name: templateName,
      scenarioId: duplicatedScenario.scenarioId,

      offlineCreatedOn: undefined,
      offlineDeletedOn: undefined,
      offlineLastUpdatedOn: undefined,
    };

    await thunkApi.dispatch(addTemplateScenario_private(templateScenario));
  });

/** Applies a template to another scenario. */
export const applyScenarioAsTemplate = createAppAsyncThunk('templateScenarios/applyScenarioAsTemplate',
  async ({ templateScenarioId, applyToScenarioId }: { templateScenarioId: ScenarioId, applyToScenarioId: ScenarioId }, thunkApi) => {
    // Because templates won't be included in state in normal operations (due to the template scenarios being disconnected from a normal client file),
    // just query the template data we need directly as part of this operation.
    // We don't need to load data for the "applyTo" scenario because it should already be in state.
    await thunkApi.dispatch(loadAllScenarioPiecesForScenarios([templateScenarioId]));

    // Make sure to only get state after any data above has been loaded.
    let state = thunkApi.getState();

    // **NOTE**: The template scenario and its children is assumed to not be filtered or modified in any way from whatever it was sourced from yet.
    // Any filtering or modifications will be made below, when actually applying that template to another scenario.
    // This decision was made to open the possibility of templates being even separated from explicit "templates."
    // The end result is that any scenario, template or not, _could_ be used as a template to apply to another scenario.
    // Also, this keeps all application logic in one spot.

    // Now we should have access to all of the template pieces.
    const allPiecesFromTemplate = getItemsForId(selectAllScenarioPiecesByScenarioMap(state), templateScenarioId);

    let createdPieces = 0;

    for (const templatePiece of allPiecesFromTemplate) {
      // SELECTION RULE:
      // Determine if the piece is even available for the target scenario.
      // If not, bail logic for this piece.
      const isPieceAvailable = selectPieceIsAvailable(state, applyToScenarioId, templatePiece.scenarioPieceType);
      if (!isPieceAvailable) { continue; }

      // If a template has been manually excluded from the template application process (marked by null return here),
      // we can't create this piece.
      const rules = getTemplateApplicationRules(templatePiece.scenarioPieceType);

      if (rules === null) { continue; }

      await rules.createPiece({
        dispatch: thunkApi.dispatch,
        scenarioId: applyToScenarioId,

        // I couldn't find an easy to way to avoid this assertion, because of the different possible piece types.
        // Checking that "rules" is not null should be enough.
        // eslint-disable-next-line no-type-assertion/no-type-assertion, @typescript-eslint/no-explicit-any
        templatePiece: templatePiece as any,
      });

      createdPieces++;

      // Refresh state, because each iteration may need to access an up-to-date state.
      state = thunkApi.getState();
    }

    // Quick short circuit - we're done at this point if no pieces were added.
    if (createdPieces === 0) { return; }

    // SELECTION RULE: Forward Sold marketing yield should be adjusted
    await thunkApi.dispatch(handleSetForwardSoldMarketingYield(applyToScenarioId));

    // This will run calculations and validations on the new scenario after pieces have been added.
    await thunkApi.dispatch(validateAndUpdateScenario({ scenarioId: applyToScenarioId }));
  });

const selectPieceIsAvailable = (state: RootState, scenarioId: ScenarioId, scenarioPieceType: ScenarioPieceType) => {
  const pieceAvailability = selectScenarioPieceAvailability(state, {
    scenarioId,

    // Logic in this context is running at the piece-by-piece level - groups will interfere with expected results.
    useScenarioPieceGroups: false,
  });

  const availabilityForPiece = pieceAvailability.find(a => a.scenarioPieceType === scenarioPieceType);

  if (availabilityForPiece === undefined) { return false; }
  return !availabilityForPiece.isDisabled;
};

const handleSetForwardSoldMarketingYield = (scenarioId: ScenarioId) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();

  const scenario = selectScenarioById(state, scenarioId);
  if (isNullOrUndefined(scenario)) return;

  const quote = selectQuoteById(state, scenario.quoteId);
  if (isNullOrUndefined(quote)) return;

  const scenarioApprovedYield = isNotNullOrUndefined(scenario.quickUnit)
    ? scenario.quickUnit.approvedYield
    : selectApprovedYieldForScenario(state, scenario.scenarioId, quote.countyId, quote.commodityCode);

  if (isNullOrUndefined(scenarioApprovedYield)) { return; }

  const forwardSoldScenarioPieces = getItemsForId(selectAllForwardSoldScenarioPiecesByScenarioMap(state), scenarioId);
  if (forwardSoldScenarioPieces.length === 0) { return; }

  const modifiedPieces = forwardSoldScenarioPieces.map(piece => ({
    ...piece,
    marketingYield: scenarioApprovedYield,
  }));

  await dispatch(modifyForwardSoldScenarioPieces({ scenarioPieces: modifiedPieces }));
};


/** If non-null, treat the modal as open. Otherwise, closed. */
export const selectCreateTemplateScenarioModalScenarioId = (state: RootState) => state.templateScenarios.createTemplateScenarioModalScenarioId;

const selectAllTemplateScenariosDictionary = (state: RootState) => state.templateScenarios.allTemplateScenarios.data;
export const selectAllTemplateScenarios = createSelector([selectAllTemplateScenariosDictionary], result => {
  const allTemplateScenarios = getKeyedStateValues(result);
  const ordered = orderByProperty(allTemplateScenarios, DefaultOrders.templateScenarios);
  return ordered;
});

export const { openCreateTemplateScenarioModal, closeCreateTemplateScenarioModal } = templateScenariosSlice.actions;
export default templateScenariosSlice.reducer;