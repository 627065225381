import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector, useKeyMapSelector, useKeyMapSelectorSingle } from '../../../hooks/reduxHooks';
import { selectCalculationsByScenarioPiece } from '../../../app/calculationResultsSlice';
import '../scenarioPieceCard.style.css';
import ScenarioPieceCard from '../scenarioPieceCard.component';
import { ScenarioPieceCardProps } from '../../../types/app/ScenarioPieceDefinition';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { modifyScenarioPiece, removeScenarioPieceAndRecalculate, selectAllRowCropScenarioPiecesByScenarioMap } from '../../../app/scenarioPiecesSlice';
import { validateRowCropScenario } from '../../../utils/validation/validateScenario';
import { LabelCurrencyPair } from '../../../components/labelValuePair/labelValuePair.component';
import { getFarmBillDisplayName, getFarmBillPerAcreIndemnities } from '../../../services/calculations/farmBill/farmBillHelpers';
import { selectAcresForScenario } from '../../../app/unitsSlice';
import { selectScenarioById } from '../../../app/scenariosSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import { MissingQuoteInStateError, MissingScenarioInStateError } from '../../../errors/state/MissingStateErrors';

const FarmBillScenarioPieceCard = ({ scenarioPiece, quoteId, isScenarioActive, isReadonly, scenarioColor }: ScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();
  const calcResults = useKeyMapSelectorSingle(selectCalculationsByScenarioPiece, scenarioPiece.scenarioPieceId);
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioPiece.scenarioId));
  const quote = useAppSelector(state => selectQuoteById(state, quoteId));
  const plantedAcres = useAppSelector(state => scenario === null ? 0 : selectAcresForScenario(state, scenario.scenarioId)) ?? 0;
  const piecesForScenario = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioPiece.scenarioId);

  const perAcreValues = getFarmBillPerAcreIndemnities(scenarioPiece.scenarioPieceType,
    scenarioPiece.planCode,
    scenarioPiece.rowCropScenarioPieceExtendedData?.acres ?? 0,
    plantedAcres, calcResults?.indemnityAmount ?? 0);

  const title = getFarmBillDisplayName(scenarioPiece.scenarioPieceType, scenarioPiece.planCode, false);

  const expandedCardBody =
    <Grid container sx={{ pb: 1, pl: 0.5, mt: 1 }} rowSpacing={0.25}>
      <Grid item xs={12}><LabelCurrencyPair disabled={isReadonly} label="Per Base Acre" value={perAcreValues.indemnityPerBaseAcre} /></Grid>
      <Grid item xs={12}><LabelCurrencyPair disabled={isReadonly} label="Per Net Base Acre" value={perAcreValues.indemnityPerNetBaseAcre} /></Grid>
      <Grid item xs={12}><LabelCurrencyPair disabled={isReadonly} label="Per Planted Acre" value={perAcreValues.indemnityPerPlantedAcre} /></Grid>
    </Grid>;


  const updateScenarioPiece = async (scenarioPiece: RowCropScenarioPiece) => {
    if (scenario === null) throw new MissingScenarioInStateError(scenarioPiece.scenarioId);
    if (quote === null) throw new MissingQuoteInStateError(quoteId);

    const scenarioPieceValidations = validateRowCropScenario(piecesForScenario, scenario, quote, scenarioPiece);

    const scenarioPiecesToUpdate = scenarioPieceValidations.filter(validation => validation.shouldUpdate);
    const piecesToUpdate = scenarioPiecesToUpdate.map(validation => dispatch(modifyScenarioPiece({ scenarioPiece: validation.scenarioPiece })));
    await Promise.all(piecesToUpdate);
  };

  const removeScenarioPiece = async (scenarioPiece: RowCropScenarioPiece) => {
    dispatch(removeScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
  };

  return (
    <ScenarioPieceCard
      scenarioColor={scenarioColor}
      scenarioPiece={scenarioPiece}
      quoteId={quoteId}
      isScenarioActive={isScenarioActive}
      updateScenarioPiece={updateScenarioPiece}
      removeScenarioPiece={removeScenarioPiece}
      label={title}
      expandedCardBody={expandedCardBody}
      netValue={perAcreValues.indemnityPerBaseAcre}
      calcResults={calcResults}
      scenarioId={scenarioPiece.scenarioId}
      displayTooltip={false}
      isReadonly={isReadonly}
    />
  );
};

export default FarmBillScenarioPieceCard;
