import { ScoEcoPlusCalculationParams } from '../../../types/api/calculationData/scoEcoPlusCalculationParams';
import { getScoEcoPlusCalcData } from '../../calculationData.service';
import { distinctBy } from '../../../utils/arrayUtils';
import { ProductOfferAvailabilities, ScenarioPieceType } from '@silveus/calculations';
import { privateProductsDb } from '../../../db';
import { safeGet } from '../../../utils/dexieQueryHelpers/getClauses';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import { ScoEcoPlusCoverageLevelParams } from '../../../types/api/adm/PrivateProductsParams';
import CoverageLevelAvailability from '../../../types/api/adm/CoverageLevelAvailability';
import { getAvailableScoEcoPlusCoverageLevels } from '../../privateProducts.service';
import { safeWhere } from '../../../utils/dexieQueryHelpers/whereClauses';
import { ProductOfferAvailabilityParams } from './OfferAvailability';
import { ScoEcoPlusCalculationParamsRequest } from '../../../types/api/calculationData/scoEcoPlusCalculationParamsRequest';

export const getScoEcoPlusCalcDataRequest = async (calcParamRequest: ScoEcoPlusCalculationParamsRequest): Promise<ScoEcoPlusCalculationParams[]> => {
  const request = () => getScoEcoPlusCalcData(calcParamRequest);

  const readTransaction = async (): Promise<ScoEcoPlusCalculationParams[]> => {
    const scoEcoPlusResponse: ScoEcoPlusCalculationParams[] = [];

    const isScoPlus = [ScenarioPieceType.ScoPlusRevenue, ScenarioPieceType.ScoPlusYield].includes(calcParamRequest.scenarioPieceTypeId);

    const productName = isScoPlus ? 'SCOPlus' : 'ECOPlus';
    const coverageLevel = isScoPlus ? calcParamRequest.lowerCoverageLevel : calcParamRequest.upperCoverageLevel;

    const unitTransactions = calcParamRequest.distinctUnits.map(async distinctUnit => {
      const scoEcoPlusData = await privateProductsDb.transaction('r', privateProductsDb.ecoScoPlusRates, async () => {
        return safeGet(privateProductsDb.ecoScoPlusRates, {
          cropYear: calcParamRequest.year,
          countyId: calcParamRequest.countyId,
          commodityCode: calcParamRequest.commodityCode,
          practiceId: distinctUnit.practiceId,
          coverageLevelId: coverageLevel,
          productName: productName,
        });
      });

      if (scoEcoPlusData !== undefined) {
        const scoEcoPlusParams: ScoEcoPlusCalculationParams = {
          unit: distinctUnit,
          yieldRate: scoEcoPlusData.yieldRate,
          revenueRate: scoEcoPlusData.revenueRate,
        };

        scoEcoPlusResponse.push(scoEcoPlusParams);
      }
    });

    await Promise.all(unitTransactions);

    return scoEcoPlusResponse;
  };

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getAvailableScoEcoPlusCoverageLevelsRequest = async (scoEcoPlusCoverageLevelParams: ScoEcoPlusCoverageLevelParams): Promise<CoverageLevelAvailability[]> => {
  const request = () => getAvailableScoEcoPlusCoverageLevels(scoEcoPlusCoverageLevelParams);

  const readTransaction = () => privateProductsDb.transaction('r', privateProductsDb.ecoScoPlusRates, async () => {
    const isScoPlus = [ScenarioPieceType.ScoPlusRevenue, ScenarioPieceType.ScoPlusYield].includes(scoEcoPlusCoverageLevelParams.scenarioPieceType);
    const isEcoPlus = [ScenarioPieceType.EcoPlusRevenue, ScenarioPieceType.EcoPlusYield].includes(scoEcoPlusCoverageLevelParams.scenarioPieceType);
    if (!isScoPlus && !isEcoPlus)
      return [];

    const ecoScoPlusRates = await safeWhere(privateProductsDb.ecoScoPlusRates, {
      cropYear: scoEcoPlusCoverageLevelParams.year,
      countyId: scoEcoPlusCoverageLevelParams.countyId,
      productName: isScoPlus ? 'SCOPlus' : 'ECOPlus',
      commodityCode: scoEcoPlusCoverageLevelParams.commodityCode,
      practiceId: scoEcoPlusCoverageLevelParams.practiceId,
    }).toArray();

    if (isScoPlus) {
      return ecoScoPlusRates.map(scoRate => {
        const coverageLevelAvailability: CoverageLevelAvailability = {
          upperCoverageLevel: 86,
          lowerCoverageLevel: scoRate.coverageLevelId,
        };
        return coverageLevelAvailability;
      });
    } else {
      return ecoScoPlusRates.map(ecoRate => {
        const coverageLevelAvailability: CoverageLevelAvailability = {
          upperCoverageLevel: ecoRate.coverageLevelId,
          lowerCoverageLevel: 86,
        };
        return coverageLevelAvailability;
      });
    }
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getOfferAvailability = async (productOfferAvailabilityParams: ProductOfferAvailabilityParams): Promise<ProductOfferAvailabilities[]> => {
  const scenarioPieceTypes: ScenarioPieceType[] = [ScenarioPieceType.EcoPlusRevenue, ScenarioPieceType.EcoPlusYield, ScenarioPieceType.ScoPlusRevenue, ScenarioPieceType.ScoPlusYield];

  const offerAvailabilityPromises = scenarioPieceTypes.map(scenarioPieceType => {
    return privateProductsDb.transaction('r', privateProductsDb.ecoScoPlusRates, async () => {
      const isScoPlus = [ScenarioPieceType.ScoPlusRevenue, ScenarioPieceType.ScoPlusYield].includes(scenarioPieceType);

      const data = await safeWhere(privateProductsDb.ecoScoPlusRates, {
        cropYear: productOfferAvailabilityParams.year,
        countyId: productOfferAvailabilityParams.countyId,
        commodityCode: productOfferAvailabilityParams.commodityCode,
        productName: isScoPlus ? 'SCOPlus' : 'ECOPlus',
      }).toArray();

      const practiceIds = distinctBy(data.map(d => d.practiceId), x => x);

      const productOfferAvailability: ProductOfferAvailabilities = {
        scenarioPieceType: scenarioPieceType,
        productOfferAvailabilities: practiceIds.map(practice => ({ typeId: null, practiceId: practice, cropYear: null, extendedData: null })),
      };

      return productOfferAvailability;
    });
  });

  return Promise.all(offerAvailabilityPromises);
};
