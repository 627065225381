import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import NumberTextField, { onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { FormInputProps } from './formInputProps';
import { ReactNode } from 'react';
import { validatePrice } from '../../../pages/units/validations/priceValidation';

export interface PriceProps extends FormInputProps {
  name: string;
  price: Nullable<number>;
  toolTip?: ReactNode;
  label: string;
  allowZero?: boolean;
}

const PriceInput = ({ name, toolTip = undefined, price, sx, label, autoFocus = false, disabled = false, allowZero = true }: PriceProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value: Nullable<number>) => validatePrice(value, allowZero) }}
      defaultValue={price ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          type="number"
          label={label}
          fullWidth
          inputProps={{ step: 0.01, min: 0 }}
          InputProps={{
            endAdornment: toolTip,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          sx={sx}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};

export default PriceInput;
