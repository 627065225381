import { OrderByParameters, orderByProperty } from './arrayUtils';
import { stableEmptyArrayAsMutable } from './stableEmptyArray';

export function getItemsForId<T, U>(map: Map<T, U[]>, key: T): U[] {
  return map.get(key) ?? stableEmptyArrayAsMutable<U>();
}


export function orderMap<TKey, TValue>(map: Map<TKey, TValue[]>, parameters: OrderByParameters<TValue>) {
  const orderedMap = new Map<TKey, TValue[]>();

  for (const item of map) {
    const orderedArray = orderByProperty(item[1], parameters);
    orderedMap.set(item[0], orderedArray);
  }

  return orderedMap;
}