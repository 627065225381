import BaseUnit from '../types/api/BaseUnit';
import UnitYear from '../types/api/UnitYear';
import { UnitGroup } from '../types/api/UnitGroup';
import { createBaseUnit } from '../services/calculations/baseDataTransformations';
import { GroupingUnit, GroupingUnitGroup, UnitGroupingService, UnitStructureCode } from '@silveus/calculations';
import { toPrimaryKey } from './primaryKeyHelpers';
import { UnitGroupId, UnitYearId } from '../types/api/PrimaryKeys';
import { ScenarioPiece } from '../types/api/ScenarioPiece';
import { isRowCropScenarioPiece } from './castingUtils';

export const getCorrectUnitGroups = (baseUnits: BaseUnit[], availableUnits: UnitYear[], currentUnitGroups: UnitGroup[], scenarioPiece: ScenarioPiece): { groupsThatHaveChanged: UnitGroup[], groupsToAdd: UnitGroup[], groupsToRemove: UnitGroup[] } => {
  const unitDtos = baseUnits.map(baseUnit => createBaseUnit(baseUnit));

  const unitsForGrouping: GroupingUnit[] = unitDtos.map(unit => {
    const matchingUnit = availableUnits.find(uy => uy.unitYearId === unit.id) ?? null;
    const matchingBaseUnit = baseUnits.find(bu => bu.id === unit.id) ?? null;

    if (matchingUnit === null) throw new Error(`Unable to find corresponding unit for id: ${unit.id}`);

    return {
      ...unit,
      basicUnitNumber: matchingUnit.basicUnitNumber,
      optionalUnitNumber: matchingUnit.optionalUnitNumber,
      options: matchingBaseUnit?.options ?? [],
    };
  });

  //The grouping service needs whole unit objects on the groups, so map the UI groups to the groups used by the service
  const currentCalcUnitGroups: GroupingUnitGroup[] = currentUnitGroups.map(currentGroup => {
    const unitGroupUnitStructureCode = currentGroup.unitStructure;
    return {
      unitGroupId: currentGroup.unitGroupId,
      unitStructureType: unitGroupUnitStructureCode,
      units: unitsForGrouping.filter(ug => currentGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(ug.id))),
    };
  });

  const unitStructureCode = isRowCropScenarioPiece(scenarioPiece) ? scenarioPiece.unitStructure : UnitStructureCode.AU;

  const newCalcUnitGroups = UnitGroupingService.groupUnits(unitsForGrouping, unitStructureCode, currentCalcUnitGroups);

  //Map the new groups back to the format needed by the UI
  const unitGroups = newCalcUnitGroups.map(calcGroup => {
    const unitStructure = calcGroup.unitStructureType;

    const newGroup: UnitGroup = {
      unitGroupId: toPrimaryKey<UnitGroupId>(calcGroup.unitGroupId),
      scenarioPieceId: scenarioPiece.scenarioPieceId,
      unitStructure: unitStructure,
      unitYearIds: calcGroup.units.map(u => toPrimaryKey<UnitYearId>(u.id)),
      year: availableUnits[0].year,
      offlineCreatedOn: undefined,
      offlineLastUpdatedOn: undefined,
      offlineDeletedOn: undefined,
    };

    return newGroup;
  });

  const unitGroupIds = unitGroups.map(ug => ug.unitGroupId);

  const currentUnitGroupIds = currentUnitGroups.map(ug => ug.unitGroupId);

  const groupsToRemove = currentUnitGroups.filter(ug => !unitGroupIds.includes(ug.unitGroupId));
  const groupsToAdd = unitGroups.filter(ug => !currentUnitGroupIds.includes(ug.unitGroupId));

  const groupsToPotentiallyUpdate = currentUnitGroups.filter(ug => unitGroupIds.includes(ug.unitGroupId));

  //Having the groups that may include updates, now we need to go through all of those groups and
  //determine which of them actually have changes to the contents of the groups
  const groupsThatHaveChanged: UnitGroup[] = [];

  groupsToPotentiallyUpdate.forEach(ug => {
    const matchingNewGroup = unitGroups.find(nug => nug.unitGroupId === ug.unitGroupId) ?? null;

    if (matchingNewGroup === null) throw Error('Unit group previously determined to exist does not');

    const hasUnitStructureChanged = ug.unitStructure !== matchingNewGroup.unitStructure;

    //Sort before doing a compare because the order can change in the service, which results in a false positive for an update
    const unitGroupSortedUnitIds = ug.unitYearIds.slice().sort((a, b) => a > b ? 1 : -1);
    const matchingUnitGroupSortedUnitIds = matchingNewGroup.unitYearIds.slice().sort((a, b) => a > b ? 1 : -1);
    const haveAnyUnitsChanged = unitGroupSortedUnitIds.slice().some((id, index) => id !== matchingUnitGroupSortedUnitIds[index]) || ug.unitYearIds.length !== matchingNewGroup.unitYearIds.length;

    if (hasUnitStructureChanged || haveAnyUnitsChanged)
      groupsThatHaveChanged.push(matchingNewGroup);
  });

  return {
    groupsThatHaveChanged: groupsThatHaveChanged,
    groupsToAdd: groupsToAdd,
    groupsToRemove: groupsToRemove,
  };
};