import { CountyYieldInputGroupFields } from '../components/formInputs/scenario/countyYieldInputGroup';
import { IndividualYieldInputGroupFields } from '../components/formInputs/scenario/individualYieldInputGroup.component';
import { PriceInputGroupFields } from '../components/formInputs/scenario/priceInputGroup.component';
import { RowCropScenario } from '../types/api/RowCropScenario';

export const mergeScenarioWithYieldAndPriceUpdates = (scenario: RowCropScenario, updateData: Partial<IndividualYieldInputGroupFields & CountyYieldInputGroupFields & PriceInputGroupFields>) => {
  const quickUnit = scenario.quickUnit;
  return {
    ...scenario,
    projectedPrice: updateData.projectedPrice ?? scenario.projectedPrice,
    harvestPrice: updateData.harvestPrice ?? scenario.harvestPrice,
    actualProducerYield: updateData.producerYield ?? scenario.actualProducerYield,
    expectedCountyYield: updateData.expectedCountyYield ?? scenario.expectedCountyYield,
    actualCountyYield: updateData.actualCountyYield ?? scenario.actualCountyYield,
    quickUnit: quickUnit ?
      {
        ...quickUnit,
        aphYield: updateData.aphYield ?? quickUnit.aphYield,
        approvedYield: updateData.approvedYield ?? quickUnit.approvedYield,
        rateYield: updateData.rateYield ?? quickUnit.rateYield,
      }
      : null,
  };
};
