import { AppTaskValidationResult } from '../../../types/app/AppTaskValidationResult';

/**
 * React Hook Forms validate expects a response type of true | string where string is a single error message
 * and true means that the input is valid. These helper methods transform our AppTaskValidationResult to these standards
 * so we can use the individual validation methods used to validate app tasks to also validate the inputs themselves.
 */
export function handleValidationResult(validationResult: AppTaskValidationResult) {
  if (validationResult.isValid) return true;

  return validationResult.message;
}

export function handleValidationResults(validationResults: AppTaskValidationResult[]) {
  const invalidResults = validationResults.filter(x => !x.isValid);
  if (invalidResults.length > 0) {
    return invalidResults[0].message;
  }
  return true;
}