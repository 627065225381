import { ReactNode } from 'react';
import { formatCurrency, formatPercentDigits } from '../../../utils/formatNumbers';

interface Props {
  percentChanceAbove: number;
  matrixPrice: number;
  currentPrice: number;
  volatility: number;
  daysUntilHarvestDiscoveryEnd: number;
}

export const MatrixHeatMapTooltip = ({ percentChanceAbove, matrixPrice, currentPrice, volatility, daysUntilHarvestDiscoveryEnd }: Props) => {

  const matrixPriceAsCurrencyString = formatCurrency(matrixPrice);

  const percentChanceBelow = 1 - percentChanceAbove;

  return (
    <div>
      <RawDataRow>
        <strong>{formatPercentDigits(percentChanceAbove)}</strong> chance to be above <strong>{matrixPriceAsCurrencyString}</strong>
      </RawDataRow>

      <RawDataRow>
        <strong>{formatPercentDigits(percentChanceBelow)}</strong> chance to be below <strong>{matrixPriceAsCurrencyString}</strong>
      </RawDataRow>

      <RawDataRow>
        These projections were based on a current price of <strong>{formatCurrency(currentPrice)}</strong> and a current volatility of <strong>{formatPercentDigits(volatility)}</strong>
      </RawDataRow>

      <RawDataRow>
        Days until harvest discovery end: <strong>{daysUntilHarvestDiscoveryEnd}</strong>
      </RawDataRow>
    </div>
  );
};

interface RawDataRowProps {
  children: ReactNode;
}

const RawDataRow = ({ children }: RawDataRowProps) => {
  return (
    <div>
      {children}
    </div>
  );
};