import {
  Commodity,
  CoverageTypeCode,
  UnitOfMeasure,
  AphUnit,
  sum,
  AphPremiumParams,
  AphIndemnityParams,
  AphRequestDTO,
  StageCode,
  UnitStructureCode,
  OptionCode, State, Unit
} from '@silveus/calculations';
import { RootState } from '../../../app/store';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import BaseUnit from '../../../types/api/BaseUnit';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { getCalcParamRequestUnits, getMatchingCalcParamDataForUnit, getUnitsForScenarioPiece } from '../calculationUtils';
import AphCalculationParams from '../../../types/api/calculationData/aphCalculationParams';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { getStateCodeFromCountyId } from '../../../utils/adm';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getAphCalcDataRequest } from '../../requestInterception/scenarioPieces/aphRequestInterceptor';
import { AphCalculationParamsRequest } from '../../../types/api/calculationData/aphCalculationParamsRequest';
import { selectQuoteById } from '../../../app/quotesSlice';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { Nullable } from '../../../types/util/Nullable';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';

export const createAphData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  //Get the calc data back from the API
  const calcParamRequest = createCalcParamRequest(state, scenarioPiece, scenario, baseUnits);
  const returnedData = await getAphCalcDataRequest(calcParamRequest);

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createAphPlanDto(scenarioPiece, returnedData, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformAphData = (calcData: Nullable<AphCalculationParams>, unit: Nullable<BaseUnit>, scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece): AphPremiumParams & AphIndemnityParams => {
  const aphParams: AphPremiumParams & AphIndemnityParams = {
    commodityCode: (unit?.commodityCode ?? '') as Commodity,
    projectedPrice: scenario.projectedPrice ?? 0,
    priceElectionPercent: scenarioPiece.protectionFactor / 100,
    multipleCommodityAdjustmentFactor: calcData?.multipleCommodityAdjustmentFactor ?? 0,
    liabilityAdjustmentFactor: 1,
    contractPrice: scenario.projectedPrice ?? 0, // Defaulting to projected price until contract pricing is implemented. Will be a user entered field.
    contractPriceMax: calcData?.maximumContractPrice ?? 0,
    yieldConversionFactor: calcData?.yieldConversionFactor ?? 0,
    unitOfMeasure: (calcData?.unitOfMeasure ?? '') as UnitOfMeasure,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    unitStructureCode: scenarioPiece.unitStructure,
    coverageTypeCode: (scenarioPiece.rowCropScenarioPieceExtendedData?.isCat ?? false) ? CoverageTypeCode.C : CoverageTypeCode.A,
    isNativeSod: false,
    isBeginningOrVeteranFarmerOrRancher: false,
    isConservationCompliance: false,
    conservComplSubsidyReductionPercent: 0,
    subsidyPercent: calcData?.subsidyPercent ?? 0,
    isSkipRow: false,
    experienceFactor: (scenarioPiece.rowCropScenarioPieceExtendedData?.experienceFactor ?? 100) / 100,
    premiumSurchargeApplied: calcData?.premiumSurchargeApplied ?? '',
    previousYearYieldLimitationCode: calcData?.previousYearYieldLimitationCode ?? '',
    stateCode: getStateCodeFromCountyId(unit?.countyId ?? '') as State,
    insuredsActualCost: calcData?.insuredsActualCost ?? 0,
    harvestCostAmount: calcData?.harvestCostAmount ?? 0,
    underlyingGuaranteeAmount: 0,
    underlyingLiabilityAmount: 0,
    isCat: scenarioPiece.rowCropScenarioPieceExtendedData?.isCat ?? false,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
  };

  return aphParams;
};

const createAphUnits = (calcData: AphCalculationParams[], units: BaseUnit[], scenario: RowCropScenario): (AphUnit & Unit)[] => {
  const unitGroupAcres = sum(units, unit => unit.acres);

  return units.map(unit => {

    const matchingData = getMatchingCalcParamDataForUnit(unit, calcData);

    //TODO: Error Handling
    if (isNullOrUndefined(matchingData)) throw new Error('APH Data Transform State mismatch: Unable to find data returned from API associated with unit in state.');

    let unitDiscountLevels = matchingData.unitDiscountLevels.filter(udl => udl.areaHighQuantity !== null && udl.areaLowQuantity !== null && udl.areaHighQuantity >= unitGroupAcres && unitGroupAcres >= udl.areaLowQuantity);
    if (unitDiscountLevels.length === 0) {
      unitDiscountLevels = matchingData.unitDiscountLevels.filter(udl => udl.areaHighQuantity === null && udl.areaLowQuantity === null);
    }
    const unitDto = createBaseUnit(unit);
    const aphUnit: AphUnit & Unit = {
      ...unitDto,
      id: unit.id,
      baseRate: matchingData.baseRate,
      subCountyRate: matchingData.subCountyRate,
      unitDiscountLevels: unitDiscountLevels,
      coverageLevelDifferentials: matchingData.coverageLevelDifferentials,
      optionRates: matchingData.optionRates,
      guaranteeAdjustmentFactor: matchingData.guaranteeAdjustmentFactor ?? 1,
      stagePercentFactor: matchingData.stagePercentFactor,
      stagePricePercentFactor: matchingData.stagePricePercentFactor,
      isAcreageLimited: false, // This is not supported yet but a value is needed when performing calculations.
      maximumReplantGuaranteePerAcre: matchingData.maximumReplantGuaranteePerAcre,
      stageCode: matchingData.stageCode as StageCode,
      depreciationFactor: matchingData.depreciationFactor,
      determinedPounds: unit.actualYield, // This may need to be a separate user entered field. It only used for mustard and oysters
      reportedPounds: unit.approvedYield, // This may need to be a separate user entered field. It only used for mustard and oysters
      //TODO #61367: This is a temporary workaround until we get a more permanent solution for handling options per scenario piece
      options: unit.options.filter(optionCode => optionCode !== OptionCode.TS),
    };

    return aphUnit;
  });
};

const createAphPlanDto = (scenarioPiece: RowCropScenarioPiece, apiData: AphCalculationParams[], unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): AphRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createAphUnits(
        apiData,
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformAphData(apiData.at(0) ?? null, unitData.at(0) ?? null, scenario, scenarioPiece),
  };
};

const createCalcParamRequest = (state: RootState, scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, baseUnits: BaseUnit[]): AphCalculationParamsRequest => {
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  return {
    year: clientFile.year,
    highRiskTypeId: scenario.highRiskTypeId,
    coverageTypeCode: (scenarioPiece.rowCropScenarioPieceExtendedData?.isCat ?? false) ? CoverageTypeCode[CoverageTypeCode.C] : CoverageTypeCode[CoverageTypeCode.A],
    unitStructureCode: UnitStructureCode[scenarioPiece.unitStructure],
    planCode: scenarioPiece.planCode,
    upperCoverageLevel: scenarioPiece.upperCoverageLevel,
    commodityCode: quote.commodityCode,
    countyId: quote.countyId,
    distinctUnits: getCalcParamRequestUnits(baseUnits),
  };
};
