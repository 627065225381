import { useParams } from 'react-router-dom';
import { AgentTeamId, ClientFileId, InsuredId } from '../../types/api/PrimaryKeys';
import { useSetClientFileContext } from '../../hooks/routeDataHooks';
import { ClientFileContent } from './component/clientFile.content';
import { isNotNullOrUndefined } from '../../utils/nullHandling';

const ClientFile = () => {
  const { insuredId, agentTeamId, clientFileId } = useParams() as { insuredId: InsuredId, agentTeamId: AgentTeamId, clientFileId: ClientFileId };
  const ownership = isNotNullOrUndefined(insuredId)
    ? { insuredId, agentTeamId: null }
    : { agentTeamId, insuredId: null };

  useSetClientFileContext(clientFileId, ownership);

  return (
    <ClientFileContent clientFileId={clientFileId} />
  );
};

export default ClientFile;
