import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferUnitStructures } from '../../../app/admSlice';
import  { UnitStructureInput, UnitStructureProps } from './unitStructureInput.component';
import { UnitStructureAttributes } from '@silveus/calculations';

export const AdmUnitStructureInput = ({ unitStructure, autoFocus = false, disabled = false }: UnitStructureProps) => {
  const unitStructures = useAppSelector(selectOfferUnitStructures);
  const availableUnitStructures = Object.values(UnitStructureAttributes)
    .filter(us => unitStructures.includes(us.value));
  return (<UnitStructureInput unitStructure={unitStructure}
    availableUnitStructures={availableUnitStructures} autoFocus={autoFocus} disabled={disabled} />);
};
