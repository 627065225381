import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ScenarioResponseDTO, ScenarioPieceResponseDTO, ScenarioRequestDTO, ScenarioPieceGroupResponseDTO } from '@silveus/calculations';
import { Nullable } from '../types/util/Nullable';
import { HailScenarioPieceCompositionId, QuoteId, ScenarioId, ScenarioPieceGroupId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import {
  getForwardSoldScenarioRequest,
  getHailScenarioRequest,
  getHarvestRevenueScenarioRequest,
  getScenarioRequest,
  runCalculationForScenario
} from '../services/calculations/calculationDelegate.service';
import {
  getKeyedStateToMap,
  getKeyedStateValues,
  initialKeyedState,
  KeyedState,
  updateKeyedState
} from './sliceHelpers';
import { modifyUnitYearsForScenario, selectAllScenariosByQuoteIdMap } from './scenariosSlice';
import { getItemsForId } from '../utils/mapHelpers';
import { selectQuoteById } from './quotesSlice';
import { createAppAsyncThunk } from './thunkHelpers';
import ForwardSoldScenarioPiece from '../types/api/ForwardSoldScenarioPiece';
import HarvestRevenueScenarioPiece from '../types/api/HarvestRevenueScenarioPiece';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import { toPrimaryKey } from '../utils/primaryKeyHelpers';
import HailScenarioPiece from '../types/api/HailScenarioPiece';

export interface CalculationResultsState {
  allCalculationResults: KeyedState<string, ScenarioResponseDTO>;
  scenarioRequests: KeyedState<ScenarioId, ScenarioRequestDTO>;
  scenariosThatAreLoadingCalculations: KeyedState<ScenarioId, true>;
}

const initialState: CalculationResultsState = {
  allCalculationResults: initialKeyedState(),
  scenarioRequests: initialKeyedState(),
  scenariosThatAreLoadingCalculations: initialKeyedState(),
};

export const calculationResultsSlice = createSlice({
  name: 'calculationResults',
  initialState: initialState,
  reducers: {
    addCalculatedResults(state, action: PayloadAction<ScenarioResponseDTO | ScenarioResponseDTO[]>) {
      updateKeyedState(state.allCalculationResults, action.payload, c => c.id);
    },
    addScenarioRequests(state, action: PayloadAction<ScenarioRequestDTO | ScenarioRequestDTO[]>) {
      updateKeyedState(state.scenarioRequests, action.payload, c => c.id);
    },
    setScenarioIsLoadingCalculations(state, action: PayloadAction<{ scenarioId: ScenarioId, isLoading: boolean }>) {
      // This is acting as basically a glorified Set (but you're not supposed to store Sets in redux)
      if (action.payload.isLoading) {
        state.scenariosThatAreLoadingCalculations[action.payload.scenarioId] = true;
      } else {
        delete state.scenariosThatAreLoadingCalculations[action.payload.scenarioId];
      }
    },
  },
});

export const updateAllScenarioCalculationsForQuote = createAppAsyncThunk(
  'calculations/updateForQuote',
  async ({ quoteId }: { quoteId: QuoteId }, thunkApi) => {
    const state = thunkApi.getState();

    const scenariosForQuote = getItemsForId(selectAllScenariosByQuoteIdMap(state), quoteId);
    const quote = selectQuoteById(state, quoteId);
    if (!(quote?.quickQuote ?? false)) {
      const scenarioUpdates = scenariosForQuote.map(scenario => {
        return thunkApi.dispatch(modifyUnitYearsForScenario({ scenarioId: scenario.scenarioId }));
      });

      await Promise.all(scenarioUpdates);
    }
    await thunkApi.dispatch(updateCalculationForScenarios({ scenarioIds: scenariosForQuote.map(s => s.scenarioId) }));
  });

export const updateCalculationForScenarios = createAppAsyncThunk(
  'calculations/updateForScenarios',
  async ({ scenarioIds }: {
    scenarioIds: ScenarioId[]
  }, thunkApi) => {
    const state = thunkApi.getState();

    const scenarioRequestPromises = scenarioIds.map(async id => {
      // Set loading state for scenario in question.
      thunkApi.dispatch(setScenarioIsLoadingCalculations({ scenarioId: id, isLoading: true }));

      // Capture the promise associated with acquiring calculation inputs from the API.
      const requestPromise = getScenarioRequest(state, id);

      try {
        // Acquire and set calculation results.
        const request = await requestPromise;
        const calculationResults = runCalculationForScenario(request);
        thunkApi.dispatch(addCalculatedResults(calculationResults));
      } finally {
        // Adjust loading state to not loading regardless of if anything above fails.
        thunkApi.dispatch(setScenarioIsLoadingCalculations({ scenarioId: id, isLoading: false }));
      }

      // Return only the REQUEST promises. The caller doesn't need to be able to see the loading state through.
      return requestPromise;
    });

    //If there was a failed scenario this was never finishing with .all() even if some of scenarios were working.
    //It was silently causing problems with matrix calculations even if the matrix only included non failing scenarios.
    //This makes it so that it keeps going with only the successful requests.
    const requests = (await Promise.allSettled(scenarioRequestPromises)).filter((resp): resp is PromiseFulfilledResult<ScenarioRequestDTO> => resp.status === 'fulfilled').map(resp => resp.value);

    // Cache all the scenario requests at once for use throughout analysis portion of the application.
    // This is done intentionally as a batch because things depending on this may need to run heavy calculations each time this updates in state.
    thunkApi.dispatch(addScenarioRequests(requests));
  });

export const updateCalculationForScenario = createAppAsyncThunk(
  'calculations/updateForScenario',
  async ({ scenarioId }: { scenarioId: ScenarioId }, thunkApi) => {
    await thunkApi.dispatch(updateCalculationForScenarios({ scenarioIds: [scenarioId] }));
  });

export const { addCalculatedResults, setScenarioIsLoadingCalculations, addScenarioRequests } = calculationResultsSlice.actions;

// Non-Memoized Selectors
export const selectCalculationsForScenario = (state: RootState, scenarioId: ScenarioId): Nullable<ScenarioResponseDTO> => state.calculationResults.allCalculationResults[scenarioId] ?? null;
export const selectScenariosThatAreLoadingCalculations = (state: RootState) => state.calculationResults.scenariosThatAreLoadingCalculations;
export const selectScenarioRequests = (state: RootState) => state.calculationResults.scenarioRequests;
export const selectScenarioRequestForScenario = (state: RootState, scenarioId: ScenarioId): Nullable<ScenarioRequestDTO> => selectScenarioRequests(state)[scenarioId] ?? null;
export const selectCalculationResults = (state: RootState) => state.calculationResults.allCalculationResults;

export const selectCalculationsForScenarios = createSelector([selectCalculationResults, (state: RootState, scenarioIds: ScenarioId[]) => scenarioIds], ((calculationResults, scenarioIds) => {
  const calcResultsAsMap = getKeyedStateToMap(calculationResults);
  const calculationsByScenario = new Map<ScenarioId, ScenarioResponseDTO>();

  for (const [scenarioId, calculationResult] of calcResultsAsMap) {
    if (scenarioIds.includes(scenarioId as ScenarioId)) calculationsByScenario.set(scenarioId as ScenarioId, calculationResult);
  }

  return calculationsByScenario;
}));

// Memoized Selectors:
export const selectCalculationsByScenarioPieceGroup = createSelector([selectCalculationResults], calculationResults => {
  const scenarioPieceGroups = getKeyedStateValues(calculationResults).flatMap(r => r.scenarioPieceGroups);

  const scenarioPieceGroupsByScenarioPieceGroupIdMap = new Map<ScenarioPieceGroupId, ScenarioPieceGroupResponseDTO>();

  for (const g of scenarioPieceGroups) {
    scenarioPieceGroupsByScenarioPieceGroupIdMap.set(toPrimaryKey<ScenarioPieceGroupId>(g.id), g);
  }

  return scenarioPieceGroupsByScenarioPieceGroupIdMap;
});

export const selectCalculationsByScenarioPiece = createSelector([selectCalculationResults], calculationResults => {
  const scenarioPieces = getKeyedStateValues(calculationResults).flatMap(r => r.scenarioPieceGroups).flatMap(spg => spg.scenarioPieces);

  const scenarioPiecesByScenarioPieceIdMap = new Map<ScenarioPieceId, ScenarioPieceResponseDTO>();

  for (const p of scenarioPieces) {
    scenarioPiecesByScenarioPieceIdMap.set(toPrimaryKey<ScenarioPieceId>(p.id), p);
  }

  return scenarioPiecesByScenarioPieceIdMap;
});

export const selectScenarioPieceCalculationsForScenario = createSelector([selectCalculationsForScenario], calculationResults => {
  return calculationResults?.scenarioPieceGroups.flatMap(spg => spg.scenarioPieces) ?? stableEmptyArrayAsMutable<ScenarioPieceResponseDTO>();
});

export const calculateForwardSold = createAppAsyncThunk('calculations/calculateForwardSold', async ({ scenarioId, forwardSoldScenarioPiece }: { scenarioId: ScenarioId, forwardSoldScenarioPiece: ForwardSoldScenarioPiece }, thunkApi) => {
  const state = thunkApi.getState();
  const scenarioRequestDto = await getForwardSoldScenarioRequest(state, scenarioId, forwardSoldScenarioPiece);
  const scenarioResponse = runCalculationForScenario(scenarioRequestDto);
  return scenarioResponse;
});

export const calculateHarvestRevenue = createAppAsyncThunk('calculations/calculateHarvestRevenue', async ({ scenarioId, harvestRevenueScenarioPiece, forwardSoldScenarioPiece }: { scenarioId: ScenarioId, harvestRevenueScenarioPiece: HarvestRevenueScenarioPiece, forwardSoldScenarioPiece: ForwardSoldScenarioPiece | undefined }, thunkApi) => {
  const state = thunkApi.getState();

  const scenarioRequestDto = await getHarvestRevenueScenarioRequest(state, scenarioId, harvestRevenueScenarioPiece, forwardSoldScenarioPiece);
  const scenarioResponse = runCalculationForScenario(scenarioRequestDto);
  return scenarioResponse;
});

export const getHailCalculateRequest = createAppAsyncThunk('calculations/getHailCalculateRequest', async ({ scenarioId, hailScenarioPieceCompositionId, hailScenarioPieces }:
  { scenarioId: ScenarioId, hailScenarioPieceCompositionId: HailScenarioPieceCompositionId, hailScenarioPieces: HailScenarioPiece[] }, thunkApi) => {
  const state = thunkApi.getState();
  const scenarioRequestDto = await getHailScenarioRequest(state, scenarioId, hailScenarioPieceCompositionId, hailScenarioPieces);
  return scenarioRequestDto;
});

export default calculationResultsSlice.reducer;
