import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './store';
import {
  HailChartLineDataId,
  HailProductId,
  HailScenarioPieceCompositionId,
  HailScenarioPieceEndorsementId,
  HailScenarioPieceId,
  HailScenarioPieceRateId,
  ScenarioPieceId,
  UnitYearId
} from '../types/api/PrimaryKeys';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import HailScenarioPiece from '../types/api/HailScenarioPiece';
import HailScenarioPieceRate from '../types/api/hail/HailScenarioPieceRate';
import HailScenarioPieceEndorsement from '../types/api/hail/HailScenarioPieceEndorsement';
import { createAppAsyncThunk } from './thunkHelpers';
import {
  getKeyedStateGroupedBy,
  getKeyedStateToMap,
  getKeyedStateValues,
  initialKeyedState,
  KeyedState,
  removeFromKeyedState,
  updateKeyedState
} from './sliceHelpers';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import { generatePrimaryKey, toPrimaryKey } from '../utils/primaryKeyHelpers';
import { getItemsForId } from '../utils/mapHelpers';
import SelectedUnitIds from '../types/app/SelectedUnitIds';
import HailChartLineData from '../types/api/hail/hailChartLineData';
import { ScenarioPieceGroupResponseDTO, ScenarioPieceResponseDTO, ScenarioRequestDTO } from '@silveus/calculations';
import { getHailCalculateRequest } from './calculationResultsSlice';
import { runCalculationForScenario } from '../services/calculations/calculationDelegate.service';

interface HailModalState {
  isHailModalOpen: boolean;
  hailScenarioPieceCompositionId: HailScenarioPieceCompositionId;
  hailScenarioPieces: SliceDataState<HailScenarioPieceId, HailScenarioPiece>;
  hailScenarioPieceRates: SliceDataState<HailScenarioPieceRateId, HailScenarioPieceRate>;
  hailScenarioPieceEndorsements: SliceDataState<HailScenarioPieceEndorsementId, HailScenarioPieceEndorsement>;
  hailScenarioPieceUnits: KeyedState<HailScenarioPieceId, SelectedUnitIds>;
  hailChartLineData: SliceDataState<HailChartLineDataId, HailChartLineData>;
  hailCalculationRequest: ScenarioRequestDTO | undefined;
  hailTotals: ScenarioPieceGroupResponseDTO | undefined;
  hailScenarioPieceResults: SliceDataState<ScenarioPieceId, ScenarioPieceResponseDTO>;
}

const initialState: HailModalState = {
  isHailModalOpen: false,
  hailScenarioPieceCompositionId: generatePrimaryKey<HailScenarioPieceCompositionId>(),
  hailScenarioPieces: initialSliceDataState(),
  hailScenarioPieceRates: initialSliceDataState(),
  hailScenarioPieceEndorsements: initialSliceDataState(),
  hailScenarioPieceUnits: initialKeyedState(),
  hailChartLineData: initialSliceDataState(),
  hailCalculationRequest: undefined,
  hailTotals: undefined,
  hailScenarioPieceResults: initialSliceDataState(),
};

export const hailModalSlice = createSlice({
  name: 'hailModal',
  initialState: initialState,
  reducers: {
    toggleHailModal(state) {
      if (state.isHailModalOpen) {
        //When the hail modal closes we should reset the state
        return initialState;
      } else {
        state.isHailModalOpen = true;
      }
    },
    setHailScenarioPieces(state, action: PayloadAction<HailScenarioPiece[]>) {
      updateKeyedState(state.hailScenarioPieces.data, action.payload, hsp => hsp.hailScenarioPieceId);
    },
    removeHailScenarioPieces(state, action: PayloadAction<HailScenarioPiece[]>) {
      removeFromKeyedState(state.hailScenarioPieces.data, action.payload.map(sp => sp.hailScenarioPieceId));
    },
    setHailScenarioPieceRates(state, action: PayloadAction<HailScenarioPieceRate[]>) {
      updateKeyedState(state.hailScenarioPieceRates.data, action.payload, spr => spr.hailScenarioPieceRateId);
    },
    removeHailScenarioPieceRatesAction(state, action: PayloadAction<HailScenarioPieceRate[]>) {
      removeFromKeyedState(state.hailScenarioPieceRates.data, action.payload.map(spr => spr.hailScenarioPieceRateId));
    },
    setHailScenarioPieceEndorsements(state, action: PayloadAction<HailScenarioPieceEndorsement[]>) {
      updateKeyedState(state.hailScenarioPieceEndorsements.data, action.payload, spe => spe.hailScenarioPieceEndorsementId);
    },
    removeHailScenarioPieceEndorsementsAction(state, action: PayloadAction<HailScenarioPieceEndorsement[]>) {
      removeFromKeyedState(state.hailScenarioPieceEndorsements.data, action.payload.map(spe => spe.hailScenarioPieceEndorsementId));
    },
    setHailScenarioPieceUnits(state, action: PayloadAction<{ hailScenarioPieceId: HailScenarioPieceId, unitYearIds: SelectedUnitIds }>) {
      updateKeyedState(state.hailScenarioPieceUnits, action.payload.unitYearIds, x => action.payload.hailScenarioPieceId);
    },
    removeHailScenarioPieceUnits(state, action: PayloadAction<{ hailScenarioPieceId: HailScenarioPieceId, unitYearIds: SelectedUnitIds }>) {
      removeFromKeyedState(state.hailScenarioPieceUnits, action.payload.hailScenarioPieceId);
    },
    setHailCalcRequest(state, action: PayloadAction<ScenarioRequestDTO | undefined>) {
      state.hailCalculationRequest = action.payload;
    },
    setHailTotals(state, action: PayloadAction<ScenarioPieceGroupResponseDTO | undefined>) {
      state.hailTotals = action.payload;
    },
    setHailScenarioPieceResults(state, action: PayloadAction<ScenarioPieceResponseDTO[]>) {
      updateKeyedState(state.hailScenarioPieceResults.data, action.payload, spr => spr.id);
    },
  },
  extraReducers(builder) {
    //Chart Line Data
    const asyncHandlerBuilderHailChartLineData = getAsyncHandlerBuilder(builder, s => s.hailChartLineData, s => s.hailChartLineDataId);
    asyncHandlerBuilderHailChartLineData.generateAsyncHandlers({
      action: 'updating', thunk: toggleHailLineDataVisibility,
      affectedIds: arg => arg.lineData.hailChartLineDataId,
    });
    asyncHandlerBuilderHailChartLineData.generateAsyncHandlers({
      action: 'adding', thunk: addHailChartLineData,
      affectedIds: arg => arg.hailChartLineData.map(p => p.hailChartLineDataId),
    });
    asyncHandlerBuilderHailChartLineData.generateAsyncHandlers({
      action: 'updating', thunk: modifyHailChartLineData,
      affectedIds: arg => arg.hailChartLineData.map(p => p.hailChartLineDataId),
    });
    asyncHandlerBuilderHailChartLineData.generateAsyncHandlers({
      action: 'deleting', thunk: removeHailChartLineData,
      affectedIds: arg => arg.hailChartLineData.map(p => p.hailChartLineDataId),
    });
  },
});

export const { toggleHailModal,
  setHailScenarioPieces,
  removeHailScenarioPieces,
  setHailScenarioPieceRates,
  removeHailScenarioPieceRatesAction,
  setHailScenarioPieceEndorsements,
  removeHailScenarioPieceEndorsementsAction,
  setHailScenarioPieceUnits,
  removeHailScenarioPieceUnits,
  setHailCalcRequest,
  setHailTotals,
  setHailScenarioPieceResults,
} = hailModalSlice.actions;

//Selectors
export const selectIsHailModalOpen = (state: RootState) => state.hailModal.isHailModalOpen;
export const selectHailScenarioPieceCompositionId = (state: RootState) => state.hailModal.hailScenarioPieceCompositionId;
export const selectHailCalcRequest = (state: RootState) => state.hailModal.hailCalculationRequest;
export const selectHailTotals = (state: RootState) => state.hailModal.hailTotals;
export const selectHailScenarioPieceModalUnitsByHailScenarioPieceId = (state: RootState, hailScenarioPieceId: HailScenarioPieceId) => state.hailModal.hailScenarioPieceUnits[hailScenarioPieceId] ?? { unitYearIds: stableEmptyArrayAsMutable<UnitYearId>() };
export const selectHailChartLineData = (state: RootState) => state.hailModal.hailChartLineData.data;
export const selectAllHailChartLineData = createSelector([selectHailChartLineData], result => {
  return getKeyedStateValues(result);
});

export const selectHailScenarioPieceResults = (state: RootState) => state.hailModal.hailScenarioPieceResults.data;
export const selectHailScenarioPieceModalResultsByScenarioPieceId = createSelector([selectHailScenarioPieceResults], selectHailScenarioPieceResults => {
  const hailScenarioPieceResults = getKeyedStateValues(selectHailScenarioPieceResults);
  const hailScenarioPieceResultsMap = new Map<ScenarioPieceId, ScenarioPieceResponseDTO>();
  for (const result of hailScenarioPieceResults) {
    hailScenarioPieceResultsMap.set(toPrimaryKey<ScenarioPieceId>(result.id), result);
  }
  return hailScenarioPieceResultsMap;
});

export const selectHailScenarioPieces = (state: RootState) => state.hailModal.hailScenarioPieces.data;
export const selectHailScenarioPiecesByHailProductId = createSelector([selectHailScenarioPieces], selectedHailScenarioPieces => {
  const hailScenarioPieces = getKeyedStateValues(selectedHailScenarioPieces);
  const hailScenarioPieceByHailProductIdMap = new Map<HailProductId, HailScenarioPiece>();
  for (const hsp of hailScenarioPieces) {
    hailScenarioPieceByHailProductIdMap.set(hsp.hailProductId, hsp);
  }
  return hailScenarioPieceByHailProductIdMap;
});

export const selectHailScenarioPieceByHailScenarioPieceId = createSelector([selectHailScenarioPieces], selectedHailScenarioPieces => {
  const hailScenarioPieces = getKeyedStateValues(selectedHailScenarioPieces);
  const hailScenarioPieceByHailScenarioPieceMap = new Map<HailScenarioPieceId, HailScenarioPiece>();
  for (const hsp of hailScenarioPieces) {
    hailScenarioPieceByHailScenarioPieceMap.set(hsp.hailScenarioPieceId, hsp);
  }
  return hailScenarioPieceByHailScenarioPieceMap;
});

const selectHailScenarioPieceRates = (state: RootState) => state.hailModal.hailScenarioPieceRates.data;
export const selectHailScenarioPieceModalRatesByHailScenarioPieceMap = createSelector([selectHailScenarioPieceRates], result => {
  const map = getKeyedStateGroupedBy(result, sp => sp.hailScenarioPieceId);
  return map;
});

const selectHailScenarioPieceEndorsements = (state: RootState) => state.hailModal.hailScenarioPieceEndorsements.data;
export const selectHailScenarioPieceModalEndorsementsByHailScenarioPieceMap = createSelector([selectHailScenarioPieceEndorsements], result => {
  const map = getKeyedStateGroupedBy(result, sp => sp.hailScenarioPieceId);
  return map;
});

const selectHailScenarioPieceUnits = (state: RootState) => state.hailModal.hailScenarioPieceUnits;
export const selectHailScenarioPieceModalUnitsByHailScenarioPiece = createSelector([selectHailScenarioPieceUnits], selectedHailScenarioPieces => {
  const hailScenarioPieceUnitsByHailScenarioPieceMap = getKeyedStateToMap(selectedHailScenarioPieces);
  return hailScenarioPieceUnitsByHailScenarioPieceMap;
});

//Hail Scenario Piece Thunks
export const addHailScenarioPiece = (
  { hailScenarioPiece, hailScenarioPieceRates, hailScenarioPieceEndorsements, hailScenarioPieceUnits }:
        { hailScenarioPiece: HailScenarioPiece, hailScenarioPieceRates: HailScenarioPieceRate[], hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[], hailScenarioPieceUnits: UnitYearId[] }): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch(setHailScenarioPieceRates(hailScenarioPieceRates));
  dispatch(setHailScenarioPieceEndorsements(hailScenarioPieceEndorsements));
  const selectedUnitsObject: SelectedUnitIds = { unitYearIds: hailScenarioPieceUnits };
  dispatch(setHailScenarioPieceUnits({ hailScenarioPieceId: hailScenarioPiece.hailScenarioPieceId, unitYearIds: selectedUnitsObject }));
  dispatch(setHailScenarioPieces([hailScenarioPiece]));
  await dispatch(recalculateAllHailScenarioPieces());
};

export const modifyHailScenarioPiece = ({ hailScenarioPiece }: { hailScenarioPiece: HailScenarioPiece }): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch(setHailScenarioPieces([hailScenarioPiece]));
  await dispatch(recalculateAllHailScenarioPieces());
};

export const removeHailScenarioPiece = ({ hailScenarioPiece }: { hailScenarioPiece: HailScenarioPiece }): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const hailScenarioPieceRates = getItemsForId(selectHailScenarioPieceModalRatesByHailScenarioPieceMap(state), hailScenarioPiece.hailScenarioPieceId);
  dispatch(removeHailScenarioPieceRatesAction(hailScenarioPieceRates));

  const hailScenarioPieceEndorsements = getItemsForId(selectHailScenarioPieceModalEndorsementsByHailScenarioPieceMap(state), hailScenarioPiece.hailScenarioPieceId);
  dispatch(removeHailScenarioPieceEndorsementsAction(hailScenarioPieceEndorsements));

  const selectedUnitsObject: SelectedUnitIds = { unitYearIds: [] };
  dispatch(removeHailScenarioPieceUnits({ hailScenarioPieceId: hailScenarioPiece.hailScenarioPieceId, unitYearIds: selectedUnitsObject }));
  dispatch(removeHailScenarioPieces([hailScenarioPiece]));
  await dispatch(recalculateAllHailScenarioPieces());
};

//Hail Scenario Piece Rate Thunks
export const addHailScenarioPieceRates = (
  { hailScenarioPieceRates }:
    { hailScenarioPieceRates: HailScenarioPieceRate[] }): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch(setHailScenarioPieceRates(hailScenarioPieceRates));
  await dispatch(recalculateAllHailScenarioPieces());
};

export const modifyHailScenarioPieceRates = (
  { hailScenarioPieceRates }:
        { hailScenarioPieceRates: HailScenarioPieceRate[] }): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch(setHailScenarioPieceRates(hailScenarioPieceRates));
  await dispatch(recalculateAllHailScenarioPieces());
};

export const removeHailScenarioPieceRates = (
  { hailScenarioPieceRates }:
        { hailScenarioPieceRates: HailScenarioPieceRate[] }): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch(removeHailScenarioPieceRatesAction(hailScenarioPieceRates));
  await dispatch(recalculateAllHailScenarioPieces());
};

//Hail Scenario Piece Endorsement Thunks
export const addHailScenarioPieceEndorsements = (
  { hailScenarioPieceEndorsements }:
        { hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[] }): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch(setHailScenarioPieceEndorsements(hailScenarioPieceEndorsements));
  await dispatch(recalculateAllHailScenarioPieces());
};

export const removeHailScenarioPieceEndorsements = (
  { hailScenarioPieceEndorsements }:
        { hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[] }): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch(removeHailScenarioPieceEndorsementsAction(hailScenarioPieceEndorsements));
  await dispatch(recalculateAllHailScenarioPieces());
};

export const modifyHailScenarioPieceUnits = (
  { hailScenarioPieceId, unitYearIds }:
      { hailScenarioPieceId: HailScenarioPieceId, unitYearIds: UnitYearId[] }): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const selectedUnits: SelectedUnitIds = { unitYearIds: unitYearIds };
  dispatch(setHailScenarioPieceUnits({ hailScenarioPieceId: hailScenarioPieceId, unitYearIds: selectedUnits }));
  await dispatch(recalculateAllHailScenarioPieces());
};
//Hail Chart Thunks
export const toggleHailLineDataVisibility = createAppAsyncThunk('hailModal/toggleVisibility', async ({ lineData }: { lineData: HailChartLineData }) => {
  const toggleLineData = { ...lineData, isVisible: !lineData.isVisible };
  return toggleLineData;
});
export const addHailChartLineData = createAppAsyncThunk('hailModal/addHailChartLineData',
  async ({ hailChartLineData }: { hailChartLineData: HailChartLineData[] }, thunkApi) => {
    return hailChartLineData;
  });
export const modifyHailChartLineData = createAppAsyncThunk('hailModal/modifyHailChartLineData',
  async ({ hailChartLineData }: { hailChartLineData: HailChartLineData[] }, thunkApi) => {
    const state = thunkApi.getState();
    const existingItems = selectAllHailChartLineData(state);
    const itemsToRemove = existingItems.filter(x => !hailChartLineData.find(ld => ld.dataKey === x.dataKey));
    if (itemsToRemove.length > 0) {
      await thunkApi.dispatch(removeHailChartLineData({ hailChartLineData: itemsToRemove }));
    }
    return hailChartLineData;
  });
export const removeHailChartLineData = createAppAsyncThunk('hailModal/removeHailChartLineData',
  async ({ hailChartLineData }: { hailChartLineData: HailChartLineData[] }, thunkApi) => {
    return hailChartLineData;
  });


const recalculateAllHailScenarioPieces = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const hailScenarioPieces = getKeyedStateValues(state.hailModal.hailScenarioPieces.data);
  const selectedScenarioId = state.scenarios.currentlySelectedScenarioId;
  const hailCompositionId = state.hailModal.hailScenarioPieceCompositionId;

  if (selectedScenarioId === null || hailScenarioPieces.length === 0){
    dispatch(setHailTotals(undefined));
    dispatch(setHailScenarioPieceResults([]));
    return;
  }

  const scenarioRequestDtoResponse = await dispatch(getHailCalculateRequest({
    scenarioId: selectedScenarioId,
    hailScenarioPieceCompositionId: hailCompositionId,
    hailScenarioPieces: hailScenarioPieces }));

  if (!getHailCalculateRequest.fulfilled.match(scenarioRequestDtoResponse)) return;
  const scenarioRequestDto = scenarioRequestDtoResponse.payload;
  setHailCalcRequest(scenarioRequestDto);
  const calcResults = runCalculationForScenario(scenarioRequestDto);

  //Hail Modal only passes in a single scenario piece group, so we can just grab the first one
  const totalHailResult = calcResults.scenarioPieceGroups.find(spg => spg.id === hailCompositionId);
  dispatch(setHailTotals(totalHailResult));
  dispatch(setHailScenarioPieceResults(totalHailResult?.scenarioPieces ?? []));
};

export default hailModalSlice.reducer;