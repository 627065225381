import { Grid } from '@mui/material';
import LabelValuePair, { LabelCurrencyPair } from '../../components/labelValuePair/labelValuePair.component';
import * as React from 'react';
import { ScenarioPieceGroupResponseDTO, ScenarioPieceResponseDTO } from '@silveus/calculations';
import { Nullable } from '../../types/util/Nullable';

interface InsuranceResultSummaryProps {
  calcResults: Nullable<ScenarioPieceResponseDTO | ScenarioPieceGroupResponseDTO>;
  hideCoverage?: boolean;
  hideTrigger?: boolean;
  isReadonly: boolean;
}

const InsuranceResultSummary = ({ calcResults, hideCoverage = false, hideTrigger = false, isReadonly }: InsuranceResultSummaryProps) => {
  return (
    <Grid container sx={{ pb: 1, pl: 0.5 }} rowSpacing={0.25}>
      {!hideCoverage && <Grid item xs={12}><LabelCurrencyPair disabled={isReadonly} label="Coverage" value={calcResults?.protectionAmountPerAcre ?? 0} /></Grid>}
      <Grid item xs={12}><LabelCurrencyPair disabled={isReadonly} label="Producer Premium" value={calcResults?.producerPremiumPerAcre ?? 0} /></Grid>
      {!hideTrigger && <Grid item xs={12}><LabelValuePair disabled={isReadonly} label="Trigger Yield" value={calcResults?.triggerYield ?? 0} /></Grid>}
    </Grid>
  );
};

export default InsuranceResultSummary;