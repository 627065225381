import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { TextField } from '@mui/material';
import { rangeMaxLength, validateRange } from '../../../pages/units/validations/rangeValidation';

interface RangeProps {
  range: Nullable<string>;
  autoFocus?: boolean;
}

export type RangeFields = {
  range: Nullable<string>;
}

const RangeInput = ({ range, autoFocus = false }: RangeProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="range"
      control={control}
      rules={{ validate: validateRange }}
      defaultValue={range}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          type="text"
          label="Range"
          fullWidth
          inputProps={{ maxLength: rangeMaxLength }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          {...field}
          autoFocus={autoFocus}
          size="small"
        />
      )}
    />
  );
};

export default RangeInput;
