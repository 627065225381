import { useAppSelector } from '../../hooks/reduxHooks';
import { selectCurrentClientFile } from '../../app/clientFilesSlice';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import ScenarioModalHeader from '../../components/modal/scenarioModalHeader';
import UnitsModalHeaderControls from './unitsModalHeaderControls';

type UnitsModalHeaderProps = {
  scenario: RowCropScenario;
  onCancel: () => void;
  onSave: (shouldCloseOnSave: boolean) => void;
  isSaving: boolean;
  isSaveDisabled: boolean;
}

const UnitsModalHeader = ({ scenario, onCancel, onSave, isSaving, isSaveDisabled }: UnitsModalHeaderProps) => {
  const clientFile = useAppSelector(selectCurrentClientFile);

  const headerControls = <UnitsModalHeaderControls onCancel={onCancel} onSave={onSave} isSaving={isSaving} isSaveDisabled={isSaveDisabled} />;

  return (
    <ScenarioModalHeader scenarioId={scenario.scenarioId} headerText={`Units ${clientFile?.year}`} headerControls={headerControls} appendScenarioText={true}></ScenarioModalHeader>
  );
};

export default UnitsModalHeader;
