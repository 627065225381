import {
  OptionCode,
  ScenarioPieceResponseDTO
} from '@silveus/calculations';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { ScenarioPieceId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { sortScenarioPiecesForQuoteSummary } from './quoteSummary/getQuoteSummaryHelpers';
import { QuoteSummaryScenarioPiece } from '../../../types/api/reports/QuoteSummaryScenarioPiece';
import { customizeQuoteSummaryScenarioPiece } from './quoteSummary/customizeQuoteSummaryScenarioPiece';

export const getQuoteSummaryScenarioPieces = (
  scenarioPieces: RowCropScenarioPiece[],
  calcsByScenarioPiece: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  scenarioOptions: OptionCode[],
  projectedPrice: number,
  isProjectedPriceSet: boolean,
  scenario: RowCropScenario,
  approvedYield: Nullable<number>,
  adjustedYield: Nullable<number>,
): QuoteSummaryScenarioPiece[] => {
  const sortedScenarioPieces = [...scenarioPieces];

  sortedScenarioPieces.sort(sortScenarioPiecesForQuoteSummary);

  const quoteSummaryScenarioPieces: QuoteSummaryScenarioPiece[] = sortedScenarioPieces.flatMap(scenarioPiece => {
    const scenarioPieceCalcData = calcsByScenarioPiece.get(scenarioPiece.scenarioPieceId) ?? null;
    const customizeScenarioPieceInputFunction = customizeQuoteSummaryScenarioPiece(scenarioPiece.scenarioPieceType);

    return customizeScenarioPieceInputFunction(
      scenario,
      scenarioPiece,
      scenarioPieceCalcData,
      scenarioOptions,
      projectedPrice,
      isProjectedPriceSet,
      approvedYield,
      adjustedYield,
    );
  });

  return quoteSummaryScenarioPieces;
};
