import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EntityType from '../constants/entityType';

interface EntityDiffIconProps {
  entityType: EntityType;
  isReconciled: boolean;
}

const EntityDiffIcon = ({ entityType, isReconciled }: EntityDiffIconProps) => {
  if (isReconciled)
    return <CheckCircleIcon color="success" />;
  if (entityType === 'added')
    return <AddBoxIcon color="success" />;
  if (entityType === 'deleted')
    return <RemoveCircleIcon color="error" />;
  return <ErrorOutlineIcon color="error" />;
};

export default EntityDiffIcon;