import { Button, Grid, Typography } from '@mui/material';
import { ClientFileQuoteList } from './clientFileQuoteList.component';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { ClientFileId } from '../../../types/api/PrimaryKeys';
import { openDrawer } from '../../../app/appDrawerSlice';
import { selectLeftPanelZoomPercentage } from '../../../app/applicationZoomSlice';
import { getScalingTransformString } from '../../../utils/css/scaling';

interface Props {
  clientFileId: ClientFileId;
}

export const ClientFileCollapsibleTray = ({ clientFileId }: Props) => {
  const dispatch = useAppDispatch();

  const leftPanelZoomPercentage = useAppSelector(selectLeftPanelZoomPercentage);
  const isZoomApplied = leftPanelZoomPercentage > 100;
  const zoomTransformString = getScalingTransformString(leftPanelZoomPercentage);

  const onAddQuoteClicked = () => {
    dispatch(openDrawer({ formName: 'quoteForm', quoteId: null, clientFileId: clientFileId }));
  };

  return (
    <Grid container direction="column" sx={{ height: '100%', flexWrap: 'nowrap', overflow: isZoomApplied ? 'auto' : undefined, transform: zoomTransformString, transformOrigin: 'top left' }}>
      <Grid container item xs="auto" alignItems="center" justifyContent="space-between">
        <Grid item xs="auto">
          <Typography variant="subtitle1" fontWeight="bold">QUOTES LIST</Typography>
        </Grid>
        <Grid item xs="auto">
          <Button id="btn-add-year" variant="text" onClick={onAddQuoteClicked}>ADD QUOTE</Button>
        </Grid>
      </Grid>
      <Grid item xs sx={{ width: '100%' }}>
        <ClientFileQuoteList clientFileId={clientFileId} />
      </Grid>
    </Grid>
  );
};
