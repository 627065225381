import * as paravano from '@paravano/utils';

export const isEmpty = paravano.isEmpty;
export const isNilOrEmpty = paravano.isNilOrEmpty;

export function isNotNilOrEmpty<T>(value: T | undefined | null): value is T {
  return paravano.isNotNilOrEmpty(value);
}

export function isNullOrUndefined<T>(value: T | undefined | null): value is undefined | null {
  return isUndefined(value) || isNull(value);
}

export function isUndefined<T>(value: T | undefined): value is undefined {
  return value === undefined;
}

export function isNull<T>(value: T | null): value is null {
  return value === null;
}

export function isNotNullOrUndefined<T>(value: T | undefined | null): value is T {
  return !isNullOrUndefined(value);
}
