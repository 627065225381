import { useMemo } from 'react';
import { StaticallyTypedGroupedColumnDef } from '../types/agGridUtilityTypes';
import { ColGroupDef, ColDef, ValueGetterParams, CellClassParams } from 'ag-grid-community';
import { HistoricalData, HistoricalDynamicConfigs } from '../types/HistoricalData';
import { groupBy } from '../../../../../../utils/arrayUtils';
import { CustomValueColumnHeader, CustomValueHeaderParams } from '../cellRenderers/customValueColumnHeader';

/** Joins the dynamic and static column data into the shape needed to render to the chart. */
export const useJoinStaticAndDynamicColumns = (
  columnDefs: StaticallyTypedGroupedColumnDef<HistoricalData>[],
  dynamicConfigs: HistoricalDynamicConfigs,
) => {
  const dynamicColumnDefs = useMemo(() => {
    //Group the dynamic column configs by the grouping key
    const groupedDynamicColumns = groupBy(dynamicConfigs.columnConfigs, config => config.groupingKey);

    const dynamicColumnDefs: ColGroupDef<HistoricalData>[] = [];

    // Build the dynamic column definitions in the format ag-grid expects.
    dynamicConfigs.columnGroupConfigs.forEach(dynamicColumnGroup => {
      const columnConfigs = groupedDynamicColumns.get(dynamicColumnGroup.groupingKey);
      if (columnConfigs === undefined) return;

      const childColumns = columnConfigs.map(columnConfig => {
        const dynamicColumnGroupConfig = {
          //Set up a custom column header that will allow us to display any value in it (such as icons)
          headerComponent: CustomValueColumnHeader,
          headerComponentParams: {
            headerValue: columnConfig.columnHeader,
            tooltip: columnConfig.headerTooltip,
          } as CustomValueHeaderParams<HistoricalData>,

          // I'm using "valueGetter" because "field" does not support nested properties, at least in arrays.
          valueGetter: (params: ValueGetterParams<HistoricalData>) => {
            if (params.data === undefined || !params.data.include) {
              return undefined;
            }

            const matchingDataColumn = params.data.dynamicColumns.filter(column => column.groupingKey === dynamicColumnGroup.groupingKey).find(column => column.dataKey === columnConfig.dataKey);

            if (matchingDataColumn === undefined) {
              return null;
            }

            return matchingDataColumn.value;
          },
          cellRenderer: columnConfig.cellRenderer,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          cellStyle: (params: CellClassParams<HistoricalData, any>) => {
            if (params.data === undefined || !params.data.include) {
              return {};
            }
            const matchingDataColumn = params.data.dynamicColumns.filter(column => column.groupingKey === dynamicColumnGroup.groupingKey).find(column => column.dataKey === columnConfig.dataKey);
            if (matchingDataColumn === undefined || matchingDataColumn.cellStyle === undefined) {
              return {};
            }
            return matchingDataColumn.cellStyle ?? {};
          },
          maxWidth: columnConfig.maxWidth,
        };

        return dynamicColumnGroupConfig;
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const typedChildColumns: (ColGroupDef<HistoricalData> | ColDef<HistoricalData, any>)[] = childColumns.map(col => col as ColDef<HistoricalData, any>);

      //Create a column group for the dynamic columns of the grouping key
      const columnGroup = {
        headerName: dynamicColumnGroup.groupingLabel,
        children: typedChildColumns,
      };

      dynamicColumnDefs.push(columnGroup);
    });

    return dynamicColumnDefs;
  }, [dynamicConfigs]);

  const finalColumnDefinitions = useMemo(() => {
    // We're assuming the dynamic columns will appear second to last (before the total column)
    const positionToInsertDynamicColumns = columnDefs.length - 1;

    // This would only be possible with an error in this wrapper.
    if (positionToInsertDynamicColumns < 0) { return columnDefs; }

    //Create the final array using the static columns
    const modifiedColumnDefs: ColGroupDef<HistoricalData>[] = [...columnDefs];
    //Then add in the dynamic columns right before the total column
    modifiedColumnDefs.splice(positionToInsertDynamicColumns, 0, ...dynamicColumnDefs);
    //Return the combined array
    return modifiedColumnDefs;

  }, [columnDefs, dynamicColumnDefs]);

  return finalColumnDefinitions;
};