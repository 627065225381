import { ColDef, ColumnState, ICellRendererParams } from 'ag-grid-community';
import { Checkbox, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { YeStatusType } from '../../types/api/enums/optionStates/yeStatusType.enum';
import {
  isAcreageInvalid, isAphYieldInvalid, isProductionInvalid,
  processAcreageChange,
  processProductionChange,
  processTypeSelection,
  processYieldChange
} from '../units/utils/aph';
import { YIELD_DESCRIPTORS, roundToPlaces } from '@silveus/calculations';
import { CombinedAphScenarioAph } from './unitAphModal';
import { generatePrimaryKey } from '../../utils/primaryKeyHelpers';
import { UnitAphGridValue } from './unitAphGridValue';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import UnitYear from '../../types/api/UnitYear';
import { Quote } from '../../types/api/Quote';
import { isAcreageEditable, isAphYieldEditable, isProductionEditable, isSummaryRow } from '../units/utils/aphGridUtils';
import { Nullable } from '../../types/util/Nullable';
import { sharedYieldFormatter } from '../unitsModal/valueFormatters/sharedYieldFormatter';

interface UnitAphColumnDefinitionParams {
  deleteRow: (unitAphGridValue: UnitAphGridValue) => void;
  yeYears: readonly number[];
  updateAphRow: (unitAphGridValue: UnitAphGridValue) => void;
  aph: CombinedAphScenarioAph[];
  scenarioId: ScenarioId;
  setAph: (aph: CombinedAphScenarioAph[]) => void;
  quote: Quote;
  unitYear: UnitYear;
  applicableTYield: number;
  yieldRoundingPrecision: Nullable<number>;
}

export const getUnitAphColumnDefinitions = ({ deleteRow, yeYears, updateAphRow, aph, scenarioId, setAph, quote, unitYear, applicableTYield, yieldRoundingPrecision }: UnitAphColumnDefinitionParams) => {
  const columnDefinitions: ColDef<UnitAphGridValue>[] = [
    {
      colId: 'actions',
      headerName: '',
      pinned: 'right',
      sortable: false,
      suppressMovable: true,
      editable: false,
      resizable: false,
      maxWidth: 60,
      cellClass: 'icon-cell',
      headerClass: 'icon-header',
      cellRenderer: (params: ICellRendererParams<UnitAphGridValue>) => {
        if (isSummaryRow(params.node.rowPinned)) return;
        return (
          <IconButton sx={{ p: .5 }} onClick={() => params.data !== undefined && deleteRow(params.data)}>
            <DeleteIcon />
          </IconButton>
        );
      },
      cellClassRules: {
        'borderless-cell': params => isSummaryRow(params.node.rowPinned),
      },
    },
    {
      colId: 'year',
      headerName: 'Year',
      field: 'year',
      initialSort: 'asc',
      type: 'number',
      editable: params => !isSummaryRow(params.node.rowPinned),
      suppressNavigable: params => isSummaryRow(params.node.rowPinned),
      valueSetter: params => {
        const asNumber = parseInt(params.newValue);
        if (isNaN(asNumber)) return false;
        const newRow = { ...params.data, year: asNumber, yeStatus: YeStatusType.NotOptedOut };
        newRow.year = asNumber;
        updateAphRow(newRow);
        return false;
      },
    },
    {
      colId: 'production',
      headerName: 'Production',
      field: 'production',
      type: 'number',
      cellClassRules: {
        'invalid-cell': params => !isSummaryRow(params.node.rowPinned) && isProductionInvalid(params.data?.yieldType ?? '', params.data?.production ?? null),
      },
      editable: isProductionEditable,
      suppressNavigable: params => isSummaryRow(params.node.rowPinned),
      valueSetter: params => {
        const asNumber = parseInt(params.newValue);
        if (isNaN(asNumber) || params.oldValue === asNumber) return false;
        const aphRow = processProductionChange(params.data, asNumber, yieldRoundingPrecision);
        const unitAphRow: UnitAphGridValue = { ...params.data, ...aphRow };
        updateAphRow(unitAphRow);
        return false;
      },
    },
    {
      colId: 'acres',
      headerName: 'Acres',
      field: 'acres',
      type: 'number',
      cellClassRules: {
        'invalid-cell': params => !isSummaryRow(params.node.rowPinned) && isAcreageInvalid(params.data?.yieldType ?? '', params.data?.acres ?? null),
      },
      editable: isAcreageEditable,
      suppressNavigable: params => isSummaryRow(params.node.rowPinned),
      valueSetter: params => {
        const asNumber = parseInt(params.newValue);
        if (isNaN(asNumber) || params.oldValue === asNumber) return false;
        const aphRow = processAcreageChange(params.data, asNumber, yieldRoundingPrecision);
        const unitAphRow: UnitAphGridValue = { ...params.data, ...aphRow };
        updateAphRow(unitAphRow);
        return false;
      },
    },
    {
      colId: 'type',
      headerName: 'Type',
      field: 'yieldType',
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: { values: Object.keys(YIELD_DESCRIPTORS).sort() },
      refData: YIELD_DESCRIPTORS,
      editable: params => !isSummaryRow(params.node.rowPinned),
      suppressNavigable: params => isSummaryRow(params.node.rowPinned),
      valueSetter: params => {
        if (params.oldValue === params.newValue) return false;
        const { changedAph } = processTypeSelection(params.data.unitYearAphId, aph, params.newValue, unitYear.commodityCode, applicableTYield, unitYear.acres, yieldRoundingPrecision);
        const combinedScenarioAph: CombinedAphScenarioAph[] = changedAph.map(row => {
          const scenarioUnitYearAphRow = aph.find(aphRow => row.unitYearAphId === aphRow.unitYearAphId);
          return ({
            ...row,
            ...{
              scenarioUnitYearAphId: scenarioUnitYearAphRow?.scenarioUnitYearAphId ?? generatePrimaryKey(),
              scenarioId: scenarioId,
              yeStatus: scenarioUnitYearAphRow?.yeStatus ?? YeStatusType.NotOptedOut,
            },
          });
        });
        setAph(combinedScenarioAph);
        return false;
      },
    },
    {
      colId: 'yield',
      headerName: 'Yield',
      field: 'aphYield',
      type: 'number',
      cellClassRules: {
        'invalid-cell': params => !isSummaryRow(params.node.rowPinned) && isAphYieldInvalid(params.data?.yieldType ?? '', params.data?.aphYield ?? null),
      },
      editable: isAphYieldEditable,
      suppressNavigable: params => isSummaryRow(params.node.rowPinned),
      valueSetter: params => {
        const asNumberPreRounded = parseFloat(params.newValue);
        const asNumber = yieldRoundingPrecision === null ? asNumberPreRounded : roundToPlaces(asNumberPreRounded, yieldRoundingPrecision);

        if (isNaN(asNumber) || params.oldValue === asNumber) return false;
        params.data.aphYield = asNumber;
        const { changedAph } = processYieldChange(params.data.unitYearAphId, aph, asNumber, applicableTYield, quote.commodityCode);
        const combinedScenarioAph: CombinedAphScenarioAph[] = changedAph.map(row => {
          const scenarioUnitYearAphRow = aph.find(aphRow => row.unitYearAphId === aphRow.unitYearAphId);
          return ({
            ...row,
            ...{
              scenarioUnitYearAphId: scenarioUnitYearAphRow?.scenarioUnitYearAphId ?? generatePrimaryKey(),
              scenarioId: scenarioId,
              yeStatus: scenarioUnitYearAphRow?.yeStatus ?? YeStatusType.NotOptedOut,
            },
          });
        });
        setAph(combinedScenarioAph);
        return false;
      },
    },
    {
      colId: 'adjustedYield',
      headerName: 'Adj. Yield',
      field: 'adjustedYield',
      editable: false,
      cellDataType: false,
      suppressNavigable: params => isSummaryRow(params.node.rowPinned),
      valueFormatter: params => sharedYieldFormatter(params, yieldRoundingPrecision),
    },
    {
      colId: 'preQaYield',
      headerName: 'Pre-QA Yield',
      field: 'preQaYield',
      type: 'number',
      editable: params => !isSummaryRow(params.node.rowPinned),
      suppressNavigable: params => isSummaryRow(params.node.rowPinned),
      valueSetter: params => {
        const asNumberPreRounded = parseFloat(params.newValue);
        const asNumber = yieldRoundingPrecision === null ? asNumberPreRounded : roundToPlaces(asNumberPreRounded, yieldRoundingPrecision);
        if (isNaN(asNumber) || params.oldValue === asNumber) return false;
        params.data.preQaYield = asNumber;
        updateAphRow(params.data);
        return false;
      },
    },
    {
      colId: 'approvedYield',
      headerName: 'Appr. Yield',
      field: 'approvedYield',
      editable: false,
      cellDataType: false,
      suppressNavigable: params => isSummaryRow(params.node.rowPinned),
      valueFormatter: params => sharedYieldFormatter(params, yieldRoundingPrecision),
    },
    {
      colId: 'yeOptOut',
      headerName: 'YE Opt Out',
      field: 'yeStatus',
      editable: false,
      suppressNavigable: params => isSummaryRow(params.node.rowPinned),
      cellRenderer: (params: ICellRendererParams<UnitAphGridValue>) => {
        if (params.node.rowPinned === 'bottom' || params.data === undefined) return;
        const row: UnitAphGridValue = params.data;
        if (!yeYears.some(ye => ye === row.year)) return;
        return (
          <Checkbox
            checked={row.yeStatus === YeStatusType.OptedOut}
            onChange={() => {
              row.yeStatus = row.yeStatus === YeStatusType.OptedOut ? YeStatusType.NotOptedOut : YeStatusType.OptedOut;
              updateAphRow(row);
            }}
          />);
      },
    },
  ];

  return columnDefinitions;
};

export const defaultColState: ColumnState[] = [
  {
    colId: 'actions',
    width: 65,
  },
  {
    colId: 'year',
    width: 70,
  },
  {
    colId: 'production',
    width: 100,
  },
  {
    colId: 'acres',
    width: 70,
  },
  {
    colId: 'type',
    width: 70,
  },
  {
    colId: 'yield',
    width: 70,
  },
  {
    colId: 'adjustedYield',
    width: 100,
  },
  {
    colId: 'preQaYield',
    width: 120,
  },
  {
    colId: 'approvedYield',
    width: 100,
  },
  {
    colId: 'yeOptOut',
    width: 100,
  },
];

export const defaultColDef: ColDef = {
  minWidth: 45,
  resizable: true,
  sortable: true,
  editable: true,
};
