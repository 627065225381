import { closeDrawer, DrawerFormType } from '../../../app/appDrawerSlice';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { Grid } from '@mui/material';
import HistoricalYearInput, { EndYearFields, StartYearFields } from '../../../components/formInputs/historicalQuote/historicalYearInput';
import { modifyHistoricalAnalysis, selectAllHistoricalAnalyses } from '../../../app/historicalAnalysisSlice';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import HistoricalIncludeInput, { HistoricalIncludeFields } from '../../../components/formInputs/historicalQuote/historicalIncludeInput';
import HistoricalShowInput, { HistoricalShowFields } from '../../../components/formInputs/historicalQuote/historicalShowInput';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import HistoricalAcreTypeInput, { HistoricalAcreageTypeFields } from '../../../components/formInputs/historicalQuote/historicalAcreTypeInput';
import IncludeMissingYearsInput, { IncludeMissingYearsFields } from '../../../components/formInputs/historicalQuote/includeMissingYearsInput';
import HistoricalAnalysis from '../../../types/api/historicalAnalysis';
import { selectScenarioById } from '../../../app/scenariosSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { useEffect } from 'react';

interface HistoricalQuoteFormProps extends FormWrapperProps {
  primaryScenarioId: Nullable<ScenarioId>;
}

type HistoricalQuoteFormFields =
  StartYearFields & EndYearFields & HistoricalAcreageTypeFields
  & HistoricalIncludeFields & HistoricalShowFields & IncludeMissingYearsFields;

const minHistoricalYear = 1970;
const historicalQuoteFormId: DrawerFormType = 'historicalQuoteForm';

const HistoricalQuoteForm = ({ primaryScenarioId, handleValidation, registerHeader, isCanceling = false }: HistoricalQuoteFormProps) => {
  const dispatch = useAppDispatch();
  const methods = useForm<HistoricalQuoteFormFields>();
  const { watch } = methods;
  const scenario = useAppSelector(state => primaryScenarioId === null ? null : selectScenarioById(state, primaryScenarioId));
  const quote = useAppSelector(s => isNullOrUndefined(scenario) ? null : selectQuoteById(s, scenario.quoteId));
  const clientFile = useAppSelector(s => isNullOrUndefined(quote) ? null : selectClientFileById(s, quote.clientFileId));
  const historicalAnalyses = useAppSelector(state => selectAllHistoricalAnalyses(state));
  const historicalAnalysis = primaryScenarioId ? historicalAnalyses.get(primaryScenarioId) ?? null : null;

  const startYear = watch('startYear');
  const endYear = watch('endYear');

  useEffect(() => {
    if (primaryScenarioId !== null && (clientFile === null)) {
      dispatch(closeDrawer());
    }
  }, [clientFile, primaryScenarioId]);

  const generateYearsArray = () => {
    const possibleYears: number[] = [];

    let sy = minHistoricalYear;
    if (clientFile === null) return possibleYears;
    while (sy <= clientFile.year) {
      possibleYears.push(sy);
      sy++;
    }

    return possibleYears;
  };

  const yearsArray = generateYearsArray();

  const onSubmit: SubmitHandler<HistoricalQuoteFormFields> = data => {
    if (!historicalAnalysis) return;

    const historicalAnalysisToSubmit: HistoricalAnalysis = {
      ...data,
      historicalAnalysisId: historicalAnalysis.historicalAnalysisId,
      hidePastIntervals: historicalAnalysis.hidePastIntervals,
      intervalCount: historicalAnalysis.intervalCount,
      primaryScenarioId: historicalAnalysis.primaryScenarioId,
      isActive: historicalAnalysis.isActive,
      historicalSmartCriteriaType: historicalAnalysis.historicalSmartCriteriaType,
      historicalAnalysisYears: historicalAnalysis.historicalAnalysisYears,
      historicalIntervalType: historicalAnalysis.historicalIntervalType,
      offlineCreatedOn: historicalAnalysis.offlineCreatedOn,
      offlineLastUpdatedOn: historicalAnalysis.offlineLastUpdatedOn,
      offlineDeletedOn: historicalAnalysis.offlineDeletedOn,
    };

    dispatch(modifyHistoricalAnalysis({ historicalAnalysis: historicalAnalysisToSubmit }));
  };

  const shouldSubmit = methods.formState.isDirty;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit);
  const handleSubmit = useFormWrapper('Historical Quote', methods, historicalQuoteFormId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  const handleReset = () => {
    methods.reset();
  };

  if (!historicalAnalysis) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form id={historicalQuoteFormId} onSubmit={handleSubmit} onReset={handleReset}>
        <Grid container alignItems="center" p={2} rowSpacing={2} columnSpacing={1}>
          <Grid item xs={6}>
            <HistoricalYearInput
              years={yearsArray.map(x => ({ year: x, disabled: x > endYear }))}
              year={historicalAnalysis.startYear}
              name="startYear"
              label="Start Year"
            />
          </Grid>
          <Grid item xs={6}>
            <HistoricalYearInput
              years={yearsArray.map(x => ({ year: x, disabled: x < startYear }))}
              year={historicalAnalysis.endYear}
              name="endYear"
              label="End Year"
            />
          </Grid>
          <Grid item xs={6}>
            <HistoricalIncludeInput value={historicalAnalysis.historicalIncludeType} />
          </Grid>
          <Grid item xs={6}>
            <HistoricalShowInput value={historicalAnalysis.historicalShowType} />
          </Grid>
          <Grid item xs={6}>
            <HistoricalAcreTypeInput value={historicalAnalysis.acreageType} />
          </Grid>
          <Grid item xs={8}>
            <IncludeMissingYearsInput calculateMissingYears={historicalAnalysis.calculateMissingYears} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default HistoricalQuoteForm;
