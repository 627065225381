import axios from 'axios';
import { AutoCompleteOption } from '../types/api/insureds/AutoCompleteOption';
import { Insured } from '../types/api/insureds/Insured';
import { InsuredContact } from '../types/api/insureds/InsuredContact';
import { InsuredNote } from '../types/api/insureds/InsuredNote';
import { InsuredPolicy } from '../types/api/insureds/InsuredPolicy';
import { InsuredSMS } from '../types/api/insureds/InsuredSMS';
import { InsuredId } from '../types/api/PrimaryKeys';
import { PersonOfInterest } from '../types/api/insureds/PersonOfInterest';
import { TaxType } from '../types/api/insureds/TaxType';
import { EntityType } from '../types/api/insureds/EntityType';
import { envBaseApiUrl } from '../constants/envConstants';
import { AgentTeam } from '../types/api/insureds/agentTeam';
import { Result, ResultBase } from '../types/api/result';
import { DecryptIdRequest } from '../types/api/applicationWizard/decryptIdRequest';

export const getInsureds = () => axios.get<Insured[]>(`${envBaseApiUrl}/Insureds`);

export const getInsured = (insuredId: InsuredId) => axios.get<Insured>(`${envBaseApiUrl}/Insureds/${insuredId}`);

export const updateInsured = (insured: Insured, updateTaxId: boolean) => axios.put<ResultBase>(`${envBaseApiUrl}/Insureds/${updateTaxId}`, insured);

export const createInsured = (insured: Insured) => axios.post<Result<InsuredId>>(`${envBaseApiUrl}/Insureds`, insured);

export const getInsuredNotes = (insuredId: InsuredId) => axios.get<InsuredNote[]>(`${envBaseApiUrl}/Insureds/${insuredId}/Notes`);

export const getInsuredSMS = (insuredId: InsuredId) => axios.get<InsuredSMS[]>(`${envBaseApiUrl}/Insureds/${insuredId}/SMS`);

export const getInsuredContacts = (insuredId: InsuredId) => axios.get<InsuredContact[]>(`${envBaseApiUrl}/Insureds/${insuredId}/Contacts`);

export const getInsuredPersonsOfInterest = (insuredId: InsuredId) => axios.get<PersonOfInterest[]>(`${envBaseApiUrl}/Insureds/${insuredId}/personsOfInterest`);

export const getInsuredPolicies = (insuredId: InsuredId) => axios.get<InsuredPolicy[]>(`${envBaseApiUrl}/Insureds/${insuredId}/Policies`);

export const getInsuredEntityTypes = () => axios.get<AutoCompleteOption[]>(`${envBaseApiUrl}/Insureds/InsuredEntityTypes`);

export const getEntityTypes = () => axios.get<EntityType[]>(`${envBaseApiUrl}/Insureds/EntityTypes`);

export const getTaxTypes = () => axios.get<TaxType[]>(`${envBaseApiUrl}/Insureds/taxTypes`);

export const getInsuredCorporationStates = () => axios.get<AutoCompleteOption[]>(`${envBaseApiUrl}/Insureds/CorporationStates`);

export const getCountiesForInsureds = (insuredIds: InsuredId[]) => axios.post<string[]>(`${envBaseApiUrl}/insureds/counties`, insuredIds);

export const getAgentTeams = () => axios.get<AgentTeam[]>(`${envBaseApiUrl}/insureds/agent-teams`);

export const createPersonOfInterest = async (personOfInterest: PersonOfInterest) => {
  const res = await axios.post<Result<PersonOfInterest>>(`${envBaseApiUrl}/Insureds/personsOfInterest`, personOfInterest);
  return res.data;
};

export const importPersonOfInterest = async (personOfInterest: PersonOfInterest) => {
  const res = await axios.post<PersonOfInterest>(`${envBaseApiUrl}/Insureds/personsOfInterest/import`, personOfInterest);
  return res.data;
};

export const getDecryptedId = async (decryptRequest: DecryptIdRequest) => {
  const res = await axios.post<string>(`${envBaseApiUrl}/Insureds/personOfInterest/decrypt`, decryptRequest);
  return res.data;
};

export const updatePersonOfInterest = (personOfInterest: PersonOfInterest, updateTaxId: boolean) => axios.put<Result<PersonOfInterest>>(`${envBaseApiUrl}/Insureds/personsOfInterest/${updateTaxId}`, personOfInterest);

export const removePersonOfInterest = (personOfInterest: PersonOfInterest) => axios.delete<void>(`${envBaseApiUrl}/Insureds/personsOfInterest/${personOfInterest.personOfInterestId}`);