import { IconButton } from '@mui/material';
import { ReactNode, ComponentProps } from 'react';
import { PopoutActionProps, usePopoutActions } from './usePopoutActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface Props extends PopoutActionProps {
  size?: ComponentProps<typeof IconButton>['size'];
  sx?: ComponentProps<typeof IconButton>['sx'];
  color?: ComponentProps<typeof IconButton>['color'];
  disabled?: boolean;
  icon?: ReactNode;
}

const Actions = ({ size, children, id, color, sx, keepOpen = false, disabled = false, icon = <MoreVertIcon /> }: Props) => {
  const { onClick, anchorRef, popoutActions, open } = usePopoutActions({ children, keepOpen, id });

  return (
    <>
      <IconButton
        disabled={disabled}
        ref={anchorRef}
        aria-controls={open ? `action-menu-${id}` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={onClick}
        size={size}
        color={color}
        id={`btn-action-${id}`}
        className="action-button"
        sx={sx}
      >
        {icon}
      </IconButton>
      {popoutActions}
    </>
  );
};

export default Actions;
