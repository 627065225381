import { FormControl, Grid, IconButton, TextField } from '@mui/material';
import { TemplateScenarioId } from '../../../types/api/PrimaryKeys';
import { TemplateScenario } from '../../../types/api/template-scenarios/TemplateScenario';
import DeleteIcon from '@mui/icons-material/Delete';

export type ManageTemplateScenarioRowProps = {
  templateScenario: TemplateScenario;
  handleTemplateScenarioChange: (templateScenario: TemplateScenario) => void;
  deleteTemplateScenario: (templateScenarioId: TemplateScenarioId) => void;
}

export default function ManageTemplateScenarioRow({
  templateScenario,
  handleTemplateScenarioChange,
  deleteTemplateScenario,
}: ManageTemplateScenarioRowProps) {
  return (
    <Grid container spacing={1} sx={{ alignItems: 'center', mt: 1 }}>
      <Grid item>
        <FormControl>
          <TextField
            value={templateScenario.name}
            onChange={e => handleTemplateScenarioChange({ ...templateScenario, name: e.target.value })}
            sx={{ width: '600px' }}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <IconButton type="button" onClick={() => deleteTemplateScenario(templateScenario.templateScenarioId)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
