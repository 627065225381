import * as React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector, useKeyMapSelectorSingle } from '../../../hooks/reduxHooks';
import { QuoteId } from '../../../types/api/PrimaryKeys';
import { selectCalculationsByScenarioPiece } from '../../../app/calculationResultsSlice';
import '../scenarioPieceCard.style.css';
import { useTheme } from '@mui/system';
import { formatCurrency } from '../../../utils/formatNumbers';
import ForwardSoldScenarioPiece from '../../../types/api/ForwardSoldScenarioPiece';
import { modifyForwardSoldScenarioPiece, removeForwardSoldScenarioPieceAndRecalculate } from '../../../app/forwardSoldScenarioPiecesSlice';
import { AdjustmentTypeAttributes } from '@silveus/calculations';
import ForwardSoldOutputSummary from './forwardSoldOutputSummary.component';
import { formatProductionDisplayValue, formatPriceDisplayValue } from './forwardSoldUtils';
import ScenarioPieceCard from '../scenarioPieceCard.component';
import { selectScenarioById } from '../../../app/scenariosSlice';

interface ForwardSoldScenarioPieceCardProps {
  scenarioPiece: ForwardSoldScenarioPiece;
  quoteId: QuoteId;
  isScenarioActive: boolean;
  isReadonly: boolean;
}

const ForwardSoldScenarioPieceCard = ({ scenarioPiece, quoteId, isScenarioActive, isReadonly }: ForwardSoldScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const calcResults = useKeyMapSelectorSingle(selectCalculationsByScenarioPiece, scenarioPiece.scenarioPieceId);
  const netScenarioTotal = -(calcResults?.fixedPerAcre ?? 0);

  const scenario = useAppSelector(state => selectScenarioById(state, scenarioPiece.scenarioId));

  if (!scenario) {
    return <></>;
  }

  const expandedCardBody = (
    <>
      <Table size="small" sx={{ tableLayout: 'fixed', width: '100%', marginBottom: '10px' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '35%' }}>
              Production
            </TableCell>
            <TableCell sx={{ width: '35%' }}>
              Price
            </TableCell>
            <TableCell sx={{ width: '30%' }}>
              Revenue
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scenarioPiece.forwardSoldTransactions.map((transactionItem, index) => {
            const formattedProduction = formatProductionDisplayValue(transactionItem.production, transactionItem.productionAdjustmentTypeId);
            const formattedPrice = formatPriceDisplayValue(transactionItem.price, transactionItem.priceAdjustmentTypeId);
            return (
              <TableRow key={index}>
                <TableCell sx={{ border: `1px solid ${theme.palette.divider}`, padding: '5px' }}>
                  <Typography fontSize="12px"> {AdjustmentTypeAttributes[transactionItem.productionAdjustmentTypeId].description} | {formattedProduction}</Typography>
                </TableCell>
                <TableCell sx={{ border: `1px solid ${theme.palette.divider}`, padding: '5px' }}>
                  <Typography fontSize="12px"> {AdjustmentTypeAttributes[transactionItem.priceAdjustmentTypeId].description} | {formattedPrice}</Typography>
                </TableCell>
                <TableCell sx={{ border: `1px solid ${theme.palette.divider}`, padding: '5px', textAlign: 'right' }}>
                  <Typography fontSize="12px">{formatCurrency(calcResults?.adjustmentRevenuesFixed?.[index] ?? 0, false)}</Typography>
                </TableCell>
              </TableRow>);
          })}
        </TableBody>
      </Table>
      <ForwardSoldOutputSummary
        totalYieldSold={calcResults?.totalYieldSoldFixed ?? 0}
        percentBushelsSold={calcResults?.percentBushelsSoldFixed ?? 0}
        averagePrice={calcResults?.averagePriceFixed ?? 0}
        useSmallFont={true}
        marketingYield={scenarioPiece.marketingYield} />
    </>
  );

  const updateScenarioPiece = async (scenarioPiece: ForwardSoldScenarioPiece) => {
    await dispatch(modifyForwardSoldScenarioPiece({ scenarioPiece: scenarioPiece }));
  };

  const removeScenarioPiece = async (scenarioPiece: ForwardSoldScenarioPiece) => {
    await dispatch(removeForwardSoldScenarioPieceAndRecalculate({ scenarioPiece: scenarioPiece }));
  };

  return (
    <ScenarioPieceCard
      scenarioPiece={scenarioPiece}
      quoteId={quoteId}
      isScenarioActive={isScenarioActive}
      updateScenarioPiece={updateScenarioPiece}
      removeScenarioPiece={removeScenarioPiece}
      label="Forward Sold"
      expandedCardBody={expandedCardBody}
      netValue={netScenarioTotal}
      calcResults={calcResults}
      scenarioId={scenarioPiece.scenarioId}
      displayTooltip={false}
      isReadonly={isReadonly}
      scenarioColor={scenario.scenarioColor}
    />
  );
};

export default ForwardSoldScenarioPieceCard;
