import { AgGridReact } from 'ag-grid-react';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import { DefaultColDefinition, HailPlanExplorerDefaultColumnState, getColumnDefinitions } from './hailPlanGridColumns';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useGridStatePersistence } from '../../../hooks/useGridStatePersistence';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { modifyHailPlanExplorerColumnsForUser, selectIsLightMode, selectPlanExplorerColumns } from '../../../app/userSettingsSlice';
import { selectCurrentClientFile } from '../../../app/clientFilesSlice';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { selectQuoteById } from '../../../app/quotesSlice';
import HailScenarioPiece from '../../../types/api/HailScenarioPiece';
import { HailProductId } from '../../../types/api/PrimaryKeys';

type HailPlanExplorerViewProps = {
  scenario: RowCropScenario;
  availableHailProducts: HailProduct[];
  onToggleProduct: (hailProduct: HailProduct) => void;
  hailScenarioPiecesByHailProductId: Map<HailProductId, HailScenarioPiece>;
}

const HailPlanExplorerView = ({ scenario, availableHailProducts, onToggleProduct, hailScenarioPiecesByHailProductId }: HailPlanExplorerViewProps) => {
  const dispatch = useAppDispatch();
  const planExplorerColumns = useAppSelector(selectPlanExplorerColumns);
  const clientFile = useAppSelector(selectCurrentClientFile);
  const quote = useAppSelector(s => selectQuoteById(s, scenario.quoteId));
  const isLightMode = useAppSelector(selectIsLightMode);

  const { onChange, onGridReady, gridApi } = useGridStatePersistence({
    defaultColumnState: HailPlanExplorerDefaultColumnState,
    columns: planExplorerColumns,
    saveColumnState: cols => dispatch(modifyHailPlanExplorerColumnsForUser({ columns: cols })),
  });

  useEffect(() => {
    if (gridApi && clientFile) {
      gridApi.setFilterModel({
        cropYear: {
          filterType: 'number',
          operator: 'OR',
          condition1: {
            filterType: 'number',
            type: 'equals',
            filter: clientFile.year,
          },
          condition2: {
            filterType: 'number',
            type: 'equals',
            filter: clientFile.year - 1,
          },
        },
      });
    }
  }, [gridApi, clientFile]);

  if (quote === null) {
    return null;
  }

  const columnDefinitions = getColumnDefinitions(onToggleProduct, quote, hailScenarioPiecesByHailProductId);

  return (
    <Grid item xs={12} sx={{ height: '100%' }} className={isLightMode ? 'ag-theme-quartz' : 'ag-theme-quartz-dark'}>
      <AgGridReact<HailProduct>
        onGridReady={onGridReady}
        className="hail-plan-grid"
        defaultColDef={DefaultColDefinition}
        rowData={availableHailProducts.length === 0 ? null : availableHailProducts}
        columnDefs={columnDefinitions}
        enterNavigatesVerticallyAfterEdit={false}
        enterNavigatesVertically={false}
        maintainColumnOrder={true}
        onDragStopped={onChange}
        pagination={true}
        paginationPageSize={50}
        gridOptions={{
          sortingOrder: ['desc', 'asc', null],
        }}
        autoSizeStrategy={{ type: 'fitGridWidth' }}
      />
    </Grid>
  );
};

export default HailPlanExplorerView;