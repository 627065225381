import axios from 'axios';
import { UserMatrixColorDictionary } from '../types/api/userSettings/UserMatrixColor';
import { UserLinkedMatrices, UserLinkedScenarios } from '../types/api/userSettings/UserLinkedScenarios';
import { UserScenarioOrder } from '../types/api/userSettings/UserScenarioOrder';
import { ColumnState } from 'ag-grid-community';
import { UserScenarioPieceOrder } from '../types/api/userSettings/UserScenarioPieceOrder';
import { PremiumBreakdownOptions } from '../types/api/userSettings/PremiumBreakdownOptions';
import { PaletteModeSettings } from '../types/api/userSettings/PaletteModeSettings';
import { envBaseApiUrl } from '../constants/envConstants';
import { UserMatrixPresets } from '../types/api/userSettings/UserMatrixPresets';

export const getPremiumBreakdownSettingsForUser = () => axios.get<PremiumBreakdownOptions>(`${envBaseApiUrl}/user-settings/premium-breakdown`);
export const updatePremiumBreakdownSettingsForUser = (options: PremiumBreakdownOptions) => axios.post<void>(`${envBaseApiUrl}/user-settings/premium-breakdown`, options);

export const getUnitModalColumnsForUser = () => axios.get<ColumnState[]>(`${envBaseApiUrl}/user-settings/unit-modal-columns`);
export const updateUnitModalColumnsForUser = (columDefs: ColumnState[]) => axios.post<void>(`${envBaseApiUrl}/user-settings/unit-modal-columns`, columDefs);

export const getHailPlanExplorerColumns = () => axios.get<ColumnState[]>(`${envBaseApiUrl}/user-settings/plan-explorer-columns`);
export const updateHailPlanExplorerColumnsForUser = (columDefs: ColumnState[]) => axios.post<void>(`${envBaseApiUrl}/user-settings/plan-explorer-columns`, columDefs);

export const getUnitColumnsForUser = () => axios.get<ColumnState[]>(`${envBaseApiUrl}/user-settings/unit-columns`);
export const updateUnitColumnsForUser = (columDefs: ColumnState[]) => axios.post<void>(`${envBaseApiUrl}/user-settings/unit-columns`, columDefs);

export const getHistoricalColumnsForUser = () => axios.get<ColumnState[]>(`${envBaseApiUrl}/user-settings/historical-columns`);
export const updateHistoricalColumnsForUser = (userLinkedScenarios: ColumnState[]) => axios.post<void>(`${envBaseApiUrl}/user-settings/historical-columns`, userLinkedScenarios);

export const getLinkedScenariosForUser = () => axios.get<UserLinkedScenarios>(`${envBaseApiUrl}/user-settings/linked-scenarios`);
export const updateLinkedScenariosForUser = (userLinkedScenarios: UserLinkedScenarios) => axios.post<void>(`${envBaseApiUrl}/user-settings/linked-scenarios`, userLinkedScenarios);

export const getScenarioOrderForUser = () => axios.get<UserScenarioOrder>(`${envBaseApiUrl}/user-settings/scenario-order`);
export const updateScenarioOrderForUser = (userScenarioOrder: UserScenarioOrder) => axios.post<void>(`${envBaseApiUrl}/user-settings/scenario-order`, userScenarioOrder);

export const getScenarioPieceOrderForUser = () => axios.get<UserScenarioPieceOrder>(`${envBaseApiUrl}/user-settings/scenario-piece-order`);
export const updateScenarioPieceOrderForUser = (userScenarioPieceOrder: UserScenarioPieceOrder) => axios.post<void>(`${envBaseApiUrl}/user-settings/scenario-piece-order`, userScenarioPieceOrder);

export const getMatrixPresetsForUser = () => axios.get<UserMatrixPresets>(`${envBaseApiUrl}/user-settings/matrix-presets`);
export const updateMatrixPresetsForUser = (matrixPresets: UserMatrixPresets) => axios.post<void>(`${envBaseApiUrl}/user-settings/matrix-presets`, matrixPresets);

export const getMatrixColorSettingsForUser = () => axios.get<UserMatrixColorDictionary>(`${envBaseApiUrl}/user-settings/matrix-colors`);
export const updateMatrixColorSettingsForUser = (matrixColorSettings: UserMatrixColorDictionary) => axios.post<void>(`${envBaseApiUrl}/user-settings/matrix-colors`, matrixColorSettings);

export const getLinkedMatricesForUser = () => axios.get<UserLinkedMatrices>(`${envBaseApiUrl}/user-settings/linked-matrices`);
export const updateLinkedMatricesForUser = (userLinkedMatrices: UserLinkedMatrices) => axios.post<void>(`${envBaseApiUrl}/user-settings/linked-matrices`, userLinkedMatrices);

export const getPaletteModeSettingsForUser = () => axios.get<PaletteModeSettings>(`${envBaseApiUrl}/user-settings/palette-mode`);
export const updatePaletteModeSettingsForUser = (paletteModeSettings: PaletteModeSettings) => axios.post<void>(`${envBaseApiUrl}/user-settings/palette-mode`, paletteModeSettings);