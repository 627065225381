import { Checkbox } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { HistoricalData } from '../types/HistoricalData';

/** This renders the checkbox specifically intended for the "Include" column of the historical grid. */
export const IncludeColumnCellRenderer = ({ value, setValue, data }: ICellRendererParams<HistoricalData, boolean>, handleIncludeChange: (year: number, include: boolean) => void) => {
  const handleChange = (newValue: boolean) => {
    if (setValue) {
      setValue(newValue);
    }

    if (data === undefined) return;

    handleIncludeChange(data.year, newValue);
  };

  return (
    <Checkbox checked={value ?? undefined} color="default" onChange={e => handleChange(e.target.checked)} sx={{ p: 0.4 }} />
  );
};