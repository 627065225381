import RampCalculationParams from '../../../types/api/calculationData/rampCalculationParams';
import { getRampCalcData } from '../../calculationData.service';
import { privateProductsDb } from '../../../db';
import { safeGet } from '../../../utils/dexieQueryHelpers/getClauses';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import { RampAvailabilityParams } from '../../../types/api/adm/PrivateProductsParams';
import CoverageLevelAvailability from '../../../types/api/adm/CoverageLevelAvailability';
import { getAvailableRampCoverageLevels } from '../../privateProducts.service';
import { safeWhere } from '../../../utils/dexieQueryHelpers/whereClauses';
import { RampCalculationParamsRequest } from '../../../types/api/calculationData/rampCalculationParamsRequest';

export const getRampCalcDataRequest = async (calcParamRequest: RampCalculationParamsRequest): Promise<RampCalculationParams[]> => {
  const request = () => getRampCalcData(calcParamRequest);

  const readTransaction = async (): Promise<RampCalculationParams[]> => {
    const rampResponse: RampCalculationParams[] = [];

    const unitTransactions = calcParamRequest.distinctUnits.map(async distinctUnit => {
      const rampData = await privateProductsDb.transaction('r', privateProductsDb.rampRates, async () => {
        return safeGet(privateProductsDb.rampRates, {
          countyId: calcParamRequest.countyId,
          commodityCode: calcParamRequest.commodityCode,
          insurancePlanCode: calcParamRequest.planCode,
          percentRangeStart: calcParamRequest.upperCoverageLevel,
          percentRangeEnd: calcParamRequest.lowerCoverageLevel,
        });
      });

      if (rampData !== undefined) {
        const rampParams: RampCalculationParams = {
          unit: distinctUnit,
          percentAmt: rampData.percentAmt,
        };

        rampResponse.push(rampParams);
      }
    });

    await Promise.all(unitTransactions);

    return rampResponse;
  };

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getAvailableRampCoverageLevelsRequest = async (privateProductParams: RampAvailabilityParams): Promise<CoverageLevelAvailability[]> => {
  const request = () => getAvailableRampCoverageLevels(privateProductParams);

  const readTransaction = () => privateProductsDb.transaction('r', privateProductsDb.rampRates, async () => {
    const rampRates = await safeWhere(privateProductsDb.rampRates, { cropYear: privateProductParams.year, countyId: privateProductParams.countyId, commodityCode: privateProductParams.commodityCode }).toArray();
    const coverageLevelAvailabilities: CoverageLevelAvailability[] = rampRates.map(rampRate => {
      const coverageLevelAvailability: CoverageLevelAvailability = {
        upperCoverageLevel: rampRate.percentRangeStart,
        lowerCoverageLevel: rampRate.percentRangeEnd,
      };
      return coverageLevelAvailability;
    });
    return coverageLevelAvailabilities;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};
