import { ColDef, ColSpanParams, ICellRendererParams, RowHeightParams } from 'ag-grid-community';
import { ADMYear } from '../../../../types/api/adm/ADMYear';
import { getCommodityName, getCountyName, getCropTypeName, getPracticeName, getStateName } from '../../../../utils/adm';
import { HighRiskAttributes, HighRiskType, ScenarioPieceType, ScenarioPieceTypeAttributes, UnitStructureAttributes, UnitStructureCode } from '@silveus/calculations';
import { QuoteId, ScenarioId } from '../../../../types/api/PrimaryKeys';
import { ApplicationWizard } from '../../../../types/api/applicationWizard/applicationWizard';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../../utils/nullHandling';
import { CheckRenderer } from '../../../../components/ag-grid/cellRenderers/checkRenderer';
import ScenarioOption from '../../../../types/api/options/ScenarioOption';

function isHeaderRow(params: RowHeightParams | ColSpanParams) {
  return params.data.section === 'big-title';
}

export type CoverageDecisionRowData = {
  scenarioId?: ScenarioId;
  isSelected?: boolean;
  quoteId?: QuoteId;
  stateId?: string;
  countyId?: string;
  commodityCode?: string;
  scenarioPieceType?: ScenarioPieceType;
  section?: string;
  practiceId?: string;
  typeId?: string;
  options?: ScenarioOption[];
  lowerCoverage?: number;
  upperCoverage?: number;
  protectionFactor?: number;
  highRiskTypeId?: HighRiskType;
  unitStructure?: UnitStructureCode;
}

export const getColumnDefinitions = (application: ApplicationWizard, year: number, admData: ADMYear[], onToggleScenario?: (quoteId: QuoteId, scenarioId: ScenarioId) => void) => {
  let columnDefinitions: ColDef<CoverageDecisionRowData>[] = [
    {
      colId: 'check',
      headerName: '',
      resizable: false,
      width: 55,
      cellClass: 'check-column',
      cellRenderer: (params: ICellRendererParams<CoverageDecisionRowData>) => {

        const scenarioId = params.data?.scenarioId;
        const quoteId = params.data?.quoteId;
        if (isNullOrUndefined(scenarioId) || isNullOrUndefined(quoteId) || !onToggleScenario) {
          return null;
        }

        const isChecked = isNotNullOrUndefined(application.applicationWizardScenarios.find(x => x.scenarioId === scenarioId));
        return CheckRenderer(`input-scenario-check-${scenarioId}`, isChecked, () => onToggleScenario(quoteId, scenarioId));
      },
    },
    {
      colId: 'state',
      headerName: 'State',
      field: 'stateId',
      width: 95,
      valueGetter: params => {
        if (params.data?.section === 'big-title') {
          return params.data.stateId;
        }

        return (params.data?.countyId !== undefined && params.data.countyId !== '') ? getStateName(admData, year, params.data.countyId) : '';
      },
      colSpan: (params: ColSpanParams) => {
        if (isHeaderRow(params)) {
          return 8;
        } else {
          return 1;
        }
      },
      cellClassRules: {
        'big-title': params => params.data?.section === 'big-title',
      },
    },
    {
      colId: 'county',
      headerName: 'County',
      field: 'countyId',
      minWidth: 95,
      valueGetter: params => (params.data?.countyId !== undefined && params.data.countyId !== '') ? getCountyName(admData, year, params.data.countyId) : '',
    },
    {
      colId: 'crop',
      headerName: 'Crop',
      field: 'commodityCode',
      minWidth: 95,
      valueGetter: params => (params.data?.commodityCode !== undefined && params.data.commodityCode !== '') ? getCommodityName(admData, year, params.data.commodityCode) : '',
    },
    {
      colId: 'plan',
      headerName: 'Plan',
      minWidth: 95,
      valueGetter: params => Object.values(ScenarioPieceTypeAttributes).find(spt => spt.value === params.data?.scenarioPieceType)?.description ?? '',
    },
    {
      colId: 'level',
      headerName: 'Level',
      minWidth: 120,
      valueGetter: params => {
        const { data } = params;
        let value = '';
        if (data === undefined || data.lowerCoverage === undefined || data.lowerCoverage === 0) {
          value = `${data?.upperCoverage}`;
        } else {
          value = `${data.upperCoverage}/${data.lowerCoverage}`;
        }
        if (data?.protectionFactor !== undefined) {
          value += ` (${data.protectionFactor})`;
        }
        return value;
      },
    },
    {
      colId: 'practice',
      headerName: 'Practice',
      minWidth: 120,
      field: 'practiceId',
      valueGetter: params => (params.data?.practiceId !== undefined && params.data.practiceId !== '') ? getPracticeName(admData, year, params.data.practiceId) : '',
    },
    {
      colId: 'type',
      headerName: 'Type',
      minWidth: 95,
      valueGetter: params => (params.data?.typeId !== undefined && params.data.typeId !== '') ? getCropTypeName(admData, year, params.data.typeId) : '',
    },
    {
      colId: 'unit',
      headerName: 'Unit Structure',
      minWidth: 95,
      valueGetter: params => params.data?.unitStructure !== undefined ? UnitStructureAttributes[params.data.unitStructure].name : '',
    },

    {
      colId: 'options',
      headerName: 'Options',
      minWidth: 95,
      field: 'options',
      valueGetter: params => params.data?.options?.map(x => x.option).sort((a, b) => a.localeCompare(b)).join(','),
    },
    {
      colId: 'highRisk',
      headerName: 'High Risk',
      minWidth: 95,
      field: 'highRiskTypeId',
      valueGetter: params => {
        const { data } = params;
        if (isNullOrUndefined(data) || isNullOrUndefined(data.highRiskTypeId)) return '';
        return HighRiskAttributes[data.highRiskTypeId].name;
      },
    },
  ];

  return columnDefinitions;
};

export const DefaultColDefinition: ColDef = {
  minWidth: 45,
  resizable: true,
  sortable: false,
  editable: false,
  filter: false,
  hide: false,
  lockVisible: true,
  suppressNavigable: true,
};
