import AphCalculationParams from '../../../types/api/calculationData/aphCalculationParams';
import { getAphCalcData } from '../../calculationData.service';
import { admDb, iceDb } from '../../../db';
import {
  getBaseRate,
  getCoverageLevelDifferentials,
  getGuaranteeAdjustment,
  getHarvestCostAmount,
  getHistoricalRevenueCapping,
  getInsuranceOfferAvailability,
  getInsuranceOfferInformation,
  getMaximumContractPrice,
  getMaximumReplantGuaranteePerAcre,
  getMultipleCommodityAdjustmentFactor,
  getOptionConversionFactor,
  getOptionRates,
  getPreviousYearYieldLimitationCode,
  getStageFactor,
  getSubCountyRate,
  getSubsidyPercent,
  getUnitDiscountLevels,
  getYieldConversionFactor
} from '../../localCalcDataQueries.service';
import {
  BaseRate,
  CoverageLevelDifferential,
  OptionRate,
  ProductOfferAvailabilities,
  ScenarioPieceType,
  SubCountyRate,
  UnitDiscountLevel
} from '@silveus/calculations';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import { ProductOfferAvailabilityParams } from './OfferAvailability';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { AphCalculationParamsRequest } from '../../../types/api/calculationData/aphCalculationParamsRequest';

export const getAphCalcDataRequest = async (calcParamRequest: AphCalculationParamsRequest): Promise<AphCalculationParams[]> => {
  const request = () => getAphCalcData(calcParamRequest);

  const admTransactionTables = [
    admDb.subsidies,
    admDb.insuranceOffers,
    admDb.coverageLevelDifferentials,
    admDb.baseRates,
    admDb.optionRates,
    admDb.unitDiscountLevels,
    admDb.historicalRevenueCappings,
    admDb.subCountyRates,
    admDb.prices,
  ];

  const iceTransactionTables = [
    iceDb.guaranteeAdjustments,
  ];

  const readTransaction = async (): Promise<AphCalculationParams[]> => {
    const multipleCroppingCode = 'NS';
    const premiumSurchargeApplied = 'N';

    const stateCode = calcParamRequest.countyId.substring(0, 2);

    const aphResponse: AphCalculationParams[] = [];

    const unitTransactions = calcParamRequest.distinctUnits.map(async distinctUnit => {
      const admData = await admDb.transaction('r', admTransactionTables, async () => {

        const subsidyPercent = getSubsidyPercent(calcParamRequest.commodityCode, calcParamRequest.unitStructureCode, calcParamRequest.planCode, calcParamRequest.upperCoverageLevel, calcParamRequest.coverageTypeCode);

        const insuranceOffer = await getInsuranceOfferInformation(calcParamRequest.planCode, calcParamRequest.countyId, distinctUnit.typeId, distinctUnit.practiceId, calcParamRequest.coverageTypeCode);

        if (insuranceOffer === null) return null;
        const baseRate = await getBaseRate(insuranceOffer.insuranceOfferId);
        const highRiskTypeId = calcParamRequest.highRiskTypeId;
        const coverageLevelDifferentials = getCoverageLevelDifferentials(insuranceOffer.insuranceOfferId, calcParamRequest.coverageTypeCode, distinctUnit.subCountyCode);
        const optionRates = getOptionRates(insuranceOffer.insuranceOfferId);
        const optionConversionFactor = getOptionConversionFactor(insuranceOffer.insuranceOfferId);
        const unitDiscountLevels = insuranceOffer.unitDiscountId === null ? Promise.resolve([]) : getUnitDiscountLevels(insuranceOffer.unitDiscountId);

        const historicalRevenueCapping = getHistoricalRevenueCapping(insuranceOffer.insuranceOfferId);
        const subCountyRate = getSubCountyRate(insuranceOffer.insuranceOfferId, highRiskTypeId);
        const harvestCostAmount = getHarvestCostAmount(insuranceOffer.insuranceOfferId, highRiskTypeId);
        const maximumContractPrice = getMaximumContractPrice(insuranceOffer.insuranceOfferId, highRiskTypeId);


        await Promise.all([optionRates, optionConversionFactor, unitDiscountLevels, historicalRevenueCapping, subCountyRate, coverageLevelDifferentials, subsidyPercent, maximumContractPrice, harvestCostAmount]);

        return {
          baseRate: baseRate,
          coverageLevelDifferentials: await coverageLevelDifferentials,
          harvestCostAmount: await harvestCostAmount,
          historicalRevenueCapping: await historicalRevenueCapping,
          maximumContractPrice: await maximumContractPrice,
          optionRates: await optionRates,
          optionConversionFactor: await optionConversionFactor,
          subCountyRate: await subCountyRate,
          subsidyPercent: await subsidyPercent,
          unitDiscountLevels: await unitDiscountLevels,
          unitOfMeasure: insuranceOffer.unitOfMeasure,
        };
      });

      if (admData === null) return;

      const iceData = await iceDb.transaction('r', iceTransactionTables, async () => {
        const countyCode = calcParamRequest.countyId.substring(2, 5);
        const practiceCode = distinctUnit.practiceId.substring(4, 7);
        const typeCode = distinctUnit.typeId.substring(4, 7);

        const highRiskTypeId = calcParamRequest.highRiskTypeId;

        const guaranteeAdjustment = getGuaranteeAdjustment(calcParamRequest.commodityCode, calcParamRequest.planCode, stateCode, countyCode, typeCode, practiceCode, highRiskTypeId);
        const maximumReplantGuaranteePerAcre = getMaximumReplantGuaranteePerAcre();
        const multipleCommodityAdjustmentFactor = getMultipleCommodityAdjustmentFactor(multipleCroppingCode);
        const previousYearYieldLimitationCode = getPreviousYearYieldLimitationCode();
        const stageFactor = getStageFactor();
        const yieldConversionFactor = getYieldConversionFactor();

        await Promise.all([guaranteeAdjustment, maximumReplantGuaranteePerAcre, multipleCommodityAdjustmentFactor, previousYearYieldLimitationCode, stageFactor, yieldConversionFactor]);

        return {
          guaranteeAdjustment: await guaranteeAdjustment,
          maximumReplantGuaranteePerAcre: await maximumReplantGuaranteePerAcre,
          multipleCommodityAdjustmentFactor: await multipleCommodityAdjustmentFactor,
          previousYearYieldLimitationCode: await previousYearYieldLimitationCode,
          stageFactor: await stageFactor,
          yieldConversionFactor: await yieldConversionFactor,
        };
      });

      const aphCalculationParams: AphCalculationParams = {
        unit: distinctUnit,
        baseRate: admData.baseRate as BaseRate,
        coverageLevelDifferentials: admData.coverageLevelDifferentials as CoverageLevelDifferential[],
        depreciationFactor: iceData.stageFactor.depreciationFactor,
        guaranteeAdjustmentFactor: iceData.guaranteeAdjustment?.fixedGuaranteeAdjustmentFactor ?? null,
        harvestCostAmount: admData.harvestCostAmount,
        insuredsActualCost: 0, // Defaulting to 0 because it is only needed for replant which is not supported yet.
        maximumContractPrice: admData.maximumContractPrice,
        maximumReplantGuaranteePerAcre: iceData.maximumReplantGuaranteePerAcre,
        multipleCommodityAdjustmentFactor: iceData.multipleCommodityAdjustmentFactor,
        optionRates: admData.optionRates as OptionRate[],
        premiumSurchargeApplied: premiumSurchargeApplied,
        previousYearYieldLimitationCode: iceData.previousYearYieldLimitationCode,
        stageCode: iceData.stageFactor.stageCode,
        stagePercentFactor: iceData.stageFactor.stagePercentFactor,
        stagePricePercentFactor: iceData.stageFactor.stagePricePercentFactor,
        subCountyRate: admData.subCountyRate as SubCountyRate,
        subsidyPercent: admData.subsidyPercent,
        unitDiscountLevels: admData.unitDiscountLevels as UnitDiscountLevel[],
        unitOfMeasure: admData.unitOfMeasure,
        yieldConversionFactor: iceData.yieldConversionFactor,
      };

      aphResponse.push(aphCalculationParams);
    });

    await Promise.all(unitTransactions);

    return aphResponse;
  };

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getOfferAvailability = async (productOfferAvailabilityParams: ProductOfferAvailabilityParams): Promise<ProductOfferAvailabilities[]> => {
  const scenarioPieceTypes: ScenarioPieceType[] = [ScenarioPieceType.Aph];

  const offerAvailabilityPromises = scenarioPieceTypes.map(scenarioPieceType => {
    return getInsuranceOfferAvailability(
      productOfferAvailabilityParams.year,
      productOfferAvailabilityParams.countyId,
      productOfferAvailabilityParams.commodityCode,
      getInsurancePlanCodeForScenarioPiece(scenarioPieceType),
      scenarioPieceType);
  });

  return Promise.all(offerAvailabilityPromises);
};
