import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { MenuItem, TextField } from '@mui/material';
import { DesignatedCountyElection, DesignatedCountyElectionAttributes } from '../../../types/api/enums/application/designatedCountyElection';
import { validateNationState } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import { handleValidationResult } from './validationHelpers';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { useEffect } from 'react';

type DesignatedCountyElectionInputProps = {
  value: Nullable<DesignatedCountyElection>;
  index: number;
  application: ApplicationWizard;
  canEditAppTask: boolean;
}

const DesignatedCountyElectionInput = ({ value, index, application, canEditAppTask }: DesignatedCountyElectionInputProps) => {
  const inputName = `appTasks.${index}.designatedCountyElection`;
  const { control, setValue } = useFormContext();

  const isDesignatedCounty: Nullable<boolean> = useWatch({ name: `appTasks.${index}.isDesignatedCounty` });

  useEffect(() => {
    if (isDesignatedCounty ?? false) {
      setValue(inputName, value, { shouldValidate: true });
    } else {
      setValue(inputName, null);
    }
  }, [isDesignatedCounty]);

  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={value}
      rules={{ validate: val => handleValidationResult(validateNationState(val, isDesignatedCounty)) }}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          select
          disabled={!application.isGeneratingForms || !(isDesignatedCounty ?? false) || !canEditAppTask}
          error={!!error}
          inputRef={ref}
          size="small"
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          {Object.values(DesignatedCountyElectionAttributes).map(ns => <MenuItem key={ns.value} value={ns.value}>{ns.name}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default DesignatedCountyElectionInput;