import { useFormContext } from 'react-hook-form';
import { Nullable } from '../../../../types/util/Nullable';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useEffect } from 'react';
import { selectOfferMpProjectedPrice } from '../../../../app/admSlice';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import MpProjectedPriceInput from './mpProjectedPriceInput';
import { FormInputProps } from '../../scenario/formInputProps';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import { Quote } from '../../../../types/api/Quote';
import { RowCropScenario } from '../../../../types/api/RowCropScenario';
import { useOptionsForSpForm } from '../../../../hooks/useOptionsForSpForm';

interface ScenarioPieceMpProjectedPriceProps extends FormInputProps {
  quote: Quote;
  scenario: Nullable<RowCropScenario>;
  scenarioPiece: Nullable<RowCropScenarioPiece>
}

export const ScenarioPieceMpProjectedPriceInput = ({ quote, scenario, scenarioPiece, sx, autoFocus = false, disabled = false }: ScenarioPieceMpProjectedPriceProps) => {
  const fieldName = ExtendedDataFormInputParameterNames.ProjectedPrice;
  const { setValue, trigger } = useFormContext();
  const offerMpProjectedPrice = useAppSelector(selectOfferMpProjectedPrice);
  const { scenarioOptionsForSpForm } = useOptionsForSpForm();
  useEffect(() => {
    if (scenarioPiece !== null || offerMpProjectedPrice === null) return;

    setValue(fieldName, offerMpProjectedPrice);
    trigger(fieldName);
  }, [offerMpProjectedPrice]);

  return (
    <MpProjectedPriceInput
      quote={quote}
      scenario={scenario}
      scenarioOptionState={scenarioOptionsForSpForm}
      projectedPrice={scenarioPiece?.rowCropScenarioPieceExtendedData?.projectedPrice ?? null}
      sx={sx}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  );
};
