import ScenarioQuickUnit from '../../types/api/ScenarioQuickUnit';
import { ClientFileId, InsuredId, QuoteId, ScenarioId } from '../../types/api/PrimaryKeys';
import { getItemsForId } from '../mapHelpers';
import { ClientFile } from '../../types/api/ClientFile';
import { Quote } from '../../types/api/Quote';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { generatePrimaryKey } from '../primaryKeyHelpers';

export interface ScenarioQuickUnitsReconciliationParams {
  clientFilesMap: Map<InsuredId, ClientFile[]>;
  quotes: Map<ClientFileId, Quote[]>;
  rowCropScenarios: Map<QuoteId, RowCropScenario[]>;
  quickUnits: Map<ScenarioId, ScenarioQuickUnit>;
}

/**
 * Reconciles scenario unit year APH records given a set of data representing the user's selected source of truth
 * @param clientFilesMap - The client files selected by the user, grouped by insured id
 * @param quotes - The quotes selected by the user, grouped by client file id
 * @param rowCropScenarios - The row crop scenarios selected by the user, grouped by quote id
 * @param quickUnits - The quick units for the scenarios selected by the user
 * @return reconciledScenarioQuickUnits A collection of all of the scenario quick units that were created and need to be added
 */
export const getReconciledScenarioQuickUnits = ({
  clientFilesMap,
  quotes,
  rowCropScenarios,
  quickUnits,
}: ScenarioQuickUnitsReconciliationParams): ScenarioQuickUnit[] => {
  const reconciledScenarioQuickUnits: ScenarioQuickUnit[] = [];

  clientFilesMap.forEach(clientFiles => {
    clientFiles.forEach(clientFile => {
      const applicableQuotes = getItemsForId(quotes, clientFile.clientFileId);

      applicableQuotes.forEach(quote => {
        if (!quote.quickQuote) return;

        const applicableScenarios = getItemsForId(rowCropScenarios, quote.quoteId);

        applicableScenarios.forEach(scenario => {
          const applicableQuickUnit = quickUnits.get(scenario.scenarioId);
          if (applicableQuickUnit !== undefined) return;

          //create new quick unit and add it to the collection of scenario quick units
          if (scenario.practiceId === null)
            throw new Error('Unable to create scenario quick unit when reconciling due to missing practice');

          //TODO: Change acre, share percent, and yield values to be values calculated based on the reconciled units
          const scenarioQuickUnit: ScenarioQuickUnit = {
            acres: 100,
            aphYield: 100,
            approvedYield: 100,
            commodityCode: quote.commodityCode,
            countyId: quote.countyId,
            practiceId: scenario.practiceId,
            rateYield: 100,
            scenarioId: scenario.scenarioId,
            scenarioQuickUnitId: generatePrimaryKey(),
            sharePercent: 1,
            typeId: scenario.typeId,
            offlineCreatedOn: undefined,
            offlineLastUpdatedOn: undefined,
            offlineDeletedOn: undefined,
          };

          reconciledScenarioQuickUnits.push(scenarioQuickUnit);
        });
      });
    });
  });

  return reconciledScenarioQuickUnits;
};