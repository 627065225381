import { valueof } from '../types/util/valueof';
import { Option } from '../types/api/adm/Option';

export const OptionLevelCodes = {
  Commodity: 'C',
  EnterpriseUnit: 'E',
  Unit: 'U',
} as const;

export type OptionLevelCode = valueof<typeof OptionLevelCodes>;

export function byCommodity(options: readonly Option[]) {
  return options.filter(option => option.optionLevelCode === OptionLevelCodes.Commodity);
}
export function byEnterpriseUnit(options: readonly Option[]) {
  return options.filter(option => option.optionLevelCode === OptionLevelCodes.EnterpriseUnit);
}
export function byUnit(options: readonly Option[]) {
  return options.filter(option => option.optionLevelCode === OptionLevelCodes.Unit);
}
