import MESSAGES from '../../../../constants/messages';
import { EntityTypeId, TaxTypeId } from '../../../../types/api/PrimaryKeys';
import { EntityTypeEnum } from '../../../../types/api/enums/application/entityType.enum';
import { PersonOfInterestType } from '../../../../types/api/enums/contactInfo/personOfInterestType';
import { PersonOrBusiness } from '../../../../types/api/enums/contactInfo/personOrBusiness';
import { EntityType } from '../../../../types/api/insureds/EntityType';
import { Insured, InsuredType } from '../../../../types/api/insureds/Insured';
import { PersonOfInterest } from '../../../../types/api/insureds/PersonOfInterest';
import { TaxType } from '../../../../types/api/insureds/TaxType';
import { Nullable } from '../../../../types/util/Nullable';
import { isNotNilOrEmpty, isNotNullOrUndefined, isNullOrUndefined } from '../../../../utils/nullHandling';
import { isNilOrEmpty } from '@paravano/utils';
import { InsuredInfoFormFields } from '../../../../pages/applicationsModal/wizardSteps/insuredInfoStep';
import { SpouseContactInfoFields } from '../../../../pages/applicationsModal/wizardSteps/spouseInfoStep';

export const TaxIdLength = 9;

type Contact = PersonOfInterest | Insured;

export function validateFirstName(contact: Contact) {
  if (isNotNullOrUndefined(contact.personOrBusiness) && (contact.firstName === null || contact.firstName.length === 0)) return 'First name is required';
  return true;
}

export function validateLastName(contact: Contact) {
  if (isNotNullOrUndefined(contact.personOrBusiness) && (contact.personOrBusiness === PersonOrBusiness.PERSON && (contact.lastName === null || contact.lastName.length === 0))) return 'Last name is required';
  return true;
}

export function validatePersonOrBusiness(contact: Contact) {
  if (contact.personOrBusiness === null) return MESSAGES.required;
  return true;
}

export function validateAddress(personOfInterest: PersonOfInterest) {
  if (personOfInterest.personOfInterestType !== PersonOfInterestType.PowerOfAttorney && personOfInterest.personOfInterestType !== PersonOfInterestType.AuthorizedRep && personOfInterest.personOfInterestType !== PersonOfInterestType.AuthorizedToSign &&
    (personOfInterest.address === null || personOfInterest.address.addressLine1 === null || personOfInterest.address.addressLine1.length === 0)) {
    return 'Address is required';
  }

  return true;
}

export function validateInsuredAddress(insured: Insured) {
  if (isNotNullOrUndefined(insured.personOrBusiness) &&
    (insured.address.addressLine1 === null || insured.address.addressLine1.length === 0)) {
    return 'Address is required';
  }

  return true;
}

export function validateCity(personOfInterest: PersonOfInterest) {
  if (personOfInterest.personOfInterestType !== PersonOfInterestType.PowerOfAttorney && personOfInterest.personOfInterestType !== PersonOfInterestType.AuthorizedRep && personOfInterest.personOfInterestType !== PersonOfInterestType.AuthorizedToSign &&
    (personOfInterest.address === null || personOfInterest.address.city === null || personOfInterest.address.city.length === 0)) {
    return 'City is required';
  }
  return true;
}

export function validateInsuredCity(insured: Insured) {
  if (isNullOrUndefined(insured.address.city) || insured.address.city.length === 0) {
    return 'City is required';
  }
  return true;
}

export function validateState(personOfInterest: PersonOfInterest) {
  if (personOfInterest.personOfInterestType !== PersonOfInterestType.PowerOfAttorney && personOfInterest.personOfInterestType !== PersonOfInterestType.AuthorizedRep && personOfInterest.personOfInterestType !== PersonOfInterestType.AuthorizedToSign &&
    (personOfInterest.address === null || personOfInterest.address.state === null || personOfInterest.address.state.length === 0)) {
    return 'State is required';
  }
  return true;
}

export function validateInsuredState(insured: Insured) {
  if (isNullOrUndefined(insured.address.state) || insured.address.state.length === 0) {
    return 'State is required';
  }
  return true;
}

export function validateEntityType(personOfInterest: PersonOfInterest) {
  if (personOfInterest.personOfInterestType === PersonOfInterestType.PowerOfAttorney || personOfInterest.personOfInterestType === PersonOfInterestType.AuthorizedToSign) {
    return true;
  }

  if (isNotNullOrUndefined(personOfInterest.personOrBusiness) && (isNullOrUndefined(personOfInterest.entityTypeId))) return 'Entity type is required';
  return true;
}

export function validateInsuredEntityType(insured: Insured) {
  if (isNotNullOrUndefined(insured.personOrBusiness) && (isNullOrUndefined(insured.entityTypeId))) return 'Entity type is required';
  return true;
}

export function validateEntityTypeAndTaxIdForPersonOfInterest(contact: PersonOfInterest) {
  if (contact.personOfInterestType === PersonOfInterestType.PowerOfAttorney || contact.personOfInterestType === PersonOfInterestType.AuthorizedToSign || contact.personOfInterestType === PersonOfInterestType.AuthorizedRep) {
    return true;
  }
  return validateEntityTypeAndTaxId(contact);
}

export function validateEntityTypeAndTaxId(contact: Contact) {
  if (isNotNilOrEmpty(contact.entityTypeId) && isNilOrEmpty(contact.taxId)) return 'Tax ID is required when Entity Type has been selected.';
  if (isNilOrEmpty(contact.entityTypeId) && isNotNilOrEmpty(contact.taxId)) return 'Entity Type is required when Tax ID has been entered.';

  // If both are provided tax id must be valid
  if (isNotNullOrUndefined(contact.entityTypeId) && isNotNullOrUndefined(contact.taxId) && contact.taxId.length !== TaxIdLength) return 'Invalid Tax ID';
  return true;
}

export function validateCorporationState(insured: Insured, entityTypes: EntityType[]) {
  const corporationEntityType = entityTypes.find(x => x.name === EntityTypeEnum.Corporation);
  const llcEntityType = entityTypes.find(x => x.name === EntityTypeEnum.LimitedLiabilityCompany);
  const isACorporationOrLlcEntityType = insured.entityTypeId === corporationEntityType?.entityTypeId || insured.entityTypeId === llcEntityType?.entityTypeId;
  if (isACorporationOrLlcEntityType && isNullOrUndefined(insured.corporationState)) {
    return 'Corporation state must be selected when Entity Type is Corporation or LLC';
  }

  return true;
}

export function validateTaxTypeId(personOrBusiness: Nullable<PersonOrBusiness>, taxTypeId: Nullable<TaxTypeId>, entityTypeId: Nullable<EntityTypeId>, entityTypes: EntityType[], taxTypes: TaxType[], personOfInterestType?: PersonOfInterestType, insuredType?: InsuredType) {

  if (personOfInterestType === PersonOfInterestType.AuthorizedToSign || personOfInterestType === PersonOfInterestType.PowerOfAttorney) {
    return true;
  }

  if (taxTypeId === null && insuredType === 'Insured') return MESSAGES.required;

  const irrevocableTrustType = entityTypes.find(x => x.name.toLowerCase().indexOf('irrevocable') >= 0)?.entityTypeId;
  const ssnType = taxTypes.find(x => x.name.toLowerCase() === 'ssn')?.taxTypeId;
  if (taxTypeId === ssnType && entityTypeId === irrevocableTrustType) {
    return 'TaxIdType cannot be SSN when Entity Type is Irrevocable Trust';
  }

  const corporationEntityType = entityTypes.find(x => x.name.toLowerCase().indexOf('corporation') >= 0)?.entityTypeId;
  const einType = taxTypes.find(x => x.name.toLocaleLowerCase() === 'ein')?.taxTypeId;
  if (taxTypeId !== einType && entityTypeId === corporationEntityType) {
    return 'TaxIdType must be EIN when Entity Type is Corporation';
  }

  if (personOrBusiness === PersonOrBusiness.PERSON && taxTypeId !== ssnType) {
    return 'TaxType must be SSN when PersonType is selected';
  }

  return true;
}

export function validatePhoneNumberString(phone: Nullable<string>, required: boolean = true) {
  if (isNullOrUndefined(phone) || phone.length === 0) return required ? MESSAGES.required : true;
  const isPhoneValid = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone);
  if (!isPhoneValid) return 'Invalid phone number';
  return true;
}

export function validatePhoneNumber(personOfInterest: PersonOfInterest, isGettingESignatures: boolean) {
  if (personOfInterest.personOfInterestType === PersonOfInterestType.PowerOfAttorney || personOfInterest.personOfInterestType === PersonOfInterestType.AuthorizedToSign || personOfInterest.personOfInterestType === PersonOfInterestType.AuthorizedRep) {
    // the check for isGettingESignatures and POA must happen before the length check because we want to require the email
    if (isGettingESignatures && (personOfInterest.personOfInterestType === PersonOfInterestType.PowerOfAttorney || personOfInterest.personOfInterestType === PersonOfInterestType.AuthorizedRep)) {
      return validatePhoneNumberString(personOfInterest.phone);
    }
    // We only care about validating phone number for POA and Auth if one is provided, otherwise phone number can be empty
    return validatePhoneNumberString(personOfInterest.phone, false);
  } else {
    // For all other contact types we must validate the number, including that it must be provided.
    return validatePhoneNumberString(personOfInterest.phone);
  }
}

export function validateInsuredPhoneNumber(insured: Insured) {
  return validatePhoneNumberString(insured.phone);
}

export function validateEmail(personOfInterest: PersonOfInterest, isGettingESignatures: boolean) {
  // the check for isGettingESignatures must happen before the length check because we want to require the email
  if (isGettingESignatures && (personOfInterest.personOfInterestType === PersonOfInterestType.PowerOfAttorney || personOfInterest.personOfInterestType === PersonOfInterestType.AuthorizedRep)) return validateEmailString(personOfInterest.email);
  if (isNullOrUndefined(personOfInterest.email) || personOfInterest.email.length === 0) return true;
  return validateEmailString(personOfInterest.email);
}

export function validateInsuredEmail(insured: Insured, isGettingESignatures: boolean) {
  // the check for isGettingESignatures must happen before the length check because we want to require the email
  if (isGettingESignatures) return validateEmailString(insured.email);
  if ((isNullOrUndefined(insured.email) || insured.email.length === 0)) return true;
  return validateEmailString(insured.email);
}

export function validateEmailString(email: Nullable<string>, required: boolean = true) {
  if (isNullOrUndefined(email) || email.length === 0) return required ? MESSAGES.required : true;
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  if (!isEmailValid) return 'Invalid email';
  return true;
}

export function validateTaxId(contact: PersonOfInterest) {
  // POA/AuthReps and Auth's to sign do not have tax id inputs so they can be ignored for validation
  if (contact.personOfInterestType === PersonOfInterestType.PowerOfAttorney || contact.personOfInterestType === PersonOfInterestType.AuthorizedRep || contact.personOfInterestType === PersonOfInterestType.AuthorizedToSign) {
    return true;
  }

  const { taxId } = contact;
  if (isNullOrUndefined(taxId)) return MESSAGES.required;
  if (taxId.length !== TaxIdLength) return 'Invalid Tax ID';
  return true;
}

export function validateInsuredTaxId(taxId: Nullable<string>) {
  if (isNullOrUndefined(taxId) || taxId.length === 0) return MESSAGES.required;
  if (taxId.length !== TaxIdLength) return 'Invalid Tax ID';
  return true;
}

export function validateZipCode(contact: PersonOfInterest) {
  if (contact.personOfInterestType !== PersonOfInterestType.PowerOfAttorney && contact.personOfInterestType !== PersonOfInterestType.AuthorizedRep && contact.personOfInterestType !== PersonOfInterestType.AuthorizedToSign &&
    (isNullOrUndefined(contact.address?.postalCode) || contact.address?.postalCode.length === 0)) {
    return MESSAGES.required;
  }

  if (isNullOrUndefined(contact.address?.postalCode) || contact.address?.postalCode.length === 0) {
    return true;
  }

  return validateZipCodeString(contact.address?.postalCode ?? '');
}

export function validateInsuredZipCode(insured: Insured) {
  if (isNullOrUndefined(insured.address.postalCode)) {
    return MESSAGES.required;
  }
  return validateZipCodeString(insured.address.postalCode);
}

export function validateZipCodeString(postalCode: string) {
  if (isNullOrUndefined(postalCode) || postalCode.length === 0) return true;
  var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postalCode);
  if (!isValidZip) return 'Zip code not valid';
  return true;
}

export function validateEntityTypeAndTaxIdNoMessage(formValues: InsuredInfoFormFields | SpouseContactInfoFields) {
  const { taxId, entityTypeId } = formValues;

  return (isNotNilOrEmpty(entityTypeId) && isNilOrEmpty(taxId)) || (isNilOrEmpty(entityTypeId) && isNotNilOrEmpty(taxId));
}

export function validatePersonOfInterest(contact: PersonOfInterest, entityTypes: EntityType[], taxTypes: TaxType[], isGettingESignatures: boolean) {
  const validationResults = [
    validatePersonOrBusiness(contact),
    validateFirstName(contact),
    validateLastName(contact),
    validateZipCode(contact),
    validateTaxId(contact),
    validateEmail(contact, isGettingESignatures),
    validatePhoneNumber(contact, isGettingESignatures),
    validateTaxTypeId(contact.personOrBusiness, contact.taxTypeId, contact.entityTypeId, entityTypes, taxTypes, contact.personOfInterestType),
    validateEntityType(contact),
    validateState(contact),
    validateCity(contact),
    validateAddress(contact),
  ];

  return validationResults.every(x => x === true);
}

export function validateInsured(insured: Insured, entityTypes: EntityType[], taxTypes: TaxType[], isGettingESignatures: boolean) {
  const validationResults = [
    validatePersonOrBusiness(insured),
    validateFirstName(insured),
    validateLastName(insured),
    validateInsuredZipCode(insured),
    validateInsuredTaxId(insured.taxId),
    validateInsuredEmail(insured, isGettingESignatures),
    validateInsuredPhoneNumber(insured),
    validateTaxTypeId(insured.personOrBusiness, insured.taxTypeId, insured.entityTypeId, entityTypes, taxTypes),
    validateInsuredEntityType(insured),
    validateInsuredState(insured),
    validateInsuredCity(insured),
    validateInsuredAddress(insured),
    validateCorporationState(insured, entityTypes),
  ];

  return validationResults.every(x => x === true);
}

/**
 * Here we only care about a few properties on the contact that will be enough to make it so
 * we can continue on to the next page.
 */
export function validateMinimumRequirementsForContact(contact: PersonOfInterest) {
  const validationResults = getMinimumValidationResultsForContact(contact);
  return validationResults.every(x => x === true);
}

export function getMinimumValidationResultsForContact(contact: PersonOfInterest) {
  const validationResults = [
    validatePersonOrBusiness(contact),
    validateFirstName(contact),
    validateLastName(contact),
    validateEntityTypeAndTaxIdForPersonOfInterest(contact),
  ];
  return validationResults;
}

export function validateMinimumRequirementsForSpouse(contact: PersonOfInterest) {
  const validationResults = [
    validateFirstName(contact),
    validateLastName(contact),
  ];

  return validationResults.every(x => x === true);
}

export function validateMinimumRequirementsForInsured(contact: Insured) {
  const validationResults = [
    validatePersonOrBusiness(contact),
    validateFirstName(contact),
    validateLastName(contact),
    validateEntityTypeAndTaxId(contact),
  ];

  return validationResults.every(x => x === true);
}

// All the validation code returns true if valid, otherwise it returns a string with the error message.
export function checkValidationResult(result: boolean | string) {
  return result === true ? false : true;
}