import { roundToPlaces, UnitResponseDTO } from '@silveus/calculations';
import { HailScenarioPieceRateId } from '../../../types/api/PrimaryKeys';
import UnitYear from '../../../types/api/UnitYear';
import HailScenarioPieceRate from '../../../types/api/hail/HailScenarioPieceRate';
import HailRate from '../../../types/api/hail/hailRate';
import { getHailRateForUnitFromHailRateMap, getHailRatesByTownshipRange } from './hailRateUtils';

export interface HailScenarioPieceRateCalculatedValues {
  acres: number;
  totalPremium: number;
}

export function getScenarioPieceRateCalculatedValuesWhenUnitQuoting(
  hailScenarioPieceRates: HailScenarioPieceRate[],
  hailRates: HailRate[],
  unitYears: UnitYear[],
  calculatedUnitValues: UnitResponseDTO[]): Map<HailScenarioPieceRateId, HailScenarioPieceRateCalculatedValues> {
  const hailScenarioPieceRateAcres = new Map<HailScenarioPieceRateId, HailScenarioPieceRateCalculatedValues>();
  //I need to determine the acres/total premium for each hail scenario piece rate based on the units township/range
  const hailRatesByTownshipRange = getHailRatesByTownshipRange(hailRates);
  unitYears.forEach(unit => {
    const hailRate = getHailRateForUnitFromHailRateMap(unit, hailRatesByTownshipRange);
    if (hailRate === undefined) return;

    const hailScenarioPieceRate = hailScenarioPieceRates.find(hr => hr.rateId === hailRate.rateId);
    if (hailScenarioPieceRate === undefined) return;

    const unitPremium = calculatedUnitValues.find(cuv => cuv.id === unit.unitYearId)?.totalPremium ?? 0;

    const existingValues = hailScenarioPieceRateAcres.get(hailScenarioPieceRate.hailScenarioPieceRateId);
    const unitAcres = roundToPlaces(unit.acres * unit.sharePercent, 2);
    if (unitAcres > 0) {
      const newCalculatedValues: HailScenarioPieceRateCalculatedValues = {
        acres: unitAcres + (existingValues?.acres ?? 0),
        totalPremium: unitPremium + (existingValues?.totalPremium ?? 0),
      };
      hailScenarioPieceRateAcres.set(hailScenarioPieceRate.hailScenarioPieceRateId, newCalculatedValues);
    }
  });
  return hailScenarioPieceRateAcres;
}