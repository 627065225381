import MESSAGES from '../../../constants/messages';
import { Nullable } from '../../../types/util/Nullable';

export function validateYield(yieldValue: Nullable<number>, allowZero: boolean = false): true | string {
  if (yieldValue === null) return MESSAGES.required;
  if (yieldValue > 99999999.99) return MESSAGES.maximum(99999999.99);
  if (yieldValue < 0) return MESSAGES.minimum(0);
  if (yieldValue === 0 && !allowZero) return MESSAGES.greaterThan(0);
  if (yieldValue.toString().length > 11) return MESSAGES.maxLength(11);

  return true;
}
