import {
  Button,
  Grid,
  useTheme
} from '@mui/material';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectIsLightMode } from '../../app/userSettingsSlice';

type UnitsAphModalHeaderControlsProps = {
  onClose: () => void;
  disabled: boolean;
}

const UnitsAphModalHeaderControls = ({ onClose, disabled }: UnitsAphModalHeaderControlsProps) => {
  const theme = useTheme();
  const isLightMode = useAppSelector(selectIsLightMode);

  return (
    <Grid container columnGap={1}>
      <Grid item>
        <Button
          id="btn-units-close"
          sx={{
            backgroundColor: isLightMode ? theme.palette.common.white : theme.palette.common.black,
            color: theme.palette.primary.main,
          }}
          variant="contained"
          disabled={disabled}
          onClick={onClose}>
          Close
        </Button>
      </Grid>
    </Grid>
  );
};

export default UnitsAphModalHeaderControls;
