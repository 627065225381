import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../app/store';
import { getItemsForId } from '../utils/mapHelpers';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


export function useKeyMapSelector<TKey, TResult>(selector: (state: RootState) => Map<TKey, TResult[]>, key: TKey): TResult[] {
  const map = useAppSelector(selector);
  return getItemsForId(map, key);
}

export function useKeyMapSelectorSingle<TKey, TResult>(selector: (state: RootState) => Map<TKey, TResult>, key: TKey) {
  const map = useAppSelector(selector);
  const result = map.get(key) ?? null;
  return result;
}