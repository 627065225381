import { Nullable } from '../../../types/util/Nullable';
import { Controller } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { MatrixOffsetType } from '../../../types/api/enums/matrixOffsetType/MatrixOffsetType.enum';
import { MatrixOffsetTypeAttributes } from '../../../types/api/enums/matrixOffsetType/MatrixOffsetTypeAttributes';

interface BottomAxisOffsetTypeProps {
  bottomAxisOffsetType: Nullable<MatrixOffsetType>;
  autoFocus?: boolean;
  disabled?: boolean;
}

export type BottomAxisOffsetTypeFields = {
  bottomAxisOffsetType: MatrixOffsetType;
}

export const BottomAxisOffsetTypeInput = ({ bottomAxisOffsetType, autoFocus = false, disabled = false }: BottomAxisOffsetTypeProps) => {
  return (
    <FormControl>
      <Controller
        name="bottomAxisOffsetType"
        rules={{ required: VALIDATION_RULES.required() }}
        defaultValue={bottomAxisOffsetType ?? MatrixOffsetType.Unset}
        render={({
          field: { ref, value, ...field }, fieldState: { error },
        }) => (
          <RadioGroup
            {...field}
            value={value}
            name="bottom-axis-offset-type-group"
            onChange={e => field.onChange(parseInt(e.target.value))}
            row
          >
            <FormControlLabel value={MatrixOffsetType.PercentChange} control={<Radio color="primary" size="small" />} label={<Typography variant="body2">{MatrixOffsetTypeAttributes[MatrixOffsetType.PercentChange].description}</Typography>} />
            <FormControlLabel value={MatrixOffsetType.UpDown} control={<Radio color="primary" size="small" />} label={<Typography variant="body2">{MatrixOffsetTypeAttributes[MatrixOffsetType.UpDown].description}</Typography>} />
          </RadioGroup>

        )}
      />
    </FormControl>
  );
};
