import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import * as React from 'react';
import { CoverageDecisionStep } from './coverageDecisionPage/coverageDecisionStep';
import { AppQuestionsStep } from './appQuestionsStep';
import { InsuredInfoStep } from './insuredInfoStep';
import { InsuredQuestionsStep } from './insuredQuestionsStep';
import { SpouseInfoStep } from './spouseInfoStep';
import { SBIInfoStep } from './sbiInfoStep';
import { POAAuthRepStep } from './poaAuthRepStep';
import { LandlordTenantStep } from './landlordTenantStep';
import { AuthToSignStep } from './authToSignStep';
import { ConditionsOfAcceptanceStep } from './conditionsOfAcceptanceStep';
import { ApplicationDecisionStep } from './appDecisionsPage/applicationDecisionPage';
import { ClientFileId } from '../../../types/api/PrimaryKeys';
import { RootState } from '../../../app/store';

export interface ApplicationWizardStep {
  isDisabled: (app: ApplicationWizard, state: RootState) => boolean;
  title: string;
  index: number;
  formId: string;
  isValid: (app: ApplicationWizard, state: RootState) => ApplicationWizardStepValidationResult;
  icon: React.ReactElement;
}

export interface ApplicationWizardStepValidationResult {
  isValid: boolean;
  errors: string[];
}

export type ApplicationWizardStepProps = {
  application: ApplicationWizard;
  navigateToPreviousStep: () => void;
  navigateToNextStep: () => void;
  clientFileId: ClientFileId;
  updateIsInEditMode: (val: boolean) => void;
  updateApplication: (application: ApplicationWizard) => void;
  isInEditMode: boolean;
  isLoading: boolean;
  updateIsLoading: (val: boolean) => void;
}

export const ApplicationWizardSteps = [
  AppQuestionsStep,
  InsuredInfoStep,
  InsuredQuestionsStep,
  SpouseInfoStep,
  SBIInfoStep,
  POAAuthRepStep,
  LandlordTenantStep,
  AuthToSignStep,
  ConditionsOfAcceptanceStep,
  CoverageDecisionStep,
  ApplicationDecisionStep,
] as const;