import TYield from '../types/api/adm/TYield';
import { Nullable } from '../types/util/Nullable';

export const getFilteredTYield = (tYields: TYield[], subCountyCodes: string[], typeId: string, practiceId: string, subCountyCode: Nullable<string>) => {
  const filteredByTypePracticeTYields = tYields.filter(ty => ty.typeId === typeId && ty.practiceId === practiceId);
  // First, try to find an item in the query that matches the passed-in subCountyCode
  let filteredBySubCountyTYields = filteredByTypePracticeTYields.find(ty => ty.subCountyCode === subCountyCode);
  // If none was found, use the first item in the query where the query code is null or the list of available codes doesn't contain the query code
  // For example, if the yield query returns results for AAA and null, and our available codes are '-' and 'AAA', we would prefer the null query result over the AAA for the default
  filteredBySubCountyTYields ??= filteredByTypePracticeTYields.find(ty => ty.subCountyCode === null || !subCountyCodes.includes(ty.subCountyCode));
  // If none was found again, just use the first item in the query
  filteredBySubCountyTYields ??= filteredByTypePracticeTYields.at(0);

  return filteredBySubCountyTYields;
};