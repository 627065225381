import { IconButton } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import DeleteIcon from '@mui/icons-material/Delete';
import { ICellRendererParams } from 'ag-grid-community';
import UnitYear from '../../../types/api/UnitYear';
import { UnitYearGridItem } from '../useUnitsModal';

export const ActionCellRenderer = (params: ICellRendererParams<UnitYearGridItem>, toggleUnitHistory: (unitYear: UnitYear) => void, deleteUnit: (unitYear: UnitYear) => void) => {
  return (
    <>
      {params.node.rowPinned === 'bottom' ? (
        <></>
      ) : (
        <>
          <IconButton color="primary" sx={{ p: .5 }} onClick={(() => params.data !== undefined && toggleUnitHistory(params.data))} >
            <CollectionsIcon />
          </IconButton>
          <IconButton sx={{ p: .5 }} onClick={() => params.data !== undefined && deleteUnit(params.data)}>
            <DeleteIcon />
          </IconButton>
        </>
      )}
    </>
  );
};
