import * as React from 'react';
import { createTheme, ThemeProvider, PaletteOptions as MuiPaletteOptions } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectPaletteMode } from '../../app/userSettingsSlice';
import { useMemo } from 'react';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    neutral: {
      light: string;
      main: string;
      dark: string;
    },
    other: {
      backdrop: string;
      grey: string;
      lightGrey: string;
      inputBackground: string;
      positive: string;
      negative: string;
    },
    matrix: {
      background: string;
      dataCellTextColor: string;
      border: string;
      axisCellTextColor: string;
    },
    premiumBreakdown: {
      labelColor: string;
    }
    scenarioPiece: {
      inactivePiece: string
    },
    altText: {
      main: string;
      disabled: string;
      grey: string;
      defaultCurrency: string;
    },
  }
  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
    value?: React.CSSProperties;
  }
  interface TypographyVariants {
    label: React.CSSProperties;
    value: React.CSSProperties;
  }
}

// This declaration is needed so that the actual "theme" consumed by application code has the correct typings.
declare module '@mui/material/styles' {
  interface Palette extends MuiPaletteOptions { }
  interface TypographyVariants {
    clickable1: React.CSSProperties;
    clickable2: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    clickable1?: React.CSSProperties;
    clickable2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface PalettePropsVariantOverrides {
    neutral: true;
    value: true;
  }
  interface TypographyPropsVariantOverrides {
    label: true;
    value: true;
    clickable1: true;
    clickable2: true;
  }
}

// ToDo: Implement Dark mode with a custom palette
export const getTheme = (mode: PaletteMode) => createTheme({
  palette: mode === 'light' ? {
    primary: {
      main: '#265e95',
      light: '#458bcf',
      dark: '#265e95',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#F8521E',
      dark: '#B93106',
      light: '#F97046',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#B00020',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF9800',
      contrastText: '#FFFFFF',
    },
    neutral: {
      light: '#ECEFF1',
      main: '#9E9C9C',
      dark: '#353535',
    },
    matrix: {
      background: '#F1F5F8',
      dataCellTextColor: '#666666',
      border: '#C4C4C4',
      axisCellTextColor: '#000000',
    },
    premiumBreakdown: {
      labelColor: '#666666',
    },
    scenarioPiece: {
      inactivePiece: '#C4C4C4',
    },
    other: {
      backdrop: '#FFF',
      grey: '#505050',
      lightGrey: '#F2F2F2',
      inputBackground: '#FFF',
      positive: 'green',
      negative: 'red',
    },
    background: {
      default: '#ECEFF1',
      paper: '#FFFFFF',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    altText: {
      main: '#FFFFFF',
      disabled: '#C4C4C4',
      grey: '#666666',
      defaultCurrency: '#333333',
    },
    action: {
      selected: 'rgba(0, 0, 0, 0.5)',
      disabled: 'rgba(0, 0, 0, 0.26)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
  } : {
    primary: {
      main: '#5CA0E0',
      light: '#E4F1FA',
      dark: '#5CA0E0',
      contrastText: '#000',
    },
    secondary: {
      main: '#FF8F60',
      dark: '#B93106',
      light: '#F97046',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#cf6679',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF9800',
      contrastText: '#FFFFFF',
    },
    neutral: {
      light: '#959595',
      main: '#858585',
      dark: '#353535',
    },
    matrix: {
      background: '#404040',
      dataCellTextColor: '#666666',
      border: '#F97046',
      axisCellTextColor: '#FFF',
    },
    premiumBreakdown: {
      labelColor: '#DDDDDD',
    },
    scenarioPiece: {
      inactivePiece: '#C4C4C4',
    },
    other: {
      backdrop: '#000',
      grey: '#CCCCCC',
      lightGrey: '#F2F2F2',
      inputBackground: '#454545',
      positive: '#87FFB3',
      negative: '#FCBDBD',
    },
    background: {
      default: '#121212',
      paper: 'rgb(39,47,53)',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    altText: {
      main: '#FFFFFF',
      disabled: '#C4C4C4',
      grey: '#DDDDDD',
      defaultCurrency: '#FFF',
    },
    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.16)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(255, 255, 255, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    divider: 'rgba(255, 255, 255, 0.12)',
  },
  typography: mode === 'light' ? {
    label: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    value: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '0.00938em',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
    },
    clickable1: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.57',
      letterSpacing: '0.00714em',
      textTransform: 'uppercase',
    },
    clickable2: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.57',
      letterSpacing: '0.00714em',
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1.66',
      letterSpacing: '0.03333em',
      display: 'block',
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '2.66',
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
      display: 'block',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.75',
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.25',
      letterSpacing: '0.00714em',
    },
    h1: {
      fontSize: '6rem',
      fontWeight: 300,
      lineHeight: '1.167',
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontSize: '3.75rem',
      fontWeight: 300,
      lineHeight: '1.2',
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: '1.167',
      letterSpacing: '0em',
    },
    h4: {
      fontSize: '2.125rem',
      lineHeight: '1.235',
      letterSpacing: '0.00735em',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '1.334',
      letterSpacing: '0em',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.6',
      letterSpacing: '0.0075em',
    },
  } : {
    label: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    value: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '0.875rem',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5',
      letterSpacing: '0.00938em',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
    },
    clickable1: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.57',
      letterSpacing: '0.00714em',
      textTransform: 'uppercase',
    },
    clickable2: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.57',
      letterSpacing: '0.00714em',
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1.66',
      letterSpacing: '0.03333em',
      display: 'block',
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '2.66',
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
      display: 'block',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.75',
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.57',
      letterSpacing: '0.00714em',
    },
    h1: {
      fontSize: '6rem',
      fontWeight: 300,
      lineHeight: '1.167',
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontSize: '3.75rem',
      fontWeight: 300,
      lineHeight: '1.2',
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: '1.167',
      letterSpacing: '0em',
    },
    h4: {
      fontSize: '2.125rem',
      lineHeight: '1.235',
      letterSpacing: '0.00735em',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '1.334',
      letterSpacing: '0em',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.6',
      letterSpacing: '0.0075em',
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: 'transparent',
          fontSize: '0.875rem',
          color: theme.palette.other.grey,
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected:after': {
            content: '\'\'',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: theme.palette.primary.main,
            height: '2px',
            borderRadius: '10px',
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '0.875rem',
          fontWeight: 400,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&.Mui-disabled': {
            backgroundColor: theme.palette.other.lightGrey,
            color: theme.palette.other.grey,
          },
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        enterDelay: 250,
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          border: '1px solid RGBA(0,0,0,0.12)',
          borderRadius: '4px',
        }),
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: mode === 'light' ? theme.palette.neutral.light : theme.palette.neutral.dark,
          color: 'white',
        }),
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.other.grey,
          '.MuiCardHeader-content': {
            width: '100%',
          },
        }),
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 3,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255,255,255,0.90)',
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.body2.fontSize,
          fontWeight: theme.typography.body2.fontWeight,
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
        inputProps: {
          autoComplete: 'off',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          style: { maxHeight: 250 },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '0.875rem',
          fontWeight: 500,
        }),
        containedPrimary: ({ theme }) => ({
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.dark,
        }),
      },
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            color: theme.palette.other.grey,
          },
        }),
      },
      defaultProps: {
        underline: 'none',
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
      defaultProps: {
        variantMapping: {
          label: 'div',
          value: 'div',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
            color: theme.palette.primary.main,
            fontWeight: 400,
            fontSize: '12px',
            '&.Mui-disabled': {
              color: theme.palette.grey[300],
            },
          },
          '&.active-step': {
            fontWeight: '600 !important',
            '.MuiStepLabel-label': {
              fontWeight: 600,
              fontSize: '14px',
              color: theme.palette.secondary.main,
              marginTop: '12px',
            },
          },
        }),
      },
    },
  },
});

interface ThemeProps {
  children?: React.ReactNode;
}

const Theme = ({ children }: ThemeProps) => {
  const paletteMode = useAppSelector(selectPaletteMode);
  const theme = useMemo(() => {
    return getTheme(paletteMode);
  }, [paletteMode]);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};


export default Theme;
