import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import { validateCoverageDesignatedCounty } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import { handleValidationResults } from './validationHelpers';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { AppTask } from '../../../types/api/AppTask';
import { AppTaskCoverage } from '../../../types/api/appTaskCoverage';
import { ProductType } from '../../../types/api/enums/application/productType.enum';

type CoverageDesignatedCountyInputProps = {
  value: Nullable<boolean>;
  index: number;
  coverageIndex: number;
  application: ApplicationWizard;
  canEditAppTask: boolean;
}

const CoverageDesignatedCountyInput = ({ value, index, coverageIndex, application, canEditAppTask }: CoverageDesignatedCountyInputProps) => {
  const inputName = `appTasks.${index}.coverages.${coverageIndex}.isDesignatedCounty`;
  const { control } = useFormContext();

  const allAppTasks: AppTask[] = useWatch({ name: 'appTasks' });
  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });
  const currentCoverage: AppTaskCoverage = useWatch({ name: `appTasks.${index}.coverages.${coverageIndex}` });

  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={value}
      rules={{ validate: _ => handleValidationResults(validateCoverageDesignatedCounty(application, currentAppTask, currentCoverage, allAppTasks)) }}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          select
          disabled={!application.isGeneratingForms || currentAppTask.productType !== ProductType.MPCI || !canEditAppTask}
          error={!!error}
          inputRef={ref}
          size="small"
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <MenuItem value={true as any}>Yes</MenuItem>
          { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <MenuItem value={false as any}>No</MenuItem>
        </TextField>
      )}
    />
  );
};

export default CoverageDesignatedCountyInput;