import { Nullable } from '../../types/util/Nullable';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { Quote } from '../../types/api/Quote';
import { useAppSelector } from '../reduxHooks';
import {
  selectAdjustedYieldForScenario,
  selectApprovedYieldForScenario
} from '../../app/unitsSlice';
import { createMatrixDefaults } from '../../pages/matrix/matrixDefaults';


export function useMatrixDefaults(firstScenario: Nullable<RowCropScenario>, quote: Nullable<Quote>) {
  const scenarioApprovedYield = useAppSelector(
    state => !firstScenario || !quote ? null :
      selectApprovedYieldForScenario(state, firstScenario.scenarioId, quote.countyId, quote.commodityCode),
  );
  const scenarioAdjustedYield = useAppSelector(
    state => !firstScenario || !quote ? null :
      selectAdjustedYieldForScenario(state, firstScenario.scenarioId, quote.countyId, quote.commodityCode))
    ?? 0;
  return createMatrixDefaults(firstScenario, quote, scenarioApprovedYield, scenarioAdjustedYield);
}
