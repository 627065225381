import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { ApplicationWizardId, ClientFileId, InsuredId, QuoteId, ScenarioId } from '../types/api/PrimaryKeys';
import { ApplicationWizard, ApplicationWizardScenarios, ApplicationWizardSummary, DefaultApplication } from '../types/api/applicationWizard/applicationWizard';
import { Nullable } from '../types/util/Nullable';
import { createAppAsyncThunk } from './thunkHelpers';
import { getKeyedStateGroupedBy, getKeyedStateValues } from './sliceHelpers';
import { fetchQuotes, selectAllQuotesByClientFileMap } from './quotesSlice';
import {
  fetchScenariosByClientFile,
  selectAllScenariosByQuoteIdMap,
  selectScenariosBelongingToInsuredApplications
} from './scenariosSlice';
import { fetchScenarioPiecesByClientFileId } from './scenarioPiecesSlice';
import { fetchScenarioOptionsForClientFile } from './optionsSlice';
import { createApplicationWizard, deleteApplicationWizard, fetchApplicationWizard, finalizeApplicationWizard, getAllApplicationWizardsForInsured, updateApplicationWizard, updateRelatedAppTaskLastFormRelatedChange } from '../services/applicationWizards.service';
import { fetchClientFile } from './clientFilesSlice';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { openToast } from './toastSlice';
import { AppTask } from '../types/api/AppTask';
import { SalesClosingDate } from '../types/api/applicationWizard/salesClosingDate';
import { getSalesClosingDates } from '../services/appTasks.service';
import { getItemsForId } from '../utils/mapHelpers';
import { stableEmptyArray } from '../utils/stableEmptyArray';
import { closeDrawer } from './appDrawerSlice';
import { ApplicationStatusType } from '../types/api/enums/application/applicationStatusType.enum';

interface ApplicationsState {
  allApplications: SliceDataState<ApplicationWizardId, ApplicationWizard>;
  currentlySelectedApplicationId: Nullable<ApplicationWizardId>;
  isApplicationModalOpen: boolean;
  salesClosingDates: SalesClosingDate[];
  currentlySelectedApplicationYear: Nullable<number>;
}

const initialState: ApplicationsState = {
  allApplications: initialSliceDataState(),
  isApplicationModalOpen: false,
  currentlySelectedApplicationId: null,
  salesClosingDates: [],
  currentlySelectedApplicationYear: null,
};

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState: initialState,
  reducers: {
    toggleApplicationsModal(state) {
      state.isApplicationModalOpen = !state.isApplicationModalOpen;
      if (state.isApplicationModalOpen === false) state.currentlySelectedApplicationId = null;
    },
    setCurrentlySelectedApplicationId(state, action: PayloadAction<Nullable<ApplicationWizardId>>) {
      state.currentlySelectedApplicationId = action.payload;
    },
    setCurrentlySelectedApplicationYear(state, action: PayloadAction<number>) {
      state.currentlySelectedApplicationYear = action.payload;
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allApplications, s => s.applicationWizardId);

    builder.addCase(fetchSalesClosingDates.fulfilled, (state, action: PayloadAction<SalesClosingDate[]>) => {
      state.salesClosingDates = action.payload;
    });

    // fetchApplication
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchApplication,
      affectedIds: () => [],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchAllApplicationWizardsForInsured,
      affectedIds: () => [],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: saveApplication,
      affectedIds: arg => arg.updatedApplication.applicationWizardId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addApplication,
      affectedIds: arg => arg.applicationWizard.applicationWizardId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeApplicationWizard,
      affectedIds: arg => arg.applicationWizard.applicationWizardId,
    });
  },
});

export const { toggleApplicationsModal, setCurrentlySelectedApplicationId, setCurrentlySelectedApplicationYear } = applicationsSlice.actions;

export default applicationsSlice.reducer;

export const selectSalesClosingDates = (state: RootState) => state.applications.salesClosingDates;

const selectApplicationWizardDictionary = (state: RootState) => state.applications.allApplications.data;

// Non-Memoized Selectors
export const selectApplicationsModalOpen = (state: RootState) => state.applications.isApplicationModalOpen;
export const currentlySelectedApplicationWizard = (state: RootState) => state.applications.currentlySelectedApplicationId === null ? null : state.applications.allApplications.data[state.applications.currentlySelectedApplicationId] ?? null;
export const selectApplicationWizardById = (state: RootState, applicationWizardId: ApplicationWizardId) => state.applications.allApplications.data[applicationWizardId];
export const selectCurrentlySelectedApplicationYear = (state: RootState) => state.applications.currentlySelectedApplicationYear;

// Memoized Selectors
export const selectCurrentlySelectedApplicationWizardScenarios = (state: RootState) => currentlySelectedApplicationWizard(state)?.applicationWizardScenarios ?? stableEmptyArray();
export const selectAllApplicationWizardByInsuredMap = createSelector([selectApplicationWizardDictionary], result => {
  return getKeyedStateGroupedBy(result, s => s.insuredId);
});

/**
 * Returns a set of client ids where one or more of the scenarios included in a quote in the client file is tied to an app wizard app task.
 * Client files that are tied to app tasks will be readonly throughout the UI meaning they can't be deleted.
 */
export const selectClientFileIdsAssociatedWithAppTasks = createSelector([selectApplicationWizardDictionary], allApplicationWizards => {
  const clientFileIdsWithAppTasks = new Set<ClientFileId>();
  const appWizards = getKeyedStateValues(allApplicationWizards);
  for (const aw of appWizards) {
    const appTasks = aw.applicationWizardAppTasks;
    if (appTasks.length > 0 && !clientFileIdsWithAppTasks.has(aw.clientFileId)) {
      clientFileIdsWithAppTasks.add(aw.clientFileId);
    }
  }
  return clientFileIdsWithAppTasks;
});

/**
 * Returns a set of quote ids that are tied to app wizard app tasks because one or more of their scenarios are tied to app tasks.
 * Quotes that are tied to app tasks will be readonly throughout the UI meaning they can't be deleted.
 */
export const selectQuoteIdsAssociatedWithAppTasks = createSelector([selectApplicationWizardDictionary], allApplicationWizards => {
  const quoteIdsWithAppTasks = new Set<QuoteId>();
  const appWizards = getKeyedStateValues(allApplicationWizards);
  const appTasks = appWizards.flatMap(x => x.applicationWizardAppTasks);
  for (const appTask of appTasks) {
    if (!quoteIdsWithAppTasks.has(appTask.quoteId)) {
      quoteIdsWithAppTasks.add(appTask.quoteId);
    }
  }
  return quoteIdsWithAppTasks;
});

/**
 * Returns a set of scenario ids that contain one or more app wizard app tasks. Scenarios that are tied to app tasks will be marked
 * as readonly throughout the UI meaning they can't be edited or deleted.
 */
export const selectScenarioIdsAssociatedWithAppTasks = createSelector([selectApplicationWizardDictionary], allApplicationWizards => {
  const scenarioIdsWithAppTasks = new Set<ScenarioId>();
  const appWizards = getKeyedStateValues(allApplicationWizards);
  for (const aw of appWizards) {
    const appTasks = aw.applicationWizardAppTasks;
    for (const appTask of appTasks) {
      if (!scenarioIdsWithAppTasks.has(appTask.scenarioId)) {
        scenarioIdsWithAppTasks.add(appTask.scenarioId);
      }
    }
  }
  return scenarioIdsWithAppTasks;
});

/**
 * Saves the application to the the API and updates the store
 */
export const saveApplication = createAppAsyncThunk('applications/saveApplication', async ({ updatedApplication }: { updatedApplication: ApplicationWizard }) => {
  await updateApplicationWizard(updatedApplication);
  if (updatedApplication.applicationWizardStatusType === ApplicationStatusType.AppTasksCreated)
  {
    updateRelatedAppTaskLastFormRelatedChange(updatedApplication.applicationWizardId);
  }

  return updatedApplication;
});

export const fetchSalesClosingDates = createAppAsyncThunk('applications/fetchSalesClosingDates', async ({ clientFileId }: { clientFileId: ClientFileId }, thunkApi) => {
  const state = thunkApi.getState();
  const clientFile = state.clientFiles.allClientFiles.data[clientFileId];
  if (clientFile !== undefined) {
    return (await getSalesClosingDates(clientFile.year)).data;
  }

  return [];
});

export const fetchApplication = createAppAsyncThunk('applications/fetchApplication', async ({ appSummary }: { appSummary: ApplicationWizardSummary }, thunkApi) => {
  const application = (await fetchApplicationWizard(appSummary.applicationWizardId)).data;
  await thunkApi.dispatch(fetchAllRequiredDataForApplication({ clientFileId: application.clientFileId }));
  thunkApi.dispatch(setCurrentlySelectedApplicationId(application.applicationWizardId));
  thunkApi.dispatch(toggleApplicationsModal());

  return application;
});

export const fetchAllApplicationWizardsForInsured = createAppAsyncThunk('applications/fetchApplications', async ({ insuredId }: { insuredId: InsuredId }, thunkApi) => {
  const applications = (await getAllApplicationWizardsForInsured(insuredId)).data;
  return applications;
});

/**
 * Creates an application but does not save it to the database
 */
export const createApplication = createAppAsyncThunk('applications/createApplication', async ({ insuredId, clientFileId, applicationWizardId }: { insuredId: InsuredId, clientFileId: ClientFileId, applicationWizardId: ApplicationWizardId }, thunkApi) => {
  const state = thunkApi.getState();
  // On initial create we need to find a list of any scenarios in the client file that are marked as finalized and don't already belong
  // to an existing application wizard and auto add those to this list
  const insuredApplicationScenarios = selectScenariosBelongingToInsuredApplications(state);
  const quotes = selectAllQuotesByClientFileMap(state).get(clientFileId) ?? [];

  // group the scenarios by quote id
  const allScenariosByQuoteId = selectAllScenariosByQuoteIdMap(state);
  const finalizedScenarios: ApplicationWizardScenarios[] = [];
  for (const quote of quotes) {
    const scenariosForQuote = getItemsForId(allScenariosByQuoteId, quote.quoteId);
    // filter out scenarios that belong to any OTHER application wizard since we are in the process of creating a new one
    const filteredScenariosForQuote = scenariosForQuote
      .filter(x => x.isFinalized && !insuredApplicationScenarios.some(s => s.scenarioId === x.scenarioId));
    for (const s of filteredScenariosForQuote) {
      finalizedScenarios.push({
        applicationWizardScenarioId: generatePrimaryKey(),
        applicationWizardId: applicationWizardId,
        scenarioId: s.scenarioId,
      });
    }
  }

  const newAppWizard: ApplicationWizard = {
    ...DefaultApplication,
    insuredId: insuredId, // might be able to remove insured id from the dto
    newInsuredId: null,
    applicationWizardId: applicationWizardId,
    isNewApplication: true,
    clientFileId: clientFileId,
    applicationWizardScenarios: finalizedScenarios,
  };

  return newAppWizard;
});

export const addApplication = createAppAsyncThunk('applications/addApplication', async ({ applicationWizard }: { applicationWizard: ApplicationWizard }) => {
  await createApplicationWizard(applicationWizard);
  const addApplicationResult: ApplicationWizard = { ...applicationWizard, isNewApplication: false };
  return addApplicationResult;
});

/**
 * This thunk exists as a wrapper to all things scenario related required for the application modal to function.
 */
export const fetchAllRequiredDataForApplication = createAppAsyncThunk('applications/fetchAllScenarioDataForApplication', async ({ clientFileId }: { clientFileId: ClientFileId }, thunkApi) => {
  const promises = [
    thunkApi.dispatch(fetchClientFile({ clientFileId })),
    thunkApi.dispatch(fetchQuotes({ clientFileId })),
    thunkApi.dispatch(fetchScenariosByClientFile({ clientFileId })),
    thunkApi.dispatch(fetchScenarioPiecesByClientFileId({ clientFileId })),
    thunkApi.dispatch(fetchScenarioOptionsForClientFile({ clientFileId })),
  ];

  await Promise.all(promises);
});

export const removeApplicationWizard = createAppAsyncThunk('applications/removeApplicationWizard', async ({ applicationWizard }: { applicationWizard: ApplicationWizard }, thunkApi) => {
  await deleteApplicationWizard(applicationWizard.applicationWizardId);
  return applicationWizard;
});

export const submitApplication = createAppAsyncThunk('aips/finalizeApplicationWizard', async ({ appWizardId, appTasks }: { appWizardId: ApplicationWizardId, appTasks: AppTask[] }, thunkApi) => {
  await finalizeApplicationWizard(appWizardId, appTasks);

  const applicationState = thunkApi.getState().applications;
  const appWizard = applicationState.allApplications.data[appWizardId];
  if (appWizard?.insuredId) {
    thunkApi.dispatch(fetchAllApplicationWizardsForInsured({ insuredId: appWizard.insuredId }));
  }
  thunkApi.dispatch(closeDrawer());
  thunkApi.dispatch(toggleApplicationsModal());

  thunkApi.dispatch(openToast({ type: 'success', message: 'Successfully created App Tasks', shouldTimeout: true, allowClickToClose: false }));
});
