import { OptionCode, ScenarioPieceResponseDTO, UnitStructureAttributes, UnitStructureCode } from '@silveus/calculations';
import { PlanBaseType, QuoteSummaryScenarioPiece } from '../../../../../types/api/reports/QuoteSummaryScenarioPiece';
import { RowCropScenarioPiece } from '../../../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../../../types/util/Nullable';
import { RowCropScenario } from '../../../../../types/api/RowCropScenario';
import { getBaseReportScenarioPiece } from '../../getBaseReportScenarioPiece';

export const getIceStackShieldQuoteSummaryData = (
  scenario: RowCropScenario,
  scenarioPiece: RowCropScenarioPiece,
  scenarioPieceCalcData: Nullable<ScenarioPieceResponseDTO>,
  scenarioOptions: OptionCode[],
  projectedPrice: number,
  isProjectedPriceSet: boolean,
  approvedYield: Nullable<number>,
  adjustedYield: Nullable<number>): QuoteSummaryScenarioPiece[] => {

  const baseReportScenarioPiece = getBaseReportScenarioPiece(scenario, scenarioPiece, scenarioPieceCalcData, scenario.expectedCountyYield, approvedYield, adjustedYield);
  const quoteSummaryScenarioPiece: QuoteSummaryScenarioPiece = {
    ...baseReportScenarioPiece,
    hailEndorsements: [],
    hailRate: null,
    isProjectedPriceSet: isProjectedPriceSet,
    options: [],
    planBaseType: PlanBaseType.Basic,
    isTAYieldVisible: false,
    taYield: null,
    yield: null,
    projectedPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.topPrice ?? null,
    protectionFactor: scenarioPiece.protectionFactor / 100,
    unitStructure: scenarioPiece.unitStructure === UnitStructureCode.AU ? '' : UnitStructureAttributes[scenarioPiece.unitStructure].name,
    lowerCoverageLevel: `${scenarioPiece.lowerCoverageLevel}%`,
    calculationData: { ...baseReportScenarioPiece.calculationData, triggerYield: null },
  };

  return [quoteSummaryScenarioPiece];
};