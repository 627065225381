import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, SxProps, TextField, Theme, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { Nullable } from '../../types/util/Nullable';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { isNilOrEmpty, isUndefined } from '../../utils/nullHandling';
import {
  fetchScenarioPricesAndYields,
  fetchPracticesWithOffers,
  selectPracticesWithOffers
} from '../../app/admSlice';
import { validatePractice } from '../../pages/units/validations/practiceValidation';
import { selectRowCropScenarioPiecePlanCodes } from '../../app/scenarioPiecesSlice';


interface PracticeProps {
  scenarioId: Nullable<string>;
  practiceId: Nullable<string>;
  year: number;
  commodityCode: Nullable<string>;
  countyId: Nullable<string>;
  typeId: Nullable<string>;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

export type PracticeFields = {
  practiceId: string;
};

const PracticeInput = ({ scenarioId, practiceId, year, commodityCode, countyId, typeId, sx, disabled = false }: PracticeProps) => {
  const dispatch = useAppDispatch();
  const { control, setValue, getValues } = useFormContext();
  const practices = useAppSelector(selectPracticesWithOffers);
  const type = useWatch({ name: 'typeId', control: control, defaultValue: undefined });

  const scenarioPlanCodes = useAppSelector(state => selectRowCropScenarioPiecePlanCodes(state, scenarioId));

  useEffect(() => {
    if (!isUndefined(type))
      setValue('practiceId', '');

    if (isNilOrEmpty(type) && isNilOrEmpty(typeId)) return;

    const commodity = getValues('commodityCode');
    const county = getValues('countyId');

    dispatch(fetchPracticesWithOffers({ year, commodityCode: commodity ?? commodityCode, countyId: county ?? countyId, typeId: type ?? typeId }));
  }, [type]);

  return (
    <Controller
      name="practiceId"
      control={control}
      rules={{ validate: validatePractice }}
      defaultValue={practiceId}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <Tooltip title={practices.find(x => x.practiceId === value)?.name ?? ''} enterDelay={500}>
          <TextField
            {...field}
            type="text"
            label="Practice"
            fullWidth
            select
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            sx={sx}
            disabled={disabled}
            value={value}
            onChange={e => {
              if (!isUndefined(e.target.value)) {
                const county = getValues('countyId');
                dispatch(fetchScenarioPricesAndYields({ year: year, countyId: county ?? countyId, typeId: getValues('typeId'), practiceId: e.target.value, planCodes: scenarioPlanCodes }));
              }
              field.onChange(e.target.value);
            }}
            size="small"
          >
            {practices.map(p => <MenuItem key={p.practiceId} value={p.practiceId}>{p.name}</MenuItem>)}
          </TextField>
        </Tooltip>
      )}
    />
  );
};

export default PracticeInput;
