import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { SliceDataState, initialSliceDataState } from './sliceStateHelpers';
import { HailScenarioPieceCompositionId, HailScenarioPieceEndorsementId, HailScenarioPieceId, HailScenarioPieceRateId, ScenarioId, ScenarioPieceId } from '../types/api/PrimaryKeys';
import HailScenarioPiece from '../types/api/HailScenarioPiece';
import { RootState } from './store';
import { getKeyedStateGroupedBy, removeFromKeyedState, updateKeyedState } from './sliceHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import {
  addHailScenarioPieceCompositionRequest,
  deleteHailScenarioPieceCompositionRequest,
  getHailScenarioPieceCompositionsForScenariosRequest,
  updateHailScenarioPieceCompositionRequest
} from '../services/requestInterception/scenarioPieces/hailScenarioPieceRequestInterceptor';
import HailScenarioPieceComposition from '../types/api/HailScenarioPieceComposition';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { updateCalculationForScenario } from './calculationResultsSlice';
import { updateUnitGroupsForScenarioPiece } from './unitGroupsSlice';
import { getItemsForId } from '../utils/mapHelpers';
import { HailScenarioPieceCompositionDTO } from '../types/api/hail/hailScenarioPieceCompositionDTO';
import HailScenarioPieceRate from '../types/api/hail/HailScenarioPieceRate';
import HailScenarioPieceEndorsement from '../types/api/hail/HailScenarioPieceEndorsement';
import { getHailCompositionDTO, getHailCompositionDTOSplitOut, getHailScenarioPieceCompositionFromDTO } from '../utils/hailMapperUtils';

export interface HailState {
  hailScenarioPieceCompositions: SliceDataState<HailScenarioPieceCompositionId, HailScenarioPieceComposition>;
  hailScenarioPieces: SliceDataState<ScenarioPieceId, HailScenarioPiece>;
  hailScenarioPieceRates: SliceDataState<HailScenarioPieceRateId, HailScenarioPieceRate>;
  hailScenarioPieceEndorsements: SliceDataState<HailScenarioPieceEndorsementId, HailScenarioPieceEndorsement>;
}

const initialState: HailState = {
  hailScenarioPieceCompositions: initialSliceDataState(),
  hailScenarioPieces: initialSliceDataState(),
  hailScenarioPieceRates: initialSliceDataState(),
  hailScenarioPieceEndorsements: initialSliceDataState(),
};

export const hailSlice = createSlice({
  name: 'hail',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addHailScenarioPieceComposition.fulfilled, (state, action: PayloadAction<HailScenarioPieceCompositionDTO>) => {
        const compositionDTO = action.payload;
        const hailObjects = getHailCompositionDTOSplitOut(compositionDTO);
        const hailComposition = getHailScenarioPieceCompositionFromDTO(compositionDTO);
        updateKeyedState(state.hailScenarioPieceCompositions.data, hailComposition, hc => hc.hailScenarioPieceCompositionId);
        updateKeyedState(state.hailScenarioPieces.data, hailObjects.hailScenarioPieces, sp => sp.scenarioPieceId);
        updateKeyedState(state.hailScenarioPieceRates.data, hailObjects.hailScenarioPieceRates, sp => sp.hailScenarioPieceRateId);
        updateKeyedState(state.hailScenarioPieceEndorsements.data, hailObjects.hailScenarioPieceEndorsements, sp => sp.hailScenarioPieceEndorsementId);
      })
      .addCase(fetchHailScenarioPieceCompositionsByScenarioIds.fulfilled, (state, action: PayloadAction<HailScenarioPieceCompositionDTO[]>) => {
        const compositionDTOs = action.payload;
        for (const compositionDTO of compositionDTOs) {
          const hailObjects = getHailCompositionDTOSplitOut(compositionDTO);
          const hailComposition = getHailScenarioPieceCompositionFromDTO(compositionDTO);
          updateKeyedState(state.hailScenarioPieceCompositions.data, hailComposition, hc => hc.hailScenarioPieceCompositionId);
          updateKeyedState(state.hailScenarioPieces.data, hailObjects.hailScenarioPieces, sp => sp.scenarioPieceId);
          updateKeyedState(state.hailScenarioPieceRates.data, hailObjects.hailScenarioPieceRates, sp => sp.hailScenarioPieceRateId);
          updateKeyedState(state.hailScenarioPieceEndorsements.data, hailObjects.hailScenarioPieceEndorsements, sp => sp.hailScenarioPieceEndorsementId);
        }
      })
      .addCase(modifyHailScenarioPieceComposition.fulfilled, (state, action: PayloadAction<HailScenarioPieceComposition>) => {
        const hailComposition = action.payload;
        updateKeyedState(state.hailScenarioPieceCompositions.data, hailComposition, hc => hc.hailScenarioPieceCompositionId);
        const hailScenarioPieces = getItemsForId(getKeyedStateGroupedBy(state.hailScenarioPieces.data, sp => sp.hailScenarioPieceCompositionId), hailComposition.hailScenarioPieceCompositionId);
        const updatedHailScenarioPieces = hailScenarioPieces.map(hailScenarioPiece => {
          const updatedHailScenarioPiece: HailScenarioPiece = { ...hailScenarioPiece, isActive: hailComposition.isActive };
          return updatedHailScenarioPiece;
        });
        updateKeyedState(state.hailScenarioPieces.data, updatedHailScenarioPieces, sp => sp.scenarioPieceId);
      })
      .addCase(removeHailScenarioPieceComposition.fulfilled, (state, action: PayloadAction<HailScenarioPieceCompositionId>) => {
        const hailCompositionId = action.payload;
        const hailScenarioPieces = getItemsForId(getKeyedStateGroupedBy(state.hailScenarioPieces.data, sp => sp.hailScenarioPieceCompositionId), hailCompositionId);
        for (const hailScenarioPiece of hailScenarioPieces) {
          const hailScenarioPieceRates = getItemsForId(getKeyedStateGroupedBy(state.hailScenarioPieceRates.data, sp => sp.hailScenarioPieceId), hailScenarioPiece.hailScenarioPieceId);
          removeFromKeyedState(state.hailScenarioPieceRates.data, hailScenarioPieceRates.map(r => r.hailScenarioPieceRateId));

          const hailScenarioPieceEndorsements = getItemsForId(getKeyedStateGroupedBy(state.hailScenarioPieceEndorsements.data, sp => sp.hailScenarioPieceId), hailScenarioPiece.hailScenarioPieceId);
          removeFromKeyedState(state.hailScenarioPieceEndorsements.data, hailScenarioPieceEndorsements.map(e => e.hailScenarioPieceEndorsementId));
        }

        removeFromKeyedState(state.hailScenarioPieces.data, hailScenarioPieces.map(sp => sp.scenarioPieceId));
        removeFromKeyedState(state.hailScenarioPieceCompositions.data, hailCompositionId);
      });
  },
});

// Non-Memoized Selectors
const selectHailScenarioPiecesDictionary = (state: RootState) => state.hail.hailScenarioPieces.data;
const selectHailScenarioPieceCompositionsDictionary = (state: RootState) => state.hail.hailScenarioPieceCompositions.data;

// Memoized Selectors
export const selectAllHailScenarioPiecesByScenarioMap = createSelector([selectHailScenarioPiecesDictionary], result => {
  return getKeyedStateGroupedBy(result, sp => sp.scenarioId);
});

export const selectAllHailScenarioPieceCompositionsByScenarioMap = createSelector([selectHailScenarioPieceCompositionsDictionary], result => {
  return getKeyedStateGroupedBy(result, sp => sp.scenarioId);
});

export const selectAllHailScenarioPiecesByCompositionMap = createSelector([selectHailScenarioPiecesDictionary], result => {
  return getKeyedStateGroupedBy(result, sp => sp.hailScenarioPieceCompositionId);
});

const selectHailScenarioPieceRates = (state: RootState) => state.hail.hailScenarioPieceRates.data;
export const selectHailScenarioPieceRatesByHailScenarioPieceMap = createSelector([selectHailScenarioPieceRates], result => {
  const map = getKeyedStateGroupedBy(result, sp => sp.hailScenarioPieceId);
  return map;
});

const selectHailScenarioPieceEndorsements = (state: RootState) => state.hail.hailScenarioPieceEndorsements.data;
export const selectHailScenarioPieceEndorsementsByHailScenarioPieceMap = createSelector([selectHailScenarioPieceEndorsements], result => {
  const map = getKeyedStateGroupedBy(result, sp => sp.hailScenarioPieceId);
  return map;
});

// Add
export const addHailScenarioPieceComposition = createAppAsyncThunk('hailScenarioPieceCompositions/addHailScenarioPieceComposition',
  async ({ hailScenarioPieceComposition, hailScenarioPieces, hailScenarioPieceRates, hailScenarioPieceEndorsements }: {
    hailScenarioPieceComposition: HailScenarioPieceComposition, hailScenarioPieces: HailScenarioPiece[],
    hailScenarioPieceRates: HailScenarioPieceRate[],
    hailScenarioPieceEndorsements: HailScenarioPieceEndorsement[]
  }, thunkApi) => {
    //Remove old composition
    const state = thunkApi.getState();
    const currentHailScenarioPieceComposition = getItemsForId(selectAllHailScenarioPieceCompositionsByScenarioMap(state), hailScenarioPieceComposition.scenarioId);
    if (currentHailScenarioPieceComposition.length > 0) {
      await thunkApi.dispatch(removeHailScenarioPieceComposition({ scenarioId: hailScenarioPieceComposition.scenarioId }));
    }
    const hailScenarioPieceCompositionDTO = getHailCompositionDTO(hailScenarioPieceComposition, hailScenarioPieces, hailScenarioPieceRates, hailScenarioPieceEndorsements);
    await addHailScenarioPieceCompositionRequest(hailScenarioPieceCompositionDTO);
    return hailScenarioPieceCompositionDTO;
  },
);

// Fetch
export const fetchHailScenarioPieceCompositionsByScenarioIds = createAppAsyncThunk('hailScenarioPieceCompositions/fetchHailScenarioPieceCompositionsByScenarioIds', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  return await getHailScenarioPieceCompositionsForScenariosRequest(scenarioIds);
});

// Modify
export const modifyHailScenarioPieceComposition = createAppAsyncThunk(
  'hailScenarioPieceCompositions/modifyHailScenarioPieceComposition', async ({ scenarioPieceComposition }: { scenarioPieceComposition: HailScenarioPieceComposition }) => {
    await updateHailScenarioPieceCompositionRequest(scenarioPieceComposition);
    return scenarioPieceComposition;
  },
);

// Duplicate
export const duplicateHailScenarioPieceComposition = createAppAsyncThunk('hailScenarioPieceCompositions/duplicateScenarioPieceComposition', async ({ hailScenarioPieceComposition, scenarioId }: { hailScenarioPieceComposition: HailScenarioPieceComposition, scenarioId: ScenarioId }, thunkApi) => {
  const state = thunkApi.getState();
  const hailScenarioPieces = getItemsForId(selectAllHailScenarioPiecesByCompositionMap(state), hailScenarioPieceComposition.hailScenarioPieceCompositionId);

  const newScenarioPieceComposition: HailScenarioPieceComposition = {
    ...hailScenarioPieceComposition,
    scenarioId: scenarioId,
    hailScenarioPieceCompositionId: generatePrimaryKey(),
  };

  const newHailScenarioPieces: HailScenarioPiece[] = [];
  const newHailScenarioPieceRates: HailScenarioPieceRate[] = [];
  const newHailScenarioPieceEndorsements: HailScenarioPieceEndorsement[] = [];
  for (const hailScenarioPiece of hailScenarioPieces) {
    const newHailScenarioPieceId = generatePrimaryKey<HailScenarioPieceId>();
    newHailScenarioPieces.push({
      ...hailScenarioPiece,
      hailScenarioPieceId: newHailScenarioPieceId,
      scenarioPieceId: generatePrimaryKey(),
      hailScenarioPieceCompositionId: newScenarioPieceComposition.hailScenarioPieceCompositionId,
    });

    const currentHailScenarioPieceRatesForPiece = getItemsForId(selectHailScenarioPieceRatesByHailScenarioPieceMap(state), hailScenarioPiece.hailScenarioPieceId);
    const newRatesForNewPiece = currentHailScenarioPieceRatesForPiece.map(rate => {
      const newHailScenarioPieceRate: HailScenarioPieceRate = {
        ...rate,
        hailScenarioPieceRateId: generatePrimaryKey(),
        hailScenarioPieceId: newHailScenarioPieceId,
      };
      return newHailScenarioPieceRate;
    });

    const currentHailScenarioPieceEndorsementsForPiece = getItemsForId(selectHailScenarioPieceEndorsementsByHailScenarioPieceMap(state), hailScenarioPiece.hailScenarioPieceId);
    const newEndorsementsForNewPiece = currentHailScenarioPieceEndorsementsForPiece.map(endorsement => {
      const newHailScenarioPieceEndorsement: HailScenarioPieceEndorsement = {
        ...endorsement,
        hailScenarioPieceEndorsementId: generatePrimaryKey(),
        hailScenarioPieceId: newHailScenarioPieceId,
      };
      return newHailScenarioPieceEndorsement;
    });

    newHailScenarioPieceRates.push(...newRatesForNewPiece);
    newHailScenarioPieceEndorsements.push(...newEndorsementsForNewPiece);
  }

  await thunkApi.dispatch(addHailScenarioPieceComposition({
    hailScenarioPieceComposition: newScenarioPieceComposition,
    hailScenarioPieces: newHailScenarioPieces,
    hailScenarioPieceRates: newHailScenarioPieceRates,
    hailScenarioPieceEndorsements: newHailScenarioPieceEndorsements,
  }));
  const unitGroupPromises = newHailScenarioPieces.map(hailScenarioPiece => {
    return thunkApi.dispatch(updateUnitGroupsForScenarioPiece({ scenarioPieceId: hailScenarioPiece.scenarioPieceId }));
  });
  await Promise.all(unitGroupPromises);
});

// Remove
const removeHailScenarioPieceComposition = createAppAsyncThunk(
  'scenarios/removeHailScenarioPieceComposition',
  async ({ scenarioId }: { scenarioId: ScenarioId }) => {
    return await deleteHailScenarioPieceCompositionRequest(scenarioId);
  },
);

export const removeHailScenarioPieceCompositionAndRecalculate = createAppAsyncThunk(
  'hailScenarioPieceCompositions/removeHailScenarioPieceCompositionAndRecalculate', async ({ hailScenarioPieceComposition }: { hailScenarioPieceComposition: HailScenarioPieceComposition }, thunkApi) => {
    await thunkApi.dispatch(removeHailScenarioPieceComposition({ scenarioId: hailScenarioPieceComposition.scenarioId }));
    await thunkApi.dispatch(updateCalculationForScenario({ scenarioId: hailScenarioPieceComposition.scenarioId }));
  },
);

export default hailSlice.reducer;