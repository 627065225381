import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { useEffect } from 'react';
import { isNullOrUndefined, isUndefined } from '../../../utils/nullHandling';
import NumberTextField, { onNumericInputChange } from '../../numberTextField/numberTextField.component';
import { SxProps, Theme } from '@mui/material';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferProducerYield } from '../../../app/admSlice';
import { isApiDataStale, isSavedDataBeingLoaded } from '../../../utils/formInputUtils';
import { cup, roundToPlaces, getValueWithPercentChangeApplied } from '@silveus/calculations';
import { Info } from '../../info/info';
import { validateYield } from '../../../pages/units/validations/yieldValidation';

interface ProducerYieldProps {
  producerYield: Nullable<number>;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
  approvedYield: Nullable<number>;
}

export type ProducerYieldFields = {
  producerYield: number;
}

const ProducerYieldInput = ({ producerYield, sx, approvedYield, autoFocus = false }: ProducerYieldProps) => {
  const { control, setValue, getValues, trigger } = useFormContext();
  const approvedYieldPercentChange = useWatch({ name: 'approvedYieldSlider', control: control, defaultValue: undefined });
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });
  const prodYield = useAppSelector(selectOfferProducerYield);

  useEffect(() => {
    //When the API data is changed, load it into the field,
    // except for the initial load when there is saved data
    const currentPractice = getValues('practiceId');

    const isThereStaleData = isApiDataStale(currentPractice, prodYield);
    const isLoadingSavedData = isSavedDataBeingLoaded(producerYield, practice);

    if (isThereStaleData || isLoadingSavedData || isNullOrUndefined(prodYield)) return;

    setValue('producerYield', prodYield);
    trigger('producerYield');
  }, [prodYield]);

  useEffect(() => {
    //When the practice is changed, clear out the current value
    if (isUndefined(practice)) return;

    setValue('producerYield', 0);
  }, [practice]);

  useEffect(() => {
    if (isNullOrUndefined(approvedYieldPercentChange)) return;

    const prodYield = cup(roundToPlaces(getValueWithPercentChangeApplied(approvedYield ?? getValues().approvedYield ?? 0, approvedYieldPercentChange), 2), 0);
    setValue('producerYield', prodYield);
  }, [approvedYieldPercentChange]);

  return (
    <Controller
      name="producerYield"
      control={control}
      rules={{ validate: value => validateYield(value, true) }}
      defaultValue={producerYield ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          type="number"
          label="Producer Yield"
          fullWidth
          inputProps={{ step: 0.01, min: 0 }}
          InputProps={{
            endAdornment: <Info tooltipContent="Producer Yield" />,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          sx={sx}
          size="small"
        />
      )}
    />
  );
};

export default ProducerYieldInput;
