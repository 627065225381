import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOADING_STATES } from '../constants/loadingStates';
import { valueof } from '../types/util/valueof';
import { RootState } from './store';
import { State } from '../types/api/adm/State';
import { County } from '../types/api/adm/County';
import { Commodity } from '../types/api/adm/Commodity';
import { Nullable } from '../types/util/Nullable';
import { Practice } from '../types/api/adm/Practice';
import { CropType } from '../types/api/adm/CropType';
import { InsuranceOfferParams } from '../types/api/adm/InsuranceOfferParams';
import AvailableInsuranceOfferSelections from '../types/api/adm/AvailableInsuranceOfferSelections';
import { stableEmptyArray, stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import { distinctBy, orderByProperty } from '../utils/arrayUtils';
import { Option } from '../types/api/adm/Option';
import TrendAdjustmentFactor from '../types/api/adm/TrendAdjustmentFactor';
import TYield from '../types/api/adm/TYield';
import {
  Commodity as CommodityCode,
  CoverageTypeCode,
  cup,
  HighRiskType,
  HistoricalTYield,
  roundToPlaces,
  ScenarioPieceType
} from '@silveus/calculations';
import { createAppAsyncThunk } from './thunkHelpers';
import { YeYear } from '../types/api/adm/YeYears';
import HistoricalPrice from '../types/api/adm/HistoricalPrice';
import PriceGroupMember from '../types/api/adm/PriceGroupMember';
import HistoricalYieldTrend from '../types/api/adm/HistoricalYieldTrend';
import HistoricalYieldTrendYear from '../types/api/adm/HistoricalYieldTrendYear';
import { ADMYear } from '../types/api/adm/ADMYear';
import { getCountiesForStateRequest } from '../services/requestInterception/countyRequestInterceptor';
import {
  getAvailableHistoricalTYieldsRequest,
  getAvailableInsuranceOfferSelectionsRequest,
  getAvailableInsurancePlanCodesRequest,
  getAvailableOptionSelectionsRequest,
  getAvailableSubCountyCodesRequest,
  getAvailableYeYearsRequest,
  getCeppMappingsRequest,
  getCountyYieldInfoRequest,
  getHipHurricaneEventRequest,
  getHistoricalInputCostsRequest,
  getHistoricalPricesRequest,
  getHistoricalStormEventsRequest,
  getHistoricalYieldTrendsRequest,
  getHistoricalYieldTrendYearsRequest,
  getInsuranceCalendarsRequest,
  getMyaPriceHistoriesRequest,
  getPreDownloadedCountiesRequest,
  getPriceDiscoveriesRequest,
  getPriceGroupMembersRequest,
  getPriceGroupsRequest,
  getTrendAdjustmentFactorsRequest,
  getTYieldsRequest,
  getYearsRequest
} from '../services/requestInterception/admRequestInterceptor';
import { getCommoditiesForCountyRequest } from '../services/requestInterception/commodityRequestInterceptor';
import { getCropTypesForCommodityRequest } from '../services/requestInterception/cropTypeRequestInterceptor';
import { getPracticesForCommodityRequest } from '../services/requestInterception/practiceRequestInterceptor';
import { getInsurancePlanCodeForScenarioPiece } from '../utils/scenarioPieceUtils';
import { isEqual } from 'lodash';
import { CoverageLevelPair } from '../types/api/adm/CoverageLevelPair';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import ScenarioPricesAndYields from '../types/api/adm/ScenarioPricesAndYields';
import HistoricalStormEvent from '../types/api/adm/HistoricalStormEvent';
import AdmDataForQuoteParams from '../types/api/adm/AdmDataForQuoteParams';
import { initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import {
  getKeyedStateToMap,
  getKeyedStateValues,
  initialKeyedState,
  KeyedState,
  updateKeyedState,
  updateKeyedStateVerbatim
} from './sliceHelpers';
import QuoteHistoricalTYields from '../types/api/adm/QuoteHistoricalTYields';
import QuoteTrendAdjustmentFactors from '../types/api/adm/QuoteTrendAdjustmentFactors';
import { getItemsForId } from '../utils/mapHelpers';
import QuoteYeYears from '../types/api/adm/QuoteYeYears';
import { buildCountyAndCommodityKey, CountyCommodityKey } from '../types/app/CompositeKeys';
import QuotePriceGroupMembers from '../types/api/adm/QuotePriceGroupMembers';
import QuoteHistoricalYieldTrends from '../types/api/adm/QuoteHistoricalYieldTrends';
import QuoteHistoricalStormEvents from '../types/api/adm/QuoteHistoricalStormEvents';
import InsuranceCalendar from '../types/api/adm/InsuranceCalendar';
import QuoteInsuranceCalendars from '../types/api/adm/QuoteInsuranceCalendars';
import PriceGroup from '../types/api/adm/PriceGroup';
import { PriceGroupId, ScenarioId } from '../types/api/PrimaryKeys';
import { format } from 'date-fns';
import RMAPriceDiscovery from '../types/api/adm/RMAPriceDiscovery';
import {
  CeppMappings,
  CeppPractice,
  CeppPracticeMapping,
  CeppType,
  CeppTypeMapping
} from '../types/api/adm/CeppMappings';
import { getInsurancePlanCodeVariationForPrice } from '../utils/priceYieldVariationUtils';
import { selectOfferAvailabilitiesForCountyCommodity } from './availabilitySlice';
import { getFilteredTYield } from '../utils/tYieldUtils';
import YieldInfoWithCountiesParams from '../types/api/adm/YieldInfoWithCountiesParams';
import HistoricalInputCost from '../types/api/adm/HistoricalInputCost';
import QuoteHistoricalInputCosts from '../types/api/adm/QuoteHistoricalInputCosts';
import InsuranceOffer from '../types/api/adm/InsuranceOffer';
import { GetInsuranceOfferParams } from '../types/api/adm/GetInsuranceOfferParams';
import { getInsuranceOfferInformation } from '../services/adm.service';
import { getDiscoveryPeriod, PriceDiscoveryPeriod } from '../utils/discoveryPeriodUtils';
import { selectRmaPriceDiscoveryForScenario } from './rmaPriceDiscoverySelectors';
import { MYAPriceHistory } from '../types/api/adm/MYAPriceHistory';
import { calculateOlympicAverage } from '../utils/calculations/olympicAverage';
import { formatCurrency } from '../utils/formatNumbers';
import { extractActualPriceToolTip } from '../utils/parsing/mya/myaDateParsing';
import { selectClientFileById, setCurrentClientFile } from './clientFilesSlice';
import { ClientFile } from '../types/api/ClientFile';
import {
  getAllApplicablePlanCodes,
  getScenarioPricesAndYieldsForAllApplicablePlanCodes
} from '../utils/priceYieldLookupUtils';
import AvailableOptionSelections from '../types/api/adm/AvailableOptionSelections';
import QuoteAvailableOptionSelections from '../types/api/adm/QuoteAvailableOptionSelections';
import { selectScenarioById } from './scenariosSlice';
import { selectQuoteById } from './quotesSlice';
import {
  MissingClientFileInStateError,
  MissingQuoteInStateError,
  MissingScenarioInStateError
} from '../errors/state/MissingStateErrors';
import { selectRowCropScenarioPiecePlanCodes } from './scenarioPiecesSlice';
import ScenarioPricesAndYieldsWithScenario from '../types/api/adm/ScenarioPricesAndYieldsWithScenario';
import CountyYieldInfo from '../types/api/adm/CountyYieldInfo';
import YieldInfoWithCounties from '../types/api/adm/YieldInfoWithCounties';

export interface AdmState {
  data: ADMYear[];
  status: valueof<typeof LOADING_STATES>;
  statusFetchScenarioPricesAndYields: valueof<typeof LOADING_STATES>;
  statusFetchAllScenarioPricesAndYieldsPriorYear: valueof<typeof LOADING_STATES>;
  statusFetchCountyYieldInfo: valueof<typeof LOADING_STATES>;
  years: number[];
  states: State[];
  counties: County[];
  commodities: Commodity[];
  types: CropType[];
  practices: Practice[];
  countiesWithOffers: County[];
  commoditiesWithOffers: Commodity[];
  typesWithOffers: CropType[];
  practicesWithOffers: Practice[];
  availableInsuranceOfferSelections: Nullable<AvailableInsuranceOfferSelections>;
  scenarioPricesAndYields: Nullable<ScenarioPricesAndYields>;
  scenarioPricesAndYieldsPriorYear: KeyedState<ScenarioId, ScenarioPricesAndYieldsWithScenario>;
  availableSubCountyCodes: string[];
  availableInsurancePlanCodes: string[];
  availableHistoricalTYields: SliceDataState<CountyCommodityKey, HistoricalTYield[]>;
  availableTYields: TYield[];
  insuranceCalendars: SliceDataState<CountyCommodityKey, InsuranceCalendar[]>;
  yeYears: SliceDataState<CountyCommodityKey, YeYear[]>;
  availableTrendAdjustmentFactors: SliceDataState<CountyCommodityKey, TrendAdjustmentFactor[]>;
  availableHistoricalPrices: HistoricalPrice[];
  availableHistoricalInputCosts: SliceDataState<CountyCommodityKey, HistoricalInputCost[]>;
  availablePriceGroupMembers: SliceDataState<CountyCommodityKey, PriceGroupMember[]>;
  availableHistoricalYieldTrends: SliceDataState<CountyCommodityKey, HistoricalYieldTrend[]>;
  availableHistoricalYieldTrendYears: HistoricalYieldTrendYear[];
  availableHistoricalStormEvents: SliceDataState<CountyCommodityKey, HistoricalStormEvent[]>;
  availableOptions: SliceDataState<CountyCommodityKey, AvailableOptionSelections[]>
  yieldInfoWithCounties: Nullable<YieldInfoWithCounties>;
  countyYieldInfo: KeyedState<ScenarioId, CountyYieldInfo>;
  preDownloadedCounties: string[];
  hurricaneEventOccurred: boolean;
  availablePriceGroups: PriceGroup[];
  availablePriceDiscoveries: SliceDataState<string, RMAPriceDiscovery[]>; // key = commodity
  ceppPractices: CeppPractice[];
  ceppPracticeMappings: CeppPracticeMapping[];
  ceppTypes: CeppType[];
  ceppTypeMappings: CeppTypeMapping[];
  myaPriceHistories: MYAPriceHistory[];
  currentInsuranceOffer: {
    params: Nullable<GetInsuranceOfferParams>;
    data: Nullable<InsuranceOffer>;
    isLoading: boolean;
  };
}

const initialState: AdmState = {
  data: [],
  status: LOADING_STATES.Idle,
  statusFetchScenarioPricesAndYields: LOADING_STATES.Idle,
  statusFetchAllScenarioPricesAndYieldsPriorYear: LOADING_STATES.Idle,
  statusFetchCountyYieldInfo: LOADING_STATES.Idle,
  years: [],
  states: [],
  counties: [],
  commodities: [],
  types: [],
  practices: [],
  countiesWithOffers: [],
  commoditiesWithOffers: [],
  typesWithOffers: [],
  practicesWithOffers: [],
  availableInsuranceOfferSelections: null,
  scenarioPricesAndYields: null,
  scenarioPricesAndYieldsPriorYear: initialKeyedState(),
  availableSubCountyCodes: [],
  availableInsurancePlanCodes: [],
  availableHistoricalTYields: initialSliceDataState(),
  availableTYields: [],
  insuranceCalendars: initialSliceDataState(),
  yeYears: initialSliceDataState(),
  availableTrendAdjustmentFactors: initialSliceDataState(),
  availableHistoricalPrices: [],
  availableHistoricalInputCosts: initialSliceDataState(),
  availablePriceGroupMembers: initialSliceDataState(),
  availablePriceGroups: [],
  availablePriceDiscoveries: initialSliceDataState(),
  availableHistoricalYieldTrends: initialSliceDataState(),
  availableHistoricalYieldTrendYears: [],
  availableHistoricalStormEvents: initialSliceDataState(),
  availableOptions: initialSliceDataState(),
  yieldInfoWithCounties: null,
  countyYieldInfo: initialKeyedState(),
  preDownloadedCounties: [],
  hurricaneEventOccurred: false,
  ceppPractices: [],
  ceppPracticeMappings: [],
  ceppTypes: [],
  ceppTypeMappings: [],
  myaPriceHistories: [],
  currentInsuranceOffer: {
    params: null,
    data: null,
    isLoading: false,
  },
};

export const admSlice = createSlice({
  name: 'adm',
  initialState: initialState,
  reducers: {
    setCurrentInsuranceOfferParams: (state: AdmState, action: PayloadAction<GetInsuranceOfferParams>) => {
      state.currentInsuranceOffer.params = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // This is intended to be a temporary fix for a larger issue where we just rely on the most recent year's ADM data to drive
      // the state here. This allows us to respond to looking at a different year, and properly use that year's ADM data.
      .addCase(setCurrentClientFile.fulfilled, (state: AdmState, action: PayloadAction<Nullable<ClientFile>>) => {
        state.states = state.data.find(yd => yd.year === action.payload?.year)?.states ?? stableEmptyArrayAsMutable<State>();
        state.counties = state.data.find(yd => yd.year === action.payload?.year)?.counties ?? stableEmptyArrayAsMutable<County>();
        state.commodities = state.data.find(yd => yd.year === action.payload?.year)?.commodities ?? stableEmptyArrayAsMutable<Commodity>();
        state.types = state.data.find(yd => yd.year === action.payload?.year)?.cropTypes ?? stableEmptyArrayAsMutable<CropType>();
        state.practices = state.data.find(yd => yd.year === action.payload?.year)?.practices ?? stableEmptyArrayAsMutable<Practice>();
      })
      .addCase(fetchAdmData.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchAdmData.fulfilled, (state: AdmState, action: PayloadAction<ADMYear[]>) => {
        state.status = LOADING_STATES.Succeeded;
        const yearData = action.payload.sort((a, b) => a.year < b.year ? 1 : -1);
        state.data = yearData;
        state.states = yearData.at(0)?.states ?? stableEmptyArrayAsMutable<State>();
        state.counties = yearData.at(0)?.counties ?? stableEmptyArrayAsMutable<County>();
        state.commodities = yearData.at(0)?.commodities ?? stableEmptyArrayAsMutable<Commodity>();
        state.types = yearData.at(0)?.cropTypes ?? stableEmptyArrayAsMutable<CropType>();
        state.practices = yearData.at(0)?.practices ?? stableEmptyArrayAsMutable<Practice>();
        state.years = yearData.map(y => y.year);
      })
      .addCase(fetchAdmData.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchCountiesWithOffers.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.countiesWithOffers = [];
      })
      .addCase(fetchCountiesWithOffers.fulfilled, (state: AdmState, action: PayloadAction<County[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.countiesWithOffers = action.payload;
      })
      .addCase(fetchCommoditiesWithOffers.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.commoditiesWithOffers = [];
      })
      .addCase(fetchCommoditiesWithOffers.fulfilled, (state: AdmState, action: PayloadAction<Commodity[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.commoditiesWithOffers = action.payload;
      })
      .addCase(fetchCommoditiesWithOffers.rejected, (state: AdmState, action) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchCommodityTypesWithOffers.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.typesWithOffers = [];
      })
      .addCase(fetchCommodityTypesWithOffers.fulfilled, (state: AdmState, action: PayloadAction<CropType[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.typesWithOffers = action.payload;
      })
      .addCase(fetchCommodityTypesWithOffers.rejected, (state: AdmState, action) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchPracticesWithOffers.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.practicesWithOffers = [];
      })
      .addCase(fetchPracticesWithOffers.fulfilled, (state: AdmState, action: PayloadAction<Practice[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.practicesWithOffers = action.payload;
      })
      .addCase(fetchPracticesWithOffers.rejected, (state: AdmState, action) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchAvailableInsuranceOfferSelectionsForPlan.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.availableInsuranceOfferSelections = null;
      })
      .addCase(fetchAvailableInsuranceOfferSelectionsForPlan.fulfilled, (state: AdmState, action: PayloadAction<Nullable<AvailableInsuranceOfferSelections>>) => {
        state.status = LOADING_STATES.Succeeded;
        state.availableInsuranceOfferSelections = action.payload;
      })
      .addCase(fetchAvailableInsuranceOfferSelectionsForPlan.rejected, (state: AdmState, action) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchScenarioPricesAndYields.pending, (state: AdmState) => {
        state.statusFetchScenarioPricesAndYields = LOADING_STATES.Loading;
        state.status = LOADING_STATES.Loading;
        state.scenarioPricesAndYields = null;
      })
      .addCase(fetchScenarioPricesAndYields.fulfilled, (state: AdmState, action: PayloadAction<Nullable<ScenarioPricesAndYields>>) => {
        state.status = LOADING_STATES.Succeeded;
        state.scenarioPricesAndYields = action.payload;
        state.statusFetchScenarioPricesAndYields = LOADING_STATES.Succeeded;
      })
      .addCase(fetchScenarioPricesAndYields.rejected, (state: AdmState, action) => {
        state.statusFetchScenarioPricesAndYields = LOADING_STATES.Failed;
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchAllScenarioPricesAndYieldsPriorYear.fulfilled, (state: AdmState, action: PayloadAction<ScenarioPricesAndYieldsWithScenario[]>) => {
        state.status = LOADING_STATES.Succeeded;
        updateKeyedState(state.scenarioPricesAndYieldsPriorYear, action.payload, c => c.scenarioId);
        state.statusFetchAllScenarioPricesAndYieldsPriorYear = LOADING_STATES.Succeeded;
      })
      .addCase(fetchAllScenarioPricesAndYieldsPriorYear.rejected, (state: AdmState, action) => {
        state.statusFetchAllScenarioPricesAndYieldsPriorYear = LOADING_STATES.Failed;
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchCountyYieldInfo.fulfilled, (state: AdmState, action: PayloadAction<CountyYieldInfo[]>) => {
        state.status = LOADING_STATES.Succeeded;
        updateKeyedState(state.countyYieldInfo, action.payload, c => c.scenarioId);
        state.statusFetchCountyYieldInfo = LOADING_STATES.Succeeded;
      })
      .addCase(fetchCountyYieldInfo.rejected, (state: AdmState, action) => {
        state.statusFetchCountyYieldInfo = LOADING_STATES.Failed;
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchAvailableSubCountyCodes.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.availableSubCountyCodes = [];
      })
      .addCase(fetchAvailableSubCountyCodes.fulfilled, (state: AdmState, action: PayloadAction<string[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.availableSubCountyCodes = action.payload;
      })
      .addCase(fetchAvailableSubCountyCodes.rejected, (state: AdmState, action) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchAvailableInsurancePlanCodes.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.availableInsurancePlanCodes = [];
      })
      .addCase(fetchAvailableInsurancePlanCodes.fulfilled, (state: AdmState, action: PayloadAction<string[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.availableInsurancePlanCodes = action.payload;
      })
      .addCase(fetchAvailableInsurancePlanCodes.rejected, (state: AdmState, action) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchTYields.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.availableTYields = [];
      })
      .addCase(fetchTYields.fulfilled, (state: AdmState, action: PayloadAction<TYield[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.availableTYields = action.payload;
      })
      .addCase(fetchTYields.rejected, (state: AdmState, action) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchHistoricalTYields.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchHistoricalTYields.fulfilled, (state: AdmState, action: PayloadAction<QuoteHistoricalTYields[]>) => {
        state.status = LOADING_STATES.Succeeded;
        action.payload.forEach(quoteData => {
          updateKeyedStateVerbatim(state.availableHistoricalTYields.data, quoteData.historicalTYields, quoteData.countyCommodityKey);
        });
      })
      .addCase(fetchHistoricalTYields.rejected, (state: AdmState, action) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchInsuranceCalendars.rejected, (state: AdmState, action) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchInsuranceCalendars.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchInsuranceCalendars.fulfilled, (state: AdmState, action: PayloadAction<QuoteInsuranceCalendars[]>) => {
        state.status = LOADING_STATES.Succeeded;
        for (const quoteData of action.payload) {
          updateKeyedStateVerbatim(state.insuranceCalendars.data, quoteData.insuranceCalendars.sort((a, b) => a.insurancePlanCode.localeCompare(b.insurancePlanCode)), quoteData.countyCommodityKey);
        }
      })
      .addCase(fetchYeYears.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchYeYears.fulfilled, (state: AdmState, action: PayloadAction<QuoteYeYears[]>) => {
        state.status = LOADING_STATES.Succeeded;
        action.payload.forEach(quoteData => {
          updateKeyedStateVerbatim(state.yeYears.data, quoteData.yeYears, quoteData.countyCommodityKey);
        });
      })
      .addCase(fetchYeYears.rejected, (state: AdmState, action) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchTrendAdjustmentFactors.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchTrendAdjustmentFactors.fulfilled, (state: AdmState, action: PayloadAction<QuoteTrendAdjustmentFactors[]>) => {
        state.status = LOADING_STATES.Succeeded;
        action.payload.forEach(quoteData => {
          updateKeyedStateVerbatim(state.availableTrendAdjustmentFactors.data, quoteData.trendAdjustmentFactors, quoteData.countyCommodityKey);
        });
      })
      .addCase(fetchTrendAdjustmentFactors.rejected, (state: AdmState, action) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchHistoricalPrices.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.availableHistoricalPrices = [];
      })
      .addCase(fetchHistoricalPrices.fulfilled, (state: AdmState, action: PayloadAction<HistoricalPrice[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.availableHistoricalPrices = action.payload;
      })
      .addCase(fetchHistoricalPrices.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchHistoricalInputCosts.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchHistoricalInputCosts.fulfilled, (state: AdmState, action: PayloadAction<QuoteHistoricalInputCosts[]>) => {
        state.status = LOADING_STATES.Succeeded;
        for (const inputcosts of action.payload) {
          updateKeyedStateVerbatim(state.availableHistoricalInputCosts.data, inputcosts.historicalInputCosts, inputcosts.countyCommodityKey);
        }
      })
      .addCase(fetchHistoricalInputCosts.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchPriceGroupMembers.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchPriceGroupMembers.fulfilled, (state: AdmState, action: PayloadAction<QuotePriceGroupMembers[]>) => {
        state.status = LOADING_STATES.Succeeded;
        for (const quoteData of action.payload) {
          updateKeyedStateVerbatim(state.availablePriceGroupMembers.data, quoteData.priceGroupMembers, quoteData.countyCommodityKey);
        }
      })
      .addCase(fetchPriceGroupMembers.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchPriceGroups.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchPriceGroups.fulfilled, (state: AdmState, action: PayloadAction<PriceGroup[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.availablePriceGroups = action.payload;
      })
      .addCase(fetchPriceGroups.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchPriceDiscoveries.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchPriceDiscoveries.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchPriceDiscoveries.fulfilled, (state: AdmState, action: PayloadAction<RMAPriceDiscovery[]>) => {
        state.status = LOADING_STATES.Succeeded;
        const commodityCodes = [...new Set(action.payload.map(x => x.commodityCode))];

        for (const commodityCode of commodityCodes) {
          const items = action.payload.filter(x => x.commodityCode === commodityCode);
          updateKeyedStateVerbatim(state.availablePriceDiscoveries.data, items, commodityCode);
        }
      })
      .addCase(fetchCeppMappings.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchCeppMappings.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchCeppMappings.fulfilled, (state: AdmState, action: PayloadAction<CeppMappings>) => {
        state.status = LOADING_STATES.Succeeded;

        state.ceppTypes = action.payload.ceppTypes;
        state.ceppTypeMappings = action.payload.ceppTypeMappings;
        state.ceppPractices = action.payload.ceppPractices;
        state.ceppPracticeMappings = action.payload.ceppPracticeMappings;
      })
      .addCase(fetchMyaPriceHistoriesRequest.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchMyaPriceHistoriesRequest.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchMyaPriceHistoriesRequest.fulfilled, (state: AdmState, action: PayloadAction<MYAPriceHistory[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.myaPriceHistories = action.payload;
      })
      .addCase(fetchHistoricalYieldTrends.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchHistoricalYieldTrends.fulfilled, (state: AdmState, action: PayloadAction<QuoteHistoricalYieldTrends[]>) => {
        state.status = LOADING_STATES.Succeeded;
        action.payload.forEach(quoteData => {
          updateKeyedStateVerbatim(state.availableHistoricalYieldTrends.data, quoteData.historicalYieldTrends, quoteData.countyCommodityKey);
        });
      })
      .addCase(fetchHistoricalYieldTrends.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchHistoricalYieldTrendYears.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.availableHistoricalYieldTrendYears = [];
      })
      .addCase(fetchHistoricalYieldTrendYears.fulfilled, (state: AdmState, action: PayloadAction<HistoricalYieldTrendYear[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.availableHistoricalYieldTrendYears = action.payload;
      })
      .addCase(fetchHistoricalYieldTrendYears.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchPreDownloadedCounties.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.preDownloadedCounties = [];
      })
      .addCase(fetchPreDownloadedCounties.fulfilled, (state: AdmState, action: PayloadAction<string[]>) => {
        state.status = LOADING_STATES.Succeeded;
        state.preDownloadedCounties = action.payload;
      })
      .addCase(fetchPreDownloadedCounties.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchHipHurricaneEvent.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
        state.hurricaneEventOccurred = false;
      })
      .addCase(fetchHipHurricaneEvent.fulfilled, (state: AdmState, action: PayloadAction<boolean>) => {
        state.status = LOADING_STATES.Succeeded;
        state.hurricaneEventOccurred = action.payload;
      })
      .addCase(fetchHipHurricaneEvent.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchHistoricalStormEvents.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchHistoricalStormEvents.fulfilled, (state: AdmState, action: PayloadAction<QuoteHistoricalStormEvents[]>) => {
        state.status = LOADING_STATES.Succeeded;
        action.payload.forEach(quoteData => {
          updateKeyedStateVerbatim(state.availableHistoricalStormEvents.data, quoteData.historicalStormEvents, quoteData.countyCommodityKey);
        });
      })
      .addCase(fetchHistoricalStormEvents.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchAvailableOptionSelections.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchAvailableOptionSelections.fulfilled, (state: AdmState, action: PayloadAction<QuoteAvailableOptionSelections[]>) => {
        state.status = LOADING_STATES.Succeeded;
        action.payload.forEach(quoteData => {
          updateKeyedStateVerbatim(state.availableOptions.data, quoteData.availableOptionSelections, quoteData.countyCommodityKey);
        });
      })
      .addCase(fetchAvailableOptionSelections.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchYieldInfoWithCounties.pending, (state: AdmState) => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(fetchYieldInfoWithCounties.fulfilled, (state: AdmState, action: PayloadAction<Nullable<YieldInfoWithCounties>>) => {
        state.status = LOADING_STATES.Succeeded;
        state.yieldInfoWithCounties = action.payload;
      })
      .addCase(fetchYieldInfoWithCounties.rejected, (state: AdmState) => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(fetchInsuranceOfferInformation.pending, (state: AdmState) => {
        state.currentInsuranceOffer.isLoading = true;
      })
      .addCase(fetchInsuranceOfferInformation.fulfilled, (state: AdmState, action) => {
        state.currentInsuranceOffer.isLoading = false;
        state.currentInsuranceOffer.data = action.payload.data;
      })
      .addCase(fetchInsuranceOfferInformation.rejected, (state: AdmState) => {
        state.currentInsuranceOffer.isLoading = false;
      });
  },
});

export const selectAdmLoadingStatus = (state: RootState) => state.adm.status;
export const selectAdmLoadingStatusFetchScenarioPricesAndYields = (state: RootState) => state.adm.statusFetchScenarioPricesAndYields;

export const selectAllTypes = (state: RootState) => state.adm.types;
export const selectAllPractices = (state: RootState) => state.adm.practices;
export const selectAdmData = (state: RootState) => state.adm.data;
export const selectYears = (state: RootState) => state.adm.years;


const selectAllStatesUnordered = (state: RootState) => state.adm.states;

export const selectAllStates = createSelector([selectAllStatesUnordered], states => {
  return orderByProperty(states, DefaultOrders.states);
});

export const selectYieldInfoWithCounties = (state: RootState) => state.adm.yieldInfoWithCounties;

const selectAllCountiesUnordered = (state: RootState) => state.adm.counties;

export const selectAllCounties = createSelector([selectAllCountiesUnordered], counties => {
  return orderByProperty(counties, DefaultOrders.counties);
});

export const selectCounties = (state: RootState, stateCode: string) => selectAllCounties(state).filter(c => c.stateCode === stateCode && c.countyCode !== '998'); //998 seems to be a "county" that is labelled as "All Counties". We don't want that showing for the counties available for a state

const selectAllCommoditiesUnordered = (state: RootState) => state.adm.commodities;
export const selectAllCommodities = createSelector([selectAllCommoditiesUnordered], commodities => {
  return orderByProperty(commodities, DefaultOrders.commodities);
});

const selectCountiesWithOffersUnordered = (state: RootState) => state.adm.countiesWithOffers;
export const selectCountiesWithOffers = createSelector([selectCountiesWithOffersUnordered], counties => {
  return orderByProperty(counties, DefaultOrders.counties);
});

const selectCommoditiesWithOffersUnordered = (state: RootState) => state.adm.commoditiesWithOffers;
export const selectCommoditiesWithOffers = createSelector([selectCommoditiesWithOffersUnordered], commodities => {
  return orderByProperty(commodities, DefaultOrders.commodities);
});

export const selectCommodityTypesWithOffersUnordered = (state: RootState) => state.adm.typesWithOffers;
export const selectCommodityTypesWithOffers = createSelector([selectCommodityTypesWithOffersUnordered], types => {
  return orderByProperty(types, DefaultOrders.cropTypes);
});

const selectPracticesWithOffersUnordered = (state: RootState) => state.adm.practicesWithOffers;
export const selectPracticesWithOffers = createSelector([selectPracticesWithOffersUnordered], practices => {
  return orderByProperty(practices, DefaultOrders.practices);
});

export const selectUniqueCoverageLevelPairings = createSelector([(state: RootState) => state.adm.availableInsuranceOfferSelections?.coverageLevelBasedSelections], coverageLevelBasedSelections => {
  const list = coverageLevelBasedSelections?.map(
    cld => {
      return {
        upperCoverageLevel: cld.upperCoverageLevel,
        lowerCoverageLevel: cld.lowerCoverageLevel,
      };
    });

  const uniqueList: CoverageLevelPair[] = [];

  list?.forEach(element => {
    if (!uniqueList.find(x => isEqual(x, element))) {
      uniqueList.push(element);
    }
  });

  return uniqueList;
});

export const selectOfferUnitStructures = (state: RootState) => state.adm.availableInsuranceOfferSelections?.unitStructures ?? stableEmptyArray();

export const selectOffer = (state: RootState) => state.adm.scenarioPricesAndYields ?? null;
export const selectOfferVolatility = (state: RootState) => state.adm.scenarioPricesAndYields?.priceVolatilityFactor ?? null;
export const selectOfferProjectedPrice = (state: RootState) => state.adm.scenarioPricesAndYields?.projectedPrice ?? null;
export const selectOfferHarvestPrice = (state: RootState) => state.adm.scenarioPricesAndYields?.harvestPrice ?? state.adm.scenarioPricesAndYields?.projectedPrice ?? null;
export const selectOfferProducerYield = (state: RootState) => state.adm.scenarioPricesAndYields?.transitionalYield ?? null;
export const selectOfferExpectedCountyYield = (state: RootState) => state.adm.scenarioPricesAndYields?.expectedIndexValue ?? null;
export const selectOfferActualCountyYield = (state: RootState) => state.adm.scenarioPricesAndYields?.finalIndexValue ?? state.adm.scenarioPricesAndYields?.expectedIndexValue ?? null;
export const selectOfferExpectedInputCosts = (state: RootState) => state.adm.scenarioPricesAndYields?.expectedInputCosts ?? null;
export const selectOfferActualInputCosts = (state: RootState) => state.adm.scenarioPricesAndYields?.actualInputCosts ?? state.adm.scenarioPricesAndYields?.expectedInputCosts ?? null;
export const selectOfferMpProjectedPrice = (state: RootState) => state.adm.scenarioPricesAndYields?.mpProjectedPrice ?? null;

export const selectScenarioPricesAndYieldsPriorYearFinalCountyYield = (state: RootState, scenarioId: ScenarioId) => {
  const allScenarioPricesAndYields = selectAllScenarioPricesAndYieldsMap(state);
  const scenarioPricesAndYields = allScenarioPricesAndYields.get(scenarioId);
  const finalCountyYield = scenarioPricesAndYields?.scenarioPricesAndYields.finalIndexValue ?? 0;
  return finalCountyYield;
};

export const selectCountyYieldInfoPriorAndCurrent = (state: RootState, scenarioId: ScenarioId) => {
  const allCountyYieldInfo = selectAllCountyYieldInfoMap(state);
  const countyYieldInfo = allCountyYieldInfo.get(scenarioId);
  return countyYieldInfo;
};

export const selectOfferUpperProtectionFactor = (state: RootState, upperCoverageLevel: number, lowerCoverageLevel: number) => state.adm.availableInsuranceOfferSelections?.coverageLevelBasedSelections.find(cld => cld.upperCoverageLevel === upperCoverageLevel && cld.lowerCoverageLevel === lowerCoverageLevel)?.upperProtectionFactor ?? null;
export const selectOfferLowerProtectionFactor = (state: RootState, upperCoverageLevel: number, lowerCoverageLevel: number) => state.adm.availableInsuranceOfferSelections?.coverageLevelBasedSelections.find(cld => cld.upperCoverageLevel === upperCoverageLevel && cld.lowerCoverageLevel === lowerCoverageLevel)?.lowerProtectionFactor ?? null;

export const selectOfferOptions = (state: RootState, upperCoverageLevel: number) => state.adm.availableInsuranceOfferSelections?.coverageLevelBasedSelections.find(cld => cld.coverageLevelId === upperCoverageLevel)?.options ?? stableEmptyArray();

export const selectAvailableSubCountyCodes = (state: RootState) => state.adm.availableSubCountyCodes;
export const selectAvailableInsurancePlanCodes = (state: RootState) => state.adm.availableInsurancePlanCodes;

export const selectAllHistoricalTYields = (state: RootState) => state.adm.availableHistoricalTYields.data;

export const selectAvailableTYields = (state: RootState) => state.adm.availableTYields;

export const selectInsuranceOffer = (state: RootState, params: GetInsuranceOfferParams) => {
  if (isEqual(state.adm.currentInsuranceOffer.params, params)) {
    return state.adm.currentInsuranceOffer.data;
  }

  return null;
};

export const selectTYield = createSelector([
  selectAvailableTYields,
  selectAvailableSubCountyCodes,
  (_state: RootState, typeId: string, _practiceId: string, _subCountyCode: Nullable<string>) => typeId,
  (_state: RootState, _typeId: string, practiceId: string, _subCountyCode: Nullable<string>) => practiceId,
  (_state: RootState, _typeId: string, _practiceId: string, subCountyCode: Nullable<string>) => subCountyCode,
], (tYields, subCountyCodes, typeId, practiceId, subCountyCode) => {
  return getFilteredTYield(tYields, subCountyCodes, typeId, practiceId, subCountyCode);
});

export const selectAllTrendAdjustmentFactors = (state: RootState) => state.adm.availableTrendAdjustmentFactors.data;

export const selectAllHistoricalPrices = (state: RootState) => state.adm.availableHistoricalPrices;

const mpPlanCodeGroup = '03';

const selectAllPriceGroupMembers = (state: RootState) => state.adm.availablePriceGroupMembers.data;

export const selectAllPriceGroups = (state: RootState) => state.adm.availablePriceGroups;

export const selectPriceGroupMembers = createSelector(
  [
    selectAllPriceGroupMembers,
    selectAllPriceGroups,
  ],
  (priceGroupMembers, priceGroups) => {
    // filter out price group members with MP

    const filteredAvailablePriceGroupMembers: KeyedState<CountyCommodityKey, PriceGroupMember[]> = initialKeyedState();

    const priceGroupMembersMap = getKeyedStateToMap(priceGroupMembers);

    for (const [key, value] of priceGroupMembersMap) {
      const filteredMembers = value.filter(member => (priceGroups.find(pg => pg.priceGroupID === member.priceGroupID)?.insurancePlanGroupCode ?? '') !== mpPlanCodeGroup);
      updateKeyedStateVerbatim(filteredAvailablePriceGroupMembers, filteredMembers, key);
    }

    return filteredAvailablePriceGroupMembers;
  },
);

export const selectMpPriceGroupMembers = createSelector(
  [
    selectAllPriceGroupMembers,
    selectAllPriceGroups,
  ],
  (priceGroupMembers, priceGroups) => {
    // filter out price group members with MP

    const filteredAvailablePriceGroupMembers: KeyedState<CountyCommodityKey, PriceGroupMember[]> = initialKeyedState();

    const priceGroupMembersMap = getKeyedStateToMap(priceGroupMembers);

    for (const [key, value] of priceGroupMembersMap) {
      const filteredMembers = value.filter(member => (priceGroups.find(pg => pg.priceGroupID === member.priceGroupID)?.insurancePlanGroupCode ?? '') === mpPlanCodeGroup);
      updateKeyedStateVerbatim(filteredAvailablePriceGroupMembers, filteredMembers, key);
    }

    return filteredAvailablePriceGroupMembers;
  },
);

export const selectPriceGroups = createSelector(
  [
    selectAllPriceGroups,
  ],
  priceGroups => {
    // filter out price group that are MP

    return priceGroups.filter(pg => pg.insurancePlanGroupCode !== mpPlanCodeGroup);
  });

export const selectAllRmaPriceDiscoveries = (state: RootState) => state.adm.availablePriceDiscoveries.data;
const selectAllMYAPriceHistories = (state: RootState) => state.adm.myaPriceHistories;

export const selectFarmBillDefaultExpectedPrice = createSelector(
  [
    (state: RootState) => selectAllMYAPriceHistories(state),
    (state: RootState, year: number, commodityCode: string) => year,
    (state: RootState, year: number, commodityCode: string) => commodityCode,
    (state: RootState, year: number, commodityCode: string, scenarioPieceType: ScenarioPieceType) => scenarioPieceType,
  ], (allMyaHistories, year, commodityCode, scenarioPieceType) => {
    const hintLines: (string)[] = [];
    let referencePrice = 0;
    if (year >= 2024) {
      switch (commodityCode) {
        case CommodityCode.Corn:
          referencePrice = 4.01;
          break;
        case CommodityCode.Grain_Sorghum:
          referencePrice = 4.06;
          break;
        case CommodityCode.Wheat:
          referencePrice = 5.5;
          break;
        case CommodityCode.Cotton:
          referencePrice = 0.367;
          break;
        case CommodityCode.Barley:
          referencePrice = 4.95;
          break;
        case CommodityCode.Oats:
          referencePrice = 2.76;
          break;
        case CommodityCode.Soybeans:
          referencePrice = 9.26;
          break;
        case CommodityCode.Rice:
          referencePrice = 0.14;
          break;
        case CommodityCode.Peanuts:
          referencePrice = 0.268;
          break;
        case CommodityCode.Dry_Peas:
          referencePrice = 0.11;
          break;
      }
    } else {
      switch (commodityCode) {
        case CommodityCode.Corn:
          referencePrice = 3.7;
          break;
        case CommodityCode.Grain_Sorghum:
          referencePrice = 3.95;
          break;
        case CommodityCode.Wheat:
          referencePrice = 5.5;
          break;
        case CommodityCode.Cotton:
          referencePrice = 0.367;
          break;
        case CommodityCode.Barley:
          referencePrice = 4.95;
          break;
        case CommodityCode.Oats:
          referencePrice = 2.4;
          break;
        case CommodityCode.Soybeans:
          referencePrice = 8.4;
          break;
        case CommodityCode.Rice:
          referencePrice = 0.14;
          break;
        case CommodityCode.Peanuts:
          referencePrice = 0.2675;
          break;
        case CommodityCode.Dry_Peas:
          referencePrice = 11;
          break;
      }
    }

    let result = 0;
    let centerAllButFirst = false;

    if (scenarioPieceType === ScenarioPieceType.Plc) {
      result = referencePrice;
      if (result > 0) {
        hintLines.push(`Benchmark Price is set at ${formatCurrency(result, true)}`);
      }
    } else if (scenarioPieceType === ScenarioPieceType.Arc) {
      const applicableMyaHistories = allMyaHistories.filter(x => x.commodityCode === commodityCode);
      result = calculateOlympicAverage(applicableMyaHistories.map(x => cup(x.price, referencePrice)));
      applicableMyaHistories.sort((a, b) => b.year - a.year);

      centerAllButFirst = true;
      hintLines.push('The Olympic Average of the latest 5 years:');
      for (const item of applicableMyaHistories) {
        hintLines.push(`${item.year} - ${formatCurrency(item.price, true)}`);
      }
    }

    return {
      value: roundToPlaces(result, 2),
      hintLines: hintLines,
      centerAllButFirst: centerAllButFirst,
    };
  });

export const selectFarmBillDefaultActualPrice = createSelector(
  [
    (state: RootState) => selectAllMYAPriceHistories(state),
    (state: RootState, year: number) => year,
    (state: RootState, year: number, commodityCode: string) => commodityCode,
  ], (allMyaHistories, year, commodityCode) => {
    let priceInfo = allMyaHistories.filter(x => x.commodityCode === commodityCode && x.year === year).at(0);
    if (priceInfo === undefined) {
      priceInfo = allMyaHistories.filter(x => x.commodityCode === commodityCode && x.year === year - 1).at(0);
    }

    let hintInfo = priceInfo;
    if (hintInfo === undefined) {
      const commodityInfo = allMyaHistories.filter(x => x.commodityCode === commodityCode);
      commodityInfo.sort((a, b) => a.year - b.year);
      hintInfo = commodityInfo.at(0);
    }

    const hintLines: (string)[] = [];
    const hint = extractActualPriceToolTip(hintInfo?.marketingYear, year);
    if (hint !== undefined) {
      hintLines.push(`Marketing year is set at ${hint}`);
    }
    return {
      hintLines: hintLines,
      value: roundToPlaces(priceInfo?.price ?? 0, 2),
      centerAllButFirst: false,
    };
  });

export const selectCeppPractice = (state: RootState, practiceId: string) => state.adm.ceppPracticeMappings.find(x => x.practiceID === practiceId);
export const selectCeppType = (state: RootState, typeId: string) => state.adm.ceppTypeMappings.find(x => x.typeID === typeId);

export const selectPriceGroupMember = (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceId: string) => {
  const priceGroupMembersMap = getKeyedStateToMap(selectPriceGroupMembers(state));
  return getPriceGroupMemberFromPriceGroups(priceGroupMembersMap, countyId, commodityCode, typeId, practiceId);
};

export const selectMpPriceGroupMember = (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceId: string) => {
  const priceGroupMembersMap = getKeyedStateToMap(selectMpPriceGroupMembers(state));
  return getPriceGroupMemberFromPriceGroups(priceGroupMembersMap, countyId, commodityCode, typeId, practiceId);
};

export const getPriceGroupMemberFromPriceGroups = (
  priceGroupMembers: Map<`${string}-${string}`, PriceGroupMember[]>,
  countyId: string,
  commodityCode: string,
  typeId: string,
  practiceId: string,
) => {
  const countyCommodityKey = buildCountyAndCommodityKey(countyId, commodityCode);
  const countyCommodityPriceGroupMembers = getItemsForId(priceGroupMembers, countyCommodityKey);
  return countyCommodityPriceGroupMembers.find(pgm => pgm.typeID === typeId && pgm.practiceID === practiceId);
};

export const selectAllHistoricalYields = (state: RootState) => state.adm.availableHistoricalYieldTrendYears;

export const selectHistoricalYieldTrends = (state: RootState) => state.adm.availableHistoricalYieldTrends.data;

export const selectHistoricalYieldTrend = (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceId: string) => {
  const historicalYieldTrendsMap = getKeyedStateToMap(selectHistoricalYieldTrends(state));
  const countyCommodityHistoricalYieldTrends = getItemsForId(historicalYieldTrendsMap, buildCountyAndCommodityKey(countyId, commodityCode));
  return countyCommodityHistoricalYieldTrends.find(hyt => hyt.typeId === typeId && hyt.practiceId === practiceId);
};

export const selectAllHistoricalInputCosts = (state: RootState) => state.adm.availableHistoricalInputCosts.data;

export const selectHistoricalInputCosts = (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceId: string) => {
  const historicalInputCostsMap = getKeyedStateToMap(selectAllHistoricalInputCosts(state));
  const countyCommodityHistoricalInputCosts = getItemsForId(historicalInputCostsMap, buildCountyAndCommodityKey(countyId, commodityCode));
  return countyCommodityHistoricalInputCosts.filter(hic => hic.typeId === typeId && hic.practiceId === practiceId);
};

export const selectPreDownloadedCounties = (state: RootState) => state.adm.preDownloadedCounties;

export const selectHurricaneEventOccurred = (state: RootState) => state.adm.hurricaneEventOccurred;

export const selectAllHistoricalStormEvents = (state: RootState) => state.adm.availableHistoricalStormEvents.data;

export const selectAllAvailableOptions = (state: RootState) => state.adm.availableOptions.data;

export const selectAllYeYears = (state: RootState) => state.adm.yeYears.data;

export const selectAllInsuranceCalendars = (state: RootState) => state.adm.insuranceCalendars.data;

export const selectAllScenarioPricesAndYields = (state: RootState) => state.adm.scenarioPricesAndYieldsPriorYear;

export const selectAllCountyYieldInfo = (state: RootState) => state.adm.countyYieldInfo;

//#region Memoized selectors

export const selectAllScenarioPricesAndYieldsMap = createSelector([selectAllScenarioPricesAndYields], allScenarioPricesAndYields => {
  const scenarioPricesAndYieldsMap = getKeyedStateToMap(allScenarioPricesAndYields);
  return scenarioPricesAndYieldsMap;
});

export const selectAllCountyYieldInfoMap = createSelector([selectAllCountyYieldInfo], allCountyYieldInfo => {
  const countyYieldInfoMap = getKeyedStateToMap(allCountyYieldInfo);
  return countyYieldInfoMap;
});

export const selectTrendAdjustmentFactorsForCountyCommodity = createSelector(
  [
    selectAllTrendAdjustmentFactors,
    (state: RootState, countyId: string, commodityCode: string) => countyId,
    (state: RootState, countyId: string, commodityCode: string) => commodityCode,
  ],
  (allTrendAdjustmentFactors, countyId, commodityCode) => {
    const allTrendAdjustmentFactorsByCountyAndCommodityMap = getKeyedStateToMap(allTrendAdjustmentFactors);
    return getItemsForId(allTrendAdjustmentFactorsByCountyAndCommodityMap, buildCountyAndCommodityKey(countyId, commodityCode));
  });

interface IPriceToolTipParams {
  hasAllInfo: boolean;
  foundPriceGroup: boolean;
  foundPriceDiscovery: boolean;

  marketSymbol: string;
  commodityExchange: string;
  contractMonth: string;
  discoveryBeginDate: string;
  discoveryEndDate: string;
  priceSet: boolean;
  discoveryPrice: number;

  commodityCode: string;
  insurancePlanCodes: string[];
}

export const selectHistoricalTYieldsForCountyCommodity = createSelector(
  [
    selectAllHistoricalTYields,
    (state: RootState, countyId: string, commodityCode: string) => countyId,
    (state: RootState, countyId: string, commodityCode: string) => commodityCode,
  ], (allHistoricalTYields, countyId, commodityCode) => {
    const allHistoricalTYieldsByCountyAndCommodityMap = getKeyedStateToMap(allHistoricalTYields);
    return getItemsForId(allHistoricalTYieldsByCountyAndCommodityMap, buildCountyAndCommodityKey(countyId, commodityCode));
  });

export const selectYeYearsForCountyCommodity = createSelector(
  [
    selectAllYeYears,
    (state: RootState, countyId: string, commodityCode: string) => countyId,
    (state: RootState, countyId: string, commodityCode: string) => commodityCode,
  ], (allYeYears, countyId, commodityCode) => {
    const allYeYearsByCountyAndCommodityMap = getKeyedStateToMap(allYeYears);
    return getItemsForId(allYeYearsByCountyAndCommodityMap, buildCountyAndCommodityKey(countyId, commodityCode));
  });

export const selectInsuranceCalendarsForCountyCommodity = createSelector(
  [
    selectAllInsuranceCalendars,
    (state: RootState, countyId: string, commodityCode: string) => countyId,
    (state: RootState, countyId: string, commodityCode: string) => commodityCode,
  ], (allInsuranceCalendars, countyId, commodityCode) => {
    const allInsuranceCalendarsByCountyAndCommodityMap = getKeyedStateToMap(allInsuranceCalendars);
    return getItemsForId(allInsuranceCalendarsByCountyAndCommodityMap, buildCountyAndCommodityKey(countyId, commodityCode));
  });

export const selectInsuranceCalendarsByTypePractice = createSelector([
  (state: RootState, countyId: string, commodityCode: string) => selectInsuranceCalendarsForCountyCommodity(state, countyId, commodityCode),
  (state: RootState, countyId: string, commodityCode: string, typeId: string) => typeId,
  (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceID) => practiceID,
], (allInsuranceCalendars, typeId, practiceId) => {
  return allInsuranceCalendars.filter(x => x.typeId === typeId && x.practiceId === practiceId);
});

const getPriceToolTip = (priceValues: IPriceToolTipParams) => {
  const hintLines: (string)[] = [];
  // aph: doesn't go through the price group methodology, just set price rma
  if (priceValues.hasAllInfo && priceValues.foundPriceGroup) {
    const marketSymbol = priceValues.marketSymbol === '' ? 'Not Available' : priceValues.marketSymbol;
    hintLines.push(`Commodity Exchange: ${priceValues.commodityExchange}`);
    hintLines.push(`Exchange Symbol: ${marketSymbol}`);
    hintLines.push(`Contract Month: ${priceValues.contractMonth}`);
    hintLines.push(`Discovery Start: ${format(new Date(priceValues.discoveryBeginDate), 'MM/dd/yyyy')}`);
    hintLines.push(`Discovery End: ${format(new Date(priceValues.discoveryEndDate), 'MM/dd/yyyy')}`);
    hintLines.push(`${priceValues.priceSet === true ? 'The price has been set' : 'The price has not yet been set'}`);
    // needs to be if rma adm_year.Price
  } else if (priceValues.hasAllInfo && priceValues.foundPriceDiscovery && priceValues.discoveryPrice > 0) {
    hintLines.push('This price has been set');
  } else {
    hintLines.push('Price information for this policy is not yet available');
  }
  const variation = getInsurancePlanCodeVariationForPrice(priceValues.commodityCode,
    priceValues.insurancePlanCodes);
  if (variation !== null) {
    hintLines.push('---');
    hintLines.push(variation.description);
  }

  return hintLines;
};

const getPriceIsSet = (
  isHarvest: boolean,
  priceGroup: PriceGroup | undefined,
  projectedPrice: number | null,
  harvestPrice: number | null,
  commodityCode: string,
  rmaPriceDiscovery: Nullable<RMAPriceDiscovery>,
): boolean => {
  const priceGroupPriceSet = (isHarvest ? priceGroup?.harvestPriceSet : priceGroup?.projectedPriceSet) ?? false;
  const discoveryPeriod = getDiscoveryPeriod(new Date(), isHarvest, projectedPrice, harvestPrice, commodityCode, priceGroup, rmaPriceDiscovery);
  return priceGroupPriceSet || discoveryPeriod === PriceDiscoveryPeriod.Set;
};

export const selectProjectedPriceToolTip = createSelector(
  [
    selectPriceGroupMember,
    selectPriceGroups,
    selectOfferProjectedPrice,
    selectOfferHarvestPrice,
    (_state: RootState, _countyId: string, commodityCode: string, _typeId: string, _practiceId: string) => commodityCode,
    (_state: RootState, _countyId: string, _commodityCode: string, typeId: string) => typeId,
    (_state: RootState, _countyId: string, _commodityCode: string, _typeId: string, practiceId: string) => practiceId,
    selectOfferAvailabilitiesForCountyCommodity,
    (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceId: string, year: number, scenarioId: Nullable<ScenarioId>) => selectRmaPriceDiscoveryForScenario(state, year, countyId, scenarioId, commodityCode, typeId, practiceId),
  ],
  (priceGroupMember, priceGroups, projectedPrice, harvestPrice, commodityCode, typeId, practiceId, scenarioPieceOffers, rmaPriceDiscovery) => {
    const priceGroup = priceGroups.find(x => priceGroupMember !== undefined && x.priceGroupID === priceGroupMember.priceGroupID);
    const scenarioPieceTypes = scenarioPieceOffers.map(i => i.scenarioPieceType);
    const planCodes = scenarioPieceTypes.map(t => getInsurancePlanCodeForScenarioPiece(t));

    return getPriceToolTip({
      hasAllInfo: typeId !== '' && practiceId !== '',
      foundPriceGroup: priceGroup !== undefined,
      foundPriceDiscovery: projectedPrice !== null,

      marketSymbol: priceGroup?.projectedMarketSymbol ?? '',
      commodityExchange: priceGroup?.projectedCommodityExchange ?? '',
      contractMonth: priceGroup?.projectedContractMonth ?? '',
      discoveryBeginDate: priceGroup?.projectedDiscoveryBeginDate ?? '',
      discoveryEndDate: priceGroup?.projectedDiscoveryEndDate ?? '',
      priceSet: getPriceIsSet(false, priceGroup, projectedPrice, harvestPrice, commodityCode, rmaPriceDiscovery),
      discoveryPrice: projectedPrice ?? 0,
      commodityCode: commodityCode,
      insurancePlanCodes: planCodes,
    });
  });

export const selectMpProjectedPriceToolTip = createSelector(
  [
    selectMpPriceGroupMember,
    selectAllPriceGroups,
    selectOfferMpProjectedPrice,
    selectOfferHarvestPrice,
    (_state: RootState, _countyId: string, commodityCode: string, _typeId: string, _practiceId: string) => commodityCode,
    (_state: RootState, _countyId: string, _commodityCode: string, typeId: string) => typeId,
    (_state: RootState, _countyId: string, _commodityCode: string, _typeId: string, practiceId: string) => practiceId,
    selectOfferAvailabilitiesForCountyCommodity,
    (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceId: string, year: number, scenarioId: Nullable<ScenarioId>) => selectRmaPriceDiscoveryForScenario(state, year, countyId, scenarioId, commodityCode, typeId, practiceId),
  ],
  (priceGroupMember, priceGroups, projectedPrice, harvestPrice, commodityCode, typeId, practiceId, scenarioPieceOffers, rmaPriceDiscovery) => {
    const priceGroup = priceGroups.find(x => priceGroupMember !== undefined && x.priceGroupID === priceGroupMember.priceGroupID);
    const scenarioPieceTypes = scenarioPieceOffers.map(i => i.scenarioPieceType);
    const planCodes = scenarioPieceTypes.map(t => getInsurancePlanCodeForScenarioPiece(t));

    return getPriceToolTip({
      hasAllInfo: typeId !== '' && practiceId !== '',
      foundPriceGroup: priceGroup !== undefined,
      foundPriceDiscovery: projectedPrice !== null,

      marketSymbol: priceGroup?.projectedMarketSymbol ?? '',
      commodityExchange: priceGroup?.projectedCommodityExchange ?? '',
      contractMonth: priceGroup?.projectedContractMonth ?? '',
      discoveryBeginDate: priceGroup?.projectedDiscoveryBeginDate ?? '',
      discoveryEndDate: priceGroup?.projectedDiscoveryEndDate ?? '',
      priceSet: getPriceIsSet(false, priceGroup, projectedPrice, harvestPrice, commodityCode, rmaPriceDiscovery),
      discoveryPrice: projectedPrice ?? 0,

      commodityCode: commodityCode,
      insurancePlanCodes: planCodes,
    });
  });

export const selectHarvestPriceToolTip = createSelector(
  [
    selectPriceGroupMember,
    selectPriceGroups,
    selectOfferProjectedPrice,
    selectOfferHarvestPrice,
    (_state, _countyId, commodityCode, _typeId, _practiceId) => commodityCode,
    (_state: RootState, _countyId: string, _commodityCode: string, typeId: string) => typeId,
    (_state: RootState, _countyId: string, _commodityCode: string, _typeId: string, practiceId: string) => practiceId,
    selectOfferAvailabilitiesForCountyCommodity,
    (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceId: string, year: number, scenarioId: Nullable<ScenarioId>) => selectRmaPriceDiscoveryForScenario(state, year, countyId, scenarioId, commodityCode, typeId, practiceId),
  ],
  (priceGroupMember, priceGroups, projectedPrice, harvestPrice, commodityCode, typeId, practiceId, scenarioPieceOffers, rmaPriceDiscovery) => {
    const priceGroup = priceGroups.find(x => priceGroupMember !== undefined && x.priceGroupID === priceGroupMember.priceGroupID);
    const scenarioPieceTypes = scenarioPieceOffers.map(i => i.scenarioPieceType);
    const planCodes = scenarioPieceTypes.map(t => getInsurancePlanCodeForScenarioPiece(t));

    return getPriceToolTip({
      hasAllInfo: typeId !== '' && practiceId !== '',
      foundPriceGroup: priceGroup !== undefined,
      foundPriceDiscovery: harvestPrice !== null,

      marketSymbol: priceGroup?.harvestMarketSymbol ?? '',
      commodityExchange: priceGroup?.harvestCommodityExchange ?? '',
      contractMonth: priceGroup?.harvestContractMonth ?? '',
      discoveryBeginDate: priceGroup?.harvestDiscoveryBeginDate ?? '',
      discoveryEndDate: priceGroup?.harvestDiscoveryEndDate ?? '',
      priceSet: getPriceIsSet(true, priceGroup, projectedPrice, harvestPrice, commodityCode, rmaPriceDiscovery),
      discoveryPrice: harvestPrice ?? 0,

      commodityCode: commodityCode,
      insurancePlanCodes: planCodes,
    });
  });

export const selectAllDistinctOfferOptions = createSelector([(state: RootState) => state.adm.availableInsuranceOfferSelections?.coverageLevelBasedSelections], coverageLevelBasedSelections => {
  const allOptions = coverageLevelBasedSelections?.flatMap(cld => cld.options) ?? [];
  return allOptions.length === 0 ? stableEmptyArrayAsMutable<Option>() : distinctBy(allOptions, option => option.optionCode);
});

export const selectState = createSelector([selectAllStates, (state: RootState, stateCode: string) => stateCode], (allStates, stateCode) => {
  return allStates.find(s => s.stateCode === stateCode);
}, { memoizeOptions: { maxSize: 50 } });

export const selectCounty = createSelector([selectAllCounties, (state: RootState, countyId: string) => countyId], (allCounties, countyId) => {
  return allCounties.find(c => c.countyId === countyId);
}, { memoizeOptions: { maxSize: 30 } });

export const selectTypeById = createSelector([selectAllTypes, (state: RootState, typeId: string) => typeId], (allTypes, typeId) => {
  return allTypes.find(t => t.typeId === typeId) ?? null;
}, { memoizeOptions: { maxSize: 30 } });

export const selectCommodity = createSelector([selectAllCommodities, (state: RootState, commodityCode: string) => commodityCode], (allCommodities, commodityCode) => {
  return allCommodities.find(c => c.commodityCode === commodityCode);
}, { memoizeOptions: { maxSize: 30 } });

export const selectPracticeById = createSelector([selectAllPractices, (state: RootState, practiceId: string) => practiceId], (allPractices, practiceId) => {
  return allPractices.find(p => p.practiceId === practiceId) ?? null;
}, { memoizeOptions: { maxSize: 30 } });

export const selectStateFromCounty = createSelector([selectAllStates, selectCounty], (allStates, county) => {
  return allStates.find(s => s.stateCode === county?.stateCode);
}, { memoizeOptions: { maxSize: 30 } });

export const selectHistoricalPrices = createSelector([
  selectAllHistoricalPrices,
  selectPriceGroupMember,
  (_state: RootState, _countyId: string, _commodityCode: string, _typeId: string, _practiceId: string, startYear: number, _endYear: number) => startYear,
  (_state: RootState, _countyId: string, _commodityCode: string, _typeId: string, _practiceId: string, _startYear: number, endYear: number) => endYear,
], (historicalPrices, priceGroupMember, startYear, endYear) => {
  if (priceGroupMember === undefined) return stableEmptyArrayAsMutable<HistoricalPrice>();

  const pricesFilteredByYear = historicalPrices.filter(hp => hp.priceYear >= startYear && hp.priceYear <= endYear && hp.priceGroupID === priceGroupMember.priceGroupID).sort((a, b) => a.priceYear - b.priceYear);
  return pricesFilteredByYear.length > 0 ? pricesFilteredByYear : stableEmptyArrayAsMutable<HistoricalPrice>();
});

export const selectMpHistoricalPrices = createSelector([
  selectAllHistoricalPrices,
  selectMpPriceGroupMember,
  (_state: RootState, _countyId: string, _commodityCode: string, _typeId: string, _practiceId: string, startYear: number, _endYear: number) => startYear,
  (_state: RootState, _countyId: string, _commodityCode: string, _typeId: string, _practiceId: string, _startYear: number, endYear: number) => endYear,
], (historicalPrices, priceGroupMember, startYear, endYear) => {
  if (priceGroupMember === undefined) return stableEmptyArrayAsMutable<HistoricalPrice>();

  const pricesFilteredByYear = historicalPrices.filter(hp => hp.priceYear >= startYear && hp.priceYear <= endYear && hp.priceGroupID === priceGroupMember.priceGroupID).sort((a, b) => a.priceYear - b.priceYear);
  return pricesFilteredByYear.length > 0 ? pricesFilteredByYear : stableEmptyArrayAsMutable<HistoricalPrice>();
});

export const selectHistoricalYieldTrendYears = createSelector([selectAllHistoricalYields,
  selectHistoricalYieldTrend,
  (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceId: string, startYear: number, endYear: number) => startYear,
  (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceId: string, startYear: number, endYear: number) => endYear,
], (historicalYields, historicalYieldTrend, startYear, endYear) => {
  if (historicalYieldTrend === undefined) return stableEmptyArrayAsMutable<HistoricalYieldTrendYear>();

  const historicalYieldsFilteredByYear = historicalYields.filter(hy => hy.year >= startYear && hy.year <= endYear && hy.historicalYieldTrendId === historicalYieldTrend.historicalYieldTrendId);
  return historicalYieldsFilteredByYear.length > 0 ? historicalYieldsFilteredByYear : stableEmptyArrayAsMutable<HistoricalYieldTrendYear>();
});

export const selectHistoricalInputCostsYears = createSelector([selectHistoricalInputCosts,
  (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceId: string, startYear: number, endYear: number) => startYear,
  (state: RootState, countyId: string, commodityCode: string, typeId: string, practiceId: string, startYear: number, endYear: number) => endYear,
], (historicalInputCosts, startYear, endYear) => {
  if (historicalInputCosts.length === 0) return stableEmptyArrayAsMutable<HistoricalInputCost>();

  const historicalYieldsFilteredByYear = historicalInputCosts.filter(hy => hy.reinsuranceYear >= startYear && hy.reinsuranceYear <= endYear);
  return historicalYieldsFilteredByYear.length > 0 ? historicalYieldsFilteredByYear : stableEmptyArrayAsMutable<HistoricalInputCost>();
});

export const selectHistoricalStormEvents = createSelector([
  selectAllHistoricalStormEvents,
  (_state: RootState, countyId: string, _commodityCode: string, _startYear: number, _endYear: number) => countyId,
  (_state: RootState, _countyId: string, commodityCode: string, _startYear: number, _endYear: number) => commodityCode,
  (_state: RootState, _countyId: string, _commodityCode: string, startYear: number, _endYear: number) => startYear,
  (_state: RootState, _countyId: string, _commodityCode: string, _startYear: number, endYear: number) => endYear,
], (historicalStormEvents, countyId, commodityCode, startYear, endYear) => {
  const historicalStormEventsMap = getKeyedStateToMap(historicalStormEvents);
  const countyCommodityHistoricalStormEvents = getItemsForId(historicalStormEventsMap, buildCountyAndCommodityKey(countyId, commodityCode));
  const historicalStormEventsFilteredByYear = countyCommodityHistoricalStormEvents.filter(hse => hse.year >= startYear && hse.year <= endYear);
  const orderedStormEvents = orderByProperty(historicalStormEventsFilteredByYear, DefaultOrders.historicalStormEvents);
  return orderedStormEvents.length > 0 ? orderedStormEvents : stableEmptyArrayAsMutable<HistoricalStormEvent>();
});

export const selectAvailableOptionsForQuote = createSelector([
  selectAllAvailableOptions,
  (_state: RootState, countyId: string, _commodityCode: string) => countyId,
  (_state: RootState, _countyId: string, commodityCode: string) => commodityCode,
], (allAvailableOptions, countyId, commodityCode) => {
  const allOptionsMap = getKeyedStateToMap(allAvailableOptions);
  const countyCommodityAvailableOptions = getItemsForId(allOptionsMap, buildCountyAndCommodityKey(countyId, commodityCode));
  return countyCommodityAvailableOptions.length > 0 ? countyCommodityAvailableOptions : stableEmptyArrayAsMutable<AvailableOptionSelections>();
});

//#endregion

export const fetchAdmData = createAppAsyncThunk('adm/fetchAdmData', async (_, thunkApi) => {
  return await getYearsRequest();
});

export const fetchCountiesWithOffers = createAppAsyncThunk('adm/fetchCountiesWithOffers', async ({ year, stateCode }: { year: number, stateCode: string }) => {
  return await getCountiesForStateRequest(year, stateCode);
});

export const fetchCommoditiesWithOffers = createAppAsyncThunk('adm/fetchCommoditiesWithOffers', async ({ countyId, year }: { countyId: string, year: number }) => {
  return await getCommoditiesForCountyRequest(year, countyId);
});

export const fetchCommodityTypesWithOffers = createAppAsyncThunk('adm/fetchCommodityTypesWithOffers', async ({ year, commodityCode, countyId }: { year: number, commodityCode: string, countyId: string }) => {
  return await getCropTypesForCommodityRequest(year, commodityCode, countyId);
});

export const fetchPracticesWithOffers = createAppAsyncThunk('adm/fetchPracticesWithOffers', async ({ year, commodityCode, countyId, typeId }: { year: number, commodityCode: string, countyId: string, typeId: string }) => {
  return await getPracticesForCommodityRequest(year, commodityCode, countyId, typeId);
});

export const fetchAvailableInsuranceOfferSelectionsForPlan = createAppAsyncThunk('adm/fetchAvailableInsuranceOfferSelectionsForPlan', async ({ year, countyId, typeId, practiceId, scenarioPieceType, coverageTypeCode, highRiskTypeId }: { year: number, countyId: string, typeId: string, practiceId: string, scenarioPieceType: ScenarioPieceType, coverageTypeCode: CoverageTypeCode, highRiskTypeId: HighRiskType }) => {
  const insurancePlanCode = getInsurancePlanCodeForScenarioPiece(scenarioPieceType);
  if (insurancePlanCode === '') return null;
  const insuranceOfferParams: InsuranceOfferParams = { year, countyId, typeId, practiceId, insurancePlanCode, coverageTypeCode, highRiskTypeId };
  return await getAvailableInsuranceOfferSelectionsRequest(insuranceOfferParams);
});

export const fetchScenarioPricesAndYields = createAppAsyncThunk('adm/fetchScenarioPricesAndYields',
  async ({ year, countyId, typeId, practiceId, planCodes }:
    { year: number, countyId: string, typeId: string, practiceId: string, planCodes: string[] }) => {
    const scenarioPricesAndYields = await getScenarioPricesAndYieldsForAllApplicablePlanCodes({ year, countyId, typeId, practiceId, planCodes });

    return scenarioPricesAndYields;
  });

export const fetchAllScenarioPricesAndYieldsPriorYear = createAppAsyncThunk('adm/fetchAllScenarioPricesAndYieldsPriorYear',
  async ({ scenarioIds }:
    { scenarioIds: ScenarioId[] }, thunkApi) => {
    const state = thunkApi.getState();
    const scenarioPricesAndYields: ScenarioPricesAndYieldsWithScenario[] = [];

    for (const scenarioId of scenarioIds) {
      const scenario = selectScenarioById(state, scenarioId);
      if (scenario === null) throw new MissingScenarioInStateError(scenarioId);
      const quote = selectQuoteById(state, scenario.quoteId);
      if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);
      const clientFile = selectClientFileById(state, quote.clientFileId);
      if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);
      const insurancePlanCodes = selectRowCropScenarioPiecePlanCodes(state, scenario);
      if (scenario.practiceId !== null) {
        const scenarioPricesAndYieldsPriorYear = await getScenarioPricesAndYieldsForAllApplicablePlanCodes({ year: clientFile.year - 1, countyId: quote.countyId, planCodes: insurancePlanCodes, practiceId: scenario.practiceId, typeId: scenario.typeId });
        if (scenarioPricesAndYieldsPriorYear !== null) {
          scenarioPricesAndYields.push({ scenarioId: scenarioId, scenarioPricesAndYields: scenarioPricesAndYieldsPriorYear });
        }
      }
    }
    return scenarioPricesAndYields;
  });

export const fetchCountyYieldInfo = createAppAsyncThunk('adm/fetchCountyYieldInfo',
  async ({ scenarioIds }:
    { scenarioIds: ScenarioId[] }, thunkApi) => {
    const state = thunkApi.getState();
    const countyYieldInfo: CountyYieldInfo[] = [];
    for (const scenarioId of scenarioIds) {
      const scenario = selectScenarioById(state, scenarioId);
      if (scenario === null) throw new MissingScenarioInStateError(scenarioId);
      const quote = selectQuoteById(state, scenario.quoteId);
      if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);
      const clientFile = selectClientFileById(state, quote.clientFileId);
      if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);
      const insurancePlanCodes = selectRowCropScenarioPiecePlanCodes(state, scenario);
      const applicableInsurancePlanCodes = getAllApplicablePlanCodes(scenario.typeId, insurancePlanCodes);
      if (scenario.practiceId !== null) {
        const yieldInfoWithCountiesParamsCurrentYear: YieldInfoWithCountiesParams = { year: clientFile.year, countyId: quote.countyId, typeId: scenario.typeId, practiceId: scenario.practiceId, planCodes: applicableInsurancePlanCodes, coverageTypeCode: CoverageTypeCode.A };
        const yieldInfoWithCountiesParamsPriorYear: YieldInfoWithCountiesParams = { year: clientFile.year - 1, countyId: quote.countyId, typeId: scenario.typeId, practiceId: scenario.practiceId, planCodes: applicableInsurancePlanCodes, coverageTypeCode: CoverageTypeCode.A };
        const countyYieldInfoCurrentYear = await getCountyYieldInfoRequest(yieldInfoWithCountiesParamsCurrentYear);
        const countyYieldInfoPriorYear = await getCountyYieldInfoRequest(yieldInfoWithCountiesParamsPriorYear);
        if (countyYieldInfoCurrentYear !== null && countyYieldInfoPriorYear !== null) {
          countyYieldInfo.push({ scenarioId: scenarioId, yieldInfoWithCountiesCurrentYear: countyYieldInfoCurrentYear, yieldInfoWithCountiesPriorYear: countyYieldInfoPriorYear });
        }
      }

    }
    return countyYieldInfo;
  });

export const fetchYieldInfoWithCounties = createAppAsyncThunk('adm/fetchYieldInfoWithCounties',
  async ({ year, countyId, typeId, practiceId, planCodes, coverageTypeCode }:
    { year: number, countyId: string, typeId: string, practiceId: string, planCodes: string[], coverageTypeCode: CoverageTypeCode }) => {
    const insurancePlanCodes = getAllApplicablePlanCodes(typeId, planCodes);

    const params: YieldInfoWithCountiesParams = { year, countyId, typeId, practiceId, planCodes: insurancePlanCodes, coverageTypeCode };
    return await getCountyYieldInfoRequest(params);
  });

export const fetchAvailableSubCountyCodes = createAppAsyncThunk('adm/fetchAvailableSubCountyCodes',
  async ({ year, countyId, typeId, practiceId }: { year: number, countyId: string, typeId: string, practiceId: string }) => {
    return await getAvailableSubCountyCodesRequest(year, countyId, typeId, practiceId);
  });

export const fetchAvailableInsurancePlanCodes = createAppAsyncThunk('adm/fetchAvailableInsurancePlanCodes',
  async ({ year, countyId, typeId, practiceId }: { year: number, countyId: string, typeId: string, practiceId: string }) => {
    return await getAvailableInsurancePlanCodesRequest(year, countyId, typeId, practiceId);
  });

export const fetchTYields = createAppAsyncThunk('adm/fetchTYields', async ({ year, countyId, commodityCode }: { year: number, countyId: string, commodityCode: string }) => {
  return await getTYieldsRequest(year, countyId, commodityCode);
});

export const fetchHistoricalTYields = createAppAsyncThunk('adm/fetchHistoricalTYields', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => {
  return await getAvailableHistoricalTYieldsRequest(quoteData);
});

export const fetchInsuranceCalendars = createAppAsyncThunk('adm/fetchInsuranceCalendars', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => {
  return await getInsuranceCalendarsRequest(quoteData);
});

export const fetchInsuranceOfferInformationIfOutOfSync = createAppAsyncThunk('adm/fetchInsuranceOfferInformationIfOutOfSync', async (data: GetInsuranceOfferParams, thunkApi) => {
  const state = thunkApi.getState();

  const areParamsCurrent = isEqual(data, state.adm.currentInsuranceOffer.params);
  if (areParamsCurrent) { return; }

  // Now we know new params have been passed.

  // Do not interrupt a request that is already in progress.
  const isRequestLoading = state.adm.currentInsuranceOffer.isLoading;
  if (isRequestLoading) { return; }

  // Set the params and start the data fetch for the params.
  thunkApi.dispatch(admSlice.actions.setCurrentInsuranceOfferParams(data));
  await thunkApi.dispatch(fetchInsuranceOfferInformation(data));
});

export const fetchInsuranceOfferInformation = createAppAsyncThunk('adm/fetchInsuranceOfferInformation', async (data: GetInsuranceOfferParams) => {
  // ToDo: Offline
  const apiResult = (await getInsuranceOfferInformation(data)).data;

  return {
    data: apiResult,
    params: data,
  };
});

export const fetchYeYears = createAppAsyncThunk('adm/fetchYeYears', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => {
  return await getAvailableYeYearsRequest(quoteData);
});

export const fetchTrendAdjustmentFactors = createAppAsyncThunk('adm/fetchTrendAdjustmentFactors', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => {
  return await getTrendAdjustmentFactorsRequest(quoteData);
});

const fetchHistoricalPrices = createAppAsyncThunk('adm/fetchHistoricalPrices', async ({ year, priceGroupIds }: { year: number, priceGroupIds: number[] }) => {
  return await getHistoricalPricesRequest(year, priceGroupIds);
});

const fetchHistoricalInputCosts = createAppAsyncThunk('adm/fetchHistoricalInputCosts', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => {
  return await getHistoricalInputCostsRequest(quoteData);
});

const fetchPriceGroupMembers = createAppAsyncThunk('adm/fetchPriceGroupMembers', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => {
  return await getPriceGroupMembersRequest(quoteData);
});

const fetchPriceGroups = createAppAsyncThunk('adm/fetchPriceGroups', async ({ year, priceGroupIds }: { year: number, priceGroupIds: PriceGroupId[] }) => {
  return await getPriceGroupsRequest(year, priceGroupIds);
});

const fetchPriceDiscoveries = createAppAsyncThunk('adm/fetchPriceDiscoveries', async ({ year, commodityCodes }: { year: number, commodityCodes: string[] }) => {
  return await getPriceDiscoveriesRequest(year, commodityCodes);
});

export const fetchCeppMappings = createAppAsyncThunk('adm/fetchCeppMappings', async () => {
  return await getCeppMappingsRequest();
});

export const fetchMyaPriceHistoriesRequest = createAppAsyncThunk('adm/fetchMyaPriceHistoriesRequest', async ({ year }: { year: number }) => {
  return await getMyaPriceHistoriesRequest(year);
});

export const fetchPriceGroupMembersAndPriceHistory = createAppAsyncThunk('adm/fetchPriceGroupMembersAndPriceHistory', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }, thunkApi) => {
  await thunkApi.dispatch(fetchPriceGroupMembers({ quoteData }));
  const state1 = thunkApi.getState();

  //We should only ever be getting data for quotes in the same client file
  // This means we should only need to worry about a single year value since they'll all be the same
  const firstQuoteData = quoteData.at(0);
  if (firstQuoteData === undefined) {
    return;
  }

  const year = firstQuoteData.year;

  const priceGroupMembers = getKeyedStateValues(selectAllPriceGroupMembers(state1)).flatMap(pgm => pgm);
  const priceGroupIds = [...new Set(priceGroupMembers.map(x => x.priceGroupID))];
  await thunkApi.dispatch(fetchPriceGroups({ year: year, priceGroupIds: priceGroupIds }));
  const state2 = thunkApi.getState();
  const commodityCodes = [...new Set(selectPriceGroups(state2).map(x => x.commodityCode))];

  const promisesToAwait: Promise<unknown>[] = [
    thunkApi.dispatch(fetchPriceDiscoveries({ year: year, commodityCodes: commodityCodes })),
    thunkApi.dispatch(fetchHistoricalPrices({ year, priceGroupIds: priceGroupIds.map(x => Number(x)) })),
    thunkApi.dispatch(fetchHistoricalInputCosts({ quoteData: quoteData })),
  ];

  await Promise.all(promisesToAwait);
});

const fetchHistoricalYieldTrends = createAppAsyncThunk('adm/fetchHistoricalYieldTrends', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => {
  return await getHistoricalYieldTrendsRequest(quoteData);
});

const fetchHistoricalYieldTrendYears = createAppAsyncThunk('adm/fetchHistoricalYieldTrendYears', async ({ year, historicalYieldTrendIds }: { year: number, historicalYieldTrendIds: number[] }) => {
  return await getHistoricalYieldTrendYearsRequest(year, historicalYieldTrendIds);
});

export const fetchHistoricalYieldTrendsAndHistoricalYieldTrendYears = createAppAsyncThunk('adm/fetchHistoricalYieldTrendsAndHistoricalYieldTrendYears', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }, thunkApi) => {
  await thunkApi.dispatch(fetchHistoricalYieldTrends({ quoteData }));

  const state = thunkApi.getState();

  const historicalYieldTrends = getKeyedStateValues(selectHistoricalYieldTrends(state)).flatMap(pgm => pgm);
  const historicalYieldTrendIds = [...new Set(distinctBy(historicalYieldTrends.map(hyt => hyt.historicalYieldTrendId), x => x))];
  const distinctYears = distinctBy(quoteData.map(qd => qd.year), x => x);

  const fetchPromises = distinctYears.map(year => thunkApi.dispatch(fetchHistoricalYieldTrendYears({ year, historicalYieldTrendIds })));
  await Promise.all(fetchPromises);
});

export const fetchHistoricalStormEvents = createAppAsyncThunk('adm/fetchHistoricalStormEvents', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => {
  return await getHistoricalStormEventsRequest(quoteData);
});

export const fetchAvailableOptionSelections = createAppAsyncThunk('adm/fetchAvailableOptionSelections', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => {
  return await getAvailableOptionSelectionsRequest(quoteData);
});

export const fetchPreDownloadedCounties = createAppAsyncThunk('adm/fetchPreDownloadedCounties', async () => {
  return await getPreDownloadedCountiesRequest();
});

export const fetchHipHurricaneEvent = createAppAsyncThunk('adm/fetchHipHurricaneEvent', async ({ year, countyId, typeId, practiceId, insurancePlanCode }: { year: number, countyId: string, typeId: string, practiceId: string, insurancePlanCode: string }) => {
  return await getHipHurricaneEventRequest(year, countyId, typeId, practiceId, insurancePlanCode);
});

export default admSlice.reducer;
