import { Box, Grid, Typography, useTheme } from '@mui/material';
import {
  roundToPlaces,
  ScenarioPieceGroupResponseDTO,
  ScenarioPieceResponseDTO,
  ScenarioPieceType,
  ScenarioResponseDTO
} from '@silveus/calculations';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import { Nullable } from '../../../types/util/Nullable';

type ScenarioSummaryTooltipProps = {
  calcResults: Nullable<ScenarioResponseDTO>;
}

const calculateValueForScenarioPieceGroups = (
  scenarioPieceGroups: ScenarioPieceGroupResponseDTO[],
  desiredScenarioPieceTypes: ScenarioPieceType[],
  valueMapper: (sp: ScenarioPieceResponseDTO) => number,
) => scenarioPieceGroups
  .flatMap(spg => spg.scenarioPieces)
  .filter(sp => desiredScenarioPieceTypes.includes(sp.scenarioPieceType))
  .map(valueMapper)
  .reduce((prev, curr) => prev + curr, 0);


const ScenarioSummaryTooltip = ({ calcResults }: ScenarioSummaryTooltipProps) => {
  const theme = useTheme();
  if (calcResults === null) return null;
  const subsidyPercent = calcResults.totalPremium !== 0 ? roundToPlaces(calcResults.subsidyAmount / calcResults.totalPremium * 100, 1) : 0;
  const modifiedProducerPremiumPerAcre = -calcResults.premiumPerAcreAmount - calcResults.discountPerAcre;
  const modifiedProducerPremium = -calcResults.premiumAmount - calcResults.discount;
  const modifiedSubsidyAmountPerAcre = calcResults.totalPremiumPerAcre - calcResults.premiumPerAcreAmount;
  const modifiedSubsidyAmount = calcResults.totalPremium - calcResults.premiumAmount;

  const physicalCostPerAcre = calculateValueForScenarioPieceGroups(calcResults.scenarioPieceGroups, [ScenarioPieceType.InputCosts], sp => -sp.fixedPerAcre);
  const physicalRevPerAcre = calculateValueForScenarioPieceGroups(calcResults.scenarioPieceGroups, [ScenarioPieceType.ForwardSold, ScenarioPieceType.HarvestRevenue], sp => -sp.fixedPerAcre + sp.simulatedPerAcre);
  const totalPhysicalCost = calculateValueForScenarioPieceGroups(calcResults.scenarioPieceGroups, [ScenarioPieceType.InputCosts], sp => -sp.totalFixed);
  const totalPhysicalRev = calculateValueForScenarioPieceGroups(calcResults.scenarioPieceGroups, [ScenarioPieceType.ForwardSold, ScenarioPieceType.HarvestRevenue], sp => -sp.totalFixed + sp.totalSimulated);
  const physicalNetPerAcre = physicalRevPerAcre + physicalCostPerAcre;
  const totalPhysicalNet = totalPhysicalRev + totalPhysicalCost;

  return (
    <Box p={2} pt={1} sx={{ width: '420px', border: `2px solid ${theme.palette.grey[400]}` }}>
      <Grid container item xs={12} sx={{ justifyContent: 'flex-end', height: '16px' }}>
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          <Typography variant="subtitle2">Per Acre</Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <Typography variant="subtitle2">Total</Typography>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={5}>
          <Typography variant="subtitle2" sx={{ textTransform: 'none' }}>$ of Coverage</Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          <CurrencyText currencyAmount={calcResults.protectionAmountPerAcre} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <CurrencyText currencyAmount={calcResults.protectionAmount} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={5}>
          <Typography variant="body2">Unsubsidized Premium</Typography>
        </Grid>
        <Grid xs={3}>
          <CurrencyText currencyAmount={calcResults.totalPremiumPerAcre} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>
        <Grid xs={4}>
          <CurrencyText currencyAmount={calcResults.totalPremium} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>

        <Grid item xs={5} >
          <Typography variant="body2">{`Subsidy (${subsidyPercent}%)`}</Typography>
        </Grid>
        <Grid xs={3}>
          <CurrencyText currencyAmount={modifiedSubsidyAmountPerAcre} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>
        <Grid xs={4}>
          <CurrencyText currencyAmount={modifiedSubsidyAmount} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>

        <Grid item xs={5} mt="2px">
          <Typography variant="subtitle2" sx={{ textTransform: 'none' }}>Producer Premium</Typography>
        </Grid>
        <Grid xs={3} mt="2px" sx={{ borderTop: '1px solid #666666' }}>
          <CurrencyText currencyAmount={modifiedProducerPremiumPerAcre} colorize align={'right'} />
        </Grid>
        <Grid xs={4} mt="2px" sx={{ borderTop: '1px solid #666666' }}>
          <CurrencyText currencyAmount={modifiedProducerPremium} colorize align={'right'} />
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={5}>
          <Typography variant="body2">Phys Cost</Typography>
        </Grid>
        <Grid xs={3}>
          <CurrencyText currencyAmount={physicalCostPerAcre} colorize align={'right'} />
        </Grid>
        <Grid xs={4}>
          <CurrencyText currencyAmount={totalPhysicalCost} colorize align={'right'} />
        </Grid>

        <Grid item xs={5} >
          <Typography variant="body2">Phys Revenue</Typography>
        </Grid>
        <Grid xs={3}>
          <CurrencyText currencyAmount={physicalRevPerAcre} colorize align={'right'} />
        </Grid>
        <Grid xs={4}>
          <CurrencyText currencyAmount={totalPhysicalRev} colorize align={'right'} />
        </Grid>

        <Grid item xs={5} mt="2px">
          <Typography variant="subtitle2" sx={{ textTransform: 'none' }}>Phys Net</Typography>
        </Grid>
        <Grid xs={3} mt="2px" sx={{ borderTop: '1px solid #666666' }}>
          <CurrencyText currencyAmount={physicalNetPerAcre} colorize align={'right'} />
        </Grid>
        <Grid xs={4} mt="2px" sx={{ borderTop: '1px solid #666666' }}>
          <CurrencyText currencyAmount={totalPhysicalNet} colorize align={'right'} />
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={5} >
          <Typography variant="body2">Phys Net</Typography>
        </Grid>
        <Grid xs={3}>
          <CurrencyText currencyAmount={physicalNetPerAcre} colorize align={'right'} />
        </Grid>
        <Grid xs={4}>
          <CurrencyText currencyAmount={totalPhysicalNet} colorize align={'right'} />
        </Grid>

        <Grid item xs={5}>
          <Typography variant="body2">Indemnity</Typography>
        </Grid>
        <Grid xs={3}>
          <CurrencyText currencyAmount={calcResults.indemnityPerAcreAmount} colorize align={'right'} />
        </Grid>
        <Grid xs={4}>
          <CurrencyText currencyAmount={calcResults.indemnityAmount} colorize align={'right'} />
        </Grid>

        <Grid item xs={5} >
          <Typography variant="body2">Producer Premium</Typography>
        </Grid>
        <Grid xs={3}>
          <CurrencyText currencyAmount={modifiedProducerPremiumPerAcre} colorize align={'right'} />
        </Grid>
        <Grid xs={4}>
          <CurrencyText currencyAmount={modifiedProducerPremium} colorize align={'right'} />
        </Grid>

        <Grid item xs={5} mt="2px">
          <Typography variant="subtitle2" sx={{ textTransform: 'none' }}>Net</Typography>
        </Grid>
        <Grid xs={3} mt="2px" sx={{ borderTop: `1px solid ${theme.palette.altText.grey}` }}>
          <CurrencyText currencyAmount={calcResults.indemnityPerAcreAmount - calcResults.premiumPerAcreAmount + physicalNetPerAcre} colorize align={'right'} />
        </Grid>
        <Grid xs={4} mt="2px" sx={{ borderTop: `1px solid ${theme.palette.altText.grey}` }}>
          <CurrencyText currencyAmount={calcResults.indemnityAmount - calcResults.premiumAmount + totalPhysicalNet} colorize align={'right'} />
        </Grid>
      </Grid>


    </Box >
  );
};

export default ScenarioSummaryTooltip;