import { useAppSelector } from './reduxHooks';
import { isNullOrUndefined } from '../utils/nullHandling';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import { selectHistoricalInputCostsYears } from '../app/admSlice';
import { selectScenarioById } from '../app/scenariosSlice';
import { selectQuoteById } from '../app/quotesSlice';
import { ScenarioId } from '../types/api/PrimaryKeys';
import { Nullable } from '../types/util/Nullable';
import HistoricalInputCost from '../types/api/adm/HistoricalInputCost';

export const useHistoricalInputCosts = (scenarioId: Nullable<ScenarioId>, startYear: number, endYear: number) => {
  const scenario = useAppSelector(state => scenarioId === null ? null : selectScenarioById(state, scenarioId));
  const quote = useAppSelector(state => scenario === null ? null : selectQuoteById(state, scenario.quoteId));
  const isScenarioOrQuoteInvalid = isNullOrUndefined(scenario) || isNullOrUndefined(quote);

  const historicalInputCostsYears = useAppSelector(state => isScenarioOrQuoteInvalid || isNullOrUndefined(scenario.practiceId)
    ? stableEmptyArrayAsMutable<HistoricalInputCost>()
    : selectHistoricalInputCostsYears(state, quote.countyId, quote.commodityCode, scenario.typeId, scenario.practiceId, startYear, endYear));

  return historicalInputCostsYears;
};