import { getFarmBillArcYieldHistories } from '../../privateProducts.service';
import AdmDataForQuoteParams from '../../../types/api/adm/AdmDataForQuoteParams';
import QuoteArcYieldHistories from '../../../types/api/adm/QuoteArcYieldHistories';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import { privateProductsDb } from '../../../db';
import { buildCountyAndCommodityKey } from '../../../types/app/CompositeKeys';
import { safeWhere } from '../../../utils/dexieQueryHelpers/whereClauses';

export const getFarmBillArcYieldHistoriesRequest = async (params: AdmDataForQuoteParams[]): Promise<QuoteArcYieldHistories[]> => {
  const request = () => getFarmBillArcYieldHistories(params);

  const transactionTables = [privateProductsDb.arcYieldHistories];

  const readTransaction = () => privateProductsDb.transaction('r', transactionTables, async () => {
    const results: QuoteArcYieldHistories[] = [];
    for (const quoteParam of params) {
      const quoteData = await safeWhere(privateProductsDb.arcYieldHistories,
        {
          countyID: quoteParam.countyId,
          commodityCode: quoteParam.commodityCode,
          cropYear: quoteParam.year,
        }).toArray();

      const historicalYieldTrendYears: QuoteArcYieldHistories = {
        countyCommodityKey: buildCountyAndCommodityKey(quoteParam.countyId, quoteParam.commodityCode),
        arcYieldHistories: quoteData,
      };
      results.push(historicalYieldTrendYears);
    }

    return results;
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};
