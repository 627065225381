import { Tab, Tabs } from '@mui/material';
import { LoadFromStateProps } from './loadFromStateProps';
import { FormInputProps } from './formInputProps';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { getAvailableIntervalsForScenarioPieceType } from '../../../app/intervalsSlice';
import { ScenarioPieceType } from '@silveus/calculations';
import { IntervalType, IntervalTypeAttributes } from '../../../types/api/adm/IntervalRange';

interface Props extends LoadFromStateProps, FormInputProps {
  scenarioPieceType: ScenarioPieceType,
  selectedIntervalType: IntervalType,
  setSelectedIntervalType: (intervalType: IntervalType) => void;
}

const IntervalTabs = ({ scenarioPieceType, selectedIntervalType, setSelectedIntervalType }: Props) => {
  const availableIntervals = useAppSelector(state => getAvailableIntervalsForScenarioPieceType(state, scenarioPieceType));

  return (
    <Tabs value={selectedIntervalType} onChange={(_, newIndex: IntervalType) => setSelectedIntervalType(newIndex)}>
      {Object.values(IntervalTypeAttributes)
        .filter(attr => availableIntervals.some(int => int.intervalType === attr.value))
        .map(attr => { return <Tab key={attr.value} label={attr.name} value={attr.value} />; })
      }
    </Tabs>
  );
};

export default IntervalTabs;
