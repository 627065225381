import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import {
  RowCropScenarioPieceUnderlyingValidationRule,
  missingUnderlyingScenarioPiece
} from '../validationRules/rowCropScenarioPieceValidationRules';
import { composeValidationResults } from '../validationHandling';
import { CombinedValidationResult } from '../validateScenario';
import { Nullable } from '../../../types/util/Nullable';

export function validateIce (scenarioPiece: RowCropScenarioPiece, underlyingScenarioPiece: Nullable<RowCropScenarioPiece>, allScenarioPieces: RowCropScenarioPiece[]): CombinedValidationResult<RowCropScenarioPiece> {
  if (underlyingScenarioPiece === null) {return missingUnderlyingScenarioPiece;}

  //TODO #61274: Address the possible ICE coverage level gap

  //No gap or overlap in coverage allowed, except for when SCO is elected. That should count as an upper coverage of 85, not 86 for this purpose.
  const validationRules: RowCropScenarioPieceUnderlyingValidationRule[] = [];

  const validationResults = validationRules.map(validationRule => validationRule(scenarioPiece, allScenarioPieces, underlyingScenarioPiece));
  return composeValidationResults(validationResults);
}