import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import { validateDesignatedCounty } from '../../../pages/applicationsModal/wizardSteps/appDecisionsPage/validations/appTaskValidator';
import { handleValidationResult } from './validationHelpers';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { AppTask } from '../../../types/api/AppTask';
import { useEffect } from 'react';
import { AppType } from '../../../types/api/enums/application/AppType.enum';
import { ProductType } from '../../../types/api/enums/application/productType.enum';
import { AppTaskCoverage } from '../../../types/api/appTaskCoverage';

type DesignatedCountyInputProps = {
  value: Nullable<boolean>;
  index: number;
  application: ApplicationWizard;
  canEditAppTask: boolean;
}

const DesignatedCountyInput = ({ value, index, application, canEditAppTask }: DesignatedCountyInputProps) => {
  const inputName = `appTasks.${index}.isDesignatedCounty`;
  const { control, setValue, resetField } = useFormContext();
  const allCoverages: AppTaskCoverage[] = useWatch({ name: `appTasks.${index}.coverages` });
  const currentAppTask: AppTask = useWatch({ name: `appTasks.${index}` });
  const shouldBeNullAndDisabled = currentAppTask.appType === AppType.HoldForCancelTransfer || currentAppTask.appType === AppType.Cancel;

  useEffect(() => {
    const isDesignatedCounty = currentAppTask.isDesignatedCounty ?? false;
    // If user sets app task to No, then we'll set all coverages for this app task to also be no
    if (!isDesignatedCounty) {
      setValue(`appTasks.${index}.designatedCountyElection`, null, { shouldValidate: true });
      for (let i = 0; i < allCoverages.length; i++) {
        setValue(`appTasks.${index}.coverages.${i}.isDesignatedCounty`, false, { shouldValidate: true });
      }
    }
  }, [currentAppTask.isDesignatedCounty]);

  useEffect(() => {
    if (shouldBeNullAndDisabled) {
      resetField(inputName, { defaultValue: null });
    }
  }, [shouldBeNullAndDisabled]);

  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={value}
      rules={{ validate: val => handleValidationResult(validateDesignatedCounty(val, application, currentAppTask)) }}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          fullWidth
          select
          disabled={!application.isGeneratingForms || currentAppTask.productType !== ProductType.MPCI || !canEditAppTask || shouldBeNullAndDisabled}
          error={!!error}
          inputRef={ref}
          size="small"
          inputProps={{
            sx: { fontSize: '12px' },
          }}
          onChange={e => field.onChange(e.target.value)}
        >
          { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <MenuItem value={true as any}>Yes</MenuItem>
          { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <MenuItem value={false as any}>No</MenuItem>
        </TextField>
      )}
    />
  );
};

export default DesignatedCountyInput;