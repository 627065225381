import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import { DrawerFormType } from '../../../app/appDrawerSlice';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { Grid } from '@mui/material';
import { modifyHistoricalColumnsForUser, selectHistoricalColumns } from '../../../app/userSettingsSlice';
import HistoricalColumnToggleInput from '../../../components/formInputs/historicalQuote/historicalColumnToggleInput';

interface HistoricalAnalysisColumnToggleFormProps extends FormWrapperProps {
}

type HistoricalQuoteFormFields = {
  plant: boolean;
  harvest: boolean;
  percentChange: boolean;
  expectedProducerYield: boolean;
  actualProducerYield: boolean;
  producerYieldPercentChange: boolean;
  expectedCountyYield: boolean;
  actualCountyYield: boolean;
  countyYieldPercentChange: boolean;
}

const formId: DrawerFormType = 'historicalQuoteColumnForm';

const columnDefinitions = [
  {
    colId: 'plant',
    label: 'Plant Price',
  },
  {
    colId: 'harvest',
    label: 'Harvest Price',
  },
  {
    colId: 'percentChange',
    label: 'Price % Change',
  },
  {
    colId: 'expectedProducerYield',
    label: 'Expected Producer Yield',
  },
  {
    colId: 'actualProducerYield',
    label: 'Actual Producer Yield',
  },
  {
    colId: 'producerYieldPercentChange',
    label: 'Producer Yield % Change',
  },
  {
    colId: 'expectedCountyYield',
    label: 'Expected County Yield',
  },
  {
    colId: 'actualCountyYield',
    label: 'Actual County Yield',
  },
  {
    colId: 'countyYieldPercentChange',
    label: 'County Yield % Change',
  },
];

const HistoricalAnalysisColumnToggleForm = ({ handleValidation, registerHeader, isCanceling = false }: HistoricalAnalysisColumnToggleFormProps) => {
  const dispatch = useAppDispatch();
  const historicalColumns = useAppSelector(selectHistoricalColumns);
  const methods = useForm<HistoricalQuoteFormFields>();


  const onSubmit: SubmitHandler<HistoricalQuoteFormFields> = data => {
    const keys = Object.keys(data);
    const columnsState = keys.map(key => {
      return {
        colId: key,
        hide: !data[key as keyof HistoricalQuoteFormFields],
      };
    });

    dispatch(modifyHistoricalColumnsForUser({ columns: columnsState }));
  };

  const handleReset = () => {
    methods.reset();
  };

  const shouldSubmit = methods.formState.isDirty;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit);
  const handleSubmit = useFormWrapper('Show/Hide', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit} onReset={handleReset}>
        <Grid container alignItems="center" p={2} rowSpacing={1} columnSpacing={1}>
          {columnDefinitions.map(c => (
            <Grid item xs={12} key={`column-${c.colId}`}>
              <HistoricalColumnToggleInput label={c.label} name={c.colId} defaultValue={!(historicalColumns.find(x => x.colId === c.colId)?.hide ?? false)} />
            </Grid>
          ))}
        </Grid>
      </form>
    </FormProvider>
  );
};

export default HistoricalAnalysisColumnToggleForm;
