import { ScenarioPieceGroupType, ScenarioPieceGroupTypeAttributes, ScenarioPieceResponseDTO, ScenarioPieceType } from '@silveus/calculations';
import { ScenarioId, ScenarioPieceId, UnitYearId } from '../../../types/api/PrimaryKeys';
import { getReportProductDataForQuickQuote } from './getReportProductDataForQuickQuote';
import { getReportProductDataForUnitQuote } from './getReportProductDataForUnitQuote';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { Quote } from '../../../types/api/Quote';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { ReportProductData } from '../../../types/api/reports/ReportProductData';
import { getItemsForId } from '../../../utils/mapHelpers';
import UnitYear from '../../../types/api/UnitYear';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';

export const getReportProductDataByUnitYear = (
  scenarioIds: ScenarioId[],
  excludedScenarioPieces: (ScenarioPieceType | ScenarioPieceGroupType)[],
  scenarioPieces: Map<ScenarioId, RowCropScenarioPiece[]>,
  scenarios: RowCropScenario[],
  quotes: Quote[],
  calculations: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  unitGroups: Map<ScenarioPieceId, UnitGroup[]>,
  unitsByScenarioId: Map<ScenarioId, UnitYear[]>,
): Map<UnitYearId, ReportProductData[]> => {
  const scenarioPiecesByUnitMap = new Map<UnitYearId, ReportProductData[]>();

  for (const scenarioId of scenarioIds) {
    const scenario = scenarios.find(scenario => scenario.scenarioId === scenarioId) ?? null;
    const quote = scenario === null ? null : quotes.find(quote => quote.quoteId === scenario.quoteId) ?? null;
    const unitsForScenario = unitsByScenarioId.get(scenarioId) ?? [];

    if (quote === null || scenario === null) continue;

    const scenarioPiecesForScenario = getItemsForId(scenarioPieces, scenarioId).filter(sp => {
      const scenarioPieceGroup = Object.values(ScenarioPieceGroupTypeAttributes).find(spg => spg.elements?.includes(sp.scenarioPieceType));
      if (isNotNullOrUndefined(scenarioPieceGroup) && !scenarioPieceGroup.useIndividualPieceName) {
        return !excludedScenarioPieces.includes(scenarioPieceGroup.value);
      }
      return !excludedScenarioPieces.includes(sp.scenarioPieceType);
    });

    for (const scenarioPiece of scenarioPiecesForScenario) {
      const data = quote.quickQuote && scenario.quickUnit !== null
        ? getReportProductDataForQuickQuote(scenarioPiecesByUnitMap, scenario.quickUnit, scenarioPiece, calculations)
        : getReportProductDataForUnitQuote(unitGroups, scenarioPiece, scenarioPiecesByUnitMap, calculations, unitsForScenario);

      for (const { unitYearId, reportProductData } of data) {
        scenarioPiecesByUnitMap.set(unitYearId, reportProductData);
      }
    }
  }

  return scenarioPiecesByUnitMap;
};