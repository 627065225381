import { ScenarioPiece } from '../../../types/api/ScenarioPiece';
import { RootState } from '../../../app/store';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import BaseUnit from '../../../types/api/BaseUnit';
import { ScenarioPieceRequestDTO } from '@silveus/calculations';
const createScenarioPieceDtoFunctionDefault = (
  scenarioPiece: ScenarioPiece,
  state: RootState,
  scenario: RowCropScenario,
  baseUnits: BaseUnit[],
  aphYield: number,
  shouldSimulateUnitGroups?: boolean): Promise<ScenarioPieceRequestDTO> => {
  throw new Error(`Scenario piece type ${scenarioPiece.scenarioPieceType} is currently not supported for calculations.`);
};

export default createScenarioPieceDtoFunctionDefault;