import { DialogTitle, Grid, useTheme } from '@mui/material';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectIsLightMode } from '../../app/userSettingsSlice';
import { ReactNode } from 'react';

type ModalHeaderProps = {
  headerContent: ReactNode;
  headerControls: ReactNode;
  headerBackgroundColor?: string;
}

const ModalHeader = ({ headerContent, headerControls, headerBackgroundColor }: ModalHeaderProps) => {
  const theme = useTheme();
  const isLightMode = useAppSelector(selectIsLightMode);

  const getBackgroundColor = () => {
    if (headerBackgroundColor !== undefined)
      return headerBackgroundColor;

    return isLightMode ? theme.palette.primary.main : theme.palette.neutral.dark;
  };

  return (
    <DialogTitle sx={{ backgroundColor: getBackgroundColor() }}>
      <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', color: theme => theme.palette.common.white }}>
        <Grid item xs>
          {headerContent}
        </Grid>
        <Grid item xs="auto">
          {headerControls}
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default ModalHeader;