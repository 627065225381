import { Na } from '../../types/util/Na';
import { Button, Grid } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { ConfirmStateContent, openConfirm } from '../../app/confirmSlice';
import { useGridStatePersistence } from '../../hooks/useGridStatePersistence';
import {
  AphModalProps,
  selectTrendAdjustmentFactorForScenario,
  selectTYieldForUnitYear
} from '../../app/unitsSlice';
import './unitsAphGrid.css';
import { useEffect, useState } from 'react';
import UnitYearAph from '../../types/api/UnitYearAph';
import {
  calculateAdjustedYieldForAphRow,
  calculateApplicableTYield,
  calculateApprovedYieldForAphRow,
  calculateRateYield,
  HistoricalTYield,
  OptionCode
} from '@silveus/calculations';
import { selectHistoricalTYieldsForCountyCommodity, selectTYield } from '../../app/admSlice';
import { selectScenarioById } from '../../app/scenariosSlice';
import { selectQuoteById } from '../../app/quotesSlice';
import { getNewAph, getUnitYearAphForAddedLand } from '../units/utils/aph';
import { selectIsLightMode, selectUnitAphModalColumns } from '../../app/userSettingsSlice';
import { YIELD_DESCRIPTORS } from '@silveus/calculations/';
import { CombinedAphScenarioAph } from './unitAphModal';
import { generatePrimaryKey } from '../../utils/primaryKeyHelpers';
import { selectBaseRowCropScenarioPieceByScenario } from '../../app/scenarioPiecesSlice';
import { generatePinnedBottomData } from './unitAphModalSummaryHelper';
import { YeStatusType } from '../../types/api/enums/optionStates/yeStatusType.enum';
import { defaultColDef, defaultColState, getUnitAphColumnDefinitions } from './unitAphColumnDefinitions';
import { RowClassParams, RowClassRules } from 'ag-grid-community';
import { stableEmptyArray, stableEmptyArrayAsMutable } from '../../utils/stableEmptyArray';
import {
  useGetYieldRoundingPrecisionForExistingScenario
} from '../../hooks/roundingPrecision/useGetYieldRoundingPrecision';
import { useScenarioYieldCalculator } from './useScenarioYieldCalculator';
import { UnitAphGridValue } from './unitAphGridValue';
import { useYeYears } from '../../hooks/useYeYears';
import { OptionSelectionState } from '../../types/app/enums/optionSelectionState.enum';

interface UnitGridProps {
  setIsInEditMode: (val: boolean) => void;
  aph: CombinedAphScenarioAph[];
  setAph: (value: CombinedAphScenarioAph[]) => void;
  modalProps: AphModalProps;
}

export const UnitAphGrid = ({ setIsInEditMode, aph, setAph, modalProps }: UnitGridProps) => {
  //#region Hooks

  const dispatch = useAppDispatch();
  const unitYear = modalProps.unitYear;
  const unitYearId = unitYear.unitYearId;
  const scenarioId = modalProps.scenarioId;

  //User settings
  const isLightMode = useAppSelector(selectIsLightMode);
  const unitColumns = useAppSelector(selectUnitAphModalColumns);

  //Quoting pre-reqs
  const scenario = useAppSelector(state => selectScenarioById(state, scenarioId));
  const quote = useAppSelector(state => scenario === null ? null : selectQuoteById(state, scenario.quoteId));
  const primaryScenarioPiece = useAppSelector(state => selectBaseRowCropScenarioPieceByScenario(state, scenarioId));

  const [aphGrid, setAphGrid] = useState<UnitAphGridValue[]>([]);

  //T-Yields
  const historicalTYields = useAppSelector(state => quote === null ? stableEmptyArrayAsMutable<HistoricalTYield>() :
    selectHistoricalTYieldsForCountyCommodity(state, quote.countyId, quote.commodityCode));

  const admTYield = useAppSelector(state => selectTYield(state, unitYear.typeId, unitYear.practiceId, unitYear.subCountyCode));
  const applicableTYield = calculateApplicableTYield(aph, admTYield?.transitionalYield ?? null);
  const trendAdjustmentFactor = useAppSelector(state => selectTrendAdjustmentFactorForScenario(state, scenarioId, quote?.countyId ?? '', quote?.commodityCode ?? ''));
  const currentYearTYield = useAppSelector(state => selectTYieldForUnitYear(state, unitYear.unitYearId));

  //YE
  const { getYeYearForCountyCommodity } = useYeYears();

  const yeYears = quote === null ? stableEmptyArray<number>() : getYeYearForCountyCommodity(quote.countyId, quote.commodityCode)
    .filterByTypePracticePlan(unitYear.typeId, unitYear.practiceId, primaryScenarioPiece?.planCode ?? null);

  //Yields
  const rateYield = calculateRateYield(aph);
  const yieldCalculator = useScenarioYieldCalculator(scenarioId);
  const applicableOptions = modalProps.unitYearOptions
    .filter(o => o.isAvailable && o.selectionState === OptionSelectionState.On).map(o => o.option.optionCode);
  const singleUnitCalculator = yieldCalculator.forSingleUnitYear(unitYear, aph, aph, applicableOptions);
  const adjustedYield = singleUnitCalculator.calculateAdjustedYield();
  const approvedYield = singleUnitCalculator.calculateApprovedYield();

  const yieldRoundingPrecision = useGetYieldRoundingPrecisionForExistingScenario({ scenario: scenario, quote: quote });

  const { onChange, gridApi, onGridReady } = useGridStatePersistence({
    defaultColumnState: defaultColState,
    columns: unitColumns,
    saveColumnState: () => { },
  });

  useEffect(() => {
    const filteredTYields = historicalTYields.filter(ty => ty.practiceId === unitYear.practiceId && ty.typeId === unitYear.typeId && ty.planCode === primaryScenarioPiece?.planCode);
    const selectedOptions = modalProps.unitYearOptions.filter(uyo => uyo.selectionState === OptionSelectionState.On);
    const calcAph = aph.map(aphRow => {
      const isYeApplicable = yeYears.includes(aphRow.year) && applicableOptions.some(o => o === OptionCode.YE);
      return { ...aphRow, excluded: isYeApplicable && aphRow.yeStatus === YeStatusType.NotOptedOut };
    });

    const newAphGrid: UnitAphGridValue[] = calcAph.map(calcAphRow => ({
      ...calcAphRow,
      adjustedYield: calculateAdjustedYieldForAphRow(calcAphRow, filteredTYields, selectedOptions.filter(o => o.isAvailable).map(o => o.option.optionCode)) ?? Na,
      approvedYield: calculateApprovedYieldForAphRow(unitYear, calcAphRow, calcAph, filteredTYields, selectedOptions.filter(o => o.isAvailable).map(o => o.option.optionCode), trendAdjustmentFactor, currentYearTYield) ?? Na,
    }));

    setAphGrid(newAphGrid);
  }, [aph, historicalTYields, modalProps.unitYearOptions, unitYear, trendAdjustmentFactor, currentYearTYield, primaryScenarioPiece]);

  useEffect(() => {
    if (gridApi) {

      const bottomRow = generatePinnedBottomData(rateYield, adjustedYield, approvedYield, gridApi);
      gridApi.setGridOption('pinnedBottomRowData', [bottomRow]);
    }
  }, [rateYield, adjustedYield, approvedYield, gridApi]);

  //#endregion

  //#region Local functions

  const latestAvailableYear = unitYear.year - 1;
  const defaultAcres = unitYear.acres;

  const onAddYearsNewUnit = (yearsToAdd: number): void => {
    let newAphs: UnitYearAph[] = [];
    const earliestYear = latestAvailableYear - yearsToAdd + 1;
    for (let year = latestAvailableYear; year >= earliestYear; year--) {
      const newAph = getNewAph(unitYearId, defaultAcres, year, YIELD_DESCRIPTORS.A, applicableTYield);
      if (newAph !== null) newAphs.push(newAph);
    }
    //after getting the new UnitYear results we need to add scenarioUnitYearAph properties to it
    const combinedScenarioAph: CombinedAphScenarioAph[] = newAphs.map(row => ({
      ...row,
      ...{
        scenarioUnitYearAphId: generatePrimaryKey(),
        scenarioId: scenarioId,
        yeStatus: YeStatusType.NotOptedOut,
        production: row.production ?? null,
      },
    }));
    setAph(combinedScenarioAph);
  };

  const onAddYearsAddedLand = (): void => {
    const newAphs = getUnitYearAphForAddedLand(latestAvailableYear, unitYearId, defaultAcres, applicableTYield);
    //after getting the new UnitYear results we need to add scenarioUnitYearAph properties to it
    const combinedScenarioAph: CombinedAphScenarioAph[] = newAphs.map(row => ({
      ...row,
      ...{
        scenarioUnitYearAphId: generatePrimaryKey(),
        scenarioId: scenarioId,
        yeStatus: YeStatusType.NotOptedOut,
        production: row.production ?? null,
      },
    }));
    setAph(combinedScenarioAph);
  };

  const updateAphRow = (newAph: UnitAphGridValue) => {
    const newAphHistory = aph.filter(history => history.unitYearAphId !== newAph.unitYearAphId);

    const { adjustedYield, approvedYield, ...remainingValues } = newAph;
    newAphHistory.push(remainingValues);

    setAph(newAphHistory);
  };

  const deleteRow = (unitYearAph: UnitAphGridValue) => {
    const confirmWindow: ConfirmStateContent = {
      title: 'Delete row?',
      message: 'Are you sure you want to delete this row? Doing so will delete it from all scenarios.',
      confirmText: 'Delete',
      onConfirm: () => {
        const tempAph = aph.filter(aphRow => aphRow.unitYearAphId !== unitYearAph.unitYearAphId);
        setAph(tempAph);
      },
    };
    dispatch(openConfirm(confirmWindow));
  };

  //#endregion

  const columnDefinitions = quote === null ? [] : getUnitAphColumnDefinitions({
    deleteRow,
    yeYears,
    updateAphRow,
    aph,
    scenarioId,
    setAph,
    quote: quote,
    unitYear,
    applicableTYield: applicableTYield ?? 0,
    yieldRoundingPrecision: yieldRoundingPrecision,
  });

  const rowClassRules: RowClassRules<UnitAphGridValue> = {
    'yeLightThemeRow': (params: RowClassParams<UnitAphGridValue>) => {
      return isLightMode && isYeYear(params.data?.year ?? 0);
    },
    'yeDarkThemeRow': (params: RowClassParams<UnitAphGridValue>) => {
      return !isLightMode && isYeYear(params.data?.year ?? 0);
    },
  };

  const isYeYear = (year: number) => {
    return modalProps.unitYearOptions
      .some(uyo => uyo.selectionState === OptionSelectionState.On && uyo.option.optionCode === OptionCode.YE) &&
      yeYears.includes(year);
  };

  return (
    <Grid container direction="column" style={{ width: '100%', height: '100%' }} rowGap={1} rowSpacing={1}>
      <Grid item xs className={isLightMode ? 'ag-theme-quartz units-grid' : 'ag-theme-quartz-dark units-grid'}>
        <AgGridReact<UnitAphGridValue>
          onGridReady={onGridReady}
          rowData={aphGrid}
          columnDefs={columnDefinitions}
          defaultColDef={defaultColDef}
          onCellEditingStarted={() => setIsInEditMode(true)}
          onCellEditingStopped={() => setIsInEditMode(false)}
          onSortChanged={onChange}
          onDragStopped={onChange}
          maintainColumnOrder={true}
          gridOptions={{
            enterNavigatesVertically: true,
            enterNavigatesVerticallyAfterEdit: true,
            rowClassRules: rowClassRules,
            stopEditingWhenCellsLoseFocus: true,
            sortingOrder: ['desc', 'asc', null],
          }}
          autoSizeStrategy={{ type: 'fitGridWidth' }}
        />
      </Grid>
      <Grid container item xs="auto" columnSpacing={2} justifyContent={'center'}>
        {aph.length === 0 &&
          <>
            <Grid item>
              <Button id="btn-add-ten-years" variant="contained" onClick={() => onAddYearsNewUnit(10)}>Add 10 Years</Button>
            </Grid>
            <Grid item>
              <Button id="btn-add-four-years" variant="contained" onClick={() => onAddYearsNewUnit(4)}>Add 4 Years</Button>
            </Grid>
            <Grid item>
              <Button id="btn-added-land" variant="contained" onClick={onAddYearsAddedLand}>Added Land</Button>
            </Grid>
          </>
        }
      </Grid>
    </Grid>
  );
};
