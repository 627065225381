import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { AgentTeamOwnedClientFile, ClientFile, InsuredOwnedClientFile } from '../types/api/ClientFile';
import { Nullable } from '../types/util/Nullable';
import { AgentTeamId, ClientFileId, InsuredId } from '../types/api/PrimaryKeys';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { getStateIdsMatching, getTypeGuardedKeyedStateGroupedBy } from './sliceHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import {
  createClientFileRequest,
  deleteClientFileRequest,
  getClientFileRequest,
  getClientFilesForAgentTeamRequest,
  getClientFilesForInsuredRequest, updateClientFileRequest
} from '../services/requestInterception/clientFileRequestInterceptor';
import { getItemsForId, orderMap } from '../utils/mapHelpers';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import { isAgentTeamOwnedClientFile, isInsuredOwnedClientFile } from '../utils/clientFileUtils';
import { duplicateQuotes, selectAllQuotesByClientFileMap } from './quotesSlice';
import { loadAllDataForClientFile } from './routeDataThunks';
import { changeUnitOwnership, cloneClientFileUnitsIntoNewInsured } from './unitsSlice';
import { isNullOrUndefined } from '../utils/nullHandling';

export interface ClientFilesState {
  allClientFiles: SliceDataState<ClientFileId, ClientFile>;
  currentClientFileId: Nullable<ClientFileId>;
}

const initialState: ClientFilesState = {
  allClientFiles: initialSliceDataState(),
  currentClientFileId: null,
};

export const clientFilesSlice = createSlice({
  name: 'clientFiles',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allClientFiles, c => c.clientFileId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: addClientFile,
      affectedIds: arg => arg.clientFile.clientFileId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchClientFile,
      affectedIds: arg => arg.clientFileId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchClientFilesForInsured,
      affectedIds: (arg, state) => getStateIdsMatching(state.allClientFiles.data, s => s.insuredId === arg.insuredId, s => s.clientFileId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchClientFilesForAgentTeam,
      affectedIds: (arg, state) => getStateIdsMatching(state.allClientFiles.data, s => s.agentTeamId === arg.agentTeamId, s => s.clientFileId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'duplicating', thunk: copyClientFileWithoutChildren,
      affectedIds: arg => arg.clientFile.clientFileId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeClientFile,
      affectedIds: arg => arg.clientFile.clientFileId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyClientFile,
      affectedIds: arg => arg.clientFileId,
    });

    builder
      .addCase(setCurrentClientFile.fulfilled, (state: ClientFilesState, action: PayloadAction<Nullable<ClientFile>>) => {
        state.currentClientFileId = action.payload?.clientFileId ?? null;
      });
  },
});

// Memoized Selectors
const selectClientFileDictionary = (state: RootState) => state.clientFiles.allClientFiles.data;
export const selectAllClientFilesByInsuredMap = createSelector([selectClientFileDictionary], result => {
  const map = getTypeGuardedKeyedStateGroupedBy<InsuredId, ClientFile, InsuredOwnedClientFile>(result, cf => cf.insuredId, isInsuredOwnedClientFile);
  const ordered = orderMap(map, DefaultOrders.clientFiles);
  return ordered;
});

export const selectAllClientFilesByAgentTeamMap = createSelector([selectClientFileDictionary], result => {
  const map = getTypeGuardedKeyedStateGroupedBy<AgentTeamId, ClientFile, AgentTeamOwnedClientFile>(result, cf => cf.agentTeamId, isAgentTeamOwnedClientFile);
  const ordered = orderMap(map, DefaultOrders.clientFiles);
  return ordered;
});

// Non-Memoized Selectors
export const selectCurrentClientFileId = (state: RootState) => state.clientFiles.currentClientFileId;
export const selectCurrentClientFile = (state: RootState): Nullable<ClientFile> => state.clientFiles.currentClientFileId === null ? null : state.clientFiles.allClientFiles.data[state.clientFiles.currentClientFileId] ?? null;
export const selectClientFileById = (state: RootState, clientFileId: ClientFileId) => state.clientFiles.allClientFiles.data[clientFileId] ?? null;

// Thunks
export const setCurrentClientFile = createAppAsyncThunk('clientFiles/setCurrentClientFile', (clientFileId: Nullable<ClientFileId>, thunkApi) => {
  const state = thunkApi.getState();
  return isNullOrUndefined(clientFileId) ? null : selectClientFileById(state, clientFileId);
});

export const fetchClientFilesForInsured = createAppAsyncThunk('clientFiles/fetchClientFilesForInsured', async ({ insuredId }: { insuredId: InsuredId }, thunkApi) => {
  return await getClientFilesForInsuredRequest(insuredId);
});

export const fetchClientFilesForAgentTeam = createAppAsyncThunk('clientFiles/fetchClientFilesForAgentTeam', async ({ agentTeamId }: { agentTeamId: AgentTeamId }, thunkApi) => {
  return await getClientFilesForAgentTeamRequest(agentTeamId);
});

export const fetchClientFile = createAppAsyncThunk('clientFiles/fetchClientFile', async ({ clientFileId }: { clientFileId: ClientFileId }, thunkApi) => {
  return await getClientFileRequest(clientFileId);
});

export const removeClientFile = createAppAsyncThunk('clientFiles/removeClientFile', async ({ clientFile }: { clientFile: ClientFile }, thunkApi) => {
  await deleteClientFileRequest(clientFile.clientFileId);
  return clientFile;
});

export const addClientFile = createAppAsyncThunk('clientFiles/add-client-file', async ({ clientFile }: { clientFile: ClientFile }) => {
  await createClientFileRequest(clientFile);
  return clientFile;
});

type CopyClientFileSettings = {
  insuredId?: InsuredId;
  name?: string;
}

/** Copies a client file, accounting for none of its children. */
export const copyClientFileWithoutChildren = createAppAsyncThunk('clientFiles/copyClientFileWithoutChildren', async ({ clientFile, clientFileSettings }: { clientFile: ClientFile, clientFileSettings?: CopyClientFileSettings }) => {
  const newClientFile: ClientFile = {
    ...clientFile,
    name: clientFileSettings?.name ?? clientFile.name,
    clientFileId: generatePrimaryKey(),
  };

  if (clientFileSettings?.insuredId !== undefined) {
    newClientFile.insuredId = clientFileSettings.insuredId;
  }

  await createClientFileRequest(newClientFile);
  return newClientFile;
});

export const modifyClientFile = createAppAsyncThunk('clientFiles/modifyClientFile', async ({ clientFile, clientFileId }: { clientFileId: ClientFileId, clientFile: ClientFile }) => {
  const newClientFile: ClientFile = { ...clientFile, clientFileId };
  await updateClientFileRequest(clientFileId, newClientFile);
  return newClientFile;
});

/** Duplicates the provided client file, including all child data. */
export const duplicateClientFile = createAppAsyncThunk('clientFiles/duplicateClientFile', async ({ clientFile, clientFileSettings }: { clientFile: ClientFile, clientFileSettings?: CopyClientFileSettings }, thunkApi) => {
  const getRefreshedQuotesForClientFile = () => {
    const state = thunkApi.getState();

    // Make sure the quotes we need to copy are in memory.
    const quotesForOriginalClientFile = getItemsForId(selectAllQuotesByClientFileMap(state), clientFile.clientFileId);

    return quotesForOriginalClientFile;
  };

  // Duplicate the Client File Level
  const newClientFile = await thunkApi.dispatch(copyClientFileWithoutChildren({ clientFile, clientFileSettings })).unwrap();

  // Make sure the quotes we need to copy are in memory.
  let quotesForOriginalClientFile = getRefreshedQuotesForClientFile();

  if (quotesForOriginalClientFile.length === 0) {
    // This is resting on an assumption - that if we are trying to clone a client file that currently has no quotes we know about in memory,
    // chances are we haven't actually loaded that client file data yet. So we should load it now.
    await thunkApi.dispatch(loadAllDataForClientFile(clientFile.clientFileId));

    // Refresh the quotes, since state has been altered / refreshed above.
    quotesForOriginalClientFile = getRefreshedQuotesForClientFile();
  }

  // Duplicate the Quotes for the source Client File, sending down the new Client File Id.
  await thunkApi.dispatch(duplicateQuotes({ quotes: quotesForOriginalClientFile, newClientFileId: newClientFile.clientFileId }));

  return newClientFile;
});

export const duplicateClientFileIntoInsured = createAppAsyncThunk('clientFiles/duplicateClientFileIntoInsured', async ({ sourceClientFile, destinationInsuredId }: { sourceClientFile: ClientFile, destinationInsuredId: InsuredId }, thunkApi) => {
  const { insuredId: sourceInsuredId } = sourceClientFile;

  // Guard conditions
  if (sourceInsuredId === null) {
    throw new Error('Cannot duplicate a client file without an insured');
  }

  if (sourceInsuredId === destinationInsuredId) {
    throw new Error('Cannot duplicate a client file into the same insured');
  }

  // Preemptively load all data for the client file, and the destination insured.
  await thunkApi.dispatch(loadAllDataForClientFile(sourceClientFile.clientFileId));

  // 1) Duplicate the Client File Level
  const newClientFile = await thunkApi.dispatch(duplicateClientFile({ clientFile: sourceClientFile, clientFileSettings: { insuredId: destinationInsuredId } })).unwrap();

  // 2) Clone the global units
  await thunkApi.dispatch(cloneClientFileUnitsIntoNewInsured({ sourceClientFileId: sourceClientFile.clientFileId, destinationInsuredId }));

  // 3) Change unit ownership for all of the data for the client file we just created, now that global units are in place.
  await thunkApi.dispatch(changeUnitOwnership({ clientFile: newClientFile }));
});


export default clientFilesSlice.reducer;
