import { useCallback } from 'react';
import { useBeforeUnload } from 'react-router-dom';
import { unstable_usePrompt as usePrompt, BlockerFunction } from 'react-router-dom';

export const useWarnOnUnloadAndNavigate = (shouldWarn: boolean) => {

  useBeforeUnload(useCallback((event: BeforeUnloadEvent) => {
    if (shouldWarn) {
      event.preventDefault();
      event.returnValue = '';
      return true;
    }
  }, [shouldWarn]));

  const whenFunc = useCallback<BlockerFunction>(({ currentLocation, nextLocation }) => {
    return shouldWarn && nextLocation.state?.ignorePrompt !== true && currentLocation.pathname !== nextLocation.pathname;
  }, [shouldWarn]);

  usePrompt({
    message: 'Changes you made may not be saved.',
    when: whenFunc,
  });
};
