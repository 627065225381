import { IEnumAttributes } from '@silveus/calculations/dist/utils/IEnumAttributes';

export interface IntervalRange {
  id: number;
  intervalDeltaYearStart: number;
  intervalMonthStart: number;
  intervalDayStart: number;
  intervalDeltaYearEnd: number;
  intervalMonthEnd: number;
  intervalDayEnd: number;
  name: string;
  initialSet: boolean;
  intervalType: IntervalType;
}

export enum IntervalType {
  None = 0,
  Monthly = 1,
  BiWeekly = 2,
  MidMonth = 3,
  TenDay = 4,
}

export const IntervalTypeAttributes: Readonly<Record<IntervalType, IEnumAttributes<IntervalType, number>>> = {
  [IntervalType.None]: { name: 'None', description: 'No interval type set', value: IntervalType.None },
  [IntervalType.Monthly]: { name: 'Monthly', description: 'MONTHLY', value: IntervalType.Monthly },
  [IntervalType.BiWeekly]: { name: 'BiWeekly', description: 'BI-WEEKLY', value: IntervalType.BiWeekly },
  [IntervalType.MidMonth]: { name: 'MidMonth', description: 'MID-MONTH', value: IntervalType.MidMonth },
  [IntervalType.TenDay]: { name: 'TenDay', description: 'TEN-DAY', value: IntervalType.TenDay },
};