import { InputAdornment, TextField } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import MESSAGES from '../../../constants/messages';
import { onNumericInputChange } from '../../numberTextField/numberTextField.component';

interface PercentOfProjectedProps {
  percentOfProjected: number;
  minValue: number;
  maxValue: number;
  step?: number;
  disabled?: boolean;
  autoFocus?: boolean;
}

export type PercentOfProjectedFields = {
  percentOfProjected: number;
  minValue: number;
  maxValue: number;
}

const PercentOfProjectedInput = ({ percentOfProjected, minValue, maxValue, step = 1, disabled = false, autoFocus = false }: PercentOfProjectedProps) => {
  const { control } = useFormContext();
  const enabled: boolean = useWatch({ name: 'rowCropScenarioPieceExtendedData.isPercentOfProjected', control: control, defaultValue: !disabled }) ?? false;

  return (
    <Controller
      name="rowCropScenarioPieceExtendedData.percentOfProjected"
      control={control}
      defaultValue={percentOfProjected}
      rules={{
        required: enabled ? VALIDATION_RULES.required() : false,
        min: VALIDATION_RULES.minimum(minValue), max: VALIDATION_RULES.maximum(maxValue),
        validate: {
          step: (value: number) => (!enabled || value % step === 0) || MESSAGES.step(step),
        },
      }}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="number"
          label="Percent of Projected"
          fullWidth
          inputProps={{ step: step, min: minValue, max: maxValue }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onNumericInputChange(e, field)}
          autoFocus={autoFocus}
          disabled={!enabled}
          size="small"
        />)
      }
    />
  );
};

export default PercentOfProjectedInput;
