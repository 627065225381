import { ViewColumn as ViewColumnIcon } from '@mui/icons-material';
import { Button, ClickAwayListener, Fade, FormControlLabel, FormGroup, IconButton, Paper, Popper, Switch, Tooltip } from '@mui/material';
import { Column, GridApi } from 'ag-grid-community';
import { isNullOrUndefined } from '../../utils/nullHandling';
import { useState, MouseEvent, useEffect, ChangeEvent } from 'react';

type GridColumnsButtonProps = {
  gridApi: GridApi | undefined;
  onChangeCallback?: () => void;
  useFullButton?: boolean;
  buttonText?: string;
  excluded?: string[];
  disabled?: boolean;
}

const GridColumnsButton = ({ gridApi, onChangeCallback, buttonText, excluded = [], useFullButton = false, disabled = false }: GridColumnsButtonProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [columns, setColumns] = useState<Column[]>([]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  useEffect(() => {
    if (gridApi) {
      refreshColumns();
    }
  }, [gridApi]);

  if (!gridApi) {
    return null;
  }

  const refreshColumns = () => {
    if (!isNullOrUndefined(gridApi)) {
      const cols = gridApi.getColumns() ?? [];
      const filtered = cols.filter(c => !excluded.includes(c.getColId()) && c.getColDef().type !== 'actions');
      setColumns(filtered);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, colId: string) => {
    gridApi.setColumnVisible(colId, event.target.checked);
    refreshColumns();
    if (onChangeCallback) {
      onChangeCallback();
    }
  };

  return <>
    <Tooltip title="Filters">
      {useFullButton ? (
        <Button id="btn-grid-col-edit" variant="outlined" disabled={disabled} onClick={handleClick}>{buttonText}</Button>
      ) : (
        <IconButton onClick={handleClick}>
          <ViewColumnIcon />
        </IconButton>
      )}

    </Tooltip >
    <Popper open={open} anchorEl={anchorEl} transition style={{ zIndex: 1300 }}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Paper sx={{ p: 1 }}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <FormGroup>
                {columns.map(c => <FormControlLabel key={`column-${c.getColId}`} control={<Switch checked={c.isVisible()} onChange={evt => handleChange(evt, c.getColId())} />} label={gridApi.getDisplayNameForColumn(c, null)} />)}
              </FormGroup>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  </>;
};

export default GridColumnsButton;