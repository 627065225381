import { HailProductId, HailScenarioPieceId, ScenarioPieceId, UnitYearId } from '../../../types/api/PrimaryKeys';
import { UnitGroup } from '../../../types/api/UnitGroup';
import HailScenarioPieceEndorsement from '../../../types/api/hail/HailScenarioPieceEndorsement';
import HailScenarioPieceRate from '../../../types/api/hail/HailScenarioPieceRate';
import HailEndorsement from '../../../types/api/hail/hailEndorsement';
import { HailProduct } from '../../../types/api/hail/hailProduct';
import HailRate from '../../../types/api/hail/hailRate';
import SelectedUnitIds from '../../../types/app/SelectedUnitIds';
import { getItemsForId } from '../../../utils/mapHelpers';
import { stableEmptyArrayAsMutable } from '../../../utils/stableEmptyArray';

export const getHailProductById = (availableHailProductsByProductId: Map<HailProductId, HailProduct>, hailProductId: HailProductId) => {
  const hailProduct = availableHailProductsByProductId.get(hailProductId);
  if (hailProduct === undefined) throw new Error(`Hail product with id ${hailProductId} not found`);
  return hailProduct;
};

export const getAvailableHailRatesByHailProductId = (
  availableHailProductsByProductId: Map<HailProductId, HailProduct>,
  hailProductId: HailProductId) => {
  const hailProduct = availableHailProductsByProductId.get(hailProductId);
  return hailProduct?.hailRates ?? stableEmptyArrayAsMutable<HailRate>();
};

export const getAvailableHailEndorsementsByHailProductId = (
  availableHailProductsByProductId: Map<HailProductId, HailProduct>,
  hailProductId: HailProductId) => {
  const hailProduct = availableHailProductsByProductId.get(hailProductId);
  return hailProduct?.hailEndorsements ?? stableEmptyArrayAsMutable<HailEndorsement>();
};

export const getSelectedHailScenarioPieceSelectedRates = (
  hailScenarioPieceRates: Map<HailScenarioPieceId, HailScenarioPieceRate[]>,
  hailSenarioPieceId: HailScenarioPieceId) => {
  return getItemsForId(hailScenarioPieceRates, hailSenarioPieceId);
};

export const getSelectedHailScenarioPieceSelectedEndorsements = (
  hailScenarioPieceEndorsements: Map<HailScenarioPieceId, HailScenarioPieceEndorsement[]>,
  hailSenarioPieceId: HailScenarioPieceId) => {
  return getItemsForId(hailScenarioPieceEndorsements, hailSenarioPieceId);
};

export const getSelectedHailScenarioPieceUnits = (
  hailScenarioPiecesUnits: Map<HailScenarioPieceId, SelectedUnitIds>,
  hailSenarioPieceId: HailScenarioPieceId) => {
  const selectedUnits = hailScenarioPiecesUnits.get(hailSenarioPieceId);
  const units = selectedUnits?.unitYearIds ?? stableEmptyArrayAsMutable<UnitYearId>();
  return units;
};

export const getSelectedHailScenarioPieceUnitsFromUnitGroup = (hailScenarioPieceUnits: Map<ScenarioPieceId, UnitGroup[]>, senarioPieceId: ScenarioPieceId) => {
  const unitGroups = hailScenarioPieceUnits.get(senarioPieceId);
  if (unitGroups === undefined) return stableEmptyArrayAsMutable<UnitYearId>();
  return unitGroups.flatMap(u => u.unitYearIds);
};