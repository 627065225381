import { IEnumAttributes } from '../IEnumAttributes';

/* eslint-disable no-unused-vars */
export enum AppType {
  Cancel = 100000004,
  CancelTransfer = 100000006,
  Change = 100000001,
  CommitNoChange = 100000002,
  HoldForCancelTransfer = 100000003,
  New = 100000000
}


export const AppTypeAttributes: Readonly<Record<AppType, IEnumAttributes<AppType>>> = {
  [ AppType.Cancel ]: { name: 'Cancel', description: 'Cancel', value: AppType.Cancel },
  [ AppType.CancelTransfer ]: { name: 'Cancel/Transfer', description: 'Cancel/Transfer', value: AppType.CancelTransfer },
  [ AppType.Change ]: { name: 'Change', description: 'Change', value: AppType.Change },
  [ AppType.CommitNoChange ]: { name: 'Commit No Change', description: 'Commit No Change', value: AppType.CommitNoChange },
  [ AppType.HoldForCancelTransfer ]: { name: 'Hold For Cancel/Transfer', description: 'Hold For Cancel/Transfer', value: AppType.HoldForCancelTransfer },
  [ AppType.New ]: { name: 'New', description: 'New', value: AppType.New },
};