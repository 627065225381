import { Card, CircularProgress, Grid, IconButton } from '@mui/material';
import PremiumBreakdown from '../../../types/api/premiumBreakdown';
import PremiumBreakdownBarChart from './premiumBreakdownBarChart.component';
import { usePremiumBreakdown } from '../../../hooks/usePremiumBreakdown';
import PremiumBreakdownProductSummary from './premiumBreakdownProductSummary';
import PremiumBreakdownProductDetails from './premiumBreakdownProductDetails';
import { useAppDispatch, useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import EditIcon from '@mui/icons-material/Edit';
import { openDrawer } from '../../../app/appDrawerSlice';
import { Print } from '@mui/icons-material';
import { generatePremiumBreakdownReport, selectIsReportLoading } from '../../../app/reportsSlice';
import { SigReportDocumentType } from '../../../constants/sigReportDocumentType';
import {
  selectAllCommodities,
  selectAllCounties,
  selectAllPractices,
  selectAllStates,
  selectInsuranceCalendarsByTypePractice
} from '../../../app/admSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import { selectScenarioById } from '../../../app/scenariosSlice';
import {
  MissingClientFileInStateError,
  MissingQuoteInStateError,
  MissingScenarioInStateError
} from '../../../errors/state/MissingStateErrors';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder } from '../../../app/scenarioPiecesSlice';
import { usePremiumBreakdownSummary } from '../../../hooks/usePremiumBreakdownSummary';
import { selectPremiumBreakdownOptions } from '../../../app/userSettingsSlice';


import { getPremiumBreakdownReportData } from '../../../components/reports/utils/getPremiumBreakdownReportData';

type PremiumBreakdownCardProps = {
  premiumBreakdown: PremiumBreakdown;
}

const PremiumBreakdownCard = ({ premiumBreakdown }: PremiumBreakdownCardProps) => {
  const dispatch = useAppDispatch();
  const premiumBreakdownOptions = useAppSelector(selectPremiumBreakdownOptions);
  const sortedData = usePremiumBreakdown(premiumBreakdown.primaryScenarioId);
  const premiumBreakdownSummary = usePremiumBreakdownSummary(premiumBreakdown.primaryScenarioId);
  const scenario = useAppSelector(state => selectScenarioById(state, premiumBreakdown.primaryScenarioId));
  if (scenario === null) throw new MissingScenarioInStateError(premiumBreakdown.primaryScenarioId);

  const quote = useAppSelector(state => selectQuoteById(state, scenario.quoteId));
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = useAppSelector(s => selectClientFileById(s, quote.clientFileId));
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  const insuranceCalendars = useAppSelector(state =>
    selectInsuranceCalendarsByTypePractice(state, quote.countyId, quote.commodityCode, scenario.typeId, scenario.practiceId));
  const rowCropScenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder, premiumBreakdown.primaryScenarioId);

  const states = useAppSelector(selectAllStates);
  const counties = useAppSelector(selectAllCounties);
  const practices = useAppSelector(selectAllPractices);
  const commodities = useAppSelector(selectAllCommodities);
  const isReportLoading = useAppSelector(selectIsReportLoading);

  const editPremiumBreakdown = () => {
    dispatch(openDrawer({ formName: 'premiumBreakdownForm', width: 460 }));
  };

  const printPremiumBreakdown = async () => {

    const premiumBreakdownReportData = getPremiumBreakdownReportData(
      clientFile,
      quote,
      scenario,
      states,
      counties,
      commodities,
      practices,
      insuranceCalendars,
      rowCropScenarioPieces,
      premiumBreakdownOptions,
      sortedData,
      premiumBreakdownSummary,
      SigReportDocumentType.Pdf,
      true,
    );

    await dispatch(generatePremiumBreakdownReport({ premiumBreakdownReportData }));
  };

  return (
    <Card sx={{ height: '100%', p: .5, minWidth: '925px', minHeight: '400px' }}>
      <Grid container sx={{ flexWrap: 'nowrap', height: '100%', overflowX: 'auto' }}>
        <Grid item xs="auto" sx={{ zIndex: 1, height: '32px' }}>
          <IconButton onClick={editPremiumBreakdown} color="primary" sx={{ pr: 0, pl: .5, pt: 1, mr: 0, ml: 0, alignItems: 'flex-start' }}>
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid item xs="auto" sx={{ zIndex: 1, height: '32px' }}>
          <IconButton disabled={isReportLoading} onClick={printPremiumBreakdown} color="primary" sx={{ pr: 0, pl: .5, pt: 1, mr: 0, ml: 0, alignItems: 'flex-start' }}>
            { isReportLoading ? <CircularProgress color="inherit" size={25} /> : <Print /> }
          </IconButton>
        </Grid>
        <Grid item xs sx={{ overflow: 'hidden', flex: 1 }}>
          <PremiumBreakdownBarChart sortedData={sortedData} />
        </Grid>
        <Grid container xs="auto" item direction="column" sx={{ flexWrap: 'nowrap', width: 'min-content !important', minWidth: '275px' }}>
          <Grid item sx={{ overflow: 'hidden' }}>
            <PremiumBreakdownProductSummary premiumBreakdownData={sortedData} premiumBreakdown={premiumBreakdown} />
          </Grid>
          <Grid item sx={{ alignSelf: 'center' }}>
            <PremiumBreakdownProductDetails premiumBreakdown={premiumBreakdown} />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PremiumBreakdownCard;