import { ValueFormatterParams } from 'ag-grid-community';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { Nullable } from '../../../types/util/Nullable';
import { optionalRoundToPlaces } from '../../../utils/rounding';

export const sharedYieldFormatter = <T>(params: ValueFormatterParams<T>, yieldRoundingPrecision: Nullable<number>) => {
  if (isNullOrUndefined(params.value)) return '';
  const parsedNumber = parseFloat(params.value);
  if (isNaN(parsedNumber)) return params.value;

  const roundedValue = optionalRoundToPlaces(params.value, yieldRoundingPrecision);
  return `${roundedValue}`;
};