import { Typography, styled } from '@mui/material';


interface ToggleableTypographyProps {
  disabled?: boolean;
}

// The normal MUI Typography does not have the ability to show the text as "disabled" without specifying the color property. Rather
// than having to specify the color on every typography like we do with the ternary in the code below, this styled component was created
// so it can be plugged in to any place we have a <Typography> component that needs the ability to show "disabled" in certain situations.
export const ToggleableTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'disabled',
})<ToggleableTypographyProps>(({ disabled = false, theme }) => ({
  '&': {
    color: disabled ? theme.palette.text.disabled : undefined,
  },
}));