import { Nullable } from '../../../types/util/Nullable';
import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { TextField } from '@mui/material';
import { ChangeEvent } from 'react';

interface YieldScaleProps {
  yieldScale: Nullable<number>;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
}

export type YieldScaleFields = {
  yieldScale: number;
}

export const YieldScaleInput = ({ yieldScale, className, autoFocus = false, disabled = false }: YieldScaleProps) => {
  const { control } = useFormContext();
  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: { onChange: (val: Nullable<number>) => void }) => {
    const val = parseFloat(e.target.value);
    field.onChange(isNaN(val) ? null : val);
  };

  return (
    <Controller
      name="yieldScale"
      rules={{ required: VALIDATION_RULES.required() }}
      defaultValue={yieldScale}
      control={control}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          className={className}
          type="number"
          label="Yield Scale"
          size="small"
          fullWidth
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => onChange(e, field)}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      )}
    />
  );
};
