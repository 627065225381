import { Nullable } from '../../../types/util/Nullable';
import { MatrixPresetId } from '../../../types/api/PrimaryKeys';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { ScenarioPieceGroupType, ScenarioPieceType } from '@silveus/calculations';
import NameInput, { NameFields } from '../../../components/formInputs/nameInput.component';
import { Button, Grid } from '@mui/material';
import MatrixPreset from '../../../types/api/MatrixPreset';
import { generatePrimaryKey } from '../../../utils/primaryKeyHelpers';
import useFormWrapper from '../../../hooks/useFormWrapper';
import useDrawerForm from '../../../hooks/useDrawerForm';
import ScenarioPieceTypeSelection from '../../../components/formInputs/matrix/scenarioPieceTypeSelection.component';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import ScrollableArea from '../../../components/appDrawer/scrollableArea.component';
import { getDistinctOrderedScenarioPieceTypeOptions } from '../../../utils/scenarioPieceUtils';
import { MatrixPresetType } from '../../../types/api/enums/matrixPresetType.enum';
import { canEditMatrixPreset, getDisplayNameForMatrixPreset } from '../../../utils/matrixPreset.utils';
import { modifyMatrixPresetsForUser, selectMatrixPresets } from '../../../app/userSettingsSlice';

export type SelectedScenarioPieceTypeDictionary = Partial<Record<ScenarioPieceType, boolean>>;
export type ScenarioPieceTypeSelected = { scenarioPieceType: ScenarioPieceType | ScenarioPieceGroupType; description: string, selected: boolean };
export type SelectedScenarioPieceTypeFields = { selectedScenarioPieceTypes: ScenarioPieceTypeSelected[] }
export type MatrixPresetFormFields = NameFields & SelectedScenarioPieceTypeFields;


export interface MatrixPresetFormProps extends FormWrapperProps {
  matrixPresetId: Nullable<MatrixPresetId>;
}

const scenarioPieceOptionMap = getDistinctOrderedScenarioPieceTypeOptions();
const availableScenarioPieceTypeOptions = Array.from(scenarioPieceOptionMap.values());

const defaultNewMatrixPreset = { name: '', matrixPresetType: MatrixPresetType.User, selectedScenarioPieceTypes: [] };

export const MatrixPresetForm = ({ matrixPresetId, handleValidation, registerHeader, isCanceling = false }: MatrixPresetFormProps) => {
  const dispatch = useAppDispatch();
  const formId = 'matrixPresetForm';

  const existingPresets = useAppSelector(selectMatrixPresets);

  const matrixPreset = existingPresets.find(tp => tp.matrixPresetId === matrixPresetId)
    ?? { ...defaultNewMatrixPreset, matrixPresetId: generatePrimaryKey<MatrixPresetId>() };

  const isEditable = canEditMatrixPreset(matrixPreset);

  const methods = useForm<MatrixPresetFormFields>({
    defaultValues: {
      name: getDisplayNameForMatrixPreset(matrixPreset),
      selectedScenarioPieceTypes: availableScenarioPieceTypeOptions.map(spt => ({ ...spt, selected: matrixPreset.selectedScenarioPieceTypes.includes(spt.scenarioPieceType) })),
    },
  });

  const onSubmit: SubmitHandler<MatrixPresetFormFields> = async data => {
    const matrixPresetFromForm: MatrixPreset = {
      ...matrixPreset,
      name: data.name,
      selectedScenarioPieceTypes: data.selectedScenarioPieceTypes.filter(sspt => sspt.selected).map(sspt => sspt.scenarioPieceType),
    };

    await dispatch(modifyMatrixPresetsForUser({ matrixPresets: [...existingPresets.filter(p => p.matrixPresetId !== matrixPresetFromForm.matrixPresetId), matrixPresetFromForm] }));
  };

  const onDeleteMatrixPreset = async () => {
    if (matrixPresetId === null || !isEditable) return;

    await dispatch(modifyMatrixPresetsForUser({ matrixPresets: existingPresets.filter(p => p.matrixPresetId !== matrixPresetId) }));
  };


  const handleReset = () => {
    methods.reset();
  };

  const shouldSubmit = methods.formState.isDirty && isEditable;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit);
  const handleSubmit = useFormWrapper('Matrix', methods, formId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  return (
    <FormProvider {...methods}>
      <ScrollableArea>
        <form id={formId} onSubmit={handleSubmit} onReset={handleReset}>
          <Grid container spacing={4} p={2}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <NameInput disabled={!isEditable} name={matrixPreset.name} />
              </Grid>
              <Grid container item xs={12}>
                <ScenarioPieceTypeSelection
                  disabled={!isEditable}
                  availableScenarioPieceTypeOptions={availableScenarioPieceTypeOptions} />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </ScrollableArea>
      {matrixPresetId !== null && isEditable &&
        <Grid justifyContent="flex-end" container sx={{ pr: 2, pt: 1 }}>
          <Button variant="contained" id="btn-delete"
            onClick={onDeleteMatrixPreset}>Delete</Button>
        </Grid>
      }
    </FormProvider>
  );
};
