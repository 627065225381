import { Dialog, DialogContent, Grid } from '@mui/material';
import { useAppSelector } from '../../hooks/reduxHooks';
import UnitsModalHeader from './unitsModalHeader';
import { selectScenarioById } from '../../app/scenariosSlice';
import { selectQuoteById } from '../../app/quotesSlice';
import UnitsGrid from './unitsGrid';
import { useUnitsModal } from './useUnitsModal';
import { useState } from 'react';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { Quote } from '../../types/api/Quote';
import UnitAphModal from '../unitsAphModal/unitAphModal';
import { selectAphModalProps, selectIsUnitAphModalOpen } from '../../app/unitsSlice';

import 'react-resizable/css/styles.css';

interface Props {
  scenarioId: ScenarioId;
}

const UnitsModal = ({ scenarioId }: Props) => {
  const scenario = useAppSelector(s => selectScenarioById(s, scenarioId));
  const quote = useAppSelector(s => scenario === null ? null : selectQuoteById(s, scenario.quoteId));

  if (!scenario || !quote) {
    return null;
  }

  return (
    <UnitsModalInternal scenario={scenario} quote={quote} />
  );
};

// This component is broken out so we can eliminate the possibility of scenario/quote being null way earlier, preventing dozens of null checks in children.

interface InternalProps {
  scenario: RowCropScenario;
  quote: Quote;
}

const UnitsModalInternal = ({ scenario, quote }: InternalProps) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const isAphModalOpen = useAppSelector(selectIsUnitAphModalOpen);
  const unitsModalProps = useAppSelector(selectAphModalProps);

  const {
    localUnitYearGridItems,
    updateUnitOptions,
    addOptionToAllUnits,
    removeOptionFromAllUnits,
    localScenarioOptions,
    localScenarioOptionUnitYears,
    handleSave,
    handleClose,
    isGridValid,
    localUnitYearAphMap,
    setLocalUnitYearAphMap,
    initialScenarioUnitYearAph,
    setLocalUnitYears,
    localScenarioUnitYearAph,
    setLocalScenarioUnitYearAph,
    setLocalScenarioOptionUnitYears,
    unitsSummary,
    isSaving,
    setAphFormDirty,
  } = useUnitsModal(scenario, quote);

  const isSaveDisabled = isInEditMode || !isGridValid || isSaving;

  return (
    <>
      <Dialog
        open={true}
        onClose={(_, reason) => handleClose(reason)}
        maxWidth={false}
        scroll="body"
        disableEscapeKeyDown={true}
        fullScreen
        sx={{ m: 2 }}
        key={scenario.scenarioId}
      >
        <>
          <UnitsModalHeader scenario={scenario} onCancel={handleClose} onSave={handleSave} isSaving={isSaving} isSaveDisabled={isSaveDisabled} />
          <DialogContent sx={{ height: 'calc(100% - 104px)', mt: 1 }}>
            <Grid container direction="column" sx={{ height: '100%' }}>
              <Grid item xs>
                <UnitsGrid
                  scenario={scenario}
                  unitYearGridItems={localUnitYearGridItems}
                  unitsSummary={unitsSummary}
                  quote={quote}
                  updateUnitOptions={updateUnitOptions}
                  localScenarioOptions={localScenarioOptions}
                  localScenarioOptionUnitYears={localScenarioOptionUnitYears}
                  addOptionToAllUnits={addOptionToAllUnits}
                  removeOptionFromAllUnits={removeOptionFromAllUnits}
                  isGridValid={isGridValid}
                  setIsInEditMode={setIsInEditMode}
                  localUnitYearAphMap={localUnitYearAphMap}
                  setLocalUnitYearAphMap={setLocalUnitYearAphMap}
                  initialScenarioUnitYearAph={initialScenarioUnitYearAph}
                  setLocalUnitYears={setLocalUnitYears}
                  localScenarioUnitYearAph={localScenarioUnitYearAph}
                  setLocalScenarioUnitYearAph={setLocalScenarioUnitYearAph}
                  setLocalScenarioOptionUnitYears={setLocalScenarioOptionUnitYears}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </>
      </Dialog>
      {isAphModalOpen && unitsModalProps && (
        <UnitAphModal modalProps={unitsModalProps} setFormDirty={setAphFormDirty} />
      )}
    </>
  );
};

export default UnitsModal;
