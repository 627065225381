import { Controller, useFormContext } from 'react-hook-form';
import { Nullable } from '../../../../types/util/Nullable';
import { Tooltip } from '@mui/material';
import NumberTextField, { onNumericInputChange } from '../../../numberTextField/numberTextField.component';
import { FormInputProps } from '../../scenario/formInputProps';
import { validateDollarAmount } from '../../../../pages/units/validations/genericDollarAmountValidation';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

interface MpDiscountProps extends FormInputProps {
  discount: Nullable<number>;
}

export type MpDiscountFields = {
  discount: number;
}

const MpDiscountInput = ({ discount, sx, autoFocus = false, disabled = false }: MpDiscountProps) => {
  const { control } = useFormContext();

  const discountTooltip = 'MP Discount - More info to come';

  return (
    <Controller
      name={ExtendedDataFormInputParameterNames.DiscountAmount}
      control={control}
      rules={{ validate: (value: Nullable<number>) => validateDollarAmount(value) }}
      defaultValue={discount ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <Tooltip title={discountTooltip}>
          <NumberTextField
            {...field}
            type="number"
            label="MP Discount"
            fullWidth
            inputProps={{ step: 0.01, min: 0 }}
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            sx={sx}
            onChange={e => onNumericInputChange(e, field, 2)}
            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
            autoFocus={autoFocus}
            size="small"
            disabled={disabled}
          />
        </Tooltip>
      )}
    />
  );
};

export default MpDiscountInput;
