import { useAppDispatch } from '../../../hooks/reduxHooks';
import { InputCostScenarioPiece } from '../../../types/api/InputCostScenarioPiece';
import { modifyInputCostScenarioPiece } from '../../../app/inputCostScenarioPiecesSlice';
import InactiveScenarioPieceCard from '../inactiveScenarioPieceCard.component';

interface InactiveInputCostScenarioPieceCardProps {
  scenarioPiece: InputCostScenarioPiece;
  isScenarioActive: boolean;
}

const InactiveInputCostScenarioPieceCard = ({ scenarioPiece, isScenarioActive }: InactiveInputCostScenarioPieceCardProps) => {
  const dispatch = useAppDispatch();

  const updateScenarioPiece = async (scenarioPiece: InputCostScenarioPiece) => {
    await dispatch(modifyInputCostScenarioPiece({ scenarioPiece: scenarioPiece }));
  };

  return (
    <InactiveScenarioPieceCard
      scenarioPiece={scenarioPiece}
      isScenarioActive={isScenarioActive}
      label="Input Costs"
      updateScenarioPiece={updateScenarioPiece}
    />
  );
};

export default InactiveInputCostScenarioPieceCard;