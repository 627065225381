import { removeAt } from '@paravano/utils';
import { maxBy, minBy } from 'lodash';
import { ACTUAL_OR_ASSIGNED_YIELD_DESCRIPTORS, YIELD_DESCRIPTORS, calculateApplicableTYield } from '@silveus/calculations/';
import { isEmpty, isNilOrEmpty, isNullOrUndefined, isUndefined } from '../../../utils/nullHandling';
import UnitYearAph from '../../../types/api/UnitYearAph';
import { Nullable } from '../../../types/util/Nullable';
import { Commodity } from '@silveus/calculations';
import * as uuid from 'uuid';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearAphId, UnitYearId } from '../../../types/api/PrimaryKeys';
import { numberSetter } from '../../../utils/grid';
import { optionalRoundToPlaces } from '../../../utils/rounding';

export const getNewAph = (unitYearId: UnitYearId, unitAcres: number, year: number, yieldType?: string, tYield: Nullable<number> = 0): Nullable<UnitYearAph> => {
  if (isNaN(year)) return null;
  const defaultType = yieldType !== undefined ? yieldType : YIELD_DESCRIPTORS.A;

  let newAph: UnitYearAph = {
    unitYearAphId: toPrimaryKey<UnitYearAphId>(uuid.v4()),
    unitYearId: unitYearId,
    year: year,
    yieldType: defaultType,
    acres: unitAcres,
    production: 0,
    aphYield: 0,
    preQaYield: null,
    offlineCreatedOn: undefined,
    offlineLastUpdatedOn: undefined,
    offlineDeletedOn: undefined,
  };
  newAph = processAphRow(newAph, tYield ?? 0);
  return newAph;
};

export const addYear = (aph: UnitYearAph[], unitId: UnitYearId, unitAcres: number, unitYear: number, commodityCode: string, yieldType?: string, tYield?: Nullable<number>, specificYear?: number): { newYear: Nullable<UnitYearAph>, newAph: UnitYearAph[] } => {
  const year = specificYear ?? ([...aph].sort((a, b) => a.year > b.year ? 1 : -1)[0]?.year ?? unitYear) - 1;
  const newYear = getNewAph(unitId, unitAcres, year, yieldType, tYield);
  if (isNullOrUndefined(newYear)) return { newYear: null, newAph: aph };
  let newAph: UnitYearAph[] = [...aph, newYear];
  newAph = processAcceptableYields(newAph, tYield ?? 0, commodityCode);
  return { newYear, newAph };
};

export const removeYear = (aph: UnitYearAph[], index: number) => {
  let newAph = [...aph];
  newAph.splice(index, 1);
  return newAph;
};

//end public facing methods

export const processAcceptableYields = (aph: UnitYearAph[], admTYield: number, commodityCode?: string, yieldPrecision: Nullable<number> = null): UnitYearAph[] => {
  const localAph = [...aph];
  //set the rules for year yield requirements here (number of t-yields, removing applicable ones, etc.) found in para. 1503 section A part 2b of CIH
  const tYieldDescriptors = [YIELD_DESCRIPTORS.T, YIELD_DESCRIPTORS.N, YIELD_DESCRIPTORS.E, YIELD_DESCRIPTORS.S, YIELD_DESCRIPTORS.C, YIELD_DESCRIPTORS.I, YIELD_DESCRIPTORS.L, YIELD_DESCRIPTORS.IL, YIELD_DESCRIPTORS.F] as const;
  //F is valid only on peanuts and tobacco, so need to bring in the unit's commodity to check it
  const actualOrAssignedYieldDescriptors = [...ACTUAL_OR_ASSIGNED_YIELD_DESCRIPTORS];

  if (commodityCode === Commodity.Peanuts || commodityCode === Commodity.Tobacco)
    actualOrAssignedYieldDescriptors.push(YIELD_DESCRIPTORS.F);

  const actualOrAssignedYields = localAph.filter(a => actualOrAssignedYieldDescriptors.includes(a.yieldType)).length;

  //the code for t-yield years depends on how many years there are
  const tYears = localAph.filter(a => tYieldDescriptors.includes(a.yieldType));

  //# of t-yield years should be equal to minimum years (4) - # of actual years
  const maxNumberOfTYields = Math.max(4 - actualOrAssignedYields, 0);
  let trimmedAph = [...localAph];

  if (tYears.length > maxNumberOfTYields) {
    //remove the oldest t year
    const oldestTYear = minBy(tYears, y => y.year);
    if (!isUndefined(oldestTYear)) {
      trimmedAph = removeAt(trimmedAph, trimmedAph.indexOf(oldestTYear));
    }
  }

  const newApplicableTYield = calculateApplicableTYield(trimmedAph, admTYield);
  const processAph = trimmedAph.map(aphRow => processAphRow(aphRow, newApplicableTYield, undefined, yieldPrecision));

  return processAph;
  ////check if there are still t-yield years after removing the oldest one, if so set the code and yield accordingly
  //const tYearsAfterTrim = trimmedAph.filter(a => tYieldDescriptors.includes(a.yieldType));
  //if (tYearsAfterTrim.length === 0) return trimmedAph;

  ////TODO #58159: This will need to be looked up from the DB at some point
  //const taFactor = 1;
  //const modifiedTYears = tYearsAfterTrim.map(y => ({
  //  ...y,
  //  aphYield: (y.aphYield ?? 0) * taFactor,
  //  //yieldType: applicableYieldDescriptor,
  //}));
  //const tYieldModifiedAph = [...trimmedAph].map(a => (modifiedTYears.find(y => y.year === a.year) || a));
  //return tYieldModifiedAph;
};

const getNextAphYear = (aph: UnitYearAph[], latestAvailableYear: number): number => {
  const minYear = (minBy(aph, a => a.year)?.year ?? 0) - 1;
  const latestYear = maxBy(aph, a => a.year)?.year ?? 0;

  return latestYear === latestAvailableYear ? minYear : latestAvailableYear;
};

export const acreageChangeNotAllowedYD = [YIELD_DESCRIPTORS.Z, YIELD_DESCRIPTORS.T, YIELD_DESCRIPTORS.E, YIELD_DESCRIPTORS.S, YIELD_DESCRIPTORS.C, YIELD_DESCRIPTORS.L, YIELD_DESCRIPTORS.I, YIELD_DESCRIPTORS.F] as const;
export const processAcreageChange = (aph: UnitYearAph, newAcreageValue: number, yieldPrecision: Nullable<number> = null): UnitYearAph => {
  if (acreageChangeNotAllowedYD.some(yt => yt === aph.yieldType))
    return aph;

  const newAph = { ...aph };
  const isValueSetSuccessful = numberSetter(newAph, 'acres', newAcreageValue, 0);

  if (!isValueSetSuccessful) return aph;

  if (![YIELD_DESCRIPTORS.PP, YIELD_DESCRIPTORS.PW, YIELD_DESCRIPTORS.WY, YIELD_DESCRIPTORS.NW, YIELD_DESCRIPTORS.B].some(yt => yt === aph.yieldType) &&
    !yieldChangeNotAllowedYD.some(yt => yt === aph.yieldType)) {
    newAph.aphYield = calculateYield(newAph, yieldPrecision);
  }

  return newAph;
};

export const productionChangeNotAllowYD = [YIELD_DESCRIPTORS.Z, YIELD_DESCRIPTORS.T, YIELD_DESCRIPTORS.N, YIELD_DESCRIPTORS.P, YIELD_DESCRIPTORS.E, YIELD_DESCRIPTORS.S, YIELD_DESCRIPTORS.C, YIELD_DESCRIPTORS.L, YIELD_DESCRIPTORS.I, YIELD_DESCRIPTORS.IL, YIELD_DESCRIPTORS.F, YIELD_DESCRIPTORS.PP, YIELD_DESCRIPTORS.PW, YIELD_DESCRIPTORS.WY, YIELD_DESCRIPTORS.B, YIELD_DESCRIPTORS.NW] as const;
export const processProductionChange = (aphRow: UnitYearAph, newProductionValue: number, yieldPrecision: Nullable<number> = null): UnitYearAph => {
  if (productionChangeNotAllowYD.some(yt => yt === aphRow.yieldType))
    return aphRow;

  const newAph = { ...aphRow };
  const isValueSetSuccessful = numberSetter(newAph, 'production', newProductionValue, 0);
  if (!isValueSetSuccessful) return aphRow;

  if (!yieldChangeNotAllowedYD.some(yt => yt === aphRow.yieldType)) {
    newAph.aphYield = calculateYield(newAph, yieldPrecision);
  }

  return newAph;
};

export const yieldChangeNotAllowedYD = [YIELD_DESCRIPTORS.Z, YIELD_DESCRIPTORS.T, YIELD_DESCRIPTORS.N, YIELD_DESCRIPTORS.P, YIELD_DESCRIPTORS.E, YIELD_DESCRIPTORS.S, YIELD_DESCRIPTORS.IL] as const;
export const processYieldChange = (unitYearAphId: UnitYearAphId, aph: UnitYearAph[], newYieldValue: number, tYield: number, commodityCode?: string): { changedRow: Nullable<UnitYearAph>, changedAph: UnitYearAph[] } => {
  const aphRow = aph.find(row => row.unitYearAphId === unitYearAphId);
  if (aphRow === undefined || yieldChangeNotAllowedYD.some(yt => yt === aphRow.yieldType)) return { changedRow: aphRow ?? null, changedAph: aph };

  const changedRow = { ...aphRow };
  changedRow.aphYield = newYieldValue;

  if (!productionChangeNotAllowYD.some(yt => yt === changedRow.yieldType))
    changedRow.production = calculateProduction(changedRow);

  const result = [...aph.filter(row => row.unitYearAphId !== unitYearAphId), changedRow];
  return { changedRow, changedAph: processAcceptableYields(result, tYield, commodityCode) };
};

export const processTypeSelection = (unitYearAphId: UnitYearAphId, aph: UnitYearAph[], newTypeValue: string, commodityCode: string, tYield: number, unitAcres: number, yieldPrecision: Nullable<number> = null): { changedRow: UnitYearAph, changedAph: UnitYearAph[] } => {
  const aphRow = aph.find(row => row.unitYearAphId === unitYearAphId);
  if (aphRow === undefined) throw new Error('Unit Year Aph not found');

  aphRow.yieldType = newTypeValue;
  const changedRow = processAphRow(aphRow, tYield, unitAcres, yieldPrecision);
  const result = [...aph.filter(row => row.unitYearAphId !== unitYearAphId), changedRow];
  const changedAph = processAcceptableYields(result, tYield, commodityCode, yieldPrecision);
  return { changedRow, changedAph };
};

export const validateAph = (aph: UnitYearAph[]) => {
  let isValid = false;
  const zeroAcreAphs = aph.filter(a => a.acres === 0);
  isValid = zeroAcreAphs.every(historicalYear => zeroAcreCompatibleYD.some(x => x === historicalYear.yieldType));
  if (isValid) {
    isValid = aph.every(historicalYear =>
      !isEmpty(historicalYear.acres) &&
      (!isEmpty(historicalYear.aphYield) || nullAphCompatibleYD.some(x => x === historicalYear.yieldType)) &&
      (!isEmpty(historicalYear.production) || nullProductionCompatibleYD.some(x => x === historicalYear.yieldType)) &&
      !isNilOrEmpty(historicalYear.yieldType) &&
      !isNilOrEmpty(historicalYear.year));
  }

  return isValid;
};

export const zeroAcreCompatibleYD = [YIELD_DESCRIPTORS.E, YIELD_DESCRIPTORS.S, YIELD_DESCRIPTORS.Z, YIELD_DESCRIPTORS.C, YIELD_DESCRIPTORS.L, YIELD_DESCRIPTORS.I, YIELD_DESCRIPTORS.F, YIELD_DESCRIPTORS.PP, YIELD_DESCRIPTORS.PW, YIELD_DESCRIPTORS.WY, YIELD_DESCRIPTORS.NW, YIELD_DESCRIPTORS.B, YIELD_DESCRIPTORS.T] as const;
export const nullProductionCompatibleYD = [YIELD_DESCRIPTORS.E, YIELD_DESCRIPTORS.N, YIELD_DESCRIPTORS.P, YIELD_DESCRIPTORS.IL, YIELD_DESCRIPTORS.S, YIELD_DESCRIPTORS.Z, YIELD_DESCRIPTORS.C, YIELD_DESCRIPTORS.L, YIELD_DESCRIPTORS.I, YIELD_DESCRIPTORS.F, YIELD_DESCRIPTORS.PP, YIELD_DESCRIPTORS.PW, YIELD_DESCRIPTORS.WY, YIELD_DESCRIPTORS.NW, YIELD_DESCRIPTORS.B, YIELD_DESCRIPTORS.T] as const;
export const nullAphCompatibleYD = [YIELD_DESCRIPTORS.Z, YIELD_DESCRIPTORS.T, YIELD_DESCRIPTORS.E, YIELD_DESCRIPTORS.S, YIELD_DESCRIPTORS.N, YIELD_DESCRIPTORS.P, YIELD_DESCRIPTORS.IL] as const;

const defaultYieldValue = 0;

export const processAphRow = (aphRow: UnitYearAph, tYield: number, defaultAcreageValue?: number, yieldPrecision: Nullable<number> = null) => {
  const result = { ...aphRow };
  switch (result.yieldType) {
    case YIELD_DESCRIPTORS.A:
    case YIELD_DESCRIPTORS.AY:
    case YIELD_DESCRIPTORS.BF:
    case YIELD_DESCRIPTORS.VF:
    case YIELD_DESCRIPTORS.FA:
    case YIELD_DESCRIPTORS.NA:
    case YIELD_DESCRIPTORS.PA:
    case YIELD_DESCRIPTORS.PR:
    case YIELD_DESCRIPTORS.DA:
      if (result.acres === null || result.acres === 0)
        result.acres = defaultAcreageValue ?? 0;
      break;

    case YIELD_DESCRIPTORS.C:
    case YIELD_DESCRIPTORS.L:
    case YIELD_DESCRIPTORS.I:
    case YIELD_DESCRIPTORS.F:
      result.acres = 0;
      result.production = null;
      if (result.aphYield === null) {
        result.aphYield = defaultYieldValue;
      }
      break;

    case YIELD_DESCRIPTORS.PP:
    case YIELD_DESCRIPTORS.PW:
    case YIELD_DESCRIPTORS.WY:
    case YIELD_DESCRIPTORS.NW:
    case YIELD_DESCRIPTORS.B:
      result.production = null;
      if (result.acres === null || result.acres === 0) {
        result.acres = defaultAcreageValue ?? 0;
      }
      break;

    case YIELD_DESCRIPTORS.E:
      result.acres = 0;
      result.aphYield = tYield * .8;
      result.production = null;
      break;

    case YIELD_DESCRIPTORS.N:
      if (result.acres === null || result.acres === 0) {
        result.acres = defaultAcreageValue ?? 0;
      }
      result.aphYield = tYield * .90;
      result.production = null;
      break;

    case YIELD_DESCRIPTORS.P:
      if (result.acres === null || result.acres === 0) {
        result.acres = defaultAcreageValue ?? 0;
      }
      result.aphYield = tYield * .75;
      result.production = null;
      break;

    case YIELD_DESCRIPTORS.IL:
      if (result.acres === null || result.acres === 0) {
        result.acres = defaultAcreageValue ?? 0;
      }
      result.aphYield = tYield;
      result.production = null;
      break;

    case YIELD_DESCRIPTORS.S:
      result.acres = 0;
      result.aphYield = tYield * .65;
      result.production = null;
      break;

    case YIELD_DESCRIPTORS.Z:
      result.acres = 0;
      result.aphYield = null;
      result.production = null;
      break;

    case YIELD_DESCRIPTORS.T:
      result.aphYield = tYield;
      result.acres = 0;
      result.production = null;
      break;
  }

  if (result.aphYield !== null) {
    // To apply rounding to any changes to aphYield that may have happened in any cases above.
    result.aphYield = optionalRoundToPlaces(result.aphYield, yieldPrecision);
  }

  return result;
};

export function isAcreageInvalid(yieldType: string, acres: Nullable<number>) {
  return (isEmpty(acres) || acres === 0) && !zeroAcreCompatibleYD.some(yt => yt === yieldType);
}

export function isAphYieldInvalid(yieldType: string, aphYield: Nullable<number>) {
  return isEmpty(aphYield) && !nullAphCompatibleYD.some(yt => yt === yieldType);
}

export function isProductionInvalid(yieldType: string, production: Nullable<number>) {
  return isEmpty(production) && !nullProductionCompatibleYD.some(yt => yt === yieldType);
}

export const getUnitYearAphForAddedLand = (policyYear: number, unitYearId: UnitYearId, defaultAcres: number, applicableTYield: number | null): UnitYearAph[] => {
  const numberOfTYieldYears = 3;
  const latestTYieldTypeYear = policyYear - 1;
  const earliestTYieldYear = policyYear - numberOfTYieldYears;

  const newUnitYearAphs: UnitYearAph[] = [];
  //Add the latest year as an A
  const newAph = getNewAph(unitYearId, defaultAcres, policyYear, YIELD_DESCRIPTORS.A, applicableTYield);
  if (newAph !== null) newUnitYearAphs.push(newAph);
  //Add the 3 years before that as a T
  for (let year = latestTYieldTypeYear; year >= earliestTYieldYear; year--) {
    const newAph = getNewAph(unitYearId, 0, year, YIELD_DESCRIPTORS.T, applicableTYield);
    if (newAph !== null) newUnitYearAphs.push(newAph);
  }
  return newUnitYearAphs;
};

const calculateYield = (aph: UnitYearAph, yieldPrecision: Nullable<number> = null): Nullable<number> => {
  if (isNullOrUndefined(aph.production) || isNullOrUndefined(aph.acres) || aph.acres === 0) {
    return 0;
  }

  const yieldValue = aph.production / aph.acres;
  const roundedValue = optionalRoundToPlaces(yieldValue, yieldPrecision);

  return roundedValue;
};

const calculateProduction = (aph: UnitYearAph): Nullable<number> => isNullOrUndefined(aph.aphYield) || isNullOrUndefined(aph.acres) ? null : aph.aphYield * aph.acres;

//actual and assigned yield rules found in CIH para 1702 for category B crops and in para 1856 for category C crops

//when we roll years if they have any temporary yields we need to have them enter that data since temp yields are only valid for 1 year (1503 A3c of CIH)
