import { Grid, IconButton, Tab, Tabs } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { ClientFileId } from '../../../types/api/PrimaryKeys';
import UnitsModal from '../../unitsModal/unitsModal';
import { selectOpenUnitsModalScenarioId } from '../../../app/unitsSlice';
import { selectIsHailModalOpen } from '../../../app/hailModalSlice';
import HailModal from '../../hailModal/hailModal';
import { ClientFileContentBody } from './clientFile.contentbody';
import {
  selectCurrentQuoteTabIndex,
  setCurrentQuoteTabIndex
} from '../../../app/quotesSlice';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ClientFileTabs } from '../../../constants/clientFileTabs';
import { ClientFileCollapsibleTray } from './clientFile.collapsibleTray';
import { LeftPanelZoomInput } from '../../../components/applicationZoomInput/LeftPanelZoomInput';
import { selectCreateTemplateScenarioModalScenarioId } from '../../../app/templateScenariosSlice';
import { CreateTemplateScenarioModal } from '../../templateScenarios/CreateTemplateScenarioModal';

interface Props {
  clientFileId: ClientFileId;
}

export const ClientFileContent = ({ clientFileId }: Props) => {
  const dispatch = useAppDispatch();
  const openUnitsModalScenarioId = useAppSelector(selectOpenUnitsModalScenarioId);
  const isHailModalOpen = useAppSelector(selectIsHailModalOpen);
  const createTemplateScenarioModalScenarioId = useAppSelector(selectCreateTemplateScenarioModalScenarioId);
  const tabIndex = useAppSelector(selectCurrentQuoteTabIndex);
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs="auto">
        <Grid container direction="column" sx={{ borderRight: theme => `1px solid ${theme.palette.grey[400]}`, height: '100%' }}>
          <Grid container item justifyContent="flex-end" xs="auto">
            <Grid item>
              <IconButton onClick={() => setCollapsed(prev => !prev)}>
                {collapsed ? <ExpandLess sx={{ rotate: '90deg' }} /> : <ExpandMore sx={{ rotate: '90deg' }} />}
              </IconButton>
            </Grid>
          </Grid>
          {!collapsed && (
            <>
              <Grid item xs="auto" sx={{ width: '310px' }}>
                <Tabs value={tabIndex} onChange={(_, newIndex) => dispatch(setCurrentQuoteTabIndex(newIndex))}>
                  <Tab label={ClientFileTabs.scenarios.label} />
                  <Tab label={ClientFileTabs.scenarioAnalysis.label} />
                  <Tab label={ClientFileTabs.comparisonView.label} />
                </Tabs>
              </Grid>
              <Grid item xs sx={{ width: '310px', overflowY: 'auto' }}>
                <ClientFileCollapsibleTray clientFileId={clientFileId} />
              </Grid>
              <Grid item xs="auto" sx={{ width: '310px' }}>
                <LeftPanelZoomInput />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid item xs sx={{ overflow: 'hidden' }}>
        <ClientFileContentBody clientFileId={clientFileId} />

        {openUnitsModalScenarioId !== null && (
          <UnitsModal scenarioId={openUnitsModalScenarioId} />
        )}
        {isHailModalOpen && (
          <HailModal />
        )}
        {createTemplateScenarioModalScenarioId !== null && (
          <CreateTemplateScenarioModal copyFromScenarioId={createTemplateScenarioModalScenarioId} />
        )}
      </Grid>
    </Grid>
  );
};
