import { BaseReportScenarioPiece } from '../../../types/api/reports/BaseReportScenarioPiece';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { getFriendlyScenarioPieceName } from '../../../utils/scenarioPieceUtils';
import { ScenarioPieceResponseDTO } from '@silveus/calculations';
import { ReportCalculationData } from '../../../types/api/reports/ReportCalculationData';
import { Nullable } from '../../../types/util/Nullable';
import ScenarioPieceClassification
  from '@silveus/calculations/dist/lookups/scenarioPieceClassification/ScenarioPieceClassification';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { OptionCode } from '@silveus/calculations';

//TODO: if you make changes here, it will affect both matrix and quote summary, unit tests may break
export const getBaseReportScenarioPiece = (
  scenario: RowCropScenario | undefined,
  scenarioPiece: RowCropScenarioPiece,
  scenarioPieceCalcData: ScenarioPieceResponseDTO | null,
  scenarioExpectedCountyYield: Nullable<number>,
  approvedYield: Nullable<number>,
  adjustedYield: Nullable<number>,
): BaseReportScenarioPiece => {

  let scenarioPieceApprovedYield: Nullable<number>;
  let scenarioPieceAdjustedYield: Nullable<number>;

  const isCounty = scenarioPieceCalcData?.scenarioPieceClassification === ScenarioPieceClassification.County;

  if (isCounty) {
    scenarioPieceAdjustedYield = scenarioExpectedCountyYield;
    scenarioPieceApprovedYield = null;
  } else {
    scenarioPieceApprovedYield = approvedYield;
    scenarioPieceAdjustedYield = adjustedYield;
  }

  const reportCalcData: ReportCalculationData = {
    liabilityAmount: scenarioPieceCalcData?.protectionAmount ?? 0,
    liabilityPerAcre: scenarioPieceCalcData?.protectionAmountPerAcre ?? 0,
    premiumLiability: scenarioPieceCalcData?.liabilityAmount ?? 0,
    premiumLiabilityPerAcre: scenarioPieceCalcData?.liabilityPerAcre ?? 0,
    producerPremium: scenarioPieceCalcData?.producerPremium ?? 0,
    producerPremiumPerAcre: scenarioPieceCalcData?.producerPremiumPerAcre ?? 0,
    totalPremium: scenarioPieceCalcData?.totalPremium ?? 0,
    totalPremiumPerAcre: scenarioPieceCalcData?.totalPremiumPerAcre ?? 0,
    triggerYield: scenarioPieceCalcData?.triggerYield ?? 0,
    totalSubsidy: null,
    subsidyPerAcre: null,
    subsidyPercent: null,
  };

  const isTAorYESelected = scenario?.scenarioOptions.some(x => x.option === OptionCode.TA || x.option === OptionCode.YE) ?? false;

  const baseReportScenarioPiece: BaseReportScenarioPiece = {
    scenarioPieceType: scenarioPiece.scenarioPieceType,
    displayPlanName: getFriendlyScenarioPieceName(scenarioPiece.scenarioPieceType),
    planAbbrev: '', //TODO #65409: Update this value
    upperCoverageLevel: `${scenarioPiece.upperCoverageLevel}%`,
    lowerCoverageLevel: '',
    isTAYieldVisible: (scenarioPieceApprovedYield !== scenarioPieceAdjustedYield) || isTAorYESelected,
    taYield: scenarioPieceApprovedYield,
    yield: scenarioPieceAdjustedYield,
    calculationData: reportCalcData,
  };
  return baseReportScenarioPiece;
};