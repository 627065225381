import { ProductType } from '../../../../types/api/enums/application/productType.enum';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import ScenarioPieceDefinition from '../../../../types/app/ScenarioPieceDefinition';
import { Nullable } from '../../../../types/util/Nullable';
import { ScenarioPieceFormPickerProps } from '../../../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import { validateIce } from '../../../../utils/validation/scenarioPieces/iceValidation';
import { createIceData } from '../../../../services/calculations/ice/iceDataTransformations';
import IceScenarioPieceForm from '../../../../pages/scenarioPiece/ice/iceScenarioPieceForm';
import { getIceStackShieldQuoteSummaryData } from '../../../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getIceStackShieldQuoteSummaryData';
import getFullyQualifiedScenarioPieceName from '../../defaultDefinitions/scenarioPieceNameDefaults';
import { ScenarioPiece } from '../../../../types/api/ScenarioPiece';
import {
  AddScenarioPieceCardChipFunction,
  ProtectionFactorScenarioPieceChip
} from '../../defaultDefinitions/scenarioPieceCardChipDefaults';
import { ScenarioPieceTypeAttributes } from '@silveus/calculations';
import { isRowCropScenarioPiece } from '../../../../utils/castingUtils';
import { baseRowScenarioPieceAdd } from '../../defaultDefinitions/defaultValues';

const IceProductScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
  return ScenarioPieceTypeAttributes[scenarioPiece.scenarioPieceType].description.replace('ICE - ', '');
};

const IceDeductibleScenarioPieceChip: AddScenarioPieceCardChipFunction = (scenarioPiece: ScenarioPiece | RowCropScenarioPiece) => {
  if (isRowCropScenarioPiece(scenarioPiece)) {
    if (scenarioPiece.rowCropScenarioPieceExtendedData?.iceDeductibleSelection !== undefined &&
      scenarioPiece.rowCropScenarioPieceExtendedData.iceDeductibleSelection !== null &&
      scenarioPiece.rowCropScenarioPieceExtendedData.iceDeductibleSelection > 1) {
      return 'Full Deductible';
    }
  }
  return null;
};

export const iceDefinition: ScenarioPieceDefinition = {
  validationFunction: validateIce,
  d365ProductType: ProductType.ICE,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups, productOfferAvailabilities) => {
    return createIceData(scenarioPiece as RowCropScenarioPiece, scenario, state, baseUnits, productOfferAvailabilities);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <IceScenarioPieceForm {...props} scenarioPiece={props.scenarioPiece as Nullable<RowCropScenarioPiece>} />;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getIceStackShieldQuoteSummaryData,
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceName,
  getScenarioPieceCardChips: [IceProductScenarioPieceChip, ProtectionFactorScenarioPieceChip, IceDeductibleScenarioPieceChip],
  addScenarioPiece: baseRowScenarioPieceAdd,
  premiumBreakdownBarColor: '#4682B4',
};