import { admDb, iceDb, quoterDb } from '../db';
import CoverageLevelDetails from '../types/api/adm/CoverageLevelDetails';
import { Nullable } from '../types/util/Nullable';
import PriceElectionBounds from '../types/api/adm/PriceElectionBounds';
import GuaranteeAdjustment from '../types/api/adm/GuaranteeAdjustment';
import BaseRate from '../types/api/adm/BaseRate';
import SubCountyRate from '../types/api/adm/SubCountyRate';
import HistoricalRevenueCapping from '../types/api/adm/HistoricalRevenueCapping';
import UnitDiscountLevel from '../types/api/adm/UnitDiscountLevel';
import BetaSequence from '../types/api/adm/BetaSequence';
import OptionRate from '../types/api/adm/OptionRate';
import CoverageLevelDifferential from '../types/api/adm/CoverageLevelDifferential';
import InsuranceOffer from '../types/api/adm/InsuranceOffer';
import ComboRevenueFactor from '../types/api/adm/ComboRevenueFactor';
import PricesAndYields from '../types/api/adm/PricesAndYields';
import HistoricalYieldTrendYear from '../types/api/adm/HistoricalYieldTrendYear';
import HistoricalYieldTrend from '../types/api/adm/HistoricalYieldTrend';
import { distinctBy, filterNotNullOrUndefined } from '../utils/arrayUtils';
import { ProductOfferAvailabilities } from '@silveus/calculations/dist/availability/productOfferAvailability';
import { HighRiskType, HistoricalTYield, OptionCode, ScenarioPieceType, getSubCountyCodeFromId, roundToPlaces } from '@silveus/calculations';
import { safeWhere } from '../utils/dexieQueryHelpers/whereClauses';
import { safeGet } from '../utils/dexieQueryHelpers/getClauses';
import { Collection } from 'dexie';
import {
  ScenarioId,
  ScenarioOptionId,
  ScenarioOptionUnitYearId,
  ScenarioPieceId
} from '../types/api/PrimaryKeys';
import { OptionLevelCode } from '../constants/optionLevelCodes';
import TrendAdjustmentFactor from '../types/api/adm/TrendAdjustmentFactor';
import { YeYear } from '../types/api/adm/YeYears';
import AreaCoverageLevel from '../types/api/adm/AreaCoverageLevel';
import PriceGroupMember from '../types/api/adm/PriceGroupMember';
import HistoricalPrice from '../types/api/adm/HistoricalPrice';
import ScenarioOption from '../types/api/options/ScenarioOption';
import ScenarioOptionUnitYear from '../types/api/options/ScenarioOptionUnitYear';
import { FlatScenarioOptionUnitYear } from '../types/api/options/FlatScenarioOptionUnitYear';
import { softDelete } from '../utils/dexieQueryHelpers/softDelete';
import BulkTransitionalYield from '../types/api/adm/BulkTransitionalYield';
import HistoricalStormEvent from '../types/api/adm/HistoricalStormEvent';
import { getCountyCodeFromCountyId, getStateCodeFromCountyId } from '../utils/adm';
import { isNotNullOrUndefined } from '../utils/nullHandling';
import AdmDataForQuoteParams from '../types/api/adm/AdmDataForQuoteParams';

//#region ADM DB

export const getBetaSequences = async (betaId: number): Promise<BetaSequence[]> => {
  const betaSequences = safeWhere(admDb.betaSequences, { betaId: betaId }).sortBy('sequenceNbr');

  return betaSequences;
};

export const getUnitDiscountLevels = async (unitDiscountId: number): Promise<UnitDiscountLevel[]> => {
  const unitDiscountLevels = safeWhere(admDb.unitDiscountLevels, { unitDiscountId: unitDiscountId }).toArray();

  return unitDiscountLevels;
};

export const getCoverageLevelDifferentialsForUnitScenario = async (insuranceOfferId: number, coverageTypeCode: string, highRiskTypeId: HighRiskType): Promise<CoverageLevelDifferential[]> => {
  const coverageLevelDifferentials = await safeWhere(admDb.coverageLevelDifferentials, {
    insuranceOfferId: insuranceOfferId,
    coverageTypeCode: coverageTypeCode,
  })
    .and(cld => {
      switch (highRiskTypeId) {
        case HighRiskType.AllLand:
          return true;
        case HighRiskType.HighRiskOnly:
          return cld.subCountyCode !== null && cld.subCountyCode !== 'URA';
        case HighRiskType.URA:
          return cld.subCountyCode === null || cld.subCountyCode === 'URA';
        default:
          return cld.subCountyCode === getSubCountyCodeFromId(highRiskTypeId);
      }
    })
    .toArray();

  return coverageLevelDifferentials;
};

export const getCoverageLevelDifferentials = async (insuranceOfferId: number, coverageTypeCode: string, subCountyCode: Nullable<string> = null): Promise<CoverageLevelDifferential[]> => {
  const coverageLevelDifferentials = await safeWhere(admDb.coverageLevelDifferentials, {
    insuranceOfferId: insuranceOfferId,
    coverageTypeCode: coverageTypeCode,
  })
    .and(cld => cld.subCountyCode === subCountyCode)
    .toArray();

  return coverageLevelDifferentials;
};

export const getComboRevenueFactors = async (commodityCode: string, stateCode: string): Promise<ComboRevenueFactor[]> => {
  const comboRevenueFactors = await safeWhere(admDb.comboRevenueFactors, { commodityCode: commodityCode, stateCode: stateCode }).toArray();
  return comboRevenueFactors;
};

export const getHistoricalRevenueCapping = async (insuranceOfferId: number): Promise<Nullable<HistoricalRevenueCapping>> => {
  const historicalRevenueCapping = await safeGet(admDb.historicalRevenueCappings, { insuranceOfferId: insuranceOfferId });

  return historicalRevenueCapping ?? null;
};

export const getSubsidyPercent = async (commodityCode: string, unitStructureCode: string, insurancePlanCode: string,
  coverageLevelPercent: number, coverageTypeCode: string): Promise<number> => {
  const applicableSubsidies = safeWhere(admDb.subsidies, {
    coverageLevelId: coverageLevelPercent,
    insurancePlanCode: insurancePlanCode,
    coverageTypeCode: coverageTypeCode,
  });

  const subsidy = await applicableSubsidies.filter(s => {
    return (s.commodityCode === commodityCode || s.commodityCode === null) && (s.unitStructureCode === unitStructureCode || s.unitStructureCode === null);
  }).reverse().sortBy('[commodityCode+unitStructureCode]');

  if (subsidy.length === 0 || subsidy[0].subsidyPercent === null) throw new Error('');

  return subsidy[0].subsidyPercent;
};

export const getOptionConversionFactor = async (insuranceOfferId: number): Promise<Nullable<number>> => {
  const cottonSeedOptionCode = 'SE';
  const optionRate = await safeGet(admDb.optionRates, {
    insuranceOfferId: insuranceOfferId,
    optionCode: cottonSeedOptionCode,
  });

  if (optionRate === undefined) return null;

  return optionRate.optionConversionFactor;
};

export const getInsuranceOfferInformation = async (insurancePlanCode: string, countyId: string, typeId: string, practiceId: string, coverageTypeCode: string): Promise<Nullable<InsuranceOffer>> => {
  const allInsuranceOffers = await safeWhere(admDb.insuranceOffers, {
    insurancePlanCode: insurancePlanCode,
    countyId: countyId,
    practiceId: practiceId,
    typeId: typeId,
  }).toArray();

  const insuranceOfferIds = allInsuranceOffers.map(io => io.insuranceOfferId);

  const coverageLevelDifferentials = await safeWhere(admDb.coverageLevelDifferentials, 'insuranceOfferId')
    .anyOf(insuranceOfferIds)
    .and(cld => cld.coverageTypeCode === coverageTypeCode)
    .toArray();

  const insuranceOfferIdsForCoverageTypeCode = coverageLevelDifferentials.map(cld => cld.insuranceOfferId);

  const filteredInsuranceOffers = allInsuranceOffers.filter(io => insuranceOfferIdsForCoverageTypeCode.includes(io.insuranceOfferId));

  const insuranceOffer = filteredInsuranceOffers.at(0);

  if (insuranceOffer === undefined) return null;

  return insuranceOffer;
};

export const getInsuranceOfferAvailability = async (year: number, countyId: string, commodityCode: string, insurancePlanCode: string, scenarioPieceType: ScenarioPieceType): Promise<ProductOfferAvailabilities> => {
  const insuranceOffers = await safeWhere(admDb.insuranceOffers, 'typeId').startsWith(commodityCode).and(io => io.countyId === countyId && io.insurancePlanCode === insurancePlanCode).toArray();

  const productOfferAvailability: ProductOfferAvailabilities = {
    scenarioPieceType: scenarioPieceType,
    productOfferAvailabilities: insuranceOffers.map(io => ({ typeId: io.typeId, practiceId: io.practiceId, cropYear: io.cropYear, extendedData: null })),
  };

  return productOfferAvailability;
};

export const getAvailableInsuranceOffers = async (countyId: string, typeId: string, practiceId: string, coverageTypeCode: string): Promise<InsuranceOffer[]> => {
  const allInsuranceOffers = await safeWhere(admDb.insuranceOffers, {
    countyId: countyId,
    practiceId: practiceId,
    typeId: typeId,
  }).toArray();

  const insuranceOfferIds = allInsuranceOffers.map(io => io.insuranceOfferId);

  const coverageLevelDifferentials = await safeWhere(admDb.coverageLevelDifferentials, 'insuranceOfferId')
    .anyOf(insuranceOfferIds)
    .and(cld => cld.coverageTypeCode === coverageTypeCode)
    .toArray();

  const insuranceOfferIdsForCoverageTypeCode = coverageLevelDifferentials.map(cld => cld.insuranceOfferId);

  const filteredInsuranceOffers = allInsuranceOffers.filter(io => insuranceOfferIdsForCoverageTypeCode.includes(io.insuranceOfferId));

  return filteredInsuranceOffers;
};

export const getOptionRates = async (insuranceOfferId: number): Promise<OptionRate[]> => {
  const optionRates = safeWhere(admDb.optionRates, { insuranceOfferId: insuranceOfferId }).toArray();

  return optionRates;
};

export const getOptions = async (insuranceOfferId: number): Promise<{ optionCode: OptionCode, optionName: string, optionLevelCode: OptionLevelCode, coverageLevel: Nullable<number> }[]> => {
  const optionRates = await safeWhere(admDb.optionRates, { insuranceOfferId: insuranceOfferId }).toArray();

  const optionCodes = optionRates.map(optRate => optRate.optionCode);

  const options = await safeWhere(admDb.options, 'optionCode').anyOf(optionCodes).toArray();

  const optionResults: { optionCode: OptionCode, optionName: string, optionLevelCode: OptionLevelCode, coverageLevel: Nullable<number> }[] = [];

  optionRates.forEach(optRate => {
    const associatedOption = options.find(opt => opt.optionCode === optRate.optionCode);

    if (associatedOption === undefined) return;

    const option = {
      optionCode: associatedOption.optionCode,
      optionName: associatedOption.optionName,
      optionLevelCode: associatedOption.optionLevelCode,
      coverageLevel: optRate.coverageLevelId,
    };

    optionResults.push(option);
  });

  return optionResults;
};

export const getBaseRate = async (insuranceOfferId: number): Promise<BaseRate> => {
  const baseRate = await safeGet(admDb.baseRates, { insuranceOfferId: insuranceOfferId });

  if (baseRate === undefined) throw new Error('');

  return baseRate;
};

export const getSubCountyRate = async (insuranceOfferId: number, highRiskTypeId: HighRiskType): Promise<Nullable<SubCountyRate>> => {
  let subCountyCode = null;

  switch (highRiskTypeId) {
    case HighRiskType.AllLand:
    case HighRiskType.HighRiskOnly:
      return null;
    case HighRiskType.URA:
      subCountyCode = 'URA';
      break;
    default:
      subCountyCode = getSubCountyCodeFromId(highRiskTypeId);
      break;
  }

  const subCountyRate = await safeGet(admDb.subCountyRates, {
    insuranceOfferId: insuranceOfferId,
    subCountyCode: subCountyCode,
  });

  if (subCountyRate === undefined) throw new Error('');

  return subCountyRate;
};

export const getSubCountyCodes = async (year: number, countyId: string, practiceId: string, typeId: string): Promise<string[]> => {
  const insuranceOffers = await safeWhere(admDb.insuranceOffers, { countyId, practiceId, typeId }).toArray();
  const insuranceOfferIds = insuranceOffers.map(io => io.insuranceOfferId);
  const subCountyRates = await safeWhere(admDb.subCountyRates, 'insuranceOfferId').anyOf(insuranceOfferIds)
    .and(scr => scr.subCountyCode !== null).toArray();
  const subCountyCodes = filterNotNullOrUndefined(subCountyRates.map(scr => scr.subCountyCode));

  return distinctBy(subCountyCodes, scc => scc);
};

export const getAreaBaseRate = async (year: number, insuranceOfferId: number, coverageLevelId: number, priceVolatilityFactor: Nullable<number>, areaLossStartPercent?: number, areaLossEndPercent?: number): Promise<number> => {
  const insuranceOffer = await safeGet(admDb.insuranceOffers, { insuranceOfferId: insuranceOfferId });
  let areaCoverageLevels = insuranceOffer === undefined || insuranceOffer.admInsuranceOfferId === null
    ? []
    : await safeWhere(admDb.areaCoverageLevels, { admInsuranceOfferId: insuranceOffer.admInsuranceOfferId, coverageLevelId: coverageLevelId }).toArray();

  if (areaLossStartPercent !== undefined) {
    var areaLossStartPercentRounded = roundToPlaces(areaLossStartPercent / 100, 2);
    areaCoverageLevels = areaCoverageLevels.filter(acl => acl.areaLossStartPercent === areaLossStartPercentRounded);
  }
  if (areaLossEndPercent !== undefined) {
    var areaLossEndPercentRounded = roundToPlaces(areaLossEndPercent / 100, 2);
    areaCoverageLevels = areaCoverageLevels.filter(acl => acl.areaLossEndPercent === areaLossEndPercentRounded);
  }

  const areaCoverageLevel = areaCoverageLevels.length === 0 ? undefined : areaCoverageLevels[0];

  const applicableAreaRates = areaCoverageLevel === undefined || areaCoverageLevel.areaRateId === null ? undefined : await safeWhere(admDb.areaRates, { areaRateId: areaCoverageLevel.areaRateId }).and(ar => ar.priceVolatilityFactor === priceVolatilityFactor).toArray();
  const areaRate = applicableAreaRates === undefined ? undefined : applicableAreaRates[0];

  return areaRate?.baseRate ?? 0;
};

export const getPricesAndYields = async (insuranceOfferId: number, insurancePlanCode: string): Promise<Nullable<PricesAndYields>> => {
  const price = await safeWhere(admDb.prices, { insuranceOfferId: insuranceOfferId }).filter(p => p.optionCode === null).first();

  if (price === undefined)
    return null;

  const expectedInputCosts = price.expectedMarginAmount !== null && price.expectedRevenueAmount !== null
    ? price.expectedRevenueAmount - price.expectedMarginAmount : null;

  const actualInputCosts = price.finalMarginAmount !== null && price.finalRevenueAmount !== null
    ? price.finalRevenueAmount - price.finalMarginAmount : null;

  const pricesAndYields: PricesAndYields = {
    insuranceOfferId: price.insuranceOfferId,
    insurancePlanCode: insurancePlanCode,
    projectedPrice: price.projectedPrice,
    harvestPrice: price.harvestPrice,
    priceVolatilityFactor: price.priceVolatilityFactor,
    expectedIndexValue: price.expectedIndexValue,
    finalIndexValue: price.finalIndexValue,
    expectedInputCosts: expectedInputCosts,
    actualInputCosts: actualInputCosts,
  };

  return pricesAndYields;
};

export const getBulkPricesAndYields = async (insuranceOfferIds: number[], insuranceOffers: InsuranceOffer[]): Promise<PricesAndYields[]> => {
  const prices = await safeWhere(admDb.prices, 'insuranceOfferId').anyOf(insuranceOfferIds).and(p => p.optionCode === null).toArray();

  const allPricesAndYields = prices.map(price => {
    const planCode = insuranceOffers.find(offer => offer.insuranceOfferId === price.insuranceOfferId)?.insurancePlanCode;

    if (planCode === undefined) throw new Error('Unable to find plan code for price');

    const expectedInputCosts = price.expectedMarginAmount !== null && price.expectedRevenueAmount !== null
      ? price.expectedRevenueAmount - price.expectedMarginAmount : null;

    const actualInputCosts = price.finalMarginAmount !== null && price.finalRevenueAmount !== null
      ? price.finalRevenueAmount - price.finalMarginAmount : null;

    const pricesAndYields: PricesAndYields = {
      insuranceOfferId: price.insuranceOfferId,
      insurancePlanCode: planCode,
      projectedPrice: price.projectedPrice ?? price.establishedPrice,
      harvestPrice: price.harvestPrice,
      priceVolatilityFactor: price.priceVolatilityFactor,
      expectedIndexValue: price.expectedIndexValue,
      finalIndexValue: price.finalIndexValue,
      expectedInputCosts: expectedInputCosts,
      actualInputCosts: actualInputCosts,
    };
    return pricesAndYields;
  });

  return allPricesAndYields;
};

export const getTYield = async (insuranceOfferId: number): Promise<Nullable<number>> => {
  const tYield = await safeGet(admDb.yieldAndTYields, { insuranceOfferId: insuranceOfferId });

  return tYield?.transitionalAmount ?? null;
};

export const getBulkTYield = async (insuranceOfferIds: number[], insuranceOffers: InsuranceOffer[], year: number): Promise<BulkTransitionalYield[]> => {
  const priorYear = year - 1;
  const tYields = await safeWhere(admDb.yieldAndTYields, 'insuranceOfferId').anyOf(insuranceOfferIds).filter(tYield => tYield.priorCommodityYear === priorYear).toArray();

  const allTYields = tYields.map(tYield => {
    const planCode = insuranceOffers.find(offer => offer.insuranceOfferId === tYield.insuranceOfferId)?.insurancePlanCode;

    if (planCode === undefined) throw new Error('Unable to find plan code for TYield');

    const transitionalYield: BulkTransitionalYield = {
      insuranceOfferId: tYield.insuranceOfferId,
      insurancePlanCode: planCode,
      transitionalYield: tYield.transitionalAmount,
    };
    return transitionalYield;
  });

  return allTYields;
};

export const getTrendAdjustmentFactorsData = async (params: AdmDataForQuoteParams): Promise<TrendAdjustmentFactor[]> => {
  const insuranceOffers = await getInsuranceOffersForCommodityAndCounty(params.countyId, params.commodityCode).toArray();
  const insuranceOfferIds = insuranceOffers.map(io => io.insuranceOfferId);

  const optionRates = await safeWhere(admDb.optionRates, 'insuranceOfferId').anyOf(insuranceOfferIds)
    .and(or => or.optionCode === 'TA' && or.optionConversionFactor !== null).toArray();

  const trendAdjustmentFactors: TrendAdjustmentFactor[] = [];

  optionRates.forEach(or => {
    const insuranceOffer = insuranceOffers.find(io => io.insuranceOfferId === or.insuranceOfferId);
    if (insuranceOffer === undefined) return;
    if (or.optionConversionFactor === null) return;

    const trendAdjustmentFactor: TrendAdjustmentFactor = {
      typeId: insuranceOffer.typeId,
      practiceId: insuranceOffer.practiceId,
      planCode: insuranceOffer.insurancePlanCode,
      factor: or.optionConversionFactor,
    };

    trendAdjustmentFactors.push(trendAdjustmentFactor);
  });

  return trendAdjustmentFactors;
};

export const getHistoricalTYields = async (params: AdmDataForQuoteParams): Promise<HistoricalTYield[]> => {
  const insuranceOffers = await getInsuranceOffersForCommodityAndCounty(params.countyId, params.commodityCode).toArray();
  const insuranceOfferIds = insuranceOffers.map(io => io.insuranceOfferId);

  const tYields = await safeWhere(admDb.yieldAndTYields, 'insuranceOfferId').anyOf(insuranceOfferIds).toArray();

  const historicalTYields: HistoricalTYield[] = [];

  tYields.forEach(ty => {
    const insuranceOffer = insuranceOffers.find(io => io.insuranceOfferId === ty.insuranceOfferId);
    if (insuranceOffer === undefined) return;
    if (ty.priorCommodityYear === null) return;
    if (ty.priorTransitionalAmount === null) return;

    const historicalTYield: HistoricalTYield = {
      typeId: insuranceOffer.typeId,
      practiceId: insuranceOffer.practiceId,
      planCode: insuranceOffer.insurancePlanCode,
      year: ty.priorCommodityYear,
      priorTransitionalYield: ty.priorTransitionalAmount,
    };
    return historicalTYield;
  });

  return historicalTYields;
};

export const getCoverageLevels = async (insuranceOfferId: number, admInsuranceOfferId: Nullable<number>, coverageTypeCode: string, highRiskTypeId: HighRiskType): Promise<CoverageLevelDetails[]> => {

  //Filter between CAT/Non-CAT Coverage Levels
  const coverageLevelDifferentials = await getCoverageLevelDifferentialsForUnitScenario(insuranceOfferId, coverageTypeCode, highRiskTypeId);

  //Area plans
  if (admInsuranceOfferId !== null) {
    let areaCoverageLevels: AreaCoverageLevel[];
    if (coverageLevelDifferentials.length !== 0) {
      //Filter between CAT/Non-CAT Coverage Levels if available
      areaCoverageLevels = await safeWhere(admDb.areaCoverageLevels, { admInsuranceOfferId: admInsuranceOfferId })
        .and(acl => acl.coverageLevelId !== null)
        .and(acl => coverageLevelDifferentials.some(cld => cld.coverageLevelId === acl.coverageLevelId))
        .toArray();
    } else {
      areaCoverageLevels = await safeWhere(admDb.areaCoverageLevels, { admInsuranceOfferId: admInsuranceOfferId })
        .and(acl => acl.coverageLevelId !== null)
        .toArray();
    }

    const coverageLevelIds = filterNotNullOrUndefined(areaCoverageLevels.map(acl => acl.coverageLevelId));
    const coverageLevels = await safeWhere(admDb.coverageLevels, 'coverageLevelId').anyOf(coverageLevelIds).toArray();

    if (areaCoverageLevels.length !== 0) return areaCoverageLevels.map(acl => {
      const applicableCoverageLevel = coverageLevels.find(cl => cl.coverageLevelId === acl.coverageLevelId);

      const coverageLevelDetails: CoverageLevelDetails = {
        coverageLevelId: applicableCoverageLevel?.coverageLevelId ?? 0,
        coverageLevelPercent: applicableCoverageLevel?.coverageLevelPercent ?? 0,
        upperCoverageLevel: acl.areaLossStartPercent ?? 0,
        lowerCoverageLevel: acl.areaLossEndPercent ?? 0,
      };
      return coverageLevelDetails;
    });
  }

  //Non-area plans
  const coverageLevels = await safeWhere(admDb.coverageLevels, 'coverageLevelId').anyOf(coverageLevelDifferentials.map(cld => cld.coverageLevelId)).toArray();

  return coverageLevels.map(cl => {
    const coverageLevelDetails: CoverageLevelDetails = {
      coverageLevelId: cl.coverageLevelId,
      coverageLevelPercent: cl.coverageLevelPercent,
      upperCoverageLevel: cl.coverageLevelPercent,
      lowerCoverageLevel: 0,
    };
    return coverageLevelDetails;
  });
};

export const getYeYears = async (params: AdmDataForQuoteParams): Promise<YeYear[]> => {
  const insuranceOffers = await getInsuranceOffersForCommodityAndCounty(params.countyId, params.commodityCode).and(io => io.admInsuranceOfferId !== null).toArray();
  const admInsuranceOfferIds = insuranceOffers.map(io => io.admInsuranceOfferId) as number[];

  const yieldExclusions = await safeWhere(admDb.yieldExclusions, 'admInsuranceOfferId').anyOf(admInsuranceOfferIds).toArray();

  const yeYears: YeYear[] = [];

  yieldExclusions.forEach(ye => {
    const applicableInsuranceOffer = insuranceOffers.find(io => io.admInsuranceOfferId === ye.admInsuranceOfferId);

    if (applicableInsuranceOffer === undefined) return;

    const yeYear: YeYear = {
      typeId: applicableInsuranceOffer.typeId,
      practiceId: applicableInsuranceOffer.practiceId,
      planCode: applicableInsuranceOffer.insurancePlanCode,
      year: ye.eligibleYear,
    };

    yeYears.push(yeYear);
  });

  return yeYears;
};

export const getHistoricalYieldTrendsData = async (params: AdmDataForQuoteParams): Promise<HistoricalYieldTrend[]> => {
  const insuranceOffers = await getInsuranceOffersForCommodityAndCounty(params.countyId, params.commodityCode).and(io => io.historicalYieldTrendId !== null).toArray();
  const historicalYieldTrendIds = distinctBy(insuranceOffers.map(io => io.historicalYieldTrendId), x => x) as number[];

  const historicalYieldTrendYears = await safeWhere(admDb.historicalYieldTrendYears, 'historicalYieldTrendId').anyOf(historicalYieldTrendIds).toArray();

  const historicalYieldTrends: HistoricalYieldTrend[] = [];

  insuranceOffers.forEach(io => {
    const applicableHistoricalYieldTrendYear = historicalYieldTrendYears.find(hyty => hyty.historicalYieldTrendId === io.historicalYieldTrendId);

    if (applicableHistoricalYieldTrendYear === undefined) return;

    const historicalYieldTrend: HistoricalYieldTrend = {
      historicalYieldTrendId: applicableHistoricalYieldTrendYear.historicalYieldTrendId,
      countyId: params.countyId,
      typeId: io.typeId,
      practiceId: io.practiceId,
      insurancePlanCode: io.insurancePlanCode,
    };

    historicalYieldTrends.push(historicalYieldTrend);
  });

  return historicalYieldTrends;
};

export const getHistoricalYieldTrendYearsData = async (year: number, historicalYieldTrendIds: number[]): Promise<HistoricalYieldTrendYear[]> => {
  const historicals = await safeWhere(admDb.historicalYieldTrendYears, 'historicalYieldTrendId').anyOf(historicalYieldTrendIds).toArray();
  return historicals.map(hyty => {
    const historicalYieldTrendYear: HistoricalYieldTrendYear = {
      historicalYieldTrendId: hyty.historicalYieldTrendId,
      year: hyty.yieldYear,
      expectedYield: hyty.trendedYieldAmount ?? 0,
      actualYield: hyty.yieldAmount ?? 0,
    };
    return historicalYieldTrendYear;
  });
};

export const getHistoricalStormEventsData = async (params: AdmDataForQuoteParams): Promise<HistoricalStormEvent[]> => {
  const stateCode = getStateCodeFromCountyId(params.countyId);
  const countyCode = getCountyCodeFromCountyId(params.countyId);
  const historicals = await safeWhere(admDb.hipEventHistoricals, { stateCode: stateCode, countyCode: countyCode }).and(heh => isNotNullOrUndefined(heh.stormDate)).toArray();
  const historicalStormEvents = historicals.map(heh => {
    if (heh.stormDate === null) throw new Error('Storm date is null');

    const historicalStormEvent: HistoricalStormEvent = {
      name: heh.stormName,
      year: heh.commodityYear,
      date: heh.stormDate,
      insuranceOptionCode: heh.insuranceOptionCode,
    };
    return historicalStormEvent;
  });
  return historicalStormEvents.sort((a, b) => a.date > b.date ? 1 : -1);
};

export const getHarvestCostAmount = async (insuranceOfferId: number, highRiskTypeId: HighRiskType): Promise<number> => {
  const price = await safeWhere(admDb.prices,
    { insuranceOfferId: insuranceOfferId })
    .and(p => p.optionCode === null)
    .and(p => {
      switch (highRiskTypeId) {
        case HighRiskType.AllLand:
          return true;
        case HighRiskType.HighRiskOnly:
          return p.subCountyCode !== null && p.subCountyCode !== 'URA';
        case HighRiskType.URA:
          return p.subCountyCode === null || p.subCountyCode === 'URA';
        default:
          return p.subCountyCode === getSubCountyCodeFromId(highRiskTypeId);
      }
    })
    .first();
  return price?.harvestCostAmount ?? 0;
};

export const getMaximumContractPrice = async (insuranceOfferId: number, highRiskTypeId: HighRiskType): Promise<number> => {
  const price = await safeWhere(admDb.prices,
    { insuranceOfferId: insuranceOfferId })
    .and(p => p.optionCode === null)
    .and(p => {
      switch (highRiskTypeId) {
        case HighRiskType.AllLand:
          return true;
        case HighRiskType.HighRiskOnly:
          return p.subCountyCode !== null && p.subCountyCode !== 'URA';
        case HighRiskType.URA:
          return p.subCountyCode === null || p.subCountyCode === 'URA';
        default:
          return p.subCountyCode === getSubCountyCodeFromId(highRiskTypeId);
      }
    })
    .first();
  return price?.maximumContractPrice ?? 0;
};

export const getPriceGroupMembersData = async (params: AdmDataForQuoteParams): Promise<PriceGroupMember[]> => {
  return safeWhere(admDb.priceGroupMembers, { countyID: params.countyId }).and(pgm => pgm.practiceID.startsWith(params.commodityCode) && pgm.typeID.startsWith(params.commodityCode)).toArray();
};

export const getPriceHistoriesData = async (year: number, priceGroupIds: number[]): Promise<HistoricalPrice[]> => {
  return safeWhere(admDb.priceHistories, 'priceGroupID').anyOf(priceGroupIds).toArray();
};

export const getHipBaseRate = async (year: number, hipRateId: number): Promise<number> => {
  const hipRate = await safeGet(admDb.hipRates, { hipRateId: hipRateId });
  return hipRate?.hipBaseRate ?? 0;
};

//#endregion

//#region ICE DB

export const getGuaranteeAdjustment = async (commodityCode: string, insurancePlanCode: string, stateCode: string, countyCode: string,
  typeCode: string, practiceCode: string, highRiskTypeId: HighRiskType): Promise<Nullable<GuaranteeAdjustment>> => {
  const guaranteeAdjustment = await safeWhere(iceDb.guaranteeAdjustments, {
    commodityCode: commodityCode,
    insurancePlanCode: insurancePlanCode,
    stateCode: stateCode,
    countyCode: countyCode,
    typeCode: typeCode,
    practiceCode: practiceCode,
  })
    .and(p => {
      switch (highRiskTypeId) {
        case HighRiskType.AllLand:
          return true;
        case HighRiskType.HighRiskOnly:
          return p.subCountyCode !== null && p.subCountyCode !== 'URA';
        case HighRiskType.URA:
          return p.subCountyCode === null || p.subCountyCode === 'URA';
        default:
          return p.subCountyCode === getSubCountyCodeFromId(highRiskTypeId);
      }
    })
    .reverse().sortBy('subCountyCode');

  if (guaranteeAdjustment.length === 0) return null;

  return guaranteeAdjustment[0];
};

export const getMultipleCommodityAdjustmentFactor = async (multipleCroppingCode: string): Promise<number> => {
  const multipleCommodityAdjustmentFactor = 1;

  return multipleCommodityAdjustmentFactor;
};

export const getPreviousYearYieldLimitationCode = async (): Promise<string> => {
  const previousYearYieldLimitationCode = '00';

  return previousYearYieldLimitationCode;
};

export const getMaximumReplantGuaranteePerAcre = async (): Promise<number> => {
  const maximumReplantGuaranteePerAcre = 0;
  return maximumReplantGuaranteePerAcre;
};

export const getYieldConversionFactor = async (): Promise<number> => {
  const yieldConversionFactor = 1;
  return yieldConversionFactor;
};

export const getStageFactor = async (): Promise<{ stageCode: Nullable<string>, stagePercentFactor: number, stagePricePercentFactor: number, depreciationFactor: number }> => {
  const stageFactor = {
    stageCode: null,
    stagePercentFactor: 1,
    stagePricePercentFactor: 1,
    depreciationFactor: 0,
  };
  return stageFactor;
};

export const getPriceElections = async (insurancePlanCode: string, commodityCode: string, coverageTypeCode: string, stateCode: string): Promise<PriceElectionBounds[]> => {
  const priceElections = await safeWhere(iceDb.priceElectionPercents, {
    commodityCode: commodityCode,
    insurancePlanCode: insurancePlanCode,
    coverageTypeCode: coverageTypeCode,
    stateCode: stateCode,
  }).toArray();

  return priceElections.map(pe => {
    const priceElectionBounds: PriceElectionBounds = {
      lowerPriceElection: pe.priceElectionLowPercent ?? 1,
      upperPriceElection: pe.priceElectionHighPercent,
      coverageLevelPercent: pe.coverageLevelPercent,
    };
    return priceElectionBounds;
  });
};

export const getInsuranceOffersForCommodityAndCounty = (countyId: string, commodityCode: string): Collection<InsuranceOffer> => {
  return safeWhere(admDb.insuranceOffers, { countyId: countyId }).and(io => io.practiceId.startsWith(commodityCode) && io.typeId.startsWith(commodityCode));
};

//#endregion

//#region Quoter DB

export const getCalcQuoteData = async (scenarioPieceId: ScenarioPieceId) => {
  interface BusinessUnit {
    year: number;
    commodityCode: string;
    typeId: string;
    practiceId: string;
    countyId: string;
    subCountyCode: Nullable<string>;
    id: string;
  }

  const quoterTransactionTables = [
    quoterDb.rowCropScenarioPieces,
    quoterDb.rowCropScenarios,
    quoterDb.quotes,
    quoterDb.clientFiles,
    quoterDb.unitGroups,
    quoterDb.unitYears,
    quoterDb.scenarioQuickUnits,
  ];

  return quoterDb.transaction('r', quoterTransactionTables, async () => {
    const scenarioPiece = await safeGet(quoterDb.rowCropScenarioPieces, { scenarioPieceId: scenarioPieceId });
    const scenario = scenarioPiece === undefined ? undefined : await safeGet(quoterDb.rowCropScenarios, { scenarioId: scenarioPiece.scenarioId });
    const quote = scenario === undefined ? undefined : await safeGet(quoterDb.quotes, { quoteId: scenario.quoteId });
    const clientFile = quote === undefined ? undefined : await safeGet(quoterDb.clientFiles, { clientFileId: quote.clientFileId });

    if (quote === undefined || scenario === undefined || scenarioPiece === undefined || clientFile === undefined) throw new Error('Unexpected missing data when running calculations offline');

    const businessUnits: BusinessUnit[] = [];

    if (quote.quickQuote) {
      const quickUnit = await safeGet(quoterDb.scenarioQuickUnits, { scenarioId: scenario.scenarioId });

      if (quickUnit === undefined) throw new Error('Unable to find quick unit while quick quoting');

      businessUnits.push({
        ...quickUnit,
        year: clientFile.year,
        subCountyCode: null,
        id: quickUnit.scenarioQuickUnitId,
      });
    } else {
      const unitGroups = await safeWhere(quoterDb.unitGroups, { scenarioPieceId: scenarioPiece.scenarioPieceId }).toArray();
      const unitYearIds = unitGroups.flatMap(ug => ug.unitYearIds);
      const unitYears = await safeWhere(quoterDb.unitYears, 'unitYearId').anyOf(unitYearIds).toArray();
      businessUnits.push(...unitYears.map(uy => ({ ...uy, id: uy.unitYearId })));
    }

    if (businessUnits.length === 0) throw new Error('Unexpected missing units when running calculations offline');

    return {
      scenario,
      scenarioPiece,
      quote,
      units: businessUnits,
    };
  });
};

export const setAllScenarioOptionUnitYearsForScenarioData = async (scenarioId: ScenarioId, scenarioOptionUnitYears: FlatScenarioOptionUnitYear[]): Promise<void> => {
  const scenarioOptions = await safeWhere(quoterDb.scenarioOptions, { scenarioId: scenarioId }).toArray();
  const scenarioOptionIds = distinctBy(scenarioOptions.map(so => so.scenarioOptionId), x => x);
  const scenarioOptionUnitYearsFromDb = await safeWhere(quoterDb.scenarioOptionUnitYears, 'scenarioOptionId').anyOf(scenarioOptionIds).toArray();
  const scenarioOptionUnitYearsForScenarioInDb = new Map<ScenarioOptionUnitYearId, ScenarioOptionUnitYear>();
  scenarioOptionUnitYearsFromDb.forEach(souy => {
    scenarioOptionUnitYearsForScenarioInDb.set(souy.scenarioOptionUnitYearId, souy);
  });

  const scenarioOptionsInBatch = scenarioOptionUnitYears.map(souy => souy.scenarioOptionId);

  // For scenario options we can just get the ones that are in the batch, because this method will not be deleting items that don't exist in the batch.
  const scenarioOptionsFromDb = await safeWhere(quoterDb.scenarioOptions, 'scenarioOptionId').anyOf(scenarioOptionsInBatch).toArray();
  const scenarioOptionsInDb = new Map<ScenarioOptionId, ScenarioOption>();
  scenarioOptionsFromDb.forEach(so => {
    scenarioOptionsInDb.set(so.scenarioOptionId, so);
  });

  const scenarioOptionUnitYearIdsThatShouldBeKeptForThisScenario = new Set<ScenarioOptionUnitYearId>();

  scenarioOptionUnitYears.forEach(scenarioOptionUnitYear => {
    // Each scenarioOptionUnitYear in the batch should be kept in the database.
    scenarioOptionUnitYearIdsThatShouldBeKeptForThisScenario.add(scenarioOptionUnitYear.scenarioOptionUnitYearId);

    let scenarioOptionUnitYearInDb: ScenarioOptionUnitYear | undefined = scenarioOptionUnitYearsForScenarioInDb.get(scenarioOptionUnitYear.scenarioOptionUnitYearId);
    let scenarioOption: ScenarioOption | undefined = scenarioOptionsInDb.get(scenarioOptionUnitYear.scenarioOptionId);

    if (scenarioOption === undefined) {
      scenarioOption = {
        scenarioOptionId: scenarioOptionUnitYear.scenarioOptionId,
        scenarioId: scenarioId,
        option: scenarioOptionUnitYear.scenarioOptionCode as OptionCode,
        offlineCreatedOn: undefined,
        offlineLastUpdatedOn: undefined,
        offlineDeletedOn: undefined,
      };

      quoterDb.scenarioOptions.put(scenarioOption);

      // This is important to prevent code above from trying to add the same scenario option twice.
      // Multiple records may point to the same option.
      scenarioOptionsInDb.set(scenarioOption.scenarioOptionId, scenarioOption);
    }

    if (scenarioOptionUnitYearInDb === undefined) {
      scenarioOptionUnitYearInDb = {
        scenarioOptionUnitYearId: scenarioOptionUnitYear.scenarioOptionUnitYearId,
        scenarioOptionId: scenarioOption.scenarioOptionId,
        unitYearId: scenarioOptionUnitYear.unitYearId,
        offlineCreatedOn: undefined,
        offlineLastUpdatedOn: undefined,
        offlineDeletedOn: undefined,
      };

      quoterDb.scenarioOptionUnitYears.put(scenarioOptionUnitYearInDb);
    }
  });

  // Flag any scenario option unit years to delete that we didn't just see in the batch above.
  // This is because this method is intended to replace the entire state at a scenario id. Any state that isn't sent up
  // in this request is presumed to be deleted.
  scenarioOptionUnitYearsForScenarioInDb.forEach((scenarioOptionUnitYear, scenarioOptionUnitYearId) => {
    if (!scenarioOptionUnitYearIdsThatShouldBeKeptForThisScenario.has(scenarioOptionUnitYearId)) {
      softDelete(quoterDb.scenarioOptionUnitYears, scenarioOptionUnitYearId);
    }
  });
};

//#endregion
