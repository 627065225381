import { Grid } from '@mui/material';
import { AnalysisLineChart, LineData } from './charts/AnalysisLineChart';
import { useHistoricalRevenue } from '../../../hooks/useHistoricalRevenue';
import { ScenarioId, TrendlineAnalysisId } from '../../../types/api/PrimaryKeys';
import HistoricalRevenue from '../../../types/api/adm/HistoricalRevenue';
import { AcreageType } from '@silveus/calculations';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { addLineData, modifyLineData, selectLineDataByType } from '../../../app/trendlineAnalysisSlice';
import { TrendlineType } from '../../../types/api/enums/trendlineAnalysis/TrendlineType.enum';
import { useLineData } from '../../../hooks/useLineData';
import { getFormattedLineDataName } from '../../../utils/lineDataHelpers';
import { pickColor } from './charts/chartColors';
import { defaultYAxisGenerator } from './charts/lineCharts.utils';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import { useMemo } from 'react';

const defaultTrendlineDataKeys = ['expectedRevenue', 'actualRevenue'] as const;

interface RevenueChartProps {
  trendlineAnalysisId: TrendlineAnalysisId;
  scenarioId: ScenarioId;
  startYear: number;
  endYear: number;
  showLabels: boolean;
  acreageType: AcreageType;
}

const RevenueChart = ({ scenarioId, trendlineAnalysisId, startYear, endYear, showLabels, acreageType }: RevenueChartProps) => {
  const sortedChartData = useHistoricalRevenue(scenarioId, startYear, endYear, acreageType);
  const dispatch = useAppDispatch();
  const cachedLineData = useAppSelector(state => selectLineDataByType(state, trendlineAnalysisId, TrendlineType.Revenue));

  // Flatten the data so rechart could use it
  const { flattenedChartData, lineData } = useMemo(() => {
    const flattenedChartData = sortedChartData.map(historicalRevenue => {
      const productRevenues = historicalRevenue.productRevenues ?? [];

      const newHistoricalRevenue: Omit<HistoricalRevenue, 'productRevenues'> & { [key: string]: number | undefined } = {
        year: historicalRevenue.year,
        expectedRevenue: historicalRevenue.expectedRevenue,
        actualRevenue: historicalRevenue.actualRevenue,
        expectedCountyRevenue: historicalRevenue.expectedCountyRevenue,
        actualCountyRevenue: historicalRevenue.actualCountyRevenue,
        expectedMpRevenue: historicalRevenue.expectedMpRevenue,
        actualMpRevenue: historicalRevenue.actualMpRevenue,
      };

      for (const productRevenue of productRevenues) {
        newHistoricalRevenue[`${productRevenue.productTitle} Upper Cov. Lvl.`] = productRevenue.upperRevenueCoverageLevel;
        if (productRevenue.lowerRevenueCoverageLevel !== undefined && productRevenue.lowerRevenueCoverageLevel !== 0) newHistoricalRevenue[`${productRevenue.productTitle} Lower Cov. Lvl.`] = productRevenue.lowerRevenueCoverageLevel;
      }

      return newHistoricalRevenue;
    });

    const lineData: LineData<HistoricalRevenue>[] = [];

    for (const [index, key] of Object.keys(flattenedChartData[0]).entries()) {
      const chartData = key as keyof HistoricalRevenue;
      const isYearKey = chartData === 'year';
      const isMpValueWithNoData = (chartData === 'expectedMpRevenue' || chartData === 'actualMpRevenue') && flattenedChartData.every(data => data[chartData] === undefined);

      if (isYearKey || isMpValueWithNoData) continue;

      lineData.push({
        dataKey: chartData,
        color: pickColor(index),
        name: getFormattedLineDataName(chartData),
      });
    }

    return {
      flattenedChartData,
      lineData,
    };
  }, [sortedChartData]);


  const trendlineLineData = useLineData(lineData, trendlineAnalysisId, cachedLineData, TrendlineType.Revenue, new Set(defaultTrendlineDataKeys));
  if (cachedLineData.length === 0) {
    dispatch(addLineData({ lineData: trendlineLineData }));
  } else if (lineData.some(ld => !cachedLineData.find(x => x.dataKey === ld.dataKey) || cachedLineData.some(x => !lineData.find(ld => ld.dataKey === x.dataKey)))) {
    dispatch(modifyLineData({ lineData: trendlineLineData, trendlineAnalysisId: trendlineAnalysisId }));
  }

  const filteredLineData = lineData.filter(ld => cachedLineData.filter(x => x.isVisible).find(x => ld.dataKey === x.dataKey));

  const customYAxisTicks = useMemo(() => defaultYAxisGenerator(
    filteredLineData
      .map(ld => ld.dataKey)
      .flatMap(key => flattenedChartData.map(d => d[key]))
      .filter((v): v is number => isNotNullOrUndefined(v)),
  ), [filteredLineData, flattenedChartData]);

  return (
    <Grid container sx={{ height: '100%', p: 2 }}>
      <AnalysisLineChart
        data={flattenedChartData}
        lineData={filteredLineData}
        customYAxisTicks={customYAxisTicks}
        xAxisKey={'year'}
        showTooltip
        showLabels={showLabels}
      />
    </Grid>
  );
};

export default RevenueChart;
