import { ScenarioPieceType } from '@silveus/calculations';
import { getScenarioPieceDefinition } from '../../../../constants/productDefinitions/scenarioPieceDefinitionRecords';

/* eslint-disable no-unused-vars */
export enum ProductType {
  ABC = 100000001,
  AIM = 100000002,
  APC = 847050000,
  APO = 847050002,
  ARCH = 847050001,
  BAND = 100000004,
  BoostMax = 100000003,
  BPM = 847050003,
  CLIFF = 847050005,
  CountyAdvantage = 847050006,
  CYA = 847050007,
  ECO = 100000005,
  EnterprisePLUS = 847050008,
  EP = 847050009,
  ExcessMoisture = 847050010,
  EZHail = 847050011,
  GRRP = 847050012,
  Hail = 847050013,
  HarvestMAX = 847050014,
  HarvestPRO = 847050016,
  HeatSelect = 847050017,
  HPA = 847050018,
  ICE = 847050019,
  Livestock = 847050020,
  LPO = 100000000,
  MPCI = 847050021,
  MPD = 847050022,
  MPowerD = 847050023,
  MyECO = 100000006,
  MySCO = 100000007,
  NamedPeril = 847050024,
  PAR = 847050025,
  PARF = 847050004,
  PARflex = 371790001,
  PARflexPYO = 371790002,
  PARPYO = 847050015,
  PCI = 371790000,
  PF = 371790003,
  PFP = 371790006,
  PM_371790009 = 371790009,
  PM_968290000 = 968290000,
  PRFOU = 371790012,
  PriceFlex = 371790018,
  PriceFlexPrime = 371790021,
  PriceSelect = 371790024,
  PriceShield = 371790015,
  PYA = 371790027,
  PYAflex = 371790030,
  PYO = 371790033,
  PYOF = 371790036,
  RainSelect = 371790039,
  RainyDay = 371790042,
  RAMP = 371790045,
  REVBOOST = 371790048,
  RPO = 371790051,
  RPowerD = 371790054,
  RPP = 371790057,
  SCO = 100000008,
  StandaloneHail = 371790060,
  UnreleasedMPCI2014 = 371790063,
  UnreleasedMPCI2015 = 371790066,
  UnreleasedMPCI2016 = 371790069,
  UnreleasedMPCI2017 = 371790072,
  UnreleasedMPCI2018 = 371790075,
  UnreleasedMPCI2019 = 371790078,
  UnreleasedMPCI2020 = 371790081,
  UnreleasedMPCI2021 = 371790084,
  VIP = 371790087,
  Weather = 371790090
}

export const getProductTypeFromScenarioPiece = (scenarioPieceType: ScenarioPieceType) => {
  const scenarioPieceDefinition = getScenarioPieceDefinition(scenarioPieceType);
  return scenarioPieceDefinition.d365ProductType;
};