import { Box, Grid, Typography, useTheme } from '@mui/material';
import {
  ScenarioPieceResponseDTO,
  roundToPlaces,
  ScenarioPieceGroupResponseDTO
} from '@silveus/calculations';
import { ScenarioPieceCoverageResponseDTO } from '@silveus/calculations/dist/calculationOrchestrator/response/scenarioPieceCoverageResponseDTO';
import CurrencyText from '../../components/currencyText/currencyText.component';

type ScenarioPieceTooltipProps = {
  displayName: string;
  calcResults: ScenarioPieceResponseDTO | ScenarioPieceGroupResponseDTO | ScenarioPieceCoverageResponseDTO | null;
  showTriggerYield?: boolean;
  showDiscount?: boolean;
}

const ScenarioPieceTooltip = ({ displayName, calcResults, showTriggerYield = true, showDiscount = false }: ScenarioPieceTooltipProps) => {
  const theme = useTheme();
  if (calcResults === null) return null;
  const subsidyPercent = calcResults.totalPremium !== 0 ? roundToPlaces(calcResults.subsidyAmount / calcResults.totalPremium * 100, 1) : 0;
  const modifiedProducerPremiumPerAcre = -calcResults.producerPremiumPerAcre - calcResults.discountPerAcre;
  const modifiedProducerPremium = -calcResults.producerPremium - calcResults.discount;

  return (
    <Box p={2} pt={1} sx={{ width: '420px', border: `2px solid ${theme.palette.grey[400]}` }}>
      <Grid container item xs={12} height="12px">
        <Typography variant="h6" fontSize="1.125rem">{displayName}</Typography>
      </Grid>
      <Grid container item xs={12} sx={{ justifyContent: 'flex-end', height: '16px' }}>
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          <Typography variant="subtitle2">Per Acre</Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <Typography variant="subtitle2">Total</Typography>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={5}>
          <Typography variant="subtitle2" sx={{ textTransform: 'none' }}>$ of Coverage</Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          <CurrencyText currencyAmount={calcResults.protectionAmountPerAcre} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <CurrencyText currencyAmount={calcResults.protectionAmount} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>
        {showTriggerYield && <>
          <Grid item xs={5}>
            <Typography variant="subtitle2" sx={{ textTransform: 'none' }}>Trigger Yield</Typography>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: 'right' }}>
            <Typography variant="body2" sx={{ color: theme.palette.altText.grey, fontWeight: 800 }}>{calcResults.triggerYield}</Typography>
          </Grid>
        </>
        }

      </Grid>

      <Grid container item>
        <Grid item xs={5}>
          <Typography variant="body2">Unsubsidized Premium</Typography>
        </Grid>
        <Grid xs={3}>
          <CurrencyText currencyAmount={calcResults.totalPremiumPerAcre} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>
        <Grid xs={4}>
          <CurrencyText currencyAmount={calcResults.totalPremium} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>

        <Grid item xs={5} >
          <Typography variant="body2">{`Subsidy (${subsidyPercent}%)`}</Typography>
        </Grid>
        <Grid xs={3} sx={{ borderBottom: '1px solid #666666' }}>
          <CurrencyText currencyAmount={calcResults.subsidyAmountPerAcre} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>
        <Grid xs={4} sx={{ borderBottom: '1px solid #666666' }}>
          <CurrencyText currencyAmount={calcResults.subsidyAmount} sx={{ color: theme.palette.altText.grey }} colorize={false} align={'right'} />
        </Grid>

        <Grid item xs={5} mt="2px">
          <Typography variant="subtitle2" sx={{ textTransform: 'none' }}>Producer Premium</Typography>
        </Grid>
        <Grid xs={3} mt="2px">
          <CurrencyText currencyAmount={modifiedProducerPremiumPerAcre} colorize align={'right'} />
        </Grid>
        <Grid xs={4} mt="2px">
          <CurrencyText currencyAmount={modifiedProducerPremium} colorize align={'right'} />
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={5}>
          <Typography variant="body2">Indemnity</Typography>
        </Grid>
        <Grid xs={3}>
          <CurrencyText currencyAmount={calcResults.indemnityPerAcre} colorize align={'right'} />
        </Grid>
        <Grid xs={4}>
          <CurrencyText currencyAmount={calcResults.indemnityAmount} colorize align={'right'} />
        </Grid>

        <Grid item xs={5} >
          <Typography variant="body2">Producer Premium</Typography>
        </Grid>
        <Grid xs={3}>
          <CurrencyText currencyAmount={modifiedProducerPremiumPerAcre} colorize align={'right'} />
        </Grid>
        <Grid xs={4}>
          <CurrencyText currencyAmount={modifiedProducerPremium} colorize align={'right'} />
        </Grid>

        {showDiscount &&
        <>
          <Grid item xs={5} >
            <Typography variant="body2">MP Discount</Typography>
          </Grid>
          <Grid xs={3}>
            <CurrencyText currencyAmount={calcResults.discountPerAcre} colorize align={'right'} />
          </Grid>
          <Grid xs={4}>
            <CurrencyText currencyAmount={calcResults.discount} colorize align={'right'} />
          </Grid>
        </>
        }

        <Grid item xs={5} mt="2px">
          <Typography variant="subtitle2" sx={{ textTransform: 'none' }}>Net</Typography>
        </Grid>
        <Grid xs={3} mt="2px" sx={{ borderTop: `1px solid ${theme.palette.altText.grey}` }}>
          <CurrencyText currencyAmount={calcResults.indemnityPerAcre - calcResults.producerPremiumPerAcre} colorize align={'right'} />
        </Grid>
        <Grid xs={4} mt="2px" sx={{ borderTop: `1px solid ${theme.palette.altText.grey}` }}>
          <CurrencyText currencyAmount={calcResults.indemnityAmount - calcResults.producerPremium} colorize align={'right'} />
        </Grid>
      </Grid>


    </Box >
  );
};

export default ScenarioPieceTooltip;