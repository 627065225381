import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';
import ManageTemplateScenarioRow from './manageTemplateScenarioRow.component';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { modifyTemplateScenarios, removeTemplateScenarios, selectAllTemplateScenarios } from '../../../app/templateScenariosSlice';
import { TemplateScenario } from '../../../types/api/template-scenarios/TemplateScenario';
import { TemplateScenarioId } from '../../../types/api/PrimaryKeys';

export type ManageTemplateScenariosModalProps = {
  onClose: () => void;
}

export default function ManageTemplateScenariosModal({ onClose }: ManageTemplateScenariosModalProps) {
  const dispatch = useAppDispatch();

  const allTemplatesStore = useAppSelector(selectAllTemplateScenarios);
  const [allTemplates, setAllTemplates] = useState(allTemplatesStore);
  const [pendingUpdates, setPendingUpdates] = useState(new Map<TemplateScenarioId, TemplateScenario>());
  const [pendingDeletes, setPendingDeletes] = useState(new Map<TemplateScenarioId, TemplateScenario>());

  const [isSavingChanges, setIsSavingChanges] = useState(false);

  const handleTemplateScenarioChange = (template: TemplateScenario, index: number) => {
    const newPendingUpdates = new Map(pendingUpdates);
    newPendingUpdates.set(template.templateScenarioId, template);
    setPendingUpdates(newPendingUpdates);

    setAllTemplates([
      ...allTemplates.slice(0, index),
      template,
      ...allTemplates.slice(index + 1),
    ]);
  };

  const handleTemplateScenarioDelete = (template: TemplateScenario, index: number) => {
    const newPendingUpdates = new Map(pendingUpdates);
    newPendingUpdates.delete(template.templateScenarioId);
    setPendingUpdates(newPendingUpdates);

    const newPendingDeletes = new Map(pendingDeletes);
    newPendingDeletes.set(template.templateScenarioId, template);
    setPendingDeletes(newPendingDeletes);

    setAllTemplates([
      ...allTemplates.slice(0, index),
      ...allTemplates.slice(index + 1),
    ]);
  };

  const handleSave = async () => {
    setIsSavingChanges(true);

    await Promise.all([
      dispatch(modifyTemplateScenarios(Array.from(pendingUpdates.values()))),
      dispatch(removeTemplateScenarios(Array.from(pendingDeletes.values()))),
    ]);

    setPendingUpdates(new Map());
    setPendingDeletes(new Map());

    setIsSavingChanges(false);
  };

  const hasMadeChanges = pendingUpdates.size > 0 || pendingDeletes.size > 0;

  const isSaveDisabled = !hasMadeChanges
                          || isSavingChanges
                          || !isArraySizeValid(allTemplates, allTemplatesStore)
                          || allTemplates.some(isTemplateScenarioInvalid);

  return (
    <Dialog
      open={true}
      maxWidth={false}
      scroll="body"
      sx={{ m: 2 }}
      disableEscapeKeyDown={true}>
      <DialogTitle>Manage Scenario Templates</DialogTitle>
      <DialogContent>
        {
          allTemplates.length > 0
            ? allTemplates.map((template, idx) => (
              <ManageTemplateScenarioRow
                templateScenario={template}
                handleTemplateScenarioChange={newTemplate => handleTemplateScenarioChange(newTemplate, idx)}
                deleteTemplateScenario={() => handleTemplateScenarioDelete(template, idx)}
                key={template.templateScenarioId}
              />),
            )
            : <Typography>No Scenario Templates available.</Typography>
        }

        <DialogActions style={{ marginTop: '15px' }}>
          <Button
            id="create-template-scenario-cancel-button"
            variant="outlined"
            onClick={onClose}
            disabled={isSavingChanges}
          >
            CLOSE
          </Button>
          <Button
            id="create-template-scenario-save-button"
            variant="contained"
            disabled={isSaveDisabled}
            onClick={() => handleSave()}
          >
            {isSavingChanges ? 'SAVING...' : 'SAVE'}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

const isTemplateScenarioInvalid = (template: TemplateScenario): boolean => template.name.trim().length === 0;

const isArraySizeValid = (allTemplates: TemplateScenario[], allTemplatesStore: TemplateScenario[]): boolean => {
  if (allTemplates.length > 0) return true;

  // If the working array is empty but the store array is not,
  // allow save so that users can delete their last template
  return allTemplatesStore.length > 0;
};
