import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import { selectAllQuotesByClientFileMap } from '../../../app/quotesSlice';
import { ClientFileId, QuoteId } from '../../../types/api/PrimaryKeys';
import { useEffect, useMemo, useState } from 'react';
import { Quote } from '../../../types/api/Quote';
import { selectEligibleScenariosForApplication } from '../../../app/scenariosSlice';
import { getKeyedStateGroupedBy } from '../../../app/sliceHelpers';
import { filterNotNullOrUndefined } from '../../../utils/arrayUtils';

type QuoteSelectInputProps = {
  clientFileId: ClientFileId;
  selectedQuotes: QuoteId[];
  onChange: (quoteIds: QuoteId[]) => void;
};

const QuoteSelectInput = ({ clientFileId, selectedQuotes, onChange }: QuoteSelectInputProps) => {
  const quotes = useKeyMapSelector(selectAllQuotesByClientFileMap, clientFileId);
  const filteredScenarios = useAppSelector(selectEligibleScenariosForApplication);
  // We only want to show quotes in this list that have scenarios. Any empty quote can be ignored.
  const quotesWithScenarios = useMemo(() => {
    const scenariosGroupedByQuoteId = getKeyedStateGroupedBy(filteredScenarios, s => s.quoteId);
    const quoteList: Quote[] = [];
    quotes.forEach(q => {
      const scenarios = scenariosGroupedByQuoteId.get(q.quoteId) ?? [];
      if (scenarios.length > 0) {
        quoteList.push(q);
      }
    });

    return quoteList;
  }, [quotes]);
  const [selectedOptions, setSelectedOptions] = useState<QuoteId[]>([]);
  const areAllOptionsChecked = quotesWithScenarios.length > 0 && selectedQuotes.length === quotesWithScenarios.length;

  useEffect(() => {
    setSelectedOptions(selectedQuotes);
  }, [selectedQuotes]);

  const onSelectAllClicked = (allShouldBeChecked: boolean) => {
    const allOptions = quotesWithScenarios.map(x => x.quoteId);
    if (allShouldBeChecked) {
      onChange(allOptions);
    } else {
      onChange([]);
    }
  };

  const addRemoveQuote = (quote: Quote) => {
    const tempSelected = [...selectedOptions];
    const index = tempSelected.findIndex(x => x === quote.quoteId);
    if (index >= 0) {
      tempSelected.splice(index, 1);
    } else {
      tempSelected.push(quote.quoteId);
    }
    onChange(tempSelected);
  };


  return (
    <div>
      <FormControl sx={{ width: 350 }} size="medium">
        <InputLabel id="quote-multiple-checkbox-label">Quotes</InputLabel>
        <Select
          labelId="quote-multiple-checkbox-label"
          id="quote-multiple-checkbox"
          multiple
          value={selectedOptions}
          input={<OutlinedInput label="Quotes" />}
          renderValue={selected => filterNotNullOrUndefined(selected.map(x => quotesWithScenarios.find(q => q.quoteId === x)?.name)).join(', ')}
        >
          <MenuItem onClick={() => onSelectAllClicked(!areAllOptionsChecked)}>
            <Checkbox checked={areAllOptionsChecked} indeterminate={!areAllOptionsChecked && selectedQuotes.length > 0} />
            <ListItemText primary={areAllOptionsChecked ? 'Unselect All' : 'Select All'} />
          </MenuItem>
          {quotesWithScenarios.map(q => (
            <MenuItem key={q.quoteId} value={q.quoteId} onClick={() => addRemoveQuote(q)}>
              <Checkbox checked={selectedOptions.findIndex(x => x === q.quoteId) >= 0} />
              <ListItemText primary={q.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default QuoteSelectInput;