import { useFormContext } from 'react-hook-form';
import { Nullable } from '../../../../types/util/Nullable';
import usePercentChange from '../../../../hooks/usePercentChange';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectOfferActualInputCosts } from '../../../../app/admSlice';
import { useEffect } from 'react';
import { RowCropScenarioPiece } from '../../../../types/api/RowCropScenarioPiece';
import MpActualInputCostsInput, { MpActualInputCostsProps } from './mpActualInputCosts';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';

interface ScenarioPieceMpActualInputCostsProps extends MpActualInputCostsProps {
  commodityCode: string;
  year: number;
  scenarioPiece: Nullable<RowCropScenarioPiece>
}

export const ScenarioPieceMpActualInputCostsInput = ({ scenarioPiece, actualInputCosts, sx, commodityCode, year, autoFocus = false, disabled = false }: ScenarioPieceMpActualInputCostsProps) => {
  const fieldName = ExtendedDataFormInputParameterNames.ActualInputCosts;
  const { trigger, setValue } = useFormContext();
  const offerActualInputCosts = useAppSelector(selectOfferActualInputCosts);

  usePercentChange(ExtendedDataFormInputParameterNames.ExpectedInputCosts, fieldName, 'inputCostsSlider');

  useEffect(() => {
    //We only want to change the value if it is a new scenario and offer expected
    // and actual input costs are loaded for the first time
    if (scenarioPiece !== null || offerActualInputCosts === null) return;

    setValue(fieldName, offerActualInputCosts);
    trigger(fieldName);
  }, [offerActualInputCosts]);

  return (
    <MpActualInputCostsInput
      commodityCode={commodityCode}
      year={year}
      actualInputCosts={actualInputCosts}
      sx={sx}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  );
};
