import { Nullable } from '../types/util/Nullable';
import { RootState } from './store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OnlineState {
  isNetworkAvailable: boolean;

  userNetworkEnabled: boolean;

  /** The most recent timestamp the client knows about where it was fully synchronized with the server. */
  lastOfflineSynchronizationIsoString: Nullable<string>;
  /** The last time that offline bulk data was completely downloaded. */
  lastOfflineBulkDataDownloadIsoString: Nullable<string>;
}

const initialState: OnlineState = {
  isNetworkAvailable: navigator.onLine,

  // Warning: Below this point, all properties are mapped to the redux persist whitelist.
  // Be aware of this before attempting to rename these properties (there is no compile check).
  userNetworkEnabled: true,
  lastOfflineSynchronizationIsoString: null,
  lastOfflineBulkDataDownloadIsoString: null,
};

export const onlineSlice = createSlice({
  name: 'online',
  initialState: initialState,
  reducers: {
    networkStatusUpdated(state, action: PayloadAction<boolean>) {
      state.isNetworkAvailable = action.payload;
    },
    shouldForceOfflineUpdated(state, action: PayloadAction<boolean>) {
      state.userNetworkEnabled = action.payload;
    },
    setLastOfflineSynchronizationTime(state, action: PayloadAction<string | null>) {
      state.lastOfflineSynchronizationIsoString = action.payload;
    },
    setLastOfflineBulkDataDownloadTime(state, action: PayloadAction<string>) {
      state.lastOfflineBulkDataDownloadIsoString = action.payload;
    },
  },
});

export const { networkStatusUpdated, shouldForceOfflineUpdated, setLastOfflineBulkDataDownloadTime, setLastOfflineSynchronizationTime } = onlineSlice.actions;


export const selectIsOnline = (state: RootState) => state.online.isNetworkAvailable && state.online.userNetworkEnabled;
export const selectUserNetworkEnabled = (state: RootState) => state.online.userNetworkEnabled;
export const selectLastOfflineSynchronizationIsoString = (state: RootState) => state.online.lastOfflineSynchronizationIsoString;
export const selectLastOfflineBulkDataDownloadIsoString = (state: RootState) => state.online.lastOfflineBulkDataDownloadIsoString;

export default onlineSlice.reducer;