import { ScenarioPieceType } from '@silveus/calculations';
import { HailProduct } from '../types/api/hail/hailProduct';
import { isNullOrUndefined } from '../utils/nullHandling';

enum ProductionCoverageLevelGroupType {
  Production = 'Production',
  ProductionFirst = 'Production First',
  PersonalizedProduction = 'Personalized Production',
  ProductionEu = 'Production EU',
  ProductionWf = 'Production WF',
  ProductionDeductible = 'Production Deductible',
}

export type PolicyRegexType = {
  regexString: string;
  policyTypeName: string;
  policyType: ScenarioPieceType;
}

type StandardPolicyRegexType = PolicyRegexType;

type CoverageLevelGroupType = {
  productionCoverageLevelGroup?: ProductionCoverageLevelGroupType;
}

type ProductionPolicyRegexType = PolicyRegexType & CoverageLevelGroupType;

export type AipType = {
  aipName: string;
  standardPolicyRegexes: StandardPolicyRegexType[];
  productionPolicyRegexes?: ProductionPolicyRegexType[];
}

type HailPlanToScenarioPieceType = {
  years: {
    year: number,
    aips: AipType[];
  }[];
}

export const parseProductNameToScenarioPieceType = (product: HailProduct) => {
  // Find the matching aip type of the most recent year that has it (and is equal to or prior to the product crop year)
  const aipType = (HailPlanData.years
    .filter(year => year.year <= product.cropYear)
    .sort((a, b) => b.year - a.year)
    .find(year => year.aips.some(aip => aip.aipName.toUpperCase() === product.aipAbbreviation.toUpperCase()))?.aips
    .find(aip => aip.aipName.toUpperCase() === product.aipAbbreviation.toUpperCase()));

  if (isNullOrUndefined(aipType)) return null;

  if (product.isProduction) {
    if (aipType.productionPolicyRegexes === undefined) return null;
    for (const policy of aipType.productionPolicyRegexes) {
      const regex = new RegExp(policy.regexString, 'i');
      if (regex.test(product.productName)) {
        return policy;
      }
    }
  } else {
    for (const policy of aipType.standardPolicyRegexes) {
      const regex = new RegExp(policy.regexString, 'i');
      if (regex.test(product.productName)) {
        return policy;
      }
    }
  }

  return null;
};

export const HailPlanData: HailPlanToScenarioPieceType = {
  years: [
    {
      year: 2019,
      aips: [
        {
          aipName: 'PROAG',
          standardPolicyRegexes: [
            {
              regexString: '\\b(BASIC)$',
              policyTypeName: 'Basic',
              policyType: ScenarioPieceType.BASICStandardHail,
            },
            {
              regexString: '\\b(BASIC)\\s*\\-\\s*NO\\s*MINIMUM$',
              policyTypeName: 'Basic',
              policyType: ScenarioPieceType.BASICStandardHail,
            },
            {
              regexString: '\\bxs50ip$',
              policyTypeName: 'XS50IP',
              policyType: ScenarioPieceType.XS50IPStandardHail,
            },
            {
              regexString: '\\bdd20$',
              policyTypeName: 'DD2050',
              policyType: ScenarioPieceType.DD2050StandardHail,
            },
            {
              regexString: '\\bd20$',
              policyTypeName: 'D20',
              policyType: ScenarioPieceType.D20StandardHail,
            },
            {
              regexString: '\\b(COMP\\s*1\\.5)$',
              policyTypeName: 'COMP1POINT5',
              policyType: ScenarioPieceType.COMP1POINT5StandardHail,
            },
            {
              regexString: '\\b(COMP\\s*2)$',
              policyTypeName: 'COMP2',
              policyType: ScenarioPieceType.COMP2StandardHail,
            },
            {
              regexString: '\\b(COMP\\s*2-10)$',
              policyTypeName: 'COMP2D10',
              policyType: ScenarioPieceType.COMP2D10StandardHail,
            },
            {
              regexString: '\\b(COMP\\s*2-15)$',
              policyTypeName: 'COMP2D15',
              policyType: ScenarioPieceType.COMP2D15StandardHail,
            },
            {
              regexString: '\\b(COMP\\s*2\\+)$',
              policyTypeName: 'COMP2PLUS',
              policyType: ScenarioPieceType.COMP2PLUSStandardHail,
            },
            {
              regexString: '\\b(COMP\\s*3)$',
              policyTypeName: 'COMP3',
              policyType: ScenarioPieceType.COMP3StandardHail,
            },
            {
              regexString: '\\b(COMP\\s*3\\-10)$',
              policyTypeName: 'COMP3D10',
              policyType: ScenarioPieceType.COMP3D10StandardHail,
            },
            {
              regexString: '\\b(COMP\\s*3\\-15)$',
              policyTypeName: 'COMP3D15',
              policyType: ScenarioPieceType.COMP3D15StandardHail,
            },
            {
              regexString: '\\b(COMP\\s*3\\+)$',
              policyTypeName: 'COMP3PLUS',
              policyType: ScenarioPieceType.COMP3PLUSStandardHail,
            },
            {
              regexString: '\\b(COMP\\s*4)$',
              policyTypeName: 'COMP4',
              policyType: ScenarioPieceType.COMP4StandardHail,
            },
            {
              regexString: '\\b(dda)$',
              policyTypeName: 'DDA',
              policyType: ScenarioPieceType.DDAStandardHail,
            },
            {
              regexString: '\\b(ddb)$',
              policyTypeName: 'DDB',
              policyType: ScenarioPieceType.DDBStandardHail,
            },
            {
              regexString: '\\b(ddc)$',
              policyTypeName: 'DDC',
              policyType: ScenarioPieceType.DDCStandardHail,
            },
            {
              regexString: '\\b(dxs5)$',
              policyTypeName: 'DXS5',
              policyType: ScenarioPieceType.DXS5StandardHail,
            },
            {
              regexString: '\\b(dxs10)$',
              policyTypeName: 'DXS10',
              policyType: ScenarioPieceType.DXS10StandardHail,
            },
            {
              regexString: '\\b(dxs25)$',
              policyTypeName: 'DXS25',
              policyType: ScenarioPieceType.DXS25StandardHail,
            },
            {
              regexString: '\\b(xs5)$',
              policyTypeName: 'XS5',
              policyType: ScenarioPieceType.XS5StandardHail,
            },
            {
              regexString: '\\b(xs10)$',
              policyTypeName: 'XS10',
              policyType: ScenarioPieceType.XS10StandardHail,
            },
            {
              regexString: '\\b(xs15)$',
              policyTypeName: 'XS15',
              policyType: ScenarioPieceType.XS15StandardHail,
            },
            {
              regexString: '\\b(xs20)$',
              policyTypeName: 'XS20',
              policyType: ScenarioPieceType.XS20StandardHail,
            },
            {
              regexString: '\\b(xs25)$',
              policyTypeName: 'XS25',
              policyType: ScenarioPieceType.XS25StandardHail,
            },
            {
              regexString: '\\b(xs10ip)$',
              policyTypeName: 'XS10IP',
              policyType: ScenarioPieceType.XS10IPStandardHail,
            },
            {
              regexString: '\\b(xs15ip)$',
              policyTypeName: 'XS15IP',
              policyType: ScenarioPieceType.XS15IPStandardHail,
            },
            {
              regexString: '\\b(xs20ip)$',
              policyTypeName: 'XS20IP',
              policyType: ScenarioPieceType.XS20IPStandardHail,
            },
          ],
          productionPolicyRegexes: [
            {
              regexString: '\\b(CPP)[\\d\\s\\-]*\\(No Min loss\\)',
              policyTypeName: 'CPP',
              policyType: ScenarioPieceType.CPPProductionHail,
            },
            {
              regexString: '\\b(CPP)\\s$',
              policyTypeName: 'CPP',
              policyType: ScenarioPieceType.CPPProductionHail,
            },
            {
              regexString: '\\b(CPP)[\\d\\s\\-]*\\(5% Min loss\\)',
              policyTypeName: 'CPP5',
              policyType: ScenarioPieceType.CPP5ProductionHail,
            },
          ],
        },
        {
          aipName: 'RCIS',
          standardPolicyRegexes: [
            {
              regexString: '\\bbasic$',
              policyTypeName: 'Basic',
              policyType: ScenarioPieceType.BASICStandardHail,
            },
            {
              regexString: '\\bcomp 2\\+$',
              policyTypeName: 'COMP2PLUS',
              policyType: ScenarioPieceType.COMP2PLUSStandardHail,
            },
            {
              regexString: '\\bcomp 2$',
              policyTypeName: 'COMP2',
              policyType: ScenarioPieceType.COMP2StandardHail,
            },
            {
              regexString: '\\bcomp 2\\-10$',
              policyTypeName: 'COMP2D10',
              policyType: ScenarioPieceType.COMP2D10StandardHail,
            },
            {
              regexString: '\\bcomp\\s*3\\-10$',
              policyTypeName: 'COMP3D10',
              policyType: ScenarioPieceType.COMP3D10StandardHail,
            },
            {
              regexString: '\\bcomp 2\\-15$',
              policyTypeName: 'COMP2D15',
              policyType: ScenarioPieceType.COMP2D15StandardHail,
            },
            {
              regexString: '\\b(comp\\s*1\\.5)$',
              policyTypeName: 'COMP1POINT5',
              policyType: ScenarioPieceType.COMP1POINT5StandardHail,
            },
            {
              regexString: '\\bcomp\\s*3\\-10$',
              policyTypeName: 'COMP3D10',
              policyType: ScenarioPieceType.COMP3D10StandardHail,
            },
            {
              regexString: '\\bcomp\\s*3\\-15$',
              policyTypeName: 'COMP3D15',
              policyType: ScenarioPieceType.COMP3D15StandardHail,
            },
            {
              regexString: '\\bcomp 3$',
              policyTypeName: 'COMP3',
              policyType: ScenarioPieceType.COMP3StandardHail,
            },
            {
              regexString: '\\bcomp 3\\+$',
              policyTypeName: 'COMP3PLUS',
              policyType: ScenarioPieceType.COMP3PLUSStandardHail,
            },
            {
              regexString: '\\bcomp[ ]*65$',
              policyTypeName: 'COMP65',
              policyType: ScenarioPieceType.COMP65StandardHail,
            },
            {
              regexString: '\\bcomp[ ]*70$',
              policyTypeName: 'COMP70',
              policyType: ScenarioPieceType.COMP70StandardHail,
            },
            {
              regexString: '\\bcomp[ ]*75$',
              policyTypeName: 'COMP75',
              policyType: ScenarioPieceType.COMP75StandardHail,
            },
            {
              regexString: '\\bcomp[ ]*80$',
              policyTypeName: 'COMP80',
              policyType: ScenarioPieceType.COMP80StandardHail,
            },
            {
              regexString: '\\bdd20$',
              policyTypeName: 'DD2050',
              policyType: ScenarioPieceType.DD2050StandardHail,
            },
            {
              regexString: '\\bdda$',
              policyTypeName: 'DDA',
              policyType: ScenarioPieceType.DDAStandardHail,
            },
            {
              regexString: '\\bddb$',
              policyTypeName: 'DDB',
              policyType: ScenarioPieceType.DDBStandardHail,
            },
            {
              regexString: '\\bdxs10$',
              policyTypeName: 'DXS10',
              policyType: ScenarioPieceType.DXS10StandardHail,
            },
            {
              regexString: '\\bdxs25$',
              policyTypeName: 'DXS25',
              policyType: ScenarioPieceType.DXS25StandardHail,
            },
            {
              regexString: '\\bdxs5$',
              policyTypeName: 'DXS5',
              policyType: ScenarioPieceType.DXS5StandardHail,
            },
            {
              regexString: '\\bxs10ip$',
              policyTypeName: 'XS10IP',
              policyType: ScenarioPieceType.XS10IPStandardHail,
            },
            {
              regexString: '\\bxs20ip$',
              policyTypeName: 'XS20IP',
              policyType: ScenarioPieceType.XS20IPStandardHail,
            },
          ],
          productionPolicyRegexes: [
            {
              regexString: '\\bhpp[0-9]+\\-[0-9]+$',
              policyTypeName: 'HPP',
              policyType: ScenarioPieceType.HPPProductionHail,
            },
            {
              regexString: 'hpp base rate',
              policyTypeName: 'HPP',
              policyType: ScenarioPieceType.HPPProductionHail,
            },
          ],
        },
        {
          aipName: 'NAU',
          standardPolicyRegexes: [
            {
              regexString: '\\bbasic$',
              policyTypeName: 'Basic',
              policyType: ScenarioPieceType.BASICStandardHail,
            },
            {
              regexString: '\\bbasic\\W*5\\W*min$',
              policyTypeName: 'Basic5Min',
              policyType: ScenarioPieceType.BASIC5MinStandardHail,
            },
            {
              regexString: '\\bcomp 2$',
              policyTypeName: 'COMP2',
              policyType: ScenarioPieceType.COMP2StandardHail,
            },
            {
              regexString: '\\bcomp 2\\+$',
              policyTypeName: 'COMP2PLUS',
              policyType: ScenarioPieceType.COMP2PLUSStandardHail,
            },
            {
              regexString: '\\bcomp 2\\W10$',
              policyTypeName: 'COMP2D10',
              policyType: ScenarioPieceType.COMP2D10StandardHail,
            },
            {
              regexString: '\\bcomp 2\\W15$',
              policyTypeName: 'COMP2D15',
              policyType: ScenarioPieceType.COMP2D15StandardHail,
            },
            {
              regexString: '\\bcomp\\s3\\/10$',
              policyTypeName: 'COMP3D10',
              policyType: ScenarioPieceType.COMP3D10StandardHail,
            },
            {
              regexString: '\\bcomp\\s3\\/15$',
              policyTypeName: 'COMP3D15',
              policyType: ScenarioPieceType.COMP3D15StandardHail,
            },
            {
              regexString: '\\bcomp 3$',
              policyTypeName: 'COMP3',
              policyType: ScenarioPieceType.COMP3StandardHail,
            },
            {
              regexString: '\\bcomp 3\\+$',
              policyTypeName: 'COMP3PLUS',
              policyType: ScenarioPieceType.COMP3PLUSStandardHail,
            },
            {
              regexString: '\\bcomp 4$',
              policyTypeName: 'COMP4',
              policyType: ScenarioPieceType.COMP4StandardHail,
            },
            {
              regexString: '\\bcomp 4\\+$',
              policyTypeName: 'COMP4PLUS',
              policyType: ScenarioPieceType.COMP4PLUSStandardHail,
            },
            {
              regexString: '\\bdda$',
              policyTypeName: 'DDA',
              policyType: ScenarioPieceType.DDAStandardHail,
            },
            {
              regexString: '\\bddb$',
              policyTypeName: 'DDB',
              policyType: ScenarioPieceType.DDBStandardHail,
            },
            {
              regexString: '\\bddc$',
              policyTypeName: 'DDC',
              policyType: ScenarioPieceType.DDCStandardHail,
            },
            {
              regexString: '\\bdxs10$',
              policyTypeName: 'DXS10',
              policyType: ScenarioPieceType.DXS10StandardHail,
            },
            {
              regexString: '\\bdxs20$',
              policyTypeName: 'DXS20',
              policyType: ScenarioPieceType.DXS20StandardHail,
            },
            {
              regexString: '\\bdxs25$',
              policyTypeName: 'DXS25',
              policyType: ScenarioPieceType.DXS25StandardHail,
            },
            {
              regexString: '\\bdxs5$',
              policyTypeName: 'DXS5',
              policyType: ScenarioPieceType.DXS5StandardHail,
            },
            {
              regexString: '\\bxs[ ]*20$',
              policyTypeName: 'XS20',
              policyType: ScenarioPieceType.XS20StandardHail,
            },
            {
              regexString: '\\bxs[ ]*25$',
              policyTypeName: 'XS25',
              policyType: ScenarioPieceType.XS25StandardHail,
            },
            {
              regexString: '\\bxs[ ]*10$',
              policyTypeName: 'XS10',
              policyType: ScenarioPieceType.XS10StandardHail,
            },
            {
              regexString: '\\bxs[ ]*5$',
              policyTypeName: 'XS5',
              policyType: ScenarioPieceType.XS5StandardHail,
            },
            {
              regexString: '\\bxs[ ]*15$',
              policyTypeName: 'XS15',
              policyType: ScenarioPieceType.XS15StandardHail,
            },
            {
              regexString: '\\bxs[ ]*10ip$',
              policyTypeName: 'XS10IP',
              policyType: ScenarioPieceType.XS10IPStandardHail,
            },
            {
              regexString: '\\bxs[ ]*15ip$',
              policyTypeName: 'XS15IP',
              policyType: ScenarioPieceType.XS15IPStandardHail,
            },
            {
              regexString: '\\bxs[ ]*20ip$',
              policyTypeName: 'XS20IP',
              policyType: ScenarioPieceType.XS20IPStandardHail,
            },
          ],
        },
        {
          aipName: 'FMH',
          standardPolicyRegexes: [
            {
              regexString: '\\b(xs10ip\\s*excess\\s*of\\s*10\\%).*$',
              policyTypeName: 'XS10IP',
              policyType: ScenarioPieceType.XS10IPStandardHail,
            },
            {
              regexString: '\\b(basic\\s*0\\%).*$',
              policyTypeName: 'Basic',
              policyType: ScenarioPieceType.BASICStandardHail,
            },
            {
              regexString: '\\b(0\\-ip2\\s*0\\%).*$',
              policyTypeName: 'IP2F',
              policyType: ScenarioPieceType.IP2FStandardHail,
            },
            {
              regexString: '\\b(xs10\\-ip3\\s*excess\\sof\\s10\\%).*$',
              policyTypeName: 'IP3D10',
              policyType: ScenarioPieceType.IP3D10StandardHail,
            },
            {
              regexString: '\\b(xs10\\-ip2\\s*excess\\s*of\\s*10\\%).*$',
              policyTypeName: 'IP2D10',
              policyType: ScenarioPieceType.IP2D10StandardHail,
            },
            {
              regexString: '\\b(xs15\\-ip3\\s*excess\\sof\\s15\\%).*$',
              policyTypeName: 'IP3D15',
              policyType: ScenarioPieceType.IP3D15StandardHail,
            },
            {
              regexString: '\\b(0\\-ip3\\s*0\\%).*$',
              policyTypeName: 'IP3F',
              policyType: ScenarioPieceType.IP3FStandardHail,
            },
            {
              regexString: '\\b(dda\\s*10\\%).*$',
              policyTypeName: 'DDA',
              policyType: ScenarioPieceType.DDAStandardHail,
            },
            {
              regexString: '\\b(ddb\\s*20\\%).*$',
              policyTypeName: 'DDB',
              policyType: ScenarioPieceType.DDBStandardHail,
            },
            {
              regexString: '\\b(ddc\\s*30\\%).*$',
              policyTypeName: 'DDC',
              policyType: ScenarioPieceType.DDCStandardHail,
            },
            {
              regexString: '\\b(dxs5)',
              policyTypeName: 'DXS5',
              policyType: ScenarioPieceType.DXS5StandardHail,
            },
            {
              regexString: '\\bdxs10$',
              policyTypeName: 'DXS10',
              policyType: ScenarioPieceType.DXS10StandardHail,
            },
            {
              regexString: '\\b(dxs20)',
              policyTypeName: 'DXS20',
              policyType: ScenarioPieceType.DXS20StandardHail,
            },
            {
              regexString: '\\bxs10$',
              policyTypeName: 'XS10',
              policyType: ScenarioPieceType.XS10StandardHail,
            },
            {
              regexString: '\\bxs15$',
              policyTypeName: 'XS15',
              policyType: ScenarioPieceType.XS15StandardHail,
            },
            {
              regexString: '\\bxs20$',
              policyTypeName: 'XS20',
              policyType: ScenarioPieceType.XS20StandardHail,
            },
            {
              regexString: '\\bxs5$',
              policyTypeName: 'XS5',
              policyType: ScenarioPieceType.XS5StandardHail,
            },
            {
              regexString: '\\b(xs20ip)',
              policyTypeName: 'XS20IP',
              policyType: ScenarioPieceType.XS20IPStandardHail,
            },
            {
              regexString: '\\b(xs5-ip2)',
              policyTypeName: 'IP2',
              policyType: ScenarioPieceType.IP2StandardHail,
            },
            {
              regexString: '\\b(xs5-ip3)',
              policyTypeName: 'IP3',
              policyType: ScenarioPieceType.IP3StandardHail,
            },
          ],
          productionPolicyRegexes: [
            {
              regexString: '\\bml0pro.*$',
              policyTypeName: 'ML0Pro',
              policyType: ScenarioPieceType.ML0PROProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.Production,
            },
            {
              regexString: '\\bml5pro.*$',
              policyTypeName: 'ML5Pro',
              policyType: ScenarioPieceType.ML5PROProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.Production,
            },
            {
              regexString: '\\bml10pro.*$',
              policyTypeName: 'ML10Pro',
              policyType: ScenarioPieceType.ML10PROProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.Production,
            },
            {
              regexString: '\\bppmax.*$',
              policyTypeName: 'PPMAX',
              policyType: ScenarioPieceType.PPMAXProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.ProductionFirst,
            },
          ],
        },
        {
          aipName: 'HUDSON',
          standardPolicyRegexes: [
            {
              regexString: '\\bbasic$',
              policyTypeName: 'Basic',
              policyType: ScenarioPieceType.BASICStandardHail,
            },
            {
              regexString: '\\b(cp2\\/10)',
              policyTypeName: 'COMP2D10',
              policyType: ScenarioPieceType.COMP2D10StandardHail,
            },
            {
              regexString: '\\bcp2$',
              policyTypeName: 'COMP2',
              policyType: ScenarioPieceType.COMP2StandardHail,
            },
            {
              regexString: '\\bcp3$',
              policyTypeName: 'COMP3',
              policyType: ScenarioPieceType.COMP3StandardHail,
            },
            {
              regexString: '\\bcp4$',
              policyTypeName: 'COMP4',
              policyType: ScenarioPieceType.COMP4StandardHail,
            },
            {
              regexString: '\\bdda$',
              policyTypeName: 'DDA',
              policyType: ScenarioPieceType.DDAStandardHail,
            },
            {
              regexString: '\\bddb$',
              policyTypeName: 'DDB',
              policyType: ScenarioPieceType.DDBStandardHail,
            },
            {
              regexString: '\\bddc$',
              policyTypeName: 'DDC',
              policyType: ScenarioPieceType.DDCStandardHail,
            },
            {
              regexString: '\\bdxs10$',
              policyTypeName: 'DXS10',
              policyType: ScenarioPieceType.DXS10StandardHail,
            },
            {
              regexString: '\\bdxs5$',
              policyTypeName: 'DXS5',
              policyType: ScenarioPieceType.DXS5StandardHail,
            },
            {
              regexString: '\\bxs10$',
              policyTypeName: 'XS10',
              policyType: ScenarioPieceType.XS10StandardHail,
            },
            {
              regexString: '\\bxs10ip$',
              policyTypeName: 'XS10IP',
              policyType: ScenarioPieceType.XS10IPStandardHail,
            },
            {
              regexString: '\\bxs15$',
              policyTypeName: 'XS15',
              policyType: ScenarioPieceType.XS15StandardHail,
            },
            {
              regexString: '\\bxs15ip$',
              policyTypeName: 'XS15IP',
              policyType: ScenarioPieceType.XS15IPStandardHail,
            },
            {
              regexString: '\\bxs20$',
              policyTypeName: 'XS20',
              policyType: ScenarioPieceType.XS20StandardHail,
            },
            {
              regexString: '\\bxs20ip$',
              policyTypeName: 'XS20IP',
              policyType: ScenarioPieceType.XS20IPStandardHail,
            },
            {
              regexString: '\\bxs5$',
              policyTypeName: 'XS5',
              policyType: ScenarioPieceType.XS5StandardHail,
            },
            {
              regexString: '\\bb\\W*c\\W*2$',
              policyTypeName: 'BC2',
              policyType: ScenarioPieceType.BC2StandardHail,
            },
            {
              regexString: '\\bb\\W*c\\W*3$',
              policyTypeName: 'BC3',
              policyType: ScenarioPieceType.BC3StandardHail,
            },
          ],
          productionPolicyRegexes: [
            {
              regexString: '\\bphip.*$',
              policyTypeName: 'PHIP',
              policyType: ScenarioPieceType.PHIPProductionHail,
            },
          ],
        },
      ],
    },
    {
      year: 2023,
      aips: [
        {
          aipName: 'NAU',
          standardPolicyRegexes: [
            {
              regexString: '\\bbasic$',
              policyTypeName: 'Basic',
              policyType: ScenarioPieceType.BASICStandardHail,
            },
            {
              regexString: '\\bbasic\\W*5\\W*min$',
              policyTypeName: 'Basic5Min',
              policyType: ScenarioPieceType.BASIC5MinStandardHail,
            },
            {
              regexString: '\\bcomp 2$',
              policyTypeName: 'COMP2',
              policyType: ScenarioPieceType.COMP2StandardHail,
            },
            {
              regexString: '\\bcomp 2\\+$',
              policyTypeName: 'COMP2PLUS',
              policyType: ScenarioPieceType.COMP2PLUSStandardHail,
            },
            {
              regexString: '\\bcomp 2\\W10$',
              policyTypeName: 'COMP2D10',
              policyType: ScenarioPieceType.COMP2D10StandardHail,
            },
            {
              regexString: '\\bcomp 2\\W15$',
              policyTypeName: 'COMP2D15',
              policyType: ScenarioPieceType.COMP2D15StandardHail,
            },
            {
              regexString: '\\bcomp\\s3\\/10$',
              policyTypeName: 'COMP3D10',
              policyType: ScenarioPieceType.COMP3D10StandardHail,
            },
            {
              regexString: '\\bcomp\\s3\\/15$',
              policyTypeName: 'COMP3D15',
              policyType: ScenarioPieceType.COMP3D15StandardHail,
            },
            {
              regexString: '\\bcomp 3$',
              policyTypeName: 'COMP3',
              policyType: ScenarioPieceType.COMP3StandardHail,
            },
            {
              regexString: '\\bcomp 3\\+$',
              policyTypeName: 'COMP3PLUS',
              policyType: ScenarioPieceType.COMP3PLUSStandardHail,
            },
            {
              regexString: '\\bcomp 4$',
              policyTypeName: 'COMP4',
              policyType: ScenarioPieceType.COMP4StandardHail,
            },
            {
              regexString: '\\bcomp 4\\+$',
              policyTypeName: 'COMP4PLUS',
              policyType: ScenarioPieceType.COMP4PLUSStandardHail,
            },
            {
              regexString: '\\bdda$',
              policyTypeName: 'DDA',
              policyType: ScenarioPieceType.DDAStandardHail,
            },
            {
              regexString: '\\bddb$',
              policyTypeName: 'DDB',
              policyType: ScenarioPieceType.DDBStandardHail,
            },
            {
              regexString: '\\bddc$',
              policyTypeName: 'DDC',
              policyType: ScenarioPieceType.DDCStandardHail,
            },
            {
              regexString: '\\bdxs10$',
              policyTypeName: 'DXS10',
              policyType: ScenarioPieceType.DXS10StandardHail,
            },
            {
              regexString: '\\bdxs20$',
              policyTypeName: 'DXS20',
              policyType: ScenarioPieceType.DXS20StandardHail,
            },
            {
              regexString: '\\bdxs25$',
              policyTypeName: 'DXS25',
              policyType: ScenarioPieceType.DXS25StandardHail,
            },
            {
              regexString: '\\bdxs5$',
              policyTypeName: 'DXS5',
              policyType: ScenarioPieceType.DXS5StandardHail,
            },
            {
              regexString: '\\bxs[ ]*20$',
              policyTypeName: 'XS20',
              policyType: ScenarioPieceType.XS20StandardHail,
            },
            {
              regexString: '\\bxs[ ]*25$',
              policyTypeName: 'XS25',
              policyType: ScenarioPieceType.XS25StandardHail,
            },
            {
              regexString: '\\bxs[ ]*10$',
              policyTypeName: 'XS10',
              policyType: ScenarioPieceType.XS10StandardHail,
            },
            {
              regexString: '\\bxs[ ]*5$',
              policyTypeName: 'XS5',
              policyType: ScenarioPieceType.XS5StandardHail,
            },
            {
              regexString: '\\bxs[ ]*15$',
              policyTypeName: 'XS15',
              policyType: ScenarioPieceType.XS15StandardHail,
            },
            {
              regexString: '\\bxs[ ]*10ip$',
              policyTypeName: 'XS10IP',
              policyType: ScenarioPieceType.XS10IPStandardHail,
            },
            {
              regexString: '\\bxs[ ]*15ip$',
              policyTypeName: 'XS15IP',
              policyType: ScenarioPieceType.XS15IPStandardHail,
            },
            {
              regexString: '\\bxs[ ]*20ip$',
              policyTypeName: 'XS20IP',
              policyType: ScenarioPieceType.XS20IPStandardHail,
            },
            {
              regexString: '^companion 2\\+$',
              policyTypeName: 'COMP2PLUS',
              policyType: ScenarioPieceType.COMP2PLUSStandardHail,
            },
            {
              regexString: '^companion 2$',
              policyTypeName: 'COMP2',
              policyType: ScenarioPieceType.COMP2StandardHail,
            },
            {
              regexString: '^companion 2\\.5\\+$',
              policyTypeName: 'COMP2POINT5PLUS',
              policyType: ScenarioPieceType.COMP2POINT5PLUSStandardHail,
            },
            {
              regexString: '^companion 2\\.5$',
              policyTypeName: 'COMP2POINT5',
              policyType: ScenarioPieceType.COMP2POINT5StandardHail,
            },
            {
              regexString: '^companion 3\\+$',
              policyTypeName: 'COMP3PLUS',
              policyType: ScenarioPieceType.COMP3PLUSStandardHail,
            },
            {
              regexString: '^companion 3$',
              policyTypeName: 'COMP3',
              policyType: ScenarioPieceType.COMP3StandardHail,
            },
            {
              regexString: '^companion 3\\.5\\+$',
              policyTypeName: 'COMP3POINT5PLUS',
              policyType: ScenarioPieceType.COMP3POINT5PLUSStandardHail,
            },
            {
              regexString: '^companion 3\\.5$',
              policyTypeName: 'COMP3POINT5',
              policyType: ScenarioPieceType.COMP3POINT5StandardHail,
            },
            {
              regexString: '^companion 4\\+$',
              policyTypeName: 'COMP4PLUS',
              policyType: ScenarioPieceType.COMP4PLUSStandardHail,
            },
            {
              regexString: '^companion 4$',
              policyTypeName: 'COMP4',
              policyType: ScenarioPieceType.COMP4StandardHail,
            },
            {
              regexString: '^companion 4\\.5\\+$',
              policyTypeName: 'COMP4POINT5PLUS',
              policyType: ScenarioPieceType.COMP4POINT5PLUSStandardHail,
            },
            {
              regexString: '^companion 4\\.5$',
              policyTypeName: 'COMP4POINT5',
              policyType: ScenarioPieceType.COMP4POINT5StandardHail,
            },
            {
              regexString: '^companion 2\\/10$',
              policyTypeName: 'COMP2D10',
              policyType: ScenarioPieceType.COMP2D10StandardHail,
            },
          ],
          productionPolicyRegexes: [
            {
              regexString: '^P[0-9]{3}\\-[0-9]{2}( \\W* | )@ 0',
              policyTypeName: 'P',
              policyType: ScenarioPieceType.PProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.Production,
            },
            {
              regexString: '^P[0-9]{3}\\-[0-9]{2}( \\W* | )@ 5',
              policyTypeName: 'P5DED',
              policyType: ScenarioPieceType.P5DEDProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.Production,
            },
            {
              regexString: '^P[0-9]{3}\\-[0-9]{2}( \\W* | )@ 10',
              policyTypeName: 'P10DED',
              policyType: ScenarioPieceType.P10DEDProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.Production,
            },
            {
              regexString: '^P[0-9]{3}\\-[0-9]{2} Personalized\\s*@\\s*0',
              policyTypeName: 'P',
              policyType: ScenarioPieceType.PProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.PersonalizedProduction,
            },
            {
              regexString: '^P[0-9]{3}\\-[0-9]{2} Personalized\\s*@\\s*5',
              policyTypeName: 'P5DED',
              policyType: ScenarioPieceType.P5DEDProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.PersonalizedProduction,
            },
            {
              regexString: '^P[0-9]{3}\\-[0-9]{2} Personalized\\s*@\\s*10',
              policyTypeName: 'P10DED',
              policyType: ScenarioPieceType.P10DEDProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.PersonalizedProduction,
            },
          ],
        },
        {
          aipName: 'CRS',
          standardPolicyRegexes: [
            {
              regexString: '\\b(basic)$',
              policyTypeName: 'Basic',
              policyType: ScenarioPieceType.BASICStandardHail,
            },
            {
              regexString: '^cmp4$',
              policyTypeName: 'COMP4',
              policyType: ScenarioPieceType.COMP4StandardHail,
            },
            {
              regexString: '^cmp3$',
              policyTypeName: 'COMP3',
              policyType: ScenarioPieceType.COMP3StandardHail,
            },
            {
              regexString: '^cmp3\\+$',
              policyTypeName: 'COMP3PLUS',
              policyType: ScenarioPieceType.COMP3PLUSStandardHail,
            },
            {
              regexString: '^cmp3\\-10ded$',
              policyTypeName: 'COMP3D10',
              policyType: ScenarioPieceType.COMP3D10StandardHail,
            },
            {
              regexString: '^cmp3\\-15ded$',
              policyTypeName: 'COMP3D15',
              policyType: ScenarioPieceType.COMP3D15StandardHail,
            },
            {
              regexString: '^cmp2\\-15ded$',
              policyTypeName: 'COMP2D15',
              policyType: ScenarioPieceType.COMP2D15StandardHail,
            },
            {
              regexString: '^cmp2\\-10ded$',
              policyTypeName: 'COMP2D10',
              policyType: ScenarioPieceType.COMP2D10StandardHail,
            },
            {
              regexString: '^cmp2\\-5ded$',
              policyTypeName: 'COMP2',
              policyType: ScenarioPieceType.COMP2StandardHail,
            },
            {
              regexString: '^cmp2\\+$',
              policyTypeName: 'COMP2PLUS',
              policyType: ScenarioPieceType.COMP2PLUSStandardHail,
            },
            {
              regexString: '^ddc\\s*\\/\\s*dd30$',
              policyTypeName: 'DDC',
              policyType: ScenarioPieceType.DDCStandardHail,
            },
            {
              regexString: '^ddb$',
              policyTypeName: 'DDB',
              policyType: ScenarioPieceType.DDBStandardHail,
            },
            {
              regexString: '^dda$',
              policyTypeName: 'DDA',
              policyType: ScenarioPieceType.DDAStandardHail,
            },
            {
              regexString: '^xs\\-25$',
              policyTypeName: 'XS25',
              policyType: ScenarioPieceType.XS25StandardHail,
            },
            {
              regexString: '^xs\\-20$',
              policyTypeName: 'XS20',
              policyType: ScenarioPieceType.XS20StandardHail,
            },
            {
              regexString: '^xs\\-15$',
              policyTypeName: 'XS15',
              policyType: ScenarioPieceType.XS15StandardHail,
            },
            {
              regexString: '^xs\\-10$',
              policyTypeName: 'XS10',
              policyType: ScenarioPieceType.XS10StandardHail,
            },
            {
              regexString: '^xs\\-5$',
              policyTypeName: 'XS5',
              policyType: ScenarioPieceType.XS5StandardHail,
            },
            {
              regexString: '^dxs5$',
              policyTypeName: 'DXS5',
              policyType: ScenarioPieceType.DXS5StandardHail,
            },
            {
              regexString: '^dxs10$',
              policyTypeName: 'DXS10',
              policyType: ScenarioPieceType.DXS10StandardHail,
            },
            {
              regexString: '^(xs15ip)$',
              policyTypeName: 'XS15IP',
              policyType: ScenarioPieceType.XS15IPStandardHail,
            },
            {
              regexString: '^(xs10ip)$',
              policyTypeName: 'XS10IP',
              policyType: ScenarioPieceType.XS10IPStandardHail,
            },
            {
              regexString: '^(xs20ip)$',
              policyTypeName: 'XS20IP',
              policyType: ScenarioPieceType.XS20IPStandardHail,
            },
            {
              regexString: '^cmp65$',
              policyTypeName: 'COMP65',
              policyType: ScenarioPieceType.COMP65StandardHail,
            },
            {
              regexString: '^cmp70$',
              policyTypeName: 'COMP70',
              policyType: ScenarioPieceType.COMP70StandardHail,
            },
            {
              regexString: '^cmp75$',
              policyTypeName: 'COMP75',
              policyType: ScenarioPieceType.COMP75StandardHail,
            },
            {
              regexString: '^cmp80$',
              policyTypeName: 'COMP80',
              policyType: ScenarioPieceType.COMP80StandardHail,
            },
          ],
          productionPolicyRegexes: [
            {
              regexString: '^P[0-9]{3}\\-[0-9]{2}$',
              policyTypeName: 'P',
              policyType: ScenarioPieceType.PProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.Production,
            },
            {
              regexString: '^PEU[0-9]{3}\\-[0-9]{2}$',
              policyTypeName: 'PEU',
              policyType: ScenarioPieceType.PEUProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.ProductionEu,
            },
            {
              regexString: '^P5DED[0-9]{3}\\-[0-9]{2}$',
              policyTypeName: 'P5DED',
              policyType: ScenarioPieceType.P5DEDProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.Production,
            },
            {
              regexString: '^P10DED[0-9]{3}\\-[0-9]{2}$',
              policyTypeName: 'P10DED',
              policyType: ScenarioPieceType.P10DEDProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.Production,
            },
            {
              regexString: '^PF5[0-9]{3}\\-[0-9]{2}$',
              policyTypeName: 'PF5',
              policyType: ScenarioPieceType.PF5ProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.ProductionFirst,
            },
          ],
        },
        {
          aipName: 'AgriSompo',
          standardPolicyRegexes: [
            {
              regexString: '\\bbasic\\W*5\\W*min$',
              policyTypeName: 'Basic5Min',
              policyType: ScenarioPieceType.BASIC5MinStandardHail,
            },
            {
              regexString: '\\bbasic(?! 5% min)',
              policyTypeName: 'Basic',
              policyType: ScenarioPieceType.BASICStandardHail,
            },
            {
              regexString: '\\b\\w*cp2(?!-)',
              policyTypeName: 'COMP2',
              policyType: ScenarioPieceType.COMP2StandardHail,
            },
            {
              regexString: '\\b\\w*cp2-10',
              policyTypeName: 'COMP2D10',
              policyType: ScenarioPieceType.COMP2D10StandardHail,
            },
            {
              regexString: '\\b\\w*cp3(?!-)',
              policyTypeName: 'COMP3',
              policyType: ScenarioPieceType.COMP3StandardHail,
            },
            {
              regexString: '\\b\\w*cp3-10',
              policyTypeName: 'COMP3D10',
              policyType: ScenarioPieceType.COMP3D10StandardHail,
            },
            {
              regexString: '\\b\\w*cp1.5',
              policyTypeName: 'COMP1POINT5',
              policyType: ScenarioPieceType.COMP1POINT5StandardHail,
            },
            {
              regexString: '\\b\\w*cp2-15',
              policyTypeName: 'COMP2D15',
              policyType: ScenarioPieceType.COMP2D15StandardHail,
            },
            {
              regexString: '\\b\\w*cp3-15',
              policyTypeName: 'COMP3D15',
              policyType: ScenarioPieceType.COMP3D15StandardHail,
            },
            {
              regexString: '\\b\\w*cp4(?!-)',
              policyTypeName: 'COMP4',
              policyType: ScenarioPieceType.COMP4StandardHail,
            },
            {
              regexString: '\\bcp65$',
              policyTypeName: 'COMP65',
              policyType: ScenarioPieceType.COMP65StandardHail,
            },
            {
              regexString: '\\bcp70$',
              policyTypeName: 'COMP70',
              policyType: ScenarioPieceType.COMP70StandardHail,
            },
            {
              regexString: '\\bcp75$',
              policyTypeName: 'COMP75',
              policyType: ScenarioPieceType.COMP75StandardHail,
            },
            {
              regexString: '\\bcp80$',
              policyTypeName: 'COMP80',
              policyType: ScenarioPieceType.COMP80StandardHail,
            },
            {
              regexString: '\\bdda',
              policyTypeName: 'DDA',
              policyType: ScenarioPieceType.DDAStandardHail,
            },
            {
              regexString: '\\bddb',
              policyTypeName: 'DDB',
              policyType: ScenarioPieceType.DDBStandardHail,
            },
            {
              regexString: '\\bddc',
              policyTypeName: 'DDC',
              policyType: ScenarioPieceType.DDCStandardHail,
            },
            {
              regexString: '\\bdxs10',
              policyTypeName: 'DXS10',
              policyType: ScenarioPieceType.DXS10StandardHail,
            },
            {
              regexString: '\\bdxs20(?!-)',
              policyTypeName: 'DXS20',
              policyType: ScenarioPieceType.DXS20StandardHail,
            },
            {
              regexString: '\\bdxs5(?!0)',
              policyTypeName: 'DXS5',
              policyType: ScenarioPieceType.DXS5StandardHail,
            },
            {
              regexString: '\\bddxs50',
              policyTypeName: 'DXS50',
              policyType: ScenarioPieceType.DXS50StandardHail,
            },
            {
              regexString: '\\bxs10(?!ip)',
              policyTypeName: 'XS10',
              policyType: ScenarioPieceType.XS10StandardHail,
            },
            {
              regexString: '\\bxs10ip',
              policyTypeName: 'XS10IP',
              policyType: ScenarioPieceType.XS10IPStandardHail,
            },
            {
              regexString: '\\bxs15',
              policyTypeName: 'XS15',
              policyType: ScenarioPieceType.XS15StandardHail,
            },
            {
              regexString: '\\bxs20(?!ip)',
              policyTypeName: 'XS20',
              policyType: ScenarioPieceType.XS20StandardHail,
            },
            {
              regexString: '\\bxs20ip',
              policyTypeName: 'XS20IP',
              policyType: ScenarioPieceType.XS20IPStandardHail,
            },
            {
              regexString: '\\bxs25(?!ip)',
              policyTypeName: 'XS25',
              policyType: ScenarioPieceType.XS25StandardHail,
            },
            {
              regexString: '\\bxs5(?!0)(?!ip)',
              policyTypeName: 'XS5',
              policyType: ScenarioPieceType.XS5StandardHail,
            },
            {
              regexString: '\\bxs50ip',
              policyTypeName: 'XS50IP',
              policyType: ScenarioPieceType.XS50IPStandardHail,
            },
          ],
          productionPolicyRegexes: [
            {
              regexString: '^[0-9]{3}(\\/|-)[0-9]{2}(?! 5)(?! First 5)(?! D)(?! WF)(?! 10%)(?! 20%)',
              policyTypeName: 'CHPP0',
              policyType: ScenarioPieceType.CHPP0ProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.Production,
            },
            {
              regexString: '^[0-9]{3}(\\/|-)[0-9]{2} WF',
              policyTypeName: 'CHPP0',
              policyType: ScenarioPieceType.CHPP0ProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.ProductionWf,
            },
            {
              regexString: '^[0-9]{3}(\\/|-)[0-9]{2} 5(?! First 5)',
              policyTypeName: 'CHPP5',
              policyType: ScenarioPieceType.CHPP5ProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.Production,
            },
            {
              regexString: '^[0-9]{3}(\\/|-)[0-9]{2} First 5',
              policyTypeName: 'CHPPF5',
              policyType: ScenarioPieceType.CHPPF5ProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.ProductionFirst,
            },
            {
              regexString: '^[0-9]{3}(\\/|-)[0-9]{2} D5',
              policyTypeName: 'CHPPD5',
              policyType: ScenarioPieceType.CHPPD5ProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.ProductionDeductible,
            },
            {
              regexString: '^[0-9]{3}(\\/|-)[0-9]{2} D5 WF',
              policyTypeName: 'CHPPD5',
              policyType: ScenarioPieceType.CHPPD5ProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.ProductionWf,
            },
            {
              regexString: '^[0-9]{3}(\\/|-)[0-9]{2} D10',
              policyTypeName: 'CHPPD10',
              policyType: ScenarioPieceType.CHPPD10ProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.ProductionDeductible,
            },
            {
              regexString: '^[0-9]{3}(\\/|-)[0-9]{2} D10 WF',
              policyTypeName: 'CHPPD10',
              policyType: ScenarioPieceType.CHPPD10ProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.ProductionWf,
            },
            {
              regexString: '^[0-9]{3}(\\/|-)[0-9]{2} D20',
              policyTypeName: 'CHPPD20',
              policyType: ScenarioPieceType.CHPPD20ProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.ProductionDeductible,
            },
            {
              regexString: '^[0-9]{3}(\\/|-)[0-9]{2} D20 WF',
              policyTypeName: 'CHPPD20',
              policyType: ScenarioPieceType.CHPPD20ProductionHail,
              productionCoverageLevelGroup: ProductionCoverageLevelGroupType.ProductionWf,
            },
          ],
        },
      ],
    },
  ],
};
