import { CurrencyCellRenderer } from '../../pages/scenarioAnalysis/historicalAnalysis/charts/HistoricalChart/cellRenderers/currencyCellRenderer';
import { HistoricalDynamicColumn, HistoricalDynamicColumnConfig } from '../../pages/scenarioAnalysis/historicalAnalysis/charts/HistoricalChart/types/HistoricalData';
import HistoricalPrice from '../../types/api/adm/HistoricalPrice';
import { GetHistoricalQuoterColumnConfigParams, GetHistoricalQuoterColumnParams } from '../../types/app/ScenarioPieceDefinition';
import { KeyedState } from '../../app/sliceHelpers';
import { IntervalPriceSymbolPrefix, ScenarioPieceId } from '../../types/api/PrimaryKeys';
import { IntervalPrice } from '../../types/api/adm/IntervalPrice';
import { IntervalRange } from '../../types/api/adm/IntervalRange';
import PriceGroup from '../../types/api/adm/PriceGroup';
import { ScenarioPieceSelectedInterval } from '../../types/api/adm/ScenarioPieceSelectedInterval';
import { getPriceGroupSymbolPrefix } from '../priceGroupUtils';

export const greenBackgroundStyle = { backgroundColor: 'rgba(146, 250, 150, 0.4)' };
export const blueBackgroundStyle = { backgroundColor: 'rgba(142, 212, 252, 0.4)' };

export const getHistoricalQuoterIntervalColumnsForMarginProduct = (params: GetHistoricalQuoterColumnParams) => {
  const getMpBasePrice = (params: HistoricalPrice | undefined) => {
    return params?.mpBasePrice ?? 0;
  };
  return getHistoricalQuoterIntervalColumnsForProductBase (params, getMpBasePrice);
};

export const getHistoricalQuoterIntervalColumnsForProduct = (params: GetHistoricalQuoterColumnParams) => {
  const getBasePrice = (params: HistoricalPrice | undefined) => {
    return params?.basePrice ?? 0;
  };
  return getHistoricalQuoterIntervalColumnsForProductBase (params, getBasePrice);
};

const getHistoricalQuoterIntervalColumnsForProductBase = (params: GetHistoricalQuoterColumnParams, getBasePriceToUse: (params: HistoricalPrice | undefined) => number) => {
  const dynamicColumns: HistoricalDynamicColumn[] = [];
  const intervalGroupName = `${params.groupingKey} Intervals`;
  if (params.scenarioPieceId === undefined) return dynamicColumns;
  const intervalPricesWithDisplayRange = getIntervalPricesWithDisplayRange(
    params.prices,
    params.priceGroups,
    params.intervalPrices,
    params.selectedIntervals,
    params.availableIntervals,
    params.scenarioPieceId,
    params.year);
  if (intervalPricesWithDisplayRange.length > 0) {
    const prices = params.prices.find(price => price.priceYear === params.year);
    const projectedPrice = getBasePriceToUse(prices);
    const maxPrice = Math.max(...intervalPricesWithDisplayRange.map(item => item.intervalPrice));
    for (const interval of intervalPricesWithDisplayRange) {
      let styleToUse = {};
      if (interval.intervalPrice > projectedPrice && projectedPrice > 0) {
        if (interval.intervalPrice === maxPrice) {
          styleToUse = blueBackgroundStyle;
        } else {
          styleToUse = greenBackgroundStyle;
        }
      }
      dynamicColumns.push(
        {
          groupingKey: intervalGroupName,
          dataKey: `${params.groupingKey}interval${interval.displayRange}${interval.deltaYear}`,
          value: interval.intervalPrice,
          cellStyle: styleToUse,
        });
    }
    const summaryStyleToUse = (maxPrice > projectedPrice && projectedPrice > 0) ? greenBackgroundStyle : {};
    dynamicColumns.push(
      {
        groupingKey: intervalGroupName,
        dataKey: `${params.groupingKey}intervalSummary`,
        value: maxPrice,
        cellStyle: summaryStyleToUse,
      });
  }

  return dynamicColumns;
};

export const getHistoricalQuoterColumnConfigs = (params: GetHistoricalQuoterColumnConfigParams) => {
  const dynamicColumnConfigs: HistoricalDynamicColumnConfig[] = [];
  const intervalGroupName = `${params.groupName} Intervals`;
  const intervalDisplayRanges = getIntervalDisplayRanges(
    params.selectedIntervals,
    params.availableIntervals,
    params.scenarioPiece.scenarioPieceId,
  );
  if (intervalDisplayRanges.length > 0) {
    for (const intervalDisplayRange of intervalDisplayRanges) {
      dynamicColumnConfigs.push(
        {
          groupingKey: intervalGroupName,
          dataKey: `${params.groupName}interval${intervalDisplayRange.displayRange}${intervalDisplayRange.deltaYear}`,
          columnHeader: intervalDisplayRange.displayRange,
          headerTooltip: intervalDisplayRange.fullDisplayRange,
          cellRenderer: CurrencyCellRenderer,
          maxWidth: 100,
        },
      );
    }
    dynamicColumnConfigs.push(
      {
        groupingKey: intervalGroupName,
        dataKey: `${params.groupName}intervalSummary`,
        columnHeader: 'Summary',
        cellRenderer: CurrencyCellRenderer,
        maxWidth: 100,
      },
    );
  }
  return dynamicColumnConfigs;
};

export const getIntervalDisplayRanges = (
  selectedIntervals: KeyedState<ScenarioPieceId, ScenarioPieceSelectedInterval[]>,
  availableIntervals: IntervalRange[],
  scenarioPieceId: ScenarioPieceId,
) => {
  const displayRanges = [];
  const selectedIntervalsForThisScenarioPiece = selectedIntervals[scenarioPieceId] ?? [];
  if (selectedIntervalsForThisScenarioPiece.length > 0) {
    const sortedIntervals = [...selectedIntervalsForThisScenarioPiece].sort((a, b) => a.intervalRangeId - b.intervalRangeId);
    for (const interval of sortedIntervals) {
      const intervalRange = availableIntervals.find(ai => ai.id === interval.intervalRangeId);
      if (intervalRange !== undefined) {
        const monthDayDisplayRange = formatMonthDayDisplayRange(intervalRange.intervalMonthStart, intervalRange.intervalDayStart, intervalRange.intervalMonthEnd, intervalRange.intervalDayEnd);
        const monthDayYearDisplayRange = formatMonthDayYearDisplayRange(intervalRange.intervalMonthStart, intervalRange.intervalDayStart, intervalRange.intervalMonthEnd, intervalRange.intervalDayEnd, interval.year);
        displayRanges.push({
          displayRange: monthDayDisplayRange,
          fullDisplayRange: monthDayYearDisplayRange,
          deltaYear: intervalRange.intervalDeltaYearStart,
        });
      }
    }
  }
  return displayRanges;
};

export const getIntervalPricesWithDisplayRange = (
  prices: HistoricalPrice[],
  priceGroups: PriceGroup[],
  intervalPrices: KeyedState<IntervalPriceSymbolPrefix, IntervalPrice[]>,
  selectedIntervals: KeyedState<ScenarioPieceId, ScenarioPieceSelectedInterval[]>,
  availableIntervals: IntervalRange[],
  scenarioPieceId: ScenarioPieceId,
  year: number,
) => {
  const historicalPrice = prices.find(price => price.priceYear === year);
  const priceGroupSymbol = priceGroups.find(pg => pg.priceGroupID === historicalPrice?.priceGroupID)?.projectedMarketSymbol ?? '';
  const symbolPrefix = getPriceGroupSymbolPrefix(priceGroupSymbol);
  const intervalPricesForSymbolCode = intervalPrices[symbolPrefix] ?? [];
  const lastTwoCharactersOfYear = year.toString().slice(-2);
  const historicalYearSymbol = `${symbolPrefix}${lastTwoCharactersOfYear}`;
  const intervalPricesForThisYear = intervalPricesForSymbolCode.filter(p => p.symbol === historicalYearSymbol);
  const intervalPricesWithDisplayRange: { intervalPrice: number, displayRange: string, deltaYear: number }[] = [];
  const selectedIntervalsForThisScenarioPiece = selectedIntervals[scenarioPieceId] ?? [];
  if (selectedIntervalsForThisScenarioPiece.length > 0) {
    const sortedIntervals = [...selectedIntervalsForThisScenarioPiece].sort((a, b) => a.intervalRangeId - b.intervalRangeId);
    for (const interval of sortedIntervals) {
      const intervalRange = availableIntervals.find(ai => ai.id === interval.intervalRangeId);
      if (intervalRange !== undefined) {
        const displayRange = formatMonthDayDisplayRange(intervalRange.intervalMonthStart, intervalRange.intervalDayStart, intervalRange.intervalMonthEnd, intervalRange.intervalDayEnd);
        const startDate = `${intervalRange.intervalMonthStart}/${intervalRange.intervalDayStart}/${intervalRange.intervalDeltaYearStart + year}`;
        const endDate = `${intervalRange.intervalMonthEnd}/${intervalRange.intervalDayEnd}/${intervalRange.intervalDeltaYearEnd + year}`;
        const intervalPriceForThisRange = intervalPricesForThisYear.find(p => new Date(p.intervalStartDate).toLocaleDateString('en-US') === startDate && new Date(p.intervalEndDate).toLocaleDateString('en-US') === endDate);
        const numericIntervalPrice = intervalPriceForThisRange?.price ?? 0;
        intervalPricesWithDisplayRange.push(
          {
            intervalPrice: numericIntervalPrice,
            displayRange: displayRange,
            deltaYear: intervalRange.intervalDeltaYearStart,
          },
        );
      }
    }
  }
  return intervalPricesWithDisplayRange;
};

const formatMonthDayDisplayRange = (startMonth: number, startDay: number, endMonth: number, endDay: number) => {
  return `${startMonth}/${startDay} - ${endMonth}/${endDay}`;
};

const formatMonthDayYearDisplayRange = (startMonth: number, startDay: number, endMonth: number, endDay: number, year: number) => {
  //Mid-month intervals can wrap the end/start of a year. When this happens, we need the end year to be 1 greater than the start year
  const endYear = startMonth > endMonth ? year + 1 : year;

  return `${startMonth}/${startDay}/${year} - ${endMonth}/${endDay}/${endYear}`;
};