import { useAppSelector, useKeyMapSelector } from './reduxHooks';
import { selectCalcUnitGroupsForScenarioPiece } from '../app/unitGroupsSlice';
import { selectUnitYearsForScenario } from '../app/scenariosSlice';
import { selectScenarioUnitYearAphForScenarioId, selectUnitYearAphByUnitYearId } from '../app/unitYearAphSlice';
import {
  AcreageType,
  calculateProducerYieldHistory, OptionCode, PracticeCode,
  ProducerYieldHistoryRequest, TypeCode,
  Unit,
  UnitGroup as CalcUnitGroup,
  UnitYearAph
} from '@silveus/calculations';
import UnitYear from '../types/api/UnitYear';
import { UnitGroup } from '../types/api/UnitGroup';
import { ScenarioId, UnitGroupId, UnitYearId } from '../types/api/PrimaryKeys';
import { selectAllRowCropScenarioPiecesByScenarioMap } from '../app/scenarioPiecesSlice';
import { scenarioPieceOrderingServiceInstance } from '../utils/scenarioOrderingServiceWrappers';
import { YeStatusType } from '../types/api/enums/optionStates/yeStatusType.enum';
import { RowCropScenario } from '../types/api/RowCropScenario';
import { toPrimaryKey } from '../utils/primaryKeyHelpers';
import * as uuid from 'uuid';
import { getPracticeCodeFromPracticeId, getTypeCodeFromTypeId } from '../utils/adm';
import { Quote } from '../types/api/Quote';
import { useOptions } from './useOptions';
import { getItemsForId } from '../utils/mapHelpers';
import { OptionSelectionState } from '../types/app/enums/optionSelectionState.enum';

export const useProducerHistory = (startYear: number, endYear: number, scenario: RowCropScenario, quote: Quote, acreageType: AcreageType, calculateProductsForMissingYears: boolean) => {
  const scenarioId = scenario.scenarioId;
  const basePieceTypes = scenarioPieceOrderingServiceInstance.getBaseScenarioPieces();
  const scenarioPiece = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMap, scenarioId).find(sp => basePieceTypes.includes(sp.scenarioPieceType));

  const unitGroups = useAppSelector(state => scenarioPiece?.scenarioPieceId === undefined ? [] : selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenarioId));
  const unitYears = useAppSelector(state => selectUnitYearsForScenario(state, scenarioId));
  const scenarioUnitYearAphArray = useAppSelector(state => selectScenarioUnitYearAphForScenarioId(state, scenarioId));
  const { getOptionStatesForUnits } = useOptions(scenario.quoteId, scenarioId);
  const unitOptions = getOptionStatesForUnits();
  let unitYearAphArray = useAppSelector(state => unitYears.flatMap(u => {
    const isYeApplicable = getItemsForId(unitOptions, u.unitId).some(o => o.option.optionCode === OptionCode.YE &&
      o.isAvailable && o.selectionState === OptionSelectionState.On);
    const unitYearAph: UnitYearAph[] = selectUnitYearAphByUnitYearId(state, u.unitYearId).map(aph => ({
      ...aph,
      excluded: isYeApplicable && scenarioUnitYearAphArray.find(scenAphRow =>
        aph.unitYearAphId === scenAphRow.unitYearAphId)?.yeStatus === YeStatusType.NotOptedOut,
    }));
    return unitYearAph;
  }));

  let units: Partial<Unit>[];
  if (quote.quickQuote && scenario.quickUnit !== null) {
    unitYearAphArray = [];
    //When quick quoting, we need a history with zero yields to simulate a unit with no history
    for (let year = startYear; year < endYear; year++) {
      const fakeAphYear: UnitYearAph = {
        acres: scenario.quickUnit.acres,
        aphYield: 0,
        excluded: false,
        preQaYield: 0,
        production: 0,
        unitYearAphId: toPrimaryKey<UnitGroupId>(uuid.v4()),
        unitYearId: scenario.quickUnit.scenarioQuickUnitId,
        year: year,
        yieldType: 'A',

      };
      unitYearAphArray.push(fakeAphYear);
    }

    const convertedQuickUnit: Unit = {
      acres: scenario.quickUnit.acres,
      actualYield: scenario.actualProducerYield ?? 0,
      adjustedYield: scenario.quickUnit.aphYield,
      approvedYield: scenario.quickUnit.approvedYield,
      id: scenario.quickUnit.scenarioQuickUnitId,
      insuredSharePercent: scenario.quickUnit.sharePercent,
      isLossEvent: false,
      isPP: false,
      practiceCode: PracticeCode[getPracticeCodeFromPracticeId(scenario.quickUnit.practiceId) as keyof typeof PracticeCode],
      rateYield: scenario.quickUnit.rateYield,
      subCountyCode: null,
      typeCode: TypeCode[getTypeCodeFromTypeId(scenario.quickUnit.typeId) as keyof typeof TypeCode],
    };
    units = [convertedQuickUnit];
  } else {
    units = convertToBaseUnit(unitYears);
  }
  const unitGroupsCalcs = convertToCalcUnitGroup(unitGroups, units);

  const producerYieldHistoryRequest = generateProducerYieldHistoryRequest(startYear, endYear, unitGroupsCalcs, unitYearAphArray, acreageType, calculateProductsForMissingYears, scenarioId);
  const calculatedProducerYieldHistories = calculateProducerYieldHistory(producerYieldHistoryRequest);

  return calculatedProducerYieldHistories;
};

function convertToBaseUnit(unitYears: UnitYear[]): Partial<Unit>[] {
  return unitYears.map(unitYear => ({
    id: unitYear.unitYearId,
    acres: unitYear.acres,
    insuredSharePercent: unitYear.sharePercent,
  }));
}

function convertToCalcUnitGroup(unitGroups: UnitGroup[], units: Partial<Unit>[]): CalcUnitGroup<Unit>[] {
  return unitGroups.map(unitGroup => ({
    unitGroupId: unitGroup.unitGroupId,
    unitStructure: unitGroup.unitStructure,
    units: units.filter(u => unitGroup.unitYearIds.includes(u.id as UnitYearId)) as Unit[],
  }));
}

function generateProducerYieldHistoryRequest(startYear: number, endYear: number, unitGroups: CalcUnitGroup<Unit>[], unitYearAphArray: UnitYearAph[], acreageType: AcreageType, calculateProductsForMissingYears: boolean, scenarioId: ScenarioId): ProducerYieldHistoryRequest {
  return {
    startYear: startYear,
    endYear: endYear,
    scenarioId: scenarioId,
    unitGroups: unitGroups,
    acreageType: acreageType,
    calculateProductsForMissingYears: calculateProductsForMissingYears,
    unitYearAphArray: unitYearAphArray,
  };
}
