import { Grid } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import PercentChangeSliderInput from '../percentChangeSliderInput.component';
import ScenarioMpActualInputCostsInput, { MpActualInputCostsFields } from './scenarioMpActualInputCostsInput.component';
import ScenarioMpExpectedInputCostsInput, { MpExpectedInputCostsFields } from './scenarioMpExpectedInputCostsInput.component';
import { FormInputProps } from './formInputProps';
import { ExtendedDataFormInputParameterNames } from '../constants/inputConstants';
import { ScenarioId } from '../../../types/api/PrimaryKeys';
import useLinkedScenarioFields from '../../../hooks/useLinkedScenarioFields';

export interface MpInputCostsInputGroupProps extends FormInputProps {
  scenarioId: Nullable<ScenarioId>;
  expectedInputCosts: Nullable<number>;
  actualInputCosts: Nullable<number>;
  commodityCode: string;
  year: number;
}

export type MpInputCostsInputGroupFields = MpExpectedInputCostsFields & MpActualInputCostsFields;

export const ScenarioMpInputCostsInputGroup = ({ scenarioId, expectedInputCosts, actualInputCosts, commodityCode, year, disabled }: MpInputCostsInputGroupProps) => {
  const { getInputStyle } = useLinkedScenarioFields(scenarioId);
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={4}>
        <ScenarioMpExpectedInputCostsInput
          sx={getInputStyle(ExtendedDataFormInputParameterNames.ActualInputCosts)}
          expectedInputCosts={expectedInputCosts}
          disabled={disabled} />
      </Grid>
      <Grid item xs={4}>
        <ScenarioMpActualInputCostsInput
          sx={getInputStyle(ExtendedDataFormInputParameterNames.ActualInputCosts)}
          commodityCode={commodityCode}
          year={year}
          actualInputCosts={actualInputCosts} />
      </Grid>
      <Grid item xs={4}>
        <PercentChangeSliderInput
          estimatedValueFieldName={ExtendedDataFormInputParameterNames.ExpectedInputCosts}
          actualValueFieldName={ExtendedDataFormInputParameterNames.ActualInputCosts}
          sliderName={'inputCostsSlider'}
          initialEstimatedValue={expectedInputCosts}
          initialActualValue={actualInputCosts}
        />
      </Grid>
    </Grid>
  );
};
