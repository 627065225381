import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../../hooks/reduxHooks';
import {
  selectOfferProjectedPrice
} from '../../../app/admSlice';
import { useEffect } from 'react';
import ProjectedPriceInput, { ProjectedPriceProps } from '../scenario/projectedPriceInput.component';
import { LoadFromStateProps } from '../scenario/loadFromStateProps';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { Nullable } from '../../../types/util/Nullable';
import { useCommodityOptionBasedPrecisionForScenario } from '../../../hooks/useCommodityOptionBasedPrecision';
import { getRoundedValue } from '../../numberTextField/numberTextField.component';
import useLinkedScenarioFields from '../../../hooks/useLinkedScenarioFields';
import { useOptionsForSpForm } from '../../../hooks/useOptionsForSpForm';

interface ScenarioPieceProjectedPriceProps extends ProjectedPriceProps, LoadFromStateProps {
  scenarioPiece: Nullable<RowCropScenarioPiece>
}

const ScenarioPieceProjectedPriceInput = ({ quote, scenario, scenarioPiece, projectedPrice, sx, autoFocus = false, loadFromState = false }: ScenarioPieceProjectedPriceProps) => {
  const { setValue, trigger } = useFormContext();
  const projPrice = useAppSelector(selectOfferProjectedPrice);
  const { scenarioOptionsForSpForm } = useOptionsForSpForm();
  const { precision } = useCommodityOptionBasedPrecisionForScenario(quote.commodityCode, scenarioOptionsForSpForm);

  const { getInputStyle } = useLinkedScenarioFields(scenario?.scenarioId ?? null);

  useEffect(() => {
    // This updates the price if the scenario piece does not exist yet (the scenario piece will be new if saved).
    // This should not be necessary in most cases. It handles cases where the insurance plan associated with the
    // scenario piece has a different price than the price used by default on the scenario.
    // Note: This also requires something (like the scenario piece form) to refetch the prices and yields based on the potentially new insurance plan code
    if (scenarioPiece !== null || projPrice === null || !loadFromState) return;

    setValue('projectedPrice', getRoundedValue(projPrice, precision));
    trigger('projectedPrice');
  }, [scenarioPiece, projPrice, loadFromState, precision]);

  return (
    <ProjectedPriceInput
      quote={quote}
      scenario={scenario}
      scenarioOptionState={scenarioOptionsForSpForm}
      projectedPrice={projectedPrice}
      sx={{
        ...sx,
        ...getInputStyle('projectedPrice'),
      }}
      autoFocus={autoFocus}
      disabled={false}
    />
  );
};

export default ScenarioPieceProjectedPriceInput;
