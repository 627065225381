import { ICellRendererParams } from 'ag-grid-community';
import { selectRateYieldForUnit } from '../../../app/unitsSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import UnitYear from '../../../types/api/UnitYear';
import { formatNumber } from '../../../utils/formatNumbers';
import { Na } from '../../../types/util/Na';

type RateYieldCellRendererProps = {
  params: ICellRendererParams<UnitYear, number>
};

const RateYieldCellRenderer = ({ params }: RateYieldCellRendererProps) => {

  const isPinnedRow = params.node.rowPinned === 'bottom';
  const rateYield = useAppSelector(state => {
    if (params.value !== undefined && isPinnedRow) {
      return params.value;
    } else if (params.data !== undefined) {
      return selectRateYieldForUnit(state, params.data.unitYearId);
    } else {
      return null;
    }
  });

  return (
    <div>{rateYield !== null ? formatNumber(rateYield, true) : Na}</div>
  );
};

export default RateYieldCellRenderer;