import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Nullable } from '../../../types/util/Nullable';
import { SxProps, TextField, Theme } from '@mui/material';
import VALIDATION_RULES from '../../../constants/formValidationRules';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useEffect } from 'react';
import { isNullOrUndefined, isUndefined } from '../../../utils/nullHandling';
import { selectOfferVolatility } from '../../../app/admSlice';
import { isApiDataStale, isSavedDataBeingLoaded } from '../../../utils/formInputUtils';

interface VolatilityProps {
  volatility: Nullable<number>;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
}

export type VolatilityFields = {
  volatility: number;
}

const VolatilityInput = ({ volatility, sx, autoFocus = false }: VolatilityProps) => {
  const { control, setValue, getValues, trigger } = useFormContext();

  const vol = useAppSelector(selectOfferVolatility);
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });

  useEffect(() => {
    const currentPractice = getValues('practiceId');

    const isThereStaleData = isApiDataStale(currentPractice, vol);
    const isLoadingSavedData = isSavedDataBeingLoaded(volatility, practice);

    if (isThereStaleData || isLoadingSavedData || isNullOrUndefined(vol)) return;

    setValue('volatility', vol);
    trigger('volatility');
  }, [vol]);

  useEffect(() => {
    if (isUndefined(practice)) return;

    setValue('volatility', 0);
  }, [practice]);

  return (
    <Controller
      name="volatility"
      control={control}
      rules={{ required: VALIDATION_RULES.required(), min: VALIDATION_RULES.minimum(0), max: VALIDATION_RULES.maximum(1), maxLength: VALIDATION_RULES.maxLength(4) }}
      defaultValue={volatility ?? 0}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="number"
          label="Volatility"
          fullWidth
          inputProps={{ step: 0.01 }}
          error={!!error}
          helperText={error?.message}
          ref={ref}
          onChange={e => {
            field.onChange(e.target.value === '' ? e.target.value : parseFloat(e.target.value));
          }}
          autoFocus={autoFocus}
          sx={sx}
          size="small"
        />
      )}
    />
  );
};

export default VolatilityInput;
