import { ADMYear } from '../../../types/api/adm/ADMYear';
import { AgencyInformation } from '../../../types/api/agencyInformation';
import { AgentInformation } from '../../../types/api/agentInformation';
import { AIP } from '../../../types/api/aip';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { AppTask } from '../../../types/api/AppTask';
import {
  HudsonAgency,
  HudsonAgent,
  HudsonApplicant,
  HudsonApplicationDto,
  HudsonApplicationInformation,
  HudsonConditionsOfAcceptance,
  HudsonCropInfo,
  HudsonEcoCropInfo,
  HudsonHipCropInfo,
  HudsonPolicyCancellationInformation,
  HudsonPolicyInformation,
  HudsonPolicyTransferInformation,
  HudsonSbi,
  HudsonSbiFormDTO,
  HudsonScoCropInfo,
  HudsonSignatureInfo,
  HudsonStaxCropInfo
} from '../../../types/api/documents/hudsonTypes';
import { AppType, AppTypeAttributes } from '../../../types/api/enums/application/AppType.enum';
import { CoverageType } from '../../../types/api/enums/application/coverageType.enum';
import { DesignatedCountyElection } from '../../../types/api/enums/application/designatedCountyElection';
import { EntityTypeEnum } from '../../../types/api/enums/application/entityType.enum';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { EntityType } from '../../../types/api/insureds/EntityType';
import { Insured } from '../../../types/api/insureds/Insured';
import { TaxType } from '../../../types/api/insureds/TaxType';
import { getCommodityName, getCountyName, getPracticeAbbrevFromPracticeCode } from '../../../utils/adm';
import { isNotNullOrUndefined } from '../../../utils/nullHandling';
import { getCoverageLevelString } from '../../applicationsModal/wizardSteps/appDecisionsPage/appTaskCoverageRow';
import { getAppTaskStateName, getTaxTypeNameFromId } from './applicationMapper';
import { ecoPlanCodesNameString, filterCoveragesByPlanCodeName, hipPlanCodesNameString, mapDelimitedAuthorizedSigners, mapDelimitedCancelTransferCrops, mapDelimitedPoasAndAuthorizedReps, mapDelimitedUnitAndOptionCodes, mapNewProducerCoverages, scoPlanCodesNameString, shouldShowTypeOrPracticeOnForm, staxPlanCodesNameString } from './applicationMapperUtils';

// Maximum SBIs for Hudson MPCI application before requiring an additional SBI form to be generated.
export const MaxHudsonSbis = 3;

export const MapHudsonApplication = (appTask: AppTask, applicationWizard: ApplicationWizard, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], aips: AIP[], agentInfo: AgentInformation, agencyInfo: AgencyInformation, insured: Insured) => {
  const sbis = mapSBIs(appTask, entityTypes, taxTypes);
  const mappedApplication: HudsonApplicationDto = {
    agency: mapAgencyInformation(agencyInfo),
    agent: mapAgentInformation(appTask, agentInfo),
    applicant: mapApplicantInformation(appTask, entityTypes, taxTypes, insured),
    applicationInfo: mapApplicationInformation(appTask, applicationWizard, admData),

    conditionsOfAcceptance: mapConditionsOfAcceptance(applicationWizard),
    cropInfo: mapCropInfo(appTask, applicationWizard, admData),
    ecoCropInfo: mapEcoCropsInfo(appTask, admData),
    hipCropInfo: mapHipCropInfo(appTask, admData),
    otherChanges: {
      addRemoveSBI: false,  // ignore - future/post 1.0 release - GETTY
      correctAuthorizedRepresentative: false,  // ignore - future/post 1.0 release - GETTY
      correctAddress: false,  // ignore - future/post 1.0 release - GETTY
      correctSbiIdNumber: false,  // ignore - future/post 1.0 release - GETTY
      correctInsuredIdNumber: false,  // ignore - future/post 1.0 release - GETTY
      correctInsuredSpelling: false,  // ignore - future/post 1.0 release - GETTY
      correctSbiSpelling: false,  // ignore - future/post 1.0 release - GETTY
      addRemoveCountyElection: false,  // ignore - future/post 1.0 release - GETTY
      other: false,  // ignore - future/post 1.0 release - GETTY
    },
    policy: mapPolicy(appTask, admData),
    policyCancellationInfo: mapPolicyCancellationInfo(appTask),
    policyTransferInformation: mapPolicyTransferInformation(appTask, agentInfo, admData, aips),
    postClosingAddedLandCoveredBy: {
      sco: false, // ignore - future/post 1.0 release - GETTY
      eco: false, // ignore - future/post 1.0 release - GETTY
      stax: false, // ignore - future/post 1.0 release - GETTY
    },
    remarks: null,
    sbIs: sbis.slice(0, MaxHudsonSbis),
    scoCropInfo: mapScoCropsInfo(appTask, admData),
    signatureInfo: mapSignatureInfo(appTask),
    staxCropInfo: mapStaxCropsInfo(appTask),
  };

  return mappedApplication;
};


const mapAgencyInformation = (agency: AgencyInformation) => {
  const agencyInfo: HudsonAgency = {
    agencyName: agency.agencyName,
    phone: agency.phone,
    address: agency.address,
    city: agency.city,
    state: agency.state,
    zipCode: agency.zipCode,
  };

  return agencyInfo;
};

const mapApplicantInformation = (appTask: AppTask, entityTypes: EntityType[], taxTypes: TaxType[], insured: Insured): HudsonApplicant => {
  const personType = entityTypes.find(x => x.entityTypeId === insured.entityTypeId);
  const spousalEntityTypeId = entityTypes.find(entityType => entityType.name === EntityTypeEnum.SpousalMarried)?.entityTypeId;
  const spouse = appTask.insuredOtherPersonsOfInterest.find(x => x.personOfInterestType === PersonOfInterestType.Spouse);
  const applicant: HudsonApplicant = {
    fullName: insured.name,
    email: insured.email,
    phone: insured.phone,
    personType: personType?.name ?? null,
    taxIdType: getTaxTypeNameFromId(insured.taxTypeId, taxTypes),
    taxId: insured.taxId,
    spouseFirstName: null,
    spouseLastName: null,
    spouseTaxIdType: null,
    spouseTaxId: null,
    address: insured.address.addressLine1,
    city: insured.address.city,
    state: insured.address.state,
    zipCode: insured.address.postalCode,
    stateOfIncorporation: insured.corporationState,
    powerOfAttorney: mapDelimitedPoasAndAuthorizedReps(appTask),
  };

  if (insured.entityTypeId === spousalEntityTypeId && isNotNullOrUndefined(spouse)) {
    applicant.spouseFirstName = spouse.firstName;
    applicant.spouseLastName = spouse.lastName;
    applicant.spouseTaxId = spouse.taxId;
    applicant.spouseTaxIdType = getTaxTypeNameFromId(spouse.taxTypeId, taxTypes);
  }

  return applicant;
};

const mapAgentInformation = (appTask: AppTask, agent: AgentInformation): HudsonAgent => {
  const agentInfo: HudsonAgent = {
    firstName: agent.firstName,
    lastName: agent.lastName,
    email: agent.email,
    agentCode: appTask.agentCodeName,
  };

  return agentInfo;
};

const mapApplicationInformation = (appTask: AppTask, applicationWizard: ApplicationWizard, admYears: ADMYear[]): HudsonApplicationInformation => {
  const { isInsuringLandlordShare, isInsuringTenantShare } = appTask;
  const newProducerCoverages = mapNewProducerCoverages(appTask, admYears);

  const applicationInfo: HudsonApplicationInformation = {
    type: AppTypeAttributes[appTask.appType].name,
    parentOrGuardian: null, // Not part of app wizard at this time but an edge case that would need to be handled in the future if not 18
    limitedResourceFarmer: applicationWizard.isLimitedResourceFarmer,
    atLeast18: applicationWizard.isApplicantAtLeast18,
    newProducer: newProducerCoverages.length > 0,
    verticallyIntegratedProducer: applicationWizard.isVIP,
    isInsuringTenantShare: isInsuringTenantShare,
    isInsuringLandlordShare: isInsuringLandlordShare,
    categoryBNational: appTask.designatedCountyElection ? appTask.designatedCountyElection === DesignatedCountyElection.Nation : null,
    categoryBState: appTask.designatedCountyElection ? appTask.designatedCountyElection === DesignatedCountyElection.State : null,
  };
  return applicationInfo;
};

const mapConditionsOfAcceptance = (applicationWizard: ApplicationWizard): HudsonConditionsOfAcceptance => {
  return {
    a: applicationWizard.fciaDelinquency,
    b: applicationWizard.controlledSubstanceConviction,
    c: applicationWizard.fciaAgreementDisqualification,
    d: applicationWizard.disqualifiedOrDebarred,
    e: applicationWizard.fciaAgreementDisqualification,
    f: applicationWizard.currentLikeInsurance,
  };
};

const mapCropInfo = (appTask: AppTask, applicationWizard: ApplicationWizard, admData: ADMYear[]): HudsonCropInfo[] => {
  return appTask.coverages.map(coverage => {
    const isCoverageCanceled = coverage.coverageType === CoverageType.Cancel;
    const coveragePractice = coverage.practiceCode !== null ? getPracticeAbbrevFromPracticeCode(admData, appTask.cropYear, coverage.practiceCode) : '';
    const typePractice = isCoverageCanceled ? 'Cancel' : shouldShowTypeOrPracticeOnForm(coverage) ? `${coveragePractice} ${coverage.typeAbbrev}` : null; // this field concatenates both PRACTICE and TYPE
    const yesFormValue = 'YES';
    const noFormValue = 'NO';

    return {
      // Assuming coverage has fields that map directly to HudsonCropInfo
      ape: null,  // ignore - future/post 1.0 release - GETTY
      countyCode: isNotNullOrUndefined(coverage.countyCode) ? getCountyName(admData, appTask.cropYear, appTask.countyCode) : '',
      coverageLevel: coverage.upperCoverageLevel?.toString() ?? '',
      cropCode: getCommodityName(admData, appTask.cropYear, coverage.cropCode),
      desCty: isNotNullOrUndefined(coverage.isDesignatedCounty) && coverage.isDesignatedCounty ? yesFormValue : noFormValue,
      effectiveCropYear: appTask.cropYear.toString(),
      ezHailAcres: null,  // ignore - future/post 1.0 release - GETTY
      ezHailPlan: null,   // ignore - future/post 1.0 release - GETTY
      ezHailPrice: null,  // ignore - future/post 1.0 release - GETTY
      intendedAcres: isNotNullOrUndefined(coverage.intendedNetAcres) ? coverage.intendedNetAcres.toString() : '',
      newProduct: isNotNullOrUndefined(coverage.isNewProducer) && coverage.isNewProducer ? yesFormValue : noFormValue,
      options: mapDelimitedUnitAndOptionCodes(coverage),
      plan: coverage.planCodeName,
      price: coverage.protectionFactor !== null ? coverage.protectionFactor.toString() : null,
      typePractice: typePractice,
      vip: applicationWizard.isVIP ? yesFormValue : noFormValue,
    };
  });
};

const mapEcoCropsInfo = (appTask: AppTask, admData: ADMYear[]): HudsonEcoCropInfo[] => {
  const ecoCrops = filterCoveragesByPlanCodeName(appTask.coverages, ecoPlanCodesNameString);

  return ecoCrops.map(coverage => {
    // we already know that this coverage has an ECO child coverage because this array ONLY contains those coverages
    const ecoChild = coverage.childCoverages.find(childCoverage => childCoverage.planCodeName.includes(ecoPlanCodesNameString));
    return {
      countyCode: appTask.countyCode ? getCountyName(admData, appTask.cropYear, appTask.countyCode) : '',
      // For Hudson form, we want coverage level of parent coverage here, not the ECO coverage
      coverageLevel: coverage.upperCoverageLevel?.toString() ?? '',
      cropCode: isNotNullOrUndefined(coverage.cropCode) ? getCommodityName(admData, appTask.cropYear, coverage.cropCode) : null,
      ecoCoveragePercent: ecoChild?.protectionFactor?.toString() ?? '',
      ecoPlan: ecoChild?.planCodeName ?? '',
      lossTrigger90: ecoChild?.upperCoverageLevel === 90,
      lossTrigger95: ecoChild?.upperCoverageLevel === 95,
      underlyingPlan: coverage.planCodeName,
    };
  });
};

const mapHipCropInfo = (appTask: AppTask, admData: ADMYear[]): HudsonHipCropInfo[] => {
  const hipCrops = filterCoveragesByPlanCodeName(appTask.coverages, hipPlanCodesNameString);

  return hipCrops.map(coverage => {
    // we already know that this coverage has a HIP child coverage because this array ONLY contains those coverages
    const hipChild = coverage.childCoverages.find(childCoverage => childCoverage.planCodeName.includes(hipPlanCodesNameString));
    return {
      countyCode: appTask.countyCode ? getCountyName(admData, appTask.cropYear, appTask.countyCode) : '',
      cropCode: isNotNullOrUndefined(coverage.cropCode) ? getCommodityName(admData, appTask.cropYear, coverage.cropCode) : null,
      hipProtectionFactor: hipChild?.protectionFactor?.toString() ?? null,
      optionCodes: mapDelimitedUnitAndOptionCodes(coverage),
      practice: null, // ignore - GETTY
      type: null, // ignore - GETTY
      underlyingPlan: null, // ignore - GETTY
    };
  });
};

const mapPolicy = (appTask: AppTask, admData: ADMYear[]): HudsonPolicyInformation => {
  const policyState = getAppTaskStateName(appTask, admData);
  const cropYear = appTask.cropYear.toString();

  return {
    cropYear: cropYear,
    policyNumber: appTask.appType !== AppType.CancelTransfer ? appTask.policyNumber : null,
    state: policyState,
  };
};

const mapPolicyCancellationInfo = (appTask: AppTask): HudsonPolicyCancellationInformation => {
  // ignore - future/post 1.0 release - GETTY
  return {
    aipRepresentativePrintedName: null, // ignore - future/post 1.0 release - GETTY
    deathImcompotenenceOrDissolition: false, // ignore - future/post 1.0 release - GETTY
    insuredRequest: false, // ignore - future/post 1.0 release - GETTY
    mutualConsent: false, // ignore - future/post 1.0 release - GETTY
    other: false, // ignore - future/post 1.0 release - GETTY
  };
};

const mapPolicyTransferInformation = (appTask: AppTask, agent: AgentInformation, admYears: ADMYear[], aips: AIP[]): HudsonPolicyTransferInformation => {
  const isAppCancelTransfer = appTask.appType === AppType.CancelTransfer;

  if (!isAppCancelTransfer) {
    return {
      aipName: null,
      aipCode: null,
      aipRepresentativePrintedName: null,
      assumingAgent: null,
      cropsToBeCancelledOrTransferred: null,
      cropsYear: null,
      policyNumber: null,
    };
  }

  const previousAIP = aips.find(x => x.aipId === appTask.previousAIPId);
  return {
    aipName: previousAIP?.aipName ?? '',
    aipCode: null,
    aipRepresentativePrintedName: null,
    assumingAgent: mapAgentInformation(appTask, agent),
    cropsToBeCancelledOrTransferred: mapDelimitedCancelTransferCrops(appTask, admYears),
    cropsYear: appTask.cropYear.toString(),
    policyNumber: appTask.previousPolicyNumber,
  };
};

const mapSBIs = (appTask: AppTask, entityTypes: EntityType[], taxTypes: TaxType[]) => {
  const sbiPOIs = appTask.insuredOtherPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.SBI || x.personOfInterestType === PersonOfInterestType.LandLordOrTenant || x.personOfInterestType === PersonOfInterestType.Spouse);
  return sbiPOIs.map(sbi => {
    const personType = entityTypes.find(x => x.entityTypeId === sbi.entityTypeId);
    const mappedSBI: HudsonSbi = {
      firstName: sbi.firstName,
      lastName: sbi.lastName,
      phone: sbi.phone,
      personType: personType?.name ?? null,
      taxIdType: getTaxTypeNameFromId(sbi.taxTypeId, taxTypes),
      taxId: sbi.taxId,
      address: sbi.address?.addressLine1 ?? null,
      city: sbi.address?.city ?? null,
      state: sbi.address?.state ?? null,
      zipCode: sbi.address?.postalCode ?? null,
      landlordOrTenant: sbi.personOfInterestType === PersonOfInterestType.LandLordOrTenant,
    };
    return mappedSBI;
  });
};

export const MapHudsonAdditionalSBIs = (appTask: AppTask, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], agent: AgentInformation, agency: AgencyInformation, insured: Insured) => {
  const sbis = mapSBIs(appTask, entityTypes, taxTypes);
  const mappedSBIForm: HudsonSbiFormDTO = {
    applicant: mapApplicantInformation(appTask, entityTypes, taxTypes, insured),
    agent: mapAgentInformation(appTask, agent),
    agency: mapAgencyInformation(agency),
    sbIs: sbis.slice(MaxHudsonSbis, sbis.length),
    policy: mapPolicy(appTask, admData),
    signatureInfo: mapSignatureInfo(appTask),
  };

  return mappedSBIForm;
};

const mapScoCropsInfo = (appTask: AppTask, admData: ADMYear[]): HudsonScoCropInfo[] => {
  const scoCrops = filterCoveragesByPlanCodeName(appTask.coverages, scoPlanCodesNameString);
  return scoCrops.map(coverage => {
    // we already know that this coverage has an SCO child coverage because this array ONLY contains those coverages
    const scoChild = coverage.childCoverages.find(childCoverage => childCoverage.planCodeName.includes(scoPlanCodesNameString));
    return {
      arcEnrolled: false, // per Ashley/Getty, default to false until spring season
      countyCode: appTask.countyCode ? getCountyName(admData, appTask.cropYear, appTask.countyCode) : '',
      coverageLevel: coverage.upperCoverageLevel?.toString() ?? null,
      cropCode: isNotNullOrUndefined(coverage.cropCode) ? getCommodityName(admData, appTask.cropYear, coverage.cropCode) : null,
      scoCoveragePercent: scoChild?.protectionFactor?.toString() ?? null,
      scoPlan: scoChild?.planCodeName ?? null,
      underlyingPlan: coverage.planCodeName,
    };
  });
};

const mapSignatureInfo = (appTask: AppTask): HudsonSignatureInfo => {
  // Placeholder implementation; adjust based on actual data
  return {
    applicantPrintedName: null, // Add logic as required
    agentPrintedName: null, // Add logic as required
    signatureAuthorizations: mapDelimitedAuthorizedSigners(appTask),
  };
};

const mapStaxCropsInfo = (appTask: AppTask): HudsonStaxCropInfo[] => {
  // Post 1.0 Release Logic below, keeping for later mapping.
  const staxCrops = appTask.coverages.filter(coverage => coverage.planCodeName.includes(staxPlanCodesNameString));
  const staxMapped = staxCrops.map(coverage => {
    return {
      countyCode: appTask.countyCode,
      coverageRange: getCoverageLevelString(coverage),
      cropCode: coverage.cropCode,
      lossTrigger: null,
      practice: null,
      scoEcoCoverage: null,
      staxPlan: null,
      staxProtectionFactor: null,
      type: null,
      underlyingPlan: null,
    };
  });

  // we are to ignore STAX Crop Info for release 1.0 - GETTY
  return [];
};
