import { FarmBillCodeType, ScenarioPieceType, UnitStructureCode, getFarmBillPercentAcresCovered, roundToPlaces } from '@silveus/calculations';
import BaseUnit from '../../../types/api/BaseUnit';
import { UnitGroupId, UnitYearId } from '../../../types/api/PrimaryKeys';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { generatePrimaryKey, toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import * as uuid from 'uuid';

const getFarmBillCodeFromPlanCode = (planCode: string): FarmBillCodeType => {
  return planCode === FarmBillCodeType.County ? FarmBillCodeType.County : FarmBillCodeType.Producer;
};

export const getFarmBillPercentAcresCoveredFromScenarioPiece = (scenarioPieceType: ScenarioPieceType, planCode: string): number => {
  const isArc = ScenarioPieceType.Arc === scenarioPieceType;

  const farmBillCode = getFarmBillCodeFromPlanCode(planCode);
  return getFarmBillPercentAcresCovered(isArc, farmBillCode);
};

export const getFarmBillPerAcreIndemnities = (scenarioPieceType: ScenarioPieceType, planCode: string, baseAcres: number, plantedAcres: number, totalIndemnity: number): {
  indemnityPerBaseAcre: number;
  indemnityPerNetBaseAcre: number;
  indemnityPerPlantedAcre: number;
} => {
  return {
    indemnityPerBaseAcre: roundToPlaces(baseAcres <= 0 ? 0 : (totalIndemnity / baseAcres), 2),
    indemnityPerNetBaseAcre: roundToPlaces(baseAcres <= 0 ? 0 : totalIndemnity / getFarmBillNetAcres(scenarioPieceType, planCode, baseAcres), 2),
    indemnityPerPlantedAcre: roundToPlaces(plantedAcres <= 0 ? 0 : totalIndemnity / plantedAcres, 2),
  };
};

export const getFarmBillDisplayName = (scenarioPieceType: ScenarioPieceType, planCode: string, addFarmBillCode: boolean): string => {
  let result: string = '';
  if (scenarioPieceType === ScenarioPieceType.Plc) {
    result = 'FarmBill - PLC';
  } else {
    result = 'FarmBill - ARC';
    if (addFarmBillCode) {
      const farmBillCode = getFarmBillCodeFromPlanCode(planCode);
      if (farmBillCode === FarmBillCodeType.County) {
        result += ' - County';
      } else {
        result += ' - Producer';
      }
    }
  }

  return result;
};

export const getFarmBillNetAcres = (scenarioPieceType: ScenarioPieceType, planCode: string, baseAcres: number): number => {
  return roundToPlaces(getFarmBillPercentAcresCoveredFromScenarioPiece(scenarioPieceType, planCode) * baseAcres, 2);
};

export const farmBillMakeBaseUnitGroup = (scenarioPiece: RowCropScenarioPiece, unit: BaseUnit): UnitGroup => {
  const unitYearId = toPrimaryKey<UnitYearId>(unit.id);
  const fakeUnitGroup: UnitGroup = {
    unitGroupId: toPrimaryKey<UnitGroupId>(uuid.v4()),
    scenarioPieceId: scenarioPiece.scenarioPieceId,
    year: 0,
    unitStructure: UnitStructureCode.AU,
    unitYearIds: [unitYearId],
    offlineCreatedOn: undefined,
    offlineLastUpdatedOn: undefined,
    offlineDeletedOn: undefined,
  };

  return fakeUnitGroup;
};

export const farmBillMakeBaseUnit = (scenarioPiece: RowCropScenarioPiece): BaseUnit => {
  const unit: BaseUnit = {
    id: generatePrimaryKey(),
    acres: scenarioPiece.rowCropScenarioPieceExtendedData?.acres ?? 0,
    actualYield: 0,
    adjustedYield: 0,
    approvedYield: 0,
    rateYield: 0,
    sharePercent: 1,
    year: 0,
    commodityCode: '',
    countyId: '',
    typeId: '',
    practiceId: '',
    options: [],
    township: null,
    range: null,
    subCountyCode: null,
  };

  return unit;
};