import { DeepPartialSkipArrayKey, FieldValues, useFormContext, useWatch } from 'react-hook-form';
/** This was created based on the suggestion from react hook form [documentation](https://react-hook-form.com/docs/usewatch):
  * useWatch's execution order matters, which means if you update a form value before the subscription is in place, then the value updated will be ignored.
  * This version creates the subsription like useWatch, but also merges with latest form value**/
export const useFormValues = <TFieldValues extends FieldValues = FieldValues>() => {
  const { getValues, setValue } = useFormContext<TFieldValues>();

  //setting watchFormValue to use the DeepPartialSkipArrayKey<TFieldValues> return type that useWatch does
  //explicitly because getValues returns the UseFormValue<TFieldValue> which is the same except for it doesn't
  //state that it can be undefined which is actually a lie and can lead to issues if you don't account for
  //handling undefined before react hook forms populates the value
  //for example: if a year field is a number, getValue return type is number, even though while at initial
  //render it actually can be undefined. useWatch would show year as number | undefined.
  const watchFormValue: DeepPartialSkipArrayKey<TFieldValues> = { ...useWatch<TFieldValues>(), ...getValues };
  return {
    watchFormValue,
    setValue,
  };
};
