import * as React from 'react';
import { useMemo, useState } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import EditIcon from '@mui/icons-material/Edit';
import GridOnIcon from '@mui/icons-material/GridOn';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  CreateMatrixFromScenariosMenu
} from '../../scenario/components/scenarioCard.component.createMatrixFromScenariosMenu';
import Matrix from '../../../types/api/Matrix';
import {
  firstTimeLinkedMatrix,
  removeMatrix,
  setCurrentlySelectedMatrixId
} from '../../../app/matricesSlice';
import { ConfirmStateContent, openConfirm } from '../../../app/confirmSlice';
import classNames from 'classnames';
import MatrixWrapper from './matrixWrapper.component';
import { openDrawer } from '../../../app/appDrawerSlice';
import { useGetScenariosFromScenarioIds, useUniqueScenarioPieceTypesForScenarios } from '../../../hooks/scenarioHooks';
import MatrixCardHeader from './matrixCardHeader.component';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkOnIcon from '@mui/icons-material/Link';
import {
  addLinkedMatrix,
  modifyLinkedMatricesForUser,
  removeLinkedMatrix,
  selectUserLinkedMatrices
} from '../../../app/userSettingsSlice';
import { selectScenarioById } from '../../../app/scenariosSlice';
import { selectQuoteById } from '../../../app/quotesSlice';

import './matrixCard.styles.css';
import { Print } from '@mui/icons-material';
import { selectIsReportLoading } from '../../../app/reportsSlice';

interface MatrixCardProps {
  matrix: Matrix;
  isSelectedItem: boolean;
  hideLinkButton?: boolean;
  printMatrix?: (matrix: Matrix) => Promise<void>;
}

const MatrixCard = ({ matrix, isSelectedItem, printMatrix, hideLinkButton = false }: MatrixCardProps) => {
  const dispatch = useAppDispatch();

  const linkedMatrices = useAppSelector(selectUserLinkedMatrices);

  const scenarioIds = useMemo(() => {
    return matrix.scenarioMatrices.map(x => x.scenarioId);
  }, [matrix.scenarioMatrices]);

  const includedScenarios = useGetScenariosFromScenarioIds(scenarioIds);
  const includedScenarioPieces = useUniqueScenarioPieceTypesForScenarios(scenarioIds);
  const [matrixMenuAnchor, setMatrixMenuAnchor] = useState<null | HTMLElement>(null);

  const primaryScenario = useAppSelector(s => selectScenarioById(s, matrix.primaryScenarioId));
  const quote = useAppSelector(s => primaryScenario === null ? null : selectQuoteById(s, primaryScenario.quoteId));

  const matricesLinkageData = quote === null ? undefined : linkedMatrices.quotes[quote.quoteId];
  const isMatrixLinked = matricesLinkageData?.linkedMatrixIds.includes(matrix.matrixId) ?? false;
  const isReportLoading = useAppSelector(selectIsReportLoading);

  const openMatrixMenu = (e: React.MouseEvent<HTMLElement>) => {
    setMatrixMenuAnchor(e.currentTarget);
  };

  const deleteMatrix = () => {
    dispatch(removeMatrix({ matrix }));
  };

  const onDeleteMatrixClick = () => {
    const confirmWindow: ConfirmStateContent = {
      title: 'Delete Matrix?',
      message: 'Are you sure you want to delete this matrix?',
      confirmText: 'Delete',
      onConfirm: deleteMatrix,
    };
    dispatch(openConfirm(confirmWindow));
  };

  const editMatrix = () => {
    dispatch(openDrawer({ formName: 'matrixForm', matrixId: matrix.matrixId, primaryScenarioId: matrix.primaryScenarioId, scenarioIds: matrix.scenarioMatrices.map(sm => sm.scenarioId) }));
  };

  const internalPrintMatrix = async () => await printMatrix?.(matrix);
  const linkMatrix = async () => {
    if (quote === null) { return; }
    if (isMatrixLinked) { //It's already linked, and toggle to unlink
      dispatch(removeLinkedMatrix({ quoteId: quote.quoteId, matrixId: matrix.matrixId }));
      await dispatch(modifyLinkedMatricesForUser()); // Update linked matrices for user
    } else {
      dispatch(addLinkedMatrix({ quoteId: quote.quoteId, matrixId: matrix.matrixId }));
      await dispatch(modifyLinkedMatricesForUser()); // Update linked matrices for user
      await dispatch(firstTimeLinkedMatrix({ quoteId: quote.quoteId, matrixId: matrix.matrixId }));
    }
  };

  const onMatrixCardClick = () => {
    // This is just here so I can demonstrate the different visual state of a matrix being selected, don't know if
    // card click will really be how we set the current matrix.
    dispatch(setCurrentlySelectedMatrixId(matrix.matrixId));
  };

  return (
    <Grid container pl={2} paddingTop="0px !important" height="100%" >
      <Grid item xs={12}>
        <Card className={classNames({ 'scenario-card-box-shadow': isSelectedItem })} onClick={onMatrixCardClick} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <CardHeader
            sx={{ mr: 0, p: 0 }}
            title={
              <Grid container sx={{ padding: '8px', alignItems: 'center' }}>
                <Grid item xs="auto">
                  {primaryScenario && quote !== null && (
                    <MatrixCardHeader quote={quote} scenario={primaryScenario} />
                  )}
                </Grid>
                <Grid item xs container justifyContent="flex-end">
                  <Grid item xs="auto">
                    {!hideLinkButton && (
                      <IconButton onClick={linkMatrix} color="primary" sx={{ pr: 0, pl: 0, mr: 0, ml: 1 }}>
                        {isMatrixLinked ? <LinkOnIcon /> : <LinkOffIcon sx={{ color: theme => theme.palette.altText.disabled }} />}
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton onClick={editMatrix} color="primary" sx={{ pr: 0, pl: 0, mr: 0, ml: 0 }}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs="auto">
                    <div key={`${matrix.matrixId}-matrix-icon`}>
                      <Tooltip placement="top" title="Matrix">
                        <div id={matrix.matrixId} style={{ paddingLeft: '5px' }} >
                          <IconButton color="primary" sx={{ pr: 0, pl: 0, mr: 0, ml: 0 }} onClick={openMatrixMenu}>
                            <GridOnIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                      {matrixMenuAnchor && (
                        <CreateMatrixFromScenariosMenu
                          anchor={matrixMenuAnchor}
                          closeMenu={() => setMatrixMenuAnchor(null)}
                          matrix={matrix}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton onClick={onDeleteMatrixClick} color="primary" sx={{ pr: 0, pl: 0, mr: 0, ml: 0 }}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  {printMatrix !== undefined &&
                  <Grid item xs="auto">
                    <IconButton onClick={internalPrintMatrix} color="primary" sx={{ pr: 0, pl: 0, mr: 0, ml: 0 }}>
                      { isReportLoading ? <CircularProgress color="inherit" size={25} /> : <Print /> }
                    </IconButton>
                  </Grid>
                  }
                </Grid>
              </Grid>
            }
          />
          <CardContent sx={{ paddingTop: 0 }} style={{ height: '100%' }}>
            <MatrixWrapper
              matrix={matrix}
              includedScenarios={includedScenarios}
              includedScenarioPieces={includedScenarioPieces}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MatrixCard;