import { InsurancePlanCode, UnitStructureCode } from '@silveus/calculations';
import { ADMYear } from '../../../types/api/adm/ADMYear';
import { AgencyInformation } from '../../../types/api/agencyInformation';
import { AgentInformation } from '../../../types/api/agentInformation';
import { AIP } from '../../../types/api/aip';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { AppTask } from '../../../types/api/AppTask';
import { AgriSompoAgency, AgriSompoAgent, AgriSompoApplicant, AgriSompoApplicationDto, AgriSompoApplicationInfo, AgriSompoConditionsOfAcceptance, AgriSompoCropInfo, AgriSompoIntendedAcres, AgriSompoOtherChanges, AgriSompoPolicyCancellationInfo, AgriSompoPolicyInfo, AgriSompoPolicyTransferInfo, AgriSompoSBI, AgriSompoSignatureAuthorization, AgriSompoSignatureInfo } from '../../../types/api/documents/agriSompoTypes';
import { AppType, AppTypeAttributes } from '../../../types/api/enums/application/AppType.enum';
import { CoverageType } from '../../../types/api/enums/application/coverageType.enum';
import { DesignatedCountyElection } from '../../../types/api/enums/application/designatedCountyElection';
import { EntityTypeEnum } from '../../../types/api/enums/application/entityType.enum';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { EntityType } from '../../../types/api/insureds/EntityType';
import { Insured } from '../../../types/api/insureds/Insured';
import { TaxType } from '../../../types/api/insureds/TaxType';
import { getCommodityName, getCountyName, getPracticeAbbrevFromPracticeCode } from '../../../utils/adm';
import { isNotNilOrEmpty, isNotNullOrUndefined } from '../../../utils/nullHandling';
import { getAppTaskStateName, getTaxTypeNameFromId } from './applicationMapper';
import { ecoPlanCodesNameString, formatPersonOfInterestName, hipPlanCodesNameString, mapDelimitedCancelTransferCrops, mapDelimitedPoasAndAuthorizedReps, mapDelimitedUnitAndOptionCodes, mapNewProducerCoverages, scoPlanCodesNameString, shouldShowTypeOrPracticeOnForm, staxPlanCodesNameString } from './applicationMapperUtils';

export const MaxAgriSompoSbi = 35;

export const MapAgriSompoApplication = (appTask: AppTask, applicationWizard: ApplicationWizard, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], aips: AIP[], agentInfo: AgentInformation, agencyInfo: AgencyInformation, insured: Insured) => {

  const mappedApplication: AgriSompoApplicationDto = {
    agency: mapAgency(agencyInfo),
    agent: mapAgent(appTask, agentInfo),
    applicant: mapApplicant(appTask, entityTypes, taxTypes, insured),
    applicationInfo: mapApplicationInfo(appTask, applicationWizard, admData),
    conditionsOfAcceptance: mapConditionsOfAcceptance(applicationWizard),
    cropInfo: mapCropInfo(appTask, applicationWizard, admData),
    intendedAcreage: mapIntendedAcres(appTask, applicationWizard, admData),
    otherChanges: mapOtherChanges(appTask),
    policyCancellationInfo: mapPolicyCancellationInfo(appTask),
    policyInfo: mapPolicyInfo(appTask, admData),
    policyTransferInfo: mapPolicyTransferInfo(appTask, agentInfo, admData, aips),
    remarks: null,
    sbis: mapSBIs(appTask, entityTypes, taxTypes),
    signatureInfo: mapSignatureInfo(appTask, agentInfo),
  };

  return mappedApplication;
};

const mapAgency = (agency: AgencyInformation) => {
  const agencyInfo: AgriSompoAgency = {
    address: agency.address,
    agencyName: agency.agencyName,
    city: agency.city,
    phone: agency.phone,
    state: agency.state,
    zipCode: agency.zipCode,
  };

  return agencyInfo;
};

const mapAgent = (appTask: AppTask, agent: AgentInformation): AgriSompoAgent => {
  const agentInfo: AgriSompoAgent = {
    agentCode: appTask.agentCodeName,
    email: agent.email,
    firstName: agent.firstName,
    lastName: agent.lastName,
  };

  return agentInfo;
};

const mapApplicant = (appTask: AppTask, entityTypes: EntityType[], taxTypes: TaxType[], insured: Insured): AgriSompoApplicant => {
  const personType = entityTypes.find(x => x.entityTypeId === insured.entityTypeId);
  const spousalEntityTypeId = entityTypes.find(entityType => entityType.name === EntityTypeEnum.SpousalMarried)?.entityTypeId;
  const spouse = appTask.insuredOtherPersonsOfInterest.find(x => x.personOfInterestType === PersonOfInterestType.Spouse);
  const applicant: AgriSompoApplicant = {
    address: insured.address.addressLine1,
    city: insured.address.city,
    email: insured.email,
    fullName: insured.name,
    personType: personType?.name ?? null,
    phone: insured.phone,
    powerOfAttorney: mapDelimitedPoasAndAuthorizedReps(appTask),
    spouseFirstName: null,
    spouseLastName: null,
    spouseTaxId: null,
    spouseTaxIdType: null,
    state: insured.address.state,
    stateOfIncorporation: insured.corporationState,
    taxId: insured.taxId,
    taxIdType: getTaxTypeNameFromId(insured.taxTypeId, taxTypes),
    zipCode: insured.address.postalCode,
  };

  if (insured.entityTypeId === spousalEntityTypeId && isNotNullOrUndefined(spouse)) {
    applicant.spouseFirstName = spouse.firstName;
    applicant.spouseLastName = spouse.lastName;
    applicant.spouseTaxId = spouse.taxId;
    applicant.spouseTaxIdType = getTaxTypeNameFromId(spouse.taxTypeId, taxTypes);
  }

  return applicant;
};

const mapApplicationInfo = (appTask: AppTask, applicationWizard: ApplicationWizard, admYears: ADMYear[]): AgriSompoApplicationInfo => {
  const { isInsuringLandlordShare, isInsuringTenantShare } = appTask;
  const newProducerCoverages = mapNewProducerCoverages(appTask, admYears);

  const applicationInfo: AgriSompoApplicationInfo = {
    type: AppTypeAttributes[appTask.appType].name,
    limitedResourceFarmer: applicationWizard.isLimitedResourceFarmer,
    atLeast18: applicationWizard.isApplicantAtLeast18,
    newProducer: newProducerCoverages.length > 0,
    verticallyIntegratedProducer: applicationWizard.isVIP,
    insuringLandlordOrTenantShare: (isInsuringLandlordShare ?? false) || (isInsuringTenantShare ?? false),
    categoryNational: appTask.designatedCountyElection === DesignatedCountyElection.Nation,
  };
  return applicationInfo;
};

const mapConditionsOfAcceptance = (applicationWizard: ApplicationWizard): AgriSompoConditionsOfAcceptance => {
  return {
    a: applicationWizard.fciaDelinquency ?? false,
    b: applicationWizard.controlledSubstanceConviction ?? false,
    c: applicationWizard.fciaAgreementDisqualification ?? false,
    d: applicationWizard.disqualifiedOrDebarred ?? false,
    e: applicationWizard.fciaAgreementDisqualification ?? false,
    f: applicationWizard.currentLikeInsurance ?? false,
  };
};

const mapCropInfo = (appTask: AppTask, applicationWizard: ApplicationWizard, admData: ADMYear[]): AgriSompoCropInfo[] => {
  return appTask.coverages.map(coverage => {
    const isCoverageCanceled = coverage.coverageType === CoverageType.Cancel;
    const coveragePractice = coverage.practiceCode !== null ? getPracticeAbbrevFromPracticeCode(admData, appTask.cropYear, coverage.practiceCode) : '';
    const cropString = getCommodityName(admData, appTask.cropYear, coverage.cropCode);

    const ecoCoverage = coverage.childCoverages.find(childCov => childCov.planCodeName.includes(ecoPlanCodesNameString));
    const hipCoverage = coverage.childCoverages.find(childCov => childCov.planCode === InsurancePlanCode.HIP_WI);
    const scoCoverage = coverage.childCoverages.find(childCov => childCov.planCodeName.includes(scoPlanCodesNameString));
    const staxCoverage = coverage.childCoverages.find(childCov => childCov.planCodeName.includes(staxPlanCodesNameString));

    return {
      arcEnrolled: false,
      county: isNotNullOrUndefined(coverage.countyCode) ? getCountyName(admData, appTask.cropYear, appTask.countyCode) : '',
      coverageLevel: coverage.upperCoverageLevel?.toString() ?? null,
      crop: cropString,
      typePractice: isCoverageCanceled ? 'Cancel' : shouldShowTypeOrPracticeOnForm(coverage) ? `(${coverage.typeAbbrev}/${coveragePractice})` : '',
      designatedCounty: coverage.isDesignatedCounty === true,
      ecoCoverageLevel: ecoCoverage?.upperCoverageLevel?.toString() ?? null,
      ecoProtectionFactor: ecoCoverage?.protectionFactor?.toString() ?? null,
      hipProtectionFactor: hipCoverage?.protectionFactor?.toString() ?? null,
      newProduct: coverage.isNewProducer === true,
      optionCodes: mapDelimitedUnitAndOptionCodes(coverage),
      plan: coverage.planCodeName,
      protectionFactor: coverage.protectionFactor?.toString() ?? null,
      scoCoverageRange: '', // Per CSR Team, not needed for now
      scoProtectionFactor: scoCoverage?.protectionFactor?.toString() ?? null,
      staxCoverageLevel: isNotNullOrUndefined(staxCoverage) ?  '' : null, // TODO - per Getty, these are a later thing
      staxCoverageRange: isNotNullOrUndefined(staxCoverage) ? '' : null, // TODO - per Getty, these are a later thing
      staxProtectionFactor: isNotNullOrUndefined(staxCoverage) ? '' : null,  // TODO - per Getty, these are a later thing
      unitStructureCode: UnitStructureCode[coverage.unitStructure],
    };
  });
};

const mapIntendedAcres = (appTask: AppTask, applicationWizard: ApplicationWizard, admData: ADMYear[]): AgriSompoIntendedAcres[] => {
  return appTask.coverages.filter(c => c.isNewProducer === true || c.optionCodes.includes(hipPlanCodesNameString)).map(coverage => {
    return {
      acres: coverage.intendedNetAcres?.toString() ?? '0',
      county: isNotNullOrUndefined(coverage.countyCode) ? getCountyName(admData, appTask.cropYear, appTask.countyCode) : '',
      crop: getCommodityName(admData, appTask.cropYear, coverage.cropCode),
    };
  });
};

const mapOtherChanges = (appTask: AppTask): AgriSompoOtherChanges => {
  return {
    addRemoveSBI: false,  // ignore - future/post 1.0 release - GETTY
    correctAuthorizedRepresentative: false,  // ignore - future/post 1.0 release - GETTY
    correctInsuredIdNumber: false,  // ignore - future/post 1.0 release - GETTY
    correctInsuredSpelling: false,  // ignore - future/post 1.0 release - GETTY
    correctSbiIdNumber: false,  // ignore - future/post 1.0 release - GETTY
    correctSbiSpelling: false,  // ignore - future/post 1.0 release - GETTY
  };
};

const mapPolicyCancellationInfo = (appTask: AppTask): AgriSompoPolicyCancellationInfo => {
  // ignore - future/post 1.0 release - GETTY
  return {
    aipRepresentativePrintedName: '', // ignore - future/post 1.0 release - GETTY
    deathIncompetenceOrDissolution: false, // ignore - future/post 1.0 release - GETTY
    insuredRequest: false, // ignore - future/post 1.0 release - GETTY
    mutualConsent: false, // ignore - future/post 1.0 release - GETTY
    other: false, // ignore - future/post 1.0 release - GETTY
  };
};

const mapPolicyInfo = (appTask: AppTask, admData: ADMYear[]): AgriSompoPolicyInfo => {
  return {
    cropYear: appTask.cropYear.toString(),
    policyNumber: appTask.appType !== AppType.CancelTransfer ? appTask.policyNumber : '',
    county: getCountyName(admData, appTask.cropYear, appTask.countyCode),
    state: getAppTaskStateName(appTask, admData),
  };
};

const mapPolicyTransferInfo = (appTask: AppTask, agent: AgentInformation, admYears: ADMYear[], aips: AIP[]): AgriSompoPolicyTransferInfo => {
  const isAppCancelTransfer = appTask.appType === AppType.CancelTransfer;

  if (!isAppCancelTransfer) {
    return {
      aipName: null,
      aipCode: null,
      aipRepresentativePrintedName: null,
      assumingAgent: null,
      cropsToBeCancelTransferred: null,
      cropYear: null,
      policyNumber: null,
    };
  }

  const previousAIP = aips.find(x => x.aipId === appTask.previousAIPId);
  return {
    aipCode: '',
    aipName: previousAIP?.aipName ?? '',
    aipRepresentativePrintedName: '',
    assumingAgent: mapAgent(appTask, agent),
    cropsToBeCancelTransferred: mapDelimitedCancelTransferCrops(appTask, admYears),
    cropYear: appTask.cropYear.toString(),
    policyNumber: appTask.previousPolicyNumber,
  };
};

const mapSBIs = (appTask: AppTask, entityTypes: EntityType[], taxTypes: TaxType[]) => {
  const sbiPOIs = appTask.insuredOtherPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.SBI || x.personOfInterestType === PersonOfInterestType.LandLordOrTenant || x.personOfInterestType === PersonOfInterestType.Spouse);
  return sbiPOIs.map(sbi => {
    const personType = entityTypes.find(x => x.entityTypeId === sbi.entityTypeId);
    const mappedSBI: AgriSompoSBI = {
      address: sbi.address?.addressLine1 ?? null,
      city: sbi.address?.city ?? null,
      firstName: sbi.firstName,
      landlordOrTenant: sbi.personOfInterestType === PersonOfInterestType.LandLordOrTenant,
      lastName: sbi.lastName,
      personType: personType?.name ?? null,
      phone: sbi.phone,
      state: sbi.address?.state ?? null,
      taxId: sbi.taxId,
      taxIdType: getTaxTypeNameFromId(sbi.taxTypeId, taxTypes),
      zipCode: sbi.address?.postalCode ?? null,
    };
    return mappedSBI;
  });
};

const mapSignatureAuthorizations = (appTask: AppTask): AgriSompoSignatureAuthorization[] => {
  const authSignerArray: AgriSompoSignatureAuthorization[] = [];
  const { insuredOtherPersonsOfInterest } = appTask;
  const authorizedToSign = insuredOtherPersonsOfInterest.filter(person => person.personOfInterestType === PersonOfInterestType.AuthorizedToSign);

  for (const authSigner of authorizedToSign) {
    const name = formatPersonOfInterestName(authSigner);
    if (isNotNilOrEmpty(name)) {
      const authorizedToSign: AgriSompoSignatureAuthorization = {
        legalName: name,
      };
      authSignerArray.push(authorizedToSign);
    }
  }
  return authSignerArray;
};

const mapSignatureInfo = (appTask: AppTask, agent: AgentInformation): AgriSompoSignatureInfo => {
  return {
    agentPrintedName: `${agent.firstName} ${agent.lastName}`, // Add logic as required
    applicantPrintedName: appTask.insuredName, // Add logic as required
    grantAuthority: false, // TODO ask Getty
    removeAuthority: false, // TODO ask Getty
    signatureAuthorizations: mapSignatureAuthorizations(appTask),
  };
};
