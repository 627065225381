import { Controller, useFormContext } from 'react-hook-form';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import { useState } from 'react';
import { formatPriceDisplayValue, getPriceAdjustmentType, forwardSoldParseNumber } from '../../../../pages/scenarioPiece/forwardSold/forwardSoldUtils';
import { ForwardSoldScenarioPieceFormFields } from '../../../../pages/scenarioPiece/forwardSold/forwardSoldScenarioPieceForm.component';
import NumberTextField, { onNumericStringInputChange } from '../../../numberTextField/numberTextField.component';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

interface PriceProps {
  onValueChange: () => void;
  index: number;
  disabled: boolean;
  sx?: SxProps<Theme>;
}

const ForwardSoldPriceInput = ({ index, disabled, sx, onValueChange }: PriceProps) => {
  const { control, getValues } = useFormContext<ForwardSoldScenarioPieceFormFields>();
  const [isFocused, setIsFocused] = useState(false);
  const [typedText, setTypedText] = useState<string>('');

  const priceAdjustmentType = getPriceAdjustmentType(index, getValues);
  // Handle focus and blur events to switch between display and edit modes
  const handleFocus = () => setIsFocused(true);

  return (
    <>
      <Controller
        name={`transactions.${index}.price`}
        control={control}
        rules={{
          required: VALIDATION_RULES.required(),
        }}
        render={({
          field: { ref, ...field }, fieldState: { error },
        }) => (
          <NumberTextField
            {...field}
            // When the field is not focused, display the formatted value
            value={isFocused ? typedText : formatPriceDisplayValue(field.value, priceAdjustmentType)}
            inputProps={{ style: { fontSize: '12px', padding: '11px 5px' } }}
            onFocus={() => {
              setTypedText(field.value.toString());
              handleFocus();
            }}
            onBlur={e => {
              const cleanedValue = forwardSoldParseNumber(e.target.value, priceAdjustmentType);
              field.onChange(cleanedValue);
              setIsFocused(false);
            }}
            error={!!error}
            disabled={disabled}
            onChange={e => {
              setTypedText(e.target.value);
              onNumericStringInputChange(e, field);
              onValueChange();
            }}
            helperText={error?.message}
            size="small"
            required
            type="text"
            sx={sx}
          />
        )}
      />
    </>
  );
};


export default ForwardSoldPriceInput;
