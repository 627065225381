import { CombinedValidationResult, ValidationResult } from './validateScenario';

export function composeValidationResults <T extends object>(validationResults: ValidationResult<T>[]): CombinedValidationResult<T> {
  let composite: Partial<T> = {};
  const validationErrors: string[] = [];

  for (const validationResult of validationResults) {
    if (validationResult.propertiesToUpdate !== null) {
      composite = {
        ...composite,
        ...validationResult.propertiesToUpdate,
      };
    }
    if (validationResult.validationError !== null) {
      validationErrors.push(validationResult.validationError);
    }
  }

  return {
    propertiesToUpdate: composite,
    validationErrors: validationErrors,
  };
}