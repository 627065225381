import BaseUnit from '../../../types/api/BaseUnit';
import {
  Commodity,
  CoverageTypeCode,
  MpIndemnityParams,
  MpPremiumParams,
  MpUnit,
  UnitStructureCode,
  Unit, MpManualPremiumParams,
  SubsidyParams
} from '@silveus/calculations';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RootState } from '../../../app/store';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import { getCalcParamRequestUnits, getMatchingCalcParamDataForUnit, getUnitsForScenarioPiece } from '../calculationUtils';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import { Nullable } from '../../../types/util/Nullable';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';
import { getMpCalcDataRequest } from '../../requestInterception/scenarioPieces/mpRequestInterceptor';
import { MpCalculationParamsRequest } from '../../../types/api/calculationData/mpCalculationParamsRequest';
import MpCalculationParams from '../../../types/api/calculationData/mpCalculationParams';
import { MpRequestDTO } from '@silveus/calculations/dist/calculationOrchestrator/request/plans/mpRequestDTO';
import { MpLiveQuotePremiumParams } from '@silveus/calculations/dist/mpci/mp/params/mpLiveQuotePremiumParams';

export const createMpData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[]) => {
  //Get the calc data back from the API
  const calcParamRequest = createCalcParamRequest(state, scenarioPiece, scenario, baseUnits);
  const returnedData = await getMpCalcDataRequest(calcParamRequest);

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createMpPlanDto(scenarioPiece, returnedData, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const transformMpData = (mpCalcData: Nullable<MpCalculationParams>, unit: Nullable<BaseUnit>, scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece): MpManualPremiumParams & MpIndemnityParams | MpPremiumParams & MpIndemnityParams => {
  const mpIndemnityParams: MpIndemnityParams = {
    commodityCode: (unit?.commodityCode ?? '') as Commodity,
    coverageTypeCode: CoverageTypeCode.A,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    // MP has a separate projected price from the scenario so we pull it from the extended data
    projectedPrice: scenarioPiece.rowCropScenarioPieceExtendedData?.projectedPrice ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
    priceElectionPercent: scenarioPiece.protectionFactor / 100,
    expectedCosts: scenarioPiece.rowCropScenarioPieceExtendedData?.expectedInputCosts ?? 0,
    finalCosts: scenarioPiece.rowCropScenarioPieceExtendedData?.actualInputCosts ?? 0,
    unitStructureCode: scenarioPiece.unitStructure,

    liabilityAdjustmentFactor: 1,
    underlyingGuaranteeAmount: 0,
    underlyingLiabilityAmount: 0,
    underlyingPreliminaryIndemnityAmount: 0,
  };

  const mpSubsidyParams: SubsidyParams = {
    subsidyPercent: mpCalcData?.subsidyPercent ?? 0,
    isBeginningOrVeteranFarmerOrRancher: false,
    isConservationCompliance: false,
    isNativeSod: false,
    conservComplSubsidyReductionPercent: 0,
  };

  // Order matters here for determining which params to use
  if (scenarioPiece.rowCropScenarioPieceExtendedData !== null
    && isNotNullOrUndefined(scenarioPiece.rowCropScenarioPieceExtendedData.unsubsidizedPremium)) {
    const liveQuoteMpParams: MpLiveQuotePremiumParams & MpIndemnityParams = {
      ...mpIndemnityParams,
      ...mpSubsidyParams,
      mpDiscountPerAcre: scenarioPiece.rowCropScenarioPieceExtendedData.discountAmount ?? 0,
      premiumPerAcre: scenarioPiece.rowCropScenarioPieceExtendedData.customPremium,
      unsubsidizedPremium: scenarioPiece.rowCropScenarioPieceExtendedData.unsubsidizedPremium,
    };
    return liveQuoteMpParams;
  }

  //Mp can have a manual premium or a calculated one, use the manual params if there is a persisted customPremium
  if (scenarioPiece.rowCropScenarioPieceExtendedData !== null
    && isNotNullOrUndefined(scenarioPiece.rowCropScenarioPieceExtendedData.customPremium)) {
    const manualMpParams: MpManualPremiumParams & MpIndemnityParams = {
      ...mpIndemnityParams,
      mpDiscountPerAcre: scenarioPiece.rowCropScenarioPieceExtendedData.discountAmount ?? 0,
      premiumPerAcre: scenarioPiece.rowCropScenarioPieceExtendedData.customPremium,
    };
    return manualMpParams;
  }

  const mpParams: MpPremiumParams & MpIndemnityParams = {
    ...mpIndemnityParams,
    ...mpSubsidyParams,
    mpDiscountPerAcre: scenarioPiece.rowCropScenarioPieceExtendedData?.discountAmount ?? 0,
    underlyingCredit: 0, // TODO #65271: Figure out how to populate this

    //These are set within the calcs and shouldn't be set here
    underlyingPremiumPerAcre: 0,
    underlyingTotalPremiumAmount: 0,
  };

  return mpParams;
};

const createMpUnits = (mpCalcData: MpCalculationParams[], units: BaseUnit[]): (MpUnit & Unit)[] => {

  return units.map(unit => {

    const matchingData = getMatchingCalcParamDataForUnit(unit, mpCalcData);

    //TODO: Error Handling
    if (isNullOrUndefined(matchingData)) throw new Error('MP Data Transform State mismatch: Unable to find data returned from API associated with unit in state.');
    const unitDtos = createBaseUnit(unit);
    const mpUnit: MpUnit & Unit = {
      ...unitDtos,
      baseRate: matchingData.baseRate,
    };

    return mpUnit;
  });
};

const createMpPlanDto = (scenarioPiece: RowCropScenarioPiece, apiData: MpCalculationParams[], unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): MpRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createMpUnits(
        apiData,
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
      ),
    ),
    ...transformMpData(apiData.at(0) ?? null, unitData.at(0) ?? null, scenario, scenarioPiece),
  };
};

const createCalcParamRequest = (state: RootState, scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, baseUnits: BaseUnit[]): MpCalculationParamsRequest => {
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  return {
    year: clientFile.year,
    coverageTypeCode: (scenarioPiece.rowCropScenarioPieceExtendedData?.isCat ?? false) ? CoverageTypeCode[CoverageTypeCode.C] : CoverageTypeCode[CoverageTypeCode.A],
    unitStructureCode: UnitStructureCode[scenarioPiece.unitStructure],
    planCode: scenarioPiece.planCode,
    upperCoverageLevel: scenarioPiece.upperCoverageLevel,
    countyId: quote.countyId,
    commodityCode: quote.commodityCode,
    distinctUnits: getCalcParamRequestUnits(baseUnits),
  };
};
