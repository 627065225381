import { Controller, useFormContext } from 'react-hook-form';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import { FormInputProps } from '../../scenario/formInputProps';
import { Nullable } from '../../../../types/util/Nullable';
import NumberTextField, { onNumericInputChange } from '../../../numberTextField/numberTextField.component';
import { validatePrice } from '../../../../pages/units/validations/priceValidation';
import { Info } from '../../../info/info';

export interface Props extends FormInputProps {
  priceCap: Nullable<number>;
}

export type VipMpCapFields = {
  priceCap: number;
}

export const VipMpCapInput = ({ priceCap, autoFocus, sx, disabled }: Props) => {
  const fieldName = ExtendedDataFormInputParameterNames.PriceChangeCap;
  const { control } = useFormContext();

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: (value: Nullable<number>) => validatePrice(value) }}
      defaultValue={priceCap ?? 1}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <NumberTextField
          {...field}
          type="number"
          label="Cap"
          fullWidth
          inputProps={{ step: 0.01, min: 0 }}
          InputProps={{
            endAdornment: <Info tooltipContent="Cap - The maximum price movement allowed." />,
          }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          sx={sx}
          onChange={e => onNumericInputChange(e, field, 2)}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          autoFocus={autoFocus}
          size="small"
          disabled={disabled}
        />
      )}
    />
  );
};
