import { Card, CardContent, Grid, Typography } from '@mui/material';
import CurrencyText from '../../../components/currencyText/currencyText.component';

type HailIndemnityColumnsProps = {
  width: number;
  indemnities: number[];
  netIndemnities: number[];
}

const HailTableIndemnityColumns = ({ width, indemnities, netIndemnities }: HailIndemnityColumnsProps) => {
  return (
    <Card sx={{ width: width, height: 'fit-content', mt: 1 }} variant="elevation" elevation={3} raised>
      <CardContent sx={{ pb: '5px !important' }}>
        <Grid container >
          <Grid item xs={6} >
            <Typography fontWeight="medium" textAlign="center">Indemnity</Typography>
          </Grid>
          <Grid item xs={6} >
            <Typography fontWeight="medium" textAlign="center">Net Indemnity</Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" rowGap={1} >
          {indemnities.map((indemnity, index) => {
            const netIndemnity = netIndemnities[index]; // We check if the arrays are the same legth in the table view
            return (
              <Grid item container key={index} sx={{ p: '0px !important' }} >
                <Grid item xs={6}>
                  <Typography textAlign="center">{<CurrencyText currencyAmount={indemnity} colorize={indemnity < 0} align="center" />}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography textAlign="center">{<CurrencyText currencyAmount={netIndemnity} colorize={netIndemnity < 0} align="center" />}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HailTableIndemnityColumns;