import { createSelector, createSlice } from '@reduxjs/toolkit';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { ClientFileId, ScenarioId } from '../types/api/PrimaryKeys';
import { RootState } from './store';
import { getKeyedStateGroupedBy } from './sliceHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAppTaskStatusesForClientFile } from '../services/appTasks.service';
import { AppTaskStatusResult } from '../types/api/appTaskStatusResult';

interface AppTaskStatusState {
  allAppTaskStatuses: SliceDataState<ScenarioId, AppTaskStatusResult>;
}

const initialState: AppTaskStatusState = {
  allAppTaskStatuses: initialSliceDataState(),
};

export const AppTaskStatusSlice = createSlice({
  name: 'appTaskStatus',
  initialState: initialState,
  reducers: {
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allAppTaskStatuses, s => s.scenarioId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchAppTaskStatusesForClientFile,
      affectedIds: () => [],
    });
  },
});

export default AppTaskStatusSlice.reducer;

const selectAllAppTaskStatuses = (state: RootState) => state.appTaskStatuses.allAppTaskStatuses.data;

export const selectAppTaskStatusesByClientFileId = createSelector([selectAllAppTaskStatuses], result => {
  const map = getKeyedStateGroupedBy(result, x => x.clientFileId);
  return map;
});

export const fetchAppTaskStatusesForClientFile = createAppAsyncThunk('appTasks/fetchAppTaskStatus', async (clientFileId: ClientFileId) => {
  const statuses = (await getAppTaskStatusesForClientFile(clientFileId)).data;
  return statuses;
});