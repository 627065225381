import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { SliceDataState, getAsyncHandlerBuilder, initialSliceDataState } from './sliceStateHelpers';
import { AgentTeamId } from '../types/api/PrimaryKeys';
import { AgentTeam } from '../types/api/agentTeam';
import { RootState } from './store';
import { getKeyedStateValues } from './sliceHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAgentTeamsForCurrentUser } from '../services/agent.service';
import { Nullable } from '../types/util/Nullable';

interface AgentTeamState {
  allAgentTeams: SliceDataState<AgentTeamId, AgentTeam>;
  currentAgentTeamId: Nullable<AgentTeamId>;
}

const initialState: AgentTeamState = {
  allAgentTeams: initialSliceDataState(),
  currentAgentTeamId: null,
};

export const agentTeamSlice = createSlice({
  name: 'agentTeams',
  initialState: initialState,
  reducers: {
    setCurrentAgentTeamId(state: AgentTeamState, action: PayloadAction<Nullable<AgentTeamId>>) {
      state.currentAgentTeamId = action.payload;
    },
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allAgentTeams, s => s.agentTeamId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchAgentTeamsForCurrentUser,
      affectedIds: () => [],
    });
  },
});

export default agentTeamSlice.reducer;

// Non-memoized selectors
export const selectCurrentAgentTeam = (state: RootState): Nullable<AgentTeam> => state.agentTeams.currentAgentTeamId === null ? null : state.agentTeams.allAgentTeams.data[state.agentTeams.currentAgentTeamId] ?? null;

// Memoized Selectors
const selectAllAgentTeamsDictionary = (state: RootState) => state.agentTeams.allAgentTeams.data;
export const selectAgentTeams = createSelector([selectAllAgentTeamsDictionary], result => getKeyedStateValues(result));

export const { setCurrentAgentTeamId } = agentTeamSlice.actions;

export const fetchAgentTeamsForCurrentUser = createAppAsyncThunk('agentTeams/fetchAgentTeamsForCurrentUser', async () => await getAgentTeamsForCurrentUser());
