import { useFormContext, useWatch } from 'react-hook-form';
import { useAppSelector } from '../../../hooks/reduxHooks';
import {
  selectOfferProjectedPrice
} from '../../../app/admSlice';
import { useEffect } from 'react';
import { isNullOrUndefined, isUndefined } from '../../../utils/nullHandling';
import { isApiDataStale, isSavedDataBeingLoaded } from '../../../utils/formInputUtils';
import ProjectedPriceInput, { ProjectedPriceProps } from './projectedPriceInput.component';
import { useCommodityOptionBasedPrecisionForScenario } from '../../../hooks/useCommodityOptionBasedPrecision';
import { getRoundedValue } from '../../numberTextField/numberTextField.component';


const ScenarioProjectedPriceInput = ({ quote, scenario, scenarioOptionState, projectedPrice, sx, autoFocus = false }: ProjectedPriceProps) => {
  const { control, setValue, getValues, trigger } = useFormContext();
  const projPrice = useAppSelector(selectOfferProjectedPrice);
  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });
  const { precision } = useCommodityOptionBasedPrecisionForScenario(quote.commodityCode, scenarioOptionState);

  useEffect(() => {
    const currentPractice = getValues('practiceId');

    const isThereStaleData = isApiDataStale(currentPractice, projPrice);
    const isLoadingSavedData = isSavedDataBeingLoaded(projectedPrice, practice);

    if (isThereStaleData || isLoadingSavedData || isNullOrUndefined(projPrice)) return;

    setValue('projectedPrice', getRoundedValue(projPrice, precision));
    trigger('projectedPrice');
  }, [projPrice]);

  useEffect(() => {
    //When the practice is changed, if it is not undefined, blank out the projected price's value
    if (isUndefined(practice) || practice === '') return;

    setValue('projectedPrice', 0);
  }, [practice]);

  return (
    <ProjectedPriceInput
      quote={quote}
      scenario={scenario}
      scenarioOptionState={scenarioOptionState}
      projectedPrice={projectedPrice}
      sx={sx}
      autoFocus={autoFocus}
      disabled={false}
    />
  );
};

export default ScenarioProjectedPriceInput;
