import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { FormInputProps } from '../scenario/formInputProps';
import { OptionLevelCodes } from '../../../constants/optionLevelCodes';
import { OptionCode } from '@silveus/calculations';
import { OptionByEuType, UnsetOptionByEu } from '../../../types/app/OptionByEuType';
import OptionState from '../../../types/app/OptionState';
import { OptionSelectionState } from '../../../types/app/enums/optionSelectionState.enum';
interface OptionsByEnterpriseUnitProps extends FormInputProps {
  scenarioOptionsForSpForm: OptionState[];
  updateEuByOptionSelection: (byEuSelection: OptionByEuType) => void;
}

export const OptionsByEnterpriseUnit = ({ scenarioOptionsForSpForm, updateEuByOptionSelection }: OptionsByEnterpriseUnitProps) => {
  const enterpriseScenarioOptions = scenarioOptionsForSpForm.filter(o => o.option.optionLevelCode === OptionLevelCodes.EnterpriseUnit);
  const enterpriseValue = enterpriseScenarioOptions.find(o => o.selectionState === OptionSelectionState.On)?.option.optionCode ?? UnsetOptionByEu;
  const validOptionCodes = new Set(Object.values(OptionCode));

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as OptionByEuType;
    if (value === UnsetOptionByEu || validOptionCodes.has(value)) {
      updateEuByOptionSelection(value);
    }
  };

  return (
    <Tooltip placement={'left'} title={enterpriseValue !== UnsetOptionByEu ? enterpriseValue : ''} enterDelay={500}>
      <FormControl fullWidth size="small">
        <InputLabel id="eu-by-label">EU By</InputLabel>
        <Select
          labelId="eu-by-label"
          id="eu-by-select"
          value={enterpriseValue}
          label="EU By"
          onChange={handleChange}
        >
          <MenuItem value="-">-</MenuItem>
          {enterpriseScenarioOptions.map(o => (<MenuItem key={o.option.optionCode} value={o.option.optionCode}>{o.option.optionName}</MenuItem>))}
        </Select>
      </FormControl>

    </Tooltip>
  );
};