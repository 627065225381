import { useMemo } from 'react';
import { IEnumAttributes } from '../../types/api/enums/IEnumAttributes';
import { ScenarioPieceType } from '@silveus/calculations';
import MatrixPreset from '../../types/api/MatrixPreset';
import { isNullOrUndefined } from '../../utils/nullHandling';
import { getFlattenedScenarioPieceTypes } from '../../utils/scenarioPieceUtils';

export function useMatrixOverlayChildren(includedScenarioPieces: Set<IEnumAttributes<ScenarioPieceType>>, selectedMatrixPreset: MatrixPreset | undefined) {
  return useMemo(() => createMatrixOverlayChildren(includedScenarioPieces, selectedMatrixPreset), [includedScenarioPieces, selectedMatrixPreset]);
}

export function createMatrixOverlayChildren(includedScenarioPieces: Set<IEnumAttributes<ScenarioPieceType>>, selectedMatrixPreset: MatrixPreset | undefined) {
  const scenarioPieceTypesFromPreset = isNullOrUndefined(selectedMatrixPreset)
    ? undefined
    : getFlattenedScenarioPieceTypes(selectedMatrixPreset.selectedScenarioPieceTypes);

  return [...includedScenarioPieces].map(sp => ({
    overlayChildId: sp.value.toString(),
    isEnabled: scenarioPieceTypesFromPreset?.includes(sp.value) ?? true,
  }));
}