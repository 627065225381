import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material';
import { getFriendlyScenarioPieceName } from '../../../../utils/scenarioPieceUtils';
import { getItemsForId } from '../../../../utils/mapHelpers';
import { ScenarioId } from '../../../../types/api/PrimaryKeys';
import { ScenarioPiece } from '../../../../types/api/ScenarioPiece';
import { Controller, useFormContext } from 'react-hook-form';
import { AvailabilityService, distinct, ScenarioPieceGroupType, ScenarioPieceGroupTypeAttributes, ScenarioPieceType } from '@silveus/calculations';
import { isNotNullOrUndefined } from '../../../../utils/nullHandling';

interface OptionalProductsInputProps {
  includedScenarios: ScenarioId[];
  scenarioPieces: Map<ScenarioId, ScenarioPiece[]>;
}

export const OptionalProductsInput = ({ includedScenarios, scenarioPieces }: OptionalProductsInputProps) => {
  const { control } = useFormContext();

  const scenarioPieceTypesForAllScenarios = distinct(includedScenarios.flatMap(scenario => {
    const selectedScenarioPieceTypes: (ScenarioPieceType | ScenarioPieceGroupType)[] = [];
    const scenarioPiecesForScenario = getItemsForId(scenarioPieces, scenario);
    for (const scenPiece of scenarioPiecesForScenario) {
      const spGroup = Object.values(ScenarioPieceGroupTypeAttributes).find(spg => spg.elements?.includes(scenPiece.scenarioPieceType));
      if (isNotNullOrUndefined(spGroup) && !spGroup.useIndividualPieceName) {
        selectedScenarioPieceTypes.push(spGroup.value);
      } else {
        selectedScenarioPieceTypes.push(scenPiece.scenarioPieceType);
      }
    }
    return selectedScenarioPieceTypes;
  }));
  const sortedScenarioPieces = AvailabilityService.sortScenarioPiecesArray(scenarioPieceTypesForAllScenarios);

  const shouldShrink = (value: ScenarioPieceType[]) => {
    return sortedScenarioPieces.length > 0 && value.length !== sortedScenarioPieces.length;
  };

  return (
    <Controller
      name="excludedScenarioPieces"
      control={control}
      rules={{}}
      defaultValue={[]}
      render={({
        field: { ref, value, ...field }, fieldState: { error },
      }) => (
        <FormControl error={!!error} fullWidth>
          <InputLabel id="optional-products-input-label" shrink={shouldShrink(value)}>Optional products</InputLabel>
          <Select
            {...field}
            inputRef={ref}
            value={value}
            multiple
            displayEmpty={true}
            labelId="optional-products-input-label"
            label="Optional products"
            notched={shouldShrink(value)}
            onChange={e => field.onChange(e.target.value)}
            renderValue={(selectedScenarioPieceTypes: (ScenarioPieceType | ScenarioPieceGroupType)[]) => {
              const scenarioPiecesToDisplay = sortedScenarioPieces.filter(spt => !selectedScenarioPieceTypes.includes(spt));
              return scenarioPiecesToDisplay.map(spt => {
                if (spt in ScenarioPieceGroupTypeAttributes) {
                  const groupType = spt as ScenarioPieceGroupType;
                  const attributes = ScenarioPieceGroupTypeAttributes[groupType];
                  return attributes.description;
                } else {
                  return getFriendlyScenarioPieceName(spt as ScenarioPieceType);
                }
              }).join(', ');
            }}
          >
            {sortedScenarioPieces.map(scenarioPieceType => {
              let scenarioPieceName = '';
              if (scenarioPieceType in ScenarioPieceGroupTypeAttributes) {
                const groupType = scenarioPieceType as ScenarioPieceGroupType;
                const attributes = ScenarioPieceGroupTypeAttributes[groupType];
                scenarioPieceName = attributes.description;
              } else {
                scenarioPieceName = getFriendlyScenarioPieceName(scenarioPieceType as ScenarioPieceType);
              }
              return (
                <MenuItem key={scenarioPieceType} value={scenarioPieceType}>
                  <Checkbox checked={!(value as (ScenarioPieceType | ScenarioPieceGroupType)[]).includes(scenarioPieceType)} />
                  <ListItemText primary={scenarioPieceName} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};