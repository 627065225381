import { Grid } from '@mui/material';
import { Nullable } from '../../../types/util/Nullable';
import { ExpectedCountyYieldFields } from './expectedCountyYieldInput';
import { ActualCountyYieldFields } from './actualCountyYieldInput';
import PercentChangeSliderInput from '../percentChangeSliderInput.component';
import ScenarioPieceExpectedCountyYieldInput from '../scenarioPiece/scenarioPieceExpectedCountyYieldInput';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import ScenarioPieceActualCountyYieldInput from '../scenarioPiece/scenarioPieceActualCountyYieldInput';
import { LoadFromStateProps } from './loadFromStateProps';
import { FormInputProps } from './formInputProps';
import { CoverageTypeCode } from '@silveus/calculations';
import useLinkedScenarioFields from '../../../hooks/useLinkedScenarioFields';
import { ScenarioId } from '../../../types/api/PrimaryKeys';

interface Props extends LoadFromStateProps, FormInputProps {
  year: number;
  countyId: string;
  typeId: Nullable<string>;
  practiceId: Nullable<string>;
  scenarioId: Nullable<ScenarioId>;
  scenarioPiece: Nullable<RowCropScenarioPiece>;
  commodityCode: string;
  expectedCountyYield: Nullable<number>;
  actualCountyYield: Nullable<number>;
  expectedCountyYieldLabel?: string;
  actualCountyYieldLabel?: string;
  insurancePlanCodes: string[];
  coverageTypeCode?: CoverageTypeCode;
  coverageLevel: Nullable<number>;
  volatility: number;
}

export type CountyYieldInputGroupFields = ExpectedCountyYieldFields & ActualCountyYieldFields;

const CountyYieldInputGroup = ({ year, countyId, typeId, practiceId, scenarioPiece, scenarioId, expectedCountyYield, actualCountyYield, coverageTypeCode = CoverageTypeCode.A,
  volatility, coverageLevel,
  commodityCode, insurancePlanCodes, expectedCountyYieldLabel = 'Expected Cty Yld',
  actualCountyYieldLabel = 'Actual Cty Yld', disabled = false,
  loadFromState = false }: Props) => {

  const { getInputStyle } = useLinkedScenarioFields(scenarioId);

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={4}>
        <ScenarioPieceExpectedCountyYieldInput
          typeId={typeId}
          practiceId={practiceId}
          scenarioPiece={scenarioPiece}
          label={expectedCountyYieldLabel}
          expectedCountyYield={expectedCountyYield}
          loadFromState={loadFromState}
          commodityCode={commodityCode}
          insurancePlanCodes={insurancePlanCodes}
          disabled={disabled}
          coverageLevel={coverageLevel}
          volatility={volatility}
          sx={getInputStyle('expectedCountyYield')}
        />
      </Grid>
      <Grid item xs={4}>
        <ScenarioPieceActualCountyYieldInput
          year={year}
          countyId={countyId}
          typeId={typeId}
          practiceId={practiceId}
          scenarioPiece={scenarioPiece}
          label={actualCountyYieldLabel}
          volatility={volatility}
          coverageLevel={coverageLevel}
          actualCountyYield={actualCountyYield}
          loadFromState={loadFromState}
          commodityCode={commodityCode}
          coverageTypeCode={coverageTypeCode}
          insurancePlanCodes={insurancePlanCodes}
          sx={getInputStyle('actualCountyYield')} />
      </Grid>
      <Grid item xs={4}>
        <PercentChangeSliderInput
          estimatedValueFieldName={'expectedCountyYield'}
          actualValueFieldName={'actualCountyYield'}
          sliderName={'countyYieldSlider'}
          initialEstimatedValue={expectedCountyYield}
          initialActualValue={actualCountyYield}
        />
      </Grid>
    </Grid>
  );
};


export default CountyYieldInputGroup;
