import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getAIPs } from '../services/appTasks.service';
import { AIP } from '../types/api/aip';
import { AIPId } from '../types/api/PrimaryKeys';
import { getKeyedStateValues } from './sliceHelpers';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import { RootState } from './store';
import { createAppAsyncThunk } from './thunkHelpers';


interface AIPState {
  allAIPs: SliceDataState<AIPId, AIP>;
}

const initialState: AIPState = {
  allAIPs: initialSliceDataState(),
};

export const AIPSlice = createSlice({
  name: 'aips',
  initialState: initialState,
  reducers: {
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allAIPs, s => s.aipId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchAIPs,
      affectedIds: () => [],
    });
  },
});

export default AIPSlice.reducer;

const selectAllAIPDictionary = (state: RootState) => state.aips.allAIPs.data;

export const selectAllAIPs = createSelector([selectAllAIPDictionary], result => {
  const aips = getKeyedStateValues(result);
  return aips;
});

export const fetchAIPs = createAppAsyncThunk('aips/fetchAIPs', async () => {
  const aips = (await getAIPs()).data;
  return aips;
});
