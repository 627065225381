import { Box, Grid, Typography } from '@mui/material';
import { useAppSelector, useKeyMapSelector } from '../../../hooks/reduxHooks';
import { selectSelectedPremiumBreakdownData } from '../../../app/premiumBreakdownSlice';
import CurrencyText from '../../../components/currencyText/currencyText.component';
import PremiumBreakdown from '../../../types/api/premiumBreakdown';
import { selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder } from '../../../app/scenarioPiecesSlice';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { selectPremiumBreakdownOptions } from '../../../app/userSettingsSlice';

type PremiumBreakdownProductDetailsProps = {
  premiumBreakdown: PremiumBreakdown;
}

const PremiumBreakdownProductDetails = ({ premiumBreakdown }: PremiumBreakdownProductDetailsProps) => {
  const scenarioPieces = useKeyMapSelector(selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder, premiumBreakdown.primaryScenarioId);
  const premiumBreakdownOptions = useAppSelector(selectPremiumBreakdownOptions);
  const selectedPremiumBreakdownData = useAppSelector(state => selectSelectedPremiumBreakdownData(state));
  const isSelectedItemPartOfScenario = !isNullOrUndefined(scenarioPieces.find(x => x.scenarioPieceId === selectedPremiumBreakdownData?.id));

  return (
    <Grid container sx={{ p: 1, maxWidth: '250px' }}>
      {selectedPremiumBreakdownData && isSelectedItemPartOfScenario && (
        <>
          <Grid item xs={12} p={1} textAlign="center">
            <Typography variant="subtitle2">Product Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ border: `5px solid ${selectedPremiumBreakdownData.fill}`, borderRadius: 1, p: 1 }}>
              <Grid container flexWrap="wrap">
                <Grid item xs={12} textAlign="center">
                  <Typography variant="subtitle2">{selectedPremiumBreakdownData.productName}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ pb: 1 }} textAlign="center">
                  <Typography variant="subtitle2">{selectedPremiumBreakdownData.lowerCoverageLevel}% - {selectedPremiumBreakdownData.upperCoverageLevel}%</Typography>
                </Grid>
                {premiumBreakdownOptions.details.coveragePerAcre && (
                  <>
                    <Grid item xs={6} textAlign="left">
                      <Typography variant="label">Coverage $/Acre</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <CurrencyText currencyAmount={selectedPremiumBreakdownData.coverage ?? 0} colorize={false} />
                    </Grid>
                  </>
                )}
                {premiumBreakdownOptions.details.premiumPerAcre && (
                  <>
                    <Grid item xs={6} textAlign="left">
                      <Typography variant="label">Premium/Acre</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <CurrencyText currencyAmount={selectedPremiumBreakdownData.premium ?? 0} colorize={false} />
                    </Grid>
                  </>
                )}
                {premiumBreakdownOptions.details.totalPremium && (
                  <>
                    <Grid item xs={6} textAlign="left">
                      <Typography variant="label">Total Premium</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <CurrencyText currencyAmount={selectedPremiumBreakdownData.totalPremium ?? 0} colorize={false} />
                    </Grid>
                  </>
                )}
                {premiumBreakdownOptions.details.subsidyPercent && (
                  <>
                    <Grid item xs={6} textAlign="left">
                      <Typography variant="label">Subsidy %</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <Typography variant="value">{selectedPremiumBreakdownData.subsidyPercent}%</Typography>
                    </Grid>
                  </>
                )}
                {premiumBreakdownOptions.details.subsidyPerAcre && (
                  <>
                    <Grid item xs={6} textAlign="left">
                      <Typography variant="label">Subsidy $/Acre</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <CurrencyText currencyAmount={selectedPremiumBreakdownData.subsidy ?? 0} colorize={false} />
                    </Grid>
                  </>
                )}
                {premiumBreakdownOptions.details.totalSubsidy && (
                  <>
                    <Grid item xs={6} textAlign="left">
                      <Typography variant="label">Total Subsidy</Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <CurrencyText currencyAmount={selectedPremiumBreakdownData.totalSubsidy ?? 0} colorize={false} />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PremiumBreakdownProductDetails;