import { Tooltip, Typography } from '@mui/material';
import { formatProductionValue } from './harvestRevenueUtils';
import { AdjustmentType, AdjustmentTypeAttributes } from '@silveus/calculations';

interface HarvestRevenueTransactionDisplayProductionProps {
  adjustmentType: AdjustmentType;
  value: number;
  productionCalculated: number;
}

const HarvestRevenueTransactionDisplayProduction = ({
  adjustmentType,
  value,
  productionCalculated,
}: HarvestRevenueTransactionDisplayProductionProps) => {
  const text = `${AdjustmentTypeAttributes[adjustmentType].description} | ${formatProductionValue(adjustmentType, value, productionCalculated)}`;

  return (
    <Tooltip title={text} placement="top-start">
      <Typography noWrap>{text}</Typography>
    </Tooltip>
  );
};

export default HarvestRevenueTransactionDisplayProduction;