import { useAppDispatch, useAppSelector } from './reduxHooks';
import { closeDrawer, selectShouldCloseOnSave } from '../app/appDrawerSlice';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { beginLoading, endLoading } from '../app/loaderSlice';

function useDrawerForm<T extends FieldValues>(methods: UseFormReturn<T>, onSubmit: SubmitHandler<T>, shouldSubmit: boolean, handleCancel?: () => void) {
  const dispatch = useAppDispatch();
  const shouldCloseOnSave = useAppSelector(selectShouldCloseOnSave);

  const submitWrapper: SubmitHandler<T> = async data => {
    if (!shouldSubmit) {
      if (shouldCloseOnSave) {
        handleCancel && handleCancel();
        dispatch(closeDrawer());
      }

      return;
    }

    dispatch(beginLoading());

    await onSubmit(data);

    dispatch(endLoading());

    // Reset to the current data so isDirty works properly, otherwise the user can keep resubmitting the form after the first submit.
    methods.reset(data, { keepValues: true });

    if (shouldCloseOnSave) {
      handleCancel && handleCancel();
      dispatch(closeDrawer());
    }
  };

  const cancelWrapper = () => {
    handleCancel && handleCancel();

    dispatch(closeDrawer());
  };

  return { onFormSubmit: submitWrapper, onFormCancel: cancelWrapper };
}

export default useDrawerForm;