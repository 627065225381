import { ApiDate } from '../apiDate';

export enum PriceDiscoveryStatus {
  YetToStart = 'Yet To Start',
  Released = 'Released',
  InDiscovery = 'In Discovery',
}

interface RMAPriceDiscovery {
  compositeKey: string;
  salesClosingDateDisplay: ApiDate;
  commodityYear: number;
  commodityCode: string;
  commodityName: string;
  ceppTypeCode: number;
  ceppTypeName: string;
  ceppPracticeCode: number;
  ceppPracticeName: string;
  stateCode: string;
  stateName: string;
  priceMultiplicativeFactor: number;
  priceAdditiveFactor: number;
  projectedPriceExchangeCode: string;
  projectedPriceMarketSymbolCode: string;
  projectedPriceBeginDate: ApiDate;
  projectedPriceEndDate: ApiDate;
  projectedPrice: number;
  approvedPriceVolatilityPercent: number;
  harvestPriceExchangeCode: string;
  harvestPriceMarketSymbolCode: string;
  harvestPriceBeginDate: ApiDate;
  harvestPriceEndDate: ApiDate;
  harvestPrice: number;
  harvestPriceStatus: PriceDiscoveryStatus;
  projectedSymbolName: string;
  projectedMonthName: string;
  harvestMonthName: string;
  projectedPriceStatus: PriceDiscoveryStatus;
  insurancePlanGroupCode: string;
  lastUpdated: ApiDate;
}

export default RMAPriceDiscovery;
