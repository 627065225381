import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormWrapperProps } from '../../../components/formWrapper/formWrapper.component';
import { Grid, Typography } from '@mui/material';
import { DrawerFormType } from '../../../app/appDrawerSlice';
import useDrawerForm from '../../../hooks/useDrawerForm';
import useFormWrapper from '../../../hooks/useFormWrapper';
import PremiumBreakdownOptionInput from '../../../components/formInputs/premiumBreakdown/premiumBreakdownOptionInput';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { modifyPremiumBreakdownOptionsForUser, selectPremiumBreakdownOptions } from '../../../app/userSettingsSlice';

export interface PremiumBreakdownFormProps extends FormWrapperProps {
}

type PremiumBreakdownFormFields = {
  summary: {
    premium: boolean;
    coverage: boolean;
    subsidy: boolean;
    subsidyPercent: boolean;
  },
  details: {
    coveragePerAcre: boolean;
    premiumPerAcre: boolean;
    totalPremium: boolean;
    subsidyPercent: boolean;
    subsidyPerAcre: boolean;
    totalSubsidy: boolean;
  },
  includeHPO: boolean;
};

const premiumBreakdownFormId: DrawerFormType = 'premiumBreakdownForm';

const PremiumBreakdownForm = ({ handleValidation, registerHeader, isCanceling = false }: PremiumBreakdownFormProps) => {
  const dispatch = useAppDispatch();
  const premiumBreakdownOptions = useAppSelector(selectPremiumBreakdownOptions);
  const methods = useForm<PremiumBreakdownFormFields>();

  const onSubmit: SubmitHandler<PremiumBreakdownFormFields> = data => {
    dispatch(modifyPremiumBreakdownOptionsForUser({ options: { ...data } }));
  };

  const handleReset = () => {
    methods.reset();
  };

  const shouldSubmit = methods.formState.isDirty;

  const { onFormSubmit, onFormCancel } = useDrawerForm(methods, onSubmit, shouldSubmit);
  const handleSubmit = useFormWrapper('Premium Breakdown', methods, premiumBreakdownFormId, onFormSubmit, onFormCancel, isCanceling, registerHeader, handleValidation);

  return (
    <FormProvider {...methods}>
      <form id={premiumBreakdownFormId} onSubmit={handleSubmit} onReset={handleReset}>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Typography variant="body2">Premium Breakdown Edits</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="body2">Summary</Typography>
            </Grid>
            <Grid item xs={6}>
              <PremiumBreakdownOptionInput name="summary.premium" label="Premium" checked={premiumBreakdownOptions.summary.premium} />
            </Grid>
            <Grid item xs={6}>
              <PremiumBreakdownOptionInput name="summary.coverage" label="Coverage $" checked={premiumBreakdownOptions.summary.coverage} />
            </Grid>
            <Grid item xs={6}>
              <PremiumBreakdownOptionInput name="summary.subsidy" label="Subsidy $" checked={premiumBreakdownOptions.summary.subsidy} />
            </Grid>
            <Grid item xs={6}>
              <PremiumBreakdownOptionInput name="summary.subsidyPercent" label="Subsidy %" checked={premiumBreakdownOptions.summary.subsidyPercent} />
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="body2">Detail</Typography>
            </Grid>
            <Grid item xs={6}>
              <PremiumBreakdownOptionInput name="details.coveragePerAcre" label="Coverage $/Acre" checked={premiumBreakdownOptions.details.coveragePerAcre} />
            </Grid>
            <Grid item xs={6}>
              <PremiumBreakdownOptionInput name="details.premiumPerAcre" label="Prem/Acre" checked={premiumBreakdownOptions.details.premiumPerAcre} />
            </Grid>
            <Grid item xs={6}>
              <PremiumBreakdownOptionInput name="details.totalPremium" label="Total Premium" checked={premiumBreakdownOptions.details.totalPremium} />
            </Grid>
            <Grid item xs={6}>
              <PremiumBreakdownOptionInput name="details.subsidyPercent" label="Subsidy %" checked={premiumBreakdownOptions.details.subsidyPercent} />
            </Grid>
            <Grid item xs={6}>
              <PremiumBreakdownOptionInput name="details.subsidyPerAcre" label="Subsidy $/Acre" checked={premiumBreakdownOptions.details.subsidyPerAcre} />
            </Grid>
            <Grid item xs={6}>
              <PremiumBreakdownOptionInput name="details.totalSubsidy" label="Total Subsidy" checked={premiumBreakdownOptions.details.totalSubsidy} />
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <PremiumBreakdownOptionInput name="includeHPO" label="Include HPO?" checked={premiumBreakdownOptions.includeHPO} />
          </Grid>
        </Grid>
      </form>
    </FormProvider >
  );
};

export default PremiumBreakdownForm;