import { MenuItem, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import VALIDATION_RULES from '../../../../constants/formValidationRules';
import IceRate from '../../../../types/api/adm/IceRate';
import { ScenarioPieceType, UnitStructureCode } from '@silveus/calculations';
import { IceDeductibleSelection } from '../../../../types/api/adm/iceDeductibleSelection';
import { Nullable } from '../../../../types/util/Nullable';

interface IceDeductibleSelectionInputProps {
  iceProductsAvailable: IceRate[],
  iceProductSelected: ScenarioPieceType,
  unitStructureSelected: UnitStructureCode,
  iceDeductibleSelections: IceDeductibleSelection[],
  selectedIceDeductibleSelection: Nullable<number>,
  disabled?: boolean;
}

const IceDeductibleSelectionInput = ({ iceDeductibleSelections, iceProductsAvailable, iceProductSelected, unitStructureSelected, selectedIceDeductibleSelection,
  disabled = false }: IceDeductibleSelectionInputProps) => {

  const iceAllowedDeductibleSelectionIds = [...new Set(iceProductsAvailable
    .filter(iceRate => iceRate.iceScenarioPieceType === iceProductSelected)
    .filter(iceRate => iceRate.unitStructure === unitStructureSelected)
    .map(iceRate => iceRate.iceDeductibleSelectionID))];

  const availableDeductibleSelections = iceDeductibleSelections
    .filter(deductibleSelection => iceAllowedDeductibleSelectionIds.includes(deductibleSelection.iceDeductibleSelectionID));

  return (
    <Controller
      name="rowCropScenarioPieceExtendedData.iceDeductibleSelection"
      rules={{
        required: VALIDATION_RULES.required(),
        validate: VALIDATION_RULES.validateMultipleRules(
          [
            VALIDATION_RULES.restrictNull(),
            VALIDATION_RULES.restrictNotOnListValueNullable(availableDeductibleSelections.map(x => x.iceDeductibleSelectionID)),
          ],
        ),
      }}
      defaultValue={selectedIceDeductibleSelection ?? null}
      render={({
        field: { ref, ...field }, fieldState: { error },
      }) => (
        <TextField
          {...field}
          type="text"
          label="ICE Deductible"
          fullWidth
          select
          size="small"
          error={!!error}
          disabled={disabled}
          helperText={error?.message}
          inputRef={ref}
          onChange={e => field.onChange(e.target.value)}
        >
          {availableDeductibleSelections.map(ids =>
            <MenuItem key={ids.iceDeductibleSelectionID} value={ids.iceDeductibleSelectionID}>{ids.iceDeductibleSelectionDescription}</MenuItem>)}
        </TextField>
      )}
    />
  );
};

export default IceDeductibleSelectionInput;