import { createTheme } from '@mui/material';

export const drawerHeaderContainer = {
  pl: theme => theme.spacing(1),
  pr: theme => theme.spacing(1),
  pt: theme => theme.spacing(1),
  pb: theme => theme.spacing(1),
  boxShadow: theme => theme.shadows[3],
  color: theme => theme.palette.common.white,
  height: '48px',
  display: 'flex',
  alignItems: 'center',
};

export const getDrawerHeaderTheme = theme => createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: theme.palette.primary.main,
          backgroundColor: theme => `${theme.palette.common.white} !important`,
          ':hover': {
            backgroundColor: theme =>  theme.palette.primary.light,
            color: theme => theme.palette.common.white,
          },
        },
        outlinedPrimary: {
          color: theme.palette.common.white,
          borderColor: theme.palette.common.white,
        },
        outlinedSecondary: {
          color: 'white',
          borderColor: theme =>  theme.palette.common.white,
          ':hover': {
            borderColor: theme =>  theme.palette.common.white,
            backgroundColor: theme => theme.palette.primary.light,
          },
        },
      },
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            height: '3px',
            backgroundColor: theme =>  theme.palette.common.white,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: theme => theme.palette.common.white,
          '&.Mui-selected': {
            color: theme =>  theme.palette.common.white,
          },
        },
      },
    },
  },
});