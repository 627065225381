import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { isNullOrUndefined, isUndefined } from '../../../utils/nullHandling';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { selectOfferHarvestPrice, selectOfferProjectedPrice } from '../../../app/admSlice';
import { isApiDataStale, isSavedDataBeingLoaded } from '../../../utils/formInputUtils';
import HarvestPriceInput, { HarvestPriceProps } from './harvestPriceInput.component';
import { useCommodityOptionBasedPrecisionForScenario } from '../../../hooks/useCommodityOptionBasedPrecision';
import { getRoundedValue } from '../../numberTextField/numberTextField.component';

const ScenarioHarvestPriceInput = ({ quote, scenario, scenarioOptionState, harvestPrice, autoFocus = false, sx, projectedPriceField, label, disabled }: HarvestPriceProps) => {
  const { control, setValue, getValues } = useFormContext();
  const harvPrice = useAppSelector(selectOfferHarvestPrice);
  const projPrice = useAppSelector(selectOfferProjectedPrice);

  const practice = useWatch({ name: 'practiceId', control: control, defaultValue: undefined });
  const { precision } = useCommodityOptionBasedPrecisionForScenario(quote.commodityCode, scenarioOptionState);

  useEffect(() => {
    const currentPractice = getValues('practiceId');

    const isThereStaleData = isApiDataStale(currentPractice, harvPrice) || isApiDataStale(currentPractice, projPrice);
    const isLoadingSavedData = isSavedDataBeingLoaded(harvestPrice, practice);

    if (isThereStaleData || isLoadingSavedData || (isNullOrUndefined(projPrice) && isNullOrUndefined(harvPrice))) return;

    setValue('harvestPrice', getRoundedValue(harvPrice, precision));
  }, [harvPrice, projPrice]);

  useEffect(() => {
    //When the practice is changed, clear out the current value
    if (isUndefined(practice)) return;

    setValue('harvestPrice', 0);
  }, [practice]);


  return (
    <HarvestPriceInput
      quote={quote}
      scenario={scenario}
      scenarioOptionState={scenarioOptionState}
      harvestPrice={harvestPrice}
      autoFocus={autoFocus}
      sx={sx}
      projectedPriceField={projectedPriceField}
      label={label}
      disabled={disabled}
    />
  );
};

export default ScenarioHarvestPriceInput;
