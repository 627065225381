import { BaseContactInfo } from '../../../types/api/reports/BaseContactInfo';
import { InsuredContact } from '../../../types/api/insureds/InsuredContact';

export const getContactInfoFromInsured = (insuredContact: InsuredContact | undefined): BaseContactInfo => {
  return {
    name: insuredContact?.entityName ?? null,
    firstAddressLine: insuredContact?.address?.addressLine1 ?? null,
    secondAddressLine: null,
    city: insuredContact?.address?.city ?? null,
    state: insuredContact?.address?.state ?? null,
    zip: insuredContact?.address?.postalCode ?? null,
    phoneNumber: insuredContact?.phone ?? null,
  };
};