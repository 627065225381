import {
  sum,
  Commodity,
  CoverageTypeCode,
  IceUnit,
  IcePremiumParams,
  IceIndemnityParams,
  IceRequestDTO,
  Unit,
  ProductOfferAvailabilities
} from '@silveus/calculations';
import { RootState } from '../../../app/store';
import { selectCalcUnitGroupsForScenarioPiece } from '../../../app/unitGroupsSlice';
import BaseUnit from '../../../types/api/BaseUnit';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { getCalcParamRequestUnits, getMatchingCalcParamDataForUnit, getUnitsForScenarioPiece } from '../calculationUtils';
import { IceCalculationParams } from '../../../types/api/calculationData/iceCalculationParams';
import { toPrimaryKey } from '../../../utils/primaryKeyHelpers';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import { isNullOrUndefined } from '../../../utils/nullHandling';
import { createBaseScenarioPieceDto, createBaseUnit } from '../baseDataTransformations';
import { UnitGroup } from '../../../types/api/UnitGroup';
import { getIceCalcDataRequest } from '../../requestInterception/scenarioPieces/iceRequestInterceptor';
import { IceCalculationParamsRequest } from '../../../types/api/calculationData/iceCalculationParamsRequest';
import { selectClientFileById } from '../../../app/clientFilesSlice';
import { selectQuoteById } from '../../../app/quotesSlice';
import { Nullable } from '../../../types/util/Nullable';
import { MissingClientFileInStateError, MissingQuoteInStateError } from '../../../errors/state/MissingStateErrors';

export const createIceData = async (scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, state: RootState, baseUnits: BaseUnit[], productOfferAvailabilities?: ProductOfferAvailabilities) => {
  //Get the calc data back from the API
  const calcParamRequest = createCalcParamRequest(state, scenarioPiece, scenario, baseUnits, productOfferAvailabilities);
  const returnedData = await getIceCalcDataRequest(calcParamRequest);

  const unitGroupsForScenarioPiece = selectCalcUnitGroupsForScenarioPiece(state, scenarioPiece.scenarioPieceId, scenario.scenarioId);
  const unitsForScenarioPiece = getUnitsForScenarioPiece(unitGroupsForScenarioPiece, baseUnits);

  //Create the DTO object specific to this scenario piece
  const scenarioPieceSpecificData = createIcePlanDto(scenarioPiece, returnedData, unitsForScenarioPiece, scenario, unitGroupsForScenarioPiece);
  return scenarioPieceSpecificData;
};

const createIcePlanDto = (scenarioPiece: RowCropScenarioPiece, iceData: IceCalculationParams[], unitData: BaseUnit[], scenario: RowCropScenario, unitGroups: UnitGroup[]): IceRequestDTO => {
  return {
    ...createBaseScenarioPieceDto(
      scenarioPiece,
      unitGroups,
      (unitGroup: UnitGroup) => createIceUnits(
        scenarioPiece,
        iceData,
        unitData.filter(data => unitGroup.unitYearIds.includes(toPrimaryKey<UnitYearId>(data.id))),
        scenario,
      ),
    ),
    ...transformIceData(unitData.at(0) ?? null, scenario, scenarioPiece),
  };
};

const createIceUnits = (scenarioPiece: RowCropScenarioPiece, iceCalcData: IceCalculationParams[], units: BaseUnit[], scenario: RowCropScenario): (IceUnit & Unit)[] => {
  const unitGroupAcres = sum(units, unit => unit.acres);

  return units.map(unit => {

    const matchingData = getMatchingCalcParamDataForUnit(unit, iceCalcData);

    //TODO: Error Handling
    if (isNullOrUndefined(matchingData)) throw new Error('ICE Data Transform State mismatch: Unable to find data returned from API associated with unit in state.');

    const unitDto = createBaseUnit(unit);
    const iceUnit: IceUnit & Unit = {
      ...unitDto,
      id: unit.id,
      premiumRate: matchingData.rates.find(
        item =>
          (item.unitStructure === scenarioPiece.unitStructure)
          &&
          (
            (
              (item.minAcres === null || unitGroupAcres >= item.minAcres)
              &&
              (item.maxAcresExclusive === null || unitGroupAcres < item.maxAcresExclusive)
            )
          ),
      )?.rate ?? 0,
    };

    return iceUnit;
  });
};

const transformIceData = (unit: Nullable<BaseUnit>, scenario: RowCropScenario, scenarioPiece: RowCropScenarioPiece): IcePremiumParams & IceIndemnityParams => {
  const iceParams: IcePremiumParams & IceIndemnityParams = {
    commodityCode: (unit?.commodityCode ?? '') as Commodity,
    liabilityAdjustmentFactor: scenarioPiece.protectionFactor / 100,
    multipleCommodityAdjustmentFactor: 1,
    priceElectionPercent: 1,
    underlyingLiabilityAmount: 0,
    underlyingGuaranteeAmount: 0,
    projectedPrice: scenario.projectedPrice ?? 0,
    harvestPrice: scenario.harvestPrice ?? 0,
    upperCoverageLevelPercent: scenarioPiece.upperCoverageLevel / 100,
    lowerCoverageLevelPercent: scenarioPiece.lowerCoverageLevel / 100,
    unitStructureCode: scenarioPiece.unitStructure,
    coverageTypeCode: CoverageTypeCode.A,
    expectedAreaYield: scenario.expectedCountyYield ?? 0,
    finalAreaYield: scenario.actualCountyYield ?? 0,
  };

  return iceParams;
};

const createCalcParamRequest = (state: RootState, scenarioPiece: RowCropScenarioPiece, scenario: RowCropScenario, baseUnits: BaseUnit[], productOfferAvailabilities?: ProductOfferAvailabilities): IceCalculationParamsRequest => {
  const quote = selectQuoteById(state, scenario.quoteId);
  if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

  const clientFile = selectClientFileById(state, quote.clientFileId);
  if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

  const yearToUse = productOfferAvailabilities?.productOfferAvailabilities.at(0)?.cropYear ?? clientFile.year;

  return {
    year: yearToUse,
    scenarioPieceTypeId: scenarioPiece.scenarioPieceType,
    iceDeductibleSelection: scenarioPiece.rowCropScenarioPieceExtendedData?.iceDeductibleSelection ?? null,
    commodityCode: quote.commodityCode,
    countyId: quote.countyId,
    distinctUnits: getCalcParamRequestUnits(baseUnits),
  };
};
