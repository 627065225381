import { PlanBaseType, ScenarioPieceTypeAttributes, sum } from '@silveus/calculations';
import { QuoteSummaryScenarioPiece } from '../../../../types/api/reports/QuoteSummaryScenarioPiece';
import { groupByWithValueSelector } from '../../../../utils/arrayUtils';


export const getQuoteSummaryLiabilityPerAcreSummary = (
  quoteSummaryScenarioPieces: QuoteSummaryScenarioPiece[],
): number => {

  const mappedGroupDataByTypes = quoteSummaryScenarioPieces.map(qssp => {
    const spAtt = ScenarioPieceTypeAttributes[qssp.scenarioPieceType];
    return {
      protectionAmountPerAcre: qssp.calculationData.premiumLiabilityPerAcre ?? 0,
      liabilityProductType: spAtt.liabilityProductType,
      planBaseType: spAtt.planBaseType,
    };
  });

  const nonHailPlanBases = mappedGroupDataByTypes.filter(x => x.planBaseType === PlanBaseType.Basic);
  const nonTypedLiabilityPerAcre = sum(nonHailPlanBases.filter(x => x.liabilityProductType === null), x => x.protectionAmountPerAcre);

  const typedLiabilityGroups = groupByWithValueSelector(
    nonHailPlanBases.filter(x => x.liabilityProductType !== null),
    x => x.liabilityProductType,
    x => x.protectionAmountPerAcre);

  const protectionArrays = Array.from(typedLiabilityGroups.values());
  const typedLiabilityPerAcre = sum(protectionArrays, liabilitiesPerType => Math.max(...liabilitiesPerType));

  return nonTypedLiabilityPerAcre + typedLiabilityPerAcre;
};