import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Nullable } from '../types/util/Nullable';
import { RootState } from './store';
import {
  ClientFileId,
  ScenarioId,
  ScenarioPieceId,
  ScenarioPieceSelectedIntervalId
} from '../types/api/PrimaryKeys';
import { RowCropScenarioPiece } from '../types/api/RowCropScenarioPiece';
import { generatePrimaryKey } from '../utils/primaryKeyHelpers';
import { getKeyedStateGroupedBy, getStateIdsMatching } from './sliceHelpers';
import { AvailabilityService, InsurancePlanCode, ScenarioPieceType } from '@silveus/calculations';
import { updateCalculationForScenarios } from './calculationResultsSlice';
import { getItemsForId, orderMap } from '../utils/mapHelpers';
import { createAppAsyncThunk } from './thunkHelpers';
import { getAsyncHandlerBuilder, initialSliceDataState, SliceDataState } from './sliceStateHelpers';
import { optionalBaseScenarioPieces, scenarioPieceOrderingServiceInstance } from '../utils/scenarioOrderingServiceWrappers';
import {
  createScenarioPieceRequest,
  deleteRowCropScenarioPieceRequest,
  getScenarioPiecesForClientFileRequest,
  getScenarioPiecesForScenarioRequest, getScenarioPiecesForScenariosRequest,
  updateScenarioPieceRequest
} from '../services/requestInterception/scenarioPieceRequestInterceptor';
import { setSelectedPremiumBreakdownData } from './premiumBreakdownSlice';
import { modifyScenarioPieceOrder, selectUserLinkedScenarios } from './userSettingsSlice';
import { DefaultOrders } from '../utils/entityOrdering/defaultOrdering';
import { stableEmptyArrayAsMutable } from '../utils/stableEmptyArray';
import {
  fetchAllScenarioPricesAndYieldsPriorYear,
  fetchCountyYieldInfo,
  fetchScenarioPricesAndYields,
  selectOfferActualCountyYield,
  selectOfferExpectedCountyYield,
  selectOfferHarvestPrice,
  selectOfferProjectedPrice
} from './admSlice';
import { selectScenarioById, updateScenario } from './scenariosSlice';
import { getCommodityCodeFromTypeId, getTypeCodeFromTypeId } from '../utils/adm';
import { selectClientFileById } from './clientFilesSlice';
import { selectQuoteById } from './quotesSlice';
import { MissingClientFileInStateError, MissingQuoteInStateError, MissingScenarioInStateError } from '../errors/state/MissingStateErrors';
import { RowCropScenario } from '../types/api/RowCropScenario';
import { doesAreaYieldVariationRuleExistForPlanCode, doesPriceVariationRuleExistForPlanCode } from '../utils/priceYieldVariationUtils';
import { getLinkedDataToUpdate } from './scenarioSliceHelpers';
import { FullExtendedData } from '../types/api/scenarioPieceExtendedData/fullExtendedData';
import { selectIntervalsForScenarioPiece, setSelectedIntervals } from './intervalsSlice';
import { validateAndUpdateScenario } from './validationsSlice';
import { isNotNullOrUndefined } from '../utils/nullHandling';
import { updateAppTaskLastFormRelatedChange } from '../services/appTasks.service';

export interface ScenarioPiecesState {
  allScenarioPieces: SliceDataState<ScenarioPieceId, RowCropScenarioPiece>;
}

const initialState: ScenarioPiecesState = {
  allScenarioPieces: initialSliceDataState(),
};

export const scenarioPiecesSlice = createSlice({
  name: 'scenarioPieces',
  initialState: initialState,
  reducers: {
  },
  extraReducers(builder) {
    const asyncHandlerBuilder = getAsyncHandlerBuilder(builder, s => s.allScenarioPieces, s => s.scenarioPieceId);

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'adding', thunk: createScenarioPiece,
      affectedIds: arg => arg.scenarioPiece.scenarioPieceId,
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchScenarioPieces,
      affectedIds: (arg, state) => getStateIdsMatching(state.allScenarioPieces.data, s => s.scenarioId === arg.scenarioId, s => s.scenarioPieceId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchScenarioPiecesByScenarioIds,
      affectedIds: () => [],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'fetching', thunk: fetchScenarioPiecesByClientFileId,
      affectedIds: () => [],
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'deleting', thunk: removeScenarioPiecesCore,
      affectedIds: arg => arg.allPiecesToDelete.map(p => p.scenarioPieceId),
    });

    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: modifyScenarioPiece,
      affectedIds: arg => arg.scenarioPiece.scenarioPieceId,
    });
    asyncHandlerBuilder.generateAsyncHandlers({
      action: 'updating', thunk: updateScenarioPiece,
      affectedIds: arg => arg.scenarioPiece.scenarioPieceId,
    });
  },
});


// Memoized Selectors
const selectScenarioPieceDictionary = (state: RootState) => state.scenarioPieces.allScenarioPieces.data;
const selectUserScenarioPieceOrder = (state: RootState) => state.userSettings.scenarioPieceOrder.scenarios;

export const selectAllRowCropScenarioPiecesByScenarioMap = createSelector([selectScenarioPieceDictionary], result => {
  const map = getKeyedStateGroupedBy(result, sp => sp.scenarioId);
  const ordered = orderMap(map, DefaultOrders.rowCropScenarioPieces);
  return ordered;
});

export const selectRowCropScenarioPiecesByScenarioId = createSelector([
  selectAllRowCropScenarioPiecesByScenarioMap,
  (_state, scenarioId) => scenarioId,
], (scenarioPiecesMap, scenarioId) => {
  return getItemsForId(scenarioPiecesMap, scenarioId);
});

export const selectBaseRowCropScenarioPieceByScenario = createSelector([selectRowCropScenarioPiecesByScenarioId], scenarioPieces => {
  const basePieceTypes = scenarioPieceOrderingServiceInstance.getBaseScenarioPieces();
  return scenarioPieces.find(sp => basePieceTypes.includes(sp.scenarioPieceType));
});

export const selectRowCropScenarioPiecePlanCodes = createSelector([selectRowCropScenarioPiecesByScenarioId], scenarioPieces => {
  const planCodes = scenarioPieces.map(sp => sp.planCode);
  return planCodes.length > 0 ? planCodes : stableEmptyArrayAsMutable<string>();
});

/** Retrieves scenario pieces by scenario, but ordered by the user's preference. */
export const selectAllRowCropScenarioPiecesByScenarioMapWithUserOrder = createSelector([selectScenarioPieceDictionary, selectAllRowCropScenarioPiecesByScenarioMap, selectUserScenarioPieceOrder], (allScenarioPieces, scenarioPiecesByScenario, scenarioPieceOrderByScenario) => {
  // Result should look very similar to the "normal" scenario pieces by scenario id map.
  const result = new Map<ScenarioId, RowCropScenarioPiece[]>();

  scenarioPiecesByScenario.forEach((scenarioPieces, scenarioId) => {
    // This is the main output.
    let orderedScenarioPiecesOutput: RowCropScenarioPiece[];

    // Check if there are user settings for order for the current scenario. If there are no user settings for the scenario,
    // it is the "easy" path (we can do nothing)
    const scenarioPieceOrderForScenario = scenarioPieceOrderByScenario[scenarioId];

    if (scenarioPieceOrderForScenario !== undefined) {
      // This is the complex path. The user has settings saved for scenario piece order.

      // Note: a set internally maintains order, and also keeps constant lookups, is why that was chosen.
      const orderedScenarioPieceSet = new Set<RowCropScenarioPiece>();

      // First: Look at the scenario piece ids in the user settings.
      // Try to match those with "real" scenario pieces in our store. Add those first, in the order they come from user settings.
      for (const scenarioPieceId of scenarioPieceOrderForScenario) {
        const matchingScenarioPieceInGlobalStore = allScenarioPieces[scenarioPieceId];
        if (matchingScenarioPieceInGlobalStore !== undefined) {
          orderedScenarioPieceSet.add(matchingScenarioPieceInGlobalStore);
        }
      }

      // Then iterate over the scenario pieces for the current scenario as from global state.
      for (const globalScenarioPieceForScenario of scenarioPieces) {

        // This explicitly checks "has" first instead of just adding to ensure original order is not touched.
        // Note that it is expected many of these will have already been added (if they are present in the user settings).
        // Since those have been added above, now we can add any scenario pieces back in that weren't added above (in the same order).
        if (!orderedScenarioPieceSet.has(globalScenarioPieceForScenario)) {
          orderedScenarioPieceSet.add(globalScenarioPieceForScenario);
        }
      }

      // Sets maintain order. Copy our set to the output.
      orderedScenarioPiecesOutput = Array.from(orderedScenarioPieceSet);

    } else {
      orderedScenarioPiecesOutput = scenarioPieces;
    }

    result.set(scenarioId, orderedScenarioPiecesOutput);
  });

  return result;
});

export const selectBaseScenarioPieceGivenPlanCodes = (state: RootState, scenarioId: ScenarioId, planCodes: string[]) => {
  return selectScenarioPieceForOfferDataInternal(state, scenarioId, planCodes);
};

export const selectScenarioPieceForOfferData = (state: RootState, scenarioId: ScenarioId) => {
  return selectScenarioPieceForOfferDataInternal(state, scenarioId, unitCompatibleInsurancePlans);
};

const unitCompatibleInsurancePlans = [InsurancePlanCode.RP, InsurancePlanCode.RPHPE, InsurancePlanCode.YP, InsurancePlanCode.APH] as string[];

const selectScenarioPieceForOfferDataInternal = (state: RootState, scenarioId: ScenarioId, planCodes: string[]) => {
  const scenarioPiecesForScenario = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), scenarioId).filter(sp => planCodes.includes(sp.planCode));
  const existingScenarioPieceTypes = scenarioPiecesForScenario.map(sp => sp.scenarioPieceType);
  const baseScenarioPieces = optionalBaseScenarioPieces(existingScenarioPieceTypes);
  const pieceInBasePieces = scenarioPiecesForScenario.find(p => baseScenarioPieces.includes(p.scenarioPieceType)) ?? null;
  return pieceInBasePieces;
};

// Non-Memoized Selectors
export const selectRowCropScenarioPiece = (state: RootState, scenarioPieceId: ScenarioPieceId): Nullable<RowCropScenarioPiece> => state.scenarioPieces.allScenarioPieces.data[scenarioPieceId] ?? null;

export const selectRowCropScenarioPiecesByScenarioIdAndTypes = (state: RootState, scenarioId: ScenarioId, scenarioPieceTypes: ScenarioPieceType[]) => {
  const scenarioPieces = selectRowCropScenarioPiecesByScenarioId(state, scenarioId);
  return scenarioPieces.find(scenarioPiece => scenarioPieceTypes.includes(scenarioPiece.scenarioPieceType)) ?? null;
};

export const fetchScenarioPieces = createAppAsyncThunk('scenarioPieces/fetchScenarioPieces', async ({ scenarioId }: { scenarioId: ScenarioId }) => {
  return await getScenarioPiecesForScenarioRequest(scenarioId);
});

export const fetchScenarioPiecesByClientFileId = createAppAsyncThunk('scenarioPieces/fetchScenarioPiecesByClientFileId', async ({ clientFileId }: { clientFileId: ClientFileId }) => {
  return await getScenarioPiecesForClientFileRequest(clientFileId);
});

export const fetchScenarioPiecesByScenarioIds = createAppAsyncThunk('scenarioPieces/fetchScenarioPiecesByScenarioPieceIds', async ({ scenarioIds }: { scenarioIds: ScenarioId[] }) => {
  return await getScenarioPiecesForScenariosRequest(scenarioIds);
});

//#region Common pieces of functionality relating to updates to scenario pieces

export const determineScenarioPiecesToChangeActiveState = (scenarioPiece: RowCropScenarioPiece, piecesOnScenario: RowCropScenarioPiece[]) => {
  let pieceTypesToUpdate: ScenarioPieceType[] = [];

  if (scenarioPiece.isActive) {
    //When a scenario piece is marked as active, we need to activate all scenario pieces that it depends on
    //Get scenario piece types of pieces that the modified piece depends on
    pieceTypesToUpdate = AvailabilityService.getScenarioPiecesThatThisScenarioPieceDependsOn(scenarioPiece.scenarioPieceType);
  } else {
    //When a scenario piece is marked as inactive, we need to deactivate all scenario pieces that depend on it
    //Get scenario piece types of pieces that depend on modified piece
    pieceTypesToUpdate = AvailabilityService.getScenarioPiecesThatDependOnScenarioPiece(scenarioPiece.scenarioPieceType);
  }

  const scenarioPiecesToChangeActiveState = piecesOnScenario.filter(sp => pieceTypesToUpdate.includes(sp.scenarioPieceType) && sp.isActive !== scenarioPiece.isActive);

  return scenarioPiecesToChangeActiveState;
};

//#endregion

export const createScenarioPiece = createAppAsyncThunk('scenarioPieces/createScenarioPiece', async (
  { scenarioPiece }:
    { scenarioPiece: RowCropScenarioPiece }, thunkApi) => {
  await createScenarioPieceRequest(scenarioPiece);
  // Update/get any potentially missing county yield data for the prior year (for historical data)
  await thunkApi.dispatch(fetchAllScenarioPricesAndYieldsPriorYear({ scenarioIds: [scenarioPiece.scenarioId] }));
  await thunkApi.dispatch(fetchCountyYieldInfo({ scenarioIds: [scenarioPiece.scenarioId] }));
  // We're okay with fire and forget here, no need to await.
  thunkApi.dispatch(updateRelatedAppTasks({ scenarioPiece }));
  return scenarioPiece;
});

const updateRelatedAppTasks = createAppAsyncThunk('scenarioPieces/updateRelatedAppTasks ', async ({ scenarioPiece }: { scenarioPiece: RowCropScenarioPiece }, thunkApi) => {
  // If the scenario(s) being updated here have been attached to an application its important that we update some fields on
  // the app task in D365 to help the user understand how their changes impact forms that may have already been generated.
  const appState = thunkApi.getState().appTaskStatuses;
  const impactedAppTaskId = appState.allAppTaskStatuses.data[scenarioPiece.scenarioId];

  if (isNotNullOrUndefined(impactedAppTaskId)) {
    updateAppTaskLastFormRelatedChange(impactedAppTaskId.appTaskId);
  }
});

export const createDuplicatedRowCropScenarioPiece = (sp: RowCropScenarioPiece, scenarioId: ScenarioId): RowCropScenarioPiece => {
  const scenarioPiece: RowCropScenarioPiece = {
    ...sp,
    scenarioId: scenarioId,
    rowCropScenarioPieceId: generatePrimaryKey(),
    scenarioPieceId: generatePrimaryKey(),
  };

  return scenarioPiece;
};

export const duplicateRowCropScenarioPieces = createAppAsyncThunk('scenarioPieces/duplicateScenarioPieces', async ({ rowCropScenarioPieces, scenarioId }: { rowCropScenarioPieces: RowCropScenarioPiece[], scenarioId: ScenarioId }, thunkApi) => {
  const newPieces = rowCropScenarioPieces.map(async sp => {
    const scenarioPiece = createDuplicatedRowCropScenarioPiece(sp, scenarioId);

    await thunkApi.dispatch(createScenarioPiece({ scenarioPiece: scenarioPiece }));
    await thunkApi.dispatch(modifyScenarioPieceOrder({ scenarioId: scenarioId, scenarioPieceId: scenarioPiece.scenarioPieceId }));

    const duplicatedIntervals = selectIntervalsForScenarioPiece(thunkApi.getState(), sp.scenarioPieceId)
      .map(interval => ({
        scenarioPieceSelectedIntervalId: generatePrimaryKey<ScenarioPieceSelectedIntervalId>(),
        scenarioPieceId: scenarioPiece.scenarioPieceId,
        intervalRangeId: interval.intervalRangeId,
        year: interval.year,
      }));

    if (duplicatedIntervals.length > 0)
      await thunkApi.dispatch(setSelectedIntervals({ scenarioPieceId: scenarioPiece.scenarioPieceId, scenarioPieceSelectedIntervals: duplicatedIntervals }));
  });
  return Promise.all(newPieces);
});

export const modifyScenarioPiece = createAppAsyncThunk(
  'scenarioPieces/modifyScenarioPiece', async ({ scenarioPiece }: { scenarioPiece: RowCropScenarioPiece }, thunkApi) => {
    const piecesToUpdate: RowCropScenarioPiece[] = [scenarioPiece];

    //Get other scenario pieces on parent scenario
    const state = thunkApi.getState();

    const userLinkedScenarios = selectUserLinkedScenarios(state);
    const scenario = selectScenarioById(state, scenarioPiece.scenarioId);
    const linkedScenarioData = scenario ? userLinkedScenarios.quotes[scenario.quoteId] ?? null : null;
    const rowCropScenarioPiecesByScenario = selectAllRowCropScenarioPiecesByScenarioMap(state);

    if (linkedScenarioData !== null) {
      const linkedScenarioRecord: Partial<Record<ScenarioId, RowCropScenarioPiece[]>> = Object.fromEntries(linkedScenarioData.linkedScenarioIds.map(id => [id, rowCropScenarioPiecesByScenario.get(id) ?? []]));
      piecesToUpdate.push(...getLinkedDataToUpdate(linkedScenarioRecord, linkedScenarioData, scenarioPiece));
    }

    const rootPiecesToUpdate = [...piecesToUpdate];
    for (const potentialRootPiece of rootPiecesToUpdate) {
      const piecesOnScenario = getItemsForId(rowCropScenarioPiecesByScenario, potentialRootPiece.scenarioId);

      //If we change the active state, we need to update the active state of all of the pieces that depend on it, or that it depends on
      const scenarioPiecesToChangeActiveState = determineScenarioPiecesToChangeActiveState(potentialRootPiece, piecesOnScenario);

      const modifiedScenarioPieces = scenarioPiecesToChangeActiveState.map(sp => ({ ...sp, isActive: potentialRootPiece.isActive }));

      // If user is hiding the scenario piece corresponds to the currently selected premiumBreakdownData we want to hide set the
      // selectedPremiumBreakdownData to null so its not shown anymore.
      const selectedPremiumBreakdownData = thunkApi.getState().premiumBreakdown.selectedPremiumBreakdownData;
      const correspondingPremiumBreakdownScenarioPiece = potentialRootPiece.scenarioPieceId === selectedPremiumBreakdownData?.id
        ? potentialRootPiece
        : modifiedScenarioPieces.find(x => x.scenarioPieceId === selectedPremiumBreakdownData?.id);
      if (correspondingPremiumBreakdownScenarioPiece && !correspondingPremiumBreakdownScenarioPiece.isActive) {
        thunkApi.dispatch(setSelectedPremiumBreakdownData(null));
      }

      piecesToUpdate.push(...modifiedScenarioPieces);
    }

    const updatePromises = piecesToUpdate.map(sp => thunkApi.dispatch(updateScenarioPiece({ scenarioPiece: sp })));
    await Promise.all(updatePromises);

    await thunkApi.dispatch(updateCalculationForScenarios({ scenarioIds: piecesToUpdate.map(sp => sp.scenarioId) }));
    return piecesToUpdate;
  },
);

const updateScenarioPiece = createAppAsyncThunk(
  'scenarios/updateScenarioPiece',
  async ({ scenarioPiece }: { scenarioPiece: RowCropScenarioPiece }, thunkApi) => {
    await updateScenarioPieceRequest(scenarioPiece);
    // We're okay with fire and forget here, no need to await.
    thunkApi.dispatch(updateRelatedAppTasks({ scenarioPiece }));
    return scenarioPiece;
  },
);

const removeScenarioPiece = createAppAsyncThunk(
  'scenarios/removeScenarioPiece',
  async ({ scenarioPiece }: { scenarioPiece: RowCropScenarioPiece }, thunkApi) => {
    //Get other scenario pieces on parent scenario
    const state = thunkApi.getState();
    const piecesOnScenario = getItemsForId(selectAllRowCropScenarioPiecesByScenarioMap(state), scenarioPiece.scenarioId);

    //Get scenario piece types of pieces that depend on deleted piece
    const pieceTypesToRemove = AvailabilityService.getScenarioPiecesThatDependOnScenarioPiece(scenarioPiece.scenarioPieceType);

    //Get the corresponding scenario pieces from the collection on the scenario
    const additionalPiecesToDelete = piecesOnScenario.filter(sp => pieceTypesToRemove.includes(sp.scenarioPieceType));
    const allPiecesToDelete = [scenarioPiece, ...additionalPiecesToDelete];

    await thunkApi.dispatch(removeScenarioPiecesCore({ allPiecesToDelete }));
    // We're okay with fire and forget here, no need to await.
    thunkApi.dispatch(updateRelatedAppTasks({ scenarioPiece }));
  },
);

const removeScenarioPiecesCore = createAppAsyncThunk(
  'scenarios/remove-scenario-pieces',
  async ({ allPiecesToDelete }: { allPiecesToDelete: RowCropScenarioPiece[] }, thunkApi) => {
    // short circuit because logic below requires at least one piece.
    if (allPiecesToDelete.length === 0) { return []; }

    const allPieceIdsToDelete = allPiecesToDelete.map(sp => sp.scenarioPieceId);

    //Send requests to delete all of them
    //TODO: This should probably be done as a bulk operation instead of a bunch of individual operations
    const deletePromises = allPieceIdsToDelete.map(scenarioPieceId => deleteRowCropScenarioPieceRequest(scenarioPieceId));

    //Wait for requests to finish
    await Promise.all(deletePromises);

    //Return IDs of objects that got deleted
    return allPiecesToDelete;
  });

export const removeScenarioPieceAndRecalculate = createAppAsyncThunk(
  'scenarioPieces/removeScenarioPieceAndRecalculate', async ({ scenarioPiece }: { scenarioPiece: RowCropScenarioPiece }, thunkApi) => {
    let state = thunkApi.getState();
    const scenario = selectScenarioById(state, scenarioPiece.scenarioId);
    if (scenario === null) throw new MissingScenarioInStateError(scenarioPiece.scenarioId);

    const quote = selectQuoteById(state, scenario.quoteId);
    if (quote === null) throw new MissingQuoteInStateError(scenario.quoteId);

    const clientFile = selectClientFileById(state, quote.clientFileId);
    if (clientFile === null) throw new MissingClientFileInStateError(quote.clientFileId);

    await thunkApi.dispatch(removeScenarioPiece({ scenarioPiece: scenarioPiece }));
    state = thunkApi.getState();

    //Check if the scenario piece being removed potentially had special prices/yields loaded. This can be done by checking for a matching rule in the priceYieldVariationUtils.
    //If there is a match, the prices/yields need to be fetched again excluding the plan code being removed, and the scenario should be updated back to the desired defaults.
    const commodityCode = getCommodityCodeFromTypeId(scenario.typeId);
    const typeCode = getTypeCodeFromTypeId(scenario.typeId);
    const priceRule = doesPriceVariationRuleExistForPlanCode(commodityCode, typeCode, scenarioPiece.planCode);
    const yieldRule = doesAreaYieldVariationRuleExistForPlanCode(commodityCode, typeCode, scenarioPiece.planCode);

    if (priceRule || yieldRule) {
      const planCodes = selectRowCropScenarioPiecePlanCodes(state, scenario);

      await thunkApi.dispatch(fetchScenarioPricesAndYields({ year: clientFile.year, countyId: quote.countyId, typeId: scenario.typeId, practiceId: scenario.practiceId ?? '', planCodes: planCodes }));
      state = thunkApi.getState();

      const updatedScenario: RowCropScenario = {
        ...scenario,
        projectedPrice: priceRule ? selectOfferProjectedPrice(state) : scenario.projectedPrice,
        harvestPrice: priceRule ? selectOfferHarvestPrice(state) : scenario.harvestPrice,
        expectedCountyYield: yieldRule ? selectOfferExpectedCountyYield(state) : scenario.expectedCountyYield,
        actualCountyYield: yieldRule ? selectOfferActualCountyYield(state) : scenario.actualCountyYield,
      };

      await thunkApi.dispatch(updateScenario({ updatedScenario }));
      // Update/get any potentially missing county yield data for the prior year (for historical data)
      await thunkApi.dispatch(fetchAllScenarioPricesAndYieldsPriorYear({ scenarioIds: [updatedScenario.scenarioId] }));
      await thunkApi.dispatch(fetchCountyYieldInfo({ scenarioIds: [updatedScenario.scenarioId] }));
    }

    await thunkApi.dispatch(validateAndUpdateScenario({ scenarioId: scenarioPiece.scenarioId }));
  },
);

export const modifyMpScenarioPiecePriceAndInputCosts = createAppAsyncThunk(
  'scenarioPieces/modifyMpScenarioPiecePriceAndInputCosts', async ({ mpScenarioPiece, rowCropScenarioPieceExtendedData }: { mpScenarioPiece: Nullable<RowCropScenarioPiece>, rowCropScenarioPieceExtendedData: Nullable<FullExtendedData> }, thunkApi) => {
    const isMpDataDirty = (formData: Nullable<FullExtendedData>, mpScenarioPiece: Nullable<RowCropScenarioPiece>) => {
      if (mpScenarioPiece === null || formData === null) {
        return false;
      }

      const mpExtendedData = mpScenarioPiece.rowCropScenarioPieceExtendedData;

      if (mpExtendedData?.projectedPrice === formData.projectedPrice &&
        mpExtendedData.expectedInputCosts === formData.expectedInputCosts &&
        mpExtendedData.actualInputCosts === formData.actualInputCosts) {
        return false;
      }

      return true;
    };

    if (mpScenarioPiece !== null &&
      rowCropScenarioPieceExtendedData !== null &&
      isMpDataDirty(rowCropScenarioPieceExtendedData, mpScenarioPiece)) {
      const mpExtendedData = mpScenarioPiece.rowCropScenarioPieceExtendedData;

      const newMpScenarioPiece = {
        ...mpScenarioPiece,
        rowCropScenarioPieceExtendedData:
          mpExtendedData !== null ?
            {
              ...mpExtendedData,
              projectedPrice: rowCropScenarioPieceExtendedData.projectedPrice ?? mpExtendedData.projectedPrice,
              expectedInputCosts: rowCropScenarioPieceExtendedData.expectedInputCosts ?? mpExtendedData.expectedInputCosts,
              actualInputCosts: rowCropScenarioPieceExtendedData.actualInputCosts ?? mpExtendedData.actualInputCosts,
            }
            : null,
      };

      await thunkApi.dispatch(modifyScenarioPiece({ scenarioPiece: newMpScenarioPiece }));
    }
  },
);

export default scenarioPiecesSlice.reducer;
