import { ICellRendererParams } from 'ag-grid-community';
import { UnitYearId } from '../../../types/api/PrimaryKeys';
import UnitYear from '../../../types/api/UnitYear';
import { getItemsForId } from '../../../utils/mapHelpers';
import { useOptionCodesWithAvailability } from '../../../hooks/useOptionCodesWithAvailability';
import { OptionLevelCodes } from '../../../constants/optionLevelCodes';
import OptionState from '../../../types/app/OptionState';
import { OptionSelectionState } from '../../../types/app/enums/optionSelectionState.enum';

type UnitOptionsCellRendererProps = {
  unitYear: UnitYear;
  params: ICellRendererParams<UnitYear>
  unitOptionsMap: Map<UnitYearId, OptionState[]>;
};

const UnitOptionsCellRenderer = ({ params, unitYear, unitOptionsMap }: UnitOptionsCellRendererProps) => {
  const getFormattedOptionCodesWithAvailability = useOptionCodesWithAvailability();
  const isPinnedRow = params.node.rowPinned === 'bottom';
  if (isPinnedRow) {
    return null;
  }

  const selectedOptions = getItemsForId(unitOptionsMap, unitYear.unitYearId)
    .filter(so => so.selectionState === OptionSelectionState.On && so.option.optionLevelCode === OptionLevelCodes.Unit);
  const formattedOptions = getFormattedOptionCodesWithAvailability(selectedOptions);

  return (
    <div>{formattedOptions}</div>
  );
};

export default UnitOptionsCellRenderer;
