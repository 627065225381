import { MatrixReportData } from '../../../types/api/reports/MatrixReportData';
import { RowCropScenario } from '../../../types/api/RowCropScenario';
import { SigReportDocumentType } from '../../../constants/sigReportDocumentType';
import { getContactInfoForAgent } from './getContactInfoForAgent';
import { getMatrixTableData } from './getMatrixTableData';
import { ScenarioId, ScenarioPieceId } from '../../../types/api/PrimaryKeys';
import MatrixPreset from '../../../types/api/MatrixPreset';
import { MatrixShowFilterType } from '../../../types/api/enums/matrixShowFilterType/MatrixShowFilterType.enum';
import SweetSpot from '../../../types/api/SweetSpot';
import { RowCropScenarioPiece } from '../../../types/api/RowCropScenarioPiece';
import { InputCostScenarioPiece } from '../../../types/api/InputCostScenarioPiece';
import ForwardSoldScenarioPiece from '../../../types/api/ForwardSoldScenarioPiece';
import HarvestRevenueScenarioPiece from '../../../types/api/HarvestRevenueScenarioPiece';
import { ScenarioPieceResponseDTO } from '@silveus/calculations';
import { Nullable } from '../../../types/util/Nullable';
import { MatrixCellData } from '../../../types/api/reports/MatrixCellData';
import { MatrixIncludeFilterType } from '../../../types/api/enums/matrixIncludeFilterType/MatrixIncludeFilterType.enum';
import { AgentInformation } from '../../../types/api/agentInformation';
import { AgencyInformation } from '../../../types/api/agencyInformation';

export const getMatrixReportData = (
  cropYear: number,
  scenarios: RowCropScenario[],
  primaryScenarioId: ScenarioId,
  reportDocumentType: SigReportDocumentType,
  includeGenerationDate: boolean,
  matrixPreset: MatrixPreset,
  matrixShowFilterType: MatrixShowFilterType,
  matrixIncludeType: MatrixIncludeFilterType,
  sweetSpots: SweetSpot[],
  rowCropScenarioPieces: RowCropScenarioPiece[],
  inputCostScenarioPiece: InputCostScenarioPiece | undefined,
  forwardSoldScenarioPiece: ForwardSoldScenarioPiece | undefined,
  harvestRevenueScenarioPiece: HarvestRevenueScenarioPiece | undefined,
  calcsByScenarioPiece: Map<ScenarioPieceId, ScenarioPieceResponseDTO>,
  scenarioExpectedCountyYield: Nullable<number>,
  scenarioApprovedYield: Nullable<number>,
  scenarioAdjustedYield: Nullable<number>,
  cellData: MatrixCellData[][],
  projectedPricePrecision: number,
  agentInformation: Nullable<AgentInformation>,
  agencyInformation: Nullable<AgencyInformation>,
): MatrixReportData => {

  const matrixReportData: MatrixReportData = {
    agentContactInformation: getContactInfoForAgent(agentInformation, agencyInformation),
    insuredContactInformation: null,
    cropYear: cropYear,
    hasGenerationDate: includeGenerationDate,
    hasPageNumbers: true,
    reportDocumentType: reportDocumentType,
    scenarios: scenarios,
    primaryScenarioId: primaryScenarioId,
    matrixData: getMatrixTableData(
      scenarios.at(0),
      matrixPreset,
      matrixShowFilterType,
      matrixIncludeType,
      sweetSpots,
      rowCropScenarioPieces,
      inputCostScenarioPiece,
      forwardSoldScenarioPiece,
      harvestRevenueScenarioPiece,
      calcsByScenarioPiece,
      scenarioExpectedCountyYield,
      scenarioApprovedYield,
      scenarioAdjustedYield,
      cellData,
      projectedPricePrecision,
    ),
  };
  return matrixReportData;
};