import { useTheme } from '@mui/material';
import OptionState from '../types/app/OptionState';

export const useOptionCodesWithAvailability = () => {
  const theme = useTheme();

  return (options: OptionState[]) => {
    return options.map((o, index) => {
      const style = !o.isAvailable ? { color: theme.palette.warning.main } : {};
      return (
        <span key={o.option.optionCode} style={style}>
          {o.option.optionCode}{index < options.length - 1 ? ', ' : ''}
        </span>
      );
    });
  };
};
