import ScenarioPieceDefinition from '../../types/app/ScenarioPieceDefinition';
import validationFunctionDefault from './defaultDefinitions/validationFunctionDefault';
import { ScenarioPieceFormPickerProps } from '../../pages/scenarioPiece/scenarioPieceFormPicker.component';
import {
  getDefaultQuoteSummaryData
} from '../../components/reports/utils/quoteSummary/quoteSummaryScenarioPieces/getDefaultQuoteSummaryData';
import { Nullable } from '../../types/util/Nullable';
import { createHarvestRevenueData } from '../../services/calculations/harvestRevenue/harvestRevenueDataTransformation';
import HarvestRevenueScenarioPiece from '../../types/api/HarvestRevenueScenarioPiece';
import HarvestRevenueScenarioPieceForm from '../../pages/scenarioPiece/harvestRevenue/harvestRevenueScenarioPieceForm.component';
import { addHarvestRevenueScenarioPiece } from '../../app/harvestRevenueScenarioPiecesSlice';
import { getFullyQualifiedScenarioPieceNameOnlyName } from './defaultDefinitions/scenarioPieceNameDefaults';
import { scenarioPieceCardChipDefault } from './defaultDefinitions/scenarioPieceCardChipDefaults';

export const harvestRevenueDefinition: ScenarioPieceDefinition = {
  validationFunction: validationFunctionDefault,
  d365ProductType: undefined,
  createScenarioPieceDto: (scenarioPiece, state, scenario, baseUnits, aphYield, shouldSimulateUnitGroups) => {
    return createHarvestRevenueData(scenarioPiece as HarvestRevenueScenarioPiece, scenario, state, baseUnits, shouldSimulateUnitGroups ?? false);
  },
  scenarioPieceForm: (props: ScenarioPieceFormPickerProps) => {
    return <HarvestRevenueScenarioPieceForm {...props} scenarioPiece={props.scenarioPiece as Nullable<HarvestRevenueScenarioPiece>}/>;
  },
  getHistoricalQuoterColumns: () => [],
  getHistoricalQuoterColumnConfigs: () => [],
  createInputForQuoteSummaryFunction: getDefaultQuoteSummaryData,
  addScenarioPiece: (scenarioPiece, dispatch) => dispatch(addHarvestRevenueScenarioPiece({ scenarioPiece: scenarioPiece as HarvestRevenueScenarioPiece })),
  getFullyQualifiedScenarioPieceName: getFullyQualifiedScenarioPieceNameOnlyName,
  getScenarioPieceCardChips: scenarioPieceCardChipDefault,
  shouldShowThreeStageSummaryExpander: false,
};
