import { ClientFile } from '../../types/api/ClientFile';
import { InputCostScenarioPiece } from '../../types/api/InputCostScenarioPiece';
import { Commodity } from '../../types/api/adm/Commodity';
import { County } from '../../types/api/adm/County';
import HistoricalRevenue from '../../types/api/adm/HistoricalRevenue';
import HistoricalYield from '../../types/api/adm/HistoricalYield';
import { State } from '../../types/api/adm/State';
import { OrderByParameters } from '../arrayUtils';
import { ScenarioUnitYearAph } from '../../types/api/ScenarioUnitYearAph';
import UnitYear from '../../types/api/UnitYear';
import { UnitGroup } from '../../types/api/UnitGroup';
import UnitYearAph from '../../types/api/UnitYearAph';
import { RowCropScenario } from '../../types/api/RowCropScenario';
import { RowCropScenarioPiece } from '../../types/api/RowCropScenarioPiece';
import { Quote } from '../../types/api/Quote';
import ScenarioOptionUnitYear from '../../types/api/options/ScenarioOptionUnitYear';
import ScenarioOption from '../../types/api/options/ScenarioOption';
import { Insured } from '../../types/api/insureds/Insured';
import Matrix from '../../types/api/Matrix';
import { CropType } from '../../types/api/adm/CropType';
import { Practice } from '../../types/api/adm/Practice';
import ForwardSoldScenarioPiece from '../../types/api/ForwardSoldScenarioPiece';
import HarvestRevenueScenarioPiece from '../../types/api/HarvestRevenueScenarioPiece';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';
import HistoricalStormEvent from '../../types/api/adm/HistoricalStormEvent';
import MatrixPreset from '../../types/api/MatrixPreset';
import { TemplateScenario } from '../../types/api/template-scenarios/TemplateScenario';

export abstract class DefaultOrders {

  static readonly clientFiles: OrderByParameters<ClientFile> = [
    {
      property: 'year',
      order: 'desc',
    },
    {
      property: 'name',
      order: 'asc',
    },
  ];

  static readonly commodities: OrderByParameters<Commodity> = {
    property: 'name',
    order: 'asc',
  };

  static readonly counties: OrderByParameters<County> = {
    property: 'name',
    order: 'asc',
  };

  static readonly cropTypes: OrderByParameters<CropType> = {
    property: 'name',
    order: 'asc',
  };

  static readonly forwardSoldScenarioPieces: OrderByParameters<ForwardSoldScenarioPiece> = {
    property: 'scenarioPieceId',
    order: 'asc',
  };

  static readonly harvestRevenueScenarioPieces: OrderByParameters<HarvestRevenueScenarioPiece> = {
    property: 'scenarioPieceId',
    order: 'asc',
  };

  static readonly historicalRevenue: OrderByParameters<HistoricalRevenue> = {
    property: 'year',
    order: 'asc',
  };

  static readonly historicalYields: OrderByParameters<HistoricalYield> = {
    property: 'year',
    order: 'asc',
  };

  static readonly inputCostScenarioPieces: OrderByParameters<InputCostScenarioPiece> = {
    property: 'scenarioPieceId',
    order: 'asc',
  };

  static readonly insureds: OrderByParameters<Insured> = {
    property: 'name',
    order: 'asc',
  };

  static readonly matrices: OrderByParameters<Matrix> = {
    property: 'name',
    order: 'asc',
  };

  static readonly matrixPresets: OrderByParameters<MatrixPreset> = [
    {
      property: 'matrixPresetType',
      order: 'asc',
    },
  ];

  static readonly practices: OrderByParameters<Practice> = {
    property: 'name',
    order: 'asc',
  };

  static readonly quotes: OrderByParameters<Quote> = [
    {
      property: 'sortIndex',
      order: 'asc',
    },
    {
      property: 'countyId',
      order: 'asc',
    },
    {
      property: 'commodityCode',
      order: 'asc',
    },
    {
      property: 'name',
      order: 'asc',
    },
  ];

  static readonly rowCropScenarios: OrderByParameters<RowCropScenario> = [
    {
      property: 'sortIndex',
      order: 'asc',
    },
    {
      property: 'name',
      order: 'asc',
    },
  ];

  static readonly rowCropScenarioPieces: OrderByParameters<RowCropScenarioPiece> = {
    property: 'scenarioPieceId',
    order: 'asc',
  };

  static readonly scenarioOptions: OrderByParameters<ScenarioOption> = {
    property: 'option',
    order: 'asc',
  };

  static readonly scenarioPieces: OrderByParameters<ScenarioPiece> = [
    {
      property: 'scenarioPieceType',
      order: 'asc',
    },
    {
      property: 'scenarioPieceId',
      order: 'asc',
    },
  ];

  static readonly scenarioOptionUnitYears: OrderByParameters<ScenarioOptionUnitYear> = {
    property: 'scenarioOptionUnitYearId',
    order: 'asc',
  };

  static readonly scenarioUnitYearAphs: OrderByParameters<ScenarioUnitYearAph> = {
    property: 'scenarioUnitYearAphId',
    order: 'asc',
  };

  static readonly states: OrderByParameters<State> = {
    property: 'name',
    order: 'asc',
  };

  static readonly templateScenarios: OrderByParameters<TemplateScenario> = {
    property: 'name',
    order: 'asc',
  };

  static readonly unitGroups: OrderByParameters<UnitGroup> = {
    property: 'year',
    order: 'asc',
  };

  static readonly unitYears: OrderByParameters<UnitYear> = {
    property: 'year',
    order: 'asc',
  };

  static readonly unitYearAphs: OrderByParameters<UnitYearAph> = {
    property: 'year',
    order: 'asc',
  };

  static readonly historicalStormEvents: OrderByParameters<HistoricalStormEvent> = {
    property: 'date',
    order: 'asc',
  };
}
