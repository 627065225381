import { ChangeEvent, useEffect, useState } from 'react';
import { ScenarioId } from '../../types/api/PrimaryKeys';
import { Grid, TextField, Tooltip, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { Nullable } from '../../types/util/Nullable';
import { ScenarioPieceTypeFields } from '../../components/formInputs/scenarioPiece/scenarioPieceTypeInput.component';
import { selectScenarioById, setCurrentlySelectedScenarioId } from '../../app/scenariosSlice';
import { selectClientFileById } from '../../app/clientFilesSlice';
import { selectQuoteById } from '../../app/quotesSlice';
import { closeDrawer, selectShouldCloseOnSave } from '../../app/appDrawerSlice';
import { IsActiveFields } from '../../components/formInputs/scenarioPiece/isActiveInput.component';
import { ScenarioPieceFormPicker } from './scenarioPieceFormPicker.component';
import { ScenarioPieceType, ScenarioPieceGroupType, ScenarioPieceGroupTypeAttributes, ScenarioPieceIdentifier } from '@silveus/calculations';
import { useAvailableScenarioPieces } from '../../components/formInputs/scenarioPiece/scenarioPieceTypeInput.util';
import { FormWrapperProps } from '../../components/formWrapper/formWrapper.component';
import { ScenarioPiece } from '../../types/api/ScenarioPiece';
import { TemplateSelection } from './TemplateSelection.component';
import { selectAllScenarioPiecesByScenarioMap } from '../../app/sharedSelectors';
import { getItemsForId } from '../../utils/mapHelpers';
import { OptionsForSpFormContextProvider } from '../../contexts/optionsForSpFormContext';

export interface ScenarioPieceFormProps extends FormWrapperProps {
  scenarioPiece: Nullable<ScenarioPiece>;
  scenarioId: ScenarioId;
  scenarioPieceType?: ScenarioPieceIdentifier;
}

export type ScenarioPieceFormFields = ScenarioPieceTypeFields & IsActiveFields;

export interface ScenarioPiecePlanCodeFields {
  planCode: string;
}

const getDefaultScenarioPieceType = (scenarioPiece: Nullable<ScenarioPiece>, scenarioPieceType: ScenarioPieceIdentifier | undefined) => {
  let defaultScenarioPieceType: ScenarioPieceIdentifier = scenarioPiece?.scenarioPieceType ?? scenarioPieceType ?? ScenarioPieceType.Unset;

  if (scenarioPiece?.scenarioPieceType !== undefined) {
    const scenarioPieceGroup = Object.values(ScenarioPieceGroupTypeAttributes).find(spg => spg.elements?.includes(scenarioPiece.scenarioPieceType));
    if (scenarioPieceGroup !== undefined) {
      defaultScenarioPieceType = scenarioPieceGroup.value;
    }
  }

  return defaultScenarioPieceType;
};

const ScenarioPieceForm = ({ scenarioPiece, scenarioId, registerHeader, handleValidation, isCanceling = false, scenarioPieceType }: ScenarioPieceFormProps) => {
  const dispatch = useAppDispatch();
  const scenario = useAppSelector(s => selectScenarioById(s, scenarioId));
  const quote = useAppSelector(state => scenario === null ? null : selectQuoteById(state, scenario.quoteId));
  const clientFile = useAppSelector(s => quote === null ? null : selectClientFileById(s, quote.clientFileId));

  const doesScenarioHaveNoPieces = useAppSelector(s => getItemsForId(selectAllScenarioPiecesByScenarioMap(s), scenarioId).length === 0);

  const defaultScenarioPieceType = getDefaultScenarioPieceType(scenarioPiece, scenarioPieceType);
  const [selectedScenarioPieceType, setSelectedScenarioPieceType] = useState(defaultScenarioPieceType);

  const shouldCloseOnSave = useAppSelector(selectShouldCloseOnSave);

  useEffect(() => {
    //This handles the case where we click the summary tab, but no piece selection has been made
    // so there's no form to tell the formWrapper that we need to change. The empty formId tells it to switch anyway
    if (selectedScenarioPieceType === ScenarioPieceType.Unset) {
      registerHeader && registerHeader('Scenario', '');
    }
  }, [selectedScenarioPieceType]);

  useEffect(() => {
    //This handles the case where we click the cancel button, but no piece selection has been made,
    // so there's no form to initiate the cancellation
    if ((isCanceling || shouldCloseOnSave) && selectedScenarioPieceType === ScenarioPieceType.Unset) {
      handleCancel();
      dispatch(closeDrawer());
    }
  }, [isCanceling, shouldCloseOnSave]);

  useEffect(() => {
    //We have a form open for a saved item that no longer exists
    if (scenarioPiece !== null && (scenario === null || clientFile === null || quote === null)) {
      dispatch(closeDrawer());
    }
  }, [scenarioPiece, scenario, clientFile, quote]);

  const handleScenarioPieceSelectionChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let newScenarioPieceType: ScenarioPieceIdentifier = ScenarioPieceType.Unset;

    try {
      const scenarioPieceTypeValue = parseInt(e.target.value);

      if (scenarioPieceTypeValue in ScenarioPieceGroupTypeAttributes) {
        newScenarioPieceType = scenarioPieceTypeValue as ScenarioPieceGroupType;
      } else {
        newScenarioPieceType = scenarioPieceTypeValue as ScenarioPieceType;
      }

    } finally {
      setSelectedScenarioPieceType(newScenarioPieceType);
    }
  };

  const handleCancel = () => {
    dispatch(setCurrentlySelectedScenarioId(null));
  };

  const availableScenarioPieces = useAvailableScenarioPieces(scenarioId);

  const shouldShowTemplateSelectionUi =
    selectedScenarioPieceType === ScenarioPieceType.Unset &&
    doesScenarioHaveNoPieces;

  return (
    <>
      {quote !== null && clientFile !== null && scenario !== null &&
        <Grid container spacing={2} p={2}>
          <Grid container item xs={12} alignContent="center">
            <Tooltip title={scenario.name} enterDelay={500}>
              <Typography
                noWrap paddingLeft={'5px'} paddingTop={'0px'} marginTop={'0px'} color={scenario.scenarioColor}
                fontWeight="bold" fontSize={'14px'}>{scenario.name}</Typography>
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="number"
              label="Scenario Piece Type"
              fullWidth
              select
              autoFocus
              value={selectedScenarioPieceType}
              onChange={handleScenarioPieceSelectionChange}
              disabled={scenarioPiece !== null}
              size="small"
            >
              {availableScenarioPieces}
            </TextField>
          </Grid>

          {shouldShowTemplateSelectionUi &&
            <>
              <Grid item xs={12}>
                <Typography variant="body2" textAlign={'center'}>OR</Typography>
              </Grid>
              <TemplateSelection scenarioId={scenarioId} />
            </>
          }

          <Grid item xs={12}>
            <OptionsForSpFormContextProvider scenarioId={scenarioId}>
              <ScenarioPieceFormPicker
                scenarioPiece={scenarioPiece}
                scenarioId={scenario.scenarioId}
                year={clientFile.year}
                countyId={quote.countyId}
                typeId={scenario.typeId}
                practiceId={scenario.practiceId ?? ''}
                commodityCode={quote.commodityCode}
                highRiskTypeId={scenario.highRiskTypeId}
                disabled={!(scenarioPiece?.isActive ?? true)}
                selectedScenarioPieceType={selectedScenarioPieceType}
                registerHeader={registerHeader}
                quote={quote}
                clientFile={clientFile}
                handleValidation={handleValidation}
                handleCancel={handleCancel}
                isCanceling={isCanceling}
                updateScenarioPieceFormSnapshot={() => { }}
                scenarioPieceFormSnapshot={null}
                scenarioPieceTypePlanSelectionList={[]}
              />
            </OptionsForSpFormContextProvider>
          </Grid>
        </Grid >
      }
    </>
  );
};

export default ScenarioPieceForm;
