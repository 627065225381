import { ActiveAIPs, getAIPLabelById, SupportedDocumentGenerationAIPIds } from '../../../constants/activeAIPs';
import { eSignStatusCallback } from '../../../constants/envConstants';
import {
  eSignFMHApplicationDocument,
  eSignFMHSBIDocument,
  eSignHudsonApplicationDocument,
  eSignHudsonSBIDocument,
  eSignNAUApplicationDocument,
  eSignNAUSBIDocument,
  generateAgriSompoApplicationDocument,
  generateFmhApplicationDocument,
  generateFmhSBIDocument,
  generateHudsonApplicationDocument,
  generateHudsonSBIDocument,
  generateNAUApplicationDocument,
  generateNAUSBIDocument,
  generateProAgApplicationDocument,
  generateProAgSbiDocument
} from '../../../services/applicationDocuments.service';
import { getApplicationDocumentBase64Content, getAppTaskSupportingDocumentBase64Content } from '../../../services/appTasks.service';
import { ADMYear } from '../../../types/api/adm/ADMYear';
import { AgencyInformation } from '../../../types/api/agencyInformation';
import { AgentInformation } from '../../../types/api/agentInformation';
import { AIP } from '../../../types/api/aip';
import { ApplicationWizard } from '../../../types/api/applicationWizard/applicationWizard';
import { AppTask, AppTaskExtended } from '../../../types/api/AppTask';
import { AppTaskDocument } from '../../../types/api/appTaskDocument';
import { BulkESignatureRequest, ESignatureDocument, ESignatureRecipient, ESignatureRequest, ESignResult } from '../../../types/api/documents/esign';
import { AppDocumentType } from '../../../types/api/enums/application/appDocumentType.enum';
import { ProductType } from '../../../types/api/enums/application/productType.enum';
import { PersonOfInterestType } from '../../../types/api/enums/contactInfo/personOfInterestType';
import { EntityType } from '../../../types/api/insureds/EntityType';
import { Insured } from '../../../types/api/insureds/Insured';
import { TaxType } from '../../../types/api/insureds/TaxType';
import { TaxTypeId } from '../../../types/api/PrimaryKeys';
import { Nullable } from '../../../types/util/Nullable';
import { getCountyName, getStateName } from '../../../utils/adm';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/nullHandling';
import { ESignData } from '../eSignModal';
import { MapAgriSompoApplication } from './agriSompoApplicationMapper';
import { MapFmhAdditionalSBIs, MapFMHApplication, MaxFmhSbis } from './fmhApplicationMapper';
import { MapHudsonAdditionalSBIs, MapHudsonApplication, MaxHudsonSbis } from './hudsonApplicationMapper';
import { MapNAUAdditionSBIs, MapNAUApplication, MaxNAUSBIs } from './nauApplicationMapper';
import { MapProAgAdditionalSBIs, MapProAgApplication, MaxProAgSbi } from './proAgApplicationMapper';

export const generateApplicationDocument = async (aips: AIP[], appTask: AppTask, applicationWizard: ApplicationWizard, insured: Insured, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], agent: AgentInformation, agency: AgencyInformation, includePDFBytes: boolean = false) => {
  const aipId = appTask.aipId;

  if (isNullOrUndefined(aipId) || !SupportedDocumentGenerationAIPIds.has(aipId)) {
    throw new Error(`AIP Id ${aipId} does not support form generation yet.`);
  }

  if (isNullOrUndefined(agent)) {
    throw new Error('Agent information is required');
  }

  switch (aipId) {
    case ActiveAIPs.Hudson.aipId:
      return await handleAipApplicationDocument(appTask, applicationWizard, insured, entityTypes, admData, taxTypes, includePDFBytes, aips, agent, agency, MapHudsonApplication, generateHudsonApplicationDocument);
    case ActiveAIPs.FMH.aipId:
      return await handleAipApplicationDocument(appTask, applicationWizard, insured, entityTypes, admData, taxTypes, includePDFBytes, aips, agent, agency, MapFMHApplication, generateFmhApplicationDocument);
    case ActiveAIPs.NAU.aipId:
      return await handleAipApplicationDocument(appTask, applicationWizard, insured, entityTypes, admData, taxTypes, includePDFBytes, aips, agent, agency, MapNAUApplication, generateNAUApplicationDocument);
    case ActiveAIPs.ProAg.aipId:
      return await handleAipApplicationDocument(appTask, applicationWizard, insured, entityTypes, admData, taxTypes, includePDFBytes, aips, agent, agency, MapProAgApplication, generateProAgApplicationDocument);
    case ActiveAIPs.AgriSompo.aipId:
      return await handleAipApplicationDocument(appTask, applicationWizard, insured, entityTypes, admData, taxTypes, includePDFBytes, aips, agent, agency, MapAgriSompoApplication, generateAgriSompoApplicationDocument);
    default:
      throw new Error(`Cannot generate Application for AIP Id ${aipId}`);
  }
};

export const generateAdditionalSBIForms = async (appTaskSummary: AppTask, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], agent: AgentInformation, agency: AgencyInformation, insured: Insured, includePDFBytes: boolean = false) => {
  const aipId = appTaskSummary.aipId;
  if (isNullOrUndefined(aipId) || !SupportedDocumentGenerationAIPIds.has(aipId)) {
    throw new Error(`AIP Id ${aipId} does not support form generation yet.`);
  }

  if (isNullOrUndefined(agent)) {
    throw new Error('Agent information is required');
  }

  switch (aipId) {
    case ActiveAIPs.Hudson.aipId:
      return await handleAipSbis(appTaskSummary, insured, entityTypes, admData, taxTypes, includePDFBytes, agent, agency, MapHudsonAdditionalSBIs, generateHudsonSBIDocument, MaxHudsonSbis);
    case ActiveAIPs.FMH.aipId:
      return await handleAipSbis(appTaskSummary, insured, entityTypes, admData, taxTypes, includePDFBytes, agent, agency, MapFmhAdditionalSBIs, generateFmhSBIDocument, MaxFmhSbis);
    case ActiveAIPs.NAU.aipId:
      return await handleAipSbis(appTaskSummary, insured, entityTypes, admData, taxTypes, includePDFBytes, agent, agency, MapNAUAdditionSBIs, generateNAUSBIDocument, MaxNAUSBIs);
    case ActiveAIPs.ProAg.aipId:
      return await handleAipSbis(appTaskSummary, insured, entityTypes, admData, taxTypes, includePDFBytes, agent, agency, MapProAgAdditionalSBIs, generateProAgSbiDocument, MaxProAgSbi);
    case ActiveAIPs.AgriSompo.aipId:
      // For now, the decision was made to not spend time supporting AgriSompo SBI form. Largely, this is because
      // the CSR team does not think we'll ever practically exceed or hit the 35 SBI slots available on the main application.
      return null;
    default:
      throw new Error(`Cannot generate SBI Forms for AIP Id ${aipId}`);
  }
};

const handleAipSbis = async <T, U>(
  appTaskSummary: AppTask,
  insured: Insured,
  entityTypes: EntityType[],
  admData: ADMYear[],
  taxTypes: TaxType[],
  includePdfBytes: boolean,
  agentInfo: AgentInformation,
  agencyInfo: AgencyInformation,
  mapAipAdditionalSbis: (appTaskSummary: AppTask, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], agentInfo: AgentInformation, agencyInfo: AgencyInformation, insured: Insured) => T,
  generateAipSbiDocument: (aipSbiDto: T, includePdfBytes: boolean) => Promise<U>,
  maxAipSbis: number,
): Promise<Nullable<U>> => {
  const sbis = appTaskSummary.insuredOtherPersonsOfInterest.filter(x => x.personOfInterestType === PersonOfInterestType.SBI || x.personOfInterestType === PersonOfInterestType.LandLordOrTenant || x.personOfInterestType === PersonOfInterestType.Spouse);
  if (sbis.length > maxAipSbis) {
    const aipSbiDto = mapAipAdditionalSbis(appTaskSummary, entityTypes, admData, taxTypes, agentInfo, agencyInfo, insured);
    return await generateAipSbiDocument(aipSbiDto, includePdfBytes);
  }

  return null;
};

export const handleAipApplicationDocument = async <T, U>(
  appTaskSummary: AppTask,
  applicationWizard: ApplicationWizard,
  insured: Insured,
  entityTypes: EntityType[],
  admData: ADMYear[],
  taxTypes: TaxType[],
  includePDFBytes: boolean,
  aips: AIP[],
  agentInfo: AgentInformation,
  agencyInfo: AgencyInformation,
  mapAipApplication: (appTaskSummary: AppTask, applicationWizard: ApplicationWizard, entityTypes: EntityType[], admData: ADMYear[], taxTypes: TaxType[], aips: AIP[], agentInfo: AgentInformation, agencyInfo: AgencyInformation, insured: Insured) => T,
  generateAipAppDocument: (aipSbiDto: T, includePdfBytes: boolean) => Promise<U>,
): Promise<U> => {
  const aipApplicationDto = mapAipApplication(appTaskSummary, applicationWizard, entityTypes, admData, taxTypes, aips, agentInfo, agencyInfo, insured);
  return await generateAipAppDocument(aipApplicationDto, includePDFBytes);
};

/**
  DEPRECATED - waiting on removing all of the pieces that work on individual until we're sure multi meets all of our needs.
**/
export const eSignApplicationDocument = async (appTask: AppTaskExtended, pdfBase64: string, pdfFileName: string, eSignData: ESignData, admData: ADMYear[]) => {
  if (isNullOrUndefined(pdfBase64)) {
    throw new Error('pdfBase64 cannot be undefined when e-signing');
  }
  const aipId = appTask.aipId;
  if (isNullOrUndefined(aipId) || !SupportedDocumentGenerationAIPIds.has(aipId)) {
    throw new Error(`AIP Id ${aipId} does not support form generation yet.`);
  }

  const request = generateESignRequest(pdfBase64, appTask, pdfFileName, eSignData, admData);

  let eSignResult: ESignResult;

  switch (aipId) {
    case ActiveAIPs.Hudson.aipId:
      eSignResult = await eSignHudsonApplicationDocument(request);
      break;
    case ActiveAIPs.FMH.aipId:
      eSignResult = await eSignFMHApplicationDocument(request);
      break;
    case ActiveAIPs.NAU.aipId:
      eSignResult = await eSignNAUApplicationDocument(request);
      break;
    default:
      throw new Error(`Cannot generate Application for AIP Id ${aipId}`);
  }

  return eSignResult;
};

/**
  DEPRECATED - waiting on removing all of the pieces that work on individual until we're sure multi meets all of our needs.
**/
export const eSignSBIDocument = async (appTask: AppTaskExtended, pdfBase64: string, pdfFileName: string, eSignData: ESignData, admData: ADMYear[]) => {
  if (isNullOrUndefined(pdfBase64)) {
    throw new Error('pdfBase64 cannot be undefined when e-signing');
  }

  const aipId = appTask.aipId;
  if (isNullOrUndefined(aipId) || !SupportedDocumentGenerationAIPIds.has(aipId)) {
    throw new Error(`AIP Id ${aipId} does not support form generation yet.`);
  }

  const request = generateESignRequest(pdfBase64, appTask, pdfFileName, eSignData, admData);

  let eSignResult: ESignResult;

  switch (aipId) {
    case ActiveAIPs.Hudson.aipId:
      eSignResult = (await eSignHudsonSBIDocument(request));
      break;
    case ActiveAIPs.FMH.aipId:
      eSignResult = (await eSignFMHSBIDocument(request));
      break;
    case ActiveAIPs.NAU.aipId:
      eSignResult = (await eSignNAUSBIDocument(request));
      break;
    default:
      throw new Error(`Cannot generate Application for AIP Id ${aipId}`);
  }

  return eSignResult;
};

/**
  DEPRECATED - waiting on removing all of the pieces that work on individual until we're sure multi meets all of our needs.
**/
const generateESignRequest = (pdfBase64: string, appTaskSummary: AppTaskExtended, filename: string, eSignData: ESignData, admData: ADMYear[]) => {
  if (isNullOrUndefined(appTaskSummary.aipId)) {
    throw new Error('AIP Id is required to generate e-signature request.');
  }
  const agentRecipient = getAgentForESign(eSignData);
  // This method will be deprecated AND this should always have items, just making compiler happy
  const docId = eSignData.eSignIncludedDocuments.length === 1 ? eSignData.eSignIncludedDocuments[0].toString() : '';
  const request: ESignatureRequest = {
    insured: getInsuredForESign(eSignData),
    agent: agentRecipient,
    documents: [
      {
        documentId: docId,
        fileName: filename,
        fileBase64: pdfBase64,
        fileContext: null,
      },
    ],
    callbackUrl: isNotNullOrUndefined(eSignStatusCallback) ? eSignStatusCallback.replace('{0}', appTaskSummary.appTaskId) : '',
    emailSubject: `${agentRecipient.name} has requested your e-signature for crop insurance documents`,
    emailBody: `Please sign this crop insurance application document for ${getCountyName(admData, appTaskSummary.cropYear, appTaskSummary.countyCode)} County - ${getAIPLabelById(appTaskSummary.aipId)?.aipName} - ${ProductType[appTaskSummary.productType]}`,
    reminderDelay: eSignData.notificationDelay.toString(),
    reminderFrequency: eSignData.notificationFrequency.toString(),
  };

  return request;
};

export const generateBulkESignRequest = async (appTaskSummary: AppTaskExtended, eSignData: ESignData, admData: ADMYear[], appTaskDocuments: AppTaskDocument[]) => {
  if (isNullOrUndefined(appTaskSummary.aipId)) {
    throw new Error('AIP Id is required to generate e-signature request.');
  }

  const agentRecipient = getAgentForESign(eSignData);
  const request: BulkESignatureRequest = {
    insured: getInsuredForESign(eSignData),
    agent: agentRecipient,
    callbackUrl: isNotNullOrUndefined(eSignStatusCallback) ? eSignStatusCallback.replace('{0}', appTaskSummary.appTaskId) : '',
    emailSubject: `${agentRecipient.name} has requested your e-signature for crop insurance documents`,
    emailBody: `Please sign this crop insurance application document for ${getCountyName(admData, appTaskSummary.cropYear, appTaskSummary.countyCode)} County - ${getAIPLabelById(appTaskSummary.aipId)?.aipName} - ${ProductType[appTaskSummary.productType]}`,
    reminderDelay: eSignData.notificationDelay.toString(),
    reminderFrequency: eSignData.notificationFrequency.toString(),
    fmh_Application_Documents: [],
    fmh_Sbi_Documents: [],
    hudson_Application_Documents: [],
    hudson_Sbi_Documents: [],
    nau_Application_Documents: [],
    nau_Sbi_Documents: [],
  };
  const applicationDocuments = await Promise.all(appTaskDocuments.filter(a => a.appDocumentType === AppDocumentType.Application).map(mapToESignDocument));
  const sbiDocuments = await Promise.all(appTaskDocuments.filter(a => a.appDocumentType === AppDocumentType.SBIForm).map(mapToESignDocument));

  switch (appTaskSummary.aipId) {
    case ActiveAIPs.Hudson.aipId:
      request.hudson_Application_Documents = applicationDocuments;
      request.hudson_Sbi_Documents = sbiDocuments;
      break;
    case ActiveAIPs.NAU.aipId:
      request.nau_Application_Documents = applicationDocuments;
      request.nau_Sbi_Documents = sbiDocuments;
      break;
    case ActiveAIPs.FMH.aipId:
      request.fmh_Application_Documents = applicationDocuments;
      request.fmh_Sbi_Documents = sbiDocuments;
      break;
  }

  return request;
};

const mapToESignDocument = async (appTaskDocument: AppTaskDocument): Promise<ESignatureDocument> => {
  let fileBase64;
  switch (appTaskDocument.appDocumentType) {
    case AppDocumentType.Application:
      fileBase64 = await getApplicationDocumentBase64Content(appTaskDocument.appTaskId);
      break;
    case AppDocumentType.SBIForm:
      fileBase64 = await getAppTaskSupportingDocumentBase64Content(appTaskDocument.documentId);
      break;
  }

  if (isNullOrUndefined(fileBase64) || isNullOrUndefined(fileBase64.data)) {
    throw new Error('Cannot get base 64 content for document to eSign.');
  }

  const eSignDocument : ESignatureDocument = {
    documentId: appTaskDocument.documentId.toString(),
    fileName: appTaskDocument.documentName,
    fileBase64: fileBase64.data,
    fileContext: null,
  };

  return eSignDocument;
};

const getAgentForESign = (eSignData: ESignData): ESignatureRecipient => {
  return {
    name: eSignData.agentName,
    email: eSignData.agentEmail,
    phone: eSignData.agentPhone,
    tabs: null,
  };
};

const getInsuredForESign = (eSignData: ESignData): ESignatureRecipient => {
  return {
    name: eSignData.signerName,
    email: eSignData.signerEmail,
    phone: eSignData.signerPhone,
    tabs: null,
  };
};

export const getTaxTypeNameFromId = (taxTypeId: Nullable<TaxTypeId>, taxTypes: TaxType[]) => {
  const ssnId = taxTypes.find(x => x.name === 'SSN')?.taxTypeId;
  const einId = taxTypes.find(x => x.name === 'EIN')?.taxTypeId;
  const rmaAssignedId = taxTypes.find(x => x.name === 'RMA Assigned')?.taxTypeId;
  switch (taxTypeId) {
    case ssnId:
      return 'SSN';
    case einId:
      return 'EIN';
    case rmaAssignedId:
      return 'RAN';
    default:
      return null;
  }
};

export const getAppTaskStateName = (appTask: AppTask, admData: ADMYear[]) => {
  let stateName = '';
  if (isNotNullOrUndefined(appTask.countyCode)) {
    stateName = getStateName(admData, appTask.cropYear, appTask.countyCode);
  } else if (appTask.coverages.length > 0) {
    const firstCountyCode = appTask.coverages[0].countyCode;
    if (isNotNullOrUndefined(firstCountyCode)) {
      stateName = getStateName(admData, appTask.cropYear, firstCountyCode);
    }
  }

  return stateName;
};