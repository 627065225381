import { quoterDb } from '../../db';
import { ScenarioId, ScenarioPieceId } from '../../types/api/PrimaryKeys';
import {
  createInputCostScenarioPiece,
  getInputCostScenarioPiece,
  getInputCostScenarioPiecesForScenario,
  updateInputCostScenarioPiece,
  deleteInputCostScenarioPiece, getInputCostScenarioPiecesForScenarios
} from '../inputCostScenarioPieces.service';
import {
  addSingle,
  remove,
  getMultiple,
  getSingle,
  update, getDefaultCachingStrategy
} from '../offlineDataAccess.service';
import { CreatedItemResult } from '../../types/api/results/CreatedItemResult';
import { safeWhere } from '../../utils/dexieQueryHelpers/whereClauses';
import { InputCostScenarioPiece } from '../../types/api/InputCostScenarioPiece';

const table = quoterDb.inputCostScenarioPieces;

export const getInputCostScenarioPiecesForScenarioRequest = async (scenarioId: ScenarioId): Promise<InputCostScenarioPiece[]> => {
  const request = () => getInputCostScenarioPiecesForScenario(scenarioId);
  return await getMultiple(table, { scenarioId: scenarioId }, request);
};

export const getInputCostScenarioPiecesForScenariosRequest = async (scenarioIds: ScenarioId[]): Promise<InputCostScenarioPiece[]> => {
  const request = () => getInputCostScenarioPiecesForScenarios(scenarioIds);

  const readTransaction = () => quoterDb.transaction('r', quoterDb.inputCostScenarioPieces, async () => {
    return safeWhere(quoterDb.inputCostScenarioPieces, 'scenarioId').anyOf(scenarioIds).toArray();
  });

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getInputCostScenarioPieceRequest = async (scenarioPieceId: ScenarioPieceId): Promise<InputCostScenarioPiece> => {
  const request = () => getInputCostScenarioPiece(scenarioPieceId);
  return await getSingle(table, { scenarioPieceId: scenarioPieceId }, request);
};

export const createInputCostScenarioPieceRequest = async (scenarioPiece: InputCostScenarioPiece): Promise<CreatedItemResult<ScenarioPieceId>> => {
  const request = () => createInputCostScenarioPiece(scenarioPiece);
  return await addSingle(table, scenarioPiece, request);
};

export const updateInputCostScenarioPieceRequest = async (scenarioPiece: InputCostScenarioPiece): Promise<void> => {
  const request = () => updateInputCostScenarioPiece(scenarioPiece);
  return await update(table, scenarioPiece, request);
};

export const deleteInputCostScenarioPieceRequest = async (scenarioPieceId: ScenarioPieceId): Promise<void> => {
  const request = () => deleteInputCostScenarioPiece(scenarioPieceId);
  return await remove(table, scenarioPieceId, request);
};
