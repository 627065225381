import { ReactNode } from 'react';
import usePercentChange from '../../../../hooks/usePercentChange';
import { ExtendedDataFormInputParameterNames } from '../../constants/inputConstants';
import { FormInputProps } from '../../scenario/formInputProps';
import PriceInput from '../../scenario/priceInput';

export interface FarmBillPriceInputProps extends FormInputProps {
  name: string;
  label: string;
  price: number;
  projectedPriceName?: string;
  actualPriceName?: string;
  toolTip?: ReactNode;
}

const FarmBillPriceInput = ({
  label,
  name,
  price,
  sx,
  toolTip,
  autoFocus = false,
  disabled = false,
  projectedPriceName = ExtendedDataFormInputParameterNames.ProjectedPrice,
  actualPriceName = ExtendedDataFormInputParameterNames.ActualPrice,
}: FarmBillPriceInputProps) => {

  usePercentChange(
    projectedPriceName,
    actualPriceName,
    'farmBillPriceSlider');

  return (
    <PriceInput
      toolTip={toolTip}
      name={name}
      sx={sx}
      price={price}
      label={label}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  );
};

export default FarmBillPriceInput;
