import EcoCalculationParams from '../../../types/api/calculationData/ecoCalculationParams';
import { getEcoCalcData } from '../../calculationData.service';
import { admDb } from '../../../db';
import {
  getAreaBaseRate,
  getInsuranceOfferAvailability,
  getInsuranceOfferInformation, getMultipleCommodityAdjustmentFactor,
  getSubsidyPercent
} from '../../localCalcDataQueries.service';
import { ProductOfferAvailabilities, ScenarioPieceType } from '@silveus/calculations';
import InsurancePlanAttributes from '../../../types/api/enums/insurancePlan/InsurancePlanAttributes';
import InsurancePlan from '../../../types/api/enums/insurancePlan/InsurancePlan.enum';
import { getDefaultCachingStrategy } from '../../offlineDataAccess.service';
import { ProductOfferAvailabilityParams } from './OfferAvailability';
import { getInsurancePlanCodeForScenarioPiece } from '../../../utils/scenarioPieceUtils';
import { EcoCalculationParamsRequest } from '../../../types/api/calculationData/ecoCalculationParamsRequest';

export const getEcoCalcDataRequest = async (calcParamRequest: EcoCalculationParamsRequest): Promise<EcoCalculationParams[]> => {
  const request = () => getEcoCalcData(calcParamRequest);
  const admTransactionTables = [admDb.subsidies, admDb.insuranceOffers, admDb.areaCoverageLevels, admDb.areaRates, admDb.coverageLevelDifferentials];

  const readTransaction = async (): Promise<EcoCalculationParams[]> => {
    const multipleCroppingCode = 'NS';
    const conservationComplianceSubsidyReductionPercent = 0;
    const totalPremiumMultiplicativeOptionalRateAdjustmentFactor = 1; //TODO- when options are implemented if underlying optioncode includes option SR than this value with use that Option's OptionRate

    //If this is a yield-based scenario piece, we don't want to take the price volatility into account
    const volatility = calcParamRequest.planCode === InsurancePlanAttributes[InsurancePlan.EcoYp].name ? null : calcParamRequest.volatility;

    const ecoResponse: EcoCalculationParams[] = [];

    const unitTransactions = calcParamRequest.distinctUnits.map(async distinctUnit => {
      const admData = await admDb.transaction('r', admTransactionTables, async () => {
        const subsidyPercent = await getSubsidyPercent(calcParamRequest.commodityCode, calcParamRequest.unitStructureCode, calcParamRequest.planCode, calcParamRequest.upperCoverageLevel, calcParamRequest.coverageTypeCode);

        const insuranceOffer = await getInsuranceOfferInformation(calcParamRequest.planCode, calcParamRequest.countyId, distinctUnit.typeId, distinctUnit.practiceId, calcParamRequest.coverageTypeCode);

        if (insuranceOffer === null) return null;

        const baseRate = await getAreaBaseRate(calcParamRequest.year, insuranceOffer.insuranceOfferId, calcParamRequest.upperCoverageLevel, volatility);

        return {
          unitOfMeasure: insuranceOffer.unitOfMeasure,
          baseRate: baseRate,
          subsidyPercent: subsidyPercent,
        };
      });

      if (admData === null) return;

      const multipleCommodityAdjustmentFactor = await getMultipleCommodityAdjustmentFactor(multipleCroppingCode);

      const ecoCalculationParams: EcoCalculationParams = {
        unit: distinctUnit,
        unitOfMeasure: admData.unitOfMeasure,
        baseRate: admData.baseRate,
        totalPremiumMultiplicativeOptionalRateAdjustmentFactor: totalPremiumMultiplicativeOptionalRateAdjustmentFactor,
        multipleCommodityAdjustmentFactor: multipleCommodityAdjustmentFactor,
        conservComplSubsidyReductionPercent: conservationComplianceSubsidyReductionPercent,
        subsidyPercent: admData.subsidyPercent,
      };

      ecoResponse.push(ecoCalculationParams);
    });

    await Promise.all(unitTransactions);

    return ecoResponse;
  };

  const strategy = getDefaultCachingStrategy();

  return strategy(request, readTransaction);
};

export const getOfferAvailability = async (productOfferAvailabilityParams: ProductOfferAvailabilityParams): Promise<ProductOfferAvailabilities[]> => {
  const scenarioPieceTypes: ScenarioPieceType[] = [ScenarioPieceType.EcoRp, ScenarioPieceType.EcoRpHpe, ScenarioPieceType.EcoYp];

  const offerAvailabilityPromises = scenarioPieceTypes.map(scenarioPieceType => {
    return getInsuranceOfferAvailability(
      productOfferAvailabilityParams.year,
      productOfferAvailabilityParams.countyId,
      productOfferAvailabilityParams.commodityCode,
      getInsurancePlanCodeForScenarioPiece(scenarioPieceType),
      scenarioPieceType);
  });

  return Promise.all(offerAvailabilityPromises);
};
