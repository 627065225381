import { Nullable } from '../../../types/util/Nullable';
import { OptionCode } from '@silveus/calculations';

export interface TropicalStormProps {
  numberOfTropicalStorms: Nullable<number>;
  initialOptions: OptionCode[];
  autoFocus?: boolean;
  disabled?: boolean;
}

export const TropicalStormInput = ({ numberOfTropicalStorms, initialOptions, autoFocus = false, disabled = false }: TropicalStormProps) => {
  {/* TODO #67490 Get Tropical Storm working for HIP again */ }

  const getNewNumberOfTropicalStorms = (newCheckedStatus: boolean, value: number): number => {
    let newNumberOfTropicalStorms = value;

    if (newCheckedStatus) {
      newNumberOfTropicalStorms += 1;
    } else {
      newNumberOfTropicalStorms -= 1;
    }

    return newNumberOfTropicalStorms;
  };

  return (
    <>
      {/*{doInitialOptionsIncludeTropicalStorm &&*/}
      {/*  <Controller*/}
      {/*    name="rowCropScenarioPieceExtendedData.numberOfTropicalStorms"*/}
      {/*    control={control}*/}
      {/*    defaultValue={numberOfTropicalStorms ?? 0}*/}
      {/*    render={({*/}
      {/*      field: { ref, value, ...field }, fieldState: { error },*/}
      {/*    }) => (*/}
      {/*      <FormGroup>*/}
      {/*        <Grid container>*/}
      {/*          <Grid item xs={6}>*/}
      {/*            <FormControlLabel*/}
      {/*              label={<Typography variant="body2">Tropical Storm 1</Typography>}*/}
      {/*              control={*/}
      {/*                <Checkbox*/}
      {/*                  {...field}*/}
      {/*                  size="small"*/}
      {/*                  disabled={disabled}*/}
      {/*                  checked={value > 0}*/}
      {/*                  inputRef={ref}*/}
      {/*                  onChange={e => field.onChange(getNewNumberOfTropicalStorms(e.target.checked, value))}*/}
      {/*                  autoFocus={autoFocus}*/}
      {/*                />*/}
      {/*              }*/}
      {/*            />*/}
      {/*          </Grid>*/}
      {/*          <Grid item xs={6}>*/}
      {/*            <FormControlLabel*/}
      {/*              label={<Typography variant="body2">Tropical Storm 2</Typography>}*/}
      {/*              control={*/}
      {/*                <Checkbox*/}
      {/*                  {...field}*/}
      {/*                  size="small"*/}
      {/*                  disabled={disabled}*/}
      {/*                  checked={value > 1}*/}
      {/*                  inputRef={ref}*/}
      {/*                  onChange={e => field.onChange(getNewNumberOfTropicalStorms(e.target.checked, value))}*/}
      {/*                  autoFocus={autoFocus}*/}
      {/*                />*/}
      {/*              }*/}
      {/*            />*/}
      {/*          </Grid>*/}
      {/*        </Grid>*/}
      {/*      </FormGroup>*/}
      {/*    )}*/}
      {/*  />*/}
      {/*}*/}
    </>
  );
};
