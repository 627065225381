import { createAppAsyncThunk } from './thunkHelpers';
import { QuoteSummaryReportData } from '../types/api/reports/QuoteSummaryReportData';
import { ActualProductionReportData } from '../types/api/reports/ActualProductionReportData';
import { TriggerYieldReportData } from '../types/api/reports/TriggerYieldReportData';
import {
  generateActualProductionReportRequest, generateMatrixReportRequest, generatePremiumBreakdownReportRequest,
  generateQuoteSummaryReportRequest,
  generateTriggerYieldReportRequest
} from '../services/requestInterception/reportRequestInterceptor';
import { isNilOrEmpty } from '../utils/nullHandling';
import { SigReportDocumentType } from '../constants/sigReportDocumentType';
import { BaseReportData } from '../types/api/reports/BaseReportData';
import { LOADING_STATES } from '../constants/loadingStates';
import { valueof } from '../types/util/valueof';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { PremiumBreakdownReportData } from '../types/api/reports/PremiumBreakdownReportData';
import { MatrixReportData } from '../types/api/reports/MatrixReportData';

export interface ReportsState {
  status: valueof<typeof LOADING_STATES>;
}

const initialState: ReportsState = {
  status: LOADING_STATES.Idle,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(generateQuoteSummaryReport.pending, state => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(generateQuoteSummaryReport.fulfilled, state => {
        state.status = LOADING_STATES.Succeeded;
      })
      .addCase(generateQuoteSummaryReport.rejected, state => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(generateActualProductionReport.pending, state => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(generateActualProductionReport.fulfilled, state => {
        state.status = LOADING_STATES.Succeeded;
      })
      .addCase(generateActualProductionReport.rejected, state => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(generateTriggerYieldReport.pending, state => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(generateTriggerYieldReport.fulfilled, state => {
        state.status = LOADING_STATES.Succeeded;
      })
      .addCase(generateTriggerYieldReport.rejected, state => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(generatePremiumBreakdownReport.pending, state => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(generatePremiumBreakdownReport.fulfilled, state => {
        state.status = LOADING_STATES.Succeeded;
      })
      .addCase(generatePremiumBreakdownReport.rejected, state => {
        state.status = LOADING_STATES.Failed;
      })
      .addCase(generateMatrixReport.pending, state => {
        state.status = LOADING_STATES.Loading;
      })
      .addCase(generateMatrixReport.fulfilled, state => {
        state.status = LOADING_STATES.Succeeded;
      })
      .addCase(generateMatrixReport.rejected, state => {
        state.status = LOADING_STATES.Failed;
      });
  },
});

export const selectIsReportLoading = (state: RootState) => state.reports.status === LOADING_STATES.Loading;

export const generateQuoteSummaryReport = createAppAsyncThunk('reports/generateQuoteSummaryReport', async ({ quoteSummaryReportData }: { quoteSummaryReportData: QuoteSummaryReportData }) => {
  const response = await generateQuoteSummaryReportRequest(quoteSummaryReportData);
  parseAndDownloadReport(response, quoteSummaryReportData, 'Quote Summary Report');
});

export const generateActualProductionReport = createAppAsyncThunk('reports/generateActualProductionReport', async ({ actualProductionReportData }: { actualProductionReportData: ActualProductionReportData }) => {
  const response = await generateActualProductionReportRequest(actualProductionReportData);
  parseAndDownloadReport(response, actualProductionReportData, 'Actual Production Report');
});

export const generateTriggerYieldReport = createAppAsyncThunk('reports/generateTriggerYieldReport', async ({ triggerYieldReportData }: { triggerYieldReportData: TriggerYieldReportData }) => {
  const response = await generateTriggerYieldReportRequest(triggerYieldReportData);
  parseAndDownloadReport(response, triggerYieldReportData, 'Trigger Yield Report');
});

export const generatePremiumBreakdownReport = createAppAsyncThunk('reports/generatePremiumBreakdownReport', async ({ premiumBreakdownReportData }: { premiumBreakdownReportData: PremiumBreakdownReportData }) => {
  const response = await generatePremiumBreakdownReportRequest(premiumBreakdownReportData);
  parseAndDownloadReport(response, premiumBreakdownReportData, 'Premium Breakdown Report');
});

export const generateMatrixReport = createAppAsyncThunk('reports/generateMatrixReport', async ({ matrixReportData }: { matrixReportData: MatrixReportData }) => {
  const response = await generateMatrixReportRequest(matrixReportData);
  parseAndDownloadReport(response, matrixReportData, 'Matrix Report');
});

const parseAndDownloadReport = (response: string, reportData: BaseReportData, reportFileName: string) => {
  const blobType = reportData.reportDocumentType === SigReportDocumentType.Excel
    ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    : 'application/pdf';
  const reportExtension = reportData.reportDocumentType === SigReportDocumentType.Excel ? '.xlsx' : '.pdf';

  const blob = new Blob([response], { type: blobType });
  const objectUrl = URL.createObjectURL(blob);

  const insuredName = reportData.insuredContactInformation?.name?.trim() ?? '';
  const fullReportName = isNilOrEmpty(insuredName) ? reportFileName : [insuredName, reportFileName].join(' - ');

  const reportNameWithExtension = `${fullReportName}${reportExtension}`;

  const a = document.createElement('a');
  a.href = objectUrl;
  a.download = reportNameWithExtension;
  a.click();
  window.URL.revokeObjectURL(objectUrl);
};

export default reportsSlice.reducer;