import { RootState } from './store';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Nullable } from '../types/util/Nullable';
import {
  RampAvailabilityParams,
  RevBoostCoverageLevelParams,
  ScoEcoPlusCoverageLevelParams,
  IceRateParams
} from '../types/api/adm/PrivateProductsParams';
import CoverageLevelAvailability from '../types/api/adm/CoverageLevelAvailability';
import IceRate from '../types/api/adm/IceRate';
import { createAppAsyncThunk } from './thunkHelpers';
import { IceDeductibleSelection } from '../types/api/adm/iceDeductibleSelection';
import {
  getAvailableRampCoverageLevelsRequest
} from '../services/requestInterception/scenarioPieces/rampRequestInterceptor';
import {
  getAvailableRevBoostCoverageLevelsRequest
} from '../services/requestInterception/scenarioPieces/revBoostRequestInterceptor';
import {
  getAvailableScoEcoPlusCoverageLevelsRequest
} from '../services/requestInterception/scenarioPieces/ecoScoPlusRequestInterceptor';
import {
  getIceDeductibleSelectionsRequest,
  getIceRatesRequest
} from '../services/requestInterception/scenarioPieces/iceRequestInterceptor';
import { CountyCommodityKey, buildCountyAndCommodityKey } from '../types/app/CompositeKeys';
import { SliceDataState, initialSliceDataState } from './sliceStateHelpers';
import ArcYieldHistory from '../types/api/adm/ArcYieldHistory';
import AdmDataForQuoteParams from '../types/api/adm/AdmDataForQuoteParams';
import { getFarmBillArcYieldHistoriesRequest } from '../services/requestInterception/scenarioPieces/farmBillRequestInterceptor';
import { getKeyedStateToMap, KeyedState, updateKeyedStateVerbatim } from './sliceHelpers';
import { getItemsForId } from '../utils/mapHelpers';
import { calculateOlympicAverage } from '../utils/calculations/olympicAverage';
import { roundToPlaces } from '@silveus/calculations';
import { getPracticeCodeFromPracticeId } from '../utils/adm';

export interface PrivateProductsState {
  availableCoverageLevels: Nullable<CoverageLevelAvailability[]>;
  availableIceProducts: Nullable<IceRate[]>;
  availableIceDeductibles: Nullable<IceDeductibleSelection[]>;
  arcYieldHistories: SliceDataState<CountyCommodityKey, ArcYieldHistory[]>;
}

const initialState: PrivateProductsState = {
  availableCoverageLevels: null,
  availableIceProducts: null,
  availableIceDeductibles: null,
  arcYieldHistories: initialSliceDataState(),
};

export const privateProductsSlice = createSlice({
  name: 'privateProducts',
  initialState: initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(fetchAvailableRampCoverageLevels.fulfilled, (state, action) => {
        state.availableCoverageLevels = action.payload;
      })
      .addCase(fetchAvailableRevBoostCoverageLevels.fulfilled, (state, action) => {
        state.availableCoverageLevels = action.payload;
      })
      .addCase(fetchAvailableScoEcoPlusCoverageLevels.fulfilled, (state, action) => {
        state.availableCoverageLevels = action.payload;
      })
      .addCase(fetchAvailableIceProducts.fulfilled, (state, action) => {
        state.availableIceProducts = action.payload;
      })
      .addCase(fetchAvailableIceDeductibles.fulfilled, (state, action) => {
        state.availableIceDeductibles = action.payload;
      })
      .addCase(fetchFarmBillArcYieldHistories.fulfilled, (state, action) => {
        for (const payload of action.payload) {
          updateKeyedStateVerbatim(state.arcYieldHistories.data, payload.arcYieldHistories, payload.countyCommodityKey);
        }
      });
  },
});

// coverage levels
const selectCoverageLevels = (state: RootState) => state.privateProducts.availableCoverageLevels;

// arc yields
const selectAllArcYields = (state: RootState) => state.privateProducts.arcYieldHistories.data;

const getFarmBillHistoryByYear = (
  allArcYields: KeyedState<`${string}-${string}`, ArcYieldHistory[]>,
  year: number,
  countyId: string,
  commodityCode: string,
  practiceId: string,
) => {
  const numberOfYearsToUse = 5;
  const allArcYieldsKeyedMap = getKeyedStateToMap(allArcYields);
  const applicableArcYields = getItemsForId(
    allArcYieldsKeyedMap,
    buildCountyAndCommodityKey(countyId, commodityCode))
    .filter(x => x.cropYear === year);

  applicableArcYields.sort((a, b) => {
    // sort by year first
    if (a.yieldYear !== b.yieldYear) {
      return b.yieldYear - a.yieldYear;
    }

    // then by irrigation code
    if (a.irrigationPracticeCode !== b.irrigationPracticeCode) {
      return a.irrigationPracticeCode.localeCompare(b.irrigationPracticeCode);
    }

    // otherwise same
    return 0;
  });

  const uniqueYears = Array.from(new Set(applicableArcYields.map(x => x.yieldYear)));
  const practiceCode = getPracticeCodeFromPracticeId(practiceId);

  const result: ArcYieldHistory[] = [];
  for (const year of uniqueYears) {
    // if one unique value for year
    // else use matching irrigation practice code
    // else use known
    const items = applicableArcYields.filter(x => x.yieldYear === year);
    let itemToAdd: ArcYieldHistory | undefined = undefined;
    if (items.length === 1) {
      itemToAdd = items[0];
    }
    else {
      const item = items.find(x => x.irrigationPracticeCode === practiceCode);
      if (item !== undefined) {
        itemToAdd = item;
      }
    }

    if (itemToAdd !== undefined) {
      result.push(itemToAdd);
    }

    if (result.length >= numberOfYearsToUse) {
      break;
    }
  }

  return result;
};

export const selectFarmBillDefaultPaymentYield = createSelector(
  [
    (state: RootState) => selectAllArcYields(state),
    (state: RootState, year: number) => year,
    (state: RootState, year: number, countyId: string) => countyId,
    (state: RootState, year: number, countyId: string, commodityCode: string) => commodityCode,
    (state: RootState, year: number, countyId: string, commodityCode: string, practiceId: string) => practiceId,
    (state: RootState, year: number, countyId: string, commodityCode: string, practiceId: string, isActual: boolean) => isActual,
    (state: RootState, year: number, countyId: string, commodityCode: string, practiceId: string, isActual: boolean, isArc: boolean) => isArc,
  ], (allArcYields, year, countyId, commodityCode, practiceId, isActual, isArc) => {

    const warningHintLines: string[] = [];
    let newestFiveYears = getFarmBillHistoryByYear(allArcYields, year, countyId, commodityCode, practiceId);
    if (newestFiveYears.length <= 0) {
      newestFiveYears = getFarmBillHistoryByYear(allArcYields, year - 1, countyId, commodityCode, practiceId);
      if (newestFiveYears.length <= 0) {
        warningHintLines.push('Warning: No historical yields found');
      } else {
        warningHintLines.push(`Warning: Prior years yields. Updates expected Dec ${year - 1}`);
      }
    }

    const hintLines: string[] = [];
    const justYields: number[] = [];

    if (newestFiveYears.length > 0) {
      const yields = newestFiveYears.map(x => x.yield).sort();
      justYields.push(...yields);

      hintLines.push('The Olympic Average of the latest 5 years:');
      for (const item of newestFiveYears) {
        hintLines.push(`${item.yieldYear} - ${roundToPlaces(item.yield, 2)}`);
      }
    }

    // hints for actual do something different
    if (isActual) {
      warningHintLines.length = 0;
      hintLines.length = 0;
      if (isArc) {
        hintLines.push('Final County Yield');
      } else {
        hintLines.push('The average of the grower\'s yield for the past 5 years. 90% of this yield will be paid upon.');
      }
    }

    return {
      warningHintLines: warningHintLines,
      hintLines: hintLines,
      value: roundToPlaces(calculateOlympicAverage(justYields), 2),
    };
  });

export const fetchAvailableRampCoverageLevels = createAppAsyncThunk('adm/fetchAvailableRampCoverageLevels',
  async (rampParams: RampAvailabilityParams) => {
    return await getAvailableRampCoverageLevelsRequest(rampParams);
  });

export const fetchAvailableRevBoostCoverageLevels = createAppAsyncThunk('adm/fetchAvailableRevBoostCoverageLevels', async ({ year, underlyingCoverageLevel }: RevBoostCoverageLevelParams) => {
  const requestParams: RevBoostCoverageLevelParams = { year: year, underlyingCoverageLevel: underlyingCoverageLevel };
  return await getAvailableRevBoostCoverageLevelsRequest(requestParams);
});

export const fetchAvailableScoEcoPlusCoverageLevels = createAppAsyncThunk('adm/fetchAvailableScoEcoPlusCoverageLevels',
  async ({ year, countyId, scenarioPieceType, commodityCode, practiceId }: ScoEcoPlusCoverageLevelParams) => {
    const requestParams: ScoEcoPlusCoverageLevelParams = {
      year: year,
      countyId: countyId,
      scenarioPieceType: scenarioPieceType,
      commodityCode: commodityCode,
      practiceId: practiceId,
    };
    return await getAvailableScoEcoPlusCoverageLevelsRequest(requestParams);
  });

export const selectPrivateProductUpperCoverageLevels = createSelector([selectCoverageLevels], result => [...new Set(result?.map(rcl => rcl.upperCoverageLevel) ?? null)]);

export const selectPrivateProductLowerCoverageLevels = createSelector(
  [selectCoverageLevels, (state, upperCoverageLevel) => upperCoverageLevel],
  (lowerCoverageLevels, upperCoverageLevel) => [...new Set(lowerCoverageLevels?.filter(rcl => rcl.upperCoverageLevel === upperCoverageLevel).map(rcl => rcl.lowerCoverageLevel) ?? null)],
);

// ICE
const selectIceProductsState = (state: RootState) => state.privateProducts.availableIceProducts;

export const fetchAvailableIceProducts = createAppAsyncThunk('adm/fetchAvailableIceProducts',
  async (iceParams: IceRateParams) => {
    return await getIceRatesRequest(iceParams);
  });

export const selectIceProduct = createSelector(
  [selectIceProductsState],
  result => [...new Set(result)]);

const selectIceDeductiblesState = (state: RootState) => state.privateProducts.availableIceDeductibles;

export const fetchAvailableIceDeductibles = createAppAsyncThunk('adm/fetchAvailableIceDeductibles',
  async () => {
    return await getIceDeductibleSelectionsRequest();
  });

export const selectIceDeductibles = createSelector(
  [selectIceDeductiblesState],
  result => [...new Set(result)]);

export const fetchFarmBillArcYieldHistories = createAppAsyncThunk('adm/fetchFarmBillArcYieldHistories', async ({ quoteData }: { quoteData: AdmDataForQuoteParams[] }) => {
  return await getFarmBillArcYieldHistoriesRequest(quoteData);
});

// export reducer
export default privateProductsSlice.reducer;